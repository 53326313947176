import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TBXResponseWrapper } from '@tc-core/model/it/codegen/tbx/api/tbx-response-wrapper';
import { KeyValue } from '@tc-core/model/it/codegen/ui/key-value';
import { TCRequestOptions } from '@tc-core/model/it/codegen/ui/request-option';
import { AlphaNumericSorting } from '@tc-core/service/sorters/sorter.service';
import { TC } from '@tc-core/util';
import { ConfigLoader } from '@tc-core/util/framework';
import { HeaderService } from '@tc-core/util/system';
import { FileUploadService } from '@tc-core/util/system/file-upload.service';
import { BehaviorSubject } from 'rxjs';
import { TCO } from '../../constants';
import { DataKey, DataStoreService } from '../util/framework/data-store.service';
import { ErrorProcessorService } from '../util/framework/error-processor-service.service';
import { DMCBaseService } from '../util/system/dmc-base.service';

@Injectable()
export class DataLoaderService
{

    private user = null;

    constructor(
        private configLoader: ConfigLoader,
        private baseService: DMCBaseService,
        private fileUploadService: FileUploadService,
        private errorProcessor: ErrorProcessorService,
        private dataStore: DataStoreService,
        private headerService: HeaderService,
        private sorter: AlphaNumericSorting
    )
    {
        this.dataStore.get(DataKey.user, true).subscribe(user => {
            this.user = user;
        });
    }

    public getHeaders(): HttpHeaders
    {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json');
        return headers;
    }

  /**
   * Loads response from backend and stored it into the given behaviour subject
   * @param dataKey
   * @param {string} endpointId
   * @param {HttpParams} params
   * @param {any[]} pathVariables
   */
  public loadResponse<T>(
    dataKey: DataKey, endpointId: string, params?: HttpParams, pathVariables?: any[]) {

    if (this.hasUrl(endpointId)) {

      let url = this.configLoader.configurations.get(TCO.CONF.CONF_ENDPOINT)[endpointId];

      params = this.setUser(params);

      if (pathVariables && pathVariables.length > 0) {
        url = this.getUrl(url, pathVariables);
      }

      // clear errors
      this.dataStore.set(DataKey.error, {});

      // if there is no entry for the given data key, create a new subject and store it there
      if (!this.dataStore.has(dataKey, true)) {
        this.dataStore.set(dataKey, new BehaviorSubject(null));
      }

      const requestOptionsArgs: TCRequestOptions = new TCRequestOptions();
      requestOptionsArgs.headers = this.getHeaders();
      requestOptionsArgs.params = params;

      // set additional headers if any
      const headers: KeyValue[] = this.headerService.getHeaders();
      headers.forEach((header) => {
        requestOptionsArgs.headers.append(header.key, header.value.toString());
      });

      let resultsWrapper: TBXResponseWrapper<T>;

      this.baseService
        .get<TBXResponseWrapper<T>>(url, requestOptionsArgs)
        .subscribe(
          results => {
            if (results && results.body && results.body.status &&
              results.body.status.code === TC.ErrorCodes.SUCCESS) {
              resultsWrapper = results.body;
              if (resultsWrapper) {
                this.dataStore.set(dataKey, resultsWrapper);
              }
            } else if (results && results.body && results.body.status &&
              results.body.status.code === TC.ErrorCodes.ERROR) {

              if (results && results.body && results.body.error) {
                this.errorProcessor.processBackendDmcError(results.body.error);
              }
              this.dataStore.set(dataKey, results.body);

            } else if (results && results.body && results.body.status && results.body.status.code === TC.ErrorCodes.WARNING) {

              if (results && results.body && results.body.error) {
                this.errorProcessor.processBackendDmcError(results.body.error);
              }
              this.dataStore.set(dataKey, results.body);
            }
            // reset headers
            this.headerService.setHeaders([]);
          },
          e => {
            if (e && e.error && e.error.error) {
              this.errorProcessor.processBackendDmcError(e.error.error);
            } else {
              this.errorProcessor.processBackendError(e.status);
            }
            this.dataStore.set(dataKey, e.error);
            this.handleUnauthorizedError(e);
          }
        );
    } else {
      this.dataStore.set(dataKey, null);
    }
  }

  /**
   * Loads response data from backend and stores it into the given behaviour subject
   * @param dataKey
   * @param endpointId  endpoint identifier in configuration
   * @param params
   */

  public handleUnauthorizedError<T>(error: any) {
    if (error.status === TC.HTTP_ERROR_CODE.UNAUTHORIZED_ERROR) {
      this.dataStore.get(DataKey.unauthorizedError).next(null);
      this.dataStore.set(DataKey.unauthorizedError, true);
    }
  }

  public postAndLoadResponse<T>(dataKey: DataKey, endpointId: string, rqBody: any, params?: HttpParams,
                                pathVariables?: any[]
  ) {

    if (this.hasUrl(endpointId)) {
      let url = this.configLoader.configurations.get(TCO.CONF.CONF_ENDPOINT)[endpointId];
      params = this.setUser(params);

      if (pathVariables && pathVariables.length > 0) {
        url = this.getUrl(url, pathVariables);
      }

      // clear errors
      this.dataStore.set(DataKey.error, {});

      // if there is no entry for the given data key, create a new subject and store it there
      if (!this.dataStore.has(dataKey, true)) {
        this.dataStore.set(dataKey, new BehaviorSubject(null));
      }

      const requestOptionsArgs: TCRequestOptions = new TCRequestOptions();
      requestOptionsArgs.headers = this.getHeaders();
      requestOptionsArgs.params = params;

      // set additional headers if any
      const headers: KeyValue[] = this.headerService.getHeaders();
      headers.forEach((header) => {
        requestOptionsArgs.headers.append(header.key, header.value.toString());
      });

      // reset headers
      this.headerService.setHeaders([]);

      let resultsWrapper: TBXResponseWrapper<T>;
      this.baseService
        .post<TBXResponseWrapper<T>>(url, rqBody, requestOptionsArgs)
        .subscribe(results => {
          const jsonResponse = results.body;
          if (jsonResponse && jsonResponse.status.code === TC.ErrorCodes.SUCCESS) {
            resultsWrapper = jsonResponse;
            if (jsonResponse.data && jsonResponse.data) {
              this.dataStore.set(dataKey, resultsWrapper);
            }
          } else if (jsonResponse && jsonResponse.status.code === TC.ErrorCodes.ERROR) {
            if (jsonResponse.error) {
              this.errorProcessor.processBackendDmcError(jsonResponse.error);
            }
            this.dataStore.set(dataKey, results.body);

          } else if (jsonResponse && jsonResponse.status.code === TC.ErrorCodes.WARNING) {
            if (jsonResponse.error) {
              this.errorProcessor.processBackendDmcError(jsonResponse.error);
            }
            this.dataStore.set(dataKey, results.body);
          } else {

            // unknown status

          }
        }, e => {
          if (e && e.error && e.error.error) {
            this.errorProcessor.processBackendDmcError(e.error.error);
          } else {
            this.errorProcessor.processBackendError(e.status);
          }
          this.dataStore.set(dataKey, e.error);
          this.handleUnauthorizedError(e);
        });
    } else {
      this.dataStore.set(dataKey, null);
    }
  }

  public putAndLoadResponse<T>(dataKey: DataKey, endpointId: string, rqBody: any, params?: HttpParams,
                               pathVariables?: any[]
  ) {

    if (this.hasUrl(endpointId)) {
      let url = this.configLoader.configurations.get(TCO.CONF.CONF_ENDPOINT)[endpointId];
      params = this.setUser(params);

      if (pathVariables && pathVariables.length > 0) {
        url = this.getUrl(url, pathVariables);
      }

      // clear errors
      this.dataStore.set(DataKey.error, {});

      // if there is no entry for the given data key, create a new subject and store it there
      if (!this.dataStore.has(dataKey, true)) {
        this.dataStore.set(dataKey, new BehaviorSubject(null));
      }

      const requestOptionsArgs: TCRequestOptions = new TCRequestOptions();
      requestOptionsArgs.headers = this.getHeaders();
      requestOptionsArgs.params = params;

      // set additional headers if any
      const headers: KeyValue[] = this.headerService.getHeaders();
      headers.forEach((header) => {
        requestOptionsArgs.headers.append(header.key, header.value.toString());
      });

      // reset headers
      this.headerService.setHeaders([]);

      let resultsWrapper: TBXResponseWrapper<T>;
      this.baseService
        .put<TBXResponseWrapper<T>>(url, rqBody, requestOptionsArgs)
        .subscribe(results => {
          const jsonResponse = results.body;
          if (jsonResponse && jsonResponse.status.code === TC.ErrorCodes.SUCCESS) {
            resultsWrapper = jsonResponse;
            if (jsonResponse.data && jsonResponse.data) {
              this.dataStore.set(dataKey, resultsWrapper);
            }
          } else if (jsonResponse && jsonResponse.status.code === TC.ErrorCodes.ERROR) {
            if (jsonResponse.error) {
              this.errorProcessor.processBackendDmcError(jsonResponse.error);
            }
            this.dataStore.set(dataKey, results.body);

          } else if (jsonResponse && jsonResponse.status.code === TC.ErrorCodes.WARNING) {
            if (jsonResponse.error) {
              this.errorProcessor.processBackendDmcError(jsonResponse.error);
            }
            this.dataStore.set(dataKey, results.body);
          } else {

            // unknown status

          }
        }, e => {
          if (e && e.error && e.error.error) {
            this.errorProcessor.processBackendDmcError(e.error.error);
          } else {
            this.errorProcessor.processBackendError(e.status);
          }
          this.dataStore.set(dataKey, e.error);
          this.handleUnauthorizedError(e);
        });
    } else {
      this.dataStore.set(dataKey, null);
    }
  }

  /**
    * Delete and load the response data from backend and stores it into the given behaviour subject
    * @param {DataKey} dataKey
    * @param {string} endpointId
    * @param {HttpParams} params
    * @param {any[]} pathVariables
    */
  public deleteAndLoadResponse<T>(
    dataKey: DataKey, endpointId: string, params?: HttpParams, pathVariables?: any[]) {

    if (this.hasUrl(endpointId)) {
      let url = this.configLoader.configurations.get(TCO.CONF.CONF_ENDPOINT)[endpointId];
      params = this.setUser(params);

      if (pathVariables && pathVariables.length > 0) {
        url = this.getUrl(url, pathVariables);
      }

      // clear errors
      this.dataStore.set(DataKey.error, {});

      // if there is no entry for the given data key, create a new subject and store it there
      if (!this.dataStore.has(dataKey, true)) {
        this.dataStore.set(dataKey, new BehaviorSubject(null));
      }

      const requestOptionsArgs: TCRequestOptions = new TCRequestOptions();
      requestOptionsArgs.headers = this.getHeaders();
      requestOptionsArgs.params = params;

      // set additional headers if any
      const headers: KeyValue[] = this.headerService.getHeaders();
      headers.forEach((header) => {
        requestOptionsArgs.headers.append(header.key, header.value.toString());
      });

      // reset headers
      this.headerService.setHeaders([]);

      let resultsWrapper: TBXResponseWrapper<T>;
      this.baseService
        .delete<TBXResponseWrapper<T>>(url, requestOptionsArgs)
        .subscribe(results => {
          const jsonResponse = results.body;
          if (jsonResponse && jsonResponse.status.code === TC.ErrorCodes.SUCCESS) {
            resultsWrapper = jsonResponse;
            if (jsonResponse.data && jsonResponse.data) {
              this.dataStore.set(dataKey, resultsWrapper);
            }
          } else if (jsonResponse && jsonResponse.status.code === TC.ErrorCodes.ERROR) {
            if (jsonResponse.error) {
              this.errorProcessor.processBackendDmcError(jsonResponse.error);
            }
            this.dataStore.set(dataKey, results.body);

          } else if (jsonResponse && jsonResponse.status.code === TC.ErrorCodes.WARNING) {
            if (jsonResponse.error) {
              this.errorProcessor.processBackendDmcError(jsonResponse.error);
            }
            this.dataStore.set(dataKey, results.body);
          } else {

            // unknown status

          }
        }, e => {
          if (e && e.error && e.error.error) {
            this.errorProcessor.processBackendDmcError(e.error.error);
          } else {
            this.errorProcessor.processBackendError(e.status);
          }
          this.dataStore.set(dataKey, e.error);
          this.handleUnauthorizedError(e);
        });
    } else {
      this.dataStore.set(dataKey, null);
    }
  }

  public patchAndLoadResponse<T>(dataKey: DataKey, endpointId: string, rqBody: any, params?: HttpParams,
                                 pathVariables?: any[]
  ) {

    if (this.hasUrl(endpointId)) {
      let url = this.configLoader.configurations.get(TCO.CONF.CONF_ENDPOINT)[endpointId];
      params = this.setUser(params);

      if (pathVariables && pathVariables.length > 0) {
        url = this.getUrl(url, pathVariables);
      }

      // clear errors
      this.dataStore.set(DataKey.error, {});

      // if there is no entry for the given data key, create a new subject and store it there
      if (!this.dataStore.has(dataKey, true)) {
        this.dataStore.set(dataKey, new BehaviorSubject(null));
      }

      const requestOptionsArgs: TCRequestOptions = new TCRequestOptions();
      requestOptionsArgs.headers = this.getHeaders();
      requestOptionsArgs.params = params;

      // set additional headers if any
      const headers: KeyValue[] = this.headerService.getHeaders();
      headers.forEach((header) => {
        requestOptionsArgs.headers.append(header.key, header.value.toString());
      });

      // reset headers
      this.headerService.setHeaders([]);

      let resultsWrapper: TBXResponseWrapper<T>;
      this.baseService
        .patch<TBXResponseWrapper<T>>(url, rqBody, requestOptionsArgs)
        .subscribe(results => {
          const jsonResponse = results.body;
          if (jsonResponse && jsonResponse.status.code === TC.ErrorCodes.SUCCESS) {
            resultsWrapper = jsonResponse;
            if (jsonResponse.data && jsonResponse.data) {
              this.dataStore.set(dataKey, resultsWrapper);
            }
          } else if (jsonResponse && jsonResponse.status.code === TC.ErrorCodes.ERROR) {
            if (jsonResponse.error) {
              this.errorProcessor.processBackendDmcError(jsonResponse.error);
            }
            this.dataStore.set(dataKey, results.body);

          } else if (jsonResponse && jsonResponse.status.code === TC.ErrorCodes.WARNING) {
            if (jsonResponse.error) {
              this.errorProcessor.processBackendDmcError(jsonResponse.error);
            }
            this.dataStore.set(dataKey, results.body);
          } else {

            // unknown status

          }
        }, e => {
          if (e && e.error && e.error.error) {
            this.errorProcessor.processBackendDmcError(e.error.error);
          } else {
            this.errorProcessor.processBackendError(e.status);
          }
          this.dataStore.set(dataKey, e.error);
          this.handleUnauthorizedError(e);
        });
    } else {
      this.dataStore.set(dataKey, null);
    }
  }

  /**
   * TODO -- replace this set user mechanism with authentication
   * @param params
   * @returns {any | undefined}
   */
  public setUser(params?: HttpParams) {
    if (params && params.get('userId') && params.get('username')) {
      if (this.user) {
        params.set('userId', this.user.userAuthentication.details.principal.userId);
        params.set('username', this.user.userAuthentication.details.principal.username);
      }
    }
    return params;
  }

  /**
   * return if relevant url present for given endpoint id
   * @param endpointId
   */
  public hasUrl(endpointId: string): boolean {
    const url = this.configLoader.configurations.get(TCO.CONF.CONF_ENDPOINT)[endpointId];
    return url ? true : false;
  }

  /**
   * return relevant URL according to path variables.
   * @param url
   * @param pathVariables
   */
  getUrl(url: string, pathVariables: any[]): string {

    const bracketPathVariables = url.match(/\{([^}]+)\}/g);
    pathVariables.forEach((pathVariable, i) => {
      if (bracketPathVariables && bracketPathVariables.length > 0) {
        url = url.replace(bracketPathVariables[i], pathVariable);
      } else {
        url = url + '/' + pathVariable;
      }
    });
    return url;
  }
}


