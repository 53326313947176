import { Component, Input, OnInit } from '@angular/core';
import { ConfigLoader } from '@tc-core/util/framework';
import { TCO } from '../../constants';
import { ServiceItem } from '../../models/reservation/service-item';
import { DataKey, DataStoreService } from '../../services/util/framework/data-store.service';

@Component({
    selector: 'tc-select-service-items',
    templateUrl: './select-service-items.component.html',
})
export class SelectServiceItemsComponent implements OnInit {

    @Input() serviceItems: ServiceItem[] = [];
    @Input() selectedItems: any[] = [];
    @Input() alreadySelectedItems: any[] = [];
    @Input() serviceAssignment: any;
    @Input() readOnly = false;

    panelOpenState = false;
    public resultHeaderHeading: string = '';
    public colDefConfig = [];
    isSaveButtonEnable: boolean;

    constructor(
        private configLoader: ConfigLoader,
        private dataStore: DataStoreService
    ) { }

    ngOnInit() {
        this.enableSaveButton();
        this.serviceAssignment = this.serviceAssignment.data;
        this.colDefConfig = this.configLoader.configurations.get(TCO.CONF.CONF_PASSENGER_GRID_COL_DEF_CONFIG);
        this.resultHeaderHeading = this.serviceItems.length + ' results found';
    }

    public onClose($event: MouseEvent) {
        this.dataStore.set(DataKey.popupClose, true);
    }

    public onSave() {
        this.serviceAssignment.serviceItemIdList = this.selectedItems;
        this.dataStore.set(DataKey.selectedServiceItemIdList, this.serviceAssignment, true);
        this.dataStore.set(DataKey.popupClose, true);
    }

    public onItemClicked($event, item: ServiceItem) {
        if (!this.readOnly) {
            if ($event.checked) {
                item.checked = true;
                this.selectedItems.push(item.serviceItemId);
                this.serviceAssignment.adults += item.adult;
                this.serviceAssignment.children += item.child;
                this.serviceAssignment.infants += item.infant;
                // this.serviceAssignment.serviceItemIdList.push(item.serviceItemId);
            } else {
                item.checked = false;
                this.selectedItems.splice(this.selectedItems.indexOf(item.serviceItemId), 1);
                this.serviceAssignment.adults -= item.adult;
                this.serviceAssignment.children -= item.child;
                this.serviceAssignment.infants -= item.infant;
                // this.serviceAssignment.serviceItemIdList.splice(this.serviceAssignment.serviceItemIdList.indexOf(item.serviceItemId), 1)

                // minimum is 0
                this.serviceAssignment.adults = this.serviceAssignment.adults > 0 ? this.serviceAssignment.adults : 0;
                this.serviceAssignment.children = this.serviceAssignment.children > 0 ?
                    this.serviceAssignment.children :
                    0;
                this.serviceAssignment.infants = this.serviceAssignment.infants > 0 ?
                    this.serviceAssignment.infants :
                    0;
            }
            this.enableSaveButton();
        }
    }

    public enableSaveButton() {
        if (!this.readOnly) {
            this.isSaveButtonEnable = this.selectedItems && this.selectedItems.length > 0;
        }
    }

    public getLeadPassenger(item: ServiceItem) {
        let lead = item.servicePassengers.find(value => value.lead);
        return lead.title + '. ' + lead.firstName + ' ' + lead.lastName + '( ' + item.adult + '.' + item.child + '.' + item.infant + ' )';
    }

    public isSelected(item: ServiceItem) {
        const isSelectedItem = this.selectedItems.includes(item.serviceItemId);
        return isSelectedItem;
    }

    public onClickCheckBox($event: MouseEvent) {
        $event.stopPropagation();
    }

    public disableToSelect(item: ServiceItem) {
        return this.readOnly || this.alreadySelectedItems.includes(item.serviceItemId);
    }
}
