import { AfterViewInit, Component, ViewChild, ViewContainerRef } from '@angular/core';
import { IFilterAngularComp } from 'ag-grid-angular';
import { IDoesFilterPassParams, IFilterParams, RowNode } from 'ag-grid-community';

@Component({
    selector: 'tc-custom-text-filter',
    templateUrl: './custom-text-filter.component.html'
})
export class CustomTextFilterComponent implements IFilterAngularComp, AfterViewInit {

    private params: IFilterParams;
    private valueGetter: (rowNode: RowNode) => any;
    public filter = '';
    public show = true;

    hidePopup: any;

    @ViewChild('input', {read: ViewContainerRef}) public input;

    agInit(params: IFilterParams): void {
        this.params = params;
        this.valueGetter = params.valueGetter;
    }

    isFilterActive(): boolean {
        return this.filter !== null && this.filter !== undefined && this.filter !== '';
    }

    doesFilterPass(params: IDoesFilterPassParams): boolean {
        return true;
    }

    getModel(): any {
        if (this.filter !== '') {
            return {'filterType': 'text', filter: this.filter, 'type': 'contains'};
        }
        else {
            return null;
        }
    }

    setModel(model: any): void {
        this.filter = model ? model.value : '';
    }

    ngAfterViewInit(): void {
    }

    afterGuiAttached(params: { hidePopup?: Function }): void {
        this.hidePopup = params.hidePopup;
        setTimeout(() => {
            this.input.element.nativeElement.focus();
        });
    }

    public onClick() {
        this.params.filterChangedCallback();
        this.hidePopup();
    }

    public onKeyUp($event: KeyboardEvent) {
        if ($event.keyCode === 13) {
            $event.preventDefault();
            this.onClick();
        }
    }
}
