<div class="container-fluid tc-item-wrapper tcpi-card">
  <div class="tc-item-wrapper__body">
    <div class="row">
      <div class="col-md-12">
        <div class="tc-flex-box">
          <div class="tc-flex-item icon-cage input-icon">
            <div class="card-icon--small icn-color-primary"><i class="material-icons">card_travel</i>
            </div>
          </div>
          <div class="tc-flex-item input-cage ">
            <div class="col-xs-12 col-sm-12  col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
              <div class="tc-content--wrap tc-input-view">
                <div id="TRD_PER_INFO_REF"
                     class="tc-caption-txt txt-light">{{"Departure Time" | translate}}
                </div>
                <div class="tc-body1--txt">{{summaryCardData?.data?.value?.arrivalDateTime ? summaryCardData.data.value.arrivalDateTime : EMPTY_CHAR}}</div>
              </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
              <div class="tc-content--wrap tc-input-view">
                <div id="TRD_PER_INFO_HOD_REF"
                     class="tc-caption-txt txt-light">{{"Number of Passengers" | translate}}
                </div>
                <div class="tc-body1--txt">{{summaryCardData?.data?.value.passengerCount!==undefined ? summaryCardData.data.value.passengerCount : EMPTY_CHAR}}</div>
              </div>
            </div>
            <div class="col-xs-12 col-sm-12  col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
              <div class="tc-content--wrap tc-input-view">
                <div id="TRADE_PER_INFO_EXT_REF"
                     class="tc-caption-txt txt-light">{{"Allocated Passengers" | translate}}
                </div>
                <div class="tc-body1--txt">{{summaryCardData?.data?.value.allocatedCount!==undefined ? summaryCardData.data.value.allocatedCount : EMPTY_CHAR}}</div>
              </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
              <div class="tc-content--wrap tc-input-view">
                <div class="tc-caption-txt txt-light">{{"Unallocated Passengers" | translate}}
                </div>
                <div class="tc-body1--txt ">{{summaryCardData?.data?.value.allocationsLeft!==undefined ? summaryCardData.data.value.allocationsLeft : EMPTY_CHAR}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="tc-flex-box">
          <div class="tc-flex-item icon-cage ">
            <div class="card-icon--small icn-color-primary"></div>
          </div>
          <div class="tc-flex-item input-cage ">
            <div class="col-xs-12 col-sm-6  col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
              <div class="tc-content--wrap tc-input-view">
                <div id="TRD_PER_INFO_COU"
                     class="tc-caption-txt txt-light">{{"Transfer Mode" | translate}}
                </div>
                <div class="tc-body1--txt">{{transferMode}}
                </div>
              </div>
            </div>
            <div class="col-xs-12 col-sm-12  col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
            </div>
            <div class="col-xs-12 col-sm-12  col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
            </div>
            <div class="col-xs-12 col-sm-12  col-md-3 col-lg-3 remove-bootstrap-padding-mobile last-column">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
