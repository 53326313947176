import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {KeyValue} from '@tc-core/model/it/codegen/ui/key-value';
import {ContactDetails} from '../../../models/profile/contact-details';
import {SummaryCardData} from '@tc-core/model/it/codegen/ui/framework/summary-card-data';
import {DocumentData} from '../../../models/profile/document';

@Component({
  selector: 'tc-document-summary-card',
  templateUrl: './document-summary-card.component.html'
})
export class DocumentSummaryCardComponent implements OnInit {

    public icon = new KeyValue();
    @Input() selectedDocument: DocumentData;
    @Input() isHeader: boolean;
    @Input() isEditable: boolean;
    @Input() documentCategoryMap: any[];
    @Input() nameCodeMap: Map<string, string>;
    @Input() showIcons: boolean;
    @Input() documentContent: SummaryCardData;

    @Output() deleteDocument: EventEmitter<number> = new EventEmitter();

    CONTACT_CATEGORY_NAME_CODE_MAP_PREFIX = 'contactCategory';

    NOT_SPECIFIED = 'NOT SPECIFIED';

    constructor() { }

    ngOnInit() {
    }

    public getDocumentName(): string {
        if (this.selectedDocument.name) {
            return `${this.selectedDocument.name}`;
        } /*else {
            return `${this.document-view-card.template}`;
        }*/
    }

    public getContactCategory(code: any): string {
        if (this.isEditable && this.documentCategoryMap) {
            let selectedContactCategoryObj = this.documentCategoryMap.filter(obj => obj.code === code)[0];
            if (selectedContactCategoryObj) {
                return selectedContactCategoryObj.name;
            }
        } else if (!this.isEditable && this.nameCodeMap) {
            return this.nameCodeMap.get(`${this.CONTACT_CATEGORY_NAME_CODE_MAP_PREFIX}${code}`);
        } else {
            return '';
        }
    }

    public onDelete(event: any) {
        event.stopPropagation();
        this.deleteDocument.emit(this.selectedDocument.tempId);
    }
}
