/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./template-renderer.component";
var styles_TemplateRendererComponent = [];
var RenderType_TemplateRendererComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TemplateRendererComponent, data: {} });
export { RenderType_TemplateRendererComponent as RenderType_TemplateRendererComponent };
function View_TemplateRendererComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
export function View_TemplateRendererComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_TemplateRendererComponent_1)), i0.ɵdid(1, 540672, null, 0, i1.NgTemplateOutlet, [i0.ViewContainerRef], { ngTemplateOutletContext: [0, "ngTemplateOutletContext"], ngTemplateOutlet: [1, "ngTemplateOutlet"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.templateContext; var currVal_1 = _co.template; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_TemplateRendererComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-template-renderer", [], null, null, null, View_TemplateRendererComponent_0, RenderType_TemplateRendererComponent)), i0.ɵdid(1, 49152, null, 0, i2.TemplateRendererComponent, [], null, null)], null, null); }
var TemplateRendererComponentNgFactory = i0.ɵccf("app-template-renderer", i2.TemplateRendererComponent, View_TemplateRendererComponent_Host_0, {}, {}, []);
export { TemplateRendererComponentNgFactory as TemplateRendererComponentNgFactory };
