import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    QueryList,
    ViewChild,
    ViewChildren
} from '@angular/core';
import { Pager } from '@tc-core/model/it/codegen/ui/framework/pager';
import { Sorter } from '@tc-core/model/it/codegen/ui/framework/sorter';
import { SummaryCardData } from '@tc-core/model/it/codegen/ui/framework/summary-card-data';
import { DataFormatterUtilService } from '@tc-core/util/system/data-formatter-util.service';
import { ExpansionPanelDirective } from '@tc/directives/expansion-panel';
import { ResourceAllocationEvent } from '../../../models/helper/resource-allocation-event';
import { ResourceAllocationSearchEvent } from '../../../models/helper/resource-allocation-search-event';
import { ServiceItemChanges } from '../../../models/helper/service-item-changes';
import { ServiceNote } from '../../../models/reservation/service-note';
import { DriverAllocationSummary } from '../../../models/summary/driver-allocation-summary';
import { TrsSupplierAllocationSummary } from '../../../models/summary/trs-supplier-allocation-summary';
import { VehicleSummary } from '../../../models/summary/vehicle-summary';

@Component({
    selector: 'tc-adv-reservation-search-results',
    templateUrl: './adv-reservation-search-results.component.html'
})
export class AdvReservationSearchResultsComponent implements OnInit {

    @Input() sorter: Sorter;
    @Input() sortingHandler: any;
    @Input() sorters: Sorter[] = [];

    @Input() pager: Pager;

    @Input() serviceItemGroupCards: Array<SummaryCardData>;
    @Input() selectedReservation: SummaryCardData = null;
    @Input() detailReservation: any = null;
    @Input() reservationServiceItemCards: SummaryCardData[];
    @Input() heading: string;
    @Input() subHeaderText: string;
    @Input() footerDataMap: Map<number, object>;
    @Input() showEditBtn: boolean;
    @Input() contentCardRow2col2MatIcon = 'people';
    @Input() supplierList: any;
    @Input() resourceList: any;
    @Input() exit: boolean;

    @Input() serviceItemChangesInSelectedGroup: Map<number, ServiceItemChanges> = new Map();
    @Input() filteredVehicleSuppliers: TrsSupplierAllocationSummary[];
    @Input() filteredDriverSuppliers: TrsSupplierAllocationSummary[];
    @Input() filteredVehicles: VehicleSummary[];
    @Input() filteredDrivers: DriverAllocationSummary[];

    @Input() changesAvailableForServiceItems: boolean;
    @Input() selectedServiceGroupNotes: ServiceNote[];
    @Input() isRequestCompleted: boolean;

    @Output() reservationSelect: EventEmitter<any> = new EventEmitter();
    @Output() edit: EventEmitter<void> = new EventEmitter<void>();
    @Output() newTabClick: EventEmitter<any> = new EventEmitter<any>();
    @Output() del: EventEmitter<any> = new EventEmitter();
    @Output() focus: EventEmitter<number> = new EventEmitter();
    @Output() sort: EventEmitter<Sorter> = new EventEmitter();
    @Output() singleClientSelect: EventEmitter<SummaryCardData> = new EventEmitter();
    @Output() selection: EventEmitter<string> = new EventEmitter();
    @Output() cardScroll: EventEmitter<number> = new EventEmitter<number>();

    @Output() actionClick: EventEmitter<any> = new EventEmitter();
    @Output() groupActionClick: EventEmitter<any> = new EventEmitter();

    @Output() displayBookingDetails: EventEmitter<any> = new EventEmitter();

    @Output() pickUps: EventEmitter<any> = new EventEmitter<any>();
    @Output() dropOffs: EventEmitter<any> = new EventEmitter<any>();

    @Output() moreClick: EventEmitter<any> = new EventEmitter();
    @Output() historyClick: EventEmitter<any> = new EventEmitter<any>();
    @Output() splitClick: EventEmitter<any> = new EventEmitter();
    @Output() leadPassengerClick: EventEmitter<SummaryCardData> = new EventEmitter();

    @Output() confirmClick: EventEmitter<any> = new EventEmitter();
    @Output() clickSupplierReject: EventEmitter<any> = new EventEmitter();
    @Output() saveAssignments: EventEmitter<any> = new EventEmitter();
    @Output() clickMerge: EventEmitter<any> = new EventEmitter();
    @Output() bulkVehicleAssignment: EventEmitter<any> = new EventEmitter();
    @Output() bulkDriverAssignment: EventEmitter<any> = new EventEmitter();

    @Output() vehicleSupplierAssign: EventEmitter<{ ResourceAllocationEvent }> = new EventEmitter();
    @Output() driverSupplierAssign: EventEmitter<{ ResourceAllocationEvent }> = new EventEmitter();
    @Output() vehicleAssign: EventEmitter<{ ResourceAllocationEvent }> = new EventEmitter();
    @Output() driverAssign: EventEmitter<ResourceAllocationEvent> = new EventEmitter();

    @Output() vehicleSupplierClear: EventEmitter<{ ResourceAllocationEvent }> = new EventEmitter();
    @Output() driverSupplierClear: EventEmitter<{ ResourceAllocationEvent }> = new EventEmitter();
    @Output() vehicleClear: EventEmitter<{ ResourceAllocationEvent }> = new EventEmitter();
    @Output() driverClear: EventEmitter<ResourceAllocationEvent> = new EventEmitter();

    @Output() vehicleSupplierTextChange: EventEmitter<ResourceAllocationSearchEvent> = new EventEmitter();
    @Output() driverSupplierTextChange: EventEmitter<ResourceAllocationSearchEvent> = new EventEmitter();
    @Output() vehicleTextChange: EventEmitter<ResourceAllocationSearchEvent> = new EventEmitter();
    @Output() driverTextChange: EventEmitter<ResourceAllocationSearchEvent> = new EventEmitter();
    @Output() vehicleJobNoChange: EventEmitter<ResourceAllocationSearchEvent> = new EventEmitter();
    @Output() driverJobNoChange: EventEmitter<ResourceAllocationSearchEvent> = new EventEmitter();
    @Output() clearVehicleAssignment: EventEmitter<ResourceAllocationSearchEvent> = new EventEmitter();
    @Output() clearDriverAssignment: EventEmitter<ResourceAllocationSearchEvent> = new EventEmitter();
    @Output() autoAssignResources: EventEmitter<any> = new EventEmitter();

    @Output() vehicleTextFocus: EventEmitter<ResourceAllocationSearchEvent> = new EventEmitter();
    @Output() serviceGroupNote: EventEmitter<any> = new EventEmitter();
    @Output() emptyServiceGroupNote: EventEmitter<any> = new EventEmitter();
    @Output() saveNote: EventEmitter<ServiceNote> = new EventEmitter();
    @Output() deleteNote: EventEmitter<ServiceNote> = new EventEmitter();
    @Output() isEditClicked: EventEmitter<boolean> = new EventEmitter();
    @Output() viewMoreFocus: EventEmitter<any> = new EventEmitter();

    isSaved: boolean = true;

    private FOCUS_ID = 'result';

    public defaultPageResultCount = 10;
    public expandedPanelIndex: number = -1;
    // public pager: Pager;
    navItems: any;

    private expandedPanel = null;
    private expansionPanel = null;
    // private pagerSubscription: Subscription = new Subscription();

    @ViewChildren(ExpansionPanelDirective) _expansionPanels: QueryList<ExpansionPanelDirective>;
    @ViewChild('clientExp') clientExp: ElementRef;

    private routes: any;

    constructor(private dataFormatterUtilService: DataFormatterUtilService) { }

    ngOnInit() {
    }

    public onReservationSelection(summaryCardData: SummaryCardData, index?: number) {
        this.selectedReservation = summaryCardData;
        if (summaryCardData) {
            this.reservationSelect.emit(this.selectedReservation);
            this.cardScroll.emit(index);
        }
    }

    public onExpand() {
        this.reservationSelect.emit(this.selectedReservation);
    }

    public onHeaderClick() {
        if (!this.isSaved && !this.exit) {
            this.saveAssignments.emit();
        } else {
            this.selectedReservation = null;
        }
    }

    public onOutsideClick() {
        if (!this.isSaved && !this.exit) {
            this.saveAssignments.emit();
        }
    }

    public onEditClick(event) {
        this.edit.emit(event);
    }

    public onSavedChanges($event) {
        this.isSaved = $event;
    }

    onActionClick(summaryCardData, event) {
        this.selectedReservation = summaryCardData;

        if (summaryCardData) {
            this.actionClick.emit(event);
            this.reservationSelect.emit(this.selectedReservation);
        }
    }

    onPickUpsClick(event) {
        this.pickUps.emit(event);
    }

    onDropOffsClick(event) {
        this.dropOffs.emit(event);
    }

    public onSelectVehicleSupplier($event) {
        this.vehicleSupplierAssign.emit($event);
    }
    public onSelectDriverSupplier($event) {
        this.driverSupplierAssign.emit($event);
    }

    public onSelectVehicle($event) {
        this.vehicleAssign.emit($event);
    }

    public onSelectDriver($event) {
        this.driverAssign.emit($event);
    }

    public clearVehicleSupplier($event) {
        this.vehicleSupplierClear.emit($event);
    }
    public clearDriverSupplier($event) {
        this.driverSupplierClear.emit($event);
    }

    public clearVehicle($event) {
        this.vehicleClear.emit($event);
    }

    public clearDriver($event) {
        this.driverClear.emit($event);
    }

    public onVehicleSupplierTextChange($event: ResourceAllocationSearchEvent) {
        this.vehicleSupplierTextChange.emit($event);
    }
    public onDriverSupplierTextChange($event: ResourceAllocationSearchEvent) {
        this.driverSupplierTextChange.emit($event);
    }

    public onAutoAssignResources($event){
        this.autoAssignResources.emit($event);
    }

    public onVehicleTextChange($event: ResourceAllocationSearchEvent) {
        this.vehicleTextChange.emit($event);
    }

    public onDriverTextChange($event: ResourceAllocationSearchEvent) {
        this.driverTextChange.emit($event);
    }

    public onVehicleFocus($event: ResourceAllocationSearchEvent) {
        this.vehicleTextFocus.emit($event);
    }

    onVehicleJobNoChange($event: ResourceAllocationSearchEvent) {
        this.vehicleJobNoChange.emit($event);
    }

    onDriverJobNoChange($event: ResourceAllocationSearchEvent) {
        this.driverJobNoChange.emit($event);
    }

    onClearVehicleAssignment($event: ResourceAllocationSearchEvent) {
        this.clearVehicleAssignment.emit($event);
    }

    onClearDriverAssignment($event: ResourceAllocationSearchEvent) {
        this.clearDriverAssignment.emit($event);
    }
    public onMoreClick(event) {
        this.moreClick.emit(event);
    }

    public onSplitClick(serviceItemCard: SummaryCardData) {
        this.splitClick.emit(serviceItemCard);
    }

    public onLeadPassengerSelect(serviceItemCard: SummaryCardData) {
        this.leadPassengerClick.emit(serviceItemCard);
    }

    showDetails(bookingId) {
        this.displayBookingDetails.emit(bookingId);
    }

    getRoute(summaryCardData: SummaryCardData): string {
        return summaryCardData['data']['value'].route;
    }

    public onConfirmClick($event: any) {
        this.confirmClick.emit($event);
    }

    private getRoutes() {

    }

    processDate(summaryCardData: SummaryCardData): string {
        let date = summaryCardData['data']['value'].serviceDate;
        return this.dataFormatterUtilService.formatDate(date, 'dd MMMM, yyyy');
    }

    public onSaveAssignments() {
        this.saveAssignments.emit();
    }

    public onClickMerge() {
        this.clickMerge.emit();
    }

    public onBulkVehicleAssignment() {
        this.bulkVehicleAssignment.emit();
    }
    public onBulkDriverAssignment() {
        this.bulkDriverAssignment.emit();
    }

    public addGroupNote() {
        this.serviceGroupNote.emit();
    }

    public addEmptyGroupNote() {
        this.emptyServiceGroupNote.emit();
    }

    public onNoteSave(event: ServiceNote) {
        this.saveNote.emit(event);
    }

    public onDelete(event: ServiceNote) {
        this.deleteNote.emit(event);
    }

    public onEdit(event: boolean) {
        this.isEditClicked.emit(event);
    }

    onViewMoreClick(serviceItem: any) {
        this.viewMoreFocus.emit(serviceItem);
    }

    public onSupplierRejectClick($event: any) {
        this.clickSupplierReject.emit();
    }

    public onHistoryClick(event: any) {
        this.historyClick.emit(event);
    }
}

