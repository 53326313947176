<!--<input type="file" name="photo" ng2FileSelect [uploader]="uploader" />-->
<div class="form-group">
<!--  <label for="file">{{'Choose File' | translate}}</label>-->
  <input type="file"
         id="file"
         (change)="handleFileInput($event?.target?.files)">
</div>
<div class="rs-footer-section">
  <button
          class="tc-button-secondary mat-button rs-footer-section__button"
          (click)="closePopUp()"
          [disabled]="false">
    {{'Cancel' | translate}}
  </button>
<button class="tc-button-primary mat-button rs-footer-section__button"
        (click)="uploadFile()"
        [disabled]="!fileToUpload" >
  {{'Upload' | translate}}
</button>
</div>
