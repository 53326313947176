import * as tslib_1 from "tslib";
import { AbstractSearchCriteria } from "./abstract-search-criteria";
var StateSearchCriteria = /** @class */ (function (_super) {
    tslib_1.__extends(StateSearchCriteria, _super);
    function StateSearchCriteria() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return StateSearchCriteria;
}(AbstractSearchCriteria));
export { StateSearchCriteria };
