import * as tslib_1 from "tslib";
import { KeyControlAndAbstractSearchCriteria } from './key-control-and-abstract-search-criteria';
var DistributionChannelSearchCriteria = /** @class */ (function (_super) {
    tslib_1.__extends(DistributionChannelSearchCriteria, _super);
    function DistributionChannelSearchCriteria() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.code = '';
        _this.name = '';
        _this.kcCompany = '';
        return _this;
    }
    return DistributionChannelSearchCriteria;
}(KeyControlAndAbstractSearchCriteria));
export { DistributionChannelSearchCriteria };
