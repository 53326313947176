<div class="header-panel-section tc-px-2 tc-py-1">
    <div class="tc-icon-block">
        <button class="tc-icon-block__icon sort-icon tc-icon-block__icon--active"
                *ngIf="!readOnly"
                mat-button
                matSuffix
                matTooltip="{{'Add '+itemTypeSingular | translate}}"
                mat-icon-button
                aria-label="Add"
                (click)="onAddClick($event)"
                [disabled]="disableAdd">
            <mat-icon>add</mat-icon>
        </button>
        <button class="tc-icon-block__icon sort-icon tc-icon-block__icon--active"
                *ngIf="enableBulkSendButton"
                mat-icon-button
                aria-label="Add"
                (click)="onBulkSendClick($event)"
                matTooltip="{{'Send Selected' | translate}}"
                [disabled]="">
            <i class="material-icons">send</i>
        </button>
        <button class="tc-icon-block__icon sort-icon tc-icon-block__icon--active"
                *ngIf="enableCustomAdd"
                mat-icon-button
                aria-label="Add"
                (click)="onCustomAddClick($event)"
                matTooltip="{{'Add '+itemTypeSingular | translate}}"
                [disabled]="">
            <i class="material-icons">add</i>
        </button>

        <button class="tc-icon-block__icon sort-icon tc-icon-block__icon--active"
                *ngIf="!readOnly"
                mat-icon-button
                aria-label="Delete"
                (click)="onDeleteSelectedClick($event)"
                matTooltip="{{'Delete selected '+ itemTypePlural | translate}}"
                [disabled]="disableDelete">
            <i class="material-icons">delete_sweep</i>
        </button>

        <button class="tc-icon-block__icon sort-icon tc-icon-block__icon--active"
                *ngFor="let hAction of headerActions"
                mat-icon-button
                aria-label="Action"
                (click)="hAction.action()"
                matTooltip="{{hAction.tooltip | translate}}"
                [disabled]="hAction.disable">
            <i class="material-icons">{{hAction.icon}}</i>
        </button>

        <button class="tc-icon-block__icon sort-icon tc-icon-block__icon--active"
                matTooltip="{{'Show/Hide Columns' | translate}}"
                mat-icon-button
                [matMenuTriggerFor]="beforeMenu"
                [disabled]="">
            <i class="material-icons">view_column</i>
        </button>

        <mat-menu #beforeMenu="matMenu" xPosition="before">
            <mat-selection-list
                #selectedColFields
                style="max-height: 400px;"
                (selectionChange)=onSelectionChange($event)>
                <ng-container *ngFor="let coldef of _colDefConfig">
                    <ng-container *ngIf="!coldef.hideInColSelector">
                        <mat-list-option
                                [value]="coldef.colId"
                                [selected]="!coldef.hide"
                                (click)="onClickColOption($event)">
                            {{coldef.headerName | translate}}
                        </mat-list-option>
                    </ng-container>
                </ng-container>
            </mat-selection-list>
        </mat-menu>
    </div>
</div>
<!--</div>-->

<div class="expand-group-card">
    <ag-grid-angular
        #agGrid
        style="height: calc(100vh - 182px)"
        class="ag-theme-material tc-ag-grid tc-ag-grid--full-width tc-ag-grid--auto-height tc-ag-grid--height-full tc-border-left-0 tc-border-right-0"
        [columnDefs]="columnDefs"
        [overlayLoadingTemplate]="overlayLoadingTemplate"
        [defaultColDef]="defaultColDef"
        [gridOptions]="gridOptions"
        [rowSelection]="'multiple'"
        [frameworkComponents]="frameworkComponents"
        [rowClassRules]="_rowClassRules"
        [enableCellChangeFlash]="true"
        [suppressRowClickSelection]="true"
        [overlayNoRowsTemplate]="overlayNoRowsTemplate"
        [floatingFilter]="false"
        (gridSizeChanged)="onGridSizeChange($event)"
        (rowDoubleClicked)="onRowDoubleClicked($event)"
        (rowClicked)="onRowClicked($event)"
        (cellValueChanged)="afterCellValueChange($event)"
        (cellKeyDown)="onKeyDown($event)"
        (cellEditingStopped)="cellEditingStopped($event)"
        (gridReady)="onGridReady($event)"
        (cellEditingStarted)="onCellEditingStart($event)"
        (firstDataRendered)="onFirstDataRendered($event)">
    </ag-grid-angular>
</div>
