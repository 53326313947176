import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { TcErrorType } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-error';
import { DialogModel } from '@tc-core/model/it/codegen/ui/framework/dialog-model';
import { LEVEL, ModalData } from '@tc-core/model/it/codegen/ui/framework/modal-data';
import { TC } from '@tc-core/util';
import { ConfigLoader, EventManager, FocusViewManager } from '@tc-core/util/framework';
import { CommonHelper } from '@tc-core/util/helpers';
import { SpinnerService } from '@tc-core/util/ui';
import { DialogService } from '@tc/dialog/dialog.service';
import { FocusViewService } from '@tc/focus-view/focus-view.service';
import { Subscription } from 'rxjs';
import { TCO } from '../../../constants';
import { LeaveSetupFocusViewComponent } from '../../../business/leave-setup-focus-view/leave-setup-focus-view.component';
import { LeaveSearchCriteria } from '../../../models/criteria/leave-search-criteria';
import { AssignmentStatus } from '../../../models/reservation/assignment-status';
import { ResourceLeaveSummary } from '../../../models/resource-leave-summary';
import { DMCCommon } from '../../../services/util/common/dmc-common';
import { TrsReservationManagementService } from '../../../services/backend-consumers/reservation-management-services/trs-reservation-management.service';
import { ResourceAvailabilityService } from '../../../services/backend-consumers/supplier-service/resource-availability.service';
import { RootService } from '../../../services/util/core-services/root.service';
import { DataKey, DataStoreService } from '../../../services/util/framework/data-store.service';
import { DMCQueryParamsService } from '../../../services/util/framework/dmc-query-params.service';
import * as moment from 'moment';
import { ResponseUtil } from '../../../services/util/response/response-util.service';
import timeGridPlugin from '@fullcalendar/timegrid';
import { ReservationV2ManagementService } from '../../../services/backend-consumers/reservation-v2/reservation-v2-management.service';
var ResAvailabilityCalendarFocusViewComponent = /** @class */ (function () {
    function ResAvailabilityCalendarFocusViewComponent(resourceAvailabilityService, trsReservationManagementService, spinnerService, dataStore, em, configLoader, focusViewService, queryParamService, rootService, focusViewManager, dialogService, commonHelper, common, reservationV2ManagementService) {
        this.resourceAvailabilityService = resourceAvailabilityService;
        this.trsReservationManagementService = trsReservationManagementService;
        this.spinnerService = spinnerService;
        this.dataStore = dataStore;
        this.em = em;
        this.configLoader = configLoader;
        this.focusViewService = focusViewService;
        this.queryParamService = queryParamService;
        this.rootService = rootService;
        this.focusViewManager = focusViewManager;
        this.dialogService = dialogService;
        this.commonHelper = commonHelper;
        this.common = common;
        this.reservationV2ManagementService = reservationV2ManagementService;
        this.calendarPlugins = [dayGridPlugin, timeGridPlugin, interactionPlugin];
        this.navConfig = [];
        this.assignments = [];
        this.events = [];
        this.leaveEvents = [];
        this.calendarEvents = [];
        this.viewOptions = [];
        this.resourceLeaveSummary = new ResourceLeaveSummary();
        this.leaveSetupSearchCriteria = new LeaveSearchCriteria();
        this.leaveTypeConfig = [];
        this.duration = 4;
        this.focusViewCloseSubject = new Subscription();
        this.deletedEventObsv = new Subscription();
        this.assignmentSearchObsv = new Subscription();
        this.leaveSearchObsv = new Subscription();
    }
    ResAvailabilityCalendarFocusViewComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.focusViewManager.canProceed.next(true);
        this.focusViewManager.canProceedState.next(true);
        this.getCalendarViewOptions();
        if (this.navConfig && this.navConfig.length > 0) {
            this.leaveTypeConfig = this.navConfig.filter(function (value) { return value.isLeave; });
        }
        this.dataStore.set(DataKey.resourceLeaves, null, true);
        this.getAssignments();
        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'CLOSE_FOCUS_VIEW').subscribe(function (e) {
            _this.dataStore.set(DataKey.availabilityCalendarFocusViewClose, null);
            _this.dataStore.set(DataKey.availabilityCalendarFocusViewClose, true);
            _this.focusViewService.close(true);
        });
    };
    ResAvailabilityCalendarFocusViewComponent.prototype.getCalendarViewOptions = function () {
        this.viewOptions = this.configLoader.configurations.get(TCO.CONF.CONF_PAGE_SECTION_CONFIG)['calendar-view-options'];
    };
    // open leave setup focus view when select on date or date range
    ResAvailabilityCalendarFocusViewComponent.prototype.handleDateClick = function (arg) {
        var _this = this;
        var e_1, _a;
        this.leaveSetupSearchCriteria.resourceType = this.resourceSummary.resourceType;
        this.leaveSetupSearchCriteria.resourceId = this.resourceSummary.resourceId;
        this.leaveSetupSearchCriteria.activeStatus = true;
        var startDate = moment(arg.start).format('YYYY-MM-DD');
        var endDate = moment(arg.end - 1).format('YYYY-MM-DD');
        var leavesForSelectedDate = false;
        var tasksForSelectedDate = false;
        try {
            for (var _b = tslib_1.__values(this.events), _c = _b.next(); !_c.done; _c = _b.next()) {
                var value = _c.value;
                if ((value.date > startDate || value.date === startDate) && (value.date < endDate || value.date === endDate)) {
                    if (value.type === 'TASK') {
                        tasksForSelectedDate = true;
                    }
                    else {
                        leavesForSelectedDate = true;
                    }
                    break;
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        if (tasksForSelectedDate) {
            var confirmDataObject = new DialogModel(true, LEVEL.WARNING, 'Add Leaves', 'There are already assigned tasks. Do you wish to proceed?', true, 3000);
            this.dialogService.confirm(confirmDataObject).subscribe(function (res) {
                if (res === true) {
                    _this.showLeaveSetup(startDate, endDate, true);
                }
            });
            // this.common.showSnackBar('There are already assigned tasks', 3000, TcErrorType.TYPE.WARN);
        }
        else if (!leavesForSelectedDate) {
            this.showLeaveSetup(startDate, endDate, false);
        }
    };
    ResAvailabilityCalendarFocusViewComponent.prototype.showLeaveSetup = function (startDate, endDate, hasTask) {
        var _this = this;
        if (hasTask === void 0) { hasTask = false; }
        setTimeout(function () {
            var fabActions = [];
            // getting fab actions for leave setup focus view
            var navItemsArray = _this.configLoader.configurations.get(TCO.CONF.CONF_NAVIGATION);
            _this.configLoader.configurations.get(TCO.CONF.CONF_FOCUS_VIEW).LEAVE_SETUP_FOCUS_VIEW.fabAction
                .forEach(function (val) {
                for (var key in navItemsArray) {
                    if (val === navItemsArray[key]['id']) {
                        fabActions.push(navItemsArray[key]);
                    }
                }
            });
            var dataObject = new ModalData(LEVEL.SUCCESS, 'Leave Setup', null, null, '', LeaveSetupFocusViewComponent, {
                'leaveSearchCriteria': _this.leaveSetupSearchCriteria,
                'isInsideForm': true,
                'hasTaskForDates': hasTask,
                'selectedStartDate': startDate,
                'selectedEndDate': endDate,
                'leaveTypes': _this.leaveTypeConfig
            }, { 'label': 'close' }, '', '', fabActions, '', 'From: ' + startDate + ' To: ' + endDate);
            _this.focusViewCloseSubject = _this.dataStore.get(DataKey.leaveSetupFocusViewClose).subscribe(function (c) {
                if (c) {
                    _this.getLeaves();
                }
            });
            _this.focusViewService.confirm(dataObject).subscribe(function (res) {
                if (res) {
                    console.log(res);
                }
            });
        }, 0);
    };
    // handle click event
    ResAvailabilityCalendarFocusViewComponent.prototype.eventClick = function (calEvent) {
        // check the target id
        if (calEvent.jsEvent && calEvent.jsEvent.target.id === 'Delete') {
            this.removeEvent(calEvent.event);
        }
    };
    ResAvailabilityCalendarFocusViewComponent.prototype.removeEvent = function (event) {
        var _this = this;
        var confirmDataObject = new DialogModel(true, LEVEL.WARNING, 'Delete Leave', 'This Leave will be deleted. Do you wish to proceed?', true, 3000);
        this.dialogService.confirm(confirmDataObject).subscribe(function (res) {
            if (res === true) {
                var criteria = void 0;
                criteria = event.extendedProps.leaveId;
                _this.getDeleteObserver(criteria);
            }
        });
    };
    // subscribe event delete
    ResAvailabilityCalendarFocusViewComponent.prototype.getDeleteObserver = function (criteria) {
        var _this = this;
        this.resourceAvailabilityService.deleteLeave(criteria).subscribe(function (data) {
            if (ResponseUtil.isSuccess(data)) {
                _this.getLeaves();
            }
            else {
                _this.common.showSnackBar('Failed to delete.', 3000, TcErrorType.TYPE.ERROR);
            }
        });
    };
    // create leaves to display in calendar
    ResAvailabilityCalendarFocusViewComponent.prototype.getLeaves = function () {
        var _this = this;
        this.dataStore.set(DataKey.resourceLeaves, null, true);
        // create search criteria
        var criteria = new LeaveSearchCriteria();
        criteria.resourceId = this.resourceSummary.resourceId;
        criteria.activeStatus = true;
        this.resourceAvailabilityService.searchLeaveSummaries(criteria, DataKey.resourceLeaves);
        this.spinnerService.show();
        this.leaveSearchObsv = this.dataStore.get(DataKey.resourceLeaves).subscribe(function (value) {
            if (value) {
                var leaveData = void 0;
                _this.resourceLeaveSummary = ResponseUtil.getFirstData(value);
                leaveData = _this.resourceLeaveSummary ? _this.resourceLeaveSummary.leaves : [];
                _this.spinnerService.hide();
                _this.events = [];
                var leaveConfig_1 = {};
                if (leaveData && leaveData.length > 0) {
                    var events_1 = [];
                    // process search results
                    leaveData.forEach(function (leave) {
                        var event = {};
                        // create leave id in FE
                        event.leaveId = {
                            'leaveDate': leave.leaveDate,
                            'resourceId': leave.resourceId,
                            'groupId': leave.groupId
                        };
                        //filter relevant configs
                        leaveConfig_1 = _this.leaveTypeConfig.filter(function (val) { return val.name === leave.leaveType; })[0];
                        event.groupId = leave.groupId;
                        event.resourceId = leave.resourceId;
                        event.start = leaveConfig_1 ? leave.leaveDate + 'T' + leaveConfig_1.startTime : leave.leaveDate;
                        event.end = leaveConfig_1 ? leave.leaveDate + 'T' + leaveConfig_1.endTime : leave.leaveDate;
                        event.leaveType = leaveConfig_1 ? leaveConfig_1.name : leave.leaveType;
                        event.type = leaveConfig_1 ? leaveConfig_1.code : leave.leaveType;
                        event.innerHtml = leaveConfig_1 ? leaveConfig_1.innerHtml : {};
                        event.title = leave.note;
                        event.date = leave.leaveDate;
                        event.allDay = leaveConfig_1.allDay;
                        events_1.push(event);
                    });
                    _this.leaveEvents = events_1;
                    _this.events = _this.leaveEvents;
                }
                if (_this.assignments && _this.assignments.length > 0) {
                    _this.assignments.forEach(function (val) { return _this.events.push(val); });
                }
                _this.calendarEvents = _this.events;
            }
        });
        // this.calendarComponent.fullCalendar('renderEvents', this.leaveEvents, true);
    };
    // create tasks for display in the calendar
    ResAvailabilityCalendarFocusViewComponent.prototype.getAssignments = function () {
        var _this = this;
        var resourceId = this.resourceSummary.resourceId;
        var resourceIdList = [];
        resourceIdList.push(this.resourceSummary.resourceId);
        this.dataStore.set(DataKey.resourceAssignments, null, true);
        this.reservationV2ManagementService.searchAssignments(resourceId, DataKey.resourceAssignments);
        this.spinnerService.show();
        this.assignmentSearchObsv = this.dataStore.get(DataKey.resourceAssignments).subscribe(function (value) {
            var resourceTasks = ResponseUtil.getDataArray(value);
            _this.spinnerService.hide();
            if (resourceTasks && resourceTasks.length > 0) {
                var events_2 = [];
                resourceTasks.forEach(function (task) {
                    var event = {};
                    event.eventId = task.assignmentId;
                    var taskTitle = task.startLocationGroup ? task.startLocationGroup : task.tourName;
                    taskTitle = taskTitle ? taskTitle : "";
                    event.title = task.startTime + ' ' + taskTitle;
                    var taskConfig = _this.navConfig.filter(function (val) { return !val.isLeave; })[0];
                    // event.color = taskConfig.color;
                    event.textColor = taskConfig.textColor;
                    event.className = taskConfig.class;
                    event.type = taskConfig.code;
                    // check task status - assignment approved or not
                    if (task.status === AssignmentStatus.PENDING) {
                        event.innerHtml = taskConfig.neutralInnerHtml;
                    }
                    else if (task.status === AssignmentStatus.DMC_CONFIRM || task.status === AssignmentStatus.COMPLETED || task.status === AssignmentStatus.STARTED || task.status === AssignmentStatus.SUPPLIER_CONFIRMED) {
                        event.innerHtml = taskConfig.confirmedInnerHtml;
                    }
                    else {
                        event.innerHtml = taskConfig.rejectedInnerHtml;
                    }
                    if (task.assignDate) {
                        task.assignDate = task.assignDate.split('T')[0];
                        event.date = task.assignDate;
                        event.start = task.assignDate + 'T' + task.startTime;
                        var end = task.endTime ? task.endTime : parseFloat(task.startTime) + _this.duration;
                        event.end = task.assignDate + 'T' + end.toString();
                    }
                    events_2.push(event);
                });
                _this.assignments = events_2;
            }
            _this.getLeaves();
        });
    };
    // filter calendar events
    ResAvailabilityCalendarFocusViewComponent.prototype.filterSelectedEvents = function (event) {
        this.calendarEvents = this.events.filter(function (value) { return event.includes(value.type); });
    };
    ResAvailabilityCalendarFocusViewComponent.prototype.ngOnDestroy = function () {
        if (this.assignmentSearchObsv) {
            this.assignmentSearchObsv.unsubscribe();
        }
        if (this.leaveSearchObsv) {
            this.leaveSearchObsv.unsubscribe();
        }
    };
    return ResAvailabilityCalendarFocusViewComponent;
}());
export { ResAvailabilityCalendarFocusViewComponent };
