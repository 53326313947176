import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { SetupGridComp } from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid-comp';
import { SetupGridComponent } from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid.component';
import { of, Subscription } from 'rxjs';
import { BoardBasisSetupSearchCriteria } from '../../../models/criteria/board-basis-setup-search-criteria';
import { ConfigLoader } from '@tc-core/util/framework';
import { DataKey, DataStoreService } from '../../../services/util/framework/data-store.service';
import { MasterDataRestService } from '../../../services/backend-consumers/master-data-handler-service/master-data-rest.service';
import { DMCCommon } from '../../../services/util/common/dmc-common';
import { TCO } from '../../../constants';
import { TcErrorType } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-error';
import { SortDirection } from '../../../models/helper/sort-direction';
import { tap } from 'rxjs/operators';
var EMPTY_CHAR = TC.FORMAT.EMPTY_CHAR;
import { TC } from '@tc-core/util/constants';
import { BoardBasisSetupService } from '../../../services/backend-consumers/setups/board-basis-setup.service';
var BoardBasisSetupComponent = /** @class */ (function (_super) {
    tslib_1.__extends(BoardBasisSetupComponent, _super);
    function BoardBasisSetupComponent(configLoader, boardBasisSetupService, dataStoreService, masterDataRestService, common) {
        var _this = _super.call(this) || this;
        _this.configLoader = configLoader;
        _this.boardBasisSetupService = boardBasisSetupService;
        _this.dataStoreService = dataStoreService;
        _this.masterDataRestService = masterDataRestService;
        _this.common = common;
        _this.colDefConfig = [];
        // search criteria obj. used to
        _this.boardBasisSetupSearchCriteria = new BoardBasisSetupSearchCriteria();
        _this.searchCriteriaObserver = new Subscription();
        _this.allBoadBasisSetupCodes = [];
        _this.getCategoryText = function (params) {
            if (params && params.data) {
                return params.data;
            }
            return EMPTY_CHAR;
        };
        return _this;
    }
    BoardBasisSetupComponent.prototype.ngOnInit = function () {
        this.colDefConfig = this.configLoader.configurations.get(TCO.CONF.CONF_BOARD_BASIS_SETUP);
        this.subscribeSearchCriteria();
    };
    /**
     * subscribe search criteria changes in search bar
     * then force grid to refresh data
     * grid refresh will be call getRaws method which is using this subscribed criteria for backend call
     */
    BoardBasisSetupComponent.prototype.subscribeSearchCriteria = function () {
        var _this = this;
        this.searchCriteriaObserver = this.dataStoreService.get(DataKey.boardBasisSetupSearchCriteria).subscribe(function (value) {
            _this.boardBasisSetupSearchCriteria = value;
            _this.setupGrid.runForceSearch();
        });
    };
    BoardBasisSetupComponent.prototype.isInvalidRow = function (params) {
        var data = params.data;
        if (data) {
            var invalid = true;
            if (data.code &&
                data.name) {
                invalid = false;
            }
            return invalid;
        }
        else {
            return false;
        }
    };
    BoardBasisSetupComponent.prototype.isUnsavedRow = function (row) {
        return !row.code;
    };
    BoardBasisSetupComponent.prototype.deleteRow = function (row) {
        if (row && row.code) {
            return this.boardBasisSetupService.deleteRow(row.code);
        }
        else {
            return of('success');
        }
    };
    BoardBasisSetupComponent.prototype.saveRow = function (row, event) {
        console.log(event);
        if (event.data.code === event.newValue) {
            return this.boardBasisSetupService.updateRow(event.oldValue, row);
        }
        else {
            if (row.unsaved) {
                if (this.allBoadBasisSetupCodes.includes(row.code)) {
                    this.common.showSnackBar('This Code Already Exist. Please Select a Different Code', 3000, TcErrorType.TYPE.ERROR);
                }
                else {
                    return this.boardBasisSetupService.saveRow(row);
                }
            }
            else {
                return this.boardBasisSetupService.saveRow(row);
            }
        }
    };
    BoardBasisSetupComponent.prototype.getRows = function (params) {
        var _this = this;
        console.log('fetch requesting for ' + params.startRow + ' to ' + params.endRow);
        console.log(params.sortModel);
        var pageSize = params.endRow - params.startRow;
        if (!this.boardBasisSetupSearchCriteria) {
            this.boardBasisSetupSearchCriteria = new BoardBasisSetupSearchCriteria();
        }
        this.boardBasisSetupSearchCriteria.start = params.startRow;
        this.boardBasisSetupSearchCriteria.size = pageSize;
        if (params.sortModel && params.sortModel[0]) {
            this.boardBasisSetupSearchCriteria.sortBy = params.sortModel[0].colId;
            if (params.sortModel[0].sort === SetupGridComp.GRID_SORT_ASCENDING) {
                this.boardBasisSetupSearchCriteria.sortDirection = SortDirection.ASC;
            }
            else {
                this.boardBasisSetupSearchCriteria.sortDirection = SortDirection.DESC;
            }
        }
        return this.boardBasisSetupService.getRows(this.boardBasisSetupSearchCriteria)
            .pipe(tap(function (data) {
            return _this.dataStoreService.set(DataKey.boardBasisSetupSearchResultsForCriteria, data);
        }));
    };
    BoardBasisSetupComponent.prototype.createNewRow = function () {
        return { unsaved: true };
    };
    BoardBasisSetupComponent.prototype.ngOnDestroy = function () {
        if (this.searchCriteriaObserver) {
            this.searchCriteriaObserver.unsubscribe();
        }
    };
    return BoardBasisSetupComponent;
}(SetupGridComp));
export { BoardBasisSetupComponent };
