import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {MatIconModule, MatTooltipModule} from '@angular/material';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { MatMenuModule } from '@angular/material/menu';
import {AgGridModule} from 'ag-grid-angular';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import {ActionsComponent} from './actions/actions.component';
import {CheckBoxRendererEditorComponent} from './check-box-selector-renderer/check-box-renderer-editor.component';
import {CheckBoxSelectorComponent} from './check-box-selector/check-box-selector.component';
import {RowErrorIndicatorComponent} from './row-error-indicator/row-error-indicator.component';
import {TemplateRendererComponent} from './template-renderer/template-renderer.component';
import {TimeEditorRendererComponent} from './time-editor-renderer/time-editor-renderer.component';
import {GridToolTipComponent} from './tooltip/grid-tool-tip.component';
import {TranslateModule} from '@ngx-translate/core';
import {NoteEditorInputComponent} from './note-editor-input/note-editor-input.component';
import {NumericEditorComponent} from './numeric-editor/numeric-editor.component';

@NgModule({
    declarations: [
        TemplateRendererComponent,
        ActionsComponent,
        CheckBoxSelectorComponent,
        CheckBoxRendererEditorComponent,
        RowErrorIndicatorComponent,
        TimeEditorRendererComponent,
        GridToolTipComponent,
        NoteEditorInputComponent,
        NumericEditorComponent
    ],
    imports: [
        CommonModule,
        AgGridModule,
        MatIconModule,
        MatCheckboxModule,
        FormsModule,
        MatTooltipModule,
        MatButtonModule,
        NgxMaterialTimepickerModule,
        TranslateModule.forChild(),
        MatMenuModule
    ],
    exports: [
        TemplateRendererComponent,
        ActionsComponent,
        CheckBoxSelectorComponent,
        CheckBoxRendererEditorComponent,
        TimeEditorRendererComponent,
        GridToolTipComponent,
        NoteEditorInputComponent
    ],
    entryComponents: [
        NoteEditorInputComponent,
        NumericEditorComponent,
        CheckBoxRendererEditorComponent
    ]
})
export class AgGridCustomRenderersModule {
}
