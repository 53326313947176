import { Subject } from 'rxjs';
import { NotificationToastConfig, NotificationToastEvent, NotificationToastEventType } from './notification-toast-model';
import * as i0 from "@angular/core";
import * as i1 from "./notification-toast-model";
var NotificationToastService = /** @class */ (function () {
    function NotificationToastService(config) {
        this.config = config;
        this.eventSource = new Subject();
        this.events = this.eventSource.asObservable();
        this.toastId = 0;
    }
    NotificationToastService.prototype.add = function (notification) {
        var notificationToastEvent = new NotificationToastEvent();
        if (!notification.id) {
            notification.id = ++this.toastId;
        }
        notificationToastEvent.toast = notification;
        if (!notification.timeout) {
            notification.timeout = this.config.timeout;
        }
        notificationToastEvent.type = NotificationToastEventType.ADD;
        this.pushEvent(notificationToastEvent);
    };
    NotificationToastService.prototype.remove = function (id) {
        var notificationToastEvent = new NotificationToastEvent();
        notificationToastEvent.toastId = id;
        notificationToastEvent.type = NotificationToastEventType.REMOVE;
        this.pushEvent(notificationToastEvent);
    };
    NotificationToastService.prototype.clearAll = function () {
        var notificationToastEvent = new NotificationToastEvent();
        notificationToastEvent.type = NotificationToastEventType.REMOVE_ALL;
        this.pushEvent(notificationToastEvent);
    };
    NotificationToastService.prototype.pushEvent = function (event) {
        if (this.eventSource) {
            this.eventSource.next(event);
        }
    };
    NotificationToastService.ngInjectableDef = i0.defineInjectable({ factory: function NotificationToastService_Factory() { return new NotificationToastService(i0.inject(i1.NotificationToastConfig)); }, token: NotificationToastService, providedIn: "root" });
    return NotificationToastService;
}());
export { NotificationToastService };
