
  <div class="container-fluid cost-break-down">
    <div class="row" *ngIf="!summaryCardData?.trsSupplier?.unitFare">
      <div class="cost-break-down__detail-view">
        <div class="fixed-column">
          <!-- header -->
          <div class="row table-header pbc-item-header item-header">
            <div class="heading item-header__content"></div>
          </div>

          <!-- body -->
          <div class="row pbc-item-header item-header">
            <div class="heading item-header__content item-header__price">
              <span>{{'Adult' | translate}}</span>
            </div>
          </div>
          <div class="row pbc-item-header item-header">
            <div class="heading item-header__content item-header__price">
              <span>{{'Child' | translate}}</span>
            </div>
          </div>
          <div class="row pbc-item-header item-header">
            <div class="heading item-header__content item-header__price">
              <span>{{'Infant' | translate}}</span>
            </div>
          </div>
          <div class="row pbc-item-header item-header">
            <div class="heading item-header__content item-header__price">
              <span>{{'Grand Total' | translate}}</span>
            </div>
          </div>
        </div>
        <div class="fixed-column">

          <!-- header -->
          <div class="row table-price-field">
            <div class="item-header__content">{{'Passenger Count' | translate}}</div>
          </div>

          <!-- body -->
          <div class="row table-price-field item-header">
            <div class="item-header__content item-header__price">
              <span>{{adultCount}}</span>
            </div>
          </div>
          <div class="row table-price-field item-header">
            <div class="item-header__content item-header__price">
              <span>{{childCount}}</span>
            </div>
          </div>
          <div class="row table-price-field item-header">
            <div class="item-header__content item-header__price">
              <span>{{infantCount}}</span>
            </div>
          </div>
          <div class="row table-price-field item-header">
            <div class="item-header__content item-header__price">
            </div>
          </div>
        </div>

        <div class="fixed-column">

          <!-- header -->
          <div class="row table-price-field">
            <div class="item-header__content">{{'Cost' | translate}}</div>
          </div>

          <!-- body -->
          <div class="row table-price-field item-header">
            <div class="assign-panel-table__price">
              <div class="payment__info-currency-input assign-panel-table__price-input">
                <!--<tc-currency-field
                        [amount]="summaryCardData?.supplier?.resourceCostSummaries[0]?.adultFare"
                        [showPreviousAmount]="false"
                        [disableRevert]="true"
                        formControlName="adult"
                        [currencyFormat]="currencyFormat"
                        [readOnly]="false"
                        (onInputValueChange)="onAdultFareChange($event)">
                </tc-currency-field>-->
                <div class="item-header__content item-header__price assign-panel-table__price">
                  <span>{{summaryCardData?.trsSupplier?.adultFare | tcCurrencyPipe:currencyFormat?.currencyCode:"code":currencyFormat?.pattern}}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="row table-price-field item-header">
            <div class="assign-panel-table__price">
              <div class="payment__info-currency-input assign-panel-table__price-input">
                <!--<tc-currency-field
                        [amount]="summaryCardData?.supplier?.resourceCostSummaries[0]?.childFare"
                        [showPreviousAmount]="false"
                        [disableRevert]="true"
                        formControlName="child"
                        [currencyFormat]="currencyFormat"
                        [readOnly]="false"
                        (onInputValueChange)="onChildFareChange($event)">
                </tc-currency-field>-->
                <div class="item-header__content item-header__price assign-panel-table__price">
                  <span>{{summaryCardData?.trsSupplier?.childFare | tcCurrencyPipe:currencyFormat?.currencyCode:"code":currencyFormat?.pattern}}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="row table-price-field item-header">
            <div class="assign-panel-table__price">
              <div class="payment__info-currency-input assign-panel-table__price-input">
                <!--<tc-currency-field
                        [amount]="summaryCardData?.supplier?.resourceCostSummaries[0]?.infantFare"
                        [showPreviousAmount]="false"
                        [disableRevert]="true"
                        formControlName="infant"
                        [currencyFormat]="currencyFormat"
                        [readOnly]="false"
                        (onInputValueChange)="onInfantFareChange($event)">
                </tc-currency-field>-->
                <div class="item-header__content item-header__price assign-panel-table__price">
                  <span>{{summaryCardData?.trsSupplier?.infantFare | tcCurrencyPipe:currencyFormat?.currencyCode:"code":currencyFormat?.pattern}}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="row table-price-field item-header">
            <div class="item-header__content item-header__price">
            </div>
          </div>
        </div>
        <div class="fixed-column">

          <!-- header -->
          <div class="row table-price-field">
            <div class="item-header__content">{{'Total' | translate}}</div>
          </div>

          <!-- body -->
          <div class="row table-price-field">
            <div class="item-header__content item-header__price assign-panel-table__price">
              <span>{{assignedVehicle?.vehicleSupplier?.adultFare | tcCurrencyPipe:currencyFormat?.currencyCode:"code":currencyFormat?.pattern}}</span>
            </div>
          </div>
          <div class="row table-price-field">
            <div class="item-header__content item-header__price assign-panel-table__price">
              <span>{{assignedVehicle?.vehicleSupplier?.childFare| tcCurrencyPipe:currencyFormat?.currencyCode:"code":currencyFormat?.pattern}}</span>
            </div>
          </div>
          <div class="row table-price-field">
            <div class="item-header__content item-header__price assign-panel-table__price">
              <span>{{assignedVehicle?.vehicleSupplier?.infantFare| tcCurrencyPipe:currencyFormat?.currencyCode:"code":currencyFormat?.pattern}}</span>
            </div>
          </div>
          <div class="row table-price-field">
            <div class="item-header__content item-header__price assign-panel-table__price-grand-total">
              <span>{{assignedVehicle?.vehicleSupplier?.totalCost| tcCurrencyPipe:currencyFormat?.currencyCode:"code":currencyFormat?.pattern}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row"  *ngIf="summaryCardData?.trsSupplier?.unitFare">
      <div class="cost-break-down__detail-view">
        <div class="fixed-column">
          <!-- header -->
          <div class="row table-header pbc-item-header item-header">
            <div class="heading item-header__content "></div>
          </div>

          <!-- body -->
          <div class="row pbc-item-header item-header">
            <div class="heading item-header__content item-header__price">
              <span>{{'Unit' | translate}}</span>
            </div>
          </div>
          <div class="row pbc-item-header item-header">
            <div class="heading item-header__content item-header__price">
              <span>{{'Grand Total' | translate}}</span>
            </div>
          </div>
        </div>
        <div class="fixed-column">

          <!-- header -->
          <div class="row table-price-field">
            <div class="item-header__content">{{'Passenger Count' | translate}}</div>
          </div>

          <!-- body -->
          <div class="row table-price-field item-header">
            <div class="item-header__content">
              <span>{{passengerCount}}</span>
            </div>
          </div>
          <div class="row table-price-field item-header">
            <div class=" item-header__content">
            </div>
          </div>
        </div>

        <div class="fixed-column">

          <!-- header -->
          <div class="row table-price-field ">
            <div class="item-header__content">{{'Cost' | translate}}</div>
          </div>

          <!-- body -->
          <div class="row table-price-field item-header">
            <div class="assign-panel-table__price">
              <!--<div class="payment__info-currency-input assign-panel-table__price-input">
                <tc-currency-field
                        [amount]="summaryCardData?.supplier?.resourceCostSummaries[0]?.unitFare"
                        [showPreviousAmount]="false"
                        [disableRevert]="true"
                        formControlName="unit"
                        [currencyFormat]="currencyFormat"
                        [readOnly]="false"
                        (onInputValueChange)="onUnitFareChange($event)">
                </tc-currency-field>
              </div>-->
              <div class="item-header__content item-header__price">
                <span>{{summaryCardData?.trsSupplier?.unitFare | tcCurrencyPipe:currencyFormat?.currencyCode:"code":currencyFormat?.pattern}}</span>
              </div>
            </div>
          </div>
          <div class="row table-price-field item-header">
            <div class="item-header__content item-header__price">
            </div>
          </div>
        </div>
        <div class="fixed-column">

          <!-- header -->
          <div class="row table-price-field">
            <div class="item-header__content">{{'Total' | translate}}</div>
          </div>

          <!-- body -->
          <div class="row table-price-field">
            <div class="item-header__content item-header__price">
              <span>{{summaryCardData?.trsSupplier?.unitFare | tcCurrencyPipe:currencyFormat?.currencyCode:"code":currencyFormat?.pattern}}</span>
            </div>
          </div>
          <div class="row table-price-field">
            <div class="item-header__content item-header__price assign-panel-table__price-grand-total">
              <span>{{summaryCardData?.trsSupplier?.totalCost| tcCurrencyPipe:currencyFormat?.currencyCode:"code":currencyFormat?.pattern}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12 col-sm-12  col-md-3 col-lg-3">
        <div class="tc-flex-box">
          <div class="tc-flex-item input-cage ">
            <div class="tc-content--wrap tc-input-view">
              <div
                      id="TRD_PER_INFO_COUNT"
                      class="tc-caption-txt txt-light">{{"Vehicle Capacity" | translate}}
              </div>
              <div class="tc-body1--txt">
                <span>{{summaryCardData?.capacity ? summaryCardData?.capacity : EMPTY_CHAR}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--<div class="col-xs-12 col-sm-12  col-md-3 col-lg-3">
        <div class="tc-flex-box">
          <div class="tc-flex-item input-cage ">
            <div class="tc-content&#45;&#45;wrap tc-input-view">
              <div
                      id="TRD_PER_INFO_NAME"
                      class="tc-caption-txt txt-light">{{"Vehicle Model" | translate}}
              </div>
              <div class="tc-body1&#45;&#45;txt">
                <span>{{summaryCardData?.data?.value?.vehicleModel ? summaryCardData?.data?.value?.vehicleModel : EMPTY_CHAR}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>-->
    </div>
  </div>
