import { environment } from '../environments/environment';
import * as i0 from "@angular/core";
var ConsoleToggleService = /** @class */ (function () {
    function ConsoleToggleService() {
    }
    ConsoleToggleService.prototype.disableConsoleInProduction = function () {
        if (environment.production) {
            console.warn("\uD83D\uDEA8 Console output is disabled on production!");
            console.log = function () { };
            // tslint:disable-next-line:no-console
            console.debug = function () { };
            console.warn = function () { };
            // tslint:disable-next-line:no-console
            console.info = function () { };
        }
    };
    ConsoleToggleService.ngInjectableDef = i0.defineInjectable({ factory: function ConsoleToggleService_Factory() { return new ConsoleToggleService(); }, token: ConsoleToggleService, providedIn: "root" });
    return ConsoleToggleService;
}());
export { ConsoleToggleService };
