import { Subject } from 'rxjs';
import { BackgroundTaskEvent } from '../../../models/background/background-task-event';
import { TaskStatus } from '../../../models/background/task-status';
import { BackgroundTaskApiService } from '../../backend-consumers/background/background-task-api.service';
import { ResponseUtil } from '../response/response-util.service';
import * as i0 from "@angular/core";
import * as i1 from "../../backend-consumers/background/background-task-api.service";
var BackgroundTaskService = /** @class */ (function () {
    function BackgroundTaskService(backgroundTaskApiService) {
        this.backgroundTaskApiService = backgroundTaskApiService;
        this.manualDocsToUiSubject = new Subject();
        this.docQueueDocsToUiSubject = new Subject();
        this.manualDocsToUi = this.manualDocsToUiSubject.asObservable();
        this.docQueueToUi = this.docQueueDocsToUiSubject.asObservable();
        this.manualDocsUserInputSubject = new Subject();
        this.manualDocsUserInput = this.manualDocsUserInputSubject.asObservable();
        this.taskCompleted = false;
        this.taskFailed = false;
        this.taskCancelled = false;
        this.taskRunning = false;
    }
    BackgroundTaskService.prototype.pushUiEvent = function (event) {
        if (this.manualDocsToUiSubject) {
            this.manualDocsToUiSubject.next(event);
        }
    };
    BackgroundTaskService.prototype.pushInput = function (event) {
        if (this.manualDocsUserInputSubject) {
            this.manualDocsUserInputSubject.next(event);
        }
    };
    BackgroundTaskService.prototype.startStatusChecking = function (task) {
        this.taskCompleted = false;
        this.taskFailed = false;
        this.taskCancelled = false;
        this.taskRunning = true;
        this.checkTaskStatusPeriodically(task);
    };
    BackgroundTaskService.prototype.cancelStatusChecking = function (task) {
        this.taskCancelled = true;
        this.taskRunning = false;
    };
    BackgroundTaskService.prototype.isTaskRunning = function () {
        return this.taskRunning;
    };
    BackgroundTaskService.prototype.checkTaskStatusPeriodically = function (task) {
        var _this = this;
        this.pushUiEvent(new BackgroundTaskEvent(task, null));
        this.pushUiEvent(new BackgroundTaskEvent(task, null));
        setTimeout(function () {
            if (!_this.taskCompleted && !_this.taskFailed) {
                _this.backgroundTaskApiService.getTask(task.taskId)
                    .subscribe(function (result) {
                    var taskLoaded = ResponseUtil.getFirstData(result);
                    if (taskLoaded.taskStatus === TaskStatus.FINISHED) {
                        _this.taskCompleted = true;
                        _this.taskRunning = false;
                    }
                    else if (taskLoaded.taskStatus === TaskStatus.FAILED) {
                        _this.taskRunning = false;
                        _this.taskFailed = true;
                    }
                    if (!_this.taskCancelled) {
                        _this.checkTaskStatusPeriodically(taskLoaded);
                    }
                });
            }
        }, 2000);
    };
    BackgroundTaskService.ngInjectableDef = i0.defineInjectable({ factory: function BackgroundTaskService_Factory() { return new BackgroundTaskService(i0.inject(i1.BackgroundTaskApiService)); }, token: BackgroundTaskService, providedIn: "root" });
    return BackgroundTaskService;
}());
export { BackgroundTaskService };
