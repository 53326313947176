import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material';
import { ContentStripModule } from '@tc/content-strip';
import { DirectivesModule } from '@tc/directives';
import { ExpandedContentCardModule } from '@tc/expanded-content-card';
import { PipesModule } from '@tc/pipes';
import { ResourceModule } from '@tc/resource';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { TimelineModule } from '../../../../../widgets/framework/cg-timeline/timeline/timeline.module';
import { ResultContentCardModule } from '../../../../../widgets/shared/result-content-card/result-content-card.module';
import { ResourceAllocationDetailViewComponent } from '../resource-allocation-detail-view/resource-allocation-detail-view.component';
import { ResourceAllocationPanelModule } from '../resource-allocation-panel.module';
import { ResourceAllocationTimelineComponent } from '../resource-allocation-timeline/resource-allocation-timeline.component';
import { AdvResourceAllocationSearchResultsComponent } from './adv-resource-allocation-search-results.component';
import {MatButtonModule} from "@angular/material/button";
import {MatTooltipModule} from "@angular/material/tooltip";
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
    declarations: [AdvResourceAllocationSearchResultsComponent,
        ResourceAllocationTimelineComponent,
        ResourceAllocationDetailViewComponent],
    exports: [
        AdvResourceAllocationSearchResultsComponent,
        ResourceAllocationTimelineComponent,
        ResourceAllocationDetailViewComponent
    ],
    imports: [
        CommonModule,
        PipesModule,
        DirectivesModule,
        ContentStripModule,
        ExpandedContentCardModule,
        ResourceModule,
        ResultContentCardModule,
        TimelineModule,
        NgxMaterialTimepickerModule,
        MatIconModule,
        MatButtonModule,
        MatTooltipModule,
        TranslateModule
    ]
})
export class AdvResourceAllocationSearchResultsModule { }
