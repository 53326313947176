import * as tslib_1 from "tslib";
import { AbstractSearchCriteria } from './abstract-search-criteria';
var BoardBasisSetupSearchCriteria = /** @class */ (function (_super) {
    tslib_1.__extends(BoardBasisSetupSearchCriteria, _super);
    function BoardBasisSetupSearchCriteria() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return BoardBasisSetupSearchCriteria;
}(AbstractSearchCriteria));
export { BoardBasisSetupSearchCriteria };
