/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "../../../widgets/shared/data-grid/data-grid.component.ngfactory";
import * as i3 from "../../../widgets/shared/data-grid/data-grid.component";
import * as i4 from "./locations-setup.component";
import * as i5 from "../../../services/util/mapper/grid-data-mapper.service";
import * as i6 from "../../../services/util/ui/table.service";
import * as i7 from "@tc-core/util/framework/event-manager.service";
var styles_LocationsSetupComponent = [];
var RenderType_LocationsSetupComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_LocationsSetupComponent, data: {} });
export { RenderType_LocationsSetupComponent as RenderType_LocationsSetupComponent };
export function View_LocationsSetupComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "tc-item-wrapper"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "div", [["class", "tc-item-header tc-flex-box"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "div", [["class", "tc-flex-item tc-item-header__primary"]], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(5, 0, null, null, 0, "div", [["class", "tc-flex-item tc-item-header__actions tc-icon-block"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 1, "tc-data-grid", [], null, [[null, "editClick"], [null, "revertClick"], [null, "saveClick"], [null, "deleteRowID"], [null, "deleteRow"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("editClick" === en)) {
        var pd_0 = (_co.onEditClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("revertClick" === en)) {
        var pd_1 = (_co.onRevertClick($event) !== false);
        ad = (pd_1 && ad);
    } if (("saveClick" === en)) {
        var pd_2 = (_co.onSaveClick($event) !== false);
        ad = (pd_2 && ad);
    } if (("deleteRowID" === en)) {
        var pd_3 = (_co.onDeleteRowClick($event) !== false);
        ad = (pd_3 && ad);
    } if (("deleteRow" === en)) {
        var pd_4 = (_co.onDeleteRow($event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, i2.View_DataGridComponent_0, i2.RenderType_DataGridComponent)), i0.ɵdid(7, 638976, null, 0, i3.DataGridComponent, [], { clickedRow: [0, "clickedRow"], unsavedRows: [1, "unsavedRows"], showEditIcon: [2, "showEditIcon"], showSaveIcon: [3, "showSaveIcon"], showDeleteIcon: [4, "showDeleteIcon"], readOnly: [5, "readOnly"], columnsArray: [6, "columnsArray"], columnNamesArray: [7, "columnNamesArray"], dataSource: [8, "dataSource"], savedDataSource: [9, "savedDataSource"] }, { deleteRow: "deleteRow", deleteRowID: "deleteRowID", editClick: "editClick", revertClick: "revertClick", saveClick: "saveClick" })], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.clickedRow; var currVal_2 = _co.unsavedRows; var currVal_3 = true; var currVal_4 = true; var currVal_5 = true; var currVal_6 = true; var currVal_7 = _co.displayedColumns; var currVal_8 = _co.columns; var currVal_9 = _co.dataSource; var currVal_10 = _co.savedDataSource; _ck(_v, 7, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10); }, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 3, 0, i0.ɵnov(_v, 4).transform("Locations")); _ck(_v, 3, 0, currVal_0); }); }
export function View_LocationsSetupComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-locations-setup", [], null, null, null, View_LocationsSetupComponent_0, RenderType_LocationsSetupComponent)), i0.ɵdid(1, 114688, null, 0, i4.LocationsSetupComponent, [i5.GridDataMapperService, i6.TableService, i7.EventManager], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LocationsSetupComponentNgFactory = i0.ɵccf("tc-locations-setup", i4.LocationsSetupComponent, View_LocationsSetupComponent_Host_0, {}, {}, []);
export { LocationsSetupComponentNgFactory as LocationsSetupComponentNgFactory };
