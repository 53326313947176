import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import * as StompJs from '@stomp/stompjs';
import {StompHeaders} from '@stomp/stompjs';
import {TcApiError} from '@tc-core/model/it/codegen/tbx/ext/errors/tc-api-error';
import {TcHttpError} from '@tc-core/model/it/codegen/tbx/ext/errors/tc-http-error';
import {LEVEL} from '@tc-core/model/it/codegen/ui/framework/dialog-model';
import {ModalData} from '@tc-core/model/it/codegen/ui/framework/modal-data';
import {ConfigLoader, UserJourneyManager} from '@tc-core/util/framework';
import {CommonHelper} from '@tc-core/util/helpers/common-helper.service';
import {SpinnerService} from '@tc-core/util/ui';
import {FocusViewService} from '@tc/focus-view/focus-view.service';
import * as moment from 'moment';
import {BehaviorSubject, Observable} from 'rxjs';

import * as SockJS from 'sockjs-client';
import {TCO} from '../../../constants';
import {NotificationToastModel} from '../../../widgets/framework/notification-toast/notification-toast-model';
import {NotificationToastService} from '../../../widgets/framework/notification-toast/notification-toast.service';
import {Criteria} from '../../../models/criteria/criteria';
import {NotificationSearchCriteria} from '../../../models/criteria/notification-search-criteria';
import {ReservationSearchCriteria} from '../../../models/criteria/reservation-search-criteria';
import {GroupBy} from '../../../models/helper/group-by';
import {SortDirection} from '../../../models/helper/sort-direction';
import {BroadcastReceiver} from '../../../models/notification/broadcastReceiver';
import {GroupMsgThread} from '../../../models/notification/group-msg-thread';
import {MessageThread} from '../../../models/notification/message-thread';
import {
    NotificationData,
    NotificationReceiver,
    NotificationType,
    TypeString
} from '../../../models/notification/notification-data';
import {NotificationStateChangeData} from '../../../models/notification/notification-state-change-data';
import {ResourceType} from '../../../models/reservation/assignment';
import {OPResponseWrapper} from '../../../models/response/op-response-wrapper';
import {UserServiceProfile} from '../../../models/user/user-service-profile';
import {
    ResAvailabilityCalendarFocusViewComponent
} from '../../../widgets/shared/res-availability-calendar-focus-view/res-availability-calendar-focus-view.component';
import {DMCCommon} from '../../util/common/dmc-common';
import {DataKey, DataStoreService} from '../../util/framework/data-store.service';
import {DMCQueryParamsService} from '../../util/framework/dmc-query-params.service';
import {RequestService} from '../../util/framework/request.service';
import {ResponseUtil} from '../../util/response/response-util.service';
import {DMCLocalStorageService} from '../../util/system/dmc-local-storage.service';
import {GenericResourceService} from '../supplier-service/generic-resource.service';
import {UrlPaths} from '../url-paths';

@Injectable({
    providedIn: 'root'
})
export class NotificationService {

    constructor(
        private queryParamsService: DMCQueryParamsService,
        private requestService: RequestService,
        private http: HttpClient,
        private configLoader: ConfigLoader,
        private dataStoreService: DataStoreService,
        private common: DMCCommon,
        private notificationToastService: NotificationToastService,
        private userJourneyManager: UserJourneyManager,
        private localStorageService: DMCLocalStorageService,
        private focusViewService: FocusViewService,
        private genericResourceService: GenericResourceService,
        private commonHelper: CommonHelper,
        private spinnerService: SpinnerService
    ) {
        this.onConnect();
    }

    private client: StompJs.Client;
    notificationUrl: string;

    private updateTotalUnreadMessageCountField = new BehaviorSubject<any>(0);
    totalUnreadMessageCount = this.updateTotalUnreadMessageCountField.asObservable();

    private updateUnreadMessageList = new BehaviorSubject<MessageThread>(null);
    readMessageThread = this.updateUnreadMessageList.asObservable();

    enableWebSocket = false;

    // web socket implementation

    onConnect() {
        const configs = this.configLoader.configurations.get(TCO.CONF.CONF_MESSAGING);
        if (configs) {
            this.enableWebSocket = configs['ENABLE-WEBSOCKET'];
        }

        // websocket connect
        if (this.enableWebSocket) {
            this.notificationUrl = this.configLoader.configurations.get(TCO.CONF.CONF_ENDPOINT)[UrlPaths.OP_NOTIFICATION_URL_KEY];

            if (!this.client || this.client.connected) {
                this.client = new StompJs.Client({
                    webSocketFactory: () => new SockJS(this.notificationUrl + UrlPaths.WEB_SOCKET),
                    debug: (msg: string) => console.log(msg)
                });

                this.client.onConnect = () => {

                    this.client.subscribe(UrlPaths.GET_NOTIFICATIONS, (response) => {
                        const data: NotificationData[] = JSON.parse(response.body);
                        if (data && data.length > 0) {
                            const notificationToastModel = new NotificationToastModel();
                            const notification = data[0];
                            notificationToastModel.title = TypeString[notification.type];
                            if (notification.type === NotificationType.RESOURCE_COMMUNICATION) {
                                notificationToastModel.body = notification.resourceName;
                            } else {
                                notificationToastModel.body = notification.description;
                            }
                            notificationToastModel.timeout = 5000;
                            notificationToastModel.matIcon = 'notifications';
                            notificationToastModel.sound = 'assets/sounds/notification.mp3';
                            notificationToastModel.rawData = notification;
                            notificationToastModel.onClick = (item: NotificationToastModel) => {
                                this.onClickNotificationPopupClick(item);
                            };
                            if (notification.type !== NotificationType.RESOURCE_COMMUNICATION) {
                                this.notificationToastService.add(notificationToastModel);
                                this.dataStoreService.set(DataKey.newNotifications, data, true);
                            }
                        }
                    });
                    this.client.subscribe(UrlPaths.GET_MESSAGES, (response) => {
                        const data: NotificationData[] = JSON.parse(response.body);
                        if (data && data.length > 0) {
                            if (!data[0].sendingDmc) {
                                const notificationToastModel = new NotificationToastModel();
                                const message = data[0];
                                notificationToastModel.title = message.resourceName;
                                notificationToastModel.body = message.description;
                                notificationToastModel.timeout = 5000;
                                notificationToastModel.matIcon = 'chat';
                                notificationToastModel.sound = 'assets/sounds/message.mp3';
                                notificationToastModel.rawData = message;
                                notificationToastModel.onClick = (item: NotificationToastModel) => {
                                    this.onClickNotificationPopupClick(item);
                                };
                                this.notificationToastService.add(notificationToastModel);
                            }
                            this.dataStoreService.set(DataKey.newMessages, data, true);
                        }
                    });

                    this.onStart();

                };

                this.client.onStompError = (frame) => {
                    console.error(frame.headers.message);
                    console.error('Details:', frame.body);
                };

                this.client.activate();
            }
        }
    }

    onClickNotificationPopupClick(notification
                                      :
                                      NotificationToastModel
    ) {
        if (notification && notification.rawData) {
            const data: NotificationData = notification.rawData;
            if (data.indicator === 'message') {
                this.openMessageQueue(data);
            } else if (data.type === NotificationType.NEW_USER_REGISTRATION) {
                this.openUserQueue(data);
            } else if (data.type === NotificationType.LEAVE_REQUEST) {
                this.onResCalendarClick(data);
            } else if (data.type === NotificationType.ASSIGNMENT_UPDATE) {
                this.openServiceQueue(data);
            }
        }
    }

    openMessageQueue(notification
                         :
                         NotificationData
    ):
        any {
        this.localStorageService.delete('resourceId');
        this.localStorageService.delete('job');
        this.localStorageService.store(
            'resourceId',
            notification.sendingResourceId ? notification.sendingResourceId.toString() : ''
        );
        this.localStorageService.store('isAssignment', 'false');
        this.userJourneyManager.goForKey('BEGIN_MESSAGE_QUEUE');
    }

    openNotificationQueue(notification
                              :
                              NotificationData
    ) {
        this.userJourneyManager.goForKey('BEGIN_NOTIFICATION_QUEUE');
    }

    openUserQueue(notification
                      :
                      NotificationData
    ):
        any {
        this.userJourneyManager.goForKey('BEGIN_RESOURCE_ACCEPTANCE_QUEUE');
    }

    onResCalendarClick(notification
                           :
                           NotificationData
    ):
        any {
        if (notification.resourceId) {
            this.retrieveResourceById(notification);
        }
    }

    retrieveResourceById(notification
                             :
                             NotificationData
    ):
        any {
        this.genericResourceService.retrieveResourceById(notification.resourceId)
            .subscribe(
                res => {
                    if (this.commonHelper.dataValidity(res)) {
                        this.spinnerService.hide();
                        const resource = ResponseUtil.getFirstData(res);
                        this.openResCalendar(resource);
                    } else if (res instanceof TcApiError) {
                        this.spinnerService.hide();
                    } else if (res instanceof TcHttpError) {
                        this.spinnerService.hide();
                    }
                }
            );
        return null;
    }

    openResCalendar(resource
                        :
                        any
    ):
        any {
        const resourceName = resource.resourceType === ResourceType.vehicle ?
            resource.vehicle.vehicleName :
            resource.genericResource.firstName;
        const navConfigData = this.configLoader.configurations.get(TCO.CONF.CONF_PAGE_SECTION_CONFIG)
            .availability_calendar_side_nav.resource_level;
        setTimeout(() => {
            const fabActions = [];
            const dataObject = new ModalData(
                LEVEL.SUCCESS,
                'Availability Calendar',
                null,
                null,
                'season-setup-focus-view',
                ResAvailabilityCalendarFocusViewComponent,
                {
                    resourceSummary: resource,
                    navConfig: navConfigData
                },
                {label: 'close'},
                '',
                '',
                fabActions,
                '',
                resource ?
                    resourceName + ' | Supplier: ' + (resource.supplierName) :
                    ''
            );

            // this.focusViewCloseSubject = this.dataStore.get(DataKey.availabilityCalendarFocusViewClose).subscribe(c => {
            //     if (c) {
            //     }
            // });
            this.focusViewService.confirm(dataObject).subscribe(res => {
                if (res) {
                    console.log(res);
                }
            });
        }, 0);
    }

    openServiceQueue(notification
                         :
                         NotificationData
    ):
        any {
        if (notification.serviceType === ResourceType.vehicle || notification.serviceType === ResourceType.driver) {
            if (notification.assignmentId && notification.assignmentId > 0) {
                const criteria = new ReservationSearchCriteria();
                criteria.serviceType = 'TRS';
                criteria.assignmentId = notification.assignmentId;
                criteria.startDate = moment().startOf('year').format('YYYY-MM-DD');
                criteria.endDate = moment().add(1, 'year').endOf('year').format('YYYY-MM-DD');
                criteria.groupBy = GroupBy.INTERVAL;
                criteria.interval = 1;
                criteria.filterStartTime = '00:00';
                criteria.sortDirection = SortDirection.ASC;
                this.localStorageService.store(TCO.AppData.RESERVATION_SEARCH_CRITERIA, criteria);
            }
            this.userJourneyManager.goForKey('GO_TO_TRANSPORT_ALLOCATION_QUEUE');
        } else {
            if (notification.assignmentId && notification.assignmentId > 0) {
                const criteria = new ReservationSearchCriteria();
                criteria.serviceType = 'GEN';
                criteria.assignmentId = notification.assignmentId;
                criteria.startDate = moment().startOf('year').format('YYYY-MM-DD');
                criteria.endDate = moment().add(1, 'year').endOf('year').format('YYYY-MM-DD');
                this.localStorageService.store(TCO.AppData.GEN_RESERVATION_SEARCH_CRITERIA, criteria);
            }
            this.userJourneyManager.goForKey('GO_TO_GENERIC_ALLOCATION_QUEUE');
        }
    }

    disconnectClicked() {
        if (this.client && this.client.connected) {
            this.client.deactivate();
            this.client = null;
            console.info('disconnected :-/');
        }
    }

    onStart() {
        if (this.client && this.client.connected) {
            const h1: StompHeaders = new StompHeaders();
            h1.isDmc = 'true';
            this.client.publish({destination: UrlPaths.START_LISTENING, headers: h1});
            // this.sendDummyMessage();
            // this.receiveDummyMsg();
        }
    }

    sendDummyMessage() {
        const notification = new NotificationData();
        notification.type = NotificationType.RESOURCE_COMMUNICATION;
        notification.description = 'Hello bro';
        notification.indicator = 'Message';
        notification.receivingResourceId = 2222;
        notification.sendingDmc = true;
        notification.resourceName = 'Mafaris';
        notification.receiver = NotificationReceiver.ONE_RESOURCE;
        this.sendMessage(notification);
    }

    receiveDummyMsg() {
        const notification = new NotificationData();
        notification.type = NotificationType.RESOURCE_COMMUNICATION;
        notification.description = 'My vehicle was stolen';
        notification.indicator = 'Message';
        // notification.receivingResourceId = 361;
        notification.sendingResourceId = 24;
        // notification.sendingResourceId = 361;
        notification.receivingDmc = true;
        notification.resourceName = 'Yashoda Sanjeewi';
        notification.receiver = NotificationReceiver.DMC;
        this.sendMessage(notification);
    }

    sendBroadCast() {
        const notification = new NotificationData();
        notification.type = NotificationType.RESOURCE_BROADCAST;
        notification.description = 'BroadCast Test';
        let broadCastReceiver1: BroadcastReceiver = new BroadcastReceiver();
        broadCastReceiver1.resourceId = 2222;
        broadCastReceiver1.name = 'Mafaris';
        let broadCastReceiver2: BroadcastReceiver = new BroadcastReceiver();
        broadCastReceiver2.resourceId = 362;
        broadCastReceiver2.name = 'Sajee lorenzo';
        notification.receivingResourcesIds = [];
        notification.receivingResourcesIds.push(broadCastReceiver1);
        notification.receivingResourcesIds.push(broadCastReceiver2);
        notification.indicator = 'Message';
        notification.sendingDmc = true;
        notification.receiver = NotificationReceiver.ALL_RESOURCES;
        this.sendBroadCastMessage(notification);
    }

    onStop() {
        if (this.client && this.client.connected) {
            this.client.publish({destination: UrlPaths.STOP_LISTENING});
        }
    }

    sendMessage(notification
                    :
                    NotificationData
    ):
        any {
        if (this.client && this.client.connected) {
            this.client.publish({destination: UrlPaths.SEND_MESSAGE, body: JSON.stringify(notification)});
        }
    }

    sendBroadCastMessage(notification
                             :
                             NotificationData
    ):
        any {
        if (this.client && this.client.connected) {
            this.client.publish({destination: UrlPaths.SEND_BROADCAST_MESSAGE, body: JSON.stringify(notification)});
        }
    }

    getResourceAcceptanceDataFromMock()
        :
        Observable<OPResponseWrapper<UserServiceProfile>> {
        return this.http.get<OPResponseWrapper<UserServiceProfile>>(
            'assets\\mockdata\\resource-acceptance-data.json');
    }

    saveGroupThread(saveGroupThread
                        :
                        GroupMsgThread
    ):
        Observable<OPResponseWrapper<any>> {
        return this.requestService.post<OPResponseWrapper<any>>(
            UrlPaths.OP_NOTIFICATION_URL_KEY,
            [UrlPaths.OP_RES_VERSION, UrlPaths.MESSAGES_GROUP_SAVE],
            saveGroupThread
        );
    }

    getGroupThread(groupThreadId
                       :
                       string
    ):
        Observable<OPResponseWrapper<Criteria>> {
        let params = this.queryParamsService.asQueryParams(groupThreadId);
        return this.requestService.get<OPResponseWrapper<Criteria>>(
            UrlPaths.OP_NOTIFICATION_URL_KEY,
            [UrlPaths.OP_RES_VERSION, UrlPaths.MESSAGES],
        );
    }

    public searchNotifications(criteria
                            :
                            NotificationSearchCriteria
    ):
        Observable<OPResponseWrapper<NotificationData>> {
        criteria.dmc = true;
        criteria.type = criteria.type && criteria.type === "null" ? "" : criteria.type;
        const params = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.getWithDataKey(
            DataKey.notificationSearchResultsFromService,
            UrlPaths.OP_NOTIFICATION_URL_KEY,
            [UrlPaths.OP_NOTIFICATION_VERSION, UrlPaths.NOTIFICATIONS],
            params
        );
    }

    public searchMessageThreads(criteria
                             :
                             NotificationSearchCriteria
    ):
        Observable<OPResponseWrapper<MessageThread>> {
        criteria.dmc = true;
        criteria.message = true;
        const params = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.getWithDataKey(
            DataKey.messageSearchResultsFromService,
            UrlPaths.OP_NOTIFICATION_URL_KEY,
            [UrlPaths.OP_NOTIFICATION_VERSION, UrlPaths.MESSAGES],
            params
        );
    }

    public loadMore(criteria
                 :
                 NotificationSearchCriteria
    ):
        Observable<OPResponseWrapper<NotificationData>> {
        criteria.dmc = true;
        criteria.indicator = 'message';
        const params = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.get(
            UrlPaths.OP_NOTIFICATION_URL_KEY,
            [UrlPaths.OP_NOTIFICATION_VERSION, UrlPaths.MORE_MESSAGES],
            params
        );
    }

    public changeNotificationState(obj
                                :
                                NotificationStateChangeData
    ) {
        return this.requestService.post(
            UrlPaths.OP_NOTIFICATION_URL_KEY,
            [UrlPaths.OP_NOTIFICATION_VERSION, UrlPaths.NOTIFICATION_STATE_CHANGE],
            obj
        );
    }

    public updateTotalUnreadMessageCount(updateCount) {
        this.updateTotalUnreadMessageCountField.next(updateCount);
    }

    public updateUnReadMessageList(thread) {
        this.updateUnreadMessageList.next(thread);
    }
}
