import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {VehicleAmenitiesComponent} from './vehicle-amenities.component';
import {TranslateModule} from '@ngx-translate/core';
import {
  MatAutocompleteModule,
  MatFormFieldModule,
  MatInputModule,
  MatOptionModule,
  MatSelectModule,
  MatSlideToggleModule
} from '@angular/material';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {PipesModule} from '@tc/pipes';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    MatInputModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
    MatSelectModule,
    FormsModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    PipesModule,
    MatOptionModule
  ],
  declarations: [VehicleAmenitiesComponent],
  exports: [VehicleAmenitiesComponent]
})
export class VehicleAmenitiesModule {
}
