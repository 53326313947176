import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {UserProfileSearchCriteria} from '../../../models/criteria/user-profile-search-criteria';
import {UserProfile} from '../../../models/user/user-profile';
import {ApprovalStatus, UserServiceProfile} from '../../../models/user/user-service-profile';
import {UserApprovalData} from '../../../models/notification/user-approval-data';
import {UserResourceData} from '../../../models/notification/UserResourceData';
import {ResourceType} from '../../../models/reservation/assignment';
import {OPResponseWrapper} from '../../../models/response/op-response-wrapper';
import {GenericResourceSummary} from '../../../models/summary/generic-resource-summary';
import {DataKey, DataStoreService} from '../../util/framework/data-store.service';
import {DMCQueryParamsService} from '../../util/framework/dmc-query-params.service';
import {RequestService} from '../../util/framework/request.service';
import {UrlPaths} from '../url-paths';

@Injectable({
    providedIn: 'root'
})
export class UserProfileService {

    constructor(
        private requestService: RequestService,
        private queryParamsService: DMCQueryParamsService,
    ) {
    }

    loadCurrentUserProfile(): Observable<OPResponseWrapper<GenericResourceSummary>> {
        return this.requestService.get(
            UrlPaths.OP_USER_PROFILE_URL_KEY,
            [UrlPaths.OP_USER_PROFILE_VERSION, UrlPaths.SERVICE_PROFILE, UrlPaths.PROFILE_ME]
        );
    }

    getOperatorServiceProfiles(searchCriteria: UserProfileSearchCriteria): Observable<OPResponseWrapper<UserServiceProfile>> {
        const params = this.queryParamsService.asQueryParams(searchCriteria);
        return this.requestService.getWithDataKey(
            DataKey.opeAcceptanceSearchResultsFromService,
            UrlPaths.OP_USER_PROFILE_URL_KEY,
            [UrlPaths.OP_USER_PROFILE_VERSION, UrlPaths.SERVICE_PROFILE],
            params
        );
    }

    getResourceServiceProfiles(searchCriteria: UserProfileSearchCriteria): Observable<OPResponseWrapper<UserServiceProfile>> {
        const params = this.queryParamsService.asQueryParams(searchCriteria);
        return this.requestService.getWithDataKey(
            DataKey.resAcceptanceSearchResultsFromService,
            UrlPaths.OP_USER_PROFILE_URL_KEY,
            [UrlPaths.OP_USER_PROFILE_VERSION, UrlPaths.SERVICE_PROFILE],
            params
        );
    }

    getSupplierServiceProfiles(searchCriteria: UserProfileSearchCriteria): Observable<OPResponseWrapper<UserServiceProfile>> {
        const params = this.queryParamsService.asQueryParams(searchCriteria);
        return this.requestService.getWithDataKey(
            DataKey.supAcceptanceSearchResultsFromService,
            UrlPaths.OP_USER_PROFILE_URL_KEY,
            [UrlPaths.OP_USER_PROFILE_VERSION, UrlPaths.SERVICE_PROFILE],
            params
        );
    }

    getResourceServiceProfilesWithoutKey(searchCriteria: UserProfileSearchCriteria): Observable<OPResponseWrapper<UserServiceProfile>> {
        const params = this.queryParamsService.asQueryParams(searchCriteria);
        return this.requestService.get(
            UrlPaths.OP_USER_PROFILE_URL_KEY,
            [UrlPaths.OP_USER_PROFILE_VERSION, UrlPaths.SERVICE_PROFILE],
            params
        );
    }

    getProfilesWithoutKey(searchCriteria: UserProfileSearchCriteria): Observable<OPResponseWrapper<UserProfile>> {
        const params = this.queryParamsService.asQueryParams(searchCriteria);
        return this.requestService.get(
            UrlPaths.OP_USER_PROFILE_URL_KEY,
            [UrlPaths.OP_USER_PROFILE_VERSION, UrlPaths.USER_PROFILE],
            params
        );
    }

    getEmailsForResourceIds(resourceIdList: number[]): Observable<OPResponseWrapper<UserResourceData>> {
        const params = this.queryParamsService.asQueryParams(resourceIdList);
        return this.requestService.post(
            UrlPaths.OP_USER_PROFILE_URL_KEY,
            [UrlPaths.OP_USER_PROFILE_VERSION, UrlPaths.SERVICE_PROFILE, UrlPaths.GET_RESOURCE_EMAILS],
            resourceIdList
        );
    }

    getAllEmailsForResourceIds(): Observable<OPResponseWrapper<UserResourceData>> {
        return this.requestService.get(
            UrlPaths.OP_USER_PROFILE_URL_KEY,
            [UrlPaths.OP_USER_PROFILE_VERSION, UrlPaths.SERVICE_PROFILE, UrlPaths.GET_ALL_RESOURCE_EMAILS]
        );
    }

    getPendingResourceAcceptanceData(): Observable<OPResponseWrapper<UserServiceProfile>> {
        const criteria = new UserProfileSearchCriteria();
        criteria.approvalStatus = ApprovalStatus.PENDING;
        criteria.restrictedServiceTypes = [];
        criteria.restrictedServiceTypes.push(ResourceType.default);
        const params = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.getWithDataKey(
            DataKey.pendingResourceAcceptanceData,
            UrlPaths.OP_USER_PROFILE_URL_KEY,
            [UrlPaths.OP_USER_PROFILE_VERSION, UrlPaths.SERVICE_PROFILE],
            params
        );
    }

    getPendingSupplierAcceptanceData(): Observable<OPResponseWrapper<UserServiceProfile>> {
        const criteria = new UserProfileSearchCriteria();
        criteria.approvalStatus = ApprovalStatus.PENDING;
        criteria.restrictedServiceTypes = [];
        criteria.restrictedServiceTypes.push(ResourceType.default);

        const params = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.getWithDataKey(
            DataKey.pendingSupplierAcceptanceData,
            UrlPaths.OP_USER_PROFILE_URL_KEY,
            [UrlPaths.OP_USER_PROFILE_VERSION, UrlPaths.SERVICE_PROFILE],
            params
        );
    }

    updateUserTypeStatus(userEmail: string, userApprovalData: UserApprovalData): Observable<OPResponseWrapper<any>> {
        return this.requestService.patch(
            UrlPaths.OP_USER_PROFILE_URL_KEY,
            [UrlPaths.OP_USER_PROFILE_VERSION, UrlPaths.SERVICE_PROFILE, userEmail, UrlPaths.APPROVAL_STATUS],
            userApprovalData
        );
    }

    updateResourceStatus(userEmail: string, userApprovalData: UserApprovalData): Observable<OPResponseWrapper<any>> {
        return this.requestService.patch(
            UrlPaths.OP_USER_PROFILE_URL_KEY,
            [UrlPaths.OP_USER_PROFILE_VERSION, UrlPaths.SERVICE_PROFILE, userEmail, UrlPaths.APPROVAL_STATUS],
            userApprovalData
        );
    }

    updateUserProfileKC( userProfile: any): Observable<OPResponseWrapper<any>> {
        return this.requestService.put(
            UrlPaths.OP_USER_PROFILE_URL_KEY,
            [UrlPaths.OP_USER_PROFILE_VERSION, UrlPaths.USER_PROFILE, UrlPaths.USER_PROFILE_KC],
            userProfile
        );
    }
}
