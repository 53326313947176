import { Assignment } from './assignment';
import { ServicePassenger } from './service-passenger';
import { ServiceGroup } from './service-group';
import { ServiceNote } from './service-note';
import { TrsServiceItem } from './trs-service-item';
import {ServiceAssignment} from './service-assignment';

export class ServiceItem {
    serviceItemId: number;
    adult: number;
    bookingReferenceId: string;
    child: number;
    infant: number;
    operator: string;
    productType: string;
    serviceDate: string;
    trsServiceItem: TrsServiceItem;
    servicePassengers: ServicePassenger[];
    assignment: Assignment;
    serviceAssignments: ServiceAssignment[];
    disabled: boolean;
    split: boolean;
    privateBooking: boolean;
    reservationStatus: ReservationStatus;
    agentId: number;
    bookingDate: string;
    brand: string;
    company: string;
    division: string;
    vehicleModel: string;
    distributionChannel: boolean;
    firstParentId: number;
    serviceGroups: ServiceGroup;
    serviceNotes: ServiceNote[];

    checked: boolean;

}

export enum ReservationStatus {
    CANCELLED = 'CANCELLED',
    UPDATED = 'UPDATED',
    NEUTRAL = 'NEUTRAL'
}
