import {Component, OnInit} from '@angular/core';
import {ResultContentCardComponent} from '../result-content-card/result-content-card.component';

@Component({
    selector: 'tc-result-content-card-suppliers',
    templateUrl: '../result-content-card/result-content-card.component.html'
})
export class ResultContentCardSuppliersComponent extends ResultContentCardComponent implements OnInit {

    constructor() {
        super();
    }

    ngOnInit() {                 
        super.ngOnInit();
        this.overrideStyles();
    }

    private overrideStyles() {
        this.row1col1_customsStyles = ['tc-strip-text--primary'];
        this.row1col2_customsStyles = ['tc-strip-text--primary'];
        this.row1col3_customsStyles = [];
        this.row1col4_customsStyles = ['tc-strip-text--right', 'tc-strip-text--primary', 'tc-strip__price', 'tc-strip__price--large'];

        this.row2col1_customsStyles = [];
        this.row2col2_customsStyles = [];
        this.row2col3_customsStyles = [];
        this.row2col4_customsStyles = ['tc-strip-text--right'];
    }

}
