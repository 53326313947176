import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { isNumeric } from 'rxjs/internal/util/isNumeric';

@Component({
    selector: 'tc-auto-assigned-summary',
    templateUrl: './auto-assigned-summary.component.html'
})
export class AutoAssignedSummaryComponent implements OnInit {

    @Output() closePopUp: EventEmitter<any> = new EventEmitter();
    @Input() assignedResources: any;
    totalCost = 0;
    defaultColDef: any;
    paginationPageSize: number;

    columnDefs = [
        {headerName: 'Service Item', field: 'serviceItem'},
        {
            headerName: 'Passenger Count',
            type: 'numericColumn',
            field: 'passengerCount'
        },
        {headerName: 'Resource', field: 'resource', sortable: true},
        {
            headerName: 'Supplier',
            field: 'supplier'
        },
        {
            headerName: 'Capacity',
            type: 'numericColumn',
            field: 'capacity'
        },
        {
            headerName: 'Resource Cost(AED)',
            type: 'numericColumn',
            field: 'cost',
            sortable: true
        }
    ];

    rowData = [];

    constructor() {
        this.defaultColDef = {
            editable: false,
            resizable: true,
            sortable: true
        };
        this.paginationPageSize = 10;
    }

    ngOnInit() {
        this.totalCost = 0;
        this.totalResourceCostCalculator();
        this.rowData = [];
        this.assignedResources.forEach(data => {
            let item = {
                serviceItem: data.serviceItem,
                passengerCount: data.passengerCount,
                resource: data.resource,
                supplier: data.supplier,
                capacity: data.capacity,
                cost: isNumeric(data.cost) ? data.cost.toFixed(2) : data.cost
            };
            this.rowData.push(item);
        });
    }

    private totalResourceCostCalculator() {
        let tempArray = [];
        this.assignedResources.forEach(resource => {
            this.totalCost += resource.cost;
            // if (tempArray.length == 0) {
            //     tempArray.push(resource);
            //     if (isNumeric(resource.cost)) {
            //         this.totalCost += resource.cost;
            //     }
            // } else {
            //     let isContain = false;
            //     tempArray.forEach(element => {
            //         if (element.resource == resource.resource) {
            //             isContain = true
            //         }
            //     });
            //     if (!isContain) {
            //         tempArray.push(resource);
            //         if (isNumeric(resource.cost)) {
            //             this.totalCost += resource.cost;
            //         }
            //     }
            // }
        });
    }

    public onClose($event: MouseEvent) {
        this.closePopUp.emit($event);
    }
}
