import {Injectable} from '@angular/core';
import {BackendConfigCodes} from './backend-config-codes';
import {ResponseUtil} from '../../util/response/response-util.service';
import {OPResponseWrapper} from '../../../models/response/op-response-wrapper';
import {Configuration} from '../../../models/setup/Configuration';
import {ConfigurationSearchCriteria} from '../../../models/criteria/configuration-search-criteria';
import {MasterDataRestService} from '../master-data-handler-service/master-data-rest.service';

@Injectable({
    providedIn: 'root'
})
export class BackendConfigService {

    private configs: Map<string, string> = new Map();
    private loaded = false;

    constructor(
        private masterDataRestService: MasterDataRestService
    ) {
    }

    public loadBackendConfigs() {
        if (!this.loaded) {
            console.log('loading backend configs');
            const configurationSearchCriteria = new ConfigurationSearchCriteria();
            configurationSearchCriteria.size = 10000000;
            this.masterDataRestService.getConfigurations(configurationSearchCriteria)
                .subscribe(
                    result => {
                        this.loaded = true;
                        this.updateConfigs(result);
                    }
                );
        }
    }

    public updateConfigs(configs: OPResponseWrapper<Configuration>) {
        if (!this.configs) {
            this.configs = new Map();
        }
        if (configs) {
            const dataArray = ResponseUtil.getDataArray(configs);
            for (const configuration of dataArray) {
                this.configs.set(configuration.code, configuration.value);
            }
        }
    }

    public getConfigStr(code: BackendConfigCodes): string {
        const value = this.configs.get(code);
        return value ? value : '';
    }

    public getConfigInt(code: BackendConfigCodes): number {
        const configValue = parseInt(this.getConfigStr(code), 10);
        if (isNaN(configValue)) {
            return -1;
        } else {
            return configValue;
        }
    }

    public getConfigFloat(code: BackendConfigCodes): number {
        const configValue = parseFloat(this.getConfigStr(code));
        if (isNaN(configValue)) {
            return -1;
        } else {
            return configValue;
        }
    }
}
