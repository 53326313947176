import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { LEVEL, ModalData } from '@tc-core/model/it/codegen/ui/framework/modal-data';
import { DateFormatter } from '@tc-core/util/system';
import { ChipInputComponent } from '@tc/chip-input';
import { ModalService } from '@tc/modal/modal.service';
import { Subscription } from 'rxjs';
import { ConfigLoader } from '@tc-core/util/framework/config-loader.service';
import { EventManager } from '@tc-core/util/framework/event-manager.service';
import { UserJourneyManager } from '@tc-core/util/framework/user-journey-manager.service';
import { CommonHelper } from '@tc-core/util/helpers/common-helper.service';
import { TC } from '@tc-core/util/constants';
import { TCO } from '../../../constants';
import { TcApiError } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-api-error';
import { TcHttpError } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-http-error';
import { ReservationSearchCriteria } from '../../../models/criteria/reservation-search-criteria';
import { GroupBy } from '../../../models/helper/group-by';
import { SortDirection } from '../../../models/helper/sort-direction';
import { TrsReservationManagementService } from '../../../services/backend-consumers/reservation-management-services/trs-reservation-management.service';
import { TransportResourceService } from '../../../services/backend-consumers/supplier-service/transport-resource.service';
import { ChipHandlerService } from '../../../services/helpers/chip-handler.service';
import { RootService } from '../../../services/util/core-services/root.service';
import { ActionHandlerService } from '../../../services/util/framework/action-handler-service.service';
import { SpinnerService } from '@tc-core/util/ui/spinner.service';
import { DMCCommon } from '../../../services/util/common/dmc-common';
import { DataKey, DataStoreService } from '../../../services/util/framework/data-store.service';
import { DMCQueryParamsService } from '../../../services/util/framework/dmc-query-params.service';
import { ShowSavedCriteriaModalComponent } from '../show-saved-criteria-modal/show-saved-criteria-modal.component';
import * as moment from 'moment';
var ReservationSearchCriteriaComponent = /** @class */ (function () {
    function ReservationSearchCriteriaComponent(configLoader, chipHandler, dateFormatter, rootService, em, userJourneyManager, queryParamService, commonHelper, reservationManagementService, dataStoreService, actionHandlerService, transportResourceService, spinnerService, common, modalService) {
        this.configLoader = configLoader;
        this.chipHandler = chipHandler;
        this.dateFormatter = dateFormatter;
        this.rootService = rootService;
        this.em = em;
        this.userJourneyManager = userJourneyManager;
        this.queryParamService = queryParamService;
        this.commonHelper = commonHelper;
        this.reservationManagementService = reservationManagementService;
        this.dataStoreService = dataStoreService;
        this.actionHandlerService = actionHandlerService;
        this.transportResourceService = transportResourceService;
        this.spinnerService = spinnerService;
        this.common = common;
        this.modalService = modalService;
        this.isInsideForm = false;
        this.isSearchBarStyleNotAvailable = false;
        this.searchTrigger = new EventEmitter();
        this.searchCriteria = new EventEmitter();
        this.availableChips = [];
        this.isDataLoaded = true;
        this.isChipsInit = false;
        this.isChipListDisplay = false;
        this.displayChipsArr = [];
        this.isFocus = false;
        this.placeHolderText = 'Search Transport Services';
        this.persistentQParams = [];
        this.persistentQParamMap = new Map();
        this.persistentChipsArr = [];
        this.isTriggerSearch = true;
        this.journey = null;
        this.systemDefinedCriteriaList = [];
        this.userDefinedCriteriaList = [];
        this.selectedChipsObsv = new Subscription();
        this.selectedFieldsObsv = new Subscription();
        this.chipValueUpdateSub = new Subscription();
        this.relatedChipsObsv = new Subscription();
        this.relatedChipsProcessObsv = new Subscription();
        this.criteriaUpdateSubscription = new Subscription();
        this.deletedChipObsv = new Subscription();
        this.selectedChips = [];
        this.chipHandlerSubject = new Subscription();
        this.SERVICE_TYPE_TRANSFER = 'TRS';
    }
    ReservationSearchCriteriaComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.chipInputService = this.transferQueueSearchChipInput.criteriaInputService;
        this.tPagination = this.configLoader.configurations.get(TCO.CONF.CONF_PAGINATION_CONFIG)['RESERVATION_SEARCH'];
        // reset the search results
        this.dataStoreService.set(DataKey.reservationSearchCriteria, null, true);
        this.dataStoreService.set(DataKey.reservationSearchResultsFromService, null, true);
        this.handleQuickActions();
        this.userJourneyManager.journey.subscribe(function (journey) {
            if (journey && JSON.stringify(journey) !== JSON.stringify({})) {
                _this.journey = journey.id;
            }
        });
        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'SHOW_SAVED_CRITERIA').subscribe(function (e) {
            _this.onShowSavedCriteriaClick();
        });
        this.getUserDefinedCriteriaList();
        this.getSystemDefinedCriteriaList();
        // resetting the view
        /*this.routerChangeSubscription = this.userJourneyManager.routeChanges.subscribe(e => {
            if (e) {
                this.chipInputService.fullReset(); // fully reset chips
                this.chipHandler.chipsArr = []; // reset chips array loaded from config
                // check journey begins from quick book
                if (!this.isInsideForm) {
                    this.getQueryParams();
                    this.initSearchCriteriaChips();
                }

                console.log('displayChipsArr', this.displayChipsArr);
                this.resetQueryParams();
            }
        });*/
        if (!this.isInsideForm) {
            this.getQueryParams();
            if (!this.isChipsInit) {
                this.initSearchCriteriaChips(); // load everything from start
            }
        }
        this.getReservationSearchObserver();
        this.errorObserver();
        // this.selectedChipsUpdate();
        this.shortCutObserver();
        this.handleInterRelatedChips();
        // this.notifyChipDelete();
        // this.shortCutObserver();
    };
    ReservationSearchCriteriaComponent.prototype.getQueryParams = function () {
        var queryParams = [];
        queryParams = this.queryParamService.getQueryParamsArray(new ReservationSearchCriteria());
        this.reservationSearchCriteria = this.rootService.loadDataFromLocalStorage(queryParams, new ReservationSearchCriteria(), TCO.AppData.RESERVATION_SEARCH_CRITERIA);
        this.reservationSearchCriteria.serviceType = this.SERVICE_TYPE_TRANSFER;
        if (this.reservationSearchCriteria && !this.reservationSearchCriteria.startDate) {
            this.reservationSearchCriteria.startDate = new Date().toISOString().split('T')[0];
        }
        if (this.reservationSearchCriteria && !this.reservationSearchCriteria.endDate) {
            this.reservationSearchCriteria.endDate = new Date(+new Date(this.reservationSearchCriteria.startDate) + 172800000).toISOString().split('T')[0];
        }
        if (this.reservationSearchCriteria) {
            this.persistentQParams = [];
            this.persistentQParams = this.queryParamService.getNotNullQueryParamsArray(this.reservationSearchCriteria);
        }
    };
    ReservationSearchCriteriaComponent.prototype.shortCutObserver = function () {
        var _this = this;
        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'FOCUS_SEARCH_BAR').subscribe(function (e) {
            _this.isFocus = true;
        });
        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'CLOSE_POPUPS').subscribe(function (e) {
            _this.isFocus = false;
        });
    };
    ReservationSearchCriteriaComponent.prototype.onFocus = function (event) {
        this.isFocus = event;
    };
    ReservationSearchCriteriaComponent.prototype.initSearchCriteriaChips = function () {
        var _this = this;
        this.isChipsInit = true;
        this.chipInputService.fullReset(); // fully reset chips
        this.chipHandler.chipsArr = [];
        // read chip config
        var criteriaChipConfigs = null;
        if (!this.isInsideForm) {
            var chipConfigs = this.configLoader.configurations.get(TCO.CONF.CONF_RESERVATION_SEARCH_CRITERIA_CHIP);
            criteriaChipConfigs = this.commonHelper.recreateJsonObject(chipConfigs);
        }
        else {
            var chipConfigs = this.configLoader.configurations.get(this.searchCriteriaChipConfig);
            criteriaChipConfigs = this.commonHelper.recreateJsonObject(chipConfigs);
        }
        if (criteriaChipConfigs.chips) {
            criteriaChipConfigs.chips.forEach(function (chip) {
                chip = _this.chipHandler.preProcessChips(chip, criteriaChipConfigs.permissionKeys.keys);
            });
        }
        var isDataLoaded = false;
        this.chipHandler.chipsSubject.subscribe(function (data) {
            if (data && JSON.stringify(data) !== JSON.stringify([]) && !isDataLoaded) {
                isDataLoaded = true;
                _this.availableChips = data;
                _this.chipHandler.chipsSubject.next(null);
                if (_this.chipHandlerSubject) {
                    _this.chipHandlerSubject.unsubscribe();
                }
                // set values to display chips
                _this.displayChipsArr = [];
                _this.displayChipsArr = _this.availableChips;
                _this.dataStoreService.set(DataKey.chips, _this.availableChips, true);
                _this.reservationSearchCriteria.serviceType = _this.SERVICE_TYPE_TRANSFER;
                if (_this.reservationSearchCriteria && !_this.reservationSearchCriteria.startDate) {
                    _this.reservationSearchCriteria.startDate = new Date().toISOString().split('T')[0];
                }
                if (_this.reservationSearchCriteria && !_this.reservationSearchCriteria.endDate) {
                    _this.reservationSearchCriteria.endDate = new Date(+new Date(_this.reservationSearchCriteria.startDate) + 172800000).toISOString().split('T')[0];
                }
                _this.chipInputService.init(_this.reservationSearchCriteria, _this.availableChips, false, true);
                // implement chip persistance
                if (_this.persistentQParams && _this.persistentQParams.length > 0) {
                    // create a queryParamMap
                    for (var i = 0; i < _this.persistentQParams.length; i++) {
                        var qParam = _this.persistentQParams[i];
                        var qParamValue = _this.reservationSearchCriteria[qParam];
                        if (qParam && qParamValue) {
                            _this.persistentQParamMap.set(qParam, qParamValue);
                        }
                    }
                    // fill persistent chips
                    _this.availableChips.forEach(function (chip) {
                        var persistChip = Object.assign({}, chip);
                        persistChip.optionParams.data = [];
                        var isPersist = false;
                        if (chip.dataParams && chip.dataParams.length > 0) {
                            chip.dataParams.forEach(function (dataParam) {
                                if (_this.persistentQParamMap.has(dataParam.paramValue)) {
                                    isPersist = true;
                                    var dataObj = {
                                        key: dataParam.paramValue,
                                        value: _this.persistentQParamMap.get(dataParam.paramValue)
                                    };
                                    persistChip.optionParams.data.push(dataObj);
                                }
                            });
                        }
                        // push persist chips to an array
                        if (isPersist) {
                            _this.persistentChipsArr.push(persistChip);
                        }
                    });
                    // persist chips
                    if (_this.persistentChipsArr && _this.persistentChipsArr.length > 0) {
                        _this.persistentChipsArr.forEach(function (value) {
                            if (value.criteriaType === 'CALENDAR' && typeof value.optionParams.data[0].value !== 'string') {
                                value.optionParams.data[0].value = moment(value.optionParams.data[0].value).format('YYYY-MM-DD');
                            }
                        });
                        var uniqueChipList = _this.persistentChipsArr.filter(function (e, i) {
                            return _this.persistentChipsArr.findIndex(function (x) {
                                return x.criteriaId === e.criteriaId;
                            }) === i;
                        });
                        _this.chipInputService.persistChips(uniqueChipList);
                    }
                }
                // trigger search when chips persisting
                _this.isDataLoaded = false;
                // this.isTriggerSearch = false;
                _this.onReservationSearch(_this.reservationSearchCriteria);
                _this.chipInputService.criteriaUpdate.subscribe(function (_data) {
                    _this.reservationSearchCriteria = _data;
                });
            }
        });
    };
    ReservationSearchCriteriaComponent.prototype.onReservationSearch = function (event) {
        var _this = this;
        this.isDataLoaded = false;
        this.reservationSearchCriteria = event;
        this.userJourneyManager.canProceed.next(false);
        if (this.tPagination && this.tPagination['initialFetchingPages'] && this.tPagination['defaultPageResultCount']) {
            this.reservationSearchCriteria.size = this.tPagination['initialFetchingPages'] * this.tPagination['defaultPageResultCount'];
            this.reservationSearchCriteria.start = 0;
        }
        this.userJourneyManager.disableQuickActions([
            'TRIGGER_EXPORT_FILE'
        ]);
        this.reservationSearchCriteria = event;
        if (!this.reservationSearchCriteria.groupBy) {
            this.reservationSearchCriteria.groupBy = GroupBy.INTERVAL;
            this.reservationSearchCriteria.interval = 1;
            this.reservationSearchCriteria.filterStartTime = '00:00';
            this.reservationSearchCriteria.sortDirection = SortDirection.ASC;
        }
        if (JSON.stringify(this.reservationSearchCriteria) !== JSON.stringify({})) {
            this.dataStoreService.set(DataKey.reservationSearchCriteria, this.reservationSearchCriteria, true);
            this.dataStoreService.set(DataKey.reservationSearchResultsFromService, null, true);
            // set criteria parameters to url
            var queryParams = [];
            queryParams = this.queryParamService.getQueryParamsArray(this.reservationSearchCriteria);
            queryParams = queryParams.filter(function (val) { return val !== 'page'; });
            this.rootService.setDataToLocalStorage(TCO.AppData.RESERVATION_SEARCH_CRITERIA, this.reservationSearchCriteria, true, queryParams, true);
            this.reservationManagementService.searchTrsItemGroups(this.reservationSearchCriteria);
            // this.spinnerService.show();
            this.isDataLoaded = false;
            this.isTriggerSearch = false;
            this.searchTrigger.emit(true);
        }
        else {
            setTimeout(function () {
                _this.isDataLoaded = true;
                _this.isTriggerSearch = true;
            }, 0);
        }
    };
    ReservationSearchCriteriaComponent.prototype.getUpdatedFieldsDataObject = function (chip, criteria) {
        var dataObjArr = [];
        if (chip && chip.dataParams && chip.dataParams.length > 0) {
            chip.dataParams.forEach(function (dataParam) {
                var dataObj = {
                    key: '',
                    value: ''
                };
                dataObj.key = dataParam.paramValue;
                dataObj.value = criteria[dataParam.paramValue];
                dataObjArr.push(dataObj);
            });
        }
        return dataObjArr;
    };
    ReservationSearchCriteriaComponent.prototype.getReservationSearchObserver = function () {
        var _this = this;
        var x = this.dataStoreService.get(DataKey.reservationSearchResultsFromService).subscribe(function (data) {
            if (_this.commonHelper.dataValidity(data)) {
                setTimeout(function () {
                    _this.isDataLoaded = true;
                    _this.isTriggerSearch = true;
                }, 0);
            }
            else if (_this.commonHelper.isEmptyData(data)) {
                _this.isDataLoaded = true;
            }
            else if (data instanceof TcApiError) {
                _this.isDataLoaded = true;
            }
            else if (data instanceof TcHttpError) {
                _this.isDataLoaded = true;
            }
        }, function (error) {
            console.log(error);
        });
    };
    ReservationSearchCriteriaComponent.prototype.errorObserver = function () {
        var _this = this;
        this.dataStoreService.get(DataKey.error).subscribe(function (data) {
            if (data && JSON.stringify(data) !== JSON.stringify({})) {
                setTimeout(function () {
                    _this.isDataLoaded = true;
                }, 0);
                _this.dataStoreService.set(DataKey.error, null);
            }
        });
    };
    ReservationSearchCriteriaComponent.prototype.resetQueryParams = function () {
        var reservationQueryParams = [];
        reservationQueryParams = this.queryParamService.getQueryParamsArray(new ReservationSearchCriteria());
        var reservationSearchCriteria1 = this.rootService.loadDataFromLocalStorage(reservationQueryParams, new ReservationSearchCriteria(), TCO.AppData.RESERVATION_SEARCH_CRITERIA);
        // set criteria parameters to url
        var queryParams = [];
        queryParams = this.queryParamService.getQueryParamsArray(reservationSearchCriteria1);
        this.rootService.setDataToLocalStorage(TCO.AppData.RESERVATION_SEARCH_CRITERIA, this.reservationSearchCriteria, true, queryParams, true);
    };
    ReservationSearchCriteriaComponent.prototype.onCancel = function (event) {
        console.log('cancelled');
    };
    ReservationSearchCriteriaComponent.prototype.onChipListDisplay = function (event) {
        this.dataStoreService.set(DataKey.chipListDisplay, null, true);
        this.isChipListDisplay = event;
        this.dataStoreService.set(DataKey.chipListDisplay, this.isChipListDisplay, true);
    };
    ReservationSearchCriteriaComponent.prototype.handleQuickActions = function () {
        var _this = this;
        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'TRIGGER_SEARCH_CRITERIA').subscribe(function (e) {
            _this.triggerSearch(e.data);
        });
    };
    ReservationSearchCriteriaComponent.prototype.triggerSearch = function (data) {
        this.reservationSearchCriteria = new ReservationSearchCriteria();
        this.reservationSearchCriteria.serviceType = this.SERVICE_TYPE_TRANSFER;
        this.reservationSearchCriteria.date = '';
        this.actionHandlerService.fillCriteriaByActionData(data, this.reservationSearchCriteria);
        var requiredChips = this.chipHandler.getChipsFromCriteria(this.reservationSearchCriteria, TCO.CONF.CONF_RESERVATION_SEARCH_CRITERIA_CHIP, true);
        // requiredChips = this.chipHandler.validateAndFilterChips(requiredChips, ['bookingRefId|>|0']);
        this.chipInputService.hardReset();
        this.chipInputService.persistChips(requiredChips, true);
        // this.chipInputService.criteria = this.reservationSearchCriteria;
        this.onReservationSearch(this.reservationSearchCriteria);
    };
    ReservationSearchCriteriaComponent.prototype.observeChipValueUpdate = function () {
    };
    ReservationSearchCriteriaComponent.prototype.handleInterRelatedChips = function () {
        var _this = this;
        this.relatedChipsObsv = this.chipInputService.relatedChipsSubject.subscribe(function (data) {
            var toggleChips = [];
            if (data && data.chip && JSON.stringify(data) !== JSON.stringify({})) {
                _this.modifiedChip = data.chip;
                // TODO: refine chip input service: inter related chips
                if (data.chip.type === 'CALENDAR') {
                    // startDate & returnDate validate
                    if (data.chip.id === 'START_DATE') {
                        var startDate = data.date;
                        var nights = 2;
                        var currentEndDate = _this.checkDependentForCalculateRelatedDate(data.chip);
                        if (currentEndDate) {
                            _this.setMinDateForRelatedDateChips(data.chip, data.date);
                            if (_this.dateFormatter.isAfter(startDate, currentEndDate)) {
                                _this.chipHandler.handleInterRelatedChips(data.chip, _this.dateFormatter.addDaysToDate(startDate, nights));
                            }
                            else {
                                _this.chipHandler.handleInterRelatedChips(data.chip, currentEndDate);
                            }
                        }
                    }
                }
                else {
                    _this.chipHandler.handleInterRelatedChips(data.chip, data.options.value[0]);
                }
                if (data.chip.optionParams.toggleChips && data.chip.optionParams.toggleChips.length > 0) {
                    toggleChips = data.chip.optionParams.toggleChips;
                    if (toggleChips && data.options && data.options.value[0].value) {
                        _this.chipInputService.selectedChipsBasedOnStateChange(toggleChips);
                    }
                    else {
                        _this.chipInputService.deleteChipsBasedOnStateChange(toggleChips);
                    }
                }
            }
        });
        this.relatedChipsProcessObsv = this.chipHandler.relatedChipsProcessSubject.subscribe(function (result) {
            if (result && JSON.stringify(result) !== JSON.stringify([]) && _this.modifiedChip.optionParams.relatedChips &&
                _this.modifiedChip.optionParams.relatedChips.length > 0 &&
                result.length === _this.modifiedChip.optionParams.relatedChips.length) {
                // if chip date is passed date it will be reset for today
                result.forEach(function (chip) {
                    if (chip.dataParam === 'DATE' && chip.criteriaId === 'START_DATE') {
                        chip.options = _this.dateFormatter.dateFromString(chip.options) > _this.dateFormatter.getToday() ?
                            chip.options :
                            _this.dateFormatter.dateAsString(_this.dateFormatter.getToday());
                    }
                    if (chip.dataParam === 'DATE' && chip.criteriaId === 'END_DATE') {
                        chip.options = _this.dateFormatter.dateFromString(chip.options) > _this.dateFormatter.getToday() ?
                            chip.options :
                            _this.dateFormatter.dateAsString(_this.dateFormatter.getToday());
                    }
                });
                // pass related chips to chip input service
                _this.chipInputService.handleInterRelatedChips(result);
            }
        });
    };
    ReservationSearchCriteriaComponent.prototype.setMinDateForRelatedDateChips = function (chip, date) {
        if (date && chip && chip.optionParams && chip.optionParams.relatedChips) {
            var minDate_1 = this.dateFormatter.dateFromString(date);
            chip.optionParams.relatedChips.forEach(function (relateChip) {
                if (relateChip.criteriaType === 'CALENDAR') {
                    relateChip.data = [{
                            key: 'minDate',
                            value: minDate_1
                        }];
                }
            });
        }
    };
    /**
     * Check dependent if available when calculate related date or related duration
     * @param chip Chip
     */
    ReservationSearchCriteriaComponent.prototype.checkDependentForCalculateRelatedDate = function (chip) {
        var dateParam;
        if (chip.optionParams && chip.optionParams.relatedChips) {
            var dependent = chip.optionParams.behaviouralData.find(function (behData) { return behData.key === 'DATE_RELATED_DEPENDENT'; });
            if (dependent) {
                dateParam = this.getValueOfDependentParameter(dependent.value);
            }
        }
        return dateParam;
    };
    /**
     * Read additional params for handle inter related chips
     * @param dependent Dependent
     */
    ReservationSearchCriteriaComponent.prototype.getValueOfDependentParameter = function (dependent) {
        if (this.chipInputService && this.chipInputService.criteria && this.chipInputService.criteria[dependent.paramValue]) {
            return this.chipInputService.criteria[dependent.paramValue];
        }
        return '';
    };
    ReservationSearchCriteriaComponent.prototype.ngOnDestroy = function () {
        // this.routerChangeSubscription.unsubscribe();
        this.userJourneyManager.routeChanges.next(false);
        if (this.selectedChipsObsv) {
            this.selectedChipsObsv.unsubscribe();
        }
        if (this.selectedFieldsObsv) {
            this.selectedFieldsObsv.unsubscribe();
        }
        if (this.chipValueUpdateSub) {
            this.chipValueUpdateSub.unsubscribe();
        }
        if (this.criteriaUpdateSubscription) {
            this.criteriaUpdateSubscription.unsubscribe();
        }
        if (this.deletedChipObsv) {
            this.deletedChipObsv.unsubscribe();
        }
        this.em.removeEvents([
            'TRIGGER_SEARCH_CRITERIA'
        ]);
    };
    ReservationSearchCriteriaComponent.prototype.selectedChipsUpdate = function () {
        var _this = this;
        this.criteriaUpdateSubscription = this.chipInputService.selectedChipsSubject.subscribe(function (selectedChips) {
            var e_1, _a;
            _this.selectedChips = selectedChips;
            if (selectedChips && JSON.stringify(selectedChips) !== JSON.stringify([])) {
                try {
                    // toggle chips when selecting BRANCH_OF_CLIENT_ID chip
                    for (var _b = tslib_1.__values(_this.selectedChips), _c = _b.next(); !_c.done; _c = _b.next()) {
                        var selectedChip = _c.value;
                        if (selectedChip.chip.id === 'BRANCH_OF_CLIENT_ID' && selectedChip.chip.optionParams.toggleChips &&
                            selectedChip.chip.optionParams.toggleChips.length > 0) {
                            _this.deleteToggleChips(selectedChip.chip.optionParams.toggleChips);
                            _this.disableChips(selectedChip.chip.optionParams.toggleChips);
                            break;
                        }
                    }
                }
                catch (e_1_1) { e_1 = { error: e_1_1 }; }
                finally {
                    try {
                        if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                    }
                    finally { if (e_1) throw e_1.error; }
                }
            }
        });
    };
    ReservationSearchCriteriaComponent.prototype.notifyChipDelete = function () {
        var _this = this;
        this.deletedChipObsv = this.chipInputService.deletedChipsSubject.subscribe(function (deletedChip) {
            if (JSON.stringify(deletedChip) !== JSON.stringify({}) && deletedChip !== null && deletedChip !== undefined) {
                if (deletedChip.id === 'CLIENT_TYPE') {
                    var deleteChips = _this.selectedChips.map(function (chip) { return chip.chip; });
                    _this.deleteSelectedChips(deleteChips);
                    _this.enableChips(_this.availableChips);
                }
                else if (deletedChip.id === 'BRANCH_OF_CLIENT_ID') {
                    if (deletedChip.optionParams.toggleChips && deletedChip.optionParams.toggleChips.length > 0) {
                        _this.enableChips(deletedChip.optionParams.toggleChips);
                    }
                }
            }
        });
    };
    /**
     * Delete toggle chips if already selected
     *
     * @param toggleChips
     */
    ReservationSearchCriteriaComponent.prototype.deleteToggleChips = function (toggleChips) {
        var _this = this;
        var deleteChips = [];
        toggleChips.forEach(function (toggleChip) {
            var e_2, _a;
            try {
                for (var _b = tslib_1.__values(_this.selectedChips), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var selectedChip = _c.value;
                    if (selectedChip.chip.id === toggleChip.criteriaId) {
                        deleteChips.push(selectedChip.chip);
                        break;
                    }
                }
            }
            catch (e_2_1) { e_2 = { error: e_2_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_2) throw e_2.error; }
            }
        });
        this.deleteSelectedChips(deleteChips);
    };
    ReservationSearchCriteriaComponent.prototype.deleteSelectedChips = function (deleteChips) {
        var _this = this;
        deleteChips.forEach(function (chip) {
            _this.chipInputService.deleteChip(chip, true);
        });
    };
    ReservationSearchCriteriaComponent.prototype.enableChips = function (enableChips) {
        var _this = this;
        enableChips.forEach(function (enableChip) {
            _this.chipInputService.modifyChipsStatus(enableChip, false);
        });
    };
    ReservationSearchCriteriaComponent.prototype.disableChips = function (disableChips) {
        var _this = this;
        disableChips.forEach(function (disableChip) {
            _this.chipInputService.modifyChipsStatus(disableChip, true);
        });
    };
    ReservationSearchCriteriaComponent.prototype.searchFromSavedCriteriaObserver = function () {
        var _this = this;
        this.isChipsInit = false;
        var x = this.dataStoreService.get(DataKey.searchFromSavedCriteria).subscribe(function (data) {
            if (_this.commonHelper.dataValidity(data) && !_this.isChipsInit) {
                var criteriaStartDate = _this.reservationSearchCriteria.startDate;
                var criteriaEndDate = _this.reservationSearchCriteria.endDate;
                _this.reservationSearchCriteria = data;
                _this.reservationSearchCriteria.startDate = criteriaStartDate;
                _this.reservationSearchCriteria.endDate = criteriaEndDate;
                _this.reservationSearchCriteria.serviceType = _this.SERVICE_TYPE_TRANSFER;
                if (_this.reservationSearchCriteria && _this.reservationSearchCriteria.startDate && _this.reservationSearchCriteria.daysFrom) {
                    _this.reservationSearchCriteria.startDate = new Date(+new Date() + _this.reservationSearchCriteria.daysFrom * 86400000).toISOString().split('T')[0];
                    _this.reservationSearchCriteria.endDate = new Date(+new Date(_this.reservationSearchCriteria.startDate) + 172800000).toISOString().split('T')[0];
                }
                else if (_this.reservationSearchCriteria && !_this.reservationSearchCriteria.startDate) {
                    _this.reservationSearchCriteria.startDate = new Date().toISOString().split('T')[0];
                    _this.reservationSearchCriteria.endDate = new Date(+new Date() + 172800000).toISOString().split('T')[0];
                }
                var queryParams = [];
                queryParams.forEach(function (param) {
                    _this.queryParamService.deleteQueryParam(param);
                });
                queryParams = _this.queryParamService.getQueryParamsArray(_this.reservationSearchCriteria);
                queryParams = queryParams.filter(function (val) { return val !== 'page'; });
                _this.rootService.setDataToLocalStorage(TCO.AppData.RESERVATION_SEARCH_CRITERIA, _this.reservationSearchCriteria, true, queryParams, true);
                /*let qparams: Map<string, any> = new Map();
                 let criteriaObj = this.reservationSearchCriteria;
                 for (let key in criteriaObj) {
                 if(criteriaObj[key] && criteriaObj[key] !== 'page' ){
                 qparams.set(key, criteriaObj[key]);
                 }
                 }*/
                _this.displayChipsArr = [];
                _this.persistentChipsArr = [];
                _this.persistentQParamMap = new Map();
                if (_this.reservationSearchCriteria) {
                    _this.persistentQParams = [];
                    _this.persistentQParams = _this.queryParamService.getNotNullQueryParamsArray(_this.reservationSearchCriteria);
                }
                if (!_this.isChipsInit) {
                    _this.initSearchCriteriaChips(); // load everything from start
                }
            }
        });
    };
    ReservationSearchCriteriaComponent.prototype.onShowSavedCriteriaClick = function () {
        var _this = this;
        this.dataStoreService.set(DataKey.searchFromSavedCriteria, null);
        this.dataStoreService.set(DataKey.popupClose, null);
        var dataObject = new ModalData(LEVEL.SUCCESS, 'Show Criteria', null, null, 'modal-basic--alt modal-basic--fixed-size', ShowSavedCriteriaModalComponent, { 'userDefinedCriteriaList': this.userDefinedCriteriaList,
            'systemDefinedCriteriaList': this.systemDefinedCriteriaList });
        var secondaryHeader = '';
        dataObject.secondaryHeader = secondaryHeader;
        this.confirmModal(dataObject);
        this.searchFromSavedCriteriaObserver();
        this.dataStoreService.get(DataKey.popupClose).subscribe(function (data) {
            if (data != null) {
                _this.closeModal();
            }
        });
    };
    ReservationSearchCriteriaComponent.prototype.closeModal = function () {
        if (this.modalService.close()) {
            this.modalService.close().subscribe(function (res) {
            });
        }
    };
    ReservationSearchCriteriaComponent.prototype.confirmModal = function (data) {
        this.modalService.confirm(data).subscribe(function (res) {
        });
    };
    ReservationSearchCriteriaComponent.prototype.getUserDefinedCriteriaList = function () {
        var _this = this;
        this.dataStoreService.get(DataKey.userSavedSearchCriteriaList).subscribe(function (data) {
            if (data) {
                _this.userDefinedCriteriaList = data;
            }
        });
    };
    ReservationSearchCriteriaComponent.prototype.getSystemDefinedCriteriaList = function () {
        var _this = this;
        this.dataStoreService.get(DataKey.systemDefinedCriteria).subscribe(function (data) {
            if (data) {
                _this.systemDefinedCriteriaList = data;
            }
        });
    };
    return ReservationSearchCriteriaComponent;
}());
export { ReservationSearchCriteriaComponent };
