import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Remark } from '../../../../models/common/remark';
import { DataKey, DataStoreService } from '../../../../services/util/framework/data-store.service';

@Component({
    selector: 'tc-add-note',
    templateUrl: './add-note.component.html'
})
export class AddNoteComponent implements OnInit {

    noteInfo = this.fb.group({
        note: ['']
    });
    notesModel = new Remark();

    constructor(
        private fb: FormBuilder,
        private dataStore: DataStoreService
    ) { }

    ngOnInit() {
        this.noteInfo.valueChanges.subscribe(
            (data) => {
                if (JSON.stringify(data) !== JSON.stringify({})) {
                    this.notesModel.remark = data.note;

                }
            }
        );
    }

    @HostListener('keydown', ['$event'])
    onKeyDown(event){
        if (event.key == 'Enter') {
            this.setNotes();
        }
    }

    setNotes() {
        if (this.noteInfo.value.note != '') {
            this.dataStore.set(DataKey.notes, this.notesModel, true);
        }
        this.dataStore.set(DataKey.modalClose, true);
    }

    public closePopUp() {
        this.dataStore.set(DataKey.modalClose, true);
    }
}
