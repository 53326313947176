import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { LEVEL } from '@tc-core/model/it/codegen/ui/framework/dialog-model';
import { ModalData } from '@tc-core/model/it/codegen/ui/framework/modal-data';
import { ConfigLoader } from '@tc-core/util/framework';
import { SpinnerService } from '@tc-core/util/ui/spinner.service';
import { ModalService } from '@tc/modal/modal.service';
import { of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SetupGridComp } from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid-comp';
import { SetupGridComponent } from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid.component';
import { TCO } from '../../../constants';
import { RoomOccupancySearchCriteria } from '../../../models/criteria/room-occupancy-search-criteria';
import { SortDirection } from '../../../models/helper/sort-direction';
import { OccupancyGenerateCriteria } from '../../../models/reservation-v2/criteria/occupancy-generate-criteria';
import { SupplierService } from '../../../services/backend-consumers/supplier-service/supplier.service';
import { DataKey, DataStoreService } from '../../../services/util/framework/data-store.service';
import { GenerateOccupanciesDialogComponent } from './generate-dialog/generate-occupancies-dialog.component';
var RoomOccupancySetupComponent = /** @class */ (function (_super) {
    tslib_1.__extends(RoomOccupancySetupComponent, _super);
    function RoomOccupancySetupComponent(configLoader, dataStoreService, supplierService, modalService, spinnerService) {
        var _this = _super.call(this) || this;
        _this.configLoader = configLoader;
        _this.dataStoreService = dataStoreService;
        _this.supplierService = supplierService;
        _this.modalService = modalService;
        _this.spinnerService = spinnerService;
        _this.colDefConfig = [];
        _this.headerActions = [
            {
                icon: 'autorenew',
                action: _this.clickCreateOccupancies.bind(_this),
                disable: false,
                tooltip: 'Create Occupancies'
            }
        ];
        // search criteria obj. used to
        _this.occupancySearchCriteria = new RoomOccupancySearchCriteria();
        return _this;
    }
    RoomOccupancySetupComponent.prototype.ngOnInit = function () {
        this.colDefConfig = this.configLoader.configurations.get(TCO.CONF.CONF_ROOM_OCCUPANCY_SETUP);
        this.subscribeSearchCriteria();
    };
    /**
     * subscribe search criteria changes in search bar
     * then force grid to refresh data
     * grid refresh will be call getRaws method which is using this subscribed criteria for backend call
     */
    RoomOccupancySetupComponent.prototype.subscribeSearchCriteria = function () {
        var _this = this;
        this.dataStoreService.get(DataKey.roomOccupancySearchCriteria).subscribe(function (value) {
            _this.occupancySearchCriteria = value;
            _this.setupGrid.runForceSearch();
        });
    };
    RoomOccupancySetupComponent.prototype.isInvalidRow = function (params) {
        var data = params.data;
        if (data) {
            var invalid = true;
            if (data.name !== '') {
                invalid = false;
            }
            return invalid;
        }
        else {
            return false;
        }
    };
    RoomOccupancySetupComponent.prototype.isUnsavedRow = function (row) {
        return false;
    };
    RoomOccupancySetupComponent.prototype.deleteRow = function (row) {
        if (row && row.occupancyId) {
            return this.supplierService.deleteOccupancy(row.occupancyId);
        }
        else {
            return of('success');
        }
    };
    RoomOccupancySetupComponent.prototype.saveRow = function (row, event) {
        return this.supplierService.saveOccupancy(row, new OccupancyGenerateCriteria());
    };
    RoomOccupancySetupComponent.prototype.getRows = function (params) {
        var _this = this;
        console.log('fetch requesting for ' + params.startRow + ' to ' + params.endRow);
        console.log(params.sortModel);
        var pageSize = params.endRow - params.startRow;
        if (!this.occupancySearchCriteria) {
            this.occupancySearchCriteria = new RoomOccupancySearchCriteria();
        }
        this.occupancySearchCriteria.start = params.startRow;
        this.occupancySearchCriteria.size = pageSize;
        if (params.sortModel && params.sortModel[0]) {
            this.occupancySearchCriteria.sortBy = params.sortModel[0].colId;
            if (params.sortModel[0].sort === SetupGridComp.GRID_SORT_ASCENDING) {
                this.occupancySearchCriteria.sortDirection = SortDirection.ASC;
            }
            else {
                this.occupancySearchCriteria.sortDirection = SortDirection.DESC;
            }
        }
        return this.supplierService.searchOccupancies(this.occupancySearchCriteria)
            .pipe(tap(function (data) {
            return _this.dataStoreService.set(DataKey.roomOccupancySearchResultsForCriteria, data);
        }));
    };
    RoomOccupancySetupComponent.prototype.createNewRow = function () {
        return {};
    };
    RoomOccupancySetupComponent.prototype.clickCreateOccupancies = function () {
        var _this = this;
        this.dataStoreService.set(DataKey.popupClose, null, true);
        var occupancyGenerateCriteria = new OccupancyGenerateCriteria();
        var dataObject = new ModalData(LEVEL.SUCCESS, 'Generate Occupancies', null, null, 'modal-basic--medium modal-basic--no-footer', GenerateOccupanciesDialogComponent, {
            occupancyGenerateCriteria: occupancyGenerateCriteria
        });
        this.confirmModal(dataObject);
        this.dataStoreService.get(DataKey.popupClose).subscribe(function (data) {
            if (data != null) {
                if (data) {
                    _this.generateOccupancies(occupancyGenerateCriteria);
                }
                _this.closeModal();
            }
        });
    };
    RoomOccupancySetupComponent.prototype.confirmModal = function (data) {
        this.modalService.confirm(data).subscribe(function (res) {
        });
    };
    RoomOccupancySetupComponent.prototype.closeModal = function () {
        if (this.modalService.close()) {
            this.modalService.close().subscribe(function (res) {
            });
        }
    };
    RoomOccupancySetupComponent.prototype.generateOccupancies = function (occupancyGenerateCriteria) {
        var _this = this;
        this.spinnerService.show();
        this.supplierService.saveOccupancy(null, occupancyGenerateCriteria).subscribe(function (value) {
            _this.setupGrid.runForceSearch();
            _this.spinnerService.hide();
        }, function (error) {
            _this.spinnerService.hide();
        });
    };
    RoomOccupancySetupComponent.prototype.cellEditingStart = function () {
    };
    return RoomOccupancySetupComponent;
}(SetupGridComp));
export { RoomOccupancySetupComponent };
