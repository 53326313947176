import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ResizedEvent } from 'angular-resize-event';
import * as moment from 'moment';
import { ResourceAvailability } from '../../../../models/resource/resource-availability';
import { TimelineEvent } from '../timeline-event';

@Component({
    selector: 'tc-timeline',
    templateUrl: './timeline.component.html'
})
export class TimelineComponent implements OnInit {

    @Input() summaryContent: any;

    @Input() isTargetBlock = false;
    @Input() targetPointTime: Date = new Date();
    @Input() targetBlockTimeStart: Date = null;
    @Input() targetBlockTimeEnd: Date = null;

    // @Input() events: TimelineEvent[] = [];
    eventsData: TimelineEvent[] = [];

    @Input('events')
    set events(es: TimelineEvent[]) {
        this.eventsData = es;
        this.updateEventIndexes();
    }

    // leftBufferHoursInput = -1;
    // leftBufferUnits = -1;
    //
    // @Input('leftBufferHours')
    // set leftBufferHours(h: number) {
    //     this.leftBufferHoursInput = h;
    //     this.leftBufferUnits = this.leftBufferHoursInput / this.unitSize;
    //     // this.initTimeline();
    // }
    //
    // rightBufferHoursInput = -1;
    // rightBufferUnits = -1;
    //
    // @Input('rightBufferHours')
    // set rightBufferHours(h: number) {
    //     this.rightBufferHoursInput = h;
    //     this.rightBufferUnits = this.rightBufferHoursInput / this.unitSize;
    //     // this.initTimeline();
    // }

    @Input() leftBufferHours = -1;
    @Input() rightBufferHours = -1;

    leftBufferUnits = -1;
    rightBufferUnits = -1;

    @Output() eventClick: EventEmitter<TimelineEvent> = new EventEmitter();
    @Output() targetClick: EventEmitter<void> = new EventEmitter();

    @ViewChild('timelinePart') timelinePart: ElementRef;

    unitWidth = 0;
    unitSize = 0.5;

    targetUnitIndex = 0;
    targetEndIndex = 0;
    targetUnitCount = 0;
    targetMargin = 0;

    showingUnitCount = -1;
    showingIndexes = [];
    minIndex = 0;
    maxIndex = 0;

    constructor() {
    }

    ngOnInit() {
        this.leftBufferUnits = this.leftBufferHours / this.unitSize;
        this.rightBufferUnits = this.rightBufferHours / this.unitSize;
        this.initTimeline();
    }

    initTimeline() {
        this.createTargetIndex();
        this.createShowingIndexArray();
        this.updateEventIndexes();

        const timeLineWidth = (this.timelinePart.nativeElement as HTMLElement).offsetWidth;
        this.setUnitWidth(timeLineWidth);
    }

    createTargetIndex() {
        if (!this.isTargetBlock) {
            const decimal = this.timeToDecimal(this.targetPointTime);
            this.targetUnitIndex = Math.floor(decimal / this.unitSize);
            this.targetEndIndex = this.targetUnitIndex;
            this.targetMargin = (decimal % this.unitSize) / this.unitSize;
        } else {
            const decimalValues = this.calculateDecimalValues(this.targetBlockTimeStart, this.targetBlockTimeEnd);
            const decimalStart = decimalValues.decimalStart;
            const decimalEnd = decimalValues.decimalEnd;

            this.targetUnitIndex = Math.floor(decimalStart / this.unitSize);
            this.targetMargin = (decimalStart % this.unitSize) / this.unitSize;
            this.targetUnitCount = (decimalEnd - decimalStart) / this.unitSize;
            this.targetEndIndex = this.targetUnitIndex + Math.floor(this.targetUnitCount);
        }
    }

    updateEventIndexes() {
        for (const event of this.eventsData) {
            const decimalValues = this.calculateDecimalValues(event.startTime, event.endTime);
            const decimalStart = decimalValues.decimalStart;
            const decimalEnd = decimalValues.decimalEnd;

            const waitingEndValue = this.calculateDecimalValues(event.startTime, event.waitingEndTime);
            const decimalWaitingEnd = waitingEndValue.decimalEnd;

            event.unitIndex = Math.floor(decimalStart / this.unitSize);
            event.margin = (decimalStart % this.unitSize) / this.unitSize;
            event.unitCount = (decimalEnd - decimalStart) / this.unitSize;
            const waitingDiff = decimalWaitingEnd - decimalStart;
            if (waitingDiff > 0) {
                event.waitingUnitCount = waitingDiff / this.unitSize;
            }
            const dayDifference = this.compareDateWithTarget(event.startTime);
            event.unitIndex = event.unitIndex + dayDifference * (24 / this.unitSize);

            this.fixStartIndexOfEvent(event);
            this.fixEndIndexOfEvent(event);
        }
    }

    fixStartIndexOfEvent(event: TimelineEvent) {
        const unitDiff = event.unitIndex - this.minIndex;
        if (unitDiff < 0) {
            event.unitIndex = this.minIndex;
            event.unitCount = event.unitCount + unitDiff;
            event.unitCount += event.margin;
            event.margin = 0;
        }
    }

    fixEndIndexOfEvent(event: TimelineEvent) {
        const endIndex = event.unitIndex + event.unitCount - 1;
        const unitDiff = endIndex - this.maxIndex;
        if (unitDiff > 0) {
            event.unitCount = event.unitCount - unitDiff - event.margin;
        }
    }

    timeToDecimal(time: Date) {
        const formatTime = moment(time).format('HH:mm');
        const hhAndMm = formatTime.split(':');
        let decimal = 0;
        if (hhAndMm && hhAndMm.length === 2) {
            const hh = parseInt(hhAndMm[0], 10);
            const mm = parseInt(hhAndMm[1], 10);
            decimal = hh + (mm / 60);
        }
        return decimal;
    }

    calculateDecimalValues(lowDate: Date, highDate: Date) {
        const dayDifference = moment(highDate).startOf('day').diff(moment(lowDate).startOf('day'), 'day');
        const decimalStart = this.timeToDecimal(lowDate);
        let decimalEnd = this.timeToDecimal(highDate);
        decimalEnd = decimalEnd + 24 * dayDifference;
        return {
            decimalStart,
            decimalEnd
        };
    }

    compareDateWithTarget(date: Date) {
        let compareWithTime = this.targetPointTime;
        if (this.isTargetBlock) {
            compareWithTime = this.targetBlockTimeStart;
        }
        return moment(date).startOf('day').diff(moment(compareWithTime).startOf('day'), 'day');
    }

    onResize(event: ResizedEvent) {
        const timeLineWidth = event.newWidth;
        this.setUnitWidth(timeLineWidth);
    }

    setUnitWidth(timeLineWidth: number) {
        if (this.showingUnitCount !== 0) {
            this.unitWidth = (timeLineWidth) / this.showingUnitCount;
        } else {
            this.unitWidth = 0;
        }
    }

    createShowingIndexArray() {
        const start = this.targetUnitIndex - this.leftBufferUnits;
        const end = this.targetEndIndex + this.rightBufferUnits;
        for (let step = start; step <= end; step++) {
            this.showingIndexes.push(step);
        }
        this.showingUnitCount = this.showingIndexes.length;
        this.minIndex = start;
        this.maxIndex = end;
    }

    onTargetClick() {
        this.targetClick.emit();
    }

    onEventClick(event: TimelineEvent) {
        this.eventClick.emit(event);
    }
}
