import { Injectable } from '@angular/core';
import { ConfigLoader } from '@tc-core/util/framework';
import { Observable } from 'rxjs';
import { LocationGroupsSearchCriteria } from '../../../models/criteria/location-groups-search-criteria';
import { LocationTypeSearchCriteria } from '../../../models/criteria/location-type-search-criteria';
import { LocationsSearchCriteria } from '../../../models/criteria/locations-search-criteria';
import { RoutesSearchCriteria } from '../../../models/criteria/routes-search-criteria';
import { LeaveSetup } from '../../../models/leave-setup';
import { Leave } from '../../../models/resource/leave';
import { OPResponseWrapper } from '../../../models/response/op-response-wrapper';
import { DataKey } from '../../util/framework/data-store.service';
import { DMCQueryParamsService } from '../../util/framework/dmc-query-params.service';
import { RequestService } from '../../util/framework/request.service';
import { UrlPaths } from '../url-paths';

@Injectable({
    providedIn: 'root'
})
export class LocationsService {

    constructor(
        private configLoader: ConfigLoader,
        private requestService: RequestService,
        private queryParamsService: DMCQueryParamsService
    ) { }

    /*********
     Location Types
     *********/

    public getLocationTypes(criteria: LocationTypeSearchCriteria): Observable<any> {
        const reqPrams = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.get(
            UrlPaths.OP_DATA_URL_KEY,
            [UrlPaths.OP_DATA_VERSION, UrlPaths.LOCATION_TYPES],
            reqPrams
        );
    }

    /**********
     Location Codes
     **********/

    public getLocationCodes(): Observable<any> {
        return this.requestService.get(
            UrlPaths.OP_DATA_URL_KEY,
            [UrlPaths.OP_DATA_VERSION, UrlPaths.LOCATIONS, UrlPaths.LOCATION_CODES]
        );
    }

    public checkCodeAvailability(code: string): Observable<any> {
        return this.requestService.get(
            UrlPaths.OP_DATA_URL_KEY,
            [UrlPaths.OP_DATA_VERSION, UrlPaths.LOCATIONS, UrlPaths.LOCATION_CODE_AVAILABILITY, code]
        );
    }

    /*********
     Locations
     *********/

    public getLocations(criteria: LocationsSearchCriteria): Observable<any> {
        const reqPrams = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.get(
            UrlPaths.OP_DATA_URL_KEY,
            [UrlPaths.OP_DATA_VERSION, UrlPaths.LOCATIONS],
            reqPrams
        );
    }

    public deleteLocation(code: string): Observable<any> {
        return this.requestService.delete(
            UrlPaths.OP_DATA_URL_KEY,
            [UrlPaths.OP_DATA_VERSION, UrlPaths.LOCATIONS, code]
        );
    }

    public saveLocation(row: any): Observable<any> {
        return this.requestService.post(
            UrlPaths.OP_DATA_URL_KEY,
            [UrlPaths.OP_DATA_VERSION, UrlPaths.LOCATIONS],
            row
        );
    }

    public updateLocation(code: string, row: any): Observable<any> {
        return this.requestService.put(
            UrlPaths.OP_DATA_URL_KEY,
            [UrlPaths.OP_DATA_VERSION, UrlPaths.LOCATIONS, code],
            row
        );
    }

    /***************
     Location Groups
     ***************/

    public getLocationGroups(criteria: LocationGroupsSearchCriteria): Observable<any> {
        const reqPrams = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.get(
            UrlPaths.OP_DATA_URL_KEY,
            [UrlPaths.OP_DATA_VERSION, UrlPaths.LOCATION_GROUPS],
            reqPrams
        );
    }

    public deleteLocationGroup(code: string): Observable<any> {
        return this.requestService.delete(
            UrlPaths.OP_DATA_URL_KEY,
            [UrlPaths.OP_DATA_VERSION, UrlPaths.LOCATION_GROUPS, code]
        );
    }

    public saveLocationGroup(row: any): Observable<any> {
        return this.requestService.post(
            UrlPaths.OP_DATA_URL_KEY,
            [UrlPaths.OP_DATA_VERSION, UrlPaths.LOCATION_GROUPS],
            row
        );
    }
    public saveLocationGroupWithDataKey(code: string, locations: any[]): Observable<any> {
        return this.requestService.putWithDataKey(
            DataKey.saveLocationGroups,
            UrlPaths.OP_DATA_URL_KEY,
            [UrlPaths.OP_DATA_VERSION, UrlPaths.LOCATION_GROUPS, code, UrlPaths.LOCATIONS],
            locations
        );
    }
    public saveLeaves(leaveSetupData: LeaveSetup): Observable<OPResponseWrapper<Leave>>   {
        return this.requestService.postWithDataKey(
            DataKey.saveLeaveData,
            UrlPaths.OP_SUP_URL_KEY,
            [UrlPaths.OP_SUP_VERSION, UrlPaths.LEAVES],
            leaveSetupData
        );
    }

    public updateLocationGroup(code: string, row: any): Observable<any> {
        return this.requestService.put(
            UrlPaths.OP_DATA_URL_KEY,
            [UrlPaths.OP_DATA_VERSION, UrlPaths.LOCATION_GROUPS, code],
            row
        );
    }

    /******
     Routes
     ******/

    public getRoutes(criteria: RoutesSearchCriteria): Observable<any> {
        const reqPrams = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.get(
            UrlPaths.OP_DATA_URL_KEY,
            [UrlPaths.OP_DATA_VERSION, UrlPaths.ROUTES],
            reqPrams
        );
    }

    public deleteRoute(code: string): Observable<any> {
        return this.requestService.delete(
            UrlPaths.OP_DATA_URL_KEY,
            [UrlPaths.OP_DATA_VERSION, UrlPaths.ROUTES, code]
        );
    }

    public saveRoute(row: any): Observable<any> {
        return this.requestService.post(
            UrlPaths.OP_DATA_URL_KEY,
            [UrlPaths.OP_DATA_VERSION, UrlPaths.ROUTES],
            row
        );
    }

    public updateRoute(code: string, row: any): Observable<any> {
        return this.requestService.put(
            UrlPaths.OP_DATA_URL_KEY,
            [UrlPaths.OP_DATA_VERSION, UrlPaths.ROUTES, code],
            row
        );
    }
}
