import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatExpansionModule } from '@angular/material';
import { TranslateModule } from '@ngx-translate/core';
import { ExpandedContentCardModule } from '@tc/expanded-content-card';
import { ExpandedContentCardBodyModule } from '@tc/expanded-content-card-body';
import { ExpandedContentCardFooterModule } from '@tc/expanded-content-card-footer';
import { AdditionalContactsCardModule } from '../additional-contacts-card/additional-contacts-card.module';
import { ContactDetailsCardModule } from '../contact-details-card/contact-details-card.module';
import { DocumentCardModule } from '../document-card/document-card.module';
import { DriverSpecificDetailsCardModule } from '../driver-specific-details-card/driver-specific-details-card.module';
import { GenCostSetupDataCardModule } from '../gen-cost-setup-data-card/gen-cost-setup-data-card.module';
import { GenericResourcePersonalInfoCardModule } from '../generic-resource-personal-info-card/generic-resource-personal-info-card.module';
import { GuideSpecificDetailsCardModule } from '../guide-specific-details-card/guide-specific-details-card.module';
import { HennaArtistSpecificDetailsCardModule } from '../henna-artist-specific-details-card/henna-artist-specific-details-card.module';
import { KeyControlDetailCardModule } from '../key-control-detail-card/key-control-detail-card.module';
import { NoteInfoCardModule } from '../note-info-card/note-info-card.module';
import { PaymentInfoCardModule } from '../payment-info-card/payment-info-card.module';
import { SupplierAttributesCardModule } from '../supplier-attributes-card/supplier-attributes-card.module';
import { TaxSchemesCardModule } from '../tax-schemes-card/tax-schemes-card.module';
import { GenericResourceDetailViewComponent } from './generic-resource-detail-view.component';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule.forChild(),
        MatExpansionModule,
        ExpandedContentCardModule,
        ExpandedContentCardFooterModule,
        ExpandedContentCardBodyModule,
        GenericResourcePersonalInfoCardModule,
        ContactDetailsCardModule,
        PaymentInfoCardModule,
        TaxSchemesCardModule,
        DocumentCardModule,
        AdditionalContactsCardModule,
        SupplierAttributesCardModule,
        PaymentInfoCardModule,
        AdditionalContactsCardModule,
        NoteInfoCardModule,

        //specific forms
        GuideSpecificDetailsCardModule,
        DriverSpecificDetailsCardModule,
        HennaArtistSpecificDetailsCardModule,
        GenCostSetupDataCardModule,
        KeyControlDetailCardModule

    ],
    declarations: [GenericResourceDetailViewComponent],
    exports: [GenericResourceDetailViewComponent]
})
export class GenericResourceDetailViewModule {}
