import {GroupBy} from '../helper/group-by';
import {SortDirection} from '../helper/sort-direction';

export class ReservationSearchCriteria {
    serviceType = '';
    bookingRefId = '';
    supplierId: number;
    pickUpLocationCode= '';
    dropOffLocationCode= '';
    pickupType= '';
    dropOffType= '';
    driverFirstName= '';
    driverLastName= '';
    driverName= '';
    driverGender= '';
    driverLicenseNumber= '';
    transferType= '';
    vehicleModel= '';
    driverPassportNumber= '';
    driverAge = 0;
    LicensePlateNumber= '';
    paymentCurrency= '';
    paymentMethod= '';
    startTime= '';
    endTime= '';
    flightArrival= '';
    flightArrivalTime= '';
    flightDepartureTime= '';
    flightDeparture= '';
    flightDepartureAirPort= '';
    flightArrivalAirPort= '';
    seatingCapacity = 0;
    route = '';
    facilities: string[];
    status= '';
    groupByType = 0;
    transferMode= '';
    groupBy: GroupBy;
    interval = 0;
    timeRange: TimeRanges;
    size = 0;
    page:number;
    start = 0;
    sortBy= '';
    filterStartTime= '';
    filterEndTime= '';
    sortDirection: SortDirection;
    startPickupTime= '';
    endPickupTime= '';
    date= '';
    startDate= '';
    endDate= '';
    serviceStartTime= ''; // pattern = "HH:mm"
    serviceEndTime= ''; // pattern = "HH:mm"
    groupEndTime= ''; // pattern = "HH:mm"
    vehicleName= '';
    vehicleId: number;
    assignedStatus = '';
    pickupCity= '';
    dropOffCity= '';
    bookingDate= '';
    serviceItemAttributes= '';
    tourName= '';
    flightDepTerminal= '';
    flightArrTerminal= '';
    resourceIdList: number[];
    userId: number;
    criteriaName= '';
    resourceType= '';
    productCode= '';
    contractRef= '';
    contractName= '';
    daysFrom = 0;
    assignmentId = 0;
    privateBooking='';

    // grid sort
    gridSorting: string;
    gridFiltering: string;
    serviceItemIdList: number[];
}

export class FilterCriteria {
    transferMode= '';
}

export class GroupCriteria {
    groupBy: GroupBy;
    startTime= '';
    interval = 0;
    timeRange: TimeRanges
}

export enum AssignedType{
    Assigned = 'Assigned', Unassigned = 'Unassigned', Confirmed = 'Confirmed', SupplierConfirmed = 'SupplierConfirmed', SupplierRejected = 'SupplierRejected'
}

