
<mat-list class="tc-pt-0">
    <!--repeat this-->
    <mat-list-item matRipple class="mat-list-item" [ngClass]="{'mat-list-item--selected' : thread?.isSelected}" *ngFor="let thread of messageThreads; index as i" (click)="selectThread(i,thread)">          <!--add 'mat-list-item--selected' as needed-->
        <img mat-list-avatar [src]="thread?.groupMsgThreadId > 0 ? 'assets/img/user-profile/chat-group.svg' : thread?.icon | async" [alt]="thread.chatHeadDisplayNameShort">
        <div mat-line [ngStyle]="{'font-weight': + (thread?.hasNewNotification) ? '500' : '400'}"><span  class="mat-line__title">{{thread?.chatHeadDisplayNameShort}}</span> <span class="pull-right tc-label">{{thread?.displayTimeStamp}}</span></div>
        <div mat-line><span >{{thread?.mostRecentShortDescription}}</span> <span class="badge tc-btn--primary" *ngIf="thread?.hasNewNotification"><strong>{{thread?.mostRecentNotificationCount}}</strong></span></div>

        <mat-divider></mat-divider>
    </mat-list-item>
<!--    <mat-list-item matRipple>-->
<!--        <img mat-list-avatar src="assets/img/user-profile/hacker.svg" alt="img">-->
<!--        <div mat-line><span>That Guy</span> <span class="pull-right tc-label">Thu</span></div>-->
<!--        <div mat-line>U up?..</div>-->
<!--    </mat-list-item>-->
<!--    <mat-divider></mat-divider>-->
<!--    <mat-list-item matRipple>-->
<!--        <img mat-list-avatar src="assets/img/user-profile/boy.svg" alt="img">-->
<!--        <div mat-line><span>Kim Jong-Un</span> <span class="pull-right tc-label">16 Jul</span></div>-->
<!--        <div mat-line>I will dominate you!</div>-->
<!--    </mat-list-item>-->
<!--    <mat-divider></mat-divider>-->
<!--    <mat-list-item matRipple>-->
<!--        <img mat-list-avatar src="assets/img/user-profile/nutritionist.svg" alt="img">-->
<!--        <div mat-line><span>Annoying Girl</span> <span class="pull-right tc-label">12 Feb, 2018</span></div>-->
<!--        <div mat-line>HI YOU!!! WHATS UPP!!! We should hang out sometime, when are you free?</div>-->
<!--    </mat-list-item>-->
</mat-list>
