import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { FocusViewModule } from '@tc/focus-view';
import { SectionHeadingModule } from '@tc/section-heading';
import { SetupGridModule } from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid.module';
import { DispatchDataModalModule } from '../dispatch-data-modal/dispatch-data-modal.module';
import { DocumentQueueFocusViewComponent } from './document-queue-focus-view.component';

@NgModule({
    declarations: [DocumentQueueFocusViewComponent],
    imports: [
        CommonModule,
        TranslateModule.forChild(),
        FocusViewModule,
        MatButtonModule,
        MatTooltipModule,

        SectionHeadingModule,
        SetupGridModule,
        DispatchDataModalModule
    ],
    exports: [DocumentQueueFocusViewComponent],
    entryComponents: [DocumentQueueFocusViewComponent]
})
export class DocumentQueueFocusViewModule {}
