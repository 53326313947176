<form [formGroup]="accomBookingItemForm">
    <div class="container-fluid direct-client-contact-details-edit tc-card-wrapper">
        <div class="row" style="border-bottom: 1px solid #eee;">
            <div class="col-md-12 col-lg-12">
                <div class="tc-flex-box">
                    <div class="tc-flex-item tc-item-circle">
                        <div class="icon-box">
                            <i class="material-icons" aria-hidden="true">format_list_bulleted</i>
                        </div>
                    </div>
                    <div class="tc-flex-item tc-right-cage">
                        <div class="row">
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                <div class="tc-select-edit dcpiec-select-title">
                                    <mat-form-field>
                                        <input
                                                matInput
                                                formControlName="itemName"
                                                [placeholder]="'Item Name'|translate"
                                                required>
                                        <mat-error
                                                class="mat-error"
                                                role="alert">{{getErrorMessage('itemName')|translate}}</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                <div class="tc-input-edit dcpiec-input-format">
                                    <mat-form-field>
                                        <input
                                                matInput
                                                formControlName="contractReference"
                                                [placeholder]="'Contract Reference'|translate"
                                                required>
                                        <mat-error
                                                class="mat-error"
                                                role="alert">{{getErrorMessage('contractReference')|translate}}</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                <div class="tc-select-edit dcpiec-select-title">
                                    <mat-form-field>
                                        <input
                                                matInput
                                                #trsModeInput
                                                formControlName="defaultSupplier"
                                                [matAutocomplete]="supAutoComplete"
                                                (focus)="onSupplierFocus($event)"
                                                (focusout)="onSupplierFocusOut($event)"
                                                [placeholder]="'Default Supplier'|translate">
                                        <mat-error
                                                class="mat-error"
                                                role="alert">{{getErrorMessage('defaultSupplier')|translate}}</mat-error>
                                    </mat-form-field>
                                    <mat-autocomplete
                                        #supAutoComplete="matAutocomplete"
                                        [displayWith]="displaySupplierFn">
                                        <mat-option
                                                *ngFor="let sup of suppliersFiltered"
                                                [value]="sup"
                                                (onSelectionChange)="onSelectSupplier(sup)">
                                            {{ sup?.name }}
                                        </mat-option>
                                    </mat-autocomplete>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                <div class="tc-input-edit dcpiec-input-frequency">
                                    <mat-form-field>
                                        <input
                                                type="number"
                                                matInput
                                                formControlName="occAdultCount"
                                                [placeholder]="'Adult Count'|translate"
                                                required
                                        >
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                <div class="tc-input-edit dcpiec-input-frequency">
                                    <mat-form-field>
                                        <input
                                                type="number"
                                                matInput
                                                formControlName="occTeenCount"
                                                [placeholder]="'Teen Count'|translate"
                                        >
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                <div class="tc-input-edit dcpiec-input-frequency">
                                    <mat-form-field>
                                        <input
                                                type="number"
                                                matInput
                                                formControlName="occChildCount"
                                                [placeholder]="'Child Count'|translate"
                                        >
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                <div class="tc-input-edit dcpiec-input-frequency">
                                    <mat-form-field>
                                        <input
                                                type="number"
                                                matInput
                                                formControlName="occInfantCount"
                                                [placeholder]="'Infant Count'|translate"

                                        >
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                <div class="tc-select-edit dcpiec-select-title">
                                    <mat-form-field>
                                        <input
                                                matInput [ngxMatDatetimePicker]="pickerStart"
                                                [placeholder]="'Check In'|translate"
                                                formControlName="startDate"
                                                [min]="today"
                                                (dateChange)="onStartDateChange()"
                                                required>
                                        <mat-datepicker-toggle matSuffix [for]="pickerStart">
                                        </mat-datepicker-toggle>
                                        <ngx-mat-datetime-picker
                                            #pickerStart
                                            [showSpinners]="true"
                                            [showSeconds]="false"
                                            [stepHour]="1"
                                            [stepMinute]="1"
                                            [stepSecond]="1"
                                            [touchUi]="false"
                                            [color]="dateTimePickerColor">
                                        </ngx-mat-datetime-picker>
                                        <mat-error
                                                class="mat-error"
                                                role="alert">{{getErrorMessage('startDate')|translate}}</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                <div class="tc-input-edit dcpiec-input-format">
                                    <mat-form-field>
                                        <input
                                                matInput [ngxMatDatetimePicker]="pickerEnd"
                                                [placeholder]="'Check Out'|translate"
                                                formControlName="endDate"
                                                [min]="startTime ? startTime : today"
                                                (dateChange)="onEndDateChange()"
                                                required>
                                        <mat-datepicker-toggle matSuffix [for]="pickerEnd">
                                        </mat-datepicker-toggle>
                                        <ngx-mat-datetime-picker
                                            #pickerEnd
                                            [showSpinners]="true"
                                            [showSeconds]="false"
                                            [stepHour]="1"
                                            [stepMinute]="1"
                                            [stepSecond]="1"
                                            [touchUi]="false"
                                            [color]="dateTimePickerColor">
                                        </ngx-mat-datetime-picker>
                                        <mat-error
                                                class="mat-error"
                                                role="alert">{{getErrorMessage('endDate')|translate}}</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                            </div>
                            <div
                                    class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile"
                                    *ngIf="sameDateTypeItems && sameDateTypeItems.length > 0">
                                <div class="tc-input-edit dcpiec-input-lname">
                                    <mat-checkbox
                                            formControlName="passengerValidated"
                                            (ngModelChange)="onChangeCheckBox($event)">
                                        {{'Validate Passengers' |translate}}
                                    </mat-checkbox>
                                </div>
                            </div>
                            <!--              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">-->
                            <!--                <div class="tc-input-edit dcpiec-input-frequency">-->
                            <!--                  <mat-form-field>-->
                            <!--                    <input-->
                            <!--                            matInput-->
                            <!--                            formControlName="nights"-->
                            <!--                            [placeholder]="'Nights'|translate"-->
                            <!--                            required>-->
                            <!--                    <mat-error-->
                            <!--                            class="mat-error"-->
                            <!--                            role="alert">{{getErrorMessage('nights')|translate}}</mat-error>-->
                            <!--                  </mat-form-field>-->
                            <!--                </div>-->
                            <!--              </div>-->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--    accommodation item specific fields-->
        <div class="row" style="border-bottom: 1px solid #eee;">
            <div class="col-md-12 col-lg-12">
                <div class="tc-flex-box">
                    <div class="tc-flex-item tc-item-circle">
                        <div class="icon-box">
                            <i class="material-icons" aria-hidden="true">hotel</i>
                        </div>
                    </div>
                    <div class="tc-flex-item tc-right-cage">
                        <div class="row">
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                <div class="tc-input-edit dcpiec-input-frequency">
                                    <mat-form-field>
                                        <input
                                                matInput
                                                #trsModeInput
                                                formControlName="roomType"
                                                [matAutocomplete]="typeAutoComplete"
                                                (focus)="onRoomTypeFocus($event)"
                                                (focusout)="onRoomTypeFocusOut($event)"
                                                [placeholder]="'Room Type'|translate">
                                        <mat-error
                                                class="mat-error"
                                                role="alert">{{getErrorMessage('roomType')|translate}}</mat-error>
                                    </mat-form-field>
                                    <mat-autocomplete
                                        #typeAutoComplete="matAutocomplete"
                                        [displayWith]="displayRoomTypeFn">
                                        <mat-option
                                                *ngFor="let type of roomTypesFiltered"
                                                [value]="type"
                                                (onSelectionChange)="onSelectRoomType(type)">
                                            {{ type?.name }}
                                        </mat-option>
                                    </mat-autocomplete>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                <div class="tc-input-edit dcpiec-input-frequency">
                                    <mat-form-field>
                                        <input
                                                matInput
                                                formControlName="roomsRequired"
                                                [placeholder]="'Required Room Count'|translate"
                                        >
                                    </mat-form-field>
                                </div>
                            </div>
                            <!--              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">-->
                            <!--                <div class="tc-input-edit dcpiec-input-frequency">-->
                            <!--                  <mat-form-field>-->
                            <!--                    <input-->
                            <!--                            matInput-->
                            <!--                            #occupancyInput-->
                            <!--                            formControlName="occupancy"-->
                            <!--                            [matAutocomplete]="occAutoComplete"-->
                            <!--                            (focus)="onOccupancyFocus($event)"-->
                            <!--                            (focusout)="onOccupancyFocusOut($event)"-->
                            <!--                            [placeholder]="'Occupancy'|translate">-->
                            <!--                    <mat-error-->
                            <!--                            class="mat-error"-->
                            <!--                            role="alert">{{getErrorMessage('occupancy')|translate}}</mat-error>-->
                            <!--                  </mat-form-field>-->
                            <!--                  <mat-autocomplete-->
                            <!--                          #occAutoComplete="matAutocomplete"-->
                            <!--                          [displayWith]="displayOccupancyFn">-->
                            <!--                    <mat-option-->
                            <!--                            *ngFor="let type of occupanciesFiltered"-->
                            <!--                            [value]="type"-->
                            <!--                            (onSelectionChange)="onSelectOccupancy(type)">-->
                            <!--                      {{ type?.occupancyName }}-->
                            <!--                    </mat-option>-->
                            <!--                  </mat-autocomplete>-->
                            <!--                </div>-->
                            <!--              </div>-->
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                <div class="tc-input-edit dcpiec-input-country">
                                    <mat-form-field>
                                        <input
                                                matInput
                                                #cityInput
                                                formControlName="city"
                                                [matAutocomplete]="cityAutoComplete"
                                                (focus)="onCityFocus($event)"
                                                (focusout)="onCityFocusOut($event)"
                                                [placeholder]="'City'|translate">
                                        <mat-error
                                                class="mat-error"
                                                role="alert">{{getErrorMessage('city')|translate}}</mat-error>
                                    </mat-form-field>
                                    <mat-autocomplete
                                        #cityAutoComplete="matAutocomplete"
                                        [displayWith]="displayCityFn">
                                        <mat-option
                                                *ngFor="let type of citiesFiltered"
                                                [value]="type"
                                                (onSelectionChange)="onSelectCity(type)">
                                            {{ type?.name }}
                                        </mat-option>
                                    </mat-autocomplete>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                <div class="tc-input-edit dcpiec-input-frequency">
                                    <mat-form-field>
                                        <input
                                                matInput
                                                #bbInput
                                                formControlName="boardBasis"
                                                [matAutocomplete]="bbAutoComplete"
                                                (focus)="onBoardBasisFocus($event)"
                                                (focusout)="onBoardBasisFocusOut($event)"
                                                [placeholder]="'Board Basis'|translate">
                                        <mat-error
                                                class="mat-error"
                                                role="alert">{{getErrorMessage('boardBasis')|translate}}</mat-error>
                                    </mat-form-field>
                                    <mat-autocomplete
                                        #bbAutoComplete="matAutocomplete"
                                        [displayWith]="displayBoardBasisFn">
                                        <mat-option
                                                *ngFor="let type of boardBasisFiltered"
                                                [value]="type"
                                                (onSelectionChange)="onSelectBoardBasis(type)">
                                            {{ type?.name }}
                                        </mat-option>
                                    </mat-autocomplete>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <tc-item-notes
                [readonly]="readonly"
                [noteList]="bookingItem.itemNotes"
                [heading]="'Notes'"
                (changeValidity)="onNotesChange($event)"
                class="bdv-booking-notes-summary-wrapper">
        </tc-item-notes>
<!--        <tc-booking-item-notes-->
<!--                [bookingItem]="bookingItem"-->
<!--                [noteTypes]="noteTypes"-->
<!--                [readonly]="readonly"-->
<!--                (changeValidity)="onNotesChange($event)">-->
<!--        </tc-booking-item-notes>-->
    </div>
</form>
