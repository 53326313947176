<div class="tc-sc__header">
  <div class="tc-flex-box">
    <h4 class="tc-flex-item">{{calendarNavData.showingYear}}</h4>
    <div class="tc-sc__navigation-icons tc-flex-item action-icon tc-ml-1 tc-mt-1">
      <span
        (click)="onClickPreviousBtn()" class=""
        matTooltip="{{'Previous' | translate}}">
        <mat-icon>keyboard_arrow_up</mat-icon>
      </span>
      <span
        (click)="onClickNextBtn()" class=""
        matTooltip="{{'Next' | translate}}">
        <mat-icon>keyboard_arrow_down</mat-icon>
      </span>
    </div>
  </div>
  <div class="tc-flex-box">
    <div
      *ngIf="validCalendarOptions && validCalendarOptions.length>0"
      class=" tc-icon-block__icon tc-flex-item">
      <tc-chip-dropdown
        class="transfer-mode-selection"
        [dropdownOptions]="validCalendarOptions"
        (selectedOption)="onSelectCalendarType($event)">
      </tc-chip-dropdown>
    </div>
    <div *ngIf="calendarMenuItems && calendarMenuItems.length">
    <button mat-icon-button
            class="tc-icon-block__icon tc-icon-block__icon--active"
            [matMenuTriggerFor]="menu"><mat-icon>more_vert</mat-icon></button>

      <mat-menu #menu="matMenu" xPosition="before">
        <button *ngFor="let menuItem of calendarMenuItems" mat-menu-item (click)="onMenuItemClick(menuItem)">
          <mat-icon>{{menuItem.matIcon}}</mat-icon>
          <span>{{menuItem.title | translate}}</span>
        </button>
      </mat-menu>
    </div>
  </div>
</div>
