<div class="container-fluid tc-item-wrapper tcpi-card">
    <div class="tc-item-wrapper__header tc-item-header-highlighted" *ngIf="showTitle">
        <div class="tc-item-header-highlighted__icon">
            <i class="material-icons material-icons">account_box</i>
        </div>
        <div class="tc-item-header-highlighted__primary">{{"Contact details" | translate}}</div>
    </div>
    <div class="tc-item-wrapper__body">
        <div *ngIf="contact">
            <div class="row">
                <div class="col-md-12">
                    <div class="tc-flex-box">
                        <div class="tc-flex-item icon-cage input-icon">
                            <div class="card-icon--small icn-color-primary">
                                <i class="material-icons material-icons">account_box</i>
                            </div>
                        </div>
                        <div class="tc-flex-item input-cage">
                            <div class="col-xs-12 col-sm-6  col-md-3 col-lg-3">
                                <div class="tc-content--wrap tc-input-view">
                                    <div class="tc-caption-txt txt-light">
                                        {{"Category" | translate}}
                                    </div>
                                    <div class="tc-body1--txt">
                                        {{contact?.category ? contact.category : EMPTY_CHAR}}
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-6  col-md-3 col-lg-3">
                                <div class="tc-content--wrap tc-input-view">
                                    <div class="tc-caption-txt txt-light">
                                        {{"Title" | translate}}
                                    </div>
                                    <div class="tc-body1--txt  ">
                                        {{contact?.title ? contact.title : EMPTY_CHAR}}
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-6  col-md-3 col-lg-3">
                                <div class="tc-content--wrap tc-input-view">
                                    <div class="tc-caption-txt txt-light">
                                        {{"First Name" | translate}}
                                    </div>
                                    <div class="tc-body1--txt  ">
                                        {{contact?.firstName ? contact.firstName : EMPTY_CHAR}}
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                                <div class="tc-content--wrap tc-input-view">
                                    <div class="tc-caption-txt txt-light">
                                        {{"Last Name" | translate}}
                                    </div>
                                    <div class="tc-body1--txt  ">
                                        {{contact?.lastName ? contact.lastName : EMPTY_CHAR}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="tc-flex-box">
                        <div class="tc-flex-item icon-cage ">
                            <div class="card-icon--small icn-color-primary"></div>
                        </div>
                        <div class="tc-flex-item input-cage ">
                            <div class="col-xs-12 col-sm-6  col-md-3 col-lg-3">
                                <div class="tc-content--wrap tc-input-view">
                                    <div class="tc-caption-txt txt-light">
                                        {{"Email" | translate}}
                                    </div>
                                    <div class="tc-body1--txt">
                                        {{contact?.email ? contact.email : EMPTY_CHAR}}
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-6  col-md-3 col-lg-3">
                                <div class="tc-content--wrap tc-input-view">
                                    <div class="tc-caption-txt txt-light">
                                        {{"Alternate Email" | translate}}
                                    </div>
                                    <div class="tc-body1--txt">
                                        {{contact?.altEmail ? contact.altEmail : EMPTY_CHAR}}
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-6  col-md-3 col-lg-3">
                                <div class="tc-content--wrap tc-input-view">
                                    <div class="tc-caption-txt txt-light">
                                        {{"Telephone Number" | translate}}
                                    </div>
                                    <div class="tc-body1--txt">
                                        {{contact?.telNum ? contact.telNum : EMPTY_CHAR}}
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-6  col-md-3 col-lg-3">
                                <div class="tc-content--wrap tc-input-view">
                                    <div class="tc-caption-txt txt-light">
                                        {{"Alternate Telephone Number" | translate}}
                                    </div>
                                    <div class="tc-body1--txt">
                                        {{contact?.altTelNum ? contact.altTelNum : EMPTY_CHAR}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="tc-flex-box">
                        <div class="tc-flex-item icon-cage ">
                            <div class="card-icon--small icn-color-primary"></div>
                        </div>
                        <div class="tc-flex-item input-cage ">
                            <div class="col-xs-12 col-sm-6  col-md-3 col-lg-3">
                                <div class="tc-content--wrap tc-input-view">
                                    <div class="tc-caption-txt txt-light">
                                        {{"Country" | translate}}
                                    </div>
                                    <div class="tc-body1--txt">
                                        {{contact?.country ? contact?.countryName : EMPTY_CHAR}}
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-6  col-md-3 col-lg-3">
                                <div class="tc-content--wrap tc-input-view">
                                    <div class="tc-caption-txt txt-light">
                                        {{"State" | translate}}
                                    </div>
                                    <div class="tc-body1--txt">
                                        {{contact?.stateName ? contact.stateName : EMPTY_CHAR}}
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-6  col-md-3 col-lg-3">
                                <div class="tc-content--wrap tc-input-view">
                                    <div class="tc-caption-txt txt-light">
                                        {{"City" | translate}}
                                    </div>
                                    <div class="tc-body1--txt">
                                        {{contact?.city ? contact.cityName : EMPTY_CHAR}}
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-6  col-md-3 col-lg-3">
                                <div class="tc-content--wrap tc-input-view">
                                    <div class="tc-caption-txt txt-light">
                                        {{"Post Code" | translate}}
                                    </div>
                                    <div class="tc-body1--txt">
                                        {{contact?.postCode ? contact.postCode : EMPTY_CHAR}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="tc-flex-box">
                        <div class="tc-flex-item icon-cage ">
                            <div class="card-icon--small icn-color-primary"></div>
                        </div>
                        <div class="tc-flex-item input-cage ">
                            <!--                            <div class="col-xs-12 col-sm-6  col-md-3 col-lg-3">-->
                            <!--                                <div class="tc-content&#45;&#45;wrap tc-input-view">-->
                            <!--                                    <div class="tc-caption-txt txt-light">-->
                            <!--                                        {{"Mobile Number" | translate}}-->
                            <!--                                    </div>-->
                            <!--                                    <div class="tc-body1&#45;&#45;txt">-->
                            <!--                                        {{contact?.mobNum ? contact.mobNum : EMPTY_CHAR}}-->
                            <!--                                    </div>-->
                            <!--                                </div>-->
                            <!--                            </div>-->
                            <div class="col-xs-12 col-sm-6  col-md-3 col-lg-3">
                                <div class="tc-content--wrap tc-input-view">
                                    <div class="tc-caption-txt txt-light">
                                        {{"Fax Number" | translate}}
                                    </div>
                                    <div class="tc-body1--txt">
                                        {{contact?.faxNum ? contact.faxNum : EMPTY_CHAR}}
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-6  col-md-3 col-lg-3">
                                <div class="tc-content--wrap tc-input-view">
                                    <div class="tc-caption-txt txt-light">
                                        {{getCardPlaceHolders('STREET_ADDRESS')?.title | translate}}
                                    </div>
                                    <div class="tc-body1--txt" [matTooltip]="contact?.street ? contact.street : EMPTY_CHAR">
                                        {{contact?.street ? contact.street : EMPTY_CHAR}}
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-6  col-md-3 col-lg-3">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
