export class PaymentInfo {
    paymentId: number;
    bankAccNum: string;
    bankName: string;
    currency: string;
    beneficiaryName: string;
    iban: string;
    paymentMethod: string;
    paymentMethodName: string;
    sortCode: string;
    swiftCode: string;
}
