<div class="rs-section-wrapper container-fluid">
  <div class="">
    <div class="rs-item-card__container">
      <ng-container *ngFor="let remark of remarks; let i = index;">
        <div
                tcExpansionPanel
                tabindex="-1"
                [enabled]="true"
                (collapse)="expandedPanelIndex = -1">

          <!-- Content card Strip -->
          <div id="clientSumExp_{{i}}">

            <tc-note
                    [remark]="remark"
                    (deleteNote)="onDelete($event)"
                    (saveNote)="onNoteSave($event)">
            </tc-note>

          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
