/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./passenger-list-grid.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/ag-grid-angular/dist/agGridAngular.ngfactory";
import * as i3 from "ag-grid-angular/dist/angularFrameworkOverrides";
import * as i4 from "ag-grid-angular/dist/angularFrameworkComponentWrapper";
import * as i5 from "ag-grid-angular/dist/agGridAngular";
import * as i6 from "./passenger-list-grid.component";
import * as i7 from "../../services/util/pre-processors/grid-column-definition-processor.service";
import * as i8 from "@ngx-translate/core";
var styles_PassengerListGridComponent = [i0.styles];
var RenderType_PassengerListGridComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PassengerListGridComponent, data: {} });
export { RenderType_PassengerListGridComponent as RenderType_PassengerListGridComponent };
export function View_PassengerListGridComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 4, "ag-grid-angular", [["class", "ag-theme-material tc-ag-grid tc-ag-grid--popup-content"], ["style", "width: 630px; margin: 0 auto;"]], null, [[null, "gridReady"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("gridReady" === en)) {
        var pd_0 = (_co.onGridReady($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_AgGridAngular_0, i2.RenderType_AgGridAngular)), i1.ɵprd(512, null, i3.AngularFrameworkOverrides, i3.AngularFrameworkOverrides, [i1.NgZone]), i1.ɵprd(512, null, i4.AngularFrameworkComponentWrapper, i4.AngularFrameworkComponentWrapper, []), i1.ɵdid(3, 4898816, null, 1, i5.AgGridAngular, [i1.ElementRef, i1.ViewContainerRef, i3.AngularFrameworkOverrides, i4.AngularFrameworkComponentWrapper, i1.ComponentFactoryResolver], { rowData: [0, "rowData"], columnDefs: [1, "columnDefs"], defaultColDef: [2, "defaultColDef"], pagination: [3, "pagination"], paginationAutoPageSize: [4, "paginationAutoPageSize"] }, { gridReady: "gridReady" }), i1.ɵqud(603979776, 1, { columns: 1 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.passengerList; var currVal_1 = _co.columnDefs; var currVal_2 = _co.defaultColDef; var currVal_3 = true; var currVal_4 = true; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
export function View_PassengerListGridComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "tc-passenger-list-grid", [], null, null, null, View_PassengerListGridComponent_0, RenderType_PassengerListGridComponent)), i1.ɵdid(1, 114688, null, 0, i6.PassengerListGridComponent, [i1.ChangeDetectorRef, i7.GridColumnDefinitionProcessorService, i8.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PassengerListGridComponentNgFactory = i1.ɵccf("tc-passenger-list-grid", i6.PassengerListGridComponent, View_PassengerListGridComponent_Host_0, { passengerList: "passengerList", colDefConfig: "colDefConfig", defaultColDef: "defaultColDef", gridOptions: "gridOptions" }, {}, []);
export { PassengerListGridComponentNgFactory as PassengerListGridComponentNgFactory };
