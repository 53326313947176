import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { PickupDropoffDetailsComponent } from './pickup-dropoff-details.component';
import { MatTabsModule } from '@angular/material';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    MatTabsModule
  ],
  exports: [
    PickupDropoffDetailsComponent
  ],
  declarations: [PickupDropoffDetailsComponent]
})
export class PickupDropoffDetailsModule {
}
