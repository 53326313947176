import { Injectable } from '@angular/core';
import { PassengerCount } from '../../../models/reservation/passenger-count';
import { ServiceAssignment } from '../../../models/reservation/service-assignment';
import { ServiceItem } from '../../../models/reservation/service-item';
import { TrsSupplierCostSummary } from '../../../models/summary/supplier-cost-summary';
import { SupplierSummaryDepricated } from '../../../models/summary/supplier-summary-depricated';
import { AssignmentCostData } from '../../../models/reservation/assignment-cost-data';
import { ResourceAvailability } from '../../../models/resource/resource-availability';
import {GenTourItem} from "../../../models/reservation/generic/gen-tour-item";

@Injectable()
export class CostCalculationUtilsService {

    constructor() {
    }

    public getSupplierCostDataForAssignment(
        resource: ResourceAvailability, isBulkAssignment: boolean, bookingData: ServiceItem,
        bookingDataArray: ServiceItem[]
    ): AssignmentCostData {
        let assignmentCostData: AssignmentCostData;
        let paxCount = this.getTotalSpecificPassengerCount(isBulkAssignment, bookingData, bookingDataArray);
        if (resource.trsSupplier.unitFare) {
            assignmentCostData.jobCost = resource.trsSupplier.unitFare;
        } else {
            let perPsersonCost = 0;
            perPsersonCost = resource.assignments[0].jobCost / resource.assignments[0].allocatedCount;
            resource.assignments[0].allocatedCount = +paxCount;
            assignmentCostData.jobCost = perPsersonCost * resource.assignments[0].allocatedCount;
        }

        return assignmentCostData;
    }

    public getTotalPassengerCount(
        isBulkAssignment: boolean, bookingData: ServiceItem, bookingDataArray: ServiceItem[]): number {
        let total = 0;
        if (isBulkAssignment && bookingDataArray) {
            bookingDataArray.forEach(
                value => {
                    if (value['passengerCount']) {
                        total += value['passengerCount'];
                    }

                }
            );
        } else if (bookingData) {
            if (bookingData.servicePassengers) {
                total = bookingData.servicePassengers.length;
            }
        }
        return total;
    }

    public getTotalPassengerCountGen(bookingData: GenTourItem): number {
        let total = 0;
        if (bookingData) {
            bookingData.serviceItems.forEach(item=>{
                // if(item.checked){
                    total = total + item.servicePassengers.length;
                // }
            })
        }
        return total;
    }

    public getTotalSpecificPassengerCountGen( genTourItem: any ): PassengerCount {
        let passengerCount = new PassengerCount();
        passengerCount.adult = 0;
        passengerCount.children = 0;
        passengerCount.infants = 0;
        passengerCount.total = 0;
        if (genTourItem) {
            let serviceItems: Array<ServiceItem> = genTourItem.serviceItems;
            serviceItems.forEach(item=>{
                // if(item.checked){
                    if (item.adult) {
                        passengerCount.adult = item.adult;
                    }
                    if (item.child) {
                        passengerCount.children = item.child;
                    }
                    if (item.infant) {
                        passengerCount.infants = item.infant;
                    }
                // }
            });
            passengerCount.total = passengerCount.adult + passengerCount.children + passengerCount.infants;
        }
        return passengerCount;
    }

    public getTotalSpecificPassengerCount(
        isBulkAssignment: boolean, bookingData: ServiceItem, bookingDataArray: ServiceItem[]): PassengerCount {
        let passengerCount = new PassengerCount();
        passengerCount.adult = 0;
        passengerCount.children = 0;
        passengerCount.infants = 0;
        passengerCount.total = 0;
        if (isBulkAssignment && bookingDataArray) {
            bookingDataArray.forEach(
                value => {
                    if (value['rawData']['adult']) {
                        passengerCount.adult += value['rawData']['adult'];
                    }
                    if (value['rawData']['child']) {
                        passengerCount.children += value['rawData']['child'];
                    }
                    if (value['rawData']['infant']) {
                        passengerCount.infants += value['rawData']['infant'];
                    }
                }
            );
        } else {
            passengerCount = this.getPassengerCountForServiceItem(bookingData);
        }
        passengerCount.total = passengerCount.adult + passengerCount.children + passengerCount.infants;
        return passengerCount;
    }

    public getSpecificPassengerCount(
        serviceItemId: number, isBulkAssignment: boolean, bookingData: ServiceItem,
        bookingDataArray: ServiceItem[]
    ): number {
        let data: ServiceItem = null;
        if (isBulkAssignment && bookingDataArray) {
            let summaryData = bookingDataArray.find(value => value.serviceItemId === serviceItemId);
            data = summaryData['rawData'];
        } else if (bookingData) {
            data = bookingData;
        }
        let count = 0;
        if (data && data.servicePassengers && data.servicePassengers.length) {
            count = data.servicePassengers.length;
        }
        return count;
    }

    /*public getCost(
     supplier: SupplierSummaryDepricated, serviceItemId, estimated: boolean, isBulkAssignment: boolean,
     bookingData: ServiceItem, bookingDataArray: ServiceItem[]
     ): number {
     let cost = 0;
     let unitCost = 0;
     if (estimated) {
     unitCost = Number(supplier.estimatedUnitCost);
     } else {
     unitCost = Number(supplier.cost);
     }

     /!*if (supplier.costUnit === 'Per Person') {
     cost = unitCost * this.getSpecificPassengerCount(serviceItemId,isBulkAssignment,bookingData,bookingDataArray);
     } else {
     cost = unitCost *
     (this.getSpecificPassengerCount(serviceItemId,isBulkAssignment,bookingData,bookingDataArray) / this.getTotalPassengerCount(isBulkAssignment,bookingData,bookingDataArray));
     }*!/
     return cost;
     }*/

    public getSupplierTotalCost(
        supplier: TrsSupplierCostSummary, isBulkAssignment: boolean, bookingData: any,
        bookingDataArray: ServiceItem[], isGenResourceAssignment?: boolean
    ) {
        let supplierCostSummary = new TrsSupplierCostSummary;
        if (!isBulkAssignment) {
            if(isGenResourceAssignment != undefined && isGenResourceAssignment){
                let adultCount = 0;
                let childCount = 0;
                let infantCount = 0;
                let serviceItems: Array<ServiceItem> = bookingData.serviceItems;
                serviceItems.forEach(item=>{
                    // if(item.checked){
                        adultCount = item.adult;
                        childCount = item.child;
                        infantCount = item.infant;
                    // }
                });
                supplierCostSummary.adultFare = (supplier.adultFare * adultCount);
                supplierCostSummary.childFare = (supplier.childFare * childCount);
                supplierCostSummary.infantFare = (supplier.infantFare * infantCount);
                supplierCostSummary.totalCost = supplierCostSummary.adultFare + supplierCostSummary.childFare +
                    supplierCostSummary.infantFare;
            }else{
                supplierCostSummary.adultFare = (supplier.adultFare * bookingData.adult);
                supplierCostSummary.childFare = (supplier.childFare * bookingData.child);
                supplierCostSummary.infantFare = (supplier.infantFare * bookingData.infant);
                supplierCostSummary.totalCost = supplierCostSummary.adultFare + supplierCostSummary.childFare +
                    supplierCostSummary.infantFare;
            }
        } else if (isBulkAssignment && bookingDataArray) {
            let adultCount = 0;
            let childCount = 0;
            let infantCount = 0;
            bookingDataArray.forEach(
                value => {
                    if (value['rawData']['adult']) {
                        adultCount += value['rawData']['adult'];
                    }
                    if (value['rawData']['child']) {
                        childCount += value['rawData']['child'];
                    }
                    if (value['rawData']['infant']) {
                        infantCount += value['rawData']['infant'];
                    }
                }
            );
            supplierCostSummary.adultFare = supplier.adultFare * adultCount;
            supplierCostSummary.childFare = supplier.childFare * childCount;
            supplierCostSummary.infantFare = supplier.infantFare * infantCount;
            supplierCostSummary.totalCost = supplierCostSummary.adultFare + supplierCostSummary.childFare +
                supplierCostSummary.infantFare;
        }
        return supplierCostSummary;
    }

    public getGenSupplierTotalCost(assignment: ServiceAssignment) {
        let supplierCostSummary = new TrsSupplierCostSummary;
        supplierCostSummary.totalCost = 0;
        supplierCostSummary.adultFare = 0;
        supplierCostSummary.childFare = 0;
        supplierCostSummary.teenFare = 0;
        supplierCostSummary.infantFare = 0;
        if (assignment) {
            if (assignment.unitPrice) {
                supplierCostSummary.totalCost = assignment.unitPrice;
            } else {
                if (assignment.adults) {
                    if (assignment.adultPrice) {
                        supplierCostSummary.adultFare = (assignment.adultPrice * assignment.adults);
                    }
                    supplierCostSummary.totalCost += supplierCostSummary.adultFare;
                }
                if (assignment.teens) {
                    if (assignment.teenPrice) {
                        supplierCostSummary.teenFare = (assignment.teenPrice * assignment.teens);
                    } else if (assignment.adultPrice) {
                        supplierCostSummary.teenFare = (assignment.adultPrice * assignment.teens);
                    }
                    supplierCostSummary.totalCost += supplierCostSummary.teenFare;
                }
                if (assignment.children) {
                    if (assignment.childPrice) {
                        supplierCostSummary.childFare = (assignment.childPrice * assignment.children);
                    } else if (assignment.adultPrice) {
                        supplierCostSummary.childFare = (assignment.adultPrice * assignment.children);
                    }
                    supplierCostSummary.totalCost += supplierCostSummary.childFare;
                }
                if (assignment.infants) {
                    if (assignment.infantPrice) {
                        supplierCostSummary.infantFare = (assignment.infantPrice * assignment.infants);
                    } else if (assignment.adultPrice) {
                        supplierCostSummary.infantFare = (assignment.adultPrice * assignment.infants);
                    }
                    supplierCostSummary.totalCost += supplierCostSummary.infantFare;
                }

            }
        }
        return supplierCostSummary.totalCost;
    }

    public getSupplierTotalFromUnitCost(
        supplier: TrsSupplierCostSummary, isBulkAssignment: boolean, bookingData: any,
        bookingDataArray: ServiceItem[], isGenResourceAssignment?: boolean
    ) {
        let total = 0;
        let passengerCount = (isGenResourceAssignment != undefined && isGenResourceAssignment)?this.getTotalPassengerCountGen(bookingData):this.getTotalPassengerCount(isBulkAssignment, bookingData, bookingDataArray);
        if (supplier.capacity > 0 && passengerCount < supplier.capacity) {
            total = parseFloat(((supplier.unitFare / supplier.capacity) * passengerCount).toFixed(2));
        } else {
            return supplier.unitFare;
        }
        return total;
    }

    getPassengerCountForServiceItem(serviceItem: ServiceItem) {
        let passengerCount = new PassengerCount();
        if (serviceItem) {
            if (serviceItem.adult) {
                passengerCount.adult = serviceItem.adult;
            }
            if (serviceItem.child) {
                passengerCount.children = serviceItem.child;
            }
            if (serviceItem.infant) {
                passengerCount.infants = serviceItem.infant;
            }
            passengerCount.total = passengerCount.adult + passengerCount.children + passengerCount.infants;
        }
        return passengerCount;
    }

    calculateServiceItemJobCost(serviceItem: ServiceItem, assignment: ServiceAssignment) {
        let totalCost = 0;
        let assignmentPaxCount = assignment.adults + assignment.children + assignment.infants;
        let itemPaxCount = serviceItem.servicePassengers ? serviceItem.servicePassengers.length : 1;
        if (assignmentPaxCount != itemPaxCount) {
            if (assignment.unitPrice) {
                totalCost = (assignment.unitPrice / assignmentPaxCount) * itemPaxCount;
            } else if (assignment.adultPrice) {
                let adultPrice = assignment.adults ?
                    (assignment.adultPrice / assignment.adults) * serviceItem.adult :
                    0;
                let childPrice = assignment.children ?
                    (assignment.adultPrice / assignment.children) * serviceItem.adult :
                    0;
                let infantPrice = assignment.infants ?
                    (assignment.adultPrice / assignment.infants) * serviceItem.adult :
                    0;
                totalCost = adultPrice + childPrice + infantPrice;
            }
        }
        else {
            totalCost = assignment.jobCost;
        }

        return totalCost;
    }
}
