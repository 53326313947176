import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { TcErrorType } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-error';
import { DialogModel } from '@tc-core/model/it/codegen/ui/framework/dialog-model';
import { LEVEL, ModalData } from '@tc-core/model/it/codegen/ui/framework/modal-data';
import { PageSection } from '@tc-core/model/it/codegen/ui/framework/page-section';
import { TC } from '@tc-core/util';
import { ConfigLoader, EventManager, UserJourneyManager } from '@tc-core/util/framework';
import { SpinnerService } from '@tc-core/util/ui';
import { DialogService } from '@tc/dialog/dialog.service';
import { FocusViewService } from '@tc/focus-view/focus-view.service';
import { ModalService } from '@tc/modal/modal.service';
import { Subscription } from 'rxjs';
import { AutoCompleteEditorComponent } from '../../../../widgets/framework/ag-grid-custom-components/editors/auto-complete-editor/auto-complete-editor.component';
import { CheckBoxSelectorComponent } from '../../../../widgets/framework/ag-grid-custom-components/renderers/check-box-selector/check-box-selector.component';
import { ActionColumnComponent } from '../../../../widgets/framework/ag-grid/action-column.component';
import { AutoCompleteComponent } from '../../../../widgets/framework/ag-grid/auto-complete.component';
import { CurrencyInputEditorComponent } from '../../../../widgets/framework/ag-grid/currency-input-editor.component';
import { MultiSelectComponent } from '../../../../widgets/framework/ag-grid/multi-select.component';
import { NumericEditorComponent } from '../../../../widgets/framework/ag-grid/numeric-editor.component';
import { FileUploaderComponent } from '../../../../widgets/framework/file-uploader/file-uploader.component';
import { DateRange } from '../../../../widgets/framework/cg-calendar/date-range';
import { RangeGroup } from '../../../../widgets/framework/cg-calendar/range-group';
import { SeasonSetupSummaryComponent } from '../../../../widgets/framework/cg-season-calendar/season-setup-summary/season-setup-summary.component';
import { DocumentCreationCriteria, DocumentFormat, DocumentType } from '../../../../models/criteria/document-creation-criteria';
import { DocumentDataCriteria } from '../../../../models/criteria/document-data-criteria';
import { ResourceType } from '../../../../models/reservation/assignment';
import { Room } from '../../../../models/supplier/room';
import { RoomOccupancy } from '../../../../models/supplier/room-occupancy';
import { RoomResourceCostSetup } from '../../../../models/supplier/room-resource-cost';
import { Season } from '../../../../models/supplier/season';
import { Supplier } from '../../../../models/supplier/supplier';
import { DocumentService } from '../../../../services/backend-consumers/documents/document.service';
import { DataHandlerService } from '../../../../services/backend-consumers/master-data-handler-service/data-handler.service';
import { SupplierService } from '../../../../services/backend-consumers/supplier-service/supplier.service';
import { DMCCommon } from '../../../../services/util/common/dmc-common';
import { DataKey, DataStoreService } from '../../../../services/util/framework/data-store.service';
import { ResponseUtil } from '../../../../services/util/response/response-util.service';
import { CardDataHandlerService } from '../../../../services/util/ui/card-data-handler-service.service';
import { ContentSummaryCardService } from '../../../../services/util/ui/content-summary-card.service';

function removeSelectedData(selectedData: any) {
    for (let i = 0; i < this.resourceCosts.length; i++) {
        if (this.resourceCosts[i].tempCostId === selectedData.tempCostId) {
            this.resourceCosts.splice(i, 1);
            break;
        }
    }
}

@Component({
    selector: 'tc-room-cost-setup-focus-view',
    templateUrl: './room-cost-setup-focus-view.component.html'
})
export class RoomCostSetupFocusViewComponent implements OnInit, AfterViewInit {
    @Input() rooms: Room[] = [];
    @Input() supplierCostSetup: Season[] = [];
    @Input() resourceCosts: RoomResourceCostSetup[] = [];
    @Input() selectedResourceTypes: any[] = [];
    @Input() selectedProfileTypes: any[] = [];
    @Input() currencyList: any[] = [];
    @Input() costingTypeList: any[] = [];
    @Input() daysOfWeek: any[] = [];
    @Input() defaultProfileType: any;
    @Input() resourceTypes: any[] = [];

    boardBasisTypes = [
        {
            name: 'Full Board',
            code: 'Full Board'
        },
        {
            name: 'Half Board',
            code: 'Half Board'
        }
    ];

    title = 'app';
    seasons = [];
    isInvalidCost = false;
    isCostMissing = false;
    private gridApi;
    private gridColumnApi;
    columnDefs;
    rowData;
    defaultColDef;
    pinnedTopRowData;
    pinnedBottomRowData;
    rowClassRules;
    frameworkComponents;
    allowedProfTypes: any[] = [];
    rangeGroupsForSeasonCalendar: RangeGroup[] = [];
    private pageSectionsMap: Map<string, PageSection>;
    public costAddSection: PageSection;
    private CARD_CONFIG_GENERIC_EDIT = 'supplier_edit';
    private genericCostSheetImportSubscribe: Subscription = new Subscription();
    private genCostSheetUploadSubscribe: Subscription = new Subscription();
    private supplierDataSubscribe: Subscription = new Subscription();
    private resourceCostSeasons: Season[];
    paginationPageSize: number;

    allDayKeys = '';
    supplierId: number;

    private templateName = 'SUPPLIER_GEN_COST_SHEET';

    constructor(
        private em: EventManager,
        private configLoader: ConfigLoader,
        private journeyManager: UserJourneyManager,
        private dataStore: DataStoreService,
        private focusViewService: FocusViewService,
        private summaryCardService: ContentSummaryCardService,
        private dataHandlerService: DataHandlerService,
        private spinnerService: SpinnerService,
        private cardDataHandlerService: CardDataHandlerService,
        private dialogService: DialogService,
        private documentService: DocumentService,
        private modalService: ModalService,
        private common: DMCCommon,
        private supplierService: SupplierService
    ) {

        this.frameworkComponents = {
            autoComplete: AutoCompleteComponent,
            currencyInput: CurrencyInputEditorComponent,
            numericInput: NumericEditorComponent,
            actionColumnComponent: ActionColumnComponent,
            multiSelect: MultiSelectComponent
        };
        this.defaultColDef = {
            editable: true,
            resizable: true

        };
        this.paginationPageSize = 10;

        this.rowClassRules = {
            'tc-ag-grid-row--error': (params) => params.data.isInvalid
        };
    }

    ngOnInit() {
        this.initPageSectionReferences();
        this.mapToSeasonCalendarRanges();
        this.getAllDayKeys();

        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'CLOSE_FOCUS_VIEW').subscribe(e => {

            this.dataStore.set(DataKey.supplierCostFocusViewClose, null);
            this.dataStore.set(DataKey.supplierCostFocusViewClose, true);
            this.saveResourceCostData();
            this.focusViewService.close(true);

            this.journeyManager.goForKey('GO_TO_ADD_TRANSFER_SUPPLIER');
        });

        if (this.supplierCostSetup !== null && this.supplierCostSetup.length > 0) {
            this.supplierId = this.supplierCostSetup[0].supplierId;
            this.supplierCostSetup.forEach(item => {
                this.seasons.push({
                    code: item.tempSeasonId,
                    name: item.name
                });
            });

        }
        this.getColumnDefs();

    }

    private initPageSectionReferences() {
        this.pageSectionsMap = this.cardDataHandlerService.getAllPageSections(
            this.CARD_CONFIG_GENERIC_EDIT,
            this.resourceCosts
        );

        this.costAddSection = this.pageSectionsMap.get('supplier_room_cost_setup');

    }

    private mapToSeasonCalendarRanges() {
        this.supplierCostSetup.forEach(season => {
            const rangeGroup = new RangeGroup();
            rangeGroup.id = season.tempSeasonId;
            rangeGroup.name = season.name;
            rangeGroup.colorId = season.colorId;

            const dateRanges: DateRange[] = [];
            for (const seasonDateRange of season.seasonDateRanges) {
                const dateRange = new DateRange();
                dateRange.id = seasonDateRange.dateRangeId;
                dateRange.startDate = new Date(seasonDateRange.startDate);
                dateRange.endDate = new Date(seasonDateRange.endDate);
                dateRanges.push(dateRange);
            }
            rangeGroup.dateRanges = dateRanges;
            this.rangeGroupsForSeasonCalendar.push(rangeGroup);
        });
    }

    public onChangeCostSetupForm(event) {
        this.dataStore.set(DataKey.supplierTrsCostSetupValid, event, true);
    }

    saveResourceCostData() {
        this.addDataFromGrid(this.gridApi, this.resourceCosts);
        this.dataStore.set(DataKey.roomResourceCosts, null, true);
        this.isInvalidCost = !!(this.resourceCosts && this.resourceCosts.length > 0 &&
            this.resourceCosts.find(value => value.isInvalid));
        // for (let i = 0; i < this.resourceCosts.length; i++) {
        //     let cost = this.resourceCosts[i];
        //     if (cost.resourceType && cost.profileType && cost.tempSeasonId && cost.dayKeys) {
        //         if (cost.seasonName instanceof Object) {
        //             cost.tempSeasonId = cost.seasonName.code;
        //         }
        //         this.isInvalidCost = false;
        //         break;
        //     } else {
        //         this.isInvalidCost = true;
        //         break;
        //     }
        // }
        this.dataStore.set(DataKey.roomResourceCosts, this.resourceCosts, true);
        this.dataStore.set(DataKey.supplierRoomCostSetupValid, !this.isInvalidCost, true);
    }

    private getNextTempId(): number {
        let maxId = 0;
        for (const resourceCost of this.resourceCosts) {
            const tempId = resourceCost.tempCostId;
            if (tempId > maxId) {
                maxId = tempId;
            }
        }
        maxId++;
        return maxId;
    }

    onGridReady(params) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.gridApi.setFloatingFiltersHeight(38);
        this.gridApi.setHeaderHeight(40);
    }

    getColumnDefs() {
        this.columnDefs = [
            {
                headerName: 'Room Type *',
                field: 'roomType',
                hide: false,
                editable: true,
                sortable: true,
                resizable: true,
                pinned: 'left',
                minWidth: 80,
                cellEditorFramework: AutoCompleteEditorComponent,
                cellEditorParams: {
                    returnObject: true,
                    filterItemFn: (params, row, text) => {
                        let filterValue = '';
                        if (text) {
                            filterValue = text.toLowerCase();
                        }
                        return this.rooms.filter(value => value.roomType.toLowerCase().indexOf(filterValue) > -1);
                    },
                    displayItemFn: (option) => {
                        if (option && option.roomType) {
                            return option.roomType;
                        } else {
                            return '';
                        }
                    }
                },
                valueFormatter: (params) => {
                    if (params.value) {
                        return params.value.name;
                    }
                    return '';
                }
            },
            {
                headerName: ' Board Basis',
                field: 'boardBasis',
                hide: false,
                minWidth: 80,
                editable: true,
                // cellEditor: 'autoComplete',
                sortable: true,
                resizable: true,
                cellEditorFramework: AutoCompleteEditorComponent,
                cellEditorParams: {
                    returnObject: true,
                    filterItemFn: (params, row, text) => {
                        const allowedProfileTypes = this.boardBasisTypes;

                        const anyType = [{code: 'ANY', name: 'Any'}];

                        const filterValue = text ? text.toLowerCase() : '';

                        return anyType.concat(allowedProfileTypes)
                                      .filter(value => value.name && value.name.toLowerCase().indexOf(filterValue) > -1);
                    },
                    displayItemFn: (option) => {
                        if (option && option.name) {
                            return option.name;
                        } else {
                            return '';
                        }
                    }
                },
                valueFormatter: (params) => {
                    if (params.value) {
                        return params.value.name;
                    }
                    return '';
                }
            },
            {
                headerName: 'Season *',
                field: 'seasonName',
                hide: false,
                editable: true,
                // cellEditor: 'autoComplete',
                sortable: true,
                resizable: true,
                filter: true,
                minWidth: 80,
                width: 150,
                cellEditorFramework: AutoCompleteEditorComponent,
                cellEditorParams: {
                    returnObject: true,
                    filterItemFn: function(params, row, text) {
                        let filterValue = '';
                        if (text) {
                            filterValue = text.toLowerCase();
                        }
                        return this.seasons.filter(value => value.name.toLowerCase().indexOf(filterValue) > -1);
                    }.bind(this),
                    displayItemFn: (option) => {
                        if (option && option.name) {
                            return option.name;
                        } else {
                            return '';
                        }
                    }
                },
                valueFormatter: (params) => {
                    if (params.value) {
                        const data = params.value.name;
                        params.value = params.value.name;
                        return data;
                    }
                    return '';
                }
            },
            {
                headerName: 'Days',
                children: [
                    {
                        headerName: 'Mo',
                        editable: false,
                        field: 'dayArray',
                        cellRendererFramework: CheckBoxSelectorComponent,
                        width: 70,
                        resizable: false,
                        cellRendererParams: {
                            date: 1,
                            day: 'MONDAY',
                            click: this.checkBoxOnClick.bind(this)
                        }
                        // columnGroupShow: 'open'
                    },
                    {
                        headerName: 'Tu',
                        editable: false,
                        field: 'dayArray',
                        cellRendererFramework: CheckBoxSelectorComponent,
                        width: 70,
                        resizable: false,
                        cellRendererParams: {
                            date: 2,
                            day: 'TUESDAY',
                            click: this.checkBoxOnClick.bind(this)
                        }
                        // columnGroupShow: 'open'
                    },
                    {
                        headerName: 'We',
                        field: 'dayArray',
                        editable: false,
                        cellRendererFramework: CheckBoxSelectorComponent,
                        width: 70,
                        resizable: false,
                        cellRendererParams: {
                            date: 3,
                            day: 'WEDNESDAY',
                            click: this.checkBoxOnClick.bind(this)
                        }
                        // columnGroupShow: 'open'
                    },
                    {
                        headerName: 'Th',
                        field: 'dayArray',
                        editable: false,
                        cellRendererFramework: CheckBoxSelectorComponent,
                        width: 70,
                        resizable: false,
                        cellRendererParams: {
                            date: 4,
                            day: 'THURSDAY',
                            click: this.checkBoxOnClick.bind(this)
                        }
                        // columnGroupShow: 'open'
                    },
                    {
                        headerName: 'Fr',
                        field: 'dayArray',
                        editable: false,
                        cellRendererFramework: CheckBoxSelectorComponent,
                        width: 70,
                        resizable: false,
                        cellRendererParams: {
                            date: 5,
                            day: 'FRIDAY',
                            click: this.checkBoxOnClick.bind(this)
                        }
                        // columnGroupShow: 'open'
                    },
                    {
                        headerName: 'Sa',
                        field: 'dayArray',
                        editable: false,
                        cellRendererFramework: CheckBoxSelectorComponent,
                        width: 70,
                        resizable: false,
                        cellRendererParams: {
                            date: 6,
                            day: 'SATURDAY',
                            click: this.checkBoxOnClick.bind(this)
                        }
                        // columnGroupShow: 'open'
                    },
                    {
                        headerName: 'Su',
                        field: 'dayArray',
                        editable: false,
                        cellRendererFramework: CheckBoxSelectorComponent,
                        width: 70,
                        resizable: false,
                        cellRendererParams: {
                            date: 7,
                            day: 'SUNDAY',
                            click: this.checkBoxOnClick.bind(this)
                        }
                        // columnGroupShow: 'open'
                    }
                ]

            },
            {
                headerName: 'Currency *',
                field: 'currency',
                hide: false,
                editable: true,
                // cellEditor: 'autoComplete',
                sortable: true,
                filter: true,
                minWidth: 80,
                width: 142,
                headerClass: 'tc-ag-grid-header--center',
                cellStyle: {textAlign: 'center'},
                cellEditorFramework: AutoCompleteEditorComponent,
                cellEditorParams: {
                    viewKey: 'code',
                    returnObject: true,
                    filterItemFn: function(params, row, text) {
                        return this.currencyList.filter(r =>
                            r.code && r.code.toLowerCase().indexOf(text) === 0);
                    }.bind(this)
                },
                valueFormatter: (params) => {
                    if (params.value) {
                        return params.value.code;
                    }
                    return '';
                }
            },
            {
                headerName: 'Costing Type *',
                field: 'costingType',
                hide: false,
                editable: true,
                // cellEditor: 'autoComplete',
                sortable: true,
                filter: true,
                minWidth: 80,
                width: 160,
                headerClass: 'tc-ag-grid-header--center',
                cellStyle: {textAlign: 'left'},
                cellEditorFramework: AutoCompleteEditorComponent,
                cellEditorParams: {
                    viewKey: 'name',
                    returnObject: true,
                    filterItemFn: function(params, row, text) {
                        if (row.resourceType &&
                            (row.resourceType.code === ResourceType.driver || row.resourceType.code === ResourceType.guide)) {
                            return this.costingTypeList.filter(r =>
                                r.code && r.code.toLowerCase().indexOf(text) === 0);
                        } else {
                            return this.costingTypeList.filter(r =>
                                r.code && r.code.toLowerCase().indexOf(text) === 0 && r.category !== 'DISTANCE' && r.code !== 'PJ');
                        }

                    }.bind(this)
                },
                valueFormatter: (params) => {
                    if (params.value) {
                        return params.value.name;
                    }
                    return '';
                }
            },
            {
                headerName: 'Nights',
                field: 'nights',
                hide: false,
                valueSetter: this.numericSetter,
                type: 'numericColumn',
                minWidth: 80,
                width: 120,
                editable: (params) => {
                    return true;
                },
                sortable: true,
                resizable: true,
                filter: true,
                cellStyle: {'text-align': 'right'}
            },
            {
                headerName: 'Single',
                field: 'single',
                hide: false,
                valueSetter: this.numericSetter,
                cellRenderer: this.CurrencyCellRenderer,
                type: 'numericColumn',
                minWidth: 120,
                width: 150,
                editable: (params) => {
                    return true;
                },
                sortable: true,
                resizable: true,
                filter: true,
                cellStyle: {'text-align': 'right'}
            },
            {
                headerName: 'Twin',
                field: 'twin',
                hide: false,
                valueSetter: this.numericSetter,
                cellRenderer: this.CurrencyCellRenderer,
                type: 'numericColumn',
                minWidth: 120,
                width: 150,
                editable: (params) => {
                    return true;
                },
                sortable: true,
                resizable: true,
                filter: true,
                cellStyle: {'text-align': 'right'}
            },
            {
                headerName: 'Extra Bed',
                field: 'extraBed',
                hide: false,
                valueSetter: this.numericSetter,
                cellRenderer: this.CurrencyCellRenderer,
                type: 'numericColumn',
                minWidth: 120,
                width: 150,
                editable: (params) => {
                    return true;
                },
                sortable: true,
                resizable: true,
                filter: true,
                cellStyle: {'text-align': 'right'}
            },
            {
                headerName: 'Adult Cost',
                field: 'adultFare',
                hide: false,
                valueSetter: this.numericSetter,
                cellRenderer: this.CurrencyCellRenderer,
                type: 'numericColumn',
                minWidth: 120,
                width: 150,
                editable: (params) => {
                    return true;
                },
                sortable: true,
                resizable: true,
                filter: true,
                cellStyle: {'text-align': 'right'}
            },
            {
                headerName: 'Child Cost',
                field: 'childFare',
                hide: false,
                valueSetter: this.numericSetter,
                cellRenderer: this.CurrencyCellRenderer,
                type: 'numericColumn',
                minWidth: 120,
                width: 150,
                editable: (params) => {
                    return true;
                },
                sortable: true,
                resizable: true,
                filter: true,
                cellStyle: {'text-align': 'right'}
            },
            {
                headerName: 'Infant Cost',
                field: 'infantFare',
                hide: false,
                valueSetter: this.numericSetter,
                cellRenderer: this.CurrencyCellRenderer,
                type: 'numericColumn',
                minWidth: 120,
                width: 150,
                flex: 1,
                editable: (params) => {
                    return true;
                },
                sortable: true,
                resizable: false,
                filter: true,
                cellStyle: {'text-align': 'right'}
            },
            {
                headerName: '',
                field: 'value',
                editable: false,
                cellRendererFramework: ActionColumnComponent,
                cellClass: [
                    'tc-ag-grid-cell--justify-center',
                    'tc-px-0'

                ],
                cellRendererParams: {
                    icon: 'delete',
                    title: 'Delete',
                    action: this.onRemoveRow.bind(this)
                },
                pinned: 'right',
                resizable: false,
                width: 80
            }
        ];

    }

    public checkBoxOnClick(params) {
        this.onCellValueChanged(params);
        console.log(params.value);
    }

    // The numeric value setter function/method
    private numericSetter(params) {
        if (params.newValue > 0 || params.newValue === '') {
            params.data[params.colDef.field] = params.newValue;
            return true;
        }
        // Illegal value - signal no change
        return false;
    }

    CurrencyCellRenderer(params: any) {
        const inrFormat = new Intl.NumberFormat('en-Us', {
            style: 'currency',
            currency: params.data.currency.code ? params.data.currency.code : params.data.currency,
            minimumFractionDigits: 2
        });
        return inrFormat.format(params.value).replace(/[a-z]{3}/i, '').trim();
    }

    onHeaderActionClick(event) {
        if (event.id === 'TRIGGER_SHOW_SEASON_SUMMARY') {
            this.showSeasonSummary();
        } else if (event.id === 'TRIGGER_ADD_COST') {
            this.addRow();
        } else if (event.id === 'TRIGGER_SAVE_COST_DATA') {
            this.saveResourceCostData();
        } else if (event.id === 'TRIGGER_EXPORT_FILE') {
            this.exportCostData();
        } else if (event.id === 'TRIGGER_IMPORT_FILE') {
            this.importCostData();
        }

    }

    addRow() {
        if (this.seasons.length > 0) {
            const resourceCost = new RoomResourceCostSetup();
            resourceCost.tempCostId = this.getNextTempId();
            resourceCost.currency = 'AED';
            resourceCost.unitFare = 0;
            resourceCost.adultFare = 0;
            resourceCost.childFare = 0;
            resourceCost.infantFare = 0;
            resourceCost.single = 0;
            resourceCost.twin = 0;
            resourceCost.extraBed = 0;
            resourceCost.noOfUnits = 0;
            resourceCost.isInvalid = true;
            resourceCost.dayArray = this.daysOfWeek;
            resourceCost.nights = 1;
            this.mapDayKeys(resourceCost);
            this.resourceCosts.unshift(resourceCost);
            this.gridApi.updateRowData({
                add: [resourceCost],
                addIndex: 0
            });
            this.validateUniqueValues(resourceCost);
        } else {
            const confirmError = new DialogModel(
                true,
                LEVEL.ERROR,
                'Failed',
                'Please add seasons',
                true,
                2000
            );
            this.dialogService
                .confirm(confirmError)
                .subscribe((res) => {
                    if (res === true) {
                        console.log('true');
                    }
                });
        }
    }

    // get all keys of whole week
    getAllDayKeys() {
        this.daysOfWeek.forEach(data => {this.allDayKeys += data.key; });
    }

    showSeasonSummary() {
        const dataObject = new ModalData(
            LEVEL.SUCCESS,
            'Season Setup Summary',
            null,
            {label: 'CLOSE'},
            'modal-basic--fixed-size modal-basic--show-footer',
            SeasonSetupSummaryComponent,
            {rangeGroups: this.rangeGroupsForSeasonCalendar}
        );
        this.modalService
            .confirm(dataObject).subscribe(res => {
            if (res) {
            }
        });
    }

    onRemoveRow(params) {
        if (!params.data.isInvalid) {
            const confirmDataObject = new DialogModel(
                true,
                LEVEL.WARNING,
                'Delete',
                'This Cost will be deleted. Do you wish to proceed?',
                true,
                3000
            );
            this.dialogService.confirm(confirmDataObject).subscribe(
                (res) => {
                    if (res === true) {
                        this.removeSelectedData(params);
                    }
                });
        } else {
            this.removeSelectedData(params);
        }
    }

    removeSelectedData(params: any) {
        for (let i = 0; i < this.resourceCosts.length; i++) {
            if (this.resourceCosts[i].tempCostId === params.data.tempCostId) {
                this.resourceCosts.splice(i, 1);
                break;
            }
        }
        this.gridApi.setRowData(this.resourceCosts);
    }

    addDataFromGrid(gridApi: any, list: any[]) {
        list.length = 0;
        gridApi.forEachNode((node) => {
            list.push(node.data);
        });
    }

    onSelectionChanged($event) {
        const selectedRows = this.gridApi.getSelectedRows();
        let selectedRowsString = '';
        selectedRows.forEach((selectedRow, index) => {
            if (index !== 0) {
                selectedRowsString += ', ';
            }
            selectedRowsString += selectedRow.athlete;
        });
    }

    private getProfileTypes(resourceType) {
        return this.selectedResourceTypes.filter(value => value.code === resourceType)[0];
    }

    onCellValueChanged(params) {
        const colId = params.column.getId();
        if (colId === 'roomType') {
            params.data.room = params.newValue;
            params.data.roomType = params.newValue.roomType;
            params.data.occupancyName = '';
            params.data.occupancyId = 0;
            params.data.occupancyTempId = 0;
        } else if (colId === 'boardBasis') {
            if (params.newValue) {
                params.data.boardBasis = params.newValue.code;
            } else {
                params.data.boardBasis = '';
            }
        } else if (colId === 'occupancyName') {
            const occupancy: RoomOccupancy = params.newValue;
            if (occupancy) {
                params.data.occupancyName = occupancy.occupancyName;
                params.data.occupancyId = occupancy.occupancyId;
                params.data.occupancyTempId = occupancy.occupancyTempId;
            } else {
                params.data.occupancyName = '';
                params.data.occupancyId = 0;
                params.data.occupancyTempId = 0;
            }
        } else if (colId === 'seasonName') {
            this.gridApi.getSelectedRows()[0].tempSeasonId = this.gridApi.getSelectedRows()[0].seasonName.code;
        } else if (colId.slice(0, -2) === 'dayArray' || colId === 'dayArray') {
            const dayValues = this.gridApi.getSelectedRows()[0].dayArray;
            const days = [];
            dayValues.forEach(day => {
                days.push(day.day ? day.day : day.value);
            });
            let dayKeys = '';
            this.daysOfWeek.forEach(data => {
                if (days.includes(data.value)) {dayKeys += data.key; }
            });
            this.gridApi.getSelectedRows()[0].dayKeys = dayKeys;
        } else if (colId === 'dayValues') {
            const dayValues = this.gridApi.getSelectedRows()[0].dayValues;
            let dayKeys = '';
            this.daysOfWeek.forEach(data => {
                if (dayValues.includes(data.value)) {dayKeys += data.key; }
            });
            this.gridApi.getSelectedRows()[0].dayKeys = dayKeys;
        } else if (colId === 'costingType') {
            this.gridApi.getSelectedRows()[0].unitFare = 0;
            this.gridApi.getSelectedRows()[0].adultFare = 0;
            this.gridApi.getSelectedRows()[0].childFare = 0;
            this.gridApi.getSelectedRows()[0].infantFare = 0;
        }

        this.validateUniqueValues(params.data);

        // set warning to missing cost fields
        const selectedResourceCost = this.gridApi.getSelectedRows()[0];
        this.isCostMissing = selectedResourceCost && !selectedResourceCost.unitFare && !selectedResourceCost.adultFare;

        this.gridApi.refreshCells(params);
    }

    validateUniqueValues(selectedResourceCost: RoomResourceCostSetup) {
        selectedResourceCost = selectedResourceCost ? selectedResourceCost : this.gridApi.getSelectedRows()[0];
        if (selectedResourceCost && selectedResourceCost.tempSeasonId && selectedResourceCost.roomType &&
            selectedResourceCost.dayKeys) {
            for (const item of this.resourceCosts) {
                if (item.tempCostId !== selectedResourceCost.tempCostId &&
                    item.tempSeasonId === selectedResourceCost.tempSeasonId &&
                    item.roomType === selectedResourceCost.roomType &&
                    item.boardBasis === selectedResourceCost.boardBasis &&
                    item.occupancyName === selectedResourceCost.occupancyName &&
                    this.anythingInCommon(item.dayKeys, selectedResourceCost.dayKeys)) {
                    this.isInvalidCost = true;
                    selectedResourceCost.isInvalid = true;
                    break;
                } else {
                    this.isInvalidCost = false;
                    selectedResourceCost.isInvalid = false;
                }
            }
            this.gridApi.getSelectedRows()[0].isInvalid = this.isInvalidCost;
        } else if (selectedResourceCost) {
            this.isInvalidCost = true;
            selectedResourceCost.isInvalid = true;
        } else {
            this.isInvalidCost = true;
        }
        this.gridApi.setRowData(this.resourceCosts);
        console.log(this.isInvalidCost);
    }

    anythingInCommon(a, b) {
        if (b.length < a.length) {
            return this.anythingInCommon(b, a);
        }

        for (let i = 0, len = a.length; i < len; i++) {
            if (b.indexOf(a[i]) !== -1) {
                return true;
            }
        }

        return false;
    }

    getCode(dataList, name) {
        return dataList.filter(val => val.name === name)[0].code;
    }

    public ngAfterViewInit(): void {
        setTimeout(() => {
            const scrollingElement: Element = document.querySelector('.tc-overlay');
            scrollingElement.scrollTo(0, 0);
        }, 0);
    }

    private mapDayKeys(resourceCost: RoomResourceCostSetup) {
        const dayValues = resourceCost.dayArray;
        const days = [];
        dayValues.forEach(day => {
            days.push(day.day ? day.day : day.value);
        });
        let dayKeys = '';
        this.daysOfWeek.forEach(data => {
            if (days.includes(data.value)) {dayKeys += data.key; }
        });
        resourceCost.dayKeys = dayKeys;
    }

    exportCostData() {
        this.spinnerService.show();
        const documentCreationCriteria = new DocumentCreationCriteria();
        documentCreationCriteria.documentFormat = DocumentFormat.csv;
        documentCreationCriteria.documentType = DocumentType.SUPPLIER_GEN_COST_SHEET;
        documentCreationCriteria.documentName = '';
        documentCreationCriteria.download = true;
        documentCreationCriteria.persist = true;

        const documentDataCriteria = new DocumentDataCriteria();
        documentDataCriteria.supplierId = this.supplierId;
        this.documentService.createAndDownloadDocument(documentCreationCriteria, documentDataCriteria);
    }

    private importCostData() {
        this.dataStore.set(DataKey.popupClose, null);
        this.dataStore.set(DataKey.fileToUpload, null);
        const dataObject = new ModalData(
            LEVEL.SUCCESS,
            'Upload Cost Sheet',
            true,
            true,
            'split-passengers-modal',
            FileUploaderComponent,
            {}
        );
        dataObject.disableClose = true;
        this.confirmModal(dataObject);

        const x = this.dataStore.get(DataKey.popupClose).subscribe((data) => {
            if (data != null) {
                this.closeModal();
            }
        });
        this.genericCostSheetImportSubscribe = this.dataStore.get(DataKey.fileToUpload).subscribe((data) => {
            if (data != null) {
                this.uploadFile(data);
                this.genericCostSheetImportSubscribe.unsubscribe();
            }
        });
    }

    uploadFile(fileToUpload: any) {
        let selectedResourceTypes = '';
        this.selectedResourceTypes.forEach((o, i) => {
            selectedResourceTypes = selectedResourceTypes + o.code;
            if (i !== this.selectedResourceTypes.length - 1) {
                selectedResourceTypes = selectedResourceTypes + ',';
            }
        });
        this.genCostSheetUploadSubscribe = this.supplierService.uploadCostSheet(
            fileToUpload,
            this.templateName,
            this.supplierId,
            selectedResourceTypes
        ).subscribe(data => {
            if (data && data.body) {
                if (this.gridApi) {
                    this.gridApi.showLoadingOverlay();
                    this.getSupplierById();
                }
                this.common.showSnackBar(
                    'Successfully uploaded',
                    3000,
                    TcErrorType.TYPE.INFO
                );
            }
        }, error => {
            if (error.error.status.description.substring(0, 4) === 'File') {
                this.common.showSnackBar(
                    'Fail to upload: ' + error.error.status.description,
                    6000,
                    TcErrorType.TYPE.ERROR
                );
            } else {
                this.common.showSnackBar(
                    'Fail to upload',
                    3000,
                    TcErrorType.TYPE.ERROR
                );
            }
        });
    }

    private confirmModal(data: any): void {
        this.modalService.confirm(data).subscribe((res) => {
        });
    }

    closeModal() {
        if (this.modalService.close()) {
            this.modalService.close().subscribe(
                (res) => {
                }
            );
        }
    }

    private getSupplierById() {
        this.supplierService.retrieveSupplierById(this.supplierId);
        this.subscribeSupplierData();
    }

    private subscribeSupplierData() {
        this.supplierDataSubscribe = this.dataStore.get(DataKey.supplier).subscribe((result) => {
            if (result != null) {
                this.resourceCosts = [];
                const supplier: Supplier = ResponseUtil.getFirstData(result);
                this.resourceCostSeasons = supplier.seasons;
                this.getResourceCostData();
                this.supplierDataSubscribe.unsubscribe();
            }
        });
    }

    getResourceCostData() {
        if (this.resourceCostSeasons.length > 0) {
            this.addDefaultProfileType(this.selectedProfileTypes);
            // setting temp ids to seasons
            this.setTempIdsToSeasons();

            let tempCostId = 0;
            this.resourceCostSeasons.forEach(item => {
                if (item.roomResourceCosts.length > 0) {
                    for (const roomResourceCost of item.roomResourceCosts) {
                        const days = this.getDays(roomResourceCost.days);
                        const daysArr = this.getDaysArr(roomResourceCost.days);
                        tempCostId++;
                        roomResourceCost.tempCostId = tempCostId;
                        const resourceCost = new RoomResourceCostSetup();
                        resourceCost.tempCostId = tempCostId;
                        resourceCost.roomType = roomResourceCost.roomType;
                        resourceCost.boardBasis = roomResourceCost.boardBasis;
                        resourceCost.seasonName = item.name;
                        resourceCost.tempSeasonId = item.tempSeasonId;
                        resourceCost.currency = roomResourceCost.currency;
                        resourceCost.unitFare = roomResourceCost.unitFare;
                        resourceCost.single = roomResourceCost.single;
                        resourceCost.twin = roomResourceCost.twin;
                        resourceCost.extraBed = roomResourceCost.extraBed;
                        resourceCost.adultFare = roomResourceCost.adultFare;
                        resourceCost.teenFare = roomResourceCost.teenFare;
                        resourceCost.childFare = roomResourceCost.childFare;
                        resourceCost.infantFare = roomResourceCost.infantFare;
                        resourceCost.nights = roomResourceCost.nights;
                        resourceCost.costingType = roomResourceCost.costingType;
                        resourceCost.dayValues = days;
                        resourceCost.dayArray = daysArr;
                        resourceCost.dayKeys = roomResourceCost.days;
                        resourceCost.isEdit = false;
                        this.resourceCosts.push(resourceCost);
                        // this.resourceCosts.splice(0,0,resourceCost);
                    }
                }
            });

            this.gridApi.refreshCells();
            this.gridApi.hideOverlay();
        }
    }

    private addDefaultProfileType(typeList: any[]) {
        return !typeList.find(value => value.code === this.defaultProfileType.code) ? typeList.push(this.defaultProfileType) : typeList;
    }

    // this.dataStore.get(DataKey.resourceTypes);
    filterObject(code: string, objArray: any[]) {
        if (objArray && objArray.length > 0) {
            for (const item of objArray) {
                if (item.code === code) {
                    return item;
                }
            }
            return null;
        } else {
            return code;
        }
    }

    private setTempIdsToSeasons() {
        let initId = 1;
        for (const season of this.resourceCostSeasons) {
            season.tempSeasonId = initId++;
        }
    }

    getDays(data: string) {
        let days = '';
        for (let i = 0; i < data.length; i++) {
            const dayArray = this.daysOfWeek.find(day => day.key === data.charAt(i)).value;
            days = days.concat(',' + dayArray);
        }
        if (days.startsWith(',')) {
            days = days.replace(',', '');
        }
        return days;
    }

    getDaysArr(data: string) {
        const daysArr = [];
        for (let i = 0; i < data.length; i++) {
            const dayArray = this.daysOfWeek.find(day => day.key === data.charAt(i)).value;
            daysArr.push({key: data.charAt(i), day: dayArray});
        }
        return daysArr;
    }

}
