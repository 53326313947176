import { DatePipe } from '@angular/common';
import { Injectable, Injector } from '@angular/core';
import { Icon } from '@tc-core/model/it/codegen/ui/framework/icon';
import { ERROR_LEVEL, PageSection } from '@tc-core/model/it/codegen/ui/framework/page-section';
import { SummaryCardData } from '@tc-core/model/it/codegen/ui/framework/summary-card-data';
import { KeyValue } from '@tc-core/model/it/codegen/ui/key-value';
import { TC } from '@tc-core/util/constants';
import { DataFormatterUtilService } from '@tc-core/util/system/data-formatter-util.service';
import { DateFormatter } from '@tc-core/util/system/date-formatter.service';
import { JsonUtilService } from '@tc-core/util/system/json-util.service';

@Injectable()
export class ContentPreProcessorService {
  constructor(
    private dataFormatterUtilService: DataFormatterUtilService,
    private injector: Injector,
    private jsonUtil: JsonUtilService,
    private dateFormatter: DateFormatter,
    private datePipe: DatePipe
  ) {
  }

  /**
   *
   * @param client
   * @param block
   * @param summaryCardData
   * @returns {SummaryCardData}
   */
  public preProcessBlock(payload: any, block: any, summaryCardData: SummaryCardData, dataKey?: any): SummaryCardData {
    let data = block['data'];
    let _id = block['_id'];
    let path = block['path'];
    let dataProcessor = block['dataProcessor'];
    for (let key in data) {
      if (dataProcessor && dataProcessor !== '') {
        const t = dataProcessor.split('#');
        const srv = t[0];
        const fnc = t[1];
        const handler = this.injector.get(srv);
        summaryCardData[key] = handler[fnc](payload, data[key], _id, dataKey); // calling handler function
        if (path) {
          summaryCardData[key].path = path;
        }
      } else {
        let val;
        try {
          val = this.jsonUtil.getRawData(payload, data[key]);
        } catch (e) {
          console.error(e);
        }
        summaryCardData[key] = this.processGenericData(val, _id, dataKey);
        if (path) {
          summaryCardData[key].path = path;
        }
      }
    }

    return summaryCardData;
  }

  /**
   *
   * @param client
   * @param contentConfig
   * @returns {SummaryCardData}
   */
  public preProcessContentData(data: any, contentConfig: any, dataKey?: any): SummaryCardData {
    let summaryCardData = new SummaryCardData();
    const placeHolderConfig = contentConfig.placeHolderConfig;
    for (let configKey of placeHolderConfig) {
      summaryCardData = this.preProcessBlock(data, configKey, summaryCardData, dataKey);
    }
    return summaryCardData;
  }

  /**
   * @param clients
   * @param contentConfig
   * @returns {SummaryCardData[]}
   */
  public preProcessContentDataList(data: any[], contentConfig: any): SummaryCardData[] {
    let summaryDataList: SummaryCardData[] = [];
    if (data && data.length > 0) {
      for (let dataItem of data) {
        summaryDataList.push(this.preProcessContentData(dataItem, contentConfig));
      }
    }
    return summaryDataList;
  }

  /**
   *
   * @param obj
   * @param arr
   */
  public appendWithSpace(obj: any, arr: any[], id: string): KeyValue {
    let returnVal = new KeyValue();

    let processedStr = '';
    for (let item of arr) {
      processedStr += this.jsonUtil.findJsonProperty(obj, item, '') + ' ';
    }
    // remove leading and trailing whitespaces
    processedStr.replace(/^\s+|\s+$/g, '');
    returnVal.key = id;
    returnVal.value = processedStr;
    return returnVal;
  }

  public appendWithDash(obj: any, arr: any[], id: string): KeyValue {
    let returnVal = new KeyValue();

    let processedStr = '';
    arr.forEach((value, i) => {
      let val = this.jsonUtil.findJsonProperty(obj, value, '');
      if (val !== '') {
        processedStr += val + (i < arr.length - 1 ? '-' : '');
      }
    });

    returnVal.key = id;
    returnVal.value = processedStr;

    return returnVal;
  }

  /**
   *
   * @param obj
   * @param arr
   */
  public appendWithSpaceAndDash(obj: any, arr: any[], id: string): KeyValue {
    let returnVal = new KeyValue();

    let processedStr = '';
    arr.forEach((value, i) => {
      let val = this.jsonUtil.findJsonProperty(obj, value, '');
      if (val !== '') {
        processedStr += (i !== 0 ? ' - ' : '') + val;
      }
    });

    returnVal.key = id;
    returnVal.value = processedStr;

    return returnVal;
  }

  /**
   *
   * @param val
   * @param id
   * @returns {KeyValue}
   */
  public processGenericData(val: any, id: string, dataKey?: any): KeyValue {
    let returnVal = new KeyValue();
    returnVal.key = id;
    returnVal.value = val;
    return returnVal;
  }

  public getLabel(obj: any, arr: any[], id: string): KeyValue {
    let returnVal = new KeyValue();

    let processedStr = '';
    arr.forEach((value, i) => {
      processedStr += value;
    });

    returnVal.key = id;
    returnVal.value = processedStr;

    return returnVal;
  }

  processDefaultIcon(obj: any, arr: any[], id: string): KeyValue {
    let returnVal = new KeyValue();
    let iconView = new Icon();

    let iconObj = arr[0]; // get the first icon
    if (iconObj) {
      iconView.key = iconObj['key'];
      iconView.iconType = iconObj['iconType'];
      iconView.style = iconObj['style'];
      iconView.value = iconObj['value'];
    }
    returnVal.key = id;
    returnVal.value = iconView;
    return returnVal;
  }

  processCommonIconKeepActive(obj: any, arr: any[], id: string): KeyValue {
    const returnVal = new KeyValue();
    const iconView = new Icon();

    const iconObj = arr[0]; // get the first icon
    if (iconObj) {
      iconView.key = iconObj['key'];
      iconView.iconType = iconObj['iconType'];
      iconView.style = iconObj['style'] + ' '+ iconObj['activeClass'];
      iconView.value = iconObj['value'];
    }
    returnVal.key = id;
    returnVal.value = iconView;
    return returnVal;
  }

  /**
   *
   * @param obj
   * @param arr
   */
  public appendWithSpaceAndCapitalize(obj: any, arr: any[], id: string): KeyValue {
    let returnVal = new KeyValue();

    let processedStr = '';
    for (let item of arr) {
      processedStr += this.jsonUtil.findJsonProperty(obj, item, '') + ' ';
    }
    // capitalize each word
    processedStr = this.dataFormatterUtilService.capitalizeEachWord(processedStr, true);
    returnVal.key = id;
    returnVal.value = processedStr;
    return returnVal;
  }

  public appendDashToEmptyValue(obj: any, arr: any[], id: string): KeyValue {
    let returnVal = new KeyValue();

    let processedStr = '';
    arr.forEach((value, i) => {
      let val = this.jsonUtil.findJsonProperty(obj, value, '');
      processedStr = val;
    });
    if (processedStr === '') {
      processedStr = '--';
    }

    returnVal.key = id;
    returnVal.value = processedStr;

    return returnVal;
  }

  public getStringDate(obj: any, arr: any[], id: string): KeyValue {
    let returnVal = new KeyValue();
    let processedStr = '';
    arr.forEach((value, i) => {
      let val = this.jsonUtil.findJsonProperty(obj, value, '');
      processedStr = val;
    });

    if (typeof processedStr === 'object') {
      // If a date
      returnVal.value = this.dateFormatter.dateAsString(processedStr);
    } else {
      // If a string
      returnVal.value = processedStr;
    }

    // try {
    //   returnVal.value = this.dateFormatter.dateAsString(processedStr);
    // } catch (e) {
    //   returnVal.value = processedStr;
    // }

    returnVal.key = id;
    return returnVal;
  }

  public getDateTime(obj: any, arr: any[], id: string): KeyValue {
    const returnVal = new KeyValue();
    let date: Date = null;
    arr.forEach((value, i) => {
      date = this.jsonUtil.findJsonProperty(obj, value, '');
    });
    let processedStr = '';
    if (date) {
      processedStr = this.dateFormatter.dateAsString(date, 'YYYY-MM-DD HH:mm');
    }
    returnVal.value = processedStr;
    returnVal.key = id;
    return returnVal;
  }

  public getDate(obj: any, arr: any[], id: string): KeyValue {
    const returnVal = new KeyValue();
    let date: Date = null;
    arr.forEach((value, i) => {
      date = this.jsonUtil.findJsonProperty(obj, value, '');
    });
    let processedStr = '';
    if (date) {
      processedStr = this.dateFormatter.dateAsString(date, 'YYYY-MM-DD');
    }
    returnVal.value = processedStr;
    returnVal.key = id;
    return returnVal;
  }

  public getGMTDate(obj: any, arr: any[], id: string): KeyValue {
    const returnVal = new KeyValue();
    let date: Date = null;
    arr.forEach((value, i) => {
      date = this.jsonUtil.findJsonProperty(obj, value, '');
    });
    let processedStr = '';
    if (date) {
      processedStr = this.datePipe.transform(date, 'yyyy-MM-dd', 'GMT');
    }
    returnVal.value = processedStr;
    returnVal.key = id;
    return returnVal;
  }

  /**
   * @param payload - data
   * @param block - section configuration
   * @returns {PageSection}
   */
  public preProcessSectionBlock(payload: any, block: any): PageSection {
    let section = new PageSection();
    section.id = block['id'];
    section.mainTitle = block['main_title'];
    section.tabTitle = block['tab_title'];
    section.actions = block['actions'];

    let errorLevel: string = block['show_error'];
    if (errorLevel) {
      section.showError = ERROR_LEVEL[errorLevel] === undefined ? ERROR_LEVEL.NONE : ERROR_LEVEL[errorLevel];
    }

    let dataProcessor = block['dataProcessor'];
    if (dataProcessor && dataProcessor !== '') {
      const temp = dataProcessor.split('#');
      const srv = temp[0];
      const fnc = temp[1];
      const handler = this.injector.get(srv);
      section.subTitle = handler[fnc](payload, block['sub_title_data'], section.id); // calling handler function

    } else {
      section.subTitle = new KeyValue();
      section.subTitle.value = block['sub_title_data'];
      section.subTitle.key = section.id;
    }
    return section;
  }

  processDateTime(payload: any, arr: any[], id: string): KeyValue {

    let returnVal = new KeyValue();
    let data = this.jsonUtil.findJsonProperty(payload, arr, null);
    let applicableDate = data ? data.split('T')[0] : '';
    let applicableTime = data ? data.split('T')[1] : '';
    if (applicableDate) {
      returnVal.value = this.dataFormatterUtilService.formatDate(applicableDate, TC.FORMAT.DATE_FORMAT_dd_MMM_yy) +
        ' ' +
        applicableTime;
    }
    returnVal.key = id;

    return returnVal;
  }

  // TODO Remove this : Temporary Fix
  resetCommisionAndDiscountValues(payload: any, arr: any[], id: string): KeyValue {

    let returnVal = new KeyValue();

    returnVal.key = id;
    returnVal.value = '';

    return returnVal;
  }

  getTitleCombined(obj: any, arr: any[], id: string): KeyValue
  {
    const keyValue = this.appendWithSpace(obj, arr, id);
    keyValue.value = id[0] + id.substr(1).replace('_', ' ').toLowerCase() + ' : ' + keyValue.value;
    return keyValue;
  }
}
