import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { LEVEL } from '@tc-core/model/it/codegen/ui/framework/dialog-model';
import { ModalData } from '@tc-core/model/it/codegen/ui/framework/modal-data';
import { ConfigLoader } from '@tc-core/util/framework';
import { FocusViewManager } from '@tc-core/util/framework/focus-view-manager.service';
import { FocusViewService } from '@tc/focus-view/focus-view.service';
import { IGetRowsParams } from 'ag-grid-community';
import { Observable, of, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SetupGridComp } from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid-comp';
import { SetupGridDataSource } from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid-data-source';
import { SetupGridComponent } from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid.component';
import { ActionRendererAction } from '../../../widgets/framework/ag-grid-custom-components/renderers/actions/actions.component';
import { TCO } from '../../../constants';
import { LocationSelectionFocusViewComponent } from '../../location-selection-focus-view/location-selection-focus-view.component';
import { LocationGroupsSearchCriteria } from '../../../models/criteria/location-groups-search-criteria';
import { LocationsSearchCriteria } from '../../../models/criteria/locations-search-criteria';
import { SortDirection } from '../../../models/helper/sort-direction';
import { DataHandlerService } from '../../../services/backend-consumers/master-data-handler-service/data-handler.service';
import { LocationsService } from '../../../services/backend-consumers/setups/locations.service';
import { DataKey, DataStoreService } from '../../../services/util/framework/data-store.service';
import { ResponseUtil } from '../../../services/util/response/response-util.service';

@Component({
    selector: 'tc-location-groups-setup',
    templateUrl: './location-groups-setup.component.html'
})
export class LocationGroupsSetupComponent extends SetupGridComp implements OnInit, OnDestroy, SetupGridDataSource {

    @ViewChild('setupGrid')
    private setupGrid: SetupGridComponent;
    public colDefConfig = [];

    gridActions: ActionRendererAction[] = [];

    private locGroupsSearchCriteria: LocationGroupsSearchCriteria = new LocationGroupsSearchCriteria();

    // required options for grid editors
    // nothing

    private searchCriteriaObserver: Subscription = new Subscription();
    private focusCloseSubscription: Subscription = new Subscription();
    private focusViewCloseSubject: Subscription = new Subscription();

    constructor(
        private configLoader: ConfigLoader,
        private dataStoreService: DataStoreService,
        private dataHandlerService: DataHandlerService,
        private locationsService: LocationsService,
        private focusViewManager: FocusViewManager,
        private focusViewService: FocusViewService
    ) {
        super();
    }

    ngOnInit() {
        this.colDefConfig = this.configLoader.configurations.get(TCO.CONF.CONF_LOCATION_GROUPS_SETUP);
        this.addGridActions();
        this.subscribeSearchCriteria();
        this.fillRequiredGridOptionData();
    }

    private addGridActions() {
        this.gridActions.push(
            {
                icon: 'add_location_alt',
                action: this.onAddLocClick.bind(this),
                disable: false,
                tooltip: 'Add Locations'
            }
        );
    }

    /**
     * subscribe search criteria changes in search bar
     * then force grid to refresh data
     * grid refresh will be call getRaws method which is using this subscribed criteria for backend call
     */
    subscribeSearchCriteria() {
        this.searchCriteriaObserver = this.dataStoreService.get(DataKey.locationGroupsSearchCriteria)
                                          .subscribe(value => {
                                              if (value) {
                                                  this.locGroupsSearchCriteria = value;
                                                  this.setupGrid.runForceSearch();
                                              }
                                          });
    }

    private fillRequiredGridOptionData() {
        // nothing
    }

    public isInvalidRow(params): boolean {
        const data = params.data;
        if (data) {
            let invalid = true;
            if (
                data.code &&
                data.name
            ) {
                invalid = false;
            }
            return invalid;
        } else {
            return false;
        }
    }

    public createNewRow(): any {
        return {unsaved: true};
    }

    public isUnsavedRow(row: any): boolean {
        return !!row.unsaved;
    }

    public deleteRow(row: any) {
        if (row && row.code) {
            return this.locationsService.deleteLocationGroup(row.code);
        } else {
            return of('success');
        }
    }

    public saveRow(row: any, event: any) {
        return this.locationsService.saveLocationGroup(row)
                   .pipe(tap(data => row.unsaved = undefined));
    }

    public getRows(params: IGetRowsParams): Observable<any> {
        console.log('fetch requesting for ' + params.startRow + ' to ' + params.endRow);
        console.log(params.sortModel);

        const pageSize = params.endRow - params.startRow;
        if (!this.locGroupsSearchCriteria) {
            this.locGroupsSearchCriteria = new LocationGroupsSearchCriteria();
        }
        this.locGroupsSearchCriteria.start = params.startRow;
        this.locGroupsSearchCriteria.size = pageSize;
        if (params.sortModel && params.sortModel[0]) {
            this.locGroupsSearchCriteria.sortBy = params.sortModel[0].colId;
            if (params.sortModel[0].sort === SetupGridComp.GRID_SORT_ASCENDING) {
                this.locGroupsSearchCriteria.sortDirection = SortDirection.ASC;
            } else {
                this.locGroupsSearchCriteria.sortDirection = SortDirection.DESC;
            }
        } else {
            this.locGroupsSearchCriteria.sortBy = 'createdOn';
            this.locGroupsSearchCriteria.sortDirection = SortDirection.DESC;
        }
        return this.locationsService.getLocationGroups(this.locGroupsSearchCriteria)
                   .pipe(
                       tap(data =>
                           this.dataStoreService.set(DataKey.locationGroupsSearchResultsForCriteria, data)
                       )
                   );
    }

    public ngOnDestroy(): void {
        if (this.searchCriteriaObserver) {
            this.searchCriteriaObserver.unsubscribe();
        }
    }

    // open location selection
    onAddLocClick = (params) => {
        this.getLocationsByLocationGroup(params.data);
    };

    openLocationSelectionModal(locationGroup: any, locations: any[]) {
        const secondaryHeader = '  ' + locationGroup.code + ' - ' + locationGroup.name;
        setTimeout(() => {
            const fabActions = [];

            const navItemsArray = this.configLoader.configurations.get(TCO.CONF.CONF_NAVIGATION);
            this.configLoader.configurations.get(TCO.CONF.CONF_FOCUS_VIEW).LOCATION_SELECTION_FOCUS_VIEW.fabAction
                .forEach(val => {
                    for (const key in navItemsArray) {
                        if (val === navItemsArray[key].id) {
                            fabActions.push(navItemsArray[key]);
                        }
                    }
                });
            const dataObject = new ModalData(
                LEVEL.SUCCESS,
                'Add Locations',
                null,
                null,
                'grid-focus-view',
                LocationSelectionFocusViewComponent,
                {
                    rightSideData: locations,
                    locationGroup: locationGroup
                },
                {label: 'close'},
                '',
                '',
                fabActions,
                '',
                secondaryHeader
            );
            this.focusViewCloseSubject = this.dataStoreService.get(DataKey.locationSelectionFocusViewClose)
                                             .subscribe(data => {
                                                 if (data != null) {
                                                     this.focusViewCloseSubject.unsubscribe();
                                                     this.closeFocusView();
                                                 }
                                             });

            this.focusViewService.confirm(dataObject).subscribe(res => {
                if (res) {
                    const e = document.getElementsByClassName('header-content__secondary-heading');
                    console.log(e);
                }
            });
        }, 0);
    }

    getLocationsByLocationGroup(locationGroup: any): any {
        const locationsSearchCriteria = new LocationsSearchCriteria();
        locationsSearchCriteria.locationGroupCode = locationGroup.code;
        locationsSearchCriteria.size = 100000;
        this.locationsService.getLocations(locationsSearchCriteria)
            .subscribe(data => {
                    if (data) {
                        const locations = ResponseUtil.getDataArray<any>(data);
                        this.openLocationSelectionModal(locationGroup, locations);
                    }
                }
            );
    }

    closeFocusView() {
        if (this.focusViewService.close()) {
            this.focusCloseSubscription = this.focusViewService.close().subscribe(
                (res) => {
                }
            );
        }
    }

    /*
     grid config methods which are called by grid setup (ag grid)
     and set by grid config
     */

    // nothing

}
