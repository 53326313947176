import {AssignmentStatus} from '../../reservation/assignment-status';
import {BookingItemStatusType} from '../../reservation/booking-item-status.enum';
import {DispatchStatus} from '../dispatch-status';
import {MainType} from '../main-type';
import {ProductLevel} from '../product-level';
import {TrsType} from '../TrsType';
import {KeyControlAndAbstractSearchCriteria} from '../../criteria/key-control-and-abstract-search-criteria';

export class ProductQueueSearchCriteria extends KeyControlAndAbstractSearchCriteria{
    productLevel: ProductLevel;
    mainType: MainType;
    subType = '';
    pvtShared = '';
    trsType: TrsType;
    serviceType = '';
    route = '';
    transferMode = '';
    contractReference = '';
    plCode = '';
    plName = '';
    defaultSupplierName = '';
    allocatedSupplierCode = '';
    allocatedSupplierName = '';
    defaultSupplierCode = '';
    language = '';
    startDate = new Date().toISOString().split('T')[0];
    endDate = new Date(+new Date() + 172800000).toISOString().split('T')[0];
    bookingStartDate = '';
    bookingEndDate = '';
    daysFrom: number;
    assignmentId = 0;
    assignStatus: AssignmentStatus;
    itemNumber = '';
    bookingSourceId = '';
    bookingSourceStatus: any;
    bookingItemStatus: BookingItemStatusType;
    suppConfStatus: any;
    manifestGenStatus: any;
    pickupType = '';
    dropOffType = '';
    supplierConfNumber = '';
    dispatchStatusType: DispatchStatus;

    // pagination and sorting
    start: number;
    end: number;
    size: number;
    // sortDirection: string;
    gridSorting: string;

    // keyControls
    kcParent = '';
    kcCompany = '';
    kcDivision = '';
    kcBrand = '';
    kcDistributionChannel = '';
    kcExtra1 = '';
    kcExtra2 = '';

}


