import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
    CityChange,
    CountryChange,
    CountryStateCityWrapper,
    InitialCountryStateCityCodes, StateChange
} from '../../../models/common/country-state-city-wrapper';
import { City, Country, State } from '../../../models/common/locale';
import { InsuranceInformation } from '../../../models/supplier/insurance-information';
import { ChangeDetectorService } from '../../../services/util/change-detector/change-detector.service';
import { IdentifierService } from '../../../services/util/identifier/identifier.service';
import { RegXPatterns } from '../regx-patterns/regx-patterns';
import {DatePipe} from '@angular/common';
import {UtilService} from '../../../services/util/common/util.service';

@Component({
    selector: 'tc-insurance-information',
    templateUrl: './insurance-information.component.html'
})
export class InsuranceInformationComponent implements OnInit {

    emailRegex = '^[a-zA-Z0-9!#$%&\'*+\\/=?^_`{|}~.-]+@[a-zA-Z0-9]([a-zA-Z0-9-]*[a-zA-Z0-9])?(\\.[a-zA-Z0-9]([a-zA-Z0-9-]*[a-zA-Z0-9])?)+$';
    telephoneRegex = '^[\\+]?[(]?[0-9]{3}[)]?[-\\s\\.]?[0-9]{3}[-\\s\\.]?[0-9]{4,9}$';

    insuranceInformationForm: FormGroup;

    @Input() insuranceInformationModel: InsuranceInformation;

    // country state city
    @Input() cityList: City[];
    @Input() stateList: State[];
    @Input() countryList: Country[];
    @Input() countryStateCityChangeMap: Map<number, CountryStateCityWrapper> = new Map();

    @Output() newCountryStateCityObserver: EventEmitter<InitialCountryStateCityCodes> = new EventEmitter();
    @Output() selectCountry: EventEmitter<CountryChange> = new EventEmitter();
    @Output() selectState: EventEmitter<StateChange> = new EventEmitter();
    @Output() selectCity: EventEmitter<CityChange> = new EventEmitter();

    @Output() countryTextChange: EventEmitter<CountryChange> = new EventEmitter();
    @Output() stateTextChange: EventEmitter<StateChange> = new EventEmitter();
    @Output() cityTextChange: EventEmitter<CityChange> = new EventEmitter();
    // --country state city--

    @Output() changeFormValidity: EventEmitter<boolean> = new EventEmitter();

    idForLocaleChange: number;

    constructor(
        private fb: FormBuilder,
        private identifierService: IdentifierService,
        private changeDetectorService: ChangeDetectorService,
        private datePipe: DatePipe,
        private utilService: UtilService
    ) { }

    ngOnInit() {

        // country state city
        this.idForLocaleChange = this.identifierService.getNextId();
        // --country state city--

        this.createForm();

        this.insuranceInformationForm.valueChanges.subscribe(
            (data) => {
                if (JSON.stringify(data) !== JSON.stringify({})) {
                    this.insuranceInformationModel.insuranceCompanyName = data.insCmpName;
                    this.insuranceInformationModel.insuredDate = this.utilService.setTimezoneAsGMTWithoutConverting(data.insuredDate);
                    this.insuranceInformationModel.insuranceExpiryDate = this.utilService.setTimezoneAsGMTWithoutConverting(data.expiryDate);
                    this.insuranceInformationModel.email = data.email;
                    this.insuranceInformationModel.altEmail = data.alternateEmail;
                    this.insuranceInformationModel.telephone = data.telephone;
                    this.insuranceInformationModel.altTelephone = data.alternateTelephone;
                    this.insuranceInformationModel.street = data.street;
                    this.insuranceInformationModel.policyNumber = data.policyNumber;

                    // country state city
                    if (typeof (data.country) === 'string' && typeof (data.country) !== 'object') {
                        if (this.clearCountry(data.country)) {
                            data.country=null;
                        }
                        this.countryTextChange.emit({id: this.idForLocaleChange, text: data.country, country: null});
                    }
                    if (typeof (data.state) === 'string' && typeof (data.state) !== 'object') {
                        if (this.clearState(data.state)) {
                            data.state = null;
                        }
                        this.stateTextChange.emit({id: this.idForLocaleChange, text: data.state, state: null});
                    }
                    if (typeof (data.city) === 'string' && typeof (data.city) !== 'object') {
                        if (this.clearCity(data.city)){
                            data.city = null;
                        }
                        this.cityTextChange.emit({id: this.idForLocaleChange, text: data.city, city: null});
                    }
                    // --country state city--

                    this.changeFormValidity.emit(this.insuranceInformationForm.valid);
                }

            }
        );

        // country state city
        this.newCountryStateCityObserver.emit(
            {
                id: this.idForLocaleChange,
                cityCode: this.insuranceInformationModel.city,
                stateCode: this.insuranceInformationModel.state,
                countryCode: this.insuranceInformationModel.country
            });
        this.subscribeCountryStateCityChange();
        // --country state city--

        this.validateForm();
    }

    public validateForm() {
        Object.keys(this.insuranceInformationForm.controls).forEach(field => {
            const control = this.insuranceInformationForm.get(field);
            control.markAsTouched({onlySelf: true});
        });
    }

    private createForm() {
        this.insuranceInformationForm = this.fb.group({
            insCmpName: [this.insuranceInformationModel ? this.insuranceInformationModel.insuranceCompanyName : ''],
            policyNumber: [this.insuranceInformationModel ? this.insuranceInformationModel.policyNumber : ''],
            insuredDate: [this.insuranceInformationModel ? this.insuranceInformationModel.insuredDate : ''],
            expiryDate: [this.insuranceInformationModel ? this.insuranceInformationModel.insuranceExpiryDate : ''],
            email: [this.insuranceInformationModel ? this.insuranceInformationModel.email : '', [Validators.pattern(RegXPatterns.EMAIL)]],
            alternateEmail: [
                this.insuranceInformationModel ? this.insuranceInformationModel.altEmail : '',
                [Validators.pattern(RegXPatterns.EMAIL)]
            ],
            telephone: [
                this.insuranceInformationModel ? this.insuranceInformationModel.telephone : '',
                [Validators.pattern(RegXPatterns.TELEPHONE)]
            ],
            alternateTelephone: [
                this.insuranceInformationModel ? this.insuranceInformationModel.altTelephone : '',
                [Validators.pattern(RegXPatterns.TELEPHONE)]
            ],
            country: [
                ''
            ],
            state: [
                ''
            ],
            city: [
                ''
            ],
            street: [this.insuranceInformationModel ? this.insuranceInformationModel.street : '']

        });
        this.changeFormValidity.emit(this.insuranceInformationForm.valid);
    }

    // country state city
    public onCityFocus($event: FocusEvent) {
        this.cityList = [];
        this.cityTextChange.emit({id: this.idForLocaleChange, text: '', city: null});
    }

    public onStateFocus($event: FocusEvent) {
        this.stateList = [];
        this.stateTextChange.emit({id: this.idForLocaleChange, text: '', state: null});
    }

    public onCountryFocus($event: FocusEvent) {
        this.countryList = [];
        this.countryTextChange.emit({id: this.idForLocaleChange, text: '', country: null});
    }

    private clearCountry(countryText: string):boolean {
        if (countryText === '') {
            this.countryStateCityChangeMap.get(this.idForLocaleChange).country = null;
            this.countryStateCityChangeMap.get(this.idForLocaleChange).state = null;
            this.countryStateCityChangeMap.get(this.idForLocaleChange).city = null;
            this.updateCountryStateCityForm();
            return true;
        }else {
            return false;
        }
    }

    private clearState(stateText):boolean {
        if (stateText === '') {
            this.countryStateCityChangeMap.get(this.idForLocaleChange).state = null;
            this.countryStateCityChangeMap.get(this.idForLocaleChange).city = null;
            this.updateCountryStateCityForm();
            return true;
        }else {
            return false;
        }
    }

    private clearCity(cityText):boolean {
        if (cityText === '') {
            this.countryStateCityChangeMap.get(this.idForLocaleChange).city = null;
            this.updateCountryStateCityForm();
            return true;
        }else {
            return false;
        }
    }

    public onSelectCity(city: City) {
        this.selectCity.emit({city: city, id: this.idForLocaleChange, text: null});
    }

    public onSelectState(state: State) {
        this.selectState.emit({state: state, id: this.idForLocaleChange, text: null});
    }

    public onSelectCountry(country: Country) {
        this.selectCountry.emit({country: country, id: this.idForLocaleChange, text: null});
    }

    subscribeCountryStateCityChange() {
        this.changeDetectorService.notifierCountryStateCityChange
            .subscribe(value => {
                if (this.countryStateCityChangeMap.get(this.idForLocaleChange)) {
                    this.updateCountryStateCityForm();
                }
            });
    }

    updateCountryStateCityForm() {
        if (this.countryStateCityChangeMap.get(this.idForLocaleChange)) {

            let state = this.countryStateCityChangeMap.get(this.idForLocaleChange).state;
            if (state && state.code === '' &&
                (this.countryStateCityChangeMap.get(this.idForLocaleChange).country === undefined ||
                    this.countryStateCityChangeMap.get(this.idForLocaleChange).country === null)) {
                state = null;
            }

            this.insuranceInformationForm.patchValue({
                country: this.countryStateCityChangeMap.get(this.idForLocaleChange).country,
                state: state,
                city: this.countryStateCityChangeMap.get(this.idForLocaleChange).city
            });

            this.insuranceInformationModel.country = this.countryStateCityChangeMap.get(this.idForLocaleChange).country ?
                this.countryStateCityChangeMap.get(this.idForLocaleChange).country.code :
                '';
            this.insuranceInformationModel.state = this.countryStateCityChangeMap.get(this.idForLocaleChange).state ?
                this.countryStateCityChangeMap.get(this.idForLocaleChange).state.code :
                '';
            this.insuranceInformationModel.city = this.countryStateCityChangeMap.get(this.idForLocaleChange).city ?
                this.countryStateCityChangeMap.get(this.idForLocaleChange).city.code :
                '';
            this.changeFormValidity.emit(this.insuranceInformationForm.valid);
        }
    }

    public displayCountryFn(destination: any): any {
        return destination ? destination.name : destination;
    }

    public displayStateFn(destination: any): any {
        return destination ? destination.name : destination;
    }

    public displayCityFn(destination: any): any {
        return destination ? destination.name : destination;
    }
    // -- country state city--

    public getErrorMessageOfControl(group: FormGroup, field: string): string {
        if (field === 'Email') {
            /*group.get('email').hasError('required') ?
               field + ' Required' :*/
            return group.get('email').hasError('pattern') ?
                field + ' invalid' : '';
        } else if (field === 'Alternate Email') {
            /*group.get('mobileNumber').hasError('required') ?
               field + ' Required' :*/
            return group.get('alternateEmail').hasError('pattern') ?
                field + ' invalid' : '';
        } else if (field === 'Telephone') {
            return group.get('telephone').hasError('pattern') ?
                field + ' invalid' : '';
        } else if (field === 'Alternate Telephone') {
            return group.get('alternateTelephone').hasError('pattern') ?
                field + ' invalid' : '';
        }

    }

    getErrorMessage(field: string): string {
        switch (field) {

            case 'country' :
                return this.insuranceInformationForm.controls['country'].hasError('invalidString') ? 'Country invalid' : '';
            case 'state' :
                return this.insuranceInformationForm.controls['state'].hasError('invalidString') ? 'State invalid' : '';
            case 'city' :
                return this.insuranceInformationForm.controls['city'].hasError('invalidString') ? 'City invalid' : '';
            default :
                return '';
        }
    }
}

export function StringValidator(control: AbstractControl) {
    if (!control.value || !control.value.name || (control.value.name === 'NOT SPECIFIED')) {
        return {invalidString: true};
    } else {
        return null;
    }
}
