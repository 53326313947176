/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../node_modules/ag-grid-angular/dist/agGridAngular.ngfactory";
import * as i2 from "ag-grid-angular/dist/angularFrameworkOverrides";
import * as i3 from "ag-grid-angular/dist/angularFrameworkComponentWrapper";
import * as i4 from "ag-grid-angular/dist/agGridAngular";
import * as i5 from "@ngx-translate/core";
import * as i6 from "./supplier-price-breakdown.component";
import * as i7 from "../../services/util/framework/data-store.service";
var styles_SupplierPriceBreakdownComponent = [];
var RenderType_SupplierPriceBreakdownComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SupplierPriceBreakdownComponent, data: {} });
export { RenderType_SupplierPriceBreakdownComponent as RenderType_SupplierPriceBreakdownComponent };
export function View_SupplierPriceBreakdownComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "detail-group__body-service-item container-fluid"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 16777216, null, null, 4, "ag-grid-angular", [["class", "ag-theme-material tc-ag-grid tc-ag-grid--popup-content"], ["style", "width: 100%; height: 100%;"]], null, [[null, "gridReady"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("gridReady" === en)) {
        var pd_0 = (_co.onGridReady($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_AgGridAngular_0, i1.RenderType_AgGridAngular)), i0.ɵprd(512, null, i2.AngularFrameworkOverrides, i2.AngularFrameworkOverrides, [i0.NgZone]), i0.ɵprd(512, null, i3.AngularFrameworkComponentWrapper, i3.AngularFrameworkComponentWrapper, []), i0.ɵdid(4, 4898816, null, 1, i4.AgGridAngular, [i0.ElementRef, i0.ViewContainerRef, i2.AngularFrameworkOverrides, i3.AngularFrameworkComponentWrapper, i0.ComponentFactoryResolver], { rowData: [0, "rowData"], columnDefs: [1, "columnDefs"], defaultColDef: [2, "defaultColDef"] }, { gridReady: "gridReady" }), i0.ɵqud(603979776, 1, { columns: 1 }), (_l()(), i0.ɵeld(6, 0, null, null, 3, "div", [["class", "container assign-panel-footer-section"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 2, "button", [["class", "assign-panel-footer-section__button tc-button-secondary mat-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClose($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(8, null, [" ", " "])), i0.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.rawData; var currVal_1 = _co.columnDefs; var currVal_2 = _co.defaultColDef; _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_3 = i0.ɵunv(_v, 8, 0, i0.ɵnov(_v, 9).transform("Close")); _ck(_v, 8, 0, currVal_3); }); }
export function View_SupplierPriceBreakdownComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-supplier-price-breakdown", [], null, null, null, View_SupplierPriceBreakdownComponent_0, RenderType_SupplierPriceBreakdownComponent)), i0.ɵdid(1, 114688, null, 0, i6.SupplierPriceBreakdownComponent, [i7.DataStoreService, i5.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SupplierPriceBreakdownComponentNgFactory = i0.ɵccf("tc-supplier-price-breakdown", i6.SupplierPriceBreakdownComponent, View_SupplierPriceBreakdownComponent_Host_0, { serviceAssignment: "serviceAssignment" }, {}, []);
export { SupplierPriceBreakdownComponentNgFactory as SupplierPriceBreakdownComponentNgFactory };
