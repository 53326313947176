import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { CurrencyFormat } from '@tc-core/model/it/codegen/ui/framework/currency-format';
import { SummaryCardData } from '@tc-core/model/it/codegen/ui/framework/summary-card-data';
import { TC } from '@tc-core/util';
import { ConfigLoader } from '@tc-core/util/framework/config-loader.service';
import { TCO } from '../../../constants';
import { Resource } from '../../../models/resource/resource';
import { Season } from '../../../models/supplier/season';

@Component({
    selector: 'tc-generic-resource-detail-view',
    templateUrl: './generic-resource-detail-view.component.html'
})
export class GenericResourceDetailViewComponent implements OnInit, OnChanges {

    @Input() resource: Resource;
    @Input() selectedResourceCost: Season[] = [];
    @Input() summaryCardData: SummaryCardData;
    @Input() controlMap: Map<any, string>;
    @Input() footerDataMap: Map<number, object>;
    @Input() resourceTypes: any[];
    @Input() trsModes: any[] = [];

    @Output() headerClick: EventEmitter<any> = new EventEmitter();
    @Output() editClick: EventEmitter<any> = new EventEmitter();
    @Output() expandEventEmitter: EventEmitter<boolean> = new EventEmitter();

    resourceType: any = null;
    currencyFormat: CurrencyFormat;


    constructor(private configLoader: ConfigLoader) { }

    ngOnInit() {
        this.currencyFormat = this.getCurrencyFormat();

        if (this.summaryCardData) {
            this.expandEventEmitter.emit(true);
        }

    }

    private getCurrencyFormat(): CurrencyFormat {
        let currencyFormat;
        let currencySymbolPattern = this.configLoader.configurations.get(TC.CONF.CONF_SYSTEM).defaults.default_currency_symbol_pattern;
        currencyFormat = new CurrencyFormat(TCO.AppData.DEF_CURRENCY_TYPE, currencySymbolPattern);
        return currencyFormat;
    }

    onHeaderClick(event) {
        this.headerClick.emit(event);
    }

    onEditClick(event) {
        this.editClick.emit(event);
    }

    public ngOnChanges(changes: SimpleChanges): void {
        for (let propName in changes) {
            if (propName === 'resource') {
                if (this.resource) {
                    this.resourceType = this.resourceTypes.find(value => value.code === this.resource.resourceType);
                    if (!this.resourceType) {
                        this.resourceType = {code: this.resource.resourceType, name: this.resource.resourceType};
                    }
                }
            }
        }
    }
}
