import { ResourceCostSetup } from './resource-cost-setup';

export class TrsResourceCostSetup extends ResourceCostSetup{
    transferMode: any;
    vehicleModel: any;
    route: any;
}

export class TrsResourceCost {
    tempCostId: number;
    resourceCostId:number;
    supplierId: number;
    seasonId: number;
    seasonName: string;
    transferMode: string;
    vehicleModel: string;
    route: string;
    days:string;
    currency:string;
    costingType:string;
    adultFare: number;
    childFare: number;
    infantFare: number;
    teenFare: number;
    unitFare: number;
}

export namespace CostUnitOptions {
    export enum TYPE {'Per Person', 'Per Unit'}
}
