import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'tc-app-section-navigation',
  templateUrl: './app-section-navigation.component.html'
})
export class AppSectionNavigationComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
