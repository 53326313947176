// get these to  top if after code format gives BaseService error
import { AgmCoreModule } from '@agm/core';
import { BaseService } from '@tc-core/util/system';

import { CurrencyPipe, DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
    MatAutocompleteModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatNativeDateModule,
    MatOptionModule,
    MatPaginatorModule,
    MatSelectModule,
    MatTableModule,
    MatTooltipModule
} from '@angular/material';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { APIConfigLoaderService } from '@tc-core/service/api/api-config-loader.service';
import { LaunchPadService } from '@tc-core/service/helpers/launch-pad/launch-pad.service';
import { LocalStorageService } from '@tc-core/service/helpers/local-storage/local-storage.service';
import {
    ResultsContentPreProcessorService
} from '@tc-core/service/helpers/pre-processor/results-content-pre-processor/results-content-pre-processor.service';
import { DataServiceHandler } from '@tc-core/service/service-handlers/data-service-handler.service';
import { PersonalizationHandler } from '@tc-core/service/service-handlers/personalization-handler.service';
import { UserServiceHandlerService } from '@tc-core/service/service-handlers/user-service-handler.service';
import { AlphaNumericSorting, SorterService } from '@tc-core/service/sorters/sorter.service';
import { Constant } from '@tc-core/util';
import { Common } from '@tc-core/util/common/common';
import { ConfigLoader } from '@tc-core/util/framework/config-loader.service';
import { DataStore } from '@tc-core/util/framework/data-store.service';
import { EventManager } from '@tc-core/util/framework/event-manager.service';
import { FocusHandlerService } from '@tc-core/util/framework/focus-handler.service';
import { FocusViewManager } from '@tc-core/util/framework/focus-view-manager.service';
import { LogStore } from '@tc-core/util/framework/log-store';
import { QueryParamsService } from '@tc-core/util/framework/query-params.service';
import { ResultsHeaderService } from '@tc-core/util/framework/results-header.service';
import { RouteManager } from '@tc-core/util/framework/route-manager.service';
import { ScaffoldingHelper } from '@tc-core/util/framework/scaffolding-helper.service';
import { UserJourneyManager } from '@tc-core/util/framework/user-journey-manager.service';
import { CommonHelper } from '@tc-core/util/helpers/common-helper.service';
import { DataFormatterUtilService } from '@tc-core/util/system/data-formatter-util.service';
import { DateFormatter } from '@tc-core/util/system/date-formatter.service';
import { FileUploadService } from '@tc-core/util/system/file-upload.service';
import { HeaderService } from '@tc-core/util/system/header.service';
import { JsonUtilService } from '@tc-core/util/system/json-util.service';
import { AnimationService } from '@tc-core/util/ui';
import { AutoScrollService } from '@tc-core/util/ui/auto-scroll.service';
import { DynamicRenderer } from '@tc-core/util/ui/dynamic-renderer.service';
import { KebabService } from '@tc-core/util/ui/kebab.service';
import { PageSummaryService } from '@tc-core/util/ui/page-summary.service';
import { SideNavBarService } from '@tc-core/util/ui/side-nav-bar.service';
import { SpinnerService } from '@tc-core/util/ui/spinner.service';
import { WindowRef } from '@tc-core/util/ui/windowref.service';
import { ChipInputModule } from '@tc/chip-input';
import { ChipInputService } from '@tc/chip-input/chip-input.service';
import { Parser } from '@tc/chip-input/shared/parser.service';
import { DialogComponent, DialogModule } from '@tc/dialog';
import { DialogService } from '@tc/dialog/dialog.service';
import { DirectiveSearchModule } from '@tc/directive-search';
import { DirectivesModule } from '@tc/directives';
import { ExpansionPanelDirective } from '@tc/directives/expansion-panel';
import { EmptyResultModule } from '@tc/empty-result';
import { ExpandedContentCardModule } from '@tc/expanded-content-card';
import { ExpandedContentCardBodyModule } from '@tc/expanded-content-card-body';
import { ExpandedContentCardFooterModule } from '@tc/expanded-content-card-footer';
import { FocusViewComponent, FocusViewModule } from '@tc/focus-view';
import { FocusViewService } from '@tc/focus-view/focus-view.service';
import { HeaderModule } from '@tc/header';
import { ModalComponent, ModalModule } from '@tc/modal';
import { ModalService } from '@tc/modal/modal.service';
import { PaginatorModule } from '@tc/paginator';
import { ResourceModule } from '@tc/resource';
import { ResultsHeaderModule } from '@tc/results-header';
import { SectionHeadingModule } from '@tc/section-heading';
import { SidePanelModule } from '@tc/side-panel';
import { SnackBarComponent } from '@tc/snack-bar';
import { SummaryStripModule } from '@tc/summary-strip';
import 'hammerjs';
import { CookieService } from 'ngx-cookie-service';
import { S05Component } from '../Scaffolding/s05/s05.component';
import { ScaffS7v0Component } from '../Scaffolding/scaff-s7v0/scaff-s7v0.component';
import { ScaffoldingModule } from '../Scaffolding/scaffolding.module';
import { AppComponent } from './app.component';
import { AppService } from './app.service';
import { AuthTokenService } from './authentication/auth-token.service';
import { AuthHeaderInterceptor } from './authentication/interceptors/auth-header-interceptor';
import { AuthInterceptor } from './authentication/interceptors/auth-interceptor';
import { AllocationFeatureModule } from './business/allocation-feature/allocation-feature.module';
import { AssignmentFocusViewModule } from './business/assignment-focus-view/assignment-focus-view.module';
import { AvailabilityCalendarPageComponent } from './business/availability-calendar-page/availability-calendar-page.component';
import { AvailabilityCalendarPageModule } from './business/availability-calendar-page/availability-calendar-page.module';
import {
    AvailabilityCalendarSearchCriteriaComponent
} from './business/availability-calendar-search-criteria/availability-calendar-search-criteria.component';
import {
    AvailabilityCalendarSearchCriteriaModule
} from './business/availability-calendar-search-criteria/availability-calendar-search-criteria.module';
import { BookingImportSummaryComponent } from './business/booking-management/booking-import-summary/booking-import-summary.component';
import { BookingImportSummaryModule } from './business/booking-management/booking-import-summary/booking-import-summary.module';
import { BookingSearchCriteriaModule } from './business/booking-management/booking-search-criteria/booking-search-criteria.module';
import { BookingSearchResultsModule } from './business/booking-management/booking-search-results/booking-search-results.module';
import { BookingModule } from './business/booking-management/booking/booking.module';
import { DashboardSearchComponent } from './business/dashboard-search/dashboard-search.component';
import { DocumentCreationModalModule } from './business/document-creation-modal/document-creation-modal.module';
import { DocumentsSearchResultsModule } from './business/documents/documents-search-results/documents-search-results.module';
import {
    GenReservationSearchCriteriaModule
} from './business/generic-reservation-management/gen-reservation-search-criteria/gen-reservation-search-criteria.module';
import {
    GenReservationSearchResultsModule
} from './business/generic-reservation-management/gen-reservation-search-results/gen-reservation-search-results.module';
import { GenericSearchCriteriaModule } from './business/generic-search-criteria/generic-search-criteria.module';
import { GenericSearchResultsModule } from './business/generic-search-results/generic-search-results.module';
import { LeaveSetupFocusViewComponent } from './business/leave-setup-focus-view/leave-setup-focus-view.component';
import { LedgerEntrySearchResultsModule } from './business/ledger/ledger-entry-search-results/ledger-entry-search-results.module';
import { LocationSelectionFocusViewComponent } from './business/location-selection-focus-view/location-selection-focus-view.component';
import { LocationSelectionFocusViewModule } from './business/location-selection-focus-view/location-selection-focus-view.module';
import { MessageQueueSearchResultsModule } from './business/message-queue-search-results/message-queue-search-results.module';
import { MessagingQueueModule } from './business/messaging-queue/messaging-queue.module';
import { NotificationQueueResultsModule } from './business/notification-queue-results/notification-queue-results.module';
import {
    NotificationQueueSearchCriteriaModule
} from './business/notification-queue-search-criteria/notification-queue-search-criteria.module';
import { OperatorAcceptanceQueueModule } from './business/operator-acceptance-queue/operator-acceptance-queue.module';
import { OperatorAcceptanceSearchModule } from './business/operator-acceptance-search-criteria/operator-acceptance-search.module';
import { AddDocumentsComponent } from './business/profile-management/add-documents/add-documents.component';
import { AddRoomsComponent } from './business/profile-management/add-rooms/add-rooms.component';
import { KeyControlsEditViewComponent } from './business/profile-management/common/key-controls-edit-view/key-controls-edit-view.component';
import { GenericModule } from './business/profile-management/generic/generic.module';
import {
    ManageSupplierAttributesWrapperModule
} from './business/profile-management/manage-supplier-attributes-wrapper/manage-supplier-attributes-wrapper.module';
import { AddNoteComponent } from './business/profile-management/shared/add-note/add-note.component';
import {
    GenericCostSetupFocusViewComponent
} from './business/profile-management/supplier/generic-cost-setup-focus-view/generic-cost-setup-focus-view.component';
import {
    GenericCostSetupFocusViewModule
} from './business/profile-management/supplier/generic-cost-setup-focus-view/generic-cost-setup-focus-view.module';
import { RoomGrpEditCardModule } from './business/profile-management/supplier/res-collection/room-grp-edit-card/room-grp-edit-card.module';
import {
    RoomGrpSummaryCardModule
} from './business/profile-management/supplier/res-collection/room-grp-summary-card/room-grp-summary-card.module';
import {
    RoomCostSetupFocusViewModule
} from './business/profile-management/supplier/room-cost-setup-focus-view/room-cost-setup-focus-view.module';
import {
    SeasonSetupFocusViewComponent
} from './business/profile-management/supplier/season-setup-focus-view/season-setup-focus-view.component';
import { SeasonSetupFocusViewModule } from './business/profile-management/supplier/season-setup-focus-view/season-setup-focus-view.module';
import { SupplierCostSetupModule } from './business/profile-management/supplier/supplier-cost-setup/supplier-cost-setup.module';
import {
    SupplierPaymentDetailComponent
} from './business/profile-management/supplier/supplier-payment-detail/supplier-payment-detail.component';
import { SupplierModule } from './business/profile-management/supplier/supplier.module';
import {
    TransportCostSetupFocusViewComponent
} from './business/profile-management/supplier/transport-cost-setup-focus-view/transport-cost-setup-focus-view.component';
import {
    TransportCostSetupQueueFocusViewComponent
} from './business/profile-management/supplier/transport-cost-setup-queue-focus-view/transport-cost-setup-queue-focus-view.component';
import { VehicleModule } from './business/profile-management/vehicle/vehicle.module';
import {
    AutoAssignedSummaryWrapperModule
} from './business/reservation-management/auto-assigned-summary-wrapper/auto-assigned-summary-wrapper.module';
import {
    LeadPassengerSelectionWrapperModule
} from './business/reservation-management/lead-passenger-selection-wrapper/lead-passenger-selection-wrapper.module';
import {
    PickupDropOffDetailsWrapperModule
} from './business/reservation-management/pickup-dropoff-details-wrapper/pickup-dropoff-details-wrapper.module';

import {
    ReservationSearchCriteriaComponent
} from './business/reservation-management/reservation-search-criteria/reservation-search-criteria.component';
import {
    ReservationSearchResultsComponent
} from './business/reservation-management/reservation-search-results/reservation-search-results.component';
import {
    SearchCriteriaSaveModalComponent
} from './business/reservation-management/search-criteria-save-modal/search-criteria-save-modal.component';
import {
    SearchCriteriaSaveModalModule
} from './business/reservation-management/search-criteria-save-modal/search-criteria-save-modal.module';
import {
    ServiceItemDetailFocusViewComponent
} from './business/reservation-management/service-item-detail-focus-view/service-item-detail-focus-view.component';
import {
    ServiceItemDetailFocusViewModule
} from './business/reservation-management/service-item-detail-focus-view/service-item-detail-focus-view.module';
import {
    ShowSavedCriteriaModalComponent
} from './business/reservation-management/show-saved-criteria-modal/show-saved-criteria-modal.component';
import { ShowSavedCriteriaModalModule } from './business/reservation-management/show-saved-criteria-modal/show-saved-criteria-modal.module';
import {
    SplitPassengersViewWrapperModule
} from './business/reservation-management/split-passengers-view-wrapper/split-passengers-view-wrapper.module';
import { OperationQueuePanelModule } from './business/reservation-v2/operation-queue-panel/operation-queue-panel.module';
import {
    ProductQueueSearchCriteriaModule
} from './business/reservation-v2/product-queue-search-criteria/product-queue-search-criteria.module';
import { ResourceAcceptanceQueueModule } from './business/resource-acceptance-queue/resource-acceptance-queue.module';
import {
    ResourceAcceptanceSearchCriteriaModule
} from './business/resource-acceptance-search-criteria/resource-acceptance-search-criteria.module';
import { ResourceMessagePanelModule } from './business/resource-message-panel/resource-message-panel.module';
import { RoomSearchCriteriaModule } from './business/room-search-criteria/room-search-criteria.module';
import { RoomSearchResultsModule } from './business/room-search-results/room-search-results.module';
import { GridSearchCriteriaModule } from './business/search-criteria/grid-search-criteria/grid-search-criteria.module';
import { CommonJobModule } from './business/settings/common-job/common-job.module';
import { ConfigSetupModule } from './business/settings/config-setup/config-setup.module';
import {
    AccountingRulesSetupSearchCriteriaComponent
} from './business/setup/accounting-rules-setup-search-criteria/accounting-rules-setup-search-criteria.component';
import { AccountingRulesSetupComponent } from './business/setup/accounting-rules-setup/accounting-rules-setup.component';
import { AddGeoLocationModalComponent } from './business/setup/add-geo-location-modal/add-geo-location-modal.component';
import {
    BoardBasisSetupSearchCriteriaComponent
} from './business/setup/board-basis-setup-search-criteria/board-basis-setup-search-criteria.component';

import { BoardBasisSetupComponent } from './business/setup/board-basis-setup/board-basis-setup.component';
import { BrandSetupSearchCriteriaComponent } from './business/setup/brand-setup-search-criteria/brand-setup-search-criteria.component';
import { BrandSetupComponent } from './business/setup/brand-setup/brand-setup.component';
import {
    CompanySetupSearchCriteriaComponent
} from './business/setup/company-setup-search-criteria/company-setup-search-criteria.component';
import { CompanySetupComponent } from './business/setup/company-setup/company-setup.component';
import {
    CostingTypeGridSetupSearchCriteriaComponent
} from './business/setup/costing-type-grid-setup-search-criteria/costing-type-grid-setup-search-criteria.component';
import { CostingTypeGridSetupComponent } from './business/setup/costing-type-grid-setup/costing-type-grid-setup.component';
import {
    CurrencyGridSetupSearchCriteriaComponent
} from './business/setup/currency-grid-setup-search-criteria/currency-grid-setup-search-criteria.component';
import { CurrencyGridSetupComponent } from './business/setup/currency-grid-setup/currency-grid-setup.component';
import {
    DistributionChannelSetupSearchCriteriaComponent
} from './business/setup/distribution-channel-setup-search-criteria/distribution-channel-setup-search-criteria.component';
import { DistributionChannelSetupComponent } from './business/setup/distribution-channel-setup/distribution-channel-setup.component';
import {
    DivisionSetupSearchCriteriaComponent
} from './business/setup/division-setup-search-criteria/division-setup-search-criteria.component';
import { DivisionSetupComponent } from './business/setup/division-setup/division-setup.component';
import {
    LedgerAccountSetupSearchCriteriaComponent
} from './business/setup/ledger-account-setup-search-criteria/ledger-account-setup-search-criteria.component';
import { LedgerAccountSetupComponent } from './business/setup/ledger-account-setup/ledger-account-setup.component';
import {
    LocationGroupsSetupSearchCriteriaComponent
} from './business/setup/location-groups-setup-search-criteria/location-groups-setup-search-criteria.component';
import { LocationGroupsSetupComponent } from './business/setup/location-groups-setup/location-groups-setup.component';
import {
    LocationsGridSetupSearchCriteriaComponent
} from './business/setup/locations-grid-setup-search-criteria/locations-grid-setup-search-criteria.component';
import {
    LocationsGridSetupSearchCriteriaModule
} from './business/setup/locations-grid-setup-search-criteria/locations-grid-setup-search-criteria.module';
import { LocationsGridSetupComponent } from './business/setup/locations-grid-setup/locations-grid-setup.component';
import { LocationsSetupComponent } from './business/setup/locations-setup/locations-setup.component';
import {
    MappedResourceTypesGridSetupComponent
} from './business/setup/mapped-resource-types-grid-setup/mapped-resource-types-grid-setup.component';
import {
    MappedRtypesGridSetupSearchCriteriaComponent
} from './business/setup/mapped-rtypes-grid-setup-search-criteria/mapped-rtypes-grid-setup-search-criteria.component';
import {
    MappedRtypesGridSetupSearchCriteriaModule
} from './business/setup/mapped-rtypes-grid-setup-search-criteria/mapped-rtypes-grid-setup-search-criteria.module';
import {
    ProductDetailsGridSetupSearchCriteriaComponent
} from './business/setup/product-details-grid-setup-search-criteria/product-details-grid-setup-search-criteria.component';
import { ProductDetailsGridSetupComponent } from './business/setup/product-details-grid-setup/product-details-grid-setup.component';
import {
    ProfileTypeSetupSearchCriteriaComponent
} from './business/setup/profile-type-setup-search-criteria/profile-type-setup-search-criteria.component';
import { ProfileTypeSetupComponent } from './business/setup/profile-type-setup/profile-type-setup.component';
import {
    ResourceTypeSetupSearchCriteriaComponent
} from './business/setup/resource-type-setup-search-criteria/resource-type-setup-search-criteria.component';
import { ResourceTypeSetupComponent } from './business/setup/resource-type-setup/resource-type-setup.component';
import {
    RoomFacilitySetupSearchCriteriaComponent
} from './business/setup/room-facility-setup-search-criteria/room-facility-setup-search-criteria.component';
import { RoomFacilitySetupComponent } from './business/setup/room-facility-setup/room-facility-setup.component';
import {
    RoomOccupancySetupSearchCriteriaComponent
} from './business/setup/room-occupancy-setup-search-criteria/room-occupancy-setup-search-criteria.component';
import {
    GenerateOccupanciesDialogComponent
} from './business/setup/room-occupancy-setup/generate-dialog/generate-occupancies-dialog.component';
import { RoomOccupancySetupComponent } from './business/setup/room-occupancy-setup/room-occupancy-setup.component';
import {
    RoomTypeGridSetupSearchCriteriaComponent
} from './business/setup/room-type-grid-setup-search-criteria/room-type-grid-setup-search-criteria.component';
import { RoomTypeGridSetupComponent } from './business/setup/room-type-grid-setup/room-type-grid-setup.component';
import {
    RoutesGridSetupSearchCriteriaComponent
} from './business/setup/routes-grid-setup-search-criteria/routes-grid-setup-search-criteria.component';
import { RoutesGridSetupComponent } from './business/setup/routes-grid-setup/routes-grid-setup.component';
import { AddLocationGroupComponent } from './business/setup/routes/location-groups/add-location-group/add-location-group.component';
import { EditLocationGroupComponent } from './business/setup/routes/location-groups/edit-location-group/edit-location-group.component';
import { LocationGroupsComponent } from './business/setup/routes/location-groups/location-groups.component';
import { LocationGroupsModule } from './business/setup/routes/location-groups/location-groups.module';
import { RouteSetupComponent } from './business/setup/routes/route-setup/route-setup.component';
import { RoutesComponent } from './business/setup/routes/routes.component';
import {
    EditTimeOffsetRulesComponent
} from './business/setup/routes/time-offset-rules/edit-time-offset-rules/edit-time-offset-rules.component';
import { TimeOffsetRulesComponent } from './business/setup/routes/time-offset-rules/time-offset-rules.component';
import { ServiceResourceSetupComponent } from './business/setup/service-resource-setup/service-resource-setup.component';
import { SupplierAcceptanceQueueModule } from './business/supplier-acceptance-queue/supplier-acceptance-queue.module';
import {
    SupplierAcceptanceSearchCriteriaModule
} from './business/supplier-acceptance-search-criteria/supplier-acceptance-search-criteria.module';
import {
    TransferSupplierSearchCriteriaModule
} from './business/transfer-supplier-search-criteria/transfer-supplier-search-criteria.module';
import {
    TransferSupplierSearchResultsComponent
} from './business/transfer-supplier-search-results/transfer-supplier-search-results.component';
import { VehicleSearchCriteriaModule } from './business/vehicle-search-criteria/vehicle-search-criteria.module';
import { VehicleSearchResultsModule } from './business/vehicle-search-results/vehicle-search-results.module';
import { ObjKeysPipePipeModule } from './pipes/obj-keys-pipe-pipe.module';
import { DataLoaderService } from './services/api/data-loader-service.service';
import { RoutesSetupService } from './services/backend-consumers/deprecated/routes-setup.service';
import { DataHandlerService } from './services/backend-consumers/master-data-handler-service/data-handler.service';
import { MasterDataHandlerService } from './services/backend-consumers/master-data-handler-service/master-data-handler.service';
import { NotificationService } from './services/backend-consumers/notifications/notification.service';
import { ReservationManagementService } from './services/backend-consumers/reservation-management-services/reservation-management.service';
import {
    TrsReservationManagementService
} from './services/backend-consumers/reservation-management-services/trs-reservation-management.service';
import { ProfileManagementService } from './services/backend-consumers/supplier-service/profile-management.service';
import { SupplierService } from './services/backend-consumers/supplier-service/supplier.service';
import { TransportResourceService } from './services/backend-consumers/supplier-service/transport-resource.service';
import { ChipHandlerService } from './services/helpers/chip-handler.service';
import { ContentPreProcessorService } from './services/helpers/content-pre-processor.service';
import { ChangeDetectorService } from './services/util/change-detector/change-detector.service';
import { DMCCommon } from './services/util/common/dmc-common';
import { UtilService } from './services/util/common/util.service';
import { RootService } from './services/util/core-services/root.service';
import { ActionHandlerService } from './services/util/framework/action-handler-service.service';
import { DataStoreService } from './services/util/framework/data-store.service';
import { DMCQueryParamsService } from './services/util/framework/dmc-query-params.service';
import { DMCRouteManager } from './services/util/framework/dmc-route-manager.service';
import { ErrorHandlerService } from './services/util/framework/error-handler.service';
import { ErrorProcessorService } from './services/util/framework/error-processor-service.service';
import { RequestService } from './services/util/framework/request.service';
import { IdentifierService } from './services/util/identifier/identifier.service';
import { GridDataMapperService } from './services/util/mapper/grid-data-mapper.service';
import { GenQueueGridDataProcessorService } from './services/util/pre-processors/gen-queue-grid-data-processor.service';
import { LeaveSetupSearchPreProcessorService } from './services/util/pre-processors/leave-setup-search-pre-processor.service';
import { OperationV2DataProcessorService } from './services/util/pre-processors/operation-v2-data-processor.service';
import { SetupCommonDataProcessorService } from './services/util/pre-processors/setup-common-data-processor.service';
import { DMCBaseService } from './services/util/system/dmc-base.service';
import { DMCLocalStorageService } from './services/util/system/dmc-local-storage.service';
import { CardContentPreProcessorService } from './services/util/ui/card-content-pre-processor.service';
import { CardDataHandlerService } from './services/util/ui/card-data-handler-service.service';
import { ContentSummaryCardService } from './services/util/ui/content-summary-card.service';
import { TableService } from './services/util/ui/table.service';
import { UiTestModule } from './ui-test/ui-test.module';
import { SetupGridModule } from './widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid.module';
import {
    RowErrorIndicatorComponent
} from './widgets/framework/ag-grid-custom-components/renderers/row-error-indicator/row-error-indicator.component';
import { AppHeaderComponent } from './widgets/framework/app-header/app-header.component';
import { AppHeaderModule } from './widgets/framework/app-header/app-header.module';
import { AppQuickActionComponent } from './widgets/framework/app-quick-action/app-quick-action.component';
import { AppQuickActionModule } from './widgets/framework/app-quick-action/app-quick-action.module';
import { AppSectionNavigationComponent } from './widgets/framework/app-section-navigation/app-section-navigation.component';
import { AppSectionNavigationModule } from './widgets/framework/app-section-navigation/app-section-navigation.module';
import { DMCSpinnerWrapperModule } from './widgets/framework/dmc-spinner-wrapper/dmc-spinner-wrapper.module';
import { FabWrapperModule } from './widgets/framework/fab-wrapper/fab-wrapper.module';
import { FileUploaderModule } from './widgets/framework/file-uploader/file-uploader.module';
import { LaunchPadPageComponent } from './widgets/framework/launch-pad/launch-pad.component';
import { LaunchPadPageModule } from './widgets/framework/launch-pad/launch-pad.module';
import { ManageNotesComponent, ManageNotesModule } from './widgets/framework/manage-notes';
import { NotificationToastModule } from './widgets/framework/notification-toast/notification-toast.module';
import { SideNavComponent } from './widgets/framework/side-nav/side-nav.component';
import { SideNavModule } from './widgets/framework/side-nav/side-nav.module';
import { AdditionalContactEditCardComponent } from './widgets/shared/additional-contact-edit-card/additional-contact-edit-card.component';
import { AdditionalContactEditCardModule } from './widgets/shared/additional-contact-edit-card/additional-contact-edit-card.module';
import {
    AdditionalContactSummaryCardModule
} from './widgets/shared/additional-contact-summary-card/additional-contact-summary-card.module';
import { AdditionalContactsModule } from './widgets/shared/additional-contacts/additional-contacts.module';
import { AdvReservationSearchResultsModule } from './widgets/shared/adv-reservation-search-results/adv-reservation-search-results.module';
import {
    AdvTransferSupplierSearchResultsComponent
} from './widgets/shared/adv-transfer-supplier-search-results/adv-transfer-supplier-search-results.component';
import {
    AdvTransferSupplierSearchResultsModule
} from './widgets/shared/adv-transfer-supplier-search-results/adv-transfer-supplier-search-results.module';
import { AvailabilityCalendarComponent } from './widgets/shared/availability-calendar/availability-calendar.component';
import { ContactDetailsComponent } from './widgets/shared/contact-details/contact-details.component';
import { ContactDetailsModule } from './widgets/shared/contact-details/contact-details.module';
import { CustomDirectivesModule } from './widgets/shared/custom-directives/custom-directives.module';
import { DataGridModule } from './widgets/shared/data-grid/data-grid.module';
import { DocumentAddCardComponent } from './widgets/shared/document-add-card/document-add-card.component';
import { DocumentAddCardModule } from './widgets/shared/document-add-card/document-add-card.module';
import { DocumentEditCardModule } from './widgets/shared/document-edit-card/document-edit-card.module';
import { DocumentSummaryCardModule } from './widgets/shared/document-summary-card/document-summary-card.module';
import { DocumentViewCardModule } from './widgets/shared/document-view-card/document-view-card.module';
import { PaymentInformationModule } from './widgets/shared/payment-information/payment-information.module';
import { PersonalInformationModule } from './widgets/shared/personal-information/personal-information.module';
import {
    ResAvailabilityCalendarFocusViewModule
} from './widgets/shared/res-availability-calendar-focus-view/res-availability-calendar-focus-view.module';
import { ResCalendarSideNavComponent } from './widgets/shared/res-calendar-side-nav/res-calendar-side-nav.component';
import { ResultHeaderModule } from './widgets/shared/result-header/result-header.module';
import { RoomAddCardComponent } from './widgets/shared/room-add-card/room-add-card.component';
import { RoomAddCardModule } from './widgets/shared/room-add-card/room-add-card.module';
import { SupplierAttributesModule } from './widgets/shared/supplier-attributes/supplier-attributes.module';
import { SupplierDetailViewModule } from './widgets/shared/supplier-detail-view/supplier-detail-view.module';
import { SupplierInformationModule } from './widgets/shared/supplier-information/supplier-information.module';
import { TaxSchemeEditCardModule } from './widgets/shared/tax-scheme-edit-card/tax-scheme-edit-card.module';
import { TaxSchemeSummaryCardModule } from './widgets/shared/tax-scheme-summary-card/tax-scheme-summary-card.module';
import { TaxSchemesModule } from './widgets/shared/tax-schemes/tax-schemes.module';
import { UserRolesEditorComponent } from './business/user-roles-editor/user-roles-editor.component';
import {AgGridModule} from 'ag-grid-angular';

export function initialize(app: AppService)
{
    return async () => {
        await app.loadInitialConfigurations();
        await app.loadAuthTokenElseLogin();
        await app.loadUserElseLogin();
        await app.loadConfigurations();
    };
}

export function HttpLoaderFactory(httpClient: HttpClient)
{
    return new TranslateHttpLoader(httpClient);
}

@NgModule({
    declarations: [
        AppComponent,
        DashboardSearchComponent,
        SupplierPaymentDetailComponent,
        RoutesComponent,
        TimeOffsetRulesComponent,
        EditLocationGroupComponent,
        EditTimeOffsetRulesComponent,
        RouteSetupComponent,
        ServiceResourceSetupComponent,
        AddLocationGroupComponent,
        TransferSupplierSearchResultsComponent,
        ReservationSearchCriteriaComponent,
        ReservationSearchResultsComponent,
        AddDocumentsComponent,
        LocationsSetupComponent,
        AccountingRulesSetupComponent,
        AccountingRulesSetupSearchCriteriaComponent,
        LocationsGridSetupComponent,
        RoutesGridSetupComponent,
        RoutesGridSetupSearchCriteriaComponent,
        LocationGroupsSetupComponent,
        LocationGroupsSetupSearchCriteriaComponent,
        LedgerAccountSetupComponent,
        LedgerAccountSetupSearchCriteriaComponent,
        LocationGroupsSetupSearchCriteriaComponent,
        CurrencyGridSetupComponent,
        CurrencyGridSetupSearchCriteriaComponent,
        ResourceTypeSetupSearchCriteriaComponent,
        ResourceTypeSetupComponent,
        ProfileTypeSetupSearchCriteriaComponent,
        ProfileTypeSetupComponent,
        AddGeoLocationModalComponent,
        CostingTypeGridSetupComponent,
        CostingTypeGridSetupSearchCriteriaComponent,
        RoomTypeGridSetupComponent,
        RoomTypeGridSetupSearchCriteriaComponent,
        RoomFacilitySetupSearchCriteriaComponent,
        RoomFacilitySetupComponent,
        AddRoomsComponent,
        RoomFacilitySetupComponent,
        MappedResourceTypesGridSetupComponent,
        RoomOccupancySetupSearchCriteriaComponent,
        RoomOccupancySetupComponent,
        GenerateOccupanciesDialogComponent,
        BoardBasisSetupComponent,
        BoardBasisSetupSearchCriteriaComponent,
        ProductDetailsGridSetupComponent,
        ProductDetailsGridSetupSearchCriteriaComponent,
        CompanySetupComponent,
        CompanySetupSearchCriteriaComponent,
        DivisionSetupComponent,
        DivisionSetupSearchCriteriaComponent,
        BrandSetupSearchCriteriaComponent,
        BrandSetupComponent,
        DistributionChannelSetupComponent,
        DistributionChannelSetupSearchCriteriaComponent,
        UserRolesEditorComponent,
    ],
    exports: [],
    imports: [
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyB_mf8EJSlDprUseskPn-vv_wA4OGhEfjw',
            libraries: ['places']
        }),
        HttpClientModule,
        RouterModule.forRoot([]),
        LaunchPadPageModule,
        ScaffoldingModule,
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        MatTableModule,
        MatPaginatorModule,
        MatSelectModule,
        SideNavModule,
        MatFormFieldModule,
        MatOptionModule,
        MatInputModule,
        MatExpansionModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatCardModule,
        MatIconModule,
        MatChipsModule,
        MatDialogModule,
        MatCheckboxModule,
        MatAutocompleteModule,
        MatTooltipModule,
        MatMenuModule,
        DialogModule,
        HeaderModule,
        AppHeaderModule,
        AppSectionNavigationModule,
        ModalModule,
        SidePanelModule,
        DirectiveSearchModule,
        ChipInputModule,
        AppQuickActionModule,
        EmptyResultModule,
        ResultsHeaderModule,
        FabWrapperModule,
        ResourceModule,
        DirectivesModule,
        ExpandedContentCardModule,
        ExpandedContentCardBodyModule,
        ExpandedContentCardFooterModule,
        AdvTransferSupplierSearchResultsModule,
        SupplierDetailViewModule,
        SummaryStripModule,
        AdvReservationSearchResultsModule,
        SectionHeadingModule,
        TranslateModule.forRoot(
            {
                loader: {
                    provide: TranslateLoader,
                    useFactory: HttpLoaderFactory,
                    deps: [HttpClient]
                }
            }
        ),
        ResultHeaderModule,
        TransferSupplierSearchCriteriaModule,
        ContactDetailsModule,
        PaymentInformationModule,
        PickupDropOffDetailsWrapperModule,
        LeadPassengerSelectionWrapperModule,
        SplitPassengersViewWrapperModule,
        AssignmentFocusViewModule,
        AdditionalContactSummaryCardModule,
        AdditionalContactEditCardModule,
        AdditionalContactsModule,
        TaxSchemeSummaryCardModule,
        TaxSchemesModule,
        TaxSchemeEditCardModule,
        DocumentViewCardModule,
        DocumentSummaryCardModule,
        DocumentEditCardModule,
        DocumentAddCardModule,
        SupplierInformationModule,
        ManageSupplierAttributesWrapperModule,
        SupplierAttributesModule,
        CustomDirectivesModule,
        PaginatorModule,
        ObjKeysPipePipeModule,
        PersonalInformationModule,
        SupplierModule,
        GenericModule,
        VehicleModule,
        GenericSearchCriteriaModule,
        GenericSearchResultsModule,
        DataGridModule,
        SupplierCostSetupModule,
        FocusViewModule,
        LocationGroupsModule,
        ServiceItemDetailFocusViewModule,
        ManageNotesModule,
        SeasonSetupFocusViewModule,
        VehicleSearchCriteriaModule,
        VehicleSearchResultsModule,
        RoomSearchResultsModule,
        RoomSearchCriteriaModule,
        DMCSpinnerWrapperModule,
        SearchCriteriaSaveModalModule,
        ShowSavedCriteriaModalModule,
        GenericCostSetupFocusViewModule,
        ResAvailabilityCalendarFocusViewModule,
        RoomCostSetupFocusViewModule,
        GenReservationSearchCriteriaModule,
        GenReservationSearchResultsModule,
        AvailabilityCalendarPageModule,
        AvailabilityCalendarSearchCriteriaModule,
        GenReservationSearchResultsModule,
        AutoAssignedSummaryWrapperModule,
        SetupGridModule,
        GridSearchCriteriaModule,
        DocumentsSearchResultsModule,
        DocumentCreationModalModule,
        LedgerEntrySearchResultsModule,
        UiTestModule,
        LocationSelectionFocusViewModule,
        LocationsGridSetupSearchCriteriaModule,
        ResourceAcceptanceSearchCriteriaModule,
        SupplierAcceptanceSearchCriteriaModule,
        ResourceAcceptanceQueueModule,
        SupplierAcceptanceQueueModule,
        NotificationQueueResultsModule,
        NotificationQueueSearchCriteriaModule,
        MessagingQueueModule,
        MessageQueueSearchResultsModule,
        ResourceMessagePanelModule,
        NotificationToastModule,
        ProductQueueSearchCriteriaModule,
        OperationQueuePanelModule,
        MappedRtypesGridSetupSearchCriteriaModule,
        FileUploaderModule,
        RoomAddCardModule,
        RoomGrpSummaryCardModule,
        RoomGrpEditCardModule,
        BookingSearchCriteriaModule,
        BookingSearchResultsModule,
        BookingModule,
        AllocationFeatureModule,
        ConfigSetupModule,
        CommonJobModule,
        OperatorAcceptanceSearchModule,
        OperatorAcceptanceQueueModule,
        BookingImportSummaryModule,
        AgGridModule,
        // HttpClientXsrfModule
    ],
    providers: [
        Constant,
        ConfigLoader,
        BaseService,
        DMCBaseService,
        DatePipe,
        CurrencyPipe,
        ProfileManagementService,
        DialogService,
        TranslateModule,
        SorterService,
        SpinnerService,
        AuthTokenService,
        AppService,
        {
            provide: APP_INITIALIZER,
            useFactory: initialize,
            deps: [AppService],
            multi: true
        },
        DataFormatterUtilService,
        {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: AuthHeaderInterceptor, multi: true},
        // {
        //     provide: HTTP_INTERCEPTORS,
        //     useClass: XsrfInterceptor,
        //     multi: true
        // },
        {provide: 'LaunchPadService', useClass: LaunchPadService},
        {provide: 'ContentPreProcessorService', useClass: ContentPreProcessorService},
        {provide: 'CardContentPreProcessorService', useClass: CardContentPreProcessorService},
        {provide: 'ResultsContentPreProcessorService', useClass: ResultsContentPreProcessorService},
        {provide: 'LeaveSetupSearchPreProcessorService', useClass: LeaveSetupSearchPreProcessorService},
        {provide: 'GenQueueGridDataProcessorService', useClass: GenQueueGridDataProcessorService},
        {provide: 'SetupCommonDataProcessorService', useClass: SetupCommonDataProcessorService},
        {provide: 'OperationV2DataProcessorService', useClass: OperationV2DataProcessorService},
        DataLoaderService,
        DataStore,
        FileUploadService,
        ScaffoldingHelper,
        ModalService,
        RootService,
        RouteManager,
        DMCRouteManager,
        UserJourneyManager,
        QueryParamsService,
        DMCQueryParamsService,
        RequestService,
        DMCLocalStorageService,
        ErrorHandlerService,
        EventManager,
        JsonUtilService,
        HeaderService,
        WindowRef,
        DataStoreService,
        FocusViewService,
        FocusViewManager,
        DateFormatter,
        ChipInputService,
        Parser,
        CookieService,
        LocalStorageService,
        CardDataHandlerService,
        Common,
        DMCCommon,
        ResultsHeaderService,
        APIConfigLoaderService,
        KebabService,
        CommonHelper,
        AlphaNumericSorting,
        FocusHandlerService,
        DataStore,
        AutoScrollService,
        PageSummaryService,
        SideNavBarService,
        AnimationService,
        ActionHandlerService,
        ChipHandlerService,
        DynamicRenderer,
        Constant,
        ExpansionPanelDirective,
        DataHandlerService,
        RoutesSetupService,
        SupplierService,
        UtilService,
        DataServiceHandler,
        PersonalizationHandler,
        UserServiceHandlerService,
        LogStore,
        ErrorProcessorService,
        ContentSummaryCardService,
        ChangeDetectorService,
        GridDataMapperService,
        TableService,
        MasterDataHandlerService,
        IdentifierService,
        TransportResourceService,
        ReservationManagementService,
        TrsReservationManagementService,
        NotificationService
    ],
    bootstrap: [AppComponent],
    entryComponents: [
        ScaffS7v0Component,
        S05Component,
        DialogComponent,
        SideNavComponent,
        LaunchPadPageComponent,
        DashboardSearchComponent,
        AppQuickActionComponent,
        AppSectionNavigationComponent,
        ModalComponent,
        AddNoteComponent,
        TimeOffsetRulesComponent,
        EditLocationGroupComponent,
        EditTimeOffsetRulesComponent,
        RouteSetupComponent,
        ServiceResourceSetupComponent,
        AddLocationGroupComponent,
        AdditionalContactEditCardComponent,
        ContactDetailsComponent,
        TransferSupplierSearchResultsComponent,
        AdvTransferSupplierSearchResultsComponent,
        ReservationSearchCriteriaComponent,
        ReservationSearchResultsComponent,
        AppHeaderComponent,
        DocumentAddCardComponent,
        AddDocumentsComponent,
        LocationsSetupComponent,
        FocusViewComponent,
        SeasonSetupFocusViewComponent,
        LocationGroupsComponent,
        ServiceItemDetailFocusViewComponent,
        ManageNotesComponent,
        TransportCostSetupFocusViewComponent,
        TransportCostSetupQueueFocusViewComponent,
        SnackBarComponent,
        SearchCriteriaSaveModalComponent,
        ShowSavedCriteriaModalComponent,
        GenericCostSetupFocusViewComponent,
        AvailabilityCalendarPageComponent,
        LeaveSetupFocusViewComponent,
        ResCalendarSideNavComponent,
        AvailabilityCalendarComponent,
        AvailabilityCalendarSearchCriteriaComponent,
        AccountingRulesSetupComponent,
        AccountingRulesSetupSearchCriteriaComponent,
        LocationsGridSetupComponent,
        LocationsGridSetupSearchCriteriaComponent,
        RoutesGridSetupComponent,
        RoutesGridSetupSearchCriteriaComponent,
        LocationGroupsSetupComponent,
        LocationGroupsSetupSearchCriteriaComponent,
        RowErrorIndicatorComponent,
        LedgerAccountSetupComponent,
        LedgerAccountSetupSearchCriteriaComponent,
        RowErrorIndicatorComponent,
        CurrencyGridSetupComponent,
        CurrencyGridSetupSearchCriteriaComponent,
        ResourceTypeSetupComponent,
        ResourceTypeSetupSearchCriteriaComponent,
        ProfileTypeSetupComponent,
        ProfileTypeSetupSearchCriteriaComponent,
        AddGeoLocationModalComponent,
        LocationSelectionFocusViewComponent,
        CostingTypeGridSetupComponent,
        CostingTypeGridSetupSearchCriteriaComponent,
        RoomTypeGridSetupComponent,
        RoomTypeGridSetupSearchCriteriaComponent,
        RoomFacilitySetupComponent,
        RoomFacilitySetupSearchCriteriaComponent,
        RoomAddCardComponent,
        AddRoomsComponent,
        MappedRtypesGridSetupSearchCriteriaComponent,
        MappedResourceTypesGridSetupComponent,
        RoomOccupancySetupSearchCriteriaComponent,
        RoomOccupancySetupComponent,
        GenerateOccupanciesDialogComponent,
        BoardBasisSetupComponent,
        BoardBasisSetupSearchCriteriaComponent,
        ProductDetailsGridSetupComponent,
        ProductDetailsGridSetupSearchCriteriaComponent,
        KeyControlsEditViewComponent,
        BookingImportSummaryComponent,
        KeyControlsEditViewComponent,
        CompanySetupComponent,
        CompanySetupSearchCriteriaComponent,
        DivisionSetupComponent,
        DivisionSetupSearchCriteriaComponent,
        BrandSetupSearchCriteriaComponent,
        BrandSetupComponent,
        DistributionChannelSetupComponent,
        DistributionChannelSetupSearchCriteriaComponent,
        UserRolesEditorComponent
    ]
})
export class AppModule
{
}
