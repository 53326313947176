import * as tslib_1 from "tslib";
import { AbstractSearchCriteria } from './abstract-search-criteria';
var RoomOccupancySearchCriteria = /** @class */ (function (_super) {
    tslib_1.__extends(RoomOccupancySearchCriteria, _super);
    function RoomOccupancySearchCriteria() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.name = '';
        return _this;
    }
    return RoomOccupancySearchCriteria;
}(AbstractSearchCriteria));
export { RoomOccupancySearchCriteria };
