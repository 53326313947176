import { Injectable } from '@angular/core';

@Injectable()
export class IdentifierService {

    private id: number;
    private manuallyResetId: number = 0;

    constructor() {
        this.id = 0;
    }

    resetManuallyResetId() {
        this.manuallyResetId = 0;
    }

    public getNextId() {
        this.id += 1;
        return this.id;
    }

    public getNextManuallyResetId() {
        this.manuallyResetId += 1;
        return this.manuallyResetId;
    }

}
