import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { TcErrorType } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-error';
import { DialogModel, LEVEL } from '@tc-core/model/it/codegen/ui/framework/dialog-model';
import { ConfigLoader } from '@tc-core/util/framework/config-loader.service';
import { SpinnerService } from '@tc-core/util/ui';
import { DialogService } from '@tc/dialog/dialog.service';
import { IGetRowsParams } from 'ag-grid-community';
import { Observable } from 'rxjs/Observable';
import { tap } from 'rxjs/operators';
import { TCO } from '../../../constants';
import { BrandSearchCriteria } from '../../../models/criteria/brand-search-criteria';
import { SortDirection } from '../../../models/helper/sort-direction';
import {
    MasterDataRestService
} from '../../../services/backend-consumers/master-data-handler-service/master-data-rest.service';
import { BrandSetupService } from '../../../services/backend-consumers/setups/brand-setup-service';
import { CostingTypeService } from '../../../services/backend-consumers/setups/costing-type-service';
import { UserManagementService } from '../../../services/user-management/user-management.service';
import { DMCCommon } from '../../../services/util/common/dmc-common';
import { DataKey, DataStoreService } from '../../../services/util/framework/data-store.service';
import {
    SetupGridComp
} from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid-comp';
import {
    SetupGridDataSource
} from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid-data-source';
import {
    SetupGridComponent
} from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid.component';
import { of, Subscription } from 'rxjs';

@Component({
    selector: 'tc-brand-setup',
    templateUrl: './brand-setup.component.html'
})
export class BrandSetupComponent extends SetupGridComp implements OnInit, SetupGridDataSource {
    @ViewChild('codeCell') codeCell: TemplateRef<any>;
    @ViewChild('nameCell') nameCell: TemplateRef<any>;
    @ViewChild('setupGrid')
    private setupGrid: SetupGridComponent;
    public colDefConfig = [];

    // search criteria obj. used to
    private brandSearchCriteria: BrandSearchCriteria = new BrandSearchCriteria();

    private searchCriteriaObserver: Subscription = new Subscription();
    private dialogSubscription: Subscription = new Subscription();

    allCostingTypeCodes = [];

    constructor(
        private configLoader: ConfigLoader,
        private costingTypeService: CostingTypeService,
        private dataStoreService: DataStoreService,
        private masterDataRestService: MasterDataRestService,
        private common: DMCCommon,
        private dataStore: DataStoreService,
        private brandSetupService: BrandSetupService,
        private spinnerService: SpinnerService,
        private dialogService: DialogService,
        private userManagementService: UserManagementService
    ) {
        super();
    }

    ngOnInit() {
        this.colDefConfig = this.configLoader.configurations.get(TCO.CONF.CONF_BRAND_SETUP_CONFIG);
        this.subscribeSearchCriteria();
    }

    /**
     * subscribe search criteria changes in search bar
     * then force grid to refresh data
     * grid refresh will be call getRaws method which is using this subscribed criteria for backend call
     */
    subscribeSearchCriteria() {
        this.searchCriteriaObserver = this.dataStoreService.get(DataKey.brandSetupSearchCriteria).subscribe(value => {
            this.brandSearchCriteria = value;
            this.setupGrid.runForceSearch();
        });
    }

    createNewRow(): any {
        return {unsaved: true};
    }

    deleteRow(row: any): Observable<any> {
        return of('success');
    }

    public getRows(params: IGetRowsParams): Observable<any> {
        if (!this.brandSearchCriteria) {
            this.brandSearchCriteria = new BrandSearchCriteria();
        }
        this.brandSearchCriteria = this.userManagementService.updateSearchCriteriaWithUserKC(this.brandSearchCriteria);
        let pageSize = 14;
        if (params && params.endRow && params.startRow) {
            pageSize = params.endRow - params.startRow;
        }
        if (params && params.startRow) {
            this.brandSearchCriteria.start = params.startRow;
        }
        if (pageSize) {
            this.brandSearchCriteria.size = pageSize;
        }
        if (params.sortModel && params.sortModel[0]) {
            this.brandSearchCriteria.sortBy = params.sortModel[0].colId;
            if (params.sortModel[0].sort === SetupGridComp.GRID_SORT_ASCENDING) {
                this.brandSearchCriteria.sortDirection = SortDirection.ASC;
            } else {
                this.brandSearchCriteria.sortDirection = SortDirection.DESC;
            }
        }
        return this.brandSetupService.getRows(this.brandSearchCriteria)
                   .pipe(
                       tap(
                           data => {
                               this.dataStoreService.set(DataKey.brandSetupSearchResultsForCriteria, data);
                           }
                       )
                   );
    }

    public isInvalidRow(params): boolean {
        const data = params.data;
        if (data) {
            let invalid = true;
            if (
                data.code &&
                data.name
            ) {
                invalid = false;
            }
            return invalid;
        } else {
            return false;
        }
    }

    public nonSelectionRow(params): boolean {
        const data = params.data;
        if (data) {
            let invalid = true;
            if (
                data.code &&
                data.name
            ) {
                invalid = false;
            }
            return invalid;
        } else {
            return false;
        }
    }

    public isUnsavedRow(row: any): boolean {
        if (row) {
            return !row.id;
        }
        return true;
    }

    public isDisabledAdd() {
        const userProfile = this.dataStore.get(DataKey.userProfile).getValue();
        return (userProfile && userProfile.kcBrand);
    }

    saveRow(row: any, event: any): Observable<any> {
        if (row.unsaved) {
            const confirmSendManifests = new DialogModel(
                true,
                LEVEL.WARNING,
                'Save Brand',
                'The entered brand cannot be modified or deleted later. Do you want to continue?',
                true,
                2000,
                null,
                'Cancel',
                'Save',
                true
            );
            confirmSendManifests.disableClose = true;
            if (this.dialogSubscription) {
                this.dialogSubscription.unsubscribe();
            }
            const dialogSubscription = this.dialogService
                                           .confirm(confirmSendManifests)
                                           .subscribe((res) => {
                                                   if (res === true) {
                                                       return this.brandSetupService.saveRow(row).subscribe(
                                                           (response) => {
                                                               this.userManagementService.getKcBrands();
                                                               this.spinnerService.show();
                                                               dialogSubscription.unsubscribe();
                                                               this.setupGrid.runForceSearch();
                                                               this.spinnerService.hide();
                                                           },
                                                           error => {
                                                               this.spinnerService.hide();
                                                               if (event && event.data) {
                                                                   event.data.name = '';
                                                               }
                                                               this.common.showSnackBar(
                                                                   'Brand code cannot be duplicated',
                                                                   3000,
                                                                   TcErrorType.TYPE.ERROR
                                                               );
                                                           }
                                                       );
                                                   } else {
                                                       if (event && event.data) {
                                                           event.data.name = '';
                                                       }
                                                   }
                                               }
                                           );
        }
        return null;
    }
}
