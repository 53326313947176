import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { CommonHelper } from '@tc-core/util/helpers';


@Component({
    selector: 'tc-document-add-card',
    templateUrl: './document-add-card.component.html'
})
export class DocumentAddCardComponent implements OnInit {
    // data: any;
    @Input() docTypeOptions: any[];
    @Input() selectedDocs: any[];

    @Output() closeModal: EventEmitter<any[]> = new EventEmitter();

    selectedDocTypes = [];
    selectedDocCodes = [];
    selected = [];

    constructor(
        private commonHelper: CommonHelper
    ) {
    }

    ngOnInit() {
        this.loadDocumentsTypes();
    }

    private loadDocumentsTypes() {
        // let selectedDocs = this.dataStore.get(DataKey.documentTypes).value;
        if (this.selectedDocs) {
            for (let doc of this.selectedDocs) {
                // this.selectedDocTypes.push(doc);
                this.selectedDocCodes.push(doc.code);
                this.selected.push(doc.code);
                this.selectedDocTypes.push(doc);
            }
        }
    }



    public addType(isSelected: any, docType: any) {
        if (isSelected.checked) {
            this.selectedDocCodes.push(docType.code);
            this.selectedDocTypes.push(docType);
        }
        else {
            this.selectedDocCodes.splice(this.selectedDocCodes.indexOf(docType.code), 1);
            this.selectedDocTypes.forEach(d => {
                if (d.code === docType.code) {
                    this.selectedDocTypes.splice(this.selectedDocTypes.indexOf(d), 1);
                }
            });
        }
    }

    addDocuments() {
        this.closeModal.emit(this.selectedDocTypes);
        /*this.dataStore.set(DataKey.documentTypes, this.selectedDocTypes, true);
        this.dataStore.set(DataKey.modalClose, true);*/
    }

    public closePopUp(event: any) {

    }
}
