import { Component, Input } from '@angular/core';
import { TC } from '@tc-core/util/constants';
import { DocumentData } from '../../../models/profile/document';

@Component({
    selector: 'tc-document-card',
    templateUrl: './document-card.component.html'
})
export class DocumentCardComponent {
    EMPTY_CHAR = TC.FORMAT.EMPTY_CHAR;

    @Input() showTitle = true;
    @Input() documents: DocumentData[];
}
