<!--<mat-card>-->
<div class="" #form tabindex="0" (focusout)="focusOutFunction($event)">
  <mat-form-field style="height: 100%;">
    <input
            #inputTextBox
            matInput type="text"
            [placeholder]="placeholder"
            [formControl]="itemControl"
            [matAutocomplete]="auto"
            (focus)="onTextInputFocus($event)">
    <mat-autocomplete class="mat-autocomplete-panel-grid-editor"
                      autoActiveFirstOption #auto="matAutocomplete" [style.width]="columnWidth"
                      [displayWith]="displayItemFn">
      <mat-option
              *ngFor="let item of filteredItems " (onSelectionChange)="onSelectItem($event)"
              [value]="item">

        <div *ngIf="displayItemFn(item)!='-- CLEAR --'" [ngClass]="getResourceStatus(item) ? 'tc-ag-grid-online-status': 'tc-ag-grid-offline-status'" >        </div>
        {{displayItemFn ? displayItemFn(item) : item | translate}}
      </mat-option>
    </mat-autocomplete>

  </mat-form-field>
</div>
<!--</mat-card>-->