import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { NgxMaterialTimepickerTheme } from 'ngx-material-timepicker/src/app/material-timepicker/models/ngx-material-timepicker-theme.interface';

@Component({
    selector: 'tc-time-editor-renderer',
    templateUrl: './time-editor-renderer.component.html'
})
export class TimeEditorRendererComponent implements ICellRendererAngularComp {

    params: any;
    isError = false;
    editableFn: any;
    column: string;
    options: any[] = [];
    rowData: any;
    rawValue: any;
    selectedTime: any;
    afterEdit: any;
    format = 24;
    default = '00:00';
    minTime: any;
    maxTime: any;
    public theme: NgxMaterialTimepickerTheme = {
        dial: {
            dialBackgroundColor: '#3f51b5'
        },
        container: {
            buttonColor: '#3f51b5'
        },
        clockFace: {
            clockHandColor: '#3f51b5'
        }
    };
    editable: boolean;

    constructor() { }

    public agInit(params: ICellRendererParams): void {
        this.params = params;
        this.options = params['options'];
        this.column = params['colDef']['colId'];
        this.editableFn = params['colDef']['editInRenderer'];
        this.rowData = params['data'];
        this.rawValue = params['value'];
        this.afterEdit = params['afterEdit'];
        this.format = params['format'];
        if (params['default']) {
            this.default = params['default'];
        }
        if (!this.rawValue) {
            this.rawValue = this.default;
        }
    }

    public refresh(params: any): boolean {
        return true;
    }

    public getValue(): any {
        if (this.selectedTime) {
            return this.selectedTime;
        } else {
            return null;
        }
    }

    public onValueChange(event: string) {
        this.selectedTime = event;
        const rowOldValue = this.rowData[this.column];
        this.rowData[this.column] = this.selectedTime;
        const cellValueChangedEvent = this.params;
        cellValueChangedEvent.newValue = this.rowData[this.column];
        cellValueChangedEvent.oldValue = rowOldValue;
        this.rawValue = this.selectedTime;
        this.afterEdit(cellValueChangedEvent);

        if (cellValueChangedEvent.colDef.field == 'startTime') {
            sessionStorage.setItem('NEW_MIN_TIME', cellValueChangedEvent.newValue);
        }
    }

    public onInputChange(event: any) {
        if (this.editable) {
            const value = event.target.value;
            if (value) {
                if (/^([2][0-3]|[01]?[0-9])([.:][0-5][0-9])?$/.test(value)) {
                    this.selectedTime = event.target.value;
                    const rowOldValue = this.rowData[this.column];
                    this.rowData[this.column] = this.selectedTime;
                    const cellValueChangedEvent = this.params;
                    cellValueChangedEvent.newValue = this.rowData[this.column];
                    cellValueChangedEvent.oldValue = rowOldValue;
                    this.afterEdit(cellValueChangedEvent);
                } else {
                    this.rawValue = this.default;
                    event.target.value = this.rawValue;
                }
            } else {
                this.rawValue = this.default;
                event.target.value = this.rawValue;
            }
        }
    }

    public isDisabled() {
        this.editable = this.editableFn(this.params);
        return !this.editable;
    }

    public setTime() {
        if (this.params.colDef.field == 'startTime') {
            sessionStorage.setItem('NEW_MIN_TIME', this.params.value);
        }
        if (this.params.colDef.field == 'endTime') {
            this.minTime = sessionStorage.getItem('NEW_MIN_TIME');
        }
    }

    public onFocusIn() {
        return this.rawValue===this.default ? this.rawValue='' : '';
    }
}
