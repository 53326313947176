import { Component, Input, OnInit } from '@angular/core';
// import { DateRange } from '@tc/cg-calendar/date-range';
// import { RangeGroup } from '@tc/cg-calendar/range-group';

import * as moment from 'moment';
import { DateRange } from '../../cg-calendar/date-range';
import { RangeGroup } from '../../cg-calendar/range-group';

@Component({
  selector: 'tc-season-setup-summary',
  templateUrl: './season-setup-summary.component.html'
})
export class SeasonSetupSummaryComponent implements OnInit {

  @Input() rangeGroups: RangeGroup[] = [];

  validYears: any[] = [];
  selectedYear: number = -1;
  validYearsNumArray: number[] = [];

  constructor() { }

  ngOnInit() {
    this.getValidYears();
  }

  public getDateBandsStr(rangeGroup: RangeGroup): string {
    let dateBands = '';
    let dateRanges = this.getShowingDateBands(rangeGroup);
    dateRanges.forEach(dateRange => {
      let startYear = dateRange.startDate.getFullYear();
      let endYear = dateRange.endDate.getFullYear();

      dateBands += moment(dateRange.startDate).format('DD MMM');

      if (this.selectedYear !== startYear) {
        dateBands += ' (' + startYear + ')';
      }
      dateBands += ' - ';
      dateBands += moment(dateRange.endDate).format('DD MMM');

      if (this.selectedYear !== endYear) {
        dateBands += ' (' + endYear + ')';
      }
      dateBands += ', \xa0';
    });
    dateBands = dateBands.substr(0, dateBands.length - 3);
    return dateBands;
  }

  getValidYears() {
    this.rangeGroups.forEach(group => {
      group.dateRanges.forEach(
        dateRange => {
          let startYear = dateRange.startDate.getFullYear();
          let endYear = dateRange.endDate.getFullYear();
          if (this.validYearsNumArray.indexOf(startYear) === -1) {
            this.validYearsNumArray.push(startYear);
            this.validYears.push({'code': startYear, 'value': startYear});
          }
          if (this.validYearsNumArray.indexOf(endYear) === -1) {
            this.validYearsNumArray.push(endYear);
            this.validYears.push({'code': endYear, 'value': endYear});
          }
        }
      );
    });

    if (this.validYears && this.validYears[0]) {
      this.validYears[0].isSelected = true;
      this.selectedYear = this.validYears[0].code;
    }
  }

  public onSelectGroupingType($event) {
    this.selectedYear = $event;
  }

  public getShowingSeasons() {
    let seasons: RangeGroup[] = [];
    for (let i = 0; i < this.rangeGroups.length; i++) {
      let group = this.rangeGroups[i];
      if (seasons.indexOf(group) === -1) {
        for (let j = 0; j < group.dateRanges.length; j++) {
          let dateRange = group.dateRanges[j];
          if (seasons.indexOf(group) === -1) {
            if (dateRange.startDate.getFullYear() === this.selectedYear || dateRange.endDate.getFullYear() ===
              this.selectedYear) {
              seasons.push(group);
            }
          } else {
            break;
          }
        }
      }
    }
    return seasons;
  }

  public getShowingDateBands(rangeGroup: RangeGroup): DateRange[] {
    let dateBands: DateRange[] = [];
    for (let i = 0; i < rangeGroup.dateRanges.length; i++) {
      let dateRange = rangeGroup.dateRanges[i];
      if (dateRange.startDate.getFullYear() === this.selectedYear || dateRange.endDate.getFullYear() ===
        this.selectedYear) {
        dateBands.push(dateRange);
      }
    }
    return dateBands;
  }
}
