import * as tslib_1 from "tslib";
import { KeyControlAndAbstractSearchCriteria } from './key-control-and-abstract-search-criteria';
var VehicleSearchCriteria = /** @class */ (function (_super) {
    tslib_1.__extends(VehicleSearchCriteria, _super);
    function VehicleSearchCriteria() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return VehicleSearchCriteria;
}(KeyControlAndAbstractSearchCriteria));
export { VehicleSearchCriteria };
