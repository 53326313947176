import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SecurityModule} from '@tc/security';
import {MatProgressBarModule, MatTooltipModule,MatIconModule,MatButtonModule} from '@angular/material';
import {TranslateModule} from '@ngx-translate/core';
import {OpExpandedContentCardHeaderComponent} from './op-expanded-content-card-header.component';

@NgModule({
    imports: [
        CommonModule,
        MatProgressBarModule,
        TranslateModule.forChild(),
        MatTooltipModule,
        SecurityModule,
        MatIconModule,
        MatButtonModule
    ],
    exports: [
        OpExpandedContentCardHeaderComponent
    ],
    declarations: [OpExpandedContentCardHeaderComponent]
})
export class OpExpandedContentCardHeaderModule {
}
