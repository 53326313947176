import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LEVEL, ModalData } from '@tc-core/model/it/codegen/ui/framework/modal-data';
import { Pager } from '@tc-core/model/it/codegen/ui/framework/pager';
import { SorterService } from '@tc-core/service/sorters/sorter.service';
import { TC } from '@tc-core/util/constants';
import { EventManager } from '@tc-core/util/framework';
import { ConfigLoader } from '@tc-core/util/framework/config-loader.service';
import { UserJourneyManager } from '@tc-core/util/framework/user-journey-manager.service';
import { CommonHelper } from '@tc-core/util/helpers/common-helper.service';
import { AutoScrollService } from '@tc-core/util/ui/auto-scroll.service';
import { SpinnerService } from '@tc-core/util/ui/spinner.service';
import { ModalService } from '@tc/modal/modal.service';
import { Subscription } from 'rxjs';
import { BookingItemExportComponent } from '../../booking-item-export/booking-item-export.component';
import { TCO } from '../../../constants';
import { BookingSearchCriteria } from '../../../models/reservation-v2/criteria/booking-search-criteria';
import { DocumentService } from '../../../services/backend-consumers/documents/document.service';
import { DataHandlerService } from '../../../services/backend-consumers/master-data-handler-service/data-handler.service';
import { BookingManagementService } from '../../../services/backend-consumers/reservation-v2/booking-management.service';
import { ReservationV2ManagementService } from '../../../services/backend-consumers/reservation-v2/reservation-v2-management.service';
import { RootService } from '../../../services/util/core-services/root.service';
import { DataKey, DataStoreService } from '../../../services/util/framework/data-store.service';
import { DMCQueryParamsService } from '../../../services/util/framework/dmc-query-params.service';
import { ResponseUtil } from '../../../services/util/response/response-util.service';
import { ContentSummaryCardService } from '../../../services/util/ui/content-summary-card.service';
import { BookingImportSummaryComponent } from '../booking-import-summary/booking-import-summary.component';
import { BookingUtilService } from '../booking-util.service';
import { TcErrorType } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-error';
import { DMCCommon } from '../../../services/util/common/dmc-common';
var BookingSearchResultsComponent = /** @class */ (function () {
    function BookingSearchResultsComponent(configLoader, dataStore, sorterService, summaryCardService, spinnerService, userJourneyManager, dataHandlerService, commonHelper, activatedRoute, rootService, queryParamService, autoScrollService, bookingManagementService, bookingUtil, reservationV2ManagementService, em, modalService, documentService, common) {
        this.configLoader = configLoader;
        this.dataStore = dataStore;
        this.sorterService = sorterService;
        this.summaryCardService = summaryCardService;
        this.spinnerService = spinnerService;
        this.userJourneyManager = userJourneyManager;
        this.dataHandlerService = dataHandlerService;
        this.commonHelper = commonHelper;
        this.activatedRoute = activatedRoute;
        this.rootService = rootService;
        this.queryParamService = queryParamService;
        this.autoScrollService = autoScrollService;
        this.bookingManagementService = bookingManagementService;
        this.bookingUtil = bookingUtil;
        this.reservationV2ManagementService = reservationV2ManagementService;
        this.em = em;
        this.modalService = modalService;
        this.documentService = documentService;
        this.common = common;
        this.heading = '';
        this.defaultPageResultCount = 10;
        this.selectionIndex = '';
        this.fetchedReservationsCount = 0;
        this.dataFromMoreClick = false;
        this.paginatorHasMore = true;
        this.resultCount = 0;
        this.searchCriteria = new BookingSearchCriteria();
        this.contentCardDataList = [];
        this.selectedSearchSummary = null;
        this.navConfig = [];
        this.showEditBtn = true;
        this.showEditAsNew = true;
        this.searchCriteriaSub = new Subscription();
        this.searchResultsCollectedSub = new Subscription();
        this.searchResultsFromServiceSub = new Subscription();
        this.bookingItemSheetImportSubscribe = new Subscription();
        this.popupCloseModel = new Subscription();
        this.supplierByIdSub = new Subscription();
        this.failedItemSheetExportSubscribe = new Subscription();
        this.tTemplateTypes = [];
    }
    BookingSearchResultsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.tBookingSearchLineConfig = this.configLoader.configurations.get(TCO.CONF.CONF_BOOKING_SEARCH_RESULTS_CONFIG);
        this.tPagination = this.configLoader.configurations.get(TCO.CONF.CONF_PAGINATION_CONFIG).BOOKING_SEARCH;
        this.tTemplateTypes = this.configLoader.configurations.get(TCO.CONF.CONF_BOOKING_IMPORT_CONFIG).template_types;
        this.setupPaginator();
        this.getSearchCriteria();
        this.subscribeSearchResults();
        this.getSearchResults();
        this.createSorters();
        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'EXPORT').subscribe(function (e) {
            _this.importBookingItemSheet();
        });
        this.userJourneyManager.canProceed.next(true);
        this.pageOptionsAvailability = this.configLoader.configurations.get(TCO.CONF.CONF_PAGE_OPTION_AVAILABILITY).BOOKING_MANAGEMENT;
        this.setupPageOptions();
        //
        // this.supplier = null;
        // this.supplierByIdSub = this.dataStore.get(DataKey.supplier).subscribe(res => {
        //     if (this.commonHelper.dataValidity(res) && res.data && res.data.length > 0) {
        //         if (this.supplierByIdSub) {
        //             this.supplierByIdSub.unsubscribe();
        //         }
        //         this.spinnerService.hide();
        //         this.supplier = ResponseUtil.getFirstData(res);
        //         console.log(this.supplier);
        //     } else if (res instanceof TcApiError || res instanceof TcHttpError) {
        //         this.spinnerService.hide();
        //     }
        // });
    };
    BookingSearchResultsComponent.prototype.setupPaginator = function () {
        this.defaultPageResultCount = this.tPagination.defaultPageResultCount;
        this.pager = new Pager(0, this.defaultPageResultCount);
    };
    BookingSearchResultsComponent.prototype.onPage = function (pageNumber) {
        this.pager.currPage = pageNumber;
        this.selectionIndex = (this.pager.currPage * this.pager.itemsPerPage).toString();
    };
    BookingSearchResultsComponent.prototype.onSelection = function (event) {
        this.selectionIndex = event;
    };
    BookingSearchResultsComponent.prototype.onMore = function () {
        this.dataFromMoreClick = true;
        if (this.tPagination && this.tPagination.defaultPageResultCount && this.tPagination.fetchingPages) {
            this.searchCriteria.size = this.tPagination.fetchingPages * this.tPagination.defaultPageResultCount;
            this.searchCriteria.start = this.fetchedReservationsCount;
            this.retrieveAndCollectSearchResults(this.searchCriteria);
        }
    };
    BookingSearchResultsComponent.prototype.setPageZero = function () {
        if (this.pager) {
            if (!this.dataFromMoreClick) {
                this.pager.currPage = 0;
            }
            else {
                this.dataFromMoreClick = false;
            }
        }
        else {
            this.pager = new Pager(0, this.defaultPageResultCount);
        }
    };
    BookingSearchResultsComponent.prototype.subscribeSearchResults = function () {
        var _this = this;
        this.searchResultsFromServiceSub = this.dataStore.get(DataKey.bookingSummarySearchResultsFromService)
            .subscribe(function (result) {
            _this.resultCount = 0;
            if (result) {
                _this.resultCount = ResponseUtil.getTotalCount(result);
                var dataList = ResponseUtil.getDataArray(result);
                _this.dataStore.set(DataKey.bookingSummarySearchResults, dataList, true);
            }
        });
    };
    BookingSearchResultsComponent.prototype.retrieveAndCollectSearchResults = function (searchCriteria) {
        var _this = this;
        this.bookingManagementService.searchBookingSummariesOnMore(JSON.parse(JSON.stringify(searchCriteria)))
            .subscribe(function (result) {
            _this.resultCount = 0;
            if (result) {
                _this.resultCount = ResponseUtil.getTotalCount(result);
                var dataList = _this.searchResults.concat(ResponseUtil.getDataArray(result));
                _this.dataStore.set(DataKey.bookingSummarySearchResults, dataList, true);
            }
        });
    };
    BookingSearchResultsComponent.prototype.getSearchCriteria = function () {
        var _this = this;
        this.searchCriteriaSub = this.dataStore.get(DataKey.bookingSummarySearchCriteria)
            .subscribe(function (data) {
            if (data !== null) {
                _this.searchCriteria = data;
                _this.searchResults = [];
            }
        });
    };
    BookingSearchResultsComponent.prototype.createSorters = function () {
        var _this = this;
        this.sorterService.sorters = [];
        this.sorters = [];
        var sorterMap = new Map();
        this.tBookingSearchLineConfig.config.placeHolderConfig.forEach(function (config) {
            if (config && config.sort) {
                var sorter = _this.sorterService.createSorter(config);
                var id = config._id;
                sorterMap.set(id, sorter);
                _this.sorters.push(sorter);
            }
        });
    };
    BookingSearchResultsComponent.prototype.onSort = function (event) {
        this.sorterService.sorter = event;
    };
    BookingSearchResultsComponent.prototype.getSearchResults = function () {
        var _this = this;
        this.fetchedReservationsCount = 0;
        this.searchResultsCollectedSub = this.dataStore.get(DataKey.bookingSummarySearchResults)
            .subscribe(function (result) {
            _this.selectionIndex = '';
            _this.searchResults = [];
            if (result != null) {
                _this.spinnerService.hide();
            }
            if (result) {
                _this.searchResults = result;
                _this.fetchedReservationsCount = _this.searchResults.length;
                if (_this.fetchedReservationsCount > 0) {
                }
                _this.getBookingCardList();
            }
            else {
                _this.contentCardDataList = [];
                _this.heading = '';
            }
            _this.userJourneyManager.canProceed.next(true);
        });
    };
    BookingSearchResultsComponent.prototype.getBookingCardList = function () {
        var e_1, _a;
        var bookingSummariesData = this.searchResults;
        // generate object list as required here
        var items = [];
        try {
            for (var bookingSummariesData_1 = tslib_1.__values(bookingSummariesData), bookingSummariesData_1_1 = bookingSummariesData_1.next(); !bookingSummariesData_1_1.done; bookingSummariesData_1_1 = bookingSummariesData_1.next()) {
                var bookingSummary = bookingSummariesData_1_1.value;
                var item = {
                    items: undefined,
                    passengers: undefined
                };
                for (var k in bookingSummary) {
                    if (bookingSummary.hasOwnProperty(k)) {
                        item[k] = bookingSummary[k];
                    }
                }
                item.items = 'No Of Items: ' + bookingSummary.noOfItems;
                item.passengers = 'No of Passengers: ' + bookingSummary.noOfPassengers;
                items.push(item);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (bookingSummariesData_1_1 && !bookingSummariesData_1_1.done && (_a = bookingSummariesData_1.return)) _a.call(bookingSummariesData_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        this.contentCardDataList = this.getSearchContentCards(items);
    };
    BookingSearchResultsComponent.prototype.getSearchContentCards = function (dataArray) {
        var contentCardList = this.summaryCardService.getContentCardsByConfig(this.tBookingSearchLineConfig, dataArray);
        this.spinnerService.hide();
        this.heading = this.resultCount + ' result' + (this.resultCount > 1 ? 's' : '') + ' found.';
        this.paginatorHasMore = this.resultCount !== contentCardList.length;
        console.log(this.heading);
        this.setPageZero();
        return contentCardList;
    };
    BookingSearchResultsComponent.prototype.onSelect = function (event) {
        var _this = this;
        this.detailedBooking = null;
        this.selectedSearchSummary = event;
        var id = event.data.value.id;
        if (id !== null) {
            this.bookingManagementService.getById(id)
                .subscribe(function (res) {
                if (_this.commonHelper.dataValidity(res)) {
                    _this.detailedBooking = ResponseUtil.getFirstData(res);
                    var selectedBooking = {
                        bookingId: _this.detailedBooking.bookingId
                    };
                    var parameters = _this.queryParamService.getQueryParamsArray(selectedBooking);
                    _this.rootService.setDataToLocalStorage(TCO.AppData.BOOKING, selectedBooking, true, parameters);
                }
            });
        }
    };
    BookingSearchResultsComponent.prototype.onEdit = function (event) {
        this.userJourneyManager.goForKey('GO_TO_EDIT_BOOKING');
        event.isEditTriggered = true;
        this.dataStore.set(DataKey.selectedBookingForEdit, event, true);
    };
    BookingSearchResultsComponent.prototype.onCardScroll = function (event) {
        var _this = this;
        setTimeout(function () {
            _this.autoScrollService.scrollToID('clientDetailExp_' + event, 'main-scroll-container');
        }, 0);
    };
    BookingSearchResultsComponent.prototype.onEditAsNew = function (event) {
        this.clearIdsInBooking(event);
        this.userJourneyManager.goForKey('GO_TO_CREATE_BOOKING');
        this.dataStore.set(DataKey.selectedBookingForEditAsNew, event, true);
    };
    /**
     * IMPORTANT : clear all the existing ids that needs to be saved as new entities
     *
     */
    BookingSearchResultsComponent.prototype.clearIdsInBooking = function (booking) {
        var e_2, _a;
        // clear ids in booking
        booking.bookingId = null;
        booking.bookingIdCombined = null;
        booking.id = 0;
        var itemNumber = 1;
        if (booking.bookingItems) {
            try {
                for (var _b = tslib_1.__values(booking.bookingItems), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var bookingItem = _c.value;
                    bookingItem.id = 0;
                    var type = 'trs';
                    if (bookingItem.genBookingItem) {
                        type = 'gen';
                        bookingItem.genBookingItem.id = 0;
                        if (bookingItem.genBookingItem && bookingItem.genBookingItem.hotelRoomType) {
                            type = 'accom';
                        }
                    }
                    else if (bookingItem.trsBookingItem) {
                        type = 'trs';
                        bookingItem.trsBookingItem.id = 0;
                    }
                    this.bookingUtil.setBookingReferenceId(booking, bookingItem, type, itemNumber++);
                    if (bookingItem.bookingPassengers) {
                        bookingItem.bookingPassengers.forEach(function (p) {
                            p.id = 0;
                            p.bookingId = 0;
                        });
                    }
                    if (bookingItem.bookingClient) {
                        bookingItem.bookingClient.id = 0;
                    }
                    if (bookingItem.bookingNotes) {
                        bookingItem.bookingNotes.forEach(function (n) { return n.bookingItemId = 0; });
                    }
                }
            }
            catch (e_2_1) { e_2 = { error: e_2_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_2) throw e_2.error; }
            }
        }
    };
    BookingSearchResultsComponent.prototype.ngOnDestroy = function () {
        if (this.searchCriteriaSub) {
            this.searchCriteriaSub.unsubscribe();
        }
        if (this.searchResultsCollectedSub) {
            this.searchResultsCollectedSub.unsubscribe();
        }
        if (this.searchResultsFromServiceSub) {
            this.searchResultsFromServiceSub.unsubscribe();
        }
        if (this.bookingItemSheetImportSubscribe) {
            this.bookingItemSheetImportSubscribe.unsubscribe();
        }
        if (this.popupCloseModel) {
            this.popupCloseModel.unsubscribe();
        }
        this.em.removeEvent('EXPORT');
    };
    BookingSearchResultsComponent.prototype.importBookingItemSheet = function () {
        var _this = this;
        var e_3, _a;
        this.dataStore.set(DataKey.popupClose, null);
        this.dataStore.set(DataKey.bookingItemFileToUpload, null);
        var defaultType = null;
        if (this.tTemplateTypes) {
            try {
                for (var _b = tslib_1.__values(this.tTemplateTypes), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var tTemplateType = _c.value;
                    if (tTemplateType.default) {
                        defaultType = tTemplateType;
                    }
                }
            }
            catch (e_3_1) { e_3 = { error: e_3_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_3) throw e_3.error; }
            }
        }
        var templateTypes = this.tTemplateTypes;
        var dataObject = new ModalData(LEVEL.SUCCESS, 'Upload Booking Items', true, true, 'modal-basic--alt modal-basic--fixed-size', BookingItemExportComponent, {
            templateTypes: templateTypes,
            templateType: defaultType
        });
        dataObject.disableClose = true;
        this.confirmModal(dataObject);
        this.popupCloseModel = this.dataStore.get(DataKey.popupClose).subscribe(function (data) {
            if (data != null) {
                _this.closeModal();
            }
        });
        this.bookingItemSheetImportSubscribe = this.dataStore.get(DataKey.bookingItemFileToUpload).subscribe(function (data) {
            if (data != null) {
                _this.uploadBookingItemSheet(data);
                _this.bookingItemSheetImportSubscribe.unsubscribe();
            }
        });
    };
    BookingSearchResultsComponent.prototype.confirmModal = function (data) {
        this.modalService.confirm(data).subscribe(function (res) {
        });
    };
    BookingSearchResultsComponent.prototype.closeModal = function () {
        if (this.modalService.close()) {
            this.modalService.close().subscribe(function (res) {
            });
        }
    };
    BookingSearchResultsComponent.prototype.uploadBookingItemSheet = function (data) {
        var _this = this;
        this.dataStore.set(DataKey.popupClose, null);
        if (data && data.type && data.type.code) {
            var serviceDateTimeStamp = '';
            if (data.date) {
                serviceDateTimeStamp = data.date.toISOString();
            }
            var colDefConfig_1 = this.configLoader.configurations.get(TCO.CONF.CONF_BOOKING_IMPORT_SUMMARY_GRID);
            this.reservationV2ManagementService.uploadBookingItemSheet(data.data, data.type.code, serviceDateTimeStamp)
                .subscribe(function (res) {
                var bookingImportSummary = ResponseUtil.getFirstData(res);
                if (bookingImportSummary && bookingImportSummary.importedBookingItems && bookingImportSummary.importedBookingItems.length > 0) {
                    console.log(res);
                    var dataObject = new ModalData(LEVEL.SUCCESS, 'Upload Booking Items', false, false, 'split-passengers-modal', BookingImportSummaryComponent, {
                        importSummary: bookingImportSummary,
                        colDefConfig: colDefConfig_1
                    }, { 'label': 'close' });
                    _this.confirmModal(dataObject);
                    _this.popupCloseModel = _this.dataStore.get(DataKey.popupClose).subscribe(function (data) {
                        if (data != null) {
                            _this.closeModal();
                        }
                    });
                }
                _this.spinnerService.hide();
            }, function (err) {
                console.log(err);
                _this.spinnerService.hide();
                _this.common.showSnackBar('Failed to import bookings.', 3000, TcErrorType.TYPE.ERROR);
            });
        }
    };
    BookingSearchResultsComponent.prototype.setupPageOptions = function () {
        if (this.pageOptionsAvailability) {
            this.showEditBtn = this.pageOptionsAvailability.editable;
            this.showEditAsNew = this.pageOptionsAvailability.edit_as_new;
        }
    };
    return BookingSearchResultsComponent;
}());
export { BookingSearchResultsComponent };
