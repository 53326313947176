import { AbstractSearchCriteria } from '../../criteria/abstract-search-criteria';
import { MainType } from '../main-type';
import {KeyControlAndAbstractSearchCriteria} from '../../criteria/key-control-and-abstract-search-criteria';

export class BookingSearchCriteria extends KeyControlAndAbstractSearchCriteria{
    bookingId = '';
    source = '';
    bId: any;
    startDate = '';
    endDate = '';
    bookingStartDate = '';
    bookingEndDate = '';
    createdDate: any;
    bookingSourceItemNo: number = null;
    mainType: MainType = null;
}
