<tc-setup-grid
        #setupGrid
        [readOnly]="false"
        [colDefConfig]="colDefConfig"
        [setupGridComp]="this"
        [dataSource]="this"
        [noSelection]="true"
        [disableAdd]="isDisabledAdd()"
        [disableDelete]=true
        [disableRowDelete]=true>
</tc-setup-grid>