import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { User } from '@tc-core/model/it/codegen/tbx/api/authority/v2/users/user';
import {UserManagementService} from '../../services/user-management/user-management.service';

@Component({
    selector: 'tc-user-profile',
    templateUrl: 'user-profile.component.html'
})
export class UserProfileComponent implements OnInit {

    @Input() disableLogOut = false;
    @Input() disableUserObjects = false;

    @Input() user: User;
    @Input() authConfigComponent: string = 'tc-user-profile';
    @Input() isUserLoaded = false;
    @Output() logout: EventEmitter<void> = new EventEmitter<void>();
    @Output() viewUserLoadedObjects: EventEmitter<void> = new EventEmitter<void>();

    public userImageUrl = '';
    public FEMALE_DEFAULT_IMAGE = 'assets/img/user-profile/female_user.svg';
    public MALE_DEFAULT_IMAGE = 'assets/img/user-profile/male_user.svg';
    public MALE_OR_FEMALE_DEFAULT_IMAGE = 'assets/img/user-profile/gender_unknown_user.svg';

    constructor(
        public userManagementService: UserManagementService
    ) { }

    ngOnInit() {
        this.setupIMageUrl();
    }

    setupIMageUrl() {
        if (this.user && this.user.userSummary && this.user.userSummary.additionalInfomation &&
            this.user.userSummary.additionalInfomation.imageUrl) {
            this.userImageUrl = this.user.userSummary.additionalInfomation.imageUrl;
        } else {
            this.setDefaultImage();
        }
    }

    setDefaultImage() {
        if (this.user && this.user.userSummary && this.user.userSummary.gender) {
            if (this.user.userSummary.gender === 'F') {
                this.userImageUrl = this.FEMALE_DEFAULT_IMAGE;
            } else if (this.user.userSummary.gender === 'M') {
                this.userImageUrl = this.MALE_DEFAULT_IMAGE;
            }
        } else {
            this.userImageUrl = this.MALE_OR_FEMALE_DEFAULT_IMAGE;
        }
    }

    public onImageError($event: ErrorEvent) {
        this.setDefaultImage();
    }

    public onLogout() {
        if (!this.disableLogOut) {
            this.logout.emit();
        }
    }

    public onUserLoadedObjectsView() {
        if (!this.disableUserObjects) {
            this.viewUserLoadedObjects.emit();
        }
    }
}
