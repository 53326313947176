<div class="detail-group__body-service-item container-fluid">
    <div class="tc-px-3">
        <ag-grid-angular
                style="width: 630px; margin: 0 auto;"
                class="ag-theme-material tc-ag-grid tc-ag-grid--popup-content"
                [rowData]="importSummary.importedBookingItems"
                [columnDefs]="columnDefs"
                [defaultColDef]="defaultColDef"
                [pagination]="true"
                [paginationAutoPageSize]="true"
                [rowClassRules]="rowClassRules"
                (gridReady)="onGridReady($event)">
        </ag-grid-angular>
    </div>
</div>

<div class="container assign-panel-footer-section">
  <button
          class="assign-panel-footer-section__button tc-button-secondary mat-button"
          [disabled]="disableExport()"
          (click)="onExport(importSummary.failedItemsDocumentId)">
    {{'Export Failed Bookings' | translate}}
  </button>

    <button class="assign-panel-footer-section__button tc-button-secondary mat-button" (click)="onClose()">
        {{'OK' | translate}}
    </button>
</div>
