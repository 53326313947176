import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SummaryCardData } from '@tc-core/model/it/codegen/ui/framework/summary-card-data';
import { TC } from '@tc-core/util/constants';
import { ServiceItem } from '../../../models/reservation/service-item';
import { ServiceAssignmentUtilService } from '../../../services/business-utils/service-item-utils/service-assignment-util.service';

@Component({
    selector: 'tc-reservation-expanded-summary-view',
    templateUrl: './reservation-expanded-summary-view.component.html'
})
export class ReservationExpandedSummaryViewComponent implements OnInit {

    EMPTY_CHAR = TC.FORMAT.EMPTY_CHAR;

    @Input() summaryCardData: SummaryCardData = null;
    @Input() contentCardDataList: SummaryCardData [] = [];

    @Output() pickUps: EventEmitter<any> = new EventEmitter<any>();
    @Output() dropOffs: EventEmitter<any> = new EventEmitter<any>();

    transferMode: string = '';
    transferModeObj: any = null;

    totalPassengerCount: number = 0;
    allocatedPassengerCount: number = 0;
    unAllocatedPassengerCount: number = 0;

    constructor() {

    }

    ngOnInit() {
        this.transferMode = this.summaryCardData.data.value['type'];
        this.transferModeObj = this.summaryCardData.data.value['transferModeObj'];

        if (this.transferModeObj && this.transferModeObj['name'] && this.transferModeObj['code']) {
            this.transferMode = this.transferModeObj['name'] + ' (' + this.transferModeObj['code'] + ')';
        }

        if (this.summaryCardData && this.summaryCardData.data && this.summaryCardData.data.value &&
            this.summaryCardData.data.value.passengerCount) {
            this.totalPassengerCount = this.summaryCardData.data.value.passengerCount;
        }
    }

    onPickUpsClick(event) {
        this.pickUps.emit(event);
    }

    onDropOffsClick(event) {
        this.dropOffs.emit(event);
    }

    getAllocatedPassengersCount(): number {
        this.allocatedPassengerCount = 0;
        this.contentCardDataList.forEach(contentCardData => {
            let serviceItem: ServiceItem = contentCardData.data.value['rawData'];
            if (serviceItem && serviceItem.serviceAssignments) {
                if (ServiceAssignmentUtilService.isAssignedAny(serviceItem.serviceAssignments)) {
                    if (serviceItem.servicePassengers) {
                        this.allocatedPassengerCount += serviceItem.servicePassengers.length;
                    }
                }
            }
        });
        return this.allocatedPassengerCount;
    }

    getUnAllocatedPassengersCount(): number {
        this.unAllocatedPassengerCount = this.totalPassengerCount - this.allocatedPassengerCount;
        return this.unAllocatedPassengerCount;
    }

}
