<tc-adv-transfer-supplier-search-results
        *ngIf="supplierResultList && supplierResultList.length>0"
        [clientResultsList]="contentCardDataList"
        [detailSupplier]="detailSupplier"
        (supplierSelect)="onSupplierSelect($event)"
        [footerDataMap]="footerDataMap"
        [showEditBtn]="showEditBtn"
        [showEditAsNew]="showEditAsNew"
        [pager]="pager"
        [resourceTypes]="resourceTypes"
        [currencyFormat]="currencyFormat"
        [trsModes]="transferModes"
        [profileTypes]="profileTypes"
        [costingTypes]="costingTypeList"
        [vehicleModels]="vehicleModels"
        (selection)="onSelection($event)"
        (edit)="onSupplierEdit($event)"
        (editAsNew)="onEditAsNewSupplier($event)"
        (cardScroll)="onCardScroll($event)"
        [heading]="heading">
</tc-adv-transfer-supplier-search-results>

<tc-paginator
        *ngIf="contentCardDataList.length > defaultPageResultCount"
        [length]="contentCardDataList.length"
        [index]="pager.currPage"
        [size]="pager.itemsPerPage"

        [hasMore]="paginatorHasMore"
        (page)="onPage($event)"
        (more)="onMore()">
</tc-paginator>
