import { Component, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { MatCheckboxChange } from '@angular/material';
import { ConfigLoader } from '@tc-core/util/framework/config-loader.service';
import { TCO } from '../../../constants';
import { BookingItem } from '../../../models/reservation-v2/booking-item';
import { BookingPassenger } from '../../../models/reservation-v2/booking-passenger';
import { BookingPaxMapping } from '../../../models/reservation-v2/booking-pax-mapping';
import { PassengerType } from '../../../models/reservation-v2/passenger-type';
import { ServiceItem } from '../../../models/reservation-v2/service-item';
import { OPResponseWrapper } from '../../../models/response/op-response-wrapper';
import { ReservationV2ManagementService } from '../../../services/backend-consumers/reservation-v2/reservation-v2-management.service';
import { DataKey, DataStoreService } from '../../../services/util/framework/data-store.service';
import { BookingPassengerGroupComponent } from './booking-passenger-group/booking-passenger-group.component';

@Component({
    selector: 'tc-passenger-editor',
    templateUrl: './passenger-editor.component.html'
})
export class PassengerEditorComponent implements OnInit
{
    constructor(
        private dataStore: DataStoreService,
        private reservationV2ManagementService: ReservationV2ManagementService,
        private configLoader: ConfigLoader
    )
    {}

    @Input() selectedItem: BookingItem | ServiceItem;
    @Input() bookingPassengerGroup: BookingPassengerGroup[];
    @Input() isBookingQueue: boolean;

    @ViewChildren('bookingPassengerGroupComponent') passengerGroupComponentList: QueryList<BookingPassengerGroupComponent>;

    splitButtonDisabledMsg = '';
    pageOptionsAvailability: any;
    bookingReferenceIdWithoutReturnFlag: any;

    selectedPassengers: BookingPaxMapping[] = [];
    readOnly = false;
    splitButtonEnable: boolean;

    ngOnInit()
    {
        this.enableSplitButton();
        this.pageOptionsAvailability = this.configLoader.configurations.get(TCO.CONF.CONF_PAGE_OPTION_AVAILABILITY).PASSENGERS;
        this.bookingReferenceIdWithoutReturnFlag = this.configLoader.configurations
                                                       .get(TCO.CONF.CONF_PAGE_OPTION_AVAILABILITY).BOOKING_MANAGEMENT;

        if (this.bookingReferenceIdWithoutReturnFlag) {
            if (this.bookingReferenceIdWithoutReturnFlag.bookingItemReferenceWithReturnFlag !== true) {
                this.bookingPassengerGroup.forEach(group => {
                    group.bookingReferenceIdWithoutReturnFlag = group.bookingReferenceId;
                });
            }
        }
    }

    public onSplit()
    {
        const paxMappingGroupList: any[] = [];

        // add selected items to paxMappingGroupList
        paxMappingGroupList.push(this.selectedPassengers);

        // add deselected items to paxMappingGroupList
        paxMappingGroupList.push(this.getDeselectedPaxList());

        // API call for split booking/service item
        if (this.isBookingQueue) {
            this.reservationV2ManagementService.splitBookingItem(paxMappingGroupList)
                .subscribe((data: OPResponseWrapper<BookingItem>) => {
                    if (data) {
                        this.dataStore.set(DataKey.popupClose, data.data);
                    }
                });
        } else {
            this.reservationV2ManagementService.splitServiceItem(paxMappingGroupList)
                .subscribe((data: OPResponseWrapper<ServiceItem>) => {
                    if (data) {
                        this.dataStore.set(DataKey.popupClose, data.data);
                    }
                });
        }
    }

    public onClose($event: MouseEvent)
    {
        this.dataStore.set(DataKey.popupClose, []);
    }

    public onClickGroupCheckBox($event: MouseEvent)
    {
        $event.stopPropagation();
    }

    public onGroupSelected($event: MatCheckboxChange, passengerGroup: BookingPassengerGroup)
    {
        if (!this.readOnly) {
            this.passengerGroupComponentList.filter(component => component.group.id === passengerGroup.id)
                .forEach(component => {
                    if ($event.checked) {
                        component.gridApi.selectAll();
                    } else {
                        component.gridApi.deselectAll();
                    }
                });
        }
    }

    public onRowSelected(params)
    {
        const bookingPaxMapping = new BookingPaxMapping();
        bookingPaxMapping.bookingId = params.data.bookingId;
        bookingPaxMapping.passengerId = params.data.id;
        bookingPaxMapping.type = params.data.type;
        if (params.node.selected) {
            this.selectedPassengers.push(bookingPaxMapping);
        } else {
            this.selectedPassengers.forEach((item, index) => {
                if (bookingPaxMapping.bookingId === item.bookingId && bookingPaxMapping.passengerId === item.passengerId) {
                    this.selectedPassengers.splice(index, 1);
                }
            });
        }
        this.enableSplitButton();
    }

    /**
     * enable/disable split button
     */
    private enableSplitButton()
    {
        if (!this.readOnly) {
            if (this.selectedItem && !this.selectedItem.primaryAssignment && !this.selectedItem.secondaryAssignment) {
                this.splitButtonEnable = true;
                this.splitButtonDisabledMsg = '';
            } else {
                this.splitButtonEnable = false;
                this.splitButtonDisabledMsg = 'Assignments available!';
                return;
            }

            if (this.selectedPassengers && this.selectedPassengers.length > 0) {
                this.splitButtonEnable = true;
                this.splitButtonDisabledMsg = '';
            } else {
                this.splitButtonEnable = false;
                this.splitButtonDisabledMsg = '';
                return;
            }

            if (this.selectedPassengers.filter(o => o.type === PassengerType.A).length >= 1 &&
                this.getDeselectedPaxList().filter(o => o.type === PassengerType.A).length >= 1) {
                this.splitButtonEnable = true;
                this.splitButtonDisabledMsg = '';
            } else {
                this.splitButtonEnable = false;
                this.splitButtonDisabledMsg = 'At least 1 Adult required in a group!';
                return;
            }
        }
    }

    /**
     * filter deselected passenger items
     */
    private getDeselectedPaxList(): BookingPaxMapping[]
    {
        const deselectedItems: BookingPaxMapping[] = [];
        this.bookingPassengerGroup.forEach(passengerGroup => {
            passengerGroup.bookingPassengers.forEach(passenger => {
                if (this.selectedPassengers.filter(o => o.bookingId === passengerGroup.id && o.passengerId === passenger.id).length === 0) {
                    const bookingPaxMapping = new BookingPaxMapping();
                    bookingPaxMapping.bookingId = passengerGroup.id;
                    bookingPaxMapping.passengerId = passenger.id;
                    bookingPaxMapping.type = passenger.type;
                    deselectedItems.push(bookingPaxMapping);
                }
            });
        });
        return deselectedItems;
    }
}

export class BookingPassengerGroup
{
    id: any;
    bookingReferenceId: string;
    bookingReferenceIdWithoutReturnFlag: string;
    bookingPassengers: BookingPassenger[] = [];
}
