<tc-result-header
        [heading]="heading"
        [sorters]="sorters"
        (selectSorter)="onSort($event)">
</tc-result-header>

<tc-adv-booking-search-results
        *ngIf="searchResults && searchResults.length>0"
        [sorters]="sorters"
        [sortingHandler]="sorterService.method"
        [sorter]="sorterService.sorter"
        [pager]="pager"
        [summaryList]="contentCardDataList"
        [selectedSummary]="selectedSearchSummary"
        [detailedObject]="detailedBooking"
        [showEditBtn]="showEditBtn"
        [showEditAsNew]="showEditAsNew"
        (select)="onSelect($event)"
        (edit)="onEdit($event)"
        (editAsNew)="onEditAsNew($event)"
        (cardScroll)="onCardScroll($event)">
</tc-adv-booking-search-results>

<tc-paginator
        *ngIf="contentCardDataList.length > defaultPageResultCount"
        [length]="contentCardDataList.length"
        [index]="pager.currPage"
        [size]="pager.itemsPerPage"
        [hasMore]="paginatorHasMore"
        (page)="onPage($event)"
        (more)="onMore()">
</tc-paginator>
