<div class="result-view-container" *ngIf="resultsList" id="supplier-assign-scroll-container">
    <ng-container
            *ngFor="let supplier of resultsList |tcAdvPager:pager.currPage:pager.itemsPerPage | tcAdvSorter:sortingHandler:sorter; let i = index;">

        <div class="result-item animate"
             [ngClass]="hasSelectedThis(supplier)?'tc-expansion-panel--expanded':''"
             tcExpansionPanel
             tabindex="-1"
             [enabled]="true"
             (clickedOutside)="onOutsideClick(selectedSupplier)"
             (collapse)="expandedPanelIndex = -1">

            <!-- Content card Strip -->
            <div *tcExpansionPanelSummary id="clientSumExp_{{i}}">

                <tc-content-strip
                        (actionClick)="onActionClick(supplier,$event)"
                        (click)="onSupplierSelection(supplier,i)">

                    <tc-result-content-card-resource-assign
                            [summaryContent]="supplier">
                    </tc-result-content-card-resource-assign>

                </tc-content-strip>
            </div>

            <div *tcExpansionPanelDetail id="clientDetailExp_{{i}}">
                <tc-expanded-content-card class="tc-expanded-content-card tc-form-view" [isEdit]="false">

                    <tc-resource-expanded-content-header
                            *ngIf="selectedSupplier"
                            [icon]="selectedSupplier.icon"
                            [headerText]="getHeader(selectedSupplier)"
                            [secondaryHeaderText]="getSecondaryHeaderText(selectedSupplier)"
                            (headerClick)="onHeaderClick(selectedSupplier)">
                    </tc-resource-expanded-content-header>

                    <tc-supplier-allocation-detail-view
                            *ngIf="selectedSupplier"
                            [summaryCardData]="selectedSupplier"
                            [itemList]="resultsList"
                            [paxCount]="paxCount"
                            [assignedSupplier]="assignedSupplier"
                            (headerClick)="onHeaderClick(selectedSupplier)"
                            (clickOutSide)="onOutsideClick(selectedSupplier)">
                    </tc-supplier-allocation-detail-view>

                </tc-expanded-content-card>
            </div>
        </div>
        <hr>
    </ng-container>
</div>

