import * as tslib_1 from "tslib";
import { ResourceCostSetup } from './resource-cost-setup';
var TrsResourceCostSetup = /** @class */ (function (_super) {
    tslib_1.__extends(TrsResourceCostSetup, _super);
    function TrsResourceCostSetup() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return TrsResourceCostSetup;
}(ResourceCostSetup));
export { TrsResourceCostSetup };
var TrsResourceCost = /** @class */ (function () {
    function TrsResourceCost() {
    }
    return TrsResourceCost;
}());
export { TrsResourceCost };
export var CostUnitOptions;
(function (CostUnitOptions) {
    var TYPE;
    (function (TYPE) {
        TYPE[TYPE["Per Person"] = 0] = "Per Person";
        TYPE[TYPE["Per Unit"] = 1] = "Per Unit";
    })(TYPE = CostUnitOptions.TYPE || (CostUnitOptions.TYPE = {}));
})(CostUnitOptions || (CostUnitOptions = {}));
