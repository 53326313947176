import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResultContentCardModule } from '../result-content-card/result-content-card.module';
import { ResultContentCardSuppliersComponent } from './result-content-card-suppliers.component';
import {ResourceModule} from '@tc/resource';

@NgModule({
  imports: [
    CommonModule,
    ResourceModule,
    ResultContentCardModule
  ],
  exports: [
    ResultContentCardSuppliersComponent
],
  declarations: [ResultContentCardSuppliersComponent]
})
export class ResultContentCardSuppliersModule { }
