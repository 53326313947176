import { Component, OnDestroy, OnInit } from '@angular/core';
import { AnimationService } from '@tc-core/util/ui';
import { animation_scaff } from '../scaff-animation';
import { UserJourneyManager } from '@tc-core/util/framework/user-journey-manager.service';

@Component({
  selector:    's05',
  templateUrl: './s05.component.html',
  animations:  [
    animation_scaff(
      'scaffAnimation',
      'false',
      'true',
      '300ms ease',
      50
    )
  ]
})

export class S05Component implements OnInit, OnDestroy {

  toggleAnimation = false;
  headerClass: string;
  uId: string;

  constructor(public animationService: AnimationService, private userJourneyManager: UserJourneyManager) { }

  ngOnInit() {
    this.animationService.registerAnimationEvent('S05_ANIMATIONS').subscribe((data) => {
      this.toggleAnimation = !this.toggleAnimation;
    });
    this.userJourneyManager.pageId.subscribe(
      page => {
        if (page) {
          this.headerClass = page;
        }
      }
    );
    this.userJourneyManager.uId.subscribe(id => {
      if (id) {
        this.uId = id;
      }
    });
  }

  public ngOnDestroy(): void {
    this.animationService.removeAnimationEvent('S05_ANIMATIONS');
  }

  closeAfterAnimation(event) {
    this.toggleAnimation = false;
  }
}
