/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i2 from "@angular/material/button";
import * as i3 from "@angular/cdk/platform";
import * as i4 from "@angular/cdk/a11y";
import * as i5 from "@angular/platform-browser/animations";
import * as i6 from "@angular/material/tooltip";
import * as i7 from "@angular/cdk/overlay";
import * as i8 from "@angular/cdk/scrolling";
import * as i9 from "@angular/cdk/bidi";
import * as i10 from "@angular/platform-browser";
import * as i11 from "@ngx-translate/core";
import * as i12 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i13 from "@angular/material/icon";
import * as i14 from "./action-column.component";
var styles_ActionColumnComponent = [".btn[_ngcontent-%COMP%] {\n            line-height: 0.5\n        }"];
var RenderType_ActionColumnComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_ActionColumnComponent, data: {} });
export { RenderType_ActionColumnComponent as RenderType_ActionColumnComponent };
export function View_ActionColumnComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "span", [["class", "tc-ag-grid__icons-container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 16777216, null, null, 6, "button", [["class", "tc-m-0 tc-icon-block__icon tc-icon-block__icon--active"], ["mat-icon-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"], [null, "longpress"], [null, "keydown"], [null, "touchend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("longpress" === en)) {
        var pd_0 = (i0.ɵnov(_v, 3).show() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i0.ɵnov(_v, 3)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (i0.ɵnov(_v, 3)._handleTouchend() !== false);
        ad = (pd_2 && ad);
    } if (("click" === en)) {
        var pd_3 = (_co.onClick() !== false);
        ad = (pd_3 && ad);
    } return ad; }, i1.View_MatButton_0, i1.RenderType_MatButton)), i0.ɵdid(2, 180224, null, 0, i2.MatButton, [i0.ElementRef, i3.Platform, i4.FocusMonitor, [2, i5.ANIMATION_MODULE_TYPE]], null, null), i0.ɵdid(3, 147456, null, 0, i6.MatTooltip, [i7.Overlay, i0.ElementRef, i8.ScrollDispatcher, i0.ViewContainerRef, i0.NgZone, i3.Platform, i4.AriaDescriber, i4.FocusMonitor, i6.MAT_TOOLTIP_SCROLL_STRATEGY, [2, i9.Directionality], [2, i6.MAT_TOOLTIP_DEFAULT_OPTIONS], [2, i10.HAMMER_LOADER]], { message: [0, "message"] }, null), i0.ɵpid(131072, i11.TranslatePipe, [i11.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(5, 0, null, 0, 2, "mat-icon", [["aria-hidden", "false"], ["aria-label", ""], ["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i12.View_MatIcon_0, i12.RenderType_MatIcon)), i0.ɵdid(6, 9158656, null, 0, i13.MatIcon, [i0.ElementRef, i13.MatIconRegistry, [8, "false"], [2, i13.MAT_ICON_LOCATION]], null, null), (_l()(), i0.ɵted(7, 0, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = i0.ɵinlineInterpolate(1, "", (_co.params.title ? _co.params.title : i0.ɵunv(_v, 3, 0, i0.ɵnov(_v, 4).transform("Action"))), ""); _ck(_v, 3, 0, currVal_2); _ck(_v, 6, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (i0.ɵnov(_v, 2).disabled || null); var currVal_1 = (i0.ɵnov(_v, 2)._animationMode === "NoopAnimations"); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_3 = i0.ɵnov(_v, 6).inline; var currVal_4 = (((i0.ɵnov(_v, 6).color !== "primary") && (i0.ɵnov(_v, 6).color !== "accent")) && (i0.ɵnov(_v, 6).color !== "warn")); _ck(_v, 5, 0, currVal_3, currVal_4); var currVal_5 = _co.params.icon; _ck(_v, 7, 0, currVal_5); }); }
export function View_ActionColumnComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "child-cell", [], null, null, null, View_ActionColumnComponent_0, RenderType_ActionColumnComponent)), i0.ɵdid(1, 49152, null, 0, i14.ActionColumnComponent, [], null, null)], null, null); }
var ActionColumnComponentNgFactory = i0.ɵccf("child-cell", i14.ActionColumnComponent, View_ActionColumnComponent_Host_0, {}, {}, []);
export { ActionColumnComponentNgFactory as ActionColumnComponentNgFactory };
