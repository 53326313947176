import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Pager } from '@tc-core/model/it/codegen/ui/framework/pager';
import { Sorter } from '@tc-core/model/it/codegen/ui/framework/sorter';
import { SummaryCardData } from '@tc-core/model/it/codegen/ui/framework/summary-card-data';
import { ChangeDetectorService } from '../../../services/util/change-detector/change-detector.service';
import {AssignmentSummary} from '../../../models/assignment-summary';
import {ResourceAvailability} from '../../../models/resource/resource-availability';
import {SearchResultSummary} from '../../../models/reservation/search-result-summary';
import {Resource} from '../../../models/resource/resource';

@Component({
  selector: 'tc-adv-driver-assign-search-result',
  templateUrl: './adv-driver-assign-search-result.component.html'
})
export class AdvDriverAssignSearchResultComponent implements OnInit {

    @Input() sorter: Sorter;
    @Input() sortingHandler: any;
    @Input() sorters: Sorter[] = [];
    @Input() pager: Pager;

    @Input() resultsList: Array<SummaryCardData>;
    @Input() selectedDriver: any = null; // selected vehicle card from search results
    @Input() bookingData: any;
    @Input() bookingDataArray: any[];
    @Input() isBulkAssignment: boolean = false;
    @Input() assignedDriver: any; //assigned driver from direct assignment or early assignments
    @Input() itemGroup: SearchResultSummary;

    @Output() driverSelect: EventEmitter<any> = new EventEmitter();
    @Output() cardScroll: EventEmitter<number> = new EventEmitter<number>();
    @Output() actionClick: EventEmitter<any> = new EventEmitter();
    @Output() outsideSelect: EventEmitter<any> = new EventEmitter();
    @Output() headerClick: EventEmitter<any> = new EventEmitter();
    @Output() resourceCalendarClick: EventEmitter<any> = new EventEmitter();
    @Output() eventClick: EventEmitter<any> = new EventEmitter();
    @Output() targetClick: EventEmitter<any> = new EventEmitter();

    selectedCard: SummaryCardData;

    constructor(
        private changeDetector: ChangeDetectorService
    ) {
    }

    ngOnInit() {
    }

    public onDriverSelection(summaryCardData: any, index?: number) {
        this.selectedDriver = summaryCardData;

        if (summaryCardData) {
            this.driverSelect.emit(this.selectedDriver);
            this.cardScroll.emit(index);
        }
    }

    onActionClick(summaryCardData, event) {
        this.selectedDriver = summaryCardData;

        if (summaryCardData) {
            this.actionClick.emit(event);
            this.driverSelect.emit(this.selectedDriver);
        }
    }

    public onExpand(summaryCardData: SummaryCardData) {
        this.driverSelect.emit(summaryCardData);
    }

    public onHeaderClick(summaryCardData: SummaryCardData) {
        this.headerClick.emit(summaryCardData);
        this.selectedCard = summaryCardData;
        this.driverSelect.emit(summaryCardData);
        this.changeDetector.notifySupplierCostChange();

    }

    public onOutsideClick($event) {
        this.changeDetector.notifySupplierCostChange();
        this.outsideSelect.emit($event);
        this.driverSelect.emit(this.selectedCard);
    }

    public hasSelectedThis(driver: ResourceAvailability): boolean {
        if (this.assignedDriver) {
            return (this.assignedDriver.resourceId === driver.resourceId);
        } else {
            return false;
        }
    }

    onResourceCalendarClick(resource: any) {
        this.resourceCalendarClick.emit(resource);
    }
    onEventClick(event: AssignmentSummary, resource: ResourceAvailability){
        event.tobeSelected = true;
        this.eventClick.emit(resource);
    }

    onTargetClick(target: any){
        this.targetClick.emit(target);
    }
}
