import { Component, Injector, OnInit } from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {locationGroupSetup} from '../../../../models/master-data/location-group-setup';
import {ModalData} from '@tc-core/model/it/codegen/ui/framework/modal-data';
import {LEVEL} from '@tc-core/model/it/codegen/ui/framework/dialog-model';
import {ModalService} from '@tc/modal/modal.service';
import { RoutesSetupService } from '../../../../services/backend-consumers/deprecated/routes-setup.service';
import { DataKey, DataStoreService } from '../../../../services/util/framework/data-store.service';
import {EditLocationGroupComponent} from './edit-location-group/edit-location-group.component';
import {Subscription} from 'rxjs';
import {AddLocationGroupComponent} from './add-location-group/add-location-group.component';
import { SummaryCardData } from '@tc-core/model/it/codegen/ui/framework/summary-card-data';
import { ConfigLoader } from '@tc-core/util/framework/config-loader.service';
import { TCO } from '../../../../constants';
import { AutoScrollService } from '@tc-core/util/ui/auto-scroll.service';
import { Pager } from '@tc-core/model/it/codegen/ui/framework/pager';

@Component({
    selector: 'tc-location-groups',
    templateUrl: './location-groups.component.html'
})
export class LocationGroupsComponent implements OnInit {
    locationGroups: locationGroupSetup[];
    private locationSubscription: Subscription = new Subscription();
    selectedLocationGroup: locationGroupSetup;
    newLocationGroup: locationGroupSetup;
    private modalCloseSubscription: Subscription = new Subscription();

    locationGroupsResultsList:  Array<SummaryCardData> = [];
    locationGroupsConfig;
    selectedLocationGroupCard: SummaryCardData = null;
    public pager: Pager;
    public defaultPageResultCount = 10;
    selectionIndex = '';
    tPagination: any;
    cardHeading = '';

    constructor(
        private  configLoader: ConfigLoader,
        private fb: FormBuilder,
        private routeService: RoutesSetupService,
        private modalService: ModalService,
        private dataStore: DataStoreService,
        private autoScrollService: AutoScrollService,
        private injector: Injector
    ) {
    }

    ngOnInit() {

        this.locationGroupsConfig = this.configLoader.configurations.get(TCO.CONF.LOCATION_GROUP_RESULT_CARD_CONFIG);
        this.dataStore.set(DataKey.locations, null, true);
        this.dataStore.set(DataKey.locationGroup, null, true);

        this.pager = new Pager(0, this.defaultPageResultCount);
        this.tPagination = this.configLoader.configurations.get(TCO.CONF.CONF_PAGINATION_CONFIG)['LOCATION_GROUPS'];

        this.setupPaginator();
        // -------------------------------------------------------

        this.routeService.retrieveLocationGroups().subscribe(data => {
            this.locationGroups = data;
            this.getContentCardData(this.locationGroups);
        });
        this.getAddedLocations();
    }

    getAddedLocations() {
        this.locationSubscription = this.dataStore.get(DataKey.locations).subscribe(data => {
            if (data != null && this.selectedLocationGroup) {
                // let newLocations = data;
                this.selectedLocationGroup.locations = data;
                console.log(this.selectedLocationGroup);
            }
        });
        this.locationSubscription = this.dataStore.get(DataKey.locationGroup).subscribe(data => {
            if (data != null) {
                // let newLocations = data;
                this.newLocationGroup = data;
                this.locationGroups.push(data);
                console.log(data);
                this.locationGroupsResultsList = [];
                this.getContentCardData(this.locationGroups);
            }
        });
    }

    editLocationGroup(group: locationGroupSetup) {
        this.dataStore.set(DataKey.locations, null, true);
        this.selectedLocationGroup = group;
        let dataObject = new ModalData(LEVEL.SUCCESS, 'Edit Location Groups',
            {'label': 'OK'},
            {'label': 'Close'},
            '',
            EditLocationGroupComponent,
            {
                'locationGroup': group
            }
        );

        this.confirmModal(dataObject);
    }

    private confirmModal(data: any): void {
        this.modalService.confirm(data).subscribe((res) => {
            console.log(res);
        });
    }

    addLocationGroup(){
        this.dataStore.set(DataKey.modalClose, null);
        this.dataStore.set(DataKey.locationGroup, null, true);
        // this.selectedLocationGroup = group;
        let dataObject = new ModalData(LEVEL.SUCCESS, 'Add Location Group',
            null,
            null,
            'payment-modal',
            AddLocationGroupComponent,
            {
            }
        );
        this.modalCloseSubscription = this.dataStore.get(DataKey.modalClose).subscribe((data) => {
            if (data != null) {
                this.modalCloseSubscription.unsubscribe();
                this.closeModal();
            }
        });

        this.confirmModal(dataObject);
    }
    closeModal() {
        if (this.modalService.close()) {
            this.modalService.close().subscribe(
                (res) => {
                }
            );
        }
    }

    public onLocationGroupSelection(summaryCardData: SummaryCardData, index?: any) {
        this.selectionIndex = index;
        this.selectedLocationGroupCard = summaryCardData;

        // if (summaryCardData && summaryCardData.row2col4 && summaryCardData.row2col4.value !== 'AGENT') {
        //     this.autoScrollService.scrollToID(
        //         'resultDetailExp_' + index, 'main-scroll-container');
        // }
    }

    getSubHeaderText(code: string) {
        return 'Code - ' + code;
    }

    onHeaderClick() {
        this.selectedLocationGroupCard = null;
    }

    onEditClick(event) {

    }

    // paginator
    private setupPaginator() {
        this.defaultPageResultCount = this.tPagination['defaultPageResultCount'];
        this.pager = new Pager(0, this.defaultPageResultCount);
    }

    onPage(pageNumber: number) {
        this.pager.currPage = pageNumber;
        this.selectionIndex = (this.pager.currPage * this.pager.itemsPerPage).toString();
    }

    private getContentCardData(locationGroupList: locationGroupSetup[]) {
        const config = this.locationGroupsConfig.config;
        if (this.locationGroupsConfig.actionHandler && this.locationGroupsConfig.actionHandler !== '') {
            const t = this.locationGroupsConfig.actionHandler.split('#');
            const srv = t[0];
            const fnc = t[1];
            const handler = this.injector.get(srv);
            this.locationGroupsResultsList = handler[fnc](locationGroupList, config);
            const noOfResults = this.locationGroupsResultsList.length;
            this.cardHeading = noOfResults + ' result' + (noOfResults > 1 ? 's' : '') + ' found.';
        }
    }

}
