/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../timeline-unit/timeline-unit.component.ngfactory";
import * as i2 from "@angular/common";
import * as i3 from "../timeline-unit/timeline-unit.component";
import * as i4 from "./timeline-unit-wrapper.component";
var styles_TimelineUnitWrapperComponent = [];
var RenderType_TimelineUnitWrapperComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TimelineUnitWrapperComponent, data: {} });
export { RenderType_TimelineUnitWrapperComponent as RenderType_TimelineUnitWrapperComponent };
function View_TimelineUnitWrapperComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "tc-timeline-unit", [["class", "tc-strip-timeline__point"]], null, [[null, "eventClick"], [null, "targetClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("eventClick" === en)) {
        var pd_0 = (_co.onEventClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("targetClick" === en)) {
        var pd_1 = (_co.onTargetClick() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_TimelineUnitComponent_0, i1.RenderType_TimelineUnitComponent)), i0.ɵdid(1, 278528, null, 0, i2.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(2, { "tc-strip-timeline__point--mark": 0 }), i0.ɵdid(3, 49152, null, 0, i3.TimelineUnitComponent, [], { events: [0, "events"], isTargetBlock: [1, "isTargetBlock"], targetUnitIndex: [2, "targetUnitIndex"], targetUnitCount: [3, "targetUnitCount"], targetMargin: [4, "targetMargin"], unitIndex: [5, "unitIndex"], unitSize: [6, "unitSize"], unitWidth: [7, "unitWidth"], unitTime: [8, "unitTime"], isTarget: [9, "isTarget"] }, { eventClick: "eventClick", targetClick: "targetClick" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "tc-strip-timeline__point"; var currVal_1 = _ck(_v, 2, 0, ((_v.context.$implicit % 2) === 0)); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _co.getUnitRelevantEvents(_v.context.$implicit); var currVal_3 = _co.isTargetBlock; var currVal_4 = _co.targetIndex; var currVal_5 = _co.targetUnitCount; var currVal_6 = _co.targetMargin; var currVal_7 = _v.context.$implicit; var currVal_8 = _co.unitSize; var currVal_9 = _co.unitWidth; var currVal_10 = _co.getUnitTime(_v.context.$implicit); var currVal_11 = ((_v.context.$implicit === _co.targetIndex) && !_co.summaryContent.selected); _ck(_v, 3, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11); }, null); }
export function View_TimelineUnitWrapperComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_TimelineUnitWrapperComponent_1)), i0.ɵdid(1, 278528, null, 0, i2.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showingIndexes; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_TimelineUnitWrapperComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-timeline-unit-wrapper", [], null, null, null, View_TimelineUnitWrapperComponent_0, RenderType_TimelineUnitWrapperComponent)), i0.ɵdid(1, 114688, null, 0, i4.TimelineUnitWrapperComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TimelineUnitWrapperComponentNgFactory = i0.ɵccf("tc-timeline-unit-wrapper", i4.TimelineUnitWrapperComponent, View_TimelineUnitWrapperComponent_Host_0, { summaryContent: "summaryContent", unitSize: "unitSize", unitWidth: "unitWidth", targetIndex: "targetIndex", targetUnitCount: "targetUnitCount", targetMargin: "targetMargin", isTargetBlock: "isTargetBlock", leftBufferUnits: "leftBufferUnits", rightBufferUnits: "rightBufferUnits", showingIndexes: "showingIndexes", events: "events" }, { eventClick: "eventClick", targetClick: "targetClick" }, []);
export { TimelineUnitWrapperComponentNgFactory as TimelineUnitWrapperComponentNgFactory };
