<tc-chip-input
    #mapped_rtypes_search_chip_input
    (cancelEmitter)="onCancel($event)"
    (chipListEmitter)="onChipListDisplay($event)"
    (onfocus)="onFocus($event)"
    (searchEmitter)="onMappedResourceTypeSearch($event)"
    [chisForDisplay]="displayChipsArr"
    [inputAuthConfigComponent]="null"
    [isChipListOptionAvail]="true"
    [isDataLoaded]="isDataLoaded"
    [isFocus]="isFocus"
    [isSearchBarStyleNotAvailable]="isSearchBarStyleNotAvailable"
    [isTriggerSearch]="isTriggerSearch"
    [placeHolderText]="placeHolderText">
</tc-chip-input>
<div class="clearfix"></div>
