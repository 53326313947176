import { Component, OnDestroy, OnInit } from '@angular/core';
import { LEVEL, ModalData } from '@tc-core/model/it/codegen/ui/framework/modal-data';
import { Pager } from '@tc-core/model/it/codegen/ui/framework/pager';
import { Sorter } from '@tc-core/model/it/codegen/ui/framework/sorter';
import { SummaryCardData } from '@tc-core/model/it/codegen/ui/framework/summary-card-data';
import { SorterService } from '@tc-core/service/sorters/sorter.service';
import { ConfigLoader } from '@tc-core/util/framework/config-loader.service';
import { UserJourneyManager } from '@tc-core/util/framework/user-journey-manager.service';
import { CommonHelper } from '@tc-core/util/helpers/common-helper.service';
import { AutoScrollService } from '@tc-core/util/ui/auto-scroll.service';
import { SpinnerService } from '@tc-core/util/ui/spinner.service';
import { FocusViewService } from '@tc/focus-view/focus-view.service';
import { Subscription } from 'rxjs';
import { TCO } from '../../constants';
import { ResourceTypeSearchCriteria } from '../../models/criteria/resource-type-search-criteria';
import { ResourceCategory, SupplierSearchCriteria } from '../../models/criteria/supplier-search-criteria';
import { VehicleSearchCriteria } from '../../models/criteria/vehicle-search-criteria';
import { ResourceType } from '../../models/reservation/assignment';
import { Resource } from '../../models/resource/resource';
import { OPResponseWrapper } from '../../models/response/op-response-wrapper';
import { VehicleSummary } from '../../models/summary/vehicle-summary';
import { Season } from '../../models/supplier/season';
import { Supplier } from '../../models/supplier/supplier';
import { TrsResourceCost } from '../../models/supplier/trs-resource-cost-setup';
import { DataHandlerService } from '../../services/backend-consumers/master-data-handler-service/data-handler.service';
import { ResourceTypeService } from '../../services/backend-consumers/setups/resource-type.service';
import { SupplierService } from '../../services/backend-consumers/supplier-service/supplier.service';
import { TransportResourceService } from '../../services/backend-consumers/supplier-service/transport-resource.service';
import { DataKey, DataStoreService } from '../../services/util/framework/data-store.service';
import { ResponseUtil } from '../../services/util/response/response-util.service';
import { ContentSummaryCardService } from '../../services/util/ui/content-summary-card.service';
import { ResAvailabilityCalendarFocusViewComponent } from '../../widgets/shared/res-availability-calendar-focus-view/res-availability-calendar-focus-view.component';
import { DMCQueryParamsService } from '../../services/util/framework/dmc-query-params.service';
import { RootService } from '../../services/util/core-services/root.service';

@Component({
    selector: 'tc-vehicle-search-results',
    templateUrl: './vehicle-search-results.component.html'
})
export class VehicleSearchResultsComponent implements OnInit, OnDestroy {

    tVehicle: any;

    heading: string = '';

    public pager: Pager;
    public defaultPageResultCount = 10;
    selectionIndex = '';
    tPagination: any;
    fetchedReservationsCount: number = 0;
    dataFromMoreClick = false;
    paginatorHasMore = true;

    resultCount: number = 0;

    searchCriteria = new VehicleSearchCriteria();

    sorters_: Sorter[];

    searchResults: VehicleSummary[];
    vehiclesDataArray: any = [];
    contentCardDataList: SummaryCardData[] = [];
    selectedVehicleSummary: SummaryCardData = null;
    detailedResource: Resource;
    resourceCost: Season[] = [];
    firstTimeSupplierResults: Boolean = false;

    resourceTypes: any[] = [];
    trsModes: any[] = [];
    navConfig: any[] = [];

    private focusViewCloseSubject: Subscription = new Subscription();
    private vehicleSearchResultSubscription: Subscription = new Subscription();
    private vehicleSearchResultFromServiceSubscription: Subscription = new Subscription();
    private vehicleSearchResultFromServiceOnMoreSubscription: Subscription = new Subscription();
    private vehicleSearchCriteriaSubscription: Subscription = new Subscription();
    private supplierSubscription: Subscription = new Subscription();

    constructor(
        private configLoader: ConfigLoader,
        private dataStore: DataStoreService,
        public sorterService: SorterService,
        private summaryCardService: ContentSummaryCardService,
        private spinnerService: SpinnerService,
        private userJourneyManager: UserJourneyManager,
        private dataHandlerService: DataHandlerService,
        private resourceTypeService: ResourceTypeService,
        private transportResourceService: TransportResourceService,
        private supplierService: SupplierService,
        private commonHelper: CommonHelper,
        private autoScrollService: AutoScrollService,
        private focusViewService: FocusViewService,
        private queryParamService: DMCQueryParamsService,
        private rootService: RootService
    ) { }

    ngOnInit() {
        this.tVehicle = this.configLoader.configurations.get(TCO.CONF.CONF_VEHICLE_SEARCH_RESULTS_CONFIG);
        this.tPagination = this.configLoader.configurations.get(TCO.CONF.CONF_PAGINATION_CONFIG)['VEHICLE_SEARCH'];

        this.setupPaginator();
        this.getSearchCriteria();
        this.subscribeSearchResults();
        this.getSearchResults();
        this.createSorters();

        const resourceTypeSearchCriteria = new ResourceTypeSearchCriteria();
        resourceTypeSearchCriteria.size = 1000000;
        this.resourceTypeService.getResourceTypes(resourceTypeSearchCriteria).subscribe(
            result => {
                this.resourceTypes = result.data;
            });

        this.dataHandlerService.retrieveTransferModes().subscribe(
            result => {
                if (result.data) {
                    this.trsModes = result.data;
                }
            });
    }

    private setupPaginator() {
        this.defaultPageResultCount = this.tPagination['defaultPageResultCount'];
        this.pager = new Pager(0, this.defaultPageResultCount);
    }

    public onPage(pageNumber: number) {
        this.pager.currPage = pageNumber;
        this.selectionIndex = (this.pager.currPage * this.pager.itemsPerPage).toString();
    }

    public onSelection(event) {
        this.selectionIndex = event;
    }

    public onMore() {
        this.dataFromMoreClick = true;
        if (this.tPagination && this.tPagination['defaultPageResultCount'] && this.tPagination['fetchingPages']) {
            this.searchCriteria.size = this.tPagination['fetchingPages'] * this.tPagination['defaultPageResultCount'];
            this.searchCriteria.start = this.fetchedReservationsCount;
            this.retrieveAndCollectSearchResults(this.searchCriteria);
        }
    }

    private setPageZero() {
        if (this.pager) {
            if (!this.dataFromMoreClick) {
                this.pager.currPage = 0;
            } else {
                this.dataFromMoreClick = false;
            }
        } else {
            this.pager = new Pager(0, this.defaultPageResultCount);
        }
    }

    subscribeSearchResults() {
        this.vehicleSearchResultFromServiceSubscription = this.dataStore.get(DataKey.vehicleSearchResultsService)
                                                              .subscribe((result: OPResponseWrapper<VehicleSummary>) => {
                                                                  this.resultCount = 0;
                                                                  if (result) {
                                                                      this.resultCount = ResponseUtil.getTotalCount(
                                                                          result);
                                                                      let dataList = ResponseUtil.getDataArray(result);
                                                                      this.dataStore.set(
                                                                          DataKey.vehicleSearchResults,
                                                                          dataList,
                                                                          true
                                                                      );
                                                                  }
                                                              });
    }

    // -- paginator--

    /**
     * keep fetched data and add new data to dataStore for pagination. only use in pagination more
     *
     * @param searchCriteria
     */
    retrieveAndCollectSearchResults(searchCriteria: VehicleSearchCriteria) {
        this.dataStore.set(DataKey.vehicleSearchPagingTempResult, null, true);
        if (this.vehicleSearchResultFromServiceOnMoreSubscription) {
            this.vehicleSearchResultFromServiceOnMoreSubscription.unsubscribe();
        }
        this.vehicleSearchResultFromServiceOnMoreSubscription = this.transportResourceService.searchVehiclesForPaging(
            searchCriteria)
                                                                    .subscribe(result => {
                                                                        this.resultCount = 0;
                                                                        if (result) {
                                                                            this.resultCount = ResponseUtil.getTotalCount(
                                                                                result);
                                                                            let dataList = this.searchResults.concat(
                                                                                ResponseUtil.getDataArray(
                                                                                    result));
                                                                            this.dataStore.set(
                                                                                DataKey.vehicleSearchResults,
                                                                                dataList,
                                                                                true
                                                                            );
                                                                        }
                                                                    });
    }

    getSearchCriteria() {
        this.vehicleSearchCriteriaSubscription = this.dataStore.get(DataKey.vehicleSearchCriteria)
                                                     .subscribe((data) => {
                                                         if (data !== null) {
                                                             this.searchCriteria = data;
                                                         }
                                                     });
    }

    createSorters() {
        this.sorterService.sorters = [];
        this.sorters_ = [];
        let sorterMap = new Map<string, Sorter>();
        this.tVehicle.config.placeHolderConfig.forEach(config => {
            if (config && config.sort) {
                let sorter = this.sorterService.createSorter(config);
                let id = config['_id'];
                sorterMap.set(id, sorter);
                this.sorters_.push(sorter);
            }
        });
    }

    public onSort(event) {
        this.sorterService.sorter = event;
    }

    getSearchResults() {
        this.fetchedReservationsCount = 0;
        this.vehicleSearchResultSubscription = this.dataStore.get(DataKey.vehicleSearchResults)
                                                   .subscribe(result => {
                                                       this.selectionIndex = '';
                                                       this.searchResults = [];
                                                       if (result != null) {
                                                           this.spinnerService.hide();
                                                       }
                                                       if (result) {
                                                           this.searchResults = result;
                                                           this.fetchedReservationsCount = this.searchResults.length;
                                                           if (this.fetchedReservationsCount > 0) {
                                                           }
                                                           this.getVehiclesCardList();
                                                       } else {
                                                           this.contentCardDataList = [];
                                                           this.heading = '';
                                                       }
                                                   });
    }

    getVehiclesCardList() {
        if (this.searchResults.length !== null) {
            let vehicleSummaries: VehicleSummary[] = this.searchResults;

            let vehicleMap = [];
            for (let i = 0; i < vehicleSummaries.length; i++) {

                let id = vehicleSummaries[i].resourceId;
                let model = vehicleSummaries[i].vehicleModel;
                let name = vehicleSummaries[i].vehicleName;
                let licencePlate = vehicleSummaries[i].licencePlate;
                let capacity = vehicleSummaries[i].capacity;
                let minPax = vehicleSummaries[i].minPax;
                let maxPax = vehicleSummaries[i].maxPax;
                let activeStatus = vehicleSummaries[i].activeStatus;
                let currency = vehicleSummaries[i].currency;
                let paymentMethod = vehicleSummaries[i].paymentMethod;

                let supplierName = vehicleSummaries[i].supplierName;
                let supplierCode = vehicleSummaries[i].supplierCode;
                let supplierId = vehicleSummaries[i].supplierId;

                let supplier = supplierName + (supplierCode ? ' (' + supplierCode + ')' : '');
                let driver = vehicleSummaries[i].driverName;
                let transferMode = vehicleSummaries[i].transferMode;

                vehicleMap.push({
                    'resourceId': id,
                    'name': name,
                    'supplier': supplier,
                    'supplierId': supplierId,
                    'model': model,
                    'licencePlate': licencePlate,
                    'capacity': capacity,
                    'minPax': minPax,
                    'maxPax': maxPax,
                    'activeStatus': activeStatus,
                    'currency': currency,
                    'paymentMethod': paymentMethod,
                    'transferMode': transferMode,
                    'driver': driver,
                    'rawData': vehicleSummaries[i]
                });
            }
            this.vehiclesDataArray = vehicleMap;
            this.contentCardDataList = this.getSearchContent();
        }
    }

    getSearchContent() {
        let contentCardList = this.summaryCardService.getContentCardsByConfig(
            this.tVehicle,
            this.vehiclesDataArray
        );

        this.spinnerService.hide();
        this.heading = this.resultCount + ' result' + (this.resultCount > 1 ? 's' : '') + ' found.';

        this.paginatorHasMore = this.resultCount !== contentCardList.length;

        console.log(this.heading);
        this.setPageZero();
        return contentCardList;
    }

    public onResourceSelect($event: SummaryCardData) {
        this.selectedVehicleSummary = $event;
        this.getSelectedResourceCost();
        const id = $event.data['value']['resourceId'];
        if (id !== null) {
            this.transportResourceService.retrieveResourceById(id)
                .subscribe(
                    res => {
                        if (this.commonHelper.dataValidity(res) && res.data && res.data.length > 0) {
                            this.detailedResource = ResponseUtil.getFirstData(res);
                            this.setTrsModeNameCode();
                            const selectedResource = {
                                resourceId: this.detailedResource.resourceId,
                                resourceType: this.detailedResource.resourceType
                            };
                            const parameters = this.queryParamService.getQueryParamsArray(selectedResource);
                            this.rootService.setDataToLocalStorage(TCO.AppData.RESOURCE, selectedResource, true, parameters);
                        }
                    }
                );
        }
    }

    getSelectedResourceCost() {
        this.dataStore.set(DataKey.supplier, null, true);
        this.firstTimeSupplierResults = true;
        let id = this.selectedVehicleSummary.data['value']['supplierId'];
        this.supplierService.retrieveSupplierById(String(id));
        this.supplierResultObserver();
    }

    supplierResultObserver() {
        this.supplierSubscription = this.dataStore.get(DataKey.supplier).subscribe(
            result => {
                if (result != null && this.firstTimeSupplierResults) {
                    this.firstTimeSupplierResults = false;
                    this.resourceCost = [];
                    let supplier: Supplier = ResponseUtil.getFirstData(result);
                    if (supplier) {
                        for (let value of supplier.seasons) {
                            let trsCosts: TrsResourceCost[] = [];
                            for (const cost of value.trsResourceCosts) {
                                if (cost.transferMode === this.selectedVehicleSummary.data['value']['transferMode'] &&
                                    cost.vehicleModel === this.selectedVehicleSummary.data['value']['model']) {
                                    trsCosts.push(cost);
                                }
                            }
                            value.trsResourceCosts = trsCosts;
                            if (value.trsResourceCosts && value.trsResourceCosts.length > 0) {
                                this.resourceCost.push(value);
                            }
                        }
                    }
                }
            }, error => {
            }
        );
    }

    public onEditResource($event: Resource) {
        this.userJourneyManager.goForKey('GO_TO_EDIT_VEHICLE');
        $event.isEditTriggered = true;
        $event.hasResourceCost = this.resourceCost && this.resourceCost.length > 0 ? true : false;
        this.dataStore.set(DataKey.selectedVehicleForEdit, $event, true);
    }

    public onCardScroll($event: number) {
        setTimeout(() => {
            this.autoScrollService.scrollToID(
                'clientDetailExp_' + $event, 'main-scroll-container');
        }, 0);
    }

    public onEditAsNewResource($event: Resource) {
        this.clearIdsInResource($event);
        this.userJourneyManager.goForKey('GO_TO_CREATE_VEHICLE');
        this.dataStore.set(DataKey.selectedVehicleForEditAsNew, $event, true);
    }

    /**
     * IMPORTANT : clear all the existing ids that needs to be saved as new entities
     *
     * @param resource
     */
    public clearIdsInResource(resource: Resource) {
        resource.resourceId = 0;
        if (resource.contactDetails) {
            resource.contactDetails.forEach(value => value.contactId = 0);
        }
        if (resource.documents) {
            resource.documents.forEach(value => value.id = 0);
        }
        if (resource.remarks) {
            resource.remarks.forEach(value => value.remarkId = 0);
        }
        if (resource.taxSchemes) {
            resource.taxSchemes.forEach(value => value.taxSchemeId = 0);
        }
        if (resource.paymentInfo) {
            resource.paymentInfo.paymentId = 0;
        }
        if (resource.supplierAttributes) {
            resource.supplierAttributes.forEach(value => value.id = 0);
        }
        if (resource.genericResource) {
            resource.genericResource.genericResId = 0;

            if (resource.genericResource.languages) {
                resource.genericResource.languages.forEach(value => {
                    value.tblId = 0;
                    value.genericResourceId = 0;
                });
            }
        }
        if (resource.vehicle) {
            resource.vehicle.vehicleId = 0;

            if (resource.vehicle.facilities) {
                resource.vehicle.facilities.forEach(value => {
                    value.tblFacilityId = 0;
                    value.vehicleId = 0;
                });
            }
            if (resource.vehicle.resServiceInfo) {
                resource.vehicle.resServiceInfo.serviceInfoId = 0;
            }
            if (resource.vehicle.resInsuranceInfo) {
                resource.vehicle.resInsuranceInfo.insuranceInfoId = 0;
            }
        }
    }

    public onCalendarClick(event: SummaryCardData) {
        this.navConfig = this.configLoader.configurations.get(TCO.CONF.CONF_PAGE_SECTION_CONFIG)['availability_calendar_side_nav']['resource_level'];
        let supplier = (event && event.data.value) ? (event.data.value.supplier) : '';
        supplier = supplier.split('(')[0];
        let resourceSummary = event.data.value['rawData'];
        setTimeout(() => {
            let fabActions = [];

            /*let navItemsArray = this.configLoader.configurations.get(TCO.CONF.CONF_NAVIGATION);
             this.configLoader.configurations.get(TCO.CONF.CONF_FOCUS_VIEW).AVAILABILITY_CAL_FOCUS_VIEW.fabAction
             .forEach(val => {
             for (let key in navItemsArray) {
             if (val === navItemsArray[key]['id']) {
             fabActions.push(navItemsArray[key]);
             }
             }
             });*/
            let dataObject = new ModalData(
                LEVEL.SUCCESS,
                'Availability Calendar',
                null,
                null,
                'season-setup-focus-view',
                ResAvailabilityCalendarFocusViewComponent,
                {
                    'resourceSummary': resourceSummary,
                    'navConfig': this.navConfig
                },
                {'label': 'close'},
                '',
                '',
                fabActions,
                '',
                (event && event.data.value) ? (event.data.value.name) + ' | Supplier: ' + supplier : ''
            );

            this.focusViewCloseSubject = this.dataStore.get(DataKey.availabilityCalendarFocusViewClose).subscribe(c => {
                if (c) {
                    resourceSummary = null;
                }
            });
            this.focusViewService.confirm(dataObject).subscribe(res => {
                if (res) {
                    console.log(res);
                }
            });
        }, 0);
    }

    public ngOnDestroy(): void {
        if (this.vehicleSearchResultSubscription) {
            this.vehicleSearchResultSubscription.unsubscribe();
        }

        if (this.vehicleSearchResultFromServiceSubscription) {
            this.vehicleSearchResultFromServiceSubscription.unsubscribe();
        }

        if (this.vehicleSearchResultFromServiceOnMoreSubscription) {
            this.vehicleSearchResultFromServiceOnMoreSubscription.unsubscribe();
        }

        if (this.vehicleSearchCriteriaSubscription) {
            this.vehicleSearchCriteriaSubscription.unsubscribe();
        }

        if (this.supplierSubscription) {
            this.supplierSubscription.unsubscribe();
        }

        if (this.focusViewCloseSubject) {
            this.focusViewCloseSubject.unsubscribe();
        }
    }

    private setTrsModeNameCode() {
        if(this.detailedResource && this.detailedResource.vehicle && this.detailedResource.vehicle.trsMode) {
            this.detailedResource.vehicle.trsModeCodeName = this.getTransferModeCodeName(this.detailedResource.vehicle.trsMode);
        }
    }

    public getTransferModeCodeName(transferMode: string) {
        let option = this.trsModes.find(value => value.code === transferMode);
        let codeName = '';
        if (option) {
            if (option.code) {
                codeName = option.code;
            }
            codeName += ' | ';
            if (option.name) {
                codeName += option.name;
            }
            return codeName;
        } else {
            return '';
        }
    }
}
