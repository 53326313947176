import { Component, EventEmitter, Output } from '@angular/core';
import {ICellRendererAngularComp} from "ag-grid-angular";

@Component({
    selector: 'child-cell',
    template: `<span class="tc-ag-grid__icons-container">
                    <button mat-icon-button matTooltip="{{params.title? params.title:'Action' | translate}}" class="tc-m-0 tc-icon-block__icon tc-icon-block__icon--active" (click)="onClick()" >
                        <mat-icon aria-hidden="false" aria-label="">{{params.icon}}</mat-icon>
                    </button>
                </span>`,
    styles: [
        `.btn {
            line-height: 0.5
        }`
    ]
})
export class ActionColumnComponent{
    params;

    constructor() {
    }

    agInit(params): void {
        this.params = params;
        /*if (_.isNil(params.action)) {
            throw new Error('Missing action parameter for ActionCellRendererComponent');
        }*/
    }

    onClick(): void {
        this.params.action(this.params);
    }
}

export type CellAction = (params) => void;
