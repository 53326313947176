import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LeaveSearchCriteria } from '../../../models/criteria/leave-search-criteria';
import { LeaveSetup } from '../../../models/leave-setup';
import { ResourceLeaveSummary } from '../../../models/resource-leave-summary';
import { Leave } from '../../../models/resource/leave';
import { OPResponseWrapper } from '../../../models/response/op-response-wrapper';
import { DateTimeProcessor } from '../../business-utils/time-zone/date-time-processor';
import { DataKey } from '../../util/framework/data-store.service';
import { DMCQueryParamsService } from '../../util/framework/dmc-query-params.service';
import { RequestService } from '../../util/framework/request.service';
import { UrlPaths } from '../url-paths';

@Injectable()
export class ResourceAvailabilityService {
    constructor(
        private queryParamsService: DMCQueryParamsService,
        private requestService: RequestService
    ) {}

    public searchLeaveSummaries(leaveSearchCriteria: LeaveSearchCriteria, key: DataKey): Observable<OPResponseWrapper<ResourceLeaveSummary>>  {
        DateTimeProcessor.processLeaveSearchCriteriaDateTimes(leaveSearchCriteria);
        const params = this.queryParamsService.asQueryParams(leaveSearchCriteria);
        return this.requestService.getWithDataKey(
            key,
            UrlPaths.OP_SUP_URL_KEY,
            [UrlPaths.OP_SUP_VERSION, UrlPaths.LEAVE_SUMMARIES],
            params
        );
    }

    public deleteLeave(leaveId: LeaveSearchCriteria): Observable<OPResponseWrapper<Leave>>   {
        const params = this.queryParamsService.asQueryParams(leaveId);
        return this.requestService.delete(
            UrlPaths.OP_SUP_URL_KEY,
            [UrlPaths.OP_SUP_VERSION, UrlPaths.LEAVES],
            params
        );
    }

    public saveLeaves(leaveSetupData: LeaveSetup): Observable<OPResponseWrapper<Leave>>   {
        return this.requestService.postWithDataKey(
            DataKey.saveLeaveData,
            UrlPaths.OP_SUP_URL_KEY,
            [UrlPaths.OP_SUP_VERSION, UrlPaths.LEAVES],
            leaveSetupData
        );
    }
}
