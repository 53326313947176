export class GenericResource {
    genericResId: number;
    dateOfBirth: Date;
    firstName: string;
    gender: string;
    grade: string;
    lastName: string;
    licenseNum: string;
    passportNum: string;
    preferredRoute: string;
    preferredTransferMode: string;
    preferredRoutes: PreferredRoute[] = [];
    preferredTransferModes: PreferredTransferMode[] = [];
    resourceType: string;
    title: string;
    workingHours: string;
    languages: Language[]=[];

    //new
    yearsOfExperience: number;
}

export class Language {
    tblId: number;
    language: string;
    genericResourceId: number;

    constructor(language: string) {
        this.language = language;
    }
}
export class PreferredRoute {
    preferredRouteId: number;
    route: string;
    genericResourceId: number;

    constructor(route: string) {
        this.route = route;
    }
}
export class PreferredTransferMode {
    preferredTrsModeId: number;
    transferMode: string;
    genericResourceId: number;

    constructor(transferMode: string) {
        this.transferMode = transferMode;
    }
}

