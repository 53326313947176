import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { OccupancyGenerateCriteria } from '../../../../models/reservation-v2/criteria/occupancy-generate-criteria';
import { DataKey, DataStoreService } from '../../../../services/util/framework/data-store.service';

@Component({
    selector: 'tc-generate-occupancies-dialog',
    templateUrl: './generate-occupancies-dialog.component.html'
})
export class GenerateOccupanciesDialogComponent implements OnInit {

    occGenerateForm: FormGroup;
    @Input() occupancyGenerateCriteria = new OccupancyGenerateCriteria();

    constructor(
        private fb: FormBuilder,
        private dataStoreService: DataStoreService
    ) { }

    ngOnInit() {
        this.createForm();
        this.occGenerateForm.valueChanges.subscribe(
            (data) => {
                if (JSON.stringify(data) !== JSON.stringify({})) {
                    this.occupancyGenerateCriteria.minimumOccupancy = data.min;
                    this.occupancyGenerateCriteria.maximumOccupancy = data.max;
                    this.occupancyGenerateCriteria.minAdultsRequired = data.minAdults;
                }
            }
        );
    }

    private createForm() {
        this.occGenerateForm = this.fb.group({
            min: [1, [Validators.required, Validators.pattern('^[0-9]*$')]],
            max: [1, [Validators.required, Validators.pattern('^[0-9]*$')]],
            minAdults: [1]
        });
    }

    public onClose() {
        this.dataStoreService.set(DataKey.popupClose, false);
    }

    public onOkClick() {
        this.dataStoreService.set(DataKey.popupClose, true);
    }

    public isOkDisabled() {
        return !(
            this.occupancyGenerateCriteria.minimumOccupancy > 0 && this.occupancyGenerateCriteria.maximumOccupancy > 0 &&
            this.occupancyGenerateCriteria.maximumOccupancy >= this.occupancyGenerateCriteria.minimumOccupancy && this.occGenerateForm.valid);
    }

    public getErrorMessage(field: string) {
        switch (field) {
            case 'min' :
                return this.occGenerateForm.get('min').hasError('required') ? 'Min Occupancy Required' : '';
            case 'max' :
                return this.occGenerateForm.get('max').hasError('required') ? 'Max Occupancy Required' : '';
            default :
                return '';
        }
    }
}
