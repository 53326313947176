import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { UserJourneyManager } from '@tc-core/util/framework/user-journey-manager.service';

@Component({
  selector: 'tc-app-quick-action',
  templateUrl: './app-quick-action.component.html',

})
export class AppQuickActionComponent implements OnInit, OnDestroy {

    actions = [];
    hideQuickActions = true;
    private subscription: Subscription = new Subscription();

    constructor(private userJourneyManager: UserJourneyManager) {
    }

    ngOnInit() {
        this.subscription.add(this.userJourneyManager.quickActions.subscribe(quickActions => {
            this.actions = quickActions;
        }));
        this.subscription.add(this.userJourneyManager.hideQuickActions.subscribe(hideQuickActions => {
            this.hideQuickActions = hideQuickActions;
        }));
    }

    onActionClick(action) {
        this.userJourneyManager.go(action);
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
