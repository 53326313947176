import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { PassengerListGridModule } from '../passenger-list-grid/passenger-list-grid.module';
import { SelectServiceItemsComponent } from './select-service-items.component';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
  declarations: [SelectServiceItemsComponent],
    imports: [
        CommonModule,
        MatExpansionModule,
        MatCheckboxModule,
        PassengerListGridModule,
        FormsModule,
        TranslateModule.forChild()
    ]
})
export class SelectServiceItemsModule { }
