import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import 'hammerjs';
import { AppService } from './app.service';
export function initialize(app) {
    var _this = this;
    return function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
        return tslib_1.__generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, app.loadInitialConfigurations()];
                case 1:
                    _a.sent();
                    return [4 /*yield*/, app.loadAuthTokenElseLogin()];
                case 2:
                    _a.sent();
                    return [4 /*yield*/, app.loadUserElseLogin()];
                case 3:
                    _a.sent();
                    return [4 /*yield*/, app.loadConfigurations()];
                case 4:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
}
export function HttpLoaderFactory(httpClient) {
    return new TranslateHttpLoader(httpClient);
}
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
