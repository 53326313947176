import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User } from '@tc-core/model/it/codegen/tbx/api/authority/v2/users/user';
import { UserDetail } from '@tc-core/model/it/codegen/tbx/api/authority/v2/users/user-detail';
import { UserInfo } from '@tc-core/model/it/codegen/tbx/api/authority/v2/util/user-info';
import { AppContext } from '@tc-core/model/it/codegen/ui/app-context';
import { Scaffolding } from '@tc-core/model/it/codegen/ui/framework/scaffolding';
import { TC } from '@tc-core/util/constants';
import { ConfigLoader } from '@tc-core/util/framework/config-loader.service';
import { HeaderService } from '@tc-core/util/system';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../environments/environment.app';
import { AuthTokenService } from './authentication/auth-token.service';
import { UserProfile } from './authentication/user-profile';
import { TCO } from './constants';
import { OPResponseWrapper } from './models/response/op-response-wrapper';
import { DMCCommon } from './services/util/common/dmc-common';
import { DataKey, DataStoreService } from './services/util/framework/data-store.service';

@Injectable()
export class AppService {
    appContext: AppContext;
    scaffStructure: BehaviorSubject<Scaffolding[]> = new BehaviorSubject([]);
    tokenAdded = false;
    userProfile: UserProfile;
    configPath: string;
    defaultConfigPath = '/config/DMC/';
    LOGIN_FAILED_MESSAGE = 'Login Failed';

    constructor(
        private configLoader: ConfigLoader,
        private dataStore: DataStoreService,
        private http: HttpClient,
        private autTokenService: AuthTokenService,
        private headerService: HeaderService,
        private commonService: DMCCommon
    ) {
        this.appContext = new AppContext();
    }

    public loadAuthTokenElseLogin() {
        return new Promise((resolve) => {
            this.getTokenFromUrlParams();
            const authorized = this.autTokenService.isAuthorized();
            const token = this.autTokenService.getToken();
            if (authorized === false) {
                this.redirectToWaitingPage();
                resolve(true);
            } else {
                if (token) {
                    resolve(true);
                } else {
                    const loginError = this.autTokenService.getAuthorizationError();
                    this.redirectToLoginPage(loginError);
                }
            }
        });
    }

    private getTokenFromUrlParams() {
        const url = window.location.href;
        console.log(url);
        this.tokenAdded = this.autTokenService.updateTokenParamsByUrl(url);
    }

    public loadInitialConfigurations() {
        return new Promise((resolve) => {
            this.configLoader.loadFromBaseConfig(environment.initial_config).then(() => {
                resolve(true);
            });
        });
    }

    public loadUserElseLogin() {
        return new Promise((resolve) => {
            const authorized = this.autTokenService.isAuthorized(); // todo FC_SUPPLIER_ACCEPTANCE
            if (authorized === false) {
                this.redirectToWaitingPage();
                resolve(true);
                localStorage.clear();
            } else {
                const url = this.configLoader.configurations.get(TCO.CONF.CONF_ENDPOINT)[TCO.URL.AUTH_USER_PROFILE];
                this.http.get(url).subscribe((result: OPResponseWrapper<UserProfile>) => {
                        if (result && result.status.code === 1 && result.data[0]) {
                            this.userProfile = result.data[0];

                            if (this.userProfile.userAppRule && this.userProfile.userAppRule.configPathFE) {
                                this.configPath = this.userProfile.userAppRule.configPathFE;
                            } else {
                                this.configPath = this.defaultConfigPath;
                            }

                            const user: User = new User();
                            user.id = this.userProfile.userId;

                            const userSummary = new UserInfo();
                            userSummary.email = this.userProfile.email;
                            userSummary.firstName = this.userProfile.firstName;
                            userSummary.lastName = this.userProfile.lastName;
                            if (this.userProfile.userName) {
                                userSummary.username = this.userProfile.userName;
                            } else {
                                userSummary.username = this.userProfile.email;
                            }
                            userSummary.additionalInfomation = {
                                imageUrl: this.userProfile.imageUrl
                            };
                            user.userSummary = userSummary;

                            const userDetail = new UserDetail();
                            if (this.userProfile.kcCompanyName == null || this.userProfile.kcCompanyName === '') {
                                userDetail.defaultCom = 'Any';
                            } else {
                                userDetail.defaultCom = this.userProfile.kcCompanyName;
                            }
                            if (this.userProfile.kcDivisionName == null || this.userProfile.kcDivisionName === '') {
                                userDetail.defaultDivison = 'Any';
                            } else {
                                userDetail.defaultDivison = this.userProfile.kcDivisionName;
                            }
                            if (this.userProfile.kcBrandName == null || this.userProfile.kcBrandName === '') {
                                userDetail.defaultBrand = 'Any';
                            } else {
                                userDetail.defaultBrand = this.userProfile.kcBrandName;
                            }
                            if (this.userProfile.kcDistributionChannelName == null || this.userProfile.kcDistributionChannelName ===
                                '') {
                                userDetail.defaultChannel = 'Any';
                            } else {
                                userDetail.defaultChannel = this.userProfile.kcDistributionChannelName;
                            }
                            user.userDetail = userDetail;

                            if (user && user.id && user.userSummary && user.userSummary.username) {
                                this.dataStore.set(DataKey.userDetail, user, true);
                                this.dataStore.set(DataKey.userProfile, this.userProfile, true);
                            }
                            resolve(true);
                        } else {
                            this.redirectToLoginPage(this.LOGIN_FAILED_MESSAGE);
                        }
                    }, (error) => {
                        console.log(error);
                        this.redirectToLoginPage(this.LOGIN_FAILED_MESSAGE);
                    }
                );
            }
        });
    }

    private redirectToLoginPage(message: string) {
        let loginUrl = this.commonService.getLoginUrl();
        if(message)
        {
            loginUrl = loginUrl + `&message=${message}`;
        }
        window.open(loginUrl, '_self');
    }

    private redirectToWaitingPage() {
        const waitingBaseUrl = this.configLoader.configurations.get(TCO.CONF.CONF_ENDPOINT)[TCO.URL.AUTH_USER_WAITING];
        const waitingUrl = `${waitingBaseUrl}`
            + `&authorized=${this.autTokenService.isAuthorized()}`
            + `&user_name=${this.autTokenService.getUserName()}`
            + `&approval_status=${this.autTokenService.getApprovalStatus()}`
            + `&message=${this.autTokenService.getMessage()}`;
        window.open(waitingUrl, '_self');
    }

    public loadConfigurations() {
        return new Promise((resolve) => {
            let path = environment.base_config;
            if (this.configPath) {
                path = this.configPath + environment.base_config;
            }
            this.configLoader.loadFromBaseConfig(path).then(() => {
                // load scaffolding config
                const results: Array<Scaffolding> = this.configLoader.configurations.get('SCAFFOLDING_CONFIG');
                this.scaffStructure.next(results);

                // load error handler status
                const catchErrorsValue: boolean = this.configLoader.conf([
                    TC.CONF.CONF_SYSTEM,
                    'defaults',
                    'catch_errors'
                ]);
                console.log('catchErrorsValue : ' + catchErrorsValue);
                this.dataStore.set(DataKey.catchErrors, catchErrorsValue);

                resolve(true);
            });
        });
    }
}
