import { Component, OnInit, ChangeDetectorRef, Input, SimpleChanges, OnChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ICellRendererParams } from 'ag-grid-community';
import { GridColumnDefinitionProcessorService } from '../../../../../services/util/pre-processors/grid-column-definition-processor.service';
import { SetupGridComp } from '../setup-grid/setup-grid-comp';

@Component({
    selector: 'tc-grouping-setup-grid',
    templateUrl: './grouping-setup-grid.component.html',
    styleUrls: ['./grouping-setup-grid.component.scss']
})
export class GroupingSetupGridComponent implements OnInit, OnChanges {

    @Input() rowDataRight = [];
    @Input() identityFunction;
    @Input() rowSelectionRight = 'multiple';
    @Input() rowSelectionLeft = 'multiple';

    _setupGridComp: SetupGridComp;

    @Input()
    set setupGridComp(setupGridComp: SetupGridComp) {
        this._setupGridComp = setupGridComp;
    }

    @Input()
    set coldefConfigLeft(colDef: any[]) {
        this._colDefConfigLeft = colDef;
        this.setColumnDefsLeft(colDef);
    }

    @Input()
    set coldefConfigRight(colDef: any[]) {
        this._colDefConfigRight = colDef;
        this.setColumnDefsRight(colDef);
    }

    _rowDataLeft: any[];

    @Input()
    set rowDataLeft(rowDataLeft: any[]) {
        this._rowDataLeft = rowDataLeft;
        this.setRowDataLeft();
    }

    public columnDefsLeft = [];
    public defaultColDefLeft = {
        headerValueGetter: (parameters: ICellRendererParams): string => {
            const headerIdentifier = parameters.colDef.headerName;
            if (headerIdentifier) {
                return this.translateService.instant(headerIdentifier);
            }
            return '';
        }
    };
    public gridApiLeft;
    public gridColumnApiLeft;
    public gridOptionsLeft = {
        rowSelection: 'multiple',
        pagination: true,
        paginationPageSize: 10
    };

    public columnDefsRight = [];
    public defaultColDefRight = {
        headerValueGetter: (parameters: ICellRendererParams): string => {
            const headerIdentifier = parameters.colDef.headerName;
            if (headerIdentifier) {
                return this.translateService.instant(headerIdentifier);
            }
            return '';
        }
    };
    public gridApiRight;
    public gridColumnApiRight;
    public gridOptionsRight = {
        rowSelection: 'multiple',
        pagination: true,
        paginationPageSize: 10
    };

    _colDefConfigLeft: any[];
    _colDefConfigRight: any[];

    @Input() autoSize = false;

    constructor(
        private columnDefinitionProcessorService: GridColumnDefinitionProcessorService,
        private cd: ChangeDetectorRef,
        private translateService: TranslateService
    ) {
        this.translateService.onLangChange.subscribe(() => {
            this.gridApiRight.refreshHeader();
            this.gridApiLeft.refreshHeader();
        });
    }

    ngOnInit(): void {
        this.checkMandatoryInputs();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.checkMandatoryInputs();
    }

    private checkMandatoryInputs() {
        if (!this._colDefConfigLeft) {
            throw new Error('Attribute \'colDefConfigLeft\' is required');
        }
        if (!this._colDefConfigRight) {
            throw new Error('Attribute \'colDefConfigRight\' is required');
        }
    }

    private setColumnDefsLeft(coldefConfigLeft) {
        const colDefConfig_ = JSON.parse(JSON.stringify(coldefConfigLeft));
        this.columnDefsLeft = this.columnDefinitionProcessorService.createColumnDefs(colDefConfig_, this);
        this.cd.detectChanges();
        // if (this.gridApiLeft) {
        //     this.gridApiLeft.sizeColumnsToFit();
        //
        // }
    }

    setGridWidth(api: any) {
        // api.sizeColumnsToFit();

        // if (this.autoSize) {
        //     api.autoSizeAllColumns();
        // }else {
        //     api.sizeColumnsToFit();
        // }
    }

    private setRowDataLeft() {
        if (this.gridApiLeft) {
            this.gridApiLeft.setRowData(this._rowDataLeft);
        }
    }

    onLeftDataSet(){
        this.selectRawsAlreadyIn();
    }

    private selectRawsAlreadyIn(){
        if (this.rowDataRight && this.rowDataRight.length > 0 && this._rowDataLeft) {
            this.gridApiLeft.forEachNode((node) => {
                if (this.isContainsInGrid(this.rowDataRight, node.data)) {
                    node.setSelected(true);
                }
            });
        }
    }

    private isContainsInGrid(datalist:any[], data:any){
        let contains=false;
        for (let datalistElement of datalist) {
            if (this.identityFunction(datalistElement) === this.identityFunction(data)) {
                contains=true;
            }
        }
        return contains;
    }

    private setColumnDefsRight(coldefConfigRight) {
        const colDefConfig_ = JSON.parse(JSON.stringify(coldefConfigRight));
        this.columnDefsRight = this.columnDefinitionProcessorService.createColumnDefs(colDefConfig_, this);
        this.cd.detectChanges();
        // if (this.gridApiRight) {
        //     this.gridApiRight.sizeColumnsToFit();
        // }
    }

    onGridReadyLeft($event) {
        this.gridApiLeft = $event.api;
        this.gridColumnApiLeft = $event.columnApi;
        // this.gridApiLeft.sizeColumnsToFit();
        this.gridApiLeft.setRowData(this._rowDataLeft);
        this.gridApiLeft.autoSizeAllColumns()
    }

    onGridReadyRight($event) {
        this.gridApiRight = $event.api;
        this.gridColumnApiRight = $event.columnApi;
        // this.gridApiRight.sizeColumnsToFit();
        this.gridApiRight.setRowData(this.rowDataRight);
        this.gridApiRight.autoSizeAllColumns()
    }

    onGridSizeChangeLeft() {
        // this.gridApiLeft.sizeColumnsToFit();
        this.setGridWidth(this.gridApiLeft);
    }

    onGridSizeChangeRight() {
        // this.gridApiRight.sizeColumnsToFit();
        this.setGridWidth(this.gridApiRight);
    }

    onClickAddSelected() {
        const selectedRows = this.gridApiLeft.getSelectedRows();
        for (const row of selectedRows) {
            this.addItemIfNotAlreadyIn(row, this.rowDataRight);
        }
    }

    onClickAddAll() {
        let rowData = [];
        this.gridApiLeft.forEachNode(node => rowData.push(node.data));
        for (const row of rowData) {
            this.addItemIfNotAlreadyIn(row, this.rowDataRight);
        }
    }

    addItemIfNotAlreadyIn(dataRow: any, dataList: any[]) {
        if (dataList) {
            if (this.identityFunction) {
                const found = dataList.find(element => this.identityFunction(dataRow) ===
                    this.identityFunction(element));
                // should replace from above after fixing above identityFunction
                // const found = dataList.find(element => element.code === dataRow.code);
                if (!found) {
                    dataList.push(dataRow);
                    this.gridApiRight.setRowData(dataList);
                }
            } else {
                dataList.push(dataRow);
                this.gridApiRight.setRowData(dataList);
            }
        }
    }

    onDeleteSelectedClickRight() {
        const selectedRows = this.gridApiRight.getSelectedRows();
        this.removeItemsFromLeftSelection(selectedRows)
        for (const row of selectedRows) {
            this.removeItemIfAlreadyIn(row, this.rowDataRight);
        }
    }

    removeItemsFromLeftSelection(selectedRows: any[]): any {
        if(selectedRows && selectedRows.length > 0) {
            // this.gridApiLeft.getSelectedRows().s
        }
    }

    removeItemIfAlreadyIn(dataRow: any, dataList: any[]) {
        if (dataList) {
            const index = dataList.indexOf(dataRow);
            if (index > -1) {
                dataList.splice(index, 1);
                this.gridApiRight.setRowData(dataList);
            }
        }
    }

    onSelectionChangeRight(event) {
        if (event && event.option) {
            const option = event.option.value;
            const selected = event.option.selected;
            if (this.gridColumnApiRight) {
                this.gridColumnApiRight.setColumnVisible(option, selected);
                if (this.autoSize) {
                    this.gridApiRight.autoSizeAllColumns();
                }else {
                    // this.gridApiRight.sizeColumnsToFit();
                }
            }
        }
    }
    onSelectionChangeLeft(event) {
        if (event && event.option) {
            const option = event.option.value;
            const selected = event.option.selected;
            if (this.gridColumnApiLeft) {
                this.gridColumnApiLeft.setColumnVisible(option, selected);
                if (this.autoSize) {
                    this.gridColumnApiLeft.autoSizeAllColumns();
                }else {
                    // this.gridApiLeft.sizeColumnsToFit();
                }
            }
        }
    }

    onClickColOptionRight(event) {
        event.stopPropagation();
    }
    onClickColOptionLeft(event) {
        event.stopPropagation();
    }

}
