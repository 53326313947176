/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../node_modules/@tc/fab/fab.component.ngfactory";
import * as i2 from "@tc/fab/fab.component";
import * as i3 from "./fab-wrapper.component";
import * as i4 from "@tc-core/util/framework/user-journey-manager.service";
import * as i5 from "@tc-core/util/framework/event-manager.service";
import * as i6 from "@tc-core/util/framework/focus-handler.service";
var styles_FabWrapperComponent = [];
var RenderType_FabWrapperComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_FabWrapperComponent, data: {} });
export { RenderType_FabWrapperComponent as RenderType_FabWrapperComponent };
export function View_FabWrapperComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-fab", [], null, [[null, "trigger"], [null, "fabClose"], [null, "toggleFAB"], ["document", "keyup"], ["document", "mousemove"], ["document", "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("document:keyup" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).handleLocalKeyboardEvent($event) !== false);
        ad = (pd_0 && ad);
    } if (("document:mousemove" === en)) {
        var pd_1 = (i0.ɵnov(_v, 1).handleMouseEnter($event) !== false);
        ad = (pd_1 && ad);
    } if (("document:click" === en)) {
        var pd_2 = (i0.ɵnov(_v, 1).handleDocumentClick($event) !== false);
        ad = (pd_2 && ad);
    } if (("trigger" === en)) {
        var pd_3 = (_co.onTrigger($event) !== false);
        ad = (pd_3 && ad);
    } if (("fabClose" === en)) {
        var pd_4 = (_co.onFabClose() !== false);
        ad = (pd_4 && ad);
    } if (("toggleFAB" === en)) {
        var pd_5 = (_co.onFABToggle($event) !== false);
        ad = (pd_5 && ad);
    } return ad; }, i1.View_FabComponent_0, i1.RenderType_FabComponent)), i0.ɵdid(1, 638976, null, 0, i2.FabComponent, [i0.Renderer2], { actions: [0, "actions"], canProceedState: [1, "canProceedState"], isActive: [2, "isActive"], selectedIdx: [3, "selectedIdx"] }, { trigger: "trigger", fabClose: "fabClose", toggleFAB: "toggleFAB" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.actions; var currVal_1 = _co.canProceedState; var currVal_2 = _co.isActive; var currVal_3 = _co.selectedSubActionIdx; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_FabWrapperComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-fab-wrapper", [], null, null, null, View_FabWrapperComponent_0, RenderType_FabWrapperComponent)), i0.ɵdid(1, 245760, null, 0, i3.FabWrapperComponent, [i4.UserJourneyManager, i5.EventManager, i0.ElementRef, i6.FocusHandlerService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FabWrapperComponentNgFactory = i0.ɵccf("tc-fab-wrapper", i3.FabWrapperComponent, View_FabWrapperComponent_Host_0, {}, {}, []);
export { FabWrapperComponentNgFactory as FabWrapperComponentNgFactory };
