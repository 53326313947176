/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../node_modules/ag-grid-angular/dist/agGridAngular.ngfactory";
import * as i4 from "ag-grid-angular/dist/angularFrameworkOverrides";
import * as i5 from "ag-grid-angular/dist/angularFrameworkComponentWrapper";
import * as i6 from "ag-grid-angular/dist/agGridAngular";
import * as i7 from "./auto-assigned-summary.component";
var styles_AutoAssignedSummaryComponent = [];
var RenderType_AutoAssignedSummaryComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AutoAssignedSummaryComponent, data: {} });
export { RenderType_AutoAssignedSummaryComponent as RenderType_AutoAssignedSummaryComponent };
function View_AutoAssignedSummaryComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "tc-modal-footer__content"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "h4", [], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ": AED ", " "])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), i0.ɵppd(4, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform("Total Cost")); var currVal_1 = i0.ɵunv(_v, 2, 1, _ck(_v, 4, 0, i0.ɵnov(_v.parent, 0), _co.totalCost, "1.2-2")); _ck(_v, 2, 0, currVal_0, currVal_1); }); }
export function View_AutoAssignedSummaryComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i2.DecimalPipe, [i0.LOCALE_ID]), (_l()(), i0.ɵeld(1, 0, null, null, 5, "div", [["class", "tc-ag-grid__container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 16777216, null, null, 4, "ag-grid-angular", [["class", "ag-theme-material tc-ag-grid tc-ag-grid--popup-content"], ["style", ""]], null, null, null, i3.View_AgGridAngular_0, i3.RenderType_AgGridAngular)), i0.ɵprd(512, null, i4.AngularFrameworkOverrides, i4.AngularFrameworkOverrides, [i0.NgZone]), i0.ɵprd(512, null, i5.AngularFrameworkComponentWrapper, i5.AngularFrameworkComponentWrapper, []), i0.ɵdid(5, 4898816, null, 1, i6.AgGridAngular, [i0.ElementRef, i0.ViewContainerRef, i4.AngularFrameworkOverrides, i5.AngularFrameworkComponentWrapper, i0.ComponentFactoryResolver], { rowData: [0, "rowData"], columnDefs: [1, "columnDefs"], defaultColDef: [2, "defaultColDef"], pagination: [3, "pagination"], paginationAutoPageSize: [4, "paginationAutoPageSize"] }, null), i0.ɵqud(603979776, 1, { columns: 1 }), (_l()(), i0.ɵeld(7, 0, null, null, 5, "div", [["class", "tc-modal-footer tc-modal-footer--space-between"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AutoAssignedSummaryComponent_1)), i0.ɵdid(9, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(10, 0, null, null, 2, "button", [["class", "button-right tc-button-primary mat-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClose($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(11, null, [" ", " "])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.rowData; var currVal_1 = _co.columnDefs; var currVal_2 = _co.defaultColDef; var currVal_3 = true; var currVal_4 = true; _ck(_v, 5, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_5 = _co.totalCost; _ck(_v, 9, 0, currVal_5); }, function (_ck, _v) { var currVal_6 = i0.ɵunv(_v, 11, 0, i0.ɵnov(_v, 12).transform("Ok")); _ck(_v, 11, 0, currVal_6); }); }
export function View_AutoAssignedSummaryComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-auto-assigned-summary", [], null, null, null, View_AutoAssignedSummaryComponent_0, RenderType_AutoAssignedSummaryComponent)), i0.ɵdid(1, 114688, null, 0, i7.AutoAssignedSummaryComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AutoAssignedSummaryComponentNgFactory = i0.ɵccf("tc-auto-assigned-summary", i7.AutoAssignedSummaryComponent, View_AutoAssignedSummaryComponent_Host_0, { assignedResources: "assignedResources" }, { closePopUp: "closePopUp" }, []);
export { AutoAssignedSummaryComponentNgFactory as AutoAssignedSummaryComponentNgFactory };
