import { Component } from '@angular/core';
import { ITooltipAngularComp } from 'ag-grid-angular';
import { ITooltipParams } from 'ag-grid-community';

@Component({
    selector: 'tc-tooltip',
    templateUrl: './grid-tool-tip.component.html',
    styleUrls: ['./grid-tool-tip.component.scss']
})
export class GridToolTipComponent implements ITooltipAngularComp {

    private params: any;
    private data: any;
    private tooltipFunc: any;
    width = '100px';

    agInit(params: ITooltipParams): void {
        this.params = params;
        this.data = params.api.getDisplayedRowAtIndex(params.rowIndex).data;
        this.tooltipFunc = this.params.tooltipFunc;
        this.width = this.params.width;
    }

    public getTooltip() {
        if (this.tooltipFunc) {
            return this.tooltipFunc(this.data);
        }
    }
}
