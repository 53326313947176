import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { QueryParamField } from '@tc-core/model/it/codegen/ui/query-param-field';
import { ConfigLoader } from '@tc-core/util/framework/config-loader.service';
import { EventManager } from '@tc-core/util/framework/event-manager.service';
import { DataStoreService } from '../framework/data-store.service';
import { DMCQueryParamsService } from '../framework/dmc-query-params.service';
import { DMCRouteManager } from '../framework/dmc-route-manager.service';
import { DMCLocalStorageService, LocalDataKey } from '../system/dmc-local-storage.service';

@Injectable()
export class RootService {

    public params: Map<string, string> = new Map();

    constructor(
        private eventManager: EventManager,
        private configLoader: ConfigLoader,
        private paramsService: DMCQueryParamsService,
        private routeManager: DMCRouteManager,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private dataStore: DataStoreService,
        private localStorageService: DMCLocalStorageService
    ) {}

    public event() {
        return this.eventManager;
    }

    public config() {
        return this.configLoader;
    }

    public param() {
        return this.paramsService;
    }

    public loadQueryParams(params: string[]) {
        let qParams = this.activatedRoute.snapshot.queryParams;

        // load data from url params
        params.forEach(key => {
            if (!this.dataStore.has(key, false)) {
                this.dataStore.set(key, qParams[key], false);
            }
        });
    }

    public setDataWithMapping(
        key: string, value: any, addToUrl?: boolean, parameters?: QueryParamField[], resetQParams?: boolean) {
        this.dataStore.set(key, value, false);

        parameters = parameters ? parameters : [];

        if (addToUrl) {

            // todo this need to be handle in proper way
            setTimeout(() => {
                this.router.navigate([], {
                    queryParams: this.paramsService.asRouteParams(key, parameters, resetQParams),
                    relativeTo: this.activatedRoute,
                    replaceUrl: true
                });
            }, 0);

            if (parameters) {
                parameters.forEach(qpf => {
                    if (value && qpf) {
                        this.dataStore.set(qpf.param, value[qpf.param], false);
                    }
                });
            }
        }
    }

    public setData(key: string, value: any, addToUrl?: boolean, parameters?: string[], resetQParams?: boolean) {

        for (let key in value) {
            if (value.hasOwnProperty(key)) {
                if (typeof value[key] === 'undefined') {
                    value[key] = '';
                }
            }
        }

        parameters = parameters ? parameters : [];
        const mappedParams = [];
        parameters.forEach(param => {
            mappedParams.push({
                param: param,
                field: param
            });
        });

        this.setDataWithMapping(key, value, addToUrl, mappedParams, resetQParams);
    }

    public setDataToLocalStorage(key: string, value: any, addToUrl?: boolean, parameters?: string[], resetQParams?: boolean) {
        this.localStorageService.store(key, value);
    }

    /**
     * Loads data to data store from url and returns the updated object
     * @param {string[]} params list of fields to be retrieved from url
     * @param obj target object where read data should be set to
     * @param {string} key of the entry in the data store (selected data map)
     * @param ignoreParse
     * @returns {any} updated object
     */
    public loadData(params: string[], obj: any, key: string, ignoreParse: string[] = []): any {
        let data: any;
        params.forEach(param => {
            data = this.activatedRoute.snapshot.queryParams[param];
            // obj[param] = data ? data : undefined;
            if (data) {
                obj[param] = ignoreParse.indexOf(param) > -1 ? data : this.parseData(data);
                this.dataStore.set(param, data, false);
            }
        });

        this.dataStore.set(key, obj, false);

        return obj;
    }

    public loadDataFromLocalStorage(
        params: string[], obj: any, localDataKey: string, ignoreParse: string[] = []) {
        const loaded = this.localStorageService.load(localDataKey);
        if (loaded) {
            params.forEach(param => {
                const data = loaded[param];
                obj[param] = ignoreParse.indexOf(param) > -1 ? data : this.parseData(data);
                this.dataStore.set(param, data, false);
            });
        }
        return obj;
    }

    public loadRawDataFromLocalStorage(
        params: string[], obj: any, localDataKey: string, ignoreParse: string[] = []) {
        const loaded = this.localStorageService.load(localDataKey);
        if (loaded) {
            for (const key in loaded) {
                if (loaded.hasOwnProperty(key)) {
                    const data = loaded[key];
                    obj[key] = ignoreParse.indexOf(key) > -1 ? data : this.parseData(data);
                }
            }
        }
        return obj;
    }

    public addCriteriaToLocalStorage(localDataKey: LocalDataKey, criteria) {
        this.localStorageService.store(localDataKey, criteria);
    }

    /**
     * Returns data from data store. If the requested data is not present,
     * it will be first read from url and loaded to data store before returning
     * @param {string} key identifier of the data store map (and query param)
     * @returns {any} any of the stored data object or value
     */
    public getData(key: string) {
        let qParams = this.activatedRoute.snapshot.queryParams;

        // if data is not available in the data map or existing value is null set it from query params
        if (!this.dataStore.has(key, false) || !this.dataStore.get(key, false)) {
            this.dataStore.set(key, qParams[key], false);
        }

        return this.dataStore.get(key, false);
    }

    public updateData(key: string, value: any) {
        this.dataStore.set(key, value, false);
    }

    public navigateNext() {
        this.routeManager.next();
    }

    public navigate(key: string) {
        this.routeManager.goto(key);
    }

    private parseData(data): any {
        if (data) {
            // check whether data is an object
            if(typeof data === 'object')
            {
                return data;
            }
            // check whether data is a number
            if (!isNaN(data)) {
                return +data;
            }

            // check whether data is a boolean
            if (data.toUpperCase() === 'TRUE' || data.toUpperCase() === 'FALSE') {
                return data.toUpperCase() === 'TRUE';
            }
        }
        return data;
    }
}
