/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../node_modules/@tc/section-navigation/section-navigation.component.ngfactory";
import * as i2 from "@tc/section-navigation/section-navigation.component";
import * as i3 from "@tc-core/util/ui/auto-scroll.service";
import * as i4 from "./app-section-navigation.component";
var styles_AppSectionNavigationComponent = [];
var RenderType_AppSectionNavigationComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AppSectionNavigationComponent, data: {} });
export { RenderType_AppSectionNavigationComponent as RenderType_AppSectionNavigationComponent };
export function View_AppSectionNavigationComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-section-navigation", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onResize() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_SectionNavigationComponent_0, i1.RenderType_SectionNavigationComponent)), i0.ɵdid(1, 12828672, null, 0, i2.SectionNavigationComponent, [i3.AutoScrollService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_AppSectionNavigationComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-app-section-navigation", [], null, null, null, View_AppSectionNavigationComponent_0, RenderType_AppSectionNavigationComponent)), i0.ɵdid(1, 114688, null, 0, i4.AppSectionNavigationComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppSectionNavigationComponentNgFactory = i0.ɵccf("tc-app-section-navigation", i4.AppSectionNavigationComponent, View_AppSectionNavigationComponent_Host_0, {}, {}, []);
export { AppSectionNavigationComponentNgFactory as AppSectionNavigationComponentNgFactory };
