<div class="resource-assignment-view">
  <mat-tab-group [selectedIndex]="selectedIndex">
    <mat-tab label= {{supplierLabel}}>
      <tc-supplier-assign-panel
              [displayChipsArr]="displayChipsArrSuppliers"
              [tAssignedSuppliers]="tAssignedSuppliers"
              [contentCardDataList]="contentCardDataListSuppliers"
              [bookingData]="bookingData"
              [bookingDataArray]="bookingDataArray"
              [genTourItem]="genTourItem"
              [isBulkAssignment]="isBulkAssignment"
              [selectedSupplier]="selectedSupplier"
              [heading]="supplierPanelHeading"
              [isDataLoaded]="isDataLoaded"
              [changesAvailableForServiceItems]="changesAvailableForServiceItems"
              [currencyFormat]="currencyFormat"
              [hasMore]="hasMoreSuppliers"
              [pager]="pager"
              [dataFromMoreClick]="suppliersFromMoreClick"
              [fetchedSupplierCount]="fetchedSupplierCount"
              [isVehicleAssignment]="isVehicleAssignment"
              [isDriverAssignment]="isDriverAssignment"
              [isGenResourceAssignment]="isGenResourceAssignment"
              (onPaginationMoreClick)="onSupPaginationMore($event)"
              (supplierSelect)="onSupplierSelect($event)">
      </tc-supplier-assign-panel>
    </mat-tab>
    <mat-tab label="Vehicle" *ngIf="isVehicleAssignment">
      <tc-vehicle-assign-panel
              [displayChipsArr]="displayChipsArrVehicles"
              [tAssignedVehicles]="tAssignedVehicles"
              [contentCardDataList]="contentCardDataListVehicles"
              [bookingData]="bookingData"
              [bookingDataArray]="bookingDataArray"
              [isBulkAssignment]="isBulkAssignment"
              [selectedVehicle]="selectedVehicle"
              [heading]="vehiclePanelHeading"
              [hasMore]="hasMoreVehicles"
              [pager]="pager"
              [itemGroup]="itemGroup"
              [dataFromMoreClick]="vehiclesFromMoreClick"
              [fetchedVehicleCount]="fetchedVehicleCount"
              [vehicleSearchCriteria]="vehicleSearchCriteria"
              (onPaginationMoreClick)="onVehiclesPaginationMore($event)"
              (vehicleSelect)="onVehicleSelect($event)"
              (resourceCalendarClick)="onDriverCalendarClick($event)"
              (eventClick)="onVehicleEventClick($event)"
              (targetClick)="onVehicleTargetClick($event)">
      </tc-vehicle-assign-panel>
    </mat-tab>
    <mat-tab label="Driver" *ngIf="isDriverAssignment">
      <tc-driver-assign-panel
              [tAssignedDrivers]="tAssignedDrivers"
              [itemGroup]="itemGroup"
              [contentCardDataList]="contentCardDataListDrivers"
              [bookingData]="bookingData"
              [bookingDataArray]="bookingDataArray"
              [isBulkAssignment]="isBulkAssignment"
              [selectedDriver]="selectedDriver"
              [supplierIdList]="supplierIdList"
              [heading]="driverPanelHeading"
              [hasMore]="hasMoreDrivers"
              [pager]="pager"
              [dataFromMoreClick]="driversFromMoreClick"
              [fetchedDriverCount]="fetchedDriverCount"
              (onPaginationMoreClick)="onDriversPaginationMore($event)"
              (driverSelect)="onDriverSelect($event)"
              (resourceCalendarClick)="onDriverCalendarClick($event)"
              (eventClick)="onDriverEventClick($event)"
              (targetClick)="onDriverTargetClick($event)">
      </tc-driver-assign-panel>
    </mat-tab>
    <mat-tab label="Resource" *ngIf="isGenResourceAssignment">
      <tc-resource-assign-panel
              [tAssignedResources]="tAssignedResources"
              [genTourItem]="genTourItem"
              [contentCardDataList]="contentCardDataListResources"
              [selectedResource]="selectedResource"
              [supplierIdList]="supplierIdList"
              [heading]="resourcePanelHeading"
              [hasMore]="hasMoreResources"
              [pager]="pager"
              [dataFromMoreClick]="resourcesFromMoreClick"
              [fetchedResourceCount]="fetchedResourceCount"
              (onPaginationMoreClick)="onResourcesPaginationMore($event)"
              (resourceSelect)="onResourceSelect($event)"
              (resourceCalendarClick)="onResourceCalendarClick($event)"
              (eventClick)="onResourceEventClick($event)"
              (targetClick)="onResourceTargetClick($event)">
      </tc-resource-assign-panel>
    </mat-tab>
  </mat-tab-group>
</div>
