export class DispatchPatch {
    supplierId: number;
    supplierName: string;
    supplierCode: string;

    email: string;
    emailSubject: string;
    emailBody: string;
}

export enum DispatchEntryOperation {
    SEND = 'SEND',
    RESEND = 'RESEND'
}
