import * as tslib_1 from "tslib";
import * as i0 from "@angular/core";
var BookingUtilService = /** @class */ (function () {
    function BookingUtilService() {
    }
    BookingUtilService.prototype.setBookingReferenceId = function (booking, bookingItem, type, itemNumber) {
        var e_1, _a;
        var maxItemNumber = 0;
        if (itemNumber > 0) {
            maxItemNumber = itemNumber - 1;
        }
        else {
            if (booking && booking.bookingItems) {
                try {
                    for (var _b = tslib_1.__values(booking.bookingItems), _c = _b.next(); !_c.done; _c = _b.next()) {
                        var bi = _c.value;
                        if (bi.bookingSourceItemNo > maxItemNumber) {
                            maxItemNumber = bi.bookingSourceItemNo;
                        }
                    }
                }
                catch (e_1_1) { e_1 = { error: e_1_1 }; }
                finally {
                    try {
                        if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                    }
                    finally { if (e_1) throw e_1.error; }
                }
            }
        }
        bookingItem.bookingSourceItemNo = maxItemNumber + 1;
        var bookingSource = booking.bookingSource ? booking.bookingSource : '';
        var bookingId = booking.bookingId ? (bookingSource ? (bookingSource + '_' + booking.bookingId) : booking.bookingId) : '';
        var productType = '';
        switch (type) {
            case 'trs':
                productType = '_TRS';
                break;
            case 'gen':
                productType = '_GEN';
                break;
            case 'accom':
                productType = '_ACCOM';
                break;
        }
        bookingItem.bookingReferenceId = bookingId + (bookingId ? '_' : '') + bookingItem.bookingSourceItemNo + productType;
    };
    BookingUtilService.ngInjectableDef = i0.defineInjectable({ factory: function BookingUtilService_Factory() { return new BookingUtilService(); }, token: BookingUtilService, providedIn: "root" });
    return BookingUtilService;
}());
export { BookingUtilService };
