import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import {
    MatAutocompleteModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule
} from '@angular/material';
import { AddMoreLangDialogModule } from '../../shared/add-more-lang-dialog/add-more-lang-dialog.module';
import { DriverSpecificComponent } from './driver-specific.component';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        AddMoreLangDialogModule,
        MatIconModule,
        MatAutocompleteModule,
        MatButtonModule,
        MatSelectModule,
        TranslateModule.forChild()
    ],
    declarations: [DriverSpecificComponent],
    exports: [DriverSpecificComponent]
})
export class DriverSpecificModule {}
