<!--<div class="tc-item-wrapper">-->
<!--<div class="tc-item-header tc-flex-box">-->
<!--<div class="tc-flex-item tc-item-header__primary">Contact Details</div>-->
<!--<div class="tc-flex-item tc-item-header__actions tc-icon-block">-->
<!--</div>-->
<!--</div>-->
<!--</div>-->
<form [formGroup]="leaveSetupForm">
    <div class="container-fluid direct-client-personal-info-edit-card tc-card-wrapper">
        <div class="row">
            <div class="col-md-12 col-lg-12">
                <div class="tc-flex-box">
                    <div class="tc-flex-item tc-item-circle">
                        <div class="icon-box">
                            <i class="material-icons">note</i>
                        </div>
                    </div>
                    <div class="tc-flex-item tc-right-cage">
                        <div class="row">
                            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 remove-bootstrap-padding-mobile">
                                <div class="tc-select-edit dcpiec-select-start-date">
                                    <mat-form-field>
                                        <input
                                                #dateInput
                                                id="INFO_EDIT_LEAVE_SDATE"
                                                matInput
                                                [min]="minDate"
                                                [matDatepicker]="picker"
                                                [placeholder]="'Apply From'"
                                                name="startDate"
                                                formControlName="startDate">
                                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                        <mat-datepicker [startAt]="initialDate"  #picker></mat-datepicker>
                                        <mat-error>{{getErrorMessage('startDate')}}</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 remove-bootstrap-padding-mobile">
                                <div class="tc-select-edit dcpiec-select-start-date">
                                    <mat-form-field>
                                        <input
                                                #dateInput
                                                id="INFO_EDIT_LEAVE_EDATE"
                                                matInput
                                                [min]="minDate"
                                                [matDatepicker]="picker1"
                                                [placeholder]="'Apply To'"
                                                name="endDate"
                                                formControlName="endDate">
                                        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                        <mat-datepicker #picker1></mat-datepicker>
                                        <mat-error>{{getErrorMessage('endDate')}}</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 remove-bootstrap-padding-mobile">
                                <div class="tc-input-edit dcpiec-input-fname">
                                    <mat-form-field>
                                        <mat-select
                                                #Input
                                                id="INFO_EDIT_LEAVE_TYPE"
                                                formControlName="leaveType"
                                                [placeholder]="'Leave Type'" required>
                                            <mat-option
                                                    *ngFor="let option of leaveTypes"
                                                    [value]="option">
                                                {{option.name}}
                                            </mat-option>
                                        </mat-select>
                                        <mat-error>{{getErrorMessage('leaveType')}}
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 remove-bootstrap-padding-mobile">
                                <div class="tc-select-edit dcpiec-select-leave-note">
                                    <mat-form-field>
                                        <input
                                                matInput
                                                formControlName="note"
                                                [placeholder]="'Note'"
                                                maxlength="30"
                                                required>
                                        <mat-error>{{getErrorMessage('note')}}
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
