import { SortDirection } from '../helper/sort-direction';
import {KeyControlAndAbstractSearchCriteria} from './key-control-and-abstract-search-criteria';

export class VehicleSearchCriteria extends KeyControlAndAbstractSearchCriteria{
    //AbstractSearchCriteria
    start:number;
    page:number;
    size:number;
    sortBy:string;
    sortDirection:SortDirection;

    //VehicleSearchCriteria
    resourceId: number;
    resourceType: string;
    licencePlate: string;
    seatingCapacity: number;
    fuelType: string;
    transferMode: string;
    vehicleModel: string;
    vehicleName: string;
    minPax: number;
    maxPax: number;
    supplierCode: string;
    supplierId: number;
    country: string;
    city: string;
    currency: string;
    paymentMethod: string;
    //yyyy-MM-dd
    startDate: any;
    //yyyy-MM-dd
    endDate: any;
    vehicleAmenities: string[];
    driver: string;
    internal: boolean;
    activeStatus: boolean;
    serviceDate: any;
    serviceStartTime: any;
    route: string;
    serviceItems: string;
    // for private serviceItem
    isPrivate: boolean;

    // to filter by rejected resourceId list
    rejectedResourceIdList: number[];

    businessType: string;
}
