import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ProfileManagementService } from '../../../services/backend-consumers/supplier-service/profile-management.service';
import { DataKey, DataStoreService } from '../../../services/util/framework/data-store.service';
var ManageSupplierAttributesWrapperComponent = /** @class */ (function () {
    function ManageSupplierAttributesWrapperComponent(dataStore, profileManagementService) {
        this.dataStore = dataStore;
        this.profileManagementService = profileManagementService;
        this.supplierAttributes = [];
        this.supplierAttributesMasterData = [];
        this.changedSupplierAttributes = [];
    }
    ManageSupplierAttributesWrapperComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.profileManagementService.retrieveSupplierAttributes().subscribe(function (result) {
            _this.supplierAttributesMasterData = result['data'];
            _this.addNewSupplierAttributesToList();
        });
    };
    ManageSupplierAttributesWrapperComponent.prototype.addNewSupplierAttributesToList = function () {
        var _this = this;
        var supplierAttributesNotInList = [];
        this.supplierAttributesMasterData.forEach(function (value) {
            var attributeIn = _this.supplierAttributes
                .filter(function (valueInList) { return valueInList.attributeCode === value.attributeCode; });
            if (attributeIn.length < 1) {
                supplierAttributesNotInList.push(value);
            }
        });
        // supplierAttributes list with new attributes included
        this.supplierAttributes = this.supplierAttributes.concat(supplierAttributesNotInList);
    };
    ManageSupplierAttributesWrapperComponent.prototype.onSupplierAttributesChanged = function ($event) {
        var e_1, _a;
        this.changedSupplierAttributes = [];
        try {
            for (var _b = tslib_1.__values(this.supplierAttributes), _c = _b.next(); !_c.done; _c = _b.next()) {
                var attribute = _c.value;
                if (attribute.attributeValue) {
                    this.changedSupplierAttributes.push(attribute);
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        this.dataStore.set(DataKey.supplierAttributes, this.changedSupplierAttributes, true);
    };
    ManageSupplierAttributesWrapperComponent.prototype.closeModalClick = function () {
        this.dataStore.set(DataKey.modalClose, true);
    };
    return ManageSupplierAttributesWrapperComponent;
}());
export { ManageSupplierAttributesWrapperComponent };
