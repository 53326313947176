<div
        class="notification-toast-item notification-toast-item--default"
        [ngClass]="toastModel.cssClass"
        (click)="onClick($event)">
    <mat-icon class="notification-toast-item--toast-icon">{{toastModel.matIcon}}</mat-icon>
    <div class="notification-toast-item--close-btn" id="item_close_btn" (click)="onClose($event)">
        <mat-icon>close</mat-icon>
    </div>
    <div *ngIf="toastModel.title || toastModel.body" class="notification-toast-item--toast-text">
        <span
                *ngIf="toastModel.title"
                class="notification-toast-item--toast-title"
                [innerHTML]="toastModel.title"></span>
        <br *ngIf="toastModel.title && toastModel.body"/>
        <span
                *ngIf="toastModel.body"
                class="notification-toast-item--toast-msg">{{toastModel.body | truncate: ['27', "... "]}}</span>
    </div>
</div>
