import { DriverAllocationSummary } from '../summary/driver-allocation-summary';
import {ResourceAvailability} from '../resource/resource-availability';
import { DriverSupplierCostSummary } from '../summary/driver-supplier-cost-summary';
import {TrsSupplierCostSummary} from '../summary/supplier-cost-summary';

export class ResourceAllocationEvent {
    serviceItemId: number;
    vehicleSupplier: TrsSupplierCostSummary;
    vehicle: ResourceAvailability;
    driverSupplier: DriverSupplierCostSummary;
    driver:ResourceAvailability;

    constructor(
        serviceItemId: number, vehicleSupplier: TrsSupplierCostSummary, vehicle: ResourceAvailability,
        driverSupplier: DriverSupplierCostSummary, driver: ResourceAvailability
    ) {
        this.serviceItemId = serviceItemId;
        this.vehicleSupplier = vehicleSupplier;
        this.vehicle = vehicle;
        this.driverSupplier = driverSupplier;
        this.driver = driver;
    }
}

