/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/tooltip";
import * as i2 from "@angular/cdk/overlay";
import * as i3 from "@angular/cdk/scrolling";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "@angular/cdk/bidi";
import * as i7 from "@angular/platform-browser";
import * as i8 from "@angular/common";
import * as i9 from "../../../../../../node_modules/ag-grid-angular/dist/agGridAngular.ngfactory";
import * as i10 from "ag-grid-angular/dist/angularFrameworkOverrides";
import * as i11 from "ag-grid-angular/dist/angularFrameworkComponentWrapper";
import * as i12 from "ag-grid-angular/dist/agGridAngular";
import * as i13 from "./booking-passenger-group.component";
import * as i14 from "@ngx-translate/core";
import * as i15 from "@tc-core/util/framework/config-loader.service";
import * as i16 from "../../../../services/util/pre-processors/grid-column-definition-processor.service";
var styles_BookingPassengerGroupComponent = [];
var RenderType_BookingPassengerGroupComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_BookingPassengerGroupComponent, data: {} });
export { RenderType_BookingPassengerGroupComponent as RenderType_BookingPassengerGroupComponent };
function View_BookingPassengerGroupComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 1, "div", [["aria-label", "indicator tooltip"], ["class", "tc-ag-grid-indicator tc-ag-grid-indicator--info"], ["matTooltipClass", "mat-tooltip--large mat-tooltip--success"], ["matTooltipPosition", "right"]], null, [[null, "longpress"], [null, "keydown"], [null, "touchend"]], function (_v, en, $event) { var ad = true; if (("longpress" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).show() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i0.ɵnov(_v, 1)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (i0.ɵnov(_v, 1)._handleTouchend() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 147456, null, 0, i1.MatTooltip, [i2.Overlay, i0.ElementRef, i3.ScrollDispatcher, i0.ViewContainerRef, i0.NgZone, i4.Platform, i5.AriaDescriber, i5.FocusMonitor, i1.MAT_TOOLTIP_SCROLL_STRATEGY, [2, i6.Directionality], [2, i1.MAT_TOOLTIP_DEFAULT_OPTIONS], [2, i7.HAMMER_LOADER]], { position: [0, "position"], message: [1, "message"], tooltipClass: [2, "tooltipClass"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "right"; var currVal_1 = i0.ɵinlineInterpolate(1, "", _co.getToolTip(_v.parent.context.$implicit), ""); var currVal_2 = "mat-tooltip--large mat-tooltip--success"; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_BookingPassengerGroupComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_BookingPassengerGroupComponent_2)), i0.ɵdid(1, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = (_v.context.$implicit.passengerAttributes && _v.context.$implicit.passengerAttributes.length); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_BookingPassengerGroupComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { passengerInfo: 0 }), (_l()(), i0.ɵeld(1, 16777216, null, null, 4, "ag-grid-angular", [["class", "ag-theme-material tc-ag-grid tc-ag-grid--full-width tc-ag-grid--popup-content"]], null, [[null, "gridReady"], [null, "firstDataRendered"], [null, "rowSelected"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("gridReady" === en)) {
        var pd_0 = (_co.onGridReady($event) !== false);
        ad = (pd_0 && ad);
    } if (("firstDataRendered" === en)) {
        var pd_1 = (_co.onFirstDataRendered($event) !== false);
        ad = (pd_1 && ad);
    } if (("rowSelected" === en)) {
        var pd_2 = (_co.onRowSelected($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i9.View_AgGridAngular_0, i9.RenderType_AgGridAngular)), i0.ɵprd(512, null, i10.AngularFrameworkOverrides, i10.AngularFrameworkOverrides, [i0.NgZone]), i0.ɵprd(512, null, i11.AngularFrameworkComponentWrapper, i11.AngularFrameworkComponentWrapper, []), i0.ɵdid(4, 4898816, null, 1, i12.AgGridAngular, [i0.ElementRef, i0.ViewContainerRef, i10.AngularFrameworkOverrides, i11.AngularFrameworkComponentWrapper, i0.ComponentFactoryResolver], { gridOptions: [0, "gridOptions"], rowData: [1, "rowData"], columnDefs: [2, "columnDefs"], frameworkComponents: [3, "frameworkComponents"], defaultColDef: [4, "defaultColDef"], rowSelection: [5, "rowSelection"], domLayout: [6, "domLayout"] }, { rowSelected: "rowSelected", gridReady: "gridReady", firstDataRendered: "firstDataRendered" }), i0.ɵqud(603979776, 2, { columns: 1 }), (_l()(), i0.ɵand(0, [[1, 2], ["passengerInfo", 2]], null, 0, null, View_BookingPassengerGroupComponent_1))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.gridOptions; var currVal_1 = ((_co.group == null) ? null : _co.group.bookingPassengers); var currVal_2 = _co.columnDefs; var currVal_3 = _co.frameworkComponents; var currVal_4 = _co.defaultColDef; var currVal_5 = "multiple"; var currVal_6 = "autoHeight"; _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
export function View_BookingPassengerGroupComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-booking-passenger-group", [], null, null, null, View_BookingPassengerGroupComponent_0, RenderType_BookingPassengerGroupComponent)), i0.ɵdid(1, 4308992, null, 0, i13.BookingPassengerGroupComponent, [i14.TranslateService, i15.ConfigLoader, i16.GridColumnDefinitionProcessorService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BookingPassengerGroupComponentNgFactory = i0.ɵccf("tc-booking-passenger-group", i13.BookingPassengerGroupComponent, View_BookingPassengerGroupComponent_Host_0, { group: "group" }, { newRowSelectEvent: "newRowSelectEvent" }, []);
export { BookingPassengerGroupComponentNgFactory as BookingPassengerGroupComponentNgFactory };
