import * as tslib_1 from "tslib";
import { SupplierAllocationSummary } from './supplier-allocation-summary';
var ResourceAllocationSummary = /** @class */ (function (_super) {
    tslib_1.__extends(ResourceAllocationSummary, _super);
    function ResourceAllocationSummary() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return ResourceAllocationSummary;
}(SupplierAllocationSummary));
export { ResourceAllocationSummary };
var AllocationTimeRange = /** @class */ (function () {
    function AllocationTimeRange() {
    }
    return AllocationTimeRange;
}());
export { AllocationTimeRange };
