import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaginatorModule } from '@tc/paginator';
import { GenericSearchCriteriaModule } from '../../generic-search-criteria/generic-search-criteria.module';
import { AdvDriverAssignSearchResultModule } from '../../../widgets/shared/adv-driver-assign-search-result/adv-driver-assign-search-result.module';
import { ResultHeaderModule } from '../../../widgets/shared/result-header/result-header.module';
import { ResourceAssignPanelComponent } from './resource-assign-panel.component';
import {AdvResourceAssignSearchResultModule} from "../../../widgets/shared/adv-resource-assign-search-result/adv-resource-assign-search-result.module";

@NgModule({
    imports: [
        CommonModule,
        GenericSearchCriteriaModule,
        ResultHeaderModule,
        PaginatorModule,
        AdvResourceAssignSearchResultModule
    ],
    exports: [
        ResourceAssignPanelComponent
    ],
    declarations: [ResourceAssignPanelComponent]
})
export class ResourceAssignPanelModule {}
