/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./note-editor.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../../node_modules/ag-grid-angular/dist/agGridAngular.ngfactory";
import * as i3 from "ag-grid-angular/dist/angularFrameworkOverrides";
import * as i4 from "ag-grid-angular/dist/angularFrameworkComponentWrapper";
import * as i5 from "ag-grid-angular/dist/agGridAngular";
import * as i6 from "./note-editor.component";
import * as i7 from "@ngx-translate/core";
var styles_NoteEditorComponent = [i0.styles];
var RenderType_NoteEditorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NoteEditorComponent, data: {} });
export { RenderType_NoteEditorComponent as RenderType_NoteEditorComponent };
export function View_NoteEditorComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 4, "ag-grid-angular", [["class", "ag-theme-material note-editor-grid mat-elevation-z2"], ["id", "myGridNoteEditor"], ["style", "width: 500px; height: 202px;"]], null, [[null, "gridReady"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("gridReady" === en)) {
        var pd_0 = (_co.onGridReady($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_AgGridAngular_0, i2.RenderType_AgGridAngular)), i1.ɵprd(512, null, i3.AngularFrameworkOverrides, i3.AngularFrameworkOverrides, [i1.NgZone]), i1.ɵprd(512, null, i4.AngularFrameworkComponentWrapper, i4.AngularFrameworkComponentWrapper, []), i1.ɵdid(3, 4898816, [["agGrid", 4]], 1, i5.AgGridAngular, [i1.ElementRef, i1.ViewContainerRef, i3.AngularFrameworkOverrides, i4.AngularFrameworkComponentWrapper, i1.ComponentFactoryResolver], { gridOptions: [0, "gridOptions"], rowData: [1, "rowData"], columnDefs: [2, "columnDefs"], defaultColDef: [3, "defaultColDef"] }, { gridReady: "gridReady" }), i1.ɵqud(603979776, 1, { columns: 1 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.gridOptions; var currVal_1 = _co.notes; var currVal_2 = _co.columnDefs; var currVal_3 = _co.defaultColDef; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_NoteEditorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "tc-note-editor", [], null, null, null, View_NoteEditorComponent_0, RenderType_NoteEditorComponent)), i1.ɵdid(1, 4243456, null, 0, i6.NoteEditorComponent, [i7.TranslateService], null, null)], null, null); }
var NoteEditorComponentNgFactory = i1.ɵccf("tc-note-editor", i6.NoteEditorComponent, View_NoteEditorComponent_Host_0, {}, {}, []);
export { NoteEditorComponentNgFactory as NoteEditorComponentNgFactory };
