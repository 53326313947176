<form [formGroup]="timeOffsetForm">
    <div class="container-fluid direct-client-personal-info-edit-card tc-card-wrapper">
        <div class="row">
            <div class="col-md-12 col-lg-12">
                <div class="tc-flex-box">
                    <div class="tc-flex-item tc-item-circle">
                        <div class="icon-box">
                            <i class="material-icons">account_circle</i>
                        </div>
                    </div>
                    <div class="tc-flex-item tc-right-cage">
                        <div class="row">
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                <div class="tc-content--wrap tc-input-view">
                                    <div class="tc-caption-txt txt-light">{{'Group Code' | translate}}</div>
                                    <div class="tc-body1--txt">{{timeOffsetRule.offsetRuleCode}}</div>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                <div class="tc-content--wrap tc-input-view">
                                    <div class="tc-caption-txt txt-light">{{'Group Name' | translate}}</div>
                                    <div class="tc-body1--txt">{{timeOffsetRule.offsetRuleName}}</div>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-1 col-lg-1 remove-bootstrap-padding-mobile">
                                <div class="tc-input-edit dcpiec-chip-group">
                                    <div class="card-chips item-right">
                                        <div class="chip"
                                             (click)="toggleValue('active')"
                                             [ngClass]=" isActive ?'active':'' ">
                                            Active
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-xs-12 col-sm-12 col-md-10 col-lg-10 remove-bootstrap-padding-mobile">
                                <table class="table">
                                    <thead class="text-primary">
                                    <tr>
                                        <th>{{'Pickup Points' | translate}}</th>
                                        <th>{{'Time Offset(Minutes)' | translate}}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <!--<tr formArrayName="locationTimeOffset" *ngFor="let point of timeOffsetRule.pickupData; let i = index;">
                                        <div [formGroupName]="i">
                                        <td>
                                            <mat-form-field>
                                                <input matInput
                                                       id="DIR_PER_INFO_EDIT_POINT"
                                                       #pointNameInput
                                                       formControlName="pointName"
                                                       [placeholder]="point.pickupPointName">
                                                &lt;!&ndash;<mat-error>{{getErrorMessage('lName')}}</mat-error>&ndash;&gt;
                                            </mat-form-field>
                                        </td>
                                        <td>
                                            <mat-form-field>
                                                <input matInput
                                                       id="DIR_PER_INFO_EDIT_TIME"
                                                       #timeOffsetValInput
                                                       formControlName="timeOffsetVal"
                                                       [placeholder]="point.pickupTimeOffset">
                                                &lt;!&ndash;<mat-error>{{getErrorMessage('lName')}}</mat-error>&ndash;&gt;
                                            </mat-form-field>
                                        </td>
                                        </div>
                                    </tr>-->
                                    <tr *ngFor="let point of timeOffsetRule.pickupData">

                                        <td>{{point.pickupPointName}}</td>
                                        <td>{{point.pickupTimeOffset}}</td>

                                    </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>
                        <div *ngIf="editLocations">
                            <div class="row">
                                <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                    <div class="tc-input-edit dcpiec-input-fname">
                                        <mat-form-field>
                                            <mat-select #regionSelect
                                                        id="DIR_PER_INFO_EDIT_REGION"
                                                        formControlName="region"
                                                        [placeholder]="'Region'"
                                                        required>
                                                <!-- title map includes it's name and type. So titleType.code is name of title which is used as code as well-->
                                                <mat-option id="DIR_PER_INFO_EDIT_REGION_{{titleType.key}}"

                                                            *ngFor="let titleType of regionList"
                                                            [value]="titleType.key">
                                                    {{titleType.value}}
                                                </mat-option>
                                            </mat-select>
                                            <!--<mat-error class="mat-error" role="alert">{{getErrorMessage('title')}}</mat-error>-->
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                    <div class="tc-input-edit dcpiec-input-lname">
                                        <mat-form-field>
                                            <mat-select #countrySelect
                                                        id="DIR_PER_INFO_EDIT_COUNTRY"
                                                        formControlName="country"
                                                        [placeholder]="'Country'"
                                                        required>
                                                <!-- title map includes it's name and type. So titleType.code is name of title which is used as code as well-->
                                                <mat-option id="DIR_PER_INFO_EDIT_COUNTRY_{{country.code}}"

                                                            *ngFor="let country of countryList"
                                                            [value]="country.code">
                                                    {{country.name}}
                                                </mat-option>
                                            </mat-select>
                                            <!--<mat-error class="mat-error" role="alert">{{getErrorMessage('title')}}</mat-error>-->
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                    <div class="tc-input-edit dcpiec-input-fname">
                                        <mat-form-field>
                                            <mat-select #titleSelect
                                                        id="DIR_PER_INFO_EDIT_CITY"
                                                        formControlName="city"
                                                        [placeholder]="'City'"
                                                        required>
                                                <mat-option id="DIR_PER_INFO_EDIT_CITY_{{city.code}}"

                                                            *ngFor="let city of cityList"
                                                            [value]="city.code">
                                                    {{city.name}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="tc-caption-txt txt-light">{{'Location Types' | translate}}</div>
                            </div>
                            <div class="row">
                                <div class="tc-input-edit dcpiec-chip-group">
                                    <div class="card-chips item-right">
                                        <div class="chip" *ngFor="let chip of searchCriteria"
                                             (click)="toggleValue(chip)"
                                             [ngClass]="selectedCriteria==chip ?'active':'' ">
                                            {{chip}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <table class="table">
                                    <tbody>
                                    <tr *ngFor="let txt of searchResults">
                                        <td>
                                            <mat-checkbox class="example-margin"
                                                          (change)="addRule($event, txt)"></mat-checkbox>
                                        </td>
                                        <td>{{txt['name']}}</td>
                                        <td>{{txt['code']}}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="tc-flex-item tc-item-circle">
                        <div class="icon-box">
                            <i class="material-icons tc-icon-block__icon tc-icon-block__icon--active"
                               (click)="editTimeOffset()">edit</i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
