import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTable, MatTableDataSource } from '@angular/material';
import { CurrencyFormat } from '@tc-core/model/it/codegen/ui/framework/currency-format';
import { TC } from '@tc-core/util';
import { DataKey, DataStoreService } from '../../../services/util/framework/data-store.service';
import { Season } from '../../../models/supplier/season';
import { TrsResourceCost } from '../../../models/supplier/trs-resource-cost-setup';

@Component({
    selector: 'tc-cost-setup-data-card',
    templateUrl: './cost-setup-data-card.component.html'
})
export class CostSetupDataCardComponent implements OnInit, OnChanges {

    EMPTY_CHAR = TC.FORMAT.EMPTY_CHAR;

    @Input() showTitle: boolean = true;
    @Input() seasons: Season[] = [];
    @Input() trsModes = [];
    @Input() costingTypes = [];
    @Input() vehicleModels = [];
    @Input() currencyFormat: CurrencyFormat;

    trsResourceCosts: TrsResourceCost[] = [];
    costingTypeList: any[] = [];

    displayedColumns: string[] = ['seasonName', 'route', 'transferMode', 'vehicleModel','days','costingType', 'unitFare', 'adultFare', 'childFare','infantFare', 'teenFare'];
    dataSource = new MatTableDataSource<TrsResourceCost>(this.trsResourceCosts);

    @ViewChild(MatTable) table: MatTable<TrsResourceCost>;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    constructor(
        private dataStore: DataStoreService,
    ) { }

    ngOnInit() {
        this.grabResourceCostsList();
        this.dataSource.data=this.trsResourceCosts;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    }

    grabResourceCostsList() {
        this.trsResourceCosts = [];
        if (this.seasons) {
            for (let i = 0; i < this.seasons.length; i++) {
                let season = this.seasons[i];
                for (let j = 0; j < season.trsResourceCosts.length; j++) {
                    let resCost = season.trsResourceCosts[j];
                    resCost.seasonName = season.name;
                    this.trsResourceCosts.push(resCost);
                }
            }
        }
    }

    public ngOnChanges(changes: SimpleChanges): void {
        for (let propName in changes) {
            if (propName === 'seasons') {
                this.grabResourceCostsList();
                this.dataSource.data=this.trsResourceCosts;
                this.dataSource.paginator = this.paginator;
                this.dataSource.sort = this.sort;
            }
        }
    }

    public getTrsModeName(code: any) {
        if (this.trsModes) {
            const type = this.trsModes.find(value => value.code === code);
            return type ? type.name : code;
        }
        return code;
    }

    public getCostingTypeNameFromList(code: any) {
        this.dataStore.get(DataKey.costingTypes).subscribe(value => {
            if(value && Array.isArray(value))
            {
                this.costingTypeList = value;
            }
            else if (value && value.data && Array.isArray(value.data))
            {
                this.costingTypeList = value.data;
            }
        });
        const type = this.costingTypeList.find(value => value.code === code);
        return type ? type.name : code;
    }

    public getCostingTypeName(code: any) {
        if (this.costingTypes && Array.isArray(this.costingTypes)) {
           const type = this.costingTypes.find(value => value.code === code);
           return type ? type.name : code;
        }
        return code;
    }

    public getVehicleModelName(code: any) {
        if (this.vehicleModels) {
            const type = this.vehicleModels.find(value => value.code === code);
            return type ? type.name : code;
        }
        return code;
    }
}

