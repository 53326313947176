import { AfterViewInit, Component } from '@angular/core';
import { MatCheckboxChange } from '@angular/material';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ICellEditorParams } from 'ag-grid-community';
import { GridCommonUtil } from '../../utils/grid-common-util';

@Component({
    selector: 'tc-multi-selector-editor',
    templateUrl: './multi-selector-editor.component.html'
})
export class MultiSelectorEditorComponent implements ICellEditorAngularComp, AfterViewInit {

    selected: boolean = false;
    params: any;
    isError = false;
    disabled: boolean = true;
    row: any;
    column: string;
    options: any[] = [];
    asCommaSeparatedValue: boolean = false;
    viewKey = 'name';
    valueKey = 'code';
    plainOptionArray: false;
    rowData: any;
    rawValue: any;
    dataArray: any[];

    constructor() { }

    ngOnInit() {
    }

    public agInit(params: ICellEditorParams): void {
        this.params = params;
        this.options = params['options'];
        this.disabled = !params['colDef']['editable'];
        this.plainOptionArray = params['plainOptionArray'];
        this.viewKey = params['viewKey'] ? params['viewKey'] : this.viewKey;
        this.valueKey = params['valueKey'] ? params['valueKey'] : this.valueKey;
        this.rowData = params['data'];
        this.asCommaSeparatedValue = params['asCommaSeparatedValue'];
        this.rawValue = params['value'];
        this.dataArray = this.rawValue;
        if (this.asCommaSeparatedValue) {
            if (this.rawValue) {
                this.dataArray = this.rawValue.split(',');
            } else {
                this.dataArray = [];
            }
        }
    }

    public onChange($event: MatCheckboxChange, option) {
        if (!this.dataArray) {
            this.dataArray = [];
        }
        if ($event.checked) {
            this.addWithoutDuplicates(option);
        } else {
            this.removeFromDataArray(option);
        }
    }

    addWithoutDuplicates(option: any) {
        if (this.plainOptionArray) {
            if (!(option in this.dataArray)) {
                this.dataArray.push(option);
            }
        } else {
            if (!(option[this.valueKey] in this.dataArray)) {
                this.dataArray.push(option[this.valueKey]);
            }
        }
    }

    removeFromDataArray(option: any) {
        if (this.plainOptionArray) {
            GridCommonUtil.removeElement(this.dataArray, option);
        } else if (this.asCommaSeparatedValue) {
            GridCommonUtil.removeElements(this.dataArray, option[this.valueKey]);
        } else {
            GridCommonUtil.removeElements(this.dataArray, option);
        }
    }

    public isSelected(option: any) {
        if (this.dataArray) {
            if (this.asCommaSeparatedValue && this.plainOptionArray) {
                let find = this.dataArray.find(
                    value => option === value
                );
                return !!find;
            }
            else {
                let find = this.dataArray.find(
                    value => option[this.valueKey] === value
                );
                return !!find;
            }
        } else {
            return false;
        }

    }

    public ngAfterViewInit(): void {
    }

    public focusIn(): void {
    }

    public focusOut(): void {
    }

    public getFrameworkComponentInstance(): any {
    }

    public getValue(): any {
        if (this.dataArray) {
            if (this.asCommaSeparatedValue) {
                return this.dataArray.toLocaleString();
            } else {
                return this.dataArray;
            }
        } else {return this.rawValue;}
    }

    public isCancelAfterEnd(): boolean {
        return false;
    }

    public isCancelBeforeStart(): boolean {
        return false;
    }

    public isPopup(): boolean {
        return true;
    }

    public getName(option: any) {
        if (this.plainOptionArray) {
            return option
        }else{
            return option[this.viewKey];
        }
    }
}
