/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../node_modules/@tc/quick-action/quick-action.component.ngfactory";
import * as i2 from "@tc/quick-action/quick-action.component";
import * as i3 from "@angular/common";
import * as i4 from "./app-quick-action.component";
import * as i5 from "@tc-core/util/framework/user-journey-manager.service";
var styles_AppQuickActionComponent = [];
var RenderType_AppQuickActionComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AppQuickActionComponent, data: {} });
export { RenderType_AppQuickActionComponent as RenderType_AppQuickActionComponent };
function View_AppQuickActionComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-quick-action", [], null, [[null, "actionClick"], ["window", "resize"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:resize" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onResize() !== false);
        ad = (pd_0 && ad);
    } if (("actionClick" === en)) {
        var pd_1 = (_co.onActionClick($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_QuickActionComponent_0, i1.RenderType_QuickActionComponent)), i0.ɵdid(1, 4308992, null, 0, i2.QuickActionComponent, [], { actions: [0, "actions"] }, { actionClick: "actionClick" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.actions; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_AppQuickActionComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppQuickActionComponent_1)), i0.ɵdid(1, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.hideQuickActions; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_AppQuickActionComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-app-quick-action", [], null, null, null, View_AppQuickActionComponent_0, RenderType_AppQuickActionComponent)), i0.ɵdid(1, 245760, null, 0, i4.AppQuickActionComponent, [i5.UserJourneyManager], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppQuickActionComponentNgFactory = i0.ɵccf("tc-app-quick-action", i4.AppQuickActionComponent, View_AppQuickActionComponent_Host_0, {}, {}, []);
export { AppQuickActionComponentNgFactory as AppQuickActionComponentNgFactory };
