import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpecialCharacterDirective } from './special-character/special-character.directive';

@NgModule({
    imports: [
        CommonModule
    ],
    exports: [
        SpecialCharacterDirective
    ],
    declarations: [
        SpecialCharacterDirective
    ],
    providers: []
})
export class CustomDirectivesModule {
}
