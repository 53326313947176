import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SecurityModule } from '@tc/security';

import { TranslateModule } from '@ngx-translate/core';
import {TaxSchemesCardComponent} from './tax-schemes-card.component';

@NgModule({
    imports: [
        TranslateModule.forChild(),
        CommonModule,
        SecurityModule
    ],
    exports: [
        TaxSchemesCardComponent
    ],
    declarations: [
        TaxSchemesCardComponent
    ],
    providers: [],
})
export class TaxSchemesCardModule {
}
