import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { TcErrorType } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-error';
import { ConfigLoader, EventManager, FocusViewManager, UserJourneyManager } from '@tc-core/util/framework';
import { CommonHelper } from '@tc-core/util/helpers';
import { SpinnerService } from '@tc-core/util/ui';
import { DialogService } from '@tc/dialog/dialog.service';
import { ModalService } from '@tc/modal/modal.service';
import { AgGridAngular } from 'ag-grid-angular';
import { ICellRendererParams, RowNode, SortChangedEvent } from 'ag-grid-community';
import { GenericResource } from 'app/models/resource/generic-resource';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { UserProfileSearchCriteria } from 'app/models/criteria/user-profile-search-criteria';
import { UserProfile } from '../../authentication/user-profile';
import { TCO } from '../../constants';
import { BrandSearchCriteria } from '../../models/criteria/brand-search-criteria';
import { DistributionChannelSearchCriteria } from '../../models/criteria/distribution-channel-search-criteria';
import { DivisionSearchCriteria } from '../../models/criteria/division-search-criteria';
import { GenericSearchCriteria } from '../../models/criteria/generic-search-criteria';
import { VehicleSearchCriteria } from '../../models/criteria/vehicle-search-criteria';
import { UserApprovalData } from '../../models/notification/user-approval-data';
import { ResourceType } from '../../models/reservation/assignment';
import { Resource } from '../../models/resource/resource';
import { Vehicle } from '../../models/resource/vehicle';
import { OPResponseWrapper } from '../../models/response/op-response-wrapper';
import { ApprovalStatus, UserServiceProfile } from '../../models/user/user-service-profile';
import {
    TemplateRendererComponent
} from 'app/widgets/framework/ag-grid-custom-components/renderers/template-renderer/template-renderer.component';
import { DocumentService } from '../../services/backend-consumers/documents/document.service';
import { NotificationService } from '../../services/backend-consumers/notifications/notification.service';
import { BrandSetupService } from '../../services/backend-consumers/setups/brand-setup-service';
import {
    DistributionChannelSetupService
} from '../../services/backend-consumers/setups/distribution-channel-setup-service';
import { DivisionSetupService } from '../../services/backend-consumers/setups/division-setup-service';
import { GenericResourceService } from '../../services/backend-consumers/supplier-service/generic-resource.service';
import { SupplierService } from '../../services/backend-consumers/supplier-service/supplier.service';
import { TransportResourceService } from '../../services/backend-consumers/supplier-service/transport-resource.service';
import { UserProfileService } from '../../services/backend-consumers/user-service/user-profile.service';
import { UserManagementService } from '../../services/user-management/user-management.service';
import { DMCCommon } from '../../services/util/common/dmc-common';
import { DataKey, DataStoreService } from '../../services/util/framework/data-store.service';
import { IdentifierService } from '../../services/util/identifier/identifier.service';
import {
    GridColumnDefinitionProcessorService
} from '../../services/util/pre-processors/grid-column-definition-processor.service';
import { ResponseUtil } from '../../services/util/response/response-util.service';
import { ButtonState } from '../../widgets/framework/ag-grid-custom-components/directives/button-state';
import {
    AutoCompleteEditorComponent
} from '../../widgets/framework/ag-grid-custom-components/editors/auto-complete-editor/auto-complete-editor.component';
import {
    DropdownEditorComponent
} from '../../widgets/framework/ag-grid-custom-components/editors/dropdown-editor/dropdown-editor.component';
import {
    NoteEditorComponent
} from '../../widgets/framework/ag-grid-custom-components/editors/note-editor/note-editor.component';
import {
    CustomTextFilterComponent
} from '../../widgets/framework/ag-grid-custom-components/filters/custom-text-filter/custom-text-filter.component';
import {
    ActionsComponent
} from '../../widgets/framework/ag-grid-custom-components/renderers/actions/actions.component';
import { ModalData } from '@tc-core/model/it/codegen/ui/framework/modal-data';
import { LEVEL } from '@tc-core/model/it/codegen/ui/framework/dialog-model';
import { PassengerEditorComponent } from '../reservation-v2/passenger-editor/passenger-editor.component';
import { UserRolesEditorComponent } from '../user-roles-editor/user-roles-editor.component';

@Component({
    selector: 'tc-operator-acceptance-queue',
    templateUrl: './operator-acceptance-queue.component.html',
    styleUrls: ['./operator-acceptance-queue.component.scss']
})
export class OperatorAcceptanceQueueComponent implements OnInit, AfterViewInit, OnDestroy {

    constructor(
        public em: EventManager,
        private configLoader: ConfigLoader,
        private dataStore: DataStoreService,
        private spinnerService: SpinnerService,
        private modalService: ModalService,
        private dialogService: DialogService,
        private common: DMCCommon,
        private notificationService: NotificationService,
        private cd: ChangeDetectorRef,
        private userJourneyManager: UserJourneyManager,
        private focusViewManager: FocusViewManager,
        private supplierService: SupplierService,
        private dataStoreService: DataStoreService,
        private columnDefinitionProcessorService: GridColumnDefinitionProcessorService,
        private commonHelper: CommonHelper,
        private documentService: DocumentService,
        private identifierService: IdentifierService,
        private genericResourceService: GenericResourceService,
        private userProfileService: UserProfileService,
        private transportResourceService: TransportResourceService,
        private translateService: TranslateService,
        private userManagementService: UserManagementService,
        private fb: FormBuilder,
        private brandSetupService: BrandSetupService,
        private divisionSetupService: DivisionSetupService,
        private distributionChannelSetupService: DistributionChannelSetupService
    ) {
        this.translateService.onLangChange.subscribe(() => {
            this.gridApi.refreshHeader();
        });
    }

    tPagination: any;
    searchCriteria = new UserProfileSearchCriteria();
    fetchedNotificationsCount = 0;
    totalNotificationCount = 0;
    resultHeaderHeading = '';
    isExportEnable = true;

    userProfile: UserProfile;
    paginatorLoaded: boolean;
    searchFromSortingOrFiltering = false;
    sortFilterModelProgrammaticallyChanging = false;
    hasABrand = false;
    hasADivision = false;
    hasADistributionChannel = false;

    gridApi;
    gridColumnApi;
    operatorAcceptanceResults: UserServiceProfile[] = [];
    components;
    rowHeight = 42;
    columnDefs = [];

    defaultColDef = {
        resizable: true,
        singleClickEdit: false,
        headerValueGetter: (parameters: ICellRendererParams): string => {
            const headerIdentifier = parameters.colDef.headerName;
            if (headerIdentifier) {
                return this.translateService.instant(headerIdentifier);
            }
            return '';
        }
    };

    gridOptions = {
        rowSelection: 'multiple',
        pagination: true,
        resizable: true,
        cacheBlockSize: 20,
        maxBlocksInCache: 2,
        enableServerSideFilter: false,
        enableServerSideSorting: false,
        rowModelType: 'infinite',
        paginationPageSize: 5
    };

    paginationPageSize = 10;
    EMPTY_STRING = '--';

    private searchCriteriaSubscription: Subscription = new Subscription();
    private searchResultSubscription: Subscription = new Subscription();
    private moreSearchResultSubscription: Subscription = new Subscription();
    private finalSearchResultsSubscription: Subscription = new Subscription();
    private modelConfirmSubscription: Subscription = new Subscription();

    private buttonState = new BehaviorSubject<ButtonState[]>([]);

    @ViewChild('agGrid') agGrid: AgGridAngular;
    @ViewChild('approvalStatusCell') approvalStatusCell: TemplateRef<any>;
    @ViewChild('resourceCell') resourceCell: TemplateRef<any>;
    @ViewChild('supplierCodeCell') supplierCodeCell: TemplateRef<any>;
    @ViewChild('supplierNameCell') supplierNameCell: TemplateRef<any>;
    @ViewChild('actionCell') actionCell: TemplateRef<any>;

    frameworkComponents = {
        templateRenderer: TemplateRendererComponent,
        actionRenderer: ActionsComponent,
        autoCompleteEditor: AutoCompleteEditorComponent,
        dropDownEditor: DropdownEditorComponent,
        noteEditor: NoteEditorComponent,
        customTextFilter: CustomTextFilterComponent
    };

    colDefConfig: any;
    public PENDING = ApprovalStatus.PENDING;
    public APPROVED = ApprovalStatus.APPROVED;
    public REJECTED = ApprovalStatus.REJECTED;

    ngOnInit() {
        this.tPagination = this.configLoader.configurations.get(TCO.CONF.CONF_PAGINATION_CONFIG).OPERATOR_ACCEPTANCE_SEARCH;
        this.paginationPageSize = this.tPagination.defaultPageResultCount;
        this.colDefConfig = this.userManagementService.updateColDefAccordingToLoggedUserKC(this.configLoader.configurations.get(
            TCO.CONF.CONF_OPERATOR_ACCEPTANCE_QUEUE_COL_DEF));

        this.subscribeSearchCriteria();
        this.subscribeSearchResults();
        this.subscribeOnMoreSearchResults();

        this.subscribeSearchResultList();
        this.keyControlAvailability();
        this.getAvailableBrands();
        this.getAvailableDivisions();
        this.getAvailableDistributionChannels();
    }

    subscribeSearchCriteria() {
        this.searchCriteriaSubscription = this.dataStore.get(DataKey.opeAcceptanceSearchCriteria).subscribe((data) => {
            if (data !== null) {
                this.searchCriteria = data;
            }
        });
    }

    subscribeSearchResults() {
        this.searchResultSubscription = this.dataStore.get(DataKey.opeAcceptanceSearchResultsFromService)
                                            .subscribe((data: OPResponseWrapper<UserServiceProfile>) => {
                                                this.totalNotificationCount = 0;
                                                if (data) {
                                                    this.totalNotificationCount = ResponseUtil.getTotalCount(data);
                                                    const dataArray = ResponseUtil.getDataArray<UserServiceProfile>(
                                                        data);
                                                    this.dataStore.set(
                                                        DataKey.opeAcceptanceSearchResultsForCriteria,
                                                        dataArray,
                                                        true
                                                    );
                                                }
                                            });
    }

    subscribeOnMoreSearchResults() {
        this.moreSearchResultSubscription = this.dataStore.get(DataKey.opeAcceptanceSearchResultsFromServiceOnMore)
                                                .subscribe((data: OPResponseWrapper<UserServiceProfile>) => {
                                                    this.totalNotificationCount = 0;
                                                    if (data) {
                                                        this.totalNotificationCount = ResponseUtil.getTotalCount(data);
                                                        let dataArray = ResponseUtil.getDataArray<UserServiceProfile>(
                                                            data);
                                                        dataArray = this.operatorAcceptanceResults.concat(dataArray);
                                                        this.dataStore.set(
                                                            DataKey.opeAcceptanceSearchResultsForCriteria,
                                                            dataArray,
                                                            true
                                                        );
                                                    }
                                                });
    }

    subscribeSearchResultList() {
        this.fetchedNotificationsCount = 0;
        this.finalSearchResultsSubscription = this.dataStore.get(DataKey.opeAcceptanceSearchResultsForCriteria)
                                                  .subscribe((data: UserServiceProfile[]) => {
                                                      this.operatorAcceptanceResults = [];
                                                      if (data != null) {
                                                          this.spinnerService.hide();

                                                          // **** result counts *** //
                                                          this.operatorAcceptanceResults = data;
                                                          this.fetchedNotificationsCount = this.operatorAcceptanceResults.length;

                                                          // **** custom buttons configuring *** //
                                                          this.paginatorLoaded = true;
                                                          if (this.fetchedNotificationsCount <
                                                              this.totalNotificationCount) {
                                                              this.enableMoreButton();
                                                          } else if (this.fetchedNotificationsCount >=
                                                              this.totalNotificationCount) {
                                                              this.disableMoreButton();
                                                          }

                                                          // **** setup result header *** //
                                                          this.setupResultHeader(this.totalNotificationCount);

                                                      } else {
                                                          // **** clear previous data*** //
                                                          this.operatorAcceptanceResults = [];
                                                          this.resultHeaderHeading = '';
                                                      }

                                                      // **** set genericGroupResults to grid*** //
                                                      this.setGridData();

                                                  });
    }

    // *********************ag grid implementation************************** //

    setGridData() {
        if (this.gridApi && this.operatorAcceptanceResults) {
            this.gridApi.setRowData(this.operatorAcceptanceResults);
            // clear grid sort filter marks on search from criteria component
            if (!this.searchFromSortingOrFiltering) {
                this.sortFilterModelProgrammaticallyChanging = true;
                this.gridApi.setSortModel(null);
                this.gridApi.setFilterModel(null);
            } else {
                this.searchFromSortingOrFiltering = false;
            }
            this.paginatorLoaded = true;
            this.gridApi.sizeColumnsToFit();
        } else {
            setTimeout(() => this.setGridData(), 0);
        }
    }

    onGridReady(params) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.gridApi.setGroupHeaderHeight(40);
        this.gridApi.setHeaderHeight(40);
        this.autoSizeAll();
        this.gridApi.sizeColumnsToFit();
    }

    public ngAfterViewInit(): void {
        this.setColumnDefs();
    }

    private setColumnDefs() {
        const colDefConfig = JSON.parse(JSON.stringify(this.colDefConfig));
        this.columnDefs = this.columnDefinitionProcessorService.createColumnDefs(colDefConfig, this);
        this.cd.detectChanges();
    }

    autoSizeAll() {
        const allColumnIds = [];
        if (this.gridColumnApi) {
            this.gridColumnApi.getAllColumns().forEach(function(column) {
                allColumnIds.push(column.colId);
            });
            this.gridColumnApi.autoSizeColumns(allColumnIds);
        }
    }

    enableMoreButton() {
        const config = [
            {
                buttonId: 'more',
                state: 'enable'
            }
        ];
        this.buttonState.next(config);
    }

    disableMoreButton() {
        const config = [
            {
                buttonId: 'more',
                state: 'disable'
            }
        ];
        this.buttonState.next(config);
    }

    setupResultHeader(count: number) {
        this.resultHeaderHeading = count + ' result' +
            (count > 1 ? 's' : '') + ' found.';
    }

    public onRowSelected($event: any) {
        const selectedRows = this.gridApi.getSelectedRows();
        console.log(selectedRows);
    }

    onSortChanged($event: SortChangedEvent): void {
        if (!this.sortFilterModelProgrammaticallyChanging) {
            this.searchResults();
            this.searchFromSortingOrFiltering = true;
        } else {
            setTimeout(() => this.sortFilterModelProgrammaticallyChanging = false, 100);
        }
    }

    public onFilterChanged($event: any) {
        if (!this.sortFilterModelProgrammaticallyChanging) {
            this.searchResults();
            this.searchFromSortingOrFiltering = true;
        } else {
            setTimeout(() => this.sortFilterModelProgrammaticallyChanging = false, 100);
        }
    }

    searchResults() {
        this.addFilterModelToCriteria();
        this.addSortModelToCriteria();

        this.dataStoreService.set(DataKey.genReservationSearchResultsFromService, null, true);
        // this.genReservationManagementService.searchReservations(this.searchCriteria);
    }

    addFilterModelToCriteria() {
        const filterModelArray = [];
        const filterModel = this.gridApi.getFilterModel();
        Object.keys(filterModel).forEach(key => {
            filterModel[key].colId = key;
            filterModelArray.push(filterModel[key]);
        });
        this.searchCriteria.gridFiltering = JSON.stringify(filterModelArray);
    }

    addSortModelToCriteria() {
        const sortModel = this.gridApi.getSortModel();
        this.searchCriteria.gridSorting = JSON.stringify(sortModel);
    }

    // used in ag grid col def by method reference
    public onApprove(userData: UserServiceProfile) {
        const approvalData = new UserApprovalData();
        approvalData.approvalStatus = ApprovalStatus.APPROVED;
        approvalData.resourceId = userData.resourceSummary ? userData.resourceSummary.resourceId : 0;
        approvalData.supplierId = userData.resourceSummary ? userData.resourceSummary.supplierId : 0;
        approvalData.serviceTypeCode = userData.serviceTypeCode;
        approvalData.kcParent = userData.kcParent;
        approvalData.kcCompany = userData.kcCompany;
        approvalData.kcDivision = userData.kcDivision;
        approvalData.kcBrand = userData.kcBrand;
        approvalData.kcDistributionChannel = userData.kcDistributionChannel;
        this.updateProfileStatus(userData.email, approvalData);
    }

    public onReject(userData: UserServiceProfile) {
        const approvalData: UserApprovalData = new UserApprovalData();
        approvalData.approvalStatus = ApprovalStatus.REJECTED;
        approvalData.resourceId = userData.resourceSummary ? userData.resourceSummary.resourceId : 0;
        approvalData.supplierId = userData.resourceSummary ? userData.resourceSummary.supplierId : 0;
        approvalData.serviceTypeCode = userData.serviceTypeCode;
        approvalData.kcParent = userData.kcParent;
        approvalData.kcCompany = userData.kcCompany;
        approvalData.kcDivision = userData.kcDivision;
        approvalData.kcBrand = userData.kcBrand;
        approvalData.kcDistributionChannel = userData.kcDistributionChannel;
        this.updateProfileStatus(userData.email, approvalData);
    }

    public onPending(userData: UserServiceProfile) {
        const approvalData: UserApprovalData = new UserApprovalData();
        approvalData.approvalStatus = ApprovalStatus.PENDING;
        approvalData.resourceId = userData.resourceSummary ? userData.resourceSummary.resourceId : 0;
        approvalData.supplierId = userData.resourceSummary ? userData.resourceSummary.supplierId : 0;
        approvalData.serviceTypeCode = userData.serviceTypeCode;
        approvalData.kcParent = userData.kcParent;
        approvalData.kcCompany = userData.kcCompany;
        approvalData.kcDivision = userData.kcDivision;
        approvalData.kcBrand = userData.kcBrand;
        approvalData.kcDistributionChannel = userData.kcDistributionChannel;
        this.updateProfileStatus(userData.email, approvalData);
    }

    private updateProfileStatus(userEmail: string, approvalData: UserApprovalData) {
        if (approvalData.kcDivision) {
            const divisions = this.getDivisions();
            if (divisions) {
                divisions.forEach(value => {
                    if (value.name === approvalData.kcDivision) {
                        approvalData.kcDivision = value.code;
                    }
                });
            }
        }

        if (approvalData.kcBrand) {
            const brands = this.getBrands();
            if (brands) {
                brands.forEach(value => {
                    if (value.name === approvalData.kcBrand) {
                        approvalData.kcBrand = value.code;
                    }
                });
            }
        }

        if (approvalData.kcDistributionChannel) {
            const distributionChannels = this.getDistributionChannels();
            if (distributionChannels) {
                distributionChannels.forEach(value => {
                    if (value.name === approvalData.kcDistributionChannel) {
                        approvalData.kcDistributionChannel = value.code;
                    }
                });
            }
        }
        this.userProfileService.updateResourceStatus(userEmail, approvalData).subscribe(result => {
            if (this.commonHelper.dataValidity(result)) {
                if (ResponseUtil.isSuccess(result)) {
                    this.common.showSnackBar(
                        'Successfully updated the status.',
                        3000,
                        TcErrorType.TYPE.INFO
                    );
                    // search data
                    this.userProfileService.getOperatorServiceProfiles(this.searchCriteria);
                    this.searchPendingResourceAcceptanceData();
                } else {
                    this.common.showSnackBar(
                        'Failed to update the status.',
                        3000,
                        TcErrorType.TYPE.ERROR
                    );
                }

            }
        });
    }

    searchPendingResourceAcceptanceData(): any {
        this.userProfileService.getPendingResourceAcceptanceData();
    }

    onGridSizeChange($event) {
        if (this.gridApi) {
            this.gridApi.sizeColumnsToFit();
        }
        // this.gridApi.autoSizeAllColumns();
    }

    openResourceProfile(data: UserServiceProfile) {
        if (data && data.resourceId) {
            this.genericResourceService.retrieveResourceById(data.resourceId).subscribe(value => {
                if (this.commonHelper.dataValidity(value)) {
                    const detailedResource = ResponseUtil.getFirstData(value);
                    if (data.serviceTypeCode === ResourceType.vehicle) {
                        this.userJourneyManager.goForKey('GO_TO_EDIT_VEHICLE');
                        this.dataStore.set(DataKey.selectedVehicleForEdit, detailedResource, true);
                    } else {
                        this.userJourneyManager.goForKey('GO_TO_EDIT_GENERIC_RESOURCE');
                        this.dataStore.set(DataKey.selectedGenericResourceForEdit, detailedResource, true);
                    }

                }
            });
        } else {
            const resource = new Resource();
            resource.resourceType = data.serviceType;

            if (data.serviceTypeCode === ResourceType.vehicle) {
                resource.vehicle = new Vehicle();
                resource.vehicle.vehicleName = data.firstName + ' ' + data.lastName;
                this.userJourneyManager.goForKey('GO_TO_CREATE_VEHICLE');
                this.dataStore.set(DataKey.selectedVehicleForEditAsNew, resource, true);
            } else {
                resource.genericResource = new GenericResource();
                resource.genericResource.firstName = data.firstName;
                resource.genericResource.lastName = data.lastName;
                this.userJourneyManager.goForKey('GO_TO_CREATE_GENERIC');
                this.dataStore.set(DataKey.selectedGenericResourceForEditAsNew, resource, true);
            }
        }
    }

    getResources = function(params, rowData, text) {
        return this.onResourceTextChange(params, rowData, text);
    };

    public onResourceTextChange(params: any, rowData: UserServiceProfile, text: string): Observable<any> {
        if (rowData.serviceTypeCode === ResourceType.vehicle) {
            const vehicleSearchCriteria = new VehicleSearchCriteria();
            vehicleSearchCriteria.vehicleName = text;
            vehicleSearchCriteria.activeStatus = true;
            vehicleSearchCriteria.resourceType = rowData.serviceTypeCode;
            if (rowData && rowData.supplierId) {
                vehicleSearchCriteria.supplierId = rowData.supplierId;
            }

            return this.transportResourceService.searchVehiclesSummaryNoDataKey(vehicleSearchCriteria)
                       .pipe(
                           map(
                               response => {
                                   let resources: any[] = [];
                                   resources = ResponseUtil.getDataArray(response);
                                   return resources;
                               }), catchError(
                               error => {
                                   return [];
                               }
                           )
                       );
        } else {
            const genericSearchCriteria = new GenericSearchCriteria();
            genericSearchCriteria.name = text;
            genericSearchCriteria.activeStatus = 'true';
            genericSearchCriteria.resourceType = rowData.serviceTypeCode;
            if (rowData && rowData.supplierId) {
                genericSearchCriteria.supplierId = rowData.supplierId;
            }

            return this.genericResourceService.searchGenericResourceWithoutDataKey(genericSearchCriteria)
                       .pipe(
                           map(
                               response => {
                                   let resources: any[] = [];
                                   resources = ResponseUtil.getDataArray(response);

                                   // let clearResource = new GenericResourceSummary();
                                   // clearResource.firstName = '-- CLEAR --';
                                   // clearResource.nullResourceForClearing = true;
                                   // if (!resources.find(value => value.firstName === clearResource.firstName)) {
                                   //     resources.unshift(clearResource);
                                   // }
                                   return resources;
                               }), catchError(
                               error => {
                                   return [];
                               }
                           )
                       );
        }

    }

    isRejectResourceRestricted(serviceProfile: UserServiceProfile) {
        return serviceProfile.approvalStatus === ApprovalStatus.REJECTED;
    }

    isApproveResourceRestricted(serviceProfile: UserServiceProfile) {
        return serviceProfile.approvalStatus === ApprovalStatus.APPROVED || serviceProfile.kcCompany === null;
    }

    // used in ag grid col def by method reference
    onSetResource = function(params) {
        const resource: any = params.newValue;
        const resourceAcceptanceData: UserServiceProfile = params.data;
        resourceAcceptanceData.resourceSummary = resource;
        if (resource) {
            resourceAcceptanceData.resourceId = resource.resourceId;
        }
        if (params.newValue && params.newValue.resourceType === ResourceType.vehicle) {
            resourceAcceptanceData.resourceSummary.resourceName = resource.vehicleName;
            resourceAcceptanceData.resourceSummary.name = resource.vehicleName;
        } else {
            resourceAcceptanceData.resourceSummary.name = resource.firstName + ' ' + resource.lastName;
        }

        return true;
    };

    public keyControlAvailability() {
        this.userProfile = this.dataStoreService.get(DataKey.userProfile).getValue();
        this.hasABrand = this.userProfile !== null && this.userProfile !== undefined && this.userProfile.kcBrand !==
            null && this.userProfile.kcBrand !== undefined;
        this.hasADivision = this.userProfile !== null && this.userProfile !== undefined &&
            this.userProfile.kcDivision !== null && this.userProfile.kcDivision !== undefined;
        this.hasADistributionChannel = this.userProfile !== null && this.userProfile !== undefined &&
            this.userProfile.kcDistributionChannel !== null && this.userProfile.kcDistributionChannel !== undefined;
    }

    isEditableBrandRow = function(params: any) {
        return !this.hasABrand && (params && params.data && params.data.approvalStatus && params.data.approvalStatus !==
            ApprovalStatus.APPROVED && params.data.approvalStatus !== ApprovalStatus.REJECTED);
    };

    isEditableDivisionRow = function(params: any) {
        return !this.hasADivision &&
            (params && params.data && params.data.approvalStatus && params.data.approvalStatus !==
                ApprovalStatus.APPROVED && params.data.approvalStatus !== ApprovalStatus.REJECTED);
    };

    isEditableDistributionChannelRow = function(params: any) {
        return !this.hasADistributionChannel &&
            (params && params.data && params.data.approvalStatus && params.data.approvalStatus !==
                ApprovalStatus.APPROVED && params.data.approvalStatus !== ApprovalStatus.REJECTED);
    };

    editCellValue(node: any) {
        if (node && node.data != null && node.newValue !== node.oldValue) {
            this.onPending(node.data);
        }
    }

    public updateUserKC(user: any) {
        this.userProfileService.updateUserProfileKC(user).subscribe(res => {
            if (res) {
                this.userProfileService.getOperatorServiceProfiles(this.searchCriteria);
            }
        });
    }

    getServiceBookingQueuePrimarySupplier = (params: any) => {
        if (params.data) {
            return params.data.primaryAssignment.supplierName;
        } else {
            return this.EMPTY_STRING;
        }
    };

    displayKcCompanyInEditor = (params: any) => {
        let channel = 'Any';
        if (params.data && params.data.kcCompany && params.data.kcCompany.name) {
            return params.data.kcCompany.name;
        } else if (params.data && params.data.kcCompany && !params.data.kcCompany.name) {
            this.dataStoreService.get(DataKey.kcCompanies).subscribe((data) => {
                data.forEach(company => {
                    if (company && company.code === params.data.kcCompany) {
                        channel = company.name;
                    }
                });
            });
            return channel;
        } else {
            return channel;
        }
    };

    displayKcDistributionChannelInEditor = (params: any) => {
        let channel = 'Any';
        if (params.data && params.data.kcDistributionChannel && params.data.kcDistributionChannel.name) {
            return params.data.kcDistributionChannel.name;
        } else if (params.data && params.data.kcDistributionChannel && !params.data.kcDistributionChannel.name) {
            this.dataStoreService.get(DataKey.kcDistributionChannelList).subscribe((data) => {
                data.forEach(disChannel => {
                    if (disChannel && disChannel.code === params.data.kcDistributionChannel) {
                        channel = disChannel.name;
                    }
                });
            });
            return channel;
        } else {
            return channel;
        }
    };

    displayKcDivisionInEditor = (params: any) => {
        let division = 'Any';
        if (params.data && params.data.kcDivision && params.data.kcDivision.name) {
            return params.data.kcDivision.name;
        } else if (params.data && params.data.kcDivision && !params.data.kcDivision.name) {
            this.dataStoreService.get(DataKey.kcDivisions).subscribe((data) => {
                data.forEach(div => {
                    if (div && div.code === params.data.kcDivision) {
                        division = div.name;
                    }
                });
            });
            return division;
        } else {
            return division;
        }
    };

    displayKcBrandInEditor = (params: any) => {
        let kcBrand = 'Any';
        if (params.data && params.data.kcBrand && params.data.kcBrand.name) {
            return params.data.kcBrand.name;
        } else if (params.data && params.data.kcBrand && !params.data.kcBrand.name) {
            this.dataStoreService.get(DataKey.kcBrandsList).subscribe((data) => {
                data.forEach(brand => {
                    if (brand && brand.code === params.data.kcBrand) {
                        kcBrand = brand.name;
                    }
                });
            });
            return kcBrand;
        } else {
            return kcBrand;
        }
    };

    onSetBrand = (params) => {
        if (params && params.newValue && params.newValue.name) {
            params.data.kcBrand = params.newValue.code;
            params.data.kcBrandName = params.newValue.name;
        } else if (params && params.newValue && !params.newValue.name) {
            params.data.kcBrand = params.newValue;
        }
    };

    onSetDivision = (params) => {
        if (params && params.newValue && params.newValue.name) {
            params.data.kcDivision = params.newValue.code;
            params.data.kcDivisionName = params.newValue.name;
        } else if (params && params.newValue && !params.newValue.name) {
            params.data.kcDivision = params.newValue;
        }
    };

    onSetDistributionChannel = (params) => {
        if (params && params.newValue && params.newValue.name) {
            params.data.kcDistributionChannel = params.newValue.code;
            params.data.kcDistributionChannelName = params.newValue.name;
        } else if (params && params.newValue && !params.newValue.name) {
            params.data.kcDistributionChannel = params.newValue;
        }
    };

    getAvailableBrands() {
        let brandSearchCriteria = new BrandSearchCriteria();
        brandSearchCriteria = this.userManagementService.updateSearchCriteriaWithUserKC(brandSearchCriteria);
        const brands = [];
        // this.brandSetupService.getRows(brandSearchCriteria).subscribe(
        //     result => {
        //         if (result) {
        //             this.dataStoreService.set(DataKey.kcBrands, result.data);
        //         }
        //     });
    }

    getAvailableDivisions() {
        let divisionSearchCriteria = new DivisionSearchCriteria();
        divisionSearchCriteria = this.userManagementService.updateSearchCriteriaWithUserKC(divisionSearchCriteria);
        const brands = [];
        // this.divisionSetupService.getRows(divisionSearchCriteria).subscribe(
        //     result => {
        //         if (result) {
        //             this.dataStoreService.set(DataKey.kcDivisions, result.data);
        //         }
        //     });
    }

    getAvailableDistributionChannels() {
        let distributionChannelSearchCriteria = new DistributionChannelSearchCriteria();
        distributionChannelSearchCriteria = this.userManagementService.updateSearchCriteriaWithUserKC(
            distributionChannelSearchCriteria);
        const brands = [];
        // this.distributionChannelSetupService.getRows(distributionChannelSearchCriteria).subscribe(
        //     result => {
        //         if (result) {
        //             this.dataStoreService.set(DataKey.kcDistributionChannels, result.data);
        //         }
        //     });
    }

    getCompany() {
        let codeNames = new Set();
        const kcBrand = this.dataStoreService.get(DataKey.kcCompanies).subscribe((data) => {
            if (data) {
                codeNames = data;
            }
        });
        return codeNames;
    }

    getBrands() {
        let codeNames = new Set();
        const kcBrand = this.dataStoreService.get(DataKey.kcBrandsList).subscribe((data) => {
            if (data) {
                codeNames = data;
            }
        });
        return codeNames;
    }

    getDivisions() {
        let codeNames = new Set();
        const kcDivision = this.dataStoreService.get(DataKey.kcDivisions).subscribe((data) => {
            if (data) {
                codeNames = data;
            }
        });
        return codeNames;

    }

    getDistributionChannels() {
        let codeNames = new Set();
        const kcDistributionChannel = this.dataStoreService.get(DataKey.kcDistributionChannelList).subscribe((data) => {
            if (data) {
                codeNames = data;
            }
        });
        return codeNames;
    }

    public ngOnDestroy(): void {
        if (this.searchCriteriaSubscription) {
            this.searchCriteriaSubscription.unsubscribe();
        }
        if (this.searchResultSubscription) {
            this.searchResultSubscription.unsubscribe();
        }
        if (this.moreSearchResultSubscription) {
            this.moreSearchResultSubscription.unsubscribe();
        }
        if (this.finalSearchResultsSubscription) {
            this.finalSearchResultsSubscription.unsubscribe();
        }
        if (this.modelConfirmSubscription) {
            this.modelConfirmSubscription.unsubscribe();
        }
    }

    private confirmModal(data: any): void {
        if (this.modelConfirmSubscription) {
            this.modelConfirmSubscription.unsubscribe();
        }
        this.modelConfirmSubscription = this.modalService.confirm(data).subscribe((res) => {
        });
    }

    onClickRoles(row: any) {
        this.confirmModal(
            new ModalData(
                LEVEL.SUCCESS,
                'Select Role',
                null,
                null,
                'modal-basic--alt modal-basic--fixed-size',
                UserRolesEditorComponent,
                {}
            )
        );
    }
}
