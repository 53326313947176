<div
        class="groupmaster-wholeseller-additional-contacts-edit-card"
        tcExpansionPanel
        [include]="['mat-expansion-panel', 'mat-option']"
        [enabled]="taxSchemeList && taxSchemeList.length>0"
        [id]="'tax_scheme_summary_strip'"
        *ngIf="!forAResourceProfile">
    <tc-summary-strip
            *tcExpansionPanelSummary
            [summaryContent]="taxSchemeSummary"
            class="gwace-summary-strip-wrapper">
    </tc-summary-strip>

    <div
            *tcExpansionPanelDetail>
        <mat-accordion
                *ngIf="taxSchemeList && taxSchemeList.length>0"
                [multi]=false
                class="additional-contacts-list-wrapper">
            <ng-container *ngFor="let taxschm of taxSchemeList; index as i">
                <mat-expansion-panel
                    #expPanel
                    [attr.id]="'expansionPanel-'+i"
                    [hideToggle]="hideToggle">
                    <mat-expansion-panel-header [collapsedHeight]="'48px'" [expandedHeight]="'60px'">
                        <tc-tax-scheme-summary-card
                                class="card-strip"
                                [hidden]="expPanel.expanded"
                                [taxSchemeContent]="taxSchemeContents[i]"
                                [isEditable]="true"
                                [taxSchemeMap]="taxSchemes"
                                [nameCodeMap]="nameCodeMap"
                                [taxSchemeData]="taxschm"
                                [showIcons]="selectedIndex === index"
                                [supplierItems]="supplierItems"
                                (mouseleave)="hideIcons(index)"
                                (mouseenter)="showIcons()"
                                (deleteTaxScheme)="onDelete($event)"
                                class="gwacs-edit-card-wrapper">
                        </tc-tax-scheme-summary-card>

                        <div [hidden]="!expPanel.expanded" class="expand-heading">
                            <tc-tax-scheme-summary-card
                                    [isEditable]="true"
                                    [taxSchemeContent]="taxSchemeContents[i]"
                                    [taxSchemeData]="taxschm"
                                    [isHeader]="true"
                                    [supplierItems]="supplierItems"
                                    (deleteTaxScheme)="onDelete($event)"
                                    class="groupmaster-wholesaler-additional-contact-summary-card">
                            </tc-tax-scheme-summary-card>
                        </div>

                    </mat-expansion-panel-header>
                    <tc-tax-scheme-edit-card
                            [taxSchemeData]="taxschm"
                            [taxSchemesMD]="taxSchemes"
                            [taxItems]="supplierItems"
                            (changeFormValidity)="checkValidity(taxschm, $event)">

                    </tc-tax-scheme-edit-card>
                </mat-expansion-panel>
                <hr/>
            </ng-container>
        </mat-accordion>
    </div>
</div>
<div [id]="'tax_scheme_summary_strip'" *ngIf="taxSchemeList && forAResourceProfile">
    <tc-tax-scheme-edit-card
            [forAResourceProfile]="forAResourceProfile"
            [taxSchemeData]="taxSchemeList[0]"
            [taxSchemesMD]="taxSchemes"
            (changeFormValidity)="checkValidity(taxSchemeList[0], $event)">
    </tc-tax-scheme-edit-card>
</div>
