import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatExpansionModule } from '@angular/material';
import { TranslateModule } from '@ngx-translate/core';
import { ContentStripModule } from '@tc/content-strip';
import { DirectivesModule } from '@tc/directives';
import { ExpandedContentCardBodyModule } from '@tc/expanded-content-card-body';
import { PipesModule } from '@tc/pipes';
import { SummaryStripModule } from '@tc/summary-strip';
import { RoomGrpEditCardModule } from '../../../business/profile-management/supplier/res-collection/room-grp-edit-card/room-grp-edit-card.module';
import { RoomGrpSummaryCardModule } from '../../../business/profile-management/supplier/res-collection/room-grp-summary-card/room-grp-summary-card.module';
import { ResultContentCardModule } from '../result-content-card/result-content-card.module';
import { TaxSchemeEditCardModule } from '../tax-scheme-edit-card/tax-scheme-edit-card.module';
import { RoomsComponent } from './rooms.component';

@NgModule({
  imports: [
    CommonModule,
      TranslateModule.forChild(),
      PipesModule,
      ExpandedContentCardBodyModule,
      MatExpansionModule,
      ContentStripModule,
      DirectivesModule,
      SummaryStripModule,
      ResultContentCardModule,
      RoomGrpSummaryCardModule,
      TaxSchemeEditCardModule,
      RoomGrpEditCardModule
  ],
    exports: [RoomsComponent],
    declarations: [RoomsComponent]
})
export class RoomsModule { }
