import * as tslib_1 from "tslib";
import { OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LEVEL } from '@tc-core/model/it/codegen/ui/framework/dialog-model';
import { ModalData } from '@tc-core/model/it/codegen/ui/framework/modal-data';
import { TC } from '@tc-core/util';
import { ConfigLoader } from '@tc-core/util/framework';
import { FocusViewService } from '@tc/focus-view/focus-view.service';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SetupGridComp } from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid-comp';
import { SetupGridComponent } from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid.component';
import { TCO } from '../../../constants';
import { DispatchStatus } from '../../../models/document/dispatch-entry';
import { SortDirection } from '../../../models/helper/sort-direction';
import { DocumentService } from '../../../services/backend-consumers/documents/document.service';
import { DataHandlerService } from '../../../services/backend-consumers/master-data-handler-service/data-handler.service';
import { DataKey, DataStoreService } from '../../../services/util/framework/data-store.service';
import { DocumentDetailFocusViewComponent } from '../document-detail-focus-view/document-detail-focus-view.component';
var EMPTY_CHAR = TC.FORMAT.EMPTY_CHAR;
var DocumentsSearchResultsComponent = /** @class */ (function (_super) {
    tslib_1.__extends(DocumentsSearchResultsComponent, _super);
    function DocumentsSearchResultsComponent(configLoader, dataStoreService, dataHandlerService, documentService, activatedRoute, focusViewService) {
        var _this = _super.call(this) || this;
        _this.configLoader = configLoader;
        _this.dataStoreService = dataStoreService;
        _this.dataHandlerService = dataHandlerService;
        _this.documentService = documentService;
        _this.activatedRoute = activatedRoute;
        _this.focusViewService = focusViewService;
        _this.colDefConfig = [];
        // required options for grid editors
        // nothing
        _this.gridActions = [];
        _this.searchCriteriaObserver = new Subscription();
        _this.focusViewDataChangeObserver = new Subscription();
        _this.focusViewConfirmObserver = new Subscription();
        _this.focusViewCloseObserver = new Subscription();
        _this.routeDataSubscription = new Subscription();
        _this.routeParamsSubscription = new Subscription();
        _this.routeEventSubscription = new Subscription();
        _this.focusCloseSubscription = new Subscription();
        _this.isFocusViewDataChanged = false;
        /*
         grid config methods which are called by grid setup (ag grid)
         and set by grid config
         */
        _this.getTypeName = function (params) {
            if (params && params.data && params.data.documentTypeLabel) {
                return params.data.documentTypeLabel;
            }
            else {
                return EMPTY_CHAR;
            }
        };
        _this.statusPending = function (params) {
            return params.data && params.data.recentDispatchEntry && params.data.recentDispatchEntry.dispatchStatus &&
                (params.data.recentDispatchEntry.dispatchStatus ===
                    DispatchStatus.PENDING || params.data.recentDispatchEntry.dispatchStatus ===
                    DispatchStatus.DOC_QUEUE_PENDING);
        };
        _this.statusSuccess = function (params) {
            return params.data && params.data.recentDispatchEntry && params.data.recentDispatchEntry.dispatchStatus &&
                params.data.recentDispatchEntry.dispatchStatus ===
                    DispatchStatus.SUCCESS;
        };
        _this.statusFailed = function (params) {
            return params.data && params.data.recentDispatchEntry && params.data.recentDispatchEntry.dispatchStatus
                && (params.data.recentDispatchEntry.dispatchStatus === DispatchStatus.FAILED
                    || params.data.recentDispatchEntry.dispatchStatus === DispatchStatus.INVALID);
        };
        _this.getSupplierNameFromManifest = function (params) {
            if (params && params.data && params.data.supplierManifest) {
                return params.data.supplierManifest.supplierName;
            }
            else {
                return EMPTY_CHAR;
            }
        };
        _this.getUserName = function (params) {
            if (params && params.data && params.data.userName) {
                return params.data.systemGenerated ? 'System' : params.data.userName;
            }
            else if (params && params.data && params.data.systemGenerated) {
                return 'System';
            }
            else {
                return EMPTY_CHAR;
            }
        };
        _this.getSupplierCodeFromManifest = function (params) {
            if (params && params.data && params.data.supplierManifest) {
                return params.data.supplierManifest.supplierCode;
            }
            else {
                return EMPTY_CHAR;
            }
        };
        _this.clickDownloadAction = function (params) {
            console.log('click download action');
            console.log(params);
            var documentId = params.data ? params.data.documentId : -1;
            if (documentId !== -1) {
                _this.documentService.downloadDocument(documentId);
            }
        };
        return _this;
    }
    DocumentsSearchResultsComponent.prototype.ngOnInit = function () {
        this.subscribeSearchCriteria();
        this.fillRequiredGridOptionData();
        this.addGridActions();
    };
    /**
     * subscribe search criteria changes in search bar
     * then force grid to refresh data
     * grid refresh will be call getRaws method which is using this subscribed criteria for backend call
     */
    DocumentsSearchResultsComponent.prototype.subscribeSearchCriteria = function () {
        var _this = this;
        this.searchCriteriaObserver = this.dataStoreService.get(DataKey.documentSearchCriteria)
            .subscribe(function (value) {
            _this.documentSearchCriteria = value;
            if (_this.documentSearchCriteria) {
                _this.setupGrid.runForceSearch();
            }
        });
    };
    DocumentsSearchResultsComponent.prototype.fillRequiredGridOptionData = function () {
        // nothing
    };
    DocumentsSearchResultsComponent.prototype.isInvalidRow = function (params) {
        return false;
    };
    DocumentsSearchResultsComponent.prototype.createNewRow = function () {
        return { unsaved: true };
    };
    DocumentsSearchResultsComponent.prototype.isUnsavedRow = function (row) {
        return !!row.unsaved;
    };
    DocumentsSearchResultsComponent.prototype.deleteRow = function (row) {
        return null;
    };
    DocumentsSearchResultsComponent.prototype.saveRow = function (row, event) {
        return null;
    };
    DocumentsSearchResultsComponent.prototype.getRows = function (params) {
        var _this = this;
        console.log('fetch requesting for ' + params.startRow + ' to ' + params.endRow);
        var pageSize = params.endRow - params.startRow;
        this.documentSearchCriteria.start = params.startRow;
        this.documentSearchCriteria.size = pageSize;
        if (params.sortModel && params.sortModel[0]) {
            this.documentSearchCriteria.sortBy = params.sortModel[0].colId;
            if (params.sortModel[0].sort === SetupGridComp.GRID_SORT_ASCENDING) {
                this.documentSearchCriteria.sortDirection = SortDirection.ASC;
            }
            else {
                this.documentSearchCriteria.sortDirection = SortDirection.DESC;
            }
        }
        return this.documentService.searchDocuments(this.documentSearchCriteria)
            .pipe(tap(function (data) { return _this.dataStoreService.set(DataKey.documentSearchResultsForCriteria, data); }));
    };
    DocumentsSearchResultsComponent.prototype.ngOnDestroy = function () {
        if (this.searchCriteriaObserver) {
            this.searchCriteriaObserver.unsubscribe();
        }
        if (this.focusViewDataChangeObserver) {
            this.focusViewDataChangeObserver.unsubscribe();
        }
        if (this.focusViewConfirmObserver) {
            this.focusViewConfirmObserver.unsubscribe();
        }
        if (this.focusViewCloseObserver) {
            this.focusViewCloseObserver.unsubscribe();
        }
        if (this.routeDataSubscription) {
            this.routeDataSubscription.unsubscribe();
        }
        if (this.routeParamsSubscription) {
            this.routeParamsSubscription.unsubscribe();
        }
        if (this.routeEventSubscription) {
            this.routeEventSubscription.unsubscribe();
        }
        if (this.focusCloseSubscription) {
            this.focusCloseSubscription.unsubscribe();
        }
    };
    DocumentsSearchResultsComponent.prototype.onRowDoubleClicked = function (event) {
        console.log('Row clicked');
        this.onViewDocumentDetails(event.data);
    };
    DocumentsSearchResultsComponent.prototype.onViewDocumentDetails = function (data) {
        var _this = this;
        this.focusViewDataObserver();
        var fabActions = [];
        var navItemsArray = this.configLoader.configurations.get(TCO.CONF.CONF_NAVIGATION);
        this.configLoader.configurations.get('FOCUS_VIEW_CONFIG').DOCUMENT_FOCUS_VIEW.fabAction
            .forEach(function (val) {
            for (var key in navItemsArray) {
                if (val === navItemsArray[key].id) {
                    fabActions.push(navItemsArray[key]);
                }
            }
        });
        var dataObject = new ModalData(LEVEL.SUCCESS, 'Document Details', null, null, 'grid-focus-view', DocumentDetailFocusViewComponent, {
            document: data,
            defaultSupplierCode: data.supplierManifest.supplierCode
        }, { label: 'close' }, '', '', fabActions, null, 'Name : ' + data.name);
        dataObject.disableClose = true;
        this.focusViewConfirmObserver = this.focusViewService.confirm(dataObject)
            .subscribe(function (res) {
            if (_this.isFocusViewDataChanged) {
                // after view closed
                _this.setupGrid.runForceSearch();
            }
        });
    };
    DocumentsSearchResultsComponent.prototype.focusViewDataObserver = function () {
        var _this = this;
        this.focusViewDataChangeObserver = this.dataStoreService.get(DataKey.docDetailsFocusViewDataChanged)
            .subscribe(function (isDataChanged) {
            if (isDataChanged) {
                _this.focusViewDataChangeObserver.unsubscribe();
            }
            _this.isFocusViewDataChanged = isDataChanged;
        });
    };
    DocumentsSearchResultsComponent.prototype.closeFocusView = function () {
        if (this.focusViewService.close()) {
            this.focusCloseSubscription = this.focusViewService.close().subscribe(function (res) {
            });
        }
    };
    DocumentsSearchResultsComponent.prototype.addGridActions = function () {
        this.gridActions.push({
            icon: 'get_app',
            action: this.clickDownloadAction.bind(this),
            disable: false,
            tooltip: 'Download'
        });
    };
    /**
     *  This method use to get the current dispatch status of a data grid data row
     * @param originalDisplayStatus - raw value from backend
     * @return - if INVALID status has passed it should display as FAILED
     */
    DocumentsSearchResultsComponent.prototype.getDisplayDispatchEntry = function (originalDisplayStatus) {
        if (originalDisplayStatus === DispatchStatus.INVALID || originalDisplayStatus === DispatchStatus.DOC_QUEUE_PENDING) {
            return DispatchStatus.FAILED;
        }
        return originalDisplayStatus;
    };
    return DocumentsSearchResultsComponent;
}(SetupGridComp));
export { DocumentsSearchResultsComponent };
