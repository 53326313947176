<div class="focus-view__content-wrapper">
  <div class="container-fluid">
    <div class="assign-panel-search">
      <tc-supplier-allocation-search-criteria
      [inputSearchCriteria]="supplierSearchCriteria"
      [isTriggerSearch]="true"
      [placeHolderText]="placeHolderText"
      [isSearchBarStyleNotAvailable]="true">
      </tc-supplier-allocation-search-criteria>
    </div>
  </div>

  <div class="container-fluid">
    <tc-result-header
            [transferModeList]="[]"
            [groupingOptionList]="[]"
            [heading]="heading"
            [sorters]="sorters_"
            (selectSorter)="onSort($event)">
    </tc-result-header>
  </div>

  <div class="container-fluid">
    <tc-adv-supplier-allocation-search-results
    [resultsList]="contentCardDataList"
    [pager]="pager"
    [paxCount]="paxCount"
    (supplierSelect)="onSupplierSelect($event)">
    </tc-adv-supplier-allocation-search-results>
  </div>
  <tc-paginator
          *ngIf="contentCardDataList && contentCardDataList.length > defaultPageResultCount"
          [length]="contentCardDataList.length"
          [index]="pager.currPage"
          [size]="pager.itemsPerPage"
          [hasMore]="hasMore"
          (page)="onPage($event)"
          (more)="onMore()">
  </tc-paginator>

</div>
