import * as tslib_1 from "tslib";
import { ChangeDetectorRef, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TcApiError } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-api-error';
import { TcHttpError } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-http-error';
import { LEVEL } from '@tc-core/model/it/codegen/ui/framework/dialog-model';
import { ModalData } from '@tc-core/model/it/codegen/ui/framework/modal-data';
import { ConfigLoader, EventManager } from '@tc-core/util/framework';
import { UserJourneyManager } from '@tc-core/util/framework/user-journey-manager.service';
import { CommonHelper } from '@tc-core/util/helpers/common-helper.service';
import { SpinnerService } from '@tc-core/util/ui';
import { FocusViewService } from '@tc/focus-view/focus-view.service';
import * as moment from 'moment';
import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SetupGridComp } from '../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid-comp';
import { SetupGridComponent } from '../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid.component';
import { TCO } from '../../constants';
import { NotificationSearchCriteria } from '../../models/criteria/notification-search-criteria';
import { SortDirection } from '../../models/helper/sort-direction';
import { ActionString, NotificationType, TypeString } from '../../models/notification/notification-data';
import { NotificationStateChangeData } from '../../models/notification/notification-state-change-data';
import { ProductQueueSearchCriteria } from '../../models/reservation-v2/criteria/product-queue-search-criteria';
import { ResourceType } from '../../models/reservation/assignment';
import { AssignmentStatus, StatusCodes } from '../../models/reservation/assignment-status';
import { NotificationService } from '../../services/backend-consumers/notifications/notification.service';
import { GenericResourceService } from '../../services/backend-consumers/supplier-service/generic-resource.service';
import { DataKey, DataStoreService } from '../../services/util/framework/data-store.service';
import { GridColumnDefinitionProcessorService } from '../../services/util/pre-processors/grid-column-definition-processor.service';
import { ResponseUtil } from '../../services/util/response/response-util.service';
import { DMCLocalStorageService } from '../../services/util/system/dmc-local-storage.service';
import { ResAvailabilityCalendarFocusViewComponent } from '../../widgets/shared/res-availability-calendar-focus-view/res-availability-calendar-focus-view.component';
var NotificationQueueResultsComponent = /** @class */ (function (_super) {
    tslib_1.__extends(NotificationQueueResultsComponent, _super);
    function NotificationQueueResultsComponent(cd, columnDefinitionProcessorService, em, configLoader, dataStore, spinnerService, notificationService, genericResourceService, commonHelper, focusViewService, localStorageService, userJourneyManager, translateService) {
        var _this = _super.call(this) || this;
        _this.cd = cd;
        _this.columnDefinitionProcessorService = columnDefinitionProcessorService;
        _this.em = em;
        _this.configLoader = configLoader;
        _this.dataStore = dataStore;
        _this.spinnerService = spinnerService;
        _this.notificationService = notificationService;
        _this.genericResourceService = genericResourceService;
        _this.commonHelper = commonHelper;
        _this.focusViewService = focusViewService;
        _this.localStorageService = localStorageService;
        _this.userJourneyManager = userJourneyManager;
        _this.translateService = translateService;
        _this.colDefConfig = [];
        _this.defaultColDef = {
            filter: false,
            sortable: false,
            resizable: false,
            headerValueGetter: function (parameters) {
                var headerIdentifier = parameters.colDef.headerName;
                if (headerIdentifier) {
                    return _this.translateService.instant(headerIdentifier);
                }
                return '';
            }
        };
        _this.headerActions = [];
        _this.searchCriteria = new NotificationSearchCriteria();
        _this.gridActions = [];
        _this.typeString = TypeString;
        _this.actionString = ActionString;
        _this.ASSIGNMENT_UPDATE = NotificationType.ASSIGNMENT_UPDATE;
        _this.LEAVE_REQUEST = NotificationType.LEAVE_REQUEST;
        _this.NEW_USER_REGISTRATION = NotificationType.NEW_USER_REGISTRATION;
        _this.RESOURCE_COMMUNICATION = NotificationType.RESOURCE_COMMUNICATION;
        _this.PENDING = AssignmentStatus.PENDING;
        _this.DMC_CONFIRM = AssignmentStatus.DMC_CONFIRM;
        _this.REQUEST_EXPIRED = AssignmentStatus.REQUEST_EXPIRED;
        _this.SUPPLIER_CONFIRMED = AssignmentStatus.SUPPLIER_CONFIRMED;
        _this.SUPPLIER_REJECTED = AssignmentStatus.SUPPLIER_REJECTED;
        _this.BOOKING_UPDATE = AssignmentStatus.BOOKING_UPDATE;
        _this.INACTIVE = AssignmentStatus.INACTIVE;
        _this.INACTIVE_NOTIFY = AssignmentStatus.INACTIVE_NOTIFY;
        _this.NO_SHOW = AssignmentStatus.NO_SHOW;
        _this.STARTED = AssignmentStatus.STARTED;
        _this.COMPLETED = AssignmentStatus.COMPLETED;
        _this.statusCodes = StatusCodes;
        _this.toolTipPosition = 'below';
        _this.navConfig = [];
        _this.searchCriteriaSubscription = new Subscription();
        _this.newNotificationResultsSubscription = new Subscription();
        _this.focusViewCloseSubject = new Subscription();
        _this.rowClassRules = {
            'tc-ag-grid-row--error': function (params) { return !params || !params.data || !params.data.readStatus; }
        };
        _this.processResourceId = function (params) {
            return params && params.data && params.data.resourceId > 0 ? params.data.resourceId : '';
        };
        return _this;
    }
    NotificationQueueResultsComponent.prototype.ngOnInit = function () {
        this.colDefConfig = this.configLoader.configurations.get(TCO.CONF.CONF_NOTIFICATION_QUEUE_COL_DEF);
        this.subscribeSearchCriteria();
        this.getNewNotifications();
    };
    NotificationQueueResultsComponent.prototype.subscribeSearchCriteria = function () {
        var _this = this;
        this.searchCriteriaSubscription = this.dataStore.get(DataKey.notificationSearchCriteria).subscribe(function (data) {
            if (data !== null) {
                _this.searchCriteria = data;
                _this.setupGrid.runForceSearch();
            }
        });
    };
    NotificationQueueResultsComponent.prototype.isInvalidRow = function (params) {
        return false;
    };
    NotificationQueueResultsComponent.prototype.createNewRow = function () {
        return null;
    };
    NotificationQueueResultsComponent.prototype.isUnsavedRow = function (row) {
        return false;
    };
    NotificationQueueResultsComponent.prototype.deleteRow = function (row) {
        return null;
    };
    NotificationQueueResultsComponent.prototype.saveRow = function (row, event) {
        return null;
    };
    NotificationQueueResultsComponent.prototype.getRows = function (params) {
        var _this = this;
        console.log('fetch requesting for ' + params.startRow + ' to ' + params.endRow);
        console.log(params.sortModel);
        var pageSize = params.endRow - params.startRow;
        if (!this.searchCriteria) {
            this.searchCriteria = new NotificationSearchCriteria();
        }
        this.searchCriteria.start = params.startRow;
        this.searchCriteria.size = pageSize;
        if (params.sortModel && params.sortModel[0]) {
            this.searchCriteria.sortBy = params.sortModel[0].colId;
            if (params.sortModel[0].sort === SetupGridComp.GRID_SORT_ASCENDING) {
                this.searchCriteria.sortDirection = SortDirection.ASC;
            }
            else {
                this.searchCriteria.sortDirection = SortDirection.DESC;
            }
        }
        return this.notificationService.searchNotifications(this.searchCriteria)
            .pipe(tap(function (data) {
            return _this.dataStore.set(DataKey.notificationSearchResultsForCriteria, data);
        }));
    };
    NotificationQueueResultsComponent.prototype.getNewNotifications = function () {
        var _this = this;
        this.newNotificationResultsSubscription = this.dataStore.get(DataKey.newNotifications).subscribe(function (data) {
            var e_1, _a;
            if (data) {
                try {
                    for (var data_1 = tslib_1.__values(data), data_1_1 = data_1.next(); !data_1_1.done; data_1_1 = data_1.next()) {
                        var notif = data_1_1.value;
                        _this.setupGrid.addNewRow(notif, false, 0);
                    }
                }
                catch (e_1_1) { e_1 = { error: e_1_1 }; }
                finally {
                    try {
                        if (data_1_1 && !data_1_1.done && (_a = data_1.return)) _a.call(data_1);
                    }
                    finally { if (e_1) throw e_1.error; }
                }
            }
        });
    };
    NotificationQueueResultsComponent.prototype.onRawClick = function ($event) {
        this.onRowClicked($event.data);
    };
    NotificationQueueResultsComponent.prototype.onClickAction = function (notification, subType) {
        this.onRowClicked(notification);
        switch (notification.type) {
            case NotificationType.NEW_USER_REGISTRATION:
                return this.openUserQueue(notification);
            case NotificationType.LEAVE_REQUEST:
                return this.onResCalendarClick(notification);
            case NotificationType.ASSIGNMENT_UPDATE:
                if (subType && subType === 'message') {
                    return this.openMessageQueueWithJob(notification);
                }
                else if (subType && subType === 'service') {
                    return this.openServiceQueue(notification);
                }
                break;
            case NotificationType.RESOURCE_COMMUNICATION:
                return this.openMessageQueue(notification);
            default:
                return '';
        }
    };
    NotificationQueueResultsComponent.prototype.openMessageQueue = function (notification) {
        this.localStorageService.delete('resourceId');
        this.localStorageService.delete('job');
        this.localStorageService.delete('notificationId');
        this.localStorageService.store('resourceId', notification.sendingResourceId ? notification.sendingResourceId.toString() : '');
        this.localStorageService.store('notificationId', notification.notificationId ? notification.notificationId.toString() : '');
        this.localStorageService.store('isAssignment', 'false');
        this.userJourneyManager.goForKey('BEGIN_MESSAGE_QUEUE');
    };
    NotificationQueueResultsComponent.prototype.openMessageQueueWithJob = function (notification) {
        this.localStorageService.delete('resourceId');
        this.localStorageService.delete('job');
        this.localStorageService.store('resourceId', notification.resourceId ? notification.resourceId.toString() : '');
        this.localStorageService.store('isAssignment', 'true');
        this.localStorageService.store('assignmentData', notification);
        this.userJourneyManager.goForKey('BEGIN_MESSAGE_QUEUE');
    };
    NotificationQueueResultsComponent.prototype.openServiceQueue = function (notification) {
        if (notification.assignmentId && notification.assignmentId > 0) {
            var criteria = new ProductQueueSearchCriteria();
            criteria.assignmentId = notification.assignmentId;
            criteria.startDate = moment().startOf('year').format('YYYY-MM-DD');
            criteria.endDate = moment().add(1, 'year').endOf('year').format('YYYY-MM-DD');
            criteria.sortDirection = SortDirection.ASC;
            this.localStorageService.store(TCO.AppData.PRODUCT_QUEUE_SEARCH_CRITERIA_COMMON, criteria);
        }
        this.userJourneyManager.goForKey('BEGIN_RESOURCE_ALLOCATION_AS_COMMON_PANEL');
    };
    NotificationQueueResultsComponent.prototype.onResCalendarClick = function (notification) {
        if (notification.resourceId) {
            this.retrieveResourceById(notification);
        }
    };
    NotificationQueueResultsComponent.prototype.openResCalendar = function (resource) {
        var _this = this;
        var resourceName = resource.resourceType === ResourceType.vehicle ?
            resource.vehicle.vehicleName :
            resource.genericResource.firstName;
        this.navConfig = this.configLoader
            .configurations
            .get(TCO.CONF.CONF_PAGE_SECTION_CONFIG)['availability_calendar_side_nav']['resource_level'];
        setTimeout(function () {
            var fabActions = [];
            var dataObject = new ModalData(LEVEL.SUCCESS, 'Availability Calendar', null, null, 'season-setup-focus-view', ResAvailabilityCalendarFocusViewComponent, {
                'resourceSummary': resource,
                'navConfig': _this.navConfig
            }, { 'label': 'close' }, '', '', fabActions, '', resource ?
                resourceName + ' | Supplier: ' + (resource.supplierName) :
                '');
            _this.focusViewCloseSubject = _this.dataStore.get(DataKey.availabilityCalendarFocusViewClose).subscribe(function (c) {
                if (c) {
                }
            });
            _this.focusViewService.confirm(dataObject).subscribe(function (res) {
                if (res) {
                    console.log(res);
                }
            });
        }, 0);
    };
    NotificationQueueResultsComponent.prototype.retrieveResourceById = function (notification) {
        var _this = this;
        this.genericResourceService.retrieveResourceById(notification.resourceId)
            .subscribe(function (res) {
            if (_this.commonHelper.dataValidity(res)) {
                _this.spinnerService.hide();
                var resource = ResponseUtil.getFirstData(res);
                _this.openResCalendar(resource);
            }
            else if (res instanceof TcApiError) {
                _this.spinnerService.hide();
            }
            else if (res instanceof TcHttpError) {
                _this.spinnerService.hide();
            }
        });
        return null;
    };
    NotificationQueueResultsComponent.prototype.openUserQueue = function (notification) {
        this.userJourneyManager.goForKey('BEGIN_RESOURCE_ACCEPTANCE_QUEUE');
    };
    NotificationQueueResultsComponent.prototype.onRowClicked = function (event) {
        event.readStatus = true;
        this.createNotificationStateChangeObj(event);
    };
    NotificationQueueResultsComponent.prototype.createNotificationStateChangeObj = function (notification) {
        if (notification.receivingResourceId === 0) {
            notification.receivingResourceId = null;
        }
        if (notification.supplierId === 0) {
            notification.supplierId = null;
        }
        var notificationStateChangeObj = new NotificationStateChangeData();
        notificationStateChangeObj.notificationId = notification.notificationId;
        notificationStateChangeObj.dmcUser = true;
        notificationStateChangeObj.resourceId = notification.receivingResourceId;
        notificationStateChangeObj.supplierId = notification.supplierId;
        this.changeNotificationReadState(notificationStateChangeObj);
    };
    NotificationQueueResultsComponent.prototype.changeNotificationReadState = function (obj) {
        this.notificationService.changeNotificationState(obj).subscribe(function (result) {
        });
    };
    NotificationQueueResultsComponent.prototype.ngOnDestroy = function () {
        if (this.searchCriteriaSubscription) {
            this.searchCriteriaSubscription.unsubscribe();
        }
        if (this.newNotificationResultsSubscription) {
            this.newNotificationResultsSubscription.unsubscribe();
        }
        if (this.focusViewCloseSubject) {
            this.focusViewCloseSubject.unsubscribe();
        }
    };
    return NotificationQueueResultsComponent;
}(SetupGridComp));
export { NotificationQueueResultsComponent };
