import { Component, Input, OnInit } from '@angular/core';
import { TC } from '@tc-core/util/constants';
import { ConfigLoader } from '@tc-core/util/framework';
import { TCO } from '../../../constants';
import { Resource } from '../../../models/resource/resource';
import { Grade } from '../../../models/supplier/supplier';

@Component({
    selector: 'tc-generic-resource-personal-info-card',
    templateUrl: './generic-resource-personal-info-card.component.html'
})
export class GenericResourcePersonalInfoCardComponent implements OnInit {
    EMPTY_CHAR = TC.FORMAT.EMPTY_CHAR;

    maxGrade = 10;
    grades: Grade[];

    @Input() resource: Resource;
    @Input() resourceType: any;

    constructor(private configLoader: ConfigLoader) { }

    ngOnInit() {
        const gradeConfig = this.configLoader.configurations.get(TCO.CONF.CONF_GRADES_CONFIG);
        if (gradeConfig.grade_values) {
            this.grades = gradeConfig.grade_values.sort((a, b) => (a.order > b.order ? 1 : b.order > a.order ? -1 : 0));
        }
        this.maxGrade = gradeConfig.max_grade ? gradeConfig.max_grade : 10;
    }

    getGradeValue(grade: number | undefined): string
    {
        if (grade && grade !== 0) {
            return this.grades[Math.ceil((grade / this.maxGrade) * this.grades.length) - 1].displayName;
        }
        return this.EMPTY_CHAR;
    }

    public getUserName() {
        if (this.resource && this.resource.userProfile) {
            return this.resource.userProfile.firstName + ' ' + this.resource.userProfile.lastName;
        } else {
            return this.EMPTY_CHAR;
        }
    }
}
