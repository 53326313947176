<div class="tc-card-block content-strip list tc-shadow--2">
    <div class="card-body">
        <div class="tc-card-container">
            <div class="content-summary-card-row tc-item--center tc-flex-box">
                <div class="col-xs-12 col-sm-12 col-md-3">
                    <div class="rs-item-card__flex rs-item-card__flex--no-wrap">
                        <div class="tc-flex-item__center">
                            <div class="tc-flex-item">
                                <tc-resource
                                        *ngIf="selectedResourceCostContent?.icon?.value"
                                        id="{{selectedResourceCostContent?.icon?.key}}"
                                        [key]="selectedResourceCostContent?.icon?.value?.key"
                                        [value]="selectedResourceCostContent?.icon?.value?.value"
                                        [style]="selectedResourceCostContent?.icon?.value?.style"
                                        [iconType]="selectedResourceCostContent?.icon?.value?.iconType">
                                </tc-resource>
                            </div>
                        </div>
                            <div *ngIf="selectedResourceCostContent?.row1col1 || selectedResourceCostContent?.row2col1" class="rs-txt-secondary">
                                <div
                                        *ngIf="selectedResourceCostContent?.row1col1"
                                        class="rs-txt-primary"
                                        id="{{selectedResourceCostContent?.row1col1?.key}}">
                                    {{selectedResourceCostContent?.row1col1?.value}}
                                </div>

                        <div
                                *ngIf="selectedResourceCostContent?.row2col1"
                                class="rs-txt-secondary"
                                id="{{selectedResourceCostContent?.row2col1?.key}}">
                            <span>{{selectedResourceCostContent?.row2col1?.value}}</span>
                        </div>
                            </div>
                    </div>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-4">
                    <div *ngIf="selectedResourceCostContent?.row1col2 || selectedResourceCostContent?.row2col2" class="rs-txt-secondary">
                    <span
                            *ngIf="selectedResourceCostContent?.row1col2"
                            id="{{selectedResourceCostContent?.row1col2?.key}}">{{selectedResourceCostContent?.row1col2?.value}}
                    </span>
                    </div>
                    <div
                            *ngIf="selectedResourceCostContent?.row2col2"
                            class="rs-txt-secondary"
                            id="{{selectedResourceCostContent?.row2col2?.key}}">
                        <span>{{selectedResourceCostContent?.row2col2?.value}}</span>
                    </div>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-5 rs-item-card__flex rs-item-card__flex--center">
                    <div *ngIf="selectedResourceCostContent?.row1col4.value !== null" class="rs-full-width">
                        <!--<div class="col-4-content-wraper">-->
                        <div
                                *ngIf="selectedResourceCostContent.row1col4"
                                class="tc-strip-text--right tc-price-field"
                                id="{{selectedResourceCostContent?.row1col4?.key}}">
                            <span class="tc-price-label">Adult :  </span>
                            <span class="tc-strip__price tc-strip__price--x-large">{{selectedResourceCostContent?.row1col4?.value | tcCurrencyPipe:currencyFormat?.currencyCode:true:currencyFormat?.pattern}}</span>

                        </div>
                        <div
                                *ngIf="selectedResourceCostContent.row2col4"
                                class="tc-strip-text--right rs-txt-secondary"
                                id="{{selectedResourceCostContent?.row2col4?.key}}">
                            <div class="tc-price-field">
                                <span class="tc-price-label">{{'Child' | translate}} :  </span>
                                <span class="tc-price-value">{{selectedResourceCostContent?.row2col4?.value | tcCurrencyPipe:currencyFormat?.currencyCode:true:currencyFormat?.pattern}}</span>
                                <span>|</span>
                                <span class="tc-price-label">{{'Infant' | translate}} :  </span>
                                <span class="tc-price-value">{{selectedResourceCostContent?.row3col4?.value | tcCurrencyPipe:currencyFormat?.currencyCode:true:currencyFormat?.pattern}}</span>
                            </div>
                        </div>
                        <!--<a class="rs-r-align tc-link__value tc-link__value&#45;&#45;regular" (click)="onViewMoreClick(summaryContent)">View More</a>-->
                        <!--</div>-->
                    </div>
                    <div *ngIf="selectedResourceCostContent?.row1col3?.value !== null" class="rs-full-width">
                        <div
                                *ngIf="selectedResourceCostContent.row1col3"
                                class="rs-r-align tc-strip__price tc-strip__price--x-large"
                                id="{{selectedResourceCostContent?.row1col3?.key}}">
                            <span class="tc-price-label">{{'Unit' | translate}} :  </span>
                            <span class="tc-price-value">{{selectedResourceCostContent?.row1col3?.value | tcCurrencyPipe:currencyFormat?.currencyCode:true:currencyFormat?.pattern}}</span>

                        </div>
                        <!--<a class="rs-r-align tc-link__value tc-link__value&#45;&#45;regular" (click)="onViewMoreClick(summaryContent)">View More</a>-->
                        <!--</div>-->
                    </div>
                    <div class="rs-action-icons">
                        <div class="rs-action-icons__item" matTooltip="{{'Edit' | translate}}">
                            <i (click)="onEditClick($event)" class="material-icons rs-action-icons__icon">
                                mode_edit</i>
                        </div>
                        <div class="rs-action-icons__item" matTooltip="{{'Delete' | translate}}">
                            <i (click)="onDeleteClick($event)" class="material-icons rs-action-icons__icon">delete</i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
