import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatProgressBarModule } from '@angular/material';
import { TranslateModule } from '@ngx-translate/core';
import { ResourceModule } from '@tc/resource';
import { ResourceExpandedContentHeaderComponent } from './resource-expanded-content-header.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    ResourceModule,
    MatProgressBarModule
  ],
  exports: [ResourceExpandedContentHeaderComponent],
  declarations: [ResourceExpandedContentHeaderComponent]
})
export class ResourceExpandedContentHeaderModule {}
