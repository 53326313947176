import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { SetupGridModule } from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid.module';
import { DocumentQueueFocusViewModule } from '../document-queue-focus-view/document-queue-focus-view.module';
import { DocumentQueueResultsComponent } from '../document-queue-results/document-queue-results.component';
import { DocumentDetailFocusViewModule } from '../document-detail-focus-view/document-detail-focus-view.module';
import { DocumentsSearchResultsComponent } from './documents-search-results.component';
import { ManifestSearchResultsComponent } from './manifest-search-results/manifest-search-results.component';
import { ReportsSearchResultsComponent } from './reports-search-results/reports-search-results.component';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
    declarations: [
        DocumentsSearchResultsComponent,
        ManifestSearchResultsComponent,
        ReportsSearchResultsComponent,
        DocumentQueueResultsComponent
    ],
    imports: [
        CommonModule,
        SetupGridModule,
        DocumentDetailFocusViewModule,
        DocumentQueueFocusViewModule,
        TranslateModule,
        MatMenuModule,
        MatIconModule,
        MatCheckboxModule
    ],
    exports: [
        DocumentsSearchResultsComponent,
        ManifestSearchResultsComponent,
        ReportsSearchResultsComponent,
        DocumentQueueResultsComponent
    ],
    entryComponents: [
        DocumentsSearchResultsComponent,
        ManifestSearchResultsComponent,
        ReportsSearchResultsComponent,
        DocumentQueueResultsComponent
    ]
})
export class DocumentsSearchResultsModule {}
