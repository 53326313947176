import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BackgroundTask } from '../../../models/background/background-task';
import { OPResponseWrapper } from '../../../models/response/op-response-wrapper';
import { DMCQueryParamsService } from '../../util/framework/dmc-query-params.service';
import { RequestService } from '../../util/framework/request.service';
import { UrlPaths } from '../url-paths';

@Injectable({
    providedIn: 'root'
})
export class BackgroundTaskApiService {

    constructor(
        private queryParamsService: DMCQueryParamsService,
        private requestService: RequestService
    ) {
    }

    public getTask(taskId: number): Observable<OPResponseWrapper<BackgroundTask>> {
        return this.requestService.get(
            UrlPaths.OP_BACKGROUND_URL_KEY,
            [UrlPaths.OP_BACKGROUND_VERSION, UrlPaths.BACKGROUND_TASKS, taskId ? taskId.toString() : '0'],
            null
        );
    }
}
