import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatExpansionModule } from '@angular/material/expansion';
import { TranslateModule } from '@ngx-translate/core';
import { AgGridModule } from 'ag-grid-angular';
import { BookingImportSummaryComponent } from './booking-import-summary.component';

@NgModule({
    declarations: [BookingImportSummaryComponent],
    imports: [
        CommonModule,
        MatExpansionModule,
        TranslateModule,
        AgGridModule
    ],
    exports: [BookingImportSummaryComponent]
})
export class BookingImportSummaryModule {}
