import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TcApiError } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-api-error';
import { TcHttpError } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-http-error';
import { Journey } from '@tc-core/model/it/codegen/ui/framework/journey';
import { TC } from '@tc-core/util';
import { ConfigLoader } from '@tc-core/util/framework/config-loader.service';
import { EventManager } from '@tc-core/util/framework/event-manager.service';
import { UserJourneyManager } from '@tc-core/util/framework/user-journey-manager.service';
import { CommonHelper } from '@tc-core/util/helpers/common-helper.service';
import { SpinnerService } from '@tc-core/util/ui';
import { ChipInputComponent } from '@tc/chip-input';
import { ChipInputService } from '@tc/chip-input/chip-input.service';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { TCO } from '../../../../../constants';
import { ResourceAllocationSearchCriteria } from '../../../../../models/reservation-v2/criteria/resource-allocation-search-criteria';
import { SupplierAllocationSearchCriteria } from '../../../../../models/reservation-v2/criteria/supplier-allocation-search-criteria';
import { ResourceType } from '../../../../../models/reservation/assignment';
import { ReservationV2ManagementService } from '../../../../../services/backend-consumers/reservation-v2/reservation-v2-management.service';
import { ChipHandlerService } from '../../../../../services/helpers/chip-handler.service';
import { RootService } from '../../../../../services/util/core-services/root.service';
import { ActionHandlerService } from '../../../../../services/util/framework/action-handler-service.service';
import { DataKey, DataStoreService } from '../../../../../services/util/framework/data-store.service';
import { DMCQueryParamsService } from '../../../../../services/util/framework/dmc-query-params.service';
import {UserManagementService} from '../../../../../services/user-management/user-management.service';

@Component({
    selector: 'tc-resource-allocation-search-criteria',
    templateUrl: './resource-allocation-search-criteria.component.html',
    providers: [
        ChipHandlerService,
        {provide: 'ChipHandlerService', useExisting: ChipHandlerService},
        ChipInputService
    ]
})
export class ResourceAllocationSearchCriteriaComponent implements OnInit {

    @Input() isInsideForm: boolean = false;
    @Input() inputSearchCriteria: ResourceAllocationSearchCriteria;
    @Input() searchCriteriaChipConfig: string;
    @Input() isSearchBarStyleNotAvailable = false;
    @Input() isDataLoaded = true;
    @Input() displayChipsArr = [];
    @Input() isFocus = false;
    @Input() isVehicleAssignment = false;
    @Input() placeHolderText = 'Search Resources';
    @Input() isTriggerSearch = true;

    @Output() searchTrigger: EventEmitter<boolean> = new EventEmitter();
    @Output() searchCriteria: EventEmitter<any> = new EventEmitter();
    tPagination: any;

    journey: string = null;

    isChipsInit = false;
    availableChips: any[] = [];
    persistentChipsArr = [];
    persistentQParams: string[] = [];
    persistentQParamMap = new Map<string, string>();

    private relatedChipsObsv: Subscription = new Subscription();
    modifiedChip;

    isChipListDisplay = false;
    resourceSearchCriteria: ResourceAllocationSearchCriteria;
    defaultVehicleModel = 'ANY';

    private routerChangeSubscription: Subscription;
    private selectedChipsObsv: Subscription = new Subscription();
    private selectedFieldsObsv: Subscription = new Subscription();
    private chipValueUpdateSub: Subscription = new Subscription();
    private criteriaUpdateSubscription: Subscription = new Subscription();
    private deletedChipObsv: Subscription = new Subscription();
    private userJourneyObserver: Subscription = new Subscription();
    private selectedChips = [];
    private focusSearchBarObserver: Subscription = new Subscription();
    private closePopUpObserver: Subscription = new Subscription();
    private errorsObserver: Subscription = new Subscription();
    private triggerSearchCriteriaObserver: Subscription = new Subscription();
    private searchResultObserver: Subscription = new Subscription();

    private chipInputService;
    @ViewChild('resource_allocation_search_chip_input') allocationSearchChipInput: ChipInputComponent;
    private chipHandlerSubject: Subscription = new Subscription();
    private selectedSupplierObsv: Subscription = new Subscription();

    constructor(
        private activatedRoute: ActivatedRoute,
        private configLoader: ConfigLoader,
        private userJourneyManager: UserJourneyManager,
        private dataStoreService: DataStoreService,
        private actionHandlerService: ActionHandlerService,
        private em: EventManager,
        private reservationV2ManagementService: ReservationV2ManagementService,
        private chipHandler: ChipHandlerService,
        private queryParamService: DMCQueryParamsService,
        private commonHelper: CommonHelper,
        private spinnerService: SpinnerService,
        private rootService: RootService,
        private userManagementService: UserManagementService
    ) { }

    ngOnInit() {
        this.chipInputService = this.allocationSearchChipInput.criteriaInputService;
        this.selectedSupplierObserver();

        // clear data store
        this.dataStoreService.set(DataKey.resourceAllocationSearchCriteria, null, true);

        this.handleQuickActions();

        this.userJourneyObserver = this.userJourneyManager.journey.subscribe((journey: Journey) => {
            if (journey && JSON.stringify(journey) !== JSON.stringify({})) {
                this.journey = journey.id;
            }
        });

        // resetting the view
        this.routerChangeSubscription = this.userJourneyManager.routeChanges.subscribe(e => {
            if (e) {
                this.chipInputService.fullReset(); // fully reset chips
                this.chipHandler.chipsArr = []; // reset chips array loaded from config
                console.log('displayChipsArr', this.displayChipsArr);
                this.resetQueryParams();
            }
        });
        if (!this.isInsideForm) {
            this.resourceSearchCriteria = new ResourceAllocationSearchCriteria();
            if (this.inputSearchCriteria) {
                this.chipInputService.fullReset(); // fully reset chips
                this.chipHandler.chipsArr = []; // reset chips array loaded from config
                this.resourceSearchCriteria.serviceDate = this.inputSearchCriteria.serviceDate;
                this.resourceSearchCriteria.serviceType = this.inputSearchCriteria.serviceType;
                this.resourceSearchCriteria.adultCount = this.inputSearchCriteria.adultCount;
                this.resourceSearchCriteria.childCount = this.inputSearchCriteria.childCount;
                this.resourceSearchCriteria.infantCount = this.inputSearchCriteria.infantCount;
                this.resourceSearchCriteria.serviceStartTime = this.inputSearchCriteria.serviceStartTime;
                this.resourceSearchCriteria.serviceEndTime = this.inputSearchCriteria.serviceEndTime;
                this.resourceSearchCriteria.bookingIdList = this.inputSearchCriteria.bookingIdList;
                this.resourceSearchCriteria.profileType = this.inputSearchCriteria.profileType;
                this.resourceSearchCriteria.costingType = this.inputSearchCriteria.costingType;
                this.resourceSearchCriteria.noOfUnits = this.inputSearchCriteria.noOfUnits;

                this.resourceSearchCriteria.kcCompany = this.inputSearchCriteria.kcCompany;
                this.resourceSearchCriteria.kcDivision = this.inputSearchCriteria.kcDivision;
                this.resourceSearchCriteria.kcBrand = this.inputSearchCriteria.kcBrand;
                this.resourceSearchCriteria.kcDistributionChannel = this.inputSearchCriteria.kcDistributionChannel;

                if (this.inputSearchCriteria.serviceType === ResourceType.vehicle) {
                    this.isVehicleAssignment = true;
                    this.resourceSearchCriteria.transferMode = this.inputSearchCriteria.transferMode;
                    this.resourceSearchCriteria.route = this.inputSearchCriteria.route;
                    this.resourceSearchCriteria.vehicleModel = this.inputSearchCriteria.vehicleModel ?
                        this.inputSearchCriteria.vehicleModel :
                        'Any';
                }

                if (!this.isChipsInit) {
                    this.initSearchCriteriaChips(); // load everything from start
                }
            }

        }

        if (!this.isInsideForm) {
            this.getQueryParams();
            if (!this.isChipsInit) {
                this.initSearchCriteriaChips(); // load everything from start
            }
        }

        this.getSearchObserver();
        this.errorObserver();
        this.selectedChipsUpdate();
        this.handleInterRelatedChips();
        this.notifyChipDelete();
        this.shortCutObserver();
    }

    selectedSupplierObserver() {
        this.selectedSupplierObsv = this.dataStoreService.get(DataKey.assignedSupplier).subscribe(value => {
            if (value && value.supplierId && value.supplierId !== this.resourceSearchCriteria.supplierId) {
                this.setCriteria(value);
            }
        });
    }

    setCriteria(value: any) {
        let selected = value;
        this.resourceSearchCriteria.supplierCode = selected.supplierCode;
        this.resourceSearchCriteria.supplierId = selected.supplierId;
        this.resourceSearchCriteria.serviceDate = selected.serviceDate ? selected.serviceDate : this.resourceSearchCriteria.serviceDate;
        this.resourceSearchCriteria.serviceType = selected.serviceType ? selected.serviceType : this.resourceSearchCriteria.serviceType;
        this.resourceSearchCriteria.activeStatus = "true";
        this.getQueryParams();
        this.initSearchCriteriaChips();

    }

    getQueryParams() {
        let queryParams: string[] = [];
        queryParams = this.queryParamService.getQueryParamsArray(new ResourceAllocationSearchCriteria());

        //this.resourceSearchCriteria = this.rootService.loadDataFromLocalStorage(queryParams, new ResourceAllocationSearchCriteria(),
        //    TCO.AppData.RESOURCE_ALLOCATION_SEARCH_CRITERIA
        // );

        // get persisting query params
        if (this.resourceSearchCriteria) {
            this.persistentQParams = [];
            this.persistentQParams = this.queryParamService.getNotNullQueryParamsArray(this.resourceSearchCriteria);
        }
    }

    private shortCutObserver() {
        this.focusSearchBarObserver = this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'FOCUS_SEARCH_BAR')
                                          .subscribe(e => {
                                              this.isFocus = true;
                                          });
        this.closePopUpObserver = this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'CLOSE_POPUPS')
                                      .subscribe(e => {
                                          this.isFocus = false;
                                      });
    }

    public onFocus(event: boolean) {
        this.isFocus = event;
    }

    initSearchCriteriaChips() {
        this.isChipsInit = true;
        // let isChipHandlerInit = false;
        // read chip config
        let criteriaChipConfigs = null;
        if (!this.isInsideForm) {
            const chipConfigs = this.configLoader.configurations.get(TCO.CONF.CONF_RESOURCE_ALLOCATION_CHIP);
            criteriaChipConfigs = this.commonHelper.recreateJsonObject(chipConfigs);
        } else {
            const chipConfigs = this.configLoader.configurations.get(this.searchCriteriaChipConfig);
            criteriaChipConfigs = this.commonHelper.recreateJsonObject(chipConfigs);
        }
        if (criteriaChipConfigs.chips) {
            criteriaChipConfigs.chips.forEach(chip => {
                chip = this.chipHandler.preProcessChips(chip, criteriaChipConfigs.permissionKeys.keys);
            });
        }
        this.chipHandler.chipsSubject.subscribe(data => {
            if (data && JSON.stringify(data) !== JSON.stringify([]) && data.length ===
                criteriaChipConfigs.chips.length) {
                this.availableChips = data;
                this.chipHandler.chipsSubject.next(null);
                // isChipHandlerInit = true;

                if (this.chipHandlerSubject) {
                    this.chipHandlerSubject.unsubscribe();
                }

                // set values to display chips
                this.displayChipsArr = [];
                this.displayChipsArr = this.availableChips;

                this.userManagementService.updateAllocationSearchChipsWithKC(
                    criteriaChipConfigs,
                    this.resourceSearchCriteria.kcCompany,
                    this.resourceSearchCriteria.kcDivision,
                    this.resourceSearchCriteria.kcBrand,
                    this.resourceSearchCriteria.kcDistributionChannel
                );
                this.availableChips.forEach((chip) => {
                    if (chip['criteriaId'] === 'SERVICE_TYPE') {
                        chip['optionParams']['isInitialPresent'] = true;

                        chip['optionParams']['isInitialPresent'] = true;
                        let choice = chip['optionParams']['options']
                            .filter(option => option.code === this.resourceSearchCriteria.serviceType);
                        chip['optionParams']['data'] = choice;
                    }
                    if (chip['criteriaId'] === 'SERVICE_DATE') {
                        chip['optionParams']['isInitialPresent'] = true;
                        chip['optionParams']['data'] = [moment(this.resourceSearchCriteria.serviceDate).toDate()];
                    }
                    if (this.isVehicleAssignment) {
                        if (chip['criteriaId'] === 'TRANSFER_MODE') {

                            chip['optionParams']['isInitialPresent'] = true;
                            let choice = chip['optionParams']['options']
                                .filter(option => option.code === this.resourceSearchCriteria.transferMode);
                            chip['optionParams']['data'] = choice;
                            chip['optionParams']['isMandatory'] = true;
                        }
                        if (chip['criteriaId'] === 'ROUTE') {

                            chip['optionParams']['isInitialPresent'] = true;
                            let choice = chip['optionParams']['options']
                                .filter(option => option.code === this.resourceSearchCriteria.route);
                            chip['optionParams']['data'] = choice;
                            chip['optionParams']['isMandatory'] = true;
                        }
                        if (chip['criteriaId'] === 'VEHICLE_MODEL') {

                            chip['optionParams']['isInitialPresent'] = true;
                            let choice = chip['optionParams']['options']
                                .filter(option => option.code === this.resourceSearchCriteria.vehicleModel);
                            chip['optionParams']['data'] = choice;
                            chip['optionParams']['isMandatory'] = true;
                        }
                    }
                });
                this.dataStoreService.set(DataKey.chips, this.availableChips, true);
                // trigger search when chips persisting
                this.isDataLoaded = false;
                // this.isTriggerSearch = false;
                this.onResourceSearch(this.resourceSearchCriteria);

                this.chipInputService.init(this.resourceSearchCriteria, this.availableChips, false, true);

                // implement chip persistance
                if (this.persistentQParams && this.persistentQParams.length > 0) {
                    // create a queryParamMap
                    for (let i = 0; i < this.persistentQParams.length; i++) {
                        let qParam = this.persistentQParams[i];
                        let qParamValue = this.resourceSearchCriteria[qParam];
                        this.persistentQParamMap.set(qParam, qParamValue);
                    }
                    // fill persistent chips
                    this.availableChips.forEach(chip => {
                        let persistChip = Object.assign({}, chip);
                        persistChip.optionParams.data = [];
                        let isPersist = false;
                        if (chip.dataParams && chip.dataParams.length > 0) {
                            chip.dataParams.forEach(dataParam => {
                                if (this.persistentQParamMap.has(dataParam.paramValue)) {
                                    isPersist = true;
                                    const dataObj = {
                                        key: dataParam.paramValue,
                                        value: this.persistentQParamMap.get(dataParam.paramValue)
                                    };
                                    persistChip.optionParams.data.push(dataObj);
                                }
                            });
                        }
                        // push persist chips to an array
                        if (isPersist) {
                            this.persistentChipsArr.push(persistChip);
                        }
                    });
                    // persist chips
                    if (this.persistentChipsArr && this.persistentChipsArr.length > 0) {
                        const uniqueChipList = this.persistentChipsArr.filter((e, i) => {
                            return this.persistentChipsArr.findIndex((x) => {
                                return x.criteriaId === e.criteriaId;
                            }) === i;
                        });

                        this.chipInputService.persistChips(uniqueChipList);

                        // trigger search when chips persisting
                        this.isDataLoaded = false;
                        // this.isTriggerSearch = false;
                        this.onResourceSearch(this.resourceSearchCriteria);
                    }
                }
                this.chipInputService.criteriaUpdate.subscribe((_data) => {
                    this.resourceSearchCriteria = _data;
                });
            }
        });
    }

    public onResourceSearch(event) {
        this.isDataLoaded = false;
        this.resourceSearchCriteria = event;
        this.userJourneyManager.canProceed.next(false);

        if (JSON.stringify(event) !== JSON.stringify({}) ) {
            if (!this.resourceSearchCriteria.vehicleModel)
            {
                this.resourceSearchCriteria.vehicleModel = this.defaultVehicleModel;
            }
            this.dataStoreService.set(DataKey.resourceAllocationSearchCriteria, this.resourceSearchCriteria, true);

            this.dataStoreService.set(DataKey.resourceAllocationSearchResults, null, true);

            // set criteria parameters to url
            let queryParams: string[] = [];
            queryParams = this.queryParamService.getQueryParamsArray(this.resourceSearchCriteria);
            this.rootService.setDataToLocalStorage(TCO.AppData.RESOURCE_ALLOCATION_SEARCH_CRITERIA, this.resourceSearchCriteria, true,
                queryParams, true
            );

            // search data
            this.reservationV2ManagementService.searchResourcesForAllocationWithKey(this.resourceSearchCriteria);

            this.isTriggerSearch = false;
            this.isDataLoaded = false;
            this.searchTrigger.emit(true);

        } else {
            setTimeout(() => {
                this.isDataLoaded = true;
                this.isTriggerSearch = true;
            }, 0);
        }
    }

    getUpdatedFieldsDataObject(chip, criteria: ResourceAllocationSearchCriteria) {
        const dataObjArr = [];

        if (chip && chip.dataParams && chip.dataParams.length > 0) {
            chip.dataParams.forEach(dataParam => {
                const dataObj = {
                    key: '',
                    value: ''
                };
                dataObj.key = dataParam.paramValue;
                dataObj.value = criteria[dataParam.paramValue];

                dataObjArr.push(dataObj);
            });
        }

        return dataObjArr;
    }

    private getSearchObserver() {
        this.searchResultObserver = this.dataStoreService.get(DataKey.resourceAllocationSearchResults)
                                        .subscribe((data) => {
                                            if (this.commonHelper.dataValidity(data)) {
                                                this.isDataLoaded = true;
                                                this.isTriggerSearch = false;
                                            } else if (this.commonHelper.isEmptyData(data)) {
                                                this.isDataLoaded = true;
                                            } else if (data instanceof TcApiError) {
                                                this.isDataLoaded = true;
                                            } else if (data instanceof TcHttpError) {
                                                this.isDataLoaded = true;
                                            }
                                        }, (error: any) => {
                                            console.log(error);
                                        });

    }

    private errorObserver() {
        this.errorsObserver = this.dataStoreService.get(DataKey.error)
                                  .subscribe((data) => {
                                      if (data && JSON.stringify(data) !== JSON.stringify({})) {
                                          setTimeout(() => {
                                              this.isDataLoaded = true;
                                          }, 0);
                                          this.dataStoreService.set(DataKey.error, null);
                                      }
                                  });
    }

    private resetQueryParams() {
        let notificationsQueryParams: string[] = [];
        notificationsQueryParams = this.queryParamService.getQueryParamsArray(new ResourceAllocationSearchCriteria());

        const allocationSearchCriteria: ResourceAllocationSearchCriteria = this.rootService.loadDataFromLocalStorage([],
            new ResourceAllocationSearchCriteria(), TCO.AppData.RESOURCE_ALLOCATION_SEARCH_CRITERIA
        );

        // set criteria parameters to url
        let queryParams: string[] = [];
        queryParams = this.queryParamService.getQueryParamsArray(allocationSearchCriteria);

        this.rootService.setDataToLocalStorage(TCO.AppData.RESOURCE_ALLOCATION_SEARCH_CRITERIA, this.resourceSearchCriteria,
            true, queryParams, true
        );
    }

    public onCancel($event) {
        console.log('cancelled');
    }

    public onChipListDisplay(event) {
        this.dataStoreService.set(DataKey.chipListDisplay, null, true);
        this.isChipListDisplay = event;
        this.dataStoreService.set(DataKey.chipListDisplay, this.isChipListDisplay, true);

    }

    handleQuickActions() {
        this.triggerSearchCriteriaObserver = this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'TRIGGER_SEARCH_CRITERIA')
                                                 .subscribe((e) => {
                                                     this.triggerSearch(e.data);
                                                 });
    }

    triggerSearch(data: string) {
        this.resourceSearchCriteria = new ResourceAllocationSearchCriteria();
        // todo criteria

        this.actionHandlerService.fillCriteriaByActionData(data, this.resourceSearchCriteria);
        const requiredChips = this.chipHandler.getChipsFromCriteria(
            this.resourceSearchCriteria,
            TCO.CONF.CONF_RESOURCE_ALLOCATION_CHIP,
            true
        );
        this.chipInputService.hardReset();
        this.chipInputService.persistChips(requiredChips, true);
        this.onResourceSearch(this.resourceSearchCriteria);
    }

    private handleInterRelatedChips() {
        this.relatedChipsObsv = this.chipInputService.relatedChipsSubject
                                    .subscribe(
                                        (data) => {
                                            let toggleChips = [];
                                            if (data && data.chip && JSON.stringify(data) !== JSON.stringify({})) {
                                                this.modifiedChip = data.chip;
                                                // TODO: refine chip input service: inter related chips
                                                if (data.chip.type === 'CALENDAR') {
                                                    this.chipHandler.handleInterRelatedChips(data.chip, data.date);
                                                } else {
                                                    this.chipHandler.handleInterRelatedChips(
                                                        data.chip,
                                                        data.options.value[0]
                                                    );
                                                }
                                                if (data.chip.optionParams.toggleChips &&
                                                    data.chip.optionParams.toggleChips.length > 0) {
                                                    toggleChips = data.chip.optionParams.toggleChips;
                                                    if (toggleChips && data.options && data.options.value[0].value) {
                                                        this.chipInputService.selectedChipsBasedOnStateChange(
                                                            toggleChips);
                                                    } else {
                                                        this.chipInputService.deleteChipsBasedOnStateChange(toggleChips);
                                                    }
                                                }
                                            }
                                        }
                                    );
    }

    private selectedChipsUpdate() {
        this.criteriaUpdateSubscription = this.chipInputService.selectedChipsSubject
                                              .subscribe(selectedChips => {
                                                  this.selectedChips = selectedChips;

                                                  if (selectedChips && JSON.stringify(selectedChips) !==
                                                      JSON.stringify([])) {
                                                      // toggle chips when selecting BRANCH_OF_CLIENT_ID chip
                                                      for (const selectedChip of this.selectedChips) {
                                                          if (selectedChip.chip.id === 'BRANCH_OF_CLIENT_ID' &&
                                                              selectedChip.chip.optionParams.toggleChips &&
                                                              selectedChip.chip.optionParams.toggleChips.length > 0) {
                                                              this.deleteToggleChips(selectedChip.chip.optionParams.toggleChips);
                                                              this.disableChips(selectedChip.chip.optionParams.toggleChips);
                                                              break;
                                                          }
                                                      }
                                                  }
                                              });
    }

    private notifyChipDelete() {
        this.deletedChipObsv = this.chipInputService.deletedChipsSubject
                                   .subscribe(deletedChip => {
                                       if (JSON.stringify(deletedChip) !== JSON.stringify({}) && deletedChip !== null &&
                                           deletedChip !==
                                           undefined) {
                                           if (deletedChip.id === 'CLIENT_TYPE') {
                                               const deleteChips = this.selectedChips.map(chip => chip.chip);
                                               this.deleteSelectedChips(deleteChips);
                                               this.enableChips(this.availableChips);
                                           } else if (deletedChip.id === 'BRANCH_OF_CLIENT_ID') {
                                               if (deletedChip.optionParams.toggleChips &&
                                                   deletedChip.optionParams.toggleChips.length > 0) {
                                                   this.enableChips(deletedChip.optionParams.toggleChips);
                                               }
                                           }
                                       }
                                   });
    }

    private deleteToggleChips(toggleChips: any[]) {
        const deleteChips = [];
        toggleChips.forEach(toggleChip => {
            for (const selectedChip of this.selectedChips) {
                if (selectedChip.chip.id === toggleChip.criteriaId) {
                    deleteChips.push(selectedChip.chip);
                    break;
                }
            }
        });
        this.deleteSelectedChips(deleteChips);
    }

    private deleteSelectedChips(deleteChips: any[]) {
        deleteChips.forEach(chip => {
            this.chipInputService.deleteChip(chip, true);
        });
    }

    private enableChips(enableChips: any[]) {
        enableChips.forEach(enableChip => {
            this.chipInputService.modifyChipsStatus(enableChip, false);
        });
    }

    private disableChips(disableChips: any[]) {
        disableChips.forEach(disableChip => {
            this.chipInputService.modifyChipsStatus(disableChip, true);
        });
    }

    ngOnDestroy() {
        this.routerChangeSubscription.unsubscribe();
        this.userJourneyManager.routeChanges.next(false);

        if (this.selectedChipsObsv) {
            this.selectedChipsObsv.unsubscribe();
        }
        if (this.selectedFieldsObsv) {
            this.selectedFieldsObsv.unsubscribe();
        }
        if (this.chipValueUpdateSub) {
            this.chipValueUpdateSub.unsubscribe();
        }
        if (this.criteriaUpdateSubscription) {
            this.criteriaUpdateSubscription.unsubscribe();
        }
        if (this.deletedChipObsv) {
            this.deletedChipObsv.unsubscribe();
        }
        if (this.searchResultObserver) {
            this.searchResultObserver.unsubscribe();
        }
        if (this.errorsObserver) {
            this.errorsObserver.unsubscribe();
        }
        if (this.userJourneyObserver) {
            this.userJourneyObserver.unsubscribe();
        }
        if (this.focusSearchBarObserver) {
            this.focusSearchBarObserver.unsubscribe();
        }
        if (this.closePopUpObserver) {
            this.closePopUpObserver.unsubscribe();
        }
        if (this.triggerSearchCriteriaObserver) {
            this.triggerSearchCriteriaObserver.unsubscribe();
        }
        if (this.selectedSupplierObsv) {
            this.selectedSupplierObsv.unsubscribe();
        }

        this.em.removeEvents([
            'TRIGGER_SEARCH_CRITERIA'
        ]);
    }

}
