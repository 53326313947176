<div class="container-fluid results-header">
    <div class="results-header__row results-header__row--actions item-header">

        <div class="item-header__content">{{heading ? heading : ""}}</div>
        <div class="item-header__actions tc-icon-block">

            <div
                    *ngIf="transferModeList && transferModeList.length>0"
                    class="tc-icon-block__icon results-header__action-icon">
                <tc-chip-dropdown
                        class="transfer-mode-selection"
                        [dropdownOptions]="transferModeList"
                        (selectedOption)="onSelectTransferMode($event)">
                </tc-chip-dropdown>
            </div>
            <div
                    *ngIf="groupingOptionList && groupingOptionList.length>0"
                    class="tc-icon-block__icon results-header__action-icon">
                <tc-chip-dropdown
                        class="transfer-mode-selection"
                        [dropdownOptions]="groupingOptionList"
                        (selectedOption)="onSelectGroupingType($event)">
                </tc-chip-dropdown>
            </div>
            <div *ngIf="routeList && routeList.length>0" class="tc-icon-block__icon results-header__action-icon">
                <tc-chip-dropdown
                        class="transfer-mode-selection"
                        [dropdownOptions]="routeList"
                        (selectedOption)="onSelectRoute($event)">
                </tc-chip-dropdown>
            </div>
            <div *ngIf="seasonList && seasonList.length>0" class="tc-icon-block__icon results-header__action-icon">
                <tc-chip-dropdown
                        class="transfer-mode-selection"
                        [dropdownOptions]="seasonList"
                        (selectedOption)="onSelectSeason($event)">
                </tc-chip-dropdown>
            </div>

            <ng-container *ngIf="sorters && sorters.length>0">
                <div class="action-icon tc-flex-item">
                    <div class="tc-flex-box">
                        <div class="sort-icon tc-flex-item">
                            <i
                                    class="material-icons tc-card-kebab-icon"
                                    [matTooltip]="'Sort' | translate"
                                    [matTooltipPosition]="'below'"
                                    [matMenuTriggerFor]="more">short_text</i>
                        </div>
                    </div>
                </div>
                <mat-menu #more="matMenu">
                    <div *ngFor="let sorter of sorters">
                        <span class="tc-body2--txt">
                          {{sorter.title | translate}}
                        </span>
                        <i
                                class="material-icons"
                                [matTooltip]="'Descending' | translate"
                                [matTooltipPosition]="'below'"
                                (click)="onSorterClick(sorter,false)">
                            arrow_downward
                        </i>
                        <i
                                class="material-icons"
                                [matTooltip]="'Ascending' | translate"
                                [matTooltipPosition]="'below'"
                                (click)="onSorterClick(sorter,true)">
                            arrow_upward
                        </i>
                    </div>
                </mat-menu>
            </ng-container>
            <div *ngIf="showPageSection">
                <span class="tc-icon-block__icon tc-icon-block__icon--active" (click)="onHeaderActionClick($event)"><i
                        class="material-icons"> assignment </i></span>
            </div>
        </div>
    </div>
</div>
