import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SetupGridModule } from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid.module';
import { GridSearchCriteriaModule } from '../../search-criteria/grid-search-criteria/grid-search-criteria.module';
import { ConfigSetupGridComponent } from './config-setup-grid/config-setup-grid.component';
import { ConfigSetupSearchCriteriaComponent } from './config-setup-search-criteria/config-setup-search-criteria.component';

@NgModule({
    declarations: [
        ConfigSetupSearchCriteriaComponent,
        ConfigSetupGridComponent
    ],
    imports: [
        CommonModule,
        GridSearchCriteriaModule,
        SetupGridModule
    ],
    exports: [
        ConfigSetupSearchCriteriaComponent,
        ConfigSetupGridComponent
    ],
    entryComponents: [
        ConfigSetupSearchCriteriaComponent,
        ConfigSetupGridComponent
    ]
})
export class ConfigSetupModule {}
