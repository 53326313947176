import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { TcErrorType } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-error';
import { SpinnerService } from '@tc-core/util/ui/spinner.service';
import { TCO } from '../../../constants';
import { DistributionChannelSearchCriteria } from '../../../models/criteria/distribution-channel-search-criteria';
import { SortDirection } from '../../../models/helper/sort-direction';
import { MasterDataRestService } from '../../../services/backend-consumers/master-data-handler-service/master-data-rest.service';
import { CostingTypeService } from '../../../services/backend-consumers/setups/costing-type-service';
import { DistributionChannelSetupService } from '../../../services/backend-consumers/setups/distribution-channel-setup-service';
import { UserManagementService } from '../../../services/user-management/user-management.service';
import { DMCCommon } from '../../../services/util/common/dmc-common';
import { DataKey, DataStoreService } from '../../../services/util/framework/data-store.service';
import { SetupGridComp } from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid-comp';
import { of, Subscription } from 'rxjs';
import { ConfigLoader } from '@tc-core/util/framework';
import { SetupGridComponent } from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid.component';
import { tap } from 'rxjs/operators';
import { DialogService } from '@tc/dialog/dialog.service';
import { DialogModel, LEVEL } from '@tc-core/model/it/codegen/ui/framework/dialog-model';
var DistributionChannelSetupComponent = /** @class */ (function (_super) {
    tslib_1.__extends(DistributionChannelSetupComponent, _super);
    function DistributionChannelSetupComponent(configLoader, costingTypeService, dataStoreService, masterDataRestService, common, dataStore, distributionChannelSetupService, dialogService, userManagementService, spinnerService) {
        var _this = _super.call(this) || this;
        _this.configLoader = configLoader;
        _this.costingTypeService = costingTypeService;
        _this.dataStoreService = dataStoreService;
        _this.masterDataRestService = masterDataRestService;
        _this.common = common;
        _this.dataStore = dataStore;
        _this.distributionChannelSetupService = distributionChannelSetupService;
        _this.dialogService = dialogService;
        _this.userManagementService = userManagementService;
        _this.spinnerService = spinnerService;
        _this.colDefConfig = [];
        // search criteria obj. used to
        _this.distributionChannelSearchCriteria = new DistributionChannelSearchCriteria();
        _this.searchCriteriaObserver = new Subscription();
        _this.dialogSubscription = new Subscription();
        _this.isEditableDistributionChannelRow = function (params) {
            var userProfile = this.dataStore.get(DataKey.userProfile).getValue();
            return (userProfile && userProfile.distributionChannel !== undefined);
        };
        return _this;
    }
    DistributionChannelSetupComponent.prototype.ngOnInit = function () {
        this.colDefConfig = this.configLoader.configurations.get(TCO.CONF.CONF_DISTRIBUTION_CHANNEL_SETUP_CONFIG);
        this.subscribeSearchCriteria();
    };
    /**
     * subscribe search criteria changes in search bar
     * then force grid to refresh data
     * grid refresh will be call getRaws method which is using this subscribed criteria for backend call
     */
    DistributionChannelSetupComponent.prototype.subscribeSearchCriteria = function () {
        var _this = this;
        this.searchCriteriaObserver = this.dataStoreService.get(DataKey.distributionChannelSetupSearchCriteria)
            .subscribe(function (value) {
            _this.distributionChannelSearchCriteria = value;
            _this.setupGrid.runForceSearch();
        });
    };
    DistributionChannelSetupComponent.prototype.createNewRow = function () {
        return { unsaved: true };
    };
    DistributionChannelSetupComponent.prototype.deleteRow = function (row) {
        return of('success');
    };
    DistributionChannelSetupComponent.prototype.getRows = function (params) {
        var _this = this;
        var pageSize = 14;
        if (params && params.endRow && params.startRow) {
            pageSize = params.endRow - params.startRow;
        }
        if (!this.distributionChannelSearchCriteria) {
            this.distributionChannelSearchCriteria = new DistributionChannelSearchCriteria();
        }
        this.distributionChannelSearchCriteria = this.userManagementService.updateSearchCriteriaWithUserKC(this.distributionChannelSearchCriteria);
        if (params && params.startRow) {
            this.distributionChannelSearchCriteria.start = params.startRow;
        }
        if (pageSize) {
            this.distributionChannelSearchCriteria.size = pageSize;
        }
        if (params.sortModel && params.sortModel[0]) {
            this.distributionChannelSearchCriteria.sortBy = params.sortModel[0].colId;
            if (params.sortModel[0].sort === SetupGridComp.GRID_SORT_ASCENDING) {
                this.distributionChannelSearchCriteria.sortDirection = SortDirection.ASC;
            }
            else {
                this.distributionChannelSearchCriteria.sortDirection = SortDirection.DESC;
            }
        }
        return this.distributionChannelSetupService.getRows(this.distributionChannelSearchCriteria)
            .pipe(tap(function (data) {
            _this.dataStoreService.set(DataKey.distributionChannelSetupSearchResultsForCriteria, data);
        }));
    };
    DistributionChannelSetupComponent.prototype.isInvalidRow = function (params) {
        var data = params.data;
        if (data) {
            var invalid = true;
            if (data.code &&
                data.name) {
                invalid = false;
            }
            return invalid;
        }
        else {
            return false;
        }
    };
    DistributionChannelSetupComponent.prototype.isDisabledAdd = function () {
        var userProfile = this.dataStore.get(DataKey.userProfile).getValue();
        return (userProfile && userProfile.kcDistributionChannel);
    };
    DistributionChannelSetupComponent.prototype.isUnsavedRow = function (row) {
        if (row) {
            return !row.id;
        }
        return true;
    };
    DistributionChannelSetupComponent.prototype.saveRow = function (row, event) {
        var _this = this;
        if (row.unsaved) {
            var confirmSendManifests = new DialogModel(true, LEVEL.WARNING, 'Save Distribution Channel', 'The entered distribution channel cannot be modified or deleted later. Do you want to continue?', true, 2000, null, 'Cancel', 'Save', true);
            confirmSendManifests.disableClose = true;
            if (this.dialogSubscription) {
                this.dialogSubscription.unsubscribe();
            }
            var dialogSubscription_1 = this.dialogService
                .confirm(confirmSendManifests)
                .subscribe(function (res) {
                if (res === true) {
                    return _this.distributionChannelSetupService.saveRow(row).subscribe(function (response) {
                        _this.userManagementService.getKcDistChannels();
                        _this.spinnerService.show();
                        dialogSubscription_1.unsubscribe();
                        _this.setupGrid.runForceSearch();
                        _this.spinnerService.hide();
                    }, function (error) {
                        _this.spinnerService.hide();
                        if (event && event.data) {
                            event.data.name = '';
                        }
                        _this.common.showSnackBar('Distribution channel code cannot be duplicated', 3000, TcErrorType.TYPE.ERROR);
                    });
                }
                else {
                    if (event && event.data) {
                        event.data.name = '';
                    }
                }
            });
        }
        else {
            return null;
        }
    };
    return DistributionChannelSetupComponent;
}(SetupGridComp));
export { DistributionChannelSetupComponent };
