import { Component, Input, OnInit } from '@angular/core';
import { TC } from '@tc-core/util/constants';
import { Booking } from '../../../models/reservation-v2/booking';
import { Resource } from '../../../models/resource/resource';
import { Supplier } from '../../../models/supplier/supplier';
import { DataKey, DataStoreService } from '../../../services/util/framework/data-store.service';

@Component({
    selector: 'tc-key-control-detail-card',
    templateUrl: './key-control-detail-card.component.html'
})
export class KeyControlDetailCardComponent implements OnInit {

    @Input() keyControlHolder: Supplier | Resource | Booking;
    kcCompanyName: string;
    kcDivisionName: string;
    kcBrandName: string;
    kcDistributionChannelName: string;

    EMPTY_CHAR = TC.FORMAT.EMPTY_CHAR;

    constructor(
        private dataStore: DataStoreService) { }

    ngOnInit() {
        const kcCompanies = this.dataStore.get(DataKey.kcCompanies).getValue();
        kcCompanies.forEach(kcCompany => {
            if (kcCompany.code && kcCompany.code === this.keyControlHolder.kcCompany) {
                this.kcCompanyName = kcCompany.name;
            }
        });
        const kcDivisions = this.dataStore.get(DataKey.kcDivisions).getValue();
        kcDivisions.forEach(kcDivision => {
            if (kcDivision.code && kcDivision.code === this.keyControlHolder.kcDivision) {
                this.kcDivisionName = kcDivision.name;
            }
        });
        const kcBrands = this.dataStore.get(DataKey.kcBrandsList).getValue();
        kcBrands.forEach(kcBrand => {
            if (kcBrand.code && kcBrand.code === this.keyControlHolder.kcBrand) {
                this.kcBrandName = kcBrand.name;
            }
        });
        const kcDistributionChannels = this.dataStore.get(DataKey.kcDistributionChannelList).getValue();
        kcDistributionChannels.forEach(kcDistributionChannel => {
            if (kcDistributionChannel.code && kcDistributionChannel.code === this.keyControlHolder.kcDistributionChannel) {
                this.kcDistributionChannelName = kcDistributionChannel.name;
            }
        });
    }

}
