import { Component, Input, OnInit } from '@angular/core';
import { PickupData, TimeOffsetSetup } from '../../../../../models/master-data/time-offset-setup';
import { FormBuilder, FormGroup } from '@angular/forms';
import { RoutesSetupService } from '../../../../../services/backend-consumers/deprecated/routes-setup.service';
import { DataKey, DataStoreService } from '../../../../../services/util/framework/data-store.service';


@Component({
    selector: 'tc-edit-time-offset-rules',
    templateUrl: './edit-time-offset-rules.component.html'
})
export class EditTimeOffsetRulesComponent implements OnInit {
    @Input() timeOffsetRule: TimeOffsetSetup;
    selectedCriteria: string;
    timeOffsetForm: FormGroup;
    searchResults: any[];
    selectedPoints: PickupData[] = [];
    searchCriteria = ['Airport', 'Cruise Port', 'Hotel', 'Other'];
    editLocations = false;
    isActive = true;
    countryList = [];
    regionList = [];
    cityList = [];

    constructor(
        private fb: FormBuilder,
        private routeService: RoutesSetupService,
        private dataStore: DataStoreService
    ) {
        this.createForm();
    }

    ngOnInit() {
        console.log(this.searchCriteria);

        this.routeService.regionList().subscribe(list => {
            this.regionList = list['data'];
        });
        this.routeService.countryList().subscribe(list => {
            this.countryList = list['data'];
        });
        this.routeService.cityList().subscribe(list => {
            this.cityList = list['data'];
        });
    }

    toggleValue(value: string) {
        this.selectedCriteria = value;
        this.searchLocations();
    }

    searchLocations() {
        this.routeService.getLocationTypes().subscribe(data => {
            if (data != null) {
                for (const key of data) {
                    if (key['locationType'] === this.selectedCriteria) {
                        this.searchResults = key['locations'];
                    }
                }
            }
        });
    }

    createForm() {
        this.timeOffsetForm = this.fb.group({
            region: [''],
            country: [''],
            city: [''],
            locationTimeOffset: this.fb.array([this.createTimeOffset()])
        });
    }

    createTimeOffset(): FormGroup {
        return this.fb.group({
            pointName: [''],
            timeOffsetVal: ['']
        });
    }

    addRule(isSelected: any, loc: any) {

        if (isSelected.checked) {

            let obj = {
                pickupPointCode: loc['code'],
                pickupPointName: loc['name'],
                pickupTimeOffset: 0

            };
            this.selectedPoints.push(obj);
            this.timeOffsetRule.pickupData.push(obj);
            console.log(this.timeOffsetRule.pickupData);
        } else {
            this.timeOffsetRule.pickupData.splice(loc['code'], 1);
            console.log(this.selectedPoints);
        }

        // TODO: should pass the selectedLocations with LocationGroup
        this.dataStore.set(DataKey.locations, this.timeOffsetRule, true);
    }

    editTimeOffset() {
        this.editLocations = true;
    }

}
