import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { SupplierAttributes } from '../../../models/common/supplier-attributes';

@Component({
    selector: 'tc-manage-supplier-attributes',
    templateUrl: './manage-supplier-attributes.component.html'
})
export class ManageSupplierAttributesComponent implements OnInit {

    @Input() supplierAttributes: SupplierAttributes[] = [];
    @Output() attributesChanged: EventEmitter<any> = new EventEmitter();

    constructor() {}

    public ngOnInit(): void {
    }

    valueChanged() {
        this.attributesChanged.emit();
    }
}
