import * as tslib_1 from "tslib";
import { KeyControlAndAbstractSearchCriteria } from './key-control-and-abstract-search-criteria';
var SupplierSearchCriteria = /** @class */ (function (_super) {
    tslib_1.__extends(SupplierSearchCriteria, _super);
    function SupplierSearchCriteria() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.sortBy = '';
        _this.resourceTypes = [];
        _this.profileType = '';
        _this.code = '';
        _this.name = '';
        _this.codeOrName = '';
        _this.locale = '';
        _this.registrationNum = '';
        _this.fuelType = '';
        _this.transferMode = '';
        _this.vehicleModel = '';
        _this.vehicleName = '';
        _this.country = '';
        _this.city = '';
        _this.currency = '';
        _this.paymentMethod = '';
        _this.driver = '';
        _this.route = '';
        _this.status = 'true';
        return _this;
    }
    return SupplierSearchCriteria;
}(KeyControlAndAbstractSearchCriteria));
export { SupplierSearchCriteria };
export var ResourceCategory;
(function (ResourceCategory) {
    ResourceCategory[ResourceCategory["TRANSFER"] = 0] = "TRANSFER";
    ResourceCategory[ResourceCategory["GENERIC"] = 1] = "GENERIC";
})(ResourceCategory || (ResourceCategory = {}));
