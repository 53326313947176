import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DocumentEditCardComponent} from './document-edit-card.component';
import {MatFormFieldModule, MatInputModule, MatOptionModule, MatSelectModule} from '@angular/material';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

@NgModule({
    imports: [
        CommonModule,
        MatInputModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        FormsModule,
        MatSelectModule,
        MatOptionModule,
    ],
    declarations: [DocumentEditCardComponent],
    exports: [DocumentEditCardComponent]
})
export class DocumentEditCardModule {
}
