import { JobSearchCriteria } from '../../../models/criteria/job-search-criteria';
import { DMCQueryParamsService } from '../../util/framework/dmc-query-params.service';
import { RequestService } from '../../util/framework/request.service';
import { UrlPaths } from '../url-paths';
import * as i0 from "@angular/core";
import * as i1 from "../../util/framework/request.service";
import * as i2 from "../../util/framework/dmc-query-params.service";
var JobService = /** @class */ (function () {
    function JobService(requestService, queryParamsService) {
        this.requestService = requestService;
        this.queryParamsService = queryParamsService;
    }
    // common job
    JobService.prototype.getCommonJobs = function (criteria) {
        var params = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.get(UrlPaths.OP_JOB_URL_KEY, [UrlPaths.OP_JOB_VERSION, UrlPaths.JOBS], params);
    };
    JobService.prototype.getCommonJobHistories = function (criteria) {
        var params = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.get(UrlPaths.OP_JOB_URL_KEY, [UrlPaths.OP_JOB_VERSION, UrlPaths.JOBS, UrlPaths.JOB_HISTORIES], params);
    };
    JobService.prototype.unlockJob = function (jobName) {
        var criteria = new JobSearchCriteria();
        criteria.jobName = jobName;
        return this.requestService.post(UrlPaths.OP_JOB_URL_KEY, [UrlPaths.OP_JOB_VERSION, UrlPaths.JOBS], criteria);
    };
    // Get Job Parameter data
    JobService.prototype.getJobParameter = function (criteria) {
        var params = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.get(UrlPaths.OP_JOB_URL_KEY, [UrlPaths.OP_JOB_VERSION, UrlPaths.JOB_PARAMETER], params);
    };
    JobService.prototype.addJobParameter = function (row) {
        return this.requestService.post(UrlPaths.OP_JOB_URL_KEY, [UrlPaths.OP_JOB_VERSION, UrlPaths.JOB_PARAMETER], row);
    };
    JobService.prototype.deleteJobParameter = function (code) {
        return this.requestService.delete(UrlPaths.OP_JOB_URL_KEY, [UrlPaths.OP_JOB_VERSION, UrlPaths.JOB_PARAMETER, code]);
    };
    JobService.ngInjectableDef = i0.defineInjectable({ factory: function JobService_Factory() { return new JobService(i0.inject(i1.RequestService), i0.inject(i2.DMCQueryParamsService)); }, token: JobService, providedIn: "root" });
    return JobService;
}());
export { JobService };
