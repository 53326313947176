import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BookingItem} from '../../../../../models/reservation-v2/booking-item';
import {BookingNoteType} from '../../../../../models/reservation-v2/booking-note';

@Component({
    selector: 'tc-accom-item-detail-view',
    templateUrl: './accom-item-detail-view.component.html'
})
export class AccomItemDetailViewComponent implements OnInit {

    @Input() booking;
    @Input() readonly = false;
    @Input() bookingItem: BookingItem;
    @Input() noteTypes: BookingNoteType[] = [];

    @Output() changeValidity: EventEmitter<boolean> = new EventEmitter();

    constructor() {
    }

    ngOnInit() {
    }

}
