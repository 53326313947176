import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericSearchCriteria } from '../../../models/criteria/generic-search-criteria';
import { Resource } from '../../../models/resource/resource';
import { OPResponseWrapper } from '../../../models/response/op-response-wrapper';
import { DriverAllocationSummary } from '../../../models/summary/driver-allocation-summary';
import { GenResourceAllocationSummary } from '../../../models/summary/gen-resource-allocation-summary';
import { GenericResourceSummary } from '../../../models/summary/generic-resource-summary';
import { DataKey } from '../../util/framework/data-store.service';
import { DMCQueryParamsService } from '../../util/framework/dmc-query-params.service';
import { RequestService } from '../../util/framework/request.service';
import { UrlPaths } from '../url-paths';
import {ResourceAvailability} from '../../../models/resource/resource-availability';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class GenericResourceService {

    constructor(
        private queryParamsService: DMCQueryParamsService,
        private requestService: RequestService,
        private  http: HttpClient
    ) { }

    searchGenericResource(genericSearchCriteria: GenericSearchCriteria): Observable<OPResponseWrapper<GenericResourceSummary>> {
        let params = this.queryParamsService.asQueryParams(genericSearchCriteria);
        return this.requestService.getWithDataKey(
            DataKey.genericResourceSearchResultFromService,
            UrlPaths.OP_SUP_URL_KEY,
            [UrlPaths.OP_SUP_VERSION, UrlPaths.GEN_RES_SUMMARIES],
            params
        );
    }
    searchGenericResourceWithoutDataKey(genericSearchCriteria: GenericSearchCriteria): Observable<OPResponseWrapper<GenericResourceSummary>> {
        let params = this.queryParamsService.asQueryParams(genericSearchCriteria);
        return this.requestService.get(
            UrlPaths.OP_SUP_URL_KEY,
            [UrlPaths.OP_SUP_VERSION, UrlPaths.GEN_RES_SUMMARIES, UrlPaths.ALL_RES_SUMMARIES],
            params
        );
    }

    searchGenericResourceForPaging(genericSearchCriteria: GenericSearchCriteria): Observable<OPResponseWrapper<GenericResourceSummary>> {
        let params = this.queryParamsService.asQueryParams(genericSearchCriteria);
        return this.requestService.get(
            UrlPaths.OP_SUP_URL_KEY,
            [UrlPaths.OP_SUP_VERSION, UrlPaths.GEN_RES_SUMMARIES],
            params
        );
    }

    retrieveResourceById(id: number): Observable<OPResponseWrapper<Resource>> {
        return this.requestService.get(
            UrlPaths.OP_SUP_URL_KEY,
            [UrlPaths.OP_SUP_VERSION, UrlPaths.RESOURCES, id.toString()]
        );
    }

    searchDriversForAllocation(genericSearchCriteria: GenericSearchCriteria): Observable<OPResponseWrapper<DriverAllocationSummary>> {
        let params = this.queryParamsService.asQueryParams(genericSearchCriteria);
        return this.requestService.getWithDataKey(
            DataKey.resourceAssignDriversSearchResults,
            UrlPaths.OP_SUP_URL_KEY,
            [UrlPaths.OP_SUP_VERSION, UrlPaths.DRIVER_ALLOC_SUMMARIES],
            params
        );
    }

    searchRecommendedDriversForAllocation(genericSearchCriteria: GenericSearchCriteria): Observable<OPResponseWrapper<ResourceAvailability>> {
        let params = this.queryParamsService.asQueryParams(genericSearchCriteria);
        return this.requestService.getWithDataKey(
            DataKey.resourceAssignDriversSearchResults,
            UrlPaths.OP_RES_URL_KEY,
            [UrlPaths.OP_RES_VERSION, UrlPaths.RECOMMENDED_DRIVERS],
            params
        );
    }

    searchRecommendedResourcesForAllocation(genericSearchCriteria: GenericSearchCriteria): Observable<OPResponseWrapper<ResourceAvailability>> {
        let params = this.queryParamsService.asQueryParams(genericSearchCriteria);
        return this.requestService.getWithDataKey(
            DataKey.resourceAssignResourcesSearchResults,
            UrlPaths.OP_RES_URL_KEY,
            [UrlPaths.OP_RES_VERSION, UrlPaths.RECOMMENDED_RESOURCES],
            params
        );
    }
    searchRecommendedDriversFromMock(driverSearchCriteria: GenericSearchCriteria): Observable<OPResponseWrapper<ResourceAvailability>> {
        return this.http.get<OPResponseWrapper<ResourceAvailability>>('assets\\mockdata\\driverAvailabilitySummary.json');
    }

    searchDriversForAllocationOnMore(genericSearchCriteria: GenericSearchCriteria): Observable<OPResponseWrapper<DriverAllocationSummary>> {
        let params = this.queryParamsService.asQueryParams(genericSearchCriteria);
        return this.requestService.getWithDataKey(
            DataKey.driverAssignAllFetchedResults,
            UrlPaths.OP_SUP_URL_KEY,
            [UrlPaths.OP_SUP_VERSION, UrlPaths.DRIVER_ALLOC_SUMMARIES],
            params
        );
    }

    searchGenResourceForAllocation(genericSearchCriteria: GenericSearchCriteria):  Observable<OPResponseWrapper<GenResourceAllocationSummary>> {
        let params = this.queryParamsService.asQueryParams(genericSearchCriteria);
        return this.requestService.getWithDataKey(
            DataKey.resourcesForGenAssignment,
            UrlPaths.OP_SUP_URL_KEY,
            [UrlPaths.OP_SUP_VERSION, UrlPaths.GEN_RES_ALLOC_SUMMARIES],
            params
        );
    }

}
