export enum GenericTypePathname {
    GENERIC = 'generic',
    GUIDE = 'guide',
    REP = 'rep',
    MEAL = 'meal',
    BELLY_DANCER = 'bellydancer',
    TANOURA = 'tanoura',
    HENNA_ARTIST = 'hennaartist',
    TICKET = 'ticket',
    DRIVER = 'driver'
}
