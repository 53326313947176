import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SummaryCardData } from '@tc-core/model/it/codegen/ui/framework/summary-card-data';
import { DateFormatter } from '@tc-core/util/system/date-formatter.service';
import { BookingItem } from '../../../../../models/reservation-v2/booking-item';

@Component({
    selector: 'tc-item-summary',
    templateUrl: './item-summary.component.html',
    styleUrls: ['./item-summary.component.scss']
})
export class ItemSummaryComponent implements OnInit {

    @Input() summaryCard: SummaryCardData;
    @Input() isHeader: boolean;
    @Input() isEditable: boolean;
    @Input() showIcons: boolean;
    @Input() bookingItem: BookingItem;

    @Output() passengersClick: EventEmitter<BookingItem> = new EventEmitter();
    @Output() deleteItem: EventEmitter<BookingItem> = new EventEmitter();
    @Output() editAsNewItem: EventEmitter<BookingItem> = new EventEmitter();

    constructor(
        public dateFormatter: DateFormatter
    ) { }

    ngOnInit() {}

    public onDelete() {
        this.deleteItem.emit(this.bookingItem);
    }


    public onEditAsNew() {
        this.editAsNewItem.emit(this.bookingItem);
    }

    public onPassengersClick(event) {
        event.stopPropagation();
        this.passengersClick.emit(this.bookingItem);
    }
}
