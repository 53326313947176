import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { DialogService } from '@tc/dialog/dialog.service';
import { ModalService } from '@tc/modal/modal.service';
import { MatDialog } from '@angular/material';
import { CurrencyPipe } from '@angular/common';
import { TaxScheme } from '../../../models/supplier/tax-scheme';
import { ExpansionPanelDirective } from '@tc/directives/expansion-panel';
import { SummaryCardData } from '@tc-core/model/it/codegen/ui/framework/summary-card-data';
import { ActivatedRoute } from '@angular/router';
import { DataHandlerService } from '../../../services/backend-consumers/master-data-handler-service/data-handler.service';
import { DataKey, DataStoreService } from '../../../services/util/framework/data-store.service';
import { CardDataHandlerService } from '../../../services/util/ui/card-data-handler-service.service';
import {ResourceType} from '../../../models/reservation/assignment';

@Component({
    selector: 'tc-tax-schemes',
    templateUrl: './tax-schemes.component.html'
})
export class TaxSchemesComponent implements OnInit {

    private selectedIndex = -1;
    taxSchemeValidityMap: Map<number, boolean>;
    validtaxSchemes: TaxScheme[] = [];

    @Input() forAResourceProfile = false;
    @Input() resourceType: string;
    @Input() supplierItems: any[];
    @Input() taxSchemeList: TaxScheme[] = [];
    @Input() taxSchemeSummary: SummaryCardData;
    @Input() taxSchemeContents: SummaryCardData[] = [];
    @Input() taxSchemes: any[];
    @Output() changeFormValidity: EventEmitter<boolean> = new EventEmitter();
    @Output() taxSchemeModified: EventEmitter<boolean> = new EventEmitter();
    @ViewChildren(ExpansionPanelDirective) _expansionPanels: QueryList<ExpansionPanelDirective>;

    addingTaxSchemeCount: number = 0;

    constructor(
        private dataHandlerService: DataHandlerService,
        private cardDataHandlerService: CardDataHandlerService,
        private route: ActivatedRoute,
        private dialogService: DialogService,
        private modalService: ModalService,
        public dialog: MatDialog,
        private dataStore: DataStoreService,
        private currencyPipe: CurrencyPipe
    ) {
    }

    ngOnInit() {
        this.dataStore.set(DataKey.taxSchemes, null, true);
        this.taxSchemeValidityMap = new Map<number, boolean>();
        if (this.forAResourceProfile && this.taxSchemeList.length === 0) {
            let taxScheme = new TaxScheme();
            this.taxSchemeList.push(taxScheme);
        }
        if (this.taxSchemeList) {
            this.emitNoContactsValidity();
            this.taxSchemeModified.emit();
        }
    }

    emitNoContactsValidity() {
        /* Initially Additional contacts form is valid since it is collapsed
         * When it is opened, th form is init and will trigger actual validation
         */
        this.changeFormValidity.emit(true);
    }

    public addTaxScheme() {
        let scheme = new TaxScheme();
        scheme.tempId = this.addingTaxSchemeCount++;
        this.taxSchemeList.push(scheme);
        this.taxSchemeModified.emit();
        this._expansionPanels.forEach((expansionElement) => {
            if (expansionElement.id === 'tax_scheme_summary_strip') {
                expansionElement.expansionState.next(false);
            }
        });

    }

    public hideIcons(index) {
        this.selectedIndex = index;
    }

    public showIcons() {
        this.selectedIndex = -1;
    }

    onDelete(event: number) {
        for (let i = 0; i < this.taxSchemeList.length; i++) {
            let taxNo = this.taxSchemeList[i].tempId;
            if (taxNo === event) {
                this.taxSchemeList.splice(i, 1);
                this.taxSchemeValidityMap.delete(taxNo);
                break;
            }
        }
        this.emitValidity();
        this.taxSchemeModified.emit();
    }

    emitValidity() {
        this.validtaxSchemes = [];
        let allSchemesValid = true;
        this.taxSchemeList.forEach(tax => {
            if (this.taxSchemeValidityMap.get(tax.tempId) === false) {
                allSchemesValid = false;
            }
            /* else {
             this.validtaxSchemes.push(address);
             }*/
        });
        this.changeFormValidity.emit(allSchemesValid);
        this.dataStore.set(DataKey.selectedTaxSchemes, this.validtaxSchemes, true);
    }

    public checkValidity(address, event) {
        this.taxSchemeValidityMap.set(address.tempId, event);
        this.emitValidity();
    }
}
