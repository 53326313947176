<form [formGroup]="locationInforForm">
  <div class="container-fluid direct-client-personal-info-edit-card tc-card-wrapper">
    <div class="row">
      <div class="col-md-12 col-lg-12">
        <div class="tc-flex-box">
          <div class="tc-flex-item tc-item-circle">
            <div class="icon-box">
              <i class="material-icons">account_circle</i>
            </div>
          </div>
          <div class="tc-flex-item tc-right-cage" >
            <div class="row">
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                <div class="tc-input-edit dcpiec-contractingCompany">
                  <mat-form-field>
                    <input matInput
                           id="DIR_PER_INFO_EDIT_CODE"
                           #routeInput
                           formControlName="groupCode"
                           [placeholder]="'Group Code'"
                           required>
                    <!--<mat-error class="mat-error" role="alert">{{getErrorMessage('title')}}</mat-error>-->
                  </mat-form-field>
                </div>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                <div class="tc-input-edit dcpiec-input-contractingDivision">
                  <mat-form-field>
                    <input matInput
                           id="DIR_PER_INFO_EDIT_NAME"
                           #routeInput
                           formControlName="groupName"
                           [placeholder]="'group Name'"
                           required>
                    <!--<mat-error class="mat-error" role="alert">{{getErrorMessage('title')}}</mat-error>-->
                  </mat-form-field>
                </div>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                <div class="tc-select-edit dcpiec-supplier-name">


                  <mat-form-field>
                    <mat-select placeholder="Locations" formControlName="locations" (valueChange)="onChange($event)" multiple>
                      <mat-option *ngFor="let option of locationList" [value]="option">{{option.name}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="payment__actions">
              <button class="button-left tc-button-secondary mat-button" mat-button="" (click)="closePopUp($event)"><span class="mat-button-wrapper">Cancel</span><div class="mat-button-ripple mat-ripple" matripple=""></div><div class="mat-button-focus-overlay"></div></button>

              <button class="button-right tc-button-primary mat-button" (click)="setLocationGroup()">{{'Ok' | translate}}</button>
              <!--<button class="button-right tc-button-primary mat-button" (click)="setAdditionalContact()">Ok</button>-->
            </div>
            <!--<button class="btn btn-primary" type="submit" (click)="result()">Save</button>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
