import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {TaxSchemeSummaryCardComponent} from './tax-scheme-summary-card.component';
import {ExpandedContentCardBodyModule} from '@tc/expanded-content-card-body';
import {MatExpansionModule} from '@angular/material';
import {ContentStripModule} from '@tc/content-strip';
import {ResultContentCardModule} from '../result-content-card/result-content-card.module';
import {DirectivesModule} from '@tc/directives';
import {SummaryStripModule} from '@tc/summary-strip';
import {ResourceModule} from '@tc/resource';
import {TaxSchemesModule} from '../tax-schemes/tax-schemes.module';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
    imports: [
        CommonModule,
        ExpandedContentCardBodyModule,
        MatExpansionModule,
        ContentStripModule,
        ResultContentCardModule,
        DirectivesModule,
        SummaryStripModule,
        ResourceModule,
        TranslateModule.forChild()
    ],
  declarations: [TaxSchemeSummaryCardComponent],
    exports: [TaxSchemeSummaryCardComponent]
})
export class TaxSchemeSummaryCardModule { }
