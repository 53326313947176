import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TcApiError } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-api-error';
import { TcHttpError } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-http-error';
import { CurrencyFormat } from '@tc-core/model/it/codegen/ui/framework/currency-format';
import { Pager } from '@tc-core/model/it/codegen/ui/framework/pager';
import { SummaryCardData } from '@tc-core/model/it/codegen/ui/framework/summary-card-data';
import { TC } from '@tc-core/util';
import { ConfigLoader } from '@tc-core/util/framework/config-loader.service';
import { EventManager } from '@tc-core/util/framework/event-manager.service';
import { UserJourneyManager } from '@tc-core/util/framework/user-journey-manager.service';
import { CommonHelper } from '@tc-core/util/helpers/common-helper.service';
import { AutoScrollService } from '@tc-core/util/ui/auto-scroll.service';
import { SpinnerService } from '@tc-core/util/ui/spinner.service';
import { Subscription } from 'rxjs';
import { TCO } from '../../constants';
import {
    DocumentCreationCriteria,
    DocumentFormat,
    DocumentType
} from '../../models/criteria/document-creation-criteria';
import { ProfileTypeSearchCriteria } from '../../models/criteria/profile-type-search-criteria';
import { ResourceTypeSearchCriteria } from '../../models/criteria/resource-type-search-criteria';
import { SupplierSearchCriteria } from '../../models/criteria/supplier-search-criteria';
import { Supplier } from '../../models/supplier/supplier';
import { UserBusinessType } from '../../models/user/user-business-type';
import { DocumentService } from '../../services/backend-consumers/documents/document.service';
import { DataHandlerService } from '../../services/backend-consumers/master-data-handler-service/data-handler.service';
import { ResourceTypeService } from '../../services/backend-consumers/setups/resource-type.service';
import { SupplierService } from '../../services/backend-consumers/supplier-service/supplier.service';
import { DMCCommon } from '../../services/util/common/dmc-common';
import { RootService } from '../../services/util/core-services/root.service';
import { DataKey, DataStoreService } from '../../services/util/framework/data-store.service';
import { DMCQueryParamsService } from '../../services/util/framework/dmc-query-params.service';
import { ResponseUtil } from '../../services/util/response/response-util.service';
import { DMCLocalStorageService, LocalDataKey } from '../../services/util/system/dmc-local-storage.service';

@Component({
    selector: 'tc-transfer-supplier-search-results',
    templateUrl: './transfer-supplier-search-results.component.html',
    styleUrls: ['./transfer-supplier-search-results.component.css']
})
export class TransferSupplierSearchResultsComponent implements OnInit, OnDestroy {

    tSuppliers: any;
    contentCardDataList: Array<any> = [];

    detailSupplier: Supplier;
    supplierResultList: any;
    searchResult: any;

    footerDataMap: Map<number, object> = new Map<number, object>();

    public showEditBtn = true;
    public showEditAsNew = true;
    heading: string;

    public pager: Pager;
    public defaultPageResultCount = 10;
    selectionIndex = '';
    tPagination: any;
    fetchedSupplierCount = 0;
    dataFromMoreClick = false;
    paginatorHasMore = true;

    resultCount = 0;

    private routerChangeSubscription: Subscription = new Subscription();
    private supplierResultObsv: Subscription = new Subscription();
    private moreResultObsv: Subscription = new Subscription();
    private costingTypesObs: Subscription = new Subscription();
    private vehicleTypesObs: Subscription = new Subscription();
    private searchCriteriaObs: Subscription = new Subscription();
    private supplierByIdObsv: Subscription = new Subscription();
    private userBusinessTypeObsv: Subscription = new Subscription();

    isEmptyResult = false;
    isError = false;
    errorText = '';
    noResultsText = '';
    userBusinessType: any;
    kcCompany: any;
    pageOptionsAvailability: any;

    public resourceTypes: any[] = [];

    searchCriteria = new SupplierSearchCriteria();

    currencyFormat: CurrencyFormat;
    costingTypeList = [];
    transferModes = [];
    vehicleModels = [];
    profileTypes = [];

    constructor(
        private dataStoreService: DataStoreService,
        private userJourneyManager: UserJourneyManager,
        private configLoader: ConfigLoader,
        private supplierService: SupplierService,
        private spinnerService: SpinnerService,
        private injector: Injector,
        private route: ActivatedRoute,
        public em: EventManager,
        private commonHelper: CommonHelper,
        private rootService: RootService,
        private dataHandlerService: DataHandlerService,
        private queryParamService: DMCQueryParamsService,
        private autoScrollService: AutoScrollService,
        private common: DMCCommon,
        private localStorage: DMCLocalStorageService,
        private resourceTypeService: ResourceTypeService
    ) {
    }

    ngOnInit() {
        this.tSuppliers = this.configLoader.configurations.get(TCO.CONF.CONF_SUPPLIER_CARD_CONFIG);
        this.dataStoreService.set(DataKey.selectedSupplierForEdit, null, true);
        this.tPagination = this.configLoader.configurations.get(TCO.CONF.CONF_PAGINATION_CONFIG).SUPPLIER_SEARCH;
        this.currencyFormat = this.getCurrencyFormat();
        this.getProfileTypes();
        this.userBusinessType = this.localStorage.load<string>(LocalDataKey.userBusinessType);
        this.pageOptionsAvailability = this.configLoader.configurations.get(TCO.CONF.CONF_PAGE_OPTION_AVAILABILITY).SUPPLIER_MANAGEMENT;
        this.setupPageOptions();
        this.dataStoreService.set(DataKey.supplier, null, true);

        this.setupPaginator();
        this.pager = new Pager(0, this.defaultPageResultCount);

        const resourceTypeSearchCriteria = new ResourceTypeSearchCriteria();
        resourceTypeSearchCriteria.size = 1000000;
        this.resourceTypeService.getResourceTypes(resourceTypeSearchCriteria).subscribe(
            result => {
                if (result.data) {
                    result.data.forEach(type => {
                        this.resourceTypes.push(type);
                    });
                }
            });

        this.dataHandlerService.retrieveTransferModes().subscribe(result => {
            this.transferModes = result.data;
        });

        this.costingTypesObs = this.dataStoreService.get(DataKey.costingTypes).subscribe(value => {
            if (value && Array.isArray(value)) {
                this.costingTypeList = value;
            }
        });

        this.vehicleTypesObs = this.dataStoreService.get(DataKey.vehicleModels).subscribe(value => {
            if (value) {
                this.vehicleModels = value;
            }
        });

        // resetting the view
        // this.routerChangeSubscription = this.userJourneyManager.routeChanges.subscribe(e => {
        //     if (e) {
        //         this.supplierResultList = null;
        //         this.contentCardDataList = [];
        //     }
        // });
        this.fetchedSupplierCount = 0;
        this.supplierResultObserver();
    }

    private getProfileTypes() {
        const profileTypeSearchCriteria = new ProfileTypeSearchCriteria();
        profileTypeSearchCriteria.size = 1000000;
        this.resourceTypeService.getProfileTypes(profileTypeSearchCriteria).subscribe(
            result => {
                this.profileTypes = result.data;
            });
    }

    private supplierResultObserver() {
        this.fetchedSupplierCount = 0;
        this.supplierResultObsv = this.dataStoreService.get(DataKey.supplierSearchResults).subscribe((result) => {
            this.selectionIndex = '';
            if (this.commonHelper.dataValidity(result)) {
                this.spinnerService.hide();
                this.paginatorHasMore = true;
                this.onResultsHandler(result);
            } else if (this.commonHelper.isEmptyData(result)) {
                this.contentCardDataList = [];
                this.supplierResultList = [];
                // this.spinnerService.hide();
            } else if (result instanceof TcApiError) {
                this.spinnerService.hide();
            } else if (result instanceof TcHttpError) {
                this.spinnerService.hide();
            } else {
                this.noResultsText = TCO.EMPTY_RESULT_PAGE_MESSAGE.SUPPLIER_RESULTS;
                this.contentCardDataList = [];
                this.supplierResultList = [];
                this.heading = '';
                this.isEmptyResult = false;
                this.isError = false;
            }
        }, (error: any) => {
        });
    }

    private onResultsHandler(result: any) {
        if (JSON.stringify(result) !== JSON.stringify({})) {
            if (ResponseUtil.isSuccess(result)) {
                const dataList = ResponseUtil.getDataArray(result);
                if (dataList) {
                    this.searchResult = result;
                    this.supplierResultList = dataList;
                    this.resultCount = ResponseUtil.getTotalCount(result);

                    this.fetchedSupplierCount = this.supplierResultList.length;
                    this.contentCardDataList = this.getContentCardData(this.supplierResultList);
                    this.setPageZero();
                    this.heading = this.resultCount + ' result' + (this.resultCount > 1 ? 's' : '') + ' found.';

                    const noOfResults = this.contentCardDataList.length;

                    this.paginatorHasMore = this.resultCount > noOfResults;

                    if (this.supplierResultList.length === 0) {
                        this.noResultsText = TCO.EMPTY_RESULT_PAGE_MESSAGE.SUPPLIER_RESULTS;
                        this.isEmptyResult = true;
                        this.isError = false;
                    } else {
                        this.isEmptyResult = false;
                        this.isError = false;
                    }
                }
            } else if (ResponseUtil.isError(result)) {
                this.isEmptyResult = true;
                this.isError = true;
                if (result.error.message) {
                    this.errorText = result.error.errors[0];
                } else {
                    this.errorText = 'Request Processing Error !!!';
                }
            }
        }
    }

    private onMoreResultsHandler(result: any) {
        if (JSON.stringify(result) !== JSON.stringify({})) {
            if (ResponseUtil.isSuccess(result)) {
                const dataList = ResponseUtil.getDataArray(result);
                if (dataList) {
                    this.supplierResultList.push(...dataList);
                    this.fetchedSupplierCount = this.supplierResultList.length;
                    const contentCardDataList = this.getContentCardData(dataList);
                    this.contentCardDataList.push(...contentCardDataList);

                    this.setPageZero();

                    const noOfResults = this.contentCardDataList.length;

                    this.paginatorHasMore = this.resultCount > noOfResults;

                    if (result.data.length === 0) {
                        this.isError = false;
                    } else {
                        this.isError = false;
                    }
                }
            } else if (ResponseUtil.isError(result)) {
                // this.isEmptyResult = true;
                this.isError = true;
                if (result.error.message) {
                    this.errorText = result.error.errors[0];
                } else {
                    this.errorText = 'Request Processing Error !!!';
                }
            }
        }
    }

    // paginator
    private setupPaginator() {
        this.defaultPageResultCount = this.tPagination.defaultPageResultCount;
        this.pager = new Pager(0, this.defaultPageResultCount);
    }

    public onPage(pageNumber: number) {
        this.pager.currPage = pageNumber;
        this.selectionIndex = (this.pager.currPage * this.pager.itemsPerPage).toString();
    }

    public onSelection(event) {
        this.selectionIndex = event;
    }

    public onMore() {
        this.dataFromMoreClick = true;
        if (this.tPagination && this.tPagination.defaultPageResultCount && this.tPagination.fetchingPages) {
            this.searchCriteria.size = this.tPagination.fetchingPages * this.tPagination.defaultPageResultCount;
            this.searchCriteria.start = this.fetchedSupplierCount;
            this.retrieveAndCollectSearchResults(this.searchCriteria);
        }
    }

    private setPageZero() {
        if (this.pager) {
            if (!this.dataFromMoreClick) {
                this.pager.currPage = 0;
            } else {
                this.dataFromMoreClick = false;
            }
        } else {
            this.pager = new Pager(0, this.defaultPageResultCount);
        }
    }

    public onSupplierSelect(summaryCardData: SummaryCardData) {
        this.dataStoreService.set(DataKey.supplier, null, true);
        const id = summaryCardData.data.value.supplierId;
        if (id !== null) {
            this.supplierService.retrieveSupplierById(id);
            this.spinnerService.show();
            this.detailSupplier = null;
            this.supplierByIdObsv = this.dataStoreService.get(DataKey.supplier).subscribe(
                res => {
                    if (this.commonHelper.dataValidity(res) && res.data && res.data.length > 0) {
                        if (this.supplierByIdObsv) {
                            this.supplierByIdObsv.unsubscribe();
                        }
                        this.spinnerService.hide();
                        this.detailSupplier = ResponseUtil.getFirstData(res);
                        const selectedSupplier = {
                            code: this.detailSupplier.code,
                            resourceType: this.detailSupplier.supplierItems &&
                            this.detailSupplier.supplierItems.length > 0 ?
                                this.detailSupplier.supplierItems[0].code :
                                null
                        };

                        const parameters = this.queryParamService.getQueryParamsArray(selectedSupplier);
                        this.rootService.setDataToLocalStorage(
                            TCO.AppData.SUPPLIER,
                            selectedSupplier,
                            true,
                            parameters
                        );

                        // this.detailSupplier = res;
                        console.log(this.detailSupplier);
                    } else if (res instanceof TcApiError) {
                        this.spinnerService.hide();
                    } else if (res instanceof TcHttpError) {
                        this.spinnerService.hide();
                    }
                }
            );
        }

        this.generateFooterDataMap();

    }

    private getContentCardData(searchResults: any[]): SummaryCardData[] {
        const config = this.tSuppliers.config;
        if (this.tSuppliers.actionHandler && this.tSuppliers.actionHandler !== '') {
            const t = this.tSuppliers.actionHandler.split('#');
            const srv = t[0];
            const fnc = t[1];
            const handler = this.injector.get(srv);
            console.log(searchResults);
            const contentCardDataList = handler[fnc](searchResults, config);
            console.log(contentCardDataList);
            return contentCardDataList;
        }
    }

    private generateFooterDataMap() {
        if (this.detailSupplier) {
            this.footerDataMap.set(0, {
                captionText: 'Created on ' + '21/12/2018',
                bodyText: ''
            });

            this.footerDataMap.set(1, {
                captionText: 'Modified on ' + '21/12/2018',
                bodyText: ''
            });
        }
    }

    public onSupplierEdit(supplier: Supplier) {
        this.rootService.setDataToLocalStorage('code', supplier.code, true);
        this.userJourneyManager.goForKey('GO_TO_EDIT_TRANSPORT_SUPPLIER');
        supplier.isEditTriggered = true;
        this.dataStoreService.set(DataKey.selectedSupplierForEdit, supplier, true);
    }

    public onEditAsNewSupplier($event: Supplier) {
        this.clearIdsInSupplier($event);
        this.userJourneyManager.goForKey('GO_TO_CREATE_SUPPLIER');
        this.dataStoreService.set(DataKey.selectedSupplierForEditAsNew, $event, true);
    }

    /**
     * IMPORTANT : clear all the existing ids that needs to be saved as new entities
     *
     * @param supplier
     */
    public clearIdsInSupplier(supplier: Supplier) {
        supplier.supplierId = 0;
        supplier.code = '';
        if (supplier.contactDetails) {
            supplier.contactDetails.forEach(value => value.contactId = 0);
        }
        if (supplier.documents) {
            supplier.documents.forEach(value => value.id = 0);
        }
        if (supplier.taxSchemes) {
            supplier.taxSchemes.forEach(value => value.taxSchemeId = 0);
        }
        if (supplier.paymentInfo) {
            supplier.paymentInfo.paymentId = 0;
        }
        if (supplier.supplierAttributes) {
            supplier.supplierAttributes.forEach(value => value.id = 0);
        }
        if (supplier.seasons) {
            supplier.seasons.forEach(value => {
                value.seasonId = 0;
                value.seasonDateRanges.forEach(value1 => {
                    value1.dateRangeId = 0;
                });
            });
        }
        if (supplier.supplierItems) {
            supplier.supplierItems.forEach(value => value.id = 0);
        }
    }

    getSearchCriteria() {
        this.searchCriteriaObs = this.dataStoreService.get(DataKey.supplierSearchCriteria).subscribe((data) => {
            if (data !== null) {
                this.searchCriteria = data;
            }
        });
    }

    retrieveAndCollectSearchResults(searchCriteria: SupplierSearchCriteria) {
        this.dataStoreService.set(DataKey.supplierAllFetchedResults, null, true);
        this.supplierService.searchSuppliersOnMore(this.searchCriteria);
        this.moreResultObserver();
    }

    moreResultObserver() {
        this.moreResultObsv = this.dataStoreService.get(DataKey.supplierAllFetchedResults).subscribe(
            (result) => {
                if (this.commonHelper.dataValidity(result)) {
                    this.moreResultObsv.unsubscribe();
                    this.paginatorHasMore = true;
                    this.onMoreResultsHandler(result);
                } else if (result instanceof TcApiError) {
                    this.paginatorHasMore = false;
                } else if (result instanceof TcHttpError) {
                    this.paginatorHasMore = false;
                }
            });
    }

    ngOnDestroy(): void {

        if (this.supplierResultObsv) {
            this.supplierResultObsv.unsubscribe();
        }

        if (this.supplierByIdObsv) {
            this.supplierByIdObsv.unsubscribe();
        }

        if (this.moreResultObsv) {
            this.moreResultObsv.unsubscribe();
        }

        if (this.costingTypesObs) {
            this.costingTypesObs.unsubscribe();
        }

        if (this.vehicleTypesObs) {
            this.vehicleTypesObs.unsubscribe();
        }

        if (this.searchCriteriaObs) {
            this.searchCriteriaObs.unsubscribe();
        }
    }

    public onCardScroll($event: number) {
        setTimeout(() => {
            this.autoScrollService.scrollToID(
                'clientDetailExp_' + $event, 'main-scroll-container');
        }, 0);
    }

    private getCurrencyFormat(): CurrencyFormat {
        let currencyFormat;
        const currencySymbolPattern = this.configLoader.configurations.get(TC.CONF.CONF_SYSTEM).defaults.default_currency_symbol_pattern;
        currencyFormat = new CurrencyFormat(TCO.AppData.DEF_CURRENCY_TYPE, currencySymbolPattern);
        return currencyFormat;
    }

    private validateUserWithBusinessType() {
        if (this.userBusinessType === UserBusinessType.EXTERNAL) {
            this.showEditBtn = false;
            this.showEditAsNew = false;
        }
    }

    private setupPageOptions() {
        if (this.pageOptionsAvailability) {
            this.showEditBtn = this.pageOptionsAvailability.editable;
            this.showEditAsNew = this.pageOptionsAvailability.edit_as_new;
        }
    }
}
