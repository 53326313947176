export enum CalendarRefreshEventType {
  RangeGroupSelect,
  RangeGroupAdded,
  RangeGroupRemove,

  RangeGroupsAddedOrAttributesChanged,

  DateRangeSelect,
  DateRangeAdded,
  DateRangeRemove,
}
