import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { AgGridModule } from 'ag-grid-angular';
import { SupplierPriceBreakdownComponent } from './supplier-price-breakdown.component';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
    declarations: [SupplierPriceBreakdownComponent],
    imports: [
        CommonModule,
        MatExpansionModule,
        MatCheckboxModule,
        AgGridModule,
        TranslateModule.forChild()
    ],
    exports: [
        SupplierPriceBreakdownComponent
    ],
    entryComponents: [
        SupplierPriceBreakdownComponent
    ]
})
export class SupplierPriceBreakdownModule {}
