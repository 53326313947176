<div
        class="container-fluid rs-item-card"
        [ngClass]="{ 'rs-item-card--warning':isUpdated,'rs-item-card--danger':isCancelled}">
    <div class="row">
        <div class="rs-item-card__flags">
            <div
                    *ngIf="changeCorner"
                    class="rs-item-card__split-flag"></div>  <!--gray corner strip for split service items-->
            <i
                    *ngIf="isSideIcon"
                    class="fa fa-user-secret rs-item-card__private-flag"></i> <!--private service item indicator icon-->
                                                             <!--private service item indicator icon-->
        </div>
    </div>
    <div class="row content-summary-card-row tc-flex tc-flex--center-vertical">
        <div class="col-xs-12 col-sm-12 col-md-2 pr-0">
            <div class="rs-item-card__flex rs-item-card__flex--no-wrap">
                <div class="rs-item-card__checkbox tc-flex-item__center">
                    <div class="">
                        <!--<mat-checkbox (change)="checkItem($event)" [value]="checked"></mat-checkbox>-->
                        <mat-checkbox
                                [disabled]="isDisabledCheckbox()"
                                *ngIf="serviceItemChangesInSelectedGroup.get(serviceItemId)"
                                [(ngModel)]="serviceItemChangesInSelectedGroup.get(serviceItemId).checked"
                                (change)="onChangeCheckBox($event)"></mat-checkbox>
                    </div>
                </div>
                <div *ngIf="summaryContent?.row1col1 || summaryContent?.row2col1" class="rs-item-card__left-info">
                                        <div class="tc-status-badge__container">
                                            <div
                                                    title="{{summaryContent?.row1col1?.value}}"
                                                    *ngIf="summaryContent?.row1col1"
                                                    class="rs-txt-primary rs-txt-primary--limit"
                                                    id="{{summaryContent?.row1col1?.key}}">
                                                {{summaryContent?.row1col1?.value}}
                                            </div>
<!--                                            <div-->
<!--                                                    *ngIf="status && status !== NEUTRAL_STATUS"-->
<!--                                                    class="tc-status-badge"-->
<!--                                                    [ngClass]="{'tc-status-badge&#45;&#45;warning': (status === CONFIRM_STATUS || status === INACTIVE_NOTIFY_STATUS),-->
<!--                                                                'tc-status-badge&#45;&#45;success': (status === SUPPLIER_CONFIRM_STATUS || status === COMPLETED_STATUS),-->
<!--                                                                'tc-status-badge&#45;&#45;danger': (status === SUPPLIER_REJECTED_STATUS || status === INACTIVE_STATUS || status === NO_SHOW_STATUS)-->
<!--                                                                }">-->

<!--                                                <span class="tc-status-badge__text">{{statusCodes[status]}}</span>-->
<!--                                            </div>-->
                                        </div>
                    <div class="rs-item-card__text-group">
                        <i *ngIf="flightNumber" class="material-icons rs-item-card__icon">flight</i>
                        {{flightNumber ? '' : EMPTY_CHAR}}
                        <span class="rs-txt-secondary">{{flightNumber}}</span>
                    </div>
                    <div
                            *ngIf="summaryContent?.row2col1"
                            class="rs-item-card__text-group"
                            id="{{summaryContent?.row2col1?.key}}">
                        <i class="material-icons rs-item-card__icon">people</i>
                        <span class="rs-txt-tertiary">{{summaryContent?.row2col1?.value}}</span>
                    </div>

                    <div *ngIf="summaryContent?.row1col2 || summaryContent?.row2col2">

                        <div
                                class="rs-item-card__text-group"
                                *ngIf="summaryContent?.row1col2"
                                id="{{summaryContent?.row1col2?.key}}">
                            <i class="material-icons rs-item-card__icon">
                                flight_takeoff
                            </i>
                            <span class="rs-txt-tertiary">{{summaryContent?.row1col2?.value}}</span>
                        </div>
                        <div
                                class="rs-item-card__text-group"
                                *ngIf="summaryContent?.row2col2"
                                id="{{summaryContent?.row2col2?.key}}">
                            <i class="material-icons rs-item-card__icon">
                                flight_land
                            </i>
                            <span class="rs-txt-tertiary">{{summaryContent?.row2col2?.value}}</span>

                        </div>
                    </div>

                </div>
            </div>
        </div>

        <div class="col-xs-12 col-sm-12 col-md-8 pr-0">
            <div *ngIf="summaryContent?.row1col3">
                <div class="rs-item-card__resource-container" id="{{summaryContent?.row1col3?.key}}">
                    <form [formGroup]="allocationForm" class="rs-item-card__resource-form container-fluid">


                        <div class="row rs-item-card__resource-form-row">
                            <div class="tc-flex-box">
                                <div class="tc-flex-item icon-cage input-icon rs-item-card__resource-status"
                                     [ngClass]="{'rs-item-card__resource-status--warning': (vehicleAssignmentStatus === CONFIRM_STATUS || vehicleAssignmentStatus === INACTIVE_NOTIFY_STATUS),
                                            'rs-item-card__resource-status--success': (vehicleAssignmentStatus === SUPPLIER_CONFIRM_STATUS || vehicleAssignmentStatus === COMPLETED_STATUS),
                                            'rs-item-card__resource-status--danger': (vehicleAssignmentStatus === SUPPLIER_REJECTED_STATUS || vehicleAssignmentStatus === INACTIVE_STATUS || vehicleAssignmentStatus === NO_SHOW_STATUS)
                                            }">
                                    <div class="card-icon--small icn-color-primary">
                                        <i class="material-icons">directions_car</i>
                                    </div>
                                    <div *ngIf="vehicleAssignmentStatus && vehicleAssignmentStatus !== NEUTRAL_STATUS" class="rs-item-card__badge">
                                        <span class="rs-item-card__badge-text">{{statusCodes[vehicleAssignmentStatus]}}</span>
                                    </div>
                                </div>
                                <div class="tc-flex-item input-cage">
                                    <div class="tc-input-edit rs-item-card__resource-input col-xs-5">
                                        <mat-form-field>
                                            <input
                                                    matInput
                                                    id="DIR_PER_INFO_VEHICLE_SUPPLIER_NAME"
                                                    #supplierInput
                                                    formControlName="vehicleSupplier"
                                                    [placeholder]="'Supplier'"
                                                    [matAutocomplete]="autoSV"
                                                    (focus)="onVehicleSupplierFocus($event)"
                                                    [readonly]="isRestrictedEditFields('vehicle')">
                                            <!--<button-->
                                            <!--mat-button-->
                                            <!--matSuffix-->
                                            <!--mat-icon-button-->
                                            <!--aria-label="Clear"-->
                                            <!--[disabled]="isDisableVehicleSupplierClear() || isRestrictedEdit()"-->
                                            <!--(click)="clearVehicleSupplier()">-->
                                            <!--<mat-icon>close</mat-icon>-->
                                            <!--</button>-->
                                        </mat-form-field>
                                        <mat-autocomplete
                                            #autoSV="matAutocomplete"
                                            [displayWith]="displayVehicleSupplierFn">
                                            <mat-option
                                                    *ngFor="let supplier of filteredVehicleSuppliers"
                                                    [value]="supplier"
                                                    (onSelectionChange)="onSelectVehicleSupplier(supplier)">
                                                <span>{{supplier?.name ? supplier.name : ''}}</span> |
                                                <small>{{supplier?.code ? supplier.code : ''}}</small>
                                            </mat-option>
                                        </mat-autocomplete>
                                    </div>
                                    <div class="tc-input-edit rs-item-card__resource-input col-xs-5">
                                        <mat-form-field>
                                            <input
                                                    matInput
                                                    id="DIR_PER_INFO_RESOURCE_TYPE"
                                                    #resourceInput
                                                    formControlName="vehicle"
                                                    [placeholder]="'Vehicle'"
                                                    [matAutocomplete]="autoR"
                                                    (focus)="onVehicleFocus($event)"
                                                    [readonly]="isRestrictedEditFields('vehicle')">
                                            <button
                                                    mat-button
                                                    matSuffix
                                                    mat-icon-button
                                                    aria-label="Clear"
                                                    (click)="clearVehicle()"
                                                    [disabled]="isRestrictedEdit()">
                                                <mat-icon>close</mat-icon>
                                            </button>
                                        </mat-form-field>
                                        <mat-autocomplete #autoR="matAutocomplete" [displayWith]="displayVehicleFn">
                                            <mat-option
                                                    *ngFor="let resource of filteredVehicles"
                                                    [value]="resource"
                                                    (onSelectionChange)="onSelectVehicle(resource)">
                                                <span>{{resource.resourceName}}</span> |
                                                <small>{{resource.capacity}}</small>
                                            </mat-option>
                                        </mat-autocomplete>
                                    </div>
                                    <div class="tc-input-edit rs-item-card__resource-input col-xs-2">
                                        <mat-form-field>
                                            <input
                                                    matInput
                                                    id=""
                                                    #vehicleJobNoInput
                                                    formControlName="vehicleJobNo"
                                                    [placeholder]="'Job No.'"
                                                    (focus)="onVehicleJobNoFocus($event)"
                                                    [readonly]="isRestrictedEditFields('vehicle')">
                                            <!--                                    <button-->
                                            <!--                                            mat-button-->
                                            <!--                                            matSuffix-->
                                            <!--                                            mat-icon-button-->
                                            <!--                                            aria-label="Clear">-->
                                            <!--                                        <mat-icon>close</mat-icon>-->
                                            <!--                                    </button>-->
                                        </mat-form-field>
                                    </div>
                                    <div class="rs-item-card__resource-form-clear">
                                        <button
                                                mat-button
                                                matSuffix
                                                mat-icon-button
                                                aria-label="Clear"
                                                (click)="onClearVehicleAssignment()"
                                                [disabled]="isRestrictedEditFields('vehicle')">
                                            <mat-icon matTooltip="{{'Clear Assignment' | translate}}">cancel</mat-icon>
                                        </button>
                                    </div>
                                </div>

                            </div>

                        </div>
                        <div class="row rs-item-card__resource-form-row">

                            <div class="tc-flex-box">
                                <div class="tc-flex-item icon-cage input-icon rs-item-card__resource-status"  [ngClass]="{'rs-item-card__resource-status--warning': (driverAssignmentStatus === CONFIRM_STATUS || driverAssignmentStatus === INACTIVE_NOTIFY_STATUS),
                                        'rs-item-card__resource-status--success': (driverAssignmentStatus === SUPPLIER_CONFIRM_STATUS || driverAssignmentStatus === COMPLETED_STATUS),
                                        'rs-item-card__resource-status--danger': (driverAssignmentStatus === SUPPLIER_REJECTED_STATUS || driverAssignmentStatus === INACTIVE_STATUS || driverAssignmentStatus === NO_SHOW_STATUS)
                                        }">
                                    <div class="card-icon--small icn-color-primary">
                                        <i class="material-icons">airline_seat_recline_normal</i>
                                    </div>
                                    <div *ngIf="driverAssignmentStatus && driverAssignmentStatus !== NEUTRAL_STATUS" class="rs-item-card__badge">
                                        <span class="rs-item-card__badge-text">{{statusCodes[driverAssignmentStatus]}}</span>
                                    </div>
                                </div>
                                <div class="tc-flex-item input-cage">
                                    <div class="tc-input-edit rs-item-card__resource-input col-xs-5">
                                        <mat-form-field>
                                            <input
                                                    matInput
                                                    id="DIR_PER_INFO_SUPPLIER_NAME"
                                                    #supplierInput
                                                    formControlName="driverSupplier"
                                                    [placeholder]="'Supplier'"
                                                    [matAutocomplete]="autoSD"
                                                    (focus)="onDriverSupplierFocus($event)"
                                                    [readonly]="isRestrictedEditFields('driver')">
                                            <!--<button-->
                                            <!--mat-button-->
                                            <!--matSuffix-->
                                            <!--mat-icon-button-->
                                            <!--aria-label="Clear"-->
                                            <!--[disabled]="isDisableDriverSupplierClear() || isRestrictedEdit()"-->
                                            <!--(click)="clearDriverSupplier()">-->
                                            <!--<mat-icon>close</mat-icon>-->
                                            <!--</button>-->
                                        </mat-form-field>
                                        <mat-autocomplete
                                            #autoSD="matAutocomplete"
                                            [displayWith]="displayDriverSupplierFn">
                                            <mat-option
                                                    *ngFor="let supplier of filteredDriverSuppliers"
                                                    [value]="supplier"
                                                    (onSelectionChange)="onSelectDriverSupplier(supplier)">
                                                <span>{{supplier?.name ? supplier.name : ''}}</span> |
                                                <small>{{supplier?.code ? supplier.code : ''}}</small>
                                            </mat-option>
                                        </mat-autocomplete>
                                    </div>
                                    <div class="tc-input-edit rs-item-card__resource-input col-xs-5">
                                        <mat-form-field>
                                            <input
                                                    matInput
                                                    #resourceInput
                                                    formControlName="driver"
                                                    [placeholder]="'Driver'"
                                                    [matAutocomplete]="autoD"
                                                    (focus)="onDriverFocus($event)"
                                                    [readonly]="isRestrictedEditFields('driver')">
                                            <button
                                                    mat-button
                                                    matSuffix
                                                    mat-icon-button
                                                    aria-label="Clear"
                                                    (click)="clearDriver()"
                                                    [disabled]="isRestrictedEdit()">
                                                <mat-icon>close</mat-icon>
                                            </button>
                                        </mat-form-field>
                                        <mat-autocomplete #autoD="matAutocomplete" [displayWith]="displayDriverFn">
                                            <mat-option
                                                    *ngFor="let driver of filteredDrivers"
                                                    [value]="driver"
                                                    (onSelectionChange)="onSelectDriver(driver)">
                                                <span>{{driver.resourceName}}</span> |
                                                <small>{{driver.resourceId}}</small>
                                            </mat-option>
                                        </mat-autocomplete>
                                    </div>
                                    <div class="tc-input-edit rs-item-card__resource-input col-xs-2">
                                        <mat-form-field>
                                            <input
                                                    matInput
                                                    #driverJobNoInput
                                                    formControlName="driverJobNo"
                                                    [placeholder]="'Job No.'"
                                                    (focus)="onDriverJobNoFocus($event)"
                                                    [readonly]="isRestrictedEditFields('driver')">
                                            <!--                                    <button-->
                                            <!--                                            mat-button-->
                                            <!--                                            matSuffix-->
                                            <!--                                            mat-icon-button-->
                                            <!--                                            aria-label="Clear">-->
                                            <!--                                        <mat-icon>close</mat-icon>-->
                                            <!--                                    </button>-->
                                        </mat-form-field>
                                    </div>

                                    <div class="rs-item-card__resource-form-clear">
                                        <button
                                                mat-button
                                                matSuffix
                                                mat-icon-button
                                                aria-label="Clear"
                                                (click)="onClearDriverAssignment()"
                                                [disabled]="isRestrictedEditFields('driver')">
                                            <mat-icon matTooltip="{{'Clear Driver Assignment' | translate}}">cancel</mat-icon>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </form>
                    <div class="container-fluid pr-0 pl-0">
                        <button
                                class="rs-item-card__resource-button"
                                mat-button
                                mat-icon-button
                                aria-label="more"

                                (click)="onMoreClick('vehicle')"
                                [disabled]="isRestrictedEditFields('vehicle')">
                            <mat-icon matTooltip="{{'Assign Supplier/Vehicle' | translate}}">more</mat-icon>
                        </button>
                        <button
                                class="rs-item-card__resource-button"
                                mat-button
                                mat-icon-button
                                aria-label="more"

                                (click)="onMoreClick('driver')"
                                [disabled]="isRestrictedEditFields('driver')">
                            <mat-icon matTooltip="{{'Assign Supplier/Driver' | translate}}">more</mat-icon>
                        </button>
                    </div>
                </div>
            </div>
            <!--            <div *ngIf="summaryContent?.row1col2 || summaryContent?.row2col2" class="rs-txt-tertiary">-->

            <!--                    <span-->
            <!--                            class="rs-item-card__location "-->
            <!--                            *ngIf="summaryContent?.row1col2"-->
            <!--                            id="{{summaryContent?.row1col2?.key}}">-->
            <!--                        <i class="material-icons rs-item-card__icon margin-remove">-->
            <!--                    flight_takeoff-->
            <!--                </i>-->
            <!--                        {{summaryContent?.row1col2?.value}}-->
            <!--                    </span>-->
            <!--                <span class="rs-item-card__location">&nbsp;-</span>-->
            <!--                <span-->
            <!--                        class="rs-item-card__location"-->
            <!--                        *ngIf="summaryContent?.row2col2"-->
            <!--                        id="{{summaryContent?.row2col2?.key}}">-->
            <!--                    <i class="material-icons rs-item-card__icon margin-remove">-->
            <!--                    flight_land-->
            <!--                </i>-->
            <!--                    {{summaryContent?.row2col2?.value}}-->
            <!--                    </span>-->
            <!--            </div>-->
        </div>

        <div class="col-xs-12 col-sm-12 col-md-2 rs-item-card__flex rs-item-card__flex--center tc-pl-0 tc-pr-1">
            <div *ngIf="summaryContent?.row1col4 || summaryContent?.row2col4" class="rs-full-width">
                <!--<div class="col-4-content-wraper">-->
                <div>
                    <div
                            *ngIf="summaryContent.row1col4"
                            class="rs-r-align rs-item-card__price"
                            id="{{summaryContent?.row1col4?.key}}">{{getCost() | tcCurrencyPipe:currencyFormat?.currencyCode:summaryContent?.row1col4?.value:currencyFormat?.pattern}}
                    </div>
                </div>

                <a class="rs-r-align tc-link__value tc-link__value--regular" (click)="onViewMoreClick(summaryContent)">{{'Item Details' | translate}}</a>
                <!--</div>-->
            </div>
            <div class="rs-action-icons tc-icon-block">
                <!--                    <div *ngIf="historyAvailable(summaryContent)" class="rs-action-icons__item" matTooltip="History">-->
                <!--                        <i (click)="onViewHistoryClick(summaryContent)" class="material-icons rs-action-icons__icon">update</i>-->
                <!--                    </div>-->
                <!--                    <div class="rs-action-icons__item" matTooltip="Split">-->
                <!--                        <i (click)="onSplitSelect($event)" class="material-icons rs-action-icons__icon">call_split</i>-->
                <!--                    </div>-->
                <!--                    <div class="rs-action-icons__item" matTooltip="Set Lead">-->
                <!--                        <i (click)="onLeadPassengerSelect($event)" class="material-icons rs-action-icons__icon">-->
                <!--                            perm_identity</i>                        -->
                <!--                    </div>-->

                <button
                        mat-icon-button
                        [matMenuTriggerFor]="menu"
                        matTooltip="{{'Actions' | translate}}"
                        aria-label="Generic queue assignment menu"
                        class="tc-icon-block__icon--active">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button
                            *ngIf="historyAvailable(summaryContent)"
                            mat-menu-item
                            (click)="onViewHistoryClick(summaryContent)">
                        <mat-icon>update</mat-icon>
                        <span class="tc-text">{{'History' | translate}}</span>
                    </button>
                    <button mat-menu-item (click)="onSplitSelect($event)">
                        <mat-icon>call_split</mat-icon>
                        <span class="tc-text">{{'Split' | translate}}</span>
                    </button>
                    <button mat-menu-item (click)="onLeadPassengerSelect($event)">
                        <mat-icon>perm_identity</mat-icon>
                        <span class="tc-text">{{'Set Lead' | translate}}</span>
                    </button>
                </mat-menu>
            </div>
        </div>
    </div>
    <!--    <div class="rs-status-bar">-->

    <!--        {{summaryContent?.row1col2?.value}} -&#45;&#45; >{{summaryContent?.row1col2?.value}}-->
    <!--    </div>-->
</div>
