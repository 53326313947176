import { Component, OnInit, ViewChild } from '@angular/core';
import { TcErrorType } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-error';
import { TC } from '@tc-core/util';
import { ConfigLoader } from '@tc-core/util/framework';
import { IGetRowsParams } from 'ag-grid-community';
import { Observable, of, Subscription } from 'rxjs/index';
import { tap } from 'rxjs/operators';
import { SetupGridComp } from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid-comp';
import { SetupGridDataSource } from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid-data-source';
import { SetupGridComponent } from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid.component';
import { TCO } from '../../../constants';
import { CurrencySearchCriteria } from '../../../models/criteria/currency-search-criteria';
import { SortDirection } from '../../../models/helper/sort-direction';
import { CurrencyService } from '../../../services/backend-consumers/setups/currency.service';
import { DMCCommon } from '../../../services/util/common/dmc-common';
import { DataKey, DataStoreService } from '../../../services/util/framework/data-store.service';
import EMPTY_CHAR = TC.FORMAT.EMPTY_CHAR;

@Component({
    selector: 'tc-currency-grid-setup',
    templateUrl: './currency-grid-setup.component.html'
})
export class CurrencyGridSetupComponent extends SetupGridComp implements OnInit, SetupGridDataSource {

    @ViewChild('setupGrid')
    private setupGrid: SetupGridComponent;
    public colDefConfig = [];

    allCurrencyCodes = [];

    // search criteria obj. used to
    private currencySearchCriteria: CurrencySearchCriteria = new CurrencySearchCriteria();

    private searchCriteriaObserver: Subscription = new Subscription();

    constructor(
        private configLoader: ConfigLoader,
        private currencyService: CurrencyService,
        private dataStoreService: DataStoreService,
        private common: DMCCommon,
    ) {super(); }

    ngOnInit() {
        this.colDefConfig = this.configLoader.configurations.get(TCO.CONF.CONF_CURRENCY_SETUP);
        this.subscribeSearchCriteria();
        this.getCurrencyCodeList();
    }

    /**
     * subscribe search criteria changes in search bar
     * then force grid to refresh data
     * grid refresh will be call getRaws method which is using this subscribed criteria for backend call
     */
    subscribeSearchCriteria() {
        this.searchCriteriaObserver = this.dataStoreService.get(DataKey.currencySearchCriteria).subscribe(value => {
            this.currencySearchCriteria = value;
            this.setupGrid.runForceSearch();
        });
    }

    getCurrencyCodeList() {
        this.currencyService.getAllCurrencyCodes().subscribe(
            response => {
                this.allCurrencyCodes = response.data;
            }
        );
    }

    public isInvalidRow(params): boolean {
        const data = params.data;
        if (data) {
            let invalid = true;
            if (
                data.code &&
                data.name &&
                data.symbol &&
                data.country
            ) {
                invalid = false;
            }
            return invalid;
        } else {
            return false;
        }
    }

    public isUnsavedRow(row: any): boolean {
        return !row.id;
    }

    public deleteRow(row: any) {
        this.setupGrid.gridApi.getSelectedRows();
        if (row && row.code) {
            return this.currencyService.deleteRow(row.code);
        } else {
            return of('success');
        }
    }

    public saveRow(row: any, event: any) {
        return this.currencyService.saveRow(row);
    }

    public getRows(params: IGetRowsParams): Observable<any> {
        console.log('fetch requesting for ' + params.startRow + ' to ' + params.endRow);
        console.log(params.sortModel);

        const pageSize = params.endRow - params.startRow;
        if (!this.currencySearchCriteria) {
            this.currencySearchCriteria = new CurrencySearchCriteria();
        }
        this.currencySearchCriteria.start = params.startRow;
        this.currencySearchCriteria.size = pageSize;
        if (params.sortModel && params.sortModel[0]) {
            this.currencySearchCriteria.sortBy = params.sortModel[0].colId;
            if (params.sortModel[0].sort === SetupGridComp.GRID_SORT_ASCENDING) {
                this.currencySearchCriteria.sortDirection = SortDirection.ASC;
            } else {
                this.currencySearchCriteria.sortDirection = SortDirection.DESC;
            }
        } else {
            this.currencySearchCriteria.sortBy = 'createdOn';
            this.currencySearchCriteria.sortDirection = SortDirection.ASC;
        }
        return this.currencyService.getRows(this.currencySearchCriteria)
                   .pipe(
                       tap(data =>
                           this.dataStoreService.set(DataKey.currencySearchResultsForCriteria, data)
                       )
                   );
    }

    public createNewRow(): any {
        return {};
    }

    getCountries = (params, rowData, text) => {
        return this.dataStoreService.get(DataKey.countryList)
                   .map(
                       countries =>
                           countries.filter(countries => {
                                   if (text) {
                                       if (countries.name) {
                                           return countries.name.toLowerCase().indexOf(text.toLocaleString()) > -1 ||
                                               countries.code.toLowerCase().indexOf(text.toLocaleString()) > -1;
                                       }
                                   } else {
                                       return true;
                                   }
                               }
                           )
                   );
    };

    onCountrySet = params => {
        if ( params.oldValue === '--' && this.allCurrencyCodes.includes(params.data.code) ) {
            this.common.showSnackBar(
                'Country Code Already Exist. Please Select a Different Code',
                3000,
                TcErrorType.TYPE.ERROR
            );
        } else {
            if (params.data && params.newValue) {
                if (params.newValue['code']) {
                    params.data['country'] = params.newValue['code'];
                } else {
                    params.data['country'] = params.newValue;
                }
                return true;
            }
        }
    };

    public ngOnDestroy(): void {
        if (this.searchCriteriaObserver) {
            this.searchCriteriaObserver.unsubscribe();
        }
    }

    /*
     grid config methods which are called by grid setup (ag grid)
     and set by grid config
     */

    getCountryText = (params) => {
        let groupText = EMPTY_CHAR;
        if (params && params.data && params.data.country) {
            if (params.data.country instanceof Object) {
                if (params.data.country.code) {
                    groupText = params.data.country.code;
                } else {
                    groupText = EMPTY_CHAR;
                }
                groupText += ' | ';
                if (params.data.country.name) {
                    groupText += params.data.country.name;
                } else {
                    groupText += EMPTY_CHAR;
                }
            } else {
                groupText = params.data.country;
            }
        }
        return groupText;
    };

}
