<tc-result-header
        [heading]="heading"
        [sorters]="sorters_"
        (selectSorter)="onSort($event)">
</tc-result-header>

<tc-adv-generic-search-results
        *ngIf="searchResults && searchResults.length>0"
        [sorters]="sorters_"
        [sortingHandler]="sorterService.method"
        [sorter]="sorterService.sorter"
        [pager]="pager"
        [genericResourceResultsList]="contentCardDataList"
        [selectedGenericResourceSummary]="selectedGenericResourceSummary"
        [detailedResource]="detailedResource"
        [resourceTypes]="resourceTypes"
        [trsModes]="transferModes"
        [selectedResourceCost]="resourceCost"
        (resourceSelect)="onResourceSelect($event)"
        (edit)="onEditResource($event)"
        (editAsNew)="onEditAsNewResource($event)"
        (calendarClick)="onCalendarClick($event)"
        (cardScroll)="onCardScroll($event)">
</tc-adv-generic-search-results>

<tc-paginator
        *ngIf="contentCardDataList.length > defaultPageResultCount"
        [length]="contentCardDataList.length"
        [index]="pager.currPage"
        [size]="pager.itemsPerPage"
        [hasMore]="paginatorHasMore"
        (page)="onPage($event)"
        (more)="onMore()">
</tc-paginator>
