import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import * as _ from 'lodash';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'tc-allocation-suggestion-editor',
  templateUrl: './allocation-suggestion-editor.component.html'
})
export class AllocationSuggestionEditorComponent implements ICellEditorAngularComp, AfterViewInit, OnDestroy  {

  @ViewChild('form', {read: ViewContainerRef}) public form;
  @ViewChild('inputTextBox', {read: ViewContainerRef}) public inputTextBox;

  // input parameters
  params: any;
  plainOptionArray: false;
  viewKey = 'name';
  valueKey = 'code';
  returnObject = false;
  rowObject: string;
  rowData: any;
  placeholder: string;
  filterItemFn: any;
  filterItemAsyncFn: any;
  displayItemFn: any;
  getResourceStatus: any;

  // selected
  selectedItem: any;
  columnWidth: string;

  // internal
  optionObserver: Observable<any> = new Observable();
  subscription: Subscription;
  itemControl: FormControl;
  filteredItems: any[];

  constructor() {
    this.itemControl = new FormControl();
    this.itemControl.valueChanges.subscribe(data => {
      if (JSON.stringify(data) !== JSON.stringify({})) {
        if (data !== undefined && typeof (data) !== 'object') {
          this.filteredItems = [];
          if (this.subscription) {
            this.subscription.unsubscribe();
          }
          if (this.filterItemAsyncFn) {
            this.optionObserver = this.filterItemAsyncFn(this.params, this.rowData, data);
            this.subscription = this.optionObserver.subscribe(
                options => {
                  this.filteredItems = options;
                }
            );
          } else if (this.filterItemFn) {
            this.filteredItems = this.filterItemFn(this.params, this.rowData, data);
          }
        }
      }
    });
  }

  ngAfterViewInit(): void {
    window.setTimeout(() => {
      this.form.element.nativeElement.focus();
      this.inputTextBox.element.nativeElement.focus();
    });
  }

  agInit(params: any): void {

    this.params = params;
    this.columnWidth = params.column.actualWidth + 'px';
    this.plainOptionArray = params['plainOptionArray'];
    this.viewKey = params['viewKey'];
    this.valueKey = params['valueKey'];
    this.returnObject = params['returnObject'];
    this.rowData = params['data'];
    this.rowObject = params['rowObject'];
    if (this.rowObject) {
      this.selectedItem = _.get(params.data, this.rowObject);
    } else {
      this.selectedItem = params['value'];
    }
    this.placeholder = params['placeholder'];
    this.filterItemFn = params['filterItemFn'];
    this.filterItemAsyncFn = params['filterItemAsyncFn'];
    this.displayItemFn = params['displayItemFn'];
    this.getResourceStatus = params['getResourceStatus'];
    console.log(this.getResourceStatus);
    // initial display function
    if (!this.displayItemFn) {
      this.displayItemFn = function(option) {
        if (this.plainOptionArray) {
          return option;
        } else if (option) {
          return option[this.viewKey];
        } else {
          return '';
        }
      }.bind(this);
    }
  }

  getValue(): any {
    if (this.returnObject || this.plainOptionArray) {
      return this.selectedItem;
    } else {
      return this.selectedItem[this.valueKey];
    }
  }

  isPopup(): boolean {
    return false;
  }

  onTextInputFocus($event: FocusEvent) {
    if (this.filterItemAsyncFn) {
      this.optionObserver = this.filterItemAsyncFn(this.params, this.rowData, '');
      this.subscription = this.optionObserver.subscribe(
          options => {
            this.filteredItems = options;
          }
      );
    } else if (this.filterItemFn) {
      this.filteredItems = this.filterItemFn(this.params, this.rowData, '');
    }
  }

  onSelectItem($event: any) {
    this.selectedItem = $event.source.value;
    this.params.api.stopEditing();
  }

  focusOutFunction($event) {
    if ($event && $event.relatedTarget == null) {
      this.params.api.stopEditing();
    }
  }

  public ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
