import { Component, EventEmitter, Input, KeyValueDiffer, KeyValueDiffers, OnInit, Output } from '@angular/core';
import { CurrencyFormat } from '@tc-core/model/it/codegen/ui/framework/currency-format';
import { Icon } from '@tc-core/model/it/codegen/ui/framework/icon';
import { TC } from '@tc-core/util';
import { ConfigLoader } from '@tc-core/util/framework';
import * as moment from 'moment';
import { NgxMaterialTimepickerTheme } from 'ngx-material-timepicker/src/app/material-timepicker/models/ngx-material-timepicker-theme.interface';
import { TCO } from '../../../../../constants';
import { TimelineEvent } from '../../../../../widgets/framework/cg-timeline/timeline-event';
import { AssignmentSummary } from '../../../../../models/assignment-summary';
import { ServiceItemChanges } from '../../../../../models/helper/service-item-changes';
import { BookingItem } from '../../../../../models/reservation-v2/booking-item';
import { ExistingAssignment } from '../../../../../models/reservation-v2/existing-assignment';
import { ResourceAllocationSummary } from '../../../../../models/reservation-v2/resource-allocation-summary';
import { ServiceItem } from '../../../../../models/reservation-v2/service-item';
import { SearchResultSummary } from '../../../../../models/reservation/search-result-summary';
import { ResourceAvailability } from '../../../../../models/resource/resource-availability';
import { ResourceCost } from '../../../../../models/supplier/resource-cost';
import { TrsResourceCost } from '../../../../../models/supplier/trs-resource-cost';

@Component({
    selector: 'tc-resource-allocation-timeline',
    templateUrl: './resource-allocation-timeline.component.html'
})
export class ResourceAllocationTimelineComponent implements OnInit {

    @Input() summaryContent: ResourceAllocationSummary;
    @Input() iconConfig: Icon;
    @Input() isRow1col4String = false;
    @Input() itemGroup: SearchResultSummary;
    @Input() itemList: (BookingItem | ServiceItem)[];
    @Input() assignedVehicle: ServiceItemChanges;
    @Input() isBulkAssignment = false;

    @Output() resourceCalendarClick: EventEmitter<any> = new EventEmitter();
    @Output() eventClick: EventEmitter<ExistingAssignment> = new EventEmitter();
    @Output() targetClick: EventEmitter<any> = new EventEmitter();

    currencyFormat: CurrencyFormat;
    isUpdated = false;
    isCancelled = false;
    assignments: ExistingAssignment[] = [];

    timelineEvents: TimelineEvent[] = [];
    leftBufferHours = -1;
    rightBufferHours = -1;

    isTargetBlock = false;
    targetPointTime: Date = new Date();
    targetBlockTimeStart: Date = null;
    targetBlockTimeEnd: Date = null;

    row1col1_customsStyles = [];
    row1col2_customsStyles = [];
    row1col3_customsStyles = [];
    row1col4_customsStyles = [];

    row2col1_customsStyles = [];
    row2col2_customsStyles = [];
    row2col3_customsStyles = [];
    row2col4_customsStyles = [];

    row3col1_customsStyles = [];

    private summaryCardDiffer: KeyValueDiffer<string, any>;
    private selectedCost: ResourceCost;
    defaultJobStartTime: any;
    defaultJobEndTime: any;
    jobStartTime: any;
    jobEndTime: any;

    //timepickers
    public theme: NgxMaterialTimepickerTheme = {
        dial: {
            dialBackgroundColor: '#3f51b5'
        },
        container: {
            buttonColor: '#3f51b5'
        },
        clockFace: {
            clockHandColor: '#3f51b5'
        }
    };

    constructor(private configLoader: ConfigLoader, private differs: KeyValueDiffers) {
    }

    ngOnInit() {
        this.createTimelineEvents();
        this.initTimeline();
        this.createTargetPointOrBlock();
        this.overrideStyles();
        this.currencyFormat = this.getCurrencyFormat();
        this.summaryCardDiffer = this.differs.find(this.summaryContent).create();
    }

    ngDoCheck(): void {
        if (this.summaryCardDiffer) {
            const changes = this.summaryCardDiffer.diff(this.summaryContent);
            if (changes) {
                console.log('summary card data changed');
                this.createTimelineEvents();
            }
        }
    }

    initTimeline() {
        const timelineConfig = this.configLoader.configurations.get(TCO.CONF.CONF_TIMELINE_UNIT_CONFIG)['resource-allocation-timeline'];
        this.leftBufferHours = timelineConfig.leftBufferHours;
        this.rightBufferHours = timelineConfig.rightBufferHours;
    }

    private overrideStyles() {
        this.row1col1_customsStyles = ['tc-strip-text--primary'];
        this.row1col2_customsStyles = ['tc-strip-text--primary'];
        this.row1col3_customsStyles = [];
        this.row1col4_customsStyles = [
            'tc-strip-text--right',
            'tc-strip-text--primary',
            'tc-strip__price',
            'tc-strip__price--x-large'
        ];

        this.row2col1_customsStyles = [];
        this.row2col2_customsStyles = ['tc-strip-text--primary'];
        this.row2col3_customsStyles = [];
        this.row2col4_customsStyles = ['tc-strip-text__label', 'tc-strip-text--right'];
    }

    private getCurrencyFormat(): CurrencyFormat {
        let currencyFormat;
        const currencySymbolPattern = this.configLoader.configurations.get(TC.CONF.CONF_SYSTEM).defaults.default_currency_symbol_pattern;
        currencyFormat = new CurrencyFormat(TCO.AppData.DEF_CURRENCY_TYPE, currencySymbolPattern);
        return currencyFormat;
    }

    public createTimelineEvents() {
        this.assignments = this.summaryContent.existingAssignments ? this.summaryContent.existingAssignments : [];
        this.timelineEvents = [];
        for (const assignment of this.assignments) {
            this.defaultJobStartTime = assignment.startTimeStr;
            this.defaultJobEndTime = assignment.endTimeStr;
            const timelineEvent = new TimelineEvent();
            timelineEvent.startTime = assignment.startTime;
            timelineEvent.endTime = assignment.endTime;
            timelineEvent.waitingEndTime = assignment.waitingEndTime;
            timelineEvent.assignment = assignment;
            timelineEvent.assignment.remainingCount = assignment.remainingCapacity;
            timelineEvent.assignment.allocatedCount = assignment.allocatedCapacity;
            this.timelineEvents.push(timelineEvent);
        }
    }

    createTargetPointOrBlock() {
        if (this.itemList) {

            // set target point if only one booking item | service item
            if (this.itemList[0].trsBookingItem &&
                this.itemList[0].trsBookingItem.departureDateTime) {
                this.itemList = this.sortData(this.itemList);
                if (this.itemList.length === 1) {
                    this.targetPointTime = new Date(this.itemList[0].trsBookingItem.departureDateTime);
                }
                else if (this.itemList.length > 1) {
                    // set target block if there are many booking items | service items
                    this.targetBlockTimeStart = this.itemList[0].trsBookingItem.departureDateTime;
                    this.targetBlockTimeEnd = this.itemList[this.itemList.length - 1].trsBookingItem.departureDateTime;
                }
            }
            else if(this.itemList[0].startTime)
            {
                this.targetPointTime = new Date(this.itemList[0].startTime);
            }

        }

    }

    sortData(data: (BookingItem | ServiceItem)[]): (BookingItem | ServiceItem)[] {
        return data.sort((a, b) => {
            return <any> new Date(b.trsBookingItem.departureDateTime) - <any> new Date(a.trsBookingItem.departureDateTime);
        });
    }

    onResourceCalendarClick(resource: any) {
        this.resourceCalendarClick.emit(resource);
    }

    onTargetClick(target: any) {
        this.targetClick.emit(this.summaryContent);
    }

    onEventClick(event: TimelineEvent) {
        this.eventClick.emit(event.assignment);
    }

    public hasSelectedCost(costSummaries: any[]) {
        this.selectedCost = costSummaries.find(value => value.selected);
    }

    setTimes($event, isStartTime: boolean) {
        let time = $event;
        this.jobStartTime = this.defaultJobStartTime;
        this.jobEndTime = this.defaultJobEndTime;
        let timeArr: Array<string> = [];
        if (time != null && time != '') {
            if (isStartTime) {
                timeArr = time.split(':');
                this.jobStartTime = time;
            } else {
                timeArr = time.split(':');
                this.jobEndTime = time;
            }
            if (timeArr.length > 1 && this.timelineEvents.length > 0) {
                this.timelineEvents.forEach(
                    (timeline, index) => {
                        if (isStartTime) {
                            timeline.assignment.startTime = this.jobStartTime;
                            timeline.assignment.startTimeObj.setHours(+timeArr[0]);
                            timeline.assignment.startTimeObj.setMinutes(+timeArr[1]);
                            timeline.startTime.setHours(+timeArr[0]);
                            timeline.startTime.setMinutes(+timeArr[1]);
                            this.summaryContent.existingAssignments[index].startTime = this.jobStartTime;
                            this.summaryContent.existingAssignments[index].startTimeObj.setHours(+timeArr[0]);
                            this.summaryContent.existingAssignments[index].startTimeObj.setMinutes(+timeArr[1]);

                        } else {
                            timeline.assignment.endTime = this.jobEndTime;
                            timeline.assignment.endTimeObj.setHours(+timeArr[0]);
                            timeline.assignment.endTimeObj.setMinutes(+timeArr[1]);
                            timeline.endTime.setHours(+timeArr[0]);
                            timeline.endTime.setMinutes(+timeArr[1]);
                            this.summaryContent.existingAssignments[index].endTime = this.jobEndTime;
                            this.summaryContent.existingAssignments[index].endTimeObj.setHours(+timeArr[0]);
                            this.summaryContent.existingAssignments[index].endTimeObj.setMinutes(+timeArr[1]);

                        }
                    }
                );
            }
        }
    }

    onInputChange($event, isStartTime: boolean) {
        let time = $event.target.value;
        this.jobStartTime = this.defaultJobStartTime;
        this.jobEndTime = this.defaultJobEndTime;
        let timeArr: Array<string> = [];
        if (time != null && time != '') {
            if (/^([2][0-3]|[01]?[0-9])([.:][0-5][0-9])?$/.test(time)) {
                if (isStartTime) {
                    timeArr = time.split(':');
                    this.jobStartTime = time;
                } else {
                    timeArr = time.split(':');
                    this.jobEndTime = time;
                }
                if (timeArr.length > 1 && this.timelineEvents.length > 0) {
                    this.timelineEvents.forEach(
                        (timeline, index) => {
                            if (isStartTime) {
                                timeline.assignment.startTime = this.jobStartTime;
                                timeline.assignment.startTimeObj.setHours(+timeArr[0]);
                                timeline.assignment.startTimeObj.setMinutes(+timeArr[1]);
                                timeline.startTime.setHours(+timeArr[0]);
                                timeline.startTime.setMinutes(+timeArr[1]);
                                this.summaryContent.existingAssignments[index].startTime = this.jobStartTime;
                                this.summaryContent.existingAssignments[index].startTimeObj.setHours(+timeArr[0]);
                                this.summaryContent.existingAssignments[index].startTimeObj.setMinutes(+timeArr[1]);

                            } else {
                                timeline.assignment.endTime = this.jobEndTime;
                                timeline.assignment.endTimeObj.setHours(+timeArr[0]);
                                timeline.assignment.endTimeObj.setMinutes(+timeArr[1]);
                                timeline.endTime.setHours(+timeArr[0]);
                                timeline.endTime.setMinutes(+timeArr[1]);
                                this.summaryContent.existingAssignments[index].endTime = this.jobStartTime;
                                this.summaryContent.existingAssignments[index].endTimeObj.setHours(+timeArr[0]);
                                this.summaryContent.existingAssignments[index].endTimeObj.setMinutes(+timeArr[1]);

                            }
                        }
                    );
                }
            } else if (isStartTime) {
                this.jobStartTime = this.defaultJobStartTime;
                $event.target.value = this.jobStartTime;
            } else {
                this.jobEndTime = this.defaultJobEndTime;
                $event.target.value = this.jobEndTime;
            }
        }
    }
}
