import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
    MatAutocompleteModule,
    MatButtonModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatTabsModule,
    MatTooltipModule
} from '@angular/material';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from '@tc/pipes';
import { ResourceModule } from '@tc/resource';
import { ReservationServiceGroupNoteComponent } from './reservation-service-group-note.component';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule.forChild(),
        ResourceModule,
        PipesModule,
        MatCheckboxModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatTabsModule,
        MatAutocompleteModule,
        MatIconModule,
        MatButtonModule,
        MatTooltipModule
    ],
    declarations: [ReservationServiceGroupNoteComponent],
    exports: [ReservationServiceGroupNoteComponent]
})
export class ReservationServiceGroupNoteModule {}
