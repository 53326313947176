import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {CountryStateCityWrapper} from '../../../models/common/country-state-city-wrapper';
import {City, Country, State} from '../../../models/common/locale';
import {
    MasterDataHandlerService
} from '../../backend-consumers/master-data-handler-service/master-data-handler.service';
import {CommonDataSearchCriteria} from "../../../models/criteria/common-data-search-criteria";
import {CommonDataType} from "../../../models/reservation-v2/common-data-type";
import {CountrySearchCriteria} from "../../../models/criteria/country-search-criteria";


@Injectable()
export class CountryStateCityService {

    private NOT_SPECIFIED_STATE = new State();
    private EMPTY_STRING = '';

    private countryList = new BehaviorSubject<Country[]>([]);
    countryListObserver = this.countryList.asObservable();

    private stateList = new BehaviorSubject<State[]>([]);
    stateListObserver = this.stateList.asObservable();

    private cityList = new BehaviorSubject<City[]>([]);
    cityListObserver = this.cityList.asObservable();

    constructor(
        private masterDataHandlerService: MasterDataHandlerService
    ) {
        this.NOT_SPECIFIED_STATE.name = 'NOT SPECIFIED';
        this.NOT_SPECIFIED_STATE.code = '';
    }

    getInitialCountry(countryCode: string) {
        if (countryCode) {
            return new Promise(resolve => {
                const criteria = new CountrySearchCriteria();
                criteria.code = countryCode;
                this.masterDataHandlerService.retrieveCountries(criteria)
                    .subscribe(
                        value => {
                            if (value && value.data) {
                                resolve(value.data[0]);
                            }
                        });
            });
        } else {
            return new Promise(resolve => resolve(null));
        }
    }

    getInitialState(countryCode: string, stateCode: string) {
        const criteria = new CommonDataSearchCriteria(CommonDataType.STATE);
        criteria.stateCode = stateCode;
        criteria.countryCode = countryCode;
        if (stateCode) {
            return new Promise(resolve => {
                this.masterDataHandlerService.retrieveStates(criteria)
                    .subscribe(
                        value => {
                            if (value && value.data) {
                                if (value.data[0]) {
                                    resolve(value.data[0]);
                                } else {
                                    resolve(this.NOT_SPECIFIED_STATE);
                                }
                            }
                        });
            });
        } else {
            return new Promise(resolve => resolve(this.NOT_SPECIFIED_STATE));
        }
    }

    getInitialCity(countryCode: string, stateCode: string, cityCode: string) {
        if (cityCode) {
            const criteria = new CommonDataSearchCriteria(CommonDataType.CITY);
            criteria.code = cityCode;
            criteria.stateCode = stateCode;
            criteria.countryCode = countryCode;
            return new Promise(resolve => {
                this.masterDataHandlerService.retrieveCities(criteria)
                    .subscribe(
                        value => {
                            if (value && value.data) {
                                resolve(value.data[0]);
                            }
                        });
            });
        } else {
            return new Promise(resolve => resolve(null));
        }
    }

    public onCountryTextChange(text: string) {
        if (!text) {
            this.countryList.next([]);
            const criteria = new CountrySearchCriteria();
            this.masterDataHandlerService.retrieveCountries(criteria)
                .subscribe(value => {
                    if (value && value.data) {
                        this.countryList.next(value.data);
                    }
                });
        } else {
            const criteria = new CountrySearchCriteria();
            criteria.codeOrName = text;
            this.masterDataHandlerService.retrieveCountries(criteria)
                .subscribe(value => {
                    if (value && value.data) {
                        this.countryList.next(value.data);
                    }
                });
        }
    }

    public onStateTextChange(text: string, wrapper: CountryStateCityWrapper) {
        const criteria = new CommonDataSearchCriteria(CommonDataType.STATE);
        criteria.countryCode = wrapper.country ? wrapper.country.code : this.EMPTY_STRING;
        if (!text) {
            this.stateList.next([]);
            if (wrapper.country && wrapper.country.code) {
                this.masterDataHandlerService.retrieveStates(criteria)
                    .subscribe(value => {
                        if (value && value.data) {
                            this.stateList.next(value.data);
                        }
                    });
            }
        } else {
            this.masterDataHandlerService.retrieveStates(criteria)
                .subscribe(value => {
                    if (value && value.data) {
                        this.stateList.next(value.data);
                    }
                });
        }
    }

    public onCityTextChange(text: string, wrapper: CountryStateCityWrapper) {
        const criteria = new CommonDataSearchCriteria(CommonDataType.CITY);
        criteria.countryCode = wrapper.country ? wrapper.country.code : this.EMPTY_STRING;
        criteria.stateCode = wrapper.state ? wrapper.state.code : this.EMPTY_STRING;
        if (!text) {
            this.cityList.next([]);
            if (((wrapper.country && wrapper.country.code) || (wrapper.state && wrapper.state.code))) {
                this.masterDataHandlerService.retrieveCities(criteria)
                    .subscribe(value => {
                        if (value && value.data) {
                            this.cityList.next(value.data);
                        }
                    });
            }
        } else {
            criteria.codeOrName = text;
            this.masterDataHandlerService.retrieveCities(criteria)
                .subscribe(value => {
                    if (value && value.data) {
                        this.cityList.next(value.data);
                    }
                });
        }
    }

    public onSelectCountry(country: Country, wrapper: CountryStateCityWrapper): CountryStateCityWrapper {
        wrapper.country = country;

        // remove state if not correct
        if (wrapper.state) {
            if (!wrapper.state.country) {
                wrapper.state = null;
            } else if (wrapper.state.country.code !== country.code) {
                wrapper.state = null;
            }
        }

        // remove city if not correct
        if (wrapper.city) {
            if (!wrapper.city.country) {
                wrapper.city = null;
            } else if (wrapper.city.country.code !== country.code) {
                wrapper.city = null;
            } else if (wrapper.state && !wrapper.city.state) {
                wrapper.city = null;
            } else if (wrapper.state &&
                wrapper.city.state.code !== wrapper.state.code) {
                wrapper.city = null;
            }
        }
        return wrapper;
    }

    public onSelectState(state: State, wrapper: CountryStateCityWrapper): CountryStateCityWrapper {
        wrapper.state = state;
        wrapper.country = state.country;

        // remove city if not correct
        if (wrapper.city) {
            if (!wrapper.city.state) {
                wrapper.city = null;
            } else if (wrapper.city.state.code !== state.code) {
                wrapper.city = null;
            }
        }
        return wrapper;
    }

    public onSelectCity(city: City, wrapper: CountryStateCityWrapper): CountryStateCityWrapper {
        wrapper.city = city;
        wrapper.state = city.state;
        wrapper.country = city.country;
        if (!wrapper.state) {
            this.NOT_SPECIFIED_STATE.country = wrapper.country;
            wrapper.state = this.NOT_SPECIFIED_STATE;
        }
        return wrapper;
    }

}
