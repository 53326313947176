import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { TranslateModule } from '@ngx-translate/core';
import { BookingItemExportComponent } from './booking-item-export.component';

@NgModule({
    declarations: [BookingItemExportComponent],
    imports: [
        CommonModule,
        FormsModule,
        TranslateModule,
        MatSelectModule,
        MatDatepickerModule,
        MatInputModule

    ],
    exports: [BookingItemExportComponent],
    entryComponents: [BookingItemExportComponent]
})
export class BookingItemExportModule {}
