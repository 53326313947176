export class SupplierSummary {
    city: string;
    code: string;
    country: string;
    currency: string;
    email: string;
    locale: string;
    name: string;
    paymentMethod: string;
    profileType: string;
    registrationNum: string;
    status: boolean;
    supplierId: number;
    kcCompany: string;
    kcDivision: string;
    kcBrand: string;
    kcDistributionChannel: string;
}
