import {MainType} from '../reservation-v2/main-type';

export class DocumentDataCriteria {
    servicingSupplierId: number = 0;
    // serviceDate: string;
    searchStartDate: string;
    searchEndDate: string;
    serviceType: string;

    // Transport queue
    route: string;
    locationGroup: string;
    pickupLocation: string;
    dropOffLocation: string;
    hotel: string;
    airport: string;
    bookingDate: string;
    daysFrom: number = 0;
    dropOffCity: string;
    dropOffType: string;
    MaxPax: number = 0;
    MinPax: number = 0;
    pickupCity: string;
    pickupEndTime: string;
    pickupStartTime: string;
    pickupType: string;
    bookingRefId: string;
    tourName: string;
    transferType: string; // Private or shared
    transferMode: string;
    arrAirport: string;
    arrFltNumber: string;
    deptFltNumber: string;
    arrTime: string;
    arrTerminal: string;
    deptAirport: string;
    deptTime: string;
    deptTerminal: string;
    serviceItemAttributes: string;
    // start:number = 0;
    // size: number = 0;

    assignStatus: string;
    supplierCode: string;
    supplierName: string;
    resourceIdList: number[] = [];
    categoryCode: string;
    productType: string;

    //for cost sheets
    supplierId: number;

    //new queue implementation
    bookingIdList: number[] = [];
    queueVersion: string;
    viewType: string;
    trsType: string;
    documentType: string;
    assignmentStatuses: string[] = [];

    //user details
    userId: number;
    userName: string;

    mainType: MainType;
}
