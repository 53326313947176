export class DocumentCreationModel {
    download: boolean;
    persist: boolean;
    autoName: boolean;
    notifySupplier: boolean;
    documentType: any;
    documentName = '';
    viewType: any;
    queueVersion = 'V1';

    selectedBookingItemIds: number[];
}
