export enum LocationType {
    HOTEL = 'HOTEL',
    REGION = 'REGION',
    COUNTRY = 'COUNTRY',
    STATE = 'STATE',
    CITY = 'CITY',
    RESORT = 'RESORT',
    AIRPORT = 'AIRPORT',
    MARKETING_LOCATION = 'MARKETING_LOCATION',
    LOCATION = 'LOCATION'
}