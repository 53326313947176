<!---------------------Content Cards------------->
<div class="trade-client-detail-view">
    <tc-expanded-content-card-body>
        <ng-container *ngIf="summaryCardData">

            <tc-reservation-expanded-summary-view
                    class="rs-full-width"
                    [summaryCardData]="summaryCardData"
                    [contentCardDataList]="reservationServiceItemCards"
                    (pickUps)="onPickUpsClick($event)"
                    (dropOffs)="onDropOffsClick($event)">
            </tc-reservation-expanded-summary-view>

            <mat-accordion class="rs-full-width tc-accordion" [multi]="true">
                <mat-expansion-panel>
                    <mat-expansion-panel-header class="tc-accordion-header__container">
                        <div class="tc-accordion-header">
                            <!--<div class="tc-item-wrapper__header tc-item-header-highlighted">-->
                                <!--<div class="tc-item-header-highlighted__icon">-->
                                    <!--<i class="material-icons material-icons">location_on</i>-->
                                <!--</div>-->
                                <!--<span class="tc-item-header-highlighted__primary">  Pickups and Drop offs</span>-->
                            <!--</div>-->
                            <span class="tc-accordion-header__title">  {{'Pickups and Drop offs' | translate}}</span>
                            <i class="material-icons tc-accordion-header__down-icon">keyboard_arrow_down</i>
                        </div>
                    </mat-expansion-panel-header>
                    <tc-pickup-dropoff-expanded-view
                            [pickups]="selectedPickups"
                            [dropOffs]="selectedDropOffs">
                    </tc-pickup-dropoff-expanded-view>
                </mat-expansion-panel>
                <mat-expansion-panel *ngIf="showFlightInfo">
                    <mat-expansion-panel-header class="tc-accordion-header__container">
                        <div class="tc-accordion-header">
                            <!--<div class="tc-item-wrapper__header tc-item-header-highlighted">-->
                                <!--<div class="tc-item-header-highlighted__icon">-->
                                    <!--<i class="material-icons material-icons">flight</i>-->
                                <!--</div>-->
                                <!--<span class="tc-item-header-highlighted__primary"> Flight Information</span>-->
                            <!--</div>-->
                            <span class="tc-accordion-header__title">{{'Flight Information' | translate}}</span>
                            <i class="material-icons tc-accordion-header__down-icon">keyboard_arrow_down</i>
                        </div>
                    </mat-expansion-panel-header>
                    <tc-flight-info-expanded-view
                            [arrFltNumbers]="arrFltNumbers"
                            [deptFltNumbers]="deptFltNumbers"
                            [arrFltTerminals]="arrFltTerminals"
                            [deptFltTerminals]="deptFltTerminals"
                            [arrFltTime]="arrFltTime"
                            [deptFltTime]="deptFltTime"
                            [arrAirports]="arrAirports"
                            [deptAirports]="deptAirports">
                    </tc-flight-info-expanded-view>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true">
                    <mat-expansion-panel-header class="tc-accordion-header__container">
                        <div class="tc-accordion-header">
                            <!--<div class="tc-item-wrapper__header tc-item-header-highlighted">-->
                                <!--<div class="tc-item-header-highlighted__icon">-->
                                    <!--<i class="material-icons material-icons">book</i>-->
                                <!--</div>-->
                                <!--<span class="tc-item-header-highlighted__primary">Service Items</span>-->
                            <!--</div>-->
                            <span class="tc-accordion-header__title">{{'Service Items' | translate}}</span>
                            <i class="material-icons tc-accordion-header__down-icon">keyboard_arrow_down</i>
                        </div>
                    </mat-expansion-panel-header>
                    <tc-reservation-service-items
                            class="rs-full-width"
                            [isRequestCompleted]="isRequestCompleted"
                            [summaryCardData]="summaryCardData"
                            [contentCardDataList]="reservationServiceItemCards"
                            [supplierList]="supplierList"
                            [resourceList]="resourceList"
                            [serviceItemChangesInSelectedGroup]="serviceItemChangesInSelectedGroup"
                            [filteredVehicleSuppliers]="filteredVehicleSuppliers"
                            [filteredDriverSuppliers]="filteredDriverSuppliers"
                            [filteredVehicles]="filteredVehicles"
                            [filteredDrivers]="filteredDrivers"
                            [changesAvailableForServiceItems]="changesAvailableForServiceItems"
                            (moreClick)="onMoreClick($event)"
                            (splitClick)="onSplitClick($event)"
                            (leadPassengerClick)="onLeadPassengerSelect($event)"
                            (clickConfirm)="onConfirmClick($event)"
                            (saveAssignments)="onSaveAssignments()"
                            (clickMerge)="onClickMerge()"
                            (historyClick)="onHistoryClick($event)"
                            (clickSupplierReject)="onClickSupplierReject()"
                            (vehicleSupplierAssign)="onSelectVehicleSupplier($event)"
                            (driverSupplierAssign)="onSelectDriverSupplier($event)"
                            (vehicleAssign)="onSelectVehicle($event)"
                            (driverAssign)="onSelectDriver($event)"
                            (vehicleClear)="clearVehicle($event)"
                            (driverClear)="clearDriver($event)"
                            (isSavedChanges)="onSavedChanges($event)"
                            (autoAssignResources)="onAutoAssignResources()"
                            (driverTextChange)="onDriverTextChange($event)"
                            (vehicleTextChange)="onVehicleTextChange($event)"
                            (vehicleTextFocus)="onVehicleFocus($event)"
                            (viewMoreFocus)="onViewMoreClick($event)"
                            (vehicleSupplierClear)="clearVehicleSupplier($event)"
                            (driverSupplierClear)="clearDriverSupplier($event)"
                            (driverSupplierTextChange)="onDriverSupplierTextChange($event)"
                            (vehicleSupplierTextChange)="onVehicleSupplierTextChange($event)"
                            (vehicleJobNoChange)="onVehicleJobNoChange($event)"
                            (driverJobNoChange)="onDriverJobNoChange($event)"
                            (clearVehicleAssignment)="onClearVehicleAssignment($event)"
                            (clearDriverAssignment)="onClearDriverAssignment($event)"
                            (bulkDriverAssignment)="onBulkDriverAssignment()"
                            (bulkVehicleAssignment)="onBulkVehicleAssignment()"
                    >
                    </tc-reservation-service-items>
                </mat-expansion-panel>
                <mat-expansion-panel>
                    <mat-expansion-panel-header class="tc-accordion-header__container">
                        <div class="tc-accordion-header" (click)="addEmptyGroupNote()">
                            <!--<div class="tc-item-wrapper__header tc-item-header-highlighted">-->
                                <!--<div class="tc-item-header-highlighted__icon">-->
                                    <!--<i class="material-icons material-icons">note</i>-->
                                <!--</div>-->
                                <!--<span class="tc-item-header-highlighted__primary">Notes</span>-->
                            <!--</div>-->
                            <span class="tc-accordion-header__title">{{'Notes' | translate}}</span>
                            <i class="material-icons tc-accordion-header__down-icon">keyboard_arrow_down</i>
                        </div>
                    </mat-expansion-panel-header>
                    <div class="tc-accordion-actions" *ngIf="!noteEditEnable()">
                        <i class="material-icons tc-icon-block__icon tc-icon-block__icon--active tc-icon-block"
                           [matTooltip]="'Add' | translate"
                           [matTooltipPosition]="'below'"
                           (click)="addGroupNote()">add</i>
                    </div>
                    <tc-reservation-service-group-notes
                            [selectedServiceGroupNotes]="selectedServiceGroupNotes"
                            [disableGroupNoteEdit]="noteEditEnable()"
                            (saveNote)="onNoteSave($event)"
                            (deleteNote)="onDelete($event)"
                            (isEditClicked)="onEdit($event)">
                    </tc-reservation-service-group-notes>
                </mat-expansion-panel>
            </mat-accordion>
        </ng-container>
    </tc-expanded-content-card-body>
</div>

