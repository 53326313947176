import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatAutocompleteModule, MatInputModule, MatMenuModule, MatSelectModule, MatSliderModule} from '@angular/material';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {PipesModule} from '@tc/pipes';
import {SupplierInformationComponent} from './supplier-information.component';

@NgModule({
    imports: [
        CommonModule,
        MatInputModule,
        ReactiveFormsModule,
        TranslateModule.forChild(),
        MatSelectModule,
        FormsModule,
        MatMenuModule,
        PipesModule,
        MatAutocompleteModule,
        MatSliderModule
    ],
  declarations: [SupplierInformationComponent],
    exports: [SupplierInformationComponent]
})
export class SupplierInformationModule { }
