<form [formGroup]="guideSpecificForm">

    <div class="container-fluid direct-client-contact-details-edit tc-card-wrapper">
        <div class="row">
            <div class="col-md-12 col-lg-12">
                <div class="tc-flex-box">
                    <div class="tc-flex-item tc-item-circle">
                        <div class="icon-box">
                            <i class="material-icons" aria-hidden="true">account_circle</i>
                        </div>
                    </div>
                    <div class="tc-flex-item tc-right-cage">

                        <div class="row">
                            <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                                <mat-form-field>
                                    <input
                                            matInput
                                            id=""
                                            #workingHours
                                            formControlName="workingHours"
                                            [placeholder]="'Working Hours'"
                                            required>
                                    <mat-error>{{getErrorMessage('workingHours')}}</mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                                <div class="tc-input-edit dcpiec-input-licenseNumber">
                                    <mat-form-field>
                                        <input
                                                matInput
                                                id="DIR_PER_INFO_EDIT_LICENSENUMBER"
                                                #licenseInput
                                                formControlName="licenseNumber"
                                                [placeholder]="'License Number'">
                                        <mat-error>{{getErrorMessage('licenseNumber')}}</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                                <div class="tc-input-edit dcpiec-input-passportNumber">
                                    <mat-form-field>
                                        <input
                                                matInput
                                                id="DIR_PER_INFO_EDIT_PASSPORTNUMBER"
                                                #passportInput
                                                formControlName="passportNumber"
                                                [placeholder]="'Passport Number'">
                                        <mat-error>{{getErrorMessage('passportNumber')}}</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                                <div class="tc-input-edit dcpiec-input-passportNumber">
                                    <mat-form-field>
                                        <input
                                                matInput
                                                formControlName="years"
                                                [placeholder]="'Years of Experience'">
                                        <mat-error>{{getErrorMessage('years')}}</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                            </div>
                        </div>
                        <div class="container-fluid">
                            <div class="row">
                                <div class="tc-chip-display__header item-header">
                                    <span>Language Fluency</span>
                                </div>
                                <div class="row">
                                    <div class="col-xs-12 col-sm-10">
                                        <div class="tc-action-chip-list-wrapper tc-chip-selector__action-chips">
                                            <div
                                                    class="tc-action-chip" *ngFor="let lang of addedLanguages"
                                                    (click)="toggleValue(lang)"
                                                    [ngClass]="languages.includes(lang) ?'tc-action-chip--active':'tc-action-chip--inactive' ">
                                                <span class="tc-action-chip__icon tc-action-chip__icon-man"></span>
                                                <span class="tc-action-chip__icon tc-action-chip__icon-select">
                                                    <i class="material-icons">done</i>
                                                </span>
                                                <div class="tc-action-chip__text">
                                                    {{lang}}
                                                </div>
                                            </div>
                                            <div
                                                    class="tc-action-chip" *ngFor="let lang of defaultLanguages"
                                                    (click)="toggleValue(lang.name)"
                                                    [ngClass]="languages.includes(lang.name)?'tc-action-chip--active':'tc-action-chip--inactive' ">
                                                <span class="tc-action-chip__icon tc-action-chip__icon-man"></span>
                                                <span class="tc-action-chip__icon tc-action-chip__icon-select">
                                                    <i class="material-icons">done</i>
                                                </span>
                                                <div class="tc-action-chip__text">
                                                    {{lang.name}}
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="col-xs-12 col-sm-2">

                                        <div class="tc-action-chip-list-wrapper tc-chip-selector__action-chips pull-right">
                                            <div class="tc-action-chip tc-action-chip--inactive" (click)="openDialog()">
                                                <span class="tc-action-chip__icon tc-action-chip__icon-man">
                                                        <i class="material-icons">add_circle</i>
                                                </span>
                                                <div class="tc-action-chip__text">
                                                    {{'Add More' | translate}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>

