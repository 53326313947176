<div class="" *ngIf="resultsList" id="supplier-assign-scroll-container">
  <ng-container
    *ngFor="let supplier of resultsList |tcAdvPager:pager.currPage:pager.itemsPerPage | tcAdvSorter:sortingHandler:sorter; let i = index;">

    <div class="">
      <div [ngClass]="hasSelectedThis(supplier)?'tc-expanded-content-card--selected':''"
        tcExpansionPanel
        tabindex="-1"
        [enabled]="true"
           (clickedOutside)="onOutsideClick(selectedSupplier)"
        (collapse)="expandedPanelIndex = -1">

        <!-- Content card Strip -->
        <div *tcExpansionPanelSummary id="clientSumExp_{{i}}">

          <tc-content-strip
            (actionClick)="onActionClick(supplier,$event)"
            (click)="onSupplierSelection(supplier,i)">

            <tc-result-content-card-resource-assign
              [summaryContent]="supplier">
            </tc-result-content-card-resource-assign>

          </tc-content-strip>
        </div>

        <div *tcExpansionPanelDetail id="clientDetailExp_{{i}}">
          <tc-expanded-content-card class="tc-expanded-content-card tc-form-view" [isEdit]="false">

            <tc-resource-expanded-content-header
              *ngIf="selectedSupplier"
              [icon]="selectedSupplier.icon"
              [headerText]="getHeader(selectedSupplier)"
              [secondaryHeaderText]="getSecondaryHeaderText(selectedSupplier)"
              (headerClick)="onHeaderClick(selectedSupplier)"
              (clickOutSide)="onOutsideClick(selectedSupplier)">
            </tc-resource-expanded-content-header>

            <tc-supplier-assign-detail-view
              *ngIf="selectedSupplier"
              [summaryCardData]="selectedSupplier"
              [footerDataMap]="footerDataMap"
              [bookingData]="bookingData"
              [bookingDataArray]="bookingDataArray"
              [isBulkAssignment]="isBulkAssignment"
              [assignedSupplier]="assignedSupplier"
              [isGenResourceAssignment]="isGenResourceAssignment"
              (headerClick)="onHeaderClick(selectedSupplier)"
              (clickOutSide)="onOutsideClick(selectedSupplier)">
            </tc-supplier-assign-detail-view>

          </tc-expanded-content-card>
        </div>
      </div>
    </div>
  </ng-container>
</div>
