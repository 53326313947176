import { Injectable } from '@angular/core';

@Injectable()
export class Constant {

    public get(ns: string, code: string): string {
        return TCO[ns][code];
    }
}

export namespace TCO {
    export namespace CONF {
        export const CONF_SUPPLIER_CRITERIA_CHIP = 'SUPPLIER_CRITERIA_CHIP_CONFIG';
        export const CONF_ENDPOINT = 'ENDPOINT_CONFIG';
        export const CONF_VERSION = 'VERSION_CONFIG';
        export const ERROR_CONFIG = 'ERROR_CONFIG';
        export const CONF_JOURNEYS = 'JOURNEY_CONFIG';
        export const CONF_CONTENT_LIST_CARD = 'CONTENT_LIST_CARD_CONFIG';
        export const CONF_PAGE_SECTION_CONFIG = 'PAGE_SECTION_CONFIG';
        export const CONF_SUPPLIER_CARD_CONFIG = 'SUPPLIER_CARD_CONFIG';
        export const CONF_PRODUCT_QUEUE_SEARCH_CRITERIA_CHIP_COMMON = 'COMMON_PRODUCT_QUEUE_CRITERIA_CHIP_CONFIG';
        export const CONF_PRODUCT_QUEUE_SEARCH_CRITERIA_CHIP_TOU = 'TOU_PRODUCT_QUEUE_CRITERIA_CHIP_CONFIG';
        export const CONF_PRODUCT_QUEUE_SEARCH_CRITERIA_CHIP_TRS = 'TRS_PRODUCT_QUEUE_CRITERIA_CHIP_CONFIG';
        export const CONF_PRODUCT_QUEUE_SEARCH_CRITERIA_CHIP_GEN = 'GEN_PRODUCT_QUEUE_CRITERIA_CHIP_CONFIG';
        export const CONF_PRODUCT_QUEUE_SEARCH_CRITERIA_CHIP_HOTEL = 'HOTEL_PRODUCT_QUEUE_CRITERIA_CHIP_CONFIG';
        export const CONF_RESERVATION_SEARCH_CRITERIA_CHIP = 'RESERVATION_CRITERIA_CHIP_CONFIG';
        export const CONF_GEN_RESERVATION_SEARCH_CRITERIA_CHIP = 'GEN_RESERVATION_CRITERIA_CHIP_CONFIG';
        export const CONF_RESERVATION_SEARCH_RESULTS_CONFIG = 'RESERVATION_SEARCH_RESULTS_CONFIG';
        export const CONF_GENERIC_SEARCH_RESULTS_CONFIG = 'GENERIC_SEARCH_RESULTS_CONFIG';
        export const CONF_BOOKING_SEARCH_RESULTS_CONFIG = 'BOOKING_SEARCH_RESULTS_CONFIG';
        export const CONF_ALLOCATION_FEATURE_CONFIG = 'ALLOCATION_FEATURE_CONFIG';
        export const CONF_BOOKING_ITEM_CARD_CONFIG = 'BOOKING_ITEM_CARD_CONFIG';
        export const CONF_DRIVER_SEARCH_RESULTS_CONFIG = 'DRIVER_SEARCH_RESULTS_CONFIG';
        export const CONF_GUIDE_SEARCH_RESULTS_CONFIG = 'GUIDE_SEARCH_RESULTS_CONFIG';
        export const CONF_VEHICLE_SEARCH_RESULTS_CONFIG = 'VEHICLE_SEARCH_RESULTS_CONFIG';
        export const CONF_SUPPLIER_DETAIL_VIEW_CONFIG = 'SUPPLIER_DETAIL_VIEW_CONFIG';
        export const CONF_ROOM_SEARCH_RESULTS_CONFIG = 'ROOM_RESULTS_CONFIG';
        export const CONF_SYSTEM = 'SYSTEM_CONFIG';
        export const CONF_RESERVATION_SERVICE_ITEMS_CARD_CONFIG = 'RESERVATION_SERVICE_ITEMS_CARD_CONFIG';
        export const CONF_RESERVATION_RESOURCE_ASSIGN_CONFIG = 'RESERVATION_RESOURCE_ASSIGN_CONFIG';
        export const CONF_ALLOCATIONV2_RESULT_CARD_CONFIG = 'ALLOCATIONV2_RESULT_CARD_CONFIG';
        export const CONF_PAGINATION_CONFIG = 'PAGINATION_CONFIG';
        export const CONF_BOOKING_IMPORT_CONFIG = 'BOOKING_IMPORT_CONFIG';
        export const CONF_BOOKING_IMPORT_SUMMARY_GRID = 'BOOKING_IMPORT_SUMMARY_GRID_CONFIG';
        export const CONF_BOOKING_SEARCH_CRITERIA_CHIP = 'BOOKING_SEARCH_CRITERIA_CHIP_CONFIG';
        export const CONF_VEHICLE_SEARCH_CRITERIA_CHIP = 'VEHICLE_SEARCH_CRITERIA_CHIP_CONFIG';
        export const CONF_ROOM_SEARCH_CRITERIA_CHIP = 'ROOM_SEARCH_CRITERIA_CHIP_CONFIG';
        export const CONF_GENERIC_SEARCH_CRITERIA_CHIP = 'GENERIC_SEARCH_CRITERIA_CHIP_CONFIG';
        export const CONF_ACC_RULE_SEARCH_CRITERIA_CHIP = 'ACC_RULE_SEARCH_CRITERIA_CHIP_CONFIG';
        export const CONF_ROOM_OCCUPANCY_SEARCH_CRITERIA_CHIP = 'ROOM_OCCUPANCY_SEARCH_CRITERIA_CHIP_CONFIG';
        export const CONF_LOCATIONS_SEARCH_CRITERIA_CHIP = 'LOCATIONS_SEARCH_CRITERIA_CHIP_CONFIG';
        export const CONF_MAPPED_RESOURCE_TYPES_SEARCH_CRITERIA_CHIP = 'MAPPED_RESOURCE_TYPES_SEARCH_CRITERIA_CHIP_CONFIG';
        export const CONF_LOCATION_GROUP_SEARCH_CRITERIA_CHIP = 'LOCATION_GROUP_SEARCH_CRITERIA_CHIP_CONFIG';
        export const CONF_CURRENCY_SEARCH_CRITERIA_CHIP = 'CURRENCY_SEARCH_CRITERIA_CHIP_CONFIG';
        export const CONF_PRODUCT_DETAILS_SEARCH_CRITERIA_CHIP = 'PRODUCT_DETAILS_SEARCH_CRITERIA_CHIP_CONFIG';
        export const CONF_DOCUMENT_QUEUE_SEARCH_CRITERIA_CHIP = 'DOCUMENT_QUEUE_SEARCH_CRITERIA_CHIP_CONFIG';
        export const CONF_COSTING_TYPE_SEARCH_CRITERIA_CHIP = 'COSTING_TYPE_SEARCH_CRITERIA_CHIP_CONFIG';
        export const CONF_BOARD_BASIS_SETUP_SEARCH_CRITERIA_CHIP = 'BOARD_BASIS_SETUP_SEARCH_CRITERIA_CHIP_CONFIG';
        export const CONF_RESOURCE_TYPE_SEARCH_CRITERIA_CHIP = 'RESOURCE_TYPE_SEARCH_CRITERIA_CHIP_CONFIG';
        export const CONF_PROFILE_TYPE_SEARCH_CRITERIA_CHIP = 'PROFILE_TYPE_SEARCH_CRITERIA_CHIP_CONFIG';
        export const CONF_ROUTES_SEARCH_CRITERIA_CHIP = 'ROUTES_SEARCH_CRITERIA_CHIP_CONFIG';
        export const CONF_LEDGER_ACCOUNTS_SEARCH_CRITERIA_CHIP = 'LEDGER_ACCOUNTS_SEARCH_CRITERIA_CHIP_CONFIG';
        export const CONF_MANIFESTS_SEARCH_CRITERIA_CHIP = 'MANIFESTS_SEARCH_CRITERIA_CHIP_CONFIG';
        export const CONF_REPORTS_SEARCH_CRITERIA_CHIP = 'REPORTS_SEARCH_CRITERIA_CHIP_CONFIG';
        export const CONF_LEDGER_ENTRY_SEARCH_CRITERIA_CHIP = 'LEDGER_ENTRY_SEARCH_CRITERIA_CHIP_CONFIG';
        export const CONF_LEAVE_SETUP_SEARCH_CRITERIA_CHIP = 'RESOURCE_LEAVE_CRITERIA_CHIP_CONFIG';
        export const CONF_OPERATOR_ACCEPTANCE_QUEUE_CHIP = 'OPERATOR_ACCEPTANCE_QUEUE_CHIP_CONFIG';
        export const CONF_RESOURCE_ACCEPTANCE_QUEUE_CHIP = 'RESOURCE_ACCEPTANCE_QUEUE_CHIP_CONFIG';
        export const CONF_SUPPLIER_ACCEPTANCE_QUEUE_CHIP = 'SUPPLIER_ACCEPTANCE_QUEUE_CHIP_CONFIG';
        export const CONF_NOTIFICATION_QUEUE_CHIP = 'NOTIFICATION_QUEUE_CHIP_CONFIG';
        export const CONF_ALLOCATION_FEATURE_QUEUE_CHIP = 'ALLOCATION_FEATURE_QUEUE_CHIP_CONFIG';
        export const CONF_RESOURCE_ALLOCATION_CHIP = 'RESOURCE_ALLOCATION_CHIP_CONFIG';
        export const CONF_SUPPLIER_ALLOCATION_CHIP = 'SUPPLIER_ALLOCATION_CHIP_CONFIG';
        export const CONF_ACCOM_SUPPLIER_ALLOCATION_CHIP = 'ACCOM_SUPPLIER_ALLOCATION_CHIP_CONFIG';
        export const CONF_EVENT_MANAGER = 'EVENT_MANAGER_CONFIG';
        export const LOCATION_GROUP_RESULT_CARD_CONFIG = 'LOCATION_GROUP_RESULT_CARD_CONFIG';
        export const CONF_NAVIGATION = 'NAVIGATION_CONFIG';
        export const CONF_FOCUS_VIEW = 'FOCUS_VIEW_CONFIG';
        export const CONF_TRANSFER_RESOURCE_COST = 'TRANSFER_RESOURCE_COST_CONFIG';
        export const CONF_GENERIC_QUEUE_ICONS = 'GENERIC_QUEUE_ICONS_CONFIG';
        export const CONF_GENERIC_QUEUE_COL_DEF = 'GENERIC_QUEUE_COL_DEF_CONFIG';
        export const CONF_RESOURCE_ACCEPTANCE_QUEUE_COL_DEF = 'RESOURCE_ACCEPTANCE_QUEUE_COL_DEF_CONFIG';
        export const CONF_OPERATOR_ACCEPTANCE_QUEUE_COL_DEF = 'OPERATOR_ACCEPTANCE_QUEUE_COL_DEF_CONFIG';
        export const CONF_SUPPLIER_ACCEPTANCE_QUEUE_COL_DEF = 'SUPPLIER_ACCEPTANCE_QUEUE_COL_DEF_CONFIG';
        export const CONF_SUPPLIER_ACCEPTANCE_QUEUE_ALL_COL_DEF = 'SUPPLIER_ACCEPTANCE_QUEUE_ALL_COL_DEF_CONFIG';
        export const CONF_NOTIFICATION_QUEUE_COL_DEF = 'NOTIFICATION_QUEUE_COL_DEF_CONFIG';
        export const CONF_PASSENGER_GRID_COL_DEF_CONFIG = 'PASSENGER_GRID_COL_DEF_CONFIG';
        export const CONF_LEAVE_SETUP_SEARCH_RESULT_CONFIG = 'LEAVE_SETUP_SEARCH_RESULT_CONFIG';
        export const CONF_TIMELINE_UNIT_CONFIG = 'TIMELINE_UNIT_CONFIG';
        export const CONF_ACCOUNTING_RULE_SETUP = 'ACCOUNTING_RULE_SETUP_CONFIG';
        export const CONF_LEDGER_ACCOUNT_SETUP = 'LEDGER_ACCOUNT_SETUP_CONFIG';
        export const CONF_LOCATIONS_SETUP = 'LOCATIONS_SETUP_CONFIG';
        export const CONF_MAPPED_RESOURCE_TYPE_SETUP = 'MAPPED_RESOURCE_TYPE_SETUP_CONFIG';
        export const CONF_LOCATIONS_SELECTION_GRID = 'LOCATIONS_SELECTION_GRID_CONFIG';
        export const CONF_JOB_PARAMETER_GRID = 'JOB_PARAMETER_GRID_CONFIG';
        export const CONF_LOCATION_GROUPS_SETUP = 'LOCATION_GROUPS_SETUP_CONFIG';
        export const CONF_ROUTES_SETUP = 'ROUTES_SETUP_CONFIG';
        export const CONF_CONFIG_SETUP = 'CONFIG_GIRD_CONFIG';
        export const CONF_JOB_SETUP = 'JOB_SETUP_GRID_CONFIG';
        export const CONF_JOB_HISTORY_SETUP = 'JOB_HISTORY_SETUP_GRID_CONFIG';
        export const CONF_ROOM_TYPE_SETUP = 'ROOM_TYPE_SETUP_GRID_CONFIG';
        export const CONF_ROOM_FACILITY_SETUP = 'ROOM_FACILITY_SETUP_GRID_CONFIG';
        export const CONF_ROOM_OCCUPANCY_SETUP = 'ROOM_OCCUPANCY_SETUP_GRID_CONFIG';
        export const CONF_MANIFESTS_GRID = 'MANIFESTS_GRID_CONFIG';
        export const CONF_REPORTS_GRID = 'REPORTS_GRID_CONFIG';
        export const CONF_DISPATCH_ENTRIES = 'DISPATCH_ENTRIES_CONFIG';
        export const CONF_DOCUMENT_QUEUE_FOCUS_COL_DEF_CONFIG = 'DOCUMENT_QUEUE_FOCUS_COL_DEF_CONFIG';
        export const CONF_CURRENCY_SETUP = 'CURRENCY_SETUP_CONFIG';
        export const CONF_PRODUCT_DETAILS_SETUP = 'PRODUCT_DETAILS_SETUP_CONFIG';
        export const CONF_DOCUMENT_QUEUE_COL_DEF_CONFIG = 'DOCUMENT_QUEUE_COL_DEF_CONFIG';
        export const CONF_COSTING_TYPE_SETUP = 'COSTING_TYPE_SETUP_CONFIG';
        export const CONF_COMPANY_SETUP = 'COMPANY_SETUP_CONFIG';
        export const CONF_BRAND_SETUP_CONFIG = 'BRAND_SETUP_CONFIG';
        export const CONF_DIVISION_SETUP_CONFIG = 'DIVISION_SETUP_CONFIG';
        export const CONF_DISTRIBUTION_CHANNEL_SETUP_CONFIG = 'DISTRIBUTION_CHANNEL_SETUP_CONFIG';
        export const CONF_BOARD_BASIS_SETUP = 'BOARD_BASIS_SETUP_CONFIG';
        export const CONF_LEDGER_ENTRY_GRID = 'LEDGER_ENTRY_GRID_CONFIG';
        export const CONF_NOTES_GRID = 'NOTES_GRID_CONFIG';
        export const CONF_PASSENGERS_GRID = 'PASSENGERS_GRID_CONFIG';
        export const CONF_USER_ROLES_GRID = 'USER_ROLES_GRID_CONFIG';
        export const CONF_RESOURCE_TYPE_SETUP = 'RESOURCE_TYPE_SETUP_CONFIG';
        export const CONF_PROFILE_TYPE_SETUP = 'PROFILE_TYPE_SETUP_CONFIG';
        export const CONF_PRODUCT_QUEUE_COL_DEF_CONFIG = 'PRODUCT_QUEUE_COL_DEF_CONFIG';
        export const CONF_BOOKING_QUEUE_COL_DEF_CONFIG = 'BOOKING_QUEUE_COL_DEF_CONFIG';
        export const CONF_BOOKING_QUEUE_ALL_COL_DEF_CONFIG = 'BOOKING_QUEUE_ALL_COL_DEF_CONFIG';
        export const CONF_SERVICE_QUEUE_COL_DEF_CONFIG = 'SERVICE_QUEUE_COL_DEF_CONFIG';
        export const CONF_SERVICE_QUEUE_ALL_COL_DEF_CONFIG = 'SERVICE_QUEUE_ALL_COL_DEF_CONFIG';
        export const CONF_COL_DEF_SERVICE_QUEUE_PROFILES_CONFIG = 'COL_DEF_SERVICE_QUEUE_PROFILES_CONFIG';
        export const CONF_COL_DEF_BOOKING_QUEUE_PROFILES_CONFIG = 'COL_DEF_BOOKING_QUEUE_PROFILES_CONFIG';
        export const CONF_TBL_DEF_BOOKING_QUEUE_ACT_BUTTON_CONFIG = 'TBL_DEF_BOOKING_QUEUE_ACTION_BUTTON_CONFIG';
        export const CONF_TRANSPORT_COST_SETUP_GRID_CONFIG = 'TRANSPORT_COST_SETUP_GRID_CONFIG';
        export const CONF_GENERIC_COST_SETUP_GRID_CONFIG = 'GENERIC_COST_SETUP_GRID_CONFIG';
        export const CONF_ROOM_TYPE_SEARCH_CRITERIA_CHIP = 'ROOM_TYPE_SEARCH_CRITERIA_CHIP_CONFIG';
        export const CONF_COMPANY_SETUP_SEARCH_CRITERIA_CHIP_CONFIG = 'COMPANY_SETUP_SEARCH_CRITERIA_CHIP_CONFIG';
        export const CONF_BRAND_SETUP_SEARCH_CRITERIA_CHIP_CONFIG = 'BRAND_SETUP_SEARCH_CRITERIA_CHIP_CONFIG';
        export const CONF_DIVISION_SETUP_SEARCH_CRITERIA_CHIP_CONFIG = 'DIVISION_SETUP_SEARCH_CRITERIA_CHIP_CONFIG';
        export const CONF_DISTRIBUTION_CHANNEL_SETUP_SEARCH_CRITERIA_CHIP_CONFIG = 'DISTRIBUTION_CHANNEL_SETUP_SEARCH_CRITERIA_CHIP_CONFIG';
        export const CONF_ROOM_FACILITY_SEARCH_CRITERIA_CHIP = 'ROOM_FACILITY_SEARCH_CRITERIA_CHIP_CONFIG';
        export const CONF_CONFIGURATION_SEARCH_CRITERIA_CHIP = 'CONFIGURATION_SEARCH_CRITERIA_CHIP_CONFIG';
        export const CONF_JOB_SEARCH_CRITERIA_CHIP = 'JOB_SEARCH_CRITERIA_CHIP_CONFIG';
        export const CONF_JOB_HISTORY_SEARCH_CRITERIA_CHIP = 'JOB_HISTORY_SEARCH_CRITERIA_CHIP_CONFIG';
        export const CONF_GRADES_CONFIG = 'GRADES_CONFIG';
        export const CONF_PREFERENCE_CONFIG = 'PREFERENCE_CONFIG';
        export const CONF_PAGE_OPTION_AVAILABILITY = 'PAGE_OPTION_AVAILABILITY_CONFIG';
        export const CONF_DOCUMENT_RULE = 'DOCUMENT_RULE_CONFIG';
        export const CONF_MANUAL_SEND_MANIFEST_MODAL = 'MANUAL_SEND_MANIFEST_MODAL_CONFIG';
        export const CONF_AUTH = 'AUTH_CONFIG';
        export const CONF_MESSAGING = 'MESSAGING_CONFIG';
        export const CONF_FORMAT = 'FORMAT_CONFIG';
    }
    export namespace AppData {
        export const RESERVATION_SEARCH_CRITERIA = 'AD_RESERVATION_SEARCH_CRITERIA';
        export const GEN_RESERVATION_SEARCH_CRITERIA = 'AD_GEN_RESERVATION_SEARCH_CRITERIA';
        export const PRODUCT_QUEUE_SEARCH_CRITERIA_COMMON = 'AD_PRODUCT_QUEUE_SEARCH_CRITERIA_COMMON';
        export const PRODUCT_QUEUE_SEARCH_CRITERIA_TOU = 'AD_PRODUCT_QUEUE_SEARCH_CRITERIA_TOU';
        export const PRODUCT_QUEUE_SEARCH_CRITERIA_TRS = 'AD_PRODUCT_QUEUE_SEARCH_CRITERIA_TRS';
        export const PRODUCT_QUEUE_SEARCH_CRITERIA_GEN = 'AD_PRODUCT_QUEUE_SEARCH_CRITERIA_GEN';
        export const PRODUCT_QUEUE_SEARCH_CRITERIA_HOTEL = 'AD_PRODUCT_QUEUE_SEARCH_CRITERIA_HOTEL';
        export const VEHICLE_SEARCH_CRITERIA = 'AD_VEHICLE_SEARCH_CRITERIA';
        export const ACC_RULE_SEARCH_CRITERIA = 'AD_ACC_RULE_SEARCH_CRITERIA';
        export const ROOM_OCCUPANCY_SEARCH_CRITERIA = 'AD_OCCUPANCY_SEARCH_CRITERIA';
        export const LOCATIONS_SEARCH_CRITERIA = 'AD_LOCATIONS_SEARCH_CRITERIA';
        export const MAPPED_RESOURCE_TYPE_SEARCH_CRITERIA = 'AD_MAPPED_RESOURCE_TYPE_SEARCH_CRITERIA';
        export const LOCATION_GROUPS_SEARCH_CRITERIA = 'AD_LOCATION_GROUPS_SEARCH_CRITERIA';
        export const COMPANY_SETUP_SEARCH_CRITERIA = 'AD_COMPANY_SETUP_SEARCH_CRITERIA';
        export const DIVISION_SETUP_SEARCH_CRITERIA = 'AD_DIVISION_SETUP_SEARCH_CRITERIA';
        export const BRAND_SEARCH_CRITERIA = 'AD_BRAND_SEARCH_CRITERIA';
        export const DISTRIBUTION_CHANNEL_SEARCH_CRITERIA = 'AD_DISTRIBUTION_CHANNEL_SEARCH_CRITERIA';
        export const CURRENCY_SEARCH_CRITERIA = 'AD_CURRENCY_SEARCH_CRITERIA';
        export const PRODUCT_DETAILS_SEARCH_CRITERIA = 'AD_PRODUCT_DETAILS_SEARCH_CRITERIA';
        export const DOCUMENT_QUEUE_SEARCH_CRITERIA = 'AD_DOCUMENT_QUEUE_SEARCH_CRITERIA';
        export const COSTING_TYPE_SEARCH_CRITERIA = 'AD_COSTING_TYPE_SEARCH_CRITERIA';
        export const PROFILE_TYPE_SEARCH_CRITERIA = 'AD_PROFILE_TYPE_SEARCH_CRITERIA';
        export const RESOURCE_TYPE_SEARCH_CRITERIA = 'AD_RESOURCE_TYPE_SEARCH_CRITERIA';
        export const ROUTES_SEARCH_CRITERIA = 'AD_ROUTES_SEARCH_CRITERIA';
        export const LEDGER_ACCOUNTS_SEARCH_CRITERIA = 'AD_LEDGER_ACCOUNTS_SEARCH_CRITERIA';
        export const MANIFESTS_SEARCH_CRITERIA = 'AD_MANIFESTS_SEARCH_CRITERIA';
        export const REPORTS_SEARCH_CRITERIA = 'AD_REPORTS_SEARCH_CRITERIA';
        export const LEDGER_ENTRY_SEARCH_CRITERIA = 'AD_LEDGER_ENTRY_SEARCH_CRITERIA';
        export const SUPPLIER_SEARCH_CRITERIA = 'AD_SUPPLIER_SEARCH_CRITERIA';
        export const GENERIC_SEARCH_CRITERIA = 'AD_GENERIC_SEARCH_CRITERIA';
        export const ROOM_SEARCH_CRITERIA = 'AD_ROOM_SEARCH_CRITERIA';
        export const BOOKING_SEARCH_CRITERIA = 'AD_BOOKING_SEARCH_CRITERIA';
        export const DEF_CURRENCY_TYPE = 'AED';
        export const SUPPLIER = 'AD_SUPPLIER';
        export const RESOURCE = 'AD_RESOURCE';
        export const BOOKING = 'AD_BOOKING';
        export const LEAVE_SETUP_SEARCH_CRITERIA = 'LEAVE_SETUP_SEARCH_CRITERIA';
        export const OPERATOR_ACCEPTANCE_SEARCH_CRITERIA = 'OPERATOR_ACCEPTANCE_SEARCH_CRITERIA';
        export const RESOURCE_ACCEPTANCE_SEARCH_CRITERIA = 'RESOURCE_ACCEPTANCE_SEARCH_CRITERIA';
        export const SUPPLIER_ACCEPTANCE_SEARCH_CRITERIA = 'SUPPLIER_ACCEPTANCE_SEARCH_CRITERIA';
        export const NOTIFICATION_SEARCH_CRITERIA = 'NOTIFICATION_SEARCH_CRITERIA';
        export const RESOURCE_ALLOCATION_SEARCH_CRITERIA = 'RESOURCE_ALLOCATION_SEARCH_CRITERIA';
        export const SUPPLIER_ALLOCATION_SEARCH_CRITERIA = 'SUPPLIER_ALLOCATION_SEARCH_CRITERIA';
        export const ACCOM_SUPPLIER_ALLOCATION_SEARCH_CRITERIA = 'ACCOM_SUPPLIER_ALLOCATION_SEARCH_CRITERIA';
        export const ROOM_TYPE_SEARCH_CRITERIA = 'ROOM_TYPE_SEARCH_CRITERIA';
        export const ROOM_FACILITY_SEARCH_CRITERIA = 'ROOM_FACILITY_SEARCH_CRITERIA';
        export const QUEUE_VERSION_1 = 'QUEUE_VERSION_1';
        export const QUEUE_VERSION_2 = 'QUEUE_VERSION_2';
        export const BOARD_BASIS_SETUP_SEARCH_CRITERIA = 'BOARD_BASIS_SETUP_SEARCH_CRITERIA';
        export const ALLOCATION_FEATURE_SEARCH_CRITERIA = 'ALLOCATION_FEATURE_SEARCH_CRITERIA';
        export const CONFIGURATION_SEARCH_CRITERIA = 'CONFIGURATION_SEARCH_CRITERIA';
        export const JOB_SEARCH_CRITERIA = 'JOB_SEARCH_CRITERIA';
        export const JOB_HISTORY_SEARCH_CRITERIA = 'JOB_HISTORY_SEARCH_CRITERIA';
        export const USER_BUSINESS_TYPE = 'USER_BUSINESS_TYPE';
        export const GMT_TIME_ZONE = 'GMT';
    }

    export namespace EMPTY_RESULT_PAGE_MESSAGE {
        export const SUPPLIER_RESULTS = 'No Suppliers Found';
    }

    export namespace URL {
        export const AUTH_USER_LOGIN = 'auth_user_login';
        export const AUTH_USER_LOGIN_OVERRIDE = 'auth_user_login_override';
        export const AUTH_USER_PROFILE = 'auth_user_profile';
        export const AUTH_USER_WAITING = 'auth_user_waiting';
    }
}
