import { Component } from '@angular/core';
import { ChipInputService } from '@tc/chip-input/chip-input.service';
import { TCO } from '../../../../constants';
import { JobSearchCriteria } from '../../../../models/criteria/job-search-criteria';
import { ChipHandlerService } from '../../../../services/helpers/chip-handler.service';
import { DataKey } from '../../../../services/util/framework/data-store.service';

@Component({
    selector: 'tc-job-search-criteria',
    templateUrl: './job-search-criteria.component.html',
    providers: [
        ChipHandlerService,
        {provide: 'ChipHandlerService', useExisting: ChipHandlerService},
        ChipInputService
    ]
})
export class JobSearchCriteriaComponent
{
    inputSearchCriteria = new JobSearchCriteria();
    searchCriteriaUpdatingDataKey = DataKey.commonJobSearchCriteria;
    searchResultsDataKey = DataKey.commonJobSearchResults;
    searchCriteriaLoadingDataKey = TCO.AppData.JOB_SEARCH_CRITERIA;
    chipConfigKey = TCO.CONF.CONF_JOB_SEARCH_CRITERIA_CHIP;
    placeHolderText = 'Search Jobs';

    createNewSearchCriteriaFn = () => {
        const jobSearchCriteria = new JobSearchCriteria();
        jobSearchCriteria.jobName = '';
        jobSearchCriteria.lockStatus = 'ANY';
        jobSearchCriteria.jobHistoryStatus = 'ANY';
        return jobSearchCriteria;
    };

    clearLoadedSearchCriteriaFn = (searchCriteria: JobSearchCriteria) => {
    };

}
