export class GenBookingItem {
    id: number;
    bookingItemId: number;

    description: string;

    /* main type */
    sourceProductCode: string; // if changed in dmc these will not be change productCode will be changed
    sourceProductName: string; // if changed in dmc these will not be change productName will be changed

    productCode: string;
    productName: string;

    /* sub type */
    typeCode: string;
    typeName: string;

    /* hotel related data */
    hotelRoomType: string;
    hotelRoomTypeName: string;
    hotelBoardBasis: string;
    hotelBoardBasisName: string;
    occupancyCode: string;
    occupancyName: string;
    bedding: string;
    city: string;
    country: string;
    occAdultCount;
    occTeenCount;
    occChildCount;
    occInfantCount;
    noOfRoomsRequired;
    cityName;
    countryName;

}
