<div class="container-fluid">
    <mat-accordion *ngIf="booking.bookingItems && booking.bookingItems.length > 0" [multi]=false class="tc-accordion">
        <ng-container *ngFor="let bookingItem of booking.bookingItems; index as i">
            <mat-expansion-panel class="tc-item-summary-expansion"
                                 #expPanel
                                 [attr.id]="'expansionPanel-'+i"
                                 (opened)="onExpand($event, i)"
                                 [hideToggle]="true">
                <mat-expansion-panel-header [collapsedHeight]="'52px'" [expandedHeight]="'68px'" class="tc-item-summary-expansion__header">
                    <tc-item-summary-detail-view
                            *ngIf="bookingItem && summaryCards"
                            [hidden]="expPanel.expanded"
                            [summaryCard]="summaryCards[i]"
                            [isEditable]="!readonly"
                            [bookingItem]="bookingItem"
                            [showIcons]="hoveredIndex === i"
                            (mouseleave)="hideIcons()"
                            (mouseenter)="showIcons(i)"
                            class="">

                    </tc-item-summary-detail-view>
                    <div [hidden]="!expPanel.expanded" class="expand-heading">
                        <tc-item-summary-detail-view
                                id="booking-item-summary-{{i}}"
                                *ngIf="bookingItem && summaryCards"
                                [isEditable]="!readonly"
                                [summaryCard]="summaryCards[i]"
                                [bookingItem]="bookingItem"
                                [showIcons]="bookingItem===selectedItem"
                                [isHeader]="true"
                                (passengersClick)="onPassengersClick($event)">
                        </tc-item-summary-detail-view>
                    </div>

                </mat-expansion-panel-header>

                <tc-trs-item-detail-view
                        id="trs-item-detail-view-{{i}}"
                        *ngIf="getItemType(bookingItem)=='trs'"
                        [bookingItem]="bookingItem"
                        [noteTypes]="noteTypes"
                        [trsModes]="trsModes"
                        [readonly]="readonly"
                        (changeValidity)=onChangeValidity(bookingItem,$event)>
                </tc-trs-item-detail-view>

                <tc-gen-item-detail-view
                        id="gen-item-detail-view-{{i}}"
                        *ngIf="getItemType(bookingItem)=='gen'"
                        [bookingItem]="bookingItem"
                        [noteTypes]="noteTypes"
                        [readonly]="readonly"
                        (changeValidity)=onChangeValidity(bookingItem,$event)>
                </tc-gen-item-detail-view>

                <tc-accom-item-detail-view
                        id="accom-item-detail-view-{{i}}"
                        *ngIf="getItemType(bookingItem)=='accom'"
                        [bookingItem]="bookingItem"
                        [noteTypes]="noteTypes"
                        [readonly]="readonly"
                        (changeValidity)=onChangeValidity(bookingItem,$event)>
                </tc-accom-item-detail-view>
            </mat-expansion-panel>
        </ng-container>
    </mat-accordion>

    <ng-container *ngIf="!(this.booking.bookingItems && this.booking.bookingItems.length)">
        <div class="tc-card-placeholder  tc-card-placeholder--space-vertical">
            <i class="material-icons tc-card-placeholder__icon">assignment_error</i>
            <span class="tc-ag-grid-empty__text">{{'No Booking Items' | translate}}</span>
        </div>
    </ng-container>

</div>
