<div class="container-fluid tc-item-wrapper tcpi-card">
    <div class="tc-item-wrapper__body">
        <div class="row">
            <div class="col-md-12">
                <div class="tc-flex-box">
                    <div class="tc-flex-item icon-cage input-icon">
                        <div class="card-icon--small icn-color-primary"><i class="material-icons">account_circle</i>
                        </div>
                    </div>
                    <div class="tc-flex-item input-cage">
                        <div class="col-xs-12 col-sm-6  col-md-3 col-lg-3">
                            <div class="tc-content--wrap tc-input-view">
                                <div class="tc-caption-txt txt-light">
                                    {{"Code" | translate}}
                                </div>
                                <div id="TRD_PER_INFO_CLI_NAME" class="tc-body1--txt">{{supplier?.code ?
                                    supplier?.code : EMPTY_CHAR}}
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-6  col-md-3 col-lg-3">
                            <div class="tc-content--wrap tc-input-view">
                                <div class="tc-caption-txt txt-light">{{"Locale" | translate}}
                                </div>
                                <div id="TRADE_PER_INFO_CLI_TYPE" class="tc-body1--txt  ">{{supplier?.localeName ?
                                    supplier?.localeName : EMPTY_CHAR}}
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-6  col-md-3 col-lg-3">
                            <div class="tc-content--wrap tc-input-view">
                                <div class="tc-caption-txt txt-light">{{"Business Type" | translate}}
                                </div>
                                <div
                                        id="TRADE_PER_INFO_BUSINESS_TYPE"
                                        class="tc-body1--txt  ">{{supplier?.businessType ?
                                    supplier?.businessType : EMPTY_CHAR}}
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                            <div class="tc-content--wrap">
                                <div class="card-chips item-right">
                                    <div
                                            id="TRD_PER_INFO_CLI_STATUS"
                                            *ngIf="supplier?.activeStatus !== false"
                                            [ngClass]="supplier?.activeStatus ?'active':'' "
                                            class="chip">
                                        {{"Active" | translate}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="tc-flex-box">
                    <div class="tc-flex-item icon-cage ">
                        <div class="card-icon--small icn-color-primary"></div>
                    </div>
                    <div class="tc-flex-item input-cage ">
                        <div class="col-xs-12 col-sm-6  col-md-3 col-lg-3">
                            <div class="tc-content--wrap tc-input-view">
                                <div
                                        id="TRD_PER_INFO_REF"
                                        class="tc-caption-txt txt-light">{{"Registration Number" | translate}}
                                </div>
                                <div class="tc-body1--txt">{{supplier?.registrationNum ? supplier?.registrationNum : EMPTY_CHAR}}</div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-6  col-md-3 col-lg-3">
                            <div class="tc-content--wrap tc-input-view">
                                <div
                                        id="TRD_PER_INFO_HOD_REF"
                                        class="tc-caption-txt txt-light">{{"License Number" | translate}}
                                </div>
                                <div class="tc-body1--txt">{{supplier?.licenseNum ? supplier?.licenseNum : EMPTY_CHAR}}</div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-6  col-md-3 col-lg-3">
                            <div class="tc-content--wrap tc-input-view">
                                <div class="tc-caption-txt txt-light">{{"Resource Type" | translate}}
                                </div>
                                <div
                                        id="TRADE_PER_INFO_EXT_REF"
                                        class="tc-body1--txt  "
                                        style="max-width: 300px"
                                        [matTooltip]="supplier?.supplierItems && supplier.supplierItems.length > 0 ? getSupplierItems(supplier.supplierItems) : EMPTY_CHAR">
                                    {{supplier?.supplierItems && supplier?.supplierItems.length > 0 ? getSupplierItems(supplier.supplierItems) : EMPTY_CHAR}}
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-6  col-md-3 col-lg-3">
                            <div class="tc-content--wrap tc-input-view">
                                <div class="tc-caption-txt txt-light">{{"Supplier Type" | translate}}
                                </div>
                                <div class="tc-body1--txt  ">{{
                                    supplier?.supplierTypeName ? supplier?.supplierTypeName : EMPTY_CHAR}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="tc-flex-box">
                    <div class="tc-flex-item icon-cage ">
                        <div class="card-icon--small icn-color-primary"></div>
                    </div>
                    <div class="tc-flex-item input-cage ">
                        <div class="col-xs-12 col-sm-6  col-md-3 col-lg-3">
                            <div class="tc-content--wrap tc-input-view">
                                <div
                                        id="TRD_PER_INFO_COU"
                                        class="tc-caption-txt txt-light">{{"Country" | translate}}
                                </div>
                                <div class="tc-body1--txt">{{supplier?.countryName ? supplier?.countryName : EMPTY_CHAR}}</div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-6  col-md-3 col-lg-3">
                            <div class="tc-content--wrap tc-input-view">
                                <div
                                        id="TRD_PER_INFO_HOD_STA"
                                        class="tc-caption-txt txt-light">{{"State" | translate}}
                                </div>
                                <div class="tc-body1--txt">{{supplier?.stateName ? supplier?.stateName : EMPTY_CHAR}}</div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-6  col-md-3 col-lg-3">
                            <div class="tc-content--wrap tc-input-view">
                                <div class="tc-caption-txt txt-light">{{"City" | translate}}
                                </div>
                                <div id="TRADE_PER_INFO_EXT_CIT" class="tc-body1--txt  ">{{
                                    supplier?.cityName ? supplier.cityName : EMPTY_CHAR}}
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-6  col-md-3 col-lg-3">
                            <div class="tc-content--wrap tc-input-view">
                                <div class="tc-caption-txt txt-light">{{getCardPlaceHolders('STREET_ADDRESS')?.title | translate}}
                                </div>
                                <div
                                        class="tc-body1--txt  "
                                        [matTooltip]=" supplier?.streetAddress ? supplier?.streetAddress : EMPTY_CHAR">{{
                                    supplier?.streetAddress ? supplier?.streetAddress : EMPTY_CHAR}}
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-6  col-md-3 col-lg-3">
                            <div class="tc-content--wrap tc-input-view">
                                <div class="tc-caption-txt txt-light">{{"Post Code" | translate}}
                                </div>
                                <div class="tc-body1--txt  ">{{
                                    supplier?.postCode ? supplier?.postCode : EMPTY_CHAR}}
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-6  col-md-3 col-lg-3">
                            <div class="tc-content--wrap tc-input-view">
                                <div class="tc-caption-txt txt-light">{{"Preference" | translate}}
                                </div>
                                <div class="tc-body1--txt  ">{{
                                    supplier?.preference ? supplier?.preference : EMPTY_CHAR}}
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-6  col-md-3 col-lg-3">
                            <div class="tc-content--wrap tc-input-view">
                                <div class="tc-caption-txt txt-light">{{"Grade" | translate}}
                                </div>
                                <div class="tc-body1--txt  ">{{getGradeValue(supplier.grade)}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--<div class="tc-item-wrapper__header tc-item-header-highlighted">
      <div class="tc-item-header-highlighted__icon">
        <i class="material-icons material-icons&#45;&#45;fa">fa_address_book</i>
      </div>
      <div class="tc-item-header-highlighted__primary">{{"Contact details" | translate}}</div>
    </div>
    <div class="tc-item-wrapper__body">
      <div class="row">
        <div class="col-md-12">
          <div class="tc-flex-box">
            <div class="tc-flex-item icon-cage ">
              <div class="card-icon&#45;&#45;small icn-color-primary"></div>
            </div>
            <div class="tc-flex-item input-cage ">
              <div class="row">
                <div class="col-xs-12 col-sm-6  col-md-4 col-lg-4">
                  <div class="tc-content&#45;&#45;wrap tc-input-view">
                    <div id="TRD_PER_INFO_ADDRESS" class="tc-caption-txt txt-light">{{"Address" | translate}}
                    </div>
                    <div class="tc-body1&#45;&#45;txt ">
                      &lt;!&ndash;can not check houseNo != '0' if it is not coming when masked&ndash;&gt;
                      <ng-container
                              id="TRD_PER_INFO_HOU_NO"
                              *ngIf="client?.summary?.address?.houseNo  && client?.summary?.address?.houseNo != '0'">
                        {{client?.summary?.address?.houseNo | tcAuthMask : (authConfigComponent + '.CLIENT_ADDRESS'): '***********'}},
                      </ng-container>
                      <ng-container
                              id="TRD_PER_INFO_STREET"
                              *ngIf="(client?.summary?.address?.street | tcAuthMask : (authConfigComponent + '.CLIENT_ADDRESS'): '***********') as result">
                        {{result}}<br>
                      </ng-container>
                      <ng-container
                              id="TRD_PER_INFO_CITY"
                              *ngIf="(client?.summary?.address?.city | tcAuthMask : (authConfigComponent + '.CLIENT_ADDRESS'): '***********') as result">
                        {{result}},
                      </ng-container>
                      <ng-container
                              id="TRD_PER_INFO_STATE"
                              *ngIf="(client?.summary?.address?.county | tcAuthMask : (authConfigComponent + '.CLIENT_ADDRESS'): '***********') as result">
                        {{result}}<br>
                      </ng-container>
                      <ng-container
                              id="TRD_PER_INFO_POST_CODE"
                              *ngIf="(client?.summary?.address?.postalCode | tcAuthMask : (authConfigComponent + '.CLIENT_ADDRESS'): '***********') as result">
                        {{result}},
                      </ng-container>
                      <ng-container id="TRD_PER_INFO_COUNTRY"
                                    *ngIf="(client?.summary?.address?.country | tcAuthMask : (authConfigComponent + '.CLIENT_ADDRESS'): '***********') as result">
                        {{result}}<br>
                      </ng-container>
                    </div>
                  </div>
                </div>
                <div class="col-xs-12 col-sm-6  col-md-3 col-lg-3">
                  <div class="tc-content&#45;&#45;wrap tc-input-view">
                    <div class="tc-caption-txt txt-light">{{"Email" | translate}}
                    </div>
                    <div
                            id="TRD_PER_INFO_EMAIL"
                            class="tc-body1&#45;&#45;txt  ">
                      {{(getContact(client?.summary?.address ,CLIENT_CONTACTS_CONST.email) ? getContact(client?.summary?.address ,CLIENT_CONTACTS_CONST.email) :EMPTY_CHAR)
                        | tcAuthMask : (authConfigComponent + '.CLIENT_ADDRESS'): '***********'}}
                    </div>
                  </div>
                  <div class="tc-content&#45;&#45;wrap tc-input-view">
                    <div class="tc-caption-txt txt-light">
                      Fax
                    </div>
                    <div
                            id="TRD_PER_INFO_FAX"
                            class="tc-body1&#45;&#45;txt  ">
                      {{(getContact(client?.summary?.address ,CLIENT_CONTACTS_CONST.fax) ? getContact(client?.summary?.address ,CLIENT_CONTACTS_CONST.fax) :EMPTY_CHAR)
                        | tcAuthMask : (authConfigComponent + '.CLIENT_ADDRESS'): '***********'}}
                    </div>
                  </div>
                </div>
                <div class="col-xs-12 col-sm-6  col-md-3 col-lg-3">
                  <div class="tc-content&#45;&#45;wrap tc-input-view">
                    <div class="tc-caption-txt txt-light">{{"Telephone" | translate}}
                    </div>
                    <div
                            id="TRD_PER_INFO_PHONE"
                            class="tc-body1&#45;&#45;txt  ">
                      {{(getContact(client?.summary?.address ,CLIENT_CONTACTS_CONST.telephone) ? getContact(client?.summary?.address ,CLIENT_CONTACTS_CONST.telephone) :EMPTY_CHAR)
                        | tcAuthMask : (authConfigComponent + '.CLIENT_ADDRESS'): '***********'}}
                    </div>
                  </div>
                  <div class="tc-content&#45;&#45;wrap tc-input-view">
                    <div class="tc-caption-txt txt-light">
                      {{"Web" | translate}}
                    </div>
                    <div id="TRD_PER_INFO_WEB" class="tc-body1&#45;&#45;txt  ">{{client?.detail?.tradeProfile?.web ?
                        client?.detail?.tradeProfile?.web :EMPTY_CHAR}}
                    </div>
                  </div>
                </div>
                <div class="col-xs-12 col-sm-6  col-md-2 col-lg-2 last-column">
                  <div class="tc-content&#45;&#45;wrap tc-input-view">
                    <div class="tc-caption-txt txt-light">{{"Mail method" | translate}}
                    </div>
                    <div
                            id="TRD_PER_INFO_MAIL_METHOD"
                            class="tc-body1&#45;&#45;txt  ">{{client?.detail?.tradeProfile?.mailMethod ? getName(client?.detail?.tradeProfile?.mailMethod) : '&#45;&#45;'}}
                    </div>
                  </div>
                  <div class="tc-content&#45;&#45;wrap">
                    &lt;!&ndash;<div class="card-chips">&ndash;&gt;
                    &lt;!&ndash;<div&ndash;&gt;
                    &lt;!&ndash;id="TRD_PER_INFO_EXTERNAL"&ndash;&gt;
                    &lt;!&ndash;[ngClass]="client?.summary?.address?.external ? 'active':('' | tcAuthMask : (authConfigComponent + '.CLIENT_ADDRESS'): '':'masked-chip')"&ndash;&gt;
                    &lt;!&ndash;class="chip">&ndash;&gt;
                    &lt;!&ndash;{{"External" | translate}}&ndash;&gt;
                    &lt;!&ndash;</div>&ndash;&gt;
                    &lt;!&ndash;</div>&ndash;&gt;
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>-->
</div>
