<div class="container-fluid chip-input-wrapper">
    <div class="tc-flex-box search-top tc-search-bar search-top--inactive tc-sear tc-mb-2">
        <div class="tc-flex-item search-btn-top tc-search-icon tc-mt-3">
            <div class="icon">
                <i class="material-icons" id="SEARCH_ICN">search</i>
            </div>
        </div>
        <div class="tc-flex-item tc-search-input-area">
            <div class="tc-criteria-input">
                <div class="tc-chip-block floting-input">
                    <mat-form-field>
                        <div class="mat-form-field-wrapper">
                            <div class="mat-form-field-flex">
                                <div class="mat-form-field-infix">
                                    <input
                                            matInput type="text"
                                            [placeholder]="'Search' | translate"
                                            [formControl]="searchControl"
                                            [matAutocomplete]="auto">
                                    <mat-autocomplete
                                            class="mat-autocomplete-panel-dashboard"
                                            #auto="matAutocomplete" [displayWith]="displayItemFn">
                                        <div class="nav-items" style="max-height: 100px; overflow: auto;">
                                            <mat-optgroup *ngIf="filteredNavItems.length" [label]="'Navigations'">
                                                <mat-option
                                                        style="display: inline-block"
                                                        *ngFor="let item of filteredNavItems "
                                                        (onSelectionChange)="onSelectNavItem($event)"
                                                        [value]="item">
                                                    <ng-container *ngIf="item">
                                                        <mat-icon>{{item.icon}}</mat-icon>
                                                        <b>{{item.name | translate}}</b>
                                                    </ng-container>
                                                </mat-option>
                                            </mat-optgroup>
                                        </div>
                                        <div class="assignment-items" *ngIf="userManagementService.notAvailableFor('FC')" [style.max-height]="getAssignmentPanelMaxHeight()" style="overflow: auto">
                                            <mat-optgroup
                                                    *ngIf="filteredAssignmentItems.length"
                                                    [label]="'Assignments'">
                                                <mat-option
                                                        *ngFor="let item of filteredAssignmentItems "
                                                        (onSelectionChange)="onSelectItem($event)"
                                                        [value]="item">
                                                    <ng-container *ngIf="item">
                                                        <ng-container *ngIf="item.type===TYPE_ASSIGNMENT">
                                                            <mat-icon>assignment_turned_in</mat-icon>
                                                            <ng-container *ngIf="item.assignmentId">
                                                                <b>{{'Job Id' | translate}}: </b>{{item.assignmentId}}
                                                                &nbsp;&nbsp;&nbsp;
                                                            </ng-container>
                                                            <ng-container *ngIf="item.resource">
                                                                <b>{{'Resource' | translate}}: </b>{{item.resource }}
                                                                &nbsp;&nbsp;&nbsp;
                                                            </ng-container>
                                                            <ng-container *ngIf="item.supplier">
                                                                <b>{{'Supplier' | translate}}: </b>{{item.supplier}}
                                                                &nbsp;&nbsp;&nbsp;
                                                            </ng-container>
                                                        </ng-container>
                                                    </ng-container>
                                                </mat-option>
                                            </mat-optgroup>
                                        </div>
                                        <div class="supplier-items" [style.max-height]="getSupplierPanelMaxHeight()" style="overflow: auto">
                                            <mat-optgroup *ngIf="filteredSupplierItems.length" [label]="'Suppliers'">
                                                <mat-option
                                                        *ngFor="let item of filteredSupplierItems "
                                                        (onSelectionChange)="onSelectItem($event)"
                                                        [value]="item">
                                                    <ng-container *ngIf="item">
                                                        <ng-container *ngIf="item.type===TYPE_SUPPLIER">
                                                            <mat-icon>account_circle</mat-icon>
                                                            <ng-container *ngIf="item.supplier">
                                                                {{item.supplier}}
                                                                &nbsp;&nbsp;&nbsp;
                                                            </ng-container>
                                                            <ng-container *ngIf="item.supplierCode">
                                                                <b>{{'Code' | translate}}: </b>{{item.supplierCode}}
                                                            </ng-container>
                                                        </ng-container>
                                                    </ng-container>
                                                </mat-option>
                                            </mat-optgroup>
                                        </div>
                                        <div class="resource-items" [style.max-height]="getResourcePanelMaxHeight()" style="overflow: auto">
                                            <mat-optgroup *ngIf="filteredResourceItems.length" [label]="'Resources'">
                                                <mat-option
                                                        *ngFor="let item of filteredResourceItems "
                                                        (onSelectionChange)="onSelectItem($event)"
                                                        [value]="item">
                                                    <ng-container *ngIf="item">
                                                        <ng-container *ngIf="item.type===TYPE_RESOURCE">
                                                            <mat-icon>{{getGenericIcon(item.resourceType)}}</mat-icon>
                                                            <ng-container *ngIf="item.resource">
                                                                {{item.resource}}
                                                                &nbsp;&nbsp;&nbsp;
                                                            </ng-container>
                                                            <ng-container *ngIf="item.resourceId">
                                                                <b>{{'Id' | translate}}: </b>{{item.resourceId}}
                                                                &nbsp;&nbsp;&nbsp;
                                                            </ng-container>
                                                        </ng-container>
                                                    </ng-container>
                                                </mat-option>
                                            </mat-optgroup>
                                        </div>
                                    </mat-autocomplete>
                                </div>
                            </div>
                        </div>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="clearfix"></div>
