import { ConfigLoader } from '@tc-core/util/framework';
import { DMCQueryParamsService } from '../../util/framework/dmc-query-params.service';
import { RequestService } from '../../util/framework/request.service';
import { UrlPaths } from '../url-paths';
import * as i0 from "@angular/core";
import * as i1 from "@tc-core/util/framework/config-loader.service";
import * as i2 from "../../util/framework/request.service";
import * as i3 from "../../util/framework/dmc-query-params.service";
var DistributionChannelSetupService = /** @class */ (function () {
    function DistributionChannelSetupService(configLoader, requestService, queryParamsService) {
        var _this = this;
        this.configLoader = configLoader;
        this.requestService = requestService;
        this.queryParamsService = queryParamsService;
        this.getRows = function (criteria) {
            var reqPrams = _this.queryParamsService.asQueryParams(criteria);
            return _this.requestService.get(UrlPaths.OP_USER_PROFILE_URL_KEY, [UrlPaths.OP_USER_PROFILE_VERSION, UrlPaths.DISTRIBUTION_CHANNEL, UrlPaths.CODE_NAME], reqPrams);
        };
    }
    DistributionChannelSetupService.prototype.deleteRow = function (code) {
        return this.requestService.delete(UrlPaths.OP_USER_PROFILE_URL_KEY, [UrlPaths.OP_USER_PROFILE_VERSION, UrlPaths.DISTRIBUTION_CHANNEL, code]);
    };
    DistributionChannelSetupService.prototype.saveRow = function (row) {
        return this.requestService.post(UrlPaths.OP_USER_PROFILE_URL_KEY, [UrlPaths.OP_USER_PROFILE_VERSION, UrlPaths.DISTRIBUTION_CHANNEL], row);
    };
    DistributionChannelSetupService.prototype.updateRow = function (code, row) {
        return this.requestService.put(UrlPaths.OP_USER_PROFILE_URL_KEY, [UrlPaths.OP_USER_PROFILE_VERSION, UrlPaths.DISTRIBUTION_CHANNEL, code], row);
    };
    DistributionChannelSetupService.ngInjectableDef = i0.defineInjectable({ factory: function DistributionChannelSetupService_Factory() { return new DistributionChannelSetupService(i0.inject(i1.ConfigLoader), i0.inject(i2.RequestService), i0.inject(i3.DMCQueryParamsService)); }, token: DistributionChannelSetupService, providedIn: "root" });
    return DistributionChannelSetupService;
}());
export { DistributionChannelSetupService };
