import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatExpansionModule } from '@angular/material';
import { TranslateModule } from '@ngx-translate/core';
import { ExpandedContentCardModule } from '@tc/expanded-content-card';
import { ExpandedContentCardBodyModule } from '@tc/expanded-content-card-body';
import { ExpandedContentCardFooterModule } from '@tc/expanded-content-card-footer';
import { AdditionalContactsCardModule } from '../additional-contacts-card/additional-contacts-card.module';
import { ContactDetailsCardModule } from '../contact-details-card/contact-details-card.module';
import { CostSetupDataCardModule } from '../cost-setup-data-card/cost-setup-data-card.module';
import { DocumentCardModule } from '../document-card/document-card.module';
import { InsuranceInfoCardModule } from '../insurance-info-card/insurance-info-card.module';
import { KeyControlDetailCardModule } from '../key-control-detail-card/key-control-detail-card.module';
import { NoteInfoCardModule } from '../note-info-card/note-info-card.module';
import { PaymentInfoCardModule } from '../payment-info-card/payment-info-card.module';
import { ServiceInfoCardModule } from '../service-info-card/service-info-card.module';
import { SupplierAttributesCardModule } from '../supplier-attributes-card/supplier-attributes-card.module';
import { TaxSchemesCardModule } from '../tax-schemes-card/tax-schemes-card.module';
import { VehicleSpecificInfoCardModule } from '../vehicle-specific-info-card/vehicle-specific-info-card.module';
import { VehicleDetailViewComponent } from './vehicle-detail-view.component';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule.forChild(),
        MatExpansionModule,
        ExpandedContentCardModule,
        ExpandedContentCardFooterModule,
        ExpandedContentCardBodyModule,
        VehicleSpecificInfoCardModule,
        ContactDetailsCardModule,
        PaymentInfoCardModule,
        TaxSchemesCardModule,
        DocumentCardModule,
        AdditionalContactsCardModule,
        SupplierAttributesCardModule,
        NoteInfoCardModule,
        InsuranceInfoCardModule,
        ServiceInfoCardModule,
        CostSetupDataCardModule,
        KeyControlDetailCardModule
    ],
    declarations: [VehicleDetailViewComponent],
    exports: [VehicleDetailViewComponent]
})
export class VehicleDetailViewModule {}
