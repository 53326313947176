import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TcErrorType } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-error';
import { TC } from '@tc-core/util';
import { ConfigLoader } from '@tc-core/util/framework';
import { IGetRowsParams } from 'ag-grid-community';
import { Observable, of, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SetupGridComp } from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid-comp';
import { SetupGridDataSource } from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid-data-source';
import { SetupGridComponent } from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid.component';
import { TCO } from '../../../constants';
import { Category, ResourceTypeSearchCriteria } from '../../../models/criteria/resource-type-search-criteria';
import { ResourceCategory } from '../../../models/criteria/supplier-search-criteria';
import { SortDirection } from '../../../models/helper/sort-direction';
import { ServiceAssignment } from '../../../models/reservation/service-assignment';
import { DataHandlerService } from '../../../services/backend-consumers/master-data-handler-service/data-handler.service';
import { MasterDataRestService } from '../../../services/backend-consumers/master-data-handler-service/master-data-rest.service';
import { ResourceTypeService } from '../../../services/backend-consumers/setups/resource-type.service';
import { DMCCommon } from '../../../services/util/common/dmc-common';
import { DataKey, DataStoreService } from '../../../services/util/framework/data-store.service';
import EMPTY_CHAR = TC.FORMAT.EMPTY_CHAR;

@Component({
    selector: 'tc-resource-type-setup',
    templateUrl: './resource-type-setup.component.html'
})
export class ResourceTypeSetupComponent extends SetupGridComp implements OnInit, OnDestroy, SetupGridDataSource {
    @ViewChild('setupGrid')
    private setupGrid: SetupGridComponent;
    public colDefConfig = [];

    private resourceTypeSearchCriteria: ResourceTypeSearchCriteria = new ResourceTypeSearchCriteria();

    allResourceCodes = [];

    // required options for grid editors
    // nothing

    private searchCriteriaObserver: Subscription = new Subscription();
    private resourceTypes = [];

    constructor(
        private configLoader: ConfigLoader,
        private dataStoreService: DataStoreService,
        private dataHandlerService: DataHandlerService,
        private resourceTypeService: ResourceTypeService,
        private common: DMCCommon,
    ) {
        super();
    }

    ngOnInit() {
        this.colDefConfig = this.configLoader.configurations.get(TCO.CONF.CONF_RESOURCE_TYPE_SETUP);
        this.subscribeSearchCriteria();
        this.fillRequiredGridOptionData();
        this.fillDataKeyForEditorData();
        this.getAllResourceCodes();
    }

    /**
     * subscribe search criteria changes in search bar
     * then force grid to refresh data
     * grid refresh will be call getRaws method which is using this subscribed criteria for backend call
     */
    subscribeSearchCriteria() {
        this.searchCriteriaObserver = this.dataStoreService.get(DataKey.resourceTypeSearchCriteria)
                                          .subscribe(value => {
                                              this.resourceTypeSearchCriteria = value;
                                              this.setupGrid.runForceSearch();
                                          });
    }

    private fillRequiredGridOptionData() {
        // nothing
    }

    private fillDataKeyForEditorData() {
        this.addResourceTypes();
    }

    private addResourceTypes() {
        const resourceTypeSearchCriteria = new ResourceTypeSearchCriteria();
        resourceTypeSearchCriteria.size = 1000000;
        this.resourceTypeService.getResourceTypes(resourceTypeSearchCriteria).subscribe(
            result => {
                this.dataStoreService.set(DataKey.resourceTypes, result.data, true);
            });
    }

    getAllResourceCodes() {
        this.resourceTypeService.getResourceCodes().subscribe(
            response => {
                this.allResourceCodes = response.data;
            }
        );
    }

    public isInvalidRow(params): boolean {
        const data = params.data;
        if (data) {
            let invalid = true;
            if (
                data.code &&
                // (!this.resourceTypes.filter(value => value.code === data.code)) &&
                data.name &&
                data.resourceCategory
            ) {
                invalid = false;
            }
            return invalid;
        } else {
            return false;
        }
    }

    public createNewRow(): any {
        return {unsaved: true};
    }

    public isUnsavedRow(row: any): boolean {
        return !!row.unsaved;
    }

    public deleteRow(row: any) {
        if (row && row.code) {
            return this.resourceTypeService.deleteResourceType(row.code);
        } else {
            return of('success');
        }
    }

    public saveRow(row: any, event: any) {
        return this.resourceTypeService.saveResourceType(row)
                   .pipe(tap(data => row.unsaved = undefined));
    }

    public getRows(params: IGetRowsParams): Observable<any> {
        console.log('fetch requesting for ' + params.startRow + ' to ' + params.endRow);
        console.log(params.sortModel);

        const pageSize = params.endRow - params.startRow;
        if (!this.resourceTypeSearchCriteria) {
            this.resourceTypeSearchCriteria = new ResourceTypeSearchCriteria();
        }
        this.resourceTypeSearchCriteria.start = params.startRow;
        this.resourceTypeSearchCriteria.size = pageSize;
        if (params.sortModel && params.sortModel[0]) {
            this.resourceTypeSearchCriteria.sortBy = params.sortModel[0].colId;
            if (params.sortModel[0].sort === SetupGridComp.GRID_SORT_ASCENDING) {
                this.resourceTypeSearchCriteria.sortDirection = SortDirection.ASC;
            } else {
                this.resourceTypeSearchCriteria.sortDirection = SortDirection.DESC;
            }
        } else {
            this.resourceTypeSearchCriteria.sortBy = 'createdOn';
            this.resourceTypeSearchCriteria.sortDirection = SortDirection.ASC;
        }
        return this.resourceTypeService.getResourceTypes(this.resourceTypeSearchCriteria)
                   .pipe(
                       tap(data => {
                               this.dataStoreService.set(DataKey.resourceTypeSearchResultsForCriteria, data);
                               this.resourceTypes = data;
                           }
                       )
                   );
    }

    public ngOnDestroy(): void {
        if (this.searchCriteriaObserver) {
            this.searchCriteriaObserver.unsubscribe();
        }
    }

    /*
     grid config methods which are called by grid setup (ag grid)
     and set by grid config
     */

    getCategoryText = (params) => {
        let groupText = EMPTY_CHAR;
        if (params && params.data && params.data.resourceCategory) {
            if (params.data.resourceCategory.code) {
                groupText = params.data.resourceCategory.code;
            } else if (params.data.resourceCategory === Category.TRANSPORT) {
                groupText = 'Transport';
            } else if (params.data.resourceCategory === Category.GENERIC) {
                groupText = 'Generic';
            } else {
                groupText += EMPTY_CHAR;
            }
        }
        return groupText;
    };

    onCategorySet = params => {
        if (params.data && params.newValue) {
            if ( params.oldValue === '--' && this.allResourceCodes.includes(params.data.code) ) {
                this.common.showSnackBar(
                    'Resource Code Already Exist. Please Select a Different Code',
                    3000,
                    TcErrorType.TYPE.ERROR
                );
            } else {
                if (params.newValue['code']) {
                    params.data['resourceCategory'] = params.newValue['code'];
                } else {
                    params.data['resourceCategory'] = params.newValue;
                }
            }
        }
        return true;
    };

    validateCode = function(params: any) {
        return !(params && params.data);
    };

    public isUnique(code: string) {
        const exist = this.resourceTypes.filter(value => value.code === code);
        return !exist;

    }
}

