import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatProgressBarModule } from '@angular/material';
import { UserProfileComponent } from './user-profile.component';
import { SecurityModule } from '@tc/security';
import {TranslateModule} from '@ngx-translate/core';


@NgModule({
    imports: [
        CommonModule,
        MatProgressBarModule,
        SecurityModule,
        TranslateModule.forChild()
    ],
  declarations: [UserProfileComponent],
  exports: [UserProfileComponent]
})
export class UserProfileModule {}
