import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChipInputModule } from '@tc/chip-input';
import { GenericResourceService } from '../../services/backend-consumers/supplier-service/generic-resource.service';
import { GenericSearchCriteriaComponent } from './generic-search-criteria.component';

@NgModule({
    imports: [
        CommonModule,
        ChipInputModule
    ],
    providers: [GenericResourceService],
    declarations: [GenericSearchCriteriaComponent],
    exports: [GenericSearchCriteriaComponent],
    entryComponents: [GenericSearchCriteriaComponent]
})
export class GenericSearchCriteriaModule {}
