import { ConfigLoader } from '@tc-core/util/framework';
import { TCO } from '../../../constants';
import { DataLoaderService } from '../../api/data-loader-service.service';
import { DMCQueryParamsService } from '../../util/framework/dmc-query-params.service';
import { RequestService } from '../../util/framework/request.service';
import { FileHandlingService } from '../file-handler/file-handling.service';
import { UrlPaths } from '../url-paths';
import * as i0 from "@angular/core";
import * as i1 from "@tc-core/util/framework/config-loader.service";
import * as i2 from "../../util/framework/request.service";
import * as i3 from "../../util/framework/dmc-query-params.service";
import * as i4 from "../file-handler/file-handling.service";
import * as i5 from "../../api/data-loader-service.service";
var MasterDataRestService = /** @class */ (function () {
    function MasterDataRestService(configLoader, requestService, queryParamsService, fileHandlingService, dataLoaderService) {
        this.configLoader = configLoader;
        this.requestService = requestService;
        this.queryParamsService = queryParamsService;
        this.fileHandlingService = fileHandlingService;
        this.dataLoaderService = dataLoaderService;
    }
    MasterDataRestService.prototype.searchCities = function (criteria) {
        var reqPrams = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.get(UrlPaths.OP_DATA_URL_KEY, [UrlPaths.OP_DATA_VERSION, UrlPaths.CITIES], reqPrams);
    };
    MasterDataRestService.prototype.getCityByCode = function (code) {
        return this.requestService.get(UrlPaths.OP_DATA_URL_KEY, [UrlPaths.OP_DATA_VERSION, UrlPaths.CITIES, code]);
    };
    MasterDataRestService.prototype.getCostingTypes = function () {
        return this.requestService.get(UrlPaths.OP_DATA_URL_KEY, [UrlPaths.OP_DATA_VERSION, UrlPaths.COSTING_TYPES, UrlPaths.ALL_TYPES]);
    };
    MasterDataRestService.prototype.getConfigurations = function (criteria) {
        var params = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.get(UrlPaths.OP_DATA_URL_KEY, [UrlPaths.OP_DATA_VERSION, UrlPaths.CONFIGURATIONS], params);
    };
    MasterDataRestService.prototype.deleteConfiguration = function (code) {
        return this.requestService.delete(UrlPaths.OP_DATA_URL_KEY, [UrlPaths.OP_DATA_VERSION, UrlPaths.CONFIGURATIONS, code]);
    };
    MasterDataRestService.prototype.saveConfiguration = function (row) {
        return this.requestService.post(UrlPaths.OP_DATA_URL_KEY, [UrlPaths.OP_DATA_VERSION, UrlPaths.CONFIGURATIONS], row);
    };
    MasterDataRestService.prototype.uploadImage = function (fileToUpload) {
        var endpoint = this.configLoader.configurations.get(TCO.CONF.CONF_ENDPOINT)[UrlPaths.OP_DATA_URL_KEY];
        endpoint = this.dataLoaderService.getUrl(endpoint, [UrlPaths.OP_SUP_VERSION, UrlPaths.CONTENTS, UrlPaths.IMAGES]);
        return this.fileHandlingService.uploadImage({ imageFile: fileToUpload }, endpoint);
    };
    MasterDataRestService.ngInjectableDef = i0.defineInjectable({ factory: function MasterDataRestService_Factory() { return new MasterDataRestService(i0.inject(i1.ConfigLoader), i0.inject(i2.RequestService), i0.inject(i3.DMCQueryParamsService), i0.inject(i4.FileHandlingService), i0.inject(i5.DataLoaderService)); }, token: MasterDataRestService, providedIn: "root" });
    return MasterDataRestService;
}());
export { MasterDataRestService };
