import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResultContentCardComponent } from './result-content-card.component';
import {ResourceModule} from '@tc/resource';

@NgModule({
  imports: [
    CommonModule,
    ResourceModule
  ],
  exports: [
    ResultContentCardComponent
],
  declarations: [ResultContentCardComponent]
})
export class ResultContentCardModule { }
