<div class="note-detail container-fluid tc-card-wrapper">
    <form [formGroup]="documentInfoForm">
        <div class="container-fluid direct-client-personal-info-edit-card tc-card-wrapper">
            <div class="row">
                <div class="col-md-12 col-lg-12">
                    <div class="tc-flex-box">
                        <div class="tc-flex-item tc-right-cage">
                            <div class="row">
                                <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                    <div class="tc-select-edit dcpiec-select-title">
                                        <mat-form-field>
                                            <input matInput
                                                   id="DIR_PER_INFO_EDIT_TEMPLATE"
                                                   [(ngModel)]="document.template"
                                                   #templateInput
                                                   formControlName="template"
                                                   [placeholder]="'Template'">
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                    <div class="tc-input-edit dcpiec-input-format">
                                        <mat-form-field>
                                            <mat-select #format
                                                        id="DIR_PER_INFO_EDIT_FORMAT"
                                                        formControlName="format"
                                                        [placeholder]="'Format'">
                                                <mat-option *ngFor="let option of formatOptions"
                                                            [value]="option.code">
                                                    {{option.name}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                    <div class="tc-input-edit dcpiec-input-dispatchMethod">
                                        <mat-form-field>
                                            <mat-select #dispatchMethod
                                                        id="DIR_PER_INFO_EDIT_RESOURCETYPE"
                                                        formControlName="dispatchMethod"
                                                        [placeholder]="'Dispatch Method'">
                                                <mat-option *ngFor="let option of dispatchTypes"
                                                            [value]="option.code">
                                                    {{option.name}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                    <div class="tc-input-edit dcpiec-input-frequency">
                                        <mat-form-field>
                                            <mat-select #frequency
                                                        id="DIR_PER_INFO_EDIT_FREQUENCY"
                                                        formControlName="frequency"
                                                        [placeholder]="'Frequency'">
                                                <mat-option *ngFor="let option of frequencyOptions"
                                                            [value]="option.code">
                                                    {{option.name}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                    <div class="tc-input-edit dcpiec-input-freqParam1">
                                        <mat-form-field>
                                            <input matInput
                                                   id="DIR_PER_INFO_EDIT_FREQPARAM1"
                                                   #freqParam1Input
                                                   formControlName="freqParam1"
                                                   [placeholder]="'Frequency Parameter 1'">
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                    <div class="tc-input-edit dcpiec-input-freqParam1">
                                        <mat-form-field>
                                            <input matInput
                                                   id="DIR_PER_INFO_EDIT_FREQPARAM2"
                                                   #freqParam2Input
                                                   formControlName="freqParam2"
                                                   [placeholder]="'Frequency Parameter 2'">
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                    <div class="tc-input-edit dcpiec-input-contactCategory">
                                        <mat-form-field>
                                            <mat-select #contactCategory
                                                        id="DIR_PER_INFO_EDIT_CONTACT"
                                                        formControlName="contactCategory"
                                                        [placeholder]="'Contact Category'">
                                                <mat-option
                                                        *ngFor="let option of contactCategoryOptions"
                                                        [value]="option.code">
                                                    {{option.name}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>