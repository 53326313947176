import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LEVEL } from '@tc-core/model/it/codegen/ui/framework/dialog-model';
import { DialogService } from '@tc/dialog/dialog.service';
import { ModalData } from '@tc-core/model/it/codegen/ui/framework/modal-data';
import { ModalService } from '@tc/modal/modal.service';
import { Remark } from '../../../../models/common/remark';
import { DataKey, DataStoreService } from '../../../../services/util/framework/data-store.service';
import { AddNoteComponent } from '../add-note/add-note.component';
import { MatDialog } from '@angular/material';
import { Subscription } from 'rxjs';

@Component({
    selector: 'tc-remarks',
    templateUrl: './remarks.component.html'
})
export class RemarksComponent implements OnInit {

    @Input() noteList: Remark[] = [];

    @Output() noteChanged: EventEmitter<any> = new EventEmitter();

    private noteSubscription: Subscription = new Subscription();
    private modalCloseSubscription: Subscription = new Subscription();


    constructor(
        private dialogService: DialogService,
        private modalService: ModalService,
        public dialog: MatDialog,
        private dataStore: DataStoreService
    ) { }

    ngOnInit() {
        this.dataStore.set(DataKey.notes, null, true);
        this.getNotes();

    }

    getNotes() {
        this.noteSubscription = this.dataStore.get(DataKey.notes).subscribe(data => {
            if (data != null) {
                this.noteList.push(data);
                this.noteChanged.emit();
            }
        });
    }



    private confirmModal(data: any): void {
        this.modalService.confirm(data).subscribe((res) => {
            console.log(res);
        });
    }

    public onNotesModified() {
        this.noteChanged.emit();
    }
}
