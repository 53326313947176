import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTableModule } from '@angular/material';
import { PassengerTooltipComponent } from './passenger-tooltip/passenger-tooltip.component';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
    declarations: [
        PassengerTooltipComponent
    ],
    imports: [
        CommonModule,
        MatTableModule,
        TranslateModule.forChild()
    ],
    exports: [
        PassengerTooltipComponent
    ]
})
export class AgGridCustomTooltipModule {}
