import { Injectable } from '@angular/core';
import { TcErrorType } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-error';
import { ErrorView } from '@tc-core/model/it/codegen/ui/error-view';
import { ConfigLoader } from '@tc-core/util/framework/config-loader.service';
import { TCO } from '../../../constants';
import { DataKey, DataStoreService } from '../framework/data-store.service';

@Injectable()
export class DMCCommon {

  constructor(
      private dataStore: DataStoreService,
      private configLoader: ConfigLoader,
  ) {

  }

  /**
   * get baseUrl of the application
   * @returns {string}
   */
  public getBaseUrl(): string {
    const protocol = window.location.protocol;
    const hostName = window.location.hostname;
    const port = window.location.port;

    return protocol + '//' + hostName + (port !== '' ? ':' + port : '');

  }

  public showSnackBar(message: string, duration: number, type: TcErrorType.TYPE): void {
    let errorView: ErrorView = new ErrorView();
    errorView.type = TcErrorType.TYPE[type];
    errorView.errorMessage = message;
    errorView.autoDismiss = true;
    errorView.dismissDuration = duration;
    this.dataStore.set(DataKey.error, errorView);
  }

  public showSnackBarWithErrors(message: string, errors: string[], duration: number, type: TcErrorType.TYPE): void {
    let errorView: ErrorView = new ErrorView();
    errorView.type = TcErrorType.TYPE[type];
    errorView.errorMessage = message;
    errorView.errors = errors;
    errorView.autoDismiss = true;
    errorView.dismissDuration = duration;
    this.dataStore.set(DataKey.error, errorView);
  }

  public getLoginUrl() {
    const loginUrlOverride = this.configLoader.configurations.get(TCO.CONF.CONF_ENDPOINT)[TCO.URL.AUTH_USER_LOGIN_OVERRIDE];
    if (loginUrlOverride && 'no' !== loginUrlOverride && 'No' !== loginUrlOverride && 'NO' !== loginUrlOverride) {
      return loginUrlOverride;
    } else {
      return this.configLoader.configurations.get(TCO.CONF.CONF_ENDPOINT)[TCO.URL.AUTH_USER_LOGIN];
    }
  }

  getTokenSaveConfig()
  {
    const authConf = this.configLoader.configurations.get(TCO.CONF.CONF_ENDPOINT);
    if (authConf) {
      return authConf.auth_token_to_cookie;
    }
  }
}
