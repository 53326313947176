import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatInputModule } from '@angular/material';
import { ManageSupplierAttributesComponent } from './manage-supplier-attributes.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        TranslateModule.forChild()
    ],
    exports: [
        ManageSupplierAttributesComponent
    ],
    declarations: [ManageSupplierAttributesComponent]
})
export class ManageSupplierAttributesModule {}
