/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/tooltip";
import * as i2 from "@angular/cdk/overlay";
import * as i3 from "@angular/cdk/scrolling";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "@angular/cdk/bidi";
import * as i7 from "@angular/platform-browser";
import * as i8 from "@ngx-translate/core";
import * as i9 from "./row-error-indicator.component";
var styles_RowErrorIndicatorComponent = [];
var RenderType_RowErrorIndicatorComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_RowErrorIndicatorComponent, data: {} });
export { RenderType_RowErrorIndicatorComponent as RenderType_RowErrorIndicatorComponent };
export function View_RowErrorIndicatorComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { selectBoxContainerRef: 0 }), (_l()(), i0.ɵeld(1, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["1"])), (_l()(), i0.ɵeld(3, 16777216, null, null, 2, "div", [["aria-label", "indicator tooltip"], ["class", "tc-ag-grid-indicator tc-ag-grid-indicator--danger"], ["matTooltipClass", "mat-tooltip--large mat-tooltip--danger"], ["matTooltipPosition", "right"]], null, [[null, "longpress"], [null, "keydown"], [null, "touchend"]], function (_v, en, $event) { var ad = true; if (("longpress" === en)) {
        var pd_0 = (i0.ɵnov(_v, 4).show() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i0.ɵnov(_v, 4)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (i0.ɵnov(_v, 4)._handleTouchend() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i0.ɵdid(4, 147456, null, 0, i1.MatTooltip, [i2.Overlay, i0.ElementRef, i3.ScrollDispatcher, i0.ViewContainerRef, i0.NgZone, i4.Platform, i5.AriaDescriber, i5.FocusMonitor, i1.MAT_TOOLTIP_SCROLL_STRATEGY, [2, i6.Directionality], [2, i1.MAT_TOOLTIP_DEFAULT_OPTIONS], [2, i7.HAMMER_LOADER]], { position: [0, "position"], message: [1, "message"], tooltipClass: [2, "tooltipClass"] }, null), i0.ɵpid(131072, i8.TranslatePipe, [i8.TranslateService, i0.ChangeDetectorRef])], function (_ck, _v) { var currVal_0 = "right"; var currVal_1 = i0.ɵinlineInterpolate(1, "", i0.ɵunv(_v, 4, 1, i0.ɵnov(_v, 5).transform("Invalid Inputs")), ""); var currVal_2 = "mat-tooltip--large mat-tooltip--danger"; _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_RowErrorIndicatorComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-row-error-indicator", [], null, null, null, View_RowErrorIndicatorComponent_0, RenderType_RowErrorIndicatorComponent)), i0.ɵdid(1, 4243456, null, 0, i9.RowErrorIndicatorComponent, [], null, null)], null, null); }
var RowErrorIndicatorComponentNgFactory = i0.ɵccf("tc-row-error-indicator", i9.RowErrorIndicatorComponent, View_RowErrorIndicatorComponent_Host_0, {}, {}, []);
export { RowErrorIndicatorComponentNgFactory as RowErrorIndicatorComponentNgFactory };
