import {Component, Input, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {GridToolTipComponent} from '../../../../widgets/framework/ag-grid-custom-components/renderers/tooltip/grid-tool-tip.component';
import {ICellRendererParams} from 'ag-grid-community';
import {BookingSupplementGroup} from '../supplements-editor.component';

@Component({
  selector: 'tc-booking-supplement-group',
  templateUrl: './booking-supplement-group.component.html'
})
export class BookingSupplementGroupComponent implements OnInit {

  constructor(
      private translateService: TranslateService
  ) {
    this.translateService.onLangChange.subscribe(() => {
      this.gridApi.refreshHeader();
    });
  }

  @Input() supplementGroup: BookingSupplementGroup;
  @Input() columnDefs: any[] = [];

  gridApi;

  public gridOptions: any = {
    // resizable: true
  };

  public frameworkComponents: any = {
    tooltipComponent: GridToolTipComponent
  };

  defaultColDef = {
    resizable: true,
    headerValueGetter: (parameters: ICellRendererParams): string => {
      const headerIdentifier = parameters.colDef.headerName;
      if (headerIdentifier) {
        return this.translateService.instant(headerIdentifier);
      }
      return '';
    }
  };

  ngOnInit() {
  }

  public onGridReadyBookingSupplements(params: any) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  }

  onFirstDataRendered(params) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  }

}
