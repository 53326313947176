import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatExpansionModule, MatInputModule } from '@angular/material';
import { TranslateModule } from '@ngx-translate/core';
import { ContentStripModule } from '@tc/content-strip';
import { DirectivesModule } from '@tc/directives';
import { ExpandedContentCardBodyModule } from '@tc/expanded-content-card-body';
import { ModalModule } from '@tc/modal';
import { PipesModule } from '@tc/pipes';
import { SummaryStripModule } from '@tc/summary-strip';
import { ResultContentCardModule } from '../../../../../widgets/shared/result-content-card/result-content-card.module';
import { RoomAddCardModule } from '../../../../../widgets/shared/room-add-card/room-add-card.module';
import { RoomGrpEditCardModule } from '../room-grp-edit-card/room-grp-edit-card.module';
import { RoomGrpSummaryCardModule } from '../room-grp-summary-card/room-grp-summary-card.module';
import { RoomGrpViewCardComponent } from './room-grp-view-card.component';

@NgModule({
    imports: [
        CommonModule,
        ModalModule,
        MatInputModule,
        ReactiveFormsModule,
        TranslateModule.forChild(),
        PipesModule,
        ExpandedContentCardBodyModule,
        MatExpansionModule,
        ContentStripModule,
        ResultContentCardModule,
        DirectivesModule,
        SummaryStripModule,
        RoomAddCardModule,
        RoomGrpEditCardModule,
        RoomGrpSummaryCardModule
    ],
    exports: [RoomGrpViewCardComponent],
    declarations: [RoomGrpViewCardComponent]
})
export class RoomGrpViewCardModule {
}
