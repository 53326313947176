import * as tslib_1 from "tslib";
import { DatePipe } from '@angular/common';
import { Injector } from '@angular/core';
import { Icon } from '@tc-core/model/it/codegen/ui/framework/icon';
import { ERROR_LEVEL, PageSection } from '@tc-core/model/it/codegen/ui/framework/page-section';
import { SummaryCardData } from '@tc-core/model/it/codegen/ui/framework/summary-card-data';
import { KeyValue } from '@tc-core/model/it/codegen/ui/key-value';
import { TC } from '@tc-core/util/constants';
import { DataFormatterUtilService } from '@tc-core/util/system/data-formatter-util.service';
import { DateFormatter } from '@tc-core/util/system/date-formatter.service';
import { JsonUtilService } from '@tc-core/util/system/json-util.service';
var ContentPreProcessorService = /** @class */ (function () {
    function ContentPreProcessorService(dataFormatterUtilService, injector, jsonUtil, dateFormatter, datePipe) {
        this.dataFormatterUtilService = dataFormatterUtilService;
        this.injector = injector;
        this.jsonUtil = jsonUtil;
        this.dateFormatter = dateFormatter;
        this.datePipe = datePipe;
    }
    /**
     *
     * @param client
     * @param block
     * @param summaryCardData
     * @returns {SummaryCardData}
     */
    ContentPreProcessorService.prototype.preProcessBlock = function (payload, block, summaryCardData, dataKey) {
        var data = block['data'];
        var _id = block['_id'];
        var path = block['path'];
        var dataProcessor = block['dataProcessor'];
        for (var key in data) {
            if (dataProcessor && dataProcessor !== '') {
                var t = dataProcessor.split('#');
                var srv = t[0];
                var fnc = t[1];
                var handler = this.injector.get(srv);
                summaryCardData[key] = handler[fnc](payload, data[key], _id, dataKey); // calling handler function
                if (path) {
                    summaryCardData[key].path = path;
                }
            }
            else {
                var val = void 0;
                try {
                    val = this.jsonUtil.getRawData(payload, data[key]);
                }
                catch (e) {
                    console.error(e);
                }
                summaryCardData[key] = this.processGenericData(val, _id, dataKey);
                if (path) {
                    summaryCardData[key].path = path;
                }
            }
        }
        return summaryCardData;
    };
    /**
     *
     * @param client
     * @param contentConfig
     * @returns {SummaryCardData}
     */
    ContentPreProcessorService.prototype.preProcessContentData = function (data, contentConfig, dataKey) {
        var e_1, _a;
        var summaryCardData = new SummaryCardData();
        var placeHolderConfig = contentConfig.placeHolderConfig;
        try {
            for (var placeHolderConfig_1 = tslib_1.__values(placeHolderConfig), placeHolderConfig_1_1 = placeHolderConfig_1.next(); !placeHolderConfig_1_1.done; placeHolderConfig_1_1 = placeHolderConfig_1.next()) {
                var configKey = placeHolderConfig_1_1.value;
                summaryCardData = this.preProcessBlock(data, configKey, summaryCardData, dataKey);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (placeHolderConfig_1_1 && !placeHolderConfig_1_1.done && (_a = placeHolderConfig_1.return)) _a.call(placeHolderConfig_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return summaryCardData;
    };
    /**
     * @param clients
     * @param contentConfig
     * @returns {SummaryCardData[]}
     */
    ContentPreProcessorService.prototype.preProcessContentDataList = function (data, contentConfig) {
        var e_2, _a;
        var summaryDataList = [];
        if (data && data.length > 0) {
            try {
                for (var data_1 = tslib_1.__values(data), data_1_1 = data_1.next(); !data_1_1.done; data_1_1 = data_1.next()) {
                    var dataItem = data_1_1.value;
                    summaryDataList.push(this.preProcessContentData(dataItem, contentConfig));
                }
            }
            catch (e_2_1) { e_2 = { error: e_2_1 }; }
            finally {
                try {
                    if (data_1_1 && !data_1_1.done && (_a = data_1.return)) _a.call(data_1);
                }
                finally { if (e_2) throw e_2.error; }
            }
        }
        return summaryDataList;
    };
    /**
     *
     * @param obj
     * @param arr
     */
    ContentPreProcessorService.prototype.appendWithSpace = function (obj, arr, id) {
        var e_3, _a;
        var returnVal = new KeyValue();
        var processedStr = '';
        try {
            for (var arr_1 = tslib_1.__values(arr), arr_1_1 = arr_1.next(); !arr_1_1.done; arr_1_1 = arr_1.next()) {
                var item = arr_1_1.value;
                processedStr += this.jsonUtil.findJsonProperty(obj, item, '') + ' ';
            }
        }
        catch (e_3_1) { e_3 = { error: e_3_1 }; }
        finally {
            try {
                if (arr_1_1 && !arr_1_1.done && (_a = arr_1.return)) _a.call(arr_1);
            }
            finally { if (e_3) throw e_3.error; }
        }
        // remove leading and trailing whitespaces
        processedStr.replace(/^\s+|\s+$/g, '');
        returnVal.key = id;
        returnVal.value = processedStr;
        return returnVal;
    };
    ContentPreProcessorService.prototype.appendWithDash = function (obj, arr, id) {
        var _this = this;
        var returnVal = new KeyValue();
        var processedStr = '';
        arr.forEach(function (value, i) {
            var val = _this.jsonUtil.findJsonProperty(obj, value, '');
            if (val !== '') {
                processedStr += val + (i < arr.length - 1 ? '-' : '');
            }
        });
        returnVal.key = id;
        returnVal.value = processedStr;
        return returnVal;
    };
    /**
     *
     * @param obj
     * @param arr
     */
    ContentPreProcessorService.prototype.appendWithSpaceAndDash = function (obj, arr, id) {
        var _this = this;
        var returnVal = new KeyValue();
        var processedStr = '';
        arr.forEach(function (value, i) {
            var val = _this.jsonUtil.findJsonProperty(obj, value, '');
            if (val !== '') {
                processedStr += (i !== 0 ? ' - ' : '') + val;
            }
        });
        returnVal.key = id;
        returnVal.value = processedStr;
        return returnVal;
    };
    /**
     *
     * @param val
     * @param id
     * @returns {KeyValue}
     */
    ContentPreProcessorService.prototype.processGenericData = function (val, id, dataKey) {
        var returnVal = new KeyValue();
        returnVal.key = id;
        returnVal.value = val;
        return returnVal;
    };
    ContentPreProcessorService.prototype.getLabel = function (obj, arr, id) {
        var returnVal = new KeyValue();
        var processedStr = '';
        arr.forEach(function (value, i) {
            processedStr += value;
        });
        returnVal.key = id;
        returnVal.value = processedStr;
        return returnVal;
    };
    ContentPreProcessorService.prototype.processDefaultIcon = function (obj, arr, id) {
        var returnVal = new KeyValue();
        var iconView = new Icon();
        var iconObj = arr[0]; // get the first icon
        if (iconObj) {
            iconView.key = iconObj['key'];
            iconView.iconType = iconObj['iconType'];
            iconView.style = iconObj['style'];
            iconView.value = iconObj['value'];
        }
        returnVal.key = id;
        returnVal.value = iconView;
        return returnVal;
    };
    ContentPreProcessorService.prototype.processCommonIconKeepActive = function (obj, arr, id) {
        var returnVal = new KeyValue();
        var iconView = new Icon();
        var iconObj = arr[0]; // get the first icon
        if (iconObj) {
            iconView.key = iconObj['key'];
            iconView.iconType = iconObj['iconType'];
            iconView.style = iconObj['style'] + ' ' + iconObj['activeClass'];
            iconView.value = iconObj['value'];
        }
        returnVal.key = id;
        returnVal.value = iconView;
        return returnVal;
    };
    /**
     *
     * @param obj
     * @param arr
     */
    ContentPreProcessorService.prototype.appendWithSpaceAndCapitalize = function (obj, arr, id) {
        var e_4, _a;
        var returnVal = new KeyValue();
        var processedStr = '';
        try {
            for (var arr_2 = tslib_1.__values(arr), arr_2_1 = arr_2.next(); !arr_2_1.done; arr_2_1 = arr_2.next()) {
                var item = arr_2_1.value;
                processedStr += this.jsonUtil.findJsonProperty(obj, item, '') + ' ';
            }
        }
        catch (e_4_1) { e_4 = { error: e_4_1 }; }
        finally {
            try {
                if (arr_2_1 && !arr_2_1.done && (_a = arr_2.return)) _a.call(arr_2);
            }
            finally { if (e_4) throw e_4.error; }
        }
        // capitalize each word
        processedStr = this.dataFormatterUtilService.capitalizeEachWord(processedStr, true);
        returnVal.key = id;
        returnVal.value = processedStr;
        return returnVal;
    };
    ContentPreProcessorService.prototype.appendDashToEmptyValue = function (obj, arr, id) {
        var _this = this;
        var returnVal = new KeyValue();
        var processedStr = '';
        arr.forEach(function (value, i) {
            var val = _this.jsonUtil.findJsonProperty(obj, value, '');
            processedStr = val;
        });
        if (processedStr === '') {
            processedStr = '--';
        }
        returnVal.key = id;
        returnVal.value = processedStr;
        return returnVal;
    };
    ContentPreProcessorService.prototype.getStringDate = function (obj, arr, id) {
        var _this = this;
        var returnVal = new KeyValue();
        var processedStr = '';
        arr.forEach(function (value, i) {
            var val = _this.jsonUtil.findJsonProperty(obj, value, '');
            processedStr = val;
        });
        if (typeof processedStr === 'object') {
            // If a date
            returnVal.value = this.dateFormatter.dateAsString(processedStr);
        }
        else {
            // If a string
            returnVal.value = processedStr;
        }
        // try {
        //   returnVal.value = this.dateFormatter.dateAsString(processedStr);
        // } catch (e) {
        //   returnVal.value = processedStr;
        // }
        returnVal.key = id;
        return returnVal;
    };
    ContentPreProcessorService.prototype.getDateTime = function (obj, arr, id) {
        var _this = this;
        var returnVal = new KeyValue();
        var date = null;
        arr.forEach(function (value, i) {
            date = _this.jsonUtil.findJsonProperty(obj, value, '');
        });
        var processedStr = '';
        if (date) {
            processedStr = this.dateFormatter.dateAsString(date, 'YYYY-MM-DD HH:mm');
        }
        returnVal.value = processedStr;
        returnVal.key = id;
        return returnVal;
    };
    ContentPreProcessorService.prototype.getDate = function (obj, arr, id) {
        var _this = this;
        var returnVal = new KeyValue();
        var date = null;
        arr.forEach(function (value, i) {
            date = _this.jsonUtil.findJsonProperty(obj, value, '');
        });
        var processedStr = '';
        if (date) {
            processedStr = this.dateFormatter.dateAsString(date, 'YYYY-MM-DD');
        }
        returnVal.value = processedStr;
        returnVal.key = id;
        return returnVal;
    };
    ContentPreProcessorService.prototype.getGMTDate = function (obj, arr, id) {
        var _this = this;
        var returnVal = new KeyValue();
        var date = null;
        arr.forEach(function (value, i) {
            date = _this.jsonUtil.findJsonProperty(obj, value, '');
        });
        var processedStr = '';
        if (date) {
            processedStr = this.datePipe.transform(date, 'yyyy-MM-dd', 'GMT');
        }
        returnVal.value = processedStr;
        returnVal.key = id;
        return returnVal;
    };
    /**
     * @param payload - data
     * @param block - section configuration
     * @returns {PageSection}
     */
    ContentPreProcessorService.prototype.preProcessSectionBlock = function (payload, block) {
        var section = new PageSection();
        section.id = block['id'];
        section.mainTitle = block['main_title'];
        section.tabTitle = block['tab_title'];
        section.actions = block['actions'];
        var errorLevel = block['show_error'];
        if (errorLevel) {
            section.showError = ERROR_LEVEL[errorLevel] === undefined ? ERROR_LEVEL.NONE : ERROR_LEVEL[errorLevel];
        }
        var dataProcessor = block['dataProcessor'];
        if (dataProcessor && dataProcessor !== '') {
            var temp = dataProcessor.split('#');
            var srv = temp[0];
            var fnc = temp[1];
            var handler = this.injector.get(srv);
            section.subTitle = handler[fnc](payload, block['sub_title_data'], section.id); // calling handler function
        }
        else {
            section.subTitle = new KeyValue();
            section.subTitle.value = block['sub_title_data'];
            section.subTitle.key = section.id;
        }
        return section;
    };
    ContentPreProcessorService.prototype.processDateTime = function (payload, arr, id) {
        var returnVal = new KeyValue();
        var data = this.jsonUtil.findJsonProperty(payload, arr, null);
        var applicableDate = data ? data.split('T')[0] : '';
        var applicableTime = data ? data.split('T')[1] : '';
        if (applicableDate) {
            returnVal.value = this.dataFormatterUtilService.formatDate(applicableDate, TC.FORMAT.DATE_FORMAT_dd_MMM_yy) +
                ' ' +
                applicableTime;
        }
        returnVal.key = id;
        return returnVal;
    };
    // TODO Remove this : Temporary Fix
    ContentPreProcessorService.prototype.resetCommisionAndDiscountValues = function (payload, arr, id) {
        var returnVal = new KeyValue();
        returnVal.key = id;
        returnVal.value = '';
        return returnVal;
    };
    ContentPreProcessorService.prototype.getTitleCombined = function (obj, arr, id) {
        var keyValue = this.appendWithSpace(obj, arr, id);
        keyValue.value = id[0] + id.substr(1).replace('_', ' ').toLowerCase() + ' : ' + keyValue.value;
        return keyValue;
    };
    return ContentPreProcessorService;
}());
export { ContentPreProcessorService };
