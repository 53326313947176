import { HttpClient } from '@angular/common/http';
import { DataKey } from '../../util/framework/data-store.service';
import { DMCQueryParamsService } from '../../util/framework/dmc-query-params.service';
import { RequestService } from '../../util/framework/request.service';
import { UrlPaths } from '../url-paths';
import * as i0 from "@angular/core";
import * as i1 from "../../util/framework/dmc-query-params.service";
import * as i2 from "../../util/framework/request.service";
import * as i3 from "@angular/common/http";
var GenReservationManagementService = /** @class */ (function () {
    function GenReservationManagementService(queryParamsService, requestService, http) {
        this.queryParamsService = queryParamsService;
        this.requestService = requestService;
        this.http = http;
        this.GENERIC_RES_TYPE = 'GEN';
    }
    GenReservationManagementService.prototype.searchReservations = function (criteria) {
        criteria.serviceType = this.GENERIC_RES_TYPE;
        var params = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.getWithDataKey(DataKey.genReservationSearchResultsFromService, UrlPaths.OP_RES_URL_KEY, [UrlPaths.OP_RES_VERSION, UrlPaths.GENERIC_ITEMS], params);
    };
    GenReservationManagementService.prototype.searchReservationsOnMore = function (criteria) {
        criteria.serviceType = this.GENERIC_RES_TYPE;
        var params = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.getWithDataKey(DataKey.genReservationSearchResultsFromServiceOnMore, UrlPaths.OP_RES_URL_KEY, [UrlPaths.OP_RES_VERSION, UrlPaths.GENERIC_ITEMS], params);
    };
    GenReservationManagementService.prototype.exportServiceItems = function (criteria) {
        return null;
    };
    GenReservationManagementService.prototype.saveGenReservations = function (genTourItineraries) {
        return this.requestService.patch(UrlPaths.OP_RES_URL_KEY, [UrlPaths.OP_RES_VERSION, UrlPaths.GENERIC_ITEMS], genTourItineraries);
    };
    GenReservationManagementService.prototype.searchReservationsFromMock = function () {
        return this.http.get("assets\\mockdata\\genericSearchResults.json");
    };
    GenReservationManagementService.ngInjectableDef = i0.defineInjectable({ factory: function GenReservationManagementService_Factory() { return new GenReservationManagementService(i0.inject(i1.DMCQueryParamsService), i0.inject(i2.RequestService), i0.inject(i3.HttpClient)); }, token: GenReservationManagementService, providedIn: "root" });
    return GenReservationManagementService;
}());
export { GenReservationManagementService };
