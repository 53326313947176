import { AfterViewChecked, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DialogModel } from '@tc-core/model/it/codegen/ui/framework/dialog-model';
import { LEVEL } from '@tc-core/model/it/codegen/ui/framework/modal-data';
// import { CalendarRefreshEventType } from '@tc/cg-calendar/calendar-refresh-event-type';
// import { DateRange } from '@tc/cg-calendar/date-range';
// import { RangeGroup } from '@tc/cg-calendar/range-group';
import { DialogService } from '@tc/dialog/dialog.service';

import * as moment from 'moment';
import { DateRange } from '../../cg-calendar/date-range';
import { RangeGroup } from '../../cg-calendar/range-group';

@Component({
  selector: 'tc-season-calendar-navigation',
  templateUrl: './season-calendar-navigation.component.html'
})
export class SeasonCalendarNavigationComponent implements OnInit, AfterViewChecked {

  @Input() rangeGroups: RangeGroup[] = [];
  @Input() viewOnlyMode: boolean = false;
  @Input() dateRangesSelectable: boolean = false;
  @Input() seasonsViewOnlyMode: boolean = false;

  @Output() selectSeason: EventEmitter<RangeGroup> = new EventEmitter();
  @Output() removeDateRange: EventEmitter<DateRange> = new EventEmitter();
  @Output() selectDateRange: EventEmitter<DateRange> = new EventEmitter();
  @Output() removeSeason: EventEmitter<RangeGroup> = new EventEmitter();
  @Output() setSeasons: EventEmitter<any> = new EventEmitter();
  @Output() setSeasonRange: EventEmitter<any> = new EventEmitter();
  @Output() viewSeasonSummary: EventEmitter<any> = new EventEmitter();

  mouseOverRangeGroup: RangeGroup = null;
  selectedRangeGroup: RangeGroup = null;

  isRemovingSeason = false;
  seasonHeaderClass: string = '';
  dateRangeHeaderClass: string= '';
  headerToggleClass: string = 'tc-section-expanded';


  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private dialogService: DialogService
  ) {
  }

  ngOnInit() {
  }

  public ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  getSelectedDateRanges(): DateRange[] {
    this.selectedRangeGroup = this.rangeGroups.find(group => group.selected);
    if (this.selectedRangeGroup && this.selectedRangeGroup.dateRanges) {
      return this.selectedRangeGroup.dateRanges;
    } else {
      return [];
    }
  }

  onSelectSeason(rangeGroup: RangeGroup) {
    if (!this.isRemovingSeason) {
      this.selectSeason.emit(rangeGroup);
    }
  }

  onRemoveDateRange(dateRange: DateRange) {
    this.removeDateRange.emit(dateRange);
  }

  getDateRangeText(dateRange: DateRange) {
    return moment(dateRange.startDate).format('DD MMM YY') +
      ' - ' +
      moment(dateRange.endDate).format('DD MMM YY');
  }

  getSelectedGroupClass() {
    let selectedRangeGroup = this.rangeGroups.find(group => group.selected);
    if (selectedRangeGroup) {
      return selectedRangeGroup.colorId;
    } else {
      return '';
    }
  }

  onRemoveSeason(rangeGroup: RangeGroup) {
    if (!rangeGroup.nonRemovable) {
      this.isRemovingSeason = true;
      this.removeSeason.emit(rangeGroup);
      setTimeout(() => {
        this.isRemovingSeason = false;
      }, 100);
    } else {
      let confirmSuccess = new DialogModel(
        true,
        LEVEL.WARNING,
        'Invalid Attempt',
        `This season cannot be deleted.`,
        true,
        2000,
        null,
        null,
        'OK'
      );
      this.dialogService
          .confirm(confirmSuccess);
    }
  }

  onMouseOverRangeGroup(rangeGroup: RangeGroup) {
    this.mouseOverRangeGroup = rangeGroup;
  }

  onMouseLeaveRangeGroup(rangeGroup: RangeGroup) {
    this.mouseOverRangeGroup = null;
  }

  public onSetSeasonsClick($event: MouseEvent) {
    this.setSeasons.emit();
  }

  public onSetSeasonRangeClick($event: MouseEvent) {
    this.setSeasonRange.emit();
  }

  public onSeasonSummaryClick($event: MouseEvent) {
    this.viewSeasonSummary.emit();
  }

  public onClickDateRange(dateRange: DateRange) {
    this.selectDateRange.emit(dateRange);
  }

  public onClickSeasonHeader($event: MouseEvent) {
    console.log($event);
    if ($event.toElement.id !== 'season-settings-btn') {
      if (this.seasonHeaderClass) {
        this.seasonHeaderClass = '';
      }else{
        this.seasonHeaderClass = this.headerToggleClass;
      }
    }

  }

  public onClickDateRangeHeader($event: MouseEvent) {
    console.log($event);
    if ($event.toElement.id !== 'add-date-range-btn') {
      if (this.dateRangeHeaderClass) {
        this.dateRangeHeaderClass = '';
      }else{
        this.dateRangeHeaderClass = this.headerToggleClass;
      }
    }
  }
}
