
<div
        class="groupmaster-wholeseller-additional-contacts-edit-card"
        tcExpansionPanel
        [include]="['tc-dialog', 'mat-option', 'mat-radio-button']"
        [enabled]="additionalContactList.length>0"
        [id]="'additional_contact_summary_strip'">
    <tc-summary-strip
            *tcExpansionPanelSummary
            [summaryContent]="additionalContactSummary"
            class="gwace-summary-strip-wrapper">
    </tc-summary-strip>
    <div
            *tcExpansionPanelDetail>
        <mat-accordion *ngIf="additionalContactList.length>0" [multi]=false class="additional-contacts-list-wrapper">
            <ng-container *ngFor="let address of additionalContactList; index as i">
                <mat-expansion-panel
                    #expPanel
                    [attr.id]="'expansionPanel-'+i"
                    [hideToggle]="hideToggle">
                    <mat-expansion-panel-header [collapsedHeight]="'48px'" [expandedHeight]="'60px'">
                        <tc-additional-contact-summary-card
                                class="card-strip"
                                [hidden]="expPanel.expanded"
                                [additionalContactContent]="additionalContactContents[i]"
                                [isEditable]="true"
                                [addressCategoryMap]="categories"
                                [nameCodeMap]="nameCodeMap"
                                [contact]="address"
                                [showIcons]="selectedIndex === index"
                                (mouseleave)="hideIcons(index)"
                                (mouseenter)="showIcons()"
                                (deleteContact)="onDelete($event)"
                                class="gwacs-edit-card-wrapper">
                        </tc-additional-contact-summary-card>

                        <div [hidden]="!expPanel.expanded" class="expand-heading">
                            <tc-additional-contact-summary-card
                                    *ngIf="additionalContactContents"
                                    [isEditable]="true"
                                    [additionalContactContent]="additionalContactContents[i]"
                                    [contact]="address"
                                    [isHeader]="true"
                                    (deleteContact)="onDelete($event)"
                                    class="groupmaster-wholesaler-additional-contact-summary-card">
                            </tc-additional-contact-summary-card>
                        </div>

                    </mat-expansion-panel-header>
                    <tc-additional-contact-edit-card
                            [contactDetails]="address"
                            [categories]="filteredCategoriesForAdditionalContacts"
                            [titles]="titles"

                            [cityList]="cityList"
                            [stateList]="stateList"
                            [countryList]="countryList"
                            [countryStateCityChangeMap]="countryStateCityChangeMap"
                            (newCountryStateCityObserver)="onNewCountryStateCityObserver($event)"
                            (selectCountry)="onSelectCountry($event)"
                            (selectState)="onSelectState($event)"
                            (selectCity)="onSelectCity($event)"
                            (countryTextChange)="onCountryTextChange($event)"
                            (stateTextChange)="onStateTextChange($event)"
                            (cityTextChange)="onCityTextChange($event)"

                            (changeFormValidity)="checkValidity(address, $event)">

                    </tc-additional-contact-edit-card>
                </mat-expansion-panel>
                <hr/>
            </ng-container>
        </mat-accordion>
    </div>
</div>
