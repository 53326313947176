import * as tslib_1 from "tslib";
import { AbstractSearchCriteria } from './abstract-search-criteria';
var UserProfileSearchCriteria = /** @class */ (function (_super) {
    tslib_1.__extends(UserProfileSearchCriteria, _super);
    function UserProfileSearchCriteria() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.firstName = "";
        _this.lastName = "";
        _this.name = '';
        _this.email = "";
        _this.phoneNumber = "";
        _this.username = "";
        _this.supplierCode = "";
        _this.supplierName = "";
        _this.serviceType = "";
        _this.serviceTypeCodes = [];
        _this.restrictedServiceTypes = [];
        _this.allowedServiceTypes = [];
        _this.resourceLinkSuggestion = false;
        return _this;
    }
    return UserProfileSearchCriteria;
}(AbstractSearchCriteria));
export { UserProfileSearchCriteria };
