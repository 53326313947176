import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule, MatIconModule, MatRadioModule } from '@angular/material';
import { ResCalendarSideNavComponent } from './res-calendar-side-nav.component';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
  declarations: [ResCalendarSideNavComponent],
    imports: [
        CommonModule,
        MatIconModule,
        MatCheckboxModule,
        FormsModule,
        MatRadioModule,
        TranslateModule.forChild()
    ],
    exports: [
        ResCalendarSideNavComponent
    ]
})
export class ResCalendarSideNavModule { }
