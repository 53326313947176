<!--<mat-form-field>-->
<!--    <mat-label>Choose a date</mat-label>-->
<!--    <input matInput [matDatepicker]="picker" [(ngModel)]="selectedDate">-->
<!--    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>-->
<!--    <mat-datepicker #picker></mat-datepicker>-->
<!--</mat-form-field>-->

<input
        [readOnly]="isDisabled()"
        [value]="rawValue"
        (keyup.enter)="onInputChange($event)"
        (focusout)="onInputChange($event)"
        (focusin)="onFocusIn()"
        [ngxTimepicker]="picker"
        [min]="minTime"
        [max]="maxTime"
        [format]="format"
        style="width: 50px; border: none;background: transparent">
<button
        style="color:rgba(0,0,0,0.54)"
        mat-button
        mat-icon-button
        aria-label="more"
        (click)="setTime(); picker.open();"
        [disabled]="isDisabled()">
    <mat-icon matTooltip="{{'Select Time' | translate}}">schedule</mat-icon>
</button>
<ngx-material-timepicker
        [ngxMaterialTimepickerTheme]="theme"
        #picker
        [format]="format"
        (timeSet)="onValueChange($event)"></ngx-material-timepicker>
<!--<ngx-timepicker-field #picker [format]="format" ></ngx-timepicker-field>-->
