import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DirectivesModule } from '@tc/directives';
import { SectionHeadingModule } from '@tc/section-heading';
import { AdditionalContactsModule } from '../../../widgets/shared/additional-contacts/additional-contacts.module';
import { ContactDetailsModule } from '../../../widgets/shared/contact-details/contact-details.module';
import { DocumentViewCardModule } from '../../../widgets/shared/document-view-card/document-view-card.module';
import { InsuranceInformationModule } from '../../../widgets/shared/insurance-information/insurance-information.module';
import { PaymentInformationModule } from '../../../widgets/shared/payment-information/payment-information.module';
import { ServiceInformationModule } from '../../../widgets/shared/service-information/service-information.module';
import { SupplierAttributesModule } from '../../../widgets/shared/supplier-attributes/supplier-attributes.module';
import { TaxSchemesModule } from '../../../widgets/shared/tax-schemes/tax-schemes.module';
import { VehicleAmenitiesModule } from '../../../widgets/shared/vehicle-amenities/vehicle-amenities.module';
import { VehicleSpecificInformationModule } from '../../../widgets/shared/vehicle-specific-information/vehicle-specific-information.module';
import { KeyControlsEditViewModule } from '../common/key-controls-edit-view/key-controls-edit-view.module';
import { RemarksModule } from '../shared/remarks/remarks.module';
import { VehicleComponent } from './vehicle.component';

@NgModule({
    imports: [
        CommonModule,
        SectionHeadingModule,
        DirectivesModule,
        VehicleSpecificInformationModule,
        PaymentInformationModule,
        ContactDetailsModule,
        AdditionalContactsModule,
        ServiceInformationModule,
        InsuranceInformationModule,
        VehicleAmenitiesModule,
        SupplierAttributesModule,
        VehicleSpecificInformationModule,
        RemarksModule,
        TaxSchemesModule,
        DocumentViewCardModule,
        KeyControlsEditViewModule
    ],
  declarations: [VehicleComponent],
  exports:[VehicleComponent],
  entryComponents:[VehicleComponent]
})
export class VehicleModule { }
