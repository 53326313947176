<!--        [ngClass]="{'tc-strip-timeline__point&#45;&#45;hidden': i%2===1}"-->


<tc-timeline-unit
        *ngFor="let i of showingIndexes"

        [unitIndex]="i"
        [events]="getUnitRelevantEvents(i)"
        [isTarget]="i === this.targetIndex && !summaryContent.selected"
        [targetUnitIndex]="targetIndex"
        [targetUnitCount]="targetUnitCount"
        [targetMargin]="targetMargin"
        [unitSize]="unitSize"
        [unitWidth]="unitWidth"
        [isTargetBlock]="isTargetBlock"
        [unitTime]="getUnitTime(i)"

        (eventClick)="onEventClick($event)"
        (targetClick)="onTargetClick()"

        [ngClass]="{'tc-strip-timeline__point--mark': i%2===0}"
        class="tc-strip-timeline__point">
</tc-timeline-unit>
