import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from '@tc/pipes';
import { KeyControlsEditViewComponent } from './key-controls-edit-view.component';

@NgModule({
    declarations: [KeyControlsEditViewComponent],
    exports: [
        KeyControlsEditViewComponent
    ],
    imports: [
        CommonModule,
        MatInputModule,
        ReactiveFormsModule,
        TranslateModule.forChild(),
        MatSelectModule,
        FormsModule,
        MatMenuModule,
        PipesModule,
    ]
})
export class KeyControlsEditViewModule { }
