<div class="tc-item-wrapper">
    <div class="tc-item-header tc-flex-box">
        <div class="tc-flex-item tc-item-header__primary">{{'Route Information' | translate}}</div>
        <div class="tc-flex-item tc-item-header__actions tc-icon-block">
            <div class="tc-flex-item tc-item-header__actions tc-icon-block">
                <span><i class="material-icons tc-icon-block__icon tc-icon-block__icon--active"
                         (click)="saveRoute()">save</i></span>
                <span><i class="material-icons tc-icon-block__icon tc-icon-block__icon--active"
                         (click)="addRoute()">add</i></span>
            </div>

        </div>
    </div>
</div>
<form [formGroup]="routeSetupForm">

    <div class="container-fluid direct-client-personal-info-edit-card tc-card-wrapper">
        <div class="col-md-12 col-lg-12">
            <div class="row" formArrayName="routeData"
                 *ngFor="let cost of routeSetupForm.get('routeData')['controls']; let i = index;">
                <div [formGroupName]="i">
                    <div class="tc-flex-box">

                        <div class="tc-flex-item tc-right-cage">
                            <div class="row">
                                <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                    <div class="tc-select-edit dcpiec-select-code">
                                        <mat-form-field>
                                            <input matInput
                                                   id="DIR_PER_INFO_EDIT_CODE"
                                                   #routeInput
                                                   formControlName="routeCode"
                                                   [placeholder]="'Route Code'"
                                                   required>
                                            <!--<mat-error class="mat-error" role="alert">{{getErrorMessage('title')}}</mat-error>-->
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                    <div class="tc-select-edit dcpiec-select-title">
                                        <mat-form-field>
                                            <input matInput
                                                   id="DIR_PER_INFO_EDIT_NAME"
                                                   #routeInput
                                                   formControlName="routeName"
                                                   [placeholder]="'Route Name'"
                                                   required>
                                            <!--<mat-error class="mat-error" role="alert">{{getErrorMessage('title')}}</mat-error>-->
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                    <div class="tc-input-edit dcpiec-input-fname">
                                        <mat-form-field>
                                            <mat-select #fromLocName
                                                        id="DIR_PER_INFO_EDIT_FROMLOC"
                                                        formControlName="fromLocationGroup"
                                                        [placeholder]="'Location Group (From)'"
                                                        required>
                                                <mat-option *ngFor="let option of locationGroups"
                                                            [value]="option.groupCode">
                                                    {{option.groupName}}
                                                </mat-option>
                                            </mat-select>
                                            <!--<mat-error>{{getErrorMessage('title')}}</mat-error>-->
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                    <div class="tc-input-edit dcpiec-input-lname">
                                        <mat-form-field>
                                            <mat-select #toLocName
                                                        id="DIR_PER_INFO_EDIT_TOLOC"
                                                        formControlName="toLocationGroup"
                                                        [placeholder]="'Location Group (To)'"
                                                        required>
                                                <mat-option *ngFor="let option of locationGroups"
                                                            [value]="option.groupCode">
                                                    {{option.groupName}}
                                                </mat-option>
                                            </mat-select>
                                            <!--<mat-error>{{getErrorMessage('title')}}</mat-error>-->
                                        </mat-form-field>
                                    </div>
                                </div>

                            </div>
                            <!--<button class="btn btn-primary" type="submit" (click)="result()" [disabled]="!personalInfoForm.valid">Save</button>-->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>

