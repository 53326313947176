import { Injectable } from '@angular/core';
import { GridData } from '../../../models/ui/grid-data';


@Injectable()
export class GridDataMapperService {

    mapToGridData(dataArray: any[]): GridData[] {
        let gridDataArray: GridData[] = [];
        dataArray.forEach( data => {
            gridDataArray.push(this.mapEachToGridData(data.col1, data.col2, data.col3, data.col4, data.col5, data.col6));
        });
        return gridDataArray;
    }

    mapEachToGridData(col1: any, col2?: any, col3?: any, col4?: any, col5?: any, col6?: any): GridData {
        let gridData = new GridData();
        gridData.col1 = col1;
        gridData.col2 = col2;
        gridData.col3 = col3;
        gridData.col4 = col4;
        gridData.col5 = col5;
        gridData.col6 = col6;
        return gridData;
    }

}
