import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ConsoleToggleService
{
    constructor()
    {

    }

    disableConsoleInProduction(): void
    {
        if (environment.production) {
            console.warn(`🚨 Console output is disabled on production!`);
            console.log = (): void => { };
            // tslint:disable-next-line:no-console
            console.debug = (): void => { };
            console.warn = (): void => { };
            // tslint:disable-next-line:no-console
            console.info = (): void => { };
        }
    }
}
