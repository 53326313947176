import { DatePipe } from '@angular/common';
import {
    AfterViewChecked,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewChild
} from '@angular/core';
import { MatTabChangeEvent } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { TcErrorType } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-error';
import { DialogModel, LEVEL } from '@tc-core/model/it/codegen/ui/framework/dialog-model';
import { ModalData } from '@tc-core/model/it/codegen/ui/framework/modal-data';
import { TC } from '@tc-core/util';
import { ConfigLoader, EventManager, UserJourneyManager } from '@tc-core/util/framework';
import { SpinnerService } from '@tc-core/util/ui';
import { DialogService } from '@tc/dialog/dialog.service';
import { FocusViewService } from '@tc/focus-view/focus-view.service';
import { ModalService } from '@tc/modal/modal.service';
import { ICellEditorParams } from 'ag-grid-community';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { TCO } from '../../../constants';
import {
    DocumentCreationCriteria,
    DocumentFormat,
    DocumentType
} from '../../../models/criteria/document-creation-criteria';
import { DocumentDataCriteria } from '../../../models/criteria/document-data-criteria';
import { LocationsSearchCriteria } from '../../../models/criteria/locations-search-criteria';
import { DocumentCreationModel } from '../../../models/helper/document-creation-model';
import { AllocationType } from '../../../models/reservation-v2/allocation-type';
import { AssignStatus, ServiceLevel, StatusCodes } from '../../../models/reservation-v2/assignment';
import { AssignmentNote } from '../../../models/reservation-v2/assignment-note';
import { AssignmentPatch } from '../../../models/reservation-v2/assignment-patch';
import { AssignmentRawData } from '../../../models/reservation-v2/assignment-raw-data';
import { BookingItem, BookingItemGridParam } from '../../../models/reservation-v2/booking-item';
import { BookingItemPatch } from '../../../models/reservation-v2/booking-item-patch';
import { BookingNote } from '../../../models/reservation-v2/booking-note';
import { BookingPassenger } from '../../../models/reservation-v2/booking-passenger';
import { BookingPaxMapping } from '../../../models/reservation-v2/booking-pax-mapping';
import { BookingSupplement } from '../../../models/reservation-v2/booking-supplement';
import { CostingType } from '../../../models/reservation-v2/costing-type';
import { BookingItemSearchCriteria } from '../../../models/reservation-v2/criteria/booking-item-search-criteria';
import { ProductQueueSearchCriteria } from '../../../models/reservation-v2/criteria/product-queue-search-criteria';
import {
    ResourceAllocationSearchCriteria
} from '../../../models/reservation-v2/criteria/resource-allocation-search-criteria';
import { ServiceItemSearchCriteria } from '../../../models/reservation-v2/criteria/service-item-search-criteria';
import {
    SupplierAllocationSearchCriteria
} from '../../../models/reservation-v2/criteria/supplier-allocation-search-criteria';
import { MainType } from '../../../models/reservation-v2/main-type';
import { PassengerAttribute } from '../../../models/reservation-v2/passenger-attribute';
import { PassengerType } from '../../../models/reservation-v2/passenger-type';
import { ProductItem, QueueType } from '../../../models/reservation-v2/product-item';
import { ProductLevel } from '../../../models/reservation-v2/product-level';
import {
    AllocationTimeRange,
    ResourceAllocationSummary
} from '../../../models/reservation-v2/resource-allocation-summary';
import { ServiceItem, ServiceItemGridParam } from '../../../models/reservation-v2/service-item';
import { ServiceSharing } from '../../../models/reservation-v2/service-sharing';
import { SupplierAllocationSummary } from '../../../models/reservation-v2/supplier-allocation-summary';
import { TIME_GROUPING_OPTIONS } from '../../../models/reservation-v2/time-grouping-options';
import { OPResponseWrapper } from '../../../models/response/op-response-wrapper';
import { GenResourceCost } from '../../../models/supplier/gen-resource-cost';
import { ResourceCost } from '../../../models/supplier/resource-cost';
import { UserBusinessType } from '../../../models/user/user-business-type';
import { DocumentService } from '../../../services/backend-consumers/documents/document.service';
import {
    ReservationV2ManagementService
} from '../../../services/backend-consumers/reservation-v2/reservation-v2-management.service';
import { BackendConfigService } from '../../../services/backend-consumers/service-config/backend-config.service';
import { LocationsService } from '../../../services/backend-consumers/setups/locations.service';
import { SupplierService } from '../../../services/backend-consumers/supplier-service/supplier.service';
import {
    CostCalculationUtilsV2Service
} from '../../../services/business-utils/cost-calculation/cost-calculation-utils-v2.service';
import { DateTimeProcessor } from '../../../services/business-utils/time-zone/date-time-processor';
import { BackgroundTaskService } from '../../../services/util/change-detector/background-task.service';
import { DMCCommon } from '../../../services/util/common/dmc-common';
import { DataKey, DataStoreService } from '../../../services/util/framework/data-store.service';
import {
    GridColumnDefinitionProcessorService
} from '../../../services/util/pre-processors/grid-column-definition-processor.service';
import {
    OperationV2DataProcessorService
} from '../../../services/util/pre-processors/operation-v2-data-processor.service';
import { ResponseUtil } from '../../../services/util/response/response-util.service';
import { DMCLocalStorageService, LocalDataKey } from '../../../services/util/system/dmc-local-storage.service';
import {
    AllocationSuggestionEditorComponent
} from '../../../widgets/framework/ag-grid-custom-components/editors/allocation-suggestion-editor/allocation-suggestion-editor.component';
import {
    AutoCompleteEditorComponent
} from '../../../widgets/framework/ag-grid-custom-components/editors/auto-complete-editor/auto-complete-editor.component';
import {
    DateEditorComponent
} from '../../../widgets/framework/ag-grid-custom-components/editors/date-editor/date-editor.component';
import {
    DropdownEditorComponent
} from '../../../widgets/framework/ag-grid-custom-components/editors/dropdown-editor/dropdown-editor.component';
import {
    MaxLengthValidatorComponent
} from '../../../widgets/framework/ag-grid-custom-components/editors/max-length-validator/max-length-validator.component';
import {
    MultiSelectorEditorComponent
} from '../../../widgets/framework/ag-grid-custom-components/editors/multi-selector-editor/multi-selector-editor.component';
import {
    RadioButtonEditorComponent
} from '../../../widgets/framework/ag-grid-custom-components/editors/radio-button-editor/radio-button-editor.component';
import {
    CheckBoxRendererEditorComponent
} from '../../../widgets/framework/ag-grid-custom-components/renderers/check-box-selector-renderer/check-box-renderer-editor.component';
import {
    TemplateRendererComponent
} from '../../../widgets/framework/ag-grid-custom-components/renderers/template-renderer/template-renderer.component';
import {
    TimeEditorRendererComponent
} from '../../../widgets/framework/ag-grid-custom-components/renderers/time-editor-renderer/time-editor-renderer.component';
import { DocumentCreationModalComponent } from '../../document-creation-modal/document-creation-modal.component';
import {
    SearchCriteriaSaveModalComponent
} from '../../reservation-management/search-criteria-save-modal/search-criteria-save-modal.component';
import {
    AllocationFocusViewComponent
} from '../allocation-panel/allocation-focus-view/allocation-focus-view.component';
import {
    EditTimeRangeComponent
} from '../allocation-panel/resource-allocation-panel/edit-time-range/edit-time-range.component';
import { BookingNoteGroup, NotesEditorComponent } from '../notes-editor/notes-editor.component';
import { BookingPassengerGroup, PassengerEditorComponent } from '../passenger-editor/passenger-editor.component';
import { BookingSupplementGroup, SupplementsEditorComponent } from '../supplements-editor/supplements-editor.component';
import { TotalCostEditorComponent } from '../total-cost-editor/total-cost-editor.component';
import { BookingQueueComponent } from './booking-queue/booking-queue.component';
import { OperationQueuePanelConfig, OperationQueueProfile } from './operation-queue-panel-config';
import { ProductQueueComponent } from './product-queue/product-queue.component';
import { ServiceQueueComponent } from './service-queue/service-queue.component';
import EMPTY_CHAR = TC.FORMAT.EMPTY_CHAR;
import {UserManagementService} from '../../../services/user-management/user-management.service';

@Component({
    selector: 'tc-operation-queue-panel',
    templateUrl: './operation-queue-panel.component.html'
})
export class OperationQueuePanelComponent implements OnInit, OnDestroy, AfterViewChecked {
    EMPTY_STRING = '--';
    NOT_AVAILABLE_STRING = 'N/A';
    userBusinessType: any;
    pageOptionsAvailability: any;
    availableDocumentTypes: any[] = [];
    allAvailableDocumentTypes: any[] = [];
    manifestGenerationStatus: any[] = [];

    private selectedResource: ResourceAllocationSummary;
    private itemList: (ServiceItem | BookingItem)[] = [];
    allocationTimeRange = new AllocationTimeRange();
    private tempSuppliers: any;

    constructor(
        private cd: ChangeDetectorRef,
        public em: EventManager,
        private userJourneyManager: UserJourneyManager,
        private configLoader: ConfigLoader,
        private dialogService: DialogService,
        private modalService: ModalService,
        private common: DMCCommon,
        private spinnerService: SpinnerService,
        private dataStore: DataStoreService,
        private focusViewService: FocusViewService,
        private columnDefinitionProcessorService: GridColumnDefinitionProcessorService,
        private reservationV2ManagementService: ReservationV2ManagementService,
        private costCalculationUtilsService: CostCalculationUtilsV2Service,
        private documentService: DocumentService,
        public operationV2DataProcessorService: OperationV2DataProcessorService,
        private datePipe: DatePipe,
        private translateService: TranslateService,
        private locationService: LocationsService,
        private localStorage: DMCLocalStorageService,
        private backgroundTaskService: BackgroundTaskService,
        private backendConfigService: BackendConfigService,
        private userManagementService: UserManagementService
    ) {
        this.allAvailableDocumentTypes = this.configLoader.configurations.get(TCO.CONF.CONF_DOCUMENT_RULE).AVAILABLE_DOCUMENTS_FOR_GENERATION;
    }

    @ViewChild('productQueueComponent') productQueueComponent: ProductQueueComponent;
    @ViewChild('bookingQueueComponent') bookingQueueComponent: BookingQueueComponent;
    @ViewChild('serviceQueueComponent') serviceQueueComponent: ServiceQueueComponent;

    /* PRODUCT QUEUE DATA */
    productQueueSearchCriteria: ProductQueueSearchCriteria;
    totalProductsCount = 0;
    selectedProducts: ProductItem[] = [];

    /* PRODUCT QUEUE COL DEF */
    productQueueColDefConfigJson: any[] = [];
    productQueueColDefConfig: any[] = [];

    /* BOOKING QUE COL DEF */
    bookingQueueColDefConfigJson: any[] = [];
    bookingQueueAllColDefConfigJson: any[] = [];
    bookingQueuePrimaryColDefConfigJson: any[] = [];
    bookingQueueSecondaryColDefConfigJson: any[] = [];

    bookingQueueColDefConfig: any[] = [];
    bookingQueueAllColDefConfig: any[] = [];
    bookingQueuePrimaryColDefConfig: any[] = [];
    bookingQueueSecondaryColDefConfig: any[] = [];

    /* SERVICE QUEUE COL DEF */
    serviceQueueColDefConfigJson: any[] = [];
    serviceQueueAllColDefConfigJson: any[] = [];
    serviceQueuePrimaryColDefConfigJson: any[] = [];
    serviceQueueSecondaryColDefConfigJson: any[] = [];

    serviceQueueColDefConfig: any[] = [];
    serviceQueueAllColDefConfig: any[] = [];
    serviceQueuePrimaryColDefConfig: any[] = [];
    serviceQueueSecondaryColDefConfig: any[] = [];

    searchCriteria = new ProductQueueSearchCriteria();

    selectedBookingIdList = [];
    allBookingIdList = [];

    /* SUBSCRIPTIONS */
    private productSearchCriteriaSubscription: Subscription = new Subscription();
    private focusViewCloseSubject: Subscription = new Subscription();
    private focusViewConfirmSubject: Subscription = new Subscription();
    private resourceTypesSubscription: Subscription = new Subscription();
    private supplierOptionsSubscription: Subscription = new Subscription();
    private costingTypesSubscription: Subscription = new Subscription();
    private languagesSubscription: Subscription = new Subscription();
    private trsModesSubscription: Subscription = new Subscription();
    private routesSubscription: Subscription = new Subscription();
    private resourceAllocationResultsSubscription: Subscription = new Subscription();
    private supplierAllocationResultsSubscription: Subscription = new Subscription();
    private popupSubscription: Subscription = new Subscription();
    private allocationTimeRangeSubscription: Subscription = new Subscription();
    private modelConfirmSubscription: Subscription = new Subscription();
    private modelCloseSubscription: Subscription = new Subscription();
    private dialogSubscription: Subscription = new Subscription();

    /* CHANGE PREVENTION */
    /* TAB switching - off */
    /* TAB switching - product item selection -off */
    operationPanelConfig = new OperationQueuePanelConfig();
    preventSwitching = false;

    showAllocationQueues = false;
    showServicingQueue = true;
    showBookingQueue = true;
    showSecondaryJobStatus = true;

    /* SERVICE QUEUE TIME GROUPING */
    serviceQueueTimeGroupingOptions = TIME_GROUPING_OPTIONS;

    @ViewChild('menuCell') menuCell: TemplateRef<any>;
    @ViewChild('assignStatusCell') assignStatusCell: TemplateRef<any>;
    @ViewChild('totalCostCell') totalCostCell: TemplateRef<any>;

    /* EDITOR DATA */
    // instead of creating object in each key down using exisiting serch object for dropdown search
    supplierSearchCriteria: SupplierAllocationSearchCriteria = new SupplierAllocationSearchCriteria();
    resourceSearchCriteria: ResourceAllocationSearchCriteria = new ResourceAllocationSearchCriteria();

    rawAssignmentTempId = 1;

    frameworkComponents = {
        templateRenderer: TemplateRendererComponent,
        autoCompleteEditor: AutoCompleteEditorComponent,
        dropDownEditor: DropdownEditorComponent,
        timeEditorRenderer: TimeEditorRendererComponent,
        dateEditor: DateEditorComponent,
        allocationSuggestionEditor: AllocationSuggestionEditorComponent,
        checkBoxRendererEditor: CheckBoxRendererEditorComponent,
        multiSelectorEditor: MultiSelectorEditorComponent,
        radioButtonEditor: RadioButtonEditorComponent,
        maxLengthValidator: MaxLengthValidatorComponent
    };

    // helper data
    resourceTypes: any[];
    costingTypes: any[];
    languages: any[];
    transferModes: any[];
    routes: any[];

    PENDING = AssignStatus.PENDING;
    ASSIGNED = AssignStatus.ASSIGNED;
    UNASSIGNED = AssignStatus.UNASSIGNED;
    DMC_CONFIRM = AssignStatus.DMC_CONFIRM;
    REQUEST_EXPIRED = AssignStatus.REQUEST_EXPIRED;
    SUPPLIER_CONFIRMED = AssignStatus.SUPPLIER_CONFIRMED;
    SUPPLIER_REJECTED = AssignStatus.SUPPLIER_REJECTED;
    BOOKING_UPDATE = AssignStatus.BOOKING_UPDATE;
    INACTIVE = AssignStatus.INACTIVE;
    INACTIVE_NOTIFY = AssignStatus.INACTIVE_NOTIFY;
    STARTED = AssignStatus.STARTED;
    NO_SHOW = AssignStatus.NO_SHOW;
    COMPLETED = AssignStatus.COMPLETED;

    SUPPLIER_MANIFEST = "SUPPLIER_MANIFEST"

    saveButtonEnable = false;
    revertButtonEnable = false;
    showManifestStatusAllocQueue = true;
    confirmButtonEnable = true;
    sendManifestButtonEnable = true;
    supplierConfirmButtonEnable = true;
    supplierRejectButtonEnable = true;
    documentButtonEnable = true;
    autoAllocationButtonEnable = true;
    showConfirmButton = true;
    showAutoAllocationButton = true;

    isHiddenTimeRangeKebabButton = false;
    isHiddenPrimaryResourceKebabButton = false;
    isHiddenSecondaryResourceKebabButton = false;
    isHiddenPassengersKebabButton = false;
    isHiddenNotesKebabButton = false;
    isHiddenSupplementsKebabButton = false;

    ngOnInit() {
        this.pageOptionsAvailability = this.configLoader.configurations.get(TCO.CONF.CONF_PAGE_OPTION_AVAILABILITY).ALLOCATION_QUEUES;
        this.availableDocumentTypes = this.allAvailableDocumentTypes ?
            this.allAvailableDocumentTypes :
            this.configLoader.configurations.get(TCO.CONF.CONF_DOCUMENT_RULE).AVAILABLE_DOCUMENTS_FOR_GENERATION;
        this.manifestGenerationStatus = this.configLoader.configurations.get(TCO.CONF.CONF_DOCUMENT_RULE).MANIFEST_GENERATION_STATUS;

        const kebabConfig = this.configLoader.configurations.get(TCO.CONF.CONF_TBL_DEF_BOOKING_QUEUE_ACT_BUTTON_CONFIG);
        this.isHiddenTimeRangeKebabButton = kebabConfig.timeRangeKebabButton.hide;
        this.isHiddenPrimaryResourceKebabButton = kebabConfig.primaryResourceKebabButton.hide;
        this.isHiddenSecondaryResourceKebabButton = kebabConfig.secondaryResourceKebabButton.hide;
        this.isHiddenPassengersKebabButton = kebabConfig.passengersKebabButton.hide;
        this.isHiddenNotesKebabButton = kebabConfig.notesKebabButton.hide;
        this.isHiddenSupplementsKebabButton = kebabConfig.supplementsKebabButton.hide;

        this.backendConfigService.loadBackendConfigs();
        this.setupPageOptions();
        this.validateUserPanels();
        this.loadResourceTypes();
        this.loadCostingTypes();
        this.loadLanguages();
        this.loadTransferModes();
        this.loadRoutes();
        this.addQuickActionEvents();
        this.subscribeProductSearchCriteria();
        this.generateProductQueueColumnDefs();
        this.subscribeAllocationResults();
        this.operationPanelConfig.timeGrouping = this.serviceQueueTimeGroupingOptions[0].code;

    }

    public ngAfterViewChecked(): void {
        this.cd.detectChanges();
    }

    isStatusBubbleVisible(row: ServiceItem | BookingItem) {
        return row && row.primaryAssignment || row && row.secondaryAssignment;
    }

    primaryAssignmentStatusCode(row: ServiceItem | BookingItem) {
        if (row && row.primaryAssignment) {
            return StatusCodes[row.primaryAssignment.assignStatus];
        } else {
            return this.EMPTY_STRING;
        }
    }

    secondaryAssignmentStatusCode(row: ServiceItem | BookingItem) {
        if (row && row.secondaryAssignment) {
            return StatusCodes[row.secondaryAssignment.assignStatus];
        } else {
            return this.EMPTY_STRING;
        }
    }

    addQuickActionEvents() {
        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'SAVE_ASSIGNMENTS').subscribe(e => {
            this.onSaveAssignmentsClick();
        });
        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'REVERT_ASSIGNMENTS').subscribe(e => {
            this.onRevertAssignmentsClick();
        });
        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'CONFIRM_ASSIGNMENTS').subscribe(e => {
            this.onConfirmClick();
        });
        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'SEND_MANIFESTS').subscribe(e => {
            this.onSendManifestsClick();
        });
        this.em.addEvent(TCO.CONF.CONF_EVENT_MANAGER, 'SUP_CONFIRM_ASSIGNMENTS').subscribe(e => {
            this.onSupplierConfirmClick();
        });
        this.em.addEvent(TCO.CONF.CONF_EVENT_MANAGER, 'SUP_REJECT_ASSIGNMENTS').subscribe(e => {
            this.onSupplierRejectClick();
        });
        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'GENERATE_DOCUMENTS').subscribe(e => {
            this.onGenerateDocumentsClick();
        });
        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'GENERATE_AUTO_ALLOCATIONS').subscribe(e => {
            this.onSupplierAutoAllocationClick();
        });
    }

    loadResourceTypes() {
        this.resourceTypesSubscription = this.dataStore.get(DataKey.resourceTypes, true)
                                             .subscribe(data => {
                                                     if (data && Array.isArray(data)) {
                                                         this.resourceTypes = data;
                                                     }
                                                 }
                                             );
    }

    loadCostingTypes() {
        this.costingTypesSubscription = this.dataStore.get(DataKey.costingTypes, true)
                                            .subscribe(costingTypes => {
                                                    if (costingTypes && Array.isArray(costingTypes)) {
                                                        this.costingTypes = costingTypes;
                                                    } else if (costingTypes && costingTypes.data &&
                                                        Array.isArray(costingTypes.data)) {
                                                        this.costingTypes = costingTypes.data;
                                                    }
                                                }
                                            );
    }

    loadLanguages() {
        this.languagesSubscription = this.dataStore.get(DataKey.allLanguages, true)
                                         .subscribe(data => {
                                                 if (data && Array.isArray(data)) {
                                                     this.languages = data;
                                                 } else if (data && data.data && Array.isArray(data.data)) {
                                                     this.languages = data.data;
                                                 }
                                             }
                                         );
    }

    loadTransferModes() {
        this.trsModesSubscription = this.dataStore.get(DataKey.transferModes, true)
                                        .subscribe(data => {
                                                if (data && Array.isArray(data)) {
                                                    this.transferModes = data;
                                                } else if (data && data.data && Array.isArray(data.data)) {
                                                    this.transferModes = data.data;
                                                }
                                            }
                                        );
    }

    loadRoutes() {
        this.routesSubscription = this.dataStore.get(DataKey.routes, true)
                                      .subscribe(data => {
                                              if (data && Array.isArray(data)) {
                                                  this.routes = data;
                                              } else if (data && data.data && Array.isArray(data.data)) {
                                                  this.routes = data.data;
                                              }
                                          }
                                      );
    }

    subscribeProductSearchCriteria() {
        this.productSearchCriteriaSubscription = this.dataStore.get(DataKey.productQueueSearchCriteria, true)
                                                     .subscribe((data: ProductQueueSearchCriteria) => {
                                                         if (data) {
                                                             this.showAllocationQueues = false;
                                                             this.productQueueSearchCriteria = data;
                                                             this.showLoadingProducts();
                                                             this.setupOperationQueueProfile(this.productQueueSearchCriteria);
                                                             this.setupServiceBookingQueuesForQueueProfile();
                                                             this.revertChanges();
                                                         }
                                                     });
    }

    showLoadingProducts() {
        if (this.productQueueComponent) {
            this.productQueueComponent.showLoadingOverlay();
        } else {
            setTimeout(() => this.showLoadingProducts(), 0);
        }
    }

    setupOperationQueueProfile(productQueueSearchCriteria: ProductQueueSearchCriteria) {
        if (productQueueSearchCriteria.mainType === MainType.TRS) {
            this.operationPanelConfig.activatedProfile = OperationQueueProfile.TRS_PROFILE;
        } else if (productQueueSearchCriteria.mainType === MainType.HTL) {
            this.operationPanelConfig.activatedProfile = OperationQueueProfile.HOTEL_PROFILE;
        } else if (productQueueSearchCriteria.productLevel === ProductLevel.ANY &&
            productQueueSearchCriteria.mainType === MainType.TOU) {
            this.operationPanelConfig.activatedProfile = OperationQueueProfile.TOUR_PROFILE;
        } else if (productQueueSearchCriteria.mainType === MainType.GEN) {
            this.operationPanelConfig.activatedProfile = OperationQueueProfile.GENERIC_PROFILE;
        } else {
            this.operationPanelConfig.activatedProfile = OperationQueueProfile.COMMON_PROFILE;
        }
        console.log(productQueueSearchCriteria.productLevel);
        console.log(productQueueSearchCriteria.mainType);
        console.log('Operation queue profile: ' + this.operationPanelConfig.activatedProfile);
    }

    private generateProductQueueColumnDefs() {
        this.productQueueColDefConfigJson = this.configLoader.configurations.get(TCO.CONF.CONF_PRODUCT_QUEUE_COL_DEF_CONFIG);
        const productQueueColDefConfigJsonClone = JSON.parse(JSON.stringify(this.productQueueColDefConfigJson));
        this.productQueueColDefConfig = this.columnDefinitionProcessorService.createColumnDefs(
            productQueueColDefConfigJsonClone,
            this
        );
    }

    private setupServiceBookingQueuesForQueueProfile() {
        if (this.operationPanelConfig.activatedProfile) {
            this.generateServiceBookingQueueConfigs(this.operationPanelConfig.activatedProfile);
        }
    }

    private generateServiceBookingQueueConfigs(profile: OperationQueueProfile) {
        const serviceQueueAllColDefConfigJsonTemp = [];
        const serviceQueueColDefConfigJsonTemp = [];
        const bookingQueueAllColDefConfigJsonTemp = [];
        const bookingQueueColDefConfigJsonTemp = [];
        const serviceQueuePrimaryColDefConfigJsonTemp = [];
        const bookingQueuePrimaryColDefConfigJsonTemp = [];
        const serviceQueueSecondaryColDefConfigJsonTemp = [];
        const bookingQueueSecondaryColDefConfigJsonTemp = [];

        const loadServicingDefaultColDefs = this.configLoader.configurations
                                                .get(TCO.CONF.CONF_COL_DEF_SERVICE_QUEUE_PROFILES_CONFIG)[profile].DEFAULT;
        const loadServicingPrimaryColDefs = this.configLoader.configurations
                                                .get(TCO.CONF.CONF_COL_DEF_SERVICE_QUEUE_PROFILES_CONFIG)[profile].PRIMARY;
        const loadServicingSecondaryColDefs = this.configLoader.configurations
                                                  .get(TCO.CONF.CONF_COL_DEF_SERVICE_QUEUE_PROFILES_CONFIG)[profile].SECONDARY;
        const loadServicingAllColDefs = this.configLoader.configurations
                                            .get(TCO.CONF.CONF_COL_DEF_SERVICE_QUEUE_PROFILES_CONFIG)[profile].ALL;

        const loadBookingDefaultColDefs = this.configLoader.configurations
                                              .get(TCO.CONF.CONF_COL_DEF_BOOKING_QUEUE_PROFILES_CONFIG)[profile].DEFAULT;
        const loadBookingPrimaryColDefs = this.configLoader.configurations
                                              .get(TCO.CONF.CONF_COL_DEF_BOOKING_QUEUE_PROFILES_CONFIG)[profile].PRIMARY;
        const loadBookingSecondaryColDefs = this.configLoader.configurations
                                                .get(TCO.CONF.CONF_COL_DEF_BOOKING_QUEUE_PROFILES_CONFIG)[profile].SECONDARY;
        const loadBookingAllColDefs = this.configLoader.configurations
                                          .get(TCO.CONF.CONF_COL_DEF_BOOKING_QUEUE_PROFILES_CONFIG)[profile].ALL;

        const allBookingCols = this.configLoader.configurations.get(TCO.CONF.CONF_BOOKING_QUEUE_ALL_COL_DEF_CONFIG);
        const allServiceCols = this.configLoader.configurations.get(TCO.CONF.CONF_SERVICE_QUEUE_ALL_COL_DEF_CONFIG);

        if (allBookingCols) {
            loadBookingDefaultColDefs.forEach(colDef => {
                const res = allBookingCols.find(col => col.field === colDef);
                if (res.field === colDef) {
                    bookingQueueColDefConfigJsonTemp.push(res);
                }
            });
            loadBookingPrimaryColDefs.forEach(colDef => {
                const res = allBookingCols.find(col => col.field === colDef);
                if (res.field === colDef) {
                    bookingQueuePrimaryColDefConfigJsonTemp.push(res);
                }
            });
            loadBookingSecondaryColDefs.forEach(colDef => {
                const res = allBookingCols.find(col => col.field === colDef);
                if (res.field === colDef) {
                    bookingQueueSecondaryColDefConfigJsonTemp.push(res);
                }
            });
            loadBookingAllColDefs.forEach(colDef => {
                const res = allBookingCols.find(col => col.field === colDef);
                if (res.field === colDef) {
                    bookingQueueAllColDefConfigJsonTemp.push(res);
                }
            });
        }

        this.bookingQueueAllColDefConfigJson = bookingQueueAllColDefConfigJsonTemp;
        this.bookingQueueColDefConfigJson = bookingQueueColDefConfigJsonTemp;
        this.bookingQueuePrimaryColDefConfigJson = bookingQueuePrimaryColDefConfigJsonTemp;
        this.bookingQueueSecondaryColDefConfigJson = bookingQueueSecondaryColDefConfigJsonTemp;

        const bookingQueueAllColDefConfigJsonClone = JSON.parse(JSON.stringify(this.bookingQueueAllColDefConfigJson));
        const bookingQueueColDefConfigJsonClone = JSON.parse(JSON.stringify(this.bookingQueueColDefConfigJson));
        const bookingQueuePrimaryColDefConfigJsonClone = JSON.parse(JSON.stringify(this.bookingQueuePrimaryColDefConfigJson));
        const bookingQueueSecondaryColDefConfigJsonClone = JSON.parse(JSON.stringify(this.bookingQueueSecondaryColDefConfigJson));

        this.bookingQueueAllColDefConfig = this.columnDefinitionProcessorService.createColumnDefs(
            bookingQueueAllColDefConfigJsonClone,
            this
        );
        this.bookingQueueColDefConfig = this.columnDefinitionProcessorService.createColumnDefs(
            bookingQueueColDefConfigJsonClone,
            this
        );
        this.bookingQueuePrimaryColDefConfig = this.columnDefinitionProcessorService.createColumnDefs(
            bookingQueuePrimaryColDefConfigJsonClone,
            this
        );
        this.bookingQueueSecondaryColDefConfig = this.columnDefinitionProcessorService.createColumnDefs(
            bookingQueueSecondaryColDefConfigJsonClone,
            this
        );

        if (allServiceCols) {
            loadServicingDefaultColDefs.forEach(colDef => {
                const res = allServiceCols.find(col => col.field === colDef);
                if (res.field === colDef) {
                    serviceQueueColDefConfigJsonTemp.push(res);
                }
            });
            loadServicingPrimaryColDefs.forEach(colDef => {
                const res = allServiceCols.find(col => col.field === colDef);
                if (res.field === colDef) {
                    serviceQueuePrimaryColDefConfigJsonTemp.push(res);
                }
            });
            loadServicingSecondaryColDefs.forEach(colDef => {
                const res = allServiceCols.find(col => col.field === colDef);
                if (res.field === colDef) {
                    serviceQueueSecondaryColDefConfigJsonTemp.push(res);
                }
            });
            loadServicingAllColDefs.forEach(colDef => {
                const res = allServiceCols.find(col => col.field === colDef);
                if (res.field === colDef) {
                    serviceQueueAllColDefConfigJsonTemp.push(res);
                }
            });
        }

        this.serviceQueueAllColDefConfigJson = serviceQueueAllColDefConfigJsonTemp;
        this.serviceQueueColDefConfigJson = serviceQueueColDefConfigJsonTemp;
        this.serviceQueuePrimaryColDefConfigJson = serviceQueuePrimaryColDefConfigJsonTemp;
        this.serviceQueueSecondaryColDefConfigJson = serviceQueueSecondaryColDefConfigJsonTemp;

        const serviceQueueAllColDefConfigJsonClone = JSON.parse(JSON.stringify(this.serviceQueueAllColDefConfigJson));
        const serviceQueueColDefConfigJsonClone = JSON.parse(JSON.stringify(this.serviceQueueColDefConfigJson));
        const serviceQueuePrimaryColDefConfigJsonClone = JSON.parse(JSON.stringify(this.serviceQueuePrimaryColDefConfigJson));
        const serviceQueueSecondaryColDefConfigJsonClone = JSON.parse(JSON.stringify(this.serviceQueueSecondaryColDefConfigJson));

        this.serviceQueueAllColDefConfig = this.columnDefinitionProcessorService.createColumnDefs(
            serviceQueueAllColDefConfigJsonClone,
            this
        );
        this.serviceQueueColDefConfig = this.columnDefinitionProcessorService.createColumnDefs(
            serviceQueueColDefConfigJsonClone,
            this
        );
        this.serviceQueuePrimaryColDefConfig = this.columnDefinitionProcessorService.createColumnDefs(
            serviceQueuePrimaryColDefConfigJsonClone,
            this
        );
        this.serviceQueueSecondaryColDefConfig = this.columnDefinitionProcessorService.createColumnDefs(
            serviceQueueSecondaryColDefConfigJsonClone,
            this
        );
        console.log('set config of service queue for ' + profile);
    }

    public isTimeGroupingVisible() {
        return (this.operationPanelConfig.currentTab === 0) &&
            (this.operationPanelConfig.activatedProfile === OperationQueueProfile.TRS_PROFILE);
    }

    setAllProducts(event: ProductItem[]) {
        this.allBookingIdList = [];
        event.forEach(o => {
            this.allBookingIdList.push(...o.bookingIdList);
        });
    }

    public onProductSelectionChange(event: ProductItem[]) {

        // collecting the bookingIds
        this.selectedBookingIdList = [];
        event.forEach(o => {
            this.selectedBookingIdList.push(o.bookingIdList);
        });

        if (event && event.length > 0) {
            this.showAllocationQueues = true;
        } else {
            this.showAllocationQueues = false;
        }
        this.selectedProducts = event;
        this.cd.detectChanges();
        if (this.operationPanelConfig.currentTab === 0) {
            if (this.serviceQueueComponent) {
                this.serviceQueueComponent.loadData();
            }
        } else if (this.operationPanelConfig.currentTab === 1) {
            if (this.bookingQueueComponent) {
                this.bookingQueueComponent.loadData();
            }
        }
    }

    public onTabChange(event: MatTabChangeEvent) {
        this.operationPanelConfig.currentTab = event.index;
    }

    public isServiceQueue() {
        return this.operationPanelConfig && this.operationPanelConfig.currentTab === 0;
    }

    public isBookingQueue() {
        return this.operationPanelConfig && this.operationPanelConfig.currentTab === 1;
    }

    public isPrimaryAssignmentRestricted(params: any) {
        const item: (BookingItem | ServiceItem) = params.data;
        return !(this.isEditableType(item, true));
    }

    public isEditTimeRestricted(params: any) {
        const item: (BookingItem | ServiceItem) = params.data;
        return !(this.hasNoAnyAssignment(params));
    }

    public hasPassengerAttributes(row: ServiceItem | BookingItem) {
        for (const bookingPassenger of row.bookingPassengers) {
            if (bookingPassenger.passengerAttributes && bookingPassenger.passengerAttributes.length) {
                return true;
            }
        }
        return false;
    }

    public getPassengerAttributesToolTip(row: ServiceItem | BookingItem) {
        const attributes: PassengerAttribute[] = [];
        for (const bookingPassenger of row.bookingPassengers) {
            if (bookingPassenger.passengerAttributes) {
                for (const passengerAttribute of bookingPassenger.passengerAttributes) {
                    const passengerAttributeFound = attributes.find(value => value.name === passengerAttribute.name &&
                        value.value ===
                        passengerAttribute.value);
                    if (!passengerAttributeFound) {
                        attributes.push(passengerAttribute);
                    }
                }
            }
        }

        let text = `Passenger Attributes: `;
        if (attributes.length) {
            for (const passengerAttribute of attributes) {
                text += passengerAttribute.name + '=' + passengerAttribute.value + '  ';
            }
        }
        return text;
    }

    public onPrimaryAssignmentClick(params: any) {
        let queueType: QueueType;
        const bookingIdList = [];
        if (this.operationPanelConfig && this.operationPanelConfig.currentTab === 0) {
            console.log('service queue 1: ' + params.data);
            queueType = QueueType.SERVICE;
            const serviceItem: ServiceItem = params.data;
            if (serviceItem && serviceItem.bookingItemSummaryList) {
                serviceItem.bookingItemSummaryList.forEach(value => bookingIdList.push(value.bookingItemId));
            }
        } else {
            queueType = QueueType.BOOKING;
            console.log('booking queue 1: ' + params.data);
            bookingIdList.push(params.data.id);
        }
        if (params && params.data && (!params.data.startTime || !params.data.endTime)) {
            this.onTimeRangeChangeClick(params, bookingIdList, true, true, false);
        } else {
            this.openAllocationFocusView(params.data, bookingIdList, true, false);
        }

    }

    public markChanges() {
        this.operationPanelConfig.preventSwitching = true;
        this.enableSaveButton();
        this.enableConfirmButton();
    }

    public revertChanges() {
        this.rawAssignmentTempId = 1;
        this.operationPanelConfig.preventSwitching = false;
        this.revertButtonEnable = false;
        this.disableSaveButton();
    }

    public isSecondaryAssignmentRestricted(params: any) {
        const item: (BookingItem | ServiceItem) = params.data;
        return !(this.isEditableType(item, false));
    }

    isEditableType(item: (BookingItem | ServiceItem), isPrimary: boolean) {
        if (isPrimary) {
            return (item && ((item.primaryAssignment && item.primaryAssignment.assignStatus &&
                (item.primaryAssignment.assignStatus === AssignStatus.PENDING || item.primaryAssignment.assignStatus ===
                    AssignStatus.BOOKING_UPDATE || item.primaryAssignment.assignStatus ===
                    AssignStatus.REQUEST_EXPIRED ||
                    item.primaryAssignment.assignStatus === AssignStatus.SUPPLIER_REJECTED)
            ) || !item.primaryAssignment));
        } else {
            return (item && item.primaryAssignment &&
                ((item.secondaryAssignment && item.secondaryAssignment.assignStatus &&
                    (item.secondaryAssignment.assignStatus === AssignStatus.PENDING ||
                        item.secondaryAssignment.assignStatus ===
                        AssignStatus.BOOKING_UPDATE || item.secondaryAssignment.assignStatus ===
                        AssignStatus.REQUEST_EXPIRED ||
                        item.secondaryAssignment.assignStatus === AssignStatus.SUPPLIER_REJECTED)
                ) || !item.secondaryAssignment)
            );
        }

    }

    public onSecondaryAssignmentClick(params: any) {
        const bookingIdList = [];
        if (this.operationPanelConfig && this.operationPanelConfig.currentTab === 0) {
            const serviceItem: ServiceItem = params.data;
            if (serviceItem && serviceItem.bookingItemSummaryList) {
                serviceItem.bookingItemSummaryList.forEach(value => bookingIdList.push(value.bookingItemId));
            }
        } else {
            bookingIdList.push(params.data.id);
        }
        if (params && params.data && (!params.data.startTime || !params.data.endTime)) {
            this.onTimeRangeChangeClick(params, bookingIdList, true, true, false);
        } else {
            this.openAllocationFocusView(params.data, bookingIdList, false, true);
        }
    }

    public onTimeRangeChangeClick(params: any, bookingIdList: any[] = [], fromAssignmentClick = false,
                                  isPrimary: boolean = true,
                                  isSecondary: boolean = false
    ) {
        const item: (ServiceItem | BookingItem) = params.data;
        if (!item.startTime && item.trsBookingItem) {
            // moment(this.itemList[0].trsBookingItem.departureDateTime).format('HH:mm');
            item.startTime = new Date(item.trsBookingItem.departureDateTime);
            // moment(this.itemList[0].trsBookingItem.arrivalDateTime).format('HH:mm');
            // item.endTime = new Date(item.trsBookingItem.arrivalDateTime);
        } else {
            item.startTime = new Date(item.serviceDate); // moment(this.itemList[0].serviceDate).format('HH:mm');
        }
        this.dataStore.set(DataKey.popupClose, null, true);
        this.dataStore.set(DataKey.ResourceAllocationTimeRange, null, true);
        const bookingIds = [];
        if (this.isBookingQueue()) {
            const bookingItem: BookingItem = params.data;
            bookingIds.push(bookingItem.id);
        } else {
            const serviceItem: ServiceItem = params.data;
            if (serviceItem.bookingItemSummaryList != null) {
                for (const bookingItemSummary of serviceItem.bookingItemSummaryList) {
                    bookingIds.push(bookingItemSummary.bookingItemId);
                }
            }
        }
        const dataObject = new ModalData(LEVEL.SUCCESS, 'Edit Time Range',

            null,
            null,
            'modal-basic--medium modal-basic--no-footer',
            EditTimeRangeComponent,
            {
                selectedResource: this.selectedResource,
                jobStartTime: item.startTime,
                jobEndTime: item.endTime,
                bookingIds
            }
        );

        this.subscribeForDateRange(params, fromAssignmentClick, bookingIdList, isPrimary, isSecondary);
        this.confirmModal(dataObject);
        if (this.popupSubscription) {
            this.popupSubscription.unsubscribe();
        }
        this.popupSubscription = this.dataStore.get(DataKey.popupClose).subscribe((data) => {
            if (data != null) {
                this.closeModal();
            }
        });
    }

    public onSelectPassengersClick(params: any) {
        const bookingItemSearchCriteria: { bookingItemId: number, bookingReferenceId: string }[] = [];
        if (this.isBookingQueue()) {
            const bookingItem: BookingItem = params.data;
            bookingItemSearchCriteria.push({
                bookingItemId: bookingItem.id,
                bookingReferenceId: bookingItem.bookingReferenceId
            });
        } else {
            const serviceItem: ServiceItem = params.data;
            serviceItem.bookingItemSummaryList.forEach(bookingItem => {
                bookingItemSearchCriteria.push({
                    bookingItemId: bookingItem.bookingItemId,
                    bookingReferenceId: bookingItem.bookingReferenceId
                });
            });
        }

        if (this.popupSubscription) {
            this.popupSubscription.unsubscribe();
        }

        this.dataStore.set(DataKey.popupClose, null);
        this.confirmModal(
            new ModalData(
                LEVEL.SUCCESS,
                'Passengers',
                null,
                null,
                'modal-basic--alt modal-basic--fixed-size',
                PassengerEditorComponent,
                {
                    selectedItem: params.data,
                    bookingPassengerGroup: this.getBookingPassengerGroup(bookingItemSearchCriteria),
                    // passengerGroupList: bookingPassengerGroups,
                    isBookingQueue: this.isBookingQueue()
                }
            )
        );
        this.popupSubscription = this.dataStore.get(DataKey.popupClose).subscribe((data) => {
            if (data != null) {
                this.closeModal();
                this.popupSubscription.unsubscribe();
                if (data.length) {
                    if (this.isBookingQueue()) {
                        const oldBookingIdList = [];
                        const newBookingIdList = [];
                        data.forEach(o => {
                            if (o.parentBookingId !== null && o.parentBookingId !== 0 &&
                                !oldBookingIdList.includes(o.parentBookingId)) {
                                oldBookingIdList.push(o.parentBookingId);
                            }
                            newBookingIdList.push(o.id);
                        });
                        this.productQueueComponent.updateSearchChipsAfterSplit(oldBookingIdList, newBookingIdList);

                        this.bookingQueueComponent.replaceItem(params.data, data);
                    } else {
                        const oldBookingIdList = params.data.bookingItemSummaryList.map(o => o.bookingItemId);
                        const newBookingIdList = [];
                        data.forEach(item => {
                            item.bookingItemSummaryList.forEach(o => {
                                if (!newBookingIdList.includes(o.bookingItemId)) {
                                    newBookingIdList.push(o.bookingItemId);
                                }
                            });
                        });
                        this.productQueueComponent.updateSearchChipsAfterSplit(oldBookingIdList, newBookingIdList);

                        this.serviceQueueComponent.replaceItem(params.data, data);
                    }
                }
            }
        });
    }

    private getBookingPassengerGroup(basicCriteriaDetails: { bookingItemId: number, bookingReferenceId: string }[]): BookingPassengerGroup[] {
        const passengerGroups: BookingPassengerGroup[] = [];
        basicCriteriaDetails.forEach(detail => {
            const tempPassengerGroup: BookingPassengerGroup = new BookingPassengerGroup();
            let totalPassenger = 0;

            const bookingSearchCriteria: BookingItemSearchCriteria = new BookingItemSearchCriteria();
            bookingSearchCriteria.bookingIdList = [detail.bookingItemId];

            this.reservationV2ManagementService.searchPassengers(bookingSearchCriteria)
                .subscribe((data: OPResponseWrapper<BookingPassenger>) => {
                    if (data) {
                        totalPassenger = ResponseUtil.getTotalCount(data);
                        tempPassengerGroup.bookingPassengers = ResponseUtil.getDataArray<BookingPassenger>(data);
                        tempPassengerGroup.id = detail.bookingItemId;
                        tempPassengerGroup.bookingReferenceId = detail.bookingReferenceId;
                    }
                });
            passengerGroups.push(tempPassengerGroup);
        });

        return passengerGroups;
    }

    public onNotesClick(params: any) {
        const bookingItemSearchCriteria: { bookingItemId: number, bookingReferenceId: string }[] = [];

        const primaryJobNotes: AssignmentNote[] = [];
        let bookingNotesOnly = false;
        if (this.isBookingQueue()) {
            const bookingItem: BookingItem = params.data;
            if (bookingItem.primaryAssignment) {
                if (bookingItem.primaryAssignment.assignmentNotes) {
                    primaryJobNotes.push(...bookingItem.primaryAssignment.assignmentNotes);
                }
            } else {
                bookingNotesOnly = true;
            }
            bookingItemSearchCriteria.push({
                bookingItemId: bookingItem.id,
                bookingReferenceId: bookingItem.bookingReferenceId
            });
        } else {
            const serviceItem: ServiceItem = params.data;
            if (serviceItem.primaryAssignment) {
                if (serviceItem.primaryAssignment.assignmentNotes) {
                    primaryJobNotes.push(...serviceItem.primaryAssignment.assignmentNotes);
                }
            } else {
                bookingNotesOnly = true;
            }
            serviceItem.bookingItemSummaryList.forEach(summary => {
                bookingItemSearchCriteria.push({
                    bookingItemId: summary.bookingItemId,
                    bookingReferenceId: summary.bookingReferenceId
                });
            });

        }
        const clonedNotesArray = JSON.parse(JSON.stringify(primaryJobNotes));

        if (this.popupSubscription) {
            this.popupSubscription.unsubscribe();
        }

        this.dataStore.set(DataKey.popupClose, null);
        const dataObject = new ModalData(
            LEVEL.SUCCESS,
            'Notes',
            null,
            null,
            'modal-basic--alt modal-basic--fixed-size',
            NotesEditorComponent,
            {
                assignmentNotes: clonedNotesArray,
                bookingNoteGroups: this.getBookingNoteGroups(bookingItemSearchCriteria),
                bookingNotesOnly
            }
        );
        this.confirmModal(dataObject);
        this.popupSubscription = this.dataStore.get(DataKey.popupClose).subscribe((data) => {
            if (data != null) {
                this.closeModal();
                if (data) {
                    this.setNotesToItems(clonedNotesArray, params.data);
                }
            }
        });
    }

    private getBookingNoteGroups(basicCriteriaDetails: { bookingItemId: number, bookingReferenceId: string }[]): BookingNoteGroup[] {
        const noteGroups: BookingNoteGroup[] = [];
        basicCriteriaDetails.forEach(detail => {
            const tempNoteGroup: BookingNoteGroup = new BookingNoteGroup();

            const bookingSearchCriteria: BookingItemSearchCriteria = new BookingItemSearchCriteria();
            bookingSearchCriteria.bookingIdList = [detail.bookingItemId];

            this.reservationV2ManagementService.searchBookingNotes(bookingSearchCriteria)
                .subscribe((results: OPResponseWrapper<BookingNote>) => {
                    if (results) {
                        tempNoteGroup.bookingNotes = ResponseUtil.getDataArray<BookingNote>(results);
                        tempNoteGroup.bookingReferenceId = detail.bookingReferenceId;
                    }
                });
            noteGroups.push(tempNoteGroup);
        });

        return noteGroups;
    }

    public onSupplementsClick(params: any) {
        const bookingItemSearchCriteria: { bookingItemId: number, bookingReferenceId: string }[] = [];
        const bookingNotesOnly = false;
        if (this.isBookingQueue()) {
            const bookingItem: BookingItem = params.data;
            bookingItemSearchCriteria.push({
                bookingItemId: bookingItem.id,
                bookingReferenceId: bookingItem.bookingReferenceId
            });
        } else {
            const serviceItem: ServiceItem = params.data;
            serviceItem.bookingItemSummaryList.forEach(summary => {
                bookingItemSearchCriteria.push({
                    bookingItemId: summary.bookingItemId,
                    bookingReferenceId: summary.bookingReferenceId
                });
            });
        }

        if (this.popupSubscription) {
            this.popupSubscription.unsubscribe();
        }

        this.dataStore.set(DataKey.popupClose, null);
        const dataObject = new ModalData(
            LEVEL.SUCCESS,
            'Supplements',
            null,
            null,
            'modal-basic--alt modal-basic--fixed-size',
            SupplementsEditorComponent,
            {
                selectedItem: params.data,
                supplementGroupList: this.getBookingSupplementGroups(bookingItemSearchCriteria),
                isBookingQueue: bookingNotesOnly
            }
        );
        this.confirmModal(dataObject);
        this.popupSubscription = this.dataStore.get(DataKey.popupClose).subscribe((data) => {
            if (data != null) {
                this.closeModal();
            }
        });
    }

    private getBookingSupplementGroups(basicCriteriaDetails: { bookingItemId: number, bookingReferenceId: string }[])
        : BookingSupplementGroup[] {
        const supplementGroups: BookingSupplementGroup[] = [];
        basicCriteriaDetails.forEach(detail => {
            const tempSupplementGroup: BookingSupplementGroup = new BookingSupplementGroup();

            const bookingSearchCriteria: BookingItemSearchCriteria = new BookingItemSearchCriteria();
            bookingSearchCriteria.bookingIdList = [detail.bookingItemId];

            this.reservationV2ManagementService.searchSupplementNotes(bookingSearchCriteria)
                .subscribe((data: OPResponseWrapper<BookingSupplement>) => {
                    if (data) {
                        tempSupplementGroup.supplementNotes = ResponseUtil.getDataArray<BookingSupplement>(data);
                        tempSupplementGroup.bookingReferenceId = detail.bookingReferenceId;
                    }
                });
            supplementGroups.push(tempSupplementGroup);
        });

        return supplementGroups;
    }

    setNotesToItems(notes: AssignmentNote[], item: BookingItem | ServiceItem) {
        if (item.primaryAssignment) {
            item.primaryAssignment.assignmentNotes = notes;
        }
        if (item.secondaryAssignment) {
            item.secondaryAssignment.assignmentNotes = notes;
        }
    }

    private confirmModal(data: any): void {
        if (this.modelConfirmSubscription) {
            this.modelConfirmSubscription.unsubscribe();
        }
        this.modelConfirmSubscription = this.modalService.confirm(data).subscribe((res) => {
        });
    }

    closeModal() {
        if (this.modelCloseSubscription) {
            this.modelCloseSubscription.unsubscribe();
        }
        if (this.modalService.close()) {
            this.modelCloseSubscription = this.modalService.close().subscribe((res) => {
            });
        }
    }

    public onShowCostBreakDownClick(params: any) {

    }

    public isAssignmentsIncomplete(row: ServiceItem | BookingItem) {
        // return row.primaryAssignment && (!row.primaryAssignment.startTime || !row.primaryAssignment.endTime);
        return false;
    }

    public isConfirm(row: ServiceItem | BookingItem) {
        return row && row.bookingSourceStatus === 'CONFIRM';
    }

    public isOnRequest(row: ServiceItem | BookingItem) {
        return row && row.bookingSourceStatus === 'ON_REQUEST';
    }

    public isManifestStatusNOT_SENT(row: ServiceItem | BookingItem) {
        return this.showManifestStatusAllocQueue && row && row.manifestStatus === 'NOT_SENT';
    }

    public isManifestStatusSENT(row: ServiceItem | BookingItem) {
        return this.showManifestStatusAllocQueue && row && row.manifestStatus === 'SENT_A';
    }

    public isManifestStatusUPDATED(row: ServiceItem | BookingItem) {
        return this.showManifestStatusAllocQueue && row && row.manifestStatus === 'UPDATED';
    }

    public isManifestStatusNEW(row: ServiceItem | BookingItem) {
        return this.showManifestStatusAllocQueue && row && (row.manifestStatus === 'NEW'
            || row.manifestStatus === 'SENT_AM' || row.manifestStatus === 'SENT_M' || row.manifestStatus ===
            'UPDATED_M');
    }

    public isManifestStatusNONE(row: ServiceItem | BookingItem) {
        return this.showManifestStatusAllocQueue && row && row.manifestStatus === 'NONE';
    }

    public getAssignmentsIncompleteMsg(row: ServiceItem | BookingItem) {
        if (row && row.primaryAssignment) {
            if (!row.primaryAssignment.startTime) {
                return 'Start time is not added';
            } else if (!row.primaryAssignment.endTime) {
                return 'End time is not added';
            }
        }
        return 'Assignment is incomplete';
    }

    public getStatusToolTip(row: ServiceItem | BookingItem) {
        if (row && row.bookingSourceStatus === 'CONFIRM') {
            return 'Booking Status: Confirm';
        } else if (row.bookingSourceStatus === 'ON_REQUEST') {
            return 'Booking Status: On Request';
        }
    }

    public getManifestStatusToolTip(row: ServiceItem | BookingItem) {
        if (row.manifestStatus === 'SENT_A') {
            return 'Manifest Status: SENT';
        } else if (row.manifestStatus === 'SENT_AM' || row.manifestStatus === 'SENT_M' || row.manifestStatus ===
            'UPDATED_M') {
            return 'Manifest Status: NEW';
        } else if (row.manifestStatus === 'UPDATED') {
            return 'Manifest Status: UPDATED';
        } else if (row.manifestStatus === 'NOT_SENT') {
            return 'Manifest Status: NOT_SENT';
        } else {
            return 'Manifest Status: ' + row.manifestStatus;
        }
    }

    private openAllocationFocusView(
        data: ServiceItem | BookingItem, bookingIdList: any[], isPrimary: boolean, isSecondary: boolean) {
        this.dataStore.set(DataKey.selectedResourceForAllocation, null, true);
        this.dataStore.set(DataKey.selectedSupplierForAllocation, null, true);
        this.itemList = [];
        this.itemList.push(data);
        const serviceDate = data.serviceDate.toString().split('T')[0];
        this.operationPanelConfig.preventSwitching = !this.operationPanelConfig.preventSwitching;
        setTimeout(() => {
            const fabActions = [];

            const navItemsArray = this.configLoader.configurations.get(TCO.CONF.CONF_NAVIGATION);
            this.configLoader.configurations.get(TCO.CONF.CONF_FOCUS_VIEW).ALLOCATION_FOCUS_VIEW.fabAction
                .forEach(val => {
                    for (const key in navItemsArray) {
                        if (val === navItemsArray[key].id) {
                            fabActions.push(navItemsArray[key]);
                        }
                    }
                });
            const costingType = data.costingType && data.costingType.name ? data.costingType.name : '--';
            const noOfUnits = data.noOfUnits ? data.noOfUnits : '--';
            const dataObject = new ModalData(
                LEVEL.SUCCESS,
                'Assign Supplier/Resource',
                null,
                null,
                '',
                AllocationFocusViewComponent,
                {
                    allocationTimeRange: this.allocationTimeRange,
                    startTime: data.startTime,
                    endTime: data.endTime,
                    itemList: this.itemList,
                    isPrimary,
                    isSecondary,
                    bookingIdList
                },
                {label: 'close'},
                '',
                '',
                fabActions,
                '',
                'Pax: ' + (data.adultCount + data.childrenCount + data.teenCount + data.infantCount) + ' | ' +
                moment(new Date(data.startTime)).format('HH:mm') + ' - ' +
                moment(new Date(data.endTime)).format('HH:mm') +
                ' | Costing Unit:' + costingType + ' | No of units:' + noOfUnits
            );
            if (this.focusViewCloseSubject) {
            }
            this.focusViewCloseSubject = this.dataStore.get(DataKey.resourceAllocationFocusViewClose)
                                             .subscribe(dataS => {
                                                 if (dataS != null) {
                                                     this.focusViewCloseSubject.unsubscribe();
                                                     this.closeFocusView();
                                                 }
                                             });

            if (this.focusViewConfirmSubject) {
                this.focusViewConfirmSubject.unsubscribe();
            }
            this.focusViewConfirmSubject = this.focusViewService.confirm(dataObject).subscribe(res => {
                if (res) {
                    const e = document.getElementsByClassName('header-content__secondary-heading');
                    console.log(e);
                }
            });
        }, 0);
    }

    closeFocusView() {
        if (this.focusViewService.close()) {
            this.focusViewCloseSubject = this.focusViewService.close().subscribe(
                (res) => {
                }
            );
        }
    }

    /*
     Grid Functions
     */

    isEditableItem = (item: ServiceItemGridParam | BookingItemGridParam) => {
        return true;
    };

    isEditableResource = (item: ServiceItemGridParam | BookingItemGridParam) => {
        if (item.data && item.data.noOfUnits > 1 && item.data.costingType &&
            (item.data.costingType.code === CostingType.UNIT || item.data.costingType.code === CostingType.PERSON)) {
            return false;
        }
        return true;
    };

    /*
     Ends Grid Functions
     */

    hasNoAnyAssignment = (item: ServiceItemGridParam | BookingItemGridParam) => {
        return item.data && !item.data.primaryAssignment && !item.data.secondaryAssignment;
    };

    onTotalCostClick = (params: any) => {
        if (params && params.data && params.data.itemCostPortion) {
            this.openTotalCostEditor(params.data);
        }
    };

    setSupplierToItems(
        items: (ServiceItem | BookingItem)[], assignment: AssignmentRawData, supplier: SupplierAllocationSummary,
        primary: boolean,
        secondary: boolean, fromPopup: boolean = false
    ) {
        assignment.temporaryAssignmentId = this.rawAssignmentTempId++;
        assignment.supplierId = supplier.supplierId;
        assignment.supplierCode = supplier.supplierCode;
        assignment.supplierName = supplier.supplierName;
        assignment.resourceType = supplier.serviceType;
        assignment.assignStatus = AssignStatus.PENDING;
        let locGroups;
        if (items && items[0]) {
            assignment.startTime = items[0].startTime;
            assignment.endTime = items[0].endTime;
            if (items[0].trsBookingItem) {
                assignment.route = items[0].trsBookingItem.route;
                assignment.startLocationGroup = items[0].trsBookingItem.pickupPointGroup;
                assignment.endLocationGroup = items[0].trsBookingItem.dropOffPointGroup;

                if (assignment.route) {
                    locGroups = assignment.route.split('-');
                }
                if (locGroups.length > 1 && !assignment.startLocationGroup) {
                    assignment.startLocationGroup = locGroups[0];
                }
                if (locGroups.length > 1 && !assignment.endLocationGroup) {
                    assignment.endLocationGroup = locGroups[1];
                }
            }

            assignment.calculatedCost = supplier.calculatedCost;
            if (supplier.trsResourceCostSummaries && supplier.trsResourceCostSummaries.length) {
                assignment.trsResourceCost = supplier.trsResourceCostSummaries.find(
                    cost => cost.selected
                );
                if (!assignment.trsResourceCost) {
                    assignment.trsResourceCost = supplier.trsResourceCostSummaries[0];
                }
            } else {
                assignment.genResourceCost = supplier.genResourceCostSummaries.find(
                    cost => cost.selected
                );
                if (!assignment.genResourceCost) {
                    assignment.genResourceCost = supplier.genResourceCostSummaries[0];
                }
                assignment.costingType = assignment.genResourceCost.costingType;
            }
            if (primary) {
                assignment.supplierObj = supplier;
            } else if (secondary) {
                assignment.supplierObj = supplier;
            }
            assignment.noOfUnits = items.length === 1 && items[0].noOfUnits ?
                items[0].noOfUnits :
                assignment.calculatedCost.noOfUnits;
            assignment.costingType = items.length === 1 && items[0].costingType ?
                items[0].costingType :
                assignment.calculatedCost.costingType;
            this.setAssignmentToItems(items, assignment, primary, secondary, fromPopup);
        }

    }

    public removeResourceFromSecondaryItems(
        items: (ServiceItem | BookingItem)[], primary: boolean, secondary: boolean) {
        if (secondary) {
            for (const item of items) {
                if (item.secondaryAssignment) {
                    item.secondaryAssignment.resourceId = undefined;
                    item.secondaryAssignment.resourceName = undefined;
                }
            }
        }
    }

    private setAssignmentToItems(
        items: (ServiceItem | BookingItem)[], assignment: AssignmentRawData, primary: boolean, secondary: boolean,
        fromPopup: boolean = false
    ) {

        this.markChanges();

        const totalPaxMappings: BookingPaxMapping[] = [];
        const removedItems = [];
        const plCodes: string[] = [];
        const plNames: string[] = [];
        const routes: string[] = [];
        const serviceDates: Date[] = [];
        for (const item of items) {
            if (primary) {
                if (item.primaryAssignment) {
                    removedItems.push(this.removeItemPassengersFromAssignment(item, item.primaryAssignment));
                }
                item.primaryAssignment = assignment;
                if (item.secondaryAssignment) {
                    item.secondaryAssignment.parentAssignmentId = item.primaryAssignment.assignmentId;
                    item.secondaryAssignment.temporaryParentAssignmentId = item.primaryAssignment.temporaryAssignmentId;
                }
            } else if (secondary) {
                if (item.secondaryAssignment) {
                    removedItems.push(this.removeItemPassengersFromAssignment(item, item.secondaryAssignment));
                }
                item.secondaryAssignment = assignment;

                // if primary assignment is available set it as parent
                if (item.primaryAssignment) {
                    item.secondaryAssignment.parentAssignmentId = item.primaryAssignment.assignmentId;
                    item.secondaryAssignment.temporaryParentAssignmentId = item.primaryAssignment.temporaryAssignmentId;
                }
            }
            plCodes.push(item.plCode);
            plNames.push(item.plName);
            serviceDates.push(item.serviceDate);
            if (item.trsBookingItem) {
                routes.push(item.trsBookingItem.route);
            }
            if (item && item.bookingPassengers) {
                const bookingPaxMappingsOfItem: BookingPaxMapping[] = item.bookingPassengers.map(p => {
                    const bookingPaxMapping = new BookingPaxMapping();
                    bookingPaxMapping.passengerId = p.id;
                    bookingPaxMapping.bookingId = p.bookingId;
                    bookingPaxMapping.type = p.type;
                    return bookingPaxMapping;
                });
                totalPaxMappings.push(...bookingPaxMappingsOfItem);
            }
        }
        for (const removedItem of removedItems) {
            this.fixAssignmentCostAndCountsForNewPassengerCount(removedItem, primary, secondary);
        }
        if (primary) {
            assignment.serviceLevel = ServiceLevel.PRIMARY;
        } else if (secondary) {
            assignment.serviceLevel = ServiceLevel.SECONDARY;
        }
        assignment.sharedAssign = !(items.length === 1 && items[0].pvtShared === ServiceSharing.PVT);
        if (plCodes.length && plCodes.every((v, i, arr) => v === arr[0])) {
            assignment.plCode = plCodes[0];
            if (plNames.length) {
                assignment.plName = plNames[0];
            }
        }
        let minDate = null;
        if (serviceDates.length) {
            minDate = new Date(Math.min.apply(null, serviceDates));
        }
        if (!assignment.jobDate) {
            assignment.jobDate = minDate;
        }
        if (!assignment.startTime) {
            assignment.startTime = minDate;
        }
        if (!assignment.startLocationGroup && !assignment.endLocationGroup && routes.length) {
            if (routes.every((v, i, arr) => v === arr[0])) {
                const locGroups = routes[0].split('-');
                if (locGroups.length > 1) {
                    assignment.startLocationGroup = locGroups[0];
                    assignment.endLocationGroup = locGroups[1];
                }
            }
        }
        if (assignment.genResourceCost) {
            assignment.resourceType = assignment.genResourceCost.resourceType;
            assignment.profileType = assignment.genResourceCost.profileType;
        } else if (assignment.trsResourceCost) {
            assignment.resourceType = 'vehicle';
        }

        assignment.bookingPaxMappings = totalPaxMappings;
        this.fixAssignmentCostAndCountsForNewPassengerCount(assignment, primary, secondary);
        if (fromPopup) {
            this.refreshGridView();
        }
    }

    public removeAssignmentFromItems(items: (ServiceItem | BookingItem)[], primary: boolean, secondary: boolean) {

        this.markChanges();

        const removedItems = [];
        for (const item of items) {
            if (primary) {
                if (item.primaryAssignment) {
                    removedItems.push(this.removeItemPassengersFromAssignment(item, item.primaryAssignment));
                    this.addAssignmentToItemsRemovedList(item, item.primaryAssignment, true, false);
                    item.primaryAssignment = null;
                }
                // if primary item is removing secondary item also removed. (child parent relationship)
                if (item.secondaryAssignment) {
                    removedItems.push(this.removeItemPassengersFromAssignment(item, item.secondaryAssignment));
                    this.addAssignmentToItemsRemovedList(item, item.secondaryAssignment, false, true);
                    item.secondaryAssignment = null;
                }
            }
            if (secondary) {
                if (item.secondaryAssignment) {
                    removedItems.push(this.removeItemPassengersFromAssignment(item, item.secondaryAssignment));
                    if (!item.removedSecondaryAssignments) {
                        item.removedSecondaryAssignments = [];
                    }
                    item.removedSecondaryAssignments.push(item.secondaryAssignment);
                    this.addAssignmentToItemsRemovedList(item, item.secondaryAssignment, false, true);
                    item.secondaryAssignment = null;
                }
            }
            this.costCalculationUtilsService.setupItemCostPortion(item);
        }
        for (const removedItem of removedItems) {
            this.fixAssignmentCostAndCountsForNewPassengerCount(removedItem, primary, secondary);
        }
    }

    addAssignmentToItemsRemovedList(
        item: BookingItem | ServiceItem, assignment: AssignmentRawData, primary: boolean, secondary: boolean) {
        if (primary) {
            if (!item.removedPrimaryAssignments) {
                item.removedPrimaryAssignments = [];
            }
            item.removedPrimaryAssignments.push(assignment);
        } else if (secondary) {
            if (!item.removedSecondaryAssignments) {
                item.removedSecondaryAssignments = [];
            }
            item.removedSecondaryAssignments.push(assignment);
        }
    }

    public removeItemPassengersFromAssignment(item: ServiceItem | BookingItem, assignmentRawData: AssignmentRawData) {
        if (assignmentRawData && assignmentRawData.bookingPaxMappings) {
            if (item && item.bookingPassengers) {
                for (const bookingPassenger of item.bookingPassengers) {
                    const index = assignmentRawData.bookingPaxMappings.findIndex(p => p.passengerId !==
                        bookingPassenger.id);
                    assignmentRawData.bookingPaxMappings.splice(index, 1);
                }
            }
        }
        return assignmentRawData;
    }

    public fixAssignmentCostAndCountsForNewPassengerCount(
        assignment: AssignmentRawData, primary: boolean, secondary: boolean) {
        let adultCount = 0;
        let teenCount = 0;
        let childCount = 0;
        let infantCount = 0;

        for (const bpm of assignment.bookingPaxMappings) {
            switch (bpm.type) {
                case PassengerType.A:
                    adultCount++;
                    break;
                case PassengerType.T:
                    teenCount++;
                    break;
                case PassengerType.C:
                    childCount++;
                    break;
                case PassengerType.I:
                    infantCount++;
                    break;
            }
        }
        assignment.adultCount = adultCount;
        assignment.teenCount = teenCount;
        assignment.childCount = childCount;
        assignment.infantCount = infantCount;
        this.costCalculationUtilsService.fixAssignmentCost(assignment);
        this.updateJobRelatedBookingServiceItemCosts(assignment);
    }

    public updateJobRelatedBookingServiceItemCosts(assignment: AssignmentRawData) {
        if (this.isBookingQueue()) {
            const bookingItems = this.bookingQueueComponent.bookingItemSearchResults.filter(
                i => i.primaryAssignment === assignment || i.secondaryAssignment === assignment
            );
            bookingItems.forEach(i => this.costCalculationUtilsService.setupItemCostPortion(i));
        } else {
            const serviceItems = this.serviceQueueComponent.serviceItemSearchResults.filter(
                i => i.primaryAssignment === assignment || i.secondaryAssignment === assignment
            );
            serviceItems.forEach(i => this.costCalculationUtilsService.setupItemCostPortion(i));
        }
    }

    private enableSaveButton() {
        // this.userJourneyManager.canProceed.next(true);
        this.saveButtonEnable = true;
        this.revertButtonEnable = true;
        this.disableConfirmButton();
    }

    public enableConfirmButton() {
        const selectedItems: (BookingItem | ServiceItem)[] = this.getSelectedItems();
        if (selectedItems && selectedItems.length > 0) {
            for (const selectedItem of selectedItems) {
                if ((!selectedItem.primaryAssignment && !selectedItem.secondaryAssignment) ||
                    (selectedItem.primaryAssignment && !selectedItem.primaryAssignment.assignmentId) ||
                    (selectedItem.secondaryAssignment && !selectedItem.secondaryAssignment.assignmentId)) {
                    this.confirmButtonEnable = false;
                    break;
                } else {
                    this.confirmButtonEnable = true;
                }
            }
        } else {
            this.confirmButtonEnable = false;
        }

        return this.confirmButtonEnable;

    }

    private disableSaveButton() {
        // this.userJourneyManager.canProceed.next(false);
        this.saveButtonEnable = false;
    }

    private disableConfirmButton() {
        this.confirmButtonEnable = false;
    }

    private reloadGridData() {
        this.revertChanges();
        if (this.isBookingQueue()) {
            // this.bookingQueueComponent.loadBookingItems();
        } else {
            this.serviceQueueComponent.loadData();
        }
    }

    private refreshGridView() {
        if (this.isBookingQueue()) {
            this.bookingQueueComponent.refreshGridView();
        } else {
            this.serviceQueueComponent.refreshGridView();
        }
    }

    public onSaveAssignmentsClick() {
        console.log('save clicked');

        const selectedItems = this.getSelectedItems();
        if (!selectedItems || !selectedItems.length) {
            const invalidAttemptWarning = new DialogModel(
                true,
                LEVEL.ERROR,
                'Invalid Attempt',
                'Please select the items you wish to save',
                true,
                2000
            );
            const subscriptionInvalid = this.dialogService.confirm(invalidAttemptWarning).subscribe((res) => {
                subscriptionInvalid.unsubscribe();
                return;
            });
            return;
        }
        const assignments: AssignmentRawData[] = this.getSelectedItemsForSave();

        let constraintsSatisfy = true;
        for (const assignment of assignments) {
            if (!assignment.removingAssignment && !(assignment.startTime && assignment.endTime)) {
                constraintsSatisfy = false;
            }
        }

        if (constraintsSatisfy) {
            const confirmDialog = new DialogModel(
                true,
                LEVEL.INFO,
                'Save Assignments',
                `Do you wish to save changes ?`,
                true,
                2000,
                null,
                'No',
                'Yes',
                true
            );
            confirmDialog.disableClose = true;
            const subscription = this.dialogService
                                     .confirm(confirmDialog)
                                     .subscribe((res) => {
                                         subscription.unsubscribe();
                                         if (res === true) {
                                             this.spinnerService.show();
                                             this.reservationV2ManagementService.saveAssignmentRawData(assignments)
                                                 .subscribe(
                                                     result => {
                                                         this.spinnerService.hide();

                                                         // refresh service items/ booking items
                                                         this.reloadGridData();
                                                         this.enableConfirmButton();
                                                         this.common.showSnackBar(
                                                             'Successfully saved the changes',
                                                             3000,
                                                             TcErrorType.TYPE.INFO
                                                         );
                                                     },
                                                     error => {
                                                         console.log(error);
                                                         this.spinnerService.hide();
                                                         this.common.showSnackBar(
                                                             'Failed to save the changes',
                                                             3000,
                                                             TcErrorType.TYPE.ERROR
                                                         );
                                                     }
                                                 );

                                         }
                                     });
        } else {
            const invalidAttemptWarning = new DialogModel(
                true,
                LEVEL.ERROR,
                'Invalid Attempt',
                'Please set start and end times of items before saving',
                true,
                2000
            );
            const subscriptionInvalid = this.dialogService.confirm(invalidAttemptWarning).subscribe((res) => {
                subscriptionInvalid.unsubscribe();
                return;
            });
        }
    }

    public onRevertAssignmentsClick() {
        console.log('revert clicked');
        const confirmDialog = new DialogModel(
            true,
            LEVEL.WARNING,
            'Revert Changes',
            `Do you wish to revert changes ?`,
            true,
            2000,
            null,
            'No',
            'Yes',
            true
        );
        confirmDialog.disableClose = true;
        const subscription = this.dialogService
                                 .confirm(confirmDialog)
                                 .subscribe((res) => {
                                     subscription.unsubscribe();
                                     if (res === true) {
                                         this.reloadGridData();
                                     }
                                 });
    }

    public onConfirmClick() {
        console.log('confirm clicked');
        const confirmDialog = new DialogModel(
            true,
            LEVEL.INFO,
            'Confirm Assignments',
            `
                        Confirming this action will trigger the following.<br><br>

                        1. Sending manifests to the allocated Suppliers/ Resources.<br>
                        2. Creating accounting ledger entries.<br><br>

                        Once confirmed this action cannot be undone.<br>
                        Do you wish to proceed?`,
            true,
            2000,
            null,
            'No',
            'Yes',
            true
        );
        confirmDialog.disableClose = true;
        const subscription = this.dialogService
                                 .confirm(confirmDialog)
                                 .subscribe((res) => {
                                     subscription.unsubscribe();
                                     if (res === true) {
                                         const assignmentIds = this.getSelectedItemsIdsForConfirm();
                                         this.spinnerService.show();
                                         this.reservationV2ManagementService.changeAssignmentsStatus(
                                             assignmentIds,
                                             AssignStatus.DMC_CONFIRM
                                         ).subscribe(
                                             result => {
                                                 this.spinnerService.hide();

                                                 // refresh service items/ booking items
                                                 this.reloadGridData();
                                                 this.common.showSnackBar(
                                                     'Successfully confirmed the assigned resources',
                                                     3000,
                                                     TcErrorType.TYPE.INFO
                                                 );
                                                 this.disableConfirmButton();
                                             },
                                             error => {
                                                 console.log(error);
                                                 this.spinnerService.hide();
                                                 this.common.showSnackBar(
                                                     'Failed to confirm the assigned resources',
                                                     3000,
                                                     TcErrorType.TYPE.ERROR
                                                 );
                                             }
                                         );

                                     }
                                 });
    }

    public onSupplierConfirmClick() {
        console.log('supplier confirm clicked');
        const confirmDialog = new DialogModel(
            true,
            LEVEL.INFO,
            'Supplier Confirm Assignments',
            `Confirming this action will mark the jobs as confirmed by assigned supplier.<br><br>
                        Do you wish to proceed?`,
            true,
            2000,
            null,
            'No',
            'Yes',
            true
        );
        confirmDialog.disableClose = true;
        const subscription = this.dialogService
                                 .confirm(confirmDialog)
                                 .subscribe((res) => {
                                     subscription.unsubscribe();
                                     if (res === true) {
                                         const assignmentIds = this.getSelectedItemIdsForSupplierConfirm();
                                         this.spinnerService.show();
                                         this.reservationV2ManagementService.changeAssignmentsStatus(
                                             assignmentIds,
                                             AssignStatus.SUPPLIER_CONFIRMED
                                         ).subscribe(
                                             result => {
                                                 this.spinnerService.hide();

                                                 // refresh service items/ booking items
                                                 this.reloadGridData();
                                                 this.common.showSnackBar(
                                                     'Successfully mark as supplier confirmed',
                                                     3000,
                                                     TcErrorType.TYPE.INFO
                                                 );
                                             },
                                             error => {
                                                 console.log(error);
                                                 this.spinnerService.hide();
                                                 this.common.showSnackBar(
                                                     'Failed to mark as supplier confirmed',
                                                     3000,
                                                     TcErrorType.TYPE.ERROR
                                                 );
                                             }
                                         );

                                     }
                                 });
    }

    public onSupplierRejectClick() {
        console.log('supplier reject clicked');
        const confirmDialog = new DialogModel(
            true,
            LEVEL.INFO,
            'Supplier Reject Assignments',
            `Confirming this action will mark the jobs as rejected by assigned supplier.<br><br>
                        Do you wish to proceed?`,
            true,
            2000,
            null,
            'No',
            'Yes',
            true
        );
        confirmDialog.disableClose = true;
        const subscription = this.dialogService
                                 .confirm(confirmDialog)
                                 .subscribe((res) => {
                                     subscription.unsubscribe();
                                     if (res === true) {
                                         const assignmentIds = this.getSelectedItemIdsForSupplierReject();
                                         this.spinnerService.show();
                                         this.reservationV2ManagementService.changeAssignmentsStatus(
                                             assignmentIds,
                                             AssignStatus.SUPPLIER_REJECTED
                                         ).subscribe(
                                             result => {
                                                 this.spinnerService.hide();

                                                 // refresh service items/ booking items
                                                 this.reloadGridData();
                                                 this.common.showSnackBar(
                                                     'Successfully marked as supplier rejected',
                                                     3000,
                                                     TcErrorType.TYPE.INFO
                                                 );
                                             },
                                             error => {
                                                 console.log(error);
                                                 this.spinnerService.hide();
                                                 this.common.showSnackBar(
                                                     'Failed to mark as supplier rejected',
                                                     3000,
                                                     TcErrorType.TYPE.ERROR
                                                 );
                                             }
                                         );

                                     }
                                 });

    }

    public onSendManifestsClick() {
        if (this.backgroundTaskService.isTaskRunning()) {
            this.common.showSnackBar(
                'Already a background task is being in progress. Please wait for the completion or stop notifying the task status.',
                5000,
                TcErrorType.TYPE.ERROR
            );
            return;
        }

        // this.spinnerService.show();
        const confirmSendManifests = new DialogModel(
            true,
            LEVEL.WARNING,
            'Send Manifests',
            ` Do you want to send manifests for selected items ?`,
            true,
            2000,
            null,
            'Cancel',
            'Send',
            true
        );
        confirmSendManifests.disableClose = true;
        if (this.dialogSubscription) {
            this.dialogSubscription.unsubscribe();
        }
        let noOfSuppliers = 0;
        const selectedItems: (ServiceItem | BookingItem)[] = this.getSelectedItems();
        if (selectedItems && selectedItems.length > 0) {
            for (const selectedItem of selectedItems) {
                // tslint:disable-next-line:triple-equals
                if (selectedItem.primaryAssignment && selectedItem.primaryAssignment.assignmentId &&
                    selectedItem.primaryAssignment.supplierId && selectedItem.primaryAssignment.supplierId !== 0) {
                    ++noOfSuppliers;
                    break;
                } else if (selectedItem.secondaryAssignment && selectedItem.secondaryAssignment.assignmentId &&
                    selectedItem.secondaryAssignment.supplierId && selectedItem.secondaryAssignment.supplierId !== 0) {
                    ++noOfSuppliers;
                    break;
                }
            }
        }
        if (noOfSuppliers > 0 || selectedItems.length === 0) {
            this.dialogSubscription = this.dialogService
                                          .confirm(confirmSendManifests)
                                          .subscribe((res) => {
                                              if (res === true) {
                                                  const documentCreationCriteria = new DocumentCreationCriteria();
                                                  documentCreationCriteria.documentFormat = DocumentFormat.pdf;
                                                  documentCreationCriteria.documentType = DocumentType.supplierManifest;
                                                  documentCreationCriteria.download = true;
                                                  documentCreationCriteria.persist = true;

                                                  const documentDataCriteria = new DocumentDataCriteria();
                                                  documentDataCriteria.bookingIdList = this.getSelectedBookingItemIdList();
                                                  documentDataCriteria.queueVersion = 'V2';
                                                  if (Array.isArray(this.manifestGenerationStatus)) {
                                                      documentDataCriteria.assignmentStatuses = this.manifestGenerationStatus;
                                                  }

                                                  documentDataCriteria.searchStartDate = this.productQueueSearchCriteria.startDate;
                                                  documentDataCriteria.searchEndDate = this.productQueueSearchCriteria.endDate;

                                                  documentDataCriteria.queueVersion = 'V2';
                                                  const userFirstName = this.dataStore.get(DataKey.userDetail)
                                                                            .getValue()
                                                      .userSummary.firstName;
                                                  const userLastName = this.dataStore.get(DataKey.userDetail).getValue()
                                                      .userSummary.lastName;
                                                  documentDataCriteria.userId = this.dataStore.get(DataKey.userDetail)
                                                                                    .getValue().id;
                                                  documentDataCriteria.userName = userFirstName + ' ' + userLastName;
                                                  DateTimeProcessor.processDocumentDataSearchCriteriaDateTimes(
                                                      documentDataCriteria);
                                                  this.documentService.createAndDownloadDocument(
                                                      documentCreationCriteria,
                                                      documentDataCriteria
                                                  );
                                              }
                                          });

        } else {
            this.common.showSnackBar(
                'No Supplier linked',
                3000,
                TcErrorType.TYPE.WARN
            );
        }

    }

    chipSelected() {
        return this.selectedBookingIdList.length === 0;
    }

    // pop-up creation
    onGenerateDocumentsClick()
    {
        if (this.backgroundTaskService.isTaskRunning()) {
            this.common.showSnackBar(
                'Already a background task is being in progress. Please wait for the completion or stop notifying the task status.',
                5000,
                TcErrorType.TYPE.ERROR
            );
            return;
        }
        let noOfSuppliers = 0;
        const selectedItems: (ServiceItem | BookingItem)[] = this.getSelectedItems();
        if (selectedItems && selectedItems.length > 0) {
            for (const selectedItem of selectedItems) {
                // tslint:disable-next-line:triple-equals
                if (selectedItem.primaryAssignment && selectedItem.primaryAssignment.assignmentId && selectedItem.primaryAssignment.supplierId && selectedItem.primaryAssignment.supplierId !== 0) {
                    ++noOfSuppliers;
                    break;
                }else if (selectedItem.secondaryAssignment && selectedItem.secondaryAssignment.assignmentId && selectedItem.secondaryAssignment.supplierId && selectedItem.secondaryAssignment.supplierId !== 0){
                    ++noOfSuppliers;
                    break;
                }
            }
        }

        if (noOfSuppliers > 0 || selectedItems.length === 0) {
            this.dataStore.set(DataKey.popupClose, null);
            let documentTypes = [];
            if (Array.isArray(this.availableDocumentTypes)) {
                documentTypes = this.availableDocumentTypes;
            }
            const documentCreationModel = new DocumentCreationModel();
            documentCreationModel.autoName = true;
            documentCreationModel.download = true;
            documentCreationModel.persist = true;
            documentCreationModel.notifySupplier = false;
            documentCreationModel.queueVersion = 'V2';
            documentCreationModel.selectedBookingItemIds = this.getSelectedBookingItemIdList();
            const dataObject = new ModalData(
                LEVEL.SUCCESS,
                'Generate Documents',
                null,
                null,
                'modal-basic--alt modal-basic--fixed-size',
                DocumentCreationModalComponent,
                {
                    documentTypes,
                    documentCreationModel
                }
            );

            dataObject.disableClose = false;
            this.confirmModal(dataObject);
            const modalSubscription = this.dataStore.get(DataKey.popupClose).subscribe((data) => {
                if (data != null) {
                    modalSubscription.unsubscribe();
                    this.closeModal();
                    if (data) {
                        modalSubscription.unsubscribe();
                        this.closeModal();
                        if (documentCreationModel.documentType) {
                            switch (documentCreationModel.documentType.code) {
                                case DocumentType.supplierManifest:
                                    this.queueV2SupplierManifestReport(documentCreationModel);
                                    break;
                                case DocumentType.queueV2ExportReport:
                                    this.queueV2ExportReport(documentCreationModel);
                                    break;
                                case DocumentType.tourList:
                                    this.getTourList(documentCreationModel);
                                    break;
                                case DocumentType.arrivalList:
                                    this.getArrivalReport(documentCreationModel);
                                    break;
                                case DocumentType.departureList:
                                    this.getDepartureReport(documentCreationModel);
                                    break;
                            }
                        }
                    }
                }
            });
        } else {
            this.common.showSnackBar(
                'No Supplier linked',
                3000,
                TcErrorType.TYPE.WARN
            );
        }
    }

    public getAllGridList() {
        let resultsList: BookingItem[] | ServiceItem[] = [];
        if (this.isBookingQueue() && this.bookingQueueComponent &&
            this.bookingQueueComponent.bookingItemSearchResults) {
            resultsList = this.bookingQueueComponent.bookingItemSearchResults;
        } else if (this.isServiceQueue() && this.serviceQueueComponent &&
            this.serviceQueueComponent.serviceItemSearchResults) {
            resultsList = this.serviceQueueComponent.serviceItemSearchResults;
        }
        return resultsList;
    }

    queueV2SupplierManifestReport(documentCreationModel: DocumentCreationModel) {
        this.spinnerService.show();
        const documentCreationCriteria = new DocumentCreationCriteria();
        documentCreationCriteria.documentFormat = documentCreationModel.documentType.format;

        documentCreationCriteria.documentName = documentCreationModel.documentName;
        documentCreationCriteria.download = !!documentCreationModel.download;
        documentCreationCriteria.persist = !!documentCreationModel.persist;
        documentCreationCriteria.notifySupplier = !!documentCreationModel.notifySupplier;
        documentCreationCriteria.documentType = DocumentType.supplierManifest;

        const documentDataCriteria = new DocumentDataCriteria();
        documentDataCriteria.bookingIdList = documentCreationModel.selectedBookingItemIds;
        documentDataCriteria.queueVersion = 'V2';
        if (Array.isArray(this.manifestGenerationStatus)) {
            documentDataCriteria.assignmentStatuses = this.manifestGenerationStatus;
        }
        documentDataCriteria.queueVersion = 'V2';
        documentDataCriteria.documentType = DocumentType.supplierManifest;

        documentDataCriteria.viewType = documentCreationModel.viewType;
        documentDataCriteria.searchStartDate = this.productQueueSearchCriteria.startDate;
        documentDataCriteria.searchEndDate = this.productQueueSearchCriteria.endDate;

        const userFirstName = this.dataStore.get(DataKey.userDetail).getValue()
            .userSummary.firstName;
        const userLastName = this.dataStore.get(DataKey.userDetail).getValue()
            .userSummary.lastName;
        documentDataCriteria.userId = this.dataStore.get(DataKey.userDetail).getValue().id;
        documentDataCriteria.userName = userFirstName + ' ' + userLastName;

        // this.selectedBookingIdList.forEach(list => {
        //     list.forEach(id => {
        //         documentDataCriteria.bookingIdList.push(id);
        //     });
        // });
        DateTimeProcessor.processDocumentDataSearchCriteriaDateTimes(documentDataCriteria);
        this.documentService.createAndDownloadDocument(documentCreationCriteria, documentDataCriteria);
    }

    queueV2ExportReport(documentCreationModel: DocumentCreationModel) {
        this.spinnerService.show();
        const documentCreationCriteria = new DocumentCreationCriteria();
        documentCreationCriteria.documentFormat = DocumentFormat.excel;

        if (documentCreationModel.viewType === 'BOOKING_VIEW') {
            documentCreationCriteria.documentType = DocumentType.queueV2BookingViewExportReport;
        } else {
            documentCreationCriteria.documentType = DocumentType.queueV2ServicingViewExportReport;
        }

        documentCreationCriteria.documentName = documentCreationModel.documentName;
        documentCreationCriteria.download = !!documentCreationModel.download;
        documentCreationCriteria.persist = !!documentCreationModel.persist;
        documentCreationCriteria.notifySupplier = !!documentCreationModel.notifySupplier;

        const documentDataCriteria = new DocumentDataCriteria();
        documentDataCriteria.queueVersion = 'V2';

        if (documentCreationModel.viewType === 'BOOKING_VIEW') {
            documentDataCriteria.documentType = DocumentType.queueV2BookingViewExportReport;
        } else {
            documentDataCriteria.documentType = DocumentType.queueV2ServicingViewExportReport;
        }

        documentDataCriteria.viewType = documentCreationModel.viewType;
        documentDataCriteria.searchStartDate = this.productQueueSearchCriteria.startDate;
        documentDataCriteria.searchEndDate = this.productQueueSearchCriteria.endDate;

        if (documentCreationModel && documentCreationModel.selectedBookingItemIds) {
            documentCreationModel.selectedBookingItemIds.forEach(id => {
                documentDataCriteria.bookingIdList.push(id);
            });
        }

        DateTimeProcessor.processDocumentDataSearchCriteriaDateTimes(documentDataCriteria);
        this.documentService.createAndDownloadDocument(documentCreationCriteria, documentDataCriteria);
    }

    private getTourList(documentCreationModel: DocumentCreationModel) {
        this.spinnerService.show();
        const documentCreationCriteria = new DocumentCreationCriteria();
        documentCreationCriteria.documentFormat = DocumentFormat.excel;
        documentCreationCriteria.documentType = DocumentType.tourListV2;
        documentCreationCriteria.documentName = documentCreationModel.documentName;
        documentCreationCriteria.download = !!documentCreationModel.download;
        documentCreationCriteria.persist = !!documentCreationModel.persist;
        documentCreationCriteria.notifySupplier = !!documentCreationModel.notifySupplier;

        const documentDataCriteria = new DocumentDataCriteria();
        documentDataCriteria.searchStartDate = this.productQueueSearchCriteria.startDate;
        documentDataCriteria.searchEndDate = this.productQueueSearchCriteria.endDate;
        documentDataCriteria.documentType = DocumentType.tourListV2;
        documentDataCriteria.bookingIdList = documentCreationModel.selectedBookingItemIds;
        documentDataCriteria.queueVersion = 'V2';
        DateTimeProcessor.processDocumentDataSearchCriteriaDateTimes(documentDataCriteria);
        this.documentService.createAndDownloadDocument(documentCreationCriteria, documentDataCriteria);
    }

    private getArrivalReport(documentCreationModel: DocumentCreationModel) {
        this.spinnerService.show();
        const documentCreationCriteria = new DocumentCreationCriteria();
        documentCreationCriteria.documentFormat = DocumentFormat.excel;
        documentCreationCriteria.documentType = DocumentType.arrivalListV2;
        documentCreationCriteria.documentName = documentCreationModel.documentName;
        documentCreationCriteria.download = !!documentCreationModel.download;
        documentCreationCriteria.persist = !!documentCreationModel.persist;
        documentCreationCriteria.notifySupplier = !!documentCreationModel.notifySupplier;

        const documentDataCriteria = new DocumentDataCriteria();
        documentDataCriteria.searchStartDate = this.productQueueSearchCriteria.startDate;
        documentDataCriteria.searchEndDate = this.productQueueSearchCriteria.endDate;
        documentDataCriteria.documentType = DocumentType.arrivalListV2;
        documentDataCriteria.bookingIdList = documentCreationModel.selectedBookingItemIds;
        documentDataCriteria.queueVersion = 'V2';
        DateTimeProcessor.processDocumentDataSearchCriteriaDateTimes(documentDataCriteria);
        this.documentService.createAndDownloadDocument(documentCreationCriteria, documentDataCriteria);
    }

    private getDepartureReport(documentCreationModel: DocumentCreationModel) {
        this.spinnerService.show();
        const documentCreationCriteria = new DocumentCreationCriteria();
        documentCreationCriteria.documentFormat = DocumentFormat.excel;
        documentCreationCriteria.documentType = DocumentType.departureListV2;
        documentCreationCriteria.documentName = documentCreationModel.documentName;
        documentCreationCriteria.download = !!documentCreationModel.download;
        documentCreationCriteria.persist = !!documentCreationModel.persist;
        documentCreationCriteria.notifySupplier = !!documentCreationModel.notifySupplier;

        const documentDataCriteria = new DocumentDataCriteria();
        documentDataCriteria.searchStartDate = this.productQueueSearchCriteria.startDate;
        documentDataCriteria.searchEndDate = this.productQueueSearchCriteria.endDate;
        documentDataCriteria.documentType = DocumentType.departureListV2;
        documentDataCriteria.bookingIdList = documentCreationModel.selectedBookingItemIds;
        documentDataCriteria.queueVersion = 'V2';
        DateTimeProcessor.processDocumentDataSearchCriteriaDateTimes(documentDataCriteria);
        this.documentService.createAndDownloadDocument(documentCreationCriteria, documentDataCriteria);
    }

    getSelectedBookingItemIdList() {
        const bookingIdList = [];
        if (this.isBookingQueue()) {
            this.getSelectedItems().forEach((bookingItem: BookingItem) => {
                bookingIdList.push(bookingItem.id);
            });
        } else if (this.isServiceQueue()) {
            this.getSelectedItems().forEach((serviceItem: ServiceItem) => {
                serviceItem.bookingItemSummaryList.forEach((item) => {
                    bookingIdList.push(item.bookingItemId);
                });
            });
        }
        if (bookingIdList.length === 0) {
            bookingIdList.push(...this.allBookingIdList);
        }
        return bookingIdList;
    }

    onSupplierAutoAllocationClick() {
        const bookingItemSearchCriteria: BookingItemSearchCriteria = new BookingItemSearchCriteria();
        const serviceItemSearchCriteria: ServiceItemSearchCriteria = new ServiceItemSearchCriteria();
        const oldBookingIdList = [];
        const newBookingIdList = [];

        const constraintsSatisfied = true;
        if (this.isBookingQueue()) {
            for (const bookingItem of this.getSelectedItems()) {
                if (bookingItem.startTime && bookingItem.endTime) {
                    oldBookingIdList.push((bookingItem as BookingItem).id);
                } else {
                    const noon = new Date(new Date().setHours(12, 0, 0, 0));
                    const midnight = new Date(new Date().setHours(0, 0, 0, 0));

                    if (!bookingItem.startTime) {
                        bookingItem.startTime = noon;
                    }
                    if (!bookingItem.endTime) {
                        bookingItem.endTime = midnight;
                    }
                    oldBookingIdList.push((bookingItem as BookingItem).id);
                    this.common.showSnackBar(
                        `Booking Start time was set to ${noon.toLocaleString('en-US')} and End time to ${midnight.toLocaleString(
                            'en-US')}`,
                        3000,
                        TcErrorType.TYPE.INFO
                    );
                }
            }
            if (constraintsSatisfied) {
                this.spinnerService.show();
                oldBookingIdList.sort();
                bookingItemSearchCriteria.bookingIdList = oldBookingIdList;
                bookingItemSearchCriteria.allocationType = AllocationType.SUPPLIER;
                this.reservationV2ManagementService.doAutoAllocationForBookingItems(bookingItemSearchCriteria)
                    .subscribe((result) => {
                        if (result.status.code === 1) {
                            this.bookingQueueComponent.replaceWithAutoAllocatedBookingItems(
                                oldBookingIdList,
                                result.data
                            );
                            result.data.forEach(newItem => {
                                newBookingIdList.push(newItem.id);
                            });
                            this.productQueueComponent.updateSearchChipsAfterSplit(oldBookingIdList, newBookingIdList);
                        }
                        this.spinnerService.hide();
                        const itemsWithAssignments = result.data.filter(i => i.primaryAssignment);
                        const plural = itemsWithAssignments.length === 1 ? '' : 's';
                        this.common.showSnackBar(
                            `Added suppliers to ${itemsWithAssignments.length} item${plural}`,
                            3000,
                            TcErrorType.TYPE.INFO
                        );
                    }, (error => {
                        console.log('Auto allocation API error during data retrieval: ' + error.message);
                        this.common.showSnackBar(
                            'Resource adding failed',
                            3000,
                            TcErrorType.TYPE.ERROR
                        );
                        this.spinnerService.hide();
                    }), () => {
                        this.reloadGridData();
                    });
            } else {
                const invalidAttemptWarning = new DialogModel(
                    true,
                    LEVEL.ERROR,
                    'Invalid Attempt',
                    'Please set start and end times before auto allocation',
                    true,
                    2000
                );
                const subscriptionInvalid = this.dialogService.confirm(invalidAttemptWarning).subscribe((res) => {
                    subscriptionInvalid.unsubscribe();
                    return;
                });
            }
        } else if (this.isServiceQueue()) {
            const oldServiceIdList = [];
            for (const serviceItem of this.getSelectedItems()) {
                if (serviceItem.startTime && serviceItem.endTime) {
                    (serviceItem as ServiceItem).bookingItemSummaryList.forEach((item) => {
                        oldBookingIdList.push(item.bookingItemId);
                    });
                } else {
                    const noon = new Date(new Date().setHours(12, 0, 0, 0));
                    const midnight = new Date(new Date().setHours(0, 0, 0, 0));

                    if (!serviceItem.startTime) {
                        serviceItem.startTime = noon;
                    }
                    if (!serviceItem.endTime) {
                        serviceItem.endTime = midnight;
                    }
                    this.common.showSnackBar(
                        `Booking Start time was set to ${noon.toLocaleString('en-US')} and End time to ${midnight.toLocaleString(
                            'en-US')}`,
                        3000,
                        TcErrorType.TYPE.INFO
                    );
                    (serviceItem as ServiceItem).bookingItemSummaryList.forEach((item) => {
                        oldBookingIdList.push(item.bookingItemId);
                    });
                }
                oldServiceIdList.push((serviceItem as ServiceItem).serviceId);
            }
            if (constraintsSatisfied) {
                this.spinnerService.show();
                oldBookingIdList.sort();
                serviceItemSearchCriteria.bookingIdList = oldBookingIdList;
                serviceItemSearchCriteria.allocationType = AllocationType.SUPPLIER;
                this.reservationV2ManagementService.doAutoAllocationForServiceItems(serviceItemSearchCriteria)
                    .subscribe(
                        (result) => {
                            if (result.status.code === 1) {
                                this.serviceQueueComponent.replaceWithAutoAllocatedServiceItems(
                                    oldServiceIdList,
                                    result.data
                                );

                                result.data.forEach(item => {
                                    item.bookingItemSummaryList.forEach(newItem => {
                                        newBookingIdList.push(newItem.bookingItemId);
                                    });
                                });
                                this.productQueueComponent.updateSearchChipsAfterSplit(
                                    oldBookingIdList,
                                    newBookingIdList
                                );
                            }
                            this.spinnerService.hide();
                            const itemsWithAssignments = result.data.filter(i => i.primaryAssignment);
                            const plural = itemsWithAssignments.length === 1 ? '' : 's';
                            this.common.showSnackBar(
                                `Added suppliers to ${itemsWithAssignments.length} item${plural}`,
                                3000,
                                TcErrorType.TYPE.INFO
                            );
                        }, error => {
                            console.log('Auto allocation API error during data retrieval: ' + error.message);
                            this.spinnerService.hide();
                            this.common.showSnackBar(
                                'Resource adding failed',
                                3000,
                                TcErrorType.TYPE.ERROR
                            );
                        }, () => {
                            this.reloadGridData();
                        });
            } else {
                const invalidAttemptWarning = new DialogModel(
                    true,
                    LEVEL.ERROR,
                    'Invalid Attempt',
                    'Please set start and end times before auto allocation',
                    true,
                    2000
                );
                const subscriptionInvalid = this.dialogService.confirm(invalidAttemptWarning).subscribe((res) => {
                    subscriptionInvalid.unsubscribe();
                    return;
                });
            }
        } else {
            this.confirmButtonEnable = false;
        }
    }

    onResourceAutoAllocationClick() {

        const bookingItemSearchCriteria: BookingItemSearchCriteria = new BookingItemSearchCriteria();
        const serviceItemSearchCriteria: ServiceItemSearchCriteria = new ServiceItemSearchCriteria();
        const oldBookingIdList = [];
        const newBookingIdList = [];

        const constraintsStasfied = true;
        if (this.isBookingQueue()) {
            for (const bookingItem of this.getSelectedItems()) {
                if (bookingItem.startTime && bookingItem.endTime) {
                    oldBookingIdList.push((bookingItem as BookingItem).id);
                } else {
                    const noon = new Date(new Date().setHours(12, 0, 0, 0));
                    const midnight = new Date(new Date().setHours(0, 0, 0, 0));

                    if (!bookingItem.startTime) {
                        bookingItem.startTime = noon;
                    }
                    if (!bookingItem.endTime) {
                        bookingItem.endTime = midnight;
                    }
                    oldBookingIdList.push((bookingItem as BookingItem).id);
                    this.common.showSnackBar(
                        `Booking Start time was set to ${noon.toLocaleString('en-US')} and End time to ${midnight.toLocaleString(
                            'en-US')}`,
                        3000,
                        TcErrorType.TYPE.INFO
                    );
                }
            }
            if (constraintsStasfied) {
                this.spinnerService.show();
                oldBookingIdList.sort();
                bookingItemSearchCriteria.bookingIdList = oldBookingIdList;
                bookingItemSearchCriteria.allocationType = AllocationType.RESOURCE;
                this.reservationV2ManagementService.doAutoAllocationForBookingItems(bookingItemSearchCriteria)
                    .subscribe((result) => {
                        if (result.status.code === 1) {
                            this.bookingQueueComponent.replaceWithAutoAllocatedBookingItems(
                                oldBookingIdList,
                                result.data
                            );
                            result.data.forEach(newItem => {
                                newBookingIdList.push(newItem.id);
                            });
                            this.productQueueComponent.updateSearchChipsAfterSplit(oldBookingIdList, newBookingIdList);
                        }
                        this.spinnerService.hide();
                        const itemsWithAssignments = result.data.filter(i => i.primaryAssignment);
                        const plural = itemsWithAssignments.length === 1 ? '' : 's';
                        this.common.showSnackBar(
                            `Added resources to ${itemsWithAssignments.length} item${plural}`,
                            3000,
                            TcErrorType.TYPE.INFO
                        );
                    }, (error => {
                        console.log('Auto allocation API error during data retrieval: ' + error.message);
                        this.common.showSnackBar(
                            'Resource adding failed',
                            3000,
                            TcErrorType.TYPE.ERROR
                        );
                    }), () => {
                        this.spinnerService.hide();
                        this.reloadGridData();
                    });
            } else {
                const invalidAttemptWarning = new DialogModel(
                    true,
                    LEVEL.ERROR,
                    'Invalid Attempt',
                    'Please set start and end times before auto allocation',
                    true,
                    2000
                );
                const subscriptionInvalid = this.dialogService.confirm(invalidAttemptWarning).subscribe((res) => {
                    subscriptionInvalid.unsubscribe();
                    return;
                });
            }
        } else if (this.isServiceQueue()) {
            const oldServiceIdList = [];
            for (const serviceItem of this.getSelectedItems()) {
                if (serviceItem.startTime && serviceItem.endTime) {
                    (serviceItem as ServiceItem).bookingItemSummaryList.forEach((item) => {
                        oldBookingIdList.push(item.bookingItemId);
                    });
                } else {
                    const noon = new Date(new Date().setHours(12, 0, 0, 0));
                    const midnight = new Date(new Date().setHours(0, 0, 0, 0));

                    if (!serviceItem.startTime) {
                        serviceItem.startTime = noon;
                    }
                    if (!serviceItem.endTime) {
                        serviceItem.endTime = midnight;
                    }
                    this.common.showSnackBar(
                        `Booking Start time was set to ${noon.toLocaleString('en-US')} and End time to ${midnight.toLocaleString(
                            'en-US')}`,
                        3000,
                        TcErrorType.TYPE.INFO
                    );
                    (serviceItem as ServiceItem).bookingItemSummaryList.forEach((item) => {
                        oldBookingIdList.push(item.bookingItemId);
                    });
                }
                oldServiceIdList.push((serviceItem as ServiceItem).serviceId);
            }
            if (constraintsStasfied) {
                this.spinnerService.show();
                oldBookingIdList.sort();
                serviceItemSearchCriteria.bookingIdList = oldBookingIdList;
                serviceItemSearchCriteria.allocationType = AllocationType.RESOURCE;
                this.reservationV2ManagementService.doAutoAllocationForServiceItems(serviceItemSearchCriteria)
                    .subscribe((result) => {
                        if (result.status.code === 1) {
                            this.serviceQueueComponent.replaceWithAutoAllocatedServiceItems(
                                oldServiceIdList,
                                result.data
                            );

                            result.data.forEach(item => {
                                item.bookingItemSummaryList.forEach(newItem => {
                                    newBookingIdList.push(newItem.bookingItemId);
                                });
                            });
                            this.productQueueComponent.updateSearchChipsAfterSplit(oldBookingIdList, newBookingIdList);
                        }
                        this.spinnerService.hide();
                        const itemsWithAssignments = result.data.filter(i => i.primaryAssignment);
                        const plural = itemsWithAssignments.length === 1 ? '' : 's';
                        this.common.showSnackBar(
                            `Added resources to ${itemsWithAssignments.length} item${plural}`,
                            3000,
                            TcErrorType.TYPE.INFO
                        );
                    }, error => {
                        console.log('Auto allocation API error during data retrieval: ' + error.message);
                        this.spinnerService.hide();
                        this.common.showSnackBar(
                            'Resource adding failed',
                            3000,
                            TcErrorType.TYPE.ERROR
                        );
                    }, () => {
                        this.reloadGridData();
                    });
            } else {
                const invalidAttemptWarning = new DialogModel(
                    true,
                    LEVEL.ERROR,
                    'Invalid Attempt',
                    'Please set start and end times before auto allocation',
                    true,
                    2000
                );
                const subscriptionInvalid = this.dialogService.confirm(invalidAttemptWarning).subscribe((res) => {
                    subscriptionInvalid.unsubscribe();
                    return;
                });
            }
        } else {
            this.confirmButtonEnable = false;
        }
    }

    private getSelectedItemsIdsForConfirm() {
        const selectedItems: (BookingItem | ServiceItem)[] = this.getSelectedItems();
        const assignmentIds: number[] = [];
        for (const selectedItem of selectedItems) {
            if (selectedItem.primaryAssignment && this.isInConfirmableState(selectedItem.primaryAssignment) &&
                selectedItem.primaryAssignment.assignmentId) {
                this.addToListIfNotExist(assignmentIds, selectedItem.primaryAssignment.assignmentId);
            }
            if (selectedItem.secondaryAssignment && this.isInConfirmableState(selectedItem.secondaryAssignment) &&
                selectedItem.secondaryAssignment.assignmentId) {
                this.addToListIfNotExist(assignmentIds, selectedItem.secondaryAssignment.assignmentId);
            }
        }
        return assignmentIds;
    }

    private getSelectedItemIdsForSupplierConfirm() {
        const selectedItems: (BookingItem | ServiceItem)[] = this.getSelectedItems();
        const assignmentIds: number[] = [];
        for (const selectedItem of selectedItems) {
            if (selectedItem.primaryAssignment && this.isInSupplierConfirmableState(selectedItem.primaryAssignment) &&
                selectedItem.primaryAssignment.assignmentId) {
                this.addToListIfNotExist(assignmentIds, selectedItem.primaryAssignment.assignmentId);
            }
            if (selectedItem.secondaryAssignment &&
                this.isInSupplierConfirmableState(selectedItem.secondaryAssignment) &&
                selectedItem.secondaryAssignment.assignmentId) {
                this.addToListIfNotExist(assignmentIds, selectedItem.secondaryAssignment.assignmentId);
            }
        }
        return assignmentIds;
    }

    private getSelectedItemIdsForSupplierReject() {
        const selectedItems: (BookingItem | ServiceItem)[] = this.getSelectedItems();
        const assignmentIds: number[] = [];
        for (const selectedItem of selectedItems) {
            if (selectedItem.primaryAssignment && this.isInSupplierRejectableState(selectedItem.primaryAssignment) &&
                selectedItem.primaryAssignment.assignmentId) {
                this.addToListIfNotExist(assignmentIds, selectedItem.primaryAssignment.assignmentId);
            }
            if (selectedItem.secondaryAssignment &&
                this.isInSupplierRejectableState(selectedItem.secondaryAssignment) &&
                selectedItem.secondaryAssignment.assignmentId) {
                this.addToListIfNotExist(assignmentIds, selectedItem.secondaryAssignment.assignmentId);
            }
        }
        return assignmentIds;
    }

    addToListIfNotExist(list: any[], assignment: any) {
        if (!list.includes(assignment)) {
            list.push(assignment);
        }
    }

    addToListIfNotExistAll(list: any[], assignments: any[]) {
        for (const assignment of assignments) {
            if (!list.includes(assignment)) {
                list.push(assignment);
            }
        }
    }

    /**
     * starts ag-grid value-setter
     */
    onSetPvtShared = (params) => {
        if (params.newValue) {
            params.data.pvtShared = params.newValue;
            this.markChanges();

            const bookingIds = [];
            if (this.isBookingQueue()) {
                const bookingItem: BookingItem = params.data;
                bookingIds.push(bookingItem.id);
                this.selectItems([bookingItem]);
            } else {
                const serviceItem: ServiceItem = params.data;
                if (serviceItem.bookingItemSummaryList != null) {
                    for (const bookingItemSummary of serviceItem.bookingItemSummaryList) {
                        bookingIds.push(bookingItemSummary.bookingItemId);
                    }
                }
                this.selectItems([serviceItem]);
            }
            const bookingItemPatch = new BookingItemPatch();
            bookingItemPatch.pvtShared = params.data.pvtShared;
            this.reservationV2ManagementService.patchBookingItems(bookingIds, bookingItemPatch)
                .subscribe(res => {
                        console.log(res);
                        if (params.data.costingType && params.data.costingType.code === CostingType.DISTANCE) {
                            params.data.noOfUnits = parseFloat(params.newValue);
                            this.updateCostingUnits(params.data);
                        }
                        this.common.showSnackBar(
                            'Added pvtShared to booking item/items',
                            3000,
                            TcErrorType.TYPE.INFO
                        );
                    }, error => {
                        console.log(error);
                        this.common.showSnackBar(
                            'Failed to add pvtShared to booking item/items',
                            3000,
                            TcErrorType.TYPE.ERROR
                        );
                    }
                );
        }
    };
    onSetDeptFlightDate = (params) => {
        if (params.newValue && new Date(params.newValue) instanceof Date) {
            const time = params.data.trsBookingItem ?
                params.data.trsBookingItem.flightDeptTime ?
                    params.data.trsBookingItem.flightDeptTime.split('T')[1] :
                    new Date().toLocaleTimeString('en-IT', {hour12: false}) :
                new Date().toLocaleTimeString('en-IT', {hour12: false});
            // params.data.startTime = new Date(date + 'T' + params.newValue);
            params.data.trsBookingItem.flightDeptTime = new Date(params.newValue + 'T' + time);
            this.markChanges();

            const bookingIds = [];
            if (this.isBookingQueue()) {
                const bookingItem: BookingItem = params.data;
                bookingIds.push(bookingItem.id);
                this.selectItems([bookingItem]);
            } else {
                const serviceItem: ServiceItem = params.data;
                if (serviceItem.bookingItemSummaryList != null) {
                    for (const bookingItemSummary of serviceItem.bookingItemSummaryList) {
                        bookingIds.push(bookingItemSummary.bookingItemId);
                    }
                }
                this.selectItems([serviceItem]);
            }
            const bookingItemPatch = new BookingItemPatch();
            bookingItemPatch.flightDeptTime = params.data.trsBookingItem.flightDeptTime;
            this.reservationV2ManagementService.patchBookingItems(bookingIds, bookingItemPatch)
                .subscribe(res => {
                        if (params.data.costingType && params.data.costingType.code === CostingType.DURATION) {
                            params.data.noOfUnits = this.getDuration(params.data);
                            this.updateCostingUnits(params.data);
                        }
                        this.common.showSnackBar(
                            'Added flight dept. date to booking item/items',
                            3000,
                            TcErrorType.TYPE.INFO
                        );
                    }, error => {
                        console.log(error);
                        this.common.showSnackBar(
                            'Failed to add flight dept. date to booking item/items',
                            3000,
                            TcErrorType.TYPE.ERROR
                        );
                    }
                );
        }
    };
    onSetDeptFltTime = (params) => {
        if (params.newValue && new Date(params.newValue) instanceof Date) {
            const date = params.data.trsBookingItem.flightDeptTime ?
                params.data.trsBookingItem.flightDeptTime.toISOString().split('T')[0] :
                params.data.serviceDate.split('T')[0];
            // params.data.startTime = new Date(date + 'T' + params.newValue);
            params.data.trsBookingItem.flightDeptTime = new Date(date + 'T' + params.newValue);
            this.markChanges();

            const bookingIds = [];
            if (this.isBookingQueue()) {
                const bookingItem: BookingItem = params.data;
                bookingIds.push(bookingItem.id);
                this.selectItems([bookingItem]);
            } else {
                const serviceItem: ServiceItem = params.data;
                if (serviceItem.bookingItemSummaryList != null) {
                    for (const bookingItemSummary of serviceItem.bookingItemSummaryList) {
                        bookingIds.push(bookingItemSummary.bookingItemId);
                    }
                }
                this.selectItems([serviceItem]);
            }
            const bookingItemPatch = new BookingItemPatch();
            bookingItemPatch.flightDeptTime = params.data.trsBookingItem.flightDeptTime;
            this.reservationV2ManagementService.patchBookingItems(bookingIds, bookingItemPatch)
                .subscribe(res => {
                        if (params.data.costingType && params.data.costingType.code === CostingType.DURATION) {
                            params.data.noOfUnits = this.getDuration(params.data);
                            this.updateCostingUnits(params.data);
                        }
                        this.common.showSnackBar(
                            'Added flight dept. time to booking item/items',
                            3000,
                            TcErrorType.TYPE.INFO
                        );
                    }, error => {
                        console.log(error);
                        this.common.showSnackBar(
                            'Failed to add flight dept. time to booking item/items',
                            3000,
                            TcErrorType.TYPE.ERROR
                        );
                    }
                );
        }
    };
    onSetDeptFltNo = (params) => {
        if (params.newValue) {
            params.data.trsBookingItem.departureFlightNo = params.newValue;
            this.markChanges();

            const bookingIds = [];
            if (this.isBookingQueue()) {
                const bookingItem: BookingItem = params.data;
                bookingIds.push(bookingItem.id);
                this.selectItems([bookingItem]);
            } else {
                const serviceItem: ServiceItem = params.data;
                if (serviceItem.bookingItemSummaryList != null) {
                    for (const bookingItemSummary of serviceItem.bookingItemSummaryList) {
                        bookingIds.push(bookingItemSummary.bookingItemId);
                    }
                }
                this.selectItems([serviceItem]);
            }
            const bookingItemPatch = new BookingItemPatch();
            bookingItemPatch.departureFlightNo = params.data.trsBookingItem.departureFlightNo;
            this.reservationV2ManagementService.patchBookingItems(bookingIds, bookingItemPatch)
                .subscribe(res => {
                        console.log(res);
                        if (params.data.costingType && params.data.costingType.code === CostingType.DISTANCE) {
                            params.data.noOfUnits = parseFloat(params.newValue);
                            this.updateCostingUnits(params.data);
                        }
                        this.common.showSnackBar(
                            'Added dept. flight number to booking item/items',
                            3000,
                            TcErrorType.TYPE.INFO
                        );
                    }, error => {
                        console.log(error);
                        this.common.showSnackBar(
                            'Failed to add dept. flight number to booking item/items',
                            3000,
                            TcErrorType.TYPE.ERROR
                        );
                    }
                );
        }
    };
    onSetDeptFltTerminal = (params) => {
        if (params.newValue) {
            params.data.trsBookingItem.flightDeptTerminal = params.newValue;
            this.markChanges();

            const bookingIds = [];
            if (this.isBookingQueue()) {
                const bookingItem: BookingItem = params.data;
                bookingIds.push(bookingItem.id);
                this.selectItems([bookingItem]);
            } else {
                const serviceItem: ServiceItem = params.data;
                if (serviceItem.bookingItemSummaryList != null) {
                    for (const bookingItemSummary of serviceItem.bookingItemSummaryList) {
                        bookingIds.push(bookingItemSummary.bookingItemId);
                    }
                }
                this.selectItems([serviceItem]);
            }
            const bookingItemPatch = new BookingItemPatch();
            bookingItemPatch.flightDeptTerminal = params.data.trsBookingItem.flightDeptTerminal;
            this.reservationV2ManagementService.patchBookingItems(bookingIds, bookingItemPatch)
                .subscribe(res => {
                        this.common.showSnackBar(
                            'Added dept. flight terminal to booking item/items',
                            3000,
                            TcErrorType.TYPE.INFO
                        );
                    }, error => {
                        console.log(error);
                        this.common.showSnackBar(
                            'Failed to add dept. flight terminal to booking item/items',
                            3000,
                            TcErrorType.TYPE.ERROR
                        );
                    }
                );
        }
    };
    onSetArrFlightDate = (params) => {
        if (params.newValue && new Date(params.newValue) instanceof Date) {
            const time = params.data.trsBookingItem.flightArrTime ?
                params.data.trsBookingItem.flightArrTime.split('T')[1] :
                new Date().toLocaleTimeString('en-IT', {hour12: false});
            // params.data.startTime = new Date(date + 'T' + params.newValue);
            params.data.trsBookingItem.flightArrTime = new Date(params.newValue + 'T' + time);
            this.markChanges();

            const bookingIds = [];
            if (this.isBookingQueue()) {
                const bookingItem: BookingItem = params.data;
                bookingIds.push(bookingItem.id);
                this.selectItems([bookingItem]);
            } else {
                const serviceItem: ServiceItem = params.data;
                if (serviceItem.bookingItemSummaryList != null) {
                    for (const bookingItemSummary of serviceItem.bookingItemSummaryList) {
                        bookingIds.push(bookingItemSummary.bookingItemId);
                    }
                }
                this.selectItems([serviceItem]);
            }
            const bookingItemPatch = new BookingItemPatch();
            bookingItemPatch.flightArrTime = params.data.trsBookingItem.flightArrTime;
            this.reservationV2ManagementService.patchBookingItems(bookingIds, bookingItemPatch)
                .subscribe(res => {
                        if (params.data.costingType && params.data.costingType.code === CostingType.DURATION) {
                            params.data.noOfUnits = this.getDuration(params.data);
                            this.updateCostingUnits(params.data);
                        }
                        this.common.showSnackBar(
                            'Added flight arr. date to booking item/items',
                            3000,
                            TcErrorType.TYPE.INFO
                        );
                    }, error => {
                        console.log(error);
                        this.common.showSnackBar(
                            'Failed to add flight arr. date to booking item/items',
                            3000,
                            TcErrorType.TYPE.ERROR
                        );
                    }
                );
        }
    };
    onSetArrFltTime = (params) => {
        if (params.newValue && new Date(params.newValue) instanceof Date) {
            const date = params.data.trsBookingItem.flightArrTime ?
                params.data.trsBookingItem.flightArrTime.toISOString().split('T')[0] :
                params.data.serviceDate.split('T')[0];
            // params.data.startTime = new Date(date + 'T' + params.newValue);
            params.data.trsBookingItem.flightArrTime = new Date(date + 'T' + params.newValue);
            this.markChanges();

            const bookingIds = [];
            if (this.isBookingQueue()) {
                const bookingItem: BookingItem = params.data;
                bookingIds.push(bookingItem.id);
                this.selectItems([bookingItem]);
            } else {
                const serviceItem: ServiceItem = params.data;
                if (serviceItem.bookingItemSummaryList != null) {
                    for (const bookingItemSummary of serviceItem.bookingItemSummaryList) {
                        bookingIds.push(bookingItemSummary.bookingItemId);
                    }
                }
                this.selectItems([serviceItem]);
            }
            const bookingItemPatch = new BookingItemPatch();
            bookingItemPatch.flightArrTime = params.data.trsBookingItem.flightArrTime;
            this.reservationV2ManagementService.patchBookingItems(bookingIds, bookingItemPatch)
                .subscribe(res => {
                        if (params.data.costingType && params.data.costingType.code === CostingType.DURATION) {
                            params.data.noOfUnits = this.getDuration(params.data);
                            this.updateCostingUnits(params.data);
                        }
                        this.common.showSnackBar(
                            'Added flight arr. time to booking item/items',
                            3000,
                            TcErrorType.TYPE.INFO
                        );
                    }, error => {
                        console.log(error);
                        this.common.showSnackBar(
                            'Failed to add flight arr. time to booking item/items',
                            3000,
                            TcErrorType.TYPE.ERROR
                        );
                    }
                );
        }
    };
    onSetArrFltNo = (params) => {
        if (params.newValue) {
            params.data.trsBookingItem.arrivalFlightNo = params.newValue;
            this.markChanges();

            const bookingIds = [];
            if (this.isBookingQueue()) {
                const bookingItem: BookingItem = params.data;
                bookingIds.push(bookingItem.id);
                this.selectItems([bookingItem]);
            } else {
                const serviceItem: ServiceItem = params.data;
                if (serviceItem.bookingItemSummaryList != null) {
                    for (const bookingItemSummary of serviceItem.bookingItemSummaryList) {
                        bookingIds.push(bookingItemSummary.bookingItemId);
                    }
                }
                this.selectItems([serviceItem]);
            }
            const bookingItemPatch = new BookingItemPatch();
            bookingItemPatch.arrivalFlightNo = params.data.trsBookingItem.arrivalFlightNo;
            this.reservationV2ManagementService.patchBookingItems(bookingIds, bookingItemPatch)
                .subscribe(res => {
                        console.log(res);
                        if (params.data.costingType && params.data.costingType.code === CostingType.DISTANCE) {
                            params.data.noOfUnits = parseFloat(params.newValue);
                            this.updateCostingUnits(params.data);
                        }
                        this.common.showSnackBar(
                            'Added arr. flight number to booking item/items',
                            3000,
                            TcErrorType.TYPE.INFO
                        );
                    }, error => {
                        console.log(error);
                        this.common.showSnackBar(
                            'Failed to add arr. flight number to booking item/items',
                            3000,
                            TcErrorType.TYPE.ERROR
                        );
                    }
                );
        }
    };
    onSetArrFltTerminal = (params) => {
        if (params.newValue) {
            params.data.trsBookingItem.flightArrTerminal = params.newValue;
            this.markChanges();

            const bookingIds = [];
            if (this.isBookingQueue()) {
                const bookingItem: BookingItem = params.data;
                bookingIds.push(bookingItem.id);
                this.selectItems([bookingItem]);
            } else {
                const serviceItem: ServiceItem = params.data;
                if (serviceItem.bookingItemSummaryList != null) {
                    for (const bookingItemSummary of serviceItem.bookingItemSummaryList) {
                        bookingIds.push(bookingItemSummary.bookingItemId);
                    }
                }
                this.selectItems([serviceItem]);
            }
            const bookingItemPatch = new BookingItemPatch();
            bookingItemPatch.flightArrTerminal = params.data.trsBookingItem.flightArrTerminal;
            this.reservationV2ManagementService.patchBookingItems(bookingIds, bookingItemPatch)
                .subscribe(res => {
                        this.common.showSnackBar(
                            'Added arrival terminal to booking item/items',
                            3000,
                            TcErrorType.TYPE.INFO
                        );
                    }, error => {
                        console.log(error);
                        this.common.showSnackBar(
                            'Failed to add arrival terminal to booking item/items',
                            3000,
                            TcErrorType.TYPE.ERROR
                        );
                    }
                );
        }
    };
    onSetDistance = (params) => {
        const oldDistance = params.data.distance;
        if (params.newValue) {
            params.data.distance = parseFloat(params.newValue);
            this.markChanges();

            const bookingIds = [];
            if (this.isBookingQueue()) {
                const bookingItem: BookingItem = params.data;
                bookingIds.push(bookingItem.id);
                this.selectItems([bookingItem]);
            } else {
                const serviceItem: ServiceItem = params.data;
                if (serviceItem.bookingItemSummaryList != null) {
                    for (const bookingItemSummary of serviceItem.bookingItemSummaryList) {
                        bookingIds.push(bookingItemSummary.bookingItemId);
                    }
                }
                this.selectItems([serviceItem]);
            }
            const bookingItemPatch = new BookingItemPatch();
            bookingItemPatch.distance = params.data.distance;
            this.reservationV2ManagementService.patchBookingItems(bookingIds, bookingItemPatch)
                .subscribe(res => {
                        console.log(res);
                        if (params.data.costingType && params.data.costingType.code === CostingType.DISTANCE) {
                            params.data.noOfUnits = parseFloat(params.newValue);
                            this.updateCostingUnits(params.data);
                        }
                        this.common.showSnackBar(
                            'Added distance to booking item/items',
                            3000,
                            TcErrorType.TYPE.INFO
                        );
                    }, error => {
                        console.log(error);
                        this.common.showSnackBar(
                            'Failed to add distance to booking item/items',
                            3000,
                            TcErrorType.TYPE.ERROR
                        );
                    }
                );
        }
        if (params.newValue > 0 || params.newValue === '') {
            if (typeof params.newValue === 'string' || params.newValue instanceof String) {
                params.newValue = parseFloat(params.newValue);
            }
            _.set(params.data, params.colDef.field, params.newValue); // calling lodash deep property value set
            return true;
        }
        return false;
    };
    onSetStartTime = (params) => {
        if (params.newValue && new Date(params.newValue) instanceof Date) {
            const date = params.data.serviceDate ? params.data.serviceDate.split('T')[0] : params.data.serviceDate;
            // params.data.startTime = new Date(date + 'T' + params.newValue);
            params.data.startTime = new Date(date + 'T' + params.newValue);
            this.markChanges();

            const bookingIds = [];
            if (this.isBookingQueue()) {
                const bookingItem: BookingItem = params.data;
                bookingIds.push(bookingItem.id);
                this.selectItems([bookingItem]);
            } else {
                const serviceItem: ServiceItem = params.data;
                if (serviceItem.bookingItemSummaryList != null) {
                    for (const bookingItemSummary of serviceItem.bookingItemSummaryList) {
                        bookingIds.push(bookingItemSummary.bookingItemId);
                    }
                }
                this.selectItems([serviceItem]);
            }
            const bookingItemPatch = new BookingItemPatch();
            bookingItemPatch.serviceStartDateTime = params.data.startTime;
            this.reservationV2ManagementService.patchBookingItems(bookingIds, bookingItemPatch)
                .subscribe(res => {
                        if (params.data.costingType && params.data.costingType.code === CostingType.DURATION) {
                            params.data.noOfUnits = this.getDuration(params.data);
                            this.updateCostingUnits(params.data);
                        }
                        this.common.showSnackBar(
                            'Added start time to booking item/items',
                            3000,
                            TcErrorType.TYPE.INFO
                        );
                    }, error => {
                        console.log(error);
                        this.common.showSnackBar(
                            'Failed to add start time to booking item/items',
                            3000,
                            TcErrorType.TYPE.ERROR
                        );
                    }
                );
        }
    };
    getDefaultStartTime = (params) => {
        if (params.data && !params.data.startTime && params.data.trsBookingItem) {
            params.data.startTime = new Date(params.data.trsBookingItem.departureDateTime);
        } else {
            params.data.startTime = EMPTY_CHAR;
        }
    };
    onSetEndTime = (params) => {
        if (params.newValue && new Date(params.newValue) instanceof Date) {
            const date = params.data.serviceDate ? params.data.serviceDate.split('T')[0] : params.data.serviceDate;
            params.data.endTime = new Date(date + 'T' + params.newValue);
            this.markChanges();

            const bookingIds = [];
            if (this.isBookingQueue()) {
                const bookingItem: BookingItem = params.data;
                bookingIds.push(bookingItem.id);
                this.selectItems([bookingItem]);
            } else {
                const serviceItem: ServiceItem = params.data;
                if (serviceItem.bookingItemSummaryList != null) {
                    for (const bookingItemSummary of serviceItem.bookingItemSummaryList) {
                        bookingIds.push(bookingItemSummary.bookingItemId);
                    }
                }
                this.selectItems([serviceItem]);
            }
            const bookingItemPatch = new BookingItemPatch();
            bookingItemPatch.serviceEndDateTime = params.data.endTime;
            this.reservationV2ManagementService.patchBookingItems(bookingIds, bookingItemPatch)
                .subscribe(res => {
                        if (params.data.costingType && params.data.costingType.code === CostingType.DURATION) {
                            params.data.noOfUnits = this.getDuration(params.data);
                            this.updateCostingUnits(params.data);
                        }
                        this.common.showSnackBar(
                            'Added end time to booking item/items',
                            3000,
                            TcErrorType.TYPE.INFO
                        );
                    }, error => {
                        console.log(error);
                        this.common.showSnackBar(
                            'Failed to add end time to booking item/items',
                            3000,
                            TcErrorType.TYPE.ERROR
                        );
                    }
                );
        }
    };
    getDefaultEndTime = (params) => {
        if (params.data && !params.data.endTime && params.data.trsBookingItem) {
            params.data.endTime = new Date(params.data.trsBookingItem.arrivalDateTime);
        } else if (params.data && !params.data.endTime) {
            params.data.endTime = EMPTY_CHAR;
        }
    };
    getCostingTypes = (params: ICellEditorParams, row: ServiceItem | BookingItem) => {
        const costingTypeList = [];
        const clearCostingType = {
            name: '-- CLEAR --',
            code: null,
            obj: null
        };
        costingTypeList.push(clearCostingType);
        if (row && row.primaryAssignment && row.primaryAssignment.supplierObj &&
            row.primaryAssignment.supplierObj.genResourceCostSummaries) {
            const uniqueCostingTypes = row.primaryAssignment.supplierObj.genResourceCostSummaries.map(item => item.costingType)
                                          .filter((value, index, self) => self.indexOf(value) === index);
            uniqueCostingTypes.map(i => {
                const costingType = this.costingTypes.find(rt => rt.code === i);
                const existingType = {
                    name: costingType ? costingType.name : i,
                    code: i,
                    obj: costingType
                };
                costingTypeList.push(existingType);
            });

        } else {
            this.costingTypes.forEach(type => {
                const costingType = {
                    name: type ? type.name : '',
                    code: type ? type.code : '',
                    obj: type
                };
                costingTypeList.push(costingType);
            });

        }
        return costingTypeList;
    };
    getSecondaryCostingTypes = (params: ICellEditorParams, row: ServiceItem | BookingItem) => {
        const costingTypeList = [];
        const clearCostingType = {
            name: '-- CLEAR --',
            code: null,
            obj: null
        };
        costingTypeList.push(clearCostingType);
        if (row && row.secondaryAssignment && row.secondaryAssignment.supplierObj &&
            row.secondaryAssignment.supplierObj.genResourceCostSummaries) {
            const uniqueCostingTypes = row.secondaryAssignment.supplierObj.genResourceCostSummaries.map(item => item.costingType)
                                          .filter((value, index, self) => self.indexOf(value) === index);
            uniqueCostingTypes.map(i => {
                const costingType = this.costingTypes.find(rt => rt.code === i);
                const existingType = {
                    name: costingType ? costingType.name : i,
                    code: i,
                    obj: costingType
                };
                costingTypeList.push(existingType);
            });

        } else {
            this.costingTypes.forEach(type => {
                const costingType = {
                    name: type ? type.name : '',
                    code: type ? type.code : '',
                    obj: type
                };
                costingTypeList.push(costingType);
            });

        }
        return costingTypeList;
    };
    getServiceBookingQueueCostingTypes = (params: BookingItemGridParam | ServiceItemGridParam) => {
        let costingType: any;
        if (this.costingTypes && params.data && params.data.costingType && Array.isArray(this.costingTypes)) {
            costingType = this.costingTypes.find(rt => rt.code === params.data.costingType.code);
            return costingType && costingType.name ? costingType.name : params.data.costingType;
        } else if (this.costingTypes && params.data && params.data.primaryAssignment && Array.isArray(this.costingTypes)) {
            costingType = this.costingTypes.find(rt => rt.code === params.data.primaryAssignment.costingType);
            params.data.costingType = costingType;
            return costingType && costingType.name ? costingType.name : params.data.primaryAssignment.costingType;
        } else {
            return this.EMPTY_STRING;
        }
    };
    getSecondaryServiceBookingQueueCostingTypes = (params: BookingItemGridParam | ServiceItemGridParam) => {
        let costingType: any;
        if (this.costingTypes && params.data && params.data.secondaryCostingType) {
            costingType = this.costingTypes.find(rt => rt.code === params.data.secondaryCostingType.code);
            return costingType && costingType.name ? costingType.name : params.data.costingType;
        } else if (this.costingTypes && params.data.secondaryAssignment) {
            costingType = this.costingTypes.find(rt => rt.code === params.data.secondaryAssignment.costingType);
            params.data.secondaryCostingType = costingType;
            return costingType && costingType.name ? costingType.name : params.data.secondaryAssignment.costingType;
        } else {
            return this.EMPTY_STRING;
        }
    };
    getServiceBookingNoOfCostingUnits = (params: BookingItemGridParam | ServiceItemGridParam) => {
        if (params.data) {
            if (params.data.noOfUnits) {
                return params.data.noOfUnits;
            } else if (params.data.primaryAssignment && params.data.primaryAssignment.noOfUnits > 0) {
                params.data.noOfUnits = params.data.primaryAssignment.noOfUnits;
                return params.data.primaryAssignment.noOfUnits;
            } else if (params.data.costingType && params.data.costingType.code === CostingType.PERSON) {
                return (params.data.adultCount + params.data.teenCount + params.data.childrenCount +
                        params.data.infantCount) + ' - (' +
                    (params.data.adultCount + params.data.teenCount) + ' ' + params.data.childrenCount + ' ' +
                    params.data.infantCount +
                    ')';
            }
        }
        return this.EMPTY_STRING;
    };
    getSecondaryServiceBookingNoOfCostingUnits = (params: BookingItemGridParam | ServiceItemGridParam) => {
        if (params.data) {
            if (params.data.secondaryNoOfUnits) {
                return params.data.secondaryNoOfUnits;
            } else if (params.data.secondaryAssignment && params.data.secondaryAssignment.noOfUnits > 0) {
                params.data.secondaryNoOfUnits = params.data.secondaryAssignment.noOfUnits;
                return params.data.secondaryAssignment.noOfUnits;
            } else if (params.data.secondaryNoOfUnits && params.data.secondaryCostingType.code === CostingType.PERSON) {
                return (params.data.adultCount + params.data.teenCount + params.data.childrenCount +
                        params.data.infantCount) + ' - (' +
                    (params.data.adultCount + params.data.teenCount) + ' ' + params.data.childrenCount + ' ' +
                    params.data.infantCount +
                    ')';
            }
        }
        return this.EMPTY_STRING;
    };
    onSetCostingType = (params) => {
        const item = params.data;
        const costingType = params.newValue;
        if (costingType) {
            item.noOfUnits = item.costingType && costingType.code !== item.costingType.code ? 0 : item.noOfUnits;
            const assignmentIds = [];
            if (item.primaryAssignment && item.primaryAssignment.assignmentId) {
                assignmentIds.push(item.primaryAssignment.assignmentId);
            }
            item.costingType = costingType.obj;
            if (assignmentIds.length > 0) {
                const assignmentPatch = new AssignmentPatch();
                assignmentPatch.costingType = costingType.code;
                this.reservationV2ManagementService.patchAssignmentsData(assignmentIds, assignmentPatch)
                    .subscribe(res => {
                            console.log(res);
                            this.common.showSnackBar(
                                'Successfully updated the job costing Unit',
                                3000,
                                TcErrorType.TYPE.INFO
                            );
                        }, error => {
                            console.log(error);
                            this.common.showSnackBar(
                                'Failed to update job costing Unit',
                                3000,
                                TcErrorType.TYPE.ERROR
                            );
                        }
                    );
                if (item && item.noOfUnits > 1 && item.costingType &&
                    (item.costingType.code === CostingType.UNIT || item.costingType.code === CostingType.PERSON)) {
                    let selectedItems: (ServiceItem | BookingItem)[] = this.getSelectedItems();
                    if (selectedItems.length < 2) { // no selected items only consider "row"
                        selectedItems = [item];
                    }
                    this.removeResourceFromItems(selectedItems, true, false);
                    this.selectItems(selectedItems);
                    this.markChanges();
                }
            }
        }
        if (item && !item.costingType) {
            item.noOfUnits = 0;
            this.refreshGridView();
        }
    };
    onSetSecondaryCostingType = (params) => {
        const item = params.data;
        const costingType = params.newValue;
        if (costingType) {
            item.secondaryNoOfUnits = item.secondaryCostingType && costingType.code !== item.secondaryCostingType.code ?
                0 :
                item.secondaryNoOfUnits;
            const assignmentIds = [];
            if (item.secondaryAssignment && item.secondaryAssignment.assignmentId) {
                assignmentIds.push(item.secondaryAssignment.assignmentId);
            }
            item.secondaryCostingType = costingType.obj;
            if (assignmentIds.length > 0) {
                const assignmentPatch = new AssignmentPatch();
                assignmentPatch.costingType = costingType.code;
                this.reservationV2ManagementService.patchAssignmentsData(assignmentIds, assignmentPatch)
                    .subscribe(res => {
                            console.log(res);
                            this.common.showSnackBar(
                                'Successfully updated the Secondary job costing Unit',
                                3000,
                                TcErrorType.TYPE.INFO
                            );
                        }, error => {
                            console.log(error);
                            this.common.showSnackBar(
                                'Failed to update the Secondary job costing Unit',
                                3000,
                                TcErrorType.TYPE.ERROR
                            );
                        }
                    );
                if (item && item.secondaryNoOfUnits > 1 && item.secondaryCostingType &&
                    (item.secondaryCostingType.code === CostingType.UNIT || item.secondaryCostingType.code ===
                        CostingType.PERSON)) {
                    let selectedItems: (ServiceItem | BookingItem)[] = this.getSelectedItems();
                    if (selectedItems.length < 2) { // no selected items only consider "row"
                        selectedItems = [item];
                    }
                    this.removeResourceFromSecondaryItems(selectedItems, false, true);
                    this.selectItems(selectedItems);
                    this.markChanges();
                }
            }
        }
        if (item && !item.secondaryCostingType) {
            item.secondaryNoOfUnits = 0;
            this.refreshGridView();
        }
    };
    onSetNoOfUnits = (params) => {
        const item = params.data;
        const noOfUnits = params.newValue;
        if (noOfUnits) {
            item.noOfUnits = noOfUnits;
            this.updateCostingUnits(item);
        }
        if (params.newValue > 0 || params.newValue === '') {
            if (typeof params.newValue === 'string' || params.newValue instanceof String) {
                params.newValue = parseFloat(params.newValue);
            }
            _.set(params.data, params.colDef.field, params.newValue); // calling lodash deep property value set
        } else {
            params.newValue = 0;
            _.set(params.data, params.colDef.field, params.newValue); // calling lodash deep property value set
        }
        return true;
    };
    onSetSecondaryNoOfUnits = (params) => {
        const item = params.data;
        const noOfUnits = params.newValue;
        if (noOfUnits) {
            item.secondaryNoOfUnits = noOfUnits;
            this.updateSecondaryCostingUnits(item);
        }
        if (params.newValue > 0 || params.newValue === '') {
            if (typeof params.newValue === 'string' || params.newValue instanceof String) {
                params.newValue = parseFloat(params.newValue);
            }
            _.set(params.data, params.colDef.field, params.newValue); // calling lodash deep property value set
        } else {
            params.newValue = 0;
            _.set(params.data, params.colDef.field, params.newValue); // calling lodash deep property value set
        }
        return true;
    };
    isNoOfUnitsEditable = (item: ServiceItemGridParam | BookingItemGridParam) => {
        return this.isEditableItem(item) && item.data && item.data.costingType && item.data.costingType.code !==
            CostingType.PERSON &&
            (!item.data.primaryAssignment ||
                (item.data.primaryAssignment && this.isEditableAssignStatus(item.data.primaryAssignment)));
    };
    isSecondaryNoOfUnitsEditable = (item: ServiceItemGridParam | BookingItemGridParam) => {
        return this.isEditableItem(item) && item.data && item.data.secondaryCostingType &&
            item.data.secondaryCostingType.code !== CostingType.PERSON &&
            (!item.data.secondaryAssignment ||
                (item.data.secondaryAssignment && this.isEditableAssignStatus(item.data.secondaryAssignment)));
    };
    isPrimaryServiceTypeEditable = (item: ServiceItemGridParam | BookingItemGridParam) => {
        return this.isEditableItem(item) && item.data && (!item.data.primaryAssignment ||
            (item.data.primaryAssignment && this.isEditableAssignStatus(item.data.primaryAssignment)));
    };
    isSecondaryServiceTypeEditable = (item: ServiceItemGridParam | BookingItemGridParam) => {
        return this.isEditableItem(item) && item.data && (!item.data.secondaryAssignment ||
            (item.data.secondaryAssignment && this.isEditableAssignStatus(item.data.secondaryAssignment)));
    };
    getServiceBookingQueuePrimaryServiceType = (params: BookingItemGridParam | ServiceItemGridParam) => {
        if (params.data && params.data.primaryAssignment) {
            if (params.data.primaryAssignment.trsResourceCost) {
                return 'Vehicle';
            } else if (params.data.primaryAssignment.genResourceCost) {
                const resourceType = this.resourceTypes.find(rt => rt.code ===
                    params.data.primaryAssignment.genResourceCost.resourceType);
                return resourceType ? resourceType.name : params.data.primaryAssignment.genResourceCost.resourceType;
            }
        } else if (params.data && params.data.primaryServiceType) {
            return params.data.primaryServiceType.name;
        } else if (params.data && params.data.genBookingItem && params.data.genBookingItem.productName) {
            return params.data.genBookingItem.productName;
        } else if (params.data && params.data.trsBookingItem) {
            return 'Vehicle';
        } else {
            return this.EMPTY_STRING;
        }
    };
    getServiceBookingQueueSecondaryServiceType = (params: BookingItemGridParam | ServiceItemGridParam) => {
        if (params.data && params.data.secondaryAssignment) {
            if (params.data.secondaryAssignment.trsResourceCost) {
                return 'Driver';
            } else if (params.data.secondaryAssignment.genResourceCost) {
                const resourceType = this.resourceTypes.find(rt => rt.code ===
                    params.data.secondaryAssignment.genResourceCost.resourceType);
                return resourceType ? resourceType.name : params.data.secondaryAssignment.genResourceCost.resourceType;
            }
        } else if (params.data && params.data.secondaryServiceType) {
            return params.data.secondaryServiceType.name;
        } else if (params.data.trsBookingItem) {
            return 'Driver';
        } else {
            return this.EMPTY_STRING;
        }
    };
    displaySupplierItemInEditor = (params) => {
        return params ? params.name : '';
    };
    getProductQueueLanguageName = (params) => {
        if (params.data && params.data.languages && params.data.languages.length) {
            if (this.languages) {
                const languages = [];
                params.data.languages.forEach(lang => {
                    const lan = this.languages.find(value => value.code === lang);
                    if (lan && lan.name) {
                        languages.push(lan.name);
                    }
                });
                return languages.join();
            } else {
                return params.data.languages.join();
            }
        } else {
            return this.NOT_AVAILABLE_STRING;
        }
    };
    getPrimarySupplierItems = (params: ICellEditorParams, row: ServiceItem | BookingItem) => {
        if (row && row.primaryAssignment && row.primaryAssignment.supplierObj &&
            row.primaryAssignment.supplierObj.genResourceCostSummaries) {
            return row.primaryAssignment.supplierObj.genResourceCostSummaries.map(i => {
                const resourceType = this.resourceTypes.find(rt => rt.code === i.resourceType);
                return {
                    name: resourceType ? resourceType.name : i.resourceType,
                    code: i.resourceType,
                    obj: i
                };
            });
        } else {
            const serviceTypes = [];
            this.resourceTypes.forEach(type => {
                const serviceType = {
                    name: type ? type.name : '',
                    code: type ? type.code : '',
                    obj: type
                };
                serviceTypes.push(serviceType);
            });
            return serviceTypes;
        }
    };
    getSecondarySupplierItems = (params: ICellEditorParams, row: ServiceItem | BookingItem) => {
        if (row && row.secondaryAssignment && row.secondaryAssignment.supplierObj &&
            row.secondaryAssignment.supplierObj.genResourceCostSummaries) {
            return row.secondaryAssignment.supplierObj.genResourceCostSummaries.map(i => {
                const resourceType = this.resourceTypes.find(rt => rt.code === i.resourceType);
                return {
                    name: resourceType ? resourceType.name : i.resourceType,
                    code: i.resourceType,
                    obj: i
                };
            });
        } else {
            const serviceTypes = [];
            this.resourceTypes.forEach(type => {
                const serviceType = {
                    name: type ? type.name : '',
                    code: type ? type.code : '',
                    obj: type
                };
                serviceTypes.push(serviceType);
            });
            return serviceTypes;
        }
    };
    onSetPrimarySupplierItem = (params) => {
        const supplierItem = params.newValue;
        if (supplierItem) {
            const costLine: ResourceCost = supplierItem.obj;
            const item: ServiceItem | BookingItem = params.data;
            item.primaryServiceType = params.newValue;
            if (item.primaryAssignment) {
                item.primaryAssignment.genResourceCost = costLine as GenResourceCost;
                this.fixAssignmentCostAndCountsForNewPassengerCount(item.primaryAssignment, true, false);
            }

            const bookingIds = [];
            if (this.isBookingQueue()) {
                const bookingItem: BookingItem = params.data;
                bookingIds.push(bookingItem.id);
                this.selectItems([bookingItem]);
            } else {
                const serviceItem: ServiceItem = params.data;
                if (serviceItem.bookingItemSummaryList != null) {
                    for (const bookingItemSummary of serviceItem.bookingItemSummaryList) {
                        bookingIds.push(bookingItemSummary.bookingItemId);
                    }
                }
                this.selectItems([serviceItem]);
            }
            const bookingItemPatch = new BookingItemPatch();
            bookingItemPatch.primaryServiceTypeCode = supplierItem.code;
            bookingItemPatch.primaryServiceTypeName = supplierItem.name;
            this.reservationV2ManagementService.patchBookingItems(bookingIds, bookingItemPatch)
                .subscribe(res => {
                        console.log(res);
                        this.common.showSnackBar(
                            'Changed service type in booking item/items',
                            3000,
                            TcErrorType.TYPE.INFO
                        );
                    }, error => {
                        console.log(error);
                        this.common.showSnackBar(
                            'Failed to change service type in booking item/items',
                            3000,
                            TcErrorType.TYPE.ERROR
                        );
                    }
                );
            this.selectItems([item]);
        }
    };
    onSetSecondarySupplierItem = (params) => {
        const supplierItem = params.newValue;
        const costLine: ResourceCost = supplierItem.obj;
        const item: ServiceItem | BookingItem = params.data;
        item.secondaryServiceType = params.newValue;
        if (item.secondaryAssignment) {
            item.secondaryAssignment.genResourceCost = costLine as GenResourceCost;
            this.fixAssignmentCostAndCountsForNewPassengerCount(item.secondaryAssignment, false, true);
        }
        this.selectItems([item]);
    };
    getPrimarySuppliers = (params: ICellEditorParams, row: ServiceItem | BookingItem, text: string) => {
        return this.getSuppliers(row, text, true, false);
    };
    getSecondarySuppliers = (params: ICellEditorParams, row: ServiceItem | BookingItem, text: string) => {
        return this.getSuppliers(row, text, false, true);
    };
    getPrimaryResources = (params: ICellEditorParams, row: ServiceItem | BookingItem, text: string) => {
        return this.getResources(params, row, text, true, false);
    };
    getSecondaryResources = (params: ICellEditorParams, row: ServiceItem | BookingItem, text: string) => {
        return this.getResources(params, row, text, false, true);
    };
    displaySupplierNameInEditor = (params: SupplierAllocationSummary) => {
        return params ? params.supplierName : '';
    };
    displayResourceNameInEditor = (params: ResourceAllocationSummary) => {
        return params ? params.resourceName : '';
    };
    getResourceStatus = (params: ResourceAllocationSummary) => {
        return params ? params.onlineStatus : false;
    };
    onSetPrimarySupplier = (params) => {
        if (params && params.node && params.node.selected) {
            const supplierSelecting: SupplierAllocationSummary = params.newValue;
            if (supplierSelecting) {
                const item: ServiceItem | BookingItem = params.data;

                let selectedItems: (ServiceItem | BookingItem)[] = this.getSelectedItems();
                if (selectedItems.length < 2) { // no selected items only consider "row"
                    selectedItems = [item];
                }

                if (supplierSelecting.nullItemForClearing) {
                    this.removeAssignmentFromItems(selectedItems, true, false);
                } else {
                    const matchedItems = [];
                    const nonMatchedItems = [];
                    if (selectedItems) {
                        {
                            selectedItems.forEach(item => {
                                this.getSuppliersExplicitly(item, '', true, false);
                                const promise = new Promise(resolve => {
                                    this.reservationV2ManagementService.searchSuppliersForAllocation(this.supplierSearchCriteria)
                                        .subscribe(
                                            response => {
                                                const suppliers = ResponseUtil.getDataArray(response);
                                                const clearSupplier = new SupplierAllocationSummary();
                                                clearSupplier.supplierName = '-- CLEAR --';
                                                clearSupplier.nullItemForClearing = true;
                                                let matched = false;
                                                if (!suppliers.find(value => value.supplierName ===
                                                    clearSupplier.supplierName)) {
                                                    suppliers.unshift(clearSupplier);
                                                }
                                                if (suppliers.find(tmp => tmp.supplierCode ===
                                                    supplierSelecting.supplierCode)) {
                                                    matchedItems.push(item);
                                                    matched = true;
                                                }
                                                if (!matched) {
                                                    nonMatchedItems.push(item);
                                                }
                                                resolve(true);
                                            },
                                            error => {
                                            }
                                        );
                                });
                                promise.then(value => {
                                    this.removeAssignmentFromItems(nonMatchedItems, true, false);
                                    this.setSupplierToItems(
                                        matchedItems,
                                        new AssignmentRawData(),
                                        supplierSelecting,
                                        true,
                                        false
                                    );
                                    this.selectItems(selectedItems);
                                    this.refreshGridView();
                                });
                            });
                        }
                        this.selectItems(selectedItems);
                    }
                }
            }
        } else {
            const supplierSelecting: SupplierAllocationSummary = params.newValue;
            if (params && params.data) {
                if (supplierSelecting.nullItemForClearing) {
                    this.removeAssignmentFromItems([params.data], true, false);
                } else {
                    this.removeAssignmentFromItems([params.data], true, false);
                    this.setSupplierToItems(
                        [params.data],
                        new AssignmentRawData(),
                        supplierSelecting,
                        true,
                        false
                    );
                    this.selectItems([params.data]);
                }
            }
        }
    };

    onSetSecondarySupplier = (params) => {
        if (params && params.node && params.node.selected) {
            const supplierSelecting: SupplierAllocationSummary = params.newValue;
            if (supplierSelecting) {
                const item: ServiceItem | BookingItem = params.data;

                let selectedItems: (ServiceItem | BookingItem)[] = this.getSelectedItems();
                if (selectedItems.length < 2) { // no selected items only consider "row"
                    selectedItems = [item];
                }

                if (supplierSelecting.nullItemForClearing) {
                    this.removeAssignmentFromItems(selectedItems, false, true);
                } else {
                    const matchedItems = [];
                    const nonMatchedItems = [];
                    if (selectedItems) {
                        {
                            selectedItems.forEach(item => {
                                this.getSuppliersExplicitly(item, '', true, false);
                                const promise = new Promise(resolve => {
                                    this.reservationV2ManagementService.searchSuppliersForAllocation(this.supplierSearchCriteria)
                                        .subscribe(
                                            response => {
                                                const suppliers = ResponseUtil.getDataArray(response);
                                                const clearSupplier = new SupplierAllocationSummary();
                                                clearSupplier.supplierName = '-- CLEAR --';
                                                clearSupplier.nullItemForClearing = true;
                                                let matched = false;
                                                if (!suppliers.find(value => value.supplierName ===
                                                    clearSupplier.supplierName)) {
                                                    suppliers.unshift(clearSupplier);
                                                }
                                                if (suppliers.find(tmp => tmp.supplierCode ===
                                                    supplierSelecting.supplierCode)) {
                                                    matchedItems.push(item);
                                                    matched = true;
                                                }
                                                if (!matched) {
                                                    nonMatchedItems.push(item);
                                                }
                                                resolve(true);
                                            },
                                            error => {
                                            }
                                        );
                                });
                                promise.then(value => {
                                    this.removeAssignmentFromItems(nonMatchedItems, false, true);
                                    this.setSupplierToItems(
                                        matchedItems,
                                        new AssignmentRawData(),
                                        supplierSelecting,
                                        false,
                                        true
                                    );
                                    this.selectItems(selectedItems);
                                    this.refreshGridView();
                                });
                            });
                        }
                        this.selectItems(selectedItems);
                    }
                }
            }
        } else {
            const supplierSelecting: SupplierAllocationSummary = params.newValue;
            if (params && params.data) {
                if (supplierSelecting.nullItemForClearing) {
                    this.removeAssignmentFromItems([params.data], false, true);
                } else {
                    this.removeAssignmentFromItems([params.data], false, true);
                    this.setSupplierToItems(
                        [params.data],
                        new AssignmentRawData(),
                        supplierSelecting,
                        false,
                        true
                    );
                    this.selectItems([params.data]);
                }
            }
        }
    };
    onSetPrimaryResource = (params) => {
        if (params && params.node && params.node.selected) {
            const resourceSelecting: ResourceAllocationSummary = params.newValue;
            if (resourceSelecting) {
                const item: ServiceItem | BookingItem = params.data;

                let selectedItems: (ServiceItem | BookingItem)[] = this.getSelectedItems();
                if (selectedItems.length < 2) { // no selected items only consider "row"
                    selectedItems = [item];
                }

                if (resourceSelecting.nullItemForClearing) {
                    this.removeResourceFromItems(selectedItems, true, false);
                } else {
                    const matchedItems = [];
                    const nonMatchedItems = [];
                    if (selectedItems) {
                        {
                            selectedItems.forEach(item => {
                                this.getResourcesExplicitly(item, '', true, false);
                                const promise = new Promise(resolve => {
                                    this.reservationV2ManagementService.searchResourcesForAllocation(this.resourceSearchCriteria)
                                        .subscribe(
                                            response => {
                                                const resources = ResponseUtil.getDataArray(response);
                                                const clearResource = new ResourceAllocationSummary();
                                                clearResource.resourceName = '-- CLEAR --';
                                                clearResource.nullItemForClearing = true;
                                                let matched = false;
                                                if (!resources.find(value => value.resourceName ===
                                                    clearResource.resourceName)) {
                                                    resources.unshift(clearResource);
                                                }
                                                if (resources.find(tmp => tmp.resourceId ===
                                                    resourceSelecting.resourceId)) {
                                                    matchedItems.push(item);
                                                    matched = true;
                                                }
                                                if (!matched) {
                                                    nonMatchedItems.push(item);
                                                }
                                                resolve(true);
                                            },
                                            error => {
                                            }
                                        );
                                });
                                promise.then(value => {
                                    this.removeAssignmentFromItems(nonMatchedItems, true, false);
                                    const assignment = new AssignmentRawData();
                                    assignment.resourceId = resourceSelecting.resourceId;
                                    assignment.resourceName = resourceSelecting.resourceName;
                                    if (resourceSelecting.capacityExceeded) {
                                        this.common.showSnackBar('Exceeding the capacity', 3000, TcErrorType.TYPE.WARN);
                                    }
                                    this.setSupplierToItems(matchedItems, assignment, resourceSelecting, true, false);
                                    this.selectItems(selectedItems);
                                    this.refreshGridView();
                                });
                            });
                        }
                        this.selectItems(selectedItems);
                    }

                }
                this.selectItems(selectedItems);
            }
        } else {
            const resourceSelecting: ResourceAllocationSummary = params.newValue;
            if (params && params.data) {
                if (resourceSelecting.nullItemForClearing) {
                    this.removeAssignmentFromItems([params.data], true, false);
                } else {
                    this.removeAssignmentFromItems([params.data], true, false);
                    this.setSupplierToItems(
                        [params.data],
                        new AssignmentRawData(),
                        resourceSelecting,
                        true,
                        false
                    );
                    this.selectItems([params.data]);
                }
            }
        }
    };
    onSetSecondaryResource = (params) => {
        if (params && params.node && params.node.selected) {
            const resourceSelecting: ResourceAllocationSummary = params.newValue;
            if (resourceSelecting) {
                const item: ServiceItem | BookingItem = params.data;

                let selectedItems: (ServiceItem | BookingItem)[] = this.getSelectedItems();
                if (selectedItems.length < 2) { // no selected items only consider "row"
                    selectedItems = [item];
                }

                if (resourceSelecting.nullItemForClearing) {
                    this.removeResourceFromSecondaryItems(selectedItems, false, true);
                } else {
                    const matchedItems = [];
                    const nonMatchedItems = [];
                    if (selectedItems) {
                        {
                            selectedItems.forEach(item => {
                                this.getResourcesExplicitly(item, '', true, false);
                                const promise = new Promise(resolve => {
                                    this.reservationV2ManagementService.searchResourcesForAllocation(this.resourceSearchCriteria)
                                        .subscribe(
                                            response => {
                                                const resources = ResponseUtil.getDataArray(response);
                                                const clearResource = new ResourceAllocationSummary();
                                                clearResource.resourceName = '-- CLEAR --';
                                                clearResource.nullItemForClearing = true;
                                                let matched = false;
                                                if (!resources.find(value => value.resourceName ===
                                                    clearResource.resourceName)) {
                                                    resources.unshift(clearResource);
                                                }
                                                if (resources.find(tmp => tmp.resourceId ===
                                                    resourceSelecting.resourceId)) {
                                                    matchedItems.push(item);
                                                    matched = true;
                                                }
                                                if (!matched) {
                                                    nonMatchedItems.push(item);
                                                }
                                                resolve(true);
                                            },
                                            error => {
                                            }
                                        );
                                });
                                promise.then(value => {
                                    this.removeAssignmentFromItems(nonMatchedItems, false, true);
                                    const assignment = new AssignmentRawData();
                                    assignment.resourceId = resourceSelecting.resourceId;
                                    assignment.resourceName = resourceSelecting.resourceName;
                                    if (resourceSelecting.capacityExceeded) {
                                        this.common.showSnackBar('Exceeding the capacity', 3000, TcErrorType.TYPE.WARN);
                                    }
                                    this.setSupplierToItems(matchedItems, assignment, resourceSelecting, false, true);
                                    this.selectItems(selectedItems);
                                    this.refreshGridView();
                                });
                            });
                        }
                        this.selectItems(selectedItems);
                    }
                }
                this.selectItems(selectedItems);
            }
        } else {
            const resourceSelecting: ResourceAllocationSummary = params.newValue;
            if (params && params.data) {
                if (resourceSelecting.nullItemForClearing) {
                    this.removeAssignmentFromItems([params.data], false, true);
                }
            } else {
                this.removeAssignmentFromItems([params.data], false, true);
                this.setSupplierToItems(
                    [params.data],
                    new AssignmentRawData(),
                    resourceSelecting,
                    false,
                    true
                );
                this.selectItems([params.data]);
            }
        }
    };

    private removeResourceFromItems(items: (ServiceItem | BookingItem)[], primary: boolean, secondary: boolean) {
        if (primary) {
            for (const item of items) {
                if (item.primaryAssignment) {
                    item.primaryAssignment.resourceId = undefined;
                    item.primaryAssignment.resourceName = undefined;
                }
            }
        }
    }

    private getSelectedItems(): (BookingItem | ServiceItem)[] {
        let selectedItems: BookingItem[] | ServiceItem[] = [];
        if (this.isBookingQueue() && this.bookingQueueComponent && this.bookingQueueComponent.gridApi) {
            selectedItems = this.bookingQueueComponent.gridApi.getSelectedRows();
        } else if (this.serviceQueueComponent && this.serviceQueueComponent.gridApi) {
            selectedItems = this.serviceQueueComponent.gridApi.getSelectedRows();
        }
        return selectedItems;
    }

    private getSelectedItemsForSave() {
        const selectedItems: (BookingItem | ServiceItem)[] = this.getSelectedItems();
        const assignments: AssignmentRawData[] = [];
        for (const selectedItem of selectedItems) {
            if (selectedItem.primaryAssignment && this.isInSavableState(selectedItem.primaryAssignment)) {
                selectedItem.primaryAssignment.startTime = selectedItem.startTime;
                selectedItem.primaryAssignment.endTime = selectedItem.endTime;
                selectedItem.primaryAssignment.costingType = selectedItem.costingType && selectedItem.costingType.code ?
                    selectedItem.costingType.code :
                    selectedItem.costingType;
                selectedItem.primaryAssignment.noOfUnits = selectedItem.noOfUnits;
                this.addToListIfNotExist(assignments, selectedItem.primaryAssignment);
            }
            if (selectedItem.secondaryAssignment && this.isInSavableState(selectedItem.secondaryAssignment)) {
                selectedItem.secondaryAssignment.startTime = selectedItem.startTime;
                selectedItem.secondaryAssignment.endTime = selectedItem.endTime;
                selectedItem.secondaryAssignment.costingType = selectedItem.costingType &&
                selectedItem.costingType.code ?
                    selectedItem.costingType.code :
                    selectedItem.costingType;
                selectedItem.secondaryAssignment.noOfUnits = selectedItem.noOfUnits;
                this.addToListIfNotExist(assignments, selectedItem.secondaryAssignment);
                if (selectedItem.primaryAssignment) {
                    this.addToListIfNotExist(assignments, selectedItem.primaryAssignment);
                }
            }
            if (selectedItem.removedPrimaryAssignments) {
                selectedItem.removedPrimaryAssignments.forEach(a => a.removingAssignment = true);
                this.addToListIfNotExistAll(assignments, selectedItem.removedPrimaryAssignments);
            }
            if (selectedItem.removedSecondaryAssignments) {
                selectedItem.removedSecondaryAssignments.forEach(a => a.removingAssignment = true);
                this.addToListIfNotExistAll(assignments, selectedItem.removedSecondaryAssignments);
            }
        }
        return assignments;
    }

    private getSuppliers(row: ServiceItem | BookingItem, text: string, primary: boolean, secondary: boolean) {
        const supplierName = text;
        let privateBooking = false;
        let adultCount = 0;
        let teenCount = 0;
        let childrenCount = 0;
        let infantCount = 0;
        let noOfUnits = 0;
        let selectedItems: (ServiceItem | BookingItem)[] = this.getSelectedItems();
        privateBooking = row.pvtShared === ServiceSharing.PVT;
        if (selectedItems.length < 2) { // no selected items only consider "row"
            selectedItems = [row];
        }
        const serviceDate = row.serviceDate;
        for (const item of selectedItems) {
            adultCount += item.adultCount;
            teenCount += item.teenCount;
            childrenCount += item.childrenCount;
            infantCount += item.infantCount;
            noOfUnits += item.noOfUnits;
        }
        let serviceType = '';
        if (primary && row.primaryServiceType) {
            serviceType = row.primaryServiceType.code;
        } else if (secondary && row.secondaryServiceType && row.secondaryServiceType.code) {
            serviceType = row.secondaryServiceType.code;
        } else {
            serviceType = row.genBookingItem ? row.genBookingItem.productCode : (primary ? 'vehicle' : 'driver');
        }
        const profileType = row.genBookingItem ? row.genBookingItem.typeCode : '';
        // const vehicleModel = row.trsBookingItem ? row.trsBookingItem.vehicleType : '';
        const vehicleModel = 'ANY';
        const route = row.trsBookingItem ? row.trsBookingItem.route : '';
        const transferMode = row.trsBookingItem ? row.trsBookingItem.transferMode : '';
        let serviceStartTime = null;
        let serviceEndTime = null;
        if (row.trsBookingItem) {
            serviceStartTime = row.startTime ? row.startTime : null;
            // moment(new Date(row.startTime)).format('HH:mm') :
            // moment(new Date(row.trsBookingItem.departureDateTime)).format('HH:mm');
            serviceEndTime = row.endTime ? row.endTime : null;
            // moment(new Date(row.endTime)).format('HH:mm') :
            // moment(new Date(row.trsBookingItem.arrivalDateTime)).format('HH:mm');

        } else {
            serviceStartTime = row.startTime ? row.startTime : null;
            // moment(new Date(row.startTime)).format('HH:mm') :
            // moment(new Date(row.serviceDate)).format('HH:mm');
            serviceEndTime = row.endTime ? row.endTime : null;
            // moment(new Date(row.endTime)).format('HH:mm') :
            // moment(new Date(row.serviceDate)).format('HH:mm');
        }
        const bookingIdList = this.getSelectedBookingIds(selectedItems);
        const costingType = row.costingType && row.costingType.code ? row.costingType.code : row.costingType;

        this.supplierSearchCriteria.supplierName = supplierName;
        this.supplierSearchCriteria.serviceType = serviceType;
        this.supplierSearchCriteria.vehicleModel = vehicleModel;
        this.supplierSearchCriteria.route = route;
        this.supplierSearchCriteria.transferMode = transferMode;
        this.supplierSearchCriteria.profileType = profileType;
        this.supplierSearchCriteria.privateBooking = privateBooking;
        this.supplierSearchCriteria.serviceDate = serviceDate;
        this.supplierSearchCriteria.adultCount = adultCount;
        this.supplierSearchCriteria.teenCount = teenCount;
        this.supplierSearchCriteria.childCount = childrenCount;
        this.supplierSearchCriteria.infantCount = infantCount;
        this.supplierSearchCriteria.serviceStartTime = serviceStartTime;
        this.supplierSearchCriteria.serviceEndTime = serviceEndTime;
        this.supplierSearchCriteria.bookingIdList = bookingIdList;
        this.supplierSearchCriteria.costingType = costingType;
        this.supplierSearchCriteria.noOfUnits = noOfUnits;
        if (row.kcCompany) {
            this.supplierSearchCriteria.kcCompany = row.kcCompany;
        }
        if (row.kcBrand) {
            this.supplierSearchCriteria.kcBrand = row.kcBrand;
        }
        if (row.kcDivision) {
            this.supplierSearchCriteria.kcDivision = row.kcDivision;
        }
        if (row.kcDistributionChannel) {
            this.supplierSearchCriteria.kcDistributionChannel = row.kcDistributionChannel;
        }
        return this.reservationV2ManagementService.searchSuppliersForAllocation(this.supplierSearchCriteria)
                   .pipe(
                       map(
                           response => {
                               const suppliers = ResponseUtil.getDataArray(response);
                               const clearSupplier = new SupplierAllocationSummary();
                               clearSupplier.supplierName = '-- CLEAR --';
                               clearSupplier.nullItemForClearing = true;
                               if (!suppliers.find(value => value.supplierName === clearSupplier.supplierName)) {
                                   suppliers.unshift(clearSupplier);
                               }
                               return suppliers;
                           }
                       ), catchError(
                           error => {
                               return [];
                           }
                       )
                   );
    }

    private getSuppliersExplicitly(row: ServiceItem | BookingItem, text: string, primary: boolean, secondary: boolean) {
        const supplierName = text;
        let privateBooking = false;
        let adultCount = 0;
        let teenCount = 0;
        let childrenCount = 0;
        let infantCount = 0;
        let noOfUnits = 0;
        let selectedItems: (ServiceItem | BookingItem)[] = this.getSelectedItems();
        privateBooking = row.pvtShared === ServiceSharing.PVT;
        if (selectedItems.length < 2) { // no selected items only consider "row"
            selectedItems = [row];
        }
        const serviceDate = row.serviceDate;
        for (const item of selectedItems) {
            adultCount += item.adultCount;
            teenCount += item.teenCount;
            childrenCount += item.childrenCount;
            infantCount += item.infantCount;
            noOfUnits += item.noOfUnits;
        }
        let serviceType = '';
        if (primary && row.primaryServiceType) {
            serviceType = row.primaryServiceType.code;
        } else if (secondary && row.secondaryServiceType && row.secondaryServiceType.code) {
            serviceType = row.secondaryServiceType.code;
        } else {
            serviceType = row.genBookingItem ? row.genBookingItem.productCode : (primary ? 'vehicle' : 'driver');
        }
        const profileType = row.genBookingItem ? row.genBookingItem.typeCode : '';
        // const vehicleModel = row.trsBookingItem ? row.trsBookingItem.vehicleType : '';
        const vehicleModel = 'ANY';
        const route = row.trsBookingItem ? row.trsBookingItem.route : '';
        const transferMode = row.trsBookingItem ? row.trsBookingItem.transferMode : '';
        let serviceStartTime = null;
        let serviceEndTime = null;
        if (row.trsBookingItem) {
            serviceStartTime = row.startTime ? row.startTime : null;
            // moment(new Date(row.startTime)).format('HH:mm') :
            // moment(new Date(row.trsBookingItem.departureDateTime)).format('HH:mm');
            serviceEndTime = row.endTime ? row.endTime : null;
            // moment(new Date(row.endTime)).format('HH:mm') :
            // moment(new Date(row.trsBookingItem.arrivalDateTime)).format('HH:mm');

        } else {
            serviceStartTime = row.startTime ? row.startTime : null;
            // moment(new Date(row.startTime)).format('HH:mm') :
            // moment(new Date(row.serviceDate)).format('HH:mm');
            serviceEndTime = row.endTime ? row.endTime : null;
            // moment(new Date(row.endTime)).format('HH:mm') :
            // moment(new Date(row.serviceDate)).format('HH:mm');
        }
        const bookingIdList = this.getSelectedBookingIds(selectedItems);
        const costingType = row.costingType && row.costingType.code ? row.costingType.code : row.costingType;
        if (row.kcCompany) {
            this.supplierSearchCriteria.kcCompany = row.kcCompany;
        }
        if (row.kcBrand) {
            this.supplierSearchCriteria.kcBrand = row.kcBrand;
        }
        if (row.kcDivision) {
            this.supplierSearchCriteria.kcDivision = row.kcDivision;
        }
        if (row.kcDistributionChannel) {
            this.supplierSearchCriteria.kcDistributionChannel = row.kcDistributionChannel;
        }
        this.supplierSearchCriteria.supplierName = supplierName;
        this.supplierSearchCriteria.serviceType = serviceType;
        this.supplierSearchCriteria.vehicleModel = vehicleModel;
        this.supplierSearchCriteria.route = route;
        this.supplierSearchCriteria.transferMode = transferMode;
        this.supplierSearchCriteria.profileType = profileType;
        this.supplierSearchCriteria.privateBooking = privateBooking;
        this.supplierSearchCriteria.serviceDate = serviceDate;
        this.supplierSearchCriteria.adultCount = adultCount;
        this.supplierSearchCriteria.teenCount = teenCount;
        this.supplierSearchCriteria.childCount = childrenCount;
        this.supplierSearchCriteria.infantCount = infantCount;
        this.supplierSearchCriteria.serviceStartTime = serviceStartTime;
        this.supplierSearchCriteria.serviceEndTime = serviceEndTime;
        this.supplierSearchCriteria.bookingIdList = bookingIdList;
        this.supplierSearchCriteria.costingType = costingType;
        this.supplierSearchCriteria.noOfUnits = noOfUnits;
        return this.supplierSearchCriteria;
    }

    private getResources(
        params: ICellEditorParams, row: ServiceItem | BookingItem, text: string, primary: boolean, secondary: boolean) {
        const resourceName = text;
        let privateBooking = false;
        let adultCount = 0;
        let teenCount = 0;
        let childrenCount = 0;
        let infantCount = 0;
        let noOfUnits = 0;
        let selectedItems: (ServiceItem | BookingItem)[] = this.getSelectedItems();
        privateBooking = row.pvtShared === ServiceSharing.PVT;
        if (selectedItems.length < 2) { // no selected items only consider "row"
            selectedItems = [row];
        }
        const serviceDate = row.serviceDate;
        for (const item of selectedItems) {
            adultCount += item.adultCount;
            teenCount += item.teenCount;
            childrenCount += item.childrenCount;
            infantCount += item.infantCount;
            noOfUnits += item.noOfUnits;
        }
        let serviceType = row.genBookingItem ? row.genBookingItem.productCode : (primary ? 'vehicle' : 'driver');
        if (primary && row.primaryServiceType) {
            serviceType = row.primaryServiceType.code ? row.primaryServiceType.code : serviceType;
        } else if (secondary && row.secondaryServiceType) {
            serviceType = row.secondaryServiceType.code ? row.secondaryServiceType.code : serviceType;
        }
        const profileType = row.genBookingItem ? row.genBookingItem.typeCode : '';
        const vehicleModel = 'ANY';
        const route = row.trsBookingItem ? row.trsBookingItem.route : '';
        const transferMode = row.trsBookingItem ? row.trsBookingItem.transferMode : '';

        let serviceStartTime = null;
        let serviceEndTime = null;
        if (row.trsBookingItem) {
            serviceStartTime = row.startTime ? row.startTime : null;
            // moment(new Date(row.startTime)).format('HH:mm') :
            // moment(new Date(row.trsBookingItem.departureDateTime)).format('HH:mm');
            serviceEndTime = row.endTime ? row.endTime : null;
            // moment(new Date(row.endTime)).format('HH:mm') :
            // moment(new Date(row.trsBookingItem.arrivalDateTime)).format('HH:mm');

        } else {
            serviceStartTime = row.startTime ? row.startTime : null;
            // moment(new Date(row.startTime)).format('HH:mm') :
            // moment(new Date(row.serviceDate)).format('HH:mm');
            serviceEndTime = row.endTime ? row.endTime : null;
            // moment(new Date(row.endTime)).format('HH:mm') :
            // moment(new Date(row.serviceDate)).format('HH:mm');

        }

        const bookingIdList = this.getSelectedBookingIds(selectedItems);

        let supplierId = -1;
        if (primary && row.primaryAssignment) {
            supplierId = row.primaryAssignment.supplierId;
            serviceType = row.primaryAssignment.resourceType;
        } else if (secondary && row.secondaryAssignment) {
            supplierId = row.secondaryAssignment.supplierId;
            serviceType = row.secondaryAssignment.resourceType;
        }
        const costingType = row.costingType && row.costingType.code ? row.costingType.code : row.costingType;

        this.resourceSearchCriteria.supplierId = supplierId;
        this.resourceSearchCriteria.resourceName = resourceName;
        this.resourceSearchCriteria.serviceType = serviceType;
        this.resourceSearchCriteria.vehicleModel = vehicleModel;
        this.resourceSearchCriteria.route = route;
        this.resourceSearchCriteria.transferMode = transferMode;
        this.resourceSearchCriteria.profileType = profileType;
        this.resourceSearchCriteria.privateBooking = privateBooking;
        this.resourceSearchCriteria.serviceDate = serviceDate;
        this.resourceSearchCriteria.adultCount = adultCount;
        this.resourceSearchCriteria.teenCount = teenCount;
        this.resourceSearchCriteria.childCount = childrenCount;
        this.resourceSearchCriteria.infantCount = infantCount;
        this.resourceSearchCriteria.serviceStartTime = serviceStartTime;
        this.resourceSearchCriteria.serviceEndTime = serviceEndTime;
        this.resourceSearchCriteria.bookingIdList = bookingIdList;
        this.resourceSearchCriteria.costingType = costingType;
        this.resourceSearchCriteria.noOfUnits = noOfUnits;
        if (row.kcCompany) {
            this.resourceSearchCriteria.kcCompany = row.kcCompany;
        }
        if (row.kcBrand) {
            this.resourceSearchCriteria.kcBrand = row.kcBrand;
        }
        if (row.kcDivision) {
            this.resourceSearchCriteria.kcDivision = row.kcDivision;
        }
        if (row.kcDistributionChannel) {
            this.resourceSearchCriteria.kcDistributionChannel = row.kcDistributionChannel;
        }
        return this.reservationV2ManagementService.searchResourcesForAllocation(this.resourceSearchCriteria)
                   .pipe(
                       map(
                           response => {
                               const resources = ResponseUtil.getDataArray(response);
                               const clearResource = new ResourceAllocationSummary();
                               clearResource.resourceName = '-- CLEAR --';
                               clearResource.nullItemForClearing = true;
                               if (!resources.find(value => value.resourceName === clearResource.resourceName)) {
                                   resources.unshift(clearResource);
                               }
                               return resources;
                           }
                       ), catchError(
                           error => {
                               return [];
                           }
                       )
                   );
    }

    private getResourcesExplicitly(row: ServiceItem | BookingItem, text: string, primary: boolean, secondary: boolean) {
        const resourceName = text;
        let privateBooking = false;
        let adultCount = 0;
        let teenCount = 0;
        let childrenCount = 0;
        let infantCount = 0;
        let noOfUnits = 0;
        let selectedItems: (ServiceItem | BookingItem)[] = this.getSelectedItems();
        privateBooking = row.pvtShared === ServiceSharing.PVT;
        if (selectedItems.length < 2) { // no selected items only consider "row"
            selectedItems = [row];
        }
        const serviceDate = row.serviceDate;
        for (const item of selectedItems) {
            adultCount += item.adultCount;
            teenCount += item.teenCount;
            childrenCount += item.childrenCount;
            infantCount += item.infantCount;
            noOfUnits += item.noOfUnits;
        }
        let serviceType = row.genBookingItem ? row.genBookingItem.productCode : (primary ? 'vehicle' : 'driver');
        if (primary && row.primaryServiceType) {
            serviceType = row.primaryServiceType.code ? row.primaryServiceType.code : serviceType;
        } else if (secondary && row.secondaryServiceType) {
            serviceType = row.secondaryServiceType.code ? row.secondaryServiceType.code : serviceType;
        }
        const profileType = row.genBookingItem ? row.genBookingItem.typeCode : '';
        const vehicleModel = 'ANY';
        const route = row.trsBookingItem ? row.trsBookingItem.route : '';
        const transferMode = row.trsBookingItem ? row.trsBookingItem.transferMode : '';

        let serviceStartTime = null;
        let serviceEndTime = null;
        if (row.trsBookingItem) {
            serviceStartTime = row.startTime ? row.startTime : null;
            // moment(new Date(row.startTime)).format('HH:mm') :
            // moment(new Date(row.trsBookingItem.departureDateTime)).format('HH:mm');
            serviceEndTime = row.endTime ? row.endTime : null;
            // moment(new Date(row.endTime)).format('HH:mm') :
            // moment(new Date(row.trsBookingItem.arrivalDateTime)).format('HH:mm');

        } else {
            serviceStartTime = row.startTime ? row.startTime : null;
            // moment(new Date(row.startTime)).format('HH:mm') :
            // moment(new Date(row.serviceDate)).format('HH:mm');
            serviceEndTime = row.endTime ? row.endTime : null;
            // moment(new Date(row.endTime)).format('HH:mm') :
            // moment(new Date(row.serviceDate)).format('HH:mm');

        }

        const bookingIdList = this.getSelectedBookingIds(selectedItems);

        let supplierId = -1;
        if (primary && row.primaryAssignment) {
            supplierId = row.primaryAssignment.supplierId;
            serviceType = row.primaryAssignment.resourceType;
        } else if (secondary && row.secondaryAssignment) {
            supplierId = row.secondaryAssignment.supplierId;
            serviceType = row.secondaryAssignment.resourceType;
        }
        const costingType = row.costingType && row.costingType.code ? row.costingType.code : row.costingType;
        this.resourceSearchCriteria.kcCompany = row.kcCompany;
        this.resourceSearchCriteria.kcBrand = row.kcBrand;
        this.resourceSearchCriteria.kcDivision = row.kcDivision;
        this.resourceSearchCriteria.kcDistributionChannel = row.kcDistributionChannel;
        this.resourceSearchCriteria.supplierId = supplierId;
        this.resourceSearchCriteria.resourceName = resourceName;
        this.resourceSearchCriteria.serviceType = serviceType;
        this.resourceSearchCriteria.vehicleModel = vehicleModel;
        this.resourceSearchCriteria.route = route;
        this.resourceSearchCriteria.transferMode = transferMode;
        this.resourceSearchCriteria.profileType = profileType;
        this.resourceSearchCriteria.privateBooking = privateBooking;
        this.resourceSearchCriteria.serviceDate = serviceDate;
        this.resourceSearchCriteria.adultCount = adultCount;
        this.resourceSearchCriteria.teenCount = teenCount;
        this.resourceSearchCriteria.childCount = childrenCount;
        this.resourceSearchCriteria.infantCount = infantCount;
        this.resourceSearchCriteria.serviceStartTime = serviceStartTime;
        this.resourceSearchCriteria.serviceEndTime = serviceEndTime;
        this.resourceSearchCriteria.bookingIdList = bookingIdList;
        this.resourceSearchCriteria.costingType = costingType;
        this.resourceSearchCriteria.noOfUnits = noOfUnits;
        if (row.kcCompany) {
            this.resourceSearchCriteria.kcCompany = row.kcCompany;
        }
        if (row.kcBrand) {
            this.resourceSearchCriteria.kcBrand = row.kcBrand;
        }
        if (row.kcDivision) {
            this.resourceSearchCriteria.kcDivision = row.kcDivision;
        }
        if (row.kcDistributionChannel) {
            this.resourceSearchCriteria.kcDistributionChannel = row.kcDistributionChannel;
        }
    }

    /**
     * ends ag-grid value-setter
     */

    private getSelectedItemsForSupplierReject() {
        return [];
    }

    private getSelectedItemsForSupplierConfirm() {
        return [];
    }

    private isInSavableState(assignment: AssignmentRawData) {
        return assignment && (
            assignment.assignStatus === AssignStatus.ASSIGNED ||
            assignment.assignStatus === AssignStatus.PENDING
        );
    }

    private isInConfirmableState(assignment: AssignmentRawData) {
        return assignment && (
            assignment.assignStatus === AssignStatus.ASSIGNED ||
            assignment.assignStatus === AssignStatus.PENDING ||
            assignment.assignStatus === AssignStatus.REQUEST_EXPIRED ||
            assignment.assignStatus === AssignStatus.SUPPLIER_REJECTED ||
            assignment.assignStatus === AssignStatus.BOOKING_UPDATE
        );
    }

    private isInSupplierConfirmableState(assignment: AssignmentRawData) {
        return assignment && (
            assignment.assignStatus === AssignStatus.DMC_CONFIRM
        );
    }

    private isInSupplierRejectableState(assignment: AssignmentRawData) {
        return assignment && (
            assignment.assignStatus === AssignStatus.DMC_CONFIRM ||
            assignment.assignStatus === AssignStatus.SUPPLIER_CONFIRMED
        );
    }

    // subscribe allocations
    subscribeAllocationResults() {
        this.resourceAllocationResultsSubscription = this.dataStore.get(DataKey.selectedResourceForAllocation)
                                                         .subscribe((data: ResourceAllocationSummary) => {
                                                             if (data) {
                                                                 this.selectedResource = data;
                                                                 const selectedAssignment = data.existingAssignments.find(
                                                                     value => value.selected);
                                                                 // selectedAssignment.resourceObj = data;
                                                                 selectedAssignment.temporaryAssignmentId = this.rawAssignmentTempId++;
                                                                 this.setAssignmentToItems(
                                                                     this.itemList,
                                                                     selectedAssignment,
                                                                     data.isPrimaryAssignment,
                                                                     data.isSecondaryAssignment,
                                                                     true
                                                                 );
                                                             }
                                                         });
        this.supplierAllocationResultsSubscription = this.dataStore.get(DataKey.selectedSupplierForAllocation)
                                                         .subscribe((data: SupplierAllocationSummary) => {
                                                             if (data) {
                                                                 const selectedAssignment = new AssignmentRawData();
                                                                 // selectedAssignment.supplierObj = data;
                                                                 this.setSupplierToItems(
                                                                     this.itemList,
                                                                     selectedAssignment,
                                                                     data,
                                                                     data.isPrimaryAssignment,
                                                                     data.isSecondaryAssignment,
                                                                     true
                                                                 );
                                                             }
                                                         });
    }

    private subscribeForDateRange(
        params, fromAssignmentClick = false, bookingIdList: any[] = [], isPrimary: boolean = true,
        isSecondary: boolean = false
    ) {
        this.allocationTimeRangeSubscription = this.dataStore.get(DataKey.ResourceAllocationTimeRange)
                                                   .subscribe((data) => {
                                                       if (data != null) {
                                                           this.allocationTimeRangeSubscription.unsubscribe();
                                                           const item: ServiceItem | BookingItem = params.data;
                                                           item.startTime = data.startTime;
                                                           item.endTime = data.endTime;
                                                           const bookingIds = data.bookingIds;
                                                           const bookingItemPatch = new BookingItemPatch();
                                                           bookingItemPatch.serviceStartDateTime = item.startTime;
                                                           bookingItemPatch.serviceEndDateTime = item.endTime;
                                                           this.reservationV2ManagementService.patchBookingItems(
                                                               bookingIds,
                                                               bookingItemPatch
                                                           )
                                                               .subscribe(res => {
                                                                       if (item.costingType && item.costingType.code ===
                                                                           CostingType.DURATION) {
                                                                           item.noOfUnits = this.getDuration(item);
                                                                           this.updateCostingUnits(item);
                                                                       }
                                                                       this.refreshGridView();
                                                                       this.cd.detectChanges();
                                                                       console.log(res);
                                                                       this.common.showSnackBar(
                                                                           'Added start time & end time to booking item/items',
                                                                           3000,
                                                                           TcErrorType.TYPE.INFO
                                                                       );
                                                                   }, error => {
                                                                       console.log(error);
                                                                       this.common.showSnackBar(
                                                                           'Failed to add start time and end time to booking item/items',
                                                                           3000,
                                                                           TcErrorType.TYPE.ERROR
                                                                       );
                                                                   }
                                                               );
                                                           if (fromAssignmentClick) {
                                                               this.openAllocationFocusView(
                                                                   item,
                                                                   bookingIdList,
                                                                   isPrimary,
                                                                   isSecondary
                                                               );
                                                           }
                                                       }
                                                   });
    }

    private getSelectedBookingIds(items: any[]) {
        const bookingIds = [];
        for (const item of items) {
            if (item.bookingItemSummaryList && item.bookingItemSummaryList.length > 0) {
                item.bookingItemSummaryList.forEach(value => bookingIds.push(value.bookingItemId));
            } else {
                bookingIds.push(item.id);
            }
        }
        return bookingIds;
    }

    public selectItems(items: (ServiceItem | BookingItem)[]) {
        if (this.isBookingQueue()) {
            this.bookingQueueComponent.selectRows(items as BookingItem[]);
        } else {
            this.serviceQueueComponent.selectRows(items as ServiceItem[]);
        }
        this.enableConfirmButton();
    }

    private isEditableAssignStatus(primaryAssignment: AssignmentRawData) {
        return true;
    }

    getServiceBookingQueueTotalCost = (params: BookingItemGridParam | ServiceItemGridParam) => {
        let cost = 0;
        if (params.data && params.data.itemCostPortion) {
            cost = params.data.itemCostPortion.totalCost;
        }
        if (cost) {
            return cost.toFixed(2) + ' ' + params.data.itemCostPortion.currency;
        } else {
            return this.EMPTY_STRING;
        }
    };

    private openTotalCostEditor(data) {
        const costLines = [];
        this.dataStore.set(DataKey.assignmentTotalCost, null, true);
        this.createTotalCostLines(data, costLines);
        this.dataStore.set(DataKey.popupClose, null);
        const editableCost = this.isEditableType(data, true);
        const dataObject = new ModalData(
            LEVEL.SUCCESS,
            'Total Cost Editor',
            null,
            null,
            'modal-basic--medium',
            TotalCostEditorComponent,
            {
                rowData: costLines,
                editable: editableCost
            }
        );
        this.subscribeForCostUpdate(data);
        this.confirmModal(dataObject);
        if (this.popupSubscription) {
            this.popupSubscription.unsubscribe();
        }
        this.popupSubscription = this.dataStore.get(DataKey.popupClose).subscribe((dataS) => {
            if (dataS != null) {
                this.closeModal();
                if (dataS) {
                }
            }
        });
    }

    private createTotalCostLines(data: (ServiceItem | BookingItem), costLines: any[]) {
        if (data && data.primaryAssignment && data.itemCostPortion.primaryJobCost) {
            const costData = {
                serviceType: data.primaryAssignment.resourceType,
                currency: data.primaryAssignment.currency,
                type: 'PRIMARY',
                totalCost: data.itemCostPortion.primaryJobCost.totalCost,
                estimatedCost: data.itemCostPortion.primaryJobCost.estimatedCost ?
                    data.itemCostPortion.primaryJobCost.estimatedCost :
                    data.primaryAssignment.estimatedCost
            };
            costLines.push(costData);
        }
        if (data && data.secondaryAssignment && data.itemCostPortion.secondaryJobCost) {
            const costData = {
                serviceType: data.secondaryAssignment.resourceType,
                currency: data.secondaryAssignment.currency,
                type: 'SECONDARY',
                totalCost: data.itemCostPortion.secondaryJobCost.totalCost,
                estimatedCost: data.itemCostPortion.secondaryJobCost.estimatedCost ?
                    data.itemCostPortion.secondaryJobCost.estimatedCost :
                    data.secondaryAssignment.estimatedCost
            };
            costLines.push(costData);
        }
    }

    private subscribeForCostUpdate(item: (BookingItem | ServiceItem)) {
        const subscribeTotalCost = this.dataStore.get(DataKey.assignmentTotalCost).subscribe((data: any[]) => {
            if (data != null && data.length > 0) {
                subscribeTotalCost.unsubscribe();
                let totalCost = 0;
                data.forEach(costData => {
                    let isEditedCost = false;
                    const assignmentIds = [];
                    if (item.itemCostPortion && item.itemCostPortion.primaryJobCost && costData.type === 'PRIMARY' &&
                        item.itemCostPortion.primaryJobCost.totalCost !== costData.totalCost) {
                        item.itemCostPortion.primaryJobCost.totalCost = costData.totalCost;
                        assignmentIds.push(item.primaryAssignment.assignmentId);
                        totalCost += +item.itemCostPortion.primaryJobCost.totalCost;
                        isEditedCost = true;
                    } else if (item.itemCostPortion && item.itemCostPortion.secondaryJobCost && costData.type ===
                        'SECONDARY' &&
                        item.itemCostPortion.secondaryJobCost.totalCost !== costData.totalCost) {
                        item.itemCostPortion.secondaryJobCost.totalCost = costData.totalCost;
                        assignmentIds.push(item.secondaryAssignment.assignmentId);
                        totalCost += +item.itemCostPortion.secondaryJobCost.totalCost;
                        isEditedCost = true;
                    }
                    if (isEditedCost) {
                        isEditedCost = false;
                        this.refreshGridView();
                        if (assignmentIds.length > 0) {
                            const assignmentPatch = new AssignmentPatch();
                            assignmentPatch.jobCost = costData.totalCost;
                            this.reservationV2ManagementService.patchAssignmentsData(assignmentIds, assignmentPatch)
                                .subscribe(res => {
                                        console.log(res);
                                        this.reloadGridData();
                                        this.common.showSnackBar(
                                            'Successfully updated the job cost',
                                            3000,
                                            TcErrorType.TYPE.INFO
                                        );
                                    }, error => {
                                        console.log(error);
                                        this.reloadGridData();
                                        this.common.showSnackBar(
                                            'Failed to update job cost',
                                            3000,
                                            TcErrorType.TYPE.ERROR
                                        );
                                    }
                                );
                        }
                    }

                });
                item.itemCostPortion.totalCost = totalCost > 0 ? totalCost : item.itemCostPortion.totalCost;
            }
        });
    }

    public onSaveCriteriaClick() {
        this.dataStore.set(DataKey.popupClose, null);
        const dataObject = new ModalData(
            LEVEL.SUCCESS,
            'Save Criteria',
            null,
            null,
            'modal-basic--alt modal-basic--fixed-size',
            SearchCriteriaSaveModalComponent,
            {searchCriteriaV2: this.productQueueSearchCriteria}
        );
        dataObject.disableClose = true;

        const secondaryHeader = '';

        dataObject.secondaryHeader = secondaryHeader;
        this.confirmModal(dataObject);

        if (this.popupSubscription) {
            this.popupSubscription.unsubscribe();
        }
        this.popupSubscription = this.dataStore.get(DataKey.popupClose).subscribe((data) => {
            if (data != null) {
                this.closeModal();
            }
        });
    }

    public onShowSavedCriteriaClick() {
        this.operationV2DataProcessorService.onShowSavedCriteriaClick();
    }

    private updateJobCosts(item: (BookingItem | ServiceItem)) {
        if (item && item.itemCostPortion && item.noOfUnits > 0) {
            let primaryJobCost = 0;
            let secondaryJobCost = 0;
            if (item.itemCostPortion.primaryJobCost && item.itemCostPortion.primaryJobCost.unitCost > 0) {
                item.itemCostPortion.primaryJobCost.totalCost = item.itemCostPortion.primaryJobCost.unitCost *
                    item.noOfUnits;
                item.primaryAssignment.jobCost = item.primaryAssignment.unitFare * item.noOfUnits;
                primaryJobCost = item.itemCostPortion.primaryJobCost.totalCost;
            }
            if (item.itemCostPortion.secondaryJobCost && item.itemCostPortion.secondaryJobCost.unitCost > 0) {
                item.itemCostPortion.secondaryJobCost.totalCost = item.itemCostPortion.secondaryJobCost.unitCost *
                    item.noOfUnits;
                item.secondaryAssignment.jobCost = item.secondaryAssignment.unitFare * item.noOfUnits;
                secondaryJobCost = item.itemCostPortion.secondaryJobCost.totalCost;
            }
            item.itemCostPortion.totalCost = primaryJobCost + secondaryJobCost;
            this.refreshGridView();
        }
    }

    private updateCostingUnits(item: ServiceItem | BookingItem) {
        const assignmentPatch = new AssignmentPatch();
        assignmentPatch.noOfUnits = item.noOfUnits;
        this.updateJobCosts(item);
        const assignmentIds = [];
        if (item.itemCostPortion && item.itemCostPortion.primaryJobCost) {
            assignmentPatch.jobCost = item.itemCostPortion.primaryJobCost.totalCost;
            if (item.primaryAssignment.assignmentId > 0) {
                assignmentIds.push(item.primaryAssignment.assignmentId);
            }
        } else if (item.itemCostPortion && item.itemCostPortion.secondaryJobCost) {
            assignmentPatch.jobCost = item.itemCostPortion.secondaryJobCost.totalCost;
            if (item.secondaryAssignment.assignmentId > 0) {
                assignmentIds.push(item.secondaryAssignment.assignmentId);
            }
        }
        if (assignmentIds.length > 0) {
            this.reservationV2ManagementService.patchAssignmentsData(assignmentIds, assignmentPatch)
                .subscribe(res => {
                        this.common.showSnackBar(
                            'Successfully updated the assignment',
                            3000,
                            TcErrorType.TYPE.INFO
                        );
                    }, error => {
                        console.log(error);
                        this.common.showSnackBar(
                            'Failed to update the assignment',
                            3000,
                            TcErrorType.TYPE.ERROR
                        );
                    }
                );
        }
        if (item && item.noOfUnits > 1 && item.costingType &&
            (item.costingType.code === CostingType.UNIT || item.costingType.code === CostingType.PERSON)) {
            let selectedItems: (ServiceItem | BookingItem)[] = this.getSelectedItems();
            if (selectedItems.length < 2) { // no selected items only consider "row"
                selectedItems = [item];
            }
            this.removeResourceFromItems(selectedItems, true, false);
            this.selectItems(selectedItems);
            this.markChanges();
        }
    }

    private updateSecondaryCostingUnits(item: ServiceItem | BookingItem) {
        const assignmentPatch = new AssignmentPatch();
        assignmentPatch.noOfUnits = item.secondaryNoOfUnits;
        this.updateJobCosts(item);
        const assignmentIds = [];
        if (item.itemCostPortion && item.itemCostPortion.secondaryJobCost) {
            assignmentPatch.jobCost = item.itemCostPortion.secondaryJobCost.totalCost;
            if (item.secondaryAssignment.assignmentId > 0) {
                assignmentIds.push(item.secondaryAssignment.assignmentId);
            }
        }
        if (assignmentIds.length > 0) {
            this.reservationV2ManagementService.patchAssignmentsData(assignmentIds, assignmentPatch)
                .subscribe(res => {
                        this.common.showSnackBar(
                            'Successfully updated the assignment',
                            3000,
                            TcErrorType.TYPE.INFO
                        );
                    }, error => {
                        console.log(error);
                        this.common.showSnackBar(
                            'Failed to update the assignment',
                            3000,
                            TcErrorType.TYPE.ERROR
                        );
                    }
                );
        }
        if (item && item.secondaryNoOfUnits > 1 && item.secondaryCostingType &&
            (item.secondaryCostingType.code === CostingType.UNIT || item.secondaryCostingType.code ===
                CostingType.PERSON)) {
            let selectedItems: (ServiceItem | BookingItem)[] = this.getSelectedItems();
            if (selectedItems.length < 2) { // no selected items only consider "row"
                selectedItems = [item];
            }
            this.removeResourceFromSecondaryItems(selectedItems, false, true);
            this.selectItems(selectedItems);
            this.markChanges();
        }
    }

    private getDuration(item: ServiceItem | BookingItem) {
        if (item.startTime && item.endTime) {
            const timeDiff = new Date(item.endTime).getTime() - new Date(item.startTime).getTime();
            let timeInHours = timeDiff > 0 ? new Date(timeDiff).getUTCHours() : 1;
            return timeDiff > 0 && new Date(timeDiff).getUTCMinutes() > 0 ? ++timeInHours : timeInHours;
        }
        return 1;
    }

    // transfer mode
    getServiceBookingQueueTrsMode = (params: BookingItemGridParam | ServiceItemGridParam) => {
        if (params.data && params.data.trsBookingItem && params.data.trsBookingItem.transferMode) {
            return params.data.trsBookingItem.transferMode;
        } else {
            return this.EMPTY_STRING;
        }
    };

    getTransferModes = (params, rowData, text) => {
        return this.dataStore.get(DataKey.transferModes)
                   .map(
                       aEvents =>
                           aEvents.filter(aEvent => {
                                   if (text) {
                                       if (aEvent.name) {
                                           if (aEvent.name.toLowerCase().indexOf(text.toLowerCase().toLocaleString()) >
                                               -1) {
                                               return aEvent.name.toLowerCase()
                                                            .indexOf(text.toLowerCase().toLocaleString()) > -1;
                                           } else {
                                               return aEvent.code.toLowerCase()
                                                            .indexOf(text.toLowerCase().toLocaleString()) > -1;
                                           }
                                       }
                                   } else {
                                       return true;
                                   }
                               }
                           )
                   );
    };

    onSetTransferMode = (params) => {
        if (params.newValue && params.data.trsBookingItem) {
            params.data.trsBookingItem.transferMode = params.newValue.code;
            this.markChanges();

            let selectedItems: (ServiceItem | BookingItem)[];

            const bookingIds = [];
            if (this.isBookingQueue()) {
                const bookingItem: BookingItem = params.data;
                bookingIds.push(bookingItem.id);
                selectedItems = [bookingItem];
            } else {
                const serviceItem: ServiceItem = params.data;
                if (serviceItem.bookingItemSummaryList != null) {
                    for (const bookingItemSummary of serviceItem.bookingItemSummaryList) {
                        bookingIds.push(bookingItemSummary.bookingItemId);
                    }
                }
                selectedItems = [serviceItem];
            }
            this.selectItems(selectedItems);
            const bookingItemPatch = new BookingItemPatch();
            bookingItemPatch.transferMode = params.data.trsBookingItem.transferMode;
            this.reservationV2ManagementService.patchBookingItems(bookingIds, bookingItemPatch)
                .subscribe(res => {
                        this.removeAssignmentFromItems(selectedItems, true, true);
                        this.common.showSnackBar(
                            'Added transfer mode to booking item/items',
                            3000,
                            TcErrorType.TYPE.INFO
                        );
                    }, error => {
                        console.log(error);
                        this.common.showSnackBar(
                            'Failed to add transfer mode to booking item/items',
                            3000,
                            TcErrorType.TYPE.ERROR
                        );
                    }
                );
        }
    };

    getRoutes = (params, rowData, text) => {
        return this.dataStore.get(DataKey.routes)
                   .map(
                       aEvents =>
                           aEvents.filter(aEvent => {
                                   if (text) {
                                       if (aEvent.code) {

                                           return aEvent.code.toLowerCase().indexOf(text.toLowerCase().toLocaleString()) >
                                               -1;

                                       }
                                   } else {
                                       return true;
                                   }
                               }
                           )
                   );
    };

    onSetRoute = (params) => {
        if (params.newValue && params.data.trsBookingItem && params.data.trsBookingItem.route !==
            params.newValue.code) {
            params.data.trsBookingItem.route = params.newValue.code;
            this.markChanges();
            let selectedItems: (ServiceItem | BookingItem)[];

            const bookingIds = [];
            if (this.isBookingQueue()) {
                const bookingItem: BookingItem = params.data;
                bookingIds.push(bookingItem.id);
                selectedItems = [bookingItem];
            } else {
                const serviceItem: ServiceItem = params.data;
                if (serviceItem.bookingItemSummaryList != null) {
                    for (const bookingItemSummary of serviceItem.bookingItemSummaryList) {
                        bookingIds.push(bookingItemSummary.bookingItemId);
                    }
                }
                selectedItems = [serviceItem];
            }
            this.selectItems(selectedItems);
            const bookingItemPatch = new BookingItemPatch();
            bookingItemPatch.route = params.data.trsBookingItem.route;
            this.reservationV2ManagementService.patchBookingItems(bookingIds, bookingItemPatch)
                .subscribe(res => {
                        this.removeAssignmentFromItems(selectedItems, true, true);
                        this.common.showSnackBar(
                            'Added route to booking item/items',
                            3000,
                            TcErrorType.TYPE.INFO
                        );
                    }, error => {
                        console.log(error);
                        this.common.showSnackBar(
                            'Failed to add route to booking item/items',
                            3000,
                            TcErrorType.TYPE.ERROR
                        );
                    }
                );
        }
    };

    // for both pickup and dropoff locations
    getLocations = (params, rowData, text) => {
        const locationSearchCriteria = new LocationsSearchCriteria();
        locationSearchCriteria.codeOrName = text;
        locationSearchCriteria.size = 10;
        return this.locationService.getLocations(locationSearchCriteria)
                   .pipe(
                       map(
                           response => {
                               return ResponseUtil.getDataArray(response);
                           }
                       ), catchError(
                           error => {
                               return [];
                           }
                       )
                   );
    };

    onSetPickupLocation = (params) => {
        if (params.newValue && params.data.trsBookingItem) {
            params.data.trsBookingItem.pickupPointName = params.newValue.name;
            params.data.trsBookingItem.pickupPointCode = params.newValue.code;
            this.markChanges();
            let selectedItems: (ServiceItem | BookingItem)[];
            const bookingIds = [];
            if (this.isBookingQueue()) {
                const bookingItem: BookingItem = params.data;
                bookingIds.push(bookingItem.id);
                selectedItems = [bookingItem];
            } else {
                const serviceItem: ServiceItem = params.data;
                if (serviceItem.bookingItemSummaryList != null) {
                    for (const bookingItemSummary of serviceItem.bookingItemSummaryList) {
                        bookingIds.push(bookingItemSummary.bookingItemId);
                    }
                }
                selectedItems = [serviceItem];
            }
            this.selectItems(selectedItems);
            const bookingItemPatch = new BookingItemPatch();
            bookingItemPatch.pickupPointName = params.data.trsBookingItem.pickupPointName;
            bookingItemPatch.pickupPointCode = params.data.trsBookingItem.pickupPointCode;
            this.reservationV2ManagementService.patchBookingItems(bookingIds, bookingItemPatch)
                .subscribe(data => {
                        if (data) {
                            const bookingItems = ResponseUtil.getDataArray<BookingItem>(data);
                            if (bookingItems && bookingItems.length > 0 && bookingItems[0].trsBookingItem &&
                                params.data.trsBookingItem.route !== bookingItems[0].trsBookingItem.route) {
                                params.data.trsBookingItem.route = bookingItems[0].trsBookingItem.route;
                                this.removeAssignmentFromItems(selectedItems, true, true);
                                this.refreshGridView();

                            }

                        }
                        this.common.showSnackBar(
                            'Pickup location and route updated',
                            3000,
                            TcErrorType.TYPE.INFO
                        );
                    }, error => {
                        console.log(error);
                        this.common.showSnackBar(
                            'Failed to update pickup location',
                            3000,
                            TcErrorType.TYPE.ERROR
                        );
                    }
                );
        }
    };

    onSetDropOffLocation = (params) => {
        if (params.newValue && params.data.trsBookingItem) {
            params.data.trsBookingItem.dropOffPointName = params.newValue.name;
            params.data.trsBookingItem.dropOffPointCode = params.newValue.code;
            this.markChanges();
            let selectedItems: (ServiceItem | BookingItem)[];
            const bookingIds = [];
            if (this.isBookingQueue()) {
                const bookingItem: BookingItem = params.data;
                bookingIds.push(bookingItem.id);
                selectedItems = [bookingItem];
            } else {
                const serviceItem: ServiceItem = params.data;
                if (serviceItem.bookingItemSummaryList != null) {
                    for (const bookingItemSummary of serviceItem.bookingItemSummaryList) {
                        bookingIds.push(bookingItemSummary.bookingItemId);
                    }
                }
                selectedItems = [serviceItem];
            }
            this.selectItems(selectedItems);
            const bookingItemPatch = new BookingItemPatch();
            bookingItemPatch.dropOffPointCode = params.data.trsBookingItem.dropOffPointCode;
            bookingItemPatch.dropOffPointName = params.data.trsBookingItem.dropOffPointName;
            this.reservationV2ManagementService.patchBookingItems(bookingIds, bookingItemPatch)
                .subscribe(data => {
                        if (data) {
                            const bookingItems = ResponseUtil.getDataArray<BookingItem>(data);
                            if (bookingItems && bookingItems.length > 0 && bookingItems[0].trsBookingItem &&
                                params.data.trsBookingItem.route !== bookingItems[0].trsBookingItem.route) {
                                params.data.trsBookingItem.route = bookingItems[0].trsBookingItem.route;
                                this.removeAssignmentFromItems(selectedItems, true, true);
                                this.refreshGridView();

                            }

                        }
                        this.common.showSnackBar(
                            'Drop off location and route updated',
                            3000,
                            TcErrorType.TYPE.INFO
                        );
                    }, error => {
                        console.log(error);
                        this.common.showSnackBar(
                            'Failed to update drop off location',
                            3000,
                            TcErrorType.TYPE.ERROR
                        );
                    }
                );
        }
    };

    private validateUserPanels() {
        this.userBusinessType = this.localStorage.load<string>(LocalDataKey.userBusinessType);
        if (this.userBusinessType === UserBusinessType.EXTERNAL) {
            // hide panels
            this.showServicingQueue = false;
            this.showConfirmButton = false;
            this.showAutoAllocationButton = false;

            // disabling
            this.saveButtonEnable = false;
            this.revertButtonEnable = false;
        }
    }

    private setupPageOptions() {
        if (this.pageOptionsAvailability) {
            // hide panels
            this.showServicingQueue = this.pageOptionsAvailability.show_servicing_queue;
            this.showConfirmButton = this.pageOptionsAvailability.show_confirm_button;
            this.showAutoAllocationButton = this.pageOptionsAvailability.show_auto_allocation_button;
            this.showSecondaryJobStatus = this.pageOptionsAvailability.show_secondary_job_status;

            // disabling
            this.saveButtonEnable = this.pageOptionsAvailability.save_button_enable;
            this.revertButtonEnable = this.pageOptionsAvailability.revert_button_enable;
            this.showManifestStatusAllocQueue = this.pageOptionsAvailability.show_manifest_status_alloc_queue;
        }
    }

    public ngOnDestroy(): void {
        if (this.productSearchCriteriaSubscription) {
            this.productSearchCriteriaSubscription.unsubscribe();
        }
        if (this.resourceTypesSubscription) {
            this.resourceTypesSubscription.unsubscribe();
        }
        if (this.costingTypesSubscription) {
            this.costingTypesSubscription.unsubscribe();
        }
        if (this.languagesSubscription) {
            this.languagesSubscription.unsubscribe();
        }
        if (this.trsModesSubscription) {
            this.trsModesSubscription.unsubscribe();
        }
        if (this.routesSubscription) {
            this.routesSubscription.unsubscribe();
        }
        if (this.productSearchCriteriaSubscription) {
            this.productSearchCriteriaSubscription.unsubscribe();
        }
        if (this.popupSubscription) {
            this.popupSubscription.unsubscribe();
        }
        if (this.modelConfirmSubscription) {
            this.modelConfirmSubscription.unsubscribe();
        }
        if (this.modelCloseSubscription) {
            this.modelCloseSubscription.unsubscribe();
        }
        if (this.dialogSubscription) {
            this.dialogSubscription.unsubscribe();
        }
        if (this.resourceAllocationResultsSubscription) {
            this.resourceAllocationResultsSubscription.unsubscribe();
        }
        if (this.supplierAllocationResultsSubscription) {
            this.supplierAllocationResultsSubscription.unsubscribe();
        }
        this.em.removeEvents([
            'SAVE_ASSIGNMENTS',
            'REVERT_ASSIGNMENTS',
            'CONFIRM_ASSIGNMENTS',
            'SEND_MANIFESTS',
            'SUP_CONFIRM_ASSIGNMENTS',
            'SUP_REJECT_ASSIGNMENTS',
            'GENERATE_DOCUMENTS',
            'GENERATE_AUTO_ALLOCATIONS'
        ]);
    }
}
