<ag-grid-angular
        class="ag-theme-material tc-ag-grid tc-ag-grid--full-width tc-ag-grid--popup-content"
        [rowData]="noteGroup?.bookingNotes"
        [gridOptions]="gridOptions"
        [columnDefs]="columnDefs"
        [defaultColDef]="defaultColDef"
        [frameworkComponents]="frameworkComponents"
        (gridReady)="onGridReadyBookingNotes($event)"
        (firstDataRendered)="onFirstDataRendered($event)">
</ag-grid-angular>
