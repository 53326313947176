import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TcErrorType } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-error';
import { TC } from '@tc-core/util';
import { ConfigLoader } from '@tc-core/util/framework';
import { ModalService } from '@tc/modal/modal.service';
import { IGetRowsParams } from 'ag-grid-community';
import { Observable, of, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SetupGridComp } from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid-comp';
import { SetupGridDataSource } from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid-data-source';
import { SetupGridComponent } from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid.component';
import { ActionRendererAction } from '../../../widgets/framework/ag-grid-custom-components/renderers/actions/actions.component';
import { TCO } from '../../../constants';
import { MappedResourceTypeSearchCriteria } from '../../../models/criteria/mapped-resource-type-search-criteria';
import { ResourceTypeSearchCriteria } from '../../../models/criteria/resource-type-search-criteria';
import { SortDirection } from '../../../models/helper/sort-direction';
import { DataHandlerService } from '../../../services/backend-consumers/master-data-handler-service/data-handler.service';
import { ResourceTypeService } from '../../../services/backend-consumers/setups/resource-type.service';
import { DMCCommon } from '../../../services/util/common/dmc-common';
import { DataKey, DataStoreService } from '../../../services/util/framework/data-store.service';
import EMPTY_CHAR = TC.FORMAT.EMPTY_CHAR;

@Component({
    selector: 'tc-mapped-resource-types-grid-setup',
    templateUrl: './mapped-resource-types-grid-setup.component.html'
})
export class MappedResourceTypesGridSetupComponent extends SetupGridComp implements OnInit, OnDestroy, SetupGridDataSource {

    @ViewChild('setupGrid')
    private setupGrid: SetupGridComponent;
    public colDefConfig = [];
    gridActions: ActionRendererAction[] = [];

    private mappedResourceTypeSearchCriteria: MappedResourceTypeSearchCriteria = new MappedResourceTypeSearchCriteria();

    allMappedResourceTypeCodes = [];
    // required options for grid editors
    // nothing

    private searchCriteriaObserver: Subscription = new Subscription();

    constructor(
        private configLoader: ConfigLoader,
        private dataStoreService: DataStoreService,
        private dataHandlerService: DataHandlerService,
        private modalService: ModalService,
        private resourceTypeService: ResourceTypeService,
        private common: DMCCommon,
    ) {
        super();
    }

    ngOnInit() {
        this.colDefConfig = this.configLoader.configurations.get(TCO.CONF.CONF_MAPPED_RESOURCE_TYPE_SETUP);
        this.addGridActions();
        this.subscribeSearchCriteria();
        this.fillRequiredGridOptionData();
        this.fillDataKeyForEditorData();
        this.getMappedTypeCodes();
    }

    private addGridActions() {
    }

    /**
     * subscribe search criteria changes in search bar
     * then force grid to refresh data
     * grid refresh will be call getRaws method which is using this subscribed criteria for backend call
     */
    subscribeSearchCriteria() {
        this.searchCriteriaObserver = this.dataStoreService.get(DataKey.mappedResourceTypeSearchCriteria)
                                          .subscribe(value => {
                                              this.mappedResourceTypeSearchCriteria = value;
                                              this.setupGrid.runForceSearch();
                                          });
    }

    private fillRequiredGridOptionData() {
        // nothing
    }

    getMappedTypeCodes() {
        this.resourceTypeService.getMappedResourceTypeCodes().subscribe(
            response => {
                this.allMappedResourceTypeCodes = response.data;
            }
        );
    }

    private fillDataKeyForEditorData() {
        this.addResourceTypes();
    }

    private addResourceTypes() {
        const resourceTypeSearchCriteria = new ResourceTypeSearchCriteria();
        resourceTypeSearchCriteria.size = 1000000;
        this.resourceTypeService.getResourceTypes(resourceTypeSearchCriteria).subscribe(
            result => {
                this.dataStoreService.set(DataKey.resourceTypes, result.data, true);
            });
    }

    public isInvalidRow(params): boolean {
        const data = params.data;
        if (data) {
            let invalid = true;
            if (
                data.mappedTypeCode &&
                data.mappedTypeName &&
                data.resourceTypeCode
            ) {
                invalid = false;
            }
            return invalid;
        } else {
            return false;
        }
    }

    public createNewRow(): any {
        return {unsaved: true};
    }

    public isUnsavedRow(row: any): boolean {
        return !!row.unsaved;
    }

    public deleteRow(row: any) {
        if (row && row.mappedTypeCode) {
            return this.resourceTypeService.deleteMappedResourceType(row.mappedTypeCode);
        } else {
            return of('success');
        }
    }

    public saveRow(row: any, event: any) {
        return this.resourceTypeService.saveMappedResourceType(row)
                   .pipe(tap(data => row.unsaved = undefined));
    }

    public getRows(params: IGetRowsParams): Observable<any> {
        const pageSize = params.endRow - params.startRow;
        if (!this.mappedResourceTypeSearchCriteria) {
            this.mappedResourceTypeSearchCriteria = new MappedResourceTypeSearchCriteria();
        }
        this.mappedResourceTypeSearchCriteria.start = params.startRow;
        this.mappedResourceTypeSearchCriteria.size = pageSize;
        if (params.sortModel && params.sortModel[0]) {
            this.mappedResourceTypeSearchCriteria.sortBy = params.sortModel[0].colId;
            if (params.sortModel[0].sort === SetupGridComp.GRID_SORT_ASCENDING) {
                this.mappedResourceTypeSearchCriteria.sortDirection = SortDirection.ASC;
            } else {
                this.mappedResourceTypeSearchCriteria.sortDirection = SortDirection.DESC;
            }
        } else {
            this.mappedResourceTypeSearchCriteria.sortBy = 'createdOn';
            this.mappedResourceTypeSearchCriteria.sortDirection = SortDirection.ASC;
        }
        return this.resourceTypeService.getMappedResourceTypes(this.mappedResourceTypeSearchCriteria)
                   .pipe(
                       tap(data =>
                           this.dataStoreService.set(DataKey.mappedReourceTypeSearchResultsForCriteria, data)
                       )
                   );
    }

    public ngOnDestroy(): void {
        if (this.searchCriteriaObserver) {
            this.searchCriteriaObserver.unsubscribe();
        }
    }

    /*
     grid config methods which are called by grid setup (ag grid)
     and set by grid config
     */

    getResourceType = (params) => {
        let typeText = EMPTY_CHAR;
        if (params && params.data) {
            if (params.data.resourceTypeName) {
                typeText = params.data.resourceTypeName;
            }
            if (params.data.resourceTypeName && params.data.resourceTypeCode) {
                typeText += ' | ';
                typeText += params.data.resourceTypeCode;
            }
        }
        return typeText;
    };

    getResourceTypes = (params, rowData, text) => {
        return this.dataStoreService.get(DataKey.resourceTypes)
                   .map(
                       aEvents =>
                           aEvents.filter(aEvent => {
                                   if (text) {
                                       if (aEvent.name) {
                                           return aEvent.name.toLowerCase().indexOf(text.toLocaleString()) > -1;
                                       }
                                   } else {
                                       return true;
                                   }
                               }
                           )
                   );
    };

    setResourceType = params => {
        if (params && params.data && params.newValue) {
            if ( params.oldValue === '--' && this.allMappedResourceTypeCodes.includes(params.data.mappedTypeCode) ) {
                this.common.showSnackBar(
                    'Resource Mapping Code Already Exist. Please Select a Different Code',
                    3000,
                    TcErrorType.TYPE.ERROR
                );
            } else {
                params.data.resourceTypeCode = params.newValue.code;
                params.data.resourceTypeName = params.newValue.name;
            }
        }
    };

}
