import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AutoAssignedSummaryModule } from '../../../widgets/shared/auto-assigned-summary/auto-assigned-summary.module';
import { AutoAssignedSummaryWrapperComponent } from './auto-assigned-summary-wrapper.component';

@NgModule({
    imports: [
        CommonModule,
        AutoAssignedSummaryModule
    ],
    exports: [AutoAssignedSummaryWrapperComponent],
    declarations: [AutoAssignedSummaryWrapperComponent],
    entryComponents: [AutoAssignedSummaryWrapperComponent]
})
export class AutoAssignedSummaryWrapperModule {}
