<div class="tc-sc__container-main">
  <div class="tc-sc__container-left">
    <tc-season-calendar-navigation
      [viewOnlyMode]="viewOnlyMode"
      [rangeGroups]="rangeGroups"
      (selectSeason)="onSelectSeason($event)"
      (removeSeason)="onRemoveSeason($event)"
      (selectDateRange)="onSelectDateRange($event)"
      (removeDateRange)="onRemoveDateRange($event)"
      (setSeasons)="onSetSeasons($event)"
      (setSeasonRange)="onSetSeasonRange($event)">
    </tc-season-calendar-navigation>
  </div>


  <div *ngIf="showCalendar" class="tc-sc__container-right">
    <tc-season-calendar-body
      class=""
      [fromYear]="fromYear"
      [toYear]="toYear"
      [rangeGroups]="rangeGroups"
      [calendarRefreshEvent]="calendarRefreshEvent"
      [calendarMenuItems]="calendarMenuItems"
      [viewOnlyMode]="viewOnlyMode"
      (selectDate)="onSelectDate($event)"
      (menuItemClick)="onMenuItemClick($event)">
    </tc-season-calendar-body>
  </div>
</div>

