import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaginatorModule } from '@tc/paginator';
import { AdvVehicleSearchResultsModule } from '../../widgets/shared/adv-vehicle-search-results/adv-vehicle-search-results.module';
import { ResultHeaderModule } from '../../widgets/shared/result-header/result-header.module';
import { VehicleSearchResultsComponent } from './vehicle-search-results.component';

@NgModule({
    imports: [
        CommonModule,
        ResultHeaderModule,
        PaginatorModule,
        AdvVehicleSearchResultsModule,

    ],
    declarations: [VehicleSearchResultsComponent],
    exports: [VehicleSearchResultsComponent],
    entryComponents:[VehicleSearchResultsComponent]
})
export class VehicleSearchResultsModule {}
