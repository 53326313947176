import { AbstractSearchCriteria } from './abstract-search-criteria';

export class NotificationSearchCriteria extends AbstractSearchCriteria {
    supplierCode = "";
    supplierName = "";
    resourceName = "";
    type = "";
    serviceType = "";
    modifiedDate = "";
    startDate = "";
    endDate = "";
    dmc: boolean;
    indicator = "";
    resourceId: number;
    public gridFiltering: string;
    public gridSorting: string;
    message: boolean;
}
