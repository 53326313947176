export class Country {
    code:string;
    name:string;
}

export class State {
    code:string;
    name:string;
    country:Country
}

export class City {
    code:string;
    name:string;
    state:State;
    country:Country
}
