<div class="">
    <tc-section-heading
            [pageSection]="costAddSection"
            (actionClick)="onHeaderActionClick($event)">
    </tc-section-heading>
    <ag-grid-angular
            style="" class="ag-theme-material tc-ag-grid tc-ag-grid--full-height tc-border-right-0 tc-border-left-0"
            [rowData]="resourceCosts"
            [columnDefs]="columnDefs"
            [defaultColDef]="defaultColDef"
            [pagination]="true"
            [paginationAutoPageSize]="true"
            [rowClassRules]="rowClassRules"
            rowSelection="multiple"
            singleClickEdit=true
            (gridSizeChanged)="onGridSizeChange()"
            (rowDoubleClicked)="onRowDoubleClicked($event)"
            (cellValueChanged)="onCellValueChange($event)"
            (gridReady)="onGridReady($event)">
    </ag-grid-angular>
</div>
