import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SetupGridModule } from '../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid.module';
import { SetupCommonDataProcessorService } from '../../services/util/pre-processors/setup-common-data-processor.service';
import { LocationsGridSetupSearchCriteriaModule } from '../setup/locations-grid-setup-search-criteria/locations-grid-setup-search-criteria.module';
import { LocationSelectionFocusViewComponent } from './location-selection-focus-view.component';

@NgModule({
  declarations: [LocationSelectionFocusViewComponent],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    SetupGridModule,
    FormsModule,
    MatIconModule,
    MatButtonModule,
    MatDividerModule,
    LocationsGridSetupSearchCriteriaModule
  ],
  providers: [
    SetupCommonDataProcessorService
  ]
})
export class LocationSelectionFocusViewModule { }
