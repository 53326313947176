import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatAutocompleteModule, MatButtonModule,
  MatCheckboxModule,
  MatFormFieldModule, MatIconModule,
  MatInputModule,
  MatSelectModule,
  MatTabsModule, MatTooltipModule
} from '@angular/material';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from '@tc/pipes';
import { ResourceModule } from '@tc/resource';
import { NoteComponent } from './note.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    ResourceModule,
    PipesModule,
    MatCheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatTabsModule,
    MatAutocompleteModule,
    MatIconModule,
    MatButtonModule,
    MatTooltipModule
  ],
  declarations: [NoteComponent],
  exports: [NoteComponent]
})
export class NoteModule { }
