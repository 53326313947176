/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid.component.ngfactory";
import * as i2 from "../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid.component";
import * as i3 from "../../../services/util/pre-processors/grid-column-definition-processor.service";
import * as i4 from "@ngx-translate/core";
import * as i5 from "@tc/dialog/dialog.service";
import * as i6 from "../../../services/util/framework/data-store.service";
import * as i7 from "../../../services/util/common/dmc-common";
import * as i8 from "./brand-setup.component";
import * as i9 from "@tc-core/util/framework/config-loader.service";
import * as i10 from "../../../services/backend-consumers/setups/costing-type-service";
import * as i11 from "../../../services/backend-consumers/master-data-handler-service/master-data-rest.service";
import * as i12 from "../../../services/backend-consumers/setups/brand-setup-service";
import * as i13 from "@tc-core/util/ui/spinner.service";
import * as i14 from "../../../services/user-management/user-management.service";
var styles_BrandSetupComponent = [];
var RenderType_BrandSetupComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_BrandSetupComponent, data: {} });
export { RenderType_BrandSetupComponent as RenderType_BrandSetupComponent };
export function View_BrandSetupComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { codeCell: 0 }), i0.ɵqud(402653184, 2, { nameCell: 0 }), i0.ɵqud(402653184, 3, { setupGrid: 0 }), (_l()(), i0.ɵeld(3, 0, null, null, 1, "tc-setup-grid", [], null, null, null, i1.View_SetupGridComponent_0, i1.RenderType_SetupGridComponent)), i0.ɵdid(4, 638976, [[3, 4], ["setupGrid", 4]], 0, i2.SetupGridComponent, [i0.ChangeDetectorRef, i3.GridColumnDefinitionProcessorService, i4.TranslateService, i5.DialogService, i6.DataStoreService, i7.DMCCommon], { readOnly: [0, "readOnly"], disableAdd: [1, "disableAdd"], disableDelete: [2, "disableDelete"], disableRowDelete: [3, "disableRowDelete"], noSelection: [4, "noSelection"], dataSource: [5, "dataSource"], setupGridComp: [6, "setupGridComp"], colDefConfig: [7, "colDefConfig"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = false; var currVal_1 = _co.isDisabledAdd(); var currVal_2 = true; var currVal_3 = true; var currVal_4 = true; var currVal_5 = _co; var currVal_6 = _co; var currVal_7 = _co.colDefConfig; _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }, null); }
export function View_BrandSetupComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-brand-setup", [], null, null, null, View_BrandSetupComponent_0, RenderType_BrandSetupComponent)), i0.ɵdid(1, 114688, null, 0, i8.BrandSetupComponent, [i9.ConfigLoader, i10.CostingTypeService, i6.DataStoreService, i11.MasterDataRestService, i7.DMCCommon, i6.DataStoreService, i12.BrandSetupService, i13.SpinnerService, i5.DialogService, i14.UserManagementService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BrandSetupComponentNgFactory = i0.ɵccf("tc-brand-setup", i8.BrandSetupComponent, View_BrandSetupComponent_Host_0, {}, {}, []);
export { BrandSetupComponentNgFactory as BrandSetupComponentNgFactory };
