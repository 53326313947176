import { AfterViewInit, Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import * as _ from 'lodash';
import { ActionsComponent } from '../../renderers/actions/actions.component';

@Component({
    selector: 'tc-note-editor',
    templateUrl: './note-editor.component.html',
    styleUrls: ['./note-editor.component.scss']
})
export class NoteEditorComponent implements ICellEditorAngularComp, AfterViewInit {

    noteEditableFn:any;
    actionDisableFn:any;
    gridApi;
    gridColumnApi;
    params: any;
    rowObject: string;
    rowData: any;
    notes: any[] = [];
    columnDefs = [
        {
            headerName: 'Note',
            field: 'text',
            width: 400,
            editable: true
        },
        {
            headerName: 'Actions',
            field: 'actions',
            width: 120,
            cellRendererFramework: ActionsComponent,
            headerClass: 'actions-header',
            cellRendererParams: {
                actions: [
                    {
                        'icon': 'delete',
                        'action': this.onDeleteNote.bind(this)
                    },
                    {
                        'icon': 'add',
                        'action': this.onAddNote.bind(this)
                    }
                ]
            },
            resizable: false,
            pinned: 'right',
            cellClass: ['tc-ag-grid-cell--read-only', 'actions-column']
        }
    ];
    defaultColDef: any = {
        headerValueGetter: (parameters: ICellRendererParams): string => {
            const headerIdentifier = parameters.colDef.headerName;
            if (headerIdentifier) {
                return this.translateService.instant(headerIdentifier);
            }
            return '';
        }
    };
    gridOptions: any;

    constructor(
        private translateService: TranslateService
    ) {
        this.translateService.onLangChange.subscribe(() => {
            this.gridApi.refreshHeader();
        });
    }

    public agInit(params: any): void {
        this.params = params;
        this.gridColumnApi = params.columnApi;
        this.rowData = params['data'];
        this.rowObject = params['rowObject'];
        this.noteEditableFn = params['noteEditableFn'];
        this.actionDisableFn = params['actionDisableFn'];
        if (this.noteEditableFn !=undefined) {
            this.columnDefs[0]['editable']=this.noteEditableFn(this.rowData);
        }
        if (this.actionDisableFn!=undefined) {
            const actions=this.columnDefs[1]['cellRendererParams']['actions'];
            actions.forEach( action=>action['disable']=this.actionDisableFn(this.rowData))
        }


        if (this.rowObject) {
            this.notes = _.get(params.data, this.rowObject);
        } else {
            this.notes = params['value'];
        }
        if (!this.notes) {
            this.notes = [];
        }
        if (this.notes && !this.notes.length) {
            this.notes.push(this.getEmptyNote());
        }
    }

    public getValue(): any {
        return this.notes;
    }

    public isPopup(): boolean {
        return true;
    }

    public ngAfterViewInit(): void {
    }

    public onGridReady(params: any) {
        this.gridApi = params.api;
        this.gridApi.sizeColumnsToFit();
        this.gridApi.setHeaderHeight(40);
        // this.gridApi.setHeaderHeight(0);
    }

    public onDeleteNote(params: any) {
        if (params && params.data && this.notes.indexOf(params.data) != -1) {
            this.notes.splice(this.notes.indexOf(params.data), 1);
            if (!this.notes.length) {
                this.notes.push(this.getEmptyNote());
            }
            this.gridApi.setRowData(this.notes);
        }
    }

    public onAddNote(params: any) {
        this.notes.splice(
            this.notes.indexOf(params.data) + 1,
            0,
            this.getEmptyNote()
        );
        this.gridApi.setRowData(this.notes);
    }

    private getEmptyNote() {
        return {};
    }
}
