/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../widgets/shared/pickup-dropoff-details/pickup-dropoff-details.component.ngfactory";
import * as i2 from "../../../widgets/shared/pickup-dropoff-details/pickup-dropoff-details.component";
import * as i3 from "./pickup-dropoff-details-wrapper.component";
import * as i4 from "../../../services/util/framework/data-store.service";
var styles_PickupDropOffDetailsWrapperComponent = [];
var RenderType_PickupDropOffDetailsWrapperComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PickupDropOffDetailsWrapperComponent, data: {} });
export { RenderType_PickupDropOffDetailsWrapperComponent as RenderType_PickupDropOffDetailsWrapperComponent };
export function View_PickupDropOffDetailsWrapperComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-pickup-dropoff-details", [], null, [[null, "closePopUp"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("closePopUp" === en)) {
        var pd_0 = (_co.onClosePopup($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_PickupDropoffDetailsComponent_0, i1.RenderType_PickupDropoffDetailsComponent)), i0.ɵdid(1, 114688, null, 0, i2.PickupDropoffDetailsComponent, [], { dropOffs: [0, "dropOffs"], pickUps: [1, "pickUps"], selectedIndex: [2, "selectedIndex"] }, { closePopUp: "closePopUp" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dropOffs; var currVal_1 = _co.pickUps; var currVal_2 = _co.selectedIndex; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_PickupDropOffDetailsWrapperComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-pickup-dropoff-details-wrapper", [], null, null, null, View_PickupDropOffDetailsWrapperComponent_0, RenderType_PickupDropOffDetailsWrapperComponent)), i0.ɵdid(1, 114688, null, 0, i3.PickupDropOffDetailsWrapperComponent, [i4.DataStoreService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PickupDropOffDetailsWrapperComponentNgFactory = i0.ɵccf("tc-pickup-dropoff-details-wrapper", i3.PickupDropOffDetailsWrapperComponent, View_PickupDropOffDetailsWrapperComponent_Host_0, { dropOffs: "dropOffs", pickUps: "pickUps", selectedIndex: "selectedIndex" }, {}, []);
export { PickupDropOffDetailsWrapperComponentNgFactory as PickupDropOffDetailsWrapperComponentNgFactory };
