import { Component } from '@angular/core';
import {DataKey} from "../../../services/util/framework/data-store.service";
import {TCO} from "../../../constants";
import {DistributionChannelSearchCriteria} from "../../../models/criteria/distribution-channel-search-criteria";

@Component({
  selector: 'tc-distribution-channel-setup-search-criteria',
  templateUrl: './distribution-channel-setup-search-criteria.component.html'
})
export class DistributionChannelSetupSearchCriteriaComponent {

  inputSearchCriteria = new DistributionChannelSearchCriteria();
  searchCriteriaUpdatingDataKey = DataKey.distributionChannelSetupSearchCriteria;
  searchResultsDataKey = DataKey.distributionChannelSetupSearchResultsForCriteria;
  searchCriteriaLoadingDataKey = TCO.AppData.DISTRIBUTION_CHANNEL_SEARCH_CRITERIA;
  chipConfigKey = TCO.CONF.CONF_DISTRIBUTION_CHANNEL_SETUP_SEARCH_CRITERIA_CHIP_CONFIG;
  placeHolderText = 'Search Distribution Channel Master Data';

  createNewSearchCriteriaFn = () => {
    const criteria = new DistributionChannelSearchCriteria();
    criteria.code = '';
    criteria.name = '';
    return criteria;
  };

  clearLoadedSearchCriteriaFn = (criteria: DistributionChannelSearchCriteria) => {
  };

}
