/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../widgets/shared/lead-passenger-selection/lead-passenger-selection.component.ngfactory";
import * as i2 from "../../../widgets/shared/lead-passenger-selection/lead-passenger-selection.component";
import * as i3 from "./lead-passenger-selection-wrapper.component";
import * as i4 from "../../../services/util/framework/data-store.service";
import * as i5 from "@tc/dialog/dialog.service";
import * as i6 from "../../../services/backend-consumers/reservation-management-services/trs-reservation-management.service";
import * as i7 from "../../../services/util/common/dmc-common";
var styles_LeadPassengerSelectionWrapperComponent = [];
var RenderType_LeadPassengerSelectionWrapperComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_LeadPassengerSelectionWrapperComponent, data: {} });
export { RenderType_LeadPassengerSelectionWrapperComponent as RenderType_LeadPassengerSelectionWrapperComponent };
export function View_LeadPassengerSelectionWrapperComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-lead-passenger-selection", [["class", "lead-passenger-selection"]], null, [[null, "save"], [null, "closePopUp"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("save" === en)) {
        var pd_0 = (_co.onSaveLeadPassenger($event) !== false);
        ad = (pd_0 && ad);
    } if (("closePopUp" === en)) {
        var pd_1 = (_co.onClosePopup($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_LeadPassengerSelectionComponent_0, i1.RenderType_LeadPassengerSelectionComponent)), i0.ɵdid(1, 114688, null, 0, i2.LeadPassengerSelectionComponent, [], { leadPassenger: [0, "leadPassenger"], passengers: [1, "passengers"], isCompleted: [2, "isCompleted"] }, { closePopUp: "closePopUp", save: "save" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.leadPassenger; var currVal_1 = _co.passengers; var currVal_2 = _co.isCompleted; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_LeadPassengerSelectionWrapperComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-lead-passenger-selection-wrapper", [], null, null, null, View_LeadPassengerSelectionWrapperComponent_0, RenderType_LeadPassengerSelectionWrapperComponent)), i0.ɵdid(1, 114688, null, 0, i3.LeadPassengerSelectionWrapperComponent, [i4.DataStoreService, i5.DialogService, i6.TrsReservationManagementService, i7.DMCCommon], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LeadPassengerSelectionWrapperComponentNgFactory = i0.ɵccf("tc-lead-passenger-selection-wrapper", i3.LeadPassengerSelectionWrapperComponent, View_LeadPassengerSelectionWrapperComponent_Host_0, { serviceItemId: "serviceItemId", leadPassenger: "leadPassenger", passengers: "passengers" }, {}, []);
export { LeadPassengerSelectionWrapperComponentNgFactory as LeadPassengerSelectionWrapperComponentNgFactory };
