import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {TcApiError} from '@tc-core/model/it/codegen/tbx/ext/errors/tc-api-error';
import {TcHttpError} from '@tc-core/model/it/codegen/tbx/ext/errors/tc-http-error';
import {Journey} from '@tc-core/model/it/codegen/ui/framework/journey';
import {TC} from '@tc-core/util';
import {ConfigLoader} from '@tc-core/util/framework/config-loader.service';
import {EventManager} from '@tc-core/util/framework/event-manager.service';
import {UserJourneyManager} from '@tc-core/util/framework/user-journey-manager.service';
import {CommonHelper} from '@tc-core/util/helpers/common-helper.service';
import {SpinnerService} from '@tc-core/util/ui';
import {ChipInputComponent} from '@tc/chip-input';
import {ChipInputService} from '@tc/chip-input/chip-input.service';
import * as moment from 'moment';
import {Subscription} from 'rxjs';
import {TCO} from '../../../../../constants';
import {SupplierAllocationSearchCriteria} from '../../../../../models/reservation-v2/criteria/supplier-allocation-search-criteria';
import {ResourceType} from '../../../../../models/reservation/assignment';
import {ReservationV2ManagementService} from '../../../../../services/backend-consumers/reservation-v2/reservation-v2-management.service';
import {ChipHandlerService} from '../../../../../services/helpers/chip-handler.service';
import {RootService} from '../../../../../services/util/core-services/root.service';
import {ActionHandlerService} from '../../../../../services/util/framework/action-handler-service.service';
import {DataKey, DataStoreService} from '../../../../../services/util/framework/data-store.service';
import {DMCQueryParamsService} from '../../../../../services/util/framework/dmc-query-params.service';
import {UserManagementService} from '../../../../../services/user-management/user-management.service';

@Component({
    selector: 'tc-supplier-allocation-search-criteria',
    templateUrl: './supplier-allocation-search-criteria.component.html',
    providers: [
        ChipHandlerService,
        {provide: 'ChipHandlerService', useExisting: ChipHandlerService},
        ChipInputService
    ]
})
export class SupplierAllocationSearchCriteriaComponent implements OnInit {

    @Input() isInsideForm = false;
    @Input() inputSearchCriteria = new SupplierAllocationSearchCriteria();
    @Input() searchCriteriaChipConfig: string;
    @Input() isSearchBarStyleNotAvailable = false;
    @Input() isDataLoaded = true;
    @Input() displayChipsArr = [];
    @Input() isFocus = false;
    @Input() placeHolderText = 'Search Suppliers';
    @Input() isTriggerSearch = true;
    @Input() isVehicleAssignment = false;

    @Output() searchTrigger: EventEmitter<boolean> = new EventEmitter();

    @ViewChild('supplier_allocation_search_chip_input') searchChipInput: ChipInputComponent;

    availableChips: any[] = [];
    supplierSearchCriteria: SupplierAllocationSearchCriteria;
    isChipListDisplay = false;

    isChipsInit = false;
    persistentQParams: string[] = [];
    persistentQParamMap = new Map<string, string>();
    persistentChipsArr = [];
    journey: string = null;
    modifiedChip;

    tPagination: any;

    private relatedChipsObsv: Subscription = new Subscription();
    private routerChangeSubscription: Subscription;
    private selectedChipsObsv: Subscription = new Subscription();
    private selectedFieldsObsv: Subscription = new Subscription();
    private chipValueUpdateSub: Subscription = new Subscription();
    private criteriaUpdateSubscription: Subscription = new Subscription();
    private deletedChipObsv: Subscription = new Subscription();
    private chipHandlerSubject: Subscription = new Subscription();

    private selectedChips = [];
    private chipInputService;

    constructor(
        private configLoader: ConfigLoader,
        private chipHandler: ChipHandlerService,
        private rootService: RootService,
        private em: EventManager,
        private userJourneyManager: UserJourneyManager,
        private queryParamService: DMCQueryParamsService,
        private commonHelper: CommonHelper,
        private reservationV2Service: ReservationV2ManagementService,
        private spinnerService: SpinnerService,
        private dataStoreService: DataStoreService,
        private actionHandlerService: ActionHandlerService,
        private userManagementService: UserManagementService
    ) {
    }

    ngOnInit() {

        this.chipInputService = this.searchChipInput.criteriaInputService;

        this.tPagination = this.configLoader.configurations.get(TCO.CONF.CONF_PAGINATION_CONFIG).RESOURCE_ALLOCATION_SEARCH;

        // reset the search results
        this.dataStoreService.set(DataKey.supplierAllocationSearchCriteria, null, true);
        this.handleQuickActions();

        this.userJourneyManager.journey.subscribe((journey: Journey) => {
            if (journey && JSON.stringify(journey) !== JSON.stringify({})) {
                this.journey = journey.id;
            }
        });
        // resetting the view
        this.routerChangeSubscription = this.userJourneyManager.routeChanges.subscribe(e => {
            if (e) {
                this.chipInputService.fullReset(); // fully reset chips
                this.chipHandler.chipsArr = []; // reset chips array loaded from config

                console.log('displayChipsArr', this.displayChipsArr);
                this.resetQueryParams();
            }
        });
        if (!this.isInsideForm) {
            this.supplierSearchCriteria = new SupplierAllocationSearchCriteria();
            if (this.inputSearchCriteria) {
                this.chipInputService.fullReset(); // fully reset chips
                this.chipHandler.chipsArr = []; // reset chips array loaded from config
                this.supplierSearchCriteria.serviceDate = this.inputSearchCriteria.serviceDate;
                this.supplierSearchCriteria.serviceType = this.inputSearchCriteria.serviceType;
                this.supplierSearchCriteria.adultCount = this.inputSearchCriteria.adultCount;
                this.supplierSearchCriteria.childCount = this.inputSearchCriteria.childCount;
                this.supplierSearchCriteria.infantCount = this.inputSearchCriteria.infantCount;
                this.supplierSearchCriteria.serviceStartTime = this.inputSearchCriteria.serviceStartTime;
                this.supplierSearchCriteria.serviceEndTime = this.inputSearchCriteria.serviceEndTime;
                this.supplierSearchCriteria.bookingIdList = this.inputSearchCriteria.bookingIdList;
                this.supplierSearchCriteria.profileType = this.inputSearchCriteria.profileType;
                this.supplierSearchCriteria.costingType = this.inputSearchCriteria.costingType;
                this.supplierSearchCriteria.noOfUnits = this.inputSearchCriteria.noOfUnits;

                this.supplierSearchCriteria.kcCompany = this.inputSearchCriteria.kcCompany;
                this.supplierSearchCriteria.kcDivision = this.inputSearchCriteria.kcDivision;
                this.supplierSearchCriteria.kcBrand = this.inputSearchCriteria.kcBrand;
                this.supplierSearchCriteria.kcDistributionChannel = this.inputSearchCriteria.kcDistributionChannel;

                if (this.inputSearchCriteria.serviceType === ResourceType.vehicle) {
                    this.isVehicleAssignment = true;
                    this.supplierSearchCriteria.transferMode = this.inputSearchCriteria.transferMode;
                    this.supplierSearchCriteria.route = this.inputSearchCriteria.route;
                    this.supplierSearchCriteria.vehicleModel = this.inputSearchCriteria.vehicleModel ? this.inputSearchCriteria.vehicleModel : 'Any';
                }

                if (!this.isChipsInit) {
                    this.initSearchCriteriaChips(); // load everything from start
                }
            }

        }

        this.getSupplierSearchObserver();
        this.errorObserver();
        this.selectedChipsUpdate();
        this.handleInterRelatedChips();
        this.notifyChipDelete();
        this.shortCutObserver();
    }

    getQueryParams() {
        let queryParams: string[] = [];
        queryParams = this.queryParamService.getQueryParamsArray(new SupplierAllocationSearchCriteria());

        this.supplierSearchCriteria = this.rootService.loadDataFromLocalStorage(queryParams,
            new SupplierAllocationSearchCriteria(),
            TCO.AppData.SUPPLIER_ALLOCATION_SEARCH_CRITERIA
        );

        // get persisting query params
        if (this.supplierSearchCriteria) {
            this.persistentQParams = [];
            this.persistentQParams = this.queryParamService.getNotNullQueryParamsArray(this.supplierSearchCriteria);
        }
    }

    private shortCutObserver() {
        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'FOCUS_SEARCH_BAR').subscribe(e => {
            this.isFocus = true;
        });
        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'CLOSE_POPUPS').subscribe(e => {
            this.isFocus = false;
        });
    }

    onFocus(event) {
        this.isFocus = event;
    }

    initSearchCriteriaChips() {
        this.isChipsInit = true;
        // read chip config
        let criteriaChipConfigs = null;
        if (!this.isInsideForm) {
            const chipConfigs = this.configLoader.configurations.get(TCO.CONF.CONF_SUPPLIER_ALLOCATION_CHIP);
            criteriaChipConfigs = this.commonHelper.recreateJsonObject(chipConfigs);
        } else {
            const chipConfigs = this.configLoader.configurations.get(this.searchCriteriaChipConfig);
            criteriaChipConfigs = this.commonHelper.recreateJsonObject(chipConfigs);
        }
        if (criteriaChipConfigs.chips) {
            criteriaChipConfigs.chips.forEach(chip => {
                chip = this.chipHandler.preProcessChips(chip, criteriaChipConfigs.permissionKeys.keys);
            });
        }
        this.chipHandler.chipsSubject.subscribe(data => {
            if (data && JSON.stringify(data) !== JSON.stringify([]) && data.length ===
                criteriaChipConfigs.chips.length) {
                this.userManagementService.updateAllocationSearchChipsWithKC(
                    criteriaChipConfigs,
                    this.supplierSearchCriteria.kcCompany,
                    this.supplierSearchCriteria.kcDivision,
                    this.supplierSearchCriteria.kcBrand,
                    this.supplierSearchCriteria.kcDistributionChannel
                );

                this.availableChips = data;
                this.chipHandler.chipsSubject.next(null);

                if (this.chipHandlerSubject) {
                    this.chipHandlerSubject.unsubscribe();
                }

                // set values to display chips
                this.displayChipsArr = [];
                this.displayChipsArr = this.availableChips;

                this.availableChips.forEach((chip) => {
                    if (chip.criteriaId === 'SERVICE_TYPE') {
                        chip.optionParams.isInitialPresent = true;

                        chip.optionParams.isInitialPresent = true;
                        const choice = chip.optionParams.options
                            .filter(option => option.code === this.supplierSearchCriteria.serviceType);
                        chip.optionParams.data = choice;
                    }
                    if (chip.criteriaId === 'SERVICE_DATE') {
                        chip.optionParams.isInitialPresent = true;
                        chip.optionParams.data = [moment(this.supplierSearchCriteria.serviceDate).toDate()];
                    }
                    if (this.isVehicleAssignment) {
                        if (chip.criteriaId === 'TRANSFER_MODE') {

                            chip.optionParams.isInitialPresent = true;
                            const choice = chip.optionParams.options
                                .filter(option => option.code === this.supplierSearchCriteria.transferMode);
                            chip.optionParams.data = choice;
                            chip.optionParams.isMandatory = true;
                        }
                        if (chip.criteriaId === 'ROUTE') {

                            chip.optionParams.isInitialPresent = true;
                            const choice = chip.optionParams.options
                                .filter(option => option.code === this.supplierSearchCriteria.route);
                            chip.optionParams.data = choice;
                            chip.optionParams.isMandatory = true;
                        }
                        if (chip.criteriaId === 'VEHICLE_MODEL') {

                            chip.optionParams.isInitialPresent = true;
                            const choice = chip.optionParams.options
                                .filter(option => option.code === this.supplierSearchCriteria.vehicleModel);
                            chip.optionParams.data = choice;
                            chip.optionParams.isMandatory = true;
                        }
                    }
                });
                this.dataStoreService.set(DataKey.chips, this.availableChips, true);

                // trigger search when chips persisting
                this.isDataLoaded = false;
                // this.isTriggerSearch = false;
                this.onSupplierSearch(this.supplierSearchCriteria);


                this.chipInputService.init(this.supplierSearchCriteria, this.availableChips, false, true);

                // implement chip persistance
                if (this.persistentQParams && this.persistentQParams.length > 0) {
                    // create a queryParamMap
                    for (let i = 0; i < this.persistentQParams.length; i++) {
                        const qParam = this.persistentQParams[i];
                        const qParamValue = this.supplierSearchCriteria[qParam];
                        this.persistentQParamMap.set(qParam, qParamValue);
                    }
                    // fill persistent chips
                    this.availableChips.forEach(chip => {
                        const persistChip = Object.assign({}, chip);
                        persistChip.optionParams.data = [];
                        let isPersist = false;
                        if (chip.dataParams && chip.dataParams.length > 0) {
                            chip.dataParams.forEach(dataParam => {
                                if (this.persistentQParamMap.has(dataParam.paramValue)) {
                                    isPersist = true;
                                    const dataObj = {
                                        key: dataParam.paramValue,
                                        value: this.persistentQParamMap.get(dataParam.paramValue)
                                    };
                                    persistChip.optionParams.data.push(dataObj);
                                }
                            });
                        }
                        // push persist chips to an array
                        if (isPersist) {
                            this.persistentChipsArr.push(persistChip);
                        }
                    });
                    // persist chips
                    if (this.persistentChipsArr && this.persistentChipsArr.length > 0) {
                        const uniqueChipList = this.persistentChipsArr.filter((e, i) => {
                            return this.persistentChipsArr.findIndex((x) => {
                                return x.criteriaId === e.criteriaId;
                            }) === i;
                        });

                        this.chipInputService.persistChips(uniqueChipList);

                        // trigger search when chips persisting
                        this.isDataLoaded = false;
                        // this.isTriggerSearch = false;
                        this.onSupplierSearch(this.supplierSearchCriteria);
                    }
                }
                this.chipInputService.criteriaUpdate.subscribe((_data) => {
                    this.supplierSearchCriteria = _data;
                });
            }
        });
    }

    onSupplierSearch(event) {
        this.isDataLoaded = false;
        this.supplierSearchCriteria = event;

        if (this.tPagination && this.tPagination.initialFetchingPages &&
            this.tPagination.defaultPageResultCount) {
            this.supplierSearchCriteria.size = this.tPagination.initialFetchingPages *
                this.tPagination.defaultPageResultCount;
        }
        this.supplierSearchCriteria.start = 0;

        this.userJourneyManager.canProceed.next(false);

        if (JSON.stringify(this.supplierSearchCriteria) !== JSON.stringify({})) {
            this.dataStoreService.set(DataKey.supplierAllocationSearchCriteria, this.supplierSearchCriteria, true);
            this.dataStoreService.set(DataKey.supplierAllocationSearchResults, null, true);
            this.reservationV2Service.searchSuppliersForAllocationWithKey(this.supplierSearchCriteria);

            this.isTriggerSearch = false;
            this.isDataLoaded = false;
            this.searchTrigger.emit(true);
        } else {
            setTimeout(() => {
                this.isDataLoaded = true;
                this.isTriggerSearch = true;
            }, 0);
        }
    }

    getUpdatedFieldsDataObject(chip, criteria: SupplierAllocationSearchCriteria) {
        const dataObjArr = [];

        if (chip && chip.dataParams && chip.dataParams.length > 0) {
            chip.dataParams.forEach(dataParam => {
                const dataObj = {
                    key: '',
                    value: ''
                };
                dataObj.key = dataParam.paramValue;
                dataObj.value = criteria[dataParam.paramValue];

                dataObjArr.push(dataObj);
            });
        }

        return dataObjArr;
    }

    private getSupplierSearchObserver() {
        const x = this.dataStoreService.get(DataKey.supplierAllocationSearchResults).subscribe((data) => {
            if (this.commonHelper.dataValidity(data)) {
                setTimeout(() => {
                    this.isDataLoaded = true;
                    this.isTriggerSearch = true;
                }, 0);
            } else if (this.commonHelper.isEmptyData(data)) {
                this.isDataLoaded = true;
            } else if (data instanceof TcApiError) {
                this.isDataLoaded = true;
            } else if (data instanceof TcHttpError) {
                this.isDataLoaded = true;
            }
        }, (error: any) => {
        });
    }

    private errorObserver() {
        this.dataStoreService.get(DataKey.error).subscribe((data) => {
            if (data && JSON.stringify(data) !== JSON.stringify({})) {
                setTimeout(() => {
                    this.isDataLoaded = true;
                }, 0);
                this.dataStoreService.set(DataKey.error, null);
            }
        });
    }

    private resetQueryParams() {

        let supplierQueryParams: string[] = [];
        supplierQueryParams = this.queryParamService.getQueryParamsArray(new SupplierAllocationSearchCriteria());

        const supplierSearchCriteria: SupplierAllocationSearchCriteria = this.rootService.loadDataFromLocalStorage(supplierQueryParams,
            new SupplierAllocationSearchCriteria(),
            TCO.AppData.SUPPLIER_ALLOCATION_SEARCH_CRITERIA
        );

        // set criteria parameters to url
        let queryParams: string[] = [];
        queryParams = this.queryParamService.getQueryParamsArray(supplierSearchCriteria);

        this.rootService.setDataToLocalStorage(TCO.AppData.SUPPLIER_ALLOCATION_SEARCH_CRITERIA, this.supplierSearchCriteria,
            true, queryParams, true
        );
    }

    onCancel(event) {
        console.log('cancelled');
    }

    onChipListDisplay(event) {
        this.dataStoreService.set(DataKey.chipListDisplay, null, true);
        this.isChipListDisplay = event;
        this.dataStoreService.set(DataKey.chipListDisplay, this.isChipListDisplay, true);
    }

    handleQuickActions() {
        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'TRIGGER_SEARCH_CRITERIA').subscribe((e) => {
            this.triggerSearch(e.data);
        });
    }

    triggerSearch(data: string) {
        this.supplierSearchCriteria = new SupplierAllocationSearchCriteria();
        this.actionHandlerService.fillCriteriaByActionData(data, this.supplierSearchCriteria);
        const requiredChips = this.chipHandler.getChipsFromCriteria(
            this.supplierSearchCriteria,
            TCO.CONF.CONF_SUPPLIER_ALLOCATION_CHIP,
            true
        );
        this.chipInputService.hardReset();
        this.chipInputService.persistChips(requiredChips, true);
        this.onSupplierSearch(this.supplierSearchCriteria);
    }

    observeChipValueUpdate() {
    }

    private handleInterRelatedChips() {
        this.relatedChipsObsv = this.chipInputService.relatedChipsSubject.subscribe(
            (data) => {
                let toggleChips = [];
                if (data && data.chip && JSON.stringify(data) !== JSON.stringify({})) {
                    this.modifiedChip = data.chip;
                    // TODO: refine chip input service: inter related chips
                    if (data.chip.type === 'CALENDAR') {
                        this.chipHandler.handleInterRelatedChips(data.chip, data.date);
                    } else {
                        this.chipHandler.handleInterRelatedChips(data.chip, data.options.value[0]);
                    }
                    if (data.chip.optionParams.toggleChips && data.chip.optionParams.toggleChips.length > 0) {
                        toggleChips = data.chip.optionParams.toggleChips;
                        if (toggleChips && data.options && data.options.value[0].value) {
                            this.chipInputService.selectedChipsBasedOnStateChange(toggleChips);
                        } else {
                            this.chipInputService.deleteChipsBasedOnStateChange(toggleChips);
                        }
                    }
                }
            }
        );
    }

    resetResourceAssignChips() {
        this.availableChips.forEach((chip) => {
            if (chip.criteriaId === 'SERVICE_TYPE') {

                chip.optionParams.isInitialPresent = true;
                chip.optionParams.data = [];
            }
            if (chip.criteriaId === 'SERVICE_DATE') {

                chip.optionParams.isInitialPresent = false;
                chip.optionParams.data = [];
            }
            if (chip.criteriaId === 'TRANSFER_MODE') {

                chip.optionParams.isInitialPresent = false;
                chip.optionParams.data = [];
            }
            if (chip.criteriaId === 'ROUTE') {

                chip.optionParams.isInitialPresent = false;
                chip.optionParams.data = [];
            }
        });
    }

    ngOnDestroy() {
        this.routerChangeSubscription.unsubscribe();
        this.userJourneyManager.routeChanges.next(false);

        if (this.selectedChipsObsv) {
            this.selectedChipsObsv.unsubscribe();
        }

        if (this.selectedFieldsObsv) {
            this.selectedFieldsObsv.unsubscribe();
        }

        if (this.chipValueUpdateSub) {
            this.chipValueUpdateSub.unsubscribe();
        }
        if (this.criteriaUpdateSubscription) {
            this.criteriaUpdateSubscription.unsubscribe();
        }
        if (this.deletedChipObsv) {
            this.deletedChipObsv.unsubscribe();
        }

        this.em.removeEvents([
            'TRIGGER_SEARCH_CRITERIA'
        ]);
    }

    private selectedChipsUpdate() {
        this.criteriaUpdateSubscription = this.chipInputService.selectedChipsSubject.subscribe(selectedChips => {
            this.selectedChips = selectedChips;

            if (selectedChips && JSON.stringify(selectedChips) !== JSON.stringify([])) {
                // toggle chips when selecting BRANCH_OF_CLIENT_ID chip
                for (const selectedChip of this.selectedChips) {
                    if (selectedChip.chip.id === 'BRANCH_OF_CLIENT_ID' && selectedChip.chip.optionParams.toggleChips &&
                        selectedChip.chip.optionParams.toggleChips.length > 0) {
                        this.deleteToggleChips(selectedChip.chip.optionParams.toggleChips);
                        this.disableChips(selectedChip.chip.optionParams.toggleChips);
                        break;
                    }
                }
            }
        });
    }

    private notifyChipDelete() {
        this.deletedChipObsv = this.chipInputService.deletedChipsSubject.subscribe(deletedChip => {
            if (JSON.stringify(deletedChip) !== JSON.stringify({}) && deletedChip !== null && deletedChip !==
                undefined) {
                if (deletedChip.id === 'CLIENT_TYPE') {
                    const deleteChips = this.selectedChips.map(chip => chip.chip);
                    this.deleteSelectedChips(deleteChips);
                    this.enableChips(this.availableChips);
                } else if (deletedChip.id === 'BRANCH_OF_CLIENT_ID') {
                    if (deletedChip.optionParams.toggleChips && deletedChip.optionParams.toggleChips.length > 0) {
                        this.enableChips(deletedChip.optionParams.toggleChips);
                    }
                }
            }
        });
    }

    /**
     * Delete toggle chips if already selected
     *
     * @param toggleChips
     */
    private deleteToggleChips(toggleChips: any[]) {
        const deleteChips = [];
        toggleChips.forEach(toggleChip => {
            for (const selectedChip of this.selectedChips) {
                if (selectedChip.chip.id === toggleChip.criteriaId) {
                    deleteChips.push(selectedChip.chip);
                    break;
                }
            }
        });
        this.deleteSelectedChips(deleteChips);
    }

    private deleteSelectedChips(deleteChips: any[]) {
        deleteChips.forEach(chip => {
            this.chipInputService.deleteChip(chip, true);
        });
    }

    private enableChips(enableChips: any[]) {
        enableChips.forEach(enableChip => {
            this.chipInputService.modifyChipsStatus(enableChip, false);
        });
    }

    private disableChips(disableChips: any[]) {
        disableChips.forEach(disableChip => {
            this.chipInputService.modifyChipsStatus(disableChip, true);
        });
    }

}
