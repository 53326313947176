import * as tslib_1 from "tslib";
import { KeyControl } from '../common/key-control';
var Resource = /** @class */ (function (_super) {
    tslib_1.__extends(Resource, _super);
    function Resource() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.isEditTriggered = false;
        return _this;
    }
    return Resource;
}(KeyControl));
export { Resource };
export var ResourceBusinessType;
(function (ResourceBusinessType) {
    ResourceBusinessType["INDEPENDENT"] = "Independent";
    ResourceBusinessType["INTERNAL"] = "Internal";
    ResourceBusinessType["EXTERNAL"] = "External";
})(ResourceBusinessType || (ResourceBusinessType = {}));
