import * as tslib_1 from "tslib";
import { KeyControlAndAbstractSearchCriteria } from './key-control-and-abstract-search-criteria';
var DivisionSearchCriteria = /** @class */ (function (_super) {
    tslib_1.__extends(DivisionSearchCriteria, _super);
    function DivisionSearchCriteria() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.code = '';
        _this.name = '';
        _this.kcCompany = '';
        return _this;
    }
    return DivisionSearchCriteria;
}(KeyControlAndAbstractSearchCriteria));
export { DivisionSearchCriteria };
