import * as tslib_1 from "tslib";
import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TcApiError } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-api-error';
import { TcHttpError } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-http-error';
import { TC } from '@tc-core/util';
import { ConfigLoader, EventManager, UserJourneyManager } from '@tc-core/util/framework';
import { CommonHelper } from '@tc-core/util/helpers';
import { SpinnerService } from '@tc-core/util/ui';
import { ChipInputComponent } from '@tc/chip-input';
import { Subscription } from 'rxjs';
import { TCO } from '../../../constants';
import { LocationGroupsSearchCriteria } from '../../../models/criteria/location-groups-search-criteria';
import { ChipHandlerService } from '../../../services/helpers/chip-handler.service';
import { RootService } from '../../../services/util/core-services/root.service';
import { ActionHandlerService } from '../../../services/util/framework/action-handler-service.service';
import { DataKey, DataStoreService } from '../../../services/util/framework/data-store.service';
import { DMCQueryParamsService } from '../../../services/util/framework/dmc-query-params.service';
var LocationGroupsSetupSearchCriteriaComponent = /** @class */ (function () {
    function LocationGroupsSetupSearchCriteriaComponent(activatedRoute, configLoader, userJourneyManager, dataStoreService, actionHandlerService, em, chipHandler, queryParamService, commonHelper, spinnerService, rootService, router) {
        this.activatedRoute = activatedRoute;
        this.configLoader = configLoader;
        this.userJourneyManager = userJourneyManager;
        this.dataStoreService = dataStoreService;
        this.actionHandlerService = actionHandlerService;
        this.em = em;
        this.chipHandler = chipHandler;
        this.queryParamService = queryParamService;
        this.commonHelper = commonHelper;
        this.spinnerService = spinnerService;
        this.rootService = rootService;
        this.router = router;
        this.isInsideForm = false;
        this.locGroupsSearchCriteria = new LocationGroupsSearchCriteria();
        this.isSearchBarStyleNotAvailable = false;
        this.placeHolderText = 'Search Location Groups';
        this.searchTrigger = new EventEmitter();
        this.searchCriteria = new EventEmitter();
        this.journey = null;
        this.isChipsInit = false;
        this.availableChips = [];
        this.persistentChipsArr = [];
        this.persistentQParams = [];
        this.persistentQParamMap = new Map();
        this.isDataLoaded = true;
        this.isTriggerSearch = true;
        this.displayChipsArr = [];
        this.isFocus = false;
        this.isChipListDisplay = false;
        this.relatedChipsObsv = new Subscription();
        this.selectedChipsObsv = new Subscription();
        this.selectedFieldsObsv = new Subscription();
        this.chipValueUpdateSub = new Subscription();
        this.criteriaUpdateSubscription = new Subscription();
        this.deletedChipObsv = new Subscription();
        this.searchResultObserver = new Subscription();
        this.errorsObserver = new Subscription();
        this.userJourneyObserver = new Subscription();
        this.focusSearchBarObserver = new Subscription();
        this.closePopUpObserver = new Subscription();
        this.triggerSearchCriteriaObserver = new Subscription();
        this.selectedChips = [];
        this.chipHandlerSubject = new Subscription();
    }
    LocationGroupsSetupSearchCriteriaComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.chipInputService = this.locGroupsSearchChipInput.criteriaInputService;
        // clear data store
        this.dataStoreService.set(DataKey.accountingRuleSearchCriteria, null, true);
        this.handleQuickActions();
        this.userJourneyObserver = this.userJourneyManager.journey
            .subscribe(function (journey) {
            if (journey && JSON.stringify(journey) !== JSON.stringify({})) {
                _this.journey = journey.id;
            }
        });
        // resetting the view
        this.routerChangeSubscription = this.userJourneyManager.routeChanges
            .subscribe(function (e) {
            if (e) {
                _this.chipInputService.fullReset(); // fully reset chips
                _this.chipHandler.chipsArr = []; // reset chips array loaded from config
                // check journey begins from quick book
                if (!_this.isInsideForm) {
                    _this.getQueryParams();
                    _this.initSearchCriteriaChips();
                }
                console.log('displayChipsArr', _this.displayChipsArr);
                _this.resetQueryParams();
            }
        });
        if (!this.isInsideForm) {
            this.getQueryParams();
            if (!this.isChipsInit) {
                this.initSearchCriteriaChips(); // load everything from start
            }
            this.locGroupsSearchCriteria = new LocationGroupsSearchCriteria();
        }
        this.getSearchObserver();
        this.errorObserver();
        this.selectedChipsUpdate();
        this.handleInterRelatedChips();
        this.notifyChipDelete();
        this.shortCutObserver();
    };
    LocationGroupsSetupSearchCriteriaComponent.prototype.getQueryParams = function () {
        var queryParams = [];
        queryParams = this.queryParamService.getQueryParamsArray(new LocationGroupsSearchCriteria());
        this.locGroupsSearchCriteria = this.rootService.loadDataFromLocalStorage(queryParams, new LocationGroupsSearchCriteria(), TCO.AppData.LOCATION_GROUPS_SEARCH_CRITERIA);
        // this.accRuleSearchCriteria.blaBla = blaBla;
        // get persisting query params
        if (this.locGroupsSearchCriteria) {
            this.persistentQParams = [];
            this.persistentQParams = this.queryParamService.getNotNullQueryParamsArray(this.locGroupsSearchCriteria);
        }
    };
    LocationGroupsSetupSearchCriteriaComponent.prototype.shortCutObserver = function () {
        var _this = this;
        this.focusSearchBarObserver = this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'FOCUS_SEARCH_BAR')
            .subscribe(function (e) {
            _this.isFocus = true;
        });
        this.closePopUpObserver = this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'CLOSE_POPUPS')
            .subscribe(function (e) {
            _this.isFocus = false;
        });
    };
    LocationGroupsSetupSearchCriteriaComponent.prototype.onFocus = function (event) {
        this.isFocus = event;
    };
    LocationGroupsSetupSearchCriteriaComponent.prototype.initSearchCriteriaChips = function () {
        var _this = this;
        this.isChipsInit = true;
        this.chipHandler.chipsArr = [];
        // read chip config
        var criteriaChipConfigs = null;
        if (!this.isInsideForm) {
            var chipConfigs = this.configLoader.configurations.get(TCO.CONF.CONF_LOCATION_GROUP_SEARCH_CRITERIA_CHIP);
            criteriaChipConfigs = this.commonHelper.recreateJsonObject(chipConfigs);
        }
        else {
            var chipConfigs = this.configLoader.configurations.get(this.searchCriteriaChipConfig);
            criteriaChipConfigs = this.commonHelper.recreateJsonObject(chipConfigs);
        }
        if (criteriaChipConfigs && criteriaChipConfigs.chips) {
            criteriaChipConfigs.chips.forEach(function (chip) {
                chip = _this.chipHandler.preProcessChips(chip, criteriaChipConfigs.permissionKeys.keys);
            });
        }
        this.chipHandler.chipsSubject.subscribe(function (data) {
            var e_1, _a;
            if (data && JSON.stringify(data) !== JSON.stringify([]) && data.length ===
                criteriaChipConfigs.chips.length) {
                _this.availableChips = data; // new json object here;
                _this.chipHandler.chipsSubject.next(null);
                if (_this.chipHandlerSubject) {
                    _this.chipHandlerSubject.unsubscribe();
                }
                // set values to display chips
                _this.displayChipsArr = [];
                _this.displayChipsArr = _this.availableChips;
                _this.dataStoreService.set(DataKey.chips, _this.availableChips, true);
                _this.chipInputService.init(_this.locGroupsSearchCriteria, _this.availableChips, false, true);
                _this.isChipsInit = true;
                // implement chip persistence
                if (_this.persistentQParams && _this.persistentQParams.length > 0) {
                    try {
                        // create a queryParamMap
                        for (var _b = tslib_1.__values(_this.persistentQParams), _c = _b.next(); !_c.done; _c = _b.next()) {
                            var qParam = _c.value;
                            var qParamValue = _this.locGroupsSearchCriteria[qParam];
                            if (qParam && qParamValue) {
                                _this.persistentQParamMap.set(qParam, qParamValue);
                            }
                        }
                    }
                    catch (e_1_1) { e_1 = { error: e_1_1 }; }
                    finally {
                        try {
                            if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                        }
                        finally { if (e_1) throw e_1.error; }
                    }
                    _this.persistentChipsArr = [];
                    // fill persistent chips
                    _this.availableChips.forEach(function (chip) {
                        var persistChip = Object.assign({}, chip);
                        persistChip.optionParams.data = [];
                        var isPersist = false;
                        if (chip.dataParams && chip.dataParams.length > 0) {
                            chip.dataParams.forEach(function (dataParam) {
                                if (_this.persistentQParamMap.has(dataParam.paramValue)) {
                                    isPersist = true;
                                    var dataObj = {
                                        key: dataParam.paramValue,
                                        value: _this.persistentQParamMap.get(dataParam.paramValue)
                                    };
                                    persistChip.optionParams.data.push(dataObj);
                                }
                            });
                        }
                        // push persist chips to an array
                        if (isPersist) {
                            _this.persistentChipsArr.push(persistChip);
                        }
                    });
                    // persist chips
                    if (_this.persistentChipsArr && _this.persistentChipsArr.length > 0) {
                        var uniqueChipList = _this.persistentChipsArr.filter(function (e, i) {
                            return _this.persistentChipsArr.findIndex(function (x) {
                                return x.criteriaId === e.criteriaId;
                            }) === i;
                        });
                        _this.chipInputService.persistChips(uniqueChipList);
                    }
                }
                // trigger search when chips persisting
                _this.isDataLoaded = false;
                // this.isTriggerSearch = false;
                _this.onLocationGroupsSearch(_this.locGroupsSearchCriteria);
                _this.chipInputService.criteriaUpdate.subscribe(function (updatedCriteriaData) {
                    _this.locGroupsSearchCriteria = updatedCriteriaData;
                });
            }
        });
    };
    LocationGroupsSetupSearchCriteriaComponent.prototype.onLocationGroupsSearch = function (event) {
        var _this = this;
        this.isDataLoaded = false;
        this.locGroupsSearchCriteria = event;
        this.userJourneyManager.canProceed.next(false);
        if (JSON.stringify(this.locGroupsSearchCriteria) !== JSON.stringify({})) {
            this.dataStoreService.set(DataKey.locationGroupsSearchCriteria, this.locGroupsSearchCriteria, true);
            // set criteria parameters to url
            var queryParams = [];
            queryParams = this.queryParamService.getQueryParamsArray(this.locGroupsSearchCriteria);
            this.rootService.setDataToLocalStorage(TCO.AppData.LOCATION_GROUPS_SEARCH_CRITERIA, this.locGroupsSearchCriteria, true, queryParams, true);
            this.isTriggerSearch = false;
            this.isDataLoaded = false;
            this.searchTrigger.emit(true);
        }
        else {
            setTimeout(function () {
                _this.isDataLoaded = true;
                _this.isTriggerSearch = true;
            }, 0);
        }
    };
    LocationGroupsSetupSearchCriteriaComponent.prototype.getUpdatedFieldsDataObject = function (chip, criteria) {
        var dataObjArr = [];
        if (chip && chip.dataParams && chip.dataParams.length > 0) {
            chip.dataParams.forEach(function (dataParam) {
                var dataObj = {
                    key: '',
                    value: ''
                };
                dataObj.key = dataParam.paramValue;
                dataObj.value = criteria[dataParam.paramValue];
                dataObjArr.push(dataObj);
            });
        }
        return dataObjArr;
    };
    LocationGroupsSetupSearchCriteriaComponent.prototype.getSearchObserver = function () {
        var _this = this;
        this.searchResultObserver = this.dataStoreService.get(DataKey.locationGroupsSearchResultsForCriteria)
            .subscribe(function (data) {
            if (_this.commonHelper.dataValidity(data)) {
                _this.isDataLoaded = true;
                _this.isTriggerSearch = false;
            }
            else if (_this.commonHelper.isEmptyData(data)) {
                _this.isDataLoaded = true;
            }
            else if (data instanceof TcApiError) {
                _this.isDataLoaded = true;
            }
            else if (data instanceof TcHttpError) {
                _this.isDataLoaded = true;
            }
        }, function (error) {
            console.log(error);
        });
    };
    LocationGroupsSetupSearchCriteriaComponent.prototype.errorObserver = function () {
        var _this = this;
        this.errorsObserver = this.dataStoreService.get(DataKey.error)
            .subscribe(function (data) {
            if (data && JSON.stringify(data) !== JSON.stringify({})) {
                setTimeout(function () {
                    _this.isDataLoaded = true;
                }, 0);
                _this.dataStoreService.set(DataKey.error, null);
            }
        });
    };
    LocationGroupsSetupSearchCriteriaComponent.prototype.resetQueryParams = function () {
        var locGroupsQueryParams = [];
        locGroupsQueryParams = this.queryParamService.getQueryParamsArray(new LocationGroupsSearchCriteria());
        var locGroupSearchCriteria = this.rootService.loadDataFromLocalStorage([], new LocationGroupsSearchCriteria(), TCO.AppData.LOCATION_GROUPS_SEARCH_CRITERIA);
        // set criteria parameters to url
        var queryParams = [];
        queryParams = this.queryParamService.getQueryParamsArray(locGroupSearchCriteria);
        this.rootService.setDataToLocalStorage(TCO.AppData.LOCATION_GROUPS_SEARCH_CRITERIA, this.locGroupsSearchCriteria, true, queryParams, true);
    };
    LocationGroupsSetupSearchCriteriaComponent.prototype.onCancel = function ($event) {
        console.log('cancelled');
    };
    LocationGroupsSetupSearchCriteriaComponent.prototype.onChipListDisplay = function (event) {
        this.dataStoreService.set(DataKey.chipListDisplay, null, true);
        this.isChipListDisplay = event;
        this.dataStoreService.set(DataKey.chipListDisplay, this.isChipListDisplay, true);
    };
    LocationGroupsSetupSearchCriteriaComponent.prototype.handleQuickActions = function () {
        var _this = this;
        this.triggerSearchCriteriaObserver = this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'TRIGGER_SEARCH_CRITERIA')
            .subscribe(function (e) {
            _this.triggerSearch(e.data);
        });
    };
    LocationGroupsSetupSearchCriteriaComponent.prototype.triggerSearch = function (data) {
        this.locGroupsSearchCriteria = new LocationGroupsSearchCriteria();
        // todo criteria
        this.actionHandlerService.fillCriteriaByActionData(data, this.locGroupsSearchCriteria);
        var requiredChips = this.chipHandler.getChipsFromCriteria(this.locGroupsSearchCriteria, TCO.CONF.CONF_LOCATION_GROUP_SEARCH_CRITERIA_CHIP, true);
        this.chipInputService.hardReset();
        this.chipInputService.persistChips(requiredChips, true);
        this.onLocationGroupsSearch(this.locGroupsSearchCriteria);
    };
    LocationGroupsSetupSearchCriteriaComponent.prototype.handleInterRelatedChips = function () {
        var _this = this;
        this.relatedChipsObsv = this.chipInputService.relatedChipsSubject
            .subscribe(function (data) {
            var toggleChips = [];
            if (data && data.chip && JSON.stringify(data) !== JSON.stringify({})) {
                _this.modifiedChip = data.chip;
                // TODO: refine chip input service: inter related chips
                if (data.chip.type === 'CALENDAR') {
                    _this.chipHandler.handleInterRelatedChips(data.chip, data.date);
                }
                else {
                    _this.chipHandler.handleInterRelatedChips(data.chip, data.options.value[0]);
                }
                if (data.chip.optionParams.toggleChips &&
                    data.chip.optionParams.toggleChips.length > 0) {
                    toggleChips = data.chip.optionParams.toggleChips;
                    if (toggleChips && data.options && data.options.value[0].value) {
                        _this.chipInputService.selectedChipsBasedOnStateChange(toggleChips);
                    }
                    else {
                        _this.chipInputService.deleteChipsBasedOnStateChange(toggleChips);
                    }
                }
            }
        });
    };
    LocationGroupsSetupSearchCriteriaComponent.prototype.selectedChipsUpdate = function () {
        var _this = this;
        this.criteriaUpdateSubscription = this.chipInputService.selectedChipsSubject
            .subscribe(function (selectedChips) {
            var e_2, _a;
            _this.selectedChips = selectedChips;
            if (selectedChips && JSON.stringify(selectedChips) !==
                JSON.stringify([])) {
                try {
                    // toggle chips when selecting BRANCH_OF_CLIENT_ID chip
                    for (var _b = tslib_1.__values(_this.selectedChips), _c = _b.next(); !_c.done; _c = _b.next()) {
                        var selectedChip = _c.value;
                        if (selectedChip.chip.id === 'BRANCH_OF_CLIENT_ID' &&
                            selectedChip.chip.optionParams.toggleChips &&
                            selectedChip.chip.optionParams.toggleChips.length > 0) {
                            _this.deleteToggleChips(selectedChip.chip.optionParams.toggleChips);
                            _this.disableChips(selectedChip.chip.optionParams.toggleChips);
                            break;
                        }
                    }
                }
                catch (e_2_1) { e_2 = { error: e_2_1 }; }
                finally {
                    try {
                        if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                    }
                    finally { if (e_2) throw e_2.error; }
                }
            }
        });
    };
    LocationGroupsSetupSearchCriteriaComponent.prototype.notifyChipDelete = function () {
        var _this = this;
        this.deletedChipObsv = this.chipInputService.deletedChipsSubject
            .subscribe(function (deletedChip) {
            if (JSON.stringify(deletedChip) !== JSON.stringify({}) && deletedChip !== null &&
                deletedChip !==
                    undefined) {
                if (deletedChip.id === 'CLIENT_TYPE') {
                    var deleteChips = _this.selectedChips.map(function (chip) { return chip.chip; });
                    _this.deleteSelectedChips(deleteChips);
                    _this.enableChips(_this.availableChips);
                }
                else if (deletedChip.id === 'BRANCH_OF_CLIENT_ID') {
                    if (deletedChip.optionParams.toggleChips &&
                        deletedChip.optionParams.toggleChips.length > 0) {
                        _this.enableChips(deletedChip.optionParams.toggleChips);
                    }
                }
            }
        });
    };
    LocationGroupsSetupSearchCriteriaComponent.prototype.deleteToggleChips = function (toggleChips) {
        var _this = this;
        var deleteChips = [];
        toggleChips.forEach(function (toggleChip) {
            var e_3, _a;
            try {
                for (var _b = tslib_1.__values(_this.selectedChips), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var selectedChip = _c.value;
                    if (selectedChip.chip.id === toggleChip.criteriaId) {
                        deleteChips.push(selectedChip.chip);
                        break;
                    }
                }
            }
            catch (e_3_1) { e_3 = { error: e_3_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_3) throw e_3.error; }
            }
        });
        this.deleteSelectedChips(deleteChips);
    };
    LocationGroupsSetupSearchCriteriaComponent.prototype.deleteSelectedChips = function (deleteChips) {
        var _this = this;
        deleteChips.forEach(function (chip) {
            _this.chipInputService.deleteChip(chip, true);
        });
    };
    LocationGroupsSetupSearchCriteriaComponent.prototype.enableChips = function (enableChips) {
        var _this = this;
        enableChips.forEach(function (enableChip) {
            _this.chipInputService.modifyChipsStatus(enableChip, false);
        });
    };
    LocationGroupsSetupSearchCriteriaComponent.prototype.disableChips = function (disableChips) {
        var _this = this;
        disableChips.forEach(function (disableChip) {
            _this.chipInputService.modifyChipsStatus(disableChip, true);
        });
    };
    LocationGroupsSetupSearchCriteriaComponent.prototype.ngOnDestroy = function () {
        // Remove this criteria's query params
        // this.queryParamService.removeParamsRelatedToObject(new LocationGroupsSearchCriteria());
        this.routerChangeSubscription.unsubscribe();
        this.userJourneyManager.routeChanges.next(false);
        if (this.selectedChipsObsv) {
            this.selectedChipsObsv.unsubscribe();
        }
        if (this.selectedFieldsObsv) {
            this.selectedFieldsObsv.unsubscribe();
        }
        if (this.chipValueUpdateSub) {
            this.chipValueUpdateSub.unsubscribe();
        }
        if (this.criteriaUpdateSubscription) {
            this.criteriaUpdateSubscription.unsubscribe();
        }
        if (this.deletedChipObsv) {
            this.deletedChipObsv.unsubscribe();
        }
        if (this.searchResultObserver) {
            this.searchResultObserver.unsubscribe();
        }
        if (this.errorsObserver) {
            this.errorsObserver.unsubscribe();
        }
        if (this.userJourneyObserver) {
            this.userJourneyObserver.unsubscribe();
        }
        if (this.focusSearchBarObserver) {
            this.focusSearchBarObserver.unsubscribe();
        }
        if (this.closePopUpObserver) {
            this.closePopUpObserver.unsubscribe();
        }
        if (this.triggerSearchCriteriaObserver) {
            this.triggerSearchCriteriaObserver.unsubscribe();
        }
        this.em.removeEvents([
            'TRIGGER_SEARCH_CRITERIA'
        ]);
    };
    return LocationGroupsSetupSearchCriteriaComponent;
}());
export { LocationGroupsSetupSearchCriteriaComponent };
