/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../node_modules/@tc/paginator/paginator.component.ngfactory";
import * as i2 from "@tc/paginator/paginator.component";
import * as i3 from "./supplier-allocation-search-criteria/supplier-allocation-search-criteria.component.ngfactory";
import * as i4 from "../../../../services/helpers/chip-handler.service";
import * as i5 from "@tc/chip-input/chip-input.service";
import * as i6 from "@tc/chip-input/shared/parser.service";
import * as i7 from "@tc-core/util/system/date-formatter.service";
import * as i8 from "../../../../services/util/framework/data-store.service";
import * as i9 from "@tc-core/util/framework/config-loader.service";
import * as i10 from "@tc-core/util/helpers/common-helper.service";
import * as i11 from "../../../../services/backend-consumers/master-data-handler-service/data-handler.service";
import * as i12 from "./supplier-allocation-search-criteria/supplier-allocation-search-criteria.component";
import * as i13 from "../../../../services/util/core-services/root.service";
import * as i14 from "@tc-core/util/framework/event-manager.service";
import * as i15 from "@tc-core/util/framework/user-journey-manager.service";
import * as i16 from "../../../../services/util/framework/dmc-query-params.service";
import * as i17 from "../../../../services/backend-consumers/reservation-v2/reservation-v2-management.service";
import * as i18 from "@tc-core/util/ui/spinner.service";
import * as i19 from "../../../../services/util/framework/action-handler-service.service";
import * as i20 from "../../../../services/user-management/user-management.service";
import * as i21 from "../../../../widgets/shared/result-header/result-header.component.ngfactory";
import * as i22 from "../../../../widgets/shared/result-header/result-header.component";
import * as i23 from "./adv-supplier-allocation-search-results/adv-supplier-allocation-search-results.component.ngfactory";
import * as i24 from "./adv-supplier-allocation-search-results/adv-supplier-allocation-search-results.component";
import * as i25 from "../../../../services/util/change-detector/change-detector.service";
import * as i26 from "@angular/common";
import * as i27 from "./supplier-allocation-panel.component";
import * as i28 from "@tc-core/service/sorters/sorter.service";
import * as i29 from "../../../../services/backend-consumers/supplier-service/supplier.service";
import * as i30 from "@tc-core/util/ui/auto-scroll.service";
var styles_SupplierAllocationPanelComponent = [];
var RenderType_SupplierAllocationPanelComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SupplierAllocationPanelComponent, data: {} });
export { RenderType_SupplierAllocationPanelComponent as RenderType_SupplierAllocationPanelComponent };
function View_SupplierAllocationPanelComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-paginator", [], null, [[null, "page"], [null, "more"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("page" === en)) {
        var pd_0 = (_co.onPage($event) !== false);
        ad = (pd_0 && ad);
    } if (("more" === en)) {
        var pd_1 = (_co.onMore() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_PaginatorComponent_0, i1.RenderType_PaginatorComponent)), i0.ɵdid(1, 638976, null, 0, i2.PaginatorComponent, [], { length: [0, "length"], index: [1, "index"], size: [2, "size"], hasMore: [3, "hasMore"] }, { page: "page", more: "more" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.contentCardDataList.length; var currVal_1 = _co.pager.currPage; var currVal_2 = _co.pager.itemsPerPage; var currVal_3 = _co.hasMore; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_SupplierAllocationPanelComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 15, "div", [["class", "focus-view__content-wrapper"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 6, "div", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 5, "div", [["class", "assign-panel-search"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 4, "tc-supplier-allocation-search-criteria", [], null, null, null, i3.View_SupplierAllocationSearchCriteriaComponent_0, i3.RenderType_SupplierAllocationSearchCriteriaComponent)), i0.ɵprd(6144, null, "ChipHandlerService", null, [i4.ChipHandlerService]), i0.ɵprd(4608, null, i5.ChipInputService, i5.ChipInputService, [i6.Parser, i7.DateFormatter]), i0.ɵprd(512, null, i4.ChipHandlerService, i4.ChipHandlerService, [i0.Injector, i8.DataStoreService, i9.ConfigLoader, i10.CommonHelper, i7.DateFormatter, i11.DataHandlerService]), i0.ɵdid(7, 245760, null, 0, i12.SupplierAllocationSearchCriteriaComponent, [i9.ConfigLoader, i4.ChipHandlerService, i13.RootService, i14.EventManager, i15.UserJourneyManager, i16.DMCQueryParamsService, i10.CommonHelper, i17.ReservationV2ManagementService, i18.SpinnerService, i8.DataStoreService, i19.ActionHandlerService, i20.UserManagementService], { inputSearchCriteria: [0, "inputSearchCriteria"], isSearchBarStyleNotAvailable: [1, "isSearchBarStyleNotAvailable"], placeHolderText: [2, "placeHolderText"], isTriggerSearch: [3, "isTriggerSearch"] }, null), (_l()(), i0.ɵeld(8, 0, null, null, 2, "div", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 1, "tc-result-header", [], null, [[null, "selectSorter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectSorter" === en)) {
        var pd_0 = (_co.onSort($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i21.View_ResultHeaderComponent_0, i21.RenderType_ResultHeaderComponent)), i0.ɵdid(10, 114688, null, 0, i22.ResultHeaderComponent, [], { transferModeList: [0, "transferModeList"], heading: [1, "heading"], groupingOptionList: [2, "groupingOptionList"], sorters: [3, "sorters"] }, { selectSorter: "selectSorter" }), (_l()(), i0.ɵeld(11, 0, null, null, 2, "div", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 1, "tc-adv-supplier-allocation-search-results", [], null, [[null, "supplierSelect"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("supplierSelect" === en)) {
        var pd_0 = (_co.onSupplierSelect($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i23.View_AdvSupplierAllocationSearchResultsComponent_0, i23.RenderType_AdvSupplierAllocationSearchResultsComponent)), i0.ɵdid(13, 114688, null, 0, i24.AdvSupplierAllocationSearchResultsComponent, [i25.ChangeDetectorService], { paxCount: [0, "paxCount"], resultsList: [1, "resultsList"], pager: [2, "pager"] }, { supplierSelect: "supplierSelect" }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SupplierAllocationPanelComponent_1)), i0.ɵdid(15, 16384, null, 0, i26.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.supplierSearchCriteria; var currVal_1 = true; var currVal_2 = _co.placeHolderText; var currVal_3 = true; _ck(_v, 7, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = i0.ɵEMPTY_ARRAY; var currVal_5 = _co.heading; var currVal_6 = i0.ɵEMPTY_ARRAY; var currVal_7 = _co.sorters_; _ck(_v, 10, 0, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_8 = _co.paxCount; var currVal_9 = _co.contentCardDataList; var currVal_10 = _co.pager; _ck(_v, 13, 0, currVal_8, currVal_9, currVal_10); var currVal_11 = (_co.contentCardDataList && (_co.contentCardDataList.length > _co.defaultPageResultCount)); _ck(_v, 15, 0, currVal_11); }, null); }
export function View_SupplierAllocationPanelComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-supplier-allocation-panel", [], null, null, null, View_SupplierAllocationPanelComponent_0, RenderType_SupplierAllocationPanelComponent)), i0.ɵdid(1, 114688, null, 0, i27.SupplierAllocationPanelComponent, [i28.SorterService, i16.DMCQueryParamsService, i8.DataStoreService, i9.ConfigLoader, i29.SupplierService, i10.CommonHelper, i30.AutoScrollService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SupplierAllocationPanelComponentNgFactory = i0.ɵccf("tc-supplier-allocation-panel", i27.SupplierAllocationPanelComponent, View_SupplierAllocationPanelComponent_Host_0, { placeHolderText: "placeHolderText", selectedSupplier: "selectedSupplier", currencyFormat: "currencyFormat", tAssignedSuppliers: "tAssignedSuppliers", contentCardDataList: "contentCardDataList", selectedSummaryCardData: "selectedSummaryCardData", bookingDataList: "bookingDataList", changesAvailableForServiceItems: "changesAvailableForServiceItems", heading: "heading", hasMore: "hasMore", fetchedSupplierCount: "fetchedSupplierCount", dataFromMoreClick: "dataFromMoreClick", isPrimary: "isPrimary", isSecondary: "isSecondary", isGenResourceAssignment: "isGenResourceAssignment", pager: "pager", paxCount: "paxCount", supplierSearchCriteria: "supplierSearchCriteria", startTime: "startTime", endTime: "endTime", bookingIdList: "bookingIdList", itemList: "itemList" }, { supplierSelect: "supplierSelect", onPaginationMoreClick: "onPaginationMoreClick" }, []);
export { SupplierAllocationPanelComponentNgFactory as SupplierAllocationPanelComponentNgFactory };
