/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../cg-calendar/cg-calendar.component.ngfactory";
import * as i2 from "../../cg-calendar/cg-calendar.component";
import * as i3 from "./season-calendar-body.component";
var styles_SeasonCalendarBodyComponent = [];
var RenderType_SeasonCalendarBodyComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SeasonCalendarBodyComponent, data: {} });
export { RenderType_SeasonCalendarBodyComponent as RenderType_SeasonCalendarBodyComponent };
export function View_SeasonCalendarBodyComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-cg-calendar", [], null, [[null, "selectDate"], [null, "menuItemClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectDate" === en)) {
        var pd_0 = (_co.onSelectDate($event) !== false);
        ad = (pd_0 && ad);
    } if (("menuItemClick" === en)) {
        var pd_1 = (_co.onMenuItemClick($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_CgCalendarComponent_0, i1.RenderType_CgCalendarComponent)), i0.ɵdid(1, 4440064, null, 0, i2.CgCalendarComponent, [], { fromYear: [0, "fromYear"], toYear: [1, "toYear"], rangeGroups: [2, "rangeGroups"], calendarRefreshEvent: [3, "calendarRefreshEvent"], calendarMenuItems: [4, "calendarMenuItems"] }, { selectDate: "selectDate", menuItemClick: "menuItemClick" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.fromYear; var currVal_1 = _co.toYear; var currVal_2 = _co.rangeGroups; var currVal_3 = _co.calendarRefreshEvent; var currVal_4 = _co.calendarMenuItems; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
export function View_SeasonCalendarBodyComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-season-calendar-body", [], null, null, null, View_SeasonCalendarBodyComponent_0, RenderType_SeasonCalendarBodyComponent)), i0.ɵdid(1, 114688, null, 0, i3.SeasonCalendarBodyComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SeasonCalendarBodyComponentNgFactory = i0.ɵccf("tc-season-calendar-body", i3.SeasonCalendarBodyComponent, View_SeasonCalendarBodyComponent_Host_0, { fromYear: "fromYear", toYear: "toYear", rangeGroups: "rangeGroups", calendarRefreshEvent: "calendarRefreshEvent", calendarMenuItems: "calendarMenuItems", viewOnlyMode: "viewOnlyMode" }, { selectDate: "selectDate", menuItemClick: "menuItemClick" }, []);
export { SeasonCalendarBodyComponentNgFactory as SeasonCalendarBodyComponentNgFactory };
