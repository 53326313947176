import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CurrencyFormat } from '@tc-core/model/it/codegen/ui/framework/currency-format';
import { Pager } from '@tc-core/model/it/codegen/ui/framework/pager';
import { Sorter } from '@tc-core/model/it/codegen/ui/framework/sorter';
import { SummaryCardData } from '@tc-core/model/it/codegen/ui/framework/summary-card-data';
import { SorterService } from '@tc-core/service/sorters/sorter.service';
import { ConfigLoader } from '@tc-core/util/framework/config-loader.service';
import { CommonHelper } from '@tc-core/util/helpers/common-helper.service';
import { AutoScrollService } from '@tc-core/util/ui/auto-scroll.service';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { TCO } from '../../../../constants';
import { ServiceItemChanges } from '../../../../models/helper/service-item-changes';
import { AssignmentRawData } from '../../../../models/reservation-v2/assignment-raw-data';
import { BookingItem } from '../../../../models/reservation-v2/booking-item';
import {
    SupplierAllocationSearchCriteria
} from '../../../../models/reservation-v2/criteria/supplier-allocation-search-criteria';
import { PaxCount } from '../../../../models/reservation-v2/pax-count';
import { ServiceItem } from '../../../../models/reservation-v2/service-item';
import { ResourceType } from '../../../../models/reservation/assignment';
import {
    SupplierSearchType
} from '../../../transfer-supplier-search-criteria/transfer-supplier-search-criteria.component';
import { SupplierService } from '../../../../services/backend-consumers/supplier-service/supplier.service';
import { DataStoreService } from '../../../../services/util/framework/data-store.service';
import { DMCQueryParamsService } from '../../../../services/util/framework/dmc-query-params.service';

@Component({
    selector: 'tc-supplier-allocation-panel',
    templateUrl: './supplier-allocation-panel.component.html'
})
export class SupplierAllocationPanelComponent implements OnInit {

    @Input() placeHolderText = 'Search Supplier';
    @Input() selectedSupplier: ServiceItemChanges;
    @Input() currencyFormat: CurrencyFormat;
    @Input() tAssignedSuppliers: any = null;
    @Input() contentCardDataList = [];
    @Input() selectedSummaryCardData: any = null;
    @Input() bookingDataList: BookingItem[];
    @Input() changesAvailableForServiceItems: boolean;
    @Input() heading = '';
    @Input() hasMore = true;
    @Input() fetchedSupplierCount = 0;
    @Input() dataFromMoreClick = false;
    @Input() isPrimary = true;
    @Input() isSecondary = false;
    @Input() isGenResourceAssignment = false;
    @Input() pager: Pager;
    @Input() paxCount: PaxCount;
    @Input() supplierSearchCriteria = new SupplierAllocationSearchCriteria();
    @Input() startTime: any;
    @Input() endTime: any;
    @Input() bookingIdList: number[] = [];
    @Input() itemList: (BookingItem | ServiceItem)[] = [];

    @Output() supplierSelect: EventEmitter<any> = new EventEmitter();
    @Output() onPaginationMoreClick: EventEmitter<any> = new EventEmitter();

    _searchType = SupplierSearchType.ResourceAssign;
    sorters_: Sorter[];
    defaultVehicleModel = 'ANY';

    //pagination

    public defaultPageResultCount = 4;
    selectionIndex = '';
    tPagination: any;

    private moreResultObsv: Subscription;

    constructor(
        public sorterService: SorterService,
        private queryParamService: DMCQueryParamsService,
        public dataStoreService: DataStoreService,
        private configLoader: ConfigLoader,
        private supplierService: SupplierService,
        private commonHelper: CommonHelper,
        private autoScrollService: AutoScrollService
    ) {
    }

    ngOnInit() {
        this.tPagination = this.configLoader.configurations.get(TCO.CONF.CONF_PAGINATION_CONFIG)['RESOURCE_ALLOCATION_SEARCH'];

        this.createSorters();
        this.createSupplierSearchCriteria();

        this.setupPaginator();

    }

    createSorters() {
        if (this.tAssignedSuppliers) {
            this.sorterService.sorters = [];
            this.sorters_ = [];
            let sorterMap = new Map<string, Sorter>();
            this.tAssignedSuppliers.config.placeHolderConfig.forEach(config => {
                if (config && config.sort) {
                    let sorter = this.sorterService.createSorter(config);
                    let id = config['_id'];
                    sorterMap.set(id, sorter);
                    this.sorters_.push(sorter);
                }
            });
        }
    }

    public onSort(event) {
        this.sorterService.sorter = event;
    }

    public onSupplierSelect(summaryCardData: SummaryCardData) {
        this.selectedSummaryCardData = summaryCardData && summaryCardData.data ? summaryCardData.data.value : null;

        if (summaryCardData) {
            this.supplierSelect.emit(this.selectedSummaryCardData);
        }
    }

    createSupplierSearchCriteria() {
        let previousAssignmentDetail: AssignmentRawData;
        this.supplierSearchCriteria.kcCompany = "DMC"
        this.supplierSearchCriteria.serviceDate = this.bookingDataList[0].serviceDate;
        this.supplierSearchCriteria.costingType = this.bookingDataList[0].costingType &&
        this.bookingDataList[0].costingType.code ?
            this.bookingDataList[0].costingType.code :
            this.bookingDataList[0].costingType;
        this.supplierSearchCriteria.noOfUnits = this.bookingDataList[0].noOfUnits;
        if (this.bookingDataList[0].genBookingItem) {
            this.supplierSearchCriteria.serviceType = this.bookingDataList[0].genBookingItem.productCode;
            if (this.isSecondary) {
                previousAssignmentDetail = this.bookingDataList[0].secondaryAssignment;
            } else {
                previousAssignmentDetail = this.bookingDataList[0].primaryAssignment;
            }
            this.supplierSearchCriteria.serviceStartTime = this.startTime ?
                moment(new Date(this.startTime)).format('HH:mm') :
                moment(new Date(this.itemList[0].serviceDate)).format('HH:mm');
            this.supplierSearchCriteria.serviceEndTime = this.endTime ?
                moment(new Date(this.endTime)).format('HH:mm') :
                moment(new Date(this.itemList[0].serviceDate)).format('HH:mm');
            this.supplierSearchCriteria.profileType = this.bookingDataList[0].genBookingItem ?
                this.bookingDataList[0].genBookingItem.typeCode :
                '';
        } else if (this.bookingDataList[0].trsBookingItem) {
            this.supplierSearchCriteria.serviceType = this.isPrimary ? ResourceType.vehicle : ResourceType.driver;
            this.supplierSearchCriteria.transferMode = this.bookingDataList[0].trsBookingItem.transferMode;
            this.supplierSearchCriteria.route = this.bookingDataList[0].trsBookingItem.route;
            this.supplierSearchCriteria.vehicleModel = this.bookingDataList[0].trsBookingItem.vehicleModel ?
                this.bookingDataList[0].trsBookingItem.vehicleModel :
                this.defaultVehicleModel;
            previousAssignmentDetail = this.bookingDataList[0].primaryAssignment;
            this.supplierSearchCriteria.serviceStartTime = this.startTime ?
                moment(new Date(this.startTime)).format('HH:mm') :
                moment(new Date(this.itemList[0].trsBookingItem.departureDateTime)).format('HH:mm');
            this.supplierSearchCriteria.serviceEndTime = this.endTime ?
                moment(new Date(this.endTime)).format('HH:mm') :
                moment(new Date(this.itemList[0].trsBookingItem.arrivalDateTime)).format('HH:mm');

        }
        this.supplierSearchCriteria.adultCount = this.paxCount.adultCount;
        this.supplierSearchCriteria.childCount = this.paxCount.childCount;
        this.supplierSearchCriteria.infantCount = this.paxCount.infantCount;
        this.supplierSearchCriteria.bookingIdList = this.bookingIdList;

        this.supplierSearchCriteria.kcCompany = this.bookingDataList[0].kcCompany;
        this.supplierSearchCriteria.kcDivision = this.bookingDataList[0].kcDivision;
        this.supplierSearchCriteria.kcBrand = this.bookingDataList[0].kcBrand;
        this.supplierSearchCriteria.kcDistributionChannel = this.bookingDataList[0].kcDistributionChannel;

        this.supplierSearchCriteria.rejectedSupplierIdList = previousAssignmentDetail ?
            previousAssignmentDetail.rejectedSupplierIdList :
            [];
    }

    private setupPaginator() {
        this.defaultPageResultCount = this.tPagination['defaultPageResultCount'];
        this.pager = new Pager(0, this.defaultPageResultCount);
    }

    public onPage(pageNumber: number) {
        this.pager.currPage = pageNumber;
        this.selectionIndex = (this.pager.currPage * this.pager.itemsPerPage).toString();
    }

    public onMore() {
        this.dataFromMoreClick = true;
        if (this.tPagination && this.tPagination['defaultPageResultCount'] && this.tPagination['fetchingPages']) {
            this.supplierSearchCriteria.size = this.tPagination['fetchingPages'] *
                this.tPagination['defaultPageResultCount'];
            this.onPaginationMoreClick.emit(this.supplierSearchCriteria);
        }
    }

    public onSelection(event) {
        this.selectionIndex = event;
    }

    public onCardScroll($event: number) {
        setTimeout(() => {
            this.autoScrollService.scrollToID(
                'clientDetailExp_' + $event, 'supplier-assign-scroll-container');
        }, 0);
    }

}
