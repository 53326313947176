<div class="detail-group__heading container-fluid">
    <div class="item-header">
        <div class="item-header__content">{{resultHeaderHeading | translate}}</div>
        <div class="tc-icon-block action-icon">
            <!--            <span *ngIf="!readOnly"-->
            <!--                     matTooltip="{{'Add '+itemTypeSingular}}"-->
            <!--                    class="tc-icon-block__icon tc-icon-block__icon&#45;&#45;active ng-star-inserted">-->
            <!--                <i class="material-icons">add</i>-->
            <!--            </span>-->

            <!--            <button-->
            <!--                    matTooltip="{{'Show/Hide Columns'}}"-->
            <!--                    mat-icon-button-->
            <!--                    [matMenuTriggerFor]="beforeMenu"-->
            <!--                    [disabled]="">-->
            <!--                <i class="material-icons">view_column</i>-->
            <!--            </button>-->
            <!--            <mat-menu #beforeMenu="matMenu" xPosition="before">-->
            <!--                <mat-selection-list-->
            <!--                    #selectedColFields-->
            <!--                    style="max-height: 400px;"-->
            <!--                    (selectionChange)=onSelectionChange($event)>-->
            <!--                    <mat-list-option-->
            <!--                            *ngFor="let coldef of columnDefs"-->
            <!--                            [value]="coldef.colId"-->
            <!--                            [selected]="!coldef.hide"-->
            <!--                            (click)="onClickColOption($event)">-->
            <!--                        {{coldef.headerName}}-->
            <!--                    </mat-list-option>-->
            <!--                </mat-selection-list>-->
            <!--            </mat-menu>-->
            <button mat-icon-button
                     aria-label="Delete"
                     (click)="onRestoreClick()"
                     matTooltip="{{'Reset Columns' | translate}}"
                     [disabled]="">
                <i class="material-icons">settings_backup_restore</i>
            </button>
            <button
                    matTooltip="{{'Show/Hide Columns' | translate}}"
                    mat-icon-button
                    [matMenuTriggerFor]="beforeMenu"
                    [disabled]="">
                <i class="material-icons">view_column</i>
            </button>
            <mat-menu #beforeMenu="matMenu" xPosition="before">
                <mat-selection-list
                    #colSelector
                    style="max-height: 400px;"
                    (selectionChange)=onSelectionChange($event)>
                    <mat-list-option
                            *ngFor="let coldef of columnDefs"
                            [value]="coldef.field"
                            [selected]="!coldef.hide"
                            (click)="onClickColOption($event)">
                        {{coldef.headerName | translate}}
                    </mat-list-option>
                </mat-selection-list>
            </mat-menu>
        </div>
    </div>
</div>


<!--<tc-result-header-->
<!--        [heading]="resultHeaderHeading">-->
<!--</tc-result-header>-->

<ag-grid-angular
        tcPaginatorCustomizer
        #agGrid
        id="myGrid"
        class="ag-theme-material tc-ag-grid tc-ag-grid--cell-merge"

        [paginatorLoaded]="paginatorLoaded"
        [buttonStateObs]="buttonStateObs"

        [columnDefs]="columnDefs"
        [rowData]="genericGroupResults"
        [rowClassRules]="rowClassRules"
        [components]="components"
        [defaultColDef]="defaultColDef"
        [gridOptions]="gridOptions"
        [suppressRowTransform]="true"
        [getRowHeight]="getRowHeight"
        [getRowStyle]="getRowStyle"
        [colResizeDefault]="colResizeDefault"
        [overlayNoRowsTemplate]="overlayNoRowsTemplate"
        [frameworkComponents]="frameworkComponents"
        [suppressRowClickSelection]="true"
        [isRowSelectable]="isRowSelectable"
        [paginationPageSize]="paginationPageSize"

        (rowSelected)="onRowSelected($event)"
        (sortChanged)="onSortChanged($event)"
        (filterChanged)="onFilterChanged($event)"
        (columnGroupOpened)="onColumnGroupOpen($event)"
        (gridReady)="onGridReady($event)"
        (cellValueChanged)="onCellValueChanged($event)"
        (clickMore)="onClickMore()"
        (clickSave)="onClickSave()"
        (clickConfirm)="onClickConfirm()"
        (clickSupplierReject)="onSupplierRejectClick()">
</ag-grid-angular>

<!--tour column view-->
<ng-template #tourCell let-row>
    <span *ngIf="row?.tourName && row?.itineraryItems" matTooltip="{{row.tourName}}">{{row.tourName}}</span>
</ng-template>

<!--tour column view-->
<ng-template #dateCell let-row>
    <span *ngIf="row?.serviceDate">{{row.serviceDate}}</span>
</ng-template>

<!--item name column view-->
<ng-template #itemNameCell let-row>
<!--    <i *ngIf="showPrivateIcon(row)" class="fa fa-user-secret ag-status-flag"></i>-->
    <i *ngIf="showPrivateIcon(row)" class="fa fa-lg fa-user-secret tc-mx-1"></i>
    <span *ngIf="row?.tourItemName && row.tourItemName"> {{row.tourItemName}}</span>
</ng-template>


<!--item category column view-->
<ng-template #itemCategoryCell let-row>
    <!--<span> <mat-icon aria-hidden="false">{{getRelatedIcon(row)}}</mat-icon></span>-->
    <!--icon-->
    <div class="tc-ag-grid__icon-group">
        <span *ngFor="let icon of [getRelatedIcon(row)]" class="tc-ag-grid__icon-group-icon">
            <span *ngIf="icon && icon.type && icon.type==='ico'">
              <mat-icon aria-hidden="false">{{icon.icon}}</mat-icon>
            </span>
            <span *ngIf="icon && icon.type && icon.type==='img'">
              <img [src]="icon.icon">
            </span>
            <span *ngIf="icon && icon.type && icon.type==='fa_ico'">
                <i [ngClass]="icon.icon"></i>
            </span>
        </span>
        <span class="tc-ag-grid__icon-group-text"> {{row?.categoryName ? row.categoryName : EMPTY_STRING}}{{row?.typeName ? " - " + row.typeName : ""}}</span>
    </div>

</ng-template>

<!--item type column view-->
<ng-template #itemTypeCell let-row>
    <span *ngIf="row?.typeName"> {{row.typeName}}</span>
</ng-template>

<!--<ng-template #totalPaxCell let-row>-->
<!--    <span *ngIf="row?.total"> {{row.total}}( Adts. {{row.adults}}| Chls. {{row.children}}| Infs.{{row.infants}})</span>-->
<!--</ng-template>-->

<!--time column view-->
<ng-template #timeCell let-row>
    <span *ngIf="row?.time"> {{row.time}}</span>
</ng-template>

<!--allocated column view-->
<ng-template #allocatedCell let-row>
    <span> {{row?.allocated ? row.allocated : EMPTY_STRING}}</span>
</ng-template>

<!--statusa and job id column view-->
<ng-template #assignStatusCell let-row>
    <div *ngIf="showAssignmentIncomplete(row)" class="tc-ag-grid-indicator tc-ag-grid-indicator--danger" matTooltip="{{getIncompleteToolTip(row)}}" matTooltipPosition="right"
         aria-label="indicator tooltip" matTooltipClass="mat-tooltip--large mat-tooltip--danger">
    </div>
    <div
            *ngIf="row && row.assignStatus && row.assignStatus !== PENDING"
            class="tc-status-badge tc-status-badge-grid"
            [ngClass]="{
                'tc-status-badge--warning': (row.assignStatus === DMC_CONFIRM || row.assignStatus === INACTIVE_NOTIFY),
                'tc-status-badge--success': (row.assignStatus === SUPPLIER_CONFIRMED || row.assignStatus === COMPLETED),
                'tc-status-badge--danger': (row.assignStatus === SUPPLIER_REJECTED || row.assignStatus === INACTIVE || row.assignStatus === NO_SHOW)
            }"
            style="right: 0 !important;top: -10px !important;float: left">
        <span class="tc-status-badge__text">{{statusCodes[row.assignStatus]}}</span>
    </div>
    <span style="margin-left: 10px">{{row?.assignmentId ? row.assignmentId : EMPTY_STRING}}</span>
</ng-template>

<!--supplier item column view-->
<ng-template #supplierItemCell let-row>
    <span> {{row?.supplierItemName ? row.supplierItemName : EMPTY_STRING}}</span>
</ng-template>

<!--supplier column view-->
<ng-template #supplierCell let-row>
    <span> {{row?.supplierName ? row.supplierName : EMPTY_STRING}}</span>
</ng-template>

<!--resource column view-->
<ng-template #resourceCell let-row>
    <span> {{row?.resourceName ? row.resourceName : EMPTY_STRING}}</span>
</ng-template>

<!--startTime column view-->
<ng-template #startTimeCell let-row>
    <span> {{row?.startTime ? row.startTime : EMPTY_STRING}}</span>
</ng-template>

<!--endTime column view-->
<ng-template #endTimeCell let-row>
    <span> {{row?.endTime ? row.endTime : EMPTY_STRING}}</span>
</ng-template>

<!--notes column view-->
<ng-template #notesCell let-row>
    <ng-container *ngIf="row && row.assignmentNotes">
        <span *ngIf="row.assignmentNotes.length===0">{{EMPTY_STRING}}</span>
        <span *ngIf="row.assignmentNotes[0]">
             {{row.assignmentNotes[0].text | truncate:[10, "... "]}}
            <span *ngIf="row.assignmentNotes.length > 1" class="badge"> +{{(row.assignmentNotes.length - 1)}}</span>
            <!--{{(row.assignmentNotes.length > 1 ? "+" + (row.assignmentNotes.length - 1) : '')}}-->
        </span>
    </ng-container>

    <div *ngIf="row && row.itineraryNotes" (click)="onClickGroupNote()">
        <span *ngIf="row.itineraryNotes.length===0">{{EMPTY_STRING}}</span>
        <span *ngFor="let note of row.itineraryNotes">
            {{note.text}}
        </span>
    </div>
</ng-template>


<!--menu column view-->
<ng-template #menuCell let-row let-params="params">
    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Generic queue assignment menu">
        <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
        <button mat-menu-item [disabled]="isAddAssignmentRestricted(params)" (click)="onAddAssignment(params)">
            <mat-icon>add</mat-icon>
            <span>Add</span>
        </button>
        <button mat-menu-item [disabled]="isDeleteAssignmentRestricted(params)" (click)="onDeleteAssignment(params)">
            <mat-icon>delete</mat-icon>
            <span>Delete</span>
        </button>
        <button mat-menu-item [disabled]="isOnAssignmentRestricted(params)" (click)="onAssignment(params)">
            <mat-icon>more</mat-icon>
            <span>Allocate</span>
        </button>
        <button
                mat-menu-item
                (click)="onSelectServiceItems(params)">
            <mat-icon>people</mat-icon>
            <span>Passengers</span>
        </button>
        <button mat-menu-item (click)="onNotesClick(params)">
            <mat-icon>note</mat-icon>
            <span>Notes</span>
        </button>
        <button mat-menu-item (click)="onShowCostBreakDown(params)">
            <mat-icon>local_atm</mat-icon>
            <span>Cost Data</span>
        </button>
    </mat-menu>
</ng-template>



