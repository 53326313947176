import { PassengerAttribute } from './passenger-attribute';
import { PassengerType } from './passenger-type';

export class BookingPassenger {
    id: number;
    tempId: number; // only to identify passengers in booking and item
    type: PassengerType;
    typeName: string;
    lead: boolean;

    /* name */
    title: string;
    firstName: string;
    middleName: string;
    lastName: string;

    /* contact info */
    telephone: string;
    email: string;

    /* address */
    passportNo: string;
    citizenship: string;
    houseNo: string;
    street: string;
    city: string;
    state: string;
    country: string;
    postalCode: string;

    passengerAttributes: PassengerAttribute[];

    // readonly data. not using for saving.
    bookingId: number;

    static contentEquals(p1: BookingPassenger, p2: BookingPassenger): boolean {
        let attOk = true;
        if (!(p1.passengerAttributes && p2.passengerAttributes) && !(!p1.passengerAttributes && !p2.passengerAttributes)) {
            attOk = false;
        }
        if (attOk && p1.passengerAttributes && p1.passengerAttributes.length) {
            for (const p1Att of p1.passengerAttributes) {
                const found = p2.passengerAttributes.find(p2Att => p2Att.name === p1Att.name && p2Att.value === p1Att.value);
                if (!found) {
                    attOk = false;
                    break;
                }
            }
        }
        return attOk && p1.type === p2.type
            && p1.title === p2.title
            && p1.firstName === p2.firstName
            && p1.middleName === p2.middleName
            && p1.lastName === p2.lastName
            && p1.telephone === p2.telephone
            && p1.email === p2.email
            && p1.passportNo === p2.passportNo
            && p1.citizenship === p2.citizenship
            && p1.houseNo === p2.houseNo
            && p1.street === p2.street
            && p1.city === p2.city
            && p1.country === p2.country
            && p1.postalCode === p2.postalCode;
    }
}
