import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatSelectionList } from '@angular/material/list';
import { TranslateService } from '@ngx-translate/core';
import { ConfigLoader } from '@tc-core/util/framework/config-loader.service';
import { AgGridAngular } from 'ag-grid-angular';
import { CellValueChangedEvent, GridApi, GridOptions, ICellRendererParams, IGetRowsParams, RowNode } from 'ag-grid-community';
import { Observable } from 'rxjs';
import { of } from 'rxjs/index';
import { tap } from 'rxjs/operators';
import { SetupGridComp } from '../../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid-comp';
import { ActionRendererAction } from '../../../../widgets/framework/ag-grid-custom-components/renderers/actions/actions.component';
import { GridCommonUtil } from '../../../../widgets/framework/ag-grid-custom-components/utils/grid-common-util';
import { TCO } from '../../../../constants';
import { SortDirection } from '../../../../models/helper/sort-direction';
import { BookingItem } from '../../../../models/reservation-v2/booking-item';
import { BookingItemSearchCriteria } from '../../../../models/reservation-v2/criteria/booking-item-search-criteria';
import { ProductItem } from '../../../../models/reservation-v2/product-item';
import { ReservationV2ManagementService } from '../../../../services/backend-consumers/reservation-v2/reservation-v2-management.service';
import { DataKey, DataStoreService } from '../../../../services/util/framework/data-store.service';
import { OperationV2DataProcessorService } from '../../../../services/util/pre-processors/operation-v2-data-processor.service';
import { ResponseUtil } from '../../../../services/util/response/response-util.service';
import { OperationQueuePanelConfig } from '../operation-queue-panel-config';

@Component({
    selector: 'tc-booking-queue',
    templateUrl: './booking-queue.component.html'
})
export class BookingQueueComponent implements OnInit, OnDestroy {

    constructor(
        private reservationV2ManagementService: ReservationV2ManagementService,
        private translateService: TranslateService,
        private operationV2DataProcessorService: OperationV2DataProcessorService,
        private configLoader: ConfigLoader,
        private dataStoreService: DataStoreService
    )
    {
        this.translateService.onLangChange.subscribe(() => {
            this.gridApi.refreshHeader();
        });
    }

    @Input() set primaryColumnDefs(colDef)
    {
        this.primaryColumnDefsInput = colDef;
    }

    @Input() set secondaryColumnDefs(colDef)
    {
        this.secondaryColumnDefsInput = colDef;
    }

    @Input() set allColumnDefs(colDef)
    {
        this.allColumnDefsInput = colDef;
    }

    @Input() set columnDefs(colDef)
    {
        this.columnDefsInput = colDef;
        if (this.gridApi) {
            setTimeout(() => {
                this.gridApi.sizeColumnsToFit();
                this.gridApi.setColumnDefs(this.columnDefsInput);
            }, 0);
        }
    }

    @ViewChild('agGrid') agGrid: AgGridAngular;
    @ViewChild('colSelector') private colSelector: MatSelectionList;

    @Input() operationPanelConfig = new OperationQueuePanelConfig();
    @Input() tabIndex;
    hideOverlay = false;
    showRowLoading = false;

    columnDefsInput: any[] = [];
    primaryColumnDefsInput: any[] = [];
    secondaryColumnDefsInput: any[] = [];
    allColumnDefsInput: any[] = [];
    selectedRowsCount = 0;
    unsavedRows = [];

    @Input() selectedProducts: ProductItem[] = [];
    @Input() frameworkComponents = {};
    @Input() cellEditingStart = null;
    @Input() autoSize = false;
    @Input() defaultColDef;
    @Input() gridOptions: Partial<GridOptions>;
    @Input() invalidRowClass = 'tc-ag-grid-row--error';

    setupGridComp: SetupGridComp;

    colResizeDefault: any = {};
    domLayout = 'autoHeight';
    overlayNoRowsTemplate: any = `<div class="tc-ag-grid-empty">
            <i class="material-icons tc-ag-grid-empty__icon">assignment_error</i>
            <span class="tc-ag-grid-empty__text">No Records Available</span>
        </div>`;
    overlayLoadingTemplate: any = `<div class="tc-ag-grid-empty">
            <img src="assets/img/loading-tab.gif" class="tc-mb-2" alt="Loading">
            <span class="tc-ag-grid-empty__text">Loading...</span>
        </div>`;

    gridApi: GridApi;
    gridColumnApi;
    gridActions: ActionRendererAction[] = [];

    bookingItemSearchResults: BookingItem[] = [];
    isHiddenPrimaryResourceButton = false;
    isHiddenSecondaryResourceButton = false;
    isHiddenSupplierConfirmActionButton = false;
    isHiddenSupplierRejectActionButton = false;

    @Output() rowDoubleClicked: EventEmitter<any> = new EventEmitter();
    @Output() rowClicked: EventEmitter<any> = new EventEmitter();
    @Output() cellClicked: EventEmitter<any> = new EventEmitter();

    public getRawClass = (params) => {
        if (params.node.rowIndex % 2 === 0) {
            return 'tc-ag-grid-row--even';
        }
    };

    ngOnInit() {
        const actionButtonConfig = this.configLoader.configurations.get(TCO.CONF.CONF_TBL_DEF_BOOKING_QUEUE_ACT_BUTTON_CONFIG);
        this.isHiddenPrimaryResourceButton = actionButtonConfig.primaryResourceButton.hide;
        this.isHiddenSecondaryResourceButton = actionButtonConfig.secondaryResourceButton.hide;
        this.isHiddenSupplierConfirmActionButton = actionButtonConfig.supplierConfirmActionButton.hide;
        this.isHiddenSupplierRejectActionButton = actionButtonConfig.supplierRejectActionButton.hide;

        this.operationPanelConfig.currentTab = this.tabIndex;
        this.colResizeDefault = 'shift';

        this.gridOptions = {
            cacheBlockSize: 50,
            maxBlocksInCache: 1,
            enableSorting: true,
            rowModelType: 'infinite',
            rowSelection: 'multiple',
            pagination: true,
            paginationPageSize: 50
        };
        this.defaultColDef = {
            filter: true,
            sortable: true,
            resizable: true,
            headerValueGetter: (parameters: ICellRendererParams): string => {
                const headerIdentifier = parameters.colDef.headerName;
                if (headerIdentifier) {
                    return this.translateService.instant(headerIdentifier);
                }
                return '';
            }
        };
        if (this.gridOptions.defaultColDef) {
            this.gridOptions.defaultColDef.resizable = true;
            this.gridOptions.defaultColDef.sortable = true;
        }
        this.autoSizeAll(false);
    }

    onClickColOption(event)
    {
        event.stopPropagation();
    }

    onSelectionChangeColSelector(event) {
        if (event && event.option) {
            const option = event.option.value;
            const selected = event.option.selected;
            if (this.gridColumnApi) {
                this.gridColumnApi.setColumnVisible(option, selected);
                // this.gridApi.sizeColumnsToFit();
                this.autoSizeAll(false);
            }
        }
    }

    autoSizeAll(skipHeader: boolean) {
        const allColumnIds = [];
        if (this.gridColumnApi) {
            this.gridColumnApi.getAllColumns().forEach((column) => {
                allColumnIds.push(column.colId);
            });
            // this.gridApi.sizeColumnsToFit();
            this.gridColumnApi.autoSizeColumns(allColumnIds, skipHeader);
        }
    }

    onGridSizeChange($event) {
        if (this.autoSize) {
            this.autoSizeAll(false);
        } else {
            this.gridApi.sizeColumnsToFit();
        }
    }

    public getRowNodeId(params: any) {
        return `${params.id}`;
    }

    onRestoreColDefClick() {
        this.columnDefsInput = this.allColumnDefsInput;
        if (this.gridColumnApi) {
            this.gridColumnApi.resetColumnState();
            const allColumnIds = [];
            this.gridColumnApi.getAllColumns().forEach((column) => {
                allColumnIds.push(column.colId);
            });
            this.gridColumnApi.hideColumns(allColumnIds, false);
            this.colSelector.selectAll();
        }
    }

    doSomeChange() {
        this.operationPanelConfig.preventSwitching = !this.operationPanelConfig.preventSwitching;
        if (this.operationPanelConfig.preventSwitching) {
            this.operationPanelConfig.currentTab = this.tabIndex;
        }
    }

    public onSelectionChange($event: any) {
        this.selectedRowsCount = $event.api.getSelectedRows().length;

        this.operationV2DataProcessorService.autoAllocationButtonEnable = this.selectedRowsCount > 0;
        this.operationV2DataProcessorService.confirmButtonEnable = this.selectedRowsCount > 0;
        if (!this.isHiddenSupplierConfirmActionButton) {
            this.operationV2DataProcessorService.supplierConfirmButtonEnable = this.selectedRowsCount > 0;
        }
        if (!this.isHiddenSupplierRejectActionButton) {
            this.operationV2DataProcessorService.supplierRejectButtonEnable = this.selectedRowsCount > 0;
        }

    }

    onGridReady($event) {
        this.gridApi = $event.api;
        this.gridColumnApi = $event.columnApi;
        this.autoSizeAll(false);
        const gridDataSource = {
            getRows: (params: IGetRowsParams) => {
                this.gridApi.showLoadingOverlay();
                const sortBy = params.sortModel && params.sortModel[0] && params.sortModel[0].colId ?
                    params.sortModel[0].colId : null;
                const sortDirection = params.sortModel && params.sortModel[0] && params.sortModel[0].sort ?
                    params.sortModel[0].sort : null;
                this.getDataRows(params.startRow, params.endRow, sortBy, sortDirection).subscribe(
                    (result: any) => {
                        if (result) {
                            this.gridApi.hideOverlay();
                            const data = ResponseUtil.getDataArray(result);
                            const totalRows = ResponseUtil.getTotalCount(result);
                            params.successCallback(data, totalRows);
                            if (totalRows === 0) {
                                this.gridApi.showNoRowsOverlay();
                            }
                            this.addUnSavedRowsAgainAfterReload();
                            this.autoSizeAll(false);
                        }
                    },
                    error => {
                        this.gridApi.hideOverlay();
                        params.successCallback([], 0);
                    }
                );
            }
        };
        this.gridApi.setDatasource(gridDataSource);
    }

    public loadData() {
        if (this.gridApi) {
            this.gridApi.refreshInfiniteCache();
        } else {
            setTimeout(() => this.loadData(), 100);
        }
        this.autoSizeAll(false);
    }

    public showLoading() {
        if (!this.hideOverlay) {
            this.showRowLoading = true;
            if (this.gridApi) {
                this.gridApi.showLoadingOverlay();
            } else {
                setTimeout(() => this.showLoading(), 0);
            }
        }
    }

    public hideLoading() {
        this.hideOverlay = true;
        this.showRowLoading = false;
        if (this.gridApi) {
            this.gridApi.hideOverlay();
        } else {
            setTimeout(() => this.hideLoading(), 0);
        }
    }

    getDataRows(startRow, endRow, sortBy, sortDirection): Observable<any> {
        const bookingIds = [];
        this.selectedProducts.forEach(productItem => {
            bookingIds.push(...productItem.bookingIdList);
        });
        if (bookingIds.length) {
            const pageSize = endRow - startRow;
            const bookingItemSearchCriteria = new BookingItemSearchCriteria();
            this.dataStoreService.get(DataKey.productQueueSearchCriteria)
                .subscribe((data) => {
                    if (data && data.itemNumber && data.itemNumber !== '-1' && data.itemNumber.toLowerCase() !== 'any') {
                        bookingItemSearchCriteria.itemNumber = data.itemNumber;
                    }
                });

            bookingItemSearchCriteria.bookingIdList = bookingIds;
            bookingItemSearchCriteria.start = startRow;
            bookingItemSearchCriteria.size = pageSize;
            if (sortBy) {
                bookingItemSearchCriteria.sortBy = sortBy;
                if (sortDirection === SetupGridComp.GRID_SORT_ASCENDING) {
                    bookingItemSearchCriteria.sortDirection = SortDirection.ASC;
                } else {
                    bookingItemSearchCriteria.sortDirection = SortDirection.DESC;
                }
            } else {
                bookingItemSearchCriteria.sortDirection = null;
            }
            return this.reservationV2ManagementService.searchBookingItems(bookingItemSearchCriteria)
                       .pipe(
                           tap(data => {
                                   if (data) {
                                       this.dataStoreService.set(DataKey.queueBookingSearchResults, data.data);
                                   }
                               }
                           )
                       );
        } else {
            // todo
            return new Observable<any>();
        }
    }

    private setupGridHeight(searchSize: number) {
        if (this.gridApi) {
            if (searchSize > 0) {
                this.gridApi.setDomLayout('autoHeight');
                // @ts-ignore
                // document.querySelector('#bookingGrid').style.height = '';
            } else {
                this.gridApi.setDomLayout('normal');
                // @ts-ignore
                // document.querySelector('#bookingGrid').style.height = '';
            }
        }
    }

    setDataToGrid(data: BookingItem[]) {
        if (this.gridApi) {
            this.hideLoading();
            this.gridApi.setRowData(data);
            this.setupGridHeight(data.length);
            this.autoSizeAll(false);
        } else {
            setTimeout(() => this.setDataToGrid(data), 0);
        }
    }

    public refreshGridView() {
        if (this.gridApi) {
            setTimeout(() => {
                this.gridApi.refreshCells({force: true});
                this.gridApi.setRowData(this.bookingItemSearchResults);
            }, 0);
        }
    }

    public replaceItem(oldBookingItem: BookingItem, replacingItems: BookingItem[]) {
        if (replacingItems.length > 0) {
            const newBookingItemSearchResults: BookingItem[] = [];
            this.bookingItemSearchResults.forEach(item => {
                if (item.id === oldBookingItem.id) {
                    replacingItems.forEach(newItem => newBookingItemSearchResults.push(newItem));
                } else {
                    newBookingItemSearchResults.push(item);
                }
            });
            this.bookingItemSearchResults = newBookingItemSearchResults;
            this.setDataToGrid(this.bookingItemSearchResults);
            this.refreshGridView();
        }
    }

    public replaceWithAutoAllocatedBookingItems(oldBookingIdList: number[], replacingItems: BookingItem[]) {
        if (replacingItems.length > 0) {
            const newBookingItemSearchResults: BookingItem[] = [];

            let index = 0;
            this.bookingItemSearchResults.forEach(oldItem => {
                let found = false;
                oldBookingIdList.forEach(newItemId => {
                    if (oldItem.id === newItemId) {
                        found = true;
                        index = this.bookingItemSearchResults.indexOf(oldItem);
                    }
                });
                if (!found) {
                    newBookingItemSearchResults.push(oldItem);
                }
            });

            newBookingItemSearchResults.splice(index, 0, ...replacingItems);

            this.bookingItemSearchResults = newBookingItemSearchResults;
            this.setDataToGrid(this.bookingItemSearchResults);
            this.refreshGridView();
            this.selectRows(replacingItems);
        }
    }

    public selectColumnGroup(type: string) {
        if (type === 'primary') {
            this.columnDefsInput = this.primaryColumnDefsInput;

        } else if (type === 'secondary') {
            this.columnDefsInput = this.secondaryColumnDefsInput;
        }
        this.autoSizeAll(false);
    }

    public gridColumnVisibleChange(fieldName: string, isHide: boolean) {
        if (this.gridColumnApi) {
            this.gridColumnApi.setColumnVisible(fieldName, !isHide);
        }
        const val = (this.columnDefsInput.find(res => res.field === fieldName)).hide = isHide;
    }

    public onRowDoubleClicked($event: any) {
        this.rowDoubleClicked.emit($event);
    }

    cellEditingStopped(event) {
        console.log('cell editing stopped');

        const nextCell = GridCommonUtil.getNextCell(event, true);
        this.gridApi.setFocusedCell(nextCell.rowIndex, nextCell.colKey);

        // problem if tab pressed before closi`ng the editor. tabToNext infinitely
        // this.gridApi.tabToNextCell();
    }

    onFirstDataRendered(params) {
        if (this.autoSize) {
            this.autoSizeAll(false);
        } else {
            this.gridApi.sizeColumnsToFit();
        }
    }

    saveRowData(event: any) {
        this.saveRow(event.data, event).subscribe(
            success => {
                console.log(success);
                this.unsavedRows = this.getUnsavedUnselectedRows();
                this.gridApi.refreshInfiniteCache();
            },
            error => {
                console.log(error);
            }
        );
    }

    public saveRow(row: any, event: any) {
        const saveData = row ? row : event;
        return of();
        // this.reservationV2ManagementService.saveLocation(saveData)
        //            .pipe(tap(data => row.unsaved = undefined));
    }

    addUnSavedRowsAgainAfterReload() {
        if (this.unsavedRows && this.unsavedRows.length > 0) {
            this.addNewRows(this.unsavedRows);
            this.unsavedRows = [];
        }
    }

    getUnsavedUnselectedRows(): any[] {
        const unSelectedRows = [];
        this.gridApi.forEachNode((node: RowNode) => {
            console.log(node);
            if (!node.isSelected() && this.isUnsavedRow(node)) {
                // unSelectedRows.push(node.data);
            }
        });
        return unSelectedRows;
    }

    public isUnsavedRow(row: any): boolean
    {
        return !!row.unsaved;
    }

    afterCellValueChange = (event: CellValueChangedEvent) => {
        this.cellClicked.emit(event);
        if (event.oldValue !== event.newValue) {
            console.log(event);
            this.saveRowData(event);
        }
    };

    addNewRow(newRow: any, selectRow: boolean, index = 0) {
        if (this.gridApi) {
            this.gridApi.updateRowData({add: [newRow], addIndex: index});
            if (selectRow) {
                this.selectRow(newRow);
            }
        } else {
            setTimeout(() => this.addNewRow(newRow, selectRow, index), 0);
        }
    }

    addNewRows(newRows: any[], index = 0) {
        newRows.forEach(newRow => {
            this.addNewRow(newRow, false, index);
        });
    }

    public selectRows(bookingItems: BookingItem[])
    {
        this.gridApi.forEachNode((node) => {
            node.setSelected(bookingItems.includes(node.data));
        });
    }

    selectRow(row: any) {
        this.gridApi.forEachNode((node: RowNode) => {
            if (!node.isSelected()) {
                node.setSelected(node.data === row);
            } else {
                node.setSelected(true);
            }
        });
    }

    public isInvalidRow(params): boolean {
        const data = params.data;
        if (data) {
            let invalid = true;
            if (data.code && data.name && data.locationType) {
                invalid = false;
            }
            return invalid;
        } else {
            return false;
        }
    }

    public onRowClicked($event: any) {
        this.rowClicked.emit($event);
    }

    private reloadGridData() {
        // todo
    }

    ngOnDestroy(): void {
        console.log('destroyed booking view');
        this.operationV2DataProcessorService.autoAllocationButtonEnable = false;
        this.operationV2DataProcessorService.confirmButtonEnable = false;
        this.operationV2DataProcessorService.supplierConfirmButtonEnable = false;
        this.operationV2DataProcessorService.supplierRejectButtonEnable = false;
    }
}
