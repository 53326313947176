import {AfterViewInit, Component, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {IAfterGuiAttachedParams, ICellEditorParams} from "ag-grid-community";
import {AgEditorComponent} from "ag-grid-angular";

@Component({
    selector: 'tc-max-length-terminator',
    templateUrl: './max-length-terminator.component.html'
})
export class MaxLengthTerminatorComponent implements OnInit, AgEditorComponent, AfterViewInit {
    public value = '';
    public fontColor: any = 'black';
    public inputMaxLength: number;

    @ViewChild('input', {read: ViewContainerRef}) public input: ViewContainerRef;
    private params: any;

    constructor() {
    }

    ngOnInit() {

    }

    afterGuiAttached(params?: IAfterGuiAttachedParams): void {
    }

    agInit(params: ICellEditorParams): void {
        this.params = params;
        if (params.colDef['maxInputCharactersLimit']) {
            this.inputMaxLength = params.colDef['maxInputCharactersLimit'];
        }
    }

    getValue(): any {
        this.value = this.input.element.nativeElement.value;
        return this.value;
    }

    ngAfterViewInit(): void {
        setTimeout(() => this.input.element.nativeElement.focus());
    }

    focusIn(): void {
    }

    focusOut(): void {
    }

    getFrameworkComponentInstance(): any {
    }

    isCancelAfterEnd(): boolean {
        return false;
    }

    isCancelBeforeStart(): boolean {
        return false;
    }

    isPopup(): boolean {
        return false;
    }


}
