import {AbstractSearchCriteria} from "./abstract-search-criteria";

export class CountrySearchCriteria extends AbstractSearchCriteria {
    code: string;
    name: string;
    codeOrName: string;
    states: string;
    cities: string;
    locations: any;
}
