import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { AgGridModule } from 'ag-grid-angular';
import { AgGridCustomEditorsModule } from '../../../../widgets/framework/ag-grid-custom-components/editors/ag-grid-custom-editors.module';
import { DropdownEditorComponent } from '../../../../widgets/framework/ag-grid-custom-components/editors/dropdown-editor/dropdown-editor.component';
import { AgGridCustomRenderersModule } from '../../../../widgets/framework/ag-grid-custom-components/renderers/ag-grid-custom-renderers.module';
import { TemplateRendererComponent } from '../../../../widgets/framework/ag-grid-custom-components/renderers/template-renderer/template-renderer.component';
import { BookingPassengersComponent } from './booking-passengers.component';

@NgModule({
    declarations: [BookingPassengersComponent],
    imports: [
        CommonModule,
        AgGridCustomEditorsModule,
        AgGridCustomRenderersModule,
        AgGridModule.withComponents([
            // renderers
            TemplateRendererComponent,

            // editors
            DropdownEditorComponent
        ]),
        TranslateModule.forChild()
    ],
    exports: [
        BookingPassengersComponent
    ]
})
export class BookingPassengersModule {}
