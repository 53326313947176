import * as tslib_1 from "tslib";
import { Injector, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TcApiError } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-api-error';
import { TcHttpError } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-http-error';
import { CurrencyFormat } from '@tc-core/model/it/codegen/ui/framework/currency-format';
import { Pager } from '@tc-core/model/it/codegen/ui/framework/pager';
import { TC } from '@tc-core/util';
import { ConfigLoader } from '@tc-core/util/framework/config-loader.service';
import { EventManager } from '@tc-core/util/framework/event-manager.service';
import { UserJourneyManager } from '@tc-core/util/framework/user-journey-manager.service';
import { CommonHelper } from '@tc-core/util/helpers/common-helper.service';
import { AutoScrollService } from '@tc-core/util/ui/auto-scroll.service';
import { SpinnerService } from '@tc-core/util/ui/spinner.service';
import { Subscription } from 'rxjs';
import { TCO } from '../../constants';
import { ProfileTypeSearchCriteria } from '../../models/criteria/profile-type-search-criteria';
import { ResourceTypeSearchCriteria } from '../../models/criteria/resource-type-search-criteria';
import { SupplierSearchCriteria } from '../../models/criteria/supplier-search-criteria';
import { UserBusinessType } from '../../models/user/user-business-type';
import { DataHandlerService } from '../../services/backend-consumers/master-data-handler-service/data-handler.service';
import { ResourceTypeService } from '../../services/backend-consumers/setups/resource-type.service';
import { SupplierService } from '../../services/backend-consumers/supplier-service/supplier.service';
import { DMCCommon } from '../../services/util/common/dmc-common';
import { RootService } from '../../services/util/core-services/root.service';
import { DataKey, DataStoreService } from '../../services/util/framework/data-store.service';
import { DMCQueryParamsService } from '../../services/util/framework/dmc-query-params.service';
import { ResponseUtil } from '../../services/util/response/response-util.service';
import { DMCLocalStorageService, LocalDataKey } from '../../services/util/system/dmc-local-storage.service';
var TransferSupplierSearchResultsComponent = /** @class */ (function () {
    function TransferSupplierSearchResultsComponent(dataStoreService, userJourneyManager, configLoader, supplierService, spinnerService, injector, route, em, commonHelper, rootService, dataHandlerService, queryParamService, autoScrollService, common, localStorage, resourceTypeService) {
        this.dataStoreService = dataStoreService;
        this.userJourneyManager = userJourneyManager;
        this.configLoader = configLoader;
        this.supplierService = supplierService;
        this.spinnerService = spinnerService;
        this.injector = injector;
        this.route = route;
        this.em = em;
        this.commonHelper = commonHelper;
        this.rootService = rootService;
        this.dataHandlerService = dataHandlerService;
        this.queryParamService = queryParamService;
        this.autoScrollService = autoScrollService;
        this.common = common;
        this.localStorage = localStorage;
        this.resourceTypeService = resourceTypeService;
        this.contentCardDataList = [];
        this.footerDataMap = new Map();
        this.showEditBtn = true;
        this.showEditAsNew = true;
        this.defaultPageResultCount = 10;
        this.selectionIndex = '';
        this.fetchedSupplierCount = 0;
        this.dataFromMoreClick = false;
        this.paginatorHasMore = true;
        this.resultCount = 0;
        this.routerChangeSubscription = new Subscription();
        this.supplierResultObsv = new Subscription();
        this.moreResultObsv = new Subscription();
        this.costingTypesObs = new Subscription();
        this.vehicleTypesObs = new Subscription();
        this.searchCriteriaObs = new Subscription();
        this.supplierByIdObsv = new Subscription();
        this.userBusinessTypeObsv = new Subscription();
        this.isEmptyResult = false;
        this.isError = false;
        this.errorText = '';
        this.noResultsText = '';
        this.resourceTypes = [];
        this.searchCriteria = new SupplierSearchCriteria();
        this.costingTypeList = [];
        this.transferModes = [];
        this.vehicleModels = [];
        this.profileTypes = [];
    }
    TransferSupplierSearchResultsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.tSuppliers = this.configLoader.configurations.get(TCO.CONF.CONF_SUPPLIER_CARD_CONFIG);
        this.dataStoreService.set(DataKey.selectedSupplierForEdit, null, true);
        this.tPagination = this.configLoader.configurations.get(TCO.CONF.CONF_PAGINATION_CONFIG).SUPPLIER_SEARCH;
        this.currencyFormat = this.getCurrencyFormat();
        this.getProfileTypes();
        this.userBusinessType = this.localStorage.load(LocalDataKey.userBusinessType);
        this.pageOptionsAvailability = this.configLoader.configurations.get(TCO.CONF.CONF_PAGE_OPTION_AVAILABILITY).SUPPLIER_MANAGEMENT;
        this.setupPageOptions();
        this.dataStoreService.set(DataKey.supplier, null, true);
        this.setupPaginator();
        this.pager = new Pager(0, this.defaultPageResultCount);
        var resourceTypeSearchCriteria = new ResourceTypeSearchCriteria();
        resourceTypeSearchCriteria.size = 1000000;
        this.resourceTypeService.getResourceTypes(resourceTypeSearchCriteria).subscribe(function (result) {
            if (result.data) {
                result.data.forEach(function (type) {
                    _this.resourceTypes.push(type);
                });
            }
        });
        this.dataHandlerService.retrieveTransferModes().subscribe(function (result) {
            _this.transferModes = result.data;
        });
        this.costingTypesObs = this.dataStoreService.get(DataKey.costingTypes).subscribe(function (value) {
            if (value && Array.isArray(value)) {
                _this.costingTypeList = value;
            }
        });
        this.vehicleTypesObs = this.dataStoreService.get(DataKey.vehicleModels).subscribe(function (value) {
            if (value) {
                _this.vehicleModels = value;
            }
        });
        // resetting the view
        // this.routerChangeSubscription = this.userJourneyManager.routeChanges.subscribe(e => {
        //     if (e) {
        //         this.supplierResultList = null;
        //         this.contentCardDataList = [];
        //     }
        // });
        this.fetchedSupplierCount = 0;
        this.supplierResultObserver();
    };
    TransferSupplierSearchResultsComponent.prototype.getProfileTypes = function () {
        var _this = this;
        var profileTypeSearchCriteria = new ProfileTypeSearchCriteria();
        profileTypeSearchCriteria.size = 1000000;
        this.resourceTypeService.getProfileTypes(profileTypeSearchCriteria).subscribe(function (result) {
            _this.profileTypes = result.data;
        });
    };
    TransferSupplierSearchResultsComponent.prototype.supplierResultObserver = function () {
        var _this = this;
        this.fetchedSupplierCount = 0;
        this.supplierResultObsv = this.dataStoreService.get(DataKey.supplierSearchResults).subscribe(function (result) {
            _this.selectionIndex = '';
            if (_this.commonHelper.dataValidity(result)) {
                _this.spinnerService.hide();
                _this.paginatorHasMore = true;
                _this.onResultsHandler(result);
            }
            else if (_this.commonHelper.isEmptyData(result)) {
                _this.contentCardDataList = [];
                _this.supplierResultList = [];
                // this.spinnerService.hide();
            }
            else if (result instanceof TcApiError) {
                _this.spinnerService.hide();
            }
            else if (result instanceof TcHttpError) {
                _this.spinnerService.hide();
            }
            else {
                _this.noResultsText = TCO.EMPTY_RESULT_PAGE_MESSAGE.SUPPLIER_RESULTS;
                _this.contentCardDataList = [];
                _this.supplierResultList = [];
                _this.heading = '';
                _this.isEmptyResult = false;
                _this.isError = false;
            }
        }, function (error) {
        });
    };
    TransferSupplierSearchResultsComponent.prototype.onResultsHandler = function (result) {
        if (JSON.stringify(result) !== JSON.stringify({})) {
            if (ResponseUtil.isSuccess(result)) {
                var dataList = ResponseUtil.getDataArray(result);
                if (dataList) {
                    this.searchResult = result;
                    this.supplierResultList = dataList;
                    this.resultCount = ResponseUtil.getTotalCount(result);
                    this.fetchedSupplierCount = this.supplierResultList.length;
                    this.contentCardDataList = this.getContentCardData(this.supplierResultList);
                    this.setPageZero();
                    this.heading = this.resultCount + ' result' + (this.resultCount > 1 ? 's' : '') + ' found.';
                    var noOfResults = this.contentCardDataList.length;
                    this.paginatorHasMore = this.resultCount > noOfResults;
                    if (this.supplierResultList.length === 0) {
                        this.noResultsText = TCO.EMPTY_RESULT_PAGE_MESSAGE.SUPPLIER_RESULTS;
                        this.isEmptyResult = true;
                        this.isError = false;
                    }
                    else {
                        this.isEmptyResult = false;
                        this.isError = false;
                    }
                }
            }
            else if (ResponseUtil.isError(result)) {
                this.isEmptyResult = true;
                this.isError = true;
                if (result.error.message) {
                    this.errorText = result.error.errors[0];
                }
                else {
                    this.errorText = 'Request Processing Error !!!';
                }
            }
        }
    };
    TransferSupplierSearchResultsComponent.prototype.onMoreResultsHandler = function (result) {
        var _a, _b;
        if (JSON.stringify(result) !== JSON.stringify({})) {
            if (ResponseUtil.isSuccess(result)) {
                var dataList = ResponseUtil.getDataArray(result);
                if (dataList) {
                    (_a = this.supplierResultList).push.apply(_a, tslib_1.__spread(dataList));
                    this.fetchedSupplierCount = this.supplierResultList.length;
                    var contentCardDataList = this.getContentCardData(dataList);
                    (_b = this.contentCardDataList).push.apply(_b, tslib_1.__spread(contentCardDataList));
                    this.setPageZero();
                    var noOfResults = this.contentCardDataList.length;
                    this.paginatorHasMore = this.resultCount > noOfResults;
                    if (result.data.length === 0) {
                        this.isError = false;
                    }
                    else {
                        this.isError = false;
                    }
                }
            }
            else if (ResponseUtil.isError(result)) {
                // this.isEmptyResult = true;
                this.isError = true;
                if (result.error.message) {
                    this.errorText = result.error.errors[0];
                }
                else {
                    this.errorText = 'Request Processing Error !!!';
                }
            }
        }
    };
    // paginator
    TransferSupplierSearchResultsComponent.prototype.setupPaginator = function () {
        this.defaultPageResultCount = this.tPagination.defaultPageResultCount;
        this.pager = new Pager(0, this.defaultPageResultCount);
    };
    TransferSupplierSearchResultsComponent.prototype.onPage = function (pageNumber) {
        this.pager.currPage = pageNumber;
        this.selectionIndex = (this.pager.currPage * this.pager.itemsPerPage).toString();
    };
    TransferSupplierSearchResultsComponent.prototype.onSelection = function (event) {
        this.selectionIndex = event;
    };
    TransferSupplierSearchResultsComponent.prototype.onMore = function () {
        this.dataFromMoreClick = true;
        if (this.tPagination && this.tPagination.defaultPageResultCount && this.tPagination.fetchingPages) {
            this.searchCriteria.size = this.tPagination.fetchingPages * this.tPagination.defaultPageResultCount;
            this.searchCriteria.start = this.fetchedSupplierCount;
            this.retrieveAndCollectSearchResults(this.searchCriteria);
        }
    };
    TransferSupplierSearchResultsComponent.prototype.setPageZero = function () {
        if (this.pager) {
            if (!this.dataFromMoreClick) {
                this.pager.currPage = 0;
            }
            else {
                this.dataFromMoreClick = false;
            }
        }
        else {
            this.pager = new Pager(0, this.defaultPageResultCount);
        }
    };
    TransferSupplierSearchResultsComponent.prototype.onSupplierSelect = function (summaryCardData) {
        var _this = this;
        this.dataStoreService.set(DataKey.supplier, null, true);
        var id = summaryCardData.data.value.supplierId;
        if (id !== null) {
            this.supplierService.retrieveSupplierById(id);
            this.spinnerService.show();
            this.detailSupplier = null;
            this.supplierByIdObsv = this.dataStoreService.get(DataKey.supplier).subscribe(function (res) {
                if (_this.commonHelper.dataValidity(res) && res.data && res.data.length > 0) {
                    if (_this.supplierByIdObsv) {
                        _this.supplierByIdObsv.unsubscribe();
                    }
                    _this.spinnerService.hide();
                    _this.detailSupplier = ResponseUtil.getFirstData(res);
                    var selectedSupplier = {
                        code: _this.detailSupplier.code,
                        resourceType: _this.detailSupplier.supplierItems &&
                            _this.detailSupplier.supplierItems.length > 0 ?
                            _this.detailSupplier.supplierItems[0].code :
                            null
                    };
                    var parameters = _this.queryParamService.getQueryParamsArray(selectedSupplier);
                    _this.rootService.setDataToLocalStorage(TCO.AppData.SUPPLIER, selectedSupplier, true, parameters);
                    // this.detailSupplier = res;
                    console.log(_this.detailSupplier);
                }
                else if (res instanceof TcApiError) {
                    _this.spinnerService.hide();
                }
                else if (res instanceof TcHttpError) {
                    _this.spinnerService.hide();
                }
            });
        }
        this.generateFooterDataMap();
    };
    TransferSupplierSearchResultsComponent.prototype.getContentCardData = function (searchResults) {
        var config = this.tSuppliers.config;
        if (this.tSuppliers.actionHandler && this.tSuppliers.actionHandler !== '') {
            var t = this.tSuppliers.actionHandler.split('#');
            var srv = t[0];
            var fnc = t[1];
            var handler = this.injector.get(srv);
            console.log(searchResults);
            var contentCardDataList = handler[fnc](searchResults, config);
            console.log(contentCardDataList);
            return contentCardDataList;
        }
    };
    TransferSupplierSearchResultsComponent.prototype.generateFooterDataMap = function () {
        if (this.detailSupplier) {
            this.footerDataMap.set(0, {
                captionText: 'Created on ' + '21/12/2018',
                bodyText: ''
            });
            this.footerDataMap.set(1, {
                captionText: 'Modified on ' + '21/12/2018',
                bodyText: ''
            });
        }
    };
    TransferSupplierSearchResultsComponent.prototype.onSupplierEdit = function (supplier) {
        this.rootService.setDataToLocalStorage('code', supplier.code, true);
        this.userJourneyManager.goForKey('GO_TO_EDIT_TRANSPORT_SUPPLIER');
        supplier.isEditTriggered = true;
        this.dataStoreService.set(DataKey.selectedSupplierForEdit, supplier, true);
    };
    TransferSupplierSearchResultsComponent.prototype.onEditAsNewSupplier = function ($event) {
        this.clearIdsInSupplier($event);
        this.userJourneyManager.goForKey('GO_TO_CREATE_SUPPLIER');
        this.dataStoreService.set(DataKey.selectedSupplierForEditAsNew, $event, true);
    };
    /**
     * IMPORTANT : clear all the existing ids that needs to be saved as new entities
     *
     * @param supplier
     */
    TransferSupplierSearchResultsComponent.prototype.clearIdsInSupplier = function (supplier) {
        supplier.supplierId = 0;
        supplier.code = '';
        if (supplier.contactDetails) {
            supplier.contactDetails.forEach(function (value) { return value.contactId = 0; });
        }
        if (supplier.documents) {
            supplier.documents.forEach(function (value) { return value.id = 0; });
        }
        if (supplier.taxSchemes) {
            supplier.taxSchemes.forEach(function (value) { return value.taxSchemeId = 0; });
        }
        if (supplier.paymentInfo) {
            supplier.paymentInfo.paymentId = 0;
        }
        if (supplier.supplierAttributes) {
            supplier.supplierAttributes.forEach(function (value) { return value.id = 0; });
        }
        if (supplier.seasons) {
            supplier.seasons.forEach(function (value) {
                value.seasonId = 0;
                value.seasonDateRanges.forEach(function (value1) {
                    value1.dateRangeId = 0;
                });
            });
        }
        if (supplier.supplierItems) {
            supplier.supplierItems.forEach(function (value) { return value.id = 0; });
        }
    };
    TransferSupplierSearchResultsComponent.prototype.getSearchCriteria = function () {
        var _this = this;
        this.searchCriteriaObs = this.dataStoreService.get(DataKey.supplierSearchCriteria).subscribe(function (data) {
            if (data !== null) {
                _this.searchCriteria = data;
            }
        });
    };
    TransferSupplierSearchResultsComponent.prototype.retrieveAndCollectSearchResults = function (searchCriteria) {
        this.dataStoreService.set(DataKey.supplierAllFetchedResults, null, true);
        this.supplierService.searchSuppliersOnMore(this.searchCriteria);
        this.moreResultObserver();
    };
    TransferSupplierSearchResultsComponent.prototype.moreResultObserver = function () {
        var _this = this;
        this.moreResultObsv = this.dataStoreService.get(DataKey.supplierAllFetchedResults).subscribe(function (result) {
            if (_this.commonHelper.dataValidity(result)) {
                _this.moreResultObsv.unsubscribe();
                _this.paginatorHasMore = true;
                _this.onMoreResultsHandler(result);
            }
            else if (result instanceof TcApiError) {
                _this.paginatorHasMore = false;
            }
            else if (result instanceof TcHttpError) {
                _this.paginatorHasMore = false;
            }
        });
    };
    TransferSupplierSearchResultsComponent.prototype.ngOnDestroy = function () {
        if (this.supplierResultObsv) {
            this.supplierResultObsv.unsubscribe();
        }
        if (this.supplierByIdObsv) {
            this.supplierByIdObsv.unsubscribe();
        }
        if (this.moreResultObsv) {
            this.moreResultObsv.unsubscribe();
        }
        if (this.costingTypesObs) {
            this.costingTypesObs.unsubscribe();
        }
        if (this.vehicleTypesObs) {
            this.vehicleTypesObs.unsubscribe();
        }
        if (this.searchCriteriaObs) {
            this.searchCriteriaObs.unsubscribe();
        }
    };
    TransferSupplierSearchResultsComponent.prototype.onCardScroll = function ($event) {
        var _this = this;
        setTimeout(function () {
            _this.autoScrollService.scrollToID('clientDetailExp_' + $event, 'main-scroll-container');
        }, 0);
    };
    TransferSupplierSearchResultsComponent.prototype.getCurrencyFormat = function () {
        var currencyFormat;
        var currencySymbolPattern = this.configLoader.configurations.get(TC.CONF.CONF_SYSTEM).defaults.default_currency_symbol_pattern;
        currencyFormat = new CurrencyFormat(TCO.AppData.DEF_CURRENCY_TYPE, currencySymbolPattern);
        return currencyFormat;
    };
    TransferSupplierSearchResultsComponent.prototype.validateUserWithBusinessType = function () {
        if (this.userBusinessType === UserBusinessType.EXTERNAL) {
            this.showEditBtn = false;
            this.showEditAsNew = false;
        }
    };
    TransferSupplierSearchResultsComponent.prototype.setupPageOptions = function () {
        if (this.pageOptionsAvailability) {
            this.showEditBtn = this.pageOptionsAvailability.editable;
            this.showEditAsNew = this.pageOptionsAvailability.edit_as_new;
        }
    };
    return TransferSupplierSearchResultsComponent;
}());
export { TransferSupplierSearchResultsComponent };
