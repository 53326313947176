<div class="tc-search-card" *ngIf="clientResultsList">

    <tc-section-heading
            *ngIf="heading"
            [title]="heading">
    </tc-section-heading>
    <div class="result-view-container">
        <ng-container *ngFor="let client of clientResultsList |tcAdvPager:pager.currPage:pager.itemsPerPage; let i = index;">

            <div
                    class="result-item animate"
                    [ngClass]="openExpansionPanel?'tc-expansion-panel--expanded':''"
                    tcExpansionPanel
                    #clientExp
                    tabindex="-1"
                    [enabled]="true"
                    (expand)="onPanelExpand(i)"
                    (collapse)="expandedPanelIndex = -1">

                <!-- Content card Strip -->
                <div *tcExpansionPanelSummary id="clientSumExp_{{i}}">
                    <tc-content-strip
                            (click)="onClientSelection(client,i)">

                        <!--<tc-content-summary-card-->
                        <!--[summaryContent]="client">-->
                        <!--</tc-content-summary-card>-->
                        <tc-result-content-card-suppliers
                                [summaryContent]="client">
                        </tc-result-content-card-suppliers>
                        <!--<span-->
                        <!--class="tc-strip-index zoomIn animated">-->
                        <!--</span>-->
                    </tc-content-strip>
                </div>

                <div *tcExpansionPanelDetail id="clientDetailExp_{{i}}">
                    <tc-expanded-content-card class="tc-expanded-content-card tc-form-view" [isEdit]=false>

                        <tc-op-expanded-content-card-header
                                *ngIf="selectedClient && (client?.row2col1.value === selectedClient?.row2col1.value)"
                                [headerText]="client?.row1col1?.value"
                                [showProgress]="!detailSupplier"
                                [showEdit]="((showEditBtn))"
                                [showEditAsNew]="((showEditAsNew))"
                                (editClick)="onEditClick(detailSupplier)"
                                (editAsNewClick)="onEditAsNewClick($event)"
                                (headerClick)="onHeaderClick()">
                        </tc-op-expanded-content-card-header>

                        <tc-supplier-detail-view
                                *ngIf="detailSupplier"
                                [supplier]="detailSupplier"
                                [summaryCardData]="selectedClient"
                                [resourceTypes]="resourceTypes"
                                [currencyFormat]="currencyFormat"
                                [costingTypes]="costingTypes"
                                [profileTypes]="profileTypes"
                                [trsModes]="trsModes"
                                [vehicleModels]="vehicleModels"
                                (expandEventEmitter)="onExpand()"
                                (headerClick)="onHeaderClick()"
                                [footerDataMap]="footerDataMap">
                        </tc-supplier-detail-view>

                    </tc-expanded-content-card>
                </div>
            </div>
        </ng-container>
    </div>
</div>
