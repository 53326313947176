import { AbstractSearchCriteria } from '../../criteria/abstract-search-criteria';
import { AllocationType } from '../allocation-type';

export class BookingItemSearchCriteria extends AbstractSearchCriteria
{
    itemNumber: string;
    bookingIdList: number[];

    //
    allocationType: AllocationType;
}
