import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TcErrorType } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-error';
import { DialogModel } from '@tc-core/model/it/codegen/ui/framework/dialog-model';
import { LEVEL } from '@tc-core/model/it/codegen/ui/framework/modal-data';
import { TC } from '@tc-core/util';
import { ConfigLoader, EventManager, UserJourneyManager } from '@tc-core/util/framework';
import { SpinnerService } from '@tc-core/util/ui/spinner.service';
import { DialogService } from '@tc/dialog/dialog.service';
import { FocusViewService } from '@tc/focus-view/focus-view.service';
import { IGetRowsParams } from 'ag-grid-community';
import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SetupGridComp } from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid-comp';
import { SetupGridDataSource } from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid-data-source';
import { SetupGridComponent } from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid.component';
import { ActionRendererAction } from '../../../widgets/framework/ag-grid-custom-components/renderers/actions/actions.component';
import { TCO } from '../../../constants';
import { LedgerEntrySearchCriteria } from '../../../models/criteria/ledger-entry-search-criteria';
import { SortDirection } from '../../../models/helper/sort-direction';
import { DataHandlerService } from '../../../services/backend-consumers/master-data-handler-service/data-handler.service';
import { AccountingRuleService } from '../../../services/backend-consumers/setups/accounting-rule.service';
import { DMCCommon } from '../../../services/util/common/dmc-common';
import { DataKey, DataStoreService } from '../../../services/util/framework/data-store.service';

@Component({
    selector: 'tc-ledger-entry-search-results',
    templateUrl: './ledger-entry-search-results.component.html'
})
export class LedgerEntrySearchResultsComponent extends SetupGridComp implements OnInit, OnDestroy, SetupGridDataSource {

    colDefConfig = [];

    @ViewChild('docSetupGrid')
    private setupGrid: SetupGridComponent;

    private ledgerEntrySearchCriteria: LedgerEntrySearchCriteria = new LedgerEntrySearchCriteria();

    // required options for grid editors
    // nothing

    gridActions: ActionRendererAction[] = [];

    headerActions: ActionRendererAction[] = [
        {
            icon: 'autorenew',
            tooltip: 'Generate Ledgers',
            action: this.onLedgerGenerationClick.bind(this),
            disable: false
        }
    ];

    private searchCriteriaObserver: Subscription = new Subscription();
    private focusViewDataChangeObserver: Subscription = new Subscription();
    private focusViewConfirmObserver: Subscription = new Subscription();
    private focusViewCloseObserver: Subscription = new Subscription();
    private routeDataSubscription: Subscription = new Subscription();
    private routeParamsSubscription: Subscription = new Subscription();
    private routeEventSubscription: Subscription = new Subscription();
    private focusCloseSubscription: Subscription = new Subscription();

    isFocusViewDataChanged = false;

    @ViewChild('statusCell') statusCell: TemplateRef<any>;
    @ViewChild('nameFormatCell') nameFormatCell: TemplateRef<any>;
    @ViewChild('formatCell') formatCell: TemplateRef<any>;
    @ViewChild('generatedByCell') generatedByCell: TemplateRef<any>;

    constructor(
        private configLoader: ConfigLoader,
        private dataStoreService: DataStoreService,
        private dataHandlerService: DataHandlerService,
        private accountingRuleService: AccountingRuleService,
        private activatedRoute: ActivatedRoute,
        private focusViewService: FocusViewService,
        private dataStore: DataStoreService,
        private route: ActivatedRoute,
        private router: Router,
        public em: EventManager,
        private dialogService: DialogService,
        private common: DMCCommon,
        private userJourneyManager: UserJourneyManager,
        private spinnerService: SpinnerService
    ) {
        super();

    }

    ngOnInit() {
        this.colDefConfig = this.configLoader.configurations.get(TCO.CONF.CONF_LEDGER_ENTRY_GRID);
        this.subscribeSearchCriteria();
        this.fillRequiredGridOptionData();
        this.addFabActionEvents();
        this.userJourneyManager.canProceed.next(true);
    }

    addFabActionEvents() {
        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'GENERATE_LEDGERS').subscribe(e => {
            this.onLedgerGenerationClick();
        });
    }

    /**
     * subscribe search criteria changes in search bar
     * then force grid to refresh data
     * grid refresh will be call getRaws method which is using this subscribed criteria for backend call
     */
    subscribeSearchCriteria() {
        this.searchCriteriaObserver = this.dataStoreService.get(DataKey.ledgerEntrySearchCriteria)
                                          .subscribe(value => {
                                              this.ledgerEntrySearchCriteria = value;
                                              if (this.ledgerEntrySearchCriteria) {
                                                  this.setupGrid.runForceSearch();
                                              }
                                          });
    }

    private fillRequiredGridOptionData() {
        // nothing
    }

    public isInvalidRow(params): boolean {
        return false;
    }

    public createNewRow(): any {
        return {unsaved: true};
    }

    public isUnsavedRow(row: any): boolean {
        return !!row.unsaved;
    }

    public deleteRow(row: any) {
        return null;
    }

    public saveRow(row: any, event: any) {
        return null;
    }

    public getRows(params: IGetRowsParams): Observable<any> {
        console.log('fetch requesting for ' + params.startRow + ' to ' + params.endRow);
        console.log(params.sortModel);

        const pageSize = params.endRow - params.startRow;
        if (!this.ledgerEntrySearchCriteria) {
            this.ledgerEntrySearchCriteria = new LedgerEntrySearchCriteria;
        }
        this.ledgerEntrySearchCriteria.start = params.startRow;
        this.ledgerEntrySearchCriteria.size = pageSize;
        if (params.sortModel && params.sortModel[0]) {
            this.ledgerEntrySearchCriteria.sortBy = params.sortModel[0].colId;
            if (params.sortModel[0].sort === SetupGridComp.GRID_SORT_ASCENDING) {
                this.ledgerEntrySearchCriteria.sortDirection = SortDirection.ASC;
            } else {
                this.ledgerEntrySearchCriteria.sortDirection = SortDirection.DESC;
            }
        }
        return this.accountingRuleService.searchLedgerEntries(this.ledgerEntrySearchCriteria)
                   .pipe(
                       tap(data =>
                           this.dataStoreService.set(DataKey.ledgerEntrySearchResultsForCriteria, data)
                       )
                   );
    }

    public ngOnDestroy(): void {
        if (this.searchCriteriaObserver) {
            this.searchCriteriaObserver.unsubscribe();
        }
        if (this.focusViewDataChangeObserver) {
            this.focusViewDataChangeObserver.unsubscribe();
        }
        if (this.focusViewConfirmObserver) {
            this.focusViewConfirmObserver.unsubscribe();
        }
        if (this.focusViewCloseObserver) {
            this.focusViewCloseObserver.unsubscribe();
        }
        if (this.routeDataSubscription) {
            this.routeDataSubscription.unsubscribe();
        }
        if (this.routeParamsSubscription) {
            this.routeParamsSubscription.unsubscribe();
        }
        if (this.routeEventSubscription) {
            this.routeEventSubscription.unsubscribe();
        }

        if (this.focusCloseSubscription) {
            this.focusCloseSubscription.unsubscribe();
        }

        this.em.removeEvents([
            'GENERATE_LEDGERS'
        ]);
    }

    /*
     grid config methods which are called by grid setup (ag grid)
     and set by grid config
     */

    public onRowDoubleClicked(event: any) {
        console.log('Row clicked');
    }

    public onLedgerGenerationClick() {
        let confirmSuccess = new DialogModel(
            true,
            LEVEL.INFO,
            'Generate Ledgers',
            `Do you want to generate ledger entries ?`,
            true,
            2000,
            null,
            'No',
            'Yes',
            true
        );
        confirmSuccess.disableClose = true;
        this.dialogService
            .confirm(confirmSuccess)
            .subscribe((res) => {
                if (res === true) {
                    this.spinnerService.show();
                    this.accountingRuleService.generateLedgers({
                        version: 'v2'
                    }).subscribe(
                        result => {
                            this.spinnerService.hide();
                            this.common.showSnackBar(
                                'Success',
                                3000,
                                TcErrorType.TYPE.INFO
                            );
                            this.setupGrid.runForceSearch();
                        },
                        error => {
                            this.spinnerService.hide();
                            console.log(error);
                            this.common.showSnackBar(
                                'Failed',
                                3000,
                                TcErrorType.TYPE.ERROR
                            );
                        }
                    );

                }
            });
    }
}
