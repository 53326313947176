import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CitySearchCriteria } from '../../../models/criteria/city-search-criteria';
import { CommonDataSearchCriteria } from '../../../models/criteria/common-data-search-criteria';
import { CountrySearchCriteria } from '../../../models/criteria/country-search-criteria';
import { KeyControlAndAbstractSearchCriteria } from '../../../models/criteria/key-control-and-abstract-search-criteria';
import { StateSearchCriteria } from '../../../models/criteria/state-search-criteria';
import { CommonDataType } from '../../../models/reservation-v2/common-data-type';
import { DMCQueryParamsService } from '../../util/framework/dmc-query-params.service';
import { RequestService } from '../../util/framework/request.service';
import { UrlPaths } from '../url-paths';

@Injectable()
export class DataHandlerService
{
    constructor(
        private http: HttpClient,
        private requestService: RequestService,
        private queryParamsService: DMCQueryParamsService
    )
    {
    }

    // Retrieving master data
    public retrieveCountryDetails(): Observable<any>
    {
        const reqPrams = this.queryParamsService.asQueryParams(new CountrySearchCriteria());
        return this.requestService.get(
            UrlPaths.OP_DATA_URL_KEY,
            [UrlPaths.OP_DATA_VERSION, UrlPaths.COUNTRY_DATA],
            reqPrams
        );
    }

    public retrieveStateDetails(country: any): Observable<any>
    {
        if (country) {
            const commonDataCriteria = new StateSearchCriteria();
            commonDataCriteria.countryCode = country;
            const reqPrams = this.queryParamsService.asQueryParams(commonDataCriteria);
            return this.requestService.get(
                UrlPaths.OP_DATA_URL_KEY,
                [UrlPaths.OP_DATA_VERSION, UrlPaths.STATES],
                reqPrams
            );

        } else if (country === '') {
            const reqPrams = this.queryParamsService.asQueryParams(new StateSearchCriteria());
            return this.requestService.get(
                UrlPaths.OP_DATA_URL_KEY,
                [UrlPaths.OP_DATA_VERSION, UrlPaths.STATES],
                reqPrams
            );
        }
    }

    // public retrieveCityDetails(country: any, state: any): Observable<any[]> {
    // let cityURL = this.dataServiceURL + 'cities';
    // if (country._links && !state._links) {
    //     cityURL = country._links.cities.href;
    // } else if (state._links) {
    //     cityURL = state._links.cities.href;
    // }
    // return this.http.get<any[]>(cityURL);
    public retrieveCityDetails(country: string, state: string): Observable<any>
    {
        if (state && !country) {
            const commonDataCriteria = new CitySearchCriteria();
            commonDataCriteria.stateCode = state;
            const reqPrams = this.queryParamsService.asQueryParams(commonDataCriteria);
            return this.requestService.get(
                UrlPaths.OP_DATA_URL_KEY,
                [UrlPaths.OP_DATA_VERSION, UrlPaths.CITIES, UrlPaths.COMMON],
                reqPrams
            );
        } else if (!state && country) {
            const commonDataCriteria = new CitySearchCriteria();
            commonDataCriteria.countryCode = country;
            const reqPrams = this.queryParamsService.asQueryParams(commonDataCriteria);
            return this.requestService.get(
                UrlPaths.OP_DATA_URL_KEY,
                [UrlPaths.OP_DATA_VERSION, UrlPaths.CITIES, UrlPaths.COMMON],
                reqPrams
            );
        } else if (state && country) {
            const commonDataCriteria = new CitySearchCriteria();
            commonDataCriteria.countryCode = country;
            commonDataCriteria.stateCode = state;
            const reqPrams = this.queryParamsService.asQueryParams(commonDataCriteria);
            return this.requestService.get(
                UrlPaths.OP_DATA_URL_KEY,
                [UrlPaths.OP_DATA_VERSION, UrlPaths.CITIES, UrlPaths.COMMON],
                reqPrams
            );
        } else {
            const reqPrams = this.queryParamsService.asQueryParams(new CitySearchCriteria());
            return this.requestService.get(
                UrlPaths.OP_DATA_URL_KEY,
                [UrlPaths.OP_DATA_VERSION, UrlPaths.CITIES, UrlPaths.COMMON],
                reqPrams
            );
        }
    }

    public retrieveSpecificCityDetails(code: string): Observable<any>
    {
        if (code) {
            const commonDataCriteria = new CitySearchCriteria();
            commonDataCriteria.code = code;
            const reqPrams = this.queryParamsService.asQueryParams(commonDataCriteria);
            return this.requestService.get(
                UrlPaths.OP_DATA_URL_KEY,
                [UrlPaths.OP_DATA_VERSION, UrlPaths.CITIES, UrlPaths.COMMON],
                reqPrams
            );
        } else {
            const reqPrams = this.queryParamsService.asQueryParams(new CitySearchCriteria());
            return this.requestService.get(
                UrlPaths.OP_DATA_URL_KEY,
                [UrlPaths.OP_DATA_VERSION, UrlPaths.CITIES, UrlPaths.COMMON],
                reqPrams
            );
        }
    }

    public retrieveSpecificStateDetails(code: string): Observable<any>
    {
        if (code) {
            const commonDataCriteria = new StateSearchCriteria();
            commonDataCriteria.code = code;
            const reqPrams = this.queryParamsService.asQueryParams(commonDataCriteria);
            return this.requestService.get(
                UrlPaths.OP_DATA_URL_KEY,
                [UrlPaths.OP_DATA_VERSION, UrlPaths.STATES],
                reqPrams
            );
        } else {
            const reqPrams = this.queryParamsService.asQueryParams(new StateSearchCriteria());
            return this.requestService.get(
                UrlPaths.OP_DATA_URL_KEY,
                [UrlPaths.OP_DATA_VERSION, UrlPaths.STATES],
                reqPrams
            );
        }
    }

    public retrieveTitleDetails(): Observable<any>
    {
        const reqPrams = this.queryParamsService.asQueryParams(new CommonDataSearchCriteria(CommonDataType.TITLE));
        return this.requestService.get(
            UrlPaths.OP_DATA_URL_KEY,
            [UrlPaths.OP_DATA_VERSION, UrlPaths.COMMON_DATA],
            reqPrams
        );
    }

    public retrieveCategories(): Observable<any>
    {
        const reqPrams = this.queryParamsService.asQueryParams(new CommonDataSearchCriteria(CommonDataType.CATEGORY));
        return this.requestService.get(
            UrlPaths.OP_DATA_URL_KEY,
            [UrlPaths.OP_DATA_VERSION, UrlPaths.COMMON_DATA],
            reqPrams
        );
    }

    public retrieveResourceTypes(): Observable<any>
    {
        // return this.http.get<any[]>(this.dataServiceURL + 'resourceTypes');
        const reqPrams = this.queryParamsService.asQueryParams(new CommonDataSearchCriteria(CommonDataType.RESOURCE_CATEGORY));
        return this.requestService.get(
            UrlPaths.OP_DATA_URL_KEY,
            [UrlPaths.OP_DATA_VERSION, UrlPaths.COMMON_DATA],
            reqPrams
        );
    }

    public retrieveTaxSchemes(): Observable<any>
    {
        const reqPrams = this.queryParamsService.asQueryParams(new CommonDataSearchCriteria(CommonDataType.TAX_SCHEME_MD));
        return this.requestService.get(
            UrlPaths.OP_DATA_URL_KEY,
            [UrlPaths.OP_DATA_VERSION, UrlPaths.COMMON_DATA],
            reqPrams
        );
    }

    public retrieveCurrencyList(): Observable<any>
    {
        // return this.http.get<any[]>(this.dataServiceURL + 'currencies' + '?size=169');
        const reqPrams = this.queryParamsService.asQueryParams(new CommonDataSearchCriteria(CommonDataType.CURRENCY));
        return this.requestService.get(
            UrlPaths.OP_DATA_URL_KEY,
            [UrlPaths.OP_DATA_VERSION, UrlPaths.COMMON_DATA],
            reqPrams
        );
    }

    public retrievePaymentMethods(): Observable<any>
    {
        // return this.http.get<any[]>(this.dataServiceURL + 'paymentMethods');
        const reqPrams = this.queryParamsService.asQueryParams(new CommonDataSearchCriteria(CommonDataType.PAYMENT_METHOD));
        return this.requestService.get(
            UrlPaths.OP_DATA_URL_KEY,
            [UrlPaths.OP_DATA_VERSION, UrlPaths.COMMON_DATA],
            reqPrams
        );
    }

    public retrieveDocumentTypes(): Observable<any>
    {
        const reqPrams = this.queryParamsService.asQueryParams(new CommonDataSearchCriteria(CommonDataType.DOCUMENT_TYPE));
        return this.requestService.get(
            UrlPaths.OP_DATA_URL_KEY,
            [UrlPaths.OP_DATA_VERSION, UrlPaths.COMMON_DATA],
            reqPrams
        );
    }

    public retrieveTransferModes(): Observable<any>
    {
        const reqPrams = this.queryParamsService.asQueryParams(new CommonDataSearchCriteria(CommonDataType.TRANSFER_MODE));
        return this.requestService.get(
            UrlPaths.OP_DATA_URL_KEY,
            [UrlPaths.OP_DATA_VERSION, UrlPaths.COMMON_DATA],
            reqPrams
        );
    }

    public retrieveVehicleFacilities(): Observable<any>
    {
        const reqPrams = this.queryParamsService.asQueryParams(new CommonDataSearchCriteria(CommonDataType.VEHICLE_FACILITY));
        return this.requestService.get(
            UrlPaths.OP_DATA_URL_KEY,
            [UrlPaths.OP_DATA_VERSION, UrlPaths.COMMON_DATA],
            reqPrams
        );
    }

    public retrieveFormatOptions(): Observable<any>
    {
        const reqPrams = this.queryParamsService.asQueryParams(new CommonDataSearchCriteria(CommonDataType.DOCUMENT_FORMAT));
        return this.requestService.get(
            UrlPaths.OP_DATA_URL_KEY,
            [UrlPaths.OP_DATA_VERSION, UrlPaths.COMMON_DATA],
            reqPrams
        );
    }

    public retrieveDispatchTypes(): Observable<any>
    {
        const reqPrams = this.queryParamsService.asQueryParams(new CommonDataSearchCriteria(CommonDataType.DISPATCH_METHOD));
        return this.requestService.get(
            UrlPaths.OP_DATA_URL_KEY,
            [UrlPaths.OP_DATA_VERSION, UrlPaths.COMMON_DATA],
            reqPrams
        );
    }

    public retrieveFrequencyOptions(): Observable<any>
    {
        const reqPrams = this.queryParamsService.asQueryParams(new CommonDataSearchCriteria(CommonDataType.DOCUMENT_FREQUENCY));
        return this.requestService.get(
            UrlPaths.OP_DATA_URL_KEY,
            [UrlPaths.OP_DATA_VERSION, UrlPaths.COMMON_DATA],
            reqPrams
        );
    }

    getTransferModes(): Observable<any>
    {
        const reqPrams = this.queryParamsService.asQueryParams(new CommonDataSearchCriteria(CommonDataType.TRANSFER_MODE));
        return this.requestService.get(
            UrlPaths.OP_DATA_URL_KEY,
            [UrlPaths.OP_DATA_VERSION, UrlPaths.COMMON_DATA],
            reqPrams
        );
    }

    public retrieveRoutes(): Observable<any>
    {
        const reqPrams = this.queryParamsService.asQueryParams(new CommonDataSearchCriteria(CommonDataType.ROUTE));
        return this.requestService.get(
            UrlPaths.OP_DATA_URL_KEY,
            [UrlPaths.OP_DATA_VERSION, UrlPaths.COMMON_DATA],
            reqPrams
        );
    }

    public getLocationTypeOfLocation(locationCode: string): Observable<any>
    {
        const commonDataCriteria = new CommonDataSearchCriteria(CommonDataType.LOCATION_TYPE);
        commonDataCriteria.locationCode = locationCode;
        const reqPrams = this.queryParamsService.asQueryParams(commonDataCriteria);
        return this.requestService.get(
            UrlPaths.OP_DATA_URL_KEY,
            [UrlPaths.OP_DATA_VERSION, UrlPaths.COMMON_DATA],
            reqPrams
        );
    }

    public getLocationsOfLocationType(type: string): Observable<any>
    {
        const commonDataCriteria = new CommonDataSearchCriteria(CommonDataType.OP_LOCATION);
        commonDataCriteria.locationType = type;
        const reqPrams = this.queryParamsService.asQueryParams(commonDataCriteria);
        return this.requestService.get(
            UrlPaths.OP_DATA_URL_KEY,
            [UrlPaths.OP_DATA_VERSION, UrlPaths.COMMON_DATA],
            reqPrams
        );
    }

    public getLocations(): Observable<any>
    {
        const reqPrams = this.queryParamsService.asQueryParams(new CommonDataSearchCriteria(CommonDataType.OP_LOCATION));
        return this.requestService.get(
            UrlPaths.OP_DATA_URL_KEY,
            [UrlPaths.OP_DATA_VERSION, UrlPaths.COMMON_DATA],
            reqPrams
        );
    }

    public getLocationTypes(): Observable<any>
    {
        const reqPrams = this.queryParamsService.asQueryParams(new CommonDataSearchCriteria(CommonDataType.LOCATION_TYPE));
        return this.requestService.get(
            UrlPaths.OP_DATA_URL_KEY,
            [UrlPaths.OP_DATA_VERSION, UrlPaths.COMMON_DATA],
            reqPrams
        );
    }

    public getAirports(): Observable<any>
    {
        const commonDataCriteria = new CommonDataSearchCriteria(CommonDataType.OP_LOCATION);
        commonDataCriteria.locationType = 'Airport';
        const reqPrams = this.queryParamsService.asQueryParams(commonDataCriteria);
        return this.requestService.get(
            UrlPaths.OP_DATA_URL_KEY,
            [UrlPaths.OP_DATA_VERSION, UrlPaths.COMMON_DATA],
            reqPrams
        );
    }

    public retrieveSupplierTypes(): Observable<any>
    {
        const reqPrams = this.queryParamsService.asQueryParams(new CommonDataSearchCriteria(CommonDataType.SUPPLIER_TYPE));
        return this.requestService.get(
            UrlPaths.OP_DATA_URL_KEY,
            [UrlPaths.OP_DATA_VERSION, UrlPaths.COMMON_DATA],
            reqPrams
        );
    }

    public getLocationsOfCity(code: string): Observable<any>
    {
        if (code) {
            const commonDataCriteria = new CommonDataSearchCriteria(CommonDataType.OP_LOCATION);
            commonDataCriteria.locationType = 'Airport';
            const reqPrams = this.queryParamsService.asQueryParams(commonDataCriteria);
            return this.requestService.get(
                UrlPaths.OP_DATA_URL_KEY,
                [UrlPaths.OP_DATA_VERSION, UrlPaths.COMMON_DATA],
                reqPrams
            );
        } else {
            const reqPrams = this.queryParamsService.asQueryParams(new CommonDataSearchCriteria(CommonDataType.OP_LOCATION));
            return this.requestService.get(
                UrlPaths.OP_DATA_URL_KEY,
                [UrlPaths.OP_DATA_VERSION, UrlPaths.COMMON_DATA],
                reqPrams
            );
        }
    }

    public retrieveDaysOfWeek(): Observable<any>
    {
        const reqPrams = this.queryParamsService.asQueryParams(new CommonDataSearchCriteria(CommonDataType.DAY_OF_WEEK));
        return this.requestService.get(
            UrlPaths.OP_DATA_URL_KEY,
            [UrlPaths.OP_DATA_VERSION, UrlPaths.COMMON_DATA],
            reqPrams
        );
    }

    public retrieveVehicleModels(): Observable<any>
    {
        const reqPrams = this.queryParamsService.asQueryParams(new CommonDataSearchCriteria(CommonDataType.VEHICLE_MODEL));
        return this.requestService.get(
            UrlPaths.OP_DATA_URL_KEY,
            [UrlPaths.OP_DATA_VERSION, UrlPaths.COMMON_DATA],
            reqPrams
        );
    }

    public retrieveLocales(): Observable<any>
    {
        const reqPrams = this.queryParamsService.asQueryParams(new CommonDataSearchCriteria(CommonDataType.LOCALE));
        return this.requestService.get(
            UrlPaths.OP_DATA_URL_KEY,
            [UrlPaths.OP_DATA_VERSION, UrlPaths.COMMON_DATA],
            reqPrams
        );
    }

    public getProfileTypes(): Observable<any>
    {
        const reqPrams = this.queryParamsService.asQueryParams(new CommonDataSearchCriteria(CommonDataType.PROFILE_TYPE));
        return this.requestService.get(
            UrlPaths.OP_DATA_URL_KEY,
            [UrlPaths.OP_DATA_VERSION, UrlPaths.COMMON_DATA],
            reqPrams
        );
    }

    public getRoomTypes(): Observable<any>
    {
        const reqPrams = this.queryParamsService.asQueryParams(new CommonDataSearchCriteria(CommonDataType.PROFILE_TYPE));
        return this.requestService.get(
            UrlPaths.OP_DATA_URL_KEY,
            [UrlPaths.OP_DATA_VERSION, UrlPaths.COMMON_DATA],
            reqPrams
        );
    }

    public getDivisions(criteria: KeyControlAndAbstractSearchCriteria): Observable<any>
    {
        const reqPrams = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.get(
            UrlPaths.OP_USER_PROFILE_URL_KEY,
            [UrlPaths.OP_DATA_VERSION, UrlPaths.DIVISIONS],
            reqPrams
        );
    }

    public getCompaniesAndDivisions(criteria: KeyControlAndAbstractSearchCriteria): Observable<any>
    {
        const reqPrams = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.get(
            UrlPaths.OP_USER_PROFILE_URL_KEY,
            [UrlPaths.OP_DATA_VERSION, UrlPaths.COMPANIES],
            reqPrams
        );
    }
}
