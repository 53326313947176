<div class="container-fluid tc-item-wrapper tcpi-card">
    <div class="tc-item-wrapper__header tc-item-header-highlighted" *ngIf="showTitle">
        <div class="tc-item-header-highlighted__icon">
            <i class="material-icons material-icons">menu</i>
        </div>
        <div class="tc-item-header-highlighted__primary">{{"Supplier Attributes" | translate}}</div>
    </div>
    <div class="tc-item-wrapper__body" *ngIf="!supplierAttributes || supplierAttributes.length===0">
        <div class="row">
            <div class="col-md-12">
                <div class="tc-flex-box">
                    <div class="tc-flex-item icon-cage input-icon">
                        <div class="card-icon--small icn-color-primary">
                            <i class="material-icons material-icons">format_align_justify</i>
                        </div>
                    </div>
                    <div class="tc-flex-item input-cage">
                        <div class="container-fluid">
                            <span class="txt-light">{{'No Supplier Attributes' | translate}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="tc-item-wrapper__body" [hidden]="!supplierAttributes || supplierAttributes.length===0">
        <div class="row">
            <div class="col-md-12">
                <div class="tc-flex-box">
                    <div class="tc-flex-item icon-cage input-icon">
                        <div class="card-icon--small icn-color-primary">
                        </div>
                    </div>
                    <div class="tc-flex-item input-cage">
                        <div class="mat-elevation-z8">
                            <table mat-table [dataSource]="dataSource" matSort class="rs-full-width">

                                <!-- Position Column -->
                                <ng-container matColumnDef="index">
                                    <th mat-header-cell *matHeaderCellDef> {{'No.' | translate}}</th>
                                    <td mat-cell *matCellDef="let element; let i = index;"> {{i + 1}} </td>
                                </ng-container>

                                <!-- Season Name Column -->
                                <ng-container matColumnDef="attributeCode">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'Code' | translate}}</th>
                                    <td mat-cell *matCellDef="let element"> {{element.attributeCode}} </td>
                                </ng-container>

                                <!-- Route Column -->
                                <ng-container matColumnDef="attributeValue">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'Value' | translate}}</th>
                                    <td mat-cell *matCellDef="let element"> {{element.attributeValue}} </td>
                                </ng-container>


                                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                            </table>

                            <mat-paginator pageSize="5" showFirstLastButtons></mat-paginator>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
