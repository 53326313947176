import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SummaryCardData } from '@tc-core/model/it/codegen/ui/framework/summary-card-data';
import { ResourceAllocationEvent } from '../../../models/helper/resource-allocation-event';
import { ResourceAllocationSearchEvent } from '../../../models/helper/resource-allocation-search-event';
import { ServiceItemChanges } from '../../../models/helper/service-item-changes';
import { ServiceNote } from '../../../models/reservation/service-note';
import { DriverAllocationSummary } from '../../../models/summary/driver-allocation-summary';
import { TrsSupplierAllocationSummary } from '../../../models/summary/trs-supplier-allocation-summary';
import { VehicleSummary } from '../../../models/summary/vehicle-summary';
import { ReservationServiceItemsComponent } from '../reservation-service-items/reservation-service-items.component';

@Component({
    selector: 'tc-reservation-detail-view',
    templateUrl: './reservation-detail-view.component.html'
})
export class ReservationDetailViewComponent implements OnInit {

    @Input() summaryCardData: SummaryCardData;
    @Input() reservationServiceItemCards: SummaryCardData[];
    @Input() footerDataMap: Map<number, object>;
    @Input() supplierList: any = [];
    @Input() resourceList: any = [];

    @Input() serviceItemChangesInSelectedGroup: Map<number, ServiceItemChanges> = new Map();
    @Input() filteredVehicleSuppliers: TrsSupplierAllocationSummary[];
    @Input() filteredDriverSuppliers: TrsSupplierAllocationSummary[];
    @Input() filteredVehicles: VehicleSummary[];
    @Input() filteredDrivers: DriverAllocationSummary[];
    @Input() isRequestCompleted: boolean;

    @Input() changesAvailableForServiceItems: boolean;
    @Input() selectedServiceGroupNotes: ServiceNote[];
    @Output() headerClick: EventEmitter<any> = new EventEmitter();
    @Output() clickOutSide: EventEmitter<any> = new EventEmitter();

    @Output() pickUps: EventEmitter<any> = new EventEmitter<any>();
    @Output() dropOffs: EventEmitter<any> = new EventEmitter<any>();

    @Output() moreClick: EventEmitter<any> = new EventEmitter();
    @Output() historyClick: EventEmitter<any> = new EventEmitter<any>();
    @Output() splitClick: EventEmitter<SummaryCardData> = new EventEmitter();
    @Output() leadPassengerClick: EventEmitter<SummaryCardData> = new EventEmitter();

    @Output() confirmClick: EventEmitter<any> = new EventEmitter();
    @Output() clickSupplierReject: EventEmitter<any> = new EventEmitter();
    @Output() saveAssignments: EventEmitter<any> = new EventEmitter();
    @Output() clickMerge: EventEmitter<any> = new EventEmitter();
    @Output() bulkVehicleAssignment: EventEmitter<any> = new EventEmitter();
    @Output() bulkDriverAssignment: EventEmitter<any> = new EventEmitter();

    @Output() vehicleSupplierAssign: EventEmitter<{ ResourceAllocationEvent }> = new EventEmitter();
    @Output() driverSupplierAssign: EventEmitter<{ ResourceAllocationEvent }> = new EventEmitter();
    @Output() vehicleAssign: EventEmitter<{ ResourceAllocationEvent }> = new EventEmitter();
    @Output() driverAssign: EventEmitter<ResourceAllocationEvent> = new EventEmitter();

    @Output() vehicleSupplierClear: EventEmitter<{ ResourceAllocationEvent }> = new EventEmitter();
    @Output() driverSupplierClear: EventEmitter<{ ResourceAllocationEvent }> = new EventEmitter();
    @Output() vehicleClear: EventEmitter<{ ResourceAllocationEvent }> = new EventEmitter();
    @Output() driverClear: EventEmitter<ResourceAllocationEvent> = new EventEmitter();

    @Output() vehicleSupplierTextChange: EventEmitter<ResourceAllocationSearchEvent> = new EventEmitter();
    @Output() driverSupplierTextChange: EventEmitter<ResourceAllocationSearchEvent> = new EventEmitter();
    @Output() vehicleTextChange: EventEmitter<ResourceAllocationSearchEvent> = new EventEmitter();
    @Output() driverTextChange: EventEmitter<ResourceAllocationSearchEvent> = new EventEmitter();
    @Output() vehicleJobNoChange: EventEmitter<ResourceAllocationSearchEvent> = new EventEmitter();
    @Output() driverJobNoChange: EventEmitter<ResourceAllocationSearchEvent> = new EventEmitter();
    @Output() clearVehicleAssignment: EventEmitter<ResourceAllocationSearchEvent> = new EventEmitter();
    @Output() clearDriverAssignment: EventEmitter<ResourceAllocationSearchEvent> = new EventEmitter();
    @Output() autoAssignResources: EventEmitter<any> = new EventEmitter();

    @Output() vehicleTextFocus: EventEmitter<ResourceAllocationSearchEvent> = new EventEmitter();
    @Output() serviceGroupNote: EventEmitter<any> = new EventEmitter();
    @Output() emptyServiceGroupNote: EventEmitter<any> = new EventEmitter();
    @Output() saveNote: EventEmitter<ServiceNote> = new EventEmitter();
    @Output() deleteNote: EventEmitter<ServiceNote> = new EventEmitter();
    @Output() isEditClicked: EventEmitter<boolean> = new EventEmitter();
    @Output() isSavedChanges: EventEmitter<boolean> = new EventEmitter();

    @Output() viewMoreFocus: EventEmitter<any> = new EventEmitter();

    selectedPickups: string[] = [];
    selectedDropOffs: string[] = [];
    arrFltNumbers: string[] = [];
    deptFltNumbers: string[] = [];
    arrFltTerminals: string[] = [];
    deptFltTerminals: string[] = [];
    arrFltTime: string[] = [];
    deptFltTime: string[] = [];
    arrAirports: string[] = [];
    deptAirports: string[] = [];
    showFlightInfo: boolean = true;
    disableGroupNoteEdit: boolean = false;

    constructor() { }

    ngOnInit() {
        if (this.summaryCardData && this.summaryCardData.data && this.summaryCardData.data.value['rawData']) {
            this.selectedPickups = this.summaryCardData.data.value['rawData']['pickups'];
            this.selectedDropOffs = this.summaryCardData.data.value['rawData']['dropOffs'];
            this.arrFltNumbers = this.summaryCardData.data.value['rawData']['arrFltNumbers'];
            this.deptFltNumbers = this.summaryCardData.data.value['rawData']['deptFltNumbers'];
            this.arrFltTerminals = this.summaryCardData.data.value['rawData']['arrFltTerminals'];
            this.deptFltTerminals = this.summaryCardData.data.value['rawData']['deptFltTerminals'];
            this.arrFltTime = this.summaryCardData.data.value['rawData']['arrFltTime'];
            this.deptFltTime = this.summaryCardData.data.value['rawData']['deptFltTime'];
            this.arrAirports = this.summaryCardData.data.value['rawData']['arrAirports'];
            this.deptAirports = this.summaryCardData.data.value['rawData']['deptAirports'];
        }
        if (this.arrFltNumbers.length > 0 || this.deptFltNumbers.length > 0 || this.arrFltTerminals.length > 0 ||
            this.deptFltTerminals.length > 0) {
            this.showFlightInfo = true;
        } else {
            this.showFlightInfo = false;
        }

    }

    /*disableNoteEdit(){
     this.serviceItemChangesInSelectedGroup.forEach(value => {
     if (value.assignment && value.assignment.status === AssignmentStatus.CONFIRM) {
     this.disableGroupNoteEdit = true;
     }
     });
     }*/

    onHeaderClick(event) {
        this.headerClick.emit(event);
    }

    onOutSideClick(event) {
        this.clickOutSide.emit(event);
    }

    onPickUpsClick(event) {
        this.pickUps.emit(event);
    }

    onDropOffsClick(event) {
        this.dropOffs.emit(event);
    }

    public onSelectVehicleSupplier($event) {
        this.vehicleSupplierAssign.emit($event);
    }
    public onSelectDriverSupplier($event) {
        this.driverSupplierAssign.emit($event);
    }

    public onSelectVehicle($event) {
        this.vehicleAssign.emit($event);
    }

    public onSelectDriver($event) {
        this.driverAssign.emit($event);
    }

    public clearVehicleSupplier($event) {
        this.vehicleSupplierClear.emit($event);
    }
    public clearDriverSupplier($event) {
        this.driverSupplierClear.emit($event);
    }

    public clearVehicle($event) {
        this.vehicleClear.emit($event);
    }

    public clearDriver($event) {
        this.driverClear.emit($event);
    }

    public onVehicleSupplierTextChange($event: ResourceAllocationSearchEvent) {
        this.vehicleSupplierTextChange.emit($event);
    }
    public onDriverSupplierTextChange($event: ResourceAllocationSearchEvent) {
        this.driverSupplierTextChange.emit($event);
    }

    public onAutoAssignResources(){
        this.autoAssignResources.emit(this.summaryCardData);
    }

    public onVehicleTextChange($event: ResourceAllocationSearchEvent) {
        this.vehicleTextChange.emit($event);
    }

    public onDriverTextChange($event: ResourceAllocationSearchEvent) {
        this.driverTextChange.emit($event);
    }

    public onVehicleFocus($event: ResourceAllocationSearchEvent) {
        this.vehicleTextFocus.emit($event);
    }

    onVehicleJobNoChange($event: ResourceAllocationSearchEvent) {
        this.vehicleJobNoChange.emit($event);
    }

    onDriverJobNoChange($event: ResourceAllocationSearchEvent) {
        this.driverJobNoChange.emit($event);
    }

    onClearVehicleAssignment($event: ResourceAllocationSearchEvent) {
        this.clearVehicleAssignment.emit($event);
    }

    onClearDriverAssignment($event: ResourceAllocationSearchEvent) {
        this.clearDriverAssignment.emit($event);
    }

    public onMoreClick(event) {
        this.moreClick.emit(event);
    }

    public onSplitClick(serviceItemCard: SummaryCardData) {
        this.splitClick.emit(serviceItemCard);
    }

    public onLeadPassengerSelect(serviceItemCard: SummaryCardData) {
        this.leadPassengerClick.emit(serviceItemCard);
    }

    public onConfirmClick($event: any) {
        this.confirmClick.emit($event);
    }

    public onSaveAssignments() {
        this.saveAssignments.emit();
    }

    public onClickMerge() {
        this.clickMerge.emit();
    }

    public onBulkVehicleAssignment() {
        this.bulkVehicleAssignment.emit();
    }
    public onBulkDriverAssignment() {
        this.bulkDriverAssignment.emit();
    }

    public addGroupNote() {
        this.serviceGroupNote.emit();
    }

    public addEmptyGroupNote() {
        this.emptyServiceGroupNote.emit();
    }

    public onNoteSave(event: ServiceNote) {
        this.saveNote.emit(event);
    }

    public onDelete(event: ServiceNote) {
        this.deleteNote.emit(event);
    }

    public onEdit(event: boolean) {
        this.isEditClicked.emit(event);
    }

    public onSavedChanges(event: boolean) {
        this.isSavedChanges.emit(event);
    }

    onViewMoreClick(serviceItem: any) {
        this.viewMoreFocus.emit(serviceItem);
    }

    noteEditEnable() {
        let disable = true;
        let statusOk = 0;
        this.serviceItemChangesInSelectedGroup.forEach(value => {
            if (ReservationServiceItemsComponent.isRestricted(value)) {
                statusOk++;
            }
        });
        if (statusOk !== this.serviceItemChangesInSelectedGroup.size) {
            disable = false;
        }
        return disable;
    }

    public onClickSupplierReject() {
        this.clickSupplierReject.emit();
    }

    public onHistoryClick(event: any) {
        this.historyClick.emit(event);
    }
}
