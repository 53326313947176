<ag-grid-angular
        class="ag-theme-material tc-ag-grid tc-ag-grid--full-width tc-ag-grid--popup-content"
        [rowData]="group?.bookingPassengers"
        [gridOptions]="gridOptions"
        [defaultColDef]="defaultColDef"
        [columnDefs]="columnDefs"
        [domLayout]="'autoHeight'"
        [rowSelection]="'multiple'"
        [frameworkComponents]="frameworkComponents"
        (gridReady)="onGridReady($event)"
        (firstDataRendered)="onFirstDataRendered($event)"
        (rowSelected)="onRowSelected($event)">
</ag-grid-angular>


<!--status and incomplete view-->
<ng-template #passengerInfo let-row>
    <div
            *ngIf="row.passengerAttributes && row.passengerAttributes.length"
            class="tc-ag-grid-indicator tc-ag-grid-indicator--info"
            matTooltip="{{getToolTip(row)}}"
            matTooltipPosition="right"
            aria-label="indicator tooltip"
            matTooltipClass="mat-tooltip--large mat-tooltip--success">
    </div>
</ng-template>
