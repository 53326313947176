<div class="container-fluid tc-item-wrapper tcpi-card">
    <div class="tc-item-wrapper__header tc-item-header-highlighted" *ngIf="showTitle">
        <div class="tc-item-header-highlighted__icon">
            <i class="material-icons material-icons">credit_card</i>
        </div>
        <div class="tc-item-header-highlighted__primary">{{"Payment Information" | translate}}</div>
    </div>
    <div class="tc-item-wrapper__body" *ngIf="!genResourceCosts || genResourceCosts.length===0">
        <div class="row">
            <div class="col-md-12">
                <div class="tc-flex-box">
                    <div class="tc-flex-item icon-cage input-icon">
                        <div class="card-icon--small icn-color-primary">
                        </div>
                    </div>
                    <div class="tc-flex-item input-cage">
                        <div class="container-fluid">
                            <span class="txt-light">{{'No Cost Setup Data' | translate}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="tc-item-wrapper__body" [hidden]="!genResourceCosts || genResourceCosts.length===0">
        <div class="row">
            <div class="col-md-12">
                <div class="tc-flex-box">
                    <!--<div class="tc-flex-item icon-cage input-icon">-->
                    <!--<div class="card-icon&#45;&#45;small icn-color-primary">-->
                    <!--</div>-->
                    <!--</div>-->
                    <div class="tc-flex-item input-cage w-max-100">

                        <div class="mat-elevation-z0 tc-mat-table tc-mat-table--border">
                            <div class="w-max-100 overflow-auto">
                                <table mat-table [dataSource]="dataSource" matSort class="rs-full-width">

                                    <!-- Position Column -->
                                    <ng-container matColumnDef="index">
                                        <th mat-header-cell *matHeaderCellDef class="l-w-minmax-150"> {{'No.' | translate}}</th>
                                        <td mat-cell *matCellDef="let element; let i = index;" class="l-w-minmax-150"> {{i + 1}} </td>
                                    </ng-container>

                                    <!-- Season Name Column -->
                                    <ng-container matColumnDef="seasonName">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="l-w-minmax-150"> {{'Season Name' | translate}}</th>
                                        <td mat-cell *matCellDef="let element" class="l-w-minmax-150"> {{element.seasonName}} </td>
                                    </ng-container>

                                    <!-- Resource Type Column -->
                                    <ng-container matColumnDef="resourceType">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="l-w-minmax-150"> {{'Resource Type' | translate}}</th>
                                        <td mat-cell *matCellDef="let element" class="l-w-minmax-150"> {{getResourceTypeName(element.resourceType)}} </td>
                                    </ng-container>

                                    <!-- Profile Type Column -->
                                    <ng-container matColumnDef="profileType">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="l-w-minmax-150"> {{'Profile Type' | translate}}</th>
                                        <td mat-cell *matCellDef="let element" class="l-w-minmax-150"> {{getProfileTypeName(element.profileType)}} </td>
                                    </ng-container>

                                    <!-- Days Column -->
                                    <ng-container matColumnDef="days">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="l-w-minmax-300"> {{'Days' | translate}}</th>
                                        <td mat-cell *matCellDef="let element" class="tc-mat-table-cell l-w-minmax-300">
                                            <div class="tc-day-selector__container">
                                                <label class="tc-day-selector">
                                                    <input type="checkbox" [checked]="element.days && element.days.includes(1)" disabled>
                                                    <span class="indicator indicator--alt">S</span>
                                                </label>
                                                <label class="tc-day-selector">
                                                    <input type="checkbox" [checked]="element.days && element.days.includes(2)" disabled>
                                                    <span class="indicator">M</span>
                                                </label>
                                                <label class="tc-day-selector">
                                                    <input type="checkbox" [checked]="element.days && element.days.includes(3)" disabled>
                                                    <span class="indicator">T</span>
                                                </label>
                                                <label class="tc-day-selector">
                                                    <input type="checkbox" [checked]="element.days && element.days.includes(4)" disabled>
                                                    <span class="indicator">W</span>
                                                </label>
                                                <label class="tc-day-selector">
                                                    <input type="checkbox" [checked]="element.days && element.days.includes(5)" disabled>
                                                    <span class="indicator">T</span>
                                                </label>
                                                <label class="tc-day-selector">
                                                    <input type="checkbox" [checked]="element.days && element.days.includes(6)" disabled>
                                                    <span class="indicator">F</span>
                                                </label>
                                                <label class="tc-day-selector">
                                                    <input type="checkbox" [checked]="element.days && element.days.includes(7)" disabled>
                                                    <span class="indicator">S</span>
                                                </label>
                                            </div>
                                        </td>
                                    </ng-container>

                                    <!-- Costing Type Column -->
                                    <ng-container matColumnDef="costingType">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="l-w-minmax-150"> {{'Costing Type' | translate}}</th>
                                        <td mat-cell *matCellDef="let element" class="l-w-minmax-150"> {{getCostingTypeNameFromList(element.costingType)}} </td>
                                    </ng-container>

                                    <!-- Unit Fare Column -->
                                    <ng-container matColumnDef="unitFare">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="l-w-minmax-150"> {{'Unit Fare' | translate}}</th>
                                        <td mat-cell *matCellDef="let element" class="l-w-minmax-150">
                                            {{element.unitFare ? (element.unitFare | tcCurrencyPipe:currencyFormat?.currencyCode:element.currency:currencyFormat?.pattern) : EMPTY_CHAR}}
                                        </td>
                                    </ng-container>


                                    <!-- Adult Fare Column -->
                                    <ng-container matColumnDef="adultFare">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="l-w-minmax-150"> {{'Adult Fare' | translate}}</th>
                                        <td mat-cell *matCellDef="let element" class="l-w-minmax-150">
                                            {{element.adultFare ? (element.adultFare | tcCurrencyPipe:currencyFormat?.currencyCode:element.currency:currencyFormat?.pattern) : EMPTY_CHAR}}
                                        </td>
                                    </ng-container>

                                    <!-- Child Fare Column -->
                                    <ng-container matColumnDef="childFare">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="l-w-minmax-150"> {{'Child Fare' | translate}}</th>
                                        <td mat-cell *matCellDef="let element" class="l-w-minmax-150">
                                            {{element.childFare ? (element.childFare | tcCurrencyPipe:currencyFormat?.currencyCode:element.currency:currencyFormat?.pattern) : EMPTY_CHAR}}
                                        </td>
                                    </ng-container>

                                    <!-- Infant Fare Column -->
                                    <ng-container matColumnDef="infantFare">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="l-w-minmax-150"> {{'Infant Fare' | translate}}</th>
                                        <td mat-cell *matCellDef="let element" class="l-w-minmax-150">
                                            {{element.infantFare ? (element.infantFare | tcCurrencyPipe:currencyFormat?.currencyCode:element.currency:currencyFormat?.pattern) : EMPTY_CHAR}}
                                        </td>
                                    </ng-container>

                                    <!-- Teen Fare Column -->
                                    <ng-container matColumnDef="teenFare">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="l-w-minmax-150"> {{'Teen Fare' | translate}}</th>
                                        <td mat-cell *matCellDef="let element" class="l-w-minmax-150">
                                            {{element.teenFare ? (element.teenFare | tcCurrencyPipe:currencyFormat?.currencyCode:element.currency:currencyFormat?.pattern) : EMPTY_CHAR}}
                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                                </table>
                            </div>
                            <mat-paginator pageSize="5" showFirstLastButtons></mat-paginator>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    </div>

</div>