import {Injectable} from '@angular/core';
import {Client} from '@tc-core/model/it/codegen/tbx/api/client/client';
import {TcApiError} from '@tc-core/model/it/codegen/tbx/ext/errors/tc-api-error';
import {TcHttpError} from '@tc-core/model/it/codegen/tbx/ext/errors/tc-http-error';
import {Icon} from '@tc-core/model/it/codegen/ui/framework/icon';
import {KeyValue} from '@tc-core/model/it/codegen/ui/key-value';
import {TC} from '@tc-core/util/constants';
import {DataKey, DataStore} from '@tc-core/util/framework/data-store.service';
import {CommonHelper} from '@tc-core/util/helpers/common-helper.service';
import {DataFormatterUtilService} from '@tc-core/util/system/data-formatter-util.service';
import {JsonUtilService} from '@tc-core/util/system/json-util.service';
import {Subscription} from 'rxjs';
import {TCO} from '../../../constants';
import {AssignmentStatus} from '../../../models/reservation/assignment-status';
import {NoteType} from '../../../models/reservation/note-item-view';
import {ServiceNote} from '../../../models/reservation/service-note';

import DEF_CURRENCY_TYPE = TCO.AppData.DEF_CURRENCY_TYPE;
import {ResourceAvailability} from '../../../models/resource/resource-availability';

@Injectable()
export class CardContentPreProcessorService {

    constructor(
        private jsonUtil: JsonUtilService,
        private dataFormatterUtilService: DataFormatterUtilService,
        private dataStore: DataStore,
        private commonHelper: CommonHelper
    ) {
    }

    // todo :change this to get from config
    private defaultCurrencyMark = 'AED';

    /**
     *
     * @param client
     * @param arr
     * @returns {any}
     */
    filterByEmailContact(client: Client, arr: any[], id: string): KeyValue {
        let returnVal = new KeyValue;
        returnVal.key = id;
        returnVal.value = this.filterByContactType(client, arr, 'email');
        return returnVal;
    }

    /**
     *
     * @param client
     * @param arr
     * @returns {any}
     */
    filterByPhoneNoContact(client: Client, arr: any[], id: string): KeyValue {
        let returnVal = new KeyValue;
        returnVal.key = id;
        returnVal.value = this.filterByContactType(client, arr, 'phoneNo');
        return returnVal;
    }

    /**
     *
     * @param client
     * @param arr
     * @param type
     * @returns {any}
     */
    filterByContactType(client: Client, arr: any[], type: string) {
        let contactValue: string = '';
        let contactItems = this.jsonUtil.getDataArray(client, arr);
        if (contactItems && contactItems.length > 0) {

            contactItems = contactItems.filter(
                item => item['contactType'] === type);

            if (contactItems && contactItems.length > 0 && contactItems[0].value && contactItems[0]['value']) {

                contactValue = contactItems[0]['value'];
                if (contactValue && contactValue.length > 0 && contactValue.indexOf('#') > 0) {
                    contactValue = contactValue.substr(0, contactValue.indexOf('#'));
                }
            }
        }

        return contactValue;
    }

    /**
     *
     * @param client
     * @param arr
     * @returns {Icon}
     */
    processClientIcon(client: Client, arr: any[], id: string): KeyValue {
        let returnVal = new KeyValue();
        let iconView = new Icon();
        let clientType = client.type;
        let filteredIcons = arr.filter(
            item => item['type'] === clientType);

        let iconObj = filteredIcons.length > 0 ? filteredIcons[0] : null;
        if (iconObj) {
            iconView.key = iconObj['key'];
            iconView.iconType = iconObj['iconType'];

            if (client.summary.active) {
                iconView.style = iconObj['style'] + ' ' + iconObj['activeClass'];
            } else {
                iconView.style = iconObj['style'] + ' ' + iconObj['inactiveClass'];
            }

            iconView.value = this.jsonUtil.findJsonProperty(client, iconObj['value'].split('.'), null);
            iconView.value = iconView.value ? iconView.value : '';
        }
        returnVal.key = id;
        returnVal.value = iconView;
        return returnVal;
    }

    /**
     *
     * @param reservation
     * @param arr
     * @param id
     */
    processTransferModeIcon(reservation: any, arr: any[], id: string): KeyValue {
        let returnVal = new KeyValue();
        let iconView = new Icon();
        let transferModeIcon = arr.find(
            item => item['iconName'] === 'TRANSFER_MODE_ICN');
        let valueField = transferModeIcon['key'];
        let statusField = transferModeIcon['status'];

        let transferModeValue = reservation[valueField];

        if (transferModeIcon) {
            iconView.key = transferModeIcon['iconName'];
            iconView.iconType = transferModeValue;
            iconView.value = transferModeValue;
            iconView.style = transferModeIcon['style'] + ' ' +
                transferModeIcon[this.getStatusClass(reservation[statusField])];
            iconView.value = iconView.value ? iconView.value : '';
            if (iconView.value.length > 3) {
                iconView.style = iconView.style + ' ' + transferModeIcon['smallTextClass'];
            }
        }
        returnVal.key = id;
        returnVal.value = iconView;
        return returnVal;
    }


    processAllocationSupplierIcon(resource: any, arr: any[], id: string): KeyValue {
        if (resource.transferMode) {
            return this.processTransferModeIconForSupplierAssignment(resource, arr, id);
        } else {
            return this.processGenericIconForSupplierAssignment(resource, arr, id);
        }
    }

    processTransferModeIconForSupplierAssignment(reservation: any, arr: any[], id: string): KeyValue {
        let returnVal = new KeyValue();
        let iconView = new Icon();
        let transferModeIcon = arr.find(
            item => item['iconNameVehicle'] === 'TRANSFER_MODE_ICN');
        let valueField = transferModeIcon['key'];
        let transferModeValue = reservation[valueField];

        if (transferModeIcon) {
            iconView.key = transferModeIcon['iconNameVehicle'];
            iconView.iconType = transferModeValue;
            iconView.value = transferModeValue;
            iconView.style = transferModeIcon['style'] + ' ' +
                transferModeIcon['neutralClass'];
            iconView.value = iconView.value ? iconView.value : '';
            if (iconView.value.length > 3) {
                iconView.style = iconView.style + ' ' + transferModeIcon['smallTextClass'];
            }
        }
        returnVal.key = id;
        returnVal.value = iconView;
        return returnVal;
    }

    processGenericIconForSupplierAssignment(genericResource: any, arr: any[], id: string): KeyValue {
        let returnVal = new KeyValue();
        let iconView = new Icon();
        let genericResourceIcon = arr.find(
            item => item['iconNameDriver'] === 'GENERIC_RESOURCE_ICN');

        let value = genericResourceIcon['keyForDriverAssignment'];

        if (genericResourceIcon) {
            if (genericResourceIcon.iconKeys[value]) {
                iconView.key = genericResourceIcon.iconKeys[value];
            }
            iconView.iconType = genericResourceIcon['iconType'];
            iconView.value = genericResourceIcon.values[value];
            iconView.style = genericResourceIcon['style'] + ' ' +
                genericResourceIcon['neutralClass'];
            iconView.value = iconView.value ? iconView.value : '';
            if (iconView.value.length > 3) {
                iconView.style = iconView.style + ' ' + genericResourceIcon['smallTextClass'];
            }
        }
        returnVal.key = id;
        returnVal.value = iconView;
        return returnVal;
    }


    processVehicleTransferModeIcon(resource: any, arr: any[], id: string): KeyValue {
        let returnVal = new KeyValue();
        let iconView = new Icon();
        let transferModeIcon = arr.find(
            item => item['iconName'] === 'TRANSFER_MODE_ICN');
        let valueField = transferModeIcon['key'];
        let statusField = transferModeIcon['status'];

        let transferModeValue = resource[valueField];

        if (transferModeIcon) {
            iconView.key = transferModeIcon['iconName'];
            iconView.iconType = transferModeValue;
            iconView.value = transferModeValue;
            if (resource.exceedCapacity) {
                iconView.style = transferModeIcon['style'] + ' ' +
                    transferModeIcon['dangerClass']
            } else {
                iconView.style = transferModeIcon['style'] + ' ' +
                    transferModeIcon['neutralClass'];
            }
            iconView.value = iconView.value ? iconView.value : '';
            if (iconView.value.length > 3) {
                iconView.style = iconView.style + ' ' + transferModeIcon['smallTextClass'];
            }
        }
        returnVal.key = id;
        returnVal.value = iconView;
        return returnVal;
    }

    processAllocationIcon(resource: ResourceAvailability, arr: any[]): ResourceAvailability {
        let returnVal = new KeyValue();
        let iconView = new Icon();
        let transferModeIcon = arr.find(
            item => item['iconName'] === 'TRANSFER_MODE_ICN');
        let valueField = transferModeIcon['key'];
        let statusField = transferModeIcon['status'];

        let transferModeValue = resource[valueField];

        if (transferModeIcon) {
            iconView.key = transferModeIcon['iconName'];
            iconView.iconType = transferModeValue;
            iconView.value = transferModeValue;
            iconView.value = iconView.value ? iconView.value : '';
            if (iconView.value.length > 3) {
                iconView.style = iconView.style + ' ' + transferModeIcon['smallTextClass'];
            }
        }
        resource.icon = iconView;
        return resource;
    }

    /**
     *
     * @param vehicleSummary
     * @param arr
     * @param id
     */
    processVehicleIcon(vehicleSummary: any, arr: any[], id: string): KeyValue {
        let returnVal = new KeyValue();
        let iconView = new Icon();
        let vehicleIcon = arr.find(
            item => item['iconName'] === 'VEHICLE_ICN');
        let valueField = vehicleIcon['key'];
        let statusField = vehicleIcon['statusField'];

        let vehicleModel = vehicleSummary[valueField];

        if (vehicleIcon) {
            iconView.key = vehicleIcon['iconName'];
            iconView.iconType = vehicleIcon['iconType'];
            // iconView.value = vehicleModel;
            iconView.style = vehicleIcon['style'] + ' ' +
                vehicleIcon[this.activeOrInactiveClass(vehicleSummary[statusField])];
            iconView.value = iconView.value ? iconView.value : '';
            if (iconView.value.length > 3) {
                iconView.style = iconView.style + ' ' + vehicleIcon['smallTextClass'];
            }
        }
        returnVal.key = id;
        returnVal.value = iconView;
        return returnVal;
    }

    /**
     *
     * @param supplier
     * @param arr
     * @param id
     */
    processSupplierIcon(supplier: any, arr: any[], id: string): KeyValue {
        let returnVal = new KeyValue();
        let iconView = new Icon();
        let supplierIcon = arr.find(
            item => item['iconName'] === 'SUPPLIER_ICN');
        let statusField = supplierIcon['statusField'];

        let supplierMode = supplier[statusField];
        let iconValue = supplierIcon['value'];

        if (supplierIcon) {
            iconView.key = supplierIcon['iconName'];
            iconView.iconType = supplierIcon['iconType'];
            iconView.value = iconValue;
            iconView.style = supplierIcon['style'] + ' ' + supplierIcon[this.activeOrInactiveClass(supplierMode)];
            iconView.value = iconView.value ? iconView.value : '';
            if (iconView.value.length > 3) {
                iconView.style = iconView.style + ' ' + supplierIcon['smallTextClass'];
            }
        }
        returnVal.key = id;
        returnVal.value = iconView;
        return returnVal;
    }

    activeOrInactiveClass(condition): string {
        let cls = '';
        if (condition) {
            cls = 'activeClass';
        } else {
            cls = 'inactiveClass';
        }
        return cls;
    }

    /**
     *
     * @param bookingItemInfo
     * @param arr
     * @param id
     */
    processBookingItemIcon(bookingItemInfo: any, arr: any[], id: string): KeyValue {
        const returnVal = new KeyValue();
        const iconView = new Icon();
        const iconResource = arr.find(item => item.key === 'BOOKING_ITEM_ICN');
        if (iconResource) {
            const iconValueField = iconResource.value;
            const iconValue = bookingItemInfo[iconValueField];
            iconView.key = iconResource.iconKeys[iconValue];
            if (!iconView.key) {
                iconView.key = iconResource.iconKeys.default;
            }
            iconView.iconType = iconResource.iconType;
            iconView.style = iconResource.style + ' ' + iconResource.activeClass;
            iconView.value = iconView.value ? iconView.value : '';
        }
        returnVal.key = id;
        returnVal.value = iconView;
        return returnVal;
    }

    /**
     *
     * @param genericResource
     * @param arr
     * @param id
     */
    processGenericIcon(genericResource: any, arr: any[], id: string): KeyValue {
        let returnVal = new KeyValue();
        let iconView = new Icon();
        let genericResourceIcon = arr.find(
            item => item['iconName'] === 'GENERIC_RESOURCE_ICN');

        let valueField = genericResourceIcon['key'];
        let iconValue = genericResource[valueField];

        let statusField = genericResourceIcon['statusField'];
        let resourceMode = statusField ? genericResource[statusField] : true;

        if (genericResourceIcon) {
            if (genericResourceIcon.iconKeys[iconValue]) {
                iconView.key = genericResourceIcon.iconKeys[iconValue];
            }
            iconView.iconType = genericResourceIcon['iconType'];
            iconView.value = genericResourceIcon.values[iconValue];
            iconView.style = genericResourceIcon['style'] + ' ' +
                genericResourceIcon[this.activeOrInactiveClass(resourceMode)];
            iconView.value = iconView.value ? iconView.value : '';
            if (iconView.value.length > 3) {
                iconView.style = iconView.style + ' ' + genericResourceIcon['smallTextClass'];
            }
        }
        returnVal.key = id;
        returnVal.value = iconView;
        return returnVal;
    }

    private getStatusClass(status: string): string {
        let classConfigName = '';
        if (status == AssignmentStatus.PENDING) {
            classConfigName = 'neutralClass';
        } else if (status == AssignmentStatus.DMC_CONFIRM) {
            classConfigName = 'confirmClass';
        } else if (status == AssignmentStatus.BOOKING_UPDATE) {
            classConfigName = 'bookingUpdateClass';
        } else if (status == AssignmentStatus.REQUEST_EXPIRED) {
            classConfigName = 'expiredClass';
        } else if (status == AssignmentStatus.INACTIVE_NOTIFY) {
            classConfigName = 'inactiveNotifyClass';
        } else if (status == AssignmentStatus.INACTIVE) {
            classConfigName = 'inactiveClass';
        } else if (status == AssignmentStatus.NO_SHOW) {
            classConfigName = 'noShowClass';
        } else if (status == AssignmentStatus.SUPPLIER_CONFIRMED) {
            classConfigName = 'supplierConfirmClass';
        } else if (status == AssignmentStatus.SUPPLIER_REJECTED) {
            classConfigName = 'rejectedClass';
        } else {
            classConfigName = 'neutralClass';
        }
        return classConfigName;
    }

    /**
     *
     * @param reservation
     * @param arr
     * @param id
     */
    getKeyValue(reservation: any, arr: any[], id: string): KeyValue {
        let returnVal = new KeyValue();
        returnVal.key = id;
        if (arr) {
            let value = this.jsonUtil.getRawData(reservation, arr);
            returnVal.value = value;
        }
        return returnVal;
    }

    private processKeyArray(reservation: any, arr: any[], id: string): any[] {
        let dataArrayInKeyOrder = [];
        let keyArray = arr[0];
        if (Array.isArray(keyArray)) {
            keyArray.forEach(
                key => {
                    if (reservation[key] !== undefined) {
                        dataArrayInKeyOrder.push(reservation[key]);
                    }
                }
            );
        } else if (reservation[keyArray] !== undefined) {
            dataArrayInKeyOrder.push(reservation[keyArray]);
        }
        return dataArrayInKeyOrder;
    }

    /**
     *
     * @param reservation
     * @param arr
     * @param id
     */
    processKeyArray_countAndPassengerName(reservation: any, arr: any[], id: string): KeyValue {
        let returnVal = new KeyValue();
        returnVal.key = id;
        let dataArray = this.processKeyArray(reservation, arr, id);
        if (dataArray && dataArray.length > 1) {
            let count = dataArray[0];
            returnVal.value = count;
            let name = dataArray[1];
            if (name) {
                returnVal.value = returnVal.value + ' (' + name + ')';
            }
        } else if (dataArray && dataArray.length) {
            returnVal.value = dataArray[0];
        }
        return returnVal;
    }

    /**
     *
     * @param reservation
     * @param arr
     * @param id
     */
    processKeyArray_priceAndCurrency(reservation: any, arr: any[], id: string): KeyValue {
        let returnVal = new KeyValue();
        returnVal.key = id;
        let dataArray = this.processKeyArray(reservation, arr, id);

        if (dataArray && dataArray.length) {
            returnVal.value = parseFloat(dataArray[0]);
        }
        return returnVal;
    }

    private getPriceAndCurrency(price: any, currency: any, forwardStyle: boolean = true): string {
        if (forwardStyle) {
            if (price || price === 0) {
                return currency + ' ' + price.toFixed(2);
            } else {
                return null;
            }
        } else {
            if (price || price === 0) {
                return price.toFixed(2) + ' ' + currency;
            } else {
                return null;
            }
        }
    }

    /**
     *
     * @param genericResource
     * @param arr
     * @param id
     */
    processMinPax(genericResource: any, arr: any[], id: string): KeyValue {
        let returnVal = new KeyValue();
        returnVal.key = id;
        let dataArray = this.processKeyArray(genericResource, arr, id);
        if (dataArray) {
            returnVal.value = 'min pax: ' + dataArray[0];
        } else if (dataArray && dataArray.length) {
            returnVal.value = 'min pax: -';
        }
        return returnVal;
    }

    /**
     *
     * @param genericResource
     * @param arr
     * @param id
     */
    processMaxPax(genericResource: any, arr: any[], id: string): KeyValue {
        let returnVal = new KeyValue();
        returnVal.key = id;
        let dataArray = this.processKeyArray(genericResource, arr, id);
        if (dataArray) {
            returnVal.value = 'max pax: ' + dataArray[0];
        } else if (dataArray && dataArray.length) {
            returnVal.value = 'max pax: -';
        }
        return returnVal;
    }

    private getPriceAndDefaultCurrency(price: any, forwardStyle: boolean = true): string {
        return this.getPriceAndCurrency(price, this.defaultCurrencyMark, forwardStyle);
    }

    /**
     *
     * @param reservation
     * @param arr
     * @param id
     */
    arrayToString(reservation: any, arr: any[], id: string): KeyValue {
        let returnVal = new KeyValue();
        returnVal.key = id;
        returnVal.value = '';
        let values = this.jsonUtil.getDataArray(reservation, arr);
        if (Array.isArray(values)) {
            returnVal.value = this.getStrOfArray(values);
        } else {
            returnVal.value = values;
        }
        return returnVal;
    }

    arrayToString_pickups(reservation: any, arr: any[], id: string): KeyValue {
        let keyValue = this.arrayToString(reservation, arr, id);
        if (keyValue.value) {
            return this.addPrefixPostfix(keyValue, '', '');
        } else {
            return keyValue;
        }
    }

    arrayToString_dropOffs(reservation: any, arr: any[], id: string): KeyValue {
        let keyValue = this.arrayToString(reservation, arr, id);
        if (keyValue.value) {
            return this.addPrefixPostfix(keyValue, '', '');
        } else {
            return keyValue;
        }
    }

    /**
     *
     * @param reservation
     * @param arr
     * @param id
     */
    processKeyArray_createAddress(reservation: any, arr: any[], id: string): KeyValue {
        let returnVal = new KeyValue();
        returnVal.key = id;
        let dataArray = this.processKeyArray(reservation, arr, id);
        returnVal.value = '';
        if (dataArray) {
            returnVal.value = dataArray[0];
            dataArray.slice(1, dataArray.length).forEach(
                val => {
                    returnVal.value = val + ', ' + returnVal.value;
                }
            );
        }
        return returnVal;
    }

    processSupplierName(reservation: any, arr: any[], id: string): KeyValue {
        let returnVal = new KeyValue();
        returnVal.key = id;
        let dataArray = this.processKeyArray(reservation, arr, id);
        returnVal.value = dataArray[0].name;
        return returnVal;
    }

    processSupplier_cost(resource: any, arr: any[], id: string): KeyValue {
        return this.processKeyArray_priceAndCurrency(resource.supplier, arr, id);
    }

    processKeyArray_processSupplierPerPrice(resource: any, arr: any[], id: string): KeyValue {
        return this.processKeyArray_processPerPrice(resource.supplier, arr, id);
    }

    processRemainingCapacity(resource: any, arr: any[], id: string): KeyValue {
        let returnVal = new KeyValue();
        returnVal.key = id;
        let dataArray = this.processKeyArray(resource, arr, id);
        returnVal.value = dataArray[0];
        returnVal = this.addPrefixPostfix(returnVal, '<span class="tc-strip-text__label">Seats Remaining: </span>', '');
        /*if(resource.exceedCapacity){
            returnVal.value = '<span class="tc-strip-exceed-capacity">'+ returnVal.value + '</span>'
        }*/
        return returnVal;
    }

    processAllocatedCapacity(resource: any, arr: any[], id: string): KeyValue {
        let returnVal = new KeyValue();
        returnVal.key = id;
        let dataArray = this.processKeyArray(resource, arr, id);
        returnVal.value = dataArray[0];
        returnVal = this.addPrefixPostfix(returnVal, '<span class="tc-strip-text__label">Seats Allocated: </span>', '');
        return returnVal;
    }

    /**
     *
     * @param supplier
     * @param arr
     * @param id
     */
    processKeyArray_processPerPrice(data: any, arr: any[], id: string): KeyValue {
        let returnVal = new KeyValue();
        returnVal.key = id;
        let dataArray = this.processKeyArray(data, arr, id);
        if (dataArray && dataArray.length > 0) {
            if (data.unitFare || data.unitCost) {
                returnVal.value = dataArray[0].toFixed(2);
                returnVal = this.addPrefixPostfix(returnVal, '<span class="tc-strip-text__label">Unit: </span>', '');
            } else {
                let adult = dataArray[1] ? dataArray[1].toFixed(2) : '0';
                let child = dataArray[2] ? dataArray[2].toFixed(2) : '0';
                let infant = dataArray[3] ? dataArray[3].toFixed(2) : '0';
                returnVal.value = "<span class='tc-strip-text__label'>Adt:</span> " + adult + " | <span class='tc-strip-text__label'>Cld: </span>" + child + " | <span class='tc-strip-text__label'>Inf: </span>" + infant;
            }
        }
        return returnVal;
    }

    processVehicleCost(resource: any, arr: any[], id: string): KeyValue {
        let data = resource.supplier;
        let returnVal = new KeyValue();
        returnVal.key = id;
        let dataArray = this.processKeyArray(resource, arr, id);
        if (dataArray && dataArray.length > 0) {
            let currency = resource.supplier && resource.supplier.currency ? resource.supplier.currency : 'AED';
            returnVal.value = this.currencyFormatter(currency, dataArray[0]);
            returnVal = this.addPrefixPostfix(returnVal, '<span class="tc-strip-text__label">Vehicle Cost: </span>', '');
        }
        return returnVal;
    }

    currencyFormatter(currency: any, cost: number) {
        var inrFormat = new Intl.NumberFormat('en-Us', {
            style: 'currency',
            currency: currency,
            minimumFractionDigits: 2
        });
        return inrFormat.format(cost);
    }

    setEmptyColumn(reservation: any, arr: any[], id: string): KeyValue {
        let returnVal = new KeyValue();
        returnVal.key = id;
        return returnVal;
    }

    private getStrOfArray(data: any[]) {
        let str = '';
        let length = data.length;
        for (let i = 0; i < length; i++) {
            str += data[i];
            if (i < length - 1) {
                str += ', ';
            }
        }
        return str;
    }

    processPickups(reservation: any, arr: any[], id: string): KeyValue {
        let keyValue = this.getKeyValue(reservation, arr, id);
        return this.limitValueCharacters(this.addPrefixPostfix(keyValue, '<span class="tc-strip-text__label">Pickups: </span>', ''), 90);
    }

    processDropOffs(reservation: any, arr: any[], id: string): KeyValue {
        let keyValue = this.getKeyValue(reservation, arr, id);
        return this.limitValueCharacters(this.addPrefixPostfix(keyValue, '<span class="tc-strip-text__label">DropOffs: </span>', ''), 90);
    }

    private limitValueCharacters(keyValue: KeyValue, size: number) {
        if (typeof (keyValue.value) === 'string') {
            if (keyValue.value.length > size) {
                keyValue.value = keyValue.value.substr(0, size).concat('...');
            }
        }
        return keyValue;
    }

    processAllocationsLeftCount(reservation: any, arr: any[], id: string): KeyValue {
        let keyValue = this.getKeyValue(reservation, arr, id);
        return this.addPrefixPostfix(keyValue, '', ' Remaining');
    }

    processAllocatedCount(reservation: any, arr: any[], id: string): KeyValue {
        let keyValue = this.getKeyValue(reservation, arr, id);
        return this.addPrefixPostfix(keyValue, '', ' Allocated');
    }

    processEstimatedPrice(reservation: any, arr: any[], id: string): KeyValue {
        let keyValue = this.processKeyArray_priceAndCurrency(reservation, arr, id);
        keyValue = this.addPrefixPostfix(keyValue, '', '');
        keyValue.value = ' ' + this.defaultCurrencyMark;
        return keyValue;
        // if (keyValue.value) {
        //     return keyValue;
        // } else {
        //     keyValue.value = ' '+this.defaultCurrencyMark+' 0';
        //     return keyValue;
        // }

    }

    processKeyArray_priceAndCurrencyServiceItem(reservation: any, arr: any[], id: string): KeyValue {
        let keyValue = this.processKeyArray_priceAndCurrency(reservation, arr, id);
        keyValue.value = this.defaultCurrencyMark;
        return keyValue;

        // if (keyValue.value !== null) {
        //     return keyValue;
        // } else {
        //     keyValue.value = this.defaultCurrencyMark+' 0';
        //     return keyValue;
        // }
    }

    private addPrefixPostfix(keyValue: KeyValue, prefix: string, postfix: string): KeyValue {
        if (keyValue.value !== null) {
            keyValue.value = prefix + keyValue.value + postfix;
        }
        return keyValue;
    }

    /**
     *
     * @param reservation
     * @param arr
     * @param id
     */
    processKeyArray_supplierResourceInputBoxes(reservation: any, arr: any[], id: string): KeyValue {
        let returnVal = new KeyValue();
        returnVal.key = id;
        let dataArray = this.processKeyArray(reservation, arr, id);
        if (dataArray && dataArray.length > 1) {
            returnVal.value = {'supplier': dataArray[0], 'resource': dataArray[1]};
        }
        return returnVal;
    }

    // /**
    //  *
    //  * @param reservation
    //  * @param arr
    //  * @param id
    //  */
    // formatDate_ddMMMyy_withStyle(reservation: any, arr: any[], id: string): KeyValue {
    //     let keyValue = this.getKeyValue(reservation, arr, id);
    //     let formattedDate = this.dataFormatterUtilService.formatDate(<string>keyValue.value, TC.FORMAT.DATE_FORMAT_dd_MMM_yy);
    //     keyValue.value=formattedDate;
    //     return keyValue;
    // }

    /**
     *
     * @param client
     * @param arr
     * @returns {Icon}
     */
    processCommonIconKeepActive(obj: any, arr: any[], id: string): KeyValue {
        const returnVal = new KeyValue();
        const iconView = new Icon();
        const iconObj = arr[0];

        if (iconObj) {
            iconView.key = iconObj['key'];
            iconView.iconType = iconObj['iconType'];
            iconView.style = iconObj['style'] + ' ' + iconObj['activeClass'];
            iconView.value = iconObj['value'];
        }
        returnVal.key = id;
        returnVal.value = iconView;
        return returnVal;
    }

    /**
     *
     * @param client
     * @param arr
     * @returns {Icon}
     */
    processGroupMasterIcon(client: Client, arr: any[], id: string): KeyValue {
        let returnVal = new KeyValue();
        let iconView = new Icon();
        let clientType = client.type;
        let iconObj = arr[0];

        if (iconObj) {
            iconView.key = iconObj['key'];
            iconView.iconType = iconObj['iconType'];

            if (client.detail.tradeProfile.groupProfile.active) {
                iconView.style = iconObj['style'] + ' ' + iconObj['activeClass'];
            } else {
                iconView.style = iconObj['style'] + ' ' + iconObj['inactiveClass'];
            }

            iconView.value = iconObj['value'];
        }
        returnVal.key = id;
        returnVal.value = iconView;
        return returnVal;
    }

    /**
     *
     * @param client
     * @param arr
     * @returns {Icon}
     */
    processGroupMasterIconFromSummary(client: Client, arr: any[], id: string): KeyValue {
        let returnVal = new KeyValue();
        let iconView = new Icon();
        let clientType = client.type;
        let iconObj = arr[0];

        if (iconObj) {
            iconView.key = iconObj['key'];
            iconView.iconType = iconObj['iconType'];

            if (client.summary.active) {
                iconView.style = iconObj['style'] + ' ' + iconObj['activeClass'];
            } else {
                iconView.style = iconObj['style'] + ' ' + iconObj['inactiveClass'];
            }

            iconView.value = iconObj['value'];
        }
        returnVal.key = id;
        returnVal.value = iconView;
        return returnVal;
    }

    public appendWithCommaAndSpace(obj: any, arr: any[], id: string): KeyValue {
        let returnVal = new KeyValue();
        let dataArray: Array<string> = [];

        arr.forEach((value, i) => {
            let str = this.jsonUtil.findJsonProperty(obj, value, '');
            if (str !== '') {
                let data = this.jsonUtil.findJsonProperty(obj, value, '');
                if (data && data.length > 0) {
                    dataArray.push(data);
                }
            }
        });

        returnVal.key = id;
        returnVal.value = dataArray.join(', ');

        return returnVal;
    }

    public processClientAddress(obj: any, arr: any[], id: string): KeyValue {
        let returnVal = new KeyValue();
        let dataArray: Array<string> = [];

        arr.forEach((value, i) => {
            let str = this.jsonUtil.findJsonProperty(obj, value, '');
            if (str !== '') {
                let data = this.jsonUtil.findJsonProperty(obj, value, '');
                if (data && data.length > 0) {
                    data = this.dataFormatterUtilService.capitalizeEachWord(data, true);
                    dataArray.push(data);
                }
            }
        });

        returnVal.key = id;
        returnVal.value = dataArray.join(', ');

        return returnVal;
    }

    /**
     *
     * @param bookingSummary
     * @param arr
     * @returns {Icon}
     */
    formatDate_ddMMMyy(obj: any, arr: any[], id: string): KeyValue {

        let returnVal = new KeyValue();

        let dateArray = [];
        arr.forEach((value, i) => {

            let data = this.jsonUtil.findJsonProperty(obj, value, '');
            data = this.dataFormatterUtilService.formatDate(data, TC.FORMAT.DATE_FORMAT_dd_MMM_yy);
            dateArray.push(data);
        });

        returnVal.key = id;

        if (dateArray) {
            let processedStr = dateArray.join(' - ');
            returnVal.value = processedStr;
        }
        return returnVal;
    }

    /**
     *
     * @param get  client booking status
     * @param arr
     * @returns {Icon}
     */
    processClientBookingStatus(obj: any, arr: any[], id: string): KeyValue {

        let returnVal = new KeyValue();

        let statusStr = '';
        arr.forEach((value, i) => {

            let data = this.jsonUtil.findJsonProperty(obj, value, '');
            statusStr = statusStr + '' + data;
        });

        let clientByIdObsv: Subscription = new Subscription();
        clientByIdObsv = this.dataStore.get(DataKey.clientBookingStatus).subscribe((status) => {
            if (this.commonHelper.dataValidity(status)) {
                status.forEach((bookingType) => {

                    if (statusStr === bookingType.code) {
                        returnVal.value = bookingType.value;
                        return true;
                    }

                });
            } else if (status instanceof TcApiError) {

            } else if (status instanceof TcHttpError) {

            }
        });

        if (clientByIdObsv) {
            clientByIdObsv.unsubscribe();
        }

        returnVal.key = id;
        return returnVal;
    }

    /**
     *
     * @param get  client booking status
     * @param arr
     * @returns {Icon}
     */
    processWholeSalerCountry(obj: any, arr: any[], id: string): KeyValue {

        let returnVal = new KeyValue();

        let statusStr = '';
        arr.forEach((value, i) => {

            let data = this.jsonUtil.findJsonProperty(obj, value, '');
            statusStr = statusStr + '' + data;
        });

        let clientByIdObsv: Subscription = new Subscription();
        clientByIdObsv = this.dataStore.get(DataKey.countries).subscribe((countries) => {
            if (this.commonHelper.dataValidity(countries)) {
                countries.forEach((country) => {

                    if (statusStr === country.code) {
                        returnVal.value = country.name;
                        return true;
                    }

                });
            } else if (countries instanceof TcApiError) {

            } else if (countries instanceof TcHttpError) {

            }
        });

        if (clientByIdObsv) {
            clientByIdObsv.unsubscribe();
        }

        returnVal.key = id;
        return returnVal;
    }

    /**
     * get client booking status
     * @param obj
     * @param arr
     * @param id
     * @returns {Icon}
     */
    processResortName(obj: any, arr: any[], id: string): KeyValue {

        let returnVal = new KeyValue();

        let resortCode = '';
        arr.forEach((value, i) => {

            let data = this.jsonUtil.findJsonProperty(obj, value, '');
            resortCode = data;
        });

        let resortObsv: Subscription = new Subscription();
        resortObsv = this.dataStore.get(DataKey.hotels).subscribe((resorts) => {
            if (this.commonHelper.dataValidity(resorts)) {
                resorts.forEach((resort) => {

                    if (resortCode === resort.code) {
                        returnVal.value = resort.name;
                        return true;
                    }
                });
            } else if (resorts instanceof TcApiError) {

            } else if (resorts instanceof TcHttpError) {

            }
        });

        if (resortObsv) {
            resortObsv.unsubscribe();
        }

        returnVal.key = id;
        return returnVal;
    }

    /**
     * get whoesaler group type
     * @param obj
     * @param arr
     * @param id
     * @returns {Icon}
     */
    processWholeSalerGroupType(obj: any, arr: any[], id: string): KeyValue {

        let returnVal = new KeyValue();

        let statusStr = '';
        arr.forEach((value, i) => {

            let data = this.jsonUtil.findJsonProperty(obj, value, '');
            statusStr = statusStr + '' + data;
        });

        let clientByIdObsv: Subscription = new Subscription();
        clientByIdObsv = this.dataStore.get(DataKey.clientGroupTypes).subscribe((status) => {
            if (status && JSON.stringify(status) !== JSON.stringify({})) {
                status.forEach((bookingType) => {

                    if (statusStr === bookingType.code) {
                        returnVal.value = bookingType.value;
                        return true;
                    }
                });

            }
        });

        if (clientByIdObsv) {
            clientByIdObsv.unsubscribe();
        }

        returnVal.key = id;
        return returnVal;
    }

    /**
     * process client group type
     * @param obj
     * @param {any[]} arr
     * @param {string} id
     * @returns {KeyValue}
     */
    processClientGroup(obj: any, arr: any[], id: string): KeyValue {

        let returnVal = new KeyValue();
        let processedStr = 'Client group - ';
        let data = this.jsonUtil.findJsonProperty(obj, arr[0], '');
        processedStr = processedStr + data;
        returnVal.key = id;
        returnVal.value = processedStr;
        return returnVal;
    }

    /**
     * Additional Contact Summary
     * @param obj
     * @param arr
     * @param id
     * @param dataKey
     */
    getAdditionalContactSummary(obj: any, arr: any[], id: string, dataKey?: any): KeyValue {

        let returnVal = new KeyValue();
        let additionalContactCount = 0;

        let addresses = this.jsonUtil.findJsonProperty(obj, arr[0], '');

        addresses.forEach((item: any) => {
            additionalContactCount++;
        });

        let data: string = '';

        if (additionalContactCount === 1) {
            data = additionalContactCount + ' Additional Contact';
        } else {
            data = additionalContactCount + ' Additional Contacts';
        }

        returnVal.key = id;
        returnVal.value = data;
        return returnVal;

    }

    /**
     * Rooms
     * @param obj
     * @param arr
     * @param id
     * @param dataKey
     */
    getRoomGroupSummary(obj: any, arr: any[], id: string, dataKey?: any): KeyValue {

        let returnVal = new KeyValue();
        let roomCount = 0;

        let roomGroups = this.jsonUtil.findJsonProperty(obj, arr[0], '');

        roomGroups.forEach((item: any) => {
            roomCount++;
        });

        let data: string = '';

        if (roomCount === 1) {
            data = roomCount + ' Room Group';
        } else {
            data = roomCount + ' Room Groups';
        }

        returnVal.key = id;
        returnVal.value = data;
        return returnVal;

    }

    getRoomGroupType(obj: any, arr: any[], id: string, dataKey?: any) {
        const returnVal = new KeyValue();
        const roomGroup = this.jsonUtil.findJsonProperty(obj, arr[0], '');
        returnVal.key = id;
        returnVal.value = roomGroup.roomTypeName;
        return returnVal;
    }

    getRoomGroupRoomCount(obj: any, arr: any[], id: string, dataKey?: any) {
        let returnVal = new KeyValue();
        let roomCount = 0;

        let roomGroup = this.jsonUtil.findJsonProperty(obj, arr[0], '');

        roomCount = roomGroup.noOfUnits;

        let data = '';

        if (roomCount === 1) {
            data = roomCount + ' Room';
        } else {
            data = roomCount + ' Rooms';
        }

        returnVal.key = id;
        returnVal.value = data;
        return returnVal;
    }

    /**
     * Tax Scheme
     * @param obj
     * @param arr
     * @param id
     * @param dataKey
     */
    getTaxSchemeSummary(obj: any, arr: any[], id: string, dataKey?: any): KeyValue {
        console.log('hit tax');
        let returnVal = new KeyValue();
        let taxSchemeCount = 0;

        let addresses = this.jsonUtil.findJsonProperty(obj, arr[0], '');

        addresses.forEach((item: any) => {
            taxSchemeCount++;
        });

        let data: string = '';

        if (taxSchemeCount === 1) {
            data = taxSchemeCount + ' Tax Scheme';
        } else {
            data = taxSchemeCount + ' Tax Schemes';
        }

        returnVal.key = id;
        returnVal.value = data;
        return returnVal;

    }

    /**
     * document summary
     * @param obj
     * @param arr
     * @param id
     * @param dataKey
     */
    getDocumentSummary(obj: any, arr: any[], id: string, dataKey?: any): KeyValue {

        let returnVal = new KeyValue();
        let documentCount = 0;

        let addresses = this.jsonUtil.findJsonProperty(obj, arr[0], '');

        addresses.forEach((item: any) => {
            documentCount++;
        });

        let data: string = '';

        if (documentCount === 1) {
            data = documentCount + ' Document';
        } else {
            data = documentCount + ' Documents';
        }

        returnVal.key = id;
        returnVal.value = data;
        return returnVal;

    }

    processIcon(obj: any, arr: any[], id: string): KeyValue {

        let returnVal = new KeyValue();
        let iconView = new Icon();
        let address = this.jsonUtil.findJsonProperty(obj, arr[0], '');
        let iconObj = arr[0];

        if (iconObj && address) {
            iconView.key = iconObj['key'];
            iconView.iconType = iconObj['iconType'];
            let validStyle = (address.firstName && address.category) ?
                iconObj['activeClass'] :
                iconObj['inactiveClass'];
            iconView.style = iconObj['style'].concat(' ', validStyle);
            iconView.value = iconObj['value'];
            iconView.activeClass = iconObj['activeClass'];
            iconView.inactiveClass = iconObj['inactiveClass'];

            returnVal.key = id;
            returnVal.value = iconView;
            return returnVal;
        }
    }


    processBookingIcon(obj: any, arr: any[], id: string): KeyValue {

        let returnVal = new KeyValue();
        let iconView = new Icon();
        let address = this.jsonUtil.findJsonProperty(obj, arr[0], '');
        let iconObj = arr[0];

        if (iconObj && address) {
            iconView.key = iconObj['key'];
            iconView.iconType = iconObj['iconType'];
            const validStyle = iconObj['activeClass'];
            iconView.style = iconObj['style'].concat(' ', validStyle);
            iconView.value = iconObj['value'];
            iconView.activeClass = iconObj['activeClass'];
            iconView.inactiveClass = iconObj['inactiveClass'];

            returnVal.key = id;
            returnVal.value = iconView;
            return returnVal;
        }
    }

    /**
     * process client vip level
     * @param obj
     * @param arr
     * @param id
     * @param dataKey
     */
    processViplevel(obj: any, arr: any[], id: string, dataKey?: any): KeyValue {

        let returnVal = new KeyValue();
        let data = null;

        let vipFlag = this.jsonUtil.findJsonProperty(obj, arr[0], '');
        if (vipFlag && 'boolean' === typeof (vipFlag) && vipFlag === true) {
            data = 'vip';
        }

        returnVal.key = id;
        returnVal.value = data;
        return returnVal;
    }

    processSummaryIcon(product: any, arr: any[], id: string, dataKey?: any): KeyValue {

        let iconView = new Icon();
        let returnVal = new KeyValue();
        let iconObj = arr[0];
        if (iconObj) {
            iconView.key = iconObj['key'];
            iconView.iconType = iconObj['iconType'];
            iconView.style = iconObj['style'];
            iconView.value = iconObj['value'];
        }

        returnVal.key = id;
        returnVal.value = iconView;
        return returnVal;
    }

    getDataObject(product: any, arr: any[], id: string): KeyValue {
        let returnVal = new KeyValue();
        returnVal.key = id;
        returnVal.value = product;
        return returnVal;
    }

    getNoteSummary(obj: any, arr: any[], id: string, dataKey?: any): KeyValue {

        let returnVal = new KeyValue();
        let noteCount = 0;

        let notes = this.jsonUtil.findJsonProperty(obj, arr[0], '');

        for (const item of notes) {
            noteCount++;
        }

        let data: string = '';

        if (noteCount === 1) {
            data = noteCount + ' Note';
        } else {
            data = noteCount + ' Notes';
        }

        returnVal.key = id;
        returnVal.value = data;
        return returnVal;

    }

    processNoteIcon(payload: any, arr: any[], id: string): KeyValue {

        let returnVal = new KeyValue();
        let iconView = new Icon();
        let note: ServiceNote = <ServiceNote>payload;

        let iconObj = null;

        if (note !== null && note.type && arr.length > 1) {
            iconObj = arr[1];
        } else if (note !== null && arr.length > 0) {
            iconObj = arr[0];
        }

        if (iconObj) {
            iconView.key = iconObj['key'];
            iconView.iconType = iconObj['iconType'];
            iconView.style = iconObj['style'];
            let noteType = this.jsonUtil.findJsonProperty(payload, iconObj['value'].split('.'), null);

            if (noteType) {
                switch (noteType) {
                    case NoteType.TYPE.BK:
                        iconView.value = NoteType.TYPE[NoteType.TYPE.BK];
                        break;
                    case NoteType.TYPE.SU:
                        iconView.value = NoteType.TYPE[NoteType.TYPE.SU];
                        break;
                    case NoteType.TYPE.SV:
                        iconView.value = NoteType.TYPE[NoteType.TYPE.SV];
                        break;
                    case NoteType.TYPE.SP:
                        iconView.value = NoteType.TYPE[NoteType.TYPE.SP];
                        break;
                    case NoteType.TYPE.IN:
                        iconView.value = NoteType.TYPE[NoteType.TYPE.IN];
                        break;
                    case NoteType.TYPE.IT:
                        iconView.value = NoteType.TYPE[NoteType.TYPE.IT];
                        break;
                    case NoteType.TYPE.VO:
                        iconView.value = NoteType.TYPE[NoteType.TYPE.VO];
                        break;
                    case NoteType.TYPE.TI:
                        iconView.value = NoteType.TYPE[NoteType.TYPE.TI];
                        break;
                    case NoteType.TYPE.DC:
                        iconView.value = NoteType.TYPE[NoteType.TYPE.DC];
                        break;
                }

            }
            returnVal.key = id;
            returnVal.value = iconView;
            return returnVal;
        }
    }

    /**
     * process note modified date
     * @param payload
     * @param {any[]} arr
     * @param {string} id
     * @returns {KeyValue}
     */
    processNoteDate(payload: any, arr: any[], id: string): KeyValue {

        let returnVal = new KeyValue();
        let data = this.jsonUtil.findJsonProperty(payload, arr, null);
        let modifiedDate = data ? data.split('T')[0] : '';
        if (modifiedDate) {
            returnVal.value = this.dataFormatterUtilService.formatDate(modifiedDate, TC.FORMAT.DATE_FORMAT_dd_MMM_yy);
        }
        returnVal.key = id;

        return returnVal;
    }

    processNote(data: any, arr: any[], id: string): KeyValue {
        let keyValue = this.getKeyValue(data, arr, id);
        return this.limitValueCharacters(keyValue, 25);
    }

    processDaysOfWeek(data: any, arr: any[], id: string): KeyValue {
        let keyValue = this.getKeyValue(data, arr, id);
        return this.limitValueCharacters(keyValue, 40);
    }

    processCost(data: any, arr: any[], id: string): KeyValue {
        let keyValue = this.getKeyValue(data, arr, id);
        return this.limitValueCharacters(keyValue, 90);
    }

    processDriverName(data: any, arr: any[], id: string): KeyValue {
        let returnVal = new KeyValue();
        returnVal.key = id;
        let dataArray = this.processKeyArray(data, arr, id);
        returnVal.value = dataArray[0] + ' ' + dataArray[1];
        return returnVal;
    }

    processKeyArray_SupplierCost(data: any, arr: any[], id: string): KeyValue {
        let returnVal = new KeyValue();
        returnVal.key = id;
        if (arr) {
            let value = this.jsonUtil.getRawData(data, arr);
            if (value || parseInt(value) == 0) {
                returnVal.value = value;
            } else {
                returnVal.value = null;
            }

        }
        return returnVal;
    }

    processYearsOfExperience(data: any, arr: any[], id: string): KeyValue {
        let keyValue = this.getKeyValue(data, arr, id);

        let prefix = '<span class="tc-strip-text__label">Experience: </span>';
        let postfix = ' years';

        this.addPrefixPostfix(keyValue, prefix, postfix);
        return keyValue;
    }

    processLanguages(data: any, arr: any[], id: string): KeyValue {
        let values = this.jsonUtil.getDataArray(data, arr);
        let keyValue = this.getKeyValue(data, arr, id);
        if (Array.isArray(values)) {
            keyValue.value = this.getStrOfArray(values);
        } else {
            keyValue.value = values;
        }
        let maxLength = 100;
        let prefix = '<span class="tc-strip-text__label">Languages: </span>';
        let postfix = '';
        if (keyValue.value) {
            this.addPrefixPostfix(keyValue, prefix, postfix);
        }
        this.limitValueCharacters(keyValue, 90);
        return keyValue;
    }

    processPreferredTransferModes(data: any, arr: any[], id: string): KeyValue {
        let values = this.jsonUtil.getDataArray(data, arr);
        let keyValue = this.getKeyValue(data, arr, id);
        if (Array.isArray(values)) {
            keyValue.value = this.getStrOfArray(values);
        } else {
            keyValue.value = values;
        }
        let maxLength = 100;
        let prefix = '<span class="tc-strip-text__label">Transfer Modes: </span>';
        let postfix = '';
        if (keyValue.value) {
            this.addPrefixPostfix(keyValue, prefix, postfix);
        }
        this.limitValueCharacters(keyValue, 90);
        return keyValue;
    }

    processPreferredRoute(data: any, arr: any[], id: string): KeyValue {
        let keyValue = this.getKeyValue(data, arr, id);

        let prefix = '<span class="tc-strip-text__label">Route: </span>';
        let postfix = '';

        this.addPrefixPostfix(keyValue, prefix, postfix);
        return keyValue;
    }

    processTransferMode(data: any, arr: any[], id: string): KeyValue {
        let keyValue = this.getKeyValue(data, arr, id);

        let prefix = '<span class="tc-strip-text__label">Transfer Mode: </span>';
        let postfix = '';

        this.addPrefixPostfix(keyValue, prefix, postfix);
        return keyValue;
    }

    processWorkingHours(data: any, arr: any[], id: string): KeyValue {
        let keyValue = this.getKeyValue(data, arr, id);

        let prefix = '<span class="tc-strip-text__label">Working Hours: </span>';
        let postfix = '';

        this.addPrefixPostfix(keyValue, prefix, postfix);
        return keyValue;
    }

    processGrade(data: any, arr: any[], id: string): KeyValue {
        let keyValue = this.getKeyValue(data, arr, id);

        let prefix = '<span class="tc-strip-text__label">Grade: </span>';
        let postfix = '';

        this.addPrefixPostfix(keyValue, prefix, postfix);
        return keyValue;
    }

    processDriver(data: any, arr: any[], id: string): KeyValue {
        let keyValue = this.getKeyValue(data, arr, id);

        let prefix = '<span class="tc-strip-text__label">Driver: </span>';
        let postfix = '';

        this.addPrefixPostfix(keyValue, prefix, postfix);
        return keyValue;
    }

    processLicensePlate(data: any, arr: any[], id: string): KeyValue {
        let keyValue = this.getKeyValue(data, arr, id);

        let prefix = '<span class="tc-strip-text__label">License Plate: </span>';
        let postfix = '';

        this.addPrefixPostfix(keyValue, prefix, postfix);
        return keyValue;
    }

    processVehicleModel(data: any, arr: any[], id: string): KeyValue {
        let keyValue = this.getKeyValue(data, arr, id);

        let prefix = '<span class="tc-strip-text__label">Model: </span>';
        let postfix = '';

        this.addPrefixPostfix(keyValue, prefix, postfix);
        return keyValue;
    }

    processCapacity(data: any, arr: any[], id: string): KeyValue {
        let keyValue = this.getKeyValue(data, arr, id);

        let prefix = '<span class="tc-strip-text__label">Capacity: </span>';
        let postfix = '';

        this.addPrefixPostfix(keyValue, prefix, postfix);
        return keyValue;
    }

    processPhoneNumber(data: any, arr: any[], id: string): KeyValue {
        let keyValue = this.getKeyValue(data, arr, id);

        let prefix = '<span class="tc-strip-text__label">Phone: </span>';
        let postfix = '';

        this.addPrefixPostfix(keyValue, prefix, postfix);
        return keyValue;
    }

    processCity(data: any, arr: any[], id: string): KeyValue {
        let keyValue = this.getKeyValue(data, arr, id);

        let prefix = '<span class="tc-strip-text__label">City: </span>';
        let postfix = '';

        this.addPrefixPostfix(keyValue, prefix, postfix);
        return keyValue;
    }

    processDescription(data: any, arr: any[], id: string): KeyValue {
        let prefix = '';
        let processData = [];
        if (data[arr['param1']['value']] !== null) {
            prefix = arr['param1']['key'] + ": ";
            processData.push(arr['param1']['value']);
            arr = [];
            arr.push(processData);
        } else {
            prefix = arr['param2']['key'] + ": ";
            processData.push(arr['param2']['value']);
            arr = [];
            arr.push(processData);
        }
        let keyValue = this.getKeyValue(data, arr, id);


        let postfix = '';

        this.addPrefixPostfix(keyValue, prefix, postfix);
        return keyValue;
    }

    // reservation v2

    processKeyArray_cost(resource: any, arr: any[], id: string): KeyValue {
        let returnVal = new KeyValue();
        returnVal.key = id;
        let dataArray = this.processKeyArray(resource.calculatedCost, arr, id);

        if (dataArray && dataArray.length) {
            returnVal.value = parseFloat(dataArray[0]);
        }
        return returnVal;
    }

    processKeyArray_processSupplierPerCost(resource: any, arr: any[], id: string): KeyValue {
        return this.processKeyArray_processPerPrice(resource.calculatedCost, arr, id);
    }

}
