import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChipInputModule } from '@tc/chip-input';
import { LocationsGridSetupSearchCriteriaComponent } from './locations-grid-setup-search-criteria.component';

@NgModule({
  declarations: [LocationsGridSetupSearchCriteriaComponent],
  exports: [
    LocationsGridSetupSearchCriteriaComponent
  ],
  imports: [
    CommonModule,
    ChipInputModule
  ]
})
export class LocationsGridSetupSearchCriteriaModule { }
