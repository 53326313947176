/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../../../../node_modules/@tc/content-strip/content-strip.component.ngfactory";
import * as i3 from "@tc/content-strip/content-strip.component";
import * as i4 from "../../../business/resource-allocation-card/resource-allocation-card.component.ngfactory";
import * as i5 from "../../../business/resource-allocation-card/resource-allocation-card.component";
import * as i6 from "@tc-core/util/framework/config-loader.service";
import * as i7 from "@tc/pipes/advanced-sorter.pipe";
import * as i8 from "@tc/pipes/advanced-pager.pipe";
import * as i9 from "./adv-driver-assign-search-result.component";
import * as i10 from "../../../services/util/change-detector/change-detector.service";
var styles_AdvDriverAssignSearchResultComponent = [];
var RenderType_AdvDriverAssignSearchResultComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AdvDriverAssignSearchResultComponent, data: {} });
export { RenderType_AdvDriverAssignSearchResultComponent as RenderType_AdvDriverAssignSearchResultComponent };
function View_AdvDriverAssignSearchResultComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 6, "div", [["class", ""]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 5, "div", [], null, null, null, null, null)), i0.ɵdid(3, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { ngClass: [0, "ngClass"] }, null), (_l()(), i0.ɵeld(4, 0, null, null, 3, "tc-content-strip", [], null, [[null, "actionClick"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("actionClick" === en)) {
        var pd_0 = (_co.onActionClick(_v.context.$implicit, $event) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.onDriverSelection(_v.context.$implicit, _v.context.index) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_ContentStripComponent_0, i2.RenderType_ContentStripComponent)), i0.ɵdid(5, 114688, null, 0, i3.ContentStripComponent, [], null, { actionClick: "actionClick" }), (_l()(), i0.ɵeld(6, 0, null, 0, 1, "tc-resource-allocation-card", [], null, [[null, "resourceCalendarClick"], [null, "eventClick"], [null, "targetClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("resourceCalendarClick" === en)) {
        var pd_0 = (_co.onResourceCalendarClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("eventClick" === en)) {
        var pd_1 = (_co.onEventClick($event, _v.context.$implicit) !== false);
        ad = (pd_1 && ad);
    } if (("targetClick" === en)) {
        var pd_2 = (_co.onTargetClick($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i4.View_ResourceAllocationCardComponent_0, i4.RenderType_ResourceAllocationCardComponent)), i0.ɵdid(7, 376832, null, 0, i5.ResourceAllocationCardComponent, [i6.ConfigLoader, i0.KeyValueDiffers], { summaryContent: [0, "summaryContent"], itemGroup: [1, "itemGroup"], bookingData: [2, "bookingData"], bookingDataArray: [3, "bookingDataArray"], isBulkAssignment: [4, "isBulkAssignment"] }, { resourceCalendarClick: "resourceCalendarClick", eventClick: "eventClick", targetClick: "targetClick" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.hasSelectedThis(_v.context.$implicit) ? "tc-expanded-content-card--selected" : ""); _ck(_v, 3, 0, currVal_0); _ck(_v, 5, 0); var currVal_1 = _v.context.$implicit; var currVal_2 = _co.itemGroup; var currVal_3 = _co.bookingData; var currVal_4 = _co.bookingDataArray; var currVal_5 = _co.isBulkAssignment; _ck(_v, 7, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
function View_AdvDriverAssignSearchResultComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", ""]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 3, null, View_AdvDriverAssignSearchResultComponent_2)), i0.ɵdid(2, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i0.ɵppd(3, 3), i0.ɵppd(4, 3)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 2, 0, _ck(_v, 4, 0, i0.ɵnov(_v.parent, 0), i0.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i0.ɵnov(_v.parent, 1), _co.resultsList, _co.pager.currPage, _co.pager.itemsPerPage)), _co.sortingHandler, _co.sorter)); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_AdvDriverAssignSearchResultComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i7.AdvancedSorterPipe, []), i0.ɵpid(0, i8.AdvancedPagerPipe, []), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AdvDriverAssignSearchResultComponent_1)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.resultsList; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_AdvDriverAssignSearchResultComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-adv-driver-assign-search-result", [], null, null, null, View_AdvDriverAssignSearchResultComponent_0, RenderType_AdvDriverAssignSearchResultComponent)), i0.ɵdid(1, 114688, null, 0, i9.AdvDriverAssignSearchResultComponent, [i10.ChangeDetectorService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AdvDriverAssignSearchResultComponentNgFactory = i0.ɵccf("tc-adv-driver-assign-search-result", i9.AdvDriverAssignSearchResultComponent, View_AdvDriverAssignSearchResultComponent_Host_0, { sorter: "sorter", sortingHandler: "sortingHandler", sorters: "sorters", pager: "pager", resultsList: "resultsList", selectedDriver: "selectedDriver", bookingData: "bookingData", bookingDataArray: "bookingDataArray", isBulkAssignment: "isBulkAssignment", assignedDriver: "assignedDriver", itemGroup: "itemGroup" }, { driverSelect: "driverSelect", cardScroll: "cardScroll", actionClick: "actionClick", outsideSelect: "outsideSelect", headerClick: "headerClick", resourceCalendarClick: "resourceCalendarClick", eventClick: "eventClick", targetClick: "targetClick" }, []);
export { AdvDriverAssignSearchResultComponentNgFactory as AdvDriverAssignSearchResultComponentNgFactory };
