import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaginatorModule } from '@tc/paginator';
import { AdvGenericSearchResultsModule } from '../../widgets/shared/adv-generic-search-results/adv-generic-search-results.module';
import { ResAvailabilityCalendarFocusViewComponent } from '../../widgets/shared/res-availability-calendar-focus-view/res-availability-calendar-focus-view.component';
import { ResultHeaderModule } from '../../widgets/shared/result-header/result-header.module';
import { GenericSearchResultsComponent } from './generic-search-results.component';

@NgModule({
    imports: [
        CommonModule,
        ResultHeaderModule,
        PaginatorModule,
        AdvGenericSearchResultsModule
    ],
    declarations: [GenericSearchResultsComponent],
    exports: [GenericSearchResultsComponent],
    entryComponents: [GenericSearchResultsComponent, ResAvailabilityCalendarFocusViewComponent]
})
export class GenericSearchResultsModule {}
