export class OperationQueuePanelConfig {
    preventSwitching = false;
    currentTab = 0;
    activatedProfile: OperationQueueProfile;
    timeGrouping: number;
    productViewOpen = true;
}

export enum OperationQueueProfile {
    COMMON_PROFILE = 'COMMON_PROFILE',
    TRS_PROFILE = 'TRS_PROFILE',
    TOUR_PROFILE = 'TOUR_PROFILE',
    HOTEL_PROFILE = 'HOTEL_PROFILE',
    GENERIC_PROFILE = 'GENERIC_PROFILE'
}
