import * as tslib_1 from "tslib";
import { KeyControl } from '../common/key-control';
var Supplier = /** @class */ (function (_super) {
    tslib_1.__extends(Supplier, _super);
    function Supplier() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.isEditTriggered = false;
        return _this;
    }
    return Supplier;
}(KeyControl));
export { Supplier };
var SupplierItems = /** @class */ (function () {
    function SupplierItems() {
    }
    return SupplierItems;
}());
export { SupplierItems };
export var Locale;
(function (Locale) {
    var TYPE;
    (function (TYPE) {
        TYPE[TYPE["English"] = 0] = "English";
        TYPE[TYPE["Danish"] = 1] = "Danish";
        TYPE[TYPE["French"] = 2] = "French";
        TYPE[TYPE["German"] = 3] = "German";
        TYPE[TYPE["Italian"] = 4] = "Italian";
        TYPE[TYPE["Japanese"] = 5] = "Japanese";
        TYPE[TYPE["Russian"] = 6] = "Russian";
        TYPE[TYPE["Spanish"] = 7] = "Spanish";
        TYPE[TYPE["Portuguese"] = 8] = "Portuguese";
        TYPE[TYPE["Mandarin-Chinese"] = 9] = "Mandarin-Chinese";
    })(TYPE = Locale.TYPE || (Locale.TYPE = {}));
})(Locale || (Locale = {}));
export var ResourceCategory;
(function (ResourceCategory) {
    ResourceCategory[ResourceCategory["TRANSPORT"] = 1] = "TRANSPORT";
    ResourceCategory[ResourceCategory["GENERIC"] = 0] = "GENERIC";
})(ResourceCategory || (ResourceCategory = {}));
export var SupplierBusinessType;
(function (SupplierBusinessType) {
    SupplierBusinessType["INDEPENDENT"] = "INDEPENDENT";
    SupplierBusinessType["INTERNAL"] = "INTERNAL";
    SupplierBusinessType["EXTERNAL"] = "EXTERNAL";
})(SupplierBusinessType || (SupplierBusinessType = {}));
var Grade = /** @class */ (function () {
    function Grade() {
    }
    return Grade;
}());
export { Grade };
