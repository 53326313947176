import { Component, Input, OnInit } from '@angular/core';
import { TC } from '@tc-core/util/constants';
import { ConfigLoader } from '@tc-core/util/framework/config-loader.service';
import { TCO } from '../../../constants';
import { Resource } from '../../../models/resource/resource';
import { Grade } from '../../../models/supplier/supplier';

@Component({
    selector: 'tc-vehicle-specific-info-card',
    templateUrl: './vehicle-specific-info-card.component.html'
})
export class VehicleSpecificInfoCardComponent implements OnInit {

    EMPTY_CHAR = TC.FORMAT.EMPTY_CHAR;
    maxGrade = 10;
    grades: Grade[];

    @Input() resource: Resource = null;
    @Input() showTitle: boolean = true;

    constructor(private configLoader: ConfigLoader) { }

    ngOnInit() {
        const gradeConfig = this.configLoader.configurations.get(TCO.CONF.CONF_GRADES_CONFIG);
        if (gradeConfig.grade_values) {
            this.grades = gradeConfig.grade_values.sort((a, b) => (a.order > b.order ? 1 : b.order > a.order ? -1 : 0));
        }
        this.maxGrade = gradeConfig.max_grade ? gradeConfig.max_grade : 10;
    }

    getGradeValue(grade: number | undefined): string
    {
        if (grade && grade !== 0) {
            return this.grades[Math.ceil((grade / this.maxGrade) * this.grades.length) - 1].displayName;
        }
        return this.EMPTY_CHAR;
    }

    getDriverName(id: number) {
        if (id) {
            return id;
        }
        return this.EMPTY_CHAR;
    }


    public getUserName() {
        if (this.resource && this.resource.userProfile) {
            return this.resource.userProfile.firstName + ' ' + this.resource.userProfile.lastName;
        } else {
            return this.EMPTY_CHAR;
        }
    }

}
