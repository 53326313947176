import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DirectivesModule } from '@tc/directives';
import { SectionHeadingModule } from '@tc/section-heading';
import { KeyControlsEditViewModule } from '../../profile-management/common/key-controls-edit-view/key-controls-edit-view.module';
import { BookingInfoModule } from './booking-info/booking-info.module';
import { BookingItemsModule } from './booking-items/booking-items.module';
import { ItemNotesModule } from './booking-items/item-notes/item-notes.module';
import { BookingNotesModule } from './booking-notes/booking-notes.module';
import { BookingComponent } from './booking.component';
import { PassengerSelectionModule } from './booking-items/passenger-selection/passenger-selection.module';
import { PassengerGenerationModule } from './passenger-generation/passenger-generation.module';
import { BookingPassengersModule } from './passengers/booking-passengers.module';
import { BookingNotesSummaryModule } from '@tc/booking-notes-summary';

@NgModule({
    declarations: [BookingComponent],
    imports: [
        CommonModule,
        SectionHeadingModule,
        DirectivesModule,
        BookingInfoModule,
        BookingPassengersModule,
        BookingItemsModule,
        BookingNotesModule,
        PassengerSelectionModule,
        BookingNotesSummaryModule,
        PassengerGenerationModule,
        ItemNotesModule,
        KeyControlsEditViewModule
    ],
    exports: [BookingComponent],
    entryComponents: [BookingComponent]
})
export class BookingModule {}
