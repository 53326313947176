import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { BackgroundTask } from '../../../models/background/background-task';
import { BackgroundTaskEvent } from '../../../models/background/background-task-event';
import { TaskStatus } from '../../../models/background/task-status';
import { Document } from '../../../models/document/document';
import { BackgroundTaskApiService } from '../../backend-consumers/background/background-task-api.service';
import { ResponseUtil } from '../response/response-util.service';
import {DocumentQueueGroup} from "../../../models/criteria/DocumentQueueGroup";

@Injectable({
    providedIn: 'root'
})
export class BackgroundTaskService {

    private manualDocsToUiSubject = new Subject<BackgroundTaskEvent<Document[]>>();
    private docQueueDocsToUiSubject = new Subject<BackgroundTaskEvent<DocumentQueueGroup[]>>();
    public manualDocsToUi: Observable<BackgroundTaskEvent<Document[]>> = this.manualDocsToUiSubject.asObservable();
    public docQueueToUi: Observable<BackgroundTaskEvent<DocumentQueueGroup[]>> = this.docQueueDocsToUiSubject.asObservable();

    private manualDocsUserInputSubject = new Subject<any>();
    public manualDocsUserInput: Observable<any> = this.manualDocsUserInputSubject.asObservable();

    taskCompleted = false;
    taskFailed = false;
    taskCancelled = false;
    taskRunning = false;

    constructor(
        private backgroundTaskApiService: BackgroundTaskApiService
    ) { }

    private pushUiEvent(event: BackgroundTaskEvent<any[]>) {
        if (this.manualDocsToUiSubject) {
            this.manualDocsToUiSubject.next(event);
        }
    }

    private pushInput(event: any) {
        if (this.manualDocsUserInputSubject) {
            this.manualDocsUserInputSubject.next(event);
        }
    }

    public startStatusChecking(task: BackgroundTask) {
        this.taskCompleted = false;
        this.taskFailed = false;
        this.taskCancelled = false;
        this.taskRunning = true;
        this.checkTaskStatusPeriodically(task);
    }

    public cancelStatusChecking(task: BackgroundTask) {
        this.taskCancelled = true;
        this.taskRunning = false;
    }

    public isTaskRunning(){
        return this.taskRunning;
    }

    private checkTaskStatusPeriodically(task: BackgroundTask) {
        this.pushUiEvent(new BackgroundTaskEvent<Document[]>(task, null));
        this.pushUiEvent(new BackgroundTaskEvent<DocumentQueueGroup[]>(task, null));
        setTimeout(() => {
            if (!this.taskCompleted && !this.taskFailed) {
                this.backgroundTaskApiService.getTask(task.taskId)
                    .subscribe(
                        result => {
                            const taskLoaded = ResponseUtil.getFirstData(result);
                            if (taskLoaded.taskStatus === TaskStatus.FINISHED) {
                                this.taskCompleted = true;
                                this.taskRunning = false;
                            }else if (taskLoaded.taskStatus === TaskStatus.FAILED){
                                this.taskRunning = false;
                                this.taskFailed = true;
                            }
                            if (!this.taskCancelled) {
                                this.checkTaskStatusPeriodically(taskLoaded);
                            }
                        }
                    );
            }
        }, 2000);
    }
}
