import * as tslib_1 from "tslib";
import { TcApiError } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-api-error';
import { TcHttpError } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-http-error';
import { Icon } from '@tc-core/model/it/codegen/ui/framework/icon';
import { KeyValue } from '@tc-core/model/it/codegen/ui/key-value';
import { TC } from '@tc-core/util/constants';
import { DataKey, DataStore } from '@tc-core/util/framework/data-store.service';
import { CommonHelper } from '@tc-core/util/helpers/common-helper.service';
import { DataFormatterUtilService } from '@tc-core/util/system/data-formatter-util.service';
import { JsonUtilService } from '@tc-core/util/system/json-util.service';
import { Subscription } from 'rxjs';
import { AssignmentStatus } from '../../../models/reservation/assignment-status';
import { NoteType } from '../../../models/reservation/note-item-view';
var CardContentPreProcessorService = /** @class */ (function () {
    function CardContentPreProcessorService(jsonUtil, dataFormatterUtilService, dataStore, commonHelper) {
        this.jsonUtil = jsonUtil;
        this.dataFormatterUtilService = dataFormatterUtilService;
        this.dataStore = dataStore;
        this.commonHelper = commonHelper;
        // todo :change this to get from config
        this.defaultCurrencyMark = 'AED';
    }
    /**
     *
     * @param client
     * @param arr
     * @returns {any}
     */
    CardContentPreProcessorService.prototype.filterByEmailContact = function (client, arr, id) {
        var returnVal = new KeyValue;
        returnVal.key = id;
        returnVal.value = this.filterByContactType(client, arr, 'email');
        return returnVal;
    };
    /**
     *
     * @param client
     * @param arr
     * @returns {any}
     */
    CardContentPreProcessorService.prototype.filterByPhoneNoContact = function (client, arr, id) {
        var returnVal = new KeyValue;
        returnVal.key = id;
        returnVal.value = this.filterByContactType(client, arr, 'phoneNo');
        return returnVal;
    };
    /**
     *
     * @param client
     * @param arr
     * @param type
     * @returns {any}
     */
    CardContentPreProcessorService.prototype.filterByContactType = function (client, arr, type) {
        var contactValue = '';
        var contactItems = this.jsonUtil.getDataArray(client, arr);
        if (contactItems && contactItems.length > 0) {
            contactItems = contactItems.filter(function (item) { return item['contactType'] === type; });
            if (contactItems && contactItems.length > 0 && contactItems[0].value && contactItems[0]['value']) {
                contactValue = contactItems[0]['value'];
                if (contactValue && contactValue.length > 0 && contactValue.indexOf('#') > 0) {
                    contactValue = contactValue.substr(0, contactValue.indexOf('#'));
                }
            }
        }
        return contactValue;
    };
    /**
     *
     * @param client
     * @param arr
     * @returns {Icon}
     */
    CardContentPreProcessorService.prototype.processClientIcon = function (client, arr, id) {
        var returnVal = new KeyValue();
        var iconView = new Icon();
        var clientType = client.type;
        var filteredIcons = arr.filter(function (item) { return item['type'] === clientType; });
        var iconObj = filteredIcons.length > 0 ? filteredIcons[0] : null;
        if (iconObj) {
            iconView.key = iconObj['key'];
            iconView.iconType = iconObj['iconType'];
            if (client.summary.active) {
                iconView.style = iconObj['style'] + ' ' + iconObj['activeClass'];
            }
            else {
                iconView.style = iconObj['style'] + ' ' + iconObj['inactiveClass'];
            }
            iconView.value = this.jsonUtil.findJsonProperty(client, iconObj['value'].split('.'), null);
            iconView.value = iconView.value ? iconView.value : '';
        }
        returnVal.key = id;
        returnVal.value = iconView;
        return returnVal;
    };
    /**
     *
     * @param reservation
     * @param arr
     * @param id
     */
    CardContentPreProcessorService.prototype.processTransferModeIcon = function (reservation, arr, id) {
        var returnVal = new KeyValue();
        var iconView = new Icon();
        var transferModeIcon = arr.find(function (item) { return item['iconName'] === 'TRANSFER_MODE_ICN'; });
        var valueField = transferModeIcon['key'];
        var statusField = transferModeIcon['status'];
        var transferModeValue = reservation[valueField];
        if (transferModeIcon) {
            iconView.key = transferModeIcon['iconName'];
            iconView.iconType = transferModeValue;
            iconView.value = transferModeValue;
            iconView.style = transferModeIcon['style'] + ' ' +
                transferModeIcon[this.getStatusClass(reservation[statusField])];
            iconView.value = iconView.value ? iconView.value : '';
            if (iconView.value.length > 3) {
                iconView.style = iconView.style + ' ' + transferModeIcon['smallTextClass'];
            }
        }
        returnVal.key = id;
        returnVal.value = iconView;
        return returnVal;
    };
    CardContentPreProcessorService.prototype.processAllocationSupplierIcon = function (resource, arr, id) {
        if (resource.transferMode) {
            return this.processTransferModeIconForSupplierAssignment(resource, arr, id);
        }
        else {
            return this.processGenericIconForSupplierAssignment(resource, arr, id);
        }
    };
    CardContentPreProcessorService.prototype.processTransferModeIconForSupplierAssignment = function (reservation, arr, id) {
        var returnVal = new KeyValue();
        var iconView = new Icon();
        var transferModeIcon = arr.find(function (item) { return item['iconNameVehicle'] === 'TRANSFER_MODE_ICN'; });
        var valueField = transferModeIcon['key'];
        var transferModeValue = reservation[valueField];
        if (transferModeIcon) {
            iconView.key = transferModeIcon['iconNameVehicle'];
            iconView.iconType = transferModeValue;
            iconView.value = transferModeValue;
            iconView.style = transferModeIcon['style'] + ' ' +
                transferModeIcon['neutralClass'];
            iconView.value = iconView.value ? iconView.value : '';
            if (iconView.value.length > 3) {
                iconView.style = iconView.style + ' ' + transferModeIcon['smallTextClass'];
            }
        }
        returnVal.key = id;
        returnVal.value = iconView;
        return returnVal;
    };
    CardContentPreProcessorService.prototype.processGenericIconForSupplierAssignment = function (genericResource, arr, id) {
        var returnVal = new KeyValue();
        var iconView = new Icon();
        var genericResourceIcon = arr.find(function (item) { return item['iconNameDriver'] === 'GENERIC_RESOURCE_ICN'; });
        var value = genericResourceIcon['keyForDriverAssignment'];
        if (genericResourceIcon) {
            if (genericResourceIcon.iconKeys[value]) {
                iconView.key = genericResourceIcon.iconKeys[value];
            }
            iconView.iconType = genericResourceIcon['iconType'];
            iconView.value = genericResourceIcon.values[value];
            iconView.style = genericResourceIcon['style'] + ' ' +
                genericResourceIcon['neutralClass'];
            iconView.value = iconView.value ? iconView.value : '';
            if (iconView.value.length > 3) {
                iconView.style = iconView.style + ' ' + genericResourceIcon['smallTextClass'];
            }
        }
        returnVal.key = id;
        returnVal.value = iconView;
        return returnVal;
    };
    CardContentPreProcessorService.prototype.processVehicleTransferModeIcon = function (resource, arr, id) {
        var returnVal = new KeyValue();
        var iconView = new Icon();
        var transferModeIcon = arr.find(function (item) { return item['iconName'] === 'TRANSFER_MODE_ICN'; });
        var valueField = transferModeIcon['key'];
        var statusField = transferModeIcon['status'];
        var transferModeValue = resource[valueField];
        if (transferModeIcon) {
            iconView.key = transferModeIcon['iconName'];
            iconView.iconType = transferModeValue;
            iconView.value = transferModeValue;
            if (resource.exceedCapacity) {
                iconView.style = transferModeIcon['style'] + ' ' +
                    transferModeIcon['dangerClass'];
            }
            else {
                iconView.style = transferModeIcon['style'] + ' ' +
                    transferModeIcon['neutralClass'];
            }
            iconView.value = iconView.value ? iconView.value : '';
            if (iconView.value.length > 3) {
                iconView.style = iconView.style + ' ' + transferModeIcon['smallTextClass'];
            }
        }
        returnVal.key = id;
        returnVal.value = iconView;
        return returnVal;
    };
    CardContentPreProcessorService.prototype.processAllocationIcon = function (resource, arr) {
        var returnVal = new KeyValue();
        var iconView = new Icon();
        var transferModeIcon = arr.find(function (item) { return item['iconName'] === 'TRANSFER_MODE_ICN'; });
        var valueField = transferModeIcon['key'];
        var statusField = transferModeIcon['status'];
        var transferModeValue = resource[valueField];
        if (transferModeIcon) {
            iconView.key = transferModeIcon['iconName'];
            iconView.iconType = transferModeValue;
            iconView.value = transferModeValue;
            iconView.value = iconView.value ? iconView.value : '';
            if (iconView.value.length > 3) {
                iconView.style = iconView.style + ' ' + transferModeIcon['smallTextClass'];
            }
        }
        resource.icon = iconView;
        return resource;
    };
    /**
     *
     * @param vehicleSummary
     * @param arr
     * @param id
     */
    CardContentPreProcessorService.prototype.processVehicleIcon = function (vehicleSummary, arr, id) {
        var returnVal = new KeyValue();
        var iconView = new Icon();
        var vehicleIcon = arr.find(function (item) { return item['iconName'] === 'VEHICLE_ICN'; });
        var valueField = vehicleIcon['key'];
        var statusField = vehicleIcon['statusField'];
        var vehicleModel = vehicleSummary[valueField];
        if (vehicleIcon) {
            iconView.key = vehicleIcon['iconName'];
            iconView.iconType = vehicleIcon['iconType'];
            // iconView.value = vehicleModel;
            iconView.style = vehicleIcon['style'] + ' ' +
                vehicleIcon[this.activeOrInactiveClass(vehicleSummary[statusField])];
            iconView.value = iconView.value ? iconView.value : '';
            if (iconView.value.length > 3) {
                iconView.style = iconView.style + ' ' + vehicleIcon['smallTextClass'];
            }
        }
        returnVal.key = id;
        returnVal.value = iconView;
        return returnVal;
    };
    /**
     *
     * @param supplier
     * @param arr
     * @param id
     */
    CardContentPreProcessorService.prototype.processSupplierIcon = function (supplier, arr, id) {
        var returnVal = new KeyValue();
        var iconView = new Icon();
        var supplierIcon = arr.find(function (item) { return item['iconName'] === 'SUPPLIER_ICN'; });
        var statusField = supplierIcon['statusField'];
        var supplierMode = supplier[statusField];
        var iconValue = supplierIcon['value'];
        if (supplierIcon) {
            iconView.key = supplierIcon['iconName'];
            iconView.iconType = supplierIcon['iconType'];
            iconView.value = iconValue;
            iconView.style = supplierIcon['style'] + ' ' + supplierIcon[this.activeOrInactiveClass(supplierMode)];
            iconView.value = iconView.value ? iconView.value : '';
            if (iconView.value.length > 3) {
                iconView.style = iconView.style + ' ' + supplierIcon['smallTextClass'];
            }
        }
        returnVal.key = id;
        returnVal.value = iconView;
        return returnVal;
    };
    CardContentPreProcessorService.prototype.activeOrInactiveClass = function (condition) {
        var cls = '';
        if (condition) {
            cls = 'activeClass';
        }
        else {
            cls = 'inactiveClass';
        }
        return cls;
    };
    /**
     *
     * @param bookingItemInfo
     * @param arr
     * @param id
     */
    CardContentPreProcessorService.prototype.processBookingItemIcon = function (bookingItemInfo, arr, id) {
        var returnVal = new KeyValue();
        var iconView = new Icon();
        var iconResource = arr.find(function (item) { return item.key === 'BOOKING_ITEM_ICN'; });
        if (iconResource) {
            var iconValueField = iconResource.value;
            var iconValue = bookingItemInfo[iconValueField];
            iconView.key = iconResource.iconKeys[iconValue];
            if (!iconView.key) {
                iconView.key = iconResource.iconKeys.default;
            }
            iconView.iconType = iconResource.iconType;
            iconView.style = iconResource.style + ' ' + iconResource.activeClass;
            iconView.value = iconView.value ? iconView.value : '';
        }
        returnVal.key = id;
        returnVal.value = iconView;
        return returnVal;
    };
    /**
     *
     * @param genericResource
     * @param arr
     * @param id
     */
    CardContentPreProcessorService.prototype.processGenericIcon = function (genericResource, arr, id) {
        var returnVal = new KeyValue();
        var iconView = new Icon();
        var genericResourceIcon = arr.find(function (item) { return item['iconName'] === 'GENERIC_RESOURCE_ICN'; });
        var valueField = genericResourceIcon['key'];
        var iconValue = genericResource[valueField];
        var statusField = genericResourceIcon['statusField'];
        var resourceMode = statusField ? genericResource[statusField] : true;
        if (genericResourceIcon) {
            if (genericResourceIcon.iconKeys[iconValue]) {
                iconView.key = genericResourceIcon.iconKeys[iconValue];
            }
            iconView.iconType = genericResourceIcon['iconType'];
            iconView.value = genericResourceIcon.values[iconValue];
            iconView.style = genericResourceIcon['style'] + ' ' +
                genericResourceIcon[this.activeOrInactiveClass(resourceMode)];
            iconView.value = iconView.value ? iconView.value : '';
            if (iconView.value.length > 3) {
                iconView.style = iconView.style + ' ' + genericResourceIcon['smallTextClass'];
            }
        }
        returnVal.key = id;
        returnVal.value = iconView;
        return returnVal;
    };
    CardContentPreProcessorService.prototype.getStatusClass = function (status) {
        var classConfigName = '';
        if (status == AssignmentStatus.PENDING) {
            classConfigName = 'neutralClass';
        }
        else if (status == AssignmentStatus.DMC_CONFIRM) {
            classConfigName = 'confirmClass';
        }
        else if (status == AssignmentStatus.BOOKING_UPDATE) {
            classConfigName = 'bookingUpdateClass';
        }
        else if (status == AssignmentStatus.REQUEST_EXPIRED) {
            classConfigName = 'expiredClass';
        }
        else if (status == AssignmentStatus.INACTIVE_NOTIFY) {
            classConfigName = 'inactiveNotifyClass';
        }
        else if (status == AssignmentStatus.INACTIVE) {
            classConfigName = 'inactiveClass';
        }
        else if (status == AssignmentStatus.NO_SHOW) {
            classConfigName = 'noShowClass';
        }
        else if (status == AssignmentStatus.SUPPLIER_CONFIRMED) {
            classConfigName = 'supplierConfirmClass';
        }
        else if (status == AssignmentStatus.SUPPLIER_REJECTED) {
            classConfigName = 'rejectedClass';
        }
        else {
            classConfigName = 'neutralClass';
        }
        return classConfigName;
    };
    /**
     *
     * @param reservation
     * @param arr
     * @param id
     */
    CardContentPreProcessorService.prototype.getKeyValue = function (reservation, arr, id) {
        var returnVal = new KeyValue();
        returnVal.key = id;
        if (arr) {
            var value = this.jsonUtil.getRawData(reservation, arr);
            returnVal.value = value;
        }
        return returnVal;
    };
    CardContentPreProcessorService.prototype.processKeyArray = function (reservation, arr, id) {
        var dataArrayInKeyOrder = [];
        var keyArray = arr[0];
        if (Array.isArray(keyArray)) {
            keyArray.forEach(function (key) {
                if (reservation[key] !== undefined) {
                    dataArrayInKeyOrder.push(reservation[key]);
                }
            });
        }
        else if (reservation[keyArray] !== undefined) {
            dataArrayInKeyOrder.push(reservation[keyArray]);
        }
        return dataArrayInKeyOrder;
    };
    /**
     *
     * @param reservation
     * @param arr
     * @param id
     */
    CardContentPreProcessorService.prototype.processKeyArray_countAndPassengerName = function (reservation, arr, id) {
        var returnVal = new KeyValue();
        returnVal.key = id;
        var dataArray = this.processKeyArray(reservation, arr, id);
        if (dataArray && dataArray.length > 1) {
            var count = dataArray[0];
            returnVal.value = count;
            var name_1 = dataArray[1];
            if (name_1) {
                returnVal.value = returnVal.value + ' (' + name_1 + ')';
            }
        }
        else if (dataArray && dataArray.length) {
            returnVal.value = dataArray[0];
        }
        return returnVal;
    };
    /**
     *
     * @param reservation
     * @param arr
     * @param id
     */
    CardContentPreProcessorService.prototype.processKeyArray_priceAndCurrency = function (reservation, arr, id) {
        var returnVal = new KeyValue();
        returnVal.key = id;
        var dataArray = this.processKeyArray(reservation, arr, id);
        if (dataArray && dataArray.length) {
            returnVal.value = parseFloat(dataArray[0]);
        }
        return returnVal;
    };
    CardContentPreProcessorService.prototype.getPriceAndCurrency = function (price, currency, forwardStyle) {
        if (forwardStyle === void 0) { forwardStyle = true; }
        if (forwardStyle) {
            if (price || price === 0) {
                return currency + ' ' + price.toFixed(2);
            }
            else {
                return null;
            }
        }
        else {
            if (price || price === 0) {
                return price.toFixed(2) + ' ' + currency;
            }
            else {
                return null;
            }
        }
    };
    /**
     *
     * @param genericResource
     * @param arr
     * @param id
     */
    CardContentPreProcessorService.prototype.processMinPax = function (genericResource, arr, id) {
        var returnVal = new KeyValue();
        returnVal.key = id;
        var dataArray = this.processKeyArray(genericResource, arr, id);
        if (dataArray) {
            returnVal.value = 'min pax: ' + dataArray[0];
        }
        else if (dataArray && dataArray.length) {
            returnVal.value = 'min pax: -';
        }
        return returnVal;
    };
    /**
     *
     * @param genericResource
     * @param arr
     * @param id
     */
    CardContentPreProcessorService.prototype.processMaxPax = function (genericResource, arr, id) {
        var returnVal = new KeyValue();
        returnVal.key = id;
        var dataArray = this.processKeyArray(genericResource, arr, id);
        if (dataArray) {
            returnVal.value = 'max pax: ' + dataArray[0];
        }
        else if (dataArray && dataArray.length) {
            returnVal.value = 'max pax: -';
        }
        return returnVal;
    };
    CardContentPreProcessorService.prototype.getPriceAndDefaultCurrency = function (price, forwardStyle) {
        if (forwardStyle === void 0) { forwardStyle = true; }
        return this.getPriceAndCurrency(price, this.defaultCurrencyMark, forwardStyle);
    };
    /**
     *
     * @param reservation
     * @param arr
     * @param id
     */
    CardContentPreProcessorService.prototype.arrayToString = function (reservation, arr, id) {
        var returnVal = new KeyValue();
        returnVal.key = id;
        returnVal.value = '';
        var values = this.jsonUtil.getDataArray(reservation, arr);
        if (Array.isArray(values)) {
            returnVal.value = this.getStrOfArray(values);
        }
        else {
            returnVal.value = values;
        }
        return returnVal;
    };
    CardContentPreProcessorService.prototype.arrayToString_pickups = function (reservation, arr, id) {
        var keyValue = this.arrayToString(reservation, arr, id);
        if (keyValue.value) {
            return this.addPrefixPostfix(keyValue, '', '');
        }
        else {
            return keyValue;
        }
    };
    CardContentPreProcessorService.prototype.arrayToString_dropOffs = function (reservation, arr, id) {
        var keyValue = this.arrayToString(reservation, arr, id);
        if (keyValue.value) {
            return this.addPrefixPostfix(keyValue, '', '');
        }
        else {
            return keyValue;
        }
    };
    /**
     *
     * @param reservation
     * @param arr
     * @param id
     */
    CardContentPreProcessorService.prototype.processKeyArray_createAddress = function (reservation, arr, id) {
        var returnVal = new KeyValue();
        returnVal.key = id;
        var dataArray = this.processKeyArray(reservation, arr, id);
        returnVal.value = '';
        if (dataArray) {
            returnVal.value = dataArray[0];
            dataArray.slice(1, dataArray.length).forEach(function (val) {
                returnVal.value = val + ', ' + returnVal.value;
            });
        }
        return returnVal;
    };
    CardContentPreProcessorService.prototype.processSupplierName = function (reservation, arr, id) {
        var returnVal = new KeyValue();
        returnVal.key = id;
        var dataArray = this.processKeyArray(reservation, arr, id);
        returnVal.value = dataArray[0].name;
        return returnVal;
    };
    CardContentPreProcessorService.prototype.processSupplier_cost = function (resource, arr, id) {
        return this.processKeyArray_priceAndCurrency(resource.supplier, arr, id);
    };
    CardContentPreProcessorService.prototype.processKeyArray_processSupplierPerPrice = function (resource, arr, id) {
        return this.processKeyArray_processPerPrice(resource.supplier, arr, id);
    };
    CardContentPreProcessorService.prototype.processRemainingCapacity = function (resource, arr, id) {
        var returnVal = new KeyValue();
        returnVal.key = id;
        var dataArray = this.processKeyArray(resource, arr, id);
        returnVal.value = dataArray[0];
        returnVal = this.addPrefixPostfix(returnVal, '<span class="tc-strip-text__label">Seats Remaining: </span>', '');
        /*if(resource.exceedCapacity){
            returnVal.value = '<span class="tc-strip-exceed-capacity">'+ returnVal.value + '</span>'
        }*/
        return returnVal;
    };
    CardContentPreProcessorService.prototype.processAllocatedCapacity = function (resource, arr, id) {
        var returnVal = new KeyValue();
        returnVal.key = id;
        var dataArray = this.processKeyArray(resource, arr, id);
        returnVal.value = dataArray[0];
        returnVal = this.addPrefixPostfix(returnVal, '<span class="tc-strip-text__label">Seats Allocated: </span>', '');
        return returnVal;
    };
    /**
     *
     * @param supplier
     * @param arr
     * @param id
     */
    CardContentPreProcessorService.prototype.processKeyArray_processPerPrice = function (data, arr, id) {
        var returnVal = new KeyValue();
        returnVal.key = id;
        var dataArray = this.processKeyArray(data, arr, id);
        if (dataArray && dataArray.length > 0) {
            if (data.unitFare || data.unitCost) {
                returnVal.value = dataArray[0].toFixed(2);
                returnVal = this.addPrefixPostfix(returnVal, '<span class="tc-strip-text__label">Unit: </span>', '');
            }
            else {
                var adult = dataArray[1] ? dataArray[1].toFixed(2) : '0';
                var child = dataArray[2] ? dataArray[2].toFixed(2) : '0';
                var infant = dataArray[3] ? dataArray[3].toFixed(2) : '0';
                returnVal.value = "<span class='tc-strip-text__label'>Adt:</span> " + adult + " | <span class='tc-strip-text__label'>Cld: </span>" + child + " | <span class='tc-strip-text__label'>Inf: </span>" + infant;
            }
        }
        return returnVal;
    };
    CardContentPreProcessorService.prototype.processVehicleCost = function (resource, arr, id) {
        var data = resource.supplier;
        var returnVal = new KeyValue();
        returnVal.key = id;
        var dataArray = this.processKeyArray(resource, arr, id);
        if (dataArray && dataArray.length > 0) {
            var currency = resource.supplier && resource.supplier.currency ? resource.supplier.currency : 'AED';
            returnVal.value = this.currencyFormatter(currency, dataArray[0]);
            returnVal = this.addPrefixPostfix(returnVal, '<span class="tc-strip-text__label">Vehicle Cost: </span>', '');
        }
        return returnVal;
    };
    CardContentPreProcessorService.prototype.currencyFormatter = function (currency, cost) {
        var inrFormat = new Intl.NumberFormat('en-Us', {
            style: 'currency',
            currency: currency,
            minimumFractionDigits: 2
        });
        return inrFormat.format(cost);
    };
    CardContentPreProcessorService.prototype.setEmptyColumn = function (reservation, arr, id) {
        var returnVal = new KeyValue();
        returnVal.key = id;
        return returnVal;
    };
    CardContentPreProcessorService.prototype.getStrOfArray = function (data) {
        var str = '';
        var length = data.length;
        for (var i = 0; i < length; i++) {
            str += data[i];
            if (i < length - 1) {
                str += ', ';
            }
        }
        return str;
    };
    CardContentPreProcessorService.prototype.processPickups = function (reservation, arr, id) {
        var keyValue = this.getKeyValue(reservation, arr, id);
        return this.limitValueCharacters(this.addPrefixPostfix(keyValue, '<span class="tc-strip-text__label">Pickups: </span>', ''), 90);
    };
    CardContentPreProcessorService.prototype.processDropOffs = function (reservation, arr, id) {
        var keyValue = this.getKeyValue(reservation, arr, id);
        return this.limitValueCharacters(this.addPrefixPostfix(keyValue, '<span class="tc-strip-text__label">DropOffs: </span>', ''), 90);
    };
    CardContentPreProcessorService.prototype.limitValueCharacters = function (keyValue, size) {
        if (typeof (keyValue.value) === 'string') {
            if (keyValue.value.length > size) {
                keyValue.value = keyValue.value.substr(0, size).concat('...');
            }
        }
        return keyValue;
    };
    CardContentPreProcessorService.prototype.processAllocationsLeftCount = function (reservation, arr, id) {
        var keyValue = this.getKeyValue(reservation, arr, id);
        return this.addPrefixPostfix(keyValue, '', ' Remaining');
    };
    CardContentPreProcessorService.prototype.processAllocatedCount = function (reservation, arr, id) {
        var keyValue = this.getKeyValue(reservation, arr, id);
        return this.addPrefixPostfix(keyValue, '', ' Allocated');
    };
    CardContentPreProcessorService.prototype.processEstimatedPrice = function (reservation, arr, id) {
        var keyValue = this.processKeyArray_priceAndCurrency(reservation, arr, id);
        keyValue = this.addPrefixPostfix(keyValue, '', '');
        keyValue.value = ' ' + this.defaultCurrencyMark;
        return keyValue;
        // if (keyValue.value) {
        //     return keyValue;
        // } else {
        //     keyValue.value = ' '+this.defaultCurrencyMark+' 0';
        //     return keyValue;
        // }
    };
    CardContentPreProcessorService.prototype.processKeyArray_priceAndCurrencyServiceItem = function (reservation, arr, id) {
        var keyValue = this.processKeyArray_priceAndCurrency(reservation, arr, id);
        keyValue.value = this.defaultCurrencyMark;
        return keyValue;
        // if (keyValue.value !== null) {
        //     return keyValue;
        // } else {
        //     keyValue.value = this.defaultCurrencyMark+' 0';
        //     return keyValue;
        // }
    };
    CardContentPreProcessorService.prototype.addPrefixPostfix = function (keyValue, prefix, postfix) {
        if (keyValue.value !== null) {
            keyValue.value = prefix + keyValue.value + postfix;
        }
        return keyValue;
    };
    /**
     *
     * @param reservation
     * @param arr
     * @param id
     */
    CardContentPreProcessorService.prototype.processKeyArray_supplierResourceInputBoxes = function (reservation, arr, id) {
        var returnVal = new KeyValue();
        returnVal.key = id;
        var dataArray = this.processKeyArray(reservation, arr, id);
        if (dataArray && dataArray.length > 1) {
            returnVal.value = { 'supplier': dataArray[0], 'resource': dataArray[1] };
        }
        return returnVal;
    };
    // /**
    //  *
    //  * @param reservation
    //  * @param arr
    //  * @param id
    //  */
    // formatDate_ddMMMyy_withStyle(reservation: any, arr: any[], id: string): KeyValue {
    //     let keyValue = this.getKeyValue(reservation, arr, id);
    //     let formattedDate = this.dataFormatterUtilService.formatDate(<string>keyValue.value, TC.FORMAT.DATE_FORMAT_dd_MMM_yy);
    //     keyValue.value=formattedDate;
    //     return keyValue;
    // }
    /**
     *
     * @param client
     * @param arr
     * @returns {Icon}
     */
    CardContentPreProcessorService.prototype.processCommonIconKeepActive = function (obj, arr, id) {
        var returnVal = new KeyValue();
        var iconView = new Icon();
        var iconObj = arr[0];
        if (iconObj) {
            iconView.key = iconObj['key'];
            iconView.iconType = iconObj['iconType'];
            iconView.style = iconObj['style'] + ' ' + iconObj['activeClass'];
            iconView.value = iconObj['value'];
        }
        returnVal.key = id;
        returnVal.value = iconView;
        return returnVal;
    };
    /**
     *
     * @param client
     * @param arr
     * @returns {Icon}
     */
    CardContentPreProcessorService.prototype.processGroupMasterIcon = function (client, arr, id) {
        var returnVal = new KeyValue();
        var iconView = new Icon();
        var clientType = client.type;
        var iconObj = arr[0];
        if (iconObj) {
            iconView.key = iconObj['key'];
            iconView.iconType = iconObj['iconType'];
            if (client.detail.tradeProfile.groupProfile.active) {
                iconView.style = iconObj['style'] + ' ' + iconObj['activeClass'];
            }
            else {
                iconView.style = iconObj['style'] + ' ' + iconObj['inactiveClass'];
            }
            iconView.value = iconObj['value'];
        }
        returnVal.key = id;
        returnVal.value = iconView;
        return returnVal;
    };
    /**
     *
     * @param client
     * @param arr
     * @returns {Icon}
     */
    CardContentPreProcessorService.prototype.processGroupMasterIconFromSummary = function (client, arr, id) {
        var returnVal = new KeyValue();
        var iconView = new Icon();
        var clientType = client.type;
        var iconObj = arr[0];
        if (iconObj) {
            iconView.key = iconObj['key'];
            iconView.iconType = iconObj['iconType'];
            if (client.summary.active) {
                iconView.style = iconObj['style'] + ' ' + iconObj['activeClass'];
            }
            else {
                iconView.style = iconObj['style'] + ' ' + iconObj['inactiveClass'];
            }
            iconView.value = iconObj['value'];
        }
        returnVal.key = id;
        returnVal.value = iconView;
        return returnVal;
    };
    CardContentPreProcessorService.prototype.appendWithCommaAndSpace = function (obj, arr, id) {
        var _this = this;
        var returnVal = new KeyValue();
        var dataArray = [];
        arr.forEach(function (value, i) {
            var str = _this.jsonUtil.findJsonProperty(obj, value, '');
            if (str !== '') {
                var data = _this.jsonUtil.findJsonProperty(obj, value, '');
                if (data && data.length > 0) {
                    dataArray.push(data);
                }
            }
        });
        returnVal.key = id;
        returnVal.value = dataArray.join(', ');
        return returnVal;
    };
    CardContentPreProcessorService.prototype.processClientAddress = function (obj, arr, id) {
        var _this = this;
        var returnVal = new KeyValue();
        var dataArray = [];
        arr.forEach(function (value, i) {
            var str = _this.jsonUtil.findJsonProperty(obj, value, '');
            if (str !== '') {
                var data = _this.jsonUtil.findJsonProperty(obj, value, '');
                if (data && data.length > 0) {
                    data = _this.dataFormatterUtilService.capitalizeEachWord(data, true);
                    dataArray.push(data);
                }
            }
        });
        returnVal.key = id;
        returnVal.value = dataArray.join(', ');
        return returnVal;
    };
    /**
     *
     * @param bookingSummary
     * @param arr
     * @returns {Icon}
     */
    CardContentPreProcessorService.prototype.formatDate_ddMMMyy = function (obj, arr, id) {
        var _this = this;
        var returnVal = new KeyValue();
        var dateArray = [];
        arr.forEach(function (value, i) {
            var data = _this.jsonUtil.findJsonProperty(obj, value, '');
            data = _this.dataFormatterUtilService.formatDate(data, TC.FORMAT.DATE_FORMAT_dd_MMM_yy);
            dateArray.push(data);
        });
        returnVal.key = id;
        if (dateArray) {
            var processedStr = dateArray.join(' - ');
            returnVal.value = processedStr;
        }
        return returnVal;
    };
    /**
     *
     * @param get  client booking status
     * @param arr
     * @returns {Icon}
     */
    CardContentPreProcessorService.prototype.processClientBookingStatus = function (obj, arr, id) {
        var _this = this;
        var returnVal = new KeyValue();
        var statusStr = '';
        arr.forEach(function (value, i) {
            var data = _this.jsonUtil.findJsonProperty(obj, value, '');
            statusStr = statusStr + '' + data;
        });
        var clientByIdObsv = new Subscription();
        clientByIdObsv = this.dataStore.get(DataKey.clientBookingStatus).subscribe(function (status) {
            if (_this.commonHelper.dataValidity(status)) {
                status.forEach(function (bookingType) {
                    if (statusStr === bookingType.code) {
                        returnVal.value = bookingType.value;
                        return true;
                    }
                });
            }
            else if (status instanceof TcApiError) {
            }
            else if (status instanceof TcHttpError) {
            }
        });
        if (clientByIdObsv) {
            clientByIdObsv.unsubscribe();
        }
        returnVal.key = id;
        return returnVal;
    };
    /**
     *
     * @param get  client booking status
     * @param arr
     * @returns {Icon}
     */
    CardContentPreProcessorService.prototype.processWholeSalerCountry = function (obj, arr, id) {
        var _this = this;
        var returnVal = new KeyValue();
        var statusStr = '';
        arr.forEach(function (value, i) {
            var data = _this.jsonUtil.findJsonProperty(obj, value, '');
            statusStr = statusStr + '' + data;
        });
        var clientByIdObsv = new Subscription();
        clientByIdObsv = this.dataStore.get(DataKey.countries).subscribe(function (countries) {
            if (_this.commonHelper.dataValidity(countries)) {
                countries.forEach(function (country) {
                    if (statusStr === country.code) {
                        returnVal.value = country.name;
                        return true;
                    }
                });
            }
            else if (countries instanceof TcApiError) {
            }
            else if (countries instanceof TcHttpError) {
            }
        });
        if (clientByIdObsv) {
            clientByIdObsv.unsubscribe();
        }
        returnVal.key = id;
        return returnVal;
    };
    /**
     * get client booking status
     * @param obj
     * @param arr
     * @param id
     * @returns {Icon}
     */
    CardContentPreProcessorService.prototype.processResortName = function (obj, arr, id) {
        var _this = this;
        var returnVal = new KeyValue();
        var resortCode = '';
        arr.forEach(function (value, i) {
            var data = _this.jsonUtil.findJsonProperty(obj, value, '');
            resortCode = data;
        });
        var resortObsv = new Subscription();
        resortObsv = this.dataStore.get(DataKey.hotels).subscribe(function (resorts) {
            if (_this.commonHelper.dataValidity(resorts)) {
                resorts.forEach(function (resort) {
                    if (resortCode === resort.code) {
                        returnVal.value = resort.name;
                        return true;
                    }
                });
            }
            else if (resorts instanceof TcApiError) {
            }
            else if (resorts instanceof TcHttpError) {
            }
        });
        if (resortObsv) {
            resortObsv.unsubscribe();
        }
        returnVal.key = id;
        return returnVal;
    };
    /**
     * get whoesaler group type
     * @param obj
     * @param arr
     * @param id
     * @returns {Icon}
     */
    CardContentPreProcessorService.prototype.processWholeSalerGroupType = function (obj, arr, id) {
        var _this = this;
        var returnVal = new KeyValue();
        var statusStr = '';
        arr.forEach(function (value, i) {
            var data = _this.jsonUtil.findJsonProperty(obj, value, '');
            statusStr = statusStr + '' + data;
        });
        var clientByIdObsv = new Subscription();
        clientByIdObsv = this.dataStore.get(DataKey.clientGroupTypes).subscribe(function (status) {
            if (status && JSON.stringify(status) !== JSON.stringify({})) {
                status.forEach(function (bookingType) {
                    if (statusStr === bookingType.code) {
                        returnVal.value = bookingType.value;
                        return true;
                    }
                });
            }
        });
        if (clientByIdObsv) {
            clientByIdObsv.unsubscribe();
        }
        returnVal.key = id;
        return returnVal;
    };
    /**
     * process client group type
     * @param obj
     * @param {any[]} arr
     * @param {string} id
     * @returns {KeyValue}
     */
    CardContentPreProcessorService.prototype.processClientGroup = function (obj, arr, id) {
        var returnVal = new KeyValue();
        var processedStr = 'Client group - ';
        var data = this.jsonUtil.findJsonProperty(obj, arr[0], '');
        processedStr = processedStr + data;
        returnVal.key = id;
        returnVal.value = processedStr;
        return returnVal;
    };
    /**
     * Additional Contact Summary
     * @param obj
     * @param arr
     * @param id
     * @param dataKey
     */
    CardContentPreProcessorService.prototype.getAdditionalContactSummary = function (obj, arr, id, dataKey) {
        var returnVal = new KeyValue();
        var additionalContactCount = 0;
        var addresses = this.jsonUtil.findJsonProperty(obj, arr[0], '');
        addresses.forEach(function (item) {
            additionalContactCount++;
        });
        var data = '';
        if (additionalContactCount === 1) {
            data = additionalContactCount + ' Additional Contact';
        }
        else {
            data = additionalContactCount + ' Additional Contacts';
        }
        returnVal.key = id;
        returnVal.value = data;
        return returnVal;
    };
    /**
     * Rooms
     * @param obj
     * @param arr
     * @param id
     * @param dataKey
     */
    CardContentPreProcessorService.prototype.getRoomGroupSummary = function (obj, arr, id, dataKey) {
        var returnVal = new KeyValue();
        var roomCount = 0;
        var roomGroups = this.jsonUtil.findJsonProperty(obj, arr[0], '');
        roomGroups.forEach(function (item) {
            roomCount++;
        });
        var data = '';
        if (roomCount === 1) {
            data = roomCount + ' Room Group';
        }
        else {
            data = roomCount + ' Room Groups';
        }
        returnVal.key = id;
        returnVal.value = data;
        return returnVal;
    };
    CardContentPreProcessorService.prototype.getRoomGroupType = function (obj, arr, id, dataKey) {
        var returnVal = new KeyValue();
        var roomGroup = this.jsonUtil.findJsonProperty(obj, arr[0], '');
        returnVal.key = id;
        returnVal.value = roomGroup.roomTypeName;
        return returnVal;
    };
    CardContentPreProcessorService.prototype.getRoomGroupRoomCount = function (obj, arr, id, dataKey) {
        var returnVal = new KeyValue();
        var roomCount = 0;
        var roomGroup = this.jsonUtil.findJsonProperty(obj, arr[0], '');
        roomCount = roomGroup.noOfUnits;
        var data = '';
        if (roomCount === 1) {
            data = roomCount + ' Room';
        }
        else {
            data = roomCount + ' Rooms';
        }
        returnVal.key = id;
        returnVal.value = data;
        return returnVal;
    };
    /**
     * Tax Scheme
     * @param obj
     * @param arr
     * @param id
     * @param dataKey
     */
    CardContentPreProcessorService.prototype.getTaxSchemeSummary = function (obj, arr, id, dataKey) {
        console.log('hit tax');
        var returnVal = new KeyValue();
        var taxSchemeCount = 0;
        var addresses = this.jsonUtil.findJsonProperty(obj, arr[0], '');
        addresses.forEach(function (item) {
            taxSchemeCount++;
        });
        var data = '';
        if (taxSchemeCount === 1) {
            data = taxSchemeCount + ' Tax Scheme';
        }
        else {
            data = taxSchemeCount + ' Tax Schemes';
        }
        returnVal.key = id;
        returnVal.value = data;
        return returnVal;
    };
    /**
     * document summary
     * @param obj
     * @param arr
     * @param id
     * @param dataKey
     */
    CardContentPreProcessorService.prototype.getDocumentSummary = function (obj, arr, id, dataKey) {
        var returnVal = new KeyValue();
        var documentCount = 0;
        var addresses = this.jsonUtil.findJsonProperty(obj, arr[0], '');
        addresses.forEach(function (item) {
            documentCount++;
        });
        var data = '';
        if (documentCount === 1) {
            data = documentCount + ' Document';
        }
        else {
            data = documentCount + ' Documents';
        }
        returnVal.key = id;
        returnVal.value = data;
        return returnVal;
    };
    CardContentPreProcessorService.prototype.processIcon = function (obj, arr, id) {
        var returnVal = new KeyValue();
        var iconView = new Icon();
        var address = this.jsonUtil.findJsonProperty(obj, arr[0], '');
        var iconObj = arr[0];
        if (iconObj && address) {
            iconView.key = iconObj['key'];
            iconView.iconType = iconObj['iconType'];
            var validStyle = (address.firstName && address.category) ?
                iconObj['activeClass'] :
                iconObj['inactiveClass'];
            iconView.style = iconObj['style'].concat(' ', validStyle);
            iconView.value = iconObj['value'];
            iconView.activeClass = iconObj['activeClass'];
            iconView.inactiveClass = iconObj['inactiveClass'];
            returnVal.key = id;
            returnVal.value = iconView;
            return returnVal;
        }
    };
    CardContentPreProcessorService.prototype.processBookingIcon = function (obj, arr, id) {
        var returnVal = new KeyValue();
        var iconView = new Icon();
        var address = this.jsonUtil.findJsonProperty(obj, arr[0], '');
        var iconObj = arr[0];
        if (iconObj && address) {
            iconView.key = iconObj['key'];
            iconView.iconType = iconObj['iconType'];
            var validStyle = iconObj['activeClass'];
            iconView.style = iconObj['style'].concat(' ', validStyle);
            iconView.value = iconObj['value'];
            iconView.activeClass = iconObj['activeClass'];
            iconView.inactiveClass = iconObj['inactiveClass'];
            returnVal.key = id;
            returnVal.value = iconView;
            return returnVal;
        }
    };
    /**
     * process client vip level
     * @param obj
     * @param arr
     * @param id
     * @param dataKey
     */
    CardContentPreProcessorService.prototype.processViplevel = function (obj, arr, id, dataKey) {
        var returnVal = new KeyValue();
        var data = null;
        var vipFlag = this.jsonUtil.findJsonProperty(obj, arr[0], '');
        if (vipFlag && 'boolean' === typeof (vipFlag) && vipFlag === true) {
            data = 'vip';
        }
        returnVal.key = id;
        returnVal.value = data;
        return returnVal;
    };
    CardContentPreProcessorService.prototype.processSummaryIcon = function (product, arr, id, dataKey) {
        var iconView = new Icon();
        var returnVal = new KeyValue();
        var iconObj = arr[0];
        if (iconObj) {
            iconView.key = iconObj['key'];
            iconView.iconType = iconObj['iconType'];
            iconView.style = iconObj['style'];
            iconView.value = iconObj['value'];
        }
        returnVal.key = id;
        returnVal.value = iconView;
        return returnVal;
    };
    CardContentPreProcessorService.prototype.getDataObject = function (product, arr, id) {
        var returnVal = new KeyValue();
        returnVal.key = id;
        returnVal.value = product;
        return returnVal;
    };
    CardContentPreProcessorService.prototype.getNoteSummary = function (obj, arr, id, dataKey) {
        var e_1, _a;
        var returnVal = new KeyValue();
        var noteCount = 0;
        var notes = this.jsonUtil.findJsonProperty(obj, arr[0], '');
        try {
            for (var notes_1 = tslib_1.__values(notes), notes_1_1 = notes_1.next(); !notes_1_1.done; notes_1_1 = notes_1.next()) {
                var item = notes_1_1.value;
                noteCount++;
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (notes_1_1 && !notes_1_1.done && (_a = notes_1.return)) _a.call(notes_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        var data = '';
        if (noteCount === 1) {
            data = noteCount + ' Note';
        }
        else {
            data = noteCount + ' Notes';
        }
        returnVal.key = id;
        returnVal.value = data;
        return returnVal;
    };
    CardContentPreProcessorService.prototype.processNoteIcon = function (payload, arr, id) {
        var returnVal = new KeyValue();
        var iconView = new Icon();
        var note = payload;
        var iconObj = null;
        if (note !== null && note.type && arr.length > 1) {
            iconObj = arr[1];
        }
        else if (note !== null && arr.length > 0) {
            iconObj = arr[0];
        }
        if (iconObj) {
            iconView.key = iconObj['key'];
            iconView.iconType = iconObj['iconType'];
            iconView.style = iconObj['style'];
            var noteType = this.jsonUtil.findJsonProperty(payload, iconObj['value'].split('.'), null);
            if (noteType) {
                switch (noteType) {
                    case NoteType.TYPE.BK:
                        iconView.value = NoteType.TYPE[NoteType.TYPE.BK];
                        break;
                    case NoteType.TYPE.SU:
                        iconView.value = NoteType.TYPE[NoteType.TYPE.SU];
                        break;
                    case NoteType.TYPE.SV:
                        iconView.value = NoteType.TYPE[NoteType.TYPE.SV];
                        break;
                    case NoteType.TYPE.SP:
                        iconView.value = NoteType.TYPE[NoteType.TYPE.SP];
                        break;
                    case NoteType.TYPE.IN:
                        iconView.value = NoteType.TYPE[NoteType.TYPE.IN];
                        break;
                    case NoteType.TYPE.IT:
                        iconView.value = NoteType.TYPE[NoteType.TYPE.IT];
                        break;
                    case NoteType.TYPE.VO:
                        iconView.value = NoteType.TYPE[NoteType.TYPE.VO];
                        break;
                    case NoteType.TYPE.TI:
                        iconView.value = NoteType.TYPE[NoteType.TYPE.TI];
                        break;
                    case NoteType.TYPE.DC:
                        iconView.value = NoteType.TYPE[NoteType.TYPE.DC];
                        break;
                }
            }
            returnVal.key = id;
            returnVal.value = iconView;
            return returnVal;
        }
    };
    /**
     * process note modified date
     * @param payload
     * @param {any[]} arr
     * @param {string} id
     * @returns {KeyValue}
     */
    CardContentPreProcessorService.prototype.processNoteDate = function (payload, arr, id) {
        var returnVal = new KeyValue();
        var data = this.jsonUtil.findJsonProperty(payload, arr, null);
        var modifiedDate = data ? data.split('T')[0] : '';
        if (modifiedDate) {
            returnVal.value = this.dataFormatterUtilService.formatDate(modifiedDate, TC.FORMAT.DATE_FORMAT_dd_MMM_yy);
        }
        returnVal.key = id;
        return returnVal;
    };
    CardContentPreProcessorService.prototype.processNote = function (data, arr, id) {
        var keyValue = this.getKeyValue(data, arr, id);
        return this.limitValueCharacters(keyValue, 25);
    };
    CardContentPreProcessorService.prototype.processDaysOfWeek = function (data, arr, id) {
        var keyValue = this.getKeyValue(data, arr, id);
        return this.limitValueCharacters(keyValue, 40);
    };
    CardContentPreProcessorService.prototype.processCost = function (data, arr, id) {
        var keyValue = this.getKeyValue(data, arr, id);
        return this.limitValueCharacters(keyValue, 90);
    };
    CardContentPreProcessorService.prototype.processDriverName = function (data, arr, id) {
        var returnVal = new KeyValue();
        returnVal.key = id;
        var dataArray = this.processKeyArray(data, arr, id);
        returnVal.value = dataArray[0] + ' ' + dataArray[1];
        return returnVal;
    };
    CardContentPreProcessorService.prototype.processKeyArray_SupplierCost = function (data, arr, id) {
        var returnVal = new KeyValue();
        returnVal.key = id;
        if (arr) {
            var value = this.jsonUtil.getRawData(data, arr);
            if (value || parseInt(value) == 0) {
                returnVal.value = value;
            }
            else {
                returnVal.value = null;
            }
        }
        return returnVal;
    };
    CardContentPreProcessorService.prototype.processYearsOfExperience = function (data, arr, id) {
        var keyValue = this.getKeyValue(data, arr, id);
        var prefix = '<span class="tc-strip-text__label">Experience: </span>';
        var postfix = ' years';
        this.addPrefixPostfix(keyValue, prefix, postfix);
        return keyValue;
    };
    CardContentPreProcessorService.prototype.processLanguages = function (data, arr, id) {
        var values = this.jsonUtil.getDataArray(data, arr);
        var keyValue = this.getKeyValue(data, arr, id);
        if (Array.isArray(values)) {
            keyValue.value = this.getStrOfArray(values);
        }
        else {
            keyValue.value = values;
        }
        var maxLength = 100;
        var prefix = '<span class="tc-strip-text__label">Languages: </span>';
        var postfix = '';
        if (keyValue.value) {
            this.addPrefixPostfix(keyValue, prefix, postfix);
        }
        this.limitValueCharacters(keyValue, 90);
        return keyValue;
    };
    CardContentPreProcessorService.prototype.processPreferredTransferModes = function (data, arr, id) {
        var values = this.jsonUtil.getDataArray(data, arr);
        var keyValue = this.getKeyValue(data, arr, id);
        if (Array.isArray(values)) {
            keyValue.value = this.getStrOfArray(values);
        }
        else {
            keyValue.value = values;
        }
        var maxLength = 100;
        var prefix = '<span class="tc-strip-text__label">Transfer Modes: </span>';
        var postfix = '';
        if (keyValue.value) {
            this.addPrefixPostfix(keyValue, prefix, postfix);
        }
        this.limitValueCharacters(keyValue, 90);
        return keyValue;
    };
    CardContentPreProcessorService.prototype.processPreferredRoute = function (data, arr, id) {
        var keyValue = this.getKeyValue(data, arr, id);
        var prefix = '<span class="tc-strip-text__label">Route: </span>';
        var postfix = '';
        this.addPrefixPostfix(keyValue, prefix, postfix);
        return keyValue;
    };
    CardContentPreProcessorService.prototype.processTransferMode = function (data, arr, id) {
        var keyValue = this.getKeyValue(data, arr, id);
        var prefix = '<span class="tc-strip-text__label">Transfer Mode: </span>';
        var postfix = '';
        this.addPrefixPostfix(keyValue, prefix, postfix);
        return keyValue;
    };
    CardContentPreProcessorService.prototype.processWorkingHours = function (data, arr, id) {
        var keyValue = this.getKeyValue(data, arr, id);
        var prefix = '<span class="tc-strip-text__label">Working Hours: </span>';
        var postfix = '';
        this.addPrefixPostfix(keyValue, prefix, postfix);
        return keyValue;
    };
    CardContentPreProcessorService.prototype.processGrade = function (data, arr, id) {
        var keyValue = this.getKeyValue(data, arr, id);
        var prefix = '<span class="tc-strip-text__label">Grade: </span>';
        var postfix = '';
        this.addPrefixPostfix(keyValue, prefix, postfix);
        return keyValue;
    };
    CardContentPreProcessorService.prototype.processDriver = function (data, arr, id) {
        var keyValue = this.getKeyValue(data, arr, id);
        var prefix = '<span class="tc-strip-text__label">Driver: </span>';
        var postfix = '';
        this.addPrefixPostfix(keyValue, prefix, postfix);
        return keyValue;
    };
    CardContentPreProcessorService.prototype.processLicensePlate = function (data, arr, id) {
        var keyValue = this.getKeyValue(data, arr, id);
        var prefix = '<span class="tc-strip-text__label">License Plate: </span>';
        var postfix = '';
        this.addPrefixPostfix(keyValue, prefix, postfix);
        return keyValue;
    };
    CardContentPreProcessorService.prototype.processVehicleModel = function (data, arr, id) {
        var keyValue = this.getKeyValue(data, arr, id);
        var prefix = '<span class="tc-strip-text__label">Model: </span>';
        var postfix = '';
        this.addPrefixPostfix(keyValue, prefix, postfix);
        return keyValue;
    };
    CardContentPreProcessorService.prototype.processCapacity = function (data, arr, id) {
        var keyValue = this.getKeyValue(data, arr, id);
        var prefix = '<span class="tc-strip-text__label">Capacity: </span>';
        var postfix = '';
        this.addPrefixPostfix(keyValue, prefix, postfix);
        return keyValue;
    };
    CardContentPreProcessorService.prototype.processPhoneNumber = function (data, arr, id) {
        var keyValue = this.getKeyValue(data, arr, id);
        var prefix = '<span class="tc-strip-text__label">Phone: </span>';
        var postfix = '';
        this.addPrefixPostfix(keyValue, prefix, postfix);
        return keyValue;
    };
    CardContentPreProcessorService.prototype.processCity = function (data, arr, id) {
        var keyValue = this.getKeyValue(data, arr, id);
        var prefix = '<span class="tc-strip-text__label">City: </span>';
        var postfix = '';
        this.addPrefixPostfix(keyValue, prefix, postfix);
        return keyValue;
    };
    CardContentPreProcessorService.prototype.processDescription = function (data, arr, id) {
        var prefix = '';
        var processData = [];
        if (data[arr['param1']['value']] !== null) {
            prefix = arr['param1']['key'] + ": ";
            processData.push(arr['param1']['value']);
            arr = [];
            arr.push(processData);
        }
        else {
            prefix = arr['param2']['key'] + ": ";
            processData.push(arr['param2']['value']);
            arr = [];
            arr.push(processData);
        }
        var keyValue = this.getKeyValue(data, arr, id);
        var postfix = '';
        this.addPrefixPostfix(keyValue, prefix, postfix);
        return keyValue;
    };
    // reservation v2
    CardContentPreProcessorService.prototype.processKeyArray_cost = function (resource, arr, id) {
        var returnVal = new KeyValue();
        returnVal.key = id;
        var dataArray = this.processKeyArray(resource.calculatedCost, arr, id);
        if (dataArray && dataArray.length) {
            returnVal.value = parseFloat(dataArray[0]);
        }
        return returnVal;
    };
    CardContentPreProcessorService.prototype.processKeyArray_processSupplierPerCost = function (resource, arr, id) {
        return this.processKeyArray_processPerPrice(resource.calculatedCost, arr, id);
    };
    return CardContentPreProcessorService;
}());
export { CardContentPreProcessorService };
