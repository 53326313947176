import { OnInit } from '@angular/core';
import { DialogModel, LEVEL } from '@tc-core/model/it/codegen/ui/framework/dialog-model';
import { ModalData } from '@tc-core/model/it/codegen/ui/framework/modal-data';
import { SummaryCardData } from '@tc-core/model/it/codegen/ui/framework/summary-card-data';
import { TC } from '@tc-core/util/constants';
import { ConfigLoader } from '@tc-core/util/framework/config-loader.service';
import { EventManager } from '@tc-core/util/framework/event-manager.service';
import { UserJourneyManager } from '@tc-core/util/framework/user-journey-manager.service';
import { SpinnerService } from '@tc-core/util/ui/spinner.service';
import { ModalService } from '@tc/modal/modal.service';
import { DateRange } from '../../../../widgets/framework/cg-calendar/date-range';
import { RangeGroup } from '../../../../widgets/framework/cg-calendar/range-group';
import { DialogService } from '@tc/dialog/dialog.service';
import { FocusViewService } from '@tc/focus-view/focus-view.service';
import { TCO } from '../../../../constants';
import { SeasonSetupSummaryComponent } from '../../../../widgets/framework/cg-season-calendar/season-setup-summary/season-setup-summary.component';
import { TrsResourceCostSetup } from '../../../../models/supplier/trs-resource-cost-setup';
import { DataHandlerService } from '../../../../services/backend-consumers/master-data-handler-service/data-handler.service';
import { DataKey, DataStoreService } from '../../../../services/util/framework/data-store.service';
import { CardDataHandlerService } from '../../../../services/util/ui/card-data-handler-service.service';
import { ContentSummaryCardService } from '../../../../services/util/ui/content-summary-card.service';
var TransportCostSetupFocusViewComponent = /** @class */ (function () {
    function TransportCostSetupFocusViewComponent(em, configLoader, journeyManager, dataStore, focusViewService, summaryCardService, dataHandlerService, spinnerService, dialogService, cardDataHandlerService, modalService) {
        this.em = em;
        this.configLoader = configLoader;
        this.journeyManager = journeyManager;
        this.dataStore = dataStore;
        this.focusViewService = focusViewService;
        this.summaryCardService = summaryCardService;
        this.dataHandlerService = dataHandlerService;
        this.spinnerService = spinnerService;
        this.dialogService = dialogService;
        this.cardDataHandlerService = cardDataHandlerService;
        this.modalService = modalService;
        this.supplierCostSetup = [];
        this.resourceCosts = [];
        this.vehicleModels = [];
        this.heading = '';
        this.routes = [];
        this.transferModes = [];
        this.seasons = [];
        this.addNew = false;
        this.addNewEditableStatus = false;
        this.isEdit = true;
        this.daysOfWeek = [];
        this.resourceCostCardContent = [];
        this.trsResourceCosts = [];
        this.transferModeList = [];
        this.routeList = [];
        this.filterSeason = 0;
        //calendar
        this.fromYear = 0;
        this.toYear = 0;
        this.rangeGroupsForSeasonCalendar = [];
        this.CARD_CONFIG_TRANSPORT_EDIT = 'supplier_edit';
    }
    TransportCostSetupFocusViewComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.initPageSectionReferences();
        this.mapToSeasonCalendarRanges();
        this.tResourceCosts = this.configLoader.configurations.get(TCO.CONF.CONF_TRANSFER_RESOURCE_COST);
        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'CLOSE_FOCUS_VIEW').subscribe(function (e) {
            _this.dataStore.set(DataKey.supplierCostFocusViewClose, null);
            _this.dataStore.set(DataKey.supplierCostFocusViewClose, true);
            _this.saveResourceCostData();
            _this.focusViewService.close(true);
            _this.journeyManager.goForKey('GO_TO_ADD_TRANSFER_SUPPLIER');
        });
        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'ADD_NEW_COST_SETUP').subscribe(function (e) {
            _this.addNewCode();
        });
        this.dataStore.get(DataKey.routes).subscribe(function (data) {
            if (data !== null) {
                _this.routeList = data;
                _this.routes.push({ 'code': '', 'value': 'All Routes', 'isSelected': true });
                data.forEach(function (val) {
                    _this.routes.push({ 'code': val, 'value': val.code, 'isSelected': false });
                });
            }
            else {
                _this.routes = [];
            }
        });
        var x = this.dataStore.get(DataKey.transferModes).subscribe(function (data) {
            if (data !== null) {
                _this.transferModeList = data;
                _this.transferModes.push({ 'code': 'any', 'value': 'All Transfer Modes', 'isSelected': true });
                data.forEach(function (val) {
                    _this.transferModes.push({ 'code': val, 'value': val.name, 'isSelected': false });
                });
            }
            else {
                _this.transferModes = [];
            }
        });
        if (this.supplierCostSetup !== null && this.supplierCostSetup.length > 0) {
            this.seasons.push({ 'code': 0, 'value': 'Any Season', 'isSelected': true });
            for (var i = 0; i < this.supplierCostSetup.length; i++) {
                this.seasons.push({
                    'code': this.supplierCostSetup[i].tempSeasonId,
                    'value': this.supplierCostSetup[i].name,
                    'isSelected': false
                });
            }
        }
        this.dataHandlerService.retrieveDaysOfWeek().subscribe(function (days) {
            if (days !== null) {
                _this.daysOfWeek = days.data;
                if (_this.resourceCosts.length === 0) {
                    _this.addNewCode();
                }
            }
        });
        this.resourceCostCardContent = this.getCostSummaryCards();
    };
    TransportCostSetupFocusViewComponent.prototype.initPageSectionReferences = function () {
        this.pageSectionsMap = this.cardDataHandlerService.getAllPageSections(this.CARD_CONFIG_TRANSPORT_EDIT, this.resourceCosts);
        this.costAddSection = this.pageSectionsMap.get('supplier_transport_cost_setup');
        // this.setupHeader();
        // this.filterPageSectionActions();
    };
    TransportCostSetupFocusViewComponent.prototype.mapToSeasonCalendarRanges = function () {
        // this.rangeGroupsForSeasonCalendar = [];
        for (var i = 0; i < this.supplierCostSetup.length; i++) {
            var season = this.supplierCostSetup[i];
            var rangeGroup = new RangeGroup();
            rangeGroup.id = season.tempSeasonId;
            rangeGroup.name = season.name;
            rangeGroup.colorId = season.colorId;
            var dateRanges = [];
            for (var j = 0; j < season.seasonDateRanges.length; j++) {
                var seasonDateRange = season.seasonDateRanges[j];
                var dateRange = new DateRange();
                dateRange.id = seasonDateRange.dateRangeId;
                dateRange.startDate = new Date(seasonDateRange.startDate);
                dateRange.endDate = new Date(seasonDateRange.endDate);
                dateRanges.push(dateRange);
            }
            rangeGroup.dateRanges = dateRanges;
            this.rangeGroupsForSeasonCalendar.push(rangeGroup);
        }
    };
    TransportCostSetupFocusViewComponent.prototype.onChangeCostSetupForm = function (event) {
        this.dataStore.set(DataKey.supplierTrsCostSetupValid, event, true);
    };
    TransportCostSetupFocusViewComponent.prototype.saveResourceCostData = function () {
        this.dataStore.set(DataKey.trsResourceCosts, null, true);
        this.dataStore.set(DataKey.trsResourceCosts, this.resourceCosts, true);
    };
    TransportCostSetupFocusViewComponent.prototype.getDays = function (data) {
        var days = '';
        var _loop_1 = function (i) {
            var dayArray = this_1.daysOfWeek.find(function (day) { return day.key === data.charAt(i); }).value;
            days = days.concat(',' + dayArray);
        };
        var this_1 = this;
        for (var i = 0; i < data.length; i++) {
            _loop_1(i);
        }
        if (days.startsWith(',')) {
            days = days.replace(',', '');
        }
        return days;
    };
    TransportCostSetupFocusViewComponent.prototype.getCostSummaryCards = function () {
        var summaryCards = this.summaryCardService.getContentCardsByConfig(this.tResourceCosts, this.resourceCosts);
        this.spinnerService.hide();
        return summaryCards;
    };
    TransportCostSetupFocusViewComponent.prototype.onSelectTransferMode = function (event) {
        if (event.code !== 'any') {
            this.filterTransferMode = event.code;
        }
        else {
            this.filterTransferMode = null;
        }
    };
    TransportCostSetupFocusViewComponent.prototype.onSelectRoute = function (event) {
        if (event.code !== 'any') {
            this.filterRoute = event.code;
        }
        else {
            this.filterRoute = null;
        }
    };
    TransportCostSetupFocusViewComponent.prototype.onSelectSeason = function (event) {
        if (event !== 0) {
            this.filterSeason = event;
        }
        else {
            this.filterSeason = 0;
        }
    };
    TransportCostSetupFocusViewComponent.prototype.addNewCode = function () {
        window.scrollTo(0, 0);
        if (this.seasons.length > 0) {
            this.addNew = true;
            var resourceCost = new TrsResourceCostSetup();
            resourceCost.tempCostId = this.getNextTempId();
            resourceCost.isEdit = true;
            resourceCost.isNew = true;
            this.resourceCosts.unshift(resourceCost);
            // this.resourceCosts.splice(0, 0, resourceCost);
            this.resourceCostCardContent.unshift(new SummaryCardData());
        }
        else {
            var confirmError = new DialogModel(true, LEVEL.ERROR, 'Failed', 'Please add seasons', true, 2000);
            this.dialogService
                .confirm(confirmError)
                .subscribe(function (res) {
                if (res === true) {
                    console.log('true');
                }
            });
        }
    };
    TransportCostSetupFocusViewComponent.prototype.getNextTempId = function () {
        var maxId = 0;
        for (var j = 0; j < this.resourceCosts.length; j++) {
            var resourceCost = this.resourceCosts[j];
            var tempId = resourceCost.tempCostId;
            if (tempId > maxId) {
                maxId = tempId;
            }
        }
        maxId++;
        return maxId;
    };
    TransportCostSetupFocusViewComponent.prototype.onNewCostSaveClick = function (event) {
        var _this = this;
        this.resourceCosts.forEach(function (data) {
            if (data.tempCostId == event.tempCostId) {
                data.isEdit = false;
                data.seasonName = _this.supplierCostSetup.find(function (data) { return data.tempSeasonId === event.tempSeasonId; }).name;
                event.dayKeys = event.dayKeys.split('').sort(function (a, b) { return a < b ? -1 : 1; }).join('');
                data.dayValues = _this.getDays(event.dayKeys);
            }
        });
        this.resourceCostCardContent = this.getCostSummaryCards();
    };
    TransportCostSetupFocusViewComponent.prototype.onDeleteClick = function (event) {
        for (var i = 0; i < this.resourceCosts.length; i++) {
            if (this.resourceCosts[i].tempCostId === event) {
                this.resourceCosts.splice(i, 1);
                this.resourceCostCardContent.splice(i, 1);
                break;
            }
        }
        // this.resourceCosts.splice(this.resourceCosts.findIndex(event), 1);
        // this.trsResourceCosts.splice(this.trsResourceCosts.findIndex(event), 1);
    };
    TransportCostSetupFocusViewComponent.prototype.onEditClick = function (event) {
        this.isEdit = true;
        this.selectedResourceCost = event;
        this.resourceCosts.find(function (data) { return data.tempCostId == event.tempCostId; }).isEdit = true;
        this.resourceCosts.find(function (data) { return data.tempCostId == event.tempCostId; }).isNew = false;
    };
    TransportCostSetupFocusViewComponent.prototype.onRevertClick = function (event) {
        var _this = this;
        this.selectedResourceCost = event;
        this.resourceCosts.forEach(function (data) {
            if (data.tempCostId == event.tempCostId) {
                data.adultFare = event.adultFare;
                data.dayKeys = event.dayKeys;
                data.childFare = event.childFare;
                data.infantFare = event.infantFare;
                data.unitFare = event.unitFare;
                data.route = event.route;
                data.transferMode = event.transferMode;
                data.isEdit = false;
                data.tempSeasonId = event.tempSeasonId;
                data.seasonName = _this.supplierCostSetup.find(function (data) { return data.tempSeasonId === event.tempSeasonId; }).name;
                event.dayKeys = event.dayKeys.split('').sort(function (a, b) { return a < b ? -1 : 1; }).join('');
                data.dayValues = _this.getDays(event.dayKeys);
            }
        });
        this.resourceCostCardContent = this.getCostSummaryCards();
    };
    TransportCostSetupFocusViewComponent.prototype.showSeasonSummary = function (event) {
        var dataObject = new ModalData(LEVEL.SUCCESS, 'Season Setup Summary', null, { 'label': 'CLOSE' }, 'modal-basic--fixed-size modal-basic--show-footer', SeasonSetupSummaryComponent, { rangeGroups: this.rangeGroupsForSeasonCalendar });
        this.modalService
            .confirm(dataObject).subscribe(function (res) {
            if (res) {
            }
        });
    };
    return TransportCostSetupFocusViewComponent;
}());
export { TransportCostSetupFocusViewComponent };
