<tc-setup-grid
    #notificationSetupGrid
    [readOnly]="true"
    [noSelection]="true"
    [colDefConfig]="colDefConfig"
    [defaultColDef]="defaultColDef"
    [setupGridComp]="this"
    [rowClassRules]="rowClassRules"
    [actions]="gridActions"
    [headerActions]="headerActions"
    [dataSource]="this"
    [autoSize]="false"
    (rowClicked)="onRawClick($event)">
</tc-setup-grid>

<!--type column view-->
<ng-template #typeCell let-row>
    <span *ngIf="row?.type && row.type===ASSIGNMENT_UPDATE">
<!--            <i class="material-icons">assignment_turned_in</i>-->
        <span
                *ngIf="row && row.indicator && row.indicator !== PENDING"
                class="tc-status-badge tc-status-badge-grid"
                [ngClass]="{
                'tc-status-badge--warning': (row.indicator === DMC_CONFIRM || row.indicator === INACTIVE_NOTIFY),
                'tc-status-badge--success': (row.indicator === SUPPLIER_CONFIRMED || row.indicator === STARTED || row.indicator === COMPLETED),
                'tc-status-badge--danger': (row.indicator === SUPPLIER_REJECTED || row.indicator === INACTIVE || row.indicator === NO_SHOW)
            }"
                style="right: 0 !important;top: -10px !important;float: left">
        <span class="tc-status-badge__text">{{statusCodes[row.indicator]}}</span>
        </span>
    </span>
    <span *ngIf="row?.type && row.type===RESOURCE_COMMUNICATION" class="tc-icon-block__icon--active">
            <i class="material-icons">chat</i>
    </span>
    <span *ngIf="row?.type && row.type===LEAVE_REQUEST" class="tc-icon-block__icon--active">
            <i class="material-icons">insert_invitation</i>
    </span>
    <span *ngIf="row?.type && row.type===NEW_USER_REGISTRATION" class="tc-icon-block__icon--active">
            <i class="material-icons">person_add</i>
    </span>
</ng-template>

<!--indicator column view-->
<ng-template #indicatorCell let-row>
    <span
            *ngIf="row?.indicator && row?.type"
            style=""
            class="tc-ag-grid-chip"
            [ngClass]="{'tc-ag-grid-chip--warning': ( row?.type === RESOURCE_COMMUNICATION ),
                                            'tc-ag-grid-chip--success': ( row?.type === LEAVE_REQUEST ),
                                            'tc-ag-grid-chip--danger': ( row?.type === NEW_USER_REGISTRATION || row?.type === ASSIGNMENT_UPDATE )
                                            }">

        <span>{{row?.indicator}}</span>
    </span>
    <!--    <span *ngIf="row?.indicator && row?.type">{{typeString[row.type]}}</span>-->
</ng-template>

<!--generated by cell-->
<ng-template #resourceTypeCell let-row>
    <span *ngIf="row?.type">
            {{row?.serviceType}}
    </span>
</ng-template>

<ng-template #descriptionCell let-row>
    <ng-container *ngIf="row">
        <ng-container *ngIf="row?.description">
            <span class="tc-mr-3"><strong>{{row.description}}</strong></span>
        </ng-container>
        <ng-container *ngIf="row?.supplierName">
            <span class="tc-mr-3" title="Supplier">
             <span class="tc-card-icon--member">
                <mat-icon class="tc-value">person</mat-icon>
            </span>
            <span>{{row.supplierName}}</span>
            </span>
        </ng-container>
        <ng-container *ngIf="row?.resourceName && row?.resourceName!=='null'">
            <span class="tc-mr-3" title="Resource">
                <span class="tc-card-icon--generic">
                    <mat-icon class="tc-value">fa_cubes</mat-icon>
                </span>
                <span>{{row.resourceName}}</span>
                <ng-container *ngIf="row?.resourceId">
                    <span>( {{row.resourceId}} )</span>
                </ng-container>
                <ng-container *ngIf="row?.sendingResourceId">
                    <span>( {{row.sendingResourceId}} )</span>
                </ng-container>
            </span>
        </ng-container>
        <ng-container *ngIf="row?.serviceType">
            <span class="tc-mr-3" title="Service type">
                <span class="tc-card-icon--business">
                    <mat-icon class="tc-value">work</mat-icon>
                </span>
                <span>{{row.serviceType}}</span>
            </span>
        </ng-container>
    </ng-container>
</ng-template>


<!--generated by cell-->
<ng-template #generatedOnCell let-row>
    &nbsp;{{row ? (row.modifiedOn | date:'yyyy-MM-dd HH:mm a':'GMT') : ''}}
</ng-template>

<!--action column view-->
<ng-template #actionCell let-row>
    <!--    <a *ngIf="row?.type" (click)="onClickAction(row)">{{actionString[row.type]}}</a>-->
    <button
            mat-button
            mat-icon-button
            class="tc-m-0 tc-icon-block__icon tc-icon-block__icon--active"
            aria-label="Open Resource Calendar"
            *ngIf="row?.type && actionString[row.type] == 'Open Resource Calendar'"
            [matTooltip]="'Open Resource Calendar' | translate"
            (click)="onClickAction(row)"
            [matTooltipPosition]="toolTipPosition">
        <mat-icon>today</mat-icon>
    </button>

    <button
            mat-button
            mat-icon-button
            class="tc-m-0 tc-icon-block__icon tc-icon-block__icon--active"
            aria-label="Send"
            *ngIf="row?.type && actionString[row.type] == 'Open User Queue'"
            [matTooltip]="'Open User Queue' | translate"
            (click)="onClickAction(row)"
            [matTooltipPosition]="toolTipPosition">
        <mat-icon>person</mat-icon>
    </button>

    <button
            mat-button
            mat-icon-button
            class="tc-m-0 tc-icon-block__icon tc-icon-block__icon--active"
            aria-label="Open Message Queue"
            *ngIf="row?.type && actionString[row.type] == 'Open Message Queue'"
            [matTooltip]="'Open Message Queue' | translate"
            (click)="onClickAction(row)"
            [matTooltipPosition]="toolTipPosition">
        <mat-icon>message</mat-icon>
    </button>

    <button
            mat-icon-button
            class="tc-m-0 tc-icon-block__icon tc-icon-block__icon--active"
            [matMenuTriggerFor]="menu"
            aria-label="Go to option"
            *ngIf="row?.type && actionString[row.type] == 'Open Service Queue'">
        <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
        <button mat-menu-item
                class="tc-kebab-menu-item tc-kebab-menu__item mat-menu-item"  (click)="onClickAction(row,'service')">
            <mat-icon class="tc-kebab-menu-item__icon tc-item-circle">assignment</mat-icon>
            <span class="tc-kebab-menu-item__content">{{'Open Service Queue' | translate}}</span>
        </button>
        <button mat-menu-item
                class="tc-kebab-menu-item tc-kebab-menu__item mat-menu-ite"  (click)="onClickAction(row,'message')">
            <mat-icon class="tc-kebab-menu-item__icon tc-item-circle">message</mat-icon>
            <span class="tc-kebab-menu-item__content">{{'Open Message Queue' | translate}}</span>
        </button>
    </mat-menu>
</ng-template>
