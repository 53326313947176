import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConfigLoader } from '@tc-core/util/framework';
import { ICellRendererParams } from 'ag-grid-community';
import { GridToolTipComponent } from '../../../widgets/framework/ag-grid-custom-components/renderers/tooltip/grid-tool-tip.component';
import { TCO } from '../../../constants';
import { BookingItem } from '../../../models/reservation-v2/booking-item';
import { BookingSupplement } from '../../../models/reservation-v2/booking-supplement';
import { ServiceItem } from '../../../models/reservation-v2/service-item';
import { DataKey, DataStoreService } from '../../../services/util/framework/data-store.service';

export class BookingSupplementGroup {
  bookingReferenceId: string;
  bookingReferenceIdWithoutReturnFlag: string;
  supplementNotes: BookingSupplement[] = [];
}

@Component({
  selector: 'tc-supplements-editor',
  templateUrl: './supplements-editor.component.html'
})
export class SupplementsEditorComponent implements OnInit {
  gridApi;
  constructor(
      private dataStore: DataStoreService,
      private translateService: TranslateService,
      private configLoader: ConfigLoader
  ) {
    this.translateService.onLangChange.subscribe(() => {
      this.gridApi.refreshHeader();
    });
  }

  @Input() selectedItem: BookingItem | ServiceItem;
  @Input() supplementGroupList: BookingSupplementGroup[] = [];
  @Input() bookingSupplementsOnly = false;

  bookingReferenceIdWithoutReturnFlag: any;


  defColumnDefs = {
    headerValueGetter: (parameters: ICellRendererParams): string => {
      const headerIdentifier = parameters.colDef.headerName;
      if (headerIdentifier) {
        return this.translateService.instant(headerIdentifier);
      }
      return '';
    }
  };

  bookingSupplementsColumnDefs = [
    {
      headerName: 'Supplement Category',
      field: 'supplementCategory',
      minWidth: 210,
      sortable: true,
      editable: false,
      resizable: true,
    },
    {
      headerName: 'Description',
      field: 'note',
      valueGetter(params) {
        if (params.data) {
          let note = params.data.note;
          if (note) {
            note = note.replaceAll('• ', '');
          }
          return note;
        }
      },
      minWidth: 250,
      sortable: true,
      editable: false,
      resizable: true,
    },
    {
      headerName: 'Return',
      field: 'transferType',
      width: 150,
      minWidth: 150,
      maxWidth: 150,
      cellRenderer: params => {
        const data = params.data.transferType;
        return `<input type='checkbox' ${ data === 'Return_Transfer' ? 'checked' : ''} disabled class="supplement-note-checkbox"/>`;
      }
    },
    {
      headerName: 'Cost Included',
      field: 'costIncluded',
      width: 180,
      minWidth: 180,
      maxWidth: 180,
      cellRenderer: params => {
        const data = params.data.costIncluded;
        return `<input type='checkbox' ${ data == true ? 'checked' : ''} disabled class="supplement-note-checkbox"/>`;
      }
    },
    {
      headerName: 'Free Supplement',
      field: 'freeSupplement',
      width: 190,
      minWidth: 190,
      maxWidth: 190,
      cellRenderer: params => {
        const data = params.data.freeSupplement;
        return `<input type='checkbox' ${ data == true ? 'checked' : ''} disabled class="supplement-note-checkbox"/>`;
      }
    },
    {
      headerName: 'Pay Supplier Directly',
      field: 'paySupplierDirectly',
      width: 220,
      minWidth: 220,
      maxWidth: 220,
      cellRenderer: params => {
        const data = params.data.paySupplierDirectly;
        return `<input type='checkbox' ${ data == true ? 'checked' : ''} disabled class="supplement-note-checkbox"/>`;
      }
    }
  ];

  public frameworkComponents: any = {
    tooltipComponent: GridToolTipComponent
  };

  ngOnInit() {
    this.bookingReferenceIdWithoutReturnFlag = this.configLoader.configurations
                                                   .get(TCO.CONF.CONF_PAGE_OPTION_AVAILABILITY).BOOKING_MANAGEMENT;

    if (this.bookingReferenceIdWithoutReturnFlag
        && this.bookingReferenceIdWithoutReturnFlag.bookingItemReferenceWithReturnFlag !== true
        && this.supplementGroupList) {
      this.supplementGroupList.forEach(group => {
        if (group.bookingReferenceId) {
          group.bookingReferenceIdWithoutReturnFlag = group.bookingReferenceId
              .replace('_R', '')
              .replace(/[_][S][0-9]+/g, '');
        }
      });
    }
  }

  public onSave() {
    this.dataStore.set(DataKey.popupClose, true);
  }

  public onClose($event: MouseEvent) {
    this.dataStore.set(DataKey.popupClose, false);
  }

  public isSaveButtonEnable() {
    return true;
  }

  public onGridReadyAssignmentSupplements(params: any) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  }

  onFirstDataRendered(params) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  }
}
