<tc-dmc-spinner-wrapper></tc-dmc-spinner-wrapper>
<tc-result-header
        [transferModeList]="transferModes"
        [groupingOptionList]="groupingOptions"
        [heading]="heading"
        [sorters]="sorters_"
        (selectedTransferMode)="onTransferModeSelect($event)"
        (selectedGroupingType)="onGroupingTypeSelect($event)"
        (selectSorter)="onSort($event)">
</tc-result-header>
<tc-adv-reservation-search-results
        [sortingHandler]="sorterService.method"
        [sorter]="sorterService.sorter"
        [sorters]="sorters_"
        [exit]="exit"
        [serviceItemGroupCards]="serviceItemGroupCards"
        [detailReservation]="serviceItemsInSelectedGroup"
        [selectedReservation]="selectedSummaryCardFromGroups"
        [selectedServiceGroupNotes]="selectedServiceGroupNotes"

        [reservationServiceItemCards]="serviceItemsInSelectedGroupCards"
        [supplierList]="allSupplierList"
        [resourceList]="allResourceList"
        [serviceItemChangesInSelectedGroup]="serviceItemChangesInSelectedGroup"
        [filteredVehicleSuppliers]="filteredVehicleSuppliers"
        [filteredDriverSuppliers]="filteredDriverSuppliers"
        [filteredVehicles]="filteredVehicles"
        [filteredDrivers]="filteredDrivers"
        [changesAvailableForServiceItems]="changesAvailableForServiceItems"
        [pager]="pager"
        [isRequestCompleted]="isRequestCompleted"
        (reservationSelect)="onReservationGroupSelect($event)"
        (selection)="onSelection($event)"
        (moreClick)="onMoreClick($event)"
        (historyClick)="onHistoryClick($event)"
        (splitClick)="onSplitClick($event)"
        (leadPassengerClick)="onLeadPassengerClick($event)"
        (confirmClick)="onConfirmClick($event)"
        (clickSupplierReject)="onSupplierRejectClick($event)"
        (saveAssignments)="onSaveAssignments($event)"
        (clickMerge)="onClickMerge()"
        (vehicleAssign)="onSelectVehicle($event)"
        (driverAssign)="onSelectDriver($event)"
        (vehicleClear)="clearVehicle($event)"
        (driverClear)="clearDriver($event)"
        (autoAssignResources)="onAutoAssignResources($event)"
        (vehicleTextChange)="onVehicleTextChange($event)"
        (driverTextChange)="onDriverTextChange($event)"
        (vehicleTextFocus)="onVehicleFocus($event)"
        (serviceGroupNote)="addGroupNote()"
        (emptyServiceGroupNote)="addEmptyGroupNote()"
        (saveNote)="onNoteSave($event)"
        (deleteNote)="onDelete($event)"
        (isEditClicked)="onEdit($event)"
        (viewMoreFocus)="onViewMoreClick($event)"
        (cardScroll)="onCardScroll($event)"
        (vehicleSupplierAssign)="onSelectVehicleSupplier($event)"
        (driverSupplierAssign)="onSelectDriverSupplier($event)"
        (vehicleSupplierClear)="clearVehicleSupplier($event)"
        (driverSupplierClear)="clearDriverSupplier($event)"
        (driverSupplierTextChange)="onDriverSupplierTextChange($event)"
        (vehicleSupplierTextChange)="onVehicleSupplierTextChange($event)"
        (vehicleJobNoChange)="onVehicleJobNoChange($event)"
        (driverJobNoChange)="onDriverJobNoChange($event)"
        (clearVehicleAssignment)="onClearVehicleAssignment($event)"
        (clearDriverAssignment)="onClearDriverAssignment($event)"
        (bulkDriverAssignment)="onBulkDriverAssignment()"
        (bulkVehicleAssignment)="onBulkVehicleAssignment()"
>
</tc-adv-reservation-search-results>
<tc-paginator
        *ngIf="serviceItemGroupCards.length > defaultPageResultCount"
        [length]="serviceItemGroupCards.length"
        [index]="pager.currPage"
        [size]="pager.itemsPerPage"
        [hasMore]="paginatorHasMore"
        (page)="onPage($event)"
        (more)="onMore()">
</tc-paginator>
