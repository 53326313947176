import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SecurityModule } from '@tc/security';
import { TranslateModule } from '@ngx-translate/core';
import { SupplierPersonalInfoCardComponent } from './supplier-personal-info-card.component';
import {MatTooltipModule} from '@angular/material';

@NgModule({
    imports: [
        TranslateModule.forChild(),
        CommonModule,
        SecurityModule,
        MatTooltipModule
    ],
    exports: [
        SupplierPersonalInfoCardComponent
    ],
    declarations: [
        SupplierPersonalInfoCardComponent
    ],
    providers: [],
})
export class SupplierPersonalInfoCardModule {
}
