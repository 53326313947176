import { Component, OnInit } from '@angular/core';
import { ConfigLoader } from '@tc-core/util/framework';
import { TCO } from '../../../../constants';

@Component({
    selector: 'tc-manifest-search-results',
    templateUrl: './manifest-search-results.component.html'
})
export class ManifestSearchResultsComponent implements OnInit
{

    colDefConfig = [];

    constructor(
        private configLoader: ConfigLoader
    )
    { }

    ngOnInit()
    {
        this.colDefConfig = this.loadGridConfig();
    }

    private loadGridConfig()
    {
        return this.configLoader.configurations.get(TCO.CONF.CONF_MANIFESTS_GRID);
    }
}
