import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { TcErrorType } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-error';
import { DialogModel, LEVEL } from '@tc-core/model/it/codegen/ui/framework/dialog-model';
import { ConfigLoader } from '@tc-core/util/framework/config-loader.service';
import { SpinnerService } from '@tc-core/util/ui/spinner.service';
import { DialogService } from '@tc/dialog/dialog.service';
import { of, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { TCO } from '../../../constants';
import { DivisionSearchCriteria } from '../../../models/criteria/division-search-criteria';
import { SortDirection } from '../../../models/helper/sort-direction';
import { MasterDataRestService } from '../../../services/backend-consumers/master-data-handler-service/master-data-rest.service';
import { CostingTypeService } from '../../../services/backend-consumers/setups/costing-type-service';
import { DivisionSetupService } from '../../../services/backend-consumers/setups/division-setup-service';
import { UserManagementService } from '../../../services/user-management/user-management.service';
import { DMCCommon } from '../../../services/util/common/dmc-common';
import { DataKey, DataStoreService } from '../../../services/util/framework/data-store.service';
import { SetupCommonDataProcessorService } from '../../../services/util/pre-processors/setup-common-data-processor.service';
import { SetupGridComp } from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid-comp';
import { SetupGridComponent } from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid.component';
var DivisionSetupComponent = /** @class */ (function (_super) {
    tslib_1.__extends(DivisionSetupComponent, _super);
    function DivisionSetupComponent(configLoader, costingTypeService, dataStoreService, masterDataRestService, common, dataStore, divisionSetupService, dialogService, userManagementService, spinnerService, setupCommonDataProcessorService) {
        var _this = _super.call(this) || this;
        _this.configLoader = configLoader;
        _this.costingTypeService = costingTypeService;
        _this.dataStoreService = dataStoreService;
        _this.masterDataRestService = masterDataRestService;
        _this.common = common;
        _this.dataStore = dataStore;
        _this.divisionSetupService = divisionSetupService;
        _this.dialogService = dialogService;
        _this.userManagementService = userManagementService;
        _this.spinnerService = spinnerService;
        _this.setupCommonDataProcessorService = setupCommonDataProcessorService;
        _this.colDefConfig = [];
        _this.hasADivision = false;
        // search criteria obj. used to
        _this.divisionSearchCriteria = new DivisionSearchCriteria();
        _this.searchCriteriaObserver = new Subscription();
        _this.dialogSubscription = new Subscription();
        return _this;
    }
    DivisionSetupComponent.prototype.ngOnInit = function () {
        this.divisionAvailability();
        this.colDefConfig = this.configLoader.configurations.get(TCO.CONF.CONF_DIVISION_SETUP_CONFIG);
        this.subscribeSearchCriteria();
    };
    /**
     * subscribe search criteria changes in search bar
     * then force grid to refresh data
     * grid refresh will be call getRaws method which is using this subscribed criteria for backend call
     */
    DivisionSetupComponent.prototype.subscribeSearchCriteria = function () {
        var _this = this;
        this.searchCriteriaObserver = this.dataStoreService.get(DataKey.divisionSetupSearchCriteria)
            .subscribe(function (value) {
            _this.divisionSearchCriteria = value;
            _this.setupGrid.runForceSearch();
        });
    };
    DivisionSetupComponent.prototype.createNewRow = function () {
        if (this.dataStore.get(DataKey.kcCompanies) && this.dataStore.get(DataKey.kcCompanies).getValue()
            && this.dataStore.get(DataKey.kcCompanies).getValue()[0] &&
            this.dataStore.get(DataKey.kcCompanies).getValue()[0].name) {
            this.defCompany = this.dataStore.get(DataKey.kcCompanies).getValue()[0].code;
            return { company: this.dataStore.get(DataKey.kcCompanies).getValue()[0].name };
        }
        return '-';
    };
    DivisionSetupComponent.prototype.divisionAvailability = function () {
        this.hasADivision = this.userProfile !== null && this.userProfile !== undefined &&
            this.userProfile.kcDivision !== null && this.userProfile.kcDivision !== undefined;
    };
    DivisionSetupComponent.prototype.deleteRow = function (row) {
        return of('success');
    };
    DivisionSetupComponent.prototype.getRows = function (params) {
        var _this = this;
        if (!this.divisionSearchCriteria) {
            this.divisionSearchCriteria = new DivisionSearchCriteria();
        }
        if (params && params.startRow) {
            this.divisionSearchCriteria.start = params.startRow;
        }
        this.divisionSearchCriteria = this.userManagementService.updateSearchCriteriaWithUserKC(this.divisionSearchCriteria);
        if (params && params.endRow && params.startRow) {
            this.divisionSearchCriteria.size = params.endRow - params.startRow;
        }
        if (params.sortModel && params.sortModel[0]) {
            this.divisionSearchCriteria.sortBy = params.sortModel[0].colId;
            if (params.sortModel[0].sort === SetupGridComp.GRID_SORT_ASCENDING) {
                this.divisionSearchCriteria.sortDirection = SortDirection.ASC;
            }
            else {
                this.divisionSearchCriteria.sortDirection = SortDirection.DESC;
            }
        }
        var userProfile = this.dataStore.get(DataKey.userProfile).getValue();
        if (userProfile.kcCompany != null) {
            this.divisionSearchCriteria.kcCompany = userProfile.kcCompany;
        }
        return this.divisionSetupService.getRows(this.divisionSearchCriteria)
            .pipe(tap(function (data) {
            if (data) {
                _this.dataStoreService.set(DataKey.divisionSetupSearchResultsForCriteria, data);
            }
            else {
            }
        }));
    };
    DivisionSetupComponent.prototype.isInvalidRow = function (params) {
        var data = params.data;
        if (data) {
            var invalid = true;
            if (data.code &&
                data.name &&
                data.company) {
                invalid = false;
            }
            return invalid;
        }
        else {
            return false;
        }
    };
    DivisionSetupComponent.prototype.isUnsavedRow = function (row) {
        if (row) {
            return !row.id;
        }
        return true;
    };
    DivisionSetupComponent.prototype.saveRow = function (row, event) {
        var _this = this;
        if (this.isUnsavedRow(row)) {
            var confirmSendManifests = new DialogModel(true, LEVEL.WARNING, 'Save Division', 'The entered division cannot be modified or deleted later. Do you want to continue?', true, 2000, null, 'Cancel', 'Save', true);
            confirmSendManifests.disableClose = true;
            if (this.dialogSubscription) {
                this.dialogSubscription.unsubscribe();
            }
            var dialogSubscription_1 = this.dialogService
                .confirm(confirmSendManifests)
                .subscribe(function (res) {
                if (res === true) {
                    row.kcCompany = _this.defCompany;
                    return _this.divisionSetupService.saveRow(row)
                        .subscribe(function (response) {
                        _this.userManagementService.getKcCompaniesAndDivisions();
                        _this.spinnerService.show();
                        dialogSubscription_1.unsubscribe();
                        _this.setupGrid.runForceSearch();
                        _this.spinnerService.hide();
                    }, function (error) {
                        _this.spinnerService.hide();
                        if (event && event.data) {
                            event.data.name = '';
                        }
                        _this.common.showSnackBar('Division code cannot be duplicated', 3000, TcErrorType.TYPE.ERROR);
                    });
                }
                else {
                    if (event && event.data) {
                        event.data.name = '';
                    }
                }
            });
        }
        return null;
    };
    DivisionSetupComponent.prototype.isDisabledAdd = function () {
        var userProfile = this.dataStore.get(DataKey.userProfile).getValue();
        return (userProfile && userProfile.kcDivision);
    };
    DivisionSetupComponent.prototype.ngOnDestroy = function () {
        if (this.searchCriteriaObserver) {
            this.searchCriteriaObserver.unsubscribe();
        }
        if (this.dialogSubscription) {
            this.dialogSubscription.unsubscribe();
        }
    };
    return DivisionSetupComponent;
}(SetupGridComp));
export { DivisionSetupComponent };
