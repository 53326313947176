import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TooltipPosition } from '@angular/material';
import { SummaryCardData } from '@tc-core/model/it/codegen/ui/framework/summary-card-data';
import { TC } from '@tc-core/util/constants';
import { NoteItemView } from '../../../models/reservation/note-item-view';

@Component({
    selector: 'tc-service-item-notes',
    templateUrl: './service-item-notes.component.html'
})
export class ServiceItemNotesComponent implements OnInit {

    @Input() noteList: any[] = [];
    @Input() noteSummary: SummaryCardData = new SummaryCardData();
    @Input() noteContents: NoteItemView[] = [];
    @Input() heading: string;
    @Input() masterNoteTypes: any[] = [];
    @Input() authConfigComponent: string = "booking-notes-summary";
    @Input() isConfirmed: boolean = false;

    @Output() addNoteClick: EventEmitter<any> = new EventEmitter();
    @Output() editClick: EventEmitter<any> = new EventEmitter();
    @Output() deleteClick: EventEmitter<any> = new EventEmitter();
    @Output() selectedNote: EventEmitter<SummaryCardData> = new EventEmitter();

    public editToolTip = 'Edit';
    public deleteToolTip = 'Delete';
    public addNoteToolTip = 'Add Note';
    public toolTipPosition: TooltipPosition = 'below';

    showSummary: boolean = false;
    hideToggle: boolean = true;
    disableEditDelete = false;
    EMPTY_CHAR = TC.FORMAT.EMPTY_CHAR;

    constructor() { }

    ngOnInit() {
    }

    showSummaryPanel(event) {
        this.showSummary = !event;
    }
    isEditable(data){
        if(data && this.masterNoteTypes.find(type=> type.code === data)){
            return true;
        }
        return false;
    }

    onEditClick(summaryContent: SummaryCardData) {
        this.editClick.emit(summaryContent);
    }

    onDeleteClick(summaryContent: SummaryCardData) {
        this.deleteClick.emit(summaryContent);
    }

    onPanelExpand(note: any, selectedIndex: number) {
        this.selectedNote.emit(note);
    }

    onAddNotesClick(event) {
        this.addNoteClick.emit(event);
    }

    public onHeaderClick() {
        this.noteSummary = null;
    }

    public onOutsideClick() {
        this.noteSummary = null;
    }


}
