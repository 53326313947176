import { Component, Input, OnInit } from '@angular/core';
import { User } from '@tc-core/model/it/codegen/tbx/api/authority/v2/users/user';
import { TcErrorType } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-error';
import { DialogModel } from '@tc-core/model/it/codegen/ui/framework/dialog-model';
import { LEVEL } from '@tc-core/model/it/codegen/ui/framework/modal-data';
import { Pager } from '@tc-core/model/it/codegen/ui/framework/pager';
import { ConfigLoader } from '@tc-core/util/framework/config-loader.service';
import { UserJourneyManager } from '@tc-core/util/framework/user-journey-manager.service';
import { DialogService } from '@tc/dialog/dialog.service';
import { TCO } from '../../../constants';
import { Criteria } from '../../../models/criteria/criteria';
import { SavedCriteriaV2 } from '../../../models/criteria/saved-criteria-v2';
import { DataHandlerService } from '../../../services/backend-consumers/master-data-handler-service/data-handler.service';
import { ReservationManagementService } from '../../../services/backend-consumers/reservation-management-services/reservation-management.service';
import { ReservationV2ManagementService } from '../../../services/backend-consumers/reservation-v2/reservation-v2-management.service';
import { DMCCommon } from '../../../services/util/common/dmc-common';
import { DataKey, DataStoreService } from '../../../services/util/framework/data-store.service';
import { ResponseUtil } from '../../../services/util/response/response-util.service';

@Component({
    selector: 'tc-show-saved-criteria-modal',
    templateUrl: './show-saved-criteria-modal.component.html'
})
export class ShowSavedCriteriaModalComponent implements OnInit {

    @Input() systemDefinedCriteriaList: any[] = [];
    @Input() userDefinedCriteriaList: any[] = [];
    selectedCriteria: any;
    selectedIndex = 0;
    public pager: Pager;
    public defaultPageResultCount = 10;
    selectionIndex = '';
    tPagination: any;
    fetchedReservationsCount: number = 0;
    dataFromMoreClick = false;
    paginatorHasMore = true;
    private user: User;

    constructor(
        private dataStore: DataStoreService,
        private userJourneyManager: UserJourneyManager,
        private dataHandlerService: DataHandlerService,
        private common: DMCCommon,
        private configLoader: ConfigLoader,
        private reservationManagementService: ReservationManagementService,
        private reservationV2ManagementService: ReservationV2ManagementService,
        private dialogService: DialogService
    ) { }

    ngOnInit() {
        this.tPagination = this.configLoader.configurations.get(TCO.CONF.CONF_PAGINATION_CONFIG)['SHOW_SEARCH_CRITERIA'];
        this.user = this.dataStore.get(DataKey.userDetail).getValue();
        this.setupPaginator();
    }

    private setupPaginator() {
        this.defaultPageResultCount = this.tPagination['defaultPageResultCount'];
        this.pager = new Pager(0, this.defaultPageResultCount);
    }

    public onPage(pageNumber: number) {
        this.pager.currPage = pageNumber;
        this.selectionIndex = (this.pager.currPage * this.pager.itemsPerPage).toString();
    }

    /*public onMore() {
     this.dataFromMoreClick = true;
     if (this.tPagination && this.tPagination['defaultPageResultCount'] && this.tPagination['fetchingPages']) {
     this.searchCriteria.size = this.tPagination['fetchingPages'] * this.tPagination['defaultPageResultCount'];
     this.searchCriteria.start = this.fetchedReservationsCount;
     this.searchOnMore(this.searchCriteria);
     }
     }*/

    public onClose($event: MouseEvent) {
        this.dataStore.set(DataKey.popupClose, true);
    }

    onSearch() {
        this.dataStore.set(DataKey.searchFromSavedCriteria, this.selectedCriteria.criteria, true);
        this.dataStore.set(DataKey.popupClose, true);
    }

    setCriteria(selected: Criteria) {
        this.selectedCriteria = selected;
    }

    onDelete(criteriaId: number) {
        let confirmSuccess = new DialogModel(
            true,
            LEVEL.WARNING,
            'Delete Search Criteria',
            `This Search Criteria will be deleted. Do you wish to proceed?`,
            true,
            2000,
            null,
            'No',
            'Yes',
            true
        );
        this.dialogService
            .confirm(confirmSuccess)
            .subscribe((res) => {
                if (res === true) {
                    // this.reservationManagementService.deleteCriteria(criteriaId).subscribe(data => {
                    this.reservationV2ManagementService.deleteCriteria(criteriaId).subscribe(data => {
                        this.userDefinedCriteriaList = this.userDefinedCriteriaList.filter(value => value.id !== criteriaId);
                        this.common.showSnackBar('Search Criteria Deleted Successfully.', 3000, TcErrorType.TYPE.INFO);
                        this.getUserCriteria();

                    }, error => {
                        this.common.showSnackBar('Failed to Delete Search Criteria.', 3000, TcErrorType.TYPE.ERROR);
                    });
                }
            });
    }

    getUserCriteria() {
        this.dataStore.set(DataKey.userSavedSearchCriteriaListV2, null, true);
        this.reservationV2ManagementService.getUserSearchCriteriaList(this.user.id).subscribe(result => {
            if (result) {
                let criterias = ResponseUtil.getDataArray<SavedCriteriaV2>(result).sort();
                this.dataStore.set(DataKey.userSavedSearchCriteriaListV2, criterias, true);
            }
        });
    }
}
