import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { TcErrorType } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-error';
import { TC } from '@tc-core/util';
import { ConfigLoader } from '@tc-core/util/framework';
import { of, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SetupGridComp } from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid-comp';
import { SetupGridComponent } from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid.component';
import { TCO } from '../../../constants';
import { ProfileTypeSearchCriteria } from '../../../models/criteria/profile-type-search-criteria';
import { ResourceTypeSearchCriteria } from '../../../models/criteria/resource-type-search-criteria';
import { SortDirection } from '../../../models/helper/sort-direction';
import { DataHandlerService } from '../../../services/backend-consumers/master-data-handler-service/data-handler.service';
import { ResourceTypeService } from '../../../services/backend-consumers/setups/resource-type.service';
import { DMCCommon } from '../../../services/util/common/dmc-common';
import { DataKey, DataStoreService } from '../../../services/util/framework/data-store.service';
var EMPTY_CHAR = TC.FORMAT.EMPTY_CHAR;
var ProfileTypeSetupComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ProfileTypeSetupComponent, _super);
    function ProfileTypeSetupComponent(configLoader, dataStoreService, dataHandlerService, resourceTypeService, common) {
        var _this = _super.call(this) || this;
        _this.configLoader = configLoader;
        _this.dataStoreService = dataStoreService;
        _this.dataHandlerService = dataHandlerService;
        _this.resourceTypeService = resourceTypeService;
        _this.common = common;
        _this.colDefConfig = [];
        _this.profileTypeSearchCriteria = new ProfileTypeSearchCriteria();
        _this.allProfileTypeCodes = [];
        // required options for grid editors
        // nothing
        _this.searchCriteriaObserver = new Subscription();
        /*
         grid config methods which are called by grid setup (ag grid)
         and set by grid config
         */
        _this.getResourceTypeText = function (params) {
            var groupText = EMPTY_CHAR;
            if (params && params.data && params.data.resourceType) {
                if (params.data.resourceType.code) {
                    groupText = params.data.resourceType.code;
                }
                else {
                    groupText = EMPTY_CHAR;
                }
                groupText += ' | ';
                if (params.data.resourceType.name) {
                    groupText += params.data.resourceType.name;
                }
                else {
                    groupText += EMPTY_CHAR;
                }
            }
            return groupText;
        };
        _this.getResourceTypes = function (params, rowData, text) {
            return _this.dataStoreService.get(DataKey.resourceTypes)
                .map(function (aEvents) {
                return aEvents.filter(function (aEvent) {
                    if (text) {
                        if (aEvent.name) {
                            return aEvent.name.toLowerCase().indexOf(text.toLocaleString()) > -1;
                        }
                    }
                    else {
                        return true;
                    }
                });
            });
        };
        return _this;
    }
    ProfileTypeSetupComponent.prototype.ngOnInit = function () {
        this.colDefConfig = this.configLoader.configurations.get(TCO.CONF.CONF_PROFILE_TYPE_SETUP);
        this.subscribeSearchCriteria();
        this.fillRequiredGridOptionData();
        this.fillDataKeyForEditorData();
        this.getProfileTypeCodes();
    };
    /**
     * subscribe search criteria changes in search bar
     * then force grid to refresh data
     * grid refresh will be call getRaws method which is using this subscribed criteria for backend call
     */
    ProfileTypeSetupComponent.prototype.subscribeSearchCriteria = function () {
        var _this = this;
        this.searchCriteriaObserver = this.dataStoreService.get(DataKey.profileTypeSearchCriteria)
            .subscribe(function (value) {
            _this.profileTypeSearchCriteria = value;
            _this.setupGrid.runForceSearch();
        });
    };
    ProfileTypeSetupComponent.prototype.fillRequiredGridOptionData = function () {
        this.addResourceTypes();
    };
    ProfileTypeSetupComponent.prototype.fillDataKeyForEditorData = function () {
        this.addProfileTypes();
    };
    ProfileTypeSetupComponent.prototype.getProfileTypeCodes = function () {
        var _this = this;
        this.resourceTypeService.getProfileTypeCodes().subscribe(function (response) {
            _this.allProfileTypeCodes = response.data;
        });
    };
    ProfileTypeSetupComponent.prototype.addResourceTypes = function () {
        var _this = this;
        var resourceTypeSearchCriteria = new ResourceTypeSearchCriteria();
        resourceTypeSearchCriteria.size = 1000000;
        this.resourceTypeService.getResourceTypes(resourceTypeSearchCriteria).subscribe(function (result) {
            _this.dataStoreService.set(DataKey.resourceTypes, result.data, true);
        });
    };
    ProfileTypeSetupComponent.prototype.addProfileTypes = function () {
        var _this = this;
        var profileTypeSearchCriteria = new ProfileTypeSearchCriteria();
        profileTypeSearchCriteria.size = 1000000;
        this.resourceTypeService.getProfileTypes(profileTypeSearchCriteria).subscribe(function (result) {
            _this.dataStoreService.set(DataKey.profileTypes, result.data, true);
        });
    };
    ProfileTypeSetupComponent.prototype.isInvalidRow = function (params) {
        var data = params.data;
        if (data) {
            var invalid = true;
            if (data.code &&
                data.name &&
                data.resourceType && data.resourceType.code) {
                invalid = false;
            }
            return invalid;
        }
        else {
            return false;
        }
    };
    ProfileTypeSetupComponent.prototype.createNewRow = function () {
        return { unsaved: true };
    };
    ProfileTypeSetupComponent.prototype.isUnsavedRow = function (row) {
        return !!row.unsaved;
    };
    ProfileTypeSetupComponent.prototype.deleteRow = function (row) {
        if (row && row.code) {
            return this.resourceTypeService.deleteProfileType(row.code);
        }
        else {
            return of('success');
        }
    };
    ProfileTypeSetupComponent.prototype.saveRow = function (row, event) {
        if (row.unsaved) {
            if (this.allProfileTypeCodes.includes(row.code)) {
                this.common.showSnackBar('Profile Type Code Already Exist. Please Select a Different Code', 3000, TcErrorType.TYPE.ERROR);
            }
            else {
                return this.resourceTypeService.saveProfileType(row)
                    .pipe(tap(function (data) { return row.unsaved = undefined; }));
            }
        }
        else {
            return this.resourceTypeService.saveProfileType(row)
                .pipe(tap(function (data) { return row.unsaved = undefined; }));
        }
    };
    ProfileTypeSetupComponent.prototype.getRows = function (params) {
        var _this = this;
        console.log('fetch requesting for ' + params.startRow + ' to ' + params.endRow);
        console.log(params.sortModel);
        var pageSize = params.endRow - params.startRow;
        if (!this.profileTypeSearchCriteria) {
            this.profileTypeSearchCriteria = new ProfileTypeSearchCriteria();
        }
        this.profileTypeSearchCriteria.start = params.startRow;
        this.profileTypeSearchCriteria.size = pageSize;
        if (params.sortModel && params.sortModel[0]) {
            this.profileTypeSearchCriteria.sortBy = params.sortModel[0].colId;
            if (params.sortModel[0].sort === SetupGridComp.GRID_SORT_ASCENDING) {
                this.profileTypeSearchCriteria.sortDirection = SortDirection.ASC;
            }
            else {
                this.profileTypeSearchCriteria.sortDirection = SortDirection.DESC;
            }
        }
        else {
            this.profileTypeSearchCriteria.sortBy = 'createdOn';
            this.profileTypeSearchCriteria.sortDirection = SortDirection.ASC;
        }
        return this.resourceTypeService.getProfileTypes(this.profileTypeSearchCriteria)
            .pipe(tap(function (data) {
            return _this.dataStoreService.set(DataKey.profileTypeSearchResultsForCriteria, data);
        }));
    };
    ProfileTypeSetupComponent.prototype.ngOnDestroy = function () {
        if (this.searchCriteriaObserver) {
            this.searchCriteriaObserver.unsubscribe();
        }
    };
    return ProfileTypeSetupComponent;
}(SetupGridComp));
export { ProfileTypeSetupComponent };
