<mat-accordion class="tc-accordion" [multi]=true>
    <ng-container>
        <tc-booking-detail-summary-view
                *ngIf="booking"
                [booking]="booking">
        </tc-booking-detail-summary-view>
    </ng-container>
    <ng-container *ngIf="userManagementService.notAvailableFor('FC')">
        <mat-expansion-panel class="mat-expansion-panel-spacing"
                             [hideToggle]="true">
            <mat-expansion-panel-header class="tc-accordion-header__container">
                <div class="tc-accordion-header">
                    <div class="tc-accordion-header__title">Key Controls</div>
                    <i class="material-icons tc-accordion-header__down-icon">keyboard_arrow_down</i>
                </div>
            </mat-expansion-panel-header>
            <tc-key-control-detail-card
                    [keyControlHolder]="booking"
                    class="tc-list-card">
            </tc-key-control-detail-card>
        </mat-expansion-panel>
    </ng-container>
    <ng-container>
        <mat-expansion-panel class="mat-expansion-panel-spacing"
                             [hideToggle]="true">
            <mat-expansion-panel-header class="tc-accordion-header__container">
                <div class="tc-accordion-header">
                    <div class="tc-accordion-header__title">Passengers</div>
                    <i class="material-icons tc-accordion-header__down-icon">keyboard_arrow_down</i>
                </div>
            </mat-expansion-panel-header>
            <tc-passengers-detail-view
                    *ngIf="booking"
                    [booking]="booking">
            </tc-passengers-detail-view>
        </mat-expansion-panel>
    </ng-container>
    <ng-container>
        <mat-expansion-panel
            [hideToggle]="true">
            <mat-expansion-panel-header class="tc-accordion-header__container">
                <div class="tc-accordion-header">
                    <div class="tc-accordion-header__title">Booking Items</div>
                    <i class="material-icons tc-accordion-header__down-icon">keyboard_arrow_down</i>
                </div>
            </mat-expansion-panel-header>

            <tc-booking-items-detail-view
                    *ngIf="booking && bookingItemSummaryCards"
                    [booking]="booking"
                    [summaryCards]="bookingItemSummaryCards"
                    (passengersClick)="onItemPassengersClick($event)">
            </tc-booking-items-detail-view>
        </mat-expansion-panel>
    </ng-container>
    <ng-container>
        <mat-expansion-panel
            #expPanelBookingNotes
            [hideToggle]="true">
            <mat-expansion-panel-header class="tc-accordion-header__container">
                <div class="tc-accordion-header">
                    <div class="tc-accordion-header__title">Booking Notes</div>
                    <i class="material-icons tc-accordion-header__down-icon">keyboard_arrow_down</i>
                </div>
            </mat-expansion-panel-header>
            <tc-item-notes
                    *ngIf="booking && booking.bookingNotes"
                    [readonly]="true"
                    [noteList]="booking.bookingNotes"
                    [heading]="''"
                    class="bdv-booking-notes-summary-wrapper">
            </tc-item-notes>
        </mat-expansion-panel>
    </ng-container>
</mat-accordion>
