import { Component, Input, OnInit } from '@angular/core';
import { AssignmentSaveOperation } from '../../models/reservation/assignment-save-operation';
import { AssignmentStatus } from '../../models/reservation/assignment-status';
import { ServiceItem } from '../../models/reservation/service-item';
import { ServiceItemChanges } from '../../models/helper/service-item-changes';
import { SearchResultSummary } from '../../models/reservation/search-result-summary';
import { Subscription } from 'rxjs';
import { ConfigLoader, EventManager, FocusViewManager, UserJourneyManager } from '@tc-core/util/framework';
import { TC } from '@tc-core/util';
import { DriverSupplierCostSummary } from '../../models/summary/driver-supplier-cost-summary';
import { DataKey, DataStoreService } from '../../services/util/framework/data-store.service';
import { FocusViewService } from '@tc/focus-view/focus-view.service';
import { CurrencyFormat } from '@tc-core/model/it/codegen/ui/framework/currency-format';
import { TCO } from '../../constants';
import { Pager } from '@tc-core/model/it/codegen/ui/framework/pager';
import { ResourceAvailability } from '../../models/resource/resource-availability';
import { ChangeDetectorService } from '../../services/util/change-detector/change-detector.service';
import { ResponseUtil } from '../../services/util/response/response-util.service';
import { TcApiError } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-api-error';
import { TcHttpError } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-http-error';
import { CommonHelper } from '@tc-core/util/helpers';
import { SpinnerService } from '@tc-core/util/ui';
import { TrsSupplierCostSummary } from '../../models/summary/supplier-cost-summary';
import { CostCalculationUtilsService } from '../../services/business-utils/cost-calculation/cost-calculation-utils.service';
import { SupplierSearchCriteria } from '../../models/criteria/supplier-search-criteria';
import { SupplierService } from '../../services/backend-consumers/supplier-service/supplier.service';
import { ContentSummaryCardService } from '../../services/util/ui/content-summary-card.service';
import { PassengerCount } from '../../models/reservation/passenger-count';
import { ServiceAssignment } from '../../models/reservation/service-assignment';
import { LEVEL, DialogModel } from '@tc-core/model/it/codegen/ui/framework/dialog-model';
import { TcErrorType } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-error';
import { DMCCommon } from '../../services/util/common/dmc-common';
import { DialogService } from '@tc/dialog/dialog.service';
import { VehicleSearchCriteria } from '../../models/criteria/vehicle-search-criteria';
import { AssignmentSummary } from '../../models/assignment-summary';
import * as moment from 'moment';
import { ResourceType } from '../../models/reservation/assignment';
import { Icon } from '@tc-core/model/it/codegen/ui/framework/icon';
import { TransportResourceService } from '../../services/backend-consumers/supplier-service/transport-resource.service';
import { ModalData } from '@tc-core/model/it/codegen/ui/framework/modal-data';
import { ResAvailabilityCalendarFocusViewComponent } from '../../widgets/shared/res-availability-calendar-focus-view/res-availability-calendar-focus-view.component';
import { GenericSearchCriteria } from '../../models/criteria/generic-search-criteria';
import { IdentifierService } from '../../services/util/identifier/identifier.service';
import {GenTourItem} from "../../models/reservation/generic/gen-tour-item";
import {GenericResourceService} from "../../services/backend-consumers/supplier-service/generic-resource.service";
import {GenResourceAllocationSummary} from "../../models/summary/gen-resource-allocation-summary";

@Component({
    selector: 'tc-assignment-focus-view',
    templateUrl: './assignment-focus-view.component.html',
    styleUrls: ['./assignment-focus-view.component.scss']
})
export class AssignmentFocusViewComponent implements OnInit {

    @Input() selectedIndex = 0;
    @Input() bookingData: ServiceItem;
    @Input() bookingDataArray: any[]; // for bulk assignment
    @Input() serviceItemChangesInSelectedGroup: Map<number, ServiceItemChanges>;
    @Input() isBulkAssignment: boolean = false;
    @Input() isVehicleAssignment: boolean = false;
    @Input() isGenResourceAssignment: boolean = false;
    @Input() isDriverAssignment: boolean = false;
    @Input() itemGroup: SearchResultSummary;
    @Input() genTourItem: GenTourItem;
    @Input() previousAssignment: ServiceAssignment;
    @Input() unSavedAssignments: ResourceAvailability[] = [];

    currencyFormat: CurrencyFormat;
    serviceDate: any;
    transferMode: any;
    route: string;
    isDataLoaded = true;

    // serviceItems
    changesAvailableForServiceItems = false;
    passengerCount: PassengerCount;

    //pagination
    public pager: Pager;
    public defaultPageResultCount = 4;

    // supplier panel
    tAssignedSuppliers: any;
    supplierPanelHeading = '';
    contentCardDataListSuppliers: Array<any> = [];
    suppliers: TrsSupplierCostSummary[] = [];
    selectedSupplier: any;
    suppliersResultCount: number = 0;
    fetchedSupplierCount: number = 0;
    suppliersFromMoreClick = false;
    hasMoreSuppliers = true;
    dataFromMoreClick = false;
    supplierIdList = [];
    displayChipsArrSuppliers: any = [];
    supplierLabel: string = "";

    // vehicle panel
    tAssignedVehicles: any;
    vehiclePanelHeading = '';
    vehicles: ResourceAvailability[] = [];
    selectedVehicle: any;
    fetchedVehicleCount = 0;
    vehiclesResultCount: number = 0;
    vehiclesFromMoreClick = false;
    hasMoreVehicles = true;
    contentCardDataListVehicles: Array<ResourceAvailability> = [];
    vehicleSearchCriteria = new VehicleSearchCriteria;
    displayChipsArrVehicles = [];

    // driver panel
    tAssignedDrivers: any;
    drivers: ResourceAvailability[] = [];
    contentCardDataListDrivers: Array<any> = [];
    driverPanelHeading = '';
    selectedDriver: any;
    fetchedDriverCount = 0;
    driversResultCount = 0;
    driversFromMoreClick = false;
    hasMoreDrivers = true;

    //resource panel
    tAssignedResources: any;
    resources: GenResourceAllocationSummary[] = [];
    fetchedResourceCount = 0;
    resourceResultCount = 0;
    resourcePanelHeading = '';
    selectedResource:any;
    contentCardDataListResources: Array<any> = [];
    hasMoreResources = true;
    resourcesFromMoreClick = false;
    resourceCategoryIcons:any;

    //availability calendar
    navConfig: any[] = [];

    private supplierResultObsv: Subscription = new Subscription();
    private supplierMoreResultObsv: Subscription = new Subscription();
    private vehicleResultObsv: Subscription = new Subscription();
    private vehicleMoreResultObsv: Subscription = new Subscription();
    private driverResultObsv: Subscription = new Subscription();
    private resourceResultObsv: Subscription = new Subscription();
    private driverMoreResultObsv: Subscription = new Subscription();
    private transferModesObsv: Subscription = new Subscription();
    private focusViewCloseSubject: Subscription = new Subscription();

    constructor(
        private dataStore: DataStoreService,
        private em: EventManager,
        private focusViewManager: FocusViewManager,
        private journeyManager: UserJourneyManager,
        private focusViewService: FocusViewService,
        private configLoader: ConfigLoader,
        private changeDetector: ChangeDetectorService,
        private commonHelper: CommonHelper,
        private spinnerService: SpinnerService,
        private costCalculationUtilsService: CostCalculationUtilsService,
        private supplierService: SupplierService,
        private summaryCardService: ContentSummaryCardService,
        private common: DMCCommon,
        private dialogService: DialogService,
        private transportResourceService: TransportResourceService,
        private genericResourceService: GenericResourceService,
        private identifierService: IdentifierService
    ) {
    }

    ngOnInit() {
        this.currencyFormat = this.getCurrencyFormat();

        this.journeyManager.canProceed.next(false);
        this.focusViewManager.canProceed.next(false);
        this.focusViewManager.canProceedState.next(true);

        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'CLOSE_FOCUS_VIEW').subscribe(e => {
            this.dataStore.set(DataKey.availabilityCalendarFocusViewClose, null);
            this.dataStore.set(DataKey.availabilityCalendarFocusViewClose, true);
            this.onSave();
            this.focusViewService.close(true);
        });

        this.resourceCategoryIcons = this.configLoader.configurations.get(TCO.CONF.CONF_GENERIC_QUEUE_ICONS).RESOURCE_CATEGORY_ICONS;
        this.enableFocusViewProceed();

        //pagination
        this.pager = new Pager(0, this.defaultPageResultCount);

        this.loadSupplierPanel();

        if(this.isVehicleAssignment){
            this.supplierLabel = "Vehicle Supplier";
            this.loadVehiclePanel();
        } else if(this.isDriverAssignment){
            this.supplierLabel = "Driver Supplier";
            this.loadDriverPanel();
        } else if(this.isGenResourceAssignment){
            this.supplierLabel = "Resource Supplier";
            this.loadResourcePanel();
        }


        this.changeDetector.notifierSupplierCostState.subscribe(
            value => {
                this.updateSupplierSearchResults();
            }
        );

    }

    private getCurrencyFormat(): CurrencyFormat {
        let currencyFormat;
        let currencySymbolPattern = this.configLoader.configurations.get(TC.CONF.CONF_SYSTEM).defaults.default_currency_symbol_pattern;
        currencyFormat = new CurrencyFormat(TCO.AppData.DEF_CURRENCY_TYPE, currencySymbolPattern);
        return currencyFormat;
    }

    private enableFocusViewProceed() {
        if (this.selectedSupplier) {
            this.focusViewManager.canProceed.next(true);
        } else {
            this.focusViewManager.canProceed.next(false);
        }
    }

    private setPageZero() {
        if (this.pager) {
            if (!this.dataFromMoreClick) {
                this.pager.currPage = 0;
            } else {
                this.dataFromMoreClick = false;
            }
        } else {
            this.pager = new Pager(0, this.defaultPageResultCount);
        }
    }

    //**************** supplier panel*********************

    private loadSupplierPanel() {
        // load supplier summary card configs
        this.tAssignedSuppliers = this.configLoader.configurations.get(TCO.CONF.CONF_RESERVATION_RESOURCE_ASSIGN_CONFIG).supplier;

        if(!this.isGenResourceAssignment){
            this.getTransferModeCapacity();
        }else if(this.isGenResourceAssignment){
            this.dataStore.set(DataKey.assignedGenResourceSupp, null, true);
            this.getGenSuppliers();
        }
        this.moreSupplierObserver();
    }

    getGenSuppliers(){
        this.dataStore.set(DataKey.searchSuppliersForGenAssignment, null, true);
        this.supplierResultObsv = this.dataStore.get(DataKey.searchSuppliersForGenAssignment).subscribe(
            result => {
                if (this.commonHelper.dataValidity(result)) {
                    this.spinnerService.hide();
                    if (ResponseUtil.isSuccess(result)) {
                        this.suppliers = ResponseUtil.getDataArray(result);
                        this.contentCardDataListSuppliers = [];
                        this.suppliers = this.processSupplierResults(this.suppliers);
                        this.fetchedSupplierCount = this.suppliers.length;
                        this.suppliersResultCount = ResponseUtil.getTotalCount(result);
                        if (this.suppliers) {
                            this.supplierPanelHeading = this.suppliersResultCount + ' result' +
                                (this.suppliersResultCount > 1 ? 's' : '') + ' found.';
                        }
                        this.contentCardDataListSuppliers = this.getSupplierSummaryCards(this.suppliers);
                    }
                } else if (this.commonHelper.isEmptyData(result)) {
                    this.isDataLoaded = true;
                } else if (result instanceof TcApiError) {
                    this.spinnerService.hide();
                } else if (result instanceof TcHttpError) {
                    this.spinnerService.hide();
                }
                // this.getSuppliersSearchResult();
            }
        );
    }

    private getTransferModeCapacity() {
        this.transferModesObsv = this.dataStore.get(DataKey.transferModes).subscribe((data) => {
            if (data !== null) {
                if (!this.isBulkAssignment) {
                    this.serviceDate = this.bookingData.serviceDate;
                    this.transferMode = data.find(
                        val => val.code === this.bookingData.trsServiceItem.transferMode);
                    this.route = this.bookingData.trsServiceItem.route;
                } else {
                    this.serviceDate = this.bookingDataArray[0].serviceDate;
                    this.transferMode = data.find(
                        val => val.code === this.bookingDataArray[0]['rawData'].trsServiceItem.transferMode);
                    this.route = this.bookingDataArray[0]['rawData'].trsServiceItem.route;
                }
                this.getSuppliers();
            }
        });
    }

    private getSuppliers() {
        this.dataStore.set(DataKey.assignedSupplier, null, true);
        if(this.isVehicleAssignment){
            this.loadTrsSuppliers();
        } else {
            this.loadGenSuppliers();
        }
    }
    loadTrsSuppliers(){
        this.supplierResultObsv = this.dataStore.get(DataKey.suppliersForAssignment).subscribe(
            result => {
                if (this.commonHelper.dataValidity(result)) {
                    this.spinnerService.hide();
                    if (ResponseUtil.isSuccess(result)) {
                        this.suppliers = ResponseUtil.getDataArray(result);
                        this.contentCardDataListSuppliers = [];
                        this.suppliers = this.processSupplierResults(this.suppliers);
                        this.fetchedSupplierCount = this.suppliers.length;
                        this.suppliersResultCount = ResponseUtil.getTotalCount(result);
                        if (this.suppliers) {
                            this.supplierPanelHeading = this.suppliersResultCount + ' result' +
                                (this.suppliersResultCount > 1 ? 's' : '') + ' found.';
                        }
                        this.contentCardDataListSuppliers = this.getSupplierSummaryCards(this.suppliers);

                    }
                } else if (this.commonHelper.isEmptyData(result)) {
                    this.isDataLoaded = true;
                } else if (result instanceof TcApiError) {
                    this.spinnerService.hide();
                } else if (result instanceof TcHttpError) {
                    this.spinnerService.hide();
                }
                // this.getSuppliersSearchResult();
            }
        );
    }
    loadGenSuppliers(){
        this.supplierResultObsv = this.dataStore.get(DataKey.driverSuppliersForAssignment).subscribe(
            result => {
                if (this.commonHelper.dataValidity(result)) {
                    this.spinnerService.hide();
                    if (ResponseUtil.isSuccess(result)) {
                        this.suppliers = ResponseUtil.getDataArray(result);
                        this.contentCardDataListSuppliers = [];
                        this.suppliers = this.processSupplierResults(this.suppliers);
                        this.fetchedSupplierCount = this.suppliers.length;
                        this.suppliersResultCount = ResponseUtil.getTotalCount(result);
                        if (this.suppliers) {
                            this.supplierPanelHeading = this.suppliersResultCount + ' result' +
                                (this.suppliersResultCount > 1 ? 's' : '') + ' found.';
                        }
                        this.contentCardDataListSuppliers = this.getSupplierSummaryCards(this.suppliers);

                    }
                } else if (this.commonHelper.isEmptyData(result)) {
                    this.isDataLoaded = true;
                } else if (result instanceof TcApiError) {
                    this.spinnerService.hide();
                } else if (result instanceof TcHttpError) {
                    this.spinnerService.hide();
                }
                // this.getSuppliersSearchResult();
            }
        );
    }

    private processSupplierResults(suppliers) {
        this.supplierIdList = [];
        // console.log(this.genTourItem);
        for (let supplier of suppliers) {
            this.supplierIdList.push(supplier.supplierId);
            if (this.transferMode) {
                supplier.capacity = this.transferMode.capacity;
            }
            if(this.isGenResourceAssignment){
                supplier = this.mapGenSuppToDriverSupp(supplier);
            }
            supplier = this.getSupplierCostData(supplier);
        }
        return suppliers;
    }

    mapGenSuppToDriverSupp(supplier){
        if(supplier.resourceCostSummaries.length>0){
            let costSummaries = supplier.resourceCostSummaries[0];
            supplier.unitCost = costSummaries.unitCost;
            supplier.totalCost = null;
            supplier.adultFare= costSummaries.adultFare;
            supplier.childFare= costSummaries.childFare;
            supplier.infantFare= costSummaries.infantFare;
            supplier.teenFare= costSummaries.teenFare;
            supplier.unitFare= costSummaries.unitFare;
        }
        return supplier;
    }

    getSupplierCostData(supplier: TrsSupplierCostSummary) {
        if (supplier.unitFare) {
            supplier.isUnitFare = true;
            supplier.estimatedTotalCost = supplier.totalCost;
            supplier.totalCost = this.costCalculationUtilsService.getSupplierTotalFromUnitCost(
                supplier,
                this.isBulkAssignment,
                this.isGenResourceAssignment?this.genTourItem:this.bookingData,
                this.bookingDataArray,this.isGenResourceAssignment
            );

        } else {
            supplier.isUnitFare = false;
            let costSummary = this.costCalculationUtilsService.getSupplierTotalCost(
                supplier,
                this.isBulkAssignment,
                this.isGenResourceAssignment?this.genTourItem:this.bookingData,
                this.bookingDataArray, this.isGenResourceAssignment
            );
            supplier.estimatedTotalCost = supplier.totalCost;
            supplier.totalCost = costSummary.totalCost;
            supplier.totalAdultFare = costSummary.adultFare;
            supplier.totalChildFare = costSummary.childFare;
            supplier.totalInfantFare = costSummary.infantFare;
        }
        return supplier;
    }

    private getSupplierSummaryCards(suppliers: TrsSupplierCostSummary[]) {
        suppliers.sort((a, b) => a.totalCost - b.totalCost);
        let summaryCards = this.summaryCardService.getContentCardsByConfig(
            this.tAssignedSuppliers,
            suppliers
        );
        if(this.isGenResourceAssignment && summaryCards.length>0){
            let iconView = new Icon();
            let summaryIcon:any;
            let iconValue:any;

            let arr: any[] = this.tAssignedResources.config.icon;
            summaryIcon = arr.find(item => item['iconName'] === 'GENERIC_RESOURCE_ICN');
            iconValue = summaryIcon['key'];
            if(iconValue === "resource"){
                iconValue = this.genTourItem.categoryCode;
            }
            if (summaryIcon) {
                if (summaryIcon.iconKeys[iconValue]) {
                    iconView.key = summaryIcon.iconKeys[iconValue];
                }
                iconView.iconType = summaryIcon['iconType'];
                iconView.value = summaryIcon.values[iconValue];
                iconView.style = summaryIcon['style'] + ' ' +
                    summaryIcon['activeClass'];
                iconView.value = iconView.value ? iconView.value : '';
            }
            if (iconView.value.length > 3) {
                iconView.style = iconView.style + ' ' + summaryIcon['smallTextClass'];
            }
            summaryCards.forEach(summaryCard=>{
               summaryCard.icon.value = iconView;
            });
        }
        this.spinnerService.hide();
        this.setPageZero();
        return summaryCards;
    }

    private updateSupplierSearchResults() {
        if (this.selectedSupplier) {
            this.contentCardDataListSuppliers.forEach(da => {
                if (da.data.value.supplierId == this.selectedSupplier.supplierId) {
                    da = this.selectedSupplier;
                }
            });
            this.contentCardDataListSuppliers = this.getSupplierSummaryCards(this.suppliers);
        }
    }

    onSupPaginationMore(criteria: SupplierSearchCriteria) {
        criteria.start = this.fetchedSupplierCount;
        this.dataStore.set(DataKey.supplierAssignAllFetchedResults, null, true);
        this.supplierService.searchSuppliersForAssignmentOnMore(criteria);
    }

    private moreSupplierObserver() {
        this.supplierMoreResultObsv = this.dataStore.get(DataKey.supplierAssignAllFetchedResults).subscribe(
            (result) => {
                if (this.commonHelper.dataValidity(result)) {
                    this.supplierMoreResultObsv.unsubscribe();
                    let dataList = ResponseUtil.getDataArray(result);
                    if (dataList) {
                        if (dataList.length > 0) {
                            let moreSuppliers = this.processSupplierResults(dataList);
                            this.suppliers.push(...moreSuppliers);
                            this.fetchedSupplierCount = this.suppliers.length;
                            this.hasMoreSuppliers = this.suppliersResultCount !== this.fetchedSupplierCount;
                            let contentCardDataList = this.getSupplierSummaryCards(moreSuppliers);
                            this.contentCardDataListSuppliers.push(...contentCardDataList);
                        } else {
                            this.hasMoreSuppliers = false;
                        }
                    }
                } else if (result instanceof TcApiError) {
                    this.hasMoreSuppliers = false;
                } else if (result instanceof TcHttpError) {
                    this.hasMoreSuppliers = false;
                }
            });
    }

    onSupplierSelect(event) {
        this.changesAvailableForServiceItems = true;
        this.selectedSupplier = event;
        this.enableFocusViewProceed();
        this.dataStore.set(DataKey.assignedSupplier, this.selectedSupplier.data.value, true);
    }

    assignSupplier(summaryCard: any) {
        let tempAssignmentId = 'Temp_' + this.identifierService.getNextManuallyResetId();
        // TODO: assign supplier only
        if (this.isBulkAssignment) {
            this.serviceItemChangesInSelectedGroup.forEach(value => {
                if (value.checked) {
                    if(this.isVehicleAssignment){
                        this.setVehicleSupplierToAssignment(value.serviceItemId, summaryCard.commonSupplier, tempAssignmentId);
                    } else if(this.isDriverAssignment){
                        this.setDriverSupplierToAssignment(value.serviceItemId, summaryCard.commonSupplier, tempAssignmentId);
                    }
                }
            });
        } else {
            if(this.isVehicleAssignment){
                this.setVehicleSupplierToAssignment(this.bookingData.serviceItemId, summaryCard.commonSupplier, tempAssignmentId);
            } else if(this.isDriverAssignment){
                this.setDriverSupplierToAssignment(this.bookingData.serviceItemId, summaryCard.commonSupplier, tempAssignmentId);
            }
        }
        this.changeDetector.notifyChange();
    }

    private setVehicleSupplierToAssignment(serviceItemId: number, supplier: TrsSupplierCostSummary, tempAssignmentId) {
        let serviceItem = this.isBulkAssignment ? this.bookingDataArray.find(value => value.serviceItemId ===serviceItemId) : this.bookingData;
        this.serviceItemChangesInSelectedGroup.get(serviceItemId).changed = true;
        this.serviceItemChangesInSelectedGroup.get(serviceItemId).vehicleSupplier = supplier;
        let serviceAssignments = this.serviceItemChangesInSelectedGroup.get(serviceItemId).serviceAssignments;
        let assignment: ServiceAssignment = new ServiceAssignment();
        assignment = this.getSupplierAssignmentMap(assignment, supplier, serviceItemId);
        assignment.estimatedCost = supplier.estimatedTotalCost;
        assignment.categoryCode = ResourceType.vehicle;
        assignment.tempAssignmentId = tempAssignmentId;
        this.serviceItemChangesInSelectedGroup.get(serviceItemId).serviceAssignments = this.serviceItemChangesInSelectedGroup.get(
            serviceItemId).serviceAssignments ?
            this.serviceItemChangesInSelectedGroup.get(serviceItemId).serviceAssignments :
            new Map();
        this.serviceItemChangesInSelectedGroup.get(serviceItemId).serviceAssignments
            .set(ResourceType.vehicle, assignment);
        this.serviceItemChangesInSelectedGroup.get(serviceItemId).vehicleCost = this.costCalculationUtilsService.calculateServiceItemJobCost(serviceItem, assignment);
    }

    private setDriverSupplierToAssignment(serviceItemId: number, supplier: DriverSupplierCostSummary, tempAssignmentId) {
        let serviceItem = this.isBulkAssignment ? this.bookingDataArray.find(value => value.serviceItemId ===serviceItemId) : this.bookingData;
        this.serviceItemChangesInSelectedGroup.get(serviceItemId).changed = true;
        this.serviceItemChangesInSelectedGroup.get(serviceItemId).driverSupplier = supplier;
        let serviceAssignments = this.serviceItemChangesInSelectedGroup.get(serviceItemId).serviceAssignments;

        let assignment: ServiceAssignment = new ServiceAssignment();
        assignment = this.getSupplierAssignmentMap(assignment, supplier, serviceItemId);
        assignment.estimatedCost = supplier.estimatedTotalCost;
        assignment.categoryCode = ResourceType.driver;
        assignment.tempAssignmentId = tempAssignmentId;
        this.serviceItemChangesInSelectedGroup.get(serviceItemId).serviceAssignments = this.serviceItemChangesInSelectedGroup.get(
            serviceItemId).serviceAssignments ?
            this.serviceItemChangesInSelectedGroup.get(serviceItemId).serviceAssignments :
            new Map();
        this.serviceItemChangesInSelectedGroup.get(serviceItemId).serviceAssignments
            .set(ResourceType.driver, assignment);
        this.serviceItemChangesInSelectedGroup.get(serviceItemId).driverCost = this.costCalculationUtilsService.calculateServiceItemJobCost(serviceItem, assignment);
    }

    private getSupplierAssignmentMap(
        assignment: ServiceAssignment, supplier: any, serviceItemId: number) {
        assignment.supplierId = supplier.supplierId;
        assignment.supplierName = supplier.name;
        assignment.supplierCode = supplier.code;
        assignment.currency = supplier.currency;
        assignment.adults = this.passengerCount.adult ? this.passengerCount.adult : 0;
        assignment.children = this.passengerCount.children ? this.passengerCount.children : 0;
        assignment.infants = this.passengerCount.infants ? this.passengerCount.infants : 0;
        assignment.adultPrice = supplier.adultFare;
        assignment.childPrice = supplier.childFare;
        assignment.infantPrice = supplier.infantFare;
        assignment.unitPrice = supplier.unitFare;
        assignment.jobCost = supplier.totalCost;
        assignment.serviceItemId = serviceItemId;
        if(this.bookingData && this.bookingData.serviceDate)
        {
            assignment.jobDate = this.bookingData.serviceDate;
        } else {
            assignment.jobDate = this.bookingDataArray[0] && this.bookingDataArray[0].rawData ? this.bookingDataArray[0].rawData.serviceDate : "";
        }
        assignment.updated = true;
        if(assignment.assignmentId > 0){
            assignment.assignmentSaveOperation = AssignmentSaveOperation.UPDATE;
        } else {
            assignment.assignmentSaveOperation = AssignmentSaveOperation.SAVE;
        }
        assignment.assignStatus = AssignmentStatus.PENDING;
        return assignment;
    }

    //**************** vehicle panel*********************

    private loadVehiclePanel() {
        // load vehicle summary card configs
        this.tAssignedVehicles = this.configLoader.configurations.get(TCO.CONF.CONF_RESERVATION_RESOURCE_ASSIGN_CONFIG).vehicle;
        this.getVehicles();
    }

    private getVehicles() {
        this.vehicleResultObsv = this.dataStore.get(DataKey.resourceAssignVehicleSearchResults).subscribe(
            // this.vehicleSearchResultObsv = this.reservationService.searchRecommendedVehiclesFromMock(vehicleSearchCriteria).subscribe(
            result => {
                if (this.commonHelper.dataValidity(result)) {
                    if (result.status.code === TC.ErrorCodes.SUCCESS) {
                        let dataList = ResponseUtil.getDataArray<ResourceAvailability>(result);
                        if (dataList) {
                            this.vehicles = dataList;
                            this.fetchedVehicleCount = this.vehicles.length;
                            this.vehiclesResultCount = ResponseUtil.getTotalCount(result);
                            this.vehiclePanelHeading = this.vehiclesResultCount + ' result' +
                                (this.vehiclesResultCount > 1 ? 's' : '') +
                                ' found.';
                            if (this.vehicles.length > 0) {
                                this.contentCardDataListVehicles = this.vehicles;
                                this.getVehicleSummaryCards();
                            }
                        }

                    }
                }
            }
        );
    }

    getVehicleSummaryCards() {
        for (let vehicle of this.contentCardDataListVehicles) {
            vehicle = this.processIcon(vehicle, this.tAssignedVehicles.config.icon, true);
            // if (this.unSavedAssignments && this.unSavedAssignments.length > 0) {
            //     let resource: ResourceAvailability = this.unSavedAssignments.filter(value => vehicle.resourceId === value.resourceId);
            //     if (resource && resource.assignments) {
            //         let selectedAssignmentExists = vehicle.assignments.filter(value => value.assignmentId ===resource.assignments[0] || value.tempAssignmentId === resource.assignments[0].tempAssignmentId);
            //         if(!selectedAssignmentExists){
            //             resource.assignments[0].selected = false;
            //             resource.assignments[0].recommended = false;
            //             vehicle.assignments.push(resource.assignments[0]);
            //         }
            //     }
            // }
        }
        this.spinnerService.hide();
    }

    processIcon(resource: ResourceAvailability, arr: any[], isVehicle: boolean): ResourceAvailability {
        let iconView = new Icon();
        let summaryIcon: any;
        let iconValue: any;

        if (isVehicle) {
            summaryIcon = arr.find(
                item => item['iconName'] === 'TRANSFER_MODE_ICN');
            let valueField = summaryIcon['key'];
            iconValue = resource[valueField];
            if (summaryIcon) {
                iconView.key = summaryIcon['iconName'];
                iconView.iconType = iconValue;
                iconView.value = iconValue;
                iconView.value = iconView.value ? iconView.value : '';
                iconView.style = summaryIcon['style'] + ' ' +
                    summaryIcon['neutralClass'];
            }
        } else {
            summaryIcon = arr.find(
                item => item['iconName'] === 'GENERIC_RESOURCE_ICN');
            iconValue = summaryIcon['key'];
            if(iconValue === "resource"){
                iconValue = this.genTourItem.categoryCode;
            }
            if (summaryIcon) {
                if (summaryIcon.iconKeys[iconValue]) {
                    iconView.key = summaryIcon.iconKeys[iconValue];
                }
                iconView.iconType = summaryIcon['iconType'];
                iconView.value = summaryIcon.values[iconValue];
                iconView.style = summaryIcon['style'] + ' ' +
                    summaryIcon['activeClass'];
                iconView.value = iconView.value ? iconView.value : '';
            }
        }

        if (iconView.value.length > 3) {
            iconView.style = iconView.style + ' ' + summaryIcon['smallTextClass'];
        }
        resource.icon = iconView;
        return resource;
    }

    onVehiclesPaginationMore(criteria: VehicleSearchCriteria) {
        criteria.start = this.fetchedVehicleCount;
        this.dataStore.set(DataKey.vehicleAssignAllFetchedResults, null, true);
        this.transportResourceService.searchVehiclesForAssignmentOnMore(criteria);
        this.moreVehiclesObserver();
    }

    moreVehiclesObserver() {
        this.vehicleMoreResultObsv = this.dataStore.get(DataKey.vehicleAssignAllFetchedResults).subscribe(
            (result) => {
                if (this.commonHelper.dataValidity(result)) {
                    this.vehicleMoreResultObsv.unsubscribe();
                    let dataList = ResponseUtil.getDataArray<ResourceAvailability>(result);
                    if (dataList) {
                        if (dataList.length > 0) {
                            this.vehicles.push(...dataList);
                            this.fetchedVehicleCount = this.vehicles.length;
                            this.hasMoreVehicles = this.vehiclesResultCount !== this.fetchedVehicleCount;
                            // this.getAllocatedSeatCount(this.vehicles);
                        } else {
                            this.hasMoreVehicles = false;
                        }
                    } else {
                        this.hasMoreVehicles = false;
                    }
                } else if (result instanceof TcApiError) {
                    this.hasMoreVehicles = false;
                } else if (result instanceof TcHttpError) {
                    this.hasMoreVehicles = false;
                }
            });
    }

    onVehicleSelect(event) {
        this.changesAvailableForServiceItems = true;
    }

    assignVehicle(vehicle: ResourceAvailability) {
        if (this.isBulkAssignment) {
            let serviceItemIds = [];
            this.serviceItemChangesInSelectedGroup.forEach(value => {
                if (value.checked) {
                    this.setVehicleToAssignment(value.serviceItemId, vehicle);
                }
            });
        } else {
            this.setVehicleToAssignment(this.bookingData.serviceItemId, vehicle);
        }
        this.unSavedAssignments.push(vehicle);
        this.changeDetector.notifyChange();
    }

    private setVehicleToAssignment(serviceItemId: number, vehicle: ResourceAvailability) {
        this.serviceItemChangesInSelectedGroup.get(serviceItemId).changed = true;
        this.serviceItemChangesInSelectedGroup.get(serviceItemId).vehicle = vehicle;
        this.serviceItemChangesInSelectedGroup.get(serviceItemId).vehicleSupplier = vehicle.trsSupplier;
        let assignmentMap = new ServiceAssignment();
        let assignments = this.serviceItemChangesInSelectedGroup.get(serviceItemId).serviceAssignments;
        if (assignments && assignments.get(ResourceType.vehicle) &&
            assignments.get(ResourceType.vehicle).resourceId !== vehicle.resourceId) {
            assignmentMap = this.getResourceAssignmentMap(
                assignments.get(ResourceType.vehicle),
                vehicle,
                serviceItemId
            );
        } else {
            assignmentMap = this.getResourceAssignmentMap(assignmentMap, vehicle, serviceItemId);
        }
        assignmentMap.categoryCode = ResourceType.vehicle;
        if (this.serviceItemChangesInSelectedGroup.get(serviceItemId).serviceAssignments.get(ResourceType.driver)) {
            this.serviceItemChangesInSelectedGroup.get(serviceItemId).serviceAssignments
                .get(ResourceType.driver).parentAssignment = assignmentMap;
        }
        this.serviceItemChangesInSelectedGroup.get(serviceItemId).serviceAssignments
            .set(ResourceType.vehicle, assignmentMap);
        this.serviceItemChangesInSelectedGroup.get(serviceItemId).vehicleCost = this.getServiceItemCost(serviceItemId, assignmentMap);
        this.changesAvailableForServiceItems = true;
    }

    onVehicleEventClick(event: ResourceAvailability) {
        if (event.selected) {
            this.deleteVehicleAssignment(event);
        } else {
            this.updateVehicleAssignment(event, false);
        }
    }

    private deleteVehicleAssignment(event: ResourceAvailability) {
        let confirmDataObject = new DialogModel(
            true,
            LEVEL.ERROR,
            'Delete Job',
            'Do you want to remove job?',
            true,
            3000
        );
        this.dialogService.confirm(confirmDataObject).subscribe(
            (res) => {
                if (res === true) {
                    // let resource = this.vehicles.find(value => event.resourceId === value.resourceId);
                    this.removeAssignment(event);

                    this.dataStore.set(DataKey.assignedSupplierFromVehicle, null, true);
                    this.enableFocusViewProceed();
                }
            });
    }

    private updateVehicleAssignment(
        event: ResourceAvailability, isAfterDriver: boolean) {
        let confirmDataObject = new DialogModel(
            true,
            LEVEL.INFO,
            'Add to Existing job',
            'Do you want to assign vehicle with an existing job?',
            true,
            3000
        );
        this.dialogService.confirm(confirmDataObject).subscribe(
            (res) => {
                if (res === true) {
                    let assignment = event.assignments.find(value => value.tobeSelected);
                    let passengerCount = this.costCalculationUtilsService.getTotalPassengerCount(
                        this.isBulkAssignment,
                        this.bookingData,
                        this.bookingDataArray
                    );
                    if (assignment && passengerCount > assignment.remainingCount) {
                        this.common.showSnackBar('Exceeding the capacity', 3000, TcErrorType.TYPE.WARN);
                    }
                    assignment.startTime = assignment &&
                    assignment.startTime > this.vehicleSearchCriteria.serviceStartTime ?
                        this.vehicleSearchCriteria.serviceStartTime :
                        assignment.startTime;

                    assignment.passengerCount += passengerCount;
                    assignment.allocatedCount += passengerCount;
                    assignment.remainingCount -= passengerCount;
                    assignment.selected = true;
                    assignment.recommended = true;
                    assignment.serviceItemIds = [];
                    if (this.isBulkAssignment) {
                        this.bookingDataArray.forEach(value => {
                            assignment.serviceItemIds.push(value.serviceItemId);
                        });
                    } else {
                        assignment.serviceItemIds.push(this.bookingData.serviceItemId);
                    }

                    assignment.jobCost = this.getJobCost(event, assignment.passengerCount);

                    event.selected = true;
                    let resource = this.contentCardDataListVehicles.find(
                        value => event.resourceId === value.resourceId);
                    event.assignments = resource.assignments;
                    event.assignments.forEach(value => {
                        if (value.assignmentId === assignment.assignmentId) {
                            value = assignment;
                        }
                    });
                    this.selectedVehicle = event;
                    if (assignment && assignment.childrenAssignments && !isAfterDriver) {
                        let driverAssignment = assignment.childrenAssignments.find(
                            value => value.categoryCode === ResourceType.driver);
                        if (driverAssignment) {
                            this.setSelectedDriver(driverAssignment);
                        }
                    }
                    this.selectedVehicle.trsSupplier.serviceDate = this.serviceDate;
                    // this.dataStore.set(DataKey.assignedSupplierFromVehicle, this.selectedVehicle.supplier, true);
                    this.selectedSupplier = resource.trsSupplier;
                    this.enableFocusViewProceed();
                }
            });
    }

    onDriverCalendarClick(resource: ResourceAvailability) {
        let supplier = resource.trsSupplier ? resource.trsSupplier : resource.driverSupplier;
        this.navConfig = this.configLoader.configurations.get(TCO.CONF.CONF_PAGE_SECTION_CONFIG)['availability_calendar_side_nav']['resource_level'];
        setTimeout(() => {
            let fabActions = [];

            /*let navItemsArray = this.configLoader.configurations.get(TCO.CONF.CONF_NAVIGATION);
             this.configLoader.configurations.get(TCO.CONF.CONF_FOCUS_VIEW).AVAILABILITY_CAL_FOCUS_VIEW.fabAction
             .forEach(val => {
             for (let key in navItemsArray) {
             if (val === navItemsArray[key]['id']) {
             fabActions.push(navItemsArray[key]);
             }
             }
             });*/
            let dataObject = new ModalData(
                LEVEL.SUCCESS,
                'Availability Calendar',
                null,
                null,
                'season-setup-focus-view',
                ResAvailabilityCalendarFocusViewComponent,
                {
                    'resourceSummary': resource,
                    'navConfig': this.navConfig
                },
                {'label': 'close'},
                '',
                '',
                fabActions,
                '',
                supplier ?
                    (resource.resourceName) + ' | Supplier: ' + (supplier.name) :
                    ''
            );

            this.focusViewCloseSubject = this.dataStore.get(DataKey.availabilityCalendarFocusViewClose).subscribe(c => {
                if (c) {
                }
            });
            this.focusViewService.confirm(dataObject).subscribe(res => {
                if (res) {
                    console.log(res);
                }
            });
        }, 0);

    }

    //**************** driver panel*********************

    private loadDriverPanel() {
        // load driver summary card configs
        this.tAssignedDrivers = this.configLoader.configurations.get(TCO.CONF.CONF_RESERVATION_RESOURCE_ASSIGN_CONFIG).driver;
        this.getDrivers();
    }

    private getDrivers() {
        this.driverResultObsv = this.dataStore.get(DataKey.resourceAssignDriversSearchResults).subscribe(
            result => {
                if (this.commonHelper.dataValidity(result)) {
                    if (result.status.code === TC.ErrorCodes.SUCCESS) {
                        let dataList = ResponseUtil.getDataArray<ResourceAvailability>(result);
                        if (dataList) {
                            this.drivers = dataList;
                            this.fetchedDriverCount = this.drivers.length;
                            this.driversResultCount = ResponseUtil.getTotalCount(result);
                            this.driverPanelHeading = this.driversResultCount + ' result' +
                                (this.driversResultCount > 1 ? 's' : '') +
                                ' found.';
                            if (this.drivers.length > 0) {
                                this.contentCardDataListDrivers = this.drivers;
                                this.getDriverSummaryCards();
                            }
                        }

                    }
                }
                // this.getSuppliersSearchResult();
            }
        );
    }

    getDriverSummaryCards() {
        for (let driver of this.contentCardDataListDrivers) {
            driver = this.processIcon(driver, this.tAssignedDrivers.config.icon, false);
        }
        this.spinnerService.hide();
    }

    onDriversPaginationMore(criteria: GenericSearchCriteria) {
        criteria.start = this.fetchedDriverCount;
    }

    onDriverSelect(event) {
        this.changesAvailableForServiceItems = true;
    }

    assignDriver(driver: ResourceAvailability) {
        if (this.isBulkAssignment) {
            let serviceItemIds = [];
            this.serviceItemChangesInSelectedGroup.forEach(value => {
                if (value.checked) {
                    this.setDriverToAssignment(value.serviceItemId, driver);
                }
            });
        } else {
            this.setDriverToAssignment(this.bookingData.serviceItemId, driver);
        }
        this.unSavedAssignments.push(driver);
        this.changeDetector.notifyChange();
    }

    private setDriverToAssignment(serviceItemId: number, driver: ResourceAvailability) {
        this.serviceItemChangesInSelectedGroup.get(serviceItemId).changed = true;
        this.serviceItemChangesInSelectedGroup.get(serviceItemId).driver = driver;
        this.serviceItemChangesInSelectedGroup.get(serviceItemId).driverSupplier = driver.driverSupplier;
        let assignmentMap = new ServiceAssignment();
        let assignments = this.serviceItemChangesInSelectedGroup.get(serviceItemId).serviceAssignments;
        if (assignments && assignments.get(ResourceType.driver) &&
            assignments.get(ResourceType.driver).resourceId !== driver.resourceId) {
            assignmentMap = this.getResourceAssignmentMap(assignments.get(ResourceType.driver), driver, serviceItemId);
        } else {
            assignmentMap = this.getResourceAssignmentMap(assignmentMap, driver, serviceItemId);
        }
        assignmentMap.categoryCode = ResourceType.driver;
        if (assignments.get(ResourceType.vehicle)) {
            assignmentMap.parentAssignment = assignments.get(ResourceType.vehicle);

        }
        this.serviceItemChangesInSelectedGroup.get(serviceItemId).serviceAssignments
            .set(ResourceType.driver, assignmentMap);
        this.serviceItemChangesInSelectedGroup.get(serviceItemId).driverCost = this.getServiceItemCost(serviceItemId, assignmentMap);
        this.changesAvailableForServiceItems = true;
    }

    private getResourceAssignmentMap(
        assignment: ServiceAssignment, resource: ResourceAvailability, serviceItemId: number) {
        let supplier = resource.trsSupplier ? resource.trsSupplier : resource.driverSupplier;
        assignment.resourceId = resource.resourceId;
        let selectedAssignment = resource.assignments.find(value => value.selected);
        if (selectedAssignment) {
            assignment.startTime = selectedAssignment.startTime;
            assignment.endTime = selectedAssignment.endTime;
            assignment.waitingEndTime = selectedAssignment.waitingEndTime;
            assignment.jobCost = selectedAssignment.jobCost;
            assignment.estimatedCost = selectedAssignment.estimatedPrice ?
                selectedAssignment.estimatedPrice :
                selectedAssignment.jobCost;
            assignment.startLocationGroup = selectedAssignment.startLocationGroup;
            assignment.endLocationGroup = selectedAssignment.endLocationGroup;
            assignment.resourceName = resource.resourceName;
            assignment.serviceItemIdList = selectedAssignment.serviceItemIds;
            assignment.assignmentId = selectedAssignment.assignmentId;
            if(!assignment.assignmentId){
                assignment.tempAssignmentId = selectedAssignment.tempAssignmentId;
            }
            assignment.updated = selectedAssignment.updated;
        } else if(assignment.assignStatus) {
            assignment.assignmentId = this.previousAssignment ? this.previousAssignment.assignmentId : 0;
        }
        return this.getSupplierAssignmentMap(assignment, supplier, serviceItemId);
    }
    getServiceItemCost(serviceItemId: number, assignment: ServiceAssignment){
        let bookingData;
        if(this.isBulkAssignment) {
            bookingData = this.bookingDataArray.find(value => value.serviceItemId === serviceItemId);

            let passengerCount = this.costCalculationUtilsService.getTotalSpecificPassengerCount(
                false,
                bookingData,
                null
            );
            let adultCost = 0;
            let childCost = 0;
            let infantCost = 0;

            if (assignment.unitPrice) {
                return this.passengerCount && this.passengerCount.total > 0 ? (assignment.jobCost/this.passengerCount.total) * passengerCount.total : assignment.unitPrice;
            } else {
                adultCost = assignment.adults > 0 ?
                    (assignment.adultPrice / assignment.adults) * passengerCount.adult :
                    assignment.adultPrice;
                childCost = assignment.children > 0 ?
                    (assignment.childPrice / assignment.children) * passengerCount.children :
                    assignment.adultPrice;
                infantCost = assignment.infants > 0 ?
                    (assignment.infantPrice / assignment.infants) * passengerCount.infants :
                    assignment.adultPrice;

                return adultCost + childCost + infantCost;
            }
        } else {
            return this.costCalculationUtilsService.calculateServiceItemJobCost(
                this.bookingData,
                assignment
            );
        }

    }

    private onSave() {
        this.passengerCount = this.isGenResourceAssignment?this.costCalculationUtilsService.getTotalSpecificPassengerCountGen(this.genTourItem):this.costCalculationUtilsService.getTotalSpecificPassengerCount(
            this.isBulkAssignment,
            this.bookingData,
            this.bookingDataArray
        );
        if (this.selectedVehicle || this.selectedDriver || this.selectedResource) {
            if (this.selectedVehicle) {
                this.assignVehicle(this.selectedVehicle);
            }
            if (this.selectedDriver) {
                this.assignDriver(this.selectedDriver);
            }
            if (this.selectedResource){
                this.assignResource(this.selectedResource);
            }
        } else if (this.selectedSupplier) {
            if(!this.isGenResourceAssignment){
                this.assignSupplier(this.selectedSupplier);
            }else{
                this.assignGenSupplier(this.selectedSupplier);
            }
        }
        if(!this.isGenResourceAssignment){
            this.dataStore.set(DataKey.resourceAssignedStatus, this.changesAvailableForServiceItems, true);
        }

    }

    assignGenSupplier(supplier:any){
        this.dataStore.set(DataKey.assignedGenResourceSupp,true,true);
    }

    assignResource(resource: any) {
        this.unSavedAssignments.push(resource);
        this.changeDetector.notifyChange();
        this.dataStore.set(DataKey.assignedResource,resource,true);
    }

    private createAssignmentSummary(target: any): AssignmentSummary {
        let supplier = target.trsSupplier ? target.trsSupplier : target.driverSupplier;
        let assignment = new AssignmentSummary();
        assignment.tempAssignmentId = 'Temp_' + this.identifierService.getNextManuallyResetId();
        assignment.status = AssignmentStatus.PENDING;
        assignment.selected = true;
        assignment.recommended = true;
        assignment.serviceItemIds = [];
        assignment.passengerCount = this.costCalculationUtilsService.getTotalPassengerCount(
            this.isBulkAssignment,
            this.bookingData,
            this.bookingDataArray
        );
        if (this.isBulkAssignment) {
            assignment.startLocationGroup = this.itemGroup.route.split('-')[0];
            assignment.endLocationGroup = this.itemGroup.route.split('-')[1];
            assignment.startTime = this.itemGroup.startTime;
            assignment.endTime = moment(this.bookingDataArray[0]['rawData'].trsServiceItem.arrivalDateTime)
                .format('HH:mm');

            assignment.startTimeObj = this.itemGroup.startTimeObj;
            assignment.endTimeObj = this.bookingDataArray[0]['rawData'].trsServiceItem.arrivalDateTime;

            assignment.waitingEndTime = this.itemGroup.endTime;
            assignment.assignDate = this.bookingDataArray[0]['rawData'].serviceDate;
            this.bookingDataArray.forEach(value => {
                assignment.serviceItemIds.push(value.serviceItemId);
            });

        } else {
            assignment.startLocationGroup = this.bookingData.trsServiceItem.route.split('-')[0];
            assignment.endLocationGroup = this.bookingData.trsServiceItem.route.split('-')[1];
            if (this.bookingData.trsServiceItem.arrivalDateTime > this.bookingData.trsServiceItem.departureDateTime) {
                assignment.startTime = moment(this.bookingData.trsServiceItem.departureDateTime).format('HH:mm');
                assignment.endTime = moment(this.bookingData.trsServiceItem.arrivalDateTime).format('HH:mm');

                assignment.startTimeObj = new Date(this.bookingData.trsServiceItem.departureDateTime);
                assignment.endTimeObj = new Date(this.bookingData.trsServiceItem.arrivalDateTime);

                assignment.waitingEndTime = moment(this.bookingData.trsServiceItem.departureDateTime).format('HH:mm');
            } else {
                assignment.startTime = moment(this.bookingData.trsServiceItem.arrivalDateTime).format('HH:mm');
                assignment.endTime = moment(this.bookingData.trsServiceItem.departureDateTime).format('HH:mm');

                assignment.startTimeObj = new Date(this.bookingData.trsServiceItem.arrivalDateTime);
                assignment.endTimeObj = new Date(this.bookingData.trsServiceItem.departureDateTime);

                assignment.waitingEndTime = moment(this.bookingData.trsServiceItem.arrivalDateTime).format('HH:mm');
            }
            assignment.serviceItemIds.push(this.bookingData.serviceItemId);
            assignment.assignDate = this.bookingData.serviceDate;
        }
        assignment.status = AssignmentStatus.PENDING;
        assignment.currency = supplier.currency;
        assignment.allocatedCount = assignment.passengerCount;
        assignment.remainingCount = target.capacity - assignment.passengerCount;
        assignment.jobCost = supplier.totalCost;
        assignment.selected = true;

        return assignment;
    }

    /*private setTempIdsToSeasons() {
     let initId = 1;
     for (let i = 0; i < this.resourceCostData.length; i++) {
     let season: Season = this.resourceCostData[i];
     season.tempSeasonId = initId++;
     }
     }*/

    // updateVehicleSearchResults() {
    //     if (this.selectedVehicle) {
    //         this.contentCardDataListVehicles.forEach(da => {
    //             if (da.resourceId == this.selectedVehicle.resourceId) {
    //                 da = this.selectedVehicle;
    //             }
    //         });
    //     }
    //
    // }

    onVehicleTargetClick(target: ResourceAvailability) {
        let confirmDataObject = new DialogModel(
            true,
            LEVEL.INFO,
            'Create New Job',
            'Do you want to create new job for this vehicle?',
            true,
            3000
        );
        this.dialogService.confirm(confirmDataObject).subscribe(
            (res) => {
                if (res === true) {
                    let assignment = this.createAssignmentSummary(target);
                    if (assignment.passengerCount > assignment.remainingCount) {
                        this.common.showSnackBar('Exceeding the capacity', 3000, TcErrorType.TYPE.WARN);
                    }
                    assignment.resourceType = ResourceType.vehicle;
                    target.assignments = target.assignments ? target.assignments : [];
                    target.assignments.push(assignment);
                    target.selected = true;
                    this.selectedVehicle = target;
                    this.selectedSupplier = target.trsSupplier;
                    if (assignment.childrenAssignments) {
                        let driver = assignment.childrenAssignments.find(
                            value => value.categoryCode === ResourceType.driver);
                        if (driver) {
                            this.setSelectedDriver(driver);
                        }
                    }
                    this.enableFocusViewProceed();
                }
            });
    }

    public ngOnDestroy(): void {
        if (this.supplierResultObsv) {
            this.supplierResultObsv.unsubscribe();
        }
        if (this.supplierMoreResultObsv) {
            this.supplierMoreResultObsv.unsubscribe();
        }
        if (this.vehicleResultObsv) {
            this.vehicleResultObsv.unsubscribe();
        }
        if (this.vehicleMoreResultObsv) {
            this.vehicleMoreResultObsv.unsubscribe();
        }
        if (this.driverResultObsv) {
            this.driverResultObsv.unsubscribe();
        }
        if (this.driverMoreResultObsv) {
            this.driverMoreResultObsv.unsubscribe();
        }
        if (this.transferModesObsv) {
            this.transferModesObsv.unsubscribe();
        }
    }

    onDriverEventClick(event: ResourceAvailability) {
        if (event.selected) {
            this.deleteDriverAssignment(event);
        } else {
            this.updateDriverAssignment(event, false);
        }
    }

    onDriverTargetClick(target: any) {
        let confirmDataObject = new DialogModel(
            true,
            LEVEL.INFO,
            'Create New Job',
            'Do you want to create new job for this resource?',
            true,
            3000
        );
        this.dialogService.confirm(confirmDataObject).subscribe(
            (res) => {
                if (res === true) {
                    let assignment = this.createAssignmentSummary(target);
                    assignment.resourceType = ResourceType.driver;
                    target.assignments = target.assignments ? target.assignments : [];
                    target.assignments.push(assignment);
                    target.selected = true;
                    this.selectedDriver = target;
                    this.selectedSupplier = target.driverSupplier;
                    if (assignment.parentAssignment) {
                        this.setSelectedVehicle(assignment.parentAssignment);
                    }

                    this.enableFocusViewProceed();
                }
            });
    }

    private deleteDriverAssignment(event: ResourceAvailability) {
        let confirmDataObject = new DialogModel(
            true,
            LEVEL.ERROR,
            'Delete Job',
            'Do you want to remove job?',
            true,
            3000
        );
        this.dialogService.confirm(confirmDataObject).subscribe(
            (res) => {
                if (res === true) {
                    this.removeAssignment(event);
                    this.dataStore.set(DataKey.assignedSupplierFromDriver, null, true);
                    this.selectedSupplier = null;
                    this.enableFocusViewProceed();
                }
            });
        // if (event && event.assignments){
        //     let selectedEvent = event.assignments.find(value => value.selected);
        //     if(selectedEvent.parentAssignment){
        //         this.serviceItemChangesInSelectedGroup.get(this.serviceItemId)
        //     }
        // }
    }

    private updateDriverAssignment(event: ResourceAvailability, isAfterVehicle: boolean) {
        let confirmDataObject = new DialogModel(
            true,
            LEVEL.INFO,
            'Add to Existing Job',
            'Do you want to assign driver with an existing job?',
            true,
            3000
        );
        this.dialogService.confirm(confirmDataObject).subscribe(
            (res) => {
                if (res === true) {
                    let assignment = event.assignments.find(value => value.tobeSelected);
                    let passengerCount = this.costCalculationUtilsService.getTotalPassengerCount(
                        this.isBulkAssignment,
                        this.bookingData,
                        this.bookingDataArray
                    );
                    if(assignment && (assignment.startTime > this.vehicleSearchCriteria.serviceStartTime)){
                        assignment.startTime = this.vehicleSearchCriteria.serviceStartTime
                    }

                    assignment.passengerCount += passengerCount;
                    assignment.allocatedCount += passengerCount;
                    if (event.capacity > 0) {
                        assignment.remainingCount -= passengerCount;
                    } else {
                        assignment.remainingCount = 0;
                    }
                    assignment.selected = true;
                    assignment.recommended = true;
                    assignment.serviceItemIds = [];
                    if (this.isBulkAssignment) {
                        this.bookingDataArray.forEach(value => {
                            assignment.serviceItemIds.push(value.serviceItemId);
                        });
                    } else {
                        assignment.serviceItemIds.push(this.bookingData.serviceItemId);
                    }
                    assignment.jobCost = this.getJobCost(event, assignment.passengerCount);

                    event.selected = true;
                    let resource = this.contentCardDataListDrivers.find(value => event.resourceId === value.resourceId);
                    event.assignments = resource.assignments;
                    event.assignments.forEach(value => {
                        if (value.assignmentId === assignment.assignmentId) {
                            value = assignment;
                        }
                    });
                    this.selectedDriver = event;
                    if (assignment.parentAssignment && !isAfterVehicle) {
                        this.setSelectedVehicle(assignment.parentAssignment);
                    }
                    this.selectedDriver.driverSupplier.serviceDate = this.serviceDate;
                    // this.dataStore.set(DataKey.assignedSupplierFromDriver, this.selectedDriver.supplier, true);
                    this.selectedSupplier = event.driverSupplier;
                    this.enableFocusViewProceed();
                }
            });
    }

    private getJobCost(event: ResourceAvailability, paxCount: number) {
        let supplier = event.trsSupplier ? event.trsSupplier : event.driverSupplier;
        if (supplier.unitFare && event.capacity > 0) {
            return paxCount * (supplier.unitFare / event.capacity);
        } else if (supplier.unitFare) {
            return supplier.unitFare;
        } else {
            return paxCount * supplier.adultFare;
        }

    }

    private removeAssignment(event: ResourceAvailability) {
        if (event.assignments) {
            let selectedAssignment = event.assignments.find(value => value.selected);
            let passengerCount = this.costCalculationUtilsService.getTotalPassengerCount(
                this.isBulkAssignment,
                this.bookingData,
                this.bookingDataArray
            );
            selectedAssignment.passengerCount -= passengerCount;
            selectedAssignment.allocatedCount -= passengerCount;
            selectedAssignment.remainingCount += passengerCount;

            if (selectedAssignment.allocatedCount > 0) {
                selectedAssignment.updated = false;
                selectedAssignment.selected = false;
                selectedAssignment.startTime = selectedAssignment.startTime >
                this.vehicleSearchCriteria.serviceStartTime ?
                    this.vehicleSearchCriteria.serviceStartTime :
                    selectedAssignment.startTime;
                // selectedAssignment.endTime = assignment.endTime > this.vehicleSearchCriteria. ? this.vehicleSearchCriteria.serviceStartTime : assignment.startTime;

                if (this.isBulkAssignment) {
                    this.bookingDataArray.forEach(value => {
                        selectedAssignment.serviceItemIds.splice(value.serviceItemId, 1);
                    });

                } else {
                    selectedAssignment.serviceItemIds.splice(this.bookingData.serviceItemId, 1);
                }

                selectedAssignment.jobCost = this.getJobCost(event, selectedAssignment.passengerCount);
                // assignment.adultCost =
                // assignment.childCost =
                // assignment.infantCost =
                // assignment.unitCost =
                event.selected = false;

                if (selectedAssignment.allocatedCount > 0) {
                    event.assignments.forEach(value => {
                        if (value.assignmentId === selectedAssignment.assignmentId ||
                            value.tempAssignmentId === selectedAssignment.tempAssignmentId) {
                            value = selectedAssignment;
                        }
                    });
                } else {
                    event.assignments = event.assignments.filter(value => !value.selected);
                    event.selected = false;
                    selectedAssignment.recommended = false;
                }

            }

            else {
                event.selected = false;
                event.assignments = event.assignments.filter(value => !value.selected);
                selectedAssignment = null;
            }

        }
    }

    private setSelectedVehicle(parentAssignment: AssignmentSummary) {
        if (this.vehicles && parentAssignment) {
            let vehicle = this.vehicles.find(value => value.resourceId === parentAssignment.resourceId);
            vehicle.assignments.forEach(value => {
                if (value.assignmentId === parentAssignment.assignmentId) {
                    value.selected = true;
                }
            });
            this.updateVehicleAssignment(vehicle, true);
        }
    }

    private setSelectedDriver(driverAssignment: AssignmentSummary) {
        if (this.drivers && driverAssignment) {
            let driver = this.drivers.find(value => value.resourceId === driverAssignment.resourceId);
            driver.assignments.forEach(value => {
                if (value.assignmentId === driverAssignment.assignmentId) {
                    value.selected = true;
                }
            });
            this.updateDriverAssignment(driver, true);
        }
    }

    //**************** resource panel*********************

    private loadResourcePanel() {
        // load resource summary card configs
        this.tAssignedResources = this.configLoader.configurations.get(TCO.CONF.CONF_RESERVATION_RESOURCE_ASSIGN_CONFIG).resource;
        this.dataStore.set(DataKey.assignedResource, null, true);
        this.getResources();
    }

    private getResources() {
        // let genericSearchCriteria = new GenericSearchCriteria();
        // genericSearchCriteria.activeStatus = 'true';
        // genericSearchCriteria.resourceType = this.genTourItem.categoryCode;
        // genericSearchCriteria.serviceDate = this.genTourItem.serviceItems[0].serviceDate;
        // genericSearchCriteria.start = 0;
        // genericSearchCriteria.size = 10;
        this.resourceResultObsv = this.dataStore.get(DataKey.resourceAssignResourcesSearchResults).subscribe(
          result=>{
              if (this.commonHelper.dataValidity(result)) {
                              if (result.status.code === TC.ErrorCodes.SUCCESS) {
                                  let dataList = ResponseUtil.getDataArray<GenResourceAllocationSummary>(result);
                                  if (dataList) {
                                      this.resources = dataList;
                                      this.fetchedResourceCount = this.resources.length;
                                      this.resourceResultCount = ResponseUtil.getTotalCount(result);
                                      this.resourcePanelHeading = this.resourceResultCount + ' result' +
                                          (this.resourceResultCount > 1 ? 's' : '') +
                                          ' found.';
                                      if (this.resources.length > 0) {
                                          this.contentCardDataListResources = this.resources;

                                          this.getResourceSummaryCards();
                                      }
                                  }

                              }
                          }
          }
        );

    }

    getResourceSummaryCards() {
        for (let resource of this.contentCardDataListResources) {
            resource = this.processIcon(resource, this.tAssignedResources.config.icon, false);
        }
        this.spinnerService.hide();
    }

    onResourcesPaginationMore(criteria: GenericSearchCriteria) {
        criteria.start = this.fetchedResourceCount;
    }

    onResourceSelect(event) {
        this.changesAvailableForServiceItems = true;
    }

    onResourceCalendarClick(resource: ResourceAvailability) {
        let supplier = resource.trsSupplier ? resource.trsSupplier : resource.driverSupplier;
        this.navConfig = this.configLoader.configurations.get(TCO.CONF.CONF_PAGE_SECTION_CONFIG)['availability_calendar_side_nav']['resource_level'];
        setTimeout(() => {
            let fabActions = [];

            /*let navItemsArray = this.configLoader.configurations.get(TCO.CONF.CONF_NAVIGATION);
             this.configLoader.configurations.get(TCO.CONF.CONF_FOCUS_VIEW).AVAILABILITY_CAL_FOCUS_VIEW.fabAction
             .forEach(val => {
             for (let key in navItemsArray) {
             if (val === navItemsArray[key]['id']) {
             fabActions.push(navItemsArray[key]);
             }
             }
             });*/
            let dataObject = new ModalData(
                LEVEL.SUCCESS,
                'Availability Calendar',
                null,
                null,
                'season-setup-focus-view',
                ResAvailabilityCalendarFocusViewComponent,
                {
                    'resourceSummary': resource,
                    'navConfig': this.navConfig
                },
                {'label': 'close'},
                '',
                '',
                fabActions,
                '',
                supplier ?
                    (resource.resourceName) + ' | Supplier: ' + (supplier.name) :
                    ''
            );

            this.focusViewCloseSubject = this.dataStore.get(DataKey.availabilityCalendarFocusViewClose).subscribe(c => {
                if (c) {
                }
            });
            this.focusViewService.confirm(dataObject).subscribe(res => {
                if (res) {
                    console.log(res);
                }
            });
        }, 0);

    }

    onResourceEventClick(event: ResourceAvailability) {
        if (event.selected) {
            this.deleteResouceAssignment(event);
        } else {
            this.updateResouceAssignment(event, false);
        }
    }

    private deleteResouceAssignment(event: ResourceAvailability) {
        let confirmDataObject = new DialogModel(
            true,
            LEVEL.ERROR,
            'Delete Job',
            'Do you want to remove job?',
            true,
            3000
        );
        this.dialogService.confirm(confirmDataObject).subscribe(
            (res) => {
                if (res === true) {
                    this.removeAssignment(event);
                    this.dataStore.set(DataKey.assignedSupplierFromDriver, null, true);
                    this.selectedSupplier = null;
                    this.enableFocusViewProceed();
                }
            });
    }

    private updateResouceAssignment(event: ResourceAvailability, isAfterVehicle: boolean) {
        let confirmDataObject = new DialogModel(
            true,
            LEVEL.INFO,
            'Add to Existing Job',
            'Do you want to assign driver with an existing job?',
            true,
            3000
        );
        this.dialogService.confirm(confirmDataObject).subscribe(
            (res) => {
                if (res === true) {
                    let assignment = event.assignments.find(value => value.tobeSelected);
                    let passengerCount = this.costCalculationUtilsService.getTotalPassengerCount(
                        this.isBulkAssignment,
                        this.bookingData,
                        this.bookingDataArray
                    );
                    if(assignment && (assignment.startTime > this.vehicleSearchCriteria.serviceStartTime)){
                        assignment.startTime = this.vehicleSearchCriteria.serviceStartTime
                    }

                    assignment.passengerCount += passengerCount;
                    assignment.allocatedCount += passengerCount;
                    if (event.capacity > 0) {
                        assignment.remainingCount -= passengerCount;
                    } else {
                        assignment.remainingCount = 0;
                    }
                    assignment.selected = true;
                    assignment.recommended = true;
                    assignment.serviceItemIds = [];
                    if (this.isBulkAssignment) {
                        this.bookingDataArray.forEach(value => {
                            assignment.serviceItemIds.push(value.serviceItemId);
                        });
                    } else {
                        assignment.serviceItemIds.push(this.bookingData.serviceItemId);
                    }
                    assignment.jobCost = this.getJobCost(event, assignment.passengerCount);

                    event.selected = true;
                    let resource = this.contentCardDataListDrivers.find(value => event.resourceId === value.resourceId);
                    event.assignments = resource.assignments;
                    event.assignments.forEach(value => {
                        if (value.assignmentId === assignment.assignmentId) {
                            value = assignment;
                        }
                    });
                    this.selectedDriver = event;
                    if (assignment.parentAssignment && !isAfterVehicle) {
                        this.setSelectedVehicle(assignment.parentAssignment);
                    }
                    this.selectedDriver.driverSupplier.serviceDate = this.serviceDate;
                    // this.dataStore.set(DataKey.assignedSupplierFromDriver, this.selectedDriver.supplier, true);
                    this.selectedSupplier = event.driverSupplier;
                    this.enableFocusViewProceed();
                }
            });
    }

    onResourceTargetClick(target: any) {
        let confirmDataObject = new DialogModel(
            true,
            LEVEL.INFO,
            'Create New Job',
            'Do you want to create new job for this resource?',
            true,
            3000
        );
        this.dialogService.confirm(confirmDataObject).subscribe(
            (res) => {
                if (res === true) {
                    let assignment = this.createResourceAssignmentSummary(target);
                    console.log(target);
                    // assignment.resourceType = ResourceType.driver;

                    target.assignments = target.assignments ? target.assignments : [];
                    target.assignments.push(assignment);
                    target.selected = true;
                    this.selectedResource= target;
                    this.selectedSupplier = target.driverSupplier;

                    this.enableFocusViewProceed();
                }
            });
    }

    private createResourceAssignmentSummary(target: any): AssignmentSummary {
        let supplier = target.trsSupplier ? target.trsSupplier : target.driverSupplier;
        let assignment = new AssignmentSummary();
        assignment.tempAssignmentId = 'Temp_' + this.identifierService.getNextManuallyResetId();
        assignment.status = AssignmentStatus.PENDING;
        assignment.selected = true;
        assignment.recommended = true;
        assignment.serviceItemIds = [];
        assignment.passengerCount = this.costCalculationUtilsService.getTotalPassengerCountGen(
            this.genTourItem
        );
        let startDateTime = new Date();
        let endDateTime = new Date();
        if(this.genTourItem.jobPeriod){
            let timesArr:Array<string> = this.genTourItem.jobPeriod.split('-');
            let startTimeArr:Array<string> = timesArr[0].split(':');
            let endTimeArr:Array<string> = timesArr[1].split(':');
            startDateTime.setHours(+startTimeArr[0],+startTimeArr[1]);
            endDateTime.setHours(+endTimeArr[0],+endTimeArr[1]);
            startDateTime.setFullYear(moment(this.genTourItem.serviceItems[0].serviceDate).year(),moment(this.genTourItem.serviceItems[0].serviceDate).month(),moment(this.genTourItem.serviceItems[0].serviceDate).date());
            endDateTime.setFullYear(moment(this.genTourItem.serviceItems[0].serviceDate).year(),moment(this.genTourItem.serviceItems[0].serviceDate).month(),moment(this.genTourItem.serviceItems[0].serviceDate).date());
        }
        assignment.startTime = moment(startDateTime).format('HH:mm');
        assignment.endTime = moment(endDateTime).format('HH:mm');
        assignment.startTimeObj = new Date(startDateTime);
        assignment.endTimeObj = new Date(endDateTime);
        assignment.waitingEndTime = moment(startDateTime).format('HH:mm');
        this.genTourItem.serviceItems.forEach(serviceItem=>{
            assignment.serviceItemIds.push(serviceItem.serviceItemId);
        });
        assignment.assignDate = this.genTourItem.serviceItems[0].serviceDate;
        assignment.status = AssignmentStatus.PENDING;
        assignment.currency = target.resourceSupplier.currency;
        assignment.allocatedCount = assignment.passengerCount;
        assignment.remainingCount = target.capacity - assignment.passengerCount;
        assignment.jobCost = supplier.totalCost;
        assignment.selected = true;
        return assignment;
    }

}



