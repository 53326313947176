import { Injectable, Injector } from '@angular/core';
import { SummaryCardData } from '@tc-core/model/it/codegen/ui/framework/summary-card-data';

@Injectable()
export class ContentSummaryCardService {

    constructor(
        private injector: Injector
    ) {
    }

    getContentCardsByConfig(configObj, data: any[]): SummaryCardData[] {
        if (configObj) {
            const config = configObj.config;
            if (configObj.actionHandler && configObj.actionHandler !== '') {
                const t = configObj.actionHandler.split('#');
                const srv = t[0];
                const fnc = t[1];
                const handler = this.injector.get(srv);
                let contentCardList = handler[fnc](data, config);
                return contentCardList;
            }
        }
    }

    getContentCardsByTypeConfig(configObjets, data: any[]): SummaryCardData[] {
        let cards: SummaryCardData[] = [];
        for (let i = 0; i < data.length; i++) {
            let dataItem = data[i];
            if (configObjets[dataItem['type']]) {
                cards = cards.concat(this.getContentCardsByConfig(configObjets[dataItem['type']], [dataItem]));
            }else{
                cards = cards.concat(this.getContentCardsByConfig(configObjets['generic'], [dataItem]));
            }
        }
        return cards;
    }

}
