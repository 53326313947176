import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule, MatSelectModule, MatTableModule } from '@angular/material';
import { TranslateModule } from '@ngx-translate/core';
import { ContentStripModule } from '@tc/content-strip';
import { CurrencyFieldModule } from '@tc/currency-field';
import { CurrencyInputModule } from '@tc/currency-input';
import { DirectivesModule } from '@tc/directives';
import { ExpandedContentCardModule } from '@tc/expanded-content-card';
import { PipesModule } from '@tc/pipes';
import { ResourceExpandedContentHeaderModule } from '../../../../../widgets/shared/resource-expanded-content-header/resource-expanded-content-header.module';
import { ResultContentCardResourceAssignModule } from '../../../../../widgets/shared/result-content-card-resource-assign/result-content-card-resource-assign.module';
import { SupplierAssignDetailViewModule } from '../../../../../widgets/shared/supplier-assign-detail-view/supplier-assign-detail-view.module';
import { SupplierAllocationDetailViewComponent } from '../supplier-allocation-detail-view/supplier-allocation-detail-view.component';
import { AdvSupplierAllocationSearchResultsComponent } from './adv-supplier-allocation-search-results.component';

@NgModule({
    declarations: [
        AdvSupplierAllocationSearchResultsComponent,
        SupplierAllocationDetailViewComponent
    ],
    exports: [
        AdvSupplierAllocationSearchResultsComponent,
        SupplierAllocationDetailViewComponent
    ],
    imports: [
        CommonModule,
        ContentStripModule,
        ResultContentCardResourceAssignModule,
        ExpandedContentCardModule,
        ResourceExpandedContentHeaderModule,
        SupplierAssignDetailViewModule,
        DirectivesModule,
        TranslateModule.forChild(),
        MatInputModule,
        ReactiveFormsModule,
        TranslateModule.forChild(),
        MatSelectModule,
        FormsModule,
        MatTableModule,
        CurrencyInputModule,
        CurrencyFieldModule,
        PipesModule
    ],
    entryComponents: [
        SupplierAllocationDetailViewComponent
    ]
})
export class AdvSupplierAllocationSearchResultsModule {}
