    <div class="container-fluid direct-client-contact-details-edit tc-card-wrapper">
        <div class="row" style="border-bottom: 1px solid #eee;">
            <div class="col-md-12 col-lg-12">
                <div class="tc-flex-box">
                    <div class="tc-flex-item tc-item-circle">
                        <div class="icon-box">
                            <i class="material-icons" aria-hidden="true">format_list_bulleted</i>
                        </div>
                    </div>
                    <div class="tc-flex-item tc-right-cage">
                        <div class="row">
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                <div class="tc-select-edit dcpiec-select-title">
                                    <mat-form-field>
                                        <input
                                                id="item-name"
                                                matInput
                                                [placeholder]="'Item Name'|translate"
                                                [value]="bookingItem?.itemName? bookingItem.itemName:'--'"
                                                readonly>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                <div class="tc-input-edit dcpiec-input-format">
                                    <mat-form-field>
                                        <input
                                                id="contract-reference"
                                                matInput
                                                [placeholder]="'Contract Reference'|translate"
                                                [value]="bookingItem?.contractReference? bookingItem.contractReference:'--'"
                                                readonly>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                <div class="tc-input-edit dcpiec-input-dispatchMethod">
                                    <mat-form-field>
                                        <input
                                                id="pl-code"
                                                matInput
                                                [placeholder]="'PL Code'|translate"
                                                [value]="bookingItem?.plCode? bookingItem.plCode:'--'"
                                                readonly>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                <div class="tc-input-edit dcpiec-input-dispatchMethod">
                                    <mat-form-field>
                                        <input
                                                id="default-supplier"
                                                matInput
                                                [placeholder]="'Default Supplier'|translate"
                                                [value]="bookingItem?.defaultSupplierName? bookingItem.defaultSupplierName:'--'"
                                        readonly>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                <div class="tc-input-edit dcpiec-input-frequency">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                <div class="tc-select-edit dcpiec-select-title">
                                    <!--                  <mat-form-field>-->
                                    <!--                    <input-->
                                    <!--                            matInput-->
                                    <!--                            formControlName="elementGroup"-->
                                    <!--                            [placeholder]="'Element Group'|translate"-->
                                    <!--                            required>-->
                                    <!--                  </mat-form-field>-->

                                    <mat-form-field>
                                        <input
                                                id="element-group"
                                                matInput
                                                [placeholder]="'Element Group'|translate"
                                                [value]="bookingItem.genBookingItem?.productName? bookingItem.genBookingItem.productName:'--'"
                                        readonly>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                <div class="tc-input-edit dcpiec-input-format">
                                    <!--                  <mat-form-field>-->
                                    <!--                    <input-->
                                    <!--                            matInput-->
                                    <!--                            formControlName="typeName"-->
                                    <!--                            [placeholder]="'Type'|translate">-->
                                    <!--                  </mat-form-field>-->
                                    <mat-form-field>
                                        <input
                                                id="type"
                                                matInput
                                                [placeholder]="'Type'|translate"
                                                [value]="bookingItem.genBookingItem?.typeName? bookingItem.genBookingItem.typeName:'--'"
                                        readonly>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                <div class="tc-input-edit dcpiec-input-frequency">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                <div class="tc-input-edit dcpiec-input-frequency">
                                    <mat-form-field>
                                        <input
                                                id="adult-count"
                                                matInput
                                                [placeholder]="'Adult Count'|translate"
                                                [value]="bookingItem.adultCount ? bookingItem.adultCount:0"
                                                readonly>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                <div class="tc-input-edit dcpiec-input-frequency">
                                    <mat-form-field>
                                        <input
                                                id="child-count"
                                                matInput
                                                [placeholder]="'Child Count'|translate"
                                                [value]="bookingItem.childrenCount ? bookingItem.childrenCount:0"
                                                readonly>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                <div class="tc-input-edit dcpiec-input-frequency">
                                    <mat-form-field>
                                        <input
                                                id="teen-count"
                                                matInput
                                                [placeholder]="'Teen Count'|translate"
                                                [value]="bookingItem.teenCount ? bookingItem.teenCount : 0"
                                                readonly>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                <div class="tc-input-edit dcpiec-input-frequency">
                                    <mat-form-field>
                                        <input
                                                id="infant-count"
                                                matInput
                                                [placeholder]="'Infant Count'|translate"
                                                [value]="bookingItem.infantCount ? bookingItem.infantCount : 0"
                                                readonly>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                <div class="tc-select-edit dcpiec-select-title">
                                    <mat-form-field>
                                        <input
                                                id="start-date"
                                                matInput
                                                [placeholder]="'Start Date'|translate"
                                                [value]="(bookingItem?.startTime? bookingItem.startTime:'--') | date:'yyyy-MM-dd h:mm a':'GMT'"
                                                readonly>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                <div class="tc-input-edit dcpiec-input-format">
                                    <mat-form-field>
                                        <input
                                                id="end-date"
                                                matInput
                                                [placeholder]="'End Date'|translate"
                                                [value]="(bookingItem?.endTime? bookingItem.endTime:'--') | date:'yyyy-MM-dd h:mm a':'GMT'"
                                                readonly>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <tc-item-notes
                    [readonly]="true"
                    [noteList]="bookingItem.itemNotes"
                    [heading]="'Notes'"
                    class="bdv-booking-notes-summary-wrapper">
            </tc-item-notes>
        </div>
    </div>
