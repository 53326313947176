import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DirectivesModule} from '@tc/directives';
import {SummaryStripModule} from '@tc/summary-strip';
import {ContentStripModule} from '@tc/content-strip';
import {MatExpansionModule, MatInputModule, MatMenuModule, MatSelectModule} from '@angular/material';
import {ExpandedContentCardBodyModule} from '@tc/expanded-content-card-body';
import {PipesModule} from '@tc/pipes';
import {MatFormFieldModule} from '@angular/material/typings/form-field';
import {MatAutocompleteModule} from '@angular/material/typings/autocomplete';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {AdditionalContactsComponent} from './additional-contacts.component';
import {ResultContentCardModule} from '../result-content-card/result-content-card.module';
import {AdditionalContactSummaryCardModule} from '../additional-contact-summary-card/additional-contact-summary-card.module';
import {AdditionalContactEditCardModule} from '../additional-contact-edit-card/additional-contact-edit-card.module';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule.forChild(),
        PipesModule,
        ExpandedContentCardBodyModule,
        MatExpansionModule,
        ContentStripModule,
        ResultContentCardModule,
        DirectivesModule,
        SummaryStripModule,
        AdditionalContactSummaryCardModule,
        AdditionalContactEditCardModule
    ],
    exports: [AdditionalContactsComponent],
    declarations: [AdditionalContactsComponent]
})
export class AdditionalContactsModule {
}
