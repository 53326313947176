<div class="header-panel-section tc-px-2 tc-py-1">
    <div class="tc-icon-block">
        <button class="tc-icon-block__icon sort-icon tc-icon-block__icon--active" id="saveBtn"
                mat-icon-button
                matTooltip="{{'Save' | translate}}"
                [disabled]="!saveButtonEnable"
                (click)="onSaveAssignmentsClick()">
            <i class="material-icons">save</i>
        </button>
        <button class="tc-icon-block__icon sort-icon tc-icon-block__icon--active" id="revertBtn"
                mat-icon-button
                matTooltip="{{'Revert' | translate}}"
                [disabled]="!revertButtonEnable"
                (click)="onRevertAssignmentsClick()">
            <i class="material-icons">settings_backup_restore</i>
        </button>
        <button class="tc-icon-block__icon sort-icon tc-icon-block__icon--active" id="confirmBtn"
                *ngIf="showConfirmButton"
                mat-icon-button
                matTooltip="{{'Confirm' | translate}}"
                [disabled]="!enableConfirmButton()"
                (click)="onConfirmClick()">
            <i class="material-icons">check</i>
        </button>
<!--        <button class="tc-icon-block__icon sort-icon tc-icon-block__icon&#45;&#45;active" id="sendBtn"-->
<!--                mat-icon-button-->
<!--                matTooltip="{{'Send Manifest' | translate}}"-->
<!--                [disabled]="!sendManifestButtonEnable"-->
<!--                (click)="onSendManifestsClick()">-->
<!--            <i class="material-icons">send</i>-->
<!--        </button>-->
        <button class="tc-icon-block__icon sort-icon tc-icon-block__icon--active" id="thumbUpBtn"
                mat-icon-button
                matTooltip="{{'Supplier Confirm' | translate}}"
                [disabled]="!operationV2DataProcessorService.supplierConfirmButtonEnable"
                (click)="onSupplierConfirmClick()">
            <i class="material-icons">thumb_up_off_alt</i>
        </button>
        <button class="tc-icon-block__icon sort-icon tc-icon-block__icon--active" id="thumbDownBtn"
                mat-icon-button
                matTooltip="{{'Supplier Reject' | translate}}"
                [disabled]="!operationV2DataProcessorService.supplierRejectButtonEnable"
                (click)="onSupplierRejectClick()">
            <i class="material-icons">thumb_down_off_alt</i>
        </button>
        <button class="tc-icon-block__icon sort-icon tc-icon-block__icon--active" id="descBtn"
                mat-icon-button
                matTooltip="{{'Documents' | translate}}"
                [disabled]="chipSelected()"
                (click)="onGenerateDocumentsClick()">
            <i class="material-icons">description</i>
        </button>
        <button class="tc-icon-block__icon sort-icon tc-icon-block__icon--active" id="autoAllocBtn"
                *ngIf="showAutoAllocationButton"
                mat-icon-button
                matTooltip="{{'Supplier Auto Allocation' | translate}}"
                [disabled]="!operationV2DataProcessorService.autoAllocationButtonEnable"
                (click)="onSupplierAutoAllocationClick()">
            <i class="material-icons">psychology_outline</i>
        </button>
        <button class="tc-icon-block__icon sort-icon tc-icon-block__icon--active" id="autoResourceAllocBtn"
                *ngIf="showAutoAllocationButton"
                mat-icon-button
                matTooltip="{{'Resource Auto Allocation' | translate}}"
                [disabled]="!operationV2DataProcessorService.autoAllocationButtonEnable"
                (click)="onResourceAutoAllocationClick()">
            <i class="material-icons">psychology</i>
        </button>
        <button
                class="tc-icon-block__icon sort-icon tc-icon-block__icon--active" id="saveCriteriaBtn"
                mat-icon-button
                matTooltip="{{'Save Criteria' | translate}}"
                (click)="onSaveCriteriaClick()">
            <i class="material-icons">save_alt</i>
        </button>
        <button class="tc-icon-block__icon sort-icon tc-icon-block__icon--active" id="showSavedCriteriaBtn"
                mat-icon-button
                matTooltip="{{'Show Saved Criteria' | translate}}"
                (click)="onShowSavedCriteriaClick()">
            <i class="material-icons">remove_red_eye</i>
        </button>
    </div>
</div>

<tc-product-queue
    #productQueueComponent
    [operationPanelConfig]="operationPanelConfig"
    [columnDefs]="productQueueColDefConfig"

    (allProducts)="setAllProducts($event)"
    (selectionChanged)="onProductSelectionChange($event)">
</tc-product-queue>


<div class="tc-position-rel" [style.display]="showAllocationQueues?'block':'none'">
    <mat-tab-group
            [selectedIndex]="0"
            [animationDuration]="'0.5s'"
            (selectedTabChange)="onTabChange($event)">
        <mat-tab *ngIf="showServicingQueue"
                label="{{'Servicing'|translate}}"
                [disabled]="operationPanelConfig.preventSwitching && operationPanelConfig.currentTab!=0">
            <ng-template matTabContent>
                <tc-service-queue
                    #serviceQueueComponent
                    [operationPanelConfig]="operationPanelConfig"
                    [tabIndex]="0"
                    [columnDefs]="serviceQueueColDefConfig"
                    [allColumnDefs]="serviceQueueAllColDefConfig"
                    [primaryColumnDefs]="serviceQueuePrimaryColDefConfig"
                    [secondaryColumnDefs]="serviceQueueSecondaryColDefConfig"
                    [selectedProducts]="selectedProducts"
                    [serviceQueueTimeGroupingOptions]="serviceQueueTimeGroupingOptions"
                    [frameworkComponents]="frameworkComponents">
                </tc-service-queue>
            </ng-template>
        </mat-tab>
        <mat-tab  *ngIf="showBookingQueue"
                label="{{'Booking'|translate}}"
                [disabled]="operationPanelConfig.preventSwitching && operationPanelConfig.currentTab!=1">
            <ng-template matTabContent>
                <tc-booking-queue
                    #bookingQueueComponent
                    [operationPanelConfig]="operationPanelConfig"
                    [tabIndex]="1"
                    [columnDefs]="bookingQueueColDefConfig"
                    [allColumnDefs]="bookingQueueAllColDefConfig"
                    [primaryColumnDefs]="bookingQueuePrimaryColDefConfig"
                    [secondaryColumnDefs]="bookingQueueSecondaryColDefConfig"
                    [selectedProducts]="selectedProducts"
                    [frameworkComponents]="frameworkComponents"
                >
                </tc-booking-queue>
            </ng-template>
        </mat-tab>
    </mat-tab-group>
</div>

<ng-container *ngIf="!showAllocationQueues">
    <div class="tc-ag-grid-empty__container">
        <div class="tc-ag-grid-empty tc-ag-grid-empty--full-height">
            <i class="material-icons tc-ag-grid-empty__icon">assignment_error</i>
            <span class="tc-ag-grid-empty__text">{{'No Products Selected' | translate}}</span>
        </div>
    </div>
</ng-container>


<!--status and incomplete view-->
<!-- Html template-->


<ng-template #assignStatusCell let-row>

    <ng-template #HtmlTemplate>
        {{getAssignmentsIncompleteMsg(row)}}
    </ng-template>

    <ng-template #HtmlStatusTemplate>
        {{getStatusToolTip(row)}}
    </ng-template>

    <ng-template #HtmlManifestStatusTemplate>
        {{getManifestStatusToolTip(row)}}
    </ng-template>

    <div
            class="tc-ag-grid-status-mark tc-ag-grid-status-mark--danger"
            *ngIf="isOnRequest(row)"
            [matTooltip]="getStatusToolTip(row)"
            content-type="template">
        <mat-icon class="tc-text">info_outline</mat-icon>
    </div>

    <div
            class="tc-ag-grid-status-mark tc-ag-grid-status-mark--success"
            *ngIf="isConfirm(row)"
            [matTooltip]="getStatusToolTip(row)"
            content-type="template">
        <mat-icon class="tc-text">info_outline</mat-icon>
    </div>

    <div
            class="tc-ag-grid-status-second-mark tc-ag-grid-status-mark--danger"
            *ngIf="isManifestStatusNOT_SENT(row)"
            [matTooltip]="getManifestStatusToolTip(row)"
            content-type="template">
        <mat-icon class="tc-text">info_outline</mat-icon>
    </div>

    <div
            class="tc-ag-grid-status-second-mark tc-ag-grid-status-mark--success"
            *ngIf="isManifestStatusSENT(row)"
            [matTooltip]="getManifestStatusToolTip(row)"
            content-type="template">
        <mat-icon class="tc-text">info_outline</mat-icon>
    </div>

    <div
            class="tc-ag-grid-status-second-mark tc-ag-grid-status-mark--danger"
            *ngIf="isManifestStatusUPDATED(row)"
            [matTooltip]="getManifestStatusToolTip(row)"
            content-type="template">
        <mat-icon class="tc-text">info_outline</mat-icon>
    </div>

    <div
            class="tc-ag-grid-status-second-mark tc-ag-grid-status-mark--danger"
            *ngIf="isManifestStatusNEW(row)"
            [matTooltip]="getManifestStatusToolTip(row)"
            content-type="template">
        <mat-icon class="tc-text">info_outline</mat-icon>
    </div>

    <div
            class="tc-ag-grid-alert tc-ag-grid-alert--danger"
            *ngIf="isAssignmentsIncomplete(row)"
            [matTooltip]="getAssignmentsIncompleteMsg(row)"
            content-type="template">
        <mat-icon class="tc-text">info_outline</mat-icon>
    </div>

    <div class="tc-status-badge-group">

        <ng-container *ngIf="isStatusBubbleVisible(row)">
            <!-- primary-->
            <div
                    class="tc-status-badge"
                    [ngClass]="{
                'tc-status-badge--inactive':
                row?.primaryAssignment?.assignStatus===PENDING||
                row?.primaryAssignment?.assignStatus===ASSIGNED||
                row?.primaryAssignment?.assignStatus===UNASSIGNED,
                'tc-status-badge--warning':
                row?.primaryAssignment?.assignStatus===DMC_CONFIRM ||
                row?.primaryAssignment?.assignStatus===INACTIVE_NOTIFY,
                'tc-status-badge--success':
                row?.primaryAssignment?.assignStatus===COMPLETED ||
                row?.primaryAssignment?.assignStatus===NO_SHOW ||
                row?.primaryAssignment?.assignStatus===INACTIVE,
                'tc-status-badge--danger':
                row?.primaryAssignment?.assignStatus===REQUEST_EXPIRED ||
                row?.primaryAssignment?.assignStatus===SUPPLIER_REJECTED ||
                row?.primaryAssignment?.assignStatus===BOOKING_UPDATE,
                'tc-status-badge--info':
                row?.primaryAssignment?.assignStatus===SUPPLIER_CONFIRMED ||
                row?.primaryAssignment?.assignStatus===STARTED
            }"
                    matTooltip="{{row?.primaryAssignment?.assignStatus}}">
                <span class="tc-status-badge__text">{{primaryAssignmentStatusCode(row)}}</span>

            </div>

            <!-- secondary-->
            <div *ngIf="showSecondaryJobStatus"
                    class="tc-status-badge"
                    [ngClass]="{
                'tc-status-badge--inactive':
                row?.secondaryAssignment?.assignStatus===PENDING||
                row?.secondaryAssignment?.assignStatus===ASSIGNED||
                row?.secondaryAssignment?.assignStatus===UNASSIGNED,
                'tc-status-badge--warning':
                row?.secondaryAssignment?.assignStatus===DMC_CONFIRM ||
                row?.secondaryAssignment?.assignStatus===INACTIVE_NOTIFY,
                'tc-status-badge--success':
                row?.secondaryAssignment?.assignStatus===COMPLETED ||
                row?.secondaryAssignment?.assignStatus===NO_SHOW ||
                row?.secondaryAssignment?.assignStatus===INACTIVE,
                'tc-status-badge--danger':
                row?.secondaryAssignment?.assignStatus===REQUEST_EXPIRED ||
                row?.secondaryAssignment?.assignStatus===SUPPLIER_REJECTED ||
                row?.secondaryAssignment?.assignStatus===BOOKING_UPDATE,
                'tc-status-badge--info':
                row?.secondaryAssignment?.assignStatus===SUPPLIER_CONFIRMED ||
                row?.secondaryAssignment?.assignStatus===STARTED
            }" matTooltip="{{row?.secondaryAssignment?.assignStatus}}">>
                <span class="tc-status-badge__text">{{secondaryAssignmentStatusCode(row)}}</span>
            </div>
        </ng-container>
        <ng-container *ngIf="!isStatusBubbleVisible(row)">
            <div class="tc-ml-1 tc-px-3" matTooltip="{{'No Supplier linked'}}">
                {{EMPTY_STRING}}
            </div>
        </ng-container>
    </div>

    <!--    <div-->
    <!--            *ngIf="hasPassengerAttributes(row)"-->
    <!--            class="tc-ag-grid-indicator tc-ag-grid-indicator&#45;&#45;info"-->
    <!--            matTooltip="{{getPassengerAttributesToolTip(row)}}"-->
    <!--            matTooltipPosition="right"-->
    <!--            aria-label="indicator tooltip"-->
    <!--            matTooltipClass="mat-tooltip&#45;&#45;large mat-tooltip&#45;&#45;success">-->
    <!--    </div>-->

    <!--        ToDo: Create like this layout Dushan-->
    <!--    <ng-template #HtmlTemplate2>-->
    <!--        <div><strong>Title</strong></div>-->
    <!--        <div>Size: XX</div>-->
    <!--        <div>Weight : XX</div>-->
    <!--    </ng-template>-->

<!--    <ng-template #HtmlTemplate2>-->
<!--        {{getPassengerAttributesToolTip(row)}}-->
<!--    </ng-template>-->

<!--    <div-->
<!--            class="tc-ag-grid-alert tc-ag-grid-alert&#45;&#45;info"-->
<!--            *ngIf="hasPassengerAttributes(row)"-->
<!--            [tooltip]="HtmlTemplate2"-->
<!--            content-type="template">-->
<!--        <mat-icon class="tc-text">info_outline</mat-icon>-->
<!--    </div>-->

</ng-template>


<!--menu column view-->
<ng-template #menuCell let-row let-params="params">
    <div class="tc-icon-block">
        <button
                mat-icon-button
                [matMenuTriggerFor]="menu"
                aria-label="Service queue assignment menu"
                matTooltip="{{'More actions' | translate}}"
                class="tc-m-0 tc-icon-block__icon tc-icon-block__icon--active">
            <mat-icon>more_vert</mat-icon>
        </button>
    </div>
    <mat-menu #menu="matMenu">
        <button *ngIf="!isHiddenTimeRangeKebabButton"
                mat-menu-item
                [disabled]="isEditTimeRestricted(params)"
                (click)="onTimeRangeChangeClick(params)">
            <mat-icon>schedule</mat-icon>
            <span>{{'Edit Time Range' | translate}}</span>
        </button>
        <button *ngIf="!isHiddenPrimaryResourceKebabButton"
                mat-menu-item
                [disabled]="isPrimaryAssignmentRestricted(params)"
                (click)="onPrimaryAssignmentClick(params)">
            <mat-icon>looks_one</mat-icon>
            <span>{{'Primary Resource' | translate}}</span>
        </button>
        <button *ngIf="!isHiddenSecondaryResourceKebabButton"
                mat-menu-item
                [disabled]="isSecondaryAssignmentRestricted(params)"
                (click)="onSecondaryAssignmentClick(params)">
            <mat-icon>looks_two</mat-icon>
            <span>{{'Secondary Resource' | translate}}</span>
        </button>
        <button *ngIf="!isHiddenPassengersKebabButton"
                mat-menu-item
                (click)="onSelectPassengersClick(params)">
            <mat-icon>people</mat-icon>
            <span>{{'Passengers' | translate}}</span>
        </button>
        <button *ngIf="!isHiddenNotesKebabButton"
                mat-menu-item
                (click)="onNotesClick(params)">
            <mat-icon>note</mat-icon>
            <span>{{'Notes' | translate}}</span>
        </button>
        <button *ngIf="!isHiddenSupplementsKebabButton"
                mat-menu-item
                (click)="onSupplementsClick(params)">
            <mat-icon>list_alt</mat-icon>
            <span>{{'Supplements' | translate}}</span>
        </button>
    </mat-menu>
</ng-template>

<!--total cost column view-->
<ng-template #totalCostCell let-row let-params="params">
    <div (dblclick)="onTotalCostClick(params)">
        <span>{{getServiceBookingQueueTotalCost(params)}}</span>
    </div>
</ng-template>
