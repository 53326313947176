/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../node_modules/@tc/launch-pad/launch-pad.component.ngfactory";
import * as i2 from "@tc/launch-pad/launch-pad.component";
import * as i3 from "./launch-pad.component";
import * as i4 from "@tc-core/util/framework/config-loader.service";
import * as i5 from "@tc-core/util/ui/page-summary.service";
import * as i6 from "@tc-core/util/ui/side-nav-bar.service";
import * as i7 from "@tc-core/util/framework/user-journey-manager.service";
import * as i8 from "../../../services/util/framework/data-store.service";
import * as i9 from "@tc-core/util/framework/event-manager.service";
import * as i10 from "../../../services/backend-consumers/reservation-management-services/reservation-management.service";
import * as i11 from "../../../services/backend-consumers/reservation-v2/reservation-v2-management.service";
import * as i12 from "../../../services/util/framework/dmc-query-params.service";
import * as i13 from "../../../services/util/core-services/root.service";
var styles_LaunchPadPageComponent = [];
var RenderType_LaunchPadPageComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_LaunchPadPageComponent, data: {} });
export { RenderType_LaunchPadPageComponent as RenderType_LaunchPadPageComponent };
export function View_LaunchPadPageComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { launchPad: 0 }), (_l()(), i0.ɵeld(1, 0, null, null, 1, "tc-launch-pad", [], null, [[null, "select"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("select" === en)) {
        var pd_0 = (_co.onActionClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_LaunchPadComponent_0, i1.RenderType_LaunchPadComponent)), i0.ɵdid(2, 638976, [[1, 4], ["launch_pad", 4]], 0, i2.LaunchPadComponent, [i0.ElementRef], { blocks: [0, "blocks"], isBlockFocus: [1, "isBlockFocus"], focusActionIndex: [2, "focusActionIndex"], focusBlockIndex: [3, "focusBlockIndex"] }, { select: "select" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.blocks; var currVal_1 = _co.isBlockFocus; var currVal_2 = _co.focusActionIndex; var currVal_3 = _co.focusBlockIndex; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_LaunchPadPageComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-launch-pad-page", [], null, null, null, View_LaunchPadPageComponent_0, RenderType_LaunchPadPageComponent)), i0.ɵdid(1, 114688, null, 0, i3.LaunchPadPageComponent, [i4.ConfigLoader, i5.PageSummaryService, i6.SideNavBarService, i7.UserJourneyManager, i8.DataStoreService, i9.EventManager, i10.ReservationManagementService, i11.ReservationV2ManagementService, i12.DMCQueryParamsService, i13.RootService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LaunchPadPageComponentNgFactory = i0.ɵccf("tc-launch-pad-page", i3.LaunchPadPageComponent, View_LaunchPadPageComponent_Host_0, {}, {}, []);
export { LaunchPadPageComponentNgFactory as LaunchPadPageComponentNgFactory };
