import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { LEVEL, ModalData } from '@tc-core/model/it/codegen/ui/framework/modal-data';
import { ConfigLoader } from '@tc-core/util/framework/config-loader.service';
import { FocusViewService } from '@tc/focus-view/focus-view.service';
import { ModalService } from '@tc/modal/modal.service';
import { Subscription } from 'rxjs';
import { TCO } from '../../../../constants';
import { GenResourceCost, GenResourceCostSetup } from '../../../../models/supplier/gen-resource-cost-setup';
import { RoomResourceCost, RoomResourceCostSetup } from '../../../../models/supplier/room-resource-cost';
import { Season } from '../../../../models/supplier/season';
import { Supplier } from '../../../../models/supplier/supplier';
import { TrsResourceCost, TrsResourceCostSetup } from '../../../../models/supplier/trs-resource-cost-setup';
import { DataHandlerService } from '../../../../services/backend-consumers/master-data-handler-service/data-handler.service';
import { MasterDataRestService } from '../../../../services/backend-consumers/master-data-handler-service/master-data-rest.service';
import { DataKey, DataStoreService } from '../../../../services/util/framework/data-store.service';
import { GenericCostSetupFocusViewComponent } from '../generic-cost-setup-focus-view/generic-cost-setup-focus-view.component';
import { RoomCostSetupFocusViewComponent } from '../room-cost-setup-focus-view/room-cost-setup-focus-view.component';
import { SeasonSetupFocusViewComponent } from '../season-setup-focus-view/season-setup-focus-view.component';
import { TransportCostSetupQueueFocusViewComponent } from '../transport-cost-setup-queue-focus-view/transport-cost-setup-queue-focus-view.component';

@Component({
    selector: 'tc-supplier-cost-setup',
    templateUrl: './supplier-cost-setup.component.html'
})
export class SupplierCostSetupComponent implements OnInit, OnDestroy {
    traResourceCosts: TrsResourceCostSetup[] = [];
    genResourceCosts: GenResourceCostSetup[] = [];
    roomResourceCosts: RoomResourceCostSetup[] = [];
    @Input() resourceCostData: Season[] = [];
    @Input() showAddButton = true;
    @Input() supplier: Supplier;
    @Input() selectedResourceTypes: any[] = [];
    @Input() resourceTypes: any[] = [];
    @Input() selectedProfileTypes: any[] = [];
    @Input() routes = [];
    @Input() transferModes = [];

    daysOfWeek: any[] = [];
    currencyList: any[] = [];
    costingTypeList: any[] = [];
    private focusviewCloseSubject: Subscription = new Subscription();
    private vehicleModels: any[] = [];
    private defaultProfileType =
        {
            code: 'ANY',
            name: 'Any'
        };

    private trsCostChangeSub: Subscription = new Subscription();
    private genCostChangeSub: Subscription = new Subscription();
    private roomCostChangeSub: Subscription = new Subscription();

    constructor(
        private modalService: ModalService,
        private dataStore: DataStoreService,
        private focusViewService: FocusViewService,
        private dataHandlerService: DataHandlerService,
        private configLoader: ConfigLoader,
        private masterDataRestService: MasterDataRestService
    ) {
    }

    ngOnInit() {
        this.dataStore.set(DataKey.resourceCostData, null, true);
        this.dataStore.set(DataKey.trsResourceCosts, null, true);
        this.dataStore.set(DataKey.genResourceCosts, null, true);
        this.dataStore.set(DataKey.roomResourceCosts, null, true);

        // this.resourceCosts = this.resourceCostData;
        this.getUpdatedResourceCostData();

        this.dataHandlerService.retrieveDaysOfWeek().subscribe(days => {
            if (days !== null) {
                this.daysOfWeek = days.data;
                this.getCurrencyList();
            }
        });

    }

    getCurrencyList() {
        this.dataStore.get(DataKey.currencyList).subscribe(value => {
            this.currencyList = value;
            this.getCostingTypeList();
        });
    }

    getCostingTypeList() {
        this.masterDataRestService.getCostingTypes().subscribe(
            result => {
                if(result && result.data)
                {
                    this.costingTypeList = result.data
                    this.getResourceCost();
                }
            });
    }

    getResourceCost() {
        if (this.resourceCostData.length > 0) {
            this.addDefaultProfileType(this.selectedProfileTypes);
            // setting temp ids to seasons
            this.setTempIdsToSeasons();

            let tempCostId = 0;
            for (let i = 0; i < this.resourceCostData.length; i++) {
                if (this.resourceCostData[i].trsResourceCosts.length > 0) {
                    for (let j = 0; j < this.resourceCostData[i].trsResourceCosts.length; j++) {
                        let days = this.getDays(this.resourceCostData[i].trsResourceCosts[j].days);
                        let daysArr = this.getDaysArr(this.resourceCostData[i].trsResourceCosts[j].days);
                        tempCostId++;
                        this.resourceCostData[i].trsResourceCosts[j].tempCostId = tempCostId;
                        let resourceCost = new TrsResourceCostSetup();
                        resourceCost.tempCostId = tempCostId;
                        resourceCost.transferMode = this.filterObject(
                            this.resourceCostData[i].trsResourceCosts[j].transferMode,
                            this.transferModes
                        );
                        resourceCost.vehicleModel = this.filterObject(
                            this.resourceCostData[i].trsResourceCosts[j].vehicleModel,
                            this.vehicleModels
                        );
                        resourceCost.route = this.filterObject(
                            this.resourceCostData[i].trsResourceCosts[j].route,
                            this.routes
                        );
                        resourceCost.currency = this.resourceCostData[i].trsResourceCosts[j].currency;
                        resourceCost.costingType = this.filterObject(
                            this.resourceCostData[i].trsResourceCosts[j].costingType,
                            this.costingTypeList
                        );
                        resourceCost.seasonName = this.resourceCostData[i].name;
                        resourceCost.tempSeasonId = this.resourceCostData[i].tempSeasonId;
                        resourceCost.unitFare = this.resourceCostData[i].trsResourceCosts[j].unitFare;
                        resourceCost.adultFare = this.resourceCostData[i].trsResourceCosts[j].adultFare;
                        resourceCost.teenFare = this.resourceCostData[i].trsResourceCosts[j].teenFare;
                        resourceCost.childFare = this.resourceCostData[i].trsResourceCosts[j].childFare;
                        resourceCost.infantFare = this.resourceCostData[i].trsResourceCosts[j].infantFare;
                        resourceCost.dayValues = days;
                        resourceCost.dayArray = daysArr;
                        resourceCost.dayKeys = this.resourceCostData[i].trsResourceCosts[j].days;
                        resourceCost.isEdit = false;
                        this.traResourceCosts.push(resourceCost);
                        // this.resourceCosts.splice(0,0,resourceCost);
                    }
                }
                if (this.resourceCostData[i].genResourceCosts.length > 0) {
                    for (let j = 0; j < this.resourceCostData[i].genResourceCosts.length; j++) {
                        let days = this.getDays(this.resourceCostData[i].genResourceCosts[j].days);
                        let daysArr = this.getDaysArr(this.resourceCostData[i].genResourceCosts[j].days);
                        tempCostId++;
                        this.resourceCostData[i].genResourceCosts[j].tempCostId = tempCostId;
                        let resourceCost = new GenResourceCostSetup();
                        resourceCost.tempCostId = tempCostId;
                        // resourceCost.resourceType = this.selectedResourceTypes.filter(value => value && value.code === this.resourceCostData[i].genResourceCosts[j].resourceType)[0];
                        resourceCost.resourceType = this.filterObject(
                            this.resourceCostData[i].genResourceCosts[j].resourceType,
                            this.selectedResourceTypes.length > 0 ? this.selectedResourceTypes : this.resourceTypes
                        );
                        // resourceCost.profileType = this.selectedProfileTypes.filter(value => value && value.code === this.resourceCostData[i].genResourceCosts[j].profileType)[0];
                        resourceCost.profileType = this.filterObject(
                            this.resourceCostData[i].genResourceCosts[j].profileType,
                            this.selectedProfileTypes
                        );
                        resourceCost.seasonName = this.resourceCostData[i].name;
                        resourceCost.tempSeasonId = this.resourceCostData[i].tempSeasonId;
                        resourceCost.currency = this.resourceCostData[i].genResourceCosts[j].currency;
                        resourceCost.costingType = this.filterObject(
                            this.resourceCostData[i].genResourceCosts[j].costingType, this.costingTypeList);
                        resourceCost.unitFare = this.resourceCostData[i].genResourceCosts[j].unitFare;
                        resourceCost.adultFare = this.resourceCostData[i].genResourceCosts[j].adultFare;
                        resourceCost.teenFare = this.resourceCostData[i].genResourceCosts[j].teenFare;
                        resourceCost.childFare = this.resourceCostData[i].genResourceCosts[j].childFare;
                        resourceCost.infantFare = this.resourceCostData[i].genResourceCosts[j].infantFare;
                        resourceCost.dayValues = days;
                        resourceCost.dayArray = daysArr;
                        resourceCost.dayKeys = this.resourceCostData[i].genResourceCosts[j].days;
                        resourceCost.isEdit = false;
                        this.genResourceCosts.push(resourceCost);
                        // this.resourceCosts.splice(0,0,resourceCost);
                    }
                }
                if (this.resourceCostData[i].roomResourceCosts.length > 0) {
                    for (let j = 0; j < this.resourceCostData[i].roomResourceCosts.length; j++) {
                        const days = this.getDays(this.resourceCostData[i].roomResourceCosts[j].days);
                        const daysArr = this.getDaysArr(this.resourceCostData[i].roomResourceCosts[j].days);
                        tempCostId++;
                        this.resourceCostData[i].roomResourceCosts[j].tempCostId = tempCostId;
                        const resourceCost = new RoomResourceCostSetup();
                        resourceCost.tempCostId = tempCostId;
                        resourceCost.seasonName = this.resourceCostData[i].name;
                        resourceCost.tempSeasonId = this.resourceCostData[i].tempSeasonId;
                        resourceCost.currency = this.resourceCostData[i].roomResourceCosts[j].currency;
                        resourceCost.single = this.resourceCostData[i].roomResourceCosts[j].single;
                        resourceCost.twin = this.resourceCostData[i].roomResourceCosts[j].twin;
                        resourceCost.extraBed = this.resourceCostData[i].roomResourceCosts[j].extraBed;
                        resourceCost.nights = this.resourceCostData[i].roomResourceCosts[j].nights;
                        resourceCost.roomType = this.resourceCostData[i].roomResourceCosts[j].roomType;
                        resourceCost.boardBasis = this.resourceCostData[i].roomResourceCosts[j].boardBasis;
                        resourceCost.unitFare = this.resourceCostData[i].roomResourceCosts[j].unitFare;
                        resourceCost.adultFare = this.resourceCostData[i].roomResourceCosts[j].adultFare;
                        resourceCost.teenFare = this.resourceCostData[i].roomResourceCosts[j].teenFare;
                        resourceCost.childFare = this.resourceCostData[i].roomResourceCosts[j].childFare;
                        resourceCost.infantFare = this.resourceCostData[i].roomResourceCosts[j].infantFare;
                        resourceCost.costingType = this.resourceCostData[i].roomResourceCosts[j].costingType;
                        resourceCost.dayValues = days;
                        resourceCost.dayArray = daysArr;
                        resourceCost.dayKeys = this.resourceCostData[i].roomResourceCosts[j].days;
                        resourceCost.isEdit = false;
                        this.roomResourceCosts.push(resourceCost);
                        // this.resourceCosts.splice(0,0,resourceCost);
                    }
                }
            }
        }
    }

    // this.dataStore.get(DataKey.resourceTypes);
    filterObject(code: string, objArray: any[]) {
        if (objArray && objArray.length > 0) {
            for (let i = 0; i < objArray.length; i++) {
                if (objArray[i]['code'] === code) {
                    return objArray[i];
                }
            }
            return null;
        } else {
            return code;
        }
    }

    private setTempIdsToSeasons() {
        let initId = 1;
        for (let i = 0; i < this.resourceCostData.length; i++) {
            let season: Season = this.resourceCostData[i];
            season.tempSeasonId = initId++;
        }
    }

    getDays(data: string) {
        let days = '';
        for (let i = 0; i < data.length; i++) {
            let dayArray = this.daysOfWeek.find(day => day.key.toString() === data.charAt(i));
            if(dayArray && dayArray.value) {
                days = days.concat(',' + dayArray);
            }
        }
        if (days.startsWith(',')) {days = days.replace(',', '');}
        return days;
    }

    getDaysArr(data: string) {
        let daysArr = [];
        for (let i = 0; i < data.length; i++) {
            let dayArray = this.daysOfWeek.find(day => day.key.toString() === data.charAt(i)).value;
            daysArr.push({key: data.charAt(i), day: dayArray});
        }
        return daysArr;
    }

    getUpdatedResourceCostData() {
        this.trsCostChangeSub = this.dataStore.get(DataKey.trsResourceCosts).subscribe(val => {
            if (val !== null) {
                this.traResourceCosts = val;
                let hasInvalidCost = false;
                for (const data of this.resourceCostData) {
                    if (hasInvalidCost) {
                        break;
                    }
                    let resourceCosts: TrsResourceCostSetup[] = [];
                    resourceCosts = this.traResourceCosts.filter(cost => cost.tempSeasonId == data.tempSeasonId);
                    if (resourceCosts !== null) {
                        data.trsResourceCosts = [];
                        for (let i = 0; i < resourceCosts.length; i++) {
                            if (resourceCosts[i].isInvalid) {
                                hasInvalidCost = true;
                                this.dataStore.set(DataKey.supplierTrsCostSetupValid, false, true);
                                break;
                            }
                            let trsResourceCost = new TrsResourceCost();
                            trsResourceCost.days = resourceCosts[i].dayKeys;
                            trsResourceCost.infantFare = resourceCosts[i].infantFare;
                            trsResourceCost.childFare = resourceCosts[i].childFare;
                            trsResourceCost.teenFare = resourceCosts[i].teenFare;
                            trsResourceCost.unitFare = resourceCosts[i].unitFare;
                            trsResourceCost.adultFare = resourceCosts[i].adultFare;
                            trsResourceCost.route = resourceCosts[i].route && resourceCosts[i].route.code ?
                                resourceCosts[i].route.code :
                                resourceCosts[i].route;
                            trsResourceCost.transferMode = resourceCosts[i].transferMode && resourceCosts[i].transferMode.code ?
                                resourceCosts[i].transferMode.code :
                                resourceCosts[i].transferMode;
                            trsResourceCost.vehicleModel = resourceCosts[i].vehicleModel && resourceCosts[i].vehicleModel.code ?
                                resourceCosts[i].vehicleModel.code :
                                resourceCosts[i].vehicleModel;
                            trsResourceCost.currency = resourceCosts[i].currency && resourceCosts[i].currency.code ?
                                resourceCosts[i].currency.code :
                                resourceCosts[i].currency;
                            trsResourceCost.costingType = resourceCosts[i].costingType && resourceCosts[i].costingType.code ?
                                resourceCosts[i].costingType.code :
                                resourceCosts[i].costingType;
                            data.trsResourceCosts.push(trsResourceCost);
                        }
                    }
                }
            }
        });
        this.genCostChangeSub = this.dataStore.get(DataKey.genResourceCosts).subscribe(val => {
            if (val !== null) {
                this.genResourceCosts = val;
                // this.dataStore.set(DataKey.supplierGenCostSetupValid, true, true);
                this.selectedProfileTypes = this.selectedProfileTypes ?
                    this.selectedProfileTypes :
                    this.getSelectedProfileTypes();
                let hasInvalidCost = false;
                for (const data of this.resourceCostData) {
                    if (hasInvalidCost) {
                        break;
                    }
                    let resourceCosts: GenResourceCostSetup[] = [];
                    resourceCosts = this.genResourceCosts.filter(cost => cost.tempSeasonId == data.tempSeasonId);
                    if (resourceCosts !== null) {
                        data.genResourceCosts = [];
                        for (let i = 0; i < resourceCosts.length; i++) {
                            if (resourceCosts[i].isInvalid) {
                                hasInvalidCost = true;
                                this.dataStore.set(DataKey.supplierGenCostSetupValid, false, true);
                                break;
                            }
                            let genResourceCost = new GenResourceCost();
                            genResourceCost.days = resourceCosts[i].dayKeys;
                            genResourceCost.infantFare = resourceCosts[i].infantFare;
                            genResourceCost.childFare = resourceCosts[i].childFare;
                            genResourceCost.teenFare = resourceCosts[i].teenFare;
                            genResourceCost.unitFare = resourceCosts[i].unitFare;
                            genResourceCost.adultFare = resourceCosts[i].adultFare;
                            genResourceCost.currency = resourceCosts[i].currency && resourceCosts[i].currency.code ?
                                resourceCosts[i].currency.code :
                                resourceCosts[i].currency;
                            genResourceCost.costingType = resourceCosts[i].costingType && resourceCosts[i].costingType.code ?
                                resourceCosts[i].costingType.code :
                                resourceCosts[i].costingType;
                            if (resourceCosts[i].profileType.code) {
                                genResourceCost.profileType = resourceCosts[i].profileType.code;
                            } else {
                                let profileType = this.selectedProfileTypes.filter(value => value.code === resourceCosts[i].profileType);
                                genResourceCost.profileType = profileType && profileType.length > 0 ? profileType[0].code : 'ANY';
                            }
                            genResourceCost.resourceType = resourceCosts[i].resourceType.code ?
                                resourceCosts[i].resourceType.code :
                                this.selectedResourceTypes.filter(value => value.name ===
                                    resourceCosts[i].resourceType)[0].code;
                            data.genResourceCosts.push(genResourceCost);
                        }
                    }
                }
            }
        });
        this.roomCostChangeSub = this.dataStore.get(DataKey.roomResourceCosts).subscribe(val => {
            if (val !== null) {
                this.roomResourceCosts = val;
                let hasInvalidCost = false;
                for (const data of this.resourceCostData) {
                    if (hasInvalidCost) {
                        break;
                    }
                    let resourceCosts: RoomResourceCostSetup[] = [];
                    resourceCosts = this.roomResourceCosts.filter(cost => cost.tempSeasonId === data.tempSeasonId);
                    if (resourceCosts !== null) {
                        data.roomResourceCosts = [];
                        for (let i = 0; i < resourceCosts.length; i++) {
                            if (resourceCosts[i].isInvalid) {
                                hasInvalidCost = true;
                                this.dataStore.set(DataKey.supplierRoomCostSetupValid, false, true);
                                break;
                            }
                            const roomResourceCost = new RoomResourceCost();
                            roomResourceCost.days = resourceCosts[i].dayKeys;
                            roomResourceCost.infantFare = resourceCosts[i].infantFare;
                            roomResourceCost.childFare = resourceCosts[i].childFare;
                            roomResourceCost.teenFare = resourceCosts[i].teenFare;
                            roomResourceCost.unitFare = resourceCosts[i].unitFare;
                            roomResourceCost.adultFare = resourceCosts[i].adultFare;
                            roomResourceCost.single = resourceCosts[i].single;
                            roomResourceCost.twin = resourceCosts[i].twin;
                            roomResourceCost.extraBed = resourceCosts[i].extraBed;
                            roomResourceCost.nights = resourceCosts[i].nights;
                            roomResourceCost.boardBasis = resourceCosts[i].boardBasis;
                            roomResourceCost.roomType = resourceCosts[i].roomType;
                            roomResourceCost.currency = resourceCosts[i].currency && resourceCosts[i].currency.code ?
                                resourceCosts[i].currency.code :
                                resourceCosts[i].currency;
                            roomResourceCost.costingType = resourceCosts[i].costingType && resourceCosts[i].costingType.code ?
                                resourceCosts[i].costingType.code :
                                resourceCosts[i].costingType;
                            data.roomResourceCosts.push(roomResourceCost);
                        }
                    }
                }
            }
        });
    }

    getSelectedProfileTypes(): any[] {
        let profileTypes = [];
        const type = {
            code: 'ANY',
            name: 'Any'
        };
        profileTypes.push(type);
        return profileTypes;
    }

    public onTransportCostSetupClick() {
        this.dataStore.get(DataKey.vehicleModels).subscribe(data => {
            if (data !== null) {
                this.vehicleModels = data;
            }
            this.showTransportCostFocusView();
        });
    }

    showTransportCostFocusView() {
        setTimeout(() => {
            let fabActions = [];

            let navItemsArray = this.configLoader.configurations.get(TCO.CONF.CONF_NAVIGATION);
            this.configLoader.configurations.get(TCO.CONF.CONF_FOCUS_VIEW).TRA_COST_SETUP_FOCUS_VIEW.fabAction
                .forEach(val => {
                    for (let key in navItemsArray) {
                        if (val === navItemsArray[key]['id']) {
                            fabActions.push(navItemsArray[key]);
                        }
                    }
                });
            let dataObject = new ModalData(
                LEVEL.SUCCESS,
                'Supplier Transport Cost Setup',
                null,
                null,
                'grid-focus-view',
                TransportCostSetupQueueFocusViewComponent,
                {
                    'resourceCosts': this.traResourceCosts,
                    'supplierCostSetup': this.resourceCostData,
                    'vehicleModels': this.vehicleModels,
                    'currencyList': this.currencyList,
                    'costingTypeList': this.costingTypeList,
                    'daysOfWeek': this.daysOfWeek,
                    'transferModes': this.transferModes,
                    'routes': this.routes,
                    'resourceTypes': this.resourceTypes,
                    'selectedResourceTypes': this.selectedResourceTypes
                },
                {'label': 'close'},
                '',
                '',
                fabActions,
                '',
                (this.supplier && this.supplier.code && this.supplier.name) ?
                    (this.supplier.code + ' | ' + this.supplier.name) :
                    ''
            );

            this.focusviewCloseSubject = this.dataStore.get(DataKey.supplierCostFocusViewClose).subscribe(c => {
                if (c) {
                }
            });
            this.focusViewService.confirm(dataObject).subscribe(res => {
                if (res) {
                    console.log(res);
                }
            });
        }, 0);
    }

    public onGenericCostSetupClick() {
        let genericResourceTypes = this.selectedResourceTypes.filter(value => value && value.resourceCategory !==
            'transport');

        // let genCostingTypes = this.costingTypeList.filter(value => value.category !== 'DISTANCE');
        setTimeout(() => {
            let fabActions = [];

            let navItemsArray = this.configLoader.configurations.get(TCO.CONF.CONF_NAVIGATION);
            this.configLoader.configurations.get(TCO.CONF.CONF_FOCUS_VIEW).GEN_COST_SETUP_FOCUS_VIEW.fabAction
                .forEach(val => {
                    for (let key in navItemsArray) {
                        if (val === navItemsArray[key]['id']) {
                            fabActions.push(navItemsArray[key]);
                        }
                    }
                });
            let dataObject = new ModalData(
                LEVEL.SUCCESS,
                'Supplier Generic Cost Setup',
                null,
                null,
                'grid-focus-view',
                GenericCostSetupFocusViewComponent,
                {
                    'resourceCosts': this.genResourceCosts,
                    'supplierCostSetup': this.resourceCostData,
                    'selectedResourceTypes': genericResourceTypes,
                    'selectedProfileTypes': this.selectedProfileTypes,
                    'currencyList': this.currencyList,
                    'costingTypeList': this.costingTypeList,
                    'daysOfWeek': this.daysOfWeek,

                    'defaultProfileType': this.defaultProfileType
                },
                {'label': 'close'},
                '',
                '',
                fabActions,
                '',
                (this.supplier && this.supplier.code && this.supplier.name) ?
                    (this.supplier.code + ' | ' + this.supplier.name) :
                    ''
            );

            this.focusviewCloseSubject = this.dataStore.get(DataKey.supplierCostFocusViewClose).subscribe(c => {
                if (c) {
                }
            });
            this.focusViewService.confirm(dataObject).subscribe(res => {
                if (res) {
                    console.log(res);
                }
            });
        }, 0);
    }

    public onRoomCostSetupClick() {
        const roomTypes = this.supplier.rooms;

        setTimeout(() => {
            const fabActions = [];

            const navItemsArray = this.configLoader.configurations.get(TCO.CONF.CONF_NAVIGATION);
            this.configLoader.configurations.get(TCO.CONF.CONF_FOCUS_VIEW).ROOM_COST_SETUP_FOCUS_VIEW.fabAction
                .forEach(val => {
                    for (const key in navItemsArray) {
                        if (val === navItemsArray[key].id) {
                            fabActions.push(navItemsArray[key]);
                        }
                    }
                });
            const dataObject = new ModalData(
                LEVEL.SUCCESS,
                'Supplier Room Cost Setup',
                null,
                null,
                'grid-focus-view',
                RoomCostSetupFocusViewComponent,
                {
                    resourceCosts: this.roomResourceCosts,
                    supplierCostSetup: this.resourceCostData,
                    currencyList: this.currencyList,
                    costingTypeList: this.costingTypeList,
                    daysOfWeek: this.daysOfWeek,
                    rooms: roomTypes
                },
                {label: 'close'},
                '',
                '',
                fabActions,
                '',
                (this.supplier && this.supplier.code && this.supplier.name) ?
                    (this.supplier.code + ' | ' + this.supplier.name) :
                    ''
            );

            this.focusviewCloseSubject = this.dataStore.get(DataKey.supplierCostFocusViewClose).subscribe(c => {
                if (c) {
                }
            });
            this.focusViewService.confirm(dataObject).subscribe(res => {
                if (res) {
                    console.log(res);
                }
            });
        }, 0);
    }

    closeModal() {
        if (this.modalService.close()) {
            this.modalService.close().subscribe(
                (res) => {
                }
            );
        }
    }

    private confirmModal(data: any): void {
        this.modalService.confirm(data).subscribe((res) => {
        });
    }

    public onSeasonSetupClick() {
        setTimeout(() => {
            let fabActions = [];

            let navItemsArray = this.configLoader.configurations.get(TCO.CONF.CONF_NAVIGATION);
            this.configLoader.configurations.get(TCO.CONF.CONF_FOCUS_VIEW).SEASON_SETUP_FOCUS_VIEW.fabAction
                .forEach(val => {
                    for (let key in navItemsArray) {
                        if (val === navItemsArray[key]['id']) {
                            fabActions.push(navItemsArray[key]);
                        }
                    }
                });
            let dataObject = new ModalData(
                LEVEL.SUCCESS,
                'Season Setup',
                null,
                null,
                'season-setup-focus-view',
                SeasonSetupFocusViewComponent,
                {resourceCostData: this.resourceCostData},
                {'label': 'close'},
                '',
                '',
                fabActions,
                '',
                (this.supplier && this.supplier.code && this.supplier.name) ?
                    (this.supplier.code + ' | ' + this.supplier.name) :
                    ''
            );

            this.focusviewCloseSubject = this.dataStore.get(DataKey.supplierCostFocusViewClose).subscribe(c => {
                if (c) {
                }
            });
            this.focusViewService.confirm(dataObject).subscribe(res => {
                if (res) {
                    console.log(res);
                }
            });
        }, 0);
    }

    private addDefaultProfileType(typeList: any[]) {
        return !typeList.find(value => value.code === this.defaultProfileType.code) ? typeList.push(this.defaultProfileType) : typeList;
    }

    ngOnDestroy(): void {
        if (this.trsCostChangeSub != null) {
            this.trsCostChangeSub.unsubscribe();
        }
        if (this.genCostChangeSub != null) {
            this.genCostChangeSub.unsubscribe();
        }
        if (this.roomCostChangeSub != null) {
            this.roomCostChangeSub.unsubscribe();
        }
    }
}
