import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule } from '@ngx-translate/core';
import { AgGridModule } from 'ag-grid-angular';
import { AgGridCustomDirectivesModule } from '../../widgets/framework/ag-grid-custom-components/directives/ag-grid-custom-directives.module';
import { AgGridCustomEditorsModule } from '../../widgets/framework/ag-grid-custom-components/editors/ag-grid-custom-editors.module';
import { AutoCompleteEditorComponent } from '../../widgets/framework/ag-grid-custom-components/editors/auto-complete-editor/auto-complete-editor.component';
import { DropdownEditorComponent } from '../../widgets/framework/ag-grid-custom-components/editors/dropdown-editor/dropdown-editor.component';
import { NoteEditorComponent } from '../../widgets/framework/ag-grid-custom-components/editors/note-editor/note-editor.component';
import { AgGridCustomFiltersModule } from '../../widgets/framework/ag-grid-custom-components/filters/ag-grid-custom-filters.module';
import { CustomTextFilterComponent } from '../../widgets/framework/ag-grid-custom-components/filters/custom-text-filter/custom-text-filter.component';
import { ActionsComponent } from '../../widgets/framework/ag-grid-custom-components/renderers/actions/actions.component';
import { AgGridCustomRenderersModule } from '../../widgets/framework/ag-grid-custom-components/renderers/ag-grid-custom-renderers.module';
import { TemplateRendererComponent } from '../../widgets/framework/ag-grid-custom-components/renderers/template-renderer/template-renderer.component';
import { ObjKeysPipePipeModule } from '../../pipes/obj-keys-pipe-pipe.module';
import { ResultHeaderModule } from '../../widgets/shared/result-header/result-header.module';
import { SupplierAcceptanceQueueComponent } from './supplier-acceptance-queue.component';

@NgModule({
    declarations: [SupplierAcceptanceQueueComponent],
    imports: [
        CommonModule,
        BrowserAnimationsModule,
        FormsModule,
        ResultHeaderModule,
        MatIconModule,

        ObjKeysPipePipeModule,

        // **** ag grid ***
        AgGridCustomRenderersModule,
        AgGridCustomEditorsModule,
        AgGridCustomDirectivesModule,
        AgGridCustomFiltersModule,
        AgGridModule.withComponents([
            // renderer
            TemplateRendererComponent,
            ActionsComponent,

            // editors
            DropdownEditorComponent,
            AutoCompleteEditorComponent,
            NoteEditorComponent,

            // filters
            CustomTextFilterComponent

        ]),
        MatMenuModule,
        MatListModule,
        MatTooltipModule,
        MatButtonModule,
        TranslateModule.forChild()
    ],
    exports: [
        SupplierAcceptanceQueueComponent
    ],
    entryComponents: [
        SupplierAcceptanceQueueComponent
    ]
})
export class SupplierAcceptanceQueueModule
{
}
