/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../widgets/shared/split-passengers-view/split-passengers-view.component.ngfactory";
import * as i2 from "../../../widgets/shared/split-passengers-view/split-passengers-view.component";
import * as i3 from "./split-passengers-view-wrapper.component";
import * as i4 from "../../../services/util/framework/data-store.service";
import * as i5 from "../../../services/backend-consumers/reservation-management-services/trs-reservation-management.service";
import * as i6 from "@tc/dialog/dialog.service";
import * as i7 from "../../../services/util/common/dmc-common";
var styles_SplitPassengersViewWrapperComponent = [];
var RenderType_SplitPassengersViewWrapperComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SplitPassengersViewWrapperComponent, data: {} });
export { RenderType_SplitPassengersViewWrapperComponent as RenderType_SplitPassengersViewWrapperComponent };
export function View_SplitPassengersViewWrapperComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-split-passengers-view", [["class", "split-passengers-view-wrapper"]], null, [[null, "split"], [null, "save"], [null, "selectGroup"], [null, "closePopUp"], [null, "selectGrouping"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("split" === en)) {
        var pd_0 = (_co.onSplit($event) !== false);
        ad = (pd_0 && ad);
    } if (("save" === en)) {
        var pd_1 = (_co.onSave($event) !== false);
        ad = (pd_1 && ad);
    } if (("selectGroup" === en)) {
        var pd_2 = (_co.selectGroup($event) !== false);
        ad = (pd_2 && ad);
    } if (("closePopUp" === en)) {
        var pd_3 = (_co.onClosePopup($event) !== false);
        ad = (pd_3 && ad);
    } if (("selectGrouping" === en)) {
        var pd_4 = (_co.onSelectGroupingType($event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, i1.View_SplitPassengersViewComponent_0, i1.RenderType_SplitPassengersViewComponent)), i0.ɵdid(1, 114688, null, 0, i2.SplitPassengersViewComponent, [], { transferMode: [0, "transferMode"], serviceItem: [1, "serviceItem"], passengerData: [2, "passengerData"], passengerDataHeadings: [3, "passengerDataHeadings"], passengerGroups: [4, "passengerGroups"], groupingOptionList: [5, "groupingOptionList"], isRequestCompleted: [6, "isRequestCompleted"] }, { split: "split", closePopUp: "closePopUp", save: "save", selectGroup: "selectGroup", selectGrouping: "selectGrouping" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.transferMode; var currVal_1 = _co.serviceItem; var currVal_2 = _co.selectedGroupPassengerData; var currVal_3 = _co.passengerDataHeadings; var currVal_4 = _co.passengerGroups; var currVal_5 = _co.groupingOptionList; var currVal_6 = _co.isRequestCompleted; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
export function View_SplitPassengersViewWrapperComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-split-passengers-view-wrapper", [], null, null, null, View_SplitPassengersViewWrapperComponent_0, RenderType_SplitPassengersViewWrapperComponent)), i0.ɵdid(1, 114688, null, 0, i3.SplitPassengersViewWrapperComponent, [i4.DataStoreService, i5.TrsReservationManagementService, i6.DialogService, i4.DataStoreService, i7.DMCCommon], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SplitPassengersViewWrapperComponentNgFactory = i0.ɵccf("tc-split-passengers-view-wrapper", i3.SplitPassengersViewWrapperComponent, View_SplitPassengersViewWrapperComponent_Host_0, { serviceItem: "serviceItem" }, {}, []);
export { SplitPassengersViewWrapperComponentNgFactory as SplitPassengersViewWrapperComponentNgFactory };
