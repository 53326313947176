<div class="container-fluid tc-gwacs-card">
    <div class="row tc-gwacs-card__row">
        <div class="col-xs-12 col-sm-12 col-md-4 tc-flex-box">
            <div class="tc-gwacs-card-col-1__circle">
                <tc-resource
                        *ngIf="taxSchemeContent?.icon?.value"
                        id="{{taxSchemeContent?.icon?.key}}"
                        [key]="taxSchemeContent?.icon?.value?.key"
                        [value]="taxSchemeContent?.icon?.value?.value"
                        [style]="taxSchemeContent?.icon?.value?.style"
                        [iconType]="taxSchemeContent?.icon?.value?.iconType">
                </tc-resource>
            </div>
            <div
                    *ngIf="taxSchemeData" class="tc-flex-item tc-flex-item__center"
                    [ngClass]="isHeader? 'additional-contact--header' : ''">
                {{getName()}}
            </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3">
            <div
                    class="tc-gwacs-card-col-2__content"
                    *ngIf="taxSchemeData && !isHeader">{{getTaxScheme(taxSchemeData.taxSchemeCode)}}
            </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4">
            <div class="tc-action-chip-list-wrapper tc-chip-selector__action-chips pull-right"
                 *ngIf="taxSchemeData && !isHeader">
                <div class="tc-action-chip"
                     [ngClass]="taxSchemeData.special ? 'tc-action-chip--active':'tc-action-chip--inactive' ">
                    <span class="tc-action-chip__icon tc-action-chip__icon-man"></span>
                    <span class="tc-action-chip__icon tc-action-chip__icon-select">
                                  <i class="material-icons">done</i>
                                </span>
                    <div class="tc-action-chip__text">
                        {{"Special" | translate}}
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-1">
            <div class="tc-icon-block pull-right">
                <span class="tc-icon-block__icon tc-icon-block__icon--active">
                  <i class="material-icons" *ngIf="isEditable && !showIcons"
                     (click)="onDelete($event)">delete</i>
                </span>
            </div>
        </div>
    </div>
</div>
