import * as tslib_1 from "tslib";
import { AbstractSearchCriteria } from './abstract-search-criteria';
var AccountingRuleSearchCriteria = /** @class */ (function (_super) {
    tslib_1.__extends(AccountingRuleSearchCriteria, _super);
    function AccountingRuleSearchCriteria() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.loadOnlyNotExportedOrModified = false;
        _this.accountingUnitCode = '';
        _this.accountingEventCode = '';
        return _this;
    }
    return AccountingRuleSearchCriteria;
}(AbstractSearchCriteria));
export { AccountingRuleSearchCriteria };
