
    <div class="tc-flex-box container-fluid">
        <div class="tc-flex-item icon-cage input-icon">
        <div class="card-icon--small icn-color-primary">
            <i class="material-icons material-icons">location_on</i>
        </div>
        </div>
        <div class="tc-flex-item input-cage">
            <div class="col-xs-12 col-sm-12  col-md-6">
                <div class="tc-content--wrap tc-input-view">
                    <div id="TRD_PER_INFO_REF"
                         class="tc-caption-txt txt-light">{{"Pickups" | translate}}
                    </div>
                    <ul class="list-group">
                        <li *ngFor="let pickup of pickups">
                            <span class="tc-body1--txt">{{pickup}}</span>
                        </li>
                    </ul>
                    <!--<div class="tc-body1&#45;&#45;txt">{{pickups}}</div>-->
                </div>
            </div>
            <div class="col-xs-12 col-sm-12  col-md-6">
                <div class="tc-content--wrap tc-input-view">
                    <div id="TRD_PER_INFO_HOD_REF"
                         class="tc-caption-txt txt-light">{{"DropOffs" | translate}}
                    </div>
                    <ul class="list-group">
                        <li *ngFor="let dropOff of dropOffs">
                            <span class="tc-body1--txt">{{dropOff}}</span>
                        </li>
                    </ul>
                    <!--<div class="tc-body1&#45;&#45;txt">{{dropOffs}}</div>-->
                </div>
            </div>
        </div>
    </div>
