import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule, MatInputModule } from '@angular/material';
import { MatSelectModule } from '@angular/material/select';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';
import { ResultHeaderModule } from '../result-header/result-header.module';
import { SplitPassengersViewComponent } from './split-passengers-view.component';

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    TranslateModule.forChild(),
    ResultHeaderModule,

    MatFormFieldModule,
    MatInputModule,
    MatSelectModule

  ],
  exports: [SplitPassengersViewComponent],
  declarations: [SplitPassengersViewComponent]
})
export class SplitPassengersViewModule {}
