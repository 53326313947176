import { Injectable } from '@angular/core';
import { ConfigLoader } from '@tc-core/util/framework';
import { Observable } from 'rxjs';
import { DMCQueryParamsService } from '../../util/framework/dmc-query-params.service';
import { RequestService } from '../../util/framework/request.service';
import { ProductDetailsSearchCriteria } from '../../../models/criteria/product-details-search-criteria';
import { UrlPaths } from '../url-paths';

@Injectable({
    providedIn: 'root'
})
export class ProductDetailsService {
    constructor(
        private configLoader: ConfigLoader,
        private requestService: RequestService,
        private queryParamsService: DMCQueryParamsService
    ) {
    }

    public getProductDetails(criteria: ProductDetailsSearchCriteria): Observable<any> {
        const reqPrams = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.get(
            UrlPaths.OP_RES_URL_KEY,
            [UrlPaths.OP_RES_VERSION, UrlPaths.PRODUCT_DETAILS],
            reqPrams
        );
    }

    public deleteProductDetails(criteria: ProductDetailsSearchCriteria): Observable<any> {
        return this.requestService.delete(
            UrlPaths.OP_RES_URL_KEY,
            [UrlPaths.OP_RES_VERSION, UrlPaths.PRODUCT_DETAILS, criteria.productCode, criteria.serviceType]
        );
    }

    public saveProductDetails(row: any): Observable<any> {
        return this.requestService.post(
            UrlPaths.OP_RES_URL_KEY,
            [UrlPaths.OP_RES_VERSION, UrlPaths.PRODUCT_DETAILS],
            row
        );
    }

}
