/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./notification-toast.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../pipes/truncate.pipe";
import * as i4 from "../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i5 from "@angular/material/icon";
import * as i6 from "./notification-toast-item.component";
var styles_NotificationToastItemComponent = [i0.styles];
var RenderType_NotificationToastItemComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NotificationToastItemComponent, data: {} });
export { RenderType_NotificationToastItemComponent as RenderType_NotificationToastItemComponent };
function View_NotificationToastItemComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "span", [["class", "notification-toast-item--toast-title"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.toastModel.title; _ck(_v, 0, 0, currVal_0); }); }
function View_NotificationToastItemComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "br", [], null, null, null, null, null))], null, null); }
function View_NotificationToastItemComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "span", [["class", "notification-toast-item--toast-msg"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpad(2, 2), i1.ɵppd(3, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent.parent, 0), _co.toastModel.body, _ck(_v, 2, 0, "27", "... "))); _ck(_v, 1, 0, currVal_0); }); }
function View_NotificationToastItemComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "notification-toast-item--toast-text"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotificationToastItemComponent_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotificationToastItemComponent_3)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotificationToastItemComponent_4)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.toastModel.title; _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.toastModel.title && _co.toastModel.body); _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.toastModel.body; _ck(_v, 6, 0, currVal_2); }, null); }
export function View_NotificationToastItemComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i3.TruncatePipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 10, "div", [["class", "notification-toast-item notification-toast-item--default"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "mat-icon", [["class", "notification-toast-item--toast-icon mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i4.View_MatIcon_0, i4.RenderType_MatIcon)), i1.ɵdid(4, 9158656, null, 0, i5.MatIcon, [i1.ElementRef, i5.MatIconRegistry, [8, null], [2, i5.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(5, 0, ["", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 3, "div", [["class", "notification-toast-item--close-btn"], ["id", "item_close_btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClose($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i4.View_MatIcon_0, i4.RenderType_MatIcon)), i1.ɵdid(8, 9158656, null, 0, i5.MatIcon, [i1.ElementRef, i5.MatIconRegistry, [8, null], [2, i5.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["close"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotificationToastItemComponent_1)), i1.ɵdid(11, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "notification-toast-item notification-toast-item--default"; var currVal_1 = _co.toastModel.cssClass; _ck(_v, 2, 0, currVal_0, currVal_1); _ck(_v, 4, 0); _ck(_v, 8, 0); var currVal_7 = (_co.toastModel.title || _co.toastModel.body); _ck(_v, 11, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = i1.ɵnov(_v, 4).inline; var currVal_3 = (((i1.ɵnov(_v, 4).color !== "primary") && (i1.ɵnov(_v, 4).color !== "accent")) && (i1.ɵnov(_v, 4).color !== "warn")); _ck(_v, 3, 0, currVal_2, currVal_3); var currVal_4 = _co.toastModel.matIcon; _ck(_v, 5, 0, currVal_4); var currVal_5 = i1.ɵnov(_v, 8).inline; var currVal_6 = (((i1.ɵnov(_v, 8).color !== "primary") && (i1.ɵnov(_v, 8).color !== "accent")) && (i1.ɵnov(_v, 8).color !== "warn")); _ck(_v, 7, 0, currVal_5, currVal_6); }); }
export function View_NotificationToastItemComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "tc-notification-toast", [], null, null, null, View_NotificationToastItemComponent_0, RenderType_NotificationToastItemComponent)), i1.ɵdid(1, 114688, null, 0, i6.NotificationToastItemComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NotificationToastItemComponentNgFactory = i1.ɵccf("tc-notification-toast", i6.NotificationToastItemComponent, View_NotificationToastItemComponent_Host_0, { toastModel: "toastModel" }, { closeClick: "closeClick", toastClick: "toastClick" }, []);
export { NotificationToastItemComponentNgFactory as NotificationToastItemComponentNgFactory };
