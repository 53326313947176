import * as tslib_1 from "tslib";
import { AbstractSearchCriteria } from './abstract-search-criteria';
var KeyControlAndAbstractSearchCriteria = /** @class */ (function (_super) {
    tslib_1.__extends(KeyControlAndAbstractSearchCriteria, _super);
    function KeyControlAndAbstractSearchCriteria() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.kcCompany = '';
        _this.kcDivision = '';
        _this.kcBrand = '';
        _this.kcDistributionChannel = '';
        return _this;
    }
    return KeyControlAndAbstractSearchCriteria;
}(AbstractSearchCriteria));
export { KeyControlAndAbstractSearchCriteria };
