import { DocumentDataCriteria } from '../../../models/criteria/document-data-criteria';
import { DocumentQueueSearchCriteria } from '../../../models/criteria/document-queue-search-criteria';
import { DocumentSearchCriteria } from '../../../models/criteria/document-search-criteria';
import { LeaveSearchCriteria } from '../../../models/criteria/leave-search-criteria';
import { SupplierSearchCriteria } from '../../../models/criteria/supplier-search-criteria';
import { VehicleSearchCriteria } from '../../../models/criteria/vehicle-search-criteria';
import { BookingSearchCriteria } from '../../../models/reservation-v2/criteria/booking-search-criteria';
import { ProductQueueSearchCriteria } from '../../../models/reservation-v2/criteria/product-queue-search-criteria';
import { SupplierAllocationSearchCriteria } from '../../../models/reservation-v2/criteria/supplier-allocation-search-criteria';

export class DateTimeProcessor {
    public static processSupplierSearchCriteriaDateTimes(criteria: SupplierSearchCriteria) {
        if (criteria.serviceDate && criteria.serviceDate !== '' && !criteria.serviceDate.includes('T')) {
            criteria.serviceDate = this.convertDateToISOString(criteria.serviceDate, true);
        }
        if (criteria.startDate && criteria.startDate !== '' && !criteria.startDate.includes('T')) {
            criteria.startDate = this.convertDateToISOString(criteria.startDate, true);
        }
        if (criteria.endDate && criteria.endDate !== '' && !criteria.endDate.includes('T')) {
            criteria.endDate = this.convertDateToISOString(criteria.endDate, false);
        }
    }

    public static processSupplierAllocationSearchCriteriaDateTimes(criteria: SupplierAllocationSearchCriteria) {
        if (criteria.serviceDate && criteria.serviceDate !== '' && !criteria.serviceDate.includes('T')) {
            criteria.serviceDate = this.convertDateToISOString(criteria.serviceDate, true);
        } else {
            criteria.serviceDate = new Date(criteria.serviceDate).toISOString();
        }
    }

    public static processSearchCriteriaDateTimes(criteria: SupplierAllocationSearchCriteria) {
        if (criteria.serviceDate && criteria.serviceDate !== '') {
            if (criteria.serviceDate instanceof Date) {
                criteria.serviceDate = criteria.serviceDate.toISOString();
            } else {
                criteria.serviceDate = new Date(criteria.serviceDate).toISOString();
            }
        }
        criteria.serviceStartTime = this.processDateTimesToISO(criteria.serviceStartTime);
        criteria.serviceEndTime = this.processDateTimesToISO(criteria.serviceEndTime);
    }

    public static processDateTimesToISO(date) {
        if (date && date !== '') {
            if (date instanceof Date) {
                date = date.toISOString();
            } else {
                date = new Date(date).toISOString();
            }
        }
        return date;
    }

    public static processLeaveSearchCriteriaDateTimes(criteria: LeaveSearchCriteria) {
        if (criteria.leaveDate && criteria.leaveDate !== '' && !criteria.leaveDate.includes('T')) {
            criteria.leaveDate = this.convertDateToISOString(criteria.leaveDate, true);
        }
    }

    public static processVehicleSearchCriteriaDateTimes(criteria: VehicleSearchCriteria) {
        if (criteria.serviceDate && criteria.serviceDate !== '' && !criteria.serviceDate.includes('T')) {
            criteria.serviceDate = this.convertDateToISOString(criteria.serviceDate, true);
        }
        if (criteria.startDate && criteria.startDate !== '' && !criteria.startDate.includes('T')) {
            criteria.startDate = this.convertDateToISOString(criteria.startDate, true);
        }
        if (criteria.endDate && criteria.endDate !== '' && !criteria.endDate.includes('T')) {
            criteria.endDate = this.convertDateToISOString(criteria.endDate, false);
        }
    }

    public static processBookingSearchCriteriaDateTimes(criteria: BookingSearchCriteria) {
        if (criteria.startDate && criteria.startDate !== '' && !criteria.startDate.includes('T')) {
            criteria.startDate = this.convertDateToISOString(criteria.startDate, true);
        }
        if (criteria.endDate && criteria.endDate !== '' && !criteria.endDate.includes('T')) {
            criteria.endDate = this.convertDateToISOString(criteria.endDate, false);
        }
        if (criteria.createdDate && criteria.createdDate !== '' && !criteria.createdDate.includes('T')) {
            criteria.createdDate = this.convertDateToISOString(criteria.createdDate, true);
        }
        if (criteria.bookingStartDate && criteria.bookingStartDate !== '' && !criteria.bookingStartDate.includes('T')) {
            criteria.bookingStartDate = this.convertDateToISOString(criteria.bookingStartDate, true);
        }
        if (criteria.bookingEndDate && criteria.bookingEndDate !== '' && !criteria.bookingEndDate.includes('T')) {
            criteria.bookingEndDate = this.convertDateToISOString(criteria.bookingEndDate, false);
        }
    }

    public static processProductQueueSearchCriteriaDateTimes(criteria: ProductQueueSearchCriteria) {
        if (criteria.startDate && criteria.startDate !== '' && !criteria.startDate.includes('T')) {
            criteria.startDate = this.convertDateToISOString(criteria.startDate, true);
        }
        if (criteria.endDate && criteria.endDate !== '' && !criteria.endDate.includes('T')) {
            criteria.endDate = this.convertDateToISOString(criteria.endDate, false);
        }
        if (criteria.bookingStartDate && criteria.bookingStartDate !== '' && !criteria.bookingStartDate.includes('T')) {
            criteria.bookingStartDate = this.convertDateToISOString(criteria.bookingStartDate, true);
        }
        if (criteria.bookingEndDate && criteria.bookingEndDate !== '' && !criteria.bookingEndDate.includes('T')) {
            criteria.bookingEndDate = this.convertDateToISOString(criteria.bookingEndDate, false);
        }
    }

    public static processDocumentSearchCriteriaDateTimes(criteria: DocumentSearchCriteria) {
        if (criteria.startDate && criteria.startDate !== '' && !criteria.startDate.includes('T')) {
            criteria.startDate = this.convertDateToISOString(criteria.startDate, true);
        }
        if (criteria.endDate && criteria.endDate !== '' && !criteria.endDate.includes('T')) {
            criteria.endDate = this.convertDateToISOString(criteria.endDate, false);
        }
    }

    public static processDocumentDataSearchCriteriaDateTimes(criteria: DocumentDataCriteria) {
        if (criteria.searchStartDate && criteria.searchStartDate !== '' && !criteria.searchStartDate.includes('T')) {
            criteria.searchStartDate = this.convertDateToISOString(criteria.searchStartDate, true);
        }
        if (criteria.searchEndDate && criteria.searchEndDate !== '' && !criteria.searchEndDate.includes('T')) {
            criteria.searchEndDate = this.convertDateToISOString(criteria.searchEndDate, false);
        }
    }

    public static processDocumentQueueSearchCriteriaDateTimes(criteria: DocumentQueueSearchCriteria) {
        if (criteria.startDate && criteria.startDate !== '' && !criteria.startDate.includes('T')) {
            criteria.startDate = this.convertDateToISOString(criteria.startDate, true);
        }
        if (criteria.endDate && criteria.endDate !== '' && !criteria.endDate.includes('T')) {
            criteria.endDate = this.convertDateToISOString(criteria.endDate, false);
        }
        if (criteria.dispatchStartDate && criteria.dispatchStartDate !== '' && !criteria.dispatchStartDate.includes('T')) {
            criteria.dispatchStartDate = this.convertDateToISOString(criteria.dispatchStartDate, true);
        }
        if (criteria.dispatchEndDate && criteria.dispatchEndDate !== '' && !criteria.dispatchEndDate.includes('T')) {
            criteria.dispatchEndDate = this.convertDateToISOString(criteria.dispatchEndDate, false);
        }
    }

    private static convertDateToISOString(date: any, isStartDate: boolean): string {
        if (isStartDate) {
            return new Date(date + ' 00:00:00.000Z').toISOString();
        }
        return new Date(date + ' 23:59:59.000Z').toISOString();
    }

    // ToDo remove any data type
    public static convertLocalStorageDatesToISOString(inputSearchCriteria: any)
    {
        this.convertLocalStorageStartDatesToISOString(inputSearchCriteria);
        this.convertLocalStorageEndDatesToISOString(inputSearchCriteria);
    }

    public static convertLocalStorageStartDatesToISOString(inputSearchCriteria: any)
    {
        if (inputSearchCriteria && inputSearchCriteria.startDate) {
            if (inputSearchCriteria.startDate.split('T').length === 1) {
                inputSearchCriteria.startDate += ' 00:00:00.000Z';
            }
            inputSearchCriteria.startDate = new Date(inputSearchCriteria.startDate).toISOString().split('T')[0];
        }
    }

    public static convertLocalStorageEndDatesToISOString(inputSearchCriteria: any)
    {
        if (inputSearchCriteria && inputSearchCriteria.endDate) {
            if (inputSearchCriteria.endDate.split('T').length === 1) {
                inputSearchCriteria.endDate += ' 00:00:00.000Z';
            }
            inputSearchCriteria.endDate = new Date(inputSearchCriteria.endDate).toISOString().split('T')[0];
        }
    }

    public static convertLocalStorageDispatchDatesToISOString(inputSearchCriteria: any) {
        this.convertLocalStorageDispatchStartDatesToISOString(inputSearchCriteria);
        this.convertLocalStorageDispatchEndDatesToISOString(inputSearchCriteria);
    }

    public static convertLocalStorageDispatchStartDatesToISOString(inputSearchCriteria: any) {
        if (inputSearchCriteria && inputSearchCriteria.dispatchStartDate) {
            inputSearchCriteria.dispatchStartDate = new Date(inputSearchCriteria.dispatchStartDate).toISOString().split('T')[0];
        }
    }

    public static convertLocalStorageDispatchEndDatesToISOString(inputSearchCriteria: any) {
        if (inputSearchCriteria && inputSearchCriteria.dispatchEndDate) {
            inputSearchCriteria.dispatchEndDate = new Date(inputSearchCriteria.dispatchEndDate).toISOString().split('T')[0];
        }
    }
}
