import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatCheckboxModule } from '@angular/material';
import { RoomAddCardComponent } from './room-add-card.component';

@NgModule({
    imports: [
        CommonModule,
        MatCheckboxModule
    ],
    declarations: [RoomAddCardComponent],
    exports: [RoomAddCardComponent]
})
export class RoomAddCardModule {
}
