import { Leave } from './resource/leave';

export class ResourceLeaveSummary {
    resourceId: number;
    name: string;
    supplierCode: string;
    supplierName: string;
    transferMode: string;
    route: string;
    city: string;
    experience: string;
    available: boolean;
    leaves: Leave[];
}