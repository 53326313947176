import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PipesModule } from '@tc/pipes';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
    MatAutocompleteModule, MatButtonModule,
    MatCheckboxModule,
    MatFormFieldModule, MatIconModule,
    MatInputModule, MatSelectModule,
    MatTabsModule
} from '@angular/material';
import { TranslateModule } from '@ngx-translate/core';
import { ResourceModule } from '@tc/resource';
import { ChangeDetectorService } from '../../../services/util/change-detector/change-detector.service';
import { ReservationServiceItemComponent } from './reservation-service-item.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import {MatMenuModule} from '@angular/material/menu';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule.forChild(),
        ResourceModule,
        PipesModule,
        MatCheckboxModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatTabsModule,
        MatAutocompleteModule,
        MatIconModule,
        MatButtonModule,
        MatTooltipModule,
        MatMenuModule
    ],
    providers: [
        ChangeDetectorService
    ],
    declarations: [ReservationServiceItemComponent],
    exports: [ReservationServiceItemComponent]
})
export class ReservationServiceItemModule {}
