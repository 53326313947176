import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SummaryCardData } from '@tc-core/model/it/codegen/ui/framework/summary-card-data';
import { ConfigLoader } from '@tc-core/util/framework/config-loader.service';
import { DateFormatter } from '@tc-core/util/system/date-formatter.service';
import { TCO } from '../../../../../constants';
import { BookingItem } from '../../../../../models/reservation-v2/booking-item';

@Component({
  selector: 'tc-item-summary-detail-view',
  templateUrl: './item-summary-detail-view.component.html'
})
export class ItemSummaryDetailViewComponent implements OnInit {

  @Input() summaryCard: SummaryCardData;
  @Input() isHeader: boolean;
  @Input() isEditable: boolean;
  @Input() showIcons: boolean;
  @Input() bookingItem: BookingItem;

  @Output() passengersClick: EventEmitter<BookingItem> = new EventEmitter();

  pageOptionsAvailability: any;

  DATE_FORMAT = 'yyyy-MM-dd h:mm a'; // this is the default format

  constructor(
      public dateFormatter: DateFormatter,
      private configLoader: ConfigLoader,
      public datePipe: DatePipe
  ) { }

  ngOnInit() {
    this.pageOptionsAvailability = this.configLoader.configurations.get(TCO.CONF.CONF_PAGE_OPTION_AVAILABILITY).BOOKING_MANAGEMENT;
    this.DATE_FORMAT = this.configLoader.configurations.get(TCO.CONF.CONF_FORMAT).date['display-format'];

    if (this.pageOptionsAvailability) {
      if (this.pageOptionsAvailability.bookingItemReferenceWithReturnFlag != true) {
        this.bookingItem.bookingReferenceIdWithoutReturnFlag = this.bookingItem.bookingReferenceId
            .replace('_R', '')
            .replace(/[_][S][0-9]+/g, '');
      }
    }
  }

  public onPassengersClick(event) {
    event.stopPropagation();
    this.passengersClick.emit(this.bookingItem);
  }

  getTimeZone(time: any): string {
    if (time) {
      const offset = time.toString().split('+')[1];
      return '+' + (offset.split(':').join(''));
    }
    return '+0000';
  }
}
