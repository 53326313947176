/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./notification-toast-wrapper.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../notification-toast-item/notification-toast-item.component.ngfactory";
import * as i3 from "../notification-toast-item/notification-toast-item.component";
import * as i4 from "@angular/common";
import * as i5 from "./notification-toast-wrapper.component";
import * as i6 from "../notification-toast.service";
import * as i7 from "../notification-toast-model";
var styles_NotificationToastWrapperComponent = [i0.styles];
var RenderType_NotificationToastWrapperComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NotificationToastWrapperComponent, data: {} });
export { RenderType_NotificationToastWrapperComponent as RenderType_NotificationToastWrapperComponent };
function View_NotificationToastWrapperComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "tc-notification-toast", [], null, [[null, "toastClick"], [null, "closeClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("toastClick" === en)) {
        var pd_0 = (_co.clickToast(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } if (("closeClick" === en)) {
        var pd_1 = (_co.closeToast(_v.context.$implicit) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_NotificationToastItemComponent_0, i2.RenderType_NotificationToastItemComponent)), i1.ɵdid(1, 114688, null, 0, i3.NotificationToastItemComponent, [], { toastModel: [0, "toastModel"] }, { closeClick: "closeClick", toastClick: "toastClick" })], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_NotificationToastWrapperComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "notification-toast-wrapper"], ["id", "toasty"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i4.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotificationToastWrapperComponent_1)), i1.ɵdid(3, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "notification-toast-wrapper"; var currVal_1 = "toasty-position-bottom-right"; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _co.toasts; _ck(_v, 3, 0, currVal_2); }, null); }
export function View_NotificationToastWrapperComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "tc-notification-toast-wrapper", [], null, null, null, View_NotificationToastWrapperComponent_0, RenderType_NotificationToastWrapperComponent)), i1.ɵdid(1, 114688, null, 0, i5.NotificationToastWrapperComponent, [i6.NotificationToastService, i7.NotificationToastConfig], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NotificationToastWrapperComponentNgFactory = i1.ɵccf("tc-notification-toast-wrapper", i5.NotificationToastWrapperComponent, View_NotificationToastWrapperComponent_Host_0, {}, {}, []);
export { NotificationToastWrapperComponentNgFactory as NotificationToastWrapperComponentNgFactory };
