<div class="row">
    <ng-container>
        <div class="container-fluid">
<!--                        <ag-grid-angular-->
<!--                                class="ag-theme-material tc-ag-grid tc-ag-grid&#45;&#45;auto-height"-->
<!--                                [rowData]="rowData"-->
<!--                                [columnDefs]="columnDefs"-->
<!--                                [domLayout]="'autoHeight'"-->
<!--                                (gridReady)="onGridReadyAssignmentNotes($event)"-->
<!--                                (firstDataRendered)="onFirstDataRendered($event)">-->
<!--                        </ag-grid-angular>-->
<!--            -->
            <!--Primary resource layout-->
            <div class="row" *ngIf="primaryCost">
                <div class="col-xs-12 col-sm-4">
                    <div class="tc-flex-box">
                        <div class="tc-flex-item tc-item-circle">
                            <div class="icon-box tc-mb-2"><i class="material-icons">looks_one</i></div>
                        </div>
                        <div class="tc-flex-item tc-input-view">
                            <div class="tc-input-view__label tc-input-view__label--light">{{'Primary Service'|translate}}</div>
                            <div class="tc-input-view__value tc-input-view__value--regular">{{primaryCost.serviceType}}</div>
                        </div>
                    </div>


                </div>
                <div class="col-xs-12 col-sm-4">
                    <div class="tc-flex-item tc-input-view">
                        <div class="tc-input-view__label tc-input-view__label--light">{{'Estimated Cost'|translate}}</div>
                        <!--ToDo: Add proper Currency-->
                        <div class="tc-input-view__value tc-input-view__value--regular">{{primaryCost.currency}} {{primaryCost.estimatedCost}}</div>
                    </div>
                </div>
                <div class="col-xs-12 col-sm-4">
                    <mat-form-field class="tc-mt-3">
                        <span matPrefix class="tc-mr-2">{{primaryCost.currency}}</span>
                        <input matInput
                               [placeholder]="'Net Cost'|translate" [value]="primaryCost.totalCost?primaryCost.totalCost:'0'" (input)="onPrimaryCostUpdate($event)">
                    </mat-form-field>
                </div>
            </div>

            <!--Secondary resource layout-->
            <div class="row" *ngIf="secondaryCost">
                <div class="col-xs-12 col-sm-4">
                    <div class="tc-flex-box">
                        <div class="tc-flex-item tc-item-circle">
                            <div class="icon-box tc-mb-2"><i class="material-icons">looks_two</i></div>
                        </div>
                        <div class="tc-flex-item tc-input-view">
                            <div class="tc-input-view__label tc-input-view__label--light">{{'Secondary Service'|translate}}</div>
                            <div class="tc-input-view__value tc-input-view__value--regular">{{secondaryCost.serviceType}}</div>
                        </div>
                    </div>


                </div>
                <div class="col-xs-12 col-sm-4">
                    <div class="tc-flex-item tc-input-view">
                        <div class="tc-input-view__label tc-input-view__label--light">{{'Estimated Cost'|translate}}</div>
                        <div class="tc-input-view__value tc-input-view__value--regular">{{secondaryCost.currency}} {{secondaryCost.estimatedCost}}</div>
                    </div>
                </div>
                <div class="col-xs-12 col-sm-4">
                    <mat-form-field class="tc-mt-3">
                        <span matPrefix class="tc-mr-2">{{secondaryCost.currency}}</span>
                        <input matInput
                               [placeholder]="'Net Cost'|translate" [value]="secondaryCost.totalCost ? secondaryCost.totalCost.toFixed(2):'0'" (input)="onSecondaryCostUpdate($event)">
                    </mat-form-field>
                </div>
            </div>
            <div class="row tc-my-4">
                <div class="col-xs-12 col-sm-6">
                    <div class="tc-price--large">{{'Total Cost'|translate}}</div>
                </div>
                <div class="col-xs-12 col-sm-6 text-right">
                    <!--ToDo: Add proper Currency-->
                    <div class="tc-price tc-price--large tc-price--highlighted">{{currency}} {{getTotalSum()}}</div>
                </div>
            </div>
        </div>
    </ng-container>
</div>

<div class="modal-footer-area tc-d-block">
    <div class="modal-inner-footer">
        <div class="other-content"><!--Pagination goes here--></div>
        <div class="button-row">
            <button class="button-right tc-button-secondary mat-button" (click)="onClose($event)">
                {{'Close'|translate}}
            </button>
            <button
                    class="button-right tc-button-primary mat-button"
                    (click)="onSave()"
                    [disabled]="!isSaveButtonEnable()">
                {{'Save'|translate}}
            </button>
        </div>
    </div>
</div>
