import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTooltipModule } from '@angular/material';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { AgGridModule } from 'ag-grid-angular';
import { AgGridCustomRenderersModule } from '../../../widgets/framework/ag-grid-custom-components/renderers/ag-grid-custom-renderers.module';
import { TemplateRendererComponent } from '../../../widgets/framework/ag-grid-custom-components/renderers/template-renderer/template-renderer.component';
import { GridToolTipComponent } from '../../../widgets/framework/ag-grid-custom-components/renderers/tooltip/grid-tool-tip.component';
import { AgGridCustomTooltipModule } from '../../../widgets/framework/ag-grid-custom-components/tooltip/ag-grid-custom-tooltip.module';
import { PassengerTooltipComponent } from '../../../widgets/framework/ag-grid-custom-components/tooltip/passenger-tooltip/passenger-tooltip.component';
import { BookingPassengerGroupComponent } from './booking-passenger-group/booking-passenger-group.component';
import { PassengerEditorComponent } from './passenger-editor.component';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
    declarations: [
        PassengerEditorComponent,
        BookingPassengerGroupComponent
    ],
    imports: [
        CommonModule,
        MatExpansionModule,
        MatCheckboxModule,
        MatIconModule,
        MatButtonModule,
        AgGridCustomRenderersModule,
        AgGridCustomTooltipModule,
        AgGridModule.forRoot([
            TemplateRendererComponent,
            GridToolTipComponent,
            PassengerTooltipComponent
        ]),
        MatTooltipModule,
        TranslateModule.forChild()
    ],
    exports: [
        PassengerEditorComponent,
        BookingPassengerGroupComponent
    ],
    entryComponents: [
        PassengerEditorComponent
    ]
})
export class PassengerEditorModule {}
