import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ChipInputModule} from '@tc/chip-input';
import {OperatorAcceptanceSearchCriteriaComponent} from './operator-acceptance-search-criteria.component';

@NgModule({
    declarations: [OperatorAcceptanceSearchCriteriaComponent],
    imports: [
        CommonModule,
        ChipInputModule
    ],
    exports: [OperatorAcceptanceSearchCriteriaComponent],
    entryComponents: [OperatorAcceptanceSearchCriteriaComponent]
})
export class OperatorAcceptanceSearchModule {
}
