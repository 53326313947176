import * as tslib_1 from "tslib";
import { KeyControlAndAbstractSearchCriteria } from './key-control-and-abstract-search-criteria';
var LedgerEntrySearchCriteria = /** @class */ (function (_super) {
    tslib_1.__extends(LedgerEntrySearchCriteria, _super);
    function LedgerEntrySearchCriteria() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.supplierCode = '';
        _this.ledgerAccount = '';
        return _this;
    }
    return LedgerEntrySearchCriteria;
}(KeyControlAndAbstractSearchCriteria));
export { LedgerEntrySearchCriteria };
