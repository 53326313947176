import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ContentStripModule } from '@tc/content-strip';
import { DirectivesModule } from '@tc/directives';
import { ExpandedContentCardModule } from '@tc/expanded-content-card';
import { PaginatorModule } from '@tc/paginator';
import { PipesModule } from '@tc/pipes';
import { ResourceModule } from '@tc/resource/resource.module';
import { PagerService } from '@tc-core/util/ui/pager.service';
import { SectionHeadingModule } from '@tc/section-heading';
import { OpExpandedContentCardHeaderModule } from '../op-expanded-content-card-header/op-expanded-content-card-header.module';
import { ResultContentCardSuppliersModule } from '../result-content-card-suppliers/result-content-card-suppliers.module';
import { AdvTransferSupplierSearchResultsComponent } from './adv-transfer-supplier-search-results.component';
import { SupplierDetailViewModule } from '../supplier-detail-view/supplier-detail-view.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ResourceModule,
        ContentStripModule,
        ResultContentCardSuppliersModule,
        DirectivesModule,
        OpExpandedContentCardHeaderModule,
        ExpandedContentCardModule,
        PaginatorModule,
        PipesModule,
        SectionHeadingModule,
        SupplierDetailViewModule,
        PaginatorModule
    ],
    exports: [
        AdvTransferSupplierSearchResultsComponent
    ],
    declarations: [
        AdvTransferSupplierSearchResultsComponent
    ],
    providers: [PagerService],
})
export class AdvTransferSupplierSearchResultsModule {
}
