import { Component, OnInit } from '@angular/core';
import {
  NotificationToastConfig,
  NotificationToastEvent,
  NotificationToastEventType,
  NotificationToastModel
} from '../notification-toast-model';
import { NotificationToastService } from '../notification-toast.service';

@Component({
  selector: 'tc-notification-toast-wrapper',
  templateUrl: './notification-toast-wrapper.component.html',
  styleUrls: ['./notification-toast-wrapper.component.scss']
})
export class NotificationToastWrapperComponent implements OnInit {

  toasts: Array<NotificationToastModel> = [];

  constructor(
    private notificationToastService: NotificationToastService,
    private config: NotificationToastConfig
  ) { }

  ngOnInit() {
    this.notificationToastService.events.subscribe(
      (event: NotificationToastEvent) => {
        if (event.type === NotificationToastEventType.ADD) {
          const toast: NotificationToastModel = event.toast;
          this.add(toast);
        } else if (event.type === NotificationToastEventType.REMOVE) {
          const id: number = event.toastId;
          this.clear(id);
        } else if (event.type === NotificationToastEventType.REMOVE_ALL) {
          this.clearAll();
        }
      });
  }

  public closeToast(toast: NotificationToastModel) {
    this.clear(toast.id);
  }

  public clickToast(toast: NotificationToastModel) {
    if (toast.onClick) {
      toast.onClick(toast);
    }
  }

  add(toast: NotificationToastModel) {
    if (this.toasts.length >= this.config.limit) {
      this.toasts.shift();
    }
    this.toasts.push(toast);

    if (toast.timeout) {
      this._setTimeout(toast);
    }
  }

  private _setTimeout(toast: NotificationToastModel) {
    window.setTimeout(() => {
      this.clear(toast.id);
    }, toast.timeout);
  }

  clear(id: number) {
    if (id) {
      this.toasts.forEach((value: any, key: number) => {
        if (value.id === id) {
          this.toasts.splice(key, 1);
        }
      });
    }
  }

  clearAll() {
    this.toasts = [];
  }
}
