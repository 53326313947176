import * as tslib_1 from "tslib";
import { AbstractSearchCriteria } from './abstract-search-criteria';
var LocationTypeSearchCriteria = /** @class */ (function (_super) {
    tslib_1.__extends(LocationTypeSearchCriteria, _super);
    function LocationTypeSearchCriteria() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.code = '';
        return _this;
    }
    return LocationTypeSearchCriteria;
}(AbstractSearchCriteria));
export { LocationTypeSearchCriteria };
