import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FullCalendarModule } from '@fullcalendar/angular';
import { DataStore } from '@tc-core/util/framework';
import { CommonHelper } from '@tc-core/util/helpers';
import { AvailabilityCalendarModule } from '../../widgets/shared/availability-calendar/availability-calendar.module';
import { LeaveSetupFocusViewModule } from '../leave-setup-focus-view/leave-setup-focus-view.module';
import { ResCalendarSideNavModule } from '../../widgets/shared/res-calendar-side-nav/res-calendar-side-nav.module';
import { AvailabilityCalendarPageComponent } from './availability-calendar-page.component';
import {ResourceAvailabilityService} from '../../services/backend-consumers/supplier-service/resource-availability.service';

@NgModule({
    declarations: [AvailabilityCalendarPageComponent],
    imports: [
        CommonModule,
        FullCalendarModule,
        LeaveSetupFocusViewModule,
        ResCalendarSideNavModule,
        AvailabilityCalendarModule,
        BrowserAnimationsModule
    ],
    exports: [
        AvailabilityCalendarPageComponent
    ],
    providers: [
        DataStore,
        CommonHelper,
        ResourceAvailabilityService
    ]
})
export class AvailabilityCalendarPageModule {}
