import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { ObjKeysPipePipeModule } from '../../../pipes/obj-keys-pipe-pipe.module';
import { LibraryPipesModule } from '../pipes/library-pipes.module';
import { NotificationToastItemComponent } from './notification-toast-item/notification-toast-item.component';
import { NotificationToastConfig } from './notification-toast-model';
import { NotificationToastWrapperComponent } from './notification-toast-wrapper/notification-toast-wrapper.component';

@NgModule({
  declarations: [
    NotificationToastWrapperComponent,
    NotificationToastItemComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    LibraryPipesModule,
    ObjKeysPipePipeModule
  ],
  exports: [
    NotificationToastWrapperComponent,
    NotificationToastItemComponent
  ],
  providers: [
    NotificationToastConfig
  ]
})
export class NotificationToastModule {}
