<div class="container-fluid tc-item-wrapper tcpi-card">
    <div class="tc-item-wrapper__header tc-item-header-highlighted" *ngIf="showTitle">
        <div class="tc-item-header-highlighted__icon">
            <i class="material-icons material-icons">description</i>
        </div>
        <div class="tc-item-header-highlighted__primary">{{"Remarks" | translate}}</div>
    </div>
    <div class="tc-item-wrapper__body" *ngIf="noteList.length===0">
        <div class="row">
            <div class="col-md-12">
                <div class="tc-flex-box">
                    <div class="tc-flex-item icon-cage input-icon">
                        <div class="card-icon--small icn-color-primary">
                            <i class="material-icons material-icons">description</i>
                        </div>
                    </div>
                    <div class="tc-flex-item input-cage">
                        <div class="container-fluid">
                            <span class="txt-light">
                            {{'No Remarks' | translate}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="tc-item-wrapper__body" *ngFor="let note of noteList">
        <div class="row">
            <div class="col-md-12">
                <div class="tc-flex-box">
                    <div class="tc-flex-item icon-cage input-icon">
                        <div class="card-icon--small icn-color-primary">
                        </div>
                    </div>
                    <div class="tc-flex-item input-cage">
                        <div class="col-xs-12 col-sm-6  col-md-3 col-lg-3">
                            <div class="tc-content--wrap tc-input-view">
                                <div class="tc-caption-txt txt-light">
                                    {{"Note" | translate}}
                                </div>
                                <div
                                        id="TRD_PER_INFO_CLI_NAME"
                                        class="tc-body1--txt-large">
                                    {{note?.remark ?
                                    note.remark : EMPTY_CHAR}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
