/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../node_modules/@tc/paginator/paginator.component.ngfactory";
import * as i2 from "@tc/paginator/paginator.component";
import * as i3 from "../../../widgets/shared/result-header/result-header.component.ngfactory";
import * as i4 from "../../../widgets/shared/result-header/result-header.component";
import * as i5 from "../../../widgets/shared/adv-supplier-assign-search-result/adv-supplier-assign-search-result.component.ngfactory";
import * as i6 from "../../../widgets/shared/adv-supplier-assign-search-result/adv-supplier-assign-search-result.component";
import * as i7 from "../../../services/util/change-detector/change-detector.service";
import * as i8 from "@angular/common";
import * as i9 from "./supplier-assign-panel.component";
import * as i10 from "@tc-core/service/sorters/sorter.service";
import * as i11 from "../../../services/util/framework/dmc-query-params.service";
import * as i12 from "../../../services/util/framework/data-store.service";
import * as i13 from "@tc-core/util/framework/config-loader.service";
import * as i14 from "../../../services/backend-consumers/supplier-service/supplier.service";
import * as i15 from "@tc-core/util/helpers/common-helper.service";
import * as i16 from "@tc-core/util/ui/auto-scroll.service";
var styles_SupplierAssignPanelComponent = [];
var RenderType_SupplierAssignPanelComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SupplierAssignPanelComponent, data: {} });
export { RenderType_SupplierAssignPanelComponent as RenderType_SupplierAssignPanelComponent };
function View_SupplierAssignPanelComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-paginator", [], null, [[null, "page"], [null, "more"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("page" === en)) {
        var pd_0 = (_co.onPage($event) !== false);
        ad = (pd_0 && ad);
    } if (("more" === en)) {
        var pd_1 = (_co.onMore() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_PaginatorComponent_0, i1.RenderType_PaginatorComponent)), i0.ɵdid(1, 638976, null, 0, i2.PaginatorComponent, [], { length: [0, "length"], index: [1, "index"], size: [2, "size"], hasMore: [3, "hasMore"] }, { page: "page", more: "more" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.contentCardDataList.length; var currVal_1 = _co.pager.currPage; var currVal_2 = _co.pager.itemsPerPage; var currVal_3 = _co.hasMore; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_SupplierAssignPanelComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "div", [["class", "focus-view__content-wrapper"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "div", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "div", [["class", "assign-panel-search"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 2, "div", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "tc-result-header", [], null, [[null, "selectSorter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectSorter" === en)) {
        var pd_0 = (_co.onSort($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_ResultHeaderComponent_0, i3.RenderType_ResultHeaderComponent)), i0.ɵdid(5, 114688, null, 0, i4.ResultHeaderComponent, [], { transferModeList: [0, "transferModeList"], heading: [1, "heading"], groupingOptionList: [2, "groupingOptionList"], sorters: [3, "sorters"] }, { selectSorter: "selectSorter" }), (_l()(), i0.ɵeld(6, 0, null, null, 2, "div", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 1, "tc-adv-supplier-assign-search-result", [], null, [[null, "supplierSelect"], [null, "cardScroll"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("supplierSelect" === en)) {
        var pd_0 = (_co.onSupplierSelect($event) !== false);
        ad = (pd_0 && ad);
    } if (("cardScroll" === en)) {
        var pd_1 = (_co.onCardScroll($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i5.View_AdvSupplierAssignSearchResultComponent_0, i5.RenderType_AdvSupplierAssignSearchResultComponent)), i0.ɵdid(8, 114688, null, 0, i6.AdvSupplierAssignSearchResultComponent, [i7.ChangeDetectorService], { sorter: [0, "sorter"], sortingHandler: [1, "sortingHandler"], sorters: [2, "sorters"], resultsList: [3, "resultsList"], selectedSupplier: [4, "selectedSupplier"], bookingData: [5, "bookingData"], bookingDataArray: [6, "bookingDataArray"], isBulkAssignment: [7, "isBulkAssignment"], isGenResourceAssignment: [8, "isGenResourceAssignment"], assignedSupplier: [9, "assignedSupplier"], pager: [10, "pager"] }, { supplierSelect: "supplierSelect", cardScroll: "cardScroll" }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SupplierAssignPanelComponent_1)), i0.ɵdid(10, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵEMPTY_ARRAY; var currVal_1 = _co.heading; var currVal_2 = i0.ɵEMPTY_ARRAY; var currVal_3 = _co.sorters_; _ck(_v, 5, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = _co.sorterService.sorter; var currVal_5 = _co.sorterService.method; var currVal_6 = _co.sorters_; var currVal_7 = _co.contentCardDataList; var currVal_8 = _co.selectedSummaryCardData; var currVal_9 = (_co.isGenResourceAssignment ? _co.genTourItem : _co.bookingData); var currVal_10 = _co.bookingDataArray; var currVal_11 = _co.isBulkAssignment; var currVal_12 = _co.isGenResourceAssignment; var currVal_13 = _co.selectedSupplier; var currVal_14 = _co.pager; _ck(_v, 8, 1, [currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14]); var currVal_15 = (_co.contentCardDataList.length > _co.defaultPageResultCount); _ck(_v, 10, 0, currVal_15); }, null); }
export function View_SupplierAssignPanelComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-supplier-assign-panel", [], null, null, null, View_SupplierAssignPanelComponent_0, RenderType_SupplierAssignPanelComponent)), i0.ɵdid(1, 114688, null, 0, i9.SupplierAssignPanelComponent, [i10.SorterService, i11.DMCQueryParamsService, i12.DataStoreService, i13.ConfigLoader, i14.SupplierService, i15.CommonHelper, i16.AutoScrollService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SupplierAssignPanelComponentNgFactory = i0.ɵccf("tc-supplier-assign-panel", i9.SupplierAssignPanelComponent, View_SupplierAssignPanelComponent_Host_0, { isDataLoaded: "isDataLoaded", isTriggerSearch: "isTriggerSearch", displayChipsArr: "displayChipsArr", isFocus: "isFocus", isSearchBarStyleNotAvailable: "isSearchBarStyleNotAvailable", placeHolderText: "placeHolderText", selectedSupplier: "selectedSupplier", currencyFormat: "currencyFormat", tAssignedSuppliers: "tAssignedSuppliers", contentCardDataList: "contentCardDataList", selectedSummaryCardData: "selectedSummaryCardData", bookingData: "bookingData", bookingDataArray: "bookingDataArray", genTourItem: "genTourItem", isBulkAssignment: "isBulkAssignment", changesAvailableForServiceItems: "changesAvailableForServiceItems", heading: "heading", hasMore: "hasMore", fetchedSupplierCount: "fetchedSupplierCount", dataFromMoreClick: "dataFromMoreClick", isVehicleAssignment: "isVehicleAssignment", isDriverAssignment: "isDriverAssignment", isGenResourceAssignment: "isGenResourceAssignment", pager: "pager" }, { supplierSelect: "supplierSelect", onPaginationMoreClick: "onPaginationMoreClick" }, []);
export { SupplierAssignPanelComponentNgFactory as SupplierAssignPanelComponentNgFactory };
