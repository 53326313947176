<tc-split-passengers-view class="split-passengers-view-wrapper"
        [transferMode]="transferMode"
        [isRequestCompleted]="isRequestCompleted"
        [serviceItem]="serviceItem"
        [passengerData]="selectedGroupPassengerData"
        [passengerDataHeadings]="passengerDataHeadings"
        [passengerGroups]="passengerGroups"
        [groupingOptionList]="groupingOptionList"
        (split)="onSplit($event)"
        (save)="onSave($event)"
        (selectGroup)="selectGroup($event)"
        (closePopUp)="onClosePopup($event)"
        (selectGrouping)="onSelectGroupingType($event)">
</tc-split-passengers-view>
