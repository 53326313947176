import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TcErrorType } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-error';
import { SpinnerService } from '@tc-core/util/ui/spinner.service';
import { Observable } from 'rxjs';
import { BackgroundTask } from '../../../models/background/background-task';
import { DispatchEntrySearchCriteria } from '../../../models/criteria/dispatch-entry-search-criteria';
import { DocumentCreationCriteria, DocumentType } from '../../../models/criteria/document-creation-criteria';
import { DocumentDataCriteria } from '../../../models/criteria/document-data-criteria';
import { DocumentSearchCriteria } from '../../../models/criteria/document-search-criteria';
import { DispatchEntry } from '../../../models/document/dispatch-entry';
import { DispatchEntryOperation, DispatchPatch } from '../../../models/document/dispatch-patch';
import { OPResponseWrapper } from '../../../models/response/op-response-wrapper';
import { DateTimeProcessor } from '../../business-utils/time-zone/date-time-processor';
import { BackgroundTaskService } from '../../util/change-detector/background-task.service';
import { DMCCommon } from '../../util/common/dmc-common';
import { DMCQueryParamsService } from '../../util/framework/dmc-query-params.service';
import { RequestService } from '../../util/framework/request.service';
import { ResponseUtil } from '../../util/response/response-util.service';
import { DMCBaseService } from '../../util/system/dmc-base.service';
import { UrlPaths } from '../url-paths';

@Injectable({
    providedIn: 'root'
})
export class DocumentService {

    constructor(
        private queryParamsService: DMCQueryParamsService,
        private requestService: RequestService,
        private http: HttpClient,
        private spinnerService: SpinnerService,
        private common: DMCCommon,
        private backgroundTaskService: BackgroundTaskService,
        private baseService: DMCBaseService
    ) { }

    downloadDocument(documentId: number) {
        const criteria = new DocumentCreationCriteria();
        const params = this.queryParamsService.asString(criteria);
        criteria.documentType = DocumentType.SUPPLIER_TRAN_COST_SHEET;
        criteria.download = true;

        const url = this.requestService.getUrl(
            UrlPaths.OP_DOCUMENT_URL_KEY,
            [UrlPaths.OP_DOCUMENT_VERSION, UrlPaths.DOCUMENT, documentId.toString(), UrlPaths.DOCUMENT_FILE]
        );
        this.spinnerService.show();
        this.http.get<any>(url + params, {
            observe: 'response',
            responseType: 'blob' as 'json'
        }).subscribe(
            (response: HttpResponse<any>) => {
                this.spinnerService.hide();
                if (criteria.download) {
                    const headers = response.headers;
                    const contentDisposition = headers.get('Content-Disposition');
                    const filename = this.getFileName(contentDisposition).replace(/[&\/\\#,+()$~%'":*?<>{}]/g, '');
                    this.downloadFile(response, filename);
                }
            },
            error => {
                this.spinnerService.hide();
                this.common.showSnackBar(
                    'Error occurred',
                    3000,
                    TcErrorType.TYPE.WARN
                );
            }
        );
    }

    downloadDocuments(documentIds: number[]) {
        const params = this.queryParamsService.asQueryParams({documentIds});
        const url = this.requestService.getUrl(
            UrlPaths.OP_DOCUMENT_URL_KEY,
            [UrlPaths.OP_DOCUMENT_VERSION, UrlPaths.DOCUMENT, UrlPaths.DOCUMENT_FILES]
        );
        this.spinnerService.show();
        this.http.get<any>(url, {
            observe: 'response',
            responseType: 'blob' as 'json',
            params
        }).subscribe(
            (response: HttpResponse<any>) => {
                this.spinnerService.hide();
                const headers = response.headers;
                const contentDisposition = headers.get('Content-Disposition');
                const filename = this.getFileName(contentDisposition).replace(/[&\/\\#,+()$~%'":*?<>{}]/g, '');
                this.downloadFile(response, filename);
            },
            error => {
                this.spinnerService.hide();
                this.common.showSnackBar(
                    'Error occurred',
                    3000,
                    TcErrorType.TYPE.WARN
                );
            }
        );
    }

    public downloadFile(response: HttpResponse<Blob>, filename: string) {
        if (filename) {
            const blob = new Blob([response.body], {type: 'octet/stream'});
            if (window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveBlob(blob, filename);
            } else {
                const anchorElement = document.createElement('a');
                anchorElement.href = window.URL.createObjectURL(blob);
                if (filename) {
                    anchorElement.setAttribute('download', filename);
                }
                document.body.appendChild(anchorElement);
                anchorElement.click();
                document.body.removeChild(anchorElement);
            }
        } else {
            this.common.showSnackBar(
                'No Files',
                3000,
                TcErrorType.TYPE.WARN
            );
        }
    }

    getDocumentTypes(reportsOnly: boolean, manifestsOnly: boolean): any {
        const reqPrams = this.queryParamsService.asQueryParams({
            reportsOnly,
            manifestsOnly
        });
        return this.requestService.get(
            UrlPaths.OP_DOCUMENT_URL_KEY,
            [UrlPaths.OP_DOCUMENT_VERSION, UrlPaths.DOCUMENT_TYPES],
            reqPrams
        );
    }

    createAndDownloadDocument(criteria: DocumentCreationCriteria, dataCriteria: DocumentDataCriteria) {
        this.postForDownloadFile(criteria, dataCriteria);
    }

    postForDownloadFile(criteria: DocumentCreationCriteria, dataCriteria: DocumentDataCriteria): void {
        const params = this.queryParamsService.asString(criteria);
        const url = this.requestService.getUrl(
            UrlPaths.OP_DOCUMENT_URL_KEY,
            [UrlPaths.OP_DOCUMENT_VERSION, UrlPaths.DOCUMENT]
        );
        this.spinnerService.show();
        this.baseService.getForBlob(url, params, dataCriteria)
            .subscribe(
                (response: HttpResponse<any>) => {
                    this.spinnerService.hide();
                    if (response && response.body && response.body.type === 'application/octet-stream') {
                        if (criteria.download) {
                            const headers = response.headers;
                            const contentDisposition = headers.get('Content-Disposition');
                            const filename = this.getFileName(contentDisposition);
                            this.downloadFile(response, filename);
                        }
                    } else if (response && response.body && response.body.type === 'application/json') {
                        response.body.text().then(
                            t => {
                                const backgroundTask: BackgroundTask = ResponseUtil.getFirstData(JSON.parse(t));
                                this.backgroundTaskService.startStatusChecking(backgroundTask);
                                this.common.showSnackBar(
                                    `Transfer manifest generation background task id: ${backgroundTask.taskId} is started. You will be notified on the generation status.`,
                                    5000,
                                    TcErrorType.TYPE.INFO
                                );
                            }
                        );
                    }
                },
                error => {
                    this.spinnerService.hide();
                    this.common.showSnackBar(
                        'Error occurred',
                        3000,
                        TcErrorType.TYPE.WARN
                    );
                }
            );
    }

    getFileName(contentDisposition: string) {
        // contentDisposition=attachment; filename=ARRIVAL_LIST_REPORT1593856889219.xlsx
        if (contentDisposition) {
            const strings = contentDisposition.split('attachment; filename=');
            if (strings && strings.length > 1) {
                return strings[1];
            }
        }
        return null;
    }

    // postForDownload(criteria: DocumentCreationCriteria, dataCriteria: DocumentDataCriteria) {
    //     const params = this.queryParamsService.asString(criteria);
    //     const url = this.requestService.getUrl(
    //         UrlPaths.OP_DOCUMENT_URL_KEY,
    //         [UrlPaths.OP_DOCUMENT_VERSION, UrlPaths.DOCUMENT]
    //     );
    //
    //     return this.http.post<HttpResponse<Blob>>(
    //         url + params,
    //         dataCriteria,
    //         {
    //             responseType: 'blob' as 'json'
    //         }
    //     );
    // }

    searchDocuments(criteria: DocumentSearchCriteria): Observable<OPResponseWrapper<any>> {
        DateTimeProcessor.processDocumentSearchCriteriaDateTimes(criteria);
        const reqPrams = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.get(
            UrlPaths.OP_DOCUMENT_URL_KEY,
            [UrlPaths.OP_DOCUMENT_VERSION, UrlPaths.DOCUMENT],
            reqPrams
        );
    }

    postDispatchEntry(documentId: number, dispatchEntry: DispatchEntry): Observable<OPResponseWrapper<any>> {
        return this.requestService.post(
            UrlPaths.OP_DOCUMENT_URL_KEY,
            [UrlPaths.OP_DOCUMENT_VERSION, UrlPaths.DOCUMENT, documentId.toString(), UrlPaths.DISPATCH_ENTRIES],
            dispatchEntry
        );
    }

    patchDispatchEntry(
        documentId: number, dispatchId: number, dispatchPatch: DispatchPatch,
        operation: DispatchEntryOperation
    ): Observable<OPResponseWrapper<any>> {
        const reqPrams = this.queryParamsService.asQueryParams({operation});
        return this.requestService.patch(
            UrlPaths.OP_DOCUMENT_URL_KEY,
            [
                UrlPaths.OP_DOCUMENT_VERSION,
                UrlPaths.DOCUMENT,
                documentId.toString(),
                UrlPaths.DISPATCH_ENTRIES,
                dispatchId.toString()
            ],
            dispatchPatch,
            reqPrams
        );
    }

    public searchDispatchEntries(documentId: number, criteria: DispatchEntrySearchCriteria) {
        const reqPrams = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.get(
            UrlPaths.OP_DOCUMENT_URL_KEY,
            [UrlPaths.OP_DOCUMENT_VERSION, UrlPaths.DOCUMENT, documentId.toString(), UrlPaths.DISPATCH_ENTRIES],
            reqPrams
        );
    }
}
