import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { TcErrorType } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-error';
import { DialogModel } from '@tc-core/model/it/codegen/ui/framework/dialog-model';
import { LEVEL, ModalData } from '@tc-core/model/it/codegen/ui/framework/modal-data';
import { PageSection } from '@tc-core/model/it/codegen/ui/framework/page-section';
import { TC } from '@tc-core/util';
import { Common } from '@tc-core/util/common/common';
import { ConfigLoader, EventManager, UserJourneyManager } from '@tc-core/util/framework';
import { SpinnerService } from '@tc-core/util/ui';
import { DialogService } from '@tc/dialog/dialog.service';
import { FocusViewService } from '@tc/focus-view/focus-view.service';
import { ModalService } from '@tc/modal/modal.service';
import { Subscription } from 'rxjs';
import { AutoCompleteEditorComponent } from '../../../../widgets/framework/ag-grid-custom-components/editors/auto-complete-editor/auto-complete-editor.component';
import { DateEditorComponent } from '../../../../widgets/framework/ag-grid-custom-components/editors/date-editor/date-editor.component';
import { DropdownEditorComponent } from '../../../../widgets/framework/ag-grid-custom-components/editors/dropdown-editor/dropdown-editor.component';
import { MultiSelectorEditorComponent } from '../../../../widgets/framework/ag-grid-custom-components/editors/multi-selector-editor/multi-selector-editor.component';
import { RadioButtonEditorComponent } from '../../../../widgets/framework/ag-grid-custom-components/editors/radio-button-editor/radio-button-editor.component';
import { ActionsComponent } from '../../../../widgets/framework/ag-grid-custom-components/renderers/actions/actions.component';
import { CheckBoxRendererEditorComponent } from '../../../../widgets/framework/ag-grid-custom-components/renderers/check-box-selector-renderer/check-box-renderer-editor.component';
import { CheckBoxSelectorComponent } from '../../../../widgets/framework/ag-grid-custom-components/renderers/check-box-selector/check-box-selector.component';
import { TemplateRendererComponent } from '../../../../widgets/framework/ag-grid-custom-components/renderers/template-renderer/template-renderer.component';
import { ActionColumnComponent } from '../../../../widgets/framework/ag-grid/action-column.component';
import { AutoCompleteComponent } from '../../../../widgets/framework/ag-grid/auto-complete.component';
import { CurrencyInputEditorComponent } from '../../../../widgets/framework/ag-grid/currency-input-editor.component';
import { MultiSelectComponent } from '../../../../widgets/framework/ag-grid/multi-select.component';
import { NumericEditorComponent } from '../../../../widgets/framework/ag-grid/numeric-editor.component';
import { TCO } from '../../../../constants';
import { FileUploaderComponent } from '../../../../widgets/framework/file-uploader/file-uploader.component';
import { DateRange } from '../../../../widgets/framework/cg-calendar/date-range';
import { RangeGroup } from '../../../../widgets/framework/cg-calendar/range-group';
import { SeasonSetupSummaryComponent } from '../../../../widgets/framework/cg-season-calendar/season-setup-summary/season-setup-summary.component';
import { DocumentCreationCriteria, DocumentFormat, DocumentType } from '../../../../models/criteria/document-creation-criteria';
import { DocumentDataCriteria } from '../../../../models/criteria/document-data-criteria';
import { ResourceType } from '../../../../models/reservation/assignment';
import { GenResourceCostSetup } from '../../../../models/supplier/gen-resource-cost-setup';
import { Season } from '../../../../models/supplier/season';
import { SeasonDateRange } from '../../../../models/supplier/season-date-range';
import { Supplier } from '../../../../models/supplier/supplier';
import { DocumentService } from '../../../../services/backend-consumers/documents/document.service';
import { DataHandlerService } from '../../../../services/backend-consumers/master-data-handler-service/data-handler.service';
import { SupplierService } from '../../../../services/backend-consumers/supplier-service/supplier.service';
import { DMCCommon } from '../../../../services/util/common/dmc-common';
import { DataKey, DataStoreService } from '../../../../services/util/framework/data-store.service';
import { GridColumnDefinitionProcessorService } from '../../../../services/util/pre-processors/grid-column-definition-processor.service';
import { ResponseUtil } from '../../../../services/util/response/response-util.service';
import { CardDataHandlerService } from '../../../../services/util/ui/card-data-handler-service.service';
import { ContentSummaryCardService } from '../../../../services/util/ui/content-summary-card.service';
import { RowErrorIndicatorComponent } from '../../../../widgets/framework/ag-grid-custom-components/renderers/row-error-indicator/row-error-indicator.component';
import EMPTY_CHAR = TC.FORMAT.EMPTY_CHAR;

function removeSelectedData(selectedData: any) {
    for (let i = 0; i < this.resourceCosts.length; i++) {
        if (this.resourceCosts[i].tempCostId === selectedData.tempCostId) {
            this.resourceCosts.splice(i, 1);
            break;
        }
    }
}

@Component({
    selector: 'tc-generic-cost-setup-focus-view',
    templateUrl: './generic-cost-setup-focus-view.component.html'
})
export class GenericCostSetupFocusViewComponent implements OnInit, AfterViewInit {
    @Input() supplierCostSetup: Season[] = [];
    @Input() resourceCosts: GenResourceCostSetup[] = [];
    @Input() selectedResourceTypes: any[] = [];
    @Input() selectedProfileTypes: any[] = [];
    @Input() currencyList: any[] = [];
    @Input() costingTypeList: any[] = [];
    @Input() daysOfWeek: any[] = [];
    @Input() defaultProfileType: any;
    @Input() resourceTypes: any[] = [];

    colDefConfig: any;
    gridOptions: any;
    frameworkComponents: any = {
        'templateRenderer': TemplateRendererComponent,
        'checkBoxRendererEditor': CheckBoxRendererEditorComponent,
        'checkBoxSelector': CheckBoxSelectorComponent,
        'dropDownEditor': DropdownEditorComponent,
        'multiSelectorEditor': MultiSelectorEditorComponent,
        'radioButtonEditor': RadioButtonEditorComponent,
        'autoCompleteEditor': AutoCompleteEditorComponent,
        'dateEditor': DateEditorComponent,
        'actionRenderer': ActionsComponent
    };

    title = 'app';
    seasons = [];
    isInvalidCost = false;
    isCostMissing = false;
    private gridApi;
    private gridColumnApi;
    columnDefs;
    rowData;
    defaultColDef;
    pinnedTopRowData;
    pinnedBottomRowData;
    rowClassRules;
    allowedProfTypes: any[] = [];
    rangeGroupsForSeasonCalendar: RangeGroup[] = [];
    private pageSectionsMap: Map<string, PageSection>;
    public costAddSection: PageSection;
    private CARD_CONFIG_GENERIC_EDIT = 'supplier_edit';
    private genericCostSheetImportSubscribe: Subscription = new Subscription();
    //upload file to supplier service and subscribe for results
    private genCostSheetUploadSubscribe: Subscription = new Subscription();
    //supplier search by Id to get updated costs
    private supplierDataSubscribe: Subscription = new Subscription();
    private resourceCostData: Season[];
    paginationPageSize: number;

    allDayKeys = '';
    supplierId: number;

    private templateName: string = 'SUPPLIER_GEN_COST_SHEET';

    constructor(
        private em: EventManager,
        private configLoader: ConfigLoader,
        private journeyManager: UserJourneyManager,
        private dataStore: DataStoreService,
        private focusViewService: FocusViewService,
        private summaryCardService: ContentSummaryCardService,
        private dataHandlerService: DataHandlerService,
        private spinnerService: SpinnerService,
        private cardDataHandlerService: CardDataHandlerService,
        private dialogService: DialogService,
        private documentService: DocumentService,
        private modalService: ModalService,
        private common: DMCCommon,
        private supplierService: SupplierService,
        private columnDefinitionProcessorService: GridColumnDefinitionProcessorService,
        private cd: ChangeDetectorRef,

    ) {
        this.defaultColDef = {
            filter: false,
            sortable: false,
            resizable: true
        };

        this.gridOptions = {
            rowSelection: true,
            cacheBlockSize: 20,
            maxBlocksInCache: 2,
            enableServerSideFilter: false,
            enableServerSideSorting: false,
            rowModelType: 'infinite',
            pagination: true,
            paginationPageSize: 10
        };
        this.rowClassRules = {
            'tc-ag-grid-row--error': function(params) { return params.data.isInvalid;}
        };
    }

    ngOnInit() {
        this.initPageSectionReferences();
        this.getAllDayKeys();
        this.mapToSeasonCalendarRanges();
        this.colDefConfig = this.configLoader.configurations.get(TCO.CONF.CONF_GENERIC_COST_SETUP_GRID_CONFIG);
        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'CLOSE_FOCUS_VIEW').subscribe(e => {

            this.dataStore.set(DataKey.supplierCostFocusViewClose, null);
            this.dataStore.set(DataKey.supplierCostFocusViewClose, true);
            this.saveResourceCostData();
            this.focusViewService.close(true);
        });

        // **** set genericGroupResults to grid*** //

        if (this.supplierCostSetup !== null && this.supplierCostSetup.length > 0) {
            this.supplierId = this.supplierCostSetup[0].supplierId;
            for (let i = 0; i < this.supplierCostSetup.length; i++) {
                this.seasons.push({
                    'code': this.supplierCostSetup[i].tempSeasonId,
                    'name': this.supplierCostSetup[i].name
                });
            }

        }
        this.setGridData();
    }

    private initPageSectionReferences() {
        this.pageSectionsMap = this.cardDataHandlerService.getAllPageSections(
            this.CARD_CONFIG_GENERIC_EDIT,
            this.resourceCosts
        );

        this.costAddSection = this.pageSectionsMap.get('supplier_generic_cost_setup');

    }

    onHeaderActionClick(event) {
        if (event.id === 'TRIGGER_SHOW_SEASON_SUMMARY') {
            this.showSeasonSummary(event);
        } else if (event.id === 'TRIGGER_ADD_COST') {
            this.addRow();
        } else if (event.id === 'TRIGGER_SAVE_COST_DATA') {
            this.saveResourceCostData();
        } else if (event.id === 'TRIGGER_EXPORT_FILE') {
            this.exportCostData();
        } else if (event.id === 'TRIGGER_IMPORT_FILE') {
            this.importCostData();
        }
    }

    addRow() {
        if (this.seasons.length > 0) {
            let resourceCost = new GenResourceCostSetup();
            resourceCost.tempCostId = this.getNextTempId();
            resourceCost.currency = 'AED';
            resourceCost.unitFare = 0;
            resourceCost.adultFare = 0;
            resourceCost.childFare = 0;
            resourceCost.infantFare = 0;
            resourceCost.isInvalid = true;
            resourceCost.dayArray = this.daysOfWeek;
            this.mapDayKeys(resourceCost);
            this.resourceCosts.unshift(resourceCost);
            this.gridApi.updateRowData({
                add: [resourceCost],
                addIndex: 0
            });
            this.validateUniqueValues(resourceCost);
        } else {
            let confirmError = new DialogModel(
                true,
                LEVEL.ERROR,
                'Failed',
                'Please add seasons',
                true,
                2000
            );
            this.dialogService
                .confirm(confirmError)
                .subscribe((res) => {
                    if (res === true) {
                        console.log('true');
                    }
                });
        }
    }

    //get all keys of whole week
    getAllDayKeys() {
        this.daysOfWeek.forEach(data => {this.allDayKeys += data.key;});
    }

    private getNextTempId(): number {
        let maxId = 0;
        for (let j = 0; j < this.resourceCosts.length; j++) {
            let resourceCost = this.resourceCosts[j];
            let tempId = resourceCost.tempCostId;
            if (tempId > maxId) {
                maxId = tempId;
            }
        }
        maxId++;
        return maxId;
    }

    saveResourceCostData() {
        this.addDataFromGrid(this.gridApi, this.resourceCosts);
        this.dataStore.set(DataKey.genResourceCosts, null, true);
        this.isInvalidCost = !!(this.resourceCosts && this.resourceCosts.length > 0 &&
            this.resourceCosts.find(value => value.isInvalid));
        this.dataStore.set(DataKey.supplierGenCostSetupValid, !this.isInvalidCost, true);
        this.dataStore.set(DataKey.genResourceCosts, this.resourceCosts, true);
    }

    addDataFromGrid(gridApi: any, list: any[]) {
        list.length = 0;
        gridApi.forEachNode(function(node) {
            list.push(node.data);
        });
    }

    private mapToSeasonCalendarRanges() {
        // this.rangeGroupsForSeasonCalendar = [];
        for (let i = 0; i < this.supplierCostSetup.length; i++) {
            let season: Season = this.supplierCostSetup[i];
            let rangeGroup = new RangeGroup();
            rangeGroup.id = season.tempSeasonId;
            rangeGroup.name = season.name;
            rangeGroup.colorId = season.colorId;

            let dateRanges: DateRange[] = [];
            for (let j = 0; j < season.seasonDateRanges.length; j++) {

                let seasonDateRange: SeasonDateRange = season.seasonDateRanges[j];
                let dateRange = new DateRange();
                dateRange.id = seasonDateRange.dateRangeId;
                dateRange.startDate = new Date(seasonDateRange.startDate);
                dateRange.endDate = new Date(seasonDateRange.endDate);
                dateRanges.push(dateRange);
            }
            rangeGroup.dateRanges = dateRanges;
            this.rangeGroupsForSeasonCalendar.push(rangeGroup);
        }
    }

    showSeasonSummary(event) {
        const dataObject = new ModalData(
            LEVEL.SUCCESS,
            'Season Setup Summary',
            null,
            {'label': 'CLOSE'},
            'modal-basic--fixed-size modal-basic--show-footer',
            SeasonSetupSummaryComponent,
            {rangeGroups: this.rangeGroupsForSeasonCalendar}
        );
        this.modalService
            .confirm(dataObject).subscribe(res => {
            if (res) {
            }
        });
    }

    // *********************ag grid implementation************************** //

    setGridData() {
        if (this.gridApi) {
            this.gridApi.setRowData(this.resourceCosts);
            // this.gridApi.sizeColumnsToFit();
        } else {
            setTimeout(() => this.setGridData(), 0);
        }
    }

    onGridReady(params) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.gridApi.setFloatingFiltersHeight(38);
        this.gridApi.setHeaderHeight(40);
        this.gridApi.setGroupHeaderHeight(40);
        this.autoSizeAll();
        this.gridApi.refreshCells(params);

        // this.gridApi.sizeColumnsToFit();
    }

    public ngAfterViewInit(): void {
        this.setColumnDefs();
    }

    private setColumnDefs() {
        let colDefConfig = JSON.parse(JSON.stringify(this.colDefConfig));
        this.columnDefs = this.columnDefinitionProcessorService.createColumnDefs(colDefConfig, this);
        this.cd.detectChanges();
    }

    autoSizeAll() {
        const allColumnIds = [];
        if (this.gridColumnApi) {
            this.gridColumnApi.getAllColumns().forEach(function(column) {
                allColumnIds.push(column.colId);
            });
            this.gridColumnApi.autoSizeColumns(allColumnIds);
        }
    }


    // private getProfileTypes(resourceType) {
    //     return this.selectedResourceTypes.filter(value => value.code === resourceType)[0];
    // }

    onCellValueChange(params) {

        var colId = params.column.getId();
        if (colId === 'resourceType') {
            var selectedProfType = params.data.profileType;
            this.allowedProfTypes = params.data.resourceType.profileTypes;
            var profMismatch = this.allowedProfTypes.indexOf(selectedProfType) < 0 || selectedProfType.code === 'ANY';
            if (profMismatch) {
                params.node.setDataValue('profileType', null);
            }
            // this.gridApi.getSelectedRows()[0].resourceType = this.gridApi.getSelectedRows()[0].resourceType.code ? this.gridApi.getSelectedRows()[0].resourceType.code : this.getCode(this.selectedResourceTypes, this.gridApi.getSelectedRows()[0].resourceType);
            this.validateUniqueValues(this.gridApi.getSelectedRows()[0]);
        } else if (colId === 'profileType') {
            this.validateUniqueValues(this.gridApi.getSelectedRows()[0]);
        } else if (colId === 'seasonName') {
            this.gridApi.getSelectedRows()[0].tempSeasonId = this.gridApi.getSelectedRows()[0].seasonName.code;
            this.validateUniqueValues(this.gridApi.getSelectedRows()[0]);
        } else if (colId.slice(0, -2) === 'dayArray' || colId === 'dayArray') {
            let dayValues = this.gridApi.getSelectedRows()[0].dayArray;
            let days = [];
            dayValues.forEach(day => {
                days.push(day.day ? day.day : day.value);
            });
            let dayKeys = '';
            this.daysOfWeek.forEach(data => {
                if (days.includes(data.value)) {dayKeys += data.key;}
            });
            this.gridApi.getSelectedRows()[0].dayKeys = dayKeys;
            this.validateUniqueValues(this.gridApi.getSelectedRows()[0]);
        } else if (colId === 'dayValues') {
            let dayValues = this.gridApi.getSelectedRows()[0].dayValues;
            let dayKeys = '';
            this.daysOfWeek.forEach(data => {
                if (dayValues.includes(data.value)) {dayKeys += data.key;}
            });
            this.gridApi.getSelectedRows()[0].dayKeys = dayKeys;
            this.validateUniqueValues(this.gridApi.getSelectedRows()[0]);
        } else if (colId === 'costingType') {
            this.gridApi.getSelectedRows()[0].unitFare = 0;
            this.gridApi.getSelectedRows()[0].adultFare = 0;
            this.gridApi.getSelectedRows()[0].childFare = 0;
            this.gridApi.getSelectedRows()[0].infantFare = 0;
            this.validateUniqueValues(this.gridApi.getSelectedRows()[0]);
        }
        // set warning to missing cost fields
        let selectedResourceCost = this.gridApi.getSelectedRows()[0];
        this.isCostMissing = selectedResourceCost && !selectedResourceCost.unitFare && !selectedResourceCost.adultFare;

        this.gridApi.refreshCells(params);

    }

    validateUniqueValues(selectedResourceCost) {
        selectedResourceCost = selectedResourceCost ? selectedResourceCost : this.gridApi.getSelectedRows()[0];
        if (selectedResourceCost && selectedResourceCost.resourceType && selectedResourceCost.tempSeasonId &&
            selectedResourceCost.profileType && selectedResourceCost.dayKeys) {
            for (let i = 0; i < this.resourceCosts.length; i++) {
                if (this.resourceCosts[i].tempCostId !== selectedResourceCost.tempCostId &&
                    this.resourceCosts[i].resourceType &&
                    this.resourceCosts[i].resourceType.code === selectedResourceCost.resourceType.code &&
                    this.resourceCosts[i].profileType &&
                    this.resourceCosts[i].profileType.code === selectedResourceCost.profileType.code &&
                    this.resourceCosts[i].tempSeasonId === selectedResourceCost.tempSeasonId &&
                    this.anythingInCommon(this.resourceCosts[i].dayKeys, selectedResourceCost.dayKeys)) {
                    this.isInvalidCost = true;
                    selectedResourceCost.isInvalid = true;
                    break;
                } else {
                    this.isInvalidCost = false;
                    selectedResourceCost.isInvalid = false;
                }
            }
            this.gridApi.getSelectedRows()[0].isInvalid = this.isInvalidCost;
        } else if (selectedResourceCost) {
            this.isInvalidCost = true;
            selectedResourceCost.isInvalid = true;
        } else {
            this.isInvalidCost = true;
        }
        this.gridApi.setRowData(this.resourceCosts);
        // this.dataStore.set(DataKey.supplierGenCostSetupValid, !this.isInvalidCost, true);
        console.log(this.isInvalidCost);
    }

    anythingInCommon(a, b) {
        if (b.length < a.length) {
            return this.anythingInCommon(b, a);
        }

        for (var i = 0, len = a.length; i < len; i++) {
            if (b.indexOf(a[i]) != -1) {
                return true;
            }
        }

        return false;
    }

    getCode(dataList, name) {
        return dataList.filter(val => val.name === name)[0].code;
    }
    public onGridSizeChange() {
        this.fixGridColumnSizes();
    }

    public fixGridColumnSizes() {
        // this.gridApi.sizeColumnsToFit();
    }

    public onRowDoubleClicked(event: any) {
        console.log('Row clicked');
    }

    getResourceTypes = function(params, rowData, text) {
        let filterValue = text ? text.toLowerCase() : '';
        return this.selectedResourceTypes.filter(value => value.name && value.name.toLowerCase().indexOf(filterValue) > -1);
    };
    getProfileTypes = function(params, row, text) {
        let filteredProfileTypes = [];
        let filterValue = text ? text.toLowerCase() : '';
        let selectedResourceType = this.selectedResourceTypes.find(value => row.resourceType && value.code === row.resourceType.code);
        if (selectedResourceType && selectedResourceType.profileTypes) {
            filteredProfileTypes = selectedResourceType.profileTypes.filter(value => value.name && value.name.toLowerCase().indexOf(filterValue) > -1);
        } else {
            filteredProfileTypes = this.selectedProfileTypes.filter(value => value.name && value.name.toLowerCase().indexOf(filterValue) > -1);
        }
        filteredProfileTypes.push(this.defaultProfileType);
        return filteredProfileTypes;
    };
    getCurrencies = function(params, rowData, text) {
        let filterValue = text ? text.toLowerCase() : '';
        return this.currencyList.filter(value => value.name && value.name.toLowerCase().indexOf(filterValue) > -1);
    };
    getCostingTypes = function(params, rowData, text) {
        let filterValue = text ? text.toLowerCase() : '';
        return this.costingTypeList.filter(value => value.name && value.name.toLowerCase().indexOf(filterValue) > -1);
    };
    getSeasons = function(params, row, text) {
        let filterValue = '';
        if (text) {
            filterValue = text.toLowerCase();
        }
        return this.seasons.filter(value => value.name.toLowerCase().indexOf(filterValue) > -1);
    };

    // used in ag grid col def by method reference
    onDeleteCost = function(params: any) {
        this.onRemoveRow(params);
    };

    getResourceTypeName = function(params: any) {
        return params.data.resourceType && params.data.resourceType.name ? params.data.resourceType.name : params.data.resourceType;
    };
    getProfileType = function(params: any) {
        let groupText = EMPTY_CHAR;
        if (params && params.data && params.data.profileType) {
            if (params.data.profileType.name) {
                groupText = params.data.profileType.name;
            } else {
                groupText = params.data.profileType;
            }
        }
        return groupText;
    };
    setProfileType = (params) => {
        if (params && params.data && params.newValue) {
            params.data.profileType = params.newValue;
        }
    };
    getSeasonName = function(params: any) {
        return params.data.seasonName && params.data.seasonName.name ? params.data.seasonName.name : params.data.seasonName;
    };
    setSeasonName = (params) => {
        if (params && params.data && params.newValue) {
            params.data.seasonName = params.newValue.name;
            params.data.tempSeasonId = params.newValue.code;
        }
    };
    getCurrency = function(params: any) {
        return params.data.currency ? params.data.currency : '';
    };
    setCurrency = (params) => {
        if (params && params.data && params.newValue) {
            params.data.currency = params.newValue.code;
        }
    };

    getCostingType = (params) => {
        let groupText = EMPTY_CHAR;
        if (params && params.data && params.data.costingType) {
            if (params.data.costingType.name) {
                groupText = params.data.costingType.name;
            } else {
                groupText = params.data.costingType;
            }
        }
        return groupText;
    };
    setCostingType = (params) => {
        if (params && params.data && params.newValue) {
            params.data.costingType = params.newValue;
        }
    };

    onRemoveRow(params) {
        if (!params.data.isInvalid) {
            let confirmDataObject = new DialogModel(
                true,
                LEVEL.WARNING,
                'Delete',
                'This Cost will be deleted. Do you wish to proceed?',
                true,
                3000
            );
            this.dialogService.confirm(confirmDataObject).subscribe(
                (res) => {
                    if (res === true) {
                        this.removeSelectedData(params);
                    }
                });
        } else {
            this.removeSelectedData(params);
        }
        /*this.gridApi ?
         this.gridApi.setRowData(this.resourceCosts) :
         params.api.setRowData(this.resourceCosts);*/
    }

    removeSelectedData(params: any) {
        for (let i = 0; i < this.resourceCosts.length; i++) {
            if (this.resourceCosts[i].tempCostId === params.data.tempCostId) {
                this.resourceCosts.splice(i, 1);
                break;
            }
        }
        this.gridApi.setRowData(this.resourceCosts);
    }

    checkBoxOnClick = function(params) {
        this.onCellValueChange(params);
        console.log(params.value);
    };

    CurrencyCellRenderer = function(params: any) {
        params.data.currency = params.data.currency ? params.data.currency : 'AED';
        var inrFormat = new Intl.NumberFormat('en-Us', {
            style: 'currency',
            currency: params.data.currency.code ? params.data.currency.code : params.data.currency,
            minimumFractionDigits: 2
        });
        return inrFormat.format(params.value).replace(/[a-z]{3}/i, '').trim();
    };

    displaySeasonName = (params: any) => {
        return params ? params.seasonName : '';
    };

    isTypeCostEditable = function(params) {
        return (params.node.data.costingType && params.node.data.costingType.code !== 'PP');
    };

    isPerPersonCost = function(params) {
        return (params.node.data.costingType && params.node.data.costingType.code === 'PP');
    };


    private mapDayKeys(resourceCost: GenResourceCostSetup) {
        let dayValues = resourceCost.dayArray;
        let days = [];
        dayValues.forEach(day => {
            days.push(day.day ? day.day : day.value);
        });
        let dayKeys = '';
        this.daysOfWeek.forEach(data => {
            if (days.includes(data.value)) {dayKeys += data.key;}
        });
        resourceCost.dayKeys = dayKeys;
    }


    /// Document generation
    exportCostData() {
        this.spinnerService.show();
        const documentCreationCriteria = new DocumentCreationCriteria();
        documentCreationCriteria.documentFormat = DocumentFormat.csv;
        documentCreationCriteria.documentType = DocumentType.SUPPLIER_GEN_COST_SHEET;
        documentCreationCriteria.documentName = '';
        documentCreationCriteria.download = true;
        documentCreationCriteria.persist = true;

        const documentDataCriteria = new DocumentDataCriteria();
        documentDataCriteria.supplierId = this.supplierId;
        this.documentService.createAndDownloadDocument(documentCreationCriteria, documentDataCriteria);
    }

    private importCostData() {
        this.dataStore.set(DataKey.popupClose, null);
        this.dataStore.set(DataKey.fileToUpload, null);
        const dataObject = new ModalData(
            LEVEL.SUCCESS,
            'Upload Cost Sheet',
            true,
            true,
            'split-passengers-modal',
            FileUploaderComponent,
            {}
        );
        dataObject.disableClose = true;
        this.confirmModal(dataObject);

        const x = this.dataStore.get(DataKey.popupClose).subscribe((data) => {
            if (data != null) {
                this.closeModal();
            }
        });
        this.genericCostSheetImportSubscribe = this.dataStore.get(DataKey.fileToUpload).subscribe((data) => {
            if (data != null) {
                this.uploadFile(data);
                this.genericCostSheetImportSubscribe.unsubscribe();
            }
        });
    }

    uploadFile(fileToUpload: any){
        let selectedResourceTypes: string = '';
        this.selectedResourceTypes.forEach( (o,i) => {
            selectedResourceTypes = selectedResourceTypes + o.code;
            if ( i != this.selectedResourceTypes.length-1){
                selectedResourceTypes = selectedResourceTypes + ',';
            }
        })
        this.genCostSheetUploadSubscribe = this.supplierService.uploadCostSheet(fileToUpload, this.templateName, this.supplierId, selectedResourceTypes).subscribe(data => {
            if(data && data.body) {
                if(this.gridApi) {
                    this.gridApi.showLoadingOverlay();
                    this.getSupplierById();
                }
                this.common.showSnackBar(
                    'Successfully uploaded',
                    3000,
                    TcErrorType.TYPE.INFO
                );
            }
        }, error => {
            if (error['error']['status']['description'].substring(0,4) == "File")
            {
                this.common.showSnackBar(
                    'Fail to upload: ' + error['error']['status']['description'],
                    6000,
                    TcErrorType.TYPE.ERROR
                );
            }
            else {
                this.common.showSnackBar(
                    'Fail to upload',
                    3000,
                    TcErrorType.TYPE.ERROR
                );
            }
        });
    }
    private confirmModal(data: any): void {
        this.modalService.confirm(data).subscribe((res) => {
        });
    }

    closeModal() {
        if (this.modalService.close()) {
            this.modalService.close().subscribe(
                (res) => {
                }
            );
        }
    }

    private getSupplierById() {
        this.supplierService.retrieveSupplierById(this.supplierId);
        this.subscribeSupplierData();
    }

    private subscribeSupplierData() {
        this.supplierDataSubscribe = this.dataStore.get(DataKey.supplier).subscribe((result) => {
            if (result != null) {
                this.resourceCosts = [];
                let supplier: Supplier = ResponseUtil.getFirstData(result);
                this.resourceCostData = supplier.seasons;
                this.getResourceCostData();
                this.supplierDataSubscribe.unsubscribe();
            }
        });
    }
    getResourceCostData() {
        if (this.resourceCostData.length > 0) {
            this.addDefaultProfileType(this.selectedProfileTypes);
            // setting temp ids to seasons
            this.setTempIdsToSeasons();

            let tempCostId = 0;
            for (let i = 0; i < this.resourceCostData.length; i++) {
                if (this.resourceCostData[i].genResourceCosts.length > 0) {
                    for (let j = 0; j < this.resourceCostData[i].genResourceCosts.length; j++) {
                        let days = this.getDays(this.resourceCostData[i].genResourceCosts[j].days);
                        let daysArr = this.getDaysArr(this.resourceCostData[i].genResourceCosts[j].days);
                        tempCostId++;
                        this.resourceCostData[i].genResourceCosts[j].tempCostId = tempCostId;
                        let resourceCost = new GenResourceCostSetup();
                        resourceCost.tempCostId = tempCostId;
                        // resourceCost.resourceType = this.selectedResourceTypes.filter(value => value && value.code === this.resourceCostData[i].genResourceCosts[j].resourceType)[0];
                        resourceCost.resourceType = this.filterObject(
                            this.resourceCostData[i].genResourceCosts[j].resourceType,
                            this.selectedResourceTypes.length > 0 ? this.selectedResourceTypes : this.resourceTypes
                        );
                        // resourceCost.profileType = this.selectedProfileTypes.filter(value => value && value.code === this.resourceCostData[i].genResourceCosts[j].profileType)[0];
                        resourceCost.profileType = this.filterObject(
                            this.resourceCostData[i].genResourceCosts[j].profileType,
                            this.selectedProfileTypes
                        );
                        resourceCost.seasonName = this.resourceCostData[i].name;
                        resourceCost.tempSeasonId = this.resourceCostData[i].tempSeasonId;
                        resourceCost.currency = this.resourceCostData[i].genResourceCosts[j].currency;
                        resourceCost.costingType = this.filterObject(
                            this.resourceCostData[i].genResourceCosts[j].costingType, this.costingTypeList);
                        resourceCost.unitFare = this.resourceCostData[i].genResourceCosts[j].unitFare;
                        resourceCost.adultFare = this.resourceCostData[i].genResourceCosts[j].adultFare;
                        resourceCost.teenFare = this.resourceCostData[i].genResourceCosts[j].teenFare;
                        resourceCost.childFare = this.resourceCostData[i].genResourceCosts[j].childFare;
                        resourceCost.infantFare = this.resourceCostData[i].genResourceCosts[j].infantFare;
                        resourceCost.dayValues = days;
                        resourceCost.dayArray = daysArr;
                        resourceCost.dayKeys = this.resourceCostData[i].genResourceCosts[j].days;
                        resourceCost.isEdit = false;
                        this.resourceCosts.push(resourceCost);
                        // this.resourceCosts.splice(0,0,resourceCost);
                    }
                }
            }

            this.gridApi.refreshCells();
            this.gridApi.hideOverlay();
        }
    }
    private addDefaultProfileType(typeList: any[]) {
        return !typeList.find(value => value.code === this.defaultProfileType.code) ? typeList.push(this.defaultProfileType) : typeList;
    }

    // this.dataStore.get(DataKey.resourceTypes);
    filterObject(code: string, objArray: any[]) {
        if (objArray && objArray.length > 0) {
            for (let i = 0; i < objArray.length; i++) {
                if (objArray[i]['code'] === code) {
                    return objArray[i];
                }
            }
            return null;
        } else {
            return code;
        }
    }

    private setTempIdsToSeasons() {
        let initId = 1;
        for (let i = 0; i < this.resourceCostData.length; i++) {
            let season: Season = this.resourceCostData[i];
            season.tempSeasonId = initId++;
        }
    }

    getDays(data: string) {
        let days = '';
        for (let i = 0; i < data.length; i++) {
            let dayArray = this.daysOfWeek.find(day => day.key === data.charAt(i)).value;
            days = days.concat(',' + dayArray);
        }
        if (days.startsWith(',')) {days = days.replace(',', '');}
        return days;
    }

    getDaysArr(data: string) {
        let daysArr = [];
        for (let i = 0; i < data.length; i++) {
            let dayArray = this.daysOfWeek.find(day => day.key === data.charAt(i)).value;
            daysArr.push({key: data.charAt(i), day: dayArray});
        }
        return daysArr;
    }

}

/*function createNewRowData() {
 return new GenResourceCostSetup();
 }*/
