import * as tslib_1 from "tslib";
import { SupplierAllocationSearchCriteria } from './supplier-allocation-search-criteria';
var ResourceAllocationSearchCriteria = /** @class */ (function (_super) {
    tslib_1.__extends(ResourceAllocationSearchCriteria, _super);
    function ResourceAllocationSearchCriteria() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return ResourceAllocationSearchCriteria;
}(SupplierAllocationSearchCriteria));
export { ResourceAllocationSearchCriteria };
