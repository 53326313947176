<div class="">
    <mat-tab-group [selectedIndex]="selectedIndex">
        <mat-tab label="{{'User Defined' | translate}}">
            <div class="tc-modal-content">
                <div class="container-fluid">
                    <!-- header -->
                    <div class="container-fluid">
                        <div class="item-header margin-expanded-area">
                            <div class="heading item-header__content">{{'Criteria Name' | translate}}</div>
                            <i></i>
                        </div>
                    </div>
                    <!-- body -->
                    <ng-container
                            *ngFor="let userData of userDefinedCriteriaList | tcAdvPager:pager.currPage:pager.itemsPerPage">
                        <div class="tc-item--center split-passengers-results__item cursor-pointer item-hover"
                             [ngClass]="{'selected-card': selectedCriteria?.criteriaId === userData.criteriaId }">
                            <div class="container-fluid" (click)="setCriteria(userData)">
                                <div class="heading item-header__content pull-left">
                                    <span>{{userData.criteriaName}}</span> #
                                    <small>{{userData.id}}</small>
                                </div>
                                <div class="tc-icon-block pull-right">
                                    <span class="tc-icon-block__icon tc-icon-block__icon--active">
                                      <i
                                              class="material-icons"
                                              (click)="onDelete(userData.id)">delete</i>
                                    </span>
                                </div>
                            </div>

                        </div>
                    </ng-container>
                </div>
            </div>
            <tc-paginator
                    *ngIf="userDefinedCriteriaList.length > defaultPageResultCount"
                    [length]="userDefinedCriteriaList.length"
                    [index]="pager.currPage"
                    [size]="pager.itemsPerPage"
                    [hasMore]="false"
                    (page)="onPage($event)">
            </tc-paginator>
        </mat-tab>
<!--        <mat-tab label="{{'Quick Links' | translate}}">-->
<!--            <div class="tc-modal-content">-->
<!--                <div class="container-fluid">-->
<!--                    &lt;!&ndash; header &ndash;&gt;-->
<!--                    <div class="container-fluid ">-->
<!--                        <div class="item-header margin-expanded-area">-->
<!--                            <div class="heading item-header__content">{{'Criteria Name' | translate}}</div>-->
<!--                            <i></i>-->
<!--                        </div>-->
<!--                    </div>-->

<!--                    &lt;!&ndash; body &ndash;&gt;-->
<!--                    <div *ngFor="let systemData of systemDefinedCriteriaList">-->
<!--                        <div-->
<!--                                [ngClass]="{'selected-card': selectedCriteria?.criteriaId === systemData.criteriaId }"-->
<!--                                class="tc-item&#45;&#45;center split-passengers-results__item cursor-pointer item-hover">-->
<!--                            <div class="container-fluid" (click)="setCriteria(systemData)">-->
<!--                                <div class="heading item-header__content pull-left">-->
<!--                                    <span>{{systemData.criteriaName}}</span> #-->
<!--                                    <small>{{systemData.id}}</small>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </mat-tab>-->
    </mat-tab-group>
</div>
<div class="container assign-panel-footer-section">
    <button class="assign-panel-footer-section__button tc-button-secondary mat-button" (click)="onClose($event)">
        {{'Close' | translate}}
    </button>
    <button
            class="assign-panel-footer-section__button tc-button-primary mat-button"
            (click)="onSearch()"
            [disabled]="!selectedCriteria">
        {{'Search' | translate}}
    </button>
</div>
