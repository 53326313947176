
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ObjKeysPipePipe } from './obj-keys-pipe.pipe';
import { TruncatePipe } from './truncate.pipe';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        ObjKeysPipePipe,
        TruncatePipe
    ],
    exports: [
        ObjKeysPipePipe,
        TruncatePipe
    ]
})
export class ObjKeysPipePipeModule {}
