import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from '@tc/pipes';
import { ServiceItemFocusViewFlightInfoComponent } from './service-item-focus-view-flight-info.component';

@NgModule({
  imports: [
    CommonModule,
      PipesModule,
      TranslateModule.forChild()
  ],
  declarations: [ServiceItemFocusViewFlightInfoComponent],
    exports: [ServiceItemFocusViewFlightInfoComponent]
})
export class ServiceItemFocusViewFlightInfoModule { }
