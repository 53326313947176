import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DialogModel, LEVEL } from '@tc-core/model/it/codegen/ui/framework/dialog-model';
import { PageSection } from '@tc-core/model/it/codegen/ui/framework/page-section';
import { DialogService } from '@tc/dialog/dialog.service';
import { ICellRendererParams } from 'ag-grid-community';
import { catchError, map } from 'rxjs/operators';
import { AutoCompleteEditorComponent } from '../../../../widgets/framework/ag-grid-custom-components/editors/auto-complete-editor/auto-complete-editor.component';
import { RoomOccupancySearchCriteria } from '../../../../models/criteria/room-occupancy-search-criteria';
import { ResourceGroup } from '../../../../models/supplier/collection/resource-group';
import { RoomOccupancy } from '../../../../models/supplier/room-occupancy';
import { SupplierService } from '../../../../services/backend-consumers/supplier-service/supplier.service';
import { DataKey, DataStoreService } from '../../../../services/util/framework/data-store.service';
import { ResponseUtil } from '../../../../services/util/response/response-util.service';
import { CardDataHandlerService } from '../../../../services/util/ui/card-data-handler-service.service';

@Component({
    selector: 'tc-occupancy-setup',
    templateUrl: './occupancy-setup.component.html'
})
export class OccupancySetupComponent implements OnInit {
    @Input() public roomGroup: ResourceGroup;

    public columnDefs: any = [];
    public defaultColDef: any = {
        headerValueGetter: (parameters: ICellRendererParams): string => {
            const headerIdentifier = parameters.colDef.headerName;
            if (headerIdentifier) {
                return this.translateService.instant(headerIdentifier);
            }
            return '';
        }
    };
    public rowClassRules: any;
    public occupancies: RoomOccupancy[];

    private gridApi;
    private gridColumnApi;

    private pageSectionsMap: Map<string, PageSection>;
    public occupancyHeaderSection: PageSection;
    private CARD_CONFIG_GENERIC_EDIT = 'supplier_edit';

    frameworkComponents = {
        autoComplete: AutoCompleteEditorComponent
    };

    constructor(
        private dataStore: DataStoreService,
        private cardDataHandlerService: CardDataHandlerService,
        private supplierService: SupplierService,
        private dialogService: DialogService,
        private translateService: TranslateService
    ) {
        this.translateService.onLangChange.subscribe(() => {
            this.gridApi.refreshHeader();
        });
    }

    ngOnInit() {
        if (!(this.roomGroup && this.roomGroup.roomGroup && this.roomGroup.roomGroup.roomOccupancies)) {
            this.roomGroup.roomGroup.roomOccupancies = [];
        }
        this.occupancies = JSON.parse(JSON.stringify(this.roomGroup.roomGroup.roomOccupancies));
        this.initPageSectionReferences();
        this.setColDefs();
    }

    private initPageSectionReferences() {
        this.pageSectionsMap = this.cardDataHandlerService.getAllPageSections(
            this.CARD_CONFIG_GENERIC_EDIT,
            []
        );

        this.occupancyHeaderSection = this.pageSectionsMap.get('occupancy_setup');

    }

    public onGridReady(params: any) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.gridApi.setFloatingFiltersHeight(38);
        this.gridApi.setHeaderHeight(40);
        this.gridApi.sizeColumnsToFit();
    }

    public onSelectionChanged($event: any) {

    }

    public onCellValueChanged($event: any) {

    }

    public validateUniqueValues($event: any) {

    }

    public onHeaderActionClick(event: any) {
        if (event.id === 'TRIGGER_ADD_OCCUPANCY') {
            this.addRow();
        } else if (event.id === 'TRIGGER_DELETE_OCCUPANCY') {
            this.deleteSelected();
        } else if (event.id === 'TRIGGER_GEN_OCCUPANCY_1A') {
            this.generateWithAtLeastOneAdult();
        } else if (event.id === 'TRIGGER_GEN_OCCUPANCY_2A') {
            this.generateWithAtLeastTwoAdult();
        } else if (event.id === 'TRIGGER_GEN_OCCUPANCY_3A') {
            this.generateWithAtLeastThreeAdult();
        }
    }

    private addRow() {
        const roomOccupancy = new RoomOccupancy();
        roomOccupancy.adults = 0;
        roomOccupancy.teens = 0;
        roomOccupancy.children = 0;
        roomOccupancy.infants = 0;
        roomOccupancy.total = 0;
        roomOccupancy.occupancyId = 0;
        const gridData = this.getGridData(false);
        gridData.push(roomOccupancy);
        this.gridApi.setRowData(gridData);
    }

    public getRandomInt(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    private deleteSelected() {
        const selectedRows = this.gridApi.getSelectedRows();
        const gridData = this.getGridData(false);
        for (const selectedRow of selectedRows) {
            gridData.splice(gridData.indexOf(selectedRow), 1);
        }
        this.gridApi.setRowData(gridData);
    }

    private generateWithAtLeastOneAdult() {
        if (this.roomGroup.roomGroup.maximumOccupancy > 0 && this.roomGroup.roomGroup.minimumOccupancy > 0) {
            this.searchGeneratedOccupanciesAndFill(1);
        } else {
            const invalidAttemptWarning = new DialogModel(
                true,
                LEVEL.ERROR,
                'Invalid Attempt',
                'Please add valid maximum and minimum occupancies for the room before generating',
                true,
                2000
            );
            const subscriptionInvalid = this.dialogService.confirm(invalidAttemptWarning).subscribe((res) => {
                subscriptionInvalid.unsubscribe();
                return;
            });
        }
    }

    private generateWithAtLeastTwoAdult() {
        if (this.roomGroup.roomGroup.maximumOccupancy > 0 && this.roomGroup.roomGroup.minimumOccupancy > 0) {
            if (this.roomGroup.roomGroup.maximumOccupancy < 2) {
                const invalidAttemptWarning = new DialogModel(
                    true,
                    LEVEL.ERROR,
                    'Invalid Attempt',
                    'Maximum occupancy is lower to generate with 2 adults',
                    true,
                    2000
                );
                const subscriptionInvalid = this.dialogService.confirm(invalidAttemptWarning).subscribe((res) => {
                    subscriptionInvalid.unsubscribe();
                    return;
                });
            } else {
                this.searchGeneratedOccupanciesAndFill(2);
            }
        } else {
            const invalidAttemptWarning = new DialogModel(
                true,
                LEVEL.ERROR,
                'Invalid Attempt',
                'Please add valid maximum and minimum occupancies for the room before generating',
                true,
                2000
            );
            const subscriptionInvalid = this.dialogService.confirm(invalidAttemptWarning).subscribe((res) => {
                subscriptionInvalid.unsubscribe();
                return;
            });
        }
    }

    private generateWithAtLeastThreeAdult() {
        if (this.roomGroup.roomGroup.maximumOccupancy > 0 && this.roomGroup.roomGroup.minimumOccupancy > 0) {
            if (this.roomGroup.roomGroup.maximumOccupancy < 3) {
                const invalidAttemptWarning = new DialogModel(
                    true,
                    LEVEL.ERROR,
                    'Invalid Attempt',
                    'Maximum occupancy is lower to generate with 3 adults',
                    true,
                    2000
                );
                const subscriptionInvalid = this.dialogService.confirm(invalidAttemptWarning).subscribe((res) => {
                    subscriptionInvalid.unsubscribe();
                    return;
                });
            } else {
                this.searchGeneratedOccupanciesAndFill(3);
            }
        } else {
            const invalidAttemptWarning = new DialogModel(
                true,
                LEVEL.ERROR,
                'Invalid Attempt',
                'Please add valid maximum and minimum occupancies for the room before generating',
                true,
                2000
            );
            const subscriptionInvalid = this.dialogService.confirm(invalidAttemptWarning).subscribe((res) => {
                subscriptionInvalid.unsubscribe();
                return;
            });
        }
    }

    private searchGeneratedOccupanciesAndFill(minAdults: number) {
        const occupancySearchCriteria = new RoomOccupancySearchCriteria();
        occupancySearchCriteria.maximumOccupancy = this.roomGroup.roomGroup.maximumOccupancy;
        occupancySearchCriteria.minimumOccupancy = this.roomGroup.roomGroup.minimumOccupancy;
        occupancySearchCriteria.minAdultsRequired = minAdults;
        occupancySearchCriteria.size = 100000;
        this.supplierService.searchOccupancies(occupancySearchCriteria).subscribe(
            value => {
                if (value) {
                    const data: RoomOccupancy[] = value.data;
                    this.fillOccupanciesWithGeneratedList(data);
                }
            }
        );
    }

    private fillOccupanciesWithGeneratedList(generated: RoomOccupancy[]) {
        const gridData = this.getGridData(false);
        for (const g of generated) {
            const roomOccupancyFound = gridData.find(value => {
                return value.occupancyId === g.occupancyId;
            });
            if (!roomOccupancyFound) {
                gridData.unshift(g);
            }
        }
        this.gridApi.setRowData(gridData);

    }

    private setColDefs() {
        this.columnDefs = [
            {
                headerName: 'Occupancy',
                field: 'occupancyName',
                editable: true,
                sortable: true,
                resizable: true,
                minWidth: 300,
                flex: 3,
                cellEditorFramework: AutoCompleteEditorComponent,
                cellEditorParams: {
                    viewKey: 'occupancyName',
                    returnObject: true,
                    filterItemAsyncFn: (params, rowData, text) => {
                        const roomOccupancySearchCriteria = new RoomOccupancySearchCriteria();
                        roomOccupancySearchCriteria.name = text;
                        roomOccupancySearchCriteria.minimumOccupancy = this.roomGroup.roomGroup.minimumOccupancy;
                        roomOccupancySearchCriteria.maximumOccupancy = this.roomGroup.roomGroup.maximumOccupancy;
                        return this.supplierService.searchOccupancies(roomOccupancySearchCriteria)
                                   .pipe(
                                       map(
                                           response => {
                                               return ResponseUtil.getDataArray(response);
                                           }
                                       ), catchError(
                                           error => {
                                               return [];
                                           }
                                       )
                                   );
                    }
                },
                valueSetter: (params) => {
                    const data: RoomOccupancy = params.data;
                    const occupancy: RoomOccupancy = params.newValue;
                    params.node.setData(occupancy);
                    return true;
                },
                valueFormatter: (params) => {
                    if (params.value) {
                        return params.value.name;
                    }
                    return '';
                }
            },
            {
                headerName: 'Adult',
                field: 'adults',
                editable: true,
                sortable: true,
                resizable: true,
                flex: 1,
                valueSetter: (params) => {
                    const data: RoomOccupancy = params.data;
                    if (!isNaN(params.newValue)) {
                        data.adults = params.newValue;
                        this.searchAndSetOccupancy(data, params);
                    }
                }
            },
            {
                headerName: 'Teen',
                field: 'teens',
                editable: true,
                sortable: true,
                resizable: true,
                flex: 1,
                valueSetter: (params) => {
                    const data: RoomOccupancy = params.data;
                    if (!isNaN(params.newValue)) {
                        data.teens = params.newValue;
                        this.searchAndSetOccupancy(data, params);
                    }
                }
            },
            {
                headerName: 'Child',
                field: 'children',
                editable: true,
                sortable: true,
                resizable: true,
                flex: 1,
                valueSetter: (params) => {
                    const data: RoomOccupancy = params.data;
                    if (!isNaN(params.newValue)) {
                        data.children = params.newValue;
                        this.searchAndSetOccupancy(data, params);
                    }
                }
            },
            {
                headerName: 'Infant',
                field: 'infants',
                editable: true,
                sortable: true,
                resizable: true,
                flex: 1,
                valueSetter: (params) => {
                    const data: RoomOccupancy = params.data;
                    if (!isNaN(params.newValue)) {
                        data.infants = params.newValue;
                        this.searchAndSetOccupancy(data, params);
                    }
                }
            }
        ];
    }

    public searchAndSetOccupancy(occupancyData: RoomOccupancy, params) {
        const roomOccupancySearchCriteria = new RoomOccupancySearchCriteria();
        roomOccupancySearchCriteria.minimumOccupancy = this.roomGroup.roomGroup.minimumOccupancy;
        roomOccupancySearchCriteria.maximumOccupancy = this.roomGroup.roomGroup.maximumOccupancy;
        roomOccupancySearchCriteria.adults = occupancyData.adults;
        roomOccupancySearchCriteria.teens = occupancyData.teens;
        roomOccupancySearchCriteria.children = occupancyData.children;
        roomOccupancySearchCriteria.infants = occupancyData.infants;
        return this.supplierService.searchOccupancies(roomOccupancySearchCriteria).subscribe(
            response => {
                const dataArray: RoomOccupancy[] = ResponseUtil.getDataArray(response);
                if (dataArray && dataArray.length) {
                    params.node.setData(dataArray[0]);
                }
            }
        );
    }

    public isSaveButtonEnable() {
        return true;
    }

    public getGridData(onlyValid: boolean) {
        const validOccupancies = [];
        this.gridApi.forEachNode(node => {
            if (!onlyValid || node.data.occupancyId) {
                validOccupancies.push(node.data);
            }
        });
        return validOccupancies;
    }

    public onSave() {
        this.roomGroup.roomGroup.roomOccupancies = this.getGridData(true);
        this.dataStore.set(DataKey.popupClose, true);
    }

    public onClose($event: MouseEvent) {
        this.dataStore.set(DataKey.popupClose, false);
    }
}
