import { Component, Input, OnInit } from '@angular/core';
import { TC } from '@tc-core/util/constants';
import { TCO } from '../../../constants';
import { Resource } from '../../../models/resource/resource';
import { DriverAllocationSummary } from '../../../models/summary/driver-allocation-summary';
import { GenericResourceSummary } from '../../../models/summary/generic-resource-summary';
import { ServiceItem } from '../../../models/reservation/service-item';
import { SupplierSummaryDepricated } from '../../../models/summary/supplier-summary-depricated';
import { VehicleSummary } from '../../../models/summary/vehicle-summary';

@Component({
    selector: 'tc-service-item-focus-view-summary',
    templateUrl: './service-item-focus-view-summary.component.html'
})
export class ServiceItemFocusViewSummaryComponent implements OnInit {

    @Input() summaryInfo: any;
    @Input() supplierSummary: SupplierSummaryDepricated;
    @Input() resourceSummary: VehicleSummary;
    @Input() driverSummary: GenericResourceSummary;
    @Input() assignmentStatus: string;
    @Input() historyText: string;

    EMPTY_CHAR = TC.FORMAT.EMPTY_CHAR;
    CURRENCY_TYPE = TCO.AppData.DEF_CURRENCY_TYPE;

    constructor() { }

    ngOnInit() {
        console.log(this.supplierSummary);

    }

}
