import { Component, Input, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { DateRange } from '../../cg-calendar/date-range';
import { RangeGroup } from '../../cg-calendar/range-group';
import { SeasonCalendarService } from '../season-calendar.service';
// import { DateRange } from '@tc/cg-calendar/date-range';
// import { RangeGroup } from '@tc/cg-calendar/range-group';
// import { SeasonCalendarService } from '@tc/cg-season-calendar/season-calendar.service';

@Component({
  selector: 'tc-season-range-edit-card',
  templateUrl: './season-range-edit-card.component.html'
})
export class SeasonRangeEditCardComponent implements OnInit {

  @Input() rangeGroups: RangeGroup[] = [];
  @Input() rangeGroup: RangeGroup = null;
  @Input() mergeNearDateRanges: boolean = true;

  newDateRange: DateRange = new DateRange();

  constructor(
    private dialogRef: MatDialogRef<SeasonRangeEditCardComponent>,
    private seasonCalendarService: SeasonCalendarService
  ) { }

  ngOnInit() {
  }

  public onClose($event: MouseEvent) {
    this.dialogRef.close();
  }

  public onSave($event: MouseEvent) {
    // this.rangeGroup.dateRanges.push(this.newDateRange);
    this.seasonCalendarService.addNewDateRange(this.rangeGroups, this.newDateRange, this.mergeNearDateRanges);
    this.dialogRef.close({rangeGroups: this.rangeGroup});
  }

  public isEnableSaveButton(): boolean {
    let enable = false;
    if (this.newDateRange.startDate && this.newDateRange.endDate) {
      enable = true;
    }
    return enable;
  }
}
