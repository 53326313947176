import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { TcErrorType } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-error';
import { TC } from '@tc-core/util';
import { ConfigLoader } from '@tc-core/util/framework';
import { ModalService } from '@tc/modal/modal.service';
import { of, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SetupGridComp } from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid-comp';
import { SetupGridComponent } from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid.component';
import { TCO } from '../../../constants';
import { MappedResourceTypeSearchCriteria } from '../../../models/criteria/mapped-resource-type-search-criteria';
import { ResourceTypeSearchCriteria } from '../../../models/criteria/resource-type-search-criteria';
import { SortDirection } from '../../../models/helper/sort-direction';
import { DataHandlerService } from '../../../services/backend-consumers/master-data-handler-service/data-handler.service';
import { ResourceTypeService } from '../../../services/backend-consumers/setups/resource-type.service';
import { DMCCommon } from '../../../services/util/common/dmc-common';
import { DataKey, DataStoreService } from '../../../services/util/framework/data-store.service';
var EMPTY_CHAR = TC.FORMAT.EMPTY_CHAR;
var MappedResourceTypesGridSetupComponent = /** @class */ (function (_super) {
    tslib_1.__extends(MappedResourceTypesGridSetupComponent, _super);
    function MappedResourceTypesGridSetupComponent(configLoader, dataStoreService, dataHandlerService, modalService, resourceTypeService, common) {
        var _this = _super.call(this) || this;
        _this.configLoader = configLoader;
        _this.dataStoreService = dataStoreService;
        _this.dataHandlerService = dataHandlerService;
        _this.modalService = modalService;
        _this.resourceTypeService = resourceTypeService;
        _this.common = common;
        _this.colDefConfig = [];
        _this.gridActions = [];
        _this.mappedResourceTypeSearchCriteria = new MappedResourceTypeSearchCriteria();
        _this.allMappedResourceTypeCodes = [];
        // required options for grid editors
        // nothing
        _this.searchCriteriaObserver = new Subscription();
        /*
         grid config methods which are called by grid setup (ag grid)
         and set by grid config
         */
        _this.getResourceType = function (params) {
            var typeText = EMPTY_CHAR;
            if (params && params.data) {
                if (params.data.resourceTypeName) {
                    typeText = params.data.resourceTypeName;
                }
                if (params.data.resourceTypeName && params.data.resourceTypeCode) {
                    typeText += ' | ';
                    typeText += params.data.resourceTypeCode;
                }
            }
            return typeText;
        };
        _this.getResourceTypes = function (params, rowData, text) {
            return _this.dataStoreService.get(DataKey.resourceTypes)
                .map(function (aEvents) {
                return aEvents.filter(function (aEvent) {
                    if (text) {
                        if (aEvent.name) {
                            return aEvent.name.toLowerCase().indexOf(text.toLocaleString()) > -1;
                        }
                    }
                    else {
                        return true;
                    }
                });
            });
        };
        _this.setResourceType = function (params) {
            if (params && params.data && params.newValue) {
                if (params.oldValue === '--' && _this.allMappedResourceTypeCodes.includes(params.data.mappedTypeCode)) {
                    _this.common.showSnackBar('Resource Mapping Code Already Exist. Please Select a Different Code', 3000, TcErrorType.TYPE.ERROR);
                }
                else {
                    params.data.resourceTypeCode = params.newValue.code;
                    params.data.resourceTypeName = params.newValue.name;
                }
            }
        };
        return _this;
    }
    MappedResourceTypesGridSetupComponent.prototype.ngOnInit = function () {
        this.colDefConfig = this.configLoader.configurations.get(TCO.CONF.CONF_MAPPED_RESOURCE_TYPE_SETUP);
        this.addGridActions();
        this.subscribeSearchCriteria();
        this.fillRequiredGridOptionData();
        this.fillDataKeyForEditorData();
        this.getMappedTypeCodes();
    };
    MappedResourceTypesGridSetupComponent.prototype.addGridActions = function () {
    };
    /**
     * subscribe search criteria changes in search bar
     * then force grid to refresh data
     * grid refresh will be call getRaws method which is using this subscribed criteria for backend call
     */
    MappedResourceTypesGridSetupComponent.prototype.subscribeSearchCriteria = function () {
        var _this = this;
        this.searchCriteriaObserver = this.dataStoreService.get(DataKey.mappedResourceTypeSearchCriteria)
            .subscribe(function (value) {
            _this.mappedResourceTypeSearchCriteria = value;
            _this.setupGrid.runForceSearch();
        });
    };
    MappedResourceTypesGridSetupComponent.prototype.fillRequiredGridOptionData = function () {
        // nothing
    };
    MappedResourceTypesGridSetupComponent.prototype.getMappedTypeCodes = function () {
        var _this = this;
        this.resourceTypeService.getMappedResourceTypeCodes().subscribe(function (response) {
            _this.allMappedResourceTypeCodes = response.data;
        });
    };
    MappedResourceTypesGridSetupComponent.prototype.fillDataKeyForEditorData = function () {
        this.addResourceTypes();
    };
    MappedResourceTypesGridSetupComponent.prototype.addResourceTypes = function () {
        var _this = this;
        var resourceTypeSearchCriteria = new ResourceTypeSearchCriteria();
        resourceTypeSearchCriteria.size = 1000000;
        this.resourceTypeService.getResourceTypes(resourceTypeSearchCriteria).subscribe(function (result) {
            _this.dataStoreService.set(DataKey.resourceTypes, result.data, true);
        });
    };
    MappedResourceTypesGridSetupComponent.prototype.isInvalidRow = function (params) {
        var data = params.data;
        if (data) {
            var invalid = true;
            if (data.mappedTypeCode &&
                data.mappedTypeName &&
                data.resourceTypeCode) {
                invalid = false;
            }
            return invalid;
        }
        else {
            return false;
        }
    };
    MappedResourceTypesGridSetupComponent.prototype.createNewRow = function () {
        return { unsaved: true };
    };
    MappedResourceTypesGridSetupComponent.prototype.isUnsavedRow = function (row) {
        return !!row.unsaved;
    };
    MappedResourceTypesGridSetupComponent.prototype.deleteRow = function (row) {
        if (row && row.mappedTypeCode) {
            return this.resourceTypeService.deleteMappedResourceType(row.mappedTypeCode);
        }
        else {
            return of('success');
        }
    };
    MappedResourceTypesGridSetupComponent.prototype.saveRow = function (row, event) {
        return this.resourceTypeService.saveMappedResourceType(row)
            .pipe(tap(function (data) { return row.unsaved = undefined; }));
    };
    MappedResourceTypesGridSetupComponent.prototype.getRows = function (params) {
        var _this = this;
        var pageSize = params.endRow - params.startRow;
        if (!this.mappedResourceTypeSearchCriteria) {
            this.mappedResourceTypeSearchCriteria = new MappedResourceTypeSearchCriteria();
        }
        this.mappedResourceTypeSearchCriteria.start = params.startRow;
        this.mappedResourceTypeSearchCriteria.size = pageSize;
        if (params.sortModel && params.sortModel[0]) {
            this.mappedResourceTypeSearchCriteria.sortBy = params.sortModel[0].colId;
            if (params.sortModel[0].sort === SetupGridComp.GRID_SORT_ASCENDING) {
                this.mappedResourceTypeSearchCriteria.sortDirection = SortDirection.ASC;
            }
            else {
                this.mappedResourceTypeSearchCriteria.sortDirection = SortDirection.DESC;
            }
        }
        else {
            this.mappedResourceTypeSearchCriteria.sortBy = 'createdOn';
            this.mappedResourceTypeSearchCriteria.sortDirection = SortDirection.ASC;
        }
        return this.resourceTypeService.getMappedResourceTypes(this.mappedResourceTypeSearchCriteria)
            .pipe(tap(function (data) {
            return _this.dataStoreService.set(DataKey.mappedReourceTypeSearchResultsForCriteria, data);
        }));
    };
    MappedResourceTypesGridSetupComponent.prototype.ngOnDestroy = function () {
        if (this.searchCriteriaObserver) {
            this.searchCriteriaObserver.unsubscribe();
        }
    };
    return MappedResourceTypesGridSetupComponent;
}(SetupGridComp));
export { MappedResourceTypesGridSetupComponent };
