import * as tslib_1 from "tslib";
import { OnInit, ChangeDetectorRef, SimpleChanges, OnChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GridColumnDefinitionProcessorService } from '../../../../../services/util/pre-processors/grid-column-definition-processor.service';
import { SetupGridComp } from '../setup-grid/setup-grid-comp';
var GroupingSetupGridComponent = /** @class */ (function () {
    function GroupingSetupGridComponent(columnDefinitionProcessorService, cd, translateService) {
        var _this = this;
        this.columnDefinitionProcessorService = columnDefinitionProcessorService;
        this.cd = cd;
        this.translateService = translateService;
        this.rowDataRight = [];
        this.rowSelectionRight = 'multiple';
        this.rowSelectionLeft = 'multiple';
        this.columnDefsLeft = [];
        this.defaultColDefLeft = {
            headerValueGetter: function (parameters) {
                var headerIdentifier = parameters.colDef.headerName;
                if (headerIdentifier) {
                    return _this.translateService.instant(headerIdentifier);
                }
                return '';
            }
        };
        this.gridOptionsLeft = {
            rowSelection: 'multiple',
            pagination: true,
            paginationPageSize: 10
        };
        this.columnDefsRight = [];
        this.defaultColDefRight = {
            headerValueGetter: function (parameters) {
                var headerIdentifier = parameters.colDef.headerName;
                if (headerIdentifier) {
                    return _this.translateService.instant(headerIdentifier);
                }
                return '';
            }
        };
        this.gridOptionsRight = {
            rowSelection: 'multiple',
            pagination: true,
            paginationPageSize: 10
        };
        this.autoSize = false;
        this.translateService.onLangChange.subscribe(function () {
            _this.gridApiRight.refreshHeader();
            _this.gridApiLeft.refreshHeader();
        });
    }
    Object.defineProperty(GroupingSetupGridComponent.prototype, "setupGridComp", {
        set: function (setupGridComp) {
            this._setupGridComp = setupGridComp;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GroupingSetupGridComponent.prototype, "coldefConfigLeft", {
        set: function (colDef) {
            this._colDefConfigLeft = colDef;
            this.setColumnDefsLeft(colDef);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GroupingSetupGridComponent.prototype, "coldefConfigRight", {
        set: function (colDef) {
            this._colDefConfigRight = colDef;
            this.setColumnDefsRight(colDef);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GroupingSetupGridComponent.prototype, "rowDataLeft", {
        set: function (rowDataLeft) {
            this._rowDataLeft = rowDataLeft;
            this.setRowDataLeft();
        },
        enumerable: true,
        configurable: true
    });
    GroupingSetupGridComponent.prototype.ngOnInit = function () {
        this.checkMandatoryInputs();
    };
    GroupingSetupGridComponent.prototype.ngOnChanges = function (changes) {
        this.checkMandatoryInputs();
    };
    GroupingSetupGridComponent.prototype.checkMandatoryInputs = function () {
        if (!this._colDefConfigLeft) {
            throw new Error('Attribute \'colDefConfigLeft\' is required');
        }
        if (!this._colDefConfigRight) {
            throw new Error('Attribute \'colDefConfigRight\' is required');
        }
    };
    GroupingSetupGridComponent.prototype.setColumnDefsLeft = function (coldefConfigLeft) {
        var colDefConfig_ = JSON.parse(JSON.stringify(coldefConfigLeft));
        this.columnDefsLeft = this.columnDefinitionProcessorService.createColumnDefs(colDefConfig_, this);
        this.cd.detectChanges();
        // if (this.gridApiLeft) {
        //     this.gridApiLeft.sizeColumnsToFit();
        //
        // }
    };
    GroupingSetupGridComponent.prototype.setGridWidth = function (api) {
        // api.sizeColumnsToFit();
        // if (this.autoSize) {
        //     api.autoSizeAllColumns();
        // }else {
        //     api.sizeColumnsToFit();
        // }
    };
    GroupingSetupGridComponent.prototype.setRowDataLeft = function () {
        if (this.gridApiLeft) {
            this.gridApiLeft.setRowData(this._rowDataLeft);
        }
    };
    GroupingSetupGridComponent.prototype.onLeftDataSet = function () {
        this.selectRawsAlreadyIn();
    };
    GroupingSetupGridComponent.prototype.selectRawsAlreadyIn = function () {
        var _this = this;
        if (this.rowDataRight && this.rowDataRight.length > 0 && this._rowDataLeft) {
            this.gridApiLeft.forEachNode(function (node) {
                if (_this.isContainsInGrid(_this.rowDataRight, node.data)) {
                    node.setSelected(true);
                }
            });
        }
    };
    GroupingSetupGridComponent.prototype.isContainsInGrid = function (datalist, data) {
        var e_1, _a;
        var contains = false;
        try {
            for (var datalist_1 = tslib_1.__values(datalist), datalist_1_1 = datalist_1.next(); !datalist_1_1.done; datalist_1_1 = datalist_1.next()) {
                var datalistElement = datalist_1_1.value;
                if (this.identityFunction(datalistElement) === this.identityFunction(data)) {
                    contains = true;
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (datalist_1_1 && !datalist_1_1.done && (_a = datalist_1.return)) _a.call(datalist_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return contains;
    };
    GroupingSetupGridComponent.prototype.setColumnDefsRight = function (coldefConfigRight) {
        var colDefConfig_ = JSON.parse(JSON.stringify(coldefConfigRight));
        this.columnDefsRight = this.columnDefinitionProcessorService.createColumnDefs(colDefConfig_, this);
        this.cd.detectChanges();
        // if (this.gridApiRight) {
        //     this.gridApiRight.sizeColumnsToFit();
        // }
    };
    GroupingSetupGridComponent.prototype.onGridReadyLeft = function ($event) {
        this.gridApiLeft = $event.api;
        this.gridColumnApiLeft = $event.columnApi;
        // this.gridApiLeft.sizeColumnsToFit();
        this.gridApiLeft.setRowData(this._rowDataLeft);
        this.gridApiLeft.autoSizeAllColumns();
    };
    GroupingSetupGridComponent.prototype.onGridReadyRight = function ($event) {
        this.gridApiRight = $event.api;
        this.gridColumnApiRight = $event.columnApi;
        // this.gridApiRight.sizeColumnsToFit();
        this.gridApiRight.setRowData(this.rowDataRight);
        this.gridApiRight.autoSizeAllColumns();
    };
    GroupingSetupGridComponent.prototype.onGridSizeChangeLeft = function () {
        // this.gridApiLeft.sizeColumnsToFit();
        this.setGridWidth(this.gridApiLeft);
    };
    GroupingSetupGridComponent.prototype.onGridSizeChangeRight = function () {
        // this.gridApiRight.sizeColumnsToFit();
        this.setGridWidth(this.gridApiRight);
    };
    GroupingSetupGridComponent.prototype.onClickAddSelected = function () {
        var e_2, _a;
        var selectedRows = this.gridApiLeft.getSelectedRows();
        try {
            for (var selectedRows_1 = tslib_1.__values(selectedRows), selectedRows_1_1 = selectedRows_1.next(); !selectedRows_1_1.done; selectedRows_1_1 = selectedRows_1.next()) {
                var row = selectedRows_1_1.value;
                this.addItemIfNotAlreadyIn(row, this.rowDataRight);
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (selectedRows_1_1 && !selectedRows_1_1.done && (_a = selectedRows_1.return)) _a.call(selectedRows_1);
            }
            finally { if (e_2) throw e_2.error; }
        }
    };
    GroupingSetupGridComponent.prototype.onClickAddAll = function () {
        var e_3, _a;
        var rowData = [];
        this.gridApiLeft.forEachNode(function (node) { return rowData.push(node.data); });
        try {
            for (var rowData_1 = tslib_1.__values(rowData), rowData_1_1 = rowData_1.next(); !rowData_1_1.done; rowData_1_1 = rowData_1.next()) {
                var row = rowData_1_1.value;
                this.addItemIfNotAlreadyIn(row, this.rowDataRight);
            }
        }
        catch (e_3_1) { e_3 = { error: e_3_1 }; }
        finally {
            try {
                if (rowData_1_1 && !rowData_1_1.done && (_a = rowData_1.return)) _a.call(rowData_1);
            }
            finally { if (e_3) throw e_3.error; }
        }
    };
    GroupingSetupGridComponent.prototype.addItemIfNotAlreadyIn = function (dataRow, dataList) {
        var _this = this;
        if (dataList) {
            if (this.identityFunction) {
                var found = dataList.find(function (element) { return _this.identityFunction(dataRow) ===
                    _this.identityFunction(element); });
                // should replace from above after fixing above identityFunction
                // const found = dataList.find(element => element.code === dataRow.code);
                if (!found) {
                    dataList.push(dataRow);
                    this.gridApiRight.setRowData(dataList);
                }
            }
            else {
                dataList.push(dataRow);
                this.gridApiRight.setRowData(dataList);
            }
        }
    };
    GroupingSetupGridComponent.prototype.onDeleteSelectedClickRight = function () {
        var e_4, _a;
        var selectedRows = this.gridApiRight.getSelectedRows();
        this.removeItemsFromLeftSelection(selectedRows);
        try {
            for (var selectedRows_2 = tslib_1.__values(selectedRows), selectedRows_2_1 = selectedRows_2.next(); !selectedRows_2_1.done; selectedRows_2_1 = selectedRows_2.next()) {
                var row = selectedRows_2_1.value;
                this.removeItemIfAlreadyIn(row, this.rowDataRight);
            }
        }
        catch (e_4_1) { e_4 = { error: e_4_1 }; }
        finally {
            try {
                if (selectedRows_2_1 && !selectedRows_2_1.done && (_a = selectedRows_2.return)) _a.call(selectedRows_2);
            }
            finally { if (e_4) throw e_4.error; }
        }
    };
    GroupingSetupGridComponent.prototype.removeItemsFromLeftSelection = function (selectedRows) {
        if (selectedRows && selectedRows.length > 0) {
            // this.gridApiLeft.getSelectedRows().s
        }
    };
    GroupingSetupGridComponent.prototype.removeItemIfAlreadyIn = function (dataRow, dataList) {
        if (dataList) {
            var index = dataList.indexOf(dataRow);
            if (index > -1) {
                dataList.splice(index, 1);
                this.gridApiRight.setRowData(dataList);
            }
        }
    };
    GroupingSetupGridComponent.prototype.onSelectionChangeRight = function (event) {
        if (event && event.option) {
            var option = event.option.value;
            var selected = event.option.selected;
            if (this.gridColumnApiRight) {
                this.gridColumnApiRight.setColumnVisible(option, selected);
                if (this.autoSize) {
                    this.gridApiRight.autoSizeAllColumns();
                }
                else {
                    // this.gridApiRight.sizeColumnsToFit();
                }
            }
        }
    };
    GroupingSetupGridComponent.prototype.onSelectionChangeLeft = function (event) {
        if (event && event.option) {
            var option = event.option.value;
            var selected = event.option.selected;
            if (this.gridColumnApiLeft) {
                this.gridColumnApiLeft.setColumnVisible(option, selected);
                if (this.autoSize) {
                    this.gridColumnApiLeft.autoSizeAllColumns();
                }
                else {
                    // this.gridApiLeft.sizeColumnsToFit();
                }
            }
        }
    };
    GroupingSetupGridComponent.prototype.onClickColOptionRight = function (event) {
        event.stopPropagation();
    };
    GroupingSetupGridComponent.prototype.onClickColOptionLeft = function (event) {
        event.stopPropagation();
    };
    return GroupingSetupGridComponent;
}());
export { GroupingSetupGridComponent };
