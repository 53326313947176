import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { CurrencyFormat } from '@tc-core/model/it/codegen/ui/framework/currency-format';
import {TC} from '@tc-core/util/constants';
import { ConfigLoader } from '@tc-core/util/framework/config-loader.service';
import { TCO } from '../../../constants';
import {ServiceItemChanges} from '../../../models/helper/service-item-changes';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ResourceAvailability} from '../../../models/resource/resource-availability';

@Component({
    selector: 'tc-vehicle-assign-detail-view',
    templateUrl: './vehicle-assign-detail-view.component.html'
})
export class VehicleAssignDetailViewComponent implements OnInit {

    EMPTY_CHAR = TC.FORMAT.EMPTY_CHAR;
    CURRENCY_TYPE = TCO.AppData.DEF_CURRENCY_TYPE;
    defCostUnit = 'Per Person';

    @Input() summaryCardData: ResourceAvailability;
    @Input() bookingData: any;
    @Input() bookingDataArray: any[];
    @Input() assignedVehicle = new ServiceItemChanges;
    @Input() footerDataMap: Map<number, object>;
    @Input() isBulkAssignment: boolean = false;

    @Output() headerClick: EventEmitter<any> = new EventEmitter();
    @Output() clickOutSide: EventEmitter<any> = new EventEmitter();

    currencyFormat: CurrencyFormat;
    supplierCostChangeForm: FormGroup;
    totalCost: number = 0;
    passengerCount: number = 1;
    vehicleCapacity: number = 1;
    adultCount = 0;
    childCount=0;
    infantCount=0;
    defaultCost = 0.00;

    constructor(private fb: FormBuilder,private configLoader: ConfigLoader) {
    }

    ngOnInit() {
        /*this.assignedVehicle.vehicle = this.summaryCardData;
        this.assignedVehicle.supplier = this.summaryCardData.supplier;*/

        this.getPassengerCounts();
        this.currencyFormat = this.getCurrencyFormat();


    }

    private getCurrencyFormat(): CurrencyFormat {
        let currencyFormat;
        let currencySymbolPattern = this.configLoader.configurations.get(TC.CONF.CONF_SYSTEM).defaults.default_currency_symbol_pattern;
        currencyFormat = new CurrencyFormat(TCO.AppData.DEF_CURRENCY_TYPE, currencySymbolPattern);
        return currencyFormat;
    }

    private getPassengerCounts() {
        this.passengerCount = 0;
        this.adultCount = 0;
        this.childCount=0;
        this.infantCount=0;
        if (this.isBulkAssignment && this.bookingDataArray) {
            this.bookingDataArray.forEach(
                value => {
                    this.passengerCount += value['rawData'].adult + value['rawData'].child + value['rawData'].infant;
                    this.adultCount += value['rawData'].adult;
                    this.childCount += value['rawData'].child;
                    this.infantCount += value['rawData'].infant;
                }
            );
        } else if (this.bookingData) {
            this.passengerCount = this.bookingData.adult + this.bookingData.child + this.bookingData.infant;
            this.adultCount = this.bookingData.adult;
            this.childCount = this.bookingData.child;
            this.infantCount = this.bookingData.infant;
        }
    }


}
