<div class="container split-passengers-panel">
    <div class="row tc-item--center">
        <div class="col-xs-12 col-md-3 split-passengers-input__container">
            <div class="tc-content--wrap tc-input-view">
                <div
                        id="TRD_PER_INFO_REF"
                        class="tc-caption-txt txt-light">{{"Transfer Mode" | translate}}
                </div>
                <mat-form-field>
                    <input
                            [disabled]="true"
                            matInput
                            [value]="transferMode">
                </mat-form-field>
            </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 split-passengers-input__container">
            <div class="tc-content--wrap tc-input-view">
                <div
                        class="tc-caption-txt txt-light">{{"Passenger Count" | translate}}
                </div>
                <mat-form-field>
                    <input
                            [disabled]="true"
                            matInput
                            [value]="passengerCount">
                </mat-form-field>
            </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 split-passengers-input__container">
            <div class="tc-content--wrap tc-input-view">
                <div
                        id="TRD_PER_INFO_HOD_REF"
                        class="tc-caption-txt txt-light">{{"Passengers per Group" | translate}}
                </div>
                <mat-form-field>
                    <input
                            type="number"
                            matInput
                            [min]="1"
                            [(ngModel)]="passengersPerGroup">

                </mat-form-field>
            </div>
        </div>
        <div class="col-xs-12 col-sm-12  col-md-3 col-lg-3">
            <div class="text-right">
                <button
                        class="button-right tc-button-primary mat-button"
                        (click)="onSpit($event)"
                        [disabled]="!(passengersPerGroup && passengersPerGroup>0 && passengersPerGroup<passengerCount)">
                    Split
                </button>
            </div>
        </div>
    </div>
</div>
<div class="" *ngIf="passengerGroups && passengerGroups.length>0">
    <tc-result-header
            [groupingOptionList]="groupingOptionList"
            (selectedGroupingType)="onSelectGroupingType($event)">
    </tc-result-header>

    <div class="price-break-down__detail-view">
        <div class="split-passengers-results__container">

            <!-- header -->
            <div class="container split-passengers-results__header">
                <div class="row item-header__content">
                    <ng-container>
                        <div class="col-xs-6 col-md-5">
                            <span>{{passengerDataHeadings[0]?.name |translate}}</span>
                        </div>
                    </ng-container>
                    <ng-container>
                        <div class="col-xs-6 col-md-3">
                            <span>{{passengerDataHeadings[1]?.name |translate}}</span>
                        </div>
                    </ng-container>
                    <ng-container>
                        <div class="col-xs-6 col-md-4">
                            <span>{{passengerDataHeadings[2]?.name |translate}}</span>
                        </div>
                    </ng-container>
                </div>
            </div>

            <!-- body -->
            <div class="container split-passengers-results__body">
                <div class="row tc-item--center split-passengers-results__item" *ngFor="let item of passengerData">
                    <i
                            *ngIf="item?.needAssistance"
                            class="material-icons split-passengers-results__assistance-icon">assistant_photo</i>
                    <div class="col-xs-6 col-md-5">{{item?.name}}</div>
                    <div class="col-xs-6 col-md-3">{{item?.type}}</div>
                    <div class="tc-input-edit col-xs-6 col-md-4">
                        <mat-form-field>
                            <mat-select
                                    [(ngModel)]="item.assignedGroup"
                                    (valueChange)="setPassengerGroup($event,item)">
                                <mat-option *ngFor="let option of passengerGroups" [value]="option.code">
                                    {{option.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="tc-modal-footer">
    <button class="button-right tc-button-secondary mat-button" (click)="onClose($event)">
        {{'Close' | translate}}
    </button>
    <button class="button-right tc-button-primary mat-button" (click)="onSave($event)"
            [disabled]="isSaveDisabled()">
        {{'Save' | translate}}
    </button>
</div>
