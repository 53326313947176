import {UserBusinessType} from '../user/user-business-type';

export class PersonalInformation {
    resourceType: any;
    title: string;
    fName: string;
    lName: string;
    dateOfBirth: Date;
    gender: string;
    activeStatus: boolean = false;
    supplierType: string;
    businessType: UserBusinessType;
}

export namespace GenderOptions {
    export enum TYPE {'Male', 'Female', 'Unknown' }
}

export namespace TitleOptions {
    export enum TYPE {'Mr', 'Mrs', 'Ms', 'Dr'}
}

export namespace SupplierTypeOptions {
    export enum TYPE {'External', 'Internal', 'Petty Cash', 'Intra'}
}
