import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { TC } from '@tc-core/util';
import { ConfigLoader } from '@tc-core/util/framework';
import { IGetRowsParams } from 'ag-grid-community';
import { of, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SetupGridComp } from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid-comp';
import { SetupGridComponent } from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid.component';
import { TCO } from '../../../constants';
import { LocationGroupsSearchCriteria } from '../../../models/criteria/location-groups-search-criteria';
import { RoutesSearchCriteria } from '../../../models/criteria/routes-search-criteria';
import { SortDirection } from '../../../models/helper/sort-direction';
import { DataHandlerService } from '../../../services/backend-consumers/master-data-handler-service/data-handler.service';
import { LocationsService } from '../../../services/backend-consumers/setups/locations.service';
import { DataKey, DataStoreService } from '../../../services/util/framework/data-store.service';
var EMPTY_CHAR = TC.FORMAT.EMPTY_CHAR;
var RoutesGridSetupComponent = /** @class */ (function (_super) {
    tslib_1.__extends(RoutesGridSetupComponent, _super);
    function RoutesGridSetupComponent(configLoader, dataStoreService, dataHandlerService, locationsService) {
        var _this = _super.call(this) || this;
        _this.configLoader = configLoader;
        _this.dataStoreService = dataStoreService;
        _this.dataHandlerService = dataHandlerService;
        _this.locationsService = locationsService;
        _this.colDefConfig = [];
        _this.routesSearchCriteria = new RoutesSearchCriteria();
        // required options for grid editors
        // nothing
        _this.searchCriteriaObserver = new Subscription();
        /*
         grid config methods which are called by grid setup (ag grid)
         and set by grid config
         */
        _this.getFromLocationGroupText = function (params) {
            var groupText = EMPTY_CHAR;
            if (params && params.data) {
                if (params.data.fromGroupEntity) {
                    if (params.data.fromGroupEntity.code) {
                        groupText = params.data.fromGroupEntity.code;
                    }
                    else {
                        groupText = EMPTY_CHAR;
                    }
                    groupText += ' | ';
                    if (params.data.fromGroupEntity.name) {
                        groupText += params.data.fromGroupEntity.name;
                    }
                    else {
                        groupText += EMPTY_CHAR;
                    }
                }
                else if (params.data.fromGroup) {
                    groupText = params.data.fromGroup;
                }
            }
            return groupText;
        };
        _this.getToLocationGroupText = function (params) {
            var groupText = EMPTY_CHAR;
            if (params && params.data) {
                if (params.data.toGroupEntity) {
                    if (params.data.toGroupEntity.code) {
                        groupText = params.data.toGroupEntity.code;
                    }
                    else {
                        groupText = EMPTY_CHAR;
                    }
                    groupText += ' | ';
                    if (params.data.toGroupEntity.name) {
                        groupText += params.data.toGroupEntity.name;
                    }
                    else {
                        groupText += EMPTY_CHAR;
                    }
                }
                else if (params.data.toGroup) {
                    groupText = params.data.toGroup;
                }
            }
            return groupText;
        };
        _this.getLocationGroups = function (params, rowData, text) {
            return _this.dataStoreService.get(DataKey.locationGroups)
                .map(function (aEvents) {
                return aEvents.filter(function (aEvent) {
                    if (text) {
                        if (aEvent.name) {
                            if (aEvent.name.toLowerCase().indexOf(text.toLowerCase().toLocaleString()) > -1) {
                                return aEvent.name.toLowerCase().indexOf(text.toLowerCase().toLocaleString()) > -1;
                            }
                            else {
                                return aEvent.code.toLowerCase().indexOf(text.toLowerCase().toLocaleString()) > -1;
                            }
                        }
                    }
                    else {
                        return true;
                    }
                });
            });
        };
        _this.onFromGroupEntitySet = function (params) {
            if (params.data && params.newValue) {
                params.data['fromGroupEntity'] = params.newValue;
                params.data['fromGroup'] = params.newValue['code'];
            }
            return true;
        };
        _this.onToGroupEntitySet = function (params) {
            if (params.data && params.newValue) {
                params.data['toGroupEntity'] = params.newValue;
                params.data['toGroup'] = params.newValue['code'];
            }
            return true;
        };
        return _this;
    }
    RoutesGridSetupComponent.prototype.ngOnInit = function () {
        this.colDefConfig = this.configLoader.configurations.get(TCO.CONF.CONF_ROUTES_SETUP);
        this.subscribeSearchCriteria();
        this.fillRequiredGridOptionData();
        this.fillDataKeyForEditorData();
    };
    /**
     * subscribe search criteria changes in search bar
     * then force grid to refresh data
     * grid refresh will be call getRaws method which is using this subscribed criteria for backend call
     */
    RoutesGridSetupComponent.prototype.subscribeSearchCriteria = function () {
        var _this = this;
        this.searchCriteriaObserver = this.dataStoreService.get(DataKey.routesSearchCriteria)
            .subscribe(function (value) {
            _this.routesSearchCriteria = value;
            _this.setupGrid.runForceSearch();
        });
    };
    RoutesGridSetupComponent.prototype.fillRequiredGridOptionData = function () {
        // nothing
    };
    RoutesGridSetupComponent.prototype.fillDataKeyForEditorData = function () {
        this.addLocationGroups();
    };
    RoutesGridSetupComponent.prototype.addLocationGroups = function () {
        var _this = this;
        var locationGroupsSearchCriteria = new LocationGroupsSearchCriteria();
        locationGroupsSearchCriteria.size = 1000000;
        this.locationsService.getLocationGroups(locationGroupsSearchCriteria).subscribe(function (result) {
            _this.dataStoreService.set(DataKey.locationGroups, result['data'], true);
        });
    };
    RoutesGridSetupComponent.prototype.isInvalidRow = function (params) {
        var data = params.data;
        if (data) {
            var invalid = true;
            if (data.code &&
                data.fromGroup &&
                data.toGroup) {
                invalid = false;
            }
            return invalid;
        }
        else {
            return false;
        }
    };
    RoutesGridSetupComponent.prototype.createNewRow = function () {
        return { unsaved: true };
    };
    RoutesGridSetupComponent.prototype.isUnsavedRow = function (row) {
        return !!row.unsaved;
    };
    RoutesGridSetupComponent.prototype.deleteRow = function (row) {
        if (row && row.code) {
            return this.locationsService.deleteRoute(row.code);
        }
        else {
            return of('success');
        }
    };
    RoutesGridSetupComponent.prototype.saveRow = function (row, event) {
        return this.locationsService.saveRoute(row)
            .pipe(tap(function (data) { return row.unsaved = undefined; }));
    };
    RoutesGridSetupComponent.prototype.getRows = function (params) {
        var _this = this;
        console.log('fetch requesting for ' + params.startRow + ' to ' + params.endRow);
        console.log(params.sortModel);
        var pageSize = params.endRow - params.startRow;
        if (!this.routesSearchCriteria) {
            this.routesSearchCriteria = new RoutesSearchCriteria();
        }
        this.routesSearchCriteria.start = params.startRow;
        this.routesSearchCriteria.size = pageSize;
        if (params.sortModel && params.sortModel[0]) {
            this.routesSearchCriteria.sortBy = params.sortModel[0].colId;
            if (params.sortModel[0].sort === SetupGridComp.GRID_SORT_ASCENDING) {
                this.routesSearchCriteria.sortDirection = SortDirection.ASC;
            }
            else {
                this.routesSearchCriteria.sortDirection = SortDirection.DESC;
            }
        }
        else {
            this.routesSearchCriteria.sortBy = 'createdOn';
            this.routesSearchCriteria.sortDirection = SortDirection.DESC;
        }
        return this.locationsService.getRoutes(this.routesSearchCriteria)
            .pipe(tap(function (data) {
            return _this.dataStoreService.set(DataKey.routesSearchResultsForCriteria, data);
        }));
    };
    RoutesGridSetupComponent.prototype.ngOnDestroy = function () {
        if (this.searchCriteriaObserver) {
            this.searchCriteriaObserver.unsubscribe();
        }
    };
    return RoutesGridSetupComponent;
}(SetupGridComp));
export { RoutesGridSetupComponent };
