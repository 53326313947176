export class TimeGroupingOptions {
    code: number;
    value: string;
    isSelected: boolean;
}

export const TIME_GROUPING_OPTIONS: TimeGroupingOptions[] = [
    {
        code: 1,
        value: '1 hour',
        isSelected: true
    },
    {
        code: 2,
        value: '2 hour',
        isSelected: false

    },
    {
        code: 3,
        value: '3 hour',
        isSelected: false

    },
    {
        code: 4,
        value: '4 hour',
        isSelected: false

    }
];
