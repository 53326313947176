import { AssignmentSummary } from '../assignment-summary';
import { DriverSupplierCostSummary } from '../summary/driver-supplier-cost-summary';
import {TrsSupplierCostSummary} from '../summary/supplier-cost-summary';
import {Icon} from '@tc-core/model/it/codegen/ui/framework/icon';

export class ResourceAvailability{
    resourceId: number;
    recommendedScore: number;
    transferMode: string;
    resourceName: string;
    resourceCost: number;
    capacity: number;
    trsSupplier: TrsSupplierCostSummary;
    driverSupplier: DriverSupplierCostSummary;
    assignments: AssignmentSummary[];
    selected: boolean;
    icon: Icon;
}