import { HttpClient } from '@angular/common/http';
import * as StompJs from '@stomp/stompjs';
import { StompHeaders } from '@stomp/stompjs';
import { TcApiError } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-api-error';
import { TcHttpError } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-http-error';
import { LEVEL } from '@tc-core/model/it/codegen/ui/framework/dialog-model';
import { ModalData } from '@tc-core/model/it/codegen/ui/framework/modal-data';
import { ConfigLoader, UserJourneyManager } from '@tc-core/util/framework';
import { CommonHelper } from '@tc-core/util/helpers/common-helper.service';
import { SpinnerService } from '@tc-core/util/ui';
import { FocusViewService } from '@tc/focus-view/focus-view.service';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import * as SockJS from 'sockjs-client';
import { TCO } from '../../../constants';
import { NotificationToastModel } from '../../../widgets/framework/notification-toast/notification-toast-model';
import { NotificationToastService } from '../../../widgets/framework/notification-toast/notification-toast.service';
import { ReservationSearchCriteria } from '../../../models/criteria/reservation-search-criteria';
import { GroupBy } from '../../../models/helper/group-by';
import { SortDirection } from '../../../models/helper/sort-direction';
import { BroadcastReceiver } from '../../../models/notification/broadcastReceiver';
import { NotificationData, NotificationReceiver, NotificationType, TypeString } from '../../../models/notification/notification-data';
import { ResourceType } from '../../../models/reservation/assignment';
import { ResAvailabilityCalendarFocusViewComponent } from '../../../widgets/shared/res-availability-calendar-focus-view/res-availability-calendar-focus-view.component';
import { DMCCommon } from '../../util/common/dmc-common';
import { DataKey, DataStoreService } from '../../util/framework/data-store.service';
import { DMCQueryParamsService } from '../../util/framework/dmc-query-params.service';
import { RequestService } from '../../util/framework/request.service';
import { ResponseUtil } from '../../util/response/response-util.service';
import { DMCLocalStorageService } from '../../util/system/dmc-local-storage.service';
import { GenericResourceService } from '../supplier-service/generic-resource.service';
import { UrlPaths } from '../url-paths';
import * as i0 from "@angular/core";
import * as i1 from "../../util/framework/dmc-query-params.service";
import * as i2 from "../../util/framework/request.service";
import * as i3 from "@angular/common/http";
import * as i4 from "@tc-core/util/framework/config-loader.service";
import * as i5 from "../../util/framework/data-store.service";
import * as i6 from "../../util/common/dmc-common";
import * as i7 from "../../../widgets/framework/notification-toast/notification-toast.service";
import * as i8 from "@tc-core/util/framework/user-journey-manager.service";
import * as i9 from "../../util/system/dmc-local-storage.service";
import * as i10 from "@tc/focus-view/focus-view.service";
import * as i11 from "../supplier-service/generic-resource.service";
import * as i12 from "@tc-core/util/helpers/common-helper.service";
import * as i13 from "@tc-core/util/ui/spinner.service";
var NotificationService = /** @class */ (function () {
    function NotificationService(queryParamsService, requestService, http, configLoader, dataStoreService, common, notificationToastService, userJourneyManager, localStorageService, focusViewService, genericResourceService, commonHelper, spinnerService) {
        this.queryParamsService = queryParamsService;
        this.requestService = requestService;
        this.http = http;
        this.configLoader = configLoader;
        this.dataStoreService = dataStoreService;
        this.common = common;
        this.notificationToastService = notificationToastService;
        this.userJourneyManager = userJourneyManager;
        this.localStorageService = localStorageService;
        this.focusViewService = focusViewService;
        this.genericResourceService = genericResourceService;
        this.commonHelper = commonHelper;
        this.spinnerService = spinnerService;
        this.updateTotalUnreadMessageCountField = new BehaviorSubject(0);
        this.totalUnreadMessageCount = this.updateTotalUnreadMessageCountField.asObservable();
        this.updateUnreadMessageList = new BehaviorSubject(null);
        this.readMessageThread = this.updateUnreadMessageList.asObservable();
        this.enableWebSocket = false;
        this.onConnect();
    }
    // web socket implementation
    NotificationService.prototype.onConnect = function () {
        var _this = this;
        var configs = this.configLoader.configurations.get(TCO.CONF.CONF_MESSAGING);
        if (configs) {
            this.enableWebSocket = configs['ENABLE-WEBSOCKET'];
        }
        // websocket connect
        if (this.enableWebSocket) {
            this.notificationUrl = this.configLoader.configurations.get(TCO.CONF.CONF_ENDPOINT)[UrlPaths.OP_NOTIFICATION_URL_KEY];
            if (!this.client || this.client.connected) {
                this.client = new StompJs.Client({
                    webSocketFactory: function () { return new SockJS(_this.notificationUrl + UrlPaths.WEB_SOCKET); },
                    debug: function (msg) { return console.log(msg); }
                });
                this.client.onConnect = function () {
                    _this.client.subscribe(UrlPaths.GET_NOTIFICATIONS, function (response) {
                        var data = JSON.parse(response.body);
                        if (data && data.length > 0) {
                            var notificationToastModel = new NotificationToastModel();
                            var notification = data[0];
                            notificationToastModel.title = TypeString[notification.type];
                            if (notification.type === NotificationType.RESOURCE_COMMUNICATION) {
                                notificationToastModel.body = notification.resourceName;
                            }
                            else {
                                notificationToastModel.body = notification.description;
                            }
                            notificationToastModel.timeout = 5000;
                            notificationToastModel.matIcon = 'notifications';
                            notificationToastModel.sound = 'assets/sounds/notification.mp3';
                            notificationToastModel.rawData = notification;
                            notificationToastModel.onClick = function (item) {
                                _this.onClickNotificationPopupClick(item);
                            };
                            if (notification.type !== NotificationType.RESOURCE_COMMUNICATION) {
                                _this.notificationToastService.add(notificationToastModel);
                                _this.dataStoreService.set(DataKey.newNotifications, data, true);
                            }
                        }
                    });
                    _this.client.subscribe(UrlPaths.GET_MESSAGES, function (response) {
                        var data = JSON.parse(response.body);
                        if (data && data.length > 0) {
                            if (!data[0].sendingDmc) {
                                var notificationToastModel = new NotificationToastModel();
                                var message = data[0];
                                notificationToastModel.title = message.resourceName;
                                notificationToastModel.body = message.description;
                                notificationToastModel.timeout = 5000;
                                notificationToastModel.matIcon = 'chat';
                                notificationToastModel.sound = 'assets/sounds/message.mp3';
                                notificationToastModel.rawData = message;
                                notificationToastModel.onClick = function (item) {
                                    _this.onClickNotificationPopupClick(item);
                                };
                                _this.notificationToastService.add(notificationToastModel);
                            }
                            _this.dataStoreService.set(DataKey.newMessages, data, true);
                        }
                    });
                    _this.onStart();
                };
                this.client.onStompError = function (frame) {
                    console.error(frame.headers.message);
                    console.error('Details:', frame.body);
                };
                this.client.activate();
            }
        }
    };
    NotificationService.prototype.onClickNotificationPopupClick = function (notification) {
        if (notification && notification.rawData) {
            var data = notification.rawData;
            if (data.indicator === 'message') {
                this.openMessageQueue(data);
            }
            else if (data.type === NotificationType.NEW_USER_REGISTRATION) {
                this.openUserQueue(data);
            }
            else if (data.type === NotificationType.LEAVE_REQUEST) {
                this.onResCalendarClick(data);
            }
            else if (data.type === NotificationType.ASSIGNMENT_UPDATE) {
                this.openServiceQueue(data);
            }
        }
    };
    NotificationService.prototype.openMessageQueue = function (notification) {
        this.localStorageService.delete('resourceId');
        this.localStorageService.delete('job');
        this.localStorageService.store('resourceId', notification.sendingResourceId ? notification.sendingResourceId.toString() : '');
        this.localStorageService.store('isAssignment', 'false');
        this.userJourneyManager.goForKey('BEGIN_MESSAGE_QUEUE');
    };
    NotificationService.prototype.openNotificationQueue = function (notification) {
        this.userJourneyManager.goForKey('BEGIN_NOTIFICATION_QUEUE');
    };
    NotificationService.prototype.openUserQueue = function (notification) {
        this.userJourneyManager.goForKey('BEGIN_RESOURCE_ACCEPTANCE_QUEUE');
    };
    NotificationService.prototype.onResCalendarClick = function (notification) {
        if (notification.resourceId) {
            this.retrieveResourceById(notification);
        }
    };
    NotificationService.prototype.retrieveResourceById = function (notification) {
        var _this = this;
        this.genericResourceService.retrieveResourceById(notification.resourceId)
            .subscribe(function (res) {
            if (_this.commonHelper.dataValidity(res)) {
                _this.spinnerService.hide();
                var resource = ResponseUtil.getFirstData(res);
                _this.openResCalendar(resource);
            }
            else if (res instanceof TcApiError) {
                _this.spinnerService.hide();
            }
            else if (res instanceof TcHttpError) {
                _this.spinnerService.hide();
            }
        });
        return null;
    };
    NotificationService.prototype.openResCalendar = function (resource) {
        var _this = this;
        var resourceName = resource.resourceType === ResourceType.vehicle ?
            resource.vehicle.vehicleName :
            resource.genericResource.firstName;
        var navConfigData = this.configLoader.configurations.get(TCO.CONF.CONF_PAGE_SECTION_CONFIG)
            .availability_calendar_side_nav.resource_level;
        setTimeout(function () {
            var fabActions = [];
            var dataObject = new ModalData(LEVEL.SUCCESS, 'Availability Calendar', null, null, 'season-setup-focus-view', ResAvailabilityCalendarFocusViewComponent, {
                resourceSummary: resource,
                navConfig: navConfigData
            }, { label: 'close' }, '', '', fabActions, '', resource ?
                resourceName + ' | Supplier: ' + (resource.supplierName) :
                '');
            // this.focusViewCloseSubject = this.dataStore.get(DataKey.availabilityCalendarFocusViewClose).subscribe(c => {
            //     if (c) {
            //     }
            // });
            _this.focusViewService.confirm(dataObject).subscribe(function (res) {
                if (res) {
                    console.log(res);
                }
            });
        }, 0);
    };
    NotificationService.prototype.openServiceQueue = function (notification) {
        if (notification.serviceType === ResourceType.vehicle || notification.serviceType === ResourceType.driver) {
            if (notification.assignmentId && notification.assignmentId > 0) {
                var criteria = new ReservationSearchCriteria();
                criteria.serviceType = 'TRS';
                criteria.assignmentId = notification.assignmentId;
                criteria.startDate = moment().startOf('year').format('YYYY-MM-DD');
                criteria.endDate = moment().add(1, 'year').endOf('year').format('YYYY-MM-DD');
                criteria.groupBy = GroupBy.INTERVAL;
                criteria.interval = 1;
                criteria.filterStartTime = '00:00';
                criteria.sortDirection = SortDirection.ASC;
                this.localStorageService.store(TCO.AppData.RESERVATION_SEARCH_CRITERIA, criteria);
            }
            this.userJourneyManager.goForKey('GO_TO_TRANSPORT_ALLOCATION_QUEUE');
        }
        else {
            if (notification.assignmentId && notification.assignmentId > 0) {
                var criteria = new ReservationSearchCriteria();
                criteria.serviceType = 'GEN';
                criteria.assignmentId = notification.assignmentId;
                criteria.startDate = moment().startOf('year').format('YYYY-MM-DD');
                criteria.endDate = moment().add(1, 'year').endOf('year').format('YYYY-MM-DD');
                this.localStorageService.store(TCO.AppData.GEN_RESERVATION_SEARCH_CRITERIA, criteria);
            }
            this.userJourneyManager.goForKey('GO_TO_GENERIC_ALLOCATION_QUEUE');
        }
    };
    NotificationService.prototype.disconnectClicked = function () {
        if (this.client && this.client.connected) {
            this.client.deactivate();
            this.client = null;
            console.info('disconnected :-/');
        }
    };
    NotificationService.prototype.onStart = function () {
        if (this.client && this.client.connected) {
            var h1 = new StompHeaders();
            h1.isDmc = 'true';
            this.client.publish({ destination: UrlPaths.START_LISTENING, headers: h1 });
            // this.sendDummyMessage();
            // this.receiveDummyMsg();
        }
    };
    NotificationService.prototype.sendDummyMessage = function () {
        var notification = new NotificationData();
        notification.type = NotificationType.RESOURCE_COMMUNICATION;
        notification.description = 'Hello bro';
        notification.indicator = 'Message';
        notification.receivingResourceId = 2222;
        notification.sendingDmc = true;
        notification.resourceName = 'Mafaris';
        notification.receiver = NotificationReceiver.ONE_RESOURCE;
        this.sendMessage(notification);
    };
    NotificationService.prototype.receiveDummyMsg = function () {
        var notification = new NotificationData();
        notification.type = NotificationType.RESOURCE_COMMUNICATION;
        notification.description = 'My vehicle was stolen';
        notification.indicator = 'Message';
        // notification.receivingResourceId = 361;
        notification.sendingResourceId = 24;
        // notification.sendingResourceId = 361;
        notification.receivingDmc = true;
        notification.resourceName = 'Yashoda Sanjeewi';
        notification.receiver = NotificationReceiver.DMC;
        this.sendMessage(notification);
    };
    NotificationService.prototype.sendBroadCast = function () {
        var notification = new NotificationData();
        notification.type = NotificationType.RESOURCE_BROADCAST;
        notification.description = 'BroadCast Test';
        var broadCastReceiver1 = new BroadcastReceiver();
        broadCastReceiver1.resourceId = 2222;
        broadCastReceiver1.name = 'Mafaris';
        var broadCastReceiver2 = new BroadcastReceiver();
        broadCastReceiver2.resourceId = 362;
        broadCastReceiver2.name = 'Sajee lorenzo';
        notification.receivingResourcesIds = [];
        notification.receivingResourcesIds.push(broadCastReceiver1);
        notification.receivingResourcesIds.push(broadCastReceiver2);
        notification.indicator = 'Message';
        notification.sendingDmc = true;
        notification.receiver = NotificationReceiver.ALL_RESOURCES;
        this.sendBroadCastMessage(notification);
    };
    NotificationService.prototype.onStop = function () {
        if (this.client && this.client.connected) {
            this.client.publish({ destination: UrlPaths.STOP_LISTENING });
        }
    };
    NotificationService.prototype.sendMessage = function (notification) {
        if (this.client && this.client.connected) {
            this.client.publish({ destination: UrlPaths.SEND_MESSAGE, body: JSON.stringify(notification) });
        }
    };
    NotificationService.prototype.sendBroadCastMessage = function (notification) {
        if (this.client && this.client.connected) {
            this.client.publish({ destination: UrlPaths.SEND_BROADCAST_MESSAGE, body: JSON.stringify(notification) });
        }
    };
    NotificationService.prototype.getResourceAcceptanceDataFromMock = function () {
        return this.http.get('assets\\mockdata\\resource-acceptance-data.json');
    };
    NotificationService.prototype.saveGroupThread = function (saveGroupThread) {
        return this.requestService.post(UrlPaths.OP_NOTIFICATION_URL_KEY, [UrlPaths.OP_RES_VERSION, UrlPaths.MESSAGES_GROUP_SAVE], saveGroupThread);
    };
    NotificationService.prototype.getGroupThread = function (groupThreadId) {
        var params = this.queryParamsService.asQueryParams(groupThreadId);
        return this.requestService.get(UrlPaths.OP_NOTIFICATION_URL_KEY, [UrlPaths.OP_RES_VERSION, UrlPaths.MESSAGES]);
    };
    NotificationService.prototype.searchNotifications = function (criteria) {
        criteria.dmc = true;
        criteria.type = criteria.type && criteria.type === "null" ? "" : criteria.type;
        var params = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.getWithDataKey(DataKey.notificationSearchResultsFromService, UrlPaths.OP_NOTIFICATION_URL_KEY, [UrlPaths.OP_NOTIFICATION_VERSION, UrlPaths.NOTIFICATIONS], params);
    };
    NotificationService.prototype.searchMessageThreads = function (criteria) {
        criteria.dmc = true;
        criteria.message = true;
        var params = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.getWithDataKey(DataKey.messageSearchResultsFromService, UrlPaths.OP_NOTIFICATION_URL_KEY, [UrlPaths.OP_NOTIFICATION_VERSION, UrlPaths.MESSAGES], params);
    };
    NotificationService.prototype.loadMore = function (criteria) {
        criteria.dmc = true;
        criteria.indicator = 'message';
        var params = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.get(UrlPaths.OP_NOTIFICATION_URL_KEY, [UrlPaths.OP_NOTIFICATION_VERSION, UrlPaths.MORE_MESSAGES], params);
    };
    NotificationService.prototype.changeNotificationState = function (obj) {
        return this.requestService.post(UrlPaths.OP_NOTIFICATION_URL_KEY, [UrlPaths.OP_NOTIFICATION_VERSION, UrlPaths.NOTIFICATION_STATE_CHANGE], obj);
    };
    NotificationService.prototype.updateTotalUnreadMessageCount = function (updateCount) {
        this.updateTotalUnreadMessageCountField.next(updateCount);
    };
    NotificationService.prototype.updateUnReadMessageList = function (thread) {
        this.updateUnreadMessageList.next(thread);
    };
    NotificationService.ngInjectableDef = i0.defineInjectable({ factory: function NotificationService_Factory() { return new NotificationService(i0.inject(i1.DMCQueryParamsService), i0.inject(i2.RequestService), i0.inject(i3.HttpClient), i0.inject(i4.ConfigLoader), i0.inject(i5.DataStoreService), i0.inject(i6.DMCCommon), i0.inject(i7.NotificationToastService), i0.inject(i8.UserJourneyManager), i0.inject(i9.DMCLocalStorageService), i0.inject(i10.FocusViewService), i0.inject(i11.GenericResourceService), i0.inject(i12.CommonHelper), i0.inject(i13.SpinnerService)); }, token: NotificationService, providedIn: "root" });
    return NotificationService;
}());
export { NotificationService };
