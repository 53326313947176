import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import { locationGroupSetup } from '../../../models/master-data/location-group-setup';
import { ServiceResourceSetup } from '../../../models/master-data/service-resource-setup';
import { TimeOffsetSetup } from '../../../models/master-data/time-offset-setup';


@Injectable()
export class RoutesSetupService {

    // baseURL = 'DMC/assets/mockdata/';
    baseURL = 'assets/mockdata/';

    constructor(private http: HttpClient) {
    }

    public retrieveLocationGroups(): Observable<locationGroupSetup[]> {
        return this.http.get<locationGroupSetup[]>(this.baseURL + 'locationGroups.json');
    }

    retrieveTimeOffsetData(): Observable<TimeOffsetSetup[]> {
        return this.http.get<TimeOffsetSetup[]>(this.baseURL + 'timeOffsetData.json');
    }
    public getLocationTypes(): Observable<any[]> {
        return this.http.get<any[]>(this.baseURL + 'locationTypes.json');
    }
    public countryList(): Observable<any[]> {
        return this.http.get<any[]>('/http://qa.central.codegen.net/data/data-service/v2/countries');
    }
    public regionList(): Observable<any[]> {
        return this.http.get<any[]>('http://qa.central.codegen.net/data/data-service/v2/states');
    }
    public cityList(): Observable<any[]> {
        return this.http.get<any[]>('http://qa.central.codegen.net/data/data-service/v2/cities');
    }

    public retrieveServices(): Observable<ServiceResourceSetup[]> {
        return this.http.get<ServiceResourceSetup[]>(this.baseURL + 'services.json');
    }

    public retrievelocations(): Observable<any[]> {
        return this.http.get<any[]>(this.baseURL + 'locations.json');
    }



}
