import * as tslib_1 from "tslib";
import { ResponseUtil } from '../../util/response/response-util.service';
import { ConfigurationSearchCriteria } from '../../../models/criteria/configuration-search-criteria';
import { MasterDataRestService } from '../master-data-handler-service/master-data-rest.service';
import * as i0 from "@angular/core";
import * as i1 from "../master-data-handler-service/master-data-rest.service";
var BackendConfigService = /** @class */ (function () {
    function BackendConfigService(masterDataRestService) {
        this.masterDataRestService = masterDataRestService;
        this.configs = new Map();
        this.loaded = false;
    }
    BackendConfigService.prototype.loadBackendConfigs = function () {
        var _this = this;
        if (!this.loaded) {
            console.log('loading backend configs');
            var configurationSearchCriteria = new ConfigurationSearchCriteria();
            configurationSearchCriteria.size = 10000000;
            this.masterDataRestService.getConfigurations(configurationSearchCriteria)
                .subscribe(function (result) {
                _this.loaded = true;
                _this.updateConfigs(result);
            });
        }
    };
    BackendConfigService.prototype.updateConfigs = function (configs) {
        var e_1, _a;
        if (!this.configs) {
            this.configs = new Map();
        }
        if (configs) {
            var dataArray = ResponseUtil.getDataArray(configs);
            try {
                for (var dataArray_1 = tslib_1.__values(dataArray), dataArray_1_1 = dataArray_1.next(); !dataArray_1_1.done; dataArray_1_1 = dataArray_1.next()) {
                    var configuration = dataArray_1_1.value;
                    this.configs.set(configuration.code, configuration.value);
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (dataArray_1_1 && !dataArray_1_1.done && (_a = dataArray_1.return)) _a.call(dataArray_1);
                }
                finally { if (e_1) throw e_1.error; }
            }
        }
    };
    BackendConfigService.prototype.getConfigStr = function (code) {
        var value = this.configs.get(code);
        return value ? value : '';
    };
    BackendConfigService.prototype.getConfigInt = function (code) {
        var configValue = parseInt(this.getConfigStr(code), 10);
        if (isNaN(configValue)) {
            return -1;
        }
        else {
            return configValue;
        }
    };
    BackendConfigService.prototype.getConfigFloat = function (code) {
        var configValue = parseFloat(this.getConfigStr(code));
        if (isNaN(configValue)) {
            return -1;
        }
        else {
            return configValue;
        }
    };
    BackendConfigService.ngInjectableDef = i0.defineInjectable({ factory: function BackendConfigService_Factory() { return new BackendConfigService(i0.inject(i1.MasterDataRestService)); }, token: BackendConfigService, providedIn: "root" });
    return BackendConfigService;
}());
export { BackendConfigService };
