import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ICellRendererParams } from 'ag-grid-community';
import { BookingImportSummary } from '../../../models/reservation-v2/booking-import-summary';
import { DocumentService } from '../../../services/backend-consumers/documents/document.service';
import { GridColumnDefinitionProcessorService } from '../../../services/util/pre-processors/grid-column-definition-processor.service';
import { AutoCompleteEditorComponent } from '../../../widgets/framework/ag-grid-custom-components/editors/auto-complete-editor/auto-complete-editor.component';
import { DateEditorComponent } from '../../../widgets/framework/ag-grid-custom-components/editors/date-editor/date-editor.component';
import { DropdownEditorComponent } from '../../../widgets/framework/ag-grid-custom-components/editors/dropdown-editor/dropdown-editor.component';
import { MultiSelectorEditorComponent } from '../../../widgets/framework/ag-grid-custom-components/editors/multi-selector-editor/multi-selector-editor.component';
import { RadioButtonEditorComponent } from '../../../widgets/framework/ag-grid-custom-components/editors/radio-button-editor/radio-button-editor.component';
import { CheckBoxRendererEditorComponent } from '../../../widgets/framework/ag-grid-custom-components/renderers/check-box-selector-renderer/check-box-renderer-editor.component';
import { TemplateRendererComponent } from '../../../widgets/framework/ag-grid-custom-components/renderers/template-renderer/template-renderer.component';

@Component({
    selector: 'tc-booking-import-summary-component',
    templateUrl: './booking-import-summary.component.html'
})
export class BookingImportSummaryComponent implements OnInit {

    @Input() importSummary: BookingImportSummary;

    @Input()
    set colDefConfig(colDef: any[]) {
        this._colDefConfig = colDef;
        this.setColumnDefs();
    }

    @Input() defaultColDef;
    @Input() gridOptions;

    _colDefConfig: any[];
    public columnDefs;
    public gridApi;
    public gridColumnApi;
    public rowClassRules;


    frameworkComponents: any = {
        'templateRenderer': TemplateRendererComponent,
        'checkBoxRendererEditor': CheckBoxRendererEditorComponent,
        'dropDownEditor': DropdownEditorComponent,
        'multiSelectorEditor': MultiSelectorEditorComponent,
        'radioButtonEditor': RadioButtonEditorComponent,
        'autoCompleteEditor': AutoCompleteEditorComponent,
        'dateEditor': DateEditorComponent
    };

    constructor(
        private cd: ChangeDetectorRef,
        private columnDefinitionProcessorService: GridColumnDefinitionProcessorService,
        private translateService: TranslateService,
        private documentService: DocumentService,
        private matDialog: MatDialog
    ) {
        this.translateService.onLangChange.subscribe(() => {
            this.gridApi.refreshHeader();
        });
        this.defaultColDef = {
            filter: false,
            sortable: false,
            resizable: false,
            headerValueGetter: (parameters: ICellRendererParams): string => {
                const headerIdentifier = parameters.colDef.headerName;
                if (headerIdentifier) {
                    return this.translateService.instant(headerIdentifier);
                }
                return '';
            }
        };

        this.gridOptions = {
            rowSelection: false,
            cacheBlockSize: 20,
            maxBlocksInCache: 2,
            enableServerSideFilter: false,
            enableServerSideSorting: false,
            rowModelType: 'infinite',
            pagination: true,
            paginationPageSize: 5
        };
        this.rowClassRules = {
            'tc-ag-grid-row--error'(params) { return !params.data.itemSavedStatus; }
        };
    }
    ngOnInit() {
        console.log(this.importSummary);
    }
    private setColumnDefs() {
        const colDefConfig = JSON.parse(JSON.stringify(this._colDefConfig));
        const coldDefs: any[] = this.columnDefinitionProcessorService.createColumnDefs(colDefConfig, this);

        this.columnDefs = coldDefs;
        this.cd.detectChanges();
        if (this.gridApi) {
            this.gridApi.sizeColumnsToFit();
        }
    }
    onGridReady($event) {
        this.gridApi = $event.api;
        this.gridColumnApi = $event.columnApi;
        this.gridApi.setFloatingFiltersHeight(38);
        this.gridApi.setHeaderHeight(40);
        this.gridApi.sizeColumnsToFit();
    }
    public onExport(failedItemsDocumentId: number) {
        if (failedItemsDocumentId > 0) {
            const docIds: number[] = [];
            docIds.push(failedItemsDocumentId);
            this.documentService.downloadDocuments(docIds);
        }

    }

    public onClose() {
        this.matDialog.closeAll();
    }

    getImportStatus = (params) => {
        if (params.data && params.data.itemSavedStatus) {
            return 'SUCCESS';
        } else {
            return 'FAILED';
        }
    };

    public disableExport() {
        return this.importSummary.failedItemsDocumentId < 1;
    }
}
