import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatExpansionModule, MatInputModule, MatMenuModule, MatSelectModule } from '@angular/material';
import { TranslateModule } from '@ngx-translate/core';
import { ContentStripModule } from '@tc/content-strip';
import { ExpandedContentCardBodyModule } from '@tc/expanded-content-card-body';
import { PipesModule } from '@tc/pipes';
import { NgNumbersOnlyInputDirectiveModule } from 'ng-numbers-only-input-directive';
import { ResAvailabilityModule } from '../res-availability/res-availability.module';
import { RoomGrpEditCardComponent } from './room-grp-edit-card.component';

@NgModule({
    imports: [
        CommonModule,
        MatInputModule,
        ReactiveFormsModule,
        TranslateModule.forChild(),
        MatSelectModule,
        FormsModule,
        MatMenuModule,
        PipesModule,
        ExpandedContentCardBodyModule,
        MatExpansionModule,
        ContentStripModule,
        ResAvailabilityModule,
        NgNumbersOnlyInputDirectiveModule
    ],
  declarations: [RoomGrpEditCardComponent],
    exports: [RoomGrpEditCardComponent]
})
export class RoomGrpEditCardModule { }
