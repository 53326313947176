import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TooltipPosition } from '@angular/material/tooltip';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { NotificationSearchCriteria } from '../../models/criteria/notification-search-criteria';
import { BroadcastReceiver } from '../../models/notification/broadcastReceiver';
import { MessageThread } from '../../models/notification/message-thread';
import { NotificationData, NotificationReceiver, NotificationType } from '../../models/notification/notification-data';
import { OPResponseWrapper } from '../../models/response/op-response-wrapper';
import { NotificationService } from '../../services/backend-consumers/notifications/notification.service';
import { UserProfileService } from '../../services/backend-consumers/user-service/user-profile.service';
import { ResponseUtil } from '../../services/util/response/response-util.service';
import { DMCLocalStorageService } from '../../services/util/system/dmc-local-storage.service';

@Component({
    selector: 'tc-resource-message-panel',
    templateUrl: './resource-message-panel.component.html'
})
export class ResourceMessagePanelComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {

    @ViewChild('chatPanel') private scrollContainer: ElementRef;
    @Input() selectedChat: MessageThread;
    @Input() isBroadCast: boolean = false;
    @Input() group: Array<BroadcastReceiver> = [];
    @Input() selectedNotificationId: number = -1;
    @Input() messagesPresent: boolean;
    @Input() groupMsgThreadId: number;
    @Output() newMsgSent: EventEmitter<boolean> = new EventEmitter();

    newMessageHeight = 100;

    public toolTipPosition: TooltipPosition = 'below';
    messages: NotificationData[] = [];
    sendMessageForm: FormGroup;
    message: string = '';
    messagesLoaded: boolean = false;
    messageToSend: string = '';
    size = 10;
    start = 10;
    notificationCriteria: NotificationSearchCriteria = new NotificationSearchCriteria();
    loadMoreSubscription: Subscription = new Subscription();

    userEmailAndResourceIdList: Map<number, string> = new Map<number, string>();

    constructor(
        private fb: FormBuilder,
        private localStorageService: DMCLocalStorageService,
        private notificationService: NotificationService,
        private userService: UserProfileService
    ) {
        this.createForm();
    }

    ngOnInit() {
        this.getAllUserEmails();
        this.sendMessageForm.valueChanges.subscribe(
            (data) => {
                if (JSON.stringify(data) !== JSON.stringify({})) {
                    this.message = data.message;
                    console.log('message: ' + this.message);
                }
            }
        );
        this.scrollToBottom();
    }

    ngAfterViewInit() {
        this.scrollToBottom();
        let element = document.getElementById('msg-' + this.selectedNotificationId);
        if (element) {
            element.scrollIntoView({behavior: 'smooth', block: 'end', inline: 'nearest'});
            this.localStorageService.delete('notificationId');
        }

    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['selectedChat'] && changes['selectedChat'].currentValue) {
            this.selectedChat = changes['selectedChat'].currentValue;
            this.message = '';
            this.processThread();
            this.scrollToBottom();
        }
        if (changes['selectedNotificationId'] && changes['selectedNotificationId'].currentValue) {
            this.selectedNotificationId = changes['selectedNotificationId'].currentValue;
        }
        if (changes['group'] && changes['group'].currentValue) {
            this.group = changes['group'].currentValue;
        }
        if (changes['selectedNotificationId'] && changes['selectedNotificationId'].currentValue) {
            this.selectedNotificationId = changes['selectedNotificationId'].currentValue;
        }
    }

    getAllUserEmails() {
        this.userService.getAllEmailsForResourceIds().subscribe((result) => {
            if (result) {
                result.data.forEach(data => {
                    this.userEmailAndResourceIdList.set(data.resourceId, data.email);
                });
            }
        });
    }

    scrollToBottom() {
        try {
            this.scrollContainer.nativeElement.scrollTop = this.scrollContainer.nativeElement.scrollHeight;
        } catch (err) {
            console.log(err);
        }
    }

    ngOnDestroy() {

    }

    /***
     * load more with scroll
     * @param $event
     */
    scrollEvent($event) {
        if (this.scrollContainer.nativeElement.scrollTop == 0) {
            this.notificationCriteria.start = this.start;
            this.notificationCriteria.size = this.size;
            this.loadMoreSubscription = this.notificationService.loadMore(this.notificationCriteria).subscribe(
                (data: OPResponseWrapper<NotificationData>) => {
                    if (data) {
                        let newMessages: Array<NotificationData> = ResponseUtil.getDataArray<NotificationData>(data);
                        if (newMessages.length > 0) {
                            newMessages.forEach(msg => {
                                this.messages.unshift(msg);
                                this.processThread();
                            });
                            this.start = this.start + this.size;
                        }
                    }
                }
            );
        }
    }

    /***
     * initialize the form
     */
    createForm() {
        this.sendMessageForm = this.fb.group({
            message: [
                ''
            ]
        });
    }

    /***
     * send message
     */
    sendMessage() {
        if (this.message && this.message.trim() !== '') {
            const notification = new NotificationData();

            if (this.selectedChat && this.selectedChat.groupMsgThreadId != null && this.selectedChat.groupMsgThreadId > 0) {
                this.isBroadCast = true;
                notification.groupMsgThreadId = this.selectedChat.groupMsgThreadId;
            } else {
                this.isBroadCast = false;
            }

            notification.type = this.isBroadCast ?
                NotificationType.RESOURCE_BROADCAST :
                NotificationType.RESOURCE_COMMUNICATION;
            notification.description = this.message.trim();
            notification.indicator = 'Message';
            notification.sendingDmc = true;
            if (!this.isBroadCast) {
                notification.resourceName = this.selectedChat && this.selectedChat.resourceName ?
                    this.selectedChat.resourceName : this.selectedChat && this.selectedChat.supplierName;
                notification.receiver = NotificationReceiver.ONE_RESOURCE;
                notification.receivingResourceId = this.selectedChat && this.selectedChat.resourceId ?
                    this.selectedChat.resourceId : this.selectedChat && this.selectedChat.supplierId;
                notification.receivingEmail = this.selectedChat && this.selectedChat.email ?
                    this.selectedChat.email : this.selectedChat && this.selectedChat.email;
                if (notification.receivingEmail == null) {
                    notification.receivingEmail = this.userEmailAndResourceIdList.get(notification.receivingResourceId);
                }
                this.notificationService.sendMessage(notification);
            } else {
                notification.receivingResourcesIds = [];
                notification.receivingResourcesIds = this.group;
                notification.receiver = NotificationReceiver.ALL_RESOURCES;
                if (this.groupMsgThreadId > 0) {
                    notification.groupMsgThreadId = this.groupMsgThreadId;
                }
                this.notificationService.sendBroadCastMessage(notification);
            }

            // this.messages.push(notification);
            this.processMessages();
            this.sendMessageForm.controls['message'].setValue('');
            this.scrollToBottom();
        } else {
            this.sendMessageForm.controls['message'].setValue('');
        }
    }

    // handleKeyEvent($event){
    //   if($event.keyCode === 13){
    //     this.sendMessage();
    //   }
    // }
    onKeyDown(event) {
        event.preventDefault();
    }

    onKeyUp(event) {
        this.sendMessage();
    }

    onKeydownCtrlEnter(event) {
        console.log('ctrl+enter');
    }

    chunk(str: string, n) {
        let ret = [];
        let i;
        let len;

        for (i = 0, len = str.length; i < len; i += n) {
            ret.push(str.substr(i, n));
        }

        return ret;
    }

    /***
     * process thread
     */
    processThread() {
        if (this.selectedChat && this.selectedChat.mostRecentMessages) {
            this.messages = this.selectedChat.mostRecentMessages;
            this.notificationCriteria.resourceId = this.selectedChat.resourceId ?
                this.selectedChat.resourceId :
                this.selectedChat.supplierId;
            //sort the messages
            this.messages.sort((a, b) => a.notificationId < b.notificationId ?
                -1 :
                b.notificationId > a.notificationId ? 1 : 0);
            this.processMessages();
            if (this.selectedChat.mostRecentMessagesFE && this.selectedChat.mostRecentMessagesFE.length > 0) {
                this.selectedChat.mostRecentMessagesFE.sort((a, b) => a.notificationId < b.notificationId ?
                    -1 :
                    b.notificationId > a.notificationId ? 1 : 0);
                this.messages.push(...this.selectedChat.mostRecentMessagesFE);
                this.selectedChat.mostRecentMessagesFE = [];
            }
        } else {
            if (this.selectedChat.mostRecentMessagesFE && this.selectedChat.mostRecentMessagesFE.length > 0) {
                this.selectedChat.mostRecentMessagesFE.sort((a, b) => a.notificationId < b.notificationId ?
                    -1 :
                    b.notificationId > a.notificationId ? 1 : 0);
                this.messages.push(...this.selectedChat.mostRecentMessagesFE);
                this.selectedChat.mostRecentMessagesFE = [];
            } else {
                this.messages = [];
            }
        }

        if (this.messages.length > 0 && this.messages[0] && this.messages[0].type === 'ASSIGNMENT_UPDATE') {
            let msg = this.messages[0].description;
            let id = '';
            let arr: Array<string> = msg.split('[ id: ');
            if (arr.length > 1 && arr[1]) {
                id = arr[1].split(' ')[0];
            }
            let msgText = '[DMC-JOB-' + id + '] ' + this.messages[0].indicator.split('_').join(' ').toLowerCase();
            this.sendMessageForm.controls['message'].setValue(msgText);
            this.localStorageService.delete('resourceId');
            this.localStorageService.delete('job');
            this.localStorageService.delete('assignmentData');
        }

    }

    /***
     * process single msg data
     */
    processMessages() {
        this.messages.forEach((msg, index) => {
            if (msg.modifiedOn !== null && !msg.displayTimeStamp) {
                if (moment(msg.modifiedOn).format('MMM Do YY') !== moment().format('MMM Do YY')) {
                    msg.displayTimeStamp = moment(msg.modifiedOn).format('MMM Do, h:mm a');
                } else {
                    msg.displayTimeStamp = moment(msg.modifiedOn).calendar();
                }
            }
            if (msg.notificationId == null && msg.receivingDmc && !this.selectedChat.isSelected) {
                this.messages.splice(index, 1);
            }
            let tempArr: Array<string> = msg.description.split('DMC-JOB');
            if (tempArr.length > 1) {
                msg.indicator = 'job';
            } else {
                msg.indicator = 'message';
            }
            msg.description = msg.sendingDmc ? msg.description : this.chunk(msg.description, 70).join('<br>');
        });
        this.messagesLoaded = true;
    }

}
