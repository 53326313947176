import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ReservationSearchCriteria } from '../../../models/criteria/reservation-search-criteria';
import { GenTourItinerary } from '../../../models/reservation/generic/gen-tour-itinerary';
import { ResourceAvailability } from '../../../models/resource/resource-availability';
import { OPResponseWrapper } from '../../../models/response/op-response-wrapper';
import { DataKey } from '../../util/framework/data-store.service';
import { DMCQueryParamsService } from '../../util/framework/dmc-query-params.service';
import { RequestService } from '../../util/framework/request.service';
import { UrlPaths } from '../url-paths';

@Injectable({
    providedIn: 'root'
})
export class GenReservationManagementService {

    GENERIC_RES_TYPE = 'GEN';

    constructor(
        private queryParamsService: DMCQueryParamsService,
        private requestService: RequestService,
        private http: HttpClient
    ) { }

    public searchReservations(criteria: ReservationSearchCriteria): Observable<OPResponseWrapper<GenTourItinerary>> {
        criteria.serviceType = this.GENERIC_RES_TYPE;
        let params = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.getWithDataKey(
            DataKey.genReservationSearchResultsFromService,
            UrlPaths.OP_RES_URL_KEY,
            [UrlPaths.OP_RES_VERSION, UrlPaths.GENERIC_ITEMS],
            params
        );
    }

    public searchReservationsOnMore(criteria: ReservationSearchCriteria): Observable<OPResponseWrapper<GenTourItinerary>> {
        criteria.serviceType = this.GENERIC_RES_TYPE;
        let params = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.getWithDataKey(
            DataKey.genReservationSearchResultsFromServiceOnMore,
            UrlPaths.OP_RES_URL_KEY,
            [UrlPaths.OP_RES_VERSION, UrlPaths.GENERIC_ITEMS],
            params
        );
    }

    public exportServiceItems(criteria: ReservationSearchCriteria): Observable<any[]> {
        return null;
    }

    public saveGenReservations(genTourItineraries: Array<GenTourItinerary>): Observable<OPResponseWrapper<any>> {
        return this.requestService.patch(
            UrlPaths.OP_RES_URL_KEY,
            [UrlPaths.OP_RES_VERSION, UrlPaths.GENERIC_ITEMS],
            genTourItineraries
        );
    }

    public searchReservationsFromMock(): Observable<OPResponseWrapper<GenTourItinerary>> {
        return this.http.get<OPResponseWrapper<GenTourItinerary>>("assets\\mockdata\\genericSearchResults.json");
    }

}
