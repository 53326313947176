import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import {
    MatFormFieldModule,
    MatIconModule,
    MatInputModule
} from '@angular/material';
import { AddMoreLangDialogModule } from '../../shared/add-more-lang-dialog/add-more-lang-dialog.module';
import { HennaArtistSpecificComponent } from './henna-artist-specific.component';

@NgModule({
    imports: [
        CommonModule,
        CommonModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        AddMoreLangDialogModule,
        MatIconModule
    ],
    declarations: [HennaArtistSpecificComponent],
    exports: [HennaArtistSpecificComponent]
})
export class HennaArtistSpecificModule {}
