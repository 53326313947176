import { Component } from '@angular/core';
import { TCO } from '../../../../constants';
import { MainType } from '../../../../models/reservation-v2/main-type';
import { ProductLevel } from '../../../../models/reservation-v2/product-level';
import { ProductQueueSearchCriteria } from '../../../../models/reservation-v2/criteria/product-queue-search-criteria';
import { DataKey } from '../../../../services/util/framework/data-store.service';

@Component({
    selector: 'tc-product-queue-search-criteria-tou',
    templateUrl: './product-queue-search-criteria-tou.component.html'
})
export class ProductQueueSearchCriteriaTouComponent {

    inputSearchCriteria = new ProductQueueSearchCriteria();
    searchCriteriaUpdatingDataKey = DataKey.productQueueSearchCriteria;
    searchResultsDataKey = DataKey.productQueueSearchResultsFromService;
    searchCriteriaLoadingDataKey = TCO.AppData.PRODUCT_QUEUE_SEARCH_CRITERIA_TOU;
    chipConfigKey = TCO.CONF.CONF_PRODUCT_QUEUE_SEARCH_CRITERIA_CHIP_TOU;
    paginationConfigKey = 'PRODUCT_QUEUE_SEARCH';
    placeHolderText = 'Search Tour Products';

    createNewSearchCriteriaFn = () => {
        const criteria = new ProductQueueSearchCriteria();
        criteria.productLevel = ProductLevel.ANY;
        criteria.mainType = MainType.TOU;
        criteria.subType = 'ANY';
        criteria.pvtShared = 'ANY';
        return criteria;
    };

    clearLoadedSearchCriteriaFn = (searchCriteria: ProductQueueSearchCriteria) => {
        searchCriteria.productLevel = ProductLevel.ANY;
        searchCriteria.mainType = MainType.TOU;
        searchCriteria.subType = 'ANY';
        searchCriteria.pvtShared = 'ANY';
    };
}
