import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TCRequestOptions } from '@tc-core/model/it/codegen/ui/request-option';
import { ConfigLoader } from '@tc-core/util/framework';
import { HeaderService } from '@tc-core/util/system';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { AuthTokenService } from '../../../authentication/auth-token.service';
import { TCO } from '../../../constants';
import { DataLoaderService } from '../../api/data-loader-service.service';
import { DMCBaseService } from '../system/dmc-base.service';
import { DataKey, DataStoreService } from './data-store.service';
import { DMCQueryParamsService } from './dmc-query-params.service';
import { ErrorHandlerService } from './error-handler.service';

@Injectable()
export class RequestService
{

    constructor(
        private queryParamsService: DMCQueryParamsService,
        private configLoader: ConfigLoader,
        private baseService: DMCBaseService,
        private headerService: HeaderService,
        private dataStore: DataStoreService,
        private dataLoader: DataLoaderService,
        private errorHandlerService: ErrorHandlerService,
    )
    {
    }

    private getRequestArgs(params?: HttpParams)
    {
        const requestOptionsArgs: TCRequestOptions = new TCRequestOptions();
        if (params) {
            requestOptionsArgs.params = params;
        }
        return requestOptionsArgs;
    }

    public getUrl(endpointId: string, pathVariables: any[]): string
    {
        let url = this.configLoader.configurations.get(TCO.CONF.CONF_ENDPOINT)[endpointId];
        if (pathVariables && pathVariables.length > 0) {
            const bracketPathVariables = url.match(/\{([^}]+)\}/g);
            pathVariables.forEach((pathVariable, i) => {
                if (bracketPathVariables && bracketPathVariables.length > 0) {
                    url = url.replace(bracketPathVariables[i], pathVariable);
                } else {
                    url = url + '/' + pathVariable;
                }
            });
        }
        return url;
    }

    public download(endpointId: string, pathVariables: string[], params?: string)
    {
        let downloadUrl = this.getUrl(endpointId, pathVariables);
        if (params) {
            downloadUrl += params;
        }
        return downloadUrl;
    }

    public getByUrl<T>(endpointId: string, url: string, params?: HttpParams): Observable<T>
    {
        const requestOptionsArgs = this.getRequestArgs(params);
        return this.baseService.get<T>(url, requestOptionsArgs)
                   .pipe(
                       map(value => value.body),
                       tap(body => this.errorHandlerService.handleError(body, endpointId)),
                       catchError(error => this.errorHandlerService.handleHttpError(error, endpointId))
                   );

    }

    public get<T>(endpointId: string, pathVariables: string[], params?: HttpParams): Observable<T>
    {
        const url = this.getUrl(endpointId, pathVariables);
        const requestOptionsArgs = this.getRequestArgs(params);
        return this.baseService.get<T>(url, requestOptionsArgs)
                   .pipe(
                       map(value => value.body),
                       tap(body => this.errorHandlerService.handleError(body, endpointId)),
                       catchError(error => this.errorHandlerService.handleHttpError(error, endpointId))
                   );

    }

    public getWithDataKey<T>(
        dataKey: DataKey, endpointId: string, pathVariables: string[], params?: HttpParams): Observable<T>
    {
        this.dataStore.set(dataKey, null, true);
        this.dataLoader.loadResponse(
            dataKey,
            endpointId,
            params,
            pathVariables
        );
        return this.dataStore.get(dataKey, true);
    }

    public post<T>(endpointId: string, pathVariables: string[], body, params?: HttpParams): Observable<T>
    {
        const url = this.getUrl(endpointId, pathVariables);
        const requestOptionsArgs = this.getRequestArgs(params);
        return this.baseService.post<T>(url, body, requestOptionsArgs)
                   .pipe(
                       map(value => value.body),
                       tap(bodyR => this.errorHandlerService.handleError(bodyR, endpointId)),
                       catchError(error => this.errorHandlerService.handleHttpError(error, endpointId))
                   );
    }

    public postForUrl<T>(url: string, body, params?: any): Observable<T>
    {
        const requestOptionsArgs = this.getRequestArgs(params);
        return this.baseService.post<T>(url, body, requestOptionsArgs)
                   .pipe(
                       map(value => value.body),
                       tap(bodyR => this.errorHandlerService.handleError(bodyR, '')),
                       catchError(error => this.errorHandlerService.handleHttpError(error, ''))
                   );
    }

    public postWithDataKey<T>(
        dataKey: DataKey, endpointId: string, pathVariables: string[], body, params?: HttpParams): Observable<T>
    {
        this.dataStore.set(dataKey, null, true);
        this.dataLoader.postAndLoadResponse(
            dataKey,
            endpointId,
            body,
            params,
            pathVariables
        );
        return this.dataStore.get(dataKey, true);
    }

    public put<T>(endpointId: string, pathVariables: string[], body, params?: HttpParams): Observable<T>
    {
        const url = this.getUrl(endpointId, pathVariables);
        const requestOptionsArgs = this.getRequestArgs(params);
        return this.baseService.put<T>(url, body, requestOptionsArgs)
                   .pipe(
                       map(value => value.body),
                       tap(body => this.errorHandlerService.handleError(body, endpointId)),
                       catchError(error => this.errorHandlerService.handleHttpError(error, endpointId))
                   );
    }

    public putWithDataKey<T>(
        dataKey: DataKey, endpointId: string, pathVariables: string[], body, params?: HttpParams): Observable<T>
    {
        this.dataStore.set(dataKey, null, true);
        this.dataLoader.putAndLoadResponse(
            dataKey,
            endpointId,
            body,
            params,
            pathVariables
        );
        return this.dataStore.get(dataKey, true);
    }

    public delete<T>(endpointId: string, pathVariables: string[], params?: HttpParams): Observable<T>
    {
        const url = this.getUrl(endpointId, pathVariables);
        const requestOptionsArgs = this.getRequestArgs(params);
        return this.baseService.delete<T>(url, requestOptionsArgs)
                   .pipe(
                       map(value => value.body),
                       tap(body => this.errorHandlerService.handleError(body, endpointId)),
                       catchError(error => this.errorHandlerService.handleHttpError(error, endpointId))
                   );
    }

    public deleteWithDataKey<T>(
        dataKey: DataKey, endpointId: string, pathVariables: string[], params?: HttpParams): Observable<T>
    {
        this.dataStore.set(dataKey, null, true);
        this.dataLoader.deleteAndLoadResponse(
            dataKey,
            endpointId,
            params,
            pathVariables
        );
        return this.dataStore.get(dataKey, true);
    }

    public patch<T>(endpointId: string, pathVariables: string[], body, params?: HttpParams): Observable<T>
    {
        const url = this.getUrl(endpointId, pathVariables);
        const requestOptionsArgs = this.getRequestArgs(params);
        return this.baseService.patch<T>(url, body, requestOptionsArgs)
                   .pipe(
                       map(value => value.body),
                       tap(bodyResp => this.errorHandlerService.handleError(bodyResp, endpointId)),
                       catchError(error => this.errorHandlerService.handleHttpError(error, endpointId))
                   );
    }

    public patchWithDataKey<T>(
        dataKey: DataKey, endpointId: string, pathVariables: string[], body, params?: HttpParams): Observable<T>
    {
        this.dataStore.set(dataKey, null, true);
        this.dataLoader.patchAndLoadResponse(
            dataKey,
            endpointId,
            body,
            params,
            pathVariables
        );
        return this.dataStore.get(dataKey, true);
    }
}
