import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { GenericResourcePersonalInfoCardComponent } from './generic-resource-personal-info-card.component';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule.forChild(),

    ],
    declarations: [GenericResourcePersonalInfoCardComponent],
    exports: [GenericResourcePersonalInfoCardComponent]
})
export class GenericResourcePersonalInfoCardModule {}
