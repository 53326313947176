<form [formGroup]="costSetupDetailsForm">
    <div class="tc-card-block tc-shadow--2 list margin-expanded-area tc-card-wrapper tc-sc-cost-setup">
        <div class="tc-flex-box container-fluid">
            <div class="tc-flex-item tppie-row-2-col-1-block__input-1 margin-remove">
                <div class="row">
                    <div class="col-xs-12 col-sm-12  col-md-3 col-lg-3">
                        <div class="tc-select-edit dcpiec-select-title">
                            <mat-form-field>
                                <mat-select
                                        #localeInput
                                        id="DIR_PER_INFO_EDIT_MODE"
                                        (selectionChange)="getVehicleModels()"
                                        formControlName="transferMode"
                                        [placeholder]="'Transfer Mode'" required>
                                    <mat-option
                                            *ngFor="let option of transferModes"
                                            [value]="option.code">
                                        {{option.name}}
                                    </mat-option>
                                </mat-select>
                                <mat-error>{{getErrorMessageOfControl(costSetupDetailsForm, 'transferMode')}}
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-12  col-md-3 col-lg-3">
                        <div class="tc-select-edit dcpiec-select-title">
                            <mat-form-field>
                                <mat-select
                                        #localeInput
                                        id="VEHICLE_MODEL_EDIT_MODE"
                                        (selectionChange)="validateUniqueValues()"
                                        formControlName="vehicleModel"
                                        [placeholder]="'Vehicle Model'" required>
                                    <mat-option
                                            *ngFor="let option of vehicleModelList"
                                            [value]="option.code">
                                        {{option.name}}

                                    </mat-option>
                                </mat-select>
                                <mat-error>{{getErrorMessageOfControl(costSetupDetailsForm, 'vehicleModel')}}
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-12  col-md-3 col-lg-3">
                        <div class="tc-select-edit dcpiec-select-title">
                            <mat-form-field>
                                <mat-select
                                        #localeInput
                                        id="DIR_PER_INFO_EDIT_ROUTE"
                                        (selectionChange)="validateUniqueValues()"
                                        formControlName="route"
                                        [placeholder]="'Route'" required>
                                    <mat-option *ngFor="let option of routes" [value]="option.code">
                                        {{option.code}}
                                    </mat-option>
                                </mat-select>
                                <mat-error>{{getErrorMessageOfControl(costSetupDetailsForm, 'route')}}
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="col-xs-12 col-sm-12  col-md-3 col-lg-3">
                        <div class="tc-select-edit dcpiec-select-title">

                            <mat-form-field>
                                <mat-select
                                        #localeInput
                                        id="DIR_PER_INFO_EDIT_SEASON"
                                        (selectionChange)="validateUniqueValues()"
                                        formControlName="season"
                                        [placeholder]="'Season'" required>
                                    <mat-option
                                            *ngFor="let option of seasonSetup"
                                            [value]="option.tempSeasonId">
                                        {{option.name}}
                                    </mat-option>
                                </mat-select>
                                <mat-error>{{getErrorMessageOfControl(costSetupDetailsForm, 'season')}}
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>


                </div>
                <div class="row">
                    <div class="col-xs-12 col-sm-12  col-md-3 col-lg-3">
                        <div class="tc-sc-cost-setup__form-input">
                            <div class="tc-input-view__label">{{'Unit Cost' | translate}}</div>
                            <mat-form-field>
                                <span matPrefix>{{currencyFormat.currencyCode}}&nbsp;</span>
                                <input
                                        tcNumberInput="true"
                                        matInput
                                        formControlName="unitFare"
                                        (change)="validateCost()"
                                        maxlength="12"
                                        [placeholder]="">
                                <!--[ngModel]="summaryCardData?.data?.value?.supplier?.cost | currency:CURRENCY_TYPE: true">-->
                                <mat-error>{{getErrorMessageOfControl(costSetupDetailsForm, 'Unit Cost')}}
                                </mat-error>
                            </mat-form-field>

                            <!--<tc-currency-field
                                    [amount]="this.costSetupDetailsForm.get('unitFare').value"
                                    [showPreviousAmount]="false"
                                    formControlName="unitFare"
                                    [currencyFormat]="currencyFormat"
                                    [readOnly]="unitFareDisable"
                                    (onInputValueChange)="onUnitFareChange($event)">
                            </tc-currency-field>-->
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-12  col-md-3 col-lg-3">
                        <div class="tc-sc-cost-setup__form-input">
                            <div class="tc-input-view__label">{{'Cost Per Adult' | translate}}</div>
                            <mat-form-field>
                                <span matPrefix>{{currencyFormat.currencyCode}}&nbsp;</span>
                                <input
                                        tcNumberInput="true"
                                        matInput
                                        formControlName="adultFare"
                                        (change)="validateCost()"
                                        maxlength="12"
                                        [placeholder]="">
                                <mat-error>{{getErrorMessageOfControl(costSetupDetailsForm, 'Cost Per Adult')}}
                                </mat-error>
                            </mat-form-field>
                            <!--<div class="tc-input-view__label tc-input-view__label&#45;&#45;light">Adult Fare</div>
                            <tc-currency-field
                                    [amount]="this.costSetupDetailsForm.get('adultFare').value"
                                    [showPreviousAmount]="false"
                                    formControlName="adultFare"
                                    [currencyFormat]="currencyFormat"
                                    [readOnly]="false"
                                    (onInputValueChange)="onAdultFareChange($event)">
                            </tc-currency-field>-->
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-12  col-md-3 col-lg-3">
                        <div class="tc-sc-cost-setup__form-input">
                            <div class="tc-input-view__label">{{'Cost Per Child' | translate}}</div>
                            <mat-form-field>
                                <span matPrefix>{{currencyFormat.currencyCode}}&nbsp;</span>
                                <input
                                        tcNumberInput="true"
                                        matInput
                                        formControlName="childFare"
                                        (change)="validateCost()"
                                        maxlength="12"
                                        [placeholder]="">
                                <mat-error>{{getErrorMessageOfControl(costSetupDetailsForm, 'Cost Per Child')}}
                                </mat-error>
                            </mat-form-field>

                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-12  col-md-3 col-lg-3">
                        <div class="tc-sc-cost-setup__form-input">
                            <div class="tc-input-view__label">{{'Cost Per Infant' | translate}}</div>
                            <mat-form-field>
                                <span matPrefix>{{currencyFormat.currencyCode}}&nbsp;</span>
                                <input
                                        tcNumberInput="true"
                                        matInput
                                        formControlName="infantFare"
                                        (change)="validateCost()"
                                        maxlength="12"
                                        [placeholder]="">
                                <mat-error>{{getErrorMessageOfControl(costSetupDetailsForm, 'Cost Per Infant')}}
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="tc-input-edit dcpiec-input-fname">
                    <div class="tc-chip-display__header ">
                        <span>Day(s) Of Week</span>
                    </div>
                    <div class="row">
                        <div class="col-xs-12">
                            <div class="tc-action-chip-list-wrapper tc-chip-selector__action-chips">
                                <div
                                        class="tc-action-chip" *ngFor="let day of daysOfWeek"
                                        (click)="toggleValue(day.key)"
                                        [ngClass]="selectedDays.includes(day.key)?'tc-action-chip--active':'tc-action-chip--inactive' ">
                                    <span class="tc-action-chip__icon tc-action-chip__icon-man"></span>
                                    <span class="tc-action-chip__icon tc-action-chip__icon-select">
                                  <i class="material-icons">done</i>
                                </span>
                                    <div class="tc-action-chip__text">
                                        {{day.value}}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tc-flex-item tc-flex-item__center ">
                <div class="rs-action-icons">
                    <i
                            class="material-icons rs-action-icons__icon rs-action-icons__item"
                            *ngIf="showSave"
                            (click)="onSaveClick($event)"
                            [matTooltip]="'Save' | translate"
                            [matTooltipPosition]="toolTipPosition">
                        save</i>
                    <i
                            *ngIf="!selectedResourceCost.isNew"
                            class="material-icons rs-action-icons__icon rs-action-icons__item"
                            (click)="onRevertClick()"
                            [matTooltip]="'Revert' | translate"
                            [matTooltipPosition]="toolTipPosition">
                        undo</i>
                    <i
                            class="material-icons rs-action-icons__icon rs-action-icons__item"
                            (click)="onDeleteClick($event)"
                            [matTooltip]="'Delete' | translate"
                            [matTooltipPosition]="toolTipPosition">
                        delete</i>
                </div>
            </div>
        </div>
        <div *ngIf="isInvalidCost" class="container-fluid">
            <br>
            <mat-hint class="text-danger">{{'Invalid cost' | translate}}</mat-hint>
        </div>
        <div *ngIf="isCostMissing" class="container-fluid">
            <br>
            <mat-hint class="text-warning">{{'Missing cost fields' | translate}}</mat-hint>
        </div>

    </div>
</form>
