import { Component } from '@angular/core';
import { TCO } from '../../../../constants';
import { MainType } from '../../../../models/reservation-v2/main-type';
import { ProductLevel } from '../../../../models/reservation-v2/product-level';
import { ProductQueueSearchCriteria } from '../../../../models/reservation-v2/criteria/product-queue-search-criteria';
import { DataKey } from '../../../../services/util/framework/data-store.service';

@Component({
    selector: 'tc-product-queue-search-criteria-common',
    templateUrl: './product-queue-search-criteria-common.component.html'
})
export class ProductQueueSearchCriteriaCommonComponent {

    inputSearchCriteria = new ProductQueueSearchCriteria();
    searchCriteriaUpdatingDataKey = DataKey.productQueueSearchCriteria;
    searchResultsDataKey = DataKey.productQueueSearchResultsFromService;
    searchCriteriaLoadingDataKey = TCO.AppData.PRODUCT_QUEUE_SEARCH_CRITERIA_COMMON;
    chipConfigKey = TCO.CONF.CONF_PRODUCT_QUEUE_SEARCH_CRITERIA_CHIP_COMMON;
    paginationConfigKey = 'PRODUCT_QUEUE_SEARCH';
    placeHolderText = 'Search Products';

    createNewSearchCriteriaFn = () => {
        const criteria = new ProductQueueSearchCriteria();
        criteria.startDate = new Date().toISOString().split('T')[0];
        criteria.endDate = new Date(+new Date() + 172800000).toISOString().split('T')[0];
        criteria.productLevel = ProductLevel.ANY;
        criteria.mainType = MainType.ANY;
        criteria.subType = 'ANY';
        criteria.pvtShared = 'ANY';
        return criteria;
    };

    clearLoadedSearchCriteriaFn = (searchCriteria: ProductQueueSearchCriteria) => {
        searchCriteria.productLevel = ProductLevel.ANY;
        searchCriteria.mainType = MainType.ANY;
        searchCriteria.subType = 'ANY';
        searchCriteria.pvtShared = 'ANY';
    };
}
