/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../node_modules/ag-grid-angular/dist/agGridAngular.ngfactory";
import * as i2 from "ag-grid-angular/dist/angularFrameworkOverrides";
import * as i3 from "ag-grid-angular/dist/angularFrameworkComponentWrapper";
import * as i4 from "ag-grid-angular/dist/agGridAngular";
import * as i5 from "@ngx-translate/core";
import * as i6 from "./booking-import-summary.component";
import * as i7 from "../../../services/util/pre-processors/grid-column-definition-processor.service";
import * as i8 from "../../../services/backend-consumers/documents/document.service";
import * as i9 from "@angular/material/dialog";
var styles_BookingImportSummaryComponent = [];
var RenderType_BookingImportSummaryComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_BookingImportSummaryComponent, data: {} });
export { RenderType_BookingImportSummaryComponent as RenderType_BookingImportSummaryComponent };
export function View_BookingImportSummaryComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "detail-group__body-service-item container-fluid"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 5, "div", [["class", "tc-px-3"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 16777216, null, null, 4, "ag-grid-angular", [["class", "ag-theme-material tc-ag-grid tc-ag-grid--popup-content"], ["style", "width: 630px; margin: 0 auto;"]], null, [[null, "gridReady"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("gridReady" === en)) {
        var pd_0 = (_co.onGridReady($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_AgGridAngular_0, i1.RenderType_AgGridAngular)), i0.ɵprd(512, null, i2.AngularFrameworkOverrides, i2.AngularFrameworkOverrides, [i0.NgZone]), i0.ɵprd(512, null, i3.AngularFrameworkComponentWrapper, i3.AngularFrameworkComponentWrapper, []), i0.ɵdid(5, 4898816, null, 1, i4.AgGridAngular, [i0.ElementRef, i0.ViewContainerRef, i2.AngularFrameworkOverrides, i3.AngularFrameworkComponentWrapper, i0.ComponentFactoryResolver], { rowData: [0, "rowData"], columnDefs: [1, "columnDefs"], defaultColDef: [2, "defaultColDef"], rowClassRules: [3, "rowClassRules"], pagination: [4, "pagination"], paginationAutoPageSize: [5, "paginationAutoPageSize"] }, { gridReady: "gridReady" }), i0.ɵqud(603979776, 1, { columns: 1 }), (_l()(), i0.ɵeld(7, 0, null, null, 6, "div", [["class", "container assign-panel-footer-section"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 2, "button", [["class", "assign-panel-footer-section__button tc-button-secondary mat-button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onExport(_co.importSummary.failedItemsDocumentId) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(9, null, [" ", " "])), i0.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(11, 0, null, null, 2, "button", [["class", "assign-panel-footer-section__button tc-button-secondary mat-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClose() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(12, null, [" ", " "])), i0.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.importSummary.importedBookingItems; var currVal_1 = _co.columnDefs; var currVal_2 = _co.defaultColDef; var currVal_3 = _co.rowClassRules; var currVal_4 = true; var currVal_5 = true; _ck(_v, 5, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_6 = _co.disableExport(); _ck(_v, 8, 0, currVal_6); var currVal_7 = i0.ɵunv(_v, 9, 0, i0.ɵnov(_v, 10).transform("Export Failed Bookings")); _ck(_v, 9, 0, currVal_7); var currVal_8 = i0.ɵunv(_v, 12, 0, i0.ɵnov(_v, 13).transform("OK")); _ck(_v, 12, 0, currVal_8); }); }
export function View_BookingImportSummaryComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-booking-import-summary-component", [], null, null, null, View_BookingImportSummaryComponent_0, RenderType_BookingImportSummaryComponent)), i0.ɵdid(1, 114688, null, 0, i6.BookingImportSummaryComponent, [i0.ChangeDetectorRef, i7.GridColumnDefinitionProcessorService, i5.TranslateService, i8.DocumentService, i9.MatDialog], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BookingImportSummaryComponentNgFactory = i0.ɵccf("tc-booking-import-summary-component", i6.BookingImportSummaryComponent, View_BookingImportSummaryComponent_Host_0, { importSummary: "importSummary", colDefConfig: "colDefConfig", defaultColDef: "defaultColDef", gridOptions: "gridOptions" }, {}, []);
export { BookingImportSummaryComponentNgFactory as BookingImportSummaryComponentNgFactory };
