<form [formGroup]="bookingInfoForm">
  <div class="container-fluid direct-client-personal-info-edit-card tc-card-wrapper">
    <div class="row">
      <div class="col-md-12 col-lg-12">
        <div class="tc-flex-box">
          <div class="tc-flex-item tc-item-circle">
            <div class="icon-box">
              <i class="material-icons">info</i>
            </div>
          </div>
          <div class="tc-flex-item tc-right-cage booking-management-info">
            <div class="row">
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile booking-management-booking-id-input">
                <div class="tc-input-edit dcpiec-input-fname">
                  <mat-form-field class="mat-form-field--no-spacing">
                    <input
                            matInput
                            id="BOOKING_ID"
                            formControlName="bookingIdCombined"
                            [readonly]="readonly"
                            [placeholder]="'Booking Id'| translate">
                    <mat-error>{{getErrorMessage('bookingIdCombined')}}</mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>

