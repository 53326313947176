import { ConfigLoader } from '@tc-core/util/framework';
import { DataKey } from '../../util/framework/data-store.service';
import { DMCQueryParamsService } from '../../util/framework/dmc-query-params.service';
import { RequestService } from '../../util/framework/request.service';
import { UrlPaths } from '../url-paths';
import * as i0 from "@angular/core";
import * as i1 from "@tc-core/util/framework/config-loader.service";
import * as i2 from "../../util/framework/request.service";
import * as i3 from "../../util/framework/dmc-query-params.service";
var LocationsService = /** @class */ (function () {
    function LocationsService(configLoader, requestService, queryParamsService) {
        this.configLoader = configLoader;
        this.requestService = requestService;
        this.queryParamsService = queryParamsService;
    }
    /*********
     Location Types
     *********/
    LocationsService.prototype.getLocationTypes = function (criteria) {
        var reqPrams = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.get(UrlPaths.OP_DATA_URL_KEY, [UrlPaths.OP_DATA_VERSION, UrlPaths.LOCATION_TYPES], reqPrams);
    };
    /**********
     Location Codes
     **********/
    LocationsService.prototype.getLocationCodes = function () {
        return this.requestService.get(UrlPaths.OP_DATA_URL_KEY, [UrlPaths.OP_DATA_VERSION, UrlPaths.LOCATIONS, UrlPaths.LOCATION_CODES]);
    };
    LocationsService.prototype.checkCodeAvailability = function (code) {
        return this.requestService.get(UrlPaths.OP_DATA_URL_KEY, [UrlPaths.OP_DATA_VERSION, UrlPaths.LOCATIONS, UrlPaths.LOCATION_CODE_AVAILABILITY, code]);
    };
    /*********
     Locations
     *********/
    LocationsService.prototype.getLocations = function (criteria) {
        var reqPrams = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.get(UrlPaths.OP_DATA_URL_KEY, [UrlPaths.OP_DATA_VERSION, UrlPaths.LOCATIONS], reqPrams);
    };
    LocationsService.prototype.deleteLocation = function (code) {
        return this.requestService.delete(UrlPaths.OP_DATA_URL_KEY, [UrlPaths.OP_DATA_VERSION, UrlPaths.LOCATIONS, code]);
    };
    LocationsService.prototype.saveLocation = function (row) {
        return this.requestService.post(UrlPaths.OP_DATA_URL_KEY, [UrlPaths.OP_DATA_VERSION, UrlPaths.LOCATIONS], row);
    };
    LocationsService.prototype.updateLocation = function (code, row) {
        return this.requestService.put(UrlPaths.OP_DATA_URL_KEY, [UrlPaths.OP_DATA_VERSION, UrlPaths.LOCATIONS, code], row);
    };
    /***************
     Location Groups
     ***************/
    LocationsService.prototype.getLocationGroups = function (criteria) {
        var reqPrams = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.get(UrlPaths.OP_DATA_URL_KEY, [UrlPaths.OP_DATA_VERSION, UrlPaths.LOCATION_GROUPS], reqPrams);
    };
    LocationsService.prototype.deleteLocationGroup = function (code) {
        return this.requestService.delete(UrlPaths.OP_DATA_URL_KEY, [UrlPaths.OP_DATA_VERSION, UrlPaths.LOCATION_GROUPS, code]);
    };
    LocationsService.prototype.saveLocationGroup = function (row) {
        return this.requestService.post(UrlPaths.OP_DATA_URL_KEY, [UrlPaths.OP_DATA_VERSION, UrlPaths.LOCATION_GROUPS], row);
    };
    LocationsService.prototype.saveLocationGroupWithDataKey = function (code, locations) {
        return this.requestService.putWithDataKey(DataKey.saveLocationGroups, UrlPaths.OP_DATA_URL_KEY, [UrlPaths.OP_DATA_VERSION, UrlPaths.LOCATION_GROUPS, code, UrlPaths.LOCATIONS], locations);
    };
    LocationsService.prototype.saveLeaves = function (leaveSetupData) {
        return this.requestService.postWithDataKey(DataKey.saveLeaveData, UrlPaths.OP_SUP_URL_KEY, [UrlPaths.OP_SUP_VERSION, UrlPaths.LEAVES], leaveSetupData);
    };
    LocationsService.prototype.updateLocationGroup = function (code, row) {
        return this.requestService.put(UrlPaths.OP_DATA_URL_KEY, [UrlPaths.OP_DATA_VERSION, UrlPaths.LOCATION_GROUPS, code], row);
    };
    /******
     Routes
     ******/
    LocationsService.prototype.getRoutes = function (criteria) {
        var reqPrams = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.get(UrlPaths.OP_DATA_URL_KEY, [UrlPaths.OP_DATA_VERSION, UrlPaths.ROUTES], reqPrams);
    };
    LocationsService.prototype.deleteRoute = function (code) {
        return this.requestService.delete(UrlPaths.OP_DATA_URL_KEY, [UrlPaths.OP_DATA_VERSION, UrlPaths.ROUTES, code]);
    };
    LocationsService.prototype.saveRoute = function (row) {
        return this.requestService.post(UrlPaths.OP_DATA_URL_KEY, [UrlPaths.OP_DATA_VERSION, UrlPaths.ROUTES], row);
    };
    LocationsService.prototype.updateRoute = function (code, row) {
        return this.requestService.put(UrlPaths.OP_DATA_URL_KEY, [UrlPaths.OP_DATA_VERSION, UrlPaths.ROUTES, code], row);
    };
    LocationsService.ngInjectableDef = i0.defineInjectable({ factory: function LocationsService_Factory() { return new LocationsService(i0.inject(i1.ConfigLoader), i0.inject(i2.RequestService), i0.inject(i3.DMCQueryParamsService)); }, token: LocationsService, providedIn: "root" });
    return LocationsService;
}());
export { LocationsService };
