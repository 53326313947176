import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BookingSupplementGroupComponent } from './booking-supplement-group/booking-supplement-group.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { AgGridModule } from 'ag-grid-angular';
import { AgGridCustomRenderersModule } from '../../../widgets/framework/ag-grid-custom-components/renderers/ag-grid-custom-renderers.module';
import { GridToolTipComponent } from '../../../widgets/framework/ag-grid-custom-components/renderers/tooltip/grid-tool-tip.component';
import {TranslateModule} from '@ngx-translate/core';
import {SupplementsEditorComponent} from './supplements-editor.component';

@NgModule({
  declarations: [SupplementsEditorComponent, BookingSupplementGroupComponent],
    imports: [
        CommonModule,
        MatExpansionModule,
        MatCheckboxModule,
        MatIconModule,
        MatButtonModule,
        AgGridCustomRenderersModule,
        AgGridModule.forRoot([GridToolTipComponent]),
        TranslateModule.forChild()
    ],
  exports : [SupplementsEditorComponent, BookingSupplementGroupComponent],
  entryComponents : [ SupplementsEditorComponent]
})
export class SupplementsEditorModule { }
