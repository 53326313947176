import {Component, Input} from '@angular/core';
import {DataKey, DataStoreService} from '../../services/util/framework/data-store.service';
import {SpinnerService} from '@tc-core/util/ui';
import {DMCCommon} from '../../services/util/common/dmc-common';

@Component({
    selector: 'tc-booking-item-export',
    templateUrl: './booking-item-export.component.html'
})
export class BookingItemExportComponent {

    @Input() templateTypes: { code, name }[];
    @Input() templateType: string;
    @Input() serviceDate: Date;

    fileToUpload: File = null;

    constructor(
        private dataStore: DataStoreService,
        private spinnerService: SpinnerService
    ) {
    }

    public uploadFile() {
        this.dataStore.set(DataKey.popupClose, true);
        this.spinnerService.show();
        this.dataStore.set(
            DataKey.bookingItemFileToUpload,
            {data: this.fileToUpload, type: this.templateType, date: this.serviceDate},
            true
        );
    }

    public closePopUp() {
        this.dataStore.set(DataKey.popupClose, true);
    }

    public handleFileInput(files: any) {
        this.fileToUpload = files.item(0);
    }

}
