import * as tslib_1 from "tslib";
import { KeyControlAndAbstractSearchCriteria } from './key-control-and-abstract-search-criteria';
var ResourceSearchCriteria = /** @class */ (function (_super) {
    tslib_1.__extends(ResourceSearchCriteria, _super);
    function ResourceSearchCriteria() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.resourceId = -1;
        _this.resourceType = '';
        _this.activeStatus = 'true';
        _this.businessType = '';
        return _this;
    }
    return ResourceSearchCriteria;
}(KeyControlAndAbstractSearchCriteria));
export { ResourceSearchCriteria };
