/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../node_modules/@tc/results-header/results-header.component.ngfactory";
import * as i2 from "@tc/results-header/results-header.component";
import * as i3 from "../../widgets/framework/card-strip/card-strip.component.ngfactory";
import * as i4 from "../../widgets/framework/card-strip/card-strip.component";
import * as i5 from "@angular/common";
import * as i6 from "../../../../node_modules/@tc/paginator/paginator.component.ngfactory";
import * as i7 from "@tc/paginator/paginator.component";
import * as i8 from "../../../../node_modules/@tc/empty-result/empty-result.component.ngfactory";
import * as i9 from "@tc/empty-result/empty-result.component";
import * as i10 from "@tc/pipes/advanced-pager.pipe";
import * as i11 from "@tc/pipes/advanced-sorter.pipe";
import * as i12 from "./leave-setup-search-results.component";
import * as i13 from "@tc-core/service/sorters/sorter.service";
import * as i14 from "../../services/util/framework/data-store.service";
import * as i15 from "@tc-core/util/ui/spinner.service";
import * as i16 from "@tc-core/util/helpers/common-helper.service";
import * as i17 from "../../services/util/ui/card-data-handler-service.service";
import * as i18 from "@tc-core/util/framework/config-loader.service";
import * as i19 from "../../services/util/core-services/root.service";
import * as i20 from "../../services/backend-consumers/supplier-service/resource-availability.service";
import * as i21 from "@tc-core/util/framework/user-journey-manager.service";
import * as i22 from "@tc-core/util/framework/event-manager.service";
import * as i23 from "@tc/focus-view/focus-view.service";
import * as i24 from "@tc-core/util/framework/results-header.service";
var styles_LeaveSetupSearchResultsComponent = [];
var RenderType_LeaveSetupSearchResultsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_LeaveSetupSearchResultsComponent, data: {} });
export { RenderType_LeaveSetupSearchResultsComponent as RenderType_LeaveSetupSearchResultsComponent };
function View_LeaveSetupSearchResultsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-results-header", [], null, [[null, "selectSorter"], [null, "headerSelect"], [null, "actionClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectSorter" === en)) {
        var pd_0 = (_co.onSort($event) !== false);
        ad = (pd_0 && ad);
    } if (("headerSelect" === en)) {
        var pd_1 = (_co.onHeaderActionSelect($event) !== false);
        ad = (pd_1 && ad);
    } if (("actionClick" === en)) {
        var pd_2 = (_co.onHeaderActionClick($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i1.View_ResultsHeaderComponent_0, i1.RenderType_ResultsHeaderComponent)), i0.ɵdid(1, 114688, null, 0, i2.ResultsHeaderComponent, [], { heading: [0, "heading"], actions: [1, "actions"], columns: [2, "columns"], sorters: [3, "sorters"], toggleAction: [4, "toggleAction"], checkBox: [5, "checkBox"] }, { selectSorter: "selectSorter", actionClick: "actionClick", headerSelect: "headerSelect" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.resultsHeaderData == null) ? null : _co.resultsHeaderData.heading); var currVal_1 = ((_co.resultsHeaderData == null) ? null : _co.resultsHeaderData.actions); var currVal_2 = ((_co.resultsHeaderData == null) ? null : _co.resultsHeaderData.columns); var currVal_3 = _co.sorterService.sorters; var currVal_4 = ((_co.resultsHeaderData == null) ? null : _co.resultsHeaderData.toggleAction); var currVal_5 = _co.getMultiSelectCheckBox(); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
function View_LeaveSetupSearchResultsComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "tc-border-list__repeat-wrapper"]], [[24, "@items", 0]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "tc-card-strip", [["class", "tc-card-strip__temp-border"]], [[8, "id", 0]], [[null, "select"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("select" === en)) {
        var pd_0 = (_co.onSelectCard($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_CardStripComponent_0, i3.RenderType_CardStripComponent)), i0.ɵdid(2, 638976, null, 0, i4.CardStripComponent, [i0.Injector], { type: [0, "type"], cardData: [1, "cardData"], checked: [2, "checked"] }, { select: "select" })], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.cardType; var currVal_3 = _v.context.$implicit; var currVal_4 = _co.isSelected(_v.context.$implicit); _ck(_v, 2, 0, currVal_2, currVal_3, currVal_4); }, function (_ck, _v) { var currVal_0 = undefined; _ck(_v, 0, 0, currVal_0); var currVal_1 = ("RESULT_INDEX_" + _v.context.index); _ck(_v, 1, 0, currVal_1); }); }
function View_LeaveSetupSearchResultsComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "tc-border-list"]], [[8, "id", 0], [24, "@list", 0]], null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 3, null, View_LeaveSetupSearchResultsComponent_3)), i0.ɵdid(2, 278528, null, 0, i5.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i0.ɵppd(3, 3), i0.ɵppd(4, 3)], function (_ck, _v) { var _co = _v.component; var currVal_2 = i0.ɵunv(_v, 2, 0, _ck(_v, 4, 0, i0.ɵnov(_v.parent, 0), i0.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i0.ɵnov(_v.parent, 1), _co.contentCardDataList, _co.sorterService.method, _co.sorterService.sorter)), _co.pager.currPage, _co.pager.itemsPerPage)); _ck(_v, 2, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = "RESULT_CONTAINER"; var currVal_1 = undefined; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_LeaveSetupSearchResultsComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-paginator", [], null, [[null, "page"], [null, "more"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("page" === en)) {
        var pd_0 = (_co.onPage($event) !== false);
        ad = (pd_0 && ad);
    } if (("more" === en)) {
        var pd_1 = (_co.onMore() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i6.View_PaginatorComponent_0, i6.RenderType_PaginatorComponent)), i0.ɵdid(1, 638976, null, 0, i7.PaginatorComponent, [], { length: [0, "length"], index: [1, "index"], size: [2, "size"] }, { page: "page", more: "more" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.contentCardDataList.length; var currVal_1 = _co.pager.currPage; var currVal_2 = _co.pager.itemsPerPage; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_LeaveSetupSearchResultsComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-empty-result", [], null, null, null, i8.View_EmptyResultComponent_0, i8.RenderType_EmptyResultComponent)), i0.ɵdid(1, 114688, null, 0, i9.EmptyResultComponent, [], { noResultsText: [0, "noResultsText"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.noResultsText; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_LeaveSetupSearchResultsComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i10.AdvancedPagerPipe, []), i0.ɵpid(0, i11.AdvancedSorterPipe, []), (_l()(), i0.ɵeld(2, 0, null, null, 6, "div", [["class", "product-promotion-search-results"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_LeaveSetupSearchResultsComponent_1)), i0.ɵdid(4, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_LeaveSetupSearchResultsComponent_2)), i0.ɵdid(6, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_LeaveSetupSearchResultsComponent_4)), i0.ɵdid(8, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_LeaveSetupSearchResultsComponent_5)), i0.ɵdid(10, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.contentCardDataList && (_co.contentCardDataList.length > 0)) && !_co.isSearchInProgress); _ck(_v, 4, 0, currVal_0); var currVal_1 = ((_co.contentCardDataList && (_co.contentCardDataList.length > 0)) && !_co.isSearchInProgress); _ck(_v, 6, 0, currVal_1); var currVal_2 = (_co.contentCardDataList.length > _co.defaultPageResultCount); _ck(_v, 8, 0, currVal_2); var currVal_3 = (((_co.contentCardDataList && (_co.contentCardDataList.length === 0)) && (_co.contentCardDataList.length === 0)) && !_co.isSearchInProgress); _ck(_v, 10, 0, currVal_3); }, null); }
export function View_LeaveSetupSearchResultsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-leave-setup-search-results", [], null, null, null, View_LeaveSetupSearchResultsComponent_0, RenderType_LeaveSetupSearchResultsComponent)), i0.ɵdid(1, 114688, null, 0, i12.LeaveSetupSearchResultsComponent, [i13.SorterService, i14.DataStoreService, i15.SpinnerService, i16.CommonHelper, i17.CardDataHandlerService, i18.ConfigLoader, i19.RootService, i20.ResourceAvailabilityService, i21.UserJourneyManager, i22.EventManager, i23.FocusViewService, i24.ResultsHeaderService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LeaveSetupSearchResultsComponentNgFactory = i0.ɵccf("tc-leave-setup-search-results", i12.LeaveSetupSearchResultsComponent, View_LeaveSetupSearchResultsComponent_Host_0, { selectedResourceIds: "selectedResourceIds", selectedCardData: "selectedCardData" }, { selectResource: "selectResource" }, []);
export { LeaveSetupSearchResultsComponentNgFactory as LeaveSetupSearchResultsComponentNgFactory };
