<tc-chip-input #gen_reservation_search_chip_input
               [isDataLoaded]="isDataLoaded"
               [isTriggerSearch]="isTriggerSearch"
               [isChipListOptionAvail]="true"
               [chisForDisplay]="displayChipsArr"
               (searchEmitter)="onGenReservationSearch($event)"
               [isFocus]="isFocus"
               [isSearchBarStyleNotAvailable]="isSearchBarStyleNotAvailable"
               [placeHolderText]="placeHolderText"
               [inputAuthConfigComponent]="null"
               (onfocus)="onFocus($event)"
               (cancelEmitter)="onCancel($event)"
               (chipListEmitter)="onChipListDisplay($event)"></tc-chip-input>
<div class="clearfix"></div>
