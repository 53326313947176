import { Component, EventEmitter, Input, OnChanges, OnInit, Output, QueryList, SimpleChanges, ViewChildren } from '@angular/core';
import { SummaryCardData } from '@tc-core/model/it/codegen/ui/framework/summary-card-data';
import {
    CityChange,
    CountryChange,
    CountryStateCityWrapper,
    InitialCountryStateCityCodes, StateChange
} from '../../../models/common/country-state-city-wrapper';
import { City, Country, State } from '../../../models/common/locale';
import { ContactDetails } from '../../../models/profile/contact-details';
import { ExpansionPanelDirective } from '@tc/directives/expansion-panel';

@Component({
    selector: 'tc-additional-contacts',
    templateUrl: './additional-contacts.component.html'
})
export class AdditionalContactsComponent implements OnInit,OnChanges {

    private selectedIndex = -1;
    contactsValidityMap: Map<number, boolean>;
    validAdditionalContacts: ContactDetails[] = [];

    @Input() categories: any[] = [];
    @Input() filteredCategoriesForAdditionalContacts: any[] = [];
    @Input() titles: any[] = [];
    @Input() additionalContactList: ContactDetails[] = [];
    @Input() additionalContactSummary: SummaryCardData = new SummaryCardData();
    @Input() additionalContactContents: SummaryCardData[] = [];
    @Input() defaultContactCategory: any;

    // country state city
    @Input() cityList: City[];
    @Input() stateList: State[];
    @Input() countryList: Country[];
    @Input() countryStateCityChangeMap: Map<number, CountryStateCityWrapper> = new Map();

    @Output() newCountryStateCityObserver: EventEmitter<InitialCountryStateCityCodes> = new EventEmitter();
    @Output() selectCountry: EventEmitter<CountryChange> = new EventEmitter();
    @Output() selectState: EventEmitter<StateChange> = new EventEmitter();
    @Output() selectCity: EventEmitter<CityChange> = new EventEmitter();

    @Output() countryTextChange: EventEmitter<CountryChange> = new EventEmitter();
    @Output() stateTextChange: EventEmitter<StateChange> = new EventEmitter();
    @Output() cityTextChange: EventEmitter<CityChange> = new EventEmitter();
    // --country state city--

    @Output() changeFormValidity: EventEmitter<boolean> = new EventEmitter();
    @Output() addressModified: EventEmitter<boolean> = new EventEmitter();

    @ViewChildren(ExpansionPanelDirective) _expansionPanels: QueryList<ExpansionPanelDirective>;

    public hideToggle = true;
    addingContactsCount: number = 0;

    constructor() {
    }

    ngOnInit() {
        this.contactsValidityMap = new Map<number, boolean>();
        if (this.additionalContactList) {
            this.emitNoContactsValidity();
            this.addressModified.emit();
        }
    }

    emitNoContactsValidity() {
        /* Initially Additional contacts form is valid since it is collapsed
         * When it is opened, th form is init and will trigger actual validation
         */
        this.changeFormValidity.emit(true);
    }

    public addAdditionalContact() {
        let contact = new ContactDetails();
        contact.tempId = this.addingContactsCount++;
        this.additionalContactList.push(contact);
        this.addressModified.emit();
        this._expansionPanels.forEach((expansionElement) => {
            if (expansionElement.id === 'additional_contact_summary_strip') {
                expansionElement.expansionState.next(false);
            }
        });
    }

    public hideIcons(index) {
        this.selectedIndex = index;
    }

    public showIcons() {
        this.selectedIndex = -1;
    }

    onDelete(event: number) {
        for (let i = 0; i < this.additionalContactList.length; i++) {
            let contactNo = this.additionalContactList[i].tempId;
            if (contactNo === event) {
                this.additionalContactList.splice(i, 1);
                this.contactsValidityMap.delete(contactNo);
                break;
            }
        }
        this.emitValidity();
        this.addressModified.emit();
    }

    emitValidity() {
        this.validAdditionalContacts = [];
        let allContactsValid = true;
        this.additionalContactList.forEach(address => {
            if (this.contactsValidityMap.get(address.tempId) === false) {
                allContactsValid = false;
            }
            /*else {
             this.validAdditionalContacts.push(address);
             }*/
        });
        this.changeFormValidity.emit(allContactsValid);
        // this.dataStore.set(DataKey.additionalContacts, this.validAdditionalContacts, true);
    }

    public checkValidity(address, event) {
        this.contactsValidityMap.set(address.tempId, event);
        this.emitValidity();
    }

    // country state city
    public onNewCountryStateCityObserver($event: InitialCountryStateCityCodes) {
        this.newCountryStateCityObserver.emit($event);
    }

    public onSelectCountry($event: CountryChange) {
        this.selectCountry.emit($event);
    }

    public onSelectState($event: StateChange) {
        this.selectState.emit($event);
    }

    public onSelectCity($event: CityChange) {
        this.selectCity.emit($event);
    }

    public onCountryTextChange($event: CountryChange) {
        this.countryTextChange.emit($event);
    }

    public onStateTextChange($event: StateChange) {
        this.stateTextChange.emit($event);
    }

    public onCityTextChange($event: CityChange) {
        this.cityTextChange.emit($event);
    }

    public ngOnChanges(changes: SimpleChanges): void {
        for (let propName in changes) {
            if (propName === 'defaultContactCategory') {
                if (this.defaultContactCategory) {
                    this.filteredCategoriesForAdditionalContacts = this.categories;
                    // this.filteredCategoriesForAdditionalContacts = this.categories.filter(value =>
                    //     value.code !== this.defaultContactCategory.code
                    // );
                }
            }
        }
    }

    // --country state city--
}
