import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PaginatorModule } from '@tc/paginator';
import { BookingItemExportModule } from '../../booking-item-export/booking-item-export.module';
import { ResultHeaderModule } from '../../../widgets/shared/result-header/result-header.module';
import { AdvBookingSearchResultsModule } from '../adv-booking-search-results/adv-booking-search-results.module';
import { BookingSearchResultsComponent } from './booking-search-results.component';

@NgModule({
    declarations: [BookingSearchResultsComponent],
    imports: [
        CommonModule,
        ResultHeaderModule,
        PaginatorModule,
        AdvBookingSearchResultsModule,
        BookingItemExportModule
    ],
    exports: [
        BookingSearchResultsComponent
    ],
    entryComponents: [
        BookingSearchResultsComponent
    ]
})
export class BookingSearchResultsModule {}
