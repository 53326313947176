import { City, Country, State } from './locale';

export class CountryStateCityWrapper {
    country: Country;
    state: State;
    city: City;
}

export class CountryChange {
    country: Country;
    text: string;
    id: number;
}

export class StateChange {
    state: State;
    text: string;
    id: number;
}

export class CityChange {
    city: City;
    text: string;
    id: number;
}

export class InitialCountryStateCityCodes {
    id: number;
    countryCode: string;
    stateCode: string;
    cityCode: string;

}
