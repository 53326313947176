import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { PaginatorModule } from '@tc/paginator';
import { ResultContentCardReservationsModule } from '../result-content-card-reservations/result-content-card-reservations.module';
import { AdvReservationSearchResultsComponent } from './adv-reservation-search-results.component';
import { PipesModule } from '@tc/pipes';
import { DirectivesModule } from '@tc/directives';
import { ContentStripModule } from '@tc/content-strip';
import { ExpandedContentCardModule } from '@tc/expanded-content-card';
import { ExpandedContentCardHeaderModule } from '@tc/expanded-content-card-header';
import { DataFormatterUtilService } from '@tc-core/util/system/data-formatter-util.service';
import { ReservationDetailViewModule } from '../reservation-detail-view/reservation-detail-view.module';

@NgModule({
  imports: [
    CommonModule,
    PipesModule,
    DirectivesModule,
    ResultContentCardReservationsModule,
    ContentStripModule,
    ExpandedContentCardModule,
    ExpandedContentCardHeaderModule,
    ReservationDetailViewModule,
    PaginatorModule
  ],
  declarations: [AdvReservationSearchResultsComponent],
  exports: [AdvReservationSearchResultsComponent],
  providers: [
    DataFormatterUtilService,
    DatePipe
  ]
})
export class AdvReservationSearchResultsModule { }
