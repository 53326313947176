/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../widgets/shared/document-add-card/document-add-card.component.ngfactory";
import * as i2 from "../../../widgets/shared/document-add-card/document-add-card.component";
import * as i3 from "@tc-core/util/helpers/common-helper.service";
import * as i4 from "./add-documents.component";
import * as i5 from "../../../services/util/framework/data-store.service";
var styles_AddDocumentsComponent = [];
var RenderType_AddDocumentsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AddDocumentsComponent, data: {} });
export { RenderType_AddDocumentsComponent as RenderType_AddDocumentsComponent };
export function View_AddDocumentsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-document-add-card", [], null, [[null, "closeModal"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("closeModal" === en)) {
        var pd_0 = (_co.closeModal($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_DocumentAddCardComponent_0, i1.RenderType_DocumentAddCardComponent)), i0.ɵdid(1, 114688, null, 0, i2.DocumentAddCardComponent, [i3.CommonHelper], { docTypeOptions: [0, "docTypeOptions"], selectedDocs: [1, "selectedDocs"] }, { closeModal: "closeModal" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.docTypeOptions; var currVal_1 = _co.selectedDocs; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_AddDocumentsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-add-documents", [], null, null, null, View_AddDocumentsComponent_0, RenderType_AddDocumentsComponent)), i0.ɵdid(1, 114688, null, 0, i4.AddDocumentsComponent, [i5.DataStoreService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AddDocumentsComponentNgFactory = i0.ɵccf("tc-add-documents", i4.AddDocumentsComponent, View_AddDocumentsComponent_Host_0, { selectedDocs: "selectedDocs", docTypeOptions: "docTypeOptions" }, {}, []);
export { AddDocumentsComponentNgFactory as AddDocumentsComponentNgFactory };
