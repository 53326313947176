import { ConfigLoader } from '@tc-core/util/framework';
import { DMCQueryParamsService } from '../../util/framework/dmc-query-params.service';
import { RequestService } from '../../util/framework/request.service';
import { UrlPaths } from '../url-paths';
import * as i0 from "@angular/core";
import * as i1 from "@tc-core/util/framework/config-loader.service";
import * as i2 from "../../util/framework/request.service";
import * as i3 from "../../util/framework/dmc-query-params.service";
var ResourceTypeService = /** @class */ (function () {
    function ResourceTypeService(configLoader, requestService, queryParamsService) {
        this.configLoader = configLoader;
        this.requestService = requestService;
        this.queryParamsService = queryParamsService;
    }
    /*********
     Resource Types
     *********/
    ResourceTypeService.prototype.getResourceTypes = function (criteria) {
        var reqPrams = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.get(UrlPaths.OP_DATA_URL_KEY, [UrlPaths.OP_DATA_VERSION, UrlPaths.RESOURCE_TYPES], reqPrams);
    };
    ResourceTypeService.prototype.getResourceCodes = function () {
        return this.requestService.get(UrlPaths.OP_DATA_URL_KEY, [UrlPaths.OP_DATA_VERSION, UrlPaths.RESOURCE_TYPES, UrlPaths.RESOURCE_CODES]);
    };
    ResourceTypeService.prototype.deleteResourceType = function (code) {
        return this.requestService.delete(UrlPaths.OP_DATA_URL_KEY, [UrlPaths.OP_DATA_VERSION, UrlPaths.RESOURCE_TYPES, code]);
    };
    ResourceTypeService.prototype.saveResourceType = function (row) {
        return this.requestService.post(UrlPaths.OP_DATA_URL_KEY, [UrlPaths.OP_DATA_VERSION, UrlPaths.RESOURCE_TYPES], row);
    };
    ResourceTypeService.prototype.updateResourceType = function (code, row) {
        return this.requestService.put(UrlPaths.OP_DATA_URL_KEY, [UrlPaths.OP_DATA_VERSION, UrlPaths.RESOURCE_TYPES, code], row);
    };
    /*********
     Profile Types
     *********/
    ResourceTypeService.prototype.getProfileTypes = function (criteria) {
        var reqPrams = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.get(UrlPaths.OP_DATA_URL_KEY, [UrlPaths.OP_DATA_VERSION, UrlPaths.PROFILE_TYPES], reqPrams);
    };
    ResourceTypeService.prototype.getProfileTypeCodes = function () {
        return this.requestService.get(UrlPaths.OP_DATA_URL_KEY, [UrlPaths.OP_DATA_VERSION, UrlPaths.PROFILE_TYPES, UrlPaths.PROFILE_TYPE_CODES]);
    };
    ResourceTypeService.prototype.deleteProfileType = function (code) {
        return this.requestService.delete(UrlPaths.OP_DATA_URL_KEY, [UrlPaths.OP_DATA_VERSION, UrlPaths.PROFILE_TYPES, code]);
    };
    ResourceTypeService.prototype.saveProfileType = function (row) {
        return this.requestService.post(UrlPaths.OP_DATA_URL_KEY, [UrlPaths.OP_DATA_VERSION, UrlPaths.PROFILE_TYPES], row);
    };
    ResourceTypeService.prototype.updateProfileType = function (code, row) {
        return this.requestService.put(UrlPaths.OP_DATA_URL_KEY, [UrlPaths.OP_DATA_VERSION, UrlPaths.PROFILE_TYPES, code], row);
    };
    /*********
     Mapped Resource Types
     *********/
    ResourceTypeService.prototype.getMappedResourceTypes = function (criteria) {
        var reqPrams = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.get(UrlPaths.OP_DATA_URL_KEY, [UrlPaths.OP_DATA_VERSION, UrlPaths.MAPPED_RESOURCE_TYPES], reqPrams);
    };
    ResourceTypeService.prototype.getMappedResourceTypeCodes = function () {
        return this.requestService.get(UrlPaths.OP_DATA_URL_KEY, [UrlPaths.OP_DATA_VERSION, UrlPaths.MAPPED_RESOURCE_TYPES, UrlPaths.MAPPED_RESOURCE_TYPE_CODES]);
    };
    ResourceTypeService.prototype.deleteMappedResourceType = function (code) {
        return this.requestService.delete(UrlPaths.OP_DATA_URL_KEY, [UrlPaths.OP_DATA_VERSION, UrlPaths.MAPPED_RESOURCE_TYPES, code]);
    };
    ResourceTypeService.prototype.saveMappedResourceType = function (row) {
        return this.requestService.post(UrlPaths.OP_DATA_URL_KEY, [UrlPaths.OP_DATA_VERSION, UrlPaths.MAPPED_RESOURCE_TYPES], row);
    };
    ResourceTypeService.ngInjectableDef = i0.defineInjectable({ factory: function ResourceTypeService_Factory() { return new ResourceTypeService(i0.inject(i1.ConfigLoader), i0.inject(i2.RequestService), i0.inject(i3.DMCQueryParamsService)); }, token: ResourceTypeService, providedIn: "root" });
    return ResourceTypeService;
}());
export { ResourceTypeService };
