import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ConfigLoader } from '@tc-core/util/framework';
import { IGetRowsParams } from 'ag-grid-community';
import { Observable, of, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SetupGridComp } from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid-comp';
import { SetupGridDataSource } from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid-data-source';
import { SetupGridComponent } from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid.component';
import { TCO } from '../../../constants';
import { RoomFacilitySearchCriteria } from '../../../models/criteria/room-facility-search-criteria';
import { SortDirection } from '../../../models/helper/sort-direction';
import { AccommodationService } from '../../../services/backend-consumers/setups/accommodation.service';
import { DataKey, DataStoreService } from '../../../services/util/framework/data-store.service';

@Component({
  selector: 'tc-room-facility-setup',
  templateUrl: './room-facility-setup.component.html'
})
export class RoomFacilitySetupComponent extends SetupGridComp implements OnInit, OnDestroy, SetupGridDataSource {

  @ViewChild('setupGrid')
  private setupGrid: SetupGridComponent;
  public colDefConfig = [];

  private roomFacilitySearchCriteria: RoomFacilitySearchCriteria = new RoomFacilitySearchCriteria();

  // required options for grid editors
  // nothing

  private searchCriteriaObserver: Subscription = new Subscription();

  constructor(
      private configLoader: ConfigLoader,
      private dataStoreService: DataStoreService,
      private accommodationService: AccommodationService,
  ) {
    super();
  }

  ngOnInit() {
    this.colDefConfig = this.configLoader.configurations.get(TCO.CONF.CONF_ROOM_FACILITY_SETUP);
    this.subscribeSearchCriteria();
  }

  /**
   * subscribe search criteria changes in search bar
   * then force grid to refresh data
   * grid refresh will be call getRaws method which is using this subscribed criteria for backend call
   */
  subscribeSearchCriteria() {
    this.searchCriteriaObserver = this.dataStoreService.get(DataKey.roomFacilitySearchCriteria)
                                      .subscribe(value => {
                                        this.roomFacilitySearchCriteria = value;
                                        this.setupGrid.runForceSearch();
                                      });
  }

  isDisableGeoClick = (params) => {
    return this.checkRowInvalid(params.data);
  };

  checkRowInvalid(data: any): boolean {
    let event = {
      data: data
    };
    return this.isInvalidRow(event);
  }

  private fillRequiredGridOptionData() {
    // nothing
  }

  private fillDataKeyForEditorData() {
    // nothing
  }

  public isInvalidRow(params): boolean {
    const data = params.data;
    if (data) {
      let invalid = true;
      if (
          data.name
      ) {
        invalid = false;
      }
      return invalid;
    } else {
      return false;
    }
  }

  public createNewRow(): any {
    return {unsaved: true};
  }

  public isUnsavedRow(row: any): boolean {
    return !!row.unsaved;
  }

  public deleteRow(row: any) {
    if (row && row.id) {
      return this.accommodationService.deleteRoomFacility(row.id);
    } else {
      return of('success');
    }
  }

  public saveRow(row: any, event: any) {
    return this.accommodationService.saveRoomFacility(row)
               .pipe(tap(data => row.unsaved = undefined));
  }

  public getRows(params: IGetRowsParams): Observable<any> {
    this.dataStoreService.set(DataKey.roomFacilitySearchResultsForCriteria, null)
    const pageSize = 1000000;
    if (!this.roomFacilitySearchCriteria) {
      this.roomFacilitySearchCriteria = new RoomFacilitySearchCriteria();
    }
    this.roomFacilitySearchCriteria.start = params.startRow;
    this.roomFacilitySearchCriteria.size = pageSize;
    if (params.sortModel && params.sortModel[0]) {
      this.roomFacilitySearchCriteria.sortBy = params.sortModel[0].colId;
      if (params.sortModel[0].sort === SetupGridComp.GRID_SORT_ASCENDING) {
        this.roomFacilitySearchCriteria.sortDirection = SortDirection.ASC;
      } else {
        this.roomFacilitySearchCriteria.sortDirection = SortDirection.DESC;
      }
    }
    return this.accommodationService.getRoomFacilities(this.roomFacilitySearchCriteria)
               .pipe(
                   tap(data =>
                       this.dataStoreService.set(DataKey.roomFacilitySearchResultsForCriteria, data)
                   )
               );
  }

  public ngOnDestroy(): void {
    if (this.searchCriteriaObserver) {
      this.searchCriteriaObserver.unsubscribe();
    }
  }

}
