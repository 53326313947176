<div class="ecshc-card">

    <div class="ecshc-card__wrapper" (click)="onHeaderClick($event)">
        <div class="ecshc-card__icon" *ngIf="isSharewith">
            <div class="tc-border-circle">
                <i class="material-icons">library_books</i>
            </div>
        </div>

        <div class="ecshc-card__content ecshc-content-wrapper">
            <h5 class="ecshc-content-wrapper__heading">
                <ng-container>{{headerText}}</ng-container>
            </h5>

            <ng-container *ngIf="isSharedBooking && isGroupBooking">
                <small class="ecshc-content-wrapper__secondary-heading">{{'Shared'|translate}}
                    | {{'Group booking'|translate}}</small>
            </ng-container>
            <ng-container *ngIf="isSharedBooking && !isGroupBooking">
                <small class="ecshc-content-wrapper__secondary-heading">{{'Shared booking'|translate}}</small>
            </ng-container>
            <ng-container *ngIf="isGroupBooking && !isSharedBooking">
                <small class="ecshc-content-wrapper__secondary-heading">{{'Group booking'|translate}}</small>
            </ng-container>

            <div class="card-chips ecshc-content-wrapper__chip" *ngIf="status">
                <div class="chip active">
                    <span>{{status}}</span>
                </div>
            </div>

            <small
                    class="ecshc-content-wrapper__secondary-heading"
                    *ngIf="subHeaderText && !isNewClient">{{subHeaderText}}
            </small>

        </div>

        <div class="item-status-block" *ngIf="showSync" [ngSwitch]="syncStatus">
      <span *ngSwitchCase="SYNC_STATUS_SUCCESS" class="tc-icon-block__icon tc-icon-block__icon--inactive">
        <i
                class="material-icons mobile"
                [matTooltip]="'Synced' | translate"
                [matTooltipPosition]="toolTipPosition">cloud_done
        </i>
      </span>
            <span *ngSwitchCase="SYNC_STATUS_NOT_SENT" class="tc-icon-block__icon tc-icon-block__icon--active">
        <i
                class="material-icons mobile"
                (click)="onSyncClick($event)"
                [matTooltip]="'Sync' | translate"
                [matTooltipPosition]="toolTipPosition">sync
        </i>
      </span>
            <span *ngSwitchCase="SYNC_STATUS_FAILED" class="tc-icon-block__icon tc-icon-block__icon--active">
        <i
                class="material-icons mobile"
                (click)="onSyncClick($event)"
                [matTooltip]="'Sync' | translate"
                [matTooltipPosition]="toolTipPosition">sync_problem
        </i>
      </span>
            <span *ngSwitchCase="SYNC_STATUS_PENDING" class="tc-icon-block__icon tc-icon-block__icon--inactive">
        <i
                class="material-icons material-icons--fa"
                [matTooltip]="'Pending' | translate"
                [matTooltipPosition]="toolTipPosition">fa_hourglass_start</i>
      </span>
        </div>
        <div class="tc-icon-block ecshc-card__actions">
            <button mat-icon-button class="tc-icon-block__icon tc-icon-block__icon--active"
                    *ngFor="let navItem of navItems" (click)="onGroupActionClick(navItem)"
                    [matTooltip]="navItem.title | translate" [matTooltipPosition]="toolTipPosition"
                    [tcSectionRestricted]="navItem.restricted">
                <i class="material-icons">{{navItem.icon}}</i>
            </button>

            <button mat-icon-button class="tc-icon-block__icon tc-icon-block__icon--active"
                    *ngIf="!hideActions && showBkgAmd" (click)="onBookingAmdClick($event)"
                    [tcActionRestricted]="authConfigComponent+'.BKG_AMND_ACTION'" [matTooltip]="'Amendment' | translate"
                    [matTooltipPosition]="toolTipPosition">
                <i class="material-icons">description</i>
            </button>

            <button mat-icon-button class="tc-icon-block__icon tc-icon-block__icon--active"
                    *ngIf="!hideActions && showDelete" (click)="onDeleteClick($event)"
                    [tcActionRestricted]="authConfigComponent+'.DELETE_ACTION'" [matTooltip]="'Cancel' | translate"
                    [matTooltipPosition]="toolTipPosition">
                <i class="material-icons">delete</i>
            </button>

            <button mat-icon-button class="tc-icon-block__icon tc-icon-block__icon--active"
                    *ngIf="!hideActions && showNewTab" (click)="onNewTabClick($event)"
                    [tcActionRestricted]="authConfigComponent+'.NEW_TAB_ACTION'" [matTooltip]="'New Tab' | translate"
                    [matTooltipPosition]="toolTipPosition">
                <i class="material-icons">open_in_new</i>
            </button>

            <button mat-icon-button class="tc-icon-block__icon tc-icon-block__icon--active"
                    *ngIf="!hideActions && showEdit" (click)="onEditClick($event)"
                    [matTooltip]="'Edit' | translate">
                <i class="material-icons">mode_edit</i>
            </button>

            <button mat-icon-button class="tc-icon-block__icon tc-icon-block__icon--active"
                    *ngIf="!hideActions && showClose" (click)="onCloseClick($event)" [tcActionRestricted]="authConfigComponent+'.CLOSE_ACTION'"
                    [matTooltip]="'Close' | translate" [matTooltipPosition]="toolTipPosition">
                <i class="material-icons">close</i>
            </button>

            <button mat-icon-button class="tc-icon-block__icon tc-icon-block__icon--active"
                    *ngIf="!hideActions && showEditAsNew" (click)="onEditAsNewClick($event)"  [tcActionRestricted]="authConfigComponent+'.EDIT_AS_NEW_ACTION'"
                    [matTooltip]="'Edit as new' | translate" [matTooltipPosition]="toolTipPosition">
                <i class="material-icons">add_to_photos</i>
            </button>

            <button mat-icon-button class="tc-icon-block__icon tc-icon-block__icon--active"
                    *ngIf="!hideActions && showCalendar" (click)="onCalendarClick($event)" [tcActionRestricted]="authConfigComponent+'.AVAILABILITY'"
                    [matTooltip]="'Availability Calendar' | translate" [matTooltipPosition]="toolTipPosition">
                <i class="material-icons">calendar_today</i>
            </button>

            <button mat-icon-button class="tc-icon-block__icon tc-icon-block__icon--active"
                    *ngIf="!hideActions && showMore" (click)="onMoreClick($event)" [tcActionRestricted]="authConfigComponent+'.MORE_ACTION'"
                    [matTooltip]="'More actions' | translate" [matTooltipPosition]="toolTipPosition" >
                <i class="material-icons">more_vert</i>
            </button>

        </div>
    </div>
</div>
<mat-progress-bar
        class="tc-progress--bar ecshc-progress-bar" *ngIf="showProgress === true" mode="indeterminate"
        value="30"></mat-progress-bar>
