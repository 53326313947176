import * as tslib_1 from "tslib";
import { Injector } from '@angular/core';
import * as i0 from "@angular/core";
var GridColumnDefinitionProcessorService = /** @class */ (function () {
    function GridColumnDefinitionProcessorService(injector) {
        this.injector = injector;
    }
    GridColumnDefinitionProcessorService.prototype.createColumnDefs = function (configs, consumer) {
        var e_1, _a;
        var colDef = [];
        try {
            for (var configs_1 = tslib_1.__values(configs), configs_1_1 = configs_1.next(); !configs_1_1.done; configs_1_1 = configs_1.next()) {
                var config = configs_1_1.value;
                this.checkForHandlers(config, null, null, consumer);
                colDef.push(config);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (configs_1_1 && !configs_1_1.done && (_a = configs_1.return)) _a.call(configs_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return colDef;
    };
    GridColumnDefinitionProcessorService.prototype.checkForHandlers = function (obj, parentKey, parent, consumer) {
        var e_2, _a;
        if (obj) {
            try {
                for (var _b = tslib_1.__values(Object.keys(obj)), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var key = _c.value;
                    if (typeof obj[key] === 'object') {
                        this.checkForHandlers(obj[key], key, obj, consumer);
                    }
                    else if (key === 'handler') {
                        this.addFunctionToParent(obj, obj[key], parentKey, parent, consumer);
                    }
                }
            }
            catch (e_2_1) { e_2 = { error: e_2_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_2) throw e_2.error; }
            }
        }
    };
    GridColumnDefinitionProcessorService.prototype.addFunctionToParent = function (obj, handler, parentKey, parent, consumer) {
        if (parent !== null && parentKey !== null) {
            parent[parentKey] = this.handle(handler, obj, consumer);
        }
    };
    GridColumnDefinitionProcessorService.prototype.handle = function (handler, handlerObj, consumer) {
        var split = handler.split('#');
        if (split && split.length > 1) {
            var service = split[0];
            var func = split[1];
            var handlerSer = this.injector.get(service);
            try {
                if (handlerObj['handlerParam']) {
                    return handlerSer[func](handlerObj, consumer);
                }
                else {
                    return handlerSer[func].bind(handlerSer);
                }
            }
            catch (e) {
                console.log(e);
                console.error(service + '.' + func + ' found error');
            }
        }
    };
    GridColumnDefinitionProcessorService.ngInjectableDef = i0.defineInjectable({ factory: function GridColumnDefinitionProcessorService_Factory() { return new GridColumnDefinitionProcessorService(i0.inject(i0.INJECTOR)); }, token: GridColumnDefinitionProcessorService, providedIn: "root" });
    return GridColumnDefinitionProcessorService;
}());
export { GridColumnDefinitionProcessorService };
