import {Injectable} from '@angular/core';
// import { CalendarRefreshEventType } from '@tc/cg-calendar/calendar-refresh-event-type';
// import { CgCalendarRefreshData } from '@tc/cg-calendar/cg-calendar-refresh-data';
// import { DateRange } from '@tc/cg-calendar/date-range';
// import { RangeGroup } from '@tc/cg-calendar/range-group';
import * as moment from 'moment';
import {Moment} from 'moment';
import {BehaviorSubject, Observable} from 'rxjs';
import {CalendarRefreshEventType} from '../cg-calendar/calendar-refresh-event-type';
import {CgCalendarRefreshData} from '../cg-calendar/cg-calendar-refresh-data';
import {DateRange} from '../cg-calendar/date-range';
import {RangeGroup} from '../cg-calendar/range-group';

@Injectable()
export class SeasonCalendarService {

  onChangeSeasons: BehaviorSubject<CgCalendarRefreshData> = new BehaviorSubject(null);
  onChangeSeasonsEvent: Observable<CgCalendarRefreshData> = this.onChangeSeasons.asObservable();

  recentlyAddedDateRange: DateRange = null;

  constructor() { }

  public sortAllDateRanges(rangeGroups: RangeGroup[]) {
    for (let i = 0; i < rangeGroups.length; i++) {
      if (rangeGroups[i].dateRanges) {
        rangeGroups[i].dateRanges.sort(SeasonCalendarService.compare);
      }
    }
  }

  public addNewRangeGroups(rangeGroups: RangeGroup[], newRangeGroups: RangeGroup[], silent: boolean = false) {
    for (let i = newRangeGroups.length - 1; i >= 0; i--) {
      rangeGroups.splice(0, 0, newRangeGroups[i]);
      if (!silent) {
        this.onChangeSeasons.next({type: CalendarRefreshEventType.RangeGroupAdded, data: null});
      }
    }
  }

  public removeRangeGroups(rangeGroups: RangeGroup[], removingRangeGroups: RangeGroup[], silent: boolean = false) {
    for (let i = 0; i < removingRangeGroups.length; i++) {
      this.removeRangeGroup(rangeGroups, removingRangeGroups[i], silent);
    }
  }

  public createNewDateRange(date1: Date, date2: Date): DateRange {
    // create new date range
    let dateRange = new DateRange();
    if (date1 < date2) {
      dateRange.startDate = SeasonCalendarService.getStartDate(date1);
      dateRange.endDate = SeasonCalendarService.getEndDate(date2);
    } else {
      dateRange.startDate = SeasonCalendarService.getStartDate(date2);
      dateRange.endDate = SeasonCalendarService.getEndDate(date1);
    }
    return dateRange;
  }

  // adding new range with processing for merging and filtering other ranges
  public addNewDateRange(
    rangeGroups: RangeGroup[], dateRange: DateRange, mergeNearDateRanges: boolean = true, silent: boolean = false) {

    let cleanedDateRange = this.createNewDateRange(dateRange.startDate, dateRange.endDate);

    let selectedRangeGroup = rangeGroups.find(group => group.selected);

    if (selectedRangeGroup.dateRanges === null) {
      selectedRangeGroup.dateRanges = [];
    }
    // add or merge date range
    this.addOrMergeDateRangeIntoRangeGroup(selectedRangeGroup, cleanedDateRange, mergeNearDateRanges);

    // check for multiple date ranges in single date
    this.checkAddedDateRangeForConflicts(selectedRangeGroup, rangeGroups);

    // sorting date ranges
    selectedRangeGroup.dateRanges.sort(SeasonCalendarService.compare);

    // notifying change
    if (!silent) {
      this.onChangeSeasons.next({type: CalendarRefreshEventType.DateRangeAdded, data: null});
    }
  }

  public createAndAddNewDateRange(
    rangeGroups: RangeGroup[], date1: Date, date2: Date, mergeNearDateRanges: boolean = true) {
    let dateRange = this.createNewDateRange(date1, date2);
    this.addNewDateRange(rangeGroups, dateRange, mergeNearDateRanges);
  }

  public removeDateRange(rangeGroups: RangeGroup[], dateRange: DateRange, silent: boolean = false) {
    let selectedRangeGroup = rangeGroups.find(group => group.selected);
    if (selectedRangeGroup && selectedRangeGroup.dateRanges.indexOf(dateRange) !== -1) {
      selectedRangeGroup.dateRanges.splice(selectedRangeGroup.dateRanges.indexOf(dateRange), 1);

      // notifying change
      if (!silent) {
        this.onChangeSeasons.next({type: CalendarRefreshEventType.DateRangeRemove, data: null});
      }
    }
  }

  public removeRangeGroup(rangeGroups: RangeGroup[], rangeGroup: RangeGroup, silent: boolean = false) {
    if (rangeGroups && rangeGroups.indexOf(rangeGroup) !== -1) {
      rangeGroups.splice(rangeGroups.indexOf(rangeGroup), 1);

      // notifying change
      if (!silent) {
        this.onChangeSeasons.next({type: CalendarRefreshEventType.RangeGroupRemove, data: null});
      }
    }
  }

  public selectRangeGroup(rangeGroups: RangeGroup[], rangeGroup: RangeGroup, silent: boolean = false) {
    if (rangeGroup) {
      rangeGroup.selected = true;
      rangeGroups.forEach(group => {
        if (group !== rangeGroup) {
          group.selected = false;
        }
      });
      if (!silent) {
        this.onChangeSeasons.next({type: CalendarRefreshEventType.RangeGroupSelect, data: null});
      }
    }
  }

  public selectIfWithinAGroup(rangeGroups: RangeGroup[], date: Date): boolean {
    let selected = false;
    for (let i = 0; i < rangeGroups.length; i++) {
      if (selected) {
        break;
      }
      let group = rangeGroups[i];
      if (!group.selected) {
        for (let j = 0; j < group.dateRanges.length; j++) {
          let dateRange = group.dateRanges[j];
          if (SeasonCalendarService.withinRange(date, dateRange)) {
            this.selectRangeGroup(rangeGroups, group);
            selected = true;
            break;
          }
        }
      }
    }
    return selected;
  }

  public static exclusiveWithinRange(d: Date, dateRange: DateRange): boolean {
    return dateRange.startDate.getTime() < d.getTime() && d.getTime() < dateRange.endDate.getTime();
  }

  public static withinRange(d: Date, dateRange: DateRange): boolean {
    return dateRange.startDate.getTime() <= d.getTime() && d.getTime() <= dateRange.endDate.getTime();
  }

  public static nearDates(leftDate: Date, right: Date) {
    return moment(leftDate).add(1, 'day').format('YYYY-MM-DD') === moment(right).format('YYYY-MM-DD');
  }

  public static equalDate(date1: Date, date2: Date): boolean {
    return moment(date1).format('YYYY-MM-DD') === moment(date2).format('YYYY-MM-DD');
  }

  private addOrMergeDateRangeIntoRangeGroup(
    rangeGroup: RangeGroup, newDateRange: DateRange, mergeNearDateRanges: boolean) {
    if (mergeNearDateRanges) {

      let fullyWithinRanges: DateRange[] = [];
      let nearLeftRange: DateRange = null;
      let withinLeftRange: DateRange = null;
      let nearRightRange: DateRange = null;
      let withinRightRange: DateRange = null;

      for (let i = 0; i < rangeGroup.dateRanges.length; i++) {
        let range = rangeGroup.dateRanges[i];

        // check fully within range
        if (SeasonCalendarService.withinRange(range.startDate, newDateRange) &&
          SeasonCalendarService.withinRange(range.endDate, newDateRange)) {
          fullyWithinRanges.push(range);
        }
        // check within left range
        else if (SeasonCalendarService.withinRange(range.endDate, newDateRange)) {
          withinLeftRange = range;
        }
        // check within right range
        else if (SeasonCalendarService.withinRange(range.startDate, newDateRange)) {
          withinRightRange = range;
        }
        // check near left range
        else if (SeasonCalendarService.nearDates(range.endDate, newDateRange.startDate)) {
          nearLeftRange = range;
        }
        // check near right range
        else if (SeasonCalendarService.nearDates(newDateRange.endDate, range.startDate)) {
          nearRightRange = range;
        }
      }

      let removingRanges: DateRange[] = [];

      // fully within ranges will be removed
      if (fullyWithinRanges.length > 0) {
        removingRanges = removingRanges.concat(fullyWithinRanges);
      }

      // has near left range
      if (nearLeftRange) {
        if (withinRightRange) {
          nearLeftRange.endDate = withinRightRange.endDate;
          removingRanges = removingRanges.concat(withinRightRange);
        } else if (nearRightRange) {
          nearLeftRange.endDate = nearRightRange.endDate;
          removingRanges = removingRanges.concat(nearRightRange);
        } else {
          nearLeftRange.endDate = newDateRange.endDate;
        }
        this.recentlyAddedDateRange = nearLeftRange;
      }
      // has within left range
      else if (withinLeftRange) {
        if (withinRightRange) {
          withinLeftRange.endDate = withinRightRange.endDate;
          removingRanges = removingRanges.concat(withinRightRange);
        } else if (nearRightRange) {
          withinLeftRange.endDate = nearRightRange.endDate;
          removingRanges = removingRanges.concat(nearRightRange);
        } else {
          withinLeftRange.endDate = newDateRange.endDate;
        }
        this.recentlyAddedDateRange = withinLeftRange;
      }
      // no left near or within ranges only near right range
      else if (nearRightRange) {
        nearRightRange.startDate = newDateRange.startDate;
        this.recentlyAddedDateRange = nearRightRange;
      }
      // no left near or within ranges only within right range
      else if (withinRightRange) {
        withinRightRange.startDate = newDateRange.startDate;
        this.recentlyAddedDateRange = withinRightRange;
      }
      // no any near or within ranges in left or right
      else {
        rangeGroup.dateRanges.push(newDateRange);
        this.recentlyAddedDateRange = newDateRange;
      }

      if (removingRanges.length) {
        for (let j = 0; j < removingRanges.length; j++) {
          let range = removingRanges[j];
          if (rangeGroup.dateRanges.indexOf(range) !== -1) {
            rangeGroup.dateRanges.splice(rangeGroup.dateRanges.indexOf(range), 1);
          }
        }
      }
    } else {
      // adding as a new range
      rangeGroup.dateRanges.push(newDateRange);
      this.recentlyAddedDateRange = newDateRange;
    }
  }

  private checkAddedDateRangeForConflicts(selectedRangeGroup: RangeGroup, rangeGroups: RangeGroup[]) {
    let removingDateRanges: DateRange[] = [];
    let addedOneWithinARange = false;

    for (let i = 0; i < rangeGroups.length; i++) {
      let group = rangeGroups[i];
      for (let j = 0; j < group.dateRanges.length; j++) {
        let dateRange = group.dateRanges[j];

        if (dateRange !== this.recentlyAddedDateRange) {

          //check added one fully within a existing range
          if (SeasonCalendarService.withinRange(this.recentlyAddedDateRange.startDate, dateRange) &&
            SeasonCalendarService.withinRange(this.recentlyAddedDateRange.endDate, dateRange)) {
            addedOneWithinARange = true;
          }

          // fully within range
          else if (SeasonCalendarService.withinRange(dateRange.startDate, this.recentlyAddedDateRange) &&
            SeasonCalendarService.withinRange(dateRange.endDate, this.recentlyAddedDateRange)) {
            removingDateRanges.push(dateRange);
          }

          // within range end date
          else if (SeasonCalendarService.withinRange(dateRange.endDate, this.recentlyAddedDateRange)) {
            let dateRangePart = new DateRange();
            dateRangePart.startDate = this.recentlyAddedDateRange.startDate;
            dateRangePart.endDate = dateRange.endDate;
            removingDateRanges.push(dateRangePart);
          }

          // within range start date
          else if (SeasonCalendarService.withinRange(dateRange.startDate, this.recentlyAddedDateRange)) {
            let dateRangePart = new DateRange();
            dateRangePart.startDate = dateRange.startDate;
            dateRangePart.endDate = this.recentlyAddedDateRange.endDate;
            removingDateRanges.push(dateRangePart);
          }
        }
      }
    }

    removingDateRanges.sort(SeasonCalendarService.compare);
    if (addedOneWithinARange && selectedRangeGroup.dateRanges.indexOf(this.recentlyAddedDateRange) !== -1) {
      selectedRangeGroup.dateRanges.splice(selectedRangeGroup.dateRanges.indexOf(this.recentlyAddedDateRange), 1);
    }
    SeasonCalendarService.removeDateRangesFromDateRange(
      this.recentlyAddedDateRange,
      removingDateRanges,
      selectedRangeGroup
    );
  }

  private static compare(r1: DateRange, r2: DateRange) {
    if (r1.startDate.getTime() < r2.startDate.getTime()) {
      return -1;
    }
    if (r1.startDate.getTime() > r2.startDate.getTime()) {
      return 1;
    }
    return 0;
  }

  private static removeDateRangesFromDateRange(
    dateRange: DateRange, sortedRemovingRanges: DateRange[], selectedRangeGroup: RangeGroup) {
    if (dateRange) {
      for (let i = 0; i < sortedRemovingRanges.length; i++) {
        const removingRange: DateRange = sortedRemovingRanges[i];
        if (SeasonCalendarService.equalDate(removingRange.startDate, dateRange.startDate)) {
          const startDateForNewPart = moment(removingRange.endDate).add(1, 'day');
          if (startDateForNewPart.isAfter(dateRange.endDate)) {
            selectedRangeGroup.dateRanges.splice(selectedRangeGroup.dateRanges.indexOf(dateRange), 1);
          } else {
            dateRange.startDate = SeasonCalendarService.getStartDateM(startDateForNewPart);
          }
        } else if (SeasonCalendarService.exclusiveWithinRange(removingRange.startDate, dateRange)) {
          const newDateRange = new DateRange();
          newDateRange.startDate = dateRange.startDate;
          newDateRange.endDate = SeasonCalendarService.getEndDateM(moment(removingRange.startDate).add(-1, 'day'));
          selectedRangeGroup.dateRanges.push(newDateRange);

          const dateForNextPart = moment(removingRange.endDate).add(1, 'day');
          if (dateForNextPart.isAfter(dateRange.endDate, 'day')) {
            selectedRangeGroup.dateRanges.splice(selectedRangeGroup.dateRanges.indexOf(dateRange), 1);
          } else {
            dateRange.startDate = SeasonCalendarService.getStartDateM(dateForNextPart);
          }
        } else if (SeasonCalendarService.equalDate(removingRange.endDate, dateRange.endDate)) {
          dateRange.endDate = SeasonCalendarService.getEndDateM(moment(removingRange.startDate).add(-1, 'day'));
        }
      }
      dateRange = null;
    }
  }

  private static getStartDate(date: Date): Date {
    return moment(date).startOf('day').toDate();
  }

  private static getEndDate(date: Date): Date {
    return moment(date).endOf('day').toDate();
  }

  private static getStartDateM(date: Moment): Date {
    return date.startOf('day').toDate();
  }

  private static getEndDateM(date: Moment): Date {
    return date.endOf('day').toDate();
  }
}
