import { Component, Input, OnInit } from '@angular/core';
import { TcErrorType } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-error';
import { DialogModel } from '@tc-core/model/it/codegen/ui/framework/dialog-model';
import { LEVEL } from '@tc-core/model/it/codegen/ui/framework/modal-data';
import { DialogService } from '@tc/dialog/dialog.service';
import { ReservationSearchCriteria } from '../../../models/criteria/reservation-search-criteria';
import { SplitByPassengerGroupCriteria } from '../../../models/criteria/split-by-passenger-group-criteria';
import { PassengerGroup } from '../../../models/reservation/passenger-group';
import { ServiceItem } from '../../../models/reservation/service-item';
import { ServicePassenger } from '../../../models/reservation/service-passenger';
import { S_PassengerGroup, SplitPassengerData, SplitPassengerDataHeader } from '../../../models/reservation/split-passenger-data';
import { TrsReservationManagementService } from '../../../services/backend-consumers/reservation-management-services/trs-reservation-management.service';
import { DMCCommon } from '../../../services/util/common/dmc-common';
import { DataKey, DataStoreService } from '../../../services/util/framework/data-store.service';
import { ResponseUtil } from '../../../services/util/response/response-util.service';

@Component({
    selector: 'tc-split-passengers-view-wrapper',
    templateUrl: './split-passengers-view-wrapper.component.html'
})
export class SplitPassengersViewWrapperComponent implements OnInit {

    @Input() serviceItem: ServiceItem;
    transferMode: string;
    passengerData: SplitPassengerData[] = [];
    selectedGroupPassengerData: SplitPassengerData[] = [];
    passengerDataHeadings: SplitPassengerDataHeader[];
    passengerGroups: S_PassengerGroup[] = [];
    passengers: ServicePassenger[];
    isRequestCompleted = true;
    searchCriteria: ReservationSearchCriteria;

    splitPassengerGroups: {};
    passengersPerGroup: number = 0;

    groupingOptionList: any[] = [];

    constructor(
        private dataStore: DataStoreService,
        private reservationService: TrsReservationManagementService,
        private dialogService: DialogService,
        private dataStoreService: DataStoreService,
        private common: DMCCommon
    ) { }

    ngOnInit() {
        console.log(this.serviceItem);
        this.transferMode = this.serviceItem.trsServiceItem.transferMode;
        this.setPassengerGroups();
        this.generatePassengerData();
        this.getSearchCriteria();
    }

    getSearchCriteria() {
        const x = this.dataStoreService.get(DataKey.reservationSelectedGroupCriteria).subscribe((data) => {
            if (data !== null) {
                this.searchCriteria = data;
            }
        });
    }

    public onClosePopup($event: any) {
        this.retrieveSelectedGroup(false);
        this.dataStore.set(DataKey.popupClose, true);
    }

    generatePassengerData() {
        this.passengerDataHeadings = [
            {name: 'Passenger Name'},
            {name: 'Type'},
            {name: 'Group'}
        ];

        this.passengers = this.serviceItem.servicePassengers;

        if (this.passengers && this.passengers.length) {
            this.passengers.forEach(
                passenger => {
                    let data = new SplitPassengerData();
                    data.id = passenger.passengerId;
                    data.name = passenger.firstName + ' ' + passenger.lastName;
                    data.type = this.getPassengerType(passenger.type);
                    data.needAssistance = false;
                    data.group = new S_PassengerGroup();

                    this.passengerData.push(data);
                }
            );
        }
        this.selectedGroupPassengerData = this.passengerData;
    }

    private getPassengerType(type: any): string {
        switch (type) {
            case 'A':
                return 'Adult';
            case 'C':
                return 'Child';
            case 'I':
                return 'Infant';
            default:
                return '';
        }
    }

    public selectGroup($event: any) {
        let passenger = $event.passenger;
        let groupCode = $event.group;
        let group = this.passengerGroups.find(g => g.code === groupCode);
        passenger.group = group;

        if (this.getGroupAllocationCount(groupCode) > this.passengersPerGroup) {
            let confirmSuccess = new DialogModel(
                true,
                LEVEL.WARNING,
                'Pax size exceeded',
                `You have allocated more passengers into ${group.name} than specified`,
                true,
                2000
            );
            this.dialogService
                .confirm(confirmSuccess)
                .subscribe((res) => {
                    if (res === true) {
                    }
                });
        }

        console.log(passenger);
    }

    public onSplit($event: any) {
        let paxPerGroup = $event['paxPerGroup'];
        this.passengersPerGroup = paxPerGroup;
        if (typeof (paxPerGroup) == 'number' && paxPerGroup > 0 && this.passengers.length > paxPerGroup) {
            let serviceItemId = this.serviceItem.serviceItemId;

            let splitByPassengerGroupCriteria = new SplitByPassengerGroupCriteria();
            splitByPassengerGroupCriteria.paxPerGroup = paxPerGroup;
            this.reservationService.splitServiceItem(serviceItemId, splitByPassengerGroupCriteria)
                .subscribe(
                    data => {
                        let dataArray = ResponseUtil.getDataArray<PassengerGroup>(data);
                        this.splitPassengerGroups = SplitPassengersViewWrapperComponent.toMap(dataArray);
                        this.setPassengerGroups();
                        console.log(this.passengerData);
                        console.log(this.passengerGroups);
                    }
                );
        } else {
            let confirmSuccess = new DialogModel(
                true,
                LEVEL.WARNING,
                'Invalid Pax count',
                'Pax per group should be lower than total passengers',
                true,
                2000
            );
            this.dialogService
                .confirm(confirmSuccess)
                .subscribe((res) => {
                    if (res === true) {
                    }
                });
        }
    }

    private static toMap(passengerGroups: PassengerGroup[]) {
        let map = {};
        for (let passengerGroup of passengerGroups) {
            map[passengerGroup.groupId]=passengerGroup.passengers
        }
        return map;
    }

    private setPassengerGroups() {
        let groupNamePostFix = 1;
        this.passengerGroups = [];

        this.groupingOptionList = [];
        this.groupingOptionList.push({'code': -1, 'value': 'All Groups', 'isSelected': true});
        if (this.splitPassengerGroups) {
            for (let key in this.splitPassengerGroups) {
                let group = new S_PassengerGroup();
                group.code = Number(key);
                group.name = 'Group ' + group.code;
                this.groupingOptionList.push({'code': group.code, 'value': group.name, 'isSelected': false});
                this.passengerGroups.push(group);
                let itemPassengers: ServicePassenger[] = this.splitPassengerGroups[group.code];
                itemPassengers.forEach(
                    passenger => {
                        let found = this.passengerData.find(
                            p => p.id == passenger.passengerId
                        );
                        if (found) {
                            // group is used. just to debug
                            found.group.code = group.code;
                            found.group.name = group.name;

                            found.assignedGroup = group.code;
                        }
                    }
                );
            }

        }
    }

    retrieveSelectedGroup(isChanged: boolean) {
        this.dataStoreService.set(DataKey.popupDataChanged, isChanged, true);
        /*this.reservationManagementService.retrieveServiceItems(this.searchCriteria).subscribe(data => {
         this.dataStoreService.set(DataKey.popupDataChanged, true, true);
         });*/
    }

    public onSave($event: any) {
        let confirmSaveSplit = new DialogModel(
            true,
            LEVEL.WARNING,
            'Save Changes',
            'Do you want to save the split items',
            true,
            2000,
            null,
            'No',
            'Yes'
        );
        confirmSaveSplit.disableClose = true;
        this.dialogService
            .confirm(confirmSaveSplit)
            .subscribe((res) => {
                if (res === true) {

                    this.isRequestCompleted = false;
                    this.allocatePassengersIntoServiceItems();
                    console.log(this.splitPassengerGroups);
                    let serviceItemId = this.serviceItem.serviceItemId;
                    this.reservationService.saveSplitServiceItems(serviceItemId, this.splitPassengerGroups).subscribe(
                        result => {
                            this.isRequestCompleted = true;
                            //fetch again the selected serviceItem group;
                            this.retrieveSelectedGroup(true);

                            this.common.showSnackBar('Successfully saved split items', 3000, TcErrorType.TYPE.INFO);

                            this.dataStore.set(DataKey.popupClose, true);

                            // let confirmSuccess = new DialogModel(
                            //     true,
                            //     LEVEL.SUCCESS,
                            //     'Success',
                            //     'Successfully saved split items',
                            //     true,
                            //     2000
                            // );
                            // this.dialogService
                            //     .confirm(confirmSuccess)
                            //     .subscribe((res) => {
                            //         if (res === true) {
                            //             console.log('true');
                            //             /* this.dataStore.set(DataKey.cart, null, true);
                            //              this.spinnerService.show();
                            //              */
                            //             this.dataStore.set(DataKey.popupClose, true);
                            //
                            //         }
                            //     });
                        },
                        error => {
                            this.isRequestCompleted = true;
                            console.log(error);
                            let confirmError = new DialogModel(
                                true,
                                LEVEL.ERROR,
                                'Failed',
                                'Failed to save split items',
                                true,
                                2000
                            );
                            this.dialogService
                                .confirm(confirmError)
                                .subscribe((res) => {
                                    if (res === true) {
                                        console.log('true');
                                        /* this.dataStore.set(DataKey.cart, null, true);
                                         this.spinnerService.show();
                                         */
                                    }
                                });
                        }
                    );
                    // this.dataStore.set(DataKey.popupClose, true);

                }
            });
    }

    private allocatePassengersIntoServiceItems() {
        for (let key in this.splitPassengerGroups) {
            this.splitPassengerGroups[Number(key)] = [];
        }
        this.passengerData.forEach((splitPassenger: SplitPassengerData) => {
            let passengerRawData = this.passengers.find(
                p => p.passengerId === splitPassenger.id
            );
            this.splitPassengerGroups[splitPassenger.assignedGroup].push(passengerRawData);
        });
    }

    private getGroupAllocationCount(groupCode) {
        this.allocatePassengersIntoServiceItems();
        let count = 0;
        this.passengerData.forEach(
            passenger => {
                if (passenger.assignedGroup == groupCode) {
                    count++;
                }
            }
        );
        return count;
    }

    public onSelectGroupingType($event: any) {
        if ($event === -1) {
            this.selectedGroupPassengerData = this.passengerData;
        } else {
            this.selectedGroupPassengerData = this.passengerData.filter(value => {
                    return value.assignedGroup === $event;
                }
            );
        }
    }
}
