import { TrsResourceCost } from '../supplier/trs-resource-cost-setup';

export class SupplierSummaryDepricated {
    supplierId: number;
    code: string;
    name: string;
    country: string;
    city: string;
    currency: string;
    unitFare: number;
    childFare: number;
    adultFare: number;
    infantFare: number;
    totalCost: number = 0; // related to edited cost of supplier
    estimatedTotalCost: number; //related to original cost of supplier
    estimatedUnitCost: number;
    resourceCostSummaries: TrsResourceCost[];
    transferMode: string;
    route: string;
    capacity: number;
    isUnitFare: boolean;
    serviceDate: string;
    exceedCapacity: boolean;
    vehicleCost: number;
    recommendedScore: number;
    nullSupplierForClearing:boolean=false;
    kcCompany: string;
    kcDivision: string;
    kcBrand: string;
    kcDistributionChannel: string;
}

export class SupplierCostSummary {
    totalCost: number;
    unitFare: number;
    adultFare: number;
    childFare: number;
    infantFare: number;
    teenFare: number;
}
