import { KeyControl } from '../common/key-control';
import {PaymentInfo} from '../common/payment-info';
import {ResourceCollection} from './collection/resource-collection';
import {Room} from './room';
import {RoomGroupAvailability} from './room-group-availability';
import {Season} from './season';
import {TaxScheme} from './tax-scheme';
import {SupplierAttributes} from '../common/supplier-attributes';
import {ContactDetails} from '../profile/contact-details';
import {DocumentData} from '../profile/document';


export class Supplier extends KeyControl{
    supplierId: number;
    code: string;
    name: string;
    locale: any;
    localeName: string;
    registrationNum: string;
    licenseNum: string;
    streetAddress: string;
    postCode: string;
    country: string;
    countryName: string;
    state: string;
    stateName: string;
    city: string;
    cityName: string;
    activeStatus: boolean;
    supplierType: string;
    supplierTypeName: string;
    businessType: SupplierBusinessType;
    profileType: string;
    serviceRequired: boolean;
    seasons: Season[];
    supplierItems: SupplierItems[];
    contactDetails: ContactDetails[];
    documents: DocumentData[];
    paymentInfo: PaymentInfo;
    taxSchemes: TaxScheme[];
    supplierAttributes: SupplierAttributes[];
    resourceCategory: number;
    capacity: number;
    rooms: Room[];
    roomGroupAvailabilities: RoomGroupAvailability[];
    resourceCollection: ResourceCollection;
    preference: number;
    grade: number;
    kcCompanies: [];
    kcBrands: [];
    kcDistributionChannels: [];
    kcDivisions: [];
    isEditTriggered = false;
}

export class SupplierItems {
    id: number;
    code: string;
}

export namespace Locale {
    export enum TYPE {'English', 'Danish', 'French','German','Italian','Japanese','Russian','Spanish','Portuguese','Mandarin-Chinese'}
}

export enum ResourceCategory {
    TRANSPORT = 1,
    GENERIC = 0
}

export enum SupplierBusinessType {
    INDEPENDENT = 'INDEPENDENT',
    INTERNAL = 'INTERNAL',
    EXTERNAL = 'EXTERNAL'
}

export class Grade {
    order: number;
    scores: number[];
    code: string;
    displayName: string;
}
