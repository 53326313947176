import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
// import { CalendarNavData } from '@tc/cg-calendar/calendar-nav-data';
import { Observable } from 'rxjs';
import { CalendarNavData } from '../calendar-nav-data';
import { RangeGroup } from '../range-group';
// import { RangeGroup } from '@tc/cg-calendar/range-group';

@Component({
  selector: 'tc-body-view-year',
  templateUrl: './body-view-year.component.html'
})
export class BodyViewYearComponent implements OnInit {

  @Input() calendarNavData: CalendarNavData;
  @Input() yearsArray: number[] = [];
  @Input() rangeGroups: RangeGroup[] = [];
  @Input() calendarRefreshEvent: Observable<any>;

  @Output() selectDate: EventEmitter<Date> = new EventEmitter();

  constructor() {}

  ngOnInit() {
  }

  onSelectDate($event: Date) {
    this.selectDate.emit($event);
  }

}
