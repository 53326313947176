<div
        class="container-fluid content-summary-card"
        [ngClass]="{ 'content-summary-card--warning':isUpdated,'content-summary-card--danger':isCancelled}">
    <div class="row  tc-item--center">
        <div class="col-xs-12 col-sm-12 col-md-2">
            <div class="tc-flex-box">
                <div class="tc-flex-item">
                    <tc-resource
                            *ngIf="summaryContent.icon"
                            id="{{summaryContent.icon.key}}"
                            [key]="summaryContent.icon.key"
                            [value]="summaryContent.icon.value"
                            [style]="summaryContent.icon.style"
                            [iconType]="summaryContent.icon.iconType">
                    </tc-resource>
                </div>
                <div
                        class="tc-flex-item"
                        *ngIf="summaryContent?.resourceName">
                    <div
                            *ngIf="summaryContent?.resourceName" class="tc-strip-text"
                            [ngClass]="row1col1_customsStyles"
                            id="RESOURCE_NAME">
                        {{summaryContent?.resourceName}}
                    </div>
                    <!--          <div-->
                    <!--                  class="rs-item-card__text-group" [ngClass]="row3col1_customsStyles"-->
                    <!--                  id="PASSENGER_COUNT">-->
                    <!--            <i class="material-icons rs-item-card__icon">seat_empty</i>-->
                    <!--            <span class="rs-txt-tertiary">{{summaryContent?.totalCapacity}}</span>-->
                    <!--          </div>-->
                    <div
                            *ngIf="summaryContent?.supplierName"
                            class="tc-strip-text"
                            [ngClass]="row2col1_customsStyles"
                            id="supplierName">
                        {{summaryContent?.supplierName}}
                    </div>
                </div>
            </div>
        </div>
        <!--        <div class="col-xs-12 col-sm-12 col-md-8">-->
        <!--            <div class="tc-strip-timeline" #timelinePart (resized)="onResize($event)" >-->
        <!--                    <tc-availability-timeline-->
        <!--                            [assignmentData]="getTimelineEvents()"-->
        <!--                            [noOfUnits]="noOfUnits"-->
        <!--                            [unitSize]="unitSize"-->
        <!--                            [unitWidth]="unitWidth"-->
        <!--                            [targetIndex]="targetUnitIndex"-->
        <!--                            [targetNoOfUnits]="targetNoOfUnits"-->
        <!--                            [targetMargin]="targetMargin"-->
        <!--                            [timelineStartTime]="timelineStartTime"-->
        <!--                            [summaryContent]="summaryContent"-->
        <!--                            [isBulkAssignment]="isBulkAssignment"-->
        <!--                            (targetClick)="onTargetClick(summaryContent)"-->
        <!--                            (eventClick)="onEventClick($event)"-->
        <!--                            class="tc-strip-timeline__ruler">-->
        <!--                    </tc-availability-timeline>-->
        <!--                </div>-->


        <!--        </div>-->
        <div class="col-xs-12 col-sm-12 col-md-8" style="padding-bottom: 2px;">

            <!--      <div *ngIf="summaryContent?.existingAssignments?.length>0" class="row  tc-item&#45;&#45;center">-->
            <!--        <div  class="col-xs-12 col-sm-6 tc-flex tc-flex&#45;&#45;center-vertical">-->
            <!--          <label class="tc-strip-text">Start Time: </label>-->
            <!--          <input-->
            <!--                  [value]="jobStartTime?jobStartTime:defaultJobStartTime"-->
            <!--                  (keyup.enter)="onInputChange($event,true)"-->
            <!--                  (focusout)="onInputChange($event,true)"-->
            <!--                  (focusin)="jobStartTime===defaultJobStartTime?jobStartTime='':''"-->
            <!--                  style="width: 40px; border: none">-->
            <!--          <div class="tc-icon-block">-->
            <!--            <button class="tc-m-0 tc-icon-block__icon tc-icon-block__icon&#45;&#45;active"-->
            <!--                    mat-button-->
            <!--                    mat-icon-button-->
            <!--                    aria-label="more"-->
            <!--                    (click)="startTimePicker.open()">-->
            <!--              <mat-icon matTooltip="Select Start Time">schedule</mat-icon>-->
            <!--            </button>-->
            <!--          </div>-->
            <!--          <ngx-material-timepicker [ngxMaterialTimepickerTheme]="theme" [format]="24" (timeSet)="setTimes($event,true)"  #startTimePicker></ngx-material-timepicker>-->
            <!--        </div>-->
            <!--        <div class="col-md-5"></div>-->
            <!--        <div class="col-xs-12 col-sm-6 tc-flex tc-flex&#45;&#45;center-vertical">-->
            <!--          <label class="tc-strip-text">End Time: </label>-->
            <!--          <input-->
            <!--                  [value]="jobEndTime?jobEndTime:defaultJobEndTime"-->
            <!--                  (keyup.enter)="onInputChange($event,false)"-->
            <!--                  (focusout)="onInputChange($event,false)"-->
            <!--                  (focusin)="jobEndTime===defaultJobEndTime?jobEndTime='':''"-->
            <!--                  style="width: 40px; border: none">-->

            <!--          <div class="tc-icon-block">-->
            <!--            <button class="tc-m-0 tc-icon-block__icon tc-icon-block__icon&#45;&#45;active"-->
            <!--                    mat-button-->
            <!--                    mat-icon-button-->
            <!--                    aria-label="more"-->
            <!--                    (click)="endTimePicker.open()">-->
            <!--              <mat-icon matTooltip="Select End Time">schedule</mat-icon>-->
            <!--            </button>-->
            <!--          </div>-->
            <!--          <ngx-material-timepicker [ngxMaterialTimepickerTheme]="theme" [format]="24" (timeSet)="setTimes($event,false)"  #endTimePicker></ngx-material-timepicker>-->
            <!--        </div>-->
            <!--      </div>-->
            <tc-timeline
                    [summaryContent]="summaryContent"
                    [isTargetBlock]="isBulkAssignment"
                    [leftBufferHours]="leftBufferHours"
                    [rightBufferHours]="rightBufferHours"
                    [targetPointTime]="targetPointTime"
                    [targetBlockTimeStart]="targetBlockTimeStart"
                    [targetBlockTimeEnd]="targetBlockTimeEnd"
                    [events]="timelineEvents"
                    (targetClick)="onTargetClick($event)"
                    (eventClick)="onEventClick($event)">
            </tc-timeline>
        </div>

        <!--    <div-->
        <!--            class="col-xs-12 col-sm-12 col-md-2"-->
        <!--            *ngIf="summaryContent?.trsResourceCostSummaries">-->
        <!--      <div-->
        <!--              *ngIf="hasSelectedCost(summaryContent?.trsResourceCostSummaries)"-->
        <!--              class="tc-strip-text"-->
        <!--              [ngClass]="row1col4_customsStyles"-->
        <!--              id="TOTAL_COST">{{summaryContent?.calculatedCost?.totalCost |-->
        <!--              tcCurrencyPipe:currencyFormat?.currencyCode:"code":currencyFormat?.pattern}}-->
        <!--      </div>-->
        <!--      <div-->
        <!--              *ngIf="selectedCost?.unitFare" class="tc-strip-text" [ngClass]="row2col4_customsStyles"-->
        <!--              id="RESOURCE_COST">Resource Cost: {{selectedCost?.unitFare |-->
        <!--              tcCurrencyPipe:currencyFormat?.currencyCode:"code":currencyFormat?.pattern}}-->
        <!--      </div>-->
        <!--      <a-->
        <!--              class="rs-r-align tc-link__value tc-link__value&#45;&#45;regular"-->
        <!--              (click)="onResourceCalendarClick(summaryContent)">Resource Calendar</a>-->
        <!--    </div>-->
        <div
                class="col-xs-12 col-sm-12 col-md-2"
                *ngIf="summaryContent?.calculatedCost">
            <!--<div class="col-4-content-wraper">-->
            <div
                    *ngIf="summaryContent?.calculatedCost?.totalCost"
                    class="tc-strip-text"
                    [ngClass]="row1col4_customsStyles"
                    id="SUPPLIER_TOTAL_COST">{{summaryContent?.calculatedCost?.totalCost |
                tcCurrencyPipe:currencyFormat?.currencyCode:"code":currencyFormat?.pattern}}
            </div>
            <div
                    *ngIf="summaryContent?.calculatedCost.unitCost"
                    class="tc-strip-text"
                    [ngClass]="row2col4_customsStyles"
                    id="SUPPLIER_RESOURCE_COST">{{'Resource Cost:' | translate}} {{summaryContent?.calculatedCost.unitCost |
                tcCurrencyPipe:currencyFormat?.currencyCode:"code":currencyFormat?.pattern}}
            </div>
            <div
                    *ngIf="!summaryContent?.calculatedCost.unitCost"
                    class="tc-strip-text"
                    [ngClass]="row2col4_customsStyles"
                    id="SUPPLIER_PERSON_COST">{{'Adt:' | translate}} {{summaryContent?.calculatedCost?.totalAdultCost.toFixed(2) }} | Cld: {{summaryContent?.calculatedCost?.totalChildCost.toFixed(2) }} | Inf: {{summaryContent?.calculatedCost?.totalInfantCost.toFixed(2) }}
            </div>
            <a
                    class="rs-r-align tc-link__value tc-link__value--regular"
                    (click)="onResourceCalendarClick(summaryContent)">{{'Resource Calendar' | translate}}</a>
        </div>
    </div>
</div>
<hr>

