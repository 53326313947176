import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
    MatAutocompleteModule,
    MatCardModule, MatCheckboxModule, MatChipsModule, MatDatepickerModule, MatDialogModule,
    MatExpansionModule, MatFormFieldModule, MatIconModule,
    MatInputModule,
    MatMenuModule, MatNativeDateModule, MatOptionModule,
    MatSelectModule, MatTooltipModule
} from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AdditionalContactEditCardComponent } from './additional-contact-edit-card.component';

@NgModule({
    imports: [
        CommonModule,
        MatAutocompleteModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatDatepickerModule,
        MatDialogModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule, MatMenuModule,
        MatNativeDateModule,
        MatOptionModule,
        MatSelectModule, MatTooltipModule,
        FormsModule,
        ReactiveFormsModule
    ],
    declarations: [AdditionalContactEditCardComponent],
    exports: [AdditionalContactEditCardComponent]
})
export class AdditionalContactEditCardModule {
}
