import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InsuranceInformationComponent } from './insurance-information.component';
import { TranslateModule } from '@ngx-translate/core';
import {
    MatAutocompleteModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatNativeDateModule,
    MatOptionModule,
    MatSelectModule,
    MatSlideToggleModule
} from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from '@tc/pipes';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        MatInputModule,
        ReactiveFormsModule,
        MatSlideToggleModule,
        MatSelectModule,
        FormsModule,
        MatAutocompleteModule,
        MatFormFieldModule,
        PipesModule,
        MatOptionModule,
        MatDatepickerModule,
        MatNativeDateModule
    ],
    declarations: [InsuranceInformationComponent],
    exports: [InsuranceInformationComponent]
})
export class InsuranceInformationModule {}
