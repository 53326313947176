import { Injectable } from '@angular/core';
import { Booking } from '../../models/reservation-v2/booking';
import { BookingItem } from '../../models/reservation-v2/booking-item';

@Injectable({
    providedIn: 'root'
})
export class BookingUtilService {
    constructor() {
    }

    public setBookingReferenceId(booking: Booking, bookingItem: BookingItem, type: 'trs' | 'gen' | 'accom', itemNumber?: number) {
        let maxItemNumber = 0;
        if (itemNumber > 0) {
            maxItemNumber = itemNumber - 1;
        } else {
            if (booking && booking.bookingItems) {
                for (const bi of booking.bookingItems) {
                    if (bi.bookingSourceItemNo > maxItemNumber) {
                        maxItemNumber = bi.bookingSourceItemNo;
                    }
                }
            }
        }

        bookingItem.bookingSourceItemNo = maxItemNumber + 1;
        const bookingSource = booking.bookingSource ? booking.bookingSource : '';
        const bookingId = booking.bookingId ? (bookingSource ? (bookingSource + '_' + booking.bookingId) : booking.bookingId) : '';
        let productType = '';
        switch (type) {
            case 'trs':
                productType = '_TRS';
                break;
            case 'gen':
                productType = '_GEN';
                break;
            case 'accom':
                productType = '_ACCOM';
                break;
        }
        bookingItem.bookingReferenceId = bookingId + (bookingId ? '_' : '') + bookingItem.bookingSourceItemNo + productType;
    }

}
