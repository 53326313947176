import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CalendarNavData } from '../calendar-nav-data';
import { CalendarMenuItem } from './calendar-menu-item';
// import { CalendarMenuItem } from '@tc/cg-calendar/calendar-header-controller/calendar-menu-item';
// import { CalendarNavData } from '@tc/cg-calendar/calendar-nav-data';

@Component({
  selector: 'tc-calendar-header-controller',
  templateUrl: './calendar-header-controller.component.html'
})
export class CalendarHeaderControllerComponent implements OnInit {

  @Input() calendarNavData: CalendarNavData;
  @Input() calendarMenuItems: CalendarMenuItem[] = [];
  @Input() validCalendarOptions: any[];

  @Output() menuItemClick: EventEmitter<CalendarMenuItem> = new EventEmitter();
  @Output() selectCalendarType: EventEmitter<any> = new EventEmitter();
  @Output() clickNextBtn: EventEmitter<any> = new EventEmitter();
  @Output() clickPreviousBtn: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  public onMenuItemClick(menuItem: CalendarMenuItem) {
    this.menuItemClick.emit(menuItem);
  }

  public onSelectCalendarType($event: any) {
    this.selectCalendarType.emit($event);
  }

  public onClickNextBtn() {
    this.clickNextBtn.emit();
  }

  public onClickPreviousBtn() {
    this.clickPreviousBtn.emit();
  }
}
