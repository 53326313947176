export class DriverSupplierCostSummary {
    supplierId: number;
    code: string;
    name: string;
    country: string;
    city: string;
    currency: string;
    resourceType: string;
    profileType: string;
    days: string;
    seasonId: number;
    seasonName: string;
    totalCost: number;
    estimatedTotalCost: number;
    unitFare: number;
    adultFare: number;
    childFare: number;
    infantFare: number;
    teenFare: number;
    capacity: number;
    isUnitFare: boolean;
}