import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormArray, FormBuilder, FormGroup} from '@angular/forms';
import {RouteInformation} from '../../../../models/master-data/route-information';
import {locationGroupSetup} from '../../../../models/master-data/location-group-setup';
import { RoutesSetupService } from '../../../../services/backend-consumers/deprecated/routes-setup.service';
import { DataStoreService } from '../../../../services/util/framework/data-store.service';

@Component({
    selector: 'tc-route-setup',
    templateUrl: './route-setup.component.html'
})
export class RouteSetupComponent implements OnInit {
    routeSetupForm: FormGroup;
    routeInfor: FormArray;
    newRoute = new RouteInformation();
    locationGroups: locationGroupSetup[];
    noOfCosts = 0;

        @Output() changeFormValidity: EventEmitter<boolean> = new EventEmitter();

    constructor(
        private fb: FormBuilder,
        private dataStore: DataStoreService,
        private routeService: RoutesSetupService
    ) {
        this.createForm();
    }

    ngOnInit() {
        this.routeSetupForm.valueChanges.subscribe(
            (data) => {
                if (JSON.stringify(data) !== JSON.stringify({})) {
                    this.newRoute = data.routeData;
                    console.log(this.newRoute);
                    this.changeFormValidity.emit(this.routeSetupForm.valid);
                }

            }
        );
        this.routeService.retrieveLocationGroups().subscribe(data => {
            this.locationGroups = data;
        });
    }

    createForm() {
        this.routeSetupForm = this.fb.group({
            routeData: this.fb.array([this.createRoute()])
        });
    }
    createRoute(): FormGroup {
        /*if(this.routeInfor) {
            this.dataStore.set(DataKey.resourceCosts, this.routeInfor.controls.length, true);
        }*/
        return this.fb.group({
            routeCode: [''],
            routeName: [''],
            fromLocationGroup: [''],
            toLocationGroup: ['']
        });
    }

    addRoute() {
        this.routeInfor = this.routeSetupForm.get('routeData') as FormArray;
        if(this.routeSetupForm.get(['routeData',this.routeInfor.controls.length - 1, 'routeName']).value != "") {
            this.routeInfor.push(this.createRoute());
        }
    }
    saveRoute(){
        console.log(this.newRoute);
    }
}
