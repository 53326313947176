import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TcApiError } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-api-error';
import { TcHttpError } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-http-error';
import { CheckBox } from '@tc-core/model/it/codegen/ui/framework/nav-item';
import { Pager } from '@tc-core/model/it/codegen/ui/framework/pager';
import { ResultsHeader } from '@tc-core/model/it/codegen/ui/framework/results-header';
import { Sorter } from '@tc-core/model/it/codegen/ui/framework/sorter';
import { SummaryCardData } from '@tc-core/model/it/codegen/ui/framework/summary-card-data';
import { SorterService } from '@tc-core/service/sorters';
import { ConfigLoader, EventManager, ResultsHeaderService, UserJourneyManager } from '@tc-core/util/framework';
import { CommonHelper } from '@tc-core/util/helpers';
import { SpinnerService } from '@tc-core/util/ui';
import { FocusViewService } from '@tc/focus-view/focus-view.service';
import { Subscription } from 'rxjs';
import { TCO } from '../../constants';
import { LeaveSearchCriteria } from '../../models/criteria/leave-search-criteria';
import { ReservationSearchCriteria } from '../../models/criteria/reservation-search-criteria';
import { LeaveSetupResultItemView } from '../../models/leave-setup-result-item-view';
import { ResourceAvailabilityService } from '../../services/backend-consumers/supplier-service/resource-availability.service';
import { RootService } from '../../services/util/core-services/root.service';
import { DataKey, DataStoreService } from '../../services/util/framework/data-store.service';
import { ResponseUtil } from '../../services/util/response/response-util.service';
import { CardDataHandlerService } from '../../services/util/ui/card-data-handler-service.service';

@Component({
    selector: 'tc-leave-setup-search-results',
    templateUrl: './leave-setup-search-results.component.html'
})
export class LeaveSetupSearchResultsComponent implements OnInit {

    @Input() selectedResourceIds: number[] = [];
    @Input() selectedCardData: Array<LeaveSetupResultItemView> = [];

    @Output() selectResource: EventEmitter<any> = new EventEmitter();

    public contentCardDataList: Array<LeaveSetupResultItemView> = [];
    // pagination
    public defaultPageResultCount = 10;
    public pager: Pager;
    selectionIndex = '';
    tPagination: any;
    fetchedResultsCount: number = 0;
    dataFromMoreClick = false;
    paginatorHasMore = true;
    isCleared = false;
    public resultsHeaderData: ResultsHeader;
    public isSearchInProgress = true;
    public cardType = 'icon';
    public selectAllResources = false;
    public noResultsText = 'No Results Found';
    public selectedResources: any = {};

    private cardConfig: any;
    private resourceSearchResults = [];
    private resultOffSet: number;

    private bulkSelectionEnabled = false;
    private isMoreClicked = false;
    private hasMore = false;

    sortingHandler: any;

    leaveSetupSearchCriteria: LeaveSearchCriteria;

    private searchResultObserver: Subscription;

    constructor(
        public sorterService: SorterService,
        private dataStore: DataStoreService,
        private spinnerService: SpinnerService,
        private commonHelper: CommonHelper,
        private cardDataHandlerService: CardDataHandlerService,
        private configLoader: ConfigLoader,
        private rootService: RootService,
        private resourceAvailabilityService: ResourceAvailabilityService,
        private userJourneyManager: UserJourneyManager,
        private eventManager: EventManager,
        private focusViewService: FocusViewService,
        private resultsHeader: ResultsHeaderService
    ) { }

    ngOnInit() {
        this.userJourneyManager.canProceed.next(false);
        this.tPagination = this.configLoader.configurations.get(TCO.CONF.CONF_PAGINATION_CONFIG)['RESOURCE_SEARCH'];

        this.setupPaginator();
        this.subscribeSearchCriteria();

        this.observeSetupSearchResults();

    }

    private observeSetupSearchResults() {
        this.searchResultObserver = this.dataStore.get(DataKey.leaveSetupSearchResults).subscribe((value) => {
            if (this.commonHelper.dataValidity(value)) {
                let searchResults = ResponseUtil.getDataArray(value);
                this.spinnerService.hide();
                this.fetchedResultsCount = searchResults.length;
                // this.preResultLoader.hide();

                // get setup config type
                const setupSearchCriteria: LeaveSearchCriteria = this.rootService.getData(TCO.AppData.LEAVE_SETUP_SEARCH_CRITERIA);
                const setupConfigKey = TCO.CONF.CONF_LEAVE_SETUP_SEARCH_RESULT_CONFIG;

                if (setupConfigKey) {
                    const tempContentCardDataList: Array<LeaveSetupResultItemView> = <Array<LeaveSetupResultItemView>>this.cardDataHandlerService.processCardDataList(
                        setupConfigKey,
                        searchResults
                    );

                    // create search result headers
                    this.createResultHeader(setupConfigKey);

                    // append result to list - since more
                    this.contentCardDataList = [...this.contentCardDataList, ...tempContentCardDataList];
                    this.resourceSearchResults = [...this.resourceSearchResults, ...searchResults];
                    const totalResultCount = this.contentCardDataList.length;
                    this.resultOffSet = totalResultCount;
                    this.resultsHeaderData.heading = totalResultCount + ' Result' + (totalResultCount > 1 ? 's' : '') +
                        ' found.';

                    if (this.pager) {
                        this.pager.currPage = 0;
                    } else {
                        this.pager = new Pager(0, this.defaultPageResultCount);
                    }
                    this.isSearchInProgress = false;

                    if(this.resultOffSet === 1){
                     this.onHeaderActionSelect(true);
                     }
                }
            } else if (this.commonHelper.isEmptyData(value)) {
                if (!this.isMoreClicked) {
                    this.contentCardDataList = [];
                    this.resourceSearchResults = [];
                    this.isMoreClicked = false;
                } else {
                    this.hasMore = false;
                    this.isMoreClicked = false;
                }
                this.isSearchInProgress = false;
                // this.resetProductPromotionSelection();
            } else if (value instanceof TcApiError) {
                this.spinnerService.hide();
                // this.preResultLoader.hide();
                this.isSearchInProgress = false;
                // this.resetProductPromotionSelection();
            } else if (value instanceof TcHttpError) {
                this.spinnerService.hide();
                // this.preResultLoader.hide();
                this.isSearchInProgress = false;
                // this.resetProductPromotionSelection();
            } else {
                this.isSearchInProgress = true;
                this.spinnerService.hide();
                // const productPromotionSearchCriteria: ContractSetupGETCriteria = this.rootService.getData(TC.AppData.PRODUCT_PROMOTION_SEARCH_CRITERIA);
                // if (productPromotionSearchCriteria) {
                //     productPromotionSearchCriteria.offset = 0;
                // }
                this.contentCardDataList = [];
                this.resourceSearchResults = [];
                this.resultsHeaderData = null;
            }
        });
    }

    /**
     * create search results header
     */
    private createResultHeader(configKey?: string) {
        if (configKey) {
            this.cardConfig = this.configLoader.configurations.get(configKey);
        }
        this.sorterService.sorters = [];
        const sorterMap = new Map<string, Sorter>();
        this.cardConfig.config.placeHolderConfig.forEach(config => {
            if (config && config.sort) {
                const sorter = this.sorterService.createSorter(config);
                const id = config._id;
                sorterMap.set(id, sorter);
            }
        });

        this.resultsHeaderData = this.resultsHeader.loadHeader(this.cardConfig, sorterMap);

        // display bulk selection mode only for package results
        if (configKey === TCO.CONF.CONF_LEAVE_SETUP_SEARCH_RESULT_CONFIG) {
            this.resultsHeaderData['toggleAction'] = this.userJourneyManager.getNavAction(
                'TRIGGER_LEAVE_SETUP_RESULT_SELECTION');
        }
    }
    public onSort(event) {
        this.sorterService.sorter = event;
    }

    public getMultiSelectCheckBox(): CheckBox {
        const checkBox: CheckBox = new CheckBox();
        checkBox.indeterminate = this.selectedResourceIds.length !== 0 && this.selectedResourceIds.length < this.resultOffSet;
        checkBox.checked = this.selectedResourceIds.length === this.resultOffSet;
        /*if(this.resultOffSet === 1){
            this.onHeaderActionSelect(true);
        }*/
        return checkBox;
    }

    /**
     * Select header action
     */
    public onHeaderActionSelect(isSelect: boolean) {
        this.selectAllResources = isSelect;
        this.selectedResourceIds = [];
        this.contentCardDataList.forEach((contentCard: SummaryCardData) => {
            if (contentCard && contentCard.data) {
                const cardSelectEvent = {data: contentCard.data.value, checked: isSelect};
                this.onSelectCard(cardSelectEvent);
            }
        });
    }

    public onSelectCard(event) {

        // set card selected or unselected
        if (event.checked) {
            this.selectedResourceIds.push(event.data.resourceId);
            this.selectedCardData = this.selectedCardData ? this.selectedCardData : [];
            this.selectedCardData.push(event);
        } else {
            const index = this.selectedResourceIds.indexOf(event.data.resourceId);
            this.selectedResourceIds.splice(index, 1);

        }
        if(this.selectedResourceIds && this.selectedResourceIds.length > 0){
            this.selectedCardData = this.contentCardDataList.filter(val => this.selectedResourceIds.includes(val.data.value.resourceId));
            this.selectedResources.resourceIds =  this.selectedResourceIds;
            this.selectedResources.contentCardData = this.selectedCardData;
            this.selectResource.emit(this.selectedResources);
        }

        /*let valueExisted = false;
         this.selectedPackageCodes.forEach((packageCode, index) => {
         if (packageCode === event.data.code) {
         this.selectedPackageCodes.splice(index, 1);
         valueExisted = true;
         }
         });
         if (!valueExisted) {
         this.selectedPackageCodes.push(event.data.code);
         }*/
        if (!this.selectedResourceIds || this.selectedResourceIds.length === 0) {
            this.userJourneyManager.canProceed.next(false);
        } else {
            this.userJourneyManager.canProceed.next(true);
        }

        this.resultsHeaderData.heading = this.selectedResourceIds.length + ' Resource' +
            (this.selectedResourceIds.length > 1 ? 's' : '') + ' selected.';
    }

    /**
     * Set card type - icon or checkbox
     * @param event
     */
    public onHeaderActionClick(event) {
        this.bulkSelectionEnabled = !this.bulkSelectionEnabled;
        if (this.bulkSelectionEnabled) {
            this.cardType = 'checkbox';
            this.resultsHeaderData.heading = this.selectedResourceIds.length + ' Resource' +
                (this.selectedResourceIds.length > 1 ? 's' : '') + ' selected.';
        } else {
            this.cardType = 'icon';
            this.resultsHeaderData.heading = this.contentCardDataList.length + ' Resource' +
                (this.contentCardDataList.length > 1 ? 's' : '') + ' found.';
        }
    }


    public isSelected(contentCard): boolean {
        if (this.commonHelper.hasProperty(contentCard, 'data.value')) {
            const cardId = contentCard.data.value.resourceId;
            return this.selectedResourceIds.indexOf(cardId) > -1;
        }
    }

    subscribeSearchCriteria() {
        this.dataStore.get(DataKey.leaveSetupSearchCriteria).subscribe((data) => {
            if (data !== null) {
                this.leaveSetupSearchCriteria = data;
            }
        });
    }

    // paginator
    private setupPaginator() {
        this.defaultPageResultCount = this.tPagination['defaultPageResultCount'];
        this.pager = new Pager(0, this.defaultPageResultCount);
    }

    public onPage(pageNumber: number) {
        this.pager.currPage = pageNumber;
        this.selectionIndex = (this.pager.currPage * this.pager.itemsPerPage).toString();
    }

    public onSelection(event) {
        this.selectionIndex = event;
    }

    // when click on More in pagination bar
    public onMore() {
        this.dataFromMoreClick = true;
        if (this.tPagination && this.tPagination['defaultPageResultCount'] && this.tPagination['fetchingPages']) {
            // update search criteria size and start
            this.leaveSetupSearchCriteria.size = this.tPagination['fetchingPages'] * this.tPagination['defaultPageResultCount'];
            this.leaveSetupSearchCriteria.start = this.fetchedResultsCount;
            this.searchOnMore(this.leaveSetupSearchCriteria);
        }
    }
    private setPageZero() {
        if (this.pager) {
            if (!this.dataFromMoreClick) {
                this.pager.currPage = 0;
            } else {
                this.dataFromMoreClick = false;
            }
        } else {
            this.pager = new Pager(0, this.defaultPageResultCount);
        }
    }
    searchOnMore(searchCriteria: LeaveSearchCriteria) {
        // service call to search resources
        this.resourceAvailabilityService.searchLeaveSummaries(searchCriteria, DataKey.leaveSetupSearchResults);
    }

    // -- paginator--




}
