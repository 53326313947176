// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.


export const environment = {
    mock: false,
    production: false,
    development: true,
    title: 'dev',
    initial_config: '/config/app_initial.config.json',
    base_config: 'app.config.json',
    i18nResourceLocation: 'assets/i18n/'
};
