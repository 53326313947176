import { Component, TemplateRef, ViewChild } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
    selector: 'tc-actions',
    templateUrl: './actions.component.html'
})
export class ActionsComponent implements ICellRendererAngularComp {
    params: any;
    kebabActionParams: any;
    actions: ActionRendererAction[] = [];
    kebabMenus: KebabActionRendererAction[] = [];
    kebabActions: KebabActions[] = [];
    @ViewChild('actionCell') actionCell: TemplateRef<any>;

    constructor() { }

    public afterGuiAttached(params?: any): void {
    }

    public agInit(params: any): void {
        this.params = params;
        this.actions = params['actions'];
        this.kebabMenus = params['kebabMenus'];
        if ( this.kebabMenus != null && this.kebabMenus !== undefined ){
            this.kebabActions = params['kebabMenus'].kebabActions;
        }
        if (this.kebabActions != null){
            console.log(this.kebabActions.length);
        }
    }

    public refresh(params: any): boolean {
        return false;
    }

    public onClick(action: ActionRendererAction) {
        action.action(this.params);
    }

    public isActionDisabled(action: ActionRendererAction) {
        if (action.disable !== undefined) {
            if (typeof action.disable === 'boolean') {
                return action.disable;
            } else {
                return action.disable(this.params);
            }
        } else {
            return false;
        }
    }

    public isKebabMenuDisabled(kebabMenu: KebabActionRendererAction) {
        if (kebabMenu.kebabDisable !== undefined) {
            if (typeof kebabMenu.kebabDisable === 'boolean') {
                return kebabMenu.kebabDisable;
            } else {
                return kebabMenu.kebabDisable(this.params);
            }
        } else {
            return false;
        }
    }
}

export interface ActionRendererAction {
    icon: string;
    action: any;
    disable: any;
    tooltip: string;
}

export interface KebabActionRendererAction {
    kebabIcon: string;
    kebabDisable: any;
    kebabTooltip: string;
    kebabActions: any;
}

export interface KebabActions {
    actionName: string;
    icon: string;
    action: any;
    disable: any;
    tooltip: string;
}
