/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../shared/side-panel/side-panel.component.ngfactory";
import * as i2 from "../../shared/side-panel/side-panel.component";
import * as i3 from "@tc-core/util/framework/focus-handler.service";
import * as i4 from "../../../services/util/framework/data-store.service";
import * as i5 from "../../../services/backend-consumers/user-service/user-profile.service";
import * as i6 from "../../../services/backend-consumers/notifications/notification.service";
import * as i7 from "@tc-core/util/framework/user-journey-manager.service";
import * as i8 from "@tc-core/util/framework/config-loader.service";
import * as i9 from "@angular/common";
import * as i10 from "./side-nav.component";
import * as i11 from "../../../services/util/core-services/root.service";
import * as i12 from "@tc-core/util/framework/event-manager.service";
import * as i13 from "@tc-core/util/framework/data-store.service";
import * as i14 from "@angular/router";
import * as i15 from "@tc/focus-view/focus-view.service";
import * as i16 from "@tc-core/util/helpers/common-helper.service";
var styles_SideNavComponent = [];
var RenderType_SideNavComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SideNavComponent, data: {} });
export { RenderType_SideNavComponent as RenderType_SideNavComponent };
function View_SideNavComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "tc-dmc-side-panel", [], null, [[null, "select"], [null, "clientSelect"], [null, "cartSelect"], ["document", "keyup"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("document:keyup" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).handleLocalKeyboardEvent($event) !== false);
        ad = (pd_0 && ad);
    } if (("select" === en)) {
        var pd_1 = (_co.onNavSelect($event) !== false);
        ad = (pd_1 && ad);
    } if (("clientSelect" === en)) {
        var pd_2 = (_co.onClientSelect($event) !== false);
        ad = (pd_2 && ad);
    } if (("cartSelect" === en)) {
        var pd_3 = (_co.onCartSelect($event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, i1.View_SidePanelComponent_0, i1.RenderType_SidePanelComponent)), i0.ɵdid(2, 704512, null, 0, i2.SidePanelComponent, [i3.FocusHandlerService, i4.DataStoreService, i5.UserProfileService, i6.NotificationService, i7.UserJourneyManager, i8.ConfigLoader], { client: [0, "client"], navItems: [1, "navItems"], cart: [2, "cart"], cartItemCount: [3, "cartItemCount"], navItemIndex: [4, "navItemIndex"], showSideNav: [5, "showSideNav"], environmentName: [6, "environmentName"], releaseTitle: [7, "releaseTitle"] }, { clientSelect: "clientSelect", cartSelect: "cartSelect", select: "select" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.client; var currVal_1 = _co.navItems; var currVal_2 = _co.shoppingCart; var currVal_3 = _co.cartItemCount; var currVal_4 = _co.selectionIndex; var currVal_5 = _co.showSideNav; var currVal_6 = ""; var currVal_7 = _co.releaseTitle; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }, null); }
export function View_SideNavComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_SideNavComponent_1)), i0.ɵdid(1, 16384, null, 0, i9.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showSidebar; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_SideNavComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-side-nav", [], null, [["document", "click"], ["window", "resize"]], function (_v, en, $event) { var ad = true; if (("document:click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onDocumentClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("window:resize" === en)) {
        var pd_1 = (i0.ɵnov(_v, 1).onResize($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, View_SideNavComponent_0, RenderType_SideNavComponent)), i0.ɵdid(1, 245760, null, 0, i10.SideNavComponent, [i11.RootService, i12.EventManager, i13.DataStore, i7.UserJourneyManager, i14.ActivatedRoute, i3.FocusHandlerService, i15.FocusViewService, i16.CommonHelper], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SideNavComponentNgFactory = i0.ɵccf("tc-side-nav", i10.SideNavComponent, View_SideNavComponent_Host_0, {}, {}, []);
export { SideNavComponentNgFactory as SideNavComponentNgFactory };
