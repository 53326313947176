<div class="adv-client-search" *ngIf="vehiclesResultsList">
  <ng-container *ngFor="let vehicleSummary of vehiclesResultsList |tcAdvPager:pager.currPage:pager.itemsPerPage | tcAdvSorter:sortingHandler:sorter; let i = index;">
    <div class="client-search-result-item-wrapper">
      <div
              tcExpansionPanel
              tabindex="-1"
              [include]="['tc-overlay']"
              [enabled]="true"
              (collapse)="expandedPanelIndex = -1">

        <!-- Content card Strip -->
        <div
                *tcExpansionPanelSummary
                id="clientSumExp_{{i}}">
          <tc-content-strip
                  (actionClick)="onActionClick(vehicleSummary,$event)"
                  (click)="onGenericResourceSelection(vehicleSummary,i)">
            <tc-result-content-card-generic
                    [summaryContent]="vehicleSummary">
            </tc-result-content-card-generic>
          </tc-content-strip>
        </div>

        <div *tcExpansionPanelDetail id="clientDetailExp_{{i}}">
          <tc-expanded-content-card class="tc-expanded-content-card tc-form-view" [isEdit]="false">
            <tc-op-expanded-content-card-header
                    *ngIf="selectedVehicleSummary"
                    [headerText]="getHeader()"
                    [subHeaderText]="getSubHeader()"
                    [showEdit]="true"
                    [showEditAsNew]="true"
                    [showCalendar]="true"
                    (editClick)="onEditClick($event)"
                    (editAsNewClick)="onEditAsNewClick($event)"
                    (headerClick)="onHeaderClick($event)"
                    (calendarClick)="onCalendarClick($event)">
            </tc-op-expanded-content-card-header>

            <tc-vehicle-detail-view
                    *ngIf="selectedVehicleSummary"
                    [resource]="detailedVehicle"
                    [selectedVehicleCost]="selectedVehicleCost"
                    [summaryCardData]="vehicleSummary">
            </tc-vehicle-detail-view>


          </tc-expanded-content-card>
        </div>
      </div>
    </div>
  </ng-container>
</div>

