import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSliderChange } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { ConfigLoader } from '@tc-core/util/framework/config-loader.service';
import { TCO } from '../../../constants';
import {
    CityChange,
    CountryChange,
    CountryStateCityWrapper,
    InitialCountryStateCityCodes,
    StateChange
} from '../../../models/common/country-state-city-wrapper';
import { City, Country, State } from '../../../models/common/locale';
import { ResourceType } from '../../../models/reservation/assignment';
import { Grade, Supplier } from '../../../models/supplier/supplier';
import { UserBusinessType } from '../../../models/user/user-business-type';
import { ChangeDetectorService } from '../../../services/util/change-detector/change-detector.service';
import { IdentifierService } from '../../../services/util/identifier/identifier.service';
import { RegXPatterns } from '../regx-patterns/regx-patterns';

@Component({
    selector: 'tc-supplier-information',
    templateUrl: './supplier-information.component.html'
})
export class SupplierInformationComponent implements OnInit {
    supplierInfoForm: FormGroup;
    activeStatus = false;
    titles: any;
    serviceRequiredStatus = false;
    pageType = '';
    inputDisable = false;
    filteredStateMap: any[] = [];
    filteredCountryMap: any[] = [];
    filteredCityMap: any[] = [];
    selectedLocale: string = '';

    @Input() supplier = new Supplier();

    @Input() titleMap: any[];
    @Input() resourceTypes: any[] = [];
    @Input() selectedResTypes = [];
    @Input() validResource = [];
    @Input() profileTypes = [];
    @Input() supplierTypes = [];
    @Input() localeOptions = [];

    // country state city
    @Input() cityList: City[];
    @Input() stateList: State[];
    @Input() countryList: Country[];
    @Input() countryStateCityChangeMap: Map<number, CountryStateCityWrapper> = new Map();

    @Output() newCountryStateCityObserver: EventEmitter<InitialCountryStateCityCodes> = new EventEmitter();
    @Output() selectCountry: EventEmitter<CountryChange> = new EventEmitter();
    @Output() selectState: EventEmitter<StateChange> = new EventEmitter();
    @Output() selectCity: EventEmitter<CityChange> = new EventEmitter();

    @Output() countryTextChange: EventEmitter<CountryChange> = new EventEmitter();
    @Output() stateTextChange: EventEmitter<StateChange> = new EventEmitter();
    @Output() cityTextChange: EventEmitter<CityChange> = new EventEmitter();
    // --country state city--

    @Output() selectResourceTypes: EventEmitter<any> = new EventEmitter();
    @Output() changeFormValidity: EventEmitter<boolean> = new EventEmitter();
    @Output() changeActiveStatus: EventEmitter<boolean> = new EventEmitter();
    @Output() changeServiceRequiredStatus: EventEmitter<boolean> = new EventEmitter();

    businessTypes = UserBusinessType;
    grades: Grade[];
    maxPreference: number;
    maxGrade = 10;
    gradeNgModel = 0;

    idForLocaleChange: number;

    constructor(
        private fb: FormBuilder,
        private route: ActivatedRoute,
        private identifierService: IdentifierService,
        private changeDetectorService: ChangeDetectorService,
        private configLoader: ConfigLoader,
    ) {

    }

    ngOnInit() {
        console.log(this.businessTypes);
        // country state city
        this.idForLocaleChange = this.identifierService.getNextId();
        // --country state city--

        this.pageType = this.route.snapshot.queryParamMap.get('page').split('_')[0];
        if (this.pageType === 'edit') {
            this.inputDisable = true;
        }

        const gradeConfig = this.configLoader.configurations.get(TCO.CONF.CONF_GRADES_CONFIG);
        if (gradeConfig.grade_values) {
            this.grades = gradeConfig.grade_values.sort((a, b) => (a.order > b.order ? 1 : b.order > a.order ? -1 : 0));
        }
        this.maxGrade = gradeConfig.max_grade ? gradeConfig.max_grade : 10;
        this.maxPreference = this.configLoader.configurations.get(TCO.CONF.CONF_PREFERENCE_CONFIG).MAX_PREFERENCE as number;

        this.createForm();

        this.supplierInfoForm.valueChanges.subscribe(
            (data) => {
                if (JSON.stringify(data) !== JSON.stringify({})) {
                    this.supplier.code = data.code;
                    this.supplier.name = data.name;
                    this.supplier.locale = this.commaSeparatedStringToArray(data.locale);
                    this.supplier.registrationNum = data.registrationNum;
                    this.supplier.licenseNum = data.licenseNum;
                    this.supplier.supplierItems = this.selectedResTypes;
                    this.supplier.streetAddress = data.streetAddress;
                    this.supplier.postCode = data.postCode;
                    this.supplier.activeStatus = this.activeStatus;
                    this.supplier.supplierType = data.supplierType;
                    this.supplier.businessType = data.businessType;
                    this.supplier.serviceRequired = this.serviceRequiredStatus;
                    this.supplier.capacity = data.capacity;
                    this.supplier.preference = data.preference;
                    this.supplier.grade = data.grade;
                    this.getConfigGradeValue(data.grade);
                    // country state city
                    if (typeof (data.country) === 'string' && typeof (data.country) !== 'object') {
                        if (this.clearCountry(data.country)) {
                            data.country = null;
                        }
                        this.countryTextChange.emit({id: this.idForLocaleChange, text: data.country, country: null});
                    }
                    if (typeof (data.state) === 'string' && typeof (data.state) !== 'object') {
                        if (this.clearState(data.state)) {
                            data.state = null;
                        }
                        this.stateTextChange.emit({id: this.idForLocaleChange, text: data.state, state: null});
                    }
                    if (typeof (data.city) === 'string' && typeof (data.city) !== 'object') {
                        if (this.clearCity(data.city)) {
                            data.city = null;
                        }
                        this.cityTextChange.emit({id: this.idForLocaleChange, text: data.city, city: null});
                    }
                    // --country state city--

                    if (this.changeFormValidity) {
                        this.changeFormValidity.emit(this.supplierInfoForm.valid);
                    }
                }
            }
        );

        // country state city
        this.newCountryStateCityObserver.emit(
            {
                id: this.idForLocaleChange,
                cityCode: this.supplier.city,
                stateCode: this.supplier.state,
                countryCode: this.supplier.country
            });
        this.subscribeCountryStateCityChange();
        // --country state city--
        this.validateForm();

        if (this.resourceTypes && this.resourceTypes.length > 0) {
            this.addType(this.validResource);
        }
    }

    commaSeparatedStringToArray(data: any) {
        if (typeof data !== 'undefined' && data != null ) {
            return data.split(',');
        }else {
            return null;
        }
    }

    onInputChange(event: MatSliderChange, type: string)
    {
        switch (type) {
            case 'grade':
                this.supplier.grade = event.value;
                break;
            case 'preference':
                this.supplier.preference = event.value;
                break;
            default:
                console.log('Invalid type');
                break;
        }
    }

    public validateForm() {
        Object.keys(this.supplierInfoForm.controls).forEach(field => {
            const control = this.supplierInfoForm.get(field);
            control.markAsTouched({onlySelf: true});
        });
    }

    private createForm() {
        this.supplierInfoForm = this.fb.group({
            code: [this.supplier ? this.supplier.code : '', Validators.required],
            name: [this.supplier ? this.supplier.name : '', [Validators.required, Validators.pattern(RegXPatterns.ANY_MAX40)]],
            locale: [this.supplier ? this.supplier.locale : ''],
            registrationNum: [this.supplier ? this.supplier.registrationNum : ''],
            licenseNum: [this.supplier ? this.supplier.licenseNum : ''],
            resourceType: [this.supplier ? this.supplier.supplierItems : '', Validators.required],
            streetAddress: [this.supplier ? this.supplier.streetAddress : ''],
            postCode: [this.supplier ? this.supplier.postCode : ''],
            supplierType: [this.supplier ? this.supplier.supplierType : ''],
            businessType: [this.supplier ? this.supplier.businessType : '', Validators.required],
            capacity: [this.supplier ? this.supplier.capacity : ''],
            activeStatus: [this.supplier ? this.supplier.activeStatus : false],
            serviceRequired: [this.supplier ? this.supplier.serviceRequired : false],

            country: [
                '', Validators.compose([Validators.required, StringValidator])
            ],
            state: [
                ''
            ],
            city: [
                '', Validators.compose([Validators.required, StringValidator])
            ],
            preference: [
                this.supplier ? this.supplier.preference : '',
                [Validators.pattern(RegXPatterns.POSITIVE_NUMBERS), Validators.max(this.maxPreference), Validators.min(1)]
            ],
            grade: [
                this.supplier && this.supplier.grade ? this.supplier.grade : 0,
                [Validators.pattern(RegXPatterns.POSITIVE_NUMBERS), Validators.max(this.maxGrade)]
            ]
        });
        this.activeStatus = this.supplier ? this.supplier.activeStatus : false;
        this.serviceRequiredStatus = this.supplier ? this.supplier.serviceRequired : false;
    }

    public toggleActiveStatus(type: boolean)
    {
        this.activeStatus = !this.activeStatus;
        this.supplier.activeStatus = type;
        this.changeActiveStatus.emit(this.activeStatus);
    }

    public toggleServiceRequiredStatus(type: boolean) {
        this.serviceRequiredStatus = !this.serviceRequiredStatus;
        this.supplier.serviceRequired = type;
        this.changeServiceRequiredStatus.emit(this.serviceRequiredStatus);
    }

    addLocale(values) {
        console.log(values);
        this.selectedLocale = this.addArrayToString(values);
        this.supplier.locale = this.selectedLocale;
        console.log(this.selectedLocale);
    }

    addArrayToString(data: any[]) {
        let stringText = '';
        if (data && data.length >= 1) {
            for (let i = 0; i < data.length; i++) {
                if (stringText === '') {
                    stringText = data[i];
                } else {
                    stringText = stringText + ',' + data[i];
                }
            }
        }
        return stringText;
    }

    getErrorMessage(field: string): string {
        switch (field) {
            case 'code' :
                return this.supplierInfoForm.get('code').hasError('required') ? 'Code Required' : '';
            case 'name' :
                return this.supplierInfoForm.get('name').hasError('required') ? 'Name Required' :
                    this.supplierInfoForm.get('name').hasError('pattern') ? 'Name invalid' : '';
            case 'country' :
                return this.supplierInfoForm.get('country').hasError('required') ? 'Country Required' :
                    this.supplierInfoForm.controls['country'].hasError('invalidString') ? 'Country invalid' : '';
            case 'city' :
                return this.supplierInfoForm.get('city').hasError('required') ? 'City Required' :
                    this.supplierInfoForm.controls['city'].hasError('invalidString') ? 'City invalid' : '';
            case 'businessType' :
                return this.supplierInfoForm.get('businessType').hasError('required') ? 'Business Type Required' : '';
            case 'resourceType' :
                return this.supplierInfoForm.get('resourceType').hasError('required') ? 'Resource Type Required' : '';
            case 'preference' :
                return this.supplierInfoForm.get('preference').hasError('pattern') ? 'Preference invalid' :
                    this.supplierInfoForm.get('preference').hasError('max') ? 'Preference invalid' :
                        this.supplierInfoForm.get('preference').hasError('min') ? 'Preference invalid' : '';
            case 'grade' :
                return this.supplierInfoForm.get('grade').hasError('pattern') ? 'Grade invalid' :
                    this.supplierInfoForm.get('grade').hasError('max') ? 'Grade invalid' : '';
            default :
                return '';
        }
    }

    addType(selected: any) {
        if ((this.supplier.taxSchemes.length > 0 && this.supplier.taxSchemes.find(item => !selected.includes(item.resourceType))) ||
            !this.isValidResourceTypes(selected)) {
            this.selectResourceTypes.emit(null);
            this.validResource = [];
            this.selectedResTypes.forEach((item) =>
                this.validResource.push(item.code));
        } else {
            let allTypes = [];
            selected.forEach(val => {
                let found = this.selectedResTypes.find(value => value.code === val);
                if (found) {
                    allTypes.push(found);
                } else {
                    allTypes.push(this.resourceTypes.filter(type => type.code === val)[0]);
                }
            });
            this.supplier.supplierItems = allTypes;
            this.selectResourceTypes.emit(allTypes);
        }
    }

    isValidResourceTypes(selected: any[]) {
        let trsSupplier = this.supplier.seasons.find(item => item.trsResourceCosts !== null && item.trsResourceCosts.length > 0);
        let genSupplier = this.supplier.seasons.find(item => item.genResourceCosts !== null && item.genResourceCosts.length > 0);
        if (trsSupplier && !selected.includes(ResourceType.vehicle)) {
            return false;
        } else if (genSupplier && genSupplier.genResourceCosts.find(item => !selected.includes(item.resourceType))){
            return false;
        } else {
            return true;
        }
    }

    // country state city
    public onCityFocus($event: FocusEvent) {
        this.cityList = [];
        this.cityTextChange.emit({id: this.idForLocaleChange, text: '', city: null});
    }

    public onStateFocus($event: FocusEvent) {
        this.stateList = [];
        this.stateTextChange.emit({id: this.idForLocaleChange, text: '', state: null});
    }

    public onCountryFocus($event: FocusEvent) {
        this.countryList = [];
        this.countryTextChange.emit({id: this.idForLocaleChange, text: '', country: null});
    }

    private clearCountry(countryText: string): boolean {
        if (countryText === '') {
            this.countryStateCityChangeMap.get(this.idForLocaleChange).country = null;
            this.countryStateCityChangeMap.get(this.idForLocaleChange).state = null;
            this.countryStateCityChangeMap.get(this.idForLocaleChange).city = null;
            this.updateCountryStateCityForm();
            return true;
        } else {
            return false;
        }
    }

    private clearState(stateText): boolean {
        if (stateText === '') {
            this.countryStateCityChangeMap.get(this.idForLocaleChange).state = null;
            this.countryStateCityChangeMap.get(this.idForLocaleChange).city = null;
            this.updateCountryStateCityForm();
            return true;
        } else {
            return false;
        }
    }

    private clearCity(cityText): boolean {
        if (cityText === '') {
            this.countryStateCityChangeMap.get(this.idForLocaleChange).city = null;
            this.updateCountryStateCityForm();
            return true;
        } else {
            return false;
        }
    }

    public onSelectCity(city: City) {
        this.selectCity.emit({city: city, id: this.idForLocaleChange, text: null});
    }

    public onSelectState(state: State) {
        this.selectState.emit({state: state, id: this.idForLocaleChange, text: null});
    }

    public onSelectCountry(country: Country) {
        this.selectCountry.emit({country: country, id: this.idForLocaleChange, text: null});
    }

    subscribeCountryStateCityChange() {
        this.changeDetectorService.notifierCountryStateCityChange
            .subscribe(value => {
                if (this.countryStateCityChangeMap.get(this.idForLocaleChange)) {
                    this.updateCountryStateCityForm();
                }
            });
    }

    updateCountryStateCityForm() {
        if (this.countryStateCityChangeMap.get(this.idForLocaleChange)) {

            let state = this.countryStateCityChangeMap.get(this.idForLocaleChange).state;
            if (state && state.code === '' &&
                (this.countryStateCityChangeMap.get(this.idForLocaleChange).country === undefined ||
                    this.countryStateCityChangeMap.get(this.idForLocaleChange).country === null)) {
                state = null;
            }

            this.supplierInfoForm.patchValue({
                country: this.countryStateCityChangeMap.get(this.idForLocaleChange).country,
                state: state,
                city: this.countryStateCityChangeMap.get(this.idForLocaleChange).city
            });

            this.supplier.country = this.countryStateCityChangeMap.get(this.idForLocaleChange).country ?
                this.countryStateCityChangeMap.get(this.idForLocaleChange).country.code :
                '';
            this.supplier.state = this.countryStateCityChangeMap.get(this.idForLocaleChange).state ?
                this.countryStateCityChangeMap.get(this.idForLocaleChange).state.code :
                '';
            this.supplier.city = this.countryStateCityChangeMap.get(this.idForLocaleChange).city ?
                this.countryStateCityChangeMap.get(this.idForLocaleChange).city.code :
                '';
            this.changeFormValidity.emit(this.supplierInfoForm.valid);
        }
    }

    public displayCountryFn(destination: any): any {
        return destination ? destination.name : destination;
    }

    public displayStateFn(destination: any): any {
        return destination ? destination.name : destination;
    }

    public displayCityFn(destination: any): any {
        return destination ? destination.name : destination;
    }

    // -- country state city--

    gradeSelectionOnChange($event: any) {
        let localGrade = 0;
        if (this.maxGrade && this.grades && $event.value !== 0) {
            localGrade = Math.floor(($event.value / this.grades.length) * this.maxGrade);
            this.gradeNgModel = $event.value;
        }
        this.supplierInfoForm.get('grade').setValue(localGrade);
    }

    getConfigGradeValue(grade: number) {
        this.gradeNgModel = Math.ceil((grade / this.maxGrade) * this.grades.length);
    }
}

export function StringValidator(control: AbstractControl) {
    if (!control.value || !control.value.name || (control.value.name === 'NOT SPECIFIED')) {
        return {invalidString: true};
    } else {
        return null;
    }
}
