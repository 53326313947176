/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../services/helpers/chip-handler.service";
import * as i2 from "@tc/chip-input/chip-input.service";
import * as i3 from "@tc/chip-input/shared/parser.service";
import * as i4 from "@tc-core/util/system/date-formatter.service";
import * as i5 from "../../services/util/framework/data-store.service";
import * as i6 from "@tc-core/util/framework/config-loader.service";
import * as i7 from "@tc-core/util/helpers/common-helper.service";
import * as i8 from "../../services/backend-consumers/master-data-handler-service/data-handler.service";
import * as i9 from "./availability-calendar-search-criteria.component";
import * as i10 from "@angular/router";
import * as i11 from "@tc-core/util/framework/user-journey-manager.service";
import * as i12 from "../../services/util/framework/action-handler-service.service";
import * as i13 from "@tc-core/util/framework/event-manager.service";
import * as i14 from "../../services/backend-consumers/supplier-service/resource-availability.service";
import * as i15 from "../../services/util/framework/dmc-query-params.service";
import * as i16 from "@tc-core/util/ui/spinner.service";
import * as i17 from "../../services/util/core-services/root.service";
var styles_AvailabilityCalendarSearchCriteriaComponent = [];
var RenderType_AvailabilityCalendarSearchCriteriaComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AvailabilityCalendarSearchCriteriaComponent, data: {} });
export { RenderType_AvailabilityCalendarSearchCriteriaComponent as RenderType_AvailabilityCalendarSearchCriteriaComponent };
export function View_AvailabilityCalendarSearchCriteriaComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { calendarSearchChipInput: 0 })], null, null); }
export function View_AvailabilityCalendarSearchCriteriaComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "tc-availability-calendar-search-criteria", [], null, null, null, View_AvailabilityCalendarSearchCriteriaComponent_0, RenderType_AvailabilityCalendarSearchCriteriaComponent)), i0.ɵprd(6144, null, "ChipHandlerService", null, [i1.ChipHandlerService]), i0.ɵprd(4608, null, i2.ChipInputService, i2.ChipInputService, [i3.Parser, i4.DateFormatter]), i0.ɵprd(512, null, i1.ChipHandlerService, i1.ChipHandlerService, [i0.Injector, i5.DataStoreService, i6.ConfigLoader, i7.CommonHelper, i4.DateFormatter, i8.DataHandlerService]), i0.ɵdid(4, 245760, null, 0, i9.AvailabilityCalendarSearchCriteriaComponent, [i10.ActivatedRoute, i6.ConfigLoader, i11.UserJourneyManager, i5.DataStoreService, i12.ActionHandlerService, i13.EventManager, i14.ResourceAvailabilityService, i1.ChipHandlerService, i15.DMCQueryParamsService, i7.CommonHelper, i16.SpinnerService, i17.RootService], null, null)], function (_ck, _v) { _ck(_v, 4, 0); }, null); }
var AvailabilityCalendarSearchCriteriaComponentNgFactory = i0.ɵccf("tc-availability-calendar-search-criteria", i9.AvailabilityCalendarSearchCriteriaComponent, View_AvailabilityCalendarSearchCriteriaComponent_Host_0, { isInsideForm: "isInsideForm", calendarSearchCriteria: "calendarSearchCriteria", searchCriteriaChipConfig: "searchCriteriaChipConfig", isSearchBarStyleNotAvailable: "isSearchBarStyleNotAvailable" }, { searchTrigger: "searchTrigger", searchCriteria: "searchCriteria" }, []);
export { AvailabilityCalendarSearchCriteriaComponentNgFactory as AvailabilityCalendarSearchCriteriaComponentNgFactory };
