<div class="tc-sc__side-panel-container">
  <div class="tc-sc__side-panel" [ngClass]="seasonHeaderClass">
    <div class="tc-sc__side-panel-header" (click)="onClickSeasonHeader($event)">
      <span class="tc-sc__side-panel-header-txt">{{'Seasons' | translate}}</span>
      <div class="tc-flex-box tc-icon-block tc-flex--center-vertical">
        <button mat-icon-button class="tc-icon-block__icon sort-icon tc-icon-block__icon--active margin-remove" *ngIf="!viewOnlyMode"
              (click)="onSetSeasonsClick($event)"><mat-icon id="season-settings-btn">settings</mat-icon></button>
        <span class="tc-accordion-header__down-icon"><mat-icon>keyboard_arrow_up</mat-icon></span>
      </div>

    </div>
    <div class="tc-sc__side-panel-body">
      <span class="tc-sc__side-panel-item" *ngIf="!rangeGroups || rangeGroups.length===0">{{'No Seasons Set' | translate}}</span>
      <div
        class="tc-sc__side-panel-item" *ngFor="let rangeGroup of rangeGroups"
        (mouseover)="onMouseOverRangeGroup(rangeGroup)"
        (mouseleave)="onMouseLeaveRangeGroup(rangeGroup)"
        [ngClass]="rangeGroup.selected?'selected-season':''"
        (click)="onSelectSeason(rangeGroup)">
        <div class="tc-sc__side-panel-item-indicator">
          <div class="tc-sc__side-panel-item-color {{rangeGroup.colorId}}"></div>
          <span class="tc-sc__side-panel-item-txt">{{rangeGroup.name}}</span>
        </div>
        <span
          class="tc-icon-block__icon"
          [ngClass]="rangeGroup.nonRemovable?'tc-icon-block__icon--inactive':'tc-icon-block__icon--active'"
          *ngIf="!viewOnlyMode && (rangeGroup.selected || mouseOverRangeGroup===rangeGroup)"
          (click)="onRemoveSeason(rangeGroup)"><mat-icon>delete</mat-icon>
      </span>
      </div>
    </div>

  </div>


  <div class="tc-sc__side-panel" [ngClass]="dateRangeHeaderClass">
    <div class="tc-sc__side-panel-header" (click)="onClickDateRangeHeader($event)">
      <span class="tc-sc__side-panel-header-txt">Date Ranges</span>
      <div class="tc-flex-box tc-icon-block tc-flex--center-vertical">
      <button mat-icon-button class="tc-icon-block__icon sort-icon tc-icon-block__icon--active margin-remove"
        *ngIf="!viewOnlyMode"
        (click)="onSetSeasonRangeClick($event)"><mat-icon id="add-date-range-btn">add</mat-icon></button>
        <span class="tc-accordion-header__down-icon"><mat-icon>keyboard_arrow_up</mat-icon></span>
      </div>
    </div>
    <div class="tc-sc__side-panel-body">
      <span
        class="tc-sc__side-panel-item"
        *ngIf="selectedRangeGroup && (!selectedRangeGroup.dateRanges || selectedRangeGroup.dateRanges.length===0)">{{'No Ranges Set' | translate}}</span>
      <span class="tc-sc__side-panel-item" *ngIf="!rangeGroups || !selectedRangeGroup">{{'No Selected Range' | translate}}</span>
      <mat-chip-list>
        <div
          class="tc-sc__side-panel-item tc-sc__side-panel-item--chip"
          *ngFor="let dateRange of getSelectedDateRanges()">
          <mat-chip
            (click)="onClickDateRange(dateRange)"
            [ngClass]="getSelectedGroupClass()"
            [selectable]="dateRangesSelectable"
            [removable]="!viewOnlyMode"
            (removed)="onRemoveDateRange(dateRange)">
            {{getDateRangeText(dateRange)}}
            <mat-icon matChipRemove *ngIf="!viewOnlyMode">cancel</mat-icon>
          </mat-chip>
        </div>
      </mat-chip-list>
    </div>
  </div>
</div>
