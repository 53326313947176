import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TcErrorType } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-error';
import { DialogModel, LEVEL } from '@tc-core/model/it/codegen/ui/framework/dialog-model';
import { DialogService } from '@tc/dialog/dialog.service';
import { AgGridAngular } from 'ag-grid-angular';
import { CellValueChangedEvent, ICellRendererParams, IGetRowsParams, RowNode } from 'ag-grid-community';
import { forkJoin, Observable } from 'rxjs';
import { DMCCommon } from '../../../../../services/util/common/dmc-common';
import { DataKey, DataStoreService } from '../../../../../services/util/framework/data-store.service';
import {
    GridColumnDefinitionProcessorService
} from '../../../../../services/util/pre-processors/grid-column-definition-processor.service';
import { AutoCompleteEditorComponent } from '../../editors/auto-complete-editor/auto-complete-editor.component';
import { DateEditorComponent } from '../../editors/date-editor/date-editor.component';
import { DropdownEditorComponent } from '../../editors/dropdown-editor/dropdown-editor.component';
import { MaxLengthTerminatorComponent } from '../../editors/max-length-terminator/max-length-terminator.component';
import { MaxLengthValidatorComponent } from '../../editors/max-length-validator/max-length-validator.component';
import { MultiSelectorEditorComponent } from '../../editors/multi-selector-editor/multi-selector-editor.component';
import { RadioButtonEditorComponent } from '../../editors/radio-button-editor/radio-button-editor.component';
import {
    ActionRendererAction,
    ActionsComponent,
    KebabActionRendererAction
} from '../../renderers/actions/actions.component';
import {
    CheckBoxRendererEditorComponent
} from '../../renderers/check-box-selector-renderer/check-box-renderer-editor.component';
import { TemplateRendererComponent } from '../../renderers/template-renderer/template-renderer.component';
import { GridCommonUtil } from '../../utils/grid-common-util';
import { SetupGridComp } from './setup-grid-comp';
import { SetupGridDataSource } from './setup-grid-data-source';

@Component({
    selector: 'tc-setup-grid',
    templateUrl: './setup-grid.component.html'
})
export class SetupGridComponent implements OnInit, OnChanges {

    @Input() itemTypeSingular = 'Item';
    @Input() itemTypePlural = 'Items';
    @Input() gridHeader: any;
    @Input() readOnly = false;
    @Input() checkboxSelection = true;
    @Input() enableCustomAdd = false;
    @Input() disableAdd = false;
    @Input() disableDelete = false;
    @Input() disableRowDelete = false;
    @Input() enableBulkSendButton = false;
    @Input() noAction = false;
    @Input() actions: ActionRendererAction[] = [];
    @Input() headerActions: ActionRendererAction[] = [];
    @Input() kebabMenus: KebabActionRendererAction[] = [];
    @Input() noSelection = false;
    @Input() cellEditingStart = null;
    @Input() actionColumnWidth = 65;
    @Input() frameworkComponents: any = {
        templateRenderer: TemplateRendererComponent,
        checkBoxRendererEditor: CheckBoxRendererEditorComponent,
        dropDownEditor: DropdownEditorComponent,
        multiSelectorEditor: MultiSelectorEditorComponent,
        radioButtonEditor: RadioButtonEditorComponent,
        autoCompleteEditor: AutoCompleteEditorComponent,
        dateEditor: DateEditorComponent,
        maxLengthValidator: MaxLengthValidatorComponent,
        maxLengthTerminator: MaxLengthTerminatorComponent
    };

    @Input() dataSource: SetupGridDataSource;

    @Input() autoSize = false;

    _setupGridComp: SetupGridComp;

    @Input()
    set setupGridComp(setupGridComp: SetupGridComp) {
        this._setupGridComp = setupGridComp;
        this.pushInvalidClassRuleToClassRules();
    }

    @Input() invalidRowClass = 'tc-ag-grid-row--error';

    _colDefConfig: any[];

    @Input()
    set colDefConfig(colDef: any[]) {
        this._colDefConfig = colDef;
        this.setColumnDefs();
    }

    @Input() defaultColDef;
    @Input() gridOptions;

    _rowClassRules = {};

    @Input()
    set rowClassRules(rowClassRules: any) {
        this._rowClassRules = rowClassRules;
        this.pushInvalidClassRuleToClassRules();
    }

    public gridApi;
    public gridColumnApi;
    public columnDefs;
    public searchCriteriaObserver;
    public searchCriteria;
    // overlayLoadingTemplate = `<span class="loading-spinner"></span>`;
    // overlayNoRowsTemplate = `<span class="ag-overlay-no-rows-center">No items</span>`;

    public overlayNoRowsTemplate: any = `<div class="tc-ag-grid-empty">
            <i class="material-icons tc-ag-grid-empty__icon">assignment_error</i>
            <span class="tc-ag-grid-empty__text">No Records Available</span>
        </div>`;
    public overlayLoadingTemplate: any = `<div class="tc-ag-grid-empty">
            <img src="assets/img/loading-tab.gif" class="tc-mb-2" alt="Loading">
            <span class="tc-ag-grid-empty__text">Loading...</span>
        </div>`;

    @ViewChild('agGrid') agGrid: AgGridAngular;
    @Input() rowSelection = 'multiple';

    @Output() rowDoubleClicked: EventEmitter<any> = new EventEmitter();
    @Output() rowClicked: EventEmitter<any> = new EventEmitter();
    @Output() customAddClick: EventEmitter<any> = new EventEmitter();
    @Output() bulkSendClick: EventEmitter<any> = new EventEmitter();

    unsavedRows = [];

    selectableColumn: any = {
        checkboxSelection: this.checkboxSelection,
        headerName: '',
        colId: 'index',
        filter: false,
        minWidth: 70,
        maxWidth: 70,
        pinned: 'left',
        suppressSizeToFit: true
    };

    actionColumn = {
        headerName: '',
        field: 'actions',
        minWidth: this.actionColumnWidth,
        maxWidth: this.actionColumnWidth,
        cellRendererFramework: ActionsComponent,
        headerClass: 'actions-header',
        cellRendererParams: {
            actions: []
        },
        filter: false,
        resizable: false,
        pinned: 'right',
        cellClass: ['actions-column'],
        suppressSizeToFit: true
    };

    kebabMenuColumn = {
        headerName: '',
        field: 'kebabActions',
        minWidth: this.actionColumnWidth,
        maxWidth: this.actionColumnWidth,
        cellRendererFramework: ActionsComponent,
        headerClass: 'actions-header',
        cellRendererParams: {
            kebabMenus: []
        },
        filter: false,
        resizable: false,
        pinned: 'right',
        suppressSizeToFit: true
    };

    deleteAction = {
        icon: 'delete',
        action: this.onDeleteClick.bind(this),
        tooltip: 'Delete',
        disable: this.disableDeleteRow.bind(this)
    };

    // to fix multi search issue at initial loading
    initialData: any[] = [];
    initialDataCount = 0;

    constructor(
        private cd: ChangeDetectorRef,
        private columnDefinitionProcessorService: GridColumnDefinitionProcessorService,
        private translateService: TranslateService,
        private dialogService: DialogService,
        private dataStoreService: DataStoreService,
        private common: DMCCommon
    ) {
        this.translateService.onLangChange.subscribe(() => {
            this.gridApi.refreshHeader();
        });

        this.defaultColDef = {
            filter: false,
            sortable: false,
            resizable: true,
            headerValueGetter: (parameters: ICellRendererParams): string => {
                const headerIdentifier = parameters.colDef.headerName;
                if (headerIdentifier) {
                    return this.translateService.instant(headerIdentifier);
                }
                return '';
            }
        };

        this.gridOptions = {
            cacheBlockSize: 20,
            maxBlocksInCache: 2,
            enableServerSideFilter: true,
            enableServerSideSorting: false,
            rowModelType: 'infinite',
            pagination: true,
            paginationPageSize: 14
        };
    }

    ngOnInit(): void {
        this.checkMandatoryInputs();
        this.gridApi;
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.checkMandatoryInputs();
    }

    private checkMandatoryInputs() {
        if (!this._colDefConfig) {
            throw new Error('Attribute \'colDefConfig\' is required');
        }
        if (!this._setupGridComp) {
            throw new Error('Attribute \'setupGridComp\' is required');
        }
    }

    private pushInvalidClassRuleToClassRules() {
        if (this._setupGridComp) {
            this._rowClassRules[this.invalidRowClass] = this._setupGridComp.isInvalidRow;

        }
    }

    onFirstDataRendered(params) {
        if (this.autoSize) {
            this.autoSizeAll(false);
        } else {
            this.gridApi.sizeColumnsToFit();
        }
    }

    autoSizeAll(skipHeader) {
        const allColumnIds = [];
        this.gridColumnApi.getAllColumns().forEach((column) => {
            allColumnIds.push(column.colId);
        });
        this.gridColumnApi.autoSizeColumns(allColumnIds, skipHeader);
    }

    onGridSizeChange($event) {
        if (this.autoSize) {
            this.autoSizeAll(false);
        } else {
            this.gridApi.sizeColumnsToFit();
        }
    }

    addUnSavedRowsAgainAfterReload() {
        if (this.unsavedRows && this.unsavedRows.length > 0) {
            this.addNewRows(this.unsavedRows);
            this.unsavedRows = [];
        }
    }

    onGridReady($event) {
        this.gridApi = $event.api;
        this.gridColumnApi = $event.columnApi;
        this.gridApi.setFloatingFiltersHeight(38);
        this.gridApi.setHeaderHeight(40);

        const dataSource = {
            getRows: (params_: IGetRowsParams) => {
                this.gridApi.showLoadingOverlay();
                this.dataSource.getRows(params_)
                    .subscribe(
                        (result: any) => {
                            if (result) {
                                this.unsavedRows = [];
                                this.gridApi.setFocusedCell(null, null );
                                this.gridApi.hideOverlay();
                                const data = this._setupGridComp.getDataFromResponse(result);
                                const totalRows = this._setupGridComp.getTotalCountFromResponse(result);
                                params_.successCallback(data, totalRows);
                                if (totalRows === 0) {
                                    this.gridApi.showNoRowsOverlay();
                                }
                                this.addUnSavedRowsAgainAfterReload();

                            }
                        },
                        error => {
                            this.gridApi.hideOverlay();
                            params_.successCallback([], 0);
                        }
                    );
            }
        };
        this.searchCriteriaObserver = this.dataStoreService.get(DataKey.documentQueueSearchCriteria)
                                          .subscribe(value => {
                                              this.searchCriteria = value;
                                              if (this.searchCriteria && this.searchCriteria.isForceSearch &&
                                                  this.searchCriteria.isForceSearch === true) {
                                                  this.gridApi.paginationGoToPage(0);
                                              }
                                          });
        $event.api.setDatasource(dataSource);
    }

    private setColumnDefs() {
        const colDefConfig = JSON.parse(JSON.stringify(this._colDefConfig));
        const coldDefs: any[] = this.columnDefinitionProcessorService.createColumnDefs(colDefConfig, this);
        if (!this.noSelection) {
            coldDefs.unshift(
                this.selectableColumn
            );
        }

        if (!this.noAction) {
            if (!this.readOnly) {
                this.actionColumn.cellRendererParams.actions.push(this.deleteAction);
                this.actionColumn.minWidth = this.actionColumnWidth;
                this.actionColumn.maxWidth = this.actionColumnWidth;
            }
            // adding input actions
            Array.prototype.push.apply(this.actionColumn.cellRendererParams.actions, this.actions);
            if (this.actionColumn.cellRendererParams.actions && this.actionColumn.cellRendererParams.actions.length >
                0) {
                coldDefs.push(
                    this.actionColumn
                );
            }
            // adding input actions for kebab menu
            Array.prototype.push.apply(this.kebabMenuColumn.cellRendererParams.kebabMenus, this.kebabMenus);
            if (this.kebabMenuColumn.cellRendererParams.kebabMenus &&
                this.kebabMenuColumn.cellRendererParams.kebabMenus.length >
                0) {
                coldDefs.push(
                    this.kebabMenuColumn
                );
            }
        }

        this.columnDefs = coldDefs;
        this.cd.detectChanges();
        if (this.gridApi) {
            if (this.autoSize) {
                this.gridApi.autoSizeAllColumns();
            } else {
                this.gridApi.sizeColumnsToFit();
            }
        }
    }

    afterCellValueChange = (event: CellValueChangedEvent) => {
        // todo testing after edit navigation

        // this.cellEditingStopped(event);

        if (!this._setupGridComp.isInvalidRow(event)) {
            if (event.oldValue !== event.newValue) {
                console.log(event);
                event.data.unsaved = true;
                this.saveRowData(event);
            }
        }
        // this.gridApi.tabToNextCell();
    };

    public saveRowData(event: any) {
        if (this.dataSource.saveRow(event.data, event)) {
            this.dataSource.saveRow(event.data, event).subscribe(
                success => {
                    console.log(success);
                    this.unsavedRows = this.getUnsavedUnselectedRows();
                    console.log(this.unsavedRows);
                    this.gridApi.refreshInfiniteCache();
                },
                error => {
                    console.log(error);
                }
            );
        }
        this.refreshCells();
    }

    onKeyDown(event) {
        console.log('key press');
        console.log(event);
    }

    tabToNextCell(params) {
        const previousCell = params.previousCellPosition;
        const lastRowIndex = previousCell.rowIndex;
        let nextRowIndex = params.backwards ? lastRowIndex + 1 : lastRowIndex - 1;
        const renderedRowCount = this.gridApi.getModel().getRowCount();
        if (nextRowIndex < 0) {
            nextRowIndex = 0;
        }
        if (nextRowIndex >= renderedRowCount) {
            nextRowIndex = renderedRowCount - 1;
        }
        const result = {
            rowIndex: nextRowIndex,
            column: previousCell.column,
            floating: previousCell.floating
        };
        return result;
    }

    addNewRow(newRow: any, selectRow: boolean, index = 0) {
        if (this.gridApi) {
            this.gridApi.updateRowData({add: [newRow], addIndex: index});
            if (selectRow) {
                this.selectRow(newRow);
            }
        } else {
            setTimeout(() => this.addNewRow(newRow, selectRow, index), 0);
        }
    }

    addNewRows(newRows: any[], index = 0) {
        for (const newRow of newRows) {
            this.addNewRow(newRow, false, index);
        }
    }

    private addNodes(nodes: RowNode[], index = 0) {
        for (const node of nodes) {
            this.addNewRow(node.data, false, index);
        }
    }

    deleteSelected() {
        const confirmDialog = new DialogModel(
            true,
            LEVEL.WARNING,
            'Delete',
            `Are you sure want to delete selected raws ?`,
            true,
            2000,
            null,
            'No',
            'Yes',
            true
        );
        this.dialogService
            .confirm(confirmDialog)
            .subscribe((res) => {
                if (res === true) {
                    const selectedRows = this.getSelectedNodes();
                    this.unsavedRows = this.getUnsavedUnselectedRows();
                    this.deleteAllInBackend(selectedRows).subscribe(
                        success => {
                            this.gridApi.refreshInfiniteCache();
                            this.gridApi.deselectAll();
                        }
                        // , error => {
                        //     this.gridApi.refreshInfiniteCache();
                        //     this.gridApi.deselectAll();
                        // }
                    );
                }
            });

    }

    refreshCells() {
        this.gridApi.refreshCells();
    }

    deleteAllInBackend(rows: RowNode[]): Observable<any> {
        return forkJoin(
            rows.map((row) => this.dataSource.deleteRow(row.data))
        );
    }

    deleteRow(row: any) {
        const confirmDialog = new DialogModel(
            true,
            LEVEL.WARNING,
            'Delete',
            `Are you sure want to delete selected raw ?`,
            true,
            2000,
            null,
            'No',
            'Yes',
            true
        );
        const subscription = this.dialogService
                                 .confirm(confirmDialog)
                                 .subscribe((res) => {
                                     if (res === true) {
                                         this.unsavedRows = this.getUnsavedRowsOtherThanThis(row);
                                         this.dataSource.deleteRow(row).subscribe(
                                             success => {
                                                 this.gridApi.refreshInfiniteCache();
                                                 this.gridApi.deselectAll();
                                             }
                                             , error => {
                                                 this.gridApi.refreshInfiniteCache();
                                                 this.gridApi.deselectAll();
                                                 if (error['error'].error.details) {
                                                     this.common.showSnackBar(
                                                         error['error'].error.details,
                                                         3000,
                                                         TcErrorType.TYPE.ERROR
                                                     );
                                                 }
                                             }
                                         );
                                     }
                                 });
    }

    getSelectedNodes(): RowNode[] {
        const selectedNodes = [];
        this.gridApi.forEachNode((node: RowNode) => {
            if (node.isSelected()) {
                selectedNodes.push(node);
            }
        });
        return selectedNodes;
    }

    getUnsavedRowsOtherThanThis(row: any): any[] {
        const unSelectedRows = [];
        this.gridApi.forEachNode((node: RowNode) => {
            if (node.data && this._setupGridComp.isUnsavedRow(node.data) && node.data !== row) {
                unSelectedRows.push(node.data);
            }
        });
        return unSelectedRows;
    }

    getUnsavedUnselectedRows(): any[] {
        const unSelectedRows = [];
        this.gridApi.forEachNode((node: RowNode) => {
            console.log(node);
            if (!node.isSelected() && this._setupGridComp.isUnsavedRow(node)) {
                // unSelectedRows.push(node.data);
            }
        });
        return unSelectedRows;
    }

    selectRow(row: any) {
        this.gridApi.forEachNode((node: RowNode) => {
            if (!node.isSelected()) {
                node.setSelected(node.data === row);
            } else {
                node.setSelected(true);
            }
        });
    }

    public onDeleteClick(params: any) {
        this.deleteRow(params.data);
    }

    public disableDeleteRow(params: any) {
        if (!this.disableRowDelete) {
            return this.disableRowDelete;
        } else {
            return !this._setupGridComp.isInvalidRow(params);
        }
    }

    public onAddClick(params: any) {
        const newRow = this._setupGridComp.createNewRow();
        this.addNewRow(newRow, false, 0);
    }

    public onCustomAddClick(params: MouseEvent) {
        this.customAddClick.emit();
    }

    public onDeleteSelectedClick($event: MouseEvent) {
        this.deleteSelected();
    }

    public runForceSearch() {
        if (this.gridApi) {
            this.gridApi.refreshInfiniteCache();
        } else {
            setTimeout(() => this.runForceSearch(), 100);
        }
    }

    cellEditingStopped(event) {
        console.log('cell editing stopped');

        const nextCell = GridCommonUtil.getNextCell(event, true);
        this.gridApi.setFocusedCell(nextCell.rowIndex, nextCell.colKey);

        // problem if tab pressed before closi`ng the editor. tabToNext infinitely
        // this.gridApi.tabToNextCell();
    }

    onClickColOption(event) {
        event.stopPropagation();
    }

    onSelectionChange(event) {
        if (event && event.option) {
            const option = event.option.value;
            const selected = event.option.selected;
            if (this.gridColumnApi) {
                this.gridColumnApi.setColumnVisible(option, selected);
                if (this.autoSize) {
                    this.autoSizeAll(false);
                } else {
                    this.gridApi.sizeColumnsToFit();
                }
            }
        }
    }

    public onCellEditingStart($event: any) {
        if (this.cellEditingStart) {
            this.cellEditingStart($event);
        }
    }

    public onRowDoubleClicked($event: any) {
        this.rowDoubleClicked.emit($event);
    }

    public onRowClicked($event: any) {
        this.rowClicked.emit($event);
    }

    public onBulkSendClick($event: any) {
        this.bulkSendClick.emit($event);
    }

    onRowSelected($event: any) {

    }
}
