import * as tslib_1 from "tslib";
import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { MatSelectionList } from '@angular/material/list';
import { TranslateService } from '@ngx-translate/core';
import { ConfigLoader } from '@tc-core/util/framework/config-loader.service';
import { AgGridAngular } from 'ag-grid-angular';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SetupGridComp } from '../../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid-comp';
import { SortDirection } from '../../../../models/helper/sort-direction';
import { ServiceItemSearchCriteria } from '../../../../models/reservation-v2/criteria/service-item-search-criteria';
import { ReservationV2ManagementService } from '../../../../services/backend-consumers/reservation-v2/reservation-v2-management.service';
import { DataKey, DataStoreService } from '../../../../services/util/framework/data-store.service';
import { OperationV2DataProcessorService } from '../../../../services/util/pre-processors/operation-v2-data-processor.service';
import { ResponseUtil } from '../../../../services/util/response/response-util.service';
import { OperationQueuePanelConfig, OperationQueueProfile } from '../operation-queue-panel-config';
var ServiceQueueComponent = /** @class */ (function () {
    function ServiceQueueComponent(reservationV2ManagementService, translateService, operationV2DataProcessorService, configLoader, dataStoreService) {
        var _this = this;
        this.reservationV2ManagementService = reservationV2ManagementService;
        this.translateService = translateService;
        this.operationV2DataProcessorService = operationV2DataProcessorService;
        this.configLoader = configLoader;
        this.dataStoreService = dataStoreService;
        this.operationPanelConfig = new OperationQueuePanelConfig();
        this.hideOverlay = false;
        this.showRowLoading = false;
        this.columnDefsInput = [];
        this.primaryColumnDefsInput = [];
        this.secondaryColumnDefsInput = [];
        this.allColumnDefsInput = [];
        this.selectedRowsCount = 0;
        this.unsavedRows = [];
        this.selectedProducts = [];
        this.frameworkComponents = {};
        this.autoSize = true;
        this.colResizeDefault = {};
        this.domLayout = 'autoHeight';
        this.overlayNoRowsTemplate = "<div class=\"tc-ag-grid-empty\">\n            <i class=\"material-icons tc-ag-grid-empty__icon\">assignment_error</i>\n            <span class=\"tc-ag-grid-empty__text\">No Records Available</span>\n        </div>";
        this.overlayLoadingTemplate = "<div class=\"tc-ag-grid-empty\">\n            <img src=\"assets/img/loading-tab.gif\" class=\"tc-mb-2\" alt=\"Loading\">\n            <span class=\"tc-ag-grid-empty__text\">Loading...</span>\n        </div>";
        this.gridActions = [];
        this.serviceItemSearchResults = [];
        this.rowDoubleClicked = new EventEmitter();
        this.rowClicked = new EventEmitter();
        this.getRawClass = function (params) {
            if (params.node.rowIndex % 2 === 0) {
                return 'tc-ag-grid-row--even';
            }
        };
        this.afterCellValueChange = function (event) {
            if (event.oldValue !== event.newValue) {
                console.log(event);
                _this.saveRowData(event);
            }
        };
        this.translateService.onLangChange.subscribe(function () {
            _this.gridApi.refreshHeader();
        });
    }
    Object.defineProperty(ServiceQueueComponent.prototype, "primaryColumnDefs", {
        set: function (colDef) {
            this.primaryColumnDefsInput = colDef;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ServiceQueueComponent.prototype, "secondaryColumnDefs", {
        set: function (colDef) {
            this.secondaryColumnDefsInput = colDef;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ServiceQueueComponent.prototype, "allColumnDefs", {
        set: function (colDef) {
            this.allColumnDefsInput = colDef;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ServiceQueueComponent.prototype, "columnDefs", {
        set: function (colDef) {
            var _this = this;
            this.columnDefsInput = colDef;
            if (this.gridApi) {
                setTimeout(function () { return _this.gridApi.sizeColumnsToFit(); }, 0);
            }
        },
        enumerable: true,
        configurable: true
    });
    ServiceQueueComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.operationPanelConfig.currentTab = this.tabIndex;
        this.colResizeDefault = 'shift';
        this.gridOptions = {
            cacheBlockSize: 50,
            maxBlocksInCache: 1,
            enableSorting: true,
            rowModelType: 'infinite',
            rowSelection: 'multiple',
            pagination: true,
            paginationPageSize: 50
        };
        this.defaultColDef = {
            resizable: true,
            headerValueGetter: function (parameters) {
                var headerIdentifier = parameters.colDef.headerName;
                if (headerIdentifier) {
                    return _this.translateService.instant(headerIdentifier);
                }
                return '';
            }
        };
        if (this.gridOptions.defaultColDef) {
            this.gridOptions.defaultColDef.resizable = true;
            this.gridOptions.defaultColDef.sortable = true;
        }
        this.autoSizeAll(false);
    };
    ServiceQueueComponent.prototype.onClickColOption = function (event) {
        event.stopPropagation();
    };
    ServiceQueueComponent.prototype.onSelectionChangeColSelector = function (event) {
        if (event && event.option) {
            var option = event.option.value;
            var selected = event.option.selected;
            if (this.gridColumnApi) {
                this.gridColumnApi.setColumnVisible(option, selected);
                // this.gridApi.sizeColumnsToFit();
                this.autoSizeAll(false);
            }
        }
    };
    ServiceQueueComponent.prototype.autoSizeAll = function (skipHeader) {
        var allColumnIds = [];
        if (this.gridColumnApi) {
            this.gridColumnApi.getAllColumns().forEach(function (column) {
                allColumnIds.push(column.colId);
            });
            // this.gridApi.sizeColumnsToFit();
            this.gridColumnApi.autoSizeColumns(allColumnIds, skipHeader);
        }
    };
    ServiceQueueComponent.prototype.onGridSizeChange = function ($event) {
        this.fixGridColumnSizes();
    };
    ServiceQueueComponent.prototype.onRestoreColDefClick = function () {
        this.columnDefsInput = this.allColumnDefsInput;
        if (this.gridColumnApi) {
            this.gridColumnApi.resetColumnState();
            var allColumnIds_1 = [];
            this.gridColumnApi.getAllColumns().forEach(function (column) {
                allColumnIds_1.push(column.colId);
            });
            this.gridColumnApi.hideColumns(allColumnIds_1, false);
            this.colSelector.selectAll();
        }
    };
    ServiceQueueComponent.prototype.onSelectTimeGrouping = function (event) {
        this.operationPanelConfig.timeGrouping = event;
        // this.loadServiceItems();
    };
    ServiceQueueComponent.prototype.isTimeGroupingVisible = function () {
        if (this.operationPanelConfig.activatedProfile === OperationQueueProfile.TRS_PROFILE) {
            return true;
        }
        else {
            return false;
        }
    };
    ServiceQueueComponent.prototype.doSomeChange = function () {
        this.operationPanelConfig.preventSwitching = !this.operationPanelConfig.preventSwitching;
        if (this.operationPanelConfig.preventSwitching) {
            this.operationPanelConfig.currentTab = this.tabIndex;
        }
    };
    ServiceQueueComponent.prototype.onSelectionChange = function ($event) {
        this.selectedRowsCount = $event.api.getSelectedRows().length;
        this.operationV2DataProcessorService.autoAllocationButtonEnable = this.selectedRowsCount > 0;
        this.operationV2DataProcessorService.confirmButtonEnable = this.selectedRowsCount > 0;
        this.operationV2DataProcessorService.supplierConfirmButtonEnable = this.selectedRowsCount > 0;
        this.operationV2DataProcessorService.supplierRejectButtonEnable = this.selectedRowsCount > 0;
    };
    ServiceQueueComponent.prototype.onGridReady = function ($event) {
        var _this = this;
        this.gridApi = $event.api;
        this.gridColumnApi = $event.columnApi;
        this.autoSizeAll(false);
        var gridDataSource = {
            getRows: function (params) {
                _this.gridApi.showLoadingOverlay();
                var sortBy = params.sortModel && params.sortModel[0] && params.sortModel[0].colId ?
                    params.sortModel[0].colId : null;
                var sortDirection = params.sortModel && params.sortModel[0] && params.sortModel[0].sort ?
                    params.sortModel[0].sort : null;
                _this.getDataRows(params.startRow, params.endRow, sortBy, sortDirection).subscribe(function (result) {
                    if (result) {
                        _this.gridApi.hideOverlay();
                        var data = ResponseUtil.getDataArray(result);
                        var totalRows = ResponseUtil.getTotalCount(result);
                        params.successCallback(data, totalRows);
                        if (totalRows === 0) {
                            _this.gridApi.showNoRowsOverlay();
                        }
                        _this.addUnSavedRowsAgainAfterReload();
                        _this.autoSizeAll(false);
                    }
                }, function (error) {
                    _this.gridApi.hideOverlay();
                    params.successCallback([], 0);
                });
            }
        };
        this.autoSizeAll(false);
        this.gridApi.setDatasource(gridDataSource);
    };
    ServiceQueueComponent.prototype.loadData = function () {
        var _this = this;
        if (this.gridApi) {
            this.gridApi.refreshInfiniteCache();
        }
        else {
            setTimeout(function () { return _this.loadData(); }, 100);
        }
        this.autoSizeAll(false);
    };
    ServiceQueueComponent.prototype.showLoading = function () {
        var _this = this;
        if (!this.hideOverlay) {
            this.showRowLoading = true;
            if (this.gridApi) {
                this.gridApi.showLoadingOverlay();
            }
            else {
                setTimeout(function () { return _this.showLoading(); }, 0);
            }
        }
    };
    ServiceQueueComponent.prototype.hideLoading = function () {
        var _this = this;
        this.hideOverlay = true;
        this.showRowLoading = false;
        if (this.gridApi) {
            this.gridApi.hideOverlay();
        }
        else {
            setTimeout(function () { return _this.hideLoading(); }, 0);
        }
    };
    ServiceQueueComponent.prototype.getDataRows = function (startRow, endRow, sortBy, sortDirection) {
        var _this = this;
        var bookingIds = [];
        this.selectedProducts.forEach(function (productItem) {
            bookingIds.push.apply(bookingIds, tslib_1.__spread(productItem.bookingIdList));
        });
        if (bookingIds.length) {
            var pageSize = endRow - startRow;
            var serviceItemSearchCriteria = new ServiceItemSearchCriteria();
            this.dataStoreService.get(DataKey.productQueueSearchCriteria)
                .subscribe(function (data) {
                if (data && data.itemNumber && data.itemNumber !== '-1' && data.itemNumber.toLowerCase() !== 'any') {
                    // serviceItemSearchCriteria.itemNumber = data.itemNumber;
                }
            });
            serviceItemSearchCriteria.bookingIdList = bookingIds;
            serviceItemSearchCriteria.start = startRow;
            serviceItemSearchCriteria.size = pageSize;
            if (sortBy) {
                serviceItemSearchCriteria.sortBy = sortBy;
                if (sortDirection === SetupGridComp.GRID_SORT_ASCENDING) {
                    serviceItemSearchCriteria.sortDirection = SortDirection.ASC;
                }
                else {
                    serviceItemSearchCriteria.sortDirection = SortDirection.DESC;
                }
            }
            else {
                serviceItemSearchCriteria.sortDirection = null;
            }
            return this.reservationV2ManagementService.searchServiceItems(serviceItemSearchCriteria)
                .pipe(tap(function (data) {
                if (data) {
                    _this.dataStoreService.set(DataKey.queueServiceSearchResults, data.data);
                    _this.serviceItemSearchResults = data.data;
                }
            }));
        }
        else {
            // todo
            return new Observable();
        }
    };
    ServiceQueueComponent.prototype.fixGridColumnSizes = function () {
        if (this.autoSize) {
            this.autoSizeAll(false);
        }
        else {
            this.gridApi.sizeColumnsToFit();
        }
    };
    ServiceQueueComponent.prototype.loadServiceItems = function () {
        var _this = this;
        var e_1, _a;
        this.hideOverlay = false;
        this.showLoading();
        var bookingIds = [];
        try {
            for (var _b = tslib_1.__values(this.selectedProducts), _c = _b.next(); !_c.done; _c = _b.next()) {
                var productItem = _c.value;
                bookingIds.push.apply(bookingIds, tslib_1.__spread(productItem.bookingIdList));
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        if (bookingIds.length) {
            var serviceItemSearchCriteria = new ServiceItemSearchCriteria();
            serviceItemSearchCriteria.bookingIdList = bookingIds;
            this.setupGroupingBasedOnQueueProfile(this.operationPanelConfig.activatedProfile, serviceItemSearchCriteria);
            this.reservationV2ManagementService.searchServiceItems(serviceItemSearchCriteria)
                .subscribe(function (data) {
                if (data) {
                    _this.serviceItemSearchResults = ResponseUtil.getDataArray(data);
                    _this.setDataToGrid(_this.serviceItemSearchResults);
                }
            });
        }
        else {
            this.serviceItemSearchResults = [];
            this.setDataToGrid(this.serviceItemSearchResults);
        }
    };
    ServiceQueueComponent.prototype.setupGridHeight = function (searchSize) {
        if (this.gridApi) {
            if (searchSize > 0) {
                this.gridApi.setDomLayout('autoHeight');
                // @ts-ignore
                // document.querySelector('#serviceGrid').style.height = '';
            }
            else {
                this.gridApi.setDomLayout('normal');
                // @ts-ignore
                // document.querySelector('#serviceGrid').style.height = '';
            }
        }
    };
    ServiceQueueComponent.prototype.setDataToGrid = function (data) {
        var _this = this;
        if (this.gridApi) {
            this.hideLoading();
            this.gridApi.setRowData(data);
            this.setupGridHeight(data.length);
        }
        else {
            setTimeout(function () { return _this.setDataToGrid(data); }, 0);
        }
    };
    ServiceQueueComponent.prototype.setupGroupingBasedOnQueueProfile = function (queueProfile, criteria) {
        switch (queueProfile) {
            case OperationQueueProfile.COMMON_PROFILE:
                criteria.groupByPlCode = true;
                criteria.groupByProductLevel = true;
                criteria.groupByRoute = true;
                criteria.groupByTransferMode = true;
                criteria.groupByHours = undefined;
                break;
            case OperationQueueProfile.TRS_PROFILE:
                criteria.groupByPlCode = true;
                criteria.groupByProductLevel = true;
                criteria.groupByRoute = true;
                criteria.groupByTransferMode = true;
                if (this.operationPanelConfig.timeGrouping) {
                    criteria.groupByHours = this.operationPanelConfig.timeGrouping;
                }
                break;
            case OperationQueueProfile.TOUR_PROFILE:
                criteria.groupByPlCode = true;
                criteria.groupByProductLevel = true;
                criteria.groupByRoute = true;
                criteria.groupByTransferMode = true;
                criteria.groupByHours = undefined;
                break;
        }
    };
    ServiceQueueComponent.prototype.refreshGridView = function () {
        var _this = this;
        if (this.gridApi) {
            setTimeout(function () {
                _this.gridApi.refreshCells({ force: true });
                _this.gridApi.setRowData(_this.serviceItemSearchResults);
            }, 0);
        }
    };
    ServiceQueueComponent.prototype.replaceItem = function (oldServiceItem, replacingItems) {
        if (replacingItems.length > 0) {
            var newServiceItemSearchResults_1 = [];
            this.serviceItemSearchResults.forEach(function (item) {
                if (item.serviceId === oldServiceItem.serviceId) {
                    replacingItems.forEach(function (newItem) { return newServiceItemSearchResults_1.push(newItem); });
                }
                else {
                    newServiceItemSearchResults_1.push(item);
                }
            });
            this.serviceItemSearchResults = newServiceItemSearchResults_1;
            this.setDataToGrid(this.serviceItemSearchResults);
            this.refreshGridView();
        }
    };
    ServiceQueueComponent.prototype.replaceWithAutoAllocatedServiceItems = function (serviceIdList, replacingItems) {
        var _this = this;
        if (replacingItems.length > 0) {
            var newServiceItemSearchResults_2 = [];
            var index_1 = 0;
            this.serviceItemSearchResults.forEach(function (oldItem) {
                var found = false;
                serviceIdList.forEach(function (oldId) {
                    if (oldItem.serviceId === oldId) {
                        found = true;
                        index_1 = _this.serviceItemSearchResults.indexOf(oldItem);
                    }
                });
                if (!found) {
                    newServiceItemSearchResults_2.push(oldItem);
                }
            });
            newServiceItemSearchResults_2.splice.apply(newServiceItemSearchResults_2, tslib_1.__spread([index_1, 0], replacingItems));
            this.serviceItemSearchResults = newServiceItemSearchResults_2;
            this.setDataToGrid(this.serviceItemSearchResults);
            this.refreshGridView();
            this.selectRows(replacingItems);
        }
    };
    ServiceQueueComponent.prototype.selectColumnGroup = function (type) {
        this.gridApi.refreshHeader();
        if (type === 'primary') {
            this.columnDefsInput = this.primaryColumnDefsInput;
        }
        else if (type === 'secondary') {
            this.columnDefsInput = this.secondaryColumnDefsInput;
        }
        this.autoSizeAll(false);
    };
    ServiceQueueComponent.prototype.gridColumnVisibleChange = function (fieldName, isHide) {
        if (this.gridColumnApi) {
            this.gridColumnApi.setColumnVisible(fieldName, !isHide);
        }
        var val = (this.columnDefsInput.find(function (res) { return res.field === fieldName; })).hide = isHide;
    };
    ServiceQueueComponent.prototype.saveRowData = function (event) {
        var _this = this;
        this.saveRow(event.data, event).subscribe(function (success) {
            console.log(success);
            _this.unsavedRows = _this.getUnsavedUnselectedRows();
            _this.gridApi.refreshInfiniteCache();
        }, function (error) {
            console.log(error);
        });
    };
    ServiceQueueComponent.prototype.saveRow = function (row, event) {
        var saveData = row ? row : event;
        return of();
        // this.reservationV2ManagementService.saveLocation(saveData)
        //            .pipe(tap(data => row.unsaved = undefined));
    };
    ServiceQueueComponent.prototype.addUnSavedRowsAgainAfterReload = function () {
        if (this.unsavedRows && this.unsavedRows.length > 0) {
            this.addNewRows(this.unsavedRows);
            this.unsavedRows = [];
        }
    };
    ServiceQueueComponent.prototype.getUnsavedUnselectedRows = function () {
        var _this = this;
        var unSelectedRows = [];
        this.gridApi.forEachNode(function (node) {
            console.log(node);
            if (!node.isSelected() && _this.isUnsavedRow(node)) {
                // unSelectedRows.push(node.data);
            }
        });
        return unSelectedRows;
    };
    ServiceQueueComponent.prototype.isUnsavedRow = function (row) {
        return !!row.unsaved;
    };
    ServiceQueueComponent.prototype.addNewRow = function (newRow, selectRow, index) {
        var _this = this;
        if (index === void 0) { index = 0; }
        if (this.gridApi) {
            this.gridApi.updateRowData({ add: [newRow], addIndex: index });
            if (selectRow) {
                this.selectRow(newRow);
            }
        }
        else {
            setTimeout(function () { return _this.addNewRow(newRow, selectRow, index); }, 0);
        }
    };
    ServiceQueueComponent.prototype.addNewRows = function (newRows, index) {
        var _this = this;
        if (index === void 0) { index = 0; }
        newRows.forEach(function (newRow) {
            _this.addNewRow(newRow, false, index);
        });
    };
    ServiceQueueComponent.prototype.selectRows = function (serviceItems) {
        this.gridApi.forEachNode(function (node) {
            node.setSelected(serviceItems.includes(node.data));
        });
    };
    ServiceQueueComponent.prototype.selectRow = function (row) {
        this.gridApi.forEachNode(function (node) {
            if (!node.isSelected()) {
                node.setSelected(node.data === row);
            }
            else {
                node.setSelected(true);
            }
        });
    };
    ServiceQueueComponent.prototype.isInvalidRow = function (params) {
        var data = params.data;
        if (data) {
            var invalid = true;
            if (data.code && data.name && data.locationType) {
                invalid = false;
            }
            return invalid;
        }
        else {
            return false;
        }
    };
    ServiceQueueComponent.prototype.onRowClicked = function ($event) {
        this.rowClicked.emit($event);
    };
    ServiceQueueComponent.prototype.ngOnDestroy = function () {
        console.log('destroyed service view');
        this.operationV2DataProcessorService.autoAllocationButtonEnable = false;
        this.operationV2DataProcessorService.confirmButtonEnable = false;
        this.operationV2DataProcessorService.supplierConfirmButtonEnable = false;
        this.operationV2DataProcessorService.supplierRejectButtonEnable = false;
    };
    return ServiceQueueComponent;
}());
export { ServiceQueueComponent };
