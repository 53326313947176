<div class="tc-modal-content">
  <div class="container-fluid">

    <div class="row">
      <div class="col-xs-4 remove-bootstrap-padding-mobile">
        <div class="tc-input-edit dcpiec-input-lname">
          <mat-form-field>
            <input
              [disabled]="true"
              [value]="rangeGroup.name"
              matInput
              [placeholder]="'Season'"
              required>
          </mat-form-field>
        </div>
      </div>
      <div class="col-xs-4 remove-bootstrap-padding-mobile">
        <div class="tc-input-edit dcpiec-input-dob">
          <mat-form-field>
            <input
              [max]="newDateRange.endDate"
              matInput
              [matDatepicker]="startDatepicker"
              [placeholder]="'Start Date'"
              [(ngModel)]="newDateRange.startDate"
              required>
            <mat-datepicker-toggle matSuffix [for]="startDatepicker"></mat-datepicker-toggle>
            <mat-datepicker #startDatepicker></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
      <div class="col-xs-4 remove-bootstrap-padding-mobile">
        <div class="tc-input-edit dcpiec-input-dob">
          <mat-form-field>
            <input
              [min]="newDateRange.startDate"
              matInput
              [matDatepicker]="endDatepicker"
              [placeholder]="'End Date'"
              [(ngModel)]="newDateRange.endDate"
              required>
            <mat-datepicker-toggle matSuffix [for]="endDatepicker"></mat-datepicker-toggle>
            <mat-datepicker #endDatepicker></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="tc-modal-footer">
  <button class="button-left tc-mr-2 tc-button-secondary mat-button" (click)="onClose($event)">
    Close
  </button>
  <button class="button-right tc-button-primary mat-button" (click)="onSave($event)" [disabled]="!isEnableSaveButton()">
    Save
  </button>
</div>
