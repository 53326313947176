import * as tslib_1 from "tslib";
import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DialogModel } from '@tc-core/model/it/codegen/ui/framework/dialog-model';
import { LEVEL } from '@tc-core/model/it/codegen/ui/framework/modal-data';
import { DialogService } from '@tc/dialog/dialog.service';
import { Subscription } from 'rxjs';
import { DataKey, DataStoreService } from '../../../../services/util/framework/data-store.service';
import { ResponseUtil } from '../../../../services/util/response/response-util.service';
import { OperationQueuePanelConfig } from '../operation-queue-panel-config';
var ProductQueueComponent = /** @class */ (function () {
    function ProductQueueComponent(dataStore, dialogService, translateService) {
        var _this = this;
        this.dataStore = dataStore;
        this.dialogService = dialogService;
        this.translateService = translateService;
        this.showAllChips = false;
        this.operationPanelConfig = new OperationQueuePanelConfig();
        /* grid data */
        this.productItemsSearchResults = [];
        this.productItemsShowing = [];
        this.numberOfProductItems = 0;
        /* grid config */
        // public overlayNoRowsTemplate: any = `<span class="ag-overlay-no-rows-center">No Products</span>`;
        this.overlayNoRowsTemplate = "<div class=\"tc-ag-grid-empty tc-mt-5\">\n            <i class=\"material-icons tc-ag-grid-empty__icon\">assignment_error</i>\n            <span class=\"tc-ag-grid-empty__text\">No Products Available</span>\n        </div>";
        this.overlayLoadingTemplate = "<div class=\"tc-ag-grid-empty\">\n            <img src=\"assets/img/loading-tab.gif\" class=\"tc-mb-2\" alt=\"Loading\">\n            <span class=\"tc-ag-grid-empty__text\">Loading...</span>\n        </div>";
        this.columnDefs = [];
        this.defaultColDef = {
            resizable: true,
            headerValueGetter: function (parameters) {
                var headerIdentifier = parameters.colDef.headerName;
                if (headerIdentifier) {
                    return _this.translateService.instant(headerIdentifier);
                }
                return '';
            }
        };
        this.colResizeDefault = {};
        this.domLayout = 'normal';
        // expansion panel
        this.expandPanelOpen = false;
        this.productsSelectedFromGrid = [];
        this.productsSelectedFromGridAndChips = [];
        // for clear button
        this.hideClearButton = true;
        this.selectionChanged = new EventEmitter();
        this.allProducts = new EventEmitter();
        this.productSearchResultsSubscription = new Subscription();
        this.getRawClass = function (params) {
            if (params.node.rowIndex % 2 === 0) {
                return 'tc-ag-grid-row--even';
            }
        };
        this.getNoRowsOverlay = function () {
            var text = _this.translateService.instant('No Products Available');
            return "<div class=\"tc-ag-grid-empty tc-mt-5\">\n        <i class=\"material-icons tc-ag-grid-empty__icon\">assignment_error</i>\n            <span class=\"tc-ag-grid-empty__text\">" + text + "</span>\n        </div>";
        };
        this.isExternalFilterPresent = function () {
            // if (this.productsSelectedFromGridAndChips) {
            //     return this.productsSelectedFromGridAndChips.length > 0;
            // } else {
            //     return false;
            // }
            // requirement came to not filter selected items
            return false;
        };
        this.doesExternalFilterPass = function (node) {
            return node.data.selected;
        };
        this.isRawSelectable = function (params) {
            if (_this.operationPanelConfig) {
                return !_this.operationPanelConfig.preventSwitching;
            }
            else {
                return true;
            }
        };
        this.translateService.onLangChange.subscribe(function () {
            _this.gridApi.refreshHeader();
        });
    }
    ProductQueueComponent.prototype.ngOnInit = function () {
        this.subscribeProductSearchResults();
        this.colResizeDefault = 'shift';
        this.operationPanelConfig.productViewOpen = this.expandPanelOpen;
    };
    ProductQueueComponent.prototype.subscribeProductSearchResults = function () {
        var _this = this;
        this.productSearchResultsSubscription = this.dataStore.get(DataKey.productQueueSearchResultsFromService, true)
            .subscribe(function (data) {
            var _a;
            if (data) {
                _this.numberOfProductItems = 0;
                _this.productItemsSearchResults.length = 0;
                _this.productItemsShowing.length = 0;
                _this.productsSelectedFromGrid.length = 0;
                _this.productsSelectedFromGridAndChips.length = 0;
                _this.numberOfProductItems = ResponseUtil.getTotalCount(data);
                _this.productItemsSearchResults = ResponseUtil.getDataArray(data);
                _this.allProducts.emit(_this.productItemsSearchResults);
                (_a = _this.productItemsShowing).push.apply(_a, tslib_1.__spread(_this.productItemsSearchResults));
                _this.hideClearButton = true;
                _this.setProductData();
            }
        });
    };
    ProductQueueComponent.prototype.setupGridHeight = function (searchSize) {
        if (this.gridApi) {
            if (searchSize > 0) {
                this.gridApi.setDomLayout('autoHeight');
                // @ts-ignore
                document.querySelector('#productGrid').style.height = '';
            }
            else {
                this.gridApi.setDomLayout('normal');
                // @ts-ignore
                document.querySelector('#productGrid').style.height = 'calc(50vh - 160px)';
            }
        }
    };
    ProductQueueComponent.prototype.selectAllRows = function () {
        if (this.gridApi) {
            this.gridApi.forEachLeafNode(function (node) {
                node.setSelected(true);
            });
            console.log('select all');
        }
    };
    ProductQueueComponent.prototype.showLoadingOverlay = function () {
        if (this.gridApi) {
            this.gridApi.setRowData([]);
            this.gridApi.showLoadingOverlay();
            this.setupGridHeight(0);
        }
    };
    ProductQueueComponent.prototype.setProductData = function () {
        var _this = this;
        if (this.gridApi) {
            this.gridApi.setRowData(this.productItemsShowing);
            if (this.productItemsShowing) {
                this.gridApi.selectAll();
                // this.onSelectionChange(this.productItemsShowing[0]);
            }
            this.gridApi.onFilterChanged();
            this.setupGridHeight(this.productItemsSearchResults.length);
        }
        else {
            setTimeout(function () { return _this.setProductData; }, 0);
        }
    };
    ProductQueueComponent.prototype.onGridReady = function (params) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        // this.gridApi.sizeColumnsToFit();
        this.setProductData();
    };
    ProductQueueComponent.prototype.autoSizeAll = function () {
        var allColumnIds = [];
        if (this.gridColumnApi) {
            this.gridColumnApi.getAllColumns().forEach(function (column) {
                allColumnIds.push(column.colId);
            });
            this.gridColumnApi.autoSizeColumns(allColumnIds);
        }
    };
    ProductQueueComponent.prototype.onChipClick = function (item) {
        if (!this.operationPanelConfig.preventSwitching) {
            item.selected = !item.selected;
            this.onChipSelectionChange(item);
        }
        else {
            var invalidAttemptWarning = new DialogModel(true, LEVEL.WARNING, 'Invalid Attempt', 'Please save or revert changes before selection change', true, 2000);
            var subscription_1 = this.dialogService.confirm(invalidAttemptWarning).subscribe(function (res) {
                subscription_1.unsubscribe();
            });
        }
    };
    ProductQueueComponent.prototype.onChipSelectionChange = function (item) {
        if (!this.operationPanelConfig.preventSwitching) {
            var index = this.productsSelectedFromGridAndChips.indexOf(item);
            if (item.selected) {
                if (index === -1) {
                    this.productsSelectedFromGridAndChips.push(item);
                }
            }
            else {
                if (index > -1) {
                    this.productsSelectedFromGridAndChips.splice(index, 1);
                }
            }
            this.gridApi.onFilterChanged();
            this.hideClearButton = this.productsSelectedFromGridAndChips.length === 0;
            this.selectionChanged.emit(this.productsSelectedFromGridAndChips);
        }
        else {
            var invalidAttemptWarning = new DialogModel(true, LEVEL.WARNING, 'Invalid Attempt', 'Please save or revert changes before selection change', true, 2000);
            var subscription_2 = this.dialogService.confirm(invalidAttemptWarning).subscribe(function (res) {
                subscription_2.unsubscribe();
            });
        }
    };
    ProductQueueComponent.prototype.onClearChipSelection = function () {
        this.productsSelectedFromGridAndChips.forEach(function (product) { return product.selected = false; });
        this.productsSelectedFromGridAndChips.length = 0;
    };
    ProductQueueComponent.prototype.onSelectionChange = function ($event) {
        var _this = this;
        var e_1, _a, _b;
        var selectedRows = this.gridApi.getSelectedRows();
        if (!this.operationPanelConfig.preventSwitching) {
            this.selectedNodes = this.gridApi.getSelectedNodes();
            try {
                for (var selectedRows_1 = tslib_1.__values(selectedRows), selectedRows_1_1 = selectedRows_1.next(); !selectedRows_1_1.done; selectedRows_1_1 = selectedRows_1.next()) {
                    var selectedRow = selectedRows_1_1.value;
                    if (this.productsSelectedFromGrid.indexOf(selectedRow) === -1) {
                        selectedRow.selected = true;
                    }
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (selectedRows_1_1 && !selectedRows_1_1.done && (_a = selectedRows_1.return)) _a.call(selectedRows_1);
                }
                finally { if (e_1) throw e_1.error; }
            }
            this.productsSelectedFromGrid.length = 0;
            (_b = this.productsSelectedFromGrid).push.apply(_b, tslib_1.__spread(selectedRows));
            // clear other raws
            var chipSelectionChanged_1 = false;
            this.productItemsSearchResults.forEach(function (item) {
                if (_this.productsSelectedFromGrid.indexOf(item) === -1) {
                    item.selected = false;
                    var indexInSelectedChips = _this.productsSelectedFromGridAndChips.indexOf(item);
                    if (indexInSelectedChips > -1) {
                        _this.productsSelectedFromGridAndChips.splice(indexInSelectedChips, 1);
                        chipSelectionChanged_1 = true;
                    }
                }
            });
            this.hideClearButton = this.productsSelectedFromGridAndChips.length === 0;
            // this.gridApi.onFilterChanged();
            if (chipSelectionChanged_1) {
                this.selectionChanged.emit(this.productsSelectedFromGridAndChips);
            }
            else {
                this.productsSelectedFromGridAndChips = selectedRows;
                this.selectionChanged.emit(selectedRows);
            }
        }
        else {
            this.selectedNodes.forEach(function (rowNode) { return rowNode.setSelected(true, false, true); });
        }
    };
    ProductQueueComponent.prototype.onClickCollapseBtn = function () {
        if (this.productsSelectedFromGrid.length > 0 || !this.expandPanelOpen) {
            this.expandPanelOpen = !this.expandPanelOpen;
            this.operationPanelConfig.productViewOpen = this.expandPanelOpen;
        }
        else {
            var invalidAttemptWarning = new DialogModel(true, LEVEL.WARNING, 'Invalid Attempt', 'Please select one or more product item', true, 2000);
            var subscription_3 = this.dialogService.confirm(invalidAttemptWarning).subscribe(function (res) {
                subscription_3.unsubscribe();
            });
        }
    };
    ProductQueueComponent.prototype.onGridSizeChange = function () {
        // this.gridApi.sizeColumnsToFit();
    };
    ProductQueueComponent.prototype.updateSearchChipsAfterSplit = function (oldBookingIds, newBookingIds) {
        var _this = this;
        var selectedIndex = 0;
        this.productItemsSearchResults.forEach(function (result, index) {
            oldBookingIds.forEach(function (oldId) {
                if (result.bookingIdList.includes(oldId)) {
                    result.bookingIdList.splice(result.bookingIdList.indexOf(oldId), 1);
                    selectedIndex = index;
                }
            });
        });
        newBookingIds.forEach(function (id) {
            _this.productItemsSearchResults[selectedIndex].bookingIdList.push(id);
        });
    };
    ProductQueueComponent.prototype.onDocumentClick = function () {
    };
    ProductQueueComponent.prototype.onAutoAllocationClicked = function () {
    };
    ProductQueueComponent.prototype.ngOnDestroy = function () {
        if (this.productSearchResultsSubscription) {
            this.productSearchResultsSubscription.unsubscribe();
        }
    };
    return ProductQueueComponent;
}());
export { ProductQueueComponent };
