import { Injectable } from '@angular/core';
import { AssignmentRawData } from '../../../models/reservation-v2/assignment-raw-data';
import { BookingItem } from '../../../models/reservation-v2/booking-item';
import { CostingType } from '../../../models/reservation-v2/costing-type';
import { ItemCostPortion } from '../../../models/reservation-v2/item-cost-portion';
import { PassengerType } from '../../../models/reservation-v2/passenger-type';
import { PaxCost } from '../../../models/reservation-v2/pax-cost';
import { ServiceItem } from '../../../models/reservation-v2/service-item';
import { ResourceCost } from '../../../models/supplier/resource-cost';

/**
 * this class must sync with
 * it.codegen.tbx.reservationv2.service.bookingitemservice.CostCalculationService
 * backend service
 */
@Injectable({
    providedIn: 'root'
})
export class CostCalculationUtilsV2Service {

    constructor() {
    }

    public calculateCost(
        adultCount: number, teenCount: number, childCount: number, infantCount: number,
        unitCost: number,
        perAdultCost: number, perTeenCost: number, perChildCost: number, perInfantCost: number,
        currency: string
    ): PaxCost {
        const paxCost: PaxCost = new PaxCost(currency);
        if (unitCost && unitCost > 0) {
            paxCost.totalCost = unitCost;
            paxCost.unitCost = unitCost;
        } else {
            let adultCost = 0;
            let teensCost = 0;
            let childrenCost = 0;
            let infantsPrice = 0;
            if (perAdultCost && perAdultCost > 0) {
                adultCost = adultCount * perAdultCost;
                paxCost.perAdultCost = perAdultCost;
            }
            if (perTeenCost && perTeenCost > 0) {
                teensCost = teenCount * perTeenCost;
                paxCost.perTeenCost = perTeenCost;
            }
            if (perChildCost && perChildCost > 0) {
                childrenCost = childCount * perChildCost;
                paxCost.perChildCost = perChildCost;
            }
            if (perInfantCost && perInfantCost > 0) {
                infantsPrice = infantCount * perInfantCost;
                paxCost.perInfantCost = perInfantCost;
            }
            paxCost.totalCost = this.roundTo2Decimals(adultCost + teensCost + childrenCost + infantsPrice);
        }
        return paxCost;
    }

    public fixAssignmentCost(rawAssignment: AssignmentRawData) {
        let adultCount = 0;
        let teenCount = 0;
        let childCount = 0;
        let infantCount = 0;

        if (rawAssignment.bookingPaxMappings) {
            for (const bookingPaxMapping of rawAssignment.bookingPaxMappings) {
                switch (bookingPaxMapping.type) {
                    case PassengerType.A:
                        adultCount++;
                        break;
                    case PassengerType.T:
                        teenCount++;
                        break;
                    case PassengerType.C:
                        childCount++;
                        break;
                    case PassengerType.I:
                        infantCount++;
                        break;
                }
            }
        }

        const resourceCost: ResourceCost = rawAssignment.genResourceCost ?
            rawAssignment.genResourceCost :
            rawAssignment.trsResourceCost;
        if (resourceCost) {
            const paxCost = this.calculateCost(
                adultCount, teenCount, childCount, infantCount,
                resourceCost.unitFare,
                resourceCost.adultFare, resourceCost.teenFare, resourceCost.childFare, resourceCost.infantFare,
                resourceCost.currency
            );
            rawAssignment.estimatedCost = rawAssignment.calculatedCost ? rawAssignment.calculatedCost.estimatedCost : paxCost.totalCost;
            rawAssignment.jobCost = rawAssignment.calculatedCost ? rawAssignment.calculatedCost.totalCost : paxCost.totalCost;
        }
    }

    public getItemCostPortion(item: ServiceItem | BookingItem, rawAssignment: AssignmentRawData): PaxCost {
        const resourceCost: ResourceCost = rawAssignment.genResourceCost ?
            rawAssignment.genResourceCost :
            rawAssignment.trsResourceCost;
        const paxCost: PaxCost = new PaxCost('');
        if (resourceCost) {
            paxCost.currency = resourceCost.currency;
            if (item.bookingPassengers.length > 0 && rawAssignment.bookingPaxMappings.length) {
                if (resourceCost.unitFare > 0) {
                    paxCost.unitCost = resourceCost.unitFare;
                    // if (resourceCost.costingType === CostingType.UNIT ){
                    //     paxCost.totalCost = this.roundTo2Decimals((rawAssignment.jobCost / rawAssignment.bookingPaxMappings.length) *
                    //         item.bookingPassengers.length);
                    // }
                    // else
                    // TODO need to calculate cost seperatly
                    if (rawAssignment.supplierObj && rawAssignment.supplierObj.calculatedCost) {
                        paxCost.totalCost = rawAssignment.supplierObj.calculatedCost.totalCost;
                        paxCost.estimatedCost = rawAssignment.supplierObj.calculatedCost.estimatedCost ?
                            rawAssignment.supplierObj.calculatedCost.estimatedCost :
                            rawAssignment.supplierObj.calculatedCost.totalCost;
                    } else {
                        paxCost.totalCost = rawAssignment.jobCost;
                        paxCost.estimatedCost = rawAssignment.estimatedCost ? rawAssignment.estimatedCost : rawAssignment.jobCost;
                    }

                } else {
                    let adultCount = 0;
                    let teenCount = 0;
                    let childCount = 0;
                    let infantCount = 0;

                    for (const passenger of item.bookingPassengers) {
                        switch (passenger.type) {
                            case PassengerType.A:
                                adultCount++;
                                break;
                            case PassengerType.T:
                                teenCount++;
                                break;
                            case PassengerType.C:
                                childCount++;
                                break;
                            case PassengerType.I:
                                infantCount++;
                                break;
                        }
                    }
                    return this.calculateCost(
                        adultCount, teenCount, childCount, infantCount,
                        resourceCost.unitFare,
                        resourceCost.adultFare, resourceCost.teenFare, resourceCost.childFare, resourceCost.infantFare,
                        resourceCost.currency
                    );
                }
            } else {
                paxCost.unitCost = resourceCost.unitFare;
                paxCost.perAdultCost = resourceCost.adultFare;
                paxCost.perTeenCost = resourceCost.teenFare;
                paxCost.perChildCost = resourceCost.childFare;
                paxCost.perInfantCost = resourceCost.infantFare;
                paxCost.totalCost = 0;
            }
        }

        return paxCost;
    }

    public setupItemCostPortion(item: BookingItem | ServiceItem) {
        const primaryAssignment = item.primaryAssignment;
        const secondaryAssignment = item.secondaryAssignment;
        const itemCostPortion = new ItemCostPortion();
        if (primaryAssignment) {
            itemCostPortion.primaryJobCost = this.getItemCostPortion(item, primaryAssignment);
            if(primaryAssignment.jobCost)
            {
                itemCostPortion.primaryJobCost.totalCost = primaryAssignment.jobCost;
                itemCostPortion.primaryJobCost.estimatedCost = primaryAssignment.estimatedCost ? primaryAssignment.estimatedCost : primaryAssignment.jobCost;
            }
        }
        if (secondaryAssignment) {
            itemCostPortion.secondaryJobCost = this.getItemCostPortion(item, secondaryAssignment);
            if(secondaryAssignment.jobCost)
            {
                itemCostPortion.secondaryJobCost.totalCost = secondaryAssignment.jobCost;
                itemCostPortion.secondaryJobCost.estimatedCost = secondaryAssignment.estimatedCost ? secondaryAssignment.estimatedCost : secondaryAssignment.jobCost;
            }
        }
        if (itemCostPortion.primaryJobCost && !itemCostPortion.secondaryJobCost) {
            itemCostPortion.totalCost = itemCostPortion.primaryJobCost.totalCost;
            itemCostPortion.currency = itemCostPortion.primaryJobCost.currency;
        } else if (!itemCostPortion.primaryJobCost && itemCostPortion.secondaryJobCost) {
            itemCostPortion.totalCost = itemCostPortion.secondaryJobCost.totalCost;
            itemCostPortion.currency = itemCostPortion.secondaryJobCost.currency;
        } else if ( // both currencies are equal or empty
            itemCostPortion.primaryJobCost && itemCostPortion.secondaryJobCost &&
            ((itemCostPortion.primaryJobCost.currency && itemCostPortion.primaryJobCost.currency ===
                    itemCostPortion.secondaryJobCost.currency)
                ||
                !itemCostPortion.primaryJobCost.currency && !itemCostPortion.secondaryJobCost.currency
            )
        ) {
            itemCostPortion.totalCost = itemCostPortion.primaryJobCost.totalCost + itemCostPortion.secondaryJobCost.totalCost;
            itemCostPortion.currency = itemCostPortion.primaryJobCost.currency;
        }
        item.itemCostPortion = itemCostPortion;
    }

    public roundTo2Decimals(value: number): number {
        return Math.round((value + Number.EPSILON) * 100) / 100;
    }

}
