import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { HennaArtistSpecificDetailsCardComponent } from './henna-artist-specific-details-card.component';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule.forChild()
    ],
    declarations: [HennaArtistSpecificDetailsCardComponent],
    exports: [HennaArtistSpecificDetailsCardComponent]
})
export class HennaArtistSpecificDetailsCardModule {}
