import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {KeyValue} from '@tc-core/model/it/codegen/ui/key-value';
import {ContactDetails} from '../../../models/profile/contact-details';
import {SummaryCardData} from '@tc-core/model/it/codegen/ui/framework/summary-card-data';

@Component({
  selector: 'tc-additional-contact-summary-card',
  templateUrl: './additional-contact-summary-card.component.html'
})
export class AdditionalContactSummaryCardComponent implements OnInit {
    public icon = new KeyValue();
    @Input() contact: ContactDetails;
    @Input() isHeader: boolean;
    @Input() isEditable: boolean;
    @Input() addressCategoryMap: any[];
    @Input() nameCodeMap: Map<string, string>;
    @Input() showIcons: boolean;
    @Input() additionalContactContent: SummaryCardData;

    @Output() deleteContact: EventEmitter<number> = new EventEmitter();

    CONTACT_CATEGORY_NAME_CODE_MAP_PREFIX = 'contactCategory';

    NOT_SPECIFIED = 'NOT SPECIFIED';

    constructor() { }

    ngOnInit() {
    }

    public getContactName(): string {
        if (!this.contact.title && !this.contact.firstName && !this.contact.lastName) {
            return 'New Contact';
        } else {
            return `${(this.contact.title && this.contact.title !== this.NOT_SPECIFIED) ? this.contact.title : ''} ${this.contact.firstName ? this.contact.firstName : ''} ${this.contact.lastName ? this.contact.lastName : ''}`;
        }
    }

    public getContactCategory(code: any): string {
        if (this.isEditable && this.addressCategoryMap) {
            let selectedContactCategoryObj = this.addressCategoryMap.filter(obj => obj.code === code)[0];
            if (selectedContactCategoryObj) {
                return selectedContactCategoryObj.name;
            }
        } else if (!this.isEditable && this.nameCodeMap) {
            return this.nameCodeMap.get(`${this.CONTACT_CATEGORY_NAME_CODE_MAP_PREFIX}${code}`);
        } else {
            return '';
        }
    }

    public onDelete(event: any) {
        event.stopPropagation();
        this.deleteContact.emit(this.contact.tempId);
    }

}
