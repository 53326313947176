import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { TcErrorType } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-error';
import { TC } from '@tc-core/util';
import { ConfigLoader } from '@tc-core/util/framework';
import { of, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SetupGridComp } from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid-comp';
import { SetupGridComponent } from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid.component';
import { TCO } from '../../../constants';
import { Category, ResourceTypeSearchCriteria } from '../../../models/criteria/resource-type-search-criteria';
import { SortDirection } from '../../../models/helper/sort-direction';
import { DataHandlerService } from '../../../services/backend-consumers/master-data-handler-service/data-handler.service';
import { ResourceTypeService } from '../../../services/backend-consumers/setups/resource-type.service';
import { DMCCommon } from '../../../services/util/common/dmc-common';
import { DataKey, DataStoreService } from '../../../services/util/framework/data-store.service';
var EMPTY_CHAR = TC.FORMAT.EMPTY_CHAR;
var ResourceTypeSetupComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ResourceTypeSetupComponent, _super);
    function ResourceTypeSetupComponent(configLoader, dataStoreService, dataHandlerService, resourceTypeService, common) {
        var _this = _super.call(this) || this;
        _this.configLoader = configLoader;
        _this.dataStoreService = dataStoreService;
        _this.dataHandlerService = dataHandlerService;
        _this.resourceTypeService = resourceTypeService;
        _this.common = common;
        _this.colDefConfig = [];
        _this.resourceTypeSearchCriteria = new ResourceTypeSearchCriteria();
        _this.allResourceCodes = [];
        // required options for grid editors
        // nothing
        _this.searchCriteriaObserver = new Subscription();
        _this.resourceTypes = [];
        /*
         grid config methods which are called by grid setup (ag grid)
         and set by grid config
         */
        _this.getCategoryText = function (params) {
            var groupText = EMPTY_CHAR;
            if (params && params.data && params.data.resourceCategory) {
                if (params.data.resourceCategory.code) {
                    groupText = params.data.resourceCategory.code;
                }
                else if (params.data.resourceCategory === Category.TRANSPORT) {
                    groupText = 'Transport';
                }
                else if (params.data.resourceCategory === Category.GENERIC) {
                    groupText = 'Generic';
                }
                else {
                    groupText += EMPTY_CHAR;
                }
            }
            return groupText;
        };
        _this.onCategorySet = function (params) {
            if (params.data && params.newValue) {
                if (params.oldValue === '--' && _this.allResourceCodes.includes(params.data.code)) {
                    _this.common.showSnackBar('Resource Code Already Exist. Please Select a Different Code', 3000, TcErrorType.TYPE.ERROR);
                }
                else {
                    if (params.newValue['code']) {
                        params.data['resourceCategory'] = params.newValue['code'];
                    }
                    else {
                        params.data['resourceCategory'] = params.newValue;
                    }
                }
            }
            return true;
        };
        _this.validateCode = function (params) {
            return !(params && params.data);
        };
        return _this;
    }
    ResourceTypeSetupComponent.prototype.ngOnInit = function () {
        this.colDefConfig = this.configLoader.configurations.get(TCO.CONF.CONF_RESOURCE_TYPE_SETUP);
        this.subscribeSearchCriteria();
        this.fillRequiredGridOptionData();
        this.fillDataKeyForEditorData();
        this.getAllResourceCodes();
    };
    /**
     * subscribe search criteria changes in search bar
     * then force grid to refresh data
     * grid refresh will be call getRaws method which is using this subscribed criteria for backend call
     */
    ResourceTypeSetupComponent.prototype.subscribeSearchCriteria = function () {
        var _this = this;
        this.searchCriteriaObserver = this.dataStoreService.get(DataKey.resourceTypeSearchCriteria)
            .subscribe(function (value) {
            _this.resourceTypeSearchCriteria = value;
            _this.setupGrid.runForceSearch();
        });
    };
    ResourceTypeSetupComponent.prototype.fillRequiredGridOptionData = function () {
        // nothing
    };
    ResourceTypeSetupComponent.prototype.fillDataKeyForEditorData = function () {
        this.addResourceTypes();
    };
    ResourceTypeSetupComponent.prototype.addResourceTypes = function () {
        var _this = this;
        var resourceTypeSearchCriteria = new ResourceTypeSearchCriteria();
        resourceTypeSearchCriteria.size = 1000000;
        this.resourceTypeService.getResourceTypes(resourceTypeSearchCriteria).subscribe(function (result) {
            _this.dataStoreService.set(DataKey.resourceTypes, result.data, true);
        });
    };
    ResourceTypeSetupComponent.prototype.getAllResourceCodes = function () {
        var _this = this;
        this.resourceTypeService.getResourceCodes().subscribe(function (response) {
            _this.allResourceCodes = response.data;
        });
    };
    ResourceTypeSetupComponent.prototype.isInvalidRow = function (params) {
        var data = params.data;
        if (data) {
            var invalid = true;
            if (data.code &&
                // (!this.resourceTypes.filter(value => value.code === data.code)) &&
                data.name &&
                data.resourceCategory) {
                invalid = false;
            }
            return invalid;
        }
        else {
            return false;
        }
    };
    ResourceTypeSetupComponent.prototype.createNewRow = function () {
        return { unsaved: true };
    };
    ResourceTypeSetupComponent.prototype.isUnsavedRow = function (row) {
        return !!row.unsaved;
    };
    ResourceTypeSetupComponent.prototype.deleteRow = function (row) {
        if (row && row.code) {
            return this.resourceTypeService.deleteResourceType(row.code);
        }
        else {
            return of('success');
        }
    };
    ResourceTypeSetupComponent.prototype.saveRow = function (row, event) {
        return this.resourceTypeService.saveResourceType(row)
            .pipe(tap(function (data) { return row.unsaved = undefined; }));
    };
    ResourceTypeSetupComponent.prototype.getRows = function (params) {
        var _this = this;
        console.log('fetch requesting for ' + params.startRow + ' to ' + params.endRow);
        console.log(params.sortModel);
        var pageSize = params.endRow - params.startRow;
        if (!this.resourceTypeSearchCriteria) {
            this.resourceTypeSearchCriteria = new ResourceTypeSearchCriteria();
        }
        this.resourceTypeSearchCriteria.start = params.startRow;
        this.resourceTypeSearchCriteria.size = pageSize;
        if (params.sortModel && params.sortModel[0]) {
            this.resourceTypeSearchCriteria.sortBy = params.sortModel[0].colId;
            if (params.sortModel[0].sort === SetupGridComp.GRID_SORT_ASCENDING) {
                this.resourceTypeSearchCriteria.sortDirection = SortDirection.ASC;
            }
            else {
                this.resourceTypeSearchCriteria.sortDirection = SortDirection.DESC;
            }
        }
        else {
            this.resourceTypeSearchCriteria.sortBy = 'createdOn';
            this.resourceTypeSearchCriteria.sortDirection = SortDirection.ASC;
        }
        return this.resourceTypeService.getResourceTypes(this.resourceTypeSearchCriteria)
            .pipe(tap(function (data) {
            _this.dataStoreService.set(DataKey.resourceTypeSearchResultsForCriteria, data);
            _this.resourceTypes = data;
        }));
    };
    ResourceTypeSetupComponent.prototype.ngOnDestroy = function () {
        if (this.searchCriteriaObserver) {
            this.searchCriteriaObserver.unsubscribe();
        }
    };
    ResourceTypeSetupComponent.prototype.isUnique = function (code) {
        var exist = this.resourceTypes.filter(function (value) { return value.code === code; });
        return !exist;
    };
    return ResourceTypeSetupComponent;
}(SetupGridComp));
export { ResourceTypeSetupComponent };
