<!--dont change the class name 'cg-cal-single-year-body'-->
<div class="cg-cal-single-year-body" (scroll)="checkScroll($event)">
  <div class="tc-sc-year-view__header">
    <span class="tc-sc-year-view__header-txt">{{year}}</span>
  </div>
  <div class="container-fluid">
    <div class="row tc-sc-month-view__container" *ngFor="let row_index of rowsIndexArray">
      <div class="col-xs-3 tc-sc-month-view" *ngFor="let col_index of colsIndexArray">
        <mat-calendar
          [selected]="selectedDate"
          *ngIf="showCalendar" #matCalendar
          cgCalMonthHeader
          cgMonthDateDirective
          [startAt]="getMonthStartingDate(row_index,col_index)"
          (selectedChange)="onSelectDate($event)">
        </mat-calendar>
      </div>
    </div>
  </div>

</div>
