export class RoomOccupancy {
    occupancyId: number;
    occupancyTempId: number;
    occupancyName: string;
    adults: number;
    teens: number;
    children: number;
    infants: number;
    total: number;
}
