import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { FileUploaderComponent } from './file-uploader.component';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
    declarations: [FileUploaderComponent],
    imports: [
        CommonModule,
        FormsModule,
        TranslateModule

    ],
    exports: [FileUploaderComponent],
    entryComponents: [FileUploaderComponent]
})
export class FileUploaderModule {}
