import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { PassengerGenerationComponent } from './passenger-generation.component';

@NgModule({
  declarations: [PassengerGenerationComponent],
    imports: [
        CommonModule,
        TranslateModule,
        MatInputModule,
        TranslateModule.forChild(),
        FormsModule
    ],
    exports: [
        PassengerGenerationComponent
    ],
    entryComponents: [
        PassengerGenerationComponent
    ]
})
export class PassengerGenerationModule { }
