<div class="tc-sc__container-main">
    <!--<tc-cg-season-calendar
            [fromYear]="fromYear"
            [toYear]="toYear"
            [rangeGroups]="rangeGroupsForSeasonCalendar"
            [showCalendar]="false"
            [viewOnlyMode]="true">
    </tc-cg-season-calendar>-->

    <div class="tc-sc__container-right">
        <div class="row">
            <tc-result-header
                    [transferModeList]="transferModes"
                    [groupingOptionList]=""
                    [routeList]="routes"
                    [seasonList]="seasons"
                    [heading]="heading"
                    [sorters]=""
                    [pageSection]="costAddSection"
                    [showPageSection]="true"
                    (actionClick)="showSeasonSummary($event)"
                    (selectedTransferMode)="onSelectTransferMode($event)"
                    (selectedRoute)="onSelectRoute($event)"
                    (selectedSeason)="onSelectSeason($event)"
            ></tc-result-header>


            <!--<tc-section-heading
                    [pageSection]="costAddSection"
                    (actionClick)="onHeaderActionClick($event)">
            </tc-section-heading>-->
        </div>
        <div class="expand-group-card">


            <div *ngIf="resourceCosts.length > 0" class="row">
                <!-- show newly added ones separately (first element) -->
                <ng-container *ngFor="let resourceCost of resourceCosts; index as i ">
                    <div class="row" *ngIf="(filterTransferMode ? resourceCost.transferMode === filterTransferMode : true) && (filterRoute ? filterRoute === resourceCost.route: true) && (filterSeason>0 ? filterSeason === resourceCost.tempSeasonId: true)">
                        <div *ngIf="resourceCost.isEdit">
                            <tc-transport-cost-setup-edit-card
                                    ngClass="isEditable-{{addNewEditableStatus}}"
                                    [selectedResourceCost]="resourceCost"
                                    [resourceCosts]="resourceCosts"
                                    [daysOfWeek]="daysOfWeek"
                                    [transferModes]="transferModeList"
                                    [vehicleModels]="vehicleModels"
                                    [routes]="routeList"
                                    [seasonSetup]="supplierCostSetup"
                                    (saveClick)="onNewCostSaveClick($event)"
                                    (deleteClick)="onDeleteClick($event)"
                                    (checkUniqueValues)="validateUniqueValues($event)"
                                    (revertClick)="onRevertClick($event)"
                                    [isNew]="addNew"
                                    (changeFormValidity)="onChangeCostSetupForm($event)">
                            </tc-transport-cost-setup-edit-card>
                        </div>
                        <div *ngIf="!resourceCost.isEdit">
                            <tc-transport-cost-setup-summary-card
                                    #bill_route_card
                                    [isEditable]="addNewEditableStatus"
                                    [seasonSetup]="supplierCostSetup"
                                    [selectedResourceCost]="resourceCost"
                                    [selectedResourceCostContent]="resourceCostCardContent[i]"
                                    [daysOfWeek]="daysOfWeek"
                                    (editClick)="onEditClick($event)"
                                    (deleteClick)="onDeleteClick($event)">
                            </tc-transport-cost-setup-summary-card>
                        </div>

                    </div>
                </ng-container>
            </div>
        </div>
    </div>

</div>

