import { UserProfileSearchCriteria } from '../../../models/criteria/user-profile-search-criteria';
import { ApprovalStatus } from '../../../models/user/user-service-profile';
import { ResourceType } from '../../../models/reservation/assignment';
import { DataKey } from '../../util/framework/data-store.service';
import { DMCQueryParamsService } from '../../util/framework/dmc-query-params.service';
import { RequestService } from '../../util/framework/request.service';
import { UrlPaths } from '../url-paths';
import * as i0 from "@angular/core";
import * as i1 from "../../util/framework/request.service";
import * as i2 from "../../util/framework/dmc-query-params.service";
var UserProfileService = /** @class */ (function () {
    function UserProfileService(requestService, queryParamsService) {
        this.requestService = requestService;
        this.queryParamsService = queryParamsService;
    }
    UserProfileService.prototype.loadCurrentUserProfile = function () {
        return this.requestService.get(UrlPaths.OP_USER_PROFILE_URL_KEY, [UrlPaths.OP_USER_PROFILE_VERSION, UrlPaths.SERVICE_PROFILE, UrlPaths.PROFILE_ME]);
    };
    UserProfileService.prototype.getOperatorServiceProfiles = function (searchCriteria) {
        var params = this.queryParamsService.asQueryParams(searchCriteria);
        return this.requestService.getWithDataKey(DataKey.opeAcceptanceSearchResultsFromService, UrlPaths.OP_USER_PROFILE_URL_KEY, [UrlPaths.OP_USER_PROFILE_VERSION, UrlPaths.SERVICE_PROFILE], params);
    };
    UserProfileService.prototype.getResourceServiceProfiles = function (searchCriteria) {
        var params = this.queryParamsService.asQueryParams(searchCriteria);
        return this.requestService.getWithDataKey(DataKey.resAcceptanceSearchResultsFromService, UrlPaths.OP_USER_PROFILE_URL_KEY, [UrlPaths.OP_USER_PROFILE_VERSION, UrlPaths.SERVICE_PROFILE], params);
    };
    UserProfileService.prototype.getSupplierServiceProfiles = function (searchCriteria) {
        var params = this.queryParamsService.asQueryParams(searchCriteria);
        return this.requestService.getWithDataKey(DataKey.supAcceptanceSearchResultsFromService, UrlPaths.OP_USER_PROFILE_URL_KEY, [UrlPaths.OP_USER_PROFILE_VERSION, UrlPaths.SERVICE_PROFILE], params);
    };
    UserProfileService.prototype.getResourceServiceProfilesWithoutKey = function (searchCriteria) {
        var params = this.queryParamsService.asQueryParams(searchCriteria);
        return this.requestService.get(UrlPaths.OP_USER_PROFILE_URL_KEY, [UrlPaths.OP_USER_PROFILE_VERSION, UrlPaths.SERVICE_PROFILE], params);
    };
    UserProfileService.prototype.getProfilesWithoutKey = function (searchCriteria) {
        var params = this.queryParamsService.asQueryParams(searchCriteria);
        return this.requestService.get(UrlPaths.OP_USER_PROFILE_URL_KEY, [UrlPaths.OP_USER_PROFILE_VERSION, UrlPaths.USER_PROFILE], params);
    };
    UserProfileService.prototype.getEmailsForResourceIds = function (resourceIdList) {
        var params = this.queryParamsService.asQueryParams(resourceIdList);
        return this.requestService.post(UrlPaths.OP_USER_PROFILE_URL_KEY, [UrlPaths.OP_USER_PROFILE_VERSION, UrlPaths.SERVICE_PROFILE, UrlPaths.GET_RESOURCE_EMAILS], resourceIdList);
    };
    UserProfileService.prototype.getAllEmailsForResourceIds = function () {
        return this.requestService.get(UrlPaths.OP_USER_PROFILE_URL_KEY, [UrlPaths.OP_USER_PROFILE_VERSION, UrlPaths.SERVICE_PROFILE, UrlPaths.GET_ALL_RESOURCE_EMAILS]);
    };
    UserProfileService.prototype.getPendingResourceAcceptanceData = function () {
        var criteria = new UserProfileSearchCriteria();
        criteria.approvalStatus = ApprovalStatus.PENDING;
        criteria.restrictedServiceTypes = [];
        criteria.restrictedServiceTypes.push(ResourceType.default);
        var params = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.getWithDataKey(DataKey.pendingResourceAcceptanceData, UrlPaths.OP_USER_PROFILE_URL_KEY, [UrlPaths.OP_USER_PROFILE_VERSION, UrlPaths.SERVICE_PROFILE], params);
    };
    UserProfileService.prototype.getPendingSupplierAcceptanceData = function () {
        var criteria = new UserProfileSearchCriteria();
        criteria.approvalStatus = ApprovalStatus.PENDING;
        criteria.restrictedServiceTypes = [];
        criteria.restrictedServiceTypes.push(ResourceType.default);
        var params = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.getWithDataKey(DataKey.pendingSupplierAcceptanceData, UrlPaths.OP_USER_PROFILE_URL_KEY, [UrlPaths.OP_USER_PROFILE_VERSION, UrlPaths.SERVICE_PROFILE], params);
    };
    UserProfileService.prototype.updateUserTypeStatus = function (userEmail, userApprovalData) {
        return this.requestService.patch(UrlPaths.OP_USER_PROFILE_URL_KEY, [UrlPaths.OP_USER_PROFILE_VERSION, UrlPaths.SERVICE_PROFILE, userEmail, UrlPaths.APPROVAL_STATUS], userApprovalData);
    };
    UserProfileService.prototype.updateResourceStatus = function (userEmail, userApprovalData) {
        return this.requestService.patch(UrlPaths.OP_USER_PROFILE_URL_KEY, [UrlPaths.OP_USER_PROFILE_VERSION, UrlPaths.SERVICE_PROFILE, userEmail, UrlPaths.APPROVAL_STATUS], userApprovalData);
    };
    UserProfileService.prototype.updateUserProfileKC = function (userProfile) {
        return this.requestService.put(UrlPaths.OP_USER_PROFILE_URL_KEY, [UrlPaths.OP_USER_PROFILE_VERSION, UrlPaths.USER_PROFILE, UrlPaths.USER_PROFILE_KC], userProfile);
    };
    UserProfileService.ngInjectableDef = i0.defineInjectable({ factory: function UserProfileService_Factory() { return new UserProfileService(i0.inject(i1.RequestService), i0.inject(i2.DMCQueryParamsService)); }, token: UserProfileService, providedIn: "root" });
    return UserProfileService;
}());
export { UserProfileService };
