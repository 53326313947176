import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { DataFormatterUtilService } from '@tc-core/util/system/data-formatter-util.service';
import { ContentStripModule } from '@tc/content-strip';
import { DirectivesModule } from '@tc/directives';
import { ExpandedContentCardModule } from '@tc/expanded-content-card';
import { ExpandedContentCardHeaderModule } from '@tc/expanded-content-card-header';
import { PaginatorModule } from '@tc/paginator';
import { PipesModule } from '@tc/pipes';
import { CardDataHandlerService } from '../../../../services/util/ui/card-data-handler-service.service';
import { LocationGroupsComponent } from './location-groups.component';
import { ContentSummaryCardModule } from '@tc/content-summary-card';
import { SectionHeadingModule } from '@tc/section-heading';

@NgModule({
    imports: [
        CommonModule,
        PipesModule,
        DirectivesModule,
        ContentSummaryCardModule,
        ContentStripModule,
        ExpandedContentCardModule,
        ExpandedContentCardHeaderModule,
        PaginatorModule,
        SectionHeadingModule
    ],
    providers: [
        DataFormatterUtilService,
        DatePipe,
        CardDataHandlerService
    ],
    declarations: [LocationGroupsComponent],
    exports: [LocationGroupsComponent]
})
export class LocationGroupsModule {}
