import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    QueryList,
    ViewChild,
    ViewChildren
} from '@angular/core';
import {Pager} from '@tc-core/model/it/codegen/ui/framework/pager';
import {Sorter} from '@tc-core/model/it/codegen/ui/framework/sorter';
import {SummaryCardData} from '@tc-core/model/it/codegen/ui/framework/summary-card-data';
import {TC} from '@tc-core/util';
import {Resource} from '../../../models/resource/resource';
import {ExpansionPanelDirective} from '@tc/directives/expansion-panel';
import { Season } from '../../../models/supplier/season';
import EMPTY_CHAR = TC.FORMAT.EMPTY_CHAR;

@Component({
    selector: 'tc-adv-vehicle-search-results',
    templateUrl: './adv-vehicle-search-results.component.html'
})
export class AdvVehicleSearchResultsComponent implements OnInit, AfterViewInit {

    @Input() sorter: Sorter;
    @Input() sortingHandler: any;
    @Input() sorters: Sorter[] = [];

    @Input() pager: Pager;

    @Input() isSingleResultExpand: boolean = true;

    @Input() vehiclesResultsList: SummaryCardData[];
    @Input() selectedVehicleSummary: SummaryCardData;
    @Input() detailedVehicle: Resource;
    @Input() selectedVehicleCost: Season[]=[];

    @Output() resourceSelect: EventEmitter<SummaryCardData> = new EventEmitter();
    @Output() edit: EventEmitter<Resource> = new EventEmitter();
    @Output() editAsNew: EventEmitter<Resource> = new EventEmitter();
    @Output() calendarClick: EventEmitter<SummaryCardData> = new EventEmitter();
    @Output() cardScroll: EventEmitter<number> = new EventEmitter<number>();

    public expandedPanelIndex: number = -1;

    private expandedPanel = null;
    private expansionPanel = null;

    public selectionIndex = '1';

    @ViewChildren(ExpansionPanelDirective) _expansionPanels: QueryList<ExpansionPanelDirective>;
    @ViewChild('resourceExp') clientExp: ElementRef;

    constructor() {
    }

    ngOnInit() {
    }

    ngAfterViewInit() {
        if (this.isSingleResultExpand && this.vehiclesResultsList && this.vehiclesResultsList.length === 1) {
            if (this._expansionPanels && this._expansionPanels !== undefined) {
                this.selectionIndex = '1';
                this.openExpansionPanel();
            }
        }
    }

    public openExpansionPanel() {
        this.onGenericResourceSelection(this.vehiclesResultsList[+this.selectionIndex - 1], +this.selectionIndex - 1);

        // close expanded panel
        if (this.expandedPanelIndex !== -1) {
            this.expandedPanel.expansionState.next(true);
        }

        // open focused panel
        this.expansionPanel = this._expansionPanels.find((expansionElement, i) => {
            return (i + 1) === +this.selectionIndex - ((this.pager.currPage) * this.pager.itemsPerPage);
        });

        // same page
        if (this.expansionPanel !== null && this.expansionPanel !== undefined) {
            this.expansionPanel.expansionState.next(false);
            // another page
        } else {
            const page = Math.ceil(+this.selectionIndex / ((this.pager.currPage + 1) * this.pager.itemsPerPage));
            this.pager.currPage = page;
        }
    }


    public onHeaderClick($event: any) {
        if ($event.srcElement.className !== 'material-icons') {
            this.selectedVehicleSummary = null;
        }
    }

    public onActionClick(genericResource: SummaryCardData, $event: any) {

    }

    public onEditClick($event) {
        this.edit.emit(this.detailedVehicle);
    }

    public onGenericResourceSelection(vehicleSummary: SummaryCardData, index?: number) {
        this.selectedVehicleSummary = vehicleSummary;

        if (vehicleSummary) {
            this.resourceSelect.emit(vehicleSummary);
            this.cardScroll.emit(index);
        }
    }

    public getHeader() {
        if (this.detailedVehicle && this.detailedVehicle.vehicle) {
            return this.detailedVehicle.vehicle.vehicleName;
        } else if (this.selectedVehicleSummary) {
            if (this.selectedVehicleSummary.data.value['name']) {
                return this.selectedVehicleSummary.data.value['name'];
            } else {
                return EMPTY_CHAR;
            }
        }
    }

    public getSubHeader() {
        if (this.detailedVehicle && this.detailedVehicle.vehicle) {
            return this.detailedVehicle.vehicle.vehicleModel;
        }
    }

    public onEditAsNewClick($event: any) {
        this.editAsNew.emit(this.detailedVehicle);
    }

    public onCalendarClick($event: any) {
        this.calendarClick.emit(this.selectedVehicleSummary);
    }
}
