import {Component, OnInit, ViewChild} from '@angular/core';
import {SetupGridComp} from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid-comp';
import {SetupGridComponent} from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid.component';
import {Observable, of, Subscription} from 'rxjs';
import {BoardBasisSetupSearchCriteria} from '../../../models/criteria/board-basis-setup-search-criteria';
import {ConfigLoader} from '@tc-core/util/framework';
import {DataKey, DataStoreService} from '../../../services/util/framework/data-store.service';
import {MasterDataRestService} from '../../../services/backend-consumers/master-data-handler-service/master-data-rest.service';
import {DMCCommon} from '../../../services/util/common/dmc-common';
import {TCO} from '../../../constants';
import {TcErrorType} from '@tc-core/model/it/codegen/tbx/ext/errors/tc-error';
import {IGetRowsParams} from 'ag-grid-community';
import {SortDirection} from '../../../models/helper/sort-direction';
import {tap} from 'rxjs/operators';
import EMPTY_CHAR = TC.FORMAT.EMPTY_CHAR;
import {TC} from '@tc-core/util/constants';
import {BoardBasisSetupService} from '../../../services/backend-consumers/setups/board-basis-setup.service';

@Component({
    selector: 'tc-board-basis-setup',
    templateUrl: './board-basis-setup.component.html'
})
export class BoardBasisSetupComponent extends SetupGridComp implements OnInit {

    @ViewChild('setupGrid')
    private setupGrid: SetupGridComponent;
    public colDefConfig = [];

    // search criteria obj. used to
    private boardBasisSetupSearchCriteria: BoardBasisSetupSearchCriteria = new BoardBasisSetupSearchCriteria();

    private searchCriteriaObserver: Subscription = new Subscription();

    allBoadBasisSetupCodes = [];

    constructor(
        private configLoader: ConfigLoader,
        private boardBasisSetupService: BoardBasisSetupService,
        private dataStoreService: DataStoreService,
        private masterDataRestService: MasterDataRestService,
        private common: DMCCommon,
    ) {
        super();
    }

    ngOnInit() {
        this.colDefConfig = this.configLoader.configurations.get(TCO.CONF.CONF_BOARD_BASIS_SETUP);
        this.subscribeSearchCriteria();
    }


    /**
     * subscribe search criteria changes in search bar
     * then force grid to refresh data
     * grid refresh will be call getRaws method which is using this subscribed criteria for backend call
     */
    subscribeSearchCriteria() {
        this.searchCriteriaObserver = this.dataStoreService.get(DataKey.boardBasisSetupSearchCriteria).subscribe(value => {
            this.boardBasisSetupSearchCriteria = value;
            this.setupGrid.runForceSearch();
        });
    }

    public isInvalidRow(params): boolean {
        const data = params.data;
        if (data) {
            let invalid = true;
            if (
                data.code &&
                data.name
            ) {
                invalid = false;
            }
            return invalid;
        } else {
            return false;
        }
    }

    public isUnsavedRow(row: any): boolean {
        return !row.code;
    }

    public deleteRow(row: any) {
        if (row && row.code) {
            return this.boardBasisSetupService.deleteRow(row.code);
        } else {
            return of('success');
        }
    }

    public saveRow(row: any, event: any) {
        console.log(event);
        if (event.data.code === event.newValue) {
            return this.boardBasisSetupService.updateRow(event.oldValue, row);
        } else {
            if (row.unsaved) {
                if (this.allBoadBasisSetupCodes.includes(row.code)) {
                    this.common.showSnackBar(
                        'This Code Already Exist. Please Select a Different Code',
                        3000,
                        TcErrorType.TYPE.ERROR
                    );
                } else {
                    return this.boardBasisSetupService.saveRow(row);
                }
            } else {
                return this.boardBasisSetupService.saveRow(row);
            }
        }
    }

    public getRows(params: IGetRowsParams): Observable<any> {
        console.log('fetch requesting for ' + params.startRow + ' to ' + params.endRow);
        console.log(params.sortModel);

        const pageSize = params.endRow - params.startRow;
        if (!this.boardBasisSetupSearchCriteria) {
            this.boardBasisSetupSearchCriteria = new BoardBasisSetupSearchCriteria();
        }
        this.boardBasisSetupSearchCriteria.start = params.startRow;
        this.boardBasisSetupSearchCriteria.size = pageSize;
        if (params.sortModel && params.sortModel[0]) {
            this.boardBasisSetupSearchCriteria.sortBy = params.sortModel[0].colId;
            if (params.sortModel[0].sort === SetupGridComp.GRID_SORT_ASCENDING) {
                this.boardBasisSetupSearchCriteria.sortDirection = SortDirection.ASC;
            } else {
                this.boardBasisSetupSearchCriteria.sortDirection = SortDirection.DESC;
            }
        }
        return this.boardBasisSetupService.getRows(this.boardBasisSetupSearchCriteria)
            .pipe(
                tap(data =>
                    this.dataStoreService.set(DataKey.boardBasisSetupSearchResultsForCriteria, data)
                )
            );
    }

    public createNewRow(): any {
        return {unsaved: true};
    }

    public ngOnDestroy(): void {
        if (this.searchCriteriaObserver) {
            this.searchCriteriaObserver.unsubscribe();
        }
    }

    getCategoryText = (params) => {
        if (params && params.data) {
            return params.data;
        }
        return EMPTY_CHAR;
    };

}
