import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { TcApiError } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-api-error';
import { TcErrorType } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-error';
import { TcHttpError } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-http-error';
import { DialogModel, LEVEL } from '@tc-core/model/it/codegen/ui/framework/dialog-model';
import { Log } from '@tc-core/model/it/codegen/ui/framework/log/log';
import { ModalData } from '@tc-core/model/it/codegen/ui/framework/modal-data';
import { ERROR_LEVEL, PageSection } from '@tc-core/model/it/codegen/ui/framework/page-section';
import { SummaryCardData } from '@tc-core/model/it/codegen/ui/framework/summary-card-data';
import { TC } from '@tc-core/util/constants';
import { ConfigLoader } from '@tc-core/util/framework/config-loader.service';
import { EventManager } from '@tc-core/util/framework/event-manager.service';
import { FocusViewManager } from '@tc-core/util/framework/focus-view-manager.service';
import { UserJourneyManager } from '@tc-core/util/framework/user-journey-manager.service';
import { CommonHelper } from '@tc-core/util/helpers/common-helper.service';
import { KebabService } from '@tc-core/util/ui';
import { SpinnerService } from '@tc-core/util/ui/spinner.service';
import { Chip } from '@tc/chip-selector/chip.model';
import { DialogService } from '@tc/dialog/dialog.service';
import { ModalService } from '@tc/modal/modal.service';
import { SorterPipe } from '@tc/pipes/sorter.pipe';
import { take } from 'rxjs/operators';
import { UserProfile } from '../../../authentication/user-profile';
import { TCO } from '../../../constants';
import { SupplierService } from '../../../services/backend-consumers/supplier-service/supplier.service';
import { ManageNotesComponent } from '../../../widgets/framework/manage-notes';
import { PaymentInfo } from '../../../models/common/payment-info';
import { PageType } from '../../../models/helper/page-type';
import { Booking } from '../../../models/reservation-v2/booking';
import { BookingItem } from '../../../models/reservation-v2/booking-item';
import { BookingItemNoteType, BookingNote, BookingNoteType } from '../../../models/reservation-v2/booking-note';
import { BookingPassenger } from '../../../models/reservation-v2/booking-passenger';
import { BookingType } from '../../../models/reservation-v2/booking-type';
import { BookingSearchCriteria } from '../../../models/reservation-v2/criteria/booking-search-criteria';
import { GenBookingItem } from '../../../models/reservation-v2/gen-booking-item';
import { MainType } from '../../../models/reservation-v2/main-type';
import { PassengerType, PassengerTypeName } from '../../../models/reservation-v2/passenger-type';
import { ProductLevel } from '../../../models/reservation-v2/product-level';
import { ServiceSharing } from '../../../models/reservation-v2/service-sharing';
import { TrsBookingItem } from '../../../models/reservation-v2/trs-booking-item';
import { ResourceType } from '../../../models/reservation/assignment';
import { NoteItemView } from '../../../models/reservation/note-item-view';
import { Supplier } from '../../../models/supplier/supplier';
import {
    DataHandlerService
} from '../../../services/backend-consumers/master-data-handler-service/data-handler.service';
import {
    BookingManagementService
} from '../../../services/backend-consumers/reservation-v2/booking-management.service';
import { DMCCommon } from '../../../services/util/common/dmc-common';
import { RootService } from '../../../services/util/core-services/root.service';
import { DataKey, DataStoreService } from '../../../services/util/framework/data-store.service';
import { DMCQueryParamsService } from '../../../services/util/framework/dmc-query-params.service';
import { ResponseUtil } from '../../../services/util/response/response-util.service';
import { DMCLocalStorageService, LocalDataKey } from '../../../services/util/system/dmc-local-storage.service';
import { CardDataHandlerService } from '../../../services/util/ui/card-data-handler-service.service';
import { ContentSummaryCardService } from '../../../services/util/ui/content-summary-card.service';
import { BookingUtilService } from '../booking-util.service';
import { BookingItemsComponent } from './booking-items/booking-items.component';
import { PassengerSelectionComponent } from './booking-items/passenger-selection/passenger-selection.component';
import { TrsItemComponent } from './booking-items/trs-item/trs-item.component';
import { BookingNotesComponent } from './booking-notes/booking-notes.component';
import { PassengerGenerationComponent } from './passenger-generation/passenger-generation.component';
import { BookingPassengersComponent } from './passengers/booking-passengers.component';

@Component({
    selector: 'tc-booking',
    templateUrl: './booking.component.html'
})
export class BookingComponent implements OnInit, OnDestroy {

    pageType = '';

    readonly = false;
    editable = false;

    // master data
    titles: any[] = [];
    passengerTypes: any[] = [];
    bookingNoteTypes: BookingNoteType[] = [
        BookingNoteType.SUPPLIER,
        BookingNoteType.INVOICE,
        BookingNoteType.TICKETING,
        BookingNoteType.BOOKING,
        BookingNoteType.ITINERARY,
        BookingNoteType.VOUCHER,
        BookingNoteType.TRANSFER_INFO,
        BookingNoteType.OSI,
        BookingNoteType.OTHER
    ];
    trsModes: any[] = [];
    nextPassengerTempId = 1;

    booking: Booking;
    bookingUnedited: Booking;
    supplier: Supplier;
    userProfile: UserProfile;

    dataChanged = false;
    formSaved = false;
    isSaveEnable = false;
    invalidKeyControl = false;

    bookingInfoSectionValid = false;
    keyControlInformationValid = false;
    passengersSectionValid = false;
    bookingItemSectionValid = false;
    bookingNoteSectionValid = false;
    disableKeyControlEdit = false;

    // page section
    private pageSectionsMap: Map<string, PageSection>;
    public bookingInfoSection: PageSection;
    public supplierKeyControlSection: PageSection;
    public passengersSection: PageSection;
    public bookingItemSection: PageSection;
    public bookingNoteSection: PageSection;

    private CARD_CONFIG_BOOKING_EDIT = 'booking_edit';

    private headerTextNew = 'New Booking';
    private headerTextEdit = 'Edit Booking';

    addNoteTitle = 'Add New Note';
    noteSummary: SummaryCardData;
    CARD_CONFIG_NOTES = 'notes';
    noteContents: NoteItemView[] = [];
    masterNoteTypes = [];

    COMMON_TITLE_ADULTS = 'Mx';
    COMMON_TITLE_CHILDREN = 'Mx';
    COMMON_TITLE_INFANTS = 'Mstr';
    DUMMY_FIRST_NAME = 'Dummy_';
    DUMMY_LAST_NAME = 'Name';

    @ViewChild('passengersComponent') passengerComponent: BookingPassengersComponent;
    @ViewChild('bookingNotesComponent') bookingNotesComponent: BookingNotesComponent;

    itemAddingKebabMenu: any;
    bookingItemCardConfig: any;
    bookingItemSummaryCards: SummaryCardData[] = [];

    constructor(
        private route: ActivatedRoute,
        private dataStore: DataStoreService,
        private localStorage: DMCLocalStorageService,
        private cardDataHandlerService: CardDataHandlerService,
        private userJourneyManager: UserJourneyManager,
        private em: EventManager,
        private location: Location,
        private bookingManagementService: BookingManagementService,
        private spinnerService: SpinnerService,
        private commonHelper: CommonHelper,
        private common: DMCCommon,
        private rootService: RootService,
        private queryParamService: DMCQueryParamsService,
        private focusViewManager: FocusViewManager,
        private dataHandlerService: DataHandlerService,
        private configLoader: ConfigLoader,
        private summaryCardService: ContentSummaryCardService,
        private supplierService: SupplierService,
        private kebabService: KebabService,
        private modalService: ModalService,
        private bookingUtil: BookingUtilService,
        private dialogService: DialogService,
        public dialog: MatDialog,
        private sortPipe: SorterPipe
    ) {
    }

    ngOnInit() {
        this.bookingItemCardConfig = this.configLoader.configurations.get(TCO.CONF.CONF_BOOKING_ITEM_CARD_CONFIG);
        this.pageType = this.route.snapshot.queryParamMap.get('page').split('_')[0];
        this.retrieveMasterData();
        this.initDataModel();
        if (this.pageType === PageType.CREATE) {
            this.dataStore.get(DataKey.selectedBookingForEditAsNew, true)
                .pipe(take(1))
                .subscribe(data => {
                    if (data != null) {
                        this.dataStore.set(DataKey.selectedBookingForEditAsNew, null, true);
                        data.bookingType = BookingType.CREATED;
                        this.fillBookingObject(data);
                    } else {
                        const unsavedData: Booking = this.localStorage.load<Booking>(LocalDataKey.unSavedBooking);
                        if (unsavedData && !unsavedData.id) {
                            this.fillBookingObject(unsavedData);
                        }
                    }
                    this.initPageSectionReferences();
                });
        } else if (this.pageType === PageType.EDIT) {
            this.editable = true;
            this.dataStore.get(DataKey.selectedBookingForEdit, true)
                .pipe(take(1))
                .subscribe(data => {
                    if (data != null) {
                        // for change detection purpose
                        this.bookingUnedited = JSON.parse(JSON.stringify(data));
                        this.disableKeyControlEdit = this.bookingUnedited.isEditTriggered;

                        this.fillBookingObject(data);
                    } else {
                        const unsavedData: Booking = this.localStorage.load<Booking>(LocalDataKey.unSavedBooking);
                        this.bookingUnedited = JSON.parse(JSON.stringify(unsavedData));
                        this.fillBookingObject(unsavedData);
                    }
                    this.initPageSectionReferences();
                });
        }

        // save event subscription
        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'SAVE').subscribe(e => {
            this.saveBooking();
        });
        this.createBookingItemSummaryCards();
        this.setItemAddingKebabMenus();
        this.setKebabActionTriggers();
        this.onNoteListUpdate();
        this.onBookingItemSectionChange(this.checkBookingItemValidity());
        this.getUserDetails();

        this.supplier = new Supplier();
        this.supplier.kcCompany = this.userProfile.kcCompany;
        this.supplier.kcDivision = this.userProfile.kcDivision;
        this.supplier.kcBrand = this.userProfile.kcBrand;
        this.supplier.kcDistributionChannel = this.userProfile.kcDistributionChannel;
    }

    private retrieveMasterData() {
        this.dataHandlerService.retrieveTitleDetails().subscribe(
            result => {
                this.titles = result.data;
            });
        this.passengerTypes = [
            PassengerType.A,
            PassengerType.T,
            PassengerType.C,
            PassengerType.I
        ];
        this.dataHandlerService.getTransferModes().subscribe(
            result => {
                this.trsModes = result.data;
            });
    }

    private initPageSectionReferences() {
        this.pageSectionsMap = this.cardDataHandlerService.getAllPageSections(
            this.CARD_CONFIG_BOOKING_EDIT,
            this.booking
        );

        this.bookingInfoSection = this.pageSectionsMap.get('booking_info');
        this.supplierKeyControlSection = this.pageSectionsMap.get('supplier_key_controls');
        this.passengersSection = this.pageSectionsMap.get('passengers');
        this.bookingItemSection = this.pageSectionsMap.get('booking_items');
        this.bookingNoteSection = this.pageSectionsMap.get('booking_notes');
        this.setupHeader();
    }

    private setupHeader() {
        if (this.pageType === PageType.CREATE) {
            this.userJourneyManager.setCurrentHeading(this.headerTextNew);
        } else if (this.pageType === PageType.EDIT) {
            this.userJourneyManager.setCurrentHeading(this.headerTextEdit);
        }
        this.setHeaderActions();
        this.setHeaderActionTriggers();
    }

    private setHeaderActionTriggers() {
        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'TRIGGER_CLOSE')
            .subscribe(e => {
                this.onCloseClick(null);
            });
    }

    public onCloseClick(event) {
        this.location.back();
    }

    private setHeaderActions() {
        const actionKeys = [];
        actionKeys.push('TRIGGER_CLOSE');
        this.userJourneyManager.setHeaderActions(actionKeys);
    }

    private removeHeaderActions() {
        this.userJourneyManager.setHeaderActions([]);
        this.em.removeEvents([
            'TRIGGER_CLOSE'
        ]);
    }

    setupSectionTabError(section: PageSection): boolean {
        if (!section) {
            return false;
        }
        const shouldShow = section.showError === ERROR_LEVEL.SHOW_ERROR_TAB || section.showError ===
            ERROR_LEVEL.SHOW_ERROR_TAB_FORM;
        return section.error && shouldShow;
    }

    private setItemAddingKebabMenus() {
        this.itemAddingKebabMenu = this.kebabService.get('BOOKING_ITEMS').items;
    }

    private setKebabActionTriggers() {
        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'TRIGGER_ADD_TRS_ITEM')
            .subscribe(e => {
                this.addTrsItem();
            });
        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'TRIGGER_ADD_GEN_ITEM')
            .subscribe(e => {
                this.addGenItem();
            });
        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'TRIGGER_ADD_ACCOM_ITEM')
            .subscribe(e => {
                this.addAccomItem();
            });
    }

    private removeKebabActionTriggers() {
        this.em.removeEvents([
            'TRIGGER_ADD_TRS_ITEM',
            'TRIGGER_ADD_GEN_ITEM',
            'TRIGGER_ADD_ACCOM_ITEM'
        ]);
    }

    public onHeaderActionClick(event) {
        switch (event.id) {
            case 'TRIGGER_ADD_PASSENGER': {
                console.log('add passenger');
                this.addPassenger();
                break;
            }
            case 'TRIGGER_GENERATE_PASSENGERS': {
                console.log('generate passengers');
                this.onGeneratePassengerClick();
                break;
            }
            case 'TRIGGER_ADD_NOTE': {
                console.log('add note');
                this.onAddNotesClick();
                break;
            }
            case 'TRIGGER_ADD_TRS_ITEM': {
                console.log('add trs item');
                this.addTrsItem();
                break;
            }
            case 'TRIGGER_ADD_GEN_ITEM': {
                console.log('add gen item');
                this.addGenItem();
                break;
            }
            case 'TRIGGER_ADD_ACCOM_ITEM': {
                console.log('add accom item');
                this.addAccomItem();
                break;
            }
        }
    }

    private initDataModel() {
        if (!this.booking) {
            this.booking = new Booking();
        }
        if (!this.booking.bookingItems) {
            this.booking.bookingItems = [];
        }
        if (!this.booking.bookingPassengers) {
            this.booking.bookingPassengers = [];
        }
        if (!this.booking.bookingNotes) {
            this.booking.bookingNotes = [];
        }
        for (const bookingPassenger of this.booking.bookingPassengers) {
            if (bookingPassenger.tempId > this.nextPassengerTempId) {
                this.nextPassengerTempId = bookingPassenger.tempId;
            }
        }
    }

    fillBookingObject(data: Booking) {
        this.booking = data;
        this.localStorage.store(LocalDataKey.unSavedBooking, this.booking);
        this.initDataModel();
        this.checkEditable();
        this.setReadOnlyPageSectionActions();
    }

    checkEditable() {
        if (this.booking.bookingType === BookingType.CREATED) {
            this.readonly = false;
        } else {
            this.readonly = true;
        }
    }

    setReadOnlyPageSectionActions() {

    }

    addPassenger() {
        if (!this.readonly) {
            const bookingPassenger = new BookingPassenger();
            bookingPassenger.tempId = ++this.nextPassengerTempId;
            this.booking.bookingPassengers.push(bookingPassenger);
            if (this.passengerComponent) {
                this.passengerComponent.updateGrid();
                this.onPassengerSectionChange(false);
            }
            this.addPassengerToItemPassengers(bookingPassenger);
            this.enableSaveButton();
        }
    }

    onAddNotesClick() {
        let addNoteDialogRef = this.getNoteDialogRef(
            this.addNoteTitle,
            '',
            '',
            '',
            '',
            false
        );
        addNoteDialogRef.afterClosed().subscribe(result => {
            if (result) {
                console.log(result);
                this.saveBookingNote(result);
            }
        });
    }

    getNoteDialogRef(
        noteTitle: any, noteText: any, noteType: any, noteMethodCodes: string, noteResourcePath: string,
        isEdit: boolean = false
    ): any {
        let noteTypes = this.sortPipe.transform(this.masterNoteTypes, 'name');

        return this.dialog.open(
            ManageNotesComponent, {
                width: '', height: '',
                data: {
                    title: noteTitle,
                    note: noteText,
                    noteTypes: noteTypes,
                    selectedNoteType: noteType,
                    isNoteEditMode: isEdit,
                    selectedNoteTypes: this.getSelectedNoteType(noteTypes, noteType),
                    methodCodes: noteMethodCodes,
                    resourcePath: noteResourcePath,
                    isMultiSelect: false
                }
            }
        );
    }

    private getSelectedNoteType(noteTypes: any, noteType: any) {
        let selectedNotes = [];
        if (noteTypes && noteTypes.length > 0 && noteType) {
            let selectedNoteTypes = noteTypes.filter(note => note.code === noteType);
            let chip = new Chip();
            chip.selected = true;
            chip.value = selectedNoteTypes[0];
            chip.restricted = false;
            selectedNotes.push(chip);
        }
        return selectedNotes;
    }

    public onDeletePassenger(event: BookingPassenger) {
        if (!this.readonly && !this.isLeadPassenger(event)) {
            const confirmDialog = new DialogModel(
                true,
                LEVEL.WARNING,
                'Delete',
                `Are you sure want to delete the passenger ?`,
                true,
                2000,
                null,
                'No',
                'Yes',
                true
            );
            this.dialogService
                .confirm(confirmDialog)
                .subscribe((res) => {
                    if (res == true) {
                        const index = this.booking.bookingPassengers.indexOf(event);
                        this.booking.bookingPassengers.splice(index, 1);
                        this.passengerComponent.updateGrid();
                        this.onPassengerSectionChange(true);
                        this.removePassengerFromItemPassengers(event);
                    }
                });
        } else {
            this.common.showSnackBar(
                'You can\'t delete this passenger',
                3000,
                TcErrorType.TYPE.ERROR
            );
        }
    }

    public onDeleteNote(event: BookingNote) {
        if (!this.readonly) {
            const confirmDialog = new DialogModel(
                true,
                LEVEL.WARNING,
                'Delete',
                `Are you sure want to delete the note ?`,
                true,
                2000,
                null,
                'No',
                'Yes',
                true
            );
            this.dialogService
                .confirm(confirmDialog)
                .subscribe((res) => {
                    if (res == true) {
                        const index = this.booking.bookingNotes.indexOf(event);
                        this.booking.bookingNotes.splice(index, 1);
                        this.bookingNotesComponent.updateGrid();
                        this.onBookingNoteSectionChange(true);
                    }
                });
        }
    }

    private addTrsItem() {
        if (!this.readonly) {
            const bookingItem = this.mapTrsItemData();
            this.bookingUtil.setBookingReferenceId(this.booking, bookingItem, 'trs');
            this.booking.bookingItems.push(bookingItem);
            this.createBookingItemSummaryCards();
            this.onBookingItemSectionChange(false);
        }
    }

    private mapTrsItemData() {
        const bookingItem = new BookingItem();
        bookingItem.productLevel = ProductLevel.COMP;
        bookingItem.mainType = MainType.TRS;
        bookingItem.pvtShared = ServiceSharing.SHARED;

        bookingItem.passengerTempIds = this.getAllBookingPassengerTempIds();
        bookingItem.bookingPassengers = [];
        bookingItem.passengerTempIds = this.booking.bookingPassengers.map(p => p.tempId);
        if (this.booking.bookingPassengers && this.booking.bookingPassengers.length) {
            bookingItem.leadPassenger = this.booking.bookingPassengers[0];
        }

        bookingItem.trsBookingItem = new TrsBookingItem();
        bookingItem.bookingSourceId = this.booking.bookingId;
        bookingItem.bookingSource = this.booking.bookingSource;
        return bookingItem;
    }

    private addGenItem() {
        if (!this.readonly) {
            const bookingItem = this.mapGenItemData();
            this.bookingUtil.setBookingReferenceId(this.booking, bookingItem, 'gen');
            this.booking.bookingItems.push(bookingItem);
            this.createBookingItemSummaryCards();
            this.onBookingItemSectionChange(false);
        }
    }

    private addAccomItem() {
        if (!this.readonly) {
            const bookingItem = this.mapGenItemData();
            bookingItem.mainType = MainType.HTL;
            bookingItem.genBookingItem.productCode = ResourceType.hotel;
            this.bookingUtil.setBookingReferenceId(this.booking, bookingItem, 'accom');
            this.booking.bookingItems.push(bookingItem);
            this.createBookingItemSummaryCards();
            this.onBookingItemSectionChange(false);
        }
    }

    private getAllBookingPassengerTempIds() {
        const ids = [];
        if (this.booking.bookingPassengers) {
            for (const bookingPassenger of this.booking.bookingPassengers) {
                ids.push(bookingPassenger.tempId);
            }
        }
        return ids;
    }

    public onItemPassengersClick(event: BookingItem) {
        console.log('passenger clicked');
        this.dataStore.set(DataKey.popupClose, null);
        const output = {
            changed: undefined,
            valid: undefined
        };
        if (this.booking.bookingPassengers && this.booking.bookingPassengers.length > 0) {
            const dataObject = new ModalData(
                LEVEL.SUCCESS,
                'Item Passengers',
                null,
                null,
                'modal-basic--alt modal-basic--fixed-size',
                PassengerSelectionComponent,
                {
                    booking: this.booking,
                    bookingItem: event,
                    readonly: this.readonly,
                    onChangeFn: this.passengerSelectionChangeFn.bind(this)
                }
            );
            this.confirmModal(dataObject);
            this.dataStore.get(DataKey.popupClose).subscribe((data) => {
                if (data != null) {
                    this.closeModal();
                    if (data.changed) {
                        this.dataChanged = true;
                    }
                }
            });
        } else {
            let confirmDataObject = new DialogModel(
                true,
                LEVEL.WARNING,
                'Passengers',
                'No Passengers added.',
                true,
                3000
            );
            this.dialogService.confirm(confirmDataObject).subscribe(
                (res) => {
                    if (res === true) {
                    }
                });
        }

    }

    passengerSelectionChangeFn = (valid) => {
        this.onBookingItemSectionChange(valid);
    };

    passengerGenerationChangeFn = (passengers) => {
        this.generatePassengers(passengers);
    };

    closeModal() {
        if (this.modalService.close()) {
            this.modalService.close().subscribe((res) => {
            });
        }
    }

    private confirmModal(data: any): void {
        this.modalService.confirm(data).subscribe((res) => {
        });
    }

    public onBookingItemDelete(event: BookingItem) {
        if (!this.readonly) {
            const confirmDialog = new DialogModel(
                true,
                LEVEL.WARNING,
                'Delete',
                `Are you sure want to delete the Booking Item ?`,
                true,
                2000,
                null,
                'No',
                'Yes',
                true
            );
            this.dialogService
                .confirm(confirmDialog)
                .subscribe((res) => {
                    if (res == true) {
                        const index = this.booking.bookingItems.indexOf(event);
                        this.booking.bookingItems.splice(index, 1);
                        this.createBookingItemSummaryCards();
                        this.onBookingItemSectionChange(this.checkBookingItemValidity());
                    }
                });
        }
    }

    private checkBookingItemValidity() {
        const allValid = true;
        for (const bookingItem of this.booking.bookingItems) {
            if (bookingItem.itemName
                && bookingItem.bookingReferenceId
                && bookingItem.startTime
                && bookingItem.endTime
                && bookingItem.adultCount) {
                if (bookingItem.genBookingItem && bookingItem.genBookingItem.productCode
                    && bookingItem.plCode) {

                } else if (bookingItem.genBookingItem
                    && bookingItem.genBookingItem.hotelRoomType && bookingItem.genBookingItem.hotelBoardBasis) {

                } else if (bookingItem.trsBookingItem && bookingItem.trsBookingItem.transferMode
                    && bookingItem.trsBookingItem.pickupPointName
                    && bookingItem.trsBookingItem.dropOffPointName
                    && bookingItem.plCode) {

                } else {
                    return false;
                }

            } else {
                return false;
            }
        }
        return allValid;
    }

    createBookingItemSummaryCards() {
        if (this.booking && this.booking.bookingItems) {
            const dataArray = [];
            for (const bookingItem of this.booking.bookingItems) {
                let iconKey = '';
                if (bookingItem.genBookingItem) {
                    if (bookingItem.genBookingItem && bookingItem.mainType === MainType.HTL) {
                        iconKey = 'accom';
                    } else if (bookingItem.genBookingItem.productCode) {
                        iconKey = bookingItem.genBookingItem.productCode;
                    } else {
                        iconKey = 'generic';
                    }
                } else if (bookingItem.trsBookingItem) {
                    iconKey = 'vehicle';
                }
                dataArray.push(
                    {
                        iconType: iconKey,
                        bookingReferenceId: bookingItem.bookingReferenceId
                    }
                );
            }
            this.bookingItemSummaryCards = this.summaryCardService.getContentCardsByConfig(
                this.bookingItemCardConfig,
                dataArray
            );
        } else {
            this.bookingItemSummaryCards = [];
        }
    }

    ngOnDestroy(): void {
        this.removeHeaderActions();
        this.removeKebabActionTriggers();
        this.localStorage.delete(LocalDataKey.unSavedBooking);
    }

    private preProcessBooking(booking: Booking) {
        if (booking.bookingItems) {
            const bookingNotes = booking.bookingNotes;
            bookingNotes.forEach(n => n.itemNoteType = BookingItemNoteType.BOOKING);
            for (const bookingItem of booking.bookingItems) {
                if (bookingItem && bookingItem.defaultSupplier && !this.invalidKeyControl) {
                    const detailedSupplier = bookingItem.defaultSupplier;
                    this.validateBookingItemKeyControls(detailedSupplier, booking);
                }

                // notes
                bookingItem.bookingNotes = [];
                bookingItem.bookingNotes.push(...bookingNotes);
                if (bookingItem.itemNotes) {
                    bookingItem.itemNotes.forEach(n => n.itemNoteType = BookingItemNoteType.BOOKING_ITEM);
                    bookingItem.bookingNotes.push(...bookingItem.itemNotes);
                }

                // passengers
                const bookingItemPassengers = [];
                if (bookingItem && bookingItem.bookingPassengers) {
                    bookingItem.bookingPassengers.forEach(value => bookingItemPassengers.push(value));
                }
                if (bookingItem.passengerTempIds instanceof Array) {
                    for (const tId of bookingItem.passengerTempIds) {
                        if (this.booking && this.booking.bookingPassengers) {
                            let pInItem = null;
                            if (bookingItem && bookingItem.bookingPassengers) {
                                pInItem = bookingItem.bookingPassengers.find(p => p.tempId === tId);
                            }
                            const pInBooking = this.booking.bookingPassengers.find(p => p.tempId === tId);
                            if (pInBooking) {
                                const pInBookingCloned: BookingPassenger = JSON.parse(JSON.stringify(pInBooking));
                                if (pInItem) {
                                    pInBookingCloned.id = pInItem.id;
                                    pInBookingCloned.lead = false;
                                }
                                bookingItemPassengers.push(pInBookingCloned);
                            }
                        }
                    }
                }
                bookingItem.bookingPassengers = bookingItemPassengers;
                if (bookingItem.leadPassenger) {
                    const lead = bookingItem.bookingPassengers.find(p => p.tempId === bookingItem.leadPassenger.tempId);
                    lead.lead = true;
                }
                bookingItem.bookingPassengers = bookingItemPassengers;
            }
        }
        console.log(booking);
    }

    private validateBookingItemKeyControls(detailedSupplier: Supplier, booking: Booking) {
        if (detailedSupplier
            && ((detailedSupplier.kcDivision && booking.kcDivision && detailedSupplier.kcDivision !==
                    booking.kcDivision)
                || (detailedSupplier.kcBrand && booking.kcBrand && detailedSupplier.kcBrand !==
                    booking.kcBrand)
                || (detailedSupplier.kcDistributionChannel && booking.kcDistributionChannel &&
                    detailedSupplier.kcDistributionChannel !== booking.kcDistributionChannel))) {
            this.invalidKeyControl = true;
        }
    }

    private saveBooking() {
        console.log(this.booking);
        if (this.pageType === PageType.CREATE || !this.booking.id) {
            // this.spinnerService.show();
            this.preProcessBooking(this.booking);
            if (!this.invalidKeyControl) {
                this.bookingManagementService.saveBooking(this.booking)
                    .subscribe(value => {
                        if (this.commonHelper.dataValidity(value)) {
                            this.spinnerService.hide();
                            if (ResponseUtil.isSuccess(value)) {
                                this.formSaved = true;
                                this.dataChanged = false;
                                this.bookingUnedited = JSON.parse(JSON.stringify(this.booking));
                                this.localStorage.delete(LocalDataKey.unSavedBooking);
                                this.common.showSnackBar(
                                    'Successfully saved the booking.',
                                    3000,
                                    TcErrorType.TYPE.INFO
                                );
                                this.handleNavigation(value);
                            } else if (ResponseUtil.isError(value)) {
                                this.common.showSnackBar('Failed to save the booking', 3000, TcErrorType.TYPE.ERROR);
                            }
                        } else if (value instanceof TcApiError) {
                            this.spinnerService.hide();
                        } else if (value instanceof TcHttpError) {
                            this.spinnerService.hide();
                        }
                    }, error => {
                    });
            } else {
                this.invalidKeyControl = false;
                this.common.showSnackBar(
                    'Inserted key controls do not match with the default supplier key controls ',
                    3000,
                    TcErrorType.TYPE.ERROR
                );
            }
        } else if (this.pageType === PageType.EDIT && this.booking.id) {
            this.dataStore.set(DataKey.updateBooking, null, true);
            this.spinnerService.show();
            this.preProcessBooking(this.booking);
            if (!this.invalidKeyControl) {
                this.bookingManagementService.saveBooking(this.booking).subscribe(value => {
                    if (this.commonHelper.dataValidity(value)) {
                        this.spinnerService.hide();
                        if (ResponseUtil.isSuccess(value)) {
                            this.formSaved = true;
                            this.dataChanged = false;
                            this.bookingUnedited = JSON.parse(JSON.stringify(this.booking));
                            this.common.showSnackBar('Successfully updated the booking', 3000, TcErrorType.TYPE.INFO);
                            this.localStorage.delete(LocalDataKey.unSavedBooking);
                            this.handleNavigation(value);
                        } else if (ResponseUtil.isError(value)) {
                            this.common.showSnackBar('Failed to update the booking', 3000, TcErrorType.TYPE.ERROR);
                        }
                    } else if (value instanceof TcApiError) {
                        this.spinnerService.hide();
                    } else if (value instanceof TcHttpError) {
                        this.spinnerService.hide();
                    }
                }, error => {
                });
            } else {
                this.invalidKeyControl = false;
                this.common.showSnackBar(
                    'Inserted key controls do not match with the default supplier key controls ',
                    3000,
                    TcErrorType.TYPE.ERROR
                );
            }
        }
    }

    handleNavigation(data: any) {
        if (data && JSON.stringify(data) !== JSON.stringify({})) {
            if (data.status.code === TC.ErrorCodes.SUCCESS) {

                if (data && data.data && data.data.length > 0 && data.data[0].bookingIdCombined) {
                    const bookingSearchCriteria: BookingSearchCriteria = new BookingSearchCriteria();
                    bookingSearchCriteria.bookingId = data.data[0].bookingId;
                    console.log(bookingSearchCriteria);
                    const parameters = this.queryParamService.getNotNullQueryParamsArray(bookingSearchCriteria);
                    this.rootService.setDataToLocalStorage(
                        TCO.AppData.BOOKING_SEARCH_CRITERIA,
                        bookingSearchCriteria,
                        true,
                        parameters
                    );
                }

                setTimeout(() => {
                    const journeyToProceed = 'BEGIN_BOOKING_MANAGEMENT';
                    if (journeyToProceed) {
                        this.userJourneyManager.goForKey(journeyToProceed);
                    }

                }, 100);
            } else {
                if (data.status.code === TC.ErrorCodes.ERROR && data.error) {
                }
            }
        }
    }

    // -----------change forms--------------------

    public onBookingInfoSectionChange(event: boolean) {
        this.bookingInfoSectionValid = event;
        if (this.bookingInfoSection) {
            this.bookingInfoSection.error = !this.bookingInfoSectionValid;
        }
        this.dataChanged = true;
        if (this.bookingInfoSectionValid) {
            this.enableSaveButton();
        } else {
            this.disableSaveButton();
        }
    }

    public onChangeKeyControlInfoForm(event) {
        this.keyControlInformationValid = event;
        this.enableSaveButton();
        if (this.supplierKeyControlSection) {
            this.supplierKeyControlSection.error = !this.keyControlInformationValid;
        }
    }

    public onPassengerSectionChange(event: boolean) {
        this.passengersSectionValid = event;
        if (this.passengersSection) {
            this.passengersSection.error = !this.passengersSectionValid;
        }
        this.dataChanged = true;
        if (this.passengersSectionValid) {
            this.enableSaveButton();
        } else {
            this.disableSaveButton();
        }
    }

    public onBookingItemSectionChange(event: boolean) {
        this.bookingItemSectionValid = event;
        const invalidAdultCountBookingItem = this.booking.bookingItems.find((bookingItem) => !(bookingItem.adultCount >
            0));
        if (invalidAdultCountBookingItem !== undefined) {
            this.bookingItemSectionValid = false;
        }
        if (this.bookingItemSection) {
            this.bookingItemSection.error = !this.bookingItemSectionValid;
        }
        this.dataChanged = true;
        this.onBookingInfoSectionChange(true);
        if (this.bookingItemSectionValid) {
            this.enableSaveButton();
        } else {
            this.disableSaveButton();
        }
    }

    public onBookingNoteSectionChange(event: boolean) {
        this.bookingNoteSectionValid = event;
        if (this.bookingNoteSection) {
            this.bookingNoteSection.error = !this.bookingNoteSectionValid;
        }
        this.dataChanged = true;
        if (this.bookingNoteSectionValid) {
            this.enableSaveButton();
        } else {
            this.disableSaveButton();
        }
    }

    private enableSaveButton() {
        if (JSON.stringify(this.booking) !== JSON.stringify(this.bookingUnedited)) {
            this.dataChanged = true;
            this.formSaved = false;
        } else {
            this.dataChanged = false;
        }
        if (this.dataChanged && !this.formSaved
            && this.bookingInfoSectionValid
            && this.passengersSectionValid
            && this.bookingItemSectionValid
            && this.bookingNoteSectionValid
            && this.keyControlInformationValid
            && this.booking && this.booking.bookingItems && this.booking.bookingItems.length
            && this.booking.bookingPassengers && this.booking.bookingPassengers.length
        ) {
            this.userJourneyManager.canProceed.next(true);
            this.focusViewManager.canProceed.next(true);
            this.isSaveEnable = true;
            this.localStorage.store(LocalDataKey.unSavedBooking, this.booking);
        } else {
            this.disableSaveButton();
        }
    }

    private disableSaveButton() {
        this.userJourneyManager.canProceed.next(false);
        this.focusViewManager.canProceed.next(false);
        this.isSaveEnable = false;
    }

    private removePassengerFromItemPassengers(passenger: BookingPassenger) {
        // const bookingPassengerFound = this.booking.bookingPassengers.find(value => value.bookingId === passenger.bookingId);
        // if (bookingPassengerFound) {
        //     const index = this.booking.bookingPassengers.indexOf(bookingPassengerFound);
        //     this.booking.bookingPassengers.splice(index, 1);
        // }
        if (this.booking.bookingItems) {
            for (const bookingItem of this.booking.bookingItems) {
                const found = bookingItem.passengerTempIds.find(i => i === passenger.tempId);
                // const passengerFound = bookingItem.bookingPassengers.find(value => value.bookingId === passenger.bookingId);
                if (found) {
                    const index = bookingItem.passengerTempIds.indexOf(found);
                    bookingItem.passengerTempIds.splice(index, 1);
                    if (bookingItem.leadPassenger && bookingItem.leadPassenger.tempId === passenger.tempId) {
                        bookingItem.leadPassenger = null;
                    }
                }
                // if (passengerFound) {
                //     const index = bookingItem.bookingPassengers.indexOf(passengerFound);
                //     bookingItem.bookingPassengers.splice(index, 1);
                //     if (bookingItem.leadPassenger && bookingItem.leadPassenger.tempId === passenger.tempId) {
                //         bookingItem.leadPassenger = null;
                //     }
                // }
                if (!bookingItem.passengerTempIds || bookingItem.passengerTempIds.length === 0 ||
                    !bookingItem.leadPassenger) {
                    this.onBookingItemSectionChange(false);
                }
            }
        }
    }

    private addPassengerToItemPassengers(passenger: BookingPassenger) {
        if (this.booking.bookingItems) {
            for (const bookingItem of this.booking.bookingItems) {
                bookingItem.passengerTempIds.push(passenger.tempId);
            }
        }
    }

    private isLeadPassenger(passenger: BookingPassenger) {
        const passengerBookingItems = [];
        if (this.booking.bookingItems) {
            for (const bookingItem of this.booking.bookingItems) {
                if (bookingItem.passengerTempIds.includes(passenger.tempId)) {
                    const passengerIsLead = bookingItem.bookingPassengers.find(value => value.tempId ===
                        passenger.tempId && value.lead);
                    if (passengerIsLead) {
                        return true;
                    }
                }

            }
        }
        return false;
    }

    public editAsNewItem(item: BookingItem) {
        if (item) {
            let bookingItem: BookingItem;
            if (item.mainType === MainType.TRS) {
                bookingItem = this.mapTrsItemData();
                this.mapCommonItemData(bookingItem, item);
                bookingItem.trsBookingItem.transferMode = item.trsBookingItem.transferMode;
                bookingItem.trsBookingItem.airportOperationTimeMinutes = item.trsBookingItem.airportOperationTimeMinutes;
                bookingItem.trsBookingItem.pickupPointName = item.trsBookingItem.pickupPointName;
                bookingItem.trsBookingItem.pickupPointCode = item.trsBookingItem.pickupPointCode;
                bookingItem.trsBookingItem.dropOffPointName = item.trsBookingItem.dropOffPointName;
                bookingItem.trsBookingItem.dropOffPointCode = item.trsBookingItem.dropOffPointCode;
                bookingItem.trsBookingItem.departureDateTime = item.trsBookingItem.departureDateTime;
                bookingItem.trsBookingItem.arrivalDateTime = item.trsBookingItem.arrivalDateTime;
                bookingItem.trsBookingItem.transferMode = item.trsBookingItem.transferMode;
                this.bookingUtil.setBookingReferenceId(this.booking, bookingItem, 'trs');

            } else if (item.mainType === MainType.GEN) {
                bookingItem = this.mapGenItemData();
                this.mapCommonItemData(bookingItem, item);
                bookingItem.genBookingItem.productName = item.genBookingItem.productName;
                bookingItem.genBookingItem.typeName = item.genBookingItem.typeName;
                this.bookingUtil.setBookingReferenceId(this.booking, bookingItem, 'gen');
            } else {
                this.addAccomItem();
            }
            this.booking.bookingItems.push(bookingItem);
            this.createBookingItemSummaryCards();
            this.onBookingItemSectionChange(true);
        }
    }

    private mapCommonItemData(bookingItem: BookingItem, item: BookingItem) {
        bookingItem.itemName = item.itemName;
        bookingItem.contractReference = item.contractReference;
        bookingItem.plCode = item.plCode;
        bookingItem.plName = item.plName;
        bookingItem.defaultSupplierCode = item.defaultSupplierCode;
        bookingItem.defaultSupplierName = item.defaultSupplierName;
        bookingItem.startTime = item.startTime;
        bookingItem.endTime = item.endTime;
        bookingItem.serviceStartDateTime = item.serviceStartDateTime;
        bookingItem.serviceEndDateTime = item.serviceEndDateTime;
        bookingItem.serviceDate = item.serviceDate;
        bookingItem.bookingStatus = item.bookingStatus;
        bookingItem.bookingNotes = item.bookingNotes;
    }

    private mapGenItemData() {
        const bookingItem = new BookingItem();
        bookingItem.productLevel = ProductLevel.COMP;
        bookingItem.mainType = MainType.GEN;
        bookingItem.pvtShared = ServiceSharing.SHARED;

        bookingItem.passengerTempIds = this.getAllBookingPassengerTempIds();
        bookingItem.bookingPassengers = [];
        bookingItem.passengerTempIds = this.booking.bookingPassengers.map(p => p.tempId);
        if (this.booking.bookingPassengers && this.booking.bookingPassengers.length) {
            bookingItem.leadPassenger = this.booking.bookingPassengers[0];
        }

        bookingItem.genBookingItem = new GenBookingItem();
        bookingItem.bookingSourceId = this.booking.bookingId;
        bookingItem.bookingSource = this.booking.bookingSource;
        return bookingItem;
    }

    // Open Generate passenger madal to each passenger count
    private onGeneratePassengerClick() {
        this.dataStore.set(DataKey.popupClose, null);
        const output = {
            changed: undefined,
            valid: undefined
        };
        const dataObject = new ModalData(
            LEVEL.SUCCESS,
            'Passenger Generation',
            null,
            null,
            'modal-basic--medium',
            PassengerGenerationComponent,
            {
                readonly: this.readonly,
                onChangeFn: this.passengerGenerationChangeFn.bind(this)
            }
        );
        this.confirmModal(dataObject);
        this.dataStore.get(DataKey.popupClose).subscribe((data) => {
            if (data != null) {
                this.closeModal();
                if (data.changed) {
                    this.dataChanged = true;
                }
            }
        });
    }

    private generatePassengers(passengers) {
        if (!this.readonly) {
            // generate adults
            let tempIdList = [];
            if (passengers.adults > 0) {
                for (let i = 0; i < passengers.adults; i++) {
                    const bookingPassenger = this.createPassenger();
                    bookingPassenger.type = PassengerType.A;
                    bookingPassenger.typeName = PassengerTypeName[PassengerType.A];
                    bookingPassenger.title = this.COMMON_TITLE_ADULTS;
                    tempIdList.push(bookingPassenger.tempId);
                    this.booking.bookingPassengers.push(bookingPassenger);
                    this.addPassengerToItemPassengers(bookingPassenger);
                }
            }
            // generate teens
            if (passengers.teens > 0) {
                for (let i = 0; i < passengers.teens; i++) {
                    const bookingPassenger = this.createPassenger();
                    bookingPassenger.type = PassengerType.T;
                    bookingPassenger.typeName = PassengerTypeName[PassengerType.T];
                    bookingPassenger.title = this.COMMON_TITLE_ADULTS;
                    tempIdList.push(bookingPassenger.tempId);
                    this.booking.bookingPassengers.push(bookingPassenger);
                    this.addPassengerToItemPassengers(bookingPassenger);
                }
            }
            // generate children
            if (passengers.children > 0) {
                for (let i = 0; i < passengers.children; i++) {
                    const bookingPassenger = this.createPassenger();
                    bookingPassenger.type = PassengerType.C;
                    bookingPassenger.typeName = PassengerTypeName[PassengerType.C];
                    bookingPassenger.title = this.COMMON_TITLE_CHILDREN;
                    tempIdList.push(bookingPassenger.tempId);
                    this.booking.bookingPassengers.push(bookingPassenger);
                    this.addPassengerToItemPassengers(bookingPassenger);
                }
            }
            // generate infants
            if (passengers.infants > 0) {
                for (let i = 0; i < passengers.infants; i++) {
                    const bookingPassenger = this.createPassenger();
                    bookingPassenger.type = PassengerType.I;
                    bookingPassenger.typeName = PassengerTypeName[PassengerType.I];
                    bookingPassenger.title = this.COMMON_TITLE_INFANTS;
                    tempIdList.push(bookingPassenger.tempId);
                    this.booking.bookingPassengers.push(bookingPassenger);
                    this.addPassengerToItemPassengers(bookingPassenger);
                }
            }

            // this.addPassengerListToItemPassengers(tempIdList);
            if (this.passengerComponent) {
                this.passengerComponent.updateGrid();
                this.onPassengerSectionChange(true);
            }
        }
        this.onBookingInfoSectionChange(true);
        this.enableSaveButton();
    }

    // Common passenger creation
    private createPassenger() {
        const bookingPassenger = new BookingPassenger();
        bookingPassenger.tempId = ++this.nextPassengerTempId;
        // For unique identification append passengerTempId
        bookingPassenger.firstName = this.DUMMY_FIRST_NAME + bookingPassenger.tempId;
        bookingPassenger.lastName = this.DUMMY_LAST_NAME;
        return bookingPassenger;
    }

    private addPassengerListToItemPassengers(tempIdList: any[]) {
        if (this.booking.bookingItems) {
            for (const bookingItem of this.booking.bookingItems) {
                bookingItem.passengerTempIds.push(...tempIdList);
            }
        }
    }

    private saveBookingNote(result: any) {
        if (!this.readonly && result) {
            const note = new BookingNote();
            note.note = result.note;
            note.type = result.selectedNoteTypes && result.selectedNoteTypes.length > 0 ?
                result.selectedNoteTypes[0].value.code :
                'BOOKING';
            if (!this.booking.bookingNotes) {
                this.booking.bookingNotes = [];
            }
            this.booking.bookingNotes.push(note);
            this.onNoteListUpdate();
        }
    }

    onNoteListUpdate() {
        this.masterNoteTypes = this.configLoader.configurations.get(TCO.CONF.CONF_CONTENT_LIST_CARD).master_note_types;
        this.noteSummary = this.cardDataHandlerService.processCardData(
            this.CARD_CONFIG_NOTES,
            this.booking.bookingNotes,
            'note-summary'
        );

        if (this.booking.bookingNotes.length > 0) {
            this.noteContents = [];
            for (const note of this.booking.bookingNotes) {
                let noteContent: NoteItemView = new NoteItemView();
                noteContent.header = this.cardDataHandlerService.processCardData(
                    this.CARD_CONFIG_NOTES,
                    note,
                    'note-detail.header'
                );
                noteContent.summary = this.cardDataHandlerService.processCardData(
                    this.CARD_CONFIG_NOTES,
                    note,
                    'note-detail.summary'
                );
                noteContent.detail = this.cardDataHandlerService.processCardData(
                    this.CARD_CONFIG_NOTES,
                    note,
                    'note-detail.detail'
                );
                this.noteContents.push(noteContent);
            }
        }
        this.bookingNoteSectionValid = true;
        this.onBookingInfoSectionChange(true);
    }

    private getUserDetails() {
        this.userProfile = this.dataStore.get(DataKey.userProfile).getValue();
    }
}
