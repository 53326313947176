import * as tslib_1 from "tslib";
import { KeyControl } from '../common/key-control';
var Booking = /** @class */ (function (_super) {
    tslib_1.__extends(Booking, _super);
    function Booking() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.isEditTriggered = false;
        return _this;
    }
    return Booking;
}(KeyControl));
export { Booking };
