import { ConfigLoader } from '@tc-core/util/framework';
import { RequestService } from '../../util/framework/request.service';
import { DMCQueryParamsService } from '../../util/framework/dmc-query-params.service';
import { UrlPaths } from '../url-paths';
import * as i0 from "@angular/core";
import * as i1 from "@tc-core/util/framework/config-loader.service";
import * as i2 from "../../util/framework/request.service";
import * as i3 from "../../util/framework/dmc-query-params.service";
var BoardBasisSetupService = /** @class */ (function () {
    function BoardBasisSetupService(configLoader, requestService, queryParamsService) {
        this.configLoader = configLoader;
        this.requestService = requestService;
        this.queryParamsService = queryParamsService;
    }
    BoardBasisSetupService.prototype.getRows = function (criteria) {
        var reqPrams = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.get(UrlPaths.OP_DATA_URL_KEY, [UrlPaths.OP_DATA_VERSION, UrlPaths.BOARD_BASIS_SETUP_SEARCH], reqPrams);
    };
    BoardBasisSetupService.prototype.deleteRow = function (code) {
        return this.requestService.delete(UrlPaths.OP_DATA_URL_KEY, [UrlPaths.OP_DATA_VERSION, UrlPaths.BOARD_BASIS_SETUP, code]);
    };
    BoardBasisSetupService.prototype.saveRow = function (row) {
        console.log(row);
        return this.requestService.post(UrlPaths.OP_DATA_URL_KEY, [UrlPaths.OP_DATA_VERSION, UrlPaths.BOARD_BASIS_SETUP], row);
    };
    BoardBasisSetupService.prototype.updateRow = function (code, row) {
        return this.requestService.put(UrlPaths.OP_DATA_URL_KEY, [UrlPaths.OP_DATA_VERSION, UrlPaths.BOARD_BASIS_SETUP, code], row);
    };
    BoardBasisSetupService.ngInjectableDef = i0.defineInjectable({ factory: function BoardBasisSetupService_Factory() { return new BoardBasisSetupService(i0.inject(i1.ConfigLoader), i0.inject(i2.RequestService), i0.inject(i3.DMCQueryParamsService)); }, token: BoardBasisSetupService, providedIn: "root" });
    return BoardBasisSetupService;
}());
export { BoardBasisSetupService };
