<!--
<div class="" #form tabindex="0" (focusout)="focusOutFunction($event)">
  &lt;!&ndash;<mat-form-field style="height: 100%;">
    <input
            #inputTextBox
            matInput type="text"
            [placeholder]="'Enter location'"
            [formControl]="locationControl"
            [matAutocomplete]="auto"
            (focus)="onTextInputFocus($event)">
    <mat-autocomplete class="mat-autocomplete-panel-grid-editor"
                      autoActiveFirstOption #auto="matAutocomplete" [style.width]="100"
                      [displayWith]="displayItemFn">
      <mat-option
              *ngFor="let item of filteredLocations " (onSelectionChange)="onSelectLocation(item)"
              [value]="item">
        {{displayItemFn ? displayItemFn(item) : item}}
      </mat-option>
    </mat-autocomplete>

  </mat-form-field>&ndash;&gt;
  <input #search autocorrect="off" autocapitalize="off" spellcheck="off" type="text" class="form-control">
 &lt;!&ndash; <input type="text" class="form-control" placeholder="Location" name="Location" [(ngModel)]="address" #LocationCtrl="ngModel"
         Googleplace (setAddress)="getAddressOnChange($event,LocationCtrl)">&ndash;&gt;
</div>
<div class="price-break-down__detail-view">
  <div class="split-passengers-results__container">

    &lt;!&ndash; header &ndash;&gt;
    <div class="container split-passengers-results__header">
      <div class="row item-header__content">
        <ng-container>
          <div class="col-xs-6 col-md-5">
            <span>LONGITUDE</span>
          </div>
        </ng-container>
        <div class="row tc-item&#45;&#45;center">
        <div class="col-xs-6 col-md-5">{{longitude}}</div>
        </div>
      </div>
    </div>

    &lt;!&ndash; body &ndash;&gt;
    <div class="container split-passengers-results__body">
      <div class="row item-header__content">
        <ng-container>
          <div class="col-xs-6 col-md-3">
            <span>LATITUDE</span>
          </div>
        </ng-container>
      <div class="row tc-item&#45;&#45;center">
        <div class="col-xs-6 col-md-3">{{latitude}}</div>
      </div>
    </div>
  </div>
</div>
-->

<div class="tc-mx-4">
    <div class="row tc-mt-4">
        <div class="col-xs-6">
            <div class="form-group">
                <mat-form-field>
                    <label></label>
                    <input
                            matInput
                            type="text"
                            (keydown.enter)="$event.preventDefault()"
                            placeholder="Search Location"
                            autocorrect="off"
                            autocapitalize="off"
                            #search>
                </mat-form-field>
            </div>
        </div>
    </div>

    <!--  <agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="zoom">-->
    <!--    <agm-marker [latitude]="latitude" [longitude]="longitude" [markerDraggable]="true"-->
    <!--                (dragEnd)="markerDragEnd($event)"></agm-marker>-->
    <!--  </agm-map>-->

    <div class="row">
        <ng-container *ngIf="longitude">
            <div class="col-xs-12 col-md-6 tc-mb-3">
                <div class="tc-content--wrap tc-input-view">
                    <div class="tc-caption-txt txt-light">Longitude</div>
                    <div class="tc-body1--txt">{{longitude}}</div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="latitude">
            <div class="col-xs-12 col-md-6 tc-mb-3">
                <div class="tc-content--wrap tc-input-view">
                    <div class="tc-caption-txt txt-light">Latitude</div>
                    <div class="tc-body1--txt">{{latitude}}</div>
                </div>
            </div>
        </ng-container>
    </div>
</div>

<div class="tc-modal-footer">
    <button class="button-right tc-button-secondary mat-button tc-mr-2" (click)="onClose($event)">
        Close
    </button>
    <button
            class="button-right tc-button-primary mat-button"
            (click)="onSave()"
            [disabled]="!isEnableSaveButton()">
        Save
    </button>
</div>
