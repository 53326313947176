import * as tslib_1 from "tslib";
import { AbstractSearchCriteria } from './abstract-search-criteria';
var ResourceTypeSearchCriteria = /** @class */ (function (_super) {
    tslib_1.__extends(ResourceTypeSearchCriteria, _super);
    function ResourceTypeSearchCriteria() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.code = '';
        _this.name = '';
        _this.codeOrName = '';
        _this.categoryCode = '';
        return _this;
    }
    return ResourceTypeSearchCriteria;
}(AbstractSearchCriteria));
export { ResourceTypeSearchCriteria };
export var Category;
(function (Category) {
    Category["TRANSPORT"] = "transport";
    Category["GENERIC"] = "generic";
})(Category || (Category = {}));
