<!--<div class="row">
    <span>Location Types</span>
    <div class="chip" *ngFor="let data of searchCriteria"
         (click)="toggleValue(data)"
         [ngClass]="selectedCriteria==data ?'active':'' ">

        {{data}}
    </div>
</div>-->

<form [formGroup]="locationGroupForm">
    <div class="container-fluid direct-client-personal-info-edit-card tc-card-wrapper">
        <div class="row">
            <div class="col-md-12 col-lg-12">
                <div class="tc-flex-box">
                    <div class="tc-flex-item tc-item-circle">
                        <div class="icon-box">
                            <i class="material-icons">account_circle</i>
                        </div>
                    </div>
                    <div class="tc-flex-item tc-right-cage">
                        <div class="row">
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                <div class="tc-content--wrap tc-input-view">
                                    <div class="tc-caption-txt txt-light">{{'Group Name' | translate}}</div>
                                    <div class="tc-body1--txt">{{locationName}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                <div class="tc-input-edit dcpiec-input-fname">
                                    <mat-form-field>
                                        <mat-select #regionSelect
                                                    id="DIR_PER_INFO_EDIT_REGION"
                                                    formControlName="region"
                                                    [placeholder]="'Region'"
                                                    required>
                                            <!-- title map includes it's name and type. So titleType.code is name of title which is used as code as well-->
                                            <mat-option id="DIR_PER_INFO_EDIT_REGION_{{region.key}}"

                                                        *ngFor="let region of regionList "
                                                        [value]="region.key">
                                                {{region.value}}
                                            </mat-option>
                                        </mat-select>
                                        <!--<mat-error class="mat-error" role="alert">{{getErrorMessage('title')}}</mat-error>-->
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                <div class="tc-input-edit dcpiec-input-lname">
                                    <mat-form-field>
                                        <mat-select #countrySelect
                                                    id="DIR_PER_INFO_EDIT_COUNTRY"
                                                    formControlName="country"
                                                    [placeholder]="'Country'"
                                                    required>
                                            <!-- title map includes it's name and type. So titleType.code is name of title which is used as code as well-->
                                            <mat-option id="DIR_PER_INFO_EDIT_COUNTRY_{{country.code}}"

                                                        *ngFor="let country of countryList" [value]="country.code">
                                                {{country.name}}
                                            </mat-option>
                                        </mat-select>
                                        <!--<mat-error class="mat-error" role="alert">{{getErrorMessage('title')}}</mat-error>-->
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                <div class="tc-input-edit dcpiec-input-fname">
                                    <mat-form-field>
                                        <mat-select #titleSelect
                                                    id="DIR_PER_INFO_EDIT_CITY"
                                                    formControlName="city"
                                                    [placeholder]="'City'"
                                                    required>
                                            <mat-option id="DIR_PER_INFO_EDIT_CITY_{{city.code}}"

                                                        *ngFor="let city of cityList"
                                                        [value]="city.code">
                                                {{city.name}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="tc-caption-txt txt-light">{{'Location Types' | translate}}</div>
                        </div>
                        <div class="row">
                            <div class="tc-input-edit dcpiec-chip-group">
                                <div class="card-chips item-right">
                                    <div class="chip" *ngFor="let chip of searchCriteria"
                                         (click)="toggleValue(chip)"
                                         [ngClass]="selectedCriteria==chip ?'active':'' ">
                                        {{chip}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <table class="table">
                                <tbody >
                                <tr *ngFor="let txt of searchResults">
                                    <td><mat-checkbox class="example-margin" (change)="addLocation($event, txt)"></mat-checkbox></td>
                                    <td>{{txt['name']}}</td>
                                    <td>{{txt['code']}}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
