<div class="p25 adv-client-search">
    <br>
    <form *ngFor="let feature of featureShowing; let i = index;" class="tc-accordion">
        <div class="client-search-result-item-wrapper">
            <div
                    tcExpansionPanel
                    tabindex="-1"
                    [include]="['tc-overlay']"
                    [enabled]="true"
                    (collapse)="expandedPanelIndex = -1">

                <div *tcExpansionPanelSummary id="clientSumExp_{{i}}">
                    <tc-content-strip (click)="onSelection(feature,i)">
                        <tc-result-content-card-generic [summaryContent]="getSummaryCardData(feature)">
                        </tc-result-content-card-generic>
                    </tc-content-strip>
                </div>

                <div *tcExpansionPanelDetail id="clientDetailExp_{{i}}">
                    <tc-expanded-content-card class="tc-expanded-content-card tc-form-view" [isEdit]="false">
                        <div class="container-fluid direct-client-personal-info-edit-card tc-card-wrapper slider-container">
                            <div class="row">
                                <div class="col-sm-12 col-lg-12">
                                    <div class="tc-flex-box">
                                        <div class="tc-flex-item tc-item-circle">
                                            <div class="icon-box">
                                                <i class="material-icons">tune</i>
                                            </div>
                                        </div>
                                        <div class="tc-flex-item tc-right-cage">
                                            <div class="row">
                                                <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                                                    <mat-form-field>
                                                        <input
                                                                matInput
                                                                [(ngModel)]="feature.serviceType"
                                                                [matAutocomplete]="serviceTypeAutoComplete"
                                                                (focus)="onServiceTypeFocus()"
                                                                (ngModelChange)="filterServiceTypes($event)"
                                                                [placeholder]="'Service Type'|translate"
                                                                [ngModelOptions]="{standalone: true}">
                                                        <span style="color:red">{{feature.serviceTypeError}}</span>
                                                    </mat-form-field>
                                                    <mat-autocomplete
                                                        #serviceTypeAutoComplete="matAutocomplete"
                                                        [displayWith]="displayServiceTypeFn">
                                                        <mat-option
                                                                *ngFor="let option of filteredServiceTypeList"
                                                                [value]="option.code"
                                                                (onSelectionChange)="onInputChange(null,'serviceType',feature,option)">
                                                            {{option?.name}}
                                                        </mat-option>
                                                    </mat-autocomplete>
                                                </div>
                                                <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                                                    <div class="tc-input-edit dcpiec-input-grade">
                                                        <div class="tc-caption-txt txt-light slider-label">
                                                            {{"Cost" | translate}}
                                                        </div>
                                                        <mat-slider
                                                                [disabled]="disabled"
                                                                [max]="max"
                                                                [min]="min"
                                                                [step]="step"
                                                                [thumbLabel]="thumbLabel"
                                                                [tickInterval]="tickInterval"
                                                                [(ngModel)]="feature.cost"
                                                                [vertical]="vertical"
                                                                (input)="onInputChange($event,'cost',feature)"
                                                                aria-labelledby=""
                                                                [ngModelOptions]="{standalone: true}">
                                                        </mat-slider>
                                                    </div>
                                                </div>
                                                <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                                                    <div class="tc-input-edit dcpiec-input-grade">
                                                        <div class="tc-caption-txt txt-light slider-label">
                                                            {{"Preference" | translate}}
                                                        </div>
                                                        <mat-slider
                                                                class="tc-user-profile__img"
                                                                [disabled]="disabled"
                                                                [max]="max"
                                                                [min]="min"
                                                                [step]="step"
                                                                [thumbLabel]="thumbLabel"
                                                                [tickInterval]="tickInterval"
                                                                [(ngModel)]="feature.preference"
                                                                [vertical]="vertical"
                                                                (input)="onInputChange($event,'preference',feature)"
                                                                aria-labelledby=""
                                                                [ngModelOptions]="{standalone: true}">
                                                        </mat-slider>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                                                    <div class="tc-input-edit dcpiec-input-grade">
                                                        <div class="tc-caption-txt txt-light slider-label">
                                                            {{"Grade" | translate}}
                                                        </div>
                                                        <mat-slider
                                                                class="tc-user-profile__img"
                                                                [disabled]="disabled"
                                                                [max]="max"
                                                                [min]="min"
                                                                [step]="step"
                                                                [thumbLabel]="thumbLabel"
                                                                [tickInterval]="tickInterval"
                                                                [(ngModel)]="feature.grade"
                                                                [vertical]="vertical"
                                                                (input)="onInputChange($event,'grade',feature)"
                                                                aria-labelledby=""
                                                                [ngModelOptions]="{standalone: true}">
                                                        </mat-slider>
                                                    </div>
                                                </div>
                                                <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                                                    <div class="tc-input-edit dcpiec-input-grade">
                                                        <div class="tc-caption-txt txt-light slider-label">
                                                            {{"Alphabetical Order" | translate}}
                                                        </div>
                                                        <mat-slider
                                                                class="tc-user-profile__img"
                                                                [disabled]="disabled"
                                                                [max]="max"
                                                                [min]="min"
                                                                [step]="step"
                                                                [thumbLabel]="thumbLabel"
                                                                [tickInterval]="tickInterval"
                                                                [(ngModel)]="feature.alphabeticalOrder"
                                                                [vertical]="vertical"
                                                                (input)="onInputChange($event,'alphabetical order',feature)"
                                                                aria-labelledby=""
                                                                [ngModelOptions]="{standalone: true}">
                                                        </mat-slider>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button
                                    class="assign-panel-footer-section__button tc-button-primary mat-button allocation-save-btn"
                                    style="float: right;"
                                    (click)="saveAllocationFeature(feature)"
                                    [disabled]="!isEnableSaveButton"
                                    [matTooltip]="'Save' | translate">
                                <span class="mat-button-wrapper">
                                    Save
                                </span>
                            </button>
                            <button
                                    class="assign-panel-footer-section__button tc-button-primary mat-button allocation-save-btn"
                                    style="float: right;"
                                    (click)="deleteAllocationFeature(feature)"
                                    [disabled]="!isEnableDeleteButton"
                                    [matTooltip]="'Delete' | translate">
                                <span class="mat-button-wrapper">
                                    Delete
                                </span>
                            </button>
                        </div>
                    </tc-expanded-content-card>
                </div>
            </div>
        </div>
    </form>
</div>
