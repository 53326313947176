import { Component } from '@angular/core';
import { TCO } from '../../../../constants';
import { ConfigurationSearchCriteria } from '../../../../models/criteria/configuration-search-criteria';
import { DataKey } from '../../../../services/util/framework/data-store.service';

@Component({
    selector: 'tc-config-setup-search-criteria',
    templateUrl: './config-setup-search-criteria.component.html'
})
export class ConfigSetupSearchCriteriaComponent {

    inputSearchCriteria = new ConfigurationSearchCriteria();
    searchCriteriaUpdatingDataKey = DataKey.serviceConfiguration;
    searchResultsDataKey = DataKey.serviceConfigurationSearchResultsForCriteria;
    searchCriteriaLoadingDataKey = TCO.AppData.CONFIGURATION_SEARCH_CRITERIA;
    chipConfigKey = TCO.CONF.CONF_CONFIGURATION_SEARCH_CRITERIA_CHIP;
    placeHolderText = 'Search Configurations';

    createNewSearchCriteriaFn = () => {
        return new ConfigurationSearchCriteria();
    };

    clearLoadedSearchCriteriaFn = (searchCriteria: ConfigurationSearchCriteria) => {
        searchCriteria.code = '';
    };
}
