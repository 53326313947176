import { Component, OnInit } from '@angular/core';
import { ConfigLoader } from '@tc-core/util/framework/config-loader.service';
import { EventManager } from '@tc-core/util/framework/event-manager.service';
import { UserJourneyManager } from '@tc-core/util/framework/user-journey-manager.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RootService } from '../../../services/util/core-services/root.service';
import { DataKey, DataStoreService } from '../../../services/util/framework/data-store.service';
import { DMCBaseService } from '../../../services/util/system/dmc-base.service';
import { User } from '@tc-core/model/it/codegen/tbx/api/authority/v2/users/user';
import {LanguageService} from '../../../services/util/language/language.service';

@Component({
    selector: 'tc-app-header',
    templateUrl: './app-header.component.html'
})
export class AppHeaderComponent implements OnInit {

    sideBarDisplay: boolean;
    inputThemeCode: string;
    logo: string;
    headings: string[];
    activeLanguage: string;
    languages: string[] = [];
    defaultLang: string;
    actions = [];
    public user: User;

    // public user: UserInfo = {
    //     username: 'codegen',
    //     firstName: 'CodeGen',
    //     lastName: undefined,
    //     email: 'info@codegen.net',
    //     sub: 'sample',
    //     company: 'CodeGen International (pvt) Ltd.',
    //     gender: 'M',
    //     authorities: undefined,
    //     additionalInfomation: undefined
    // };

    constructor(
        private configLoader: ConfigLoader,
        private rootService: RootService,
        private em: EventManager,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private journeyManager: UserJourneyManager,
        private translateService: TranslateService,
        private dataStore: DataStoreService,
        private baseService: DMCBaseService,
        private languageService: LanguageService
    ) {
        this.sideBarDisplay = this.configLoader.configurations.get('SYSTEM_CONFIG').sidebar.toggleSideBar;
        this.logo = this.configLoader.configurations.get('SYSTEM_CONFIG').defaults.logo;
    }

    ngOnInit() {

        // load all available languages
        this.languages = this.configLoader.configurations.get('LANGUAGE_CONFIG').languages;
        this.defaultLang = this.configLoader.sysconf(['defaults', 'language']); // get defaulat language

        this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
            this.activeLanguage = event.lang;
        });

        // read theme qparam from the url
        this.inputThemeCode = this.rootService.getData('theme');

        this.journeyManager.heading.subscribe(headings => {
            this.headings = headings;
        });
        this.journeyManager.headingActions.subscribe(headingActions => {
            this.actions = headingActions;
        });
        // this.serviceHandler.getUserServiceHandlerService().getLoggedUser();

        this.user = this.dataStore.get(DataKey.userDetail).getValue();
    }

    onThemeSelection(event) {
        this.rootService.setData('theme', event, true);
    }

    onSidebarToggle(event) {
        this.em.trigger('SYS_TOGGLE_SIDE_NAV', event);
    }

    onLanguageChagne(lang) {
        localStorage.setItem('lang', lang);
        this.rootService.setData('lang', lang, true);
        this.translateService.use(lang);
        this.languageService.checkLangDirection(lang);
    }

    onActionClick(action) {
        this.journeyManager.go(action);
    }

    public onLogout() {
        this.baseService.isLoggedOut.next(true);
    }

}
