import { HttpClient, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigLoader } from '@tc-core/util/framework/config-loader.service';
import { Observable } from 'rxjs';
import { TCO } from '../../../constants';
import { RoomOccupancySearchCriteria } from '../../../models/criteria/room-occupancy-search-criteria';
import { SupplierSearchCriteria } from '../../../models/criteria/supplier-search-criteria';
import { OccupancyGenerateCriteria } from '../../../models/reservation-v2/criteria/occupancy-generate-criteria';
import { OPResponseWrapper } from '../../../models/response/op-response-wrapper';
import { DriverSupplierCostSummary } from '../../../models/summary/driver-supplier-cost-summary';
import { GenSupplierAllocationSummary } from '../../../models/summary/gen-supplier-allocation-summary';
import { SupplierSummary } from '../../../models/summary/supplier-summary';
import { TrsSupplierAllocationSummary } from '../../../models/summary/trs-supplier-allocation-summary';
import { RoomOccupancy } from '../../../models/supplier/room-occupancy';
import { Supplier } from '../../../models/supplier/supplier';
import { DataLoaderService } from '../../api/data-loader-service.service';
import { DateTimeProcessor } from '../../business-utils/time-zone/date-time-processor';
import { DataKey } from '../../util/framework/data-store.service';
import { DMCQueryParamsService } from '../../util/framework/dmc-query-params.service';
import { RequestService } from '../../util/framework/request.service';
import { UrlPaths } from '../url-paths';

@Injectable()
export class SupplierService {

    constructor(
        private queryParamsService: DMCQueryParamsService,
        private requestService: RequestService,
        private httpClient: HttpClient,
        private dataLoaderService: DataLoaderService,
        private configLoader: ConfigLoader
    ) { }

    searchSuppliersForChips(): Observable<OPResponseWrapper<SupplierSummary>> {
        const params = this.queryParamsService.asQueryParams({
            status: true
        });
        return this.requestService.get(
            UrlPaths.OP_SUP_URL_KEY,
            [UrlPaths.OP_SUP_VERSION, UrlPaths.SUP_SUMMARIES],
            params
        );
    }

    searchSuppliers(supplierSearchCriteria: SupplierSearchCriteria): Observable<OPResponseWrapper<SupplierSummary>> {
        DateTimeProcessor.processSupplierSearchCriteriaDateTimes(supplierSearchCriteria);
        const params = this.queryParamsService.asQueryParams(supplierSearchCriteria);
        return this.requestService.getWithDataKey(
            DataKey.supplierSearchResults,
            UrlPaths.OP_SUP_URL_KEY,
            [UrlPaths.OP_SUP_VERSION, UrlPaths.SUP_SUMMARIES],
            params
        );
    }

    searchSuppliersWithoutDataKey(supplierSearchCriteria: SupplierSearchCriteria): Observable<OPResponseWrapper<SupplierSummary>> {
        DateTimeProcessor.processSupplierSearchCriteriaDateTimes(supplierSearchCriteria);
        const params = this.queryParamsService.asQueryParams(supplierSearchCriteria);
        return this.requestService.get(
            UrlPaths.OP_SUP_URL_KEY,
            [UrlPaths.OP_SUP_VERSION, UrlPaths.SUP_SUMMARIES],
            params
        );
    }

    searchSuppliersNoDataKey(supplierSearchCriteria: any): Observable<OPResponseWrapper<SupplierSummary>> {
        DateTimeProcessor.processSupplierSearchCriteriaDateTimes(supplierSearchCriteria);
        const params = this.queryParamsService.asQueryParams(supplierSearchCriteria);
        return this.requestService.get(
            UrlPaths.OP_SUP_URL_KEY,
            [UrlPaths.OP_SUP_VERSION, UrlPaths.SUP_SUMMARIES],
            params
        );
    }

    searchSuppliersOnMore(supplierSearchCriteria: any): Observable<OPResponseWrapper<SupplierSummary>> {
        DateTimeProcessor.processSupplierSearchCriteriaDateTimes(supplierSearchCriteria);
        const params = this.queryParamsService.asQueryParams(supplierSearchCriteria);
        return this.requestService.getWithDataKey(
            DataKey.supplierAllFetchedResults,
            UrlPaths.OP_SUP_URL_KEY,
            [UrlPaths.OP_SUP_VERSION, UrlPaths.SUP_SUMMARIES],
            params
        );
    }

    public retrieveSupplierById(id: any): Observable<OPResponseWrapper<Supplier>> {
        return this.requestService.getWithDataKey(
            DataKey.supplier,
            UrlPaths.OP_SUP_URL_KEY,
            [UrlPaths.OP_SUP_VERSION, UrlPaths.SUPPLIERS, id.toString()]
        );
    }

    public retrieveSupplierByCode(code: any): Observable<OPResponseWrapper<Supplier>> {
        return this.requestService.getWithDataKey(
            DataKey.supplier,
            UrlPaths.OP_SUP_URL_KEY,
            [UrlPaths.OP_SUP_VERSION, UrlPaths.SUPPLIERS, code.toString()]
        );
    }

    public getOccupancies(criteria: OccupancyGenerateCriteria):
        Observable<OPResponseWrapper<RoomOccupancy>> {
        const params = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.get(
            UrlPaths.OP_SUP_URL_KEY,
            [UrlPaths.OP_SUP_VERSION, UrlPaths.ROOM_OCCUPANCIES_V2],
            params
        );
    }

    public retrieveSupplierItemsById(supplierId: string, itemName: string): Observable<OPResponseWrapper<any>> {
        const params = this.queryParamsService.asQueryParams({name: itemName});
        return this.requestService.getWithDataKey(
            DataKey.supplier,
            UrlPaths.OP_SUP_URL_KEY,
            [UrlPaths.OP_SUP_VERSION, UrlPaths.SUPPLIERS, supplierId.toString(), UrlPaths.SUPPLIER_ITEMS],
            params
        );
    }

    // trs supplier search for assignments
    public searchSuppliersForAssignment(criteria: SupplierSearchCriteria): Observable<OPResponseWrapper<TrsSupplierAllocationSummary>> {
        DateTimeProcessor.processSupplierSearchCriteriaDateTimes(criteria);
        const params = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.getWithDataKey(
            DataKey.suppliersForAssignment,
            UrlPaths.OP_SUP_URL_KEY,
            [UrlPaths.OP_SUP_VERSION, UrlPaths.TRS_SUP_ALLOC_SUMMARIES],
            params
        );
    }

    // generic supplier search for assignments - driver
    public searchDriverSuppliersForAssignment(criteria: SupplierSearchCriteria): Observable<OPResponseWrapper<DriverSupplierCostSummary>> {
        DateTimeProcessor.processSupplierSearchCriteriaDateTimes(criteria);
        const params = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.getWithDataKey(
            DataKey.driverSuppliersForAssignment,
            UrlPaths.OP_SUP_URL_KEY,
            [UrlPaths.OP_SUP_VERSION, UrlPaths.DRI_SUP_ALLOC_SUMMARIES],
            params
        );
    }

    public searchDriverSuppliersForAssignmentNoDataKey(criteria: SupplierSearchCriteria): Observable<OPResponseWrapper<DriverSupplierCostSummary>> {
        DateTimeProcessor.processSupplierSearchCriteriaDateTimes(criteria);
        const params = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.get(
            UrlPaths.OP_SUP_URL_KEY,
            [UrlPaths.OP_SUP_VERSION, UrlPaths.DRI_SUP_ALLOC_SUMMARIES],
            params
        );
    }

    public searchSuppliersForAssignmentNoDataKey(criteria: SupplierSearchCriteria): Observable<OPResponseWrapper<TrsSupplierAllocationSummary>> {
        DateTimeProcessor.processSupplierSearchCriteriaDateTimes(criteria);
        const params = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.get(
            UrlPaths.OP_SUP_URL_KEY,
            [UrlPaths.OP_SUP_VERSION, UrlPaths.TRS_SUP_ALLOC_SUMMARIES],
            params
        );
    }

    public searchSuppliersForAssignmentOnMore(criteria: SupplierSearchCriteria): Observable<OPResponseWrapper<TrsSupplierAllocationSummary>> {
        DateTimeProcessor.processSupplierSearchCriteriaDateTimes(criteria);
        const params = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.getWithDataKey(
            DataKey.supplierAssignAllFetchedResults,
            UrlPaths.OP_SUP_URL_KEY,
            [UrlPaths.OP_SUP_VERSION, UrlPaths.TRS_SUP_ALLOC_SUMMARIES],
            params
        );
    }

    public searchGenSuppliersForAssignment(criteria: SupplierSearchCriteria): Observable<OPResponseWrapper<GenSupplierAllocationSummary>> {
        DateTimeProcessor.processSupplierSearchCriteriaDateTimes(criteria);
        const params = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.get(
            UrlPaths.OP_SUP_URL_KEY,
            [UrlPaths.OP_SUP_VERSION, UrlPaths.GEN_SUP_ALLOC_SUMMARIES],
            params
        );
    }

    public searchGenSuppliersForAssignmentWithDataKey(criteria: SupplierSearchCriteria): Observable<OPResponseWrapper<GenSupplierAllocationSummary>> {
        DateTimeProcessor.processSupplierSearchCriteriaDateTimes(criteria);
        const params = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.getWithDataKey(
            DataKey.searchSuppliersForGenAssignment,
            UrlPaths.OP_SUP_URL_KEY,
            [UrlPaths.OP_SUP_VERSION, UrlPaths.GEN_SUP_ALLOC_SUMMARIES],
            params
        );
    }

    saveSupplier(supplier: any): Observable<OPResponseWrapper<Supplier>> {
        return this.requestService.postWithDataKey(
            DataKey.saveSupplier,
            UrlPaths.OP_SUP_URL_KEY,
            [UrlPaths.OP_SUP_VERSION, UrlPaths.SUPPLIERS],
            supplier
        );
    }

    updateSupplier(supplier: any, supplierID: number): Observable<OPResponseWrapper<Supplier>> {
        return this.requestService.putWithDataKey(
            DataKey.updateSupplier,
            UrlPaths.OP_SUP_URL_KEY,
            [UrlPaths.OP_SUP_VERSION, UrlPaths.SUPPLIERS, supplierID.toString()],
            supplier
        );
    }

    uploadCostSheet(fileToUpload: File, templateName: string, supplierId: number, selectedResourceTypes: string): Observable<any> {
        let endpoint = this.configLoader.configurations.get(TCO.CONF.CONF_ENDPOINT)[UrlPaths.OP_SUP_URL_KEY];
        endpoint = this.dataLoaderService.getUrl(endpoint, [UrlPaths.OP_SUP_VERSION, UrlPaths.SUPPLIERS, UrlPaths.UPLOAD]);
        const formData: FormData = new FormData();
        formData.append('dataSource', fileToUpload);
        formData.append('costSheetType', templateName);
        formData.append('supplierId', supplierId.toString());
        formData.append('selectedResourceTypes', selectedResourceTypes.toString());
        const req = new HttpRequest('POST', endpoint, formData, {
            reportProgress: true,
            responseType: 'json'
        });
        return this.httpClient.request(req);
    }

    public searchOccupancies(criteria: RoomOccupancySearchCriteria): Observable<OPResponseWrapper<RoomOccupancy>> {
        const params = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.get(
            UrlPaths.OP_SUP_URL_KEY,
            [UrlPaths.OP_SUP_VERSION, UrlPaths.OCCUPANCIES],
            params
        );
    }

    public saveOccupancy(data: RoomOccupancy, criteria: OccupancyGenerateCriteria): Observable<OPResponseWrapper<void>> {
        const params = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.post(
            UrlPaths.OP_SUP_URL_KEY,
            [UrlPaths.OP_SUP_VERSION, UrlPaths.OCCUPANCIES],
            data,
            params
        );
    }

    public deleteOccupancy(occupancyId: number): Observable<OPResponseWrapper<void>> {
        return this.requestService.delete(
            UrlPaths.OP_SUP_URL_KEY,
            [UrlPaths.OP_SUP_VERSION, UrlPaths.OCCUPANCIES, occupancyId.toString()]
        );
    }

}
