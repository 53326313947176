import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FullCalendarModule } from '@fullcalendar/angular';
import { DataStore } from '@tc-core/util/framework';
import { CommonHelper } from '@tc-core/util/helpers';
import { AvailabilityCalendarComponent } from '../availability-calendar/availability-calendar.component';
import { AvailabilityCalendarModule } from '../availability-calendar/availability-calendar.module';
import { LeaveSetupFocusViewComponent } from '../../../business/leave-setup-focus-view/leave-setup-focus-view.component';
import { LeaveSetupFocusViewModule } from '../../../business/leave-setup-focus-view/leave-setup-focus-view.module';
import { ResCalendarSideNavComponent } from '../res-calendar-side-nav/res-calendar-side-nav.component';
import { ResCalendarSideNavModule } from '../res-calendar-side-nav/res-calendar-side-nav.module';
import { ResAvailabilityCalendarFocusViewComponent } from './res-availability-calendar-focus-view.component';
import {ResourceAvailabilityService} from '../../../services/backend-consumers/supplier-service/resource-availability.service';

@NgModule({
    declarations: [ResAvailabilityCalendarFocusViewComponent],
    imports: [
        CommonModule,
        FullCalendarModule,
        LeaveSetupFocusViewModule,
        ResCalendarSideNavModule,
        AvailabilityCalendarModule,
        BrowserAnimationsModule
    ],
    providers: [
        DataStore,
        CommonHelper,
        ResourceAvailabilityService
    ],
    exports: [
        ResAvailabilityCalendarFocusViewComponent
    ],
    entryComponents: []
})
export class ResAvailabilityCalendarFocusViewModule {}
