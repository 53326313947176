import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Booking} from '../../../../models/reservation-v2/booking';
import {BookingItem} from '../../../../models/reservation-v2/booking-item';
import {SummaryCardData} from '@tc-core/model/it/codegen/ui/framework/summary-card-data';
import {BookingNoteType} from '../../../../models/reservation-v2/booking-note';

@Component({
    selector: 'tc-booking-items-detail-view',
    templateUrl: './booking-items-detail-view.component.html',
})
export class BookingItemsDetailViewComponent implements OnInit {

    @Input() booking: Booking;
    @Input() readonly = false;
    @Input() summaryCards: SummaryCardData[] = [];
    @Input() noteTypes: BookingNoteType[] = [];
    @Input() trsModes: any[] = [];

    @Output() passengersClick: EventEmitter<BookingItem> = new EventEmitter();
    @Output() changeValidity: EventEmitter<boolean> = new EventEmitter();

    public hoveredIndex = -1;
    public selectedItem: BookingItem;

    private validityMap: Map<BookingItem, boolean> = new Map();

    constructor() {
    }

    ngOnInit() {
    }

    public onExpand(event, index) {
        this.hoveredIndex = index;
        this.selectedItem = this.booking.bookingItems[index];
    }

    public hideIcons() {
        this.hoveredIndex = -1;
    }

    public showIcons(index) {
        this.hoveredIndex = index;
    }

    onPassengersClick(event: BookingItem) {
        this.passengersClick.emit(event);

    }

    public getItemType(bookingItem: BookingItem) {
        if (bookingItem.trsBookingItem != null) {
            return 'trs';
        } else if (bookingItem.genBookingItem != null && !bookingItem.genBookingItem.hotelRoomType) {
            return 'gen';
        } else if (bookingItem.genBookingItem != null && bookingItem.genBookingItem.hotelRoomType) {
            return 'accom';
        }
    }

    public onChangeValidity(bookingItem: BookingItem, event: boolean) {
        this.validityMap.set(bookingItem, event);
        let allValid = true;
        this.validityMap.forEach((value, key) => {
            if (!value) {
                allValid = value;
            }
        });
        this.changeValidity.emit(allValid);
    }
}
