<mat-accordion *ngIf="booking.bookingItems.length > 0" [multi]=false class="tc-expansion-list-wrapper">
    <ng-container *ngFor="let bookingItem of booking.bookingItems; index as i">
        <mat-expansion-panel
            #expPanel
            [attr.id]="'expansionPanel-'+i"
            (opened)="onExpand($event, i)"
            [hideToggle]="true">
            <mat-expansion-panel-header [collapsedHeight]="'52px'" [expandedHeight]="'68px'" class="">
                <tc-item-summary
                        [hidden]="expPanel.expanded"
                        [summaryCard]="summaryCards[i]"
                        [isEditable]="!readonly"
                        [bookingItem]="bookingItem"
                        [showIcons]="true"
                        (mouseleave)="hideIcons()"
                        (mouseenter)="showIcons(i)"
                        (deleteItem)="onDelete($event)"
                        (editAsNewItem)="editAsNew($event)"
                        class="">
                </tc-item-summary>

                <div [hidden]="!expPanel.expanded" class="expand-heading">
                    <tc-item-summary
                            *ngIf="summaryCards"
                            [isEditable]="!readonly"
                            [summaryCard]="summaryCards[i]"
                            [bookingItem]="bookingItem"
                            [showIcons]="true"
                            [isHeader]="true"
                            (passengersClick)="onPassengersClick($event)"
                            (deleteItem)="onDelete($event)"
                            (editAsNewItem)="editAsNew($event)"
                            class="">
                    </tc-item-summary>
                </div>

            </mat-expansion-panel-header>

            <!--For transfer item-->
            <tc-trs-item
                    *ngIf="getItemType(bookingItem)==='trs'"
                    [bookingItem]="bookingItem"
                    [noteTypes]="noteTypes"
                    [trsModes]="trsModes"
                    [readonly]="readonly"
                    [sameTypeItems]="getSameTypeItems('trs',bookingItem)"
                    [allPassengerIds]="allPassengerIds"
                    [allBookingPassengers]="booking.bookingPassengers"
                    (changeValidity)=onChangeValidity(bookingItem,$event)>
            </tc-trs-item>

            <!--For generic item-->
            <tc-gen-item
                    *ngIf="getItemType(bookingItem)==='gen'"
                    [bookingItem]="bookingItem"
                    [noteTypes]="noteTypes"
                    [readonly]="readonly"
                    [sameTypeItems]="getSameTypeItems('gen',bookingItem)"
                    [allPassengerIds]="allPassengerIds"
                    [allBookingPassengers]="booking.bookingPassengers"
                    (changeValidity)=onChangeValidity(bookingItem,$event)>
            </tc-gen-item>

            <!--For accommodation item-->
            <tc-accom-item
                    *ngIf="getItemType(bookingItem)==='accom'"
                    [bookingItem]="bookingItem"
                    [noteTypes]="noteTypes"
                    [readonly]="readonly"
                    [sameTypeItems]="getSameTypeItems('accom', bookingItem)"
                    [allPassengerIds]="allPassengerIds"
                    [allBookingPassengers]="booking.bookingPassengers"
                    (changeValidity)=onChangeValidity(bookingItem,$event)>
            </tc-accom-item>
        </mat-expansion-panel>
        <!--        <hr *ngIf=" i !== booking.bookingItems.length-1 && !expPanel.expanded"/>-->
    </ng-container>
</mat-accordion>

<ng-container *ngIf="!(this.booking.bookingItems && this.booking.bookingItems.length)">
    <div class="detail-group__content">
        <div class="container-fluid  tc-card-wrapper">
            <div class="row">
                <div class="col-md-12 col-lg-12">
                    <div class="tc-flex-box">
                        <div class="tc-flex-item tc-flex-item__top tc-item-circle">
                            <div class="icon-box">
                                <mat-icon>view_list</mat-icon>
                            </div>
                        </div>
                        <div class="tc-flex-item tc-flex-item--fill tc-right-cage">
                            <div class="row">
                                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 remove-bootstrap-padding-mobile">
                                    <div class="tc-card-placeholder  tc-card-placeholder--space-vertical">
                                        <i class="material-icons tc-card-placeholder__icon">assignment_error</i>
                                        <span class="tc-ag-grid-empty__text">{{'No Booking Items' | translate}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>



