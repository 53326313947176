import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaginatorModule } from '@tc/paginator';
import { GenericSearchCriteriaModule } from '../../generic-search-criteria/generic-search-criteria.module';
import { AdvDriverAssignSearchResultModule } from '../../../widgets/shared/adv-driver-assign-search-result/adv-driver-assign-search-result.module';
import { ResultHeaderModule } from '../../../widgets/shared/result-header/result-header.module';
import { DriverAssignPanelComponent } from './driver-assign-panel.component';

@NgModule({
    imports: [
        CommonModule,
        GenericSearchCriteriaModule,
        ResultHeaderModule,
        AdvDriverAssignSearchResultModule,
        PaginatorModule
    ],
    exports: [
        DriverAssignPanelComponent
    ],
    declarations: [DriverAssignPanelComponent]
})
export class DriverAssignPanelModule {}
