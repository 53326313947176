<div class="">
    <form [formGroup]="occGenerateForm">
        <div class="row tc-py-5">
            <div class="col-xs-12 col-sm-4">
                <mat-form-field>
                    <input
                            type="number"
                            matInput
                            formControlName="min"
                            [placeholder]="'Min Occupancy' | translate"
                            required>
                    <mat-error>{{getErrorMessage('min')}}</mat-error>
                </mat-form-field>
            </div>
            <div class="col-xs-12 col-sm-4">
                <mat-form-field>
                    <input
                            type="number"
                            matInput
                            formControlName="max"
                            [placeholder]="'Max Occupancy' | translate"
                            required>
                    <mat-error>{{getErrorMessage('max')}}</mat-error>
                </mat-form-field>
            </div>
            <div class="col-xs-12 col-sm-4">
                <mat-form-field>
                    <input
                            matInput
                            formControlName="minAdults"
                            [placeholder]="'Min Adults' | translate">
                    <mat-error>{{getErrorMessage('minAdults')}}</mat-error>
                </mat-form-field>
            </div>
        </div>
    </form>
    <div class="tc-modal-footer tc-px-0 tc-pb-0">
        <button class="button-left tc-button-secondary mat-button tc-mr-2" (click)="onClose()">
            {{'Close' | translate}}
        </button>
        <button
                class="button-right tc-button-primary mat-button" (click)="onOkClick()"
                [disabled]="isOkDisabled()">
            {{'Ok' | translate}}
        </button>
    </div>
</div>
