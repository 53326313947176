/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../node_modules/@tc/resource/resource.component.ngfactory";
import * as i2 from "@tc/resource/resource.component";
import * as i3 from "@tc-core/util/framework/resource-manager.service";
import * as i4 from "../../../../../node_modules/@angular/material/progress-bar/typings/index.ngfactory";
import * as i5 from "@angular/material/progress-bar";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "@angular/common";
import * as i8 from "@ngx-translate/core";
import * as i9 from "./resource-expanded-content-header.component";
var styles_ResourceExpandedContentHeaderComponent = [];
var RenderType_ResourceExpandedContentHeaderComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ResourceExpandedContentHeaderComponent, data: {} });
export { RenderType_ResourceExpandedContentHeaderComponent as RenderType_ResourceExpandedContentHeaderComponent };
function View_ResourceExpandedContentHeaderComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-resource", [], [[8, "id", 0]], null, null, i1.View_ResourceComponent_0, i1.RenderType_ResourceComponent)), i0.ɵdid(1, 114688, null, 0, i2.ResourceComponent, [i3.ResourceManager], { key: [0, "key"], value: [1, "value"], style: [2, "style"], iconType: [3, "iconType"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = ((_co.icon == null) ? null : ((_co.icon.value == null) ? null : _co.icon.value.key)); var currVal_2 = ((_co.icon == null) ? null : ((_co.icon.value == null) ? null : _co.icon.value.value)); var currVal_3 = ((_co.icon == null) ? null : ((_co.icon.value == null) ? null : _co.icon.value.style)); var currVal_4 = ((_co.icon == null) ? null : ((_co.icon.value == null) ? null : _co.icon.value.iconType)); _ck(_v, 1, 0, currVal_1, currVal_2, currVal_3, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "", ((_co.icon == null) ? null : _co.icon.key), ""); _ck(_v, 0, 0, currVal_0); }); }
function View_ResourceExpandedContentHeaderComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "mat-progress-bar", [["aria-valuemax", "100"], ["aria-valuemin", "0"], ["class", "tc-progress--bar ecshc-progress-bar mat-progress-bar"], ["mode", "indeterminate"], ["role", "progressbar"], ["value", "30"]], [[1, "aria-valuenow", 0], [1, "mode", 0], [2, "_mat-animation-noopable", null]], null, null, i4.View_MatProgressBar_0, i4.RenderType_MatProgressBar)), i0.ɵdid(1, 4374528, null, 0, i5.MatProgressBar, [i0.ElementRef, i0.NgZone, [2, i6.ANIMATION_MODULE_TYPE], [2, i5.MAT_PROGRESS_BAR_LOCATION]], { value: [0, "value"], mode: [1, "mode"] }, null)], function (_ck, _v) { var currVal_3 = "30"; var currVal_4 = "indeterminate"; _ck(_v, 1, 0, currVal_3, currVal_4); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).value; var currVal_1 = i0.ɵnov(_v, 1).mode; var currVal_2 = i0.ɵnov(_v, 1)._isNoopAnimation; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_ResourceExpandedContentHeaderComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "div", [["class", "ecshc-card"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 10, "div", [["class", "ecshc-card__wrapper"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onHeaderClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "div", [["class", "tc-flex-item"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ResourceExpandedContentHeaderComponent_1)), i0.ɵdid(4, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(5, 0, null, null, 6, "div", [["class", "ecshc-card__content ecshc-content-wrapper"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 3, "h5", [["class", "ecshc-content-wrapper__heading"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(8, null, ["", ""])), i0.ɵpid(131072, i8.TranslatePipe, [i8.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(10, 0, null, null, 1, "small", [["class", "ecshc-content-wrapper__secondary-heading"]], null, null, null, null, null)), (_l()(), i0.ɵted(11, null, ["", ""])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ResourceExpandedContentHeaderComponent_2)), i0.ɵdid(13, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.icon == null) ? null : _co.icon.value); _ck(_v, 4, 0, currVal_0); var currVal_3 = (_co.showProgress === true); _ck(_v, 13, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i0.ɵunv(_v, 8, 0, i0.ɵnov(_v, 9).transform(_co.headerText)); _ck(_v, 8, 0, currVal_1); var currVal_2 = _co.secondaryHeaderText; _ck(_v, 11, 0, currVal_2); }); }
export function View_ResourceExpandedContentHeaderComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-resource-expanded-content-header", [], null, null, null, View_ResourceExpandedContentHeaderComponent_0, RenderType_ResourceExpandedContentHeaderComponent)), i0.ɵdid(1, 114688, null, 0, i9.ResourceExpandedContentHeaderComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ResourceExpandedContentHeaderComponentNgFactory = i0.ɵccf("tc-resource-expanded-content-header", i9.ResourceExpandedContentHeaderComponent, View_ResourceExpandedContentHeaderComponent_Host_0, { headerText: "headerText", secondaryHeaderText: "secondaryHeaderText", icon: "icon", showProgress: "showProgress" }, { headerClick: "headerClick" }, []);
export { ResourceExpandedContentHeaderComponentNgFactory as ResourceExpandedContentHeaderComponentNgFactory };
