import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { Calendar } from "@fullcalendar/core";
import { EventApi } from "@fullcalendar/core/api/EventApi";
import { DayGridView } from "@fullcalendar/daygrid/DayGridView";

@Component({
    selector: 'tc-availability-calendar',
    templateUrl: './availability-calendar.component.html'
})
export class AvailabilityCalendarComponent implements OnInit {

    @Input() calendarEvents: any[] = [];
    @Input() navConfig: string;
    @Input() calendarPlugins: string[] = [];
    @Input() isRadioButton: boolean = false;
    @Input() viewOptions = [];

    @Output() dateClick: EventEmitter<any> = new EventEmitter();
    @Output() select: EventEmitter<any> = new EventEmitter();
    @Output() eventClick: EventEmitter<any> = new EventEmitter();
    @Output() sideNavItemClick: EventEmitter<any> = new EventEmitter();

    calendarApi: Calendar;
    defaultView: any;

    @ViewChild('calendar') fullCalendarComponent: FullCalendarComponent;

    constructor() { }

    ngOnInit() {
        this.calendarApi = this.fullCalendarComponent.getApi();
        this.defaultView = this.viewOptions.filter(value => value.isSelected)[0].code;
    }

    // render event
    eventRender($event: any) {
        const el: HTMLElement = $event.el;
        const event: EventApi = $event.event;

        const node = document.createElement('div');
        node.innerHTML = event.extendedProps.innerHtml;

        el.innerHTML = '';
        el.append(node);
        el.children[0].children[0].children[0].innerHTML = event.title;
        el.children[0].children[0].children[0].setAttribute('title', event.title);

        el.addEventListener('click', () => {
            this.onEventClick($event);
        });
    }

    onEventClick(calEvent) {
        this.eventClick.emit(calEvent);
    }

    // filter events by selected side navigation item
    filterSelectedEvents(event: any[]) {
        this.sideNavItemClick.emit(event);
    }


    onDateClick(arg) {
        this.dateClick.emit(arg);
    }

    onSelectViewOption(type: any) {
        this.fullCalendarComponent.getApi().changeView(type);
    }

}
