<div
        class="container-fluid content-summary-card"
        [ngClass]="{ 'content-summary-card--warning':isUpdated,'content-summary-card--danger':isCancelled}">
    <div class="row card-body tc-item--center">
        <div class="col-xs-12 col-sm-12 col-md-3">
            <div class="tc-flex-box">
                <div class="tc-flex-item">
                    <tc-resource
                            *ngIf="summaryContent?.icon?.value"
                            id="{{summaryContent?.icon?.key}}"
                            [key]="summaryContent?.icon?.value?.key"
                            [value]="summaryContent?.icon?.value?.value"
                            [style]="summaryContent?.icon?.value?.style"
                            [iconType]="summaryContent?.icon?.value?.iconType">
                    </tc-resource>
                </div>
                <div
                        class="tc-flex-item"
                        *ngIf="summaryContent?.row1col1 || summaryContent?.row2col1">
                    <div
                            *ngIf="summaryContent?.row1col1" class="tc-strip-text" [ngClass]="row1col1_customsStyles"
                            id="{{summaryContent?.row1col1?.key}}" [innerHTML]="summaryContent?.row1col1?.value">
                    </div>
                    <div
                            *ngIf="summaryContent?.row2col1" class="tc-strip-text" [ngClass]="row2col1_customsStyles"
                            id="{{summaryContent?.row2col1?.key}}"
                            [innerHTML]="summaryContent?.row2col1?.value">
                    </div>
                </div>
            </div>
        </div>

        <div
                class="col-xs-12 col-sm-12 col-md-3"
                *ngIf="summaryContent?.row1col2 || summaryContent?.row2col2">
            <div class="tc-content--wrap bk-detail-item">
                <div
                        *ngIf="summaryContent?.row1col2" class="tc-strip-text" [ngClass]="row1col2_customsStyles"
                        id="{{summaryContent?.row1col2?.key}}" [innerHTML]="summaryContent?.row1col2?.value">
                </div>
                <div
                        *ngIf="summaryContent?.row2col2" class="tc-strip-text__group tc-strip-text" [ngClass]="row2col2_customsStyles"
                        id="{{summaryContent?.row2col2?.key}}">
                    <i *ngIf="row2col2MatIcon" class="material-icons tc-strip-text__icon">{{row2col2MatIcon}}</i>
                    <span class="" [innerHTML]="summaryContent?.row2col2?.value"></span>
                </div>
            </div>
        </div>

        <div
                class="col-xs-12 col-sm-12 col-md-4"
                *ngIf="summaryContent.row1col3 || summaryContent.row2col3">
            <div
                    *ngIf="summaryContent.row1col3" class="tc-strip-text" [ngClass]="row1col3_customsStyles"
                    id="{{summaryContent?.row1col3?.key}}" [innerHTML]="summaryContent?.row1col3?.value">
            </div>
            <div
                    *ngIf="summaryContent.row2col3" class="tc-strip-text" [ngClass]="row2col3_customsStyles"
                    id="{{summaryContent?.row2col3?.key}}" [innerHTML]="summaryContent?.row2col3?.value">
            </div>
        </div>

        <div
                class="col-xs-12 col-sm-12  col-md-2"
                *ngIf="summaryContent?.row1col4 || summaryContent?.row2col4">
            <!--<div class="col-4-content-wraper">-->
            <div
                    *ngIf="summaryContent.row1col4" class="tc-strip-text" [ngClass]="row1col4_customsStyles"
                    id="{{summaryContent?.row1col4?.key}}" [innerHTML]="summaryContent?.row1col4?.value">
            </div>
            <div
                    *ngIf="summaryContent.row2col4" class="tc-strip-text" [ngClass]="row2col4_customsStyles"
                    id="{{summaryContent?.row2col4?.key}}"[innerHTML]="summaryContent?.row2col4?.value">
            </div>
            <!--</div>-->
        </div>
    </div>
</div>
<hr>

