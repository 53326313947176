import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PickupDropoffDetailsModule } from '../../../widgets/shared/pickup-dropoff-details/pickup-dropoff-details.module';
import { PickupDropOffDetailsWrapperComponent } from './pickup-dropoff-details-wrapper.component';

@NgModule({
    imports: [
        CommonModule,
        PickupDropoffDetailsModule
    ],
    exports: [
        PickupDropOffDetailsWrapperComponent
    ],
    declarations: [PickupDropOffDetailsWrapperComponent],
    entryComponents:[PickupDropOffDetailsWrapperComponent]
})
export class PickupDropOffDetailsWrapperModule {}
