import { SummaryCardData } from '@tc-core/model/it/codegen/ui/framework/summary-card-data';

export class NoteItemView {
    header: SummaryCardData;
    summary: SummaryCardData;
    detail: SummaryCardData;
}

export namespace NoteType {
    export enum TYPE {
        BK = <any>'BOOKING',
        SU = <any> 'SUPPLEMENT',
        SV = <any> 'SERVICE',
        IN = <any> 'INVOICE',
        IT = <any> 'ITINERARY',
        VO = <any> 'VOUCHER',
        SP = <any> 'SUPPLIER',
        DC = <any> 'DOCUMENT',
        TI = <any> 'TRANSFER_INFO',
    }
}
