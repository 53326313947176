/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./note-editor-input.component";
var styles_NoteEditorInputComponent = [];
var RenderType_NoteEditorInputComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_NoteEditorInputComponent, data: {} });
export { RenderType_NoteEditorInputComponent as RenderType_NoteEditorInputComponent };
export function View_NoteEditorInputComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), i0.ɵdid(1, 278528, null, 0, i1.NgStyle, [i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { ngStyle: [0, "ngStyle"] }, null), (_l()(), i0.ɵeld(2, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.cellStyles; _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.params.value; _ck(_v, 3, 0, currVal_1); }); }
export function View_NoteEditorInputComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-note-editor-input", [], null, null, null, View_NoteEditorInputComponent_0, RenderType_NoteEditorInputComponent)), i0.ɵdid(1, 49152, null, 0, i2.NoteEditorInputComponent, [], null, null)], null, null); }
var NoteEditorInputComponentNgFactory = i0.ɵccf("tc-note-editor-input", i2.NoteEditorInputComponent, View_NoteEditorInputComponent_Host_0, {}, {}, []);
export { NoteEditorInputComponentNgFactory as NoteEditorInputComponentNgFactory };
