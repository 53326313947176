import { Component, OnInit } from '@angular/core';
import { UserProfile } from '../../../authentication/user-profile';
import { TCO } from '../../../constants';
import { CompanySearchCriteria } from '../../../models/criteria/company-search-criteria';
import { DataKey, DataStoreService } from '../../../services/util/framework/data-store.service';

@Component({
    selector: 'tc-company-setup-search-criteria',
    templateUrl: './company-setup-search-criteria.component.html'
})
export class CompanySetupSearchCriteriaComponent implements OnInit {
    constructor(
        private dataStoreService: DataStoreService
    ) {
    }

    inputSearchCriteria = new CompanySearchCriteria();
    searchCriteriaUpdatingDataKey = DataKey.companySetupSearchCriteria;
    searchResultsDataKey = DataKey.companySearchResultsForCriteria;
    searchCriteriaLoadingDataKey = TCO.AppData.COMPANY_SETUP_SEARCH_CRITERIA;
    chipConfigKey = TCO.CONF.CONF_COMPANY_SETUP_SEARCH_CRITERIA_CHIP_CONFIG;
    placeHolderText = 'Search Company Master Data';
    companyCode: string;
    companyName: string;
    public userProfile: UserProfile;

    ngOnInit() {
        this.userProfile = this.dataStoreService.get(DataKey.userProfile).getValue();
        this.loadCompanyDetails();
    }

    public loadCompanyDetails() {
        if (this.dataStoreService.get(DataKey.kcCompanies) && this.dataStoreService.get(DataKey.kcCompanies)._value) {
            this.companyCode = this.dataStoreService.get(DataKey.kcCompanies)._value[0].code;
        } else {
            this.companyCode = this.userProfile.kcCompany;
        }

        if (this.dataStoreService.get(DataKey.kcCompanies) && this.dataStoreService.get(DataKey.kcCompanies)._value) {
            this.companyName = this.dataStoreService.get(DataKey.kcCompanies)._value[0].name;
        } else {
            this.companyName = this.userProfile.kcCompanyName;
        }
    }

    createNewSearchCriteriaFn = () => {
        const criteria = new CompanySearchCriteria();
        criteria.code = this.companyCode;
        criteria.name = this.companyName;
        return criteria;
    };

    clearLoadedSearchCriteriaFn = (criteria: CompanySearchCriteria) => {
    };

}
