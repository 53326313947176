
        <div class="tc-ag-grid__edit">
            <input
                    #input
                    [(ngModel)]="inputValue"
                    (ngModelChange)="processDataInput(inputValue)"
                    [style.width]="params.column.actualWidth + 'px'" class="tc-ag-grid__edit-input">
        </div>
            <ag-grid-angular
                    [style.height]="gridHeight + '%'"
                    [style.max-width]="gridWidth + 'px'"
                    class="ag-theme-material tc-ag-grid__edit-grid"
                    [rowData]="rowData"
                    [columnDefs]="columnDefs"
                    [defaultColDef]="defaultColDef"
                    rowSelection="multiple"
                    [rowMultiSelectWithClick]="true"
                    (gridReady)="onGridReady($event)"
                    (selectionChanged)="onSelectionChanged($event)"
                    (rowClicked)="rowClicked()">
            </ag-grid-angular> 
        
    