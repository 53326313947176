import { Component, OnInit, ViewChild } from '@angular/core';
import { LEVEL } from '@tc-core/model/it/codegen/ui/framework/dialog-model';
import { ModalData } from '@tc-core/model/it/codegen/ui/framework/modal-data';
import { ConfigLoader } from '@tc-core/util/framework';
import { SpinnerService } from '@tc-core/util/ui/spinner.service';
import { ModalService } from '@tc/modal/modal.service';
import { IGetRowsParams } from 'ag-grid-community';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SetupGridComp } from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid-comp';
import { SetupGridDataSource } from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid-data-source';
import { SetupGridComponent } from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid.component';
import { TCO } from '../../../constants';
import { RoomOccupancySearchCriteria } from '../../../models/criteria/room-occupancy-search-criteria';
import { SortDirection } from '../../../models/helper/sort-direction';
import { OccupancyGenerateCriteria } from '../../../models/reservation-v2/criteria/occupancy-generate-criteria';
import { SupplierService } from '../../../services/backend-consumers/supplier-service/supplier.service';
import { DataKey, DataStoreService } from '../../../services/util/framework/data-store.service';
import { GenerateOccupanciesDialogComponent } from './generate-dialog/generate-occupancies-dialog.component';

@Component({
    selector: 'tc-room-occupancy-setup',
    templateUrl: './room-occupancy-setup.component.html'
})
export class RoomOccupancySetupComponent extends SetupGridComp implements OnInit, SetupGridDataSource {

    @ViewChild('setupGrid')
    private setupGrid: SetupGridComponent;
    public colDefConfig = [];

    headerActions = [
        {
            icon: 'autorenew',
            action: this.clickCreateOccupancies.bind(this),
            disable: false,
            tooltip: 'Create Occupancies'
        }
    ];

    // search criteria obj. used to
    private occupancySearchCriteria: RoomOccupancySearchCriteria = new RoomOccupancySearchCriteria();

    constructor(
        private configLoader: ConfigLoader,
        private dataStoreService: DataStoreService,
        private supplierService: SupplierService,
        private modalService: ModalService,
        private spinnerService: SpinnerService
    ) {
        super();
    }

    ngOnInit() {
        this.colDefConfig = this.configLoader.configurations.get(TCO.CONF.CONF_ROOM_OCCUPANCY_SETUP);
        this.subscribeSearchCriteria();
    }

    /**
     * subscribe search criteria changes in search bar
     * then force grid to refresh data
     * grid refresh will be call getRaws method which is using this subscribed criteria for backend call
     */
    subscribeSearchCriteria() {
        this.dataStoreService.get(DataKey.roomOccupancySearchCriteria).subscribe(value => {
            this.occupancySearchCriteria = value;
            this.setupGrid.runForceSearch();
        });
    }

    public isInvalidRow(params): boolean {
        const data = params.data;
        if (data) {
            let invalid = true;
            if (
                data.name !== ''
            ) {
                invalid = false;
            }
            return invalid;
        } else {
            return false;
        }
    }

    public isUnsavedRow(row: any): boolean {
        return false;
    }

    public deleteRow(row: any) {
        if (row && row.occupancyId) {
            return this.supplierService.deleteOccupancy(row.occupancyId);
        } else {
            return of('success');
        }
    }

    public saveRow(row: any, event: any) {
        return this.supplierService.saveOccupancy(row, new OccupancyGenerateCriteria());
    }

    public getRows(params: IGetRowsParams): Observable<any> {
        console.log('fetch requesting for ' + params.startRow + ' to ' + params.endRow);
        console.log(params.sortModel);

        const pageSize = params.endRow - params.startRow;
        if (!this.occupancySearchCriteria) {
            this.occupancySearchCriteria = new RoomOccupancySearchCriteria();
        }
        this.occupancySearchCriteria.start = params.startRow;
        this.occupancySearchCriteria.size = pageSize;
        if (params.sortModel && params.sortModel[0]) {
            this.occupancySearchCriteria.sortBy = params.sortModel[0].colId;
            if (params.sortModel[0].sort === SetupGridComp.GRID_SORT_ASCENDING) {
                this.occupancySearchCriteria.sortDirection = SortDirection.ASC;
            } else {
                this.occupancySearchCriteria.sortDirection = SortDirection.DESC;
            }
        }
        return this.supplierService.searchOccupancies(this.occupancySearchCriteria)
                   .pipe(
                       tap(data =>
                           this.dataStoreService.set(DataKey.roomOccupancySearchResultsForCriteria, data)
                       )
                   );
    }

    public createNewRow(): any {
        return {};
    }

    private clickCreateOccupancies() {
        this.dataStoreService.set(DataKey.popupClose, null, true);
        const occupancyGenerateCriteria = new OccupancyGenerateCriteria();
        const dataObject = new ModalData(
            LEVEL.SUCCESS,
            'Generate Occupancies',
            null,
            null,
            'modal-basic--medium modal-basic--no-footer',
            GenerateOccupanciesDialogComponent,
            {
                occupancyGenerateCriteria
            }
        );

        this.confirmModal(dataObject);
        this.dataStoreService.get(DataKey.popupClose).subscribe((data) => {
            if (data != null) {
                if (data) {
                    this.generateOccupancies(occupancyGenerateCriteria);
                }
                this.closeModal();
            }
        });
    }

    private confirmModal(data: any): void {
        this.modalService.confirm(data).subscribe((res) => {
        });
    }

    closeModal() {
        if (this.modalService.close()) {
            this.modalService.close().subscribe((res) => {
            });
        }
    }

    private generateOccupancies(occupancyGenerateCriteria: OccupancyGenerateCriteria) {
        this.spinnerService.show();
        this.supplierService.saveOccupancy(null, occupancyGenerateCriteria).subscribe(
            value => {
                this.setupGrid.runForceSearch();
                this.spinnerService.hide();
            }, error => {
                this.spinnerService.hide();

            }
        );
    }

    public cellEditingStart() {
    }

    /*
     grid config methods which are called by grid setup (ag grid)
     and set by grid config
     */

}
