import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SectionHeadingModule } from '@tc/section-heading';
import { ResultHeaderComponent } from './result-header.component';
import { ChipDropdownModule } from '@tc/chip-dropdown';
import { MatMenuModule, MatTooltipModule } from '@angular/material';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    imports: [
        CommonModule,
        ChipDropdownModule,
        MatMenuModule,
        TranslateModule.forChild(),
        MatTooltipModule,
        SectionHeadingModule
    ],
    declarations: [ResultHeaderComponent],
    exports: [ResultHeaderComponent]
})
export class ResultHeaderModule {}
