import { ServiceGroup } from './service-group';

export class ServiceNote {
    serviceNoteId: number ;
    tempId: number;
    note: string;
    createdOn: number;
    type: string;
    serviceGroup: ServiceGroup;
}
