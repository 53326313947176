import * as tslib_1 from "tslib";
import { AbstractSearchCriteria } from './abstract-search-criteria';
var RoomFacilitySearchCriteria = /** @class */ (function (_super) {
    tslib_1.__extends(RoomFacilitySearchCriteria, _super);
    function RoomFacilitySearchCriteria() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.name = '';
        return _this;
    }
    return RoomFacilitySearchCriteria;
}(AbstractSearchCriteria));
export { RoomFacilitySearchCriteria };
