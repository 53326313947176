import { SortDirection } from '../helper/sort-direction';
import {SupplierBusinessType} from '../supplier/supplier';
import { AbstractSearchCriteria } from './abstract-search-criteria';
import { KeyControlAndAbstractSearchCriteria } from './key-control-and-abstract-search-criteria';

export class SupplierSearchCriteria extends KeyControlAndAbstractSearchCriteria {
    start: number;
    page: number;
    size: number;
    sortBy = '';
    sortDirection: SortDirection;
    supplierId: number;
    resourceTypes: string[] = [];
    resourceTypesStr: any;
    profileType = '';
    resourceId: number;
    supplierType: number;
    businessType: SupplierBusinessType;
    code = '';
    name = '';
    codeOrName = '';
    locale = '';
    registrationNum = '';
    seatingCapacity: number;
    fuelType = '';
    transferMode = '';
    vehicleModel = '';
    vehicleName = '';
    minPax: number;
    maxPax: number;
    country = '';
    city = '';
    currency = '';
    paymentMethod = '';
    startDate: any;
    endDate: any;
    vehicleAmenities: string[];
    driver = '';
    serviceDate: any;
    route = '';
    status = 'true';
    resourceCategory: ResourceCategory;

    // to filter by rejected resourceId list
    rejectedSupplierIdList: number[];

}

export enum ResourceCategory {
    TRANSFER = 0,
    GENERIC = 1
}
