/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../node_modules/@tc/content-strip/content-strip.component.ngfactory";
import * as i2 from "@tc/content-strip/content-strip.component";
import * as i3 from "../../../widgets/shared/result-content-card-generic/result-content-card-generic.component.ngfactory";
import * as i4 from "../../../widgets/shared/result-content-card-generic/result-content-card-generic.component";
import * as i5 from "../../../widgets/shared/op-expanded-content-card-header/op-expanded-content-card-header.component.ngfactory";
import * as i6 from "../../../widgets/shared/op-expanded-content-card-header/op-expanded-content-card-header.component";
import * as i7 from "../booking-detail-view/booking-detail-view.component.ngfactory";
import * as i8 from "../booking-detail-view/booking-detail-view.component";
import * as i9 from "../../../services/backend-consumers/reservation-v2/booking-management.service";
import * as i10 from "@tc-core/util/helpers/common-helper.service";
import * as i11 from "../../../services/util/ui/content-summary-card.service";
import * as i12 from "../../../services/util/framework/data-store.service";
import * as i13 from "@tc/modal/modal.service";
import * as i14 from "@tc-core/util/framework/config-loader.service";
import * as i15 from "../../../services/user-management/user-management.service";
import * as i16 from "../../../../../node_modules/@tc/expanded-content-card/expanded-content-card.component.ngfactory";
import * as i17 from "@tc/expanded-content-card/expanded-content-card.component";
import * as i18 from "@angular/common";
import * as i19 from "@tc/directives/expansion-panel/expansion-panel.directive";
import * as i20 from "@tc-core/util/security/authorization.service";
import * as i21 from "@tc/pipes/advanced-sorter.pipe";
import * as i22 from "@tc/pipes/advanced-pager.pipe";
import * as i23 from "./adv-booking-search-results.component";
var styles_AdvBookingSearchResultsComponent = [];
var RenderType_AdvBookingSearchResultsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AdvBookingSearchResultsComponent, data: {} });
export { RenderType_AdvBookingSearchResultsComponent as RenderType_AdvBookingSearchResultsComponent };
function View_AdvBookingSearchResultsComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [], [[8, "id", 0]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "tc-content-strip", [], null, [[null, "actionClick"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("actionClick" === en)) {
        var pd_0 = (_co.onActionClick(_v.parent.context.$implicit, $event) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.onSelection(_v.parent.context.$implicit, _v.parent.context.index) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_ContentStripComponent_0, i1.RenderType_ContentStripComponent)), i0.ɵdid(2, 114688, null, 0, i2.ContentStripComponent, [], null, { actionClick: "actionClick" }), (_l()(), i0.ɵeld(3, 0, null, 0, 1, "tc-result-content-card-generic", [], null, null, null, i3.View_ResultContentCardGenericComponent_0, i3.RenderType_ResultContentCardGenericComponent)), i0.ɵdid(4, 114688, null, 0, i4.ResultContentCardGenericComponent, [], { summaryContent: [0, "summaryContent"] }, null)], function (_ck, _v) { _ck(_v, 2, 0); var currVal_1 = _v.parent.context.$implicit; _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i0.ɵinlineInterpolate(1, "clientSumExp_", _v.parent.context.index, ""); _ck(_v, 0, 0, currVal_0); }); }
function View_AdvBookingSearchResultsComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-op-expanded-content-card-header", [], null, [[null, "editClick"], [null, "editAsNewClick"], [null, "headerClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("editClick" === en)) {
        var pd_0 = (_co.onEditClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("editAsNewClick" === en)) {
        var pd_1 = (_co.onEditAsNewClick($event) !== false);
        ad = (pd_1 && ad);
    } if (("headerClick" === en)) {
        var pd_2 = (_co.onHeaderClick($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i5.View_OpExpandedContentCardHeaderComponent_0, i5.RenderType_OpExpandedContentCardHeaderComponent)), i0.ɵdid(1, 114688, null, 0, i6.OpExpandedContentCardHeaderComponent, [], { headerText: [0, "headerText"], subHeaderText: [1, "subHeaderText"], showEdit: [2, "showEdit"], showEditAsNew: [3, "showEditAsNew"], showCalendar: [4, "showCalendar"] }, { headerClick: "headerClick", editClick: "editClick", editAsNewClick: "editAsNewClick" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getHeader(); var currVal_1 = _co.getSubHeader(); var currVal_2 = _co.showEditBtn; var currVal_3 = _co.showEditAsNew; var currVal_4 = false; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
function View_AdvBookingSearchResultsComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-booking-detail-view", [], null, null, null, i7.View_BookingDetailViewComponent_0, i7.RenderType_BookingDetailViewComponent)), i0.ɵdid(1, 114688, null, 0, i8.BookingDetailViewComponent, [i9.BookingManagementService, i10.CommonHelper, i11.ContentSummaryCardService, i12.DataStoreService, i13.ModalService, i14.ConfigLoader, i15.UserManagementService], { booking: [0, "booking"], summaryCardData: [1, "summaryCardData"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.detailedObject; var currVal_1 = _co.selectedSummary; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_AdvBookingSearchResultsComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [], [[8, "id", 0]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 5, "tc-expanded-content-card", [["class", "tc-expanded-content-card tc-form-view"]], null, null, null, i16.View_ExpandedContentCardComponent_0, i16.RenderType_ExpandedContentCardComponent)), i0.ɵdid(2, 114688, null, 0, i17.ExpandedContentCardComponent, [], { isEdit: [0, "isEdit"] }, null), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_AdvBookingSearchResultsComponent_5)), i0.ɵdid(4, 16384, null, 0, i18.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_AdvBookingSearchResultsComponent_6)), i0.ɵdid(6, 16384, null, 0, i18.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = false; _ck(_v, 2, 0, currVal_1); var currVal_2 = _co.selectedSummary; _ck(_v, 4, 0, currVal_2); var currVal_3 = (_co.selectedSummary && _co.detailedObject); _ck(_v, 6, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i0.ɵinlineInterpolate(1, "clientDetailExp_", _v.parent.context.index, ""); _ck(_v, 0, 0, currVal_0); }); }
function View_AdvBookingSearchResultsComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 7, "div", [["class", "client-search-result-item-wrapper"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, [[2, 0], ["clientExp", 1]], null, 6, "div", [["tabindex", "-1"], ["tcExpansionPanel", ""]], null, [[null, "expand"], [null, "collapse"], ["document", "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("document:click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 3).handleClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("expand" === en)) {
        var pd_1 = (_co.onPanelExpand(_v.context.index) !== false);
        ad = (pd_1 && ad);
    } if (("collapse" === en)) {
        var pd_2 = ((_co.expandedPanelIndex = (0 - 1)) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i0.ɵdid(3, 606208, [[1, 4]], 1, i19.ExpansionPanelDirective, [i0.ElementRef, i20.AuthorizationService], { enabled: [0, "enabled"] }, { expand: "expand", collapse: "collapse" }), i0.ɵqud(335544320, 3, { tooltipDirective: 0 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AdvBookingSearchResultsComponent_3)), i0.ɵdid(6, 81920, null, 0, i19.ExpansionPanelSummaryDirective, [i0.ViewContainerRef, i0.TemplateRef, i19.ExpansionPanelDirective], null, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AdvBookingSearchResultsComponent_4)), i0.ɵdid(8, 81920, null, 0, i19.ExpansionPanelDetailDirective, [i0.ViewContainerRef, i0.TemplateRef, i19.ExpansionPanelDirective], null, null)], function (_ck, _v) { var currVal_0 = true; _ck(_v, 3, 0, currVal_0); _ck(_v, 6, 0); _ck(_v, 8, 0); }, null); }
function View_AdvBookingSearchResultsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "adv-client-search"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 3, null, View_AdvBookingSearchResultsComponent_2)), i0.ɵdid(2, 278528, null, 0, i18.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i0.ɵppd(3, 3), i0.ɵppd(4, 3)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 2, 0, _ck(_v, 4, 0, i0.ɵnov(_v.parent, 0), i0.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i0.ɵnov(_v.parent, 1), _co.summaryList, _co.pager.currPage, _co.pager.itemsPerPage)), _co.sortingHandler, _co.sorter)); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_AdvBookingSearchResultsComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i21.AdvancedSorterPipe, []), i0.ɵpid(0, i22.AdvancedPagerPipe, []), i0.ɵqud(671088640, 1, { expansionPanels: 1 }), i0.ɵqud(671088640, 2, { clientExp: 0 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AdvBookingSearchResultsComponent_1)), i0.ɵdid(5, 16384, null, 0, i18.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.summaryList; _ck(_v, 5, 0, currVal_0); }, null); }
export function View_AdvBookingSearchResultsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-adv-booking-search-results", [], null, null, null, View_AdvBookingSearchResultsComponent_0, RenderType_AdvBookingSearchResultsComponent)), i0.ɵdid(1, 4308992, null, 0, i23.AdvBookingSearchResultsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AdvBookingSearchResultsComponentNgFactory = i0.ɵccf("tc-adv-booking-search-results", i23.AdvBookingSearchResultsComponent, View_AdvBookingSearchResultsComponent_Host_0, { sorter: "sorter", sortingHandler: "sortingHandler", sorters: "sorters", pager: "pager", isSingleResultExpand: "isSingleResultExpand", summaryList: "summaryList", selectedSummary: "selectedSummary", detailedObject: "detailedObject", selectedVehicleCost: "selectedVehicleCost", showEditBtn: "showEditBtn", showEditAsNew: "showEditAsNew" }, { select: "select", edit: "edit", editAsNew: "editAsNew", cardScroll: "cardScroll", focus: "focus" }, []);
export { AdvBookingSearchResultsComponentNgFactory as AdvBookingSearchResultsComponentNgFactory };
