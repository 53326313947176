import { Location } from '@angular/common';
import { AfterViewInit, Component, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Currency } from '@tc-core/model/it/codegen/tbx/api/data/controls/currency';
import { TcApiError } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-api-error';
import { TcErrorType } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-error';
import { TcHttpError } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-http-error';
import { LEVEL } from '@tc-core/model/it/codegen/ui/framework/dialog-model';
import { ModalData } from '@tc-core/model/it/codegen/ui/framework/modal-data';
import { ERROR_LEVEL, PageSection } from '@tc-core/model/it/codegen/ui/framework/page-section';
import { SummaryCardData } from '@tc-core/model/it/codegen/ui/framework/summary-card-data';
import { TC } from '@tc-core/util/constants';
import { EventManager } from '@tc-core/util/framework/event-manager.service';
import { FocusViewManager } from '@tc-core/util/framework/focus-view-manager.service';
import { UserJourneyManager } from '@tc-core/util/framework/user-journey-manager.service';
import { CommonHelper } from '@tc-core/util/helpers/common-helper.service';
import { SpinnerService } from '@tc-core/util/ui/spinner.service';
import { DialogService } from '@tc/dialog/dialog.service';
import { ExpansionPanelDirective } from '@tc/directives/expansion-panel';
import { ModalService } from '@tc/modal/modal.service';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { TCO } from '../../../constants';
import {
    CityChange,
    CountryChange,
    CountryStateCityWrapper,
    InitialCountryStateCityCodes,
    StateChange
} from '../../../models/common/country-state-city-wrapper';
import { City, Country, State } from '../../../models/common/locale';
import { PaymentInfo } from '../../../models/common/payment-info';
import { CurrencySearchCriteria } from '../../../models/criteria/currency-search-criteria';
import { GenericSearchCriteria } from '../../../models/criteria/generic-search-criteria';
import { ResourceCategory, SupplierSearchCriteria } from '../../../models/criteria/supplier-search-criteria';
import { UserProfileSearchCriteria } from '../../../models/criteria/user-profile-search-criteria';
import { VehicleSearchCriteria } from '../../../models/criteria/vehicle-search-criteria';
import { PageType } from '../../../models/helper/page-type';
import { ContactDetails } from '../../../models/profile/contact-details';
import { DocumentData } from '../../../models/profile/document';
import { ResourceType } from '../../../models/reservation/assignment';
import { Resource } from '../../../models/resource/resource';
import { TransferResourceSpecificInformation } from '../../../models/resource/transfer-resource-specific-information';
import { Vehicle } from '../../../models/resource/vehicle';
import { GenericResourceSummary } from '../../../models/summary/generic-resource-summary';
import { SupplierSummaryDepricated } from '../../../models/summary/supplier-summary-depricated';
import { InsuranceInformation } from '../../../models/supplier/insurance-information';
import { ServiceInformation } from '../../../models/supplier/service-information';
import { UserProfile } from '../../../models/user/user-profile';
import {
    DataHandlerService
} from '../../../services/backend-consumers/master-data-handler-service/data-handler.service';
import { CurrencyService } from '../../../services/backend-consumers/setups/currency.service';
import { GenericResourceService } from '../../../services/backend-consumers/supplier-service/generic-resource.service';
import {
    ProfileManagementService
} from '../../../services/backend-consumers/supplier-service/profile-management.service';
import { SupplierService } from '../../../services/backend-consumers/supplier-service/supplier.service';
import { UserProfileService } from '../../../services/backend-consumers/user-service/user-profile.service';
import {
    CountryStateCityService
} from '../../../services/business-utils/country-state-city/country-state-city.service';
import { ChangeDetectorService } from '../../../services/util/change-detector/change-detector.service';

import { DMCCommon } from '../../../services/util/common/dmc-common';
import { UtilService } from '../../../services/util/common/util.service';
import { RootService } from '../../../services/util/core-services/root.service';
import { DataKey, DataStoreService } from '../../../services/util/framework/data-store.service';
import { DMCQueryParamsService } from '../../../services/util/framework/dmc-query-params.service';
import { ResponseUtil } from '../../../services/util/response/response-util.service';
import { DMCLocalStorageService, LocalDataKey } from '../../../services/util/system/dmc-local-storage.service';
import { CardDataHandlerService } from '../../../services/util/ui/card-data-handler-service.service';
import { AdditionalContactsComponent } from '../../../widgets/shared/additional-contacts/additional-contacts.component';
import { TaxSchemesComponent } from '../../../widgets/shared/tax-schemes/tax-schemes.component';
import {
    VehicleSpecificInformationComponent
} from '../../../widgets/shared/vehicle-specific-information/vehicle-specific-information.component';
import { AddDocumentsComponent } from '../add-documents/add-documents.component';
import {
    ManageSupplierAttributesWrapperComponent
} from '../manage-supplier-attributes-wrapper/manage-supplier-attributes-wrapper.component';
import { AddNoteComponent } from '../shared/add-note/add-note.component';

@Component({
    selector: 'tc-vehicle',
    templateUrl: './vehicle.component.html'

})
export class VehicleComponent implements OnInit, OnDestroy, AfterViewInit {

    pageType: string = '';
    resourceType = '';
    filteredSuppliers = [];
    filteredDrivers = [];

    // after refactoring
    resource: Resource;
    vehicleUnedited: Resource;
    contactDetails: ContactDetails = new ContactDetails();
    additionalContactDetails: ContactDetails[] = [];

    // additional contacts
    titles: any[];
    categories: any[];
    additionalContactSummary: SummaryCardData;
    CARD_CONFIG_ADDITIONALCONTACT = 'additionalContact';
    additionalContactContents: SummaryCardData[] = [];

    // tax schemes
    CARD_CONFIG_TAXSCHEME = 'taxScheme';
    taxSchemeSummary: SummaryCardData;
    taxSchemeContents: SummaryCardData[] = [];
    taxSchemes: any[];

    // documents
    docTypeOptions: any[];
    formatOptions = [];
    dispatchTypes = [];
    frequencyOptions = [];
    documentSummary: SummaryCardData;
    CARD_CONFIG_DOCUMENT = 'documents';
    documentContents: SummaryCardData[] = [];
    addingDocumentCount = 0;
    docTypes = [];
    disableKeyControlEdit = false;

    assignedSupplierForResource: SupplierSummaryDepricated;
    assignedDriverForResource: GenericResourceSummary;

    // validations
    dataChanged = false;
    formSaved = false;
    private vehiclePaymentValid = false;
    private vehicleInformationValid = false;
    private keyControlInformationValid = false;
    private insuranceInformationValid = false;
    private vehicleContactDetailsValid = false;
    private additionalContactsValid = true;
    private serviceInfoValid = true;
    private isSaveEnable = false;
    private hasValidSupplier = false;
    private missingCostForSupplier = false;
    private hasValidDriver = false;

    vehicle: TransferResourceSpecificInformation;

    transferModes: any[] = [];
    driverName: string[] = ['None', 'Nico Roseberg', 'Sebastian Vettel', 'Jolyon Palmer', 'Jenson Button'];
    fuelTypeOptions: string[] = ['Petrol', 'Diesel', 'Electric', 'Hybrid'];
    currencyList: any[];
    payMethods: any[];
    defaultFacilityDetails: any[];

    supplierAttributes: any[] = [];
    supplierTypes = [];

    filteredUsers: UserProfile[] = [];

    userProfile: UserProfile;

    defaultContactCategory: any;

    private documentTypesSubscription: Subscription = new Subscription();
    private modalCloseSubscription: Subscription = new Subscription();
    @ViewChildren(ExpansionPanelDirective) _expansionPanels: QueryList<ExpansionPanelDirective>;
    private attributesSubscription: Subscription = new Subscription();

    private createResourceObsv: Subscription = new Subscription();
    private updateResourceObsv: Subscription = new Subscription();

    // country state city
    countryStateCityChangeMap: Map<number, CountryStateCityWrapper> = new Map();
    cityList: City[];
    stateList: State[];
    countryList: Country[];

    // --country state city--//

    // page section
    private pageSectionsMap: Map<string, PageSection>;
    public vehicleInfoSection: PageSection;
    public keyControlSection: PageSection;
    public resourceKeyControlSection: PageSection;
    public contactDetailsSection: PageSection;
    public paymentInfoSection: PageSection;
    public vehicleAmenitiesSection: PageSection;
    public insuranceInfoSection: PageSection;
    public serviceInfoSection: PageSection;
    public additionalContactsSection: PageSection;
    public supplierAttributesSection: PageSection;
    public notesSection: PageSection;
    public taxSchemeSection: PageSection;
    public documentSection: PageSection;
    private CARD_CONFIG_GENERIC_EDIT = 'vehicle_edit';
    private headerTextNew: string = 'New Vehicle Profile';
    private headerTextEdit: string = 'Edit Vehicle Profile';
    private vehicleModels: any;

    // -- page section --//

    @ViewChild(VehicleSpecificInformationComponent) resourceSpecificInfoComponent: VehicleSpecificInformationComponent;
    @ViewChild(AdditionalContactsComponent) additionalContactComponent: AdditionalContactsComponent;
    @ViewChild(TaxSchemesComponent) taxSchemeComponent: TaxSchemesComponent;

    constructor(
        private dialogService: DialogService,
        private userJourneyManager: UserJourneyManager,
        private focusViewManager: FocusViewManager,
        private em: EventManager,
        private location: Location,
        private dataStore: DataStoreService,
        private dataHandlerService: DataHandlerService,
        private currencyService: CurrencyService,
        private profileManagementService: ProfileManagementService,
        private supplierService: SupplierService,
        private modalService: ModalService,
        private route: ActivatedRoute,
        private cardDataHandlerService: CardDataHandlerService,
        private countryStateCityService: CountryStateCityService,
        private changeDetectorService: ChangeDetectorService,
        private genericResourceService: GenericResourceService,
        private userProfileService: UserProfileService,
        private utilService: UtilService,
        private commonHelper: CommonHelper,
        private spinnerService: SpinnerService,
        private common: DMCCommon,
        private rootService: RootService,
        private queryParamService: DMCQueryParamsService,
        private localStorage: DMCLocalStorageService
    ) {
    }

    ngOnInit() {
        this.resourceType = ResourceType.vehicle;
        this.pageType = this.route.snapshot.queryParamMap.get('page').split('_')[0];

        this.retrieveMasterData();

        this.getSupplierAttributes();

        // country state city
        this.subscribeCountryStateCityListChange();
        // --country state city--//
        this.getUserDetails();

        // data model initiation
        this.initDataModel();

        if (this.pageType === PageType.CREATE) {
            console.log('create page');
            this.dataStore.get(DataKey.selectedVehicleForEditAsNew, true)
                .pipe(take(1))
                .subscribe(data => {
                    if (data != null) {
                        this.dataStore.set(DataKey.selectedVehicleForEditAsNew, null, true);
                        this.fillVehicleObject(data);
                    } else {
                        let data: Resource = this.localStorage.load<Resource>(LocalDataKey.unSavedVehicle);
                        if (data && !data.resourceId) {
                            this.fillVehicleObject(data);
                        }
                    }
                    this.initPageSectionReferences();
                });
        } else if (this.pageType === PageType.EDIT) {
            console.log('edit page');
            this.dataStore.get(DataKey.selectedVehicleForEdit, true)
                .pipe(take(1))
                .subscribe(data => {
                    if (data != null) {
                        this.fillVehicleObject(data);

                        this.vehicleUnedited = JSON.parse(JSON.stringify(this.resource));
                        this.disableKeyControlEdit = this.vehicleUnedited.isEditTriggered;
                        if (this.vehicleUnedited.contactDetails) {
                            this.vehicleUnedited.contactDetails.sort((a, b) => a.contactId - b.contactId);
                        }
                    } else {
                        let data: Resource = this.localStorage.load<Resource>(LocalDataKey.unSavedVehicle);
                        this.fillVehicleObject(data);

                        this.vehicleUnedited = JSON.parse(JSON.stringify(this.resource));
                        if (this.vehicleUnedited.contactDetails) {
                            this.vehicleUnedited.contactDetails.sort((a, b) => a.contactId - b.contactId);
                        }
                    }
                    this.initPageSectionReferences();
                });
        }

        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'SAVE').subscribe(e => {
            this.saveVehicle();
        });
        this.dataStore.get(DataKey.vehicleModels).subscribe(data => {
            if (data !== null) {
                this.vehicleModels = data;
            }
        });

    }

    fillVehicleObject(data: Resource) {
        this.resource = data;

        this.localStorage.store(LocalDataKey.unSavedVehicle, data);

        // data model initiation
        this.initDataModel();

        // set supplier
        if (this.resource.supplierId > 0) {
            // this.missingCostForSupplier = this.resource.hasResourceCost ? false : true;
            this.validateSupplierWithCost(this.resource);
            this.assignedSupplierForResource = new SupplierSummaryDepricated();
            this.assignedSupplierForResource.name = this.resource.supplierName;
            this.assignedSupplierForResource.supplierId = this.resource.supplierId;
            this.assignedSupplierForResource.code = this.resource.supplierCode;
            this.hasValidSupplier = true;
        }
        // set driver
        if (this.resource.vehicle && this.resource.vehicle.driverId > 0) {
            this.getAndSetDriver(this.resource.vehicle.driverId);
        }
        this.localStorage.store(LocalDataKey.unSavedVehicle, this.resource);
        this.breakModel();
    }

    validateSupplierWithCost(resource: Resource) {
        let supplierSearchCriteria = new SupplierSearchCriteria();

        supplierSearchCriteria.status = 'true';
        supplierSearchCriteria.resourceCategory = ResourceCategory.TRANSFER;
        supplierSearchCriteria.resourceTypes = [];
        supplierSearchCriteria.resourceTypes.push(ResourceType.vehicle);
        supplierSearchCriteria.transferMode = this.resource.vehicle.trsMode;
        supplierSearchCriteria.vehicleModel = this.resource.vehicle.vehicleModel;
        this.supplierService.searchSuppliers(supplierSearchCriteria).subscribe(
            result => {
                let suppliers = ResponseUtil.getDataArray(result);
                if (suppliers && suppliers.length > 0) {
                    let supplierExist = suppliers.find(value => value.supplierId === resource.supplierId);
                    this.missingCostForSupplier = supplierExist ? false : true;
                } else {
                    this.missingCostForSupplier = true;
                }
            }, error => {
            }
        );
    }

    private initPageSectionReferences() {
        this.pageSectionsMap = this.cardDataHandlerService.getAllPageSections(
            this.CARD_CONFIG_GENERIC_EDIT,
            this.resource
        );

        this.vehicleInfoSection = this.pageSectionsMap.get('vehicle_info');
        this.keyControlSection = this.pageSectionsMap.get('key_controls');
        this.contactDetailsSection = this.pageSectionsMap.get('contact_details');
        this.paymentInfoSection = this.pageSectionsMap.get('payment_details');
        this.vehicleAmenitiesSection = this.pageSectionsMap.get('vehicle_amenities');
        this.insuranceInfoSection = this.pageSectionsMap.get('insurance_info');
        this.serviceInfoSection = this.pageSectionsMap.get('service_info');
        this.additionalContactsSection = this.pageSectionsMap.get('additional_contacts');
        this.supplierAttributesSection = this.pageSectionsMap.get('supplier_attributes');
        this.notesSection = this.pageSectionsMap.get('notes');
        this.taxSchemeSection = this.pageSectionsMap.get('tax_schemes');
        this.documentSection = this.pageSectionsMap.get('documents');

        this.setupHeader();
        this.filterPageSectionActions();
    }

    filterPageSectionActions() {
        if (this.resource && this.resource.resourceType !== ResourceType.vehicle) {
            this.taxSchemeSection.actions = this.taxSchemeSection.actions.filter(action => {
                return action !== 'TRIGGER_ADD_TAX_SCHEME';
            });
        }
    }

    public onHeaderActionClick(event) {
        switch (event.id) {
            case 'TRIGGER_ADD_ADDITIONAL_CONTACT': {
                console.log('add addition contact');
                this.additionalContactComponent.addAdditionalContact();
                break;
            }
            case 'TRIGGER_SET_SUPPLIER_ATTRIBUTES': {
                console.log('add supplier attribute');
                this.onSupplierAttributesClick();
                break;
            }
            case 'TRIGGER_ADD_NOTE': {
                console.log('add note');
                this.onAddNote();
                break;
            }
            case 'TRIGGER_ADD_TAX_SCHEME': {
                console.log('add tax scheme');
                if (this.resource && this.resource.resourceType !== ResourceType.vehicle) {
                    this.taxSchemeComponent.addTaxScheme();
                }
                break;
            }
            case 'TRIGGER_ADD_DOCUMENTS': {
                console.log('add document');
                this.onAddDocument();
                break;
            }
        }
    }

    setupSectionTabError(section: PageSection): boolean {
        if (!section) {
            return false;
        }
        const shouldShow = section.showError === ERROR_LEVEL.SHOW_ERROR_TAB || section.showError ===
            ERROR_LEVEL.SHOW_ERROR_TAB_FORM;
        return section.error && shouldShow;
    }

    private setupHeader() {
        if (this.pageType === PageType.CREATE) {
            this.userJourneyManager.setCurrentHeading(this.headerTextNew);
        } else if (this.pageType === PageType.EDIT) {
            this.userJourneyManager.setCurrentHeading(this.headerTextEdit);
        }
        this.setHeaderActions();
        this.setHeaderActionTriggers();
        // this.setHeaderKebab();
    }

    private setHeaderActions() {
        let actionKeys = [];
        actionKeys.push('TRIGGER_CLOSE');
        this.userJourneyManager.setHeaderActions(actionKeys);
    }

    private removeHeaderActions() {
        this.userJourneyManager.setHeaderActions([]);
        this.em.removeEvents([
            'TRIGGER_CLOSE'
        ]);
    }

    private setHeaderActionTriggers() {
        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'TRIGGER_CLOSE').subscribe(e => {
            this.onCloseClick(null);
        });
    }

    public onCloseClick(event) {
        this.location.back();
    }

    private breakModel() {
        if (this.resource && this.resource.vehicle) {
            // this.copyToResourceSpecificInfo();
        }
        if (this.resource && this.resource.contactDetails != null && this.resource.contactDetails.length > 0) {
            this.copyToContactDetails();
        }
    }

    private initDataModel() {
        if (!this.resource) {
            this.resource = new Resource();
            this.resource.resourceType = this.resourceType;
        }
        if (!this.resource.contactDetails) {
            this.resource.contactDetails = [];
        }
        if (!this.resource.documents) {
            this.resource.documents = [];
        }
        if (!this.resource.taxSchemes) {
            this.resource.taxSchemes = [];
        }
        if (!this.resource.vehicle) {
            this.resource.vehicle = new Vehicle();
        }
        if (!this.resource.vehicle.resInsuranceInfo) {
            this.resource.vehicle.resInsuranceInfo = new InsuranceInformation();
        }
        if (!this.resource.vehicle.resServiceInfo) {
            this.resource.vehicle.resServiceInfo = new ServiceInformation();
        }
        if (!this.resource.vehicle.facilities) {
            this.resource.vehicle.facilities = [];
        }
        if (!this.resource.paymentInfo) {
            this.resource.paymentInfo = new PaymentInfo();
        }
        if (!this.resource.supplierAttributes) {
            this.resource.supplierAttributes = [];
        }
        if (!this.resource.remarks) {
            this.resource.remarks = [];
        }
        if (!this.contactDetails) {
            this.contactDetails = new ContactDetails();
        }
        if (!this.additionalContactDetails) {
            this.additionalContactDetails = [];
        }
    }

    private retrieveMasterData() {
        this.dataHandlerService.retrieveDocumentTypes().subscribe(result => {
            this.docTypeOptions = result.data;
        });
        this.dataHandlerService.retrieveFormatOptions().subscribe(
            result => {
                this.formatOptions = result.data;
            });

        this.dataHandlerService.retrieveDispatchTypes().subscribe(
            result => {
                this.dispatchTypes = result.data;
            });

        this.dataHandlerService.retrieveFrequencyOptions().subscribe(
            result => {
                this.frequencyOptions = result.data;
            });
        this.dataHandlerService.retrieveTransferModes().subscribe(result => {

            this.transferModes = result.data;

        });
        this.dataHandlerService.retrieveTitleDetails().subscribe(
            result => {
                this.titles = result.data;
            });
        this.dataHandlerService.retrieveCategories().subscribe(
            result => {
                this.categories = result.data;
                this.defaultContactCategory = this.categories.find(value => value.name === 'Reservation');
            });
        let currencySearchCriteria = new CurrencySearchCriteria();
        currencySearchCriteria.size = 1000000;
        this.currencyService.getRows(currencySearchCriteria).subscribe(
            result => {
                this.currencyList = ResponseUtil.getDataArray<Currency>(result);
            });

        this.dataHandlerService.retrievePaymentMethods().subscribe(
            result => {
                this.payMethods = result.data;
            });
        this.dataHandlerService.retrieveVehicleFacilities().subscribe(result => {
            this.defaultFacilityDetails = result.data;
        });
        this.dataHandlerService.retrieveTaxSchemes().subscribe(
            result => {
                this.taxSchemes = result.data;
            });
        this.dataHandlerService.retrieveSupplierTypes().subscribe(
            result => {
                this.supplierTypes = result.data;
            });
    }

    // private copyToResourceSpecificInfo() {
    //     this.vehicle.transferMode = this.resource.vehicle.trsMode;
    //     this.vehicle.vehicleModel = this.resource.vehicle.vehicleModel;
    //     this.vehicle.vehicleName = this.resource.vehicle.vehicleName;
    //     this.vehicle.licenseNumber = this.resource.vehicle.licencePlate;
    //     this.vehicle.fuelType = this.resource.vehicle.fuelType;
    //     this.vehicle.driver = this.resource.vehicle.driver;
    //     this.vehicle.supplier = this.resource.supplierId;
    //     this.vehicle.seatingCapacity = this.resource.capacity;
    //     this.vehicle.maxPax = this.resource.maxPax;
    //     this.vehicle.minPax = this.resource.minPax;
    //     this.vehicle.fuelConsumption = this.resource.vehicle.fuelConsumption;
    // }

    public onChangeVehicleInfoForm(event) {
        this.vehicleInformationValid = event;
        if (this.vehicleInfoSection) {
            this.vehicleInfoSection.error = !(this.vehicleInformationValid && this.hasValidSupplier);
        }
        this.enableSaveButton();
    }

    public onChangeKeyControlInfoForm(event) {
        this.keyControlInformationValid = event;
        this.enableSaveButton();
        if (this.keyControlSection) {
            this.keyControlSection.error = !this.keyControlInformationValid;
        }
    }

    public onChangeVehicleContactForm(event) {
        this.vehicleContactDetailsValid = event;
        if (this.contactDetailsSection) {
            this.contactDetailsSection.error = !this.vehicleContactDetailsValid;
        }
        this.enableSaveButton();
    }

    public onAdditionalContactsModified() {

        this.additionalContactSummary = this.cardDataHandlerService.processCardData(
            this.CARD_CONFIG_ADDITIONALCONTACT,
            this.additionalContactDetails,
            'additional_contact_summary'
        );

        if (this.additionalContactDetails.length > 0) {
            this.additionalContactContents = [];
            this.additionalContactDetails.forEach(address => {
                let additionalContactContent = this.cardDataHandlerService.processCardData(
                    this.CARD_CONFIG_ADDITIONALCONTACT, address, 'additional_contact_detail');
                this.additionalContactContents.push(additionalContactContent);
            });
        }

        this.enableSaveButton();
    }

    public onChangeInsuranceInformation($event: boolean) {
        this.insuranceInformationValid = $event;
        if (this.paymentInfoSection) {
            this.insuranceInfoSection.error = !this.insuranceInformationValid;
        }
        this.enableSaveButton();
    }

    public onChangePaymentForm($event: boolean) {
        this.vehiclePaymentValid = $event;
        if (this.paymentInfoSection) {
            this.paymentInfoSection.error = !this.vehiclePaymentValid;
        }
        this.enableSaveButton();
    }

    public onChangeVehicleAmenities() {
        this.enableSaveButton();
    }

    public onChangeTaxSchemeFormValidity() {
        this.enableSaveButton();
    }

    getSupplierAttributes() {
        this.dataStore.set(DataKey.supplierAttributes, null, true);
        this.attributesSubscription = this.dataStore.get(DataKey.supplierAttributes).subscribe(
            data => {
                if (data != null) {
                    this.resource.supplierAttributes = data;
                    this.enableSaveButton();
                }
            });
    }

    public onSupplierAttributesClick() {
        this.dataStore.set(DataKey.modalClose, null);
        let dataObject = new ModalData(LEVEL.SUCCESS, 'Supplier Attributes',
            {'label': 'OK'},
            {'label': 'Close'},
            'supplier-attributes-modal',
            ManageSupplierAttributesWrapperComponent,
            {'supplierAttributes': this.resource.supplierAttributes}
        );
        this.confirmModal(dataObject);
        this.modalCloseSubscription = this.dataStore.get(DataKey.modalClose).subscribe((data) => {
            if (data != null) {
                this.modalCloseSubscription.unsubscribe();
                this.closeModal();
            }
        });
    }

    public onAddNote() {
        this.dataStore.set(DataKey.modalClose, null);
        let dataObject = new ModalData(LEVEL.SUCCESS, 'Add a note',

            null,
            null,
            'payment-modal',
            AddNoteComponent,
            {}
        );

        this.confirmModal(dataObject);

        this.modalCloseSubscription = this.dataStore.get(DataKey.modalClose).subscribe((data) => {
            if (data != null) {
                this.modalCloseSubscription.unsubscribe();
                this.closeModal();
            }
        });
    }

    private confirmModal(data: any): void {
        this.modalService.confirm(data).subscribe((res) => {
            console.log(res);
        });
    }

    public onTaxSchemeModified() {
        this.taxSchemeSummary = this.cardDataHandlerService.processCardData(
            this.CARD_CONFIG_TAXSCHEME,
            this.resource.taxSchemes,
            'tax_scheme_summary'
        );

        if (this.resource.taxSchemes.length > 0) {
            this.taxSchemeContents = [];
            this.resource.taxSchemes.forEach(address => {
                let taxSchemeContent = this.cardDataHandlerService.processCardData(
                    this.CARD_CONFIG_TAXSCHEME, address, 'tax_scheme_detail');
                this.taxSchemeContents.push(taxSchemeContent);
            });
        }
        this.enableSaveButton();
    }

    public onDocumentsModified() {
        this.documentSummary = this.cardDataHandlerService.processCardData(
            this.CARD_CONFIG_DOCUMENT,
            this.resource.documents,
            'document_summary'
        );

        if (this.resource.documents.length > 0) {
            this.documentContents = [];
            this.resource.documents.forEach(doc => {
                let documentContent = this.cardDataHandlerService.processCardData(
                    this.CARD_CONFIG_DOCUMENT, doc, 'document_detail');
                this.documentContents.push(documentContent);
            });
        }
    }

    public onAddDocument() {
        this.setOldDocsTypesAndSubscribeForNewTypes();
        this.dataStore.set(DataKey.modalClose, null);
        let dataObject = new ModalData(
            LEVEL.SUCCESS,
            'Add Documents',
            null,
            null,
            'add-service-menu',
            AddDocumentsComponent,
            {
                'docTypeOptions': this.docTypeOptions,
                'selectedDocs': this.docTypes
            }
        );
        this.confirmModal(dataObject);

        this.modalCloseSubscription = this.dataStore.get(DataKey.modalClose).subscribe((data) => {
            if (data != null) {
                this.modalCloseSubscription.unsubscribe();
                this.closeModal();
            }
        });
    }

    closeModal() {
        if (this.modalService.close()) {
            this.modalService.close().subscribe(
                (res) => {
                }
            );
        }
    }

    setOldDocsTypesAndSubscribeForNewTypes() {
        let oldDocTypes = [];
        this.resource.documents.forEach(
            doc => {
                let docTypeOption = this.docTypeOptions.find(docType => docType.code === doc.template);
                if (docTypeOption) {
                    oldDocTypes.push(docTypeOption);
                    doc.name = docTypeOption.name;
                }
            }
        );
        this.dataStore.set(DataKey.documentTypes, oldDocTypes, true);
        this.getDocumentTypes();
    }

    getDocumentTypes() {
        this.documentTypesSubscription = this.dataStore.get(DataKey.documentTypes).subscribe(data => {
            if (data != null) {
                this.docTypes = data;
                let existingDocTypes = [];
                this.resource.documents.forEach(
                    doc => {
                        let DocTypes_ = this.docTypes.find(docType => docType.code === doc.template);
                        existingDocTypes.push(DocTypes_);
                    }
                );
                let newTypes = this.docTypes.filter(docType => !existingDocTypes.includes(docType));
                if (newTypes.length) {
                    newTypes.forEach(docType => {
                        let document = new DocumentData();
                        document.tempId = this.addingDocumentCount++;
                        document.name = docType.name;
                        document.template = docType.code;
                        this.resource.documents.push(document);
                        this._expansionPanels.forEach((expansionElement) => {
                            if (expansionElement.id === 'document_summary_strip') {
                                expansionElement.expansionState.next(false);
                            }
                        });
                    });
                }
            }
            this.onDocumentsModified();
        });
    }

    public onSupplierTextChange(event: any) {
        // if (event.supplier !== '') {
        let supplierSearchCriteria = new SupplierSearchCriteria();

        if (this.assignedDriverForResource) {
            supplierSearchCriteria.supplierId = this.assignedDriverForResource.supplierId;
        } else {
            supplierSearchCriteria.name = event.supplier;
            supplierSearchCriteria.status = 'true';
            supplierSearchCriteria.resourceCategory = ResourceCategory.TRANSFER;
            supplierSearchCriteria.resourceTypes = [];
            supplierSearchCriteria.resourceTypes.push(ResourceType.vehicle);
            supplierSearchCriteria.kcCompany = this.resource.kcCompany;
            supplierSearchCriteria.kcDivision = this.resource.kcDivision;
            supplierSearchCriteria.kcBrand = this.resource.kcBrand;
            supplierSearchCriteria.kcDistributionChannel = this.resource.kcDistributionChannel;
            // supplierSearchCriteria.vehicleModel = event.vehicleModel;
            supplierSearchCriteria.transferMode = this.resource.vehicle.trsMode;
            supplierSearchCriteria.vehicleModel = this.resource.vehicle.vehicleModel;
        }
        this.supplierService.searchSuppliers(supplierSearchCriteria).subscribe(
            result => {
                this.filteredSuppliers = ResponseUtil.getDataArray(result);
            }, error => {
                this.filteredSuppliers = [];
            }
        );
        // }
    }

    public onSupplierClear($event: any) {
        this.assignedSupplierForResource = null;
        this.resource.supplierId = undefined;
        this.resource.supKcCompany = undefined;
        this.resource.supKcDivision = undefined;
        this.resource.supKcBrand = undefined;
        this.resource.supKcDistributionChannel = undefined;
        this.hasValidSupplier = false;
    }

    public onSupplierAssign($event: SupplierSummaryDepricated) {
        this.resource.supplierId = $event.supplierId;
        this.resource.supKcCompany = $event.kcCompany;
        this.resource.supKcDivision = $event.kcDivision;
        this.resource.supKcBrand = $event.kcBrand;
        this.resource.supKcDistributionChannel = $event.kcDistributionChannel;
        this.hasValidSupplier = true;
        this.missingCostForSupplier = false;
    }

    public onDriverTextChange($event: any) {
        if ($event.text !== '' && $event.text !== undefined ||
            (this.resource.supplierId)) {
            let driverSearchCriteria = new GenericSearchCriteria();
            driverSearchCriteria.activeStatus = 'true';
            driverSearchCriteria.firstName = $event.text;
            driverSearchCriteria.resourceType = 'driver';
            driverSearchCriteria.transferMode = this.resource.vehicle.trsMode;
            if (this.resource.supplierId) {
                driverSearchCriteria.supplierId = this.resource.supplierId;
            }
            this.genericResourceService.searchGenericResource(driverSearchCriteria)
                .subscribe(
                    result => {
                        this.filteredDrivers = ResponseUtil.getDataArray(result);
                    }
                    , error => {
                        this.filteredDrivers = [];
                    }
                );
        } else {
            this.filteredDrivers = [];
        }
    }

    public onDriverClear($event: any) {
        this.assignedDriverForResource = null;
        this.resource.vehicle.driverId = undefined;
        this.hasValidDriver = false;
    }

    public onDriverAssign($event: GenericResourceSummary) {
        this.resource.vehicle.driverId = $event.resourceId;
        this.assignedDriverForResource = $event;
        if (!this.assignedSupplierForResource) {
            let supplierSearchCriteria = new SupplierSearchCriteria();
            supplierSearchCriteria.supplierId = $event.supplierId;
            this.dataStore.set(DataKey.supplierSearchResults, null);
            this.supplierService.searchSuppliers(supplierSearchCriteria)
                .pipe(take(2))
                .subscribe(
                    result => {
                        if (result) {
                            let supplier = ResponseUtil.getFirstData(result);
                            if (supplier) {
                                this.resourceSpecificInfoComponent.updateAssignedSupplier(supplier);
                            }
                        }
                    }
                );
        }
        this.hasValidDriver = true;
    }

    getAndSetDriver(driverId: number) {
        let driverSearchCriteria = new GenericSearchCriteria();
        driverSearchCriteria.resourceId = driverId;
        driverSearchCriteria.activeStatus = 'true';
        this.genericResourceService.searchGenericResource(driverSearchCriteria)
            .pipe(take(2))
            .subscribe(
                result => {
                    if (result) {
                        let driver = ResponseUtil.getFirstData(result);
                        this.resourceSpecificInfoComponent.updateAssignedDriver(driver);
                        this.hasValidDriver = true;
                    }
                }
            );
    }

    public onUserTextChange($event: string) {
        if ($event !== '') {
            const userSearchCriteria = new UserProfileSearchCriteria();
            userSearchCriteria.name = $event;
            userSearchCriteria.resourceLinkSuggestion = true;
            userSearchCriteria.supplierId = this.resource.supplierId ? this.resource.supplierId : 0;
            userSearchCriteria.resourceSuggestIncludingId = this.resource.resourceId ? this.resource.resourceId : 0;
            userSearchCriteria.restrictedServiceTypes = [ResourceType.vehicle];

            this.userProfileService.getProfilesWithoutKey(userSearchCriteria).subscribe(
                result => {
                    this.filteredUsers = ResponseUtil.getDataArray(result);
                }, error => {
                    this.filteredUsers = [];
                }
            );
        }
    }

    public onUserClear($event: any) {
        this.dataChanged = true;
        this.resource.userProfile = null;
        this.enableSaveButton();
    }

    public onUserAssign(event: UserProfile) {
        this.dataChanged = true;
        this.resource.userProfile = event;
        this.enableSaveButton();

    }

    public onNotesModified() {
        this.enableSaveButton();
    }

    public onAdditionalContactsValidChange($event: boolean) {
        this.additionalContactsValid = $event;
        if (this.additionalContactsSection) {
            this.additionalContactsSection.error = !this.additionalContactsValid;
        }
        this.enableSaveButton();
    }

    public onChangeServiceInformation($event: boolean) {
        this.serviceInfoValid = $event;
        if (this.serviceInfoSection) {
            this.serviceInfoSection.error = !this.serviceInfoValid;
        }
        this.enableSaveButton();
    }

    private enableSaveButton() {
        this.updateDataChanged();
        if ((this.dataChanged && !this.formSaved)
            && this.vehiclePaymentValid
            && this.vehicleInformationValid
            && this.keyControlInformationValid
            && this.vehicleContactDetailsValid
            && this.additionalContactsValid
            && this.insuranceInformationValid
            && this.serviceInfoValid
            && this.hasValidSupplier
        ) {
            if (!this.resource.vehicle.driverId || this.resource.vehicle.driverId <= 0 || this.hasValidDriver) {
                this.userJourneyManager.canProceed.next(true);
                this.focusViewManager.canProceed.next(true);
                this.isSaveEnable = true;
                this.localStorage.store(LocalDataKey.unSavedVehicle, this.resource);
            }
        } else {
            this.disableSaveButton();
        }
    }

    private disableSaveButton() {
        this.userJourneyManager.canProceed.next(false);
        this.focusViewManager.canProceed.next(false);
        this.isSaveEnable = false;
    }

    private updateDataChanged() {
        this.updateResourceChanges();

        // to make it same as resourceUnedited
        this.resource.contactDetails.forEach(value => {
            if (value.state === '') {
                value.state = null;
            }
        });
        if (this.resource && this.resource.vehicle && this.resource.vehicle.resInsuranceInfo &&
            this.resource.vehicle.resInsuranceInfo.state === '') {
            this.resource.vehicle.resInsuranceInfo.state = null;
        }

        this.resource.contactDetails.sort((a, b) => a.contactId - b.contactId);
        if (JSON.stringify(this.resource) !== JSON.stringify(this.vehicleUnedited)) {
            this.dataChanged = true;
            this.formSaved = false;
        } else {
            this.dataChanged = false;
        }

    }

    // save data

    updateResourceChanges() {
        this.resource.contactDetails = [];
        this.resource.contactDetails.push(this.contactDetails);
        Array.prototype.push.apply(this.resource.contactDetails, this.additionalContactDetails);
    }

    private copyToContactDetails() {
        if (this.resource && this.resource.contactDetails) {
            this.additionalContactDetails = [];
            this.resource.contactDetails.forEach(
                (contactDetails: ContactDetails) => {
                    if (contactDetails.status) {
                        this.contactDetails = contactDetails;
                    } else {
                        this.additionalContactDetails.push(contactDetails);
                    }
                });
        }
    }

    private saveVehicle() {
        this.updateResourceChanges();
        console.log(this.resource);
        if (this.pageType === PageType.CREATE || !this.resource.resourceId) {
            this.spinnerService.show();
            if ((this.resource.supKcCompany === this.resource.kcCompany) &&
                ((!this.resource.supKcDivision || !this.resource.kcDivision ||
                        (this.resource.supKcDivision === this.resource.kcDivision)) &&
                    (!this.resource.supKcBrand || !this.resource.kcBrand ||
                        (this.resource.supKcBrand === this.resource.kcBrand)) &&
                    (!this.resource.supKcDistributionChannel || !this.resource.kcDistributionChannel ||
                        (this.resource.supKcDistributionChannel === this.resource.kcDistributionChannel)))) {
                this.profileManagementService.saveResource(this.resource).subscribe(value => {
                    if (this.commonHelper.dataValidity(value)) {
                        this.createResourceObsv.unsubscribe();
                        this.spinnerService.hide();
                        if (ResponseUtil.isSuccess(value)) {
                            this.formSaved = true;
                            this.dataChanged = false;
                            this.vehicleUnedited = JSON.parse(JSON.stringify(this.resource));
                            this.common.showSnackBar(
                                'Successfully saved the vehicle profile.',
                                3000,
                                TcErrorType.TYPE.INFO
                            );
                            this.localStorage.delete(LocalDataKey.unSavedVehicle);
                            this.enableSaveButton();
                            this.handleNavigation(value);
                        } else if (ResponseUtil.isError(value)) {
                            this.common.showSnackBar(
                                'Failed to save the vehicle profile',
                                3000,
                                TcErrorType.TYPE.ERROR
                            );
                        }
                    } else if (value instanceof TcApiError) {
                        this.spinnerService.hide();
                    } else if (value instanceof TcHttpError) {
                        this.spinnerService.hide();
                    }
                }, error => {
                });
            } else {
                this.common.showSnackBar(
                    'Supplier and Resource key controls mismatched',
                    3000,
                    TcErrorType.TYPE.ERROR
                );
            }

        } else if (this.pageType === PageType.EDIT && this.resource.resourceId) {
            this.dataStore.set(DataKey.updateResource, null, true);
            this.spinnerService.show();
            if ((this.resource.supKcCompany === this.resource.kcCompany) &&
                ((!this.resource.supKcDivision || !this.resource.kcDivision ||
                        (this.resource.supKcDivision === this.resource.kcDivision)) &&
                    (!this.resource.supKcBrand || !this.resource.kcBrand ||
                        (this.resource.supKcBrand === this.resource.kcBrand)) &&
                    (!this.resource.supKcDistributionChannel || !this.resource.kcDistributionChannel ||
                        (this.resource.supKcDistributionChannel === this.resource.kcDistributionChannel)))) {
                this.profileManagementService.updateResource(this.resource, String(this.resource.resourceId))
                    .subscribe(value => {
                        if (this.commonHelper.dataValidity(value)) {
                            this.createResourceObsv.unsubscribe();
                            this.dataStore.set(DataKey.updateResource, null, true);
                            this.spinnerService.hide();
                            if (ResponseUtil.isSuccess(value)) {
                                this.formSaved = true;
                                this.dataChanged = false;
                                this.vehicleUnedited = JSON.parse(JSON.stringify(this.resource));
                                this.common.showSnackBar(
                                    'Successfully updated the vehicle profile',
                                    3000,
                                    TcErrorType.TYPE.INFO
                                );
                                this.localStorage.delete(LocalDataKey.unSavedVehicle);
                                this.enableSaveButton();
                                this.handleNavigation(value);
                            } else if (ResponseUtil.isError(value)) {
                                this.common.showSnackBar(
                                    'Failed to update the vehicle profile',
                                    3000,
                                    TcErrorType.TYPE.ERROR
                                );
                            }
                        } else if (value instanceof TcApiError) {
                            this.spinnerService.hide();
                        } else if (value instanceof TcHttpError) {
                            this.spinnerService.hide();
                        }
                    }, error => {
                    });
            } else {
                this.common.showSnackBar(
                    'Supplier and Resource key controls mismatched',
                    3000,
                    TcErrorType.TYPE.ERROR
                );
            }
        }
    }

    handleNavigation(data: any) {
        if (data && JSON.stringify(data) !== JSON.stringify({})) {
            if (data.status.code === TC.ErrorCodes.SUCCESS) {

                // if the client is trade client
                if (data && data.data && data.data.length > 0 && data.data[0].resourceId) {
                    const vehicleSearchCriteria: VehicleSearchCriteria = new VehicleSearchCriteria();
                    vehicleSearchCriteria.resourceType = data.data[0].resourceType;
                    vehicleSearchCriteria.activeStatus = data.data[0].activeStatus;
                    vehicleSearchCriteria.resourceId = data.data[0].resourceId;
                    console.log(vehicleSearchCriteria);
                    let parameters = this.queryParamService.getQueryParamsArray(vehicleSearchCriteria);
                    this.rootService.setDataToLocalStorage(
                        TCO.AppData.VEHICLE_SEARCH_CRITERIA,
                        vehicleSearchCriteria,
                        true,
                        parameters
                    );
                }

                setTimeout(() => {

                    let journeyToProceed = 'BEGIN_RESOURCE_MANAGEMENT_AS_TRANSPORT';

                    if (journeyToProceed) {
                        this.userJourneyManager.goForKey(journeyToProceed);
                    }

                }, 100);
            } else {
                if (data.status.code === TC.ErrorCodes.ERROR && data.error) {
                }
            }
        }
    }

    private confirmDialog(dialog: any) {
        this.dialogService.confirm(dialog);
    }

    // country state city
    public onNewCountryStateCityObserver($event: InitialCountryStateCityCodes) {
        let wrapper = new CountryStateCityWrapper();
        this.countryStateCityChangeMap.set($event.id, wrapper);

        this.countryStateCityService.getInitialCity($event.countryCode, $event.stateCode, $event.cityCode)
            .then((value: City) => {
                if (value) {
                    wrapper.city = value;
                    this.changeDetectorService.notifyCountryStateCityChange();
                }
            });

        this.countryStateCityService.getInitialState($event.countryCode, $event.stateCode)
            .then((value: State) => {
                if (value) {
                    wrapper.state = value;
                    this.changeDetectorService.notifyCountryStateCityChange();
                }
            });

        this.countryStateCityService.getInitialCountry($event.countryCode)
            .then((value: Country) => {
                if (value) {
                    wrapper.country = value;
                    this.changeDetectorService.notifyCountryStateCityChange();
                }
            });
    }

    public onSelectCountry($event: CountryChange) {
        let wrapper = this.countryStateCityChangeMap.get($event.id);
        this.countryStateCityService.onSelectCountry($event.country, wrapper);
        this.changeDetectorService.notifyCountryStateCityChange();
    }

    public onSelectState($event: StateChange) {
        let wrapper = this.countryStateCityChangeMap.get($event.id);
        this.countryStateCityService.onSelectState($event.state, wrapper);
        this.changeDetectorService.notifyCountryStateCityChange();
    }

    public onSelectCity($event: CityChange) {
        let wrapper = this.countryStateCityChangeMap.get($event.id);
        this.countryStateCityService.onSelectCity($event.city, wrapper);
        this.changeDetectorService.notifyCountryStateCityChange();
    }

    public onCountryTextChange($event: CountryChange) {
        this.countryStateCityService.onCountryTextChange($event.text);
    }

    public onStateTextChange($event: StateChange) {
        let wrapper = this.countryStateCityChangeMap.get($event.id);
        this.countryStateCityService.onStateTextChange($event.text, wrapper);
    }

    public onCityTextChange($event: CityChange) {
        let wrapper = this.countryStateCityChangeMap.get($event.id);
        this.countryStateCityService.onCityTextChange($event.text, wrapper);
    }

    private subscribeCountryStateCityListChange() {
        this.countryStateCityService.countryListObserver.subscribe(
            value => {
                this.countryList = value;
            }
        );

        this.countryStateCityService.stateListObserver.subscribe(
            value => {
                this.stateList = value;
            }
        );

        this.countryStateCityService.cityListObserver.subscribe(
            value => {
                this.cityList = value;
            }
        );
    }

    // --country state city--//

    ngOnDestroy(): void {
        this.removeHeaderActions();
        this.localStorage.delete(LocalDataKey.unSavedVehicle);
    }

    public ngAfterViewInit(): void {
        let element = document.querySelector('.main-panel');
        if (element) {
            element.scrollTop = 0;
        }
    }

    private getUserDetails() {
        this.userProfile = this.dataStore.get(DataKey.userProfile).getValue();
    }

}
