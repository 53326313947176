import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CgCalendarModule } from '../../../../widgets/framework/cg-calendar';
// import { CgCalendarModule } from '@tc/cg-calendar';
// import { CgSeasonCalendarModule } from '@tc/cg-season-calendar';
import { CgSeasonCalendarModule } from '../../../../widgets/framework/cg-season-calendar';
import { SeasonSetupFocusViewComponent } from './season-setup-focus-view.component';


@NgModule({
  imports: [
    CommonModule,
    CgCalendarModule,
    CgSeasonCalendarModule
  ],
  declarations: [SeasonSetupFocusViewComponent],
  exports:[SeasonSetupFocusViewComponent]
})
export class SeasonSetupFocusViewModule { }
