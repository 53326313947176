import {Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {DialogModel, LEVEL} from '@tc-core/model/it/codegen/ui/framework/dialog-model';
import {ModalData} from '@tc-core/model/it/codegen/ui/framework/modal-data';
import {ConfigLoader} from '@tc-core/util/framework';
import {SpinnerService} from '@tc-core/util/ui/spinner.service';
import {DialogService} from '@tc/dialog/dialog.service';
import {FocusViewService} from '@tc/focus-view/focus-view.service';
import {GridApi, GridOptions, IGetRowsParams, RowNode} from 'ag-grid-community';
import {Observable, Subscription} from 'rxjs/index';
import {tap} from 'rxjs/operators';
import {SetupGridComp} from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid-comp';
import {SetupGridDataSource} from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid-data-source';
import {SetupGridComponent} from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid.component';
import {KebabActionRendererAction} from '../../../widgets/framework/ag-grid-custom-components/renderers/actions/actions.component';
import {TCO} from '../../../constants';
import {DocumentQueueSearchCriteria} from '../../../models/criteria/document-queue-search-criteria';
import {DocumentQueueGroup} from '../../../models/criteria/DocumentQueueGroup';
import {DispatchStatus} from '../../../models/document/dispatch-entry';
import {SortDirection} from '../../../models/helper/sort-direction';
import {DocumentQueueService} from '../../../services/backend-consumers/documents/document-queue.service';
import {DataHandlerService} from '../../../services/backend-consumers/master-data-handler-service/data-handler.service';
import {DataKey, DataStoreService} from '../../../services/util/framework/data-store.service';
import {SetupCommonDataProcessorService} from '../../../services/util/pre-processors/setup-common-data-processor.service';
import {ResponseUtil} from '../../../services/util/response/response-util.service';
import {DocumentQueueFocusViewComponent} from '../document-queue-focus-view/document-queue-focus-view.component';
import {DMCCommon} from '../../../services/util/common/dmc-common';
import {BackgroundTask} from '../../../models/background/background-task';
import {TcErrorType} from '@tc-core/model/it/codegen/tbx/ext/errors/tc-error';
import {BackgroundTaskService} from '../../../services/util/change-detector/background-task.service';
import {TaskStatus} from "../../../models/background/task-status";

@Component({
    selector: 'tc-document-queue-results',
    templateUrl: './document-queue-results.component.html'
})

export class DocumentQueueResultsComponent extends SetupGridComp implements OnInit, OnDestroy, SetupGridDataSource {

    constructor(
        private configLoader: ConfigLoader,
        private dataStoreService: DataStoreService,
        private dataHandlerService: DataHandlerService,
        private documentQueueService: DocumentQueueService,
        private activatedRoute: ActivatedRoute,
        private focusViewService: FocusViewService,
        private setupCommonDataProcessorService: SetupCommonDataProcessorService,
        private spinnerService: SpinnerService,
        private dialogService: DialogService,
        private common: DMCCommon,
        private backgroundTaskService: BackgroundTaskService
    ) {
        super();
    }

    colDefConfig = [];
    gridOptions: GridOptions;

    @ViewChild('setupGrid')
    private setupGrid: SetupGridComponent;

    documentQueueSearchCriteria: DocumentQueueSearchCriteria;

    // required options for grid editors
    // nothing
    gridApi: GridApi;
    gridActions: KebabActionRendererAction[] = [];
    documentQueueSearchResults: DocumentQueueGroup[] = [];
    enableBulkSendButton: true;

    private searchCriteriaObserver: Subscription = new Subscription();
    private focusViewDataChangeObserver: Subscription = new Subscription();
    private focusViewConfirmObserver: Subscription = new Subscription();
    private dialogSubscription: Subscription = new Subscription();
    private docQueueResultsSubscription: Subscription = new Subscription();
    private dialogSubscription1: Subscription = new Subscription();

    isFocusViewDataChanged = false;

    @ViewChild('statusCell') statusCell: TemplateRef<any>;
    @ViewChild('nameFormatCell') nameFormatCell: TemplateRef<any>;
    @ViewChild('formatCell') formatCell: TemplateRef<any>;
    @ViewChild('generatedByCell') generatedByCell: TemplateRef<any>;
    @ViewChild('checkBoxDisabledCell') checkBoxDisabledCell: TemplateRef<any>;

    ngOnInit() {
        this.colDefConfig = this.loadGridConfig();
        this.subscribeSearchCriteria();
        this.fillRequiredGridOptionData();
        this.addGridActions();
        this.refreshGrid();
    }

    /**
     * subscribe search criteria changes in search bar
     * then force grid to refresh data
     * grid refresh will be call getRaws method which is using this subscribed criteria for backend call
     */
    private loadGridConfig() {
        return this.configLoader.configurations.get(TCO.CONF.CONF_DOCUMENT_QUEUE_COL_DEF_CONFIG);
    }

    private fillRequiredGridOptionData() {
        // nothing
    }

    public isDisabledRow(params): boolean {
        const data = params.data;
        if (data) {
            let invalid = true;
            if (data.dispatchStatus !== DispatchStatus.SUCCESS
                && data.dispatchStatus !== DispatchStatus.GENERATED) {
                invalid = false;
            }
            return invalid;
        } else {
            return false;
        }
    }

    public isEditableRow(params): boolean {
        const data = params.data;
        if (data) {
            let invalid = true;
            if (data.dispatchStatus !== DispatchStatus.SUCCESS
                && data.dispatchStatus !== DispatchStatus.GENERATED) {
                invalid = false;
            }
            return !invalid;
        } else {
            return true;
        }
    }

    getSelectedRows(): any[] {
        const selectedNodes = [];
        if (this.setupGrid && this.setupGrid.gridApi) {
            this.setupGrid.gridApi.forEachNode((node: RowNode) => {
                if (node && node.data && node.data.selected) {
                    selectedNodes.push(node.data);
                }
            });
        }
        return selectedNodes;
    }

    public createNewRow(): any {
        return {unsaved: true};
    }

    public isUnsavedRow(row: any): boolean {
        return !!row.unsaved;
    }

    public deleteRow(row: any) {
        return null;
    }

    public saveRow(row: any, event: any) {
        return null;
    }

    // search criteria subscription
    subscribeSearchCriteria() {
        this.searchCriteriaObserver = this.dataStoreService.get(DataKey.documentQueueSearchCriteria)
            .subscribe(value => {
                this.documentQueueSearchCriteria = value;
                if (this.documentQueueSearchCriteria) {
                    this.setupGrid.runForceSearch();
                }
            });
    }

    public refreshGridView() {
        if (this.gridApi) {
            setTimeout(() => {
                this.gridApi.refreshCells({force: true});
                this.gridApi.setRowData(this.documentQueueSearchResults);
            }, 0);

        }
    }

    public refreshGrid() {
        this.documentQueueService.getTaskStatus().subscribe(taskStatus => {
            if (taskStatus && taskStatus.TaskStatus === TaskStatus.FINISHED) {
                this.setupGrid.runForceSearch();
                this.documentQueueService.clearTaskStatus();
            }
        });
    }

    // retrieve grid data
    public getRows(params: IGetRowsParams): Observable<any> {
        console.log('fetch requesting for ' + params.startRow + ' to ' + params.endRow);

        const pageSize = params.endRow - params.startRow;

        this.documentQueueSearchCriteria.start = params.startRow;
        this.documentQueueSearchCriteria.size = pageSize;
        if (params.sortModel && params.sortModel[0]) {
            this.documentQueueSearchCriteria.sortBy = params.sortModel[0].colId;
            if (params.sortModel[0].sort === SetupGridComp.GRID_SORT_ASCENDING) {
                this.documentQueueSearchCriteria.sortDirection = SortDirection.ASC;
            } else {
                this.documentQueueSearchCriteria.sortDirection = SortDirection.DESC;
            }
        }
        return this.documentQueueService.searchDocumentQueues(this.documentQueueSearchCriteria)
            .pipe(
                tap(
                    data => {
                        this.dataStoreService.set(DataKey.documentQueueSearchResultsForCriteria, data);
                        this.documentQueueSearchResults = ResponseUtil.getDataArray<DocumentQueueGroup>(data);
                        this.refreshGridView();
                    }
                )
            );
    }

    // view document queue focus view
    public onViewClick(row: any) {
        this.onViewDocumentQueueDetails(row.data);
    }

    public onDeleteClick(row: any) {
    }

    onViewDocumentQueueDetails(data: any) {
        this.focusViewDataObserver();
        const fabActions = [];
        const navItemsArray = this.configLoader.configurations.get(TCO.CONF.CONF_NAVIGATION);
        this.configLoader.configurations.get('FOCUS_VIEW_CONFIG').DOCUMENT_QUEUE_FOCUS_VIEW.fabAction
            .forEach(val => {
                for (const key in navItemsArray) {
                    if (val === navItemsArray[key].id) {
                        fabActions.push(navItemsArray[key]);
                    }
                }
            });

        const dataObject = new ModalData(
            LEVEL.SUCCESS, 'Document Queue Details', null, null, 'grid-focus-view',
            DocumentQueueFocusViewComponent,
            {
                documentQueue: data.documentQueueSummaries
            },
            {label: 'close'},
            '', '', fabActions, null,
            'Name : ' + data.supplierName + ' - ' +
            this.setupCommonDataProcessorService.dateValueConvertor(data.scheduledTime)
        );
        dataObject.disableClose = true;
        this.focusViewConfirmObserver = this.focusViewService.confirm(dataObject)
            .subscribe(
                res => {
                    if (this.isFocusViewDataChanged) {
                        // after view closed
                        this.setupGrid.runForceSearch();
                    }
                }
            );
    }

    // add grid actions
    private addGridActions() {
        this.gridActions.push(
            {
                kebabIcon: 'more_vert',
                kebabDisable: this.isDisableKebabClick.bind(this),
                kebabTooltip: 'more actions',
                kebabActions: [
                    // {
                    //     actionName: 'View',
                    //     icon: 'visibility',
                    //     action: this.onViewClick.bind(this),
                    //     disable: false,
                    //     tooltip: ''
                    // },
                    {
                        actionName: 'Send',
                        icon: 'send',
                        action: this.clickSendAction.bind(this),
                        disable: false,
                        tooltip: ''
                    }
                    // {
                    //     actionName: 'Delete',
                    //     icon: 'delete',
                    //     action: this.onDeleteClick.bind(this),
                    //     disable: false,
                    //     tooltip: ''
                    // }
                ]
            }
        );
    }

    // Send action on individual items
    clickSendAction = (params) => {
        if (this.backgroundTaskService.isTaskRunning()) {
            this.common.showSnackBar(
                'Already a background task is being in progress. Please wait for the completion or stop notifying the task status.',
                5000,
                TcErrorType.TYPE.ERROR
            );
            return;
        }
        const confirmSendManifests = new DialogModel(
            true,
            LEVEL.WARNING,
            'Send Manifests',
            'Do you want to send manifest for this item? Task may be handled in background.',
            true,
            2000,
            null,
            'Cancel',
            'Send',
            true
        );
        confirmSendManifests.disableClose = true;
        if (this.dialogSubscription) {
            this.dialogSubscription.unsubscribe();
        }
        const dialogSubscription = this.dialogService
            .confirm(confirmSendManifests)
            .subscribe((res) => {
                    this.spinnerService.show();
                    dialogSubscription.unsubscribe();
                    if (res === true) {
                        const documentQueueGroupList: DocumentQueueGroup[] = [];
                        const documentQueueGroup: DocumentQueueGroup = params.data;
                        if (documentQueueGroup) {
                            documentQueueGroupList.push(documentQueueGroup);
                            this.docQueueResultsSubscription = this.documentQueueService.patchDispatchEntry(
                                documentQueueGroupList)
                                .subscribe(response => {
                                        if (response && response.data && response.data[0] && response.data[0].taskStatus) {
                                            const backgroundTask: BackgroundTask = response.data[0];
                                            this.backgroundTaskService.startStatusChecking(backgroundTask);
                                            this.common.showSnackBar(
                                                // tslint:disable-next-line:max-line-length
                                                `Transfer manifest generation background task id: ${backgroundTask.taskId} is started. You will be notified on the generation status.`,
                                                5000,
                                                TcErrorType.TYPE.INFO
                                            );
                                        } else if (response && response.status && response.status.code === 1) {
                                            this.common.showSnackBar(
                                                // tslint:disable-next-line:max-line-length
                                                `Transfer manifest generation is successful.`,
                                                5000,
                                                TcErrorType.TYPE.INFO
                                            );
                                            this.setupGrid.runForceSearch();
                                        } else if (response && response.status && response.status.code === -1) {
                                            this.errorMessage();
                                            this.setupGrid.runForceSearch();
                                        }
                                        this.spinnerService.hide();
                                    },
                                    error => {
                                        this.errorMessage();
                                        this.setupGrid.runForceSearch();
                                        this.spinnerService.hide();
                                    }, () => {
                                    });
                        }
                        this.spinnerService.hide();
                    }
                    this.spinnerService.hide();
                }
            );
    };

    errorMessage() {
        this.common.showSnackBar(
            // tslint:disable-next-line:max-line-length
            `One or more transfer manifest email sending has been failed.`,
            5000,
            TcErrorType.TYPE.ERROR
        );
    }

    isDisableKebabClick = (params) => {
        return this.checkRowDisabled(params.data);
    };

    isDisableCheckBox = (params) => {
        return this.checkRowDisabled(params.data);
    };

    checkRowDisabled(data: any): boolean {
        const event = {
            data
        };
        return this.isDisabledRow(event);
    }

    focusViewDataObserver() {
        this.focusViewDataChangeObserver = this.dataStoreService.get(DataKey.docDetailsFocusViewDataChanged)
            .subscribe(isDataChanged => {
                if (isDataChanged) {
                    this.focusViewDataChangeObserver.unsubscribe();
                }
                this.isFocusViewDataChanged = isDataChanged;
            });
    }

    /*
     grid config methods which are called by grid setup (ag grid)
     and set by grid config
     */

    statusSuccess = (params) => {
        return params.data && params.data.dispatchStatus && params.data.dispatchStatus === DispatchStatus.SUCCESS;
    };

    statusPending = (params) => {
        return params.data && params.data.dispatchStatus && params.data.dispatchStatus === DispatchStatus.PENDING;
    };

    statusFailed = (params) => {
        return params.data && params.data.dispatchStatus && params.data.dispatchStatus === DispatchStatus.FAILED;
    };

    statusIgnored = (params) => {
        return params.data && params.data.dispatchStatus && params.data.dispatchStatus === DispatchStatus.IGNORED;
    };

    statusGenerated = (params) => {
        return params.data && params.data.dispatchStatus && params.data.dispatchStatus === DispatchStatus.GENERATED;
    };

    // Bulk send
    public onBulkSendClick(event: any) {
        if (this.backgroundTaskService.isTaskRunning()) {
            this.common.showSnackBar(
                'Already a background task is being in progress. Please wait for the completion or stop notifying the task status.',
                5000,
                TcErrorType.TYPE.ERROR
            );
            return;
        }
        // this.spinnerService.show();
        const results = this.getSelectedRows();
        let message = '';
        if (results && results.length > 0) {
            message = 'Do you want to send manifests for selected items? Task may be handled in background.';
        } else if (results && results.length === 0) {
            message = 'Do you want to send manifest for all the dispatch entries listed in the grid? Task may be handled in background.';
        }
        const confirmSendManifests = new DialogModel(
            true,
            LEVEL.WARNING,
            'Send Manifests',
            message,
            true,
            2000,
            null,
            'Cancel',
            'Send',
            true
        );
        confirmSendManifests.disableClose = true;
        if (this.dialogSubscription) {
            this.dialogSubscription.unsubscribe();
        }
        let nonSuccessEntry = false;
        if (this.documentQueueSearchResults) {
            this.documentQueueSearchResults.forEach(value => {
                if (value && value.dispatchStatus && value.dispatchStatus
                    !== DispatchStatus.SUCCESS && value.dispatchStatus !== DispatchStatus.GENERATED) {
                    nonSuccessEntry = true;
                    return;
                }
            });
        }

        if (nonSuccessEntry) {
            const dialogSubscription = this.dialogService
                .confirm(confirmSendManifests)
                .subscribe((res) => {
                        this.spinnerService.show();
                        dialogSubscription.unsubscribe();
                        if (res === true) {
                            if (results && results.length > 0) {
                                const documentQueueGroupList: DocumentQueueGroup[] = [];
                                results.forEach(item => {
                                    const documentQueueGroup: DocumentQueueGroup = item;
                                    documentQueueGroupList.push(documentQueueGroup);
                                });
                                this.docQueueResultsSubscription = this.documentQueueService.patchDispatchEntry(documentQueueGroupList)
                                    .subscribe(
                                        (response) => {
                                            if (response && response.data && response.data[0] && response.data[0].taskStatus) {
                                                const backgroundTask: BackgroundTask = response.data[0];
                                                this.backgroundTaskService.startStatusChecking(backgroundTask);
                                                this.common.showSnackBar(
                                                    // tslint:disable-next-line:max-line-length
                                                    `Transfer manifest generation background task id: ${backgroundTask.taskId} is started. You will be notified on the generation status.`,
                                                    5000,
                                                    TcErrorType.TYPE.INFO
                                                );
                                            } else if (response && response.status && response.status.code === 1) {
                                                this.common.showSnackBar(
                                                    // tslint:disable-next-line:max-line-length
                                                    `Transfer manifest generation is successful.`,
                                                    5000,
                                                    TcErrorType.TYPE.INFO
                                                );
                                                this.setupGrid.runForceSearch();
                                            } else if (response && response.status && response.status.code === -1) {
                                                this.common.showSnackBar(
                                                    // tslint:disable-next-line:max-line-length
                                                    `Transfer manifest generation failed.`,
                                                    5000,
                                                    TcErrorType.TYPE.ERROR
                                                );
                                                this.setupGrid.runForceSearch();
                                            }
                                            this.spinnerService.hide();
                                        },
                                        error => {
                                            this.errorMessage();
                                            this.setupGrid.runForceSearch();
                                            this.spinnerService.hide();
                                        }, () => {
                                        }
                                    );
                            } else if (results && results.length === 0 && this.documentQueueSearchResults) {
                                this.docQueueResultsSubscription = this.documentQueueService.patchDispatchEntry(this.documentQueueSearchResults)
                                    .subscribe(
                                        (response) => {
                                            if (response && response.data && response.data[0] && response.data[0].taskStatus) {
                                                const backgroundTask: BackgroundTask = response.data[0];
                                                this.backgroundTaskService.startStatusChecking(backgroundTask);
                                                this.common.showSnackBar(
                                                    // tslint:disable-next-line:max-line-length
                                                    `Transfer manifest generation background task id: ${backgroundTask.taskId} is started. You will be notified on the generation status.`,
                                                    5000,
                                                    TcErrorType.TYPE.INFO
                                                );
                                            } else if (response && response.status && response.status.code === 1) {
                                                this.common.showSnackBar(
                                                    // tslint:disable-next-line:max-line-length
                                                    `Transfer manifest generation is successful.`,
                                                    5000,
                                                    TcErrorType.TYPE.INFO
                                                );
                                                this.setupGrid.runForceSearch();
                                            } else if (response && response.status && response.status.code === -1) {
                                                this.errorMessage();
                                                this.setupGrid.runForceSearch();
                                            }
                                            this.spinnerService.hide();
                                            // this.refreshGrid();
                                        },
                                        error => {
                                            this.errorMessage();
                                            this.setupGrid.runForceSearch();
                                            this.spinnerService.hide();
                                        }, () => {
                                        }
                                    );
                            }
                        }
                        this.spinnerService.hide();
                    }
                );
        } else {
            const confirmUnableSendManifests = new DialogModel(
                true,
                LEVEL.WARNING,
                'Send Manifests',
                'Manifests cannot be generated for SUCCESS or GENERATED dispatch entries',
                true,
                2000,
                null,
                '',
                'Ok',
                true
            );
            confirmUnableSendManifests.disableClose = true;
            if (this.dialogSubscription) {
                this.dialogSubscription.unsubscribe();
            }
            const dialogSubscription = this.dialogService
                .confirm(confirmUnableSendManifests)
                .subscribe((res) => {
                    }
                );
        }
    }

    public ngOnDestroy(): void {
        if (this.searchCriteriaObserver) {
            this.searchCriteriaObserver.unsubscribe();
        }
        if (this.dialogSubscription) {
            this.dialogSubscription.unsubscribe();
        }
        if (this.focusViewConfirmObserver) {
            this.focusViewConfirmObserver.unsubscribe();
        }
        if (this.docQueueResultsSubscription) {
            this.docQueueResultsSubscription.unsubscribe();
        }
    }

    public isInvalidRow(params): boolean {
        return false;
    }
}

