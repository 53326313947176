/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./season-calendar-body/season-calendar-body.component.ngfactory";
import * as i2 from "./season-calendar-body/season-calendar-body.component";
import * as i3 from "./season-calendar-navigation/season-calendar-navigation.component.ngfactory";
import * as i4 from "./season-calendar-navigation/season-calendar-navigation.component";
import * as i5 from "@tc/dialog/dialog.service";
import * as i6 from "@angular/common";
import * as i7 from "./cg-season-calendar.component";
import * as i8 from "@tc/modal/modal.service";
import * as i9 from "./season-calendar.service";
var styles_CgSeasonCalendarComponent = [];
var RenderType_CgSeasonCalendarComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CgSeasonCalendarComponent, data: {} });
export { RenderType_CgSeasonCalendarComponent as RenderType_CgSeasonCalendarComponent };
function View_CgSeasonCalendarComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "tc-sc__container-right"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "tc-season-calendar-body", [["class", ""]], null, [[null, "selectDate"], [null, "menuItemClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectDate" === en)) {
        var pd_0 = (_co.onSelectDate($event) !== false);
        ad = (pd_0 && ad);
    } if (("menuItemClick" === en)) {
        var pd_1 = (_co.onMenuItemClick($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_SeasonCalendarBodyComponent_0, i1.RenderType_SeasonCalendarBodyComponent)), i0.ɵdid(2, 114688, null, 0, i2.SeasonCalendarBodyComponent, [], { fromYear: [0, "fromYear"], toYear: [1, "toYear"], rangeGroups: [2, "rangeGroups"], calendarRefreshEvent: [3, "calendarRefreshEvent"], calendarMenuItems: [4, "calendarMenuItems"], viewOnlyMode: [5, "viewOnlyMode"] }, { selectDate: "selectDate", menuItemClick: "menuItemClick" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.fromYear; var currVal_1 = _co.toYear; var currVal_2 = _co.rangeGroups; var currVal_3 = _co.calendarRefreshEvent; var currVal_4 = _co.calendarMenuItems; var currVal_5 = _co.viewOnlyMode; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
export function View_CgSeasonCalendarComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "tc-sc__container-main"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "tc-sc__container-left"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "tc-season-calendar-navigation", [], null, [[null, "selectSeason"], [null, "removeSeason"], [null, "selectDateRange"], [null, "removeDateRange"], [null, "setSeasons"], [null, "setSeasonRange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectSeason" === en)) {
        var pd_0 = (_co.onSelectSeason($event) !== false);
        ad = (pd_0 && ad);
    } if (("removeSeason" === en)) {
        var pd_1 = (_co.onRemoveSeason($event) !== false);
        ad = (pd_1 && ad);
    } if (("selectDateRange" === en)) {
        var pd_2 = (_co.onSelectDateRange($event) !== false);
        ad = (pd_2 && ad);
    } if (("removeDateRange" === en)) {
        var pd_3 = (_co.onRemoveDateRange($event) !== false);
        ad = (pd_3 && ad);
    } if (("setSeasons" === en)) {
        var pd_4 = (_co.onSetSeasons($event) !== false);
        ad = (pd_4 && ad);
    } if (("setSeasonRange" === en)) {
        var pd_5 = (_co.onSetSeasonRange($event) !== false);
        ad = (pd_5 && ad);
    } return ad; }, i3.View_SeasonCalendarNavigationComponent_0, i3.RenderType_SeasonCalendarNavigationComponent)), i0.ɵdid(3, 8503296, null, 0, i4.SeasonCalendarNavigationComponent, [i0.ChangeDetectorRef, i5.DialogService], { rangeGroups: [0, "rangeGroups"], viewOnlyMode: [1, "viewOnlyMode"] }, { selectSeason: "selectSeason", removeDateRange: "removeDateRange", selectDateRange: "selectDateRange", removeSeason: "removeSeason", setSeasons: "setSeasons", setSeasonRange: "setSeasonRange" }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CgSeasonCalendarComponent_1)), i0.ɵdid(5, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.rangeGroups; var currVal_1 = _co.viewOnlyMode; _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = _co.showCalendar; _ck(_v, 5, 0, currVal_2); }, null); }
export function View_CgSeasonCalendarComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-cg-season-calendar", [], null, null, null, View_CgSeasonCalendarComponent_0, RenderType_CgSeasonCalendarComponent)), i0.ɵdid(1, 114688, null, 0, i7.CgSeasonCalendarComponent, [i8.ModalService, i9.SeasonCalendarService, i5.DialogService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CgSeasonCalendarComponentNgFactory = i0.ɵccf("tc-cg-season-calendar", i7.CgSeasonCalendarComponent, View_CgSeasonCalendarComponent_Host_0, { fromYear: "fromYear", toYear: "toYear", rangeGroups: "rangeGroups", viewOnlyMode: "viewOnlyMode", mergeNearDateRanges: "mergeNearDateRanges", newRangeGroupNamePrefix: "newRangeGroupNamePrefix", showCalendar: "showCalendar" }, { onChangeSeasons: "onChangeSeasons" }, []);
export { CgSeasonCalendarComponentNgFactory as CgSeasonCalendarComponentNgFactory };
