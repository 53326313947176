import { ConfigLoader } from '@tc-core/util/framework';
import { DMCQueryParamsService } from '../../util/framework/dmc-query-params.service';
import { RequestService } from '../../util/framework/request.service';
import { UrlPaths } from '../url-paths';
import * as i0 from "@angular/core";
import * as i1 from "@tc-core/util/framework/config-loader.service";
import * as i2 from "../../util/framework/request.service";
import * as i3 from "../../util/framework/dmc-query-params.service";
var AccommodationService = /** @class */ (function () {
    function AccommodationService(configLoader, requestService, queryParamsService) {
        this.configLoader = configLoader;
        this.requestService = requestService;
        this.queryParamsService = queryParamsService;
    }
    /*********
     Room Types
     *********/
    AccommodationService.prototype.getRoomTypes = function (criteria) {
        var reqPrams = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.get(UrlPaths.OP_DATA_URL_KEY, [UrlPaths.OP_DATA_VERSION, UrlPaths.ROOM_TYPES], reqPrams);
    };
    AccommodationService.prototype.deleteRoomType = function (code) {
        return this.requestService.delete(UrlPaths.OP_DATA_URL_KEY, [UrlPaths.OP_DATA_VERSION, UrlPaths.ROOM_TYPES, code]);
    };
    AccommodationService.prototype.saveRoomType = function (row) {
        return this.requestService.post(UrlPaths.OP_DATA_URL_KEY, [UrlPaths.OP_DATA_VERSION, UrlPaths.ROOM_TYPES], row);
    };
    AccommodationService.prototype.updateRoomType = function (code, row) {
        return this.requestService.put(UrlPaths.OP_DATA_URL_KEY, [UrlPaths.OP_DATA_VERSION, UrlPaths.ROOM_TYPES, code], row);
    };
    /***************
     Room Facilities
     ***************/
    AccommodationService.prototype.getRoomFacilities = function (criteria) {
        var reqPrams = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.get(UrlPaths.OP_DATA_URL_KEY, [UrlPaths.OP_DATA_VERSION, UrlPaths.ROOM_FACILITIES], reqPrams);
    };
    AccommodationService.prototype.deleteRoomFacility = function (id) {
        return this.requestService.delete(UrlPaths.OP_DATA_URL_KEY, [UrlPaths.OP_DATA_VERSION, UrlPaths.ROOM_FACILITIES, id]);
    };
    AccommodationService.prototype.saveRoomFacility = function (row) {
        return this.requestService.post(UrlPaths.OP_DATA_URL_KEY, [UrlPaths.OP_DATA_VERSION, UrlPaths.ROOM_FACILITIES], row);
    };
    AccommodationService.prototype.updateRoomFacility = function (id, row) {
        return this.requestService.put(UrlPaths.OP_DATA_URL_KEY, [UrlPaths.OP_DATA_VERSION, UrlPaths.ROOM_FACILITIES, id], row);
    };
    AccommodationService.ngInjectableDef = i0.defineInjectable({ factory: function AccommodationService_Factory() { return new AccommodationService(i0.inject(i1.ConfigLoader), i0.inject(i2.RequestService), i0.inject(i3.DMCQueryParamsService)); }, token: AccommodationService, providedIn: "root" });
    return AccommodationService;
}());
export { AccommodationService };
