import { ResponseUtil } from '../../../../../services/util/response/response-util.service';

export abstract class SetupGridComp {

    static GRID_SORT_ASCENDING = 'asc';
    static GRID_SORT_DECENDING = 'desc';

    abstract isInvalidRow(params): boolean;

    abstract isUnsavedRow(row: any): boolean;

    abstract createNewRow(): any;

    public getDataFromResponse(response: any): any[] {
        return ResponseUtil.getDataArray(response);
    }

    public getTotalCountFromResponse<T>(response: any): number {
        return ResponseUtil.getTotalCount(response);
    }

}
