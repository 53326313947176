import { AfterViewInit, Directive, Input, OnInit, ViewContainerRef } from '@angular/core';
import * as moment from 'moment';
import { Moment } from 'moment';

@Directive({
  selector: '[cgCalMonthHeader]'
})
export class MonthHeaderDirective implements OnInit, AfterViewInit {

  @Input() startAt: string = '';

  monthName: string = '';
  momentOfStarting: Moment;

  constructor(
    private viewContainer: ViewContainerRef
  ) {
  }

  ngOnInit(): void {
    this.momentOfStarting = moment(this.startAt);
    this.monthName = moment.months()[this.momentOfStarting.month()];
  }

  ngAfterViewInit(): void {
    let header: HTMLElement = this.viewContainer.element.nativeElement.querySelector('.mat-calendar-header');
    let headerControls = header.querySelector('.mat-calendar-controls');
    headerControls.removeChild(headerControls.querySelector('.mat-calendar-period-button')); // arrow <
    headerControls.removeChild(headerControls.querySelector('.mat-calendar-previous-button')); // arrow <
    headerControls.removeChild(headerControls.querySelector('.mat-calendar-next-button')); // arrow >

    let monthNameDiv = document.createElement('div');
    monthNameDiv.innerHTML = this.monthName;
    monthNameDiv.classList.add('tc-sc-month-view__header');
    headerControls.appendChild(monthNameDiv);


    // remove month name inside month
    let monthTables: NodeListOf<HTMLElement> =
      this.viewContainer.element.nativeElement.querySelectorAll('.mat-calendar-body');
    for (let monthTableKey in monthTables) {
      let monthTable: HTMLElement = monthTables[monthTableKey];
      if (monthTable && monthTable.children && monthTable.children[0] && monthTable.children[0].children) {
        if (monthTable.children[0].children.length === 1) {
          monthTable.removeChild(monthTable.children[0]);
        }else if (monthTable.children[0].children.length > 1) {
          monthTable.children[0].children[0].innerHTML = '';
        }
      }
    }
  }

}
