import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AssignmentOperationCriteria } from '../../../models/criteria/assignment-operation-criteria';
import { Criteria } from '../../../models/criteria/criteria';
import { CriteriaSearchCriteria } from '../../../models/criteria/criteria-search-criteria';
import { Assignment } from '../../../models/reservation/assignment';
import { AssignmentStatus } from '../../../models/reservation/assignment-status';
import { OPResponseWrapper } from '../../../models/response/op-response-wrapper';
import { DMCQueryParamsService } from '../../util/framework/dmc-query-params.service';
import { RequestService } from '../../util/framework/request.service';
import { UrlPaths } from '../url-paths';

@Injectable()
export class ReservationManagementService {

    constructor(
        private queryParamsService: DMCQueryParamsService,
        private requestService: RequestService
    ) {
    }

    saveSearchCriteria(criteria: Criteria): Observable<OPResponseWrapper<any>> {
        let criteriaSaveCriteria = new CriteriaSearchCriteria();
        criteriaSaveCriteria.userId = criteria.userId;
        criteriaSaveCriteria.criteriaName = criteria.name;
        let params = this.queryParamsService.asQueryParams(criteriaSaveCriteria);
        return this.requestService.post<OPResponseWrapper<any>>(
            UrlPaths.OP_RES_URL_KEY,
            [UrlPaths.OP_RES_VERSION, UrlPaths.CRITERIA],
            criteria.criteria,
            params
        );
    }

    getUserSearchCriteriaList(userId: number): Observable<OPResponseWrapper<Criteria>> {
        let criteria = new CriteriaSearchCriteria();
        criteria.userId = userId;
        let params = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.get<OPResponseWrapper<Criteria>>(
            UrlPaths.OP_RES_URL_KEY,
            [UrlPaths.OP_RES_VERSION, UrlPaths.CRITERIA],
            params
        );
    }

    public deleteCriteria(criteriaId: number): Observable<OPResponseWrapper<any>> {
        return this.requestService.delete<OPResponseWrapper<any>>(
            UrlPaths.OP_RES_URL_KEY,
            [UrlPaths.OP_RES_VERSION, UrlPaths.CRITERIA, criteriaId.toString()]
        );
    }

    public confirmAssignments(assignmentIds: number[]): Observable<OPResponseWrapper<Assignment>> {
        let criteria = new AssignmentOperationCriteria();
        criteria.status = AssignmentStatus.DMC_CONFIRM;
        let params = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.patch<OPResponseWrapper<Assignment>>(
            UrlPaths.OP_RES_URL_KEY,
            [UrlPaths.OP_RES_VERSION, UrlPaths.ASSIGNMENT],
            assignmentIds,
            params
        );
    }
    public onSupplierRejectAssignments(assignmentIds: number[]): Observable<OPResponseWrapper<Assignment>> {
        let criteria = new AssignmentOperationCriteria();
        criteria.status = AssignmentStatus.SUPPLIER_REJECTED;
        let params = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.patch<OPResponseWrapper<Assignment>>(
            UrlPaths.OP_RES_URL_KEY,
            [UrlPaths.OP_RES_VERSION, UrlPaths.ASSIGNMENT],
            assignmentIds,
            params
        );
    }

}
