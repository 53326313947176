import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { TcErrorType } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-error';
import { DialogModel } from '@tc-core/model/it/codegen/ui/framework/dialog-model';
import { LEVEL } from '@tc-core/model/it/codegen/ui/framework/modal-data';
import { DialogService } from '@tc/dialog/dialog.service';
import { SplitByPassengerGroupCriteria } from '../../../models/criteria/split-by-passenger-group-criteria';
import { ServiceItem } from '../../../models/reservation/service-item';
import { S_PassengerGroup, SplitPassengerData } from '../../../models/reservation/split-passenger-data';
import { TrsReservationManagementService } from '../../../services/backend-consumers/reservation-management-services/trs-reservation-management.service';
import { DMCCommon } from '../../../services/util/common/dmc-common';
import { DataKey, DataStoreService } from '../../../services/util/framework/data-store.service';
import { ResponseUtil } from '../../../services/util/response/response-util.service';
var SplitPassengersViewWrapperComponent = /** @class */ (function () {
    function SplitPassengersViewWrapperComponent(dataStore, reservationService, dialogService, dataStoreService, common) {
        this.dataStore = dataStore;
        this.reservationService = reservationService;
        this.dialogService = dialogService;
        this.dataStoreService = dataStoreService;
        this.common = common;
        this.passengerData = [];
        this.selectedGroupPassengerData = [];
        this.passengerGroups = [];
        this.isRequestCompleted = true;
        this.passengersPerGroup = 0;
        this.groupingOptionList = [];
    }
    SplitPassengersViewWrapperComponent.prototype.ngOnInit = function () {
        console.log(this.serviceItem);
        this.transferMode = this.serviceItem.trsServiceItem.transferMode;
        this.setPassengerGroups();
        this.generatePassengerData();
        this.getSearchCriteria();
    };
    SplitPassengersViewWrapperComponent.prototype.getSearchCriteria = function () {
        var _this = this;
        var x = this.dataStoreService.get(DataKey.reservationSelectedGroupCriteria).subscribe(function (data) {
            if (data !== null) {
                _this.searchCriteria = data;
            }
        });
    };
    SplitPassengersViewWrapperComponent.prototype.onClosePopup = function ($event) {
        this.retrieveSelectedGroup(false);
        this.dataStore.set(DataKey.popupClose, true);
    };
    SplitPassengersViewWrapperComponent.prototype.generatePassengerData = function () {
        var _this = this;
        this.passengerDataHeadings = [
            { name: 'Passenger Name' },
            { name: 'Type' },
            { name: 'Group' }
        ];
        this.passengers = this.serviceItem.servicePassengers;
        if (this.passengers && this.passengers.length) {
            this.passengers.forEach(function (passenger) {
                var data = new SplitPassengerData();
                data.id = passenger.passengerId;
                data.name = passenger.firstName + ' ' + passenger.lastName;
                data.type = _this.getPassengerType(passenger.type);
                data.needAssistance = false;
                data.group = new S_PassengerGroup();
                _this.passengerData.push(data);
            });
        }
        this.selectedGroupPassengerData = this.passengerData;
    };
    SplitPassengersViewWrapperComponent.prototype.getPassengerType = function (type) {
        switch (type) {
            case 'A':
                return 'Adult';
            case 'C':
                return 'Child';
            case 'I':
                return 'Infant';
            default:
                return '';
        }
    };
    SplitPassengersViewWrapperComponent.prototype.selectGroup = function ($event) {
        var passenger = $event.passenger;
        var groupCode = $event.group;
        var group = this.passengerGroups.find(function (g) { return g.code === groupCode; });
        passenger.group = group;
        if (this.getGroupAllocationCount(groupCode) > this.passengersPerGroup) {
            var confirmSuccess = new DialogModel(true, LEVEL.WARNING, 'Pax size exceeded', "You have allocated more passengers into " + group.name + " than specified", true, 2000);
            this.dialogService
                .confirm(confirmSuccess)
                .subscribe(function (res) {
                if (res === true) {
                }
            });
        }
        console.log(passenger);
    };
    SplitPassengersViewWrapperComponent.prototype.onSplit = function ($event) {
        var _this = this;
        var paxPerGroup = $event['paxPerGroup'];
        this.passengersPerGroup = paxPerGroup;
        if (typeof (paxPerGroup) == 'number' && paxPerGroup > 0 && this.passengers.length > paxPerGroup) {
            var serviceItemId = this.serviceItem.serviceItemId;
            var splitByPassengerGroupCriteria = new SplitByPassengerGroupCriteria();
            splitByPassengerGroupCriteria.paxPerGroup = paxPerGroup;
            this.reservationService.splitServiceItem(serviceItemId, splitByPassengerGroupCriteria)
                .subscribe(function (data) {
                var dataArray = ResponseUtil.getDataArray(data);
                _this.splitPassengerGroups = SplitPassengersViewWrapperComponent.toMap(dataArray);
                _this.setPassengerGroups();
                console.log(_this.passengerData);
                console.log(_this.passengerGroups);
            });
        }
        else {
            var confirmSuccess = new DialogModel(true, LEVEL.WARNING, 'Invalid Pax count', 'Pax per group should be lower than total passengers', true, 2000);
            this.dialogService
                .confirm(confirmSuccess)
                .subscribe(function (res) {
                if (res === true) {
                }
            });
        }
    };
    SplitPassengersViewWrapperComponent.toMap = function (passengerGroups) {
        var e_1, _a;
        var map = {};
        try {
            for (var passengerGroups_1 = tslib_1.__values(passengerGroups), passengerGroups_1_1 = passengerGroups_1.next(); !passengerGroups_1_1.done; passengerGroups_1_1 = passengerGroups_1.next()) {
                var passengerGroup = passengerGroups_1_1.value;
                map[passengerGroup.groupId] = passengerGroup.passengers;
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (passengerGroups_1_1 && !passengerGroups_1_1.done && (_a = passengerGroups_1.return)) _a.call(passengerGroups_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return map;
    };
    SplitPassengersViewWrapperComponent.prototype.setPassengerGroups = function () {
        var _this = this;
        var groupNamePostFix = 1;
        this.passengerGroups = [];
        this.groupingOptionList = [];
        this.groupingOptionList.push({ 'code': -1, 'value': 'All Groups', 'isSelected': true });
        if (this.splitPassengerGroups) {
            var _loop_1 = function (key) {
                var group = new S_PassengerGroup();
                group.code = Number(key);
                group.name = 'Group ' + group.code;
                this_1.groupingOptionList.push({ 'code': group.code, 'value': group.name, 'isSelected': false });
                this_1.passengerGroups.push(group);
                var itemPassengers = this_1.splitPassengerGroups[group.code];
                itemPassengers.forEach(function (passenger) {
                    var found = _this.passengerData.find(function (p) { return p.id == passenger.passengerId; });
                    if (found) {
                        // group is used. just to debug
                        found.group.code = group.code;
                        found.group.name = group.name;
                        found.assignedGroup = group.code;
                    }
                });
            };
            var this_1 = this;
            for (var key in this.splitPassengerGroups) {
                _loop_1(key);
            }
        }
    };
    SplitPassengersViewWrapperComponent.prototype.retrieveSelectedGroup = function (isChanged) {
        this.dataStoreService.set(DataKey.popupDataChanged, isChanged, true);
        /*this.reservationManagementService.retrieveServiceItems(this.searchCriteria).subscribe(data => {
         this.dataStoreService.set(DataKey.popupDataChanged, true, true);
         });*/
    };
    SplitPassengersViewWrapperComponent.prototype.onSave = function ($event) {
        var _this = this;
        var confirmSaveSplit = new DialogModel(true, LEVEL.WARNING, 'Save Changes', 'Do you want to save the split items', true, 2000, null, 'No', 'Yes');
        confirmSaveSplit.disableClose = true;
        this.dialogService
            .confirm(confirmSaveSplit)
            .subscribe(function (res) {
            if (res === true) {
                _this.isRequestCompleted = false;
                _this.allocatePassengersIntoServiceItems();
                console.log(_this.splitPassengerGroups);
                var serviceItemId = _this.serviceItem.serviceItemId;
                _this.reservationService.saveSplitServiceItems(serviceItemId, _this.splitPassengerGroups).subscribe(function (result) {
                    _this.isRequestCompleted = true;
                    //fetch again the selected serviceItem group;
                    _this.retrieveSelectedGroup(true);
                    _this.common.showSnackBar('Successfully saved split items', 3000, TcErrorType.TYPE.INFO);
                    _this.dataStore.set(DataKey.popupClose, true);
                    // let confirmSuccess = new DialogModel(
                    //     true,
                    //     LEVEL.SUCCESS,
                    //     'Success',
                    //     'Successfully saved split items',
                    //     true,
                    //     2000
                    // );
                    // this.dialogService
                    //     .confirm(confirmSuccess)
                    //     .subscribe((res) => {
                    //         if (res === true) {
                    //             console.log('true');
                    //             /* this.dataStore.set(DataKey.cart, null, true);
                    //              this.spinnerService.show();
                    //              */
                    //             this.dataStore.set(DataKey.popupClose, true);
                    //
                    //         }
                    //     });
                }, function (error) {
                    _this.isRequestCompleted = true;
                    console.log(error);
                    var confirmError = new DialogModel(true, LEVEL.ERROR, 'Failed', 'Failed to save split items', true, 2000);
                    _this.dialogService
                        .confirm(confirmError)
                        .subscribe(function (res) {
                        if (res === true) {
                            console.log('true');
                            /* this.dataStore.set(DataKey.cart, null, true);
                             this.spinnerService.show();
                             */
                        }
                    });
                });
                // this.dataStore.set(DataKey.popupClose, true);
            }
        });
    };
    SplitPassengersViewWrapperComponent.prototype.allocatePassengersIntoServiceItems = function () {
        var _this = this;
        for (var key in this.splitPassengerGroups) {
            this.splitPassengerGroups[Number(key)] = [];
        }
        this.passengerData.forEach(function (splitPassenger) {
            var passengerRawData = _this.passengers.find(function (p) { return p.passengerId === splitPassenger.id; });
            _this.splitPassengerGroups[splitPassenger.assignedGroup].push(passengerRawData);
        });
    };
    SplitPassengersViewWrapperComponent.prototype.getGroupAllocationCount = function (groupCode) {
        this.allocatePassengersIntoServiceItems();
        var count = 0;
        this.passengerData.forEach(function (passenger) {
            if (passenger.assignedGroup == groupCode) {
                count++;
            }
        });
        return count;
    };
    SplitPassengersViewWrapperComponent.prototype.onSelectGroupingType = function ($event) {
        if ($event === -1) {
            this.selectedGroupPassengerData = this.passengerData;
        }
        else {
            this.selectedGroupPassengerData = this.passengerData.filter(function (value) {
                return value.assignedGroup === $event;
            });
        }
    };
    return SplitPassengersViewWrapperComponent;
}());
export { SplitPassengersViewWrapperComponent };
