import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AutoCompleteEditorComponent } from '../../../../widgets/framework/ag-grid-custom-components/editors/auto-complete-editor/auto-complete-editor.component';
import { DropdownEditorComponent } from '../../../../widgets/framework/ag-grid-custom-components/editors/dropdown-editor/dropdown-editor.component';
import { ActionsComponent } from '../../../../widgets/framework/ag-grid-custom-components/renderers/actions/actions.component';
import { Booking } from '../../../../models/reservation-v2/booking';
import { NumericEditorComponent } from '../../../../widgets/framework/ag-grid-custom-components/renderers/numeric-editor/numeric-editor.component';
var BookingPassengersComponent = /** @class */ (function () {
    function BookingPassengersComponent(translateService) {
        var _this = this;
        this.translateService = translateService;
        this.readonly = false;
        this.titles = [];
        this.passengerTypes = [];
        this.deletePassenger = new EventEmitter();
        this.changeValidity = new EventEmitter();
        this.overlayNoRowsTemplate = "<span class=\"ag-overlay-no-rows-center\">No Passengers</span>";
        this.defaultColDef = {
            headerValueGetter: function (parameters) {
                var headerIdentifier = parameters.colDef.headerName;
                if (headerIdentifier) {
                    return _this.translateService.instant(headerIdentifier);
                }
                return '';
            }
        };
        this.frameworkComponents = {
            dropDownEditor: DropdownEditorComponent,
            autoCompleteEditor: AutoCompleteEditorComponent,
            numericEditor: NumericEditorComponent,
        };
        this.PASSENGER_TYPES = [
            { name: 'Adult', code: 'A' },
            { name: 'Child', code: 'C' },
            { name: 'Teen', code: 'T' },
            { name: 'Infant', code: 'I' }
        ];
        this.translateService.onLangChange.subscribe(function () {
            _this.gridApi.refreshHeader();
        });
    }
    BookingPassengersComponent.prototype.ngOnInit = function () {
        this.setColDefs();
        this.onValueChange(null);
    };
    BookingPassengersComponent.prototype.setColDefs = function () {
        this.columnDefs = [
            {
                headerName: 'Title',
                field: 'title',
                maxWidth: 100,
                cellEditorFramework: this.frameworkComponents.autoCompleteEditor,
                editable: !this.readonly,
                cellEditorParams: {
                    rowObject: 'title',
                    returnObject: true,
                    viewKey: 'name',
                    valueKey: 'code',
                    filterItemFn: this.getTitles.bind(this)
                },
                valueSetter: this.onSetTitle
            },
            {
                headerName: 'First Name',
                field: 'firstName',
                flex: 1,
                editable: !this.readonly
            },
            {
                headerName: 'Middle Name',
                field: 'middleName',
                hide: true,
                flex: 1,
                editable: !this.readonly
            },
            {
                headerName: 'Last Name',
                flex: 1,
                editable: !this.readonly,
                field: 'lastName'
            },
            {
                headerName: 'Type',
                field: 'typeName',
                flex: 1,
                editable: !this.readonly,
                cellEditorFramework: this.frameworkComponents.dropDownEditor,
                cellEditorParams: {
                    rowObject: 'typeName',
                    returnObject: true,
                    options: this.PASSENGER_TYPES
                },
                valueSetter: this.onSetType
            },
            {
                headerName: 'Email',
                field: 'email',
                flex: 1,
                editable: !this.readonly
            },
            {
                headerName: 'Contact No.',
                field: 'telephone',
                flex: 1,
                cellEditor: 'numericEditor',
                editable: !this.readonly
            },
            {
                headerName: ' ',
                field: 'actions',
                width: 65,
                cellRendererFramework: ActionsComponent,
                headerClass: 'actions-header',
                cellRendererParams: {
                    actions: [
                        {
                            icon: 'delete',
                            action: this.onDeleteClick.bind(this),
                            tooltip: 'Delete'
                        }
                    ]
                },
                filter: false,
                resizable: false,
                pinned: 'right',
                cellClass: ['actions-column'],
                suppressSizeToFit: true
            }
        ];
    };
    BookingPassengersComponent.prototype.onGridReady = function (params) {
        this.gridApi = params.api;
        this.gridApi.sizeColumnsToFit();
    };
    BookingPassengersComponent.prototype.onValueChange = function (event) {
        var e_1, _a;
        var allValid = true;
        try {
            for (var _b = tslib_1.__values(this.booking.bookingPassengers), _c = _b.next(); !_c.done; _c = _b.next()) {
                var bookingPassenger = _c.value;
                if (!(bookingPassenger.firstName && bookingPassenger.lastName && bookingPassenger.title && bookingPassenger.type)) {
                    allValid = false;
                    break;
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        this.changeValidity.emit(allValid);
    };
    BookingPassengersComponent.prototype.getTitles = function (params, row, text) {
        if (text) {
            return this.titles.filter(function (t) { return t.name.toLowerCase().includes(text.toLowerCase()); }).slice(0, 10);
        }
        else {
            return this.titles;
        }
    };
    BookingPassengersComponent.prototype.onDeleteClick = function (params) {
        if (!this.readonly) {
            this.deletePassenger.emit(params.data);
        }
    };
    BookingPassengersComponent.prototype.onSetTitle = function (params) {
        var title = params.newValue;
        if (title && title.code) {
            var passenger = params.data;
            passenger.title = title.code;
        }
    };
    BookingPassengersComponent.prototype.onSetType = function (params) {
        var type = params.newValue;
        if (type) {
            var passenger = params.data;
            passenger.typeName = type.name;
            passenger.type = type.code;
        }
    };
    BookingPassengersComponent.prototype.updateGrid = function () {
        var _this = this;
        if (this.gridApi) {
            this.gridApi.setRowData(this.booking.bookingPassengers);
        }
        else {
            setTimeout(function () { return _this.updateGrid(); }, 0);
        }
    };
    return BookingPassengersComponent;
}());
export { BookingPassengersComponent };
