<!--<mat-form-field>-->
<!--    <mat-label>Choose a date</mat-label>-->
<!--    <input matInput [matDatepicker]="picker" [(ngModel)]="selectedDate">-->
<!--    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>-->
<!--    <mat-datepicker #picker></mat-datepicker>-->
<!--</mat-form-field>-->

<input [ngxTimepicker]="picker">
<ngx-material-timepicker #picker></ngx-material-timepicker>

