import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatExpansionModule, MatInputModule, MatMenuModule, MatSelectModule} from '@angular/material';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {PipesModule} from '@tc/pipes';
import {ExpandedContentCardBodyModule} from '@tc/expanded-content-card-body';
import {ContentStripModule} from '@tc/content-strip';
import {TaxSchemeEditCardComponent} from './tax-scheme-edit-card.component';
import {TaxSchemeSummaryCardModule} from '../tax-scheme-summary-card/tax-scheme-summary-card.module';
import {TaxSchemesModule} from '../tax-schemes/tax-schemes.module';

@NgModule({
  imports: [
    CommonModule,
      MatInputModule,
      ReactiveFormsModule,
      TranslateModule.forChild(),
      MatSelectModule,
      FormsModule,
      MatMenuModule,
      PipesModule,
      ExpandedContentCardBodyModule,
      MatExpansionModule,
      ContentStripModule
  ],
  declarations: [TaxSchemeEditCardComponent],
    exports: [TaxSchemeEditCardComponent]
})
export class TaxSchemeEditCardModule { }
