import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SetupGridModule } from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid.module';
import { LedgerEntrySearchResultsComponent } from './ledger-entry-search-results.component';

@NgModule({
  declarations: [LedgerEntrySearchResultsComponent],
  imports: [
    CommonModule,
    SetupGridModule
  ],
  exports: [LedgerEntrySearchResultsComponent],
  entryComponents: [LedgerEntrySearchResultsComponent]
})
export class LedgerEntrySearchResultsModule { }
