import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LEVEL } from '@tc-core/model/it/codegen/ui/framework/dialog-model';
import { ModalData } from '@tc-core/model/it/codegen/ui/framework/modal-data';
import { ModalService } from '@tc/modal/modal.service';
import { Language } from '../../../../models/resource/generic-resource';
import { Resource } from '../../../../models/resource/resource';
import { ChangeDetectSupporter } from '../../../../models/supplier/change-detect-supporter';
import { ProfileManagementService } from '../../../../services/backend-consumers/supplier-service/profile-management.service';
import { DataKey, DataStoreService } from '../../../../services/util/framework/data-store.service';
import { RegXPatterns } from '../../../../widgets/shared/regx-patterns/regx-patterns';
import { AddMoreLangDialogComponent } from '../../shared/add-more-lang-dialog/add-more-lang-dialog.component';

@Component({
    selector: 'tc-guide-specific',
    templateUrl: './guide-specific.component.html'
})
export class GuideSpecificComponent implements OnInit, OnDestroy {

    @Input() resource: Resource;
    @Input() changeDetectSupporter: ChangeDetectSupporter;

    @Output() changeFormValidity: EventEmitter<boolean> = new EventEmitter();

    defaultLanguages = [];
    addedLanguages = [];
    languages: string[] = [];

    selectedFromAddedLanguages = [];

    guideSpecificForm: FormGroup;

    constructor(
        private profileManagementService: ProfileManagementService,
        private dataStore: DataStoreService,
        private fb: FormBuilder,
        private modalService: ModalService
    ) {}

    ngOnInit() {
        this.changeDetectSupporter.checkedSpecific = true;

        this.createForm();

        this.dataStore.set(DataKey.languages, null, true);
        this.getLanguages();

        this.guideSpecificForm.valueChanges.subscribe(
            (data) => {
                if (JSON.stringify(data) !== JSON.stringify({})) {
                    this.resource.genericResource.yearsOfExperience = data.years;
                    this.resource.genericResource.licenseNum = data.licenseNumber;
                    this.resource.genericResource.passportNum = data.passportNumber;
                    this.resource.genericResource.workingHours = data.workingHours;

                    this.changeFormValidity.emit(this.guideSpecificForm.valid);

                }
            }
        );

        this.profileManagementService.retrieveDefaultLanguages().subscribe(result => {
            this.defaultLanguages = result['data'];

            // adding languages to form
            this.setLanguagesFromResource();
        });


        this.validateForm();
    }

    public validateForm() {
        Object.keys(this.guideSpecificForm.controls).forEach(field => {
            const control = this.guideSpecificForm.get(field);
            control.markAsTouched({onlySelf: true});
        });
    }

    private createForm() {
        this.guideSpecificForm = this.fb.group({
            years: [this.resource.genericResource ? this.resource.genericResource.yearsOfExperience : '',[Validators.pattern(RegXPatterns.POSITIVE_NUMBERS_3DIGIT)]],
            licenseNumber: [this.resource.genericResource ? this.resource.genericResource.licenseNum : ''],
            passportNumber: [this.resource.genericResource ? this.resource.genericResource.passportNum : ''],
            workingHours: [
                this.resource.genericResource ? this.resource.genericResource.workingHours : '',
                Validators.required
            ]
        });

        this.changeFormValidity.emit(this.guideSpecificForm.valid);
    }

    private getLanguages() {
        // subscription for adding languages from popup
        this.dataStore.get(DataKey.languages).subscribe(data => {
            if (data != null) {
                this.addedLanguages = data;
                this.addedLanguages.forEach(lang => {
                        if (!this.languages.includes(lang)) {
                            this.languages.push(lang);
                            if (!this.selectedFromAddedLanguages.includes(lang)) {
                                this.selectedFromAddedLanguages.push(lang);
                            }
                        }
                    }
                );
                this.languages.forEach(
                    lang => {
                        if (!this.addedLanguages.includes(lang) && !this.findLang(lang, this.defaultLanguages)) {
                            this.languages.splice(this.languages.indexOf(lang), 1);
                        }
                    }
                );

                this.updateLanguagesInResource();
            }
        });
    }

    setLanguagesFromResource() {
        this.languages = [];
        this.addedLanguages = [];
        this.selectedFromAddedLanguages = [];
        if (this.resource && this.resource.genericResource && this.resource.genericResource.languages) {
            this.resource.genericResource.languages.forEach(value => {
                this.languages.push(value.language);
                if (!this.addedLanguages.includes(value.language) && !this.findLang(value.language,this.defaultLanguages)) {
                    this.addedLanguages.push(value.language);
                    this.selectedFromAddedLanguages.push(value.language);
                }
            });
        }
    }

    findLang(lang: string, arr: any[]): any {
        return arr.find(value => value.name === lang);
    }

    toggleValue(language: string) {
        if (!this.languages.includes(language)) {
            this.languages.push(language);
            console.log(this.languages);
        } else {
            this.languages.splice(this.languages.indexOf(language), 1);
            if (this.selectedFromAddedLanguages.includes(language)) {
                this.selectedFromAddedLanguages.splice(this.selectedFromAddedLanguages.indexOf(language), 1);
            }

        }
        this.updateLanguagesInResource();
        this.changeFormValidity.emit(this.guideSpecificForm.valid);

    }

    public openDialog(): void {

        let dataObject = new ModalData(LEVEL.SUCCESS, 'Add More Languages',

            {'label': 'OK'},
            {'label': 'Close'},
            null,
            AddMoreLangDialogComponent,
            {'selectedLanguages': this.selectedFromAddedLanguages}
        );

        this.confirmModal(dataObject);

    }

    private confirmModal(data: any): void {
        this.modalService.confirm(data).subscribe((res) => {
            console.log(res);
            this.changeFormValidity.emit(this.guideSpecificForm.valid);
        });
    }

    private updateLanguagesInResource() {
        if (this.resource.genericResource.languages) {
            this.resource.genericResource.languages.forEach((lang: Language) => {
                if (!this.languages.includes(lang.language)) {
                    this.resource.genericResource.languages = this.resource.genericResource.languages.filter(
                        l => l !== lang
                    );
                }
            });
            this.languages.forEach((langStr: string) => {
                let langIn: boolean = false;
                this.resource.genericResource.languages.forEach((lang: Language) => {
                    if (lang.language === langStr) {
                        langIn = true;
                    }
                });
                if (!langIn) {
                    this.resource.genericResource.languages.push(new Language(langStr));
                }
            });
        }
    }

    public getErrorMessage(field: string): string {
        switch (field) {
            case 'years' :
                return this.guideSpecificForm.get('years').hasError('required') ? 'Years Of Experience required' :
                    this.guideSpecificForm.get('years').hasError('pattern') ? 'Years Of Experience invalid' : '';
            case 'licenseNumber' :
                return this.guideSpecificForm.get('licenseNumber').hasError('required') ?
                    'License Number required' :
                    '';
            case 'passportNumber' :
                return this.guideSpecificForm.get('passportNumber').hasError('required') ?
                    'Passport number required' :
                    '';
            case 'workingHours' :
                return this.guideSpecificForm.get('workingHours').hasError('required') ?
                    'Working hours required' :
                    '';
            default :
                return '';
        }
    }

    public ngOnDestroy(): void {
        this.changeDetectSupporter.checkedSpecific = false;
    }
}
