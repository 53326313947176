import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BookingItem} from '../../../../../models/reservation-v2/booking-item';
import {BookingNoteType} from '../../../../../models/reservation-v2/booking-note';
import {Booking} from '../../../../../models/reservation-v2/booking';
import {TrsBookingItem} from '../../../../../models/reservation-v2/trs-booking-item';

@Component({
    selector: 'tc-trs-item-detail-view',
    templateUrl: './trs-item-detail-view.component.html'
})
export class TrsItemDetailViewComponent implements OnInit {

    @Input() readonly = false;
    @Input() booking: Booking;
    @Input() bookingItem: BookingItem;
    @Input() noteTypes: BookingNoteType[] = [];
    @Input() trsModes: any[] = [];

    @Output() changeValidity: EventEmitter<boolean> = new EventEmitter();
    trsBookingItem: TrsBookingItem;

    constructor() {
    }

    ngOnInit() {
    }

}
