import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MessageQueueSearchResultsModule } from '../message-queue-search-results/message-queue-search-results.module';
import { ResourceMessagePanelModule } from '../resource-message-panel/resource-message-panel.module';
import { MessagingQueueComponent } from './messaging-queue.component';
import {ResourceModule} from "@tc/resource";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatChipsModule} from "@angular/material/chips";
import {MatButtonModule} from "@angular/material/button";
import {MatListModule} from "@angular/material/list";
import {MatTooltipModule} from "@angular/material/tooltip";
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
  declarations: [MessagingQueueComponent],
    imports: [
        CommonModule,
        MessageQueueSearchResultsModule,
        ResourceMessagePanelModule,
        MatSidenavModule,
        MatFormFieldModule,
        MatInputModule,
        MatIconModule,
        FormsModule,
        ResourceModule,
        MatAutocompleteModule,
        ReactiveFormsModule,
        MatCheckboxModule,
        MatChipsModule,
        MatButtonModule,
        MatListModule,
        MatTooltipModule,
        TranslateModule.forChild()
    ],
  exports: [
    MessagingQueueComponent
  ],
  entryComponents: [
    MessagingQueueComponent
  ]
})
export class MessagingQueueModule { }
