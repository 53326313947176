import {Injectable, Injector} from '@angular/core';
import {ConfigLoader} from '@tc-core/util/framework/config-loader.service';
import {JsonUtilService} from '@tc-core/util/system/json-util.service';
import {SummaryCardData} from '@tc-core/model/it/codegen/ui/framework/summary-card-data';
import {PageSection} from '@tc-core/model/it/codegen/ui/framework/page-section';
import { TCO } from '../../../constants';

@Injectable()
export class CardDataHandlerService {

    constructor(private configLoader: ConfigLoader,
                private injector: Injector,
                private jsonUtil: JsonUtilService) {
    }

    processCardData(cardConfigKey: string, dataObject: any, cardConfigSubKey?: string, dataObjectKey?: string): SummaryCardData {

        let result: SummaryCardData;
        let configToProcess: any;

        let cardConfig = this.configLoader.configurations.get(TCO.CONF.CONF_CONTENT_LIST_CARD)[cardConfigKey];
        if (cardConfig) {

            let cardSubConfig = this.getCardConfigByKey(cardConfig, cardConfigSubKey);
            if (cardSubConfig) {
                configToProcess = cardSubConfig;
            } else {
                configToProcess = cardConfig;
            }
        }

        if (configToProcess) {
            if (configToProcess.actionHandler && configToProcess.actionHandler !== '') {
                const t = configToProcess.actionHandler.split('#');
                const srv = t[0];
                const fnc = t[1];
                const handler = this.injector.get(srv);
                result = handler[fnc](dataObject, configToProcess.config, dataObjectKey);
            }
        }
        return result;
    }

    processCardDataList(cardConfigKey: string, dataObjectList: Array<any>, cardConfigSubKey?: string, dataObjectKey?: string): Array<SummaryCardData> {

        let resultList: Array<SummaryCardData> = [];

        let configToProcess: any;

        let cardConfig = this.configLoader.configurations.get(cardConfigKey);
        if (cardConfig) {

            let cardSubConfig = this.getCardConfigByKey(cardConfig, cardConfigSubKey);
            if (cardSubConfig) {
                configToProcess = cardSubConfig;
            } else {
                configToProcess = cardConfig;
            }
        }

        if (configToProcess) {
            if (configToProcess.actionHandler && configToProcess.actionHandler !== '') {
                const t = configToProcess.actionHandler.split('#');
                const srv = t[0];
                const fnc = t[1];
                const handler = this.injector.get(srv);

                resultList = handler[fnc](dataObjectList, configToProcess.config, dataObjectKey);

            }
        }
        return resultList;
    }

    getCardConfigByKey(cardConfig: any, cardConfigSubKey: string) {

        let configToLoad = null;

        try {
            let configKeylist = cardConfigSubKey.split('.');
            switch (configKeylist.length) {
                case 1:
                    configToLoad = cardConfig[configKeylist[0]];
                    break;
                case 2:
                    configToLoad = cardConfig[configKeylist[0]][configKeylist[1]];
                    break;
                case 3:
                    configToLoad = cardConfig[configKeylist[0]][configKeylist[1]][configKeylist[2]];
                    break;
                case 4:
                    configToLoad = cardConfig[configKeylist[0]][configKeylist[1]][configKeylist[2]][configKeylist[3]];
                    break;
                case 5:
                    configToLoad = cardConfig[configKeylist[0]][configKeylist[1]][configKeylist[2]][configKeylist[3]][configKeylist[4]];
                    break;
            }
            return configToLoad;
        }
        catch (e) {
            return configToLoad;
        }
    }

    getPageSection(cardConfigKey: string, dataObject: any, sectionKey?: string): PageSection {
        let result: PageSection;
        let configToProcess: any;

        let cardConfig = this.configLoader.configurations.get(TCO.CONF.CONF_PAGE_SECTION_CONFIG)[cardConfigKey].sections;

        if (cardConfig) {
            let cardSubConfig = this.getCardConfigByKey(cardConfig, sectionKey);
            if (cardSubConfig) {
                configToProcess = cardSubConfig;
            } else {
                configToProcess = cardConfig;
            }

            if (configToProcess) {
                let actionHandler = 'ContentPreProcessorService#preProcessSectionBlock';
                const t = actionHandler.split('#');
                const srv: any = t[0];
                const fnc = t[1];
                const handler = this.injector.get(srv);
                result = handler[fnc](dataObject, configToProcess);

            }
            return result;
        }
    }

    getAllPageSections(cardConfigKey: string, dataObject: any): Map<string, PageSection> {
        let result: Map<string, PageSection> = new Map<string, PageSection>();
        let configToProcess: any;

        let cardConfig = this.configLoader.configurations.get(TCO.CONF.CONF_PAGE_SECTION_CONFIG)[cardConfigKey].sections;

        if (cardConfig) {
            let actionHandler = 'ContentPreProcessorService#preProcessSectionBlock';
            const t = actionHandler.split('#');
            const srv: any = t[0];
            const fnc = t[1];
            const handler = this.injector.get(srv);

            for (let key in cardConfig) {
                configToProcess = cardConfig[key];
                if (configToProcess) {
                    result.set(key, handler[fnc](dataObject, configToProcess));
                }
            }
            return result;
        }

    }

}
