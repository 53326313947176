import { formatDate } from '@angular/common';
import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TBXResponseWrapper } from '@tc-core/model/it/codegen/tbx/api/tbx-response-wrapper';
import { TcErrorType } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-error';
import { DialogModel, LEVEL } from '@tc-core/model/it/codegen/ui/framework/dialog-model';
import { ModalData } from '@tc-core/model/it/codegen/ui/framework/modal-data';
import { Pager } from '@tc-core/model/it/codegen/ui/framework/pager';
import { Sorter } from '@tc-core/model/it/codegen/ui/framework/sorter';
import { SummaryCardData } from '@tc-core/model/it/codegen/ui/framework/summary-card-data';
import { SorterService } from '@tc-core/service/sorters/sorter.service';
import { TC } from '@tc-core/util/constants';
import { ConfigLoader } from '@tc-core/util/framework/config-loader.service';
import { EventManager } from '@tc-core/util/framework/event-manager.service';
import { FocusViewManager } from '@tc-core/util/framework/focus-view-manager.service';
import { UserJourneyManager } from '@tc-core/util/framework/user-journey-manager.service';
import { CommonHelper } from '@tc-core/util/helpers/common-helper.service';
import { DateFormatter } from '@tc-core/util/system/date-formatter.service';
import { AutoScrollService } from '@tc-core/util/ui/auto-scroll.service';
import { SpinnerService } from '@tc-core/util/ui/spinner.service';
import { DialogService } from '@tc/dialog/dialog.service';
import { FocusViewService } from '@tc/focus-view/focus-view.service';
import { ModalService } from '@tc/modal/modal.service';
import * as moment from 'moment';
import { Observable, Subscription } from 'rxjs';
import { AssignmentFocusViewComponent } from '../../assignment-focus-view/assignment-focus-view.component';
import { TCO } from '../../../constants';
import { DocumentCreationModalComponent } from '../../document-creation-modal/document-creation-modal.component';
import { AssignmentSummary } from '../../../models/assignment-summary';
import {
    DocumentCreationCriteria,
    DocumentFormat,
    DocumentType
} from '../../../models/criteria/document-creation-criteria';
import { DocumentDataCriteria } from '../../../models/criteria/document-data-criteria';
import { GenericSearchCriteria } from '../../../models/criteria/generic-search-criteria';
import { ReservationSearchCriteria } from '../../../models/criteria/reservation-search-criteria';
import { SupplierSearchCriteria } from '../../../models/criteria/supplier-search-criteria';
import { VehicleSearchCriteria } from '../../../models/criteria/vehicle-search-criteria';
import { DocumentCreationModel } from '../../../models/helper/document-creation-model';
import { ResourceAllocationEvent } from '../../../models/helper/resource-allocation-event';
import { ResourceAllocationSearchEvent } from '../../../models/helper/resource-allocation-search-event';
import { ServiceItemChanges } from '../../../models/helper/service-item-changes';
import { LocationType } from '../../../models/master-data/location-type';
import { ResourceType } from '../../../models/reservation/assignment';
import { AssignmentSaveOperation } from '../../../models/reservation/assignment-save-operation';
import { AssignmentStatus } from '../../../models/reservation/assignment-status';
import { NoteWrapper } from '../../../models/reservation/note-wrapper';
import { PassengerCount } from '../../../models/reservation/passenger-count';
import { SearchResultSummary } from '../../../models/reservation/search-result-summary';
import { ServiceAssignment } from '../../../models/reservation/service-assignment';
import { ServiceGroup } from '../../../models/reservation/service-group';
import { ReservationStatus, ServiceItem } from '../../../models/reservation/service-item';
import { ServiceNote } from '../../../models/reservation/service-note';
import { ServicePassenger } from '../../../models/reservation/service-passenger';
import { TrsItemGroupTimeRange } from '../../../models/reservation/trs-item-group-time-range';
import { ResourceAvailability } from '../../../models/resource/resource-availability';
import { OPResponseWrapper } from '../../../models/response/op-response-wrapper';
import { DriverSupplierCostSummary } from '../../../models/summary/driver-supplier-cost-summary';
import { TrsSupplierCostSummary } from '../../../models/summary/supplier-cost-summary';
import { TrsSupplierAllocationSummary } from '../../../models/summary/trs-supplier-allocation-summary';
import { VehicleSummary } from '../../../models/summary/vehicle-summary';
import { DocumentService } from '../../../services/backend-consumers/documents/document.service';
import { DataHandlerService } from '../../../services/backend-consumers/master-data-handler-service/data-handler.service';
import { ReservationManagementService } from '../../../services/backend-consumers/reservation-management-services/reservation-management.service';
import { TrsReservationManagementService } from '../../../services/backend-consumers/reservation-management-services/trs-reservation-management.service';
import { GenericResourceService } from '../../../services/backend-consumers/supplier-service/generic-resource.service';
import { SupplierService } from '../../../services/backend-consumers/supplier-service/supplier.service';
import { TransportResourceService } from '../../../services/backend-consumers/supplier-service/transport-resource.service';
import { CostCalculationUtilsService } from '../../../services/business-utils/cost-calculation/cost-calculation-utils.service';
import { ServiceAssignmentUtilService } from '../../../services/business-utils/service-item-utils/service-assignment-util.service';
import { ChangeDetectorService } from '../../../services/util/change-detector/change-detector.service';
import { DMCCommon } from '../../../services/util/common/dmc-common';
import { UtilService } from '../../../services/util/common/util.service';
import { RootService } from '../../../services/util/core-services/root.service';
import { DataKey, DataStoreService } from '../../../services/util/framework/data-store.service';
import { DMCQueryParamsService } from '../../../services/util/framework/dmc-query-params.service';
import { IdentifierService } from '../../../services/util/identifier/identifier.service';
import { ResponseUtil } from '../../../services/util/response/response-util.service';
import { ContentSummaryCardService } from '../../../services/util/ui/content-summary-card.service';
import { AutoAssignedSummaryWrapperComponent } from '../auto-assigned-summary-wrapper/auto-assigned-summary-wrapper.component';
import { LeadPassengerSelectionWrapperComponent } from '../lead-passenger-selection-wrapper/lead-passenger-selection-wrapper.component';
import { SearchCriteriaSaveModalComponent } from '../search-criteria-save-modal/search-criteria-save-modal.component';
import { ServiceItemDetailFocusViewComponent } from '../service-item-detail-focus-view/service-item-detail-focus-view.component';
import { SplitPassengersViewWrapperComponent } from '../split-passengers-view-wrapper/split-passengers-view-wrapper.component';

@Component({
    selector: 'tc-reservation-search-results',
    templateUrl: './reservation-search-results.component.html',
    styleUrls: ['./reservation-search-results.component.css']
})
export class ReservationSearchResultsComponent implements OnInit, OnDestroy {

    tReservations: any;
    tServiceItems: any;

    // service item groups
    serviceItemGroups: SearchResultSummary[] = [];
    serviceItemGroupsDataForCards: any = [];
    serviceItemGroupCards: SummaryCardData[] = [];
    selectedSummaryCardFromGroups: SummaryCardData;
    selectedServiceItemGroup: SearchResultSummary;

    // selected group service items
    serviceItemsInSelectedGroup: ServiceItem [];
    serviceItemsInSelectedGroupCards: SummaryCardData[] = [];
    selectedServiceItem: ServiceItem;
    selectedServiceGroupNotes: ServiceNote[] = [];
    addingServiceNoteCount: number = 0;

    footerDataMap: Map<number, object> = new Map<number, object>();
    isSearchResultLoaded = false;
    public showEditBtn = true;
    heading: string;
    transferModes = [];
    groupingOptions = [];
    groupingOptionsRowData = [];
    searchCriteria = new ReservationSearchCriteria();
    sorters_: Sorter[];
    allSupplierList: any[];
    allResourceList: any[];
    autoAssignResults: any[];
    unSavedAssignments: any[] = [];
    // isFiltered = false;
    isGroupNoteEditClicked = false;
    isRequestCompleted = true;
    autoCompleteClicked = false;

    public pager: Pager;
    public defaultPageResultCount = 10;
    selectionIndex = '';
    tPagination: any;
    fetchedReservationsCount: number = 0;
    dataFromMoreClick = false;
    paginatorHasMore = true;
    isCleared = false;

    resultCount: number = 0;

    serviceItemChangesInSelectedGroup: Map<number, ServiceItemChanges> = new Map();
    savedServiceItems: number[] = [];
    selectedGroupCriteria: ReservationSearchCriteria = null;
    passengerCount: PassengerCount;

    filteredVehicleSuppliers: TrsSupplierAllocationSummary[] = [];
    filteredDriverSuppliers: DriverSupplierCostSummary[] = [];
    filteredVehicles: ResourceAvailability[] = [];
    filteredDrivers: ResourceAvailability[] = [];

    changesAvailableForServiceItems = false;
    isExportEnable = true;
    baseURL = this.configLoader.configurations.get(TCO.CONF.CONF_ENDPOINT).base_url;
    defaultVehicleModel = 'ANY';

    isFocusViewDataChanged = false;
    isPopupDataChanged = false;
    unSavedDataAvailable = false;
    exit = false;

    assignmentList: ServiceAssignment[] = [];

    private addCriteriaNameSubscription: Subscription = new Subscription();
    private reservationResultsSubscription: Subscription = new Subscription();
    private reservationResultsFromServiceSubscription: Subscription = new Subscription();
    private reservationResultsFromServiceOnMoreSubscription: Subscription = new Subscription();
    private reservationSearchCriteriaSubscription: Subscription = new Subscription();
    private pickupDropOffModalCloseSubscription: Subscription = new Subscription();
    private splitPassengersModalCloseSubscription: Subscription = new Subscription();
    private leadPassengerModalCloseSubscription: Subscription = new Subscription();
    private focusviewCloseSubject: Subscription = new Subscription();
    private focusViewDataChangeObserver: Subscription = new Subscription();
    private popupDataChangeObserver: Subscription = new Subscription();
    private vehicleResultObsv: Subscription = new Subscription();
    private selectedGroupItemsObserver: Subscription = new Subscription();

    constructor(
        private userJourneyManager: UserJourneyManager,
        private configLoader: ConfigLoader,
        private trsReservationManagementService: TrsReservationManagementService,
        private identifierService: IdentifierService,
        private reservationManagementService: ReservationManagementService,
        private spinnerService: SpinnerService,
        private injector: Injector,
        private route: ActivatedRoute,
        public em: EventManager,
        public sorterService: SorterService,
        private utilService: UtilService,
        private dateFormatter: DateFormatter,
        private dataStore: DataStoreService,
        private summaryCardService: ContentSummaryCardService,
        private modalService: ModalService,
        private dialogService: DialogService,
        private supplierService: SupplierService,
        private changeDetector: ChangeDetectorService,
        private commonHelper: CommonHelper,
        private common: DMCCommon,
        private focusViewManager: FocusViewManager,
        private costCalculationUtilsService: CostCalculationUtilsService,
        private focusViewService: FocusViewService,
        private transportResourceService: TransportResourceService,
        private genericResourceService: GenericResourceService,
        private rootService: RootService,
        private queryParamService: DMCQueryParamsService,
        private dataHandlerService: DataHandlerService,
        private autoScrollService: AutoScrollService,
        private documentService: DocumentService
    ) {
    }

    ngOnInit() {
        this.tReservations = this.configLoader.configurations.get(TCO.CONF.CONF_RESERVATION_SEARCH_RESULTS_CONFIG);
        this.tServiceItems = this.configLoader.configurations.get(TCO.CONF.CONF_RESERVATION_SERVICE_ITEMS_CARD_CONFIG);
        this.tPagination = this.configLoader.configurations.get(TCO.CONF.CONF_PAGINATION_CONFIG)['RESERVATION_SEARCH'];

        this.setupPaginator();

        this.subscribeSearchCriteria();

        this.subscribeSearchResults(); // search results at first
        this.subscribeOnMoreSearchResults();  // search results when click more
        this.subscribeSearchResultList();  // concat search results list

        this.createSorters();

        this.getSelectedGroup();

        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'GENERATE_DOCUMENTS').subscribe(e => {
            this.onGenerateDocumentsClick();
        });

        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'EXPORT').subscribe(e => {
            this.export('', false, true);
        });
        this.em.addEvent(TCO.CONF.CONF_EVENT_MANAGER, 'SAVE_CRITERIA').subscribe(e => {
            this.onSaveSearchCriteriaClick();
        });
        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'SEND_MANIFESTS').subscribe(e => {
            this.sendManifests();
        });
        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'ARRIVAL_LIST').subscribe(e => {
            this.getArrivalList('', false, true);
        });
        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'DEPARTURE_LIST').subscribe(e => {
            this.getDepartureList('', false, true);
        });
    }

    // paginator
    private setupPaginator() {
        this.defaultPageResultCount = this.tPagination['defaultPageResultCount'];
        this.pager = new Pager(0, this.defaultPageResultCount);
    }

    public onPage(pageNumber: number) {
        this.pager.currPage = pageNumber;
        this.selectionIndex = (this.pager.currPage * this.pager.itemsPerPage).toString();
    }

    public onSelection(event) {
        this.selectionIndex = event;
    }

    public onMore() {
        this.dataFromMoreClick = true;
        if (this.tPagination && this.tPagination['defaultPageResultCount'] && this.tPagination['fetchingPages']) {
            this.searchCriteria.size = this.tPagination['fetchingPages'] * this.tPagination['defaultPageResultCount'];
            this.searchCriteria.start = this.fetchedReservationsCount;
            this.searchOnMore(this.searchCriteria);
        }
    }

    private setPageZero() {
        if (this.pager) {
            if (!this.dataFromMoreClick) {
                this.pager.currPage = 0;
            } else {
                this.dataFromMoreClick = false;
            }
        } else {
            this.pager = new Pager(0, this.defaultPageResultCount);
        }
    }

    // -- paginator--

    searchOnMore(searchCriteria: ReservationSearchCriteria) {
        this.dataStore.set(DataKey.reservationSearchResultsFromServiceOnMore, null, true);
        this.trsReservationManagementService.searchTrsItemGroupsOnMore(searchCriteria);
    }

    search(searchCriteria: ReservationSearchCriteria) {
        this.dataStore.set(DataKey.reservationSearchResultsFromService, null, true);
        this.trsReservationManagementService.searchTrsItemGroups(searchCriteria);
    }

    subscribeSearchResults() {
        this.reservationResultsFromServiceSubscription = this.dataStore.get(DataKey.reservationSearchResultsFromService)
            .subscribe((data: OPResponseWrapper<SearchResultSummary>) => {
                this.resultCount = 0;
                if (this.searchCriteria.transferMode !== null) {
                    // this.isFiltered = true;
                }
                if (data) {
                    let trsItemGroups = ResponseUtil.getDataArray<SearchResultSummary>(
                        data);
                    this.resultCount = ResponseUtil.getTotalCount(data);
                    this.dataStore.set(
                        DataKey.reservationSearchResults,
                        trsItemGroups,
                        true
                    );
                }
            });
    }

    subscribeOnMoreSearchResults() {
        this.reservationResultsFromServiceOnMoreSubscription = this.dataStore.get(DataKey.reservationSearchResultsFromServiceOnMore)
            .subscribe((data: OPResponseWrapper<SearchResultSummary>) => {
                if (data) {
                    this.resultCount = 0;
                    let trsItemGroups = ResponseUtil.getDataArray<SearchResultSummary>(
                        data);
                    trsItemGroups = this.serviceItemGroups.concat(
                        trsItemGroups);
                    this.resultCount = ResponseUtil.getTotalCount(
                        data);
                    this.dataStore.set(
                        DataKey.reservationSearchResults,
                        trsItemGroups,
                        true
                    );
                }
            });
    }

    subscribeSearchCriteria() {
        this.reservationSearchCriteriaSubscription = this.dataStore.get(DataKey.reservationSearchCriteria)
            .subscribe((data) => {
                if (data !== null) {
                    this.searchCriteria = data;
                }
            });
    }

    subscribeSearchResultList() {
        this.fetchedReservationsCount = 0;
        this.reservationResultsSubscription = this.dataStore.get(DataKey.reservationSearchResults)
            .subscribe((data: SearchResultSummary[]) => {
                this.serviceItemGroups = [];
                if (data != null) {
                    this.spinnerService.hide();
                }
                if (data) {
                    this.serviceItemGroups = data;
                    this.fetchedReservationsCount = this.serviceItemGroups.length;
                    if (this.fetchedReservationsCount > 0) {
                        this.enableExportButton();
                        this.userJourneyManager.enableQuickActions([
                            'TRIGGER_EXPORT_FILE'
                        ]);
                    }
                    this.getServiceItemGroupSummaryCards();
                } else {
                    this.serviceItemGroupCards = [];
                    this.heading = '';
                }
            });
    }

    enableExportButton() {
        this.userJourneyManager.canProceed.next(true);
        this.focusViewManager.canProceed.next(true);
        this.isExportEnable = true;
    }

    getServiceItemGroupSummaryCards() {
        if (this.serviceItemGroups.length !== null) {
            let reservations: SearchResultSummary[] = this.serviceItemGroups;
            if (Array.isArray(reservations)) {
                let unique_tms = reservations.filter((e, i) => reservations.findIndex(
                    a => a.transferMode === e.transferMode) === i).map(a => a.transferMode);

                this.loadResultsHeader(unique_tms);
            }
            let serviceItemGroupsDataArray = [];
            for (let i = 0; i < reservations.length; i++) {
                let transferMode = reservations[i].transferMode;

                let dateStart = reservations[i].startTime;
                let serviceDate = this.dateFormatter.dateAsString(new Date(reservations[i].serviceDate), 'DD MMM YY');

                let route = reservations[i].route;
                let pickupLocations = reservations[i].pickups;
                let allocationsLeft = reservations[i].remainingCount;

                let endDate = reservations[i].endTime;
                let arrivalDateTime = dateStart + '-' + endDate;

                let passengerCount = reservations[i].passengerCount;
                let dropOffLocations = reservations[i].dropOffs;
                let allocatedCount = reservations[i].allocatedCount;

                let groupStatus = reservations[i].groupStatus;
                let reservationStatus = reservations[i].reservationStatus;

                let transferModeObj = this.transferModes.find(value => {
                    if (value['code'] && value['code']['code'] === transferMode) {
                        return true;
                    } else {
                        return false;
                    }
                });
                if (transferModeObj && transferModeObj['code']) {
                    transferModeObj = transferModeObj['code'];
                }

                serviceItemGroupsDataArray.push({
                    'type': transferMode,

                    'serviceDate': serviceDate,
                    'route': route,
                    'pickupLocations': this.getPickupLocations(pickupLocations),
                    'allocationsLeft': allocationsLeft,

                    'arrivalDateTime': arrivalDateTime,
                    'passengerCount': passengerCount,
                    'dropOffLocations': this.getDropOffLocations(dropOffLocations),
                    'allocatedCount': allocatedCount,
                    'groupStatus': groupStatus,
                    'reservationStatus': reservationStatus,
                    'transferModeObj': transferModeObj,
                    'rawData': reservations[i]

                });
            }
            this.serviceItemGroupsDataForCards = serviceItemGroupsDataArray;
            this.serviceItemGroupCards = this.getSearchContentAsCardData();
        }
    }

    getSearchContentAsCardData() {
        let contentCardList = this.summaryCardService.getContentCardsByConfig(
            this.tReservations,
            this.serviceItemGroupsDataForCards
        );

        this.spinnerService.hide();
        this.heading = this.resultCount + ' result' + (this.resultCount > 1 ? 's' : '') + ' found.';

        this.paginatorHasMore = contentCardList.length !== this.resultCount;

        console.log(this.heading);
        this.setPageZero();
        return contentCardList;
    }

    createSorters() {
        this.sorterService.sorters = [];
        this.sorters_ = [];
        let sorterMap = new Map<string, Sorter>();
        this.tReservations.config.placeHolderConfig.forEach(config => {
            if (config && config.sort) {
                let sorter = this.sorterService.createSorter(config);
                let id = config['_id'];
                sorterMap.set(id, sorter);
                this.sorters_.push(sorter);
            }
        });
    }

    public onSort(event) {
        this.sorterService.sorter = event;
    }

    private getPickupLocations(pickupLocations: any) {
        let pickupLocationsStr = '';
        let length = pickupLocations.length;
        for (let i = 0; i < length; i++) {
            pickupLocationsStr += pickupLocations[i];
            if (i < length - 1) {
                pickupLocationsStr += ', ';
            }
        }
        return pickupLocationsStr;
    }

    private getDropOffLocations(dropOffLocations: any) {
        let dropOffLocationsStr = '';
        let length = dropOffLocations.length;
        for (let i = 0; i < length; i++) {
            dropOffLocationsStr += dropOffLocations[i];
            if (i < length - 1) {
                dropOffLocationsStr += ', ';
            }
        }
        return dropOffLocationsStr;
    }

    retrieveSelectedGroupAndSetCriteria() {
        this.dataStore.set(DataKey.reservationSelectedGroupCriteria, this.selectedGroupCriteria, true);
        this.retrieveSelectedGroup(false);
    }

    retrieveSelectedGroup(considerLocalChanges: boolean = true) {
        this.autoAssignResults = [];
        this.autoCompleteClicked = false;
        this.assignmentList = this.getAssignmentsFromMap();
        this.trsReservationManagementService.retrieveServiceItems(this.selectedGroupCriteria)
            .subscribe((result: TBXResponseWrapper<ServiceItem>) => {
                let data = ResponseUtil.getDataArray<ServiceItem>(result);
                if (considerLocalChanges) {
                    if (data && this.changesAvailableForServiceItems) {
                        for (let i = 0; i < data.length; i++) {
                            let assignments = this.assignmentList.filter(
                                ass => ass.serviceItemId === data[i].serviceItemId);
                            if (assignments) {
                                this.unSavedDataAvailable = true;
                            }

                            /*if (assignments && data[i].assignments) {
                             assignment.assignmentId = data[i].assignment.assignmentId;
                             assignment.status = data[i].assignment.status;
                             }*/

                            data[i].serviceAssignments = assignments.length > 0 ?
                                assignments :
                                data[i].serviceAssignments;
                        }
                    }
                }
                this.dataStore.set(DataKey.reservationSelectedGroupData, data, true);
            });

        this.trsReservationManagementService.retrieveReservationGroupNotes(this.selectedGroupCriteria)
            .subscribe(result => {
                let serviceGroup = ResponseUtil.getFirstData<ServiceGroup>(result);
                if (serviceGroup) {
                    let groupNotes = serviceGroup.notes;
                    if (groupNotes && groupNotes.length > 0) {
                        this.selectedServiceGroupNotes = groupNotes.sort();
                    } else {
                        this.selectedServiceGroupNotes = [];
                    }
                } else {
                    this.selectedServiceGroupNotes = [];
                }

            });

    }

    getSelectedGroup() {
        this.serviceItemsInSelectedGroup = [];
        this.serviceItemsInSelectedGroupCards = [];
        this.selectedServiceGroupNotes = [];
        this.selectedGroupItemsObserver = this.dataStore.get(DataKey.reservationSelectedGroupData)
            .subscribe((result: ServiceItem[]) => {
                this.serviceItemsInSelectedGroup = [];
                this.serviceItemsInSelectedGroupCards = [];
                this.selectedServiceGroupNotes = [];
                if (result != null) {
                    this.serviceItemsInSelectedGroup = result;
                    this.createSelectedGroupServiceItemsList();
                    this.serviceItemsInSelectedGroupCards = this.getServiceItemSummaryCards();
                    if (this.unSavedDataAvailable) {
                        this.changesAvailableForServiceItems = true;
                    } else {
                        this.changesAvailableForServiceItems = false;
                    }

                    this.updateSummaryCard();
                }
                this.savedServiceItems = [];
            });

        /*const n = this.dataStore.get(DataKey.reservationSelectedGroupNoteData).subscribe(result => {
         if (result && result.body) {
         if (result.body.data && result.body.message !== 'No notes for this group') {
         this.selectedServiceGroupNotes = result.body.data.notes;
         this.dataStore.get(DataKey.selectedGroupNoteTemporaryData)
         .pipe(
         take(2)
         )
         .subscribe(result => {
         if (result) {
         this.selectedServiceGroupNotes = this.selectedServiceGroupNotes.concat(result);
         }
         this.dataStore.set(DataKey.selectedGroupNoteTemporaryData, null, true);
         });
         } else {
         this.selectedServiceGroupNotes = [];
         }
         }
         });*/

    }

    createSelectedGroupServiceItemsList() {
        this.serviceItemChangesInSelectedGroup = new Map();
        this.serviceItemsInSelectedGroup.forEach(
            item => {
                let serviceItemCardData = new ServiceItemChanges();
                serviceItemCardData.serviceItemId = item.serviceItemId;
                serviceItemCardData.checked = this.shouldCheckItemAfterSave(item);
                serviceItemCardData.firstParentId = item.firstParentId;
                serviceItemCardData.isPrivate = item.privateBooking;
                serviceItemCardData.reservationStatus = item.reservationStatus;
                serviceItemCardData.serviceAssignments = new Map();
                if (item.serviceAssignments) {
                    item.serviceAssignments.forEach(value => {
                        serviceItemCardData.serviceAssignments.set(value.categoryCode, value);
                    });
                }
                // for each service item has a serviceItemData in serviceItemChangesInSelectedGroup
                this.serviceItemChangesInSelectedGroup.set(item.serviceItemId, serviceItemCardData);
                if (item.serviceAssignments) {
                    // serviceItemCardData.serviceAssignments = item.serviceAssignments;
                    if (item.serviceAssignments) {
                        let vehicleSupplierId = 0;
                        let driverSupplierId = 0;
                        let driverId = 0;
                        let vehicleId = 0;
                        item.serviceAssignments.forEach(value => {
                            if (value.categoryCode === ResourceType.driver) {
                                driverSupplierId = value.supplierId;
                                driverId = value.resourceId;
                                serviceItemCardData.driverCost = this.costCalculationUtilsService.calculateServiceItemJobCost(
                                    item,
                                    value
                                );
                            } else if (value.categoryCode === ResourceType.vehicle) {
                                vehicleSupplierId = value.supplierId;
                                vehicleId = value.resourceId;
                                serviceItemCardData.vehicleCost = this.costCalculationUtilsService.calculateServiceItemJobCost(
                                    item,
                                    value
                                );
                            }
                            value.serviceItemId = item.serviceItemId;
                            serviceItemCardData.serviceAssignments.set(value.categoryCode, value);
                        });

                        if (vehicleSupplierId > 0) {
                            let criteria = new SupplierSearchCriteria();
                            criteria.transferMode = item.trsServiceItem.transferMode;
                            criteria.route = item.trsServiceItem.route;
                            criteria.serviceDate = item.serviceDate;
                            criteria.status = 'true';
                            criteria.supplierId = vehicleSupplierId;
                            this.getAssignedSupplierById(criteria).subscribe(
                                result => {
                                    if (this.commonHelper.dataValidity(result)) {
                                        let suppler = ResponseUtil.getFirstData<any>(result);
                                        if (suppler) {
                                            if (suppler.supplierId === vehicleSupplierId) {
                                                serviceItemCardData.vehicleSupplier = suppler;
                                                this.changeDetector.notifyChange();
                                            }
                                        }

                                    }
                                }
                            );
                        }
                        if (driverSupplierId > 0) {
                            let criteria = new SupplierSearchCriteria();
                            criteria.status = 'true';
                            criteria.supplierId = driverSupplierId;
                            this.supplierService.searchDriverSuppliersForAssignment(criteria).subscribe(
                                result => {
                                    if (this.commonHelper.dataValidity(result)) {
                                        let suppler = ResponseUtil.getFirstData<any>(result);
                                        if (suppler) {
                                            if (suppler.supplierId === driverSupplierId) {
                                                serviceItemCardData.driverSupplier = suppler;
                                                this.changeDetector.notifyChange();
                                            }
                                        }

                                    }
                                }
                            );
                        }
                        if (vehicleId > 0) {
                            if (this.autoCompleteClicked == true) {
                                this.autoAssignCallForResources(serviceItemCardData, item);
                            } else {
                                this.getAssignedVehicleById(vehicleId).subscribe(
                                    result => {
                                        if (this.commonHelper.dataValidity(result)) {
                                            let vehicle = ResponseUtil.getFirstData<VehicleSummary>(result);
                                            if (vehicle) {
                                                if (vehicle.resourceId === vehicleId) {
                                                    let resource = new ResourceAvailability();
                                                    resource.resourceName = vehicle.vehicleName;
                                                    resource.resourceId = vehicle.resourceId;
                                                    serviceItemCardData.vehicle = resource;
                                                    this.changeDetector.notifyChange();
                                                }
                                            }
                                        }
                                    }, error2 => {
                                        this.spinnerService.hide();
                                    }
                                );
                            }

                        }
                        if (driverId > 0) {
                            this.getAssignedDriverById(driverId).subscribe(
                                result => {
                                    if (this.commonHelper.dataValidity(result)) {
                                        let driver: any = ResponseUtil.getFirstData(result);
                                        if (driver) {
                                            if (driver.resourceId === driverId) {
                                                // @ts-ignore
                                                serviceItemCardData.driver = driver;
                                                serviceItemCardData.driver.resourceName = driver.firstName +
                                                    ' ' +
                                                    driver.lastName;
                                                this.changeDetector.notifyChange();
                                            }
                                        }
                                    }
                                }
                            );
                        }
                    }
                }
            }
        );
        // this.autoCompleteClicked = false;
    }

    private autoAssignCallForResources(serviceItemCardData: ServiceItemChanges, item) {
        this.getAssignedVehicleByIdBackend(serviceItemCardData.serviceAssignments.get(ResourceType.vehicle).resourceId)
            .subscribe(
                result => {
                    if (this.commonHelper.dataValidity(result)) {
                        let vehicle = ResponseUtil.getFirstData<ResourceAvailability>(result);
                        if (vehicle) {
                            if (vehicle.resourceId ===
                                serviceItemCardData.serviceAssignments.get(ResourceType.vehicle).resourceId) {
                                serviceItemCardData.vehicle = vehicle;
                                if (this.autoCompleteClicked == true) {
                                    let vehicleSearchCriteria = new VehicleSearchCriteria();
                                    vehicleSearchCriteria.activeStatus = true;
                                    vehicleSearchCriteria.vehicleName = serviceItemCardData.vehicle.resourceName;
                                    vehicleSearchCriteria.resourceType = ResourceType.vehicle;
                                    vehicleSearchCriteria.transferMode = serviceItemCardData.vehicle.transferMode;
                                    vehicleSearchCriteria.serviceDate = item.serviceDate;
                                    vehicleSearchCriteria.route = item.trsServiceItem.route;
                                    vehicleSearchCriteria.start = 0;
                                    vehicleSearchCriteria.size = 10;
                                    vehicleSearchCriteria.supplierId = +serviceItemCardData.vehicle.trsSupplier.supplierId;
                                    vehicleSearchCriteria.serviceItems = serviceItemCardData.serviceItemId.toString();
                                    this.trsReservationManagementService.searchRecommendedVehiclesFromMock(
                                        vehicleSearchCriteria).subscribe(
                                        result => {
                                            this.spinnerService.hide();
                                            if (result) {
                                                if (result) {
                                                    this.filteredVehicles = ResponseUtil.getDataArray<ResourceAvailability>(
                                                        result);
                                                    let firstData = ResponseUtil.getFirstData<ResourceAvailability>(
                                                        result);
                                                    let event = new ResourceAllocationEvent(
                                                        serviceItemCardData.serviceItemId,
                                                        null,
                                                        firstData,
                                                        null,
                                                        null
                                                    );
                                                    this.changeDetector.notifyChange();
                                                    this.onSelectVehicle(event);

                                                } else {
                                                    this.filteredVehicles = [];
                                                }
                                            } else {
                                                this.filteredVehicles = [];
                                            }
                                        }
                                        , error => {
                                            this.spinnerService.hide();
                                            this.filteredVehicles = [];
                                        }
                                    );
                                } else {
                                    this.changeDetector.notifyChange();
                                }
                            }
                        }
                    }
                }, error2 => {
                    this.spinnerService.hide();
                }
            );
    }

    getAssignedSupplierById(supplierSearchCriteria: SupplierSearchCriteria): Observable<any> {
        return this.supplierService.searchSuppliersForAssignment(supplierSearchCriteria);
    }

    getAssignedVehicleById(resourceId: number): Observable<any> {
        let vehicleSearchCriteria = new VehicleSearchCriteria();
        vehicleSearchCriteria.activeStatus = true;
        vehicleSearchCriteria.resourceId = resourceId;
        return this.transportResourceService.searchVehicleSummaries(vehicleSearchCriteria);
    }

    getAssignedVehicleByIdBackend(resourceId: number): Observable<any> {
        let vehicleSearchCriteria = new VehicleSearchCriteria();
        vehicleSearchCriteria.activeStatus = true;
        vehicleSearchCriteria.resourceId = resourceId;
        return this.transportResourceService.searchVehiclesSummaryNoDataKey(vehicleSearchCriteria);
    }

    getAssignedDriverById(resourceId: number): Observable<any> {
        let driverSearchCriteria = new GenericSearchCriteria();
        driverSearchCriteria.activeStatus = 'true';
        driverSearchCriteria.resourceId = resourceId;
        return this.genericResourceService.searchGenericResource(driverSearchCriteria);
    }

    public onReservationGroupSelect(summaryCardData: SummaryCardData) {
        // clear service item cards
        this.serviceItemsInSelectedGroupCards = [];

        this.selectedSummaryCardFromGroups = summaryCardData;
        this.selectedServiceItemGroup = summaryCardData.data.value['rawData'];

        // don't use existing criteria itself. create a new one.
        // existing one is needed for fetching more results in pagination.
        this.selectedGroupCriteria = new ReservationSearchCriteria();

        //copy data from existing criteria
        Object.assign(this.selectedGroupCriteria, this.searchCriteria);

        this.selectedGroupCriteria.start = 0;

        this.selectedGroupCriteria.route = this.selectedServiceItemGroup.route;
        this.selectedGroupCriteria.transferMode = this.selectedServiceItemGroup.transferMode;
        this.selectedGroupCriteria.date = formatDate(this.selectedServiceItemGroup.serviceDate, 'yyyy-MM-dd', 'en-us');

        // time range for the selected group
        this.selectedGroupCriteria.serviceStartTime = this.selectedServiceItemGroup.startTime;
        this.selectedGroupCriteria.groupEndTime = this.selectedServiceItemGroup.endTime;
        this.selectedGroupCriteria.serviceItemIdList = this.selectedServiceItemGroup.serviceItemIdList;

        this.identifierService.resetManuallyResetId();
        this.retrieveSelectedGroupAndSetCriteria();

        this.generateFooterDataMap();
    }

    getServiceItemSummaryCards() {

        let reservationMap = [];
        this.serviceItemsInSelectedGroup.forEach((serviceItem: ServiceItem) => {
            let vehicleSupplierId = 0;
            let driverSupplierId = 0;
            let driverId = 0;
            let vehicleId = 0;
            let status = '';
            let vehicleAssignmentStatus = '';
            let driverAssignmentStatus = '';
            let jobCost = 0;
            let unitCost = 0;
            if (serviceItem.serviceAssignments) {
                serviceItem.serviceAssignments.forEach(value => {
                    if (value.categoryCode === ResourceType.driver) {
                        driverSupplierId = value.supplierId;
                        driverId = value.resourceId;
                        driverAssignmentStatus = value.assignStatus;
                    } else if (value.categoryCode === ResourceType.vehicle) {
                        vehicleSupplierId = value.supplierId;
                        vehicleId = value.resourceId;
                        vehicleAssignmentStatus = value.assignStatus;
                    }
                    jobCost += value.jobCost;
                    unitCost += value.estimatedCost;
                    status = value.assignStatus;
                });
            }
            reservationMap.push({
                'serviceItemId': serviceItem.serviceItemId,
                'bookingId': serviceItem.bookingReferenceId,
                'pickups': serviceItem.trsServiceItem.pickupPointName ?
                    serviceItem.trsServiceItem.pickupPointName :
                    '',
                'dropOffs': serviceItem.trsServiceItem.dropoffPointName ?
                    serviceItem.trsServiceItem.dropoffPointName :
                    '',
                'vehicleSupplier': vehicleSupplierId,
                'driverSupplier': driverSupplierId,
                'resource': vehicleId,
                'driver': driverId,
                'price': jobCost,
                'passengerCount': serviceItem.servicePassengers ? serviceItem.servicePassengers.length : '',
                'leadPassenger': this.getLeadPassengerName(serviceItem.servicePassengers),
                'estimatedPrice': unitCost,
                'private': serviceItem.privateBooking,
                'split': serviceItem.split,
                'status': status,
                'vehicleAssignmentStatus': vehicleAssignmentStatus,
                'driverAssignmentStatus' : driverAssignmentStatus,
                'reservationStatus': serviceItem.reservationStatus,
                'flightNumber': '-',
                'rawData': serviceItem

            });
        });

        let summaryCards = this.summaryCardService.getContentCardsByConfig(this.tServiceItems, reservationMap);
        this.spinnerService.hide();
        return summaryCards;
    }

    getLeadPassengerName(passengers: ServicePassenger[]) {
        let leadPassenger = this.getLeadPassenger(passengers);
        if (leadPassenger) {
            return leadPassenger.firstName + ' ' + leadPassenger.lastName;
        } else {
            return '';
        }
    }

    private generateFooterDataMap() {
        if (this.serviceItemsInSelectedGroup) {
            this.footerDataMap.set(0, {
                captionText: 'Created on ' + '21/12/2018',
                bodyText: ''
            });

            this.footerDataMap.set(1, {
                captionText: 'Modified on ' + '21/12/2018',
                bodyText: ''
            });
        }
    }

    private loadResultsHeader(trfModes: string[]) {
        if (trfModes !== null) {
            const x = this.dataStore.get(DataKey.transferModes).subscribe((data) => {
                if (data !== null) {
                    this.transferModes = [];
                    let searchResultModes = data.filter(tm => trfModes.includes(tm.code));
                    if (!this.searchCriteria.transferMode) {
                        this.transferModes.push({'code': null, 'value': 'All Transfer Modes', 'isSelected': true});
                        searchResultModes.forEach(val => {
                            this.transferModes.push({'code': val, 'value': val.name, 'isSelected': false});
                        });
                    } else {
                        this.transferModes.push({'code': null, 'value': 'All Transfer Modes', 'isSelected': false});
                        searchResultModes.forEach(val => {
                            if (this.searchCriteria.transferMode === val.code) {
                                this.transferModes.push({'code': val, 'value': val.name, 'isSelected': true});
                            } else {
                                this.transferModes.push({'code': val, 'value': val.name, 'isSelected': false});
                            }
                        });
                    }

                } else {
                    this.transferModes = [];
                }
            });
            this.trsReservationManagementService.getGroupByOptions().subscribe(data => {
                if (data !== null) {
                    this.groupingOptions = [];
                    this.groupingOptionsRowData = ResponseUtil.getDataArray<TrsItemGroupTimeRange>(data);
                    if (!this.searchCriteria.groupByType) {
                        this.groupingOptionsRowData.forEach(val => {
                            if (val.id == 5) {
                                this.groupingOptions.push({'code': val.id, 'value': val.name, 'isSelected': true});
                            } else {
                                this.groupingOptions.push({'code': val.id, 'value': val.name, 'isSelected': false});
                            }
                        });
                    } else {
                        this.groupingOptionsRowData.forEach(val => {
                            if (val.id == this.searchCriteria.groupByType) {
                                this.groupingOptions.push({'code': val.id, 'value': val.name, 'isSelected': true});
                            } else {
                                this.groupingOptions.push({'code': val.id, 'value': val.name, 'isSelected': false});
                            }
                        });
                    }

                } else {
                    this.groupingOptions = [];
                }
            });
            // this.groupingOptions.push({'code': 'oneHour', 'value': '9.00-10-00', 'isSelected': true});
        }
    }

    onTransferModeSelect(event) {
        if (event !== null) {
            this.searchCriteria.transferMode = event.code;
        } else {
            this.searchCriteria.transferMode = event;
        }
        this.search(this.searchCriteria);
    }

    onGroupingTypeSelect(event) {
        this.searchCriteria.groupByType = event;

        let groupOption = this.groupingOptionsRowData.find(
            option => option.id === event
        );
        // todo set start time for INTERVAL grouping
        this.searchCriteria.filterStartTime = this.utilService.localTimeString(6, 0);

        this.searchCriteria.interval = groupOption['duration'];
        this.searchCriteria.groupBy = groupOption.type;
        if (this.searchCriteria.groupBy === 'RANGE') {
            this.searchCriteria.filterEndTime = this.utilService.localTimeString(
                groupOption['end'][0],
                groupOption['end'][1]
            );
            this.searchCriteria.filterStartTime = this.utilService.localTimeString(
                groupOption['start'][0],
                groupOption['start'][1]
            );
        }

        this.search(this.searchCriteria);
    }

    viewAutoAssignSummary() {
        this.popupDataObserver();
        this.dataStore.set(DataKey.popupClose, null);
        let dataObject = new ModalData(
            LEVEL.SUCCESS,
            'Auto Assigned Summary',
            null,
            null,
            'modal-basic--alt modal-basic--fixed-size',
            AutoAssignedSummaryWrapperComponent,
            {
                'assignedResources': this.autoAssignResults
            }
        );
        dataObject.disableClose = false;
        this.confirmModal(dataObject);
        let modalSubscription = this.dataStore.get(DataKey.popupClose).subscribe((data) => {
            if (data != null) {
                modalSubscription.unsubscribe();
                this.closeModal();
            }
            if (this.isPopupDataChanged) {
                this.retrieveSelectedGroup();
            }
        });
    }

    onLeadPassengerClick(serviceItemData: SummaryCardData) {
        let selectedCount = 0;
        this.serviceItemChangesInSelectedGroup.forEach(value => {
            if (value.checked) {
                selectedCount++;
            }
        });
        if (selectedCount < 2) {
            let serviceItem: ServiceItem = serviceItemData.data.value['rawData'];
            const reservationStatus = serviceItemData.data.value['reservationStatus'];
            if (reservationStatus === ReservationStatus.UPDATED) {
                const updatedWarning = new DialogModel(
                    true,
                    LEVEL.ERROR,
                    'Invalid Attempt',
                    'Cannot split updated old reference item',
                    true,
                    2000
                );
                updatedWarning.disableClose = true;
                this.confirmDialog(updatedWarning);
            } else if (reservationStatus === ReservationStatus.CANCELLED) {
                const updatedWarning = new DialogModel(
                    true,
                    LEVEL.ERROR,
                    'Invalid Attempt',
                    'Cannot split cancelled item',
                    true,
                    2000
                );
                updatedWarning.disableClose = true;
                this.confirmDialog(updatedWarning);
            } else if (serviceItem.assignment
                && this.isRestricted(this.serviceItemChangesInSelectedGroup.get(serviceItem.serviceItemId))) {
                let invalidAttemptWarning = new DialogModel(
                    true,
                    LEVEL.WARNING,
                    'Invalid Attempt',
                    'Cannot change lead passenger of service items in confirm state',
                    true,
                    2000
                );
                invalidAttemptWarning.disableClose = true;
                this.confirmDialog(invalidAttemptWarning);
            } else {
                this.popupDataObserver();
                let servicePassengers: ServicePassenger[] = serviceItem.servicePassengers;
                this.dataStore.set(DataKey.popupClose, null);
                let dataObject = new ModalData(
                    LEVEL.SUCCESS,
                    'Select Lead Passenger',
                    null,
                    null,
                    'add-service-menu',
                    LeadPassengerSelectionWrapperComponent,
                    {
                        'passengers': servicePassengers,
                        'leadPassenger': this.getLeadPassenger(servicePassengers),
                        'serviceItemId': serviceItem.serviceItemId
                    }
                );
                dataObject.disableClose = true;
                this.confirmModal(dataObject);

                this.leadPassengerModalCloseSubscription = this.dataStore.get(DataKey.popupClose).subscribe((data) => {
                    if (data != null) {
                        this.leadPassengerModalCloseSubscription.unsubscribe();
                        this.closeModal();
                    }
                    if (this.isPopupDataChanged) {
                        this.retrieveSelectedGroup();
                    }
                });
            }

        } else {
            let invalidAttemptWarning = new DialogModel(
                true,
                LEVEL.ERROR,
                'Invalid Attempt',
                'Please proceed without selecting multiple service items',
                true,
                2000
            );
            invalidAttemptWarning.disableClose = true;
            this.confirmDialog(invalidAttemptWarning);
        }

    }

    private getLeadPassenger(passengers: ServicePassenger[]): ServicePassenger {
        let leadPassenger = passengers.find(passenger => {
            return passenger.lead;
        });
        return leadPassenger;
    }

    onSplitClick(data: any) {
        let serviceItemData = data.summary;
        let isSaved = data.isSaved;
        this.popupDataObserver();
        let serviceItem = serviceItemData.data.value['rawData'];
        let serviceAssignmentList: ServiceAssignment[] = [];
        this.serviceItemChangesInSelectedGroup.get(serviceItem.serviceItemId).serviceAssignments
            .forEach(value => serviceAssignmentList.push(value));
        const reservationStatus = serviceItemData.data.value['reservationStatus'];
        if (reservationStatus === ReservationStatus.UPDATED) {
            const updatedWarning = new DialogModel(
                true,
                LEVEL.ERROR,
                'Invalid Attempt',
                'Cannot split updated old reference item',
                true,
                2000
            );
            updatedWarning.disableClose = true;
            this.confirmDialog(updatedWarning);
        } else if (reservationStatus === ReservationStatus.CANCELLED) {
            const updatedWarning = new DialogModel(
                true,
                LEVEL.ERROR,
                'Invalid Attempt',
                'Cannot split cancelled item',
                true,
                2000
            );
            updatedWarning.disableClose = true;
            this.confirmDialog(updatedWarning);
        } else if (!isSaved) {
            let unsavedDataWarning = new DialogModel(
                true,
                LEVEL.WARNING,
                'Invalid Attempt',
                'Cannot split item in unsaved state',
                true,
                2000
            );
            unsavedDataWarning.disableClose = true;
            this.confirmDialog(unsavedDataWarning);

        } else if (serviceItem.assignment
            && this.isRestricted(this.serviceItemChangesInSelectedGroup.get(serviceItem.serviceItemId))) {
            let invalidAttemptWarning = new DialogModel(
                true,
                LEVEL.WARNING,
                'Invalid Attempt',
                'Cannot split service items in confirm state',
                true,
                2000
            );
            invalidAttemptWarning.disableClose = true;
            this.confirmDialog(invalidAttemptWarning);

        } else if (this.serviceItemChangesInSelectedGroup.get(serviceItem.serviceItemId) &&
            serviceAssignmentList &&
            ServiceAssignmentUtilService.isAssignedAny(serviceAssignmentList)) {
            let invalidAttemptWarning = new DialogModel(
                true,
                LEVEL.WARNING,
                'Invalid Attempt',
                'Cannot split service items with Assignments',
                true,
                2000
            );
            invalidAttemptWarning.disableClose = true;
            this.confirmDialog(invalidAttemptWarning);

        } else if (serviceItem.servicePassengers &&
            serviceItem.servicePassengers.length < 2) {
            let invalidAttemptWarning = new DialogModel(
                true,
                LEVEL.WARNING,
                'Invalid Attempt',
                'Cannot split service items with passengers less than 2',
                true,
                2000
            );
            invalidAttemptWarning.disableClose = true;
            this.confirmDialog(invalidAttemptWarning);
        } else if (serviceItem.servicePassengers) {
            this.dataStore.set(DataKey.popupClose, null);
            let dataObject = new ModalData(
                LEVEL.SUCCESS,
                'Split Passengers into Groups',
                null,
                null,
                'split-passengers-modal',
                SplitPassengersViewWrapperComponent,
                {'serviceItem': serviceItem}
            );
            dataObject.disableClose = true;
            this.confirmModal(dataObject);

            this.splitPassengersModalCloseSubscription = this.dataStore.get(DataKey.popupClose).subscribe((data) => {
                this.autoAssignResults = [];
                if (data != null) {
                    this.splitPassengersModalCloseSubscription.unsubscribe();
                    this.closeModal();
                }
                if (this.isPopupDataChanged) {
                    this.retrieveSelectedGroup();
                }
            });
        }
    }

    popupDataObserver() {
        this.popupDataChangeObserver = this.dataStore.get(DataKey.popupDataChanged).subscribe(isDataChanged => {
            if (isDataChanged) {
                this.popupDataChangeObserver.unsubscribe();
            }
            this.isPopupDataChanged = isDataChanged;
        });
    }

    onMoreClick($event: any) {
        let selectedIndex_ = 0;
        if ($event && $event.item) {
            this.selectedServiceItem = $event.item.data.value['rawData'];
            if (this.isRestricted(this.serviceItemChangesInSelectedGroup.get(this.selectedServiceItem.serviceItemId))) {
                let invalidAttemptWarning = new DialogModel(
                    true,
                    LEVEL.WARNING,
                    'Invalid Attempt',
                    'Cannot assign to service items in confirm state',
                    true,
                    2000
                );
                invalidAttemptWarning.disableClose = true;
                this.confirmDialog(invalidAttemptWarning);
            } else {
                let isVehicleAssignment: boolean = false;
                let isDriverAssignment: boolean = false;
                if ($event.event === ResourceType.vehicle) {
                    isVehicleAssignment = true;
                } else if ($event.event === ResourceType.driver) {
                    isDriverAssignment = true;
                }
                let previousAssignmentDetail = this.selectedServiceItem.serviceAssignments.find(value => value.categoryCode ===
                    $event.event);

                let input = {
                    'isVehicleAssignment': isVehicleAssignment,
                    'isDriverAssignment': isDriverAssignment,
                    'selectedIndex': selectedIndex_,
                    'bookingData': this.selectedServiceItem,
                    'previousAssignment': previousAssignmentDetail,
                    'serviceItemChangesInSelectedGroup': this.serviceItemChangesInSelectedGroup
                };

                let passengerCount = this.getPassengerCount(input);
                let serviceTime = moment(this.selectedServiceItem.trsServiceItem.departureDateTime).format('HH:mm');
                let pickupDropOff = this.selectedServiceItem.trsServiceItem.pickupPointName + '-' +
                    this.selectedServiceItem.trsServiceItem.dropoffPointName;
                let secondaryHeader = this.selectedServiceItem.bookingReferenceId + ' | Pax: ' + passengerCount +
                    ' | ' +
                    serviceTime + ' | ' + pickupDropOff;

                // this.setSupplierSearchCriteriatoUrl(false, this.selectedServiceItem);

                this.openResourceAssignmentView(input, secondaryHeader);
            }
        }

    }

    public onBulkDriverAssignment() {
        this.onBulkAssignment(false, true);
    }

    public onBulkVehicleAssignment() {
        this.onBulkAssignment(true, false);
    }

    public onBulkAssignment(isVehicleAssignment: boolean, isDriverAssignment: boolean) {
        let selectedIndex_ = 0;
        let bookingDataArray = [];
        this.serviceItemChangesInSelectedGroup.forEach(value => {
            if (value.checked) {
                bookingDataArray.push(this.serviceItemsInSelectedGroupCards.find(
                    itemCard => itemCard.data.value.serviceItemId === value.serviceItemId
                    ).data.value
                );
            }
        });
        let input = {
            'isVehicleAssignment': isVehicleAssignment,
            'isDriverAssignment': isDriverAssignment,
            'selectedIndex': selectedIndex_,
            'bookingDataArray': bookingDataArray,
            'serviceItemChangesInSelectedGroup': this.serviceItemChangesInSelectedGroup,
            'isBulkAssignment': true
        };
        let passengerCount = this.getPassengerCount(input);
        let secondaryHeader = '[Bulk] | Pax: ' + passengerCount + ' | ' + this.selectedGroupCriteria.serviceStartTime +
            '-' + this.selectedGroupCriteria.groupEndTime;

        // this.setSupplierSearchCriteriatoUrl(true, bookingDataArray);

        this.openResourceAssignmentView(input, secondaryHeader);
    }

    openResourceAssignmentView(input: any, secondaryHeader: string) {
        input.itemGroup = this.selectedServiceItemGroup;
        input.assignmentList = this.unSavedAssignments;
        this.getResourceAssignedStatus();
        setTimeout(() => {
            let fabActions = [];

            let navItemsArray = this.configLoader.configurations.get(TCO.CONF.CONF_NAVIGATION);
            this.configLoader.configurations.get(TCO.CONF.CONF_FOCUS_VIEW).ALLOCATION_FOCUS_VIEW.fabAction
                .forEach(val => {
                    for (let key in navItemsArray) {
                        if (val === navItemsArray[key]['id']) {
                            fabActions.push(navItemsArray[key]);
                        }
                    }
                });
            let dataObject = new ModalData(
                LEVEL.SUCCESS,
                'Assign Supplier/Resource',
                null,
                null,
                '',
                AssignmentFocusViewComponent,
                input, {'label': 'close'}, '', '', fabActions, '', secondaryHeader
            );
            this.focusviewCloseSubject = this.dataStore.get(DataKey.supplierCostFocusViewClose).subscribe(data => {

                this.autoAssignResults = [];
                if (data != null) {
                    this.focusviewCloseSubject.unsubscribe();
                    this.closeFocusView();
                }
            });

            this.focusViewService.confirm(dataObject).subscribe(res => {
                if (res) {
                    var e = document.getElementsByClassName('header-content__secondary-heading');
                    console.log(e);
                }
            });
        }, 0);
        /*this.dataStore.set(DataKey.popupClose, null);
         let dataObject = new ModalData(
         LEVEL.SUCCESS,
         'Assign Supplier/Resource',
         null,
         null,
         'assign-panel-popup-modal',
         ResourceAssignmentFocusViewComponent,
         input, '', '', '', '', '', ''
         );
         dataObject.disableClose = true;

         let secondaryHeader = this.getPassengerCount(input);

         dataObject.secondaryHeader = '  (' + secondaryHeader + ' Passengers)';
         this.confirmModal(dataObject);

         this.resourceAssignModalCloseSubscription = this.dataStore.get(DataKey.popupClose).subscribe((data) => {
         this.autoAssignResults = [];
         if (data != null) {
         this.resourceAssignModalCloseSubscription.unsubscribe();
         this.closeModal();
         }
         });*/
    }

    private getPassengerCount(input) {
        if (input.isBulkAssignment) {
            let count = 0;
            input.bookingDataArray.forEach(data => {
                count += data.passengerCount;
            });
            return count;
        } else {
            return input.bookingData.servicePassengers.length;
        }
    }

    setSupplierSearchCriteriatoUrl(isBulkAssignment: boolean, bookingData: any) {
        let supplierSearchCriteria = new SupplierSearchCriteria();
        supplierSearchCriteria.resourceTypes = [];
        supplierSearchCriteria.resourceTypes.push(ResourceType.vehicle);
        supplierSearchCriteria.status = 'true';

        if (isBulkAssignment) {
            supplierSearchCriteria.transferMode = bookingData[0]['rawData'].trsServiceItem.transferMode;
            supplierSearchCriteria.route = bookingData[0]['rawData'].trsServiceItem.route;
            supplierSearchCriteria.serviceDate = bookingData[0]['rawData'].serviceDate;
        } else {
            supplierSearchCriteria.transferMode = bookingData.trsServiceItem.transferMode;
            supplierSearchCriteria.route = bookingData.trsServiceItem.route;
            supplierSearchCriteria.serviceDate = bookingData.serviceDate;
        }

        let parameters = this.queryParamService.getQueryParamsArray(supplierSearchCriteria);
        this.rootService.setDataToLocalStorage(TCO.AppData.SUPPLIER_SEARCH_CRITERIA, supplierSearchCriteria, true, parameters);
    }

    getResourceAssignedStatus() {
        this.dataStore.get(DataKey.resourceAssignedStatus).subscribe(data => {
            if (data) {
                this.changesAvailableForServiceItems = true;
            }
        });
    }

    private confirmModal(data: any): void {
        this.modalService.confirm(data).subscribe((res) => {
        });
    }

    private confirmDialog(data: any): void {
        this.dialogService.confirm(data).subscribe((res) => {
        });
    }

    closeModal() {
        if (this.modalService.close()) {
            this.modalService.close().subscribe(
                (res) => {
                }
            );
        }
    }

    closeFocusView() {
        if (this.focusViewService.close()) {
            this.focusViewService.close().subscribe(
                (res) => {
                }
            );
        }
    }

    grabRowDataList(summaryCardDataList: SummaryCardData[]): any[] {
        let serviceItems = [];
        summaryCardDataList.forEach(
            summaryCard => {
                if (summaryCard.data.value['rawData']) {
                    serviceItems.push(summaryCard.data.value['rawData']);
                }
            }
        );
        return serviceItems;
    }

    getAssignmentsFromMap(): ServiceAssignment[] {
        let assignmentInfoList = [];
        this.exit = false;
        let hasAssignmentsToSaveForItem = false;
        this.serviceItemChangesInSelectedGroup.forEach(value => {
            hasAssignmentsToSaveForItem = false;
            if (value.serviceAssignments) {
                value.serviceAssignments.forEach(assignment => {
                    if (assignment.serviceItemId > 0 && assignment.updated &&
                        assignment.assignStatus !== AssignmentStatus.DMC_CONFIRM &&
                        assignment.assignStatus !== AssignmentStatus.SUPPLIER_CONFIRMED) {
                        if (assignment.tempAssignmentId && isNaN(assignment.tempAssignmentId)) {
                            assignment.tempAssignmentId = assignment.tempAssignmentId.split('_') ?
                                assignment.tempAssignmentId.split('_')[1] :
                                this.identifierService.getNextManuallyResetId();
                        }
                        assignment.assignStatus = assignment.assignStatus ?
                            assignment.assignStatus :
                            AssignmentStatus.PENDING;
                        assignmentInfoList.push(assignment);
                        hasAssignmentsToSaveForItem = true;
                    }
                });
                if (hasAssignmentsToSaveForItem) {
                    this.savedServiceItems.push(value.serviceItemId);
                }
            }
        });
        return assignmentInfoList;
    }

    public isRestricted(value: ServiceItemChanges) {
        if (value.serviceAssignments) {
            value.serviceAssignments.forEach(assignment => {
                if (assignment.assignStatus === AssignmentStatus.DMC_CONFIRM ||
                    assignment.assignStatus === AssignmentStatus.SUPPLIER_CONFIRMED) {
                    return true;
                }
            });
        }
        return false;
    }

    public onConfirmClick($event: any) {
        let assignmentIds = [];

        this.serviceItemChangesInSelectedGroup.forEach(entry => {
            if (entry.checked && entry.serviceAssignments) {
                let serviceAssignmentList = [];
                entry.serviceAssignments.forEach(value => serviceAssignmentList.push(value));
                for (const data of serviceAssignmentList) {
                    if (data.assignStatus !== AssignmentStatus.DMC_CONFIRM ||
                        data.assignStatus !== AssignmentStatus.SUPPLIER_CONFIRMED && data.assignStatus !==
                        AssignmentStatus.INACTIVE_NOTIFY) {
                        assignmentIds.push(data.assignmentId);
                    }
                }
            }
        });

        if (assignmentIds.length > 0) {

            let confirmSuccess = new DialogModel(
                true,
                LEVEL.WARNING,
                'Confirm Assignments',
                `
                        Confirming this action will trigger the following.<br><br> 

                        1. Sending manifests to the allocated Suppliers/ Resources.<br> 
                        2. Creating accounting ledger entries.<br><br> 

                        Once confirmed this action cannot be undone.<br>  
                        Do you wish to proceed?`,
                true,
                2000,
                null,
                'No',
                'Yes',
                true
            );
            confirmSuccess.disableClose = true;
            this.dialogService
                .confirm(confirmSuccess)
                .subscribe((res) => {
                    if (res === true) {
                        this.isRequestCompleted = false;
                        this.reservationManagementService.confirmAssignments(assignmentIds).subscribe(
                            result => {

                                // refresh service items
                                this.retrieveSelectedGroup();
                                this.isRequestCompleted = true;
                                this.common.showSnackBar(
                                    'Successfully confirmed the assigned resources.',
                                    3000,
                                    TcErrorType.TYPE.INFO
                                );
                                this.changesAvailableForServiceItems = false;
                            },
                            error => {
                                console.log(error);
                                this.isRequestCompleted = true;
                                let confirmError = new DialogModel(
                                    true,
                                    LEVEL.ERROR,
                                    'Failed',
                                    'Failed to confirm the assigned resources',
                                    true,
                                    2000
                                );
                                this.dialogService
                                    .confirm(confirmError)
                                    .subscribe((res) => {
                                        if (res === true) {
                                            console.log('true');
                                        }
                                    });
                            }
                        );

                    }
                });

        }
    }

    public onSupplierRejectClick($event: any) {
        let assignmentIds = [];

        this.serviceItemChangesInSelectedGroup.forEach(entry => {
            if (entry.checked && entry.serviceAssignments) {
                let serviceAssignmentList = [];
                entry.serviceAssignments.forEach(value => serviceAssignmentList.push(value));
                for (const data of serviceAssignmentList) {
                    if (data.assignStatus === AssignmentStatus.SUPPLIER_CONFIRMED
                        || data.assignStatus === AssignmentStatus.DMC_CONFIRM) {
                        assignmentIds.push(data.assignmentId);
                    }
                }
            }
        });
        if (assignmentIds.length > 0) {

            let confirmSuccess = new DialogModel(
                true,
                LEVEL.WARNING,
                'Supplier Reject Assignments',
                `
                        Rejecting this action will trigger the following.<br><br> 

                        1. Removing Suppliers/ Resources from the assignment.<br> 
                        2. Sending manifests to the rejected Suppliers/ Resources.<br> 
                        3. Removing accounting ledger entries.<br><br> 

                        Once confirmed this action cannot be undone.<br>  
                        Do you wish to proceed?`,
                true,
                2000,
                null,
                'No',
                'Yes',
                true
            );
            confirmSuccess.disableClose = true;
            this.dialogService
                .confirm(confirmSuccess)
                .subscribe((res) => {
                    if (res === true) {
                        this.isRequestCompleted = false;
                        this.reservationManagementService.onSupplierRejectAssignments(assignmentIds).subscribe(
                            result => {

                                // refresh service items
                                this.retrieveSelectedGroup();
                                this.isRequestCompleted = true;
                                this.common.showSnackBar(
                                    'Successfully rejected the assigned resources.',
                                    3000,
                                    TcErrorType.TYPE.INFO
                                );
                                this.changesAvailableForServiceItems = false;
                            },
                            error => {
                                console.log(error);
                                this.isRequestCompleted = true;
                                let confirmError = new DialogModel(
                                    true,
                                    LEVEL.ERROR,
                                    'Failed',
                                    'Failed to confirm the assigned resources',
                                    true,
                                    2000
                                );
                                this.dialogService
                                    .confirm(confirmError)
                                    .subscribe((res) => {
                                        if (res === true) {
                                            console.log('true');
                                        }
                                    });
                            }
                        );

                    }
                });

        }
    }

    updateSummaryCard() {
        if (this.selectedSummaryCardFromGroups && this.selectedSummaryCardFromGroups.data) {

            const data = this.selectedSummaryCardFromGroups.data.value;

            let allocatedCount = 0;
            for (const item of this.serviceItemsInSelectedGroup) {
                if (this.isNotUpdatedOrCancelled(item)) {
                    allocatedCount += ServiceAssignmentUtilService.getTrsAllocatedPassengersCount(item);
                }
            }
            let allocationsLeft = data['passengerCount'] - allocatedCount;
            let groupStatus = ServiceAssignmentUtilService.getGroupStatus(this.serviceItemsInSelectedGroup);

            let groupDataFroCard = data;
            groupDataFroCard['allocatedCount'] = allocatedCount;
            groupDataFroCard['allocationsLeft'] = allocationsLeft;
            groupDataFroCard['groupStatus'] = groupStatus;

            let contentCardList = this.summaryCardService.getContentCardsByConfig(
                this.tReservations,
                [groupDataFroCard]
            );

            if (contentCardList && contentCardList.length > 0) {
                const summaryCardData: SummaryCardData = contentCardList[0];
                this.selectedSummaryCardFromGroups.data = summaryCardData.data;
                this.selectedSummaryCardFromGroups.icon = summaryCardData.icon;

                this.selectedSummaryCardFromGroups.row1col1 = summaryCardData.row1col1;
                this.selectedSummaryCardFromGroups.row1col2 = summaryCardData.row1col2;
                this.selectedSummaryCardFromGroups.row1col3 = summaryCardData.row1col3;
                this.selectedSummaryCardFromGroups.row1col4 = summaryCardData.row1col4;

                this.selectedSummaryCardFromGroups.row2col1 = summaryCardData.row2col1;
                this.selectedSummaryCardFromGroups.row2col2 = summaryCardData.row2col2;
                this.selectedSummaryCardFromGroups.row2col3 = summaryCardData.row2col3;
                this.selectedSummaryCardFromGroups.row2col4 = summaryCardData.row2col4;
            }
        }
    }

    isNotUpdatedOrCancelled(serviceItem: ServiceItem) {
        return (serviceItem.reservationStatus === null ||
            (serviceItem.reservationStatus !== ReservationStatus.UPDATED && serviceItem.reservationStatus !==
                ReservationStatus.CANCELLED));
    }

    getTransferModeCapacity() {
        return new Promise(resolve => {
            this.dataStore.get(DataKey.transferModes).subscribe((data) => {
                if (data !== null) {
                    let trsMode = data.filter(val => val.code === this.selectedServiceItem.trsServiceItem.transferMode);
                    resolve(trsMode[0].capacity);
                }
            });
        });
    }

    //Auto Assign Resources
    public onAutoAssignResources($event: any) {
        if (this.autoAssignResults && this.autoAssignResults.length > 0) {
            this.viewAutoAssignSummary();
        } else {
            let confirmSaveSplit = new DialogModel(
                true,
                LEVEL.WARNING,
                'Confirm Auto Assigning',
                'This operation might be override your previous unsaved changes. Do you want to proceed?',
                true,
                2000,
                null,
                'No',
                'Yes'
            );
            confirmSaveSplit.disableClose = true;
            this.dialogService
                .confirm(confirmSaveSplit)
                .subscribe((res) => {
                        if (res === true) {
                            this.isRequestCompleted = false;
                            this.spinnerService.show();
                            this.getAssignedResources($event);
                        }
                    }
                );

        }
    }

    private getAssignedResources(summaryCardData: SummaryCardData) {
        let selectedServiceItemGroup = summaryCardData.data.value['rawData'];
        let selectedGroupCriteria = new ReservationSearchCriteria();
        selectedGroupCriteria.start = 0;
        selectedGroupCriteria.route = selectedServiceItemGroup.route;
        selectedGroupCriteria.transferMode = selectedServiceItemGroup.transferMode;
        selectedGroupCriteria.date = this.selectedGroupCriteria.date;
        selectedGroupCriteria.serviceStartTime = selectedServiceItemGroup.startTime;
        selectedGroupCriteria.groupEndTime = selectedServiceItemGroup.endTime;
        this.trsReservationManagementService.searchRecommendedAssignments(selectedGroupCriteria)
            .subscribe(
                result => {
                    if (result) {
                        try {
                            this.autoAssignResults = [];
                            let vehicles = ResponseUtil.getDataArray<VehicleSummary>(result);
                            for (let i = 1; i < vehicles.length; i++) {
                                let summary = vehicles[0][i - 1].resource;
                                let item = vehicles[i];
                                let refServiceItem = vehicles[0][i - 1].references ?
                                    vehicles[0][i - 1].references.SERVICE_ITEM :
                                    new ServiceItem();
                                let summaryObject;
                                if (summary.id != -11) {
                                    summaryObject = {
                                        serviceItem: item[2],
                                        passengerCount: item[3],
                                        resource: summary.name,
                                        capacity: summary.capacity,
                                        cost: this.getTotalCost(item[0].trsSupplier, refServiceItem, summary.capacity),
                                        supplier: item[0].trsSupplier.name,
                                        currency: item[0].trsSupplier.currency
                                    };
                                    let event = new ResourceAllocationEvent(
                                        item[1],
                                        null,
                                        item[0],
                                        null,
                                        null
                                    );
                                    this.changeDetector.notifyChange();
                                    this._onSelectVehicle(event);
                                    this.isRequestCompleted = true;
                                } else {
                                    summaryObject = {
                                        serviceItem: item[2],
                                        passengerCount: item[3],
                                        resource: 'Not Assigned',
                                        capacity: 'Not Available',
                                        cost: 'Not Available',
                                        supplier: 'Not Available',
                                        currency: item[0].trsSupplier.currency
                                    };
                                }

                                this.autoAssignResults.push(summaryObject);

                            }
                            this.spinnerService.hide();
                            this.viewAutoAssignSummary();
                            // this.dataStore.set(DataKey.reservationSelectedGroupData, result, true);
                        } catch (e) {
                            this.spinnerService.hide();
                            this.common.showSnackBar('Client side data processing error', 4000, TcErrorType.TYPE.ERROR);
                        }
                    }
                }
                , error => {
                    this.spinnerService.hide();
                    this.isRequestCompleted = true;
                    this.common.showSnackBar(error.error, 4000, TcErrorType.TYPE.ERROR);
                }
            );

    }

    private getTotalCost(resourceCost: any, serviceItem: ServiceItem, resourceCapacity) {
        if (resourceCost.adultFare != null && resourceCost.adultFare > 0) {
            return (resourceCost.adultFare * serviceItem.adult) + (resourceCost.childFare * serviceItem.child) +
                (resourceCost.infantFare * serviceItem.infant);
        } else if (resourceCost.unitFare != null && resourceCost.unitFare > 0) {
            return resourceCapacity > 0 ?
                (resourceCost.unitFare / resourceCapacity) * serviceItem.servicePassengers.length :
                resourceCost.unitFare;
        }
        return 0;
    }

    public onVehicleFocus($event: ResourceAllocationSearchEvent) {
        this.onVehicleTextChange($event);
    }

    public onSaveAssignments($event: any) {

        let assignmentsAddedModel = new DialogModel(
            true,
            LEVEL.WARNING,
            'Save Changes',
            'Do you want to save assignments',
            true,
            2000,
            null,
            'No',
            'Yes'
        );
        let assignmentsClearedModel = new DialogModel(
            true,
            LEVEL.WARNING,
            'Save Changes',
            'Do you want to save Changes',
            true,
            2000,
            null,
            'No',
            'Yes'
        );
        assignmentsAddedModel.disableClose = true;
        if (this.isCleared) {
            let message = 'Successfully changed the resources';
            this.saveAssignmentsChangesToBackend(assignmentsClearedModel, message);
        } else {
            let message = 'Successfully assigned the resources';
            this.saveAssignmentsChangesToBackend(assignmentsAddedModel, message);
        }

    }

    private saveAssignmentsChangesToBackend(dialogModel: DialogModel, message: string) {
        this.exit = true;
        this.dialogService
            .confirm(dialogModel)
            .subscribe((res) => {
                if (res === true) {
                    let assignments: ServiceAssignment[] = this.getAssignmentsFromMap();
                    this.isRequestCompleted = false;
                    this.trsReservationManagementService.saveAssignments(assignments).subscribe(
                        result => {
                            this.unSavedAssignments = [];

                            // refresh service items
                            this.retrieveSelectedGroup(false);

                            this.common.showSnackBar(message, 3000, TcErrorType.TYPE.INFO);
                            this.unSavedDataAvailable = false;
                            this.isRequestCompleted = true;
                            this.autoCompleteClicked = false;
                            this.changesAvailableForServiceItems = false;
                            this.isCleared = false;
                        },
                        error => {
                            console.log(error);

                            // refresh service items
                            this.retrieveSelectedGroup();
                            let confirmError = new DialogModel(
                                true,
                                LEVEL.ERROR,
                                'Failed',
                                error.error ? error.error.message : '',
                                true,
                                2000
                            );
                            this.dialogService
                                .confirm(confirmError)
                                .subscribe((res) => {
                                    this.isRequestCompleted = true;
                                    if (res === true) {
                                    }
                                });
                        }
                    );
                }
            });
    }

    public onClickMerge() {
        let confirmSaveSplit = new DialogModel(
            true,
            LEVEL.WARNING,
            'Confirm Merge',
            'Do you want to merge the split items',
            true,
            2000,
            null,
            'No',
            'Yes'
        );
        confirmSaveSplit.disableClose = true;
        this.dialogService
            .confirm(confirmSaveSplit)
            .subscribe((res) => {
                    if (res === true) {
                        console.log('merge selected');
                        this.isRequestCompleted = false;
                        let selectedServiceItemIds = this.getSelectedServiceItems();
                        if (selectedServiceItemIds.length > 1) {
                            this.trsReservationManagementService.mergeServiceItems(selectedServiceItemIds).subscribe(
                                result => {
                                    this.isRequestCompleted = true;

                                    // refresh result again
                                    this.retrieveSelectedGroup();
                                    this.common.showSnackBar(
                                        'Successfully merged service items.',
                                        3000,
                                        TcErrorType.TYPE.INFO
                                    );
                                },
                                error => {
                                    console.log(error);
                                    this.isRequestCompleted = true;
                                    let confirmError = new DialogModel(
                                        true,
                                        LEVEL.ERROR,
                                        'Failed',
                                        `${error.error.message}`,
                                        true,
                                        2000
                                    );
                                    this.dialogService
                                        .confirm(confirmError)
                                        .subscribe((res) => {
                                            if (res === true) {
                                                console.log('true');
                                                /* this.dataStore.set(DataKey.cart, null, true);
                                                 this.spinnerService.show();
                                                 */
                                            }
                                        });
                                }
                            );
                        }
                    }
                }
            );
    }

    private getSelectedServiceItems(): number[] {
        let selectedList: number[] = [];
        this.serviceItemChangesInSelectedGroup.forEach(value => {
            if (value.checked) {
                selectedList.push(value.serviceItemId);
            }
        });
        return selectedList;
    }

    mapItemSearchCriteriaToDocumentCriteria(documentCriteria: DocumentDataCriteria) {
        documentCriteria.searchStartDate = this.searchCriteria.startDate;
        documentCriteria.searchEndDate = this.searchCriteria.endDate;
        // documentCriteria.size = this.searchCriteria.size;
        if (this.searchCriteria.bookingDate) {
            documentCriteria.bookingDate = this.searchCriteria.bookingDate;
        }
        if (this.searchCriteria.daysFrom) {
            documentCriteria.daysFrom = this.searchCriteria.daysFrom;
        }
        if (this.searchCriteria.resourceType) {
            documentCriteria.categoryCode = this.searchCriteria.resourceType;
        }
        if (this.searchCriteria.assignedStatus) {
            documentCriteria.assignStatus = this.searchCriteria.assignedStatus;
        }
        if (this.searchCriteria.supplierId) {
            documentCriteria.servicingSupplierId = this.searchCriteria.supplierId;
        }
        if (this.searchCriteria.resourceIdList) {
            documentCriteria.resourceIdList = this.searchCriteria.resourceIdList;
        }
        documentCriteria.route = this.searchCriteria.route;
        documentCriteria.dropOffLocation = this.searchCriteria.dropOffLocationCode;
        documentCriteria.pickupLocation = this.searchCriteria.pickUpLocationCode;
        documentCriteria.dropOffCity = this.searchCriteria.dropOffCity;
        documentCriteria.dropOffType = this.searchCriteria.dropOffType;
        documentCriteria.pickupCity = this.searchCriteria.pickupCity;
        documentCriteria.pickupType = this.searchCriteria.pickupType;
        documentCriteria.bookingRefId = this.searchCriteria.bookingRefId;
        documentCriteria.transferType = this.searchCriteria.transferType;
        documentCriteria.transferMode = this.searchCriteria.transferMode;
        documentCriteria.deptFltNumber = this.searchCriteria.flightDeparture;
        documentCriteria.arrFltNumber = this.searchCriteria.flightArrival;
        documentCriteria.arrTerminal = this.searchCriteria.flightArrTerminal;
        documentCriteria.arrTime = this.searchCriteria.flightArrivalTime;
        documentCriteria.deptTime = this.searchCriteria.flightDepartureTime;
        documentCriteria.deptTerminal = this.searchCriteria.flightDepTerminal;
        documentCriteria.serviceItemAttributes = this.searchCriteria.serviceItemAttributes;

    }

    public addGroupNote() {
        if (this.selectedServiceGroupNotes) {
            let serviceNote = new ServiceNote();
            serviceNote.tempId = this.addingServiceNoteCount++;
            this.selectedServiceGroupNotes.push(serviceNote);
        } else {
            this.selectedServiceGroupNotes = [];
            let serviceNote = new ServiceNote();
            serviceNote.tempId = this.addingServiceNoteCount++;
            this.selectedServiceGroupNotes.push(serviceNote);
        }
    }

    public addEmptyGroupNote() {
        if (this.selectedServiceGroupNotes === undefined || this.selectedServiceGroupNotes.length == 0) {
            let serviceNote = new ServiceNote();
            serviceNote.tempId = this.addingServiceNoteCount++;
            this.selectedServiceGroupNotes.push(serviceNote);
        }
    }

    public onDelete(event: ServiceNote) {
        if (event.tempId) {
            this.selectedServiceGroupNotes = this.selectedServiceGroupNotes.filter(value => {
                return value.tempId !== event.tempId;
            });
        }

        if (event.serviceNoteId) {
            this.deleteNote(event.serviceNoteId);
        }
    }

    deleteNote(serviceNoteId: number): void {
        let confirmDataObject = new DialogModel(
            true,
            LEVEL.WARNING,
            'Delete Note',
            'This Note will be deleted. Do you wish to proceed?',
            true,
            3000
        );
        confirmDataObject.disableClose = true;
        this.dialogService.confirm(confirmDataObject).subscribe(
            (res) => {
                if (res === true) {
                    this.deleteItemNote(serviceNoteId);
                }
            });
    }

    deleteItemNote(serviceNoteId: number) {
        this.trsReservationManagementService.deleteGroupNote(serviceNoteId).subscribe(
            result => {
                this.selectedServiceGroupNotes = this.selectedServiceGroupNotes.filter(value => {
                    return value.serviceNoteId !== serviceNoteId;
                });
                this.isGroupNoteEditClicked = false;

                this.common.showSnackBar('Successfully deleted the note', 3000, TcErrorType.TYPE.INFO);
                // refresh service items
                this.retrieveSelectedGroup();
            },
            error => {
                this.common.showSnackBar('Failed, because ' + error.error.message, 3000, TcErrorType.TYPE.ERROR);
            }
        );
    }

    public onNoteSave(event: ServiceNote) {
        event.createdOn = Date.now();
        event.type = 'SERVICE';
        let noteWrapper = new NoteWrapper();
        noteWrapper.serviceNotes = [];
        noteWrapper.serviceNotes.push(event);
        let groupCriteria = this.selectedGroupCriteria;
        for (let key in groupCriteria) {
            if (!groupCriteria[key]) {
                groupCriteria[key] = null;
            }
        }
        // if(!this.selectedGroupCriteria.assignedStatus){groupCriteria.assignedStatus = null;}
        noteWrapper.serviceItemSearchCriteria = groupCriteria;

        let serviceNotes = this.selectedServiceGroupNotes.filter(value => {
            return !(value.serviceNoteId > 0) && value.note !== event.note;
        });
        // this.dataStore.set(DataKey.selectedGroupNoteTemporaryData, serviceNotes, true);

        if (this.isGroupNoteEditClicked) {
            this.trsReservationManagementService.editGroupNote(event.serviceNoteId, event).subscribe(
                result => {

                    this.common.showSnackBar('Successfully edited the note', 3000, TcErrorType.TYPE.INFO);
                    // refresh service items
                    this.retrieveSelectedGroup();
                    this.isGroupNoteEditClicked = false;
                },
                error => {

                    this.common.showSnackBar('Failed, because ' + error.error.message, 3000, TcErrorType.TYPE.ERROR);
                    // refresh service items
                    this.retrieveSelectedGroup();
                    this.isGroupNoteEditClicked = false;
                }
            );
        } else {
            this.trsReservationManagementService.saveGroupNote(noteWrapper).subscribe(
                result => {

                    this.common.showSnackBar('Successfully saved the note', 3000, TcErrorType.TYPE.INFO);
                    // refresh service items
                    this.retrieveSelectedGroup();
                    this.isGroupNoteEditClicked = false;
                },
                error => {

                    this.common.showSnackBar('Failed, because ' + error.error.message, 3000, TcErrorType.TYPE.ERROR);
                    // refresh service items
                    this.retrieveSelectedGroup();
                    this.isGroupNoteEditClicked = false;
                }
            );
        }
    }

    public onEdit(event: boolean) {
        if (event === true) {
            this.isGroupNoteEditClicked = true;
        } else if (event === false) {
            this.isGroupNoteEditClicked = false;
        }
    }

    onViewMoreClick(data: any) {
        this.focusViewDataObserver();
        let fabActions = [];
        let navItemsArray = this.configLoader.configurations.get(TCO.CONF.CONF_NAVIGATION);
        this.configLoader.configurations.get('FOCUS_VIEW_CONFIG').RESERVATION_VIEW_MORE.fabAction
            .forEach(val => {
                for (let key in navItemsArray) {
                    if (val === navItemsArray[key]['id']) {
                        fabActions.push(navItemsArray[key]);
                    }
                }
            });

        let dataObject = new ModalData(
            LEVEL.SUCCESS, 'Service Item Details', null, null, 'price-breakdown-focus-view',
            ServiceItemDetailFocusViewComponent,
            {
                'selectedServiceItem': data

            },
            {'label': 'close'},
            '', '', fabActions, null,
            'Booking ID : ' + data.bookingId
        );
        dataObject.disableClose = true;
        this.focusViewService.confirm(dataObject).subscribe(
            res => {
                if (this.isFocusViewDataChanged) {
                    this.retrieveSelectedGroup();
                }
            }
        );
    }

    focusViewDataObserver() {
        this.focusViewDataChangeObserver = this.dataStore.get(DataKey.focusViewDataChanged).subscribe(isDataChanged => {
            if (isDataChanged) {
                this.focusViewDataChangeObserver.unsubscribe();
            }
            this.isFocusViewDataChanged = isDataChanged;
        });
    }

    public onCardScroll($event: number) {
        setTimeout(() => {
            this.autoScrollService.scrollToID(
                'clientDetailExp_' + $event, 'main-scroll-container');
        }, 0);
    }

    onSaveSearchCriteriaClick() {
        this.dataStore.set(DataKey.popupClose, null);
        let dataObject = new ModalData(
            LEVEL.SUCCESS,
            'Save Criteria',
            null,
            null,
            'modal-basic--alt modal-basic--fixed-size',
            SearchCriteriaSaveModalComponent,
            {'searchCriteria': this.searchCriteria}
        );
        dataObject.disableClose = true;

        let secondaryHeader = '';

        dataObject.secondaryHeader = secondaryHeader;
        this.confirmModal(dataObject);

        this.dataStore.get(DataKey.popupClose).subscribe((data) => {
            if (data != null) {
                this.closeModal();
            }
        });
    }

    private shouldCheckItemAfterSave(item: ServiceItem) {
        if (this.savedServiceItems.includes(item.serviceItemId)) {
            return item.serviceAssignments && ServiceAssignmentUtilService.isAssignedAnyTrs(item.serviceAssignments);
        }
        return false;
    }

    public ngOnDestroy(): void {
        if (this.reservationResultsSubscription) {
            this.reservationResultsSubscription.unsubscribe();
        }
        if (this.reservationResultsFromServiceSubscription) {
            this.reservationResultsFromServiceSubscription.unsubscribe();
        }
        if (this.reservationResultsFromServiceOnMoreSubscription) {
            this.reservationResultsFromServiceOnMoreSubscription.unsubscribe();
        }
        if (this.reservationSearchCriteriaSubscription) {
            this.reservationSearchCriteriaSubscription.unsubscribe();
        }
        if (this.splitPassengersModalCloseSubscription) {
            this.splitPassengersModalCloseSubscription.unsubscribe();
        }
        if (this.focusviewCloseSubject) {
            this.focusviewCloseSubject.unsubscribe();
        }
        if (this.focusViewDataChangeObserver) {
            this.focusViewDataChangeObserver.unsubscribe();
        }
        if (this.popupDataChangeObserver) {
            this.popupDataChangeObserver.unsubscribe();
        }
        if (this.vehicleResultObsv) {
            this.vehicleResultObsv.unsubscribe();
        }
        if (this.selectedGroupItemsObserver) {
            this.selectedGroupItemsObserver.unsubscribe();
        }
        this.em.removeEvents([
            'EXPORT', 'SAVE_CRITERIA', 'SEND_MANIFESTS'
        ]);
    }

    ///////////// Smart key Assignment related operations ////////////////////////////////////

    //get search results

    // set this.filteredSuppliers
    public onVehicleSupplierTextChange($event) {
        let supplierSearchCriteria = this.getSupplierSearchCriteria($event);
        let serviceItem: ServiceItem = $event.serviceItem.data.value['rawData'];
        supplierSearchCriteria.resourceTypes = [];
        supplierSearchCriteria.resourceTypes.push(ResourceType.vehicle);
        supplierSearchCriteria.transferMode = serviceItem.trsServiceItem.transferMode;
        supplierSearchCriteria.route = serviceItem.trsServiceItem.route;
        supplierSearchCriteria.vehicleModel = serviceItem.trsServiceItem.vehicleModel ?
            serviceItem.trsServiceItem.vehicleModel :
            this.defaultVehicleModel;
        let previousAssignmentDetail = serviceItem.serviceAssignments.find(value => value.categoryCode ===
            ResourceType.vehicle);
        supplierSearchCriteria.rejectedSupplierIdList = previousAssignmentDetail ?
            previousAssignmentDetail.rejectedSupplierIdList :
            [];
        this.supplierService.searchSuppliersForAssignmentNoDataKey(supplierSearchCriteria)
            .subscribe(
                result => {
                    this.filteredVehicleSuppliers = ResponseUtil.getDataArray(result);
                }, error => {
                    this.filteredVehicleSuppliers = [];
                }
            );
    }

    public onDriverSupplierTextChange($event) {
        let serviceItem: ServiceItem = $event.serviceItem.data.value['rawData'];
        let supplierSearchCriteria = this.getSupplierSearchCriteria($event);
        supplierSearchCriteria.resourceTypes = [];
        supplierSearchCriteria.resourceTypes.push(ResourceType.driver);
        let previousAssignmentDetail = serviceItem.serviceAssignments.find(value => value.categoryCode ===
            ResourceType.driver);
        supplierSearchCriteria.rejectedSupplierIdList = previousAssignmentDetail ?
            previousAssignmentDetail.rejectedSupplierIdList :
            [];
        this.supplierService.searchDriverSuppliersForAssignmentNoDataKey(supplierSearchCriteria)
            .subscribe(
                result => {
                    this.filteredDriverSuppliers = ResponseUtil.getDataArray(result);
                }, error => {
                    this.filteredDriverSuppliers = [];
                }
            );
    }

    public getSupplierSearchCriteria($event: ResourceAllocationSearchEvent) {
        let codeOrName = '';
        if ($event.text !== undefined) {
            codeOrName = $event.text;
        }
        let serviceItem: ServiceItem = $event.serviceItem.data.value['rawData'];

        let supplierSearchCriteria = new SupplierSearchCriteria();
        supplierSearchCriteria.codeOrName = codeOrName;
        supplierSearchCriteria.status = 'true';
        supplierSearchCriteria.serviceDate = serviceItem.serviceDate;
        supplierSearchCriteria.start = 0;
        supplierSearchCriteria.size = 10;

        return supplierSearchCriteria;
    }

    public onVehicleTextChange($event: ResourceAllocationSearchEvent) {
        if ($event.text !== undefined ||
            (this.serviceItemChangesInSelectedGroup.get($event.serviceItemId).vehicleSupplier)) {

            let serviceItem: ServiceItem = $event.serviceItem.data.value['rawData'];

            let vehicleSearchCriteria = new VehicleSearchCriteria();
            vehicleSearchCriteria.activeStatus = true;
            vehicleSearchCriteria.vehicleName = $event.text;
            vehicleSearchCriteria.resourceType = ResourceType.vehicle;
            vehicleSearchCriteria.transferMode = serviceItem.trsServiceItem.transferMode;
            vehicleSearchCriteria.vehicleModel = serviceItem.trsServiceItem.vehicleModel ?
                serviceItem.trsServiceItem.vehicleModel :
                null;
            vehicleSearchCriteria.serviceDate = serviceItem.serviceDate;
            vehicleSearchCriteria.route = serviceItem.trsServiceItem.route;
            vehicleSearchCriteria.start = 0;
            vehicleSearchCriteria.size = 10;
            vehicleSearchCriteria.serviceItems = serviceItem.serviceItemId.toString();

            let previousAssignmentDetail = serviceItem.serviceAssignments.find(value => value.categoryCode ===
                ResourceType.vehicle);
            vehicleSearchCriteria.rejectedResourceIdList = previousAssignmentDetail ?
                previousAssignmentDetail.rejectedResourceIdList :
                [];

            // todo. check assignment map for assigned supplier for this serviceItem
            if (this.serviceItemChangesInSelectedGroup.get($event.serviceItemId).vehicleSupplier &&
                this.serviceItemChangesInSelectedGroup.get($event.serviceItemId).vehicleSupplier.supplierId) {
                vehicleSearchCriteria.supplierId = this.serviceItemChangesInSelectedGroup.get($event.serviceItemId).vehicleSupplier.supplierId;
            }
            this.trsReservationManagementService.searchRecommendedVehiclesWithDataKey(vehicleSearchCriteria);
            this.subscribeForVehicleSearchResults();
        } else {
            this.filteredVehicles = [];
        }
    }

    private subscribeForVehicleSearchResults() {
        this.vehicleResultObsv = this.dataStore.get(DataKey.resourceAssignVehicleSearchResults).subscribe(
            result => {
                if (result) {
                    if (result) {
                        this.filteredVehicles = ResponseUtil.getDataArray<ResourceAvailability>(result);
                    } else {
                        this.filteredVehicles = [];
                    }
                } else {
                    this.filteredVehicles = [];
                }
            }
            , error => {
                this.filteredVehicles = [];
            }
        );
    }

    public onDriverTextChange($event: ResourceAllocationSearchEvent) {
        if ($event.text !== undefined ||
            (this.serviceItemChangesInSelectedGroup.get($event.serviceItemId).driverSupplier)) {

            let serviceItem: ServiceItem = $event.serviceItem.data.value['rawData'];

            let driverSearchCriteria = new GenericSearchCriteria();
            driverSearchCriteria.routes = [];
            driverSearchCriteria.transferModes = [];
            driverSearchCriteria.activeStatus = 'true';
            driverSearchCriteria.name = $event.text;
            driverSearchCriteria.resourceType = ResourceType.driver;
            driverSearchCriteria.transferMode = serviceItem.trsServiceItem.transferMode;
            driverSearchCriteria.transferModes.push(serviceItem.trsServiceItem.transferMode);
            driverSearchCriteria.serviceDate = serviceItem.serviceDate;
            driverSearchCriteria.route = serviceItem.trsServiceItem.route;
            driverSearchCriteria.serviceItems = serviceItem.serviceItemId.toString();
            driverSearchCriteria.start = 0;
            driverSearchCriteria.size = 1000000;

            let previousAssignmentDetail = serviceItem.serviceAssignments.find(value => value.categoryCode ===
                ResourceType.driver);
            driverSearchCriteria.rejectedResourceIdList = previousAssignmentDetail ?
                previousAssignmentDetail.rejectedResourceIdList :
                [];

            // todo. check assignment map for assigned supplier for this serviceItem
            if (this.serviceItemChangesInSelectedGroup.get($event.serviceItemId).driverSupplier &&
                this.serviceItemChangesInSelectedGroup.get($event.serviceItemId).driverSupplier.supplierId) {
                driverSearchCriteria.supplierId = this.serviceItemChangesInSelectedGroup.get($event.serviceItemId).driverSupplier.supplierId;
            }
            this.genericResourceService.searchRecommendedDriversForAllocation(driverSearchCriteria);
            this.subscribeForDriverSearchResults();
        } else {
            this.filteredDrivers = [];
        }
    }

    private subscribeForDriverSearchResults() {
        this.dataStore.get(DataKey.resourceAssignDriversSearchResults).subscribe(
            result => {
                this.filteredDrivers = ResponseUtil.getDataArray(result);
            }, error => {
                this.filteredDrivers = [];
            });
    }

    onVehicleJobNoChange(event: ResourceAllocationSearchEvent) {

    }

    onDriverJobNoChange(event: ResourceAllocationSearchEvent) {

    }

    // select supplier
    public onSelectVehicleSupplier($event: ResourceAllocationEvent) {
        this.autoAssignResults = [];
        this.exit = false;
        this.selectedServiceItem = this.serviceItemsInSelectedGroup.find(
            value => value.serviceItemId === $event.serviceItemId);

        let selectedIds = new Set();
        this.serviceItemChangesInSelectedGroup.forEach(value => {
            if (value.checked) {
                selectedIds.add(value.serviceItemId);
            }
        });
        selectedIds.add($event.serviceItemId);
        if (selectedIds.size > 1) {
            let confirmSuccess = new DialogModel(
                true,
                LEVEL.WARNING,
                'Multiple Assign',
                `
                        There are multiple selected item in this group.
                        Do you wish to assign for all selected items ?`,
                true,
                2000,
                null,
                'Assign only for added one',
                'Yes',
                true
            );
            confirmSuccess.disableClose = true;
            this.dialogService
                .confirm(confirmSuccess)
                .subscribe((res) => {
                    if (res === true) {
                        for (const itemId of Array.from(selectedIds.values())) {
                            this.setVehicleSupplierToAssignment(itemId, $event.vehicleSupplier);
                        }
                    } else {
                        this.setVehicleSupplierToAssignment($event.serviceItemId, $event.vehicleSupplier);
                    }
                });

        } else {
            this.setVehicleSupplierToAssignment($event.serviceItemId, $event.vehicleSupplier);
        }
    }

    public onSelectDriverSupplier($event: ResourceAllocationEvent) {
        this.autoAssignResults = [];
        this.exit = false;
        this.selectedServiceItem = this.serviceItemsInSelectedGroup.find(
            value => value.serviceItemId === $event.serviceItemId);

        let selectedIds = new Set();
        this.serviceItemChangesInSelectedGroup.forEach(value => {
            if (value.checked) {
                selectedIds.add(value.serviceItemId);
            }
        });
        selectedIds.add($event.serviceItemId);
        if (selectedIds.size > 1) {
            let confirmSuccess = new DialogModel(
                true,
                LEVEL.WARNING,
                'Multiple Assign',
                `
                        There are multiple selected item in this group.
                        Do you wish to assign for all selected items ?`,
                true,
                2000,
                null,
                'Assign only for added one',
                'Yes',
                true
            );
            confirmSuccess.disableClose = true;
            this.dialogService
                .confirm(confirmSuccess)
                .subscribe((res) => {
                    if (res === true) {
                        for (const itemId of Array.from(selectedIds.values())) {
                            this.setDriverSupplierToAssignment(itemId, $event.driverSupplier);
                        }
                    } else {
                        this.setDriverSupplierToAssignment($event.serviceItemId, $event.driverSupplier);
                    }
                });

        } else {
            this.setDriverSupplierToAssignment($event.serviceItemId, $event.driverSupplier);
        }
    }

    setVehicleSupplierToAssignment(serviceItemId: number, supplier: TrsSupplierCostSummary) {
        let serviceItem = this.serviceItemsInSelectedGroup.find(value => value.serviceItemId === serviceItemId);
        this.serviceItemChangesInSelectedGroup.get(serviceItemId).changed = true;
        this.getSupplierCostData(supplier).then(
            value => {
                let assignment: ServiceAssignment = new ServiceAssignment();
                assignment = this.getSupplierAssignmentMap(assignment, supplier, serviceItemId);
                this.serviceItemChangesInSelectedGroup.get(serviceItemId).vehicleSupplier = supplier;
                assignment.categoryCode = ResourceType.vehicle;
                this.serviceItemChangesInSelectedGroup.get(serviceItemId).serviceAssignments = this.serviceItemChangesInSelectedGroup.get(
                    serviceItemId).serviceAssignments ?
                    this.serviceItemChangesInSelectedGroup.get(serviceItemId).serviceAssignments :
                    new Map();
                this.serviceItemChangesInSelectedGroup.get(serviceItemId).serviceAssignments
                    .set(ResourceType.vehicle, assignment);
                this.serviceItemChangesInSelectedGroup.get(serviceItemId).vehicleCost = this.costCalculationUtilsService.calculateServiceItemJobCost(
                    serviceItem,
                    assignment
                );
                this.changesAvailableForServiceItems = true;
                this.changeDetector.notifyChange();
            });

    }

    setDriverSupplierToAssignment(serviceItemId: number, supplier: DriverSupplierCostSummary) {
        let serviceItem = this.serviceItemsInSelectedGroup.find(value => value.serviceItemId === serviceItemId);
        this.serviceItemChangesInSelectedGroup.get(serviceItemId).changed = true;
        this.getSupplierCostData(supplier).then(
            value => {
                let assignment: ServiceAssignment = new ServiceAssignment();
                assignment = this.getSupplierAssignmentMap(assignment, supplier, serviceItemId);
                this.serviceItemChangesInSelectedGroup.get(serviceItemId).driverSupplier = supplier;
                assignment.categoryCode = ResourceType.driver;
                this.serviceItemChangesInSelectedGroup.get(serviceItemId).serviceAssignments = this.serviceItemChangesInSelectedGroup.get(
                    serviceItemId).serviceAssignments ?
                    this.serviceItemChangesInSelectedGroup.get(serviceItemId).serviceAssignments :
                    new Map();
                this.serviceItemChangesInSelectedGroup.get(serviceItemId).serviceAssignments
                    .set(ResourceType.driver, assignment);

                this.serviceItemChangesInSelectedGroup.get(serviceItemId).driverCost = this.costCalculationUtilsService.calculateServiceItemJobCost(
                    serviceItem,
                    assignment
                );
                this.changesAvailableForServiceItems = true;
                this.changeDetector.notifyChange();

            });

    }

    getSupplierCostData(supplier: any): Promise<any> {
        return new Promise(resolve => {
            if (supplier.unitFare) {
                supplier.isUnitFare = true;
                supplier.estimatedTotalCost = supplier.totalCost;
                if (!supplier.capacity) {
                    this.getTransferModeCapacity().then((value: number) => {
                        supplier.capacity = value;

                        supplier.totalCost = this.costCalculationUtilsService.getSupplierTotalFromUnitCost(
                            supplier,
                            false,
                            this.selectedServiceItem,
                            null
                        );
                        resolve(supplier);

                    });
                    // supplier.capacity = trsMode ? trsMode['__zone_symbol__value'] : 1;
                } else {
                    supplier.estimatedTotalCost = supplier.totalCost;
                    supplier.totalCost = this.costCalculationUtilsService.getSupplierTotalFromUnitCost(
                        supplier,
                        false,
                        this.selectedServiceItem,
                        null
                    );

                    resolve(supplier);

                }
            } else {
                supplier.isUnitFare = false;
                let passengerCount = this.costCalculationUtilsService.getTotalPassengerCount(
                    false,
                    this.selectedServiceItem,
                    null
                );
                let costSummary = this.costCalculationUtilsService.getSupplierTotalCost(
                    supplier,
                    false,
                    this.selectedServiceItem,
                    null
                );
                supplier.estimatedTotalCost = supplier.totalCost;
                supplier.totalCost = costSummary.totalCost;
                supplier.totalAdultFare = costSummary.adultFare;
                supplier.totalChildFare = costSummary.childFare;
                supplier.totalInfantFare = costSummary.infantFare;

                resolve(supplier);

            }
        });
    }

    private getSupplierAssignmentMap(
        assignment: ServiceAssignment, supplier: any, serviceItemId: number) {

        assignment.tempAssignmentId = assignment.tempAssignmentId ?
            assignment.tempAssignmentId :
            'Temp_' + this.identifierService.getNextManuallyResetId();

        assignment.supplierId = supplier.supplierId;
        assignment.supplierCode = supplier.supplierCode;
        assignment.supplierName = supplier.name;
        assignment.supplierCode = supplier.code;
        assignment.currency = supplier.currency;
        assignment.adults = this.selectedServiceItem.adult;
        assignment.children = this.selectedServiceItem.child;
        assignment.infants = this.selectedServiceItem.infant;
        assignment.adultPrice = supplier.adultFare;
        assignment.childPrice = supplier.childFare;
        assignment.infantPrice = supplier.infantFare;
        assignment.unitPrice = supplier.unitFare;
        assignment.jobCost = supplier.totalCost;
        assignment.estimatedCost = supplier.estimatedTotalCost ?
            supplier.estimatedTotalCost :
            supplier.totalCost;

        assignment.serviceItemIdList = [];
        assignment.serviceItemId = serviceItemId;
        assignment.jobDate = this.selectedServiceItem.serviceDate;
        assignment.updated = true;
        assignment.assignStatus = assignment.assignStatus ? assignment.assignStatus : AssignmentStatus.PENDING;
        return assignment;
    }

    // select vehicle
    public onSelectVehicle(event: ResourceAllocationEvent) {
        this.autoAssignResults = [];
        this._onSelectVehicle(event);
    }

    private _onSelectVehicle($event: ResourceAllocationEvent) {
        this.exit = false;
        this.selectedServiceItem = this.serviceItemsInSelectedGroup.find(
            value => value.serviceItemId === $event.serviceItemId);

        let selectedIds = new Set();
        this.serviceItemChangesInSelectedGroup.forEach(value => {
            if (value.checked) {
                selectedIds.add(value.serviceItemId);
            }
        });
        selectedIds.add($event.serviceItemId);
        if (selectedIds.size > 1) {
            let confirmSuccess = new DialogModel(
                true,
                LEVEL.WARNING,
                'Multiple Assign',
                `
                        There are multiple selected item in this group.
                        Do you wish to assign for all selected items ?`,
                true,
                2000,
                null,
                'Assign only for added one',
                'Yes',
                true
            );
            confirmSuccess.disableClose = true;
            this.dialogService
                .confirm(confirmSuccess)
                .subscribe((res) => {
                    if (res === true) {
                        for (const itemId of Array.from(selectedIds.values())) {

                            this.setVehicleToAssignment(itemId, $event.vehicle);
                            // let supplier = $event.vehicle.trsSupplier;
                            // this.setVehicleSupplierToAssignment(itemId, supplier);
                        }
                    } else {
                        this.setVehicleToAssignment($event.serviceItemId, $event.vehicle);
                        // let supplier = $event.vehicle.trsSupplier;
                        // this.setVehicleSupplierToAssignment($event.serviceItemId, supplier);
                    }
                });

        } else {
            this.setVehicleToAssignment($event.serviceItemId, $event.vehicle);
        }
        this.changeDetector.notifyChange();
    }

    // set vehicle assignment
    setVehicleToAssignment(serviceItemId: number, vehicle: ResourceAvailability) {
        let serviceItem = this.serviceItemsInSelectedGroup.find(value => value.serviceItemId === serviceItemId);
        this.serviceItemChangesInSelectedGroup.get(serviceItemId).changed = true;
        this.serviceItemChangesInSelectedGroup.get(serviceItemId).vehicle = vehicle;
        this.serviceItemChangesInSelectedGroup.get(serviceItemId).vehicleSupplier = vehicle.trsSupplier;
        let assignmentMap = new ServiceAssignment();
        let selectedAssignment = vehicle.assignments ?
            vehicle.assignments.filter(value => value.recommended)[0] :
            null;
        assignmentMap = this.getResourceAssignmentMap(selectedAssignment, vehicle, serviceItemId);
        if (selectedAssignment) {
            assignmentMap.assignmentId = selectedAssignment.assignmentId;
            let vehicleHasDriver = selectedAssignment.childrenAssignments ?
                selectedAssignment.childrenAssignments.find(value => value.categoryCode === ResourceType.driver) :
                null;
            if (vehicleHasDriver) {
                assignmentMap.childrenAssignments = [];
                assignmentMap.childrenAssignments.push(this.setChildAssignment(serviceItemId, vehicleHasDriver));
            }
        } else {
            assignmentMap.tempAssignmentId = 'Temp_' + this.identifierService.getNextManuallyResetId();
        }
        assignmentMap.resourceId = vehicle.resourceId;
        assignmentMap.categoryCode = ResourceType.vehicle;

        this.serviceItemChangesInSelectedGroup.get(serviceItemId).serviceAssignments
            .set(ResourceType.vehicle, assignmentMap);
        this.serviceItemChangesInSelectedGroup.get(serviceItemId).vehicleCost = this.costCalculationUtilsService.calculateServiceItemJobCost(
            serviceItem,
            assignmentMap
        );
        this.changesAvailableForServiceItems = true;
        this.changeDetector.notifyChange();
    }

    private getResourceAssignmentMap(
        selectedAssignment: AssignmentSummary, resource: ResourceAvailability, serviceItemId: number) {
        let assignment = new ServiceAssignment();
        let supplier = resource.trsSupplier ? resource.trsSupplier : resource.driverSupplier;
        supplier.totalCost = supplier.totalCost ?
            supplier.totalCost :
            this.getTotalCost(supplier, this.selectedServiceItem, resource.capacity);
        assignment = this.getSupplierAssignmentMap(assignment, supplier, serviceItemId);
        assignment.assignmentId = selectedAssignment ? selectedAssignment.assignmentId : 0;
        assignment = this.mapServiceItemDataToAssignment(assignment, serviceItemId);
        assignment.resourceId = resource.resourceId;
        assignment.resourceName = resource.resourceName;
        this.unSavedAssignments.push(resource);
        return assignment;
    }

    mapServiceItemDataToAssignment(assignment: ServiceAssignment, serviceItemId: number) {
        let serviceItem = this.serviceItemsInSelectedGroup.find(value => value.serviceItemId === serviceItemId);
        if (assignment.startTime && assignment.endTime && assignment.waitingEndTime) {
            if (assignment.startTime > this.getTimeFormatFromTimestamp(serviceItem.trsServiceItem.departureDateTime)) {
                assignment.startTime = this.getTimeFormatFromTimestamp(serviceItem.trsServiceItem.departureDateTime);
            }
            if (assignment.endTime < this.getTimeFormatFromTimestamp(serviceItem.trsServiceItem.arrivalDateTime)) {
                assignment.startTime = this.getTimeFormatFromTimestamp(serviceItem.trsServiceItem.arrivalDateTime);
            }
        } else {
            if (serviceItem.trsServiceItem.arrivalDateTime > serviceItem.trsServiceItem.departureDateTime) {
                assignment.startTime = this.getTimeFormatFromTimestamp(serviceItem.trsServiceItem.departureDateTime);
                assignment.endTime = this.getTimeFormatFromTimestamp(serviceItem.trsServiceItem.arrivalDateTime);
                assignment.waitingEndTime = this.getTimeFormatFromTimestamp(serviceItem.trsServiceItem.departureDateTime);
            } else {
                assignment.startTime = this.getTimeFormatFromTimestamp(serviceItem.trsServiceItem.arrivalDateTime);
                assignment.endTime = this.getTimeFormatFromTimestamp(serviceItem.trsServiceItem.departureDateTime);
                assignment.waitingEndTime = this.getTimeFormatFromTimestamp(serviceItem.trsServiceItem.arrivalDateTime);
            }
        }
        assignment.adults = this.selectedServiceItem.adult;
        assignment.children = this.selectedServiceItem.child;
        assignment.infants = this.selectedServiceItem.infant;
        assignment.startLocationGroup = serviceItem.trsServiceItem.pickupPointCode.substring(0, 3);
        assignment.endLocationGroup = serviceItem.trsServiceItem.dropoffPointCode.substring(0, 3);

        assignment.assignmentSaveOperation = AssignmentSaveOperation.SAVE;
        return assignment;
    }

    private getTimeFormatFromTimestamp(dateTime: any) {
        return moment(dateTime).format('HH:mm');
    }

    public onSelectDriver($event) {
        this.autoAssignResults = [];
        this.exit = false;
        this.selectedServiceItem = this.serviceItemsInSelectedGroup.find(
            value => value.serviceItemId === $event.serviceItemId);

        let selectedIds = new Set();
        this.serviceItemChangesInSelectedGroup.forEach(value => {
            if (value.checked) {
                selectedIds.add(value.serviceItemId);
            }
        });
        selectedIds.add($event.serviceItemId);
        if (selectedIds.size > 1) {
            let confirmSuccess = new DialogModel(
                true,
                LEVEL.WARNING,
                'Multiple Assign',
                `
                        There are multiple selected item in this group.
                        Do you wish to assign for all selected items ?`,
                true,
                2000,
                null,
                'Assign only for added one',
                'Yes',
                true
            );
            confirmSuccess.disableClose = true;
            this.dialogService
                .confirm(confirmSuccess)
                .subscribe((res) => {
                    if (res === true) {
                        for (const itemId of Array.from(selectedIds.values())) {

                            this.setDriverToAssignment(itemId, $event.driver);
                            // let supplier = $event.driver.driverSupplier;
                            // this.setSupplierToAssignment(itemId, supplier);
                        }
                    } else {

                        this.setDriverToAssignment($event.serviceItemId, $event.driver);
                        // let supplier = $event.driver.driverSupplier;
                        // this.setSupplierToAssignment($event.serviceItemId, supplier);
                    }
                });

        } else {
            this.setDriverToAssignment($event.serviceItemId, $event.driver);
        }
        this.changeDetector.notifyChange();
    }

    // set driver assignment
    setDriverToAssignment(serviceItemId: number, driver: ResourceAvailability) {
        let serviceItem = this.serviceItemsInSelectedGroup.find(value => value.serviceItemId === serviceItemId);
        this.serviceItemChangesInSelectedGroup.get(serviceItemId).changed = true;
        this.serviceItemChangesInSelectedGroup.get(serviceItemId).driver = driver;
        this.serviceItemChangesInSelectedGroup.get(serviceItemId).driverSupplier = driver.driverSupplier;
        let selectedAssignment = driver.assignments ?
            driver.assignments.filter(value => value.recommended)[0] :
            null;
        let assignmentMap = this.getResourceAssignmentMap(selectedAssignment, driver, serviceItemId);
        if (selectedAssignment) {
            assignmentMap.assignmentId = selectedAssignment.assignmentId;
            let driverHasVehicle = selectedAssignment.parentAssignment;
            if (driverHasVehicle) {
                assignmentMap.parentAssignment = this.setParentAssignment(serviceItemId, driverHasVehicle);
            }
        } else {
            assignmentMap.tempAssignmentId = 'Temp_' + this.identifierService.getNextManuallyResetId();
        }
        assignmentMap.resourceId = driver.resourceId;
        assignmentMap.resourceName = driver.resourceName;
        assignmentMap.categoryCode = ResourceType.driver;
        if (this.serviceItemChangesInSelectedGroup.get(serviceItemId).serviceAssignments.get(ResourceType.vehicle)) {
            assignmentMap.parentAssignment = this.serviceItemChangesInSelectedGroup.get(serviceItemId)
                .serviceAssignments.get(ResourceType.vehicle);
        }
        this.serviceItemChangesInSelectedGroup.get(serviceItemId).serviceAssignments
            .set(ResourceType.driver, assignmentMap);
        this.serviceItemChangesInSelectedGroup.get(serviceItemId).driverCost = this.costCalculationUtilsService.calculateServiceItemJobCost(
            serviceItem,
            assignmentMap
        );
        this.changesAvailableForServiceItems = true;
        this.changeDetector.notifyChange();
    }

    // set vehicle from driver assignment
    private setParentAssignment(serviceItemId: number, driverHasVehicle: AssignmentSummary) {
        let parentAssignment = new ServiceAssignment();
        let vehicle = new ResourceAvailability();
        vehicle.resourceId = driverHasVehicle.resourceId;
        vehicle.resourceName = driverHasVehicle.resourceName;
        this.serviceItemChangesInSelectedGroup.get(serviceItemId).changed = true;
        this.serviceItemChangesInSelectedGroup.get(serviceItemId).vehicle = vehicle;
        parentAssignment = this.mapServiceItemDataToAssignment(parentAssignment, serviceItemId);
        this.unSavedAssignments.push(parentAssignment);
        return parentAssignment;
    }

    // set driver from vehicle assignment
    private setChildAssignment(serviceItemId: number, vehicleHasDriver: AssignmentSummary | any[]) {
        let childAssignment = new ServiceAssignment();
        let driver = new ResourceAvailability();
        driver.resourceName = childAssignment.resourceName;
        driver.resourceId = childAssignment.resourceId;
        this.serviceItemChangesInSelectedGroup.get(serviceItemId).changed = true;
        this.serviceItemChangesInSelectedGroup.get(serviceItemId).driver = driver;
        childAssignment = this.mapServiceItemDataToAssignment(childAssignment, serviceItemId);
        this.unSavedAssignments.push(childAssignment);
        return childAssignment;
    }

    // clear Assignment
    public onClearVehicleAssignment($event: ResourceAllocationEvent) {
        this.clearVehicle($event);
        this.clearVehicleSupplier($event);

    }

    // clear Assignment
    public onClearDriverAssignment($event: ResourceAllocationEvent) {
        this.clearDriver($event);
        this.clearDriverSupplier($event);

    }

    // clear Supplier
    clearVehicleSupplier(event) {
        this.autoAssignResults = [];
        this.isCleared = true;
        this.exit = false;
        this.serviceItemChangesInSelectedGroup.get(event.serviceItemId).vehicleSupplier = null;
        this.serviceItemChangesInSelectedGroup.get(event.serviceItemId).vehicle = null;
        if (this.serviceItemChangesInSelectedGroup.get(event.serviceItemId).serviceAssignments) {
            this.serviceItemChangesInSelectedGroup.get(event.serviceItemId).changed = true;
            let assignment = this.serviceItemChangesInSelectedGroup.get(event.serviceItemId).serviceAssignments
                .get(ResourceType.vehicle);
            this.removeSupplierFromAssignment(event.serviceItemId, assignment);
            this.serviceItemChangesInSelectedGroup.get(event.serviceItemId).vehicleCost = 0;
            this.changesAvailableForServiceItems = true;
        }
    }

    clearDriverSupplier(event) {
        this.isCleared = true;
        this.exit = false;
        this.serviceItemChangesInSelectedGroup.get(event.serviceItemId).driverSupplier = null;
        if (this.serviceItemChangesInSelectedGroup.get(event.serviceItemId).serviceAssignments) {
            this.serviceItemChangesInSelectedGroup.get(event.serviceItemId).changed = true;
            let assignment = this.serviceItemChangesInSelectedGroup.get(event.serviceItemId).serviceAssignments
                .get(ResourceType.driver);
            this.removeSupplierFromAssignment(event.serviceItemId, assignment);
            this.serviceItemChangesInSelectedGroup.get(event.serviceItemId).driverCost = 0;
            this.changesAvailableForServiceItems = true;
        }
    }

    private removeSupplierFromAssignment(serviceItemId: number, assignment: ServiceAssignment) {
        assignment.supplierId = undefined;
        assignment.supplierName = undefined;
        assignment.supplierCode = undefined;
        assignment.resourceId = undefined;
        assignment.resourceName = undefined;
        assignment.serviceItemId = serviceItemId;
        assignment.updated = true;
        assignment.jobCost = 0;
        assignment.unitPrice = 0;
        assignment.assignmentSaveOperation = AssignmentSaveOperation.DELETE;
        return assignment;

    }

    // clear Vehicle
    public clearVehicle($event: ResourceAllocationEvent) {
        this.autoAssignResults = [];
        this.isCleared = true;
        this.exit = false;
        this.serviceItemChangesInSelectedGroup.get($event.serviceItemId).vehicle = null;
        if (this.serviceItemChangesInSelectedGroup.get($event.serviceItemId).serviceAssignments &&
            this.serviceItemChangesInSelectedGroup.get($event.serviceItemId).serviceAssignments
                .get(ResourceType.vehicle)) {
            this.serviceItemChangesInSelectedGroup.get($event.serviceItemId).changed = true;
            let assignment = this.serviceItemChangesInSelectedGroup.get($event.serviceItemId).serviceAssignments
                .get(ResourceType.vehicle);
            if (assignment.resourceId) {
                this.serviceItemChangesInSelectedGroup.get($event.serviceItemId).serviceAssignments.set(
                    ResourceType.vehicle,
                    this.removeResourceFromAssignment(
                        $event.serviceItemId,
                        this.serviceItemChangesInSelectedGroup.get($event.serviceItemId).vehicle,
                        assignment
                    )
                );
            }
            if (assignment.assignmentId > 0) {
                assignment.assignmentSaveOperation = AssignmentSaveOperation.UPDATE;
            }
            if (this.serviceItemChangesInSelectedGroup.get($event.serviceItemId).serviceAssignments
                .get(ResourceType.driver)) {
                assignment.childrenAssignments = assignment.childrenAssignments ?
                    assignment.childrenAssignments.filter(value => value.categoryCode !== ResourceType.driver) :
                    [];
                assignment.childrenAssignments.push(this.serviceItemChangesInSelectedGroup.get($event.serviceItemId)
                    .serviceAssignments
                    .get(ResourceType.driver));
            }
            assignment.resourceId = undefined;
            assignment.resourceName = undefined;
            this.serviceItemChangesInSelectedGroup.get($event.serviceItemId).serviceAssignments
                .set(ResourceType.vehicle, assignment);
            this.changesAvailableForServiceItems = true;
        }
    }

    // clear driver
    public clearDriver($event) {
        this.isCleared = true;
        this.exit = false;
        this.serviceItemChangesInSelectedGroup.get($event.serviceItemId).driver = null;
        if (this.serviceItemChangesInSelectedGroup.get($event.serviceItemId).serviceAssignments &&
            this.serviceItemChangesInSelectedGroup.get($event.serviceItemId).serviceAssignments
                .get(ResourceType.driver)) {
            let assignment = this.serviceItemChangesInSelectedGroup.get($event.serviceItemId).serviceAssignments
                .get(ResourceType.driver);
            this.serviceItemChangesInSelectedGroup.get($event.serviceItemId).changed = true;
            assignment.resourceId = undefined;
            assignment.resourceName = undefined;
            let hasVehicleAssignment = this.serviceItemChangesInSelectedGroup.get($event.serviceItemId)
                .serviceAssignments
                .get(ResourceType.vehicle);

            assignment.updated = true;
            if (hasVehicleAssignment) {
                this.serviceItemChangesInSelectedGroup.get($event.serviceItemId)
                    .serviceAssignments
                    .get(ResourceType.vehicle).childrenAssignments = [];
            }
            if (assignment.assignmentId > 0) {
                //should set as update after view change - seperate supplier to driver
                assignment.assignmentSaveOperation = hasVehicleAssignment ?
                    AssignmentSaveOperation.DELETE :
                    AssignmentSaveOperation.UPDATE;

            }
            this.changesAvailableForServiceItems = true;
            this.serviceItemChangesInSelectedGroup.get($event.serviceItemId).serviceAssignments
                .set(ResourceType.driver, assignment);
        }
    }

    private removeResourceFromAssignment(
        serviceItemId: number, vehicle: ResourceAvailability, assignment: ServiceAssignment) {
        assignment.resourceId = undefined;
        assignment.resourceName = undefined;
        assignment.updated = true;
        assignment.assignmentSaveOperation = AssignmentSaveOperation.UPDATE;
        return assignment;
    }

    private sendManifests() {
        // this.spinnerService.show();
        const confirmSendManifests = new DialogModel(
            true,
            LEVEL.WARNING,
            'Send Manifests',
            ` Do you want to send manifests for all confirmed assignments
                       from ` + this.searchCriteria.startDate + ` to ` + this.searchCriteria.endDate + `?`,
            true,
            2000,
            null,
            'Cancel',
            'Send',
            true
        );
        confirmSendManifests.disableClose = true;
        this.dialogService
            .confirm(confirmSendManifests)
            .subscribe((res) => {
                if (res === true) {
                    const documentCreationCriteria = new DocumentCreationCriteria();
                    documentCreationCriteria.documentFormat = DocumentFormat.pdf;
                    documentCreationCriteria.documentType = DocumentType.supplierManifest;
                    documentCreationCriteria.download = true;

                    const documentDataCriteria = new DocumentDataCriteria();
                    documentDataCriteria.searchStartDate = this.searchCriteria.startDate;
                    documentDataCriteria.searchEndDate = this.searchCriteria.endDate;
                    this.documentService.createAndDownloadDocument(documentCreationCriteria, documentDataCriteria);
                }
            });

    }

    export(docName: string, persist: boolean, download: boolean) {
        // this.spinnerService.show();
        if (!this.searchCriteria.assignedStatus) {
            this.searchCriteria.assignedStatus = null;
        }
        const documentCreationCriteria = new DocumentCreationCriteria();
        documentCreationCriteria.documentFormat = DocumentFormat.excel;
        documentCreationCriteria.documentType = DocumentType.trsExportReport;
        documentCreationCriteria.documentName = docName;
        documentCreationCriteria.download = download;
        documentCreationCriteria.persist = persist;

        const documentDataCriteria = new DocumentDataCriteria();
        this.mapItemSearchCriteriaToDocumentCriteria(documentDataCriteria);
        documentDataCriteria.searchStartDate = this.searchCriteria.startDate;
        documentDataCriteria.searchEndDate = this.searchCriteria.endDate;
        documentDataCriteria.serviceType = 'TRS';
        this.documentService.createAndDownloadDocument(documentCreationCriteria, documentDataCriteria);
    }

    getArrivalList(docName: string, persist: boolean, download: boolean) {
        if (this.searchCriteria) {
            const documentCreationCriteria = new DocumentCreationCriteria();
            documentCreationCriteria.documentFormat = DocumentFormat.excel;
            documentCreationCriteria.documentType = DocumentType.arrivalList;
            documentCreationCriteria.documentName = docName;
            documentCreationCriteria.download = download;
            documentCreationCriteria.persist = persist;

            const documentDataCriteria = new DocumentDataCriteria();
            this.mapItemSearchCriteriaToDocumentCriteria(documentDataCriteria);
            documentDataCriteria.serviceType = 'TRS';
            documentDataCriteria.pickupType = LocationType.AIRPORT;
            this.documentService.createAndDownloadDocument(documentCreationCriteria, documentDataCriteria);
        }
    }

    getDepartureList(docName: string, persist: boolean, download: boolean) {
        if (this.searchCriteria) {
            const documentCreationCriteria = new DocumentCreationCriteria();
            documentCreationCriteria.documentFormat = DocumentFormat.excel;
            documentCreationCriteria.documentType = DocumentType.departureList;
            documentCreationCriteria.documentName = docName;
            documentCreationCriteria.download = download;
            documentCreationCriteria.persist = persist;

            const documentDataCriteria = new DocumentDataCriteria();
            this.mapItemSearchCriteriaToDocumentCriteria(documentDataCriteria);
            documentDataCriteria.serviceType = 'TRS';
            documentDataCriteria.dropOffType = LocationType.AIRPORT;
            this.documentService.createAndDownloadDocument(documentCreationCriteria, documentDataCriteria);
        }
    }

    private onGenerateDocumentsClick() {
        this.dataStore.set(DataKey.popupClose, null);
        const documentTypes = [
            {
                name: 'Trs Export Report',
                code: DocumentType.trsExportReport,
                icon: 'unarchive'
            },
            {
                name: 'Arrival List',
                code: DocumentType.arrivalList,
                icon: 'flight_land'
            },
            {
                name: 'Departure List',
                code: DocumentType.departureList,
                icon: 'flight_takeoff'
            }
        ];

        const documentCreationModel = new DocumentCreationModel();
        documentCreationModel.autoName = true;
        documentCreationModel.download = true;

        const dataObject = new ModalData(
            LEVEL.SUCCESS,
            'Generate Documents',
            null,
            null,
            'modal-basic--alt modal-basic--fixed-size',
            DocumentCreationModalComponent,
            {
                documentTypes,
                documentCreationModel
            }
        );
        dataObject.disableClose = false;
        this.confirmModal(dataObject);
        const modalSubscription = this.dataStore.get(DataKey.popupClose).subscribe((data) => {
            if (data != null) {
                modalSubscription.unsubscribe();
                this.closeModal();
                if (data) {
                    if (documentCreationModel.documentType) {
                        switch (documentCreationModel.documentType.code) {
                            case DocumentType.arrivalList:
                                this.getArrivalList(
                                    documentCreationModel.documentName,
                                    !!documentCreationModel.persist,
                                    !!documentCreationModel.download
                                );
                                break;
                            case DocumentType.departureList:
                                this.getDepartureList(
                                    documentCreationModel.documentName,
                                    !!documentCreationModel.persist,
                                    !!documentCreationModel.download
                                );
                                break;
                            case DocumentType.trsExportReport:
                                this.export(
                                    documentCreationModel.documentName,
                                    !!documentCreationModel.persist,
                                    !!documentCreationModel.download
                                );
                                break;
                        }
                    }
                }
            }
        });
    }

    public onHistoryClick(event: any) {
        const serviceItem = event.data.value.rawData;
        if (serviceItem && serviceItem.serviceItemHistories.length > 0) {
            const historyText = [];
            for (const serviceItemHistory of serviceItem.serviceItemHistories) {
                if (serviceItemHistory.description) {
                    historyText.push(...serviceItemHistory.description.split('. '));
                }
            }
            console.log(historyText);
            let historyTextFormatted = `<ul>`;
            for (const historyTextElement of historyText) {
                historyTextFormatted += `<li>` + historyTextElement + `</li>`;
            }
            historyTextFormatted += `</ul>`;
            console.log(historyTextFormatted);
            const confirmSuccess = new DialogModel(
                true,
                LEVEL.INFO,
                'Service Item History',
                historyTextFormatted,
                false,
                2000,
                null,
                'Cancel',
                'Ok',
                true
            );
            confirmSuccess.disableClose = true;
            this.dialogService
                .confirm(confirmSuccess)
                .subscribe((res) => {});
            }
    }
}
