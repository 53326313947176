import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TcApiError } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-api-error';
import { TcHttpError } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-http-error';
import { TC } from '@tc-core/util';
import { ConfigLoader } from '@tc-core/util/framework/config-loader.service';
import { EventManager } from '@tc-core/util/framework/event-manager.service';
import { UserJourneyManager } from '@tc-core/util/framework/user-journey-manager.service';
import { CommonHelper } from '@tc-core/util/helpers/common-helper.service';
import { SpinnerService } from '@tc-core/util/ui';
import { ChipInputComponent } from '@tc/chip-input';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { TCO } from '../../../../../constants';
import { ResourceAllocationSearchCriteria } from '../../../../../models/reservation-v2/criteria/resource-allocation-search-criteria';
import { ResourceType } from '../../../../../models/reservation/assignment';
import { ReservationV2ManagementService } from '../../../../../services/backend-consumers/reservation-v2/reservation-v2-management.service';
import { ChipHandlerService } from '../../../../../services/helpers/chip-handler.service';
import { RootService } from '../../../../../services/util/core-services/root.service';
import { ActionHandlerService } from '../../../../../services/util/framework/action-handler-service.service';
import { DataKey, DataStoreService } from '../../../../../services/util/framework/data-store.service';
import { DMCQueryParamsService } from '../../../../../services/util/framework/dmc-query-params.service';
import { UserManagementService } from '../../../../../services/user-management/user-management.service';
var ResourceAllocationSearchCriteriaComponent = /** @class */ (function () {
    function ResourceAllocationSearchCriteriaComponent(activatedRoute, configLoader, userJourneyManager, dataStoreService, actionHandlerService, em, reservationV2ManagementService, chipHandler, queryParamService, commonHelper, spinnerService, rootService, userManagementService) {
        this.activatedRoute = activatedRoute;
        this.configLoader = configLoader;
        this.userJourneyManager = userJourneyManager;
        this.dataStoreService = dataStoreService;
        this.actionHandlerService = actionHandlerService;
        this.em = em;
        this.reservationV2ManagementService = reservationV2ManagementService;
        this.chipHandler = chipHandler;
        this.queryParamService = queryParamService;
        this.commonHelper = commonHelper;
        this.spinnerService = spinnerService;
        this.rootService = rootService;
        this.userManagementService = userManagementService;
        this.isInsideForm = false;
        this.isSearchBarStyleNotAvailable = false;
        this.isDataLoaded = true;
        this.displayChipsArr = [];
        this.isFocus = false;
        this.isVehicleAssignment = false;
        this.placeHolderText = 'Search Resources';
        this.isTriggerSearch = true;
        this.searchTrigger = new EventEmitter();
        this.searchCriteria = new EventEmitter();
        this.journey = null;
        this.isChipsInit = false;
        this.availableChips = [];
        this.persistentChipsArr = [];
        this.persistentQParams = [];
        this.persistentQParamMap = new Map();
        this.relatedChipsObsv = new Subscription();
        this.isChipListDisplay = false;
        this.defaultVehicleModel = 'ANY';
        this.selectedChipsObsv = new Subscription();
        this.selectedFieldsObsv = new Subscription();
        this.chipValueUpdateSub = new Subscription();
        this.criteriaUpdateSubscription = new Subscription();
        this.deletedChipObsv = new Subscription();
        this.userJourneyObserver = new Subscription();
        this.selectedChips = [];
        this.focusSearchBarObserver = new Subscription();
        this.closePopUpObserver = new Subscription();
        this.errorsObserver = new Subscription();
        this.triggerSearchCriteriaObserver = new Subscription();
        this.searchResultObserver = new Subscription();
        this.chipHandlerSubject = new Subscription();
        this.selectedSupplierObsv = new Subscription();
    }
    ResourceAllocationSearchCriteriaComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.chipInputService = this.allocationSearchChipInput.criteriaInputService;
        this.selectedSupplierObserver();
        // clear data store
        this.dataStoreService.set(DataKey.resourceAllocationSearchCriteria, null, true);
        this.handleQuickActions();
        this.userJourneyObserver = this.userJourneyManager.journey.subscribe(function (journey) {
            if (journey && JSON.stringify(journey) !== JSON.stringify({})) {
                _this.journey = journey.id;
            }
        });
        // resetting the view
        this.routerChangeSubscription = this.userJourneyManager.routeChanges.subscribe(function (e) {
            if (e) {
                _this.chipInputService.fullReset(); // fully reset chips
                _this.chipHandler.chipsArr = []; // reset chips array loaded from config
                console.log('displayChipsArr', _this.displayChipsArr);
                _this.resetQueryParams();
            }
        });
        if (!this.isInsideForm) {
            this.resourceSearchCriteria = new ResourceAllocationSearchCriteria();
            if (this.inputSearchCriteria) {
                this.chipInputService.fullReset(); // fully reset chips
                this.chipHandler.chipsArr = []; // reset chips array loaded from config
                this.resourceSearchCriteria.serviceDate = this.inputSearchCriteria.serviceDate;
                this.resourceSearchCriteria.serviceType = this.inputSearchCriteria.serviceType;
                this.resourceSearchCriteria.adultCount = this.inputSearchCriteria.adultCount;
                this.resourceSearchCriteria.childCount = this.inputSearchCriteria.childCount;
                this.resourceSearchCriteria.infantCount = this.inputSearchCriteria.infantCount;
                this.resourceSearchCriteria.serviceStartTime = this.inputSearchCriteria.serviceStartTime;
                this.resourceSearchCriteria.serviceEndTime = this.inputSearchCriteria.serviceEndTime;
                this.resourceSearchCriteria.bookingIdList = this.inputSearchCriteria.bookingIdList;
                this.resourceSearchCriteria.profileType = this.inputSearchCriteria.profileType;
                this.resourceSearchCriteria.costingType = this.inputSearchCriteria.costingType;
                this.resourceSearchCriteria.noOfUnits = this.inputSearchCriteria.noOfUnits;
                this.resourceSearchCriteria.kcCompany = this.inputSearchCriteria.kcCompany;
                this.resourceSearchCriteria.kcDivision = this.inputSearchCriteria.kcDivision;
                this.resourceSearchCriteria.kcBrand = this.inputSearchCriteria.kcBrand;
                this.resourceSearchCriteria.kcDistributionChannel = this.inputSearchCriteria.kcDistributionChannel;
                if (this.inputSearchCriteria.serviceType === ResourceType.vehicle) {
                    this.isVehicleAssignment = true;
                    this.resourceSearchCriteria.transferMode = this.inputSearchCriteria.transferMode;
                    this.resourceSearchCriteria.route = this.inputSearchCriteria.route;
                    this.resourceSearchCriteria.vehicleModel = this.inputSearchCriteria.vehicleModel ?
                        this.inputSearchCriteria.vehicleModel :
                        'Any';
                }
                if (!this.isChipsInit) {
                    this.initSearchCriteriaChips(); // load everything from start
                }
            }
        }
        if (!this.isInsideForm) {
            this.getQueryParams();
            if (!this.isChipsInit) {
                this.initSearchCriteriaChips(); // load everything from start
            }
        }
        this.getSearchObserver();
        this.errorObserver();
        this.selectedChipsUpdate();
        this.handleInterRelatedChips();
        this.notifyChipDelete();
        this.shortCutObserver();
    };
    ResourceAllocationSearchCriteriaComponent.prototype.selectedSupplierObserver = function () {
        var _this = this;
        this.selectedSupplierObsv = this.dataStoreService.get(DataKey.assignedSupplier).subscribe(function (value) {
            if (value && value.supplierId && value.supplierId !== _this.resourceSearchCriteria.supplierId) {
                _this.setCriteria(value);
            }
        });
    };
    ResourceAllocationSearchCriteriaComponent.prototype.setCriteria = function (value) {
        var selected = value;
        this.resourceSearchCriteria.supplierCode = selected.supplierCode;
        this.resourceSearchCriteria.supplierId = selected.supplierId;
        this.resourceSearchCriteria.serviceDate = selected.serviceDate ? selected.serviceDate : this.resourceSearchCriteria.serviceDate;
        this.resourceSearchCriteria.serviceType = selected.serviceType ? selected.serviceType : this.resourceSearchCriteria.serviceType;
        this.resourceSearchCriteria.activeStatus = "true";
        this.getQueryParams();
        this.initSearchCriteriaChips();
    };
    ResourceAllocationSearchCriteriaComponent.prototype.getQueryParams = function () {
        var queryParams = [];
        queryParams = this.queryParamService.getQueryParamsArray(new ResourceAllocationSearchCriteria());
        //this.resourceSearchCriteria = this.rootService.loadDataFromLocalStorage(queryParams, new ResourceAllocationSearchCriteria(),
        //    TCO.AppData.RESOURCE_ALLOCATION_SEARCH_CRITERIA
        // );
        // get persisting query params
        if (this.resourceSearchCriteria) {
            this.persistentQParams = [];
            this.persistentQParams = this.queryParamService.getNotNullQueryParamsArray(this.resourceSearchCriteria);
        }
    };
    ResourceAllocationSearchCriteriaComponent.prototype.shortCutObserver = function () {
        var _this = this;
        this.focusSearchBarObserver = this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'FOCUS_SEARCH_BAR')
            .subscribe(function (e) {
            _this.isFocus = true;
        });
        this.closePopUpObserver = this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'CLOSE_POPUPS')
            .subscribe(function (e) {
            _this.isFocus = false;
        });
    };
    ResourceAllocationSearchCriteriaComponent.prototype.onFocus = function (event) {
        this.isFocus = event;
    };
    ResourceAllocationSearchCriteriaComponent.prototype.initSearchCriteriaChips = function () {
        var _this = this;
        this.isChipsInit = true;
        // let isChipHandlerInit = false;
        // read chip config
        var criteriaChipConfigs = null;
        if (!this.isInsideForm) {
            var chipConfigs = this.configLoader.configurations.get(TCO.CONF.CONF_RESOURCE_ALLOCATION_CHIP);
            criteriaChipConfigs = this.commonHelper.recreateJsonObject(chipConfigs);
        }
        else {
            var chipConfigs = this.configLoader.configurations.get(this.searchCriteriaChipConfig);
            criteriaChipConfigs = this.commonHelper.recreateJsonObject(chipConfigs);
        }
        if (criteriaChipConfigs.chips) {
            criteriaChipConfigs.chips.forEach(function (chip) {
                chip = _this.chipHandler.preProcessChips(chip, criteriaChipConfigs.permissionKeys.keys);
            });
        }
        this.chipHandler.chipsSubject.subscribe(function (data) {
            if (data && JSON.stringify(data) !== JSON.stringify([]) && data.length ===
                criteriaChipConfigs.chips.length) {
                _this.availableChips = data;
                _this.chipHandler.chipsSubject.next(null);
                // isChipHandlerInit = true;
                if (_this.chipHandlerSubject) {
                    _this.chipHandlerSubject.unsubscribe();
                }
                // set values to display chips
                _this.displayChipsArr = [];
                _this.displayChipsArr = _this.availableChips;
                _this.userManagementService.updateAllocationSearchChipsWithKC(criteriaChipConfigs, _this.resourceSearchCriteria.kcCompany, _this.resourceSearchCriteria.kcDivision, _this.resourceSearchCriteria.kcBrand, _this.resourceSearchCriteria.kcDistributionChannel);
                _this.availableChips.forEach(function (chip) {
                    if (chip['criteriaId'] === 'SERVICE_TYPE') {
                        chip['optionParams']['isInitialPresent'] = true;
                        chip['optionParams']['isInitialPresent'] = true;
                        var choice = chip['optionParams']['options']
                            .filter(function (option) { return option.code === _this.resourceSearchCriteria.serviceType; });
                        chip['optionParams']['data'] = choice;
                    }
                    if (chip['criteriaId'] === 'SERVICE_DATE') {
                        chip['optionParams']['isInitialPresent'] = true;
                        chip['optionParams']['data'] = [moment(_this.resourceSearchCriteria.serviceDate).toDate()];
                    }
                    if (_this.isVehicleAssignment) {
                        if (chip['criteriaId'] === 'TRANSFER_MODE') {
                            chip['optionParams']['isInitialPresent'] = true;
                            var choice = chip['optionParams']['options']
                                .filter(function (option) { return option.code === _this.resourceSearchCriteria.transferMode; });
                            chip['optionParams']['data'] = choice;
                            chip['optionParams']['isMandatory'] = true;
                        }
                        if (chip['criteriaId'] === 'ROUTE') {
                            chip['optionParams']['isInitialPresent'] = true;
                            var choice = chip['optionParams']['options']
                                .filter(function (option) { return option.code === _this.resourceSearchCriteria.route; });
                            chip['optionParams']['data'] = choice;
                            chip['optionParams']['isMandatory'] = true;
                        }
                        if (chip['criteriaId'] === 'VEHICLE_MODEL') {
                            chip['optionParams']['isInitialPresent'] = true;
                            var choice = chip['optionParams']['options']
                                .filter(function (option) { return option.code === _this.resourceSearchCriteria.vehicleModel; });
                            chip['optionParams']['data'] = choice;
                            chip['optionParams']['isMandatory'] = true;
                        }
                    }
                });
                _this.dataStoreService.set(DataKey.chips, _this.availableChips, true);
                // trigger search when chips persisting
                _this.isDataLoaded = false;
                // this.isTriggerSearch = false;
                _this.onResourceSearch(_this.resourceSearchCriteria);
                _this.chipInputService.init(_this.resourceSearchCriteria, _this.availableChips, false, true);
                // implement chip persistance
                if (_this.persistentQParams && _this.persistentQParams.length > 0) {
                    // create a queryParamMap
                    for (var i = 0; i < _this.persistentQParams.length; i++) {
                        var qParam = _this.persistentQParams[i];
                        var qParamValue = _this.resourceSearchCriteria[qParam];
                        _this.persistentQParamMap.set(qParam, qParamValue);
                    }
                    // fill persistent chips
                    _this.availableChips.forEach(function (chip) {
                        var persistChip = Object.assign({}, chip);
                        persistChip.optionParams.data = [];
                        var isPersist = false;
                        if (chip.dataParams && chip.dataParams.length > 0) {
                            chip.dataParams.forEach(function (dataParam) {
                                if (_this.persistentQParamMap.has(dataParam.paramValue)) {
                                    isPersist = true;
                                    var dataObj = {
                                        key: dataParam.paramValue,
                                        value: _this.persistentQParamMap.get(dataParam.paramValue)
                                    };
                                    persistChip.optionParams.data.push(dataObj);
                                }
                            });
                        }
                        // push persist chips to an array
                        if (isPersist) {
                            _this.persistentChipsArr.push(persistChip);
                        }
                    });
                    // persist chips
                    if (_this.persistentChipsArr && _this.persistentChipsArr.length > 0) {
                        var uniqueChipList = _this.persistentChipsArr.filter(function (e, i) {
                            return _this.persistentChipsArr.findIndex(function (x) {
                                return x.criteriaId === e.criteriaId;
                            }) === i;
                        });
                        _this.chipInputService.persistChips(uniqueChipList);
                        // trigger search when chips persisting
                        _this.isDataLoaded = false;
                        // this.isTriggerSearch = false;
                        _this.onResourceSearch(_this.resourceSearchCriteria);
                    }
                }
                _this.chipInputService.criteriaUpdate.subscribe(function (_data) {
                    _this.resourceSearchCriteria = _data;
                });
            }
        });
    };
    ResourceAllocationSearchCriteriaComponent.prototype.onResourceSearch = function (event) {
        var _this = this;
        this.isDataLoaded = false;
        this.resourceSearchCriteria = event;
        this.userJourneyManager.canProceed.next(false);
        if (JSON.stringify(event) !== JSON.stringify({})) {
            if (!this.resourceSearchCriteria.vehicleModel) {
                this.resourceSearchCriteria.vehicleModel = this.defaultVehicleModel;
            }
            this.dataStoreService.set(DataKey.resourceAllocationSearchCriteria, this.resourceSearchCriteria, true);
            this.dataStoreService.set(DataKey.resourceAllocationSearchResults, null, true);
            // set criteria parameters to url
            var queryParams = [];
            queryParams = this.queryParamService.getQueryParamsArray(this.resourceSearchCriteria);
            this.rootService.setDataToLocalStorage(TCO.AppData.RESOURCE_ALLOCATION_SEARCH_CRITERIA, this.resourceSearchCriteria, true, queryParams, true);
            // search data
            this.reservationV2ManagementService.searchResourcesForAllocationWithKey(this.resourceSearchCriteria);
            this.isTriggerSearch = false;
            this.isDataLoaded = false;
            this.searchTrigger.emit(true);
        }
        else {
            setTimeout(function () {
                _this.isDataLoaded = true;
                _this.isTriggerSearch = true;
            }, 0);
        }
    };
    ResourceAllocationSearchCriteriaComponent.prototype.getUpdatedFieldsDataObject = function (chip, criteria) {
        var dataObjArr = [];
        if (chip && chip.dataParams && chip.dataParams.length > 0) {
            chip.dataParams.forEach(function (dataParam) {
                var dataObj = {
                    key: '',
                    value: ''
                };
                dataObj.key = dataParam.paramValue;
                dataObj.value = criteria[dataParam.paramValue];
                dataObjArr.push(dataObj);
            });
        }
        return dataObjArr;
    };
    ResourceAllocationSearchCriteriaComponent.prototype.getSearchObserver = function () {
        var _this = this;
        this.searchResultObserver = this.dataStoreService.get(DataKey.resourceAllocationSearchResults)
            .subscribe(function (data) {
            if (_this.commonHelper.dataValidity(data)) {
                _this.isDataLoaded = true;
                _this.isTriggerSearch = false;
            }
            else if (_this.commonHelper.isEmptyData(data)) {
                _this.isDataLoaded = true;
            }
            else if (data instanceof TcApiError) {
                _this.isDataLoaded = true;
            }
            else if (data instanceof TcHttpError) {
                _this.isDataLoaded = true;
            }
        }, function (error) {
            console.log(error);
        });
    };
    ResourceAllocationSearchCriteriaComponent.prototype.errorObserver = function () {
        var _this = this;
        this.errorsObserver = this.dataStoreService.get(DataKey.error)
            .subscribe(function (data) {
            if (data && JSON.stringify(data) !== JSON.stringify({})) {
                setTimeout(function () {
                    _this.isDataLoaded = true;
                }, 0);
                _this.dataStoreService.set(DataKey.error, null);
            }
        });
    };
    ResourceAllocationSearchCriteriaComponent.prototype.resetQueryParams = function () {
        var notificationsQueryParams = [];
        notificationsQueryParams = this.queryParamService.getQueryParamsArray(new ResourceAllocationSearchCriteria());
        var allocationSearchCriteria = this.rootService.loadDataFromLocalStorage([], new ResourceAllocationSearchCriteria(), TCO.AppData.RESOURCE_ALLOCATION_SEARCH_CRITERIA);
        // set criteria parameters to url
        var queryParams = [];
        queryParams = this.queryParamService.getQueryParamsArray(allocationSearchCriteria);
        this.rootService.setDataToLocalStorage(TCO.AppData.RESOURCE_ALLOCATION_SEARCH_CRITERIA, this.resourceSearchCriteria, true, queryParams, true);
    };
    ResourceAllocationSearchCriteriaComponent.prototype.onCancel = function ($event) {
        console.log('cancelled');
    };
    ResourceAllocationSearchCriteriaComponent.prototype.onChipListDisplay = function (event) {
        this.dataStoreService.set(DataKey.chipListDisplay, null, true);
        this.isChipListDisplay = event;
        this.dataStoreService.set(DataKey.chipListDisplay, this.isChipListDisplay, true);
    };
    ResourceAllocationSearchCriteriaComponent.prototype.handleQuickActions = function () {
        var _this = this;
        this.triggerSearchCriteriaObserver = this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'TRIGGER_SEARCH_CRITERIA')
            .subscribe(function (e) {
            _this.triggerSearch(e.data);
        });
    };
    ResourceAllocationSearchCriteriaComponent.prototype.triggerSearch = function (data) {
        this.resourceSearchCriteria = new ResourceAllocationSearchCriteria();
        // todo criteria
        this.actionHandlerService.fillCriteriaByActionData(data, this.resourceSearchCriteria);
        var requiredChips = this.chipHandler.getChipsFromCriteria(this.resourceSearchCriteria, TCO.CONF.CONF_RESOURCE_ALLOCATION_CHIP, true);
        this.chipInputService.hardReset();
        this.chipInputService.persistChips(requiredChips, true);
        this.onResourceSearch(this.resourceSearchCriteria);
    };
    ResourceAllocationSearchCriteriaComponent.prototype.handleInterRelatedChips = function () {
        var _this = this;
        this.relatedChipsObsv = this.chipInputService.relatedChipsSubject
            .subscribe(function (data) {
            var toggleChips = [];
            if (data && data.chip && JSON.stringify(data) !== JSON.stringify({})) {
                _this.modifiedChip = data.chip;
                // TODO: refine chip input service: inter related chips
                if (data.chip.type === 'CALENDAR') {
                    _this.chipHandler.handleInterRelatedChips(data.chip, data.date);
                }
                else {
                    _this.chipHandler.handleInterRelatedChips(data.chip, data.options.value[0]);
                }
                if (data.chip.optionParams.toggleChips &&
                    data.chip.optionParams.toggleChips.length > 0) {
                    toggleChips = data.chip.optionParams.toggleChips;
                    if (toggleChips && data.options && data.options.value[0].value) {
                        _this.chipInputService.selectedChipsBasedOnStateChange(toggleChips);
                    }
                    else {
                        _this.chipInputService.deleteChipsBasedOnStateChange(toggleChips);
                    }
                }
            }
        });
    };
    ResourceAllocationSearchCriteriaComponent.prototype.selectedChipsUpdate = function () {
        var _this = this;
        this.criteriaUpdateSubscription = this.chipInputService.selectedChipsSubject
            .subscribe(function (selectedChips) {
            var e_1, _a;
            _this.selectedChips = selectedChips;
            if (selectedChips && JSON.stringify(selectedChips) !==
                JSON.stringify([])) {
                try {
                    // toggle chips when selecting BRANCH_OF_CLIENT_ID chip
                    for (var _b = tslib_1.__values(_this.selectedChips), _c = _b.next(); !_c.done; _c = _b.next()) {
                        var selectedChip = _c.value;
                        if (selectedChip.chip.id === 'BRANCH_OF_CLIENT_ID' &&
                            selectedChip.chip.optionParams.toggleChips &&
                            selectedChip.chip.optionParams.toggleChips.length > 0) {
                            _this.deleteToggleChips(selectedChip.chip.optionParams.toggleChips);
                            _this.disableChips(selectedChip.chip.optionParams.toggleChips);
                            break;
                        }
                    }
                }
                catch (e_1_1) { e_1 = { error: e_1_1 }; }
                finally {
                    try {
                        if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                    }
                    finally { if (e_1) throw e_1.error; }
                }
            }
        });
    };
    ResourceAllocationSearchCriteriaComponent.prototype.notifyChipDelete = function () {
        var _this = this;
        this.deletedChipObsv = this.chipInputService.deletedChipsSubject
            .subscribe(function (deletedChip) {
            if (JSON.stringify(deletedChip) !== JSON.stringify({}) && deletedChip !== null &&
                deletedChip !==
                    undefined) {
                if (deletedChip.id === 'CLIENT_TYPE') {
                    var deleteChips = _this.selectedChips.map(function (chip) { return chip.chip; });
                    _this.deleteSelectedChips(deleteChips);
                    _this.enableChips(_this.availableChips);
                }
                else if (deletedChip.id === 'BRANCH_OF_CLIENT_ID') {
                    if (deletedChip.optionParams.toggleChips &&
                        deletedChip.optionParams.toggleChips.length > 0) {
                        _this.enableChips(deletedChip.optionParams.toggleChips);
                    }
                }
            }
        });
    };
    ResourceAllocationSearchCriteriaComponent.prototype.deleteToggleChips = function (toggleChips) {
        var _this = this;
        var deleteChips = [];
        toggleChips.forEach(function (toggleChip) {
            var e_2, _a;
            try {
                for (var _b = tslib_1.__values(_this.selectedChips), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var selectedChip = _c.value;
                    if (selectedChip.chip.id === toggleChip.criteriaId) {
                        deleteChips.push(selectedChip.chip);
                        break;
                    }
                }
            }
            catch (e_2_1) { e_2 = { error: e_2_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_2) throw e_2.error; }
            }
        });
        this.deleteSelectedChips(deleteChips);
    };
    ResourceAllocationSearchCriteriaComponent.prototype.deleteSelectedChips = function (deleteChips) {
        var _this = this;
        deleteChips.forEach(function (chip) {
            _this.chipInputService.deleteChip(chip, true);
        });
    };
    ResourceAllocationSearchCriteriaComponent.prototype.enableChips = function (enableChips) {
        var _this = this;
        enableChips.forEach(function (enableChip) {
            _this.chipInputService.modifyChipsStatus(enableChip, false);
        });
    };
    ResourceAllocationSearchCriteriaComponent.prototype.disableChips = function (disableChips) {
        var _this = this;
        disableChips.forEach(function (disableChip) {
            _this.chipInputService.modifyChipsStatus(disableChip, true);
        });
    };
    ResourceAllocationSearchCriteriaComponent.prototype.ngOnDestroy = function () {
        this.routerChangeSubscription.unsubscribe();
        this.userJourneyManager.routeChanges.next(false);
        if (this.selectedChipsObsv) {
            this.selectedChipsObsv.unsubscribe();
        }
        if (this.selectedFieldsObsv) {
            this.selectedFieldsObsv.unsubscribe();
        }
        if (this.chipValueUpdateSub) {
            this.chipValueUpdateSub.unsubscribe();
        }
        if (this.criteriaUpdateSubscription) {
            this.criteriaUpdateSubscription.unsubscribe();
        }
        if (this.deletedChipObsv) {
            this.deletedChipObsv.unsubscribe();
        }
        if (this.searchResultObserver) {
            this.searchResultObserver.unsubscribe();
        }
        if (this.errorsObserver) {
            this.errorsObserver.unsubscribe();
        }
        if (this.userJourneyObserver) {
            this.userJourneyObserver.unsubscribe();
        }
        if (this.focusSearchBarObserver) {
            this.focusSearchBarObserver.unsubscribe();
        }
        if (this.closePopUpObserver) {
            this.closePopUpObserver.unsubscribe();
        }
        if (this.triggerSearchCriteriaObserver) {
            this.triggerSearchCriteriaObserver.unsubscribe();
        }
        if (this.selectedSupplierObsv) {
            this.selectedSupplierObsv.unsubscribe();
        }
        this.em.removeEvents([
            'TRIGGER_SEARCH_CRITERIA'
        ]);
    };
    return ResourceAllocationSearchCriteriaComponent;
}());
export { ResourceAllocationSearchCriteriaComponent };
