import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CurrencyFormat } from '@tc-core/model/it/codegen/ui/framework/currency-format';
import { Pager } from '@tc-core/model/it/codegen/ui/framework/pager';
import { Sorter } from '@tc-core/model/it/codegen/ui/framework/sorter';
import { SummaryCardData } from '@tc-core/model/it/codegen/ui/framework/summary-card-data';
import { PaxCount } from '../../../../../models/reservation-v2/pax-count';
import { ChangeDetectorService } from '../../../../../services/util/change-detector/change-detector.service';

@Component({
  selector: 'tc-adv-supplier-allocation-search-results',
  templateUrl: './adv-supplier-allocation-search-results.component.html'
})
export class AdvSupplierAllocationSearchResultsComponent implements OnInit {

  @Input() sorter: Sorter;
  @Input() sortingHandler: any;
  @Input() paxCount: PaxCount;
  @Input() sorters: Sorter[] = [];

  @Input() resultsList: Array<SummaryCardData>;
  @Input() selectedSupplier: SummaryCardData = null; // selected supplier card from search results

  @Input() assignedSupplier: any; //assigned supplier from direct assignment or early assignments
  @Input() currencyFormat: CurrencyFormat;
  @Input() pager: Pager;

  @Output() supplierSelect: EventEmitter<any> = new EventEmitter();
  @Output() cardScroll: EventEmitter<number> = new EventEmitter<number>();
  @Output() actionClick: EventEmitter<any> = new EventEmitter();
  @Output() outsideSelect: EventEmitter<any> = new EventEmitter();
  @Output() headerClick: EventEmitter<any> = new EventEmitter();

  selectedCard: SummaryCardData;
  footerDataMap: any;
  public expandedPanelIndex: number;

  constructor(
      private changeDetector: ChangeDetectorService
  ) {
  }

  ngOnInit() {
  }

  public onSupplierSelection(summaryCardData: SummaryCardData, index?: number) {
    this.selectedSupplier = summaryCardData;

    if (summaryCardData) {
      this.supplierSelect.emit(this.selectedSupplier);
      this.cardScroll.emit(index);
    }
  }

  onActionClick(summaryCardData, event) {
    this.selectedSupplier = summaryCardData;

    if (summaryCardData) {
      this.actionClick.emit(event);
      this.supplierSelect.emit(this.selectedSupplier);
    }
  }

  public onExpand(summaryCardData: SummaryCardData) {
    this.supplierSelect.emit(summaryCardData);
  }

  public onHeaderClick(summaryCardData: SummaryCardData) {
    this.headerClick.emit(summaryCardData);
    this.selectedCard = summaryCardData;
    this.supplierSelect.emit(summaryCardData);
    this.changeDetector.notifySupplierCostChange();

  }

  public getHeader(resource: SummaryCardData) {
    return resource.data.value['supplierName'];
  }

  public getSecondaryHeaderText(selectedSupplier: SummaryCardData) {
    return '';
    // return selectedSupplier.data.value['city'] + ' | ' + selectedSupplier.data.value['country'];
  }

  public  onOutsideClick(summaryCardData: SummaryCardData) {
    this.changeDetector.notifySupplierCostChange();
    this.outsideSelect.emit(summaryCardData);
    this.selectedCard = summaryCardData;
    this.supplierSelect.emit(summaryCardData);
  }

  public hasSelectedThis(supplier: SummaryCardData): boolean {
    if (this.assignedSupplier && this.assignedSupplier.data) {
      return (this.assignedSupplier.data.value['supplierId'] === supplier.data.value['supplierId']);
    } else if (this.assignedSupplier) {
      return (this.assignedSupplier.supplierId === supplier.data.value['supplierId']);
    } else {
      return false;
    }
  }
}
