import * as tslib_1 from "tslib";
import { Location } from '@angular/common';
import { AfterViewInit, OnDestroy, OnInit, QueryList } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TcApiError } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-api-error';
import { TcErrorType } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-error';
import { TcHttpError } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-http-error';
import { DialogModel, LEVEL } from '@tc-core/model/it/codegen/ui/framework/dialog-model';
import { ModalData } from '@tc-core/model/it/codegen/ui/framework/modal-data';
import { ERROR_LEVEL } from '@tc-core/model/it/codegen/ui/framework/page-section';
import { TC } from '@tc-core/util/constants';
import { EventManager } from '@tc-core/util/framework/event-manager.service';
import { FocusViewManager } from '@tc-core/util/framework/focus-view-manager.service';
import { UserJourneyManager } from '@tc-core/util/framework/user-journey-manager.service';
import { CommonHelper } from '@tc-core/util/helpers/common-helper.service';
import { KebabService } from '@tc-core/util/ui';
import { SpinnerService } from '@tc-core/util/ui/spinner.service';
import { DialogService } from '@tc/dialog/dialog.service';
import { ExpansionPanelDirective } from '@tc/directives/expansion-panel';
import { ModalService } from '@tc/modal/modal.service';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { TCO } from '../../../constants';
import { CountryStateCityWrapper } from '../../../models/common/country-state-city-wrapper';
import { PaymentInfo } from '../../../models/common/payment-info';
import { CurrencySearchCriteria } from '../../../models/criteria/currency-search-criteria';
import { ResourceTypeSearchCriteria } from '../../../models/criteria/resource-type-search-criteria';
import { RoomFacilitySearchCriteria } from '../../../models/criteria/room-facility-search-criteria';
import { RoomTypeSearchCriteria } from '../../../models/criteria/room-type-search-criteria';
import { SupplierSearchCriteria } from '../../../models/criteria/supplier-search-criteria';
import { PageType } from '../../../models/helper/page-type';
import { ContactDetails } from '../../../models/profile/contact-details';
import { DocumentData } from '../../../models/profile/document';
import { GenericGroup } from '../../../models/supplier/collection/generic-group';
import { ResourceCollection } from '../../../models/supplier/collection/resource-collection';
import { ResourceGroup } from '../../../models/supplier/collection/resource-group';
import { RoomGroup } from '../../../models/supplier/collection/room-group';
import { VehicleGroup } from '../../../models/supplier/collection/vehicle-group';
import { ResourceCategory, Supplier } from '../../../models/supplier/supplier';
import { DataHandlerService } from '../../../services/backend-consumers/master-data-handler-service/data-handler.service';
import { AccommodationService } from '../../../services/backend-consumers/setups/accommodation.service';
import { CurrencyService } from '../../../services/backend-consumers/setups/currency.service';
import { ResourceTypeService } from '../../../services/backend-consumers/setups/resource-type.service';
import { SupplierService } from '../../../services/backend-consumers/supplier-service/supplier.service';
import { CountryStateCityService } from '../../../services/business-utils/country-state-city/country-state-city.service';
import { ChangeDetectorService } from '../../../services/util/change-detector/change-detector.service';
import { DMCCommon } from '../../../services/util/common/dmc-common';
import { RootService } from '../../../services/util/core-services/root.service';
import { DataKey, DataStoreService } from '../../../services/util/framework/data-store.service';
import { DMCQueryParamsService } from '../../../services/util/framework/dmc-query-params.service';
import { ResponseUtil } from '../../../services/util/response/response-util.service';
import { DMCLocalStorageService, LocalDataKey } from '../../../services/util/system/dmc-local-storage.service';
import { CardDataHandlerService } from '../../../services/util/ui/card-data-handler-service.service';
import { AdditionalContactsComponent } from '../../../widgets/shared/additional-contacts/additional-contacts.component';
import { RoomsComponent } from '../../../widgets/shared/rooms/rooms.component';
import { TaxSchemesComponent } from '../../../widgets/shared/tax-schemes/tax-schemes.component';
import { AddDocumentsComponent } from '../add-documents/add-documents.component';
import { ManageSupplierAttributesWrapperComponent } from '../manage-supplier-attributes-wrapper/manage-supplier-attributes-wrapper.component';
import { AddNoteComponent } from '../shared/add-note/add-note.component';
import { OccupancySetupComponent } from './occupancy-setup/occupancy-setup.component';
import { SupplierCostSetupComponent } from './supplier-cost-setup/supplier-cost-setup.component';
var SupplierComponent = /** @class */ (function () {
    function SupplierComponent(dialogService, userJourneyManager, location, supplierService, rootService, queryParamService, focusViewManager, em, dataStore, dataHandlerService, resourceTypeService, route, modalService, cardDataHandlerService, commonHelper, common, countryStateCityService, changeDetectorService, spinnerService, kebabService, localStorage, currencyService, accommodationService) {
        this.dialogService = dialogService;
        this.userJourneyManager = userJourneyManager;
        this.location = location;
        this.supplierService = supplierService;
        this.rootService = rootService;
        this.queryParamService = queryParamService;
        this.focusViewManager = focusViewManager;
        this.em = em;
        this.dataStore = dataStore;
        this.dataHandlerService = dataHandlerService;
        this.resourceTypeService = resourceTypeService;
        this.route = route;
        this.modalService = modalService;
        this.cardDataHandlerService = cardDataHandlerService;
        this.commonHelper = commonHelper;
        this.common = common;
        this.countryStateCityService = countryStateCityService;
        this.changeDetectorService = changeDetectorService;
        this.spinnerService = spinnerService;
        this.kebabService = kebabService;
        this.localStorage = localStorage;
        this.currencyService = currencyService;
        this.accommodationService = accommodationService;
        this.supplierPaymentValid = false;
        this.supplierInformationValid = false;
        this.keyControlInformationValid = false;
        this.personalContactValid = false;
        this.additionalContactValid = true;
        this.genericGrpValid = true;
        this.vehicleGrpValid = true;
        this.roomGrpValid = true;
        this.roomsAllocationValid = true;
        this.supplierCostSetupValid = true;
        this.supplierGenCostSetupValid = true;
        this.supplierRoomCostSetupValid = true;
        this.dataChanged = false;
        this.formSaved = false;
        this.isSaveEnable = false;
        this.contactDetails = new ContactDetails();
        this.additionalContactDetails = [];
        this.pageType = '';
        this.testCountry = '';
        this.testState = '';
        this.resourceTypes = [];
        this.selectedResProfTypes = [];
        this.resourceType = '';
        this.validResource = [];
        this.selectedResTypes = [];
        this.supplierTypes = [];
        this.profileTypes = [];
        this.masterRoomFacilities = [];
        this.locales = [];
        this.showTransportCostAddButton = true;
        this.showGenericCostAddButton = true;
        this.showRoomCostAddButton = true;
        this.disableKeyControlEdit = false;
        this.CARD_CONFIG_ADDITIONALCONTACT = 'additionalContact';
        this.additionalContactContents = [];
        // generics
        this.CARD_CONFIG_GENERICS = 'generics';
        this.genericsContents = [];
        this.genericTypes = [];
        this.addingGenericCount = 0;
        // vehicle
        this.CARD_CONFIG_VEHICLES = 'vehicles';
        this.vehiclesContents = [];
        this.vehicleTypes = [];
        this.addingVehiclesCount = 0;
        // rooms
        this.CARD_CONFIG_ROOMS = 'rooms';
        this.roomsContents = [];
        this.roomTypes = [];
        this.addingResourceGrpCount = 0;
        // tax scheme
        this.CARD_CONFIG_TAXSCHEME = 'taxScheme';
        this.taxSchemeContents = [];
        this.formatOptions = [];
        this.dispatchTypes = [];
        this.frequencyOptions = [];
        this.CARD_CONFIG_DOCUMENT = 'documents';
        this.documentContents = [];
        this.addingDocumentCount = 0;
        this.docTypes = [];
        this.supplierAttributes = [];
        this.categories = [];
        this.titles = [];
        this.isNewSupplier = false;
        this.documentTypesSubscription = new Subscription();
        this.roomTypesSubscription = new Subscription();
        this.modalCloseSubscription = new Subscription();
        this.createSupplierObsv = new Subscription();
        this.updateSupplierObsv = new Subscription();
        this.focusviewCloseSubject = new Subscription();
        this.resourceCostDataSubscription = new Subscription();
        this.attributesSubscription = new Subscription();
        // country state city
        this.countryStateCityChangeMap = new Map();
        this.routes = [];
        this.transferModes = [];
        this.CARD_CONFIG_GENERIC_EDIT = 'supplier_edit';
        this.headerTextNew = 'New Supplier Profile';
        this.headerTextEdit = 'Edit Supplier Profile';
        this.costSetupKebabMenu = [];
    }
    SupplierComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.dataStore.set(DataKey.supplierSearchResults, null, true);
        this.supplier = new Supplier();
        this.supplier.paymentInfo = new PaymentInfo();
        this.supplier.activeStatus = true;
        this.supplier.taxSchemes = [];
        this.supplier.documents = [];
        this.supplier.rooms = [];
        this.supplier.contactDetails = [];
        this.supplier.seasons = [];
        this.supplier.supplierAttributes = [];
        this.supplier.supplierItems = [];
        this.selectedResTypes = this.supplier.supplierItems;
        this.showTransportCostAddButton = this.selectedResTypes && this.selectedResTypes.length > 0 &&
            this.selectedResTypes.filter(function (type) { return type['resourceCategory'] === 'transport'; }).length > 0;
        this.showGenericCostAddButton = this.selectedResTypes && this.selectedResTypes.length > 0 &&
            this.selectedResTypes.filter(function (type) { return type['resourceCategory'] === 'generic'; }).length > 0;
        this.showRoomCostAddButton = this.selectedResTypes && this.selectedResTypes.length > 0 &&
            this.selectedResTypes.filter(function (type) { return type['code'] && type['code'].toUpperCase() === 'HTL'; }).length > 0;
        this.dataStore.set(DataKey.selectedCountry, null, true);
        this.pageType = this.route.snapshot.queryParamMap.get('page').split('_')[0];
        this.getPaymentMethods();
        this.getCurrencies();
        this.onChangeCostSetupForm();
        this.onChangeAdditionalContactForm(true);
        this.getUserDetails();
        if (this.pageType === PageType.CREATE) {
            // this.editSupplier = undefined;
            this.dataStore.set(DataKey.selectedSupplierForEdit, null, true);
            this.dataStore.get(DataKey.selectedSupplierForEditAsNew, true)
                .pipe(take(1))
                .subscribe(function (data) {
                if (data != null) {
                    _this.dataStore.set(DataKey.selectedSupplierForEditAsNew, null, true);
                    if (data.paymentInfo == null) {
                        data.paymentInfo = new PaymentInfo();
                    }
                    _this.fillSupplierObject(data);
                }
                else {
                    var data_1 = _this.localStorage.load(LocalDataKey.unSavedSupplier);
                    if (data_1 && !data_1.supplierId) {
                        if (data_1.paymentInfo == null) {
                            data_1.paymentInfo = new PaymentInfo();
                        }
                        _this.fillSupplierObject(data_1);
                    }
                }
                _this.initPageSectionReferences();
            });
            this.isNewSupplier = true;
        }
        else if (this.pageType === PageType.EDIT) {
            this.isNewSupplier = false;
            this.dataStore.get(DataKey.selectedSupplierForEdit, true).subscribe(function (data) {
                if (data != null) {
                    _this.spinnerService.hide();
                    _this.editSupplierUnedited = JSON.parse(JSON.stringify(data));
                    _this.disableKeyControlEdit = _this.editSupplierUnedited.isEditTriggered;
                    _this.fillSupplierObject(data);
                }
                else {
                    var data_2 = _this.localStorage.load(LocalDataKey.unSavedSupplier);
                    _this.editSupplierUnedited = JSON.parse(JSON.stringify(data_2));
                    _this.fillSupplierObject(data_2);
                }
                _this.initPageSectionReferences();
            });
        }
        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'SAVE').subscribe(function (e) {
            _this.saveSupplier();
        });
        this.retrieveMasterData();
        this.getSupplierAttributes();
        // country state city
        this.subscribeCountryStateCityListChange();
        // --country state city--//
    };
    /**
     * adding supplier object from loaded data store
     * @param data
     */
    SupplierComponent.prototype.fillSupplierObject = function (data) {
        var e_1, _a, e_2, _b, e_3, _c, e_4, _d;
        this.supplier = data;
        if (this.supplier) {
            this.localStorage.store(LocalDataKey.unSavedSupplier, this.supplier);
            if (data && data.contactDetails) {
                this.contactDetails = data.contactDetails.filter(function (e) { return e['status'] === true; })[0];
                this.additionalContactDetails = data.contactDetails.filter(function (e) { return e['status'] === false; });
            }
            if (this.supplier.supplierItems) {
                this.selectedResTypes = [];
                this.validResource = [];
                try {
                    for (var _e = tslib_1.__values(this.supplier.supplierItems), _f = _e.next(); !_f.done; _f = _e.next()) {
                        var item = _f.value;
                        if (!this.findObject(this.validResource, item)) {
                            this.selectedResTypes.push(item);
                            this.validResource.push(item.code);
                        }
                    }
                }
                catch (e_1_1) { e_1 = { error: e_1_1 }; }
                finally {
                    try {
                        if (_f && !_f.done && (_a = _e.return)) _a.call(_e);
                    }
                    finally { if (e_1) throw e_1.error; }
                }
            }
            if (!this.supplier.paymentInfo) {
                this.supplier.paymentInfo = new PaymentInfo();
            }
            this.supplierID = this.supplier.supplierId;
            if (!this.supplier.resourceCollection) {
                var resourceCollection = new ResourceCollection();
                resourceCollection.roomCollection = [];
                resourceCollection.vehicleCollection = [];
                resourceCollection.genericCollection = [];
                this.supplier.resourceCollection = resourceCollection;
            }
            else {
                if (this.supplier.resourceCollection.roomCollection) {
                    try {
                        for (var _g = tslib_1.__values(this.supplier.resourceCollection.roomCollection), _h = _g.next(); !_h.done; _h = _g.next()) {
                            var resourceGroup = _h.value;
                            resourceGroup.tempId = this.addingResourceGrpCount++;
                        }
                    }
                    catch (e_2_1) { e_2 = { error: e_2_1 }; }
                    finally {
                        try {
                            if (_h && !_h.done && (_b = _g.return)) _b.call(_g);
                        }
                        finally { if (e_2) throw e_2.error; }
                    }
                }
                else {
                    this.supplier.resourceCollection.roomCollection = [];
                }
                if (this.supplier.resourceCollection.genericCollection) {
                    try {
                        for (var _j = tslib_1.__values(this.supplier.resourceCollection.genericCollection), _k = _j.next(); !_k.done; _k = _j.next()) {
                            var resourceGroup = _k.value;
                            resourceGroup.tempId = this.addingResourceGrpCount++;
                        }
                    }
                    catch (e_3_1) { e_3 = { error: e_3_1 }; }
                    finally {
                        try {
                            if (_k && !_k.done && (_c = _j.return)) _c.call(_j);
                        }
                        finally { if (e_3) throw e_3.error; }
                    }
                }
                else {
                    this.supplier.resourceCollection.genericCollection = [];
                }
                if (this.supplier.resourceCollection.vehicleCollection) {
                    try {
                        for (var _l = tslib_1.__values(this.supplier.resourceCollection.vehicleCollection), _m = _l.next(); !_m.done; _m = _l.next()) {
                            var resourceGroup = _m.value;
                            resourceGroup.tempId = this.addingResourceGrpCount++;
                        }
                    }
                    catch (e_4_1) { e_4 = { error: e_4_1 }; }
                    finally {
                        try {
                            if (_m && !_m.done && (_d = _l.return)) _d.call(_l);
                        }
                        finally { if (e_4) throw e_4.error; }
                    }
                }
                else {
                    this.supplier.resourceCollection.vehicleCollection = [];
                }
            }
            this.onChangeSupplierInfoForm(true);
            this.onChangeSupplierPaymentForm(true);
            this.onChangePersonalContactForm(true);
            this.onChangeRooms(true);
        }
    };
    SupplierComponent.prototype.findObject = function (array, item) {
        var e_5, _a;
        var found = null;
        try {
            for (var array_1 = tslib_1.__values(array), array_1_1 = array_1.next(); !array_1_1.done; array_1_1 = array_1.next()) {
                var obj = array_1_1.value;
                if (obj === item['code']) {
                    found = obj;
                    return found;
                }
            }
        }
        catch (e_5_1) { e_5 = { error: e_5_1 }; }
        finally {
            try {
                if (array_1_1 && !array_1_1.done && (_a = array_1.return)) _a.call(array_1);
            }
            finally { if (e_5) throw e_5.error; }
        }
        if (!found) {
            return found;
        }
    };
    SupplierComponent.prototype.initPageSectionReferences = function () {
        this.setKebabMenus();
        this.addKebabMenuActions();
        this.pageSectionsMap = this.cardDataHandlerService.getAllPageSections(this.CARD_CONFIG_GENERIC_EDIT, this.supplier);
        this.supplierInfoSection = this.pageSectionsMap.get('supplier_info');
        this.supplierKeyControlSection = this.pageSectionsMap.get('supplier_key_controls');
        this.contactDetailsSection = this.pageSectionsMap.get('contact_details');
        this.paymentInfoSection = this.pageSectionsMap.get('payment_details');
        this.additionalContactsSection = this.pageSectionsMap.get('additional_contacts');
        this.supplierCostSetupSection = this.pageSectionsMap.get('supplier_cost_setup');
        this.supplierAttributesSection = this.pageSectionsMap.get('supplier_attributes');
        this.genericGrpSection = this.pageSectionsMap.get('generics');
        this.vehicleGrpSection = this.pageSectionsMap.get('vehicles');
        this.roomGrpSection = this.pageSectionsMap.get('rooms');
        this.roomAllocationSection = this.pageSectionsMap.get('roomAllocations');
        this.taxSchemeSection = this.pageSectionsMap.get('tax_schemes');
        this.documentSection = this.pageSectionsMap.get('documents');
        this.setupHeader();
        this.filterPageSectionActions();
    };
    SupplierComponent.prototype.filterPageSectionActions = function () {
        // if (this.supplier && this.supplier.supplierItems.includes()) {
        //     this.taxSchemeSection.actions = this.taxSchemeSection.actions.filter(action => {
        //         return action !== 'TRIGGER_ADD_TAX_SCHEME';
        //     });
        // }
    };
    SupplierComponent.prototype.onHeaderActionClick = function (event) {
        switch (event.id) {
            case 'TRIGGER_ADD_ADDITIONAL_CONTACT': {
                console.log('add addition contact');
                this.additionalContactComponent.addAdditionalContact();
                break;
            }
            case 'TRIGGER_SET_SUPPLIER_ATTRIBUTES': {
                console.log('add supplier attribute');
                this.onSupplierAttributesClick();
                break;
            }
            case 'TRIGGER_ADD_NOTE': {
                console.log('add note');
                this.onAddNote();
                break;
            }
            case 'TRIGGER_ADD_TAX_SCHEME': {
                console.log('add tax scheme');
                this.taxSchemeComponent.addTaxScheme();
                break;
            }
            case 'TRIGGER_ADD_DOCUMENTS': {
                console.log('add document');
                this.onAddDocument();
                break;
            }
            case 'TRIGGER_ADD_GENERIC_GRP': {
                console.log('add rooms');
                this.onAddGenericGrp();
                break;
            }
            case 'TRIGGER_ADD_VEHICLE_GRP': {
                console.log('add rooms');
                this.onAddVehicleGrp();
                break;
            }
            case 'TRIGGER_ADD_ROOM_GRP': {
                console.log('add rooms');
                this.onAddRoomGrp();
                break;
            }
        }
    };
    SupplierComponent.prototype.setupSectionTabError = function (section) {
        if (!section) {
            return false;
        }
        var shouldShow = section.showError === ERROR_LEVEL.SHOW_ERROR_TAB || section.showError ===
            ERROR_LEVEL.SHOW_ERROR_TAB_FORM;
        return section.error && shouldShow;
    };
    SupplierComponent.prototype.setupHeader = function () {
        if (this.pageType === PageType.CREATE) {
            this.userJourneyManager.setCurrentHeading(this.headerTextNew);
        }
        else if (this.pageType === PageType.EDIT) {
            this.userJourneyManager.setCurrentHeading(this.headerTextEdit);
        }
        this.setHeaderActions();
        this.setHeaderActionTriggers();
        // this.setHeaderKebab();
    };
    SupplierComponent.prototype.setHeaderActions = function () {
        var actionKeys = [];
        actionKeys.push('TRIGGER_CLOSE');
        this.userJourneyManager.setHeaderActions(actionKeys);
    };
    SupplierComponent.prototype.removeHeaderActions = function () {
        this.userJourneyManager.setHeaderActions([]);
        this.em.removeEvents([
            'TRIGGER_CLOSE'
        ]);
    };
    SupplierComponent.prototype.setHeaderActionTriggers = function () {
        var _this = this;
        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'TRIGGER_CLOSE').subscribe(function (e) {
            _this.onCloseClick(null);
        });
    };
    SupplierComponent.prototype.onCloseClick = function (event) {
        this.location.back();
    };
    SupplierComponent.prototype.addKebabMenuActions = function () {
        this.addCostSetupKebabMenuActions();
    };
    SupplierComponent.prototype.setKebabMenus = function () {
        this.costSetupKebabMenu = this.kebabService.get('SUPPLIER_COST_SETUP').items;
    };
    SupplierComponent.prototype.addCostSetupKebabMenuActions = function () {
        var _this = this;
        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'TRIGGER_SEASON_SETUP').subscribe(function (e) {
            _this.supplierCostSetupComponent.onSeasonSetupClick();
        });
        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'TRIGGER_TRA_COST_SETUP').subscribe(function (e) {
            if (_this.showTransportCostAddButton) {
                _this.supplierCostSetupComponent.onTransportCostSetupClick();
            }
            else {
                var confirmError = new DialogModel(true, LEVEL.ERROR, 'Failed', 'Please add transport resource types', true, 2000);
                _this.dialogService
                    .confirm(confirmError)
                    .subscribe(function (res) {
                    if (res === true) {
                        console.log('true');
                        /* this.dataStore.set(DataKey.cart, null, true);
                         this.spinnerService.show();
                         this.addPassengers();*/
                    }
                });
            }
        });
        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'TRIGGER_GEN_COST_SETUP').subscribe(function (e) {
            if (_this.showGenericCostAddButton) {
                _this.supplierCostSetupComponent.onGenericCostSetupClick();
            }
            else {
                var confirmError = new DialogModel(true, LEVEL.ERROR, 'Failed', 'Please add generic resource types', true, 2000);
                _this.dialogService
                    .confirm(confirmError)
                    .subscribe(function (res) {
                    if (res === true) {
                        console.log('true');
                        /* this.dataStore.set(DataKey.cart, null, true);
                         this.spinnerService.show();
                         this.addPassengers();*/
                    }
                });
            }
        });
        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'TRIGGER_ROOM_COST_SETUP').subscribe(function (e) {
            if (_this.showRoomCostAddButton) {
                _this.supplierCostSetupComponent.onRoomCostSetupClick();
            }
            else {
                var confirmError = new DialogModel(true, LEVEL.ERROR, 'Failed', 'Please add room resource types', true, 2000);
                _this.dialogService
                    .confirm(confirmError)
                    .subscribe(function (res) {
                    if (res === true) {
                        console.log('true');
                        /* this.dataStore.set(DataKey.cart, null, true);
                         this.spinnerService.show();
                         this.addPassengers();*/
                    }
                });
            }
        });
    };
    SupplierComponent.prototype.retrieveMasterData = function () {
        var _this = this;
        this.dataHandlerService.retrieveTitleDetails().subscribe(function (result) {
            _this.titles = result.data;
        });
        this.dataHandlerService.retrieveCategories().subscribe(function (result) {
            _this.categories = result.data;
            _this.defaultContactCategory = _this.categories.find(function (value) { return value.name === 'Reservation'; });
        });
        this.resourceType = this.route.snapshot.queryParamMap.get('page').split('_')[1];
        this.supplier.resourceCategory = ResourceCategory.GENERIC;
        // if (this.resourceType === 'generic') {
        //     this.supplier.resourceCategory = ResourceCategory.GENERIC;
        //     this.showCostAddButton = false;
        // } else {
        //     this.supplier.resourceCategory = ResourceCategory.TRANSPORT;
        // }
        var resourceTypeSearchCriteria = new ResourceTypeSearchCriteria();
        resourceTypeSearchCriteria.size = 1000000;
        this.resourceTypeService.getResourceTypes(resourceTypeSearchCriteria).subscribe(function (result) {
            if (result.data) {
                result.data.forEach(function (type) {
                    _this.resourceTypes.push(type);
                });
            }
        });
        // this.resourceTypes = result['data']['resourceTypes'];
        // console.log(this.resourceType);
        this.dataHandlerService.retrieveTaxSchemes().subscribe(function (result) {
            _this.taxSchemes = result.data;
        });
        this.dataHandlerService.retrieveDocumentTypes().subscribe(function (result) {
            _this.docTypeOptions = result.data;
        });
        this.dataHandlerService.retrieveFormatOptions().subscribe(function (result) {
            _this.formatOptions = result.data;
        });
        this.dataHandlerService.retrieveDispatchTypes().subscribe(function (result) {
            _this.dispatchTypes = result.data;
        });
        this.dataHandlerService.retrieveFrequencyOptions().subscribe(function (result) {
            _this.frequencyOptions = result.data;
        });
        this.dataHandlerService.retrieveSupplierTypes().subscribe(function (result) {
            _this.supplierTypes = result.data;
        });
        this.dataHandlerService.getProfileTypes().subscribe(function (result) {
            _this.profileTypes = result.data;
        });
        this.dataHandlerService.retrieveLocales().subscribe(function (result) {
            _this.locales = result.data;
        });
        this.dataStore.get(DataKey.routes).subscribe(function (data) {
            if (data !== null) {
                _this.routes = data;
            }
            else {
                _this.routes = [];
            }
        });
        var x = this.dataStore.get(DataKey.transferModes).subscribe(function (data) {
            if (data !== null) {
                _this.transferModes = data;
            }
            else {
                _this.transferModes = [];
            }
        });
        this.accommodationService.getRoomTypes(new RoomTypeSearchCriteria()).subscribe(function (data) {
            _this.roomTypeOptions = data.data;
        });
        this.accommodationService.getRoomFacilities(new RoomFacilitySearchCriteria()).subscribe(function (data) {
            _this.masterRoomFacilities = data.data;
        });
    };
    SupplierComponent.prototype.onAddNote = function () {
        var _this = this;
        this.dataStore.set(DataKey.modalClose, null);
        var dataObject = new ModalData(LEVEL.SUCCESS, 'Add a note', null, null, 'payment-modal', AddNoteComponent, {});
        this.confirmModal(dataObject);
        this.modalCloseSubscription = this.dataStore.get(DataKey.modalClose).subscribe(function (data) {
            if (data != null) {
                _this.modalCloseSubscription.unsubscribe();
                _this.closeModal();
            }
        });
    };
    SupplierComponent.prototype.onAddDocument = function () {
        var _this = this;
        this.setOldDocsTypesAndSubscribeForNewTypes();
        this.dataStore.set(DataKey.modalClose, null);
        var dataObject = new ModalData(LEVEL.SUCCESS, 'Add Documents', null, null, 'modal-basic--alt modal-basic--small add-service-menu', AddDocumentsComponent, {
            'docTypeOptions': this.docTypeOptions,
            'selectedDocs': this.docTypes
        });
        this.confirmModal(dataObject);
        this.modalCloseSubscription = this.dataStore.get(DataKey.modalClose).subscribe(function (data) {
            if (data != null) {
                _this.modalCloseSubscription.unsubscribe();
                _this.closeModal();
            }
        });
    };
    SupplierComponent.prototype.onAddGenericGrp = function () {
        var resourceGroup = new ResourceGroup();
        resourceGroup.genericGroup = new GenericGroup();
        resourceGroup.tempId = this.addingResourceGrpCount++;
        resourceGroup.resourceGroupAvailabilities = [];
        resourceGroup.noOfUnits = 0;
        this.supplier.resourceCollection.genericCollection.push(resourceGroup);
        this.expansionPanelDirective.forEach(function (expansionElement) {
            if (expansionElement.id === 'generic_summary_strip') {
                expansionElement.expansionState.next(false);
            }
        });
    };
    SupplierComponent.prototype.onAddVehicleGrp = function () {
        var resourceGroup = new ResourceGroup();
        resourceGroup.vehicleGroup = new VehicleGroup();
        resourceGroup.tempId = this.addingResourceGrpCount++;
        resourceGroup.resourceGroupAvailabilities = [];
        resourceGroup.noOfUnits = 0;
        this.supplier.resourceCollection.vehicleCollection.push(resourceGroup);
        this.expansionPanelDirective.forEach(function (expansionElement) {
            if (expansionElement.id === 'vehicle_summary_strip') {
                expansionElement.expansionState.next(false);
            }
        });
    };
    SupplierComponent.prototype.onAddRoomGrp = function () {
        var resourceGroup = new ResourceGroup();
        resourceGroup.roomGroup = new RoomGroup();
        resourceGroup.tempId = this.addingResourceGrpCount++;
        resourceGroup.resourceGroupAvailabilities = [];
        resourceGroup.noOfUnits = 0;
        resourceGroup.roomGroup.roomFacilities = [];
        this.supplier.resourceCollection.roomCollection.push(resourceGroup);
        this.onRoomGrpAddOrDelete();
        this.expansionPanelDirective.forEach(function (expansionElement) {
            if (expansionElement.id === 'room_summary_strip') {
                expansionElement.expansionState.next(false);
            }
        });
    };
    SupplierComponent.prototype.closeModal = function () {
        if (this.modalService.close()) {
            this.modalService.close().subscribe(function (res) {
            });
        }
    };
    SupplierComponent.prototype.getSupplierAttributes = function () {
        var _this = this;
        this.dataStore.set(DataKey.supplierAttributes, null, true);
        this.attributesSubscription = this.dataStore.get(DataKey.supplierAttributes).subscribe(function (data) {
            if (data != null) {
                _this.supplier.supplierAttributes = data;
                _this.dataChanged = true;
                _this.enableSaveButton();
            }
        });
    };
    SupplierComponent.prototype.onAddressModified = function () {
        var _this = this;
        this.additionalContactSummary = this.cardDataHandlerService.processCardData(this.CARD_CONFIG_ADDITIONALCONTACT, this.additionalContactDetails, 'additional_contact_summary');
        if (this.additionalContactDetails.length > 0) {
            // this.additionalContactValid = false;
            this.additionalContactContents = [];
            this.additionalContactDetails.forEach(function (address) {
                var additionalContactContent = _this.cardDataHandlerService.processCardData(_this.CARD_CONFIG_ADDITIONALCONTACT, address, 'additional_contact_detail');
                _this.additionalContactContents.push(additionalContactContent);
            });
        }
    };
    SupplierComponent.prototype.onGenericsModified = function () {
        var _this = this;
        var e_6, _a;
        this.genericsSummary = this.cardDataHandlerService.processCardData(this.CARD_CONFIG_GENERICS, this.supplier.resourceCollection.genericCollection, 'generics_summary');
        var valid = true;
        if (this.supplier.resourceCollection.genericCollection && this.supplier.resourceCollection.genericCollection.length > 0) {
            this.genericsContents = [];
            this.supplier.resourceCollection.genericCollection.forEach(function (genGrp) {
                var genContent = _this.cardDataHandlerService.processCardData(_this.CARD_CONFIG_GENERICS, genGrp, 'generics_detail');
                _this.genericsContents.push(genContent);
            });
            try {
                for (var _b = tslib_1.__values(this.supplier.resourceCollection.genericCollection), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var genGrp = _c.value;
                    if (genGrp.resourceType && genGrp.genTypeCode && genGrp.noOfUnits) {
                    }
                    else {
                        valid = false;
                        break;
                    }
                }
            }
            catch (e_6_1) { e_6 = { error: e_6_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_6) throw e_6.error; }
            }
        }
        this.onChangeRooms(valid);
    };
    SupplierComponent.prototype.onVehiclesModified = function () {
        var _this = this;
        var e_7, _a;
        this.vehiclesSummary = this.cardDataHandlerService.processCardData(this.CARD_CONFIG_VEHICLES, this.supplier.resourceCollection.vehicleCollection, 'vehicles_summary');
        var valid = true;
        if (this.supplier.resourceCollection.vehicleCollection && this.supplier.resourceCollection.vehicleCollection.length > 0) {
            this.vehiclesContents = [];
            this.supplier.resourceCollection.vehicleCollection.forEach(function (genGrp) {
                var genContent = _this.cardDataHandlerService.processCardData(_this.CARD_CONFIG_VEHICLES, genGrp, 'vehicles_detail');
                _this.vehiclesContents.push(genContent);
            });
            try {
                for (var _b = tslib_1.__values(this.supplier.resourceCollection.vehicleCollection), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var vehGrp = _c.value;
                    if (vehGrp.resourceType && vehGrp.genTypeCode && vehGrp.noOfUnits && vehGrp.transferMode && vehGrp.vehicleModelCode) {
                    }
                    else {
                        valid = false;
                        break;
                    }
                }
            }
            catch (e_7_1) { e_7 = { error: e_7_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_7) throw e_7.error; }
            }
        }
        this.onChangeRooms(valid);
    };
    SupplierComponent.prototype.onRoomGrpAddOrDelete = function () {
        var e_8, _a;
        var valid = true;
        if (this.supplier.resourceCollection.roomCollection && this.supplier.resourceCollection.roomCollection.length > 0) {
            this.roomsContents = [];
            try {
                for (var _b = tslib_1.__values(this.supplier.resourceCollection.roomCollection), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var roomGrp = _c.value;
                    if (roomGrp.resourceType && roomGrp.genTypeCode && roomGrp.noOfUnits && roomGrp.roomTypeCode && roomGrp.roomGroup &&
                        roomGrp.roomGroup.minimumOccupancy && roomGrp.roomGroup.maximumOccupancy && roomGrp.roomGroup.standardOccupancy) {
                    }
                    else {
                        valid = false;
                        break;
                    }
                }
            }
            catch (e_8_1) { e_8 = { error: e_8_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_8) throw e_8.error; }
            }
        }
        this.onChangeRooms(valid);
    };
    SupplierComponent.prototype.onOccupancyClick = function (tempId) {
        var _this = this;
        var roomGrp = this.supplier.resourceCollection.roomCollection.find(function (value) { return value.tempId === tempId; });
        this.dataStore.set(DataKey.popupClose, null);
        var dataObject = new ModalData(LEVEL.SUCCESS, 'Occupancies of \'' + roomGrp.roomTypeName + '\' room group', { label: 'OK' }, { label: 'Cancel' }, 'split-passengers-modal', OccupancySetupComponent, {
            roomGroup: roomGrp
        }, '', '', '', '', '', '  ');
        this.confirmModal(dataObject);
        this.dataStore.get(DataKey.popupClose).subscribe(function (data) {
            if (data != null) {
                _this.closeModal();
                _this.onRoomGrpAddOrDelete();
            }
        });
    };
    SupplierComponent.prototype.onTaxSchemeModified = function () {
        var _this = this;
        this.taxSchemeSummary = this.cardDataHandlerService.processCardData(this.CARD_CONFIG_TAXSCHEME, this.supplier.taxSchemes, 'tax_scheme_summary');
        if (this.supplier.taxSchemes.length > 0) {
            this.taxSchemeContents = [];
            this.supplier.taxSchemes.forEach(function (address) {
                var taxSchemeContent = _this.cardDataHandlerService.processCardData(_this.CARD_CONFIG_TAXSCHEME, address, 'tax_scheme_detail');
                _this.taxSchemeContents.push(taxSchemeContent);
            });
        }
    };
    SupplierComponent.prototype.onDocumentsModified = function () {
        var _this = this;
        this.documentSummary = this.cardDataHandlerService.processCardData(this.CARD_CONFIG_DOCUMENT, this.supplier.documents, 'document_summary');
        if (this.supplier.documents.length > 0) {
            this.documentContents = [];
            this.supplier.documents.forEach(function (doc) {
                var documentContent = _this.cardDataHandlerService.processCardData(_this.CARD_CONFIG_DOCUMENT, doc, 'document_detail');
                _this.documentContents.push(documentContent);
            });
        }
    };
    SupplierComponent.prototype.onAddDocuments = function () {
        var _this = this;
        this.setOldDocsTypesAndSubscribeForNewTypes();
        this.dataStore.set(DataKey.modalClose, null);
        var dataObject = new ModalData(LEVEL.SUCCESS, 'Add Documents', null, null, 'modal-basic--alt modal-basic--small add-service-menu', AddDocumentsComponent, {
            'docTypeOptions': this.docTypeOptions,
            'selectedDocs': this.docTypes
        });
        this.confirmModal(dataObject);
        this.modalCloseSubscription = this.dataStore.get(DataKey.modalClose).subscribe(function (data) {
            if (data != null) {
                _this.modalCloseSubscription.unsubscribe();
                _this.closeModal();
            }
        });
    };
    SupplierComponent.prototype.setOldDocsTypesAndSubscribeForNewTypes = function () {
        var _this = this;
        var oldDocTypes = [];
        this.supplier.documents.forEach(function (doc) {
            var docTypeOption = _this.docTypeOptions.find(function (docType) { return docType.code === doc.template; });
            if (docTypeOption) {
                oldDocTypes.push(docTypeOption);
                doc.name = docTypeOption.name;
            }
        });
        this.dataStore.set(DataKey.documentTypes, oldDocTypes, true);
        this.getDocumentTypes();
    };
    SupplierComponent.prototype.setOldRoomTypesAndSubscribeForNewTypes = function () {
        var _this = this;
        var oldRoomTypes = [];
        this.supplier.rooms.forEach(function (room) {
            var roomTypeOption = _this.roomTypeOptions.find(function (roomType) { return roomType.name === room.roomType; });
            if (roomTypeOption) {
                oldRoomTypes.push(roomTypeOption);
                room.roomType = roomTypeOption.name;
            }
        });
        this.dataStore.set(DataKey.roomTypes, oldRoomTypes, true);
    };
    SupplierComponent.prototype.getDocumentTypes = function () {
        var _this = this;
        this.documentTypesSubscription = this.dataStore.get(DataKey.documentTypes).subscribe(function (data) {
            if (data != null) {
                _this.docTypes = data;
                var existingDocTypes_1 = [];
                _this.supplier.documents.forEach(function (doc) {
                    var DocTypes_ = _this.docTypes.find(function (docType) { return docType.code === doc.template; });
                    existingDocTypes_1.push(DocTypes_);
                });
                var newTypes = _this.docTypes.filter(function (docType) { return !existingDocTypes_1.includes(docType); });
                if (newTypes.length) {
                    newTypes.forEach(function (docType) {
                        var document = new DocumentData();
                        document.tempId = _this.addingDocumentCount++;
                        document.name = docType.name;
                        document.template = docType.code;
                        _this.supplier.documents.push(document);
                        _this.expansionPanelDirective.forEach(function (expansionElement) {
                            if (expansionElement.id === 'document_summary_strip') {
                                expansionElement.expansionState.next(false);
                            }
                        });
                    });
                }
            }
            _this.onDocumentsModified();
        });
    };
    SupplierComponent.prototype.onChangeSupplierInfoForm = function (event) {
        this.supplierInformationValid = event;
        this.enableSaveButton();
        if (this.supplierInfoSection) {
            this.supplierInfoSection.error = !this.supplierInformationValid;
        }
    };
    SupplierComponent.prototype.onChangeKeyControlInfoForm = function (event) {
        this.keyControlInformationValid = event;
        this.enableSaveButton();
        if (this.supplierKeyControlSection) {
            this.supplierKeyControlSection.error = !this.keyControlInformationValid;
        }
    };
    SupplierComponent.prototype.onChangeSupplierPaymentForm = function (event) {
        this.supplierPaymentValid = event;
        this.enableSaveButton();
        if (this.paymentInfoSection) {
            this.paymentInfoSection.error = !this.supplierInformationValid;
        }
    };
    SupplierComponent.prototype.onChangeCostSetupForm = function () {
        var _this = this;
        var x = this.dataStore.get(DataKey.supplierTrsCostSetupValid).subscribe(function (data) {
            if (data !== null) {
                _this.supplierCostSetupValid = data;
                if (_this.supplierCostSetupSection) {
                    _this.supplierCostSetupSection.error = !_this.supplierCostSetupValid;
                }
                _this.enableSaveButton();
            }
        });
        var y = this.dataStore.get(DataKey.supplierGenCostSetupValid).subscribe(function (data) {
            if (data !== null) {
                _this.supplierGenCostSetupValid = data;
                if (_this.supplierCostSetupSection) {
                    _this.supplierCostSetupSection.error = !_this.supplierGenCostSetupValid;
                }
                _this.enableSaveButton();
            }
        });
        var z = this.dataStore.get(DataKey.supplierRoomCostSetupValid).subscribe(function (data) {
            if (data !== null) {
                _this.supplierRoomCostSetupValid = data;
                if (_this.supplierCostSetupSection) {
                    _this.supplierCostSetupSection.error = !_this.supplierRoomCostSetupValid;
                }
                _this.enableSaveButton();
            }
        });
    };
    SupplierComponent.prototype.onChangePersonalContactForm = function (event) {
        this.personalContactValid = event;
        if (this.contactDetailsSection) {
            this.contactDetailsSection.error = !this.personalContactValid;
        }
        this.enableSaveButton();
    };
    SupplierComponent.prototype.onChangeAdditionalContactForm = function (event) {
        this.additionalContactValid = event;
        if (this.additionalContactsSection) {
            this.additionalContactsSection.error = !this.additionalContactValid;
        }
        this.enableSaveButton();
    };
    SupplierComponent.prototype.onChangeGenerics = function (event) {
        this.genericGrpValid = event;
        if (this.genericGrpSection) {
            this.genericGrpSection.error = !this.genericGrpValid;
        }
        this.enableSaveButton();
    };
    SupplierComponent.prototype.onChangeVehicles = function (event) {
        this.vehicleGrpValid = event;
        if (this.vehicleGrpSection) {
            this.vehicleGrpSection.error = !this.vehicleGrpValid;
        }
        this.enableSaveButton();
    };
    SupplierComponent.prototype.onChangeRooms = function (event) {
        var _this = this;
        this.roomsSummary = this.cardDataHandlerService.processCardData(this.CARD_CONFIG_ROOMS, this.supplier.resourceCollection.roomCollection, 'rooms_summary');
        this.supplier.resourceCollection.roomCollection.forEach(function (genGrp) {
            var genContent = _this.cardDataHandlerService.processCardData(_this.CARD_CONFIG_ROOMS, genGrp, 'rooms_detail');
            _this.roomsContents.push(genContent);
        });
        this.roomGrpValid = event;
        if (this.roomGrpSection) {
            this.roomGrpSection.error = !this.roomGrpValid;
        }
        this.enableSaveButton();
    };
    SupplierComponent.prototype.onChangeActiveStatus = function ($event) {
        this.enableSaveButton();
    };
    SupplierComponent.prototype.onChangeServiceRequiredStatus = function ($event) {
        this.enableSaveButton();
    };
    SupplierComponent.prototype.enableSaveButton = function () {
        this.updateDataChanged();
        if ((this.dataChanged && !this.formSaved)
            && this.supplierPaymentValid
            && this.supplierInformationValid
            && this.personalContactValid
            && this.additionalContactValid
            && this.roomGrpValid
            && this.roomsAllocationValid
            && this.supplierCostSetupValid && this.supplierGenCostSetupValid) {
            this.userJourneyManager.canProceed.next(true);
            this.focusViewManager.canProceed.next(true);
            this.isSaveEnable = true;
            this.localStorage.store(LocalDataKey.unSavedSupplier, this.supplier);
        }
        else {
            this.disableSaveButton();
        }
    };
    SupplierComponent.prototype.disableSaveButton = function () {
        this.userJourneyManager.canProceed.next(false);
        this.focusViewManager.canProceed.next(false);
        this.isSaveEnable = false;
    };
    SupplierComponent.prototype.updateDataChanged = function () {
        this.updateSupplierChanges();
        // to make it same as resourceUnedited
        this.supplier.contactDetails.forEach(function (value) {
            if (value) {
                if (value.state === '') {
                    value.state = null;
                }
            }
        });
        if (JSON.stringify(this.supplier) !== JSON.stringify(this.editSupplierUnedited)) {
            this.dataChanged = true;
            this.formSaved = false;
        }
        else {
            this.dataChanged = false;
        }
    };
    SupplierComponent.prototype.updateSupplierChanges = function () {
        var _this = this;
        if (this.supplier) {
            this.supplier.contactDetails = [];
            this.supplier.contactDetails.push(this.contactDetails);
            Array.prototype.push.apply(this.supplier.contactDetails, this.additionalContactDetails);
            this.resourceCostDataSubscription = this.dataStore.get(DataKey.resourceCostData).subscribe(function (data) {
                if (data) {
                    _this.supplier.seasons = data.seasons;
                }
            });
        }
    };
    SupplierComponent.prototype.saveSupplier = function () {
        this.updateSupplierChanges();
        if (this.supplierID) {
            this.dataStore.set(DataKey.updateSupplier, null, true);
            this.updateSupplierObserver();
            if (this.supplier && this.supplier.locale && this.supplier.locale instanceof Array) {
                this.supplier.locale = this.supplier.locale.toString();
            }
            this.supplierService.updateSupplier(this.supplier, this.supplierID);
            this.spinnerService.show();
        }
        else {
            this.dataStore.set(DataKey.saveSupplier, null, true);
            this.createSupplierObserver();
            if (this.supplier && this.supplier.locale && this.supplier.locale instanceof Array) {
                this.supplier.locale = this.supplier.locale.toString();
            }
            this.spinnerService.show();
            this.supplierService.saveSupplier(this.supplier);
        }
    };
    SupplierComponent.prototype.updateSupplierObserver = function () {
        var _this = this;
        this.updateSupplierObsv = this.dataStore.get(DataKey.updateSupplier, true).subscribe(function (result) {
            // console.log(result);
            if (_this.commonHelper.dataValidity(result)) {
                _this.updateSupplierObsv.unsubscribe();
                _this.dataStore.set(DataKey.updateSupplier, null, true);
                _this.spinnerService.hide();
                if (result.status.code === TC.ErrorCodes.SUCCESS) {
                    _this.editSupplierUnedited = JSON.parse(JSON.stringify(_this.supplier));
                    _this.formSaved = true;
                    _this.dataChanged = false;
                    _this.disableSaveButton();
                    _this.localStorage.delete(LocalDataKey.unSavedSupplier);
                    _this.common.showSnackBar('Successfully updated the supplier data', 3000, TcErrorType.TYPE.INFO);
                    _this.handleNavigation(result);
                    // let confirmSuccess = new DialogModel(
                    //     true,
                    //     LEVEL.SUCCESS,
                    //     'Success',
                    //     'Successfully updated the supplier data',
                    //     true,
                    //     2000
                    // );
                    // this.dialogService
                    //     .confirm(confirmSuccess)
                    //     .subscribe((res) => {
                    //         if (res === true) {
                    //             console.log('true');
                    //             /* this.dataStore.set(DataKey.cart, null, true);
                    //              this.spinnerService.show();
                    //              this.addPassengers();*/
                    //         }
                    //     });
                }
                else if (result.status.code === TC.ErrorCodes.ERROR) {
                    var confirmError = new DialogModel(true, LEVEL.ERROR, 'Failed', 'Failed to update the supplier data', true, 2000);
                    _this.dialogService
                        .confirm(confirmError)
                        .subscribe(function (res) {
                        if (res === true) {
                            console.log('true');
                            /* this.dataStore.set(DataKey.cart, null, true);
                             this.spinnerService.show();
                             this.addPassengers();*/
                        }
                    });
                }
            }
            else if (result instanceof TcApiError) {
                _this.spinnerService.hide();
            }
            else if (result instanceof TcHttpError) {
                _this.spinnerService.hide();
            }
        });
    };
    SupplierComponent.prototype.createSupplierObserver = function () {
        var _this = this;
        this.createSupplierObsv = this.dataStore.get(DataKey.saveSupplier, true).subscribe(function (result) {
            // console.log(result);
            if (_this.commonHelper.dataValidity(result)) {
                _this.createSupplierObsv.unsubscribe();
                _this.spinnerService.hide();
                _this.dataStore.set(DataKey.saveSupplier, null, true);
                if (result.status.code === TC.ErrorCodes.SUCCESS) {
                    _this.editSupplierUnedited = JSON.parse(JSON.stringify(_this.supplier));
                    _this.formSaved = true;
                    _this.dataChanged = false;
                    _this.disableSaveButton();
                    _this.localStorage.delete(LocalDataKey.unSavedSupplier);
                    _this.common.showSnackBar('Supplier Saved Successfully.', 3000, TcErrorType.TYPE.INFO);
                    _this.handleNavigation(result);
                }
                else if (result.status.code === TC.ErrorCodes.ERROR) {
                    if (result.error.message.includes('OP_SUPPLIER_UK1')) {
                        var confirmError = new DialogModel(true, LEVEL.ERROR, 'CONFLICT', 'Supplier Code is already exists', true, 2000);
                        _this.dialogService
                            .confirm(confirmError)
                            .subscribe(function (res) {
                            if (res === true) {
                                console.log('true');
                            }
                        });
                    }
                    else {
                        var confirmError = new DialogModel(true, LEVEL.ERROR, 'Failed', 'Failed to Save the Supplier Details', true, 2000);
                        _this.dialogService
                            .confirm(confirmError)
                            .subscribe(function (res) {
                            if (res === true) {
                                console.log('true');
                            }
                        });
                    }
                }
            }
            else if (result instanceof TcApiError) {
                _this.spinnerService.hide();
            }
            else if (result instanceof TcHttpError) {
                _this.spinnerService.hide();
            }
        });
    };
    // country state city
    SupplierComponent.prototype.onNewCountryStateCityObserver = function ($event) {
        var _this = this;
        var wrapper = new CountryStateCityWrapper();
        this.countryStateCityChangeMap.set($event.id, wrapper);
        this.countryStateCityService.getInitialCity($event.countryCode, $event.stateCode, $event.cityCode)
            .then(function (value) {
            if (value) {
                wrapper.city = value;
                _this.changeDetectorService.notifyCountryStateCityChange();
            }
        });
        this.countryStateCityService.getInitialState($event.countryCode, $event.stateCode)
            .then(function (value) {
            if (value) {
                wrapper.state = value;
                _this.changeDetectorService.notifyCountryStateCityChange();
            }
        });
        this.countryStateCityService.getInitialCountry($event.countryCode)
            .then(function (value) {
            if (value) {
                wrapper.country = value;
                _this.changeDetectorService.notifyCountryStateCityChange();
            }
        });
    };
    SupplierComponent.prototype.onSelectCountry = function ($event) {
        var wrapper = this.countryStateCityChangeMap.get($event.id);
        this.countryStateCityService.onSelectCountry($event.country, wrapper);
        this.changeDetectorService.notifyCountryStateCityChange();
    };
    SupplierComponent.prototype.onSelectState = function ($event) {
        var wrapper = this.countryStateCityChangeMap.get($event.id);
        this.countryStateCityService.onSelectState($event.state, wrapper);
        this.changeDetectorService.notifyCountryStateCityChange();
    };
    SupplierComponent.prototype.onSelectCity = function ($event) {
        var wrapper = this.countryStateCityChangeMap.get($event.id);
        this.countryStateCityService.onSelectCity($event.city, wrapper);
        this.changeDetectorService.notifyCountryStateCityChange();
    };
    SupplierComponent.prototype.onCountryTextChange = function ($event) {
        this.countryStateCityService.onCountryTextChange($event.text);
    };
    SupplierComponent.prototype.onStateTextChange = function ($event) {
        var wrapper = this.countryStateCityChangeMap.get($event.id);
        this.countryStateCityService.onStateTextChange($event.text, wrapper);
    };
    SupplierComponent.prototype.onCityTextChange = function ($event) {
        var wrapper = this.countryStateCityChangeMap.get($event.id);
        this.countryStateCityService.onCityTextChange($event.text, wrapper);
    };
    SupplierComponent.prototype.subscribeCountryStateCityListChange = function () {
        var _this = this;
        this.countryStateCityService.countryListObserver.subscribe(function (value) {
            _this.countryList = value;
        });
        this.countryStateCityService.stateListObserver.subscribe(function (value) {
            _this.stateList = value;
        });
        this.countryStateCityService.cityListObserver.subscribe(function (value) {
            _this.cityList = value;
        });
    };
    // --country state city--//
    SupplierComponent.prototype.getCurrencies = function () {
        var _this = this;
        var currencySearchCriteria = new CurrencySearchCriteria();
        currencySearchCriteria.size = 1000000;
        return this.currencyService.getRows(currencySearchCriteria).subscribe(function (result) {
            _this.currencyList = ResponseUtil.getDataArray(result);
            _this.currencyList = _this.currencyList.filter(function (value) { return value.contract; });
        });
    };
    SupplierComponent.prototype.getPaymentMethods = function () {
        var _this = this;
        this.dataHandlerService.retrievePaymentMethods().subscribe(function (result) {
            _this.payMethods = result.data;
        });
    };
    SupplierComponent.prototype.onSupplierAttributesClick = function () {
        var _this = this;
        this.dataStore.set(DataKey.modalClose, null);
        var dataObject = new ModalData(LEVEL.SUCCESS, 'Supplier Attributes', null, null, 'supplier-attributes-modal', ManageSupplierAttributesWrapperComponent, { 'supplierAttributes': this.supplier.supplierAttributes });
        this.confirmModal(dataObject);
        this.modalCloseSubscription = this.dataStore.get(DataKey.modalClose).subscribe(function (data) {
            if (data != null) {
                _this.modalCloseSubscription.unsubscribe();
                _this.closeModal();
            }
        });
    };
    SupplierComponent.prototype.confirmModal = function (data) {
        this.modalService.confirm(data).subscribe(function (res) {
            console.log(res);
        });
    };
    SupplierComponent.prototype.onResourceTypeSelection = function (event) {
        if (event) {
            this.selectedResTypes = event;
            this.showTransportCostAddButton = this.selectedResTypes && this.selectedResTypes.length > 0 &&
                this.selectedResTypes.filter(function (type) { return type && type['resourceCategory'] === 'transport'; }).length > 0;
            this.showGenericCostAddButton = this.selectedResTypes && this.selectedResTypes.length > 0 &&
                this.selectedResTypes.filter(function (type) { return type && type['resourceCategory'] === 'generic'; }).length > 0;
            this.showRoomCostAddButton = this.selectedResTypes && this.selectedResTypes.length > 0 &&
                this.selectedResTypes.filter(function (type) { return type['code'] && type['code'].toUpperCase() === 'HTL'; }).length > 0;
            var types_1 = [];
            this.selectedResTypes.forEach(function (value) {
                types_1.push(value.code);
            });
            this.selectedResProfTypes = this.resourceTypes.filter(function (value) { return types_1.includes(value.code); });
        }
        else {
            var confirmError = new DialogModel(true, LEVEL.ERROR, 'Failed', 'Failed to update the resource type. Please remove Resource Cost and Tax Schemes related to resource type before you save.', true, 2000);
            this.dialogService
                .confirm(confirmError)
                .subscribe(function (res) {
                if (res === true) {
                    console.log('true');
                }
            });
        }
    };
    SupplierComponent.prototype.handleNavigation = function (data) {
        var _this = this;
        if (data && JSON.stringify(data) !== JSON.stringify({})) {
            if (data.status.code === TC.ErrorCodes.SUCCESS) {
                // if the client is trade client
                if (data && data.data && data.data.length > 0 && data.data[0].code) {
                    var supplierSearchCriteria = new SupplierSearchCriteria();
                    supplierSearchCriteria.code = data.data[0].code;
                    supplierSearchCriteria.supplierId = data.data[0].supplierId;
                    supplierSearchCriteria.status = data.data[0].activeStatus;
                    var parameters = this.queryParamService.getQueryParamsArray(supplierSearchCriteria);
                    this.rootService.setDataToLocalStorage(TCO.AppData.SUPPLIER_SEARCH_CRITERIA, supplierSearchCriteria, true, parameters);
                }
                setTimeout(function () {
                    var journeyToProceed = 'BEGIN_SUPPLIER_MANAGEMENT';
                    // TODO chnage to DataKey.client after changing service
                    _this.dataStore.set(DataKey.updateSupplier, null, true);
                    _this.dataStore.set(DataKey.saveSupplier, null, true);
                    if (journeyToProceed) {
                        _this.userJourneyManager.goForKey(journeyToProceed);
                    }
                }, 100);
            }
            else {
                if (data.status.code === TC.ErrorCodes.ERROR && data.error) {
                }
            }
        }
    };
    SupplierComponent.prototype.ngOnDestroy = function () {
        this.documentTypesSubscription.unsubscribe();
        this.modalCloseSubscription.unsubscribe();
        this.createSupplierObsv.unsubscribe();
        this.updateSupplierObsv.unsubscribe();
        if (this.roomTypesSubscription) {
            this.roomTypesSubscription.unsubscribe();
        }
        if (this.resourceCostDataSubscription) {
            this.resourceCostDataSubscription.unsubscribe();
        }
        this.removeHeaderActions();
        this.localStorage.delete(LocalDataKey.unSavedSupplier);
    };
    SupplierComponent.prototype.ngAfterViewInit = function () {
        var element = document.querySelector('.main-panel');
        if (element) {
            element.scrollTop = 0;
        }
    };
    SupplierComponent.prototype.getUserDetails = function () {
        this.userProfile = this.dataStore.get(DataKey.userProfile).getValue();
    };
    return SupplierComponent;
}());
export { SupplierComponent };
