import {AfterViewInit, Component, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellEditorParams, ICellRendererParams} from 'ag-grid-community';

@Component({
  selector: 'tc-row-error-indicator',
  templateUrl: './row-error-indicator.component.html'
})
export class RowErrorIndicatorComponent  implements ICellRendererAngularComp{
  @ViewChild('selectBox', {read: ViewContainerRef}) public selectBoxContainerRef;

  
  constructor() { }
  ngAfterViewInit(): void {
    window.setTimeout(() => {
      this.selectBoxContainerRef.element.nativeElement.focus();
    });
  }

  agInit(params: ICellRendererParams): void {
  }

  refresh(params: any): boolean {
    return false;
  }

}
