import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Booking } from '../../../models/reservation-v2/booking';
import { BookingSummary } from '../../../models/reservation-v2/booking-summary';
import { BookingSearchCriteria } from '../../../models/reservation-v2/criteria/booking-search-criteria';
import { OPResponseWrapper } from '../../../models/response/op-response-wrapper';
import { DateTimeProcessor } from '../../business-utils/time-zone/date-time-processor';
import { DataKey, DataStoreService } from '../../util/framework/data-store.service';
import { DMCQueryParamsService } from '../../util/framework/dmc-query-params.service';
import { RequestService } from '../../util/framework/request.service';
import { UrlPaths } from '../url-paths';

@Injectable({
    providedIn: 'root'
})
export class BookingManagementService {

    constructor(
        private queryParamsService: DMCQueryParamsService,
        private requestService: RequestService,
        private http: HttpClient,
        private dataStore: DataStoreService
    ) {}

    public searchBookingSummaries(bookingSearchCriteria: BookingSearchCriteria): Observable<OPResponseWrapper<BookingSummary>> {
        DateTimeProcessor.processBookingSearchCriteriaDateTimes(bookingSearchCriteria);
        const params = this.queryParamsService.asQueryParams(bookingSearchCriteria);
        return this.requestService.getWithDataKey(
            DataKey.bookingSummarySearchResultsFromService,
            UrlPaths.OP_RES_URL_KEY,
            [UrlPaths.OP_RES_VERSION, UrlPaths.BOOKING_SUMMARIES],
            params
        );
    }

    public searchBookingSummariesOnMore(bookingSearchCriteria: BookingSearchCriteria): Observable<OPResponseWrapper<BookingSummary>> {
        DateTimeProcessor.processBookingSearchCriteriaDateTimes(bookingSearchCriteria);
        const params = this.queryParamsService.asQueryParams(bookingSearchCriteria);
        return this.requestService.get(
            UrlPaths.OP_RES_URL_KEY,
            [UrlPaths.OP_RES_VERSION, UrlPaths.BOOKING_SUMMARIES],
            params
        );
    }

    public getById(bookingId: number): Observable<OPResponseWrapper<Booking>> {
        return this.requestService.get(
            UrlPaths.OP_RES_URL_KEY,
            [UrlPaths.OP_RES_VERSION, UrlPaths.BOOKINGS, bookingId.toString()]
        );
    }

    public saveBooking(booking: Booking): Observable<OPResponseWrapper<Booking>> {
        return this.requestService.post(
            UrlPaths.OP_RES_URL_KEY,
            [UrlPaths.OP_RES_VERSION, UrlPaths.BOOKINGS],
            booking
        );
    }
}
