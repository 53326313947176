import { AfterViewInit, Directive, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[cgMonthDateDirective]'
})
export class MonthDateDirective implements AfterViewInit {

  constructor(
    private viewContainer: ViewContainerRef
  ) { }

  public ngAfterViewInit(): void {

    let dateDivs: NodeListOf<HTMLElement> =
      this.viewContainer.element.nativeElement.querySelectorAll('.mat-calendar-body-cell-content');

    for (let dateDivsKey in dateDivs) {
      let dateDiv: HTMLElement = dateDivs[dateDivsKey];
      if (typeof (dateDiv) === 'object') {
        let dateSpan = document.createElement('span');
        dateSpan.innerHTML = dateDiv.innerHTML;
        dateSpan.classList.add('tc-sc-date');
        dateDiv.innerText = '';
        dateDiv.appendChild(dateSpan);
      }
    }
  }
}
