import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
    MatAutocompleteModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule, MatNativeDateModule, MatOptionModule, MatSelectModule, MatTooltipModule
} from '@angular/material';
import { TranslateModule } from '@ngx-translate/core';
import { ContentStripModule } from '@tc/content-strip';
import { CurrencyFieldModule } from '@tc/currency-field';
import { CurrencyInputModule } from '@tc/currency-input';
import { PipesModule } from '@tc/pipes';
import { ResourceModule } from '@tc/resource';
import { TransportCostSetupEditCardComponent } from './transport-cost-setup-edit-card.component';

@NgModule({
    imports: [
        CommonModule,
        MatAutocompleteModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatDatepickerModule,
        MatDialogModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule, MatMenuModule,
        MatNativeDateModule,
        MatOptionModule,
        MatSelectModule, MatTooltipModule,
        FormsModule,
        ReactiveFormsModule,
        ContentStripModule,
        ResourceModule,
        CurrencyInputModule,
        CurrencyFieldModule,
        PipesModule,
        TranslateModule.forChild()
    ],
    declarations: [TransportCostSetupEditCardComponent],
    exports: [TransportCostSetupEditCardComponent]
})
export class TransportCostSetupEditCardModule {}
