import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SupplierAttributesComponent } from './supplier-attributes.component';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule.forChild()
    ],
    exports: [SupplierAttributesComponent],
    declarations: [SupplierAttributesComponent]
})
export class SupplierAttributesModule {}
