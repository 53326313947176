import { Component, Input, OnInit } from '@angular/core';
import { TC } from '@tc-core/util';
import { LeaveSetup } from '../../../models/leave-setup';

@Component({
    selector: 'tc-leave-setup-detail-view',
    templateUrl: './leave-setup-detail-view.component.html'
})
export class LeaveSetupDetailViewComponent implements OnInit {

    @Input() leaveSummary: LeaveSetup;
    @Input() leaveConfig: any;

    EMPTY_CHAR = TC.FORMAT.EMPTY_CHAR;

    constructor() { }

    ngOnInit() {
    }

}
