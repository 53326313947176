/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "@angular/common";
import * as i3 from "./tax-schemes-card.component";
var styles_TaxSchemesCardComponent = [];
var RenderType_TaxSchemesCardComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TaxSchemesCardComponent, data: {} });
export { RenderType_TaxSchemesCardComponent as RenderType_TaxSchemesCardComponent };
function View_TaxSchemesCardComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "tc-item-wrapper__header tc-item-header-highlighted"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "tc-item-header-highlighted__icon"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "i", [["class", "material-icons material-icons"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["note"])), (_l()(), i0.ɵeld(4, 0, null, null, 2, "div", [["class", "tc-item-header-highlighted__primary"]], null, null, null, null, null)), (_l()(), i0.ɵted(5, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 5, 0, i0.ɵnov(_v, 6).transform("Tax Schemes")); _ck(_v, 5, 0, currVal_0); }); }
function View_TaxSchemesCardComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 12, "div", [["class", "tc-item-wrapper__body"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 11, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 10, "div", [["class", "col-md-12"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 9, "div", [["class", "tc-flex-box"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 3, "div", [["class", "tc-flex-item icon-cage input-icon"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 2, "div", [["class", "card-icon--small icn-color-primary"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 1, "i", [["class", "material-icons material-icons"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["note"])), (_l()(), i0.ɵeld(8, 0, null, null, 4, "div", [["class", "tc-flex-item input-cage"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 3, "div", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 2, "span", [["class", "txt-light"]], null, null, null, null, null)), (_l()(), i0.ɵted(11, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 11, 0, i0.ɵnov(_v, 12).transform("No Tax Schemes")); _ck(_v, 11, 0, currVal_0); }); }
function View_TaxSchemesCardComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 13, "div", [["class", "tc-item-wrapper__body"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 12, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 11, "div", [["class", "col-md-12"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 10, "div", [["class", "tc-flex-box"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "div", [["class", "tc-flex-item icon-cage input-icon"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 0, "div", [["class", "card-icon--small icn-color-primary"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 7, "div", [["class", "tc-flex-item input-cage"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 6, "div", [["class", "col-xs-12 col-sm-6  col-md-3 col-lg-3"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 5, "div", [["class", "tc-content--wrap tc-input-view"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 2, "div", [["class", "tc-caption-txt txt-light"]], null, null, null, null, null)), (_l()(), i0.ɵted(10, null, [" ", " "])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(12, 0, null, null, 1, "div", [["class", "tc-body1--txt-large"], ["id", "TRD_PER_INFO_CLI_NAME"]], null, null, null, null, null)), (_l()(), i0.ɵted(13, null, ["", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 10, 0, i0.ɵnov(_v, 11).transform("Tax Scheme")); _ck(_v, 10, 0, currVal_0); var currVal_1 = (((_v.context.$implicit == null) ? null : _v.context.$implicit.taxSchemeCode) ? ((_v.context.$implicit == null) ? null : _v.context.$implicit.taxSchemeCode) : _co.EMPTY_CHAR); _ck(_v, 13, 0, currVal_1); }); }
export function View_TaxSchemesCardComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "container-fluid tc-item-wrapper tcpi-card"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TaxSchemesCardComponent_1)), i0.ɵdid(2, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TaxSchemesCardComponent_2)), i0.ɵdid(4, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TaxSchemesCardComponent_3)), i0.ɵdid(6, 278528, null, 0, i2.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showTitle; _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.taxSchemes.length === 0); _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.taxSchemes; _ck(_v, 6, 0, currVal_2); }, null); }
export function View_TaxSchemesCardComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-tax-schemes-card", [], null, null, null, View_TaxSchemesCardComponent_0, RenderType_TaxSchemesCardComponent)), i0.ɵdid(1, 114688, null, 0, i3.TaxSchemesCardComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TaxSchemesCardComponentNgFactory = i0.ɵccf("tc-tax-schemes-card", i3.TaxSchemesCardComponent, View_TaxSchemesCardComponent_Host_0, { taxSchemes: "taxSchemes", showTitle: "showTitle" }, {}, []);
export { TaxSchemesCardComponentNgFactory as TaxSchemesCardComponentNgFactory };
