import { ConfigLoader } from '@tc-core/util/framework';
import { DMCQueryParamsService } from '../../util/framework/dmc-query-params.service';
import { RequestService } from '../../util/framework/request.service';
import { UrlPaths } from '../url-paths';
import * as i0 from "@angular/core";
import * as i1 from "@tc-core/util/framework/config-loader.service";
import * as i2 from "../../util/framework/request.service";
import * as i3 from "../../util/framework/dmc-query-params.service";
var AccountingRuleService = /** @class */ (function () {
    function AccountingRuleService(configLoader, requestService, queryParamsService) {
        this.configLoader = configLoader;
        this.requestService = requestService;
        this.queryParamsService = queryParamsService;
    }
    AccountingRuleService.prototype.getRows = function (criteria) {
        var reqPrams = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.get(UrlPaths.OP_ACC_URL_KEY, [UrlPaths.OP_ACC_VERSION, UrlPaths.ACCOUNTING_RULES], reqPrams);
    };
    AccountingRuleService.prototype.deleteRow = function (id) {
        return this.requestService.delete(UrlPaths.OP_ACC_URL_KEY, [UrlPaths.OP_ACC_VERSION, UrlPaths.ACCOUNTING_RULES, id.toString()]);
    };
    AccountingRuleService.prototype.saveRow = function (row) {
        return this.requestService.post(UrlPaths.OP_ACC_URL_KEY, [UrlPaths.OP_ACC_VERSION, UrlPaths.ACCOUNTING_RULES], row);
    };
    AccountingRuleService.prototype.getAccountingEvents = function (criteria) {
        var reqPrams = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.get(UrlPaths.OP_ACC_URL_KEY, [UrlPaths.OP_ACC_VERSION, UrlPaths.ACCOUNTING_EVENTS], reqPrams);
    };
    AccountingRuleService.prototype.getAccountingUnits = function (criteria) {
        var reqPrams = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.get(UrlPaths.OP_ACC_URL_KEY, [UrlPaths.OP_ACC_VERSION, UrlPaths.ACCOUNTING_UNITS], reqPrams);
    };
    AccountingRuleService.prototype.getLedgerAccounts = function (criteria) {
        var reqPrams = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.get(UrlPaths.OP_ACC_URL_KEY, [UrlPaths.OP_ACC_VERSION, UrlPaths.LEDGER_ACCOUNTS], reqPrams);
    };
    AccountingRuleService.prototype.saveLedgerAccount = function (row) {
        var reqPrams = this.queryParamsService.asQueryParams({
            importedFromScheduler: false
        });
        return this.requestService.post(UrlPaths.OP_ACC_URL_KEY, [UrlPaths.OP_ACC_VERSION, UrlPaths.LEDGER_ACCOUNTS], row, reqPrams);
    };
    AccountingRuleService.prototype.deleteLedgerAccount = function (code) {
        return this.requestService.delete(UrlPaths.OP_ACC_URL_KEY, [UrlPaths.OP_ACC_VERSION, UrlPaths.LEDGER_ACCOUNTS, code]);
    };
    AccountingRuleService.prototype.searchLedgerEntries = function (criteria) {
        var reqPrams = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.get(UrlPaths.OP_ACC_URL_KEY, [UrlPaths.OP_ACC_VERSION, UrlPaths.LEDGER_ENTRIES], reqPrams);
    };
    AccountingRuleService.prototype.generateLedgers = function (params) {
        var reqPrams = this.queryParamsService.asQueryParams(params);
        return this.requestService.post(UrlPaths.OP_ACC_URL_KEY, [UrlPaths.OP_ACC_VERSION, UrlPaths.LEDGER_ENTRIES], null, reqPrams);
    };
    AccountingRuleService.ngInjectableDef = i0.defineInjectable({ factory: function AccountingRuleService_Factory() { return new AccountingRuleService(i0.inject(i1.ConfigLoader), i0.inject(i2.RequestService), i0.inject(i3.DMCQueryParamsService)); }, token: AccountingRuleService, providedIn: "root" });
    return AccountingRuleService;
}());
export { AccountingRuleService };
