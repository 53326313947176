<div class="detail-group__heading container-fluid">
    <div class="item-header">
        <div class="item-header__content">{{resultHeaderHeading | translate}}</div>
    </div>
</div>

<div class="detail-group__body-service-item container-fluid">
    <mat-accordion *ngFor="let item of serviceItems">
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <mat-checkbox
                            color="primary"
                            [ngModel]= "isSelected(item)"
                            [disabled]="disableToSelect(item)"
                            (change)="onItemClicked($event, item)"
                            (click)="onClickCheckBox($event)">
                        {{item.bookingReferenceId}}
                    </mat-checkbox>
                </mat-panel-title>
                <mat-panel-description>
                    <div>{{getLeadPassenger(item)}}</div>
                </mat-panel-description>
            </mat-expansion-panel-header>
            <tc-passenger-list-grid
                    [passengerList]="item.servicePassengers"
                    [colDefConfig]="colDefConfig">
            </tc-passenger-list-grid>
        </mat-expansion-panel>
    </mat-accordion>
</div>

<div class="container assign-panel-footer-section">
    <button class="assign-panel-footer-section__button tc-button-secondary mat-button" (click)="onClose($event)">
        {{'Close' | translate}}
    </button>
    <button
            class="assign-panel-footer-section__button tc-button-primary mat-button"
            (click)="onSave()"
            [disabled]="!isSaveButtonEnable">
        {{'Save' | translate}}
    </button>
</div>
