import * as tslib_1 from "tslib";
import { AbstractSearchCriteria } from './abstract-search-criteria';
var CostingTypeSearchCriteria = /** @class */ (function (_super) {
    tslib_1.__extends(CostingTypeSearchCriteria, _super);
    function CostingTypeSearchCriteria() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.code = '';
        _this.name = '';
        _this.category = '';
        return _this;
    }
    return CostingTypeSearchCriteria;
}(AbstractSearchCriteria));
export { CostingTypeSearchCriteria };
export var CostingTypeCategory;
(function (CostingTypeCategory) {
    CostingTypeCategory["UNIT"] = "UNIT";
    CostingTypeCategory["PERSON"] = "PERSON";
    CostingTypeCategory["DISTANCE"] = "DISTANCE (Km)";
    CostingTypeCategory["DURATION"] = "DURATION (h)";
    CostingTypeCategory["DAY"] = "DAY";
})(CostingTypeCategory || (CostingTypeCategory = {}));
