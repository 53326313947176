import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CurrencyFormat } from '@tc-core/model/it/codegen/ui/framework/currency-format';
import { Pager } from '@tc-core/model/it/codegen/ui/framework/pager';
import { Sorter } from '@tc-core/model/it/codegen/ui/framework/sorter';
import { SummaryCardData } from '@tc-core/model/it/codegen/ui/framework/summary-card-data';
import { SorterService } from '@tc-core/service/sorters/sorter.service';
import { ConfigLoader } from '@tc-core/util/framework/config-loader.service';
import { CommonHelper } from '@tc-core/util/helpers/common-helper.service';
import { AutoScrollService } from '@tc-core/util/ui/auto-scroll.service';
import { Subscription } from 'rxjs';
import { TCO } from '../../../constants';
import { ResourceType } from '../../../models/reservation/assignment';
import { ServiceItem } from '../../../models/reservation/service-item';
import { SupplierSearchType } from '../../transfer-supplier-search-criteria/transfer-supplier-search-criteria.component';
import { SupplierService } from '../../../services/backend-consumers/supplier-service/supplier.service';
import { DataStoreService } from '../../../services/util/framework/data-store.service';
import { SupplierSearchCriteria } from '../../../models/criteria/supplier-search-criteria';
import { ServiceItemChanges } from '../../../models/helper/service-item-changes';
import { DMCQueryParamsService } from '../../../services/util/framework/dmc-query-params.service';
import {GenTourItem} from "../../../models/reservation/generic/gen-tour-item";

@Component({
    selector: 'tc-supplier-assign-panel',
    templateUrl: './supplier-assign-panel.component.html'
})
export class SupplierAssignPanelComponent implements OnInit {

    @Input() isDataLoaded: true;
    @Input() isTriggerSearch: true;
    @Input() displayChipsArr = null;
    @Input() isFocus = false;
    @Input() isSearchBarStyleNotAvailable = true;
    @Input() placeHolderText = 'Search Transfer Supplier';
    @Input() selectedSupplier: ServiceItemChanges;
    @Input() currencyFormat: CurrencyFormat;
    @Input() tAssignedSuppliers: any = null;
    @Input() contentCardDataList: Array<any> = [];
    @Input() selectedSummaryCardData: any = null;
    @Input() bookingData: ServiceItem;
    @Input() bookingDataArray: ServiceItem[];
    @Input() genTourItem: GenTourItem;
    @Input() isBulkAssignment: boolean = false;
    @Input() changesAvailableForServiceItems: boolean;
    @Input() heading = '';
    @Input() hasMore = true;
    @Input() fetchedSupplierCount = 0;
    @Input() dataFromMoreClick = false;
    @Input() isVehicleAssignment = false;
    @Input() isDriverAssignment = false;
    @Input() isGenResourceAssignment = false;
    @Input() pager: Pager;
    @Output() supplierSelect: EventEmitter<any> = new EventEmitter();
    @Output() onPaginationMoreClick: EventEmitter<any> = new EventEmitter();

    supplierSearchCriteria = new SupplierSearchCriteria;
    _searchType = SupplierSearchType.ResourceAssign;
    sorters_: Sorter[];
    defaultVehicleModel = 'ANY';

    //pagination

    public defaultPageResultCount = 4;
    selectionIndex = '';
    tPagination: any;

    private moreResultObsv: Subscription;

    constructor(
        public sorterService: SorterService,
        private queryParamService: DMCQueryParamsService,
        public dataStoreService: DataStoreService,
        private configLoader: ConfigLoader,
        private supplierService: SupplierService,
        private commonHelper: CommonHelper,
        private autoScrollService: AutoScrollService
    ) {
    }

    ngOnInit() {
        this.tPagination = this.configLoader.configurations.get(TCO.CONF.CONF_PAGINATION_CONFIG)['RESOURCE_ALLOCATION_SEARCH'];

        // remove supplier search criteria from url
        let queryParams: string[] = [];
        queryParams = this.queryParamService.getQueryParamsArray(new SupplierSearchCriteria());

        queryParams.forEach(param => {
            this.queryParamService.deleteQueryParam(param);
        });

        this.createSorters();
        this.createSupplierSearchCriteria();

        this.setupPaginator();

    }

    createSorters() {
        if (this.tAssignedSuppliers) {
            this.sorterService.sorters = [];
            this.sorters_ = [];
            let sorterMap = new Map<string, Sorter>();
            this.tAssignedSuppliers.config.placeHolderConfig.forEach(config => {
                if (config && config.sort) {
                    let sorter = this.sorterService.createSorter(config);
                    let id = config['_id'];
                    sorterMap.set(id, sorter);
                    this.sorters_.push(sorter);
                }
            });
        }
    }

    public onSort(event) {
        this.sorterService.sorter = event;
    }

    public onSupplierSelect(summaryCardData: SummaryCardData) {
        this.selectedSummaryCardData = summaryCardData;

        if (summaryCardData) {
            this.supplierSelect.emit(this.selectedSummaryCardData);
        }
    }

    createSupplierSearchCriteria() {
        if (this.isBulkAssignment) {
            this.supplierSearchCriteria.serviceDate = this.bookingDataArray[0]['rawData'].serviceDate;
            if (this.isVehicleAssignment) {
                this.supplierSearchCriteria.transferMode = this.bookingDataArray[0]['rawData'].trsServiceItem.transferMode;
                this.supplierSearchCriteria.route = this.bookingDataArray[0]['rawData'].trsServiceItem.route;
                this.supplierSearchCriteria.vehicleModel = this.bookingDataArray[0]['rawData'].vehicleModel ?
                    this.bookingDataArray[0]['rawData'].vehicleModel :
                    this.defaultVehicleModel;
            }
        } else if(!this.isGenResourceAssignment){
            this.supplierSearchCriteria.serviceDate = this.bookingData.serviceDate;
            let resourceType = ResourceType.driver;
            if (this.isVehicleAssignment) {
                resourceType = ResourceType.vehicle;
                this.supplierSearchCriteria.transferMode = this.bookingData.trsServiceItem.transferMode;
                this.supplierSearchCriteria.route = this.bookingData.trsServiceItem.route;
                this.supplierSearchCriteria.vehicleModel = this.bookingData.vehicleModel ?
                    this.bookingData.vehicleModel :
                    this.defaultVehicleModel;
            }
            let previousAssignmentDetail = this.bookingData.serviceAssignments.find(value => value.categoryCode === resourceType);
            this.supplierSearchCriteria.rejectedSupplierIdList = previousAssignmentDetail ? previousAssignmentDetail.rejectedSupplierIdList : [];
        }else if(this.isGenResourceAssignment){
            this.placeHolderText = 'Search Resource Supplier';
            this.supplierSearchCriteria.serviceDate = this.genTourItem.serviceItems[0].serviceDate;
            this.supplierSearchCriteria.resourceTypes = [];
            this.supplierSearchCriteria.resourceTypes.push(this.genTourItem.categoryCode);
            this._searchType = SupplierSearchType.GenResourceAssign;
        }
        if (this.isDriverAssignment) {
            this.supplierSearchCriteria.resourceTypes = [];
            this.supplierSearchCriteria.resourceTypes.push(ResourceType.driver);
        }
    }

    private setupPaginator() {
        this.defaultPageResultCount = this.tPagination['defaultPageResultCount'];
        this.pager = new Pager(0, this.defaultPageResultCount);
    }

    public onPage(pageNumber: number) {
        this.pager.currPage = pageNumber;
        this.selectionIndex = (this.pager.currPage * this.pager.itemsPerPage).toString();
    }

    public onMore() {
        this.dataFromMoreClick = true;
        if (this.tPagination && this.tPagination['defaultPageResultCount'] && this.tPagination['fetchingPages']) {
            this.supplierSearchCriteria.size = this.tPagination['fetchingPages'] *
                this.tPagination['defaultPageResultCount'];
            this.onPaginationMoreClick.emit(this.supplierSearchCriteria);
            // this.retrieveAndCollectSearchResults(this.supplierSearchCriteria);
        }
    }

    public onSelection(event) {
        this.selectionIndex = event;
    }

    /*retrieveAndCollectSearchResults(searchCriteria: SupplierSearchCriteria) {
     this.dataStoreService.set(DataKey.supplierAssignAllFetchedResults, null, true);
     this.supplierService.searchSuppliersForAssignmentOnMore(this.supplierSearchCriteria);
     this.moreResultObserver();
     }

     moreResultObserver() {
     this.moreResultObsv = this.dataStoreService.get(DataKey.supplierAllFetchedResults).subscribe(
     (result) => {
     if (this.commonHelper.dataValidity(result)) {
     this.moreResultObsv.unsubscribe();
     this.hasMore = true;
     this.onMoreResultsHandler(result);
     } else if (result instanceof TcApiError) {
     this.hasMore = false;
     } else if (result instanceof TcHttpError) {
     this.hasMore = false;
     }
     });
     }*/

    public onCardScroll($event: number) {
        setTimeout(() => {
            this.autoScrollService.scrollToID(
                'clientDetailExp_' + $event, 'supplier-assign-scroll-container');
        }, 0);
    }

    /*retrieveAndCollectSearchResults(searchCriteria: SupplierSearchCriteria) {
     this.dataStoreService.set(DataKey.supplierAllFetchedResults, null, true);
     this.supplierService.searchSuppliersOnMore(this.supplierSearchCriteria);
     this.moreResultObserver();
     }

     moreResultObserver() {
     this.moreResultObsv = this.dataStoreService.get(DataKey.supplierAllFetchedResults).subscribe(
     (result) => {
     if (this.commonHelper.dataValidity(result)) {
     this.moreResultObsv.unsubscribe();
     this.hasMore = true;
     this.onMoreResultsHandler(result);
     } else if (result instanceof TcApiError) {
     this.hasMore = false;
     } else if (result instanceof TcHttpError) {
     this.hasMore = false;
     }
     });
     }

     private onMoreResultsHandler(result: any) {
     if (JSON.stringify(result) !== JSON.stringify({})) {
     if (result.status.code === 1) {
     if (result.data) {
     this.hasMore = true;

     this.supplierResultList.push(...result.data);
     this.fetchedSupplierCount = this.supplierResultList.length;
     let contentCardDataList = this.getContentCardData(result.data);
     this.contentCardDataList.push(...contentCardDataList);

     this.setPageZero();

     const noOfResults = this.contentCardDataList.length;
     this.heading = noOfResults + ' result' + (noOfResults > 1 ? 's' : '') + ' found.';

     if (result.data.length === 0) {
     this.hasMore = false;
     this.isError = false;
     } else {
     this.isError = false;
     }
     }
     } else if (result.status.code === -1) {
     // this.isEmptyResult = true;
     this.isError = true;
     if (result.error.message) {
     this.errorText = result.error.errors[0];
     } else {
     this.errorText = 'Request Processing Error !!!';
     }
     }
     }
     }*/

}
