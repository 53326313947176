import { KeyControlAndAbstractSearchCriteria } from '../../criteria/key-control-and-abstract-search-criteria';

export class SupplierAllocationSearchCriteria extends KeyControlAndAbstractSearchCriteria {
    supplierId: number;
    supplierCode: string = '';
    supplierName: string = '';
    resourceId: number;
    resourceName: string = '';
    serviceType: string = '';
    vehicleModel: string = '';
    route: string = '';
    transferMode: string = '';
    profileType: string = '';
    adultCount: number;
    teenCount: number;
    childCount: number;
    infantCount: number;
    privateBooking: boolean;
    serviceDate: any;
    serviceDateStr: String = '';
    rejectedSupplierIdList: number[];
    bookingIdList: number[];
    serviceStartTime: any;
    serviceEndTime: any;
    activeStatus: any = '';

    //accommodation
    roomCount: number;
    occAdultCount: number;
    occTeenCount: number;
    occChildrenCount: number;
    occInfantCount: number;
    boardBasis: string = '';
    occupancy: string = '';
    nights: number;

    //costing fields
    costingType: string;
    noOfUnits: number;

    kcCompany: string;
    kcDivision: string;
    kcBrand: string;
    kcDistributionChannel: string;
}
