import * as tslib_1 from "tslib";
import { EventEmitter, KeyValueDiffer, KeyValueDiffers, OnInit } from '@angular/core';
import { CurrencyFormat } from '@tc-core/model/it/codegen/ui/framework/currency-format';
import { Icon } from '@tc-core/model/it/codegen/ui/framework/icon';
import { TC } from '@tc-core/util';
import { ConfigLoader } from '@tc-core/util/framework';
import { TCO } from '../../../../../constants';
import { TimelineEvent } from '../../../../../widgets/framework/cg-timeline/timeline-event';
import { ServiceItemChanges } from '../../../../../models/helper/service-item-changes';
import { ResourceAllocationSummary } from '../../../../../models/reservation-v2/resource-allocation-summary';
import { SearchResultSummary } from '../../../../../models/reservation/search-result-summary';
var ResourceAllocationTimelineComponent = /** @class */ (function () {
    function ResourceAllocationTimelineComponent(configLoader, differs) {
        this.configLoader = configLoader;
        this.differs = differs;
        this.isRow1col4String = false;
        this.isBulkAssignment = false;
        this.resourceCalendarClick = new EventEmitter();
        this.eventClick = new EventEmitter();
        this.targetClick = new EventEmitter();
        this.isUpdated = false;
        this.isCancelled = false;
        this.assignments = [];
        this.timelineEvents = [];
        this.leftBufferHours = -1;
        this.rightBufferHours = -1;
        this.isTargetBlock = false;
        this.targetPointTime = new Date();
        this.targetBlockTimeStart = null;
        this.targetBlockTimeEnd = null;
        this.row1col1_customsStyles = [];
        this.row1col2_customsStyles = [];
        this.row1col3_customsStyles = [];
        this.row1col4_customsStyles = [];
        this.row2col1_customsStyles = [];
        this.row2col2_customsStyles = [];
        this.row2col3_customsStyles = [];
        this.row2col4_customsStyles = [];
        this.row3col1_customsStyles = [];
        //timepickers
        this.theme = {
            dial: {
                dialBackgroundColor: '#3f51b5'
            },
            container: {
                buttonColor: '#3f51b5'
            },
            clockFace: {
                clockHandColor: '#3f51b5'
            }
        };
    }
    ResourceAllocationTimelineComponent.prototype.ngOnInit = function () {
        this.createTimelineEvents();
        this.initTimeline();
        this.createTargetPointOrBlock();
        this.overrideStyles();
        this.currencyFormat = this.getCurrencyFormat();
        this.summaryCardDiffer = this.differs.find(this.summaryContent).create();
    };
    ResourceAllocationTimelineComponent.prototype.ngDoCheck = function () {
        if (this.summaryCardDiffer) {
            var changes = this.summaryCardDiffer.diff(this.summaryContent);
            if (changes) {
                console.log('summary card data changed');
                this.createTimelineEvents();
            }
        }
    };
    ResourceAllocationTimelineComponent.prototype.initTimeline = function () {
        var timelineConfig = this.configLoader.configurations.get(TCO.CONF.CONF_TIMELINE_UNIT_CONFIG)['resource-allocation-timeline'];
        this.leftBufferHours = timelineConfig.leftBufferHours;
        this.rightBufferHours = timelineConfig.rightBufferHours;
    };
    ResourceAllocationTimelineComponent.prototype.overrideStyles = function () {
        this.row1col1_customsStyles = ['tc-strip-text--primary'];
        this.row1col2_customsStyles = ['tc-strip-text--primary'];
        this.row1col3_customsStyles = [];
        this.row1col4_customsStyles = [
            'tc-strip-text--right',
            'tc-strip-text--primary',
            'tc-strip__price',
            'tc-strip__price--x-large'
        ];
        this.row2col1_customsStyles = [];
        this.row2col2_customsStyles = ['tc-strip-text--primary'];
        this.row2col3_customsStyles = [];
        this.row2col4_customsStyles = ['tc-strip-text__label', 'tc-strip-text--right'];
    };
    ResourceAllocationTimelineComponent.prototype.getCurrencyFormat = function () {
        var currencyFormat;
        var currencySymbolPattern = this.configLoader.configurations.get(TC.CONF.CONF_SYSTEM).defaults.default_currency_symbol_pattern;
        currencyFormat = new CurrencyFormat(TCO.AppData.DEF_CURRENCY_TYPE, currencySymbolPattern);
        return currencyFormat;
    };
    ResourceAllocationTimelineComponent.prototype.createTimelineEvents = function () {
        var e_1, _a;
        this.assignments = this.summaryContent.existingAssignments ? this.summaryContent.existingAssignments : [];
        this.timelineEvents = [];
        try {
            for (var _b = tslib_1.__values(this.assignments), _c = _b.next(); !_c.done; _c = _b.next()) {
                var assignment = _c.value;
                this.defaultJobStartTime = assignment.startTimeStr;
                this.defaultJobEndTime = assignment.endTimeStr;
                var timelineEvent = new TimelineEvent();
                timelineEvent.startTime = assignment.startTime;
                timelineEvent.endTime = assignment.endTime;
                timelineEvent.waitingEndTime = assignment.waitingEndTime;
                timelineEvent.assignment = assignment;
                timelineEvent.assignment.remainingCount = assignment.remainingCapacity;
                timelineEvent.assignment.allocatedCount = assignment.allocatedCapacity;
                this.timelineEvents.push(timelineEvent);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
    };
    ResourceAllocationTimelineComponent.prototype.createTargetPointOrBlock = function () {
        if (this.itemList) {
            // set target point if only one booking item | service item
            if (this.itemList[0].trsBookingItem &&
                this.itemList[0].trsBookingItem.departureDateTime) {
                this.itemList = this.sortData(this.itemList);
                if (this.itemList.length === 1) {
                    this.targetPointTime = new Date(this.itemList[0].trsBookingItem.departureDateTime);
                }
                else if (this.itemList.length > 1) {
                    // set target block if there are many booking items | service items
                    this.targetBlockTimeStart = this.itemList[0].trsBookingItem.departureDateTime;
                    this.targetBlockTimeEnd = this.itemList[this.itemList.length - 1].trsBookingItem.departureDateTime;
                }
            }
            else if (this.itemList[0].startTime) {
                this.targetPointTime = new Date(this.itemList[0].startTime);
            }
        }
    };
    ResourceAllocationTimelineComponent.prototype.sortData = function (data) {
        return data.sort(function (a, b) {
            return new Date(b.trsBookingItem.departureDateTime) - new Date(a.trsBookingItem.departureDateTime);
        });
    };
    ResourceAllocationTimelineComponent.prototype.onResourceCalendarClick = function (resource) {
        this.resourceCalendarClick.emit(resource);
    };
    ResourceAllocationTimelineComponent.prototype.onTargetClick = function (target) {
        this.targetClick.emit(this.summaryContent);
    };
    ResourceAllocationTimelineComponent.prototype.onEventClick = function (event) {
        this.eventClick.emit(event.assignment);
    };
    ResourceAllocationTimelineComponent.prototype.hasSelectedCost = function (costSummaries) {
        this.selectedCost = costSummaries.find(function (value) { return value.selected; });
    };
    ResourceAllocationTimelineComponent.prototype.setTimes = function ($event, isStartTime) {
        var _this = this;
        var time = $event;
        this.jobStartTime = this.defaultJobStartTime;
        this.jobEndTime = this.defaultJobEndTime;
        var timeArr = [];
        if (time != null && time != '') {
            if (isStartTime) {
                timeArr = time.split(':');
                this.jobStartTime = time;
            }
            else {
                timeArr = time.split(':');
                this.jobEndTime = time;
            }
            if (timeArr.length > 1 && this.timelineEvents.length > 0) {
                this.timelineEvents.forEach(function (timeline, index) {
                    if (isStartTime) {
                        timeline.assignment.startTime = _this.jobStartTime;
                        timeline.assignment.startTimeObj.setHours(+timeArr[0]);
                        timeline.assignment.startTimeObj.setMinutes(+timeArr[1]);
                        timeline.startTime.setHours(+timeArr[0]);
                        timeline.startTime.setMinutes(+timeArr[1]);
                        _this.summaryContent.existingAssignments[index].startTime = _this.jobStartTime;
                        _this.summaryContent.existingAssignments[index].startTimeObj.setHours(+timeArr[0]);
                        _this.summaryContent.existingAssignments[index].startTimeObj.setMinutes(+timeArr[1]);
                    }
                    else {
                        timeline.assignment.endTime = _this.jobEndTime;
                        timeline.assignment.endTimeObj.setHours(+timeArr[0]);
                        timeline.assignment.endTimeObj.setMinutes(+timeArr[1]);
                        timeline.endTime.setHours(+timeArr[0]);
                        timeline.endTime.setMinutes(+timeArr[1]);
                        _this.summaryContent.existingAssignments[index].endTime = _this.jobEndTime;
                        _this.summaryContent.existingAssignments[index].endTimeObj.setHours(+timeArr[0]);
                        _this.summaryContent.existingAssignments[index].endTimeObj.setMinutes(+timeArr[1]);
                    }
                });
            }
        }
    };
    ResourceAllocationTimelineComponent.prototype.onInputChange = function ($event, isStartTime) {
        var _this = this;
        var time = $event.target.value;
        this.jobStartTime = this.defaultJobStartTime;
        this.jobEndTime = this.defaultJobEndTime;
        var timeArr = [];
        if (time != null && time != '') {
            if (/^([2][0-3]|[01]?[0-9])([.:][0-5][0-9])?$/.test(time)) {
                if (isStartTime) {
                    timeArr = time.split(':');
                    this.jobStartTime = time;
                }
                else {
                    timeArr = time.split(':');
                    this.jobEndTime = time;
                }
                if (timeArr.length > 1 && this.timelineEvents.length > 0) {
                    this.timelineEvents.forEach(function (timeline, index) {
                        if (isStartTime) {
                            timeline.assignment.startTime = _this.jobStartTime;
                            timeline.assignment.startTimeObj.setHours(+timeArr[0]);
                            timeline.assignment.startTimeObj.setMinutes(+timeArr[1]);
                            timeline.startTime.setHours(+timeArr[0]);
                            timeline.startTime.setMinutes(+timeArr[1]);
                            _this.summaryContent.existingAssignments[index].startTime = _this.jobStartTime;
                            _this.summaryContent.existingAssignments[index].startTimeObj.setHours(+timeArr[0]);
                            _this.summaryContent.existingAssignments[index].startTimeObj.setMinutes(+timeArr[1]);
                        }
                        else {
                            timeline.assignment.endTime = _this.jobEndTime;
                            timeline.assignment.endTimeObj.setHours(+timeArr[0]);
                            timeline.assignment.endTimeObj.setMinutes(+timeArr[1]);
                            timeline.endTime.setHours(+timeArr[0]);
                            timeline.endTime.setMinutes(+timeArr[1]);
                            _this.summaryContent.existingAssignments[index].endTime = _this.jobStartTime;
                            _this.summaryContent.existingAssignments[index].endTimeObj.setHours(+timeArr[0]);
                            _this.summaryContent.existingAssignments[index].endTimeObj.setMinutes(+timeArr[1]);
                        }
                    });
                }
            }
            else if (isStartTime) {
                this.jobStartTime = this.defaultJobStartTime;
                $event.target.value = this.jobStartTime;
            }
            else {
                this.jobEndTime = this.defaultJobEndTime;
                $event.target.value = this.jobEndTime;
            }
        }
    };
    return ResourceAllocationTimelineComponent;
}());
export { ResourceAllocationTimelineComponent };
