import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule, MatMenuModule, MatProgressBarModule, MatTooltipModule } from '@angular/material';
import { TranslateModule } from '@ngx-translate/core';
import { KebabMenuModule } from '@tc/kebab-menu';
import { PipesModule } from '@tc/pipes';
import { ResourceModule } from '@tc/resource';
import { ExpandedContentHeaderCardComponent } from './expanded-content-header-card.component';

@NgModule({
  imports: [
      CommonModule,
      ResourceModule,
      MatProgressBarModule,
      MatMenuModule,
      MatIconModule,
      MatTooltipModule,
      TranslateModule.forChild(),
      KebabMenuModule,
      PipesModule
  ],
    exports: [
        ExpandedContentHeaderCardComponent
    ],
  declarations: [ExpandedContentHeaderCardComponent]
})
export class ExpandedContentHeaderCardModule { }
