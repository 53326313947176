import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChipInputModule } from '@tc/chip-input';
import { ResourceAvailabilityService } from '../../services/backend-consumers/supplier-service/resource-availability.service';
import { LeaveSetupSearchCriteriaComponent } from './leave-setup-search-criteria.component';

@NgModule({
    declarations: [LeaveSetupSearchCriteriaComponent],
    imports: [
        CommonModule,
        ChipInputModule
    ],
    providers: [ResourceAvailabilityService],
    exports: [LeaveSetupSearchCriteriaComponent],
    entryComponents: [LeaveSetupSearchCriteriaComponent]
})
export class LeaveSetupSearchCriteriaModule {}
