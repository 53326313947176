import { Icon } from '@tc-core/model/it/codegen/ui/framework/icon';
import { ExistingAssignment } from './existing-assignment';
import { SupplierAllocationSummary } from './supplier-allocation-summary';

export class ResourceAllocationSummary extends SupplierAllocationSummary {
    resourceId: number;
    resourceName: string;
    totalCapacity: number;
    capacityExceeded: boolean;
    existingAssignments: ExistingAssignment[];
    icon: Icon;
}

export class AllocationTimeRange {
    startTime: any;
    endTime: any;
    bookingIds: any[];
}
