import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { TC } from '@tc-core/util/constants';
import { ConfigLoader } from '@tc-core/util/framework';
import { EventManager } from '@tc-core/util/framework/event-manager.service';
import { FocusViewManager } from '@tc-core/util/framework/focus-view-manager.service';
import { FocusViewService } from '@tc/focus-view/focus-view.service';
import { IGetRowsParams } from 'ag-grid-community';
import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SetupGridComp } from '../../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid-comp';
import { SetupGridDataSource } from '../../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid-data-source';
import { SetupGridComponent } from '../../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid.component';
import { TCO } from '../../../../constants';
import { JobSearchCriteria } from '../../../../models/criteria/job-search-criteria';
import { SortDirection } from '../../../../models/helper/sort-direction';
import { JobHistoryStatus } from '../../../../models/job/job-history-status';
import { JobService } from '../../../../services/backend-consumers/job/job-service';
import { DataKey, DataStoreService } from '../../../../services/util/framework/data-store.service';

@Component({
    selector: 'tc-common-job-history-grid',
    templateUrl: './common-job-history-grid.component.html'
})
export class CommonJobHistoryGridComponent extends SetupGridComp implements OnInit, OnDestroy, SetupGridDataSource
{
    @ViewChild('jobHistoriesView') setupGrid: SetupGridComponent;
    @ViewChild('statusCell') statusCell: TemplateRef<any>;

    @Input()
    private jobName: string;

    colDefConfig = [];

    private searchCriteria: JobSearchCriteria = new JobSearchCriteria();

    private searchCriteriaObserver: Subscription = new Subscription();

    constructor(
        private configLoader: ConfigLoader,
        private jobService: JobService,
        private dataStoreService: DataStoreService,
        private em: EventManager,
        private focusViewManager: FocusViewManager,
        private focusViewService: FocusViewService
    )
    {
        super();
    }

    ngOnInit()
    {
        this.colDefConfig = this.configLoader.configurations.get(TCO.CONF.CONF_JOB_HISTORY_SETUP);
        this.subscribeSearchCriteria();

        this.focusViewManager.canProceed.next(true);
        this.focusViewManager.canProceedState.next(true);
        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'CLOSE_FOCUS_VIEW')
            .subscribe(e => {
                this.focusViewService.close(true);
            });
    }

    public getRows(params: IGetRowsParams): Observable<any>
    {
        const pageSize = params.endRow - params.startRow;
        if (!this.searchCriteria) {
            this.searchCriteria = new JobSearchCriteria();
        }
        this.searchCriteria.start = params.startRow;
        this.searchCriteria.size = pageSize;
        if (params.sortModel && params.sortModel[0]) {
            this.searchCriteria.sortBy = params.sortModel[0].colId;
            if (params.sortModel[0].sort === SetupGridComp.GRID_SORT_ASCENDING) {
                this.searchCriteria.sortDirection = SortDirection.ASC;
            } else {
                this.searchCriteria.sortDirection = SortDirection.DESC;
            }
        } else {
            this.searchCriteria.sortDirection = SortDirection.DESC;
        }
        this.searchCriteria.jobName = this.jobName;
        return this.jobService.getCommonJobHistories(this.searchCriteria)
                   .pipe(
                       tap(data =>
                           this.dataStoreService.set(DataKey.commonJobHistorySearchResults, data)
                       )
                   );
    }

    subscribeSearchCriteria()
    {
        this.searchCriteriaObserver = this.dataStoreService.get(DataKey.commonJobHistorySearchCriteria)
                                          .subscribe(value => {
                                              this.searchCriteria = value;
                                              this.setupGrid.runForceSearch();
                                          });
    }

    displayStatus(status: string): string
    {
        const splitsByUnderscore = status.split('_');
        if (splitsByUnderscore.length > 1) {
            return splitsByUnderscore.map(part => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase()).join(' ');
        }
        return status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();
    }

    ngOnDestroy(): void
    {
        if (this.searchCriteriaObserver) {
            this.searchCriteriaObserver.unsubscribe();
        }
    }

    statusStarted = (params) => {
        return params.data &&
            (params.data.historyStatus === JobHistoryStatus.STARTED || params.data.historyStatus === JobHistoryStatus.RUNNING);
    };

    statusFailed = (params) => {
        return params.data && params.data.historyStatus === JobHistoryStatus.FAILED;
    };

    statusFinished = (params) => {
        return params.data && params.data.historyStatus === JobHistoryStatus.FINISHED;
    };

    statusCanceled = (params) => {
        return params.data && params.data.historyStatus === JobHistoryStatus.CANCELED;
    };

    statusTerminated = (params) => {
        return params.data && params.data.historyStatus === JobHistoryStatus.TERMINATED;
    };

    /**
     * Not applicable to jobs
     */
    saveRow(row: any, event: any): Observable<any>
    {
        throw new Error('Method not implemented.');
    }

    /**
     * Not applicable to jobs
     */
    deleteRow(row: any): Observable<any>
    {
        throw new Error('Method not implemented.');
    }

    /**
     * Not applicable to jobs
     */
    public createNewRow(): any
    {
    }

    /**
     * Not applicable to jobs
     */
    public isInvalidRow(params): boolean
    {
        return false;
    }

    /**
     * Not applicable to jobs
     */
    public isUnsavedRow(row: any): boolean
    {
        return false;
    }

}
