<div id="wrapper" class="row-grid">
    <div id="left" class="column-grid">
<!--        <span-->
<!--                (click)="onDeleteSelectedClickLeft()" matTooltip="{{''}}" class="click-btn"-->
<!--                style="color:gray;float:right;">-->
<!--          <i class="material-icons">view_column</i>-->
<!--        </span>-->
        <div class="tc-icon-block tc-px-2">
            <button class="tc-icon-block__icon sort-icon tc-icon-block__icon--active"
                    matTooltip="{{'Show/Hide Columns' | translate}}"
                    mat-icon-button
                    [matMenuTriggerFor]="beforeMenuLeft"
                    [disabled]="">
                <i class="material-icons">view_column</i>
            </button>
            <mat-menu #beforeMenuLeft="matMenu" xPosition="before">
                <mat-selection-list
                    #selectedColFields
                    style="max-height: 400px;"
                    (selectionChange)=onSelectionChangeLeft($event)>
                    <mat-list-option
                            *ngFor="let coldef of _colDefConfigLeft"
                            [value]="coldef.colId"
                            [selected]="!coldef.hide"
                            (click)="onClickColOptionLeft($event)">
                        {{coldef.headerName | translate}}
                    </mat-list-option>
                </mat-selection-list>
            </mat-menu>
        </div>
        <ag-grid-angular
            #agGridLeft class="ag-theme-material group-setup-grid tc-ag-grid" [columnDefs]="columnDefsLeft"
            [defaultColDef]="defaultColDefLeft"
            [gridOptions]="gridOptionsLeft" [enableCellChangeFlash]="true" [rowSelection]="rowSelectionLeft"
            [rowMultiSelectWithClick]="true"
            (gridSizeChanged)="onGridSizeChangeLeft()"
            (gridReady)="onGridReadyLeft($event)"
            (rowDataChanged)="onLeftDataSet()">
        </ag-grid-angular>
    </div>

    <div id="middle" class="column-middle">
            <button
                    mat-icon-button color="primary" class="click-btn send-all-btn" matTooltip="{{'Add all' | translate}}"
                    (click)="onClickAddSelected()">
                <mat-icon>send</mat-icon>
            </button>

            <!-- <button mat-icon-button color="primary" class="click-btn send-all-btn" matTooltip="{{'Add all'}}" mat-button
              (click)="onClickAddAll()">
              <mat-icon>double_arrow</mat-icon>
            </button> -->
    </div>

    <div id="right" class="column-grid">

<!--        <span-->
<!--                (click)="onDeleteSelectedClickRight()" matTooltip="{{'Delete selected'}}" class="click-btn "-->
<!--                style="color:gray;float:right;">-->
<!--      <i class="material-icons">delete_sweep</i>-->
<!--    </span>-->
        <div class="tc-icon-block tc-px-2">
            <button  class="tc-icon-block__icon sort-icon tc-icon-block__icon--active"
                    matTooltip="{{'Delete selected' | translate}}"
                    mat-icon-button
                    [disabled]=""
                    (click)="onDeleteSelectedClickRight()">
                <i class="material-icons">delete_sweep</i>
            </button>
            <button class="tc-icon-block__icon sort-icon tc-icon-block__icon--active"
                    matTooltip="{{'Show/Hide Columns' | translate}}"
                mat-icon-button
                [matMenuTriggerFor]="beforeMenuRight"
                [disabled]="">
            <i class="material-icons">view_column</i>
            </button>
            <mat-menu #beforeMenuRight="matMenu" xPosition="before">
                <mat-selection-list
                    #selectedColFields
                    style="max-height: 400px;"
                    (selectionChange)=onSelectionChangeRight($event)>
                    <mat-list-option
                            *ngFor="let coldef of _colDefConfigRight"
                            [value]="coldef.colId"
                            [selected]="!coldef.hide"
                            (click)="onClickColOptionRight($event)">
                        {{coldef.headerName |translate}}
                    </mat-list-option>
                </mat-selection-list>
            </mat-menu>
        </div>
        <ag-grid-angular
            #agGridRight class="ag-theme-material group-setup-grid tc-ag-grid" [columnDefs]="columnDefsRight"
            [defaultColDef]="defaultColDefRight"
            [gridOptions]="gridOptionsRight" [enableCellChangeFlash]="true" [rowSelection]="rowSelectionRight"
            [rowMultiSelectWithClick]="true" (gridSizeChanged)="onGridSizeChangeRight()"
            (gridReady)="onGridReadyRight($event)">
        </ag-grid-angular>
    </div>
</div>
