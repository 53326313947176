import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
// import { CalendarMenuItem } from '@tc/cg-calendar/calendar-header-controller/calendar-menu-item';
// import { CalendarRefreshEventType } from '@tc/cg-calendar/calendar-refresh-event-type';
// import { RangeGroup } from '@tc/cg-calendar/range-group';
import { Observable } from 'rxjs';
import { CalendarMenuItem } from '../../cg-calendar/calendar-header-controller/calendar-menu-item';
import { CalendarRefreshEventType } from '../../cg-calendar/calendar-refresh-event-type';
import { RangeGroup } from '../../cg-calendar/range-group';

@Component({
  selector: 'tc-season-calendar-body',
  templateUrl: './season-calendar-body.component.html'
})
export class SeasonCalendarBodyComponent implements OnInit {

  @Input() fromYear: number = 0;
  @Input() toYear: number = 0;
  @Input() rangeGroups: RangeGroup[] = [];
  @Input() calendarRefreshEvent: Observable<CalendarRefreshEventType> = new Observable<any>();
  @Input() calendarMenuItems: CalendarMenuItem[] = [];
  @Input() viewOnlyMode: boolean = false;

  @Output() selectDate: EventEmitter<Date> = new EventEmitter();
  @Output() menuItemClick: EventEmitter<CalendarMenuItem> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  onSelectDate($event: Date) {
    this.selectDate.emit($event);
  }

  public onMenuItemClick($event: CalendarMenuItem) {
    this.menuItemClick.emit($event);
  }
}
