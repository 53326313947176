<form [formGroup]="supplierInfoForm">
    <div class="container-fluid direct-client-personal-info-edit-card tc-card-wrapper">
        <div class="row">
            <div class="col-md-12 col-lg-12">
                <div class="tc-flex-box">
                    <div class="tc-flex-item tc-item-circle">
                        <div class="icon-box">
                            <i class="material-icons">account_circle</i>
                        </div>
                    </div>
                    <div class="tc-flex-item tc-right-cage">
                        <div class="row">
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                                <!--<div class="row">-->
                                <!--<div class="col-sm-3 col-md-3 col-lg-3">-->
                                <div class="tc-select-edit dcpiec-select-title">
                                    <mat-form-field>

                                        <input matInput
                                               id="DIR_PER_INFO_EDIT_CODE"
                                               #codeInput
                                               [readonly]="inputDisable"
                                               formControlName="code"
                                               [placeholder]="'Code'"
                                               required
                                        >
                                        <mat-error>{{getErrorMessage('code')}}</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                                <div class="tc-input-edit dcpiec-input-fname">
                                    <mat-form-field>
                                        <input matInput
                                               id="DIR_PER_INFO_EDIT_NAME"
                                               #nameInput
                                               formControlName="name"
                                               [placeholder]="'Name'"
                                               required

                                        >
                                        <mat-error>{{getErrorMessage('name')}}</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                                <div class="tc-input-edit dcpiec-input-fname">
                                    <mat-form-field>
                                        <mat-select #localeInput
                                                    id="DIR_PER_INFO_EDIT_LOCALE"
                                                    formControlName="locale"
                                                    [placeholder]="'Locale'"
                                                    (selectionChange)="addLocale($event.value)"
                                                    multiple>
                                            <mat-option *ngFor="let option of localeOptions" [value]="option.code">
                                                {{option.name}}
                                            </mat-option>
                                        </mat-select>
                                        <!--<mat-error>{{getErrorMessage('fName')}}</mat-error>-->
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 dcpiec-chip-active-column">
                                <div class="tc-input-edit dcpiec-input-regnum">
                                    <mat-form-field>
                                        <input matInput
                                               id="DIR_PER_INFO_EDIT_REGNUM"
                                               #fNameInput
                                               formControlName="registrationNum"
                                               [placeholder]="'Registration Number'"
                                        >
                                        <!--<mat-error>{{getErrorMessage('fName')}}</mat-error>-->
                                    </mat-form-field>
                                </div>
                            </div>
                            <!--       <div class="col-xs-12 col-sm-12 col-md-1 col-lg-1 remove-bootstrap-padding-mobile">
                                       <div class="tc-input-edit dcpiec-chip-group">
                                           <div class="card-chips item-right">
                                               <div class="chip"
                                                    (click)="toggleValue(!status)"
                                                    [ngClass]="status ?'active':'' ">
                                                   Active
                                               </div>
                                           </div>
                                       </div>
                                   </div>-->
                        </div>
                        <div class="row">
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                                <div class="tc-input-edit dcpiec-input-licNumber">
                                    <mat-form-field>
                                        <input matInput
                                               id="DIR_PER_INFO_EDIT_LICNUMBER"
                                               #licNumberInput
                                               formControlName="licenseNum"
                                               [placeholder]="'License Number'">
                                    </mat-form-field>
                                </div>
                            </div>
                            <!--<div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile" *ngIf="this.resourceTypes.length === 1">
                                <div class="tc-input-edit dcpiec-input-currency">
                                    <mat-form-field>
                                        <input matInput #contractingDivision
                                                    id="DIR_PER_INFO_EDIT_RESOURCETYPE1"
                                                    formControlName="resourceType"
                                                    [(ngModel)]="validResource"
                                                    [placeholder]="'Resource Type'">
                                        &lt;!&ndash;<mat-error>{{getErrorMessage('resourceType')}}</mat-error>&ndash;&gt;
                                    </mat-form-field>
                                </div>
                            </div>-->
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                                <div class="tc-input-edit dcpiec-input-currency">
                                    <mat-form-field>
                                        <mat-select #contractingDivision
                                                    id="DIR_PER_INFO_EDIT_RESOURCETYPE"
                                                    formControlName="resourceType"
                                                    [(ngModel)]="validResource"
                                                    [placeholder]="'Resource Type'"
                                                    (selectionChange)="addType($event.value)"
                                                    multiple required>
                                            <mat-option [id]="option.code" *ngFor="let option of resourceTypes" [value]="option.code">
                                                {{option.name}}
                                            </mat-option>
                                        </mat-select>
                                        <mat-error>{{getErrorMessage('resourceType')}}</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                                <div class="tc-input-edit dcpiec-input-streetsddr">
                                    <mat-form-field>
                                        <input matInput
                                               id="DIR_PER_INFO_EDIT_STREETADDRESS"
                                               #streetAddressInput
                                               formControlName="streetAddress"
                                               [placeholder]="'Street Address'">
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                                <div class="tc-input-edit dcpiec-input-postCode">
                                    <mat-form-field>
                                        <input matInput
                                               id="DIR_PER_INFO_EDIT_POSTCODE"
                                               #postCodeInput
                                               formControlName="postCode"
                                               [placeholder]="'Post Code'">
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                                <div class="tc-input-edit dcpiec-input-country">

                                    <mat-form-field>
                                        <input
                                                matInput
                                                #countryInput
                                                formControlName="country"
                                                [placeholder]="'Country'"
                                                (focus)="onCountryFocus($event)"
                                                required
                                                [matAutocomplete]="countryAutoComplete">
                                        <mat-error
                                                *ngIf="supplierInfoForm.controls['country'].hasError('required')
                          ||supplierInfoForm.controls['country'].hasError('invalidString')">
                                            {{getErrorMessage('country')}}
                                        </mat-error>
                                    </mat-form-field>
                                    <mat-autocomplete #countryAutoComplete="matAutocomplete"
                                                      [displayWith]="displayCountryFn">
                                        <mat-option
                                                *ngFor="let country of countryList"
                                                (onSelectionChange)="onSelectCountry(country)"
                                                [value]="country">
                                            {{ country?.name }}
                                        </mat-option>
                                    </mat-autocomplete>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                                <div class="tc-input-edit dcpiec-input-country">
                                    <mat-form-field>
                                        <input
                                                matInput
                                                #stateInput
                                                formControlName="state"
                                                [matAutocomplete]="stateAutoComplete"
                                                (focus)="onStateFocus($event)"
                                                [placeholder]="'State'">
                                    </mat-form-field>
                                    <mat-autocomplete #stateAutoComplete="matAutocomplete"
                                                      [displayWith]="displayStateFn">
                                        <mat-option
                                                *ngFor="let state of stateList"
                                                [value]="state"
                                                (onSelectionChange)="onSelectState(state)">
                                            {{ state?.name }}
                                        </mat-option>
                                    </mat-autocomplete>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                                <div class="tc-input-edit dcpiec-input-country">
                                    <mat-form-field>
                                        <input
                                                matInput
                                                #cityInput
                                                formControlName="city"
                                                required
                                                (focus)="onCityFocus($event)"
                                                [matAutocomplete]="cityAutoComplete"
                                                [placeholder]="'City'"
                                        >
                                        <mat-error
                                                *ngIf="supplierInfoForm.controls['city'].hasError('required')
                          ||supplierInfoForm.controls['city'].hasError('invalidString')">
                                            {{getErrorMessage('city')}}
                                        </mat-error>
                                    </mat-form-field>
                                    <mat-autocomplete #cityAutoComplete="matAutocomplete"
                                                      [displayWith]="displayCityFn">
                                        <mat-option
                                                *ngFor="let city of cityList"
                                                [value]="city"
                                                (onSelectionChange)="onSelectCity(city)">
                                            {{ city?.name }}
                                        </mat-option>
                                    </mat-autocomplete>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                                <div class="tc-input-edit dcpiec-input-fname">
                                    <mat-form-field>
                                        <mat-select #localeInput
                                                    id="DIR_PER_INFO_EDIT_SUPPLIER_TYPE"
                                                    formControlName="supplierType"
                                                    [placeholder]="'Supplier Type'">
                                            <mat-option *ngFor="let option of supplierTypes" [value]="option.code">
                                                {{option.name}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                            <!--<div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                                <div class="tc-input-edit dcpiec-input-fname">
                                    <mat-form-field>
                                        <mat-select #localeInput
                                                    id="DIR_PER_INFO_EDIT_PROFILE_TYPE"
                                                    formControlName="profileType"
                                                    [placeholder]="'Profile Type'" multiple required>
                                            <mat-option *ngFor="let option of profileTypes" [value]="option.code">
                                                {{option.name}}
                                            </mat-option>
                                        </mat-select>
                                        <mat-error>{{getErrorMessage('profileType')}}</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>-->
                        </div>
                        <div class="row">
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                                <div class="tc-input-edit dcpiec-input-businessType">
                                    <mat-form-field>
                                        <mat-select #localeInput
                                                    id="DIR_PER_INFO_EDIT_BUSINESS_TYPE"
                                                    formControlName="businessType"
                                                    [placeholder]="'Business Type'" required>
                                            <mat-option *ngFor="let option of businessTypes | keyvalue" [value]="option.key">
                                                {{option.value}}
                                            </mat-option>
                                        </mat-select>
                                        <mat-error>{{getErrorMessage('businessType')}}</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                                <div class="tc-input-edit dcpiec-input-preference">
                                    <mat-form-field>
                                        <input matInput
                                               id="DIR_PER_INFO_EDIT_PREFERENCE"
                                               #preferenceInput
                                               formControlName="preference"
                                               [placeholder]="'Preference'"
                                        >
                                        <mat-error>{{getErrorMessage('preference')}}</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                                <div class="tc-input-edit dcpiec-input-grade">
                                    <mat-form-field>
                                        <mat-select #gradeInput
                                                    id="DIR_PER_INFO_EDIT_GRADE"
                                                    [(ngModel)]="gradeNgModel"
                                                    (selectionChange)="gradeSelectionOnChange($event)"
                                                    [ngModelOptions]="{standalone: true}"
                                                    [placeholder]="'Grade'">
                                            <mat-option *ngFor="let single of grades" [value]="single?.order">
                                                {{single?.displayName}}
                                            </mat-option>
                                        </mat-select>
                                        <mat-error>{{getErrorMessage('grade')}}</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <!--<div class="row">
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                                <div class="tc-input-edit dcpiec-input-fname">
                                    <mat-form-field>
                                        <input
                                                matInput
                                                formControlName="capacity"
                                                [placeholder]="'capacity'"
                                                type="number"
                                                [min]="1"
                                                required
                                        >
                                        <mat-error>{{getErrorMessage('capacity')}}</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>-->
                        <div class="tc-action-chip-list-wrapper tc-chip-selector__action-chips">
                            <div class="tc-action-chip"
                                 (click)="toggleActiveStatus(!activeStatus)"
                                 [ngClass]="activeStatus ?'tc-action-chip--active':'tc-action-chip--inactive' ">
                                <span class="tc-action-chip__icon tc-action-chip__icon-man"></span>
                                <span class="tc-action-chip__icon tc-action-chip__icon-select">
                                  <i class="material-icons">done</i>
                                </span>
                                <div class="tc-action-chip__text">
                                    Active
                                </div>
                            </div>
                            <div class="tc-action-chip"
                                 (click)="toggleServiceRequiredStatus(!serviceRequiredStatus)"
                                 [ngClass]="serviceRequiredStatus ?'tc-action-chip--active':'tc-action-chip--inactive' ">
                                <span class="tc-action-chip__icon tc-action-chip__icon-man"></span>
                                <span class="tc-action-chip__icon tc-action-chip__icon-select">
                                  <i class="material-icons">done</i>
                                </span>
                                <div class="tc-action-chip__text">
                                    {{'Service Required' | translate}}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
