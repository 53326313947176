import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatExpansionModule, MatIconModule, MatMenuModule } from '@angular/material';
import { ContentStripModule } from '@tc/content-strip';
import { DirectivesModule } from '@tc/directives';
import { ExpandedContentCardBodyModule } from '@tc/expanded-content-card-body';
import { ResourceModule } from '@tc/resource';
import { SummaryStripModule } from '@tc/summary-strip';
import { ResultContentCardModule } from '../../../../../widgets/shared/result-content-card/result-content-card.module';
import { RoomGrpSummaryCardComponent } from './room-grp-summary-card.component';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
    imports: [
        CommonModule,
        ExpandedContentCardBodyModule,
        MatExpansionModule,
        ContentStripModule,
        ResultContentCardModule,
        DirectivesModule,
        SummaryStripModule,
        ResourceModule,
        MatMenuModule,
        MatIconModule,
        TranslateModule.forChild()
    ],
  declarations: [RoomGrpSummaryCardComponent],
    exports: [RoomGrpSummaryCardComponent]
})
export class RoomGrpSummaryCardModule { }
