import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { DialogModel, LEVEL } from '@tc-core/model/it/codegen/ui/framework/dialog-model';
import { TC } from '@tc-core/util/constants';
import { ConfigLoader } from '@tc-core/util/framework/config-loader.service';
import { EventManager } from '@tc-core/util/framework/event-manager.service';
import { FocusViewManager } from '@tc-core/util/framework/focus-view-manager.service';
import { Chip } from '@tc/chip-selector/chip.model';
import { DialogService } from '@tc/dialog/dialog.service';
import { FocusViewService } from '@tc/focus-view/focus-view.service';
import { SorterPipe } from '@tc/pipes/sorter.pipe';
import { TCO } from '../../../constants';
import { ManageNotesComponent } from '../../../widgets/framework/manage-notes';
import { GenericSearchCriteria } from '../../../models/criteria/generic-search-criteria';
import { SupplierSearchCriteria } from '../../../models/criteria/supplier-search-criteria';
import { VehicleSearchCriteria } from '../../../models/criteria/vehicle-search-criteria';
import { ItemGroupNoteMap } from '../../../models/reservation/item-group-note-map';
import { NoteItemView } from '../../../models/reservation/note-item-view';
import { ServiceNote } from '../../../models/reservation/service-note';
import { TrsReservationManagementService } from '../../../services/backend-consumers/reservation-management-services/trs-reservation-management.service';
import { GenericResourceService } from '../../../services/backend-consumers/supplier-service/generic-resource.service';
import { SupplierService } from '../../../services/backend-consumers/supplier-service/supplier.service';
import { TransportResourceService } from '../../../services/backend-consumers/supplier-service/transport-resource.service';
import { DataKey, DataStoreService } from '../../../services/util/framework/data-store.service';
import { ResponseUtil } from '../../../services/util/response/response-util.service';
import { CardDataHandlerService } from '../../../services/util/ui/card-data-handler-service.service';
var ServiceItemDetailFocusViewComponent = /** @class */ (function () {
    function ServiceItemDetailFocusViewComponent(supplierService, configLoader, sortPipe, transportResourceService, reservationService, dialog, dialogService, cardDataHandlerService, dataStore, em, focusViewManager, focusViewService, genericResourceService) {
        this.supplierService = supplierService;
        this.configLoader = configLoader;
        this.sortPipe = sortPipe;
        this.transportResourceService = transportResourceService;
        this.reservationService = reservationService;
        this.dialog = dialog;
        this.dialogService = dialogService;
        this.cardDataHandlerService = cardDataHandlerService;
        this.dataStore = dataStore;
        this.em = em;
        this.focusViewManager = focusViewManager;
        this.focusViewService = focusViewService;
        this.genericResourceService = genericResourceService;
        this.noteList = [];
        this.assignmentStatus = '';
        this.addNoteTitle = 'Add New Note';
        this.masterNoteTypes = [];
        this.CARD_CONFIG_NOTES = 'notes';
        this.noteContents = [];
        this.isConfirmed = false;
        this.editNoteTitle = 'Edit Note';
        this.historyText = '';
        this.addNoteToolTip = 'Add Note';
        this.toolTipPosition = 'below';
    }
    ServiceItemDetailFocusViewComponent.prototype.ngOnInit = function () {
        var _this = this;
        var e_1, _a, e_2, _b;
        this.masterNoteTypes = this.configLoader.configurations.get(TCO.CONF.CONF_CONTENT_LIST_CARD).master_note_types;
        this.focusViewManager.canProceed.next(true);
        this.focusViewManager.canProceedState.next(true);
        this.noteList = this.selectedServiceItem['rawData']['serviceNotes'];
        if (this.selectedServiceItem['rawData']['assignment'] !== null) {
            if (this.selectedServiceItem['rawData']['assignment'].status === 1) {
                this.assignmentStatus = 'Confirmed';
                this.isConfirmed = true;
            }
            else if (this.selectedServiceItem['rawData']['assignment'].status === 2) {
                this.assignmentStatus = 'Supplier Confirmed';
                this.isConfirmed = true;
            }
            else if (this.selectedServiceItem['rawData']['assignment'].status === 3) {
                this.assignmentStatus = 'Supplier Rejected';
                this.isConfirmed = true;
            }
            else {
                this.assignmentStatus = '';
                this.isConfirmed = false;
            }
        }
        if (this.selectedServiceItem['rawData']['serviceItemHistories'] &&
            this.selectedServiceItem['rawData']['serviceItemHistories'].length > 0) {
            var historyTexList = [];
            try {
                for (var _c = tslib_1.__values(this.selectedServiceItem['rawData']['serviceItemHistories']), _d = _c.next(); !_d.done; _d = _c.next()) {
                    var serviceItemHistory = _d.value;
                    if (serviceItemHistory.description) {
                        historyTexList.push.apply(historyTexList, tslib_1.__spread(serviceItemHistory.description.split('. ')));
                    }
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
                }
                finally { if (e_1) throw e_1.error; }
            }
            try {
                for (var historyTexList_1 = tslib_1.__values(historyTexList), historyTexList_1_1 = historyTexList_1.next(); !historyTexList_1_1.done; historyTexList_1_1 = historyTexList_1.next()) {
                    var historyTexListElement = historyTexList_1_1.value;
                    this.historyText += historyTexListElement + '<br>';
                }
            }
            catch (e_2_1) { e_2 = { error: e_2_1 }; }
            finally {
                try {
                    if (historyTexList_1_1 && !historyTexList_1_1.done && (_b = historyTexList_1.return)) _b.call(historyTexList_1);
                }
                finally { if (e_2) throw e_2.error; }
            }
        }
        this.onNoteListUpdate();
        if (this.selectedServiceItem['supplier'] > 0) {
            this.getSelectedSupplier();
        }
        if (this.selectedServiceItem['resource'] > 0) {
            this.getSelectedVehicle();
        }
        if (this.selectedServiceItem['driver'] > 0) {
            this.getSelectedDriver();
        }
        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'CLOSE_FOCUS_VIEW').subscribe(function (e) {
            _this.focusViewService.close(true);
        });
    };
    ServiceItemDetailFocusViewComponent.prototype.getSelectedSupplier = function () {
        var _this = this;
        var supplierSearchCriteria = new SupplierSearchCriteria();
        supplierSearchCriteria.supplierId = this.selectedServiceItem['supplier'];
        this.supplierService.searchSuppliersForAssignment(supplierSearchCriteria).subscribe(function (result) {
            if (result) {
                _this.selectedSupplier = ResponseUtil.getFirstData(result);
            }
        }, function (error) {
        });
    };
    ServiceItemDetailFocusViewComponent.prototype.getSelectedVehicle = function () {
        var _this = this;
        var vehicleSearchCriteria = new VehicleSearchCriteria();
        vehicleSearchCriteria.activeStatus = true;
        vehicleSearchCriteria.resourceId = this.selectedServiceItem['resource'];
        this.transportResourceService.searchVehicleSummaries(vehicleSearchCriteria).subscribe(function (result) {
            if (result) {
                _this.selectedVehicle = ResponseUtil.getFirstData(result);
            }
        }, function (error) {
        });
    };
    ServiceItemDetailFocusViewComponent.prototype.getSelectedDriver = function () {
        var _this = this;
        var genericSearchCriteria = new GenericSearchCriteria();
        genericSearchCriteria.activeStatus = 'true';
        genericSearchCriteria.resourceId = this.selectedServiceItem['driver'];
        this.genericResourceService.searchGenericResource(genericSearchCriteria).subscribe(function (result) {
            if (result) {
                _this.selectedDriver = ResponseUtil.getFirstData(result);
            }
        });
    };
    ServiceItemDetailFocusViewComponent.prototype.onAddNotesClick = function (event) {
        var _this = this;
        var addNoteDialogRef = this.getNoteDialogRef(this.addNoteTitle, '', '', '', '', false);
        addNoteDialogRef.afterClosed().subscribe(function (result) {
            if (result) {
                console.log(result);
                _this.saveItemNote(result);
                /*if (productKey) {
                 this.addBkgItemNote(
                 this.bookingHelperService.buildKeyControls(
                 this.booking,
                 this.client,
                 this.bookingSearchCriteria,
                 this.user
                 ), productKey, result
                 );
                 } else {
                 this.addNote(
                 this.bookingHelperService.buildKeyControls(
                 this.booking,
                 this.client,
                 this.bookingSearchCriteria,
                 this.user
                 ), result
                 );
                 }*/
            }
        });
    };
    ServiceItemDetailFocusViewComponent.prototype.saveItemNote = function (note) {
        var _this = this;
        var serviceItemNote = new ItemGroupNoteMap();
        serviceItemNote.serviceNote = new ServiceNote();
        serviceItemNote.serviceNote.createdOn = Date.now();
        serviceItemNote.serviceNote.note = note.note;
        serviceItemNote.serviceNote.type = note.selectedNoteTypes[0].value.code;
        serviceItemNote.serviceItemId = this.selectedServiceItem.serviceItemId;
        this.reservationService.saveServiceItemNote(serviceItemNote.serviceItemId, serviceItemNote)
            .subscribe(function (noteResult) {
            if (noteResult !== null) {
                _this.noteList = ResponseUtil.getDataArray(noteResult);
                _this.dataStore.set(DataKey.focusViewDataChanged, true);
                _this.onNoteListUpdate();
            }
        });
    };
    ServiceItemDetailFocusViewComponent.prototype.onNoteListUpdate = function () {
        var e_3, _a;
        this.masterNoteTypes = this.configLoader.configurations.get(TCO.CONF.CONF_CONTENT_LIST_CARD).master_note_types;
        this.noteSummary = this.cardDataHandlerService.processCardData(this.CARD_CONFIG_NOTES, this.noteList, 'note-summary');
        if (this.noteList.length > 0) {
            this.noteContents = [];
            try {
                for (var _b = tslib_1.__values(this.noteList), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var note = _c.value;
                    var noteContent = new NoteItemView();
                    noteContent.header = this.cardDataHandlerService.processCardData(this.CARD_CONFIG_NOTES, note, 'note-detail.header');
                    noteContent.summary = this.cardDataHandlerService.processCardData(this.CARD_CONFIG_NOTES, note, 'note-detail.summary');
                    noteContent.detail = this.cardDataHandlerService.processCardData(this.CARD_CONFIG_NOTES, note, 'note-detail.detail');
                    this.noteContents.push(noteContent);
                }
            }
            catch (e_3_1) { e_3 = { error: e_3_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_3) throw e_3.error; }
            }
        }
    };
    ServiceItemDetailFocusViewComponent.prototype.getNoteDialogRef = function (noteTitle, noteText, noteType, noteMethodCodes, noteResourcePath, isEdit) {
        if (isEdit === void 0) { isEdit = false; }
        var noteTypes = this.sortPipe.transform(this.masterNoteTypes, 'name');
        return this.dialog.open(ManageNotesComponent, {
            width: '', height: '',
            data: {
                title: noteTitle,
                note: noteText,
                noteTypes: noteTypes,
                selectedNoteType: noteType,
                isNoteEditMode: isEdit,
                selectedNoteTypes: this.getSelectedNoteType(noteTypes, noteType),
                methodCodes: noteMethodCodes,
                resourcePath: noteResourcePath,
                isMultiSelect: false
            }
        });
    };
    ServiceItemDetailFocusViewComponent.prototype.getSelectedNoteType = function (noteTypes, noteType) {
        var selectedNotes = [];
        if (noteTypes && noteTypes.length > 0 && noteType) {
            var selectedNoteTypes = noteTypes.filter(function (note) { return note.code === noteType; });
            var chip = new Chip();
            chip.selected = true;
            chip.value = selectedNoteTypes[0];
            chip.restricted = false;
            selectedNotes.push(chip);
        }
        return selectedNotes;
    };
    ServiceItemDetailFocusViewComponent.prototype.onNoteSelection = function (note) {
    };
    ServiceItemDetailFocusViewComponent.prototype.onNotesEditClick = function (note) {
        if (note && note.data && note.data.value) {
            this.openEditNoteDialog(note.data.value);
        }
    };
    ServiceItemDetailFocusViewComponent.prototype.openEditNoteDialog = function (noteDetail) {
        var _this = this;
        var editNoteDialogRef = this.getNoteDialogRef(this.editNoteTitle, noteDetail.note, noteDetail.type, 'tc-manage-notes-edit.NOTE_EDIT', '@tc-manage-notes-edit.NOTE_EDIT', true);
        editNoteDialogRef.afterClosed().subscribe(function (result) {
            if (result) {
                _this.editItemNote(noteDetail, result);
            }
        });
    };
    ServiceItemDetailFocusViewComponent.prototype.editItemNote = function (noteDetail, editedNoteContent) {
        var _this = this;
        noteDetail.note = editedNoteContent.note;
        this.reservationService.editServiceNote(this.selectedServiceItem.serviceItemId, noteDetail.serviceNoteId, noteDetail)
            .subscribe(function (result) {
            if (result) {
                _this.dataStore.set(DataKey.focusViewDataChanged, true);
                _this.onNoteListUpdate();
            }
        });
    };
    ServiceItemDetailFocusViewComponent.prototype.onNotesDeleteClick = function (note) {
        if (note && note.data && note.data.value) {
            var noteIndex = note.data.value.serviceNoteId;
            this.deleteNote(noteIndex);
        }
    };
    ServiceItemDetailFocusViewComponent.prototype.deleteNote = function (noteIndex) {
        var _this = this;
        var confirmDataObject = new DialogModel(true, LEVEL.WARNING, 'Delete Note', 'This Note will be deleted. Do you wish to proceed?', true, 3000);
        this.dialogService.confirm(confirmDataObject).subscribe(function (res) {
            if (res === true) {
                _this.deleteItemNote(noteIndex);
            }
        });
    };
    ServiceItemDetailFocusViewComponent.prototype.deleteItemNote = function (noteIndex) {
        var _this = this;
        this.reservationService.deleteServiceNote(this.selectedServiceItem.serviceItemId, noteIndex).subscribe(function (result) {
            for (var i = 0; i < _this.noteList.length; i++) {
                var id = _this.noteList[i].serviceNoteId;
                if (id === noteIndex) {
                    _this.noteList.splice(i, 1);
                    break;
                }
            }
            _this.dataStore.set(DataKey.focusViewDataChanged, true);
            _this.onNoteListUpdate();
        });
    };
    return ServiceItemDetailFocusViewComponent;
}());
export { ServiceItemDetailFocusViewComponent };
