import { KeyControl } from '../common/key-control';
import { BookingItem } from './booking-item';
import { BookingNote } from './booking-note';
import { BookingPassenger } from './booking-passenger';
import { BookingType } from './booking-type';
import {BookingSupplement} from './booking-supplement';

export class Booking extends KeyControl {
    id: number;
    bookingSource: string;
    bookingId: number;
    bookingIdCombined: string;
    bookingType: BookingType;
    startTime: Date;
    endTime: Date;
    itemImportedDate: Date;
    noOfItems: number;
    bookingItems: BookingItem[];
    bookingPassengers: BookingPassenger[];
    bookingNotes: BookingNote[];
    supplementNotes: BookingSupplement[];
    createdOn: Date;
    bookingDate: Date;
    kcCompanies: [];
    kcBrands: [];
    kcDistributionChannels: [];
    kcDivisions: [];
    isEditTriggered = false;
}
