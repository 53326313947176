<div class="container split-passengers-panel">
    <div class="container-fluid tc-card-wrapper">
        <div class="row">
            <div class="col-md-12 col-lg-12">
                <div class="tc-flex-box">
                    <form [formGroup]="docCreationForm" class="rs-item-card__resource-form container-fluid">
                        <div class="tc-flex-item tc-right-cage">
                            <div class="row">
                                <div class="col-xs-8 remove-bootstrap-padding-mobile">
                                    <div class="tc-input-edit dcpiec-input-lname">
                                        <mat-form-field>
                                            <input
                                                    matInput
                                                    maxlength="30"
                                                    formControlName="documentName"
                                                    [placeholder]="'Document Name'"
                                            >
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="col-xs-4 remove-bootstrap-padding-mobile align--right">
                                    <div class="tc-input-edit dcpiec-input-lname">
                                        <mat-checkbox #autoName_checkBox
                                                      formControlName="autoName"
                                                      (ngModelChange)="changeAutoName()">
                                            {{'Auto Set Name' |translate}}
                                        </mat-checkbox>
                                    </div>

                                </div>
                            </div>
                            <div class="row">
                                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 remove-bootstrap-padding-mobile">
                                    <div class="tc-select-edit dcpiec-select-title">
                                        <mat-form-field>
                                            <mat-select
                                                    [placeholder]="'Document Type'"
                                                    formControlName="documentType"
                                                    required>
                                                <mat-select-trigger>
                                                    <mat-icon
                                                            *ngIf="documentCreationModel?.documentType?.icon">{{documentCreationModel?.documentType ? documentCreationModel.documentType.icon : ''}}</mat-icon>&nbsp;{{documentCreationModel?.documentType ? documentCreationModel.documentType.name : ''}}
                                                </mat-select-trigger>
                                                <mat-option *ngFor="let docType of documentTypes" [value]="docType">
                                                    <mat-icon>{{docType.icon}}</mat-icon>&nbsp;{{docType.name}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="tc-input-edit dcpiec-input-lname" *ngIf="documentCreationModel?.queueVersion == 'V2' && documentCreationModel?.documentType?.code == exportReport">
                                    <mat-radio-group formControlName="viewType">
                                        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 remove-bootstrap-padding-mobile">
                                            <mat-radio-button value="SERVICING_VIEW"
                                                              checked=true>{{'Servicing View' | translate}}</mat-radio-button>
                                        </div>
                                        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 remove-bootstrap-padding-mobile">
                                            <mat-radio-button
                                                    value="BOOKING_VIEW">{{'Booking View' | translate}}</mat-radio-button>
                                        </div>
                                    </mat-radio-group>
                                </div>
                            </div>
                            <br>

                            <div class="row">
                                <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 remove-bootstrap-padding-mobile">
                                    <div class="tc-input-edit dcpiec-input-lname">
                                        <mat-checkbox
                                                formControlName="download">
                                            {{'Download' |translate}}
                                        </mat-checkbox>
                                    </div>
                                </div>
                                <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 remove-bootstrap-padding-mobile">
                                    <div class="tc-input-edit dcpiec-input-lname">
                                        <mat-checkbox
                                                formControlName="notifySupplier">
                                            {{'Notify Supplier' | translate}}
                                        </mat-checkbox>
                                    </div>

                                </div>
                            </div>
                            <div class="row">
                                <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 remove-bootstrap-padding-mobile">
                                    <div class="tc-input-edit dcpiec-input-lname">
                                        <mat-checkbox
                                                formControlName="persist">
                                            {{'Add to Repository' | translate}}
                                        </mat-checkbox>
                                    </div>

                                </div>
                            </div>
                            <div class="row col-xs-12" *ngIf="runInBackground">
                                <br>
                                <p *ngIf="numOfItemsSelected>=minItemsToRunInBackground">{{numOfItemsSelected}}{{' items selected.' | translate}}{{' This exceeds the max limit'| translate}}{{' (' + minItemsToRunInBackground + ').'}}  {{' Task will be handled in background.' | translate}}</p>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="tc-modal-footer">
    <button class="button-right tc-button-secondary mat-button" (click)="onClose()">
        {{'Close' | translate}}
    </button>
    <button
            class="button-right tc-button-primary mat-button" (click)="onOkClick()"
            [disabled]="isOkDisabled()">
        {{'Generate'| translate}}
    </button>
</div>
