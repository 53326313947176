import * as tslib_1 from "tslib";
import { ChangeDetectorRef, EventEmitter, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { CurrencyFormat } from '@tc-core/model/it/codegen/ui/framework/currency-format';
import { SummaryCardData } from '@tc-core/model/it/codegen/ui/framework/summary-card-data';
import { TC } from '@tc-core/util';
import { ConfigLoader } from '@tc-core/util/framework/config-loader.service';
import { TCO } from '../../../constants';
import { ResourceAllocationEvent } from '../../../models/helper/resource-allocation-event';
import { ResourceType } from '../../../models/reservation/assignment';
import { AssignmentStatus, StatusCodes } from '../../../models/reservation/assignment-status';
import { ReservationStatus } from '../../../models/reservation/service-item';
import { ChangeDetectorService } from '../../../services/util/change-detector/change-detector.service';
var ReservationServiceItemComponent = /** @class */ (function () {
    function ReservationServiceItemComponent(fb, cd, changeDetector, configLoader) {
        this.fb = fb;
        this.cd = cd;
        this.changeDetector = changeDetector;
        this.configLoader = configLoader;
        this.EMPTY_CHAR = TC.FORMAT.EMPTY_CHAR;
        this.summaryContent = null;
        this.checkList = null;
        this.isSideIcon = false;
        this.changeCorner = false;
        this.serviceItemChangesInSelectedGroup = new Map();
        this.moreClick = new EventEmitter();
        this.historyClick = new EventEmitter();
        this.leadPassengerClick = new EventEmitter();
        this.splitClick = new EventEmitter();
        this.changeFormValidity = new EventEmitter();
        this.checkChanged = new EventEmitter();
        this.vehicleSupplierAssign = new EventEmitter();
        this.driverSupplierAssign = new EventEmitter();
        this.vehicleAssign = new EventEmitter();
        this.driverAssign = new EventEmitter();
        this.vehicleSupplierClear = new EventEmitter();
        this.driverSupplierClear = new EventEmitter();
        this.vehicleClear = new EventEmitter();
        this.driverClear = new EventEmitter();
        this.clearVehicleAssignment = new EventEmitter();
        this.clearDriverAssignment = new EventEmitter();
        this.vehicleSupplierTextChange = new EventEmitter();
        this.driverSupplierTextChange = new EventEmitter();
        this.vehicleTextChange = new EventEmitter();
        this.vehicleJobNoChange = new EventEmitter();
        this.driverTextChange = new EventEmitter();
        this.driverJobNoChange = new EventEmitter();
        this.vehicleTextFocus = new EventEmitter();
        this.viewMoreFocus = new EventEmitter();
        this.isUpdated = false;
        this.isCancelled = false;
        this.checked = false;
        this.estimatedCost = 0;
        this.jobCost = 0;
        this.flightNumber = '';
        this.NEUTRAL_STATUS = AssignmentStatus.PENDING;
        this.CONFIRM_STATUS = AssignmentStatus.DMC_CONFIRM;
        this.SUPPLIER_CONFIRM_STATUS = AssignmentStatus.SUPPLIER_CONFIRMED;
        this.SUPPLIER_REJECTED_STATUS = AssignmentStatus.SUPPLIER_REJECTED;
        this.BOOKING_UPDATE_STATUS = AssignmentStatus.BOOKING_UPDATE;
        this.INACTIVE_NOTIFY_STATUS = AssignmentStatus.INACTIVE_NOTIFY;
        this.INACTIVE_STATUS = AssignmentStatus.INACTIVE;
        this.COMPLETED_STATUS = AssignmentStatus.COMPLETED;
        this.NO_SHOW_STATUS = AssignmentStatus.NO_SHOW;
        this.REQUEST_EXPIRED_STATUS = AssignmentStatus.REQUEST_EXPIRED;
        this.statusCodes = StatusCodes;
        this.defaultCost = 0.00;
        this.isFormCreated = false;
    }
    ReservationServiceItemComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.rawData = this.summaryContent.data.value['rawData'];
        this.serviceItemId = this.summaryContent.data.value['serviceItemId'];
        this.status = this.summaryContent.data.value['status'];
        this.vehicleAssignmentStatus = this.summaryContent.data.value['vehicleAssignmentStatus'];
        this.driverAssignmentStatus = this.summaryContent.data.value['driverAssignmentStatus'];
        var trsServiceItem = this.summaryContent.data.value['rawData']['trsServiceItem'];
        this.flightNumber = trsServiceItem['arrFltNo'] ? trsServiceItem['arrFltNo'] : trsServiceItem['depFltNo'];
        this.getCost();
        this.getEstimatedCost();
        this.createForm();
        this.changeDetector.notifierState.subscribe(function (value) {
            _this.updateForm();
        });
        this.allocationForm.valueChanges.subscribe(function (data) {
            if (JSON.stringify(data) !== JSON.stringify({})) {
                if (data.vehicleSupplier !== undefined && typeof (data.vehicleSupplier) !== 'object') {
                    _this.filteredVehicleSuppliers = [];
                    _this.vehicleSupplierTextChange.emit({
                        serviceItemId: _this.serviceItemId,
                        text: data.vehicleSupplier,
                        serviceItem: _this.summaryContent
                    });
                }
                if (data.driverSupplier !== undefined && typeof (data.driverSupplier) !== 'object') {
                    _this.filteredDriverSuppliers = [];
                    _this.driverSupplierTextChange.emit({
                        serviceItemId: _this.serviceItemId,
                        text: data.driverSupplier,
                        serviceItem: _this.summaryContent
                    });
                }
                if (data.vehicle !== undefined && typeof (data.vehicle) !== 'object') {
                    _this.filteredVehicles = [];
                    _this.vehicleTextChange.emit({
                        serviceItemId: _this.serviceItemId,
                        text: data.vehicle,
                        serviceItem: _this.summaryContent
                    });
                }
                if (data.driver !== undefined && typeof (data.driver) !== 'object') {
                    _this.filteredDrivers = [];
                    _this.driverTextChange.emit({
                        serviceItemId: _this.serviceItemId,
                        text: data.driver,
                        serviceItem: _this.summaryContent
                    });
                }
                if (data.vehicleJobNo !== undefined && typeof (data.vehicleJobNo) !== 'object') {
                    _this.vehicleJobNoChange.emit({
                        serviceItemId: _this.serviceItemId,
                        text: data.vehicleJobNo,
                        serviceItem: _this.summaryContent
                    });
                }
                if (data.driverJobNo !== undefined && typeof (data.driverJobNo) !== 'object') {
                    _this.driverJobNoChange.emit({
                        serviceItemId: _this.serviceItemId,
                        text: data.driverJobNo,
                        serviceItem: _this.summaryContent
                    });
                }
            }
        });
        // reservation status
        if (this.summaryContent.data.value['reservationStatus']) {
            if (this.summaryContent.data.value['reservationStatus'] === ReservationStatus.CANCELLED) {
                this.isCancelled = true;
            }
            else if (this.summaryContent.data.value['reservationStatus'] === ReservationStatus.UPDATED) {
                this.isUpdated = true;
            }
        }
        this.currencyFormat = this.getCurrencyFormat();
    };
    ReservationServiceItemComponent.prototype.getCurrencyFormat = function () {
        var currencyFormat;
        var currencySymbolPattern = this.configLoader.configurations.get(TC.CONF.CONF_SYSTEM).defaults.default_currency_symbol_pattern;
        currencyFormat = new CurrencyFormat(TCO.AppData.DEF_CURRENCY_TYPE, currencySymbolPattern);
        return currencyFormat;
    };
    ReservationServiceItemComponent.prototype.getFilteredArray = function (val, array) {
        if (val && val.length > 0) {
            var filteredArray_1 = [];
            array.forEach(function (data) {
                if (data.name.length > 0 && ((data.name.toLowerCase().indexOf(val.toLowerCase()) >= 0) ||
                    (data.code.toLowerCase().indexOf(val.toLowerCase()) >= 0))) {
                    filteredArray_1.push(data);
                }
            });
            return filteredArray_1;
        }
        else {
            return array;
        }
    };
    ReservationServiceItemComponent.prototype.createForm = function () {
        this.allocationForm = this.fb.group({
            vehicleSupplier: '',
            driverSupplier: '',
            vehicle: '',
            driver: '',
            vehicleJobNo: '',
            driverJobNo: ''
        });
        this.isFormCreated = true;
    };
    ReservationServiceItemComponent.prototype.updateForm = function () {
        this.selectedVehicleSupplier = this.serviceItemChangesInSelectedGroup.get(this.serviceItemId).vehicleSupplier;
        this.selectedDriverSupplier = this.serviceItemChangesInSelectedGroup.get(this.serviceItemId).driverSupplier;
        this.selectedVehicle = this.serviceItemChangesInSelectedGroup.get(this.serviceItemId).vehicle;
        this.selectedDriver = this.serviceItemChangesInSelectedGroup.get(this.serviceItemId).driver;
        if (this.selectedVehicle || this.selectedVehicleSupplier) {
            this.selectedVehicleJobNo = this.serviceItemChangesInSelectedGroup.get(this.serviceItemId)
                .serviceAssignments.get(ResourceType.vehicle) &&
                this.serviceItemChangesInSelectedGroup.get(this.serviceItemId)
                    .serviceAssignments.get(ResourceType.vehicle).assignmentId > 0 ?
                this.serviceItemChangesInSelectedGroup.get(this.serviceItemId)
                    .serviceAssignments.get(ResourceType.vehicle).assignmentId :
                this.serviceItemChangesInSelectedGroup.get(this.serviceItemId)
                    .serviceAssignments.get(ResourceType.vehicle).tempAssignmentId;
        }
        if (this.selectedDriver || this.selectedDriverSupplier) {
            this.selectedDriverJobNo = this.serviceItemChangesInSelectedGroup.get(this.serviceItemId)
                .serviceAssignments.get(ResourceType.driver) &&
                this.serviceItemChangesInSelectedGroup.get(this.serviceItemId).serviceAssignments
                    .get(ResourceType.driver).assignmentId > 0 ?
                this.serviceItemChangesInSelectedGroup.get(this.serviceItemId).serviceAssignments
                    .get(ResourceType.driver).assignmentId :
                this.serviceItemChangesInSelectedGroup.get(this.serviceItemId).serviceAssignments
                    .get(ResourceType.driver).tempAssignmentId;
        }
        this.allocationForm.patchValue({
            vehicleSupplier: this.selectedVehicleSupplier,
            driverSupplier: this.selectedDriverSupplier,
            vehicle: this.selectedVehicle,
            driver: this.selectedDriver,
            vehicleJobNo: this.selectedVehicleJobNo,
            driverJobNo: this.selectedDriverJobNo
        });
    };
    // display
    ReservationServiceItemComponent.prototype.displayVehicleSupplierFn = function (supplier) {
        return supplier ? supplier.name : supplier;
    };
    ReservationServiceItemComponent.prototype.displayDriverSupplierFn = function (supplier) {
        return supplier ? supplier.name : supplier;
    };
    ReservationServiceItemComponent.prototype.displayVehicleFn = function (resource) {
        return resource ? resource.resourceName : resource;
    };
    ReservationServiceItemComponent.prototype.displayDriverFn = function (driver) {
        return driver ? driver.resourceName : driver;
    };
    // select
    ReservationServiceItemComponent.prototype.onSelectVehicleSupplier = function (supplier) {
        this.selectedVehicleSupplier = supplier;
        this.vehicleSupplierAssign.emit(new ResourceAllocationEvent(this.serviceItemId, this.selectedVehicleSupplier, this.selectedVehicle, this.selectedDriverSupplier, this.selectedDriver));
        this.isSavedChild = false;
        this.getEstimatedCost();
        this.getCost();
    };
    ReservationServiceItemComponent.prototype.onSelectDriverSupplier = function (supplier) {
        this.selectedDriverSupplier = supplier;
        this.driverSupplierAssign.emit(new ResourceAllocationEvent(this.serviceItemId, this.selectedVehicleSupplier, this.selectedVehicle, this.selectedDriverSupplier, this.selectedDriver));
        this.isSavedChild = false;
        this.getEstimatedCost();
        this.getCost();
    };
    ReservationServiceItemComponent.prototype.onSelectVehicle = function (resource) {
        this.selectedVehicle = resource;
        this.vehicleAssign.emit(new ResourceAllocationEvent(this.serviceItemId, this.selectedVehicleSupplier, this.selectedVehicle, this.selectedDriverSupplier, this.selectedDriver));
        this.isSavedChild = false;
        this.getEstimatedCost();
        this.getCost();
    };
    ReservationServiceItemComponent.prototype.onSelectDriver = function (driver) {
        this.selectedDriver = driver;
        this.driverAssign.emit(new ResourceAllocationEvent(this.serviceItemId, this.selectedVehicleSupplier, this.selectedVehicle, this.selectedDriverSupplier, this.selectedDriver));
        this.isSavedChild = false;
        this.getEstimatedCost();
        this.getCost();
    };
    // clear
    ReservationServiceItemComponent.prototype.clearVehicleSupplier = function () {
        if (this.selectedVehicleSupplier != null) {
            this.isSavedChild = false;
        }
        this.allocationForm.get('vehicleSupplier').setValue('');
        this.selectedVehicleSupplier = null;
        this.vehicleSupplierClear.emit(new ResourceAllocationEvent(this.serviceItemId, this.selectedVehicleSupplier, this.selectedVehicle, this.selectedDriverSupplier, this.selectedDriver));
        this.getEstimatedCost();
        this.getCost();
    };
    ReservationServiceItemComponent.prototype.clearDriverSupplier = function () {
        if (this.selectedDriverSupplier != null) {
            this.isSavedChild = false;
        }
        this.allocationForm.get('driverSupplier').setValue('');
        this.selectedDriverSupplier = null;
        this.driverSupplierClear.emit(new ResourceAllocationEvent(this.serviceItemId, this.selectedVehicleSupplier, this.selectedVehicle, this.selectedDriverSupplier, this.selectedDriver));
        this.getEstimatedCost();
        this.getCost();
    };
    ReservationServiceItemComponent.prototype.clearVehicle = function () {
        if (this.selectedVehicle != null) {
            console.log(this.selectedVehicle);
            this.isSavedChild = false;
        }
        this.allocationForm.get('vehicle').setValue('');
        this.selectedVehicle = null;
        this.vehicleClear.emit(new ResourceAllocationEvent(this.serviceItemId, this.selectedVehicleSupplier, this.selectedVehicle, this.selectedDriverSupplier, this.selectedDriver));
    };
    ReservationServiceItemComponent.prototype.clearDriver = function () {
        if (this.selectedDriver != null) {
            this.isSavedChild = false;
        }
        this.allocationForm.get('driver').setValue('');
        this.selectedDriver = null;
        this.driverClear.emit(new ResourceAllocationEvent(this.serviceItemId, this.selectedVehicleSupplier, this.selectedVehicle, this.selectedDriverSupplier, this.selectedDriver));
        this.getEstimatedCost();
        this.getCost();
    };
    ReservationServiceItemComponent.prototype.onClearVehicleAssignment = function () {
        this.selectedVehicle = null;
        this.selectedVehicleSupplier = null;
        this.selectedVehicleJobNo = null;
        this.allocationForm.get('vehicleSupplier').setValue('');
        this.allocationForm.get('vehicle').setValue('');
        this.allocationForm.get('vehicleJobNo').setValue('');
        this.clearVehicleAssignment.emit(new ResourceAllocationEvent(this.serviceItemId, this.selectedVehicleSupplier, this.selectedVehicle, this.selectedDriverSupplier, this.selectedDriver));
    };
    ReservationServiceItemComponent.prototype.onClearDriverAssignment = function () {
        this.selectedDriver = null;
        this.selectedDriverSupplier = null;
        this.selectedDriverJobNo = null;
        this.allocationForm.get('driverSupplier').setValue('');
        this.allocationForm.get('driver').setValue('');
        this.allocationForm.get('driverJobNo').setValue('');
        this.clearDriverAssignment.emit(new ResourceAllocationEvent(this.serviceItemId, this.selectedVehicleSupplier, this.selectedVehicle, this.selectedDriverSupplier, this.selectedDriver));
    };
    // focus
    ReservationServiceItemComponent.prototype.onVehicleSupplierFocus = function ($event) {
        this.filteredVehicleSuppliers = [];
        this.vehicleSupplierTextChange.emit({
            serviceItemId: this.serviceItemId,
            text: this.allocationForm.get('vehicleSupplier').value,
            serviceItem: this.summaryContent
        });
    };
    ReservationServiceItemComponent.prototype.onDriverSupplierFocus = function ($event) {
        this.filteredDriverSuppliers = [];
        this.driverSupplierTextChange.emit({
            serviceItemId: this.serviceItemId,
            text: this.allocationForm.get('driverSupplier').value,
            serviceItem: this.summaryContent
        });
    };
    ReservationServiceItemComponent.prototype.onVehicleFocus = function ($event) {
        this.filteredVehicles = [];
        this.vehicleTextChange.emit({
            serviceItemId: this.serviceItemId,
            text: this.allocationForm.get('vehicle').value,
            serviceItem: this.summaryContent
        });
    };
    ReservationServiceItemComponent.prototype.onVehicleJobNoFocus = function ($event) {
        this.vehicleJobNoChange.emit({
            serviceItemId: this.serviceItemId,
            text: this.allocationForm.get('vehicleJobNo').value,
            serviceItem: this.summaryContent
        });
    };
    ReservationServiceItemComponent.prototype.onDriverFocus = function ($event) {
        this.filteredDrivers = [];
        this.driverTextChange.emit({
            serviceItemId: this.serviceItemId,
            text: this.allocationForm.get('driver').value,
            serviceItem: this.summaryContent
        });
    };
    ReservationServiceItemComponent.prototype.onDriverJobNoFocus = function ($event) {
        this.driverJobNoChange.emit({
            serviceItemId: this.serviceItemId,
            text: this.allocationForm.get('driverJobNo').value,
            serviceItem: this.summaryContent
        });
    };
    ReservationServiceItemComponent.prototype.getEstimatedCost = function () {
        this.estimatedCost = 0;
        if (this.serviceItemChangesInSelectedGroup.get(this.serviceItemId).serviceAssignments) {
            if (this.serviceItemChangesInSelectedGroup.get(this.serviceItemId).serviceAssignments
                .get(ResourceType.vehicle)) {
                this.estimatedCost = this.serviceItemChangesInSelectedGroup.get(this.serviceItemId)
                    .serviceAssignments.get(ResourceType.vehicle).estimatedCost;
            }
            if (this.serviceItemChangesInSelectedGroup.get(this.serviceItemId).serviceAssignments
                .get(ResourceType.driver)) {
                this.estimatedCost += this.serviceItemChangesInSelectedGroup.get(this.serviceItemId)
                    .serviceAssignments.get(ResourceType.driver).estimatedCost;
            }
        }
        return this.estimatedCost ? this.estimatedCost.toFixed(2) : this.defaultCost.toFixed(2);
        // if(this.isFormCreated){ this.cd.detectChanges();}
    };
    ReservationServiceItemComponent.prototype.getCost = function () {
        this.jobCost = 0;
        if (this.serviceItemChangesInSelectedGroup.get(this.serviceItemId).serviceAssignments) {
            var driverCost = this.serviceItemChangesInSelectedGroup.get(this.serviceItemId).driverCost ?
                this.serviceItemChangesInSelectedGroup.get(this.serviceItemId).driverCost :
                0;
            var vehicleCost = this.serviceItemChangesInSelectedGroup.get(this.serviceItemId).vehicleCost ?
                this.serviceItemChangesInSelectedGroup.get(this.serviceItemId).vehicleCost :
                0;
            this.jobCost = driverCost + vehicleCost;
            // if (this.serviceItemChangesInSelectedGroup.get(this.serviceItemId).serviceAssignments
            //         .get(ResourceType.vehicle)) {
            //     this.jobCost = this.serviceItemChangesInSelectedGroup.get(this.serviceItemId).serviceAssignments
            //                        .get(ResourceType.vehicle).jobCost;
            // }
            // if (this.serviceItemChangesInSelectedGroup.get(this.serviceItemId).serviceAssignments
            //         .get(ResourceType.driver)) {
            //     this.jobCost += this.serviceItemChangesInSelectedGroup.get(this.serviceItemId).serviceAssignments
            //                         .get(ResourceType.driver).jobCost;
            // }
        }
        return this.jobCost ? this.jobCost.toFixed(2) : this.defaultCost.toFixed(2);
        // if(this.isFormCreated){ this.cd.detectChanges();}
    };
    ReservationServiceItemComponent.prototype.onChangeCheckBox = function ($event) {
        this.checkChanged.emit($event.checked);
    };
    ReservationServiceItemComponent.prototype.onMoreClick = function (event) {
        this.moreClick.emit(event);
    };
    ReservationServiceItemComponent.prototype.onLeadPassengerSelect = function ($event) {
        this.leadPassengerClick.emit(event);
    };
    ReservationServiceItemComponent.prototype.onSplitSelect = function ($event) {
        this.splitClick.emit({ summary: this.summaryContent, isSaved: this.isSavedChild });
    };
    ReservationServiceItemComponent.prototype.isDisableVehicleSupplierClear = function () {
        var disable = false;
        if (this.serviceItemChangesInSelectedGroup.get(this.serviceItemId)) {
            if (this.serviceItemChangesInSelectedGroup.get(this.serviceItemId).serviceAssignments &&
                (this.serviceItemChangesInSelectedGroup.get(this.serviceItemId).serviceAssignments
                    .get(ResourceType.vehicle))) {
                disable = true;
            }
        }
        return disable;
    };
    ReservationServiceItemComponent.prototype.isDisableDriverSupplierClear = function () {
        var disable = false;
        if (this.serviceItemChangesInSelectedGroup.get(this.serviceItemId)) {
            if (this.serviceItemChangesInSelectedGroup.get(this.serviceItemId).serviceAssignments &&
                (this.serviceItemChangesInSelectedGroup.get(this.serviceItemId).serviceAssignments
                    .get(ResourceType.driver))) {
                disable = true;
            }
        }
        return disable;
    };
    ReservationServiceItemComponent.prototype.isRestrictedEdit = function () {
        return this.isRestricted(this.serviceItemChangesInSelectedGroup.get(this.serviceItemId));
    };
    ReservationServiceItemComponent.prototype.isRestrictedEditFields = function (event) {
        var restrict = false;
        var value = this.serviceItemChangesInSelectedGroup.get(this.serviceItemId);
        if (value) {
            restrict = this.isCancelled || this.isUpdated;
            if (!restrict) {
                if ((this.vehicleAssignmentStatus === '' && this.driverAssignmentStatus === '')
                    || (this.vehicleAssignmentStatus === null && this.driverAssignmentStatus === null)
                    || (this.vehicleAssignmentStatus === AssignmentStatus.PENDING && this.driverAssignmentStatus === AssignmentStatus.PENDING)
                    || (this.vehicleAssignmentStatus === AssignmentStatus.SUPPLIER_REJECTED && this.driverAssignmentStatus === AssignmentStatus.SUPPLIER_REJECTED)
                    || (this.vehicleAssignmentStatus === AssignmentStatus.BOOKING_UPDATE && this.driverAssignmentStatus === AssignmentStatus.BOOKING_UPDATE)
                    || (this.vehicleAssignmentStatus === AssignmentStatus.SUPPLIER_CONFIRMED && this.driverAssignmentStatus === AssignmentStatus.SUPPLIER_CONFIRMED)
                    || (this.vehicleAssignmentStatus === AssignmentStatus.DMC_CONFIRM && this.driverAssignmentStatus === AssignmentStatus.DMC_CONFIRM)) {
                    restrict = false;
                }
                else {
                    restrict = true;
                }
            }
        }
        return restrict;
    };
    ReservationServiceItemComponent.prototype.isRestricted = function (value) {
        var e_1, _a;
        var restrict = false;
        if (value) {
            restrict = this.isCancelled || this.isUpdated;
            if (!restrict) {
                var serviceAssignmentList_2 = [];
                this.serviceItemChangesInSelectedGroup.get(value.serviceItemId).serviceAssignments
                    .forEach(function (value) { return serviceAssignmentList_2.push(value); });
                if (value.serviceAssignments) {
                    try {
                        for (var serviceAssignmentList_1 = tslib_1.__values(serviceAssignmentList_2), serviceAssignmentList_1_1 = serviceAssignmentList_1.next(); !serviceAssignmentList_1_1.done; serviceAssignmentList_1_1 = serviceAssignmentList_1.next()) {
                            var data = serviceAssignmentList_1_1.value;
                            if (data && (data.assignStatus !== AssignmentStatus.PENDING && data.assignStatus !==
                                AssignmentStatus.SUPPLIER_REJECTED && data.assignStatus !==
                                AssignmentStatus.BOOKING_UPDATE && data.assignStatus !==
                                AssignmentStatus.REQUEST_EXPIRED)) {
                                restrict = true;
                                break;
                            }
                        }
                    }
                    catch (e_1_1) { e_1 = { error: e_1_1 }; }
                    finally {
                        try {
                            if (serviceAssignmentList_1_1 && !serviceAssignmentList_1_1.done && (_a = serviceAssignmentList_1.return)) _a.call(serviceAssignmentList_1);
                        }
                        finally { if (e_1) throw e_1.error; }
                    }
                }
            }
        }
        return restrict;
    };
    ReservationServiceItemComponent.prototype.onViewMoreClick = function (serviceItem) {
        this.viewMoreFocus.emit(serviceItem.data.value);
    };
    ReservationServiceItemComponent.prototype.isDisabledCheckbox = function () {
        var disable = false;
        if (this.vehicleAssignmentStatus === AssignmentStatus.STARTED
            || this.driverAssignmentStatus === AssignmentStatus.STARTED
            || this.vehicleAssignmentStatus === AssignmentStatus.COMPLETED
            || this.driverAssignmentStatus === AssignmentStatus.COMPLETED
            || this.vehicleAssignmentStatus === AssignmentStatus.NO_SHOW
            || this.driverAssignmentStatus === AssignmentStatus.NO_SHOW) {
            disable = true;
        }
        if (this.isCancelled || this.isUpdated) {
            disable = true;
        }
        return disable;
    };
    ReservationServiceItemComponent.prototype.onViewHistoryClick = function (summaryContent) {
        this.historyClick.emit(summaryContent);
    };
    ReservationServiceItemComponent.prototype.historyAvailable = function (summaryContent) {
        return this.rawData && this.rawData.serviceItemHistories && this.rawData.serviceItemHistories.length > 0;
    };
    return ReservationServiceItemComponent;
}());
export { ReservationServiceItemComponent };
export function StringValidator(control) {
    if (control.value !== '' && (!control.value.name || (control.value.name === 'NOT SPECIFIED'))) {
        return { invalidString: true };
    }
    else {
        return null;
    }
}
