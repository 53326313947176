import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CurrencyFormat } from '@tc-core/model/it/codegen/ui/framework/currency-format';
import { SummaryCardData } from '@tc-core/model/it/codegen/ui/framework/summary-card-data';
import { Supplier } from '../../../models/supplier/supplier';
import {UserManagementService} from '../../../services/user-management/user-management.service';

@Component({
  selector: 'tc-supplier-detail-view',
  templateUrl: './supplier-detail-view.component.html'
})
export class SupplierDetailViewComponent implements OnInit {

    @Input() summaryCardData: SummaryCardData;
    @Input() supplier: Supplier;
    @Input() controlMap: Map<any, string>;
    @Input() footerDataMap: Map<number, object>;
    @Input() resourceTypes: any[];
    @Input() trsModes = [];
    @Input() vehicleModels = [];
    @Input() costingTypes = [];
    @Input() profileTypes = [];
    @Input() currencyFormat: CurrencyFormat;

    @Output() headerClick: EventEmitter<any> = new EventEmitter();
    @Output() editClick: EventEmitter<any> = new EventEmitter();
    @Output() expandEventEmitter: EventEmitter<boolean> = new EventEmitter();

    constructor(
        public userManagementService: UserManagementService
    ) { }

    ngOnInit() {
        if (this.summaryCardData) {
            this.expandEventEmitter.emit(true);
        }

    }

    onHeaderClick(event) {
        this.headerClick.emit(event);
    }

    onEditClick(event) {
        this.editClick.emit(event);
    }

    // getSubHeaderText():string{
    //     if(this.supplier){
    //         return 'ID - ' + this.supplier.summary.id ;
    //     }
    //     else{
    //         return '';
    //     }
    // }
}
