import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { AgGridModule } from 'ag-grid-angular';
import { AgGridCustomRenderersModule } from '../../widgets/framework/ag-grid-custom-components/renderers/ag-grid-custom-renderers.module';
import { GridToolTipComponent } from '../../widgets/framework/ag-grid-custom-components/renderers/tooltip/grid-tool-tip.component';
import { BookingNoteGroupComponent } from './booking-note-group/booking-note-group.component';
import { NotesEditorComponent } from './notes-editor.component';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
    declarations: [
        NotesEditorComponent,
        BookingNoteGroupComponent
    ],
    imports: [
        CommonModule,
        MatExpansionModule,
        MatCheckboxModule,
        MatIconModule,
        MatButtonModule,
        AgGridCustomRenderersModule,
        AgGridModule.forRoot([GridToolTipComponent]),
        TranslateModule.forChild()
    ],
    exports: [
        NotesEditorComponent,
        BookingNoteGroupComponent
    ],
    entryComponents: [
        NotesEditorComponent
    ]
})
export class NotesEditorModule {}
