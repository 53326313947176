import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray } from '@angular/forms';
import { SupplierAttributes } from '../../../models/common/supplier-attributes';
import { ProfileManagementService } from '../../../services/backend-consumers/supplier-service/profile-management.service';
import { DataKey, DataStoreService } from '../../../services/util/framework/data-store.service';


@Component({
    selector: 'tc-manage-supplier-attributes-wrapper',
    templateUrl: './manage-supplier-attributes-wrapper.component.html'
})
export class ManageSupplierAttributesWrapperComponent implements OnInit {

    @Input() supplierAttributes: SupplierAttributes[] = [];

    supplierAttributesMasterData: SupplierAttributes[] = [];
    changedSupplierAttributes: SupplierAttributes[] = [];

    constructor(
        private dataStore: DataStoreService,
        private profileManagementService: ProfileManagementService
    ) { }

    ngOnInit() {
        this.profileManagementService.retrieveSupplierAttributes().subscribe(result => {
            this.supplierAttributesMasterData = result['data'];

            this.addNewSupplierAttributesToList();
        });
    }

    private addNewSupplierAttributesToList() {
        let supplierAttributesNotInList: SupplierAttributes[] = [];
        this.supplierAttributesMasterData.forEach(value => {
            let attributeIn = this.supplierAttributes
                                  .filter(
                                      valueInList => valueInList.attributeCode === value.attributeCode);
            if (attributeIn.length < 1) {
                supplierAttributesNotInList.push(value);
            }
        });

        // supplierAttributes list with new attributes included
        this.supplierAttributes = this.supplierAttributes.concat(supplierAttributesNotInList);
    }

    public onSupplierAttributesChanged($event: any) {
        this.changedSupplierAttributes = [];
        for (let attribute of this.supplierAttributes) {
            if (attribute.attributeValue) {
                this.changedSupplierAttributes.push(attribute);}
        }
        this.dataStore.set(DataKey.supplierAttributes, this.changedSupplierAttributes, true);
    }

    public closeModalClick(){
        this.dataStore.set(DataKey.modalClose, true);
    }
}



