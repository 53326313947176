<div class=" container-fluid">
    <div class="" *ngIf="selectedServiceGroupNotes">
        <div class="">
            <ng-container *ngFor="let serviceNote of selectedServiceGroupNotes; let i = index;">
                <div
                        tcExpansionPanel
                        tabindex="-1"
                        [enabled]="true"
                        (collapse)="expandedPanelIndex = -1">

                    <!-- Content card Strip -->
                    <div id="clientSumExp_{{i}}">

                        <tc-reservation-service-group-note
                                [serviceNote]="serviceNote"
                                [disableGroupNoteEdit]="disableGroupNoteEdit"
                                (deleteNote)="onDelete($event)"
                                (saveNote)="onNoteSave($event)"
                                (isEditClicked)="onEdit($event)">
                        </tc-reservation-service-group-note>

                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>
