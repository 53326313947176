import * as tslib_1 from "tslib";
import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { GenericSearchCriteria } from '../../models/criteria/generic-search-criteria';
import { Subscription } from 'rxjs';
import { ChipInputComponent } from '@tc/chip-input';
import { ActivatedRoute } from '@angular/router';
import { ConfigLoader } from '@tc-core/util/framework/config-loader.service';
import { UserJourneyManager } from '@tc-core/util/framework/user-journey-manager.service';
import { DataKey, DataStoreService } from '../../services/util/framework/data-store.service';
import { ActionHandlerService } from '../../services/util/framework/action-handler-service.service';
import { EventManager } from '@tc-core/util/framework/event-manager.service';
import { ChipHandlerService } from '../../services/helpers/chip-handler.service';
import { DMCQueryParamsService } from '../../services/util/framework/dmc-query-params.service';
import { CommonHelper } from '@tc-core/util/helpers/common-helper.service';
import { SpinnerService } from '@tc-core/util/ui/spinner.service';
import { RootService } from '../../services/util/core-services/root.service';
import { TCO } from '../../constants';
import { TC } from '@tc-core/util/constants';
import { SortDirection } from '../../models/helper/sort-direction';
import { TcApiError } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-api-error';
import { TcHttpError } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-http-error';
import { VehicleSearchCriteria } from '../../models/criteria/vehicle-search-criteria';
import { TransportResourceService } from '../../services/backend-consumers/supplier-service/transport-resource.service';
import { TrsReservationManagementService } from '../../services/backend-consumers/reservation-management-services/trs-reservation-management.service';
import { ResourceType } from '../../models/reservation/assignment';
import { VehicleSearchType } from '../vehicle-search-criteria/vehicle-search-criteria.component';
export var RoomResourceSearchType;
(function (RoomResourceSearchType) {
    RoomResourceSearchType[RoomResourceSearchType["Normal"] = 0] = "Normal";
    RoomResourceSearchType[RoomResourceSearchType["ResourceAssign"] = 1] = "ResourceAssign";
    RoomResourceSearchType[RoomResourceSearchType["GenResourceAssign"] = 2] = "GenResourceAssign";
})(RoomResourceSearchType || (RoomResourceSearchType = {}));
var RoomSearchCriteriaComponent = /** @class */ (function () {
    function RoomSearchCriteriaComponent(activatedRoute, configLoader, userJourneyManager, dataStoreService, actionHandlerService, transportResourceService, trsReservationManagementService, em, chipHandler, queryParamService, commonHelper, spinnerService, rootService) {
        this.activatedRoute = activatedRoute;
        this.configLoader = configLoader;
        this.userJourneyManager = userJourneyManager;
        this.dataStoreService = dataStoreService;
        this.actionHandlerService = actionHandlerService;
        this.transportResourceService = transportResourceService;
        this.trsReservationManagementService = trsReservationManagementService;
        this.em = em;
        this.chipHandler = chipHandler;
        this.queryParamService = queryParamService;
        this.commonHelper = commonHelper;
        this.spinnerService = spinnerService;
        this.rootService = rootService;
        this.isInsideForm = false;
        this.isSearchBarStyleNotAvailable = false;
        this.searchType = VehicleSearchType.Normal;
        this.placeHolderText = 'Search Vehicles';
        this.searchTrigger = new EventEmitter();
        this.searchCriteria = new EventEmitter();
        this.journey = null;
        this.isChipsInit = false;
        this.availableChips = [];
        this.persistentChipsArr = [];
        this.persistentQParams = [];
        this.persistentQParamMap = new Map();
        this.relatedChipsObsv = new Subscription();
        this.isDataLoaded = true;
        this.isTriggerSearch = true;
        this.displayChipsArr = [];
        this.isFocus = false;
        this.isChipListDisplay = false;
        this.selectedChipsObsv = new Subscription();
        this.selectedFieldsObsv = new Subscription();
        this.chipValueUpdateSub = new Subscription();
        this.criteriaUpdateSubscription = new Subscription();
        this.deletedChipObsv = new Subscription();
        this.selectedSupplierObsv = new Subscription();
        this.selectedChips = [];
        this.chipHandlerSubject = new Subscription();
    }
    RoomSearchCriteriaComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.chipInputService = this.vehicleSearchChipInput.criteriaInputService;
        if (this.searchType === VehicleSearchType.ResourceAssign) {
            this.tPagination = this.configLoader.configurations.get(TCO.CONF.CONF_PAGINATION_CONFIG)['RESOURCE_ALLOCATION_SEARCH'];
            this.selectedSupplierObserver();
            this.selectedSupplierFromDriverObserver();
        }
        else if (this.searchType === VehicleSearchType.Normal) {
            this.tPagination = this.configLoader.configurations.get(TCO.CONF.CONF_PAGINATION_CONFIG)['VEHICLE_SEARCH'];
        }
        //clear data store
        this.dataStoreService.set(DataKey.vehicleSearchResultsService, null, true);
        this.dataStoreService.set(DataKey.resourceAssignVehicleSearchResults, null, true);
        this.handleQuickActions();
        this.userJourneyManager.journey.subscribe(function (journey) {
            if (journey && JSON.stringify(journey) !== JSON.stringify({})) {
                _this.journey = journey.id;
            }
        });
        // resetting the view
        this.routerChangeSubscription = this.userJourneyManager.routeChanges.subscribe(function (e) {
            if (e) {
                _this.chipInputService.fullReset(); // fully reset chips
                _this.chipHandler.chipsArr = []; // reset chips array loaded from config
                // check journey begins from quick book
                if (!_this.isInsideForm) {
                    _this.getQueryParams();
                    _this.initSearchCriteriaChips();
                }
                console.log('displayChipsArr', _this.displayChipsArr);
                _this.resetQueryParams();
            }
        });
        if (!this.isInsideForm) {
            this.getQueryParams();
            if (!this.isChipsInit) {
                this.initSearchCriteriaChips(); // load everything from start
            }
            if (this.searchType === VehicleSearchType.Normal) {
                this.vehicleSearchCriteria = new VehicleSearchCriteria();
                this.vehicleSearchCriteria.activeStatus = true;
                this.vehicleSearchCriteria.resourceType = ResourceType.vehicle;
            }
            else if (this.searchType === VehicleSearchType.ResourceAssign) {
                this.vehicleSearchCriteria.resourceId = this.vehicleSearchCriteria.resourceId === -1 ?
                    null :
                    this.vehicleSearchCriteria.resourceId;
            }
        }
        this.getVehicleSearchObserver();
        this.errorObserver();
        this.selectedChipsUpdate();
        this.handleInterRelatedChips();
        this.notifyChipDelete();
        this.shortCutObserver();
    };
    RoomSearchCriteriaComponent.prototype.selectedSupplierObserver = function () {
        var _this = this;
        this.selectedSupplierObsv = this.dataStoreService.get(DataKey.assignedSupplier).subscribe(function (value) {
            if (value) {
                _this.setCriteria(value);
            }
        });
    };
    RoomSearchCriteriaComponent.prototype.selectedSupplierFromDriverObserver = function () {
        var _this = this;
        this.selectedSupplierObsv = this.dataStoreService.get(DataKey.assignedSupplierFromDriver).subscribe(function (value) {
            if (value) {
                _this.setCriteria(value);
            }
        });
    };
    RoomSearchCriteriaComponent.prototype.setCriteria = function (value) {
        var selected = value;
        if (selected.code !== this.vehicleSearchCriteria.supplierCode) {
            this.vehicleSearchCriteria.supplierCode = selected.code;
            this.vehicleSearchCriteria.serviceDate = selected.serviceDate ? selected.serviceDate : this.vehicleSearchCriteria.serviceDate;
            this.vehicleSearchCriteria.route = selected.route;
            this.vehicleSearchCriteria.transferMode = selected.transferMode;
            this.vehicleSearchCriteria.activeStatus = true;
            this.vehicleSearchCriteria.resourceType = ResourceType.vehicle;
            this.getQueryParams();
            this.initSearchCriteriaChips();
        }
    };
    RoomSearchCriteriaComponent.prototype.getQueryParams = function () {
        var queryParams = [];
        queryParams = this.queryParamService.getQueryParamsArray(new GenericSearchCriteria());
        if (this.searchType !== VehicleSearchType.ResourceAssign) {
            this.vehicleSearchCriteria = this.rootService.loadDataFromLocalStorage(queryParams, new VehicleSearchCriteria(), TCO.AppData.VEHICLE_SEARCH_CRITERIA);
        }
        this.vehicleSearchCriteria.resourceType = ResourceType.vehicle;
        this.vehicleSearchCriteria.activeStatus = true;
        // get persisting query params
        if (this.vehicleSearchCriteria) {
            this.persistentQParams = [];
            this.persistentQParams = this.queryParamService.getNotNullQueryParamsArray(this.vehicleSearchCriteria);
            // remove resourceId from persistent chips when it is -1
            if (this.persistentQParams && this.persistentQParams.length > 0) {
                if (this.vehicleSearchCriteria.resourceId === -1) {
                    for (var i = 0; i < this.persistentQParams.length; i++) {
                        if (this.persistentQParams[i] === 'resourceId') {
                            this.persistentQParams.splice(i, 1);
                            this.vehicleSearchCriteria.resourceId = null;
                        }
                    }
                }
                /*else {
                    for (let i = 0; i < this.persistentQParams.length; i++) {
                        if (this.persistentQParams[i] === 'resourceType') {
                            this.persistentQParams.splice(i, 1);
                            this.vehicleSearchCriteria.resourceType = '';
                        }
                    }
                }*/
            }
        }
    };
    RoomSearchCriteriaComponent.prototype.shortCutObserver = function () {
        var _this = this;
        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'FOCUS_SEARCH_BAR').subscribe(function (e) {
            _this.isFocus = true;
        });
        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'CLOSE_POPUPS').subscribe(function (e) {
            _this.isFocus = false;
        });
    };
    RoomSearchCriteriaComponent.prototype.onFocus = function ($event) {
        this.isFocus = $event;
    };
    RoomSearchCriteriaComponent.prototype.initSearchCriteriaChips = function () {
        var _this = this;
        this.isChipsInit = true;
        this.chipHandler.chipsArr = [];
        // read chip config
        var criteriaChipConfigs = null;
        if (!this.isInsideForm) {
            var chipConfigs = this.configLoader.configurations.get(TCO.CONF.CONF_VEHICLE_SEARCH_CRITERIA_CHIP);
            criteriaChipConfigs = this.commonHelper.recreateJsonObject(chipConfigs);
        }
        else {
            var chipConfigs = this.configLoader.configurations.get(this.searchCriteriaChipConfig);
            criteriaChipConfigs = this.commonHelper.recreateJsonObject(chipConfigs);
        }
        if (criteriaChipConfigs && criteriaChipConfigs.chips) {
            criteriaChipConfigs.chips.forEach(function (chip) {
                chip = _this.chipHandler.preProcessChips(chip, criteriaChipConfigs.permissionKeys.keys);
            });
        }
        this.chipHandler.chipsSubject.subscribe(function (data) {
            if (data && JSON.stringify(data) !== JSON.stringify([]) && data.length === criteriaChipConfigs.chips.length) {
                _this.availableChips = data; //new json object here;
                _this.chipHandler.chipsSubject.next(null);
                if (_this.chipHandlerSubject) {
                    _this.chipHandlerSubject.unsubscribe();
                }
                // set values to display chips
                _this.displayChipsArr = [];
                _this.displayChipsArr = _this.availableChips;
                _this.dataStoreService.set(DataKey.chips, _this.availableChips, true);
                _this.availableChips.forEach(function (chip) {
                    if (chip['criteriaId'] === 'RESOURCE_TYPE') {
                        chip['optionParams']['isInitialPresent'] = true;
                        var choice = chip['optionParams']['options']
                            .filter(function (option) { return option.code === _this.vehicleSearchCriteria.resourceType; });
                        chip['optionParams']['data'] = choice;
                        chip['optionParams']['isMandatory'] = true;
                        chip['optionParams']['disabled'] = true;
                    }
                    if (_this.searchType === VehicleSearchType.ResourceAssign && _this.vehicleSearchCriteria) {
                        /*if (chip['criteriaId'] === 'SERVICE_DATE' && this.vehicleSearchCriteria.serviceDate) {

                            chip['optionParams']['isInitialPresent'] = true;
                            chip['optionParams']['data'] = [moment(this.vehicleSearchCriteria.serviceDate).toDate()];
                        }*/
                        if (chip['criteriaId'] === 'TRANSFER_MODE') {
                            chip['optionParams']['isInitialPresent'] = true;
                            var choice = chip['optionParams']['options']
                                .filter(function (option) { return option.code === _this.vehicleSearchCriteria.transferMode; });
                            chip['optionParams']['data'] = choice;
                            chip['optionParams']['isMandatory'] = true;
                            if (_this.vehicleSearchCriteria.isPrivate) {
                                chip['optionParams']['disabled'] = true;
                            }
                        }
                        if (chip['criteriaId'] === 'ROUTE') {
                            chip['optionParams']['isInitialPresent'] = true;
                            var choice = chip['optionParams']['options']
                                .filter(function (option) { return option.code === _this.vehicleSearchCriteria.route; });
                            chip['optionParams']['data'] = choice;
                            chip['optionParams']['isMandatory'] = true;
                        }
                        if (chip['criteriaId'] === 'SUPPLIER' && _this.vehicleSearchCriteria.supplierId) {
                            chip['optionParams']['isInitialPresent'] = true;
                            var choice = chip['optionParams']['options']
                                .filter(function (option) { return option.supplierId === _this.vehicleSearchCriteria.supplierId; });
                            chip['optionParams']['data'] = choice;
                        }
                    }
                });
                _this.chipInputService.init(_this.vehicleSearchCriteria, _this.availableChips, false, true);
                _this.isChipsInit = true;
                // implement chip persistance
                if (_this.persistentQParams && _this.persistentQParams.length > 0) {
                    // create a queryParamMap
                    for (var i = 0; i < _this.persistentQParams.length; i++) {
                        var qParam = _this.persistentQParams[i];
                        var qParamValue = _this.vehicleSearchCriteria[qParam];
                        if (qParam && qParamValue) {
                            _this.persistentQParamMap.set(qParam, qParamValue);
                        }
                    }
                    _this.persistentChipsArr = [];
                    // fill persistent chips
                    _this.availableChips.forEach(function (chip) {
                        var persistChip = Object.assign({}, chip);
                        persistChip.optionParams.data = [];
                        var isPersist = false;
                        if (chip.dataParams && chip.dataParams.length > 0) {
                            chip.dataParams.forEach(function (dataParam) {
                                if (_this.persistentQParamMap.has(dataParam.paramValue)) {
                                    isPersist = true;
                                    var dataObj = {
                                        key: dataParam.paramValue,
                                        value: _this.persistentQParamMap.get(dataParam.paramValue)
                                    };
                                    persistChip.optionParams.data.push(dataObj);
                                }
                            });
                        }
                        // push persist chips to an array
                        if (isPersist) {
                            _this.persistentChipsArr.push(persistChip);
                        }
                    });
                    // persist chips
                    if (_this.persistentChipsArr && _this.persistentChipsArr.length > 0) {
                        /*const uniqueChipList = this.persistentChipsArr.filter((e, i) => {
                            return this.persistentChipsArr.findIndex((x) => {
                                return x.criteriaId === e.criteriaId;
                            }) === i;
                        });*/
                        _this.chipInputService.persistChips(_this.persistentChipsArr);
                        // trigger search when chips persisting
                        _this.isDataLoaded = false;
                        // this.isTriggerSearch = false;
                        _this.onVehicleSearch(_this.vehicleSearchCriteria);
                    }
                }
                _this.chipInputService.criteriaUpdate.subscribe(function (_data) {
                    _this.vehicleSearchCriteria = _data;
                });
            }
        });
    };
    RoomSearchCriteriaComponent.prototype.onVehicleSearch = function ($event) {
        var _this = this;
        this.isDataLoaded = false;
        this.vehicleSearchCriteria = $event;
        this.vehicleSearchCriteria.sortDirection = SortDirection.ASC;
        if (this.tPagination && this.tPagination['initialFetchingPages'] &&
            this.tPagination['defaultPageResultCount']) {
            this.vehicleSearchCriteria.size = this.tPagination['initialFetchingPages'] *
                this.tPagination['defaultPageResultCount'];
            this.vehicleSearchCriteria.start = 0;
        }
        this.userJourneyManager.canProceed.next(false);
        if (JSON.stringify(this.vehicleSearchCriteria) !== JSON.stringify({})) {
            this.dataStoreService.set(DataKey.vehicleSearchCriteria, this.vehicleSearchCriteria, true);
            if (this.searchType === VehicleSearchType.ResourceAssign) {
                this.dataStoreService.set(DataKey.resourceAssignVehicleSearchResults, null, true);
                this.trsReservationManagementService.searchRecommendedVehiclesWithDataKey(this.vehicleSearchCriteria);
            }
            else {
                this.dataStoreService.set(DataKey.vehicleSearchResults, null, true);
                this.transportResourceService.searchVehicleSummaries(this.vehicleSearchCriteria);
                // set criteria parameters to url
                var queryParams = [];
                queryParams = this.queryParamService.getQueryParamsArray(this.vehicleSearchCriteria);
                this.rootService.setDataToLocalStorage(TCO.AppData.VEHICLE_SEARCH_CRITERIA, this.vehicleSearchCriteria, true, queryParams, true);
            }
            // this.spinnerService.show();
            this.isTriggerSearch = false;
            this.isDataLoaded = false;
            this.searchTrigger.emit(true);
        }
        else {
            setTimeout(function () {
                _this.isDataLoaded = true;
                _this.isTriggerSearch = true;
            }, 0);
        }
    };
    RoomSearchCriteriaComponent.prototype.getUpdatedFieldsDataObject = function (chip, criteria) {
        var dataObjArr = [];
        if (chip && chip.dataParams && chip.dataParams.length > 0) {
            chip.dataParams.forEach(function (dataParam) {
                var dataObj = {
                    key: '',
                    value: ''
                };
                dataObj.key = dataParam.paramValue;
                dataObj.value = criteria[dataParam.paramValue];
                dataObjArr.push(dataObj);
            });
        }
        return dataObjArr;
    };
    RoomSearchCriteriaComponent.prototype.getVehicleSearchObserver = function () {
        var _this = this;
        if (this.searchType === VehicleSearchType.ResourceAssign) {
            var x = this.dataStoreService.get(DataKey.resourceAssignVehicleSearchResults).subscribe(function (data) {
                if (_this.commonHelper.dataValidity(data)) {
                    setTimeout(function () {
                        _this.isDataLoaded = true;
                        _this.isTriggerSearch = true;
                    }, 0);
                }
                else if (_this.commonHelper.isEmptyData(data)) {
                    _this.isDataLoaded = true;
                }
                else if (data instanceof TcApiError) {
                    _this.isDataLoaded = true;
                }
                else if (data instanceof TcHttpError) {
                    _this.isDataLoaded = true;
                }
            }, function (error) {
                console.log(error);
            });
        }
        else {
            var x = this.dataStoreService.get(DataKey.vehicleSearchResultsService).subscribe(function (data) {
                if (_this.commonHelper.dataValidity(data)) {
                    setTimeout(function () {
                        _this.isDataLoaded = true;
                        _this.isTriggerSearch = false;
                    }, 0);
                }
                else if (_this.commonHelper.isEmptyData(data)) {
                    _this.isDataLoaded = true;
                }
                else if (data instanceof TcApiError) {
                    _this.isDataLoaded = true;
                }
                else if (data instanceof TcHttpError) {
                    _this.isDataLoaded = true;
                }
            }, function (error) {
                console.log(error);
            });
        }
    };
    RoomSearchCriteriaComponent.prototype.errorObserver = function () {
        var _this = this;
        this.dataStoreService.get(DataKey.error).subscribe(function (data) {
            if (data && JSON.stringify(data) !== JSON.stringify({})) {
                setTimeout(function () {
                    _this.isDataLoaded = true;
                }, 0);
                _this.dataStoreService.set(DataKey.error, null);
            }
        });
    };
    RoomSearchCriteriaComponent.prototype.resetQueryParams = function () {
        var vehicleQueryParams = [];
        vehicleQueryParams = this.queryParamService.getQueryParamsArray(new VehicleSearchCriteria());
        var vehicleSearchCriteria = this.rootService.loadDataFromLocalStorage([], new VehicleSearchCriteria(), TCO.AppData.VEHICLE_SEARCH_CRITERIA);
        // set criteria parameters to url
        var queryParams = [];
        queryParams = this.queryParamService.getQueryParamsArray(vehicleSearchCriteria);
        this.rootService.setDataToLocalStorage(TCO.AppData.VEHICLE_SEARCH_CRITERIA, this.vehicleSearchCriteria, true, queryParams, true);
    };
    RoomSearchCriteriaComponent.prototype.onCancel = function ($event) {
        console.log('cancelled');
    };
    RoomSearchCriteriaComponent.prototype.onChipListDisplay = function ($event) {
        this.dataStoreService.set(DataKey.chipListDisplay, null, true);
        this.isChipListDisplay = $event;
        this.dataStoreService.set(DataKey.chipListDisplay, this.isChipListDisplay, true);
    };
    RoomSearchCriteriaComponent.prototype.handleQuickActions = function () {
        var _this = this;
        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'TRIGGER_SEARCH_CRITERIA').subscribe(function (e) {
            _this.triggerSearch(e.data);
        });
    };
    RoomSearchCriteriaComponent.prototype.triggerSearch = function (data) {
        this.vehicleSearchCriteria = new VehicleSearchCriteria();
        // todo criteria
        this.actionHandlerService.fillCriteriaByActionData(data, this.vehicleSearchCriteria);
        var requiredChips = this.chipHandler.getChipsFromCriteria(this.vehicleSearchCriteria, TCO.CONF.CONF_VEHICLE_SEARCH_CRITERIA_CHIP, true);
        // requiredChips = this.chipHandler.validateAndFilterChips(requiredChips, ['bookingRefId|>|0']);
        this.chipInputService.hardReset();
        this.chipInputService.persistChips(requiredChips, true);
        this.onVehicleSearch(this.vehicleSearchCriteria);
    };
    RoomSearchCriteriaComponent.prototype.observeChipValueUpdate = function () {
    };
    RoomSearchCriteriaComponent.prototype.handleInterRelatedChips = function () {
        var _this = this;
        this.relatedChipsObsv = this.chipInputService.relatedChipsSubject.subscribe(function (data) {
            var toggleChips = [];
            if (data && data.chip && JSON.stringify(data) !== JSON.stringify({})) {
                _this.modifiedChip = data.chip;
                // TODO: refine chip input service: inter related chips
                if (data.chip.type === 'CALENDAR') {
                    _this.chipHandler.handleInterRelatedChips(data.chip, data.date);
                }
                else {
                    _this.chipHandler.handleInterRelatedChips(data.chip, data.options.value[0]);
                }
                if (data.chip.optionParams.toggleChips && data.chip.optionParams.toggleChips.length > 0) {
                    toggleChips = data.chip.optionParams.toggleChips;
                    if (toggleChips && data.options && data.options.value[0].value) {
                        _this.chipInputService.selectedChipsBasedOnStateChange(toggleChips);
                    }
                    else {
                        _this.chipInputService.deleteChipsBasedOnStateChange(toggleChips);
                    }
                }
            }
        });
    };
    RoomSearchCriteriaComponent.prototype.resetResourceAssignChips = function () {
        this.availableChips.forEach(function (chip) {
            if (chip['criteriaId'] === 'SERVICE_DATE') {
                chip['optionParams']['isInitialPresent'] = false;
                chip['optionParams']['data'] = [];
            }
            if (chip['criteriaId'] === 'TRANSFER_MODE') {
                chip['optionParams']['isInitialPresent'] = false;
                chip['optionParams']['data'] = [];
            }
            if (chip['criteriaId'] === 'ROUTE') {
                chip['optionParams']['isInitialPresent'] = false;
                chip['optionParams']['data'] = [];
            }
        });
    };
    RoomSearchCriteriaComponent.prototype.ngOnDestroy = function () {
        this.routerChangeSubscription.unsubscribe();
        this.userJourneyManager.routeChanges.next(false);
        if (this.selectedChipsObsv) {
            this.selectedChipsObsv.unsubscribe();
        }
        if (this.selectedFieldsObsv) {
            this.selectedFieldsObsv.unsubscribe();
        }
        if (this.chipValueUpdateSub) {
            this.chipValueUpdateSub.unsubscribe();
        }
        if (this.criteriaUpdateSubscription) {
            this.criteriaUpdateSubscription.unsubscribe();
        }
        if (this.deletedChipObsv) {
            this.deletedChipObsv.unsubscribe();
        }
        if (this.searchType === VehicleSearchType.ResourceAssign) {
            this.resetResourceAssignChips();
            this.resetQueryParams();
        }
        this.em.removeEvents([
            'TRIGGER_SEARCH_CRITERIA'
        ]);
    };
    RoomSearchCriteriaComponent.prototype.selectedChipsUpdate = function () {
        var _this = this;
        this.criteriaUpdateSubscription = this.chipInputService.selectedChipsSubject.subscribe(function (selectedChips) {
            var e_1, _a;
            _this.selectedChips = selectedChips;
            if (selectedChips && JSON.stringify(selectedChips) !== JSON.stringify([])) {
                try {
                    // toggle chips when selecting BRANCH_OF_CLIENT_ID chip
                    for (var _b = tslib_1.__values(_this.selectedChips), _c = _b.next(); !_c.done; _c = _b.next()) {
                        var selectedChip = _c.value;
                        if (selectedChip.chip.id === 'BRANCH_OF_CLIENT_ID' && selectedChip.chip.optionParams.toggleChips &&
                            selectedChip.chip.optionParams.toggleChips.length > 0) {
                            _this.deleteToggleChips(selectedChip.chip.optionParams.toggleChips);
                            _this.disableChips(selectedChip.chip.optionParams.toggleChips);
                            break;
                        }
                    }
                }
                catch (e_1_1) { e_1 = { error: e_1_1 }; }
                finally {
                    try {
                        if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                    }
                    finally { if (e_1) throw e_1.error; }
                }
            }
        });
    };
    RoomSearchCriteriaComponent.prototype.notifyChipDelete = function () {
        var _this = this;
        this.deletedChipObsv = this.chipInputService.deletedChipsSubject.subscribe(function (deletedChip) {
            if (JSON.stringify(deletedChip) !== JSON.stringify({}) && deletedChip !== null && deletedChip !== undefined) {
                if (deletedChip.id === 'CLIENT_TYPE') {
                    var deleteChips = _this.selectedChips.map(function (chip) { return chip.chip; });
                    _this.deleteSelectedChips(deleteChips);
                    _this.enableChips(_this.availableChips);
                }
                else if (deletedChip.id === 'BRANCH_OF_CLIENT_ID') {
                    if (deletedChip.optionParams.toggleChips && deletedChip.optionParams.toggleChips.length > 0) {
                        _this.enableChips(deletedChip.optionParams.toggleChips);
                    }
                }
            }
        });
    };
    /**
     * Delete toggle chips if already selected
     *
     * @param toggleChips
     */
    RoomSearchCriteriaComponent.prototype.deleteToggleChips = function (toggleChips) {
        var _this = this;
        var deleteChips = [];
        toggleChips.forEach(function (toggleChip) {
            var e_2, _a;
            try {
                for (var _b = tslib_1.__values(_this.selectedChips), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var selectedChip = _c.value;
                    if (selectedChip.chip.id === toggleChip.criteriaId) {
                        deleteChips.push(selectedChip.chip);
                        break;
                    }
                }
            }
            catch (e_2_1) { e_2 = { error: e_2_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_2) throw e_2.error; }
            }
        });
        this.deleteSelectedChips(deleteChips);
    };
    RoomSearchCriteriaComponent.prototype.deleteSelectedChips = function (deleteChips) {
        var _this = this;
        deleteChips.forEach(function (chip) {
            _this.chipInputService.deleteChip(chip, true);
        });
    };
    RoomSearchCriteriaComponent.prototype.enableChips = function (enableChips) {
        var _this = this;
        enableChips.forEach(function (enableChip) {
            _this.chipInputService.modifyChipsStatus(enableChip, false);
        });
    };
    RoomSearchCriteriaComponent.prototype.disableChips = function (disableChips) {
        var _this = this;
        disableChips.forEach(function (disableChip) {
            _this.chipInputService.modifyChipsStatus(disableChip, true);
        });
    };
    return RoomSearchCriteriaComponent;
}());
export { RoomSearchCriteriaComponent };
