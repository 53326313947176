<div>
        <div class="main-titles">
            <div class="col-md-6">{{'Attribute Code' | translate}}</div>
            <div class="col-md-6">{{'Attribute Value' | translate}}</div>
        </div>
        <div class="supplier-attributes-list__container container-fluid">
            <div
                    *ngFor="let supplierAttribute of supplierAttributes; let i = index"
                    class="inner-content-wrapper">
                <!--[attr.data-index]="'supplierAttributes_' + i"-->
                <div>
                    <div class="row" *ngIf="supplierAttributes.length">
                        <div class="col-md-6">
                            <mat-form-field>
                                <input
                                        matInput
                                        readonly
                                        [(ngModel)]="supplierAttribute.attributeCode">
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field id="supplierAttrVal_{{i}}">
                                <input
                                        matInput
                                        (change)="valueChanged()"
                                        [(ngModel)]="supplierAttribute.attributeValue">
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</div>
