import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatListModule } from '@angular/material';
import { AgGridModule } from 'ag-grid-angular';
import { SetupGridModule } from '../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid.module';
import { ResAvailabilityCalendarFocusViewModule } from '../../widgets/shared/res-availability-calendar-focus-view/res-availability-calendar-focus-view.module';
import { NotificationQueueResultsComponent } from './notification-queue-results.component';
import {MatButtonModule} from '@angular/material/button';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatIconModule} from '@angular/material/icon';
import {TranslateModule} from '@ngx-translate/core';
import {MatMenuModule} from "@angular/material/menu";

@NgModule({
    declarations: [NotificationQueueResultsComponent],
    imports: [
        CommonModule,
        AgGridModule,
        ResAvailabilityCalendarFocusViewModule,
        MatButtonModule,
        MatTooltipModule,
        MatIconModule,
        TranslateModule,
        MatMenuModule,
        MatListModule,
        SetupGridModule
    ],
    exports: [
        NotificationQueueResultsComponent
    ],
    entryComponents: [
        NotificationQueueResultsComponent
    ]
})
export class NotificationQueueResultsModule {}
