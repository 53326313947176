import { Component, HostListener, OnDestroy, OnInit, Type } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '@tc-core/model/it/codegen/tbx/api/authority/v2/users/user';
import { ErrorView } from '@tc-core/model/it/codegen/ui/error-view';
import { PlaceHolder } from '@tc-core/model/it/codegen/ui/framework/placeholder';
import { ScaffableComponent } from '@tc-core/model/it/codegen/ui/framework/scaffable-component';
import { Scaffolding } from '@tc-core/model/it/codegen/ui/framework/scaffolding';
import { ScaffoldingHelper } from '@tc-core/util/framework/scaffolding-helper.service';
import { CommonHelper } from '@tc-core/util/helpers';
import { SpinnerService } from '@tc-core/util/ui';
import { SnackBarComponent } from '@tc/snack-bar';
import { Subscription } from 'rxjs';
import { AppService } from './app.service';
import { AuthTokenService } from './authentication/auth-token.service';
import { UserProfile } from './authentication/user-profile';
import { ConsoleToggleService } from './console-toggle.service';
import { TCO } from './constants';
import { BrandSearchCriteria } from './models/criteria/brand-search-criteria';
import { DistributionChannelSearchCriteria } from './models/criteria/distribution-channel-search-criteria';
import { JobSearchCriteria } from './models/criteria/job-search-criteria';
import { KeyControlAndAbstractSearchCriteria } from './models/criteria/key-control-and-abstract-search-criteria';
import { LanguageSearchCriteria } from './models/criteria/language-search-criteria';
import { SavedCriteriaV2 } from './models/criteria/saved-criteria-v2';
import { SupplierSearchCriteria } from './models/criteria/supplier-search-criteria';
import { UserBusinessType } from './models/user/user-business-type';
import { DocumentService } from './services/backend-consumers/documents/document.service';
import { JobService } from './services/backend-consumers/job/job-service';
import { DataHandlerService } from './services/backend-consumers/master-data-handler-service/data-handler.service';
import {
    MasterDataHandlerService
} from './services/backend-consumers/master-data-handler-service/master-data-handler.service';
import {
    MasterDataRestService
} from './services/backend-consumers/master-data-handler-service/master-data-rest.service';
import { NotificationService } from './services/backend-consumers/notifications/notification.service';
import {
    ReservationManagementService
} from './services/backend-consumers/reservation-management-services/reservation-management.service';
import {
    TrsReservationManagementService
} from './services/backend-consumers/reservation-management-services/trs-reservation-management.service';
import {
    ReservationV2ManagementService
} from './services/backend-consumers/reservation-v2/reservation-v2-management.service';
import { AccountingRuleService } from './services/backend-consumers/setups/accounting-rule.service';
import { BrandSetupService } from './services/backend-consumers/setups/brand-setup-service';
import { DistributionChannelSetupService } from './services/backend-consumers/setups/distribution-channel-setup-service';
import { SupplierService } from './services/backend-consumers/supplier-service/supplier.service';
import { DMCCommon } from './services/util/common/dmc-common';
import { RootService } from './services/util/core-services/root.service';
import { DataKey, DataStoreService } from './services/util/framework/data-store.service';
import { DMCQueryParamsService } from './services/util/framework/dmc-query-params.service';
import { LanguageService } from './services/util/language/language.service';
import { ResponseUtil } from './services/util/response/response-util.service';
import { DMCLocalStorageService, LocalDataKey } from './services/util/system/dmc-local-storage.service';
import {UserManagementService} from './services/user-management/user-management.service';

@Component({
    selector: 'tc-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
    private static defaultRoute = '/s01/(p1:tc-side-nav//p2:tc-app-header//p4:tc-launch-pad-page//p10:tc-dashboard-search)' +
        '?journey=launch_pad&page=launch_pad';
    snackBarRef = null;
    public user: User;
    public userProfile: UserProfile;
    systemId = 0; // for system defined saved search criteria
    allItems = 1000000;
    tokenAdded = false;
    public anyOption: any;
    public innerWidth: any;
    private dialogIsOpen = false;

    private supplierByIdObsv: Subscription = new Subscription();

    constructor(
        private dataHandlerService: DataHandlerService,
        private masterDataRestService: MasterDataRestService,
        private supplierService: SupplierService,
        public ais: AppService,
        private router: Router,
        private scaffHelper: ScaffoldingHelper,
        private dataStoreService: DataStoreService,
        private common: DMCCommon,
        private spinnerService: SpinnerService,
        public snackBar: MatSnackBar,
        private trsReservationManagementService: TrsReservationManagementService,
        private reservationManagementService: ReservationManagementService,
        private reservationV2ManagementService: ReservationV2ManagementService,
        private masterDataHandlerService: MasterDataHandlerService,
        private accountingRuleService: AccountingRuleService,
        private documentService: DocumentService,
        private activatedRoute: ActivatedRoute,
        private notificationService: NotificationService,
        private autTokenService: AuthTokenService,
        // private translateService: TranslateService,
        public langService: LanguageService,
        private commonHelper: CommonHelper,
        private queryParamService: DMCQueryParamsService,
        private rootService: RootService,
        private localStorage: DMCLocalStorageService,
        private consoleToggleService: ConsoleToggleService,
        private brandSetupService: BrandSetupService,
        private distributionChannelSetupService: DistributionChannelSetupService,
        private jobService: JobService,
        private userManagementService: UserManagementService
    )
    {
        // translateService.addLangs(['en', 'ar', 'es', 'fr', 'it', 'ru', 'it', 'zh']);
        // translateService.setDefaultLang('en');
        // translateService.use('en');
        this.consoleToggleService.disableConsoleInProduction();
        // this.activatedRoute.queryParams.subscribe((params: TokenParams) => {
        //     console.log(params);
        //     this.tokenAdded = this.autTokenService.updateTokenParams(params);
        //     if (this.tokenAdded) {
        //         // let url: string = this.router.url.substring(0, this.router.url.indexOf("?"));
        //         // this.router.navigateByUrl(url);
        //     }
        // });

    }

    ngOnInit()
    {
        this.userManagementService.loadConfiguredCompany();
        this.checkScreenRes();

        // websocket connect
        this.notificationService.onConnect();

        document.querySelector('html').classList.add('central');

        this.ais.scaffStructure.asObservable().subscribe(
            scaff => {
                if (JSON.stringify(scaff) !== JSON.stringify([])) {

                    // add default route to configuration
                    this.router.config.push(
                        {
                            path: '',
                            redirectTo: AppComponent.defaultRoute,
                            pathMatch: 'full',
                            canActivate: [true]
                        }
                    );

                    // iterate scaffolding models
                    for (let i = 0; i < scaff.length; i++) {
                        const currentScaff: Scaffolding = scaff[i];
                        const parentComponentRef = this.scaffHelper.getComponentReferenceBySelector(
                            currentScaff.info.selector) as Type<any>;

                        this.router.config.push(
                            {
                                path: currentScaff.info.selector,
                                component: parentComponentRef,
                                children: [],
                                runGuardsAndResolvers: 'always'
                            }
                        );

                        if (currentScaff.placeHolders) {
                            // iterate placeholders of a scaffolding model
                            for (let j = 0; j < currentScaff.placeHolders.length; j++) {
                                const placeholder: PlaceHolder = currentScaff.placeHolders[j];

                                if (placeholder.validComponents) {
                                    // iterate calid components of a placeholder
                                    for (let k = 0; k < placeholder.validComponents.length; k++) {
                                        const validComponent: ScaffableComponent = placeholder.validComponents[k];
                                        const childCmpRef = this.scaffHelper
                                                                .getComponentReferenceBySelector(
                                                                    validComponent.selector) as Type<any>;

                                        if (childCmpRef) {
                                            this.router.config[i + 1].children.push(
                                                {
                                                    path: validComponent.selector,
                                                    component: childCmpRef,
                                                    outlet: placeholder.info.id
                                                }
                                            );
                                        }

                                    }
                                }
                            }
                        }
                    }

                    this.router.resetConfig(this.router.config);
                }
            }
        );
        this.anyOption = {
            code: 'ANY',
            name: 'Any'
        };
        this.showSnackBarSubscriber();
        this.user = this.dataStoreService.get(DataKey.userDetail).getValue();
        this.userProfile = this.dataStoreService.get(DataKey.userProfile).getValue();
        this.validateUser();
        this.getResourceTypes();
        this.getContactCategories();
        this.getCountries();
        this.getCurrencyList();
        this.getPaymentMethods();
        this.getTransferModes();
        this.getVehicleAmenities();
        this.getSuppliers();
        this.getRoutes();
        this.getLocationTypes();
        // this.getAccountingEvents();
        // this.getAccountingUnits();
        // this.getLedgerAccounts();
        this.getDocumentTypesForReports();
        this.getDocumentTypesForManifests();
        this.getUserCriteria();
        this.getSystemDefinedCriteria();
        this.getVehicleModels();
        this.getCostingTypeList();
        this.getLanguageList();
        this.getCities();
        this.getJobNames();
        this.userManagementService.getKcCompaniesAndDivisions();
        this.userManagementService.getKcBrands();
        this.userManagementService.getKcDistChannels();
    }

    public getResourceTypes()
    {
        this.dataHandlerService.retrieveResourceTypes().subscribe(
            result => {
                this.dataStoreService.set(DataKey.resourceTypes, result.data, true);
            });
    }

    public getVehicleModels() {
        this.dataHandlerService.retrieveVehicleModels().subscribe(result => {
            this.dataStoreService.set(DataKey.vehicleModels, result.data, true);
        });
    }

    public getCountries() {
        this.dataHandlerService.retrieveCountryDetails().subscribe(
            result => {
                const x = JSON.parse(JSON.stringify(result));
                if (x && x.data) {
                    this.dataStoreService.set(DataKey.countryList, x.data, true);
                }
            });
    }

    public getCities() {
        this.dataHandlerService.retrieveCityDetails('', '').subscribe(
            result => {
                result = JSON.parse(JSON.stringify(result));
                this.dataStoreService.set(DataKey.cityList, result.data, true);
            });
    }

    public getJobNames() {
        const jobSearchCriteria = new JobSearchCriteria();
        this.jobService.getCommonJobs(jobSearchCriteria).subscribe(
            result => {
                result = JSON.parse(JSON.stringify(result));
                if (result && result.data) {
                    const jobList = result.data.map(datum => {
                        return {
                            code: datum.jobName,
                            name: datum.jobDisplayName
                        };
                    });
                    jobList.push({
                        code: 'ANY',
                        name: 'Any'
                    });
                    this.dataStoreService.set(DataKey.commonJobList, jobList, true);
                }
            });
    }

    public getTransferModes() {
        this.dataHandlerService.getTransferModes().subscribe(
            result => {
                result = JSON.parse(JSON.stringify(result));
                if (result && result.data && result.data) {
                    const transferModeList = [];
                    result.data.forEach(data => {
                        const trsMode = {
                            code: data.code,
                            name: data.code + ' | ' + data.name,
                            nameOnly: data.name,
                            capacity: data.capacity
                        };
                        transferModeList.push(trsMode);
                    });

                    this.dataStoreService.set(DataKey.transferModes, transferModeList, true);
                }
            });
    }

    public getCurrencyList() {
        this.dataHandlerService.retrieveCurrencyList().subscribe(
            result => {
                result = JSON.parse(JSON.stringify(result));
                this.dataStoreService.set(DataKey.currencyList, result.data, true);
            });
    }

    public getCostingTypeList() {
        this.masterDataRestService.getCostingTypes().subscribe(
            result => {
                if (result && result.data) {
                    this.dataStoreService.set(DataKey.costingTypes, result.data, true);
                }
            });
    }

    public getLanguageList() {
        const criteria = new LanguageSearchCriteria();
        criteria.size = 1000000;
        this.langService.getLanguages(criteria).subscribe(
            result => {
                result = JSON.parse(JSON.stringify(result));
                this.dataStoreService.set(DataKey.allLanguages, result.data, true);
            });
    }

    public getPaymentMethods() {
        this.dataHandlerService.retrievePaymentMethods().subscribe(
            result => {
                result = JSON.parse(JSON.stringify(result));
                this.dataStoreService.set(DataKey.paymentMethods, result.data, true);
            });
    }

    public getContactCategories() {
        this.dataHandlerService.retrieveCategories().subscribe(
            result => {
                this.dataStoreService.set(DataKey.contactCategories, result.data, true);
            });
    }

    public getVehicleAmenities() {
        this.dataHandlerService.retrieveVehicleFacilities().subscribe(
            result => {
                this.dataStoreService.set(DataKey.vehicleAmenities, result.data, true);
            });
    }

    public getSuppliers() {
        this.supplierService.searchSuppliersForChips()
            .subscribe(
                result => {
                    if (result !== null) {
                        const suppliers = ResponseUtil.getDataArray(result);
                        this.dataStoreService.set(DataKey.supplierList, suppliers, true);
                    }
                });
    }

    public getRoutes() {
        this.dataHandlerService.retrieveRoutes().subscribe(
            result => {
                this.dataStoreService.set(DataKey.routes, result.data, true);
            });
    }

    // not calling in ng on init
    getLocations() {
        this.dataHandlerService.getLocations().subscribe(
            result => {
                this.dataStoreService.set(DataKey.locationsArr, result.data, true);
            });
    }

    getLocationTypes() {
        this.dataHandlerService.getLocationTypes().subscribe(
            result => {
                this.dataStoreService.set(DataKey.locationTypesArr, result.data, true);
            });
    }

    // not calling in ng on init
    getAirports() {
        this.dataHandlerService.getAirports().subscribe(
            result => {
                this.dataStoreService.set(DataKey.airportArr, result.data, true);
            });
    }

    getAccountingEvents() {
        const criteria: any = {};
        criteria.size = 1000000;
        // this.accountingRuleService.getAccountingEvents(criteria).subscribe(
        //     result => {
        //         this.dataStoreService.set(DataKey.accountingEvents, result['data'], true);
        //     });
    }

    getAccountingUnits() {
        const criteria: any = {};
        criteria.size = 1000000;
        // this.accountingRuleService.getAccountingUnits(criteria).subscribe(
        //     result => {
        //         this.dataStoreService.set(DataKey.accountingUnits, result['data'], true);
        //     });
    }

    getLedgerAccounts() {
        const criteria: any = {};
        criteria.size = 1000000;
        // this.accountingRuleService.getLedgerAccounts(criteria).subscribe(
        //     result => {
        //         this.dataStoreService.set(DataKey.ledgerAccounts, result['data'], true);
        //     });
    }

    getDocumentTypesForReports() {
        const criteria: any = {};
        criteria.size = 1000000;
        this.documentService.getDocumentTypes(true, false).subscribe(
            result => {
                const reports = [];
                // removing the cost sheets
                result.data.forEach(data => {
                    if (data.code !== 'SUPPLIER_COST_SHEET' && data.code !== 'SUPPLIER_GEN_COST_SHEET' && data.code !==
                        'SUPPLIER_TRAN_COST_SHEET') {
                        reports.push(data);
                    }
                });
                this.dataStoreService.set(DataKey.documentTypesReports, reports, true);
            });
    }

    getDocumentTypesForManifests() {
        const criteria: any = {};
        criteria.size = 1000000;
        this.documentService.getDocumentTypes(false, true).subscribe(
            result => {
                this.dataStoreService.set(DataKey.documentTypesManifests, result.data, true);
            });
    }

    getLocationsOfLocationType(locationType: string) {
        this.dataHandlerService.getLocationsOfLocationType(locationType).subscribe(
            result => {
                if (result.data) {
                    this.dataStoreService.set(DataKey.locationsOfDropOffType, result.data, true);
                }
                return this.dataStoreService.get(DataKey.locationsOfDropOffType);
            });
    }

    getUserCriteria() {
        this.dataStoreService.set(DataKey.userSavedSearchCriteriaListV2, null, true);
        if (this.user) {
            // For V1
            // this.reservationManagementService.getUserSearchCriteriaList(this.user.id)
            //     .subscribe(result => {
            //         if (result) {
            //             let criterias = ResponseUtil.getDataArray<Criteria>(result).sort();
            //             this.dataStoreService.set(DataKey.userSavedSearchCriteriaList, criterias, true);
            //         }
            //     });

            // For V2
            this.reservationV2ManagementService.getUserSearchCriteriaList(this.user.id)
                .subscribe(result => {
                    if (result) {
                        const criterias = ResponseUtil.getDataArray<SavedCriteriaV2>(result).sort();
                        this.dataStoreService.set(DataKey.userSavedSearchCriteriaListV2, criterias, true);
                    }
                });
        }
    }

    getSystemDefinedCriteria() {
        this.dataStoreService.set(DataKey.systemDefinedCriteriaV2, null, true);
        // For V1
        // this.reservationManagementService.getUserSearchCriteriaList(this.systemId)
        //     .subscribe(result => {
        //         if (result) {
        //             let criterias = ResponseUtil.getDataArray<Criteria>(result).sort();
        //             this.dataStoreService.set(DataKey.systemDefinedCriteria, criterias, true);
        //         }
        //     });

        // For V2
        this.reservationV2ManagementService.getUserSearchCriteriaList(this.systemId)
            .subscribe(result => {
                if (result) {
                    const criterias = ResponseUtil.getDataArray<SavedCriteriaV2>(result).sort();
                    this.dataStoreService.set(DataKey.systemDefinedCriteriaV2, criterias, true);
                }
            });
    }

    private showSnackBarSubscriber() {
        this.dataStoreService.get(DataKey.error).subscribe(data => {
            if (data && JSON.stringify(data) !== JSON.stringify({})) {

                const errorView: ErrorView = data as ErrorView;

                this.snackBarRef = this.snackBar.openFromComponent(SnackBarComponent, {
                    data: errorView,
                    duration: errorView.dismissDuration
                });

                this.spinnerService.hide();

                this.dataStoreService.set(DataKey.error, null);
            } else if (JSON.stringify(data) === JSON.stringify({})) {
                /*if (this.snackBarRef) {
                 this.snackBarRef.dismiss();
                 }*/
            }
        });
    }

    public ngOnDestroy(): void {
        this.notificationService.onStop();
    }

    private validateUser() {
        this.localStorage.store(LocalDataKey.userBusinessType, this.userProfile.businessType);
        this.localStorage.store(LocalDataKey.kcCompany, this.userProfile.kcCompany);
        if (this.userProfile && this.userProfile.resourceLinks[0]
            && this.userProfile.resourceLinks[0].supplierId > 0
            && this.userProfile.businessType === UserBusinessType.EXTERNAL) {
            this.setExtranetSupplier(this.userProfile.resourceLinks[0].supplierId);
        }
    }

    private setExtranetSupplier(supplierId: number) {
        this.supplierService.retrieveSupplierById(supplierId);
        let detailSupplier = null;
        this.supplierByIdObsv = this.dataStoreService.get(DataKey.supplier).subscribe(
            res => {
                if (this.supplierByIdObsv) {
                    this.supplierByIdObsv.unsubscribe();
                }
                if (this.commonHelper.dataValidity(res) && ResponseUtil.getFirstData(res)) {
                    detailSupplier = ResponseUtil.getFirstData(res);
                    const supplierSearchCriteria: SupplierSearchCriteria = new SupplierSearchCriteria();
                    supplierSearchCriteria.code = detailSupplier.code;
                    supplierSearchCriteria.supplierId = detailSupplier.supplierId;
                    supplierSearchCriteria.status = detailSupplier.activeStatus;
                    const parameters = this.queryParamService.getQueryParamsArray(supplierSearchCriteria);
                    this.rootService.setDataToLocalStorage(
                        TCO.AppData.SUPPLIER_SEARCH_CRITERIA,
                        supplierSearchCriteria,
                        true,
                        parameters
                    );
                }
            }
        );
    }

    public getKcCompaniesAndDivisions() {
        const criteria = new KeyControlAndAbstractSearchCriteria();
        criteria.kcCompany = this.userProfile.kcCompany;
        const divisionList = [];
        this.dataHandlerService.getCompaniesAndDivisions(criteria).subscribe(
            result => {
                const data = result.data[0];

                if (data) {
                    this.dataStoreService.set(DataKey.kcCompanies, [data], true);

                    if (data.divisions) {
                        divisionList.push(...data.divisions);
                    }
                    this.dataStoreService.set(DataKey.kcDivisions, divisionList, true);
                }
                if (this.userProfile.kcDivision) {
                    criteria.kcDivision = this.userProfile.kcDivision;
                } else {
                    divisionList.unshift(this.anyOption);
                }
            });
    }

    private getKcBrands() {
        const criteria = new BrandSearchCriteria();
        const brandList = [];
        criteria.size = this.allItems;
        this.brandSetupService.getRows(criteria).subscribe(
            result => {
                if (result) {
                    brandList.push(...result.data);
                    this.dataStoreService.set(DataKey.kcBrandsList, brandList, true);
                }
            });
        if (this.userProfile.kcBrand) {
            criteria.code = this.userProfile.kcBrand;
        } else {
            brandList.unshift(this.anyOption);
        }
    }

    private getKcDistChannels() {
        const criteria = new DistributionChannelSearchCriteria();
        criteria.size = this.allItems;
        let distChannelList = [];
        this.distributionChannelSetupService.getRows(criteria).subscribe(
            result => {
                if (result) {
                    distChannelList.push(...result.data);
                    this.dataStoreService.set(DataKey.kcDistributionChannelList, distChannelList, true);
                }
            });
        if (this.userProfile.kcDistributionChannel) {
            criteria.code = this.userProfile.kcDistributionChannel;
        } else {
            distChannelList.unshift(this.anyOption);
        }
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.checkScreenRes();
    }

    private checkScreenRes() {
        this.innerWidth = window.innerWidth;
        const snackBarConfig = new MatSnackBarConfig();
        snackBarConfig.panelClass = ['tc-snack-bar'];
        if (this.innerWidth < 1024 && !this.dialogIsOpen) {
            this.dialogIsOpen = true;
            const snack = this.snackBar.open('Screen resolution is not supported', 'Close', snackBarConfig);

            snack.afterDismissed().subscribe(() => {
                this.dialogIsOpen = false;
            });
        }
        if (this.innerWidth >= 1024) {
            // close alert
            this.snackBar.dismiss();
        }
    }
}
