/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../node_modules/@tc/section-heading/section-heading.component.ngfactory";
import * as i2 from "@tc/section-heading/section-heading.component";
import * as i3 from "@tc-core/util/framework/user-journey-manager.service";
import * as i4 from "../../../../../../node_modules/ag-grid-angular/dist/agGridAngular.ngfactory";
import * as i5 from "ag-grid-angular/dist/angularFrameworkOverrides";
import * as i6 from "ag-grid-angular/dist/angularFrameworkComponentWrapper";
import * as i7 from "ag-grid-angular/dist/agGridAngular";
import * as i8 from "./room-cost-setup-focus-view.component";
import * as i9 from "@tc-core/util/framework/event-manager.service";
import * as i10 from "@tc-core/util/framework/config-loader.service";
import * as i11 from "../../../../services/util/framework/data-store.service";
import * as i12 from "@tc/focus-view/focus-view.service";
import * as i13 from "../../../../services/util/ui/content-summary-card.service";
import * as i14 from "../../../../services/backend-consumers/master-data-handler-service/data-handler.service";
import * as i15 from "@tc-core/util/ui/spinner.service";
import * as i16 from "../../../../services/util/ui/card-data-handler-service.service";
import * as i17 from "@tc/dialog/dialog.service";
import * as i18 from "../../../../services/backend-consumers/documents/document.service";
import * as i19 from "@tc/modal/modal.service";
import * as i20 from "../../../../services/util/common/dmc-common";
import * as i21 from "../../../../services/backend-consumers/supplier-service/supplier.service";
var styles_RoomCostSetupFocusViewComponent = [];
var RenderType_RoomCostSetupFocusViewComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_RoomCostSetupFocusViewComponent, data: {} });
export { RenderType_RoomCostSetupFocusViewComponent as RenderType_RoomCostSetupFocusViewComponent };
export function View_RoomCostSetupFocusViewComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [["class", ""]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "tc-section-heading", [], null, [[null, "actionClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("actionClick" === en)) {
        var pd_0 = (_co.onHeaderActionClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_SectionHeadingComponent_0, i1.RenderType_SectionHeadingComponent)), i0.ɵdid(2, 638976, null, 0, i2.SectionHeadingComponent, [i3.UserJourneyManager], { pageSection: [0, "pageSection"] }, { actionClick: "actionClick" }), (_l()(), i0.ɵeld(3, 16777216, null, null, 4, "ag-grid-angular", [["class", "ag-theme-material tc-ag-grid tc-ag-grid--full-height tc-border-right-0 tc-border-left-0"], ["rowSelection", "multiple"], ["singleClickEdit", "true"], ["style", ""]], null, [[null, "gridReady"], [null, "selectionChanged"], [null, "cellValueChanged"], [null, "rowValueChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("gridReady" === en)) {
        var pd_0 = (_co.onGridReady($event) !== false);
        ad = (pd_0 && ad);
    } if (("selectionChanged" === en)) {
        var pd_1 = (_co.onSelectionChanged($event) !== false);
        ad = (pd_1 && ad);
    } if (("cellValueChanged" === en)) {
        var pd_2 = (_co.onCellValueChanged($event) !== false);
        ad = (pd_2 && ad);
    } if (("rowValueChanged" === en)) {
        var pd_3 = (_co.validateUniqueValues($event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, i4.View_AgGridAngular_0, i4.RenderType_AgGridAngular)), i0.ɵprd(512, null, i5.AngularFrameworkOverrides, i5.AngularFrameworkOverrides, [i0.NgZone]), i0.ɵprd(512, null, i6.AngularFrameworkComponentWrapper, i6.AngularFrameworkComponentWrapper, []), i0.ɵdid(6, 4898816, null, 1, i7.AgGridAngular, [i0.ElementRef, i0.ViewContainerRef, i5.AngularFrameworkOverrides, i6.AngularFrameworkComponentWrapper, i0.ComponentFactoryResolver], { rowData: [0, "rowData"], columnDefs: [1, "columnDefs"], frameworkComponents: [2, "frameworkComponents"], defaultColDef: [3, "defaultColDef"], rowClassRules: [4, "rowClassRules"], rowSelection: [5, "rowSelection"], singleClickEdit: [6, "singleClickEdit"], pagination: [7, "pagination"], paginationAutoPageSize: [8, "paginationAutoPageSize"] }, { cellValueChanged: "cellValueChanged", rowValueChanged: "rowValueChanged", selectionChanged: "selectionChanged", gridReady: "gridReady" }), i0.ɵqud(603979776, 1, { columns: 1 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.costAddSection; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.resourceCosts; var currVal_2 = _co.columnDefs; var currVal_3 = _co.frameworkComponents; var currVal_4 = _co.defaultColDef; var currVal_5 = _co.rowClassRules; var currVal_6 = "multiple"; var currVal_7 = "true"; var currVal_8 = true; var currVal_9 = true; _ck(_v, 6, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); }, null); }
export function View_RoomCostSetupFocusViewComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-room-cost-setup-focus-view", [], null, null, null, View_RoomCostSetupFocusViewComponent_0, RenderType_RoomCostSetupFocusViewComponent)), i0.ɵdid(1, 4308992, null, 0, i8.RoomCostSetupFocusViewComponent, [i9.EventManager, i10.ConfigLoader, i3.UserJourneyManager, i11.DataStoreService, i12.FocusViewService, i13.ContentSummaryCardService, i14.DataHandlerService, i15.SpinnerService, i16.CardDataHandlerService, i17.DialogService, i18.DocumentService, i19.ModalService, i20.DMCCommon, i21.SupplierService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RoomCostSetupFocusViewComponentNgFactory = i0.ɵccf("tc-room-cost-setup-focus-view", i8.RoomCostSetupFocusViewComponent, View_RoomCostSetupFocusViewComponent_Host_0, { rooms: "rooms", supplierCostSetup: "supplierCostSetup", resourceCosts: "resourceCosts", selectedResourceTypes: "selectedResourceTypes", selectedProfileTypes: "selectedProfileTypes", currencyList: "currencyList", costingTypeList: "costingTypeList", daysOfWeek: "daysOfWeek", defaultProfileType: "defaultProfileType", resourceTypes: "resourceTypes" }, {}, []);
export { RoomCostSetupFocusViewComponentNgFactory as RoomCostSetupFocusViewComponentNgFactory };
