import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatExpansionModule } from '@angular/material';
import { ChipInputModule } from '@tc/chip-input';
import { ReservationV2ManagementService } from '../../../services/backend-consumers/reservation-v2/reservation-v2-management.service';
import { ProductQueueSearchCriteriaCommonComponent } from './common/product-queue-search-criteria-common.component';
import { ProductQueueSearchCriteriaGenComponent } from './gen/product-queue-search-criteria-gen.component';
import { ProductQueueSearchCriteriaHotelComponent } from './hotel/product-queue-search-criteria-hotel.component';
import { ProductQueueSearchCriteriaComponent } from './product-queue-search-criteria.component';
import { ProductQueueSearchCriteriaTouComponent } from './tou/product-queue-search-criteria-tou.component';
import { ProductQueueSearchCriteriaTrsComponent } from './trs/product-queue-search-criteria-trs.component';

@NgModule({
    declarations: [
        ProductQueueSearchCriteriaComponent,
        ProductQueueSearchCriteriaCommonComponent,
        ProductQueueSearchCriteriaGenComponent,
        ProductQueueSearchCriteriaTrsComponent,
        ProductQueueSearchCriteriaTouComponent,
        ProductQueueSearchCriteriaHotelComponent
    ],
    imports: [
        CommonModule,
        ChipInputModule,
        MatExpansionModule
    ],
    exports: [
        ProductQueueSearchCriteriaComponent,
        ProductQueueSearchCriteriaCommonComponent,
        ProductQueueSearchCriteriaGenComponent,
        ProductQueueSearchCriteriaTrsComponent,
        ProductQueueSearchCriteriaTouComponent,
        ProductQueueSearchCriteriaHotelComponent
    ],
    entryComponents: [
        ProductQueueSearchCriteriaComponent,
        ProductQueueSearchCriteriaCommonComponent,
        ProductQueueSearchCriteriaGenComponent,
        ProductQueueSearchCriteriaTrsComponent,
        ProductQueueSearchCriteriaTouComponent,
        ProductQueueSearchCriteriaHotelComponent
    ],
    providers: [
        ReservationV2ManagementService
    ]
})

export class ProductQueueSearchCriteriaModule {}
