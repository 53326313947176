<div class="container-fluid tc-item-wrapper tcpi-card">
    <div class="tc-item-wrapper__header tc-item-header-highlighted" *ngIf="showTitle">
        <div class="tc-item-header-highlighted__icon">
            <i class="material-icons material-icons">business_center</i>
        </div>
        <div class="tc-item-header-highlighted__primary">{{"Service Information" | translate}}</div>
    </div>
    <div class="tc-item-wrapper__body">
        <div class="row">
            <div class="col-md-12">
                <div class="tc-flex-box">
                    <div class="tc-flex-item icon-cage input-icon">
                        <div class="card-icon--small icn-color-primary">
                            <i class="material-icons material-icons">business_center</i>
                        </div>
                    </div>
                    <div class="tc-flex-item input-cage">
                        <div class="col-xs-12 col-sm-6  col-md-3 col-lg-3">
                            <div class="tc-content--wrap tc-input-view">
                                <div class="tc-caption-txt txt-light">
                                    {{"Service Company Name" | translate}}
                                </div>
                                <div
                                        class="tc-body1--txt">{{serviceInformation?.serviceCompanyName ? serviceInformation.serviceCompanyName : EMPTY_CHAR}}
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-6  col-md-3 col-lg-3">
                            <div class="tc-content--wrap tc-input-view">
                                <div class="tc-caption-txt txt-light">{{"Service Company Contact Number" | translate}}
                                </div>
                                <div
                                        class="tc-body1--txt">{{serviceInformation?.companyContactNum ? serviceInformation.companyContactNum : EMPTY_CHAR}}
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-6  col-md-3 col-lg-3">
                            <div class="tc-content--wrap tc-input-view">
                                <div class="tc-caption-txt txt-light">{{"Alternate Contact Number" | translate}}
                                </div>
                                <div class="tc-body1--txt">{{serviceInformation?.altContactNum ? serviceInformation.altContactNum : EMPTY_CHAR}}
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                            <div class="tc-content--wrap tc-input-view">
                                <div class="tc-caption-txt txt-light">{{"Email" | translate}}
                                </div>
                                <div class="tc-body1--txt">{{serviceInformation?.email ? serviceInformation.email : EMPTY_CHAR}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="tc-flex-box">
                    <div class="tc-flex-item icon-cage input-icon">
                        <div class="card-icon--small icn-color-primary">
                        </div>
                    </div>
                    <div class="tc-flex-item input-cage">
                        <div class="col-xs-12 col-sm-6  col-md-3 col-lg-3">
                            <div class="tc-content--wrap tc-input-view">
                                <div class="tc-caption-txt txt-light">
                                    {{"Last Service Date" | translate}}
                                </div>
                                <div
                                        class="tc-body1--txt">{{serviceInformation?.lastServiceDate ? serviceInformation.lastServiceDate : EMPTY_CHAR}}
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-6  col-md-3 col-lg-3">
                            <div class="tc-content--wrap tc-input-view">
                                <div class="tc-caption-txt txt-light">{{"Next Service Date" | translate}}
                                </div>
                                <div
                                        class="tc-body1--txt">{{serviceInformation?.nextServiceDate ? serviceInformation.nextServiceDate : EMPTY_CHAR}}
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-6  col-md-6 col-lg-6">
                            <div class="tc-content--wrap tc-input-view">
                                <div class="tc-caption-txt txt-light">{{"Service Note" | translate}}
                                </div>
                                <div
                                        class="tc-body1--txt">{{serviceInformation?.serviceNote ? serviceInformation.serviceNote : EMPTY_CHAR}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
