<div class="container-fluid direct-client-personal-info-edit-card tc-card-wrapper">
  <div class="row">
    <div class="col-md-12 col-lg-12">
      <div class="tc-flex-box">
        <div class="tc-flex-item tc-item-circle">
          <div class="icon-box">
            <i class="material-icons">people</i>
          </div>
        </div>
        <div class="tc-flex-item tc-right-cage">
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 remove-bootstrap-padding-mobile" >
              <ag-grid-angular
                      id="booking-passenger-grid"
                      *ngIf="(this.booking && this.booking.bookingPassengers && this.booking.bookingPassengers.length)"
                      class="ag-theme-material tc-ag-grid"
                      style="height: 100%;"
                      [columnDefs]="columnDefs"
                      [defaultColDef]="defaultColDef"
                      [domLayout]="'autoHeight'"
                      [rowData]="this.booking.bookingPassengers"
                      [overlayNoRowsTemplate]="overlayNoRowsTemplate"
                     >
              </ag-grid-angular>

              <ng-container *ngIf="!(this.booking && this.booking.bookingPassengers && this.booking.bookingPassengers.length)">
                <div class="tc-card-placeholder  tc-card-placeholder--space-vertical">
                  <i class="material-icons tc-card-placeholder__icon">assignment_error</i>
                  <span class="tc-ag-grid-empty__text">{{'No Passengers' | translate}}</span>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

