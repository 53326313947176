import { ServiceInformation } from '../supplier/service-information';
import { InsuranceInformation } from '../supplier/insurance-information';

export class Vehicle {
    vehicleId: number;
    fuelConsumption: string;
    fuelType: string;
    licencePlate: string;
    trsMode: string;
    trsModeCodeName: string;
    vehicleModel: string;
    vehicleName: string;
    driverId: number;
    driverName: string;
    facilities: Facility[] = [];
    resInsuranceInfo: InsuranceInformation;
    resServiceInfo: ServiceInformation;
}

export namespace FuelTypeOptions {
    export enum TYPE {'Petrol', 'Diesel', 'Electric', 'Hybrid'}
}

export class Facility {
    tblFacilityId : number;
    facilityCode: string;
    vehicleId: number;


    constructor(facilityCode: string) {
        this.facilityCode = facilityCode;
    }
}
