import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PaymentInfo } from '../../../models/common/payment-info';

@Component({
    selector: 'tc-payment-information',
    templateUrl: './payment-information.component.html'
})
export class PaymentInformationComponent implements OnInit {

    @Input() paymentInfo = new PaymentInfo();
    @Input() currencyList: any[]=[];
    @Input() payMethods: any[]=[];

    @Output() changeFormValidity: EventEmitter<boolean> = new EventEmitter();

    paymentInfoFG: FormGroup;
    stringRegex = '^[a-zA-Z_ ]*$';

    constructor(private fb: FormBuilder) {

    }

    ngOnInit() {
        this.createForm();
        this.paymentInfoFG.valueChanges.subscribe(
            (data) => {
                if (JSON.stringify(data) !== JSON.stringify({})) {
                    this.paymentInfo.currency = data.paymentCurrency;
                    this.paymentInfo.beneficiaryName = data.beneficiaryName;
                    this.paymentInfo.paymentMethod = data.paymentMethod;
                    this.paymentInfo.bankName = data.bankName;
                    this.paymentInfo.bankAccNum = data.accountNumber;
                    this.paymentInfo.iban = data.iban;
                    this.paymentInfo.sortCode = data.sortCode;
                    this.paymentInfo.swiftCode = data.swiftCode;
                    this.changeFormValidity.emit(this.paymentInfoFG.valid);
                }
            });

        this.validateForm();
    }

    public validateForm() {
        Object.keys(this.paymentInfoFG.controls).forEach(field => {
            const control = this.paymentInfoFG.get(field);
            control.markAsTouched({onlySelf: true});
        });
    }

    private createForm() {
        this.paymentInfoFG = this.fb.group({
            paymentCurrency: [this.paymentInfo ? this.paymentInfo.currency : '', [Validators.required]],
            beneficiaryName: [this.paymentInfo ? this.paymentInfo.beneficiaryName : '', [Validators.pattern(this.stringRegex)]],
            paymentMethod: [this.paymentInfo ? this.paymentInfo.paymentMethod : '', [Validators.required]],
            bankName: [this.paymentInfo ? this.paymentInfo.bankName : '', [Validators.pattern(this.stringRegex)]],
            accountNumber: [this.paymentInfo ? this.paymentInfo.bankAccNum : ''],
            iban: [this.paymentInfo ? this.paymentInfo.iban : ''],
            sortCode: [this.paymentInfo ? this.paymentInfo.sortCode : ''],
            swiftCode: [this.paymentInfo ? this.paymentInfo.swiftCode : '']
        });
        this.changeFormValidity.emit(this.paymentInfoFG.valid);
    }

    getErrorMessage(field: string): string {
        switch (field) {
            case 'paymentCurrency' :
                return this.paymentInfoFG.get('paymentCurrency').hasError('required') ? 'Payment Currency Required' : '';
            case 'paymentMethod' :
                return this.paymentInfoFG.get('paymentMethod').hasError('required') ? 'Payment Method Required' : '';
            default :
                return '';
        }
    }

    public getErrorMessageOfControl(group: FormGroup, field: string): string {
        if (field === 'Beneficiary Name') {

            return group.get('beneficiaryName').hasError('pattern') ?
                field + ' invalid' : '';
        } else if (field === 'Bank Name') {

            return group.get('bankName').hasError('pattern') ?
                field + ' invalid' : '';
        }

    }
}
