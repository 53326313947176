import { DateRange } from './date-range';

export class RangeGroup {
  id: number;
  name: string;
  dateRanges: DateRange[] = [];
  colorId: string;
  selected: boolean;
  nonRemovable: boolean;

  tempName: string;  //for edit purpose
  tempColorId: string; //for edit purpose
}
