import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DirectivesModule } from '@tc/directives';
import { SectionHeadingModule } from '@tc/section-heading';
import { CountryStateCityService } from '../../../services/business-utils/country-state-city/country-state-city.service';
import { AdditionalContactsModule } from '../../../widgets/shared/additional-contacts/additional-contacts.module';
import { ContactDetailsModule } from '../../../widgets/shared/contact-details/contact-details.module';
import { PaymentInformationModule } from '../../../widgets/shared/payment-information/payment-information.module';
import { PersonalInformationModule } from '../../../widgets/shared/personal-information/personal-information.module';
import { VehicleSpecificInformationModule } from '../../../widgets/shared/vehicle-specific-information/vehicle-specific-information.module';
import { SupplierAttributesModule } from '../../../widgets/shared/supplier-attributes/supplier-attributes.module';
import { TaxSchemesModule } from '../../../widgets/shared/tax-schemes/tax-schemes.module';
import { KeyControlsEditViewModule } from '../common/key-controls-edit-view/key-controls-edit-view.module';
import { RemarksModule } from '../shared/remarks/remarks.module';
import { DriverSpecificModule } from '../specific/driver-specific/driver-specific.module';
import { GuideSpecificModule } from '../specific/guide-specific/guide-specific.module';
import { HennaArtistSpecificModule } from '../specific/henna-artist-specific/henna-artist-specific.module';
import { GenericComponent } from './generic.component';

@NgModule({
    imports: [
        CommonModule,
        SectionHeadingModule,
        DirectivesModule,
        PersonalInformationModule,
        PaymentInformationModule,
        ContactDetailsModule,
        AdditionalContactsModule,
        SupplierAttributesModule,
        VehicleSpecificInformationModule,
        TaxSchemesModule,
        RemarksModule,

        GuideSpecificModule,
        DriverSpecificModule,
        HennaArtistSpecificModule,
        KeyControlsEditViewModule

    ],
    providers: [CountryStateCityService],
    declarations: [GenericComponent],
    exports: [GenericComponent],
    entryComponents: [GenericComponent]
})
export class GenericModule {}
