<!--<tc-result-header-->
<!--        [heading]="heading"-->
<!--        [sorters]="sorters_"-->
<!--        (selectSorter)="onSort($event)">-->
<!--</tc-result-header>-->

<tc-adv-room-search-results>

</tc-adv-room-search-results>

<!--<tc-paginator-->
<!--        *ngIf="contentCardDataList.length > defaultPageResultCount"-->
<!--        [length]="contentCardDataList.length"-->
<!--        [index]="pager.currPage"-->
<!--        [size]="pager.itemsPerPage"-->
<!--        [hasMore]="paginatorHasMore"-->
<!--        (page)="onPage($event)"-->
<!--        (more)="onMore()">-->
<!--</tc-paginator>-->
