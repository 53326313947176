import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ConfigLoader } from '@tc-core/util/framework';
import { DataKey, DataStoreService } from '../../../services/util/framework/data-store.service';
var TotalCostEditorComponent = /** @class */ (function () {
    function TotalCostEditorComponent(dataStore, configLoader) {
        this.dataStore = dataStore;
        this.configLoader = configLoader;
        this.rowData = [];
        this.editable = true;
        this.EMPTY_STRING = '--';
        this.primaryCost = null;
        this.secondaryCost = null;
        this.currency = '';
    }
    TotalCostEditorComponent.prototype.ngOnInit = function () {
        var e_1, _a;
        this.getColumnDefs();
        try {
            for (var _b = tslib_1.__values(this.rowData), _c = _b.next(); !_c.done; _c = _b.next()) {
                var rowData = _c.value;
                if (rowData && rowData.type === 'PRIMARY') {
                    this.primaryCost = rowData;
                    this.currency = rowData.currency;
                }
                if (rowData && rowData.type === 'SECONDARY') {
                    this.secondaryCost = rowData;
                    this.currency = rowData.currency;
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
    };
    TotalCostEditorComponent.prototype.onSave = function () {
        this.dataStore.set(DataKey.popupClose, true);
        this.dataStore.set(DataKey.assignmentTotalCost, this.rowData);
    };
    TotalCostEditorComponent.prototype.onGridReadyAssignmentNotes = function (params) {
        this.gridApi = params.api;
        this.gridApi.sizeColumnsToFit();
    };
    TotalCostEditorComponent.prototype.onFirstDataRendered = function (params) {
        this.gridApi = params.api;
        this.gridApi.sizeColumnsToFit();
    };
    TotalCostEditorComponent.prototype.onClose = function ($event) {
        this.dataStore.set(DataKey.popupClose, false);
    };
    TotalCostEditorComponent.prototype.isSaveButtonEnable = function () {
        return true;
    };
    // The numeric value setter function/method
    TotalCostEditorComponent.prototype.numericSetter = function (params) {
        if (params.newValue > 0 || params.newValue === '') {
            params.data[params.colDef.field] = params.newValue;
            return true;
        }
        // Illegal value - signal no change
        return false;
    };
    TotalCostEditorComponent.prototype.getTotalCost = function (params) {
        if (params.data.totalCost) {
            return params.data.totalCost.toFixed(2);
        }
        else {
            return this.EMPTY_STRING;
        }
    };
    TotalCostEditorComponent.prototype.getTotalSum = function () {
        var sum = 0;
        if (this.primaryCost) {
            sum += this.primaryCost.totalCost;
        }
        if (this.secondaryCost) {
            sum += this.secondaryCost.totalCost;
        }
        return sum.toFixed(2);
    };
    TotalCostEditorComponent.prototype.getColumnDefs = function () {
        this.columnDefs = [
            {
                headerName: 'Service Type',
                field: 'serviceType',
                minWidth: 100,
                sortable: false,
                editable: false,
                resizable: true,
            },
            {
                headerName: 'Type',
                field: 'type',
                minWidth: 100,
                sortable: false,
                editable: false,
                resizable: true
            },
            {
                headerName: 'Estimated Cost',
                field: 'estimatedCost',
                minWidth: 100,
                sortable: false,
                editable: false,
                resizable: true,
                type: 'numericColumn',
                valueSetter: this.numericSetter,
                valueGetter: function (params) {
                    if (params.data.estimatedCost) {
                        return parseFloat(params.data.estimatedCost).toFixed(2);
                    }
                    else {
                        return '--';
                    }
                },
            },
            {
                headerName: 'Total Cost',
                field: 'totalCost',
                minWidth: 100,
                sortable: true,
                editable: this.editable,
                resizable: true,
                type: 'numericColumn',
                valueSetter: this.numericSetter,
                valueGetter: function (params) {
                    if (params.data.totalCost) {
                        return parseFloat(params.data.totalCost).toFixed(2);
                    }
                    else {
                        return '--';
                    }
                },
                cellClass: [
                    "total-cost"
                ],
            }
        ];
    };
    TotalCostEditorComponent.prototype.onSecondaryCostUpdate = function (event) {
        if (event.target && event.target.value && (event.target.value > 0 || event.target.value.value === '')) {
            this.secondaryCost.totalCost = parseFloat(event.target.value);
        }
    };
    TotalCostEditorComponent.prototype.onPrimaryCostUpdate = function (event) {
        if (event.target && event.target.value && (event.target.value > 0 || event.target.value.value === '')) {
            this.primaryCost.totalCost = parseFloat(event.target.value);
        }
    };
    return TotalCostEditorComponent;
}());
export { TotalCostEditorComponent };
