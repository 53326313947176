import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule, MatMenuModule } from '@angular/material';
import { SeasonSetupFocusViewModule } from '../season-setup-focus-view/season-setup-focus-view.module';
import { TransportCostSetupFocusViewModule } from '../transport-cost-setup-focus-view/transport-cost-setup-focus-view.module';
import { TransportCostSetupQueueFocusViewModule } from '../transport-cost-setup-queue-focus-view/transport-cost-setup-queue-focus-view.module';
import { SupplierCostSetupComponent } from './supplier-cost-setup.component';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
    imports: [
        CommonModule,
        MatIconModule,
        MatMenuModule,
        SeasonSetupFocusViewModule,
        TransportCostSetupFocusViewModule,
        TransportCostSetupQueueFocusViewModule,
        TranslateModule.forChild()
    ],
    declarations: [SupplierCostSetupComponent],
    exports: [SupplierCostSetupComponent]
})
export class SupplierCostSetupModule {}
