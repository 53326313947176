import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatMenuModule, MatTooltipModule } from '@angular/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule } from '@ngx-translate/core';
import { EmptyResultModule } from '@tc/empty-result';
import { PaginatorModule } from '@tc/paginator';
import { PipesModule } from '@tc/pipes';
import { ResultsHeaderModule } from '@tc/results-header';
import { SectionHeadingModule } from '@tc/section-heading';
import { CardStripModule } from '../../widgets/framework/card-strip';
import { LeaveSetupResourceSummaryViewComponent } from './leave-setup-resource-summary-view.component';

@NgModule({
  declarations: [LeaveSetupResourceSummaryViewComponent],
  imports: [
      CommonModule,
      CardStripModule,
      SectionHeadingModule,
      PipesModule,
      EmptyResultModule,
      PaginatorModule,
      MatMenuModule,
      TranslateModule,
      MatTooltipModule,
      ResultsHeaderModule,
      BrowserAnimationsModule
  ],
    exports:[LeaveSetupResourceSummaryViewComponent]
})
export class LeaveSetupResourceSummaryViewModule { }
