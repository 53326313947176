import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LeaveSetup } from '../../../models/leave-setup';
import { RegXPatterns } from '../regx-patterns/regx-patterns';
import { VehicleSpecificInformationComponent } from '../vehicle-specific-information/vehicle-specific-information.component';
import * as moment from 'moment';

@Component({
    selector: 'tc-leave-setup-edit-card',
    templateUrl: './leave-setup-edit-card.component.html'
})
export class LeaveSetupEditCardComponent implements OnInit {

    @Input() leaveSetupData: LeaveSetup = new LeaveSetup();
    @Input() leaveTypes = [];

    @Output() changeFormValidity: EventEmitter<boolean> = new EventEmitter();

    leaveSetupForm: FormGroup;
    minDate: any;
    initialDate = new Date(new Date().getTime());

    constructor(private fb: FormBuilder) { }

    ngOnInit() {
        this.createForm();
        this.leaveSetupForm.valueChanges.subscribe(
            (data) => {
                if (JSON.stringify(data) !== JSON.stringify({})) {
                    this.leaveSetupData.startDate = moment(data.startDate).format('YYYY-MM-DD');
                    this.leaveSetupData.endDate = moment(data.endDate).format('YYYY-MM-DD');
                    this.leaveSetupData.startTime = data.leaveType ? data.leaveType.startTime: "08:00";
                    this.leaveSetupData.endTime = data.leaveType ? data.leaveType.endTime : "05:00";
                    this.leaveSetupData.leaveType = data.leaveType ? data.leaveType.name : "FD";
                    this.leaveSetupData.note = data.note;

                    if (this.changeFormValidity) {
                        this.changeFormValidity.emit(this.leaveSetupForm.valid);
                    }
                }

            }
        );
        this.validateForm();
    }

    public validateForm() {
        Object.keys(this.leaveSetupForm.controls).forEach(field => {
            const control = this.leaveSetupForm.get(field);
            control.markAsTouched({onlySelf: true});
        });
    }

    private createForm() {
        this.leaveSetupForm = this.fb.group({
            startDate: [this.leaveSetupData ? new Date(this.leaveSetupData.startDate) : '', Validators.required],
            endDate: [this.leaveSetupData ? new Date(this.leaveSetupData.endDate) : '', Validators.required],
            leaveType: [
                this.leaveSetupData ? this.leaveSetupData.leaveType : '', Validators.required
            ],
            note: [
                this.leaveSetupData ?
                    this.leaveSetupData.note :
                    '',
                [
                    Validators.required
                ]
            ]
        }, {
            validator: LeaveSetupEditCardComponent.validateDate
        });

        this.changeFormValidity.emit(this.leaveSetupForm.valid);
    }

    getErrorMessage(field: string): string {
        if (this.leaveSetupForm) {
            switch (field) {
                case 'startDate' :
                    return this.leaveSetupForm.get('startDate').hasError('required') ?
                        'Apply From Required' :
                        'Invalid Date';
                case 'endDate' :
                    return this.leaveSetupForm.get('endDate').hasError('required') ?
                        'Apply To Required' :
                        'Invalid Date';
                case 'leaveType' :
                    return this.leaveSetupForm.get('leaveType').hasError('required') ?
                        'Leave Type Required' :
                        'Invalid Date';
                case 'note' :
                    return this.leaveSetupForm.get('note').hasError('required') ?
                        'Note Required' :
                            '';
                default :
                    return '';
            }
        }
    }

    static validateDate(group: FormGroup) {
        let start = group.controls['startDate'];
        let end = group.controls['endDate'];
        if (start.value && end.value && start.value > end.value) {
            start.setErrors({startDateError: true});
            end.setErrors({endDateError: true});
        } else {
            if (start.hasError('required')) {
                start.setErrors({required: true});
            }
            else {
                start.setErrors(null);
            }
            if (end.hasError('required')) {
                end.setErrors({required: true});
            } else {
                end.setErrors(null);
            }
        }
        // const invalid = this.leaveSetupForm ? this.leaveSetupForm.get('startDate').value > this.leaveSetupForm.get('endDate').value : false;

        // return invalid ? { 'invalidDate': true } : { 'invalidDate': false };

        return null;
    }
}
