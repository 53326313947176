import { Component, OnInit, ViewChild } from '@angular/core';
import { User } from '@tc-core/model/it/codegen/tbx/api/authority/v2/users/user';
import { Action } from '@tc-core/model/it/codegen/ui/action';
import { PageSummary } from '@tc-core/model/it/codegen/ui/framework/page-summary';
import { TC } from '@tc-core/util/constants';
import { ConfigLoader } from '@tc-core/util/framework/config-loader.service';
import { EventManager } from '@tc-core/util/framework/event-manager.service';
import { UserJourneyManager } from '@tc-core/util/framework/user-journey-manager.service';
import { PageSummaryService } from '@tc-core/util/ui/page-summary.service';
import { SideNavBarService } from '@tc-core/util/ui/side-nav-bar.service';
import { LaunchPadComponent } from '@tc/launch-pad';
import { Criteria } from 'app/models/criteria/criteria';
import { TCO} from '../../../constants';
import { SavedCriteriaV2 } from '../../../models/criteria/saved-criteria-v2';
import { ReservationManagementService } from '../../../services/backend-consumers/reservation-management-services/reservation-management.service';
import { ReservationV2ManagementService } from '../../../services/backend-consumers/reservation-v2/reservation-v2-management.service';
import { RootService } from '../../../services/util/core-services/root.service';
import { DataKey, DataStoreService } from '../../../services/util/framework/data-store.service';
import { DMCQueryParamsService } from '../../../services/util/framework/dmc-query-params.service';
import { ResponseUtil } from '../../../services/util/response/response-util.service';

// @ts-ignore
@Component({
    moduleId: module.id,
    selector: 'tc-launch-pad-page',
    templateUrl: './launch-pad.component.html'
})
export class LaunchPadPageComponent implements OnInit {

    @ViewChild('launch_pad') public launchPad: LaunchPadComponent;

    pageSummary: PageSummary = new PageSummary();
    blocks = [];
    isBlockFocus: boolean;
    focusBlockIndex = -1;
    focusActionIndex = 0;
    public user: User;
    userSavedSearchCriteriaListV1: Criteria[] = [];
    definedSearchCriteriaListV1: Criteria[] = [];
    userSavedSearchCriteriaListV2: SavedCriteriaV2[] = [];
    definedSearchCriteriaListV2: SavedCriteriaV2[] = [];
    systemId = 0; //for system defined saved search criteria
    defaultCriteriaCount = 6;
    TRANSPORT_QUEUE = 'BEGIN_RESOURCE_ALLOCATION_AS_TRANSFER_PANEL';
    GENERIC_QUEUE = 'BEGIN_RESOURCE_ALLOCATION_AS_GENERIC_QUEUE';
    COMMON_QUEUE_V2 = 'BEGIN_RESOURCE_ALLOCATION_AS_COMMON_PANEL';
    SAVED_CRITERIA = 'SAVED_CRITERIA';

    userCriteriaBlockIndex = -1;
    maxNoOfVisibleCriteria = -3;

    constructor(
        private configLoader: ConfigLoader,
        private pageSummaryService: PageSummaryService,
        private sideNavBarService: SideNavBarService,
        private userJourneyManager: UserJourneyManager,
        private dataStore: DataStoreService,
        public em: EventManager,
        private reservationService: ReservationManagementService,
        private reservationV2ManagementService: ReservationV2ManagementService,
        private queryParamService: DMCQueryParamsService,
        private rootService: RootService
    ) {
        this.sideNavBarService.addSideNavItemsList([
            'dashboard',
            'productSearch',
            'bookingMgt',
            'backOffice',
            'finance',
            'queues',
            'salesAssistant',
            'settings'
        ]);

        this.pageSummary.page = 'DASHBOARD';
        this.pageSummaryService.setPageSummary(this.pageSummary);
    }

    ngOnInit() {
        this.userJourneyManager.canProceed.next(true);

        this.blocks = this.configLoader.configurations.get(TC.CONF.CONF_LAUNCH_PAD).blocks;
        this.userCriteriaBlockIndex = this.blocks.findIndex(block => block.id === 'USER_SAVED_CRITERIA');
        const userSavedCriteriaBlock = this.blocks.filter(block => block.id === 'USER_SAVED_CRITERIA')[0];
        this.maxNoOfVisibleCriteria = userSavedCriteriaBlock.maxNoOfVisibleCriteria as unknown as number;

        this.getSystemDefinedCriteria();
        this.user = this.dataStore.get(DataKey.userDetail).getValue();
        this.getUserCriteria();

        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'FOCUS_FIRST_BLOCK').subscribe(e => {
            this.isBlockFocus = true;
            this.focusBlockIndex = (this.focusBlockIndex + 1) % (this.blocks.length);
            this.focusActionIndex = 0;
        });

        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'MOVE_FOCUS_ACTION_NEXT').subscribe(e => {
            if (this.isBlockFocus) {
                this.focusActionIndex++;
            }
        });

        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'MOVE_FOCUS_ACTION_PREVIOUS').subscribe(e => {
            if (this.isBlockFocus && this.focusActionIndex > 0) {
                this.focusActionIndex--;
            }
        });

        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'TRIGGER_ACTION').subscribe(e => {
            if (this.isBlockFocus) {
                this.launchPad.triggerAction();
            }
        });
    }

    getUserCriteria() {
        this.dataStore.set(DataKey.userSavedSearchCriteriaListV2, null, true);
        if (this.user) {
            //For V2
            this.reservationV2ManagementService.getUserSearchCriteriaList(this.user.id).subscribe(result => {
                if (result) {
                    this.userSavedSearchCriteriaListV2 = Object.values(ResponseUtil.getDataArray<SavedCriteriaV2>(result))
                                                             .sort();

                    this.dataStore.set(DataKey.userSavedSearchCriteriaListV2, this.userSavedSearchCriteriaListV2, true);
                    this.userSavedSearchCriteriaListV2 = this.processDynamicBlocks(
                        this.userSavedSearchCriteriaListV2.slice(-this.maxNoOfVisibleCriteria), this.userCriteriaBlockIndex);
                }
            });
        }
    }

    getSystemDefinedCriteria() {
        this.dataStore.set(DataKey.systemDefinedCriteriaV2, null, true);

        //V2 - system saved criteria currently not using
        // this.reservationV2ManagementService.getUserSearchCriteriaList(this.systemId).subscribe(result => {
        //     if (result) {
        //         this.definedSearchCriteriaListV2 = Object.values(ResponseUtil.getDataArray<SavedCriteriaV2>(result)).sort();
        //         this.dataStore.set(DataKey.systemDefinedCriteriaV2, this.definedSearchCriteriaListV2, true);
        //         this.definedSearchCriteriaListV2 = this.processDynamicBlocks(this.definedSearchCriteriaListV2, 2);
        //     }
        // });
    }

    processQuickLinks(criteriaList: any[], i: number): any[] {
        if (this.blocks[i] && this.blocks[i].actionHandler !== '') {
            for (let j = 0; j < criteriaList.length; j++) {
                if (j <= this.defaultCriteriaCount) {
                    this.blocks[i].actions[j].primaryText = criteriaList[j].name;
                    this.blocks[i].actions[j].actionRef = this.SAVED_CRITERIA;
                }
            }
        }
        return criteriaList;
    }

    processDynamicBlocks(criteriaList: any[], i: number): any[] {
        if (this.blocks[i] && this.blocks[i].actionHandler !== '') {
            this.blocks[i].actions = new Array();
            let tAction: Action;
            for (const data of criteriaList) {
                if (this.blocks[i].actions.length === this.defaultCriteriaCount) {
                    break;
                }
                tAction = new Action(this.blocks[i].defaultAction);
                tAction.primaryText = data.criteriaName;
                tAction.actionRef = this.SAVED_CRITERIA;
                this.blocks[i].actions.push(tAction);
            }
        }
        return criteriaList;
    }

    onActionClick(action: Action) {
        // todo handle this using journey manager framework

        //For V1
        // if (action.actionRef.startsWith(this.SAVED_CRITERIA)) {
        //     let qparams: Map<string, any> = new Map();
        //     let criteriaObj = this.getSelectedCriteriaV1(action.primaryText);
        //     if (criteriaObj.criteria.serviceType === 'GEN') {
        //         action.actionRef = this.GENERIC_QUEUE;
        //         this.rootService.setDataToLocalStorage(TCO.AppData.GEN_RESERVATION_SEARCH_CRITERIA,
        //             criteriaObj.criteria,
        //             true,
        //             null,
        //             true
        //         );
        //     } else {
        //         action.actionRef = this.TRANSPORT_QUEUE;
        //         this.rootService.setDataToLocalStorage(TCO.AppData.RESERVATION_SEARCH_CRITERIA,
        //             criteriaObj.criteria,
        //             true,
        //             null,
        //             true
        //         );
        //     }
        // }
        //For V2
        if (action.actionRef.startsWith(this.SAVED_CRITERIA)) {
            const qparams: Map<string, any> = new Map();
            const criteriaObj = this.getSelectedCriteriaV2(action.primaryText);
            action.actionRef = this.TRANSPORT_QUEUE;
            if (criteriaObj.criteria) {
                // set criteria parameters to url
                let queryParams: string[] = [];
                queryParams = this.queryParamService.getQueryParamsArray(criteriaObj.criteria);
                queryParams = queryParams.filter(val => val !== 'page');
                this.rootService.setDataToLocalStorage(
                    TCO.AppData.PRODUCT_QUEUE_SEARCH_CRITERIA_TRS,
                    criteriaObj.criteria,
                    true,
                    queryParams,
                    true
                );
            }
        }
        this.userJourneyManager.goForKey(action.actionRef);
        console.log('Success');
    }

    getSelectedCriteriaV1(actionText: string) {
        let criteria = this.userSavedSearchCriteriaListV1.find(data => data.name === actionText);
        if (!criteria) {
            criteria = this.definedSearchCriteriaListV1.find(data => data.name === actionText);
        }
        if (criteria.criteria.daysFrom) {
            criteria.criteria.date = new Date(+new Date() + criteria.criteria.daysFrom * 86400000).toISOString()
                                                                                                  .split('T')[0];
        }
        return criteria;
    }
    getSelectedCriteriaV2(actionText: string) {
        let criteria = this.userSavedSearchCriteriaListV2.find(data => data.criteriaName === actionText);
        if (!criteria) {
            criteria = this.definedSearchCriteriaListV2.find(data => data.criteriaName === actionText);
        }
        return criteria;
    }
}
