import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import {
    MatButtonModule,
    MatChipsModule,
    MatExpansionModule,
    MatIconModule,
    MatListModule,
    MatMenuModule,
    MatTabsModule,
    MatTooltipModule
} from '@angular/material';
import { MatCardModule } from '@angular/material/card';
import { TranslateModule } from '@ngx-translate/core';
import { ChipDropdownModule } from '@tc/chip-dropdown';
import { AgGridModule } from 'ag-grid-angular';
import { AgGridCustomDirectivesModule } from '../../../widgets/framework/ag-grid-custom-components/directives/ag-grid-custom-directives.module';
import { AgGridCustomEditorsModule } from '../../../widgets/framework/ag-grid-custom-components/editors/ag-grid-custom-editors.module';
import { AllocationSuggestionEditorComponent } from '../../../widgets/framework/ag-grid-custom-components/editors/allocation-suggestion-editor/allocation-suggestion-editor.component';
import { AutoCompleteEditorComponent } from '../../../widgets/framework/ag-grid-custom-components/editors/auto-complete-editor/auto-complete-editor.component';
import { DropdownEditorComponent } from '../../../widgets/framework/ag-grid-custom-components/editors/dropdown-editor/dropdown-editor.component';
import { MultiSelectorEditorComponent } from '../../../widgets/framework/ag-grid-custom-components/editors/multi-selector-editor/multi-selector-editor.component';
import { NoteEditorComponent } from '../../../widgets/framework/ag-grid-custom-components/editors/note-editor/note-editor.component';
import { TimeEditorComponent } from '../../../widgets/framework/ag-grid-custom-components/editors/time-editor/time-editor.component';
import { AgGridCustomFiltersModule } from '../../../widgets/framework/ag-grid-custom-components/filters/ag-grid-custom-filters.module';
import { AgGridCustomRenderersModule } from '../../../widgets/framework/ag-grid-custom-components/renderers/ag-grid-custom-renderers.module';
import { CheckBoxRendererEditorComponent } from '../../../widgets/framework/ag-grid-custom-components/renderers/check-box-selector-renderer/check-box-renderer-editor.component';
import { TemplateRendererComponent } from '../../../widgets/framework/ag-grid-custom-components/renderers/template-renderer/template-renderer.component';
import { TimeEditorRendererComponent } from '../../../widgets/framework/ag-grid-custom-components/renderers/time-editor-renderer/time-editor-renderer.component';
import { AllocationFocusViewComponent } from '../allocation-panel/allocation-focus-view/allocation-focus-view.component';
import { AllocationFocusViewModule } from '../allocation-panel/allocation-focus-view/allocation-focus-view.module';
import { NotesEditorModule } from '../notes-editor/notes-editor.module';
import { PassengerEditorModule } from '../passenger-editor/passenger-editor.module';
import { SupplementsEditorModule } from '../supplements-editor/supplements-editor.module';
import { TotalCostEditorModule } from '../total-cost-editor/total-cost-editor.module';
import { BookingQueueComponent } from './booking-queue/booking-queue.component';
import { OperationQueuePanelComponent } from './operation-queue-panel.component';
import { ProductQueueComponent } from './product-queue/product-queue.component';
import { ServiceQueueComponent } from './service-queue/service-queue.component';

@NgModule({
    declarations: [
        OperationQueuePanelComponent,
        ProductQueueComponent,
        BookingQueueComponent,
        ServiceQueueComponent
    ],
    imports: [
        CommonModule,
        AgGridCustomRenderersModule,
        AgGridCustomEditorsModule,
        AgGridCustomDirectivesModule,
        AgGridCustomFiltersModule,
        AgGridModule.withComponents([
            // renderer
            TemplateRendererComponent,

            // editors
            DropdownEditorComponent,
            AutoCompleteEditorComponent,
            NoteEditorComponent,
            TimeEditorComponent,
            TimeEditorRendererComponent,
            AllocationSuggestionEditorComponent,
            CheckBoxRendererEditorComponent,
            MultiSelectorEditorComponent,
        ]),
        MatExpansionModule,
        MatChipsModule,
        MatIconModule,
        MatButtonModule,
        MatTabsModule,
        ChipDropdownModule,
        MatMenuModule,
        MatTooltipModule,
        AllocationFocusViewModule,
        NotesEditorModule,
        PassengerEditorModule,
        MatListModule,
        MatCardModule,
        TotalCostEditorModule,
        TranslateModule,
        SupplementsEditorModule
    ],
    exports: [
        OperationQueuePanelComponent,
        ProductQueueComponent,
        BookingQueueComponent,
        ServiceQueueComponent
    ],
    entryComponents: [
        OperationQueuePanelComponent,
        ProductQueueComponent,
        BookingQueueComponent,
        ServiceQueueComponent,
        AllocationFocusViewComponent
    ],
    providers: [DatePipe]
})
export class OperationQueuePanelModule {}
