import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { Pager } from '@tc-core/model/it/codegen/ui/framework/pager';
import {Sorter} from '@tc-core/model/it/codegen/ui/framework/sorter';
import {SummaryCardData} from '@tc-core/model/it/codegen/ui/framework/summary-card-data';
import { ChangeDetectorService } from '../../../services/util/change-detector/change-detector.service';
import {ServiceItemChanges} from '../../../models/helper/service-item-changes';
import {ResourceAvailability} from '../../../models/resource/resource-availability';
import {SearchResultSummary} from '../../../models/reservation/search-result-summary';
import {AssignmentSummary} from '../../../models/assignment-summary';

@Component({
  selector: 'tc-adv-vehicle-assign-search-result',
  templateUrl: './adv-vehicle-assign-search-result.component.html'
})
export class AdvVehicleAssignSearchResultComponent implements OnInit {
    @Input() sorter: Sorter;
    @Input() sortingHandler: any;
    @Input() sorters: Sorter[] = [];

    @Input() resultsList: any[] = [];
    @Input() selectedVehicle: ResourceAvailability = null; // selected vehicle card from search results
    @Input() bookingData: any;
    @Input() bookingDataArray: any[];
    @Input() isBulkAssignment: boolean = false;
    @Input() assignedVehicle: any; //assigned vehicle from direct assignment or early assignments
    @Input() pager: Pager;
    @Input() itemGroup: SearchResultSummary;

    @Output() vehicleSelect: EventEmitter<any> = new EventEmitter();
    @Output() cardScroll: EventEmitter<number> = new EventEmitter<number>();
    @Output() actionClick: EventEmitter<any> = new EventEmitter();
    @Output() outsideSelect: EventEmitter<any> = new EventEmitter();
    @Output() headerClick: EventEmitter<any> = new EventEmitter();
    @Output() resourceCalendarClick: EventEmitter<any> = new EventEmitter();
    @Output() eventClick: EventEmitter<any> = new EventEmitter();
    @Output() targetClick: EventEmitter<any> = new EventEmitter();

    selectedCard: SummaryCardData;
    enableExpand: boolean = true;
    externalClicked = true;

    constructor(
        private changeDetector: ChangeDetectorService
    ) {
    }

    ngOnInit() {
    }

    public onVehicleSelection(summaryCardData: ResourceAvailability, index?: number) {
        this.selectedVehicle = summaryCardData;

        if (summaryCardData) {
            this.vehicleSelect.emit(this.selectedVehicle);
            this.cardScroll.emit(index);
        }
    }

    onActionClick(summaryCardData, event) {
        this.selectedVehicle = summaryCardData;

        if (summaryCardData) {
            this.actionClick.emit(event);
            this.vehicleSelect.emit(this.selectedVehicle);
        }
    }

    public onExpand(summaryCardData: SummaryCardData) {
        this.vehicleSelect.emit(summaryCardData);
    }

    public onHeaderClick(summaryCardData: SummaryCardData) {
        this.headerClick.emit(summaryCardData);
        this.selectedCard = summaryCardData;
        this.vehicleSelect.emit(summaryCardData);
        this.changeDetector.notifySupplierCostChange();

    }

    public onOutsideClick(summaryCardData: SummaryCardData) {
        this.selectedCard = summaryCardData;
        this.outsideSelect.emit(summaryCardData);
        this.vehicleSelect.emit(summaryCardData);
        this.changeDetector.notifySupplierCostChange();
    }

    public hasSelectedThis(vehicle: ResourceAvailability): boolean {
        if (this.assignedVehicle) {
            return (this.assignedVehicle.resourceId === vehicle.resourceId);
        } else {
            this.externalClicked = true;
            return false;
        }
    }

    onResourceCalendarClick(resource: any) {
        this.resourceCalendarClick.emit(resource);
    }
    onEventClick(event: AssignmentSummary, resource: ResourceAvailability){
        this.enableExpand = false;
        event.tobeSelected = true;
        this.eventClick.emit(resource);
    }

    onTargetClick(target: any){
        this.enableExpand = false;
        this.targetClick.emit(target);
    }
}
