import { Component, Input, OnInit } from '@angular/core';
import { TC } from '@tc-core/util/constants';
import { TaxScheme } from '../../../models/supplier/tax-scheme';

@Component({
    selector: 'tc-tax-schemes-card',
    templateUrl: './tax-schemes-card.component.html'
})
export class TaxSchemesCardComponent implements OnInit {
    EMPTY_CHAR = TC.FORMAT.EMPTY_CHAR;

    @Input() taxSchemes: TaxScheme[];
    @Input() showTitle: boolean = true;

    constructor() {
    }

    ngOnInit() {
    }

}
