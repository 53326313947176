/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./adv-room-search-results.component";
var styles_AdvRoomSearchResultsComponent = [];
var RenderType_AdvRoomSearchResultsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AdvRoomSearchResultsComponent, data: {} });
export { RenderType_AdvRoomSearchResultsComponent as RenderType_AdvRoomSearchResultsComponent };
export function View_AdvRoomSearchResultsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "p", [], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = "adv-room-search-results works!"; _ck(_v, 0, 0, currVal_0); }); }
export function View_AdvRoomSearchResultsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-adv-room-search-results", [], null, null, null, View_AdvRoomSearchResultsComponent_0, RenderType_AdvRoomSearchResultsComponent)), i0.ɵdid(1, 114688, null, 0, i1.AdvRoomSearchResultsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AdvRoomSearchResultsComponentNgFactory = i0.ɵccf("tc-adv-room-search-results", i1.AdvRoomSearchResultsComponent, View_AdvRoomSearchResultsComponent_Host_0, {}, {}, []);
export { AdvRoomSearchResultsComponentNgFactory as AdvRoomSearchResultsComponentNgFactory };
