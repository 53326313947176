<form [formGroup]="genBookingItemForm">
    <div class="container-fluid direct-client-contact-details-edit tc-card-wrapper">
        <div class="row" style="border-bottom: 1px solid #eee;">
            <div class="col-md-12 col-lg-12">
                <div class="tc-flex-box">
                    <div class="tc-flex-item tc-item-circle">
                        <div class="icon-box">
                            <i class="material-icons" aria-hidden="true">format_list_bulleted</i>
                        </div>
                    </div>
                    <div class="tc-flex-item tc-right-cage">
                        <div class="row">
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                <div class="tc-select-edit dcpiec-select-title">
                                    <mat-form-field>
                                        <input
                                                matInput
                                                formControlName="itemName"
                                                [placeholder]="'Item Name'|translate"
                                                required>
                                        <mat-error
                                                class="mat-error"
                                                role="alert">{{getErrorMessage('itemName')|translate}}</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                <div class="tc-input-edit dcpiec-input-format">
                                    <mat-form-field>
                                        <input
                                                matInput
                                                formControlName="contractReference"
                                                [placeholder]="'Contract Reference'|translate"
                                                required>
                                        <mat-error
                                                class="mat-error"
                                                role="alert">{{getErrorMessage('contractReference')|translate}}</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                <div class="tc-input-edit dcpiec-input-dispatchMethod">
                                    <mat-form-field>
                                        <input
                                                matInput
                                                formControlName="plCode"
                                                [placeholder]="'PL Code'|translate"
                                                required>
                                        <mat-error
                                                class="mat-error"
                                                role="alert">{{getErrorMessage('plCode')|translate}}</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                <div class="tc-input-edit dcpiec-input-dispatchMethod">
                                    <mat-form-field>
                                        <input
                                                matInput
                                                #trsModeInput
                                                formControlName="defaultSupplier"
                                                [matAutocomplete]="supAutoComplete"
                                                (focus)="onSupplierFocus($event)"
                                                (focusout)="onSupplierFocusOut($event)"
                                                [placeholder]="'Default Supplier'|translate">
                                        <mat-error
                                                class="mat-error"
                                                role="alert">{{getErrorMessage('defaultSupplier')|translate}}</mat-error>
                                    </mat-form-field>
                                    <mat-autocomplete
                                        #supAutoComplete="matAutocomplete"
                                        [displayWith]="displaySupplierFn">
                                        <mat-option
                                                *ngFor="let sup of suppliersFiltered"
                                                [value]="sup"
                                                (onSelectionChange)="onSelectSupplier(sup)">
                                            {{ sup?.name }}
                                        </mat-option>
                                    </mat-autocomplete>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                <div class="tc-input-edit dcpiec-input-frequency">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                <div class="tc-input-edit dcpiec-input-frequency">
                                    <mat-form-field>
                                        <input
                                                type="number"
                                                matInput
                                                formControlName="occAdultCount"
                                                [placeholder]="'Adult Count'|translate"
                                                required
                                        >
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                <div class="tc-input-edit dcpiec-input-frequency">
                                    <mat-form-field>
                                        <input
                                                type="number"
                                                matInput
                                                formControlName="occTeenCount"
                                                [placeholder]="'Teen Count'|translate"
                                        >
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                <div class="tc-input-edit dcpiec-input-frequency">
                                    <mat-form-field>
                                        <input
                                                type="number"
                                                matInput
                                                formControlName="occChildCount"
                                                [placeholder]="'Child Count'|translate"
                                        >
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                <div class="tc-input-edit dcpiec-input-frequency">
                                    <mat-form-field>
                                        <input
                                                type="number"
                                                matInput
                                                formControlName="occInfantCount"
                                                [placeholder]="'Infant Count'|translate"

                                        >
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                <div class="tc-select-edit dcpiec-select-title">
                                    <mat-form-field>
                                        <input
                                                matInput [ngxMatDatetimePicker]="pickerStart"
                                                [placeholder]="'Start Date'|translate"
                                                formControlName="startDate"
                                                [min]="today"
                                                (dateChange)="onStartDateChange()"
                                                required>
                                        <mat-datepicker-toggle matSuffix [for]="pickerStart">
                                        </mat-datepicker-toggle>
                                        <ngx-mat-datetime-picker
                                            #pickerStart
                                            [showSpinners]="true"
                                            [showSeconds]="false"
                                            [stepHour]="1"
                                            [stepMinute]="1"
                                            [stepSecond]="1"
                                            [touchUi]="false"
                                            [color]="dateTimePickerColor">
                                        </ngx-mat-datetime-picker>
                                        <mat-error
                                                class="mat-error"
                                                role="alert">{{getErrorMessage('startDate')|translate}}</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                <div class="tc-input-edit dcpiec-input-format">
                                    <mat-form-field>
                                        <input
                                                matInput [ngxMatDatetimePicker]="pickerEnd"
                                                [placeholder]="'End Date'|translate"
                                                formControlName="endDate"
                                                [min]="startTime ? startTime : today"
                                                (dateChange)="onEndDateChange()"
                                                required>
                                        <mat-datepicker-toggle matSuffix [for]="pickerEnd">
                                        </mat-datepicker-toggle>
                                        <ngx-mat-datetime-picker
                                            #pickerEnd
                                            [showSpinners]="true"
                                            [showSeconds]="false"
                                            [stepHour]="1"
                                            [stepMinute]="1"
                                            [stepSecond]="1"
                                            [touchUi]="false"
                                            [color]="dateTimePickerColor">
                                        </ngx-mat-datetime-picker>
                                        <mat-error
                                                class="mat-error"
                                                role="alert">{{getErrorMessage('endDate')|translate}}</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                            </div>
                            <div
                                    class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile"
                                    *ngIf="sameDateTypeItems && sameDateTypeItems.length > 0">
                                <div class="tc-input-edit dcpiec-input-lname">
                                    <mat-checkbox
                                            formControlName="passengerValidated"
                                            (ngModelChange)="onChangeCheckBox($event)">
                                        {{'Validate Passengers' |translate}}
                                    </mat-checkbox>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
<!--        generic item specific fields-->
        <div class="row" style="border-bottom: 2px solid #eee;">
            <div class="col-md-12 col-lg-12">
                <div class="tc-flex-box">
                    <div class="tc-flex-item tc-item-circle">
                        <div class="icon-box">
                            <i class="material-icons" aria-hidden="true">work</i>
                        </div>
                    </div>
                    <div class="tc-flex-item tc-right-cage">
                        <div class="row">
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                <div class="tc-select-edit dcpiec-select-title">
                                    <!--                  <mat-form-field>-->
                                    <!--                    <input-->
                                    <!--                            matInput-->
                                    <!--                            formControlName="elementGroup"-->
                                    <!--                            [placeholder]="'Element Group'|translate"-->
                                    <!--                            required>-->
                                    <!--                    <mat-error-->
                                    <!--                            class="mat-error"-->
                                    <!--                            role="alert">{{getErrorMessage('productName')|translate}}</mat-error>-->
                                    <!--                  </mat-form-field>-->

                                    <mat-form-field>
                                        <input
                                                matInput
                                                #trsModeInput
                                                formControlName="elementGroup"
                                                [matAutocomplete]="resAutoComplete"
                                                (focus)="onElementGrpFocus($event)"
                                                (focusout)="onElementGrpFocusOut($event)"
                                                [placeholder]="'Element Group'|translate">
                                        <mat-error
                                                class="mat-error"
                                                role="alert">{{getErrorMessage('elementGroup')|translate}}</mat-error>
                                    </mat-form-field>
                                    <mat-autocomplete
                                        #resAutoComplete="matAutocomplete"
                                        [displayWith]="displayElementGrpFn">
                                        <mat-option
                                                *ngFor="let res of resourceTypesFiltered"
                                                [value]="res"
                                                (onSelectionChange)="onSelectElementGrp(res)">
                                            {{ res?.name }}
                                        </mat-option>
                                    </mat-autocomplete>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                <div class="tc-input-edit dcpiec-input-format">
                                    <!--                  <mat-form-field>-->
                                    <!--                    <input-->
                                    <!--                            matInput-->
                                    <!--                            formControlName="typeName"-->
                                    <!--                            [placeholder]="'Type'|translate">-->
                                    <!--                    <mat-error-->
                                    <!--                            class="mat-error"-->
                                    <!--                            role="alert">{{getErrorMessage('typeName')|translate}}</mat-error>-->
                                    <!--                  </mat-form-field>-->
                                    <mat-form-field>
                                        <input
                                                matInput
                                                #trsModeInput
                                                formControlName="typeName"
                                                [matAutocomplete]="typeAutoComplete"
                                                (focus)="onTypeFocus($event)"
                                                (focusout)="onTypeFocusOut($event)"
                                                [placeholder]="'Type'|translate">
                                        <mat-error
                                                class="mat-error"
                                                role="alert">{{getErrorMessage('typeName')|translate}}</mat-error>
                                    </mat-form-field>
                                    <mat-autocomplete
                                        #typeAutoComplete="matAutocomplete"
                                        [displayWith]="displayTypeFn">
                                        <mat-option
                                                *ngFor="let type of profileTypesFiltered"
                                                [value]="type"
                                                (onSelectionChange)="onSelectType(type)">
                                            {{ type?.name }}
                                        </mat-option>
                                    </mat-autocomplete>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                <div class="tc-input-edit dcpiec-input-frequency">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <tc-item-notes
                [readonly]="readonly"
                [noteList]="bookingItem.itemNotes"
                [heading]="'Notes'"
                (changeValidity)="onNotesChange($event)"
                class="bdv-booking-notes-summary-wrapper">
        </tc-item-notes>
<!--        <tc-booking-item-notes-->
<!--                [bookingItem]="bookingItem"-->
<!--                [noteTypes]="noteTypes"-->
<!--                [readonly]="readonly"-->
<!--                (changeValidity)="onNotesChange($event)">-->
<!--        </tc-booking-item-notes>-->
    </div>
</form>
