import * as tslib_1 from "tslib";
import { EventEmitter, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { SummaryCardData } from '@tc-core/model/it/codegen/ui/framework/summary-card-data';
import { ServiceAssignment } from '../../../models/reservation/service-assignment';
import { ReservationStatus } from '../../../models/reservation/service-item';
import { ServiceAssignmentUtilService } from '../../../services/business-utils/service-item-utils/service-assignment-util.service';
var ReservationServiceItemsComponent = /** @class */ (function () {
    function ReservationServiceItemsComponent() {
        this.contentCardDataList = [];
        this.checkList = null;
        this.supplierList = [];
        this.resourceList = [];
        this.serviceItemChangesInSelectedGroup = new Map();
        this.clickConfirm = new EventEmitter();
        this.clickSupplierReject = new EventEmitter();
        this.clickMerge = new EventEmitter();
        this.saveAssignments = new EventEmitter();
        this.bulkVehicleAssignment = new EventEmitter();
        this.bulkDriverAssignment = new EventEmitter();
        this.moreClick = new EventEmitter();
        this.historyClick = new EventEmitter();
        this.splitClick = new EventEmitter();
        this.leadPassengerClick = new EventEmitter();
        this.vehicleSupplierAssign = new EventEmitter();
        this.driverSupplierAssign = new EventEmitter();
        this.vehicleAssign = new EventEmitter();
        this.driverAssign = new EventEmitter();
        this.vehicleSupplierClear = new EventEmitter();
        this.driverSupplierClear = new EventEmitter();
        this.vehicleClear = new EventEmitter();
        this.driverClear = new EventEmitter();
        this.vehicleSupplierTextChange = new EventEmitter();
        this.driverSupplierTextChange = new EventEmitter();
        this.vehicleTextChange = new EventEmitter();
        this.driverTextChange = new EventEmitter();
        this.vehicleJobNoChange = new EventEmitter();
        this.driverJobNoChange = new EventEmitter();
        this.clearVehicleAssignment = new EventEmitter();
        this.clearDriverAssignment = new EventEmitter();
        this.autoAssignResources = new EventEmitter();
        this.isSavedChanges = new EventEmitter();
        this.vehicleTextFocus = new EventEmitter();
        this.viewMoreFocus = new EventEmitter();
        this.selectedAll = false;
        this.isSaved = true;
        this.isAutoAssignEnabled = false;
    }
    ReservationServiceItemsComponent.prototype.ngOnInit = function () {
        // console.log(this.contentCardDataList);
        this.initCheckList();
    };
    ReservationServiceItemsComponent.prototype.onServiceItemSelection = function (serviceItemCard, i) {
        // console.log(serviceItemCard);
    };
    ReservationServiceItemsComponent.prototype.onActionClick = function (serviceItemCard, $event) {
        // console.log(serviceItemCard);
    };
    ReservationServiceItemsComponent.prototype.initCheckList = function () {
        var _this = this;
        this.checkList = [];
        this.contentCardDataList.forEach(function (card) {
            _this.checkList.push(false);
        });
    };
    ReservationServiceItemsComponent.prototype.checkAllChange = function ($event) {
        this.serviceItemChangesInSelectedGroup.forEach(function (value, key) {
            if (!ReservationServiceItemsComponent.isRestricted(value)) {
                value.checked = $event.checked;
            }
        });
    };
    ReservationServiceItemsComponent.prototype.onSelectVehicleSupplier = function ($event) {
        this.vehicleSupplierAssign.emit($event);
    };
    ReservationServiceItemsComponent.prototype.onSelectDriverSupplier = function ($event) {
        this.driverSupplierAssign.emit($event);
    };
    ReservationServiceItemsComponent.prototype.onSelectVehicle = function ($event) {
        console.log($event);
        console.log(this.filteredVehicles);
        this.vehicleAssign.emit($event);
    };
    ReservationServiceItemsComponent.prototype.onSelectDriver = function ($event) {
        this.driverAssign.emit($event);
    };
    ReservationServiceItemsComponent.prototype.clearVehicleSupplier = function ($event) {
        this.vehicleSupplierClear.emit($event);
    };
    ReservationServiceItemsComponent.prototype.clearDriverSupplier = function ($event) {
        this.driverSupplierClear.emit($event);
    };
    ReservationServiceItemsComponent.prototype.clearVehicle = function ($event) {
        this.vehicleClear.emit($event);
    };
    ReservationServiceItemsComponent.prototype.clearDriver = function ($event) {
        this.driverClear.emit($event);
    };
    ReservationServiceItemsComponent.prototype.onVehicleSupplierTextChange = function ($event) {
        this.vehicleSupplierTextChange.emit($event);
    };
    ReservationServiceItemsComponent.prototype.onDriverSupplierTextChange = function ($event) {
        this.driverSupplierTextChange.emit($event);
    };
    ReservationServiceItemsComponent.prototype.onAutoAssignResources = function () {
        this.isAutoAssignEnabled = true;
        this.autoAssignResources.emit(this.summaryCardData);
    };
    ReservationServiceItemsComponent.prototype.onVehicleTextChange = function ($event) {
        this.vehicleTextChange.emit($event);
    };
    ReservationServiceItemsComponent.prototype.onDriverTextChange = function ($event) {
        this.driverTextChange.emit($event);
    };
    ReservationServiceItemsComponent.prototype.onVehicleFocus = function ($event) {
        this.vehicleTextFocus.emit($event);
    };
    ReservationServiceItemsComponent.prototype.onVehicleJobNoChange = function ($event) {
        this.vehicleJobNoChange.emit($event);
    };
    ReservationServiceItemsComponent.prototype.onDriverJobNoChange = function ($event) {
        this.driverJobNoChange.emit($event);
    };
    ReservationServiceItemsComponent.prototype.onClearVehicleAssignment = function ($event) {
        this.clearVehicleAssignment.emit($event);
    };
    ReservationServiceItemsComponent.prototype.onClearDriverAssignment = function ($event) {
        this.clearDriverAssignment.emit($event);
    };
    ReservationServiceItemsComponent.prototype.clickOutside = function () {
        console.log('outside click');
    };
    ReservationServiceItemsComponent.prototype.isPrivate = function (serviceItemCard) {
        if (serviceItemCard && serviceItemCard.data.value && serviceItemCard.data.value['private'] !== undefined) {
            return serviceItemCard.data.value['private'];
        }
        else {
            return false;
        }
    };
    ReservationServiceItemsComponent.prototype.isSplit = function (serviceItemCard) {
        if (serviceItemCard && serviceItemCard.data.value && serviceItemCard.data.value['split'] !== undefined &&
            serviceItemCard.data.value['split']) {
            return true;
        }
        else {
            return false;
        }
    };
    ReservationServiceItemsComponent.prototype.onClickConfirm = function () {
        this.clickConfirm.emit();
    };
    ReservationServiceItemsComponent.prototype.onClickSupplierReject = function () {
        this.clickSupplierReject.emit();
    };
    ReservationServiceItemsComponent.prototype.onClickMerge = function () {
        this.clickMerge.emit();
    };
    ReservationServiceItemsComponent.prototype.onSaveAssignments = function () {
        this.saveAssignments.emit();
        this.isSaved = true;
    };
    ReservationServiceItemsComponent.prototype.onMoreClick = function (event, serviceItemCard) {
        this.moreClick.emit({ event: event, item: serviceItemCard });
    };
    ReservationServiceItemsComponent.prototype.onSplitClick = function (data) {
        this.splitClick.emit(data);
    };
    ReservationServiceItemsComponent.prototype.onLeadPassengerSelect = function (serviceItemCard) {
        this.leadPassengerClick.emit(serviceItemCard);
    };
    ReservationServiceItemsComponent.prototype.onItemCheckChanged = function ($event) {
        console.log($event);
        if ($event === false) {
            this.selectedAll = false;
        }
    };
    ReservationServiceItemsComponent.prototype.isDisabledMerge = function () {
        var _this = this;
        // if(this.isRequestCompleted){
        var disable = true;
        var firstParentId = 0;
        var parentsMatch = true;
        var checkedCount = 0;
        var hasAssignments = false;
        var statusOk = true;
        this.serviceItemChangesInSelectedGroup.forEach(function (value) {
            if (value.checked) {
                checkedCount++;
                if (checkedCount == 1) {
                    firstParentId = value.firstParentId; // first item's parent assigned as parentId
                }
                else if (firstParentId === 0 || value.firstParentId === 0 || firstParentId !== value.firstParentId) {
                    parentsMatch = false;
                }
                if (value.serviceAssignments) {
                    var serviceAssignmentList_1 = [];
                    _this.serviceItemChangesInSelectedGroup.get(value.serviceItemId).serviceAssignments.forEach(function (value) { return serviceAssignmentList_1.push(value); });
                    if (ServiceAssignmentUtilService.isAssignedAny(serviceAssignmentList_1)) {
                        hasAssignments = true;
                    }
                }
                if (ReservationServiceItemsComponent.isRestricted(value)) {
                    statusOk = false;
                }
            }
        });
        if (checkedCount > 1 && !hasAssignments && parentsMatch && statusOk) {
            disable = false;
        }
        return disable;
    };
    ReservationServiceItemsComponent.isRestricted = function (value) {
        var e_1, _a;
        var restrict = false;
        if (value.reservationStatus === ReservationStatus.UPDATED || value.reservationStatus ===
            ReservationStatus.CANCELLED) {
            return true;
        }
        if (value.serviceAssignments) {
            var serviceAssignmentList_3 = [];
            value.serviceAssignments.forEach(function (value) { return serviceAssignmentList_3.push(value); });
            try {
                for (var serviceAssignmentList_2 = tslib_1.__values(serviceAssignmentList_3), serviceAssignmentList_2_1 = serviceAssignmentList_2.next(); !serviceAssignmentList_2_1.done; serviceAssignmentList_2_1 = serviceAssignmentList_2.next()) {
                    var data = serviceAssignmentList_2_1.value;
                    if (ServiceAssignment.isRestrictedEdit(data)) {
                        restrict = true;
                        break;
                    }
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (serviceAssignmentList_2_1 && !serviceAssignmentList_2_1.done && (_a = serviceAssignmentList_2.return)) _a.call(serviceAssignmentList_2);
                }
                finally { if (e_1) throw e_1.error; }
            }
        }
        return restrict;
    };
    ReservationServiceItemsComponent.prototype.isDisabledAssign = function () {
        var disable = true;
        var checkedCount = 0;
        var statusOk = true;
        var privateItems = [];
        var sharedItems = [];
        this.serviceItemChangesInSelectedGroup.forEach(function (value) {
            if (value.checked) {
                if (value.isPrivate && !privateItems.includes(value.firstParentId)) {
                    privateItems.push(value.firstParentId);
                }
                else if (!sharedItems.includes(value.firstParentId)) {
                    sharedItems.push(value.firstParentId);
                }
                checkedCount++;
                if (ReservationServiceItemsComponent.isRestricted(value)) {
                    statusOk = false;
                }
            }
        });
        if (checkedCount >= 1 && statusOk && !(privateItems.length > 0 && sharedItems.length > 0) && privateItems.length < 2) {
            disable = false;
        }
        this.isAutoAssignEnabled = disable;
        return disable;
    };
    ReservationServiceItemsComponent.prototype.isDisabledAutoAssign = function () {
        var disable = false;
        this.serviceItemChangesInSelectedGroup.forEach(function (value) {
            if (ReservationServiceItemsComponent.isRestricted(value)) {
                disable = true;
            }
        });
        return disable;
    };
    ReservationServiceItemsComponent.prototype.isDisabledConfirm = function () {
        var _this = this;
        var disable = true;
        var checkedCount = 0;
        var statusOk = true;
        var invalidStatus = false;
        this.serviceItemChangesInSelectedGroup.forEach(function (value) {
            if (value.checked) {
                checkedCount++;
                var serviceAssignmentList_4 = [];
                _this.serviceItemChangesInSelectedGroup.get(value.serviceItemId).serviceAssignments.forEach(function (value) { return serviceAssignmentList_4.push(value); });
                if (!(value.serviceAssignments && ServiceAssignmentUtilService.isAssignedAny(serviceAssignmentList_4))) {
                    invalidStatus = true;
                }
                if (ReservationServiceItemsComponent.isRestricted(value)) {
                    statusOk = false;
                }
            }
        });
        if (checkedCount > 0 && !invalidStatus && statusOk && !this.changesAvailableForServiceItems) {
            disable = false;
        }
        return disable;
    };
    ReservationServiceItemsComponent.prototype.isDisabledSupplierReject = function () {
        var _this = this;
        var disable = true;
        var isDMC = false;
        var checkedCount = 0;
        var invalidStatus = false;
        this.serviceItemChangesInSelectedGroup.forEach(function (value) {
            if (value.checked) {
                checkedCount++;
                var serviceAssignmentList_5 = [];
                _this.serviceItemChangesInSelectedGroup.get(value.serviceItemId).serviceAssignments.forEach(function (value) { return serviceAssignmentList_5.push(value); });
                isDMC = ServiceAssignmentUtilService.isSupplierConfirmedOrDmcConfirmedAll(serviceAssignmentList_5);
                if (!(value.serviceAssignments && ServiceAssignmentUtilService.isSupplierConfirmedAny(serviceAssignmentList_5))) {
                    invalidStatus = true;
                }
            }
        });
        if (isDMC) {
            disable = false;
        }
        if (checkedCount > 0 && !invalidStatus && !this.changesAvailableForServiceItems) {
            disable = false;
        }
        return disable;
    };
    ReservationServiceItemsComponent.prototype.isDisabledSave = function () {
        if (this.isRequestCompleted) {
            var disable = true;
            if (this.changesAvailableForServiceItems) {
                disable = false;
            }
            this.isSavedChanges.emit(disable);
            return disable;
        }
        else {
            this.isSavedChanges.emit(true);
            return true;
        }
    };
    ReservationServiceItemsComponent.prototype.onBulkVehicleAssignment = function () {
        this.bulkVehicleAssignment.emit();
    };
    ReservationServiceItemsComponent.prototype.onBulkDriverAssignment = function () {
        this.bulkDriverAssignment.emit();
    };
    ReservationServiceItemsComponent.prototype.ngOnChanges = function (changes) {
        for (var propName in changes) {
            if (propName === 'contentCardDataList') {
                this.selectedAll = false;
            }
        }
    };
    ReservationServiceItemsComponent.prototype.onViewMoreClick = function (serviceItem) {
        this.viewMoreFocus.emit(serviceItem);
    };
    ReservationServiceItemsComponent.prototype.isDisabledCheckbox = function () {
        var disable = true;
        var statusOk = 0;
        this.serviceItemChangesInSelectedGroup.forEach(function (value) {
            if (ReservationServiceItemsComponent.isRestricted(value)) {
                statusOk++;
            }
        });
        if (statusOk !== this.serviceItemChangesInSelectedGroup.size) {
            disable = false;
        }
        return disable;
    };
    ReservationServiceItemsComponent.prototype.onHistoryClick = function (event) {
        this.historyClick.emit(event);
    };
    return ReservationServiceItemsComponent;
}());
export { ReservationServiceItemsComponent };
