import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
    MatDatepickerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatOptionModule, MatSelectModule
} from '@angular/material';
import { LeaveSetupEditCardComponent } from './leave-setup-edit-card.component';

@NgModule({
  declarations: [LeaveSetupEditCardComponent],
  imports: [
      CommonModule,
      ReactiveFormsModule,
      FormsModule,
      MatFormFieldModule,
      MatInputModule,
      MatDatepickerModule,
      MatSelectModule,
      MatOptionModule,
      MatIconModule
  ],
    exports: [LeaveSetupEditCardComponent]
})
export class LeaveSetupEditCardModule { }
