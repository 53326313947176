import * as tslib_1 from "tslib";
import { KeyControlAndAbstractSearchCriteria } from '../../criteria/key-control-and-abstract-search-criteria';
var BookingSearchCriteria = /** @class */ (function (_super) {
    tslib_1.__extends(BookingSearchCriteria, _super);
    function BookingSearchCriteria() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.bookingId = '';
        _this.source = '';
        _this.startDate = '';
        _this.endDate = '';
        _this.bookingStartDate = '';
        _this.bookingEndDate = '';
        _this.bookingSourceItemNo = null;
        _this.mainType = null;
        return _this;
    }
    return BookingSearchCriteria;
}(KeyControlAndAbstractSearchCriteria));
export { BookingSearchCriteria };
