<div class="" *ngIf="resultsList">

    <ng-container
            *ngFor="let client of resultsList  | tcAdvPager:pager.currPage:pager.itemsPerPage | tcAdvSorter:sortingHandler:sorter; let i = index;">

        <div class="">
            <div [ngClass]="hasSelectedThis(client)?'tc-expanded-content-card--selected':''">

                <tc-content-strip
                        (actionClick)="onActionClick(client,$event)"
                        (click)="onDriverSelection(client,i)">

                    <!--<tc-result-content-card-resource-assign-->
                            <!--[summaryContent]="client"-->
                            <!--[isRow1col4String]="true">-->
                    <!--</tc-result-content-card-resource-assign>-->

                    <tc-resource-allocation-card-gen
                            [itemGroup]="itemGroup"
                            [bookingData]="bookingData"
                            [bookingDataArray]="bookingDataArray"
                            [summaryContent]="client"
                            [isBulkAssignment]="isBulkAssignment"
                            (resourceCalendarClick)="onResourceCalendarClick($event)"
                            (eventClick)="onEventClick($event, client)"
                            (targetClick)="onTargetClick($event)">
                    </tc-resource-allocation-card-gen>

                </tc-content-strip>
            </div>
        </div>
    </ng-container>
</div>
