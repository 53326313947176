import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DocumentData } from '../../../models/profile/document';
import { SummaryCardData } from '@tc-core/model/it/codegen/ui/framework/summary-card-data';
import { ProfileManagementService } from '../../../services/backend-consumers/supplier-service/profile-management.service';
import { DataKey, DataStoreService } from '../../../services/util/framework/data-store.service';

@Component({
    selector: 'tc-document-edit-card',
    templateUrl: './document-edit-card.component.html'
})
export class DocumentEditCardComponent implements OnInit {

    @Input() document: DocumentData;
    @Input() formatOptions: any[];
    @Input() dispatchTypes: any[];
    @Input() frequencyOptions: any[];
    @Input() contactCategoryOptions: any[];
    @Input() documentContent: SummaryCardData;

    @Output() changeFormValidity: EventEmitter<boolean> = new EventEmitter();

    documentInfoForm: FormGroup;

    constructor(
        private profileManagementService: ProfileManagementService,
        private fb: FormBuilder,
        private dataStore: DataStoreService
    ) {
    }

    ngOnInit() {
        this.createForm();
        this.documentInfoForm.valueChanges.subscribe(
            (data) => {
                if (JSON.stringify(data) !== JSON.stringify({})) {
                    this.document.template = data.template;
                    this.document.contactCategory = data.contactCategory;
                    this.document.freqParam2 = data.freqParam2;
                    this.document.freqParam1 = data.freqParam1;
                    this.document.frequency = data.frequency;
                    this.document.format = data.format;
                    this.document.dispatchMethod = data.dispatchMethod;
                }
                this.changeFormValidity.emit(this.documentInfoForm.valid);
                if (this.changeFormValidity) {
                    if (this.document['id']) {
                        this.dataStore.updateArray(DataKey.documentData, this.document, 'id', true);
                    } else {
                        this.dataStore.updateArray(DataKey.documentData, this.document, 'tempId', true);
                    }
                }
            }
        );
    }

    private createForm() {
        this.documentInfoForm = this.fb.group({
            template: [this.document ? this.document.template : ''],
            format: [this.document ? this.document.format : ''],
            dispatchMethod: [this.document ? this.document.dispatchMethod : ''],
            frequency: [this.document ? this.document.frequency : ''],
            freqParam1: [this.document ? this.document.freqParam1 : ''],
            freqParam2: [this.document ? this.document.freqParam2 : ''],
            contactCategory: [this.document ? this.document.contactCategory : '']
        });
    }

}
