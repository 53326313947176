import * as tslib_1 from "tslib";
import { ElementRef, EventEmitter, OnInit } from '@angular/core';
import * as moment from 'moment';
var TimelineComponent = /** @class */ (function () {
    function TimelineComponent() {
        this.isTargetBlock = false;
        this.targetPointTime = new Date();
        this.targetBlockTimeStart = null;
        this.targetBlockTimeEnd = null;
        // @Input() events: TimelineEvent[] = [];
        this.eventsData = [];
        // leftBufferHoursInput = -1;
        // leftBufferUnits = -1;
        //
        // @Input('leftBufferHours')
        // set leftBufferHours(h: number) {
        //     this.leftBufferHoursInput = h;
        //     this.leftBufferUnits = this.leftBufferHoursInput / this.unitSize;
        //     // this.initTimeline();
        // }
        //
        // rightBufferHoursInput = -1;
        // rightBufferUnits = -1;
        //
        // @Input('rightBufferHours')
        // set rightBufferHours(h: number) {
        //     this.rightBufferHoursInput = h;
        //     this.rightBufferUnits = this.rightBufferHoursInput / this.unitSize;
        //     // this.initTimeline();
        // }
        this.leftBufferHours = -1;
        this.rightBufferHours = -1;
        this.leftBufferUnits = -1;
        this.rightBufferUnits = -1;
        this.eventClick = new EventEmitter();
        this.targetClick = new EventEmitter();
        this.unitWidth = 0;
        this.unitSize = 0.5;
        this.targetUnitIndex = 0;
        this.targetEndIndex = 0;
        this.targetUnitCount = 0;
        this.targetMargin = 0;
        this.showingUnitCount = -1;
        this.showingIndexes = [];
        this.minIndex = 0;
        this.maxIndex = 0;
    }
    Object.defineProperty(TimelineComponent.prototype, "events", {
        set: function (es) {
            this.eventsData = es;
            this.updateEventIndexes();
        },
        enumerable: true,
        configurable: true
    });
    TimelineComponent.prototype.ngOnInit = function () {
        this.leftBufferUnits = this.leftBufferHours / this.unitSize;
        this.rightBufferUnits = this.rightBufferHours / this.unitSize;
        this.initTimeline();
    };
    TimelineComponent.prototype.initTimeline = function () {
        this.createTargetIndex();
        this.createShowingIndexArray();
        this.updateEventIndexes();
        var timeLineWidth = this.timelinePart.nativeElement.offsetWidth;
        this.setUnitWidth(timeLineWidth);
    };
    TimelineComponent.prototype.createTargetIndex = function () {
        if (!this.isTargetBlock) {
            var decimal = this.timeToDecimal(this.targetPointTime);
            this.targetUnitIndex = Math.floor(decimal / this.unitSize);
            this.targetEndIndex = this.targetUnitIndex;
            this.targetMargin = (decimal % this.unitSize) / this.unitSize;
        }
        else {
            var decimalValues = this.calculateDecimalValues(this.targetBlockTimeStart, this.targetBlockTimeEnd);
            var decimalStart = decimalValues.decimalStart;
            var decimalEnd = decimalValues.decimalEnd;
            this.targetUnitIndex = Math.floor(decimalStart / this.unitSize);
            this.targetMargin = (decimalStart % this.unitSize) / this.unitSize;
            this.targetUnitCount = (decimalEnd - decimalStart) / this.unitSize;
            this.targetEndIndex = this.targetUnitIndex + Math.floor(this.targetUnitCount);
        }
    };
    TimelineComponent.prototype.updateEventIndexes = function () {
        var e_1, _a;
        try {
            for (var _b = tslib_1.__values(this.eventsData), _c = _b.next(); !_c.done; _c = _b.next()) {
                var event_1 = _c.value;
                var decimalValues = this.calculateDecimalValues(event_1.startTime, event_1.endTime);
                var decimalStart = decimalValues.decimalStart;
                var decimalEnd = decimalValues.decimalEnd;
                var waitingEndValue = this.calculateDecimalValues(event_1.startTime, event_1.waitingEndTime);
                var decimalWaitingEnd = waitingEndValue.decimalEnd;
                event_1.unitIndex = Math.floor(decimalStart / this.unitSize);
                event_1.margin = (decimalStart % this.unitSize) / this.unitSize;
                event_1.unitCount = (decimalEnd - decimalStart) / this.unitSize;
                var waitingDiff = decimalWaitingEnd - decimalStart;
                if (waitingDiff > 0) {
                    event_1.waitingUnitCount = waitingDiff / this.unitSize;
                }
                var dayDifference = this.compareDateWithTarget(event_1.startTime);
                event_1.unitIndex = event_1.unitIndex + dayDifference * (24 / this.unitSize);
                this.fixStartIndexOfEvent(event_1);
                this.fixEndIndexOfEvent(event_1);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
    };
    TimelineComponent.prototype.fixStartIndexOfEvent = function (event) {
        var unitDiff = event.unitIndex - this.minIndex;
        if (unitDiff < 0) {
            event.unitIndex = this.minIndex;
            event.unitCount = event.unitCount + unitDiff;
            event.unitCount += event.margin;
            event.margin = 0;
        }
    };
    TimelineComponent.prototype.fixEndIndexOfEvent = function (event) {
        var endIndex = event.unitIndex + event.unitCount - 1;
        var unitDiff = endIndex - this.maxIndex;
        if (unitDiff > 0) {
            event.unitCount = event.unitCount - unitDiff - event.margin;
        }
    };
    TimelineComponent.prototype.timeToDecimal = function (time) {
        var formatTime = moment(time).format('HH:mm');
        var hhAndMm = formatTime.split(':');
        var decimal = 0;
        if (hhAndMm && hhAndMm.length === 2) {
            var hh = parseInt(hhAndMm[0], 10);
            var mm = parseInt(hhAndMm[1], 10);
            decimal = hh + (mm / 60);
        }
        return decimal;
    };
    TimelineComponent.prototype.calculateDecimalValues = function (lowDate, highDate) {
        var dayDifference = moment(highDate).startOf('day').diff(moment(lowDate).startOf('day'), 'day');
        var decimalStart = this.timeToDecimal(lowDate);
        var decimalEnd = this.timeToDecimal(highDate);
        decimalEnd = decimalEnd + 24 * dayDifference;
        return {
            decimalStart: decimalStart,
            decimalEnd: decimalEnd
        };
    };
    TimelineComponent.prototype.compareDateWithTarget = function (date) {
        var compareWithTime = this.targetPointTime;
        if (this.isTargetBlock) {
            compareWithTime = this.targetBlockTimeStart;
        }
        return moment(date).startOf('day').diff(moment(compareWithTime).startOf('day'), 'day');
    };
    TimelineComponent.prototype.onResize = function (event) {
        var timeLineWidth = event.newWidth;
        this.setUnitWidth(timeLineWidth);
    };
    TimelineComponent.prototype.setUnitWidth = function (timeLineWidth) {
        if (this.showingUnitCount !== 0) {
            this.unitWidth = (timeLineWidth) / this.showingUnitCount;
        }
        else {
            this.unitWidth = 0;
        }
    };
    TimelineComponent.prototype.createShowingIndexArray = function () {
        var start = this.targetUnitIndex - this.leftBufferUnits;
        var end = this.targetEndIndex + this.rightBufferUnits;
        for (var step = start; step <= end; step++) {
            this.showingIndexes.push(step);
        }
        this.showingUnitCount = this.showingIndexes.length;
        this.minIndex = start;
        this.maxIndex = end;
    };
    TimelineComponent.prototype.onTargetClick = function () {
        this.targetClick.emit();
    };
    TimelineComponent.prototype.onEventClick = function (event) {
        this.eventClick.emit(event);
    };
    return TimelineComponent;
}());
export { TimelineComponent };
