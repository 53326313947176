export class Assignment {
    serviceItemId: number;
    assignmentId: number;
    assignDate: string;
    cost: number;
    dutyNo: number;
    vehicleId: number;
    resourceType: ResourceType;
    supplierId: number;
    unitCost: number;
    status: number;
    estimatedCost: number;
    driverId: number;
}

export enum ResourceType {
    supplier = 'supplier',
    vehicle = 'vehicle',
    driver = 'driver',
    cleared = 'cleared',
    guide = 'GUID',
    hotel = 'HTL',
    default = 'default',
    operator = 'operator',
}

