import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChipInputModule } from '@tc/chip-input';
import { AvailabilityCalendarSearchCriteriaComponent } from './availability-calendar-search-criteria.component';
import {ResourceAvailabilityService} from '../../services/backend-consumers/supplier-service/resource-availability.service';

@NgModule({
    declarations: [AvailabilityCalendarSearchCriteriaComponent],
    providers: [ResourceAvailabilityService],
    imports: [
        CommonModule,
        ChipInputModule
    ]
})
export class AvailabilityCalendarSearchCriteriaModule {}
