import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatExpansionModule} from '@angular/material';
import {TranslateModule} from '@ngx-translate/core';
import {PipesModule} from '@tc/pipes';
import {ExpandedContentCardBodyModule} from '@tc/expanded-content-card-body';
import {ContentStripModule} from '@tc/content-strip';
import {DirectivesModule} from '@tc/directives';
import {SummaryStripModule} from '@tc/summary-strip';
import {TaxSchemesComponent} from './tax-schemes.component';
import {ResultContentCardModule} from '../result-content-card/result-content-card.module';
import {TaxSchemeEditCardModule} from '../tax-scheme-edit-card/tax-scheme-edit-card.module';
import {TaxSchemeSummaryCardModule} from '../tax-scheme-summary-card/tax-scheme-summary-card.module';

@NgModule({
  imports: [
    CommonModule,
      TranslateModule.forChild(),
      PipesModule,
      ExpandedContentCardBodyModule,
      MatExpansionModule,
      ContentStripModule,
      DirectivesModule,
      SummaryStripModule,
      ResultContentCardModule,
      TaxSchemeSummaryCardModule,
      TaxSchemeEditCardModule
  ],
    exports: [TaxSchemesComponent],
    declarations: [TaxSchemesComponent]
})
export class TaxSchemesModule { }
