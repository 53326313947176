import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ChipInputModule } from '@tc/chip-input';
import { BookingManagementService } from '../../../services/backend-consumers/reservation-v2/booking-management.service';
import { BookingSearchCriteriaComponent } from './booking-search-criteria.component';

@NgModule({
    declarations: [BookingSearchCriteriaComponent],
    imports: [
        CommonModule,
        ChipInputModule
    ],
    providers: [
        BookingManagementService
    ],
    exports: [
        BookingSearchCriteriaComponent
    ],
    entryComponents: [
        BookingSearchCriteriaComponent
    ]
})
export class BookingSearchCriteriaModule {}
