import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'tc-pickup-dropoff-expanded-view',
  templateUrl: './pickup-dropoff-expanded-view.component.html'
})
export class PickupDropoffExpandedViewComponent implements OnInit {

  constructor() { }

  @Input() pickups:string[]=[];
  @Input() dropOffs:string[]=[];

  ngOnInit() {
  }

}
