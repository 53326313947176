import { ChangeDetectorRef, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ICellRendererParams } from 'ag-grid-community';
import { AutoCompleteEditorComponent } from '../../widgets/framework/ag-grid-custom-components/editors/auto-complete-editor/auto-complete-editor.component';
import { DateEditorComponent } from '../../widgets/framework/ag-grid-custom-components/editors/date-editor/date-editor.component';
import { DropdownEditorComponent } from '../../widgets/framework/ag-grid-custom-components/editors/dropdown-editor/dropdown-editor.component';
import { MultiSelectorEditorComponent } from '../../widgets/framework/ag-grid-custom-components/editors/multi-selector-editor/multi-selector-editor.component';
import { RadioButtonEditorComponent } from '../../widgets/framework/ag-grid-custom-components/editors/radio-button-editor/radio-button-editor.component';
import { CheckBoxRendererEditorComponent } from '../../widgets/framework/ag-grid-custom-components/renderers/check-box-selector-renderer/check-box-renderer-editor.component';
import { TemplateRendererComponent } from '../../widgets/framework/ag-grid-custom-components/renderers/template-renderer/template-renderer.component';
import { GridColumnDefinitionProcessorService } from '../../services/util/pre-processors/grid-column-definition-processor.service';
var PassengerListGridComponent = /** @class */ (function () {
    function PassengerListGridComponent(cd, columnDefinitionProcessorService, translateService) {
        var _this = this;
        this.cd = cd;
        this.columnDefinitionProcessorService = columnDefinitionProcessorService;
        this.translateService = translateService;
        this.passengerList = [];
        this.frameworkComponents = {
            'templateRenderer': TemplateRendererComponent,
            'checkBoxRendererEditor': CheckBoxRendererEditorComponent,
            'dropDownEditor': DropdownEditorComponent,
            'multiSelectorEditor': MultiSelectorEditorComponent,
            'radioButtonEditor': RadioButtonEditorComponent,
            'autoCompleteEditor': AutoCompleteEditorComponent,
            'dateEditor': DateEditorComponent
        };
        // ag grid value-getter
        this.getPassengerName = function (params) {
            return params.data.firstName + " " + params.data.lastName;
        };
        this.translateService.onLangChange.subscribe(function () {
            _this.gridApi.refreshHeader();
        });
        this.defaultColDef = {
            filter: false,
            sortable: false,
            resizable: false,
            headerValueGetter: function (parameters) {
                var headerIdentifier = parameters.colDef.headerName;
                if (headerIdentifier) {
                    return _this.translateService.instant(headerIdentifier);
                }
                return '';
            }
        };
        this.gridOptions = {
            rowSelection: false,
            cacheBlockSize: 20,
            maxBlocksInCache: 2,
            enableServerSideFilter: false,
            enableServerSideSorting: false,
            rowModelType: 'infinite',
            pagination: true,
            paginationPageSize: 5
        };
    }
    Object.defineProperty(PassengerListGridComponent.prototype, "colDefConfig", {
        set: function (colDef) {
            this._colDefConfig = colDef;
            this.setColumnDefs();
        },
        enumerable: true,
        configurable: true
    });
    PassengerListGridComponent.prototype.ngOnInit = function () {
    };
    PassengerListGridComponent.prototype.setColumnDefs = function () {
        var colDefConfig = JSON.parse(JSON.stringify(this._colDefConfig));
        var coldDefs = this.columnDefinitionProcessorService.createColumnDefs(colDefConfig, this);
        this.columnDefs = coldDefs;
        this.cd.detectChanges();
        if (this.gridApi) {
            this.gridApi.sizeColumnsToFit();
        }
    };
    PassengerListGridComponent.prototype.onGridReady = function ($event) {
        this.gridApi = $event.api;
        this.gridColumnApi = $event.columnApi;
        this.gridApi.setFloatingFiltersHeight(38);
        this.gridApi.setHeaderHeight(40);
        this.gridApi.sizeColumnsToFit();
    };
    return PassengerListGridComponent;
}());
export { PassengerListGridComponent };
