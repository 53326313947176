import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {ServiceResourceSetup} from '../../../models/master-data/service-resource-setup';
import { RoutesSetupService } from '../../../services/backend-consumers/deprecated/routes-setup.service';
import { DataStoreService } from '../../../services/util/framework/data-store.service';

@Component({
    selector: 'tc-service-resource-setup',
    templateUrl: './service-resource-setup.component.html'
})
export class ServiceResourceSetupComponent implements OnInit {
    resourceSetupForm: FormGroup;
    resourceInfor: FormArray;
    newSetup: ServiceResourceSetup[] = [];
    services: ServiceResourceSetup[];
    mainResources: string[] = [];
    otherResources: string[] = [];
    noOfCosts = 0;

    @Output() changeFormValidity: EventEmitter<boolean> = new EventEmitter();

    constructor(
        private fb: FormBuilder,
        private dataStore: DataStoreService,
        private routeService: RoutesSetupService
    ) {
        this.createForm();
    }

    ngOnInit() {
        this.resourceSetupForm.valueChanges.subscribe(
            (data) => {
                if (JSON.stringify(data) !== JSON.stringify({})) {
                    this.newSetup = data.setupData;
                    this.changeFormValidity.emit(this.resourceSetupForm.valid);
                }

            }
        );
        this.routeService.retrieveServices().subscribe(data => {
            this.services = data;
        });
    }

    createForm() {
        this.resourceSetupForm = this.fb.group({
            setupData: this.fb.array([this.createResource()])
        });
    }

    createResource(): FormGroup {
        /*if(this.routeInfor) {
            this.dataStore.set(DataKey.resourceCosts, this.routeInfor.controls.length, true);
        }*/
        return this.fb.group({
            serviceTypeCode: [''],
            serviceTypeName: [''],
            mainResource: [''],
            additionalResources: this.fb.array([])
        });
    }

    addRoute() {
        this.resourceInfor = this.resourceSetupForm.get('setupData') as FormArray;
        if (this.resourceSetupForm.valid) {
            this.resourceInfor.push(this.createResource());
        }
    }

    getResources(data: any) {
        this.mainResources = [];
        let selectedService = this.services.filter(val => val.serviceTypeCode === data.value);
        this.mainResources.push(selectedService[0].mainResource);
        this.otherResources = selectedService[0].additionalResources;
        console.log(this.newSetup);
    }
    onChange(data: any){
        const resourceArray = this.resourceSetupForm.get('setupData') as FormArray;
let resource = this.resourceSetupForm.get(['setupData',resourceArray.controls.length - 1, 'additionalResources']).value;
        if(data.target.checked) {
            resource.push(new FormControl(data.value));
        } else {
            let index = resource.controls.findIndex(x => x.value == data.value)
            resource.removeAt(index);
        }
    }
}
