import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PageSection } from '@tc-core/model/it/codegen/ui/framework/page-section';
import { Sorter } from '@tc-core/model/it/codegen/ui/framework/sorter';

@Component({
    selector: 'tc-result-header',
    templateUrl: './result-header.component.html'
})
export class ResultHeaderComponent implements OnInit {

    @Input() transferModeList: any[];
    @Input() heading: string;
    @Input() groupingOptionList: any[];
    @Input() sorters: Sorter[];
    @Input() routeList: any[];
    @Input() seasonList: any[];
    @Input() pageSection: PageSection;
    @Input() showPageSection: boolean = false;

    @Output() selectedTransferMode: EventEmitter<string> = new EventEmitter();
    @Output() selectedGroupingType: EventEmitter<number> = new EventEmitter();
    @Output() columnClick: EventEmitter<Sorter> = new EventEmitter();
    @Output() selectSorter: EventEmitter<Sorter> = new EventEmitter();
    @Output() selectedRoute: EventEmitter<string> = new EventEmitter();
    @Output() selectedSeason: EventEmitter<string> = new EventEmitter();
    @Output() actionClick: EventEmitter<any> = new EventEmitter();

    public selectedSorterColumn = null;

    constructor() {
    }

    filterOptions: any[] = [];

    ngOnInit() {
        this.filterOptions.push(this.transferModeList);
    }

    onSelectGroupingType(event: number) {
        this.selectedGroupingType.emit(event);
    }

    onSelectTransferMode(event: any) {
        this.selectedTransferMode.emit(event);
    }

    onSorterClick(sorter: Sorter, asc: boolean) {
        sorter.asc = asc;
        this.selectSorter.emit(sorter);
    }

    onSelectRoute(event: any){
        this.selectedRoute.emit(event);
    }

    onSelectSeason(event: any){
        this.selectedSeason.emit(event);
    }

    onHeaderActionClick(event) {
        this.actionClick.emit(event);
    }

}
