import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SectionNavigationModule } from '@tc/section-navigation';
import { AppSectionNavigationComponent } from './app-section-navigation.component';

@NgModule({
  imports: [
    CommonModule,
    SectionNavigationModule
  ],
  exports: [
    AppSectionNavigationComponent
  ],
  declarations: [AppSectionNavigationComponent]
})
export class AppSectionNavigationModule { }
