import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FabModule } from '@tc/fab';
import { FabWrapperComponent } from './fab-wrapper.component';

@NgModule({
  imports: [
    CommonModule,
    FabModule
  ],
  exports: [
    FabWrapperComponent
  ],
  declarations: [
    FabWrapperComponent
  ],
  providers: []
})
export class FabWrapperModule { }
