import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
    MatAutocompleteModule,
    MatButtonModule,
    MatFormFieldModule, MatIconModule,
    MatInputModule,
    MatSelectModule, MatSliderModule
} from '@angular/material';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ObjKeysPipePipeModule } from '../../../pipes/obj-keys-pipe-pipe.module';
import { PersonalInformationComponent } from './personal-information.component';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatDatepickerModule,
        MatSelectModule,
        ObjKeysPipePipeModule,
        MatAutocompleteModule,
        MatButtonModule,
        MatIconModule,
        TranslateModule.forChild(),
        MatSliderModule,
        FormsModule
    ],
    declarations: [PersonalInformationComponent],
    exports: [PersonalInformationComponent]
})
export class PersonalInformationModule {}
