import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TransferSupplierSearchCriteriaComponent } from './transfer-supplier-search-criteria.component';
import { ChipInputModule } from '@tc/chip-input';

@NgModule({
    imports: [
        CommonModule,
        ChipInputModule
    ],
    exports: [
        TransferSupplierSearchCriteriaComponent
    ],
    declarations: [
        TransferSupplierSearchCriteriaComponent
    ],
    entryComponents: [
        TransferSupplierSearchCriteriaComponent
    ]
})
export class TransferSupplierSearchCriteriaModule {
}
