/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../node_modules/@tc/content-strip/content-strip.component.ngfactory";
import * as i2 from "@tc/content-strip/content-strip.component";
import * as i3 from "../result-content-card-resource-assign/result-content-card-resource-assign.component.ngfactory";
import * as i4 from "../result-content-card-resource-assign/result-content-card-resource-assign.component";
import * as i5 from "@tc-core/util/framework/config-loader.service";
import * as i6 from "../resource-expanded-content-header/resource-expanded-content-header.component.ngfactory";
import * as i7 from "../resource-expanded-content-header/resource-expanded-content-header.component";
import * as i8 from "../supplier-assign-detail-view/supplier-assign-detail-view.component.ngfactory";
import * as i9 from "../supplier-assign-detail-view/supplier-assign-detail-view.component";
import * as i10 from "@angular/forms";
import * as i11 from "../../../../../node_modules/@tc/expanded-content-card/expanded-content-card.component.ngfactory";
import * as i12 from "@tc/expanded-content-card/expanded-content-card.component";
import * as i13 from "@angular/common";
import * as i14 from "@tc/directives/expansion-panel/expansion-panel.directive";
import * as i15 from "@tc-core/util/security/authorization.service";
import * as i16 from "@tc/pipes/advanced-sorter.pipe";
import * as i17 from "@tc/pipes/advanced-pager.pipe";
import * as i18 from "./adv-supplier-assign-search-result.component";
import * as i19 from "../../../services/util/change-detector/change-detector.service";
var styles_AdvSupplierAssignSearchResultComponent = [];
var RenderType_AdvSupplierAssignSearchResultComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AdvSupplierAssignSearchResultComponent, data: {} });
export { RenderType_AdvSupplierAssignSearchResultComponent as RenderType_AdvSupplierAssignSearchResultComponent };
function View_AdvSupplierAssignSearchResultComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [], [[8, "id", 0]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "tc-content-strip", [], null, [[null, "actionClick"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("actionClick" === en)) {
        var pd_0 = (_co.onActionClick(_v.parent.context.$implicit, $event) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.onSupplierSelection(_v.parent.context.$implicit, _v.parent.context.index) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_ContentStripComponent_0, i1.RenderType_ContentStripComponent)), i0.ɵdid(2, 114688, null, 0, i2.ContentStripComponent, [], null, { actionClick: "actionClick" }), (_l()(), i0.ɵeld(3, 0, null, 0, 1, "tc-result-content-card-resource-assign", [], null, null, null, i3.View_ResultContentCardResourceAssignComponent_0, i3.RenderType_ResultContentCardResourceAssignComponent)), i0.ɵdid(4, 114688, null, 0, i4.ResultContentCardResourceAssignComponent, [i5.ConfigLoader], { summaryContent: [0, "summaryContent"] }, null)], function (_ck, _v) { _ck(_v, 2, 0); var currVal_1 = _v.parent.context.$implicit; _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i0.ɵinlineInterpolate(1, "clientSumExp_", _v.parent.context.index, ""); _ck(_v, 0, 0, currVal_0); }); }
function View_AdvSupplierAssignSearchResultComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-resource-expanded-content-header", [], null, [[null, "headerClick"], [null, "clickOutSide"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("headerClick" === en)) {
        var pd_0 = (_co.onHeaderClick(_co.selectedSupplier) !== false);
        ad = (pd_0 && ad);
    } if (("clickOutSide" === en)) {
        var pd_1 = (_co.onOutsideClick(_co.selectedSupplier) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i6.View_ResourceExpandedContentHeaderComponent_0, i6.RenderType_ResourceExpandedContentHeaderComponent)), i0.ɵdid(1, 114688, null, 0, i7.ResourceExpandedContentHeaderComponent, [], { headerText: [0, "headerText"], secondaryHeaderText: [1, "secondaryHeaderText"], icon: [2, "icon"] }, { headerClick: "headerClick" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getHeader(_co.selectedSupplier); var currVal_1 = _co.getSecondaryHeaderText(_co.selectedSupplier); var currVal_2 = _co.selectedSupplier.icon; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_AdvSupplierAssignSearchResultComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-supplier-assign-detail-view", [], null, [[null, "headerClick"], [null, "clickOutSide"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("headerClick" === en)) {
        var pd_0 = (_co.onHeaderClick(_co.selectedSupplier) !== false);
        ad = (pd_0 && ad);
    } if (("clickOutSide" === en)) {
        var pd_1 = (_co.onOutsideClick(_co.selectedSupplier) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i8.View_SupplierAssignDetailViewComponent_0, i8.RenderType_SupplierAssignDetailViewComponent)), i0.ɵdid(1, 114688, null, 0, i9.SupplierAssignDetailViewComponent, [i10.FormBuilder, i5.ConfigLoader], { summaryCardData: [0, "summaryCardData"], bookingData: [1, "bookingData"], bookingDataArray: [2, "bookingDataArray"], assignedSupplier: [3, "assignedSupplier"], footerDataMap: [4, "footerDataMap"], isBulkAssignment: [5, "isBulkAssignment"], isGenResourceAssignment: [6, "isGenResourceAssignment"] }, { headerClick: "headerClick", clickOutSide: "clickOutSide" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.selectedSupplier; var currVal_1 = _co.bookingData; var currVal_2 = _co.bookingDataArray; var currVal_3 = _co.assignedSupplier; var currVal_4 = _co.footerDataMap; var currVal_5 = _co.isBulkAssignment; var currVal_6 = _co.isGenResourceAssignment; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
function View_AdvSupplierAssignSearchResultComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [], [[8, "id", 0]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 5, "tc-expanded-content-card", [["class", "tc-expanded-content-card tc-form-view"]], null, null, null, i11.View_ExpandedContentCardComponent_0, i11.RenderType_ExpandedContentCardComponent)), i0.ɵdid(2, 114688, null, 0, i12.ExpandedContentCardComponent, [], { isEdit: [0, "isEdit"] }, null), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_AdvSupplierAssignSearchResultComponent_5)), i0.ɵdid(4, 16384, null, 0, i13.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_AdvSupplierAssignSearchResultComponent_6)), i0.ɵdid(6, 16384, null, 0, i13.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = false; _ck(_v, 2, 0, currVal_1); var currVal_2 = _co.selectedSupplier; _ck(_v, 4, 0, currVal_2); var currVal_3 = _co.selectedSupplier; _ck(_v, 6, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i0.ɵinlineInterpolate(1, "clientDetailExp_", _v.parent.context.index, ""); _ck(_v, 0, 0, currVal_0); }); }
function View_AdvSupplierAssignSearchResultComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 8, "div", [["class", ""]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 7, "div", [["tabindex", "-1"], ["tcExpansionPanel", ""]], null, [[null, "clickedOutside"], [null, "collapse"], ["document", "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("document:click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 4).handleClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("clickedOutside" === en)) {
        var pd_1 = (_co.onOutsideClick(_co.selectedSupplier) !== false);
        ad = (pd_1 && ad);
    } if (("collapse" === en)) {
        var pd_2 = ((_co.expandedPanelIndex = (0 - 1)) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i0.ɵdid(3, 278528, null, 0, i13.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { ngClass: [0, "ngClass"] }, null), i0.ɵdid(4, 606208, null, 1, i14.ExpansionPanelDirective, [i0.ElementRef, i15.AuthorizationService], { enabled: [0, "enabled"] }, { clickedOutside: "clickedOutside", collapse: "collapse" }), i0.ɵqud(335544320, 1, { tooltipDirective: 0 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AdvSupplierAssignSearchResultComponent_3)), i0.ɵdid(7, 81920, null, 0, i14.ExpansionPanelSummaryDirective, [i0.ViewContainerRef, i0.TemplateRef, i14.ExpansionPanelDirective], null, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AdvSupplierAssignSearchResultComponent_4)), i0.ɵdid(9, 81920, null, 0, i14.ExpansionPanelDetailDirective, [i0.ViewContainerRef, i0.TemplateRef, i14.ExpansionPanelDirective], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.hasSelectedThis(_v.context.$implicit) ? "tc-expanded-content-card--selected" : ""); _ck(_v, 3, 0, currVal_0); var currVal_1 = true; _ck(_v, 4, 0, currVal_1); _ck(_v, 7, 0); _ck(_v, 9, 0); }, null); }
function View_AdvSupplierAssignSearchResultComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", ""], ["id", "supplier-assign-scroll-container"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 3, null, View_AdvSupplierAssignSearchResultComponent_2)), i0.ɵdid(2, 278528, null, 0, i13.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i0.ɵppd(3, 3), i0.ɵppd(4, 3)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 2, 0, _ck(_v, 4, 0, i0.ɵnov(_v.parent, 0), i0.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i0.ɵnov(_v.parent, 1), _co.resultsList, _co.pager.currPage, _co.pager.itemsPerPage)), _co.sortingHandler, _co.sorter)); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_AdvSupplierAssignSearchResultComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i16.AdvancedSorterPipe, []), i0.ɵpid(0, i17.AdvancedPagerPipe, []), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AdvSupplierAssignSearchResultComponent_1)), i0.ɵdid(3, 16384, null, 0, i13.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.resultsList; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_AdvSupplierAssignSearchResultComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-adv-supplier-assign-search-result", [], null, null, null, View_AdvSupplierAssignSearchResultComponent_0, RenderType_AdvSupplierAssignSearchResultComponent)), i0.ɵdid(1, 114688, null, 0, i18.AdvSupplierAssignSearchResultComponent, [i19.ChangeDetectorService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AdvSupplierAssignSearchResultComponentNgFactory = i0.ɵccf("tc-adv-supplier-assign-search-result", i18.AdvSupplierAssignSearchResultComponent, View_AdvSupplierAssignSearchResultComponent_Host_0, { sorter: "sorter", sortingHandler: "sortingHandler", sorters: "sorters", resultsList: "resultsList", selectedSupplier: "selectedSupplier", bookingData: "bookingData", bookingDataArray: "bookingDataArray", isBulkAssignment: "isBulkAssignment", isGenResourceAssignment: "isGenResourceAssignment", assignedSupplier: "assignedSupplier", currencyFormat: "currencyFormat", pager: "pager" }, { supplierSelect: "supplierSelect", cardScroll: "cardScroll", actionClick: "actionClick", outsideSelect: "outsideSelect", headerClick: "headerClick" }, []);
export { AdvSupplierAssignSearchResultComponentNgFactory as AdvSupplierAssignSearchResultComponentNgFactory };
