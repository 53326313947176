import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AssignmentSummary } from '../../../../models/assignment-summary';
import { ResizedEvent } from 'angular-resize-event';
import { ResourceAvailability } from '../../../../models/resource/resource-availability';
import { TimelineEvent } from '../timeline-event';

@Component({
    selector: 'tc-timeline-unit-wrapper',
    templateUrl: './timeline-unit-wrapper.component.html'
})
export class TimelineUnitWrapperComponent implements OnInit {

    @Input() summaryContent: any;

    @Input() unitSize = 0;
    @Input() unitWidth = 0;
    @Input() targetIndex = 0;
    @Input() targetUnitCount = 0;
    @Input() targetMargin = 0.00;
    @Input() isTargetBlock = false;
    @Input() leftBufferUnits = -1;
    @Input() rightBufferUnits = -1;
    @Input() showingIndexes = [];
    @Input() events: TimelineEvent[] = [];

    @Output() eventClick: EventEmitter<TimelineEvent> = new EventEmitter();
    @Output() targetClick: EventEmitter<void> = new EventEmitter();

    dayEndUnit = 0;
    dayStartUnit = 0;

    constructor() {
    }

    ngOnInit(): void {
        this.dayEndUnit = 24 / this.unitSize;
        this.dayStartUnit = 0;
    }

    getUnitRelevantEvents(unitIndex: number) {
        let relevantEvents: TimelineEvent[] = [];
        if (this.events) {
            relevantEvents = this.events.filter(e => e.unitIndex === unitIndex);
            return relevantEvents;
        }
        return relevantEvents;
    }

    getUnitTime(unitIndex: number) {
        let hour = 0;
        if (unitIndex < 0) {
            hour = 24 + unitIndex * this.unitSize;
        } else if (this.dayEndUnit > unitIndex && unitIndex > 0) {
            hour = unitIndex * this.unitSize;
        } else if (this.dayEndUnit < unitIndex) {
            hour = (unitIndex * this.unitSize) - 24;
        }
        if (hour.toString().length > 1) {
            return hour + ':00';
        } else {
            return '0' + hour + ':00';
        }
    }

    onTargetClick() {
        this.targetClick.emit();
    }

    onEventClick(event: TimelineEvent) {
        this.eventClick.emit(event);
    }
}
