<form [formGroup]="hennaArtistSpecificForm">

    <div class="container-fluid direct-client-contact-details-edit tc-card-wrapper">
        <div class="row">
            <div class="col-md-12 col-lg-12">
                <div class="tc-flex-box">
                    <div class="tc-flex-item tc-item-circle">
                        <div class="icon-box">
                            <i class="material-icons" aria-hidden="true">account_circle</i>
                        </div>
                    </div>
                    <div class="tc-flex-item tc-right-cage">

                        <div class="row">
                            <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                                <mat-form-field>
                                    <input
                                            matInput
                                            id=""
                                            #workingHours
                                            formControlName="workingHours"
                                            [placeholder]="'Working Hours'"
                                            required>
                                    <mat-error>{{getErrorMessage('workingHours')}}</mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                            </div>
                            <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>

