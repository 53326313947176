import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ServiceAssignment } from '../../models/reservation/service-assignment';
import { DataKey, DataStoreService } from '../../services/util/framework/data-store.service';
import { TranslateService } from '@ngx-translate/core';
var SupplierPriceBreakdownComponent = /** @class */ (function () {
    function SupplierPriceBreakdownComponent(dataStore, translateService) {
        var _this = this;
        this.dataStore = dataStore;
        this.translateService = translateService;
        this.columnDefs = [
            {
                headerName: 'Type',
                field: 'type',
                sortable: false,
                editable: false
            },
            {
                headerName: 'Cost',
                field: 'cost',
                sortable: true,
                editable: false
            }
        ];
        this.rawData = [];
        this.defaultColDef = {
            resizable: true,
            headerValueGetter: function (parameters) {
                var headerIdentifier = parameters.colDef.headerName;
                if (headerIdentifier) {
                    return _this.translateService.instant(headerIdentifier);
                }
                return '';
            }
        };
        this.translateService.onLangChange.subscribe(function () {
            _this.gridApi.refreshHeader();
        });
    }
    SupplierPriceBreakdownComponent.prototype.ngOnInit = function () {
        var _a;
        var inrFormat = new Intl.NumberFormat('en-Us', {
            style: 'currency',
            currency: this.serviceAssignment.currency ? this.serviceAssignment.currency : 'AED',
            minimumFractionDigits: 2
        });
        (_a = this.rawData).push.apply(_a, tslib_1.__spread([
            {
                type: 'Per Unit',
                cost: inrFormat.format(this.serviceAssignment.unitPrice)
            },
            {
                type: 'Per Adult',
                cost: inrFormat.format(this.serviceAssignment.adultPrice)
            },
            {
                type: 'Per Teen',
                cost: inrFormat.format(this.serviceAssignment.teenPrice)
            },
            {
                type: 'Per Child',
                cost: inrFormat.format(this.serviceAssignment.childPrice)
            },
            {
                type: 'Per Infant',
                cost: inrFormat.format(this.serviceAssignment.infantPrice)
            }
        ]));
    };
    SupplierPriceBreakdownComponent.prototype.onClose = function ($event) {
        this.dataStore.set(DataKey.popupClose, true);
    };
    SupplierPriceBreakdownComponent.prototype.onGridReady = function (event) {
        this.gridApi = event.api;
        this.gridApi.sizeColumnsToFit();
    };
    return SupplierPriceBreakdownComponent;
}());
export { SupplierPriceBreakdownComponent };
