import { RoomFacility } from '../room-facility';
import { RoomOccupancy } from '../room-occupancy';

export class RoomGroup {
    roomGrpId: number;
    roomType: string;
    minimumOccupancy: number;
    standardOccupancy: number;
    maximumOccupancy: number;
    roomFacilities: RoomFacility[];
    roomOccupancies: RoomOccupancy[];
}
