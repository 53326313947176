import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MatAutocomplete, MatInput } from '@angular/material';
import { ThemePalette } from '@angular/material/core';
import { Subscription } from 'rxjs';
import { LocationsSearchCriteria } from '../../../../../models/criteria/locations-search-criteria';
import { SupplierSearchCriteria } from '../../../../../models/criteria/supplier-search-criteria';
import { BookingItem } from '../../../../../models/reservation-v2/booking-item';
import { BookingNoteType } from '../../../../../models/reservation-v2/booking-note';
import { BookingPassenger } from '../../../../../models/reservation-v2/booking-passenger';
import { PassengerType } from '../../../../../models/reservation-v2/passenger-type';
import { TrsBookingItem } from '../../../../../models/reservation-v2/trs-booking-item';
import { ResourceType } from '../../../../../models/reservation/assignment';
import { SupplierSummary } from '../../../../../models/summary/supplier-summary';
import { LocationsService } from '../../../../../services/backend-consumers/setups/locations.service';
import { SupplierService } from '../../../../../services/backend-consumers/supplier-service/supplier.service';
import { ResponseUtil } from '../../../../../services/util/response/response-util.service';

@Component({
    selector: 'tc-trs-item',
    templateUrl: './trs-item.component.html',
    styleUrls: ['./trs-item.component.scss']
})
export class TrsItemComponent implements OnInit, OnDestroy {

    @Input() readonly = false;
    @Input() bookingItem: BookingItem;
    @Input() noteTypes: BookingNoteType[] = [];
    @Input() trsModes: any[] = [];
    @Input() sameTypeItems = [];
    @Input() allPassengerIds = [];
    @Input() allBookingPassengers: BookingPassenger[] = [];
    @Output() changeValidity: EventEmitter<boolean> = new EventEmitter();

    trsBookingItem: TrsBookingItem;
    trsBookingItemForm: FormGroup;
    dateTimePickerColor: ThemePalette = 'primary';
    notesValid = true;
    today:any;

    // for passenger validation
    sameStartDateItems: BookingItem[] = [];
    sameEndDateItems: BookingItem[] = [];
    sameDateTypeItems: BookingItem[] = [];

    // form filling data
    trsModeListFiltered: any[] = [];
    pickupLocationsFiltered: any[] = [];
    dropOffLocationsFiltered: any[] = [];
    suppliersFiltered: any[] = [];
    locationSearchCriteria = new LocationsSearchCriteria();
    supplierSearchCriteria = new SupplierSearchCriteria();

    changesSubscription: Subscription = new Subscription();
    defSupChangesSubscription: Subscription = new Subscription();
    trsModeChangesSubscription: Subscription = new Subscription();
    pickupChangesSubscription: Subscription = new Subscription();
    dropOffChangesSubscription: Subscription = new Subscription();
    adultCountChangeSubscription: Subscription = new Subscription();
    teenCountChangeSubscription: Subscription = new Subscription();
    childCountChangeSubscription: Subscription = new Subscription();
    infantCountChangeSubscription: Subscription = new Subscription();

    @ViewChild('trsModeAutoComplete') trsModeAutoComplete: MatAutocomplete;
    @ViewChild('trsModeInput') trsModeInput: MatInput;

    constructor(
        private fb: FormBuilder,
        private locationService: LocationsService,
        private supplierService: SupplierService
    ) { }

    ngOnInit() {
        // set default minimum datetime as now
        this.today = new Date();
        this.locationSearchCriteria.size = 10;
        this.supplierSearchCriteria.size = 10;
        this.supplierSearchCriteria.status = 'true';
        this.supplierSearchCriteria.resourceTypes = [];
        this.supplierSearchCriteria.resourceTypes.push(ResourceType.vehicle);
        if (this.bookingItem && this.bookingItem.trsBookingItem) {
            this.trsBookingItem = this.bookingItem.trsBookingItem;
        }
        this.createForm();
        this.validateForm();
        //check any sameDateType items in same
        this.getSameDateTypeItems();

        this.changesSubscription = this.trsBookingItemForm.valueChanges.subscribe(
            (data) => {
                if (JSON.stringify(data) !== JSON.stringify({})) {
                    this.bookingItem.itemName = data.itemName;
                    this.bookingItem.contractReference = data.contractReference;
                    this.bookingItem.plCode = data.plCode;
                    this.bookingItem.plName = data.plName;
                    this.bookingItem.defaultSupplierName = data.defaultSupplierName;
                    this.trsBookingItem.departureDateTime = data.startDate;
                    this.trsBookingItem.arrivalDateTime = data.endDate;
                    this.bookingItem.startTime = data.startDate ? data.startDate.setSeconds(0) : data.startDate;
                    this.bookingItem.endTime = data.endDate ? data.endDate.setSeconds(0) : data.endDate;
                    this.bookingItem.serviceDate = data.startDate ? data.startDate.setSeconds(0) : data.startDate;
                    this.bookingItem.passengerValidated = data.passengerValidated;
                    this.bookingItem.adultCount = data.occAdultCount ? +data.occAdultCount : 0;
                    this.bookingItem.teenCount = data.occTeenCount ? +data.occTeenCount : 0;
                    this.bookingItem.childrenCount = data.occChildCount ? +data.occChildCount : 0;
                    this.bookingItem.infantCount = data.occInfantCount ? +data.occInfantCount : 0;
                    this.changeValidity.emit(this.trsBookingItemForm.valid && this.notesValid &&
                        !!this.bookingItem.leadPassenger);
                }
            });
        this.trsModeChangesSubscription = this.trsBookingItemForm.get('transferMode').valueChanges.subscribe(data => {
            if (typeof (data) === 'string' && typeof (data) !== 'object') {
                this.filterTrsModes(data);
            }
        });
        this.pickupChangesSubscription = this.trsBookingItemForm.get('pickupLocation').valueChanges.subscribe(data => {
            if (typeof (data) === 'string' && typeof (data) !== 'object') {
                this.filterPickupLocations(data);
            }
        });
        this.dropOffChangesSubscription = this.trsBookingItemForm.get('dropOffLocation').valueChanges
                                              .subscribe(data => {
                                                  if (typeof (data) === 'string' && typeof (data) !== 'object') {
                                                      this.filterDropOffLocations(data);
                                                  }
                                              });
        this.defSupChangesSubscription = this.trsBookingItemForm.get('defaultSupplier').valueChanges.subscribe(data => {
            if (typeof (data) === 'string' && typeof (data) !== 'object') {
                this.filterSupplier(data);
            }
        });
        this.adultCountChangeSubscription = this.trsBookingItemForm.get('occAdultCount').valueChanges
                                                .subscribe(data => {
                                                    if(data) {
                                                        this.bookingItem.adultCount = +data;
                                                        this.allocatePaxForGivenCount();
                                                    }

                                                });
        this.teenCountChangeSubscription = this.trsBookingItemForm.get('occTeenCount').valueChanges.subscribe(data => {
            if(data) {
                this.bookingItem.teenCount = +data;
                this.allocatePaxForGivenCount();
            }
        });
        this.childCountChangeSubscription = this.trsBookingItemForm.get('occChildCount').valueChanges
                                                .subscribe(data => {
                                                    if(data) {
                                                        this.bookingItem.childrenCount = +data;
                                                        this.allocatePaxForGivenCount();
                                                    }
                                                });
        this.infantCountChangeSubscription = this.trsBookingItemForm.get('occInfantCount').valueChanges
                                                 .subscribe(data => {
                                                     if(data) {
                                                         this.bookingItem.infantCount = +data;
                                                         this.allocatePaxForGivenCount();
                                                     }
                                                 });
    }

    public validateForm() {
        Object.keys(this.trsBookingItemForm.controls).forEach(field => {
            const control = this.trsBookingItemForm.get(field);
            control.markAsTouched({onlySelf: true});
        });
    }

    private createForm() {
        let startTime = null;
        let endTime = null;
        if (this.trsBookingItem && this.trsBookingItem.departureDateTime) {
            startTime = new Date(this.trsBookingItem.departureDateTime);
        }
        if (this.trsBookingItem && this.trsBookingItem.arrivalDateTime) {
            endTime = new Date(this.trsBookingItem.arrivalDateTime);
        }
        this.trsBookingItemForm = this.fb.group({
            itemName: new FormControl(
                {value: this.bookingItem ? this.bookingItem.itemName : '', disabled: this.readonly},
                Validators.compose([
                    Validators.required
                ])
            ),
            contractReference: new FormControl(
                {value: this.bookingItem ? this.bookingItem.contractReference : '', disabled: this.readonly},
                Validators.compose([
                    Validators.required
                ])
            ),
            plCode: new FormControl(
                {value: this.bookingItem ? this.bookingItem.plCode : '', disabled: this.readonly},
                Validators.compose([
                    Validators.required
                ])
            ),
            defaultSupplier: new FormControl(
                {value: this.bookingItem ? this.bookingItem.defaultSupplierName : '', disabled: this.readonly},
                Validators.compose([])
            ),
            startDate: new FormControl(
                {value: startTime, disabled: this.readonly},
                Validators.compose([
                    Validators.required
                ])
            ),
            endDate: new FormControl(
                {value: endTime, disabled: this.readonly},
                Validators.compose([
                    Validators.required
                ])
            ),
            transferMode: new FormControl(
                {value: this.trsBookingItem ? this.trsBookingItem.transferMode : '', disabled: this.readonly},
                Validators.compose([
                    Validators.required,
                    this.isValidTrsMode.bind(this)
                ])
            ),
            pickupLocation: new FormControl(
                {value: this.trsBookingItem ? this.trsBookingItem.pickupPointName : '', disabled: this.readonly},
                Validators.compose([
                    Validators.required
                ])
            ),
            dropOffLocation: new FormControl(
                {value: this.trsBookingItem ? this.trsBookingItem.dropOffPointName : '', disabled: this.readonly},
                Validators.compose([
                    Validators.required
                ])
            ),
            occAdultCount: new FormControl(
                {value: this.bookingItem ? this.bookingItem.adultCount : '', disabled: this.readonly},
                Validators.compose([
                    Validators.required
                ])
            ),
            occTeenCount: new FormControl(
                {value: this.bookingItem ? this.bookingItem.teenCount : '', disabled: this.readonly},
                Validators.compose([])
            ),
            occChildCount: new FormControl(
                {value: this.bookingItem ? this.bookingItem.childrenCount : '', disabled: this.readonly},
                Validators.compose([])
            ),
            occInfantCount: new FormControl(
                {value: this.bookingItem ? this.bookingItem.infantCount : '', disabled: this.readonly},
                Validators.compose([])
            ),
            passengerValidated: new FormControl(
                {
                    value: this.bookingItem && this.bookingItem.passengerValidated ?
                        this.bookingItem.passengerValidated :
                        false, disabled: this.readonly
                },
                Validators.compose([])
            )
        });
    }

    getErrorMessage(field: string): string {
        switch (field) {
            case 'itemName' :
                return this.trsBookingItemForm.get('itemName').hasError('required') ? 'Item Name Required' : '';
            case 'contractReference' :
                return this.trsBookingItemForm.get('contractReference').hasError('required') ?
                    'Contract Reference Required' :
                    '';
            case 'plCode' :
                return this.trsBookingItemForm.get('plCode').hasError('required') ? 'PL Code Required' : '';
            case 'startDate' :
                return this.trsBookingItemForm.get('startDate').hasError('required') ? 'Start Date Required' : '';
            case 'endDate' :
                return this.trsBookingItemForm.get('endDate').hasError('required') ? 'End Date Required' : '';
            case 'transferMode' :
                if (this.trsBookingItemForm.get('transferMode').hasError('formatError')) {
                    return 'Invalid Transfer Mode';
                } else if (this.trsBookingItemForm.get('transferMode').hasError('required')) {
                    return 'Transfer Mode Required';
                } else {
                    return '';
                }
            case 'pickupLocation' :
                return this.trsBookingItemForm.get('pickupLocation').hasError('required') ?
                    'Pickup Location Required' :
                    '';
            case 'dropOffLocation' :
                return this.trsBookingItemForm.get('dropOffLocation').hasError('required') ?
                    'Drop Off Location Required' :
                    '';
            case 'occAdultCount' :
                return this.trsBookingItemForm.get('occAdultCount').hasError('required') ?
                    'Adult Count Required' :
                    '';
        }
    }

    public onNotesChange(event: boolean) {
        this.notesValid = event;
        this.changeValidity.emit(this.trsBookingItemForm.valid && this.notesValid);
    }

    // trs mode
    public displayTrsModeFn(trsMode: any) {
        return trsMode && trsMode.code ? trsMode.code : trsMode;
    }

    public onTrsModeFocus($event: FocusEvent) {
        this.trsModeListFiltered = [];
    }

    public onTrsModeFocusOut($event: FocusEvent) {
        // const text = this.trsBookingItemForm.get('transferMode').value;
        // if (text) {
        //     this.trsBookingItemForm.get('transferMode').setValue(this.trsBookingItem.transferMode, {onlySelf: true});
        // }
    }

    public onSelectTrsMode(trsMode: any) {
        this.trsBookingItem.transferMode = trsMode.code;
    }

    private filterTrsModes(input: string) {
        if (input) {
            this.trsModeListFiltered = this.trsModes
                                           .filter(
                                               t => ((t.code && t.code.toLowerCase().includes(input.toLowerCase()))
                                                   || (t.name && t.name.toLowerCase().includes(input.toLowerCase())))
                                           );
        } else {
            this.trsModeListFiltered = this.trsModes;
        }
    }

    public isValidTrsMode(control: AbstractControl): ValidationErrors | null {
        const value = control.value;
        if (value && this.trsModes && this.trsModes.length) {
            const found = this.trsModes
                              .find(
                                  t => ((t.code && t.code === value))
                              );
            if (!found) {
                return {formatError: true};
            } else {
                return null;
            }
        } else {
            return null;
        }
    }

    // location
    public displayLocationFn(loc: any) {
        return loc && loc.name ? loc.name : loc;
    }

    // pickup
    public onPickupLocationFocus($event: FocusEvent) {
        this.pickupLocationsFiltered = [];
    }

    public onPickupLocationFocusOut($event: FocusEvent) {
        // const text = this.trsBookingItemForm.get('pickupLocation').value;
        // if (text) {
        //     this.trsBookingItemForm.get('pickupLocation').setValue(this.trsBookingItem.pickupPointName, {onlySelf: true});
        // }
    }

    public onSelectPickupLocation(loc: any) {
        this.trsBookingItem.pickupPointName = loc.name;
        this.trsBookingItem.pickupPointCode = loc.code;
        this.changeValidity.emit(this.trsBookingItemForm.valid && this.notesValid);
    }

    private filterPickupLocations(input: string) {
        if (input) {
            this.locationSearchCriteria.codeOrName = input;
            this.locationService.getLocations(this.locationSearchCriteria).subscribe(data => {
                this.pickupLocationsFiltered = ResponseUtil.getDataArray(data);
            });
        } else {
            this.pickupLocationsFiltered = [];
        }
    }

    // dropoff
    public onDropOffLocationFocus($event: FocusEvent) {
        this.dropOffLocationsFiltered = [];
    }

    public onDropOffLocationFocusOut($event: FocusEvent) {
        // const text = this.trsBookingItemForm.get('dropOffLocation').value;
        // if (text) {
        //     this.trsBookingItemForm.get('dropOffLocation').setValue(this.trsBookingItem.dropOffPointName, {onlySelf: true});
        // }
    }

    public onSelectDropOffLocation(loc: any) {
        this.trsBookingItem.dropOffPointName = loc.name;
        this.trsBookingItem.dropOffPointCode = loc.code;
        this.changeValidity.emit(this.trsBookingItemForm.valid && this.notesValid);
    }

    private filterDropOffLocations(input: string) {
        if (input) {
            this.locationSearchCriteria.codeOrName = input;
            this.locationService.getLocations(this.locationSearchCriteria).subscribe(data => {
                this.dropOffLocationsFiltered = ResponseUtil.getDataArray(data);
            });
        } else {
            this.dropOffLocationsFiltered = [];
        }
    }

    // supplier
    public displaySupplierFn(sup: any) {
        return sup && sup.name ? sup.name : sup;
    }

    public onSupplierFocus($event: FocusEvent) {
        this.suppliersFiltered = [];
    }

    public onSupplierFocusOut($event: FocusEvent) {
        // const text = this.trsBookingItemForm.get('defaultSupplier').value;
        // if (text) {
        //     this.trsBookingItemForm.get('defaultSupplier').setValue(this.bookingItem.defaultSupplierName, {onlySelf: true});
        // }
    }

    public onSelectSupplier(supplier: SupplierSummary) {
        this.bookingItem.defaultSupplierName = supplier.name;
        this.bookingItem.defaultSupplierCode = supplier.code;
        this.bookingItem.defaultSupplier = supplier;
        this.changeValidity.emit(this.trsBookingItemForm.valid && this.notesValid);
    }

    private filterSupplier(input: string) {
        if (input) {
            this.supplierSearchCriteria.codeOrName = input;
            this.supplierService.searchSuppliersNoDataKey(this.supplierSearchCriteria).subscribe(data => {
                this.suppliersFiltered = ResponseUtil.getDataArray(data);
            });
        } else {
            this.suppliersFiltered = [];
        }
    }

    public onChangeCheckBox(event: boolean) {
        let alreadyAllocatedPaxTempIds = [];
        if (event && this.sameDateTypeItems && this.sameDateTypeItems.length > 0) {
            this.sameDateTypeItems.forEach(value => alreadyAllocatedPaxTempIds.push(...value.passengerTempIds));
        }
        this.allocatePassengers(alreadyAllocatedPaxTempIds);
    }

    public onStartDateChange() {
        this.trsBookingItemForm.get('startDate').value.setSeconds(0);
        if (this.sameEndDateItems && this.sameEndDateItems.length > 0) {
            this.sameDateTypeItems = this.sameEndDateItems.filter(value => new Date(value.startTime).toString() ===
                this.trsBookingItemForm.get('startDate').value.toString());
        } else if (this.sameTypeItems && this.sameTypeItems.length > 0) {
            this.sameStartDateItems = this.sameTypeItems.filter(value => new Date(value.startTime).toString() ===
                this.trsBookingItemForm.get('startDate').value.toString());
        }
        // this.checkPassengerValidation();
    }

    public onEndDateChange() {
        this.trsBookingItemForm.get('endDate').value.setSeconds(0);
        if (this.sameStartDateItems && this.sameStartDateItems.length > 0) {
            this.sameDateTypeItems = this.sameStartDateItems.filter(value => new Date(value.endTime).toString() ===
                this.trsBookingItemForm.get('endDate').value.toString());
        } else if (this.sameTypeItems && this.sameTypeItems.length > 0) {
            this.sameEndDateItems = this.sameTypeItems.filter(value => new Date(value.endTime).toString() ===
                this.trsBookingItemForm.get('endDate').value.toString());
        }
    }

    // if validating passengers, then set only already non allocated passengers for current item
    private allocatePassengers(alreadyAllocatedPaxTempIds: any[]) {
        let nonAllocatedPassengers = [];
        let adults = [];
        let teens = [];
        let children = [];
        let infants = [];
        if (this.allBookingPassengers && this.allBookingPassengers.length > 0) {
            this.allBookingPassengers.forEach(value => {
                if (!alreadyAllocatedPaxTempIds.includes(value.tempId)) {
                    if (value.type === PassengerType.A && this.bookingItem.adultCount > 0 && adults.length <
                        this.bookingItem.adultCount) {
                        adults.push(value.tempId);
                        nonAllocatedPassengers.push(value.tempId);
                    } else if (value.type === PassengerType.T && this.bookingItem.teenCount > 0 && teens.length <
                        this.bookingItem.teenCount) {
                        teens.push(value.tempId);
                        nonAllocatedPassengers.push(value.tempId);
                    } else if (value.type === PassengerType.C && this.bookingItem.childrenCount > 0 && children.length <
                        this.bookingItem.childrenCount) {
                        children.push(value.tempId);
                        nonAllocatedPassengers.push(value.tempId);
                    } else if (value.type === PassengerType.I && this.bookingItem.infantCount > 0 && infants.length <
                        this.bookingItem.infantCount) {
                        infants.push(value.tempId);
                        nonAllocatedPassengers.push(value.tempId);
                    }
                }
            });
            // this.checkPassengerValidation();
        }
        this.bookingItem.passengerTempIds = nonAllocatedPassengers;
        //set lead from adults
        for (let i = 0; i < this.allBookingPassengers.length; i++) {
            const value = this.allBookingPassengers[i];
            if (this.bookingItem.passengerTempIds.includes(value.tempId) && value.type === PassengerType.A) {
                value.lead = true;
                this.bookingItem.leadPassenger = value;
                break;
            }
        }
    }

    private checkPassengerValidation() {
        if (!this.sameDateTypeItems || this.sameDateTypeItems.length === 0) {
            this.bookingItem.passengerTempIds = this.allPassengerIds;
        }
    }

    private getSameDateTypeItems() {
        if (this.sameTypeItems && this.sameTypeItems.length > 0 && this.bookingItem.startTime &&
            this.bookingItem.endTime) {
            this.sameDateTypeItems = this.sameTypeItems.filter(value => new Date(value.endTime).toString() ===
                this.trsBookingItemForm.get('endDate').value.toString() && new Date(value.startTime).toString() ===
                this.trsBookingItemForm.get('startDate').value.toString());
        }
    }

    private allocatePaxForGivenCount() {
        if (this.sameDateTypeItems && this.sameDateTypeItems.length > 0 && this.bookingItem && this.bookingItem.passengerValidated) {
            this.onChangeCheckBox(true);
        } else {
            this.allocatePassengers([]);
        }
    }

    public ngOnDestroy(): void {
        if (this.changesSubscription) {
            this.changesSubscription.unsubscribe();
        }
        if (this.defSupChangesSubscription) {
            this.defSupChangesSubscription.unsubscribe();
        }
        if (this.trsModeChangesSubscription) {
            this.trsModeChangesSubscription.unsubscribe();
        }
        if (this.pickupChangesSubscription) {
            this.pickupChangesSubscription.unsubscribe();
        }
        if (this.dropOffChangesSubscription) {
            this.dropOffChangesSubscription.unsubscribe();
        }
    }
}
