import { AbstractSearchCriteria } from './abstract-search-criteria';

export class RoomOccupancySearchCriteria extends AbstractSearchCriteria {
    minimumOccupancy: number;
    maximumOccupancy: number;
    minAdultsRequired: number;
    name = '';
    adults: number;
    teens: number;
    children: number;
    infants: number;
}
