import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AdditionalContactSummaryCardComponent} from './additional-contact-summary-card.component';
import {ExpandedContentCardBodyModule} from '@tc/expanded-content-card-body';
import {MatExpansionModule} from '@angular/material';
import {ContentStripModule} from '@tc/content-strip';
import {ResultContentCardModule} from '../result-content-card/result-content-card.module';
import {DirectivesModule} from '@tc/directives';
import {SummaryStripModule} from '@tc/summary-strip';
import {ResourceModule} from '@tc/resource';

@NgModule({
    imports: [
        CommonModule,
        ExpandedContentCardBodyModule,
        MatExpansionModule,
        ContentStripModule,
        ResultContentCardModule,
        DirectivesModule,
        SummaryStripModule,
        ResourceModule
    ],
    declarations: [AdditionalContactSummaryCardComponent],
    exports: [AdditionalContactSummaryCardComponent]
})
export class AdditionalContactSummaryCardModule {
}
