import { DatePipe } from '@angular/common';
import { ConfigLoader } from '@tc-core/util/framework';
import { DateTimeProcessor } from '../../business-utils/time-zone/date-time-processor';
import { DMCQueryParamsService } from '../../util/framework/dmc-query-params.service';
import { RequestService } from '../../util/framework/request.service';
import { UrlPaths } from '../url-paths';
import { Subject } from "rxjs/Subject";
import * as i0 from "@angular/core";
import * as i1 from "@tc-core/util/framework/config-loader.service";
import * as i2 from "../../util/framework/request.service";
import * as i3 from "../../util/framework/dmc-query-params.service";
var DocumentQueueService = /** @class */ (function () {
    function DocumentQueueService(configLoader, requestService, queryParamsService) {
        this.configLoader = configLoader;
        this.requestService = requestService;
        this.queryParamsService = queryParamsService;
        this.datePipe = new DatePipe('en-US');
        this.simpleDateFormat = 'dd-MM-yyyy';
        this.simpleTimeFormat = 'h:mm a';
        this.EMPTY_STRING = '--';
        this.pipe = new DatePipe('en-US');
        this.subject = new Subject();
        this.manualDocsToUi = this.subject.asObservable();
    }
    DocumentQueueService.prototype.searchDocumentQueues = function (criteria) {
        DateTimeProcessor.processDocumentQueueSearchCriteriaDateTimes(criteria);
        var reqPrams = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.get(UrlPaths.OP_DOCUMENT_URL_KEY, [UrlPaths.OP_DOCUMENT_VERSION, UrlPaths.RULE_BASED_DOCUMENTS], reqPrams);
    };
    DocumentQueueService.prototype.patchDispatchEntry = function (documentQueueGroupList) {
        return this.requestService.post(UrlPaths.OP_DOCUMENT_URL_KEY, [UrlPaths.OP_DOCUMENT_VERSION, UrlPaths.RULE_BASED_DOCUMENTS, UrlPaths.DOCUMENT_QUEUE_ENTRIES], documentQueueGroupList);
    };
    DocumentQueueService.prototype.sendTaskStatus = function (taskStatus) {
        this.subject.next({ TaskStatus: taskStatus });
    };
    DocumentQueueService.prototype.clearTaskStatus = function () {
        this.subject.next();
    };
    DocumentQueueService.prototype.getTaskStatus = function () {
        return this.subject.asObservable();
    };
    DocumentQueueService.ngInjectableDef = i0.defineInjectable({ factory: function DocumentQueueService_Factory() { return new DocumentQueueService(i0.inject(i1.ConfigLoader), i0.inject(i2.RequestService), i0.inject(i3.DMCQueryParamsService)); }, token: DocumentQueueService, providedIn: "root" });
    return DocumentQueueService;
}());
export { DocumentQueueService };
