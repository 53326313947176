/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../widgets/shared/auto-assigned-summary/auto-assigned-summary.component.ngfactory";
import * as i2 from "../../../widgets/shared/auto-assigned-summary/auto-assigned-summary.component";
import * as i3 from "./auto-assigned-summary-wrapper.component";
import * as i4 from "../../../services/util/framework/data-store.service";
var styles_AutoAssignedSummaryWrapperComponent = [];
var RenderType_AutoAssignedSummaryWrapperComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AutoAssignedSummaryWrapperComponent, data: {} });
export { RenderType_AutoAssignedSummaryWrapperComponent as RenderType_AutoAssignedSummaryWrapperComponent };
export function View_AutoAssignedSummaryWrapperComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-auto-assigned-summary", [], null, [[null, "closePopUp"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("closePopUp" === en)) {
        var pd_0 = (_co.onClosePopup($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_AutoAssignedSummaryComponent_0, i1.RenderType_AutoAssignedSummaryComponent)), i0.ɵdid(1, 114688, null, 0, i2.AutoAssignedSummaryComponent, [], { assignedResources: [0, "assignedResources"] }, { closePopUp: "closePopUp" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.assignedResources; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_AutoAssignedSummaryWrapperComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-auto-assigned-summary-wrapper", [], null, null, null, View_AutoAssignedSummaryWrapperComponent_0, RenderType_AutoAssignedSummaryWrapperComponent)), i0.ɵdid(1, 114688, null, 0, i3.AutoAssignedSummaryWrapperComponent, [i4.DataStoreService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AutoAssignedSummaryWrapperComponentNgFactory = i0.ɵccf("tc-auto-assigned-summary-wrapper", i3.AutoAssignedSummaryWrapperComponent, View_AutoAssignedSummaryWrapperComponent_Host_0, { assignedResources: "assignedResources" }, {}, []);
export { AutoAssignedSummaryWrapperComponentNgFactory as AutoAssignedSummaryWrapperComponentNgFactory };
