import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { TcErrorType } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-error';
import { TC } from '@tc-core/util';
import { ConfigLoader } from '@tc-core/util/framework';
import { of, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SetupGridComp } from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid-comp';
import { SetupGridComponent } from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid.component';
import { TCO } from '../../../constants';
import { CostingTypeCategory, CostingTypeSearchCriteria } from '../../../models/criteria/costing-type-search-criteria';
import { SortDirection } from '../../../models/helper/sort-direction';
import { MasterDataRestService } from '../../../services/backend-consumers/master-data-handler-service/master-data-rest.service';
import { CostingTypeService } from '../../../services/backend-consumers/setups/costing-type-service';
import { DMCCommon } from '../../../services/util/common/dmc-common';
import { DataKey, DataStoreService } from '../../../services/util/framework/data-store.service';
var EMPTY_CHAR = TC.FORMAT.EMPTY_CHAR;
var CostingTypeGridSetupComponent = /** @class */ (function (_super) {
    tslib_1.__extends(CostingTypeGridSetupComponent, _super);
    function CostingTypeGridSetupComponent(configLoader, costingTypeService, dataStoreService, masterDataRestService, common) {
        var _this = _super.call(this) || this;
        _this.configLoader = configLoader;
        _this.costingTypeService = costingTypeService;
        _this.dataStoreService = dataStoreService;
        _this.masterDataRestService = masterDataRestService;
        _this.common = common;
        _this.colDefConfig = [];
        // search criteria obj. used to
        _this.costingTypeSearchCriteria = new CostingTypeSearchCriteria();
        _this.searchCriteriaObserver = new Subscription();
        _this.allCostingTypeCodes = [];
        _this.getCategoryText = function (params) {
            if (params && params.data && params.data.category) {
                return CostingTypeCategory[params.data.category];
            }
            return EMPTY_CHAR;
        };
        return _this;
    }
    CostingTypeGridSetupComponent.prototype.ngOnInit = function () {
        this.colDefConfig = this.configLoader.configurations.get(TCO.CONF.CONF_COSTING_TYPE_SETUP);
        this.subscribeSearchCriteria();
        this.getAllCostingTypeCodes();
    };
    CostingTypeGridSetupComponent.prototype.getAllCostingTypeCodes = function () {
        var _this = this;
        this.masterDataRestService.getCostingTypes().subscribe(function (response) {
            response.data.forEach(function (data) {
                _this.allCostingTypeCodes.push(data.code);
            });
        });
    };
    /**
     * subscribe search criteria changes in search bar
     * then force grid to refresh data
     * grid refresh will be call getRaws method which is using this subscribed criteria for backend call
     */
    CostingTypeGridSetupComponent.prototype.subscribeSearchCriteria = function () {
        var _this = this;
        this.searchCriteriaObserver = this.dataStoreService.get(DataKey.costingTypeSearchCriteria).subscribe(function (value) {
            _this.costingTypeSearchCriteria = value;
            _this.setupGrid.runForceSearch();
        });
    };
    CostingTypeGridSetupComponent.prototype.isInvalidRow = function (params) {
        var data = params.data;
        if (data) {
            var invalid = true;
            if (data.code &&
                data.name &&
                data.category &&
                data.factor) {
                invalid = false;
            }
            return invalid;
        }
        else {
            return false;
        }
    };
    CostingTypeGridSetupComponent.prototype.isUnsavedRow = function (row) {
        return !row.id;
    };
    CostingTypeGridSetupComponent.prototype.deleteRow = function (row) {
        if (row && row.code) {
            return this.costingTypeService.deleteRow(row.code);
        }
        else {
            return of('success');
        }
    };
    CostingTypeGridSetupComponent.prototype.saveRow = function (row, event) {
        if (row.unsaved) {
            if (this.allCostingTypeCodes.includes(row.code)) {
                this.common.showSnackBar('Costing Type Code Already Exist. Please Select a Different Code', 3000, TcErrorType.TYPE.ERROR);
            }
            else {
                return this.costingTypeService.saveRow(row);
            }
        }
        else {
            return this.costingTypeService.saveRow(row);
        }
    };
    CostingTypeGridSetupComponent.prototype.getRows = function (params) {
        var _this = this;
        console.log('fetch requesting for ' + params.startRow + ' to ' + params.endRow);
        console.log(params.sortModel);
        var pageSize = params.endRow - params.startRow;
        if (!this.costingTypeSearchCriteria) {
            this.costingTypeSearchCriteria = new CostingTypeSearchCriteria();
        }
        this.costingTypeSearchCriteria.start = params.startRow;
        this.costingTypeSearchCriteria.size = pageSize;
        if (params.sortModel && params.sortModel[0]) {
            this.costingTypeSearchCriteria.sortBy = params.sortModel[0].colId;
            if (params.sortModel[0].sort === SetupGridComp.GRID_SORT_ASCENDING) {
                this.costingTypeSearchCriteria.sortDirection = SortDirection.ASC;
            }
            else {
                this.costingTypeSearchCriteria.sortDirection = SortDirection.DESC;
            }
        }
        else {
            this.costingTypeSearchCriteria.sortBy = 'createdOn';
            this.costingTypeSearchCriteria.sortDirection = SortDirection.ASC;
        }
        return this.costingTypeService.getRows(this.costingTypeSearchCriteria)
            .pipe(tap(function (data) {
            return _this.dataStoreService.set(DataKey.costingTypeSearchResultsForCriteria, data);
        }));
    };
    CostingTypeGridSetupComponent.prototype.createNewRow = function () {
        return { unsaved: true };
    };
    CostingTypeGridSetupComponent.prototype.ngOnDestroy = function () {
        if (this.searchCriteriaObserver) {
            this.searchCriteriaObserver.unsubscribe();
        }
    };
    return CostingTypeGridSetupComponent;
}(SetupGridComp));
export { CostingTypeGridSetupComponent };
