import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigLoader } from '@tc-core/util/framework/config-loader.service';
import { Observable } from 'rxjs';
import { TCO } from '../../../constants';
import { AssignmentSummary } from '../../../models/assignment-summary';
import { KeyControl } from '../../../models/common/key-control';
import { AssignmentSummaryCriteriaV2 } from '../../../models/criteria/assignment-summary-criteria-v2';
import { CriteriaSearchCriteria } from '../../../models/criteria/criteria-search-criteria';
import { SavedCriteriaV2 } from '../../../models/criteria/saved-criteria-v2';
import { AllocationFeature } from '../../../models/reservation-v2/allocation-feature';
import { AssignStatus } from '../../../models/reservation-v2/assignment';
import { AssignmentPatch } from '../../../models/reservation-v2/assignment-patch';
import { AssignmentRawData } from '../../../models/reservation-v2/assignment-raw-data';
import { BookingItem } from '../../../models/reservation-v2/booking-item';
import { BookingItemPatch } from '../../../models/reservation-v2/booking-item-patch';
import { BookingNote } from '../../../models/reservation-v2/booking-note';
import { BookingPassenger } from '../../../models/reservation-v2/booking-passenger';
import { BookingSupplement } from '../../../models/reservation-v2/booking-supplement';
import { AllocationFeatureSearchCriteria } from '../../../models/reservation-v2/criteria/allocation-feature-search-criteria';
import { BookingItemSearchCriteria } from '../../../models/reservation-v2/criteria/booking-item-search-criteria';
import { ProductQueueSearchCriteria } from '../../../models/reservation-v2/criteria/product-queue-search-criteria';
import { ResourceAllocationSearchCriteria } from '../../../models/reservation-v2/criteria/resource-allocation-search-criteria';
import { ServiceItemSearchCriteria } from '../../../models/reservation-v2/criteria/service-item-search-criteria';
import { SupplierAllocationSearchCriteria } from '../../../models/reservation-v2/criteria/supplier-allocation-search-criteria';
import { ProductItem } from '../../../models/reservation-v2/product-item';
import { ResourceAllocationSummary } from '../../../models/reservation-v2/resource-allocation-summary';
import { ServiceItem } from '../../../models/reservation-v2/service-item';
import { SupplierAllocationSummary } from '../../../models/reservation-v2/supplier-allocation-summary';
import { OPResponseWrapper } from '../../../models/response/op-response-wrapper';
import { DataLoaderService } from '../../api/data-loader-service.service';
import { DateTimeProcessor } from '../../business-utils/time-zone/date-time-processor';
import { DataKey, DataStoreService } from '../../util/framework/data-store.service';
import { DMCQueryParamsService } from '../../util/framework/dmc-query-params.service';
import { RequestService } from '../../util/framework/request.service';
import { FileHandlingService } from '../file-handler/file-handling.service';
import { UrlPaths } from '../url-paths';

@Injectable({
    providedIn: 'root'
})
export class ReservationV2ManagementService
{
    constructor(
        private queryParamsService: DMCQueryParamsService,
        private requestService: RequestService,
        private http: HttpClient,
        private dataStore: DataStoreService,
        private httpClient: HttpClient,
        private configLoader: ConfigLoader,
        private dataLoaderService: DataLoaderService,
        private fileHandlingService: FileHandlingService
    )
    {}

    public searchProducts(productQueueSearchCriteria: ProductQueueSearchCriteria): Observable<OPResponseWrapper<ProductItem>>
    {
        DateTimeProcessor.processProductQueueSearchCriteriaDateTimes(productQueueSearchCriteria);
        const params = this.queryParamsService.asQueryParams(productQueueSearchCriteria);
        return this.requestService.getWithDataKey(
            DataKey.productQueueSearchResultsFromService,
            UrlPaths.OP_RES_URL_KEY,
            [UrlPaths.OP_RES_VERSION, UrlPaths.PRODUCT_ITEMS],
            params
        );
    }

    public searchServiceItems(criteria: ServiceItemSearchCriteria): Observable<OPResponseWrapper<ServiceItem>>
    {
        // const params = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.post(
            UrlPaths.OP_RES_URL_KEY,
            [UrlPaths.OP_RES_VERSION, UrlPaths.SERVICE_ITEMS_V2, UrlPaths.search],
            criteria
        );
    }

    public searchBookingItems(criteria: BookingItemSearchCriteria): Observable<OPResponseWrapper<BookingItem>>
    {
        // const params = this.queryParamsService.asQueryParams(criteria);
        // return this.http.get<OPResponseWrapper<BookingItem>>('assets/mockdata/booking_items.json');
        return this.requestService.post(
            UrlPaths.OP_RES_URL_KEY,
            [UrlPaths.OP_RES_VERSION, UrlPaths.BOOKING_ITEMS_V2, UrlPaths.search],
            criteria
        );
    }

    public searchSuppliersForAllocation(criteria: SupplierAllocationSearchCriteria): Observable<OPResponseWrapper<SupplierAllocationSummary>>
    {
        DateTimeProcessor.processSearchCriteriaDateTimes(criteria);
        const params = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.get(
            UrlPaths.OP_RES_URL_KEY,
            [UrlPaths.OP_RES_VERSION, UrlPaths.SUPPLIER_ALLOCATION_SUMMARIES_V2],
            params
        );
    }

    public searchResourcesForAllocation(criteria: ResourceAllocationSearchCriteria):
        Observable<OPResponseWrapper<ResourceAllocationSummary>>
    {
        DateTimeProcessor.processSearchCriteriaDateTimes(criteria);
        const params = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.get(
            UrlPaths.OP_RES_URL_KEY,
            [UrlPaths.OP_RES_VERSION, UrlPaths.RESOURCE_ALLOCATION_SUMMARIES_V2],
            params
        );
    }

    public searchSuppliersForAllocationWithKey(criteria: SupplierAllocationSearchCriteria): Observable<OPResponseWrapper<SupplierAllocationSummary>>
    {
        DateTimeProcessor.processSupplierAllocationSearchCriteriaDateTimes(criteria);
        const params = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.getWithDataKey(
            DataKey.supplierAllocationSearchResults,
            UrlPaths.OP_RES_URL_KEY,
            [UrlPaths.OP_RES_VERSION, UrlPaths.SUPPLIER_ALLOCATION_SUMMARIES_V2],
            params
        );
    }

    public searchResourcesForAllocationWithKey(criteria: SupplierAllocationSearchCriteria): Observable<OPResponseWrapper<SupplierAllocationSummary>>
    {
        DateTimeProcessor.processSupplierAllocationSearchCriteriaDateTimes(criteria);
        const params = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.getWithDataKey(
            DataKey.resourceAllocationSearchResults,
            UrlPaths.OP_RES_URL_KEY,
            [UrlPaths.OP_RES_VERSION, UrlPaths.RESOURCE_ALLOCATION_SUMMARIES_V2],
            params
        );
    }

    public saveAssignmentRawData(assignmentList: AssignmentRawData[]): Observable<OPResponseWrapper<AssignmentRawData>>
    {
        return this.requestService.post(
            UrlPaths.OP_RES_URL_KEY,
            [UrlPaths.OP_RES_VERSION, UrlPaths.ASSIGNMENT_RAW_DATA_V2],
            assignmentList
        );
    }

    public changeAssignmentsStatus(assignmentIds: number[], status: AssignStatus)
    {
        const params = this.queryParamsService.asQueryParams({
            status
        });
        return this.requestService.patch(
            UrlPaths.OP_RES_URL_KEY,
            [UrlPaths.OP_RES_VERSION, UrlPaths.ASSIGNMENTS_V2],
            assignmentIds,
            params
        );
    }

    public splitBookingItem(paxMappingGroupList: any[]): Observable<OPResponseWrapper<BookingItem>>
    {
        return this.requestService.post(
            UrlPaths.OP_RES_URL_KEY,
            [UrlPaths.OP_RES_VERSION, UrlPaths.BOOKING_ITEMS_V2, UrlPaths.SPLIT],
            paxMappingGroupList
        );
    }

    public splitServiceItem(paxMappingGroupList: any[]): Observable<OPResponseWrapper<ServiceItem>>
    {
        return this.requestService.post(
            UrlPaths.OP_RES_URL_KEY,
            [UrlPaths.OP_RES_VERSION, UrlPaths.SERVICE_ITEMS_V2, UrlPaths.SPLIT],
            paxMappingGroupList
        );
    }

    public patchBookingItems(
        bookingIdList: number[], bookingItemPatch: BookingItemPatch): Observable<OPResponseWrapper<BookingItem>>
    {
        const params = this.queryParamsService.asQueryParams({
            bookingIdList
        });
        return this.requestService.patch(
            UrlPaths.OP_RES_URL_KEY,
            [UrlPaths.OP_RES_VERSION, UrlPaths.BOOKING_ITEMS_V2],
            bookingItemPatch,
            params
        );
    }

    public doAutoAllocationForBookingItems(criteria: BookingItemSearchCriteria): Observable<OPResponseWrapper<BookingItem>>
    {
        const params = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.post(
            UrlPaths.OP_RES_URL_KEY,
            [UrlPaths.OP_RES_VERSION, UrlPaths.BOOKING_ITEMS_V2, UrlPaths.RECOMMENDED],
            null,
            params
        );
    }

    public doAutoAllocationForServiceItems(criteria: ServiceItemSearchCriteria): Observable<OPResponseWrapper<ServiceItem>>
    {
        const params = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.post(
            UrlPaths.OP_RES_URL_KEY,
            [UrlPaths.OP_RES_VERSION, UrlPaths.SERVICE_ITEMS_V2, UrlPaths.RECOMMENDED],
            null,
            params
        );
    }

    // Assignment Patch
    public patchAssignmentsData(assignmentIds: number[], assignmentPatch: AssignmentPatch)
    {
        const params = this.queryParamsService.asQueryParams({
            assignmentIds
        });
        return this.requestService.patch(
            UrlPaths.OP_RES_URL_KEY,
            [UrlPaths.OP_RES_VERSION, UrlPaths.ASSIGNMENTS_V2, UrlPaths.PATCH_ITEM],
            assignmentPatch,
            params
        );
    }

    // Saved Criteria
    saveSearchCriteria(criteria: SavedCriteriaV2): Observable<OPResponseWrapper<any>>
    {
        const criteriaSaveCriteria = new CriteriaSearchCriteria();
        criteriaSaveCriteria.userId = criteria.userId;
        criteriaSaveCriteria.criteriaName = criteria.criteriaName;
        const params = this.queryParamsService.asQueryParams(criteriaSaveCriteria);
        return this.requestService.post<OPResponseWrapper<any>>(
            UrlPaths.OP_RES_URL_KEY,
            [UrlPaths.OP_RES_VERSION, UrlPaths.CRITERIA_V2],
            criteria.criteria,
            params
        );
    }

    getUserSearchCriteriaList(userId: number): Observable<OPResponseWrapper<SavedCriteriaV2>>
    {
        const criteria = new CriteriaSearchCriteria();
        criteria.userId = userId;
        const params = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.get<OPResponseWrapper<SavedCriteriaV2>>(
            UrlPaths.OP_RES_URL_KEY,
            [UrlPaths.OP_RES_VERSION, UrlPaths.CRITERIA_V2],
            params
        );
    }

    public deleteCriteria(criteriaId: number): Observable<OPResponseWrapper<any>>
    {
        return this.requestService.delete<OPResponseWrapper<any>>(
            UrlPaths.OP_RES_URL_KEY,
            [UrlPaths.OP_RES_VERSION, UrlPaths.CRITERIA_V2, criteriaId.toString()]
        );
    }

    public searchAssignments(resourceId: number, key: DataKey): Observable<OPResponseWrapper<AssignmentSummary>>
    {
        const criteria = new AssignmentSummaryCriteriaV2();
        criteria.resourceId = resourceId;
        const params = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.getWithDataKey<OPResponseWrapper<AssignmentSummary>>(
            key,
            UrlPaths.OP_RES_URL_KEY,
            [UrlPaths.OP_RES_VERSION, UrlPaths.ASSIGNMENT_SUMMARIES],
            params
        );
    }

    uploadBookingItemSheet(fileToUpload: File, templateType: string, dateTimeString: string): Observable<any>
    {
        let endpoint = this.configLoader.configurations.get(TCO.CONF.CONF_ENDPOINT)[UrlPaths.OP_RES_URL_KEY];
        endpoint = this.dataLoaderService.getUrl(
            endpoint,
            [UrlPaths.OP_RES_VERSION, UrlPaths.BOOKING_ITEMS_V2, UrlPaths.UPLOAD]
        );
        return this.fileHandlingService.uploadImage({file: fileToUpload, templateType, serviceDate: dateTimeString}, endpoint);
    }

    public searchFeatureItems(criteria: AllocationFeatureSearchCriteria): Observable<OPResponseWrapper<AllocationFeature>>
    {
        const params = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.getWithDataKey(
            DataKey.allocationFeatureSearchResultsFromService,
            UrlPaths.OP_RES_URL_KEY,
            [UrlPaths.OP_RES_VERSION, UrlPaths.ALLOCATION_FEATURE],
            params
        );
    }

    saveAllocationFeature(feature: AllocationFeature): Observable<OPResponseWrapper<AllocationFeature>>
    {
        return this.requestService.post(
            UrlPaths.OP_RES_URL_KEY,
            [UrlPaths.OP_RES_VERSION, UrlPaths.ALLOCATION_FEATURE],
            feature
        );
    }

    deleteAllocationFeature(serviceType: string): Observable<OPResponseWrapper<AllocationFeature>>
    {
        return this.requestService.delete(
            UrlPaths.OP_RES_URL_KEY,
            [UrlPaths.OP_RES_VERSION, UrlPaths.ALLOCATION_FEATURE, serviceType],
        );
    }

    public searchPassengers(criteria: BookingItemSearchCriteria): Observable<OPResponseWrapper<BookingPassenger>>
    {
        return this.requestService.post(
            UrlPaths.OP_RES_URL_KEY,
            [UrlPaths.OP_RES_VERSION, UrlPaths.BOOKING_ITEMS_V2, UrlPaths.PASSENGERS],
            criteria
        );
    }

    public searchBookingNotes(criteria: BookingItemSearchCriteria): Observable<OPResponseWrapper<BookingNote>>
    {
        return this.requestService.post(
            UrlPaths.OP_RES_URL_KEY,
            [UrlPaths.OP_RES_VERSION, UrlPaths.BOOKING_ITEMS_V2, UrlPaths.NOTES],
            criteria
        );
    }

    public searchSupplementNotes(criteria: BookingItemSearchCriteria): Observable<OPResponseWrapper<BookingSupplement>>
    {
        return this.requestService.post(
            UrlPaths.OP_RES_URL_KEY,
            [UrlPaths.OP_RES_VERSION, UrlPaths.BOOKING_ITEMS_V2, UrlPaths.SUPPLEMENT_NOTES],
            criteria
        );
    }
}
