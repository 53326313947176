export enum PassengerType {
    A = 'A',
    T = 'T',
    C = 'C',
    I = 'I',
}

export enum PassengerTypeName {
    A = 'Adult',
    T = 'Teen',
    C = 'Child',
    I = 'Infant',
}
