import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CurrencyFormat } from '@tc-core/model/it/codegen/ui/framework/currency-format';
import { Pager } from '@tc-core/model/it/codegen/ui/framework/pager';
import { Sorter } from '@tc-core/model/it/codegen/ui/framework/sorter';
import { SummaryCardData } from '@tc-core/model/it/codegen/ui/framework/summary-card-data';
import { ChangeDetectorService } from '../../../services/util/change-detector/change-detector.service';

@Component({
    selector: 'tc-adv-supplier-assign-search-result',
    templateUrl: './adv-supplier-assign-search-result.component.html'
})
export class AdvSupplierAssignSearchResultComponent implements OnInit {

    @Input() sorter: Sorter;
    @Input() sortingHandler: any;
    @Input() sorters: Sorter[] = [];

    @Input() resultsList: Array<SummaryCardData>;
    @Input() selectedSupplier: SummaryCardData = null; // selected supplier card from search results
    @Input() bookingData: any;
    @Input() bookingDataArray: any[];
    @Input() isBulkAssignment: boolean = false;
    @Input() isGenResourceAssignment: boolean = false;
    @Input() assignedSupplier: any; //assigned supplier from direct assignment or early assignments
    @Input() currencyFormat: CurrencyFormat;
    @Input() pager: Pager;

    @Output() supplierSelect: EventEmitter<any> = new EventEmitter();
    @Output() cardScroll: EventEmitter<number> = new EventEmitter<number>();
    @Output() actionClick: EventEmitter<any> = new EventEmitter();
    @Output() outsideSelect: EventEmitter<any> = new EventEmitter();
    @Output() headerClick: EventEmitter<any> = new EventEmitter();

    selectedCard: SummaryCardData;
    footerDataMap: any;

    constructor(
        private changeDetector: ChangeDetectorService
    ) {
    }

    ngOnInit() {
    }

    public onSupplierSelection(summaryCardData: SummaryCardData, index?: number) {
        this.selectedSupplier = summaryCardData;

        if (summaryCardData) {
            this.supplierSelect.emit(this.selectedSupplier);
            this.cardScroll.emit(index);
        }
    }

    onActionClick(summaryCardData, event) {
        this.selectedSupplier = summaryCardData;

        if (summaryCardData) {
            this.actionClick.emit(event);
            this.supplierSelect.emit(this.selectedSupplier);
        }
    }

    public onExpand(summaryCardData: SummaryCardData) {
        this.supplierSelect.emit(summaryCardData);
    }

    public onHeaderClick(summaryCardData: SummaryCardData) {
        this.headerClick.emit(summaryCardData);
        this.selectedCard = summaryCardData;
        this.supplierSelect.emit(summaryCardData);
        this.changeDetector.notifySupplierCostChange();

    }

    public getHeader(client: SummaryCardData) {
        return client.data.value['name'];
    }

    public getSecondaryHeaderText(selectedSupplier: SummaryCardData) {
        return selectedSupplier.data.value['city'] + ' | ' + selectedSupplier.data.value['country'];
    }

    public  onOutsideClick(summaryCardData: SummaryCardData) {
        this.changeDetector.notifySupplierCostChange();
        this.outsideSelect.emit(summaryCardData);
        this.selectedCard = summaryCardData;
        this.supplierSelect.emit(summaryCardData);
    }

    public hasSelectedThis(supplier: SummaryCardData): boolean {
        if (this.assignedSupplier && this.assignedSupplier.data) {
            return (this.assignedSupplier.data.value['supplierId'] === supplier.data.value['supplierId']);
        } else if (this.assignedSupplier) {
            return (this.assignedSupplier.supplierId === supplier.data.value['supplierId']);
        } else {
            return false;
        }
    }
}
