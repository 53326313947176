import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { CurrencyFieldModule } from '@tc/currency-field';
import { AgGridModule } from 'ag-grid-angular';
import { TotalCostEditorComponent } from './total-cost-editor.component';
import {MatInputModule} from "@angular/material/input";
import {SectionHeadingModule} from "@tc/section-heading";

@NgModule({
  declarations: [TotalCostEditorComponent],
    imports: [
        CommonModule,
        CurrencyFieldModule,
        AgGridModule,
        MatInputModule,
        SectionHeadingModule,
        TranslateModule.forChild(),
        FormsModule
    ],
    exports: [
        TotalCostEditorComponent
    ],
    entryComponents: [
        TotalCostEditorComponent
    ]
})
export class TotalCostEditorModule { }
