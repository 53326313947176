/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../node_modules/@tc/loader/loader.component.ngfactory";
import * as i2 from "@tc/loader/loader.component";
import * as i3 from "@angular/common";
import * as i4 from "./dmc-spinner-wrapper.component";
import * as i5 from "@tc-core/util/ui/spinner.service";
import * as i6 from "../../../services/util/framework/data-store.service";
import * as i7 from "@angular/material/snack-bar";
var styles_DMCSpinnerWrapperComponent = [];
var RenderType_DMCSpinnerWrapperComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DMCSpinnerWrapperComponent, data: {} });
export { RenderType_DMCSpinnerWrapperComponent as RenderType_DMCSpinnerWrapperComponent };
function View_DMCSpinnerWrapperComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-loader", [], null, null, null, i1.View_LoaderComponent_0, i1.RenderType_LoaderComponent)), i0.ɵdid(1, 114688, null, 0, i2.LoaderComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_DMCSpinnerWrapperComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_DMCSpinnerWrapperComponent_1)), i0.ɵdid(1, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.show; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_DMCSpinnerWrapperComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-dmc-spinner-wrapper", [], null, null, null, View_DMCSpinnerWrapperComponent_0, RenderType_DMCSpinnerWrapperComponent)), i0.ɵdid(1, 245760, null, 0, i4.DMCSpinnerWrapperComponent, [i5.SpinnerService, i6.DataStoreService, i7.MatSnackBar], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DMCSpinnerWrapperComponentNgFactory = i0.ɵccf("tc-dmc-spinner-wrapper", i4.DMCSpinnerWrapperComponent, View_DMCSpinnerWrapperComponent_Host_0, {}, {}, []);
export { DMCSpinnerWrapperComponentNgFactory as DMCSpinnerWrapperComponentNgFactory };
