<div class="tc-form-edit">
    <div class="detail-group"
         *tcScrollSection="bookingInfoSection;
         id:bookingInfoSection?.id;
         title:bookingInfoSection?.tabTitle;
         error:setupSectionTabError(bookingInfoSection)">
        <div class="detail-group__heading">
            <tc-section-heading
                    [pageSection]="bookingInfoSection"
                    (actionClick)="onHeaderActionClick($event)">
            </tc-section-heading>
        </div>
        <div class="detail-group__content">
            <tc-booking-info
                    [booking]="booking"
                    [readonly]="editable"
                    (changeValidity)="onBookingInfoSectionChange($event)">
            </tc-booking-info>
        </div>
    </div>

    <div class="detail-group"
         *tcScrollSection="supplierKeyControlSection;
         id:supplierKeyControlSection?.id;
         title:supplierKeyControlSection?.tabTitle;
         error:setupSectionTabError(supplierKeyControlSection)">
        <div class="detail-group__heading">
            <tc-section-heading
                    [pageSection]="supplierKeyControlSection"
                    (actionClick)="onHeaderActionClick($event)">
            </tc-section-heading>
        </div>
        <div class="detail-group__content">
            <tc-key-controls-edit-view
                    [object]="booking"
                    [userProfile]="userProfile"
                    [mandatory]="true"
                    [disableKeyControlEdit]="disableKeyControlEdit"
                    (changeFormValidity)="onChangeKeyControlInfoForm($event)">
            </tc-key-controls-edit-view>
        </div>
    </div>

    <div class="detail-group"
         *tcScrollSection="passengersSection;
          id:passengersSection?.id;
          title:passengersSection?.tabTitle;
          error:setupSectionTabError(passengersSection)">
        <div class="detail-group__heading">
            <tc-section-heading
                    [pageSection]="passengersSection"
                    (actionClick)="onHeaderActionClick($event)">
            </tc-section-heading>
        </div>
        <div class="detail-group__content">
            <tc-booking-passengers
                #passengersComponent
                [booking]="booking"
                [readonly]="readonly"
                [titles]="titles"
                [passengerTypes]="passengerTypes"
                (deletePassenger)="onDeletePassenger($event)"
                (changeValidity)="onPassengerSectionChange($event)">
            </tc-booking-passengers>
        </div>
    </div>

    <div class="detail-group"
         *tcScrollSection="bookingItemSection;
          id:bookingItemSection?.id;
          title:bookingItemSection?.tabTitle;
          error:setupSectionTabError(bookingItemSection)">
        <div class="detail-group__heading">
            <tc-section-heading
                    [pageSection]="bookingItemSection"
                    [kebabItems]="itemAddingKebabMenu">
            </tc-section-heading>
        </div>
        <div class="">
            <tc-booking-items
                    [booking]="booking"
                    [readonly]="readonly"
                    [summaryCards]="bookingItemSummaryCards"
                    [noteTypes]="bookingNoteTypes"
                    [trsModes]="trsModes"
                    (passengersClick)="onItemPassengersClick($event)"
                    (deleteItem)="onBookingItemDelete($event)"
                    (editAsNewItem)="editAsNewItem($event)"
                    (changeValidity)="onBookingItemSectionChange($event)">
            </tc-booking-items>
        </div>
    </div>

    <div class="detail-group"
         *tcScrollSection="bookingNoteSection;
          id:bookingNoteSection?.id;
          title:bookingNoteSection?.tabTitle;
          error:setupSectionTabError(bookingNoteSection)">
        <div class="detail-group__heading">
            <tc-section-heading
                    [pageSection]="bookingNoteSection"
                    (actionClick)="onHeaderActionClick($event)">
            </tc-section-heading>
        </div>
        <tc-item-notes
                [readonly]="readonly"
                [noteContents]="noteContents"
                [noteSummary]="noteSummary"
                [masterNoteTypes]="masterNoteTypes"
                [noteList]="booking.bookingNotes"
                [heading]="''"
                (changeValidity)="onBookingNoteSectionChange($event)"
                class="bdv-booking-notes-summary-wrapper">
        </tc-item-notes>

        <!--            <tc-booking-notes-summary-->
        <!--                    id="BOOKING_NOTES_VIEW"-->
        <!--                    [summaryContent]="bookingView?.notesSummary"-->
        <!--                    [summaryContentList]="bookingView?.notes"-->
        <!--                    [heading]="'Notes'"-->
        <!--                    [showHeader]="showHeader"-->
        <!--                    [allowNoteOperations]="allowNoteOperations"-->
        <!--                    [disableEditDelete]="disableNotesEditDelete"-->
        <!--                    [showHeading]="false"-->
        <!--                    [isCombineNoteView]="enableCombineNote"-->
        <!--                    [authConfigComponent]="authConfigComponent + '-notes'"-->
        <!--                    (editClick)="onNotesEditClick($event)"-->
        <!--                    (deleteClick)="onNotesDeleteClick($event)"-->
        <!--                    (selectedNote)="onNoteSelection($event)"-->
        <!--                    (addNoteClick)="onNoteAddClick()"-->
        <!--                    (addRemarksClick)="onAddRemarksClick()"-->
        <!--                    class="bdv-booking-notes-summary-wrapper">-->
        <!--            </tc-booking-notes-summary>-->
    </div>
</div>



