<div class="tc-form-edit">

    <div
            class="detail-group"
            *tcScrollSection="vehicleInfoSection;id:vehicleInfoSection?.id;title:vehicleInfoSection?.tabTitle;error:setupSectionTabError(vehicleInfoSection)">
        <div
                class="detail-group__heading">
            <tc-section-heading
                    [pageSection]="vehicleInfoSection"
                    (actionClick)="onHeaderActionClick($event)">
            </tc-section-heading>
        </div>
        <div class="detail-group__content">
            <tc-vehicle-specific-information
                    [resourceSpecificInformationModel]="resource"
                    [transferModes]="transferModes"
                    [driverName]="driverName"
                    [fuelTypeOptions]="fuelTypeOptions"
                    [filteredSuppliers]="filteredSuppliers"
                    [filteredDrivers]="filteredDrivers"
                    [supplierTypes]="supplierTypes"
                    [assignedSupplier]="assignedSupplierForResource"
                    [assignedDriver]="assignedDriverForResource"
                    [vehicleModels]="vehicleModels"
                    [missingCostForSupplier] = "missingCostForSupplier"
                    [filteredUsers]="filteredUsers"

                    (supplierClear)="onSupplierClear($event)"
                    (supplierAssign)="onSupplierAssign($event)"
                    (supplierTextChange)="onSupplierTextChange($event)"

                    (driverClear)="onDriverClear($event)"
                    (driverAssign)="onDriverAssign($event)"
                    (driverTextChange)="onDriverTextChange($event)"

                    (userTextChange)="onUserTextChange($event)"
                    (userClear)="onUserClear($event)"
                    (userAssign)="onUserAssign($event)"

                    (changeFormValidity)="onChangeVehicleInfoForm($event)">
            </tc-vehicle-specific-information>
        </div>
    </div>
    <div
            class="detail-group"
            *tcScrollSection="keyControlSection;id:keyControlSection?.id;title:keyControlSection?.tabTitle;error:setupSectionTabError(keyControlSection)">
        <div
                class="detail-group__heading">
            <tc-section-heading
                    [pageSection]="keyControlSection"
                    (actionClick)="onHeaderActionClick($event)">
            </tc-section-heading>
        </div>
        <div class="detail-group__content">
            <tc-key-controls-edit-view
                    [object]="resource"
                    [userProfile]="userProfile"
                    [disableKeyControlEdit]="disableKeyControlEdit"
                    (changeFormValidity)="onChangeKeyControlInfoForm($event)">
            </tc-key-controls-edit-view>
        </div>
    </div>

    <div
            class="detail-group"
            *tcScrollSection="contactDetailsSection;id:contactDetailsSection?.id;title:contactDetailsSection?.tabTitle;error:setupSectionTabError(contactDetailsSection)">
        <div
                class="detail-group__heading">
            <tc-section-heading
                    [pageSection]="contactDetailsSection"
                    (actionClick)="onHeaderActionClick($event)">
            </tc-section-heading>
        </div>
        <div class="detail-group__content">
            <tc-contact-details
                    [titles]="titles"
                    [categories]="categories"
                    [contactDetails]="contactDetails"
                    [defaultContactCategory]="defaultContactCategory"

                    [cityList]="cityList"
                    [stateList]="stateList"
                    [countryList]="countryList"
                    [countryStateCityChangeMap]="countryStateCityChangeMap"
                    (newCountryStateCityObserver)="onNewCountryStateCityObserver($event)"
                    (selectCountry)="onSelectCountry($event)"
                    (selectState)="onSelectState($event)"
                    (selectCity)="onSelectCity($event)"
                    (countryTextChange)="onCountryTextChange($event)"
                    (stateTextChange)="onStateTextChange($event)"
                    (cityTextChange)="onCityTextChange($event)"

                    (changeFormValidity)="onChangeVehicleContactForm($event)">
            </tc-contact-details>
        </div>
    </div>

    <div
            class="detail-group"
            *tcScrollSection="paymentInfoSection;id:paymentInfoSection?.id;title:paymentInfoSection?.tabTitle;error:setupSectionTabError(paymentInfoSection)">
        <div
                class="detail-group__heading">
            <tc-section-heading
                    [pageSection]="paymentInfoSection"
                    (actionClick)="onHeaderActionClick($event)">
            </tc-section-heading>
        </div>
        <div class="detail-group__content">
            <tc-payment-information
                    [currencyList]="currencyList"
                    [payMethods]="payMethods"
                    [paymentInfo]="resource?.paymentInfo"
                    (changeFormValidity)="onChangePaymentForm($event)">
            </tc-payment-information>
        </div>
    </div>

    <div
            class="detail-group"
            *tcScrollSection="vehicleAmenitiesSection;id:vehicleAmenitiesSection?.id;title:vehicleAmenitiesSection?.tabTitle;error:setupSectionTabError(vehicleAmenitiesSection)">
        <div
                class="detail-group__heading">
            <tc-section-heading
                    [pageSection]="vehicleAmenitiesSection"
                    (actionClick)="onHeaderActionClick($event)">
            </tc-section-heading>
        </div>
        <div class="detail-group__content">
            <tc-vehicle-amenities
                    [facilities]="resource?.vehicle?.facilities"
                    [defaultFacilityDetails]="defaultFacilityDetails"
                    (changeFormValidity)="onChangeVehicleAmenities()">
            </tc-vehicle-amenities>
        </div>
    </div>

    <div
            class="detail-group"
            *tcScrollSection="insuranceInfoSection;id:insuranceInfoSection?.id;title:insuranceInfoSection?.tabTitle;error:setupSectionTabError(insuranceInfoSection)">
        <div
                class="detail-group__heading">
            <tc-section-heading
                    [pageSection]="insuranceInfoSection"
                    (actionClick)="onHeaderActionClick($event)">
            </tc-section-heading>
        </div>
        <div class="detail-group__content">
            <tc-insurance-information
                    [insuranceInformationModel]="resource?.vehicle?.resInsuranceInfo"

                    [cityList]="cityList"
                    [stateList]="stateList"
                    [countryList]="countryList"
                    [countryStateCityChangeMap]="countryStateCityChangeMap"
                    (newCountryStateCityObserver)="onNewCountryStateCityObserver($event)"
                    (selectCountry)="onSelectCountry($event)"
                    (selectState)="onSelectState($event)"
                    (selectCity)="onSelectCity($event)"
                    (countryTextChange)="onCountryTextChange($event)"
                    (stateTextChange)="onStateTextChange($event)"
                    (cityTextChange)="onCityTextChange($event)"

                    (changeFormValidity)="onChangeInsuranceInformation($event)">
            </tc-insurance-information>

        </div>
    </div>
    <div
            class="detail-group"
            *tcScrollSection="serviceInfoSection;id:serviceInfoSection?.id;title:serviceInfoSection?.tabTitle;error:setupSectionTabError(serviceInfoSection)">
        <div
                class="detail-group__heading">
            <tc-section-heading
                    [pageSection]="serviceInfoSection"
                    (actionClick)="onHeaderActionClick($event)">
            </tc-section-heading>
        </div>
        <div class="detail-group__content">
            <tc-service-information
                    [serviceInformationModel]="resource?.vehicle?.resServiceInfo"
                    (changeFormValidity)="onChangeServiceInformation($event)">
            </tc-service-information>

        </div>
    </div>
    <div
            class=""
            *tcScrollSection="additionalContactsSection;id:additionalContactsSection?.id;title:additionalContactsSection?.tabTitle;error:setupSectionTabError(additionalContactsSection)">
        <div
                class="">
            <tc-section-heading
                    [pageSection]="additionalContactsSection"
                    (actionClick)="onHeaderActionClick($event)">
            </tc-section-heading>
        </div>
        <div class="">
            <tc-additional-contacts
                    [additionalContactList]="additionalContactDetails"
                    [titles]="titles"
                    [categories]="categories"
                    [additionalContactSummary]="additionalContactSummary"
                    [additionalContactContents]="additionalContactContents"
                    [defaultContactCategory]="defaultContactCategory"

                    [cityList]="cityList"
                    [stateList]="stateList"
                    [countryList]="countryList"
                    [countryStateCityChangeMap]="countryStateCityChangeMap"
                    (newCountryStateCityObserver)="onNewCountryStateCityObserver($event)"
                    (selectCountry)="onSelectCountry($event)"
                    (selectState)="onSelectState($event)"
                    (selectCity)="onSelectCity($event)"
                    (countryTextChange)="onCountryTextChange($event)"
                    (stateTextChange)="onStateTextChange($event)"
                    (cityTextChange)="onCityTextChange($event)"
                    (changeFormValidity)="onAdditionalContactsValidChange($event)"
                    (addressModified)="onAdditionalContactsModified()">
            </tc-additional-contacts>
        </div>
    </div>

    <div
            class="detail-group"
            *tcScrollSection="supplierAttributesSection;id:supplierAttributesSection?.id;title:supplierAttributesSection?.tabTitle;error:setupSectionTabError(supplierAttributesSection)">
        <div
                class="detail-group__heading">
            <tc-section-heading
                    [pageSection]="supplierAttributesSection"
                    (actionClick)="onHeaderActionClick($event)">
            </tc-section-heading>
        </div>
        <div class="detail-group__content">
            <tc-supplier-attributes
                    [supplierAttributes]="resource?.supplierAttributes? resource.supplierAttributes:[]">
            </tc-supplier-attributes>
        </div>
    </div>
    <div
            class="detail-group"
            *tcScrollSection="notesSection;id:notesSection?.id;title:notesSection?.tabTitle;error:setupSectionTabError(notesSection)">
        <div
                class="detail-group__heading">
            <tc-section-heading
                    [pageSection]="notesSection"
                    (actionClick)="onHeaderActionClick($event)">
            </tc-section-heading>
        </div>
        <div class="detail-group__content">
            <tc-remarks
                    [noteList]="resource?.remarks ? resource?.remarks : []"
                    (noteChanged)="onNotesModified()">
            </tc-remarks>
        </div>
    </div>
    <div
            class="detail-group"
            *tcScrollSection="taxSchemeSection;id:taxSchemeSection?.id;title:taxSchemeSection?.tabTitle;error:setupSectionTabError(taxSchemeSection)">
        <div
                class="detail-group__heading">
            <tc-section-heading
                    [pageSection]="taxSchemeSection"
                    (actionClick)="onHeaderActionClick($event)">
            </tc-section-heading>
        </div>
        <div class="detail-group__content">
            <tc-tax-schemes
                    [forAResourceProfile]="true"
                    [taxSchemeList]="resource?.taxSchemes ? resource.taxSchemes : []"
                    [taxSchemes]="taxSchemes"
                    [taxSchemeContents]="taxSchemeContents"
                    [taxSchemeSummary]="taxSchemeSummary"
                    [resourceType]="resourceType"
                    [supplierItems]=""
                    (changeFormValidity)="onChangeTaxSchemeFormValidity()"
                    (taxSchemeModified)="onTaxSchemeModified()">
            </tc-tax-schemes>
        </div>
    </div>
    <div
            class=""
            *tcScrollSection="documentSection;id:documentSection?.id;title:documentSection?.tabTitle;error:setupSectionTabError(documentSection)">
        <div
                class="">
            <tc-section-heading
                    [pageSection]="documentSection"
                    (actionClick)="onHeaderActionClick($event)">
            </tc-section-heading>
        </div>
        <div class="">
            <tc-document-view-card
                    [formatOptions]="formatOptions"
                    [frequencyOptions]="frequencyOptions"
                    [contactCategoryOptions]="categories"
                    [dispatchTypes]="dispatchTypes"
                    [docTypeOptions]="docTypeOptions"
                    [documentList]="resource?.documents ? resource.documents: []"
                    [documentContents]="documentContents"
                    [documentSummary]="documentSummary"
                    (documentsModified)="onDocumentsModified()">
            </tc-document-view-card>
        </div>
    </div>
</div>
