export class DocumentCreationCriteria {
    documentType: DocumentType;
    documentFormat: DocumentFormat;
    documentName: string;
    persist: boolean;
    notifySupplier: boolean;

    download: boolean;

    overrideDispatchTime: Date;
    overrideEmailAddress: string;
    overrideEmailBody: string;
    overrideEmailSubject: string;
    quickDispatch: boolean;
    scheduled: boolean;
}

export enum DocumentFormat {
    ANY = 'ANY',
    pdf = 'pdf',
    html = 'html',
    excel = 'excel',
    csv = 'csv'
}

export enum DocumentType {
    supplierManifest = 'SUPPLIER_MANIFEST',
    supplierManifestV2 = 'SUPPLIER_MANIFEST_V2',
    arrivalList = 'ARRIVAL_LIST_REPORT',
    arrivalListV2 = 'ARRIVAL_LIST_REPORT_V2',
    departureList = 'DEPARTURE_LIST_REPORT',
    departureListV2 = 'DEPARTURE_LIST_REPORT_V2',
    trsExportReport = 'TRS_EXPORT_REPORT_V1',
    genExportReport = 'GEN_EXPORT_REPORT_V1',
    tourList = 'TOUR_LIST_REPORT',
    tourListV2 = 'TOUR_LIST_REPORT_V2',
    SUPPLIER_GEN_COST_SHEET = 'SUPPLIER_GEN_COST_SHEET',
    SUPPLIER_TRAN_COST_SHEET = 'SUPPLIER_TRAN_COST_SHEET',
    queueV2ExportReport = 'QUEUE_V2_EXPORT_REPORT',
    queueV2ServicingViewExportReport = 'QUEUE_V2_SERVICING_REPORT',
    queueV2BookingViewExportReport = 'QUEUE_V2_BOOKING_REPORT'
}
