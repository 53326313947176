import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatButtonModule, MatCheckboxModule } from '@angular/material';
import { TranslateModule } from '@ngx-translate/core';
import { ContentStripModule } from '@tc/content-strip';
import { DirectivesModule } from '@tc/directives';
import { NoteModule } from '../note/note.module';
import { NotesComponent } from './notes.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    MatCheckboxModule,
    MatButtonModule,
    NoteModule,
    ContentStripModule,
    DirectivesModule,
    FormsModule
  ],
  declarations: [NotesComponent],
  exports:[NotesComponent]
})
export class NotesModule { }
