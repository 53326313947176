<form [formGroup]="supplierCostChangeForm">
  <div class="container-fluid cost-break-down">
    <div class="row" *ngIf="summaryCardData?.data?.value?.calculateCost?.adultCost">
      <div class="cost-break-down__detail-view">
        <div class="fixed-column">
          <!-- header -->
          <div class="row table-header pbc-item-header item-header">
            <div class="heading item-header__content"></div>
          </div>

          <!-- body -->
          <div class="row pbc-item-header item-header">
            <div class="heading item-header__content item-header__price">
              <span>{{'Adult' | translate}}</span>
            </div>
          </div>
          <div class="row pbc-item-header item-header">
            <div class="heading item-header__content item-header__price">
              <span>{{'Child' | translate}}</span>
            </div>
          </div>
          <div class="row pbc-item-header item-header">
            <div class="heading item-header__content item-header__price">
              <span>{{'Infant' | translate}}</span>
            </div>
          </div>
          <div class="row pbc-item-header item-header">
            <div class="heading item-header__content item-header__price">
              <span>{{'Grand Total' | translate}}</span>
            </div>
          </div>
        </div>
        <div class="fixed-column">

          <!-- header -->
          <div class="row table-price-field">
            <div class="item-header__content">{{'Passenger Count' | translate}}</div>
          </div>

          <!-- body -->
          <div class="row table-price-field item-header">
            <div class="item-header__content item-header__price">
              <span>{{paxCount.adultCount}}</span>
            </div>
          </div>
          <div class="row table-price-field item-header">
            <div class="item-header__content item-header__price">
              <span>{{paxCount.childCount}}</span>
            </div>
          </div>
          <div class="row table-price-field item-header">
            <div class="item-header__content item-header__price">
              <span>{{paxCount.infantCount}}</span>
            </div>
          </div>
          <div class="row table-price-field item-header">
            <div class="item-header__content item-header__price">
            </div>
          </div>
        </div>

        <div class="fixed-column">

          <!-- header -->
          <div class="row table-price-field">
            <div class="item-header__content">{{'Cost' | translate}} ( {{currencyFormat.currencyCode}} )</div>
          </div>

          <!-- body -->
          <div class="row table-price-field item-header">
            <div class="assign-panel-table__price">
              <div class="payment__info-currency-input assign-panel-table__price-input">
                <tc-currency-field
                        [amount]="summaryCardData?.data?.value?.calculatedCost?.adultCost"
                        [disableRevert]="true"
                        [showPreviousAmount]="false"
                        formControlName="adult"
                        [currencyFormat]="currencyFormat"
                        [readOnly]="true"
                        (onInputValueChange)="onAdultCostChange($event)">
                </tc-currency-field>
              </div>
            </div>
          </div>
          <div class="row table-price-field item-header">
            <div class="assign-panel-table__price">
              <div class="payment__info-currency-input assign-panel-table__price-input">
                <tc-currency-field
                        [amount]="summaryCardData?.data?.value?.calculatedCost?.childCost"
                        [disableRevert]="true"
                        [showPreviousAmount]="false"
                        formControlName="child"
                        [currencyFormat]="currencyFormat"
                        [readOnly]="true"
                        (onInputValueChange)="onChildCostChange($event)">
                </tc-currency-field>
              </div>
            </div>
          </div>
          <div class="row table-price-field item-header">
            <div class="assign-panel-table__price">
              <div class="payment__info-currency-input assign-panel-table__price-input">
                <tc-currency-field
                        [amount]="summaryCardData?.data?.value?.calculatedCost?.infantCost"
                        [disableRevert]="true"
                        [showPreviousAmount]="false"
                        formControlName="infant"
                        [currencyFormat]="currencyFormat"
                        [readOnly]="true"
                        (onInputValueChange)="onInfantCostChange($event)">
                </tc-currency-field>
              </div>
            </div>
          </div>
          <div class="row table-price-field item-header">
            <div class=" ">
            </div>
          </div>
        </div>
        <div class="fixed-column">

          <!-- header -->
          <div class="row table-price-field">
            <div class="item-header__content">{{'Total' | translate}}</div>
          </div>

          <!-- body -->
          <div class="row table-price-field">
            <div class="item-header__content item-header__price assign-panel-table__price">
              <span>{{assignedSupplier?.calculatedCost?.totalAdultCost | tcCurrencyPipe:currencyFormat?.currencyCode:"code":currencyFormat?.pattern}}</span>
            </div>
          </div>
          <div class="row table-price-field">
            <div class="item-header__content item-header__price assign-panel-table__price">
              <span>{{assignedSupplier?.calculatedCost?.totalChildCost| tcCurrencyPipe:currencyFormat?.currencyCode:"code":currencyFormat?.pattern}}</span>
            </div>
          </div>
          <div class="row table-price-field">
            <div class="item-header__content item-header__price assign-panel-table__price">
              <span>{{assignedSupplier?.calculatedCost?.totalInfantCost| tcCurrencyPipe:currencyFormat?.currencyCode:"code":currencyFormat?.pattern}}</span>
            </div>
          </div>
          <div class="row table-price-field">
            <div class="item-header__content item-header__price assign-panel-table__price-grand-total">
              <span>{{assignedSupplier?.calculatedCost?.totalCost| tcCurrencyPipe:currencyFormat?.currencyCode:"code":currencyFormat?.pattern}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row"  *ngIf="summaryCardData?.data?.value?.calculatedCost?.unitCost">
      <div class="cost-break-down__detail-view">
        <div class="fixed-column">
          <!-- header -->
          <div class="row table-header pbc-item-header item-header">
            <div class="heading item-header__content "></div>
          </div>

          <!-- body -->
          <div class="row pbc-item-header item-header">
            <div class="heading item-header__content item-header__price">
              <span>{{'Unit' | translate}}</span>
            </div>
          </div>
          <div class="row pbc-item-header item-header">
            <div class="heading item-header__content item-header__price">
              <span>{{'Grand Total' | translate}}</span>
            </div>
          </div>
        </div>
        <div class="fixed-column">

          <!-- header -->
          <div class="row table-price-field">
            <div class="item-header__content">{{'Passenger Count' | translate}}</div>
          </div>

          <!-- body -->
          <div class="row table-price-field item-header">
            <div class="item-header__content">
              <span>{{paxCount.totalPax}}</span>
            </div>
          </div>
          <div class="row table-price-field item-header">
            <div class=" item-header__content">
            </div>
          </div>
        </div>

        <div class="fixed-column">

          <!-- header -->
          <div class="row table-price-field ">
            <div class="item-header__content">{{'Cost' | translate}} ( {{currencyFormat.currencyCode}} )</div>
          </div>

          <!-- body -->
          <div class="row table-price-field item-header">
            <div class="assign-panel-table__price">
              <div class="payment__info-currency-input assign-panel-table__price-input">
                <tc-currency-field
                        [amount]="summaryCardData?.data?.value?.calculatedCost?.unitCost"
                        [disableRevert]="true"
                        [showPreviousAmount]="false"
                        formControlName="unit"
                        [currencyFormat]="currencyFormat"
                        [readOnly]="true"
                        (onInputValueChange)="onUnitCostChange($event)">
                </tc-currency-field>
              </div>
            </div>
          </div>
          <div class="row table-price-field item-header">
            <div class="item-header__content item-header__price">
            </div>
          </div>
        </div>
        <div class="fixed-column">

          <!-- header -->
          <div class="row table-price-field">
            <div class="item-header__content">{{'Total' | translate}}</div>
          </div>

          <!-- body -->
          <div class="row table-price-field">
            <div class="item-header__content item-header__price">
              <span>{{assignedSupplier?.calculatedCost?.totalCost | tcCurrencyPipe:currencyFormat?.currencyCode:"code":currencyFormat?.pattern}}</span>
            </div>
          </div>
          <div class="row table-price-field">
            <div class="item-header__content item-header__price assign-panel-table__price-grand-total">
              <span>{{assignedSupplier?.calculatedCost?.totalCost| tcCurrencyPipe:currencyFormat?.currencyCode:"code":currencyFormat?.pattern}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
<!--    <div class="row" *ngIf="!isGenResourceAssignment">-->
<!--      <div class="col-xs-12 col-sm-12  col-md-3 col-lg-3">-->
<!--        <div class="tc-flex-box">-->
<!--          <div class="tc-flex-item input-cage ">-->
<!--            <div class="tc-content&#45;&#45;wrap tc-input-view">-->
<!--              <div-->
<!--                      id="TRD_PER_INFO_COU"-->
<!--                      class="tc-caption-txt txt-light">{{"Vehicle Capacity" | translate}}-->
<!--              </div>-->
<!--              <div class="tc-body1&#45;&#45;txt">-->
<!--                <span>{{summaryCardData?.data?.value?.capacity ? summaryCardData?.data?.value?.capacity : EMPTY_CHAR}}</span>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
  </div>
</form>
