import { Component, Input, OnInit } from '@angular/core';
import { TC } from '@tc-core/util/constants';
import { ConfigLoader } from '@tc-core/util/framework/config-loader.service';
import { TCO } from '../../../../constants';
import { Booking } from '../../../../models/reservation-v2/booking';

@Component({
  selector: 'tc-booking-detail-summary-view',
  templateUrl: './booking-detail-summary-view.component.html'
})
export class BookingDetailSummaryViewComponent implements OnInit {

  @Input() booking: Booking;

  EMPTY_CHAR = TC.FORMAT.EMPTY_CHAR;
  passengerCount = {Adult: 0, Children: 0, Teen: 0, Infant: 0};
  bookingItemsCountByCategory = {GEN: 0, TRS: 0};

  DATE_FORMAT = 'yyyy-MM-dd h:mm a';

  constructor( private configLoader: ConfigLoader) {
  }

  ngOnInit() {
    this.DATE_FORMAT = this.configLoader.configurations.get(TCO.CONF.CONF_FORMAT).date['display-format'];

    this.getPassengersCount();
    this.getBookingItemCountByType();
  }

  getPassengersCount() {
    this.passengerCount = {Adult: 0, Children: 0, Teen: 0, Infant: 0};
    this.booking.bookingPassengers.forEach(passenger => {
      if (passenger.type === 'A') {
        this.passengerCount.Adult = this.passengerCount.Adult + 1;
      } else if (passenger.type === 'C') {
        this.passengerCount.Children = this.passengerCount.Children + 1;
      } else if (passenger.type === 'T') {
        this.passengerCount.Teen = this.passengerCount.Teen + 1;
      } else if (passenger.type === 'I') {
        this.passengerCount.Infant = this.passengerCount.Infant + 1;
      }
    });
  }

  getBookingItemCountByType() {
    this.bookingItemsCountByCategory = {GEN: 0, TRS: 0};
    this.booking.bookingItems.forEach(item => {
      if (item.mainType === 'TRS') {
        this.bookingItemsCountByCategory.TRS = this.bookingItemsCountByCategory.TRS + 1;
      } else if (item.mainType === 'GEN') {
        this.bookingItemsCountByCategory.GEN = this.bookingItemsCountByCategory.GEN + 1;
      }
    });
  }

  getTimeZone(): string {
    if (this.booking.startTime) {
      const offset = this.booking.startTime.toString().split('+')[1];
      return '+' + (offset.split(':').join(''));
    }
    return '+0000';
  }
}
