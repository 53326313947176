import { Component } from '@angular/core';
import { ICellEditorAngularComp, ICellRendererAngularComp } from 'ag-grid-angular';
import { GridCommonUtil } from '../../utils/grid-common-util';

@Component({
    selector: 'tc-check-renderer-editor',
    templateUrl: './check-box-renderer-editor.component.html'
})
export class CheckBoxRendererEditorComponent implements ICellRendererAngularComp {
    selected: boolean = false;
    params: any;
    isError = false;
    disabled = true;
    row: any;
    column: string;
    afterEdit: any;
    editableFunc: any;

    constructor() { }

    public afterGuiAttached(params?: any): void {
    }

    public agInit(params: any): void {
        this.params = params;
        this.selected = GridCommonUtil.toBoolean(params['value']);
        this.disabled = !params['colDef']['editInRenderer'];
        this.row = params['data'];
        this.column = params['colDef']['colId'];
        if ( params['afterEdit']){
            this.afterEdit = params['afterEdit'];
        }
        this.editableFunc = params['colDef']['editInRendererFunc'];
        if (this.editableFunc){
            this.disabled = !this.editableFunc(params);
        }
        // if ( params['data'] && params['data']['dispatchStatus']  && params['data']['dispatchStatus'] === 'SUCCESS'){
        //     this.disabled = true;
        // }else {
        //     this.disabled = false;
        // }
        //this.isError = this.params.data.isInvalid;
    }

    public refresh(params: any): boolean {
        return false;
    }

    public onChange($event) {
        const oldValue = this.row[this.column];
        this.row[this.column] = this.selected;
        const cellValueChangedEvent = {
            newValue: this.row[this.column],
            oldValue: oldValue,
            data: this.row
        };
        if (typeof this.afterEdit === 'function'){
            this.afterEdit(cellValueChangedEvent);
        }
    }
    getValue() {
        return this.selected;
    }

    isPopup(): boolean {
        return false;
    }
}

export interface CheckboxActionRenderer {
    disabled: any;
}
