import {UserBusinessType} from './user-business-type';

export class UserServiceProfile {
    userId: number;
    resourceId: number;
    supplierId: number;
    firstName = '';
    lastName = '';
    username = '';
    gender: string;
    email = '';
    countryCode = '';
    mobileNumber = '';
    supplierCode = '';
    supplierName = '';
    businessType: UserBusinessType;
    serviceType = '';
    serviceTypeCode = '';
    serviceTypeName: string;
    approvalStatus: ApprovalStatus;
    resourceSummary: any;
    resourceProfile;
    imageUrl: string;
    onlineStatus: boolean;
    userAppRule: any;
    kcParent: string;
    kcCompany: string;
    kcDivision: string;
    kcBrand: string;
    kcDistributionChannel: string;
}

export enum ApprovalStatus {
    PENDING = 'PENDING',
    APPROVED = 'APPROVED',
    REJECTED = 'REJECTED',
    DEACTIVATED = 'DEACTIVATED',
}
