import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ResultContentCardComponent } from '../result-content-card/result-content-card.component';
var ResultContentCardGenericComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ResultContentCardGenericComponent, _super);
    function ResultContentCardGenericComponent() {
        return _super.call(this) || this;
    }
    ResultContentCardGenericComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
        this.overrideStyles();
    };
    ResultContentCardGenericComponent.prototype.overrideStyles = function () {
        this.row1col1_customsStyles = ["tc-strip-text--primary"];
        this.row1col2_customsStyles = ["tc-strip-text--primary"];
        this.row1col3_customsStyles = ["tc-strip-text--primary"];
        this.row1col4_customsStyles = ["tc-strip-text--primary", "tc-strip-text--right"];
        this.row2col1_customsStyles = ["tc-strip-text--primary"];
        this.row2col2_customsStyles = ["tc-strip-text--primary"];
        this.row2col3_customsStyles = ["tc-strip-text--primary"];
        this.row2col4_customsStyles = ["tc-strip-text--primary", "tc-strip-text--right"];
    };
    return ResultContentCardGenericComponent;
}(ResultContentCardComponent));
export { ResultContentCardGenericComponent };
