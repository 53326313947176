/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "./supplier-attributes.component";
var styles_SupplierAttributesComponent = [];
var RenderType_SupplierAttributesComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SupplierAttributesComponent, data: {} });
export { RenderType_SupplierAttributesComponent as RenderType_SupplierAttributesComponent };
export function View_SupplierAttributesComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "div", [["class", "container-fluid direct-client-personal-info-edit-card tc-card-wrapper"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 9, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 8, "div", [["class", "col-md-12 col-lg-12"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 7, "div", [["class", "tc-flex-box"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 3, "div", [["class", "tc-flex-item tc-item-circle"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 2, "div", [["class", "icon-box tc-mb-0"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 1, "i", [["aria-hidden", "true"], ["class", "material-icons"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["menu"])), (_l()(), i0.ɵeld(8, 0, null, null, 2, "div", [["class", "tc-flex-item tc-right-cage"]], null, null, null, null, null)), (_l()(), i0.ɵted(9, null, [" ", " ", " "])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.supplierAttributes ? _co.supplierAttributes.length : ""); var currVal_1 = i0.ɵunv(_v, 9, 1, i0.ɵnov(_v, 10).transform("Supplier Attributes")); _ck(_v, 9, 0, currVal_0, currVal_1); }); }
export function View_SupplierAttributesComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-supplier-attributes", [], null, null, null, View_SupplierAttributesComponent_0, RenderType_SupplierAttributesComponent)), i0.ɵdid(1, 114688, null, 0, i2.SupplierAttributesComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SupplierAttributesComponentNgFactory = i0.ɵccf("tc-supplier-attributes", i2.SupplierAttributesComponent, View_SupplierAttributesComponent_Host_0, { supplierAttributes: "supplierAttributes" }, {}, []);
export { SupplierAttributesComponentNgFactory as SupplierAttributesComponentNgFactory };
