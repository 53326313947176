import { Component, Input, OnInit } from '@angular/core';
import { DataKey, DataStoreService } from '../../../../services/util/framework/data-store.service';

/**
 * For automatically generate passengers
 */
@Component({
    selector: 'tc-passenger-generation',
    templateUrl: './passenger-generation.component.html'
})
export class PassengerGenerationComponent implements OnInit {

    @Input() readonly = false;
    @Input() onChangeFn: any;

    disableClose = false;
    adultCount = 0;
    childCount = 0;
    teenCount = 0;
    infantCount = 0;

    constructor(
        private dataStore: DataStoreService
    ) {
    }

    ngOnInit() {
    }

    public onAdultCountUpdate($event: Event) {
        this.adultCount = +$event;
    }

    public onChildCountUpdate($event: Event) {
        this.childCount = +$event;
    }

    public onInfantCountUpdate($event: Event) {
        this.infantCount = +$event;
    }

    getTotalPassengersToGenerate() {
        return +this.adultCount + +this.childCount + +this.teenCount + +this.infantCount;
    }

    public onClose(event: MouseEvent) {
        this.dataStore.set(DataKey.popupClose, true);
    }

    public onSave() {
        const passengers = {
            adults: this.adultCount,
            teens: this.teenCount,
            children: this.childCount,
            infants: this.infantCount
        };
        this.onChangeFn(passengers);
        this.dataStore.set(DataKey.popupClose, true);
    }

    public isSaveButtonEnable() {
        return (this.adultCount + this.childCount + this.teenCount + this.infantCount) > 0;
    }
}
