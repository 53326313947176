import { HttpClient } from '@angular/common/http';
import { DateTimeProcessor } from '../../business-utils/time-zone/date-time-processor';
import { DataKey, DataStoreService } from '../../util/framework/data-store.service';
import { DMCQueryParamsService } from '../../util/framework/dmc-query-params.service';
import { RequestService } from '../../util/framework/request.service';
import { UrlPaths } from '../url-paths';
import * as i0 from "@angular/core";
import * as i1 from "../../util/framework/dmc-query-params.service";
import * as i2 from "../../util/framework/request.service";
import * as i3 from "@angular/common/http";
import * as i4 from "../../util/framework/data-store.service";
var BookingManagementService = /** @class */ (function () {
    function BookingManagementService(queryParamsService, requestService, http, dataStore) {
        this.queryParamsService = queryParamsService;
        this.requestService = requestService;
        this.http = http;
        this.dataStore = dataStore;
    }
    BookingManagementService.prototype.searchBookingSummaries = function (bookingSearchCriteria) {
        DateTimeProcessor.processBookingSearchCriteriaDateTimes(bookingSearchCriteria);
        var params = this.queryParamsService.asQueryParams(bookingSearchCriteria);
        return this.requestService.getWithDataKey(DataKey.bookingSummarySearchResultsFromService, UrlPaths.OP_RES_URL_KEY, [UrlPaths.OP_RES_VERSION, UrlPaths.BOOKING_SUMMARIES], params);
    };
    BookingManagementService.prototype.searchBookingSummariesOnMore = function (bookingSearchCriteria) {
        DateTimeProcessor.processBookingSearchCriteriaDateTimes(bookingSearchCriteria);
        var params = this.queryParamsService.asQueryParams(bookingSearchCriteria);
        return this.requestService.get(UrlPaths.OP_RES_URL_KEY, [UrlPaths.OP_RES_VERSION, UrlPaths.BOOKING_SUMMARIES], params);
    };
    BookingManagementService.prototype.getById = function (bookingId) {
        return this.requestService.get(UrlPaths.OP_RES_URL_KEY, [UrlPaths.OP_RES_VERSION, UrlPaths.BOOKINGS, bookingId.toString()]);
    };
    BookingManagementService.prototype.saveBooking = function (booking) {
        return this.requestService.post(UrlPaths.OP_RES_URL_KEY, [UrlPaths.OP_RES_VERSION, UrlPaths.BOOKINGS], booking);
    };
    BookingManagementService.ngInjectableDef = i0.defineInjectable({ factory: function BookingManagementService_Factory() { return new BookingManagementService(i0.inject(i1.DMCQueryParamsService), i0.inject(i2.RequestService), i0.inject(i3.HttpClient), i0.inject(i4.DataStoreService)); }, token: BookingManagementService, providedIn: "root" });
    return BookingManagementService;
}());
export { BookingManagementService };
