import { Component, Input, OnInit } from '@angular/core';
import { TC } from '@tc-core/util/constants';
import { DataKey, DataStoreService } from '../../../services/util/framework/data-store.service';
import { PaymentInfo } from '../../../models/common/payment-info';

@Component({
    selector: 'tc-payment-info-card',
    templateUrl: './payment-info-card.component.html'
})
export class PaymentInfoCardComponent implements OnInit {
    EMPTY_CHAR = TC.FORMAT.EMPTY_CHAR;

    @Input() paymentInfo: PaymentInfo;
    @Input() showTitle: boolean = true;

    paymentMethodList=[];

    constructor(
        private dataStore: DataStoreService
    ) { }

    ngOnInit() {
    }

    getPaymentMethodName(code:any){
        this.dataStore.get(DataKey.paymentMethods).subscribe(value => {
            this.paymentMethodList = value;
        });
        const type = this.paymentMethodList.find(value => value.code === code);
        return type ? type.name : code;
    }

}
