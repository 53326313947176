/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "angular-resize-event";
import * as i2 from "../timeline-unit-wrapper/timeline-unit-wrapper.component.ngfactory";
import * as i3 from "../timeline-unit-wrapper/timeline-unit-wrapper.component";
import * as i4 from "./timeline.component";
var styles_TimelineComponent = [];
var RenderType_TimelineComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TimelineComponent, data: {} });
export { RenderType_TimelineComponent as RenderType_TimelineComponent };
export function View_TimelineComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { timelinePart: 0 }), (_l()(), i0.ɵeld(1, 0, [[1, 0], ["timelinePart", 1]], null, 3, "div", [["class", "tc-strip-timeline"]], null, [[null, "resized"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("resized" === en)) {
        var pd_0 = (_co.onResize($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 212992, null, 0, i1.ResizedDirective, [i0.ElementRef], null, { resized: "resized" }), (_l()(), i0.ɵeld(3, 0, null, null, 1, "tc-timeline-unit-wrapper", [["class", "tc-strip-timeline__ruler"]], null, [[null, "targetClick"], [null, "eventClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("targetClick" === en)) {
        var pd_0 = (_co.onTargetClick() !== false);
        ad = (pd_0 && ad);
    } if (("eventClick" === en)) {
        var pd_1 = (_co.onEventClick($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_TimelineUnitWrapperComponent_0, i2.RenderType_TimelineUnitWrapperComponent)), i0.ɵdid(4, 114688, null, 0, i3.TimelineUnitWrapperComponent, [], { summaryContent: [0, "summaryContent"], unitSize: [1, "unitSize"], unitWidth: [2, "unitWidth"], targetIndex: [3, "targetIndex"], targetUnitCount: [4, "targetUnitCount"], targetMargin: [5, "targetMargin"], isTargetBlock: [6, "isTargetBlock"], leftBufferUnits: [7, "leftBufferUnits"], rightBufferUnits: [8, "rightBufferUnits"], showingIndexes: [9, "showingIndexes"], events: [10, "events"] }, { eventClick: "eventClick", targetClick: "targetClick" })], function (_ck, _v) { var _co = _v.component; _ck(_v, 2, 0); var currVal_0 = _co.summaryContent; var currVal_1 = _co.unitSize; var currVal_2 = _co.unitWidth; var currVal_3 = _co.targetUnitIndex; var currVal_4 = _co.targetUnitCount; var currVal_5 = _co.targetMargin; var currVal_6 = _co.isTargetBlock; var currVal_7 = _co.leftBufferUnits; var currVal_8 = _co.rightBufferUnits; var currVal_9 = _co.showingIndexes; var currVal_10 = _co.eventsData; _ck(_v, 4, 1, [currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10]); }, null); }
export function View_TimelineComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-timeline", [], null, null, null, View_TimelineComponent_0, RenderType_TimelineComponent)), i0.ɵdid(1, 114688, null, 0, i4.TimelineComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TimelineComponentNgFactory = i0.ɵccf("tc-timeline", i4.TimelineComponent, View_TimelineComponent_Host_0, { summaryContent: "summaryContent", isTargetBlock: "isTargetBlock", targetPointTime: "targetPointTime", targetBlockTimeStart: "targetBlockTimeStart", targetBlockTimeEnd: "targetBlockTimeEnd", events: "events", leftBufferHours: "leftBufferHours", rightBufferHours: "rightBufferHours" }, { eventClick: "eventClick", targetClick: "targetClick" }, []);
export { TimelineComponentNgFactory as TimelineComponentNgFactory };
