import { Component } from '@angular/core';
import { ITooltipAngularComp } from 'ag-grid-angular';
import { ITooltipParams } from 'ag-grid-community';
import { Passenger } from '../../../../../models/reservation-v2/passenger';
import { PassengerAttribute } from '../../../../../models/reservation-v2/passenger-attribute';

@Component({
    selector: 'tc-passenger-tooltip',
    templateUrl: './passenger-tooltip.component.html',
    styleUrls: ['./passenger-tooltip.component.scss']
})
export class PassengerTooltipComponent implements ITooltipAngularComp {

    params: any;
    data: Passenger;
    passengerAttributes: PassengerAttribute[] = [];
    hasAttributes = false;

    displayedColumns: string[] = ['name', 'value'];

    constructor() { }

    public agInit(params: ITooltipParams): void {
        this.params = params;
        this.data = params.api.getDisplayedRowAtIndex(params.rowIndex).data;
        if (this.data && this.data.passengerAttributes && this.data.passengerAttributes.length) {
            this.hasAttributes = true;
            this.passengerAttributes = this.data.passengerAttributes;
        }
    }

}
