import * as tslib_1 from "tslib";
import { AbstractSearchCriteria } from "./abstract-search-criteria";
var CommonDataSearchCriteria = /** @class */ (function (_super) {
    tslib_1.__extends(CommonDataSearchCriteria, _super);
    function CommonDataSearchCriteria(commonDataType) {
        var _this = _super.call(this) || this;
        _this.commonDataType = commonDataType;
        return _this;
    }
    return CommonDataSearchCriteria;
}(AbstractSearchCriteria));
export { CommonDataSearchCriteria };
