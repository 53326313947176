import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { locationGroupSetup } from '../../../../../models/master-data/location-group-setup';
import { FormBuilder } from '@angular/forms';
import { RoutesSetupService } from '../../../../../services/backend-consumers/deprecated/routes-setup.service';
import { DataKey, DataStoreService } from '../../../../../services/util/framework/data-store.service';
var EditLocationGroupComponent = /** @class */ (function () {
    function EditLocationGroupComponent(fb, routeService, dataStore) {
        this.fb = fb;
        this.routeService = routeService;
        this.dataStore = dataStore;
        this.selectedLocations = [];
        this.searchCriteria = ['Airport', 'Cruise Port', 'Hotel', 'Other'];
        this.countryList = [];
        this.regionList = [];
        this.cityList = [];
        this.createForm();
    }
    EditLocationGroupComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.locationGroup.locations.forEach(function (loc) {
            _this.selectedLocations.push(loc);
        });
        console.log(this.searchCriteria);
        this.locationName = this.locationGroup.groupName;
        // Testing
        this.routeService.regionList().subscribe(function (list) {
            _this.regionList = list['data'];
        });
        this.routeService.countryList().subscribe(function (list) {
            _this.countryList = list['data'];
        });
        this.routeService.cityList().subscribe(function (list) {
            _this.cityList = list['data'];
        });
    };
    EditLocationGroupComponent.prototype.toggleValue = function (value) {
        this.selectedCriteria = value;
        this.searchLocations();
    };
    EditLocationGroupComponent.prototype.searchLocations = function () {
        var _this = this;
        this.routeService.getLocationTypes().subscribe(function (data) {
            var e_1, _a;
            if (data != null) {
                try {
                    for (var data_1 = tslib_1.__values(data), data_1_1 = data_1.next(); !data_1_1.done; data_1_1 = data_1.next()) {
                        var key = data_1_1.value;
                        if (key['locationType'] === _this.selectedCriteria) {
                            _this.searchResults = key['locations'];
                        }
                    }
                }
                catch (e_1_1) { e_1 = { error: e_1_1 }; }
                finally {
                    try {
                        if (data_1_1 && !data_1_1.done && (_a = data_1.return)) _a.call(data_1);
                    }
                    finally { if (e_1) throw e_1.error; }
                }
            }
        });
    };
    EditLocationGroupComponent.prototype.createForm = function () {
        this.locationGroupForm = this.fb.group({
            region: [''],
            country: [''],
            city: ['']
        });
    };
    EditLocationGroupComponent.prototype.addLocation = function (isSelected, loc) {
        if (isSelected.checked) {
            this.selectedLocations.push({ 'code': loc['code'], 'name': loc['name'] });
            // this.locationGroup.locations.set(loc['code'], loc['name']);
            console.log(this.locationGroup.locations);
        }
        else {
            var index = this.selectedLocations.findIndex(function (order) { return order.code === loc['code']; });
            this.selectedLocations.splice(index, 1);
            // console.log(this.selectedLocations);
        }
        // TODO: should pass the selectedLocations with LocationGroup
        this.dataStore.set(DataKey.locations, this.selectedLocations, true);
    };
    return EditLocationGroupComponent;
}());
export { EditLocationGroupComponent };
