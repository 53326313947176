import * as tslib_1 from "tslib";
import { AfterViewInit, OnInit } from '@angular/core';
import { TcErrorType } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-error';
import { DialogModel } from '@tc-core/model/it/codegen/ui/framework/dialog-model';
import { LEVEL, ModalData } from '@tc-core/model/it/codegen/ui/framework/modal-data';
import { TC } from '@tc-core/util';
import { ConfigLoader, EventManager, UserJourneyManager } from '@tc-core/util/framework';
import { SpinnerService } from '@tc-core/util/ui';
import { DialogService } from '@tc/dialog/dialog.service';
import { FocusViewService } from '@tc/focus-view/focus-view.service';
import { ModalService } from '@tc/modal/modal.service';
import { Subscription } from 'rxjs';
import { AutoCompleteEditorComponent } from '../../../../widgets/framework/ag-grid-custom-components/editors/auto-complete-editor/auto-complete-editor.component';
import { CheckBoxSelectorComponent } from '../../../../widgets/framework/ag-grid-custom-components/renderers/check-box-selector/check-box-selector.component';
import { ActionColumnComponent } from '../../../../widgets/framework/ag-grid/action-column.component';
import { AutoCompleteComponent } from '../../../../widgets/framework/ag-grid/auto-complete.component';
import { CurrencyInputEditorComponent } from '../../../../widgets/framework/ag-grid/currency-input-editor.component';
import { MultiSelectComponent } from '../../../../widgets/framework/ag-grid/multi-select.component';
import { NumericEditorComponent } from '../../../../widgets/framework/ag-grid/numeric-editor.component';
import { FileUploaderComponent } from '../../../../widgets/framework/file-uploader/file-uploader.component';
import { DateRange } from '../../../../widgets/framework/cg-calendar/date-range';
import { RangeGroup } from '../../../../widgets/framework/cg-calendar/range-group';
import { SeasonSetupSummaryComponent } from '../../../../widgets/framework/cg-season-calendar/season-setup-summary/season-setup-summary.component';
import { DocumentCreationCriteria, DocumentFormat, DocumentType } from '../../../../models/criteria/document-creation-criteria';
import { DocumentDataCriteria } from '../../../../models/criteria/document-data-criteria';
import { ResourceType } from '../../../../models/reservation/assignment';
import { RoomResourceCostSetup } from '../../../../models/supplier/room-resource-cost';
import { DocumentService } from '../../../../services/backend-consumers/documents/document.service';
import { DataHandlerService } from '../../../../services/backend-consumers/master-data-handler-service/data-handler.service';
import { SupplierService } from '../../../../services/backend-consumers/supplier-service/supplier.service';
import { DMCCommon } from '../../../../services/util/common/dmc-common';
import { DataKey, DataStoreService } from '../../../../services/util/framework/data-store.service';
import { ResponseUtil } from '../../../../services/util/response/response-util.service';
import { CardDataHandlerService } from '../../../../services/util/ui/card-data-handler-service.service';
import { ContentSummaryCardService } from '../../../../services/util/ui/content-summary-card.service';
function removeSelectedData(selectedData) {
    for (var i = 0; i < this.resourceCosts.length; i++) {
        if (this.resourceCosts[i].tempCostId === selectedData.tempCostId) {
            this.resourceCosts.splice(i, 1);
            break;
        }
    }
}
var RoomCostSetupFocusViewComponent = /** @class */ (function () {
    function RoomCostSetupFocusViewComponent(em, configLoader, journeyManager, dataStore, focusViewService, summaryCardService, dataHandlerService, spinnerService, cardDataHandlerService, dialogService, documentService, modalService, common, supplierService) {
        this.em = em;
        this.configLoader = configLoader;
        this.journeyManager = journeyManager;
        this.dataStore = dataStore;
        this.focusViewService = focusViewService;
        this.summaryCardService = summaryCardService;
        this.dataHandlerService = dataHandlerService;
        this.spinnerService = spinnerService;
        this.cardDataHandlerService = cardDataHandlerService;
        this.dialogService = dialogService;
        this.documentService = documentService;
        this.modalService = modalService;
        this.common = common;
        this.supplierService = supplierService;
        this.rooms = [];
        this.supplierCostSetup = [];
        this.resourceCosts = [];
        this.selectedResourceTypes = [];
        this.selectedProfileTypes = [];
        this.currencyList = [];
        this.costingTypeList = [];
        this.daysOfWeek = [];
        this.resourceTypes = [];
        this.boardBasisTypes = [
            {
                name: 'Full Board',
                code: 'Full Board'
            },
            {
                name: 'Half Board',
                code: 'Half Board'
            }
        ];
        this.title = 'app';
        this.seasons = [];
        this.isInvalidCost = false;
        this.isCostMissing = false;
        this.allowedProfTypes = [];
        this.rangeGroupsForSeasonCalendar = [];
        this.CARD_CONFIG_GENERIC_EDIT = 'supplier_edit';
        this.genericCostSheetImportSubscribe = new Subscription();
        this.genCostSheetUploadSubscribe = new Subscription();
        this.supplierDataSubscribe = new Subscription();
        this.allDayKeys = '';
        this.templateName = 'SUPPLIER_GEN_COST_SHEET';
        this.frameworkComponents = {
            autoComplete: AutoCompleteComponent,
            currencyInput: CurrencyInputEditorComponent,
            numericInput: NumericEditorComponent,
            actionColumnComponent: ActionColumnComponent,
            multiSelect: MultiSelectComponent
        };
        this.defaultColDef = {
            editable: true,
            resizable: true
        };
        this.paginationPageSize = 10;
        this.rowClassRules = {
            'tc-ag-grid-row--error': function (params) { return params.data.isInvalid; }
        };
    }
    RoomCostSetupFocusViewComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.initPageSectionReferences();
        this.mapToSeasonCalendarRanges();
        this.getAllDayKeys();
        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'CLOSE_FOCUS_VIEW').subscribe(function (e) {
            _this.dataStore.set(DataKey.supplierCostFocusViewClose, null);
            _this.dataStore.set(DataKey.supplierCostFocusViewClose, true);
            _this.saveResourceCostData();
            _this.focusViewService.close(true);
            _this.journeyManager.goForKey('GO_TO_ADD_TRANSFER_SUPPLIER');
        });
        if (this.supplierCostSetup !== null && this.supplierCostSetup.length > 0) {
            this.supplierId = this.supplierCostSetup[0].supplierId;
            this.supplierCostSetup.forEach(function (item) {
                _this.seasons.push({
                    code: item.tempSeasonId,
                    name: item.name
                });
            });
        }
        this.getColumnDefs();
    };
    RoomCostSetupFocusViewComponent.prototype.initPageSectionReferences = function () {
        this.pageSectionsMap = this.cardDataHandlerService.getAllPageSections(this.CARD_CONFIG_GENERIC_EDIT, this.resourceCosts);
        this.costAddSection = this.pageSectionsMap.get('supplier_room_cost_setup');
    };
    RoomCostSetupFocusViewComponent.prototype.mapToSeasonCalendarRanges = function () {
        var _this = this;
        this.supplierCostSetup.forEach(function (season) {
            var e_1, _a;
            var rangeGroup = new RangeGroup();
            rangeGroup.id = season.tempSeasonId;
            rangeGroup.name = season.name;
            rangeGroup.colorId = season.colorId;
            var dateRanges = [];
            try {
                for (var _b = tslib_1.__values(season.seasonDateRanges), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var seasonDateRange = _c.value;
                    var dateRange = new DateRange();
                    dateRange.id = seasonDateRange.dateRangeId;
                    dateRange.startDate = new Date(seasonDateRange.startDate);
                    dateRange.endDate = new Date(seasonDateRange.endDate);
                    dateRanges.push(dateRange);
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_1) throw e_1.error; }
            }
            rangeGroup.dateRanges = dateRanges;
            _this.rangeGroupsForSeasonCalendar.push(rangeGroup);
        });
    };
    RoomCostSetupFocusViewComponent.prototype.onChangeCostSetupForm = function (event) {
        this.dataStore.set(DataKey.supplierTrsCostSetupValid, event, true);
    };
    RoomCostSetupFocusViewComponent.prototype.saveResourceCostData = function () {
        this.addDataFromGrid(this.gridApi, this.resourceCosts);
        this.dataStore.set(DataKey.roomResourceCosts, null, true);
        this.isInvalidCost = !!(this.resourceCosts && this.resourceCosts.length > 0 &&
            this.resourceCosts.find(function (value) { return value.isInvalid; }));
        // for (let i = 0; i < this.resourceCosts.length; i++) {
        //     let cost = this.resourceCosts[i];
        //     if (cost.resourceType && cost.profileType && cost.tempSeasonId && cost.dayKeys) {
        //         if (cost.seasonName instanceof Object) {
        //             cost.tempSeasonId = cost.seasonName.code;
        //         }
        //         this.isInvalidCost = false;
        //         break;
        //     } else {
        //         this.isInvalidCost = true;
        //         break;
        //     }
        // }
        this.dataStore.set(DataKey.roomResourceCosts, this.resourceCosts, true);
        this.dataStore.set(DataKey.supplierRoomCostSetupValid, !this.isInvalidCost, true);
    };
    RoomCostSetupFocusViewComponent.prototype.getNextTempId = function () {
        var e_2, _a;
        var maxId = 0;
        try {
            for (var _b = tslib_1.__values(this.resourceCosts), _c = _b.next(); !_c.done; _c = _b.next()) {
                var resourceCost = _c.value;
                var tempId = resourceCost.tempCostId;
                if (tempId > maxId) {
                    maxId = tempId;
                }
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_2) throw e_2.error; }
        }
        maxId++;
        return maxId;
    };
    RoomCostSetupFocusViewComponent.prototype.onGridReady = function (params) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.gridApi.setFloatingFiltersHeight(38);
        this.gridApi.setHeaderHeight(40);
    };
    RoomCostSetupFocusViewComponent.prototype.getColumnDefs = function () {
        var _this = this;
        this.columnDefs = [
            {
                headerName: 'Room Type *',
                field: 'roomType',
                hide: false,
                editable: true,
                sortable: true,
                resizable: true,
                pinned: 'left',
                minWidth: 80,
                cellEditorFramework: AutoCompleteEditorComponent,
                cellEditorParams: {
                    returnObject: true,
                    filterItemFn: function (params, row, text) {
                        var filterValue = '';
                        if (text) {
                            filterValue = text.toLowerCase();
                        }
                        return _this.rooms.filter(function (value) { return value.roomType.toLowerCase().indexOf(filterValue) > -1; });
                    },
                    displayItemFn: function (option) {
                        if (option && option.roomType) {
                            return option.roomType;
                        }
                        else {
                            return '';
                        }
                    }
                },
                valueFormatter: function (params) {
                    if (params.value) {
                        return params.value.name;
                    }
                    return '';
                }
            },
            {
                headerName: ' Board Basis',
                field: 'boardBasis',
                hide: false,
                minWidth: 80,
                editable: true,
                // cellEditor: 'autoComplete',
                sortable: true,
                resizable: true,
                cellEditorFramework: AutoCompleteEditorComponent,
                cellEditorParams: {
                    returnObject: true,
                    filterItemFn: function (params, row, text) {
                        var allowedProfileTypes = _this.boardBasisTypes;
                        var anyType = [{ code: 'ANY', name: 'Any' }];
                        var filterValue = text ? text.toLowerCase() : '';
                        return anyType.concat(allowedProfileTypes)
                            .filter(function (value) { return value.name && value.name.toLowerCase().indexOf(filterValue) > -1; });
                    },
                    displayItemFn: function (option) {
                        if (option && option.name) {
                            return option.name;
                        }
                        else {
                            return '';
                        }
                    }
                },
                valueFormatter: function (params) {
                    if (params.value) {
                        return params.value.name;
                    }
                    return '';
                }
            },
            {
                headerName: 'Season *',
                field: 'seasonName',
                hide: false,
                editable: true,
                // cellEditor: 'autoComplete',
                sortable: true,
                resizable: true,
                filter: true,
                minWidth: 80,
                width: 150,
                cellEditorFramework: AutoCompleteEditorComponent,
                cellEditorParams: {
                    returnObject: true,
                    filterItemFn: function (params, row, text) {
                        var filterValue = '';
                        if (text) {
                            filterValue = text.toLowerCase();
                        }
                        return this.seasons.filter(function (value) { return value.name.toLowerCase().indexOf(filterValue) > -1; });
                    }.bind(this),
                    displayItemFn: function (option) {
                        if (option && option.name) {
                            return option.name;
                        }
                        else {
                            return '';
                        }
                    }
                },
                valueFormatter: function (params) {
                    if (params.value) {
                        var data = params.value.name;
                        params.value = params.value.name;
                        return data;
                    }
                    return '';
                }
            },
            {
                headerName: 'Days',
                children: [
                    {
                        headerName: 'Mo',
                        editable: false,
                        field: 'dayArray',
                        cellRendererFramework: CheckBoxSelectorComponent,
                        width: 70,
                        resizable: false,
                        cellRendererParams: {
                            date: 1,
                            day: 'MONDAY',
                            click: this.checkBoxOnClick.bind(this)
                        }
                        // columnGroupShow: 'open'
                    },
                    {
                        headerName: 'Tu',
                        editable: false,
                        field: 'dayArray',
                        cellRendererFramework: CheckBoxSelectorComponent,
                        width: 70,
                        resizable: false,
                        cellRendererParams: {
                            date: 2,
                            day: 'TUESDAY',
                            click: this.checkBoxOnClick.bind(this)
                        }
                        // columnGroupShow: 'open'
                    },
                    {
                        headerName: 'We',
                        field: 'dayArray',
                        editable: false,
                        cellRendererFramework: CheckBoxSelectorComponent,
                        width: 70,
                        resizable: false,
                        cellRendererParams: {
                            date: 3,
                            day: 'WEDNESDAY',
                            click: this.checkBoxOnClick.bind(this)
                        }
                        // columnGroupShow: 'open'
                    },
                    {
                        headerName: 'Th',
                        field: 'dayArray',
                        editable: false,
                        cellRendererFramework: CheckBoxSelectorComponent,
                        width: 70,
                        resizable: false,
                        cellRendererParams: {
                            date: 4,
                            day: 'THURSDAY',
                            click: this.checkBoxOnClick.bind(this)
                        }
                        // columnGroupShow: 'open'
                    },
                    {
                        headerName: 'Fr',
                        field: 'dayArray',
                        editable: false,
                        cellRendererFramework: CheckBoxSelectorComponent,
                        width: 70,
                        resizable: false,
                        cellRendererParams: {
                            date: 5,
                            day: 'FRIDAY',
                            click: this.checkBoxOnClick.bind(this)
                        }
                        // columnGroupShow: 'open'
                    },
                    {
                        headerName: 'Sa',
                        field: 'dayArray',
                        editable: false,
                        cellRendererFramework: CheckBoxSelectorComponent,
                        width: 70,
                        resizable: false,
                        cellRendererParams: {
                            date: 6,
                            day: 'SATURDAY',
                            click: this.checkBoxOnClick.bind(this)
                        }
                        // columnGroupShow: 'open'
                    },
                    {
                        headerName: 'Su',
                        field: 'dayArray',
                        editable: false,
                        cellRendererFramework: CheckBoxSelectorComponent,
                        width: 70,
                        resizable: false,
                        cellRendererParams: {
                            date: 7,
                            day: 'SUNDAY',
                            click: this.checkBoxOnClick.bind(this)
                        }
                        // columnGroupShow: 'open'
                    }
                ]
            },
            {
                headerName: 'Currency *',
                field: 'currency',
                hide: false,
                editable: true,
                // cellEditor: 'autoComplete',
                sortable: true,
                filter: true,
                minWidth: 80,
                width: 142,
                headerClass: 'tc-ag-grid-header--center',
                cellStyle: { textAlign: 'center' },
                cellEditorFramework: AutoCompleteEditorComponent,
                cellEditorParams: {
                    viewKey: 'code',
                    returnObject: true,
                    filterItemFn: function (params, row, text) {
                        return this.currencyList.filter(function (r) {
                            return r.code && r.code.toLowerCase().indexOf(text) === 0;
                        });
                    }.bind(this)
                },
                valueFormatter: function (params) {
                    if (params.value) {
                        return params.value.code;
                    }
                    return '';
                }
            },
            {
                headerName: 'Costing Type *',
                field: 'costingType',
                hide: false,
                editable: true,
                // cellEditor: 'autoComplete',
                sortable: true,
                filter: true,
                minWidth: 80,
                width: 160,
                headerClass: 'tc-ag-grid-header--center',
                cellStyle: { textAlign: 'left' },
                cellEditorFramework: AutoCompleteEditorComponent,
                cellEditorParams: {
                    viewKey: 'name',
                    returnObject: true,
                    filterItemFn: function (params, row, text) {
                        if (row.resourceType &&
                            (row.resourceType.code === ResourceType.driver || row.resourceType.code === ResourceType.guide)) {
                            return this.costingTypeList.filter(function (r) {
                                return r.code && r.code.toLowerCase().indexOf(text) === 0;
                            });
                        }
                        else {
                            return this.costingTypeList.filter(function (r) {
                                return r.code && r.code.toLowerCase().indexOf(text) === 0 && r.category !== 'DISTANCE' && r.code !== 'PJ';
                            });
                        }
                    }.bind(this)
                },
                valueFormatter: function (params) {
                    if (params.value) {
                        return params.value.name;
                    }
                    return '';
                }
            },
            {
                headerName: 'Nights',
                field: 'nights',
                hide: false,
                valueSetter: this.numericSetter,
                type: 'numericColumn',
                minWidth: 80,
                width: 120,
                editable: function (params) {
                    return true;
                },
                sortable: true,
                resizable: true,
                filter: true,
                cellStyle: { 'text-align': 'right' }
            },
            {
                headerName: 'Single',
                field: 'single',
                hide: false,
                valueSetter: this.numericSetter,
                cellRenderer: this.CurrencyCellRenderer,
                type: 'numericColumn',
                minWidth: 120,
                width: 150,
                editable: function (params) {
                    return true;
                },
                sortable: true,
                resizable: true,
                filter: true,
                cellStyle: { 'text-align': 'right' }
            },
            {
                headerName: 'Twin',
                field: 'twin',
                hide: false,
                valueSetter: this.numericSetter,
                cellRenderer: this.CurrencyCellRenderer,
                type: 'numericColumn',
                minWidth: 120,
                width: 150,
                editable: function (params) {
                    return true;
                },
                sortable: true,
                resizable: true,
                filter: true,
                cellStyle: { 'text-align': 'right' }
            },
            {
                headerName: 'Extra Bed',
                field: 'extraBed',
                hide: false,
                valueSetter: this.numericSetter,
                cellRenderer: this.CurrencyCellRenderer,
                type: 'numericColumn',
                minWidth: 120,
                width: 150,
                editable: function (params) {
                    return true;
                },
                sortable: true,
                resizable: true,
                filter: true,
                cellStyle: { 'text-align': 'right' }
            },
            {
                headerName: 'Adult Cost',
                field: 'adultFare',
                hide: false,
                valueSetter: this.numericSetter,
                cellRenderer: this.CurrencyCellRenderer,
                type: 'numericColumn',
                minWidth: 120,
                width: 150,
                editable: function (params) {
                    return true;
                },
                sortable: true,
                resizable: true,
                filter: true,
                cellStyle: { 'text-align': 'right' }
            },
            {
                headerName: 'Child Cost',
                field: 'childFare',
                hide: false,
                valueSetter: this.numericSetter,
                cellRenderer: this.CurrencyCellRenderer,
                type: 'numericColumn',
                minWidth: 120,
                width: 150,
                editable: function (params) {
                    return true;
                },
                sortable: true,
                resizable: true,
                filter: true,
                cellStyle: { 'text-align': 'right' }
            },
            {
                headerName: 'Infant Cost',
                field: 'infantFare',
                hide: false,
                valueSetter: this.numericSetter,
                cellRenderer: this.CurrencyCellRenderer,
                type: 'numericColumn',
                minWidth: 120,
                width: 150,
                flex: 1,
                editable: function (params) {
                    return true;
                },
                sortable: true,
                resizable: false,
                filter: true,
                cellStyle: { 'text-align': 'right' }
            },
            {
                headerName: '',
                field: 'value',
                editable: false,
                cellRendererFramework: ActionColumnComponent,
                cellClass: [
                    'tc-ag-grid-cell--justify-center',
                    'tc-px-0'
                ],
                cellRendererParams: {
                    icon: 'delete',
                    title: 'Delete',
                    action: this.onRemoveRow.bind(this)
                },
                pinned: 'right',
                resizable: false,
                width: 80
            }
        ];
    };
    RoomCostSetupFocusViewComponent.prototype.checkBoxOnClick = function (params) {
        this.onCellValueChanged(params);
        console.log(params.value);
    };
    // The numeric value setter function/method
    RoomCostSetupFocusViewComponent.prototype.numericSetter = function (params) {
        if (params.newValue > 0 || params.newValue === '') {
            params.data[params.colDef.field] = params.newValue;
            return true;
        }
        // Illegal value - signal no change
        return false;
    };
    RoomCostSetupFocusViewComponent.prototype.CurrencyCellRenderer = function (params) {
        var inrFormat = new Intl.NumberFormat('en-Us', {
            style: 'currency',
            currency: params.data.currency.code ? params.data.currency.code : params.data.currency,
            minimumFractionDigits: 2
        });
        return inrFormat.format(params.value).replace(/[a-z]{3}/i, '').trim();
    };
    RoomCostSetupFocusViewComponent.prototype.onHeaderActionClick = function (event) {
        if (event.id === 'TRIGGER_SHOW_SEASON_SUMMARY') {
            this.showSeasonSummary();
        }
        else if (event.id === 'TRIGGER_ADD_COST') {
            this.addRow();
        }
        else if (event.id === 'TRIGGER_SAVE_COST_DATA') {
            this.saveResourceCostData();
        }
        else if (event.id === 'TRIGGER_EXPORT_FILE') {
            this.exportCostData();
        }
        else if (event.id === 'TRIGGER_IMPORT_FILE') {
            this.importCostData();
        }
    };
    RoomCostSetupFocusViewComponent.prototype.addRow = function () {
        if (this.seasons.length > 0) {
            var resourceCost = new RoomResourceCostSetup();
            resourceCost.tempCostId = this.getNextTempId();
            resourceCost.currency = 'AED';
            resourceCost.unitFare = 0;
            resourceCost.adultFare = 0;
            resourceCost.childFare = 0;
            resourceCost.infantFare = 0;
            resourceCost.single = 0;
            resourceCost.twin = 0;
            resourceCost.extraBed = 0;
            resourceCost.noOfUnits = 0;
            resourceCost.isInvalid = true;
            resourceCost.dayArray = this.daysOfWeek;
            resourceCost.nights = 1;
            this.mapDayKeys(resourceCost);
            this.resourceCosts.unshift(resourceCost);
            this.gridApi.updateRowData({
                add: [resourceCost],
                addIndex: 0
            });
            this.validateUniqueValues(resourceCost);
        }
        else {
            var confirmError = new DialogModel(true, LEVEL.ERROR, 'Failed', 'Please add seasons', true, 2000);
            this.dialogService
                .confirm(confirmError)
                .subscribe(function (res) {
                if (res === true) {
                    console.log('true');
                }
            });
        }
    };
    // get all keys of whole week
    RoomCostSetupFocusViewComponent.prototype.getAllDayKeys = function () {
        var _this = this;
        this.daysOfWeek.forEach(function (data) { _this.allDayKeys += data.key; });
    };
    RoomCostSetupFocusViewComponent.prototype.showSeasonSummary = function () {
        var dataObject = new ModalData(LEVEL.SUCCESS, 'Season Setup Summary', null, { label: 'CLOSE' }, 'modal-basic--fixed-size modal-basic--show-footer', SeasonSetupSummaryComponent, { rangeGroups: this.rangeGroupsForSeasonCalendar });
        this.modalService
            .confirm(dataObject).subscribe(function (res) {
            if (res) {
            }
        });
    };
    RoomCostSetupFocusViewComponent.prototype.onRemoveRow = function (params) {
        var _this = this;
        if (!params.data.isInvalid) {
            var confirmDataObject = new DialogModel(true, LEVEL.WARNING, 'Delete', 'This Cost will be deleted. Do you wish to proceed?', true, 3000);
            this.dialogService.confirm(confirmDataObject).subscribe(function (res) {
                if (res === true) {
                    _this.removeSelectedData(params);
                }
            });
        }
        else {
            this.removeSelectedData(params);
        }
    };
    RoomCostSetupFocusViewComponent.prototype.removeSelectedData = function (params) {
        for (var i = 0; i < this.resourceCosts.length; i++) {
            if (this.resourceCosts[i].tempCostId === params.data.tempCostId) {
                this.resourceCosts.splice(i, 1);
                break;
            }
        }
        this.gridApi.setRowData(this.resourceCosts);
    };
    RoomCostSetupFocusViewComponent.prototype.addDataFromGrid = function (gridApi, list) {
        list.length = 0;
        gridApi.forEachNode(function (node) {
            list.push(node.data);
        });
    };
    RoomCostSetupFocusViewComponent.prototype.onSelectionChanged = function ($event) {
        var selectedRows = this.gridApi.getSelectedRows();
        var selectedRowsString = '';
        selectedRows.forEach(function (selectedRow, index) {
            if (index !== 0) {
                selectedRowsString += ', ';
            }
            selectedRowsString += selectedRow.athlete;
        });
    };
    RoomCostSetupFocusViewComponent.prototype.getProfileTypes = function (resourceType) {
        return this.selectedResourceTypes.filter(function (value) { return value.code === resourceType; })[0];
    };
    RoomCostSetupFocusViewComponent.prototype.onCellValueChanged = function (params) {
        var colId = params.column.getId();
        if (colId === 'roomType') {
            params.data.room = params.newValue;
            params.data.roomType = params.newValue.roomType;
            params.data.occupancyName = '';
            params.data.occupancyId = 0;
            params.data.occupancyTempId = 0;
        }
        else if (colId === 'boardBasis') {
            if (params.newValue) {
                params.data.boardBasis = params.newValue.code;
            }
            else {
                params.data.boardBasis = '';
            }
        }
        else if (colId === 'occupancyName') {
            var occupancy = params.newValue;
            if (occupancy) {
                params.data.occupancyName = occupancy.occupancyName;
                params.data.occupancyId = occupancy.occupancyId;
                params.data.occupancyTempId = occupancy.occupancyTempId;
            }
            else {
                params.data.occupancyName = '';
                params.data.occupancyId = 0;
                params.data.occupancyTempId = 0;
            }
        }
        else if (colId === 'seasonName') {
            this.gridApi.getSelectedRows()[0].tempSeasonId = this.gridApi.getSelectedRows()[0].seasonName.code;
        }
        else if (colId.slice(0, -2) === 'dayArray' || colId === 'dayArray') {
            var dayValues = this.gridApi.getSelectedRows()[0].dayArray;
            var days_1 = [];
            dayValues.forEach(function (day) {
                days_1.push(day.day ? day.day : day.value);
            });
            var dayKeys_1 = '';
            this.daysOfWeek.forEach(function (data) {
                if (days_1.includes(data.value)) {
                    dayKeys_1 += data.key;
                }
            });
            this.gridApi.getSelectedRows()[0].dayKeys = dayKeys_1;
        }
        else if (colId === 'dayValues') {
            var dayValues_1 = this.gridApi.getSelectedRows()[0].dayValues;
            var dayKeys_2 = '';
            this.daysOfWeek.forEach(function (data) {
                if (dayValues_1.includes(data.value)) {
                    dayKeys_2 += data.key;
                }
            });
            this.gridApi.getSelectedRows()[0].dayKeys = dayKeys_2;
        }
        else if (colId === 'costingType') {
            this.gridApi.getSelectedRows()[0].unitFare = 0;
            this.gridApi.getSelectedRows()[0].adultFare = 0;
            this.gridApi.getSelectedRows()[0].childFare = 0;
            this.gridApi.getSelectedRows()[0].infantFare = 0;
        }
        this.validateUniqueValues(params.data);
        // set warning to missing cost fields
        var selectedResourceCost = this.gridApi.getSelectedRows()[0];
        this.isCostMissing = selectedResourceCost && !selectedResourceCost.unitFare && !selectedResourceCost.adultFare;
        this.gridApi.refreshCells(params);
    };
    RoomCostSetupFocusViewComponent.prototype.validateUniqueValues = function (selectedResourceCost) {
        var e_3, _a;
        selectedResourceCost = selectedResourceCost ? selectedResourceCost : this.gridApi.getSelectedRows()[0];
        if (selectedResourceCost && selectedResourceCost.tempSeasonId && selectedResourceCost.roomType &&
            selectedResourceCost.dayKeys) {
            try {
                for (var _b = tslib_1.__values(this.resourceCosts), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var item = _c.value;
                    if (item.tempCostId !== selectedResourceCost.tempCostId &&
                        item.tempSeasonId === selectedResourceCost.tempSeasonId &&
                        item.roomType === selectedResourceCost.roomType &&
                        item.boardBasis === selectedResourceCost.boardBasis &&
                        item.occupancyName === selectedResourceCost.occupancyName &&
                        this.anythingInCommon(item.dayKeys, selectedResourceCost.dayKeys)) {
                        this.isInvalidCost = true;
                        selectedResourceCost.isInvalid = true;
                        break;
                    }
                    else {
                        this.isInvalidCost = false;
                        selectedResourceCost.isInvalid = false;
                    }
                }
            }
            catch (e_3_1) { e_3 = { error: e_3_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_3) throw e_3.error; }
            }
            this.gridApi.getSelectedRows()[0].isInvalid = this.isInvalidCost;
        }
        else if (selectedResourceCost) {
            this.isInvalidCost = true;
            selectedResourceCost.isInvalid = true;
        }
        else {
            this.isInvalidCost = true;
        }
        this.gridApi.setRowData(this.resourceCosts);
        console.log(this.isInvalidCost);
    };
    RoomCostSetupFocusViewComponent.prototype.anythingInCommon = function (a, b) {
        if (b.length < a.length) {
            return this.anythingInCommon(b, a);
        }
        for (var i = 0, len = a.length; i < len; i++) {
            if (b.indexOf(a[i]) !== -1) {
                return true;
            }
        }
        return false;
    };
    RoomCostSetupFocusViewComponent.prototype.getCode = function (dataList, name) {
        return dataList.filter(function (val) { return val.name === name; })[0].code;
    };
    RoomCostSetupFocusViewComponent.prototype.ngAfterViewInit = function () {
        setTimeout(function () {
            var scrollingElement = document.querySelector('.tc-overlay');
            scrollingElement.scrollTo(0, 0);
        }, 0);
    };
    RoomCostSetupFocusViewComponent.prototype.mapDayKeys = function (resourceCost) {
        var dayValues = resourceCost.dayArray;
        var days = [];
        dayValues.forEach(function (day) {
            days.push(day.day ? day.day : day.value);
        });
        var dayKeys = '';
        this.daysOfWeek.forEach(function (data) {
            if (days.includes(data.value)) {
                dayKeys += data.key;
            }
        });
        resourceCost.dayKeys = dayKeys;
    };
    RoomCostSetupFocusViewComponent.prototype.exportCostData = function () {
        this.spinnerService.show();
        var documentCreationCriteria = new DocumentCreationCriteria();
        documentCreationCriteria.documentFormat = DocumentFormat.csv;
        documentCreationCriteria.documentType = DocumentType.SUPPLIER_GEN_COST_SHEET;
        documentCreationCriteria.documentName = '';
        documentCreationCriteria.download = true;
        documentCreationCriteria.persist = true;
        var documentDataCriteria = new DocumentDataCriteria();
        documentDataCriteria.supplierId = this.supplierId;
        this.documentService.createAndDownloadDocument(documentCreationCriteria, documentDataCriteria);
    };
    RoomCostSetupFocusViewComponent.prototype.importCostData = function () {
        var _this = this;
        this.dataStore.set(DataKey.popupClose, null);
        this.dataStore.set(DataKey.fileToUpload, null);
        var dataObject = new ModalData(LEVEL.SUCCESS, 'Upload Cost Sheet', true, true, 'split-passengers-modal', FileUploaderComponent, {});
        dataObject.disableClose = true;
        this.confirmModal(dataObject);
        var x = this.dataStore.get(DataKey.popupClose).subscribe(function (data) {
            if (data != null) {
                _this.closeModal();
            }
        });
        this.genericCostSheetImportSubscribe = this.dataStore.get(DataKey.fileToUpload).subscribe(function (data) {
            if (data != null) {
                _this.uploadFile(data);
                _this.genericCostSheetImportSubscribe.unsubscribe();
            }
        });
    };
    RoomCostSetupFocusViewComponent.prototype.uploadFile = function (fileToUpload) {
        var _this = this;
        var selectedResourceTypes = '';
        this.selectedResourceTypes.forEach(function (o, i) {
            selectedResourceTypes = selectedResourceTypes + o.code;
            if (i !== _this.selectedResourceTypes.length - 1) {
                selectedResourceTypes = selectedResourceTypes + ',';
            }
        });
        this.genCostSheetUploadSubscribe = this.supplierService.uploadCostSheet(fileToUpload, this.templateName, this.supplierId, selectedResourceTypes).subscribe(function (data) {
            if (data && data.body) {
                if (_this.gridApi) {
                    _this.gridApi.showLoadingOverlay();
                    _this.getSupplierById();
                }
                _this.common.showSnackBar('Successfully uploaded', 3000, TcErrorType.TYPE.INFO);
            }
        }, function (error) {
            if (error.error.status.description.substring(0, 4) === 'File') {
                _this.common.showSnackBar('Fail to upload: ' + error.error.status.description, 6000, TcErrorType.TYPE.ERROR);
            }
            else {
                _this.common.showSnackBar('Fail to upload', 3000, TcErrorType.TYPE.ERROR);
            }
        });
    };
    RoomCostSetupFocusViewComponent.prototype.confirmModal = function (data) {
        this.modalService.confirm(data).subscribe(function (res) {
        });
    };
    RoomCostSetupFocusViewComponent.prototype.closeModal = function () {
        if (this.modalService.close()) {
            this.modalService.close().subscribe(function (res) {
            });
        }
    };
    RoomCostSetupFocusViewComponent.prototype.getSupplierById = function () {
        this.supplierService.retrieveSupplierById(this.supplierId);
        this.subscribeSupplierData();
    };
    RoomCostSetupFocusViewComponent.prototype.subscribeSupplierData = function () {
        var _this = this;
        this.supplierDataSubscribe = this.dataStore.get(DataKey.supplier).subscribe(function (result) {
            if (result != null) {
                _this.resourceCosts = [];
                var supplier = ResponseUtil.getFirstData(result);
                _this.resourceCostSeasons = supplier.seasons;
                _this.getResourceCostData();
                _this.supplierDataSubscribe.unsubscribe();
            }
        });
    };
    RoomCostSetupFocusViewComponent.prototype.getResourceCostData = function () {
        var _this = this;
        if (this.resourceCostSeasons.length > 0) {
            this.addDefaultProfileType(this.selectedProfileTypes);
            // setting temp ids to seasons
            this.setTempIdsToSeasons();
            var tempCostId_1 = 0;
            this.resourceCostSeasons.forEach(function (item) {
                var e_4, _a;
                if (item.roomResourceCosts.length > 0) {
                    try {
                        for (var _b = tslib_1.__values(item.roomResourceCosts), _c = _b.next(); !_c.done; _c = _b.next()) {
                            var roomResourceCost = _c.value;
                            var days = _this.getDays(roomResourceCost.days);
                            var daysArr = _this.getDaysArr(roomResourceCost.days);
                            tempCostId_1++;
                            roomResourceCost.tempCostId = tempCostId_1;
                            var resourceCost = new RoomResourceCostSetup();
                            resourceCost.tempCostId = tempCostId_1;
                            resourceCost.roomType = roomResourceCost.roomType;
                            resourceCost.boardBasis = roomResourceCost.boardBasis;
                            resourceCost.seasonName = item.name;
                            resourceCost.tempSeasonId = item.tempSeasonId;
                            resourceCost.currency = roomResourceCost.currency;
                            resourceCost.unitFare = roomResourceCost.unitFare;
                            resourceCost.single = roomResourceCost.single;
                            resourceCost.twin = roomResourceCost.twin;
                            resourceCost.extraBed = roomResourceCost.extraBed;
                            resourceCost.adultFare = roomResourceCost.adultFare;
                            resourceCost.teenFare = roomResourceCost.teenFare;
                            resourceCost.childFare = roomResourceCost.childFare;
                            resourceCost.infantFare = roomResourceCost.infantFare;
                            resourceCost.nights = roomResourceCost.nights;
                            resourceCost.costingType = roomResourceCost.costingType;
                            resourceCost.dayValues = days;
                            resourceCost.dayArray = daysArr;
                            resourceCost.dayKeys = roomResourceCost.days;
                            resourceCost.isEdit = false;
                            _this.resourceCosts.push(resourceCost);
                            // this.resourceCosts.splice(0,0,resourceCost);
                        }
                    }
                    catch (e_4_1) { e_4 = { error: e_4_1 }; }
                    finally {
                        try {
                            if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                        }
                        finally { if (e_4) throw e_4.error; }
                    }
                }
            });
            this.gridApi.refreshCells();
            this.gridApi.hideOverlay();
        }
    };
    RoomCostSetupFocusViewComponent.prototype.addDefaultProfileType = function (typeList) {
        var _this = this;
        return !typeList.find(function (value) { return value.code === _this.defaultProfileType.code; }) ? typeList.push(this.defaultProfileType) : typeList;
    };
    // this.dataStore.get(DataKey.resourceTypes);
    RoomCostSetupFocusViewComponent.prototype.filterObject = function (code, objArray) {
        var e_5, _a;
        if (objArray && objArray.length > 0) {
            try {
                for (var objArray_1 = tslib_1.__values(objArray), objArray_1_1 = objArray_1.next(); !objArray_1_1.done; objArray_1_1 = objArray_1.next()) {
                    var item = objArray_1_1.value;
                    if (item.code === code) {
                        return item;
                    }
                }
            }
            catch (e_5_1) { e_5 = { error: e_5_1 }; }
            finally {
                try {
                    if (objArray_1_1 && !objArray_1_1.done && (_a = objArray_1.return)) _a.call(objArray_1);
                }
                finally { if (e_5) throw e_5.error; }
            }
            return null;
        }
        else {
            return code;
        }
    };
    RoomCostSetupFocusViewComponent.prototype.setTempIdsToSeasons = function () {
        var e_6, _a;
        var initId = 1;
        try {
            for (var _b = tslib_1.__values(this.resourceCostSeasons), _c = _b.next(); !_c.done; _c = _b.next()) {
                var season = _c.value;
                season.tempSeasonId = initId++;
            }
        }
        catch (e_6_1) { e_6 = { error: e_6_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_6) throw e_6.error; }
        }
    };
    RoomCostSetupFocusViewComponent.prototype.getDays = function (data) {
        var days = '';
        var _loop_1 = function (i) {
            var dayArray = this_1.daysOfWeek.find(function (day) { return day.key === data.charAt(i); }).value;
            days = days.concat(',' + dayArray);
        };
        var this_1 = this;
        for (var i = 0; i < data.length; i++) {
            _loop_1(i);
        }
        if (days.startsWith(',')) {
            days = days.replace(',', '');
        }
        return days;
    };
    RoomCostSetupFocusViewComponent.prototype.getDaysArr = function (data) {
        var daysArr = [];
        var _loop_2 = function (i) {
            var dayArray = this_2.daysOfWeek.find(function (day) { return day.key === data.charAt(i); }).value;
            daysArr.push({ key: data.charAt(i), day: dayArray });
        };
        var this_2 = this;
        for (var i = 0; i < data.length; i++) {
            _loop_2(i);
        }
        return daysArr;
    };
    return RoomCostSetupFocusViewComponent;
}());
export { RoomCostSetupFocusViewComponent };
