import { AssignmentStatus } from '../reservation/assignment-status';

export class AssignmentOperationCriteria {
    status: AssignmentStatus;
}

export enum AssignmentOperation {
    CONFIRM = 'confirm',
    SUPPLIER_REJECT = 'supplier_reject'
}
