import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthTokenService } from '../auth-token.service';
import { DMCCommon } from '../../services/util/common/dmc-common';

@Injectable()
export class AuthHeaderInterceptor implements HttpInterceptor
{
    saveToCookies = false;
    LOCAL_TOKEN_KEY = 'token';

    constructor(
        private authTokenService: AuthTokenService,
        private commonService: DMCCommon
    ) {
        // this.saveToCookies = this.commonService.getTokenSaveConfig() ? this.commonService.getTokenSaveConfig() : this.saveToCookies;
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let token = '';
        let addAsBearerToken = true;
        if (!this.saveToCookies) {
            addAsBearerToken = true;
            token = localStorage.getItem(this.LOCAL_TOKEN_KEY);
        } else {
            if (req.url.includes('/v1/user-profiles/me')) {
                addAsBearerToken = true;
            }
            token = this.authTokenService.getToken();
        }
        if (addAsBearerToken && token) {
            const cloned = req.clone({
                headers: req.headers.set('Authorization', 'Bearer ' + token)
            });
            return next.handle(cloned);
        }
        req.headers.set('Content-Type', 'application/json');
        req.headers.set('Allow-Origin-With-Credentials', 'true');
        req.headers.set('Access-Control-Allow-Credentials', 'true');
        req = req.clone({
            withCredentials: true
        });
        return next.handle(req);
    }
}
