<div [ngStyle]="{'width': unitWidth+'px'}" class="">
    <label *ngIf="unitIndex%4===0">{{unitTime}}</label>
    <tc-timeline-event
            *ngFor="let event of events"
            [ngStyle]="{'width': event?event.unitCount*unitWidth+'px':'0px', 'left' : event?event.margin*unitWidth+'px':'0px'}"
            [event]="event"
            (eventClick)="onEventClick($event)"
            class="event">
    </tc-timeline-event>
    <div *ngIf="isTarget && isTargetBlock" class="tc-strip-timeline__target tc-strip-timeline__target--group" [ngStyle]="{'width': targetUnitCount*unitWidth+'px'}" (click)="onTargetClick($event)"></div>
    <div *ngIf="isTarget && !isTargetBlock" class="tc-strip-timeline__target tc-strip-timeline__target--single" [ngStyle]="{'left' : targetMargin*unitWidth+'px'}" (click)="onTargetClick($event)"></div>
</div>
<!--<div class="tc-strip-timeline__point"></div>-->
