import {Component} from '@angular/core';
import {ChipInputService} from '@tc/chip-input/chip-input.service';
import {TCO} from '../../../../constants';
import {DocumentQueueSearchCriteria} from '../../../../models/criteria/document-queue-search-criteria';
import {DispatchStatus} from '../../../../models/document/dispatch-entry';
import {MainType} from '../../../../models/reservation-v2/main-type';
import {ChipHandlerService} from '../../../../services/helpers/chip-handler.service';
import {DataKey} from '../../../../services/util/framework/data-store.service';

@Component({
    selector: 'tc-document-queue-search-criteria',
    templateUrl: './document-queue-search-criteria.component.html',
    providers: [
        ChipHandlerService,
        {provide: 'ChipHandlerService', useExisting: ChipHandlerService},
        ChipInputService
    ]
})
export class DocumentQueueSearchCriteriaComponent {

    inputSearchCriteria = new DocumentQueueSearchCriteria();
    searchCriteriaUpdatingDataKey = DataKey.documentQueueSearchCriteria;
    searchResultsDataKey = DataKey.documentQueueSearchResultsForCriteria;
    searchCriteriaLoadingDataKey = TCO.AppData.DOCUMENT_QUEUE_SEARCH_CRITERIA;
    chipConfigKey = TCO.CONF.CONF_DOCUMENT_QUEUE_SEARCH_CRITERIA_CHIP;
    placeHolderText = 'Search Document Queues';

    createNewSearchCriteriaFn = () => {
        const criteria = new DocumentQueueSearchCriteria();
        criteria.start = 0;
        criteria.mainType = MainType.TRS;
        criteria.dispatchStartDate = new Date().toISOString().split('T')[0];
        criteria.dispatchEndDate = new Date(+new Date() + 172800000).toISOString().split('T')[0];
        criteria.dispatchStatus = DispatchStatus.FAILED;
        return criteria;
    };

    clearLoadedSearchCriteriaFn = (criteria: DocumentQueueSearchCriteria) => {
    };
}
