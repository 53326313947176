<div class="adv-client-search" *ngIf="genericResourceResultsList">
    <ng-container *ngFor="let genericResourceSummary of genericResourceResultsList |tcAdvPager:pager.currPage:pager.itemsPerPage | tcAdvSorter:sortingHandler:sorter; let i = index;">
        <div class="client-search-result-item-wrapper">
            <div
                    tcExpansionPanel
                    #resourceExp
                    tabindex="-1"
                    [include]="['tc-overlay', 'cdk-global-overlay-wrapper','tc-icon-block ecshc-card__actions']"
                    [enabled]="true"
                    (collapse)="expandedPanelIndex = -1">

                <!-- Content card Strip -->
                <div
                        *tcExpansionPanelSummary
                        id="resourceSumExp_{{i}}">
                    <tc-content-strip
                            (actionClick)="onActionClick(genericResourceSummary,$event)"
                            (click)="onGenericResourceSelection(genericResourceSummary,i)">
                        <tc-result-content-card-generic
                                [summaryContent]="genericResourceSummary">
                        </tc-result-content-card-generic>
                    </tc-content-strip>
                </div>

                <div *tcExpansionPanelDetail id="resourceDetailExp_{{i}}">
                    <tc-expanded-content-card class="tc-expanded-content-card tc-form-view" [isEdit]="false">
                        <tc-op-expanded-content-card-header
                                *ngIf="selectedGenericResourceSummary"
                                [headerText]="getHeader()"
                                [subHeaderText]="''"
                                [showEdit]="true"
                                [showEditAsNew]="true"
                                [showCalendar]="true"
                                (editClick)="onEditClick($event)"
                                (editAsNewClick)="onEditAsNewClick($event)"
                                (headerClick)="onHeaderClick($event)"
                        (calendarClick)="onCalendarClick($event)">
                        </tc-op-expanded-content-card-header>

                        <tc-generic-resource-detail-view
                                *ngIf="selectedGenericResourceSummary"
                                [resource]="detailedResource"
                                [selectedResourceCost]="selectedResourceCost"
                                [resourceTypes]="resourceTypes"
                                [trsModes]="trsModes"
                                [summaryCardData]="genericResourceSummary">
                        </tc-generic-resource-detail-view>


                    </tc-expanded-content-card>
                </div>
            </div>
        </div>
    </ng-container>
</div>

