import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TcApiError } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-api-error';
import { TcHttpError } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-http-error';
import { Journey } from '@tc-core/model/it/codegen/ui/framework/journey';
import { TC } from '@tc-core/util';
import { ConfigLoader, EventManager, UserJourneyManager } from '@tc-core/util/framework';
import { CommonHelper } from '@tc-core/util/helpers';
import { SpinnerService } from '@tc-core/util/ui';
import { ChipInputComponent } from '@tc/chip-input';
import { ChipInputService } from '@tc/chip-input/chip-input.service';
import { Subscription } from 'rxjs';
import { TCO } from '../../../constants';
import { AccountingRuleSearchCriteria } from '../../../models/criteria/accounting-rule-search-criteria';
import { GenericSearchCriteria } from '../../../models/criteria/generic-search-criteria';
import { ChipHandlerService } from '../../../services/helpers/chip-handler.service';
import { RootService } from '../../../services/util/core-services/root.service';
import { ActionHandlerService } from '../../../services/util/framework/action-handler-service.service';
import { DataKey, DataStoreService } from '../../../services/util/framework/data-store.service';
import { DMCQueryParamsService } from '../../../services/util/framework/dmc-query-params.service';

@Component({
    selector: 'tc-accounting-rules-setup-search-criteria',
    templateUrl: './accounting-rules-setup-search-criteria.component.html',
    providers: [
        ChipHandlerService,
        {provide: 'ChipHandlerService', useExisting: ChipHandlerService},
        ChipInputService
    ]
})
export class AccountingRulesSetupSearchCriteriaComponent implements OnInit, OnDestroy {

    @Input() isInsideForm: boolean = false;
    @Input() accRuleSearchCriteria: AccountingRuleSearchCriteria = new AccountingRuleSearchCriteria();
    @Input() searchCriteriaChipConfig: string;
    @Input() isSearchBarStyleNotAvailable: boolean = false;

    @Input() placeHolderText = 'Search Accounting Rules';

    @Output() searchTrigger: EventEmitter<boolean> = new EventEmitter();
    @Output() searchCriteria: EventEmitter<any> = new EventEmitter();

    tPagination: any;

    journey: string = null;

    isChipsInit = false;
    availableChips: any[] = [];
    persistentChipsArr = [];
    persistentQParams: string[] = [];
    persistentQParamMap = new Map<string, string>();

    private relatedChipsObsv: Subscription = new Subscription();
    modifiedChip;

    isDataLoaded: boolean = true;
    isTriggerSearch: boolean = true;
    displayChipsArr = [];
    isFocus: boolean = false;
    isChipListDisplay = false;

    private routerChangeSubscription: Subscription;
    private selectedChipsObsv: Subscription = new Subscription();
    private selectedFieldsObsv: Subscription = new Subscription();
    private chipValueUpdateSub: Subscription = new Subscription();
    private criteriaUpdateSubscription: Subscription = new Subscription();
    private deletedChipObsv: Subscription = new Subscription();
    private searchResultObserver: Subscription = new Subscription();
    private selectedChips = [];

    private chipInputService;
    @ViewChild('accounting_rule_search_chip_input') accRuleSearchChipInput: ChipInputComponent;
    private chipHandlerSubject: Subscription = new Subscription();

    constructor(
        private activatedRoute: ActivatedRoute,
        private configLoader: ConfigLoader,
        private userJourneyManager: UserJourneyManager,
        private dataStoreService: DataStoreService,
        private actionHandlerService: ActionHandlerService,
        private em: EventManager,
        private chipHandler: ChipHandlerService,
        private queryParamService: DMCQueryParamsService,
        private commonHelper: CommonHelper,
        private spinnerService: SpinnerService,
        private rootService: RootService
    ) { }

    ngOnInit() {
        this.chipInputService = this.accRuleSearchChipInput.criteriaInputService;

        //clear data store
        this.dataStoreService.set(DataKey.accountingRuleSearchCriteria, null, true);

        this.handleQuickActions();

        this.userJourneyManager.journey.subscribe((journey: Journey) => {
            if (journey && JSON.stringify(journey) !== JSON.stringify({})) {
                this.journey = journey.id;
            }
        });

        // resetting the view
        this.routerChangeSubscription = this.userJourneyManager.routeChanges.subscribe(e => {
            if (e) {
                this.chipInputService.fullReset(); // fully reset chips
                this.chipHandler.chipsArr = []; // reset chips array loaded from config
                // check journey begins from quick book
                if (!this.isInsideForm) {
                    this.getQueryParams();
                    this.initSearchCriteriaChips();
                }

                console.log('displayChipsArr', this.displayChipsArr);
                this.resetQueryParams();
            }
        });

        if (!this.isInsideForm) {
            this.getQueryParams();
            if (!this.isChipsInit) {
                this.initSearchCriteriaChips(); // load everything from start
            }

            this.accRuleSearchCriteria = new AccountingRuleSearchCriteria();

        }

        this.getRuleSearchObserver();
        this.errorObserver();
        this.selectedChipsUpdate();
        this.handleInterRelatedChips();
        this.notifyChipDelete();
        this.shortCutObserver();
    }

    getQueryParams() {

        let queryParams: string[] = [];
        queryParams = this.queryParamService.getQueryParamsArray(new AccountingRuleSearchCriteria());

        this.accRuleSearchCriteria = this.rootService.loadDataFromLocalStorage(queryParams, new AccountingRuleSearchCriteria(),
            TCO.AppData.ACC_RULE_SEARCH_CRITERIA
        );

        // this.accRuleSearchCriteria.blaBla = blaBla;

        // get persisting query params
        if (this.accRuleSearchCriteria) {
            this.persistentQParams = [];
            this.persistentQParams = this.queryParamService.getNotNullQueryParamsArray(this.accRuleSearchCriteria);

        }
    }

    private shortCutObserver() {
        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'FOCUS_SEARCH_BAR').subscribe(e => {
            this.isFocus = true;
        });
        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'CLOSE_POPUPS').subscribe(e => {
            this.isFocus = false;
        });
    }

    public onFocus($event) {
        this.isFocus = $event;
    }

    initSearchCriteriaChips() {
        this.isChipsInit = true;
        this.chipHandler.chipsArr = [];
        // read chip config
        let criteriaChipConfigs = null;
        if (!this.isInsideForm) {
            const chipConfigs = this.configLoader.configurations.get(TCO.CONF.CONF_ACC_RULE_SEARCH_CRITERIA_CHIP);
            criteriaChipConfigs = this.commonHelper.recreateJsonObject(chipConfigs);
        } else {
            const chipConfigs = this.configLoader.configurations.get(this.searchCriteriaChipConfig);
            criteriaChipConfigs = this.commonHelper.recreateJsonObject(chipConfigs);
        }
        if (criteriaChipConfigs && criteriaChipConfigs.chips) {
            criteriaChipConfigs.chips.forEach(chip => {
                chip = this.chipHandler.preProcessChips(chip, criteriaChipConfigs.permissionKeys.keys);
            });
        }

        this.chipHandler.chipsSubject.subscribe(data => {
            if (data && JSON.stringify(data) !== JSON.stringify([]) && data.length === criteriaChipConfigs.chips.length) {
                this.availableChips = data; //new json object here;
                this.chipHandler.chipsSubject.next(null);

                if (this.chipHandlerSubject) {
                    this.chipHandlerSubject.unsubscribe();
                }

                // set values to display chips
                this.displayChipsArr = [];
                this.displayChipsArr = this.availableChips;

                this.dataStoreService.set(DataKey.chips, this.availableChips, true);

                this.chipInputService.init(this.accRuleSearchCriteria, this.availableChips, false, true);
                this.isChipsInit = true;

                // implement chip persistance
                if (this.persistentQParams && this.persistentQParams.length > 0) {
                    // create a queryParamMap
                    for (let i = 0; i < this.persistentQParams.length; i++) {
                        let qParam = this.persistentQParams[i];
                        let qParamValue = this.accRuleSearchCriteria[qParam];
                        if (qParam && qParamValue) {
                            this.persistentQParamMap.set(qParam, qParamValue);
                        }
                    }
                    this.persistentChipsArr = [];
                    // fill persistent chips
                    this.availableChips.forEach(chip => {
                        let persistChip = Object.assign({}, chip);
                        persistChip.optionParams.data = [];
                        let isPersist = false;
                        if (chip.dataParams && chip.dataParams.length > 0) {
                            chip.dataParams.forEach(dataParam => {
                                if (this.persistentQParamMap.has(dataParam.paramValue)) {
                                    isPersist = true;
                                    const dataObj = {
                                        key: dataParam.paramValue,
                                        value: this.persistentQParamMap.get(dataParam.paramValue)
                                    };
                                    persistChip.optionParams.data.push(dataObj);
                                }
                            });
                        }
                        // push persist chips to an array
                        if (isPersist) {
                            this.persistentChipsArr.push(persistChip);
                        }
                    });
                    // persist chips
                    if (this.persistentChipsArr && this.persistentChipsArr.length > 0) {
                        const uniqueChipList = this.persistentChipsArr.filter((e, i) => {
                            return this.persistentChipsArr.findIndex((x) => {
                                return x.criteriaId === e.criteriaId;
                            }) === i;
                        });

                        this.chipInputService.persistChips(uniqueChipList);
                    }
                }
                // trigger search when chips persisting
                this.isDataLoaded = false;
                // this.isTriggerSearch = false;
                this.onRuleSearch(this.accRuleSearchCriteria);

                this.chipInputService.criteriaUpdate.subscribe((_data) => {
                    this.accRuleSearchCriteria = _data;
                });
            }
        });
    }

    public onRuleSearch($event) {
        this.isDataLoaded = false;
        this.accRuleSearchCriteria = $event;

        this.userJourneyManager.canProceed.next(false);

        if (JSON.stringify(this.accRuleSearchCriteria) !== JSON.stringify({})) {
            this.dataStoreService.set(DataKey.accountingRuleSearchCriteria, this.accRuleSearchCriteria, true);

            // set criteria parameters to url
            let queryParams: string[] = [];
            queryParams = this.queryParamService.getQueryParamsArray(this.accRuleSearchCriteria);
            this.rootService.setDataToLocalStorage(TCO.AppData.ACC_RULE_SEARCH_CRITERIA, this.accRuleSearchCriteria, true,
                queryParams, true
            );

            // this.spinnerService.show();

            this.isTriggerSearch = false;
            this.isDataLoaded = false;
            this.searchTrigger.emit(true);

        } else {
            setTimeout(() => {
                this.isDataLoaded = true;
                this.isTriggerSearch = true;
            }, 0);
        }

    }

    getUpdatedFieldsDataObject(chip, criteria: GenericSearchCriteria) {
        const dataObjArr = [];

        if (chip && chip.dataParams && chip.dataParams.length > 0) {
            chip.dataParams.forEach(dataParam => {
                const dataObj = {
                    key: '',
                    value: ''
                };
                dataObj.key = dataParam.paramValue;
                dataObj.value = criteria[dataParam.paramValue];

                dataObjArr.push(dataObj);
            });
        }

        return dataObjArr;
    }

    private getRuleSearchObserver() {
        this.searchResultObserver = this.dataStoreService.get(DataKey.accountingRuleSearchResultsForCriteria).subscribe((data) => {
            if (this.commonHelper.dataValidity(data)) {
                this.isDataLoaded = true;
                this.isTriggerSearch = false;
            } else if (this.commonHelper.isEmptyData(data)) {
                this.isDataLoaded = true;
            } else if (data instanceof TcApiError) {
                this.isDataLoaded = true;
            } else if (data instanceof TcHttpError) {
                this.isDataLoaded = true;
            }
        }, (error: any) => {
            console.log(error);
        });

    }

    private errorObserver() {
        this.dataStoreService.get(DataKey.error).subscribe((data) => {
            if (data && JSON.stringify(data) !== JSON.stringify({})) {
                setTimeout(() => {
                    this.isDataLoaded = true;
                }, 0);
                this.dataStoreService.set(DataKey.error, null);
            }
        });
    }

    private resetQueryParams() {

        let accRuleQueryParams: string[] = [];
        accRuleQueryParams = this.queryParamService.getQueryParamsArray(new AccountingRuleSearchCriteria());

        let accRuleSearchCriteria: AccountingRuleSearchCriteria = this.rootService.loadDataFromLocalStorage([],
            new AccountingRuleSearchCriteria(), TCO.AppData.ACC_RULE_SEARCH_CRITERIA
        );

        // set criteria parameters to url
        let queryParams: string[] = [];
        queryParams = this.queryParamService.getQueryParamsArray(accRuleSearchCriteria);

        this.rootService.setDataToLocalStorage(TCO.AppData.ACC_RULE_SEARCH_CRITERIA, this.accRuleSearchCriteria,
            true, queryParams, true
        );
    }

    public onCancel($event) {
        console.log('cancelled');
    }

    public onChipListDisplay($event) {
        this.dataStoreService.set(DataKey.chipListDisplay, null, true);
        this.isChipListDisplay = $event;
        this.dataStoreService.set(DataKey.chipListDisplay, this.isChipListDisplay, true);

    }

    handleQuickActions() {
        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'TRIGGER_SEARCH_CRITERIA').subscribe((e) => {
            this.triggerSearch(e.data);
        });
    }

    triggerSearch(data: string) {
        this.accRuleSearchCriteria = new AccountingRuleSearchCriteria();
        // todo criteria

        this.actionHandlerService.fillCriteriaByActionData(data, this.accRuleSearchCriteria);
        let requiredChips = this.chipHandler.getChipsFromCriteria(
            this.accRuleSearchCriteria,
            TCO.CONF.CONF_ACC_RULE_SEARCH_CRITERIA_CHIP,
            true
        );
        // requiredChips = this.chipHandler.validateAndFilterChips(requiredChips, ['bookingRefId|>|0']);
        this.chipInputService.hardReset();
        this.chipInputService.persistChips(requiredChips, true);
        this.onRuleSearch(this.accRuleSearchCriteria);
    }

    observeChipValueUpdate() {
    }

    private handleInterRelatedChips() {
        this.relatedChipsObsv = this.chipInputService.relatedChipsSubject.subscribe(
            (data) => {
                let toggleChips = [];
                if (data && data.chip && JSON.stringify(data) !== JSON.stringify({})) {
                    this.modifiedChip = data.chip;
                    // TODO: refine chip input service: inter related chips
                    if (data.chip.type === 'CALENDAR') {
                        this.chipHandler.handleInterRelatedChips(data.chip, data.date);
                    } else {
                        this.chipHandler.handleInterRelatedChips(data.chip, data.options.value[0]);
                    }
                    if (data.chip.optionParams.toggleChips && data.chip.optionParams.toggleChips.length > 0) {
                        toggleChips = data.chip.optionParams.toggleChips;
                        if (toggleChips && data.options && data.options.value[0].value) {
                            this.chipInputService.selectedChipsBasedOnStateChange(toggleChips);
                        } else {
                            this.chipInputService.deleteChipsBasedOnStateChange(toggleChips);
                        }
                    }
                }
            }
        );
    }

    ngOnDestroy() {

        // Remove this criteria's query params
        // this.queryParamService.removeParamsRelatedToObject(new AccountingRuleSearchCriteria());

        this.routerChangeSubscription.unsubscribe();
        this.userJourneyManager.routeChanges.next(false);

        if (this.selectedChipsObsv) {
            this.selectedChipsObsv.unsubscribe();
        }
        if (this.selectedFieldsObsv) {
            this.selectedFieldsObsv.unsubscribe();
        }
        if (this.chipValueUpdateSub) {
            this.chipValueUpdateSub.unsubscribe();
        }
        if (this.criteriaUpdateSubscription) {
            this.criteriaUpdateSubscription.unsubscribe();
        }
        if (this.deletedChipObsv) {
            this.deletedChipObsv.unsubscribe();
        }
        if (this.searchResultObserver) {
            this.searchResultObserver.unsubscribe();
        }

        this.em.removeEvents([
            'TRIGGER_SEARCH_CRITERIA'
        ]);
    }

    private selectedChipsUpdate() {
        this.criteriaUpdateSubscription = this.chipInputService.selectedChipsSubject.subscribe(selectedChips => {
            this.selectedChips = selectedChips;

            if (selectedChips && JSON.stringify(selectedChips) !== JSON.stringify([])) {
                // toggle chips when selecting BRANCH_OF_CLIENT_ID chip
                for (const selectedChip of this.selectedChips) {
                    if (selectedChip.chip.id === 'BRANCH_OF_CLIENT_ID' && selectedChip.chip.optionParams.toggleChips &&
                        selectedChip.chip.optionParams.toggleChips.length > 0) {
                        this.deleteToggleChips(selectedChip.chip.optionParams.toggleChips);
                        this.disableChips(selectedChip.chip.optionParams.toggleChips);
                        break;
                    }
                }
            }
        });
    }

    private notifyChipDelete() {
        this.deletedChipObsv = this.chipInputService.deletedChipsSubject.subscribe(deletedChip => {
            if (JSON.stringify(deletedChip) !== JSON.stringify({}) && deletedChip !== null && deletedChip !== undefined) {
                if (deletedChip.id === 'CLIENT_TYPE') {
                    const deleteChips = this.selectedChips.map(chip => chip.chip);
                    this.deleteSelectedChips(deleteChips);
                    this.enableChips(this.availableChips);
                } else if (deletedChip.id === 'BRANCH_OF_CLIENT_ID') {
                    if (deletedChip.optionParams.toggleChips && deletedChip.optionParams.toggleChips.length > 0) {
                        this.enableChips(deletedChip.optionParams.toggleChips);
                    }
                }
            }
        });
    }

    private deleteToggleChips(toggleChips: any[]) {
        const deleteChips = [];
        toggleChips.forEach(toggleChip => {
            for (const selectedChip of this.selectedChips) {
                if (selectedChip.chip.id === toggleChip.criteriaId) {
                    deleteChips.push(selectedChip.chip);
                    break;
                }
            }
        });
        this.deleteSelectedChips(deleteChips);
    }

    private deleteSelectedChips(deleteChips: any[]) {
        deleteChips.forEach(chip => {
            this.chipInputService.deleteChip(chip, true);
        });
    }

    private enableChips(enableChips: any[]) {
        enableChips.forEach(enableChip => {
            this.chipInputService.modifyChipsStatus(enableChip, false);
        });
    }

    private disableChips(disableChips: any[]) {
        disableChips.forEach(disableChip => {
            this.chipInputService.modifyChipsStatus(disableChip, true);
        });
    }
}
