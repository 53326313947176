<tc-chip-input
    #allocation_feature_search_chip_input
    [isDataLoaded]="isDataLoaded"
    [isTriggerSearch]="isTriggerSearch"
    [isChipListOptionAvail]="true"
    [chisForDisplay]="displayChipsArr"
    [isFocus]="isFocus"
    [isSearchBarStyleNotAvailable]="isSearchBarStyleNotAvailable"
    [placeHolderText]="placeHolderText"
    [inputAuthConfigComponent]="null"
    (searchEmitter)="onNotificationSearch($event)"
    (onfocus)="onFocus($event)"
    (cancelEmitter)="onCancel($event)"
    (chipListEmitter)="onChipListDisplay($event)">
</tc-chip-input>
<div class="clearfix"></div>
