<div class="container-fluid tc-item-wrapper tcpi-card">
    <div class="tc-item-wrapper__header tc-item-header-highlighted" *ngIf="showTitle">
        <div class="tc-item-header-highlighted__icon">
            <i class="material-icons material-icons">security</i>
        </div>
        <div class="tc-item-header-highlighted__primary">{{"Insurance Information" | translate}}</div>
    </div>
    <div class="tc-item-wrapper__body">
        <div class="row">
            <div class="col-md-12">
                <div class="tc-flex-box">
                    <div class="tc-flex-item icon-cage input-icon">
                        <div class="card-icon--small icn-color-primary">
                            <i class="material-icons material-icons">security</i>
                        </div>
                    </div>
                    <div class="tc-flex-item input-cage">
                        <div class="col-xs-12 col-sm-6  col-md-3 col-lg-3">
                            <div class="tc-content--wrap tc-input-view">
                                <div class="tc-caption-txt txt-light">
                                    {{"Insurance Company Name" | translate}}
                                </div>
                                <div
                                        class="tc-body1--txt">{{insuranceInformation?.insuranceCompanyName ? insuranceInformation.insuranceCompanyName : EMPTY_CHAR}}
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-6  col-md-3 col-lg-3">
                            <div class="tc-content--wrap tc-input-view">
                                <div class="tc-caption-txt txt-light">{{"Insured Date" | translate}}
                                </div>
                                <div
                                        class="tc-body1--txt">{{insuranceInformation?.insuredDate ? insuranceInformation.insuredDate : EMPTY_CHAR}}
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-6  col-md-3 col-lg-3">
                            <div class="tc-content--wrap tc-input-view">
                                <div class="tc-caption-txt txt-light">{{"Insurance Expiry Date" | translate}}
                                </div>
                                <div class="tc-body1--txt">{{insuranceInformation?.insuranceExpiryDate ? insuranceInformation.insuranceExpiryDate : EMPTY_CHAR}}
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                            <div class="tc-content--wrap tc-input-view">
                                <div class="tc-caption-txt txt-light">{{"Insurance Policy Number" | translate}}
                                </div>
                                <div class="tc-body1--txt">{{insuranceInformation?.policyNumber ? insuranceInformation.policyNumber : EMPTY_CHAR}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="tc-flex-box">
                    <div class="tc-flex-item icon-cage input-icon">
                        <div class="card-icon--small icn-color-primary">
                        </div>
                    </div>
                    <div class="tc-flex-item input-cage">
                        <div class="col-xs-12 col-sm-6  col-md-3 col-lg-3">
                            <div class="tc-content--wrap tc-input-view">
                                <div class="tc-caption-txt txt-light">
                                    {{"Email" | translate}}
                                </div>
                                <div
                                        class="tc-body1--txt">{{insuranceInformation?.email ? insuranceInformation.email : EMPTY_CHAR}}
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-6  col-md-3 col-lg-3">
                            <div class="tc-content--wrap tc-input-view">
                                <div class="tc-caption-txt txt-light">{{"Alternate Email" | translate}}
                                </div>
                                <div
                                        class="tc-body1--txt">{{insuranceInformation?.altEmail ? insuranceInformation.altEmail : EMPTY_CHAR}}
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-6  col-md-3 col-lg-3">
                            <div class="tc-content--wrap tc-input-view">
                                <div class="tc-caption-txt txt-light">{{"Telephone" | translate}}
                                </div>
                                <div class="tc-body1--txt">{{insuranceInformation?.telephone ? insuranceInformation.telephone : EMPTY_CHAR}}
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                            <div class="tc-content--wrap tc-input-view">
                                <div class="tc-caption-txt txt-light">{{"Alternate Telephone" | translate}}
                                </div>
                                <div class="tc-body1--txt">{{insuranceInformation?.altTelephone ? insuranceInformation.altTelephone : EMPTY_CHAR}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>


        <div class="row">
            <div class="col-md-12">
                <div class="tc-flex-box">
                    <div class="tc-flex-item icon-cage input-icon">
                        <div class="card-icon--small icn-color-primary">
                        </div>
                    </div>
                    <div class="tc-flex-item input-cage">
                        <div class="col-xs-12 col-sm-6  col-md-3 col-lg-3">
                            <div class="tc-content--wrap tc-input-view">
                                <div class="tc-caption-txt txt-light">
                                    {{"Country" | translate}}
                                </div>
                                <div
                                        class="tc-body1--txt">{{insuranceInformation?.country ? insuranceInformation.country : EMPTY_CHAR}}
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-6  col-md-3 col-lg-3">
                            <div class="tc-content--wrap tc-input-view">
                                <div class="tc-caption-txt txt-light">{{"State" | translate}}
                                </div>
                                <div
                                        class="tc-body1--txt  ">{{insuranceInformation?.state ? insuranceInformation.state : EMPTY_CHAR}}
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-6  col-md-3 col-lg-3">
                            <div class="tc-content--wrap tc-input-view">
                                <div class="tc-caption-txt txt-light">{{"City" | translate}}
                                </div>
                                <div class="tc-body1--txt  ">{{insuranceInformation?.city ? insuranceInformation.city : EMPTY_CHAR}}
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                            <div class="tc-content--wrap tc-input-view">
                                <div class="tc-caption-txt txt-light">{{"Street" | translate}}
                                </div>
                                <div class="tc-body1--txt  ">{{insuranceInformation?.street ? insuranceInformation.street : EMPTY_CHAR}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
