/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../widgets/shared/adv-vehicle-search-results/adv-vehicle-search-results.component.ngfactory";
import * as i2 from "../../widgets/shared/adv-vehicle-search-results/adv-vehicle-search-results.component";
import * as i3 from "../../../../node_modules/@tc/paginator/paginator.component.ngfactory";
import * as i4 from "@tc/paginator/paginator.component";
import * as i5 from "../../widgets/shared/result-header/result-header.component.ngfactory";
import * as i6 from "../../widgets/shared/result-header/result-header.component";
import * as i7 from "@angular/common";
import * as i8 from "./vehicle-search-results.component";
import * as i9 from "@tc-core/util/framework/config-loader.service";
import * as i10 from "../../services/util/framework/data-store.service";
import * as i11 from "@tc-core/service/sorters/sorter.service";
import * as i12 from "../../services/util/ui/content-summary-card.service";
import * as i13 from "@tc-core/util/ui/spinner.service";
import * as i14 from "@tc-core/util/framework/user-journey-manager.service";
import * as i15 from "../../services/backend-consumers/master-data-handler-service/data-handler.service";
import * as i16 from "../../services/backend-consumers/setups/resource-type.service";
import * as i17 from "../../services/backend-consumers/supplier-service/transport-resource.service";
import * as i18 from "../../services/backend-consumers/supplier-service/supplier.service";
import * as i19 from "@tc-core/util/helpers/common-helper.service";
import * as i20 from "@tc-core/util/ui/auto-scroll.service";
import * as i21 from "@tc/focus-view/focus-view.service";
import * as i22 from "../../services/util/framework/dmc-query-params.service";
import * as i23 from "../../services/util/core-services/root.service";
var styles_VehicleSearchResultsComponent = [];
var RenderType_VehicleSearchResultsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_VehicleSearchResultsComponent, data: {} });
export { RenderType_VehicleSearchResultsComponent as RenderType_VehicleSearchResultsComponent };
function View_VehicleSearchResultsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-adv-vehicle-search-results", [], null, [[null, "resourceSelect"], [null, "calendarClick"], [null, "edit"], [null, "editAsNew"], [null, "cardScroll"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("resourceSelect" === en)) {
        var pd_0 = (_co.onResourceSelect($event) !== false);
        ad = (pd_0 && ad);
    } if (("calendarClick" === en)) {
        var pd_1 = (_co.onCalendarClick($event) !== false);
        ad = (pd_1 && ad);
    } if (("edit" === en)) {
        var pd_2 = (_co.onEditResource($event) !== false);
        ad = (pd_2 && ad);
    } if (("editAsNew" === en)) {
        var pd_3 = (_co.onEditAsNewResource($event) !== false);
        ad = (pd_3 && ad);
    } if (("cardScroll" === en)) {
        var pd_4 = (_co.onCardScroll($event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, i1.View_AdvVehicleSearchResultsComponent_0, i1.RenderType_AdvVehicleSearchResultsComponent)), i0.ɵdid(1, 4308992, null, 0, i2.AdvVehicleSearchResultsComponent, [], { sorter: [0, "sorter"], sortingHandler: [1, "sortingHandler"], sorters: [2, "sorters"], pager: [3, "pager"], vehiclesResultsList: [4, "vehiclesResultsList"], selectedVehicleSummary: [5, "selectedVehicleSummary"], detailedVehicle: [6, "detailedVehicle"], selectedVehicleCost: [7, "selectedVehicleCost"] }, { resourceSelect: "resourceSelect", edit: "edit", editAsNew: "editAsNew", calendarClick: "calendarClick", cardScroll: "cardScroll" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.sorterService.sorter; var currVal_1 = _co.sorterService.method; var currVal_2 = _co.sorters_; var currVal_3 = _co.pager; var currVal_4 = _co.contentCardDataList; var currVal_5 = _co.selectedVehicleSummary; var currVal_6 = _co.detailedResource; var currVal_7 = _co.resourceCost; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }, null); }
function View_VehicleSearchResultsComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-paginator", [], null, [[null, "page"], [null, "more"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("page" === en)) {
        var pd_0 = (_co.onPage($event) !== false);
        ad = (pd_0 && ad);
    } if (("more" === en)) {
        var pd_1 = (_co.onMore() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i3.View_PaginatorComponent_0, i3.RenderType_PaginatorComponent)), i0.ɵdid(1, 638976, null, 0, i4.PaginatorComponent, [], { length: [0, "length"], index: [1, "index"], size: [2, "size"], hasMore: [3, "hasMore"] }, { page: "page", more: "more" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.contentCardDataList.length; var currVal_1 = _co.pager.currPage; var currVal_2 = _co.pager.itemsPerPage; var currVal_3 = _co.paginatorHasMore; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_VehicleSearchResultsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-result-header", [], null, [[null, "selectSorter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectSorter" === en)) {
        var pd_0 = (_co.onSort($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_ResultHeaderComponent_0, i5.RenderType_ResultHeaderComponent)), i0.ɵdid(1, 114688, null, 0, i6.ResultHeaderComponent, [], { heading: [0, "heading"], sorters: [1, "sorters"] }, { selectSorter: "selectSorter" }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_VehicleSearchResultsComponent_1)), i0.ɵdid(3, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_VehicleSearchResultsComponent_2)), i0.ɵdid(5, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.heading; var currVal_1 = _co.sorters_; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = (_co.searchResults && (_co.searchResults.length > 0)); _ck(_v, 3, 0, currVal_2); var currVal_3 = (_co.contentCardDataList.length > _co.defaultPageResultCount); _ck(_v, 5, 0, currVal_3); }, null); }
export function View_VehicleSearchResultsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-vehicle-search-results", [], null, null, null, View_VehicleSearchResultsComponent_0, RenderType_VehicleSearchResultsComponent)), i0.ɵdid(1, 245760, null, 0, i8.VehicleSearchResultsComponent, [i9.ConfigLoader, i10.DataStoreService, i11.SorterService, i12.ContentSummaryCardService, i13.SpinnerService, i14.UserJourneyManager, i15.DataHandlerService, i16.ResourceTypeService, i17.TransportResourceService, i18.SupplierService, i19.CommonHelper, i20.AutoScrollService, i21.FocusViewService, i22.DMCQueryParamsService, i23.RootService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var VehicleSearchResultsComponentNgFactory = i0.ɵccf("tc-vehicle-search-results", i8.VehicleSearchResultsComponent, View_VehicleSearchResultsComponent_Host_0, {}, {}, []);
export { VehicleSearchResultsComponentNgFactory as VehicleSearchResultsComponentNgFactory };
