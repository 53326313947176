import {BroadcastReceiver} from "./broadcastReceiver";

export class NotificationData {
    notificationId: number;
    description: string;
    assignmentId: number;
    supplierId: number;
    supplierName: string;
    resourceId: number;
    email: string;
    groupMsgThreadId: number;
    groupThreadName: string;
    resourceName: string;
    type: NotificationType;
    receiver : NotificationReceiver;
    serviceType : string;
    indicator : string;
    resourceType: string;
    sendingSupplierId: number;
    sendingResourceId: number;
    sendingEmail: string;
    sendingDmc: boolean;
    receivingSupplierId: number;
    receivingResourceId: number;
    receivingEmail: string;
    receivingDmc: boolean;
    modifiedOn:string;
    displayTimeStamp:any;
    readStatus: any;
    receivingResourcesIds:Array<BroadcastReceiver>;

    constructor() {
        this.displayTimeStamp = '';
    }

}

export enum NotificationType {
    LEAVE_REQUEST = "LEAVE_REQUEST",
    ASSIGNMENT_UPDATE = "ASSIGNMENT_UPDATE",
    RESOURCE_COMMUNICATION = "RESOURCE_COMMUNICATION",
    RESOURCE_BROADCAST = "RESOURCE_BROADCAST",
    NEW_USER_REGISTRATION = "NEW_USER_REGISTRATION",
}

export const TypeString = {
    LEAVE_REQUEST: "Leave Request",
    ASSIGNMENT_UPDATE: "Assignment Update",
    RESOURCE_COMMUNICATION: "Resource Communication",
    NEW_USER_REGISTRATION: "New User Registration",
}

export const ActionString = {
    LEAVE_REQUEST: "Open Resource Calendar",
    ASSIGNMENT_UPDATE: "Open Service Queue",
    RESOURCE_COMMUNICATION: "Open Message Queue",
    NEW_USER_REGISTRATION: "Open User Queue",
}

export enum NotificationReceiver {
    ONE_RESOURCE = "ONE_RESOURCE",
    ALL_RESOURCES = "ALL_RESOURCES",
    ONE_SUPPLIER = "ONE_SUPPLIER",
    ALL_SUPPLIERS = "ALL_SUPPLIERS",
    ALL_RESOURCES_AND_ALL_SUPPLIERS = "ALL_RESOURCES_AND_ALL_SUPPLIERS",
    DMC = "DMC",
    EVERYONE = "EVERYONE",
}
