<div class="container-fluid direct-client-personal-info-edit-card tc-card-wrapper">
    <div class="row">
        <div class="col-md-12 col-lg-12">
            <div class="tc-flex-box">
                <div class="tc-flex-item tc-item-circle">
                    <div class="icon-box tc-mb-0">
                        <i class="material-icons" aria-hidden="true">note</i>
                    </div>
                </div>
                <div class="tc-flex-item tc-right-cage">
                    {{traResourceCosts.length + genResourceCosts.length}} {{'Resource Costs' | translate}}
                </div>
            </div>
        </div>
    </div>
</div>
