import * as tslib_1 from "tslib";
import { ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { TcErrorType } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-error';
import { LEVEL } from '@tc-core/model/it/codegen/ui/framework/dialog-model';
import { ModalData } from '@tc-core/model/it/codegen/ui/framework/modal-data';
import { TC } from '@tc-core/util';
import { ConfigLoader } from '@tc-core/util/framework';
import { ModalService } from '@tc/modal/modal.service';
import { of, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SetupGridComp } from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid-comp';
import { SetupGridComponent } from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid.component';
import { TCO } from '../../../constants';
import { CitySearchCriteria } from '../../../models/criteria/city-search-criteria';
import { LocationGroupsSearchCriteria } from '../../../models/criteria/location-groups-search-criteria';
import { LocationTypeSearchCriteria } from '../../../models/criteria/location-type-search-criteria';
import { LocationsSearchCriteria } from '../../../models/criteria/locations-search-criteria';
import { SortDirection } from '../../../models/helper/sort-direction';
import { DataHandlerService } from '../../../services/backend-consumers/master-data-handler-service/data-handler.service';
import { MasterDataRestService } from '../../../services/backend-consumers/master-data-handler-service/master-data-rest.service';
import { LocationsService } from '../../../services/backend-consumers/setups/locations.service';
import { DMCCommon } from '../../../services/util/common/dmc-common';
import { DataKey, DataStoreService } from '../../../services/util/framework/data-store.service';
import { AddGeoLocationModalComponent } from '../add-geo-location-modal/add-geo-location-modal.component';
var EMPTY_CHAR = TC.CHIP_INPUT_DEFAULT_VALUES.EMPTY_CHAR;
var LocationsGridSetupComponent = /** @class */ (function (_super) {
    tslib_1.__extends(LocationsGridSetupComponent, _super);
    function LocationsGridSetupComponent(configLoader, dataStoreService, dataHandlerService, modalService, locationsService, masterDataRestService, cd, common) {
        var _this = _super.call(this) || this;
        _this.configLoader = configLoader;
        _this.dataStoreService = dataStoreService;
        _this.dataHandlerService = dataHandlerService;
        _this.modalService = modalService;
        _this.locationsService = locationsService;
        _this.masterDataRestService = masterDataRestService;
        _this.cd = cd;
        _this.common = common;
        _this.colDefConfig = [];
        _this.gridActions = [];
        _this.allReadyTakenLocationCodes = [];
        _this.locationsSearchCriteria = new LocationsSearchCriteria();
        // required options for grid editors
        // nothing
        _this.searchCriteriaObserver = new Subscription();
        _this.geoLocationDataObserver = new Subscription();
        _this.onGeoLocClick = function (params) {
            _this.openGeoLocationDialog(params.data);
        };
        _this.isDisableGeoClick = function (params) {
            return _this.checkRowInvalid(params.data);
        };
        /*
         grid config methods which are called by grid setup (ag grid)
         and set by grid config
         */
        _this.getLocationTypeText = function (params) {
            var typeText = '';
            if (params && params.data) {
                if (params.data.locationTypeName) {
                    typeText = params.data.locationTypeName;
                }
                else if (params.data.locationType) {
                    typeText = params.data.locationType;
                }
                else {
                    typeText = EMPTY_CHAR;
                }
            }
            return typeText;
        };
        _this.setLocationType = function (params) {
            if (params && params.data && params.newValue) {
                if (params.oldValue === '--' && _this.allReadyTakenLocationCodes.includes(params.data.code)) {
                    _this.common.showSnackBar('Location Code Already Exist. Please Select a Different Code', 3000, TcErrorType.TYPE.ERROR);
                }
                else {
                    params.data.locationType = params.newValue.code;
                    params.data.locationTypeName = params.newValue.name;
                }
            }
        };
        _this.getLocationGroupText = function (params) {
            var groupText = EMPTY_CHAR;
            if (params && params.data) {
                if (params.data.locationGroup) {
                    groupText = params.data.locationGroup;
                }
                else {
                    groupText = EMPTY_CHAR;
                }
                groupText += ' | ';
                if (params.data.locationGroupName) {
                    groupText += params.data.locationGroupName;
                }
                else if (params.data.locationGroup) {
                    groupText += EMPTY_CHAR;
                }
                else {
                    groupText = EMPTY_CHAR;
                }
            }
            return groupText;
        };
        _this.setLocationGroup = function (params) {
            if (params && params.data && params.newValue) {
                params.data.locationGroup = params.newValue.code;
                params.data.locationGroupName = params.newValue.name;
            }
        };
        _this.getLocationCityText = function (params) {
            var cityText = EMPTY_CHAR;
            if (params && params.data) {
                if (params.data.city) {
                    cityText = params.data.city;
                }
                else {
                    cityText = EMPTY_CHAR;
                }
                cityText += ' | ';
                if (params.data.cityName) {
                    cityText += params.data.cityName;
                }
                else if (params.data.city) {
                    cityText += EMPTY_CHAR;
                }
                else {
                    cityText = EMPTY_CHAR;
                }
            }
            return cityText;
        };
        _this.setCity = function (params) {
            if (params && params.data && params.newValue) {
                params.data.city = params.newValue.code;
                params.data.cityName = params.newValue.name;
                if (params.newValue.country) {
                    params.data.country = params.newValue.country.code;
                    params.data.countryName = params.newValue.country.name;
                }
            }
        };
        _this.getLocationTypes = function (params, rowData, text) {
            return _this.dataStoreService.get(DataKey.locationTypes)
                .map(function (aEvents) {
                return aEvents.filter(function (aEvent) {
                    if (text) {
                        if (aEvent.name) {
                            return aEvent.name.toLowerCase().indexOf(text.toLocaleString()) > -1;
                        }
                    }
                    else {
                        return true;
                    }
                });
            });
        };
        _this.getLocationGroups = function (params, rowData, text) {
            return _this.dataStoreService.get(DataKey.locationGroups)
                .map(function (aEvents) {
                return aEvents.filter(function (aEvent) {
                    if (text) {
                        if (aEvent.name) {
                            if (aEvent.name.toLowerCase().indexOf(text.toLowerCase().toLocaleString()) > -1) {
                                return aEvent.name.toLowerCase().indexOf(text.toLowerCase().toLocaleString()) > -1;
                            }
                            else {
                                return aEvent.code.toLowerCase().indexOf(text.toLowerCase().toLocaleString()) > -1;
                            }
                        }
                    }
                    else {
                        return true;
                    }
                });
            });
        };
        _this.getCities = function (params, rowData, text) {
            var citySearchCriteria = new CitySearchCriteria();
            citySearchCriteria.codeOrName = text.toLowerCase();
            return _this.masterDataRestService.searchCities(citySearchCriteria).map(function (result) { return result.data; });
        };
        return _this;
    }
    LocationsGridSetupComponent.prototype.ngOnInit = function () {
        this.colDefConfig = this.configLoader.configurations.get(TCO.CONF.CONF_LOCATIONS_SETUP);
        this.addGridActions();
        this.subscribeSearchCriteria();
        this.fillRequiredGridOptionData();
        this.fillDataKeyForEditorData();
        this.getLocationCodes();
    };
    LocationsGridSetupComponent.prototype.addGridActions = function () {
        this.gridActions.push({
            icon: 'edit_location',
            action: this.onGeoLocClick.bind(this),
            disable: this.isDisableGeoClick.bind(this),
            tooltip: 'Add Geo Location'
        });
    };
    /**
     * subscribe search criteria changes in search bar
     * then force grid to refresh data
     * grid refresh will be call getRaws method which is using this subscribed criteria for backend call
     */
    LocationsGridSetupComponent.prototype.subscribeSearchCriteria = function () {
        var _this = this;
        this.searchCriteriaObserver = this.dataStoreService.get(DataKey.locationsSearchCriteria)
            .subscribe(function (value) {
            _this.locationsSearchCriteria = value;
            _this.setupGrid.runForceSearch();
        });
    };
    LocationsGridSetupComponent.prototype.openGeoLocationDialog = function (data) {
        var _this = this;
        this.dataStoreService.set(DataKey.geoCodes, null, true);
        this.dataStoreService.set(DataKey.popupClose, null);
        var dataObject = new ModalData(LEVEL.SUCCESS, 'Add Geo Location', { 'label': 'OK' }, { 'label': 'Close' }, 'modal-basic--medium modal-basic--alt', AddGeoLocationModalComponent, {
            'locationData': data,
            'longitude': data.longitude,
            'latitude': data.latitude
        }, '', '', '', '', '', '  ' + data.code + ' - ' + data.name);
        this.confirmModal(dataObject, data);
        this.dataStoreService.get(DataKey.popupClose).subscribe(function (data) {
            if (data != null) {
                _this.closeModal();
            }
        });
    };
    LocationsGridSetupComponent.prototype.subscribeGeoLocation = function (data) {
        var _this = this;
        this.geoLocationDataObserver = this.dataStoreService.get(DataKey.geoCodes).subscribe(function (value) {
            if (value != null) {
                _this.setupGrid.refreshCells();
                if (!_this.checkRowInvalid(value)) {
                    _this.setupGrid.saveRowData(value);
                }
            }
        });
    };
    LocationsGridSetupComponent.prototype.checkRowInvalid = function (data) {
        var event = {
            data: data
        };
        return this.isInvalidRow(event);
    };
    LocationsGridSetupComponent.prototype.closeModal = function () {
        if (this.modalService.close()) {
            this.modalService.close().subscribe(function (res) {
                // this.cd.detectChanges();
            });
        }
    };
    LocationsGridSetupComponent.prototype.confirmModal = function (dataObject, locationData) {
        var _this = this;
        this.modalService.confirm(dataObject).subscribe(function (res) {
            _this.subscribeGeoLocation(locationData);
        });
    };
    LocationsGridSetupComponent.prototype.fillRequiredGridOptionData = function () {
        // nothing
    };
    LocationsGridSetupComponent.prototype.fillDataKeyForEditorData = function () {
        this.addLocationTypes();
        this.addLocationGroups();
    };
    LocationsGridSetupComponent.prototype.addLocationTypes = function () {
        var _this = this;
        var locationTypeSearchCriteria = new LocationTypeSearchCriteria();
        locationTypeSearchCriteria.size = 1000000;
        this.locationsService.getLocationTypes(locationTypeSearchCriteria).subscribe(function (result) {
            _this.dataStoreService.set(DataKey.locationTypes, result.data, true);
        });
    };
    LocationsGridSetupComponent.prototype.addLocationGroups = function () {
        var _this = this;
        var locationGroupsSearchCriteria = new LocationGroupsSearchCriteria();
        locationGroupsSearchCriteria.size = 1000000;
        this.locationsService.getLocationGroups(locationGroupsSearchCriteria).subscribe(function (result) {
            _this.dataStoreService.set(DataKey.locationGroups, result.data, true);
        });
    };
    LocationsGridSetupComponent.prototype.isInvalidRow = function (params) {
        var data = params.data;
        if (data) {
            var invalid = true;
            if (data.code &&
                data.name &&
                data.locationType) {
                invalid = false;
            }
            return invalid;
        }
        else {
            return false;
        }
    };
    LocationsGridSetupComponent.prototype.createNewRow = function () {
        return { unsaved: true };
    };
    LocationsGridSetupComponent.prototype.isUnsavedRow = function (row) {
        return !!row.unsaved;
    };
    LocationsGridSetupComponent.prototype.deleteRow = function (row) {
        if (row && row.code) {
            return this.locationsService.deleteLocation(row.code);
        }
        else {
            return of('success');
        }
    };
    LocationsGridSetupComponent.prototype.saveRow = function (row, event) {
        var saveData = row ? row : event;
        return this.locationsService.saveLocation(saveData)
            .pipe(tap(function (data) { return row.unsaved = undefined; }));
    };
    LocationsGridSetupComponent.prototype.getRows = function (params) {
        var _this = this;
        var pageSize = params.endRow - params.startRow;
        if (!this.locationsSearchCriteria) {
            this.locationsSearchCriteria = new LocationsSearchCriteria();
        }
        this.locationsSearchCriteria.start = params.startRow;
        this.locationsSearchCriteria.size = pageSize;
        if (params.sortModel && params.sortModel[0]) {
            this.locationsSearchCriteria.sortBy = params.sortModel[0].colId;
            if (params.sortModel[0].sort === SetupGridComp.GRID_SORT_ASCENDING) {
                this.locationsSearchCriteria.sortDirection = SortDirection.ASC;
            }
            else {
                this.locationsSearchCriteria.sortDirection = SortDirection.DESC;
            }
        }
        else {
            this.locationsSearchCriteria.sortBy = 'createdOn';
            this.locationsSearchCriteria.sortDirection = SortDirection.DESC;
        }
        console.log(this.locationsSearchCriteria);
        return this.locationsService.getLocations(this.locationsSearchCriteria)
            .pipe(tap(function (data) {
            return _this.dataStoreService.set(DataKey.locationsSearchResultsForCriteria, data);
        }));
    };
    LocationsGridSetupComponent.prototype.ngOnDestroy = function () {
        if (this.searchCriteriaObserver) {
            this.searchCriteriaObserver.unsubscribe();
        }
        if (this.geoLocationDataObserver) {
            this.geoLocationDataObserver.unsubscribe();
        }
    };
    LocationsGridSetupComponent.prototype.getLocationCodes = function () {
        var _this = this;
        this.locationsService.getLocationCodes().subscribe(function (result) {
            _this.allReadyTakenLocationCodes = result.data;
        });
    };
    return LocationsGridSetupComponent;
}(SetupGridComp));
export { LocationsGridSetupComponent };
