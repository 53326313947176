import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GridApi, ICellRendererParams } from 'ag-grid-community';
import { CheckBoxRendererEditorComponent } from '../../../../../widgets/framework/ag-grid-custom-components/renderers/check-box-selector-renderer/check-box-renderer-editor.component';
import { Booking } from '../../../../../models/reservation-v2/booking';
import { BookingItem } from '../../../../../models/reservation-v2/booking-item';
import { DataKey, DataStoreService } from '../../../../../services/util/framework/data-store.service';

@Component({
    selector: 'tc-passenger-selection',
    templateUrl: './passenger-selection.component.html',
    styleUrls: ['./passenger-selection.component.scss']
})
export class PassengerSelectionComponent implements OnInit {

    @Input() booking: Booking;
    @Input() bookingItem: BookingItem;
    @Input() readonly = false;
    @Input() onChangeFn: any;

    disableClose = false;
    disableCloseMsg = 'Please select the lead passenger';

    public overlayNoRowsTemplate: any = `<div class="tc-ag-grid-empty">
            <i class="material-icons tc-ag-grid-empty__icon">assignment_error</i>
            <span class="tc-ag-grid-empty__text">No Records Available</span>
        </div>`;

    @Input() output = {
        changed: undefined,
        valid: undefined
    };

    gridApi: GridApi;
    defaultColDef = {
        headerValueGetter: (parameters: ICellRendererParams): string => {
            const headerIdentifier = parameters.colDef.headerName;
            if (headerIdentifier) {
                return this.translateService.instant(headerIdentifier);
            }
            return '';
        }
    };
    columnDefs;
    frameworkComponents = {
        checkBoxRendererEditor: CheckBoxRendererEditorComponent
    };

    constructor(
        private translateService: TranslateService,
        private dataStore: DataStoreService
    ) {
        this.translateService.onLangChange.subscribe(() => {
            this.gridApi.refreshHeader();
        });
    }

    ngOnInit() {
        this.setColDefs();
        if (!this.bookingItem.leadPassenger) {
            this.disableClose = true;
        }
    }

    setColDefs() {
        this.columnDefs = [
            {
                headerName: 'Selection',
                width: 140,
                minWidth: 140,
                maxWidth: 140,
                pinned: 'left',
                field: 'availableInItem',
                valueGetter: this.isSelected.bind(this),
                cellRendererFramework: this.frameworkComponents.checkBoxRendererEditor,
                editInRenderer: !this.readonly,
                cellRendererParams: {
                    afterEdit: this.afterPassengerSelect.bind(this)
                }
            },
            {
                headerName: 'Title',
                field: 'title'
            },
            {
                headerName: 'First Name',
                field: 'firstName'
            },
            {
                headerName: 'Middle Name',
                field: 'middleName',
                hide: true
            },
            {
                headerName: 'Last Name',
                field: 'lastName'
            },
            {
                headerName: 'Type',
                field: 'typeName'
            },
            {
                headerName: 'Lead',
                field: 'lead',
                cellRendererFramework: this.frameworkComponents.checkBoxRendererEditor,
                valueGetter: this.isLead.bind(this),
                cellRendererParams: {
                    afterEdit: this.afterLeadEdit.bind(this)
                },
                editInRenderer: !this.readonly
            }
        ];
    }

    isSelected = (params) => {
        if (params.data && this.bookingItem.passengerTempIds) {
            const found = this.bookingItem.passengerTempIds.find(i => i === params.data.tempId);
            return !!found;
        } else {
            return false;
        }
    };

    afterPassengerSelect = (event: { newValue, oldValue, data }) => {
        if (!event.newValue) {
            const index = this.bookingItem.passengerTempIds.indexOf(event.data.tempId);
            this.bookingItem.passengerTempIds.splice(index, 1);
            if (this.bookingItem.leadPassenger && this.bookingItem.leadPassenger.tempId === event.data.tempId) {
                this.bookingItem.leadPassenger = null;
                this.gridApi.refreshCells({force: true, columns: ['lead']});
            }
        } else {
            this.bookingItem.passengerTempIds.push(event.data.tempId);
        }
        this.onChangeData();
    };

    isLead = (params) => {
        if (params.data && this.bookingItem.leadPassenger) {
            return params.data.tempId === this.bookingItem.leadPassenger.tempId;
        } else {
            return false;
        }
    };

    afterLeadEdit = (event: { newValue, oldValue, data }) => {
        if (event.newValue) {
            const index = this.bookingItem.passengerTempIds.indexOf(event.data.tempId);
            if (index > -1) {
                this.bookingItem.leadPassenger = event.data;
                this.onChangeData();
            }
            this.gridApi.refreshCells({force: true, columns: ['lead']});
        } else {
            this.bookingItem.leadPassenger = null;
            this.onChangeData();
            this.gridApi.refreshCells({force: true, columns: ['lead']});
        }
    };

    public onGridReady(params: any) {
        this.gridApi = params.api;
        this.gridApi.sizeColumnsToFit();
    }

    public onClose(event: MouseEvent) {
        this.dataStore.set(DataKey.popupClose, this.output);
    }

    public onChangeData() {
        this.output.changed = true;
        let valid = true;
        valid = !!(this.bookingItem.leadPassenger && this.bookingItem.passengerTempIds && this.bookingItem.passengerTempIds.length);
        if (valid) {
            this.disableClose = false;
        } else {
            this.disableClose = true;
        }
        if (this.onChangeFn) {
            this.onChangeFn(valid);
        }
    }
}
