export enum CostingType {
    DURATION= 'PH',
    DISTANCE = 'PK',
    UNIT =  'PU',
    JOURNEY =  'PJ',
    PERSON =  'PP',
}

export enum CostingCategory {
    DISTANCE= 'DISTANCE',
    DURATION = 'DURATION',
    UNIT =  'UNIT',
    JOURNEY =  'JOURNEY',
    PERSON =  'PERSON',
}
