import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SecurityModule } from '@tc/security';
import { TranslateModule } from '@ngx-translate/core';
import { AdditionalContactsCardComponent } from './additional-contacts-card.component';

@NgModule({
    imports: [
        TranslateModule.forChild(),
        CommonModule,
        SecurityModule
    ],
    exports: [
        AdditionalContactsCardComponent
    ],
    declarations: [
        AdditionalContactsCardComponent
    ],
    providers: [],
})
export class AdditionalContactsCardModule {
}
