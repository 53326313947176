import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TooltipPosition } from '@angular/material';
import { SyncStatus } from '@tc-core/model/it/codegen/tbx/api/product/external-Info';
import STATUS = SyncStatus.STATUS;

@Component({
  selector: 'tc-op-expanded-content-card-header',
  templateUrl: './op-expanded-content-card-header.component.html'
})
export class OpExpandedContentCardHeaderComponent implements OnInit {

  @Input() headerText: string;
  @Input() isSharedBooking = false;
  @Input() isGroupBooking = false;
  @Input() status: string;
  @Input() isSharewith = false;
  @Input() subHeaderText: string;
  @Input() showEdit: boolean;
  @Input() showNewTab: boolean;
  @Input() showDelete: boolean;
  @Input() showClose: boolean;
  @Input() showMore: boolean;
  @Input() showEditAsNew: boolean;
  @Input() showProgress: boolean;
  @Input() public showSync: boolean;
  @Input() public isSynced: boolean;
  @Input() showBkgAmd: boolean;
  @Input() syncStatus: any;
  @Input() navItems: any;
  @Input() authConfigComponent: string = "";
  @Input() showCalendar: boolean = false;

  // TODO review these
  @Input() hideActions: boolean;
  @Input() isNewClient: boolean;

  public toolTipPosition: TooltipPosition = 'below';

  @Output() headerClick: EventEmitter<any> = new EventEmitter();
  @Output() editClick: EventEmitter<any> = new EventEmitter();
  @Output() newTabClick: EventEmitter<any> = new EventEmitter();
  @Output() deleteClick: EventEmitter<any> = new EventEmitter();
  @Output() closeClick: EventEmitter<any> = new EventEmitter();
  @Output() moreClick: EventEmitter<any> = new EventEmitter();
  @Output() editAsNewClick: EventEmitter<any> = new EventEmitter();
  @Output() public syncClick: EventEmitter<any> = new EventEmitter();
  @Output() bookingAmdClick: EventEmitter<any> = new EventEmitter();
  @Output() groupActionClick: EventEmitter<any> = new EventEmitter();
  @Output() calendarClick: EventEmitter<any> = new EventEmitter();

  public SYNC_STATUS_NOT_SENT: STATUS;
  public SYNC_STATUS_PENDING: STATUS;
  public SYNC_STATUS_SUCCESS: STATUS;
  public SYNC_STATUS_FAILED: STATUS;

  constructor() { }

  ngOnInit() {
    this.SYNC_STATUS_NOT_SENT = SyncStatus.STATUS.NOT_SENT;
    this.SYNC_STATUS_PENDING = SyncStatus.STATUS.PENDING;
    this.SYNC_STATUS_SUCCESS = SyncStatus.STATUS.SUCCESS;
    this.SYNC_STATUS_FAILED = SyncStatus.STATUS.FAILED;
  }

  onHeaderClick(event) {
    this.headerClick.emit(event);
  }

  onEditClick(event) {
    this.editClick.emit(event);
  }

  onNewTabClick(event) {
    this.newTabClick.emit(event);
  }

  onDeleteClick(event) {
    this.deleteClick.emit(event);
  }

  onCloseClick(event) {
    this.closeClick.emit(event);
  }

  onMoreClick(event) {
    this.moreClick.emit(event);
  }

  onGroupActionClick(event) {
    this.groupActionClick.emit(event);
  }

  public onSyncClick(event) {
    // if (!this.isSynced) {
    this.syncClick.emit(event);
    // }
  }

  public onBookingAmdClick(event) {
    this.bookingAmdClick.emit(event);
  }

  public onEditAsNewClick(event: any) {
    this.editAsNewClick.emit(event);
  }

  public onCalendarClick(event: any) {
    this.calendarClick.emit(event);
  }
}
