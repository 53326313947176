<div class="">
  <div class="tc-sc-summery-panel__action-header">
    <div *ngIf="validYears && validYears.length>0" class="tc-icon-block__icon">
      <tc-chip-dropdown
        class="transfer-mode-selection"
        [dropdownOptions]="validYears"
        (selectedOption)="onSelectGroupingType($event)">
      </tc-chip-dropdown>

    </div>
  </div>


    <div class="rs-full-width">

      <!-- header -->
      <div class="container tc-sc-summery-panel__header">
        <div class="row item-header__content">
          <ng-container>
            <div class="col-xs-4">
              <span>{{"Season" |translate}}</span>
            </div>
          </ng-container>
          <ng-container>
            <div class="col-xs-8">
              <span>{{"Date Band" |translate}}</span>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="container tc-sc-summery-panel__body">
        <div class="row tc-sc-summery-panel__card" *ngFor="let rangeGroup of getShowingSeasons()">
          <div class="col-xs-4 tc-sc-summery-panel__card-col1">
            {{rangeGroup.name}}
          </div>
          <div class="col-xs-8 tc-sc-summery-panel__card-col2">{{getDateBandsStr(rangeGroup)}}</div>
        </div>
      </div>
    </div>
</div>

