import {Injectable, Injector} from '@angular/core';
import {DateFormatter} from '@tc-core/util/system/date-formatter.service';
import {DatePipe} from '@angular/common';
// import * as _ from "lodash";

@Injectable()
export class UtilService {

    constructor(
        private dateFormatter: DateFormatter,
        private datePipe: DatePipe
    ) {
    }

    // isEqual(o1, o2):boolean {
    //     let anies = _.differenceWith(o1, o2, _.isEqual);
    //     console.log(anies);
    //     return _.isEqual(o1, o2);
    // }

    addOrUpdateToArray(array: any, filterKey: string, newData: any) {
        if (newData[filterKey] !== undefined) {
            let filtered = array.filter(o => o[filterKey] === newData[filterKey]);
            if (filtered && filtered.length > 0) {
                let obj = array.splice(array.findIndex(o => o[filterKey] === newData[filterKey]), 1);
                array.push(newData);
            } else {
                array.push(newData);
            }
            return array;
        } else {
            throw new Error(`Array not updated. '${filterKey}' is undefined in Object`);
        }
    }

    localDateTimeToDate(localDateTime: LocalDateTime): Date {
        return this.dateFormatter.dateTime(localDateTime.year, localDateTime.monthValue, localDateTime.dayOfMonth,localDateTime.hour, localDateTime.minute);
    }

    stringToDate(date: string): Date {
        return this.dateFormatter.dateFromString(date);
    }

    localTimeString(h:number,m:number){
        return ("0" + h).slice(-2)+':'+("0" + m).slice(-2);
    }

    setTimezoneAsGMTWithoutConverting(date: any) {
        let tempDate;
        if (date != null) {
            tempDate = this.datePipe.transform(date, 'yyyy-MM-dd') + 'T00:00:00.000Z';
        }
        return tempDate ? tempDate : null;
    }

}

class LocalDateTime {

    // "monthValue": 2,
    // "hour": 12,
    // "minute": 39,
    // "second": 0,
    // "nano": 0,
    // "year": 2019,
    // "month": "FEBRUARY",
    // "dayOfMonth": 28,
    // "dayOfWeek": "THURSDAY",
    // "dayOfYear": 59,

    monthValue: number;
    hour: number;
    minute: number;
    second: number;
    nano: number;
    year: number;
    month: string;
    dayOfMonth: number;
    dayOfWeek: string;
    dayOfYear: number;
}
