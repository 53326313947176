import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChipInputModule } from '@tc/chip-input';
import { ResourceAllocationSearchCriteriaComponent } from './resource-allocation-search-criteria.component';

@NgModule({
    declarations: [ResourceAllocationSearchCriteriaComponent],
    exports: [
        ResourceAllocationSearchCriteriaComponent
    ],
    imports: [
        CommonModule,
        ChipInputModule
    ]
})
export class ResourceAllocationSearchCriteriaModule { }
