import { EventEmitter } from '@angular/core';
import { CurrencyFormat } from '@tc-core/model/it/codegen/ui/framework/currency-format';
import { TC } from '@tc-core/util';
import { ConfigLoader } from '@tc-core/util/framework';
import { TimelineEvent } from '../timeline-event';
var TimelineEventComponent = /** @class */ (function () {
    function TimelineEventComponent(configLoader) {
        this.configLoader = configLoader;
        this.widthNoOfUnits = 0;
        this.unitWidth = 0;
        this.height = 0;
        this.eventData = null;
        this.eventClick = new EventEmitter();
        this.vehicle = 'vehicle';
    }
    Object.defineProperty(TimelineEventComponent.prototype, "event", {
        set: function (e) {
            this.eventData = e;
            this.currencyFormat = this.getCurrencyFormat();
        },
        enumerable: true,
        configurable: true
    });
    TimelineEventComponent.prototype.getCurrencyFormat = function () {
        if (this.eventData && this.eventData.assignment) {
            var currencyFormat = void 0;
            var currencySymbolPattern = this.configLoader
                .configurations
                .get(TC.CONF.CONF_SYSTEM).defaults.default_currency_symbol_pattern;
            currencyFormat = new CurrencyFormat(this.eventData.assignment.currency, currencySymbolPattern);
            return currencyFormat;
        }
        return null;
    };
    TimelineEventComponent.prototype.onEventClick = function (clickEvent) {
        clickEvent.stopPropagation();
        this.eventClick.emit(this.eventData);
    };
    return TimelineEventComponent;
}());
export { TimelineEventComponent };
