import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { LEVEL } from '@tc-core/model/it/codegen/ui/framework/dialog-model';
import { ModalData } from '@tc-core/model/it/codegen/ui/framework/modal-data';
import { ConfigLoader } from '@tc-core/util/framework';
import { FocusViewManager } from '@tc-core/util/framework/focus-view-manager.service';
import { FocusViewService } from '@tc/focus-view/focus-view.service';
import { of, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SetupGridComp } from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid-comp';
import { SetupGridComponent } from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid.component';
import { TCO } from '../../../constants';
import { LocationSelectionFocusViewComponent } from '../../location-selection-focus-view/location-selection-focus-view.component';
import { LocationGroupsSearchCriteria } from '../../../models/criteria/location-groups-search-criteria';
import { LocationsSearchCriteria } from '../../../models/criteria/locations-search-criteria';
import { SortDirection } from '../../../models/helper/sort-direction';
import { DataHandlerService } from '../../../services/backend-consumers/master-data-handler-service/data-handler.service';
import { LocationsService } from '../../../services/backend-consumers/setups/locations.service';
import { DataKey, DataStoreService } from '../../../services/util/framework/data-store.service';
import { ResponseUtil } from '../../../services/util/response/response-util.service';
var LocationGroupsSetupComponent = /** @class */ (function (_super) {
    tslib_1.__extends(LocationGroupsSetupComponent, _super);
    function LocationGroupsSetupComponent(configLoader, dataStoreService, dataHandlerService, locationsService, focusViewManager, focusViewService) {
        var _this = _super.call(this) || this;
        _this.configLoader = configLoader;
        _this.dataStoreService = dataStoreService;
        _this.dataHandlerService = dataHandlerService;
        _this.locationsService = locationsService;
        _this.focusViewManager = focusViewManager;
        _this.focusViewService = focusViewService;
        _this.colDefConfig = [];
        _this.gridActions = [];
        _this.locGroupsSearchCriteria = new LocationGroupsSearchCriteria();
        // required options for grid editors
        // nothing
        _this.searchCriteriaObserver = new Subscription();
        _this.focusCloseSubscription = new Subscription();
        _this.focusViewCloseSubject = new Subscription();
        // open location selection
        _this.onAddLocClick = function (params) {
            _this.getLocationsByLocationGroup(params.data);
        };
        return _this;
    }
    LocationGroupsSetupComponent.prototype.ngOnInit = function () {
        this.colDefConfig = this.configLoader.configurations.get(TCO.CONF.CONF_LOCATION_GROUPS_SETUP);
        this.addGridActions();
        this.subscribeSearchCriteria();
        this.fillRequiredGridOptionData();
    };
    LocationGroupsSetupComponent.prototype.addGridActions = function () {
        this.gridActions.push({
            icon: 'add_location_alt',
            action: this.onAddLocClick.bind(this),
            disable: false,
            tooltip: 'Add Locations'
        });
    };
    /**
     * subscribe search criteria changes in search bar
     * then force grid to refresh data
     * grid refresh will be call getRaws method which is using this subscribed criteria for backend call
     */
    LocationGroupsSetupComponent.prototype.subscribeSearchCriteria = function () {
        var _this = this;
        this.searchCriteriaObserver = this.dataStoreService.get(DataKey.locationGroupsSearchCriteria)
            .subscribe(function (value) {
            if (value) {
                _this.locGroupsSearchCriteria = value;
                _this.setupGrid.runForceSearch();
            }
        });
    };
    LocationGroupsSetupComponent.prototype.fillRequiredGridOptionData = function () {
        // nothing
    };
    LocationGroupsSetupComponent.prototype.isInvalidRow = function (params) {
        var data = params.data;
        if (data) {
            var invalid = true;
            if (data.code &&
                data.name) {
                invalid = false;
            }
            return invalid;
        }
        else {
            return false;
        }
    };
    LocationGroupsSetupComponent.prototype.createNewRow = function () {
        return { unsaved: true };
    };
    LocationGroupsSetupComponent.prototype.isUnsavedRow = function (row) {
        return !!row.unsaved;
    };
    LocationGroupsSetupComponent.prototype.deleteRow = function (row) {
        if (row && row.code) {
            return this.locationsService.deleteLocationGroup(row.code);
        }
        else {
            return of('success');
        }
    };
    LocationGroupsSetupComponent.prototype.saveRow = function (row, event) {
        return this.locationsService.saveLocationGroup(row)
            .pipe(tap(function (data) { return row.unsaved = undefined; }));
    };
    LocationGroupsSetupComponent.prototype.getRows = function (params) {
        var _this = this;
        console.log('fetch requesting for ' + params.startRow + ' to ' + params.endRow);
        console.log(params.sortModel);
        var pageSize = params.endRow - params.startRow;
        if (!this.locGroupsSearchCriteria) {
            this.locGroupsSearchCriteria = new LocationGroupsSearchCriteria();
        }
        this.locGroupsSearchCriteria.start = params.startRow;
        this.locGroupsSearchCriteria.size = pageSize;
        if (params.sortModel && params.sortModel[0]) {
            this.locGroupsSearchCriteria.sortBy = params.sortModel[0].colId;
            if (params.sortModel[0].sort === SetupGridComp.GRID_SORT_ASCENDING) {
                this.locGroupsSearchCriteria.sortDirection = SortDirection.ASC;
            }
            else {
                this.locGroupsSearchCriteria.sortDirection = SortDirection.DESC;
            }
        }
        else {
            this.locGroupsSearchCriteria.sortBy = 'createdOn';
            this.locGroupsSearchCriteria.sortDirection = SortDirection.DESC;
        }
        return this.locationsService.getLocationGroups(this.locGroupsSearchCriteria)
            .pipe(tap(function (data) {
            return _this.dataStoreService.set(DataKey.locationGroupsSearchResultsForCriteria, data);
        }));
    };
    LocationGroupsSetupComponent.prototype.ngOnDestroy = function () {
        if (this.searchCriteriaObserver) {
            this.searchCriteriaObserver.unsubscribe();
        }
    };
    LocationGroupsSetupComponent.prototype.openLocationSelectionModal = function (locationGroup, locations) {
        var _this = this;
        var secondaryHeader = '  ' + locationGroup.code + ' - ' + locationGroup.name;
        setTimeout(function () {
            var fabActions = [];
            var navItemsArray = _this.configLoader.configurations.get(TCO.CONF.CONF_NAVIGATION);
            _this.configLoader.configurations.get(TCO.CONF.CONF_FOCUS_VIEW).LOCATION_SELECTION_FOCUS_VIEW.fabAction
                .forEach(function (val) {
                for (var key in navItemsArray) {
                    if (val === navItemsArray[key].id) {
                        fabActions.push(navItemsArray[key]);
                    }
                }
            });
            var dataObject = new ModalData(LEVEL.SUCCESS, 'Add Locations', null, null, 'grid-focus-view', LocationSelectionFocusViewComponent, {
                rightSideData: locations,
                locationGroup: locationGroup
            }, { label: 'close' }, '', '', fabActions, '', secondaryHeader);
            _this.focusViewCloseSubject = _this.dataStoreService.get(DataKey.locationSelectionFocusViewClose)
                .subscribe(function (data) {
                if (data != null) {
                    _this.focusViewCloseSubject.unsubscribe();
                    _this.closeFocusView();
                }
            });
            _this.focusViewService.confirm(dataObject).subscribe(function (res) {
                if (res) {
                    var e = document.getElementsByClassName('header-content__secondary-heading');
                    console.log(e);
                }
            });
        }, 0);
    };
    LocationGroupsSetupComponent.prototype.getLocationsByLocationGroup = function (locationGroup) {
        var _this = this;
        var locationsSearchCriteria = new LocationsSearchCriteria();
        locationsSearchCriteria.locationGroupCode = locationGroup.code;
        locationsSearchCriteria.size = 100000;
        this.locationsService.getLocations(locationsSearchCriteria)
            .subscribe(function (data) {
            if (data) {
                var locations = ResponseUtil.getDataArray(data);
                _this.openLocationSelectionModal(locationGroup, locations);
            }
        });
    };
    LocationGroupsSetupComponent.prototype.closeFocusView = function () {
        if (this.focusViewService.close()) {
            this.focusCloseSubscription = this.focusViewService.close().subscribe(function (res) {
            });
        }
    };
    return LocationGroupsSetupComponent;
}(SetupGridComp));
export { LocationGroupsSetupComponent };
