<div class="detail-group__body-service-item container-fluid">

  <div class="detail-group__heading container-fluid">
    <div class="item-header">
      <div class="item-header__content">{{'Booking Supplements' | translate}}</div>
    </div>
  </div>
  <div class="tc-px-3">
    <mat-accordion *ngFor="let supplementGroup of supplementGroupList;let i = index;">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title id="booking-item-ref-{{i}}">
            {{supplementGroup.bookingReferenceIdWithoutReturnFlag == null ? supplementGroup.bookingReferenceId : supplementGroup.bookingReferenceIdWithoutReturnFlag}}
          </mat-panel-title>
          <mat-panel-description>
             <div id="booking-item-supplement-count-{{i}}">{{supplementGroup.supplementNotes.length + ' Supplements Available' | translate}}</div>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <tc-booking-supplement-group
                [supplementGroup]="supplementGroup"
                [columnDefs]="bookingSupplementsColumnDefs">
        </tc-booking-supplement-group>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>

<div class="container assign-panel-footer-section">
  <button class="assign-panel-footer-section__button tc-button-secondary mat-button" (click)="onClose($event)">
    {{'Close' | translate}}
  </button>
</div>
