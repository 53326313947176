import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import {
  NotificationToastConfig,
  NotificationToastEvent,
  NotificationToastEventType,
  NotificationToastModel
} from './notification-toast-model';

@Injectable({
  providedIn: 'root'
})
export class NotificationToastService {

  private eventSource = new Subject<NotificationToastEvent>();
  public events: Observable<NotificationToastEvent> = this.eventSource.asObservable();

  constructor(
    private config: NotificationToastConfig
  ) { }

  toastId = 0;

  add(notification: NotificationToastModel) {
    const notificationToastEvent = new NotificationToastEvent();
    if (!notification.id) {
      notification.id = ++this.toastId;
    }
    notificationToastEvent.toast = notification;
    if (!notification.timeout) {
      notification.timeout = this.config.timeout;
    }
    notificationToastEvent.type = NotificationToastEventType.ADD;
    this.pushEvent(notificationToastEvent);
  }

  remove(id: number) {
    const notificationToastEvent = new NotificationToastEvent();
    notificationToastEvent.toastId = id;
    notificationToastEvent.type = NotificationToastEventType.REMOVE;
    this.pushEvent(notificationToastEvent);
  }

  clearAll() {
    const notificationToastEvent = new NotificationToastEvent();
    notificationToastEvent.type = NotificationToastEventType.REMOVE_ALL;
    this.pushEvent(notificationToastEvent);
  }

  private pushEvent(event: NotificationToastEvent) {
    if (this.eventSource) {
      this.eventSource.next(event);
    }
  }
}
