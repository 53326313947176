import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { AllocationTimeRange, ResourceAllocationSummary } from '../../../../../models/reservation-v2/resource-allocation-summary';
import { DataKey, DataStoreService } from '../../../../../services/util/framework/data-store.service';

@Component({
    selector: 'tc-edit-time-range',
    templateUrl: './edit-time-range.component.html'
})
export class EditTimeRangeComponent implements OnInit {

    @Input() selectedResource: ResourceAllocationSummary;
    @Input() jobStartTime: any;
    @Input() jobEndTime: any;
    @Input() bookingIds: any[] =[];

    timeRangeForm: FormGroup;
    changeFormValidity: boolean;

    constructor(
        private dataStoreService: DataStoreService,
        private fb: FormBuilder
    ) {
    }

    ngOnInit() {
        this.createForm();
        let serviceDate = moment(this.jobStartTime).format('YYYY-MM-DD');
        this.timeRangeForm.valueChanges.subscribe(
            (data) => {
                if (JSON.stringify(data) !== JSON.stringify({})) {
                    this.jobStartTime = new Date(serviceDate + ' ' + data.startTime);
                    this.jobEndTime = new Date(serviceDate + ' ' + data.endTime);
                }
            }
        );
    }

    private createForm() {
        this.timeRangeForm = this.fb.group({
            startTime: [this.jobStartTime ? moment(this.jobStartTime).format('HH:mm') : ''],
            endTime: [this.jobEndTime ? moment(this.jobEndTime).format('HH:mm') : '']
        });
    }

    public onClose() {
        this.dataStoreService.set(DataKey.popupClose, false);
    }

    public onOkClick() {
        this.dataStoreService.set(DataKey.popupClose, true);
        const timeRange = new AllocationTimeRange();
        timeRange.startTime = this.jobStartTime;
        timeRange.endTime = this.jobEndTime;
        timeRange.bookingIds = this.bookingIds;
        this.dataStoreService.set(DataKey.ResourceAllocationTimeRange, timeRange, true);
    }

    public isOkDisabled() {
        return !(this.jobStartTime && this.jobEndTime && this.jobStartTime < this.jobEndTime);
    }
}
