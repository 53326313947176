import { NgModule } from '@angular/core';

import { FormsModule } from '@angular/forms';
import { MatButtonModule, MatDividerModule } from '@angular/material';
import { MatIconModule } from '@angular/material/icon';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule } from '@ngx-translate/core';
import { SetupGridModule } from '../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid.module';
import { UiTestComponent } from './ui-test.component';

@NgModule({
    declarations: [UiTestComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        SetupGridModule,
        FormsModule,
        MatIconModule,
        MatButtonModule,
        MatDividerModule,
        SetupGridModule,
        TranslateModule.forChild()
    ],
    exports: [
        UiTestComponent
    ],
    entryComponents: [
        UiTestComponent
    ]
})
export class UiTestModule {}
