/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./max-length-validator.component";
var styles_MaxLengthValidatorComponent = [];
var RenderType_MaxLengthValidatorComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_MaxLengthValidatorComponent, data: {} });
export { RenderType_MaxLengthValidatorComponent as RenderType_MaxLengthValidatorComponent };
export function View_MaxLengthValidatorComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { input: 0 }), (_l()(), i0.ɵeld(1, 16777216, [[1, 3], ["input", 1]], null, 0, "input", [["style", "width: 100%"], ["type", "text"]], [[4, "color", null], [8, "value", 0]], [[null, "keydown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("keydown" === en)) {
        var pd_0 = (_co.getInputValues() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.fontColor; var currVal_1 = _co.value; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
export function View_MaxLengthValidatorComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-max-length-validator", [], null, null, null, View_MaxLengthValidatorComponent_0, RenderType_MaxLengthValidatorComponent)), i0.ɵdid(1, 4308992, null, 0, i1.MaxLengthValidatorComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MaxLengthValidatorComponentNgFactory = i0.ɵccf("tc-max-length-validator", i1.MaxLengthValidatorComponent, View_MaxLengthValidatorComponent_Host_0, {}, {}, []);
export { MaxLengthValidatorComponentNgFactory as MaxLengthValidatorComponentNgFactory };
