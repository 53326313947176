<mat-horizontal-stepper [linear]="true" #stepper>
    <mat-step label="Select Resources" [completed]="isResourceSelectionStepComplete">
        <tc-leave-setup-search-criteria
                [leaveSetupSearchCriteria]="leaveSearchCriteria"
                [isInsideForm]="true"
        ></tc-leave-setup-search-criteria>
        <tc-leave-setup-search-results
                [selectedResourceIds]="selectedResourceIds"
                [selectedCardData]="selectedResourceCards"
                (selectResource)="continueToSetLeave($event)">
        </tc-leave-setup-search-results>
    </mat-step>
    <mat-step label="Set Leave" [completed]="isSetLeaveStepComplete">
        <tc-leave-setup-edit-card
                [leaveTypes]="leaveTypes"
                [leaveSetupData]="leaveSetupData"
                (changeFormValidity)="continueToConfirm($event)">
        </tc-leave-setup-edit-card>
    </mat-step>
    <mat-step label="Done" [completed]="isConfirmStepComplete">
        <div class="container-fluid">
            <tc-leave-setup-detail-view
                    [leaveSummary]="leaveSetupData"
                    [leaveConfig]=""
            ></tc-leave-setup-detail-view>
            <tc-leave-setup-resource-summary-view
                    [selectedResources]="selectedResourceCards"></tc-leave-setup-resource-summary-view>
        </div>
    </mat-step>
</mat-horizontal-stepper>