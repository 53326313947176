import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AssignmentSummary } from '../../../models/assignment-summary';
import { AssignmentSummaryCriteria } from '../../../models/criteria/assignment-summary-criteria';
import { LeadPassengerCriteria } from '../../../models/criteria/lead-passenger-criteria';
import { ReservationSearchCriteria } from '../../../models/criteria/reservation-search-criteria';
import { ServiceItemOperation, ServiceItemOperationCriteria } from '../../../models/criteria/service-item-operation-criteria';
import { SplitByPassengerGroupCriteria } from '../../../models/criteria/split-by-passenger-group-criteria';
import { VehicleSearchCriteria } from '../../../models/criteria/vehicle-search-criteria';
import { AllocationCount } from '../../../models/reservation/allocation-count';
import { Assignment } from '../../../models/reservation/assignment';
import { ItemGroupNoteMap } from '../../../models/reservation/item-group-note-map';
import { NoteWrapper } from '../../../models/reservation/note-wrapper';
import { PassengerGroup } from '../../../models/reservation/passenger-group';
import { SearchResultSummary } from '../../../models/reservation/search-result-summary';
import { ServiceGroup } from '../../../models/reservation/service-group';
import { ServiceItem } from '../../../models/reservation/service-item';
import { ServiceNote } from '../../../models/reservation/service-note';
import { TrsItemGroupTimeRange } from '../../../models/reservation/trs-item-group-time-range';
import { OPResponseWrapper } from '../../../models/response/op-response-wrapper';
import { VehicleSummary } from '../../../models/summary/vehicle-summary';
import { DateTimeProcessor } from '../../business-utils/time-zone/date-time-processor';
import { DataKey } from '../../util/framework/data-store.service';
import { DMCQueryParamsService } from '../../util/framework/dmc-query-params.service';
import { RequestService } from '../../util/framework/request.service';
import { UrlPaths } from '../url-paths';
import {HttpClient} from '@angular/common/http';
import {ResourceAvailability} from '../../../models/resource/resource-availability';
import {ServiceAssignment} from '../../../models/reservation/service-assignment';

@Injectable()
export class TrsReservationManagementService {

    constructor(
        private queryParamsService: DMCQueryParamsService,
        private requestService: RequestService,
        protected http: HttpClient,
    ) {
    }

    public retrieveServiceItems(criteria: ReservationSearchCriteria): Observable<OPResponseWrapper<ServiceItem>> {
        const params = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.get<OPResponseWrapper<ServiceItem>>(
            UrlPaths.OP_RES_URL_KEY,
            [UrlPaths.OP_RES_VERSION, UrlPaths.SERVICE_ITEMS],
            params
        );
    }

    public searchTrsItemGroups(criteria: ReservationSearchCriteria): Observable<OPResponseWrapper<SearchResultSummary>> {
        const params = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.getWithDataKey(
            DataKey.reservationSearchResultsFromService,
            UrlPaths.OP_RES_URL_KEY,
            [UrlPaths.OP_RES_VERSION, UrlPaths.TRS_ITEM_GROUPS],
            params
        );
    }

    public searchTrsItemGroupsOnMore(criteria: ReservationSearchCriteria): Observable<OPResponseWrapper<SearchResultSummary>> {
        const params = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.getWithDataKey(
            DataKey.reservationSearchResultsFromServiceOnMore,
            UrlPaths.OP_RES_URL_KEY,
            [UrlPaths.OP_RES_VERSION, UrlPaths.TRS_ITEM_GROUPS],
            params
        );
    }

    public getGroupByOptions(): Observable<OPResponseWrapper<TrsItemGroupTimeRange>> {
        return this.requestService.get<OPResponseWrapper<TrsItemGroupTimeRange>>(
            UrlPaths.OP_RES_URL_KEY,
            [UrlPaths.OP_RES_VERSION, UrlPaths.TRS_ITEM_GROUP_TIME_RANGES]
        );
    }

    public splitServiceItem(id: number, passengerGroupCriteria: SplitByPassengerGroupCriteria)
        : Observable<OPResponseWrapper<PassengerGroup>> {
        const params = this.queryParamsService.asQueryParams(passengerGroupCriteria);
        return this.requestService.get<OPResponseWrapper<PassengerGroup>>(
            UrlPaths.OP_RES_URL_KEY,
            [UrlPaths.OP_RES_VERSION, UrlPaths.SERVICE_ITEMS, id.toString(), UrlPaths.PASSENGER_GROUPS],
            params
        );
    }

    public saveSplitServiceItems(id: number, passengerGroups: any): Observable<OPResponseWrapper<ServiceItem>> {
        return this.requestService.put<OPResponseWrapper<ServiceItem>>(
            UrlPaths.OP_RES_URL_KEY,
            [UrlPaths.OP_RES_VERSION, UrlPaths.SERVICE_ITEMS, id.toString(), UrlPaths.PASSENGER_GROUPS],
            passengerGroups
        );
    }

    public saveAssignments(assignments: ServiceAssignment[]): Observable<OPResponseWrapper<Assignment>> {
        return this.requestService.post<OPResponseWrapper<Assignment>>(
            UrlPaths.OP_RES_URL_KEY,
            [UrlPaths.OP_RES_VERSION, UrlPaths.ASSIGNMENT],
            assignments
        );
    }

    public retrieveReservationGroupNotes(criteria: ReservationSearchCriteria): Observable<OPResponseWrapper<ServiceGroup>> {
        const params = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.get<OPResponseWrapper<any>>(
            UrlPaths.OP_RES_URL_KEY,
            [UrlPaths.OP_RES_VERSION, UrlPaths.GROUP_NOTES],
            params
        );
    }

    public saveGroupNote(noteWrapper: NoteWrapper): Observable<OPResponseWrapper<ServiceGroup>> {
        return this.requestService.post<OPResponseWrapper<ServiceGroup>>(
            UrlPaths.OP_RES_URL_KEY,
            [UrlPaths.OP_RES_VERSION, UrlPaths.GROUP_NOTES],
            noteWrapper
        );
    }

    public editGroupNote(serviceNoteId: number, serviceNote: ServiceNote): Observable<OPResponseWrapper<ServiceNote>> {
        return this.requestService.put<OPResponseWrapper<ServiceNote>>(
            UrlPaths.OP_RES_URL_KEY,
            [UrlPaths.OP_RES_VERSION, UrlPaths.GROUP_NOTES, serviceNoteId.toString()],
            serviceNote
        );
    }

    public deleteGroupNote(serviceNoteId: number): Observable<OPResponseWrapper<any>> {
        return this.requestService.delete<OPResponseWrapper<any>>(
            UrlPaths.OP_RES_URL_KEY,
            [UrlPaths.OP_RES_VERSION, UrlPaths.GROUP_NOTES, serviceNoteId.toString()]
        );
    }

    public saveServiceItemNote(serviceItemId: number, serviceItemNote: ItemGroupNoteMap): Observable<OPResponseWrapper<ServiceNote>> {
        return this.requestService.post<OPResponseWrapper<ServiceNote>>(
            UrlPaths.OP_RES_URL_KEY,
            [UrlPaths.OP_RES_VERSION, UrlPaths.SERVICE_ITEMS, serviceItemId.toString(), UrlPaths.SERVICE_NOTES],
            serviceItemNote
        );
    }

    public editServiceNote(
        serviceItemId: number, serviceNoteId: number, serviceNote: ServiceNote): Observable<OPResponseWrapper<ServiceNote>> {
        return this.requestService.put<OPResponseWrapper<ServiceNote>>(
            UrlPaths.OP_RES_URL_KEY,
            [
                UrlPaths.OP_RES_VERSION,
                UrlPaths.SERVICE_ITEMS,
                serviceItemId.toString(),
                UrlPaths.SERVICE_NOTES,
                serviceNoteId.toString()
            ],
            serviceNote
        );
    }

    public deleteServiceNote(serviceItemId: number, serviceNoteId: number): Observable<OPResponseWrapper<any>> {
        return this.requestService.delete<OPResponseWrapper<any>>(
            UrlPaths.OP_RES_URL_KEY,
            [
                UrlPaths.OP_RES_VERSION,
                UrlPaths.SERVICE_ITEMS,
                serviceItemId.toString(),
                UrlPaths.SERVICE_NOTES,
                serviceNoteId.toString()
            ]
        );
    }

    public mergeServiceItems(serviceItemIds: number[]): Observable<OPResponseWrapper<ServiceItem>> {
        const criteria: ServiceItemOperationCriteria = new ServiceItemOperationCriteria();
        criteria.operation = ServiceItemOperation.MERGE;
        const params = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.patch<OPResponseWrapper<ServiceItem>>(
            UrlPaths.OP_RES_URL_KEY,
            [UrlPaths.OP_RES_VERSION, UrlPaths.SERVICE_ITEMS],
            serviceItemIds,
            params
        );
    }

    public setLeadPassenger(serviceItemId: number, passengerId: number): Observable<OPResponseWrapper<any>> {
        const criteria: LeadPassengerCriteria = new LeadPassengerCriteria();
        criteria.passengerId = passengerId;
        const params = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.put<OPResponseWrapper<any>>(
            UrlPaths.OP_RES_URL_KEY,
            [UrlPaths.OP_RES_VERSION, UrlPaths.SERVICE_ITEMS, serviceItemId.toString(), UrlPaths.LEAD_PASSENGER],
            null,
            params
        );
    }

    public getAllocatedCountForResources(criteria: ReservationSearchCriteria): Observable<OPResponseWrapper<AllocationCount>> {
        const params = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.get<OPResponseWrapper<AllocationCount>>(
            UrlPaths.OP_RES_URL_KEY,
            [UrlPaths.OP_RES_VERSION, UrlPaths.ALLOCATION_COUNT],
            params
        );
    }



    public exportServiceItems(criteria: ReservationSearchCriteria): Observable<OPResponseWrapper<string>> {
        const params = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.get<OPResponseWrapper<string>>(
            UrlPaths.OP_RES_URL_KEY,
            [UrlPaths.OP_RES_VERSION, UrlPaths.SERVICE_ITEM_DOCUMENT],
            params
        );
    }

    public searchAssignments(resourceIdList: number[], key: DataKey): Observable<OPResponseWrapper<AssignmentSummary>> {
        const criteria = new AssignmentSummaryCriteria();
        criteria.resourceIdList = resourceIdList;
        const params = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.getWithDataKey<OPResponseWrapper<AssignmentSummary>>(
            key,
            UrlPaths.OP_RES_URL_KEY,
            [UrlPaths.OP_RES_VERSION, UrlPaths.ASSIGNMENT_SUMMARIES],
            params
        );
    }

    searchRecommendedVehiclesWithDataKey(vehicleSearchCriteria: VehicleSearchCriteria): Observable<OPResponseWrapper<VehicleSummary>> {
        DateTimeProcessor.processVehicleSearchCriteriaDateTimes(vehicleSearchCriteria);
        const params = this.queryParamsService.asQueryParams(vehicleSearchCriteria);
        return this.requestService.getWithDataKey<OPResponseWrapper<VehicleSummary>>(
            DataKey.resourceAssignVehicleSearchResults,
            UrlPaths.OP_RES_URL_KEY,
            [UrlPaths.OP_RES_VERSION, UrlPaths.RECOMMENDED_VEHICLES],
            params
        );
    }
    searchRecommendedVehiclesFromMock(vehicleSearchCriteria: VehicleSearchCriteria): Observable<OPResponseWrapper<ResourceAvailability>> {
        return this.http.get<OPResponseWrapper<ResourceAvailability>>('assets\\mockdata\\vehicleAvailabilitySummary.json');
    }

    // resource assign will use this from reservation service
    searchRecommendedVehicles(vehicleSearchCriteria: VehicleSearchCriteria): Observable<OPResponseWrapper<VehicleSummary>> {
        DateTimeProcessor.processVehicleSearchCriteriaDateTimes(vehicleSearchCriteria);
        const params = this.queryParamsService.asQueryParams(vehicleSearchCriteria);
        return this.requestService.get<OPResponseWrapper<VehicleSummary>>(
            UrlPaths.OP_RES_URL_KEY,
            [UrlPaths.OP_RES_VERSION, UrlPaths.RECOMMENDED_VEHICLES],
            params
        );
    }

    searchRecommendedAssignments(SearchCriteria: ReservationSearchCriteria): Observable<OPResponseWrapper<VehicleSummary>> {
        const params = this.queryParamsService.asQueryParams(SearchCriteria);
        return this.requestService.get<OPResponseWrapper<VehicleSummary>>(
            UrlPaths.OP_RES_URL_KEY,
            [UrlPaths.OP_RES_VERSION, UrlPaths.RECOMMENDED_ASSIGNMENTS],
            params
        );
    }
}
