import { enableProdMode } from '@angular/core';
import { BootController } from './app/boot-controller';
import { environment } from './environments/environment';
import * as __NgCli_bootstrap_1 from "./app/app.module.ngfactory";
import * as __NgCli_bootstrap_2 from "@angular/platform-browser";
if (environment.production) {
    enableProdMode();
}
var init = function () {
    __NgCli_bootstrap_2.platformBrowser().bootstrapModuleFactory(__NgCli_bootstrap_1.AppModuleNgFactory);
};
var ɵ0 = init;
init();
var ɵ1 = function () { return init(); };
// Init on reboot request
var boot = BootController.getbootControl().watchReboot().subscribe(ɵ1);
export { ɵ0, ɵ1 };
