<div class="adv-client-search" *ngIf="summaryList">
  <ng-container *ngFor="let summary of summaryList |tcAdvPager:pager.currPage:pager.itemsPerPage | tcAdvSorter:sortingHandler:sorter; let i = index;">
    <div class="client-search-result-item-wrapper">
      <div
              tcExpansionPanel
              #clientExp
              tabindex="-1"
              [enabled]="true"
              (expand)="onPanelExpand(i)"
              (collapse)="expandedPanelIndex = -1">

        <!-- Content card Strip -->
        <div *tcExpansionPanelSummary id="clientSumExp_{{i}}">
          <tc-content-strip
                  (actionClick)="onActionClick(summary,$event)"
                  (click)="onSelection(summary,i)">
            <tc-result-content-card-generic
                    [summaryContent]="summary">
            </tc-result-content-card-generic>
          </tc-content-strip>
        </div>

        <div *tcExpansionPanelDetail id="clientDetailExp_{{i}}">
          <tc-expanded-content-card class="tc-expanded-content-card tc-form-view" [isEdit]="false">

            <tc-op-expanded-content-card-header
                    *ngIf="selectedSummary"
                    [headerText]="getHeader()"
                    [subHeaderText]="getSubHeader()"
                    [showEdit]="showEditBtn"
                    [showEditAsNew]="showEditAsNew"
                    [showCalendar]="false"
                    (editClick)="onEditClick($event)"
                    (editAsNewClick)="onEditAsNewClick($event)"
                    (headerClick)="onHeaderClick($event)">
            </tc-op-expanded-content-card-header>

            <tc-booking-detail-view
                    *ngIf="selectedSummary && detailedObject"
                    [booking]="detailedObject"
                    [summaryCardData]="selectedSummary">
            </tc-booking-detail-view>

          </tc-expanded-content-card>
        </div>
      </div>
    </div>
  </ng-container>
</div>

