import * as tslib_1 from "tslib";
import { AbstractSearchCriteria } from '../../criteria/abstract-search-criteria';
var ServiceItemSearchCriteria = /** @class */ (function (_super) {
    tslib_1.__extends(ServiceItemSearchCriteria, _super);
    function ServiceItemSearchCriteria() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return ServiceItemSearchCriteria;
}(AbstractSearchCriteria));
export { ServiceItemSearchCriteria };
