import { KeyControl } from '../common/key-control';
import { PaymentInfo } from '../common/payment-info';
import { Remark } from '../common/remark';
import { SupplierAttributes } from '../common/supplier-attributes';
import { ContactDetails } from '../profile/contact-details';
import { DocumentData } from '../profile/document';
import { Room } from '../supplier/room';
import { TaxScheme } from '../supplier/tax-scheme';
import { UserProfile } from '../user/user-profile';
import { GenericResource } from './generic-resource';
import { Vehicle } from './vehicle';
import {UserBusinessType} from '../user/user-business-type';

export class Resource extends KeyControl{
    resourceId: number;
    capacity: number;
    description: string;
    maxPax: number;
    minPax: number;
    resourceType: string;
    activeStatus: boolean;
    supplierType: string;
    businessType: UserBusinessType;
    contactDetails: ContactDetails[];
    documents: DocumentData[];
    remarks: Remark[];
    supplierId: number;
    supplierName: string;
    supplierCode: string;
    supKcCompany: string;
    supKcDivision: string;
    supKcBrand: string;
    supKcDistributionChannel: string;
    rooms: Room[];
    taxSchemes: TaxScheme[];
    genericResource: GenericResource;
    paymentInfo: PaymentInfo;
    supplierAttributes: SupplierAttributes[];
    vehicle: Vehicle;
    hasResourceCost: boolean;
    userProfile: UserProfile;
    preference: number;
    grade: number;
    kcCompanies: [];
    kcBrands: [];
    kcDistributionChannels: [];
    kcDivisions: [];
    isEditTriggered = false;
}

export enum ResourceBusinessType {
    INDEPENDENT = 'Independent',
    INTERNAL = 'Internal',
    EXTERNAL = 'External'
}
