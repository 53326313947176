import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ConfigLoader } from '@tc-core/util/framework';
import { TCO } from '../../../constants';
import { SortDirection } from '../../../models/helper/sort-direction';
import { MasterDataRestService } from '../../../services/backend-consumers/master-data-handler-service/master-data-rest.service';
import { CompanySetupService } from '../../../services/backend-consumers/setups/company-setup-service';
import { UserManagementService } from '../../../services/user-management/user-management.service';
import { DMCCommon } from '../../../services/util/common/dmc-common';
import { DataKey, DataStoreService } from '../../../services/util/framework/data-store.service';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SetupGridComp } from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid-comp';
import { SetupGridComponent } from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid.component';
var CompanySetupComponent = /** @class */ (function (_super) {
    tslib_1.__extends(CompanySetupComponent, _super);
    function CompanySetupComponent(configLoader, dataStoreService, masterDataRestService, common, dataStore, companySetupService, userManagementService) {
        var _this = _super.call(this) || this;
        _this.configLoader = configLoader;
        _this.dataStoreService = dataStoreService;
        _this.masterDataRestService = masterDataRestService;
        _this.common = common;
        _this.dataStore = dataStore;
        _this.companySetupService = companySetupService;
        _this.userManagementService = userManagementService;
        _this.colDefConfig = [];
        _this.hasACompany = false;
        _this.searchCriteriaObserver = new Subscription();
        _this.isEditableRow = function (params) {
            return this.isInvalidRow();
        };
        return _this;
    }
    CompanySetupComponent.prototype.ngOnInit = function () {
        this.companyAvailability();
        this.colDefConfig = this.configLoader.configurations.get(TCO.CONF.CONF_COMPANY_SETUP);
        this.subscribeSearchCriteria();
    };
    /**
     * subscribe search criteria changes in search bar
     * then force grid to refresh data
     * grid refresh will be call getRaws method which is using this subscribed criteria for backend call
     */
    CompanySetupComponent.prototype.subscribeSearchCriteria = function () {
        var _this = this;
        this.searchCriteriaObserver = this.dataStoreService.get(DataKey.companySetupSearchCriteria).subscribe(function (value) {
            _this.companySearchCriteria = value;
            _this.setupGrid.runForceSearch();
        });
    };
    CompanySetupComponent.prototype.createNewRow = function () {
        return { unsaved: true };
    };
    CompanySetupComponent.prototype.deleteRow = function (row) {
        return undefined;
    };
    CompanySetupComponent.prototype.getRows = function (params) {
        var _this = this;
        var pageSize = 14;
        if (params && params.endRow && params.startRow) {
            pageSize = params.endRow - params.startRow;
        }
        this.companySearchCriteria = this.userManagementService.updateSearchCriteriaWithUserKC(this.companySearchCriteria);
        if (params && params.startRow) {
            this.companySearchCriteria.start = params.startRow;
        }
        if (pageSize) {
            this.companySearchCriteria.size = pageSize;
        }
        if (params.sortModel && params.sortModel[0]) {
            this.companySearchCriteria.sortBy = params.sortModel[0].colId;
            if (params.sortModel[0].sort === SetupGridComp.GRID_SORT_ASCENDING) {
                this.companySearchCriteria.sortDirection = SortDirection.ASC;
            }
            else {
                this.companySearchCriteria.sortDirection = SortDirection.DESC;
            }
        }
        var userProfile = this.dataStore.get(DataKey.userProfile).getValue();
        this.companySearchCriteria.code = userProfile.kcCompany;
        this.companySearchCriteria.name = userProfile.kcCompanyName;
        return this.companySetupService.getRows(this.companySearchCriteria)
            .pipe(tap(function (data) {
            _this.dataStoreService.set(DataKey.companySearchResultsForCriteria, data);
        }));
    };
    CompanySetupComponent.prototype.companyAvailability = function () {
        this.hasACompany = this.userProfile !== null && this.userProfile !== undefined &&
            this.userProfile.kcCompany !== null && this.userProfile.kcCompany !== undefined;
    };
    CompanySetupComponent.prototype.isInvalidRow = function (params) {
        if (params && params.data) {
            var data = params.data;
            if (data) {
                var invalid = true;
                if (data.code &&
                    data.name) {
                    invalid = false;
                }
                return invalid;
            }
            else {
                return false;
            }
        }
        return false;
    };
    CompanySetupComponent.prototype.isUnsavedRow = function (row) {
        return false;
    };
    CompanySetupComponent.prototype.saveRow = function (row, event) {
        return undefined;
    };
    return CompanySetupComponent;
}(SetupGridComp));
export { CompanySetupComponent };
