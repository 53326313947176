import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { TcErrorType } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-error';
import { LEVEL } from '@tc-core/model/it/codegen/ui/framework/dialog-model';
import { ModalData } from '@tc-core/model/it/codegen/ui/framework/modal-data';
import { TC } from '@tc-core/util';
import { ConfigLoader } from '@tc-core/util/framework';
import { ModalService } from '@tc/modal/modal.service';
import { IGetRowsParams } from 'ag-grid-community';
import { Observable, of, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SetupGridComp } from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid-comp';
import { SetupGridDataSource } from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid-data-source';
import { SetupGridComponent } from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid.component';
import { ActionRendererAction } from '../../../widgets/framework/ag-grid-custom-components/renderers/actions/actions.component';
import { TCO } from '../../../constants';
import { FileUploaderComponent } from '../../../widgets/framework/file-uploader/file-uploader.component';
import { ProductDetailsSearchCriteria } from '../../../models/criteria/product-details-search-criteria';
import { SortDirection } from '../../../models/helper/sort-direction';
import { MasterDataRestService } from '../../../services/backend-consumers/master-data-handler-service/master-data-rest.service';
import { ProductDetailsService } from '../../../services/backend-consumers/setups/product-details-service';
import { DMCCommon } from '../../../services/util/common/dmc-common';
import { DataKey, DataStoreService } from '../../../services/util/framework/data-store.service';
import EMPTY_CHAR = TC.FORMAT.EMPTY_CHAR;

@Component({
    selector: 'tc-product-details-grid-setup',
    templateUrl: './product-details-grid-setup.component.html'
})
export class ProductDetailsGridSetupComponent extends SetupGridComp implements OnInit, SetupGridDataSource {

    @Input() selectedResourceTypes: any[] = [];

    @ViewChild('setupGrid')
    private setupGrid: SetupGridComponent;
    public colDefConfig = [];
    gridActions: ActionRendererAction[] = [];

    // search criteria obj. used to
    private productDetailsSearchCriteria: ProductDetailsSearchCriteria = new ProductDetailsSearchCriteria();

    private searchCriteriaObserver: Subscription = new Subscription();
    private serviceTypeList = [];
    private imageUrlImportSubscribe: Subscription = new Subscription();
    private gridApi;
    private genImageUploadSubscribe: Subscription = new Subscription();

    constructor(
        private configLoader: ConfigLoader,
        private productDetailsService: ProductDetailsService,
        private dataStoreService: DataStoreService,
        private modalService: ModalService,
        private common: DMCCommon,
        private masterDataRestService: MasterDataRestService
    ) {super(); }

    ngOnInit() {
        this.colDefConfig = this.configLoader.configurations.get(TCO.CONF.CONF_PRODUCT_DETAILS_SETUP);
        this.addGridActions();
        console.log(this.setupGrid);
        this.subscribeSearchCriteria();
        this.getResourceTypeList();
    }

    private addGridActions() {
        this.gridActions.push(
            {
                icon: 'file_upload',
                action: this.onImageUploadClick.bind(this),
                disable: this.isDisableImageUploadClick.bind(this),
                tooltip: 'Add Image'
            }
        );
    }

    /**
     * subscribe search criteria changes in search bar
     * then force grid to refresh data
     * grid refresh will be call getRaws method which is using this subscribed criteria for backend call
     */
    subscribeSearchCriteria() {
        this.searchCriteriaObserver = this.dataStoreService.get(DataKey.productDetailSearchCriteria)
                                          .subscribe(value => {
                                              this.productDetailsSearchCriteria = value;
                                              this.setupGrid.runForceSearch();
                                          });
    }

    onImageUploadClick = (params) => {
        this.openImageUploadDialog(params);
    };

    private openImageUploadDialog(params: any) {
        this.dataStoreService.set(DataKey.popupClose, null);
        this.dataStoreService.set(DataKey.fileToUpload, null);
        const dataObject = new ModalData(
            LEVEL.SUCCESS,
            'Upload Image',
            true,
            true,
            'split-passengers-modal',
            FileUploaderComponent,
            {}
        );
        dataObject.disableClose = true;
        this.confirmModal(dataObject);

        // tslint:disable-next-line:no-shadowed-variable
        const x = this.dataStoreService.get(DataKey.popupClose).subscribe((data) => {
            if (data != null) {
                this.closeModal();
            }
        });
        // tslint:disable-next-line:no-shadowed-variable
        this.imageUrlImportSubscribe = this.dataStoreService.get(DataKey.fileToUpload).subscribe((data) => {
            if (data != null) {
                this.uploadFile(data, params);
                this.imageUrlImportSubscribe.unsubscribe();
            }
        });
    }

    isDisableImageUploadClick = (params) => {
        return this.checkRowInvalid(params.data);
    };

    uploadFile(fileToUpload: any, params: any) {
        this.genImageUploadSubscribe = this.masterDataRestService.uploadImage(
            fileToUpload
        ).subscribe(res => {
            console.log(res);
            if (res && res.data && res.data[0]) {
                params.data.imageUrl = res.data[0].contentUrl;
                this.saveRow(params.data, null).subscribe(data => {
                    console.log(data);
                    this.setupGrid.runForceSearch();
                });
                this.common.showSnackBar(
                    'Successfully uploaded',
                    3000,
                    TcErrorType.TYPE.INFO
                );
            }
        }, error => {
            if (error.error.status.description.substring(0, 4) === 'File') {
                this.common.showSnackBar(
                    'Fail to upload: ' + error.error.status.description,
                    6000,
                    TcErrorType.TYPE.ERROR
                );
            } else {
                this.common.showSnackBar(
                    'Fail to upload',
                    3000,
                    TcErrorType.TYPE.ERROR
                );
            }
        });
    }

    private confirmModal(data: any): void {
        this.modalService.confirm(data).subscribe((res) => {
        });
    }

    closeModal() {
        if (this.modalService.close()) {
            this.modalService.close().subscribe(
                (res) => {
                }
            );
        }
    }

    public isInvalidRow(params): boolean {
        const data = params.data;
        if (data) {
            let invalid = true;
            if (
                data.productCode &&
                data.serviceType &&
                data.imageUrl
            ) {
                invalid = false;
            }
            return invalid;
        } else {
            return false;
        }
    }

    checkRowInvalid(data: any): boolean {
        let event = {
            data: data
        };
        return this.isInvalidRow(event);
    }

    public isUnsavedRow(row: any): boolean {
        return !row.productCode && !row.serviceType;
    }

    public deleteRow(row: any) {
        if (row && row.productCode) {
            const productDetailsSearchCriteria = new ProductDetailsSearchCriteria();
            productDetailsSearchCriteria.productCode = row.productCode;
            productDetailsSearchCriteria.serviceType = row.serviceType;
            return this.productDetailsService.deleteProductDetails(productDetailsSearchCriteria);
        } else {
            return of('success');
        }
    }

    public saveRow(row: any, event: any) {
        return this.productDetailsService.saveProductDetails(row);
    }

    public getRows(params: IGetRowsParams): Observable<any> {
        console.log('fetch requesting for ' + params.startRow + ' to ' + params.endRow);
        console.log(params.sortModel);

        const pageSize = params.endRow - params.startRow;
        if (!this.productDetailsSearchCriteria) {
            this.productDetailsSearchCriteria = new ProductDetailsSearchCriteria();
        }
        this.productDetailsSearchCriteria.start = params.startRow;
        this.productDetailsSearchCriteria.size = pageSize;
        if (params.sortModel && params.sortModel[0]) {
            this.productDetailsSearchCriteria.sortBy = params.sortModel[0].colId;
            if (params.sortModel[0].sort === SetupGridComp.GRID_SORT_ASCENDING) {
                this.productDetailsSearchCriteria.sortDirection = SortDirection.ASC;
            } else {
                this.productDetailsSearchCriteria.sortDirection = SortDirection.DESC;
            }
        } else {
            // this.productDetailsSearchCriteria.sortBy = 'productCode';
            this.productDetailsSearchCriteria.sortDirection = SortDirection.ASC;
        }
        return this.productDetailsService.getProductDetails(this.productDetailsSearchCriteria)
                   .pipe(
                       tap(data =>
                           this.dataStoreService.set(DataKey.productDetailsSearchResultsForCriteria, data)
                       )
                   );
    }

    public createNewRow(): any {
        return {};
    }

    getServiceTypeText = (params) => {
        let groupText = EMPTY_CHAR;
        if (params && params.data && params.data.serviceType) {
            if (params.data.serviceType.code) {
                groupText = params.data.serviceType.code;
            } else if (params.data.serviceType && params.data.serviceType !== '-') {
                const type = this.serviceTypeList.find(value => value.code === params.data.serviceType);
                if (type) {
                    groupText = type.name;
                } else {
                    groupText = EMPTY_CHAR;
                }
            } else {
                groupText = EMPTY_CHAR;
            }
        }
        return groupText;
    };
    onServiceTypeSet = (params) => {
        if (params.newValue && params.data) {
            params.data.serviceType = params.newValue.code;
        }
    };

    getServiceTypes = (params, rowData, text) => {
        return this.dataStoreService.get(DataKey.resourceTypes)
                   .map(
                       aEvents =>
                           aEvents.filter(aEvent => {
                                   if (text) {
                                       if (aEvent.name) {
                                           return aEvent.name.toLowerCase().indexOf(text.toLocaleString()) > -1;
                                       }
                                   } else {
                                       return true;
                                   }
                               }
                           )
                   );
    };

    getResourceTypeList() {
        this.dataStoreService.get(DataKey.resourceTypes).subscribe(types => {
            if (types) {
                this.serviceTypeList = types;
            }
        });
    }

    public ngOnDestroy(): void {
        if (this.searchCriteriaObserver) {
            this.searchCriteriaObserver.unsubscribe();
        }
    }

    /*
     grid config methods which are called by grid setup (ag grid)
     and set by grid config
     */

}
