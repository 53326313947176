import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigLoader } from '@tc-core/util/framework/config-loader.service';
import { Observable } from 'rxjs';
import { TCO } from '../../../constants';
import {UrlPaths} from "../url-paths";
import {DMCQueryParamsService} from "../../util/framework/dmc-query-params.service";
import {CommonDataSearchCriteria} from "../../../models/criteria/common-data-search-criteria";
import {RequestService} from "../../util/framework/request.service";
import {CountrySearchCriteria} from "../../../models/criteria/country-search-criteria";
@Injectable()
export class MasterDataHandlerService {

    baseGraphqlURL = this.configLoader.configurations.get(TCO.CONF.CONF_ENDPOINT).graph_ql_url;

    constructor(
        private http: HttpClient,
        private queryParamsService: DMCQueryParamsService,
        private requestService: RequestService,
        private configLoader: ConfigLoader
    ) { }

    public retrieveCountries(criteria: CountrySearchCriteria): Observable<any> {
        const reqPrams = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.get(
            UrlPaths.OP_DATA_URL_KEY,
            [UrlPaths.OP_DATA_VERSION, UrlPaths.COUNTRY_DATA],
            reqPrams
        );
    }

    public retrieveStates(criteria: CommonDataSearchCriteria): Observable<any> {
        const reqPrams = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.get(
            UrlPaths.OP_DATA_URL_KEY,
            [UrlPaths.OP_DATA_VERSION, UrlPaths.COMMON_DATA],
            reqPrams
        );
    }

    public retrieveCities(criteria: CommonDataSearchCriteria): Observable<any> {
        const reqPrams = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.get(
            UrlPaths.OP_DATA_URL_KEY,
            [UrlPaths.OP_DATA_VERSION, UrlPaths.COMMON_DATA],
            reqPrams
        );
    }
}
