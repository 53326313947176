import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatCheckboxChange } from '@angular/material';
import { SummaryCardData } from '@tc-core/model/it/codegen/ui/framework/summary-card-data';
import { ResourceAllocationEvent } from '../../../models/helper/resource-allocation-event';
import { ResourceAllocationSearchEvent } from '../../../models/helper/resource-allocation-search-event';
import { ServiceItemChanges } from '../../../models/helper/service-item-changes';
import { ServiceAssignment } from '../../../models/reservation/service-assignment';
import { ReservationStatus } from '../../../models/reservation/service-item';
import { DriverAllocationSummary } from '../../../models/summary/driver-allocation-summary';
import { TrsSupplierAllocationSummary } from '../../../models/summary/trs-supplier-allocation-summary';
import { VehicleSummary } from '../../../models/summary/vehicle-summary';
import {ServiceAssignmentUtilService} from '../../../services/business-utils/service-item-utils/service-assignment-util.service';
import {Autowired} from "ag-grid-community";

@Component({
    selector: 'tc-reservation-service-items',
    templateUrl: './reservation-service-items.component.html'
})
export class ReservationServiceItemsComponent implements OnInit, OnChanges {

    @Input() summaryCardData: SummaryCardData;
    @Input() contentCardDataList: SummaryCardData [] = [];
    @Input() checkList: boolean[] = null;

    @Input() supplierList: any[] = [];
    @Input() resourceList: any[] = [];

    @Input() serviceItemChangesInSelectedGroup: Map<number, ServiceItemChanges> = new Map();
    @Input() filteredVehicleSuppliers: TrsSupplierAllocationSummary[];
    @Input() filteredDriverSuppliers: TrsSupplierAllocationSummary[];
    @Input() filteredVehicles: VehicleSummary[];
    @Input() filteredDrivers: DriverAllocationSummary[];

    @Input() changesAvailableForServiceItems: boolean;
    @Input() isRequestCompleted: boolean;

    @Output() clickConfirm: EventEmitter<any> = new EventEmitter();
    @Output() clickSupplierReject: EventEmitter<any> = new EventEmitter();
    @Output() clickMerge: EventEmitter<any> = new EventEmitter();
    @Output() saveAssignments: EventEmitter<any> = new EventEmitter();
    @Output() bulkVehicleAssignment: EventEmitter<any> = new EventEmitter();
    @Output() bulkDriverAssignment: EventEmitter<any> = new EventEmitter();

    @Output() moreClick: EventEmitter<any> = new EventEmitter();
    @Output() historyClick: EventEmitter<any> = new EventEmitter<any>();
    @Output() splitClick: EventEmitter<any> = new EventEmitter<any>();
    @Output() leadPassengerClick: EventEmitter<SummaryCardData> = new EventEmitter();

    @Output() vehicleSupplierAssign: EventEmitter<{ ResourceAllocationEvent }> = new EventEmitter();
    @Output() driverSupplierAssign: EventEmitter<{ ResourceAllocationEvent }> = new EventEmitter();
    @Output() vehicleAssign: EventEmitter<{ ResourceAllocationEvent }> = new EventEmitter();
    @Output() driverAssign: EventEmitter<ResourceAllocationEvent> = new EventEmitter();

    @Output() vehicleSupplierClear: EventEmitter<{ ResourceAllocationEvent }> = new EventEmitter();
    @Output() driverSupplierClear: EventEmitter<{ ResourceAllocationEvent }> = new EventEmitter();
    @Output() vehicleClear: EventEmitter<{ ResourceAllocationEvent }> = new EventEmitter();
    @Output() driverClear: EventEmitter<ResourceAllocationEvent> = new EventEmitter();

    @Output() vehicleSupplierTextChange: EventEmitter<ResourceAllocationSearchEvent> = new EventEmitter();
    @Output() driverSupplierTextChange: EventEmitter<ResourceAllocationSearchEvent> = new EventEmitter();
    @Output() vehicleTextChange: EventEmitter<ResourceAllocationSearchEvent> = new EventEmitter();
    @Output() driverTextChange: EventEmitter<ResourceAllocationSearchEvent> = new EventEmitter();
    @Output() vehicleJobNoChange: EventEmitter<ResourceAllocationSearchEvent> = new EventEmitter();
    @Output() driverJobNoChange: EventEmitter<ResourceAllocationSearchEvent> = new EventEmitter();
    @Output() clearVehicleAssignment: EventEmitter<ResourceAllocationSearchEvent> = new EventEmitter();
    @Output() clearDriverAssignment: EventEmitter<ResourceAllocationSearchEvent> = new EventEmitter();
    @Output() autoAssignResources: EventEmitter<any> = new EventEmitter();
    @Output() isSavedChanges: EventEmitter<boolean> = new EventEmitter();

    @Output() vehicleTextFocus: EventEmitter<ResourceAllocationSearchEvent> = new EventEmitter();

    @Output() viewMoreFocus: EventEmitter<any> = new EventEmitter();

    selectedAll: boolean = false;
    isSaved: boolean = true;
    isAutoAssignEnabled: boolean = false
    public expandedPanelIndex: number;
    constructor() { }

    ngOnInit() {
        // console.log(this.contentCardDataList);
        this.initCheckList();
    }

    onServiceItemSelection(serviceItemCard: any, i: number) {
        // console.log(serviceItemCard);
    }

    onActionClick(serviceItemCard: any, $event: any) {
        // console.log(serviceItemCard);
    }

    initCheckList() {
        this.checkList = [];
        this.contentCardDataList.forEach(
            card => {
                this.checkList.push(false);
            }
        );
    }

    checkAllChange($event: MatCheckboxChange) {
        this.serviceItemChangesInSelectedGroup.forEach(function(value, key) {
                if (!ReservationServiceItemsComponent.isRestricted(value)) {
                    value.checked = $event.checked;
                }
            }
        );
    }

    public onSelectVehicleSupplier($event) {
        this.vehicleSupplierAssign.emit($event);
    }
    public onSelectDriverSupplier($event) {
        this.driverSupplierAssign.emit($event);
    }

    public onSelectVehicle($event) {
        console.log($event);
        console.log(this.filteredVehicles);
        this.vehicleAssign.emit($event);
    }

    public onSelectDriver($event) {
        this.driverAssign.emit($event);
    }

    public clearVehicleSupplier($event) {
        this.vehicleSupplierClear.emit($event);
    }
    public clearDriverSupplier($event) {
        this.driverSupplierClear.emit($event);
    }

    public clearVehicle($event) {
        this.vehicleClear.emit($event);
    }

    public clearDriver($event) {
        this.driverClear.emit($event);
    }

    public onVehicleSupplierTextChange($event: ResourceAllocationSearchEvent) {
        this.vehicleSupplierTextChange.emit($event);
    }
    public onDriverSupplierTextChange($event: ResourceAllocationSearchEvent) {
        this.driverSupplierTextChange.emit($event);
    }

    public onAutoAssignResources(){
        this.isAutoAssignEnabled = true;
        this.autoAssignResources.emit(this.summaryCardData);
    }

    public onVehicleTextChange($event: ResourceAllocationSearchEvent) {
        this.vehicleTextChange.emit($event);
    }

    public onDriverTextChange($event: ResourceAllocationSearchEvent) {
        this.driverTextChange.emit($event);
    }

    public onVehicleFocus($event: ResourceAllocationSearchEvent) {
        this.vehicleTextFocus.emit($event);
    }

    onVehicleJobNoChange($event: ResourceAllocationSearchEvent) {
        this.vehicleJobNoChange.emit($event);
    }

    onDriverJobNoChange($event: ResourceAllocationSearchEvent) {
        this.driverJobNoChange.emit($event);
    }

    onClearVehicleAssignment($event: ResourceAllocationSearchEvent) {
        this.clearVehicleAssignment.emit($event);
    }

    onClearDriverAssignment($event: ResourceAllocationSearchEvent) {
        this.clearDriverAssignment.emit($event);
    }

    clickOutside() {
        console.log('outside click');
    }

    public isPrivate(serviceItemCard: any): boolean {
        if (serviceItemCard && serviceItemCard.data.value && serviceItemCard.data.value['private'] !== undefined) {
            return serviceItemCard.data.value['private'];
        } else {
            return false;
        }
    }

    public isSplit(serviceItemCard: any): boolean {
        if (serviceItemCard && serviceItemCard.data.value && serviceItemCard.data.value['split'] !== undefined &&
            serviceItemCard.data.value['split']) {
            return true;
        } else {
            return false;
        }
    }

    public onClickConfirm() {
        this.clickConfirm.emit();
    }
    public onClickSupplierReject() {
        this.clickSupplierReject.emit();
    }

    public onClickMerge() {
        this.clickMerge.emit();
    }

    public onSaveAssignments() {
        this.saveAssignments.emit();
        this.isSaved = true;
    }

    public onMoreClick(event, serviceItemCard: SummaryCardData) {
        this.moreClick.emit({event: event, item: serviceItemCard});
    }

    public onSplitClick(data: any) {
        this.splitClick.emit(data);
    }

    public onLeadPassengerSelect(serviceItemCard: SummaryCardData) {
        this.leadPassengerClick.emit(serviceItemCard);
    }

    public onItemCheckChanged($event: any) {
        console.log($event);
        if ($event === false) {
            this.selectedAll = false;
        }
    }

    public isDisabledMerge() {
        // if(this.isRequestCompleted){
            let disable = true;
            let firstParentId = 0;
            let parentsMatch = true;
            let checkedCount = 0;
            let hasAssignments = false;
            let statusOk = true;
            this.serviceItemChangesInSelectedGroup.forEach(value => {
                if (value.checked) {
                    checkedCount++;
                    if (checkedCount == 1) {
                        firstParentId = value.firstParentId;  // first item's parent assigned as parentId
                    } else if (firstParentId === 0 || value.firstParentId === 0 || firstParentId !== value.firstParentId) {
                        parentsMatch = false;
                    }
                    if (value.serviceAssignments){
                        let serviceAssignmentList = [];
                        this.serviceItemChangesInSelectedGroup.get(value.serviceItemId).serviceAssignments.forEach(value => serviceAssignmentList.push(value));
                        if(ServiceAssignmentUtilService.isAssignedAny(serviceAssignmentList)){
                            hasAssignments = true;
                        }
                    }
                    if (ReservationServiceItemsComponent.isRestricted(value)) {
                        statusOk = false;
                    }
                }
            });
            if (checkedCount > 1 && !hasAssignments && parentsMatch && statusOk) {
                disable = false;
            }
            return disable;
    }

    public static isRestricted(value: ServiceItemChanges) {
        let restrict = false;
        if (value.reservationStatus === ReservationStatus.UPDATED || value.reservationStatus ===
            ReservationStatus.CANCELLED) {
            return true;
        }
        if (value.serviceAssignments) {
            let serviceAssignmentList = [];
            value.serviceAssignments.forEach(value => serviceAssignmentList.push(value));
            for (const data of serviceAssignmentList) {
                if (ServiceAssignment.isRestrictedEdit(data)) {
                    restrict = true;
                    break;
                }
            }
        }
        return restrict;
    }

    public isDisabledAssign() {
        let disable = true;
        let checkedCount = 0;
        let statusOk = true;
        let privateItems = [];
        let sharedItems = [];
        this.serviceItemChangesInSelectedGroup.forEach(value => {
            if (value.checked ) {
                if(value.isPrivate && !privateItems.includes(value.firstParentId)){
                    privateItems.push(value.firstParentId);
                } else if(!sharedItems.includes(value.firstParentId)){
                    sharedItems.push(value.firstParentId);
                }
                checkedCount++;
                if (ReservationServiceItemsComponent.isRestricted(value)) {
                    statusOk = false;
                }
            }
        });
        if (checkedCount >= 1 && statusOk && !(privateItems.length>0 && sharedItems.length>0) && privateItems.length<2) {
            disable = false;
        }
        this.isAutoAssignEnabled = disable;
        return disable;
    }

    public isDisabledAutoAssign() {
        let disable = false;
        this.serviceItemChangesInSelectedGroup.forEach(value => {
                if (ReservationServiceItemsComponent.isRestricted(value)) {
                    disable = true;
                }
        });
        return disable;
    }

    public isDisabledConfirm() {
        let disable = true;
        let checkedCount = 0;
        let statusOk = true;
        let invalidStatus = false;
        this.serviceItemChangesInSelectedGroup.forEach(value => {
            if (value.checked) {
                checkedCount++;
                let serviceAssignmentList = [];
                this.serviceItemChangesInSelectedGroup.get(value.serviceItemId).serviceAssignments.forEach(value => serviceAssignmentList.push(value));
                if (!(value.serviceAssignments && ServiceAssignmentUtilService.isAssignedAny(serviceAssignmentList))) {
                    invalidStatus = true;
                }
                if (ReservationServiceItemsComponent.isRestricted(value)) {
                    statusOk = false;
                }
            }

        });
        if (checkedCount > 0 && !invalidStatus && statusOk && !this.changesAvailableForServiceItems) {
            disable = false;
        }
        return disable;
    }

    public isDisabledSupplierReject() {
        let disable = true;
        let isDMC = false;
        let checkedCount = 0;
        let invalidStatus = false;
        this.serviceItemChangesInSelectedGroup.forEach(value => {
            if (value.checked) {
                checkedCount++;
                let serviceAssignmentList = [];
                this.serviceItemChangesInSelectedGroup.get(value.serviceItemId).serviceAssignments.forEach(value => serviceAssignmentList.push(value));
                isDMC = ServiceAssignmentUtilService.isSupplierConfirmedOrDmcConfirmedAll(serviceAssignmentList);
                if (!(value.serviceAssignments && ServiceAssignmentUtilService.isSupplierConfirmedAny(serviceAssignmentList))) {
                    invalidStatus = true;
                }
            }
        });

        if (isDMC) {
            disable = false;
        }

        if (checkedCount > 0 && !invalidStatus && !this.changesAvailableForServiceItems) {
            disable = false;
        }
        return disable;
    }

    public isDisabledSave() {
        if (this.isRequestCompleted) {
            let disable = true;
            if (this.changesAvailableForServiceItems) {
                disable = false;
            }
            this.isSavedChanges.emit(disable);
            return disable;
        } else {
            this.isSavedChanges.emit(true);
            return true;
        }
    }

    public onBulkVehicleAssignment() {
        this.bulkVehicleAssignment.emit();
    }
    public onBulkDriverAssignment() {
        this.bulkDriverAssignment.emit();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        for (let propName in changes) {
            if (propName === 'contentCardDataList') {
                this.selectedAll = false;
            }
        }
    }

    onViewMoreClick(serviceItem: any) {
        this.viewMoreFocus.emit(serviceItem);
    }

    public isDisabledCheckbox(): boolean {
        let disable = true;
        let statusOk = 0;
        this.serviceItemChangesInSelectedGroup.forEach(value => {
            if (ReservationServiceItemsComponent.isRestricted(value)) {
                statusOk++;
            }
        });
        if (statusOk !== this.serviceItemChangesInSelectedGroup.size) {
            disable = false;
        }
        return disable;
    }

    public onHistoryClick(event: any) {
        this.historyClick.emit(event);
    }
}
