export class PaxCost {
    estimatedCost: number;
    totalCost: number;
    unitCost: number;
    totalAdultCost: number;
    totalTeenCost: number;
    totalChildCost: number;
    totalInfantCost: number;
    perAdultCost: number;
    perTeenCost: number;
    perChildCost: number;
    perInfantCost: number;
    currency: string;
    costingType: string;
    noOfUnits: number;
    distanceInKm: number;
    durationInHours: number;

    constructor(currency: string) {
        this.currency = currency;
    }
}
