import { EventEmitter, OnInit } from '@angular/core';
import { DialogModel } from '@tc-core/model/it/codegen/ui/framework/dialog-model';
import { LEVEL, ModalData } from '@tc-core/model/it/codegen/ui/framework/modal-data';
// import { CalendarMenuItem } from '@tc/cg-calendar/calendar-header-controller/calendar-menu-item';
// import { CalendarRefreshEventType } from '@tc/cg-calendar/calendar-refresh-event-type';
// import { CgCalendarCssClassConstants } from '@tc/cg-calendar/cg-calendar-css-class-constants';
// import { CgCalendarRefreshData } from '@tc/cg-calendar/cg-calendar-refresh-data';
// import { DateRange } from '@tc/cg-calendar/date-range';
// import { RangeGroup } from '@tc/cg-calendar/range-group';
// import { SeasonCalendarService } from '@tc/cg-season-calendar/season-calendar.service';
// import { SeasonRangeEditCardComponent } from '@tc/cg-season-calendar/season-range-edit-card/season-range-edit-card.component';
// import { SeasonSetupSummaryComponent } from '@tc/cg-season-calendar/season-setup-summary/season-setup-summary.component';
// import { SeasonsEditCardComponent } from '@tc/cg-season-calendar/seasons-edit-card/seasons-edit-card.component';
import { DialogService } from '@tc/dialog/dialog.service';
import { ModalService } from '@tc/modal/modal.service';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { CalendarMenuItem } from '../cg-calendar/calendar-header-controller/calendar-menu-item';
import { CalendarRefreshEventType } from '../cg-calendar/calendar-refresh-event-type';
import { CgCalendarCssClassConstants } from '../cg-calendar/cg-calendar-css-class-constants';
import { SeasonCalendarService } from './season-calendar.service';
import { SeasonRangeEditCardComponent } from './season-range-edit-card/season-range-edit-card.component';
import { SeasonSetupSummaryComponent } from './season-setup-summary/season-setup-summary.component';
import { SeasonsEditCardComponent } from './seasons-edit-card/seasons-edit-card.component';
var CgSeasonCalendarComponent = /** @class */ (function () {
    function CgSeasonCalendarComponent(modalService, seasonCalendarService, dialogService) {
        this.modalService = modalService;
        this.seasonCalendarService = seasonCalendarService;
        this.dialogService = dialogService;
        this.fromYear = 0;
        this.toYear = 0;
        this.rangeGroups = [];
        this.viewOnlyMode = false;
        this.mergeNearDateRanges = true;
        this.newRangeGroupNamePrefix = 'Season ';
        this.showCalendar = true;
        this.onChangeSeasons = new EventEmitter();
        this.refreshCalendar = new BehaviorSubject(null);
        this.calendarRefreshEvent = this.refreshCalendar.asObservable();
        this.calendarMenuItems = [];
        this.seasonSetupSummaryMenuId = 'seasonSetupMenuId';
        this.rangeInitTempDate = null;
    }
    CgSeasonCalendarComponent.prototype.ngOnInit = function () {
        this.addInitialSeasonColors();
        this.initMenuItems();
        this.subscribeSeasonChange();
        this.seasonCalendarService.sortAllDateRanges(this.rangeGroups);
    };
    CgSeasonCalendarComponent.prototype.initMenuItems = function () {
        var calendarMenuItem = new CalendarMenuItem();
        calendarMenuItem.id = this.seasonSetupSummaryMenuId;
        calendarMenuItem.title = 'Season Setup Summary';
        calendarMenuItem.matIcon = 'assignment';
        this.calendarMenuItems.push(calendarMenuItem);
    };
    CgSeasonCalendarComponent.prototype.subscribeSeasonChange = function () {
        var _this = this;
        this.seasonCalendarService.onChangeSeasonsEvent.subscribe(function (value) {
            if (value) {
                _this.onChangeSeasons.emit(value);
            }
        });
    };
    CgSeasonCalendarComponent.prototype.addInitialSeasonColors = function () {
        for (var i = 0; i < this.rangeGroups.length; i++) {
            var group = this.rangeGroups[i];
            if (!group.colorId) {
                group.colorId = this.getFreeColor();
            }
        }
    };
    CgSeasonCalendarComponent.prototype.getFreeColor = function () {
        var colorId = '';
        var _loop_1 = function (colorIdKey) {
            var match = false;
            this_1.rangeGroups.forEach(function (group) {
                if (group.colorId === CgCalendarCssClassConstants.ColorId[colorIdKey].class) {
                    match = true;
                }
            });
            if (!match) {
                colorId = CgCalendarCssClassConstants.ColorId[colorIdKey].class;
                return { value: colorId };
            }
        };
        var this_1 = this;
        for (var colorIdKey in CgCalendarCssClassConstants.ColorId) {
            var state_1 = _loop_1(colorIdKey);
            if (typeof state_1 === "object")
                return state_1.value;
        }
        return colorId;
    };
    CgSeasonCalendarComponent.prototype.onSelectDate = function ($event) {
        this.processDateSelect($event);
    };
    CgSeasonCalendarComponent.prototype.refreshCalendarData = function (eventType, data) {
        var _this = this;
        setTimeout(function () {
            _this.refreshCalendar.next({ type: eventType, data: data });
        }, 0);
    };
    ;
    CgSeasonCalendarComponent.prototype.onSelectSeason = function ($event) {
        this.rangeInitTempDate = null;
        this.seasonCalendarService.selectRangeGroup(this.rangeGroups, $event);
        this.refreshCalendarData(CalendarRefreshEventType.RangeGroupSelect, null);
    };
    CgSeasonCalendarComponent.prototype.onRemoveDateRange = function ($event) {
        var _this = this;
        if ($event) {
            var confirmSuccess = new DialogModel(true, LEVEL.WARNING, 'Remove Date Range', "Do you want to delete the date range (" + moment($event.startDate).format('YYYY MMM DD') + " - \n        " + moment($event.endDate).format('YYYY MMM DD') + ") ?", true, 2000, null, 'No', 'Yes');
            this.dialogService
                .confirm(confirmSuccess)
                .subscribe(function (res) {
                if (res === true) {
                    _this.seasonCalendarService.removeDateRange(_this.rangeGroups, $event);
                    _this.refreshCalendarData(CalendarRefreshEventType.DateRangeRemove, null);
                }
            });
        }
    };
    CgSeasonCalendarComponent.prototype.onRemoveSeason = function ($event) {
        var _this = this;
        if ($event && !$event.nonRemovable) {
            var confirmSuccess = new DialogModel(true, LEVEL.WARNING, 'Remove Season', "Do you want to delete the season '" + $event.name + "' ?", true, 2000, null, 'No', 'Yes');
            this.dialogService
                .confirm(confirmSuccess)
                .subscribe(function (res) {
                if (res === true) {
                    _this.seasonCalendarService.removeRangeGroup(_this.rangeGroups, $event);
                    _this.refreshCalendarData(CalendarRefreshEventType.RangeGroupRemove, null);
                }
            });
        }
    };
    CgSeasonCalendarComponent.prototype.processDateSelect = function (date) {
        var processingDate = new Date(date);
        var selectedAnother = false;
        // select rangeGroup if the date is within an unselected rangeGroup
        if (!this.rangeInitTempDate) {
            selectedAnother = this.selectIfWithinAGroup(processingDate);
            if (selectedAnother) {
                this.refreshCalendarData(CalendarRefreshEventType.RangeGroupSelect, null);
            }
        }
        if (!selectedAnother && !this.viewOnlyMode) {
            var selectedRangeGroup = this.rangeGroups.find(function (group) { return group.selected; });
            if (selectedRangeGroup) {
                if (this.rangeInitTempDate) {
                    //create and add new date range
                    this.seasonCalendarService.createAndAddNewDateRange(this.rangeGroups, this.rangeInitTempDate, processingDate, this.mergeNearDateRanges);
                    this.refreshCalendarData(CalendarRefreshEventType.DateRangeAdded, null);
                    this.rangeInitTempDate = null;
                }
                else {
                    if (!this.hasADateRange(processingDate, selectedRangeGroup)) {
                        this.rangeInitTempDate = processingDate;
                    }
                }
            }
        }
    };
    CgSeasonCalendarComponent.prototype.hasADateRange = function (processingDate, selectedRangeGroup) {
        var foundRange = selectedRangeGroup.dateRanges.find(function (range) {
            return SeasonCalendarService.withinRange(processingDate, range);
        });
        return !!foundRange;
    };
    CgSeasonCalendarComponent.prototype.selectIfWithinAGroup = function (processingDate) {
        var selected = this.seasonCalendarService.selectIfWithinAGroup(this.rangeGroups, processingDate);
        return selected;
    };
    CgSeasonCalendarComponent.prototype.onSetSeasons = function ($event) {
        var _this = this;
        var dataObject = new ModalData(LEVEL.SUCCESS, 'Set Seasons', null, null, 'modal-basic--alt modal-basic--fixed-size', SeasonsEditCardComponent, { rangeGroups: this.rangeGroups, newRangeGroupNamePrefix: this.newRangeGroupNamePrefix });
        this.modalService
            .confirm(dataObject).subscribe(function (res) {
            if (res) {
                _this.rangeGroups = res['rangeGroups'];
                _this.refreshCalendarData(CalendarRefreshEventType.RangeGroupsAddedOrAttributesChanged, null);
            }
        });
    };
    CgSeasonCalendarComponent.prototype.onSetSeasonRange = function ($event) {
        var _this = this;
        var selectedSeason = this.rangeGroups.find(function (group) { return group.selected; });
        if (selectedSeason) {
            var dataObject = new ModalData(LEVEL.SUCCESS, 'Set Date Range', null, null, 'modal-basic--alt modal-basic--fixed-size', SeasonRangeEditCardComponent, { rangeGroup: selectedSeason, rangeGroups: this.rangeGroups, mergeNearDateRanges: this.mergeNearDateRanges });
            this.modalService
                .confirm(dataObject).subscribe(function (res) {
                if (res) {
                    _this.refreshCalendarData(CalendarRefreshEventType.DateRangeAdded, null);
                }
            });
        }
    };
    CgSeasonCalendarComponent.prototype.onMenuItemClick = function ($event) {
        if ($event.id === this.seasonSetupSummaryMenuId) {
            var dataObject = new ModalData(LEVEL.SUCCESS, 'Season Setup Summary', null, { 'label': 'CLOSE' }, 'modal-basic--fixed-size modal-basic--show-footer', SeasonSetupSummaryComponent, { rangeGroups: this.rangeGroups });
            this.modalService
                .confirm(dataObject).subscribe(function (res) {
                if (res) {
                }
            });
        }
    };
    CgSeasonCalendarComponent.prototype.copyRangeGroupsArray = function (newRangeGroups) {
        var _this = this;
        this.rangeGroups.length = 0; //clear original array
        newRangeGroups.forEach(function (group) {
            _this.rangeGroups.push(group);
        });
    };
    CgSeasonCalendarComponent.prototype.onSelectDateRange = function ($event) {
        this.refreshCalendarData(CalendarRefreshEventType.DateRangeSelect, $event);
    };
    return CgSeasonCalendarComponent;
}());
export { CgSeasonCalendarComponent };
