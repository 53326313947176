import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SecurityModule } from '@tc/security';

import { TranslateModule } from '@ngx-translate/core';

import { PaymentInfoCardComponent } from './payment-info-card.component';

@NgModule({
    imports: [
        TranslateModule.forChild(),
        CommonModule,
        SecurityModule
    ],
    exports: [
        PaymentInfoCardComponent
    ],
    declarations: [
        PaymentInfoCardComponent
    ],
    providers: [],
})
export class PaymentInfoCardModule {
}
