import { Component, OnInit } from '@angular/core';
import {DataKey} from "../../../services/util/framework/data-store.service";
import {TCO} from "../../../constants";
import {CompanySearchCriteria} from "../../../models/criteria/company-search-criteria";
import {DivisionSearchCriteria} from "../../../models/criteria/division-search-criteria";

@Component({
  selector: 'tc-division-setup-search-criteria',
  templateUrl: './division-setup-search-criteria.component.html'
})
export class DivisionSetupSearchCriteriaComponent {

  inputSearchCriteria = new DivisionSearchCriteria();
  searchCriteriaUpdatingDataKey = DataKey.divisionSetupSearchCriteria;
  searchResultsDataKey = DataKey.divisionSetupSearchResultsForCriteria;
  searchCriteriaLoadingDataKey = TCO.AppData.DIVISION_SETUP_SEARCH_CRITERIA;
  chipConfigKey = TCO.CONF.CONF_DIVISION_SETUP_SEARCH_CRITERIA_CHIP_CONFIG;
  placeHolderText = 'Search Division Master Data';

  createNewSearchCriteriaFn = () => {
    const criteria = new DivisionSearchCriteria();
    criteria.code = '';
    criteria.name = '';
    criteria.kcCompany = '';
    return criteria;
  };

  clearLoadedSearchCriteriaFn = (criteria: DivisionSearchCriteria) => {
  };

}
