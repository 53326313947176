import * as tslib_1 from "tslib";
import { AbstractSearchCriteria } from './abstract-search-criteria';
var MappedResourceTypeSearchCriteria = /** @class */ (function (_super) {
    tslib_1.__extends(MappedResourceTypeSearchCriteria, _super);
    function MappedResourceTypeSearchCriteria() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return MappedResourceTypeSearchCriteria;
}(AbstractSearchCriteria));
export { MappedResourceTypeSearchCriteria };
