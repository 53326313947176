/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../node_modules/@angular/material/checkbox/typings/index.ngfactory";
import * as i2 from "@angular/material/checkbox";
import * as i3 from "@angular/cdk/a11y";
import * as i4 from "@angular/platform-browser/animations";
import * as i5 from "@angular/forms";
import * as i6 from "./check-box-renderer-editor.component";
var styles_CheckBoxRendererEditorComponent = [];
var RenderType_CheckBoxRendererEditorComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CheckBoxRendererEditorComponent, data: {} });
export { RenderType_CheckBoxRendererEditorComponent as RenderType_CheckBoxRendererEditorComponent };
export function View_CheckBoxRendererEditorComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 5, "mat-checkbox", [["class", "tc-ag-grid__checkbox mat-checkbox"]], [[2, "tc-ag-grid__checkbox--error", null], [8, "id", 0], [1, "tabindex", 0], [2, "mat-checkbox-indeterminate", null], [2, "mat-checkbox-checked", null], [2, "mat-checkbox-disabled", null], [2, "mat-checkbox-label-before", null], [2, "_mat-animation-noopable", null], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ngModelChange" === en)) {
        var pd_0 = ((_co.selected = $event) !== false);
        ad = (pd_0 && ad);
    } if (("change" === en)) {
        var pd_1 = (_co.onChange($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_MatCheckbox_0, i1.RenderType_MatCheckbox)), i0.ɵdid(2, 8568832, null, 0, i2.MatCheckbox, [i0.ElementRef, i0.ChangeDetectorRef, i3.FocusMonitor, i0.NgZone, [8, null], [2, i2.MAT_CHECKBOX_CLICK_ACTION], [2, i4.ANIMATION_MODULE_TYPE]], { disabled: [0, "disabled"] }, { change: "change" }), i0.ɵprd(1024, null, i5.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.MatCheckbox]), i0.ɵdid(4, 671744, null, 0, i5.NgModel, [[8, null], [8, null], [8, null], [6, i5.NG_VALUE_ACCESSOR]], { isDisabled: [0, "isDisabled"], model: [1, "model"] }, { update: "ngModelChange" }), i0.ɵprd(2048, null, i5.NgControl, null, [i5.NgModel]), i0.ɵdid(6, 16384, null, 0, i5.NgControlStatus, [[4, i5.NgControl]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_15 = _co.disabled; _ck(_v, 2, 0, currVal_15); var currVal_16 = _co.disabled; var currVal_17 = _co.selected; _ck(_v, 4, 0, currVal_16, currVal_17); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isError; var currVal_1 = i0.ɵnov(_v, 2).id; var currVal_2 = null; var currVal_3 = i0.ɵnov(_v, 2).indeterminate; var currVal_4 = i0.ɵnov(_v, 2).checked; var currVal_5 = i0.ɵnov(_v, 2).disabled; var currVal_6 = (i0.ɵnov(_v, 2).labelPosition == "before"); var currVal_7 = (i0.ɵnov(_v, 2)._animationMode === "NoopAnimations"); var currVal_8 = i0.ɵnov(_v, 6).ngClassUntouched; var currVal_9 = i0.ɵnov(_v, 6).ngClassTouched; var currVal_10 = i0.ɵnov(_v, 6).ngClassPristine; var currVal_11 = i0.ɵnov(_v, 6).ngClassDirty; var currVal_12 = i0.ɵnov(_v, 6).ngClassValid; var currVal_13 = i0.ɵnov(_v, 6).ngClassInvalid; var currVal_14 = i0.ɵnov(_v, 6).ngClassPending; _ck(_v, 1, 1, [currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14]); }); }
export function View_CheckBoxRendererEditorComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-check-renderer-editor", [], null, null, null, View_CheckBoxRendererEditorComponent_0, RenderType_CheckBoxRendererEditorComponent)), i0.ɵdid(1, 49152, null, 0, i6.CheckBoxRendererEditorComponent, [], null, null)], null, null); }
var CheckBoxRendererEditorComponentNgFactory = i0.ɵccf("tc-check-renderer-editor", i6.CheckBoxRendererEditorComponent, View_CheckBoxRendererEditorComponent_Host_0, {}, {}, []);
export { CheckBoxRendererEditorComponentNgFactory as CheckBoxRendererEditorComponentNgFactory };
