export class DocumentData {
    id: number;
    tempId: number;
    name: string;
    template: string;
    documentCode: string;
    format: string;
    dispatchMethod: string;
    dispatchMethodName: string;
    frequency: string;
    frequencyName: string;
    freqParam1: string;
    freqParam2: string;
    contactCategory: string;
}
