import { Component, Input, OnInit } from '@angular/core';
import { TC } from '@tc-core/util/constants';

@Component({
    selector: 'tc-flight-info-expanded-view',
    templateUrl: './flight-info-expanded-view.component.html'
})
export class FlightInfoExpandedViewComponent implements OnInit {

    @Input() arrFltNumbers: string[] = [];
    @Input() deptFltNumbers: string[] = [];
    @Input() arrFltTerminals: string[] = [];
    @Input() deptFltTerminals: string[] = [];
    @Input() arrFltTime: string[] = [];
    @Input() deptFltTime: string[] = [];
    @Input() arrAirports: string[] = [];
    @Input() deptAirports: string[] = [];

    EMPTY_CHAR = TC.FORMAT.EMPTY_CHAR;

    constructor() { }

    ngOnInit() {
    }

}
