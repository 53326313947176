import { ConfigLoader } from '@tc-core/util/framework';
import { DMCQueryParamsService } from '../../util/framework/dmc-query-params.service';
import { RequestService } from '../../util/framework/request.service';
import { UrlPaths } from '../url-paths';
import * as i0 from "@angular/core";
import * as i1 from "@tc-core/util/framework/config-loader.service";
import * as i2 from "../../util/framework/request.service";
import * as i3 from "../../util/framework/dmc-query-params.service";
var ProductDetailsService = /** @class */ (function () {
    function ProductDetailsService(configLoader, requestService, queryParamsService) {
        this.configLoader = configLoader;
        this.requestService = requestService;
        this.queryParamsService = queryParamsService;
    }
    ProductDetailsService.prototype.getProductDetails = function (criteria) {
        var reqPrams = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.get(UrlPaths.OP_RES_URL_KEY, [UrlPaths.OP_RES_VERSION, UrlPaths.PRODUCT_DETAILS], reqPrams);
    };
    ProductDetailsService.prototype.deleteProductDetails = function (criteria) {
        return this.requestService.delete(UrlPaths.OP_RES_URL_KEY, [UrlPaths.OP_RES_VERSION, UrlPaths.PRODUCT_DETAILS, criteria.productCode, criteria.serviceType]);
    };
    ProductDetailsService.prototype.saveProductDetails = function (row) {
        return this.requestService.post(UrlPaths.OP_RES_URL_KEY, [UrlPaths.OP_RES_VERSION, UrlPaths.PRODUCT_DETAILS], row);
    };
    ProductDetailsService.ngInjectableDef = i0.defineInjectable({ factory: function ProductDetailsService_Factory() { return new ProductDetailsService(i0.inject(i1.ConfigLoader), i0.inject(i2.RequestService), i0.inject(i3.DMCQueryParamsService)); }, token: ProductDetailsService, providedIn: "root" });
    return ProductDetailsService;
}());
export { ProductDetailsService };
