import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, TooltipPosition } from '@angular/material';
import { DialogModel, LEVEL } from '@tc-core/model/it/codegen/ui/framework/dialog-model';
import { SummaryCardData } from '@tc-core/model/it/codegen/ui/framework/summary-card-data';
import { TC } from '@tc-core/util/constants';
import { ConfigLoader } from '@tc-core/util/framework/config-loader.service';
import { EventManager } from '@tc-core/util/framework/event-manager.service';
import { FocusViewManager } from '@tc-core/util/framework/focus-view-manager.service';
import { Chip } from '@tc/chip-selector/chip.model';
import { DialogService } from '@tc/dialog/dialog.service';
import { FocusViewService } from '@tc/focus-view/focus-view.service';
import { SorterPipe } from '@tc/pipes/sorter.pipe';
import { TCO } from '../../../constants';
import { ManageNotesComponent } from '../../../widgets/framework/manage-notes';
import { GenericSearchCriteria } from '../../../models/criteria/generic-search-criteria';
import { SupplierSearchCriteria } from '../../../models/criteria/supplier-search-criteria';
import { VehicleSearchCriteria } from '../../../models/criteria/vehicle-search-criteria';
import { ItemGroupNoteMap } from '../../../models/reservation/item-group-note-map';
import { NoteItemView } from '../../../models/reservation/note-item-view';
import { ServiceNote } from '../../../models/reservation/service-note';
import { GenericResourceSummary } from '../../../models/summary/generic-resource-summary';
import { TrsSupplierAllocationSummary } from '../../../models/summary/trs-supplier-allocation-summary';
import { VehicleSummary } from '../../../models/summary/vehicle-summary';
import { TrsReservationManagementService } from '../../../services/backend-consumers/reservation-management-services/trs-reservation-management.service';
import { GenericResourceService } from '../../../services/backend-consumers/supplier-service/generic-resource.service';
import { SupplierService } from '../../../services/backend-consumers/supplier-service/supplier.service';
import { TransportResourceService } from '../../../services/backend-consumers/supplier-service/transport-resource.service';
import { DataKey, DataStoreService } from '../../../services/util/framework/data-store.service';
import { ResponseUtil } from '../../../services/util/response/response-util.service';
import { CardDataHandlerService } from '../../../services/util/ui/card-data-handler-service.service';

@Component({
    selector: 'tc-service-item-detail-focus-view',
    templateUrl: './service-item-detail-focus-view.component.html'
})
export class ServiceItemDetailFocusViewComponent implements OnInit {
    @Input() selectedServiceItem: any;

    selectedSupplier: TrsSupplierAllocationSummary;
    selectedVehicle: VehicleSummary;
    selectedDriver: GenericResourceSummary;

    noteList = [];
    assignmentStatus = '';
    addNoteTitle = 'Add New Note';
    masterNoteTypes = [];
    noteSummary: SummaryCardData;
    CARD_CONFIG_NOTES = 'notes';
    noteContents: NoteItemView[] = [];
    isConfirmed: boolean = false;
    editNoteTitle = 'Edit Note';
    historyText = '';
    public addNoteToolTip = 'Add Note';
    public toolTipPosition: TooltipPosition = 'below';

    constructor(
        private supplierService: SupplierService,
        private configLoader: ConfigLoader,
        private sortPipe: SorterPipe,
        private transportResourceService: TransportResourceService,
        private reservationService: TrsReservationManagementService,
        public dialog: MatDialog, private dialogService: DialogService,
        private cardDataHandlerService: CardDataHandlerService,
        private dataStore: DataStoreService,
        private em: EventManager,
        private focusViewManager: FocusViewManager,
        private focusViewService: FocusViewService,
        private genericResourceService: GenericResourceService
    ) { }

    ngOnInit() {
        this.masterNoteTypes = this.configLoader.configurations.get(TCO.CONF.CONF_CONTENT_LIST_CARD).master_note_types;
        this.focusViewManager.canProceed.next(true);
        this.focusViewManager.canProceedState.next(true);
        this.noteList = this.selectedServiceItem['rawData']['serviceNotes'];
        if (this.selectedServiceItem['rawData']['assignment'] !== null) {
            if (this.selectedServiceItem['rawData']['assignment'].status === 1) {
                this.assignmentStatus = 'Confirmed';
                this.isConfirmed = true;
            } else if (this.selectedServiceItem['rawData']['assignment'].status === 2) {
                this.assignmentStatus = 'Supplier Confirmed';
                this.isConfirmed = true;
            } else if (this.selectedServiceItem['rawData']['assignment'].status === 3) {
                this.assignmentStatus = 'Supplier Rejected';
                this.isConfirmed = true;
            } else {
                this.assignmentStatus = '';
                this.isConfirmed = false;
            }
        }
        if (this.selectedServiceItem['rawData']['serviceItemHistories'] &&
            this.selectedServiceItem['rawData']['serviceItemHistories'].length > 0) {
            const historyTexList = [];
            for (const serviceItemHistory of this.selectedServiceItem['rawData']['serviceItemHistories']) {
                if (serviceItemHistory.description) {
                    historyTexList.push(...serviceItemHistory.description.split('. '));
                }
            }
            for (const historyTexListElement of historyTexList) {
                this.historyText += historyTexListElement + '<br>';
            }
        }
        this.onNoteListUpdate();

        if (this.selectedServiceItem['supplier'] > 0) {
            this.getSelectedSupplier();
        }
        if (this.selectedServiceItem['resource'] > 0) {
            this.getSelectedVehicle();
        }
        if (this.selectedServiceItem['driver'] > 0) {
            this.getSelectedDriver();
        }

        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'CLOSE_FOCUS_VIEW').subscribe(e => {
            this.focusViewService.close(true);
        });
    }

    getSelectedSupplier() {
        let supplierSearchCriteria = new SupplierSearchCriteria();
        supplierSearchCriteria.supplierId = this.selectedServiceItem['supplier'];
        this.supplierService.searchSuppliersForAssignment(supplierSearchCriteria).subscribe(
            result => {
                if (result) {
                    this.selectedSupplier = ResponseUtil.getFirstData(result);
                }
            }, error => {
            }
        );
    }

    getSelectedVehicle() {
        let vehicleSearchCriteria = new VehicleSearchCriteria();
        vehicleSearchCriteria.activeStatus = true;
        vehicleSearchCriteria.resourceId = this.selectedServiceItem['resource'];
        this.transportResourceService.searchVehicleSummaries(vehicleSearchCriteria).subscribe(
            result => {
                if (result ) {
                    this.selectedVehicle = ResponseUtil.getFirstData(result);
                }
            }, error => {
            }
        );
    }

    getSelectedDriver() {
        let genericSearchCriteria = new GenericSearchCriteria();
        genericSearchCriteria.activeStatus = 'true';
        genericSearchCriteria.resourceId = this.selectedServiceItem['driver'];
        this.genericResourceService.searchGenericResource(genericSearchCriteria).subscribe(
            result => {
                if (result ) {
                    this.selectedDriver = ResponseUtil.getFirstData(result);
                }
            }
        );
    }

    onAddNotesClick(event) {
        let addNoteDialogRef = this.getNoteDialogRef(
            this.addNoteTitle,
            '',
            '',
            '',
            '',
            false
        );
        addNoteDialogRef.afterClosed().subscribe(result => {
            if (result) {
                console.log(result);
                this.saveItemNote(result);
                /*if (productKey) {
                 this.addBkgItemNote(
                 this.bookingHelperService.buildKeyControls(
                 this.booking,
                 this.client,
                 this.bookingSearchCriteria,
                 this.user
                 ), productKey, result
                 );
                 } else {
                 this.addNote(
                 this.bookingHelperService.buildKeyControls(
                 this.booking,
                 this.client,
                 this.bookingSearchCriteria,
                 this.user
                 ), result
                 );
                 }*/
            }
        });
    }

    saveItemNote(note: any) {
        let serviceItemNote = new ItemGroupNoteMap();
        serviceItemNote.serviceNote = new ServiceNote();
        serviceItemNote.serviceNote.createdOn = Date.now();
        serviceItemNote.serviceNote.note = note.note;
        serviceItemNote.serviceNote.type = note.selectedNoteTypes[0].value.code;
        serviceItemNote.serviceItemId = this.selectedServiceItem.serviceItemId;
        this.reservationService.saveServiceItemNote(serviceItemNote.serviceItemId, serviceItemNote)
            .subscribe(noteResult => {
                if (noteResult !== null) {
                    this.noteList = ResponseUtil.getDataArray<ServiceNote>(noteResult);

                    this.dataStore.set(DataKey.focusViewDataChanged, true);
                    this.onNoteListUpdate();
                }
            });
    }

    onNoteListUpdate() {
        this.masterNoteTypes = this.configLoader.configurations.get(TCO.CONF.CONF_CONTENT_LIST_CARD).master_note_types;
        this.noteSummary = this.cardDataHandlerService.processCardData(
            this.CARD_CONFIG_NOTES,
            this.noteList,
            'note-summary'
        );

        if (this.noteList.length > 0) {
            this.noteContents = [];
            for (const note of this.noteList) {
                let noteContent: NoteItemView = new NoteItemView();
                noteContent.header = this.cardDataHandlerService.processCardData(
                    this.CARD_CONFIG_NOTES,
                    note,
                    'note-detail.header'
                );
                noteContent.summary = this.cardDataHandlerService.processCardData(
                    this.CARD_CONFIG_NOTES,
                    note,
                    'note-detail.summary'
                );
                noteContent.detail = this.cardDataHandlerService.processCardData(
                    this.CARD_CONFIG_NOTES,
                    note,
                    'note-detail.detail'
                );
                this.noteContents.push(noteContent);
            }
        }
    }

    getNoteDialogRef(
        noteTitle: any, noteText: any, noteType: any, noteMethodCodes: string, noteResourcePath: string,
        isEdit: boolean = false
    ): any {
        let noteTypes = this.sortPipe.transform(this.masterNoteTypes, 'name');

        return this.dialog.open(
            ManageNotesComponent, {
                width: '', height: '',
                data: {
                    title: noteTitle,
                    note: noteText,
                    noteTypes: noteTypes,
                    selectedNoteType: noteType,
                    isNoteEditMode: isEdit,
                    selectedNoteTypes: this.getSelectedNoteType(noteTypes, noteType),
                    methodCodes: noteMethodCodes,
                    resourcePath: noteResourcePath,
                    isMultiSelect: false
                }
            }
        );
    }

    private getSelectedNoteType(noteTypes: any, noteType: any) {
        let selectedNotes = [];
        if (noteTypes && noteTypes.length > 0 && noteType) {
            let selectedNoteTypes = noteTypes.filter(note => note.code === noteType);
            let chip = new Chip();
            chip.selected = true;
            chip.value = selectedNoteTypes[0];
            chip.restricted = false;
            selectedNotes.push(chip);
        }
        return selectedNotes;
    }

    onNoteSelection(note) {
    }

    onNotesEditClick(note: SummaryCardData) {
        if (note && note.data && note.data.value) {
            this.openEditNoteDialog(note.data.value);
        }
    }

    openEditNoteDialog(noteDetail): void {
        let editNoteDialogRef = this.getNoteDialogRef(
            this.editNoteTitle,
            noteDetail.note, noteDetail.type,
            'tc-manage-notes-edit.NOTE_EDIT',
            '@tc-manage-notes-edit.NOTE_EDIT',
            true
        );
        editNoteDialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.editItemNote(noteDetail, result);
            }
        });
    }

    editItemNote(noteDetail: ServiceNote, editedNoteContent: any) {
        noteDetail.note = editedNoteContent.note;
        this.reservationService.editServiceNote(this.selectedServiceItem.serviceItemId, noteDetail.serviceNoteId, noteDetail)
            .subscribe(result => {
                if (result) {
                    this.dataStore.set(DataKey.focusViewDataChanged, true);
                    this.onNoteListUpdate();
                }
            });
    }

    onNotesDeleteClick(note) {
        if (note && note.data && note.data.value) {
            let noteIndex = note.data.value.serviceNoteId;
            this.deleteNote(noteIndex);
        }

    }

    deleteNote(noteIndex: number): void {
        let confirmDataObject = new DialogModel(
            true,
            LEVEL.WARNING,
            'Delete Note',
            'This Note will be deleted. Do you wish to proceed?',
            true,
            3000
        );
        this.dialogService.confirm(confirmDataObject).subscribe(
            (res) => {
                if (res === true) {
                    this.deleteItemNote(noteIndex);
                }
            });
    }

    deleteItemNote(noteIndex: number) {
        this.reservationService.deleteServiceNote(this.selectedServiceItem.serviceItemId, noteIndex).subscribe(result => {
            for (let i = 0; i < this.noteList.length; i++) {
                let id = this.noteList[i].serviceNoteId;
                if (id === noteIndex) {
                    this.noteList.splice(i, 1);
                    break;
                }
            }
            this.dataStore.set(DataKey.focusViewDataChanged, true);
            this.onNoteListUpdate();
        });
    }
}
