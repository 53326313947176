/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../node_modules/@angular/material/checkbox/typings/index.ngfactory";
import * as i2 from "@angular/forms";
import * as i3 from "@angular/material/checkbox";
import * as i4 from "@angular/cdk/a11y";
import * as i5 from "@angular/platform-browser/animations";
import * as i6 from "@angular/common";
import * as i7 from "./room-add-card.component";
import * as i8 from "@tc-core/util/helpers/common-helper.service";
var styles_RoomAddCardComponent = [];
var RenderType_RoomAddCardComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_RoomAddCardComponent, data: {} });
export { RenderType_RoomAddCardComponent as RenderType_RoomAddCardComponent };
function View_RoomAddCardComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "tc-ml-2  tc-mb-2"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "mat-checkbox", [["class", "example-margin mat-checkbox"]], [[8, "id", 0], [1, "tabindex", 0], [2, "mat-checkbox-indeterminate", null], [2, "mat-checkbox-checked", null], [2, "mat-checkbox-disabled", null], [2, "mat-checkbox-label-before", null], [2, "_mat-animation-noopable", null]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.addType($event, _v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_MatCheckbox_0, i1.RenderType_MatCheckbox)), i0.ɵprd(5120, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.MatCheckbox]), i0.ɵdid(3, 8568832, null, 0, i3.MatCheckbox, [i0.ElementRef, i0.ChangeDetectorRef, i4.FocusMonitor, i0.NgZone, [8, null], [2, i3.MAT_CHECKBOX_CLICK_ACTION], [2, i5.ANIMATION_MODULE_TYPE]], { checked: [0, "checked"] }, { change: "change" }), (_l()(), i0.ɵted(4, 0, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.selected.includes(_v.context.$implicit["code"]); _ck(_v, 3, 0, currVal_7); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 3).id; var currVal_1 = null; var currVal_2 = i0.ɵnov(_v, 3).indeterminate; var currVal_3 = i0.ɵnov(_v, 3).checked; var currVal_4 = i0.ɵnov(_v, 3).disabled; var currVal_5 = (i0.ɵnov(_v, 3).labelPosition == "before"); var currVal_6 = (i0.ɵnov(_v, 3)._animationMode === "NoopAnimations"); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_8 = _v.context.$implicit["name"]; _ck(_v, 4, 0, currVal_8); }); }
export function View_RoomAddCardComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "add-document-wrapper"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_RoomAddCardComponent_1)), i0.ɵdid(2, 278528, null, 0, i6.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 2, "div", [["class", "tc-modal__footer"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "button", [["class", "button-right tc-button-primary tc-flex-item mat-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addRooms() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, [" Add "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.roomTypeOptions; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_RoomAddCardComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-room-add-card", [], null, null, null, View_RoomAddCardComponent_0, RenderType_RoomAddCardComponent)), i0.ɵdid(1, 114688, null, 0, i7.RoomAddCardComponent, [i8.CommonHelper], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RoomAddCardComponentNgFactory = i0.ɵccf("tc-room-add-card", i7.RoomAddCardComponent, View_RoomAddCardComponent_Host_0, { roomTypeOptions: "roomTypeOptions", selectedRooms: "selectedRooms" }, { closeModal: "closeModal" }, []);
export { RoomAddCardComponentNgFactory as RoomAddCardComponentNgFactory };
