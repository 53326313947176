import { AbstractSearchCriteria } from './abstract-search-criteria';
import {KeyControlAndAbstractSearchCriteria} from './key-control-and-abstract-search-criteria';

export class LedgerEntrySearchCriteria extends KeyControlAndAbstractSearchCriteria {
    bookingId: number;
    itemNo: number;
    serviceItemId: number;
    assignmentId: number;
    supplierCode: string = '';
    ledgerAccount: string = '';
}
