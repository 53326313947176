import { Component, OnInit, ViewChild } from '@angular/core';
import { TcErrorType } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-error';
import { TC } from '@tc-core/util';
import { ConfigLoader } from '@tc-core/util/framework';
import { IGetRowsParams } from 'ag-grid-community';
import { Observable, of, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SetupGridComp } from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid-comp';
import { SetupGridDataSource } from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid-data-source';
import { SetupGridComponent } from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid.component';
import { TCO } from '../../../constants';
import { CostingTypeCategory, CostingTypeSearchCriteria } from '../../../models/criteria/costing-type-search-criteria';
import { SortDirection } from '../../../models/helper/sort-direction';
import { MasterDataRestService } from '../../../services/backend-consumers/master-data-handler-service/master-data-rest.service';
import { CostingTypeService } from '../../../services/backend-consumers/setups/costing-type-service';
import { DMCCommon } from '../../../services/util/common/dmc-common';
import { DataKey, DataStoreService } from '../../../services/util/framework/data-store.service';
import EMPTY_CHAR = TC.FORMAT.EMPTY_CHAR;

@Component({
  selector: 'tc-costing-type-grid-setup',
  templateUrl: './costing-type-grid-setup.component.html'
})
export class CostingTypeGridSetupComponent extends SetupGridComp implements OnInit, SetupGridDataSource  {

  @ViewChild('setupGrid')
  private setupGrid: SetupGridComponent;
  public colDefConfig = [];

  // search criteria obj. used to
  private costingTypeSearchCriteria: CostingTypeSearchCriteria = new CostingTypeSearchCriteria();

  private searchCriteriaObserver: Subscription = new Subscription();

  allCostingTypeCodes = [];

  constructor(
      private configLoader: ConfigLoader,
      private costingTypeService: CostingTypeService,
      private dataStoreService: DataStoreService,
      private masterDataRestService: MasterDataRestService,
      private common: DMCCommon,
  ) {super(); }

  ngOnInit() {
    this.colDefConfig = this.configLoader.configurations.get(TCO.CONF.CONF_COSTING_TYPE_SETUP);
    this.subscribeSearchCriteria();
    this.getAllCostingTypeCodes();
  }

  getAllCostingTypeCodes() {
    this.masterDataRestService.getCostingTypes().subscribe(
        response => {
          response.data.forEach( data => {
            this.allCostingTypeCodes.push(data.code);
          });
        }
    );
  }

  /**
   * subscribe search criteria changes in search bar
   * then force grid to refresh data
   * grid refresh will be call getRaws method which is using this subscribed criteria for backend call
   */
  subscribeSearchCriteria() {
    this.searchCriteriaObserver = this.dataStoreService.get(DataKey.costingTypeSearchCriteria).subscribe(value => {
      this.costingTypeSearchCriteria = value;
      this.setupGrid.runForceSearch();
    });
  }

  public isInvalidRow(params): boolean {
    const data = params.data;
    if (data) {
      let invalid = true;
      if (
          data.code &&
          data.name &&
          data.category &&
          data.factor
      ) {
        invalid = false;
      }
      return invalid;
    } else {
      return false;
    }
  }

  public isUnsavedRow(row: any): boolean {
    return !row.id;
  }

  public deleteRow(row: any) {
    if (row && row.code) {
      return this.costingTypeService.deleteRow(row.code);
    } else {
      return of('success');
    }
  }

  public saveRow(row: any, event: any) {
    if (row.unsaved) {
      if (this.allCostingTypeCodes.includes(row.code)) {
        this.common.showSnackBar(
            'Costing Type Code Already Exist. Please Select a Different Code',
            3000,
            TcErrorType.TYPE.ERROR
        );
      } else {
        return this.costingTypeService.saveRow(row);
      }
    } else {
      return this.costingTypeService.saveRow(row);
    }
  }

  public getRows(params: IGetRowsParams): Observable<any> {
    console.log('fetch requesting for ' + params.startRow + ' to ' + params.endRow);
    console.log(params.sortModel);

    const pageSize = params.endRow - params.startRow;
    if (!this.costingTypeSearchCriteria) {
      this.costingTypeSearchCriteria = new CostingTypeSearchCriteria();
    }
    this.costingTypeSearchCriteria.start = params.startRow;
    this.costingTypeSearchCriteria.size = pageSize;
    if (params.sortModel && params.sortModel[0]) {
      this.costingTypeSearchCriteria.sortBy = params.sortModel[0].colId;
      if (params.sortModel[0].sort === SetupGridComp.GRID_SORT_ASCENDING) {
        this.costingTypeSearchCriteria.sortDirection = SortDirection.ASC;
      } else {
        this.costingTypeSearchCriteria.sortDirection = SortDirection.DESC;
      }
    } else {
      this.costingTypeSearchCriteria.sortBy = 'createdOn';
      this.costingTypeSearchCriteria.sortDirection = SortDirection.ASC;
    }
    return this.costingTypeService.getRows(this.costingTypeSearchCriteria)
               .pipe(
                   tap(data =>
                       this.dataStoreService.set(DataKey.costingTypeSearchResultsForCriteria, data)
                   )
               );
  }

  public createNewRow(): any {
    return {unsaved: true};
  }

  public ngOnDestroy(): void {
    if (this.searchCriteriaObserver) {
      this.searchCriteriaObserver.unsubscribe();
    }
  }

  getCategoryText = (params) => {
    if (params && params.data && params.data.category) {
      return CostingTypeCategory[params.data.category];
    }
    return EMPTY_CHAR;
  };

}
