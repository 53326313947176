import { GenResourceCost } from '../supplier/gen-resource-cost-setup';

export class GenSupplierAllocationSummary {
    city: string;
    code: string;
    country: string;
    currency: string;
    name: string;
    resourceCostSummaries: GenResourceCost[];
    supplierId: number;
    nullSupplierForClearing: boolean = false;
}
