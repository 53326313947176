<div class="trade-client-detail-view">
    <div class="">
        <tc-setup-grid
            #dispatchViewGrid
            [gridHeader]="'Dispatch Entries'"
            [readOnly]="true"
            [noSelection]="true"
            [colDefConfig]="colDefConfig"
            [setupGridComp]="this"
            [actions]="gridActions"
            [dataSource]="this"
            [enableCustomAdd]="true"
            (customAddClick)="onAddDispatchEntryClick()">
        </tc-setup-grid>
    </div>
</div>

<ng-template #dispatchIdCell let-row>
    <ng-container *ngIf="row?.id">
        DISP_{{row.id}}
    </ng-container>
</ng-template>

<!--status column view-->
<ng-template #statusCell let-row>
    <ng-container *ngIf="row?.dispatchStatus">
        <div class="tc-w-100 text-center">
            <!--            <div-->
            <!--                    class="tc-status-badge tc-status-badge&#45;&#45;success" style="width: fit-content;-->
            <!--    left: 0;-->
            <!--    padding: 10px;-->
            <!--    border-radius: 10px;"-->
            <!--                    [ngClass]="{'tc-status-badge&#45;&#45;warning': row.dispatchStatus === 'PENDING',-->
            <!--                                            'tc-status-badge&#45;&#45;success': row.dispatchStatus === 'SUCCESS',-->
            <!--                                            'tc-status-badge&#45;&#45;danger': row.dispatchStatus === 'FAILED'-->
            <!--                                            }">-->
            <div>
                <span class=""> {{row.dispatchStatus}}</span>
            </div>
        </div>
    </ng-container>
</ng-template>
