import { Component, Input, OnInit } from '@angular/core';
import { TC } from '@tc-core/util/constants';
import { InsuranceInformation } from '../../../models/supplier/insurance-information';

@Component({
  selector: 'tc-insurance-info-card',
  templateUrl: './insurance-info-card.component.html'
})
export class InsuranceInfoCardComponent implements OnInit {

  EMPTY_CHAR = TC.FORMAT.EMPTY_CHAR;

  @Input() insuranceInformation: InsuranceInformation;
  @Input() showTitle: boolean = true;

  constructor() { }

  ngOnInit() {
  }

}
