/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../widgets/framework/cg-season-calendar/cg-season-calendar.component.ngfactory";
import * as i2 from "../../../../widgets/framework/cg-season-calendar/cg-season-calendar.component";
import * as i3 from "@tc/modal/modal.service";
import * as i4 from "../../../../widgets/framework/cg-season-calendar/season-calendar.service";
import * as i5 from "@tc/dialog/dialog.service";
import * as i6 from "./season-setup-focus-view.component";
import * as i7 from "@tc-core/util/framework/event-manager.service";
import * as i8 from "@tc-core/util/framework/user-journey-manager.service";
import * as i9 from "../../../../services/util/framework/data-store.service";
import * as i10 from "@tc-core/util/framework/focus-view-manager.service";
import * as i11 from "@tc/focus-view/focus-view.service";
var styles_SeasonSetupFocusViewComponent = [];
var RenderType_SeasonSetupFocusViewComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SeasonSetupFocusViewComponent, data: {} });
export { RenderType_SeasonSetupFocusViewComponent as RenderType_SeasonSetupFocusViewComponent };
export function View_SeasonSetupFocusViewComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-cg-season-calendar", [], null, [[null, "onChangeSeasons"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onChangeSeasons" === en)) {
        var pd_0 = (_co.onChangeSeasons($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_CgSeasonCalendarComponent_0, i1.RenderType_CgSeasonCalendarComponent)), i0.ɵdid(1, 114688, null, 0, i2.CgSeasonCalendarComponent, [i3.ModalService, i4.SeasonCalendarService, i5.DialogService], { fromYear: [0, "fromYear"], toYear: [1, "toYear"], rangeGroups: [2, "rangeGroups"] }, { onChangeSeasons: "onChangeSeasons" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.fromYear; var currVal_1 = _co.toYear; var currVal_2 = _co.rangeGroupsForSeasonCalendar; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_SeasonSetupFocusViewComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-season-setup-focus-view", [], null, null, null, View_SeasonSetupFocusViewComponent_0, RenderType_SeasonSetupFocusViewComponent)), i0.ɵdid(1, 4308992, null, 0, i6.SeasonSetupFocusViewComponent, [i7.EventManager, i8.UserJourneyManager, i9.DataStoreService, i10.FocusViewManager, i11.FocusViewService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SeasonSetupFocusViewComponentNgFactory = i0.ɵccf("tc-season-setup-focus-view", i6.SeasonSetupFocusViewComponent, View_SeasonSetupFocusViewComponent_Host_0, { resourceCostData: "resourceCostData" }, {}, []);
export { SeasonSetupFocusViewComponentNgFactory as SeasonSetupFocusViewComponentNgFactory };
