<div class="trade-client-detail-view">
    <div class="">
        <tc-setup-grid
            #jobHistoriesView
            [readOnly]="true"
            [colDefConfig]="colDefConfig"
            [setupGridComp]="this"
            [dataSource]="this"
            [noSelection]="true">
        </tc-setup-grid>
    </div>
</div>


<ng-template #statusCell let-row>
    <ng-container *ngIf="row?.historyStatus">
        {{displayStatus(row.historyStatus)}}
    </ng-container>
</ng-template>
