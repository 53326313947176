<div class="" *ngIf="resultsList" id="vehicle-assign-scroll-container">

    <ng-container
            *ngFor="let client of resultsList | tcAdvPager:pager.currPage:pager.itemsPerPage | tcAdvSorter:sortingHandler:sorter; let i = index;">

        <div class="">
            <div [ngClass]="hasSelectedThis(client)?'tc-expanded-content-card--selected':''"
                 tcExpansionPanel
                 tabindex="-1"
                 [exclude]="['event', 'tc-strip-timeline__target tc-strip-timeline__target--single ng-star-inserted', 'tc-strip-timeline__target tc-strip-timeline__target--group']"
                 [enabled]="true"
                 (clickedOutside)="onOutsideClick(selectedVehicle)"
                 (collapse)="expandedPanelIndex = -1">

                <!-- Content card Strip -->
                <div *tcExpansionPanelSummary id="clientSumExp_{{i}}">

                    <tc-content-strip
                            (actionClick)="onActionClick(client,$event)"
                            (click)="onVehicleSelection(client,i)">

                        <!--<tc-result-content-card-resource-assign
                                [summaryContent]="client">
                        </tc-result-content-card-resource-assign>-->
                        <tc-resource-allocation-card
                                [itemGroup]="itemGroup"
                                [bookingData]="bookingData"
                                [bookingDataArray]="bookingDataArray"
                                [summaryContent]="client"
                                [isBulkAssignment]="isBulkAssignment"
                                (resourceCalendarClick)="onResourceCalendarClick($event)"
                                (eventClick)="onEventClick($event, client)"
                                (targetClick)="onTargetClick($event)">
                        </tc-resource-allocation-card>

                    </tc-content-strip>
                </div>

                <div *tcExpansionPanelDetail id="clientDetailExp_{{i}}">
                    <tc-expanded-content-card class="tc-expanded-content-card tc-form-view" [isEdit]="false">

                        <!--<tc-resource-expanded-content-header
                                *ngIf="selectedVehicle"
                                [icon]="selectedVehicle.icon"
                                [headerText]="getHeader(selectedVehicle)"
                                [secondaryHeaderText]="getSecondaryHeaderText(selectedVehicle)"
                                (headerClick)="onHeaderClick(selectedVehicle)">
                        </tc-resource-expanded-content-header>-->
                        <tc-resource-allocation-card
                                [itemGroup]="itemGroup"
                                [summaryContent]="client"
                                [bookingData]="bookingData"
                                [bookingDataArray]="bookingDataArray"
                                [isBulkAssignment]="isBulkAssignment"
                                (resourceCalendarClick)="onResourceCalendarClick($event)"
                                (eventClick)="onEventClick($event, client)"
                                (targetClick)="onTargetClick($event)">
                        </tc-resource-allocation-card>

                        <tc-vehicle-assign-detail-view
                                *ngIf="selectedVehicle"
                                [summaryCardData]="selectedVehicle"
                                [footerDataMap]="footerDataMap"
                                [bookingData]="bookingData"
                                [bookingDataArray]="bookingDataArray"
                                [isBulkAssignment]="isBulkAssignment"
                                [assignedVehicle]="assignedVehicle"
                                (headerClick)="onHeaderClick(selectedVehicle)"
                                (clickOutSide)="onOutsideClick($event)">
                        </tc-vehicle-assign-detail-view>

                    </tc-expanded-content-card>
                </div>
            </div>
        </div>
    </ng-container>
</div>
