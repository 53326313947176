import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppQuickActionComponent } from './app-quick-action.component';
import {QuickActionModule} from '@tc/quick-action';

@NgModule({
    imports: [
        CommonModule,
        QuickActionModule
    ],
    declarations: [AppQuickActionComponent],
    exports: [AppQuickActionComponent]
})
export class AppQuickActionModule { }
