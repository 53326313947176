import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {KeyValue} from '@tc-core/model/it/codegen/ui/key-value';
import {ContactDetails} from '../../../models/profile/contact-details';
import {SummaryCardData} from '@tc-core/model/it/codegen/ui/framework/summary-card-data';
import {TaxScheme} from '../../../models/supplier/tax-scheme';

@Component({
    selector: 'tc-tax-scheme-summary-card',
    templateUrl: './tax-scheme-summary-card.component.html'
})
export class TaxSchemeSummaryCardComponent implements OnInit {
    public icon = new KeyValue();
    @Input() taxSchemeData: TaxScheme;
    @Input() isHeader: boolean;
    @Input() isEditable: boolean;
    @Input() taxSchemeMap: any[];
    @Input() nameCodeMap: Map<string, string>;
    @Input() showIcons: boolean;
    @Input() taxSchemeContent: SummaryCardData;
    @Input() supplierItems: any[];

    @Output() deleteTaxScheme: EventEmitter<number> = new EventEmitter();

    CONTACT_CATEGORY_NAME_CODE_MAP_PREFIX = 'tax Scheme';

    NOT_SPECIFIED = 'NOT SPECIFIED';

    constructor() {
    }

    ngOnInit() {
        // console.log(this.taxSchemeContent);
    }

    public getName(): string {
        if (!this.taxSchemeData.resourceType && !this.taxSchemeData.taxSchemeCode) {
            return 'New Tax Scheme';
        }else if (this.taxSchemeData.exempt){
            return 'New Tax Scheme';
        } else {
            let resourceName = this.supplierItems ? this.supplierItems.filter( item => item.code === this.taxSchemeData.resourceType)[0] : '';
            return `${this.taxSchemeData.resourceType ? (resourceName ? resourceName.name : this.taxSchemeData.resourceType) : ''}`;
        }
    }

    public getTaxScheme(code: any): string {
        if (this.isEditable && this.taxSchemeMap) {
            let selectedObj = this.taxSchemeMap.filter(obj => obj.name === code)[0];
            if (selectedObj) {
                return selectedObj.name;
            }
        } else if (!this.isEditable && this.nameCodeMap) {
            return this.nameCodeMap.get(`${this.CONTACT_CATEGORY_NAME_CODE_MAP_PREFIX}${code}`);
        } else {
            return '';
        }
    }

    public onDelete(event: any) {
        event.stopPropagation();
        this.deleteTaxScheme.emit(this.taxSchemeData.tempId);

    }

}
