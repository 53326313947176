import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataStore } from '@tc-core/util/framework/data-store.service';
import { CommonHelper } from '@tc-core/util/helpers/common-helper.service';
import { PaginatorModule } from '@tc/paginator';
import { ResultContentCardResourceAssignModule } from '../result-content-card-resource-assign/result-content-card-resource-assign.module';
import { AdvSupplierAssignSearchResultComponent } from './adv-supplier-assign-search-result.component';
import { DirectivesModule } from '@tc/directives';
import { PipesModule } from '@tc/pipes';
import { ContentStripModule } from '@tc/content-strip';
import { ExpandedContentCardModule } from '@tc/expanded-content-card';
import { SupplierAssignDetailViewModule } from '../supplier-assign-detail-view/supplier-assign-detail-view.module';
import { ResourceExpandedContentHeaderModule } from '../resource-expanded-content-header/resource-expanded-content-header.module';

@NgModule({
  imports: [
    CommonModule,
    DirectivesModule,
    PipesModule,
    ContentStripModule,
    ResultContentCardResourceAssignModule,
    ExpandedContentCardModule,
    ResourceExpandedContentHeaderModule,
    SupplierAssignDetailViewModule,
      PaginatorModule
  ],
  providers: [
    DataStore,
    CommonHelper
  ],
  declarations: [AdvSupplierAssignSearchResultComponent],
  exports: [AdvSupplierAssignSearchResultComponent],
})
export class AdvSupplierAssignSearchResultModule { }
