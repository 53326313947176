import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatMenuModule, MatTooltipModule } from '@angular/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule } from '@ngx-translate/core';
import { EmptyResultModule } from '@tc/empty-result';
import { PaginatorModule } from '@tc/paginator';
import { PipesModule } from '@tc/pipes';
import { ResultsHeaderModule } from '@tc/results-header';
import { SectionHeadingModule } from '@tc/section-heading';
import { CardStripModule } from '../../widgets/framework/card-strip';
import { ResourceAvailabilityService } from '../../services/backend-consumers/supplier-service/resource-availability.service';
import { LeaveSetupSearchResultsComponent } from './leave-setup-search-results.component';

@NgModule({
  declarations: [LeaveSetupSearchResultsComponent],
  imports: [
      CommonModule,
      CardStripModule,
      SectionHeadingModule,
      PipesModule,
      EmptyResultModule,
      PaginatorModule,
      MatMenuModule,
      TranslateModule,
      MatTooltipModule,
      ResultsHeaderModule,
      BrowserAnimationsModule
  ],
    providers: [ResourceAvailabilityService],
    exports: [LeaveSetupSearchResultsComponent],
    entryComponents: [LeaveSetupSearchResultsComponent]
})
export class LeaveSetupSearchResultsModule { }
