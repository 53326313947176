/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid.component.ngfactory";
import * as i3 from "../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid.component";
import * as i4 from "../../../services/util/pre-processors/grid-column-definition-processor.service";
import * as i5 from "@ngx-translate/core";
import * as i6 from "@tc/dialog/dialog.service";
import * as i7 from "../../../services/util/framework/data-store.service";
import * as i8 from "../../../services/util/common/dmc-common";
import * as i9 from "./document-detail-focus-view.component";
import * as i10 from "@tc-core/util/framework/event-manager.service";
import * as i11 from "@tc-core/util/framework/focus-view-manager.service";
import * as i12 from "@tc/focus-view/focus-view.service";
import * as i13 from "@tc-core/util/framework/config-loader.service";
import * as i14 from "../../../services/backend-consumers/documents/document.service";
import * as i15 from "@tc/modal/modal.service";
import * as i16 from "../../../services/backend-consumers/supplier-service/supplier.service";
import * as i17 from "@tc-core/util/ui/spinner.service";
var styles_DocumentDetailFocusViewComponent = [];
var RenderType_DocumentDetailFocusViewComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DocumentDetailFocusViewComponent, data: {} });
export { RenderType_DocumentDetailFocusViewComponent as RenderType_DocumentDetailFocusViewComponent };
function View_DocumentDetailFocusViewComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" DISP_", " "]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.id; _ck(_v, 1, 0, currVal_0); }); }
function View_DocumentDetailFocusViewComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_DocumentDetailFocusViewComponent_2)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = ((_v.context.$implicit == null) ? null : _v.context.$implicit.id); _ck(_v, 1, 0, currVal_0); }, null); }
function View_DocumentDetailFocusViewComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "div", [["class", "tc-w-100 text-center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "span", [["class", ""]], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, [" ", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.dispatchStatus; _ck(_v, 4, 0, currVal_0); }); }
function View_DocumentDetailFocusViewComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_DocumentDetailFocusViewComponent_4)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = ((_v.context.$implicit == null) ? null : _v.context.$implicit.dispatchStatus); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_DocumentDetailFocusViewComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { setupGrid: 0 }), i0.ɵqud(402653184, 2, { statusCell: 0 }), i0.ɵqud(402653184, 3, { dispatchIdCell: 0 }), (_l()(), i0.ɵeld(3, 0, null, null, 3, "div", [["class", "trade-client-detail-view"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 2, "div", [["class", ""]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 1, "tc-setup-grid", [], null, [[null, "customAddClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("customAddClick" === en)) {
        var pd_0 = (_co.onAddDispatchEntryClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_SetupGridComponent_0, i2.RenderType_SetupGridComponent)), i0.ɵdid(6, 638976, [[1, 4], ["dispatchViewGrid", 4]], 0, i3.SetupGridComponent, [i0.ChangeDetectorRef, i4.GridColumnDefinitionProcessorService, i5.TranslateService, i6.DialogService, i7.DataStoreService, i8.DMCCommon], { gridHeader: [0, "gridHeader"], readOnly: [1, "readOnly"], enableCustomAdd: [2, "enableCustomAdd"], actions: [3, "actions"], noSelection: [4, "noSelection"], dataSource: [5, "dataSource"], setupGridComp: [6, "setupGridComp"], colDefConfig: [7, "colDefConfig"] }, { customAddClick: "customAddClick" }), (_l()(), i0.ɵand(0, [[3, 2], ["dispatchIdCell", 2]], null, 0, null, View_DocumentDetailFocusViewComponent_1)), (_l()(), i0.ɵand(0, [[2, 2], ["statusCell", 2]], null, 0, null, View_DocumentDetailFocusViewComponent_3))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Dispatch Entries"; var currVal_1 = true; var currVal_2 = true; var currVal_3 = _co.gridActions; var currVal_4 = true; var currVal_5 = _co; var currVal_6 = _co; var currVal_7 = _co.colDefConfig; _ck(_v, 6, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }, null); }
export function View_DocumentDetailFocusViewComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-document-detail-focus-view", [], null, null, null, View_DocumentDetailFocusViewComponent_0, RenderType_DocumentDetailFocusViewComponent)), i0.ɵdid(1, 245760, null, 0, i9.DocumentDetailFocusViewComponent, [i10.EventManager, i11.FocusViewManager, i12.FocusViewService, i13.ConfigLoader, i7.DataStoreService, i14.DocumentService, i15.ModalService, i16.SupplierService, i17.SpinnerService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DocumentDetailFocusViewComponentNgFactory = i0.ɵccf("tc-document-detail-focus-view", i9.DocumentDetailFocusViewComponent, View_DocumentDetailFocusViewComponent_Host_0, { document: "document", defaultSupplierCode: "defaultSupplierCode" }, {}, []);
export { DocumentDetailFocusViewComponentNgFactory as DocumentDetailFocusViewComponentNgFactory };
