import { AbstractSearchCriteria } from './abstract-search-criteria';

export class ResourceTypeSearchCriteria extends AbstractSearchCriteria {
    code: string = '';
    name: string = '';
    codeOrName: string = '';
    categoryCode: string = '';
}

export enum Category {
    TRANSPORT = 'transport',
    GENERIC = 'generic'
}
