export class ResourceCostSetup {
    tempCostId: number;
    resourceCostId: number;
    supplierId: number;
    tempSeasonId: number;
    seasonName: any;
    currency: any;
    dayValues: string;
    dayArray: any;
    dayKeys: string;
    costingType: any;
    adultFare: number;
    childFare: number;
    infantFare: number;
    teenFare: number;
    unitFare: number;
    single: number;
    twin: number;
    extraBed: number;
    isEdit: boolean;
    isNew: boolean;
    isInvalid: boolean;
}
