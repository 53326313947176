import { AfterViewInit, ChangeDetectorRef, OnInit } from '@angular/core';
import { TcErrorType } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-error';
import { DialogModel, LEVEL } from '@tc-core/model/it/codegen/ui/framework/dialog-model';
import { ModalData } from '@tc-core/model/it/codegen/ui/framework/modal-data';
import { TC } from '@tc-core/util/constants';
import { ConfigLoader } from '@tc-core/util/framework/config-loader.service';
import { EventManager } from '@tc-core/util/framework/event-manager.service';
import { UserJourneyManager } from '@tc-core/util/framework/user-journey-manager.service';
import { SpinnerService } from '@tc-core/util/ui/spinner.service';
import { DialogService } from '@tc/dialog/dialog.service';
import { FocusViewService } from '@tc/focus-view/focus-view.service';
import { ModalService } from '@tc/modal/modal.service';
import { Subscription } from 'rxjs';
import { AutoCompleteEditorComponent } from '../../../../widgets/framework/ag-grid-custom-components/editors/auto-complete-editor/auto-complete-editor.component';
import { DateEditorComponent } from '../../../../widgets/framework/ag-grid-custom-components/editors/date-editor/date-editor.component';
import { DropdownEditorComponent } from '../../../../widgets/framework/ag-grid-custom-components/editors/dropdown-editor/dropdown-editor.component';
import { MultiSelectorEditorComponent } from '../../../../widgets/framework/ag-grid-custom-components/editors/multi-selector-editor/multi-selector-editor.component';
import { RadioButtonEditorComponent } from '../../../../widgets/framework/ag-grid-custom-components/editors/radio-button-editor/radio-button-editor.component';
import { ActionsComponent } from '../../../../widgets/framework/ag-grid-custom-components/renderers/actions/actions.component';
import { CheckBoxRendererEditorComponent } from '../../../../widgets/framework/ag-grid-custom-components/renderers/check-box-selector-renderer/check-box-renderer-editor.component';
import { CheckBoxSelectorComponent } from '../../../../widgets/framework/ag-grid-custom-components/renderers/check-box-selector/check-box-selector.component';
import { TemplateRendererComponent } from '../../../../widgets/framework/ag-grid-custom-components/renderers/template-renderer/template-renderer.component';
import { TCO } from '../../../../constants';
import { FileUploaderComponent } from '../../../../widgets/framework/file-uploader/file-uploader.component';
import { DateRange } from '../../../../widgets/framework/cg-calendar/date-range';
import { RangeGroup } from '../../../../widgets/framework/cg-calendar/range-group';
import { SeasonSetupSummaryComponent } from '../../../../widgets/framework/cg-season-calendar/season-setup-summary/season-setup-summary.component';
import { DocumentCreationCriteria, DocumentFormat, DocumentType } from '../../../../models/criteria/document-creation-criteria';
import { DocumentDataCriteria } from '../../../../models/criteria/document-data-criteria';
import { TrsResourceCostSetup } from '../../../../models/supplier/trs-resource-cost-setup';
import { DocumentService } from '../../../../services/backend-consumers/documents/document.service';
import { DataHandlerService } from '../../../../services/backend-consumers/master-data-handler-service/data-handler.service';
import { SupplierService } from '../../../../services/backend-consumers/supplier-service/supplier.service';
import { DMCCommon } from '../../../../services/util/common/dmc-common';
import { DataKey, DataStoreService } from '../../../../services/util/framework/data-store.service';
import { GridColumnDefinitionProcessorService } from '../../../../services/util/pre-processors/grid-column-definition-processor.service';
import { ResponseUtil } from '../../../../services/util/response/response-util.service';
import { CardDataHandlerService } from '../../../../services/util/ui/card-data-handler-service.service';
import { ContentSummaryCardService } from '../../../../services/util/ui/content-summary-card.service';
var EMPTY_CHAR = TC.FORMAT.EMPTY_CHAR;
var TransportCostSetupQueueFocusViewComponent = /** @class */ (function () {
    function TransportCostSetupQueueFocusViewComponent(em, configLoader, journeyManager, dataStore, focusViewService, summaryCardService, dataHandlerService, spinnerService, dialogService, cardDataHandlerService, modalService, cd, documentService, columnDefinitionProcessorService, common, supplierService) {
        this.em = em;
        this.configLoader = configLoader;
        this.journeyManager = journeyManager;
        this.dataStore = dataStore;
        this.focusViewService = focusViewService;
        this.summaryCardService = summaryCardService;
        this.dataHandlerService = dataHandlerService;
        this.spinnerService = spinnerService;
        this.dialogService = dialogService;
        this.cardDataHandlerService = cardDataHandlerService;
        this.modalService = modalService;
        this.cd = cd;
        this.documentService = documentService;
        this.columnDefinitionProcessorService = columnDefinitionProcessorService;
        this.common = common;
        this.supplierService = supplierService;
        this.supplierCostSetup = [];
        this.resourceCosts = [];
        this.selectedResourceTypes = [];
        this.vehicleModels = [];
        this.currencyList = [];
        this.costingTypeList = [];
        this.daysOfWeek = [];
        this.routes = [];
        this.transferModes = [];
        this.rangeGroupsForSeasonCalendar = [];
        this.allDayKeys = '';
        this.seasons = [];
        this.rowHeight = 42;
        this.columnDefs = [];
        this._rowClassRules = {};
        this.overlayNoRowsTemplate = "<span class=\"ag-overlay-no-rows-center\">No items</span>";
        this.frameworkComponents = {
            'templateRenderer': TemplateRendererComponent,
            'checkBoxRendererEditor': CheckBoxRendererEditorComponent,
            'checkBoxSelector': CheckBoxSelectorComponent,
            'dropDownEditor': DropdownEditorComponent,
            'multiSelectorEditor': MultiSelectorEditorComponent,
            'radioButtonEditor': RadioButtonEditorComponent,
            'autoCompleteEditor': AutoCompleteEditorComponent,
            'dateEditor': DateEditorComponent,
            'actionRenderer': ActionsComponent
        };
        this.CARD_CONFIG_GENERIC_EDIT = 'supplier_edit';
        this.allowedVehicleModels = [];
        //get cost sheet from file uploader popup
        this.trsCostSheetImportSubscribe = new Subscription();
        //upload file to supplier service and subscribe for results
        this.trsCostSheetUploadSubscribe = new Subscription();
        //supplier search by Id to get updated costs
        this.supplierDataSubscribe = new Subscription();
        this.templateName = 'SUPPLIER_TRAN_COST_SHEET';
        this.getRoutes = function (params, row, text) {
            var filterValue = text ? text.toLowerCase() : '';
            return this.routes.filter(function (value) { return value.code && value.code.toLowerCase().indexOf(filterValue) > -1; });
        };
        this.getTransferModes = function (params, rowData, text) {
            var filterValue = text ? text.toLowerCase() : '';
            return this.transferModes.filter(function (value) { return value.name && value.name.toLowerCase().indexOf(filterValue) > -1; });
        };
        this.getVehicleModels = function (params, row, text) {
            var filterValue = text ? text.toLowerCase() : '';
            var filteredModels = this.vehicleModels.filter(function (value) { return value.transferMode === null ||
                (row.transferMode && value.transferMode && value.transferMode.code === row.transferMode.code); });
            return filteredModels.filter(function (value) { return value.name && value.name.toLowerCase().indexOf(filterValue) > -1; });
        };
        this.getCurrencies = function (params, rowData, text) {
            var filterValue = text ? text.toLowerCase() : '';
            return this.currencyList.filter(function (value) { return value.name && value.name.toLowerCase().indexOf(filterValue) > -1; });
        };
        this.getCostingTypes = function (params, rowData, text) {
            if (this.costingTypeList && Array.isArray(this.costingTypeList)) {
                var filterValue_1 = text ? text.toLowerCase() : '';
                return this.costingTypeList.filter(function (value) { return value.name && value.name.toLowerCase().indexOf(filterValue_1) > -1; });
            }
            return null;
        };
        this.getSeasons = function (params, row, text) {
            var filterValue = '';
            if (text) {
                filterValue = text.toLowerCase();
            }
            return this.seasons.filter(function (value) { return value.name.toLowerCase().indexOf(filterValue) > -1; });
        };
        // used in ag grid col def by method reference
        this.onDeleteCost = function (params) {
            this.onRemoveRow(params);
        };
        this.getTrsModeName = function (params) {
            return params.data.transferMode && params.data.transferMode.name ? params.data.transferMode.name : params.data.transferMode;
        };
        this.getRoute = function (params) {
            return params.data.route && params.data.route.code ? params.data.route.code : params.data.route;
        };
        this.getVehicleModel = function (params) {
            var groupText = EMPTY_CHAR;
            if (params && params.data && params.data.vehicleModel) {
                if (params.data.vehicleModel.name) {
                    groupText = params.data.vehicleModel.name;
                }
                else {
                    groupText = params.data.vehicleModel;
                }
            }
            return groupText;
        };
        this.setVehicleModel = function (params) {
            if (params && params.data && params.newValue) {
                params.data.vehicleModel = params.newValue;
            }
        };
        this.getSeasonName = function (params) {
            return params.data.seasonName && params.data.seasonName.name ? params.data.seasonName.name : params.data.seasonName;
        };
        this.setSeasonName = function (params) {
            if (params && params.data && params.newValue) {
                params.data.seasonName = params.newValue.name;
                params.data.tempSeasonId = params.newValue.code;
            }
        };
        this.getCurrency = function (params) {
            return params.data.currency ? params.data.currency : '';
        };
        this.setCurrency = function (params) {
            if (params && params.data && params.newValue) {
                params.data.currency = params.newValue.code;
            }
        };
        this.getCostingType = function (params) {
            var groupText = EMPTY_CHAR;
            if (params && params.data && params.data.costingType) {
                if (params.data.costingType.name) {
                    groupText = params.data.costingType.name;
                }
                else {
                    groupText = params.data.costingType;
                }
            }
            return groupText;
        };
        this.setCostingType = function (params) {
            if (params && params.data && params.newValue) {
                params.data.costingType = params.newValue;
            }
        };
        this.checkBoxOnClick = function (params) {
            this.onCellValueChange(params);
            console.log(params.value);
        };
        this.CurrencyCellRenderer = function (params) {
            params.data.currency = params.data.currency ? params.data.currency : 'AED';
            var inrFormat = new Intl.NumberFormat('en-Us', {
                style: 'currency',
                currency: params.data.currency.code ? params.data.currency.code : params.data.currency,
                minimumFractionDigits: 2
            });
            return inrFormat.format(params.value).replace(/[a-z]{3}/i, '').trim();
        };
        this.displaySeasonName = function (params) {
            return params ? params.seasonName : '';
        };
        this.isTypeCostEditable = function (params) {
            return (params.node.data.costingType && params.node.data.costingType.code !== 'PP');
        };
        this.isPerPersonCost = function (params) {
            return (params.node.data.costingType && params.node.data.costingType.code === 'PP');
        };
        this.defaultColDef = {
            filter: false,
            sortable: false,
            resizable: true
        };
        this.gridOptions = {
            rowSelection: true,
            cacheBlockSize: 20,
            maxBlocksInCache: 2,
            enableServerSideFilter: false,
            enableServerSideSorting: false,
            rowModelType: 'infinite',
            pagination: true,
            paginationPageSize: 10
        };
        this.rowClassRules = {
            'tc-ag-grid-row--error': function (params) { return params.data.isInvalid; }
        };
    }
    TransportCostSetupQueueFocusViewComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.initPageSectionReferences();
        this.getAllDayKeys();
        this.mapToSeasonCalendarRanges();
        this.colDefConfig = this.configLoader.configurations.get(TCO.CONF.CONF_TRANSPORT_COST_SETUP_GRID_CONFIG);
        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'CLOSE_FOCUS_VIEW').subscribe(function (e) {
            _this.dataStore.set(DataKey.supplierCostFocusViewClose, null);
            _this.dataStore.set(DataKey.supplierCostFocusViewClose, true);
            _this.saveResourceCostData();
            _this.focusViewService.close(true);
            _this.journeyManager.goForKey('GO_TO_ADD_TRANSFER_SUPPLIER');
        });
        // **** set genericGroupResults to grid*** //
        if (this.supplierCostSetup !== null && this.supplierCostSetup.length > 0) {
            this.supplierId = this.supplierCostSetup[0].supplierId;
            for (var i = 0; i < this.supplierCostSetup.length; i++) {
                this.seasons.push({
                    'code': this.supplierCostSetup[i].tempSeasonId,
                    'name': this.supplierCostSetup[i].name
                });
            }
        }
        this.setGridData();
    };
    TransportCostSetupQueueFocusViewComponent.prototype.initPageSectionReferences = function () {
        this.pageSectionsMap = this.cardDataHandlerService.getAllPageSections(this.CARD_CONFIG_GENERIC_EDIT, this.resourceCosts);
        this.costAddSection = this.pageSectionsMap.get('supplier_generic_cost_setup');
    };
    TransportCostSetupQueueFocusViewComponent.prototype.onHeaderActionClick = function (event) {
        if (event.id === 'TRIGGER_SHOW_SEASON_SUMMARY') {
            this.showSeasonSummary(event);
        }
        else if (event.id === 'TRIGGER_ADD_COST') {
            this.addRow();
        }
        else if (event.id === 'TRIGGER_SAVE_COST_DATA') {
            this.saveResourceCostData();
        }
        else if (event.id === 'TRIGGER_EXPORT_FILE') {
            this.exportCostData();
        }
        else if (event.id === 'TRIGGER_IMPORT_FILE') {
            this.importCostData();
        }
    };
    TransportCostSetupQueueFocusViewComponent.prototype.addRow = function () {
        if (this.seasons.length > 0) {
            var resourceCost = new TrsResourceCostSetup();
            resourceCost.tempCostId = this.getNextTempId();
            resourceCost.currency = 'AED';
            resourceCost.unitFare = 0;
            resourceCost.adultFare = 0;
            resourceCost.childFare = 0;
            resourceCost.infantFare = 0;
            resourceCost.isInvalid = true;
            resourceCost.dayArray = this.daysOfWeek;
            this.mapDayKeys(resourceCost);
            this.resourceCosts.unshift(resourceCost);
            this.gridApi.updateRowData({
                add: [resourceCost],
                addIndex: 0
            });
            this.validateUniqueValues(resourceCost);
        }
        else {
            var confirmError = new DialogModel(true, LEVEL.ERROR, 'Failed', 'Please add seasons', true, 2000);
            this.dialogService
                .confirm(confirmError)
                .subscribe(function (res) {
                if (res === true) {
                    console.log('true');
                }
            });
        }
    };
    //get all keys of whole week
    TransportCostSetupQueueFocusViewComponent.prototype.getAllDayKeys = function () {
        var _this = this;
        this.daysOfWeek.forEach(function (data) { _this.allDayKeys += data.key; });
    };
    TransportCostSetupQueueFocusViewComponent.prototype.getNextTempId = function () {
        var maxId = 0;
        for (var j = 0; j < this.resourceCosts.length; j++) {
            var resourceCost = this.resourceCosts[j];
            var tempId = resourceCost.tempCostId;
            if (tempId > maxId) {
                maxId = tempId;
            }
        }
        maxId++;
        return maxId;
    };
    TransportCostSetupQueueFocusViewComponent.prototype.saveResourceCostData = function () {
        this.addDataFromGrid(this.gridApi, this.resourceCosts);
        this.dataStore.set(DataKey.trsResourceCosts, null, true);
        this.isInvalidCost = !!(this.resourceCosts && this.resourceCosts.length > 0 &&
            this.resourceCosts.find(function (value) { return value.isInvalid; }));
        this.dataStore.set(DataKey.supplierTrsCostSetupValid, !this.isInvalidCost, true);
        this.dataStore.set(DataKey.trsResourceCosts, this.resourceCosts, true);
    };
    TransportCostSetupQueueFocusViewComponent.prototype.addDataFromGrid = function (gridApi, list) {
        list.length = 0;
        gridApi.forEachNode(function (node) {
            list.push(node.data);
        });
    };
    TransportCostSetupQueueFocusViewComponent.prototype.mapToSeasonCalendarRanges = function () {
        // this.rangeGroupsForSeasonCalendar = [];
        for (var i = 0; i < this.supplierCostSetup.length; i++) {
            var season = this.supplierCostSetup[i];
            var rangeGroup = new RangeGroup();
            rangeGroup.id = season.tempSeasonId;
            rangeGroup.name = season.name;
            rangeGroup.colorId = season.colorId;
            var dateRanges = [];
            for (var j = 0; j < season.seasonDateRanges.length; j++) {
                var seasonDateRange = season.seasonDateRanges[j];
                var dateRange = new DateRange();
                dateRange.id = seasonDateRange.dateRangeId;
                dateRange.startDate = new Date(seasonDateRange.startDate);
                dateRange.endDate = new Date(seasonDateRange.endDate);
                dateRanges.push(dateRange);
            }
            rangeGroup.dateRanges = dateRanges;
            this.rangeGroupsForSeasonCalendar.push(rangeGroup);
        }
    };
    TransportCostSetupQueueFocusViewComponent.prototype.showSeasonSummary = function (event) {
        var dataObject = new ModalData(LEVEL.SUCCESS, 'Season Setup Summary', null, { 'label': 'CLOSE' }, 'modal-basic--fixed-size modal-basic--show-footer', SeasonSetupSummaryComponent, { rangeGroups: this.rangeGroupsForSeasonCalendar });
        this.modalService
            .confirm(dataObject).subscribe(function (res) {
            if (res) {
            }
        });
    };
    // *********************ag grid implementation************************** //
    TransportCostSetupQueueFocusViewComponent.prototype.setGridData = function () {
        var _this = this;
        if (this.gridApi) {
            this.gridApi.setRowData(this.resourceCosts);
            // this.gridApi.sizeColumnsToFit();
        }
        else {
            setTimeout(function () { return _this.setGridData(); }, 0);
        }
    };
    TransportCostSetupQueueFocusViewComponent.prototype.onGridReady = function (params) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.gridApi.setFloatingFiltersHeight(38);
        this.gridApi.setHeaderHeight(40);
        this.gridApi.setGroupHeaderHeight(40);
        this.autoSizeAll();
        this.gridApi.refreshCells(params);
        // this.gridApi.sizeColumnsToFit();
    };
    TransportCostSetupQueueFocusViewComponent.prototype.ngAfterViewInit = function () {
        this.setColumnDefs();
    };
    TransportCostSetupQueueFocusViewComponent.prototype.setColumnDefs = function () {
        var colDefConfig = JSON.parse(JSON.stringify(this.colDefConfig));
        this.columnDefs = this.columnDefinitionProcessorService.createColumnDefs(colDefConfig, this);
        this.cd.detectChanges();
    };
    TransportCostSetupQueueFocusViewComponent.prototype.autoSizeAll = function () {
        var allColumnIds = [];
        if (this.gridColumnApi) {
            this.gridColumnApi.getAllColumns().forEach(function (column) {
                allColumnIds.push(column.colId);
            });
            this.gridColumnApi.autoSizeColumns(allColumnIds);
        }
    };
    TransportCostSetupQueueFocusViewComponent.prototype.onCellValueChange = function (params) {
        var colId = params.column.getId();
        if (colId === 'transferMode') {
            var selectedModels = params.data.vehicleModel;
            this.allowedVehicleModels = params.data.transferMode ? params.data.transferMode.vehicleModels : selectedModels;
            var profMismatch;
            if (selectedModels && this.allowedVehicleModels) {
                profMismatch = this.allowedVehicleModels.indexOf(selectedModels) < 0 || selectedModels.code === 'ANY';
            }
            if (profMismatch || !selectedModels || !this.allowedVehicleModels) {
                params.node.setDataValue('vehicleModel', null);
            }
            // this.gridApi.getSelectedRows()[0].resourceType = this.gridApi.getSelectedRows()[0].resourceType.code ? this.gridApi.getSelectedRows()[0].resourceType.code : this.getCode(this.selectedResourceTypes, this.gridApi.getSelectedRows()[0].resourceType);
            this.validateUniqueValues(this.gridApi.getSelectedRows()[0]);
        }
        else if (colId === 'vehicleModel') {
            this.validateUniqueValues(this.gridApi.getSelectedRows()[0]);
        }
        else if (colId === 'seasonName') {
            this.gridApi.getSelectedRows()[0].tempSeasonId = this.gridApi.getSelectedRows()[0].seasonName.code;
            // this.gridApi.getSelectedRows()[0].seasonName = this.gridApi.getSelectedRows()[0].seasonName;
            this.validateUniqueValues(this.gridApi.getSelectedRows()[0]);
        }
        else if (colId.slice(0, -2) === 'dayArray' || colId === 'dayArray') {
            var dayValues = this.gridApi.getSelectedRows()[0].dayArray;
            var days_1 = [];
            dayValues.forEach(function (day) {
                days_1.push(day.day ? day.day : day.value);
            });
            var dayKeys_1 = '';
            this.daysOfWeek.forEach(function (data) {
                if (days_1.includes(data.value)) {
                    dayKeys_1 += data.key;
                }
            });
            this.gridApi.getSelectedRows()[0].dayKeys = dayKeys_1;
            this.validateUniqueValues(this.gridApi.getSelectedRows()[0]);
        }
        else if (colId === 'dayValues') {
            var dayValues_1 = this.gridApi.getSelectedRows()[0].dayValues;
            var dayKeys_2 = '';
            this.daysOfWeek.forEach(function (data) {
                if (dayValues_1.includes(data.value)) {
                    dayKeys_2 += data.key;
                }
            });
            this.gridApi.getSelectedRows()[0].dayKeys = dayKeys_2;
            this.validateUniqueValues(this.gridApi.getSelectedRows()[0]);
        }
        else if (colId === 'costingType') {
            this.gridApi.getSelectedRows()[0].unitFare = 0;
            this.gridApi.getSelectedRows()[0].adultFare = 0;
            this.gridApi.getSelectedRows()[0].childFare = 0;
            this.gridApi.getSelectedRows()[0].infantFare = 0;
            this.validateUniqueValues(this.gridApi.getSelectedRows()[0]);
        }
        else {
            this.validateUniqueValues(this.gridApi.getSelectedRows()[0]);
        }
        // set warning to missing cost fields
        var selectedResourceCost = this.gridApi.getSelectedRows()[0];
        this.isCostMissing = selectedResourceCost && !selectedResourceCost.unitFare && !selectedResourceCost.adultFare;
        this.gridApi.refreshCells(params);
    };
    TransportCostSetupQueueFocusViewComponent.prototype.validateUniqueValues = function (selectedResourceCost) {
        selectedResourceCost = selectedResourceCost ? selectedResourceCost : this.gridApi.getSelectedRows()[0];
        if (selectedResourceCost && selectedResourceCost.transferMode && selectedResourceCost.tempSeasonId &&
            selectedResourceCost.route && selectedResourceCost.vehicleModel && selectedResourceCost.dayKeys) {
            for (var i = 0; i < this.resourceCosts.length; i++) {
                if (this.resourceCosts[i].tempCostId !== selectedResourceCost.tempCostId &&
                    this.resourceCosts[i].transferMode &&
                    this.resourceCosts[i].transferMode.code === selectedResourceCost.transferMode.code &&
                    this.resourceCosts[i].route &&
                    this.resourceCosts[i].route.code === selectedResourceCost.route.code &&
                    this.resourceCosts[i].vehicleModel &&
                    this.resourceCosts[i].vehicleModel.code === selectedResourceCost.vehicleModel.code &&
                    this.resourceCosts[i].tempSeasonId === selectedResourceCost.tempSeasonId &&
                    this.anythingInCommon(this.resourceCosts[i].dayKeys, selectedResourceCost.dayKeys)) {
                    this.isInvalidCost = true;
                    selectedResourceCost.isInvalid = true;
                    break;
                }
                else {
                    this.isInvalidCost = false;
                    selectedResourceCost.isInvalid = false;
                }
            }
            this.gridApi.getSelectedRows()[0].isInvalid = this.isInvalidCost;
        }
        else if (selectedResourceCost) {
            this.isInvalidCost = true;
            selectedResourceCost.isInvalid = true;
        }
        else {
            this.isInvalidCost = true;
        }
        this.gridApi.setRowData(this.resourceCosts);
        // this.dataStore.set(DataKey.supplierGenCostSetupValid, !this.isInvalidCost, true);
        console.log(this.isInvalidCost);
    };
    TransportCostSetupQueueFocusViewComponent.prototype.anythingInCommon = function (a, b) {
        if (b.length < a.length) {
            return this.anythingInCommon(b, a);
        }
        for (var i = 0, len = a.length; i < len; i++) {
            if (b.indexOf(a[i]) != -1) {
                return true;
            }
        }
        return false;
    };
    TransportCostSetupQueueFocusViewComponent.prototype.getCode = function (dataList, name) {
        return dataList.filter(function (val) { return val.name === name; })[0].code;
    };
    TransportCostSetupQueueFocusViewComponent.prototype.onKeyDown = function ($event) {
    };
    TransportCostSetupQueueFocusViewComponent.prototype.onGridSizeChange = function () {
        this.fixGridColumnSizes();
    };
    TransportCostSetupQueueFocusViewComponent.prototype.fixGridColumnSizes = function () {
        // this.gridApi.sizeColumnsToFit();
    };
    TransportCostSetupQueueFocusViewComponent.prototype.onRowDoubleClicked = function (event) {
        console.log('Row clicked');
    };
    TransportCostSetupQueueFocusViewComponent.prototype.onRemoveRow = function (params) {
        var _this = this;
        if (!params.data.isInvalid) {
            var confirmDataObject = new DialogModel(true, LEVEL.WARNING, 'Delete', 'This Cost will be deleted. Do you wish to proceed?', true, 3000);
            this.dialogService.confirm(confirmDataObject).subscribe(function (res) {
                if (res === true) {
                    _this.removeSelectedData(params);
                }
            });
        }
        else {
            this.removeSelectedData(params);
        }
        /*this.gridApi ?
         this.gridApi.setRowData(this.resourceCosts) :
         params.api.setRowData(this.resourceCosts);*/
    };
    TransportCostSetupQueueFocusViewComponent.prototype.removeSelectedData = function (params) {
        for (var i = 0; i < this.resourceCosts.length; i++) {
            if (this.resourceCosts[i].tempCostId === params.data.tempCostId) {
                this.resourceCosts.splice(i, 1);
                break;
            }
        }
        this.gridApi.setRowData(this.resourceCosts);
    };
    TransportCostSetupQueueFocusViewComponent.prototype.mapDayKeys = function (resourceCost) {
        var dayValues = resourceCost.dayArray;
        var days = [];
        dayValues.forEach(function (day) {
            days.push(day.day ? day.day : day.value);
        });
        var dayKeys = '';
        this.daysOfWeek.forEach(function (data) {
            if (days.includes(data.value)) {
                dayKeys += data.key;
            }
        });
        resourceCost.dayKeys = dayKeys;
    };
    TransportCostSetupQueueFocusViewComponent.prototype.exportCostData = function () {
        this.spinnerService.show();
        var documentCreationCriteria = new DocumentCreationCriteria();
        documentCreationCriteria.documentFormat = DocumentFormat.csv;
        documentCreationCriteria.documentType = DocumentType.SUPPLIER_TRAN_COST_SHEET;
        documentCreationCriteria.documentName = '';
        documentCreationCriteria.download = true;
        documentCreationCriteria.persist = true;
        var documentDataCriteria = new DocumentDataCriteria();
        documentDataCriteria.supplierId = this.supplierId;
        this.documentService.createAndDownloadDocument(documentCreationCriteria, documentDataCriteria);
    };
    TransportCostSetupQueueFocusViewComponent.prototype.importCostData = function () {
        var _this = this;
        this.dataStore.set(DataKey.popupClose, null);
        this.dataStore.set(DataKey.fileToUpload, null);
        var dataObject = new ModalData(LEVEL.SUCCESS, 'Upload Cost Sheet', true, true, 'split-passengers-modal', FileUploaderComponent, {});
        dataObject.disableClose = true;
        this.confirmModal(dataObject);
        var x = this.dataStore.get(DataKey.popupClose).subscribe(function (data) {
            if (data != null) {
                _this.closeModal();
            }
        });
        this.trsCostSheetImportSubscribe = this.dataStore.get(DataKey.fileToUpload).subscribe(function (data) {
            if (data != null) {
                _this.uploadFile(data);
                _this.trsCostSheetImportSubscribe.unsubscribe();
            }
        });
    };
    TransportCostSetupQueueFocusViewComponent.prototype.uploadFile = function (fileToUpload) {
        var _this = this;
        var selectedResourceTypes = '';
        this.selectedResourceTypes.forEach(function (o, i) {
            selectedResourceTypes = selectedResourceTypes + o.name;
            if (i != _this.selectedResourceTypes.length - 1) {
                selectedResourceTypes = selectedResourceTypes + ',';
            }
        });
        this.trsCostSheetUploadSubscribe = this.supplierService.uploadCostSheet(fileToUpload, this.templateName, this.supplierId, selectedResourceTypes).subscribe(function (data) {
            if (data && data.body) {
                _this.trsCostSheetUploadSubscribe.unsubscribe();
                if (_this.gridApi) {
                    _this.gridApi.showLoadingOverlay();
                    _this.getSupplierById();
                }
                _this.common.showSnackBar('Successfully uploaded', 3000, TcErrorType.TYPE.INFO);
            }
        }, function (error) {
            _this.common.showSnackBar('Fail to upload', 3000, TcErrorType.TYPE.ERROR);
        });
    };
    TransportCostSetupQueueFocusViewComponent.prototype.confirmModal = function (data) {
        this.modalService.confirm(data).subscribe(function (res) {
        });
    };
    TransportCostSetupQueueFocusViewComponent.prototype.closeModal = function () {
        if (this.modalService.close()) {
            this.modalService.close().subscribe(function (res) {
            });
        }
    };
    TransportCostSetupQueueFocusViewComponent.prototype.getSupplierById = function () {
        this.supplierService.retrieveSupplierById(this.supplierId);
        this.subscribeSupplierData();
    };
    TransportCostSetupQueueFocusViewComponent.prototype.subscribeSupplierData = function () {
        var _this = this;
        this.supplierDataSubscribe = this.dataStore.get(DataKey.supplier).subscribe(function (result) {
            if (result != null) {
                _this.resourceCosts = [];
                var supplier = ResponseUtil.getFirstData(result);
                _this.resourceCostData = supplier.seasons;
                _this.getResourceCostData();
                _this.supplierDataSubscribe.unsubscribe();
            }
        });
    };
    TransportCostSetupQueueFocusViewComponent.prototype.getResourceCostData = function () {
        if (this.resourceCostData.length > 0) {
            // setting temp ids to seasons
            this.setTempIdsToSeasons();
            var tempCostId = 0;
            for (var i = 0; i < this.resourceCostData.length; i++) {
                if (this.resourceCostData[i].trsResourceCosts.length > 0) {
                    for (var j = 0; j < this.resourceCostData[i].trsResourceCosts.length; j++) {
                        var days = this.getDays(this.resourceCostData[i].trsResourceCosts[j].days);
                        var daysArr = this.getDaysArr(this.resourceCostData[i].trsResourceCosts[j].days);
                        tempCostId++;
                        this.resourceCostData[i].trsResourceCosts[j].tempCostId = tempCostId;
                        var resourceCost = new TrsResourceCostSetup();
                        resourceCost.tempCostId = tempCostId;
                        resourceCost.transferMode = this.filterObject(this.resourceCostData[i].trsResourceCosts[j].transferMode, this.transferModes);
                        resourceCost.vehicleModel = this.filterObject(this.resourceCostData[i].trsResourceCosts[j].vehicleModel, this.vehicleModels);
                        resourceCost.route = this.filterObject(this.resourceCostData[i].trsResourceCosts[j].route, this.routes);
                        resourceCost.currency = this.resourceCostData[i].trsResourceCosts[j].currency;
                        resourceCost.costingType = this.filterObject(this.resourceCostData[i].trsResourceCosts[j].costingType, this.costingTypeList);
                        resourceCost.seasonName = this.resourceCostData[i].name;
                        resourceCost.tempSeasonId = this.resourceCostData[i].tempSeasonId;
                        resourceCost.unitFare = this.resourceCostData[i].trsResourceCosts[j].unitFare;
                        resourceCost.adultFare = this.resourceCostData[i].trsResourceCosts[j].adultFare;
                        resourceCost.teenFare = this.resourceCostData[i].trsResourceCosts[j].teenFare;
                        resourceCost.childFare = this.resourceCostData[i].trsResourceCosts[j].childFare;
                        resourceCost.infantFare = this.resourceCostData[i].trsResourceCosts[j].infantFare;
                        resourceCost.dayValues = days;
                        resourceCost.dayArray = daysArr;
                        resourceCost.dayKeys = this.resourceCostData[i].trsResourceCosts[j].days;
                        resourceCost.isEdit = false;
                        this.resourceCosts.push(resourceCost);
                    }
                }
            }
            this.gridApi.refreshCells();
            this.gridApi.hideOverlay();
        }
    };
    // this.dataStore.get(DataKey.resourceTypes);
    TransportCostSetupQueueFocusViewComponent.prototype.filterObject = function (code, objArray) {
        if (objArray && objArray.length > 0) {
            for (var i = 0; i < objArray.length; i++) {
                if (objArray[i]['code'] === code) {
                    return objArray[i];
                }
            }
            return null;
        }
        else {
            return code;
        }
    };
    TransportCostSetupQueueFocusViewComponent.prototype.setTempIdsToSeasons = function () {
        var initId = 1;
        for (var i = 0; i < this.resourceCostData.length; i++) {
            var season = this.resourceCostData[i];
            season.tempSeasonId = initId++;
        }
    };
    TransportCostSetupQueueFocusViewComponent.prototype.getDays = function (data) {
        var days = '';
        var _loop_1 = function (i) {
            var dayArray = this_1.daysOfWeek.find(function (day) { return day.key === data.charAt(i); }).value;
            days = days.concat(',' + dayArray);
        };
        var this_1 = this;
        for (var i = 0; i < data.length; i++) {
            _loop_1(i);
        }
        if (days.startsWith(',')) {
            days = days.replace(',', '');
        }
        return days;
    };
    TransportCostSetupQueueFocusViewComponent.prototype.getDaysArr = function (data) {
        var daysArr = [];
        var _loop_2 = function (i) {
            var dayArray = this_2.daysOfWeek.find(function (day) { return day.key === data.charAt(i); }).value;
            daysArr.push({ key: data.charAt(i), day: dayArray });
        };
        var this_2 = this;
        for (var i = 0; i < data.length; i++) {
            _loop_2(i);
        }
        return daysArr;
    };
    return TransportCostSetupQueueFocusViewComponent;
}());
export { TransportCostSetupQueueFocusViewComponent };
