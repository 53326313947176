<div class="container split-passengers-panel">
    <div class="container-fluid tc-card-wrapper">
        <!--        <div class="col-xs-12 col-md-3 split-passengers-input__container">-->
        <div class="row">
            <div class="col-md-12 col-lg-12">
                <div class="tc-flex-box">
                    <form [formGroup]="dispatchForm" class="rs-item-card__resource-form container-fluid">
                        <div class="tc-flex-item tc-right-cage">
                            <div class="row">
                                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 remove-bootstrap-padding-mobile">
                                    <div class="tc-input-edit dcpiec-input-lname">
                                        <mat-form-field>
                                            <input
                                                    matInput
                                                    id="DIR_PER_INFO_VEHICLE_SUPPLIER_NAME"
                                                    #supplierInput
                                                    formControlName="supplier"
                                                    [placeholder]="'Supplier'"
                                                    [matAutocomplete]="autoSV"
                                                    (focus)="onSupplierFocus($event)"
                                                    [readonly]="isDisableSupplierInput">
                                        </mat-form-field>
                                        <mat-autocomplete
                                            #autoSV="matAutocomplete"
                                            autoActiveFirstOption
                                            [displayWith]="displaySupplierFn">
                                            <mat-option
                                                    *ngFor="let supplier of supplierSummaries"
                                                    [value]="supplier"
                                                    (onSelectionChange)="onSelectSupplier(supplier)">
                                                <span>{{supplier?.name ? supplier.name : ''}}</span> |
                                                <small>{{supplier?.code ? supplier.code : ''}}</small>
                                            </mat-option>
                                        </mat-autocomplete>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 remove-bootstrap-padding-mobile">

                                    <div class="tc-input-edit dcpiec-input-lname">
                                        <mat-form-field>
                                            <input
                                                    matInput
                                                    formControlName="email"
                                                    [placeholder]="'Email'"
                                                    [readonly]="isDisableEmailInput"
                                                    required>
                                            <mat-error>{{getErrorMessage('email')}}
                                            </mat-error>
                                        </mat-form-field>
                                    </div>

                                </div>
                            </div>
                            <div class="row" [hidden]="isHiddenEmailSubject">
                                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 remove-bootstrap-padding-mobile">
                                    <div class="tc-select-edit dcpiec-select-title">
                                        <mat-form-field>
                                            <input
                                                    matInput
                                                    formControlName="emailSubject"
                                                    [placeholder]="'Email Subject'"
                                                    [readonly]="isHiddenEmailSubject"
                                                    >
                                            <mat-error>{{getErrorMessage('emailSubject')}}
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                            <div class="row" [hidden]="isHiddenEmailBody">
                                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 remove-bootstrap-padding-mobile">
                                    <div class="tc-select-edit dcpiec-select-title">
                                        <mat-form-field>
                                            <textarea
                                                    matInput
                                                    formControlName="emailBody"
                                                    [placeholder]="'Email Body'"
                                                    cdkTextareaAutosize
                                                    #autosize="cdkTextareaAutosize"
                                                    cdkAutosizeMinRows="1"
                                                    cdkAutosizeMaxRows="5"
                                                    [readonly]="isHiddenEmailBody"
                                                    >
                                            </textarea>
                                            <mat-error>{{getErrorMessage('emailBody')}}
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="tc-modal-footer">
    <button class="button-right tc-button-secondary mat-button" (click)="onClose()">
        {{'Close' | translate}}
    </button>
    <button class="button-right tc-button-primary mat-button" (click)="onOkClick()"
            [disabled]="isOkDisabled()">
        {{okBtnText | translate}}
    </button>
</div>
