import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TimelineEvent } from '../timeline-event';

@Component({
    selector: 'tc-timeline-unit',
    templateUrl: './timeline-unit.component.html'
})
export class TimelineUnitComponent {

    @Input() events: TimelineEvent[] = [];
    @Input() isTargetBlock = false;
    @Input() targetUnitIndex = 0;
    @Input() targetUnitCount = 0;
    @Input() targetMargin = 0.00;

    @Input() unitIndex = 0;
    @Input() unitSize = 0;
    @Input() unitWidth = 0;
    @Input() unitTime = '00:00';

    @Input() isTarget = false;

    @Output() eventClick: EventEmitter<TimelineEvent> = new EventEmitter();
    @Output() targetClick: EventEmitter<void> = new EventEmitter();

    constructor() { }

    onEventClick(event: TimelineEvent) {
        this.eventClick.emit(event);
    }

    onTargetClick(event) {
        event.stopPropagation();
        this.targetClick.emit();
    }
}
