<div class="product-queue">
    <div class="tc-ag-grid-accordion" [ngClass]="{'tc-ag-grid-accordion--expanded':expandPanelOpen}">
        <div id="header" class="tc-ag-grid-accordion__toggle tc-border-left-0 tc-border-right-0 tc-flex-item__top" (click)="onClickCollapseBtn()">
            <div class="tc-ag-grid-accordion__text tc-mt-1">{{numberOfProductItems}} {{'Products Found' | translate}}</div>

            <div class="tc-flex tc-flex-item__top">
                <div class="chip-list-wrapper">
                    <mat-chip-list class="" multiple>
                        <ng-container *ngFor="let item of productsSelectedFromGrid;let i = index; ">
                            <mat-chip
                                    class="chip-item scroll-item"
                                    *ngIf="i <= 4 && showAllChips == false"
                                    [selected]="item.selected"
                                    [value]="item"
                                    (click)="onChipClick(item)">
                                {{item.contractRef}}
                            </mat-chip>
                        </ng-container>

                        <ng-container *ngFor="let item of productsSelectedFromGrid;let i = index; ">
                            <mat-chip
                                    class="chip-item scroll-item"
                                    *ngIf="showAllChips == true"
                                    [selected]="item.selected"
                                    [value]="item"
                                    (click)="onChipClick(item)">
                                {{item.contractRef}}
                            </mat-chip>
                        </ng-container>
                        
                        <mat-chip  (click)="showAllChips = true"
                                   *ngIf="showAllChips == false && productsSelectedFromGrid.length > 5"
                                class="chip-item scroll-item tc-border"
                                [value]="null">
                            {{'+'+productsSelectedFromGrid.slice(5).length +' more'}}
                        </mat-chip>
                        <mat-chip  (click)="showAllChips = false"
                                   *ngIf="showAllChips == true"
                                   class="chip-item scroll-item tc-border"
                                   [value]="null">
                            {{'Show less'}}
                        </mat-chip>
                    </mat-chip-list>
                </div>
                <div class="tc-icon-block tc-flex tc-flex-item__top">
                    <button mat-icon-button aria-label="clear all" matTooltip="{{'Clear All' | translate}}"
                            [style.display]="hideClearButton ? 'none' : 'inline-block'"  (click)="onClearChipSelection();$event.stopPropagation()"
                            class="tc-icon-block__icon tc-icon-block__icon--active tc-mt-1n">
                        <mat-icon>clear_all</mat-icon>
                    </button>
                    <span class="tc-ml-3 tc-ag-grid-accordion__icon tc-icon-block__icon tc-icon-block__icon--active tc-mt-1">
                        <mat-icon>keyboard_arrow_down</mat-icon>
                    </span>
                </div>
            </div>
        </div>
        <div
                id="content"
                class="tc-ag-grid-accordion__content">

            <ag-grid-angular
                #agGrid
                id="productGrid"
                class="ag-theme-material tc-ag-grid tc-ag-grid--row-selection tc-ag-grid--full-width tc-ag-grid--auto-height tc-ag-grid--height-m tc-border-left-0 tc-border-right-0"
                [columnDefs]="columnDefs"
                [rowSelection]="'multiple'"
                [rowMultiSelectWithClick]="true"
                [rowData]="productItemsShowing"
                [getRowClass]="getRawClass"
                [defaultColDef]="defaultColDef"
                [colResizeDefault]="colResizeDefault"
                [isExternalFilterPresent]="isExternalFilterPresent"
                [doesExternalFilterPass]="doesExternalFilterPass"
                [overlayNoRowsTemplate]="overlayNoRowsTemplate"
                [overlayLoadingTemplate]="overlayLoadingTemplate"
                [enableColResize]="true"
                [suppressRowTransform]=true

                (gridSizeChanged)="onGridSizeChange()"
                (selectionChanged)="onSelectionChange($event)"
                (gridReady)="onGridReady($event)">
            </ag-grid-angular>
        </div>
    </div>

</div>
<!--<hr>-->
