<tc-setup-grid
    #jobsView
    [readOnly]="true"
    [colDefConfig]="colDefConfig"
    [setupGridComp]="this"
    [dataSource]="this"
    [noSelection]="true"
    [actions]="gridActions">
</tc-setup-grid>


<ng-template #statusCell let-row>
    <ng-container *ngIf="row?.status">
        {{displayStatus(row.status)}}
    </ng-container>
</ng-template>

<ng-template #latestHistoryStatusCell let-row>
    <ng-container *ngIf="row?.latestHistoryStatus">
        {{displayStatus(row.latestHistoryStatus)}}
    </ng-container>
</ng-template>

<ng-template #menuCell let-row>
    <div class="tc-icon-block">
        <button
                mat-icon-button
                [matMenuTriggerFor]="menu"
                aria-label="Service queue assignment menu"
                matTooltip="{{'More actions' | translate}}"
                class="tc-m-0 tc-icon-block__icon tc-icon-block__icon--active">
            <mat-icon>more_vert</mat-icon>
        </button>
    </div>
    <mat-menu #menu="matMenu">
        <button
                mat-menu-item
                [disabled]="this.disableHistoryButton(row)"
                (click)="onJobHistoryClick(row)">
            <mat-icon>visibility</mat-icon>
            <span>{{'View History' | translate}}</span>
        </button>
        <button
                mat-menu-item
                [disabled]="this.disableUnlockButton(row)"
                (click)="this.onUnlockClick(row)">
            <mat-icon>lock_open_rounded</mat-icon>
            <span>{{'Unlock' | translate}}</span>
        </button>
        <button *ngIf="userManagementService.notAvailableFor('FC')"
                mat-menu-item
                (click)="openJobParameterModal(row)">
            <mat-icon>device_hub</mat-icon>
            <span>{{'Job Parameter' | translate}}</span>
        </button>
    </mat-menu>
</ng-template>
