import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ThemePalette} from '@angular/material/core';
import {Subscription} from 'rxjs';
import {City, Country} from '../../../../../models/common/locale';
import {BoardBasisSetupSearchCriteria} from '../../../../../models/criteria/board-basis-setup-search-criteria';
import {RoomOccupancySearchCriteria} from '../../../../../models/criteria/room-occupancy-search-criteria';
import {RoomTypeSearchCriteria} from '../../../../../models/criteria/room-type-search-criteria';
import {SupplierSearchCriteria} from '../../../../../models/criteria/supplier-search-criteria';
import {BookingItem} from '../../../../../models/reservation-v2/booking-item';
import {BookingNoteType} from '../../../../../models/reservation-v2/booking-note';
import {BookingPassenger} from '../../../../../models/reservation-v2/booking-passenger';
import {GenBookingItem} from '../../../../../models/reservation-v2/gen-booking-item';
import {PassengerType} from '../../../../../models/reservation-v2/passenger-type';
import {ResourceType} from '../../../../../models/reservation/assignment';
import {SupplierSummary} from '../../../../../models/summary/supplier-summary';
import {RoomOccupancy} from '../../../../../models/supplier/room-occupancy';
import {
    MasterDataHandlerService
} from '../../../../../services/backend-consumers/master-data-handler-service/master-data-handler.service';
import {AccommodationService} from '../../../../../services/backend-consumers/setups/accommodation.service';
import {BoardBasisSetupService} from '../../../../../services/backend-consumers/setups/board-basis-setup.service';
import {ResourceTypeService} from '../../../../../services/backend-consumers/setups/resource-type.service';
import {SupplierService} from '../../../../../services/backend-consumers/supplier-service/supplier.service';
import {
    CountryStateCityService
} from '../../../../../services/business-utils/country-state-city/country-state-city.service';
import {ChangeDetectorService} from '../../../../../services/util/change-detector/change-detector.service';
import {ResponseUtil} from '../../../../../services/util/response/response-util.service';
import {CommonDataSearchCriteria} from "../../../../../models/criteria/common-data-search-criteria";
import {CommonDataType} from "../../../../../models/reservation-v2/common-data-type";

@Component({
    selector: 'tc-accom-item',
    templateUrl: './accom-item.component.html',
    styleUrls: ['./accom-item.component.scss']
})
export class AccomItemComponent implements OnInit, OnDestroy {

    @Input() readonly = false;
    @Input() bookingItem: BookingItem;
    @Input() noteTypes: BookingNoteType[] = [];
    @Input() sameTypeItems = [];
    @Input() allPassengerIds = [];
    @Input() allBookingPassengers: BookingPassenger[] = [];


    @Output() changeValidity: EventEmitter<boolean> = new EventEmitter();

    cityList: City[];
    countryList: Country[];

    genBookingItem: GenBookingItem;
    accomBookingItemForm: FormGroup;
    dateTimePickerColor: ThemePalette = 'primary';
    notesValid = true;
    today: any;
    startTime: any = null;
    endTime: any = null;

    sameStartDateItems: BookingItem[] = [];
    sameEndDateItems: BookingItem[] = [];
    sameDateTypeItems: BookingItem[] = [];

    suppliersFiltered: any[] = [];
    boardBasisFiltered: any[] = [];
    roomTypesFiltered: any[] = [];
    citiesFiltered: any[] = [];
    occupanciesFiltered: any[] = [];
    supplierSearchCriteria = new SupplierSearchCriteria();
    roomTypeSearchCriteria = new RoomTypeSearchCriteria();
    boardBasisSearchCriteria = new BoardBasisSetupSearchCriteria();

    changesSubscription: Subscription = new Subscription();
    defSupChangesSubscription: Subscription = new Subscription();
    roomTypeChangesSubscription: Subscription = new Subscription();
    boardBasisChangesSubscription: Subscription = new Subscription();
    occupancyChangesSubscription: Subscription = new Subscription();
    cityChangesSubscription: Subscription = new Subscription();
    adultCountChangeSubscription: Subscription = new Subscription();
    teenCountChangeSubscription: Subscription = new Subscription();
    childCountChangeSubscription: Subscription = new Subscription();
    infantCountChangeSubscription: Subscription = new Subscription();

    constructor(
        private fb: FormBuilder,
        private supplierService: SupplierService,
        private resourceTypeService: ResourceTypeService,
        private countryStateCityService: CountryStateCityService,
        private changeDetectorService: ChangeDetectorService,
        private boardBasisSetupService: BoardBasisSetupService,
        private accommodationService: AccommodationService,
        private masterDataHandlerService: MasterDataHandlerService
    ) {
    }

    ngOnInit() {
        // set default minimum datetime as now
        this.today = new Date();
        //for search suppliers
        this.supplierSearchCriteria.size = 10;
        this.supplierSearchCriteria.status = 'true';
        this.supplierSearchCriteria.resourceTypes = [];
        this.supplierSearchCriteria.resourceTypes.push(ResourceType.hotel);
        //for search room types
        this.roomTypeSearchCriteria.size = 10;
        //for search boar basis
        this.boardBasisSearchCriteria.size = 10;

        if (this.bookingItem && this.bookingItem.genBookingItem) {
            this.genBookingItem = this.bookingItem.genBookingItem;
        }
        this.createForm();

        this.validateForm();

        //check any sameDateType items in same
        this.getSameDateTypeItems();

        // if(this.bookingItem.passengerValidated) {
        //     this.onChangeCheckBox(true);
        // }
        //set form value changes
        this.changesSubscription = this.accomBookingItemForm.valueChanges.subscribe(
            (data) => {
                if (JSON.stringify(data) !== JSON.stringify({})) {
                    this.bookingItem.itemName = data.itemName;
                    this.bookingItem.contractReference = data.contractReference;
                    this.bookingItem.startTime = data.startDate ? data.startDate.setSeconds(0) : data.startDate;
                    this.bookingItem.endTime = data.endDate ? data.endDate.setSeconds(0) : data.endDate;
                    this.bookingItem.serviceDate = data.startDate ? data.startDate.setSeconds(0) : data.startDate;
                    this.bookingItem.passengerValidated = data.passengerValidated;
                    this.bookingItem.genBookingItem.noOfRoomsRequired = data.roomsRequired ? +data.roomsRequired : 0;
                    this.bookingItem.adultCount = data.occAdultCount ? +data.occAdultCount : 0;
                    this.bookingItem.teenCount = data.occTeenCount ? +data.occTeenCount : 0;
                    this.bookingItem.childrenCount = data.occChildCount ? +data.occChildCount : 0;
                    this.bookingItem.infantCount = data.occInfantCount ? +data.occInfantCount : 0;
                    this.changeValidity.emit(this.accomBookingItemForm.valid && this.notesValid &&
                        !!this.bookingItem.leadPassenger);
                    //for date validation
                    this.startTime = new Date(this.bookingItem.startTime);
                }
            });
        this.defSupChangesSubscription = this.accomBookingItemForm.get('defaultSupplier').valueChanges
            .subscribe(data => {
                if (typeof (data) === 'string' && typeof (data) !== 'object') {
                    this.filterSupplier(data);
                }
            });
        this.roomTypeChangesSubscription = this.accomBookingItemForm.get('roomType').valueChanges.subscribe(data => {
            if (typeof (data) === 'string' && typeof (data) !== 'object') {
                this.filterRoomTypes(data);
            }
        });
        this.boardBasisChangesSubscription = this.accomBookingItemForm.get('boardBasis').valueChanges
            .subscribe(data => {
                if (typeof (data) === 'string' && typeof (data) !== 'object') {
                    this.filterBoardBasis(data);
                }
            });
        this.cityChangesSubscription = this.accomBookingItemForm.get('city').valueChanges.subscribe(data => {
            if (typeof (data) === 'string' && typeof (data) !== 'object') {
                this.filterCity(data);
            }
        });
        this.adultCountChangeSubscription = this.accomBookingItemForm.get('occAdultCount').valueChanges.subscribe(data => {
            if (data) {
                this.bookingItem.adultCount = +data;
                this.allocatePaxForGivenCount();
            }
        });
        this.teenCountChangeSubscription = this.accomBookingItemForm.get('occTeenCount').valueChanges.subscribe(data => {
            if (data) {
                this.bookingItem.teenCount = +data;
                this.allocatePaxForGivenCount();
            }
        });
        this.childCountChangeSubscription = this.accomBookingItemForm.get('occChildCount').valueChanges.subscribe(data => {
            if (data) {
                this.bookingItem.childrenCount = +data;
                this.allocatePaxForGivenCount();
            }
        });
        this.infantCountChangeSubscription = this.accomBookingItemForm.get('occInfantCount').valueChanges.subscribe(data => {
            if (data) {
                this.bookingItem.infantCount = +data;
                this.allocatePaxForGivenCount();
            }
        });

    }

    private allocatePaxForGivenCount() {
        if (this.sameDateTypeItems && this.sameDateTypeItems.length > 0 && this.bookingItem && this.bookingItem.passengerValidated) {
            this.onChangeCheckBox(true);
        } else {
            this.allocatePassengers([]);
        }
    }

    // Form validation for accom. item
    public validateForm() {
        Object.keys(this.accomBookingItemForm.controls).forEach(field => {
            const control = this.accomBookingItemForm.get(field);
            control.markAsTouched({onlySelf: true});
        });
    }

    // Form creation for accom. item
    private createForm() {
        //set start time and end time before create form fields
        if (this.bookingItem && this.bookingItem.startTime) {
            this.startTime = new Date(this.bookingItem.startTime);
        }
        if (this.bookingItem && this.bookingItem.endTime) {
            this.endTime = new Date(this.bookingItem.endTime);
        }
        this.accomBookingItemForm = this.fb.group({
            itemName: new FormControl(
                {value: this.bookingItem ? this.bookingItem.itemName : '', disabled: this.readonly},
                Validators.compose([
                    Validators.required
                ])
            ),
            contractReference: new FormControl(
                {value: this.bookingItem ? this.bookingItem.contractReference : '', disabled: this.readonly},
                Validators.compose([
                    Validators.required
                ])
            ),
            defaultSupplier: new FormControl(
                {value: this.bookingItem ? this.bookingItem.defaultSupplierName : '', disabled: this.readonly},
                Validators.compose([
                    Validators.required
                ])
            ),
            startDate: new FormControl(
                {value: this.startTime, disabled: this.readonly},
                Validators.compose([
                    Validators.required
                ])
            ),
            endDate: new FormControl(
                {value: this.endTime, disabled: this.readonly},
                Validators.compose([
                    Validators.required
                ])
            ),
            city: new FormControl(
                {value: this.genBookingItem ? this.genBookingItem.cityName : '', disabled: this.readonly},
                Validators.compose([
                    Validators.required
                ])
            ),
            roomType: new FormControl(
                {value: this.genBookingItem ? this.genBookingItem.hotelRoomTypeName : '', disabled: this.readonly},
                Validators.compose([
                    Validators.required
                ])
            ),
            boardBasis: new FormControl(
                {value: this.genBookingItem ? this.genBookingItem.hotelBoardBasisName : '', disabled: this.readonly},
                Validators.compose([
                    Validators.required
                ])
            ),
            roomsRequired: new FormControl(
                {value: this.genBookingItem ? this.genBookingItem.noOfRoomsRequired : '', disabled: this.readonly},
                Validators.compose([])
            ),
            occAdultCount: new FormControl(
                {value: this.bookingItem ? this.bookingItem.adultCount : '', disabled: this.readonly},
                Validators.compose([
                    Validators.required
                ])
            ),
            occTeenCount: new FormControl(
                {value: this.bookingItem ? this.bookingItem.teenCount : '', disabled: this.readonly},
                Validators.compose([])
            ),
            occChildCount: new FormControl(
                {value: this.bookingItem ? this.bookingItem.childrenCount : '', disabled: this.readonly},
                Validators.compose([])
            ),
            occInfantCount: new FormControl(
                {value: this.bookingItem ? this.bookingItem.infantCount : '', disabled: this.readonly},
                Validators.compose([])
            ),
            passengerValidated: new FormControl(
                {
                    value: this.bookingItem && this.bookingItem.passengerValidated ?
                        this.bookingItem.passengerValidated :
                        false, disabled: this.readonly
                },
                Validators.compose([])
            )
        });
    }

    // set error messages
    getErrorMessage(field: string): string {
        switch (field) {
            case 'itemName' :
                return this.accomBookingItemForm.get('itemName').hasError('required') ? 'Item Name Required' : '';
            case 'contractReference' :
                return this.accomBookingItemForm.get('contractReference').hasError('required') ?
                    'Contract Reference Required' :
                    '';
            case 'roomType' :
                return this.accomBookingItemForm.get('roomType').hasError('required') ? 'Room Type Required' : '';
            case 'boardBasis' :
                return this.accomBookingItemForm.get('boardBasis').hasError('required') ? 'Board basis Required' : '';
            case 'city' :
                return this.accomBookingItemForm.get('city').hasError('required') ? 'City Required' : '';
            case 'occAdultCount' :
                return this.accomBookingItemForm.get('occAdultCount').hasError('required') ?
                    'Adult Count Required' :
                    '';
            case 'startDate' :
                return this.accomBookingItemForm.get('startDate').hasError('required') ? 'Check in Date Required' : '';
            case 'endDate' :
                return this.accomBookingItemForm.get('endDate').hasError('required') ? 'Check out Date Required' : '';
        }
    }

    // Notes editor
    public onNotesChange(event: boolean) {
        this.notesValid = event;
        this.changeValidity.emit(this.accomBookingItemForm.valid && this.notesValid);
    }

    // supplier
    public displaySupplierFn(sup: any) {
        return sup && sup.name ? sup.name : sup;
    }

    public onSupplierFocus($event: FocusEvent) {
        this.suppliersFiltered = [];
    }

    public onSupplierFocusOut($event: FocusEvent) {
    }

    public onSelectSupplier(supplier: SupplierSummary) {
        this.bookingItem.defaultSupplierName = supplier.name;
        this.bookingItem.defaultSupplierCode = supplier.code;
        this.changeValidity.emit(this.accomBookingItemForm.valid && this.notesValid);
    }

    private filterSupplier(input: string) {
        if (input) {
            this.supplierSearchCriteria.codeOrName = input;
            this.supplierService.searchSuppliersNoDataKey(this.supplierSearchCriteria).subscribe(data => {
                this.suppliersFiltered = ResponseUtil.getDataArray(data);
            });
        } else {
            this.suppliersFiltered = [];
        }
    }

    // types (profile type)
    public displayRoomTypeFn(type: any) {
        return type && type.name ? type.name : type;
    }

    public onRoomTypeFocus($event: FocusEvent) {
        this.roomTypesFiltered = [];
    }

    public onRoomTypeFocusOut($event: FocusEvent) {
    }

    public onSelectRoomType(type: any) {
        this.genBookingItem.hotelRoomType = type.code;
        this.genBookingItem.hotelRoomTypeName = type.name;
        this.changeValidity.emit(this.accomBookingItemForm.valid && this.notesValid);
    }

    private filterRoomTypes(input: string) {
        this.roomTypeSearchCriteria.name = input;
        this.accommodationService.getRoomTypes(this.roomTypeSearchCriteria).subscribe(data => {
            this.roomTypesFiltered = ResponseUtil.getDataArray(data);
        });
    }

    // BoardBasis
    public displayBoardBasisFn(type: any) {
        return type && type.name ? type.name : type;
    }

    public onBoardBasisFocus($event: FocusEvent) {
        this.boardBasisFiltered = [];
    }

    public onBoardBasisFocusOut($event: FocusEvent) {
    }

    public onSelectBoardBasis(type: any) {
        this.genBookingItem.hotelBoardBasis = type.code;
        this.genBookingItem.hotelBoardBasisName = type.name;
        this.changeValidity.emit(this.accomBookingItemForm.valid && this.notesValid);
    }

    private filterBoardBasis(input: string) {
        this.boardBasisSearchCriteria.name = input;
        this.boardBasisSetupService.getRows(this.boardBasisSearchCriteria).subscribe(data => {
            this.boardBasisFiltered = ResponseUtil.getDataArray(data);
        });
    }

    // Occupancy - removed
    public displayOccupancyFn(type: any) {
        return type && type.occupancyName ? type.occupancyName : type;
    }

    public onOccupancyFocus($event: FocusEvent) {
        this.occupanciesFiltered = [];
    }

    public onOccupancyFocusOut($event: FocusEvent) {
    }

    public onSelectOccupancy(type: RoomOccupancy) {
        this.genBookingItem.occupancyName = type.occupancyName;
        this.genBookingItem.occAdultCount = type.adults;
        this.genBookingItem.occChildCount = type.children;
        this.genBookingItem.occInfantCount = type.infants;
        this.changeValidity.emit(this.accomBookingItemForm.valid && this.notesValid);
    }

    private filterOccupancy(input: string) {
        let roomOccupancySearchCriteria = new RoomOccupancySearchCriteria();
        roomOccupancySearchCriteria.name = input;
        this.supplierService.searchOccupancies(roomOccupancySearchCriteria).subscribe(data => {
            this.occupanciesFiltered = ResponseUtil.getDataArray(data);
        });
    }

    // City
    public displayCityFn(type: any) {
        return type && type.name ? type.name : type;
    }

    public onCityFocus($event: FocusEvent) {
        this.citiesFiltered = [];
    }

    public onCityFocusOut($event: FocusEvent) {
    }

    public onSelectCity(type: City) {
        this.genBookingItem.city = type.code;
        this.genBookingItem.cityName = type.name;
        this.genBookingItem.country = type.country ? type.country.code : '';
        this.genBookingItem.countryName = type.country ? type.country.name : '';
        this.changeValidity.emit(this.accomBookingItemForm.valid && this.notesValid);
    }

    private filterCity(input: string) {
        const criteria = new CommonDataSearchCriteria(CommonDataType.CITY);
        criteria.codeOrName = input;
        this.masterDataHandlerService.retrieveCities(criteria)
            .subscribe(value => {
                if (value && value.data) {
                    this.citiesFiltered = (value.data);
                }
            });

    }

    public ngOnDestroy(): void {
        if (this.changesSubscription) {
            this.changesSubscription.unsubscribe();
        }
        if (this.defSupChangesSubscription) {
            this.defSupChangesSubscription.unsubscribe();
        }
        if (this.boardBasisChangesSubscription) {
            this.boardBasisChangesSubscription.unsubscribe();
        }
        if (this.roomTypeChangesSubscription) {
            this.roomTypeChangesSubscription.unsubscribe();
        }
        if (this.occupancyChangesSubscription) {
            this.occupancyChangesSubscription.unsubscribe();
        }
    }

    public onChangeCheckBox(event: boolean) {
        let alreadyAllocatedPaxTempIds = [];
        if (event && this.sameDateTypeItems && this.sameDateTypeItems.length > 0) {
            this.sameDateTypeItems.forEach(value => alreadyAllocatedPaxTempIds.push(...value.passengerTempIds));
        }
        this.allocatePassengers(alreadyAllocatedPaxTempIds);
    }

    public onStartDateChange() {
        this.accomBookingItemForm.get('startDate').value.setSeconds(0);
        if (this.sameEndDateItems && this.sameEndDateItems.length > 0) {
            this.sameDateTypeItems = this.sameEndDateItems.filter(value => new Date(value.startTime).toString() ===
                this.accomBookingItemForm.get('startDate').value.toString());
        } else if (this.sameTypeItems && this.sameTypeItems.length > 0) {
            this.sameStartDateItems = this.sameTypeItems.filter(value => new Date(value.startTime).toString() ===
                this.accomBookingItemForm.get('startDate').value.toString());
        }
        // this.checkPassengerValidation();
    }

    public onEndDateChange() {
        this.accomBookingItemForm.get('endDate').value.setSeconds(0);
        if (this.sameStartDateItems && this.sameStartDateItems.length > 0) {
            this.sameDateTypeItems = this.sameStartDateItems.filter(value => new Date(value.endTime).toString() ===
                this.accomBookingItemForm.get('endDate').value.toString());
        } else if (this.sameTypeItems && this.sameTypeItems.length > 0) {
            this.sameEndDateItems = this.sameTypeItems.filter(value => new Date(value.endTime).toString() ===
                this.accomBookingItemForm.get('endDate').value.toString());
        }
    }

    private allocatePassengers(alreadyAllocatedPaxTempIds: any[]) {
        let nonAllocatedPassengers = [];
        let adults = [];
        let teens = [];
        let children = [];
        let infants = [];
        if (this.allBookingPassengers && this.allBookingPassengers.length > 0) {
            this.allBookingPassengers.forEach(value => {
                if (!alreadyAllocatedPaxTempIds.includes(value.tempId)) {
                    if (value.type === PassengerType.A && this.bookingItem.adultCount > 0 && adults.length < this.bookingItem.adultCount) {
                        adults.push(value.tempId);
                        nonAllocatedPassengers.push(value.tempId);
                    } else if (value.type === PassengerType.T && this.bookingItem.teenCount > 0 && teens.length < this.bookingItem.teenCount) {
                        teens.push(value.tempId);
                        nonAllocatedPassengers.push(value.tempId);
                    } else if (value.type === PassengerType.C && this.bookingItem.childrenCount > 0 && children.length < this.bookingItem.childrenCount) {
                        children.push(value.tempId);
                        nonAllocatedPassengers.push(value.tempId);
                    } else if (value.type === PassengerType.I && this.bookingItem.infantCount > 0 && infants.length < this.bookingItem.infantCount) {
                        infants.push(value.tempId);
                        nonAllocatedPassengers.push(value.tempId);
                    }
                }
            });
            this.checkPassengerValidation();
        }
        this.bookingItem.passengerTempIds = nonAllocatedPassengers;
        for (let i = 0; i < this.allBookingPassengers.length; i++) {
            const value = this.allBookingPassengers[i];
            if (this.bookingItem.passengerTempIds.includes(value.tempId) && value.type === PassengerType.A) {
                value.lead = true;
                this.bookingItem.leadPassenger = value;
                break;
            }
        }
    }

    private checkPassengerValidation() {
        if (!this.sameDateTypeItems || this.sameDateTypeItems.length === 0) {
            this.bookingItem.passengerTempIds = this.allPassengerIds;
        }
    }

    private getSameDateTypeItems() {
        if (this.sameTypeItems && this.sameTypeItems.length > 0 && this.bookingItem.startTime && this.bookingItem.endTime) {
            this.sameDateTypeItems = this.sameTypeItems.filter(value => new Date(value.endTime).toString() ===
                this.accomBookingItemForm.get('endDate').value.toString() && new Date(value.startTime).toString() ===
                this.accomBookingItemForm.get('startDate').value.toString());
        }
    }
}
