import {Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {LEVEL} from '@tc-core/model/it/codegen/ui/framework/dialog-model';
import {ModalData} from '@tc-core/model/it/codegen/ui/framework/modal-data';
import {TC} from '@tc-core/util';
import {ConfigLoader} from '@tc-core/util/framework';
import {FocusViewService} from '@tc/focus-view/focus-view.service';
import {IGetRowsParams} from 'ag-grid-community';
import {Observable, Subscription} from 'rxjs';
import {tap} from 'rxjs/operators';
import {SetupGridComp} from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid-comp';
import {SetupGridDataSource} from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid-data-source';
import {SetupGridComponent} from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid.component';
import {ActionRendererAction} from '../../../widgets/framework/ag-grid-custom-components/renderers/actions/actions.component';
import {TCO} from '../../../constants';
import {DocumentSearchCriteria} from '../../../models/criteria/document-search-criteria';
import {DispatchStatus} from '../../../models/document/dispatch-entry';
import {SortDirection} from '../../../models/helper/sort-direction';
import {DocumentService} from '../../../services/backend-consumers/documents/document.service';
import {DataHandlerService} from '../../../services/backend-consumers/master-data-handler-service/data-handler.service';
import {DataKey, DataStoreService} from '../../../services/util/framework/data-store.service';
import {DocumentDetailFocusViewComponent} from '../document-detail-focus-view/document-detail-focus-view.component';
import EMPTY_CHAR = TC.FORMAT.EMPTY_CHAR;

@Component({
    selector: 'tc-documents-search-results',
    templateUrl: './documents-search-results.component.html'
})
export class DocumentsSearchResultsComponent extends SetupGridComp implements OnInit, OnDestroy, SetupGridDataSource {
    @Input() colDefConfig = [];

    @ViewChild('docSetupGrid')
    private setupGrid: SetupGridComponent;

    documentSearchCriteria: DocumentSearchCriteria;

    // required options for grid editors
    // nothing

    gridActions: ActionRendererAction[] = [];

    private searchCriteriaObserver: Subscription = new Subscription();
    private focusViewDataChangeObserver: Subscription = new Subscription();
    private focusViewConfirmObserver: Subscription = new Subscription();
    private focusViewCloseObserver: Subscription = new Subscription();
    private routeDataSubscription: Subscription = new Subscription();
    private routeParamsSubscription: Subscription = new Subscription();
    private routeEventSubscription: Subscription = new Subscription();
    private focusCloseSubscription: Subscription = new Subscription();

    isFocusViewDataChanged = false;

    @ViewChild('statusCell') statusCell: TemplateRef<any>;
    @ViewChild('nameFormatCell') nameFormatCell: TemplateRef<any>;
    @ViewChild('formatCell') formatCell: TemplateRef<any>;
    @ViewChild('generatedByCell') generatedByCell: TemplateRef<any>;

    constructor(
        private configLoader: ConfigLoader,
        private dataStoreService: DataStoreService,
        private dataHandlerService: DataHandlerService,
        private documentService: DocumentService,
        private activatedRoute: ActivatedRoute,
        private focusViewService: FocusViewService
    ) {
        super();
    }

    ngOnInit() {
        this.subscribeSearchCriteria();
        this.fillRequiredGridOptionData();
        this.addGridActions();
    }

    /**
     * subscribe search criteria changes in search bar
     * then force grid to refresh data
     * grid refresh will be call getRaws method which is using this subscribed criteria for backend call
     */
    subscribeSearchCriteria() {
        this.searchCriteriaObserver = this.dataStoreService.get(DataKey.documentSearchCriteria)
            .subscribe(value => {
                this.documentSearchCriteria = value;
                if (this.documentSearchCriteria) {
                    this.setupGrid.runForceSearch();
                }
            });
    }

    private fillRequiredGridOptionData() {
        // nothing
    }

    public isInvalidRow(params): boolean {
        return false;
    }

    public createNewRow(): any {
        return {unsaved: true};
    }

    public isUnsavedRow(row: any): boolean {
        return !!row.unsaved;
    }

    public deleteRow(row: any) {
        return null;
    }

    public saveRow(row: any, event: any) {
        return null;
    }

    public getRows(params: IGetRowsParams): Observable<any> {
        console.log('fetch requesting for ' + params.startRow + ' to ' + params.endRow);

        const pageSize = params.endRow - params.startRow;

        this.documentSearchCriteria.start = params.startRow;
        this.documentSearchCriteria.size = pageSize;
        if (params.sortModel && params.sortModel[0]) {
            this.documentSearchCriteria.sortBy = params.sortModel[0].colId;
            if (params.sortModel[0].sort === SetupGridComp.GRID_SORT_ASCENDING) {
                this.documentSearchCriteria.sortDirection = SortDirection.ASC;
            } else {
                this.documentSearchCriteria.sortDirection = SortDirection.DESC;
            }
        }
        return this.documentService.searchDocuments(this.documentSearchCriteria)
            .pipe(
                tap(
                    data => this.dataStoreService.set(DataKey.documentSearchResultsForCriteria, data)
                )
            );
    }

    public ngOnDestroy(): void {
        if (this.searchCriteriaObserver) {
            this.searchCriteriaObserver.unsubscribe();
        }
        if (this.focusViewDataChangeObserver) {
            this.focusViewDataChangeObserver.unsubscribe();
        }
        if (this.focusViewConfirmObserver) {
            this.focusViewConfirmObserver.unsubscribe();
        }
        if (this.focusViewCloseObserver) {
            this.focusViewCloseObserver.unsubscribe();
        }
        if (this.routeDataSubscription) {
            this.routeDataSubscription.unsubscribe();
        }
        if (this.routeParamsSubscription) {
            this.routeParamsSubscription.unsubscribe();
        }
        if (this.routeEventSubscription) {
            this.routeEventSubscription.unsubscribe();
        }

        if (this.focusCloseSubscription) {
            this.focusCloseSubscription.unsubscribe();
        }
    }

    /*
     grid config methods which are called by grid setup (ag grid)
     and set by grid config
     */

    getTypeName = (params) => {
        if (params && params.data && params.data.documentTypeLabel) {
            return params.data.documentTypeLabel;
        } else {
            return EMPTY_CHAR;
        }
    };

    statusPending = (params) => {
        return params.data && params.data.recentDispatchEntry && params.data.recentDispatchEntry.dispatchStatus &&
            (params.data.recentDispatchEntry.dispatchStatus ===
                DispatchStatus.PENDING || params.data.recentDispatchEntry.dispatchStatus ===
                DispatchStatus.DOC_QUEUE_PENDING);
    };

    statusSuccess = (params) => {
        return params.data && params.data.recentDispatchEntry && params.data.recentDispatchEntry.dispatchStatus &&
            params.data.recentDispatchEntry.dispatchStatus ===
            DispatchStatus.SUCCESS;
    };

    statusFailed = (params) => {
        return params.data && params.data.recentDispatchEntry && params.data.recentDispatchEntry.dispatchStatus
            && (params.data.recentDispatchEntry.dispatchStatus === DispatchStatus.FAILED
                || params.data.recentDispatchEntry.dispatchStatus === DispatchStatus.INVALID);
    };

    getSupplierNameFromManifest = (params) => {
        if (params && params.data && params.data.supplierManifest) {
            return params.data.supplierManifest.supplierName;
        } else {
            return EMPTY_CHAR;
        }
    };

    getUserName = (params) => {
        if (params && params.data && params.data.userName) {
            return params.data.systemGenerated ? 'System' : params.data.userName;
        } else if (params && params.data && params.data.systemGenerated) {
            return 'System';
        } else {
            return EMPTY_CHAR;
        }
    };

    getSupplierCodeFromManifest = (params) => {
        if (params && params.data && params.data.supplierManifest) {
            return params.data.supplierManifest.supplierCode;
        } else {
            return EMPTY_CHAR;
        }
    };

    public onRowDoubleClicked(event: any) {
        console.log('Row clicked');
        this.onViewDocumentDetails(event.data);
    }

    onViewDocumentDetails(data: any) {
        this.focusViewDataObserver();
        const fabActions = [];
        const navItemsArray = this.configLoader.configurations.get(TCO.CONF.CONF_NAVIGATION);
        this.configLoader.configurations.get('FOCUS_VIEW_CONFIG').DOCUMENT_FOCUS_VIEW.fabAction
            .forEach(val => {
                for (const key in navItemsArray) {
                    if (val === navItemsArray[key].id) {
                        fabActions.push(navItemsArray[key]);
                    }
                }
            });

        const dataObject = new ModalData(
            LEVEL.SUCCESS, 'Document Details', null, null, 'grid-focus-view',
            DocumentDetailFocusViewComponent,
            {
                document: data,
                defaultSupplierCode: data.supplierManifest.supplierCode
            },
            {label: 'close'},
            '', '', fabActions, null,
            'Name : ' + data.name
        );
        dataObject.disableClose = true;
        this.focusViewConfirmObserver = this.focusViewService.confirm(dataObject)
            .subscribe(
                res => {
                    if (this.isFocusViewDataChanged) {
                        // after view closed
                        this.setupGrid.runForceSearch();
                    }
                }
            );

    }

    focusViewDataObserver() {
        this.focusViewDataChangeObserver = this.dataStoreService.get(DataKey.docDetailsFocusViewDataChanged)
            .subscribe(isDataChanged => {
                if (isDataChanged) {
                    this.focusViewDataChangeObserver.unsubscribe();
                }
                this.isFocusViewDataChanged = isDataChanged;
            });
    }

    closeFocusView() {
        if (this.focusViewService.close()) {
            this.focusCloseSubscription = this.focusViewService.close().subscribe(
                (res) => {
                }
            );
        }
    }

    private addGridActions() {
        this.gridActions.push(
            {
                icon: 'get_app',
                action: this.clickDownloadAction.bind(this),
                disable: false,
                tooltip: 'Download'
            }
        );
    }

    clickDownloadAction = (params) => {
        console.log('click download action');
        console.log(params);
        const documentId = params.data ? params.data.documentId : -1;
        if (documentId !== -1) {
            this.documentService.downloadDocument(documentId);
        }
    };

    /**
     *  This method use to get the current dispatch status of a data grid data row
     * @param originalDisplayStatus - raw value from backend
     * @return - if INVALID status has passed it should display as FAILED
     */
    getDisplayDispatchEntry(originalDisplayStatus: string): string {
        if (originalDisplayStatus === DispatchStatus.INVALID || originalDisplayStatus === DispatchStatus.DOC_QUEUE_PENDING) {
            return DispatchStatus.FAILED;
        }
        return originalDisplayStatus;
    }
}
