import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CurrencyFormat } from '@tc-core/model/it/codegen/ui/framework/currency-format';
import { TC } from '@tc-core/util';
import { ConfigLoader } from '@tc-core/util/framework';
import { TimelineEvent } from '../timeline-event';

@Component({
    selector: 'tc-timeline-event',
    templateUrl: './timeline-event.component.html'
})
export class TimelineEventComponent {

    @Input() widthNoOfUnits = 0;
    @Input() unitWidth = 0;
    @Input() height = 0;

    eventData: TimelineEvent = null;

    @Input('event')
    set event(e: TimelineEvent) {
        this.eventData = e;
        this.currencyFormat = this.getCurrencyFormat();
    }

    @Output() eventClick: EventEmitter<TimelineEvent> = new EventEmitter();

    currencyFormat: CurrencyFormat;
    vehicle = 'vehicle';

    constructor(
        private configLoader: ConfigLoader
    ) {}

    private getCurrencyFormat(): CurrencyFormat {
        if (this.eventData && this.eventData.assignment) {
            let currencyFormat;
            const currencySymbolPattern = this.configLoader
                                              .configurations
                                              .get(TC.CONF.CONF_SYSTEM).defaults.default_currency_symbol_pattern;
            currencyFormat = new CurrencyFormat(this.eventData.assignment.currency, currencySymbolPattern);
            return currencyFormat;
        }
        return null;
    }

    onEventClick(clickEvent) {
        clickEvent.stopPropagation();
        this.eventClick.emit(this.eventData);
    }
}
