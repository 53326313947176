import { AssignmentRawData } from './assignment-raw-data';
import { BookingPassenger } from './booking-passenger';
import { PaxCost } from './pax-cost';
import { ServiceSharing } from './service-sharing';

export class ExistingAssignment extends AssignmentRawData {
    calculatedCost: PaxCost;
    bookingPassengers: BookingPassenger[];
    recommended: boolean;
    allocatedCapacity: number;
    remainingCapacity: number;
    pvtShared: ServiceSharing;
}
