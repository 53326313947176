import * as tslib_1 from "tslib";
import { AbstractSearchCriteria } from '../../criteria/abstract-search-criteria';
var BookingItemSearchCriteria = /** @class */ (function (_super) {
    tslib_1.__extends(BookingItemSearchCriteria, _super);
    function BookingItemSearchCriteria() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return BookingItemSearchCriteria;
}(AbstractSearchCriteria));
export { BookingItemSearchCriteria };
