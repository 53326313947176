/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../widgets/shared/adv-room-search-results/adv-room-search-results.component.ngfactory";
import * as i2 from "../../widgets/shared/adv-room-search-results/adv-room-search-results.component";
import * as i3 from "./room-search-results.component";
var styles_RoomSearchResultsComponent = [];
var RenderType_RoomSearchResultsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_RoomSearchResultsComponent, data: {} });
export { RenderType_RoomSearchResultsComponent as RenderType_RoomSearchResultsComponent };
export function View_RoomSearchResultsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-adv-room-search-results", [], null, null, null, i1.View_AdvRoomSearchResultsComponent_0, i1.RenderType_AdvRoomSearchResultsComponent)), i0.ɵdid(1, 114688, null, 0, i2.AdvRoomSearchResultsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_RoomSearchResultsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-room-search-results", [], null, null, null, View_RoomSearchResultsComponent_0, RenderType_RoomSearchResultsComponent)), i0.ɵdid(1, 114688, null, 0, i3.RoomSearchResultsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RoomSearchResultsComponentNgFactory = i0.ɵccf("tc-room-search-results", i3.RoomSearchResultsComponent, View_RoomSearchResultsComponent_Host_0, {}, {}, []);
export { RoomSearchResultsComponentNgFactory as RoomSearchResultsComponentNgFactory };
