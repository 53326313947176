import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ResultContentCardComponent } from '../result-content-card/result-content-card.component';
var ResultContentCardSuppliersComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ResultContentCardSuppliersComponent, _super);
    function ResultContentCardSuppliersComponent() {
        return _super.call(this) || this;
    }
    ResultContentCardSuppliersComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
        this.overrideStyles();
    };
    ResultContentCardSuppliersComponent.prototype.overrideStyles = function () {
        this.row1col1_customsStyles = ['tc-strip-text--primary'];
        this.row1col2_customsStyles = ['tc-strip-text--primary'];
        this.row1col3_customsStyles = [];
        this.row1col4_customsStyles = ['tc-strip-text--right', 'tc-strip-text--primary', 'tc-strip__price', 'tc-strip__price--large'];
        this.row2col1_customsStyles = [];
        this.row2col2_customsStyles = [];
        this.row2col3_customsStyles = [];
        this.row2col4_customsStyles = ['tc-strip-text--right'];
    };
    return ResultContentCardSuppliersComponent;
}(ResultContentCardComponent));
export { ResultContentCardSuppliersComponent };
