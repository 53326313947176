import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PipesModule } from '@tc/pipes';
import { ResourceModule } from '@tc/resource';
import { TimelineModule } from '../../widgets/framework/cg-timeline/timeline/timeline.module';
import { ResultContentCardModule } from '../../widgets/shared/result-content-card/result-content-card.module';
import {ResourceAllocationCardGenComponent} from "./resource-allocation-card-gen.component";
import {MatIconModule} from "@angular/material/icon";
import {NgxMaterialTimepickerModule} from "ngx-material-timepicker";
import {MatButtonModule} from "@angular/material/button";
import {MatTooltipModule} from "@angular/material/tooltip";
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
    declarations: [ResourceAllocationCardGenComponent],
    imports: [
        CommonModule,
        ResourceModule,
        ResultContentCardModule,
        PipesModule,
        TimelineModule,
        MatIconModule,
        NgxMaterialTimepickerModule,
        MatButtonModule,
        MatTooltipModule,
        TranslateModule.forChild()
    ],
    exports: [ResourceAllocationCardGenComponent]
})
export class ResourceAllocationCardGenModule {
}
