import { Component } from '@angular/core';
import {DataKey} from "../../../services/util/framework/data-store.service";
import {TCO} from "../../../constants";
import {BrandSearchCriteria} from "../../../models/criteria/brand-search-criteria";

@Component({
  selector: 'tc-brand-setup-search-criteria',
  templateUrl: './brand-setup-search-criteria.component.html'
})
export class BrandSetupSearchCriteriaComponent {

  inputSearchCriteria = new BrandSearchCriteria();
  searchCriteriaUpdatingDataKey = DataKey.brandSetupSearchCriteria;
  searchResultsDataKey = DataKey.brandSetupSearchResultsForCriteria;
  searchCriteriaLoadingDataKey = TCO.AppData.BRAND_SEARCH_CRITERIA;
  chipConfigKey = TCO.CONF.CONF_BRAND_SETUP_SEARCH_CRITERIA_CHIP_CONFIG;
  placeHolderText = 'Search Brand Master Data';

  createNewSearchCriteriaFn = () => {
    const criteria = new BrandSearchCriteria();
    criteria.code = '';
    criteria.name = '';
    return criteria;
  };

  clearLoadedSearchCriteriaFn = (criteria: BrandSearchCriteria) => {
  };

}
