import { Component, Input, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { DialogModel } from '@tc-core/model/it/codegen/ui/framework/dialog-model';
import { LEVEL } from '@tc-core/model/it/codegen/ui/framework/modal-data';
// import { CgCalendarCssClassConstants } from '@tc/cg-calendar/cg-calendar-css-class-constants';
// import { RangeGroup } from '@tc/cg-calendar/range-group';
// import { SeasonCalendarService } from '@tc/cg-season-calendar/season-calendar.service';
import { DialogService } from '@tc/dialog/dialog.service';
import { CgCalendarCssClassConstants } from '../../cg-calendar/cg-calendar-css-class-constants';
import { RangeGroup } from '../../cg-calendar/range-group';
import { SeasonCalendarService } from '../season-calendar.service';
import CgCalColor = CgCalendarCssClassConstants.CgCalColor;

@Component({
  selector: 'tc-seasons-edit-card',
  templateUrl: './seasons-edit-card.component.html'
})
export class SeasonsEditCardComponent implements OnInit {

  @Input() rangeGroups: RangeGroup[] = [];
  @Input() newRangeGroupNamePrefix: string = '';

  newRangeGroups: RangeGroup[] = [];
  allRangeGroups: RangeGroup[] = [];
  removingRangeGroups: RangeGroup[] = [];
  cgCalDateRangeColorCssClasses: CgCalColor[] = [];

  lastAddedRangeGroup: RangeGroup = null;

  constructor(
    private dialogRef: MatDialogRef<SeasonsEditCardComponent>,
    private seasonCalendarService: SeasonCalendarService,
    private dialogService: DialogService
  ) { }

  ngOnInit() {
    this.allRangeGroups = this.allRangeGroups.concat(this.rangeGroups);
    this.setTemporaryAttributes();
    this.getColorIds();
  }

  setTemporaryAttributes() {
    for (let j = 0; j < this.rangeGroups.length; j++) {
      this.rangeGroups[j].tempName = this.rangeGroups[j].name;
      this.rangeGroups[j].tempColorId = this.rangeGroups[j].colorId;
    }
  }

  getDataFromTempAttributes() {
    for (let j = 0; j < this.rangeGroups.length; j++) {
      this.rangeGroups[j].name = this.rangeGroups[j].tempName;
      this.rangeGroups[j].colorId = this.rangeGroups[j].tempColorId;
    }
  }

  getColorIds() {
    for (let colorIdKey in CgCalendarCssClassConstants.ColorId) {
      this.cgCalDateRangeColorCssClasses.push(CgCalendarCssClassConstants.ColorId[colorIdKey]);
    }
  }

  public onAddSeasonClick($event: MouseEvent) {
    let freeColor = this.getFreeColor();
    if (freeColor) {
      let rangeGroup = new RangeGroup();
      rangeGroup.tempName = this.createSeasonName();
      rangeGroup.tempColorId = freeColor;
      this.lastAddedRangeGroup = rangeGroup;
      this.newRangeGroups.splice(0, 0, rangeGroup);
      this.allRangeGroups.splice(0, 0, rangeGroup);
    }
  }

  private createSeasonName(): string {
    let name = this.newRangeGroupNamePrefix;
    let maxSeasonNum = 0;
    for (let j = 0; j < this.allRangeGroups.length; j++) {
      let rangeGroup = this.allRangeGroups[j];
      let arr = rangeGroup.tempName.split(this.newRangeGroupNamePrefix);
      let number = Number(arr[1]);
      if (arr.length > 1 && !isNaN(number)) {
        if (number > maxSeasonNum) {
          maxSeasonNum = number;
        }
      }
    }
    maxSeasonNum++;
    name += maxSeasonNum;
    return name;
  }

  private getFreeColor(): string {
    let colorId = '';
    for (let colorIdKey in CgCalendarCssClassConstants.ColorId) {
      let match = false;
      this.allRangeGroups.forEach(group => {
        if (group.tempColorId === CgCalendarCssClassConstants.ColorId[colorIdKey].class) {
          match = true;
        }
      });
      if (!match) {
        colorId = CgCalendarCssClassConstants.ColorId[colorIdKey].class;
        return colorId;
      }
    }
    return colorId;
  }

  onRemoveSeason(rangeGroup: RangeGroup) {
    if (!rangeGroup.nonRemovable) {
        let confirmSuccess = new DialogModel(
          true,
          LEVEL.WARNING,
          'Remove Season',
          `Do you want to delete the season '${rangeGroup.name}' ?`,
          true,
          2000,
          null,
          'No',
          'Yes'
        );
        this.dialogService
            .confirm(confirmSuccess)
            .subscribe((res) => {
              if (res === true) {
                if (this.lastAddedRangeGroup === rangeGroup) {
                  this.lastAddedRangeGroup = null;
                }
                // delete range
                this.removingRangeGroups.push(rangeGroup);
                this.seasonCalendarService.removeRangeGroup(this.allRangeGroups, rangeGroup,true);
                this.seasonCalendarService.removeRangeGroup(this.newRangeGroups, rangeGroup,true);
              }
            });
    } else {
      let confirmSuccess = new DialogModel(
        true,
        LEVEL.WARNING,
        'Invalid Attempt',
        `This season cannot be deleted.`,
        true,
        2000,
        null,
        null,
        'OK'
      );
      this.dialogService
          .confirm(confirmSuccess);
    }
  }

  public onClose($event: MouseEvent) {
    this.dialogRef.close();
  }

  public onSave($event: MouseEvent) {
    this.seasonCalendarService.removeRangeGroups(this.rangeGroups,this.removingRangeGroups);
    this.seasonCalendarService.addNewRangeGroups(this.rangeGroups,this.newRangeGroups);
    this.getDataFromTempAttributes();
    if (this.lastAddedRangeGroup) {
      this.seasonCalendarService.selectRangeGroup(this.rangeGroups, this.lastAddedRangeGroup);
    }
    this.dialogRef.close({rangeGroups: this.rangeGroups});
  }

  public onColorChanged(rangeGroup: RangeGroup, colorClass: CgCalColor) {
    if (!this.isDisableColor(colorClass)) {
      rangeGroup.tempColorId = colorClass.class;
    }
  }

  public isEnableSaveButton(): boolean {
    let enable = true;
    for (let i = 0; i < this.allRangeGroups.length; i++) {
      if (!this.allRangeGroups[i].tempName) {
        enable = false;
      } else if (!this.allRangeGroups[i].tempColorId) {
        enable = false;
      }
    }
    return enable;
  }

  public onClickPalette() {
    setTimeout(() => {
      let colorIndicator = document
        .querySelector('.tc-sc-color-palette__item');

      if (colorIndicator && colorIndicator.parentElement && colorIndicator.parentElement.parentElement &&
        colorIndicator.parentElement.parentElement.parentElement) {

        colorIndicator.parentElement.parentElement.parentElement.classList.add('tc-sc-color-palette');
      }
    }, 0);

  }

  public isDisableColor(colorClass: CgCalColor) {
    let disable = false;
    for (let i = 0; i < this.allRangeGroups.length; i++) {
      let group = this.allRangeGroups[i];
      if (group.tempColorId === colorClass.class) {
        disable = true;
      }
    }
    return disable;
  }

}
