<div class="container-fluid direct-client-contact-details-edit tc-card-wrapper">
    <div class="row" style="border-bottom: 1px solid #eee;">
        <div class="col-md-12 col-lg-12">
            <div class="tc-flex-box">
                <div class="tc-flex-item tc-item-circle">
                    <div class="icon-box">
                        <i class="material-icons" aria-hidden="true">format_list_bulleted</i>
                    </div>
                </div>

                <div class="tc-flex-item tc-right-cage">
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                            <div class="tc-select-edit dcpiec-select-title">
                                <mat-form-field>
                                    <input
                                            id="item-name"
                                            matInput
                                            [placeholder]="'Item Name'|translate"
                                            [value]="bookingItem?.itemName? bookingItem.itemName:''"
                                            readonly>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                            <div class="tc-input-edit dcpiec-input-format">
                                <mat-form-field>
                                    <input
                                            id="contract-reference"
                                            matInput
                                            [placeholder]="'Contract Reference'|translate"
                                            [value]="bookingItem?.contractReference? bookingItem.contractReference:''"
                                            readonly>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                            <div class="tc-input-edit dcpiec-input-dispatchMethod">
                                <mat-form-field>
                                    <input
                                            id="pl-code"
                                            matInput
                                            [placeholder]="'PL Code'|translate"
                                            [value]="bookingItem?.plCode? bookingItem.plCode:''"
                                            readonly>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                            <div class="tc-input-edit dcpiec-input-dispatchMethod">
                                <mat-form-field>
                                    <input
                                            id="default-supplier"
                                            matInput
                                            [placeholder]="'Default Supplier'|translate"
                                            [value]="bookingItem?.trsBookingItem? bookingItem.defaultSupplierName:''"
                                            readonly>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                            <div class="tc-input-edit dcpiec-input-frequency">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                            <div class="tc-select-edit dcpiec-select-title">
                                <mat-form-field>
                                    <input
                                            id="transfer-mode"
                                            matInput
                                            [placeholder]="'Transfer Mode'|translate"
                                            [value]="bookingItem?.trsBookingItem?.transferModeName? bookingItem.trsBookingItem.transferModeName:(bookingItem?.trsBookingItem?.transferMode? bookingItem.trsBookingItem.transferMode:'')"
                                            readonly>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                            <div class="tc-input-edit dcpiec-input-format">
                                <mat-form-field>
                                    <input
                                            id="pickup-location"
                                            matInput
                                            #trsModeInput
                                            [matTooltip]="bookingItem?.trsBookingItem?.pickupPointName? bookingItem.trsBookingItem.pickupPointName:''"
                                            [placeholder]="'Pickup Location'|translate"
                                            [value]="bookingItem?.trsBookingItem?.pickupPointName? bookingItem.trsBookingItem.pickupPointName:''"
                                            readonly>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                            <div class="tc-input-edit dcpiec-input-dispatchMethod">
                                <mat-form-field>
                                    <input
                                            id="drop-off-location"
                                            matInput
                                            #trsModeInput
                                            [matTooltip]="bookingItem?.trsBookingItem?.dropOffPointName? bookingItem.trsBookingItem.dropOffPointName:''"
                                            [placeholder]="'Drop Off Location'|translate"
                                            [value]="bookingItem?.trsBookingItem?.dropOffPointName? bookingItem.trsBookingItem.dropOffPointName:''"
                                            readonly>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                            <div class="tc-input-edit dcpiec-input-frequency">
                                <mat-form-field>
                                    <input
                                            id="vehicle-count"
                                            matInput
                                            [placeholder]="'Vehicle Count'|translate"
                                            [value]="bookingItem?.trsBookingItem?.vehicleCount? bookingItem.trsBookingItem.vehicleCount:'1'"
                                            readonly>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                            <div class="tc-input-edit dcpiec-input-frequency">
                                <mat-form-field>
                                    <input
                                            id="adult-count"
                                            matInput
                                            [placeholder]="'Adult Count'|translate"
                                            [value]="bookingItem.adultCount ? bookingItem.adultCount:0"
                                            readonly>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                            <div class="tc-input-edit dcpiec-input-frequency">
                                <mat-form-field>
                                    <input
                                            id="child-count"
                                            matInput
                                            [placeholder]="'Child Count'|translate"
                                            [value]="bookingItem.childrenCount ? bookingItem.childrenCount:0"
                                            readonly>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                            <div class="tc-input-edit dcpiec-input-frequency">
                                <mat-form-field>
                                    <input
                                            id="teen-count"
                                            matInput
                                            [placeholder]="'Teen Count'|translate"
                                            [value]="bookingItem.teenCount ? bookingItem.teenCount : 0"
                                            readonly>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                            <div class="tc-input-edit dcpiec-input-frequency">
                                <mat-form-field>
                                    <input
                                            id="infant-count"
                                            matInput
                                            [placeholder]="'Infant Count'|translate"
                                            [value]="bookingItem.infantCount ? bookingItem.infantCount : 0"
                                            readonly>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                            <div class="tc-select-edit dcpiec-select-title">
                                <mat-form-field>
                                    <input
                                            id="booking-date-time"
                                            matInput
                                            [placeholder]="'Booking Date & Time'|translate"
                                            [value]="bookingItem?.bookingDate | date:'yyyy-MM-dd h:mm a':'GMT'"
                                            readonly>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                            <div class="tc-select-edit dcpiec-select-title">
                                <mat-form-field>
                                    <input
                                            id="start-date"
                                            matInput
                                            [placeholder]="'Service Start Date & Time'|translate"
                                            [value]="(bookingItem?.trsBookingItem?.departureDateTime? bookingItem.trsBookingItem.departureDateTime:'' )| date:'yyyy-MM-dd h:mm a':'GMT'"
                                            readonly>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                            <div class="tc-input-edit dcpiec-input-format">
                                <mat-form-field>
                                    <input
                                            id="end-date"
                                            matInput
                                            [placeholder]="'Service End Date & Time'|translate"
                                            [value]="(bookingItem?.trsBookingItem?.arrivalDateTime? bookingItem.trsBookingItem.arrivalDateTime:'') | date:'yyyy-MM-dd h:mm a':'GMT'"
                                            readonly>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <div class="row">
        <tc-item-notes
                [readonly]="true"
                [noteList]="bookingItem.itemNotes"
                [heading]="'Notes'"
                class="bdv-booking-notes-summary-wrapper">
        </tc-item-notes>
    </div>
</div>
