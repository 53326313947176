import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalService } from '@tc/modal/modal.service';
import { Resource } from '../../../../models/resource/resource';
import { ChangeDetectSupporter } from '../../../../models/supplier/change-detect-supporter';
import { ProfileManagementService } from '../../../../services/backend-consumers/supplier-service/profile-management.service';
import { DataStoreService } from '../../../../services/util/framework/data-store.service';

@Component({
    selector: 'tc-henna-artist-specific',
    templateUrl: './henna-artist-specific.component.html'
})
export class HennaArtistSpecificComponent implements OnInit, OnDestroy {

    @Input() resource: Resource;
    @Input() changeDetectSupporter: ChangeDetectSupporter;

    @Output() changeFormValidity: EventEmitter<boolean> = new EventEmitter();

    hennaArtistSpecificForm: FormGroup;

    constructor(
        private profileManagementService: ProfileManagementService,
        private dataStore: DataStoreService,
        private fb: FormBuilder,
        private modalService: ModalService
    ) {}

    ngOnInit() {
        this.changeDetectSupporter.checkedSpecific = true;

        this.createForm();

        this.hennaArtistSpecificForm.valueChanges.subscribe(
            (data) => {
                if (JSON.stringify(data) !== JSON.stringify({})) {
                    this.resource.genericResource.workingHours = data.workingHours;
                    this.changeFormValidity.emit(this.hennaArtistSpecificForm.valid);

                }
            }
        );

        this.validateForm();
    }

    public validateForm() {
        Object.keys(this.hennaArtistSpecificForm.controls).forEach(field => {
            const control = this.hennaArtistSpecificForm.get(field);
            control.markAsTouched({onlySelf: true});
        });
    }

    private createForm() {
        this.hennaArtistSpecificForm = this.fb.group({
            workingHours: [
                this.resource.genericResource ? this.resource.genericResource.workingHours : '',
                Validators.required
            ]

        });
        this.changeFormValidity.emit(this.hennaArtistSpecificForm.valid);
    }

    private confirmModal(data: any): void {
        this.modalService.confirm(data).subscribe((res) => {
            console.log(res);
        });
    }

    public getErrorMessage(field: string): string {
        switch (field) {
            case 'workingHours' :
                return this.hennaArtistSpecificForm.get('workingHours').hasError('required') ?
                    'Working hours required' :
                    '';
            default :
                return '';
        }
    }

    public ngOnDestroy(): void {
        this.changeDetectSupporter.checkedSpecific = false;
    }

}
