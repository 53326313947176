import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ProfileManagementService } from '../../../../services/backend-consumers/supplier-service/profile-management.service';
import { DataKey, DataStoreService } from '../../../../services/util/framework/data-store.service';

@Component({
    selector: 'tc-add-more-lang-dialog',
    templateUrl: './add-more-lang-dialog.component.html'
})
export class AddMoreLangDialogComponent implements OnInit {

    @Input() selectedLanguages = [];

    languageDetails: any[]=[];

    constructor(
        private profileManagementService: ProfileManagementService,
        private fb: FormBuilder,
        private dataStore: DataStoreService
    ) {}

    ngOnInit() {
        this.getLangs();

    }

    private getLangs() {
        this.profileManagementService.retrieveAdditionalLanguages().subscribe(result => {
            this.languageDetails = result['data'];
        });
    }

    public addType(isSelected: any, docType: string) {
        if (isSelected.checked) {
            this.selectedLanguages.push(docType);
        } else {
            this.selectedLanguages.splice(this.selectedLanguages.indexOf(docType), 1);
        }
        this.dataStore.set(DataKey.languages, this.selectedLanguages, true);
        console.log(this.selectedLanguages);
    }
}
