import * as tslib_1 from "tslib";
import { ResourceSearchCriteria } from './resource-search-criteria';
var GenericSearchCriteria = /** @class */ (function (_super) {
    tslib_1.__extends(GenericSearchCriteria, _super);
    function GenericSearchCriteria() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.route = '';
        _this.transferMode = '';
        _this.routes = [];
        _this.transferModes = [];
        return _this;
    }
    return GenericSearchCriteria;
}(ResourceSearchCriteria));
export { GenericSearchCriteria };
