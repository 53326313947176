<div class="tc-ac__side-panel-container">
    <div class="tc-ac__side-panel">
        <div class="tc-ac__side-panel-header">
            <span class="tc-ac__side-panel-header-txt">{{'Items' | translate}}</span>
            <div class="tc-flex-box">
        <span class="tc-icon-block__icon sort-icon tc-icon-block__icon--active margin-remove" *ngIf="!viewOnlyMode">
          <!--<mat-icon id="season-settings-btn">settings</mat-icon>-->
        </span>
                <!--<span class="tc-accordion-header__down-icon"><mat-icon>keyboard_arrow_up</mat-icon></span>-->
            </div>

        </div>
        <div class="tc-ac__side-panel-body">
            <!--<span class="tc-ac__side-panel-item" *ngIf="!sideNavItems || sideNavItems.length===0">No Items Set</span>-->
            <div class="tc-ac__side-panel-item" *ngFor="let navItem of navItems">
                <mat-checkbox class="example-margin" *ngIf="!isRadioButton" [ngClass]="navItem.class" [checked]="navItem.checked" (change)="onSelect(navItem)" [(ngModel)]="navItem.checked" >{{navItem?.name}}</mat-checkbox>
                <mat-radio-button class="example-margin" *ngIf="isRadioButton" [ngClass]="navItem.class" [checked]="navItem.checked" (change)="onSelect(navItem)" [(ngModel)]="navItem.checked" >{{navItem?.name}}</mat-radio-button>
            </div>
        </div>

    </div>
</div>
