export enum BookingItemStatusType
{
    ANY = 'ANY',
    CANCELLATION_PENDING = 'CANCELLATION_PENDING',
    CONFIRMED = 'CONFIRMED',
    CANCELLED = 'CANCELLED',
    EXPIRED = 'EXPIRED',
    ONREQUEST = 'ONREQUEST',
    UNABLE = 'UNABLE',
    PENDING = 'PENDING',
    QUOTE = 'QUOTE',
    OTHER = 'OTHER'
}
