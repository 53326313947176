import * as tslib_1 from "tslib";
import { ChangeDetectorRef, EventEmitter, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TcErrorType } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-error';
import { DialogModel, LEVEL } from '@tc-core/model/it/codegen/ui/framework/dialog-model';
import { DialogService } from '@tc/dialog/dialog.service';
import { AgGridAngular } from 'ag-grid-angular';
import { forkJoin } from 'rxjs';
import { DMCCommon } from '../../../../../services/util/common/dmc-common';
import { DataKey, DataStoreService } from '../../../../../services/util/framework/data-store.service';
import { GridColumnDefinitionProcessorService } from '../../../../../services/util/pre-processors/grid-column-definition-processor.service';
import { AutoCompleteEditorComponent } from '../../editors/auto-complete-editor/auto-complete-editor.component';
import { DateEditorComponent } from '../../editors/date-editor/date-editor.component';
import { DropdownEditorComponent } from '../../editors/dropdown-editor/dropdown-editor.component';
import { MaxLengthTerminatorComponent } from '../../editors/max-length-terminator/max-length-terminator.component';
import { MaxLengthValidatorComponent } from '../../editors/max-length-validator/max-length-validator.component';
import { MultiSelectorEditorComponent } from '../../editors/multi-selector-editor/multi-selector-editor.component';
import { RadioButtonEditorComponent } from '../../editors/radio-button-editor/radio-button-editor.component';
import { ActionsComponent } from '../../renderers/actions/actions.component';
import { CheckBoxRendererEditorComponent } from '../../renderers/check-box-selector-renderer/check-box-renderer-editor.component';
import { TemplateRendererComponent } from '../../renderers/template-renderer/template-renderer.component';
import { GridCommonUtil } from '../../utils/grid-common-util';
import { SetupGridComp } from './setup-grid-comp';
var SetupGridComponent = /** @class */ (function () {
    function SetupGridComponent(cd, columnDefinitionProcessorService, translateService, dialogService, dataStoreService, common) {
        var _this = this;
        this.cd = cd;
        this.columnDefinitionProcessorService = columnDefinitionProcessorService;
        this.translateService = translateService;
        this.dialogService = dialogService;
        this.dataStoreService = dataStoreService;
        this.common = common;
        this.itemTypeSingular = 'Item';
        this.itemTypePlural = 'Items';
        this.readOnly = false;
        this.checkboxSelection = true;
        this.enableCustomAdd = false;
        this.disableAdd = false;
        this.disableDelete = false;
        this.disableRowDelete = false;
        this.enableBulkSendButton = false;
        this.noAction = false;
        this.actions = [];
        this.headerActions = [];
        this.kebabMenus = [];
        this.noSelection = false;
        this.cellEditingStart = null;
        this.actionColumnWidth = 65;
        this.frameworkComponents = {
            templateRenderer: TemplateRendererComponent,
            checkBoxRendererEditor: CheckBoxRendererEditorComponent,
            dropDownEditor: DropdownEditorComponent,
            multiSelectorEditor: MultiSelectorEditorComponent,
            radioButtonEditor: RadioButtonEditorComponent,
            autoCompleteEditor: AutoCompleteEditorComponent,
            dateEditor: DateEditorComponent,
            maxLengthValidator: MaxLengthValidatorComponent,
            maxLengthTerminator: MaxLengthTerminatorComponent
        };
        this.autoSize = false;
        this.invalidRowClass = 'tc-ag-grid-row--error';
        this._rowClassRules = {};
        // overlayLoadingTemplate = `<span class="loading-spinner"></span>`;
        // overlayNoRowsTemplate = `<span class="ag-overlay-no-rows-center">No items</span>`;
        this.overlayNoRowsTemplate = "<div class=\"tc-ag-grid-empty\">\n            <i class=\"material-icons tc-ag-grid-empty__icon\">assignment_error</i>\n            <span class=\"tc-ag-grid-empty__text\">No Records Available</span>\n        </div>";
        this.overlayLoadingTemplate = "<div class=\"tc-ag-grid-empty\">\n            <img src=\"assets/img/loading-tab.gif\" class=\"tc-mb-2\" alt=\"Loading\">\n            <span class=\"tc-ag-grid-empty__text\">Loading...</span>\n        </div>";
        this.rowSelection = 'multiple';
        this.rowDoubleClicked = new EventEmitter();
        this.rowClicked = new EventEmitter();
        this.customAddClick = new EventEmitter();
        this.bulkSendClick = new EventEmitter();
        this.unsavedRows = [];
        this.selectableColumn = {
            checkboxSelection: this.checkboxSelection,
            headerName: '',
            colId: 'index',
            filter: false,
            minWidth: 70,
            maxWidth: 70,
            pinned: 'left',
            suppressSizeToFit: true
        };
        this.actionColumn = {
            headerName: '',
            field: 'actions',
            minWidth: this.actionColumnWidth,
            maxWidth: this.actionColumnWidth,
            cellRendererFramework: ActionsComponent,
            headerClass: 'actions-header',
            cellRendererParams: {
                actions: []
            },
            filter: false,
            resizable: false,
            pinned: 'right',
            cellClass: ['actions-column'],
            suppressSizeToFit: true
        };
        this.kebabMenuColumn = {
            headerName: '',
            field: 'kebabActions',
            minWidth: this.actionColumnWidth,
            maxWidth: this.actionColumnWidth,
            cellRendererFramework: ActionsComponent,
            headerClass: 'actions-header',
            cellRendererParams: {
                kebabMenus: []
            },
            filter: false,
            resizable: false,
            pinned: 'right',
            suppressSizeToFit: true
        };
        this.deleteAction = {
            icon: 'delete',
            action: this.onDeleteClick.bind(this),
            tooltip: 'Delete',
            disable: this.disableDeleteRow.bind(this)
        };
        // to fix multi search issue at initial loading
        this.initialData = [];
        this.initialDataCount = 0;
        this.afterCellValueChange = function (event) {
            // todo testing after edit navigation
            // this.cellEditingStopped(event);
            if (!_this._setupGridComp.isInvalidRow(event)) {
                if (event.oldValue !== event.newValue) {
                    console.log(event);
                    event.data.unsaved = true;
                    _this.saveRowData(event);
                }
            }
            // this.gridApi.tabToNextCell();
        };
        this.translateService.onLangChange.subscribe(function () {
            _this.gridApi.refreshHeader();
        });
        this.defaultColDef = {
            filter: false,
            sortable: false,
            resizable: true,
            headerValueGetter: function (parameters) {
                var headerIdentifier = parameters.colDef.headerName;
                if (headerIdentifier) {
                    return _this.translateService.instant(headerIdentifier);
                }
                return '';
            }
        };
        this.gridOptions = {
            cacheBlockSize: 20,
            maxBlocksInCache: 2,
            enableServerSideFilter: true,
            enableServerSideSorting: false,
            rowModelType: 'infinite',
            pagination: true,
            paginationPageSize: 14
        };
    }
    Object.defineProperty(SetupGridComponent.prototype, "setupGridComp", {
        set: function (setupGridComp) {
            this._setupGridComp = setupGridComp;
            this.pushInvalidClassRuleToClassRules();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SetupGridComponent.prototype, "colDefConfig", {
        set: function (colDef) {
            this._colDefConfig = colDef;
            this.setColumnDefs();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SetupGridComponent.prototype, "rowClassRules", {
        set: function (rowClassRules) {
            this._rowClassRules = rowClassRules;
            this.pushInvalidClassRuleToClassRules();
        },
        enumerable: true,
        configurable: true
    });
    SetupGridComponent.prototype.ngOnInit = function () {
        this.checkMandatoryInputs();
        this.gridApi;
    };
    SetupGridComponent.prototype.ngOnChanges = function (changes) {
        this.checkMandatoryInputs();
    };
    SetupGridComponent.prototype.checkMandatoryInputs = function () {
        if (!this._colDefConfig) {
            throw new Error('Attribute \'colDefConfig\' is required');
        }
        if (!this._setupGridComp) {
            throw new Error('Attribute \'setupGridComp\' is required');
        }
    };
    SetupGridComponent.prototype.pushInvalidClassRuleToClassRules = function () {
        if (this._setupGridComp) {
            this._rowClassRules[this.invalidRowClass] = this._setupGridComp.isInvalidRow;
        }
    };
    SetupGridComponent.prototype.onFirstDataRendered = function (params) {
        if (this.autoSize) {
            this.autoSizeAll(false);
        }
        else {
            this.gridApi.sizeColumnsToFit();
        }
    };
    SetupGridComponent.prototype.autoSizeAll = function (skipHeader) {
        var allColumnIds = [];
        this.gridColumnApi.getAllColumns().forEach(function (column) {
            allColumnIds.push(column.colId);
        });
        this.gridColumnApi.autoSizeColumns(allColumnIds, skipHeader);
    };
    SetupGridComponent.prototype.onGridSizeChange = function ($event) {
        if (this.autoSize) {
            this.autoSizeAll(false);
        }
        else {
            this.gridApi.sizeColumnsToFit();
        }
    };
    SetupGridComponent.prototype.addUnSavedRowsAgainAfterReload = function () {
        if (this.unsavedRows && this.unsavedRows.length > 0) {
            this.addNewRows(this.unsavedRows);
            this.unsavedRows = [];
        }
    };
    SetupGridComponent.prototype.onGridReady = function ($event) {
        var _this = this;
        this.gridApi = $event.api;
        this.gridColumnApi = $event.columnApi;
        this.gridApi.setFloatingFiltersHeight(38);
        this.gridApi.setHeaderHeight(40);
        var dataSource = {
            getRows: function (params_) {
                _this.gridApi.showLoadingOverlay();
                _this.dataSource.getRows(params_)
                    .subscribe(function (result) {
                    if (result) {
                        _this.unsavedRows = [];
                        _this.gridApi.setFocusedCell(null, null);
                        _this.gridApi.hideOverlay();
                        var data = _this._setupGridComp.getDataFromResponse(result);
                        var totalRows = _this._setupGridComp.getTotalCountFromResponse(result);
                        params_.successCallback(data, totalRows);
                        if (totalRows === 0) {
                            _this.gridApi.showNoRowsOverlay();
                        }
                        _this.addUnSavedRowsAgainAfterReload();
                    }
                }, function (error) {
                    _this.gridApi.hideOverlay();
                    params_.successCallback([], 0);
                });
            }
        };
        this.searchCriteriaObserver = this.dataStoreService.get(DataKey.documentQueueSearchCriteria)
            .subscribe(function (value) {
            _this.searchCriteria = value;
            if (_this.searchCriteria && _this.searchCriteria.isForceSearch &&
                _this.searchCriteria.isForceSearch === true) {
                _this.gridApi.paginationGoToPage(0);
            }
        });
        $event.api.setDatasource(dataSource);
    };
    SetupGridComponent.prototype.setColumnDefs = function () {
        var colDefConfig = JSON.parse(JSON.stringify(this._colDefConfig));
        var coldDefs = this.columnDefinitionProcessorService.createColumnDefs(colDefConfig, this);
        if (!this.noSelection) {
            coldDefs.unshift(this.selectableColumn);
        }
        if (!this.noAction) {
            if (!this.readOnly) {
                this.actionColumn.cellRendererParams.actions.push(this.deleteAction);
                this.actionColumn.minWidth = this.actionColumnWidth;
                this.actionColumn.maxWidth = this.actionColumnWidth;
            }
            // adding input actions
            Array.prototype.push.apply(this.actionColumn.cellRendererParams.actions, this.actions);
            if (this.actionColumn.cellRendererParams.actions && this.actionColumn.cellRendererParams.actions.length >
                0) {
                coldDefs.push(this.actionColumn);
            }
            // adding input actions for kebab menu
            Array.prototype.push.apply(this.kebabMenuColumn.cellRendererParams.kebabMenus, this.kebabMenus);
            if (this.kebabMenuColumn.cellRendererParams.kebabMenus &&
                this.kebabMenuColumn.cellRendererParams.kebabMenus.length >
                    0) {
                coldDefs.push(this.kebabMenuColumn);
            }
        }
        this.columnDefs = coldDefs;
        this.cd.detectChanges();
        if (this.gridApi) {
            if (this.autoSize) {
                this.gridApi.autoSizeAllColumns();
            }
            else {
                this.gridApi.sizeColumnsToFit();
            }
        }
    };
    SetupGridComponent.prototype.saveRowData = function (event) {
        var _this = this;
        if (this.dataSource.saveRow(event.data, event)) {
            this.dataSource.saveRow(event.data, event).subscribe(function (success) {
                console.log(success);
                _this.unsavedRows = _this.getUnsavedUnselectedRows();
                console.log(_this.unsavedRows);
                _this.gridApi.refreshInfiniteCache();
            }, function (error) {
                console.log(error);
            });
        }
        this.refreshCells();
    };
    SetupGridComponent.prototype.onKeyDown = function (event) {
        console.log('key press');
        console.log(event);
    };
    SetupGridComponent.prototype.tabToNextCell = function (params) {
        var previousCell = params.previousCellPosition;
        var lastRowIndex = previousCell.rowIndex;
        var nextRowIndex = params.backwards ? lastRowIndex + 1 : lastRowIndex - 1;
        var renderedRowCount = this.gridApi.getModel().getRowCount();
        if (nextRowIndex < 0) {
            nextRowIndex = 0;
        }
        if (nextRowIndex >= renderedRowCount) {
            nextRowIndex = renderedRowCount - 1;
        }
        var result = {
            rowIndex: nextRowIndex,
            column: previousCell.column,
            floating: previousCell.floating
        };
        return result;
    };
    SetupGridComponent.prototype.addNewRow = function (newRow, selectRow, index) {
        var _this = this;
        if (index === void 0) { index = 0; }
        if (this.gridApi) {
            this.gridApi.updateRowData({ add: [newRow], addIndex: index });
            if (selectRow) {
                this.selectRow(newRow);
            }
        }
        else {
            setTimeout(function () { return _this.addNewRow(newRow, selectRow, index); }, 0);
        }
    };
    SetupGridComponent.prototype.addNewRows = function (newRows, index) {
        if (index === void 0) { index = 0; }
        var e_1, _a;
        try {
            for (var newRows_1 = tslib_1.__values(newRows), newRows_1_1 = newRows_1.next(); !newRows_1_1.done; newRows_1_1 = newRows_1.next()) {
                var newRow = newRows_1_1.value;
                this.addNewRow(newRow, false, index);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (newRows_1_1 && !newRows_1_1.done && (_a = newRows_1.return)) _a.call(newRows_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
    };
    SetupGridComponent.prototype.addNodes = function (nodes, index) {
        if (index === void 0) { index = 0; }
        var e_2, _a;
        try {
            for (var nodes_1 = tslib_1.__values(nodes), nodes_1_1 = nodes_1.next(); !nodes_1_1.done; nodes_1_1 = nodes_1.next()) {
                var node = nodes_1_1.value;
                this.addNewRow(node.data, false, index);
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (nodes_1_1 && !nodes_1_1.done && (_a = nodes_1.return)) _a.call(nodes_1);
            }
            finally { if (e_2) throw e_2.error; }
        }
    };
    SetupGridComponent.prototype.deleteSelected = function () {
        var _this = this;
        var confirmDialog = new DialogModel(true, LEVEL.WARNING, 'Delete', "Are you sure want to delete selected raws ?", true, 2000, null, 'No', 'Yes', true);
        this.dialogService
            .confirm(confirmDialog)
            .subscribe(function (res) {
            if (res === true) {
                var selectedRows = _this.getSelectedNodes();
                _this.unsavedRows = _this.getUnsavedUnselectedRows();
                _this.deleteAllInBackend(selectedRows).subscribe(function (success) {
                    _this.gridApi.refreshInfiniteCache();
                    _this.gridApi.deselectAll();
                }
                // , error => {
                //     this.gridApi.refreshInfiniteCache();
                //     this.gridApi.deselectAll();
                // }
                );
            }
        });
    };
    SetupGridComponent.prototype.refreshCells = function () {
        this.gridApi.refreshCells();
    };
    SetupGridComponent.prototype.deleteAllInBackend = function (rows) {
        var _this = this;
        return forkJoin(rows.map(function (row) { return _this.dataSource.deleteRow(row.data); }));
    };
    SetupGridComponent.prototype.deleteRow = function (row) {
        var _this = this;
        var confirmDialog = new DialogModel(true, LEVEL.WARNING, 'Delete', "Are you sure want to delete selected raw ?", true, 2000, null, 'No', 'Yes', true);
        var subscription = this.dialogService
            .confirm(confirmDialog)
            .subscribe(function (res) {
            if (res === true) {
                _this.unsavedRows = _this.getUnsavedRowsOtherThanThis(row);
                _this.dataSource.deleteRow(row).subscribe(function (success) {
                    _this.gridApi.refreshInfiniteCache();
                    _this.gridApi.deselectAll();
                }, function (error) {
                    _this.gridApi.refreshInfiniteCache();
                    _this.gridApi.deselectAll();
                    if (error['error'].error.details) {
                        _this.common.showSnackBar(error['error'].error.details, 3000, TcErrorType.TYPE.ERROR);
                    }
                });
            }
        });
    };
    SetupGridComponent.prototype.getSelectedNodes = function () {
        var selectedNodes = [];
        this.gridApi.forEachNode(function (node) {
            if (node.isSelected()) {
                selectedNodes.push(node);
            }
        });
        return selectedNodes;
    };
    SetupGridComponent.prototype.getUnsavedRowsOtherThanThis = function (row) {
        var _this = this;
        var unSelectedRows = [];
        this.gridApi.forEachNode(function (node) {
            if (node.data && _this._setupGridComp.isUnsavedRow(node.data) && node.data !== row) {
                unSelectedRows.push(node.data);
            }
        });
        return unSelectedRows;
    };
    SetupGridComponent.prototype.getUnsavedUnselectedRows = function () {
        var _this = this;
        var unSelectedRows = [];
        this.gridApi.forEachNode(function (node) {
            console.log(node);
            if (!node.isSelected() && _this._setupGridComp.isUnsavedRow(node)) {
                // unSelectedRows.push(node.data);
            }
        });
        return unSelectedRows;
    };
    SetupGridComponent.prototype.selectRow = function (row) {
        this.gridApi.forEachNode(function (node) {
            if (!node.isSelected()) {
                node.setSelected(node.data === row);
            }
            else {
                node.setSelected(true);
            }
        });
    };
    SetupGridComponent.prototype.onDeleteClick = function (params) {
        this.deleteRow(params.data);
    };
    SetupGridComponent.prototype.disableDeleteRow = function (params) {
        if (!this.disableRowDelete) {
            return this.disableRowDelete;
        }
        else {
            return !this._setupGridComp.isInvalidRow(params);
        }
    };
    SetupGridComponent.prototype.onAddClick = function (params) {
        var newRow = this._setupGridComp.createNewRow();
        this.addNewRow(newRow, false, 0);
    };
    SetupGridComponent.prototype.onCustomAddClick = function (params) {
        this.customAddClick.emit();
    };
    SetupGridComponent.prototype.onDeleteSelectedClick = function ($event) {
        this.deleteSelected();
    };
    SetupGridComponent.prototype.runForceSearch = function () {
        var _this = this;
        if (this.gridApi) {
            this.gridApi.refreshInfiniteCache();
        }
        else {
            setTimeout(function () { return _this.runForceSearch(); }, 100);
        }
    };
    SetupGridComponent.prototype.cellEditingStopped = function (event) {
        console.log('cell editing stopped');
        var nextCell = GridCommonUtil.getNextCell(event, true);
        this.gridApi.setFocusedCell(nextCell.rowIndex, nextCell.colKey);
        // problem if tab pressed before closi`ng the editor. tabToNext infinitely
        // this.gridApi.tabToNextCell();
    };
    SetupGridComponent.prototype.onClickColOption = function (event) {
        event.stopPropagation();
    };
    SetupGridComponent.prototype.onSelectionChange = function (event) {
        if (event && event.option) {
            var option = event.option.value;
            var selected = event.option.selected;
            if (this.gridColumnApi) {
                this.gridColumnApi.setColumnVisible(option, selected);
                if (this.autoSize) {
                    this.autoSizeAll(false);
                }
                else {
                    this.gridApi.sizeColumnsToFit();
                }
            }
        }
    };
    SetupGridComponent.prototype.onCellEditingStart = function ($event) {
        if (this.cellEditingStart) {
            this.cellEditingStart($event);
        }
    };
    SetupGridComponent.prototype.onRowDoubleClicked = function ($event) {
        this.rowDoubleClicked.emit($event);
    };
    SetupGridComponent.prototype.onRowClicked = function ($event) {
        this.rowClicked.emit($event);
    };
    SetupGridComponent.prototype.onBulkSendClick = function ($event) {
        this.bulkSendClick.emit($event);
    };
    SetupGridComponent.prototype.onRowSelected = function ($event) {
    };
    return SetupGridComponent;
}());
export { SetupGridComponent };
