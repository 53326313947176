<tc-product-queue-search-criteria
        [chipConfigKey]="chipConfigKey"
        [inputSearchCriteria]="inputSearchCriteria"
        [searchCriteriaUpdatingDataKey]="searchCriteriaUpdatingDataKey"
        [searchResultsDataKey]="searchResultsDataKey"
        [searchCriteriaLoadingDataKey]="searchCriteriaLoadingDataKey"
        [createNewSearchCriteriaFn]="createNewSearchCriteriaFn"
        [clearLoadedSearchCriteriaFn]="clearLoadedSearchCriteriaFn"
        [placeHolderText]="placeHolderText"
        [paginationConfigKey]="paginationConfigKey">
</tc-product-queue-search-criteria>
