import { Component, Input, OnInit } from '@angular/core';
import { TC } from '@tc-core/util/constants';
import { Resource } from '../../../models/resource/resource';

@Component({
    selector: 'tc-driver-specific-details-card',
    templateUrl: './driver-specific-details-card.component.html'
})
export class DriverSpecificDetailsCardComponent implements OnInit {

    @Input() resource: Resource;
    @Input() trsModes: any[] = [];

    EMPTY_CHAR = TC.FORMAT.EMPTY_CHAR;

    constructor() { }

    ngOnInit() {
    }

    public getTransferModeCodeName(transferMode: string) {
        let option = this.trsModes.find(value => value.code === transferMode);
        let codeName = '';
        if (option) {
            if (option.code) {
                codeName = option.code;
            }
            codeName += ' | ';
            if (option.name) {
                codeName += option.name;
            }
            return codeName;
        } else {
            return '';
        }
    }
}
