import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ChipInputModule } from '@tc/chip-input';
import { GenReservationManagementService } from '../../../services/backend-consumers/reservation-management-services/gen-reservation-management.service';
import { GenReservationSearchCriteriaComponent } from './gen-reservation-search-criteria.component';

@NgModule({
    declarations: [
        GenReservationSearchCriteriaComponent
    ],
    imports: [
        CommonModule,
        ChipInputModule
    ],
    exports: [
        GenReservationSearchCriteriaComponent
    ],
    entryComponents: [
        GenReservationSearchCriteriaComponent
    ],
    providers:[
        GenReservationManagementService
    ]
})
export class GenReservationSearchCriteriaModule {}
