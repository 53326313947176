import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatInputModule, MatStepperModule } from '@angular/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DataStore } from '@tc-core/util/framework';
import { CommonHelper } from '@tc-core/util/helpers';
import { StepperModule } from '@tc/stepper';
import { LeaveSetupDetailViewModule } from '../../widgets/shared/leave-setup-detail-view/leave-setup-detail-view.module';
import { LeaveSetupEditCardComponent } from '../../widgets/shared/leave-setup-edit-card/leave-setup-edit-card.component';
import { LeaveSetupEditCardModule } from '../../widgets/shared/leave-setup-edit-card/leave-setup-edit-card.module';
import { LeaveSetupResourceSummaryViewModule } from '../leave-setup-resource-summary-view/leave-setup-resource-summary-view.module';
import { LeaveSetupSearchCriteriaComponent } from '../leave-setup-search-criteria/leave-setup-search-criteria.component';
import { LeaveSetupSearchCriteriaModule } from '../leave-setup-search-criteria/leave-setup-search-criteria.module';
import { LeaveSetupSearchResultsModule } from '../leave-setup-search-results/leave-setup-search-results.module';
import { ResourceAvailabilityService } from '../../services/backend-consumers/supplier-service/resource-availability.service';
import { LeaveSetupFocusViewComponent } from './leave-setup-focus-view.component';

@NgModule({
  declarations: [LeaveSetupFocusViewComponent],
  imports: [
    CommonModule,
      StepperModule,
      MatInputModule,
      FormsModule,
      MatStepperModule,
      LeaveSetupSearchCriteriaModule,
      LeaveSetupSearchResultsModule,
      LeaveSetupEditCardModule,
      BrowserAnimationsModule,
      LeaveSetupDetailViewModule,
      LeaveSetupResourceSummaryViewModule
  ],
    providers: [
        DataStore,
        CommonHelper,
        ResourceAvailabilityService
    ],
    exports: [
        LeaveSetupFocusViewComponent
    ],
    entryComponents: [LeaveSetupSearchCriteriaComponent,LeaveSetupEditCardComponent]
})
export class LeaveSetupFocusViewModule { }
