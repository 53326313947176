import {AbstractSearchCriteria} from "./abstract-search-criteria";
import {CommonDataType} from "../reservation-v2/common-data-type";

export class CommonDataSearchCriteria extends AbstractSearchCriteria {
    commonDataType: CommonDataType;
    codeOrName: string;
    code: string;
    name: string;
    countryCode: string;
    stateCode: string;
    locationGroupCode: string;
    locationType: string;
    locationCode: string;
    routeCode: string;

    constructor(commonDataType: CommonDataType) {
        super();
        this.commonDataType = commonDataType;
    }
}
