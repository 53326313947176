import { Injectable } from '@angular/core';
import { ConfigLoader } from '@tc-core/util/framework';
import { Observable } from 'rxjs';
import { AccountingRuleSearchCriteria } from '../../../models/criteria/accounting-rule-search-criteria';
import { LedgerEntrySearchCriteria } from '../../../models/criteria/ledger-entry-search-criteria';
import { DMCQueryParamsService } from '../../util/framework/dmc-query-params.service';
import { RequestService } from '../../util/framework/request.service';
import { UrlPaths } from '../url-paths';

@Injectable({
    providedIn: 'root'
})
export class AccountingRuleService {

    constructor(
        private configLoader: ConfigLoader,
        private requestService: RequestService,
        private queryParamsService: DMCQueryParamsService
    ) {
    }

    public getRows(criteria: AccountingRuleSearchCriteria): Observable<any> {
        const reqPrams = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.get(
            UrlPaths.OP_ACC_URL_KEY,
            [UrlPaths.OP_ACC_VERSION, UrlPaths.ACCOUNTING_RULES],
            reqPrams
        );
    }

    public deleteRow(id: number): Observable<any> {
        return this.requestService.delete(
            UrlPaths.OP_ACC_URL_KEY,
            [UrlPaths.OP_ACC_VERSION, UrlPaths.ACCOUNTING_RULES, id.toString()]
        );
    }

    public saveRow(row: any): Observable<any> {
        return this.requestService.post(
            UrlPaths.OP_ACC_URL_KEY,
            [UrlPaths.OP_ACC_VERSION, UrlPaths.ACCOUNTING_RULES],
            row
        );
    }

    public getAccountingEvents(criteria) {
        const reqPrams = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.get(
            UrlPaths.OP_ACC_URL_KEY,
            [UrlPaths.OP_ACC_VERSION, UrlPaths.ACCOUNTING_EVENTS],
            reqPrams
        );
    }

    public getAccountingUnits(criteria) {
        const reqPrams = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.get(
            UrlPaths.OP_ACC_URL_KEY,
            [UrlPaths.OP_ACC_VERSION, UrlPaths.ACCOUNTING_UNITS],
            reqPrams
        );
    }

    public getLedgerAccounts(criteria) {
        const reqPrams = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.get(
            UrlPaths.OP_ACC_URL_KEY,
            [UrlPaths.OP_ACC_VERSION, UrlPaths.LEDGER_ACCOUNTS],
            reqPrams
        );
    }

    public saveLedgerAccount(row: any): Observable<any> {
        const reqPrams = this.queryParamsService.asQueryParams({
            importedFromScheduler: false
        });
        return this.requestService.post(
            UrlPaths.OP_ACC_URL_KEY,
            [UrlPaths.OP_ACC_VERSION, UrlPaths.LEDGER_ACCOUNTS],
            row,
            reqPrams
        );
    }

    public deleteLedgerAccount(code: string): Observable<any> {
        return this.requestService.delete(
            UrlPaths.OP_ACC_URL_KEY,
            [UrlPaths.OP_ACC_VERSION, UrlPaths.LEDGER_ACCOUNTS, code]
        );
    }

    public searchLedgerEntries(criteria: LedgerEntrySearchCriteria): Observable<any> {
        const reqPrams = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.get(
            UrlPaths.OP_ACC_URL_KEY,
            [UrlPaths.OP_ACC_VERSION, UrlPaths.LEDGER_ENTRIES],
            reqPrams
        );
    }

    public generateLedgers(params) {
        const reqPrams = this.queryParamsService.asQueryParams(params);
        return this.requestService.post(
            UrlPaths.OP_ACC_URL_KEY,
            [UrlPaths.OP_ACC_VERSION, UrlPaths.LEDGER_ENTRIES],
            null,
            reqPrams
        );
    }
}
