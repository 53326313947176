export class SplitPassengerData{
    id:number;
    name:string;
    type:string;
    needAssistance:boolean;
    assignedGroup:number;
    group:S_PassengerGroup;
}

export class SplitPassengerDataHeader {
    name:string;
}
export class S_PassengerGroup {
    name:string;
    code:number;
}

