<tc-setup-grid
    #setupGrid
    [readOnly]="true"
    [noSelection]="true"
    [colDefConfig]="colDefConfig"
    [setupGridComp]="this"
    [enableBulkSendButton]="true"
    [kebabMenus]="gridActions"
    [dataSource]="this"
    (bulkSendClick)="onBulkSendClick($event)">
</tc-setup-grid>

<ng-template #statusCell let-row>
    <ng-container *ngIf="row?.dispatchStatus">
        <div class="tc-w-100 text-center">
            <span class=""> {{row.dispatchStatus}}</span>
        </div>
    </ng-container>
</ng-template>
