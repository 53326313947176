import { Injectable } from '@angular/core';

export class NotificationToastModel {
    id: number;
    title: string;
    body: string;
    timeout: number;
    cssClass: string;
    onClick: any;
    matIcon: string;
    sound: string;
    rawData: any;
}

@Injectable()
export class NotificationToastConfig {
    limit = 10;
    timeout = 5000;
}

export class NotificationToastEvent {
    type: NotificationToastEventType;
    toast: NotificationToastModel;
    toastId: number;
}

export enum NotificationToastEventType {
    ADD,
    REMOVE,
    REMOVE_ALL
}
