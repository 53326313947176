import { DispatchStatus } from '../document/dispatch-entry';
import { MainType } from '../reservation-v2/main-type';
import { AbstractSearchCriteria } from './abstract-search-criteria';
import { DocumentFormat } from './document-creation-criteria';
import {KeyControlAndAbstractSearchCriteria} from './key-control-and-abstract-search-criteria';

export class DocumentQueueSearchCriteria extends KeyControlAndAbstractSearchCriteria {
    startDate = '';
    endDate = '';
    dispatchStartDate =  new Date().toISOString().split('T')[0];
    dispatchEndDate = new Date(+new Date() + 172800000).toISOString().split('T')[0];
    supplierCode = '';
    supplierName = '';
    dispatchStatus = DispatchStatus.FAILED;
    bookingSourceItemNo: number = null;
    mainType = MainType.TRS;
    backgroundTaskId: number = null;
    size = 14;

}
