export class InsuranceInformation {
    insuranceInfoId: number;
    insuranceCompanyName: string;
    policyNumber: string;
    insuredDate: string;
    insuranceExpiryDate: string;
    email: string;
    altEmail: string;
    telephone: string;
    altTelephone: string;
    country: string;
    state: string;
    city: string;
    street: string;
}
