import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ICellRendererParams } from 'ag-grid-community';
import { TemplateRendererComponent } from '../../../../widgets/framework/ag-grid-custom-components/renderers/template-renderer/template-renderer.component';
import { Passenger } from '../../../../models/reservation-v2/passenger';
import { BookingPassengerGroup } from '../passenger-editor.component';
import {CheckBoxRendererEditorComponent} from '../../../../widgets/framework/ag-grid-custom-components/renderers/check-box-selector-renderer/check-box-renderer-editor.component';
import {TCO} from '../../../../constants';
import {ConfigLoader} from '@tc-core/util/framework';
import {GridColumnDefinitionProcessorService} from '../../../../services/util/pre-processors/grid-column-definition-processor.service';

@Component({
    selector: 'tc-booking-passenger-group',
    templateUrl: './booking-passenger-group.component.html'
})
export class BookingPassengerGroupComponent implements OnInit, AfterViewInit {
    constructor(
        private translateService: TranslateService,
        private configLoader: ConfigLoader,
        private columnDefinitionProcessorService: GridColumnDefinitionProcessorService,
    ) {
        this.translateService.onLangChange.subscribe(() => {
            this.gridApi.refreshHeader();
        });
    }

    @Input() group: BookingPassengerGroup;
    @Output() newRowSelectEvent = new EventEmitter<any>();
    columnDefs;

    gridApi;
    gridOptions: any = {};
    frameworkComponents: any = {
        templateRenderer: TemplateRendererComponent,
        checkBoxRendererEditor: CheckBoxRendererEditorComponent
    };


    defaultColDef = {
        resizable: true,
        headerValueGetter: (parameters: ICellRendererParams): string => {
            const headerIdentifier = parameters.colDef.headerName;
            if (headerIdentifier) {
                return this.translateService.instant(headerIdentifier);
            }
            return '';
        }
    };

    @ViewChild('passengerInfo') passengerInfo: TemplateRef<any>;

    ngOnInit() {
        this.columnDefs = this.columnDefinitionProcessorService.createColumnDefs(this.configLoader.configurations.get(TCO.CONF.CONF_PASSENGERS_GRID), this);
    }

    public onGridReady(params) {
        this.gridApi = params.api;
        this.gridApi.sizeColumnsToFit();
    }

    public onFirstDataRendered(params) {
        this.gridApi = params.api;
        this.gridApi.sizeColumnsToFit();
    }

    public onRowSelected(params) {
        this.newRowSelectEvent.emit(params);
    }

    getToolTip(row: Passenger) {
        let text = '';
        if (row.passengerAttributes && row.passengerAttributes.length) {
            for (const passengerAttribute of row.passengerAttributes) {
                text += passengerAttribute.name + '=' + passengerAttribute.value + '  ';
            }
        }
        return text;
    }

    getFullName(params) {
        if (params.data) {
            return params.data.firstName + ' ' + params.data.lastName;
        }
    }

    validatePaxAge(params) {
        if (params.data) {
            if (params.data.age > 0) {
                return params.data.age;
            } else {
                return '';
            }
        }
    }

    validateTelephoneNo(params) {
        if (params.data) {
            if (params.data.telephone != null) {
                return params.data.telephone;
            } else {
                return '';
            }
        }
    }

    public ngAfterViewInit(): void {
    }
}
