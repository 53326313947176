import { HttpParams } from '@angular/common/http';
import { HttpParamsOptions } from '@angular/common/http/src/params';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { QueryParamField } from '@tc-core/model/it/codegen/ui/query-param-field';
import { TCHttpParamsOptions } from '@tc-core/model/it/codegen/ui/framework/tc-httpParams-options';
import { DataStoreService } from './data-store.service';

/**
 * Created by charitha on 8/4/2017.
 */
@Injectable()
export class DMCQueryParamsService {

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private dataStore: DataStoreService
  ) {
  }

  /**
   * Set properties of the data object as query params
   * @param key identifier of the data object
   * @param parameters if not all properties are needed, only these will be set to the query params
   * @param resetQParams
   * @returns {Params} query params instance
   */
  asRouteParams(key: string, parameters: QueryParamField[] = [], resetQParams?: boolean): Params {
    let urlParams: Params = {};
    const obj = this.dataStore.get(key, false);

    const params = this.activatedRoute.snapshot.queryParams;
    for (let k in params) {
      if (params.hasOwnProperty(k)) {

        let addToParams = this.handleUrlParams(k, parameters, resetQParams);
        if (addToParams) {
          urlParams[k] = params[k];
        }
      }
    }

    // if obj is not an object
    let type = typeof obj;
    if (type !== 'object' && type !== 'undefined') {
      urlParams[key] = obj;
    }

    if (parameters.length > 0) {
      parameters.forEach(qpf => {
        if (obj != null && qpf && obj.hasOwnProperty(qpf.param) && obj[qpf.param] !== undefined) {
          urlParams[qpf.param] = obj[qpf.param];
        }
      });
    }

    this.activatedRoute.snapshot.queryParams = urlParams;
    return urlParams;
  }

  routeToSelected(key: string, params: HttpParams, proceedOnexisting: boolean) {
    let paramObjStr = params.get(key);
    if (JSON.stringify(paramObjStr) !== JSON.stringify({})) {
      if (proceedOnexisting) {
        let paramObj = JSON.parse(paramObjStr);
        this.dataStore.set(key, paramObj, false);
      }
    }
  }

  asQueryParams(criteria: any): HttpParams {
    const tcHttpParamsOptions = new TCHttpParamsOptions();
    const httpParamsOptions = {};

    const keys = this.getNotNullQueryParamsArray(criteria);
    if (keys) {
      for (let i = 0; i < keys.length; i++) {
        if (criteria.hasOwnProperty(keys[i])) {
          httpParamsOptions[keys[i]] = criteria[keys[i]];
        }
      }
      tcHttpParamsOptions.fromObject = httpParamsOptions;
    }
    return new HttpParams(tcHttpParamsOptions as HttpParamsOptions);
  }

  asString(criteria: any, routeParams?: string[]): string {
    let params = '';

    let keys = Object.keys(criteria);
    for (let i = 0; i < keys.length; i++) {
      if (i === 0) {
        params += '?';
      } else {
        params += '&';
      }

      if (criteria.hasOwnProperty(keys[i])) {
        params += keys[i] + '=' + criteria[keys[i]];
      }
    }

    return params;
  }

  handleUrlParams(paramName: any, parameters: QueryParamField[], resetQParams?: boolean) {

    let addToParams: boolean = false;

    if (resetQParams) {
      let isExists: boolean = false;
      let index: number = -1;
      for (let i = 0; i < parameters.length; i++) {
        if (parameters[i] && parameters[i].param === paramName) {
          isExists = true;
          index = i;
          break;
        }
      }

      if (isExists) {
        if (parameters[index].field) {
          addToParams = true;
        } else {
          addToParams = false;
        }
      } else {
        addToParams = true;
      }
    } else {
      addToParams = true;
    }
    return addToParams;
  }

  asQueryParamsArray(params: any): QueryParamField[] {

    let paramsArray: QueryParamField[] = [];

    for (let key in params) {
      if (params.hasOwnProperty(key)) {
        let queryParam = new QueryParamField();
        queryParam.param = key;
        queryParam.field = key;
        paramsArray.push(queryParam);
      }
    }
    return paramsArray;
  }

  getQueryParamsArray(obj: any): string[] {
    let paramsArr: string[] = [];
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        paramsArr.push(key);
      }
    }
    return paramsArr;
  }

  getNotNullQueryParamsArray(object: any) {

    if (object && Object.keys(object).length > 0) {
      return Object.keys(object).filter(key => {
        const value = object[key];
        if (value != null && value !== undefined && value.toString().length > 0) {
          return key;
        }
      });
    }
  }

  deleteQueryParam(key: string) {
    let params = this.activatedRoute.snapshot.queryParams;
    let keys = Object.keys(params);
    let newParams = {};

    for (let i = 0; i < keys.length; i++) {
      if (keys[i] !== key) {
        newParams[keys[i]] = params[keys[i]];
      } else {
        this.dataStore.set(key, null, false);
      }
    }
    this.router.navigate([], {
      queryParams: newParams,
      relativeTo: this.activatedRoute,
      replaceUrl: true
    });
  }

  getQueryParamValue(key:string):string{
    return this.activatedRoute.snapshot.paramMap.get(key);
  }

  // todo complete implementation
  // public deleteQueryParams(keys: string) {
  //   let urlParams: Params = {};
  //   const params = this.activatedRoute.snapshot.queryParams;
  //   for (let key in params) {
  //     // if the list of keys that are needed to be deleted does not have this key
  //     if (params.hasOwnProperty(key) && keys.indexOf(key) === -1) {
  //       urlParams[key] = params[key];
  //     }
  //   }
  //   this.router.navigate([], {
  //     queryParams: this.asRouteParams(key, parameters, resetQParams),
  //     relativeTo: this.activatedRoute,
  //     replaceUrl: true
  //   });
  // }
}
