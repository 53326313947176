import { Injectable } from '@angular/core';
import { ConfigLoader } from '@tc-core/util/framework';
import { Observable } from 'rxjs';
import { LocationGroupsSearchCriteria } from '../../../models/criteria/location-groups-search-criteria';
import { LocationsSearchCriteria } from '../../../models/criteria/locations-search-criteria';
import { RoomFacilitySearchCriteria } from '../../../models/criteria/room-facility-search-criteria';
import { RoomTypeSearchCriteria } from '../../../models/criteria/room-type-search-criteria';
import { LeaveSetup } from '../../../models/leave-setup';
import { Leave } from '../../../models/resource/leave';
import { OPResponseWrapper } from '../../../models/response/op-response-wrapper';
import { DataKey } from '../../util/framework/data-store.service';
import { DMCQueryParamsService } from '../../util/framework/dmc-query-params.service';
import { RequestService } from '../../util/framework/request.service';
import { UrlPaths } from '../url-paths';
@Injectable({
    providedIn: 'root'
})
export class AccommodationService {
    constructor(
        private configLoader: ConfigLoader,
        private requestService: RequestService,
        private queryParamsService: DMCQueryParamsService
    ) { }

    /*********
     Room Types
     *********/

    public getRoomTypes(criteria: RoomTypeSearchCriteria): Observable<any> {
        const reqPrams = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.get(
            UrlPaths.OP_DATA_URL_KEY,
            [UrlPaths.OP_DATA_VERSION, UrlPaths.ROOM_TYPES],
            reqPrams
        );
    }

    public deleteRoomType(code: string): Observable<any> {
        return this.requestService.delete(
            UrlPaths.OP_DATA_URL_KEY,
            [UrlPaths.OP_DATA_VERSION, UrlPaths.ROOM_TYPES, code]
        );
    }

    public saveRoomType(row: any): Observable<any> {
        return this.requestService.post(
            UrlPaths.OP_DATA_URL_KEY,
            [UrlPaths.OP_DATA_VERSION, UrlPaths.ROOM_TYPES],
            row
        );
    }

    public updateRoomType(code: string, row: any): Observable<any> {
        return this.requestService.put(
            UrlPaths.OP_DATA_URL_KEY,
            [UrlPaths.OP_DATA_VERSION, UrlPaths.ROOM_TYPES, code],
            row
        );
    }

    /***************
     Room Facilities
     ***************/

    public getRoomFacilities(criteria: RoomFacilitySearchCriteria): Observable<any> {
        const reqPrams = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.get(
            UrlPaths.OP_DATA_URL_KEY,
            [UrlPaths.OP_DATA_VERSION, UrlPaths.ROOM_FACILITIES],
            reqPrams
        );
    }

    public deleteRoomFacility(id: any): Observable<any> {
        return this.requestService.delete(
            UrlPaths.OP_DATA_URL_KEY,
            [UrlPaths.OP_DATA_VERSION, UrlPaths.ROOM_FACILITIES, id]
        );
    }

    public saveRoomFacility(row: any): Observable<any> {
        return this.requestService.post(
            UrlPaths.OP_DATA_URL_KEY,
            [UrlPaths.OP_DATA_VERSION, UrlPaths.ROOM_FACILITIES],
            row
        );
    }

    public updateRoomFacility(id: any, row: any): Observable<any> {
        return this.requestService.put(
            UrlPaths.OP_DATA_URL_KEY,
            [UrlPaths.OP_DATA_VERSION, UrlPaths.ROOM_FACILITIES, id],
            row
        );
    }

}
