<div class="detail-group__body-service-item container-fluid">
    <div class="detail-group__heading container-fluid">
        <div class="item-header">
            <div class="item-header__content">{{'Booking Passengers' | translate}}</div>
        </div>
    </div>
    <div class="tc-px-3">
        <mat-accordion *ngFor="let passengerGroup of bookingPassengerGroup; let i=index" [attr.data-index]="i">
            <mat-expansion-panel class="tc-mb-0">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <mat-checkbox
                                *ngIf="pageOptionsAvailability.checkbox"
                                color="primary"
                                [disabled]="readOnly"
                                (change)="onGroupSelected($event, passengerGroup)"
                                (click)="onClickGroupCheckBox($event)">
                            {{passengerGroup.bookingReferenceId}}
                        </mat-checkbox>
                        <span id="booking-item-ref-{{i}}" *ngIf="!pageOptionsAvailability.checkbox">{{passengerGroup.bookingReferenceIdWithoutReturnFlag == null ? passengerGroup.bookingReferenceId : passengerGroup.bookingReferenceIdWithoutReturnFlag }}</span>
                    </mat-panel-title>
                    <mat-panel-description>
                        <div id="booking-item-pax-count-{{i}}">
                            {{passengerGroup.bookingPassengers.length }}{{' Passengers Available' | translate}}
                        </div>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <tc-booking-passenger-group
                    #bookingPassengerGroupComponent
                    [group]="passengerGroup"
                    (newRowSelectEvent)="onRowSelected($event)">
                </tc-booking-passenger-group>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>

<div class="container assign-panel-footer-section">
    <div
            *ngIf="splitButtonDisabledMsg && pageOptionsAvailability.split_button_disabled_msg"
            style="color: red">{{splitButtonDisabledMsg}}</div>
    <button
            class="assign-panel-footer-section__button tc-button-secondary mat-button"
            (click)="onClose($event)">
        {{'Close' | translate}}
    </button>
    <button
            *ngIf="pageOptionsAvailability.split"
            class="assign-panel-footer-section__button tc-button-primary mat-button"
            (click)="onSplit()"
            [disabled]="!splitButtonEnable">
        {{'Split' | translate}}
    </button>
</div>
