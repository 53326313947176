import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User } from '@tc-core/model/it/codegen/tbx/api/authority/v2/users/user';
import { DialogModel, LEVEL } from '@tc-core/model/it/codegen/ui/framework/dialog-model';
import { APIErrorLog } from '@tc-core/model/it/codegen/ui/framework/log/api-error-log';
import { HeaderInfo } from '@tc-core/model/it/codegen/ui/framework/log/header-info';
import { RequestLog } from '@tc-core/model/it/codegen/ui/framework/log/request-log';
import { TCRequestOptions } from '@tc-core/model/it/codegen/ui/request-option';
import { ConfigLoader, LogStore, UserJourneyManager } from '@tc-core/util/framework';
import { CommonHelper } from '@tc-core/util/helpers';
import { AuthorizationService } from '@tc-core/util/security';
import { DialogService } from '@tc/dialog/dialog.service';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, publishReplay, refCount } from 'rxjs/operators';
import { AuthTokenService } from '../../../authentication/auth-token.service';
import { DocumentDataCriteria } from '../../../models/criteria/document-data-criteria';
import { DMCCommon } from '../common/dmc-common';
import { DataKey, DataStoreService } from '../framework/data-store.service';
import { DMCLocalStorageService } from './dmc-local-storage.service';
import {SpinnerService} from '@tc-core/util/ui';

@Injectable()
export class DMCBaseService
{

    public static LOGGING_REQUEST_CONTEXT = 'Request';
    public static LOGGING_ERROR_CONTEXT = 'APIError';

    private headers: HttpHeaders = new HttpHeaders();
    private requestOptionsArgs: TCRequestOptions = new TCRequestOptions();
    private lastJourney: string = '';
    private lastJourneyId: any;
    private sessionId: string;
    private user: User;
    private sessionExpirePopupOpened: boolean = false;

    public logData: BehaviorSubject<any> = new BehaviorSubject(0);
    public isLoggedOut: BehaviorSubject<boolean> = new BehaviorSubject(false);

    constructor(
        protected http: HttpClient,
        private authorizationService: AuthorizationService,
        private userJourneyManager: UserJourneyManager,
        private logStore: LogStore,
        private dataStore: DataStoreService,
        private commonHelper: CommonHelper,
        private dialogService: DialogService,
        private configLoader: ConfigLoader,
        private autTokenService: AuthTokenService,
        private localStorageService: DMCLocalStorageService,
        private commonService: DMCCommon,
        private spinnerService: SpinnerService
    ) {
        this.user = this.dataStore.get(DataKey.userDetail).getValue();
        this.sessionId = this.generateUuid();
        this.isLoggedOut.subscribe(
            response => {
                if (response) {
                    this.sessionId = this.generateUuid();
                    this.autTokenService.clearTokenParams();
                    this.localStorageService.clear();
                    this.redirectToLoginPage();
                }
            }
        );
    }

    private redirectToLoginPage() {
        const loginUrl = this.commonService.getLoginUrl();
        window.open(loginUrl, '_self');
    }

    /**
     * if cache enabled this tells Rx to cache the latest emitted value
     * and tells Rx to keep the Observable alive as long as there are any Subscribers
     * @param {string} url
     * @param {RequestOptionsArgs} options
     * @param {boolean} cache
     * @returns {any}
     */
    public get<T>(url: string, options?: TCRequestOptions, cache: boolean = false): Observable<HttpResponse<T>> {
        this.createRequestHeaders(options);
        this.logRequest('GET', url, options);

        let opts = options ? options : this.requestOptionsArgs;

        if (cache) {
            return this.http.get<T>(url, {
                observe: 'response',
                headers: opts.headers,
                params: opts.params
            }).pipe(
                catchError(this.handleError.bind(this)),
                publishReplay(1),
                refCount()
            );
        } else {
            return this.http.get<T>(url, {
                observe: 'response',
                headers: opts.headers,
                params: opts.params
            }).pipe(
                catchError(this.handleError.bind(this))
            );
        }
    }
    public getForBlob<T>(url: string, params: string, dataCriteria: DocumentDataCriteria): Observable<HttpResponse<T>> {
        return this.http.post<any>(url + params, dataCriteria, {
            observe: 'response',
            responseType: 'blob' as 'json'
        }).pipe(
            catchError(this.handleError.bind(this))
        );
    }

    /**
     * @param url
     * @param body
     * @param options
     */
    public post<T>(url: string, body: any, options?: TCRequestOptions): Observable<HttpResponse<T>> {
        this.authorizationService.prepareResponse('POST', url, body, options);
        this.createRequestHeaders(options);
        this.logRequest('POST', url, options);

        let opts = options ? options : this.requestOptionsArgs;
        return this.http.post<T>(url, body, {
            observe: 'response',
            headers: opts.headers,
            params: opts.params
        }).pipe(
            catchError(this.handleError.bind(this))
        );
    }

    /**
     * @param url
     * @param body
     * @param options
     */
    public put<T>(url: string, body: any, options?: TCRequestOptions): Observable<HttpResponse<T>> {
        this.authorizationService.prepareResponse('PUT', url, body, options);
        this.createRequestHeaders(options);
        this.logRequest('PUT', url, options);
        let opts = options ? options : this.requestOptionsArgs;
        return this.http.put<T>(url, body, {
            observe: 'response',
            headers: opts.headers,
            params: opts.params
        }).pipe(
            catchError(this.handleError.bind(this))
        );
    }

    /**
     * @param url
     * @param body
     * @param options
     */
    public patch<T>(url: string, body: any, options?: TCRequestOptions): Observable<HttpResponse<T>> {
        this.authorizationService.prepareResponse('PUT', url, body, options);
        this.createRequestHeaders(options);
        this.logRequest('PATCH', url, options);
        let opts = options ? options : this.requestOptionsArgs;
        return this.http.patch<T>(url, body, {
            observe: 'response',
            headers: opts.headers,
            params: opts.params
        }).pipe(
            catchError(this.handleError.bind(this))
        );
    }

    /**
     * @param url
     * @param options
     */
    public delete<T>(url: string, options?: TCRequestOptions): Observable<HttpResponse<T>> {
        this.createRequestHeaders(options);
        this.logRequest('DELETE', url, options);
        let opts = options ? options : this.requestOptionsArgs;
        return this.http.delete<T>(url, {
            observe: 'response',
            headers: opts.headers,
            params: opts.params
        }).pipe(
            catchError(this.handleError.bind(this))
        );
    }

    /**
     * @param {string} url
     * @param body
     * @param {RequestOptionsArgs} options
     * @returns {Observable<Response>}
     */
    public log<T>(url: string, body: any, options?: TCRequestOptions): Observable<HttpResponse<T>> {
        this.createRequestHeaders(options);
        let opts = options ? options : this.requestOptionsArgs;
        return this.http.post<T>(url, body, {
            observe: 'response',
            headers: opts.headers,
            params: opts.params
        }).pipe(
            catchError(this.handleError.bind(this))
        );
    }

    public setHeaders(headers: HttpHeaders) {
        this.headers = headers;
    }

    public returnJsonResponse(res: Response) {
        if (res.ok) {
            return res.json();
        }
    }

    public overrideUserDetailInRequestBody(body: any) {
        if (body && body.keyControls && this.commonHelper.hasProperty(this.user, 'id') &&
            this.commonHelper.hasProperty(this.user, 'userSummary.username')) {
            body.keyControls.userId = this.user.id;
            body.keyControls.username = this.user.userSummary.username;
        }
    }

    public createHeaders(options: any) {
        if (!options.headers) {
            options.headers = new Headers({'Content-Type': 'text/plain'});
        }
        let params = this.getQueryString();
        // options.headers.append('tbx-trace-log-enable', 'true');
        if (this.commonHelper.hasProperty(this.user, 'id') &&
            this.commonHelper.hasProperty(this.user, 'userSummary.username')) {
            options.headers.append('tbx-web-user-id', this.user.id);
            options.headers.append('tbx-web-user-name', this.user.userSummary.username);
        }
        options.headers.append('tbx-web-client-id', params['cliId']);
        options.headers.append('tbx-journey', params['journey']);
        options.headers.append('messageId', this.generateUuid());
        options.headers.append('user', params['username']);
        options.headers.append('sessionId', this.sessionId);
        if (this.lastJourney !== params['journey']) {
            this.lastJourney = params['journey'];
            this.lastJourneyId = this.generateUuid();
        }
        options.headers.append('conversationId', this.lastJourneyId);
    }

    createFileUploadHeaders(headers: HttpHeaders) {
        let params = this.getQueryString();
        // options.headers.append('tbx-trace-log-enable', 'true');
        if (this.commonHelper.hasProperty(this.user, 'id') &&
            this.commonHelper.hasProperty(this.user, 'userSummary.username')) {
            headers = headers.append('tbx-web-user-id', String(this.user.id));
            headers = headers.append('tbx-web-user-name', this.user.userSummary.username);
        }
        headers = headers.append('tbx-web-client-id', params['cliId']);
        headers = headers.append('tbx-journey', params['journey']);
        headers = headers.append('messageId', this.generateUuid());
        headers = headers.append('user', params['username']);
        headers = headers.append('sessionId', this.sessionId);
        if (this.lastJourney !== params['journey']) {
            this.lastJourney = params['journey'];
            this.lastJourneyId = this.generateUuid();
        }
        return headers.append('conversationId', this.lastJourneyId);
    }

    public getQueryString(fromString?: string) {
        let vars = {}, hash;
        let str = '';
        if (fromString) {
            str = fromString;
        } else if (window.location.href.indexOf('?') > -1) {
            str = window.location.href.slice(window.location.href.indexOf('?') + 1);
        }
        if (str) {
            const hashes = str.split('&');
            for (let i = 0; i < hashes.length; i++) {
                hash = hashes[i].split('=');
                vars[hash[0]] = decodeURIComponent(hash[1]);
            }
        }
        return vars;
    }

    public generateUuid() {
        let d = new Date().getTime();
        if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
            d += performance.now();
        }
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
            /[xy]/g, function(c) {
                let r = (d + Math.random() * 16) % 16 | 0;
                d = Math.floor(d / 16);
                return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
            }
        );
    }

    public getSessionId() {
        return this.sessionId;
    }

    private createRequestHeaders(options: TCRequestOptions) {
        if (options) {
            this.createHeaders(options);
        } else {
            this.requestOptionsArgs.headers = new HttpHeaders({'Content-Type': 'text/plain'});
            this.createHeaders(this.requestOptionsArgs);
        }
    }

    private createRequestHeadersForBlob(options: TCRequestOptions) {
        if (options) {
            this.createHeaders(options);
        } else {
            this.requestOptionsArgs.headers = new HttpHeaders({'Content-Type': 'text/plain'});
            this.createHeaders(this.requestOptionsArgs);
        }
    }

    /**
     * @param error
     * @param url
     * @param messageId
     */
    public logErrors(error: any, url, messageId: string): void {
        const log = new APIErrorLog();
        const wrapper = this.logStore.getWrapper(null, DMCBaseService.LOGGING_ERROR_CONTEXT);

        log.url = url;
        log.messageId = messageId;
        log.error = error;
        if (wrapper.type) {
            log.type = wrapper.type;
        }

        this.userJourneyManager.setupLog(log);
        wrapper.log = log;
        this.logStore.logs.next(wrapper);
    }

    /**
     * Logging GET, PUT, POST and DELETE request signatures
     * @param {string} method
     * @param url
     * @param options
     */
    public logRequest(method: string, url, options?: TCRequestOptions) {

        const wrapper = this.logStore.getWrapper(method, DMCBaseService.LOGGING_REQUEST_CONTEXT);

        const log = new RequestLog();
        if (options) {
            let messageId;
            let sessionId;
            // TODO - handle this method
            // options.headers.forEach((value, key) => {
            //   if (key === 'messageId') {
            //     messageId = value;
            //   }
            //   if (key === 'sessionId') {
            //     sessionId = value;
            //   }
            // });

            log.headers = new HeaderInfo(messageId, sessionId);
        }
        log.method = method;
        log.url = url;
        if (wrapper.type) {
            log.type = wrapper.type;
        }

        this.userJourneyManager.setupLog(log);
        wrapper.log = log;
        this.logStore.logs.next(wrapper);
    }

    /**
     * @param error
     */
    public handleError(error: HttpErrorResponse)
    {
        let messageId = '';
        if (error && error.headers && error.headers.get('messageId')) {
            messageId = error.headers.get('messageId');
        }
        this.logErrors(error.message, error.url, messageId);

        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            //console.error(
            //  'Backend returned code ${error.status}, ' +
            //  'body was: ${error.error}');

            if (error instanceof HttpErrorResponse) {
                if (error.status === 401 || error.status === 0) {
                    this.openLoginPageConfirmBox();
                } else {
                    return throwError(error);
                }
            }
        }
    }

    openLoginPageConfirmBox() {
        if (!this.sessionExpirePopupOpened) {
            this.spinnerService.hide();
            this.sessionExpirePopupOpened = true;
            const confirmError = new DialogModel(
                true,
                LEVEL.ERROR,
                'Session Expired',
                'Please sign in to continue',
                true,
                1000,
                new Map(),
                'Cancel',
                'Proceed'
            );
            this.dialogService
                .confirm(confirmError)
                .subscribe((res) => {
                    if (res === true) {
                        const loginUrl = this.commonService.getLoginUrl();
                        window.open(loginUrl, '_blank ');
                    }
                    this.sessionExpirePopupOpened = false;
                });
        }

    }

    // private getHttpOptions(options: TCRequestOptions): any{
    //   return options;
    // }

}
