import { AbstractSearchCriteria } from '../../criteria/abstract-search-criteria';
import { AllocationType } from '../allocation-type';

export class ServiceItemSearchCriteria extends AbstractSearchCriteria
{
    bookingIdList: number[];
    groupByHours: number;
    groupByPlCode: boolean;
    groupByProductLevel: boolean;
    groupByRoute: boolean;
    groupByTransferMode: boolean;

    //
    allocationType: AllocationType;
}
