import { AfterViewInit, Component } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ICellEditorParams } from 'ag-grid-community';
import * as moment from 'moment';

@Component({
    selector: 'tc-date-editor',
    templateUrl: './date-editor.component.html'
})
export class DateEditorComponent implements ICellEditorAngularComp, AfterViewInit {

    params: any;
    isError = false;
    disabled = true;
    row: any;
    column: string;
    options: any[] = [];
    rowData: any;
    rawValue: any;
    selectedDate: any;

    constructor() { }

    public agInit(params: ICellEditorParams): void {
        this.params = params;
        this.options = params['options'];
        this.disabled = !params['colDef']['editable'];
        this.rowData = params['data'];
        this.rawValue = params['value'];
        if (this.rawValue) {
            this.selectedDate = new Date(this.rawValue);
        }
    }

    public ngAfterViewInit(): void {
    }

    public focusIn(): void {
    }

    public focusOut(): void {
    }

    public getFrameworkComponentInstance(): any {
    }

    public getValue(): any {
        if (this.selectedDate) {
            return moment(this.selectedDate).format('YYYY-MM-DD');
        } else {
            return null;
        }
    }

    public isCancelAfterEnd(): boolean {
        return false;
    }

    public isCancelBeforeStart(): boolean {
        return false;
    }

    public isPopup(): boolean {
        return false;
    }
}
