import * as tslib_1 from "tslib";
import { OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { TcErrorType } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-error';
import { LEVEL } from '@tc-core/model/it/codegen/ui/framework/dialog-model';
import { ModalData } from '@tc-core/model/it/codegen/ui/framework/modal-data';
import { ConfigLoader } from '@tc-core/util/framework';
import { SpinnerService } from '@tc-core/util/ui';
import { FocusViewService } from '@tc/focus-view/focus-view.service';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SetupGridComp } from '../../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid-comp';
import { SetupGridComponent } from '../../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid.component';
import { TCO } from '../../../../constants';
import { JOB_UNLOCKED } from '../../../../models/constants/success-api-message';
import { JobSearchCriteria } from '../../../../models/criteria/job-search-criteria';
import { SortDirection } from '../../../../models/helper/sort-direction';
import { JobHistoryStatus } from '../../../../models/job/job-history-status';
import { JobLockStatus } from '../../../../models/job/job-lock-status';
import { JobService } from '../../../../services/backend-consumers/job/job-service';
import { DataHandlerService } from '../../../../services/backend-consumers/master-data-handler-service/data-handler.service';
import { DMCCommon } from '../../../../services/util/common/dmc-common';
import { DataKey, DataStoreService } from '../../../../services/util/framework/data-store.service';
import { ResponseUtil } from '../../../../services/util/response/response-util.service';
import { CommonJobHistoryGridComponent } from '../common-job-history-grid/common-job-history-grid.component';
import { JobParameterFocusViewComponent } from '../job-parameter-focus-view/job-parameter-focus-view.component';
import { UserManagementService } from '../../../../services/user-management/user-management.service';
var CommonJobGridComponent = /** @class */ (function (_super) {
    tslib_1.__extends(CommonJobGridComponent, _super);
    function CommonJobGridComponent(focusViewService, configLoader, dataStoreService, dataHandlerService, jobService, common, spinnerService, userManagementService) {
        var _this = _super.call(this) || this;
        _this.focusViewService = focusViewService;
        _this.configLoader = configLoader;
        _this.dataStoreService = dataStoreService;
        _this.dataHandlerService = dataHandlerService;
        _this.jobService = jobService;
        _this.common = common;
        _this.spinnerService = spinnerService;
        _this.userManagementService = userManagementService;
        _this.colDefConfig = [];
        _this.gridActions = [];
        _this.searchCriteria = new JobSearchCriteria();
        _this.searchCriteriaObserver = new Subscription();
        _this.focusViewDataChangeObserver = new Subscription();
        _this.focusViewConfirmObserver = new Subscription();
        _this.focusCloseSubscription = new Subscription();
        _this.isFocusViewDataChanged = false;
        // selection
        _this.onJobParamClick = function (params) {
            _this.openJobParameterModal(params.data);
        };
        _this.onUnlockClick = function (params) {
            var jobName = params.jobName;
            if (jobName) {
                _this.jobService.unlockJob(jobName)
                    .subscribe(function (data) {
                    if (data) {
                        var message = ResponseUtil.getFirstData(data);
                        if (message === JOB_UNLOCKED) {
                            //
                        }
                        _this.common.showSnackBar(message, 3000, TcErrorType.TYPE.INFO);
                    }
                }, function (error) {
                    _this.common.showSnackBar(error, 3000, TcErrorType.TYPE.ERROR);
                }, function () {
                    _this.setupGrid.gridApi.refreshInfiniteCache();
                });
            }
        };
        _this.disableUnlockButton = function (params) {
            if (params) {
                return params.status !== JobLockStatus.LOCKED;
            }
            return true;
        };
        _this.disableHistoryButton = function (params) {
            if (params) {
                return !params.historyAvailable;
            }
            return true;
        };
        /**
         * Grid cell render styles
         * Do not remove
         */
        _this.statusLocked = function (params) {
            return params.data && params.data.status === JobLockStatus.LOCKED;
        };
        _this.statusUnlocked = function (params) {
            return params.data && (params.data.status === JobLockStatus.UNLOCKED || params.data.status === JobLockStatus.FORCEFULLY_UNLOCKED);
        };
        // history
        _this.historyStatusStarted = function (params) {
            return params.data &&
                (params.data.latestHistoryStatus === JobHistoryStatus.STARTED || params.data.latestHistoryStatus === JobHistoryStatus.RUNNING);
        };
        _this.historyStatusFailed = function (params) {
            return params.data && params.data.latestHistoryStatus === JobHistoryStatus.FAILED;
        };
        _this.historyStatusFinished = function (params) {
            return params.data && params.data.latestHistoryStatus === JobHistoryStatus.FINISHED;
        };
        _this.historyStatusTerminated = function (params) {
            return params.data && params.data.latestHistoryStatus === JobHistoryStatus.TERMINATED;
        };
        return _this;
    }
    CommonJobGridComponent.prototype.ngOnInit = function () {
        this.colDefConfig = this.configLoader.configurations.get(TCO.CONF.CONF_JOB_SETUP);
        this.addGridActions();
        this.subscribeSearchCriteria();
    };
    CommonJobGridComponent.prototype.addGridActions = function () {
    };
    CommonJobGridComponent.prototype.openJobParameterModal = function (data) {
        var _this = this;
        setTimeout(function () {
            var dataObject = new ModalData(LEVEL.SUCCESS, 'Job Parameter', null, null, 'grid-focus-view', JobParameterFocusViewComponent, { data: data }, { label: 'close' }, '', '', '', '');
            _this.focusViewService.confirm(dataObject).subscribe();
        }, 0);
    };
    CommonJobGridComponent.prototype.closeFocusView = function () {
        if (this.focusViewService.close()) {
            this.focusCloseSubscription = this.focusViewService.close().subscribe(function (res) {
            });
        }
    };
    CommonJobGridComponent.prototype.subscribeSearchCriteria = function () {
        var _this = this;
        this.searchCriteriaObserver = this.dataStoreService.get(DataKey.commonJobSearchCriteria)
            .subscribe(function (value) {
            _this.searchCriteria = value;
            _this.setupGrid.runForceSearch();
        });
    };
    CommonJobGridComponent.prototype.getRows = function (params) {
        var _this = this;
        this.addSearchCriteria(params);
        return this.jobService.getCommonJobs(this.searchCriteria)
            .pipe(tap(function (data) {
            return _this.dataStoreService.set(DataKey.commonJobSearchResults, data);
        }));
    };
    CommonJobGridComponent.prototype.ngOnDestroy = function () {
        if (this.searchCriteriaObserver) {
            this.searchCriteriaObserver.unsubscribe();
        }
        if (this.focusViewDataChangeObserver) {
            this.focusViewDataChangeObserver.unsubscribe();
        }
    };
    CommonJobGridComponent.prototype.focusViewDataObserver = function () {
        var _this = this;
        this.focusViewDataChangeObserver = this.dataStoreService.get(DataKey.docDetailsFocusViewDataChanged)
            .subscribe(function (isDataChanged) {
            if (isDataChanged) {
                _this.focusViewDataChangeObserver.unsubscribe();
            }
            _this.isFocusViewDataChanged = isDataChanged;
        });
    };
    CommonJobGridComponent.prototype.onJobHistoryClick = function (params) {
        var _this = this;
        this.focusViewDataObserver();
        var fabActions = [];
        var dataObject = new ModalData(LEVEL.SUCCESS, 'Job History', null, null, 'grid-focus-view', CommonJobHistoryGridComponent, {
            jobName: params.jobName
        }, { label: 'close' }, '', '', fabActions, null, 'Job : ' + params.jobDisplayName);
        dataObject.disableClose = true;
        this.focusViewConfirmObserver = this.focusViewService.confirm(dataObject)
            .subscribe(function (res) {
            if (_this.isFocusViewDataChanged) {
                // after view closed
                _this.setupGrid.runForceSearch();
            }
        });
    };
    CommonJobGridComponent.prototype.addSearchCriteria = function (params) {
        // search criteria populate
        var pageSize = params.endRow - params.startRow;
        if (!this.searchCriteria) {
            this.searchCriteria = new JobSearchCriteria();
        }
        this.searchCriteria.start = params.startRow;
        this.searchCriteria.size = pageSize;
        if (params.sortModel && params.sortModel[0]) {
            this.searchCriteria.sortBy = params.sortModel[0].colId;
            if (params.sortModel[0].sort === SetupGridComp.GRID_SORT_ASCENDING) {
                this.searchCriteria.sortDirection = SortDirection.ASC;
            }
            else {
                this.searchCriteria.sortDirection = SortDirection.DESC;
            }
        }
        else {
            this.searchCriteria.sortDirection = SortDirection.DESC;
        }
    };
    CommonJobGridComponent.prototype.displayStatus = function (status) {
        var splitsByUnderscore = status.split('_');
        if (splitsByUnderscore.length > 1) {
            return splitsByUnderscore.map(function (part) { return part.charAt(0).toUpperCase() + part.slice(1).toLowerCase(); }).join(' ');
        }
        return status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();
    };
    //
    /**
     * Not applicable to jobs
     */
    CommonJobGridComponent.prototype.saveRow = function (row, event) {
        return undefined;
    };
    /**
     * Not applicable to jobs
     */
    CommonJobGridComponent.prototype.deleteRow = function (row) {
        return undefined;
    };
    /**
     * Not applicable to jobs
     */
    CommonJobGridComponent.prototype.isUnsavedRow = function (row) {
        return false;
    };
    /**
     * Not applicable to jobs
     */
    CommonJobGridComponent.prototype.createNewRow = function () {
    };
    /**
     * Not applicable to jobs
     */
    CommonJobGridComponent.prototype.isInvalidRow = function (params) {
        return false;
    };
    return CommonJobGridComponent;
}(SetupGridComp));
export { CommonJobGridComponent };
