import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Pager } from '@tc-core/model/it/codegen/ui/framework/pager';
import { Sorter } from '@tc-core/model/it/codegen/ui/framework/sorter';
import { SummaryCardData } from '@tc-core/model/it/codegen/ui/framework/summary-card-data';
import { SorterService } from '@tc-core/service/sorters/sorter.service';
import { ConfigLoader } from '@tc-core/util/framework/config-loader.service';
import { TCO } from '../../../constants';
import { GenericSearchCriteria } from '../../../models/criteria/generic-search-criteria';
import { GenericTypePathname } from '../../../models/helper/generic-type-pathname';
import { ResourceType } from '../../../models/reservation/assignment';
import { ServiceItem } from '../../../models/reservation/service-item';
import { ServiceItemChanges } from '../../../models/helper/service-item-changes';
import { GenericResourceSearchType } from '../../generic-search-criteria/generic-search-criteria.component';
import * as moment from 'moment';
import {SearchResultSummary} from '../../../models/reservation/search-result-summary';
import {ResourceAvailability} from '../../../models/resource/resource-availability';

@Component({
  selector: 'tc-driver-assign-panel',
  templateUrl: './driver-assign-panel.component.html'
})
export class DriverAssignPanelComponent implements OnInit {

    @Input() isDataLoaded: true;
    @Input() isTriggerSearch: true;
    @Input() isFocus = false;
    @Input() isSearchBarStyleNotAvailable = true;
    @Input() placeHolderText = 'Search Drivers';
    @Input() selectedDriver: ResourceAvailability;
    @Input() itemGroup: SearchResultSummary;

    @Input() tAssignedDrivers: any = null;
    @Input() contentCardDataList: Array<any> = [];
    @Input() selectedSummaryCardData: any = null;
    @Input() bookingData: ServiceItem;
    @Input() bookingDataArray: ServiceItem[];
    @Input() isBulkAssignment: boolean = false;
    @Input() heading = '';
    @Input() supplierIdList = [];
    @Input() hasMore = true;
    @Input() fetchedDriverCount = 0;
    @Input() dataFromMoreClick = false;
    @Input() pager: Pager;

    @Output() driverSelect: EventEmitter<any> = new EventEmitter();
    @Output() onPaginationMoreClick: EventEmitter<any> = new EventEmitter();
    @Output() resourceCalendarClick: EventEmitter<any> = new EventEmitter<number>();
    @Output() eventClick: EventEmitter<any> = new EventEmitter();
    @Output() targetClick: EventEmitter<any> = new EventEmitter();


    driverSearchCriteria = new GenericSearchCriteria();
    sorters_: Sorter[];
    _searchType = GenericResourceSearchType.ResourceAssign;
    genericTypePathName = GenericTypePathname.DRIVER;
    public defaultPageResultCount = 4;
    selectionIndex = '';
    tPagination: any;


  constructor( public sorterService: SorterService,private configLoader: ConfigLoader) { }

  ngOnInit() {
      this.tPagination = this.configLoader.configurations.get(TCO.CONF.CONF_PAGINATION_CONFIG)['RESOURCE_ALLOCATION_SEARCH'];
      // this.createSorters();
      this.createDriverSearchCriteria();
      this.setupPaginator();
  }

    createSorters() {
        if (this.tAssignedDrivers) {
            this.sorterService.sorters = [];
            this.sorters_ = [];
            let sorterMap = new Map<string, Sorter>();
            this.tAssignedDrivers.config.placeHolderConfig.forEach(config => {
                if (config && config.sort) {
                    let sorter = this.sorterService.createSorter(config);
                    let id = config['_id'];
                    sorterMap.set(id, sorter);
                    this.sorters_.push(sorter);
                }
            });
        }
    }

    public onSort(event) {
        this.sorterService.sorter = event;
    }

    public onDriverSelect(summaryCardData: SummaryCardData) {
        this.selectedSummaryCardData = summaryCardData;

        if (summaryCardData) {
            this.driverSelect.emit(this.selectedSummaryCardData);
        }
    }

    createDriverSearchCriteria(){
        if (this.isBulkAssignment) {
            this.driverSearchCriteria.transferMode = this.bookingDataArray[0]['rawData'].trsServiceItem.transferMode;
            this.driverSearchCriteria.serviceDate = this.bookingDataArray[0]['rawData'].serviceDate;
            this.driverSearchCriteria.route = this.bookingDataArray[0]['rawData'].trsServiceItem.route;
            this.driverSearchCriteria.serviceItems = this.bookingDataArray.map(function (elem) {
                return elem.serviceItemId;
            }).join(",");
            this.driverSearchCriteria.isPrivate = this.bookingDataArray[0]['rawData'].privateBooking;
            this.driverSearchCriteria.serviceStartTime = moment(this.bookingDataArray[0]['rawData'].trsServiceItem.departureDateTime).format('HH:mm');
        }else {
            let previousAssignmentDetail = this.bookingData.serviceAssignments.find(value => value.categoryCode === ResourceType.driver);
            this.driverSearchCriteria.rejectedResourceIdList = previousAssignmentDetail ? previousAssignmentDetail.rejectedResourceIdList : [];

            this.driverSearchCriteria.transferMode = this.bookingData.trsServiceItem.transferMode;
            this.driverSearchCriteria.route = this.bookingData.trsServiceItem.route;
            this.driverSearchCriteria.serviceDate = this.bookingData.serviceDate;
            this.driverSearchCriteria.isPrivate = this.bookingData.privateBooking;
            this.driverSearchCriteria.serviceItems = this.bookingData.serviceItemId.toString();
            this.driverSearchCriteria.serviceStartTime = moment(this.bookingData.trsServiceItem.departureDateTime).format('HH:mm');
        }
        this.driverSearchCriteria.resourceType = this.genericTypePathName;
        this.driverSearchCriteria.activeStatus = 'true';
        this.driverSearchCriteria.supplierIdList = this.supplierIdList;
    }

    private setupPaginator() {
        this.defaultPageResultCount = this.tPagination['defaultPageResultCount'];
        this.pager = new Pager(0, this.defaultPageResultCount);
    }

    public onPage(pageNumber: number) {
        this.pager.currPage = pageNumber;
        this.selectionIndex = (this.pager.currPage * this.pager.itemsPerPage).toString();
    }

    public onMore() {
        this.dataFromMoreClick = true;
        if (this.tPagination && this.tPagination['defaultPageResultCount'] && this.tPagination['fetchingPages']) {
            this.driverSearchCriteria.size = this.tPagination['fetchingPages'] *
                this.tPagination['defaultPageResultCount'];
            this.onPaginationMoreClick.emit(this.driverSearchCriteria);
            // this.retrieveAndCollectSearchResults(this.supplierSearchCriteria);
        }
    }

    onResourceCalendarClick(resourceId: number) {
        this.resourceCalendarClick.emit(resourceId);
    }

    onTargetClick(target: any){
        this.targetClick.emit(target);
    }

    onEventClick(event: any){
        this.eventClick.emit(event);
    }

}
