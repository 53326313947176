/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid.component.ngfactory";
import * as i3 from "../../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid.component";
import * as i4 from "../../../../services/util/pre-processors/grid-column-definition-processor.service";
import * as i5 from "@ngx-translate/core";
import * as i6 from "@tc/dialog/dialog.service";
import * as i7 from "../../../../services/util/framework/data-store.service";
import * as i8 from "../../../../services/util/common/dmc-common";
import * as i9 from "./common-job-history-grid.component";
import * as i10 from "@tc-core/util/framework/config-loader.service";
import * as i11 from "../../../../services/backend-consumers/job/job-service";
import * as i12 from "@tc-core/util/framework/event-manager.service";
import * as i13 from "@tc-core/util/framework/focus-view-manager.service";
import * as i14 from "@tc/focus-view/focus-view.service";
var styles_CommonJobHistoryGridComponent = [];
var RenderType_CommonJobHistoryGridComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CommonJobHistoryGridComponent, data: {} });
export { RenderType_CommonJobHistoryGridComponent as RenderType_CommonJobHistoryGridComponent };
function View_CommonJobHistoryGridComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.displayStatus(_v.parent.context.$implicit.historyStatus); _ck(_v, 1, 0, currVal_0); }); }
function View_CommonJobHistoryGridComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_CommonJobHistoryGridComponent_2)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = ((_v.context.$implicit == null) ? null : _v.context.$implicit.historyStatus); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_CommonJobHistoryGridComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { setupGrid: 0 }), i0.ɵqud(402653184, 2, { statusCell: 0 }), (_l()(), i0.ɵeld(2, 0, null, null, 3, "div", [["class", "trade-client-detail-view"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 2, "div", [["class", ""]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "tc-setup-grid", [], null, null, null, i2.View_SetupGridComponent_0, i2.RenderType_SetupGridComponent)), i0.ɵdid(5, 638976, [[1, 4], ["jobHistoriesView", 4]], 0, i3.SetupGridComponent, [i0.ChangeDetectorRef, i4.GridColumnDefinitionProcessorService, i5.TranslateService, i6.DialogService, i7.DataStoreService, i8.DMCCommon], { readOnly: [0, "readOnly"], noSelection: [1, "noSelection"], dataSource: [2, "dataSource"], setupGridComp: [3, "setupGridComp"], colDefConfig: [4, "colDefConfig"] }, null), (_l()(), i0.ɵand(0, [[2, 2], ["statusCell", 2]], null, 0, null, View_CommonJobHistoryGridComponent_1))], function (_ck, _v) { var _co = _v.component; var currVal_0 = true; var currVal_1 = true; var currVal_2 = _co; var currVal_3 = _co; var currVal_4 = _co.colDefConfig; _ck(_v, 5, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
export function View_CommonJobHistoryGridComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-common-job-history-grid", [], null, null, null, View_CommonJobHistoryGridComponent_0, RenderType_CommonJobHistoryGridComponent)), i0.ɵdid(1, 245760, null, 0, i9.CommonJobHistoryGridComponent, [i10.ConfigLoader, i11.JobService, i7.DataStoreService, i12.EventManager, i13.FocusViewManager, i14.FocusViewService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CommonJobHistoryGridComponentNgFactory = i0.ɵccf("tc-common-job-history-grid", i9.CommonJobHistoryGridComponent, View_CommonJobHistoryGridComponent_Host_0, { jobName: "jobName" }, {}, []);
export { CommonJobHistoryGridComponentNgFactory as CommonJobHistoryGridComponentNgFactory };
