import { GenTourItem } from './gen-tour-item';

export class GenTourItinerary {
    tourCode: string;
    tourName: string;
    serviceDate: Date;
    itineraryItems: GenTourItem[];

    /**
     * identifier for data grid rendering process
     */
    gridObjType: string;

    constructor() {
        this.gridObjType = 'GenTourItinerary';
    }

    static issEqual(firstOne:GenTourItinerary, secondOne:GenTourItinerary){
        return firstOne.tourCode === secondOne.tourCode && firstOne.tourName === secondOne.tourName;
    }
}
