import { GenericGroup } from './generic-group';
import { ResourceGroupAvailability } from './resource-group-availability';
import { RoomGroup } from './room-group';
import { VehicleGroup } from './vehicle-group';

export class ResourceGroup {
    tempId: number; // front end only

    groupId: number;
    logical: boolean;

    resourceType: string;
    resourceTypeName: string;

    noOfUnits: number;
    resourceIds: number[];
    resourceGroupAvailabilities: ResourceGroupAvailability[];

    // specific information
    // generic data
    genTypeCode: string;
    genTypeName: string;
    genericGroup: GenericGroup;

    // vehicle data
    vehicleModelCode: string;
    vehicleModelName: string;
    transferMode: string;
    transferModeName: string;
    vehicleGroup: VehicleGroup;

    // room data
    roomTypeCode: string;
    roomTypeName: string;
    roomGroup: RoomGroup;
}
