import { HttpClient } from '@angular/common/http';
import { ConfigLoader } from '@tc-core/util/framework/config-loader.service';
import { TCO } from '../../../constants';
import { AssignmentSummaryCriteriaV2 } from '../../../models/criteria/assignment-summary-criteria-v2';
import { CriteriaSearchCriteria } from '../../../models/criteria/criteria-search-criteria';
import { DataLoaderService } from '../../api/data-loader-service.service';
import { DateTimeProcessor } from '../../business-utils/time-zone/date-time-processor';
import { DataKey, DataStoreService } from '../../util/framework/data-store.service';
import { DMCQueryParamsService } from '../../util/framework/dmc-query-params.service';
import { RequestService } from '../../util/framework/request.service';
import { FileHandlingService } from '../file-handler/file-handling.service';
import { UrlPaths } from '../url-paths';
import * as i0 from "@angular/core";
import * as i1 from "../../util/framework/dmc-query-params.service";
import * as i2 from "../../util/framework/request.service";
import * as i3 from "@angular/common/http";
import * as i4 from "../../util/framework/data-store.service";
import * as i5 from "@tc-core/util/framework/config-loader.service";
import * as i6 from "../../api/data-loader-service.service";
import * as i7 from "../file-handler/file-handling.service";
var ReservationV2ManagementService = /** @class */ (function () {
    function ReservationV2ManagementService(queryParamsService, requestService, http, dataStore, httpClient, configLoader, dataLoaderService, fileHandlingService) {
        this.queryParamsService = queryParamsService;
        this.requestService = requestService;
        this.http = http;
        this.dataStore = dataStore;
        this.httpClient = httpClient;
        this.configLoader = configLoader;
        this.dataLoaderService = dataLoaderService;
        this.fileHandlingService = fileHandlingService;
    }
    ReservationV2ManagementService.prototype.searchProducts = function (productQueueSearchCriteria) {
        DateTimeProcessor.processProductQueueSearchCriteriaDateTimes(productQueueSearchCriteria);
        var params = this.queryParamsService.asQueryParams(productQueueSearchCriteria);
        return this.requestService.getWithDataKey(DataKey.productQueueSearchResultsFromService, UrlPaths.OP_RES_URL_KEY, [UrlPaths.OP_RES_VERSION, UrlPaths.PRODUCT_ITEMS], params);
    };
    ReservationV2ManagementService.prototype.searchServiceItems = function (criteria) {
        // const params = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.post(UrlPaths.OP_RES_URL_KEY, [UrlPaths.OP_RES_VERSION, UrlPaths.SERVICE_ITEMS_V2, UrlPaths.search], criteria);
    };
    ReservationV2ManagementService.prototype.searchBookingItems = function (criteria) {
        // const params = this.queryParamsService.asQueryParams(criteria);
        // return this.http.get<OPResponseWrapper<BookingItem>>('assets/mockdata/booking_items.json');
        return this.requestService.post(UrlPaths.OP_RES_URL_KEY, [UrlPaths.OP_RES_VERSION, UrlPaths.BOOKING_ITEMS_V2, UrlPaths.search], criteria);
    };
    ReservationV2ManagementService.prototype.searchSuppliersForAllocation = function (criteria) {
        DateTimeProcessor.processSearchCriteriaDateTimes(criteria);
        var params = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.get(UrlPaths.OP_RES_URL_KEY, [UrlPaths.OP_RES_VERSION, UrlPaths.SUPPLIER_ALLOCATION_SUMMARIES_V2], params);
    };
    ReservationV2ManagementService.prototype.searchResourcesForAllocation = function (criteria) {
        DateTimeProcessor.processSearchCriteriaDateTimes(criteria);
        var params = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.get(UrlPaths.OP_RES_URL_KEY, [UrlPaths.OP_RES_VERSION, UrlPaths.RESOURCE_ALLOCATION_SUMMARIES_V2], params);
    };
    ReservationV2ManagementService.prototype.searchSuppliersForAllocationWithKey = function (criteria) {
        DateTimeProcessor.processSupplierAllocationSearchCriteriaDateTimes(criteria);
        var params = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.getWithDataKey(DataKey.supplierAllocationSearchResults, UrlPaths.OP_RES_URL_KEY, [UrlPaths.OP_RES_VERSION, UrlPaths.SUPPLIER_ALLOCATION_SUMMARIES_V2], params);
    };
    ReservationV2ManagementService.prototype.searchResourcesForAllocationWithKey = function (criteria) {
        DateTimeProcessor.processSupplierAllocationSearchCriteriaDateTimes(criteria);
        var params = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.getWithDataKey(DataKey.resourceAllocationSearchResults, UrlPaths.OP_RES_URL_KEY, [UrlPaths.OP_RES_VERSION, UrlPaths.RESOURCE_ALLOCATION_SUMMARIES_V2], params);
    };
    ReservationV2ManagementService.prototype.saveAssignmentRawData = function (assignmentList) {
        return this.requestService.post(UrlPaths.OP_RES_URL_KEY, [UrlPaths.OP_RES_VERSION, UrlPaths.ASSIGNMENT_RAW_DATA_V2], assignmentList);
    };
    ReservationV2ManagementService.prototype.changeAssignmentsStatus = function (assignmentIds, status) {
        var params = this.queryParamsService.asQueryParams({
            status: status
        });
        return this.requestService.patch(UrlPaths.OP_RES_URL_KEY, [UrlPaths.OP_RES_VERSION, UrlPaths.ASSIGNMENTS_V2], assignmentIds, params);
    };
    ReservationV2ManagementService.prototype.splitBookingItem = function (paxMappingGroupList) {
        return this.requestService.post(UrlPaths.OP_RES_URL_KEY, [UrlPaths.OP_RES_VERSION, UrlPaths.BOOKING_ITEMS_V2, UrlPaths.SPLIT], paxMappingGroupList);
    };
    ReservationV2ManagementService.prototype.splitServiceItem = function (paxMappingGroupList) {
        return this.requestService.post(UrlPaths.OP_RES_URL_KEY, [UrlPaths.OP_RES_VERSION, UrlPaths.SERVICE_ITEMS_V2, UrlPaths.SPLIT], paxMappingGroupList);
    };
    ReservationV2ManagementService.prototype.patchBookingItems = function (bookingIdList, bookingItemPatch) {
        var params = this.queryParamsService.asQueryParams({
            bookingIdList: bookingIdList
        });
        return this.requestService.patch(UrlPaths.OP_RES_URL_KEY, [UrlPaths.OP_RES_VERSION, UrlPaths.BOOKING_ITEMS_V2], bookingItemPatch, params);
    };
    ReservationV2ManagementService.prototype.doAutoAllocationForBookingItems = function (criteria) {
        var params = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.post(UrlPaths.OP_RES_URL_KEY, [UrlPaths.OP_RES_VERSION, UrlPaths.BOOKING_ITEMS_V2, UrlPaths.RECOMMENDED], null, params);
    };
    ReservationV2ManagementService.prototype.doAutoAllocationForServiceItems = function (criteria) {
        var params = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.post(UrlPaths.OP_RES_URL_KEY, [UrlPaths.OP_RES_VERSION, UrlPaths.SERVICE_ITEMS_V2, UrlPaths.RECOMMENDED], null, params);
    };
    // Assignment Patch
    ReservationV2ManagementService.prototype.patchAssignmentsData = function (assignmentIds, assignmentPatch) {
        var params = this.queryParamsService.asQueryParams({
            assignmentIds: assignmentIds
        });
        return this.requestService.patch(UrlPaths.OP_RES_URL_KEY, [UrlPaths.OP_RES_VERSION, UrlPaths.ASSIGNMENTS_V2, UrlPaths.PATCH_ITEM], assignmentPatch, params);
    };
    // Saved Criteria
    ReservationV2ManagementService.prototype.saveSearchCriteria = function (criteria) {
        var criteriaSaveCriteria = new CriteriaSearchCriteria();
        criteriaSaveCriteria.userId = criteria.userId;
        criteriaSaveCriteria.criteriaName = criteria.criteriaName;
        var params = this.queryParamsService.asQueryParams(criteriaSaveCriteria);
        return this.requestService.post(UrlPaths.OP_RES_URL_KEY, [UrlPaths.OP_RES_VERSION, UrlPaths.CRITERIA_V2], criteria.criteria, params);
    };
    ReservationV2ManagementService.prototype.getUserSearchCriteriaList = function (userId) {
        var criteria = new CriteriaSearchCriteria();
        criteria.userId = userId;
        var params = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.get(UrlPaths.OP_RES_URL_KEY, [UrlPaths.OP_RES_VERSION, UrlPaths.CRITERIA_V2], params);
    };
    ReservationV2ManagementService.prototype.deleteCriteria = function (criteriaId) {
        return this.requestService.delete(UrlPaths.OP_RES_URL_KEY, [UrlPaths.OP_RES_VERSION, UrlPaths.CRITERIA_V2, criteriaId.toString()]);
    };
    ReservationV2ManagementService.prototype.searchAssignments = function (resourceId, key) {
        var criteria = new AssignmentSummaryCriteriaV2();
        criteria.resourceId = resourceId;
        var params = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.getWithDataKey(key, UrlPaths.OP_RES_URL_KEY, [UrlPaths.OP_RES_VERSION, UrlPaths.ASSIGNMENT_SUMMARIES], params);
    };
    ReservationV2ManagementService.prototype.uploadBookingItemSheet = function (fileToUpload, templateType, dateTimeString) {
        var endpoint = this.configLoader.configurations.get(TCO.CONF.CONF_ENDPOINT)[UrlPaths.OP_RES_URL_KEY];
        endpoint = this.dataLoaderService.getUrl(endpoint, [UrlPaths.OP_RES_VERSION, UrlPaths.BOOKING_ITEMS_V2, UrlPaths.UPLOAD]);
        return this.fileHandlingService.uploadImage({ file: fileToUpload, templateType: templateType, serviceDate: dateTimeString }, endpoint);
    };
    ReservationV2ManagementService.prototype.searchFeatureItems = function (criteria) {
        var params = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.getWithDataKey(DataKey.allocationFeatureSearchResultsFromService, UrlPaths.OP_RES_URL_KEY, [UrlPaths.OP_RES_VERSION, UrlPaths.ALLOCATION_FEATURE], params);
    };
    ReservationV2ManagementService.prototype.saveAllocationFeature = function (feature) {
        return this.requestService.post(UrlPaths.OP_RES_URL_KEY, [UrlPaths.OP_RES_VERSION, UrlPaths.ALLOCATION_FEATURE], feature);
    };
    ReservationV2ManagementService.prototype.deleteAllocationFeature = function (serviceType) {
        return this.requestService.delete(UrlPaths.OP_RES_URL_KEY, [UrlPaths.OP_RES_VERSION, UrlPaths.ALLOCATION_FEATURE, serviceType]);
    };
    ReservationV2ManagementService.prototype.searchPassengers = function (criteria) {
        return this.requestService.post(UrlPaths.OP_RES_URL_KEY, [UrlPaths.OP_RES_VERSION, UrlPaths.BOOKING_ITEMS_V2, UrlPaths.PASSENGERS], criteria);
    };
    ReservationV2ManagementService.prototype.searchBookingNotes = function (criteria) {
        return this.requestService.post(UrlPaths.OP_RES_URL_KEY, [UrlPaths.OP_RES_VERSION, UrlPaths.BOOKING_ITEMS_V2, UrlPaths.NOTES], criteria);
    };
    ReservationV2ManagementService.prototype.searchSupplementNotes = function (criteria) {
        return this.requestService.post(UrlPaths.OP_RES_URL_KEY, [UrlPaths.OP_RES_VERSION, UrlPaths.BOOKING_ITEMS_V2, UrlPaths.SUPPLEMENT_NOTES], criteria);
    };
    ReservationV2ManagementService.ngInjectableDef = i0.defineInjectable({ factory: function ReservationV2ManagementService_Factory() { return new ReservationV2ManagementService(i0.inject(i1.DMCQueryParamsService), i0.inject(i2.RequestService), i0.inject(i3.HttpClient), i0.inject(i4.DataStoreService), i0.inject(i3.HttpClient), i0.inject(i5.ConfigLoader), i0.inject(i6.DataLoaderService), i0.inject(i7.FileHandlingService)); }, token: ReservationV2ManagementService, providedIn: "root" });
    return ReservationV2ManagementService;
}());
export { ReservationV2ManagementService };
