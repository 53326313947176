import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ServiceNote } from '../../../models/reservation/service-note';

@Component({
  selector: 'tc-reservation-service-group-notes',
  templateUrl: './reservation-service-group-notes.component.html'
})
export class ReservationServiceGroupNotesComponent implements OnInit {

  @Input() selectedServiceGroupNotes: ServiceNote[];
  @Input() disableGroupNoteEdit: boolean = false;

  @Output() saveNote: EventEmitter<ServiceNote> = new EventEmitter();
  @Output() deleteNote: EventEmitter<ServiceNote> = new EventEmitter();
  @Output() isEditClicked: EventEmitter<boolean> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  public onNoteSave(event: ServiceNote) {
    this.saveNote.emit(event);
  }

  public onDelete(event: ServiceNote) {
    this.deleteNote.emit(event);
  }

  public onEdit(event: boolean) {
    this.isEditClicked.emit(event);
  }
}
