import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgGridModule } from 'ag-grid-angular';
import { PassengerListGridComponent } from './passenger-list-grid.component';

@NgModule({
  declarations: [PassengerListGridComponent],
  exports: [
    PassengerListGridComponent
  ],
  imports: [
    CommonModule,
    AgGridModule
  ]
})
export class PassengerListGridModule { }
