<div class="tc-form-edit">
    <div
            class="detail-group"
            *tcScrollSection="personalInfoSection;id:personalInfoSection?.id;title:personalInfoSection?.tabTitle;error:setupSectionTabError(personalInfoSection)">
        <div
                class="detail-group__heading">
            <tc-section-heading
                    [pageSection]="personalInfoSection"
                    (actionClick)="onHeaderActionClick($event)">
            </tc-section-heading>
        </div>
        <div class="detail-group__content">
            <tc-personal-information
                    [personalInfo]="personalInfo"
                    [resource]="resource"
                    [titles]="titles"
                    [assignedSupplier]="assignedSupplierForResource"
                    [supplierTypeOptions]="supplierTypes"
                    [filteredSuppliers]="filteredSuppliers"
                    [filteredUsers]="filteredUsers"
                    [resourceTypeOptions]="resourceTypes"
                    [resourceType]="resourceType"
                    [isResourceTypeReadOnly]="isResourceTypeReadOnly"
                    [resourceTypePathName]="genericTypePathName"
                    [missingCostForSupplier]="missingCostForSupplier"
                    (supplierTextChange)="onSupplierTextChange($event)"
                    (supplierClear)="onSupplierClear($event)"
                    (supplierAssign)="onSupplierAssign($event)"
                    (userTextChange)="onUserTextChange($event)"
                    (userClear)="onUserClear($event)"
                    (userAssign)="onUserAssign($event)"
                    (resourceTypeChange)="onResourceTypeChange($event)"
                    (changeFormValidity)="onChangePersonalInfoForm($event)">
            </tc-personal-information>
        </div>
    </div>
    <div
            class="detail-group"
            *tcScrollSection="keyControlSection;id:keyControlSection?.id;title:keyControlSection?.tabTitle;error:setupSectionTabError(keyControlSection)">
        <div
                class="detail-group__heading">
            <tc-section-heading
                    [pageSection]="keyControlSection"
                    (actionClick)="onHeaderActionClick($event)">
            </tc-section-heading>
        </div>
        <div class="detail-group__content">
            <tc-key-controls-edit-view
                    [object]="resource"
                    [userProfile]="userProfile"
                    [disableKeyControlEdit]="disableKeyControlEdit"
                    (changeFormValidity)="onChangeKeyControlInfoForm($event)">
            </tc-key-controls-edit-view>
        </div>
    </div>

    <div
            class="detail-group"
            *tcScrollSection="contactDetailsSection;id:contactDetailsSection?.id;title:contactDetailsSection?.tabTitle;error:setupSectionTabError(contactDetailsSection)">
        <div
                class="detail-group__heading">
            <tc-section-heading
                    [pageSection]="contactDetailsSection"
                    (actionClick)="onHeaderActionClick($event)">
            </tc-section-heading>
        </div>
        <div
                class="detail-group__content">
            <tc-contact-details
                    [titles]="titles"
                    [categories]="categories"
                    [contactDetails]="contactDetails"
                    [defaultContactCategory]="defaultContactCategory"
                    [cityList]="cityList"
                    [stateList]="stateList"
                    [countryList]="countryList"
                    [countryStateCityChangeMap]="countryStateCityChangeMap"
                    (newCountryStateCityObserver)="onNewCountryStateCityObserver($event)"
                    (selectCountry)="onSelectCountry($event)"
                    (selectState)="onSelectState($event)"
                    (selectCity)="onSelectCity($event)"
                    (countryTextChange)="onCountryTextChange($event)"
                    (stateTextChange)="onStateTextChange($event)"
                    (cityTextChange)="onCityTextChange($event)"

                    (changeFormValidity)="onChangePersonalContactForm($event)">
            </tc-contact-details>
        </div>
    </div>

    <!--specific forms-->
        <div [hidden]="!(personalInfo && (personalInfo.resourceType==='GUID' || personalInfo.resourceType==='driver' || personalInfo.resourceType==='hennaartist'))"
                class="detail-group"
                *tcScrollSection="specificFormSection;id:specificFormSection?.id;title:specificFormSection?.tabTitle;error:setupSectionTabError(specificFormSection)">
            <div
                    class="detail-group__heading">
                <tc-section-heading
                        [pageSection]="specificFormSection">
                </tc-section-heading>
            </div>
            <div *ngIf="personalInfo && (personalInfo.resourceType==='GUID' || personalInfo.resourceType==='driver' || personalInfo.resourceType==='hennaartist')"
                    class="detail-group__content">
                <tc-guide-specific
                        *ngIf="personalInfo && personalInfo.resourceType==='GUID'"
                        [changeDetectSupporter]="changeDetectSupporter"
                        [resource]="resource"
                        (changeFormValidity)="onChangeGuideSpecificForm($event)">
                </tc-guide-specific>
                <tc-driver-specific
                        *ngIf="personalInfo && personalInfo.resourceType==='driver'"
                        [changeDetectSupporter]="changeDetectSupporter"
                        [resource]="resource"
                        (changeFormValidity)="onChangeDriverSpecificForm($event)">
                </tc-driver-specific>
                <tc-henna-artist-specific
                        *ngIf="personalInfo && personalInfo.resourceType==='hennaartist'"
                        [changeDetectSupporter]="changeDetectSupporter"
                        [resource]="resource"
                        (changeFormValidity)="onChangeHennaArtistSpecificForm($event)">
                </tc-henna-artist-specific>
            </div>
        </div>

    <div
            class="detail-group"
            *tcScrollSection="paymentInfoSection;id:paymentInfoSection?.id;title:paymentInfoSection?.tabTitle;error:setupSectionTabError(paymentInfoSection)">
        <div
                class="detail-group__heading">
            <tc-section-heading
                    [pageSection]="paymentInfoSection"
                    (actionClick)="onHeaderActionClick($event)">
            </tc-section-heading>
        </div>
        <div
                class="detail-group__content">
            <tc-payment-information
                    [currencyList]="currencyList"
                    [payMethods]="payMethods"
                    [paymentInfo]="resource.paymentInfo"
                    (changeFormValidity)="onChangePaymentForm($event)">
            </tc-payment-information>
        </div>
    </div>

    <div
            class=""
            *tcScrollSection="additionalContactsSection;id:additionalContactsSection?.id;title:additionalContactsSection?.tabTitle;error:setupSectionTabError(additionalContactsSection)">
        <div
                class="">
            <tc-section-heading
                    [pageSection]="additionalContactsSection"
                    (actionClick)="onHeaderActionClick($event)">
            </tc-section-heading>
        </div>
        <div
                class="">
            <tc-additional-contacts
                    [titles]="titles"
                    [categories]="categories"
                    [additionalContactList]="additionalContactDetails"
                    [additionalContactSummary]="additionalContactSummary"
                    [additionalContactContents]="additionalContactContents"
                    [defaultContactCategory]="defaultContactCategory"

                    [cityList]="cityList"
                    [stateList]="stateList"
                    [countryList]="countryList"
                    [countryStateCityChangeMap]="countryStateCityChangeMap"
                    (newCountryStateCityObserver)="onNewCountryStateCityObserver($event)"
                    (selectCountry)="onSelectCountry($event)"
                    (selectState)="onSelectState($event)"
                    (selectCity)="onSelectCity($event)"
                    (countryTextChange)="onCountryTextChange($event)"
                    (stateTextChange)="onStateTextChange($event)"
                    (cityTextChange)="onCityTextChange($event)"

                    (changeFormValidity)="onAdditionalContactsValidChange($event)"
                    (addressModified)="onAdditionalContactsModified()">
            </tc-additional-contacts>
        </div>
    </div>

    <div
            class="detail-group"
            *tcScrollSection="supplierAttributesSection;id:supplierAttributesSection?.id;title:supplierAttributesSection?.tabTitle;error:setupSectionTabError(supplierAttributesSection)">
        <div
                class="detail-group__heading">
            <tc-section-heading
                    [pageSection]="supplierAttributesSection"
                    (actionClick)="onHeaderActionClick($event)">
            </tc-section-heading>
        </div>
        <div
                class="detail-group__content">
            <tc-supplier-attributes
                    [supplierAttributes]="resource.supplierAttributes">
            </tc-supplier-attributes>
        </div>
    </div>


    <div
            *tcScrollSection="notesSection;id:notesSection?.id;title:notesSection?.tabTitle;error:setupSectionTabError(notesSection)"
            class="detail-group">
        <div class="detail-group__heading">
            <tc-section-heading
                    [pageSection]="notesSection"
                    (actionClick)="onHeaderActionClick($event)">
            </tc-section-heading>
        </div>
        <div
                class="detail-group__content">
            <tc-remarks
                    [noteList]="resource.remarks"
                    (noteChanged)="onNotesModified()">
            </tc-remarks>
        </div>
    </div>


    <div
            class="detail-group"
            *tcScrollSection="taxSchemeSection;id:taxSchemeSection?.id;title:taxSchemeSection?.tabTitle;error:setupSectionTabError(taxSchemeSection)">
        <div
                class="detail-group__heading">
            <tc-section-heading
                    [pageSection]="taxSchemeSection"
                    (actionClick)="onHeaderActionClick($event)">
            </tc-section-heading>
        </div>
        <div class="detail-group__content">
            <tc-tax-schemes
                    [forAResourceProfile]="true"
                    [taxSchemeList]="resource?.taxSchemes ? resource.taxSchemes : []"
                    [taxSchemes]="taxSchemes"
                    [taxSchemeContents]="taxSchemeContents"
                    [taxSchemeSummary]="taxSchemeSummary"
                    [resourceType]="resourceType"
                    [supplierItems]=""
                    (changeFormValidity)="onChangeTaxSchemeFormValidity()"
                    (taxSchemeModified)="onTaxSchemeModified()">
            </tc-tax-schemes>
        </div>
    </div>

    <!--<div-->
            <!--class=""-->
            <!--*tcScrollSection="taxSchemeSection;id:taxSchemeSection?.id;title:taxSchemeSection?.tabTitle;error:setupSectionTabError(taxSchemeSection)">-->
        <!--<div-->
                <!--class="">-->
            <!--<tc-section-heading-->
                    <!--[pageSection]="taxSchemeSection"-->
                    <!--(actionClick)="onHeaderActionClick($event)">-->
            <!--</tc-section-heading>-->
        <!--</div>-->
        <!--<div-->
                <!--class="">-->
            <!--<tc-tax-schemes-->
                    <!--[taxSchemeList]="resource.taxSchemes"-->
                    <!--[taxSchemes]="taxSchemes"-->
                    <!--[taxSchemeContents]="taxSchemeContents"-->
                    <!--[taxSchemeSummary]="taxSchemeSummary"-->
                    <!--[resourceType]="resourceType"-->
                    <!--[supplierItems]="selectedResTypes"-->
                    <!--(taxSchemeModified)="onTaxSchemeModified()">-->
            <!--</tc-tax-schemes>-->
        <!--</div>-->
    <!--</div>-->

</div>



