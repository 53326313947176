import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTabsModule } from '@angular/material';
import { PaginatorModule } from '@tc/paginator';
import { PipesModule } from '@tc/pipes';
import { ShowSavedCriteriaModalComponent } from './show-saved-criteria-modal.component';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
    imports: [
        CommonModule,
        MatTabsModule,
        PaginatorModule,
        PipesModule,
        TranslateModule.forChild()
    ],
  declarations: [ShowSavedCriteriaModalComponent]
})
export class ShowSavedCriteriaModalModule { }
