import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MessageQueueSearchResultsComponent } from './message-queue-search-results.component';
import {MatListModule} from '@angular/material/list';
import {MatCardModule} from '@angular/material/card';
import {MatRippleModule} from '@angular/material/core';

@NgModule({
  declarations: [MessageQueueSearchResultsComponent],
    imports: [
        CommonModule,
        MatFormFieldModule,
        FormsModule,
        MatInputModule,
        MatIconModule,
        ReactiveFormsModule,
        MatAutocompleteModule,
        MatListModule,
        MatCardModule,
        MatRippleModule
    ],
  exports: [MessageQueueSearchResultsComponent]
})
export class MessageQueueSearchResultsModule { }
