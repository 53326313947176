import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CurrencyFieldModule } from '@tc/currency-field';
import { CurrencyInputModule } from '@tc/currency-input';
import { PipesModule } from '@tc/pipes';
import { ResourceModule } from '@tc/resource';
import { AngularResizedEventModule } from 'angular-resize-event';
import { ResultContentCardModule } from '../../../shared/result-content-card/result-content-card.module';
import { TimelineEventComponent } from '../timeline-event/timeline-event.component';
import { TimelineUnitWrapperComponent } from '../timeline-unit-wrapper/timeline-unit-wrapper.component';
import { TimelineUnitComponent } from '../timeline-unit/timeline-unit.component';
import { TimelineComponent } from './timeline.component';

@NgModule({
    declarations: [
        TimelineComponent,
        TimelineUnitWrapperComponent,
        TimelineUnitComponent,
        TimelineEventComponent
    ],
    imports: [
        CommonModule,
        ResourceModule,
        ResultContentCardModule,
        PipesModule,
        CommonModule,
        MatTooltipModule,
        PipesModule,
        CurrencyInputModule,
        CurrencyFieldModule,
        AngularResizedEventModule
    ],
    exports: [
        TimelineComponent,
        TimelineUnitWrapperComponent,
        TimelineUnitComponent,
        TimelineEventComponent
    ]
})
export class TimelineModule {
}
