import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs';
import { DataSource } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material';

@Injectable()
export class TableService {

    getTableDataSource(rows: Array<any>, paginator?: MatPaginator): TableDataSource<any> {
        return new TableDataSource(new TableData<any>(rows), paginator);
    }
}

export class TableData<T> {

    dataChange: BehaviorSubject<T[]> = new BehaviorSubject<T[]>([]);
    get data(): T[] { return this.dataChange.value; }

    constructor(data: any) {
        this.dataChange.next(data);
    }

}

export class TableDataSource<T> extends DataSource<any>  {
    renderedData: T[] = [];
    totalData: T[] = [];
    constructor(private _tableData: TableData<T>,
                private _paginator?: MatPaginator) {
        super();
        this.totalData = this._tableData.data;
    }
    connect(): Observable<T[]> {
        return this._tableData.dataChange;
        //   const displayDataChanges = [
        //       this._tableData.dataChange,
        //       this._paginator.page
        //   ];

        // return Observable.merge(...displayDataChanges).map(() => {
        //
        //     // Grab the page's slice of the filtered sorted data.
        //     const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
        //     this.renderedData = this._tableData.data.slice(startIndex, this._paginator.pageSize);
        //     return this.renderedData;
        // });
    }

    disconnect() {}
}


