<div class="tc-item-wrapper">
  <div class="tc-item-header tc-flex-box">
    <div class="tc-flex-item tc-item-header__primary">{{'Locations' | translate}}</div>
    <div class="tc-flex-item tc-item-header__actions tc-icon-block">
      <!--<div class="tc-flex-item tc-item-header__actions tc-icon-block">-->
      <!--&lt;!&ndash;<span><i class="material-icons tc-icon-block__icon tc-icon-block__icon&#45;&#45;active">save</i></span>&ndash;&gt;-->
      <!--<span><i-->
      <!--class="material-icons tc-icon-block__icon tc-icon-block__icon&#45;&#45;active"-->
      <!--(click)="onAddRow($event)">add</i></span>-->
      <!--</div>-->

    </div>
  </div>
</div>
<tc-data-grid [dataSource]="dataSource"
              [savedDataSource]="savedDataSource"
              [columnsArray]="displayedColumns"
              [columnNamesArray]="columns"
              [readOnly]="true"
              [showDeleteIcon]="true"
              [showEditIcon]="true"
              [showSaveIcon]="true"
              [clickedRow]="clickedRow"
              [unsavedRows]="unsavedRows"
              (editClick)="onEditClick($event)"
              (revertClick)="onRevertClick($event)"
              (saveClick)="onSaveClick($event)"
              (deleteRowID)="onDeleteRowClick($event)"
              (deleteRow)="onDeleteRow($event)"></tc-data-grid>
