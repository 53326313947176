import { Component, OnInit, ViewChild } from '@angular/core';
import { TcErrorType } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-error';
import { SpinnerService } from '@tc-core/util/ui/spinner.service';
import { TCO } from '../../../constants';
import { DistributionChannelSearchCriteria } from '../../../models/criteria/distribution-channel-search-criteria';
import { SortDirection } from '../../../models/helper/sort-direction';
import {
    MasterDataRestService
} from '../../../services/backend-consumers/master-data-handler-service/master-data-rest.service';
import { CostingTypeService } from '../../../services/backend-consumers/setups/costing-type-service';
import {
    DistributionChannelSetupService
} from '../../../services/backend-consumers/setups/distribution-channel-setup-service';
import { UserManagementService } from '../../../services/user-management/user-management.service';
import { DMCCommon } from '../../../services/util/common/dmc-common';
import { DataKey, DataStoreService } from '../../../services/util/framework/data-store.service';
import {
    SetupGridComp
} from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid-comp';
import { of, Subscription } from 'rxjs';
import { ConfigLoader } from '@tc-core/util/framework';
import {
    SetupGridDataSource
} from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid-data-source';
import {
    SetupGridComponent
} from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid.component';
import { Observable } from 'rxjs/Observable';
import { IGetRowsParams } from 'ag-grid-community';
import { tap } from 'rxjs/operators';
import { DialogService } from '@tc/dialog/dialog.service';
import { DialogModel, LEVEL } from '@tc-core/model/it/codegen/ui/framework/dialog-model';

@Component({
    selector: 'tc-distribution-channel-setup',
    templateUrl: './distribution-channel-setup.component.html'
})
export class DistributionChannelSetupComponent extends SetupGridComp implements OnInit, SetupGridDataSource {
    @ViewChild('setupGrid')
    private setupGrid: SetupGridComponent;
    public colDefConfig = [];

    // search criteria obj. used to
    private distributionChannelSearchCriteria: DistributionChannelSearchCriteria = new DistributionChannelSearchCriteria();

    private searchCriteriaObserver: Subscription = new Subscription();
    private dialogSubscription: Subscription = new Subscription();

    constructor(
        private configLoader: ConfigLoader,
        private costingTypeService: CostingTypeService,
        private dataStoreService: DataStoreService,
        private masterDataRestService: MasterDataRestService,
        private common: DMCCommon,
        private dataStore: DataStoreService,
        private distributionChannelSetupService: DistributionChannelSetupService,
        private dialogService: DialogService,
        private userManagementService: UserManagementService,
        private spinnerService: SpinnerService
    ) {
        super();
    }

    ngOnInit() {
        this.colDefConfig = this.configLoader.configurations.get(TCO.CONF.CONF_DISTRIBUTION_CHANNEL_SETUP_CONFIG);
        this.subscribeSearchCriteria();
    }

    /**
     * subscribe search criteria changes in search bar
     * then force grid to refresh data
     * grid refresh will be call getRaws method which is using this subscribed criteria for backend call
     */
    subscribeSearchCriteria() {
        this.searchCriteriaObserver = this.dataStoreService.get(DataKey.distributionChannelSetupSearchCriteria)
                                          .subscribe(value => {
                                              this.distributionChannelSearchCriteria = value;
                                              this.setupGrid.runForceSearch();
                                          });
    }

    createNewRow(): any {
        return {unsaved: true};
    }

    deleteRow(row: any): Observable<any> {
        return of('success');
    }

    public getRows(params: IGetRowsParams): Observable<any> {

        let pageSize = 14;
        if (params && params.endRow && params.startRow) {
            pageSize = params.endRow - params.startRow;
        }
        if (!this.distributionChannelSearchCriteria) {
            this.distributionChannelSearchCriteria = new DistributionChannelSearchCriteria();
        }
        this.distributionChannelSearchCriteria = this.userManagementService.updateSearchCriteriaWithUserKC(this.distributionChannelSearchCriteria);
        if (params && params.startRow) {
            this.distributionChannelSearchCriteria.start = params.startRow;
        }
        if (pageSize) {
            this.distributionChannelSearchCriteria.size = pageSize;
        }
        if (params.sortModel && params.sortModel[0]) {
            this.distributionChannelSearchCriteria.sortBy = params.sortModel[0].colId;
            if (params.sortModel[0].sort === SetupGridComp.GRID_SORT_ASCENDING) {
                this.distributionChannelSearchCriteria.sortDirection = SortDirection.ASC;
            } else {
                this.distributionChannelSearchCriteria.sortDirection = SortDirection.DESC;
            }
        }

        return this.distributionChannelSetupService.getRows(this.distributionChannelSearchCriteria)
                   .pipe(
                       tap(
                           data => {
                               this.dataStoreService.set(
                                   DataKey.distributionChannelSetupSearchResultsForCriteria,
                                   data
                               );
                           }
                       )
                   );
    }

    isEditableDistributionChannelRow = function(params: any) {
        const userProfile = this.dataStore.get(DataKey.userProfile).getValue();
        return (userProfile && userProfile.distributionChannel !== undefined);
    };

    public isInvalidRow(params): boolean {
        const data = params.data;
        if (data) {
            let invalid = true;
            if (
                data.code &&
                data.name
            ) {
                invalid = false;
            }
            return invalid;
        } else {
            return false;
        }
    }

    public isDisabledAdd() {
        const userProfile = this.dataStore.get(DataKey.userProfile).getValue();
        return (userProfile && userProfile.kcDistributionChannel);
    }

    public isUnsavedRow(row: any): boolean {
        if (row) {
            return !row.id;
        }
        return true;
    }

    saveRow(row: any, event: any): Observable<any> {
        if (row.unsaved) {
            const confirmSendManifests = new DialogModel(
                true,
                LEVEL.WARNING,
                'Save Distribution Channel',
                'The entered distribution channel cannot be modified or deleted later. Do you want to continue?',
                true,
                2000,
                null,
                'Cancel',
                'Save',
                true
            );
            confirmSendManifests.disableClose = true;
            if (this.dialogSubscription) {
                this.dialogSubscription.unsubscribe();
            }
            const dialogSubscription = this.dialogService
                .confirm(confirmSendManifests)
                .subscribe((res) => {
                        if (res === true) {
                            return this.distributionChannelSetupService.saveRow(row).subscribe(
                                (response) => {
                                    this.userManagementService.getKcDistChannels();
                                    this.spinnerService.show();
                                    dialogSubscription.unsubscribe();
                                    this.setupGrid.runForceSearch();
                                    this.spinnerService.hide();
                                },
                                error => {
                                    this.spinnerService.hide();
                                    if (event && event.data) {
                                        event.data.name = '';
                                    }
                                    this.common.showSnackBar(
                                        'Distribution channel code cannot be duplicated',
                                        3000,
                                        TcErrorType.TYPE.ERROR
                                    );
                                }
                            );
                        } else {
                            if (event && event.data) {
                                event.data.name = '';
                            }
                        }
                    }
                );
        } else {
            return null;
        }
    }
}
