/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../node_modules/@tc/chip-input/chip-input.component.ngfactory";
import * as i2 from "@tc/chip-input/chip-input.service";
import * as i3 from "@tc/chip-input/shared/parser.service";
import * as i4 from "@tc-core/util/system/date-formatter.service";
import * as i5 from "@tc/chip-input/chip-input.component";
import * as i6 from "@tc-core/util/framework/focus-handler.service";
import * as i7 from "@tc-core/service/sorters/sorter.service";
import * as i8 from "../../../services/helpers/chip-handler.service";
import * as i9 from "../../../services/util/framework/data-store.service";
import * as i10 from "@tc-core/util/framework/config-loader.service";
import * as i11 from "@tc-core/util/helpers/common-helper.service";
import * as i12 from "../../../services/backend-consumers/master-data-handler-service/data-handler.service";
import * as i13 from "./product-queue-search-criteria.component";
import * as i14 from "@tc-core/util/framework/event-manager.service";
import * as i15 from "../../../services/util/framework/dmc-query-params.service";
import * as i16 from "../../../services/util/core-services/root.service";
import * as i17 from "@tc-core/util/framework/user-journey-manager.service";
import * as i18 from "../../../services/util/framework/action-handler-service.service";
import * as i19 from "@tc/modal/modal.service";
import * as i20 from "../../../services/backend-consumers/reservation-v2/reservation-v2-management.service";
import * as i21 from "../../../services/util/pre-processors/operation-v2-data-processor.service";
import * as i22 from "../../../services/user-management/user-management.service";
var styles_ProductQueueSearchCriteriaComponent = [];
var RenderType_ProductQueueSearchCriteriaComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ProductQueueSearchCriteriaComponent, data: {} });
export { RenderType_ProductQueueSearchCriteriaComponent as RenderType_ProductQueueSearchCriteriaComponent };
export function View_ProductQueueSearchCriteriaComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { productQueueSearchChipInput: 0 }), (_l()(), i0.ɵeld(1, 0, null, null, 2, "tc-chip-input", [], null, [[null, "searchEmitter"], [null, "onfocus"], [null, "cancelEmitter"], [null, "chipListEmitter"], ["document", "click"], [null, "focusin"], [null, "focusout"], [null, "keyup"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("document:click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 3).handleDocumentClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("focusin" === en)) {
        var pd_1 = (i0.ɵnov(_v, 3).onFocusIn($event) !== false);
        ad = (pd_1 && ad);
    } if (("focusout" === en)) {
        var pd_2 = (i0.ɵnov(_v, 3).onFocusOut($event) !== false);
        ad = (pd_2 && ad);
    } if (("keyup" === en)) {
        var pd_3 = (i0.ɵnov(_v, 3).onTabNavigation($event) !== false);
        ad = (pd_3 && ad);
    } if (("searchEmitter" === en)) {
        var pd_4 = (_co.onProductQueueSearch($event) !== false);
        ad = (pd_4 && ad);
    } if (("onfocus" === en)) {
        var pd_5 = (_co.onFocus($event) !== false);
        ad = (pd_5 && ad);
    } if (("cancelEmitter" === en)) {
        var pd_6 = (_co.onCancel($event) !== false);
        ad = (pd_6 && ad);
    } if (("chipListEmitter" === en)) {
        var pd_7 = (_co.onChipListDisplay($event) !== false);
        ad = (pd_7 && ad);
    } return ad; }, i1.View_ChipInputComponent_0, i1.RenderType_ChipInputComponent)), i0.ɵprd(512, null, i2.ChipInputService, i2.ChipInputService, [i3.Parser, i4.DateFormatter]), i0.ɵdid(3, 770048, [[1, 4], ["product_queue_search_chip_input", 4]], 0, i5.ChipInputComponent, [i2.ChipInputService, i0.ApplicationRef, i6.FocusHandlerService, i7.AlphaNumericSorting, i7.NumberSorting, i0.ElementRef], { isDataLoaded: [0, "isDataLoaded"], chisForDisplay: [1, "chisForDisplay"], isTriggerSearch: [2, "isTriggerSearch"], isChipListOptionAvail: [3, "isChipListOptionAvail"], isFocus: [4, "isFocus"], placeHolderText: [5, "placeHolderText"], inputAuthConfigComponent: [6, "inputAuthConfigComponent"], isSearchBarStyleNotAvailable: [7, "isSearchBarStyleNotAvailable"] }, { searchEmitter: "searchEmitter", chipListEmitter: "chipListEmitter", cancelEmitter: "cancelEmitter", onfocus: "onfocus" }), (_l()(), i0.ɵeld(4, 0, null, null, 0, "div", [["class", "clearfix"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isDataLoaded; var currVal_1 = _co.displayChipsArr; var currVal_2 = _co.isTriggerSearch; var currVal_3 = true; var currVal_4 = _co.isFocus; var currVal_5 = _co.placeHolderText; var currVal_6 = null; var currVal_7 = _co.isSearchBarStyleNotAvailable; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }, null); }
export function View_ProductQueueSearchCriteriaComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "tc-product-queue-search-criteria", [], null, null, null, View_ProductQueueSearchCriteriaComponent_0, RenderType_ProductQueueSearchCriteriaComponent)), i0.ɵprd(6144, null, "ChipHandlerService", null, [i8.ChipHandlerService]), i0.ɵprd(4608, null, i2.ChipInputService, i2.ChipInputService, [i3.Parser, i4.DateFormatter]), i0.ɵprd(512, null, i8.ChipHandlerService, i8.ChipHandlerService, [i0.Injector, i9.DataStoreService, i10.ConfigLoader, i11.CommonHelper, i4.DateFormatter, i12.DataHandlerService]), i0.ɵdid(4, 245760, null, 0, i13.ProductQueueSearchCriteriaComponent, [i10.ConfigLoader, i9.DataStoreService, i14.EventManager, i15.DMCQueryParamsService, i16.RootService, i8.ChipHandlerService, i11.CommonHelper, i17.UserJourneyManager, i18.ActionHandlerService, i19.ModalService, i4.DateFormatter, i20.ReservationV2ManagementService, i21.OperationV2DataProcessorService, i22.UserManagementService], null, null)], function (_ck, _v) { _ck(_v, 4, 0); }, null); }
var ProductQueueSearchCriteriaComponentNgFactory = i0.ɵccf("tc-product-queue-search-criteria", i13.ProductQueueSearchCriteriaComponent, View_ProductQueueSearchCriteriaComponent_Host_0, { inputSearchCriteria: "inputSearchCriteria", createNewSearchCriteriaFn: "createNewSearchCriteriaFn", searchCriteriaUpdatingDataKey: "searchCriteriaUpdatingDataKey", searchResultsDataKey: "searchResultsDataKey", searchCriteriaLoadingDataKey: "searchCriteriaLoadingDataKey", chipConfigKey: "chipConfigKey", clearLoadedSearchCriteriaFn: "clearLoadedSearchCriteriaFn", paginationConfigKey: "paginationConfigKey", placeHolderText: "placeHolderText", isInsideForm: "isInsideForm", searchCriteriaChipConfig: "searchCriteriaChipConfig", isSearchBarStyleNotAvailable: "isSearchBarStyleNotAvailable" }, { searchTrigger: "searchTrigger" }, []);
export { ProductQueueSearchCriteriaComponentNgFactory as ProductQueueSearchCriteriaComponentNgFactory };
