/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../documents-search-results.component.ngfactory";
import * as i2 from "../documents-search-results.component";
import * as i3 from "@tc-core/util/framework/config-loader.service";
import * as i4 from "../../../../services/util/framework/data-store.service";
import * as i5 from "../../../../services/backend-consumers/master-data-handler-service/data-handler.service";
import * as i6 from "../../../../services/backend-consumers/documents/document.service";
import * as i7 from "@angular/router";
import * as i8 from "@tc/focus-view/focus-view.service";
import * as i9 from "./reports-search-results.component";
var styles_ReportsSearchResultsComponent = [];
var RenderType_ReportsSearchResultsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ReportsSearchResultsComponent, data: {} });
export { RenderType_ReportsSearchResultsComponent as RenderType_ReportsSearchResultsComponent };
export function View_ReportsSearchResultsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-documents-search-results", [], null, null, null, i1.View_DocumentsSearchResultsComponent_0, i1.RenderType_DocumentsSearchResultsComponent)), i0.ɵdid(1, 245760, null, 0, i2.DocumentsSearchResultsComponent, [i3.ConfigLoader, i4.DataStoreService, i5.DataHandlerService, i6.DocumentService, i7.ActivatedRoute, i8.FocusViewService], { colDefConfig: [0, "colDefConfig"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.colDefConfig; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ReportsSearchResultsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-reports-search-results", [], null, null, null, View_ReportsSearchResultsComponent_0, RenderType_ReportsSearchResultsComponent)), i0.ɵdid(1, 114688, null, 0, i9.ReportsSearchResultsComponent, [i3.ConfigLoader], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ReportsSearchResultsComponentNgFactory = i0.ɵccf("tc-reports-search-results", i9.ReportsSearchResultsComponent, View_ReportsSearchResultsComponent_Host_0, {}, {}, []);
export { ReportsSearchResultsComponentNgFactory as ReportsSearchResultsComponentNgFactory };
