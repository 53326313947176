import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ServiceInfoCardComponent } from './service-info-card.component';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule.forChild()
    ],
    declarations: [ServiceInfoCardComponent],
    exports: [ServiceInfoCardComponent]
})
export class ServiceInfoCardModule {}
