import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { DialogModel } from '@tc-core/model/it/codegen/ui/framework/dialog-model';
import { LEVEL } from '@tc-core/model/it/codegen/ui/framework/modal-data';
// import { CgCalendarCssClassConstants } from '@tc/cg-calendar/cg-calendar-css-class-constants';
// import { RangeGroup } from '@tc/cg-calendar/range-group';
// import { SeasonCalendarService } from '@tc/cg-season-calendar/season-calendar.service';
import { DialogService } from '@tc/dialog/dialog.service';
import { CgCalendarCssClassConstants } from '../../cg-calendar/cg-calendar-css-class-constants';
import { RangeGroup } from '../../cg-calendar/range-group';
import { SeasonCalendarService } from '../season-calendar.service';
var SeasonsEditCardComponent = /** @class */ (function () {
    function SeasonsEditCardComponent(dialogRef, seasonCalendarService, dialogService) {
        this.dialogRef = dialogRef;
        this.seasonCalendarService = seasonCalendarService;
        this.dialogService = dialogService;
        this.rangeGroups = [];
        this.newRangeGroupNamePrefix = '';
        this.newRangeGroups = [];
        this.allRangeGroups = [];
        this.removingRangeGroups = [];
        this.cgCalDateRangeColorCssClasses = [];
        this.lastAddedRangeGroup = null;
    }
    SeasonsEditCardComponent.prototype.ngOnInit = function () {
        this.allRangeGroups = this.allRangeGroups.concat(this.rangeGroups);
        this.setTemporaryAttributes();
        this.getColorIds();
    };
    SeasonsEditCardComponent.prototype.setTemporaryAttributes = function () {
        for (var j = 0; j < this.rangeGroups.length; j++) {
            this.rangeGroups[j].tempName = this.rangeGroups[j].name;
            this.rangeGroups[j].tempColorId = this.rangeGroups[j].colorId;
        }
    };
    SeasonsEditCardComponent.prototype.getDataFromTempAttributes = function () {
        for (var j = 0; j < this.rangeGroups.length; j++) {
            this.rangeGroups[j].name = this.rangeGroups[j].tempName;
            this.rangeGroups[j].colorId = this.rangeGroups[j].tempColorId;
        }
    };
    SeasonsEditCardComponent.prototype.getColorIds = function () {
        for (var colorIdKey in CgCalendarCssClassConstants.ColorId) {
            this.cgCalDateRangeColorCssClasses.push(CgCalendarCssClassConstants.ColorId[colorIdKey]);
        }
    };
    SeasonsEditCardComponent.prototype.onAddSeasonClick = function ($event) {
        var freeColor = this.getFreeColor();
        if (freeColor) {
            var rangeGroup = new RangeGroup();
            rangeGroup.tempName = this.createSeasonName();
            rangeGroup.tempColorId = freeColor;
            this.lastAddedRangeGroup = rangeGroup;
            this.newRangeGroups.splice(0, 0, rangeGroup);
            this.allRangeGroups.splice(0, 0, rangeGroup);
        }
    };
    SeasonsEditCardComponent.prototype.createSeasonName = function () {
        var name = this.newRangeGroupNamePrefix;
        var maxSeasonNum = 0;
        for (var j = 0; j < this.allRangeGroups.length; j++) {
            var rangeGroup = this.allRangeGroups[j];
            var arr = rangeGroup.tempName.split(this.newRangeGroupNamePrefix);
            var number = Number(arr[1]);
            if (arr.length > 1 && !isNaN(number)) {
                if (number > maxSeasonNum) {
                    maxSeasonNum = number;
                }
            }
        }
        maxSeasonNum++;
        name += maxSeasonNum;
        return name;
    };
    SeasonsEditCardComponent.prototype.getFreeColor = function () {
        var colorId = '';
        var _loop_1 = function (colorIdKey) {
            var match = false;
            this_1.allRangeGroups.forEach(function (group) {
                if (group.tempColorId === CgCalendarCssClassConstants.ColorId[colorIdKey].class) {
                    match = true;
                }
            });
            if (!match) {
                colorId = CgCalendarCssClassConstants.ColorId[colorIdKey].class;
                return { value: colorId };
            }
        };
        var this_1 = this;
        for (var colorIdKey in CgCalendarCssClassConstants.ColorId) {
            var state_1 = _loop_1(colorIdKey);
            if (typeof state_1 === "object")
                return state_1.value;
        }
        return colorId;
    };
    SeasonsEditCardComponent.prototype.onRemoveSeason = function (rangeGroup) {
        var _this = this;
        if (!rangeGroup.nonRemovable) {
            var confirmSuccess = new DialogModel(true, LEVEL.WARNING, 'Remove Season', "Do you want to delete the season '" + rangeGroup.name + "' ?", true, 2000, null, 'No', 'Yes');
            this.dialogService
                .confirm(confirmSuccess)
                .subscribe(function (res) {
                if (res === true) {
                    if (_this.lastAddedRangeGroup === rangeGroup) {
                        _this.lastAddedRangeGroup = null;
                    }
                    // delete range
                    _this.removingRangeGroups.push(rangeGroup);
                    _this.seasonCalendarService.removeRangeGroup(_this.allRangeGroups, rangeGroup, true);
                    _this.seasonCalendarService.removeRangeGroup(_this.newRangeGroups, rangeGroup, true);
                }
            });
        }
        else {
            var confirmSuccess = new DialogModel(true, LEVEL.WARNING, 'Invalid Attempt', "This season cannot be deleted.", true, 2000, null, null, 'OK');
            this.dialogService
                .confirm(confirmSuccess);
        }
    };
    SeasonsEditCardComponent.prototype.onClose = function ($event) {
        this.dialogRef.close();
    };
    SeasonsEditCardComponent.prototype.onSave = function ($event) {
        this.seasonCalendarService.removeRangeGroups(this.rangeGroups, this.removingRangeGroups);
        this.seasonCalendarService.addNewRangeGroups(this.rangeGroups, this.newRangeGroups);
        this.getDataFromTempAttributes();
        if (this.lastAddedRangeGroup) {
            this.seasonCalendarService.selectRangeGroup(this.rangeGroups, this.lastAddedRangeGroup);
        }
        this.dialogRef.close({ rangeGroups: this.rangeGroups });
    };
    SeasonsEditCardComponent.prototype.onColorChanged = function (rangeGroup, colorClass) {
        if (!this.isDisableColor(colorClass)) {
            rangeGroup.tempColorId = colorClass.class;
        }
    };
    SeasonsEditCardComponent.prototype.isEnableSaveButton = function () {
        var enable = true;
        for (var i = 0; i < this.allRangeGroups.length; i++) {
            if (!this.allRangeGroups[i].tempName) {
                enable = false;
            }
            else if (!this.allRangeGroups[i].tempColorId) {
                enable = false;
            }
        }
        return enable;
    };
    SeasonsEditCardComponent.prototype.onClickPalette = function () {
        setTimeout(function () {
            var colorIndicator = document
                .querySelector('.tc-sc-color-palette__item');
            if (colorIndicator && colorIndicator.parentElement && colorIndicator.parentElement.parentElement &&
                colorIndicator.parentElement.parentElement.parentElement) {
                colorIndicator.parentElement.parentElement.parentElement.classList.add('tc-sc-color-palette');
            }
        }, 0);
    };
    SeasonsEditCardComponent.prototype.isDisableColor = function (colorClass) {
        var disable = false;
        for (var i = 0; i < this.allRangeGroups.length; i++) {
            var group = this.allRangeGroups[i];
            if (group.tempColorId === colorClass.class) {
                disable = true;
            }
        }
        return disable;
    };
    return SeasonsEditCardComponent;
}());
export { SeasonsEditCardComponent };
