<mat-card>
  <div tabindex="0">
    <mat-radio-group #selectBox
      [(ngModel)]="selectedItem"
      (ngModelChange)="onValueChange($event)"
      [style.width]="columnWidth">
      <mat-radio-button *ngFor="let option of options" [value]="option">
        {{displayItemFn(option) | translate}}
      </mat-radio-button>
    </mat-radio-group>
  </div>
</mat-card>
