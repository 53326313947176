import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FocusViewManager } from '@tc-core/util/framework/focus-view-manager.service';
import { SummaryCardData } from '@tc-core/model/it/codegen/ui/framework/summary-card-data';
import {
    CityChange,
    CountryChange,
    CountryStateCityWrapper, InitialCountryStateCityCodes,
    StateChange
} from '../../../models/common/country-state-city-wrapper';
import { City, Country, State } from '../../../models/common/locale';
import { ContactDetails } from '../../../models/profile/contact-details';
import { ProfileManagementService } from '../../../services/backend-consumers/supplier-service/profile-management.service';
import { ChangeDetectorService } from '../../../services/util/change-detector/change-detector.service';
import { DataStoreService } from '../../../services/util/framework/data-store.service';
import { IdentifierService } from '../../../services/util/identifier/identifier.service';
import { RegXPatterns } from '../regx-patterns/regx-patterns';

@Component({
    selector: 'tc-additional-contact-edit-card',
    templateUrl: './additional-contact-edit-card.component.html'
})
export class AdditionalContactEditCardComponent implements OnInit, OnChanges {

    @Input() contactDetails: ContactDetails;
    @Input() titles: any[];
    @Input() categories: any[];
    @Input() additionalContactContent: SummaryCardData;

    // country state city
    @Input() cityList: City[];
    @Input() stateList: State[];
    @Input() countryList: Country[];
    @Input() countryStateCityChangeMap: Map<number, CountryStateCityWrapper> = new Map();

    @Output() newCountryStateCityObserver: EventEmitter<InitialCountryStateCityCodes> = new EventEmitter();
    @Output() selectCountry: EventEmitter<CountryChange> = new EventEmitter();
    @Output() selectState: EventEmitter<StateChange> = new EventEmitter();
    @Output() selectCity: EventEmitter<CityChange> = new EventEmitter();

    @Output() countryTextChange: EventEmitter<CountryChange> = new EventEmitter();
    @Output() stateTextChange: EventEmitter<StateChange> = new EventEmitter();
    @Output() cityTextChange: EventEmitter<CityChange> = new EventEmitter();
    // --country state city--

    @Output() changeFormValidity: EventEmitter<boolean> = new EventEmitter();

    idForLocaleChange: number;

    contactDetailsForm: FormGroup;

    emailRegex = '^[a-zA-Z0-9!#$%&\'*+\\/=?^_`{|}~.-]+@[a-zA-Z0-9]([a-zA-Z0-9-]*[a-zA-Z0-9])?(\\.[a-zA-Z0-9]([a-zA-Z0-9-]*[a-zA-Z0-9])?)+$';
    telephoneRegex = '^[\\+]?[(]?[0-9]{3}[)]?[-\\s\\.]?[0-9]{3}[-\\s\\.]?[0-9]{4,9}$';

    constructor(
        private fb: FormBuilder,
        private profileManagementService: ProfileManagementService,
        private dataStore: DataStoreService,
        private focusViewManager: FocusViewManager,
        private identifierService: IdentifierService,
        private changeDetectorService: ChangeDetectorService
    ) {
    }

    ngOnInit() {
        this.focusViewManager.canProceedState.next(true);

        // country state city
        this.idForLocaleChange = this.identifierService.getNextId();
        // --country state city--

        this.createForm();

        this.contactDetailsForm.valueChanges.subscribe(
            (data) => {
                if (JSON.stringify(data) !== JSON.stringify({})) {
                    this.contactDetails.category = data.category;
                    this.contactDetails.title = data.title;
                    this.contactDetails.firstName = data.firstName;
                    this.contactDetails.lastName = data.lastName;
                    this.contactDetails.email = data.email;
                    this.contactDetails.altEmail = data.alternateEmail;
                    this.contactDetails.telNum = data.telephoneNumber;
                    this.contactDetails.altTelNum = data.alternateTelephoneNumber;
                    this.contactDetails.mobNum = data.mobileNumber;
                    this.contactDetails.faxNum = data.faxNumber;
                    this.contactDetails.street = data.streetAddress;
                    this.contactDetails.postCode = data.postCode;

                    // country state city
                    if (typeof (data.country) === 'string' && typeof (data.country) !== 'object') {
                        if (this.clearCountry(data.country)) {
                            data.country=null;
                        }
                        this.countryTextChange.emit({id: this.idForLocaleChange, text: data.country, country: null});
                    }
                    if (typeof (data.state) === 'string' && typeof (data.state) !== 'object') {
                        if (this.clearState(data.state)) {
                            data.state = null;
                        }
                        this.stateTextChange.emit({id: this.idForLocaleChange, text: data.state, state: null});
                    }
                    if (typeof (data.city) === 'string' && typeof (data.city) !== 'object') {
                        if (this.clearCity(data.city)){
                            data.city = null;
                        }
                        this.cityTextChange.emit({id: this.idForLocaleChange, text: data.city, city: null});
                    }
                    // --country state city--

                    this.contactDetails.status = false;


                    if (this.changeFormValidity) {
                        this.changeFormValidity.emit(this.contactDetailsForm.valid);
                    }
                }

            }
        );

        // country state city
        this.newCountryStateCityObserver.emit(
            {
                id: this.idForLocaleChange,
                cityCode: this.contactDetails.city,
                stateCode: this.contactDetails.state,
                countryCode: this.contactDetails.country
            });
        this.subscribeCountryStateCityChange();
        // --country state city--
        this.validateForm();
    }

    public validateForm() {
        Object.keys(this.contactDetailsForm.controls).forEach(field => {
            const control = this.contactDetailsForm.get(field);
            control.markAsTouched({onlySelf: true});
        });
    }

    private createForm() {
        this.contactDetailsForm = this.fb.group({
            category: [this.contactDetails ? this.contactDetails.category : '', Validators.required],
            title: [this.contactDetails ? this.contactDetails.title : '', Validators.required],
            firstName: [
                this.contactDetails ? this.contactDetails.firstName : '',
                [Validators.required, Validators.pattern(RegXPatterns.LETTERS_ONLY_NAME_MAX30)]
            ],
            lastName: [
                this.contactDetails ? this.contactDetails.lastName : '',
                [Validators.required, Validators.pattern(RegXPatterns.LETTERS_ONLY_NAME_MAX30)]
            ],
            email: [
                this.contactDetails ? this.contactDetails.email : '',
                [Validators.pattern(RegXPatterns.EMAIL), Validators.required]
            ],
            alternateEmail: [
                this.contactDetails ? this.contactDetails.altEmail : '',
                [Validators.pattern(RegXPatterns.EMAIL)]
            ],
            telephoneNumber: [
                this.contactDetails ? this.contactDetails.telNum : '',
                [Validators.pattern(RegXPatterns.TELEPHONE), Validators.required]
            ],
            alternateTelephoneNumber: [
                this.contactDetails ? this.contactDetails.altTelNum : '',
                [Validators.pattern(RegXPatterns.TELEPHONE)]
            ],
            faxNumber: [
                this.contactDetails ? this.contactDetails.faxNum : '',
                [Validators.pattern(RegXPatterns.TELEPHONE)]
            ],
            mobileNumber: [
                this.contactDetails ? this.contactDetails.mobNum : '',
                [Validators.pattern(RegXPatterns.TELEPHONE)]
            ],
            streetAddress: [this.contactDetails ? this.contactDetails.street : ''],
            postCode: [this.contactDetails ? this.contactDetails.postCode : ''],

            country: [
                '', Validators.compose([Validators.required, StringValidator])
            ],
            state: [
                ''
            ],
            city: [
                '', Validators.compose([Validators.required, StringValidator])
            ],

            status: [this.contactDetails ? this.contactDetails.status : '']
        });

        this.changeFormValidity.emit(this.contactDetailsForm.valid);
    }

    // country state city
    public onCityFocus($event: FocusEvent) {
        this.cityList = [];
        this.cityTextChange.emit({id: this.idForLocaleChange, text: '', city: null});
    }

    public onStateFocus($event: FocusEvent) {
        this.stateList = [];
        this.stateTextChange.emit({id: this.idForLocaleChange, text: '', state: null});
    }

    public onCountryFocus($event: FocusEvent) {
        this.countryList = [];
        this.countryTextChange.emit({id: this.idForLocaleChange, text: '', country: null});
    }

    private clearCountry(countryText: string):boolean {
        if (countryText === '') {
            this.countryStateCityChangeMap.get(this.idForLocaleChange).country = null;
            this.countryStateCityChangeMap.get(this.idForLocaleChange).state = null;
            this.countryStateCityChangeMap.get(this.idForLocaleChange).city = null;
            this.updateCountryStateCityForm();
            return true;
        }else {
            return false;
        }
    }

    private clearState(stateText):boolean {
        if (stateText === '') {
            this.countryStateCityChangeMap.get(this.idForLocaleChange).state = null;
            this.countryStateCityChangeMap.get(this.idForLocaleChange).city = null;
            this.updateCountryStateCityForm();
            return true;
        }else {
            return false;
        }
    }

    private clearCity(cityText):boolean {
        if (cityText === '') {
            this.countryStateCityChangeMap.get(this.idForLocaleChange).city = null;
            this.updateCountryStateCityForm();
            return true;
        }else {
            return false;
        }
    }

    public onSelectCity(city: City) {
        this.selectCity.emit({city: city, id: this.idForLocaleChange, text: null});
    }

    public onSelectState(state: State) {
        this.selectState.emit({state: state, id: this.idForLocaleChange, text: null});
    }

    public onSelectCountry(country: Country) {
        this.selectCountry.emit({country: country, id: this.idForLocaleChange, text: null});
    }

    subscribeCountryStateCityChange() {
        this.changeDetectorService.notifierCountryStateCityChange
            .subscribe(value => {
                if (this.countryStateCityChangeMap.get(this.idForLocaleChange)) {
                    this.updateCountryStateCityForm();
                }
            });
    }

    updateCountryStateCityForm() {
        if (this.countryStateCityChangeMap.get(this.idForLocaleChange)) {

            let state = this.countryStateCityChangeMap.get(this.idForLocaleChange).state;
            if (state && state.code === '' &&
                (this.countryStateCityChangeMap.get(this.idForLocaleChange).country === undefined ||
                    this.countryStateCityChangeMap.get(this.idForLocaleChange).country === null)) {
                state = null;
            }

            this.contactDetailsForm.patchValue({
                country: this.countryStateCityChangeMap.get(this.idForLocaleChange).country,
                state: state,
                city: this.countryStateCityChangeMap.get(this.idForLocaleChange).city
            });

            this.contactDetails.country = this.countryStateCityChangeMap.get(this.idForLocaleChange).country ?
                this.countryStateCityChangeMap.get(this.idForLocaleChange).country.code :
                '';
            this.contactDetails.state = this.countryStateCityChangeMap.get(this.idForLocaleChange).state ?
                this.countryStateCityChangeMap.get(this.idForLocaleChange).state.code :
                '';
            this.contactDetails.city = this.countryStateCityChangeMap.get(this.idForLocaleChange).city ?
                this.countryStateCityChangeMap.get(this.idForLocaleChange).city.code :
                '';
            this.changeFormValidity.emit(this.contactDetailsForm.valid);
        }
    }

    public displayCountryFn(destination: any): any {
        return destination ? destination.name : destination;
    }

    public displayStateFn(destination: any): any {
        return destination ? destination.name : destination;
    }

    public displayCityFn(destination: any): any {
        return destination ? destination.name : destination;
    }
    // -- country state city--

    ngOnChanges(changes: SimpleChanges) {
        for (let propName in changes) {
            if (propName === 'contactDetails') {
                if (this.contactDetails && this.contactDetailsForm) {
                    this.contactDetailsForm.patchValue({

                        category: this.contactDetails.category,
                        title: this.contactDetails.title,
                        firstName: this.contactDetails.firstName,
                        lastName: this.contactDetails.lastName,
                        email: this.contactDetails.email,
                        alternateEmail:this.contactDetails.altEmail,
                        telephoneNumber:this.contactDetails.telNum,
                        alternateTelephoneNumber:this.contactDetails.altTelNum,
                        mobileNumber: this.contactDetails.mobNum,
                        faxNumber:this.contactDetails.faxNum,
                        streetAddress:this.contactDetails.street,
                        postCode: this.contactDetails.postCode,
                        status: false
                    });

                    this.changeFormValidity.emit(this.contactDetailsForm.valid);
                }
            }
        }
    }

    getErrorMessage(field: string): string {
        switch (field) {
            case 'category' :
                return this.contactDetailsForm.get('category').hasError('required') ? 'Category Required' : '';
            case 'title' :
                return this.contactDetailsForm.get('title').hasError('required') ? 'Title Required' : '';
            case 'firstName' :
                return this.contactDetailsForm.get('firstName').hasError('required') ? 'First Name Required' :
                    this.contactDetailsForm.controls['firstName'].hasError('pattern') ? 'First Name invalid' : '';
            case 'lastName' :
                return this.contactDetailsForm.get('lastName').hasError('required') ? 'Last Name Required' :
                    this.contactDetailsForm.controls['lastName'].hasError('pattern') ? 'Last Name invalid' : '';
            case 'country' :
                return this.contactDetailsForm.get('country').hasError('required') ? 'Country Required' :
                    this.contactDetailsForm.controls['country'].hasError('invalidString') ? 'Country invalid' : '';
            case 'city' :
                return this.contactDetailsForm.get('city').hasError('required') ? 'City Required' :
                    this.contactDetailsForm.controls['city'].hasError('invalidString') ? 'City invalid' : '';
            case 'email' :
                return this.contactDetailsForm.get('email').hasError('required') ? 'Email Required' :
                    this.contactDetailsForm.controls['email'].hasError('pattern') ? 'Email invalid' : '';
            case 'alternateEmail' :
                return this.contactDetailsForm.controls['alternateEmail'].hasError('pattern') ? 'Email invalid' : '';
            case 'telephoneNumber' :
                return this.contactDetailsForm.get('telephoneNumber').hasError('required') ? 'Telephone Number Required' :
                    this.contactDetailsForm.controls['telephoneNumber'].hasError('pattern') ? 'Telephone Number invalid' : '';
            case 'alternateTelephoneNumber' :
                return this.contactDetailsForm.controls['alternateTelephoneNumber'].hasError('pattern') ? 'Telephone Number invalid' : '';
            case 'mobileNumber' :
                return this.contactDetailsForm.controls['mobileNumber'].hasError('pattern') ? 'Mobile Number invalid' : '';
            case 'faxNumber' :
                return this.contactDetailsForm.controls['faxNumber'].hasError('pattern') ? 'Fax Number invalid' : '';
            default :
                return '';
        }
    }

}

export function StringValidator(control: AbstractControl) {
    if ( !control.value || !control.value.name || (control.value.name === 'NOT SPECIFIED')) {
        return {invalidString: true};
    } else {
        return null;
    }
}




