import { Injectable } from '@angular/core';
import { AssignmentStatus } from '../../../models/reservation/assignment-status';
import { ServiceAssignment } from '../../../models/reservation/service-assignment';
import { ServiceItem } from '../../../models/reservation/service-item';

@Injectable()
export class ServiceAssignmentUtilService {

    constructor() { }

    // todo check does this return all assigned state and is that what required
    public static isAssignedAny(assignments: ServiceAssignment[]): boolean {
        for (let assignment of assignments) {
            if (assignment.resourceId > 0 || assignment.supplierId > 0) {
                return true;
            } else {
                continue;
            }
        }
        return false;
    }

    public static isSupplierConfirmedOrDmcConfirmedAll(assignments: ServiceAssignment[]): boolean {
        let state = true;
        for (const assignment of assignments) {
            if (!(assignment.assignStatus === AssignmentStatus.SUPPLIER_CONFIRMED || assignment.assignStatus ===
                AssignmentStatus.DMC_CONFIRM)) {
                state = false;
            }
        }
        return state;
    }

    public static isSupplierConfirmedAny(assignments: ServiceAssignment[]): boolean {
        for (const assignment of assignments) {
            if (assignment.assignStatus === AssignmentStatus.SUPPLIER_CONFIRMED) {
                return true;
            } else {
                continue;
            }
        }
        return false;
    }

    public static isAssignedAnyTrs(assignments: ServiceAssignment[]): boolean {
        for (let assignment of assignments) {
            if (ServiceAssignment.isAssigned(assignment) && ServiceAssignment.isTrsAssign(assignment)) {
                return true;
            }
        }
        return false;
    }

    public static isAssignedAll(assignments: ServiceAssignment[]) {
        let allAreAssigned = true;
        if (assignments) {
            for (let assignment of assignments) {
                allAreAssigned = assignment && ServiceAssignment.isAssigned(assignment);
                if (!allAreAssigned) {
                    break;
                }
            }
            return allAreAssigned;
        }
        return allAreAssigned;
    }

    public static isRestrictedAny(assignments: ServiceAssignment[]) {
        let anyoneRestricted = false;
        if (assignments) {
            for (let assignment of assignments) {
                anyoneRestricted = assignment && ServiceAssignment.isRestrictedEdit(assignment);
                if (anyoneRestricted) {
                    break;
                }
            }
            return anyoneRestricted;
        }
        return anyoneRestricted;
    }

    public static getTrsAllocatedPassengersCount(serviceItem: ServiceItem): number {
        let allocatedPassengerCount = 0;
        if (serviceItem && serviceItem.serviceAssignments) {
            if (ServiceAssignmentUtilService.isAssignedAnyTrs(serviceItem.serviceAssignments)) {
                if (serviceItem.servicePassengers) {
                    allocatedPassengerCount += serviceItem.servicePassengers.length;
                }
            }
        }
        return allocatedPassengerCount;
    }

    public static getGroupStatus(serviceItems: ServiceItem[]) {

        /*
         Status code priorities:

         SUPPLIER_REJECTED   : 1 or more has been rejected
         PENDING             : no any rejected & 1 or more has been pending
         CONFIRM             : all has been confirmed
         SUPPLIER_CONFIRM    : all has been supplier confirmed

         */

        let statusCounts: Map<string, number> = new Map();

        let totalAssignments = 0;
        for (const item of serviceItems) {
            if (!item.serviceAssignments || item.serviceAssignments.length === 0) {
                ServiceAssignmentUtilService.updateStatusCountMap(statusCounts, AssignmentStatus.PENDING);
            } else {
                totalAssignments += item.serviceAssignments.length;

                for (const assignment of item.serviceAssignments) {
                    ServiceAssignmentUtilService.updateStatusCountMap(statusCounts, assignment.assignStatus);
                }
            }
        }

        if (statusCounts.has(AssignmentStatus.SUPPLIER_REJECTED) &&
            statusCounts.get(AssignmentStatus.SUPPLIER_REJECTED) > 0) {
            return AssignmentStatus.SUPPLIER_REJECTED;
        } else if (statusCounts.has(AssignmentStatus.BOOKING_UPDATE) && statusCounts.get(AssignmentStatus.BOOKING_UPDATE) > 0) {
            return AssignmentStatus.BOOKING_UPDATE;
        } else if (statusCounts.has(AssignmentStatus.REQUEST_EXPIRED) && statusCounts.get(AssignmentStatus.REQUEST_EXPIRED) > 0) {
            return AssignmentStatus.REQUEST_EXPIRED;
        } else if (statusCounts.has(AssignmentStatus.PENDING) && statusCounts.get(AssignmentStatus.PENDING) > 0) {
            return AssignmentStatus.PENDING;
        } else if (statusCounts.has(AssignmentStatus.DMC_CONFIRM) && statusCounts.get(AssignmentStatus.DMC_CONFIRM) === totalAssignments) {
            return AssignmentStatus.DMC_CONFIRM;
        } else if (statusCounts.has(AssignmentStatus.SUPPLIER_CONFIRMED) && statusCounts.get(AssignmentStatus.SUPPLIER_CONFIRMED) === totalAssignments) {
            return AssignmentStatus.SUPPLIER_CONFIRMED;
        }else if (statusCounts.has(AssignmentStatus.NO_SHOW) && statusCounts.get(AssignmentStatus.NO_SHOW) === totalAssignments) {
            return AssignmentStatus.NO_SHOW;
        }else if (statusCounts.has(AssignmentStatus.INACTIVE) && statusCounts.get(AssignmentStatus.INACTIVE) === totalAssignments) {
            return AssignmentStatus.INACTIVE;
        } else {
            return AssignmentStatus.PENDING;
        }
    }

    static updateStatusCountMap(statusCounts: Map<string,number>, status: string) {
        if (statusCounts.has(status)) {
            statusCounts.set(status, statusCounts.get(status) + 1);
        } else {
            statusCounts.set(status, 1);
        }
    }

}

