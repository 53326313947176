import * as tslib_1 from "tslib";
import { AbstractSearchCriteria } from './abstract-search-criteria';
var RoomTypeSearchCriteria = /** @class */ (function (_super) {
    tslib_1.__extends(RoomTypeSearchCriteria, _super);
    function RoomTypeSearchCriteria() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.code = '';
        _this.name = '';
        return _this;
    }
    return RoomTypeSearchCriteria;
}(AbstractSearchCriteria));
export { RoomTypeSearchCriteria };
