import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { DocumentType } from '../../models/criteria/document-creation-criteria';
import { DocumentCreationModel } from '../../models/helper/document-creation-model';
import { DataKey, DataStoreService } from '../../services/util/framework/data-store.service';
import { BackendConfigService } from '../../services/backend-consumers/service-config/backend-config.service';
import { BackendConfigCodes } from '../../services/backend-consumers/service-config/backend-config-codes';

@Component({
    selector: 'tc-document-creation-modal',
    templateUrl: './document-creation-modal.component.html'
})
export class DocumentCreationModalComponent implements OnInit, OnDestroy {
    @Input() public documentTypes: any[] = [];
    @Input() public documentCreationModel: DocumentCreationModel = new DocumentCreationModel();

    public docCreationForm: FormGroup;
    public exportReport = DocumentType.queueV2ExportReport;

    public servicingViewType;

    formChangesObserver: Subscription = new Subscription();

    minItemsToRunInBackground = 0;
    runInBackground = false;
    numOfItemsSelected = 0;

    constructor(
        private dataStoreService: DataStoreService,
        private fb: FormBuilder,
        private backendConfigService: BackendConfigService
    ) {
    }

    ngOnInit() {
        this.minItemsToRunInBackground = this.backendConfigService.getConfigInt(BackendConfigCodes.BACKGROUND_ITEM_LIMIT_MAN_DOCS);

        if (this.documentCreationModel && this.documentCreationModel.selectedBookingItemIds) {
            this.numOfItemsSelected = this.documentCreationModel.selectedBookingItemIds.length;
            if (this.numOfItemsSelected === 0 || this.numOfItemsSelected >= this.minItemsToRunInBackground) {
                this.runInBackground = true;
                this.documentCreationModel.download = false;
                this.documentCreationModel.persist = true;
            } else {
                this.runInBackground = false;
            }
        } else {
            this.runInBackground = false;
        }

        this.documentCreationModel.viewType = 'SERVICING_VIEW';
        if (this.documentTypes && this.documentTypes.length > 0) {
            this.documentCreationModel.documentType = this.documentTypes[0];
        }
        this.createForm();
        this.formChangesObserver = this.docCreationForm.valueChanges.subscribe(
            data => {
                if (JSON.stringify(data) !== JSON.stringify({})) {
                    this.updateDataModel();
                }
            }
        );
    }

    private createForm() {
        this.docCreationForm = this.fb.group({
            documentType: this.documentCreationModel.documentType,
            documentName: new FormControl({
                value: this.documentCreationModel.documentName,
                disabled: this.documentCreationModel.autoName
            }),
            autoName: this.documentCreationModel.autoName,
            download: new FormControl({
                value: this.documentCreationModel.download,
                disabled: this.runInBackground
            }),
            persist: new FormControl({
                value: this.documentCreationModel.persist,
                disabled: this.runInBackground
            }),
            notifySupplier: new FormControl({
                value: this.documentCreationModel.notifySupplier,
                disabled: false
            }),
            viewType: this.documentCreationModel.viewType
        });
    }

    public onClose() {
        this.dataStoreService.set(DataKey.popupClose, false);
    }

    public onOkClick() {
        this.dataStoreService.set(DataKey.popupClose, true);
    }

    public isOkDisabled() {
        return !(this.documentCreationModel.documentType &&
            (this.documentCreationModel.autoName || this.documentCreationModel.documentName) &&
            (this.documentCreationModel.download || this.documentCreationModel.persist));
    }

    public isNameReadOnly() {
        return this.documentCreationModel.autoName;
    }

    private updateDataModel() {
        this.documentCreationModel.persist = this.docCreationForm.get('persist').value;
        this.documentCreationModel.download = this.docCreationForm.get('download').value;
        this.documentCreationModel.documentName = this.docCreationForm.get('documentName').value;
        this.documentCreationModel.documentType = this.docCreationForm.get('documentType').value;
        this.documentCreationModel.autoName = this.docCreationForm.get('autoName').value;
        this.documentCreationModel.viewType = this.docCreationForm.get('viewType').value;
        this.documentCreationModel.notifySupplier = this.docCreationForm.get('notifySupplier').value;

        if (this.documentCreationModel.autoName) {
            this.documentCreationModel.documentName = '';
            this.docCreationForm.get('documentName').disable({
                onlySelf: true
            });
        } else {
            this.docCreationForm.get('documentName').enable({
                onlySelf: true
            });
        }
        if (this.documentCreationModel.notifySupplier) {
            this.documentCreationModel.persist = true;
            this.docCreationForm.get('persist').setValue(true, {onlySelf: true});
            this.docCreationForm.get('persist').disable({
                onlySelf: true
            });
        } else if (!this.runInBackground) {
            this.docCreationForm.get('persist').enable({
                onlySelf: true
            });
        }
    }

    public changeAutoName() {
        this.docCreationForm.get('documentName').setValue('', {onlySelf: true});
    }

    public ngOnDestroy(): void {
        if (this.formChangesObserver) {
            this.formChangesObserver.unsubscribe();
        }
    }
}
