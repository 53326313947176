import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { SummaryCardData } from '@tc-core/model/it/codegen/ui/framework/summary-card-data';
import { SplitPassengerData, SplitPassengerDataHeader } from '../../../models/reservation/split-passenger-data';

@Component({
    selector: 'tc-split-passengers-view',
    templateUrl: './split-passengers-view.component.html'
})
export class SplitPassengersViewComponent implements OnInit {

    @Input() transferMode: string;
    @Input() serviceItem: SummaryCardData;
    @Input() passengerData: SplitPassengerData[];
    @Input() passengerDataHeadings: SplitPassengerDataHeader[];
    @Input() passengerGroups: any;
    @Input() groupingOptionList: any[];
    @Input() isRequestCompleted: boolean;

    @Output() split: EventEmitter<any> = new EventEmitter();
    @Output() closePopUp: EventEmitter<any> = new EventEmitter();
    @Output() save: EventEmitter<any> = new EventEmitter();
    @Output() selectGroup: EventEmitter<any> = new EventEmitter();
    @Output() selectGrouping: EventEmitter<any> = new EventEmitter();

    passengersPerGroup: number;
    passengerCount: number;

    constructor() { }

    ngOnInit() {
        this.passengerCount = this.passengerData.length;
    }

    public onClose($event: MouseEvent) {
        this.closePopUp.emit($event);
    }

    public onSave($event: MouseEvent) {
        console.log(this.isSaveDisabled());
        this.save.emit($event);

    }

    public onSpit($event: MouseEvent) {
        this.split.emit({'paxPerGroup': this.passengersPerGroup});
    }

    public setPassengerGroup($event: any, item: SplitPassengerData) {
        this.selectGroup.emit({'passenger': item, 'group': $event});
    }

    public onSelectGroupingType($event: any) {
        this.selectGrouping.emit($event);
    }

    public isSaveDisabled() {
        if (this.isRequestCompleted) {
            if (!(this.passengerGroups && this.passengerGroups.length > 0)) {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }
    }
}
