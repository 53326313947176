export namespace RegXPatterns {

    export const LETTERS_ONLY = /^[a-zA-Z]+$/;

    export const ANY_MAX10 = /^(.){1,10}$/;

    export const ANY_MAX30 = /^(.){1,30}$/;

    export const ANY_MAX40 = /^(.){1,30}$/;

    export const LETTERS_ONLY_NAME_MAX30 = /^([a-zA-Z ']){1,30}$/;

    export const POSITIVE_NUMBERS= /^\d+$/;

    export const POSITIVE_NUMBERS_3DIGIT= /^\d{1,3}$/;

    export const TELEPHONE = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,9}$/;

    export const EMAIL = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

}
