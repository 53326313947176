import {
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    QueryList,
    ViewChildren
} from '@angular/core';
import { Cart } from '@tc-core/model/it/codegen/tbx/api/cart/cart';
import { Client } from '@tc-core/model/it/codegen/tbx/api/client/client';
import { ClientSummary } from '@tc-core/model/it/codegen/tbx/api/client/client-summary';
import { NavItem } from '@tc-core/model/it/codegen/ui/framework/nav-item';
import { TC } from '@tc-core/util/constants';
import { ConfigLoader } from '@tc-core/util/framework/config-loader.service';
import { FocusHandlerService } from '@tc-core/util/framework/focus-handler.service';
import { UserJourneyManager } from '@tc-core/util/framework/user-journey-manager.service';
import { Subscription } from 'rxjs';
import { TCO } from '../../../constants';
import { MessageThread } from '../../../models/notification/message-thread';
import { OPResponseWrapper } from '../../../models/response/op-response-wrapper';
import { UserServiceProfile } from '../../../models/user/user-service-profile';
import { NotificationService } from '../../../services/backend-consumers/notifications/notification.service';
import { UserProfileService } from '../../../services/backend-consumers/user-service/user-profile.service';
import { DataKey, DataStoreService } from '../../../services/util/framework/data-store.service';
import { ResponseUtil } from '../../../services/util/response/response-util.service';

declare const $: any;

@Component({
    selector: 'tc-dmc-side-panel',
    templateUrl: 'side-panel.component.html'
})
export class SidePanelComponent implements OnDestroy, OnChanges {

    @Input() client: ClientSummary;
    @Input() navItems: NavItem[] = [];
    @Input() cart: Cart;
    @Input() cartItemCount: number;
    @Input() navItemIndex = -1;
    @Input() navSubItemIndex = -1;
    @Input() pendingResourceAcceptanceDataCount = 0;
    @Input() showSideNav = false;
    @Input() environmentName: string = '';
    @Input() releaseTitle: string = '';
    @Output() clientSelect: EventEmitter<ClientSummary> = new EventEmitter();
    @Output() cartSelect: EventEmitter<ClientSummary> = new EventEmitter();
    @Output() select: EventEmitter<NavItem> = new EventEmitter();
    @Output() toggle: EventEmitter<boolean> = new EventEmitter();

    public ClientType = Client.TYPE;
    private subItemEnabled: boolean = false;
    selectedTab: string;
    selectedSubTab: string;
    FOCUS_ID: string = 'side-panel';
    logoAction = null;
    version = 'DMC 2.0.114';

    @ViewChildren('navitem') navItemRefs: QueryList<ElementRef>;
    @ViewChildren('navSubItem') navSubItemRefs: QueryList<ElementRef>;

    messageThreadList: MessageThread[] = [];
    totalUnreadMessageCount = 0;

    private pendingResourceAcceptanceDataSubscription = new Subscription();
    private searchResultSubscription: Subscription = new Subscription();
    private newMessageSubscription: Subscription = new Subscription();
    private unreadMessageCountSubscription: Subscription = new Subscription();

    constructor(
        private focusHandler: FocusHandlerService,
        private dataStoreService: DataStoreService,
        private userProfileService: UserProfileService,
        private notificationService: NotificationService,
        private userJourneyManager: UserJourneyManager,
        private configLoader: ConfigLoader,
    ) {
        this.getPendingResourceAcceptanceData();
        this.getMessageThreadList();
        this.subscribeForNewMessage();
        this.subscribeForUnreadMessageCountUpdates();
        this.logoAction = this.configLoader.configurations.get(TC.CONF.CONF_LAUNCH_PAD).logoAction;
        this.version = this.configLoader.configurations.get(TCO.CONF.CONF_VERSION).version;
    }

    getPendingResourceAcceptanceData() {
        this.userProfileService.getPendingResourceAcceptanceData();
        this.subscribePendingResourcesToAccept();
    }

    ngOnChanges() {
        this.focusOnSideNav();
    }

    public onClientSelect(client) {
        this.clientSelect.emit(client);
    }

    public onCartSelect(cart) {
        this.cartSelect.emit(cart);
    }

    public onNavigationItemSelect(event, nav, i?) {
        if (!nav.restricted) {
            if (i != 'undefined' && i != null) {
                this.selectedTab = i;
                this.selectedSubTab = null;
            }
            this.navItemIndex = -1;
            this.select.emit(nav);
        }
    }

    public onNavigationSubItemSelect(event, nav, j?) {
        if (!nav.restricted) {
            if (j != 'undefined' && j != null) {
                this.selectedSubTab = j;
                this.selectedTab = null;
            }
            this.navSubItemIndex = -1;
            this.select.emit(nav);
        }
        event.stopPropagation();
        event.stopImmediatePropagation();
        event.preventDefault();
    }

    public toggleSubMenu(event: MouseEvent, navItem: NavItem) {
        navItem['expand'] = !navItem['expand'];
        event.stopPropagation();
        event.stopImmediatePropagation();
        event.preventDefault();
    }

    @HostListener('document:keyup', ['$event'])
    handleLocalKeyboardEvent(event: KeyboardEvent) {
        if (this.showSideNav && this.focusHandler.handleFocus(this.FOCUS_ID)) {
            event.stopPropagation();
            event.stopImmediatePropagation();

            const key = event.keyCode;
            switch (key) {
                case 38: //up Arrow
                    this.navigateUpOnSideNav();
                    break;
                case 40: //down Arrow
                    this.navigateDownOnSideNav();
                    break;
                case 37: //Collapes sub level left Arrow
                    this.collapesOnSubItemPanel();
                    break;
                case 39: //Enable sub level right Arrow
                    this.expandOnSubItemPanel();
                    break;
                case 13: // ENTER
                    // this.trigerActionOnSideNav();
                    break;
            }
            event.preventDefault();
        }
    }

    onLogoClick() {
        if (this.logoAction && this.logoAction.actionRef) {
            if (this.logoAction.refreshPageIf && this.logoAction.refreshPageIf === this.userJourneyManager.getCurrentPage()) {
                window.open('/', '_self');
            } else {
                this.userJourneyManager.goForKey(this.logoAction.actionRef);
            }
        } else {
            window.open('/', '_self');
        }
    }

    // private trigerActionOnSideNav() {
    //   if (this.navItems[this.navItemIndex].subItems && this.navItems[this.navItemIndex].subItems.length > 0) {
    //     // if there's a sub menu expand it
    //     if (!this.subItemEnabled) {
    //       this.toggleSidePanel();
    //     } else {
    //       if (this.navSubItemIndex > -1) {
    //         this.onNavigationSubItemSelect(this.navItems[this.navItemIndex].subItems[this.navSubItemIndex]);
    //       }
    //     }
    //   } else {
    //     if (this.navItemIndex > -1) {
    //       this.onNavigationItemSelect(this.navItems[this.navItemIndex]);
    //     }
    //   }
    // }

    private expandOnSubItemPanel() {
        if (this.navItems[this.navItemIndex].subItems && this.navItems[this.navItemIndex].subItems.length > 0) {
            if (!this.subItemEnabled) {
                this.toggleSidePanel();
                this.navSubItemIndex = 0;
            }
        }
    }

    private collapesOnSubItemPanel() {
        if (this.subItemEnabled) {
            this.toggleSidePanel();
            this.navSubItemIndex = -1;
        }
    }

    private toggleSidePanel() {
        this.navItemRefs.forEach((el, index) => {
            if (this.navItemIndex === index) {
                // el['collapse']("toggle");
                const refs = el.nativeElement.getElementsByClassName('collapse');
                if (refs && refs.length > 0) {
                    $('#' + refs[0].id).collapse('toggle');
                    this.subItemEnabled = !this.subItemEnabled;
                }
                if (this.subItemEnabled) {
                    el.nativeElement.firstElementChild.classList.remove('sid-menu--select');
                }
            }
        });
    }

    private focusOnSideNav() {
        this.navItemIndex = this.showSideNav ? 0 : -1;
        this.showSideNav ? this.focusHandler.setFocus(this.FOCUS_ID) : this.focusHandler.removeFocus(this.FOCUS_ID);
        const sideNavMini = document.body;
        if (!this.showSideNav) {
            sideNavMini.classList.remove('sidebar-mini--open');
            sideNavMini.classList.remove('nav-open');
        } else {
            sideNavMini.classList.add('sidebar-mini--open');
            sideNavMini.classList.add('nav-open');
            if (this.subItemEnabled) {
                this.navItemRefs.forEach((el, index) => {
                    if (this.navItemIndex === index) {
                        // el['collapse']("toggle");
                        el.nativeElement.firstElementChild.classList.remove('sid-menu--select');
                    }
                });
            }
        }
    }

    private navigateUpOnSideNav() {
        if (this.showSideNav && this.navItems.length !== 0) {
            if (!this.subItemEnabled) {
                if (this.navItemIndex <= 0) {
                    this.navItemIndex = this.navItems.length - 1;
                } else {
                    this.navItemIndex = this.navItemIndex - 1;
                }
            } else {
                this.navSubItemIndex = (this.navSubItemIndex - 1) % this.navItems[this.navItemIndex].subItems.length;
            }

        }

    }

    private navigateDownOnSideNav() {
        if (this.showSideNav && this.navItems.length !== 0) {
            if (!this.subItemEnabled) {
                this.navItemIndex = (this.navItemIndex + 1) % this.navItems.length;
            } else {
                this.navSubItemIndex = (this.navSubItemIndex + 1) % this.navItems[this.navItemIndex].subItems.length;
            }

        }
    }

    isCountAvailable(id: String) {
        switch (id) {
            case 'RESOURCE_ACCEPTANCE_QUEUE' :
                return true;
            case 'MESSAGE_QUEUE' :
                return this.totalUnreadMessageCount > 0;
            default:
                return false;
        }
    }

    getCount(id: String) {
        switch (id) {
            case 'RESOURCE_ACCEPTANCE_QUEUE' :
                return this.pendingResourceAcceptanceDataCount;
            case 'MESSAGE_QUEUE' :
                return this.totalUnreadMessageCount;
            default:
                return false;
        }
    }

    subscribePendingResourcesToAccept() {
        this.pendingResourceAcceptanceDataSubscription = this.dataStoreService.get(DataKey.pendingResourceAcceptanceData)
                                                             .subscribe((result: OPResponseWrapper<UserServiceProfile>) => {
                                                                 if (result) {
                                                                     this.pendingResourceAcceptanceDataCount = result.pageData ?
                                                                         result.pageData.total :
                                                                         0;
                                                                 }
                                                             });
    }

    getMessageThreadList() {
        this.subscribeSearchResults();
    }

    subscribeForNewMessage() {
        this.newMessageSubscription = this.dataStoreService.get(DataKey.newMessages).subscribe((result) => {
            if (result) {
                result.forEach((message) => {
                    if (message.message === true) {
                        this.totalUnreadMessageCount++;
                    }
                });
            }
        });
    }

    subscribeForUnreadMessageCountUpdates() {
        this.unreadMessageCountSubscription = this.notificationService.totalUnreadMessageCount.subscribe((count) => {
            this.totalUnreadMessageCount = this.totalUnreadMessageCount - count;
        });
    }

    subscribeSearchResults() {
        this.searchResultSubscription = this.dataStoreService.get(DataKey.messageSearchResultsFromService)
                                            .subscribe((data: OPResponseWrapper<MessageThread>) => {
                                                if (data) {
                                                    this.messageThreadList = ResponseUtil.getDataArray<MessageThread>(
                                                        data);
                                                    if (this.messageThreadList.length > 0) {
                                                        this.messageThreadList.forEach(thread => {
                                                                this.calculateUnread(thread);
                                                            }
                                                        );
                                                    }
                                                } else {
                                                    this.messageThreadList = [];
                                                    this.totalUnreadMessageCount = 0;
                                                }
                                            });
    }

    /**
     *
     * @param thread
     */
    calculateUnread(thread: MessageThread) {
        let count = 0;
        if (thread.mostRecentMessages) {
            thread.mostRecentMessages.forEach(message => {
                    if (!message.readStatus && !message.sendingDmc) {
                        count++;
                    }
                }
            );
        }
        if (count !== 0) {
            this.totalUnreadMessageCount = count;
        }
    }

    public ngOnDestroy(): void {
        if (this.pendingResourceAcceptanceDataSubscription) {
            this.pendingResourceAcceptanceDataSubscription.unsubscribe();
        }
        if (this.searchResultSubscription) {
            this.searchResultSubscription.unsubscribe();
        }
        if (this.newMessageSubscription) {
            this.newMessageSubscription.unsubscribe();
        }
        if (this.unreadMessageCountSubscription) {
            this.unreadMessageCountSubscription.unsubscribe();
        }
    }

}
