import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ProfileManagementService } from '../../../services/backend-consumers/supplier-service/profile-management.service';
import { DataStoreService } from '../../../services/util/framework/data-store.service';
import { ManageSupplierAttributesModule } from '../../../widgets/shared/manage-supplier-attributes/manage-supplier-attributes.module';
import { ManageSupplierAttributesWrapperComponent } from './manage-supplier-attributes-wrapper.component';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
    imports: [
        CommonModule,
        ManageSupplierAttributesModule,
        TranslateModule.forChild()
    ],
    providers:[
        ProfileManagementService,
        DataStoreService
    ],
    exports: [ManageSupplierAttributesWrapperComponent],
    declarations: [ManageSupplierAttributesWrapperComponent],
    entryComponents:[ManageSupplierAttributesWrapperComponent]
})
export class ManageSupplierAttributesWrapperModule {}
