import {NotificationData} from './notification-data';
import {Observable} from "rxjs";
import {BroadcastReceiver} from "./broadcastReceiver";

export class MessageThread {
    userId: number;
    resourceId: number;
    email: string;
    supplierId: number;
    dmc: boolean;
    resourceName: string;
    supplierName: string;
    mostRecentMessages: NotificationData[];
    mostRecentMessagesFE: NotificationData[];
    latestTime: string;
    mostRecentShortDescription: string;
    newNotificationReceived: boolean;
    displayTimeStamp: any;
    chatHeadDisplayNameShort: string;
    hasNewNotification: boolean;
    mostRecentNotificationCount: number;
    isSelected: boolean;
    icon: Observable<string>;
    receivingResourcesIds: Array<BroadcastReceiver>;
    groupMsgThreadId: number;
    groupThreadName: string;
    resourceTypeName: string;

    constructor() {
        this.mostRecentMessages = [];
        this.mostRecentShortDescription = '';
        this.newNotificationReceived = false;
        this.displayTimeStamp = '';
        this.chatHeadDisplayNameShort = '';
        this.mostRecentNotificationCount = 0;
        this.hasNewNotification = false;
        this.isSelected = false;
        this.icon = null;
    }
}
