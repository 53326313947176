/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./grid-tool-tip.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./grid-tool-tip.component";
var styles_GridToolTipComponent = [i0.styles];
var RenderType_GridToolTipComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GridToolTipComponent, data: {} });
export { RenderType_GridToolTipComponent as RenderType_GridToolTipComponent };
export function View_GridToolTipComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "custom-tooltip"]], [[4, "width", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "span", [["class", "tooltiptext"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.width; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.getTooltip(); _ck(_v, 2, 0, currVal_1); }); }
export function View_GridToolTipComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "tc-tooltip", [], null, null, null, View_GridToolTipComponent_0, RenderType_GridToolTipComponent)), i1.ɵdid(1, 49152, null, 0, i2.GridToolTipComponent, [], null, null)], null, null); }
var GridToolTipComponentNgFactory = i1.ɵccf("tc-tooltip", i2.GridToolTipComponent, View_GridToolTipComponent_Host_0, {}, {}, []);
export { GridToolTipComponentNgFactory as GridToolTipComponentNgFactory };
