import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    MatOptionModule,
    MatPaginatorModule,
    MatSelectModule,
    MatTableModule
} from '@angular/material';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { DataGridComponent } from './data-grid.component';

@NgModule({
    imports: [
        CommonModule,
        MatInputModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        FormsModule,
        MatSelectModule,
        MatOptionModule,
        MatTableModule,
        MatPaginatorModule,
        MatCheckboxModule
    ],
    declarations: [DataGridComponent],
    exports: [DataGridComponent]
})
export class DataGridModule {
}
