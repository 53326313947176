import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DispatchDataModalComponent } from './dispatch-data-modal.component';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
    declarations: [DispatchDataModalComponent],
    imports: [
        CommonModule,
        MatInputModule,
        MatAutocompleteModule,
        MatDatepickerModule,
        MatIconModule,
        MatInputModule,
        MatSelectModule,
        MatTooltipModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule
    ],
    exports: [
        DispatchDataModalComponent
    ],
    entryComponents: [
        DispatchDataModalComponent
    ]
})
export class DispatchDataModalModule {}
