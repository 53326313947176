import { NgxMatDatetimePickerModule, NgxMatNativeDateModule } from '@angular-material-components/datetime-picker';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule, MatDatepickerModule, MatExpansionModule, MatFormFieldModule, MatIconModule } from '@angular/material';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { ResourceModule } from '@tc/resource';
import { AgGridModule } from 'ag-grid-angular';
import { ServiceItemNotesModule } from '../../../../widgets/shared/service-item-notes/service-item-notes.module';
import { BookingItemNotesModule } from '../booking-item-notes/booking-item-notes.module';
import { AccomItemComponent } from './accom-item/accom-item.component';
import { BookingItemsComponent } from './booking-items.component';
import { GenItemComponent } from './gen-item/gen-item.component';
import { ItemNotesModule } from './item-notes/item-notes.module';
import { ItemSummaryComponent } from './item-summary/item-summary.component';
import { TrsItemComponent } from './trs-item/trs-item.component';
import {MatButtonModule} from "@angular/material/button";
import {MatTooltipModule} from "@angular/material/tooltip";
import {SummaryStripModule} from "@tc/summary-strip";
import {ExpandedContentCardBodyModule} from "@tc/expanded-content-card-body";

@NgModule({
    declarations: [BookingItemsComponent, ItemSummaryComponent, TrsItemComponent, GenItemComponent, AccomItemComponent],
    imports: [
        CommonModule,
        TranslateModule.forChild(),
        MatExpansionModule,
        ResourceModule,
        ReactiveFormsModule,
        MatInputModule,
        MatFormFieldModule,
        NgxMatDatetimePickerModule,
        MatDatepickerModule,
        NgxMatNativeDateModule,
        AgGridModule,
        BookingItemNotesModule,
        MatAutocompleteModule,
        MatIconModule,
        MatButtonModule,
        MatTooltipModule,
        SummaryStripModule,
        ExpandedContentCardBodyModule,
        MatCheckboxModule,
        FormsModule,
        ServiceItemNotesModule,
        ItemNotesModule
    ],
    exports: [
        BookingItemsComponent
    ]
})
export class BookingItemsModule {}
