<!--<div class="tc-item-wrapper">-->
    <!--<div class="tc-item-header tc-flex-box">-->
        <!--<div class="tc-flex-item tc-item-header__primary">Payment Information</div>-->
        <!--<div class="tc-flex-item tc-item-header__actions tc-icon-block">-->
        <!--</div>-->
    <!--</div>-->
<!--</div>-->
<form [formGroup]="paymentInfoFG">
    <div class="container-fluid direct-client-personal-info-edit-card tc-card-wrapper">
        <div class="row">
            <div class="col-md-12 col-lg-12">
                <div class="tc-flex-box">
                    <div class="tc-flex-item tc-item-circle">
                        <div class="icon-box">
                            <i class="material-icons">attach_money</i>
                        </div>
                    </div>
                    <div class="tc-flex-item tc-right-cage">
                        <div class="row">
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                <div class="tc-input-edit dcpiec-input-contractingDivision">
                                    <mat-form-field>
                                        <mat-select #contractingCompany
                                                    id="PAY_METHOD"
                                                    formControlName="paymentMethod"
                                                    [placeholder]="'Payment Method'"
                                                    required>
                                            <mat-option *ngFor="let option of payMethods" [value]="option.code">
                                                {{option.name}}
                                            </mat-option>
                                        </mat-select>
                                        <mat-error>{{getErrorMessage('paymentMethod')}}</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                <div class="tc-select-edit dcpiec-supplier-name">
                                    <mat-form-field>
                                        <mat-select #paymentCurrency
                                                    id="PAY_CURRENCY"
                                                    formControlName="paymentCurrency"
                                                    [placeholder]="'Payment Currency'"
                                                    required>
                                            <mat-option *ngFor="let currency of currencyList" [value]="currency.code" class="tc-mat-select-option__group">
                                                <span class="tc-mat-select-option__code">{{currency.code}}</span>&nbsp;
                                                <span class="tc-mat-select-option__name">{{currency.name}}</span>
                                            </mat-option>
                                        </mat-select>
                                        <mat-error>{{getErrorMessage('paymentCurrency')}}</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                <div class="tc-input-edit dcpiec-contractingCompany">
                                    <mat-form-field>
                                        <input matInput
                                               id="BEN_NAME"
                                               #beneficiaryNameInput
                                               formControlName="beneficiaryName"
                                               [placeholder]="'Beneficiary Name'"
                                        >
                                        <mat-error>{{getErrorMessageOfControl(paymentInfoFG, 'Beneficiary Name')}}
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                <div class="tc-input-edit dcpiec-input-bank">
                                    <mat-form-field>
                                        <input matInput
                                               id="PAY_BANK"
                                               #bankInput
                                               formControlName="bankName"
                                               [placeholder]="'Bank Name'">
                                        <mat-error>{{getErrorMessageOfControl(paymentInfoFG, 'Bank Name')}}
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                <div class="tc-select-edit dcpiec-supplier-name">
                                    <mat-form-field>
                                        <input matInput
                                               id="ACC_NUM"
                                               #accNumInput
                                               formControlName="accountNumber"
                                               [placeholder]="'Account Number'">

                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                <div class="tc-input-edit dcpiec-contractingCompany">
                                    <mat-form-field>
                                        <input matInput
                                               id="IBAN"
                                               #IBANInput
                                               formControlName="iban"
                                               [placeholder]="'IBAN'">

                                    </mat-form-field>
                                </div>
                            </div>

                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                <div class="tc-input-edit dcpiec-input-bank">
                                    <mat-form-field>
                                        <input matInput
                                               id="SORT_CODE"
                                               #sortCodeInput
                                               formControlName="sortCode"
                                               [placeholder]="'Sort Code'">

                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                <div class="tc-input-edit dcpiec-input-bank">
                                    <mat-form-field>
                                        <input matInput
                                               id="SWIFT_CODE"
                                               #swiftCodeInput
                                               formControlName="swiftCode"
                                               [placeholder]="'Swift Code'">

                                    </mat-form-field>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    </div>
</form>
