import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TcApiError } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-api-error';
import { TcHttpError } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-http-error';
import { Journey } from '@tc-core/model/it/codegen/ui/framework/journey';
import { LEVEL, ModalData } from '@tc-core/model/it/codegen/ui/framework/modal-data';
import { TC } from '@tc-core/util';
import { ConfigLoader, EventManager, UserJourneyManager } from '@tc-core/util/framework';
import { CommonHelper } from '@tc-core/util/helpers';
import { DateFormatter } from '@tc-core/util/system';
import { ChipInputComponent } from '@tc/chip-input';
import { ChipInputService } from '@tc/chip-input/chip-input.service';
import { ModalService } from '@tc/modal/modal.service';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { TCO } from '../../../constants';
import { Criteria } from '../../../models/criteria/criteria';
import { ReservationSearchCriteria } from '../../../models/criteria/reservation-search-criteria';
import { SortDirection } from '../../../models/helper/sort-direction';
import { ShowSavedCriteriaModalComponent } from '../../reservation-management/show-saved-criteria-modal/show-saved-criteria-modal.component';
import { GenReservationManagementService } from '../../../services/backend-consumers/reservation-management-services/gen-reservation-management.service';
import { ChipHandlerService } from '../../../services/helpers/chip-handler.service';
import { RootService } from '../../../services/util/core-services/root.service';
import { ActionHandlerService } from '../../../services/util/framework/action-handler-service.service';
import { DataKey, DataStoreService } from '../../../services/util/framework/data-store.service';
import { DMCQueryParamsService } from '../../../services/util/framework/dmc-query-params.service';

@Component({
    selector: 'tc-gen-reservation-search-criteria',
    templateUrl: './gen-reservation-search-criteria.component.html',
    providers: [
        ChipHandlerService,
        {provide: 'ChipHandlerService', useExisting: ChipHandlerService},
        ChipInputService
    ]
})
export class GenReservationSearchCriteriaComponent implements OnInit {

    tPagination: any;

    @Input() isInsideForm: boolean = false;
    @Input() genReservationSearchCriteria: ReservationSearchCriteria;
    @Input() searchCriteriaChipConfig: string;
    @Input() isSearchBarStyleNotAvailable: boolean = false;
    @Output() searchTrigger: EventEmitter<boolean> = new EventEmitter();
    @Output() searchCriteria: EventEmitter<any> = new EventEmitter();

    availableChips: any[] = [];
    isDataLoaded: boolean = true;
    isChipsInit = false;
    isChipListDisplay = false;
    displayChipsArr = [];
    isFocus: boolean = false;
    modifiedChip;
    placeHolderText = 'Search Generic Services';

    persistentQParams: string[] = [];
    persistentQParamMap = new Map<string, string>();
    persistentChipsArr = [];
    isTriggerSearch: boolean = true;
    journey: string = null;

    systemDefinedCriteriaList: Criteria[] = [];
    userDefinedCriteriaList: Criteria[] = [];

    private routerChangeSubscription: Subscription;
    private selectedChipsObsv: Subscription = new Subscription();
    private selectedFieldsObsv: Subscription = new Subscription();
    private chipValueUpdateSub: Subscription = new Subscription();
    private relatedChipsObsv: Subscription = new Subscription();
    private relatedChipsProcessObsv: Subscription = new Subscription();
    private criteriaUpdateSubscription: Subscription = new Subscription();
    private deletedChipObsv: Subscription = new Subscription();
    private selectedChips = [];

    private chipInputService;
    @ViewChild('gen_reservation_search_chip_input') genericQueueSearchChipInput: ChipInputComponent;
    private chipHandlerSubject: Subscription = new Subscription();

    SERVICE_TYPE = 'GEN';
    PRODUCT_CODE = '';

    constructor(
        private configLoader: ConfigLoader,
        private dataStoreService: DataStoreService,
        private em: EventManager,
        private queryParamService: DMCQueryParamsService,
        private rootService: RootService,
        private chipHandler: ChipHandlerService,
        private commonHelper: CommonHelper,
        private userJourneyManager: UserJourneyManager,
        private actionHandlerService: ActionHandlerService,
        private modalService: ModalService,
        private dateFormatter: DateFormatter,
        private genReservationManagementService: GenReservationManagementService
    ) { }

    ngOnInit() {
        this.chipInputService = this.genericQueueSearchChipInput.criteriaInputService;

        this.tPagination = this.configLoader.configurations.get(TCO.CONF.CONF_PAGINATION_CONFIG)['GEN_RESERVATION_SEARCH'];

        // reset the search results
        this.dataStoreService.set(DataKey.genReservationSearchCriteria, null, true);
        this.dataStoreService.set(DataKey.genReservationSearchResultsFromService, null, true);

        this.handleQuickActions();
        this.userJourneyManager.journey.subscribe((journey: Journey) => {
            if (journey && JSON.stringify(journey) !== JSON.stringify({})) {
                this.journey = journey.id;
            }
        });
        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'SHOW_SAVED_CRITERIA').subscribe(e => {
            this.onShowSavedCriteriaClick();
        });

        this.getUserDefinedCriteriaList();
        this.getSystemDefinedCriteriaList();

        if (!this.isInsideForm) {
            this.getQueryParams();
            if (!this.isChipsInit) {
                this.initSearchCriteriaChips(); // load everything from start
            }
        }

        this.getGenReservationSearchObserver();
        this.errorObserver();
        // this.selectedChipsUpdate();
        this.shortCutObserver();
        this.handleInterRelatedChips();
        // this.notifyChipDelete();
        // this.shortCutObserver();
    }

    getQueryParams() {
        let queryParams: string[] = [];
        queryParams = this.queryParamService.getQueryParamsArray(new ReservationSearchCriteria());

        this.genReservationSearchCriteria = this.rootService.loadDataFromLocalStorage(queryParams, new ReservationSearchCriteria(),
            TCO.AppData.GEN_RESERVATION_SEARCH_CRITERIA
        );

        this.genReservationSearchCriteria.serviceType = this.SERVICE_TYPE;
        if(!this.genReservationSearchCriteria.productCode){
            this.genReservationSearchCriteria.productCode = this.PRODUCT_CODE;
        }
        if(this.genReservationSearchCriteria && !this.genReservationSearchCriteria.startDate){
            this.genReservationSearchCriteria.startDate =  new Date().toISOString().split('T')[0];
        }
        if(this.genReservationSearchCriteria && !this.genReservationSearchCriteria.endDate){
            this.genReservationSearchCriteria.endDate =  new Date(+new Date(this.genReservationSearchCriteria.startDate) + 172800000).toISOString().split('T')[0];
        }
        if (this.genReservationSearchCriteria) {
            this.persistentQParams = [];
            this.persistentQParams = this.queryParamService.getNotNullQueryParamsArray(this.genReservationSearchCriteria);
        }
    }

    private shortCutObserver() {
        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'FOCUS_SEARCH_BAR').subscribe(e => {
            this.isFocus = true;
        });
        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'CLOSE_POPUPS').subscribe(e => {
            this.isFocus = false;
        });
    }

    onFocus(event) {
        this.isFocus = event;
    }

    initSearchCriteriaChips() {
        this.isChipsInit = true;
        this.chipInputService.fullReset(); // fully reset chips
        this.chipHandler.chipsArr = [];
        // read chip config
        let criteriaChipConfigs = null;
        if (!this.isInsideForm) {
            const chipConfigs = this.configLoader.configurations.get(TCO.CONF.CONF_GEN_RESERVATION_SEARCH_CRITERIA_CHIP);
            criteriaChipConfigs = this.commonHelper.recreateJsonObject(chipConfigs);
        } else {
            const chipConfigs = this.configLoader.configurations.get(this.searchCriteriaChipConfig);
            criteriaChipConfigs = this.commonHelper.recreateJsonObject(chipConfigs);
        }
        if (criteriaChipConfigs.chips) {
            criteriaChipConfigs.chips.forEach(chip => {
                chip = this.chipHandler.preProcessChips(chip, criteriaChipConfigs.permissionKeys.keys);
            });
        }
        let isDataLoaded = false;
        // isDataLoaded = true; //todo remove
        this.chipHandler.chipsSubject.subscribe(data => {
            if (data && JSON.stringify(data) !== JSON.stringify([]) && !isDataLoaded) {
                isDataLoaded = true;
                this.availableChips = data;
                this.chipHandler.chipsSubject.next(null);

                if (this.chipHandlerSubject) {
                    this.chipHandlerSubject.unsubscribe();
                }

                // set values to display chips
                this.displayChipsArr = [];
                this.displayChipsArr = this.availableChips;

                this.dataStoreService.set(DataKey.chips, this.availableChips, true);

                this.genReservationSearchCriteria.serviceType = this.SERVICE_TYPE;

                if (this.genReservationSearchCriteria && !this.genReservationSearchCriteria.startDate) {
                    this.genReservationSearchCriteria.startDate =  new Date().toISOString().split('T')[0];
                }
                if (this.genReservationSearchCriteria && !this.genReservationSearchCriteria.endDate) {
                    this.genReservationSearchCriteria.endDate =  new Date(+new Date(this.genReservationSearchCriteria.startDate) + 172800000)
                        .toISOString().split('T')[0];
                }
                this.availableChips.forEach(chip => {
                    if (chip['criteriaId'] === 'RESOURCE_TYPE') {
                        chip['optionParams']['isInitialPresent'] = true;
                        if (chip['optionParams']['defValue'] && this.genReservationSearchCriteria.productCode === this.PRODUCT_CODE)
                        {
                            this.genReservationSearchCriteria.productCode = chip['optionParams']['defValue'];
                            let choice = chip['optionParams']['options']
                                .filter(option => option.code === chip['optionParams']['defValue']);
                            chip['optionParams']['data'] = choice;
                        } else if (this.genReservationSearchCriteria.productCode === this.PRODUCT_CODE){
                            this.genReservationSearchCriteria.productCode = undefined;
                        }
                    }
                });

                this.chipInputService.init(this.genReservationSearchCriteria, this.availableChips, false, true);
                this.isChipsInit = true;

                // implement chip persistance
                if (this.persistentQParams && this.persistentQParams.length > 0) {
                    // create a queryParamMap
                    for (let i = 0; i < this.persistentQParams.length; i++) {
                        let qParam = this.persistentQParams[i];
                        let qParamValue = this.genReservationSearchCriteria[qParam];
                        if (qParam && qParamValue) {
                            this.persistentQParamMap.set(qParam, qParamValue);
                        }
                    }
                    this.persistentChipsArr = [];

                    // fill persistent chips
                    this.availableChips.forEach(chip => {
                        let persistChip = Object.assign({}, chip);
                        persistChip.optionParams.data = [];
                        let isPersist = false;
                        if (chip.dataParams && chip.dataParams.length > 0) {
                            chip.dataParams.forEach(dataParam => {
                                if (this.persistentQParamMap.has(dataParam.paramValue)) {
                                    isPersist = true;
                                    const dataObj = {
                                        key: dataParam.paramValue,
                                        value: this.persistentQParamMap.get(dataParam.paramValue)
                                    };
                                    persistChip.optionParams.data.push(dataObj);
                                }
                            });
                        }
                        // push persist chips to an array
                        if (isPersist) {
                            this.persistentChipsArr.push(persistChip);
                        }
                    });
                    // persist chips
                    if (this.persistentChipsArr && this.persistentChipsArr.length > 0) {
                        // const uniqueChipList = this.persistentChipsArr.filter((e, i) => {
                        //     return this.persistentChipsArr.findIndex((x) => {
                        //         return x.criteriaId === e.criteriaId;
                        //     }) === i;
                        // });
                        this.persistentChipsArr.forEach(value => {
                            if(value.criteriaType === 'CALENDAR' && typeof value.optionParams.data[0].value !== 'string'){
                                value.optionParams.data[0].value = moment(value.optionParams.data[0].value).format('YYYY-MM-DD');
                            }
                        })

                        this.chipInputService.persistChips(this.persistentChipsArr);
                        // trigger search when chips persisting
                        this.isDataLoaded = false;
                        // this.isTriggerSearch = false;
                        this.onGenReservationSearch(this.genReservationSearchCriteria);

                    }
                }

                this.chipInputService.criteriaUpdate.subscribe((_data) => {
                    this.genReservationSearchCriteria = _data;
                });
            }
        });
    }

    onGenReservationSearch(event) {
        this.isDataLoaded = false;
        this.isDataLoaded = true; //todo remove
        this.genReservationSearchCriteria = event;
        this.userJourneyManager.canProceed.next(false);

        if (this.tPagination && this.tPagination['initialFetchingPages'] && this.tPagination['defaultPageResultCount']) {
            this.genReservationSearchCriteria.size = this.tPagination['initialFetchingPages'] * this.tPagination['defaultPageResultCount'];
            this.genReservationSearchCriteria.start = 0;
        }

        this.userJourneyManager.disableQuickActions([
            'TRIGGER_EXPORT_FILE'
        ]);

        this.genReservationSearchCriteria = event;
        if (!this.genReservationSearchCriteria.groupBy) {
            this.genReservationSearchCriteria.sortDirection = SortDirection.ASC;
        }

        if (JSON.stringify(this.genReservationSearchCriteria) !== JSON.stringify({})) {
            this.dataStoreService.set(DataKey.genReservationSearchCriteria, this.genReservationSearchCriteria, true);

            this.dataStoreService.set(DataKey.genReservationSearchResultsFromService, null, true);

            // set criteria parameters to url
            let queryParams: string[] = [];
            queryParams = this.queryParamService.getQueryParamsArray(this.genReservationSearchCriteria);
            queryParams = queryParams.filter(val => val !== 'page');
            this.rootService.setDataToLocalStorage(TCO.AppData.GEN_RESERVATION_SEARCH_CRITERIA, this.genReservationSearchCriteria, true,
                queryParams, true
            );

            // remove grid sorting and filtering
            this.genReservationSearchCriteria.gridFiltering = undefined;
            this.genReservationSearchCriteria.gridSorting = "[{'colId':'serviceDate','sort':'asc'},{'colId':'tourItemName','sort':'asc'},{'colId':'assignmentId','sort':'asc'}]";

            // search data //todo remove comment
            this.genReservationManagementService.searchReservations(this.genReservationSearchCriteria);

            // this.spinnerService.show();

            //this.isDataLoaded = false;  //todo remove comment
            this.isTriggerSearch = false;
            this.searchTrigger.emit(true);

        } else {
            setTimeout(() => {
                this.isDataLoaded = true;
                this.isTriggerSearch = true;
            }, 0);
        }
    }

    getUpdatedFieldsDataObject(chip, criteria: ReservationSearchCriteria) {
        const dataObjArr = [];

        if (chip && chip.dataParams && chip.dataParams.length > 0) {
            chip.dataParams.forEach(dataParam => {
                const dataObj = {
                    key: '',
                    value: ''
                };
                dataObj.key = dataParam.paramValue;
                dataObj.value = criteria[dataParam.paramValue];

                dataObjArr.push(dataObj);
            });
        }

        return dataObjArr;
    }

    private getGenReservationSearchObserver() {
        const subscription = this.dataStoreService.get(DataKey.genReservationSearchResultsFromService)
                                 .subscribe((data) => {
                                     if (this.commonHelper.dataValidity(data)) {
                                         setTimeout(() => {
                                             this.isDataLoaded = true;
                                             this.isTriggerSearch = true;
                                         }, 0);
                                     } else if (this.commonHelper.isEmptyData(data)) {
                                         this.isDataLoaded = true;
                                     } else if (data instanceof TcApiError) {
                                         this.isDataLoaded = true;
                                     } else if (data instanceof TcHttpError) {
                                         this.isDataLoaded = true;
                                     }
                                 });
    }

    private errorObserver() {
        this.dataStoreService.get(DataKey.error).subscribe((data) => {
            if (data && JSON.stringify(data) !== JSON.stringify({})) {
                setTimeout(() => {
                    this.isDataLoaded = true;
                }, 0);
                this.dataStoreService.set(DataKey.error, null);
            }
        });
    }

    private resetQueryParams() {
        let reservationQueryParams: string[] = [];
        reservationQueryParams = this.queryParamService.getQueryParamsArray(new ReservationSearchCriteria());

        const reservationSearchCriteria1: ReservationSearchCriteria = this.rootService.loadDataFromLocalStorage(reservationQueryParams,
            new ReservationSearchCriteria(), TCO.AppData.GEN_RESERVATION_SEARCH_CRITERIA
        );

        // set criteria parameters to url
        let queryParams: string[] = [];
        queryParams = this.queryParamService.getQueryParamsArray(reservationSearchCriteria1);

        this.rootService.setDataToLocalStorage(TCO.AppData.GEN_RESERVATION_SEARCH_CRITERIA, this.genReservationSearchCriteria,
            true, queryParams, true
        );
    }

    onCancel(event) {
        console.log('cancelled');
    }

    onChipListDisplay(event) {
        this.dataStoreService.set(DataKey.chipListDisplay, null, true);
        this.isChipListDisplay = event;
        this.dataStoreService.set(DataKey.chipListDisplay, this.isChipListDisplay, true);
    }

    handleQuickActions() {
        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'TRIGGER_SEARCH_CRITERIA').subscribe((e) => {
            this.triggerSearch(e.data);
        });
    }

    triggerSearch(data: string) {
        this.genReservationSearchCriteria = new ReservationSearchCriteria();
        this.genReservationSearchCriteria.serviceType = this.SERVICE_TYPE;
        this.genReservationSearchCriteria.productCode = '';
        this.genReservationSearchCriteria.startDate = '';
        this.genReservationSearchCriteria.endDate = '';
        this.actionHandlerService.fillCriteriaByActionData(data, this.genReservationSearchCriteria);
        let requiredChips = this.chipHandler.getChipsFromCriteria(
            this.genReservationSearchCriteria,
            TCO.CONF.CONF_GEN_RESERVATION_SEARCH_CRITERIA_CHIP,
            true
        );
        // requiredChips = this.chipHandler.validateAndFilterChips(requiredChips, ['bookingRefId|>|0']);
        this.chipInputService.hardReset();
        this.chipInputService.persistChips(requiredChips, true);
        // this.chipInputService.criteria = this.reservationSearchCriteria;
        this.onGenReservationSearch(this.genReservationSearchCriteria);
    }

    private handleInterRelatedChips() {
        this.relatedChipsObsv = this.chipInputService.relatedChipsSubject.subscribe(
            (data) => {
                let toggleChips = [];
                if (data && data.chip && JSON.stringify(data) !== JSON.stringify({})) {
                    this.modifiedChip = data.chip;
                    // TODO: refine chip input service: inter related chips
                    if (data.chip.type === 'CALENDAR') {
                        // startDate & returnDate validate
                        if (data.chip.id === 'START_DATE') {
                            const startDate = data.date;
                            let nights = 2;
                            const currentEndDate = this.checkDependentForCalculateRelatedDate(data.chip);
                            if (currentEndDate) {
                                this.setMinDateForRelatedDateChips(data.chip, data.date);

                                if (this.dateFormatter.isAfter(startDate, currentEndDate)) {
                                    this.chipHandler.handleInterRelatedChips(data.chip, this.dateFormatter.addDaysToDate(startDate, nights));
                                } else {
                                    this.chipHandler.handleInterRelatedChips(data.chip, currentEndDate);
                                }

                            }

                        }
                    } else {
                        this.chipHandler.handleInterRelatedChips(data.chip, data.options.value[0]);
                    }
                    if (data.chip.optionParams.toggleChips && data.chip.optionParams.toggleChips.length > 0) {
                        toggleChips = data.chip.optionParams.toggleChips;
                        if (toggleChips && data.options && data.options.value[0].value) {
                            this.chipInputService.selectedChipsBasedOnStateChange(toggleChips);
                        } else {
                            this.chipInputService.deleteChipsBasedOnStateChange(toggleChips);
                        }
                    }
                }
            }
        );
        this.relatedChipsProcessObsv = this.chipHandler.relatedChipsProcessSubject.subscribe((result) => {
            if (result && JSON.stringify(result) !== JSON.stringify([]) && this.modifiedChip.optionParams.relatedChips &&
                this.modifiedChip.optionParams.relatedChips.length > 0 &&
                result.length === this.modifiedChip.optionParams.relatedChips.length) {

                // if chip date is passed date it will be reset for today
                result.forEach(chip => {
                        if (chip.dataParam === 'DATE' && chip.criteriaId === 'START_DATE') {
                            chip.options = this.dateFormatter.dateFromString(chip.options) > this.dateFormatter.getToday() ?
                                chip.options :
                                this.dateFormatter.dateAsString(this.dateFormatter.getToday());
                        }if (chip.dataParam === 'DATE' && chip.criteriaId === 'END_DATE') {
                            chip.options = this.dateFormatter.dateFromString(chip.options) > this.dateFormatter.getToday() ?
                                chip.options :
                                this.dateFormatter.dateAsString(this.dateFormatter.getToday());
                        }
                    }
                );

                // pass related chips to chip input service
                this.chipInputService.handleInterRelatedChips(result);
            }
        });
    }
    private setMinDateForRelatedDateChips(chip: any, date: string) {
        if (date && chip && chip.optionParams && chip.optionParams.relatedChips) {
            const minDate = this.dateFormatter.dateFromString(date);
            chip.optionParams.relatedChips.forEach(relateChip => {
                if (relateChip.criteriaType === 'CALENDAR') {
                    relateChip.data = [{
                        key: 'minDate',
                        value: minDate
                    }];
                }
            });
        }
    }
    /**
     * Check dependent if available when calculate related date or related duration
     * @param chip Chip
     */
    private checkDependentForCalculateRelatedDate(chip: any) {
        let dateParam;
        if (chip.optionParams && chip.optionParams.relatedChips) {
            const dependent = chip.optionParams.behaviouralData.find(
                behData => behData.key === 'DATE_RELATED_DEPENDENT');
            if (dependent) {
                dateParam = this.getValueOfDependentParameter(dependent.value);
            }
        }
        return dateParam;
    }

    /**
     * Read additional params for handle inter related chips
     * @param dependent Dependent
     */
    private getValueOfDependentParameter(dependent: any): any {
        if (this.chipInputService && this.chipInputService.criteria && this.chipInputService.criteria[dependent.paramValue]) {
            return this.chipInputService.criteria[dependent.paramValue];
        }
        return '';
    }


    ngOnDestroy() {
        // this.routerChangeSubscription.unsubscribe();
        this.userJourneyManager.routeChanges.next(false);

        if (this.selectedChipsObsv) {
            this.selectedChipsObsv.unsubscribe();
        }

        if (this.selectedFieldsObsv) {
            this.selectedFieldsObsv.unsubscribe();
        }

        if (this.chipValueUpdateSub) {
            this.chipValueUpdateSub.unsubscribe();
        }
        if (this.criteriaUpdateSubscription) {
            this.criteriaUpdateSubscription.unsubscribe();
        }
        if (this.deletedChipObsv) {
            this.deletedChipObsv.unsubscribe();
        }

        this.em.removeEvents([
            'TRIGGER_SEARCH_CRITERIA'
        ]);
    }

    private selectedChipsUpdate() {
        this.criteriaUpdateSubscription = this.chipInputService.selectedChipsSubject.subscribe(selectedChips => {
            this.selectedChips = selectedChips;

            if (selectedChips && JSON.stringify(selectedChips) !== JSON.stringify([])) {
                // toggle chips when selecting BRANCH_OF_CLIENT_ID chip
                for (const selectedChip of this.selectedChips) {
                    if (selectedChip.chip.id === 'BRANCH_OF_CLIENT_ID' && selectedChip.chip.optionParams.toggleChips &&
                        selectedChip.chip.optionParams.toggleChips.length > 0) {
                        this.deleteToggleChips(selectedChip.chip.optionParams.toggleChips);
                        this.disableChips(selectedChip.chip.optionParams.toggleChips);
                        break;
                    }
                }
            }
        });
    }

    private notifyChipDelete() {
        this.deletedChipObsv = this.chipInputService.deletedChipsSubject.subscribe(deletedChip => {
            if (JSON.stringify(deletedChip) !== JSON.stringify({}) && deletedChip !== null && deletedChip !== undefined) {
                if (deletedChip.id === 'CLIENT_TYPE') {
                    const deleteChips = this.selectedChips.map(chip => chip.chip);
                    this.deleteSelectedChips(deleteChips);
                    this.enableChips(this.availableChips);
                } else if (deletedChip.id === 'BRANCH_OF_CLIENT_ID') {
                    if (deletedChip.optionParams.toggleChips && deletedChip.optionParams.toggleChips.length > 0) {
                        this.enableChips(deletedChip.optionParams.toggleChips);
                    }
                }
            }
        });
    }

    /**
     * Delete toggle chips if already selected
     *
     * @param toggleChips
     */
    private deleteToggleChips(toggleChips: any[]) {
        const deleteChips = [];
        toggleChips.forEach(toggleChip => {
            for (const selectedChip of this.selectedChips) {
                if (selectedChip.chip.id === toggleChip.criteriaId) {
                    deleteChips.push(selectedChip.chip);
                    break;
                }
            }
        });
        this.deleteSelectedChips(deleteChips);
    }

    private deleteSelectedChips(deleteChips: any[]) {
        deleteChips.forEach(chip => {
            this.chipInputService.deleteChip(chip, true);
        });
    }

    private enableChips(enableChips: any[]) {
        enableChips.forEach(enableChip => {
            this.chipInputService.modifyChipsStatus(enableChip, false);
        });
    }

    private disableChips(disableChips: any[]) {
        disableChips.forEach(disableChip => {
            this.chipInputService.modifyChipsStatus(disableChip, true);
        });
    }

    searchFromSavedCriteriaObserver() {
        this.isChipsInit = false;
        const x = this.dataStoreService.get(DataKey.searchFromSavedCriteria).subscribe((data) => {
            if (this.commonHelper.dataValidity(data) && !this.isChipsInit) {
                const criteriaStartDate = this.genReservationSearchCriteria.startDate;
                const criteriaEndDate = this.genReservationSearchCriteria.endDate;
                this.genReservationSearchCriteria = data;
                this.genReservationSearchCriteria.startDate = criteriaStartDate;
                this.genReservationSearchCriteria.endDate = criteriaEndDate;
                this.genReservationSearchCriteria.serviceType = this.SERVICE_TYPE;
                if(this.genReservationSearchCriteria && this.genReservationSearchCriteria.startDate && this.genReservationSearchCriteria.daysFrom){
                    this.genReservationSearchCriteria.startDate =  new Date(+new Date() + this.genReservationSearchCriteria.daysFrom * 86400000).toISOString().split('T')[0];
                    this.genReservationSearchCriteria.endDate =  new Date(+new Date(this.genReservationSearchCriteria.startDate) + 172800000).toISOString().split('T')[0];
                } else if(this.genReservationSearchCriteria && !this.genReservationSearchCriteria.startDate){
                    this.genReservationSearchCriteria.startDate =  new Date().toISOString().split('T')[0];
                    this.genReservationSearchCriteria.endDate =  new Date(+new Date() + 172800000).toISOString().split('T')[0];
                }
                let queryParams: string[] = [];
                queryParams.forEach(param => {
                    this.queryParamService.deleteQueryParam(param);
                });
                queryParams = this.queryParamService.getQueryParamsArray(this.genReservationSearchCriteria);
                queryParams = queryParams.filter(val => val !== 'page');
                this.rootService.setDataToLocalStorage(TCO.AppData.GEN_RESERVATION_SEARCH_CRITERIA, this.genReservationSearchCriteria, true,
                    queryParams, true
                );
                /*let qparams: Map<string, any> = new Map();
                 let criteriaObj = this.reservationSearchCriteria;
                 for (let key in criteriaObj) {
                 if(criteriaObj[key] && criteriaObj[key] !== 'page' ){
                 qparams.set(key, criteriaObj[key]);
                 }
                 }*/
                this.displayChipsArr = [];
                this.persistentChipsArr = [];
                this.persistentQParamMap = new Map<string, string>();
                if (this.genReservationSearchCriteria) {
                    this.persistentQParams = [];
                    this.persistentQParams = this.queryParamService.getNotNullQueryParamsArray(this.genReservationSearchCriteria);
                }
                if (!this.isChipsInit) {
                    this.initSearchCriteriaChips(); // load everything from start
                }
            }
        });
    }

    onShowSavedCriteriaClick() {
        this.dataStoreService.set(DataKey.searchFromSavedCriteria, null);
        this.dataStoreService.set(DataKey.popupClose, null);
        let dataObject = new ModalData(
            LEVEL.SUCCESS,
            'Show Criteria',
            null,
            null,
            'modal-basic--alt modal-basic--fixed-size',
            ShowSavedCriteriaModalComponent,
            {
                'userDefinedCriteriaList': this.userDefinedCriteriaList,
                'systemDefinedCriteriaList': this.systemDefinedCriteriaList
            }
        );

        let secondaryHeader = '';

        dataObject.secondaryHeader = secondaryHeader;
        this.confirmModal(dataObject);
        this.searchFromSavedCriteriaObserver();
        this.dataStoreService.get(DataKey.popupClose).subscribe((data) => {
            if (data != null) {
                this.closeModal();
            }
        });
    }

    closeModal() {
        if (this.modalService.close()) {
            this.modalService.close().subscribe(
                (res) => {
                }
            );
        }
    }

    private confirmModal(data: any): void {
        this.modalService.confirm(data).subscribe((res) => {
        });
    }

    getUserDefinedCriteriaList() {
        this.dataStoreService.get(DataKey.userSavedSearchCriteriaList).subscribe(data => {
            if (data) {
                this.userDefinedCriteriaList = data;
            }
        });
    }

    getSystemDefinedCriteriaList() {
        this.dataStoreService.get(DataKey.systemDefinedCriteria).subscribe(data => {
            if (data) {
                this.systemDefinedCriteriaList = data;
            }
        });
    }

}
