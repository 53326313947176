import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CurrencyFormat } from '@tc-core/model/it/codegen/ui/framework/currency-format';
import { Pager } from '@tc-core/model/it/codegen/ui/framework/pager';
import { Sorter } from '@tc-core/model/it/codegen/ui/framework/sorter';
import { SummaryCardData } from '@tc-core/model/it/codegen/ui/framework/summary-card-data';
import { AssignmentSummary } from '../../../../../models/assignment-summary';
import { BookingItem } from '../../../../../models/reservation-v2/booking-item';
import { ExistingAssignment } from '../../../../../models/reservation-v2/existing-assignment';
import { PaxCount } from '../../../../../models/reservation-v2/pax-count';
import { ResourceAllocationSummary } from '../../../../../models/reservation-v2/resource-allocation-summary';
import { ServiceItem } from '../../../../../models/reservation-v2/service-item';
import { ResourceAvailability } from '../../../../../models/resource/resource-availability';
import { ChangeDetectorService } from '../../../../../services/util/change-detector/change-detector.service';

@Component({
  selector: 'tc-adv-resource-allocation-search-results',
  templateUrl: './adv-resource-allocation-search-results.component.html'
})
export class AdvResourceAllocationSearchResultsComponent implements OnInit {
  @Input() sorter: Sorter;
  @Input() sortingHandler: any;
  @Input() paxCount: PaxCount;
  @Input() sorters: Sorter[] = [];
  @Input() itemList: (ServiceItem | BookingItem)[] = [];

  @Input() resultsList: ResourceAllocationSummary[] = [];
  @Input() selectedResource: ResourceAllocationSummary = null; // selected supplier card from search results

  @Input() assignedResource: any; //assigned resource from direct assignment or early assignments
  @Input() currencyFormat: CurrencyFormat;
  @Input() pager: Pager;

  @Output() resourceSelect: EventEmitter<any> = new EventEmitter();
  @Output() cardScroll: EventEmitter<number> = new EventEmitter<number>();
  @Output() actionClick: EventEmitter<any> = new EventEmitter();
  @Output() outsideSelect: EventEmitter<any> = new EventEmitter();
  @Output() headerClick: EventEmitter<any> = new EventEmitter();
  @Output() resourceCalendarClick: EventEmitter<any> = new EventEmitter();
  @Output() eventClick: EventEmitter<ResourceAllocationSummary> = new EventEmitter();
  @Output() targetClick: EventEmitter<any> = new EventEmitter();

  selectedCard: ResourceAllocationSummary;
  footerDataMap: any;
  public expandedPanelIndex: number;

  constructor(
      private changeDetector: ChangeDetectorService
  ) {
  }

  ngOnInit() {
    console.log(this.resultsList);
  }

  public onResourceSelection(summaryCardData: ResourceAllocationSummary, index?: number) {
    this.selectedResource = summaryCardData;

    if (summaryCardData) {
      this.resourceSelect.emit(this.selectedResource);
      this.cardScroll.emit(index);
    }
  }

  onActionClick(summaryCardData, event) {
    this.selectedResource = summaryCardData;

    if (summaryCardData) {
      this.actionClick.emit(event);
      this.resourceSelect.emit(this.selectedResource);
    }
  }

  public onExpand(summaryCardData: ResourceAllocationSummary) {
    this.resourceSelect.emit(summaryCardData);
  }

  public onHeaderClick(summaryCardData: ResourceAllocationSummary) {
    this.headerClick.emit(summaryCardData);
    this.selectedCard = summaryCardData;
    this.resourceSelect.emit(summaryCardData);
    this.changeDetector.notifySupplierCostChange();

  }

  public getHeader(resource: SummaryCardData) {
    return resource.data.value['supplierName'];
  }

  public getSecondaryHeaderText(selectedSupplier: SummaryCardData) {
    return selectedSupplier.data.value['city'] + ' | ' + selectedSupplier.data.value['country'];
  }

  public  onOutsideClick(summaryCardData: ResourceAllocationSummary) {
    this.changeDetector.notifySupplierCostChange();
    this.outsideSelect.emit(summaryCardData);
    this.selectedCard = summaryCardData;
    this.resourceSelect.emit(summaryCardData);
  }

  public hasSelectedThis(supplier: SummaryCardData): boolean {
    if (this.assignedResource && this.assignedResource.data) {
      return (this.assignedResource.data.value['supplierId'] === supplier.data.value['supplierId']);
    } else if (this.assignedResource) {
      return (this.assignedResource.supplierId === supplier.data.value['supplierId']);
    } else {
      return false;
    }
  }

  onResourceCalendarClick(resource: any) {
    this.resourceCalendarClick.emit(resource);
  }
  onEventClick(event: ExistingAssignment, resource: ResourceAllocationSummary){
    event.tobeSelected = true;
    this.eventClick.emit(resource);
  }

  onTargetClick(target: any){
    this.targetClick.emit(target);
  }
}
