/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../widgets/shared/transport-cost-setup-edit-card/transport-cost-setup-edit-card.component.ngfactory";
import * as i2 from "@angular/common";
import * as i3 from "../../../../widgets/shared/transport-cost-setup-edit-card/transport-cost-setup-edit-card.component";
import * as i4 from "@angular/forms";
import * as i5 from "@tc-core/util/framework/config-loader.service";
import * as i6 from "../../../../widgets/shared/transport-cost-setup-summary-card/transport-cost-setup-summary-card.component.ngfactory";
import * as i7 from "../../../../widgets/shared/transport-cost-setup-summary-card/transport-cost-setup-summary-card.component";
import * as i8 from "../../../../widgets/shared/result-header/result-header.component.ngfactory";
import * as i9 from "../../../../widgets/shared/result-header/result-header.component";
import * as i10 from "./transport-cost-setup-focus-view.component";
import * as i11 from "@tc-core/util/framework/event-manager.service";
import * as i12 from "@tc-core/util/framework/user-journey-manager.service";
import * as i13 from "../../../../services/util/framework/data-store.service";
import * as i14 from "@tc/focus-view/focus-view.service";
import * as i15 from "../../../../services/util/ui/content-summary-card.service";
import * as i16 from "../../../../services/backend-consumers/master-data-handler-service/data-handler.service";
import * as i17 from "@tc-core/util/ui/spinner.service";
import * as i18 from "@tc/dialog/dialog.service";
import * as i19 from "../../../../services/util/ui/card-data-handler-service.service";
import * as i20 from "@tc/modal/modal.service";
var styles_TransportCostSetupFocusViewComponent = [];
var RenderType_TransportCostSetupFocusViewComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TransportCostSetupFocusViewComponent, data: {} });
export { RenderType_TransportCostSetupFocusViewComponent as RenderType_TransportCostSetupFocusViewComponent };
function View_TransportCostSetupFocusViewComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "tc-transport-cost-setup-edit-card", [], null, [[null, "saveClick"], [null, "deleteClick"], [null, "checkUniqueValues"], [null, "revertClick"], [null, "changeFormValidity"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("saveClick" === en)) {
        var pd_0 = (_co.onNewCostSaveClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("deleteClick" === en)) {
        var pd_1 = (_co.onDeleteClick($event) !== false);
        ad = (pd_1 && ad);
    } if (("checkUniqueValues" === en)) {
        var pd_2 = (_co.validateUniqueValues($event) !== false);
        ad = (pd_2 && ad);
    } if (("revertClick" === en)) {
        var pd_3 = (_co.onRevertClick($event) !== false);
        ad = (pd_3 && ad);
    } if (("changeFormValidity" === en)) {
        var pd_4 = (_co.onChangeCostSetupForm($event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, i1.View_TransportCostSetupEditCardComponent_0, i1.RenderType_TransportCostSetupEditCardComponent)), i0.ɵdid(2, 278528, null, 0, i2.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { ngClass: [0, "ngClass"] }, null), i0.ɵdid(3, 638976, null, 0, i3.TransportCostSetupEditCardComponent, [i4.FormBuilder, i5.ConfigLoader], { isNew: [0, "isNew"], seasonSetup: [1, "seasonSetup"], daysOfWeek: [2, "daysOfWeek"], transferModes: [3, "transferModes"], vehicleModels: [4, "vehicleModels"], routes: [5, "routes"], selectedResourceCost: [6, "selectedResourceCost"], resourceCosts: [7, "resourceCosts"] }, { revertClick: "revertClick", saveClick: "saveClick", deleteClick: "deleteClick", changeFormValidity: "changeFormValidity", checkUniqueValues: "checkUniqueValues" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "isEditable-", _co.addNewEditableStatus, ""); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.addNew; var currVal_2 = _co.supplierCostSetup; var currVal_3 = _co.daysOfWeek; var currVal_4 = _co.transferModeList; var currVal_5 = _co.vehicleModels; var currVal_6 = _co.routeList; var currVal_7 = _v.parent.parent.context.$implicit; var currVal_8 = _co.resourceCosts; _ck(_v, 3, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }, null); }
function View_TransportCostSetupFocusViewComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "tc-transport-cost-setup-summary-card", [], null, [[null, "editClick"], [null, "deleteClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("editClick" === en)) {
        var pd_0 = (_co.onEditClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("deleteClick" === en)) {
        var pd_1 = (_co.onDeleteClick($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i6.View_TransportCostSetupSummaryCardComponent_0, i6.RenderType_TransportCostSetupSummaryCardComponent)), i0.ɵdid(2, 114688, [["bill_route_card", 4]], 0, i7.TransportCostSetupSummaryCardComponent, [i5.ConfigLoader], { isEditable: [0, "isEditable"], seasonSetup: [1, "seasonSetup"], selectedResourceCostContent: [2, "selectedResourceCostContent"], daysOfWeek: [3, "daysOfWeek"], selectedResourceCost: [4, "selectedResourceCost"] }, { editClick: "editClick", deleteClick: "deleteClick" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.addNewEditableStatus; var currVal_1 = _co.supplierCostSetup; var currVal_2 = _co.resourceCostCardContent[_v.parent.parent.context.index]; var currVal_3 = _co.daysOfWeek; var currVal_4 = _v.parent.parent.context.$implicit; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
function View_TransportCostSetupFocusViewComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TransportCostSetupFocusViewComponent_4)), i0.ɵdid(2, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TransportCostSetupFocusViewComponent_5)), i0.ɵdid(4, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.isEdit; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_v.parent.context.$implicit.isEdit; _ck(_v, 4, 0, currVal_1); }, null); }
function View_TransportCostSetupFocusViewComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TransportCostSetupFocusViewComponent_3)), i0.ɵdid(2, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (((_co.filterTransferMode ? (_v.context.$implicit.transferMode === _co.filterTransferMode) : true) && (_co.filterRoute ? (_co.filterRoute === _v.context.$implicit.route) : true)) && ((_co.filterSeason > 0) ? (_co.filterSeason === _v.context.$implicit.tempSeasonId) : true)); _ck(_v, 2, 0, currVal_0); }, null); }
function View_TransportCostSetupFocusViewComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TransportCostSetupFocusViewComponent_2)), i0.ɵdid(2, 278528, null, 0, i2.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.resourceCosts; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_TransportCostSetupFocusViewComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [["class", "tc-sc__container-main"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 6, "div", [["class", "tc-sc__container-right"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "tc-result-header", [], null, [[null, "actionClick"], [null, "selectedTransferMode"], [null, "selectedRoute"], [null, "selectedSeason"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("actionClick" === en)) {
        var pd_0 = (_co.showSeasonSummary($event) !== false);
        ad = (pd_0 && ad);
    } if (("selectedTransferMode" === en)) {
        var pd_1 = (_co.onSelectTransferMode($event) !== false);
        ad = (pd_1 && ad);
    } if (("selectedRoute" === en)) {
        var pd_2 = (_co.onSelectRoute($event) !== false);
        ad = (pd_2 && ad);
    } if (("selectedSeason" === en)) {
        var pd_3 = (_co.onSelectSeason($event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, i8.View_ResultHeaderComponent_0, i8.RenderType_ResultHeaderComponent)), i0.ɵdid(4, 114688, null, 0, i9.ResultHeaderComponent, [], { transferModeList: [0, "transferModeList"], heading: [1, "heading"], routeList: [2, "routeList"], seasonList: [3, "seasonList"], pageSection: [4, "pageSection"], showPageSection: [5, "showPageSection"] }, { selectedTransferMode: "selectedTransferMode", selectedRoute: "selectedRoute", selectedSeason: "selectedSeason", actionClick: "actionClick" }), (_l()(), i0.ɵeld(5, 0, null, null, 2, "div", [["class", "expand-group-card"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TransportCostSetupFocusViewComponent_1)), i0.ɵdid(7, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.transferModes; var currVal_1 = _co.heading; var currVal_2 = _co.routes; var currVal_3 = _co.seasons; var currVal_4 = _co.costAddSection; var currVal_5 = true; _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_6 = (_co.resourceCosts.length > 0); _ck(_v, 7, 0, currVal_6); }, null); }
export function View_TransportCostSetupFocusViewComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-transport-cost-setup-focus-view", [], null, null, null, View_TransportCostSetupFocusViewComponent_0, RenderType_TransportCostSetupFocusViewComponent)), i0.ɵdid(1, 114688, null, 0, i10.TransportCostSetupFocusViewComponent, [i11.EventManager, i5.ConfigLoader, i12.UserJourneyManager, i13.DataStoreService, i14.FocusViewService, i15.ContentSummaryCardService, i16.DataHandlerService, i17.SpinnerService, i18.DialogService, i19.CardDataHandlerService, i20.ModalService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TransportCostSetupFocusViewComponentNgFactory = i0.ɵccf("tc-transport-cost-setup-focus-view", i10.TransportCostSetupFocusViewComponent, View_TransportCostSetupFocusViewComponent_Host_0, { supplierCostSetup: "supplierCostSetup", resourceCosts: "resourceCosts", vehicleModels: "vehicleModels" }, {}, []);
export { TransportCostSetupFocusViewComponentNgFactory as TransportCostSetupFocusViewComponentNgFactory };
