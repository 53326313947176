import { AbstractSearchCriteria } from './abstract-search-criteria';
import {KeyControlAndAbstractSearchCriteria} from './key-control-and-abstract-search-criteria';

export class ResourceSearchCriteria extends KeyControlAndAbstractSearchCriteria{
    resourceId = -1;
    capacity: number;
    minPax: number;
    maxPax: number;
    resourceType: string = '';
    activeStatus: string = 'true';
    external: boolean; //  External(1) or Internal(0)
    supplierId: number;
    supplierCode: string;
    currency: string;
    paymentMethod: string;
    serviceDate: string;
    supplierIdList: number[];

    businessType = '';
}
