export class AllocationFeature {
    kcParent: string;
    kcCompany: string;
    serviceType: string;
    serviceTypeName: string;
    cost: number;
    alphabeticalOrder: number;
    preference: number;
    grade: number;
    serviceTypeError = '';
    isServiceTypeValid = false;
}
