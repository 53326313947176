import { DatePipe } from '@angular/common';
import { TC } from '@tc-core/util';
import * as _ from 'lodash';
import { TCO } from '../../../constants';
import * as i0 from "@angular/core";
var SetupCommonDataProcessorService = /** @class */ (function () {
    function SetupCommonDataProcessorService() {
        var _this = this;
        this.EMPTY_STRING = TC.FORMAT.EMPTY_CHAR;
        this.pipe = new DatePipe('en-US');
        this.normalCellValueRenderer = function (params) {
            if (params.value) {
                return params.value;
            }
            else {
                return _this.EMPTY_STRING;
            }
        };
        this.decimal2Renderer = function (params) {
            if (params.value) {
                return params.value.toFixed(2);
            }
            else {
                return _this.EMPTY_STRING;
            }
        };
        this.decimal3Renderer = function (params) {
            if (params.value) {
                return params.value.toFixed(3);
            }
            else {
                return _this.EMPTY_STRING;
            }
        };
        this.dateValueRenderer = function (params) {
            if (params.value) {
                try {
                    var date = new Date(Date.parse(params.value));
                    return _this.pipe.transform(date, 'y-MMM-d', TCO.AppData.GMT_TIME_ZONE);
                }
                catch (e) {
                    return _this.EMPTY_STRING;
                }
            }
            else {
                return _this.EMPTY_STRING;
            }
        };
        this.dateValueConvertor = function (params) {
            if (params) {
                try {
                    var date = new Date(Date.parse(params));
                    return _this.pipe.transform(date, 'y-MMM-d', TCO.AppData.GMT_TIME_ZONE);
                }
                catch (e) {
                    return _this.EMPTY_STRING;
                }
            }
            else {
                return _this.EMPTY_STRING;
            }
        };
        this.gmtDateTimeValueRenderer = function (params) {
            if (params.value) {
                try {
                    var date = new Date(Date.parse(params.value));
                    return _this.pipe.transform(date, 'y-MMM-d h:mm a', TCO.AppData.GMT_TIME_ZONE);
                }
                catch (e) {
                    return _this.EMPTY_STRING;
                }
            }
            else {
                return _this.EMPTY_STRING;
            }
        };
        this.dateTimeValueRenderer = function (params) {
            if (params.value) {
                try {
                    var date = new Date(Date.parse(params.value));
                    return _this.pipe.transform(date, 'y-MMM-d h:mm a');
                }
                catch (e) {
                    return _this.EMPTY_STRING;
                }
            }
            else {
                return _this.EMPTY_STRING;
            }
        };
        this.dateTimeValueRendererAsISO = function (params) {
            if (params.value) {
                try {
                    var gmtDateTime = params.value;
                    if (gmtDateTime.slice(gmtDateTime.length - 1).toLowerCase() !== 'z') {
                        gmtDateTime += 'Z';
                    }
                    var localDateTime = new Date(gmtDateTime);
                    return _this.pipe.transform(localDateTime, 'y-MMM-d h:mm a');
                }
                catch (e) {
                    return _this.EMPTY_STRING;
                }
            }
            else {
                return _this.EMPTY_STRING;
            }
        };
        this.getFromConsumer = function (handlerObj, consumer) {
            if (consumer && handlerObj.handlerParam) {
                var item = _.get(consumer, handlerObj.handlerParam);
                if (item && handlerObj.type && handlerObj.type === 'function') {
                    return item.bind(consumer);
                }
                else {
                    if (!item) {
                        if (consumer.constructor && consumer.constructor.name) {
                            console.error(handlerObj.handlerParam + ' not found in ' + consumer.constructor.name);
                        }
                        else {
                            console.error(handlerObj.handlerParam + ' not found in ' + consumer);
                        }
                    }
                    return item;
                }
            }
        };
        this.mapValueToName = function (handlerObj, consumer) {
            if (consumer && handlerObj['handlerParam']) {
                return function (params) {
                    if (params && params.value) {
                        var name_1 = this['nameOptions'][params.value];
                        if (name_1) {
                            return name_1;
                        }
                        else if (this['nameOptions']['_default_']) {
                            return this['nameOptions']['_default_'];
                        }
                        else {
                            return TC.FORMAT.EMPTY_CHAR;
                        }
                    }
                    else if (this['nameOptions']['_default_']) {
                        return this['nameOptions']['_default_'];
                    }
                    else {
                        return TC.FORMAT.EMPTY_CHAR;
                    }
                }.bind({ nameOptions: handlerObj['handlerParam'] });
            }
        };
        this.displayObjName = function (option) {
            if (option && option.name) {
                return option.name;
            }
            else {
                return '';
            }
        };
        // resourceAcceptance queue
        this.displayResourceObjName = function (option) {
            if (option && option.firstName) {
                return option.firstName + ' ' + option.lastName;
            }
            else if (option && option.vehicleModel) {
                return option.vehicleName + ' | ' + option.vehicleModel;
            }
            else {
                return '';
            }
        };
        this.displayObjCodeName = function (option) {
            var codeName = '';
            if (option) {
                if (option.code) {
                    codeName = option.code;
                }
                codeName += ' | ';
                if (option.name) {
                    codeName += option.name;
                }
                return codeName;
            }
            else {
                return '';
            }
        };
        this.displaySupplierName = function (option) {
            if (option && option.name) {
                return option.name;
            }
            else if (option && option.resourceSummary && option.resourceSummary.name) {
                return option.resourceSummary.name;
            }
            else if (option && option.code) {
                return option.code;
            }
            else {
                return '';
            }
        };
        this.displayObjCode = function (option) {
            var code = '';
            if (option) {
                if (option.code) {
                    code = option.code;
                }
                return code;
            }
            else {
                return '';
            }
        };
        this.numericSetter = function (params) {
            if (params.newValue > 0 || params.newValue === '') {
                _.set(params.data, params.colDef.field, params.newValue); // calling lodash deep property value set
                return true;
            }
            return false;
        };
        this.doubleValueSetter = function (params) {
            if (params.newValue > 0 || params.newValue === '') {
                if (typeof params.newValue === 'string' || params.newValue instanceof String) {
                    params.newValue = parseFloat(params.newValue);
                }
                _.set(params.data, params.colDef.field, params.newValue); // calling lodash deep property value set
                return true;
            }
            return false;
        };
        this.currencyCellRenderer = function (params) {
            params.data.currency = params.data.currency ? params.data.currency : 'AED';
            if (params.data && params.data.currency && params.value) {
                var inrFormat = new Intl.NumberFormat('en-Us', {
                    style: 'currency',
                    currency: params.data.currency.code ?
                        params.data.currency.code :
                        params.data.currency,
                    minimumFractionDigits: 2
                });
                // return inrFormat.format(params.value).replace(/[a-z]{3}/i, '').trim();
                return inrFormat.format(params.value);
            }
            else {
                return this.EMPTY_STRING;
            }
        };
        this.currencyCellRendererWithoutCurrencyCode = function (params) {
            params.data.currency = params.data.currency ? params.data.currency : 'AED';
            if (params.data && params.data.currency && params.value) {
                var inrFormat = new Intl.NumberFormat('en-Us', {
                    style: 'currency',
                    currency: params.data.currency.code ?
                        params.data.currency.code :
                        params.data.currency,
                    minimumFractionDigits: 2
                });
                return inrFormat.format(params.value).replace(/[a-z]{3}/i, '').trim();
                // return inrFormat.format(params.value);
            }
            else {
                return this.EMPTY_STRING;
            }
        };
    }
    SetupCommonDataProcessorService.ngInjectableDef = i0.defineInjectable({ factory: function SetupCommonDataProcessorService_Factory() { return new SetupCommonDataProcessorService(); }, token: SetupCommonDataProcessorService, providedIn: "root" });
    return SetupCommonDataProcessorService;
}());
export { SetupCommonDataProcessorService };
