import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material';
import { TranslateModule } from '@ngx-translate/core';
import { ExpandedContentCardBodyModule } from '@tc/expanded-content-card-body';
import { FlightInfoExpandedViewModule } from '../flight-info-expanded-view/flight-info-expanded-view.module';
import { PickupDropoffExpandedViewModule } from '../pickup-dropoff-expanded-view/pickup-dropoff-expanded-view.module';
import { ReservationServiceGroupNotesModule } from '../reservation-service-group-notes/reservation-service-group-notes.module';
import { ReservationDetailViewComponent } from './reservation-detail-view.component';
import { ReservationExpandedSummaryViewModule } from '../reservation-expanded-summary-view/reservation-expanded-summary-view.module';
import { ReservationServiceItemsModule } from '../reservation-service-items/reservation-service-items.module';
import { MatExpansionModule } from '@angular/material/expansion';

@NgModule({
    imports: [
        CommonModule,
        MatExpansionModule,
        ExpandedContentCardBodyModule,
        ReservationExpandedSummaryViewModule,
        ReservationServiceItemsModule,
        PickupDropoffExpandedViewModule,
        FlightInfoExpandedViewModule,
        ReservationServiceGroupNotesModule,
        MatTooltipModule,
        TranslateModule.forChild()
    ],
    declarations: [ReservationDetailViewComponent],
    exports: [ReservationDetailViewComponent]
})
export class ReservationDetailViewModule {}
