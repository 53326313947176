import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpResponse, HttpErrorResponse }
  from '@angular/common/http';

import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/do';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    return next.handle(req).do(evt => {
      if (evt instanceof HttpErrorResponse) {
        var contentType =   evt.headers.get('content-type');
        var status = evt.status;
        var url = evt.url;

        if(contentType.indexOf('text/html') > -1 && status == 200 && url.indexOf('Login') > -1)
        {
            console.log('Session Expired');
            window.location.href = '/Logout';
        }
        
      }
    });

  }
}