import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SummaryCardData } from '@tc-core/model/it/codegen/ui/framework/summary-card-data';
import { TC } from '@tc-core/util/constants';
import { ConfigLoader } from '@tc-core/util/framework/config-loader.service';
import { Chip } from '@tc/chip-selector/chip.model';
import { DialogService } from '@tc/dialog/dialog.service';
import { SorterPipe } from '@tc/pipes/sorter.pipe';
import { TCO } from '../../../../../constants';
import { ManageNotesComponent } from '../../../../../widgets/framework/manage-notes';
import { BookingNote, BookingNoteType } from '../../../../../models/reservation-v2/booking-note';
import { NoteItemView } from '../../../../../models/reservation/note-item-view';
import { CardDataHandlerService } from '../../../../../services/util/ui/card-data-handler-service.service';
/*
common component for notes module in bookings
 */
var ItemNotesComponent = /** @class */ (function () {
    function ItemNotesComponent(dialog, dialogService, configLoader, sortPipe, cardDataHandlerService) {
        this.dialog = dialog;
        this.dialogService = dialogService;
        this.configLoader = configLoader;
        this.sortPipe = sortPipe;
        this.cardDataHandlerService = cardDataHandlerService;
        this.authConfigComponent = "booking-notes-summary";
        this.noteContents = [];
        this.masterNoteTypes = [];
        this.noteList = [];
        this.readonly = false;
        this.addNoteClick = new EventEmitter();
        this.editClick = new EventEmitter();
        this.deleteClick = new EventEmitter();
        this.selectedNote = new EventEmitter();
        this.changeValidity = new EventEmitter();
        this.editToolTip = 'Edit';
        this.deleteToolTip = 'Delete';
        this.addNoteToolTip = 'Add Note';
        this.toolTipPosition = 'below';
        this.addNoteTitle = 'Add New Note';
        this.CARD_CONFIG_NOTES = 'notes';
        this.isConfirmed = false;
        this.editNoteTitle = 'Edit Note';
        this.historyText = '';
        this.showSummary = false;
        this.hideToggle = true;
        this.EMPTY_CHAR = TC.FORMAT.EMPTY_CHAR;
    }
    ItemNotesComponent.prototype.ngOnInit = function () {
        this.onNoteListUpdate();
    };
    // create note summary view - should call when noteList update
    ItemNotesComponent.prototype.onNoteListUpdate = function () {
        var e_1, _a;
        this.masterNoteTypes = this.configLoader.configurations.get(TCO.CONF.CONF_CONTENT_LIST_CARD).master_note_types;
        this.noteSummary = this.cardDataHandlerService.processCardData(this.CARD_CONFIG_NOTES, this.noteList, 'note-summary');
        if (this.noteList && this.noteList.length > 0) {
            this.noteContents = [];
            try {
                for (var _b = tslib_1.__values(this.noteList), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var note = _c.value;
                    this.convertCardNoteType(note);
                    var noteContent = new NoteItemView();
                    noteContent.header = this.cardDataHandlerService.processCardData(this.CARD_CONFIG_NOTES, note, 'note-detail.header');
                    noteContent.summary = this.cardDataHandlerService.processCardData(this.CARD_CONFIG_NOTES, note, 'note-detail.summary');
                    noteContent.detail = this.cardDataHandlerService.processCardData(this.CARD_CONFIG_NOTES, note, 'note-detail.detail');
                    this.noteContents.push(noteContent);
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_1) throw e_1.error; }
            }
        }
    };
    ItemNotesComponent.prototype.showSummaryPanel = function (event) {
        this.showSummary = !event;
    };
    ItemNotesComponent.prototype.convertCardNoteType = function (data) {
        if (data && data.type && data.type === BookingNoteType.TRANSFER_INFO) {
            data.typeString = 'TRANSFER INFO';
        }
        else if (data && data.type) {
            data.typeString = data.type;
        }
    };
    ItemNotesComponent.prototype.isEditable = function (data) {
        return !!(data && this.masterNoteTypes.find(function (type) { return type.code === data; }));
    };
    ItemNotesComponent.prototype.onEditClick = function (summaryContent) {
        if (!this.readonly && summaryContent && summaryContent.detail && summaryContent.detail.data && summaryContent.detail.data.value) {
            this.openEditNoteDialog(summaryContent.detail.data.value);
        }
    };
    ItemNotesComponent.prototype.openEditNoteDialog = function (noteDetail) {
        var _this = this;
        var editNoteDialogRef = this.getNoteDialogRef(this.editNoteTitle, noteDetail.note, noteDetail.type, 'tc-manage-notes-edit.NOTE_EDIT', '@tc-manage-notes-edit.NOTE_EDIT', true);
        editNoteDialogRef.afterClosed().subscribe(function (result) {
            if (result) {
                _this.editItemNote(noteDetail, result);
            }
        });
    };
    ItemNotesComponent.prototype.editItemNote = function (noteDetail, editedNoteContent) {
        noteDetail.note = editedNoteContent.note;
        this.onValueChange();
    };
    ItemNotesComponent.prototype.onDeleteClick = function (summaryContent) {
        if (!this.readonly && summaryContent && summaryContent.detail && summaryContent.detail.data && summaryContent.detail.data.value) {
            var index = this.noteList.indexOf(summaryContent.detail.data.value);
            this.noteList.splice(index, 1);
            this.onValueChange();
        }
    };
    ItemNotesComponent.prototype.onValueChange = function () {
        var e_2, _a;
        var allValid = true;
        try {
            for (var _b = tslib_1.__values(this.noteList), _c = _b.next(); !_c.done; _c = _b.next()) {
                var value = _c.value;
                if (!(value && value.note && value.type)) {
                    allValid = false;
                    break;
                }
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_2) throw e_2.error; }
        }
        this.onNoteListUpdate();
        this.changeValidity.emit(allValid);
    };
    ItemNotesComponent.prototype.onPanelExpand = function ($event, note, selectedIndex) {
        $event.stopPropagation();
        this.selectedNote.emit(note);
    };
    ItemNotesComponent.prototype.onAddNotesClick = function (event) {
        var _this = this;
        var addNoteDialogRef = this.getNoteDialogRef(this.addNoteTitle, '', '', '', '', false);
        addNoteDialogRef.afterClosed().subscribe(function (result) {
            if (result) {
                console.log(result);
                _this.saveItemNote(result);
            }
        });
    };
    ItemNotesComponent.prototype.getNoteDialogRef = function (noteTitle, noteText, noteType, noteMethodCodes, noteResourcePath, isEdit) {
        if (isEdit === void 0) { isEdit = false; }
        var noteTypes = this.sortPipe.transform(this.masterNoteTypes, 'name');
        return this.dialog.open(ManageNotesComponent, {
            width: '', height: '',
            data: {
                title: noteTitle,
                note: noteText,
                noteTypes: noteTypes,
                selectedNoteType: noteType,
                isNoteEditMode: isEdit,
                selectedNoteTypes: this.getSelectedNoteType(noteTypes, noteType),
                methodCodes: noteMethodCodes,
                resourcePath: noteResourcePath,
                isMultiSelect: false
            }
        });
    };
    ItemNotesComponent.prototype.getSelectedNoteType = function (noteTypes, noteType) {
        var selectedNotes = [];
        if (noteTypes && noteTypes.length > 0 && noteType) {
            var selectedNoteTypes = noteTypes.filter(function (note) { return note.code === noteType; });
            var chip = new Chip();
            chip.selected = true;
            chip.value = selectedNoteTypes[0];
            chip.restricted = false;
            selectedNotes.push(chip);
        }
        return selectedNotes;
    };
    ItemNotesComponent.prototype.onHeaderClick = function () {
        this.noteSummary = null;
    };
    ItemNotesComponent.prototype.onOutsideClick = function () {
        this.noteSummary = null;
    };
    ItemNotesComponent.prototype.saveItemNote = function (result) {
        if (!this.readonly && result) {
            var note = new BookingNote();
            note.note = result.note;
            note.type = result.selectedNoteTypes && result.selectedNoteTypes.length > 0 ? result.selectedNoteTypes[0].value.code : 'BOOKING';
            if (!this.noteList) {
                this.noteList = [];
            }
            this.noteList.push(note);
            this.changeValidity.emit(true);
            this.onNoteListUpdate();
        }
    };
    return ItemNotesComponent;
}());
export { ItemNotesComponent };
