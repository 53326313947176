/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/forms";
import * as i2 from "./numeric-editor.component";
var styles_NumericEditorComponent = [];
var RenderType_NumericEditorComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_NumericEditorComponent, data: {} });
export { RenderType_NumericEditorComponent as RenderType_NumericEditorComponent };
export function View_NumericEditorComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { input: 0 }), (_l()(), i0.ɵeld(1, 16777216, [[1, 3], ["input", 1]], null, 5, "input", [["style", "width: 100%"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "keydown"], [null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 2).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i0.ɵnov(_v, 2)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i0.ɵnov(_v, 2)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("keydown" === en)) {
        var pd_4 = (_co.onKeyDown($event) !== false);
        ad = (pd_4 && ad);
    } if (("ngModelChange" === en)) {
        var pd_5 = ((_co.value = $event) !== false);
        ad = (pd_5 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 16384, null, 0, i1.DefaultValueAccessor, [i0.Renderer2, i0.ElementRef, [2, i1.COMPOSITION_BUFFER_MODE]], null, null), i0.ɵprd(1024, null, i1.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i1.DefaultValueAccessor]), i0.ɵdid(4, 671744, null, 0, i1.NgModel, [[8, null], [8, null], [8, null], [6, i1.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i0.ɵprd(2048, null, i1.NgControl, null, [i1.NgModel]), i0.ɵdid(6, 16384, null, 0, i1.NgControlStatus, [[4, i1.NgControl]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.value; _ck(_v, 4, 0, currVal_7); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 6).ngClassUntouched; var currVal_1 = i0.ɵnov(_v, 6).ngClassTouched; var currVal_2 = i0.ɵnov(_v, 6).ngClassPristine; var currVal_3 = i0.ɵnov(_v, 6).ngClassDirty; var currVal_4 = i0.ɵnov(_v, 6).ngClassValid; var currVal_5 = i0.ɵnov(_v, 6).ngClassInvalid; var currVal_6 = i0.ɵnov(_v, 6).ngClassPending; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_NumericEditorComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "numeric-cell", [], null, null, null, View_NumericEditorComponent_0, RenderType_NumericEditorComponent)), i0.ɵdid(1, 4243456, null, 0, i2.NumericEditorComponent, [], null, null)], null, null); }
var NumericEditorComponentNgFactory = i0.ɵccf("numeric-cell", i2.NumericEditorComponent, View_NumericEditorComponent_Host_0, {}, {}, []);
export { NumericEditorComponentNgFactory as NumericEditorComponentNgFactory };
