import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    QueryList,
    ViewChild,
    ViewChildren
} from '@angular/core';
import { Pager } from '@tc-core/model/it/codegen/ui/framework/pager';
import { Sorter } from '@tc-core/model/it/codegen/ui/framework/sorter';
import { SummaryCardData } from '@tc-core/model/it/codegen/ui/framework/summary-card-data';
import { TC } from '@tc-core/util';
import { ExpansionPanelDirective } from '@tc/directives/expansion-panel';
import { Booking } from '../../../models/reservation-v2/booking';
import { Season } from '../../../models/supplier/season';
import EMPTY_CHAR = TC.FORMAT.EMPTY_CHAR;

@Component({
    selector: 'tc-adv-booking-search-results',
    templateUrl: './adv-booking-search-results.component.html'
})
export class AdvBookingSearchResultsComponent implements OnInit, AfterViewInit {

    @Input() sorter: Sorter;
    @Input() sortingHandler: any;
    @Input() sorters: Sorter[] = [];

    @Input() pager: Pager;

    @Input() isSingleResultExpand = true;

    @Input() summaryList: SummaryCardData[];
    @Input() selectedSummary: SummaryCardData;
    @Input() detailedObject: Booking;
    @Input() selectedVehicleCost: Season[] = [];
    @Input() showEditBtn: boolean;
    @Input() showEditAsNew: boolean = true;

    @Output() select: EventEmitter<SummaryCardData> = new EventEmitter();
    @Output() edit: EventEmitter<Booking> = new EventEmitter();
    @Output() editAsNew: EventEmitter<Booking> = new EventEmitter();
    @Output() cardScroll: EventEmitter<number> = new EventEmitter<number>();
    @Output() focus: EventEmitter<number> = new EventEmitter();

    public expandedPanelIndex = -1;

    private expandedPanel = null;
    private expansionPanel = null;

    public selectionIndex = '1';

    @ViewChildren(ExpansionPanelDirective) expansionPanels: QueryList<ExpansionPanelDirective>;
    @ViewChild('clientExp') clientExp: ElementRef;

    constructor() { }

    ngOnInit() {
    }

    ngAfterViewInit() {
        if (this.isSingleResultExpand && this.summaryList && this.summaryList.length === 1) {
            if (this.expansionPanels) {
                this.selectionIndex = '1';
                this.openExpansionPanel();
            }
        }
    }

    public getHeader() {
        if ( this.detailedObject && this.detailedObject.kcCompany && this.detailedObject.kcCompany.toString().toLowerCase() === 'fc' ){
            if (this.detailedObject && this.detailedObject.bookingId) {
                return this.detailedObject.bookingId;
            } else if (this.selectedSummary) {
                if (this.selectedSummary.data.value.bookingId) {
                    return this.selectedSummary.data.value.bookingId;
                } else {
                    return EMPTY_CHAR;
                }
            }
        }else{
            if (this.detailedObject && this.detailedObject.bookingIdCombined) {
                return this.detailedObject.bookingIdCombined;
            } else if (this.selectedSummary) {
                if (this.selectedSummary.data.value.bookingIdCombined) {
                    return this.selectedSummary.data.value.bookingIdCombined;
                } else {
                    return EMPTY_CHAR;
                }
            }
        }

    }

    public getSubHeader() {
        if (this.detailedObject) {
            return '';
        }
    }

    public openExpansionPanel() {
        this.onSelection(this.summaryList[+this.selectionIndex - 1], +this.selectionIndex - 1);

        // close expanded panel
        if (this.expandedPanelIndex !== -1) {
            this.expandedPanel.expansionState.next(true);
        }

        // open focused panel
        this.expansionPanel = this.expansionPanels.find((expansionElement, i) => {
            return (i + 1) === +this.selectionIndex - ((this.pager.currPage) * this.pager.itemsPerPage);
        });

        // same page
        if (this.expansionPanel !== null && this.expansionPanel !== undefined) {
            this.expansionPanel.expansionState.next(false);
            // another page
        } else {
            this.pager.currPage = Math.ceil(+this.selectionIndex / ((this.pager.currPage + 1) * this.pager.itemsPerPage));
        }
    }

    public onSelection(summaryCardData: SummaryCardData, index?: number) {
        this.selectedSummary = summaryCardData;

        if (summaryCardData) {
            this.select.emit(summaryCardData);
            this.cardScroll.emit(index);
        }
    }

    public onHeaderClick($event: any) {
        if ($event.srcElement.className !== 'material-icons') {
            this.selectedSummary = null;
        }
    }

    public onActionClick(genericResource: SummaryCardData, $event: any) {

    }

    public onEditClick(event) {
        this.edit.emit(this.detailedObject);
    }

    public onEditAsNewClick(event) {
        /**
         * Inhere, Set bookingSource as 'DMC' to make BookingID as DMC one (Ex: DMC-XXXX)
         * Logic: When taking a copy of bookingItem (using 'edit as new') we treat it as DMC created one.
         */
        if (this.detailedObject.bookingSource != null) {
            this.detailedObject.bookingSource = 'DMC';
        }
        this.editAsNew.emit(this.detailedObject);
    }

    public onPanelExpand(index: number) {
        this.selectionIndex = (index + 1 + ((this.pager.currPage) * this.pager.itemsPerPage)).toString();
        this.expansionPanel = this.expansionPanels.find((expansionElement, i) => {
            return (i + 1) === +this.selectionIndex - (this.pager.currPage * this.pager.itemsPerPage);
        });
        this.expandedPanelIndex = +this.selectionIndex - 1;
        this.expandedPanel = this.expansionPanel;
        this.focus.emit(Number.parseInt(this.selectionIndex));

        // set animation class
        this.expansionPanel.elementRef.nativeElement.classList.add('animate');

    }

}
