<div class="">
    <tc-section-heading
            [pageSection]="costAddSection"
            (actionClick)="onHeaderActionClick($event)">
    </tc-section-heading>
    <ag-grid-angular
            style=""
            class="ag-theme-material tc-ag-grid tc-ag-grid--full-height tc-border-right-0 tc-border-left-0"
            [rowData]="resourceCosts"
            [defaultColDef]="defaultColDef"
            [columnDefs]="columnDefs"
            [pagination]="true"
            [paginationAutoPageSize]="true"
            rowSelection="multiple"
            [rowClassRules]="rowClassRules"
            singleClickEdit=true
            (gridReady)="onGridReady($event)"
            (selectionChanged)="onSelectionChanged($event)"
            (cellValueChanged)="onCellValueChanged($event)"
            (rowValueChanged)="validateUniqueValues($event)"
            [frameworkComponents]="frameworkComponents">
    </ag-grid-angular>
</div>
