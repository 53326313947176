import { AfterViewInit, EventEmitter, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { MatCalendar } from '@angular/material';
// import { CgCalendarCssClassConstants } from '@tc/cg-calendar/cg-calendar-css-class-constants';
import { Observable } from 'rxjs';
// import { DateRange } from '@tc/cg-calendar/date-range';
// import { RangeGroup } from '@tc/cg-calendar/range-group';
import * as moment from 'moment';
import { CgCalendarCssClassConstants } from '../cg-calendar-css-class-constants';
var SingleYearComponent = /** @class */ (function () {
    function SingleYearComponent(viewContainer) {
        var _this = this;
        this.viewContainer = viewContainer;
        this.selectDate = new EventEmitter();
        this.selectedDate = null;
        this.showCalendar = true;
        this.numOfRows = 3;
        this.numOfCols = 4;
        this.cgCalDateRangeCssClasses = [];
        this.dateClass = function (date) {
            var classes = [];
            _this.rangeGroups.forEach(function (group) {
                if (group.dateRanges) {
                    group.dateRanges.forEach(function (range) {
                        if (SingleYearComponent.withinRange(date, range)) {
                            classes.push(group.colorId);
                            if (group.selected) {
                                classes.push(CgCalendarCssClassConstants.RangeState.RANGE_SELECTED);
                            }
                            if (SingleYearComponent.isLeftEdge(date, range)) {
                                classes.push(CgCalendarCssClassConstants.RangeState.RANGE_START_DATE);
                            }
                            if (SingleYearComponent.isRightEdge(date, range)) {
                                classes.push(CgCalendarCssClassConstants.RangeState.RANGE_END_DATE);
                            }
                        }
                    });
                }
            });
            return classes;
        };
    }
    SingleYearComponent.prototype.ngOnInit = function () {
        this.initCustomClassList();
        this.createCalendarMonthArrays();
        if (this.calendarRefreshEvent) {
            this.subscribeCalendarRefreshEvent();
        }
    };
    SingleYearComponent.prototype.ngAfterViewInit = function () {
        this.updateCalendar();
    };
    SingleYearComponent.prototype.initCustomClassList = function () {
        for (var colorIdKey in CgCalendarCssClassConstants.ColorId) {
            this.cgCalDateRangeCssClasses.push(CgCalendarCssClassConstants.ColorId[colorIdKey].class);
        }
        for (var rangeStateKey in CgCalendarCssClassConstants.RangeState) {
            this.cgCalDateRangeCssClasses.push(CgCalendarCssClassConstants.RangeState[rangeStateKey]);
        }
    };
    SingleYearComponent.prototype.subscribeCalendarRefreshEvent = function () {
        var _this = this;
        this.calendarRefreshEventSubscription = this.calendarRefreshEvent.subscribe(function () {
            _this.updateCalendar();
        });
    };
    SingleYearComponent.prototype.createCalendarMonthArrays = function () {
        this.rowsIndexArray = SingleYearComponent.arrayOfNumbers(this.numOfRows);
        this.colsIndexArray = SingleYearComponent.arrayOfNumbers(this.numOfCols);
    };
    SingleYearComponent.arrayOfNumbers = function (n) {
        var arr = [];
        for (var i = 0; i < n; i++) {
            arr.push(i);
        }
        return arr;
    };
    SingleYearComponent.prototype.getMonthStartingDate = function (row_index, col_index) {
        var month = this.numOfCols * (row_index) + col_index;
        var date = moment([this.year, month]);
        return date.format('YYYY-MM-DD');
    };
    SingleYearComponent.withinRange = function (d, dateRange) {
        if (dateRange.startDate && dateRange.endDate) {
            if (moment(dateRange.startDate).isSame(d, 'day')) {
                return true;
            }
            else if (moment(dateRange.endDate).isSame(d, 'day')) {
                return true;
            }
            else {
                return dateRange.startDate.getTime() <= d.getTime() && d.getTime() <= dateRange.endDate.getTime();
            }
        }
        else {
            return false;
        }
    };
    SingleYearComponent.isLeftEdge = function (d, dateRange) {
        return moment(d).format('YYYY-MM-DD') === moment(dateRange.startDate).format('YYYY-MM-DD');
    };
    SingleYearComponent.isRightEdge = function (d, dateRange) {
        return moment(d).format('YYYY-MM-DD') === moment(dateRange.endDate).format('YYYY-MM-DD');
    };
    SingleYearComponent.prototype.onSelectDate = function ($event) {
        this.selectedDate = new Date($event);
        this.selectDate.emit($event);
    };
    SingleYearComponent.prototype.updateCalendar = function () {
        this.updateCalendarClasses();
    };
    SingleYearComponent.prototype.updateCalendarClasses = function () {
        var matCalAllDates = this.viewContainer
            .element
            .nativeElement
            .querySelectorAll('.mat-calendar-body-cell');
        var _loop_1 = function (i) {
            // for (let matCalAllDatesKey in matCalAllDates) {
            var dateNode = matCalAllDates[i];
            var date = this_1.getDateFromMatCalDateNode(dateNode);
            if (date) {
                var innerDiv_1 = dateNode.querySelector('div');
                // clean previous custom css css classes
                this_1.cgCalDateRangeCssClasses.forEach(function (customClass) {
                    innerDiv_1.classList.remove(customClass);
                });
                // add new classes
                var newClassList = this_1.dateClass(date);
                newClassList.forEach(function (newClass) {
                    innerDiv_1.classList.add(newClass);
                });
                // tooltip
                var toolTips = innerDiv_1.querySelectorAll('.tc-sc-tooltip__txt');
                if (toolTips && toolTips.length > 0) {
                    for (var j = 0; j < toolTips.length; j++) {
                        innerDiv_1.removeChild(toolTips[j]);
                    }
                }
                innerDiv_1.classList.add('tc-sc-tooltip');
                var span = document.createElement('span');
                var seasonNames = this_1.getSeasonNamesOfDate(date);
                var str_1 = '';
                if (seasonNames.length > 0) {
                    seasonNames.forEach(function (name) {
                        str_1 += name + ', ';
                    });
                    span.innerHTML = str_1.substring(0, str_1.length - 2);
                    span.classList.add('tc-sc-tooltip__txt');
                    innerDiv_1.appendChild(span);
                }
            }
        };
        var this_1 = this;
        for (var i = 0; i < matCalAllDates.length; i++) {
            _loop_1(i);
        }
        this.selectedDate = null;
    };
    SingleYearComponent.prototype.getSeasonNamesOfDate = function (date) {
        var seasonNames = [];
        this.rangeGroups.forEach(function (group) {
            if (group.dateRanges) {
                group.dateRanges.forEach(function (range) {
                    if (SingleYearComponent.withinRange(date, range)) {
                        seasonNames.push(group.name);
                    }
                });
            }
        });
        return seasonNames;
    };
    SingleYearComponent.prototype.getDateFromMatCalDateNode = function (node) {
        try {
            var date = new Date(node.attributes['aria-label'].nodeValue);
            if (date) {
                return date;
            }
            else {
                return null;
            }
        }
        catch (e) {
            return null;
        }
    };
    SingleYearComponent.prototype.ngOnDestroy = function () {
        if (this.calendarRefreshEventSubscription) {
            this.calendarRefreshEventSubscription.unsubscribe();
        }
    };
    SingleYearComponent.prototype.checkScroll = function ($event) {
    };
    return SingleYearComponent;
}());
export { SingleYearComponent };
