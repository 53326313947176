/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../node_modules/@angular/material/checkbox/typings/index.ngfactory";
import * as i2 from "@angular/forms";
import * as i3 from "@angular/material/checkbox";
import * as i4 from "@angular/cdk/a11y";
import * as i5 from "@angular/platform-browser/animations";
import * as i6 from "@angular/material/expansion";
import * as i7 from "../../../../../node_modules/@angular/material/expansion/typings/index.ngfactory";
import * as i8 from "@angular/cdk/collections";
import * as i9 from "@angular/common";
import * as i10 from "@ngx-translate/core";
import * as i11 from "./booking-passenger-group/booking-passenger-group.component.ngfactory";
import * as i12 from "./booking-passenger-group/booking-passenger-group.component";
import * as i13 from "@tc-core/util/framework/config-loader.service";
import * as i14 from "../../../services/util/pre-processors/grid-column-definition-processor.service";
import * as i15 from "./passenger-editor.component";
import * as i16 from "../../../services/util/framework/data-store.service";
import * as i17 from "../../../services/backend-consumers/reservation-v2/reservation-v2-management.service";
var styles_PassengerEditorComponent = [];
var RenderType_PassengerEditorComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PassengerEditorComponent, data: {} });
export { RenderType_PassengerEditorComponent as RenderType_PassengerEditorComponent };
function View_PassengerEditorComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "mat-checkbox", [["class", "mat-checkbox"], ["color", "primary"]], [[8, "id", 0], [1, "tabindex", 0], [2, "mat-checkbox-indeterminate", null], [2, "mat-checkbox-checked", null], [2, "mat-checkbox-disabled", null], [2, "mat-checkbox-label-before", null], [2, "_mat-animation-noopable", null]], [[null, "change"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.onGroupSelected($event, _v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.onClickGroupCheckBox($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_MatCheckbox_0, i1.RenderType_MatCheckbox)), i0.ɵprd(5120, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.MatCheckbox]), i0.ɵdid(2, 8568832, null, 0, i3.MatCheckbox, [i0.ElementRef, i0.ChangeDetectorRef, i4.FocusMonitor, i0.NgZone, [8, null], [2, i3.MAT_CHECKBOX_CLICK_ACTION], [2, i5.ANIMATION_MODULE_TYPE]], { color: [0, "color"], disabled: [1, "disabled"] }, { change: "change" }), (_l()(), i0.ɵted(3, 0, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_7 = "primary"; var currVal_8 = _co.readOnly; _ck(_v, 2, 0, currVal_7, currVal_8); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 2).id; var currVal_1 = null; var currVal_2 = i0.ɵnov(_v, 2).indeterminate; var currVal_3 = i0.ɵnov(_v, 2).checked; var currVal_4 = i0.ɵnov(_v, 2).disabled; var currVal_5 = (i0.ɵnov(_v, 2).labelPosition == "before"); var currVal_6 = (i0.ɵnov(_v, 2)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_9 = _v.parent.context.$implicit.bookingReferenceId; _ck(_v, 3, 0, currVal_9); }); }
function View_PassengerEditorComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [], [[8, "id", 0]], null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = i0.ɵinlineInterpolate(1, "booking-item-ref-", _v.parent.context.index, ""); _ck(_v, 0, 0, currVal_0); var currVal_1 = ((_v.parent.context.$implicit.bookingReferenceIdWithoutReturnFlag == null) ? _v.parent.context.$implicit.bookingReferenceId : _v.parent.context.$implicit.bookingReferenceIdWithoutReturnFlag); _ck(_v, 1, 0, currVal_1); }); }
function View_PassengerEditorComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 24, "mat-accordion", [["class", "mat-accordion"]], [[1, "data-index", 0]], null, null, null, null)), i0.ɵdid(1, 1720320, null, 1, i6.MatAccordion, [], null, null), i0.ɵqud(603979776, 2, { _headers: 1 }), i0.ɵprd(2048, null, i6.MAT_ACCORDION, null, [i6.MatAccordion]), (_l()(), i0.ɵeld(4, 16777216, null, null, 20, "mat-expansion-panel", [["class", "tc-mb-0 mat-expansion-panel"]], [[2, "mat-expanded", null], [2, "_mat-animation-noopable", null], [2, "mat-expansion-panel-spacing", null]], null, null, i7.View_MatExpansionPanel_0, i7.RenderType_MatExpansionPanel)), i0.ɵdid(5, 1753088, null, 1, i6.MatExpansionPanel, [[3, i6.MAT_ACCORDION], i0.ChangeDetectorRef, i8.UniqueSelectionDispatcher, i0.ViewContainerRef, i9.DOCUMENT, [2, i5.ANIMATION_MODULE_TYPE], [2, i6.MAT_EXPANSION_PANEL_DEFAULT_OPTIONS]], null, null), i0.ɵqud(335544320, 3, { _lazyContent: 0 }), i0.ɵprd(256, null, i6.MAT_ACCORDION, undefined, []), (_l()(), i0.ɵeld(8, 0, null, 0, 14, "mat-expansion-panel-header", [["class", "mat-expansion-panel-header"], ["role", "button"]], [[1, "id", 0], [1, "tabindex", 0], [1, "aria-controls", 0], [1, "aria-expanded", 0], [1, "aria-disabled", 0], [2, "mat-expanded", null], [40, "@expansionHeight", 0]], [[null, "click"], [null, "keydown"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 9)._toggle() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i0.ɵnov(_v, 9)._keydown($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i7.View_MatExpansionPanelHeader_0, i7.RenderType_MatExpansionPanelHeader)), i0.ɵdid(9, 180224, [[2, 4]], 0, i6.MatExpansionPanelHeader, [i6.MatExpansionPanel, i0.ElementRef, i4.FocusMonitor, i0.ChangeDetectorRef, [2, i6.MAT_EXPANSION_PANEL_DEFAULT_OPTIONS]], null, null), i0.ɵpod(10, { collapsedHeight: 0, expandedHeight: 1 }), i0.ɵpod(11, { value: 0, params: 1 }), (_l()(), i0.ɵeld(12, 0, null, 0, 5, "mat-panel-title", [["class", "mat-expansion-panel-header-title"]], null, null, null, null, null)), i0.ɵdid(13, 16384, null, 0, i6.MatExpansionPanelTitle, [], null, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PassengerEditorComponent_2)), i0.ɵdid(15, 16384, null, 0, i9.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PassengerEditorComponent_3)), i0.ɵdid(17, 16384, null, 0, i9.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(18, 0, null, 1, 4, "mat-panel-description", [["class", "mat-expansion-panel-header-description"]], null, null, null, null, null)), i0.ɵdid(19, 16384, null, 0, i6.MatExpansionPanelDescription, [], null, null), (_l()(), i0.ɵeld(20, 0, null, null, 2, "div", [], [[8, "id", 0]], null, null, null, null)), (_l()(), i0.ɵted(21, null, [" ", "", " "])), i0.ɵpid(131072, i10.TranslatePipe, [i10.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(23, 0, null, 1, 1, "tc-booking-passenger-group", [], null, [[null, "newRowSelectEvent"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("newRowSelectEvent" === en)) {
        var pd_0 = (_co.onRowSelected($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i11.View_BookingPassengerGroupComponent_0, i11.RenderType_BookingPassengerGroupComponent)), i0.ɵdid(24, 4308992, [[1, 4], ["bookingPassengerGroupComponent", 4]], 0, i12.BookingPassengerGroupComponent, [i10.TranslateService, i13.ConfigLoader, i14.GridColumnDefinitionProcessorService], { group: [0, "group"] }, { newRowSelectEvent: "newRowSelectEvent" })], function (_ck, _v) { var _co = _v.component; var currVal_11 = _co.pageOptionsAvailability.checkbox; _ck(_v, 15, 0, currVal_11); var currVal_12 = !_co.pageOptionsAvailability.checkbox; _ck(_v, 17, 0, currVal_12); var currVal_16 = _v.context.$implicit; _ck(_v, 24, 0, currVal_16); }, function (_ck, _v) { var currVal_0 = _v.context.index; _ck(_v, 0, 0, currVal_0); var currVal_1 = i0.ɵnov(_v, 5).expanded; var currVal_2 = (i0.ɵnov(_v, 5)._animationMode === "NoopAnimations"); var currVal_3 = i0.ɵnov(_v, 5)._hasSpacing(); _ck(_v, 4, 0, currVal_1, currVal_2, currVal_3); var currVal_4 = i0.ɵnov(_v, 9).panel._headerId; var currVal_5 = (i0.ɵnov(_v, 9).disabled ? (0 - 1) : 0); var currVal_6 = i0.ɵnov(_v, 9)._getPanelId(); var currVal_7 = i0.ɵnov(_v, 9)._isExpanded(); var currVal_8 = i0.ɵnov(_v, 9).panel.disabled; var currVal_9 = i0.ɵnov(_v, 9)._isExpanded(); var currVal_10 = _ck(_v, 11, 0, i0.ɵnov(_v, 9)._getExpandedState(), _ck(_v, 10, 0, i0.ɵnov(_v, 9).collapsedHeight, i0.ɵnov(_v, 9).expandedHeight)); _ck(_v, 8, 0, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10); var currVal_13 = i0.ɵinlineInterpolate(1, "booking-item-pax-count-", _v.context.index, ""); _ck(_v, 20, 0, currVal_13); var currVal_14 = _v.context.$implicit.bookingPassengers.length; var currVal_15 = i0.ɵunv(_v, 21, 1, i0.ɵnov(_v, 22).transform(" Passengers Available")); _ck(_v, 21, 0, currVal_14, currVal_15); }); }
function View_PassengerEditorComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["style", "color: red"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.splitButtonDisabledMsg; _ck(_v, 1, 0, currVal_0); }); }
function View_PassengerEditorComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "button", [["class", "assign-panel-footer-section__button tc-button-primary mat-button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSplit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "])), i0.ɵpid(131072, i10.TranslatePipe, [i10.TranslateService, i0.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.splitButtonEnable; _ck(_v, 0, 0, currVal_0); var currVal_1 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform("Split")); _ck(_v, 1, 0, currVal_1); }); }
export function View_PassengerEditorComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(671088640, 1, { passengerGroupComponentList: 1 }), (_l()(), i0.ɵeld(1, 0, null, null, 8, "div", [["class", "detail-group__body-service-item container-fluid"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 4, "div", [["class", "detail-group__heading container-fluid"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 3, "div", [["class", "item-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 2, "div", [["class", "item-header__content"]], null, null, null, null, null)), (_l()(), i0.ɵted(5, null, ["", ""])), i0.ɵpid(131072, i10.TranslatePipe, [i10.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(7, 0, null, null, 2, "div", [["class", "tc-px-3"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PassengerEditorComponent_1)), i0.ɵdid(9, 278528, null, 0, i9.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵeld(10, 0, null, null, 7, "div", [["class", "container assign-panel-footer-section"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PassengerEditorComponent_4)), i0.ɵdid(12, 16384, null, 0, i9.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(13, 0, null, null, 2, "button", [["class", "assign-panel-footer-section__button tc-button-secondary mat-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClose($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(14, null, [" ", " "])), i0.ɵpid(131072, i10.TranslatePipe, [i10.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PassengerEditorComponent_5)), i0.ɵdid(17, 16384, null, 0, i9.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.bookingPassengerGroup; _ck(_v, 9, 0, currVal_1); var currVal_2 = (_co.splitButtonDisabledMsg && _co.pageOptionsAvailability.split_button_disabled_msg); _ck(_v, 12, 0, currVal_2); var currVal_4 = _co.pageOptionsAvailability.split; _ck(_v, 17, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 5, 0, i0.ɵnov(_v, 6).transform("Booking Passengers")); _ck(_v, 5, 0, currVal_0); var currVal_3 = i0.ɵunv(_v, 14, 0, i0.ɵnov(_v, 15).transform("Close")); _ck(_v, 14, 0, currVal_3); }); }
export function View_PassengerEditorComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-passenger-editor", [], null, null, null, View_PassengerEditorComponent_0, RenderType_PassengerEditorComponent)), i0.ɵdid(1, 114688, null, 0, i15.PassengerEditorComponent, [i16.DataStoreService, i17.ReservationV2ManagementService, i13.ConfigLoader], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PassengerEditorComponentNgFactory = i0.ɵccf("tc-passenger-editor", i15.PassengerEditorComponent, View_PassengerEditorComponent_Host_0, { selectedItem: "selectedItem", bookingPassengerGroup: "bookingPassengerGroup", isBookingQueue: "isBookingQueue" }, {}, []);
export { PassengerEditorComponentNgFactory as PassengerEditorComponentNgFactory };
