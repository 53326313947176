<form [formGroup]="insuranceInformationForm">
    <div class="container-fluid direct-client-personal-info-edit-card tc-card-wrapper">
        <div class="row">
            <div class="col-md-12 col-lg-12">
                <div class="tc-flex-box">
                    <div class="tc-flex-item tc-item-circle">
                        <div class="icon-box">
                            <i class="material-icons">security</i>
                        </div>
                    </div>
                    <div class="tc-flex-item tc-right-cage">
                        <div class="row">
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                <div class="tc-select-edit dcpiec-select-title">
                                    <mat-form-field>
                                        <input matInput
                                               formControlName="insCmpName"
                                               [placeholder]="'Insurance Company Name'"
                                        >

                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                <div class="tc-input-edit dcpiec-input-fname">
                                    <mat-form-field>
                                        <input matInput formControlName="insuredDate" [matDatepicker]="picker1"
                                               placeholder="Insured Date">
                                        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                        <mat-datepicker #picker1></mat-datepicker>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                <div class="tc-input-edit dcpiec-input-fname">
                                    <mat-form-field>
                                        <input matInput formControlName="expiryDate" [matDatepicker]="picker2"
                                               placeholder="Insurance Expiry Date">
                                        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                        <mat-datepicker #picker2></mat-datepicker>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                <div class="tc-select-edit dcpiec-select-title">
                                    <mat-form-field>
                                        <input matInput
                                               formControlName="policyNumber"
                                               [placeholder]="'Insurance Policy Number'"
                                        >
                                    </mat-form-field>
                                </div>
                            </div>

                        </div>
                        <div class="row">
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                <div class="tc-input-edit dcpiec-input-lname">
                                    <mat-form-field>
                                        <input
                                                matInput
                                                formControlName="email"
                                                [placeholder]="'Email'">
                                        <mat-error>{{getErrorMessageOfControl(insuranceInformationForm, 'Email')}}
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">

                                <div class="tc-select-edit dcpiec-select-title">
                                    <mat-form-field>
                                        <input
                                                matInput
                                                formControlName="alternateEmail"
                                                [placeholder]="'Alternate Email'">
                                        <mat-error>{{getErrorMessageOfControl(insuranceInformationForm, 'Alternate Email')}}
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">

                                <div class="tc-select-edit dcpiec-select-title">
                                    <mat-form-field>
                                        <input
                                                matInput
                                                formControlName="telephone"
                                                [placeholder]="'Telephone'"
                                                maxlength="15">
                                        <mat-error>{{getErrorMessageOfControl(insuranceInformationForm,
                                            'Telephone')}}
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                <div class="tc-input-edit dcpiec-input-licenseNumber">
                                    <mat-form-field>
                                        <input
                                                matInput
                                                formControlName="alternateTelephone"
                                                [placeholder]="'Alternate Telephone'"
                                                maxlength="15">
                                        <mat-error>{{getErrorMessageOfControl(insuranceInformationForm,
                                            'Alternate Telephone')}}
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>


                        </div>
                        <div class="row">

                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                <div class="tc-input-edit dcpiec-input-passportNumber">

                                    <mat-form-field>
                                        <input
                                                matInput
                                                #countryInput
                                                formControlName="country"
                                                [placeholder]="'Country'"
                                                (focus)="onCountryFocus($event)"
                                                [matAutocomplete]="countryAutoComplete">
                                        <mat-error
                                                *ngIf="insuranceInformationForm.controls['country'].hasError('invalidString')">
                                            {{getErrorMessage('country')}}
                                        </mat-error>
                                    </mat-form-field>
                                    <mat-autocomplete #countryAutoComplete="matAutocomplete"
                                                      [displayWith]="displayCountryFn">
                                        <mat-option
                                                *ngFor="let country of countryList"
                                                (onSelectionChange)="onSelectCountry(country)"
                                                [value]="country">
                                            {{ country?.name }}
                                        </mat-option>
                                    </mat-autocomplete>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                <div class="tc-input-edit dcpiec-input-passportNumber">

                                    <mat-form-field>
                                        <input
                                                matInput
                                                #stateInput
                                                formControlName="state"
                                                [matAutocomplete]="stateAutoComplete"
                                                (focus)="onStateFocus($event)"
                                                [placeholder]="'State'">
                                    </mat-form-field>
                                    <mat-autocomplete #stateAutoComplete="matAutocomplete"
                                                      [displayWith]="displayStateFn">
                                        <mat-option
                                                *ngFor="let state of stateList"
                                                [value]="state"
                                                (onSelectionChange)="onSelectState(state)">
                                            {{ state?.name }}
                                        </mat-option>
                                    </mat-autocomplete>

                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                <div class="tc-input-edit dcpiec-input-dob">
                                    <mat-form-field>
                                        <input
                                                matInput
                                                #cityInput
                                                formControlName="city"
                                                (focus)="onCityFocus($event)"
                                                [matAutocomplete]="cityAutoComplete"
                                                [placeholder]="'City'"
                                        >
                                        <mat-error
                                                *ngIf="insuranceInformationForm.controls['city'].hasError('invalidString')">
                                            {{getErrorMessage('city')}}
                                        </mat-error>
                                    </mat-form-field>
                                    <mat-autocomplete #cityAutoComplete="matAutocomplete"
                                                      [displayWith]="displayCityFn">
                                        <mat-option
                                                *ngFor="let city of cityList"
                                                [value]="city"
                                                (onSelectionChange)="onSelectCity(city)">
                                            {{ city?.name }}
                                        </mat-option>
                                    </mat-autocomplete>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                <div class="tc-select-edit dcpiec-select-title">
                                    <mat-form-field>
                                        <input
                                                matInput
                                                formControlName="street"
                                                [placeholder]="'Street'">


                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
