import { Injectable } from '@angular/core';
import { ConfigLoader } from '@tc-core/util/framework';
import { Observable } from 'rxjs';
import { TCO } from '../../../constants';
import { CitySearchCriteria } from '../../../models/criteria/city-search-criteria';
import { ConfigurationSearchCriteria } from '../../../models/criteria/configuration-search-criteria';
import { OPResponseWrapper } from '../../../models/response/op-response-wrapper';
import { Configuration } from '../../../models/setup/Configuration';
import { DataLoaderService } from '../../api/data-loader-service.service';
import { DMCQueryParamsService } from '../../util/framework/dmc-query-params.service';
import { RequestService } from '../../util/framework/request.service';
import { FileHandlingService } from '../file-handler/file-handling.service';
import { UrlPaths } from '../url-paths';

@Injectable({
    providedIn: 'root'
})
export class MasterDataRestService {

    constructor(
        private configLoader: ConfigLoader,
        private requestService: RequestService,
        private queryParamsService: DMCQueryParamsService,
        private fileHandlingService: FileHandlingService,
        private dataLoaderService: DataLoaderService
    ) {
    }

    public searchCities(criteria: CitySearchCriteria): Observable<any> {
        const reqPrams = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.get(
            UrlPaths.OP_DATA_URL_KEY,
            [UrlPaths.OP_DATA_VERSION, UrlPaths.CITIES],
            reqPrams
        );
    }

    public getCityByCode(code: string): Observable<any> {
        return this.requestService.get(
            UrlPaths.OP_DATA_URL_KEY,
            [UrlPaths.OP_DATA_VERSION, UrlPaths.CITIES, code]
        );
    }

    public getCostingTypes(): Observable<any> {
        return this.requestService.get(
            UrlPaths.OP_DATA_URL_KEY,
            [UrlPaths.OP_DATA_VERSION, UrlPaths.COSTING_TYPES, UrlPaths.ALL_TYPES]
        );
    }

    public getConfigurations(criteria: ConfigurationSearchCriteria): Observable<OPResponseWrapper<Configuration>> {
        const params = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.get(
            UrlPaths.OP_DATA_URL_KEY,
            [UrlPaths.OP_DATA_VERSION, UrlPaths.CONFIGURATIONS],
            params
        );
    }

    public deleteConfiguration(code: string): Observable<any> {
        return this.requestService.delete(
            UrlPaths.OP_DATA_URL_KEY,
            [UrlPaths.OP_DATA_VERSION, UrlPaths.CONFIGURATIONS, code]
        );
    }

    public saveConfiguration(row: Configuration): Observable<OPResponseWrapper<any>> {
        return this.requestService.post(
            UrlPaths.OP_DATA_URL_KEY,
            [UrlPaths.OP_DATA_VERSION, UrlPaths.CONFIGURATIONS],
            row
        );
    }

    uploadImage(fileToUpload: File): Observable<any> {
        let endpoint = this.configLoader.configurations.get(TCO.CONF.CONF_ENDPOINT)[UrlPaths.OP_DATA_URL_KEY];
        endpoint = this.dataLoaderService.getUrl(
            endpoint,
            [UrlPaths.OP_SUP_VERSION, UrlPaths.CONTENTS, UrlPaths.IMAGES]
        );
        return this.fileHandlingService.uploadImage({imageFile: fileToUpload}, endpoint);
    }

}
