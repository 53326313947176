import * as tslib_1 from "tslib";
import { AbstractSearchCriteria } from './abstract-search-criteria';
var RoutesSearchCriteria = /** @class */ (function (_super) {
    tslib_1.__extends(RoutesSearchCriteria, _super);
    function RoutesSearchCriteria() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return RoutesSearchCriteria;
}(AbstractSearchCriteria));
export { RoutesSearchCriteria };
