<div class="container-fluid tc-item-wrapper tcpi-card" *ngIf="summaryInfo?.rawData?.trsServiceItem?.arrFltNo != null">
    <div class="tc-item-wrapper__body">
        <div class="row">
            <div class="col-md-12 rs-full-width">
                <div class="tc-flex-box">
                    <div class="tc-flex-item icon-cage input-icon">
                        <div class="card-icon--small icn-color-primary"><i class="material-icons">flight</i>
                        </div>
                    </div>
                    <div class="tc-flex-item input-cage ">
                        <div class="col-xs-12 col-sm-12  col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                            <div class="tc-content--wrap tc-input-view">
                                <div
                                        id="TRD_PER_INFO_REF"
                                        class="tc-caption-txt txt-light">{{"Arrival Flight Number" | translate}}
                                </div>
                                <div class="tc-body1--txt">{{summaryInfo?.rawData?.trsServiceItem?.arrFltNo ? summaryInfo?.rawData?.trsServiceItem?.arrFltNo : EMPTY_CHAR}}</div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-12  col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                            <div class="tc-content--wrap tc-input-view">
                                <div
                                        id="TRADE_PER_INFO_EXT_REF"
                                        class="tc-caption-txt txt-light">{{"Flight Arrival Time" | translate}}
                                </div>
                                <div class="tc-body1--txt">{{summaryInfo?.rawData?.trsServiceItem?.flightArrTime ? (summaryInfo?.rawData?.trsServiceItem?.flightArrTime  | date:'yyyy-MM-dd HH:mm':'GMT') : EMPTY_CHAR}}</div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                            <div class="tc-content--wrap tc-input-view">
                                <div class="tc-caption-txt txt-light">{{"Flight Arrival Terminal" | translate}}
                                </div>
                                <div class="tc-body1--txt ">{{summaryInfo?.rawData?.trsServiceItem?.flightArrTerminal ? summaryInfo?.rawData?.trsServiceItem?.flightArrTerminal : EMPTY_CHAR}}
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-6  col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                            <div class="tc-content--wrap tc-input-view">
                                <div
                                        id="TRD_PER_INFO_COU"
                                        class="tc-caption-txt txt-light">{{"Pickup Time" | translate}}
                                </div>
                                <div class="tc-body1--txt">{{summaryInfo?.rawData?.trsServiceItem?.pickupTime ? (summaryInfo?.rawData?.trsServiceItem?.pickupTime  | date:'yyyy-MM-dd HH:mm':'GMT') : EMPTY_CHAR}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 rs-full-width">
                <div class="tc-flex-box">
                    <div class="tc-flex-item icon-cage ">
                        <div class="card-icon--small icn-color-primary"></div>
                    </div>
                    <div class="tc-flex-item input-cage ">
                        <div class="col-xs-12 col-sm-6  col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                        </div>
                        <div class="col-xs-12 col-sm-12  col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                        </div>
                        <div class="col-xs-12 col-sm-12  col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                        </div>
                        <div class="col-xs-12 col-sm-12  col-md-3 col-lg-3 remove-bootstrap-padding-mobile last-column">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid tc-item-wrapper tcpi-card" *ngIf="summaryInfo?.rawData?.trsServiceItem?.depFltNo != null">
    <div class="tc-item-wrapper__body">
        <div class="row">
            <div class="col-md-12 rs-full-width">
                <div class="tc-flex-box">
                    <div class="tc-flex-item icon-cage input-icon">
                        <div class="card-icon--small icn-color-primary"><i class="material-icons">flight</i>
                        </div>
                    </div>
                    <div class="tc-flex-item input-cage ">
                        <div class="col-xs-12 col-sm-12  col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                            <div class="tc-content--wrap tc-input-view">
                                <div
                                        class="tc-caption-txt txt-light">{{"Departure Flight Number" | translate}}
                                </div>
                                <div class="tc-body1--txt">{{summaryInfo?.rawData?.trsServiceItem?.depFltNo ? summaryInfo?.rawData?.trsServiceItem?.depFltNo : EMPTY_CHAR}}</div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                            <div class="tc-content--wrap tc-input-view">
                                <div
                                        class="tc-caption-txt txt-light">{{"Flight Departure Time" | translate}}
                                </div>
                                <div class="tc-body1--txt">{{summaryInfo?.rawData?.trsServiceItem?.flightDeptTime ? (summaryInfo?.rawData?.trsServiceItem?.flightDeptTime | date:'yyyy-MM-dd HH:mm':'GMT') : EMPTY_CHAR}}</div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                            <div class="tc-content--wrap tc-input-view">
                                <div class="tc-caption-txt txt-light">{{"Flight Departure Terminal" | translate}}
                                </div>
                                <div class="tc-body1--txt ">{{summaryInfo?.rawData?.trsServiceItem?.flightDepTerminal ? summaryInfo?.rawData?.trsServiceItem?.flightDepTerminal : EMPTY_CHAR}}
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-6  col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                            <div class="tc-content--wrap tc-input-view">
                                <div
                                        class="tc-caption-txt txt-light">{{"Pickup Time" | translate}}
                                </div>
                                <div class="tc-body1--txt">{{summaryInfo?.rawData?.trsServiceItem?.pickupTime ? (summaryInfo?.rawData?.trsServiceItem?.pickupTime  | date:'yyyy-MM-dd HH:mm':'GMT') : EMPTY_CHAR}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 rs-full-width">
                <div class="tc-flex-box">
                    <div class="tc-flex-item icon-cage ">
                        <div class="card-icon--small icn-color-primary"></div>
                    </div>
                    <div class="tc-flex-item input-cage ">
                        <div class="col-xs-12 col-sm-6  col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                        </div>
                        <div class="col-xs-12 col-sm-12  col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                        </div>
                        <div class="col-xs-12 col-sm-12  col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                        </div>
                        <div class="col-xs-12 col-sm-12  col-md-3 col-lg-3 remove-bootstrap-padding-mobile last-column">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
