import * as tslib_1 from "tslib";
import { DatePipe } from '@angular/common';
import { AfterViewChecked, ChangeDetectorRef, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TcErrorType } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-error';
import { DialogModel, LEVEL } from '@tc-core/model/it/codegen/ui/framework/dialog-model';
import { ModalData } from '@tc-core/model/it/codegen/ui/framework/modal-data';
import { TC } from '@tc-core/util';
import { ConfigLoader, EventManager, UserJourneyManager } from '@tc-core/util/framework';
import { SpinnerService } from '@tc-core/util/ui';
import { DialogService } from '@tc/dialog/dialog.service';
import { FocusViewService } from '@tc/focus-view/focus-view.service';
import { ModalService } from '@tc/modal/modal.service';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { TCO } from '../../../constants';
import { DocumentCreationCriteria, DocumentFormat, DocumentType } from '../../../models/criteria/document-creation-criteria';
import { DocumentDataCriteria } from '../../../models/criteria/document-data-criteria';
import { LocationsSearchCriteria } from '../../../models/criteria/locations-search-criteria';
import { DocumentCreationModel } from '../../../models/helper/document-creation-model';
import { AllocationType } from '../../../models/reservation-v2/allocation-type';
import { AssignStatus, ServiceLevel, StatusCodes } from '../../../models/reservation-v2/assignment';
import { AssignmentPatch } from '../../../models/reservation-v2/assignment-patch';
import { AssignmentRawData } from '../../../models/reservation-v2/assignment-raw-data';
import { BookingItemPatch } from '../../../models/reservation-v2/booking-item-patch';
import { BookingPaxMapping } from '../../../models/reservation-v2/booking-pax-mapping';
import { CostingType } from '../../../models/reservation-v2/costing-type';
import { BookingItemSearchCriteria } from '../../../models/reservation-v2/criteria/booking-item-search-criteria';
import { ProductQueueSearchCriteria } from '../../../models/reservation-v2/criteria/product-queue-search-criteria';
import { ResourceAllocationSearchCriteria } from '../../../models/reservation-v2/criteria/resource-allocation-search-criteria';
import { ServiceItemSearchCriteria } from '../../../models/reservation-v2/criteria/service-item-search-criteria';
import { SupplierAllocationSearchCriteria } from '../../../models/reservation-v2/criteria/supplier-allocation-search-criteria';
import { MainType } from '../../../models/reservation-v2/main-type';
import { PassengerType } from '../../../models/reservation-v2/passenger-type';
import { QueueType } from '../../../models/reservation-v2/product-item';
import { ProductLevel } from '../../../models/reservation-v2/product-level';
import { AllocationTimeRange, ResourceAllocationSummary } from '../../../models/reservation-v2/resource-allocation-summary';
import { ServiceSharing } from '../../../models/reservation-v2/service-sharing';
import { SupplierAllocationSummary } from '../../../models/reservation-v2/supplier-allocation-summary';
import { TIME_GROUPING_OPTIONS } from '../../../models/reservation-v2/time-grouping-options';
import { UserBusinessType } from '../../../models/user/user-business-type';
import { DocumentService } from '../../../services/backend-consumers/documents/document.service';
import { ReservationV2ManagementService } from '../../../services/backend-consumers/reservation-v2/reservation-v2-management.service';
import { BackendConfigService } from '../../../services/backend-consumers/service-config/backend-config.service';
import { LocationsService } from '../../../services/backend-consumers/setups/locations.service';
import { CostCalculationUtilsV2Service } from '../../../services/business-utils/cost-calculation/cost-calculation-utils-v2.service';
import { DateTimeProcessor } from '../../../services/business-utils/time-zone/date-time-processor';
import { BackgroundTaskService } from '../../../services/util/change-detector/background-task.service';
import { DMCCommon } from '../../../services/util/common/dmc-common';
import { DataKey, DataStoreService } from '../../../services/util/framework/data-store.service';
import { GridColumnDefinitionProcessorService } from '../../../services/util/pre-processors/grid-column-definition-processor.service';
import { OperationV2DataProcessorService } from '../../../services/util/pre-processors/operation-v2-data-processor.service';
import { ResponseUtil } from '../../../services/util/response/response-util.service';
import { DMCLocalStorageService, LocalDataKey } from '../../../services/util/system/dmc-local-storage.service';
import { AllocationSuggestionEditorComponent } from '../../../widgets/framework/ag-grid-custom-components/editors/allocation-suggestion-editor/allocation-suggestion-editor.component';
import { AutoCompleteEditorComponent } from '../../../widgets/framework/ag-grid-custom-components/editors/auto-complete-editor/auto-complete-editor.component';
import { DateEditorComponent } from '../../../widgets/framework/ag-grid-custom-components/editors/date-editor/date-editor.component';
import { DropdownEditorComponent } from '../../../widgets/framework/ag-grid-custom-components/editors/dropdown-editor/dropdown-editor.component';
import { MaxLengthValidatorComponent } from '../../../widgets/framework/ag-grid-custom-components/editors/max-length-validator/max-length-validator.component';
import { MultiSelectorEditorComponent } from '../../../widgets/framework/ag-grid-custom-components/editors/multi-selector-editor/multi-selector-editor.component';
import { RadioButtonEditorComponent } from '../../../widgets/framework/ag-grid-custom-components/editors/radio-button-editor/radio-button-editor.component';
import { CheckBoxRendererEditorComponent } from '../../../widgets/framework/ag-grid-custom-components/renderers/check-box-selector-renderer/check-box-renderer-editor.component';
import { TemplateRendererComponent } from '../../../widgets/framework/ag-grid-custom-components/renderers/template-renderer/template-renderer.component';
import { TimeEditorRendererComponent } from '../../../widgets/framework/ag-grid-custom-components/renderers/time-editor-renderer/time-editor-renderer.component';
import { DocumentCreationModalComponent } from '../../document-creation-modal/document-creation-modal.component';
import { SearchCriteriaSaveModalComponent } from '../../reservation-management/search-criteria-save-modal/search-criteria-save-modal.component';
import { AllocationFocusViewComponent } from '../allocation-panel/allocation-focus-view/allocation-focus-view.component';
import { EditTimeRangeComponent } from '../allocation-panel/resource-allocation-panel/edit-time-range/edit-time-range.component';
import { BookingNoteGroup, NotesEditorComponent } from '../notes-editor/notes-editor.component';
import { BookingPassengerGroup, PassengerEditorComponent } from '../passenger-editor/passenger-editor.component';
import { BookingSupplementGroup, SupplementsEditorComponent } from '../supplements-editor/supplements-editor.component';
import { TotalCostEditorComponent } from '../total-cost-editor/total-cost-editor.component';
import { BookingQueueComponent } from './booking-queue/booking-queue.component';
import { OperationQueuePanelConfig, OperationQueueProfile } from './operation-queue-panel-config';
import { ProductQueueComponent } from './product-queue/product-queue.component';
import { ServiceQueueComponent } from './service-queue/service-queue.component';
var EMPTY_CHAR = TC.FORMAT.EMPTY_CHAR;
import { UserManagementService } from '../../../services/user-management/user-management.service';
var OperationQueuePanelComponent = /** @class */ (function () {
    function OperationQueuePanelComponent(cd, em, userJourneyManager, configLoader, dialogService, modalService, common, spinnerService, dataStore, focusViewService, columnDefinitionProcessorService, reservationV2ManagementService, costCalculationUtilsService, documentService, operationV2DataProcessorService, datePipe, translateService, locationService, localStorage, backgroundTaskService, backendConfigService, userManagementService) {
        var _this = this;
        this.cd = cd;
        this.em = em;
        this.userJourneyManager = userJourneyManager;
        this.configLoader = configLoader;
        this.dialogService = dialogService;
        this.modalService = modalService;
        this.common = common;
        this.spinnerService = spinnerService;
        this.dataStore = dataStore;
        this.focusViewService = focusViewService;
        this.columnDefinitionProcessorService = columnDefinitionProcessorService;
        this.reservationV2ManagementService = reservationV2ManagementService;
        this.costCalculationUtilsService = costCalculationUtilsService;
        this.documentService = documentService;
        this.operationV2DataProcessorService = operationV2DataProcessorService;
        this.datePipe = datePipe;
        this.translateService = translateService;
        this.locationService = locationService;
        this.localStorage = localStorage;
        this.backgroundTaskService = backgroundTaskService;
        this.backendConfigService = backendConfigService;
        this.userManagementService = userManagementService;
        this.EMPTY_STRING = '--';
        this.NOT_AVAILABLE_STRING = 'N/A';
        this.availableDocumentTypes = [];
        this.allAvailableDocumentTypes = [];
        this.manifestGenerationStatus = [];
        this.itemList = [];
        this.allocationTimeRange = new AllocationTimeRange();
        this.totalProductsCount = 0;
        this.selectedProducts = [];
        /* PRODUCT QUEUE COL DEF */
        this.productQueueColDefConfigJson = [];
        this.productQueueColDefConfig = [];
        /* BOOKING QUE COL DEF */
        this.bookingQueueColDefConfigJson = [];
        this.bookingQueueAllColDefConfigJson = [];
        this.bookingQueuePrimaryColDefConfigJson = [];
        this.bookingQueueSecondaryColDefConfigJson = [];
        this.bookingQueueColDefConfig = [];
        this.bookingQueueAllColDefConfig = [];
        this.bookingQueuePrimaryColDefConfig = [];
        this.bookingQueueSecondaryColDefConfig = [];
        /* SERVICE QUEUE COL DEF */
        this.serviceQueueColDefConfigJson = [];
        this.serviceQueueAllColDefConfigJson = [];
        this.serviceQueuePrimaryColDefConfigJson = [];
        this.serviceQueueSecondaryColDefConfigJson = [];
        this.serviceQueueColDefConfig = [];
        this.serviceQueueAllColDefConfig = [];
        this.serviceQueuePrimaryColDefConfig = [];
        this.serviceQueueSecondaryColDefConfig = [];
        this.searchCriteria = new ProductQueueSearchCriteria();
        this.selectedBookingIdList = [];
        this.allBookingIdList = [];
        /* SUBSCRIPTIONS */
        this.productSearchCriteriaSubscription = new Subscription();
        this.focusViewCloseSubject = new Subscription();
        this.focusViewConfirmSubject = new Subscription();
        this.resourceTypesSubscription = new Subscription();
        this.supplierOptionsSubscription = new Subscription();
        this.costingTypesSubscription = new Subscription();
        this.languagesSubscription = new Subscription();
        this.trsModesSubscription = new Subscription();
        this.routesSubscription = new Subscription();
        this.resourceAllocationResultsSubscription = new Subscription();
        this.supplierAllocationResultsSubscription = new Subscription();
        this.popupSubscription = new Subscription();
        this.allocationTimeRangeSubscription = new Subscription();
        this.modelConfirmSubscription = new Subscription();
        this.modelCloseSubscription = new Subscription();
        this.dialogSubscription = new Subscription();
        /* CHANGE PREVENTION */
        /* TAB switching - off */
        /* TAB switching - product item selection -off */
        this.operationPanelConfig = new OperationQueuePanelConfig();
        this.preventSwitching = false;
        this.showAllocationQueues = false;
        this.showServicingQueue = true;
        this.showBookingQueue = true;
        this.showSecondaryJobStatus = true;
        /* SERVICE QUEUE TIME GROUPING */
        this.serviceQueueTimeGroupingOptions = TIME_GROUPING_OPTIONS;
        /* EDITOR DATA */
        // instead of creating object in each key down using exisiting serch object for dropdown search
        this.supplierSearchCriteria = new SupplierAllocationSearchCriteria();
        this.resourceSearchCriteria = new ResourceAllocationSearchCriteria();
        this.rawAssignmentTempId = 1;
        this.frameworkComponents = {
            templateRenderer: TemplateRendererComponent,
            autoCompleteEditor: AutoCompleteEditorComponent,
            dropDownEditor: DropdownEditorComponent,
            timeEditorRenderer: TimeEditorRendererComponent,
            dateEditor: DateEditorComponent,
            allocationSuggestionEditor: AllocationSuggestionEditorComponent,
            checkBoxRendererEditor: CheckBoxRendererEditorComponent,
            multiSelectorEditor: MultiSelectorEditorComponent,
            radioButtonEditor: RadioButtonEditorComponent,
            maxLengthValidator: MaxLengthValidatorComponent
        };
        this.PENDING = AssignStatus.PENDING;
        this.ASSIGNED = AssignStatus.ASSIGNED;
        this.UNASSIGNED = AssignStatus.UNASSIGNED;
        this.DMC_CONFIRM = AssignStatus.DMC_CONFIRM;
        this.REQUEST_EXPIRED = AssignStatus.REQUEST_EXPIRED;
        this.SUPPLIER_CONFIRMED = AssignStatus.SUPPLIER_CONFIRMED;
        this.SUPPLIER_REJECTED = AssignStatus.SUPPLIER_REJECTED;
        this.BOOKING_UPDATE = AssignStatus.BOOKING_UPDATE;
        this.INACTIVE = AssignStatus.INACTIVE;
        this.INACTIVE_NOTIFY = AssignStatus.INACTIVE_NOTIFY;
        this.STARTED = AssignStatus.STARTED;
        this.NO_SHOW = AssignStatus.NO_SHOW;
        this.COMPLETED = AssignStatus.COMPLETED;
        this.SUPPLIER_MANIFEST = "SUPPLIER_MANIFEST";
        this.saveButtonEnable = false;
        this.revertButtonEnable = false;
        this.showManifestStatusAllocQueue = true;
        this.confirmButtonEnable = true;
        this.sendManifestButtonEnable = true;
        this.supplierConfirmButtonEnable = true;
        this.supplierRejectButtonEnable = true;
        this.documentButtonEnable = true;
        this.autoAllocationButtonEnable = true;
        this.showConfirmButton = true;
        this.showAutoAllocationButton = true;
        this.isHiddenTimeRangeKebabButton = false;
        this.isHiddenPrimaryResourceKebabButton = false;
        this.isHiddenSecondaryResourceKebabButton = false;
        this.isHiddenPassengersKebabButton = false;
        this.isHiddenNotesKebabButton = false;
        this.isHiddenSupplementsKebabButton = false;
        /*
         Grid Functions
         */
        this.isEditableItem = function (item) {
            return true;
        };
        this.isEditableResource = function (item) {
            if (item.data && item.data.noOfUnits > 1 && item.data.costingType &&
                (item.data.costingType.code === CostingType.UNIT || item.data.costingType.code === CostingType.PERSON)) {
                return false;
            }
            return true;
        };
        /*
         Ends Grid Functions
         */
        this.hasNoAnyAssignment = function (item) {
            return item.data && !item.data.primaryAssignment && !item.data.secondaryAssignment;
        };
        this.onTotalCostClick = function (params) {
            if (params && params.data && params.data.itemCostPortion) {
                _this.openTotalCostEditor(params.data);
            }
        };
        /**
         * starts ag-grid value-setter
         */
        this.onSetPvtShared = function (params) {
            var e_1, _a;
            if (params.newValue) {
                params.data.pvtShared = params.newValue;
                _this.markChanges();
                var bookingIds = [];
                if (_this.isBookingQueue()) {
                    var bookingItem = params.data;
                    bookingIds.push(bookingItem.id);
                    _this.selectItems([bookingItem]);
                }
                else {
                    var serviceItem = params.data;
                    if (serviceItem.bookingItemSummaryList != null) {
                        try {
                            for (var _b = tslib_1.__values(serviceItem.bookingItemSummaryList), _c = _b.next(); !_c.done; _c = _b.next()) {
                                var bookingItemSummary = _c.value;
                                bookingIds.push(bookingItemSummary.bookingItemId);
                            }
                        }
                        catch (e_1_1) { e_1 = { error: e_1_1 }; }
                        finally {
                            try {
                                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                            }
                            finally { if (e_1) throw e_1.error; }
                        }
                    }
                    _this.selectItems([serviceItem]);
                }
                var bookingItemPatch = new BookingItemPatch();
                bookingItemPatch.pvtShared = params.data.pvtShared;
                _this.reservationV2ManagementService.patchBookingItems(bookingIds, bookingItemPatch)
                    .subscribe(function (res) {
                    console.log(res);
                    if (params.data.costingType && params.data.costingType.code === CostingType.DISTANCE) {
                        params.data.noOfUnits = parseFloat(params.newValue);
                        _this.updateCostingUnits(params.data);
                    }
                    _this.common.showSnackBar('Added pvtShared to booking item/items', 3000, TcErrorType.TYPE.INFO);
                }, function (error) {
                    console.log(error);
                    _this.common.showSnackBar('Failed to add pvtShared to booking item/items', 3000, TcErrorType.TYPE.ERROR);
                });
            }
        };
        this.onSetDeptFlightDate = function (params) {
            var e_2, _a;
            if (params.newValue && new Date(params.newValue) instanceof Date) {
                var time = params.data.trsBookingItem ?
                    params.data.trsBookingItem.flightDeptTime ?
                        params.data.trsBookingItem.flightDeptTime.split('T')[1] :
                        new Date().toLocaleTimeString('en-IT', { hour12: false }) :
                    new Date().toLocaleTimeString('en-IT', { hour12: false });
                // params.data.startTime = new Date(date + 'T' + params.newValue);
                params.data.trsBookingItem.flightDeptTime = new Date(params.newValue + 'T' + time);
                _this.markChanges();
                var bookingIds = [];
                if (_this.isBookingQueue()) {
                    var bookingItem = params.data;
                    bookingIds.push(bookingItem.id);
                    _this.selectItems([bookingItem]);
                }
                else {
                    var serviceItem = params.data;
                    if (serviceItem.bookingItemSummaryList != null) {
                        try {
                            for (var _b = tslib_1.__values(serviceItem.bookingItemSummaryList), _c = _b.next(); !_c.done; _c = _b.next()) {
                                var bookingItemSummary = _c.value;
                                bookingIds.push(bookingItemSummary.bookingItemId);
                            }
                        }
                        catch (e_2_1) { e_2 = { error: e_2_1 }; }
                        finally {
                            try {
                                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                            }
                            finally { if (e_2) throw e_2.error; }
                        }
                    }
                    _this.selectItems([serviceItem]);
                }
                var bookingItemPatch = new BookingItemPatch();
                bookingItemPatch.flightDeptTime = params.data.trsBookingItem.flightDeptTime;
                _this.reservationV2ManagementService.patchBookingItems(bookingIds, bookingItemPatch)
                    .subscribe(function (res) {
                    if (params.data.costingType && params.data.costingType.code === CostingType.DURATION) {
                        params.data.noOfUnits = _this.getDuration(params.data);
                        _this.updateCostingUnits(params.data);
                    }
                    _this.common.showSnackBar('Added flight dept. date to booking item/items', 3000, TcErrorType.TYPE.INFO);
                }, function (error) {
                    console.log(error);
                    _this.common.showSnackBar('Failed to add flight dept. date to booking item/items', 3000, TcErrorType.TYPE.ERROR);
                });
            }
        };
        this.onSetDeptFltTime = function (params) {
            var e_3, _a;
            if (params.newValue && new Date(params.newValue) instanceof Date) {
                var date = params.data.trsBookingItem.flightDeptTime ?
                    params.data.trsBookingItem.flightDeptTime.toISOString().split('T')[0] :
                    params.data.serviceDate.split('T')[0];
                // params.data.startTime = new Date(date + 'T' + params.newValue);
                params.data.trsBookingItem.flightDeptTime = new Date(date + 'T' + params.newValue);
                _this.markChanges();
                var bookingIds = [];
                if (_this.isBookingQueue()) {
                    var bookingItem = params.data;
                    bookingIds.push(bookingItem.id);
                    _this.selectItems([bookingItem]);
                }
                else {
                    var serviceItem = params.data;
                    if (serviceItem.bookingItemSummaryList != null) {
                        try {
                            for (var _b = tslib_1.__values(serviceItem.bookingItemSummaryList), _c = _b.next(); !_c.done; _c = _b.next()) {
                                var bookingItemSummary = _c.value;
                                bookingIds.push(bookingItemSummary.bookingItemId);
                            }
                        }
                        catch (e_3_1) { e_3 = { error: e_3_1 }; }
                        finally {
                            try {
                                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                            }
                            finally { if (e_3) throw e_3.error; }
                        }
                    }
                    _this.selectItems([serviceItem]);
                }
                var bookingItemPatch = new BookingItemPatch();
                bookingItemPatch.flightDeptTime = params.data.trsBookingItem.flightDeptTime;
                _this.reservationV2ManagementService.patchBookingItems(bookingIds, bookingItemPatch)
                    .subscribe(function (res) {
                    if (params.data.costingType && params.data.costingType.code === CostingType.DURATION) {
                        params.data.noOfUnits = _this.getDuration(params.data);
                        _this.updateCostingUnits(params.data);
                    }
                    _this.common.showSnackBar('Added flight dept. time to booking item/items', 3000, TcErrorType.TYPE.INFO);
                }, function (error) {
                    console.log(error);
                    _this.common.showSnackBar('Failed to add flight dept. time to booking item/items', 3000, TcErrorType.TYPE.ERROR);
                });
            }
        };
        this.onSetDeptFltNo = function (params) {
            var e_4, _a;
            if (params.newValue) {
                params.data.trsBookingItem.departureFlightNo = params.newValue;
                _this.markChanges();
                var bookingIds = [];
                if (_this.isBookingQueue()) {
                    var bookingItem = params.data;
                    bookingIds.push(bookingItem.id);
                    _this.selectItems([bookingItem]);
                }
                else {
                    var serviceItem = params.data;
                    if (serviceItem.bookingItemSummaryList != null) {
                        try {
                            for (var _b = tslib_1.__values(serviceItem.bookingItemSummaryList), _c = _b.next(); !_c.done; _c = _b.next()) {
                                var bookingItemSummary = _c.value;
                                bookingIds.push(bookingItemSummary.bookingItemId);
                            }
                        }
                        catch (e_4_1) { e_4 = { error: e_4_1 }; }
                        finally {
                            try {
                                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                            }
                            finally { if (e_4) throw e_4.error; }
                        }
                    }
                    _this.selectItems([serviceItem]);
                }
                var bookingItemPatch = new BookingItemPatch();
                bookingItemPatch.departureFlightNo = params.data.trsBookingItem.departureFlightNo;
                _this.reservationV2ManagementService.patchBookingItems(bookingIds, bookingItemPatch)
                    .subscribe(function (res) {
                    console.log(res);
                    if (params.data.costingType && params.data.costingType.code === CostingType.DISTANCE) {
                        params.data.noOfUnits = parseFloat(params.newValue);
                        _this.updateCostingUnits(params.data);
                    }
                    _this.common.showSnackBar('Added dept. flight number to booking item/items', 3000, TcErrorType.TYPE.INFO);
                }, function (error) {
                    console.log(error);
                    _this.common.showSnackBar('Failed to add dept. flight number to booking item/items', 3000, TcErrorType.TYPE.ERROR);
                });
            }
        };
        this.onSetDeptFltTerminal = function (params) {
            var e_5, _a;
            if (params.newValue) {
                params.data.trsBookingItem.flightDeptTerminal = params.newValue;
                _this.markChanges();
                var bookingIds = [];
                if (_this.isBookingQueue()) {
                    var bookingItem = params.data;
                    bookingIds.push(bookingItem.id);
                    _this.selectItems([bookingItem]);
                }
                else {
                    var serviceItem = params.data;
                    if (serviceItem.bookingItemSummaryList != null) {
                        try {
                            for (var _b = tslib_1.__values(serviceItem.bookingItemSummaryList), _c = _b.next(); !_c.done; _c = _b.next()) {
                                var bookingItemSummary = _c.value;
                                bookingIds.push(bookingItemSummary.bookingItemId);
                            }
                        }
                        catch (e_5_1) { e_5 = { error: e_5_1 }; }
                        finally {
                            try {
                                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                            }
                            finally { if (e_5) throw e_5.error; }
                        }
                    }
                    _this.selectItems([serviceItem]);
                }
                var bookingItemPatch = new BookingItemPatch();
                bookingItemPatch.flightDeptTerminal = params.data.trsBookingItem.flightDeptTerminal;
                _this.reservationV2ManagementService.patchBookingItems(bookingIds, bookingItemPatch)
                    .subscribe(function (res) {
                    _this.common.showSnackBar('Added dept. flight terminal to booking item/items', 3000, TcErrorType.TYPE.INFO);
                }, function (error) {
                    console.log(error);
                    _this.common.showSnackBar('Failed to add dept. flight terminal to booking item/items', 3000, TcErrorType.TYPE.ERROR);
                });
            }
        };
        this.onSetArrFlightDate = function (params) {
            var e_6, _a;
            if (params.newValue && new Date(params.newValue) instanceof Date) {
                var time = params.data.trsBookingItem.flightArrTime ?
                    params.data.trsBookingItem.flightArrTime.split('T')[1] :
                    new Date().toLocaleTimeString('en-IT', { hour12: false });
                // params.data.startTime = new Date(date + 'T' + params.newValue);
                params.data.trsBookingItem.flightArrTime = new Date(params.newValue + 'T' + time);
                _this.markChanges();
                var bookingIds = [];
                if (_this.isBookingQueue()) {
                    var bookingItem = params.data;
                    bookingIds.push(bookingItem.id);
                    _this.selectItems([bookingItem]);
                }
                else {
                    var serviceItem = params.data;
                    if (serviceItem.bookingItemSummaryList != null) {
                        try {
                            for (var _b = tslib_1.__values(serviceItem.bookingItemSummaryList), _c = _b.next(); !_c.done; _c = _b.next()) {
                                var bookingItemSummary = _c.value;
                                bookingIds.push(bookingItemSummary.bookingItemId);
                            }
                        }
                        catch (e_6_1) { e_6 = { error: e_6_1 }; }
                        finally {
                            try {
                                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                            }
                            finally { if (e_6) throw e_6.error; }
                        }
                    }
                    _this.selectItems([serviceItem]);
                }
                var bookingItemPatch = new BookingItemPatch();
                bookingItemPatch.flightArrTime = params.data.trsBookingItem.flightArrTime;
                _this.reservationV2ManagementService.patchBookingItems(bookingIds, bookingItemPatch)
                    .subscribe(function (res) {
                    if (params.data.costingType && params.data.costingType.code === CostingType.DURATION) {
                        params.data.noOfUnits = _this.getDuration(params.data);
                        _this.updateCostingUnits(params.data);
                    }
                    _this.common.showSnackBar('Added flight arr. date to booking item/items', 3000, TcErrorType.TYPE.INFO);
                }, function (error) {
                    console.log(error);
                    _this.common.showSnackBar('Failed to add flight arr. date to booking item/items', 3000, TcErrorType.TYPE.ERROR);
                });
            }
        };
        this.onSetArrFltTime = function (params) {
            var e_7, _a;
            if (params.newValue && new Date(params.newValue) instanceof Date) {
                var date = params.data.trsBookingItem.flightArrTime ?
                    params.data.trsBookingItem.flightArrTime.toISOString().split('T')[0] :
                    params.data.serviceDate.split('T')[0];
                // params.data.startTime = new Date(date + 'T' + params.newValue);
                params.data.trsBookingItem.flightArrTime = new Date(date + 'T' + params.newValue);
                _this.markChanges();
                var bookingIds = [];
                if (_this.isBookingQueue()) {
                    var bookingItem = params.data;
                    bookingIds.push(bookingItem.id);
                    _this.selectItems([bookingItem]);
                }
                else {
                    var serviceItem = params.data;
                    if (serviceItem.bookingItemSummaryList != null) {
                        try {
                            for (var _b = tslib_1.__values(serviceItem.bookingItemSummaryList), _c = _b.next(); !_c.done; _c = _b.next()) {
                                var bookingItemSummary = _c.value;
                                bookingIds.push(bookingItemSummary.bookingItemId);
                            }
                        }
                        catch (e_7_1) { e_7 = { error: e_7_1 }; }
                        finally {
                            try {
                                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                            }
                            finally { if (e_7) throw e_7.error; }
                        }
                    }
                    _this.selectItems([serviceItem]);
                }
                var bookingItemPatch = new BookingItemPatch();
                bookingItemPatch.flightArrTime = params.data.trsBookingItem.flightArrTime;
                _this.reservationV2ManagementService.patchBookingItems(bookingIds, bookingItemPatch)
                    .subscribe(function (res) {
                    if (params.data.costingType && params.data.costingType.code === CostingType.DURATION) {
                        params.data.noOfUnits = _this.getDuration(params.data);
                        _this.updateCostingUnits(params.data);
                    }
                    _this.common.showSnackBar('Added flight arr. time to booking item/items', 3000, TcErrorType.TYPE.INFO);
                }, function (error) {
                    console.log(error);
                    _this.common.showSnackBar('Failed to add flight arr. time to booking item/items', 3000, TcErrorType.TYPE.ERROR);
                });
            }
        };
        this.onSetArrFltNo = function (params) {
            var e_8, _a;
            if (params.newValue) {
                params.data.trsBookingItem.arrivalFlightNo = params.newValue;
                _this.markChanges();
                var bookingIds = [];
                if (_this.isBookingQueue()) {
                    var bookingItem = params.data;
                    bookingIds.push(bookingItem.id);
                    _this.selectItems([bookingItem]);
                }
                else {
                    var serviceItem = params.data;
                    if (serviceItem.bookingItemSummaryList != null) {
                        try {
                            for (var _b = tslib_1.__values(serviceItem.bookingItemSummaryList), _c = _b.next(); !_c.done; _c = _b.next()) {
                                var bookingItemSummary = _c.value;
                                bookingIds.push(bookingItemSummary.bookingItemId);
                            }
                        }
                        catch (e_8_1) { e_8 = { error: e_8_1 }; }
                        finally {
                            try {
                                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                            }
                            finally { if (e_8) throw e_8.error; }
                        }
                    }
                    _this.selectItems([serviceItem]);
                }
                var bookingItemPatch = new BookingItemPatch();
                bookingItemPatch.arrivalFlightNo = params.data.trsBookingItem.arrivalFlightNo;
                _this.reservationV2ManagementService.patchBookingItems(bookingIds, bookingItemPatch)
                    .subscribe(function (res) {
                    console.log(res);
                    if (params.data.costingType && params.data.costingType.code === CostingType.DISTANCE) {
                        params.data.noOfUnits = parseFloat(params.newValue);
                        _this.updateCostingUnits(params.data);
                    }
                    _this.common.showSnackBar('Added arr. flight number to booking item/items', 3000, TcErrorType.TYPE.INFO);
                }, function (error) {
                    console.log(error);
                    _this.common.showSnackBar('Failed to add arr. flight number to booking item/items', 3000, TcErrorType.TYPE.ERROR);
                });
            }
        };
        this.onSetArrFltTerminal = function (params) {
            var e_9, _a;
            if (params.newValue) {
                params.data.trsBookingItem.flightArrTerminal = params.newValue;
                _this.markChanges();
                var bookingIds = [];
                if (_this.isBookingQueue()) {
                    var bookingItem = params.data;
                    bookingIds.push(bookingItem.id);
                    _this.selectItems([bookingItem]);
                }
                else {
                    var serviceItem = params.data;
                    if (serviceItem.bookingItemSummaryList != null) {
                        try {
                            for (var _b = tslib_1.__values(serviceItem.bookingItemSummaryList), _c = _b.next(); !_c.done; _c = _b.next()) {
                                var bookingItemSummary = _c.value;
                                bookingIds.push(bookingItemSummary.bookingItemId);
                            }
                        }
                        catch (e_9_1) { e_9 = { error: e_9_1 }; }
                        finally {
                            try {
                                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                            }
                            finally { if (e_9) throw e_9.error; }
                        }
                    }
                    _this.selectItems([serviceItem]);
                }
                var bookingItemPatch = new BookingItemPatch();
                bookingItemPatch.flightArrTerminal = params.data.trsBookingItem.flightArrTerminal;
                _this.reservationV2ManagementService.patchBookingItems(bookingIds, bookingItemPatch)
                    .subscribe(function (res) {
                    _this.common.showSnackBar('Added arrival terminal to booking item/items', 3000, TcErrorType.TYPE.INFO);
                }, function (error) {
                    console.log(error);
                    _this.common.showSnackBar('Failed to add arrival terminal to booking item/items', 3000, TcErrorType.TYPE.ERROR);
                });
            }
        };
        this.onSetDistance = function (params) {
            var e_10, _a;
            var oldDistance = params.data.distance;
            if (params.newValue) {
                params.data.distance = parseFloat(params.newValue);
                _this.markChanges();
                var bookingIds = [];
                if (_this.isBookingQueue()) {
                    var bookingItem = params.data;
                    bookingIds.push(bookingItem.id);
                    _this.selectItems([bookingItem]);
                }
                else {
                    var serviceItem = params.data;
                    if (serviceItem.bookingItemSummaryList != null) {
                        try {
                            for (var _b = tslib_1.__values(serviceItem.bookingItemSummaryList), _c = _b.next(); !_c.done; _c = _b.next()) {
                                var bookingItemSummary = _c.value;
                                bookingIds.push(bookingItemSummary.bookingItemId);
                            }
                        }
                        catch (e_10_1) { e_10 = { error: e_10_1 }; }
                        finally {
                            try {
                                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                            }
                            finally { if (e_10) throw e_10.error; }
                        }
                    }
                    _this.selectItems([serviceItem]);
                }
                var bookingItemPatch = new BookingItemPatch();
                bookingItemPatch.distance = params.data.distance;
                _this.reservationV2ManagementService.patchBookingItems(bookingIds, bookingItemPatch)
                    .subscribe(function (res) {
                    console.log(res);
                    if (params.data.costingType && params.data.costingType.code === CostingType.DISTANCE) {
                        params.data.noOfUnits = parseFloat(params.newValue);
                        _this.updateCostingUnits(params.data);
                    }
                    _this.common.showSnackBar('Added distance to booking item/items', 3000, TcErrorType.TYPE.INFO);
                }, function (error) {
                    console.log(error);
                    _this.common.showSnackBar('Failed to add distance to booking item/items', 3000, TcErrorType.TYPE.ERROR);
                });
            }
            if (params.newValue > 0 || params.newValue === '') {
                if (typeof params.newValue === 'string' || params.newValue instanceof String) {
                    params.newValue = parseFloat(params.newValue);
                }
                _.set(params.data, params.colDef.field, params.newValue); // calling lodash deep property value set
                return true;
            }
            return false;
        };
        this.onSetStartTime = function (params) {
            var e_11, _a;
            if (params.newValue && new Date(params.newValue) instanceof Date) {
                var date = params.data.serviceDate ? params.data.serviceDate.split('T')[0] : params.data.serviceDate;
                // params.data.startTime = new Date(date + 'T' + params.newValue);
                params.data.startTime = new Date(date + 'T' + params.newValue);
                _this.markChanges();
                var bookingIds = [];
                if (_this.isBookingQueue()) {
                    var bookingItem = params.data;
                    bookingIds.push(bookingItem.id);
                    _this.selectItems([bookingItem]);
                }
                else {
                    var serviceItem = params.data;
                    if (serviceItem.bookingItemSummaryList != null) {
                        try {
                            for (var _b = tslib_1.__values(serviceItem.bookingItemSummaryList), _c = _b.next(); !_c.done; _c = _b.next()) {
                                var bookingItemSummary = _c.value;
                                bookingIds.push(bookingItemSummary.bookingItemId);
                            }
                        }
                        catch (e_11_1) { e_11 = { error: e_11_1 }; }
                        finally {
                            try {
                                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                            }
                            finally { if (e_11) throw e_11.error; }
                        }
                    }
                    _this.selectItems([serviceItem]);
                }
                var bookingItemPatch = new BookingItemPatch();
                bookingItemPatch.serviceStartDateTime = params.data.startTime;
                _this.reservationV2ManagementService.patchBookingItems(bookingIds, bookingItemPatch)
                    .subscribe(function (res) {
                    if (params.data.costingType && params.data.costingType.code === CostingType.DURATION) {
                        params.data.noOfUnits = _this.getDuration(params.data);
                        _this.updateCostingUnits(params.data);
                    }
                    _this.common.showSnackBar('Added start time to booking item/items', 3000, TcErrorType.TYPE.INFO);
                }, function (error) {
                    console.log(error);
                    _this.common.showSnackBar('Failed to add start time to booking item/items', 3000, TcErrorType.TYPE.ERROR);
                });
            }
        };
        this.getDefaultStartTime = function (params) {
            if (params.data && !params.data.startTime && params.data.trsBookingItem) {
                params.data.startTime = new Date(params.data.trsBookingItem.departureDateTime);
            }
            else {
                params.data.startTime = EMPTY_CHAR;
            }
        };
        this.onSetEndTime = function (params) {
            var e_12, _a;
            if (params.newValue && new Date(params.newValue) instanceof Date) {
                var date = params.data.serviceDate ? params.data.serviceDate.split('T')[0] : params.data.serviceDate;
                params.data.endTime = new Date(date + 'T' + params.newValue);
                _this.markChanges();
                var bookingIds = [];
                if (_this.isBookingQueue()) {
                    var bookingItem = params.data;
                    bookingIds.push(bookingItem.id);
                    _this.selectItems([bookingItem]);
                }
                else {
                    var serviceItem = params.data;
                    if (serviceItem.bookingItemSummaryList != null) {
                        try {
                            for (var _b = tslib_1.__values(serviceItem.bookingItemSummaryList), _c = _b.next(); !_c.done; _c = _b.next()) {
                                var bookingItemSummary = _c.value;
                                bookingIds.push(bookingItemSummary.bookingItemId);
                            }
                        }
                        catch (e_12_1) { e_12 = { error: e_12_1 }; }
                        finally {
                            try {
                                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                            }
                            finally { if (e_12) throw e_12.error; }
                        }
                    }
                    _this.selectItems([serviceItem]);
                }
                var bookingItemPatch = new BookingItemPatch();
                bookingItemPatch.serviceEndDateTime = params.data.endTime;
                _this.reservationV2ManagementService.patchBookingItems(bookingIds, bookingItemPatch)
                    .subscribe(function (res) {
                    if (params.data.costingType && params.data.costingType.code === CostingType.DURATION) {
                        params.data.noOfUnits = _this.getDuration(params.data);
                        _this.updateCostingUnits(params.data);
                    }
                    _this.common.showSnackBar('Added end time to booking item/items', 3000, TcErrorType.TYPE.INFO);
                }, function (error) {
                    console.log(error);
                    _this.common.showSnackBar('Failed to add end time to booking item/items', 3000, TcErrorType.TYPE.ERROR);
                });
            }
        };
        this.getDefaultEndTime = function (params) {
            if (params.data && !params.data.endTime && params.data.trsBookingItem) {
                params.data.endTime = new Date(params.data.trsBookingItem.arrivalDateTime);
            }
            else if (params.data && !params.data.endTime) {
                params.data.endTime = EMPTY_CHAR;
            }
        };
        this.getCostingTypes = function (params, row) {
            var costingTypeList = [];
            var clearCostingType = {
                name: '-- CLEAR --',
                code: null,
                obj: null
            };
            costingTypeList.push(clearCostingType);
            if (row && row.primaryAssignment && row.primaryAssignment.supplierObj &&
                row.primaryAssignment.supplierObj.genResourceCostSummaries) {
                var uniqueCostingTypes = row.primaryAssignment.supplierObj.genResourceCostSummaries.map(function (item) { return item.costingType; })
                    .filter(function (value, index, self) { return self.indexOf(value) === index; });
                uniqueCostingTypes.map(function (i) {
                    var costingType = _this.costingTypes.find(function (rt) { return rt.code === i; });
                    var existingType = {
                        name: costingType ? costingType.name : i,
                        code: i,
                        obj: costingType
                    };
                    costingTypeList.push(existingType);
                });
            }
            else {
                _this.costingTypes.forEach(function (type) {
                    var costingType = {
                        name: type ? type.name : '',
                        code: type ? type.code : '',
                        obj: type
                    };
                    costingTypeList.push(costingType);
                });
            }
            return costingTypeList;
        };
        this.getSecondaryCostingTypes = function (params, row) {
            var costingTypeList = [];
            var clearCostingType = {
                name: '-- CLEAR --',
                code: null,
                obj: null
            };
            costingTypeList.push(clearCostingType);
            if (row && row.secondaryAssignment && row.secondaryAssignment.supplierObj &&
                row.secondaryAssignment.supplierObj.genResourceCostSummaries) {
                var uniqueCostingTypes = row.secondaryAssignment.supplierObj.genResourceCostSummaries.map(function (item) { return item.costingType; })
                    .filter(function (value, index, self) { return self.indexOf(value) === index; });
                uniqueCostingTypes.map(function (i) {
                    var costingType = _this.costingTypes.find(function (rt) { return rt.code === i; });
                    var existingType = {
                        name: costingType ? costingType.name : i,
                        code: i,
                        obj: costingType
                    };
                    costingTypeList.push(existingType);
                });
            }
            else {
                _this.costingTypes.forEach(function (type) {
                    var costingType = {
                        name: type ? type.name : '',
                        code: type ? type.code : '',
                        obj: type
                    };
                    costingTypeList.push(costingType);
                });
            }
            return costingTypeList;
        };
        this.getServiceBookingQueueCostingTypes = function (params) {
            var costingType;
            if (_this.costingTypes && params.data && params.data.costingType && Array.isArray(_this.costingTypes)) {
                costingType = _this.costingTypes.find(function (rt) { return rt.code === params.data.costingType.code; });
                return costingType && costingType.name ? costingType.name : params.data.costingType;
            }
            else if (_this.costingTypes && params.data && params.data.primaryAssignment && Array.isArray(_this.costingTypes)) {
                costingType = _this.costingTypes.find(function (rt) { return rt.code === params.data.primaryAssignment.costingType; });
                params.data.costingType = costingType;
                return costingType && costingType.name ? costingType.name : params.data.primaryAssignment.costingType;
            }
            else {
                return _this.EMPTY_STRING;
            }
        };
        this.getSecondaryServiceBookingQueueCostingTypes = function (params) {
            var costingType;
            if (_this.costingTypes && params.data && params.data.secondaryCostingType) {
                costingType = _this.costingTypes.find(function (rt) { return rt.code === params.data.secondaryCostingType.code; });
                return costingType && costingType.name ? costingType.name : params.data.costingType;
            }
            else if (_this.costingTypes && params.data.secondaryAssignment) {
                costingType = _this.costingTypes.find(function (rt) { return rt.code === params.data.secondaryAssignment.costingType; });
                params.data.secondaryCostingType = costingType;
                return costingType && costingType.name ? costingType.name : params.data.secondaryAssignment.costingType;
            }
            else {
                return _this.EMPTY_STRING;
            }
        };
        this.getServiceBookingNoOfCostingUnits = function (params) {
            if (params.data) {
                if (params.data.noOfUnits) {
                    return params.data.noOfUnits;
                }
                else if (params.data.primaryAssignment && params.data.primaryAssignment.noOfUnits > 0) {
                    params.data.noOfUnits = params.data.primaryAssignment.noOfUnits;
                    return params.data.primaryAssignment.noOfUnits;
                }
                else if (params.data.costingType && params.data.costingType.code === CostingType.PERSON) {
                    return (params.data.adultCount + params.data.teenCount + params.data.childrenCount +
                        params.data.infantCount) + ' - (' +
                        (params.data.adultCount + params.data.teenCount) + ' ' + params.data.childrenCount + ' ' +
                        params.data.infantCount +
                        ')';
                }
            }
            return _this.EMPTY_STRING;
        };
        this.getSecondaryServiceBookingNoOfCostingUnits = function (params) {
            if (params.data) {
                if (params.data.secondaryNoOfUnits) {
                    return params.data.secondaryNoOfUnits;
                }
                else if (params.data.secondaryAssignment && params.data.secondaryAssignment.noOfUnits > 0) {
                    params.data.secondaryNoOfUnits = params.data.secondaryAssignment.noOfUnits;
                    return params.data.secondaryAssignment.noOfUnits;
                }
                else if (params.data.secondaryNoOfUnits && params.data.secondaryCostingType.code === CostingType.PERSON) {
                    return (params.data.adultCount + params.data.teenCount + params.data.childrenCount +
                        params.data.infantCount) + ' - (' +
                        (params.data.adultCount + params.data.teenCount) + ' ' + params.data.childrenCount + ' ' +
                        params.data.infantCount +
                        ')';
                }
            }
            return _this.EMPTY_STRING;
        };
        this.onSetCostingType = function (params) {
            var item = params.data;
            var costingType = params.newValue;
            if (costingType) {
                item.noOfUnits = item.costingType && costingType.code !== item.costingType.code ? 0 : item.noOfUnits;
                var assignmentIds = [];
                if (item.primaryAssignment && item.primaryAssignment.assignmentId) {
                    assignmentIds.push(item.primaryAssignment.assignmentId);
                }
                item.costingType = costingType.obj;
                if (assignmentIds.length > 0) {
                    var assignmentPatch = new AssignmentPatch();
                    assignmentPatch.costingType = costingType.code;
                    _this.reservationV2ManagementService.patchAssignmentsData(assignmentIds, assignmentPatch)
                        .subscribe(function (res) {
                        console.log(res);
                        _this.common.showSnackBar('Successfully updated the job costing Unit', 3000, TcErrorType.TYPE.INFO);
                    }, function (error) {
                        console.log(error);
                        _this.common.showSnackBar('Failed to update job costing Unit', 3000, TcErrorType.TYPE.ERROR);
                    });
                    if (item && item.noOfUnits > 1 && item.costingType &&
                        (item.costingType.code === CostingType.UNIT || item.costingType.code === CostingType.PERSON)) {
                        var selectedItems = _this.getSelectedItems();
                        if (selectedItems.length < 2) { // no selected items only consider "row"
                            selectedItems = [item];
                        }
                        _this.removeResourceFromItems(selectedItems, true, false);
                        _this.selectItems(selectedItems);
                        _this.markChanges();
                    }
                }
            }
            if (item && !item.costingType) {
                item.noOfUnits = 0;
                _this.refreshGridView();
            }
        };
        this.onSetSecondaryCostingType = function (params) {
            var item = params.data;
            var costingType = params.newValue;
            if (costingType) {
                item.secondaryNoOfUnits = item.secondaryCostingType && costingType.code !== item.secondaryCostingType.code ?
                    0 :
                    item.secondaryNoOfUnits;
                var assignmentIds = [];
                if (item.secondaryAssignment && item.secondaryAssignment.assignmentId) {
                    assignmentIds.push(item.secondaryAssignment.assignmentId);
                }
                item.secondaryCostingType = costingType.obj;
                if (assignmentIds.length > 0) {
                    var assignmentPatch = new AssignmentPatch();
                    assignmentPatch.costingType = costingType.code;
                    _this.reservationV2ManagementService.patchAssignmentsData(assignmentIds, assignmentPatch)
                        .subscribe(function (res) {
                        console.log(res);
                        _this.common.showSnackBar('Successfully updated the Secondary job costing Unit', 3000, TcErrorType.TYPE.INFO);
                    }, function (error) {
                        console.log(error);
                        _this.common.showSnackBar('Failed to update the Secondary job costing Unit', 3000, TcErrorType.TYPE.ERROR);
                    });
                    if (item && item.secondaryNoOfUnits > 1 && item.secondaryCostingType &&
                        (item.secondaryCostingType.code === CostingType.UNIT || item.secondaryCostingType.code ===
                            CostingType.PERSON)) {
                        var selectedItems = _this.getSelectedItems();
                        if (selectedItems.length < 2) { // no selected items only consider "row"
                            selectedItems = [item];
                        }
                        _this.removeResourceFromSecondaryItems(selectedItems, false, true);
                        _this.selectItems(selectedItems);
                        _this.markChanges();
                    }
                }
            }
            if (item && !item.secondaryCostingType) {
                item.secondaryNoOfUnits = 0;
                _this.refreshGridView();
            }
        };
        this.onSetNoOfUnits = function (params) {
            var item = params.data;
            var noOfUnits = params.newValue;
            if (noOfUnits) {
                item.noOfUnits = noOfUnits;
                _this.updateCostingUnits(item);
            }
            if (params.newValue > 0 || params.newValue === '') {
                if (typeof params.newValue === 'string' || params.newValue instanceof String) {
                    params.newValue = parseFloat(params.newValue);
                }
                _.set(params.data, params.colDef.field, params.newValue); // calling lodash deep property value set
            }
            else {
                params.newValue = 0;
                _.set(params.data, params.colDef.field, params.newValue); // calling lodash deep property value set
            }
            return true;
        };
        this.onSetSecondaryNoOfUnits = function (params) {
            var item = params.data;
            var noOfUnits = params.newValue;
            if (noOfUnits) {
                item.secondaryNoOfUnits = noOfUnits;
                _this.updateSecondaryCostingUnits(item);
            }
            if (params.newValue > 0 || params.newValue === '') {
                if (typeof params.newValue === 'string' || params.newValue instanceof String) {
                    params.newValue = parseFloat(params.newValue);
                }
                _.set(params.data, params.colDef.field, params.newValue); // calling lodash deep property value set
            }
            else {
                params.newValue = 0;
                _.set(params.data, params.colDef.field, params.newValue); // calling lodash deep property value set
            }
            return true;
        };
        this.isNoOfUnitsEditable = function (item) {
            return _this.isEditableItem(item) && item.data && item.data.costingType && item.data.costingType.code !==
                CostingType.PERSON &&
                (!item.data.primaryAssignment ||
                    (item.data.primaryAssignment && _this.isEditableAssignStatus(item.data.primaryAssignment)));
        };
        this.isSecondaryNoOfUnitsEditable = function (item) {
            return _this.isEditableItem(item) && item.data && item.data.secondaryCostingType &&
                item.data.secondaryCostingType.code !== CostingType.PERSON &&
                (!item.data.secondaryAssignment ||
                    (item.data.secondaryAssignment && _this.isEditableAssignStatus(item.data.secondaryAssignment)));
        };
        this.isPrimaryServiceTypeEditable = function (item) {
            return _this.isEditableItem(item) && item.data && (!item.data.primaryAssignment ||
                (item.data.primaryAssignment && _this.isEditableAssignStatus(item.data.primaryAssignment)));
        };
        this.isSecondaryServiceTypeEditable = function (item) {
            return _this.isEditableItem(item) && item.data && (!item.data.secondaryAssignment ||
                (item.data.secondaryAssignment && _this.isEditableAssignStatus(item.data.secondaryAssignment)));
        };
        this.getServiceBookingQueuePrimaryServiceType = function (params) {
            if (params.data && params.data.primaryAssignment) {
                if (params.data.primaryAssignment.trsResourceCost) {
                    return 'Vehicle';
                }
                else if (params.data.primaryAssignment.genResourceCost) {
                    var resourceType = _this.resourceTypes.find(function (rt) { return rt.code ===
                        params.data.primaryAssignment.genResourceCost.resourceType; });
                    return resourceType ? resourceType.name : params.data.primaryAssignment.genResourceCost.resourceType;
                }
            }
            else if (params.data && params.data.primaryServiceType) {
                return params.data.primaryServiceType.name;
            }
            else if (params.data && params.data.genBookingItem && params.data.genBookingItem.productName) {
                return params.data.genBookingItem.productName;
            }
            else if (params.data && params.data.trsBookingItem) {
                return 'Vehicle';
            }
            else {
                return _this.EMPTY_STRING;
            }
        };
        this.getServiceBookingQueueSecondaryServiceType = function (params) {
            if (params.data && params.data.secondaryAssignment) {
                if (params.data.secondaryAssignment.trsResourceCost) {
                    return 'Driver';
                }
                else if (params.data.secondaryAssignment.genResourceCost) {
                    var resourceType = _this.resourceTypes.find(function (rt) { return rt.code ===
                        params.data.secondaryAssignment.genResourceCost.resourceType; });
                    return resourceType ? resourceType.name : params.data.secondaryAssignment.genResourceCost.resourceType;
                }
            }
            else if (params.data && params.data.secondaryServiceType) {
                return params.data.secondaryServiceType.name;
            }
            else if (params.data.trsBookingItem) {
                return 'Driver';
            }
            else {
                return _this.EMPTY_STRING;
            }
        };
        this.displaySupplierItemInEditor = function (params) {
            return params ? params.name : '';
        };
        this.getProductQueueLanguageName = function (params) {
            if (params.data && params.data.languages && params.data.languages.length) {
                if (_this.languages) {
                    var languages_1 = [];
                    params.data.languages.forEach(function (lang) {
                        var lan = _this.languages.find(function (value) { return value.code === lang; });
                        if (lan && lan.name) {
                            languages_1.push(lan.name);
                        }
                    });
                    return languages_1.join();
                }
                else {
                    return params.data.languages.join();
                }
            }
            else {
                return _this.NOT_AVAILABLE_STRING;
            }
        };
        this.getPrimarySupplierItems = function (params, row) {
            if (row && row.primaryAssignment && row.primaryAssignment.supplierObj &&
                row.primaryAssignment.supplierObj.genResourceCostSummaries) {
                return row.primaryAssignment.supplierObj.genResourceCostSummaries.map(function (i) {
                    var resourceType = _this.resourceTypes.find(function (rt) { return rt.code === i.resourceType; });
                    return {
                        name: resourceType ? resourceType.name : i.resourceType,
                        code: i.resourceType,
                        obj: i
                    };
                });
            }
            else {
                var serviceTypes_1 = [];
                _this.resourceTypes.forEach(function (type) {
                    var serviceType = {
                        name: type ? type.name : '',
                        code: type ? type.code : '',
                        obj: type
                    };
                    serviceTypes_1.push(serviceType);
                });
                return serviceTypes_1;
            }
        };
        this.getSecondarySupplierItems = function (params, row) {
            if (row && row.secondaryAssignment && row.secondaryAssignment.supplierObj &&
                row.secondaryAssignment.supplierObj.genResourceCostSummaries) {
                return row.secondaryAssignment.supplierObj.genResourceCostSummaries.map(function (i) {
                    var resourceType = _this.resourceTypes.find(function (rt) { return rt.code === i.resourceType; });
                    return {
                        name: resourceType ? resourceType.name : i.resourceType,
                        code: i.resourceType,
                        obj: i
                    };
                });
            }
            else {
                var serviceTypes_2 = [];
                _this.resourceTypes.forEach(function (type) {
                    var serviceType = {
                        name: type ? type.name : '',
                        code: type ? type.code : '',
                        obj: type
                    };
                    serviceTypes_2.push(serviceType);
                });
                return serviceTypes_2;
            }
        };
        this.onSetPrimarySupplierItem = function (params) {
            var e_13, _a;
            var supplierItem = params.newValue;
            if (supplierItem) {
                var costLine = supplierItem.obj;
                var item = params.data;
                item.primaryServiceType = params.newValue;
                if (item.primaryAssignment) {
                    item.primaryAssignment.genResourceCost = costLine;
                    _this.fixAssignmentCostAndCountsForNewPassengerCount(item.primaryAssignment, true, false);
                }
                var bookingIds = [];
                if (_this.isBookingQueue()) {
                    var bookingItem = params.data;
                    bookingIds.push(bookingItem.id);
                    _this.selectItems([bookingItem]);
                }
                else {
                    var serviceItem = params.data;
                    if (serviceItem.bookingItemSummaryList != null) {
                        try {
                            for (var _b = tslib_1.__values(serviceItem.bookingItemSummaryList), _c = _b.next(); !_c.done; _c = _b.next()) {
                                var bookingItemSummary = _c.value;
                                bookingIds.push(bookingItemSummary.bookingItemId);
                            }
                        }
                        catch (e_13_1) { e_13 = { error: e_13_1 }; }
                        finally {
                            try {
                                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                            }
                            finally { if (e_13) throw e_13.error; }
                        }
                    }
                    _this.selectItems([serviceItem]);
                }
                var bookingItemPatch = new BookingItemPatch();
                bookingItemPatch.primaryServiceTypeCode = supplierItem.code;
                bookingItemPatch.primaryServiceTypeName = supplierItem.name;
                _this.reservationV2ManagementService.patchBookingItems(bookingIds, bookingItemPatch)
                    .subscribe(function (res) {
                    console.log(res);
                    _this.common.showSnackBar('Changed service type in booking item/items', 3000, TcErrorType.TYPE.INFO);
                }, function (error) {
                    console.log(error);
                    _this.common.showSnackBar('Failed to change service type in booking item/items', 3000, TcErrorType.TYPE.ERROR);
                });
                _this.selectItems([item]);
            }
        };
        this.onSetSecondarySupplierItem = function (params) {
            var supplierItem = params.newValue;
            var costLine = supplierItem.obj;
            var item = params.data;
            item.secondaryServiceType = params.newValue;
            if (item.secondaryAssignment) {
                item.secondaryAssignment.genResourceCost = costLine;
                _this.fixAssignmentCostAndCountsForNewPassengerCount(item.secondaryAssignment, false, true);
            }
            _this.selectItems([item]);
        };
        this.getPrimarySuppliers = function (params, row, text) {
            return _this.getSuppliers(row, text, true, false);
        };
        this.getSecondarySuppliers = function (params, row, text) {
            return _this.getSuppliers(row, text, false, true);
        };
        this.getPrimaryResources = function (params, row, text) {
            return _this.getResources(params, row, text, true, false);
        };
        this.getSecondaryResources = function (params, row, text) {
            return _this.getResources(params, row, text, false, true);
        };
        this.displaySupplierNameInEditor = function (params) {
            return params ? params.supplierName : '';
        };
        this.displayResourceNameInEditor = function (params) {
            return params ? params.resourceName : '';
        };
        this.getResourceStatus = function (params) {
            return params ? params.onlineStatus : false;
        };
        this.onSetPrimarySupplier = function (params) {
            if (params && params.node && params.node.selected) {
                var supplierSelecting_1 = params.newValue;
                if (supplierSelecting_1) {
                    var item = params.data;
                    var selectedItems_1 = _this.getSelectedItems();
                    if (selectedItems_1.length < 2) { // no selected items only consider "row"
                        selectedItems_1 = [item];
                    }
                    if (supplierSelecting_1.nullItemForClearing) {
                        _this.removeAssignmentFromItems(selectedItems_1, true, false);
                    }
                    else {
                        var matchedItems_1 = [];
                        var nonMatchedItems_1 = [];
                        if (selectedItems_1) {
                            {
                                selectedItems_1.forEach(function (item) {
                                    _this.getSuppliersExplicitly(item, '', true, false);
                                    var promise = new Promise(function (resolve) {
                                        _this.reservationV2ManagementService.searchSuppliersForAllocation(_this.supplierSearchCriteria)
                                            .subscribe(function (response) {
                                            var suppliers = ResponseUtil.getDataArray(response);
                                            var clearSupplier = new SupplierAllocationSummary();
                                            clearSupplier.supplierName = '-- CLEAR --';
                                            clearSupplier.nullItemForClearing = true;
                                            var matched = false;
                                            if (!suppliers.find(function (value) { return value.supplierName ===
                                                clearSupplier.supplierName; })) {
                                                suppliers.unshift(clearSupplier);
                                            }
                                            if (suppliers.find(function (tmp) { return tmp.supplierCode ===
                                                supplierSelecting_1.supplierCode; })) {
                                                matchedItems_1.push(item);
                                                matched = true;
                                            }
                                            if (!matched) {
                                                nonMatchedItems_1.push(item);
                                            }
                                            resolve(true);
                                        }, function (error) {
                                        });
                                    });
                                    promise.then(function (value) {
                                        _this.removeAssignmentFromItems(nonMatchedItems_1, true, false);
                                        _this.setSupplierToItems(matchedItems_1, new AssignmentRawData(), supplierSelecting_1, true, false);
                                        _this.selectItems(selectedItems_1);
                                        _this.refreshGridView();
                                    });
                                });
                            }
                            _this.selectItems(selectedItems_1);
                        }
                    }
                }
            }
            else {
                var supplierSelecting = params.newValue;
                if (params && params.data) {
                    if (supplierSelecting.nullItemForClearing) {
                        _this.removeAssignmentFromItems([params.data], true, false);
                    }
                    else {
                        _this.removeAssignmentFromItems([params.data], true, false);
                        _this.setSupplierToItems([params.data], new AssignmentRawData(), supplierSelecting, true, false);
                        _this.selectItems([params.data]);
                    }
                }
            }
        };
        this.onSetSecondarySupplier = function (params) {
            if (params && params.node && params.node.selected) {
                var supplierSelecting_2 = params.newValue;
                if (supplierSelecting_2) {
                    var item = params.data;
                    var selectedItems_2 = _this.getSelectedItems();
                    if (selectedItems_2.length < 2) { // no selected items only consider "row"
                        selectedItems_2 = [item];
                    }
                    if (supplierSelecting_2.nullItemForClearing) {
                        _this.removeAssignmentFromItems(selectedItems_2, false, true);
                    }
                    else {
                        var matchedItems_2 = [];
                        var nonMatchedItems_2 = [];
                        if (selectedItems_2) {
                            {
                                selectedItems_2.forEach(function (item) {
                                    _this.getSuppliersExplicitly(item, '', true, false);
                                    var promise = new Promise(function (resolve) {
                                        _this.reservationV2ManagementService.searchSuppliersForAllocation(_this.supplierSearchCriteria)
                                            .subscribe(function (response) {
                                            var suppliers = ResponseUtil.getDataArray(response);
                                            var clearSupplier = new SupplierAllocationSummary();
                                            clearSupplier.supplierName = '-- CLEAR --';
                                            clearSupplier.nullItemForClearing = true;
                                            var matched = false;
                                            if (!suppliers.find(function (value) { return value.supplierName ===
                                                clearSupplier.supplierName; })) {
                                                suppliers.unshift(clearSupplier);
                                            }
                                            if (suppliers.find(function (tmp) { return tmp.supplierCode ===
                                                supplierSelecting_2.supplierCode; })) {
                                                matchedItems_2.push(item);
                                                matched = true;
                                            }
                                            if (!matched) {
                                                nonMatchedItems_2.push(item);
                                            }
                                            resolve(true);
                                        }, function (error) {
                                        });
                                    });
                                    promise.then(function (value) {
                                        _this.removeAssignmentFromItems(nonMatchedItems_2, false, true);
                                        _this.setSupplierToItems(matchedItems_2, new AssignmentRawData(), supplierSelecting_2, false, true);
                                        _this.selectItems(selectedItems_2);
                                        _this.refreshGridView();
                                    });
                                });
                            }
                            _this.selectItems(selectedItems_2);
                        }
                    }
                }
            }
            else {
                var supplierSelecting = params.newValue;
                if (params && params.data) {
                    if (supplierSelecting.nullItemForClearing) {
                        _this.removeAssignmentFromItems([params.data], false, true);
                    }
                    else {
                        _this.removeAssignmentFromItems([params.data], false, true);
                        _this.setSupplierToItems([params.data], new AssignmentRawData(), supplierSelecting, false, true);
                        _this.selectItems([params.data]);
                    }
                }
            }
        };
        this.onSetPrimaryResource = function (params) {
            if (params && params.node && params.node.selected) {
                var resourceSelecting_1 = params.newValue;
                if (resourceSelecting_1) {
                    var item = params.data;
                    var selectedItems_3 = _this.getSelectedItems();
                    if (selectedItems_3.length < 2) { // no selected items only consider "row"
                        selectedItems_3 = [item];
                    }
                    if (resourceSelecting_1.nullItemForClearing) {
                        _this.removeResourceFromItems(selectedItems_3, true, false);
                    }
                    else {
                        var matchedItems_3 = [];
                        var nonMatchedItems_3 = [];
                        if (selectedItems_3) {
                            {
                                selectedItems_3.forEach(function (item) {
                                    _this.getResourcesExplicitly(item, '', true, false);
                                    var promise = new Promise(function (resolve) {
                                        _this.reservationV2ManagementService.searchResourcesForAllocation(_this.resourceSearchCriteria)
                                            .subscribe(function (response) {
                                            var resources = ResponseUtil.getDataArray(response);
                                            var clearResource = new ResourceAllocationSummary();
                                            clearResource.resourceName = '-- CLEAR --';
                                            clearResource.nullItemForClearing = true;
                                            var matched = false;
                                            if (!resources.find(function (value) { return value.resourceName ===
                                                clearResource.resourceName; })) {
                                                resources.unshift(clearResource);
                                            }
                                            if (resources.find(function (tmp) { return tmp.resourceId ===
                                                resourceSelecting_1.resourceId; })) {
                                                matchedItems_3.push(item);
                                                matched = true;
                                            }
                                            if (!matched) {
                                                nonMatchedItems_3.push(item);
                                            }
                                            resolve(true);
                                        }, function (error) {
                                        });
                                    });
                                    promise.then(function (value) {
                                        _this.removeAssignmentFromItems(nonMatchedItems_3, true, false);
                                        var assignment = new AssignmentRawData();
                                        assignment.resourceId = resourceSelecting_1.resourceId;
                                        assignment.resourceName = resourceSelecting_1.resourceName;
                                        if (resourceSelecting_1.capacityExceeded) {
                                            _this.common.showSnackBar('Exceeding the capacity', 3000, TcErrorType.TYPE.WARN);
                                        }
                                        _this.setSupplierToItems(matchedItems_3, assignment, resourceSelecting_1, true, false);
                                        _this.selectItems(selectedItems_3);
                                        _this.refreshGridView();
                                    });
                                });
                            }
                            _this.selectItems(selectedItems_3);
                        }
                    }
                    _this.selectItems(selectedItems_3);
                }
            }
            else {
                var resourceSelecting = params.newValue;
                if (params && params.data) {
                    if (resourceSelecting.nullItemForClearing) {
                        _this.removeAssignmentFromItems([params.data], true, false);
                    }
                    else {
                        _this.removeAssignmentFromItems([params.data], true, false);
                        _this.setSupplierToItems([params.data], new AssignmentRawData(), resourceSelecting, true, false);
                        _this.selectItems([params.data]);
                    }
                }
            }
        };
        this.onSetSecondaryResource = function (params) {
            if (params && params.node && params.node.selected) {
                var resourceSelecting_2 = params.newValue;
                if (resourceSelecting_2) {
                    var item = params.data;
                    var selectedItems_4 = _this.getSelectedItems();
                    if (selectedItems_4.length < 2) { // no selected items only consider "row"
                        selectedItems_4 = [item];
                    }
                    if (resourceSelecting_2.nullItemForClearing) {
                        _this.removeResourceFromSecondaryItems(selectedItems_4, false, true);
                    }
                    else {
                        var matchedItems_4 = [];
                        var nonMatchedItems_4 = [];
                        if (selectedItems_4) {
                            {
                                selectedItems_4.forEach(function (item) {
                                    _this.getResourcesExplicitly(item, '', true, false);
                                    var promise = new Promise(function (resolve) {
                                        _this.reservationV2ManagementService.searchResourcesForAllocation(_this.resourceSearchCriteria)
                                            .subscribe(function (response) {
                                            var resources = ResponseUtil.getDataArray(response);
                                            var clearResource = new ResourceAllocationSummary();
                                            clearResource.resourceName = '-- CLEAR --';
                                            clearResource.nullItemForClearing = true;
                                            var matched = false;
                                            if (!resources.find(function (value) { return value.resourceName ===
                                                clearResource.resourceName; })) {
                                                resources.unshift(clearResource);
                                            }
                                            if (resources.find(function (tmp) { return tmp.resourceId ===
                                                resourceSelecting_2.resourceId; })) {
                                                matchedItems_4.push(item);
                                                matched = true;
                                            }
                                            if (!matched) {
                                                nonMatchedItems_4.push(item);
                                            }
                                            resolve(true);
                                        }, function (error) {
                                        });
                                    });
                                    promise.then(function (value) {
                                        _this.removeAssignmentFromItems(nonMatchedItems_4, false, true);
                                        var assignment = new AssignmentRawData();
                                        assignment.resourceId = resourceSelecting_2.resourceId;
                                        assignment.resourceName = resourceSelecting_2.resourceName;
                                        if (resourceSelecting_2.capacityExceeded) {
                                            _this.common.showSnackBar('Exceeding the capacity', 3000, TcErrorType.TYPE.WARN);
                                        }
                                        _this.setSupplierToItems(matchedItems_4, assignment, resourceSelecting_2, false, true);
                                        _this.selectItems(selectedItems_4);
                                        _this.refreshGridView();
                                    });
                                });
                            }
                            _this.selectItems(selectedItems_4);
                        }
                    }
                    _this.selectItems(selectedItems_4);
                }
            }
            else {
                var resourceSelecting = params.newValue;
                if (params && params.data) {
                    if (resourceSelecting.nullItemForClearing) {
                        _this.removeAssignmentFromItems([params.data], false, true);
                    }
                }
                else {
                    _this.removeAssignmentFromItems([params.data], false, true);
                    _this.setSupplierToItems([params.data], new AssignmentRawData(), resourceSelecting, false, true);
                    _this.selectItems([params.data]);
                }
            }
        };
        this.getServiceBookingQueueTotalCost = function (params) {
            var cost = 0;
            if (params.data && params.data.itemCostPortion) {
                cost = params.data.itemCostPortion.totalCost;
            }
            if (cost) {
                return cost.toFixed(2) + ' ' + params.data.itemCostPortion.currency;
            }
            else {
                return _this.EMPTY_STRING;
            }
        };
        // transfer mode
        this.getServiceBookingQueueTrsMode = function (params) {
            if (params.data && params.data.trsBookingItem && params.data.trsBookingItem.transferMode) {
                return params.data.trsBookingItem.transferMode;
            }
            else {
                return _this.EMPTY_STRING;
            }
        };
        this.getTransferModes = function (params, rowData, text) {
            return _this.dataStore.get(DataKey.transferModes)
                .map(function (aEvents) {
                return aEvents.filter(function (aEvent) {
                    if (text) {
                        if (aEvent.name) {
                            if (aEvent.name.toLowerCase().indexOf(text.toLowerCase().toLocaleString()) >
                                -1) {
                                return aEvent.name.toLowerCase()
                                    .indexOf(text.toLowerCase().toLocaleString()) > -1;
                            }
                            else {
                                return aEvent.code.toLowerCase()
                                    .indexOf(text.toLowerCase().toLocaleString()) > -1;
                            }
                        }
                    }
                    else {
                        return true;
                    }
                });
            });
        };
        this.onSetTransferMode = function (params) {
            var e_14, _a;
            if (params.newValue && params.data.trsBookingItem) {
                params.data.trsBookingItem.transferMode = params.newValue.code;
                _this.markChanges();
                var selectedItems_5;
                var bookingIds = [];
                if (_this.isBookingQueue()) {
                    var bookingItem = params.data;
                    bookingIds.push(bookingItem.id);
                    selectedItems_5 = [bookingItem];
                }
                else {
                    var serviceItem = params.data;
                    if (serviceItem.bookingItemSummaryList != null) {
                        try {
                            for (var _b = tslib_1.__values(serviceItem.bookingItemSummaryList), _c = _b.next(); !_c.done; _c = _b.next()) {
                                var bookingItemSummary = _c.value;
                                bookingIds.push(bookingItemSummary.bookingItemId);
                            }
                        }
                        catch (e_14_1) { e_14 = { error: e_14_1 }; }
                        finally {
                            try {
                                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                            }
                            finally { if (e_14) throw e_14.error; }
                        }
                    }
                    selectedItems_5 = [serviceItem];
                }
                _this.selectItems(selectedItems_5);
                var bookingItemPatch = new BookingItemPatch();
                bookingItemPatch.transferMode = params.data.trsBookingItem.transferMode;
                _this.reservationV2ManagementService.patchBookingItems(bookingIds, bookingItemPatch)
                    .subscribe(function (res) {
                    _this.removeAssignmentFromItems(selectedItems_5, true, true);
                    _this.common.showSnackBar('Added transfer mode to booking item/items', 3000, TcErrorType.TYPE.INFO);
                }, function (error) {
                    console.log(error);
                    _this.common.showSnackBar('Failed to add transfer mode to booking item/items', 3000, TcErrorType.TYPE.ERROR);
                });
            }
        };
        this.getRoutes = function (params, rowData, text) {
            return _this.dataStore.get(DataKey.routes)
                .map(function (aEvents) {
                return aEvents.filter(function (aEvent) {
                    if (text) {
                        if (aEvent.code) {
                            return aEvent.code.toLowerCase().indexOf(text.toLowerCase().toLocaleString()) >
                                -1;
                        }
                    }
                    else {
                        return true;
                    }
                });
            });
        };
        this.onSetRoute = function (params) {
            var e_15, _a;
            if (params.newValue && params.data.trsBookingItem && params.data.trsBookingItem.route !==
                params.newValue.code) {
                params.data.trsBookingItem.route = params.newValue.code;
                _this.markChanges();
                var selectedItems_6;
                var bookingIds = [];
                if (_this.isBookingQueue()) {
                    var bookingItem = params.data;
                    bookingIds.push(bookingItem.id);
                    selectedItems_6 = [bookingItem];
                }
                else {
                    var serviceItem = params.data;
                    if (serviceItem.bookingItemSummaryList != null) {
                        try {
                            for (var _b = tslib_1.__values(serviceItem.bookingItemSummaryList), _c = _b.next(); !_c.done; _c = _b.next()) {
                                var bookingItemSummary = _c.value;
                                bookingIds.push(bookingItemSummary.bookingItemId);
                            }
                        }
                        catch (e_15_1) { e_15 = { error: e_15_1 }; }
                        finally {
                            try {
                                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                            }
                            finally { if (e_15) throw e_15.error; }
                        }
                    }
                    selectedItems_6 = [serviceItem];
                }
                _this.selectItems(selectedItems_6);
                var bookingItemPatch = new BookingItemPatch();
                bookingItemPatch.route = params.data.trsBookingItem.route;
                _this.reservationV2ManagementService.patchBookingItems(bookingIds, bookingItemPatch)
                    .subscribe(function (res) {
                    _this.removeAssignmentFromItems(selectedItems_6, true, true);
                    _this.common.showSnackBar('Added route to booking item/items', 3000, TcErrorType.TYPE.INFO);
                }, function (error) {
                    console.log(error);
                    _this.common.showSnackBar('Failed to add route to booking item/items', 3000, TcErrorType.TYPE.ERROR);
                });
            }
        };
        // for both pickup and dropoff locations
        this.getLocations = function (params, rowData, text) {
            var locationSearchCriteria = new LocationsSearchCriteria();
            locationSearchCriteria.codeOrName = text;
            locationSearchCriteria.size = 10;
            return _this.locationService.getLocations(locationSearchCriteria)
                .pipe(map(function (response) {
                return ResponseUtil.getDataArray(response);
            }), catchError(function (error) {
                return [];
            }));
        };
        this.onSetPickupLocation = function (params) {
            var e_16, _a;
            if (params.newValue && params.data.trsBookingItem) {
                params.data.trsBookingItem.pickupPointName = params.newValue.name;
                params.data.trsBookingItem.pickupPointCode = params.newValue.code;
                _this.markChanges();
                var selectedItems_7;
                var bookingIds = [];
                if (_this.isBookingQueue()) {
                    var bookingItem = params.data;
                    bookingIds.push(bookingItem.id);
                    selectedItems_7 = [bookingItem];
                }
                else {
                    var serviceItem = params.data;
                    if (serviceItem.bookingItemSummaryList != null) {
                        try {
                            for (var _b = tslib_1.__values(serviceItem.bookingItemSummaryList), _c = _b.next(); !_c.done; _c = _b.next()) {
                                var bookingItemSummary = _c.value;
                                bookingIds.push(bookingItemSummary.bookingItemId);
                            }
                        }
                        catch (e_16_1) { e_16 = { error: e_16_1 }; }
                        finally {
                            try {
                                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                            }
                            finally { if (e_16) throw e_16.error; }
                        }
                    }
                    selectedItems_7 = [serviceItem];
                }
                _this.selectItems(selectedItems_7);
                var bookingItemPatch = new BookingItemPatch();
                bookingItemPatch.pickupPointName = params.data.trsBookingItem.pickupPointName;
                bookingItemPatch.pickupPointCode = params.data.trsBookingItem.pickupPointCode;
                _this.reservationV2ManagementService.patchBookingItems(bookingIds, bookingItemPatch)
                    .subscribe(function (data) {
                    if (data) {
                        var bookingItems = ResponseUtil.getDataArray(data);
                        if (bookingItems && bookingItems.length > 0 && bookingItems[0].trsBookingItem &&
                            params.data.trsBookingItem.route !== bookingItems[0].trsBookingItem.route) {
                            params.data.trsBookingItem.route = bookingItems[0].trsBookingItem.route;
                            _this.removeAssignmentFromItems(selectedItems_7, true, true);
                            _this.refreshGridView();
                        }
                    }
                    _this.common.showSnackBar('Pickup location and route updated', 3000, TcErrorType.TYPE.INFO);
                }, function (error) {
                    console.log(error);
                    _this.common.showSnackBar('Failed to update pickup location', 3000, TcErrorType.TYPE.ERROR);
                });
            }
        };
        this.onSetDropOffLocation = function (params) {
            var e_17, _a;
            if (params.newValue && params.data.trsBookingItem) {
                params.data.trsBookingItem.dropOffPointName = params.newValue.name;
                params.data.trsBookingItem.dropOffPointCode = params.newValue.code;
                _this.markChanges();
                var selectedItems_8;
                var bookingIds = [];
                if (_this.isBookingQueue()) {
                    var bookingItem = params.data;
                    bookingIds.push(bookingItem.id);
                    selectedItems_8 = [bookingItem];
                }
                else {
                    var serviceItem = params.data;
                    if (serviceItem.bookingItemSummaryList != null) {
                        try {
                            for (var _b = tslib_1.__values(serviceItem.bookingItemSummaryList), _c = _b.next(); !_c.done; _c = _b.next()) {
                                var bookingItemSummary = _c.value;
                                bookingIds.push(bookingItemSummary.bookingItemId);
                            }
                        }
                        catch (e_17_1) { e_17 = { error: e_17_1 }; }
                        finally {
                            try {
                                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                            }
                            finally { if (e_17) throw e_17.error; }
                        }
                    }
                    selectedItems_8 = [serviceItem];
                }
                _this.selectItems(selectedItems_8);
                var bookingItemPatch = new BookingItemPatch();
                bookingItemPatch.dropOffPointCode = params.data.trsBookingItem.dropOffPointCode;
                bookingItemPatch.dropOffPointName = params.data.trsBookingItem.dropOffPointName;
                _this.reservationV2ManagementService.patchBookingItems(bookingIds, bookingItemPatch)
                    .subscribe(function (data) {
                    if (data) {
                        var bookingItems = ResponseUtil.getDataArray(data);
                        if (bookingItems && bookingItems.length > 0 && bookingItems[0].trsBookingItem &&
                            params.data.trsBookingItem.route !== bookingItems[0].trsBookingItem.route) {
                            params.data.trsBookingItem.route = bookingItems[0].trsBookingItem.route;
                            _this.removeAssignmentFromItems(selectedItems_8, true, true);
                            _this.refreshGridView();
                        }
                    }
                    _this.common.showSnackBar('Drop off location and route updated', 3000, TcErrorType.TYPE.INFO);
                }, function (error) {
                    console.log(error);
                    _this.common.showSnackBar('Failed to update drop off location', 3000, TcErrorType.TYPE.ERROR);
                });
            }
        };
        this.allAvailableDocumentTypes = this.configLoader.configurations.get(TCO.CONF.CONF_DOCUMENT_RULE).AVAILABLE_DOCUMENTS_FOR_GENERATION;
    }
    OperationQueuePanelComponent.prototype.ngOnInit = function () {
        this.pageOptionsAvailability = this.configLoader.configurations.get(TCO.CONF.CONF_PAGE_OPTION_AVAILABILITY).ALLOCATION_QUEUES;
        this.availableDocumentTypes = this.allAvailableDocumentTypes ?
            this.allAvailableDocumentTypes :
            this.configLoader.configurations.get(TCO.CONF.CONF_DOCUMENT_RULE).AVAILABLE_DOCUMENTS_FOR_GENERATION;
        this.manifestGenerationStatus = this.configLoader.configurations.get(TCO.CONF.CONF_DOCUMENT_RULE).MANIFEST_GENERATION_STATUS;
        var kebabConfig = this.configLoader.configurations.get(TCO.CONF.CONF_TBL_DEF_BOOKING_QUEUE_ACT_BUTTON_CONFIG);
        this.isHiddenTimeRangeKebabButton = kebabConfig.timeRangeKebabButton.hide;
        this.isHiddenPrimaryResourceKebabButton = kebabConfig.primaryResourceKebabButton.hide;
        this.isHiddenSecondaryResourceKebabButton = kebabConfig.secondaryResourceKebabButton.hide;
        this.isHiddenPassengersKebabButton = kebabConfig.passengersKebabButton.hide;
        this.isHiddenNotesKebabButton = kebabConfig.notesKebabButton.hide;
        this.isHiddenSupplementsKebabButton = kebabConfig.supplementsKebabButton.hide;
        this.backendConfigService.loadBackendConfigs();
        this.setupPageOptions();
        this.validateUserPanels();
        this.loadResourceTypes();
        this.loadCostingTypes();
        this.loadLanguages();
        this.loadTransferModes();
        this.loadRoutes();
        this.addQuickActionEvents();
        this.subscribeProductSearchCriteria();
        this.generateProductQueueColumnDefs();
        this.subscribeAllocationResults();
        this.operationPanelConfig.timeGrouping = this.serviceQueueTimeGroupingOptions[0].code;
    };
    OperationQueuePanelComponent.prototype.ngAfterViewChecked = function () {
        this.cd.detectChanges();
    };
    OperationQueuePanelComponent.prototype.isStatusBubbleVisible = function (row) {
        return row && row.primaryAssignment || row && row.secondaryAssignment;
    };
    OperationQueuePanelComponent.prototype.primaryAssignmentStatusCode = function (row) {
        if (row && row.primaryAssignment) {
            return StatusCodes[row.primaryAssignment.assignStatus];
        }
        else {
            return this.EMPTY_STRING;
        }
    };
    OperationQueuePanelComponent.prototype.secondaryAssignmentStatusCode = function (row) {
        if (row && row.secondaryAssignment) {
            return StatusCodes[row.secondaryAssignment.assignStatus];
        }
        else {
            return this.EMPTY_STRING;
        }
    };
    OperationQueuePanelComponent.prototype.addQuickActionEvents = function () {
        var _this = this;
        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'SAVE_ASSIGNMENTS').subscribe(function (e) {
            _this.onSaveAssignmentsClick();
        });
        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'REVERT_ASSIGNMENTS').subscribe(function (e) {
            _this.onRevertAssignmentsClick();
        });
        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'CONFIRM_ASSIGNMENTS').subscribe(function (e) {
            _this.onConfirmClick();
        });
        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'SEND_MANIFESTS').subscribe(function (e) {
            _this.onSendManifestsClick();
        });
        this.em.addEvent(TCO.CONF.CONF_EVENT_MANAGER, 'SUP_CONFIRM_ASSIGNMENTS').subscribe(function (e) {
            _this.onSupplierConfirmClick();
        });
        this.em.addEvent(TCO.CONF.CONF_EVENT_MANAGER, 'SUP_REJECT_ASSIGNMENTS').subscribe(function (e) {
            _this.onSupplierRejectClick();
        });
        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'GENERATE_DOCUMENTS').subscribe(function (e) {
            _this.onGenerateDocumentsClick();
        });
        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'GENERATE_AUTO_ALLOCATIONS').subscribe(function (e) {
            _this.onSupplierAutoAllocationClick();
        });
    };
    OperationQueuePanelComponent.prototype.loadResourceTypes = function () {
        var _this = this;
        this.resourceTypesSubscription = this.dataStore.get(DataKey.resourceTypes, true)
            .subscribe(function (data) {
            if (data && Array.isArray(data)) {
                _this.resourceTypes = data;
            }
        });
    };
    OperationQueuePanelComponent.prototype.loadCostingTypes = function () {
        var _this = this;
        this.costingTypesSubscription = this.dataStore.get(DataKey.costingTypes, true)
            .subscribe(function (costingTypes) {
            if (costingTypes && Array.isArray(costingTypes)) {
                _this.costingTypes = costingTypes;
            }
            else if (costingTypes && costingTypes.data &&
                Array.isArray(costingTypes.data)) {
                _this.costingTypes = costingTypes.data;
            }
        });
    };
    OperationQueuePanelComponent.prototype.loadLanguages = function () {
        var _this = this;
        this.languagesSubscription = this.dataStore.get(DataKey.allLanguages, true)
            .subscribe(function (data) {
            if (data && Array.isArray(data)) {
                _this.languages = data;
            }
            else if (data && data.data && Array.isArray(data.data)) {
                _this.languages = data.data;
            }
        });
    };
    OperationQueuePanelComponent.prototype.loadTransferModes = function () {
        var _this = this;
        this.trsModesSubscription = this.dataStore.get(DataKey.transferModes, true)
            .subscribe(function (data) {
            if (data && Array.isArray(data)) {
                _this.transferModes = data;
            }
            else if (data && data.data && Array.isArray(data.data)) {
                _this.transferModes = data.data;
            }
        });
    };
    OperationQueuePanelComponent.prototype.loadRoutes = function () {
        var _this = this;
        this.routesSubscription = this.dataStore.get(DataKey.routes, true)
            .subscribe(function (data) {
            if (data && Array.isArray(data)) {
                _this.routes = data;
            }
            else if (data && data.data && Array.isArray(data.data)) {
                _this.routes = data.data;
            }
        });
    };
    OperationQueuePanelComponent.prototype.subscribeProductSearchCriteria = function () {
        var _this = this;
        this.productSearchCriteriaSubscription = this.dataStore.get(DataKey.productQueueSearchCriteria, true)
            .subscribe(function (data) {
            if (data) {
                _this.showAllocationQueues = false;
                _this.productQueueSearchCriteria = data;
                _this.showLoadingProducts();
                _this.setupOperationQueueProfile(_this.productQueueSearchCriteria);
                _this.setupServiceBookingQueuesForQueueProfile();
                _this.revertChanges();
            }
        });
    };
    OperationQueuePanelComponent.prototype.showLoadingProducts = function () {
        var _this = this;
        if (this.productQueueComponent) {
            this.productQueueComponent.showLoadingOverlay();
        }
        else {
            setTimeout(function () { return _this.showLoadingProducts(); }, 0);
        }
    };
    OperationQueuePanelComponent.prototype.setupOperationQueueProfile = function (productQueueSearchCriteria) {
        if (productQueueSearchCriteria.mainType === MainType.TRS) {
            this.operationPanelConfig.activatedProfile = OperationQueueProfile.TRS_PROFILE;
        }
        else if (productQueueSearchCriteria.mainType === MainType.HTL) {
            this.operationPanelConfig.activatedProfile = OperationQueueProfile.HOTEL_PROFILE;
        }
        else if (productQueueSearchCriteria.productLevel === ProductLevel.ANY &&
            productQueueSearchCriteria.mainType === MainType.TOU) {
            this.operationPanelConfig.activatedProfile = OperationQueueProfile.TOUR_PROFILE;
        }
        else if (productQueueSearchCriteria.mainType === MainType.GEN) {
            this.operationPanelConfig.activatedProfile = OperationQueueProfile.GENERIC_PROFILE;
        }
        else {
            this.operationPanelConfig.activatedProfile = OperationQueueProfile.COMMON_PROFILE;
        }
        console.log(productQueueSearchCriteria.productLevel);
        console.log(productQueueSearchCriteria.mainType);
        console.log('Operation queue profile: ' + this.operationPanelConfig.activatedProfile);
    };
    OperationQueuePanelComponent.prototype.generateProductQueueColumnDefs = function () {
        this.productQueueColDefConfigJson = this.configLoader.configurations.get(TCO.CONF.CONF_PRODUCT_QUEUE_COL_DEF_CONFIG);
        var productQueueColDefConfigJsonClone = JSON.parse(JSON.stringify(this.productQueueColDefConfigJson));
        this.productQueueColDefConfig = this.columnDefinitionProcessorService.createColumnDefs(productQueueColDefConfigJsonClone, this);
    };
    OperationQueuePanelComponent.prototype.setupServiceBookingQueuesForQueueProfile = function () {
        if (this.operationPanelConfig.activatedProfile) {
            this.generateServiceBookingQueueConfigs(this.operationPanelConfig.activatedProfile);
        }
    };
    OperationQueuePanelComponent.prototype.generateServiceBookingQueueConfigs = function (profile) {
        var serviceQueueAllColDefConfigJsonTemp = [];
        var serviceQueueColDefConfigJsonTemp = [];
        var bookingQueueAllColDefConfigJsonTemp = [];
        var bookingQueueColDefConfigJsonTemp = [];
        var serviceQueuePrimaryColDefConfigJsonTemp = [];
        var bookingQueuePrimaryColDefConfigJsonTemp = [];
        var serviceQueueSecondaryColDefConfigJsonTemp = [];
        var bookingQueueSecondaryColDefConfigJsonTemp = [];
        var loadServicingDefaultColDefs = this.configLoader.configurations
            .get(TCO.CONF.CONF_COL_DEF_SERVICE_QUEUE_PROFILES_CONFIG)[profile].DEFAULT;
        var loadServicingPrimaryColDefs = this.configLoader.configurations
            .get(TCO.CONF.CONF_COL_DEF_SERVICE_QUEUE_PROFILES_CONFIG)[profile].PRIMARY;
        var loadServicingSecondaryColDefs = this.configLoader.configurations
            .get(TCO.CONF.CONF_COL_DEF_SERVICE_QUEUE_PROFILES_CONFIG)[profile].SECONDARY;
        var loadServicingAllColDefs = this.configLoader.configurations
            .get(TCO.CONF.CONF_COL_DEF_SERVICE_QUEUE_PROFILES_CONFIG)[profile].ALL;
        var loadBookingDefaultColDefs = this.configLoader.configurations
            .get(TCO.CONF.CONF_COL_DEF_BOOKING_QUEUE_PROFILES_CONFIG)[profile].DEFAULT;
        var loadBookingPrimaryColDefs = this.configLoader.configurations
            .get(TCO.CONF.CONF_COL_DEF_BOOKING_QUEUE_PROFILES_CONFIG)[profile].PRIMARY;
        var loadBookingSecondaryColDefs = this.configLoader.configurations
            .get(TCO.CONF.CONF_COL_DEF_BOOKING_QUEUE_PROFILES_CONFIG)[profile].SECONDARY;
        var loadBookingAllColDefs = this.configLoader.configurations
            .get(TCO.CONF.CONF_COL_DEF_BOOKING_QUEUE_PROFILES_CONFIG)[profile].ALL;
        var allBookingCols = this.configLoader.configurations.get(TCO.CONF.CONF_BOOKING_QUEUE_ALL_COL_DEF_CONFIG);
        var allServiceCols = this.configLoader.configurations.get(TCO.CONF.CONF_SERVICE_QUEUE_ALL_COL_DEF_CONFIG);
        if (allBookingCols) {
            loadBookingDefaultColDefs.forEach(function (colDef) {
                var res = allBookingCols.find(function (col) { return col.field === colDef; });
                if (res.field === colDef) {
                    bookingQueueColDefConfigJsonTemp.push(res);
                }
            });
            loadBookingPrimaryColDefs.forEach(function (colDef) {
                var res = allBookingCols.find(function (col) { return col.field === colDef; });
                if (res.field === colDef) {
                    bookingQueuePrimaryColDefConfigJsonTemp.push(res);
                }
            });
            loadBookingSecondaryColDefs.forEach(function (colDef) {
                var res = allBookingCols.find(function (col) { return col.field === colDef; });
                if (res.field === colDef) {
                    bookingQueueSecondaryColDefConfigJsonTemp.push(res);
                }
            });
            loadBookingAllColDefs.forEach(function (colDef) {
                var res = allBookingCols.find(function (col) { return col.field === colDef; });
                if (res.field === colDef) {
                    bookingQueueAllColDefConfigJsonTemp.push(res);
                }
            });
        }
        this.bookingQueueAllColDefConfigJson = bookingQueueAllColDefConfigJsonTemp;
        this.bookingQueueColDefConfigJson = bookingQueueColDefConfigJsonTemp;
        this.bookingQueuePrimaryColDefConfigJson = bookingQueuePrimaryColDefConfigJsonTemp;
        this.bookingQueueSecondaryColDefConfigJson = bookingQueueSecondaryColDefConfigJsonTemp;
        var bookingQueueAllColDefConfigJsonClone = JSON.parse(JSON.stringify(this.bookingQueueAllColDefConfigJson));
        var bookingQueueColDefConfigJsonClone = JSON.parse(JSON.stringify(this.bookingQueueColDefConfigJson));
        var bookingQueuePrimaryColDefConfigJsonClone = JSON.parse(JSON.stringify(this.bookingQueuePrimaryColDefConfigJson));
        var bookingQueueSecondaryColDefConfigJsonClone = JSON.parse(JSON.stringify(this.bookingQueueSecondaryColDefConfigJson));
        this.bookingQueueAllColDefConfig = this.columnDefinitionProcessorService.createColumnDefs(bookingQueueAllColDefConfigJsonClone, this);
        this.bookingQueueColDefConfig = this.columnDefinitionProcessorService.createColumnDefs(bookingQueueColDefConfigJsonClone, this);
        this.bookingQueuePrimaryColDefConfig = this.columnDefinitionProcessorService.createColumnDefs(bookingQueuePrimaryColDefConfigJsonClone, this);
        this.bookingQueueSecondaryColDefConfig = this.columnDefinitionProcessorService.createColumnDefs(bookingQueueSecondaryColDefConfigJsonClone, this);
        if (allServiceCols) {
            loadServicingDefaultColDefs.forEach(function (colDef) {
                var res = allServiceCols.find(function (col) { return col.field === colDef; });
                if (res.field === colDef) {
                    serviceQueueColDefConfigJsonTemp.push(res);
                }
            });
            loadServicingPrimaryColDefs.forEach(function (colDef) {
                var res = allServiceCols.find(function (col) { return col.field === colDef; });
                if (res.field === colDef) {
                    serviceQueuePrimaryColDefConfigJsonTemp.push(res);
                }
            });
            loadServicingSecondaryColDefs.forEach(function (colDef) {
                var res = allServiceCols.find(function (col) { return col.field === colDef; });
                if (res.field === colDef) {
                    serviceQueueSecondaryColDefConfigJsonTemp.push(res);
                }
            });
            loadServicingAllColDefs.forEach(function (colDef) {
                var res = allServiceCols.find(function (col) { return col.field === colDef; });
                if (res.field === colDef) {
                    serviceQueueAllColDefConfigJsonTemp.push(res);
                }
            });
        }
        this.serviceQueueAllColDefConfigJson = serviceQueueAllColDefConfigJsonTemp;
        this.serviceQueueColDefConfigJson = serviceQueueColDefConfigJsonTemp;
        this.serviceQueuePrimaryColDefConfigJson = serviceQueuePrimaryColDefConfigJsonTemp;
        this.serviceQueueSecondaryColDefConfigJson = serviceQueueSecondaryColDefConfigJsonTemp;
        var serviceQueueAllColDefConfigJsonClone = JSON.parse(JSON.stringify(this.serviceQueueAllColDefConfigJson));
        var serviceQueueColDefConfigJsonClone = JSON.parse(JSON.stringify(this.serviceQueueColDefConfigJson));
        var serviceQueuePrimaryColDefConfigJsonClone = JSON.parse(JSON.stringify(this.serviceQueuePrimaryColDefConfigJson));
        var serviceQueueSecondaryColDefConfigJsonClone = JSON.parse(JSON.stringify(this.serviceQueueSecondaryColDefConfigJson));
        this.serviceQueueAllColDefConfig = this.columnDefinitionProcessorService.createColumnDefs(serviceQueueAllColDefConfigJsonClone, this);
        this.serviceQueueColDefConfig = this.columnDefinitionProcessorService.createColumnDefs(serviceQueueColDefConfigJsonClone, this);
        this.serviceQueuePrimaryColDefConfig = this.columnDefinitionProcessorService.createColumnDefs(serviceQueuePrimaryColDefConfigJsonClone, this);
        this.serviceQueueSecondaryColDefConfig = this.columnDefinitionProcessorService.createColumnDefs(serviceQueueSecondaryColDefConfigJsonClone, this);
        console.log('set config of service queue for ' + profile);
    };
    OperationQueuePanelComponent.prototype.isTimeGroupingVisible = function () {
        return (this.operationPanelConfig.currentTab === 0) &&
            (this.operationPanelConfig.activatedProfile === OperationQueueProfile.TRS_PROFILE);
    };
    OperationQueuePanelComponent.prototype.setAllProducts = function (event) {
        var _this = this;
        this.allBookingIdList = [];
        event.forEach(function (o) {
            var _a;
            (_a = _this.allBookingIdList).push.apply(_a, tslib_1.__spread(o.bookingIdList));
        });
    };
    OperationQueuePanelComponent.prototype.onProductSelectionChange = function (event) {
        var _this = this;
        // collecting the bookingIds
        this.selectedBookingIdList = [];
        event.forEach(function (o) {
            _this.selectedBookingIdList.push(o.bookingIdList);
        });
        if (event && event.length > 0) {
            this.showAllocationQueues = true;
        }
        else {
            this.showAllocationQueues = false;
        }
        this.selectedProducts = event;
        this.cd.detectChanges();
        if (this.operationPanelConfig.currentTab === 0) {
            if (this.serviceQueueComponent) {
                this.serviceQueueComponent.loadData();
            }
        }
        else if (this.operationPanelConfig.currentTab === 1) {
            if (this.bookingQueueComponent) {
                this.bookingQueueComponent.loadData();
            }
        }
    };
    OperationQueuePanelComponent.prototype.onTabChange = function (event) {
        this.operationPanelConfig.currentTab = event.index;
    };
    OperationQueuePanelComponent.prototype.isServiceQueue = function () {
        return this.operationPanelConfig && this.operationPanelConfig.currentTab === 0;
    };
    OperationQueuePanelComponent.prototype.isBookingQueue = function () {
        return this.operationPanelConfig && this.operationPanelConfig.currentTab === 1;
    };
    OperationQueuePanelComponent.prototype.isPrimaryAssignmentRestricted = function (params) {
        var item = params.data;
        return !(this.isEditableType(item, true));
    };
    OperationQueuePanelComponent.prototype.isEditTimeRestricted = function (params) {
        var item = params.data;
        return !(this.hasNoAnyAssignment(params));
    };
    OperationQueuePanelComponent.prototype.hasPassengerAttributes = function (row) {
        var e_18, _a;
        try {
            for (var _b = tslib_1.__values(row.bookingPassengers), _c = _b.next(); !_c.done; _c = _b.next()) {
                var bookingPassenger = _c.value;
                if (bookingPassenger.passengerAttributes && bookingPassenger.passengerAttributes.length) {
                    return true;
                }
            }
        }
        catch (e_18_1) { e_18 = { error: e_18_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_18) throw e_18.error; }
        }
        return false;
    };
    OperationQueuePanelComponent.prototype.getPassengerAttributesToolTip = function (row) {
        var e_19, _a, e_20, _b, e_21, _c;
        var attributes = [];
        try {
            for (var _d = tslib_1.__values(row.bookingPassengers), _e = _d.next(); !_e.done; _e = _d.next()) {
                var bookingPassenger = _e.value;
                if (bookingPassenger.passengerAttributes) {
                    var _loop_1 = function (passengerAttribute) {
                        var passengerAttributeFound = attributes.find(function (value) { return value.name === passengerAttribute.name &&
                            value.value ===
                                passengerAttribute.value; });
                        if (!passengerAttributeFound) {
                            attributes.push(passengerAttribute);
                        }
                    };
                    try {
                        for (var _f = tslib_1.__values(bookingPassenger.passengerAttributes), _g = _f.next(); !_g.done; _g = _f.next()) {
                            var passengerAttribute = _g.value;
                            _loop_1(passengerAttribute);
                        }
                    }
                    catch (e_20_1) { e_20 = { error: e_20_1 }; }
                    finally {
                        try {
                            if (_g && !_g.done && (_b = _f.return)) _b.call(_f);
                        }
                        finally { if (e_20) throw e_20.error; }
                    }
                }
            }
        }
        catch (e_19_1) { e_19 = { error: e_19_1 }; }
        finally {
            try {
                if (_e && !_e.done && (_a = _d.return)) _a.call(_d);
            }
            finally { if (e_19) throw e_19.error; }
        }
        var text = "Passenger Attributes: ";
        if (attributes.length) {
            try {
                for (var attributes_1 = tslib_1.__values(attributes), attributes_1_1 = attributes_1.next(); !attributes_1_1.done; attributes_1_1 = attributes_1.next()) {
                    var passengerAttribute = attributes_1_1.value;
                    text += passengerAttribute.name + '=' + passengerAttribute.value + '  ';
                }
            }
            catch (e_21_1) { e_21 = { error: e_21_1 }; }
            finally {
                try {
                    if (attributes_1_1 && !attributes_1_1.done && (_c = attributes_1.return)) _c.call(attributes_1);
                }
                finally { if (e_21) throw e_21.error; }
            }
        }
        return text;
    };
    OperationQueuePanelComponent.prototype.onPrimaryAssignmentClick = function (params) {
        var queueType;
        var bookingIdList = [];
        if (this.operationPanelConfig && this.operationPanelConfig.currentTab === 0) {
            console.log('service queue 1: ' + params.data);
            queueType = QueueType.SERVICE;
            var serviceItem = params.data;
            if (serviceItem && serviceItem.bookingItemSummaryList) {
                serviceItem.bookingItemSummaryList.forEach(function (value) { return bookingIdList.push(value.bookingItemId); });
            }
        }
        else {
            queueType = QueueType.BOOKING;
            console.log('booking queue 1: ' + params.data);
            bookingIdList.push(params.data.id);
        }
        if (params && params.data && (!params.data.startTime || !params.data.endTime)) {
            this.onTimeRangeChangeClick(params, bookingIdList, true, true, false);
        }
        else {
            this.openAllocationFocusView(params.data, bookingIdList, true, false);
        }
    };
    OperationQueuePanelComponent.prototype.markChanges = function () {
        this.operationPanelConfig.preventSwitching = true;
        this.enableSaveButton();
        this.enableConfirmButton();
    };
    OperationQueuePanelComponent.prototype.revertChanges = function () {
        this.rawAssignmentTempId = 1;
        this.operationPanelConfig.preventSwitching = false;
        this.revertButtonEnable = false;
        this.disableSaveButton();
    };
    OperationQueuePanelComponent.prototype.isSecondaryAssignmentRestricted = function (params) {
        var item = params.data;
        return !(this.isEditableType(item, false));
    };
    OperationQueuePanelComponent.prototype.isEditableType = function (item, isPrimary) {
        if (isPrimary) {
            return (item && ((item.primaryAssignment && item.primaryAssignment.assignStatus &&
                (item.primaryAssignment.assignStatus === AssignStatus.PENDING || item.primaryAssignment.assignStatus ===
                    AssignStatus.BOOKING_UPDATE || item.primaryAssignment.assignStatus ===
                    AssignStatus.REQUEST_EXPIRED ||
                    item.primaryAssignment.assignStatus === AssignStatus.SUPPLIER_REJECTED)) || !item.primaryAssignment));
        }
        else {
            return (item && item.primaryAssignment &&
                ((item.secondaryAssignment && item.secondaryAssignment.assignStatus &&
                    (item.secondaryAssignment.assignStatus === AssignStatus.PENDING ||
                        item.secondaryAssignment.assignStatus ===
                            AssignStatus.BOOKING_UPDATE || item.secondaryAssignment.assignStatus ===
                        AssignStatus.REQUEST_EXPIRED ||
                        item.secondaryAssignment.assignStatus === AssignStatus.SUPPLIER_REJECTED)) || !item.secondaryAssignment));
        }
    };
    OperationQueuePanelComponent.prototype.onSecondaryAssignmentClick = function (params) {
        var bookingIdList = [];
        if (this.operationPanelConfig && this.operationPanelConfig.currentTab === 0) {
            var serviceItem = params.data;
            if (serviceItem && serviceItem.bookingItemSummaryList) {
                serviceItem.bookingItemSummaryList.forEach(function (value) { return bookingIdList.push(value.bookingItemId); });
            }
        }
        else {
            bookingIdList.push(params.data.id);
        }
        if (params && params.data && (!params.data.startTime || !params.data.endTime)) {
            this.onTimeRangeChangeClick(params, bookingIdList, true, true, false);
        }
        else {
            this.openAllocationFocusView(params.data, bookingIdList, false, true);
        }
    };
    OperationQueuePanelComponent.prototype.onTimeRangeChangeClick = function (params, bookingIdList, fromAssignmentClick, isPrimary, isSecondary) {
        var _this = this;
        if (bookingIdList === void 0) { bookingIdList = []; }
        if (fromAssignmentClick === void 0) { fromAssignmentClick = false; }
        if (isPrimary === void 0) { isPrimary = true; }
        if (isSecondary === void 0) { isSecondary = false; }
        var e_22, _a;
        var item = params.data;
        if (!item.startTime && item.trsBookingItem) {
            // moment(this.itemList[0].trsBookingItem.departureDateTime).format('HH:mm');
            item.startTime = new Date(item.trsBookingItem.departureDateTime);
            // moment(this.itemList[0].trsBookingItem.arrivalDateTime).format('HH:mm');
            // item.endTime = new Date(item.trsBookingItem.arrivalDateTime);
        }
        else {
            item.startTime = new Date(item.serviceDate); // moment(this.itemList[0].serviceDate).format('HH:mm');
        }
        this.dataStore.set(DataKey.popupClose, null, true);
        this.dataStore.set(DataKey.ResourceAllocationTimeRange, null, true);
        var bookingIds = [];
        if (this.isBookingQueue()) {
            var bookingItem = params.data;
            bookingIds.push(bookingItem.id);
        }
        else {
            var serviceItem = params.data;
            if (serviceItem.bookingItemSummaryList != null) {
                try {
                    for (var _b = tslib_1.__values(serviceItem.bookingItemSummaryList), _c = _b.next(); !_c.done; _c = _b.next()) {
                        var bookingItemSummary = _c.value;
                        bookingIds.push(bookingItemSummary.bookingItemId);
                    }
                }
                catch (e_22_1) { e_22 = { error: e_22_1 }; }
                finally {
                    try {
                        if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                    }
                    finally { if (e_22) throw e_22.error; }
                }
            }
        }
        var dataObject = new ModalData(LEVEL.SUCCESS, 'Edit Time Range', null, null, 'modal-basic--medium modal-basic--no-footer', EditTimeRangeComponent, {
            selectedResource: this.selectedResource,
            jobStartTime: item.startTime,
            jobEndTime: item.endTime,
            bookingIds: bookingIds
        });
        this.subscribeForDateRange(params, fromAssignmentClick, bookingIdList, isPrimary, isSecondary);
        this.confirmModal(dataObject);
        if (this.popupSubscription) {
            this.popupSubscription.unsubscribe();
        }
        this.popupSubscription = this.dataStore.get(DataKey.popupClose).subscribe(function (data) {
            if (data != null) {
                _this.closeModal();
            }
        });
    };
    OperationQueuePanelComponent.prototype.onSelectPassengersClick = function (params) {
        var _this = this;
        var bookingItemSearchCriteria = [];
        if (this.isBookingQueue()) {
            var bookingItem = params.data;
            bookingItemSearchCriteria.push({
                bookingItemId: bookingItem.id,
                bookingReferenceId: bookingItem.bookingReferenceId
            });
        }
        else {
            var serviceItem = params.data;
            serviceItem.bookingItemSummaryList.forEach(function (bookingItem) {
                bookingItemSearchCriteria.push({
                    bookingItemId: bookingItem.bookingItemId,
                    bookingReferenceId: bookingItem.bookingReferenceId
                });
            });
        }
        if (this.popupSubscription) {
            this.popupSubscription.unsubscribe();
        }
        this.dataStore.set(DataKey.popupClose, null);
        this.confirmModal(new ModalData(LEVEL.SUCCESS, 'Passengers', null, null, 'modal-basic--alt modal-basic--fixed-size', PassengerEditorComponent, {
            selectedItem: params.data,
            bookingPassengerGroup: this.getBookingPassengerGroup(bookingItemSearchCriteria),
            // passengerGroupList: bookingPassengerGroups,
            isBookingQueue: this.isBookingQueue()
        }));
        this.popupSubscription = this.dataStore.get(DataKey.popupClose).subscribe(function (data) {
            if (data != null) {
                _this.closeModal();
                _this.popupSubscription.unsubscribe();
                if (data.length) {
                    if (_this.isBookingQueue()) {
                        var oldBookingIdList_1 = [];
                        var newBookingIdList_1 = [];
                        data.forEach(function (o) {
                            if (o.parentBookingId !== null && o.parentBookingId !== 0 &&
                                !oldBookingIdList_1.includes(o.parentBookingId)) {
                                oldBookingIdList_1.push(o.parentBookingId);
                            }
                            newBookingIdList_1.push(o.id);
                        });
                        _this.productQueueComponent.updateSearchChipsAfterSplit(oldBookingIdList_1, newBookingIdList_1);
                        _this.bookingQueueComponent.replaceItem(params.data, data);
                    }
                    else {
                        var oldBookingIdList = params.data.bookingItemSummaryList.map(function (o) { return o.bookingItemId; });
                        var newBookingIdList_2 = [];
                        data.forEach(function (item) {
                            item.bookingItemSummaryList.forEach(function (o) {
                                if (!newBookingIdList_2.includes(o.bookingItemId)) {
                                    newBookingIdList_2.push(o.bookingItemId);
                                }
                            });
                        });
                        _this.productQueueComponent.updateSearchChipsAfterSplit(oldBookingIdList, newBookingIdList_2);
                        _this.serviceQueueComponent.replaceItem(params.data, data);
                    }
                }
            }
        });
    };
    OperationQueuePanelComponent.prototype.getBookingPassengerGroup = function (basicCriteriaDetails) {
        var _this = this;
        var passengerGroups = [];
        basicCriteriaDetails.forEach(function (detail) {
            var tempPassengerGroup = new BookingPassengerGroup();
            var totalPassenger = 0;
            var bookingSearchCriteria = new BookingItemSearchCriteria();
            bookingSearchCriteria.bookingIdList = [detail.bookingItemId];
            _this.reservationV2ManagementService.searchPassengers(bookingSearchCriteria)
                .subscribe(function (data) {
                if (data) {
                    totalPassenger = ResponseUtil.getTotalCount(data);
                    tempPassengerGroup.bookingPassengers = ResponseUtil.getDataArray(data);
                    tempPassengerGroup.id = detail.bookingItemId;
                    tempPassengerGroup.bookingReferenceId = detail.bookingReferenceId;
                }
            });
            passengerGroups.push(tempPassengerGroup);
        });
        return passengerGroups;
    };
    OperationQueuePanelComponent.prototype.onNotesClick = function (params) {
        var _this = this;
        var bookingItemSearchCriteria = [];
        var primaryJobNotes = [];
        var bookingNotesOnly = false;
        if (this.isBookingQueue()) {
            var bookingItem = params.data;
            if (bookingItem.primaryAssignment) {
                if (bookingItem.primaryAssignment.assignmentNotes) {
                    primaryJobNotes.push.apply(primaryJobNotes, tslib_1.__spread(bookingItem.primaryAssignment.assignmentNotes));
                }
            }
            else {
                bookingNotesOnly = true;
            }
            bookingItemSearchCriteria.push({
                bookingItemId: bookingItem.id,
                bookingReferenceId: bookingItem.bookingReferenceId
            });
        }
        else {
            var serviceItem = params.data;
            if (serviceItem.primaryAssignment) {
                if (serviceItem.primaryAssignment.assignmentNotes) {
                    primaryJobNotes.push.apply(primaryJobNotes, tslib_1.__spread(serviceItem.primaryAssignment.assignmentNotes));
                }
            }
            else {
                bookingNotesOnly = true;
            }
            serviceItem.bookingItemSummaryList.forEach(function (summary) {
                bookingItemSearchCriteria.push({
                    bookingItemId: summary.bookingItemId,
                    bookingReferenceId: summary.bookingReferenceId
                });
            });
        }
        var clonedNotesArray = JSON.parse(JSON.stringify(primaryJobNotes));
        if (this.popupSubscription) {
            this.popupSubscription.unsubscribe();
        }
        this.dataStore.set(DataKey.popupClose, null);
        var dataObject = new ModalData(LEVEL.SUCCESS, 'Notes', null, null, 'modal-basic--alt modal-basic--fixed-size', NotesEditorComponent, {
            assignmentNotes: clonedNotesArray,
            bookingNoteGroups: this.getBookingNoteGroups(bookingItemSearchCriteria),
            bookingNotesOnly: bookingNotesOnly
        });
        this.confirmModal(dataObject);
        this.popupSubscription = this.dataStore.get(DataKey.popupClose).subscribe(function (data) {
            if (data != null) {
                _this.closeModal();
                if (data) {
                    _this.setNotesToItems(clonedNotesArray, params.data);
                }
            }
        });
    };
    OperationQueuePanelComponent.prototype.getBookingNoteGroups = function (basicCriteriaDetails) {
        var _this = this;
        var noteGroups = [];
        basicCriteriaDetails.forEach(function (detail) {
            var tempNoteGroup = new BookingNoteGroup();
            var bookingSearchCriteria = new BookingItemSearchCriteria();
            bookingSearchCriteria.bookingIdList = [detail.bookingItemId];
            _this.reservationV2ManagementService.searchBookingNotes(bookingSearchCriteria)
                .subscribe(function (results) {
                if (results) {
                    tempNoteGroup.bookingNotes = ResponseUtil.getDataArray(results);
                    tempNoteGroup.bookingReferenceId = detail.bookingReferenceId;
                }
            });
            noteGroups.push(tempNoteGroup);
        });
        return noteGroups;
    };
    OperationQueuePanelComponent.prototype.onSupplementsClick = function (params) {
        var _this = this;
        var bookingItemSearchCriteria = [];
        var bookingNotesOnly = false;
        if (this.isBookingQueue()) {
            var bookingItem = params.data;
            bookingItemSearchCriteria.push({
                bookingItemId: bookingItem.id,
                bookingReferenceId: bookingItem.bookingReferenceId
            });
        }
        else {
            var serviceItem = params.data;
            serviceItem.bookingItemSummaryList.forEach(function (summary) {
                bookingItemSearchCriteria.push({
                    bookingItemId: summary.bookingItemId,
                    bookingReferenceId: summary.bookingReferenceId
                });
            });
        }
        if (this.popupSubscription) {
            this.popupSubscription.unsubscribe();
        }
        this.dataStore.set(DataKey.popupClose, null);
        var dataObject = new ModalData(LEVEL.SUCCESS, 'Supplements', null, null, 'modal-basic--alt modal-basic--fixed-size', SupplementsEditorComponent, {
            selectedItem: params.data,
            supplementGroupList: this.getBookingSupplementGroups(bookingItemSearchCriteria),
            isBookingQueue: bookingNotesOnly
        });
        this.confirmModal(dataObject);
        this.popupSubscription = this.dataStore.get(DataKey.popupClose).subscribe(function (data) {
            if (data != null) {
                _this.closeModal();
            }
        });
    };
    OperationQueuePanelComponent.prototype.getBookingSupplementGroups = function (basicCriteriaDetails) {
        var _this = this;
        var supplementGroups = [];
        basicCriteriaDetails.forEach(function (detail) {
            var tempSupplementGroup = new BookingSupplementGroup();
            var bookingSearchCriteria = new BookingItemSearchCriteria();
            bookingSearchCriteria.bookingIdList = [detail.bookingItemId];
            _this.reservationV2ManagementService.searchSupplementNotes(bookingSearchCriteria)
                .subscribe(function (data) {
                if (data) {
                    tempSupplementGroup.supplementNotes = ResponseUtil.getDataArray(data);
                    tempSupplementGroup.bookingReferenceId = detail.bookingReferenceId;
                }
            });
            supplementGroups.push(tempSupplementGroup);
        });
        return supplementGroups;
    };
    OperationQueuePanelComponent.prototype.setNotesToItems = function (notes, item) {
        if (item.primaryAssignment) {
            item.primaryAssignment.assignmentNotes = notes;
        }
        if (item.secondaryAssignment) {
            item.secondaryAssignment.assignmentNotes = notes;
        }
    };
    OperationQueuePanelComponent.prototype.confirmModal = function (data) {
        if (this.modelConfirmSubscription) {
            this.modelConfirmSubscription.unsubscribe();
        }
        this.modelConfirmSubscription = this.modalService.confirm(data).subscribe(function (res) {
        });
    };
    OperationQueuePanelComponent.prototype.closeModal = function () {
        if (this.modelCloseSubscription) {
            this.modelCloseSubscription.unsubscribe();
        }
        if (this.modalService.close()) {
            this.modelCloseSubscription = this.modalService.close().subscribe(function (res) {
            });
        }
    };
    OperationQueuePanelComponent.prototype.onShowCostBreakDownClick = function (params) {
    };
    OperationQueuePanelComponent.prototype.isAssignmentsIncomplete = function (row) {
        // return row.primaryAssignment && (!row.primaryAssignment.startTime || !row.primaryAssignment.endTime);
        return false;
    };
    OperationQueuePanelComponent.prototype.isConfirm = function (row) {
        return row && row.bookingSourceStatus === 'CONFIRM';
    };
    OperationQueuePanelComponent.prototype.isOnRequest = function (row) {
        return row && row.bookingSourceStatus === 'ON_REQUEST';
    };
    OperationQueuePanelComponent.prototype.isManifestStatusNOT_SENT = function (row) {
        return this.showManifestStatusAllocQueue && row && row.manifestStatus === 'NOT_SENT';
    };
    OperationQueuePanelComponent.prototype.isManifestStatusSENT = function (row) {
        return this.showManifestStatusAllocQueue && row && row.manifestStatus === 'SENT_A';
    };
    OperationQueuePanelComponent.prototype.isManifestStatusUPDATED = function (row) {
        return this.showManifestStatusAllocQueue && row && row.manifestStatus === 'UPDATED';
    };
    OperationQueuePanelComponent.prototype.isManifestStatusNEW = function (row) {
        return this.showManifestStatusAllocQueue && row && (row.manifestStatus === 'NEW'
            || row.manifestStatus === 'SENT_AM' || row.manifestStatus === 'SENT_M' || row.manifestStatus ===
            'UPDATED_M');
    };
    OperationQueuePanelComponent.prototype.isManifestStatusNONE = function (row) {
        return this.showManifestStatusAllocQueue && row && row.manifestStatus === 'NONE';
    };
    OperationQueuePanelComponent.prototype.getAssignmentsIncompleteMsg = function (row) {
        if (row && row.primaryAssignment) {
            if (!row.primaryAssignment.startTime) {
                return 'Start time is not added';
            }
            else if (!row.primaryAssignment.endTime) {
                return 'End time is not added';
            }
        }
        return 'Assignment is incomplete';
    };
    OperationQueuePanelComponent.prototype.getStatusToolTip = function (row) {
        if (row && row.bookingSourceStatus === 'CONFIRM') {
            return 'Booking Status: Confirm';
        }
        else if (row.bookingSourceStatus === 'ON_REQUEST') {
            return 'Booking Status: On Request';
        }
    };
    OperationQueuePanelComponent.prototype.getManifestStatusToolTip = function (row) {
        if (row.manifestStatus === 'SENT_A') {
            return 'Manifest Status: SENT';
        }
        else if (row.manifestStatus === 'SENT_AM' || row.manifestStatus === 'SENT_M' || row.manifestStatus ===
            'UPDATED_M') {
            return 'Manifest Status: NEW';
        }
        else if (row.manifestStatus === 'UPDATED') {
            return 'Manifest Status: UPDATED';
        }
        else if (row.manifestStatus === 'NOT_SENT') {
            return 'Manifest Status: NOT_SENT';
        }
        else {
            return 'Manifest Status: ' + row.manifestStatus;
        }
    };
    OperationQueuePanelComponent.prototype.openAllocationFocusView = function (data, bookingIdList, isPrimary, isSecondary) {
        var _this = this;
        this.dataStore.set(DataKey.selectedResourceForAllocation, null, true);
        this.dataStore.set(DataKey.selectedSupplierForAllocation, null, true);
        this.itemList = [];
        this.itemList.push(data);
        var serviceDate = data.serviceDate.toString().split('T')[0];
        this.operationPanelConfig.preventSwitching = !this.operationPanelConfig.preventSwitching;
        setTimeout(function () {
            var fabActions = [];
            var navItemsArray = _this.configLoader.configurations.get(TCO.CONF.CONF_NAVIGATION);
            _this.configLoader.configurations.get(TCO.CONF.CONF_FOCUS_VIEW).ALLOCATION_FOCUS_VIEW.fabAction
                .forEach(function (val) {
                for (var key in navItemsArray) {
                    if (val === navItemsArray[key].id) {
                        fabActions.push(navItemsArray[key]);
                    }
                }
            });
            var costingType = data.costingType && data.costingType.name ? data.costingType.name : '--';
            var noOfUnits = data.noOfUnits ? data.noOfUnits : '--';
            var dataObject = new ModalData(LEVEL.SUCCESS, 'Assign Supplier/Resource', null, null, '', AllocationFocusViewComponent, {
                allocationTimeRange: _this.allocationTimeRange,
                startTime: data.startTime,
                endTime: data.endTime,
                itemList: _this.itemList,
                isPrimary: isPrimary,
                isSecondary: isSecondary,
                bookingIdList: bookingIdList
            }, { label: 'close' }, '', '', fabActions, '', 'Pax: ' + (data.adultCount + data.childrenCount + data.teenCount + data.infantCount) + ' | ' +
                moment(new Date(data.startTime)).format('HH:mm') + ' - ' +
                moment(new Date(data.endTime)).format('HH:mm') +
                ' | Costing Unit:' + costingType + ' | No of units:' + noOfUnits);
            if (_this.focusViewCloseSubject) {
            }
            _this.focusViewCloseSubject = _this.dataStore.get(DataKey.resourceAllocationFocusViewClose)
                .subscribe(function (dataS) {
                if (dataS != null) {
                    _this.focusViewCloseSubject.unsubscribe();
                    _this.closeFocusView();
                }
            });
            if (_this.focusViewConfirmSubject) {
                _this.focusViewConfirmSubject.unsubscribe();
            }
            _this.focusViewConfirmSubject = _this.focusViewService.confirm(dataObject).subscribe(function (res) {
                if (res) {
                    var e = document.getElementsByClassName('header-content__secondary-heading');
                    console.log(e);
                }
            });
        }, 0);
    };
    OperationQueuePanelComponent.prototype.closeFocusView = function () {
        if (this.focusViewService.close()) {
            this.focusViewCloseSubject = this.focusViewService.close().subscribe(function (res) {
            });
        }
    };
    OperationQueuePanelComponent.prototype.setSupplierToItems = function (items, assignment, supplier, primary, secondary, fromPopup) {
        if (fromPopup === void 0) { fromPopup = false; }
        assignment.temporaryAssignmentId = this.rawAssignmentTempId++;
        assignment.supplierId = supplier.supplierId;
        assignment.supplierCode = supplier.supplierCode;
        assignment.supplierName = supplier.supplierName;
        assignment.resourceType = supplier.serviceType;
        assignment.assignStatus = AssignStatus.PENDING;
        var locGroups;
        if (items && items[0]) {
            assignment.startTime = items[0].startTime;
            assignment.endTime = items[0].endTime;
            if (items[0].trsBookingItem) {
                assignment.route = items[0].trsBookingItem.route;
                assignment.startLocationGroup = items[0].trsBookingItem.pickupPointGroup;
                assignment.endLocationGroup = items[0].trsBookingItem.dropOffPointGroup;
                if (assignment.route) {
                    locGroups = assignment.route.split('-');
                }
                if (locGroups.length > 1 && !assignment.startLocationGroup) {
                    assignment.startLocationGroup = locGroups[0];
                }
                if (locGroups.length > 1 && !assignment.endLocationGroup) {
                    assignment.endLocationGroup = locGroups[1];
                }
            }
            assignment.calculatedCost = supplier.calculatedCost;
            if (supplier.trsResourceCostSummaries && supplier.trsResourceCostSummaries.length) {
                assignment.trsResourceCost = supplier.trsResourceCostSummaries.find(function (cost) { return cost.selected; });
                if (!assignment.trsResourceCost) {
                    assignment.trsResourceCost = supplier.trsResourceCostSummaries[0];
                }
            }
            else {
                assignment.genResourceCost = supplier.genResourceCostSummaries.find(function (cost) { return cost.selected; });
                if (!assignment.genResourceCost) {
                    assignment.genResourceCost = supplier.genResourceCostSummaries[0];
                }
                assignment.costingType = assignment.genResourceCost.costingType;
            }
            if (primary) {
                assignment.supplierObj = supplier;
            }
            else if (secondary) {
                assignment.supplierObj = supplier;
            }
            assignment.noOfUnits = items.length === 1 && items[0].noOfUnits ?
                items[0].noOfUnits :
                assignment.calculatedCost.noOfUnits;
            assignment.costingType = items.length === 1 && items[0].costingType ?
                items[0].costingType :
                assignment.calculatedCost.costingType;
            this.setAssignmentToItems(items, assignment, primary, secondary, fromPopup);
        }
    };
    OperationQueuePanelComponent.prototype.removeResourceFromSecondaryItems = function (items, primary, secondary) {
        var e_23, _a;
        if (secondary) {
            try {
                for (var items_1 = tslib_1.__values(items), items_1_1 = items_1.next(); !items_1_1.done; items_1_1 = items_1.next()) {
                    var item = items_1_1.value;
                    if (item.secondaryAssignment) {
                        item.secondaryAssignment.resourceId = undefined;
                        item.secondaryAssignment.resourceName = undefined;
                    }
                }
            }
            catch (e_23_1) { e_23 = { error: e_23_1 }; }
            finally {
                try {
                    if (items_1_1 && !items_1_1.done && (_a = items_1.return)) _a.call(items_1);
                }
                finally { if (e_23) throw e_23.error; }
            }
        }
    };
    OperationQueuePanelComponent.prototype.setAssignmentToItems = function (items, assignment, primary, secondary, fromPopup) {
        if (fromPopup === void 0) { fromPopup = false; }
        var e_24, _a, e_25, _b;
        this.markChanges();
        var totalPaxMappings = [];
        var removedItems = [];
        var plCodes = [];
        var plNames = [];
        var routes = [];
        var serviceDates = [];
        try {
            for (var items_2 = tslib_1.__values(items), items_2_1 = items_2.next(); !items_2_1.done; items_2_1 = items_2.next()) {
                var item = items_2_1.value;
                if (primary) {
                    if (item.primaryAssignment) {
                        removedItems.push(this.removeItemPassengersFromAssignment(item, item.primaryAssignment));
                    }
                    item.primaryAssignment = assignment;
                    if (item.secondaryAssignment) {
                        item.secondaryAssignment.parentAssignmentId = item.primaryAssignment.assignmentId;
                        item.secondaryAssignment.temporaryParentAssignmentId = item.primaryAssignment.temporaryAssignmentId;
                    }
                }
                else if (secondary) {
                    if (item.secondaryAssignment) {
                        removedItems.push(this.removeItemPassengersFromAssignment(item, item.secondaryAssignment));
                    }
                    item.secondaryAssignment = assignment;
                    // if primary assignment is available set it as parent
                    if (item.primaryAssignment) {
                        item.secondaryAssignment.parentAssignmentId = item.primaryAssignment.assignmentId;
                        item.secondaryAssignment.temporaryParentAssignmentId = item.primaryAssignment.temporaryAssignmentId;
                    }
                }
                plCodes.push(item.plCode);
                plNames.push(item.plName);
                serviceDates.push(item.serviceDate);
                if (item.trsBookingItem) {
                    routes.push(item.trsBookingItem.route);
                }
                if (item && item.bookingPassengers) {
                    var bookingPaxMappingsOfItem = item.bookingPassengers.map(function (p) {
                        var bookingPaxMapping = new BookingPaxMapping();
                        bookingPaxMapping.passengerId = p.id;
                        bookingPaxMapping.bookingId = p.bookingId;
                        bookingPaxMapping.type = p.type;
                        return bookingPaxMapping;
                    });
                    totalPaxMappings.push.apply(totalPaxMappings, tslib_1.__spread(bookingPaxMappingsOfItem));
                }
            }
        }
        catch (e_24_1) { e_24 = { error: e_24_1 }; }
        finally {
            try {
                if (items_2_1 && !items_2_1.done && (_a = items_2.return)) _a.call(items_2);
            }
            finally { if (e_24) throw e_24.error; }
        }
        try {
            for (var removedItems_1 = tslib_1.__values(removedItems), removedItems_1_1 = removedItems_1.next(); !removedItems_1_1.done; removedItems_1_1 = removedItems_1.next()) {
                var removedItem = removedItems_1_1.value;
                this.fixAssignmentCostAndCountsForNewPassengerCount(removedItem, primary, secondary);
            }
        }
        catch (e_25_1) { e_25 = { error: e_25_1 }; }
        finally {
            try {
                if (removedItems_1_1 && !removedItems_1_1.done && (_b = removedItems_1.return)) _b.call(removedItems_1);
            }
            finally { if (e_25) throw e_25.error; }
        }
        if (primary) {
            assignment.serviceLevel = ServiceLevel.PRIMARY;
        }
        else if (secondary) {
            assignment.serviceLevel = ServiceLevel.SECONDARY;
        }
        assignment.sharedAssign = !(items.length === 1 && items[0].pvtShared === ServiceSharing.PVT);
        if (plCodes.length && plCodes.every(function (v, i, arr) { return v === arr[0]; })) {
            assignment.plCode = plCodes[0];
            if (plNames.length) {
                assignment.plName = plNames[0];
            }
        }
        var minDate = null;
        if (serviceDates.length) {
            minDate = new Date(Math.min.apply(null, serviceDates));
        }
        if (!assignment.jobDate) {
            assignment.jobDate = minDate;
        }
        if (!assignment.startTime) {
            assignment.startTime = minDate;
        }
        if (!assignment.startLocationGroup && !assignment.endLocationGroup && routes.length) {
            if (routes.every(function (v, i, arr) { return v === arr[0]; })) {
                var locGroups = routes[0].split('-');
                if (locGroups.length > 1) {
                    assignment.startLocationGroup = locGroups[0];
                    assignment.endLocationGroup = locGroups[1];
                }
            }
        }
        if (assignment.genResourceCost) {
            assignment.resourceType = assignment.genResourceCost.resourceType;
            assignment.profileType = assignment.genResourceCost.profileType;
        }
        else if (assignment.trsResourceCost) {
            assignment.resourceType = 'vehicle';
        }
        assignment.bookingPaxMappings = totalPaxMappings;
        this.fixAssignmentCostAndCountsForNewPassengerCount(assignment, primary, secondary);
        if (fromPopup) {
            this.refreshGridView();
        }
    };
    OperationQueuePanelComponent.prototype.removeAssignmentFromItems = function (items, primary, secondary) {
        var e_26, _a, e_27, _b;
        this.markChanges();
        var removedItems = [];
        try {
            for (var items_3 = tslib_1.__values(items), items_3_1 = items_3.next(); !items_3_1.done; items_3_1 = items_3.next()) {
                var item = items_3_1.value;
                if (primary) {
                    if (item.primaryAssignment) {
                        removedItems.push(this.removeItemPassengersFromAssignment(item, item.primaryAssignment));
                        this.addAssignmentToItemsRemovedList(item, item.primaryAssignment, true, false);
                        item.primaryAssignment = null;
                    }
                    // if primary item is removing secondary item also removed. (child parent relationship)
                    if (item.secondaryAssignment) {
                        removedItems.push(this.removeItemPassengersFromAssignment(item, item.secondaryAssignment));
                        this.addAssignmentToItemsRemovedList(item, item.secondaryAssignment, false, true);
                        item.secondaryAssignment = null;
                    }
                }
                if (secondary) {
                    if (item.secondaryAssignment) {
                        removedItems.push(this.removeItemPassengersFromAssignment(item, item.secondaryAssignment));
                        if (!item.removedSecondaryAssignments) {
                            item.removedSecondaryAssignments = [];
                        }
                        item.removedSecondaryAssignments.push(item.secondaryAssignment);
                        this.addAssignmentToItemsRemovedList(item, item.secondaryAssignment, false, true);
                        item.secondaryAssignment = null;
                    }
                }
                this.costCalculationUtilsService.setupItemCostPortion(item);
            }
        }
        catch (e_26_1) { e_26 = { error: e_26_1 }; }
        finally {
            try {
                if (items_3_1 && !items_3_1.done && (_a = items_3.return)) _a.call(items_3);
            }
            finally { if (e_26) throw e_26.error; }
        }
        try {
            for (var removedItems_2 = tslib_1.__values(removedItems), removedItems_2_1 = removedItems_2.next(); !removedItems_2_1.done; removedItems_2_1 = removedItems_2.next()) {
                var removedItem = removedItems_2_1.value;
                this.fixAssignmentCostAndCountsForNewPassengerCount(removedItem, primary, secondary);
            }
        }
        catch (e_27_1) { e_27 = { error: e_27_1 }; }
        finally {
            try {
                if (removedItems_2_1 && !removedItems_2_1.done && (_b = removedItems_2.return)) _b.call(removedItems_2);
            }
            finally { if (e_27) throw e_27.error; }
        }
    };
    OperationQueuePanelComponent.prototype.addAssignmentToItemsRemovedList = function (item, assignment, primary, secondary) {
        if (primary) {
            if (!item.removedPrimaryAssignments) {
                item.removedPrimaryAssignments = [];
            }
            item.removedPrimaryAssignments.push(assignment);
        }
        else if (secondary) {
            if (!item.removedSecondaryAssignments) {
                item.removedSecondaryAssignments = [];
            }
            item.removedSecondaryAssignments.push(assignment);
        }
    };
    OperationQueuePanelComponent.prototype.removeItemPassengersFromAssignment = function (item, assignmentRawData) {
        var e_28, _a;
        if (assignmentRawData && assignmentRawData.bookingPaxMappings) {
            if (item && item.bookingPassengers) {
                var _loop_2 = function (bookingPassenger) {
                    var index = assignmentRawData.bookingPaxMappings.findIndex(function (p) { return p.passengerId !==
                        bookingPassenger.id; });
                    assignmentRawData.bookingPaxMappings.splice(index, 1);
                };
                try {
                    for (var _b = tslib_1.__values(item.bookingPassengers), _c = _b.next(); !_c.done; _c = _b.next()) {
                        var bookingPassenger = _c.value;
                        _loop_2(bookingPassenger);
                    }
                }
                catch (e_28_1) { e_28 = { error: e_28_1 }; }
                finally {
                    try {
                        if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                    }
                    finally { if (e_28) throw e_28.error; }
                }
            }
        }
        return assignmentRawData;
    };
    OperationQueuePanelComponent.prototype.fixAssignmentCostAndCountsForNewPassengerCount = function (assignment, primary, secondary) {
        var e_29, _a;
        var adultCount = 0;
        var teenCount = 0;
        var childCount = 0;
        var infantCount = 0;
        try {
            for (var _b = tslib_1.__values(assignment.bookingPaxMappings), _c = _b.next(); !_c.done; _c = _b.next()) {
                var bpm = _c.value;
                switch (bpm.type) {
                    case PassengerType.A:
                        adultCount++;
                        break;
                    case PassengerType.T:
                        teenCount++;
                        break;
                    case PassengerType.C:
                        childCount++;
                        break;
                    case PassengerType.I:
                        infantCount++;
                        break;
                }
            }
        }
        catch (e_29_1) { e_29 = { error: e_29_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_29) throw e_29.error; }
        }
        assignment.adultCount = adultCount;
        assignment.teenCount = teenCount;
        assignment.childCount = childCount;
        assignment.infantCount = infantCount;
        this.costCalculationUtilsService.fixAssignmentCost(assignment);
        this.updateJobRelatedBookingServiceItemCosts(assignment);
    };
    OperationQueuePanelComponent.prototype.updateJobRelatedBookingServiceItemCosts = function (assignment) {
        var _this = this;
        if (this.isBookingQueue()) {
            var bookingItems = this.bookingQueueComponent.bookingItemSearchResults.filter(function (i) { return i.primaryAssignment === assignment || i.secondaryAssignment === assignment; });
            bookingItems.forEach(function (i) { return _this.costCalculationUtilsService.setupItemCostPortion(i); });
        }
        else {
            var serviceItems = this.serviceQueueComponent.serviceItemSearchResults.filter(function (i) { return i.primaryAssignment === assignment || i.secondaryAssignment === assignment; });
            serviceItems.forEach(function (i) { return _this.costCalculationUtilsService.setupItemCostPortion(i); });
        }
    };
    OperationQueuePanelComponent.prototype.enableSaveButton = function () {
        // this.userJourneyManager.canProceed.next(true);
        this.saveButtonEnable = true;
        this.revertButtonEnable = true;
        this.disableConfirmButton();
    };
    OperationQueuePanelComponent.prototype.enableConfirmButton = function () {
        var e_30, _a;
        var selectedItems = this.getSelectedItems();
        if (selectedItems && selectedItems.length > 0) {
            try {
                for (var selectedItems_9 = tslib_1.__values(selectedItems), selectedItems_9_1 = selectedItems_9.next(); !selectedItems_9_1.done; selectedItems_9_1 = selectedItems_9.next()) {
                    var selectedItem = selectedItems_9_1.value;
                    if ((!selectedItem.primaryAssignment && !selectedItem.secondaryAssignment) ||
                        (selectedItem.primaryAssignment && !selectedItem.primaryAssignment.assignmentId) ||
                        (selectedItem.secondaryAssignment && !selectedItem.secondaryAssignment.assignmentId)) {
                        this.confirmButtonEnable = false;
                        break;
                    }
                    else {
                        this.confirmButtonEnable = true;
                    }
                }
            }
            catch (e_30_1) { e_30 = { error: e_30_1 }; }
            finally {
                try {
                    if (selectedItems_9_1 && !selectedItems_9_1.done && (_a = selectedItems_9.return)) _a.call(selectedItems_9);
                }
                finally { if (e_30) throw e_30.error; }
            }
        }
        else {
            this.confirmButtonEnable = false;
        }
        return this.confirmButtonEnable;
    };
    OperationQueuePanelComponent.prototype.disableSaveButton = function () {
        // this.userJourneyManager.canProceed.next(false);
        this.saveButtonEnable = false;
    };
    OperationQueuePanelComponent.prototype.disableConfirmButton = function () {
        this.confirmButtonEnable = false;
    };
    OperationQueuePanelComponent.prototype.reloadGridData = function () {
        this.revertChanges();
        if (this.isBookingQueue()) {
            // this.bookingQueueComponent.loadBookingItems();
        }
        else {
            this.serviceQueueComponent.loadData();
        }
    };
    OperationQueuePanelComponent.prototype.refreshGridView = function () {
        if (this.isBookingQueue()) {
            this.bookingQueueComponent.refreshGridView();
        }
        else {
            this.serviceQueueComponent.refreshGridView();
        }
    };
    OperationQueuePanelComponent.prototype.onSaveAssignmentsClick = function () {
        var _this = this;
        var e_31, _a;
        console.log('save clicked');
        var selectedItems = this.getSelectedItems();
        if (!selectedItems || !selectedItems.length) {
            var invalidAttemptWarning = new DialogModel(true, LEVEL.ERROR, 'Invalid Attempt', 'Please select the items you wish to save', true, 2000);
            var subscriptionInvalid_1 = this.dialogService.confirm(invalidAttemptWarning).subscribe(function (res) {
                subscriptionInvalid_1.unsubscribe();
                return;
            });
            return;
        }
        var assignments = this.getSelectedItemsForSave();
        var constraintsSatisfy = true;
        try {
            for (var assignments_1 = tslib_1.__values(assignments), assignments_1_1 = assignments_1.next(); !assignments_1_1.done; assignments_1_1 = assignments_1.next()) {
                var assignment = assignments_1_1.value;
                if (!assignment.removingAssignment && !(assignment.startTime && assignment.endTime)) {
                    constraintsSatisfy = false;
                }
            }
        }
        catch (e_31_1) { e_31 = { error: e_31_1 }; }
        finally {
            try {
                if (assignments_1_1 && !assignments_1_1.done && (_a = assignments_1.return)) _a.call(assignments_1);
            }
            finally { if (e_31) throw e_31.error; }
        }
        if (constraintsSatisfy) {
            var confirmDialog = new DialogModel(true, LEVEL.INFO, 'Save Assignments', "Do you wish to save changes ?", true, 2000, null, 'No', 'Yes', true);
            confirmDialog.disableClose = true;
            var subscription_1 = this.dialogService
                .confirm(confirmDialog)
                .subscribe(function (res) {
                subscription_1.unsubscribe();
                if (res === true) {
                    _this.spinnerService.show();
                    _this.reservationV2ManagementService.saveAssignmentRawData(assignments)
                        .subscribe(function (result) {
                        _this.spinnerService.hide();
                        // refresh service items/ booking items
                        _this.reloadGridData();
                        _this.enableConfirmButton();
                        _this.common.showSnackBar('Successfully saved the changes', 3000, TcErrorType.TYPE.INFO);
                    }, function (error) {
                        console.log(error);
                        _this.spinnerService.hide();
                        _this.common.showSnackBar('Failed to save the changes', 3000, TcErrorType.TYPE.ERROR);
                    });
                }
            });
        }
        else {
            var invalidAttemptWarning = new DialogModel(true, LEVEL.ERROR, 'Invalid Attempt', 'Please set start and end times of items before saving', true, 2000);
            var subscriptionInvalid_2 = this.dialogService.confirm(invalidAttemptWarning).subscribe(function (res) {
                subscriptionInvalid_2.unsubscribe();
                return;
            });
        }
    };
    OperationQueuePanelComponent.prototype.onRevertAssignmentsClick = function () {
        var _this = this;
        console.log('revert clicked');
        var confirmDialog = new DialogModel(true, LEVEL.WARNING, 'Revert Changes', "Do you wish to revert changes ?", true, 2000, null, 'No', 'Yes', true);
        confirmDialog.disableClose = true;
        var subscription = this.dialogService
            .confirm(confirmDialog)
            .subscribe(function (res) {
            subscription.unsubscribe();
            if (res === true) {
                _this.reloadGridData();
            }
        });
    };
    OperationQueuePanelComponent.prototype.onConfirmClick = function () {
        var _this = this;
        console.log('confirm clicked');
        var confirmDialog = new DialogModel(true, LEVEL.INFO, 'Confirm Assignments', "\n                        Confirming this action will trigger the following.<br><br>\n\n                        1. Sending manifests to the allocated Suppliers/ Resources.<br>\n                        2. Creating accounting ledger entries.<br><br>\n\n                        Once confirmed this action cannot be undone.<br>\n                        Do you wish to proceed?", true, 2000, null, 'No', 'Yes', true);
        confirmDialog.disableClose = true;
        var subscription = this.dialogService
            .confirm(confirmDialog)
            .subscribe(function (res) {
            subscription.unsubscribe();
            if (res === true) {
                var assignmentIds = _this.getSelectedItemsIdsForConfirm();
                _this.spinnerService.show();
                _this.reservationV2ManagementService.changeAssignmentsStatus(assignmentIds, AssignStatus.DMC_CONFIRM).subscribe(function (result) {
                    _this.spinnerService.hide();
                    // refresh service items/ booking items
                    _this.reloadGridData();
                    _this.common.showSnackBar('Successfully confirmed the assigned resources', 3000, TcErrorType.TYPE.INFO);
                    _this.disableConfirmButton();
                }, function (error) {
                    console.log(error);
                    _this.spinnerService.hide();
                    _this.common.showSnackBar('Failed to confirm the assigned resources', 3000, TcErrorType.TYPE.ERROR);
                });
            }
        });
    };
    OperationQueuePanelComponent.prototype.onSupplierConfirmClick = function () {
        var _this = this;
        console.log('supplier confirm clicked');
        var confirmDialog = new DialogModel(true, LEVEL.INFO, 'Supplier Confirm Assignments', "Confirming this action will mark the jobs as confirmed by assigned supplier.<br><br>\n                        Do you wish to proceed?", true, 2000, null, 'No', 'Yes', true);
        confirmDialog.disableClose = true;
        var subscription = this.dialogService
            .confirm(confirmDialog)
            .subscribe(function (res) {
            subscription.unsubscribe();
            if (res === true) {
                var assignmentIds = _this.getSelectedItemIdsForSupplierConfirm();
                _this.spinnerService.show();
                _this.reservationV2ManagementService.changeAssignmentsStatus(assignmentIds, AssignStatus.SUPPLIER_CONFIRMED).subscribe(function (result) {
                    _this.spinnerService.hide();
                    // refresh service items/ booking items
                    _this.reloadGridData();
                    _this.common.showSnackBar('Successfully mark as supplier confirmed', 3000, TcErrorType.TYPE.INFO);
                }, function (error) {
                    console.log(error);
                    _this.spinnerService.hide();
                    _this.common.showSnackBar('Failed to mark as supplier confirmed', 3000, TcErrorType.TYPE.ERROR);
                });
            }
        });
    };
    OperationQueuePanelComponent.prototype.onSupplierRejectClick = function () {
        var _this = this;
        console.log('supplier reject clicked');
        var confirmDialog = new DialogModel(true, LEVEL.INFO, 'Supplier Reject Assignments', "Confirming this action will mark the jobs as rejected by assigned supplier.<br><br>\n                        Do you wish to proceed?", true, 2000, null, 'No', 'Yes', true);
        confirmDialog.disableClose = true;
        var subscription = this.dialogService
            .confirm(confirmDialog)
            .subscribe(function (res) {
            subscription.unsubscribe();
            if (res === true) {
                var assignmentIds = _this.getSelectedItemIdsForSupplierReject();
                _this.spinnerService.show();
                _this.reservationV2ManagementService.changeAssignmentsStatus(assignmentIds, AssignStatus.SUPPLIER_REJECTED).subscribe(function (result) {
                    _this.spinnerService.hide();
                    // refresh service items/ booking items
                    _this.reloadGridData();
                    _this.common.showSnackBar('Successfully marked as supplier rejected', 3000, TcErrorType.TYPE.INFO);
                }, function (error) {
                    console.log(error);
                    _this.spinnerService.hide();
                    _this.common.showSnackBar('Failed to mark as supplier rejected', 3000, TcErrorType.TYPE.ERROR);
                });
            }
        });
    };
    OperationQueuePanelComponent.prototype.onSendManifestsClick = function () {
        var _this = this;
        var e_32, _a;
        if (this.backgroundTaskService.isTaskRunning()) {
            this.common.showSnackBar('Already a background task is being in progress. Please wait for the completion or stop notifying the task status.', 5000, TcErrorType.TYPE.ERROR);
            return;
        }
        // this.spinnerService.show();
        var confirmSendManifests = new DialogModel(true, LEVEL.WARNING, 'Send Manifests', " Do you want to send manifests for selected items ?", true, 2000, null, 'Cancel', 'Send', true);
        confirmSendManifests.disableClose = true;
        if (this.dialogSubscription) {
            this.dialogSubscription.unsubscribe();
        }
        var noOfSuppliers = 0;
        var selectedItems = this.getSelectedItems();
        if (selectedItems && selectedItems.length > 0) {
            try {
                for (var selectedItems_10 = tslib_1.__values(selectedItems), selectedItems_10_1 = selectedItems_10.next(); !selectedItems_10_1.done; selectedItems_10_1 = selectedItems_10.next()) {
                    var selectedItem = selectedItems_10_1.value;
                    // tslint:disable-next-line:triple-equals
                    if (selectedItem.primaryAssignment && selectedItem.primaryAssignment.assignmentId &&
                        selectedItem.primaryAssignment.supplierId && selectedItem.primaryAssignment.supplierId !== 0) {
                        ++noOfSuppliers;
                        break;
                    }
                    else if (selectedItem.secondaryAssignment && selectedItem.secondaryAssignment.assignmentId &&
                        selectedItem.secondaryAssignment.supplierId && selectedItem.secondaryAssignment.supplierId !== 0) {
                        ++noOfSuppliers;
                        break;
                    }
                }
            }
            catch (e_32_1) { e_32 = { error: e_32_1 }; }
            finally {
                try {
                    if (selectedItems_10_1 && !selectedItems_10_1.done && (_a = selectedItems_10.return)) _a.call(selectedItems_10);
                }
                finally { if (e_32) throw e_32.error; }
            }
        }
        if (noOfSuppliers > 0 || selectedItems.length === 0) {
            this.dialogSubscription = this.dialogService
                .confirm(confirmSendManifests)
                .subscribe(function (res) {
                if (res === true) {
                    var documentCreationCriteria = new DocumentCreationCriteria();
                    documentCreationCriteria.documentFormat = DocumentFormat.pdf;
                    documentCreationCriteria.documentType = DocumentType.supplierManifest;
                    documentCreationCriteria.download = true;
                    documentCreationCriteria.persist = true;
                    var documentDataCriteria = new DocumentDataCriteria();
                    documentDataCriteria.bookingIdList = _this.getSelectedBookingItemIdList();
                    documentDataCriteria.queueVersion = 'V2';
                    if (Array.isArray(_this.manifestGenerationStatus)) {
                        documentDataCriteria.assignmentStatuses = _this.manifestGenerationStatus;
                    }
                    documentDataCriteria.searchStartDate = _this.productQueueSearchCriteria.startDate;
                    documentDataCriteria.searchEndDate = _this.productQueueSearchCriteria.endDate;
                    documentDataCriteria.queueVersion = 'V2';
                    var userFirstName = _this.dataStore.get(DataKey.userDetail)
                        .getValue()
                        .userSummary.firstName;
                    var userLastName = _this.dataStore.get(DataKey.userDetail).getValue()
                        .userSummary.lastName;
                    documentDataCriteria.userId = _this.dataStore.get(DataKey.userDetail)
                        .getValue().id;
                    documentDataCriteria.userName = userFirstName + ' ' + userLastName;
                    DateTimeProcessor.processDocumentDataSearchCriteriaDateTimes(documentDataCriteria);
                    _this.documentService.createAndDownloadDocument(documentCreationCriteria, documentDataCriteria);
                }
            });
        }
        else {
            this.common.showSnackBar('No Supplier linked', 3000, TcErrorType.TYPE.WARN);
        }
    };
    OperationQueuePanelComponent.prototype.chipSelected = function () {
        return this.selectedBookingIdList.length === 0;
    };
    // pop-up creation
    OperationQueuePanelComponent.prototype.onGenerateDocumentsClick = function () {
        var _this = this;
        var e_33, _a;
        if (this.backgroundTaskService.isTaskRunning()) {
            this.common.showSnackBar('Already a background task is being in progress. Please wait for the completion or stop notifying the task status.', 5000, TcErrorType.TYPE.ERROR);
            return;
        }
        var noOfSuppliers = 0;
        var selectedItems = this.getSelectedItems();
        if (selectedItems && selectedItems.length > 0) {
            try {
                for (var selectedItems_11 = tslib_1.__values(selectedItems), selectedItems_11_1 = selectedItems_11.next(); !selectedItems_11_1.done; selectedItems_11_1 = selectedItems_11.next()) {
                    var selectedItem = selectedItems_11_1.value;
                    // tslint:disable-next-line:triple-equals
                    if (selectedItem.primaryAssignment && selectedItem.primaryAssignment.assignmentId && selectedItem.primaryAssignment.supplierId && selectedItem.primaryAssignment.supplierId !== 0) {
                        ++noOfSuppliers;
                        break;
                    }
                    else if (selectedItem.secondaryAssignment && selectedItem.secondaryAssignment.assignmentId && selectedItem.secondaryAssignment.supplierId && selectedItem.secondaryAssignment.supplierId !== 0) {
                        ++noOfSuppliers;
                        break;
                    }
                }
            }
            catch (e_33_1) { e_33 = { error: e_33_1 }; }
            finally {
                try {
                    if (selectedItems_11_1 && !selectedItems_11_1.done && (_a = selectedItems_11.return)) _a.call(selectedItems_11);
                }
                finally { if (e_33) throw e_33.error; }
            }
        }
        if (noOfSuppliers > 0 || selectedItems.length === 0) {
            this.dataStore.set(DataKey.popupClose, null);
            var documentTypes = [];
            if (Array.isArray(this.availableDocumentTypes)) {
                documentTypes = this.availableDocumentTypes;
            }
            var documentCreationModel_1 = new DocumentCreationModel();
            documentCreationModel_1.autoName = true;
            documentCreationModel_1.download = true;
            documentCreationModel_1.persist = true;
            documentCreationModel_1.notifySupplier = false;
            documentCreationModel_1.queueVersion = 'V2';
            documentCreationModel_1.selectedBookingItemIds = this.getSelectedBookingItemIdList();
            var dataObject = new ModalData(LEVEL.SUCCESS, 'Generate Documents', null, null, 'modal-basic--alt modal-basic--fixed-size', DocumentCreationModalComponent, {
                documentTypes: documentTypes,
                documentCreationModel: documentCreationModel_1
            });
            dataObject.disableClose = false;
            this.confirmModal(dataObject);
            var modalSubscription_1 = this.dataStore.get(DataKey.popupClose).subscribe(function (data) {
                if (data != null) {
                    modalSubscription_1.unsubscribe();
                    _this.closeModal();
                    if (data) {
                        modalSubscription_1.unsubscribe();
                        _this.closeModal();
                        if (documentCreationModel_1.documentType) {
                            switch (documentCreationModel_1.documentType.code) {
                                case DocumentType.supplierManifest:
                                    _this.queueV2SupplierManifestReport(documentCreationModel_1);
                                    break;
                                case DocumentType.queueV2ExportReport:
                                    _this.queueV2ExportReport(documentCreationModel_1);
                                    break;
                                case DocumentType.tourList:
                                    _this.getTourList(documentCreationModel_1);
                                    break;
                                case DocumentType.arrivalList:
                                    _this.getArrivalReport(documentCreationModel_1);
                                    break;
                                case DocumentType.departureList:
                                    _this.getDepartureReport(documentCreationModel_1);
                                    break;
                            }
                        }
                    }
                }
            });
        }
        else {
            this.common.showSnackBar('No Supplier linked', 3000, TcErrorType.TYPE.WARN);
        }
    };
    OperationQueuePanelComponent.prototype.getAllGridList = function () {
        var resultsList = [];
        if (this.isBookingQueue() && this.bookingQueueComponent &&
            this.bookingQueueComponent.bookingItemSearchResults) {
            resultsList = this.bookingQueueComponent.bookingItemSearchResults;
        }
        else if (this.isServiceQueue() && this.serviceQueueComponent &&
            this.serviceQueueComponent.serviceItemSearchResults) {
            resultsList = this.serviceQueueComponent.serviceItemSearchResults;
        }
        return resultsList;
    };
    OperationQueuePanelComponent.prototype.queueV2SupplierManifestReport = function (documentCreationModel) {
        this.spinnerService.show();
        var documentCreationCriteria = new DocumentCreationCriteria();
        documentCreationCriteria.documentFormat = documentCreationModel.documentType.format;
        documentCreationCriteria.documentName = documentCreationModel.documentName;
        documentCreationCriteria.download = !!documentCreationModel.download;
        documentCreationCriteria.persist = !!documentCreationModel.persist;
        documentCreationCriteria.notifySupplier = !!documentCreationModel.notifySupplier;
        documentCreationCriteria.documentType = DocumentType.supplierManifest;
        var documentDataCriteria = new DocumentDataCriteria();
        documentDataCriteria.bookingIdList = documentCreationModel.selectedBookingItemIds;
        documentDataCriteria.queueVersion = 'V2';
        if (Array.isArray(this.manifestGenerationStatus)) {
            documentDataCriteria.assignmentStatuses = this.manifestGenerationStatus;
        }
        documentDataCriteria.queueVersion = 'V2';
        documentDataCriteria.documentType = DocumentType.supplierManifest;
        documentDataCriteria.viewType = documentCreationModel.viewType;
        documentDataCriteria.searchStartDate = this.productQueueSearchCriteria.startDate;
        documentDataCriteria.searchEndDate = this.productQueueSearchCriteria.endDate;
        var userFirstName = this.dataStore.get(DataKey.userDetail).getValue()
            .userSummary.firstName;
        var userLastName = this.dataStore.get(DataKey.userDetail).getValue()
            .userSummary.lastName;
        documentDataCriteria.userId = this.dataStore.get(DataKey.userDetail).getValue().id;
        documentDataCriteria.userName = userFirstName + ' ' + userLastName;
        // this.selectedBookingIdList.forEach(list => {
        //     list.forEach(id => {
        //         documentDataCriteria.bookingIdList.push(id);
        //     });
        // });
        DateTimeProcessor.processDocumentDataSearchCriteriaDateTimes(documentDataCriteria);
        this.documentService.createAndDownloadDocument(documentCreationCriteria, documentDataCriteria);
    };
    OperationQueuePanelComponent.prototype.queueV2ExportReport = function (documentCreationModel) {
        this.spinnerService.show();
        var documentCreationCriteria = new DocumentCreationCriteria();
        documentCreationCriteria.documentFormat = DocumentFormat.excel;
        if (documentCreationModel.viewType === 'BOOKING_VIEW') {
            documentCreationCriteria.documentType = DocumentType.queueV2BookingViewExportReport;
        }
        else {
            documentCreationCriteria.documentType = DocumentType.queueV2ServicingViewExportReport;
        }
        documentCreationCriteria.documentName = documentCreationModel.documentName;
        documentCreationCriteria.download = !!documentCreationModel.download;
        documentCreationCriteria.persist = !!documentCreationModel.persist;
        documentCreationCriteria.notifySupplier = !!documentCreationModel.notifySupplier;
        var documentDataCriteria = new DocumentDataCriteria();
        documentDataCriteria.queueVersion = 'V2';
        if (documentCreationModel.viewType === 'BOOKING_VIEW') {
            documentDataCriteria.documentType = DocumentType.queueV2BookingViewExportReport;
        }
        else {
            documentDataCriteria.documentType = DocumentType.queueV2ServicingViewExportReport;
        }
        documentDataCriteria.viewType = documentCreationModel.viewType;
        documentDataCriteria.searchStartDate = this.productQueueSearchCriteria.startDate;
        documentDataCriteria.searchEndDate = this.productQueueSearchCriteria.endDate;
        if (documentCreationModel && documentCreationModel.selectedBookingItemIds) {
            documentCreationModel.selectedBookingItemIds.forEach(function (id) {
                documentDataCriteria.bookingIdList.push(id);
            });
        }
        DateTimeProcessor.processDocumentDataSearchCriteriaDateTimes(documentDataCriteria);
        this.documentService.createAndDownloadDocument(documentCreationCriteria, documentDataCriteria);
    };
    OperationQueuePanelComponent.prototype.getTourList = function (documentCreationModel) {
        this.spinnerService.show();
        var documentCreationCriteria = new DocumentCreationCriteria();
        documentCreationCriteria.documentFormat = DocumentFormat.excel;
        documentCreationCriteria.documentType = DocumentType.tourListV2;
        documentCreationCriteria.documentName = documentCreationModel.documentName;
        documentCreationCriteria.download = !!documentCreationModel.download;
        documentCreationCriteria.persist = !!documentCreationModel.persist;
        documentCreationCriteria.notifySupplier = !!documentCreationModel.notifySupplier;
        var documentDataCriteria = new DocumentDataCriteria();
        documentDataCriteria.searchStartDate = this.productQueueSearchCriteria.startDate;
        documentDataCriteria.searchEndDate = this.productQueueSearchCriteria.endDate;
        documentDataCriteria.documentType = DocumentType.tourListV2;
        documentDataCriteria.bookingIdList = documentCreationModel.selectedBookingItemIds;
        documentDataCriteria.queueVersion = 'V2';
        DateTimeProcessor.processDocumentDataSearchCriteriaDateTimes(documentDataCriteria);
        this.documentService.createAndDownloadDocument(documentCreationCriteria, documentDataCriteria);
    };
    OperationQueuePanelComponent.prototype.getArrivalReport = function (documentCreationModel) {
        this.spinnerService.show();
        var documentCreationCriteria = new DocumentCreationCriteria();
        documentCreationCriteria.documentFormat = DocumentFormat.excel;
        documentCreationCriteria.documentType = DocumentType.arrivalListV2;
        documentCreationCriteria.documentName = documentCreationModel.documentName;
        documentCreationCriteria.download = !!documentCreationModel.download;
        documentCreationCriteria.persist = !!documentCreationModel.persist;
        documentCreationCriteria.notifySupplier = !!documentCreationModel.notifySupplier;
        var documentDataCriteria = new DocumentDataCriteria();
        documentDataCriteria.searchStartDate = this.productQueueSearchCriteria.startDate;
        documentDataCriteria.searchEndDate = this.productQueueSearchCriteria.endDate;
        documentDataCriteria.documentType = DocumentType.arrivalListV2;
        documentDataCriteria.bookingIdList = documentCreationModel.selectedBookingItemIds;
        documentDataCriteria.queueVersion = 'V2';
        DateTimeProcessor.processDocumentDataSearchCriteriaDateTimes(documentDataCriteria);
        this.documentService.createAndDownloadDocument(documentCreationCriteria, documentDataCriteria);
    };
    OperationQueuePanelComponent.prototype.getDepartureReport = function (documentCreationModel) {
        this.spinnerService.show();
        var documentCreationCriteria = new DocumentCreationCriteria();
        documentCreationCriteria.documentFormat = DocumentFormat.excel;
        documentCreationCriteria.documentType = DocumentType.departureListV2;
        documentCreationCriteria.documentName = documentCreationModel.documentName;
        documentCreationCriteria.download = !!documentCreationModel.download;
        documentCreationCriteria.persist = !!documentCreationModel.persist;
        documentCreationCriteria.notifySupplier = !!documentCreationModel.notifySupplier;
        var documentDataCriteria = new DocumentDataCriteria();
        documentDataCriteria.searchStartDate = this.productQueueSearchCriteria.startDate;
        documentDataCriteria.searchEndDate = this.productQueueSearchCriteria.endDate;
        documentDataCriteria.documentType = DocumentType.departureListV2;
        documentDataCriteria.bookingIdList = documentCreationModel.selectedBookingItemIds;
        documentDataCriteria.queueVersion = 'V2';
        DateTimeProcessor.processDocumentDataSearchCriteriaDateTimes(documentDataCriteria);
        this.documentService.createAndDownloadDocument(documentCreationCriteria, documentDataCriteria);
    };
    OperationQueuePanelComponent.prototype.getSelectedBookingItemIdList = function () {
        var bookingIdList = [];
        if (this.isBookingQueue()) {
            this.getSelectedItems().forEach(function (bookingItem) {
                bookingIdList.push(bookingItem.id);
            });
        }
        else if (this.isServiceQueue()) {
            this.getSelectedItems().forEach(function (serviceItem) {
                serviceItem.bookingItemSummaryList.forEach(function (item) {
                    bookingIdList.push(item.bookingItemId);
                });
            });
        }
        if (bookingIdList.length === 0) {
            bookingIdList.push.apply(bookingIdList, tslib_1.__spread(this.allBookingIdList));
        }
        return bookingIdList;
    };
    OperationQueuePanelComponent.prototype.onSupplierAutoAllocationClick = function () {
        var _this = this;
        var e_34, _a, e_35, _b;
        var bookingItemSearchCriteria = new BookingItemSearchCriteria();
        var serviceItemSearchCriteria = new ServiceItemSearchCriteria();
        var oldBookingIdList = [];
        var newBookingIdList = [];
        var constraintsSatisfied = true;
        if (this.isBookingQueue()) {
            try {
                for (var _c = tslib_1.__values(this.getSelectedItems()), _d = _c.next(); !_d.done; _d = _c.next()) {
                    var bookingItem = _d.value;
                    if (bookingItem.startTime && bookingItem.endTime) {
                        oldBookingIdList.push(bookingItem.id);
                    }
                    else {
                        var noon = new Date(new Date().setHours(12, 0, 0, 0));
                        var midnight = new Date(new Date().setHours(0, 0, 0, 0));
                        if (!bookingItem.startTime) {
                            bookingItem.startTime = noon;
                        }
                        if (!bookingItem.endTime) {
                            bookingItem.endTime = midnight;
                        }
                        oldBookingIdList.push(bookingItem.id);
                        this.common.showSnackBar("Booking Start time was set to " + noon.toLocaleString('en-US') + " and End time to " + midnight.toLocaleString('en-US'), 3000, TcErrorType.TYPE.INFO);
                    }
                }
            }
            catch (e_34_1) { e_34 = { error: e_34_1 }; }
            finally {
                try {
                    if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
                }
                finally { if (e_34) throw e_34.error; }
            }
            if (constraintsSatisfied) {
                this.spinnerService.show();
                oldBookingIdList.sort();
                bookingItemSearchCriteria.bookingIdList = oldBookingIdList;
                bookingItemSearchCriteria.allocationType = AllocationType.SUPPLIER;
                this.reservationV2ManagementService.doAutoAllocationForBookingItems(bookingItemSearchCriteria)
                    .subscribe(function (result) {
                    if (result.status.code === 1) {
                        _this.bookingQueueComponent.replaceWithAutoAllocatedBookingItems(oldBookingIdList, result.data);
                        result.data.forEach(function (newItem) {
                            newBookingIdList.push(newItem.id);
                        });
                        _this.productQueueComponent.updateSearchChipsAfterSplit(oldBookingIdList, newBookingIdList);
                    }
                    _this.spinnerService.hide();
                    var itemsWithAssignments = result.data.filter(function (i) { return i.primaryAssignment; });
                    var plural = itemsWithAssignments.length === 1 ? '' : 's';
                    _this.common.showSnackBar("Added suppliers to " + itemsWithAssignments.length + " item" + plural, 3000, TcErrorType.TYPE.INFO);
                }, (function (error) {
                    console.log('Auto allocation API error during data retrieval: ' + error.message);
                    _this.common.showSnackBar('Resource adding failed', 3000, TcErrorType.TYPE.ERROR);
                    _this.spinnerService.hide();
                }), function () {
                    _this.reloadGridData();
                });
            }
            else {
                var invalidAttemptWarning = new DialogModel(true, LEVEL.ERROR, 'Invalid Attempt', 'Please set start and end times before auto allocation', true, 2000);
                var subscriptionInvalid_3 = this.dialogService.confirm(invalidAttemptWarning).subscribe(function (res) {
                    subscriptionInvalid_3.unsubscribe();
                    return;
                });
            }
        }
        else if (this.isServiceQueue()) {
            var oldServiceIdList_1 = [];
            try {
                for (var _e = tslib_1.__values(this.getSelectedItems()), _f = _e.next(); !_f.done; _f = _e.next()) {
                    var serviceItem = _f.value;
                    if (serviceItem.startTime && serviceItem.endTime) {
                        serviceItem.bookingItemSummaryList.forEach(function (item) {
                            oldBookingIdList.push(item.bookingItemId);
                        });
                    }
                    else {
                        var noon = new Date(new Date().setHours(12, 0, 0, 0));
                        var midnight = new Date(new Date().setHours(0, 0, 0, 0));
                        if (!serviceItem.startTime) {
                            serviceItem.startTime = noon;
                        }
                        if (!serviceItem.endTime) {
                            serviceItem.endTime = midnight;
                        }
                        this.common.showSnackBar("Booking Start time was set to " + noon.toLocaleString('en-US') + " and End time to " + midnight.toLocaleString('en-US'), 3000, TcErrorType.TYPE.INFO);
                        serviceItem.bookingItemSummaryList.forEach(function (item) {
                            oldBookingIdList.push(item.bookingItemId);
                        });
                    }
                    oldServiceIdList_1.push(serviceItem.serviceId);
                }
            }
            catch (e_35_1) { e_35 = { error: e_35_1 }; }
            finally {
                try {
                    if (_f && !_f.done && (_b = _e.return)) _b.call(_e);
                }
                finally { if (e_35) throw e_35.error; }
            }
            if (constraintsSatisfied) {
                this.spinnerService.show();
                oldBookingIdList.sort();
                serviceItemSearchCriteria.bookingIdList = oldBookingIdList;
                serviceItemSearchCriteria.allocationType = AllocationType.SUPPLIER;
                this.reservationV2ManagementService.doAutoAllocationForServiceItems(serviceItemSearchCriteria)
                    .subscribe(function (result) {
                    if (result.status.code === 1) {
                        _this.serviceQueueComponent.replaceWithAutoAllocatedServiceItems(oldServiceIdList_1, result.data);
                        result.data.forEach(function (item) {
                            item.bookingItemSummaryList.forEach(function (newItem) {
                                newBookingIdList.push(newItem.bookingItemId);
                            });
                        });
                        _this.productQueueComponent.updateSearchChipsAfterSplit(oldBookingIdList, newBookingIdList);
                    }
                    _this.spinnerService.hide();
                    var itemsWithAssignments = result.data.filter(function (i) { return i.primaryAssignment; });
                    var plural = itemsWithAssignments.length === 1 ? '' : 's';
                    _this.common.showSnackBar("Added suppliers to " + itemsWithAssignments.length + " item" + plural, 3000, TcErrorType.TYPE.INFO);
                }, function (error) {
                    console.log('Auto allocation API error during data retrieval: ' + error.message);
                    _this.spinnerService.hide();
                    _this.common.showSnackBar('Resource adding failed', 3000, TcErrorType.TYPE.ERROR);
                }, function () {
                    _this.reloadGridData();
                });
            }
            else {
                var invalidAttemptWarning = new DialogModel(true, LEVEL.ERROR, 'Invalid Attempt', 'Please set start and end times before auto allocation', true, 2000);
                var subscriptionInvalid_4 = this.dialogService.confirm(invalidAttemptWarning).subscribe(function (res) {
                    subscriptionInvalid_4.unsubscribe();
                    return;
                });
            }
        }
        else {
            this.confirmButtonEnable = false;
        }
    };
    OperationQueuePanelComponent.prototype.onResourceAutoAllocationClick = function () {
        var _this = this;
        var e_36, _a, e_37, _b;
        var bookingItemSearchCriteria = new BookingItemSearchCriteria();
        var serviceItemSearchCriteria = new ServiceItemSearchCriteria();
        var oldBookingIdList = [];
        var newBookingIdList = [];
        var constraintsStasfied = true;
        if (this.isBookingQueue()) {
            try {
                for (var _c = tslib_1.__values(this.getSelectedItems()), _d = _c.next(); !_d.done; _d = _c.next()) {
                    var bookingItem = _d.value;
                    if (bookingItem.startTime && bookingItem.endTime) {
                        oldBookingIdList.push(bookingItem.id);
                    }
                    else {
                        var noon = new Date(new Date().setHours(12, 0, 0, 0));
                        var midnight = new Date(new Date().setHours(0, 0, 0, 0));
                        if (!bookingItem.startTime) {
                            bookingItem.startTime = noon;
                        }
                        if (!bookingItem.endTime) {
                            bookingItem.endTime = midnight;
                        }
                        oldBookingIdList.push(bookingItem.id);
                        this.common.showSnackBar("Booking Start time was set to " + noon.toLocaleString('en-US') + " and End time to " + midnight.toLocaleString('en-US'), 3000, TcErrorType.TYPE.INFO);
                    }
                }
            }
            catch (e_36_1) { e_36 = { error: e_36_1 }; }
            finally {
                try {
                    if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
                }
                finally { if (e_36) throw e_36.error; }
            }
            if (constraintsStasfied) {
                this.spinnerService.show();
                oldBookingIdList.sort();
                bookingItemSearchCriteria.bookingIdList = oldBookingIdList;
                bookingItemSearchCriteria.allocationType = AllocationType.RESOURCE;
                this.reservationV2ManagementService.doAutoAllocationForBookingItems(bookingItemSearchCriteria)
                    .subscribe(function (result) {
                    if (result.status.code === 1) {
                        _this.bookingQueueComponent.replaceWithAutoAllocatedBookingItems(oldBookingIdList, result.data);
                        result.data.forEach(function (newItem) {
                            newBookingIdList.push(newItem.id);
                        });
                        _this.productQueueComponent.updateSearchChipsAfterSplit(oldBookingIdList, newBookingIdList);
                    }
                    _this.spinnerService.hide();
                    var itemsWithAssignments = result.data.filter(function (i) { return i.primaryAssignment; });
                    var plural = itemsWithAssignments.length === 1 ? '' : 's';
                    _this.common.showSnackBar("Added resources to " + itemsWithAssignments.length + " item" + plural, 3000, TcErrorType.TYPE.INFO);
                }, (function (error) {
                    console.log('Auto allocation API error during data retrieval: ' + error.message);
                    _this.common.showSnackBar('Resource adding failed', 3000, TcErrorType.TYPE.ERROR);
                }), function () {
                    _this.spinnerService.hide();
                    _this.reloadGridData();
                });
            }
            else {
                var invalidAttemptWarning = new DialogModel(true, LEVEL.ERROR, 'Invalid Attempt', 'Please set start and end times before auto allocation', true, 2000);
                var subscriptionInvalid_5 = this.dialogService.confirm(invalidAttemptWarning).subscribe(function (res) {
                    subscriptionInvalid_5.unsubscribe();
                    return;
                });
            }
        }
        else if (this.isServiceQueue()) {
            var oldServiceIdList_2 = [];
            try {
                for (var _e = tslib_1.__values(this.getSelectedItems()), _f = _e.next(); !_f.done; _f = _e.next()) {
                    var serviceItem = _f.value;
                    if (serviceItem.startTime && serviceItem.endTime) {
                        serviceItem.bookingItemSummaryList.forEach(function (item) {
                            oldBookingIdList.push(item.bookingItemId);
                        });
                    }
                    else {
                        var noon = new Date(new Date().setHours(12, 0, 0, 0));
                        var midnight = new Date(new Date().setHours(0, 0, 0, 0));
                        if (!serviceItem.startTime) {
                            serviceItem.startTime = noon;
                        }
                        if (!serviceItem.endTime) {
                            serviceItem.endTime = midnight;
                        }
                        this.common.showSnackBar("Booking Start time was set to " + noon.toLocaleString('en-US') + " and End time to " + midnight.toLocaleString('en-US'), 3000, TcErrorType.TYPE.INFO);
                        serviceItem.bookingItemSummaryList.forEach(function (item) {
                            oldBookingIdList.push(item.bookingItemId);
                        });
                    }
                    oldServiceIdList_2.push(serviceItem.serviceId);
                }
            }
            catch (e_37_1) { e_37 = { error: e_37_1 }; }
            finally {
                try {
                    if (_f && !_f.done && (_b = _e.return)) _b.call(_e);
                }
                finally { if (e_37) throw e_37.error; }
            }
            if (constraintsStasfied) {
                this.spinnerService.show();
                oldBookingIdList.sort();
                serviceItemSearchCriteria.bookingIdList = oldBookingIdList;
                serviceItemSearchCriteria.allocationType = AllocationType.RESOURCE;
                this.reservationV2ManagementService.doAutoAllocationForServiceItems(serviceItemSearchCriteria)
                    .subscribe(function (result) {
                    if (result.status.code === 1) {
                        _this.serviceQueueComponent.replaceWithAutoAllocatedServiceItems(oldServiceIdList_2, result.data);
                        result.data.forEach(function (item) {
                            item.bookingItemSummaryList.forEach(function (newItem) {
                                newBookingIdList.push(newItem.bookingItemId);
                            });
                        });
                        _this.productQueueComponent.updateSearchChipsAfterSplit(oldBookingIdList, newBookingIdList);
                    }
                    _this.spinnerService.hide();
                    var itemsWithAssignments = result.data.filter(function (i) { return i.primaryAssignment; });
                    var plural = itemsWithAssignments.length === 1 ? '' : 's';
                    _this.common.showSnackBar("Added resources to " + itemsWithAssignments.length + " item" + plural, 3000, TcErrorType.TYPE.INFO);
                }, function (error) {
                    console.log('Auto allocation API error during data retrieval: ' + error.message);
                    _this.spinnerService.hide();
                    _this.common.showSnackBar('Resource adding failed', 3000, TcErrorType.TYPE.ERROR);
                }, function () {
                    _this.reloadGridData();
                });
            }
            else {
                var invalidAttemptWarning = new DialogModel(true, LEVEL.ERROR, 'Invalid Attempt', 'Please set start and end times before auto allocation', true, 2000);
                var subscriptionInvalid_6 = this.dialogService.confirm(invalidAttemptWarning).subscribe(function (res) {
                    subscriptionInvalid_6.unsubscribe();
                    return;
                });
            }
        }
        else {
            this.confirmButtonEnable = false;
        }
    };
    OperationQueuePanelComponent.prototype.getSelectedItemsIdsForConfirm = function () {
        var e_38, _a;
        var selectedItems = this.getSelectedItems();
        var assignmentIds = [];
        try {
            for (var selectedItems_12 = tslib_1.__values(selectedItems), selectedItems_12_1 = selectedItems_12.next(); !selectedItems_12_1.done; selectedItems_12_1 = selectedItems_12.next()) {
                var selectedItem = selectedItems_12_1.value;
                if (selectedItem.primaryAssignment && this.isInConfirmableState(selectedItem.primaryAssignment) &&
                    selectedItem.primaryAssignment.assignmentId) {
                    this.addToListIfNotExist(assignmentIds, selectedItem.primaryAssignment.assignmentId);
                }
                if (selectedItem.secondaryAssignment && this.isInConfirmableState(selectedItem.secondaryAssignment) &&
                    selectedItem.secondaryAssignment.assignmentId) {
                    this.addToListIfNotExist(assignmentIds, selectedItem.secondaryAssignment.assignmentId);
                }
            }
        }
        catch (e_38_1) { e_38 = { error: e_38_1 }; }
        finally {
            try {
                if (selectedItems_12_1 && !selectedItems_12_1.done && (_a = selectedItems_12.return)) _a.call(selectedItems_12);
            }
            finally { if (e_38) throw e_38.error; }
        }
        return assignmentIds;
    };
    OperationQueuePanelComponent.prototype.getSelectedItemIdsForSupplierConfirm = function () {
        var e_39, _a;
        var selectedItems = this.getSelectedItems();
        var assignmentIds = [];
        try {
            for (var selectedItems_13 = tslib_1.__values(selectedItems), selectedItems_13_1 = selectedItems_13.next(); !selectedItems_13_1.done; selectedItems_13_1 = selectedItems_13.next()) {
                var selectedItem = selectedItems_13_1.value;
                if (selectedItem.primaryAssignment && this.isInSupplierConfirmableState(selectedItem.primaryAssignment) &&
                    selectedItem.primaryAssignment.assignmentId) {
                    this.addToListIfNotExist(assignmentIds, selectedItem.primaryAssignment.assignmentId);
                }
                if (selectedItem.secondaryAssignment &&
                    this.isInSupplierConfirmableState(selectedItem.secondaryAssignment) &&
                    selectedItem.secondaryAssignment.assignmentId) {
                    this.addToListIfNotExist(assignmentIds, selectedItem.secondaryAssignment.assignmentId);
                }
            }
        }
        catch (e_39_1) { e_39 = { error: e_39_1 }; }
        finally {
            try {
                if (selectedItems_13_1 && !selectedItems_13_1.done && (_a = selectedItems_13.return)) _a.call(selectedItems_13);
            }
            finally { if (e_39) throw e_39.error; }
        }
        return assignmentIds;
    };
    OperationQueuePanelComponent.prototype.getSelectedItemIdsForSupplierReject = function () {
        var e_40, _a;
        var selectedItems = this.getSelectedItems();
        var assignmentIds = [];
        try {
            for (var selectedItems_14 = tslib_1.__values(selectedItems), selectedItems_14_1 = selectedItems_14.next(); !selectedItems_14_1.done; selectedItems_14_1 = selectedItems_14.next()) {
                var selectedItem = selectedItems_14_1.value;
                if (selectedItem.primaryAssignment && this.isInSupplierRejectableState(selectedItem.primaryAssignment) &&
                    selectedItem.primaryAssignment.assignmentId) {
                    this.addToListIfNotExist(assignmentIds, selectedItem.primaryAssignment.assignmentId);
                }
                if (selectedItem.secondaryAssignment &&
                    this.isInSupplierRejectableState(selectedItem.secondaryAssignment) &&
                    selectedItem.secondaryAssignment.assignmentId) {
                    this.addToListIfNotExist(assignmentIds, selectedItem.secondaryAssignment.assignmentId);
                }
            }
        }
        catch (e_40_1) { e_40 = { error: e_40_1 }; }
        finally {
            try {
                if (selectedItems_14_1 && !selectedItems_14_1.done && (_a = selectedItems_14.return)) _a.call(selectedItems_14);
            }
            finally { if (e_40) throw e_40.error; }
        }
        return assignmentIds;
    };
    OperationQueuePanelComponent.prototype.addToListIfNotExist = function (list, assignment) {
        if (!list.includes(assignment)) {
            list.push(assignment);
        }
    };
    OperationQueuePanelComponent.prototype.addToListIfNotExistAll = function (list, assignments) {
        var e_41, _a;
        try {
            for (var assignments_2 = tslib_1.__values(assignments), assignments_2_1 = assignments_2.next(); !assignments_2_1.done; assignments_2_1 = assignments_2.next()) {
                var assignment = assignments_2_1.value;
                if (!list.includes(assignment)) {
                    list.push(assignment);
                }
            }
        }
        catch (e_41_1) { e_41 = { error: e_41_1 }; }
        finally {
            try {
                if (assignments_2_1 && !assignments_2_1.done && (_a = assignments_2.return)) _a.call(assignments_2);
            }
            finally { if (e_41) throw e_41.error; }
        }
    };
    OperationQueuePanelComponent.prototype.removeResourceFromItems = function (items, primary, secondary) {
        var e_42, _a;
        if (primary) {
            try {
                for (var items_4 = tslib_1.__values(items), items_4_1 = items_4.next(); !items_4_1.done; items_4_1 = items_4.next()) {
                    var item = items_4_1.value;
                    if (item.primaryAssignment) {
                        item.primaryAssignment.resourceId = undefined;
                        item.primaryAssignment.resourceName = undefined;
                    }
                }
            }
            catch (e_42_1) { e_42 = { error: e_42_1 }; }
            finally {
                try {
                    if (items_4_1 && !items_4_1.done && (_a = items_4.return)) _a.call(items_4);
                }
                finally { if (e_42) throw e_42.error; }
            }
        }
    };
    OperationQueuePanelComponent.prototype.getSelectedItems = function () {
        var selectedItems = [];
        if (this.isBookingQueue() && this.bookingQueueComponent && this.bookingQueueComponent.gridApi) {
            selectedItems = this.bookingQueueComponent.gridApi.getSelectedRows();
        }
        else if (this.serviceQueueComponent && this.serviceQueueComponent.gridApi) {
            selectedItems = this.serviceQueueComponent.gridApi.getSelectedRows();
        }
        return selectedItems;
    };
    OperationQueuePanelComponent.prototype.getSelectedItemsForSave = function () {
        var e_43, _a;
        var selectedItems = this.getSelectedItems();
        var assignments = [];
        try {
            for (var selectedItems_15 = tslib_1.__values(selectedItems), selectedItems_15_1 = selectedItems_15.next(); !selectedItems_15_1.done; selectedItems_15_1 = selectedItems_15.next()) {
                var selectedItem = selectedItems_15_1.value;
                if (selectedItem.primaryAssignment && this.isInSavableState(selectedItem.primaryAssignment)) {
                    selectedItem.primaryAssignment.startTime = selectedItem.startTime;
                    selectedItem.primaryAssignment.endTime = selectedItem.endTime;
                    selectedItem.primaryAssignment.costingType = selectedItem.costingType && selectedItem.costingType.code ?
                        selectedItem.costingType.code :
                        selectedItem.costingType;
                    selectedItem.primaryAssignment.noOfUnits = selectedItem.noOfUnits;
                    this.addToListIfNotExist(assignments, selectedItem.primaryAssignment);
                }
                if (selectedItem.secondaryAssignment && this.isInSavableState(selectedItem.secondaryAssignment)) {
                    selectedItem.secondaryAssignment.startTime = selectedItem.startTime;
                    selectedItem.secondaryAssignment.endTime = selectedItem.endTime;
                    selectedItem.secondaryAssignment.costingType = selectedItem.costingType &&
                        selectedItem.costingType.code ?
                        selectedItem.costingType.code :
                        selectedItem.costingType;
                    selectedItem.secondaryAssignment.noOfUnits = selectedItem.noOfUnits;
                    this.addToListIfNotExist(assignments, selectedItem.secondaryAssignment);
                    if (selectedItem.primaryAssignment) {
                        this.addToListIfNotExist(assignments, selectedItem.primaryAssignment);
                    }
                }
                if (selectedItem.removedPrimaryAssignments) {
                    selectedItem.removedPrimaryAssignments.forEach(function (a) { return a.removingAssignment = true; });
                    this.addToListIfNotExistAll(assignments, selectedItem.removedPrimaryAssignments);
                }
                if (selectedItem.removedSecondaryAssignments) {
                    selectedItem.removedSecondaryAssignments.forEach(function (a) { return a.removingAssignment = true; });
                    this.addToListIfNotExistAll(assignments, selectedItem.removedSecondaryAssignments);
                }
            }
        }
        catch (e_43_1) { e_43 = { error: e_43_1 }; }
        finally {
            try {
                if (selectedItems_15_1 && !selectedItems_15_1.done && (_a = selectedItems_15.return)) _a.call(selectedItems_15);
            }
            finally { if (e_43) throw e_43.error; }
        }
        return assignments;
    };
    OperationQueuePanelComponent.prototype.getSuppliers = function (row, text, primary, secondary) {
        var e_44, _a;
        var supplierName = text;
        var privateBooking = false;
        var adultCount = 0;
        var teenCount = 0;
        var childrenCount = 0;
        var infantCount = 0;
        var noOfUnits = 0;
        var selectedItems = this.getSelectedItems();
        privateBooking = row.pvtShared === ServiceSharing.PVT;
        if (selectedItems.length < 2) { // no selected items only consider "row"
            selectedItems = [row];
        }
        var serviceDate = row.serviceDate;
        try {
            for (var selectedItems_16 = tslib_1.__values(selectedItems), selectedItems_16_1 = selectedItems_16.next(); !selectedItems_16_1.done; selectedItems_16_1 = selectedItems_16.next()) {
                var item = selectedItems_16_1.value;
                adultCount += item.adultCount;
                teenCount += item.teenCount;
                childrenCount += item.childrenCount;
                infantCount += item.infantCount;
                noOfUnits += item.noOfUnits;
            }
        }
        catch (e_44_1) { e_44 = { error: e_44_1 }; }
        finally {
            try {
                if (selectedItems_16_1 && !selectedItems_16_1.done && (_a = selectedItems_16.return)) _a.call(selectedItems_16);
            }
            finally { if (e_44) throw e_44.error; }
        }
        var serviceType = '';
        if (primary && row.primaryServiceType) {
            serviceType = row.primaryServiceType.code;
        }
        else if (secondary && row.secondaryServiceType && row.secondaryServiceType.code) {
            serviceType = row.secondaryServiceType.code;
        }
        else {
            serviceType = row.genBookingItem ? row.genBookingItem.productCode : (primary ? 'vehicle' : 'driver');
        }
        var profileType = row.genBookingItem ? row.genBookingItem.typeCode : '';
        // const vehicleModel = row.trsBookingItem ? row.trsBookingItem.vehicleType : '';
        var vehicleModel = 'ANY';
        var route = row.trsBookingItem ? row.trsBookingItem.route : '';
        var transferMode = row.trsBookingItem ? row.trsBookingItem.transferMode : '';
        var serviceStartTime = null;
        var serviceEndTime = null;
        if (row.trsBookingItem) {
            serviceStartTime = row.startTime ? row.startTime : null;
            // moment(new Date(row.startTime)).format('HH:mm') :
            // moment(new Date(row.trsBookingItem.departureDateTime)).format('HH:mm');
            serviceEndTime = row.endTime ? row.endTime : null;
            // moment(new Date(row.endTime)).format('HH:mm') :
            // moment(new Date(row.trsBookingItem.arrivalDateTime)).format('HH:mm');
        }
        else {
            serviceStartTime = row.startTime ? row.startTime : null;
            // moment(new Date(row.startTime)).format('HH:mm') :
            // moment(new Date(row.serviceDate)).format('HH:mm');
            serviceEndTime = row.endTime ? row.endTime : null;
            // moment(new Date(row.endTime)).format('HH:mm') :
            // moment(new Date(row.serviceDate)).format('HH:mm');
        }
        var bookingIdList = this.getSelectedBookingIds(selectedItems);
        var costingType = row.costingType && row.costingType.code ? row.costingType.code : row.costingType;
        this.supplierSearchCriteria.supplierName = supplierName;
        this.supplierSearchCriteria.serviceType = serviceType;
        this.supplierSearchCriteria.vehicleModel = vehicleModel;
        this.supplierSearchCriteria.route = route;
        this.supplierSearchCriteria.transferMode = transferMode;
        this.supplierSearchCriteria.profileType = profileType;
        this.supplierSearchCriteria.privateBooking = privateBooking;
        this.supplierSearchCriteria.serviceDate = serviceDate;
        this.supplierSearchCriteria.adultCount = adultCount;
        this.supplierSearchCriteria.teenCount = teenCount;
        this.supplierSearchCriteria.childCount = childrenCount;
        this.supplierSearchCriteria.infantCount = infantCount;
        this.supplierSearchCriteria.serviceStartTime = serviceStartTime;
        this.supplierSearchCriteria.serviceEndTime = serviceEndTime;
        this.supplierSearchCriteria.bookingIdList = bookingIdList;
        this.supplierSearchCriteria.costingType = costingType;
        this.supplierSearchCriteria.noOfUnits = noOfUnits;
        if (row.kcCompany) {
            this.supplierSearchCriteria.kcCompany = row.kcCompany;
        }
        if (row.kcBrand) {
            this.supplierSearchCriteria.kcBrand = row.kcBrand;
        }
        if (row.kcDivision) {
            this.supplierSearchCriteria.kcDivision = row.kcDivision;
        }
        if (row.kcDistributionChannel) {
            this.supplierSearchCriteria.kcDistributionChannel = row.kcDistributionChannel;
        }
        return this.reservationV2ManagementService.searchSuppliersForAllocation(this.supplierSearchCriteria)
            .pipe(map(function (response) {
            var suppliers = ResponseUtil.getDataArray(response);
            var clearSupplier = new SupplierAllocationSummary();
            clearSupplier.supplierName = '-- CLEAR --';
            clearSupplier.nullItemForClearing = true;
            if (!suppliers.find(function (value) { return value.supplierName === clearSupplier.supplierName; })) {
                suppliers.unshift(clearSupplier);
            }
            return suppliers;
        }), catchError(function (error) {
            return [];
        }));
    };
    OperationQueuePanelComponent.prototype.getSuppliersExplicitly = function (row, text, primary, secondary) {
        var e_45, _a;
        var supplierName = text;
        var privateBooking = false;
        var adultCount = 0;
        var teenCount = 0;
        var childrenCount = 0;
        var infantCount = 0;
        var noOfUnits = 0;
        var selectedItems = this.getSelectedItems();
        privateBooking = row.pvtShared === ServiceSharing.PVT;
        if (selectedItems.length < 2) { // no selected items only consider "row"
            selectedItems = [row];
        }
        var serviceDate = row.serviceDate;
        try {
            for (var selectedItems_17 = tslib_1.__values(selectedItems), selectedItems_17_1 = selectedItems_17.next(); !selectedItems_17_1.done; selectedItems_17_1 = selectedItems_17.next()) {
                var item = selectedItems_17_1.value;
                adultCount += item.adultCount;
                teenCount += item.teenCount;
                childrenCount += item.childrenCount;
                infantCount += item.infantCount;
                noOfUnits += item.noOfUnits;
            }
        }
        catch (e_45_1) { e_45 = { error: e_45_1 }; }
        finally {
            try {
                if (selectedItems_17_1 && !selectedItems_17_1.done && (_a = selectedItems_17.return)) _a.call(selectedItems_17);
            }
            finally { if (e_45) throw e_45.error; }
        }
        var serviceType = '';
        if (primary && row.primaryServiceType) {
            serviceType = row.primaryServiceType.code;
        }
        else if (secondary && row.secondaryServiceType && row.secondaryServiceType.code) {
            serviceType = row.secondaryServiceType.code;
        }
        else {
            serviceType = row.genBookingItem ? row.genBookingItem.productCode : (primary ? 'vehicle' : 'driver');
        }
        var profileType = row.genBookingItem ? row.genBookingItem.typeCode : '';
        // const vehicleModel = row.trsBookingItem ? row.trsBookingItem.vehicleType : '';
        var vehicleModel = 'ANY';
        var route = row.trsBookingItem ? row.trsBookingItem.route : '';
        var transferMode = row.trsBookingItem ? row.trsBookingItem.transferMode : '';
        var serviceStartTime = null;
        var serviceEndTime = null;
        if (row.trsBookingItem) {
            serviceStartTime = row.startTime ? row.startTime : null;
            // moment(new Date(row.startTime)).format('HH:mm') :
            // moment(new Date(row.trsBookingItem.departureDateTime)).format('HH:mm');
            serviceEndTime = row.endTime ? row.endTime : null;
            // moment(new Date(row.endTime)).format('HH:mm') :
            // moment(new Date(row.trsBookingItem.arrivalDateTime)).format('HH:mm');
        }
        else {
            serviceStartTime = row.startTime ? row.startTime : null;
            // moment(new Date(row.startTime)).format('HH:mm') :
            // moment(new Date(row.serviceDate)).format('HH:mm');
            serviceEndTime = row.endTime ? row.endTime : null;
            // moment(new Date(row.endTime)).format('HH:mm') :
            // moment(new Date(row.serviceDate)).format('HH:mm');
        }
        var bookingIdList = this.getSelectedBookingIds(selectedItems);
        var costingType = row.costingType && row.costingType.code ? row.costingType.code : row.costingType;
        if (row.kcCompany) {
            this.supplierSearchCriteria.kcCompany = row.kcCompany;
        }
        if (row.kcBrand) {
            this.supplierSearchCriteria.kcBrand = row.kcBrand;
        }
        if (row.kcDivision) {
            this.supplierSearchCriteria.kcDivision = row.kcDivision;
        }
        if (row.kcDistributionChannel) {
            this.supplierSearchCriteria.kcDistributionChannel = row.kcDistributionChannel;
        }
        this.supplierSearchCriteria.supplierName = supplierName;
        this.supplierSearchCriteria.serviceType = serviceType;
        this.supplierSearchCriteria.vehicleModel = vehicleModel;
        this.supplierSearchCriteria.route = route;
        this.supplierSearchCriteria.transferMode = transferMode;
        this.supplierSearchCriteria.profileType = profileType;
        this.supplierSearchCriteria.privateBooking = privateBooking;
        this.supplierSearchCriteria.serviceDate = serviceDate;
        this.supplierSearchCriteria.adultCount = adultCount;
        this.supplierSearchCriteria.teenCount = teenCount;
        this.supplierSearchCriteria.childCount = childrenCount;
        this.supplierSearchCriteria.infantCount = infantCount;
        this.supplierSearchCriteria.serviceStartTime = serviceStartTime;
        this.supplierSearchCriteria.serviceEndTime = serviceEndTime;
        this.supplierSearchCriteria.bookingIdList = bookingIdList;
        this.supplierSearchCriteria.costingType = costingType;
        this.supplierSearchCriteria.noOfUnits = noOfUnits;
        return this.supplierSearchCriteria;
    };
    OperationQueuePanelComponent.prototype.getResources = function (params, row, text, primary, secondary) {
        var e_46, _a;
        var resourceName = text;
        var privateBooking = false;
        var adultCount = 0;
        var teenCount = 0;
        var childrenCount = 0;
        var infantCount = 0;
        var noOfUnits = 0;
        var selectedItems = this.getSelectedItems();
        privateBooking = row.pvtShared === ServiceSharing.PVT;
        if (selectedItems.length < 2) { // no selected items only consider "row"
            selectedItems = [row];
        }
        var serviceDate = row.serviceDate;
        try {
            for (var selectedItems_18 = tslib_1.__values(selectedItems), selectedItems_18_1 = selectedItems_18.next(); !selectedItems_18_1.done; selectedItems_18_1 = selectedItems_18.next()) {
                var item = selectedItems_18_1.value;
                adultCount += item.adultCount;
                teenCount += item.teenCount;
                childrenCount += item.childrenCount;
                infantCount += item.infantCount;
                noOfUnits += item.noOfUnits;
            }
        }
        catch (e_46_1) { e_46 = { error: e_46_1 }; }
        finally {
            try {
                if (selectedItems_18_1 && !selectedItems_18_1.done && (_a = selectedItems_18.return)) _a.call(selectedItems_18);
            }
            finally { if (e_46) throw e_46.error; }
        }
        var serviceType = row.genBookingItem ? row.genBookingItem.productCode : (primary ? 'vehicle' : 'driver');
        if (primary && row.primaryServiceType) {
            serviceType = row.primaryServiceType.code ? row.primaryServiceType.code : serviceType;
        }
        else if (secondary && row.secondaryServiceType) {
            serviceType = row.secondaryServiceType.code ? row.secondaryServiceType.code : serviceType;
        }
        var profileType = row.genBookingItem ? row.genBookingItem.typeCode : '';
        var vehicleModel = 'ANY';
        var route = row.trsBookingItem ? row.trsBookingItem.route : '';
        var transferMode = row.trsBookingItem ? row.trsBookingItem.transferMode : '';
        var serviceStartTime = null;
        var serviceEndTime = null;
        if (row.trsBookingItem) {
            serviceStartTime = row.startTime ? row.startTime : null;
            // moment(new Date(row.startTime)).format('HH:mm') :
            // moment(new Date(row.trsBookingItem.departureDateTime)).format('HH:mm');
            serviceEndTime = row.endTime ? row.endTime : null;
            // moment(new Date(row.endTime)).format('HH:mm') :
            // moment(new Date(row.trsBookingItem.arrivalDateTime)).format('HH:mm');
        }
        else {
            serviceStartTime = row.startTime ? row.startTime : null;
            // moment(new Date(row.startTime)).format('HH:mm') :
            // moment(new Date(row.serviceDate)).format('HH:mm');
            serviceEndTime = row.endTime ? row.endTime : null;
            // moment(new Date(row.endTime)).format('HH:mm') :
            // moment(new Date(row.serviceDate)).format('HH:mm');
        }
        var bookingIdList = this.getSelectedBookingIds(selectedItems);
        var supplierId = -1;
        if (primary && row.primaryAssignment) {
            supplierId = row.primaryAssignment.supplierId;
            serviceType = row.primaryAssignment.resourceType;
        }
        else if (secondary && row.secondaryAssignment) {
            supplierId = row.secondaryAssignment.supplierId;
            serviceType = row.secondaryAssignment.resourceType;
        }
        var costingType = row.costingType && row.costingType.code ? row.costingType.code : row.costingType;
        this.resourceSearchCriteria.supplierId = supplierId;
        this.resourceSearchCriteria.resourceName = resourceName;
        this.resourceSearchCriteria.serviceType = serviceType;
        this.resourceSearchCriteria.vehicleModel = vehicleModel;
        this.resourceSearchCriteria.route = route;
        this.resourceSearchCriteria.transferMode = transferMode;
        this.resourceSearchCriteria.profileType = profileType;
        this.resourceSearchCriteria.privateBooking = privateBooking;
        this.resourceSearchCriteria.serviceDate = serviceDate;
        this.resourceSearchCriteria.adultCount = adultCount;
        this.resourceSearchCriteria.teenCount = teenCount;
        this.resourceSearchCriteria.childCount = childrenCount;
        this.resourceSearchCriteria.infantCount = infantCount;
        this.resourceSearchCriteria.serviceStartTime = serviceStartTime;
        this.resourceSearchCriteria.serviceEndTime = serviceEndTime;
        this.resourceSearchCriteria.bookingIdList = bookingIdList;
        this.resourceSearchCriteria.costingType = costingType;
        this.resourceSearchCriteria.noOfUnits = noOfUnits;
        if (row.kcCompany) {
            this.resourceSearchCriteria.kcCompany = row.kcCompany;
        }
        if (row.kcBrand) {
            this.resourceSearchCriteria.kcBrand = row.kcBrand;
        }
        if (row.kcDivision) {
            this.resourceSearchCriteria.kcDivision = row.kcDivision;
        }
        if (row.kcDistributionChannel) {
            this.resourceSearchCriteria.kcDistributionChannel = row.kcDistributionChannel;
        }
        return this.reservationV2ManagementService.searchResourcesForAllocation(this.resourceSearchCriteria)
            .pipe(map(function (response) {
            var resources = ResponseUtil.getDataArray(response);
            var clearResource = new ResourceAllocationSummary();
            clearResource.resourceName = '-- CLEAR --';
            clearResource.nullItemForClearing = true;
            if (!resources.find(function (value) { return value.resourceName === clearResource.resourceName; })) {
                resources.unshift(clearResource);
            }
            return resources;
        }), catchError(function (error) {
            return [];
        }));
    };
    OperationQueuePanelComponent.prototype.getResourcesExplicitly = function (row, text, primary, secondary) {
        var e_47, _a;
        var resourceName = text;
        var privateBooking = false;
        var adultCount = 0;
        var teenCount = 0;
        var childrenCount = 0;
        var infantCount = 0;
        var noOfUnits = 0;
        var selectedItems = this.getSelectedItems();
        privateBooking = row.pvtShared === ServiceSharing.PVT;
        if (selectedItems.length < 2) { // no selected items only consider "row"
            selectedItems = [row];
        }
        var serviceDate = row.serviceDate;
        try {
            for (var selectedItems_19 = tslib_1.__values(selectedItems), selectedItems_19_1 = selectedItems_19.next(); !selectedItems_19_1.done; selectedItems_19_1 = selectedItems_19.next()) {
                var item = selectedItems_19_1.value;
                adultCount += item.adultCount;
                teenCount += item.teenCount;
                childrenCount += item.childrenCount;
                infantCount += item.infantCount;
                noOfUnits += item.noOfUnits;
            }
        }
        catch (e_47_1) { e_47 = { error: e_47_1 }; }
        finally {
            try {
                if (selectedItems_19_1 && !selectedItems_19_1.done && (_a = selectedItems_19.return)) _a.call(selectedItems_19);
            }
            finally { if (e_47) throw e_47.error; }
        }
        var serviceType = row.genBookingItem ? row.genBookingItem.productCode : (primary ? 'vehicle' : 'driver');
        if (primary && row.primaryServiceType) {
            serviceType = row.primaryServiceType.code ? row.primaryServiceType.code : serviceType;
        }
        else if (secondary && row.secondaryServiceType) {
            serviceType = row.secondaryServiceType.code ? row.secondaryServiceType.code : serviceType;
        }
        var profileType = row.genBookingItem ? row.genBookingItem.typeCode : '';
        var vehicleModel = 'ANY';
        var route = row.trsBookingItem ? row.trsBookingItem.route : '';
        var transferMode = row.trsBookingItem ? row.trsBookingItem.transferMode : '';
        var serviceStartTime = null;
        var serviceEndTime = null;
        if (row.trsBookingItem) {
            serviceStartTime = row.startTime ? row.startTime : null;
            // moment(new Date(row.startTime)).format('HH:mm') :
            // moment(new Date(row.trsBookingItem.departureDateTime)).format('HH:mm');
            serviceEndTime = row.endTime ? row.endTime : null;
            // moment(new Date(row.endTime)).format('HH:mm') :
            // moment(new Date(row.trsBookingItem.arrivalDateTime)).format('HH:mm');
        }
        else {
            serviceStartTime = row.startTime ? row.startTime : null;
            // moment(new Date(row.startTime)).format('HH:mm') :
            // moment(new Date(row.serviceDate)).format('HH:mm');
            serviceEndTime = row.endTime ? row.endTime : null;
            // moment(new Date(row.endTime)).format('HH:mm') :
            // moment(new Date(row.serviceDate)).format('HH:mm');
        }
        var bookingIdList = this.getSelectedBookingIds(selectedItems);
        var supplierId = -1;
        if (primary && row.primaryAssignment) {
            supplierId = row.primaryAssignment.supplierId;
            serviceType = row.primaryAssignment.resourceType;
        }
        else if (secondary && row.secondaryAssignment) {
            supplierId = row.secondaryAssignment.supplierId;
            serviceType = row.secondaryAssignment.resourceType;
        }
        var costingType = row.costingType && row.costingType.code ? row.costingType.code : row.costingType;
        this.resourceSearchCriteria.kcCompany = row.kcCompany;
        this.resourceSearchCriteria.kcBrand = row.kcBrand;
        this.resourceSearchCriteria.kcDivision = row.kcDivision;
        this.resourceSearchCriteria.kcDistributionChannel = row.kcDistributionChannel;
        this.resourceSearchCriteria.supplierId = supplierId;
        this.resourceSearchCriteria.resourceName = resourceName;
        this.resourceSearchCriteria.serviceType = serviceType;
        this.resourceSearchCriteria.vehicleModel = vehicleModel;
        this.resourceSearchCriteria.route = route;
        this.resourceSearchCriteria.transferMode = transferMode;
        this.resourceSearchCriteria.profileType = profileType;
        this.resourceSearchCriteria.privateBooking = privateBooking;
        this.resourceSearchCriteria.serviceDate = serviceDate;
        this.resourceSearchCriteria.adultCount = adultCount;
        this.resourceSearchCriteria.teenCount = teenCount;
        this.resourceSearchCriteria.childCount = childrenCount;
        this.resourceSearchCriteria.infantCount = infantCount;
        this.resourceSearchCriteria.serviceStartTime = serviceStartTime;
        this.resourceSearchCriteria.serviceEndTime = serviceEndTime;
        this.resourceSearchCriteria.bookingIdList = bookingIdList;
        this.resourceSearchCriteria.costingType = costingType;
        this.resourceSearchCriteria.noOfUnits = noOfUnits;
        if (row.kcCompany) {
            this.resourceSearchCriteria.kcCompany = row.kcCompany;
        }
        if (row.kcBrand) {
            this.resourceSearchCriteria.kcBrand = row.kcBrand;
        }
        if (row.kcDivision) {
            this.resourceSearchCriteria.kcDivision = row.kcDivision;
        }
        if (row.kcDistributionChannel) {
            this.resourceSearchCriteria.kcDistributionChannel = row.kcDistributionChannel;
        }
    };
    /**
     * ends ag-grid value-setter
     */
    OperationQueuePanelComponent.prototype.getSelectedItemsForSupplierReject = function () {
        return [];
    };
    OperationQueuePanelComponent.prototype.getSelectedItemsForSupplierConfirm = function () {
        return [];
    };
    OperationQueuePanelComponent.prototype.isInSavableState = function (assignment) {
        return assignment && (assignment.assignStatus === AssignStatus.ASSIGNED ||
            assignment.assignStatus === AssignStatus.PENDING);
    };
    OperationQueuePanelComponent.prototype.isInConfirmableState = function (assignment) {
        return assignment && (assignment.assignStatus === AssignStatus.ASSIGNED ||
            assignment.assignStatus === AssignStatus.PENDING ||
            assignment.assignStatus === AssignStatus.REQUEST_EXPIRED ||
            assignment.assignStatus === AssignStatus.SUPPLIER_REJECTED ||
            assignment.assignStatus === AssignStatus.BOOKING_UPDATE);
    };
    OperationQueuePanelComponent.prototype.isInSupplierConfirmableState = function (assignment) {
        return assignment && (assignment.assignStatus === AssignStatus.DMC_CONFIRM);
    };
    OperationQueuePanelComponent.prototype.isInSupplierRejectableState = function (assignment) {
        return assignment && (assignment.assignStatus === AssignStatus.DMC_CONFIRM ||
            assignment.assignStatus === AssignStatus.SUPPLIER_CONFIRMED);
    };
    // subscribe allocations
    OperationQueuePanelComponent.prototype.subscribeAllocationResults = function () {
        var _this = this;
        this.resourceAllocationResultsSubscription = this.dataStore.get(DataKey.selectedResourceForAllocation)
            .subscribe(function (data) {
            if (data) {
                _this.selectedResource = data;
                var selectedAssignment = data.existingAssignments.find(function (value) { return value.selected; });
                // selectedAssignment.resourceObj = data;
                selectedAssignment.temporaryAssignmentId = _this.rawAssignmentTempId++;
                _this.setAssignmentToItems(_this.itemList, selectedAssignment, data.isPrimaryAssignment, data.isSecondaryAssignment, true);
            }
        });
        this.supplierAllocationResultsSubscription = this.dataStore.get(DataKey.selectedSupplierForAllocation)
            .subscribe(function (data) {
            if (data) {
                var selectedAssignment = new AssignmentRawData();
                // selectedAssignment.supplierObj = data;
                _this.setSupplierToItems(_this.itemList, selectedAssignment, data, data.isPrimaryAssignment, data.isSecondaryAssignment, true);
            }
        });
    };
    OperationQueuePanelComponent.prototype.subscribeForDateRange = function (params, fromAssignmentClick, bookingIdList, isPrimary, isSecondary) {
        var _this = this;
        if (fromAssignmentClick === void 0) { fromAssignmentClick = false; }
        if (bookingIdList === void 0) { bookingIdList = []; }
        if (isPrimary === void 0) { isPrimary = true; }
        if (isSecondary === void 0) { isSecondary = false; }
        this.allocationTimeRangeSubscription = this.dataStore.get(DataKey.ResourceAllocationTimeRange)
            .subscribe(function (data) {
            if (data != null) {
                _this.allocationTimeRangeSubscription.unsubscribe();
                var item_1 = params.data;
                item_1.startTime = data.startTime;
                item_1.endTime = data.endTime;
                var bookingIds = data.bookingIds;
                var bookingItemPatch = new BookingItemPatch();
                bookingItemPatch.serviceStartDateTime = item_1.startTime;
                bookingItemPatch.serviceEndDateTime = item_1.endTime;
                _this.reservationV2ManagementService.patchBookingItems(bookingIds, bookingItemPatch)
                    .subscribe(function (res) {
                    if (item_1.costingType && item_1.costingType.code ===
                        CostingType.DURATION) {
                        item_1.noOfUnits = _this.getDuration(item_1);
                        _this.updateCostingUnits(item_1);
                    }
                    _this.refreshGridView();
                    _this.cd.detectChanges();
                    console.log(res);
                    _this.common.showSnackBar('Added start time & end time to booking item/items', 3000, TcErrorType.TYPE.INFO);
                }, function (error) {
                    console.log(error);
                    _this.common.showSnackBar('Failed to add start time and end time to booking item/items', 3000, TcErrorType.TYPE.ERROR);
                });
                if (fromAssignmentClick) {
                    _this.openAllocationFocusView(item_1, bookingIdList, isPrimary, isSecondary);
                }
            }
        });
    };
    OperationQueuePanelComponent.prototype.getSelectedBookingIds = function (items) {
        var e_48, _a;
        var bookingIds = [];
        try {
            for (var items_5 = tslib_1.__values(items), items_5_1 = items_5.next(); !items_5_1.done; items_5_1 = items_5.next()) {
                var item = items_5_1.value;
                if (item.bookingItemSummaryList && item.bookingItemSummaryList.length > 0) {
                    item.bookingItemSummaryList.forEach(function (value) { return bookingIds.push(value.bookingItemId); });
                }
                else {
                    bookingIds.push(item.id);
                }
            }
        }
        catch (e_48_1) { e_48 = { error: e_48_1 }; }
        finally {
            try {
                if (items_5_1 && !items_5_1.done && (_a = items_5.return)) _a.call(items_5);
            }
            finally { if (e_48) throw e_48.error; }
        }
        return bookingIds;
    };
    OperationQueuePanelComponent.prototype.selectItems = function (items) {
        if (this.isBookingQueue()) {
            this.bookingQueueComponent.selectRows(items);
        }
        else {
            this.serviceQueueComponent.selectRows(items);
        }
        this.enableConfirmButton();
    };
    OperationQueuePanelComponent.prototype.isEditableAssignStatus = function (primaryAssignment) {
        return true;
    };
    OperationQueuePanelComponent.prototype.openTotalCostEditor = function (data) {
        var _this = this;
        var costLines = [];
        this.dataStore.set(DataKey.assignmentTotalCost, null, true);
        this.createTotalCostLines(data, costLines);
        this.dataStore.set(DataKey.popupClose, null);
        var editableCost = this.isEditableType(data, true);
        var dataObject = new ModalData(LEVEL.SUCCESS, 'Total Cost Editor', null, null, 'modal-basic--medium', TotalCostEditorComponent, {
            rowData: costLines,
            editable: editableCost
        });
        this.subscribeForCostUpdate(data);
        this.confirmModal(dataObject);
        if (this.popupSubscription) {
            this.popupSubscription.unsubscribe();
        }
        this.popupSubscription = this.dataStore.get(DataKey.popupClose).subscribe(function (dataS) {
            if (dataS != null) {
                _this.closeModal();
                if (dataS) {
                }
            }
        });
    };
    OperationQueuePanelComponent.prototype.createTotalCostLines = function (data, costLines) {
        if (data && data.primaryAssignment && data.itemCostPortion.primaryJobCost) {
            var costData = {
                serviceType: data.primaryAssignment.resourceType,
                currency: data.primaryAssignment.currency,
                type: 'PRIMARY',
                totalCost: data.itemCostPortion.primaryJobCost.totalCost,
                estimatedCost: data.itemCostPortion.primaryJobCost.estimatedCost ?
                    data.itemCostPortion.primaryJobCost.estimatedCost :
                    data.primaryAssignment.estimatedCost
            };
            costLines.push(costData);
        }
        if (data && data.secondaryAssignment && data.itemCostPortion.secondaryJobCost) {
            var costData = {
                serviceType: data.secondaryAssignment.resourceType,
                currency: data.secondaryAssignment.currency,
                type: 'SECONDARY',
                totalCost: data.itemCostPortion.secondaryJobCost.totalCost,
                estimatedCost: data.itemCostPortion.secondaryJobCost.estimatedCost ?
                    data.itemCostPortion.secondaryJobCost.estimatedCost :
                    data.secondaryAssignment.estimatedCost
            };
            costLines.push(costData);
        }
    };
    OperationQueuePanelComponent.prototype.subscribeForCostUpdate = function (item) {
        var _this = this;
        var subscribeTotalCost = this.dataStore.get(DataKey.assignmentTotalCost).subscribe(function (data) {
            if (data != null && data.length > 0) {
                subscribeTotalCost.unsubscribe();
                var totalCost_1 = 0;
                data.forEach(function (costData) {
                    var isEditedCost = false;
                    var assignmentIds = [];
                    if (item.itemCostPortion && item.itemCostPortion.primaryJobCost && costData.type === 'PRIMARY' &&
                        item.itemCostPortion.primaryJobCost.totalCost !== costData.totalCost) {
                        item.itemCostPortion.primaryJobCost.totalCost = costData.totalCost;
                        assignmentIds.push(item.primaryAssignment.assignmentId);
                        totalCost_1 += +item.itemCostPortion.primaryJobCost.totalCost;
                        isEditedCost = true;
                    }
                    else if (item.itemCostPortion && item.itemCostPortion.secondaryJobCost && costData.type ===
                        'SECONDARY' &&
                        item.itemCostPortion.secondaryJobCost.totalCost !== costData.totalCost) {
                        item.itemCostPortion.secondaryJobCost.totalCost = costData.totalCost;
                        assignmentIds.push(item.secondaryAssignment.assignmentId);
                        totalCost_1 += +item.itemCostPortion.secondaryJobCost.totalCost;
                        isEditedCost = true;
                    }
                    if (isEditedCost) {
                        isEditedCost = false;
                        _this.refreshGridView();
                        if (assignmentIds.length > 0) {
                            var assignmentPatch = new AssignmentPatch();
                            assignmentPatch.jobCost = costData.totalCost;
                            _this.reservationV2ManagementService.patchAssignmentsData(assignmentIds, assignmentPatch)
                                .subscribe(function (res) {
                                console.log(res);
                                _this.reloadGridData();
                                _this.common.showSnackBar('Successfully updated the job cost', 3000, TcErrorType.TYPE.INFO);
                            }, function (error) {
                                console.log(error);
                                _this.reloadGridData();
                                _this.common.showSnackBar('Failed to update job cost', 3000, TcErrorType.TYPE.ERROR);
                            });
                        }
                    }
                });
                item.itemCostPortion.totalCost = totalCost_1 > 0 ? totalCost_1 : item.itemCostPortion.totalCost;
            }
        });
    };
    OperationQueuePanelComponent.prototype.onSaveCriteriaClick = function () {
        var _this = this;
        this.dataStore.set(DataKey.popupClose, null);
        var dataObject = new ModalData(LEVEL.SUCCESS, 'Save Criteria', null, null, 'modal-basic--alt modal-basic--fixed-size', SearchCriteriaSaveModalComponent, { searchCriteriaV2: this.productQueueSearchCriteria });
        dataObject.disableClose = true;
        var secondaryHeader = '';
        dataObject.secondaryHeader = secondaryHeader;
        this.confirmModal(dataObject);
        if (this.popupSubscription) {
            this.popupSubscription.unsubscribe();
        }
        this.popupSubscription = this.dataStore.get(DataKey.popupClose).subscribe(function (data) {
            if (data != null) {
                _this.closeModal();
            }
        });
    };
    OperationQueuePanelComponent.prototype.onShowSavedCriteriaClick = function () {
        this.operationV2DataProcessorService.onShowSavedCriteriaClick();
    };
    OperationQueuePanelComponent.prototype.updateJobCosts = function (item) {
        if (item && item.itemCostPortion && item.noOfUnits > 0) {
            var primaryJobCost = 0;
            var secondaryJobCost = 0;
            if (item.itemCostPortion.primaryJobCost && item.itemCostPortion.primaryJobCost.unitCost > 0) {
                item.itemCostPortion.primaryJobCost.totalCost = item.itemCostPortion.primaryJobCost.unitCost *
                    item.noOfUnits;
                item.primaryAssignment.jobCost = item.primaryAssignment.unitFare * item.noOfUnits;
                primaryJobCost = item.itemCostPortion.primaryJobCost.totalCost;
            }
            if (item.itemCostPortion.secondaryJobCost && item.itemCostPortion.secondaryJobCost.unitCost > 0) {
                item.itemCostPortion.secondaryJobCost.totalCost = item.itemCostPortion.secondaryJobCost.unitCost *
                    item.noOfUnits;
                item.secondaryAssignment.jobCost = item.secondaryAssignment.unitFare * item.noOfUnits;
                secondaryJobCost = item.itemCostPortion.secondaryJobCost.totalCost;
            }
            item.itemCostPortion.totalCost = primaryJobCost + secondaryJobCost;
            this.refreshGridView();
        }
    };
    OperationQueuePanelComponent.prototype.updateCostingUnits = function (item) {
        var _this = this;
        var assignmentPatch = new AssignmentPatch();
        assignmentPatch.noOfUnits = item.noOfUnits;
        this.updateJobCosts(item);
        var assignmentIds = [];
        if (item.itemCostPortion && item.itemCostPortion.primaryJobCost) {
            assignmentPatch.jobCost = item.itemCostPortion.primaryJobCost.totalCost;
            if (item.primaryAssignment.assignmentId > 0) {
                assignmentIds.push(item.primaryAssignment.assignmentId);
            }
        }
        else if (item.itemCostPortion && item.itemCostPortion.secondaryJobCost) {
            assignmentPatch.jobCost = item.itemCostPortion.secondaryJobCost.totalCost;
            if (item.secondaryAssignment.assignmentId > 0) {
                assignmentIds.push(item.secondaryAssignment.assignmentId);
            }
        }
        if (assignmentIds.length > 0) {
            this.reservationV2ManagementService.patchAssignmentsData(assignmentIds, assignmentPatch)
                .subscribe(function (res) {
                _this.common.showSnackBar('Successfully updated the assignment', 3000, TcErrorType.TYPE.INFO);
            }, function (error) {
                console.log(error);
                _this.common.showSnackBar('Failed to update the assignment', 3000, TcErrorType.TYPE.ERROR);
            });
        }
        if (item && item.noOfUnits > 1 && item.costingType &&
            (item.costingType.code === CostingType.UNIT || item.costingType.code === CostingType.PERSON)) {
            var selectedItems = this.getSelectedItems();
            if (selectedItems.length < 2) { // no selected items only consider "row"
                selectedItems = [item];
            }
            this.removeResourceFromItems(selectedItems, true, false);
            this.selectItems(selectedItems);
            this.markChanges();
        }
    };
    OperationQueuePanelComponent.prototype.updateSecondaryCostingUnits = function (item) {
        var _this = this;
        var assignmentPatch = new AssignmentPatch();
        assignmentPatch.noOfUnits = item.secondaryNoOfUnits;
        this.updateJobCosts(item);
        var assignmentIds = [];
        if (item.itemCostPortion && item.itemCostPortion.secondaryJobCost) {
            assignmentPatch.jobCost = item.itemCostPortion.secondaryJobCost.totalCost;
            if (item.secondaryAssignment.assignmentId > 0) {
                assignmentIds.push(item.secondaryAssignment.assignmentId);
            }
        }
        if (assignmentIds.length > 0) {
            this.reservationV2ManagementService.patchAssignmentsData(assignmentIds, assignmentPatch)
                .subscribe(function (res) {
                _this.common.showSnackBar('Successfully updated the assignment', 3000, TcErrorType.TYPE.INFO);
            }, function (error) {
                console.log(error);
                _this.common.showSnackBar('Failed to update the assignment', 3000, TcErrorType.TYPE.ERROR);
            });
        }
        if (item && item.secondaryNoOfUnits > 1 && item.secondaryCostingType &&
            (item.secondaryCostingType.code === CostingType.UNIT || item.secondaryCostingType.code ===
                CostingType.PERSON)) {
            var selectedItems = this.getSelectedItems();
            if (selectedItems.length < 2) { // no selected items only consider "row"
                selectedItems = [item];
            }
            this.removeResourceFromSecondaryItems(selectedItems, false, true);
            this.selectItems(selectedItems);
            this.markChanges();
        }
    };
    OperationQueuePanelComponent.prototype.getDuration = function (item) {
        if (item.startTime && item.endTime) {
            var timeDiff = new Date(item.endTime).getTime() - new Date(item.startTime).getTime();
            var timeInHours = timeDiff > 0 ? new Date(timeDiff).getUTCHours() : 1;
            return timeDiff > 0 && new Date(timeDiff).getUTCMinutes() > 0 ? ++timeInHours : timeInHours;
        }
        return 1;
    };
    OperationQueuePanelComponent.prototype.validateUserPanels = function () {
        this.userBusinessType = this.localStorage.load(LocalDataKey.userBusinessType);
        if (this.userBusinessType === UserBusinessType.EXTERNAL) {
            // hide panels
            this.showServicingQueue = false;
            this.showConfirmButton = false;
            this.showAutoAllocationButton = false;
            // disabling
            this.saveButtonEnable = false;
            this.revertButtonEnable = false;
        }
    };
    OperationQueuePanelComponent.prototype.setupPageOptions = function () {
        if (this.pageOptionsAvailability) {
            // hide panels
            this.showServicingQueue = this.pageOptionsAvailability.show_servicing_queue;
            this.showConfirmButton = this.pageOptionsAvailability.show_confirm_button;
            this.showAutoAllocationButton = this.pageOptionsAvailability.show_auto_allocation_button;
            this.showSecondaryJobStatus = this.pageOptionsAvailability.show_secondary_job_status;
            // disabling
            this.saveButtonEnable = this.pageOptionsAvailability.save_button_enable;
            this.revertButtonEnable = this.pageOptionsAvailability.revert_button_enable;
            this.showManifestStatusAllocQueue = this.pageOptionsAvailability.show_manifest_status_alloc_queue;
        }
    };
    OperationQueuePanelComponent.prototype.ngOnDestroy = function () {
        if (this.productSearchCriteriaSubscription) {
            this.productSearchCriteriaSubscription.unsubscribe();
        }
        if (this.resourceTypesSubscription) {
            this.resourceTypesSubscription.unsubscribe();
        }
        if (this.costingTypesSubscription) {
            this.costingTypesSubscription.unsubscribe();
        }
        if (this.languagesSubscription) {
            this.languagesSubscription.unsubscribe();
        }
        if (this.trsModesSubscription) {
            this.trsModesSubscription.unsubscribe();
        }
        if (this.routesSubscription) {
            this.routesSubscription.unsubscribe();
        }
        if (this.productSearchCriteriaSubscription) {
            this.productSearchCriteriaSubscription.unsubscribe();
        }
        if (this.popupSubscription) {
            this.popupSubscription.unsubscribe();
        }
        if (this.modelConfirmSubscription) {
            this.modelConfirmSubscription.unsubscribe();
        }
        if (this.modelCloseSubscription) {
            this.modelCloseSubscription.unsubscribe();
        }
        if (this.dialogSubscription) {
            this.dialogSubscription.unsubscribe();
        }
        if (this.resourceAllocationResultsSubscription) {
            this.resourceAllocationResultsSubscription.unsubscribe();
        }
        if (this.supplierAllocationResultsSubscription) {
            this.supplierAllocationResultsSubscription.unsubscribe();
        }
        this.em.removeEvents([
            'SAVE_ASSIGNMENTS',
            'REVERT_ASSIGNMENTS',
            'CONFIRM_ASSIGNMENTS',
            'SEND_MANIFESTS',
            'SUP_CONFIRM_ASSIGNMENTS',
            'SUP_REJECT_ASSIGNMENTS',
            'GENERATE_DOCUMENTS',
            'GENERATE_AUTO_ALLOCATIONS'
        ]);
    };
    return OperationQueuePanelComponent;
}());
export { OperationQueuePanelComponent };
