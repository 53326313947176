import { AfterViewInit, Directive, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { ButtonState } from './button-state';

@Directive({
    selector: '[tcPaginatorCustomizer]'
})
export class PaginatorCustomizerDirective implements AfterViewInit, OnChanges, OnDestroy {

    @Input() paginatorLoaded: false;
    @Input() buttonStateObs: Observable<ButtonState[]>;

    @Output() clickMore: EventEmitter<any> = new EventEmitter();
    @Output() clickSave: EventEmitter<any> = new EventEmitter();
    @Output() clickConfirm: EventEmitter<any> = new EventEmitter();
    @Output() clickSupplierReject: EventEmitter<any> = new EventEmitter();

    private htmlMoreButtonElement;
    private htmlSaveButtonElement;
    private htmlConfirmButtonElement;
    private htmlRejectButtonElement;

    private buttonsAdded = false;

    enableButtonClass = 'ag-grid-enabled-custom-btn';
    disableButtonClass = 'ag-grid-disabled-custom-btn';
    buttonStateSubscription: Subscription;

    paginator: HTMLElement;

    moreButton =
        `
        More
    `;
    saveButton =
        `
        Save
    `;
    confirmButton =
        `
        Confirm
    `;
    supplierRejectButton =
        `
        Supplier Reject
    `;

    constructor(
        private el: ElementRef
    ) {}

    onMoreClick = () => {
        this.clickMore.emit();
    };

    onSaveClick = () => {
        this.clickSave.emit();
    };

    onConfirmClick = () => {
        this.clickConfirm.emit();
    };

    onSupplierRejectClick = () => {
        this.clickSupplierReject.emit();
    };

    ngAfterViewInit(): void {
        this.addCustomButtons();
    }

    addCustomButtons() {
        if (!this.buttonsAdded) {
            this.paginator = this.el.nativeElement.querySelector('.ag-paging-panel') as HTMLElement;
            if (this.paginator) {

                // more button
                this.htmlMoreButtonElement = document.createElement('button');
                this.htmlMoreButtonElement.classList.add("mat-button", "rs-footer-section__button", "tc-button-primary");
                this.htmlMoreButtonElement.innerHTML = this.moreButton;
                this.htmlMoreButtonElement.addEventListener('click', this.onMoreClick, true);
                this.paginator.appendChild(this.htmlMoreButtonElement);

                // save button
                this.htmlSaveButtonElement = document.createElement('button');
                this.htmlSaveButtonElement.classList.add("mat-button", "rs-footer-section__button", "tc-button-primary");
                this.htmlSaveButtonElement.innerHTML = this.saveButton;
                this.htmlSaveButtonElement.addEventListener('click', this.onSaveClick, true);
                this.paginator.appendChild(this.htmlSaveButtonElement);

                // confirm button
                this.htmlConfirmButtonElement = document.createElement('button');
                this.htmlConfirmButtonElement.classList.add("mat-button", "rs-footer-section__button", "tc-button-primary");
                this.htmlConfirmButtonElement.innerHTML = this.confirmButton;
                this.htmlConfirmButtonElement.addEventListener('click', this.onConfirmClick, true);
                this.paginator.appendChild(this.htmlConfirmButtonElement);

                // supplier reject button
                this.htmlRejectButtonElement = document.createElement('button');
                this.htmlRejectButtonElement.classList.add("mat-button", "rs-footer-section__button", "tc-button-primary");
                this.htmlRejectButtonElement.innerHTML = this.supplierRejectButton;
                this.htmlRejectButtonElement.addEventListener('click', this.onSupplierRejectClick, true);
                this.paginator.appendChild(this.htmlRejectButtonElement);

                this.subscribeButtonStateChange();

                this.buttonsAdded = true;
            } else {
                setTimeout(() => this.addCustomButtons(), 0);
            }
        }
    }

    subscribeButtonStateChange() {
        if (this.buttonStateObs) {
            this.buttonStateSubscription = this.buttonStateObs.subscribe(
                next => {
                    next.forEach(
                        buttonState => {
                            if (buttonState && buttonState.state === 'enable') {
                                if (buttonState.buttonId === 'more') {
                                    this.enableButton(this.htmlMoreButtonElement);
                                } else if (buttonState.buttonId === 'save') {
                                    this.enableButton(this.htmlSaveButtonElement);
                                } else if (buttonState.buttonId === 'confirm') {
                                    this.enableButton(this.htmlConfirmButtonElement);
                                } else if (buttonState.buttonId === 'supplierReject') {
                                    this.enableButton(this.htmlRejectButtonElement);
                                }
                            } else if (buttonState && buttonState.state === 'disable') {
                                if (buttonState.buttonId === 'more') {
                                    this.disableButton(this.htmlMoreButtonElement);
                                } else if (buttonState.buttonId === 'save') {
                                    this.disableButton(this.htmlSaveButtonElement);
                                } else if (buttonState.buttonId === 'confirm') {
                                    this.disableButton(this.htmlConfirmButtonElement);
                                }else if (buttonState.buttonId === 'supplierReject') {
                                    this.disableButton(this.htmlRejectButtonElement);
                                }
                            }
                        }
                    );
                }
            );
        }
    }

    public ngOnChanges(changes: SimpleChanges): void {
        for (let propName in changes) {
            if (propName === 'paginatorLoaded') {
                this.addCustomButtons();
            }
        }
    }

    enableButton(htmlButtonElement) {
        // if (htmlButtonElement && htmlButtonElement.classList) {
            // htmlButtonElement.classList.remove(this.disableButtonClass);
            // htmlButtonElement.classList.add(this.enableButtonClass);
        // }

        if (htmlButtonElement && htmlButtonElement.hasAttribute("disabled")) {
            htmlButtonElement.disabled = false;
        }
    }

    disableButton(htmlButtonElement) {
        // if (htmlButtonElement && htmlButtonElement.classList) {
        //     htmlButtonElement.classList.remove(this.enableButtonClass);
        //     htmlButtonElement.classList.add(this.disableButtonClass);
        // }
        if (htmlButtonElement && !htmlButtonElement.hasAttribute("disabled")) {
            htmlButtonElement.disabled = true;
        }
    }

    public ngOnDestroy(): void {
        if (this.htmlMoreButtonElement) {
            this.htmlMoreButtonElement.removeEventListener('click', this.onMoreClick);
        }
        if (this.htmlSaveButtonElement) {
            this.htmlSaveButtonElement.removeEventListener('click', this.onSaveClick);
        }
        if (this.htmlConfirmButtonElement) {
            this.htmlConfirmButtonElement.removeEventListener('click', this.onConfirmClick);
        }
        if (this.htmlRejectButtonElement) {
            this.htmlRejectButtonElement.removeEventListener('click', this.onSupplierRejectClick);
        }
        if (this.buttonStateSubscription) {
            this.buttonStateSubscription.unsubscribe();
        }
    }
}
