import { Component } from '@angular/core';
import { TCO } from '../../../../constants';
import { DocumentSearchCriteria } from '../../../../models/criteria/document-search-criteria';
import { DataKey } from '../../../../services/util/framework/data-store.service';

@Component({
    selector: 'tc-reports-search-criteria',
    templateUrl: './reports-search-criteria.component.html'
})
export class ReportsSearchCriteriaComponent {

    inputSearchCriteria = new DocumentSearchCriteria(false, true);
    searchCriteriaUpdatingDataKey = DataKey.documentSearchCriteria;
    searchResultsDataKey = DataKey.documentSearchResultsForCriteria;
    searchCriteriaLoadingDataKey = TCO.AppData.REPORTS_SEARCH_CRITERIA;
    chipConfigKey = TCO.CONF.CONF_REPORTS_SEARCH_CRITERIA_CHIP;
    placeHolderText = 'Search Reports';

    createNewSearchCriteriaFn = () => {
        return new DocumentSearchCriteria(false, true);
    }

    clearLoadedSearchCriteriaFn = (searchCriteria: DocumentSearchCriteria) => {
        searchCriteria.documentType = [];
        searchCriteria.reportsOnly = true;
        searchCriteria.manifestsOnly = false;
    }
}
