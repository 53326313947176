import { Component, Input, OnInit } from '@angular/core';
import { CurrencyFormat } from '@tc-core/model/it/codegen/ui/framework/currency-format';
import { ConfigLoader } from '@tc-core/util/framework';
import { DataKey, DataStoreService } from '../../../services/util/framework/data-store.service';

@Component({
    selector: 'tc-total-cost-editor',
    templateUrl: './total-cost-editor.component.html'
})
export class TotalCostEditorComponent implements OnInit {

    @Input() rowData: any[] =[];
    @Input() editable: boolean = true;
    currencyFormat: CurrencyFormat;
    gridApi;
    columnDefs;
    EMPTY_STRING = '--';

    primaryCost = null;
    secondaryCost = null;

    currency = '';

    constructor(
        private dataStore: DataStoreService,
        private configLoader: ConfigLoader
    ) { }

    ngOnInit() {
        this.getColumnDefs();
        for (const rowData of this.rowData) {
            if (rowData && rowData.type === 'PRIMARY') {
                this.primaryCost = rowData;
                this.currency = rowData.currency;
            }
            if (rowData && rowData.type === 'SECONDARY') {
                this.secondaryCost = rowData;
                this.currency = rowData.currency;
            }
        }
    }

    public onSave() {
        this.dataStore.set(DataKey.popupClose, true);
        this.dataStore.set(DataKey.assignmentTotalCost, this.rowData);
    }

    public onGridReadyAssignmentNotes(params: any) {
        this.gridApi = params.api;
        this.gridApi.sizeColumnsToFit();
    }

    onFirstDataRendered(params) {
        this.gridApi = params.api;
        this.gridApi.sizeColumnsToFit();
    }

    public onClose($event: MouseEvent) {
        this.dataStore.set(DataKey.popupClose, false);
    }

    public isSaveButtonEnable() {
        return true;
    }

    // The numeric value setter function/method
    private numericSetter(params) {
        if (params.newValue > 0 || params.newValue === '') {
            params.data[params.colDef.field] = params.newValue;
            return true;
        }
        // Illegal value - signal no change
        return false;
    }

    private getTotalCost(params) {
        if (params.data.totalCost) {
            return params.data.totalCost.toFixed(2);
        } else {
            return this.EMPTY_STRING;
        }
    }

    getTotalSum() {
        let sum = 0;
        if (this.primaryCost) {
            sum += this.primaryCost.totalCost;
        }
        if (this.secondaryCost) {
            sum += this.secondaryCost.totalCost;
        }
        return sum.toFixed(2);
    }

    private getColumnDefs() {
        this.columnDefs = [
            {
                headerName: 'Service Type',
                field: 'serviceType',
                minWidth: 100,
                sortable: false,
                editable: false,
                resizable: true,
            },
            {
                headerName: 'Type',
                field: 'type',
                minWidth: 100,
                sortable: false,
                editable: false,
                resizable: true
            },
            {
                headerName: 'Estimated Cost',
                field: 'estimatedCost',
                minWidth: 100,
                sortable: false,
                editable: false,
                resizable: true,
                type: 'numericColumn',
                valueSetter: this.numericSetter,
                valueGetter: function(params) {
                    if (params.data.estimatedCost) {
                        return parseFloat(params.data.estimatedCost).toFixed(2);
                    } else {
                        return '--';
                    }
                },
            },
            {
                headerName: 'Total Cost',
                field: 'totalCost',
                minWidth: 100,
                sortable: true,
                editable: this.editable,
                resizable: true,
                type: 'numericColumn',
                valueSetter: this.numericSetter,
                valueGetter: function(params) {
                    if (params.data.totalCost) {
                        return parseFloat(params.data.totalCost).toFixed(2);
                    } else {
                        return '--';
                    }
                },
                cellClass: [
                    "total-cost"
                ],
            }
        ];
    }

    public onSecondaryCostUpdate(event: any) {
        if (event.target && event.target.value && (event.target.value > 0 || event.target.value.value === '')) {
            this.secondaryCost.totalCost = parseFloat(event.target.value);
        }
    }

    public onPrimaryCostUpdate(event: any) {
        if (event.target && event.target.value && (event.target.value > 0 || event.target.value.value === '')) {
            this.primaryCost.totalCost = parseFloat(event.target.value);
        }
    }
}
