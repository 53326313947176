import * as tslib_1 from "tslib";
import { OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { TC } from '@tc-core/util/constants';
import { ConfigLoader } from '@tc-core/util/framework';
import { EventManager } from '@tc-core/util/framework/event-manager.service';
import { FocusViewManager } from '@tc-core/util/framework/focus-view-manager.service';
import { FocusViewService } from '@tc/focus-view/focus-view.service';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SetupGridComp } from '../../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid-comp';
import { SetupGridComponent } from '../../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid.component';
import { TCO } from '../../../../constants';
import { JobSearchCriteria } from '../../../../models/criteria/job-search-criteria';
import { SortDirection } from '../../../../models/helper/sort-direction';
import { JobHistoryStatus } from '../../../../models/job/job-history-status';
import { JobService } from '../../../../services/backend-consumers/job/job-service';
import { DataKey, DataStoreService } from '../../../../services/util/framework/data-store.service';
var CommonJobHistoryGridComponent = /** @class */ (function (_super) {
    tslib_1.__extends(CommonJobHistoryGridComponent, _super);
    function CommonJobHistoryGridComponent(configLoader, jobService, dataStoreService, em, focusViewManager, focusViewService) {
        var _this = _super.call(this) || this;
        _this.configLoader = configLoader;
        _this.jobService = jobService;
        _this.dataStoreService = dataStoreService;
        _this.em = em;
        _this.focusViewManager = focusViewManager;
        _this.focusViewService = focusViewService;
        _this.colDefConfig = [];
        _this.searchCriteria = new JobSearchCriteria();
        _this.searchCriteriaObserver = new Subscription();
        _this.statusStarted = function (params) {
            return params.data &&
                (params.data.historyStatus === JobHistoryStatus.STARTED || params.data.historyStatus === JobHistoryStatus.RUNNING);
        };
        _this.statusFailed = function (params) {
            return params.data && params.data.historyStatus === JobHistoryStatus.FAILED;
        };
        _this.statusFinished = function (params) {
            return params.data && params.data.historyStatus === JobHistoryStatus.FINISHED;
        };
        _this.statusCanceled = function (params) {
            return params.data && params.data.historyStatus === JobHistoryStatus.CANCELED;
        };
        _this.statusTerminated = function (params) {
            return params.data && params.data.historyStatus === JobHistoryStatus.TERMINATED;
        };
        return _this;
    }
    CommonJobHistoryGridComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.colDefConfig = this.configLoader.configurations.get(TCO.CONF.CONF_JOB_HISTORY_SETUP);
        this.subscribeSearchCriteria();
        this.focusViewManager.canProceed.next(true);
        this.focusViewManager.canProceedState.next(true);
        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'CLOSE_FOCUS_VIEW')
            .subscribe(function (e) {
            _this.focusViewService.close(true);
        });
    };
    CommonJobHistoryGridComponent.prototype.getRows = function (params) {
        var _this = this;
        var pageSize = params.endRow - params.startRow;
        if (!this.searchCriteria) {
            this.searchCriteria = new JobSearchCriteria();
        }
        this.searchCriteria.start = params.startRow;
        this.searchCriteria.size = pageSize;
        if (params.sortModel && params.sortModel[0]) {
            this.searchCriteria.sortBy = params.sortModel[0].colId;
            if (params.sortModel[0].sort === SetupGridComp.GRID_SORT_ASCENDING) {
                this.searchCriteria.sortDirection = SortDirection.ASC;
            }
            else {
                this.searchCriteria.sortDirection = SortDirection.DESC;
            }
        }
        else {
            this.searchCriteria.sortDirection = SortDirection.DESC;
        }
        this.searchCriteria.jobName = this.jobName;
        return this.jobService.getCommonJobHistories(this.searchCriteria)
            .pipe(tap(function (data) {
            return _this.dataStoreService.set(DataKey.commonJobHistorySearchResults, data);
        }));
    };
    CommonJobHistoryGridComponent.prototype.subscribeSearchCriteria = function () {
        var _this = this;
        this.searchCriteriaObserver = this.dataStoreService.get(DataKey.commonJobHistorySearchCriteria)
            .subscribe(function (value) {
            _this.searchCriteria = value;
            _this.setupGrid.runForceSearch();
        });
    };
    CommonJobHistoryGridComponent.prototype.displayStatus = function (status) {
        var splitsByUnderscore = status.split('_');
        if (splitsByUnderscore.length > 1) {
            return splitsByUnderscore.map(function (part) { return part.charAt(0).toUpperCase() + part.slice(1).toLowerCase(); }).join(' ');
        }
        return status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();
    };
    CommonJobHistoryGridComponent.prototype.ngOnDestroy = function () {
        if (this.searchCriteriaObserver) {
            this.searchCriteriaObserver.unsubscribe();
        }
    };
    /**
     * Not applicable to jobs
     */
    CommonJobHistoryGridComponent.prototype.saveRow = function (row, event) {
        throw new Error('Method not implemented.');
    };
    /**
     * Not applicable to jobs
     */
    CommonJobHistoryGridComponent.prototype.deleteRow = function (row) {
        throw new Error('Method not implemented.');
    };
    /**
     * Not applicable to jobs
     */
    CommonJobHistoryGridComponent.prototype.createNewRow = function () {
    };
    /**
     * Not applicable to jobs
     */
    CommonJobHistoryGridComponent.prototype.isInvalidRow = function (params) {
        return false;
    };
    /**
     * Not applicable to jobs
     */
    CommonJobHistoryGridComponent.prototype.isUnsavedRow = function (row) {
        return false;
    };
    return CommonJobHistoryGridComponent;
}(SetupGridComp));
export { CommonJobHistoryGridComponent };
