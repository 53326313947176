export class DispatchEntry {
    id: number;
    dispatchStatus: DispatchStatus;

    supplierId: number;
    supplierName: string;
    supplierCode: string;

    email: string;
    emailSubject: string;
    emailBody: string;
    manual: boolean;

    scheduledTime: Date;
    sentTime: Date;
}

export enum DispatchStatus {
    ANY = 'ANY',
    NOT_DISPATCHED = 'NOT_DISPATCHED',
    PENDING = 'PENDING',
    DOC_QUEUE_PENDING = 'DOC_QUEUE_PENDING',
    SUCCESS = 'SUCCESS',
    FAILED = 'FAILED',
    INVALID = 'INVALID',
    IGNORED = 'IGNORED',
    GENERATED = 'GENERATED',
}
