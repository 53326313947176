import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { LEVEL, ModalData } from '@tc-core/model/it/codegen/ui/framework/modal-data';
import { Pager } from '@tc-core/model/it/codegen/ui/framework/pager';
import { SorterService } from '@tc-core/service/sorters/sorter.service';
import { ConfigLoader } from '@tc-core/util/framework/config-loader.service';
import { UserJourneyManager } from '@tc-core/util/framework/user-journey-manager.service';
import { CommonHelper } from '@tc-core/util/helpers/common-helper.service';
import { AutoScrollService } from '@tc-core/util/ui/auto-scroll.service';
import { SpinnerService } from '@tc-core/util/ui/spinner.service';
import { FocusViewService } from '@tc/focus-view/focus-view.service';
import { Subscription } from 'rxjs';
import { TCO } from '../../constants';
import { ResourceTypeSearchCriteria } from '../../models/criteria/resource-type-search-criteria';
import { VehicleSearchCriteria } from '../../models/criteria/vehicle-search-criteria';
import { DataHandlerService } from '../../services/backend-consumers/master-data-handler-service/data-handler.service';
import { ResourceTypeService } from '../../services/backend-consumers/setups/resource-type.service';
import { SupplierService } from '../../services/backend-consumers/supplier-service/supplier.service';
import { TransportResourceService } from '../../services/backend-consumers/supplier-service/transport-resource.service';
import { DataKey, DataStoreService } from '../../services/util/framework/data-store.service';
import { ResponseUtil } from '../../services/util/response/response-util.service';
import { ContentSummaryCardService } from '../../services/util/ui/content-summary-card.service';
import { ResAvailabilityCalendarFocusViewComponent } from '../../widgets/shared/res-availability-calendar-focus-view/res-availability-calendar-focus-view.component';
import { DMCQueryParamsService } from '../../services/util/framework/dmc-query-params.service';
import { RootService } from '../../services/util/core-services/root.service';
var VehicleSearchResultsComponent = /** @class */ (function () {
    function VehicleSearchResultsComponent(configLoader, dataStore, sorterService, summaryCardService, spinnerService, userJourneyManager, dataHandlerService, resourceTypeService, transportResourceService, supplierService, commonHelper, autoScrollService, focusViewService, queryParamService, rootService) {
        this.configLoader = configLoader;
        this.dataStore = dataStore;
        this.sorterService = sorterService;
        this.summaryCardService = summaryCardService;
        this.spinnerService = spinnerService;
        this.userJourneyManager = userJourneyManager;
        this.dataHandlerService = dataHandlerService;
        this.resourceTypeService = resourceTypeService;
        this.transportResourceService = transportResourceService;
        this.supplierService = supplierService;
        this.commonHelper = commonHelper;
        this.autoScrollService = autoScrollService;
        this.focusViewService = focusViewService;
        this.queryParamService = queryParamService;
        this.rootService = rootService;
        this.heading = '';
        this.defaultPageResultCount = 10;
        this.selectionIndex = '';
        this.fetchedReservationsCount = 0;
        this.dataFromMoreClick = false;
        this.paginatorHasMore = true;
        this.resultCount = 0;
        this.searchCriteria = new VehicleSearchCriteria();
        this.vehiclesDataArray = [];
        this.contentCardDataList = [];
        this.selectedVehicleSummary = null;
        this.resourceCost = [];
        this.firstTimeSupplierResults = false;
        this.resourceTypes = [];
        this.trsModes = [];
        this.navConfig = [];
        this.focusViewCloseSubject = new Subscription();
        this.vehicleSearchResultSubscription = new Subscription();
        this.vehicleSearchResultFromServiceSubscription = new Subscription();
        this.vehicleSearchResultFromServiceOnMoreSubscription = new Subscription();
        this.vehicleSearchCriteriaSubscription = new Subscription();
        this.supplierSubscription = new Subscription();
    }
    VehicleSearchResultsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.tVehicle = this.configLoader.configurations.get(TCO.CONF.CONF_VEHICLE_SEARCH_RESULTS_CONFIG);
        this.tPagination = this.configLoader.configurations.get(TCO.CONF.CONF_PAGINATION_CONFIG)['VEHICLE_SEARCH'];
        this.setupPaginator();
        this.getSearchCriteria();
        this.subscribeSearchResults();
        this.getSearchResults();
        this.createSorters();
        var resourceTypeSearchCriteria = new ResourceTypeSearchCriteria();
        resourceTypeSearchCriteria.size = 1000000;
        this.resourceTypeService.getResourceTypes(resourceTypeSearchCriteria).subscribe(function (result) {
            _this.resourceTypes = result.data;
        });
        this.dataHandlerService.retrieveTransferModes().subscribe(function (result) {
            if (result.data) {
                _this.trsModes = result.data;
            }
        });
    };
    VehicleSearchResultsComponent.prototype.setupPaginator = function () {
        this.defaultPageResultCount = this.tPagination['defaultPageResultCount'];
        this.pager = new Pager(0, this.defaultPageResultCount);
    };
    VehicleSearchResultsComponent.prototype.onPage = function (pageNumber) {
        this.pager.currPage = pageNumber;
        this.selectionIndex = (this.pager.currPage * this.pager.itemsPerPage).toString();
    };
    VehicleSearchResultsComponent.prototype.onSelection = function (event) {
        this.selectionIndex = event;
    };
    VehicleSearchResultsComponent.prototype.onMore = function () {
        this.dataFromMoreClick = true;
        if (this.tPagination && this.tPagination['defaultPageResultCount'] && this.tPagination['fetchingPages']) {
            this.searchCriteria.size = this.tPagination['fetchingPages'] * this.tPagination['defaultPageResultCount'];
            this.searchCriteria.start = this.fetchedReservationsCount;
            this.retrieveAndCollectSearchResults(this.searchCriteria);
        }
    };
    VehicleSearchResultsComponent.prototype.setPageZero = function () {
        if (this.pager) {
            if (!this.dataFromMoreClick) {
                this.pager.currPage = 0;
            }
            else {
                this.dataFromMoreClick = false;
            }
        }
        else {
            this.pager = new Pager(0, this.defaultPageResultCount);
        }
    };
    VehicleSearchResultsComponent.prototype.subscribeSearchResults = function () {
        var _this = this;
        this.vehicleSearchResultFromServiceSubscription = this.dataStore.get(DataKey.vehicleSearchResultsService)
            .subscribe(function (result) {
            _this.resultCount = 0;
            if (result) {
                _this.resultCount = ResponseUtil.getTotalCount(result);
                var dataList = ResponseUtil.getDataArray(result);
                _this.dataStore.set(DataKey.vehicleSearchResults, dataList, true);
            }
        });
    };
    // -- paginator--
    /**
     * keep fetched data and add new data to dataStore for pagination. only use in pagination more
     *
     * @param searchCriteria
     */
    VehicleSearchResultsComponent.prototype.retrieveAndCollectSearchResults = function (searchCriteria) {
        var _this = this;
        this.dataStore.set(DataKey.vehicleSearchPagingTempResult, null, true);
        if (this.vehicleSearchResultFromServiceOnMoreSubscription) {
            this.vehicleSearchResultFromServiceOnMoreSubscription.unsubscribe();
        }
        this.vehicleSearchResultFromServiceOnMoreSubscription = this.transportResourceService.searchVehiclesForPaging(searchCriteria)
            .subscribe(function (result) {
            _this.resultCount = 0;
            if (result) {
                _this.resultCount = ResponseUtil.getTotalCount(result);
                var dataList = _this.searchResults.concat(ResponseUtil.getDataArray(result));
                _this.dataStore.set(DataKey.vehicleSearchResults, dataList, true);
            }
        });
    };
    VehicleSearchResultsComponent.prototype.getSearchCriteria = function () {
        var _this = this;
        this.vehicleSearchCriteriaSubscription = this.dataStore.get(DataKey.vehicleSearchCriteria)
            .subscribe(function (data) {
            if (data !== null) {
                _this.searchCriteria = data;
            }
        });
    };
    VehicleSearchResultsComponent.prototype.createSorters = function () {
        var _this = this;
        this.sorterService.sorters = [];
        this.sorters_ = [];
        var sorterMap = new Map();
        this.tVehicle.config.placeHolderConfig.forEach(function (config) {
            if (config && config.sort) {
                var sorter = _this.sorterService.createSorter(config);
                var id = config['_id'];
                sorterMap.set(id, sorter);
                _this.sorters_.push(sorter);
            }
        });
    };
    VehicleSearchResultsComponent.prototype.onSort = function (event) {
        this.sorterService.sorter = event;
    };
    VehicleSearchResultsComponent.prototype.getSearchResults = function () {
        var _this = this;
        this.fetchedReservationsCount = 0;
        this.vehicleSearchResultSubscription = this.dataStore.get(DataKey.vehicleSearchResults)
            .subscribe(function (result) {
            _this.selectionIndex = '';
            _this.searchResults = [];
            if (result != null) {
                _this.spinnerService.hide();
            }
            if (result) {
                _this.searchResults = result;
                _this.fetchedReservationsCount = _this.searchResults.length;
                if (_this.fetchedReservationsCount > 0) {
                }
                _this.getVehiclesCardList();
            }
            else {
                _this.contentCardDataList = [];
                _this.heading = '';
            }
        });
    };
    VehicleSearchResultsComponent.prototype.getVehiclesCardList = function () {
        if (this.searchResults.length !== null) {
            var vehicleSummaries = this.searchResults;
            var vehicleMap = [];
            for (var i = 0; i < vehicleSummaries.length; i++) {
                var id = vehicleSummaries[i].resourceId;
                var model = vehicleSummaries[i].vehicleModel;
                var name_1 = vehicleSummaries[i].vehicleName;
                var licencePlate = vehicleSummaries[i].licencePlate;
                var capacity = vehicleSummaries[i].capacity;
                var minPax = vehicleSummaries[i].minPax;
                var maxPax = vehicleSummaries[i].maxPax;
                var activeStatus = vehicleSummaries[i].activeStatus;
                var currency = vehicleSummaries[i].currency;
                var paymentMethod = vehicleSummaries[i].paymentMethod;
                var supplierName = vehicleSummaries[i].supplierName;
                var supplierCode = vehicleSummaries[i].supplierCode;
                var supplierId = vehicleSummaries[i].supplierId;
                var supplier = supplierName + (supplierCode ? ' (' + supplierCode + ')' : '');
                var driver = vehicleSummaries[i].driverName;
                var transferMode = vehicleSummaries[i].transferMode;
                vehicleMap.push({
                    'resourceId': id,
                    'name': name_1,
                    'supplier': supplier,
                    'supplierId': supplierId,
                    'model': model,
                    'licencePlate': licencePlate,
                    'capacity': capacity,
                    'minPax': minPax,
                    'maxPax': maxPax,
                    'activeStatus': activeStatus,
                    'currency': currency,
                    'paymentMethod': paymentMethod,
                    'transferMode': transferMode,
                    'driver': driver,
                    'rawData': vehicleSummaries[i]
                });
            }
            this.vehiclesDataArray = vehicleMap;
            this.contentCardDataList = this.getSearchContent();
        }
    };
    VehicleSearchResultsComponent.prototype.getSearchContent = function () {
        var contentCardList = this.summaryCardService.getContentCardsByConfig(this.tVehicle, this.vehiclesDataArray);
        this.spinnerService.hide();
        this.heading = this.resultCount + ' result' + (this.resultCount > 1 ? 's' : '') + ' found.';
        this.paginatorHasMore = this.resultCount !== contentCardList.length;
        console.log(this.heading);
        this.setPageZero();
        return contentCardList;
    };
    VehicleSearchResultsComponent.prototype.onResourceSelect = function ($event) {
        var _this = this;
        this.selectedVehicleSummary = $event;
        this.getSelectedResourceCost();
        var id = $event.data['value']['resourceId'];
        if (id !== null) {
            this.transportResourceService.retrieveResourceById(id)
                .subscribe(function (res) {
                if (_this.commonHelper.dataValidity(res) && res.data && res.data.length > 0) {
                    _this.detailedResource = ResponseUtil.getFirstData(res);
                    _this.setTrsModeNameCode();
                    var selectedResource = {
                        resourceId: _this.detailedResource.resourceId,
                        resourceType: _this.detailedResource.resourceType
                    };
                    var parameters = _this.queryParamService.getQueryParamsArray(selectedResource);
                    _this.rootService.setDataToLocalStorage(TCO.AppData.RESOURCE, selectedResource, true, parameters);
                }
            });
        }
    };
    VehicleSearchResultsComponent.prototype.getSelectedResourceCost = function () {
        this.dataStore.set(DataKey.supplier, null, true);
        this.firstTimeSupplierResults = true;
        var id = this.selectedVehicleSummary.data['value']['supplierId'];
        this.supplierService.retrieveSupplierById(String(id));
        this.supplierResultObserver();
    };
    VehicleSearchResultsComponent.prototype.supplierResultObserver = function () {
        var _this = this;
        this.supplierSubscription = this.dataStore.get(DataKey.supplier).subscribe(function (result) {
            var e_1, _a, e_2, _b;
            if (result != null && _this.firstTimeSupplierResults) {
                _this.firstTimeSupplierResults = false;
                _this.resourceCost = [];
                var supplier = ResponseUtil.getFirstData(result);
                if (supplier) {
                    try {
                        for (var _c = tslib_1.__values(supplier.seasons), _d = _c.next(); !_d.done; _d = _c.next()) {
                            var value = _d.value;
                            var trsCosts = [];
                            try {
                                for (var _e = tslib_1.__values(value.trsResourceCosts), _f = _e.next(); !_f.done; _f = _e.next()) {
                                    var cost = _f.value;
                                    if (cost.transferMode === _this.selectedVehicleSummary.data['value']['transferMode'] &&
                                        cost.vehicleModel === _this.selectedVehicleSummary.data['value']['model']) {
                                        trsCosts.push(cost);
                                    }
                                }
                            }
                            catch (e_2_1) { e_2 = { error: e_2_1 }; }
                            finally {
                                try {
                                    if (_f && !_f.done && (_b = _e.return)) _b.call(_e);
                                }
                                finally { if (e_2) throw e_2.error; }
                            }
                            value.trsResourceCosts = trsCosts;
                            if (value.trsResourceCosts && value.trsResourceCosts.length > 0) {
                                _this.resourceCost.push(value);
                            }
                        }
                    }
                    catch (e_1_1) { e_1 = { error: e_1_1 }; }
                    finally {
                        try {
                            if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
                        }
                        finally { if (e_1) throw e_1.error; }
                    }
                }
            }
        }, function (error) {
        });
    };
    VehicleSearchResultsComponent.prototype.onEditResource = function ($event) {
        this.userJourneyManager.goForKey('GO_TO_EDIT_VEHICLE');
        $event.isEditTriggered = true;
        $event.hasResourceCost = this.resourceCost && this.resourceCost.length > 0 ? true : false;
        this.dataStore.set(DataKey.selectedVehicleForEdit, $event, true);
    };
    VehicleSearchResultsComponent.prototype.onCardScroll = function ($event) {
        var _this = this;
        setTimeout(function () {
            _this.autoScrollService.scrollToID('clientDetailExp_' + $event, 'main-scroll-container');
        }, 0);
    };
    VehicleSearchResultsComponent.prototype.onEditAsNewResource = function ($event) {
        this.clearIdsInResource($event);
        this.userJourneyManager.goForKey('GO_TO_CREATE_VEHICLE');
        this.dataStore.set(DataKey.selectedVehicleForEditAsNew, $event, true);
    };
    /**
     * IMPORTANT : clear all the existing ids that needs to be saved as new entities
     *
     * @param resource
     */
    VehicleSearchResultsComponent.prototype.clearIdsInResource = function (resource) {
        resource.resourceId = 0;
        if (resource.contactDetails) {
            resource.contactDetails.forEach(function (value) { return value.contactId = 0; });
        }
        if (resource.documents) {
            resource.documents.forEach(function (value) { return value.id = 0; });
        }
        if (resource.remarks) {
            resource.remarks.forEach(function (value) { return value.remarkId = 0; });
        }
        if (resource.taxSchemes) {
            resource.taxSchemes.forEach(function (value) { return value.taxSchemeId = 0; });
        }
        if (resource.paymentInfo) {
            resource.paymentInfo.paymentId = 0;
        }
        if (resource.supplierAttributes) {
            resource.supplierAttributes.forEach(function (value) { return value.id = 0; });
        }
        if (resource.genericResource) {
            resource.genericResource.genericResId = 0;
            if (resource.genericResource.languages) {
                resource.genericResource.languages.forEach(function (value) {
                    value.tblId = 0;
                    value.genericResourceId = 0;
                });
            }
        }
        if (resource.vehicle) {
            resource.vehicle.vehicleId = 0;
            if (resource.vehicle.facilities) {
                resource.vehicle.facilities.forEach(function (value) {
                    value.tblFacilityId = 0;
                    value.vehicleId = 0;
                });
            }
            if (resource.vehicle.resServiceInfo) {
                resource.vehicle.resServiceInfo.serviceInfoId = 0;
            }
            if (resource.vehicle.resInsuranceInfo) {
                resource.vehicle.resInsuranceInfo.insuranceInfoId = 0;
            }
        }
    };
    VehicleSearchResultsComponent.prototype.onCalendarClick = function (event) {
        var _this = this;
        this.navConfig = this.configLoader.configurations.get(TCO.CONF.CONF_PAGE_SECTION_CONFIG)['availability_calendar_side_nav']['resource_level'];
        var supplier = (event && event.data.value) ? (event.data.value.supplier) : '';
        supplier = supplier.split('(')[0];
        var resourceSummary = event.data.value['rawData'];
        setTimeout(function () {
            var fabActions = [];
            /*let navItemsArray = this.configLoader.configurations.get(TCO.CONF.CONF_NAVIGATION);
             this.configLoader.configurations.get(TCO.CONF.CONF_FOCUS_VIEW).AVAILABILITY_CAL_FOCUS_VIEW.fabAction
             .forEach(val => {
             for (let key in navItemsArray) {
             if (val === navItemsArray[key]['id']) {
             fabActions.push(navItemsArray[key]);
             }
             }
             });*/
            var dataObject = new ModalData(LEVEL.SUCCESS, 'Availability Calendar', null, null, 'season-setup-focus-view', ResAvailabilityCalendarFocusViewComponent, {
                'resourceSummary': resourceSummary,
                'navConfig': _this.navConfig
            }, { 'label': 'close' }, '', '', fabActions, '', (event && event.data.value) ? (event.data.value.name) + ' | Supplier: ' + supplier : '');
            _this.focusViewCloseSubject = _this.dataStore.get(DataKey.availabilityCalendarFocusViewClose).subscribe(function (c) {
                if (c) {
                    resourceSummary = null;
                }
            });
            _this.focusViewService.confirm(dataObject).subscribe(function (res) {
                if (res) {
                    console.log(res);
                }
            });
        }, 0);
    };
    VehicleSearchResultsComponent.prototype.ngOnDestroy = function () {
        if (this.vehicleSearchResultSubscription) {
            this.vehicleSearchResultSubscription.unsubscribe();
        }
        if (this.vehicleSearchResultFromServiceSubscription) {
            this.vehicleSearchResultFromServiceSubscription.unsubscribe();
        }
        if (this.vehicleSearchResultFromServiceOnMoreSubscription) {
            this.vehicleSearchResultFromServiceOnMoreSubscription.unsubscribe();
        }
        if (this.vehicleSearchCriteriaSubscription) {
            this.vehicleSearchCriteriaSubscription.unsubscribe();
        }
        if (this.supplierSubscription) {
            this.supplierSubscription.unsubscribe();
        }
        if (this.focusViewCloseSubject) {
            this.focusViewCloseSubject.unsubscribe();
        }
    };
    VehicleSearchResultsComponent.prototype.setTrsModeNameCode = function () {
        if (this.detailedResource && this.detailedResource.vehicle && this.detailedResource.vehicle.trsMode) {
            this.detailedResource.vehicle.trsModeCodeName = this.getTransferModeCodeName(this.detailedResource.vehicle.trsMode);
        }
    };
    VehicleSearchResultsComponent.prototype.getTransferModeCodeName = function (transferMode) {
        var option = this.trsModes.find(function (value) { return value.code === transferMode; });
        var codeName = '';
        if (option) {
            if (option.code) {
                codeName = option.code;
            }
            codeName += ' | ';
            if (option.name) {
                codeName += option.name;
            }
            return codeName;
        }
        else {
            return '';
        }
    };
    return VehicleSearchResultsComponent;
}());
export { VehicleSearchResultsComponent };
