import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
// import { CalendarBodyComponent } from '@tc/cg-calendar/calendar-body/calendar-body.component';
// import { CalendarMenuItem } from '@tc/cg-calendar/calendar-header-controller/calendar-menu-item';
// import { CalendarNavData } from '@tc/cg-calendar/calendar-nav-data';
// import { CalendarRefreshEventType } from '@tc/cg-calendar/calendar-refresh-event-type';
// import { CgCalendarRefreshData } from '@tc/cg-calendar/cg-calendar-refresh-data';
// import { DateRange } from '@tc/cg-calendar/date-range';
// import { RangeGroup } from '@tc/cg-calendar/range-group';
import { Observable, Subscription } from 'rxjs';

import * as moment from 'moment';
import { CalendarBodyComponent } from './calendar-body/calendar-body.component';
import { CalendarMenuItem } from './calendar-header-controller/calendar-menu-item';
import { CalendarNavData } from './calendar-nav-data';
import { CalendarRefreshEventType } from './calendar-refresh-event-type';
import { CgCalendarRefreshData } from './cg-calendar-refresh-data';
import { DateRange } from './date-range';
import { RangeGroup } from './range-group';

@Component({
  selector: 'tc-cg-calendar',
  templateUrl: './cg-calendar.component.html'
})
export class CgCalendarComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() fromYear: number = 0;
  @Input() toYear: number = 0;
  @Input() rangeGroups: RangeGroup[] = [];
  @Input() calendarRefreshEvent: Observable<CgCalendarRefreshData>;
  @Input() calendarMenuItems: CalendarMenuItem[] = [];
  @Input() validCalendarOptions: any[] = [];
  @Input() calendarNavData: CalendarNavData = new CalendarNavData();
  @Input() viewOnlyMode: boolean = false;

  @Output() selectDate: EventEmitter<Date> = new EventEmitter();
  @Output() menuItemClick: EventEmitter<CalendarMenuItem> = new EventEmitter();

  @ViewChild(CalendarBodyComponent)
  public calendarBody: CalendarBodyComponent;

  calendarRefreshEventSubscription: Subscription;
  yearsArray: number[] = [];

  constructor() {
  }

  ngOnInit() {
    this.setValidCalendarOptions();
    this.createYearsArray();
    if (this.calendarRefreshEvent) {
      this.navigateWhenSelectedRangeChanges();
    }
    if (this.calendarRefreshEvent) {
      this.navigateWhenDateRangeSelect();
    }
  }

  public ngAfterViewInit(): void {
    setTimeout(() => {
      this.navigateToSelectedRangeGroup();
    }, 0);
  }

  setValidCalendarOptions() {
    this.validCalendarOptions.push({'code': 'year', 'value': 'Year', 'isSelected': true});
  }

  navigateWhenSelectedRangeChanges() {
    this.calendarRefreshEvent.subscribe((res) => {
      if (res) {
        if (res.type === CalendarRefreshEventType.RangeGroupSelect
          || res.type === CalendarRefreshEventType.RangeGroupsAddedOrAttributesChanged) {
          this.navigateToSelectedRangeGroup();
        }
      }
    });
  }

  navigateWhenDateRangeSelect() {
    this.calendarRefreshEvent.subscribe((res) => {
      if (res) {
        if (res.type === CalendarRefreshEventType.DateRangeSelect) {
          let dateRange: DateRange = res.data;
          if (dateRange) {
            // let midDate=new Date((dateRange.startDate.getTime() + dateRange.endDate.getTime()) / 2);
            this.calendarBody.scrollToDate(dateRange.startDate);
          }
        }
      }
    });
  }

  createYearsArray() {
    if (this.fromYear && this.toYear && this.fromYear <= this.toYear) {
      for (let i = this.fromYear; i <= this.toYear; i++) {
        this.yearsArray.push(i);
      }
    }
    if (this.yearsArray.length === 0) {
      this.yearsArray.push(moment().year());
    }
    this.calendarNavData.showingYear = this.fromYear;
  }

  onSelectDate($event: Date) {
    this.selectDate.emit($event);
  }

  public onMenuItemClick(menuItem: CalendarMenuItem) {
    this.menuItemClick.emit(menuItem);
  }

  public getCurrentViewHeader() {
    return this.fromYear;
  }

  public onSelectCalendarType($event: any) {
    // todo switching between calendar types
  }

  public onClickNextBtn($event: any) {
    this.calendarBody.scrollToYear(this.calendarNavData.showingYear + 1);
  }

  public onClickPreviousBtn($event: any) {
    this.calendarBody.scrollToYear(this.calendarNavData.showingYear - 1);
  }

  private navigateToSelectedRangeGroup() {
    if (this.rangeGroups) {
      let selectedRangeGroup: RangeGroup = this.rangeGroups.find(group => group.selected);
      if (selectedRangeGroup && selectedRangeGroup.dateRanges[0]) {
        let navigatingDate = selectedRangeGroup.dateRanges[0].startDate;
        this.calendarBody.scrollToDate(navigatingDate);
      }
    }
  }

  public ngOnDestroy(): void {
    if (this.calendarRefreshEventSubscription) {
      this.calendarRefreshEventSubscription.unsubscribe();
    }
  }

}
