import { Component, ElementRef, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatOptionSelectionChange } from '@angular/material';
import { ConfigLoader } from '@tc-core/util/framework/config-loader.service';
import { EventManager } from '@tc-core/util/framework/event-manager.service';
import { UserJourneyManager } from '@tc-core/util/framework/user-journey-manager.service';
import { PageSummaryService } from '@tc-core/util/ui/page-summary.service';
import { SideNavBarService } from '@tc-core/util/ui/side-nav-bar.service';
import * as moment from 'moment';
import { TCO } from '../../constants';
import { SupplierSearchCriteria } from '../../models/criteria/supplier-search-criteria';
import { VehicleSearchCriteria } from '../../models/criteria/vehicle-search-criteria';
import { SortDirection } from '../../models/helper/sort-direction';
import { DashboardSearchItem, DashboardSearchItemType, ResourceCategory } from '../../models/launchpad/dashboard-search-item';
import { DashboardSearchNavItem } from '../../models/launchpad/dashboard-search-nav-item';
import { ProductQueueSearchCriteria } from '../../models/reservation-v2/criteria/product-queue-search-criteria';
import { DashboardSearchService } from '../../services/backend-consumers/launchpad/dashboard-search.service';
import { ResponseUtil } from '../../services/util/response/response-util.service';
import { DMCLocalStorageService } from '../../services/util/system/dmc-local-storage.service';
import {UserManagementService} from "../../services/user-management/user-management.service";

@Component({
    selector: 'tc-dashboard-search',
    templateUrl: './dashboard-search.component.html',
    styleUrls: ['./dashboard-search.component.css']
})
export class DashboardSearchComponent implements OnInit {

    searchControl: FormControl;
    navItems: DashboardSearchNavItem[] = [];

    filteredAssignmentItems: DashboardSearchItem[] = [];
    filteredSupplierItems: DashboardSearchItem[] = [];
    filteredResourceItems: DashboardSearchItem[] = [];
    filteredNavItems: DashboardSearchNavItem[] = [];

    TYPE_ASSIGNMENT = DashboardSearchItemType.ASSIGNMENT;
    TYPE_SUPPLIER = DashboardSearchItemType.SUPPLIER;
    TYPE_RESOURCE = DashboardSearchItemType.RESOURCE;

    constructor(
        private sideNavBarService: SideNavBarService,
        private pageSummaryService: PageSummaryService,
        private em: EventManager,
        private element: ElementRef,
        private userJourneyManager: UserJourneyManager,
        private dashboardSearchService: DashboardSearchService,
        private localStorageService: DMCLocalStorageService,
        private configLoader: ConfigLoader,
        public userManagementService: UserManagementService
    ) {
    }

    ngOnInit() {
        const navItems = this.configLoader.configurations.get(TCO.CONF.CONF_NAVIGATION);
        this.navItems = this.generateDashboardNavItems(navItems);

        this.searchControl = new FormControl();
        this.searchControl.valueChanges.subscribe(text => {
            if (JSON.stringify(text) !== JSON.stringify({})) {
                this.filteredAssignmentItems = [];
                this.filteredSupplierItems = [];
                this.filteredResourceItems = [];
                this.filteredNavItems = [];
                if (text && !(text instanceof Object)) {
                    this.dashboardSearchService.searchDashboardAssignmentItems(text).subscribe(
                        result => {
                            this.filteredAssignmentItems = ResponseUtil.getDataArray(result);
                        });
                    this.dashboardSearchService.searchDashboardSupplierResourceItems(text).subscribe(
                        result => {
                            const supAndResources = ResponseUtil.getDataArray(result);
                            const suppliers = [];
                            const resources = [];
                            for (const supOrResource of supAndResources) {
                                if (supOrResource.type === DashboardSearchItemType.SUPPLIER) {
                                    suppliers.push(supOrResource);
                                } else if (supOrResource.type === DashboardSearchItemType.RESOURCE) {
                                    resources.push(supOrResource);
                                }
                            }
                            this.filteredSupplierItems = suppliers;
                            this.filteredResourceItems = resources;
                        });
                    this.filteredNavItems = this.navItems.filter(
                        nav => {
                            let hasTerm = nav.name && nav.name.toLowerCase().startsWith(text.toLowerCase());
                            if (!hasTerm && nav.searchTags && nav.searchTags.length) {
                                for (const searchTag of nav.searchTags) {
                                    hasTerm = searchTag.toLowerCase().startsWith(text.toLowerCase());
                                    if (hasTerm) { break; }
                                }
                            }
                            return hasTerm;
                        }
                    );
                }
            }
        });
    }

    generateDashboardNavItems(navItems: any) {
        const list = [];
        for (const key of Object.keys(navItems)) {
            const navItem = navItems[key];
            if (navItem && navItem.dashboardSearch) {
                const dashboardSearchNavItem = new DashboardSearchNavItem();
                dashboardSearchNavItem.icon = navItem.icon;
                dashboardSearchNavItem.name = navItem.title;
                dashboardSearchNavItem.actionRef = key;
                if (navItem.searchTags) {
                    dashboardSearchNavItem.searchTags = navItem.searchTags;
                }
                list.push(dashboardSearchNavItem);
            }
        }
        return list;
    }

    displayItemFn = (option: DashboardSearchItem | DashboardSearchNavItem) => {
        let text = '';
        if (option) {
            if (option instanceof DashboardSearchItem) {
                if (option.type === this.TYPE_ASSIGNMENT) {
                    if (option.assignmentId) {
                        text += 'Job Id: ' + option.assignmentId;
                    }
                }
                if (option.type === this.TYPE_SUPPLIER) {
                    if (option.supplier) {
                        text += 'Supplier: ' + option.supplier;
                    }
                }
                if (option.type === this.TYPE_RESOURCE) {
                    if (option.resource) {
                        text += 'Resource: ' + option.resource;
                    }
                }
                return text;
            } else if (option instanceof DashboardSearchNavItem) {
                text += option.name;
            }
        }
        return text;
    };

    public onSelectItem(event: MatOptionSelectionChange) {
        const selected: DashboardSearchItem = event.source.value;
        console.log(selected);
        if (selected.type === this.TYPE_ASSIGNMENT) {
            this.openServiceQueue(selected);
        }
        if (selected.type === this.TYPE_SUPPLIER) {
            this.openSuppliers(selected);
        }
        if (selected.type === this.TYPE_RESOURCE) {
            if (selected.resourceCategory === ResourceCategory.TRS) {
                this.openTrsResources(selected);
            } else {
                this.openGenResources(selected);
            }
        }
    }

    getAssignmentPanelMaxHeight() {
        if (this.filteredSupplierItems.length && this.filteredResourceItems.length) {
            return '200px';
        } else if (this.filteredSupplierItems.length || this.filteredResourceItems.length) {
            return '400px';
        } else {
            return '600px';
        }
    }

    getSupplierPanelMaxHeight() {
        if (this.filteredAssignmentItems.length && this.filteredResourceItems.length) {
            return '200px';
        } else if (this.filteredAssignmentItems.length || this.filteredResourceItems.length) {
            return '400px';
        } else {
            return '600px';
        }
    }

    getResourcePanelMaxHeight() {
        if (this.filteredAssignmentItems.length && this.filteredSupplierItems.length) {
            return '200px';
        } else if (this.filteredAssignmentItems.length || this.filteredSupplierItems.length) {
            return '400px';
        } else {
            return '600px';
        }
    }

    getGenericIcon(resourceType) {
        let icon = 'person';
        if (resourceType === 'guide') {
            icon = 'face';
        } else if (resourceType === 'GUID') {
            icon = 'face';
        } else if (resourceType === 'rep') {
            icon = 'person';
        } else if (resourceType === 'meal') {
            icon = 'fastfood';
        } else if (resourceType === 'bellydancer') {
            icon = 'person';
        } else if (resourceType === 'hennaartist') {
            icon = 'filter_vintage';
        } else if (resourceType === 'driver') {
            icon = 'airline_seat_recline_normal';
        } else if (resourceType === 'vehicle') {
            icon = 'directions_car';
        } else if (resourceType === 'ticket') {
            icon = 'loyalty';
        }
        return icon;
    }

    public onSelectNavItem(event: MatOptionSelectionChange) {
        const selected: DashboardSearchNavItem = event.source.value;
        console.log(selected);
        this.userJourneyManager.goForKey(selected.actionRef);
    }

    // navigate
    openServiceQueue(item: DashboardSearchItem): any {
        if (item.assignmentId && item.assignmentId > 0) {
            const criteria = new ProductQueueSearchCriteria();
            criteria.assignmentId = item.assignmentId;
            criteria.startDate = moment().startOf('year').format('YYYY-MM-DD');
            criteria.endDate = moment().add(1, 'year').endOf('year').format('YYYY-MM-DD');
            criteria.sortDirection = SortDirection.ASC;
            this.localStorageService.store(TCO.AppData.PRODUCT_QUEUE_SEARCH_CRITERIA_COMMON, criteria);
        }
        this.userJourneyManager.goForKey('BEGIN_RESOURCE_ALLOCATION_AS_COMMON_PANEL');
    }

    openSuppliers(item: DashboardSearchItem): any {
        if (item.supplierCode) {
            const criteria = new SupplierSearchCriteria();
            criteria.code = item.supplierCode;
            this.localStorageService.store(TCO.AppData.SUPPLIER_SEARCH_CRITERIA, criteria);
        }
        this.userJourneyManager.goForKey('BEGIN_SUPPLIER_MANAGEMENT');
    }

    openTrsResources(item: DashboardSearchItem): any {
        if (item.resourceId) {
            const criteria = new VehicleSearchCriteria();
            criteria.resourceType = 'vehicle';
            criteria.activeStatus = true;
            criteria.resourceId = item.resourceId;
            this.localStorageService.store(TCO.AppData.VEHICLE_SEARCH_CRITERIA, criteria);
        }
        this.userJourneyManager.goForKey('BEGIN_RESOURCE_MANAGEMENT_AS_TRANSPORT');
    }

    openGenResources(item: DashboardSearchItem): any {
        if (item.resourceId) {
            const criteria = new VehicleSearchCriteria();
            criteria.activeStatus = true;
            criteria.resourceId = item.resourceId;
            this.localStorageService.store(TCO.AppData.GENERIC_SEARCH_CRITERIA, criteria);
        }
        this.userJourneyManager.goForKey('BEGIN_RESOURCE_MANAGEMENT_AS_GENERIC');
    }

}
