import * as tslib_1 from "tslib";
import { AfterViewInit, ElementRef, EventEmitter, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { NotificationSearchCriteria } from '../../models/criteria/notification-search-criteria';
import { MessageThread } from '../../models/notification/message-thread';
import { NotificationData, NotificationReceiver, NotificationType } from '../../models/notification/notification-data';
import { NotificationService } from '../../services/backend-consumers/notifications/notification.service';
import { UserProfileService } from '../../services/backend-consumers/user-service/user-profile.service';
import { ResponseUtil } from '../../services/util/response/response-util.service';
import { DMCLocalStorageService } from '../../services/util/system/dmc-local-storage.service';
var ResourceMessagePanelComponent = /** @class */ (function () {
    function ResourceMessagePanelComponent(fb, localStorageService, notificationService, userService) {
        this.fb = fb;
        this.localStorageService = localStorageService;
        this.notificationService = notificationService;
        this.userService = userService;
        this.isBroadCast = false;
        this.group = [];
        this.selectedNotificationId = -1;
        this.newMsgSent = new EventEmitter();
        this.newMessageHeight = 100;
        this.toolTipPosition = 'below';
        this.messages = [];
        this.message = '';
        this.messagesLoaded = false;
        this.messageToSend = '';
        this.size = 10;
        this.start = 10;
        this.notificationCriteria = new NotificationSearchCriteria();
        this.loadMoreSubscription = new Subscription();
        this.userEmailAndResourceIdList = new Map();
        this.createForm();
    }
    ResourceMessagePanelComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.getAllUserEmails();
        this.sendMessageForm.valueChanges.subscribe(function (data) {
            if (JSON.stringify(data) !== JSON.stringify({})) {
                _this.message = data.message;
                console.log('message: ' + _this.message);
            }
        });
        this.scrollToBottom();
    };
    ResourceMessagePanelComponent.prototype.ngAfterViewInit = function () {
        this.scrollToBottom();
        var element = document.getElementById('msg-' + this.selectedNotificationId);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
            this.localStorageService.delete('notificationId');
        }
    };
    ResourceMessagePanelComponent.prototype.ngOnChanges = function (changes) {
        if (changes['selectedChat'] && changes['selectedChat'].currentValue) {
            this.selectedChat = changes['selectedChat'].currentValue;
            this.message = '';
            this.processThread();
            this.scrollToBottom();
        }
        if (changes['selectedNotificationId'] && changes['selectedNotificationId'].currentValue) {
            this.selectedNotificationId = changes['selectedNotificationId'].currentValue;
        }
        if (changes['group'] && changes['group'].currentValue) {
            this.group = changes['group'].currentValue;
        }
        if (changes['selectedNotificationId'] && changes['selectedNotificationId'].currentValue) {
            this.selectedNotificationId = changes['selectedNotificationId'].currentValue;
        }
    };
    ResourceMessagePanelComponent.prototype.getAllUserEmails = function () {
        var _this = this;
        this.userService.getAllEmailsForResourceIds().subscribe(function (result) {
            if (result) {
                result.data.forEach(function (data) {
                    _this.userEmailAndResourceIdList.set(data.resourceId, data.email);
                });
            }
        });
    };
    ResourceMessagePanelComponent.prototype.scrollToBottom = function () {
        try {
            this.scrollContainer.nativeElement.scrollTop = this.scrollContainer.nativeElement.scrollHeight;
        }
        catch (err) {
            console.log(err);
        }
    };
    ResourceMessagePanelComponent.prototype.ngOnDestroy = function () {
    };
    /***
     * load more with scroll
     * @param $event
     */
    ResourceMessagePanelComponent.prototype.scrollEvent = function ($event) {
        var _this = this;
        if (this.scrollContainer.nativeElement.scrollTop == 0) {
            this.notificationCriteria.start = this.start;
            this.notificationCriteria.size = this.size;
            this.loadMoreSubscription = this.notificationService.loadMore(this.notificationCriteria).subscribe(function (data) {
                if (data) {
                    var newMessages = ResponseUtil.getDataArray(data);
                    if (newMessages.length > 0) {
                        newMessages.forEach(function (msg) {
                            _this.messages.unshift(msg);
                            _this.processThread();
                        });
                        _this.start = _this.start + _this.size;
                    }
                }
            });
        }
    };
    /***
     * initialize the form
     */
    ResourceMessagePanelComponent.prototype.createForm = function () {
        this.sendMessageForm = this.fb.group({
            message: [
                ''
            ]
        });
    };
    /***
     * send message
     */
    ResourceMessagePanelComponent.prototype.sendMessage = function () {
        if (this.message && this.message.trim() !== '') {
            var notification = new NotificationData();
            if (this.selectedChat && this.selectedChat.groupMsgThreadId != null && this.selectedChat.groupMsgThreadId > 0) {
                this.isBroadCast = true;
                notification.groupMsgThreadId = this.selectedChat.groupMsgThreadId;
            }
            else {
                this.isBroadCast = false;
            }
            notification.type = this.isBroadCast ?
                NotificationType.RESOURCE_BROADCAST :
                NotificationType.RESOURCE_COMMUNICATION;
            notification.description = this.message.trim();
            notification.indicator = 'Message';
            notification.sendingDmc = true;
            if (!this.isBroadCast) {
                notification.resourceName = this.selectedChat && this.selectedChat.resourceName ?
                    this.selectedChat.resourceName : this.selectedChat && this.selectedChat.supplierName;
                notification.receiver = NotificationReceiver.ONE_RESOURCE;
                notification.receivingResourceId = this.selectedChat && this.selectedChat.resourceId ?
                    this.selectedChat.resourceId : this.selectedChat && this.selectedChat.supplierId;
                notification.receivingEmail = this.selectedChat && this.selectedChat.email ?
                    this.selectedChat.email : this.selectedChat && this.selectedChat.email;
                if (notification.receivingEmail == null) {
                    notification.receivingEmail = this.userEmailAndResourceIdList.get(notification.receivingResourceId);
                }
                this.notificationService.sendMessage(notification);
            }
            else {
                notification.receivingResourcesIds = [];
                notification.receivingResourcesIds = this.group;
                notification.receiver = NotificationReceiver.ALL_RESOURCES;
                if (this.groupMsgThreadId > 0) {
                    notification.groupMsgThreadId = this.groupMsgThreadId;
                }
                this.notificationService.sendBroadCastMessage(notification);
            }
            // this.messages.push(notification);
            this.processMessages();
            this.sendMessageForm.controls['message'].setValue('');
            this.scrollToBottom();
        }
        else {
            this.sendMessageForm.controls['message'].setValue('');
        }
    };
    // handleKeyEvent($event){
    //   if($event.keyCode === 13){
    //     this.sendMessage();
    //   }
    // }
    ResourceMessagePanelComponent.prototype.onKeyDown = function (event) {
        event.preventDefault();
    };
    ResourceMessagePanelComponent.prototype.onKeyUp = function (event) {
        this.sendMessage();
    };
    ResourceMessagePanelComponent.prototype.onKeydownCtrlEnter = function (event) {
        console.log('ctrl+enter');
    };
    ResourceMessagePanelComponent.prototype.chunk = function (str, n) {
        var ret = [];
        var i;
        var len;
        for (i = 0, len = str.length; i < len; i += n) {
            ret.push(str.substr(i, n));
        }
        return ret;
    };
    /***
     * process thread
     */
    ResourceMessagePanelComponent.prototype.processThread = function () {
        var _a, _b;
        if (this.selectedChat && this.selectedChat.mostRecentMessages) {
            this.messages = this.selectedChat.mostRecentMessages;
            this.notificationCriteria.resourceId = this.selectedChat.resourceId ?
                this.selectedChat.resourceId :
                this.selectedChat.supplierId;
            //sort the messages
            this.messages.sort(function (a, b) { return a.notificationId < b.notificationId ?
                -1 :
                b.notificationId > a.notificationId ? 1 : 0; });
            this.processMessages();
            if (this.selectedChat.mostRecentMessagesFE && this.selectedChat.mostRecentMessagesFE.length > 0) {
                this.selectedChat.mostRecentMessagesFE.sort(function (a, b) { return a.notificationId < b.notificationId ?
                    -1 :
                    b.notificationId > a.notificationId ? 1 : 0; });
                (_a = this.messages).push.apply(_a, tslib_1.__spread(this.selectedChat.mostRecentMessagesFE));
                this.selectedChat.mostRecentMessagesFE = [];
            }
        }
        else {
            if (this.selectedChat.mostRecentMessagesFE && this.selectedChat.mostRecentMessagesFE.length > 0) {
                this.selectedChat.mostRecentMessagesFE.sort(function (a, b) { return a.notificationId < b.notificationId ?
                    -1 :
                    b.notificationId > a.notificationId ? 1 : 0; });
                (_b = this.messages).push.apply(_b, tslib_1.__spread(this.selectedChat.mostRecentMessagesFE));
                this.selectedChat.mostRecentMessagesFE = [];
            }
            else {
                this.messages = [];
            }
        }
        if (this.messages.length > 0 && this.messages[0] && this.messages[0].type === 'ASSIGNMENT_UPDATE') {
            var msg = this.messages[0].description;
            var id = '';
            var arr = msg.split('[ id: ');
            if (arr.length > 1 && arr[1]) {
                id = arr[1].split(' ')[0];
            }
            var msgText = '[DMC-JOB-' + id + '] ' + this.messages[0].indicator.split('_').join(' ').toLowerCase();
            this.sendMessageForm.controls['message'].setValue(msgText);
            this.localStorageService.delete('resourceId');
            this.localStorageService.delete('job');
            this.localStorageService.delete('assignmentData');
        }
    };
    /***
     * process single msg data
     */
    ResourceMessagePanelComponent.prototype.processMessages = function () {
        var _this = this;
        this.messages.forEach(function (msg, index) {
            if (msg.modifiedOn !== null && !msg.displayTimeStamp) {
                if (moment(msg.modifiedOn).format('MMM Do YY') !== moment().format('MMM Do YY')) {
                    msg.displayTimeStamp = moment(msg.modifiedOn).format('MMM Do, h:mm a');
                }
                else {
                    msg.displayTimeStamp = moment(msg.modifiedOn).calendar();
                }
            }
            if (msg.notificationId == null && msg.receivingDmc && !_this.selectedChat.isSelected) {
                _this.messages.splice(index, 1);
            }
            var tempArr = msg.description.split('DMC-JOB');
            if (tempArr.length > 1) {
                msg.indicator = 'job';
            }
            else {
                msg.indicator = 'message';
            }
            msg.description = msg.sendingDmc ? msg.description : _this.chunk(msg.description, 70).join('<br>');
        });
        this.messagesLoaded = true;
    };
    return ResourceMessagePanelComponent;
}());
export { ResourceMessagePanelComponent };
