import * as tslib_1 from "tslib";
import { MainType } from '../reservation-v2/main-type';
import { KeyControlAndAbstractSearchCriteria } from './key-control-and-abstract-search-criteria';
var DocumentSearchCriteria = /** @class */ (function (_super) {
    tslib_1.__extends(DocumentSearchCriteria, _super);
    function DocumentSearchCriteria(manifestOnly, reportsOnly) {
        var _this = _super.call(this) || this;
        _this.documentType = [];
        _this.manifestsOnly = false;
        _this.reportsOnly = false;
        _this.startDate = '';
        _this.endDate = '';
        _this.name = '';
        _this.generatedBy = 'ANY';
        _this.mainType = MainType.TRS;
        _this.userId = null;
        _this.userName = '';
        _this.bookingId = null;
        _this.bookingTableId = null;
        _this.bookingSourceItemNo = null;
        _this.dispatchStatus = 'SUCCESS';
        _this.documentFormat = 'ANY';
        _this.backgroundTaskId = null;
        _this.manifestsOnly = manifestOnly;
        _this.reportsOnly = reportsOnly;
        return _this;
    }
    return DocumentSearchCriteria;
}(KeyControlAndAbstractSearchCriteria));
export { DocumentSearchCriteria };
