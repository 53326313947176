<form [formGroup]="objectKcInfoForm">
    <div class="container-fluid direct-client-personal-info-edit-card tc-card-wrapper">
        <div class="row">
            <div class="col-md-12 col-lg-12">
                <div class="tc-flex-box">
                    <div class="tc-flex-item tc-item-circle">
                        <div class="icon-box">
                            <em class="material-icons">account_circle</em>
                        </div>
                    </div>
                    <div class="tc-flex-item tc-right-cage">
                        <div class="row">
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                                <div class="tc-select-edit dcpiec-select-title">
                                    <mat-form-field>
                                        <mat-select
                                                id="DIR_PER_INFO_EDIT_CODE"
                                                #codeInput
                                                formControlName="kcCompany"
                                                [placeholder]="'Company'"
                                                [(ngModel)]="defCompany[0].code"
                                                [disabled] = true
                                                required
                                        >
                                            <mat-option
                                                    *ngFor="let option of defCompany"
                                                    [value]="option.code">
                                                {{(option.name === '-' || option === null) ? 'Any' : option.name}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                                <div class="tc-input-edit dcpiec-input-fname">
<!--                                    <mat-form-field *ngIf="hasADivision">-->
<!--                                        <input-->
<!--                                                matInput-->
<!--                                                id="DIR_PER_INFO_EDIT_NAME"-->
<!--                                                #nameInput-->
<!--                                                [readonly]="isDivisionReadOnly"-->
<!--                                                formControlName="kcDivision"-->
<!--                                                [placeholder]="'Division'"-->
<!--                                                required-->
<!--                                        >-->
<!--                                    </mat-form-field>-->
                                    <mat-form-field>
                                        <mat-select
                                            #nameInput
                                            id="DIR_PER_INFO_EDIT_DIVISION_DROP_DOWN"
                                            formControlName="kcDivision"
                                            [placeholder]="'Division'"
                                            [disabled] = "hasADivision || disableKeyControlEdit"
                                            >
                                            <mat-option
                                                    *ngFor="let option of divisions"
                                                    [value]="option.code">
                                                {{(option.name === '-' || option === null) ? 'Any' : option.name}}
                                            </mat-option>
                                        </mat-select>
                                                                          <!--<mat-error>{{getErrorMessage('title')}}</mat-error>-->
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                                <div class="tc-input-edit dcpiec-input-fname">
                                    <mat-form-field>
                                        <mat-select
                                            #fromBrands
                                            id="DIR_PER_INFO_EDIT_BRAND_DROP_DOWN"
                                            formControlName="kcBrand"
                                            [placeholder]="'Brand'"
                                            [disabled] = "hasABrand || disableKeyControlEdit"
                                            >
                                            <mat-option
                                                    *ngFor="let option of brands"
                                                    [value]="option.code">
                                                {{(option.name === '-' || option === null) ? 'Any' : option.name}}
                                            </mat-option>
                                        </mat-select>
                                        <!--<mat-error>{{getErrorMessage('title')}}</mat-error>-->
                                    </mat-form-field>
<!--                                    <mat-form-field *ngIf="!hasABrand && !mandatory">-->
<!--                                        <mat-select-->
<!--                                            #fromBrands-->
<!--                                            id="DIR_PER_INFO_EDIT_BRAND_DROP_DOWN_MAN"-->
<!--                                            formControlName="kcBrand"-->
<!--                                            [placeholder]="'brands'"-->
<!--                                            >-->
<!--                                            <mat-option-->
<!--                                                    *ngFor="let option of brands"-->
<!--                                                    [value]="option.code">-->
<!--                                                {{(option.name === '-' || option === null) ? 'Any' : option.name}}-->
<!--                                            </mat-option>-->
<!--                                        </mat-select>-->
<!--                                        &lt;!&ndash;<mat-error>{{getErrorMessage('title')}}</mat-error>&ndash;&gt;-->
<!--                                    </mat-form-field>-->
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                                <div class="tc-input-edit dcpiec-input-fname">
                                    <mat-form-field>
                                        <mat-select
                                            #fromDistributionChannels
                                            id="DIR_PER_INFO_EDIT_DISTRIBUTION_CHANNEL_DROP_DOWN_MAN"
                                            formControlName="kcDistributionChannel"
                                            [placeholder]="'Distribution Channel'"
                                            [disabled] = "hasADistributionChannel || disableKeyControlEdit"
                                            >
                                            <mat-option
                                                    *ngFor="let option of distributionChannels"
                                                    [value]="option.code">
                                                {{(option.name === '-' || option === null) ? 'Any' : option.name}}
                                            </mat-option>
                                        </mat-select>
                                        <!--<mat-error>{{getErrorMessage('title')}}</mat-error>-->
                                    </mat-form-field>
<!--                                    <mat-form-field *ngIf="hasADistributionChannel && !mandatory">-->
<!--                                        <mat-select-->
<!--                                            #fromDistributionChannels-->
<!--                                            id="DIR_PER_INFO_EDIT_DISTRIBUTION_CHANNEL_DROP_DOWN"-->
<!--                                            formControlName="kcDistributionChannel"-->
<!--                                            [placeholder]="'distribution channel'"-->
<!--                                            required>-->
<!--                                            <mat-option-->
<!--                                                    *ngFor="let option of distributionChannels"-->
<!--                                                    [value]="option.code">-->
<!--                                                {{(option.name === '-' || option === null) ? 'Any' : option.name}}-->
<!--                                            </mat-option>-->
<!--                                        </mat-select>-->
<!--                                    </mat-form-field>-->
<!--                                    <mat-form-field *ngIf="hasADistributionChannel">-->
<!--                                        <input-->
<!--                                                matInput-->
<!--                                                id="DIR_PER_INFO_EDIT_DIS_CHANNEL"-->
<!--                                                #nameInput-->
<!--                                                [readonly]="isDistributionChannelReadOnly"-->
<!--                                                formControlName="kcDistributionChannel"-->
<!--                                                [placeholder]="'Distribution Channel'"-->
<!--                                        >-->
<!--                                    </mat-form-field>-->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
