import * as tslib_1 from "tslib";
import { AfterViewInit, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TcErrorType } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-error';
import { ConfigLoader } from '@tc-core/util/framework';
import * as moment from 'moment';
import { of, Subscription } from 'rxjs';
import { catchError, debounceTime, map, startWith, switchMap } from 'rxjs/operators';
import { GenericSearchCriteria } from '../../models/criteria/generic-search-criteria';
import { NotificationSearchCriteria } from '../../models/criteria/notification-search-criteria';
import { UserProfileSearchCriteria } from '../../models/criteria/user-profile-search-criteria';
import { GroupMsgMember } from '../../models/notification/group-msg-member';
import { GroupMsgThread } from '../../models/notification/group-msg-thread';
import { MessageThread } from '../../models/notification/message-thread';
import { NotificationType } from '../../models/notification/notification-data';
import { NotificationStateChangeData } from '../../models/notification/notification-state-change-data';
import { NotificationService } from '../../services/backend-consumers/notifications/notification.service';
import { GenericResourceService } from '../../services/backend-consumers/supplier-service/generic-resource.service';
import { UserProfileService } from '../../services/backend-consumers/user-service/user-profile.service';
import { DMCCommon } from '../../services/util/common/dmc-common';
import { DataKey, DataStoreService } from '../../services/util/framework/data-store.service';
import { ResponseUtil } from '../../services/util/response/response-util.service';
import { DMCLocalStorageService } from '../../services/util/system/dmc-local-storage.service';
var MessagingQueueComponent = /** @class */ (function () {
    function MessagingQueueComponent(notificationService, dataStore, genericResourceService, configLoader, userService, dataService, localStorageService, common) {
        this.notificationService = notificationService;
        this.dataStore = dataStore;
        this.genericResourceService = genericResourceService;
        this.configLoader = configLoader;
        this.userService = userService;
        this.dataService = dataService;
        this.localStorageService = localStorageService;
        this.common = common;
        this.messageThreadList = [];
        this.filteredData = null;
        this.selectedChats = [];
        this.group = [];
        this.isBroadCast = false;
        this.searchErr = false;
        this.searchingInit = false;
        this.selectedNotificationId = -1;
        this.createdThreadGroup = [];
        this.searchResultSubscription = new Subscription();
        this.newMessageSubscription = new Subscription();
        this.userEmailAndResourceIdList = new Map();
        this.userNameAndResourceIdList = new Map();
        this.nameCtrl = new FormControl();
        //for configs
        // this.resourceData = this.configLoader.configurations.get(TCO.CONF.CONF_RESERVATION_RESOURCE_ASSIGN_CONFIG).resource;
    }
    MessagingQueueComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.genericSearchCriteria = new GenericSearchCriteria();
        this.genericSearchCriteria.activeStatus = 'true';
        //search for resource
        this.filteredData = this.nameCtrl.valueChanges.pipe(startWith(''), debounceTime(300), switchMap(function (value) {
            _this.searchingInit = true; // show/hide search init text
            if (value !== '') {
                return _this.searchForResource(value);
            }
            else {
                _this.searchErr = false; //show/hide search err
                _this.searchingInit = false;
                return of(null);
            }
        }));
        this.getMessageThreadList();
        this.subscribeNewMessages();
    };
    MessagingQueueComponent.prototype.ngOnDestroy = function () {
        this.localStorageService.delete('resourceId');
        this.localStorageService.delete('job');
        this.localStorageService.delete('notificationId');
        this.localStorageService.delete('assignmentData');
        if (this.newMessageSubscription) {
            this.newMessageSubscription.unsubscribe();
        }
        if (this.searchResultSubscription) {
            this.searchResultSubscription.unsubscribe();
        }
    };
    MessagingQueueComponent.prototype.ngAfterViewInit = function () {
        this.getSidenavHeaderHeight();
    };
    MessagingQueueComponent.prototype.getMessageThreadList = function () {
        this.notificationSearchCriteria = new NotificationSearchCriteria();
        this.notificationSearchCriteria.message = true;
        this.notificationSearchCriteria.dmc = true;
        // also get all groupMsgThreadData
        this.notificationService.searchMessageThreads(this.notificationSearchCriteria);
        this.subscribeSearchResults();
    };
    MessagingQueueComponent.prototype.pushToChip = function (newMsgRecipient) {
        if (newMsgRecipient.source.value) {
            var data = newMsgRecipient.source.value;
            var name_1 = (data.firstName ? data.firstName : '') + ' ' + (data.lastName ? data.lastName : '');
            this.singleChatIcon = of(data.icon);
            this.nameCtrl.setValue('');
            this.selectedChats.push({ resourceId: data.resourceId, name: name_1, email: null });
        }
        // this.getSidenavHeaderHeight();
    };
    /**
     * select new resource to send msg
     * @param newMsgRecipient
     */
    MessagingQueueComponent.prototype.addNewChat = function () {
        var _this = this;
        var e_1, _a;
        if (this.selectedChats && this.selectedChats.length > 0) {
            if (this.selectedChats.length == 1) {
                this.isBroadCast = false;
                var name_2 = this.selectedChats[0].name;
                var isExistingThread_1 = false;
                this.deselectAll();
                this.messageThreadList.forEach(function (thread, i) {
                    if (thread.supplierId == _this.selectedChats[0].resourceId) {
                        isExistingThread_1 = true;
                        thread.isSelected = true;
                        _this.selectedThread = thread;
                    }
                });
                if (!isExistingThread_1) {
                    this.addNewThread(this.selectedChats[0], name_2);
                }
                this.selectedChats.splice(0, 1);
            }
            else {
                var headerName_1 = '';
                this.isBroadCast = true;
                this.selectedChats.forEach(function (chat, index) {
                    _this.userNameAndResourceIdList.set(chat.resourceId, chat.name);
                    _this.createdThreadGroup.push(chat.resourceId);
                    if (index == 0) {
                        headerName_1 = headerName_1 + chat.name;
                    }
                    else {
                        headerName_1 = headerName_1 + ',' + chat.name;
                    }
                });
                this.deselectAll();
                var groupMsgThread = new GroupMsgThread();
                var groupMsgMembers = [];
                try {
                    for (var _b = tslib_1.__values(this.createdThreadGroup), _c = _b.next(); !_c.done; _c = _b.next()) {
                        var groupMember = _c.value;
                        var groupMsgMember = new GroupMsgMember();
                        groupMsgMember.resourceId = groupMember;
                        groupMsgMember.email = this.userEmailAndResourceIdList.get(groupMsgMember.resourceId);
                        groupMsgMember.name = this.userNameAndResourceIdList.get(groupMsgMember.resourceId);
                        groupMsgMembers.push(groupMsgMember);
                    }
                }
                catch (e_1_1) { e_1 = { error: e_1_1 }; }
                finally {
                    try {
                        if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                    }
                    finally { if (e_1) throw e_1.error; }
                }
                groupMsgThread.groupMsgMembers = groupMsgMembers;
                groupMsgThread.groupThreadName = this.setShortNameForNew(headerName_1);
                this.saveGroupThread(groupMsgThread);
            }
            // this.getSidenavHeaderHeight();
        }
    };
    /**
     * save group Thread
     * GroupMsgThread
     */
    MessagingQueueComponent.prototype.saveGroupThread = function (saveGroupThread) {
        var _this = this;
        var savingResourceIds = [];
        saveGroupThread.groupMsgMembers.forEach(function (member) {
            savingResourceIds.push(member.resourceId);
        });
        this.userService.getEmailsForResourceIds(savingResourceIds).subscribe(function (result) {
            if (result) {
                result.data.forEach(function (data) {
                    _this.userEmailAndResourceIdList.set(data.resourceId, data.email);
                });
                saveGroupThread.groupMsgMembers.forEach(function (member) {
                    member.email = _this.userEmailAndResourceIdList.get(member.resourceId);
                });
            }
            _this.notificationService.saveGroupThread(saveGroupThread).subscribe(function (data) {
                if (data) {
                    if (data.data.length > 0) {
                        _this.groupMsgThreadId = data.data[0].groupMsgThreadId;
                        _this.groupMsgThread = data.data[0];
                        var msgThread = new MessageThread();
                        msgThread.groupMsgThreadId = _this.groupMsgThread.groupMsgThreadId;
                        msgThread.mostRecentMessages = [];
                        msgThread.chatHeadDisplayNameShort = _this.setShortNameForNew(_this.groupMsgThread.groupThreadName);
                        msgThread.groupThreadName = _this.setShortNameForNew(_this.groupMsgThread.groupThreadName);
                        msgThread.icon = of('assets/img/user-profile/chat-group.svg');
                        msgThread.isSelected = true;
                        _this.selectedThread = msgThread;
                        _this.messageThreadList.unshift(msgThread);
                        var receiverList_1 = [];
                        _this.groupMsgThread.groupMsgMembers.forEach(function (member) {
                            if (member.resourceId > 0) {
                                receiverList_1.push({ resourceId: member.resourceId, name: name, email: member.email });
                            }
                            if (member.supplierId > 0) {
                                receiverList_1.push({ resourceId: member.supplierId, name: name, email: member.email });
                            }
                        });
                        _this.group = receiverList_1;
                        msgThread.receivingResourcesIds = receiverList_1;
                        _this.selectedChats = [];
                    }
                    _this.common.showSnackBar('Group Thread Initiated Successfully.', 3000, TcErrorType.TYPE.INFO);
                    // this.getGroupThreadData();
                }
            }, function (error) {
                _this.common.showSnackBar('Failed to initiate Group Thread.', 3000, TcErrorType.TYPE.ERROR);
            });
        });
    };
    /**
     * search groupThread
     * @param groupThreadId
     */
    MessagingQueueComponent.prototype.getGroupThreadData = function () {
        var _this = this;
        this.dataStore.set(DataKey.groupThreadData, null, true);
        this.notificationService.getGroupThread('savedThreadId').subscribe(function (result) {
            if (result) {
                var groupThread = ResponseUtil.getDataArray(result).sort();
                _this.dataStore.set(DataKey.groupThreadData, groupThread, true);
            }
        });
    };
    MessagingQueueComponent.prototype.removeChatChip = function (index) {
        this.selectedChats.splice(index, 1);
        // this.getSidenavHeaderHeight();
    };
    MessagingQueueComponent.prototype.addNewThread = function (resource, name) {
        var msgThread = new MessageThread();
        msgThread.resourceId = resource.resourceId;
        msgThread.email = resource.email;
        msgThread.resourceName = name;
        msgThread.mostRecentMessages = [];
        msgThread.isSelected = true;
        msgThread.chatHeadDisplayNameShort = this.setShortNameForNew(msgThread.resourceName);
        msgThread.icon = this.singleChatIcon;
        this.messageThreadList.unshift(msgThread);
        this.selectedThread = msgThread;
    };
    /**
     * search for resource and process data
     * @param searchKey
     */
    MessagingQueueComponent.prototype.searchForResource = function (searchKey) {
        var _this = this;
        this.genericSearchCriteria.name = searchKey;
        return this.genericResourceService.searchGenericResourceWithoutDataKey(this.genericSearchCriteria).pipe(map(function (results) {
            var e_2, _a;
            var resourceList = [];
            var finalResourceList = [];
            if (results.data && results.data.length == 0) {
                _this.searchingInit = false;
                _this.searchErr = true;
            }
            else {
                resourceList = results.data;
                try {
                    for (var resourceList_1 = tslib_1.__values(resourceList), resourceList_1_1 = resourceList_1.next(); !resourceList_1_1.done; resourceList_1_1 = resourceList_1.next()) {
                        var resource = resourceList_1_1.value;
                        resource = _this.processData(resource);
                    }
                }
                catch (e_2_1) { e_2 = { error: e_2_1 }; }
                finally {
                    try {
                        if (resourceList_1_1 && !resourceList_1_1.done && (_a = resourceList_1.return)) _a.call(resourceList_1);
                    }
                    finally { if (e_2) throw e_2.error; }
                }
                // resourceList.filter( ( el ) => !this.selectedResourceList.includes( el ) );
                _this.searchingInit = false;
                _this.searchErr = false;
            }
            if (_this.selectedChats.length == 0) {
                finalResourceList = resourceList;
            }
            else {
                resourceList.forEach(function (resource) {
                    var found = false;
                    _this.selectedChats.forEach(function (chat) {
                        if (resource.resourceId === chat.resourceId) {
                            found = true;
                        }
                    });
                    if (!found) {
                        finalResourceList.push(resource);
                    }
                });
            }
            return finalResourceList;
        }), catchError(function (_) {
            return of(null);
        }));
    };
    /**
     * get ad process data for resource search for new msg
     * @param resource
     * @param arr
     */
    MessagingQueueComponent.prototype.processData = function (resource) {
        if (resource.resourceId || resource.supplierId) {
            var id = resource.resourceId !== null ? resource.resourceId : resource.supplierId;
            var searchCriteria = new UserProfileSearchCriteria();
            searchCriteria.resourceId = id;
            this.userService.getResourceServiceProfilesWithoutKey(searchCriteria).subscribe(function (result) {
                var data = ResponseUtil.getDataArray(result);
                if (data[0] && data[0].imageUrl && data[0].imageUrl != null) {
                    resource.icon = data[0].imageUrl;
                }
                else {
                    resource.icon = 'assets/img/user-profile/gender_unknown_user.svg';
                }
            });
        }
        else {
            resource.icon = 'assets/img/user-profile/gender_unknown_user.svg';
        }
        //load icon from configs
        // let iconView = new Icon();
        // let summaryIcon: any;
        // let iconValue: any;
        // summaryIcon = arr.find(
        //     item => item['iconName'] === 'GENERIC_RESOURCE_ICN');
        // iconValue = summaryIcon['key'];
        // if(iconValue === "resource"){
        //     iconValue = resource.resourceType;
        // }
        // if (summaryIcon) {
        //     if (summaryIcon.iconKeys[iconValue]) {
        //         iconView.key = summaryIcon.iconKeys[iconValue];
        //     }
        //     iconView.iconType = summaryIcon['iconType'];
        //     iconView.value = summaryIcon.values[iconValue];
        //     iconView.style = summaryIcon['style'] + ' ' +
        //         summaryIcon['activeClass'];
        //     iconView.value = iconView.value ? iconView.value : '';
        // }
        // resource.icon = iconView;
    };
    MessagingQueueComponent.prototype.subscribeSearchResults = function () {
        var _this = this;
        this.searchResultSubscription = this.dataStore.get(DataKey.messageSearchResultsFromService)
            .subscribe(function (data) {
            if (data) {
                _this.messageThreadList = ResponseUtil.getDataArray(data);
                if (_this.messageThreadList.length > 0) {
                    _this.messageThreadList.forEach(function (thread) {
                        _this.calculateUnread(thread);
                    });
                    if (_this.localStorageService.load('resourceId') !== null &&
                        _this.localStorageService.load('resourceId') !== '') {
                        var id_1 = _this.localStorageService.load('resourceId');
                        var isAssignmentMsg = _this.localStorageService.load('isAssignment');
                        var notificationId = _this.localStorageService.load('notificationId');
                        if (notificationId && notificationId > 0) {
                            _this.selectedNotificationId = +notificationId;
                        }
                        var hasThread_1 = false;
                        _this.messageThreadList.forEach(function (thread) {
                            if (thread.supplierId == id_1 || thread.resourceId ==
                                id_1) {
                                hasThread_1 = true;
                                thread.isSelected = true;
                                _this.selectedThread = thread;
                                console.log('Thread selected');
                            }
                            else {
                                console.log('Thread is not selected');
                                thread.isSelected = false;
                            }
                        });
                        if (isAssignmentMsg && isAssignmentMsg == 'true') {
                            var msgData = _this.localStorageService.load('assignmentData');
                            if (msgData !== null && msgData && !hasThread_1) {
                                var thread = new MessageThread();
                                thread.resourceName = msgData.resourceName;
                                thread.resourceId = msgData.resourceId;
                                if (thread.groupMsgThreadId > 0) {
                                    thread.chatHeadDisplayNameShort = _this.setShortNameForNew(thread.groupThreadName);
                                }
                                else {
                                    thread.chatHeadDisplayNameShort = _this.setShortNameForNew(thread.resourceName);
                                }
                                thread.isSelected = true;
                                thread.mostRecentMessages = [];
                                thread.mostRecentMessages.push(msgData);
                                _this.messageThreadList.unshift(thread);
                                _this.selectedThread = _this.messageThreadList[0];
                            }
                            else if (msgData !== null && msgData && hasThread_1) {
                                _this.selectedThread.mostRecentMessages.push(msgData);
                            }
                        }
                        if (!hasThread_1) {
                            _this.messageThreadList[0].isSelected = true;
                            _this.updateReadStatus(_this.messageThreadList[0]);
                            _this.selectedThread = _this.messageThreadList[0];
                        }
                    }
                    else {
                        _this.messageThreadList[0].isSelected = true;
                        _this.updateReadStatus(_this.messageThreadList[0]);
                        _this.selectedThread = _this.messageThreadList[0];
                    }
                    _this.messagePresent = true;
                }
                else {
                    _this.messagePresent = false;
                }
            }
            else {
                _this.messageThreadList = [];
            }
        });
    };
    MessagingQueueComponent.prototype.updateReadStatus = function (thread) {
        var _this = this;
        thread.mostRecentMessages.forEach(function (msg) {
            if (!msg.sendingDmc) {
                var notificationStateChangeData = new NotificationStateChangeData();
                notificationStateChangeData.dmcUser = true;
                notificationStateChangeData.notificationId = msg.notificationId;
                notificationStateChangeData.resourceId = msg.resourceId;
                notificationStateChangeData.supplierId = msg.supplierId;
                _this.notificationService.changeNotificationState(notificationStateChangeData).subscribe(function (response) {
                    console.log(response);
                });
            }
        });
    };
    /**
     *
     * @param thread
     */
    MessagingQueueComponent.prototype.calculateUnread = function (thread) {
        var count = 0;
        thread.mostRecentMessages.forEach(function (message) {
            if (!message.readStatus && !message.sendingDmc) {
                count++;
            }
        });
        if (count != 0) {
            thread.hasNewNotification = true;
            thread.mostRecentNotificationCount = count;
        }
    };
    /**
     *
     * @param thread
     */
    MessagingQueueComponent.prototype.setGroupThreadIcon = function (thread) {
        if (thread.groupMsgThreadId > 0) {
            thread.icon = of('assets/img/user-profile/chat-group.svg');
        }
    };
    /***
     * select thread using id and index
     * @param data
     */
    MessagingQueueComponent.prototype.selectThread = function (data) {
        if ((this.messageThreadList.length > data.index && data.index >= 0) && this.messageThreadList[data.index]) {
            this.selectedThread = this.messageThreadList[data.index];
            if (!this.selectedThread) {
                console.log(' resourceId or supplierId or groupMsgThreadId not found');
                this.findThreadById(data.id);
            }
        }
    };
    /***
     * find thread with resource id
     * @param id
     */
    MessagingQueueComponent.prototype.findThreadById = function (id) {
        var _this = this;
        this.messageThreadList.forEach(function (thread) {
            if (thread.supplierId == id || thread.resourceId == id || thread.groupMsgThreadId == id) {
                _this.selectedThread = thread;
            }
        });
    };
    MessagingQueueComponent.prototype.subscribeNewMessages = function () {
        var _this = this;
        this.newMessageSubscription = this.dataStore.get(DataKey.newMessages)
            .subscribe(function (data) {
            if (data) {
                _this.newMessage = data[0];
                if (_this.newMessage.type === NotificationType.RESOURCE_COMMUNICATION
                    ||
                        (_this.newMessage.type === NotificationType.RESOURCE_BROADCAST)) {
                    if (_this.selectedThread &&
                        (_this.selectedThread.supplierId ===
                            _this.newMessage.sendingResourceId)
                        || (_this.selectedThread.groupMsgThreadId ===
                            _this.newMessage.groupMsgThreadId)) {
                        _this.newMessage.displayTimeStamp = moment().calendar();
                        // TODO need to change update readStatus based on groupThreadId
                        _this.updateReadStatus(_this.selectedThread);
                    }
                    var isNewThread_1 = true;
                    _this.messageThreadList.forEach(function (thread) {
                        if ((thread.resourceId > 0 && thread.resourceId ===
                            _this.newMessage.sendingResourceId &&
                            _this.newMessage.groupMsgThreadId === 0)
                            || (thread.resourceId > 0 && thread.resourceId ===
                                _this.newMessage.receivingResourceId &&
                                _this.newMessage.groupMsgThreadId === 0)
                            || (thread.supplierId > 0 && thread.supplierId ===
                                _this.newMessage.sendingSupplierId &&
                                _this.newMessage.groupMsgThreadId === 0)
                            || (thread.supplierId > 0 && thread.supplierId ===
                                _this.newMessage.receivingSupplierId &&
                                _this.newMessage.groupMsgThreadId === 0)
                            || (thread.groupMsgThreadId > 0 && thread.groupMsgThreadId ===
                                _this.newMessage.groupMsgThreadId)) {
                            if (!_this.newMessage.sendingDmc) {
                                thread.hasNewNotification = true;
                            }
                            thread.displayTimeStamp = moment().calendar();
                            _this.newMessage.description.length > 25 ?
                                thread.mostRecentShortDescription = _this.newMessage.description.slice(0, 22) + '...' :
                                thread.mostRecentShortDescription = _this.newMessage.description;
                            if (thread.mostRecentNotificationCount &&
                                !_this.newMessage.sendingDmc) {
                                thread.mostRecentNotificationCount = thread.mostRecentNotificationCount +
                                    1;
                            }
                            else {
                                thread.mostRecentNotificationCount = 1;
                            }
                            _this.newMessage.displayTimeStamp = moment().calendar();
                            if (thread.mostRecentMessagesFE &&
                                thread.mostRecentMessagesFE.length > 0) {
                                // this.newMessage.notificationId = thread.mostRecentMessagesFE.length+1;
                                // thread.mostRecentMessagesFE.push(this.newMessage);
                                thread.mostRecentMessages.push(_this.newMessage);
                            }
                            else {
                                thread.mostRecentMessagesFE = [];
                                // this.newMessage.notificationId = 1;
                                // thread.mostRecentMessagesFE.push(this.newMessage);
                                thread.mostRecentMessages.push(_this.newMessage);
                            }
                            if (thread.groupMsgThreadId > 0 &&
                                thread.chatHeadDisplayNameShort == null) {
                                thread.chatHeadDisplayNameShort = _this.setShortNameForNew(thread.groupThreadName);
                            }
                            else if (thread.chatHeadDisplayNameShort == null) {
                                thread.chatHeadDisplayNameShort = _this.setShortNameForNew(thread.resourceName);
                            }
                            isNewThread_1 = false;
                        }
                    });
                    if (isNewThread_1) {
                        var msgThread_1 = new MessageThread();
                        msgThread_1.groupMsgThreadId = _this.newMessage.groupMsgThreadId;
                        if (_this.newMessage.sendingResourceId) {
                            msgThread_1.resourceId = _this.newMessage.sendingResourceId;
                        }
                        else if (_this.newMessage.receivingResourceId) {
                            msgThread_1.resourceId = _this.newMessage.receivingResourceId;
                        }
                        if (_this.newMessage.sendingSupplierId) {
                            msgThread_1.supplierId = _this.newMessage.sendingSupplierId;
                        }
                        else if (_this.newMessage.receivingSupplierId) {
                            msgThread_1.supplierId = _this.newMessage.receivingSupplierId;
                        }
                        msgThread_1.groupThreadName = _this.newMessage.groupThreadName;
                        msgThread_1.resourceName = _this.newMessage.resourceName;
                        msgThread_1.supplierName = _this.newMessage.supplierName;
                        msgThread_1.mostRecentMessages.push(_this.newMessage);
                        msgThread_1.hasNewNotification = true;
                        msgThread_1.displayTimeStamp = moment().calendar();
                        _this.newMessage.description.length > 25 ?
                            msgThread_1.mostRecentShortDescription = _this.newMessage.description.slice(0, 22) + '...' :
                            msgThread_1.mostRecentShortDescription = _this.newMessage.description;
                        if (msgThread_1.mostRecentNotificationCount) {
                            msgThread_1.mostRecentNotificationCount = msgThread_1.mostRecentNotificationCount +
                                1;
                        }
                        else {
                            msgThread_1.mostRecentNotificationCount = 1;
                        }
                        _this.newMessage.displayTimeStamp = moment().calendar();
                        // if(msgThread.mostRecentMessagesFE && msgThread.mostRecentMessagesFE.length>0){
                        //     this.newMessage.notificationId = msgThread.mostRecentMessagesFE.length+1;
                        //     msgThread.mostRecentMessagesFE.push(this.newMessage);
                        // }else{
                        //     msgThread.mostRecentMessagesFE=[];
                        //     this.newMessage.notificationId = 1;
                        //     msgThread.mostRecentMessagesFE.push(this.newMessage);
                        // }
                        if (msgThread_1.groupMsgThreadId > 0) {
                            msgThread_1.chatHeadDisplayNameShort = _this.setShortNameForNew(msgThread_1.groupThreadName);
                        }
                        else {
                            msgThread_1.chatHeadDisplayNameShort = _this.setShortNameForNew(msgThread_1.resourceName);
                        }
                        if (_this.newMessage.groupMsgThreadId > 0) {
                            msgThread_1.icon = of('assets/img/user-profile/chat-group.svg');
                        }
                        else {
                            var searchCriteria_1 = new UserProfileSearchCriteria();
                            searchCriteria_1.resourceId = _this.newMessage.sendingResourceId;
                            _this.userService.getResourceServiceProfilesWithoutKey(searchCriteria_1)
                                .subscribe(function (results) {
                                searchCriteria_1.resourceId = null;
                                msgThread_1.icon = of('assets/img/user-profile/gender_unknown_user.svg');
                                if (results.data[0] && results.data[0].imageUrl &&
                                    results.data[0].imageUrl != null) {
                                    msgThread_1.icon = of(results.data[0].imageUrl);
                                }
                            });
                        }
                        _this.messageThreadList.unshift(msgThread_1);
                    }
                }
            }
        });
    };
    MessagingQueueComponent.prototype.setShortNameForNew = function (name) {
        return name.length > 30 ? name.slice(0, 28) + '.. ' : name;
    };
    MessagingQueueComponent.prototype.deselectAll = function () {
        this.messageThreadList.forEach(function (thread) {
            thread.isSelected =
                false;
        });
    };
    //receive dummy msg
    MessagingQueueComponent.prototype.receiveDummy = function () {
        this.notificationService.receiveDummyMsg();
    };
    MessagingQueueComponent.prototype.sendDummy = function () {
        this.notificationService.sendDummyMessage();
    };
    MessagingQueueComponent.prototype.sendBroadCast = function () {
        this.notificationService.sendBroadCast();
    };
    MessagingQueueComponent.prototype.getSidenavHeaderHeight = function () {
        this.sidebarHeaderHeight = this.mq_sidebar_header.nativeElement.offsetHeight;
        console.log('Height: ' + this.sidebarHeaderHeight);
    };
    return MessagingQueueComponent;
}());
export { MessagingQueueComponent };
