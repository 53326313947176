import * as tslib_1 from "tslib";
import { Injector } from '@angular/core';
import { ConfigLoader } from '@tc-core/util/framework/config-loader.service';
import { CommonHelper } from '@tc-core/util/helpers/common-helper.service';
import { DateFormatter } from '@tc-core/util/system/date-formatter.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { DataHandlerService } from '../backend-consumers/master-data-handler-service/data-handler.service';
import { DataKey, DataStoreService } from '../util/framework/data-store.service';
var ChipHandlerService = /** @class */ (function () {
    /*dataServiceHandler: DataServiceHandler;
     clientServiceHandler: ClientSearchServiceHandler;*/
    function ChipHandlerService(injector, dataStore, 
    // private rootService: RootService,
    // private serviceHandler: ServiceHandler,
    configLoader, commonHelper, dateFormatter, dataHandlerService
    // private authorizationService: AuthorizationService
    ) {
        this.injector = injector;
        this.dataStore = dataStore;
        this.configLoader = configLoader;
        this.commonHelper = commonHelper;
        this.dateFormatter = dateFormatter;
        this.dataHandlerService = dataHandlerService;
        this.chipsSubject = new BehaviorSubject([]);
        this.relatedChipsProcessSubject = new BehaviorSubject([]);
        this.chipsArr = [];
        this.relatedChipsArr = [];
        // this.dataServiceHandler = this.serviceHandler.getDataServiceHandler();
        // this.clientServiceHandler = this.serviceHandler.getClientSearchServiceHandler();
    }
    ChipHandlerService.prototype.preProcessChips = function (chip, keys) {
        var _this = this;
        if (chip.optionParams.optionService && chip.optionParams.optionServiceMethod &&
            chip.optionParams.optionServiceObservable
            && chip.optionParams.optionServiceMethod !== '' && chip.optionParams.optionServiceObservable !== '') {
            var srv = chip.optionParams.optionService;
            var fnc = chip.optionParams.optionServiceMethod;
            var obsv = chip.optionParams.optionServiceObservable;
            var dataLoader = this.injector.get(srv);
            // function call in ChipsDataLoaderService
            try {
                dataLoader[fnc]().subscribe(function (data) {
                    if (data && JSON.stringify(data) !== JSON.stringify([])) {
                        if (_this.chipsArr && _this.chipsArr.length > 0) {
                            if (!_this.checkIfExistAlready(_this.chipsArr, chip)) {
                                chip.optionParams.options = _this.authorizeChipOptions(keys, data, chip);
                                _this.chipsArr.push(chip);
                                _this.chipsSubject.next(_this.chipsArr);
                            }
                        }
                        else {
                            chip.optionParams.options = _this.authorizeChipOptions(keys, data, chip);
                            if (!_this.checkIfExistAlready(_this.chipsArr, chip)) {
                                _this.chipsArr.push(chip);
                            }
                            _this.chipsSubject.next(_this.chipsArr);
                        }
                    }
                    else if (data && JSON.stringify(data) === JSON.stringify([])) {
                        if (_this.chipsArr && _this.chipsArr.length > 0) {
                            if (!_this.checkIfExistAlready(_this.chipsArr, chip)) {
                                chip.optionParams.options = data;
                                _this.chipsArr.push(chip);
                                _this.chipsSubject.next(_this.chipsArr);
                            }
                        }
                        else {
                            chip.optionParams.options = data;
                            if (!_this.checkIfExistAlready(_this.chipsArr, chip)) {
                                _this.chipsArr.push(chip);
                            }
                            _this.chipsSubject.next(_this.chipsArr);
                        }
                    }
                }, function (error) {
                    console.log(error);
                });
            }
            catch (e) {
                console.log(e);
            }
            // behavior subject subscription
            /*dataLoader[obsv]
             .subscribe(data => {
             if (data && JSON.stringify(data) !== JSON.stringify([])) {
             chip.optionParams.options = data;
             this.chipsArr.push(chip);
             this.chipsSubject.next(this.chipsArr);
             }
             });*/
        }
        else {
            switch (chip.criteriaType) {
                case 'LIST_MULTI': {
                    chip.dataParams.forEach(function (dataParam) {
                        switch (dataParam.paramType) {
                            case 'OBJ_LIST': {
                                chip.optionParams.options = _this.authorizeChipWithConfigMasterData(keys, chip);
                                break;
                            }
                            case 'TEXT_TILDER': {
                                chip.optionParams.options = _this.authorizeChipWithConfigMasterData(keys, chip);
                                break;
                            }
                            case 'OVERRIDE_OBJ_LIST': {
                                chip.optionParams.options = _this.authorizeChipWithConfigMasterData(keys, chip);
                                break;
                            }
                        }
                    });
                    if (!this.checkIfExistAlready(this.chipsArr, chip)) {
                        this.chipsArr.push(chip);
                    }
                    this.chipsSubject.next(this.chipsArr);
                    break;
                }
                default: {
                    if (!this.checkIfExistAlready(this.chipsArr, chip)) {
                        this.chipsArr.push(chip);
                    }
                    this.chipsSubject.next(this.chipsArr);
                    break;
                }
            }
            // this.chipsArr.push(chip);
            // this.chipsSubject.next(this.chipsArr);
        }
    };
    ChipHandlerService.prototype.authorizeChipOptions = function (permissionKeys, data, chip) {
        // check if permission keys are available for this processing chip's criteria chip config (this has to be set in chip search criteria config)
        /*if(permissionKeys){
         let queryParams: string[] = [];
         let allowedQueryParams : string[] = [];
         // making query param array from returned data
         data.forEach((data: any) => {
         queryParams.push(data.code);
         });
         // calling authorization service : getting allowed query params.
         // allowedQueryParams = this.authorizationService.getAllowedPrams(permissionKeys, chip.dataParams[0].paramValue, queryParams);

         // checking and injecting property.
         data.forEach((data: any) => {
         data["restricted"] = -1;
         });
         return data;
         } else {
         return data;
         }*/
        /*let queryParams: string[] = [];
         let allowedQueryParams : string[] = [];
         // making query param array from returned data
         data.forEach((data: any) => {
         queryParams.push(data.code);
         });
         // calling authorization service : getting allowed query params.
         // allowedQueryParams = this.authorizationService.getAllowedPrams(permissionKeys, chip.dataParams[0].paramValue, queryParams);

         // checking and injecting property.
         data.forEach((data: any) => {
         data["restricted"] = -1;
         });*/
        return data;
    };
    ChipHandlerService.prototype.authorizeChipWithConfigMasterData = function (permissionKeys, chip) {
        return chip.optionParams.options;
        /*let key;
         if (permissionKeys && permissionKeys.length && permissionKeys.length > 0) {
         key = permissionKeys;
         } else if (chip.permissionKey) {
         key = chip.permissionKey;
         }

         if (key && chip.optionParams.options && chip.optionParams.options.length > 0) {
         let queryParams = [];
         chip.optionParams.options.forEach((data: any) => {
         if (typeof(data.code) === 'string') {
         queryParams.push(data.code);
         } else {
         queryParams.push(data.code.value);
         }
         });
         let allowedQueryParams = this.authorizationService.getAllowedPrams(
         key,
         chip.dataParams[0].paramValue,
         queryParams
         );
         // if (queryParams.length > 0 && allowedQueryParams.length === 0) {
         //   // has to implement restricted property after lalinda's color blind related chip modifications
         //   chip.optionParams.disabled = true;
         // }

         // checking and injecting property.
         chip.optionParams.options.forEach((data: any) => {
         if (typeof(data.code) === 'string') {
         data['restricted'] = allowedQueryParams.indexOf(data.code) === -1;
         } else {
         if (typeof data.code.value === 'boolean') {
         data['restricted'] = allowedQueryParams.indexOf((data.code.value ? 'true' : 'false')) === -1;
         } else {
         data['restricted'] = allowedQueryParams.indexOf(data.code.value) === -1;
         }
         }
         if (this.authorizationService.isAccessiblePramDataAvailable(
         key,
         chip.dataParams[0].paramValue
         )) {
         // following only applies to a boolean chip
         if (allowedQueryParams.length === 0 && typeof chip.dataParams[0].defaultValue === 'boolean') {
         data['restricted'] = !chip.dataParams[0].defaultValue;
         } else if (allowedQueryParams.length === 1 && typeof chip.dataParams[0].defaultValue === 'boolean') {
         data['restricted'] = chip.dataParams[0].defaultValue;
         }
         }

         });

         return chip.optionParams.options;
         } else {
         return chip.optionParams.options;
         }*/
    };
    ChipHandlerService.prototype.handleInterRelatedChips = function (chip, params) {
        var _this = this;
        this.relatedChipsArr = [];
        if (chip && chip.optionParams.isRelated) {
            if (chip.optionParams.relatedChips && chip.optionParams.relatedChips.length > 0) {
                chip.optionParams.relatedChips.forEach(function (c) {
                    if (c.optionService && c.optionServiceMethod && c.optionServiceObservable &&
                        c.optionServiceMethod !== ''
                        && c.optionServiceObservable !== '') {
                        var srv = c.optionService;
                        var fnc = c.optionServiceMethod;
                        var obsv = c.optionServiceObservable;
                        // empty options array
                        c.options = [];
                        var dataLoader = _this.injector.get(srv);
                        // reset observable
                        _this.dataStore.set(DataKey[obsv], null);
                        var dataLoaderSubscription_1 = new Subscription();
                        dataLoaderSubscription_1 = dataLoader[fnc](params)
                            .subscribe(function (data) {
                            if (data && data.status && data.status.code === 1) {
                                if (c.isLevelUp) {
                                    if (data.data && data.data[0].parent && data.data[0].parent.length > 0) {
                                        data.data[0].parent.forEach(function (p) {
                                            if (c.dataParam === p.type) {
                                                c.options.push(p);
                                                _this.relatedChipsArr.push(c);
                                                _this.relatedChipsProcessSubject.next(_this.relatedChipsArr);
                                            }
                                        });
                                        if (dataLoaderSubscription_1) {
                                            dataLoaderSubscription_1.unsubscribe();
                                        }
                                    }
                                    // used in not hierarchical scenario
                                    if (data.data && !data.data[0].parent) {
                                        c.options = data.data;
                                        _this.relatedChipsArr.push(c);
                                        _this.relatedChipsProcessSubject.next(_this.relatedChipsArr);
                                        if (dataLoaderSubscription_1) {
                                            dataLoaderSubscription_1.unsubscribe();
                                        }
                                    }
                                }
                                else {
                                    c.options = data.data;
                                    _this.relatedChipsArr.push(c);
                                    _this.relatedChipsProcessSubject.next(_this.relatedChipsArr);
                                    if (dataLoaderSubscription_1) {
                                        dataLoaderSubscription_1.unsubscribe();
                                    }
                                }
                            }
                        });
                    }
                    else {
                        c.options = params;
                        _this.relatedChipsArr.push(c);
                        _this.relatedChipsProcessSubject.next(_this.relatedChipsArr);
                    }
                });
            }
        }
    };
    ChipHandlerService.prototype.getResourceTypes = function () {
        return this.dataStore.get(DataKey.resourceTypes);
    };
    ChipHandlerService.prototype.getVehicleModels = function () {
        return this.dataStore.get(DataKey.vehicleModels);
    };
    ChipHandlerService.prototype.getDocumentTypesForReports = function () {
        return this.dataStore.get(DataKey.documentTypesReports);
    };
    ChipHandlerService.prototype.getDocumentTypesForManifests = function () {
        return this.dataStore.get(DataKey.documentTypesManifests);
    };
    ChipHandlerService.prototype.getCountries = function () {
        return this.dataStore.get(DataKey.countryList);
    };
    ChipHandlerService.prototype.getCitiesOfCountry = function (params) {
        return this.dataStore.get(DataKey.cityList);
        // return this.dataStore.get(DataKey.cityList).value;
    };
    ChipHandlerService.prototype.getCities = function () {
        return this.dataStore.get(DataKey.cityList);
    };
    ChipHandlerService.prototype.getTransferModes = function () {
        return this.dataStore.get(DataKey.transferModes);
    };
    ChipHandlerService.prototype.getJobNames = function () {
        return this.dataStore.get(DataKey.commonJobList);
    };
    ChipHandlerService.prototype.getTransferModesWithAny = function () {
        return this.dataStore.get(DataKey.transferModes)
            .pipe(map(function (transferModes) {
            var trsModeList = [];
            var anyTransferMode = {
                code: 'ANY',
                name: 'Any',
                nameOnly: 'ANY'
            };
            trsModeList.push(anyTransferMode);
            if (transferModes) {
                trsModeList.push.apply(trsModeList, tslib_1.__spread(transferModes));
            }
            return trsModeList;
        }), catchError(function (error) {
            return [];
        }));
    };
    ChipHandlerService.prototype.getResourceTypesWithAny = function () {
        return this.dataStore.get(DataKey.resourceTypes)
            .pipe(map(function (resourceTypes) {
            var resourceList = [];
            var anyResource = {
                code: 'ANY',
                name: 'Any',
                nameOnly: 'ANY'
            };
            var allResource = {
                code: 'All',
                name: 'All',
                nameOnly: 'All'
            };
            resourceList.push(anyResource);
            resourceList.push(allResource);
            if (resourceTypes) {
                resourceList.push.apply(resourceList, tslib_1.__spread(resourceTypes));
            }
            return resourceList;
        }), catchError(function (error) {
            return [];
        }));
    };
    ChipHandlerService.prototype.getCurrencyList = function () {
        return this.dataStore.get(DataKey.currencyList);
    };
    ChipHandlerService.prototype.getPaymentMethods = function () {
        return this.dataStore.get(DataKey.paymentMethods);
    };
    ChipHandlerService.prototype.getContactCategories = function () {
        return this.dataStore.get(DataKey.contactCategories);
    };
    ChipHandlerService.prototype.getVehicleAmenities = function () {
        return this.dataStore.get(DataKey.vehicleAmenities);
    };
    // not calling on initial loading
    ChipHandlerService.prototype.getAirports = function () {
        return this.dataStore.get(DataKey.airportArr);
    };
    ChipHandlerService.prototype.getSuppliers = function () {
        return this.dataStore.get(DataKey.supplierList);
    };
    ChipHandlerService.prototype.getRoutes = function () {
        return this.dataStore.get(DataKey.routes);
    };
    ChipHandlerService.prototype.getRoutesWithAny = function () {
        return this.dataStore.get(DataKey.routes)
            .pipe(map(function (routes) {
            var routeList = [];
            var anyRoute = {
                code: 'ANY',
                name: 'ANY'
            };
            routeList.push(anyRoute);
            if (routes) {
                routeList.push.apply(routeList, tslib_1.__spread(routes));
            }
            return routeList;
        }), catchError(function (error) {
            return [];
        }));
    };
    ChipHandlerService.prototype.getLocationTypes = function () {
        return this.dataStore.get(DataKey.locationTypesArr);
    };
    // not calling on initial loading
    ChipHandlerService.prototype.getLocations = function () {
        return this.dataStore.get(DataKey.locationsArr);
    };
    ChipHandlerService.prototype.getAccountingEvents = function () {
        return this.dataStore.get(DataKey.accountingEvents);
    };
    ChipHandlerService.prototype.getLanguages = function () {
        return this.dataStore.get(DataKey.allLanguages);
    };
    ChipHandlerService.prototype.getAccountingUnits = function () {
        return this.dataStore.get(DataKey.accountingUnits);
    };
    ChipHandlerService.prototype.getKcDistChannels = function () {
        return this.dataStore.get(DataKey.kcDistributionChannelList);
    };
    ChipHandlerService.prototype.getKcBrands = function () {
        return this.dataStore.get(DataKey.kcBrandsList);
    };
    ChipHandlerService.prototype.getKcDivisions = function () {
        return this.dataStore.get(DataKey.kcDivisions);
    };
    ChipHandlerService.prototype.getKcCompanies = function () {
        return this.dataStore.get(DataKey.kcCompanies);
    };
    ChipHandlerService.prototype.getKcCompany = function () {
        this.dataStore.set(DataKey.kcCompany, this.dataStore.get(DataKey.kcCompanies).getValue()[0]);
        return this.dataStore.get(DataKey.kcCompany);
    };
    ChipHandlerService.prototype.getPickupTypeOfPickupLocation = function (param) {
        var _this = this;
        this.dataHandlerService.getLocationTypeOfLocation(param).subscribe(function (result) {
            if (result.data) {
                _this.dataStore.set(DataKey.locationTypeOfLocation, result.data, true);
            }
            return _this.dataStore.get(DataKey.locationTypeOfLocation);
        });
    };
    ChipHandlerService.prototype.getLocationsOfPickupType = function (param) {
        var _this = this;
        this.dataHandlerService.getLocationsOfLocationType(param).subscribe(function (result) {
            if (result.data) {
                _this.dataStore.set(DataKey.locationsOfPickupType, result.data, true);
            }
            return _this.dataStore.get(DataKey.locationsOfPickupType);
        });
    };
    ChipHandlerService.prototype.getLocationsOfDropOffType = function (param) {
        return this.retrieveLocationsByDropOffType(param);
    };
    ChipHandlerService.prototype.retrieveLocationsByDropOffType = function (locationType) {
        var _this = this;
        this.dataHandlerService.getLocationsOfLocationType(locationType).pipe(map(function (result) {
            _this.dataStore.set(DataKey.locationsOfDropOffType, result.data, true);
            return _this.dataStore.get(DataKey.locationsOfDropOffType);
        }));
    };
    ChipHandlerService.prototype.generatePersistentChipsArr = function (persistentQParams, searchCriteria, persistentQParamMap, availableChips, persistentChipsArr) {
        // create a queryParamMap
        for (var i = 0; i < persistentQParams.length; i++) {
            var qParam = persistentQParams[i];
            var qParamValue = searchCriteria[qParam];
            persistentQParamMap.set(qParam, qParamValue);
        }
        // fill persistent chips
        availableChips.forEach(function (chip) {
            var persistChip = Object.assign({}, chip);
            persistChip.optionParams.data = [];
            var isPersist = false;
            if (chip.dataParams && chip.dataParams.length > 0) {
                chip.dataParams.forEach(function (dataParam) {
                    if (persistentQParamMap.has(dataParam.paramValue)) {
                        isPersist = true;
                        var dataObj = {
                            'key': dataParam.paramValue,
                            'value': persistentQParamMap.get(dataParam.paramValue)
                        };
                        persistChip.optionParams.data.push(dataObj);
                    }
                });
            }
            // push persist chips to an array
            if (isPersist) {
                persistentChipsArr.push(persistChip);
            }
        });
    };
    ////////////////////////////////////////////////////////////////////////////////
    ChipHandlerService.prototype.getChipsFromCriteria = function (criteria, chipConfig, isFromKey) {
        var _this = this;
        var propertyChipMap = new Map();
        var requiredChips = [];
        var chipConfigs = null;
        if (isFromKey) {
            chipConfigs = this.configLoader.configurations.get(chipConfig);
            chipConfigs.chips.forEach(function (chip) {
                chip.dataParams.forEach(function (param) {
                    var chip_ = _this.commonHelper.deepCopy(chip);
                    propertyChipMap.set(param.paramValue, chip_);
                });
            });
        }
        else {
            chipConfig.forEach(function (chip) {
                chip.dataParams.forEach(function (param) {
                    var chip_ = _this.commonHelper.deepCopy(chip);
                    propertyChipMap.set(param.paramValue, chip_);
                });
            });
        }
        for (var key in criteria) {
            if (criteria[key] && criteria[key] !== '' && criteria[key] !== undefined) {
                var chip = propertyChipMap.get(key);
                if (chip) {
                    if (!this.isAvailableChip(chip.criteriaId, requiredChips)) {
                        var dataObj = {
                            'key': key,
                            'value': criteria[key]
                        };
                        chip.optionParams.data = [];
                        chip.optionParams.data.push(dataObj);
                        requiredChips.push(chip);
                    }
                }
            }
        }
        return requiredChips;
    };
    ChipHandlerService.prototype.isAvailableChip = function (key, chips) {
        var e_1, _a;
        try {
            for (var chips_1 = tslib_1.__values(chips), chips_1_1 = chips_1.next(); !chips_1_1.done; chips_1_1 = chips_1.next()) {
                var chip = chips_1_1.value;
                if (chip.criteriaId && chip.criteriaId == key) {
                    return true;
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (chips_1_1 && !chips_1_1.done && (_a = chips_1.return)) _a.call(chips_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return false;
    };
    ChipHandlerService.prototype.validateAndFilterChips = function (chips, validators) {
        var _this = this;
        var propertyChipMap = new Map();
        var tempChips = this.commonHelper.deepCopy(chips);
        tempChips.forEach(function (chip) {
            chip.optionParams.data.forEach(function (keyVal) {
                propertyChipMap.set(keyVal.key, chip);
            });
        });
        validators.forEach(function (validator) {
            var chipToRemove = _this.checkToRemove(propertyChipMap, validator);
            if (chipToRemove) {
                var index = tempChips.indexOf(chipToRemove);
                tempChips.splice(index, 1);
            }
        });
        return tempChips;
    };
    ChipHandlerService.prototype.checkToRemove = function (propertyChipMap, validator) {
        var validatorParams = validator.split('|');
        var chip = propertyChipMap.get(validatorParams[0]);
        if (!chip) {
            return null;
        }
        switch (validatorParams[1]) {
            case '>':
                return chip.optionParams.data.value > +validatorParams[2] ? null : chip;
            case '<':
                return chip.optionParams.data.value < +validatorParams[2] ? null : chip;
            case '===':
                return chip.optionParams.data.value === +validatorParams[2] ? null : chip;
            case '!==':
                return chip.optionParams.data.value !== +validatorParams[2] ? null : chip;
            case 'SNP': // Should not present
                return chip;
        }
    };
    ChipHandlerService.prototype.checkIfExistAlready = function (chipsArr, chip) {
        var isContain = false;
        this.chipsArr.forEach(function (c) {
            if (c.criteriaId === chip.criteriaId) {
                isContain = true;
            }
        });
        return isContain;
    };
    /**
     * param data formats:
     *  date = 'DD-MM-YYYY'
     *  criteriaId = 'START_DATE' or 'END_DATE' <= this value is related to the given criteriaId in the configs.
     * */
    ChipHandlerService.prototype.setMinDateForRelatedChips = function (chips, date, criteriaId) {
        var chip = chips.chips.filter(function (data) { return data.criteriaId === criteriaId; });
        var minDate = this.dateFormatter.dateFromString(date);
        if (chip[0] && chip[0].optionParams && chip[0].optionParams.behaviouralData) {
            var tempChip = chip[0].optionParams.behaviouralData.filter(function (data) { return data.key === 'minDate'; });
            if (tempChip) {
                tempChip[0].value = minDate;
            }
            else {
                chip[0].optionParams.behaviouralData.push({ key: 'minDate', value: minDate });
            }
        }
    };
    return ChipHandlerService;
}());
export { ChipHandlerService };
