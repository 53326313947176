import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { LEVEL } from '@tc-core/model/it/codegen/ui/framework/dialog-model';
import { ModalData } from '@tc-core/model/it/codegen/ui/framework/modal-data';
import { TC } from '@tc-core/util/constants';
import { ConfigLoader } from '@tc-core/util/framework';
import { EventManager } from '@tc-core/util/framework/event-manager.service';
import { FocusViewManager } from '@tc-core/util/framework/focus-view-manager.service';
import { FocusViewService } from '@tc/focus-view/focus-view.service';
import { ModalService } from '@tc/modal/modal.service';
import { IGetRowsParams } from 'ag-grid-community';
import { Observable, Subscription, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SetupGridComp } from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid-comp';
import { SetupGridDataSource } from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid-data-source';
import { SetupGridComponent } from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid.component';
import { ActionRendererAction } from '../../../widgets/framework/ag-grid-custom-components/renderers/actions/actions.component';
import { TCO } from '../../../constants';
import { DispatchEntrySearchCriteria } from '../../../models/criteria/dispatch-entry-search-criteria';
import { SupplierSearchCriteria } from '../../../models/criteria/supplier-search-criteria';
import { DispatchEntry, DispatchStatus } from '../../../models/document/dispatch-entry';
import { SortDirection } from '../../../models/helper/sort-direction';
import { OPResponseWrapper } from '../../../models/response/op-response-wrapper';
import { SupplierSummary } from '../../../models/summary/supplier-summary';
import { DocumentService } from '../../../services/backend-consumers/documents/document.service';
import { SupplierService } from '../../../services/backend-consumers/supplier-service/supplier.service';
import { DataKey, DataStoreService } from '../../../services/util/framework/data-store.service';
import { TBXResponseWrapper } from '@tc-core/model/it/codegen/tbx/api/tbx-response-wrapper';
import { ResponseUtil } from '../../../services/util/response/response-util.service';
import { DispatchDataModalComponent, DispatchDataModalType } from '../dispatch-data-modal/dispatch-data-modal.component';
import EMPTY_CHAR = TC.FORMAT.EMPTY_CHAR;

@Component({
    selector: 'tc-document-detail-focus-view',
    templateUrl: './document-queue-focus-view.component.html'
})
export class DocumentQueueFocusViewComponent extends SetupGridComp implements OnInit, OnDestroy, SetupGridDataSource
{

    @Input() documentQueue: any;

    defaultSupplier: SupplierSummary;

    @ViewChild('dispatchViewGrid')
    private setupGrid: SetupGridComponent;

    public colDefConfig = [];
    dispatchEntrySearchCriteria: DispatchEntrySearchCriteria = new DispatchEntrySearchCriteria();

    gridActions: ActionRendererAction[] = [];

    @ViewChild('statusCell') statusCell: TemplateRef<any>;
    @ViewChild('dispatchIdCell') dispatchIdCell: TemplateRef<any>;

    dispatchPostPatchModalCloseSubscription: Subscription = new Subscription();

    tbx: TBXResponseWrapper<any> = new TBXResponseWrapper<any>();
    dataUpdated = false;

    constructor(
        private em: EventManager,
        private focusViewManager: FocusViewManager,
        private focusViewService: FocusViewService,
        private configLoader: ConfigLoader,
        private dataStoreService: DataStoreService,
        private documentService: DocumentService,
        private modalService: ModalService
    )
    {
        super();
    }

    ngOnInit() {
        this.focusViewManager.canProceed.next(true);
        this.focusViewManager.canProceedState.next(true);
        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'CLOSE_FOCUS_VIEW')
            .subscribe(e => {
                this.focusViewService.close(true);
            });
        this.colDefConfig = this.configLoader.configurations.get(TCO.CONF.CONF_DOCUMENT_QUEUE_FOCUS_COL_DEF_CONFIG);
        this.addGridActions();
    }

    public createNewRow(): any {
    }

    public deleteRow(row: any): Observable<any> {
        return undefined;
    }

    public getRows(params: IGetRowsParams): Observable<any> {
        console.log('fetch requesting for ' + params.startRow + ' to ' + params.endRow);
        console.log(params.sortModel);
        this.dataStoreService.set(DataKey.documentQueueSummaryResultsForCriteria, this.documentQueue);
        this.tbx.data = this.documentQueue;
        return of(this.tbx);
    }

    public isInvalidRow(params): boolean {
        return false;
    }

    public isUnsavedRow(row: any): boolean {
        return false;
    }

    public ngOnDestroy(): void {
    }

    public saveRow(row: any, event: any): Observable<any> {
        return undefined;
    }

    /*
     grid config methods which are called by grid setup (ag grid)
     and set by grid config
     */

    getSupplierName = (params) => {
        let supText = EMPTY_CHAR;
        if (params && params.data) {
            if (params.data.supplierName) {
                supText = params.data.supplierName;
            } else {
                supText = EMPTY_CHAR;
            }
            supText += ' | ';
            if (params.data.supplierCode) {
                supText += params.data.supplierCode;
            } else {
                supText += EMPTY_CHAR;
            }
        }
        return supText;
    };

    getUserName = (params) => {
        if (params && params.data && params.data.userName) {
            return params.data.systemGenerated ? 'System' : params.data.userName;
        } else if (params && params.data && params.data.systemGenerated) {
            return 'System';
        } else {
            return EMPTY_CHAR;
        }
    };

    statusCellClass = () => {
        return {
            'tc-ag-grid-cell--warning'(params)
            {
                if (params.data && params.data.dispatchStatus && params.data.dispatchStatus ===
                    DispatchStatus.PENDING) {
                    return true;
                }
            },
            'tc-ag-grid-cell--success'(params)
            {
                if (params.data && params.data.dispatchStatus && params.data.dispatchStatus ===
                    DispatchStatus.SUCCESS) {
                    return true;
                }
            },
            'tc-ag-grid-cell--danger'(params)
            {
                if (params.data && params.data.dispatchStatus && params.data.dispatchStatus ===
                    DispatchStatus.FAILED) {
                    return true;
                }
            }
        };
    };

    statusPending = (params) => {
        return params.data && params.data.dispatchStatus && params.data.dispatchStatus ===
            DispatchStatus.PENDING;
    };

    statusSuccess = (params) => {
        return params.data && params.data.dispatchStatus && params.data.dispatchStatus ===
            DispatchStatus.SUCCESS;
    };

    statusFailed = (params) => {
        return params.data && params.data.dispatchStatus && params.data.dispatchStatus ===
            DispatchStatus.FAILED;
    };

    private addGridActions() {
        this.gridActions.push(
            {
                icon: 'send',
                action: this.clickSendAction.bind(this),
                disable: false,
                tooltip: 'Send Now'
            }
        );
    }

    clickSendAction = (params) => {
        console.log('click send action');
        console.log(params);
        const dispatchEntry: DispatchEntry = params.data;
    };

    private confirmModal(data: any): void {
        this.modalService.confirm(data).subscribe((res) => {
        });
    }

    closeModal() {
        if (this.modalService.close()) {
            this.modalService.close().subscribe(
                (res) => {
                }
            );
        }
    }
}
