import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { Subscription } from 'rxjs/index';
import { ProfileTypeSearchCriteria } from '../../../../../models/criteria/profile-type-search-criteria';
import { ResourceTypeSearchCriteria } from '../../../../../models/criteria/resource-type-search-criteria';
import { SupplierSearchCriteria } from '../../../../../models/criteria/supplier-search-criteria';
import { BookingItem } from '../../../../../models/reservation-v2/booking-item';
import { BookingNoteType } from '../../../../../models/reservation-v2/booking-note';
import { BookingPassenger } from '../../../../../models/reservation-v2/booking-passenger';
import { GenBookingItem } from '../../../../../models/reservation-v2/gen-booking-item';
import { PassengerType } from '../../../../../models/reservation-v2/passenger-type';
import { SupplierSummary } from '../../../../../models/summary/supplier-summary';
import { ResourceTypeService } from '../../../../../services/backend-consumers/setups/resource-type.service';
import { SupplierService } from '../../../../../services/backend-consumers/supplier-service/supplier.service';
import { ResponseUtil } from '../../../../../services/util/response/response-util.service';

@Component({
    selector: 'tc-gen-item',
    templateUrl: './gen-item.component.html',
    styleUrls: ['./gen-item.component.scss']
})
export class GenItemComponent implements OnInit, OnDestroy {

    @Input() readonly = false;
    @Input() bookingItem: BookingItem;
    @Input() noteTypes: BookingNoteType[] = [];
    @Input() sameTypeItems = [];
    @Input() allPassengerIds = [];
    @Input() allBookingPassengers: BookingPassenger[] = [];
    @Output() changeValidity: EventEmitter<boolean> = new EventEmitter();

    genBookingItem: GenBookingItem;
    genBookingItemForm: FormGroup;
    dateTimePickerColor: ThemePalette = 'primary';
    notesValid = true;
    today: any;
    startTime: any = null;
    endTime: any = null;

    sameStartDateItems: BookingItem[] = [];
    sameEndDateItems: BookingItem[] = [];
    sameDateTypeItems: BookingItem[] = [];

    suppliersFiltered: any[] = [];
    resourceTypesFiltered: any[] = [];
    profileTypesFiltered: any[] = [];
    supplierSearchCriteria = new SupplierSearchCriteria();
    resourceTypeSearchCriteria = new ResourceTypeSearchCriteria();
    profileTypeSearchCriteria = new ProfileTypeSearchCriteria();

    changesSubscription: Subscription = new Subscription();
    defSupChangesSubscription: Subscription = new Subscription();
    elGrpChangesSubscription: Subscription = new Subscription();
    typeChangesSubscription: Subscription = new Subscription();
    adultCountChangeSubscription: Subscription = new Subscription();
    teenCountChangeSubscription: Subscription = new Subscription();
    childCountChangeSubscription: Subscription = new Subscription();
    infantCountChangeSubscription: Subscription = new Subscription();


    constructor(
        private fb: FormBuilder,
        private supplierService: SupplierService,
        private resourceTypeService: ResourceTypeService
    ) { }

    ngOnInit() {
        // set default minimum datetime as now
        this.today = new Date();
        this.supplierSearchCriteria.size = 10;
        this.supplierSearchCriteria.status = 'true';
        this.resourceTypeSearchCriteria.size = 10;
        this.profileTypeSearchCriteria.size = 10;

        if (this.bookingItem && this.bookingItem.genBookingItem) {
            this.genBookingItem = this.bookingItem.genBookingItem;
        }
        this.createForm();
        this.validateForm();

        //check any sameDateType items in same
        this.getSameDateTypeItems();

        if(this.bookingItem.passengerValidated) {
            this.onChangeCheckBox(true);
        }

        this.changesSubscription = this.genBookingItemForm.valueChanges.subscribe(
            (data) => {
                if (JSON.stringify(data) !== JSON.stringify({})) {
                    this.bookingItem.itemName = data.itemName;
                    this.bookingItem.contractReference = data.contractReference;
                    this.bookingItem.plCode = data.plCode;
                    this.bookingItem.plName = data.plName;
                    this.bookingItem.startTime = data.startDate ? data.startDate.setSeconds(0) : data.startDate;
                    this.bookingItem.endTime = data.endDate ? data.endDate.setSeconds(0) : data.endDate;
                    this.bookingItem.serviceDate = data.startDate ? data.startDate.setSeconds(0) : data.startDate;
                    this.bookingItem.passengerValidated = data.passengerValidated;
                    this.bookingItem.genBookingItem.noOfRoomsRequired = data.roomsRequired ? +data.roomsRequired : 0;
                    this.bookingItem.adultCount = data.occAdultCount ? +data.occAdultCount : 0;
                    this.bookingItem.teenCount = data.occTeenCount ? +data.occTeenCount : 0;
                    this.bookingItem.childrenCount = data.occChildCount ? +data.occChildCount : 0;
                    this.bookingItem.infantCount = data.occInfantCount ? +data.occInfantCount : 0;
                    this.changeValidity.emit(this.genBookingItemForm.valid && this.notesValid && !!this.bookingItem.leadPassenger);
                    //for date validation
                    this.startTime = new Date(this.bookingItem.startTime);
                    this.endTime = new Date(this.bookingItem.endTime);
                }
            });
        this.defSupChangesSubscription = this.genBookingItemForm.get('defaultSupplier').valueChanges.subscribe(data => {
            if (typeof (data) === 'string' && typeof (data) !== 'object') {
                this.filterSupplier(data);
            }
        });
        this.elGrpChangesSubscription = this.genBookingItemForm.get('elementGroup').valueChanges.subscribe(data => {
            if (typeof (data) === 'string' && typeof (data) !== 'object') {
                this.filterElementGrp(data);
            }
        });
        this.typeChangesSubscription = this.genBookingItemForm.get('typeName').valueChanges.subscribe(data => {
            if (typeof (data) === 'string' && typeof (data) !== 'object') {
                this.filterTypes(data);
            }
        });
        this.adultCountChangeSubscription = this.genBookingItemForm.get('occAdultCount').valueChanges.subscribe(data => {
            if(data) {
                this.bookingItem.adultCount = +data;
                this.allocatePaxForGivenCount();
            }
        });
        this.teenCountChangeSubscription = this.genBookingItemForm.get('occTeenCount').valueChanges.subscribe(data => {
            if(data) {
                this.bookingItem.teenCount = +data;
                this.allocatePaxForGivenCount();
            }
        });
        this.childCountChangeSubscription = this.genBookingItemForm.get('occChildCount').valueChanges.subscribe(data => {
            if(data) {
                this.bookingItem.childrenCount = +data;
                this.allocatePaxForGivenCount();
            }
        });
        this.infantCountChangeSubscription = this.genBookingItemForm.get('occInfantCount').valueChanges.subscribe(data => {
            if(data) {
                this.bookingItem.infantCount = +data;
                this.allocatePaxForGivenCount();
            }
        });
    }

    private allocatePaxForGivenCount() {
        if (this.sameDateTypeItems && this.sameDateTypeItems.length > 0 && this.bookingItem && this.bookingItem.passengerValidated) {
            this.onChangeCheckBox(true);
        } else {
            this.allocatePassengers([]);
        }
    }

    public validateForm() {
        Object.keys(this.genBookingItemForm.controls).forEach(field => {
            const control = this.genBookingItemForm.get(field);
            control.markAsTouched({onlySelf: true});
        });
    }

    private createForm() {
        if (this.bookingItem && this.bookingItem.startTime) {
            this.startTime = new Date(this.bookingItem.startTime);
        }
        if (this.bookingItem && this.bookingItem.endTime) {
            this.endTime = new Date(this.bookingItem.endTime);
        }
        this.genBookingItemForm = this.fb.group({
            itemName: new FormControl(
                {value: this.bookingItem ? this.bookingItem.itemName : '', disabled: this.readonly},
                Validators.compose([
                    Validators.required
                ])
            ),
            contractReference: new FormControl(
                {value: this.bookingItem ? this.bookingItem.contractReference : '', disabled: this.readonly},
                Validators.compose([
                    Validators.required
                ])
            ),
            plCode: new FormControl(
                {value: this.bookingItem ? this.bookingItem.plCode : '', disabled: this.readonly},
                Validators.compose([
                    Validators.required
                ])
            ),
            defaultSupplier: new FormControl(
                {value: this.bookingItem ? this.bookingItem.defaultSupplierName : '', disabled: this.readonly},
                Validators.compose([
                    Validators.required
                ])
            ),
            startDate: new FormControl(
                {value: this.startTime, disabled: this.readonly},
                Validators.compose([
                    Validators.required
                ])
            ),
            endDate: new FormControl(
                {value: this.endTime, disabled: this.readonly},
                Validators.compose([
                    Validators.required
                ])
            ),
            elementGroup: new FormControl(
                {value: this.genBookingItem ? this.genBookingItem.productName : '', disabled: this.readonly},
                Validators.compose([
                    Validators.required
                ])
            ),
            typeName: new FormControl(
                {value: this.genBookingItem ? this.genBookingItem.typeName : '', disabled: this.readonly},
                Validators.compose([])
            ),
            occAdultCount: new FormControl(
                {value: this.bookingItem ? this.bookingItem.adultCount : '', disabled: this.readonly},
                Validators.compose([
                    Validators.required
                ])
            ),
            occTeenCount: new FormControl(
                {value: this.bookingItem ? this.bookingItem.teenCount : '', disabled: this.readonly},
                Validators.compose([])
            ),
            occChildCount: new FormControl(
                {value: this.bookingItem ? this.bookingItem.childrenCount : '', disabled: this.readonly},
                Validators.compose([])
            ),
            occInfantCount: new FormControl(
                {value: this.bookingItem ? this.bookingItem.infantCount : '', disabled: this.readonly},
                Validators.compose([])
            ),
            passengerValidated: new FormControl(
                {
                    value: this.bookingItem && this.bookingItem.passengerValidated ?
                        this.bookingItem.passengerValidated :
                        false, disabled: this.readonly
                },
                Validators.compose([])
            )
        });
    }

    getErrorMessage(field: string): string {
        switch (field) {
            case 'itemName' :
                return this.genBookingItemForm.get('itemName').hasError('required') ? 'Item Name Required' : '';
            case 'contractReference' :
                return this.genBookingItemForm.get('contractReference').hasError('required') ? 'Contract Reference Required' : '';
            case 'plCode' :
                return this.genBookingItemForm.get('plCode').hasError('required') ? 'PL Code Required' : '';
            case 'elementGroup' :
                return this.genBookingItemForm.get('elementGroup').hasError('required') ? 'Element Group Required' : '';
            case 'typeName' :
            case 'occAdultCount' :
                return this.genBookingItemForm.get('occAdultCount').hasError('required') ?
                    'Adult Count Required' :
                    '';
                return this.genBookingItemForm.get('typeName').hasError('required') ? 'Type Required' : '';
            case 'startDate' :
                return this.genBookingItemForm.get('startDate').hasError('required') ? 'Start Date Required' : '';
            case 'endDate' :
                return this.genBookingItemForm.get('endDate').hasError('required') ? 'End Date Required' : '';
        }
    }

    public onNotesChange(event: boolean) {
        this.notesValid = event;
        this.changeValidity.emit(this.genBookingItemForm.valid && this.notesValid);
    }

    // supplier
    public displaySupplierFn(sup: any) {
        return sup && sup.name ? sup.name : sup;
    }

    public onSupplierFocus($event: FocusEvent) {
        this.suppliersFiltered = [];
    }

    public onSupplierFocusOut($event: FocusEvent) {
        // const text = this.genBookingItemForm.get('defaultSupplier').value;
        // if (text) {
        //     this.genBookingItemForm.get('defaultSupplier').setValue(this.bookingItem.defaultSupplierName, {onlySelf: true});
        // }
    }

    public onSelectSupplier(supplier: SupplierSummary) {
        this.bookingItem.defaultSupplierName = supplier.name;
        this.bookingItem.defaultSupplierCode = supplier.code;
        this.changeValidity.emit(this.genBookingItemForm.valid && this.notesValid);
    }

    private filterSupplier(input: string) {
        if (input) {
            this.supplierSearchCriteria.codeOrName = input;
            if(this.genBookingItem.productCode) {
                this.supplierSearchCriteria.resourceTypes = [];
                this.supplierSearchCriteria.resourceTypes.push(this.genBookingItem.productCode);
            }
            this.supplierService.searchSuppliersNoDataKey(this.supplierSearchCriteria).subscribe(data => {
                this.suppliersFiltered = ResponseUtil.getDataArray(data);
            });
        } else {
            this.suppliersFiltered = [];
        }
    }

    // element group(resource type)
    public displayElementGrpFn(res: any) {
        return res && res.name ? res.name : res;
    }

    public onElementGrpFocus($event: FocusEvent) {
        this.resourceTypesFiltered = [];
    }

    public onElementGrpFocusOut($event: FocusEvent) {
        // const text = this.genBookingItemForm.get('elementGroup').value;
        // if (text) {
        //     this.genBookingItemForm.get('elementGroup').setValue(this.genBookingItem.productName, {onlySelf: true});
        // }
    }

    public onSelectElementGrp(res: any) {
        this.genBookingItem.productName = res.name;
        this.genBookingItem.productCode = res.code;
        this.genBookingItem.typeCode = undefined;
        this.genBookingItem.typeName = undefined;
        this.changeValidity.emit(this.genBookingItemForm.valid && this.notesValid);
    }

    private filterElementGrp(input: string) {
        if (input) {
            this.resourceTypeSearchCriteria.codeOrName = input;
            this.resourceTypeService.getResourceTypes(this.resourceTypeSearchCriteria).subscribe(data => {
                this.resourceTypesFiltered = ResponseUtil.getDataArray(data);
            });
        } else {
            this.resourceTypesFiltered = [];
        }
    }

    // types (profile type)
    public displayTypeFn(type: any) {
        return type && type.name ? type.name : type;
    }

    public onTypeFocus($event: FocusEvent) {
        this.profileTypesFiltered = [];
    }

    public onTypeFocusOut($event: FocusEvent) {
        // const text = this.genBookingItemForm.get('typeName').value;
        // if (text) {
        //     this.genBookingItemForm.get('typeName').setValue(this.genBookingItem.typeName, {onlySelf: true});
        // }
    }

    public onSelectType(type: any) {
        this.genBookingItem.typeCode = type.code;
        this.genBookingItem.typeName = type.name;
        this.changeValidity.emit(this.genBookingItemForm.valid && this.notesValid);
    }

    private filterTypes(input: string) {
        if (this.genBookingItem.productCode) {
            this.profileTypeSearchCriteria.codeOrName = input;
            this.profileTypeSearchCriteria.resourceType = this.genBookingItem.productCode;
            this.resourceTypeService.getProfileTypes(this.profileTypeSearchCriteria).subscribe(data => {
                this.profileTypesFiltered = ResponseUtil.getDataArray(data);
            });
        } else {
            this.profileTypesFiltered = [];
        }
    }

    public onChangeCheckBox(event: boolean) {
        let alreadyAllocatedPaxTempIds = [];
        if (event && this.sameDateTypeItems && this.sameDateTypeItems.length > 0) {
            this.sameDateTypeItems.forEach(value => alreadyAllocatedPaxTempIds.push(...value.passengerTempIds));
        }
        this.allocatePassengers(alreadyAllocatedPaxTempIds);
    }

    public onStartDateChange() {
        this.genBookingItemForm.get('startDate').value.setSeconds(0);
        if (this.sameEndDateItems && this.sameEndDateItems.length > 0) {
            this.sameDateTypeItems = this.sameEndDateItems.filter(value => new Date(value.startTime).toString() ===
                this.genBookingItemForm.get('startDate').value.toString());
        } else if (this.sameTypeItems && this.sameTypeItems.length > 0) {
            this.sameStartDateItems = this.sameTypeItems.filter(value => new Date(value.startTime).toString() ===
                this.genBookingItemForm.get('startDate').value.toString());
        }
        // this.checkPassengerValidation();
    }

    public onEndDateChange() {
        this.genBookingItemForm.get('endDate').value.setSeconds(0);
        if (this.sameStartDateItems && this.sameStartDateItems.length > 0) {
            this.sameDateTypeItems = this.sameStartDateItems.filter(value => new Date(value.endTime).toString() ===
                this.genBookingItemForm.get('endDate').value.toString());
        } else if (this.sameTypeItems && this.sameTypeItems.length > 0) {
            this.sameEndDateItems = this.sameTypeItems.filter(value => new Date(value.endTime).toString() ===
                this.genBookingItemForm.get('endDate').value.toString());
        }
    }

    private allocatePassengers(alreadyAllocatedPaxTempIds: any[]) {
        let nonAllocatedPassengers = [];
        let adults = [];
        let teens = [];
        let children = [];
        let infants = [];
        if(this.allBookingPassengers && this.allBookingPassengers.length >0) {
            this.allBookingPassengers.forEach(value => {
                if(!alreadyAllocatedPaxTempIds.includes(value.tempId))
                {
                    if(value.type === PassengerType.A && this.bookingItem.adultCount > 0 && adults.length < this.bookingItem.adultCount) {
                        adults.push(value.tempId);
                        nonAllocatedPassengers.push(value.tempId);
                    }
                    else if(value.type === PassengerType.T && this.bookingItem.teenCount > 0 && teens.length < this.bookingItem.teenCount) {
                        teens.push(value.tempId);
                        nonAllocatedPassengers.push(value.tempId);
                    }
                    else if(value.type === PassengerType.C && this.bookingItem.childrenCount > 0 && children.length < this.bookingItem.childrenCount) {
                        children.push(value.tempId);
                        nonAllocatedPassengers.push(value.tempId);
                    }
                    else if(value.type === PassengerType.I && this.bookingItem.infantCount > 0 && infants.length < this.bookingItem.infantCount) {
                        infants.push(value.tempId);
                        nonAllocatedPassengers.push(value.tempId);
                    }
                }
            });
            // this.checkPassengerValidation();
        }
        this.bookingItem.passengerTempIds = nonAllocatedPassengers;
        for (let i = 0; i < this.allBookingPassengers.length; i++){
            const value = this.allBookingPassengers[i];
            if(this.bookingItem.passengerTempIds.includes(value.tempId) && value.type === PassengerType.A) {
                value.lead = true;
                this.bookingItem.leadPassenger = value;
                break;
            }
        }
    }

    private checkPassengerValidation() {
        if(!this.sameDateTypeItems || this.sameDateTypeItems.length === 0) {
            this.bookingItem.passengerTempIds = this.allPassengerIds;
        }
    }

    private getSameDateTypeItems() {
        if (this.sameTypeItems && this.sameTypeItems.length > 0 && this.bookingItem.startTime && this.bookingItem.endTime) {
            this.sameDateTypeItems = this.sameTypeItems.filter(value => new Date(value.endTime).toString() ===
                this.genBookingItemForm.get('endDate').value.toString() && new Date(value.startTime).toString() ===
                this.genBookingItemForm.get('startDate').value.toString());
        }
    }

    public ngOnDestroy(): void {
        if (this.changesSubscription) {
            this.changesSubscription.unsubscribe();
        }
        if (this.defSupChangesSubscription) {
            this.defSupChangesSubscription.unsubscribe();
        }
        if (this.elGrpChangesSubscription) {
            this.elGrpChangesSubscription.unsubscribe();
        }
        if (this.typeChangesSubscription) {
            this.typeChangesSubscription.unsubscribe();
        }
    }

}
