import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LeadPassengerSelectionModule } from '../../../widgets/shared/lead-passenger-selection/lead-passenger-selection.module';
import { LeadPassengerSelectionWrapperComponent } from './lead-passenger-selection-wrapper.component';

@NgModule({
    imports: [
        CommonModule,
        LeadPassengerSelectionModule
    ],
    exports: [LeadPassengerSelectionWrapperComponent],
    declarations: [LeadPassengerSelectionWrapperComponent],
    entryComponents:[LeadPassengerSelectionWrapperComponent]
})
export class LeadPassengerSelectionWrapperModule {}
