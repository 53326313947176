/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./transfer-supplier-search-results.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../widgets/shared/adv-transfer-supplier-search-results/adv-transfer-supplier-search-results.component.ngfactory";
import * as i3 from "../../widgets/shared/adv-transfer-supplier-search-results/adv-transfer-supplier-search-results.component";
import * as i4 from "@tc-core/util/ui/pager.service";
import * as i5 from "@tc-core/util/framework/focus-handler.service";
import * as i6 from "@angular/common";
import * as i7 from "../../../../node_modules/@tc/paginator/paginator.component.ngfactory";
import * as i8 from "@tc/paginator/paginator.component";
import * as i9 from "./transfer-supplier-search-results.component";
import * as i10 from "../../services/util/framework/data-store.service";
import * as i11 from "@tc-core/util/framework/user-journey-manager.service";
import * as i12 from "@tc-core/util/framework/config-loader.service";
import * as i13 from "../../services/backend-consumers/supplier-service/supplier.service";
import * as i14 from "@tc-core/util/ui/spinner.service";
import * as i15 from "@angular/router";
import * as i16 from "@tc-core/util/framework/event-manager.service";
import * as i17 from "@tc-core/util/helpers/common-helper.service";
import * as i18 from "../../services/util/core-services/root.service";
import * as i19 from "../../services/backend-consumers/master-data-handler-service/data-handler.service";
import * as i20 from "../../services/util/framework/dmc-query-params.service";
import * as i21 from "@tc-core/util/ui/auto-scroll.service";
import * as i22 from "../../services/util/common/dmc-common";
import * as i23 from "../../services/util/system/dmc-local-storage.service";
import * as i24 from "../../services/backend-consumers/setups/resource-type.service";
var styles_TransferSupplierSearchResultsComponent = [i0.styles];
var RenderType_TransferSupplierSearchResultsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TransferSupplierSearchResultsComponent, data: {} });
export { RenderType_TransferSupplierSearchResultsComponent as RenderType_TransferSupplierSearchResultsComponent };
function View_TransferSupplierSearchResultsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "tc-adv-transfer-supplier-search-results", [], null, [[null, "supplierSelect"], [null, "selection"], [null, "edit"], [null, "editAsNew"], [null, "cardScroll"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("supplierSelect" === en)) {
        var pd_0 = (_co.onSupplierSelect($event) !== false);
        ad = (pd_0 && ad);
    } if (("selection" === en)) {
        var pd_1 = (_co.onSelection($event) !== false);
        ad = (pd_1 && ad);
    } if (("edit" === en)) {
        var pd_2 = (_co.onSupplierEdit($event) !== false);
        ad = (pd_2 && ad);
    } if (("editAsNew" === en)) {
        var pd_3 = (_co.onEditAsNewSupplier($event) !== false);
        ad = (pd_3 && ad);
    } if (("cardScroll" === en)) {
        var pd_4 = (_co.onCardScroll($event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, i2.View_AdvTransferSupplierSearchResultsComponent_0, i2.RenderType_AdvTransferSupplierSearchResultsComponent)), i1.ɵdid(1, 4964352, null, 0, i3.AdvTransferSupplierSearchResultsComponent, [i4.PagerService, i5.FocusHandlerService, i6.DOCUMENT], { pager: [0, "pager"], clientResultsList: [1, "clientResultsList"], detailSupplier: [2, "detailSupplier"], resourceTypes: [3, "resourceTypes"], footerDataMap: [4, "footerDataMap"], showEditBtn: [5, "showEditBtn"], showEditAsNew: [6, "showEditAsNew"], heading: [7, "heading"], currencyFormat: [8, "currencyFormat"], trsModes: [9, "trsModes"], vehicleModels: [10, "vehicleModels"], costingTypes: [11, "costingTypes"], profileTypes: [12, "profileTypes"] }, { supplierSelect: "supplierSelect", edit: "edit", editAsNew: "editAsNew", selection: "selection", cardScroll: "cardScroll" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.pager; var currVal_1 = _co.contentCardDataList; var currVal_2 = _co.detailSupplier; var currVal_3 = _co.resourceTypes; var currVal_4 = _co.footerDataMap; var currVal_5 = _co.showEditBtn; var currVal_6 = _co.showEditAsNew; var currVal_7 = _co.heading; var currVal_8 = _co.currencyFormat; var currVal_9 = _co.transferModes; var currVal_10 = _co.vehicleModels; var currVal_11 = _co.costingTypeList; var currVal_12 = _co.profileTypes; _ck(_v, 1, 1, [currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12]); }, null); }
function View_TransferSupplierSearchResultsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "tc-paginator", [], null, [[null, "page"], [null, "more"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("page" === en)) {
        var pd_0 = (_co.onPage($event) !== false);
        ad = (pd_0 && ad);
    } if (("more" === en)) {
        var pd_1 = (_co.onMore() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i7.View_PaginatorComponent_0, i7.RenderType_PaginatorComponent)), i1.ɵdid(1, 638976, null, 0, i8.PaginatorComponent, [], { length: [0, "length"], index: [1, "index"], size: [2, "size"], hasMore: [3, "hasMore"] }, { page: "page", more: "more" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.contentCardDataList.length; var currVal_1 = _co.pager.currPage; var currVal_2 = _co.pager.itemsPerPage; var currVal_3 = _co.paginatorHasMore; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_TransferSupplierSearchResultsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_TransferSupplierSearchResultsComponent_1)), i1.ɵdid(1, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TransferSupplierSearchResultsComponent_2)), i1.ɵdid(3, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.supplierResultList && (_co.supplierResultList.length > 0)); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.contentCardDataList.length > _co.defaultPageResultCount); _ck(_v, 3, 0, currVal_1); }, null); }
export function View_TransferSupplierSearchResultsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "tc-transfer-supplier-search-results", [], null, null, null, View_TransferSupplierSearchResultsComponent_0, RenderType_TransferSupplierSearchResultsComponent)), i1.ɵdid(1, 245760, null, 0, i9.TransferSupplierSearchResultsComponent, [i10.DataStoreService, i11.UserJourneyManager, i12.ConfigLoader, i13.SupplierService, i14.SpinnerService, i1.Injector, i15.ActivatedRoute, i16.EventManager, i17.CommonHelper, i18.RootService, i19.DataHandlerService, i20.DMCQueryParamsService, i21.AutoScrollService, i22.DMCCommon, i23.DMCLocalStorageService, i24.ResourceTypeService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TransferSupplierSearchResultsComponentNgFactory = i1.ɵccf("tc-transfer-supplier-search-results", i9.TransferSupplierSearchResultsComponent, View_TransferSupplierSearchResultsComponent_Host_0, {}, {}, []);
export { TransferSupplierSearchResultsComponentNgFactory as TransferSupplierSearchResultsComponentNgFactory };
