<div class="header-panel-section tc-px-3 tc-py-1">
    <div class="item-header tc-my-2 tc-py-3 tc-pl-3 tc-pr-2">
        <div class="item-header__content">
            {{
            supplierAcceptanceResults.length < 1 ? '--' : setupResultHeader(supplierAcceptanceResults.length) | translate
            }}
        </div>
        <div class="tc-icon-block action-icon">
            <button
                    mat-icon-button
                    aria-label="Delete"
                    (click)="onRestoreColDefClick()"
                    matTooltip="{{'Select All Columns' | translate}}"
                    [disabled]="">
                <i class="material-icons">select_all</i>
            </button>
            <button
                    matTooltip="{{'Show/Hide Columns' | translate}}"
                    mat-icon-button
                    [matMenuTriggerFor]="beforeMenu"
                    [disabled]="">
                <i class="material-icons">view_column</i>
            </button>
            <mat-menu #beforeMenu="matMenu" xPosition="before">
                <mat-selection-list
                    #colSelector
                    style="max-height: 400px;"
                    (selectionChange)=onSelectionChangeColSelector($event)>
                    <ng-container *ngFor="let coldef of columnDefsInput">
                        <ng-container *ngIf="!coldef.hideInColSelector">
                            <mat-list-option
                                    [value]="coldef.field"
                                    [selected]="!coldef.hide"
                                    (click)="onClickColOption($event)">
                                {{coldef.headerName | translate}}
                            </mat-list-option>
                        </ng-container>
                    </ng-container>
                </mat-selection-list>
            </mat-menu>
        </div>
    </div>
</div>

<ag-grid-angular
    #agGrid
    id="supplierAcceptanceGrid"
    style="height: calc(100vh - 182px)"
    class="ag-theme-material tc-ag-grid tc-ag-grid--full-width tc-ag-grid--auto-height tc-ag-grid--height-full tc-border-left-0 tc-border-right-0"
    [columnDefs]="columnDefsInput"
    [defaultColDef]="defaultColDef"
    [pagination]="true"
    [paginationAutoPageSize]="true"
    [enableColResize]="true"
    [suppressRowTransform]=true
    (cellValueChanged)="editCellValue($event)"
    (gridSizeChanged)="onGridSizeChange($event)"
    (gridReady)="onGridReady($event)">
</ag-grid-angular>

<ng-template #approvalStatusCell let-row>
    <div
            class="tc-ag-grid-chip"
            *ngIf="row && row.approvalStatus"
            [ngClass]="{'tc-ag-grid-chip--warning': ( row?.approvalStatus === PENDING ),
                                            'tc-ag-grid-chip--success': ( row?.approvalStatus === APPROVED ),
                                            'tc-ag-grid-chip--danger': ( row?.approvalStatus === REJECTED )
                                            }">
        <span>{{row?.approvalStatus}}</span>
    </div>
</ng-template>

<!--supplier code column view-->
<ng-template #supplierCodeCell let-row>
    <span> {{row?.supplierCode || EMPTY_STRING}}</span>
</ng-template>

<!--supplier name column view-->
<ng-template #supplierNameCell let-row>
    <span> {{row?.supplierName || EMPTY_STRING}}</span>
</ng-template>

<!--action column view-->
<ng-template #actionCell let-row>
    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Go to option">
        <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="onClickRoles(row)">
            <mat-icon>admin_panel_settings</mat-icon>
            <span>{{'Roles' | translate}}</span>
        </button>
        <button mat-menu-item [disabled]="isApproveSupplierRestricted(row)" (click)="onApprove(row)">
            <mat-icon>thumb_up_alt</mat-icon>
            <span>{{'Accept User' | translate}}</span>
        </button>
        <button mat-menu-item [disabled]="isRejectSupplierRestricted(row)" (click)="onReject(row)">
            <mat-icon>thumb_down_alt</mat-icon>
            <span>{{'Reject User' | translate}}</span>
        </button>
        <button mat-menu-item [disabled]="isReviewSupplierRestricted(row)" (click)="openSupplierProfile(row)">
            <mat-icon>rate_review</mat-icon>
            <span>{{'Review Supplier' | translate}}</span>
        </button>
    </mat-menu>
</ng-template>

