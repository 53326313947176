import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCheckboxModule } from '@angular/material';
import { AddMoreLangDialogComponent } from './add-more-lang-dialog.component';

@NgModule({
    imports: [
        CommonModule,
        MatCheckboxModule
    ],
    declarations: [AddMoreLangDialogComponent],
    exports: [AddMoreLangDialogComponent],
    entryComponents: [AddMoreLangDialogComponent]
})
export class AddMoreLangDialogModule {}
