import { NgModule } from '@angular/core';

import { LaunchPadPageComponent } from './launch-pad.component';
import { CommonModule } from '@angular/common';
import { LaunchPadModule } from '@tc/launch-pad';

@NgModule({
  imports: [
    CommonModule,
    LaunchPadModule
  ],
  exports: [
    LaunchPadPageComponent
  ],
  declarations: [
    LaunchPadPageComponent
  ],
  providers: [],
})
export class LaunchPadPageModule {}
