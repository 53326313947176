import { Injectable } from '@angular/core';
import { ConfigLoader } from '@tc-core/util/framework';
import { Observable } from 'rxjs';
import { CurrencySearchCriteria } from '../../../models/criteria/currency-search-criteria';
import { DMCQueryParamsService } from '../../util/framework/dmc-query-params.service';
import { RequestService } from '../../util/framework/request.service';
import { UrlPaths } from '../url-paths';
import {OPResponseWrapper} from "../../../models/response/op-response-wrapper";

@Injectable({
    providedIn: 'root'
})
export class DivisionSetupService {
    constructor(
        private configLoader: ConfigLoader,
        private requestService: RequestService,
        private queryParamsService: DMCQueryParamsService
    ) {
    }

    getRows = (criteria: any): Observable<OPResponseWrapper<any>> => {
        const reqPrams = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.get(
            UrlPaths.OP_USER_PROFILE_URL_KEY,
            [UrlPaths.OP_USER_PROFILE_VERSION, UrlPaths.DIVISIONS, UrlPaths.CODE_NAME],
            reqPrams
        );
    };

    public deleteRow(code: string): Observable<any> {
        return this.requestService.delete(
            UrlPaths.OP_USER_PROFILE_VERSION,
            [ UrlPaths.DIVISIONS, code]
        );
    }

    public saveRow(row: any): Observable<OPResponseWrapper<any>> {
        return this.requestService.post(
            UrlPaths.OP_USER_PROFILE_URL_KEY,
            [UrlPaths.OP_USER_PROFILE_VERSION, UrlPaths.DIVISIONS],
            row
        );
    }

    public updateRow(code: string, row: any): Observable<any> {
        return this.requestService.put(
            UrlPaths.OP_USER_PROFILE_VERSION,
            [UrlPaths.OP_USER_PROFILE_URL_KEY, UrlPaths.DIVISIONS, code],
            row
        );
    }

}
