import * as tslib_1 from "tslib";
import { DispatchStatus } from '../document/dispatch-entry';
import { MainType } from '../reservation-v2/main-type';
import { KeyControlAndAbstractSearchCriteria } from './key-control-and-abstract-search-criteria';
var DocumentQueueSearchCriteria = /** @class */ (function (_super) {
    tslib_1.__extends(DocumentQueueSearchCriteria, _super);
    function DocumentQueueSearchCriteria() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.startDate = '';
        _this.endDate = '';
        _this.dispatchStartDate = new Date().toISOString().split('T')[0];
        _this.dispatchEndDate = new Date(+new Date() + 172800000).toISOString().split('T')[0];
        _this.supplierCode = '';
        _this.supplierName = '';
        _this.dispatchStatus = DispatchStatus.FAILED;
        _this.bookingSourceItemNo = null;
        _this.mainType = MainType.TRS;
        _this.backgroundTaskId = null;
        _this.size = 14;
        return _this;
    }
    return DocumentQueueSearchCriteria;
}(KeyControlAndAbstractSearchCriteria));
export { DocumentQueueSearchCriteria };
