import { GenResourceCost } from '../supplier/gen-resource-cost';
import { TrsResourceCost } from '../supplier/trs-resource-cost';
import { AssignStatus, ServiceLevel } from './assignment';
import { AssignmentNote } from './assignment-note';
import { BookingPaxMapping } from './booking-pax-mapping';
import { PaxCost } from './pax-cost';
import { ResourceAllocationSummary } from './resource-allocation-summary';
import { SupplierAllocationSummary } from './supplier-allocation-summary';

export class AssignmentRawData {
    /* ids */
    assignmentId: number;
    temporaryAssignmentId: number;
    parentAssignmentId: number;
    temporaryParentAssignmentId: number;

    /* booking specific data in job */
    plCode: string;
    plName: string;

    /* supplier data */
    supplierId: number;
    supplierName: string;
    supplierCode: string;

    /* resource data */
    resourceId: number;
    resourceName: string;

    /* vehicle data */
    route: string;

    // applied cost line
    genResourceCost: GenResourceCost;
    trsResourceCost: TrsResourceCost;
    resourceType: string;
    profileType: string;

    /* job data */
    assignStatus: AssignStatus;
    serviceLevel: ServiceLevel;
    sharedAssign: boolean;
    jobDate: Date;
    startTime: Date;
    startLocationGroup: string;
    waitingEndTime: Date;
    endTime: Date;
    endLocationGroup: string;
    estimatedCost: number;
    jobCost: number;
    costingType: any;
    noOfUnits: any;
    unitFare: any;

    /* read friendly data */
    adultCount: number;
    teenCount: number;
    childCount: number;
    infantCount: number;

    /* logging */
    modifiedOn: Date;
    assignedDate: Date;

    /* relationships */
    assignmentNotes: AssignmentNote[];
    bookingPaxMappings: BookingPaxMapping[];

    /* Not contain in BE DTO */
    // for front end processing
    removingAssignment = false; // to reduce constraints on saving assignments

    unitIndex: number;
    widthNoOfUnits: number;
    widthMargin: number;
    selected: boolean;
    recommended: boolean;
    updated: boolean;
    tobeSelected: boolean;
    startTimeObj: Date;
    endTimeObj: Date;
    waitingEndTimeObj: Date;

    supplierObj: SupplierAllocationSummary;
    resourceObj: ResourceAllocationSummary;

    rejectedResourceIdList: number[];
    rejectedSupplierIdList: number[];

    currency: string;
    distanceInKm: number;
    durationInHours: number;
    startTimeStr: string;
    endTimeStr: string;

    calculatedCost: PaxCost;
}
