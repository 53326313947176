import {Component, Input, OnInit} from '@angular/core';
import {Booking} from '../../../../../models/reservation-v2/booking';
import {BookingItem} from '../../../../../models/reservation-v2/booking-item';
import {DataKey, DataStoreService} from '../../../../../services/util/framework/data-store.service';
import {CheckBoxRendererEditorComponent} from '../../../../../widgets/framework/ag-grid-custom-components/renderers/check-box-selector-renderer/check-box-renderer-editor.component';
import {GridApi, ICellRendererParams} from 'ag-grid-community';
import {TranslateService} from '@ngx-translate/core';
import {MatDialog} from '@angular/material';

@Component({
    selector: 'tc-passenger-selection-detail-view',
    templateUrl: './passenger-selection-detail-view.component.html'
})
export class PassengerSelectionDetailViewComponent implements OnInit {

    @Input() booking: Booking;
    @Input() bookingItem: BookingItem;
    @Input() readonly = false;
    @Input() onChangeFn: any;

    @Input() output = {
        changed: undefined,
        valid: undefined
    };

    disableClose = false;
    disableCloseMsg = 'Please select the lead passenger';

    public overlayNoRowsTemplate: any = `<div class="tc-ag-grid-empty">
            <i class="material-icons tc-ag-grid-empty__icon">assignment_error</i>
            <span class="tc-ag-grid-empty__text">No Records Available</span>
        </div>`;


    gridApi: GridApi;
    defaultColDef = {
        headerValueGetter: (parameters: ICellRendererParams): string => {
            const headerIdentifier = parameters.colDef.headerName;
            if (headerIdentifier) {
                return this.translateService.instant(headerIdentifier);
            }
            return '';
        }
    };
    columnDefs;
    frameworkComponents = {
        checkBoxRendererEditor: CheckBoxRendererEditorComponent
    };

    constructor(
        private translateService: TranslateService,
        private dataStore: DataStoreService,
        private matDialog: MatDialog
    ) {
    }

    ngOnInit() {
        this.setColDefs();
    }

    setColDefs() {
        this.columnDefs = [
            {
                headerName: 'Title',
                field: 'title',
                cellClass: [
                    "tc-ag-grid-cell--read-only"
                ],
            },
            {
                headerName: 'First Name',
                field: 'firstName',
                cellClass: [
                    "tc-ag-grid-cell--read-only"
                ],
            },
            {
                headerName: 'Middle Name',
                field: 'middleName',
                hide: true,
                cellClass: [
                    "tc-ag-grid-cell--read-only"
                ],
            },
            {
                headerName: 'Last Name',
                field: 'lastName',
                cellClass: [
                    "tc-ag-grid-cell--read-only"
                ],
            },
            {
                headerName: 'Type',
                field: 'typeName',
                cellClass: [
                    "tc-ag-grid-cell--read-only"
                ],
            },
            {
                headerName: 'Lead',
                field: 'lead',
                cellRendererFramework: this.frameworkComponents.checkBoxRendererEditor,
                editInRenderer: this.readonly,
                cellClass: [
                    "tc-ag-grid-cell--read-only"
                ],
            }
        ];
    }

    public onClose(event: MouseEvent) {
        // this.dataStore.set(DataKey.popupClose, this.output);
        this.matDialog.closeAll();
    }

    public onGridReady(params: any) {
        this.gridApi = params.api;
        this.gridApi.sizeColumnsToFit();
    }

}
