import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderModule } from '@tc/loader';
import { MatSnackBarModule } from '@angular/material';
import { SnackBarModule } from '@tc/snack-bar';
import { DMCSpinnerWrapperComponent } from './dmc-spinner-wrapper.component';

@NgModule({
  imports: [
    CommonModule, LoaderModule, MatSnackBarModule, SnackBarModule
  ],
  exports: [
      DMCSpinnerWrapperComponent
  ],
  declarations: [
    DMCSpinnerWrapperComponent
  ],
  providers: []
})
export class DMCSpinnerWrapperModule {}
