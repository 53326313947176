import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule, MatExpansionModule, MatIconModule, MatTooltipModule } from '@angular/material';
import { TranslateModule } from '@ngx-translate/core';
import { DirectivesModule } from '@tc/directives';
import { PipesModule } from '@tc/pipes';
import { SecurityModule } from '@tc/security';
import { SingleLineStripModule } from '@tc/single-line-strip';
import { SummaryStripModule } from '@tc/summary-strip';
import { ExpandedContentHeaderCardModule } from '../expanded-content-secondary-header-card/expanded-content-header-card.module';
import { ServiceItemNotesComponent } from './service-item-notes.component';

@NgModule({
  imports: [
    CommonModule,
      MatExpansionModule,
      SingleLineStripModule,
      SummaryStripModule,
      MatButtonModule,
      MatIconModule,
      MatTooltipModule,
      ExpandedContentHeaderCardModule,
      PipesModule,
      DirectivesModule,
      SecurityModule,
      TranslateModule.forChild()
  ],
  declarations: [ServiceItemNotesComponent],
    exports: [ServiceItemNotesComponent]
})
export class ServiceItemNotesModule { }
