/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../node_modules/@tc/paginator/paginator.component.ngfactory";
import * as i2 from "@tc/paginator/paginator.component";
import * as i3 from "../../generic-search-criteria/generic-search-criteria.component.ngfactory";
import * as i4 from "../../../services/helpers/chip-handler.service";
import * as i5 from "@tc/chip-input/chip-input.service";
import * as i6 from "@tc/chip-input/shared/parser.service";
import * as i7 from "@tc-core/util/system/date-formatter.service";
import * as i8 from "../../../services/util/framework/data-store.service";
import * as i9 from "@tc-core/util/framework/config-loader.service";
import * as i10 from "@tc-core/util/helpers/common-helper.service";
import * as i11 from "../../../services/backend-consumers/master-data-handler-service/data-handler.service";
import * as i12 from "../../generic-search-criteria/generic-search-criteria.component";
import * as i13 from "../../../services/util/framework/action-handler-service.service";
import * as i14 from "@tc-core/util/framework/user-journey-manager.service";
import * as i15 from "../../../services/backend-consumers/supplier-service/generic-resource.service";
import * as i16 from "@tc-core/util/framework/event-manager.service";
import * as i17 from "../../../services/util/framework/dmc-query-params.service";
import * as i18 from "@tc-core/util/ui/spinner.service";
import * as i19 from "../../../services/util/core-services/root.service";
import * as i20 from "../../../services/user-management/user-management.service";
import * as i21 from "../../../widgets/shared/result-header/result-header.component.ngfactory";
import * as i22 from "../../../widgets/shared/result-header/result-header.component";
import * as i23 from "../../../widgets/shared/adv-resource-assign-search-result/adv-resource-assign-search-result.component.ngfactory";
import * as i24 from "../../../widgets/shared/adv-resource-assign-search-result/adv-resource-assign-search-result.component";
import * as i25 from "../../../services/util/change-detector/change-detector.service";
import * as i26 from "@angular/common";
import * as i27 from "./resource-assign-panel.component";
import * as i28 from "@tc-core/service/sorters/sorter.service";
var styles_ResourceAssignPanelComponent = [];
var RenderType_ResourceAssignPanelComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ResourceAssignPanelComponent, data: {} });
export { RenderType_ResourceAssignPanelComponent as RenderType_ResourceAssignPanelComponent };
function View_ResourceAssignPanelComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-paginator", [], null, [[null, "page"], [null, "more"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("page" === en)) {
        var pd_0 = (_co.onPage($event) !== false);
        ad = (pd_0 && ad);
    } if (("more" === en)) {
        var pd_1 = (_co.onMore() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_PaginatorComponent_0, i1.RenderType_PaginatorComponent)), i0.ɵdid(1, 638976, null, 0, i2.PaginatorComponent, [], { length: [0, "length"], index: [1, "index"], size: [2, "size"], hasMore: [3, "hasMore"] }, { page: "page", more: "more" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.contentCardDataList.length; var currVal_1 = _co.pager.currPage; var currVal_2 = _co.pager.itemsPerPage; var currVal_3 = _co.hasMore; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_ResourceAssignPanelComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 15, "div", [["class", "focus-view__content-wrapper"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 6, "div", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 5, "div", [["class", "assign-panel-search"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 4, "tc-generic-search-criteria", [["]", "resourceSearchCriteria"]], null, null, null, i3.View_GenericSearchCriteriaComponent_0, i3.RenderType_GenericSearchCriteriaComponent)), i0.ɵprd(6144, null, "ChipHandlerService", null, [i4.ChipHandlerService]), i0.ɵprd(4608, null, i5.ChipInputService, i5.ChipInputService, [i6.Parser, i7.DateFormatter]), i0.ɵprd(512, null, i4.ChipHandlerService, i4.ChipHandlerService, [i0.Injector, i8.DataStoreService, i9.ConfigLoader, i10.CommonHelper, i7.DateFormatter, i11.DataHandlerService]), i0.ɵdid(7, 245760, null, 0, i12.GenericSearchCriteriaComponent, [i9.ConfigLoader, i8.DataStoreService, i13.ActionHandlerService, i14.UserJourneyManager, i15.GenericResourceService, i16.EventManager, i4.ChipHandlerService, i17.DMCQueryParamsService, i10.CommonHelper, i18.SpinnerService, i19.RootService, i7.DateFormatter, i20.UserManagementService], { searchType: [0, "searchType"], genericTypePathname: [1, "genericTypePathname"] }, null), (_l()(), i0.ɵeld(8, 0, null, null, 2, "div", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 1, "tc-result-header", [], null, [[null, "selectSorter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectSorter" === en)) {
        var pd_0 = (_co.onSort($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i21.View_ResultHeaderComponent_0, i21.RenderType_ResultHeaderComponent)), i0.ɵdid(10, 114688, null, 0, i22.ResultHeaderComponent, [], { transferModeList: [0, "transferModeList"], heading: [1, "heading"], groupingOptionList: [2, "groupingOptionList"], sorters: [3, "sorters"] }, { selectSorter: "selectSorter" }), (_l()(), i0.ɵeld(11, 0, null, null, 2, "div", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 1, "tc-adv-resource-assign-search-result", [], null, [[null, "driverSelect"], [null, "resourceCalendarClick"], [null, "targetClick"], [null, "eventClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("driverSelect" === en)) {
        var pd_0 = (_co.onDriverSelect($event) !== false);
        ad = (pd_0 && ad);
    } if (("resourceCalendarClick" === en)) {
        var pd_1 = (_co.onResourceCalendarClick($event) !== false);
        ad = (pd_1 && ad);
    } if (("targetClick" === en)) {
        var pd_2 = (_co.onTargetClick($event) !== false);
        ad = (pd_2 && ad);
    } if (("eventClick" === en)) {
        var pd_3 = (_co.onEventClick($event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, i23.View_AdvResourceAssignSearchResultComponent_0, i23.RenderType_AdvResourceAssignSearchResultComponent)), i0.ɵdid(13, 114688, null, 0, i24.AdvResourceAssignSearchResultComponent, [i25.ChangeDetectorService], { sorter: [0, "sorter"], sortingHandler: [1, "sortingHandler"], sorters: [2, "sorters"], pager: [3, "pager"], resultsList: [4, "resultsList"], selectedDriver: [5, "selectedDriver"], bookingData: [6, "bookingData"], bookingDataArray: [7, "bookingDataArray"], isBulkAssignment: [8, "isBulkAssignment"], assignedDriver: [9, "assignedDriver"] }, { driverSelect: "driverSelect", resourceCalendarClick: "resourceCalendarClick", eventClick: "eventClick", targetClick: "targetClick" }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ResourceAssignPanelComponent_1)), i0.ɵdid(15, 16384, null, 0, i26.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co._searchType; var currVal_1 = ((_co.genTourItem == null) ? null : _co.genTourItem.categoryCode); _ck(_v, 7, 0, currVal_0, currVal_1); var currVal_2 = i0.ɵEMPTY_ARRAY; var currVal_3 = _co.heading; var currVal_4 = i0.ɵEMPTY_ARRAY; var currVal_5 = _co.sorters_; _ck(_v, 10, 0, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_6 = _co.sorterService.sorter; var currVal_7 = _co.sorterService.method; var currVal_8 = _co.sorters_; var currVal_9 = _co.pager; var currVal_10 = _co.contentCardDataList; var currVal_11 = _co.selectedSummaryCardData; var currVal_12 = _co.genTourItem; var currVal_13 = _co.bookingDataArray; var currVal_14 = _co.isBulkAssignment; var currVal_15 = _co.selectedResource; _ck(_v, 13, 0, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14, currVal_15); var currVal_16 = (_co.contentCardDataList.length > _co.defaultPageResultCount); _ck(_v, 15, 0, currVal_16); }, null); }
export function View_ResourceAssignPanelComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-resource-assign-panel", [], null, null, null, View_ResourceAssignPanelComponent_0, RenderType_ResourceAssignPanelComponent)), i0.ɵdid(1, 114688, null, 0, i27.ResourceAssignPanelComponent, [i28.SorterService, i9.ConfigLoader], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ResourceAssignPanelComponentNgFactory = i0.ɵccf("tc-resource-assign-panel", i27.ResourceAssignPanelComponent, View_ResourceAssignPanelComponent_Host_0, { isDataLoaded: "isDataLoaded", isTriggerSearch: "isTriggerSearch", isFocus: "isFocus", isSearchBarStyleNotAvailable: "isSearchBarStyleNotAvailable", placeHolderText: "placeHolderText", selectedResource: "selectedResource", genTourItem: "genTourItem", tAssignedResources: "tAssignedResources", contentCardDataList: "contentCardDataList", selectedSummaryCardData: "selectedSummaryCardData", bookingDataArray: "bookingDataArray", isBulkAssignment: "isBulkAssignment", heading: "heading", supplierIdList: "supplierIdList", hasMore: "hasMore", fetchedResourceCount: "fetchedResourceCount", dataFromMoreClick: "dataFromMoreClick", pager: "pager" }, { resourceSelect: "resourceSelect", onPaginationMoreClick: "onPaginationMoreClick", resourceCalendarClick: "resourceCalendarClick", eventClick: "eventClick", targetClick: "targetClick" }, []);
export { ResourceAssignPanelComponentNgFactory as ResourceAssignPanelComponentNgFactory };
