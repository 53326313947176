import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ServiceInformation } from '../../../models/supplier/service-information';
import { RegXPatterns } from '../regx-patterns/regx-patterns';
import {UtilService} from '../../../services/util/common/util.service';

@Component({
    selector: 'tc-service-information',
    templateUrl: './service-information.component.html'
})
export class ServiceInformationComponent implements OnInit {

    emailRegex = '^[a-zA-Z0-9!#$%&\'*+\\/=?^_`{|}~.-]+@[a-zA-Z0-9]([a-zA-Z0-9-]*[a-zA-Z0-9])?(\\.[a-zA-Z0-9]([a-zA-Z0-9-]*[a-zA-Z0-9])?)+$';
    telephoneRegex = '^[\\+]?[(]?[0-9]{3}[)]?[-\\s\\.]?[0-9]{3}[-\\s\\.]?[0-9]{4,9}$';

    serviceInformationForm: FormGroup;

    @Output() changeFormValidity: EventEmitter<boolean> = new EventEmitter();

    @Input() serviceInformationModel: ServiceInformation;

    constructor(
        private fb: FormBuilder,
        private utilService: UtilService
    ) { }

    ngOnInit() {

        this.createForm();
        this.serviceInformationForm.valueChanges.subscribe(
            (data) => {
                if (JSON.stringify(data) !== JSON.stringify({})) {
                    this.serviceInformationModel.serviceCompanyName = data.serCmpName;
                    this.serviceInformationModel.companyContactNum = data.contactNumber;
                    this.serviceInformationModel.altContactNum = data.alternateContactNumber;
                    this.serviceInformationModel.email = data.email;
                    this.serviceInformationModel.lastServiceDate = this.utilService.setTimezoneAsGMTWithoutConverting(data.lastServiceDate);
                    this.serviceInformationModel.nextServiceDate = this.utilService.setTimezoneAsGMTWithoutConverting(data.nextServiceDate);
                    this.serviceInformationModel.serviceNote = data.serviceNote;

                    this.changeFormValidity.emit(this.serviceInformationForm.valid);

                }

            }
        );
        this.validateForm();
    }

    public validateForm() {
        Object.keys(this.serviceInformationForm.controls).forEach(field => {
            const control = this.serviceInformationForm.get(field);
            control.markAsTouched({onlySelf: true});
        });
    }

    public getErrorMessageOfControl(group: FormGroup, field: string): string {
        if (field === 'Email') {
            return group.get('email').hasError('pattern') ?
                field + ' invalid' : '';
        } else if (field === 'Service Company Contact Number') {
            return group.get('contactNumber').hasError('pattern') ?
                field + ' invalid' : '';
        } else if (field === 'Alternate Contact Number') {
            return group.get('alternateContactNumber').hasError('pattern') ?
                field + ' invalid' : '';
        }

    }

    private createForm() {
        this.serviceInformationForm = this.fb.group({
            serCmpName: [this.serviceInformationModel ? this.serviceInformationModel.serviceCompanyName : ''],
            lastServiceDate: [this.serviceInformationModel ? this.serviceInformationModel.lastServiceDate : ''],
            nextServiceDate: [this.serviceInformationModel ? this.serviceInformationModel.nextServiceDate : ''],
            serviceNote: [this.serviceInformationModel ? this.serviceInformationModel.serviceNote : ''],
            email: [this.serviceInformationModel ? this.serviceInformationModel.email : '', [Validators.pattern(RegXPatterns.EMAIL)]],
            contactNumber: [
                this.serviceInformationModel ? this.serviceInformationModel.companyContactNum : '',
                [Validators.pattern(RegXPatterns.TELEPHONE)]
            ],
            alternateContactNumber: [
                this.serviceInformationModel ? this.serviceInformationModel.altContactNum : '',
                [Validators.pattern(RegXPatterns.TELEPHONE)]
            ]
        });
    }
}
