<div class="container-fluid tc-gwacs-card">
  <div class="row tc-gwacs-card__row">
    <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 tc-gwacs-card-col-1">
      <div class="tc-gwacs-card-col-1__circle">
        <tc-resource
                *ngIf="documentContent?.icon?.value"
                id="{{documentContent?.icon?.key}}"
                [key]="documentContent?.icon?.value?.key"
                [value]="documentContent?.icon?.value?.value"
                [style]="documentContent?.icon?.value?.style"
                [iconType]="documentContent?.icon?.value?.iconType">
        </tc-resource>
      </div>
      <div
              *ngIf="selectedDocument" class="tc-gwacs-card-col-1__content"
              [ngClass]="isHeader? 'additional-contact--header' : ''">
        {{getDocumentName()}}
      </div>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 tc-gwacs-card-col-2">
      <div
              class="tc-gwacs-card-col-2__content"
              *ngIf="selectedDocument && !isHeader">{{getContactCategory(selectedDocument.contactCategory)}}
      </div>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 tc-gwacs-card-col-2">
    </div>
    <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 tc-gwacs-card-col-4">
      <div class="tc-icon-block">
        <span class="tc-icon-block__icon tc-icon-block__icon--active">
          <i
                  class="material-icons" *ngIf="isEditable && !showIcons"
                  (click)="onDelete($event)">delete</i>
        </span>
      </div>
    </div>
  </div>
</div>
