import { Component, Input, OnInit } from '@angular/core';
import { Remark } from '../../../models/common/remark';

@Component({
  selector: 'tc-note-info-card',
  templateUrl: './note-info-card.component.html'
})
export class NoteInfoCardComponent implements OnInit {

  @Input() noteList: Remark[] = [];
  @Input() showTitle: boolean = true;

  constructor() { }

  ngOnInit() {
  }

}
