<!--don't change the class and dont move (scroll)="onScrollYears()" -->
<div class="tc-sc__cal-panel" (scroll)="onScrollYears()">
  <tc-body-view-year
    [calendarNavData]="calendarNavData"
    [yearsArray]="yearsArray"
    [rangeGroups]="rangeGroups"
    [calendarRefreshEvent]="calendarRefreshEvent"
    (selectDate)="onSelectDate($event)">
  </tc-body-view-year>
</div>

