import { AfterViewChecked, Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ChipSelectorComponent } from '@tc/chip-selector';
import { Chip } from '@tc/chip-selector/chip.model';

@Component({
  selector: 'tc-manage-notes',
  templateUrl: 'manage-notes.component.html'
})
export class ManageNotesComponent implements OnInit, AfterViewChecked {

  @Input() isMultiSelect = true;

  public noteFormGroup: FormGroup;
  public selectedNotes: Chip[] = [];

  @ViewChild('_chip_selector') private chip_selector: ChipSelectorComponent;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    public dialogRef: MatDialogRef<ManageNotesComponent>
  ) { }

  ngOnInit() {

    this.noteFormGroup = new FormGroup({
      noteType: new FormControl('', Validators.required),
      noteText: new FormControl('', Validators.required)
    });
  }

  // load the chip value for edit action
  ngAfterViewChecked() {
    if (this.data.selectedNoteType !== null && this.chip_selector.chips.length > 0 &&
      this.data.title === 'Edit Notes') {
      this.chip_selector.chips.forEach(chip => {
        if (chip.value.code === this.data.selectedNoteType) {
          this.chip_selector.writeValue([chip]);
          // calling propagate change to validate form
          this.chip_selector.propagateChange(chip);
          this.chip_selector.isEditable = false;
          this.data.selectedNoteTypes = [chip];
        }
      });
    }
  }
    multiSelect(){
        if(this.data.isMultiSelect !== undefined || this.data.isMultiSelect !== null){
            return this.data.isMultiSelect;
        }
        return this.isMultiSelect;
    }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onModelChange(chips) {
    if (chips && chips.length > 0) {
      this.data.selectedNoteTypes = chips;
    }
  }
}
