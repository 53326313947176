/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./operator-acceptance-search-criteria.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@tc/chip-input/chip-input.component.ngfactory";
import * as i3 from "@tc/chip-input/chip-input.service";
import * as i4 from "@tc/chip-input/shared/parser.service";
import * as i5 from "@tc-core/util/system/date-formatter.service";
import * as i6 from "@tc/chip-input/chip-input.component";
import * as i7 from "@tc-core/util/framework/focus-handler.service";
import * as i8 from "@tc-core/service/sorters/sorter.service";
import * as i9 from "../../services/helpers/chip-handler.service";
import * as i10 from "../../services/util/framework/data-store.service";
import * as i11 from "@tc-core/util/framework/config-loader.service";
import * as i12 from "@tc-core/util/helpers/common-helper.service";
import * as i13 from "../../services/backend-consumers/master-data-handler-service/data-handler.service";
import * as i14 from "./operator-acceptance-search-criteria.component";
import * as i15 from "../../services/util/framework/action-handler-service.service";
import * as i16 from "@tc-core/util/framework/user-journey-manager.service";
import * as i17 from "@tc-core/util/framework/event-manager.service";
import * as i18 from "../../services/util/framework/dmc-query-params.service";
import * as i19 from "@tc-core/util/ui/spinner.service";
import * as i20 from "../../services/backend-consumers/user-service/user-profile.service";
import * as i21 from "../../services/util/core-services/root.service";
import * as i22 from "../../services/user-management/user-management.service";
var styles_OperatorAcceptanceSearchCriteriaComponent = [i0.styles];
var RenderType_OperatorAcceptanceSearchCriteriaComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_OperatorAcceptanceSearchCriteriaComponent, data: {} });
export { RenderType_OperatorAcceptanceSearchCriteriaComponent as RenderType_OperatorAcceptanceSearchCriteriaComponent };
export function View_OperatorAcceptanceSearchCriteriaComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { searchChipInput: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 2, "tc-chip-input", [], null, [[null, "searchEmitter"], [null, "onfocus"], [null, "cancelEmitter"], [null, "chipListEmitter"], ["document", "click"], [null, "focusin"], [null, "focusout"], [null, "keyup"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("document:click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).handleDocumentClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("focusin" === en)) {
        var pd_1 = (i1.ɵnov(_v, 3).onFocusIn($event) !== false);
        ad = (pd_1 && ad);
    } if (("focusout" === en)) {
        var pd_2 = (i1.ɵnov(_v, 3).onFocusOut($event) !== false);
        ad = (pd_2 && ad);
    } if (("keyup" === en)) {
        var pd_3 = (i1.ɵnov(_v, 3).onTabNavigation($event) !== false);
        ad = (pd_3 && ad);
    } if (("searchEmitter" === en)) {
        var pd_4 = (_co.onSearch($event) !== false);
        ad = (pd_4 && ad);
    } if (("onfocus" === en)) {
        var pd_5 = (_co.onFocus($event) !== false);
        ad = (pd_5 && ad);
    } if (("cancelEmitter" === en)) {
        var pd_6 = (_co.onCancel($event) !== false);
        ad = (pd_6 && ad);
    } if (("chipListEmitter" === en)) {
        var pd_7 = (_co.onChipListDisplay($event) !== false);
        ad = (pd_7 && ad);
    } return ad; }, i2.View_ChipInputComponent_0, i2.RenderType_ChipInputComponent)), i1.ɵprd(512, null, i3.ChipInputService, i3.ChipInputService, [i4.Parser, i5.DateFormatter]), i1.ɵdid(3, 770048, [[1, 4], ["operator_acceptance_search_chip_input", 4]], 0, i6.ChipInputComponent, [i3.ChipInputService, i1.ApplicationRef, i7.FocusHandlerService, i8.AlphaNumericSorting, i8.NumberSorting, i1.ElementRef], { isDataLoaded: [0, "isDataLoaded"], chisForDisplay: [1, "chisForDisplay"], isTriggerSearch: [2, "isTriggerSearch"], isChipListOptionAvail: [3, "isChipListOptionAvail"], isFocus: [4, "isFocus"], placeHolderText: [5, "placeHolderText"], inputAuthConfigComponent: [6, "inputAuthConfigComponent"], isSearchBarStyleNotAvailable: [7, "isSearchBarStyleNotAvailable"] }, { searchEmitter: "searchEmitter", chipListEmitter: "chipListEmitter", cancelEmitter: "cancelEmitter", onfocus: "onfocus" }), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [["class", "clearfix"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isDataLoaded; var currVal_1 = _co.displayChipsArr; var currVal_2 = _co.isTriggerSearch; var currVal_3 = true; var currVal_4 = _co.isFocus; var currVal_5 = _co.placeHolderText; var currVal_6 = null; var currVal_7 = _co.isSearchBarStyleNotAvailable; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }, null); }
export function View_OperatorAcceptanceSearchCriteriaComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "tc-operator-acceptance-search-criteria", [], null, null, null, View_OperatorAcceptanceSearchCriteriaComponent_0, RenderType_OperatorAcceptanceSearchCriteriaComponent)), i1.ɵprd(6144, null, "ChipHandlerService", null, [i9.ChipHandlerService]), i1.ɵprd(4608, null, i3.ChipInputService, i3.ChipInputService, [i4.Parser, i5.DateFormatter]), i1.ɵprd(512, null, i9.ChipHandlerService, i9.ChipHandlerService, [i1.Injector, i10.DataStoreService, i11.ConfigLoader, i12.CommonHelper, i5.DateFormatter, i13.DataHandlerService]), i1.ɵdid(4, 245760, null, 0, i14.OperatorAcceptanceSearchCriteriaComponent, [i11.ConfigLoader, i10.DataStoreService, i15.ActionHandlerService, i16.UserJourneyManager, i17.EventManager, i9.ChipHandlerService, i18.DMCQueryParamsService, i12.CommonHelper, i19.SpinnerService, i20.UserProfileService, i21.RootService, i5.DateFormatter, i22.UserManagementService], null, null)], function (_ck, _v) { _ck(_v, 4, 0); }, null); }
var OperatorAcceptanceSearchCriteriaComponentNgFactory = i1.ɵccf("tc-operator-acceptance-search-criteria", i14.OperatorAcceptanceSearchCriteriaComponent, View_OperatorAcceptanceSearchCriteriaComponent_Host_0, { isInsideForm: "isInsideForm", searchCriteria: "searchCriteria", searchCriteriaChipConfig: "searchCriteriaChipConfig", isSearchBarStyleNotAvailable: "isSearchBarStyleNotAvailable" }, { searchTrigger: "searchTrigger" }, []);
export { OperatorAcceptanceSearchCriteriaComponentNgFactory as OperatorAcceptanceSearchCriteriaComponentNgFactory };
