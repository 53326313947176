import { Injectable } from '@angular/core';
import { Icon } from '@tc-core/model/it/codegen/ui/framework/icon';
import { KeyValue } from '@tc-core/model/it/codegen/ui/key-value';
import { DataFormatterUtilService, JsonUtilService } from '@tc-core/util/system';

@Injectable()
export class LeaveSetupSearchPreProcessorService {
    constructor(
        private jsonUtil: JsonUtilService,
        private dataFormatterUtilService: DataFormatterUtilService
    ) {
    }
    processSearchResultsIcon(product: any, arr: any[], id: string): KeyValue {
        let returnVal = new KeyValue();
        let iconView = new Icon();
        if (product) {
            // todo need to fix this when proper status returns from backend
            let filteredIcons = arr[0];
            let iconObj = filteredIcons;
            if (iconObj) {
                iconView.key = iconObj['key'];
                iconView.iconType = iconObj['iconType'];
                iconView.style = iconObj['style'];
                iconView.value = iconObj['value'] ? iconObj['value'] : '';
                iconView.badge = iconObj['badge'] ? iconObj['badge'] : '';
            }
        }
        returnVal.key = id;
        returnVal.value = iconView;
        return returnVal;
    }

}