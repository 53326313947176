/**
 *  REST URL Pattern
 *  http://dmcdev.codegen.net/some-service/SERVICE_VERSION/LEVEL_1_RESOURCE/{id_of_LEVEL_1_RESOURCE}/LEVEL_2_RESOURCE/{id_of_LEVEL_2_RESOURCE}
 *
 *  eg:
 *  http://dmcdev.codegen.net/operation-reservation-service/v1/service-items/15443/service-notes/1252
 */

export namespace UrlPaths {
    // SERVICE URL KEYS
    export const OP_RES_URL_KEY = 'reservation_service_url';
    export const OP_SUP_URL_KEY = 'supplier_service_url';
    export const OP_ACC_URL_KEY = 'accounting_service_url';
    export const OP_DATA_URL_KEY = 'data_service_url';
    export const OP_DOCUMENT_URL_KEY = 'document_service_url';
    export const OP_USER_PROFILE_URL_KEY = 'user_profile_service_url';
    export const OP_NOTIFICATION_URL_KEY = 'notification_service_url';
    export const OP_JOB_URL_KEY = 'job_service_url';
    export const OP_BACKGROUND_URL_KEY = 'background_service_url';

    // SERVICE_VERSIONS
    export const OP_RES_VERSION = 'v1';
    export const OP_SUP_VERSION = 'v1';
    export const OP_ACC_VERSION = 'v1';
    export const OP_DATA_VERSION = 'v1';
    export const OP_DOCUMENT_VERSION = 'v1';
    export const OP_USER_PROFILE_VERSION = 'v1';
    export const OP_NOTIFICATION_VERSION = 'v1';
    export const OP_JOB_VERSION = 'v1';
    export const OP_BACKGROUND_VERSION = 'v1';

    // RESERVATION SERVICE
    // LEVEL_1_RESOURCE
    export const TRS_ITEM_GROUPS = 'transport-item-groups';
    export const GENERIC_ITEMS = 'generic-item-groups';
    export const SERVICE_ITEMS = 'service-items';
    export const ALLOCATION_COUNT = 'allocation-counts';
    export const SERVICE_ITEM_DOCUMENT = 'service-item-documents';
    export const CRITERIA = 'criteria';
    export const CRITERIA_V2 = 'criteria';
    export const TRS_ITEM_GROUP_TIME_RANGES = 'transport-item-group-time-ranges';
    export const ASSIGNMENT = 'assignments';
    export const ASSIGNMENT_SUMMARIES = 'assignment-summaries';
    export const GROUP_NOTES = 'group-notes';
    export const RECOMMENDED_ASSIGNMENTS = 'recommended-assignments';
    export const RECOMMENDED_VEHICLES = 'recommended-vehicles';
    export const RECOMMENDED_DRIVERS = 'recommended-drivers';
    export const RECOMMENDED_RESOURCES = 'recommended-resources';
    export const RECOMMENDED = 'recommended';
    export const NOTIFICATIONS = 'notifications';
    export const MESSAGES = 'messages';
    export const MORE_MESSAGES = 'messages/load';
    export const MESSAGES_GROUP_SAVE = 'messages/groupSave';
    export const NOTIFICATION_STATE_CHANGE = 'notifications/notification-state-change';
    export const BOOKING_SUMMARIES = 'booking-summaries';
    export const BOOKINGS = 'bookings';
    export const PRODUCT_ITEMS = 'product-items';
    export const BOOKING_ITEMS_V2 = 'booking-items';
    export const SPLIT = 'split';
    export const SERVICE_ITEMS_V2 = 'service-items';
    export const SUPPLIER_ALLOCATION_SUMMARIES_V2 = 'supplier-allocation-summaries';
    export const RESOURCE_ALLOCATION_SUMMARIES_V2 = 'resource-allocation-summaries';
    export const ROOM_OCCUPANCIES_V2 = 'room-occupancies';
    export const ASSIGNMENT_RAW_DATA_V2 = 'assignment-raw-data';
    export const ASSIGNMENTS_V2 = 'assignments';
    export const DASHBOARD_SEARCH_ITEM_V2 = 'dashboard-search-items';
    export const ALLOCATION_FEATURE = 'allocation-feature';
    export const JOBS = 'jobs';
    export const JOB_HISTORIES = 'history';
    export const JOB_PARAMETER = 'job-parameter';
    export const BACKGROUND_TASKS = 'background-tasks';

    // LEVEL_2_RESOURCE
    export const PASSENGER_GROUPS = 'passenger-groups';
    export const SERVICE_NOTES = 'service-notes';
    export const LEAD_PASSENGER = 'lead-passenger';
    export const PATCH_ITEM = 'patch-item';
    export const search = 'search-items';
    export const PASSENGERS = 'passengers';
    export const NOTES = 'notes';
    export const SUPPLEMENT_NOTES = 'supplement-notes';



    // SUPPLIER SERVICE
    // LEVEL_1_RESOURCE
    export const RESOURCES = 'resources';
    export const SUPPLIERS = 'suppliers';
    export const CONTENTS = 'content';
    export const SUP_SUMMARIES = 'supplier-summaries';
    export const GEN_SUP_ALLOC_SUMMARIES = 'gen-supplier-allocation-summaries';
    export const TRS_SUP_ALLOC_SUMMARIES = 'trs-supplier-allocation-summaries';
    export const DRI_SUP_ALLOC_SUMMARIES = 'driver-supplier-allocation-summaries';
    export const GEN_RES_SUMMARIES = 'gen-resource-summaries';
    export const ALL_RES_SUMMARIES = 'allResourceSummaries';
    export const GEN_RES_ALLOC_SUMMARIES = 'gen-resource-allocation-summaries';
    export const DRIVER_ALLOC_SUMMARIES = 'driver-allocation-summaries';
    export const VEHICLE_SUMMARIES = 'vehicle-summaries';
    export const VEHICLE_ALLOC_SUMMARIES = 'vehicle-allocation-summaries';
    export const LEAVES = 'leaves';
    export const LEAVE_SUMMARIES = 'leave-summaries';
    export const OCCUPANCIES = 'room-occupancies';

    // LEVEL_2_RESOURCE
    export const SUPPLIER_ITEMS = 'items';
    export const UPLOAD = 'upload';
    export const IMAGES = 'images';

    // ACCOUNTING SERVICE
    // LEVEL_1_RESOURCE
    export const ACCOUNTING_RULES = 'accounting-rules';
    export const ACCOUNTING_EVENTS = 'accounting-events';
    export const ACCOUNTING_UNITS = 'accounting-units';
    export const LEDGER_ACCOUNTS = 'ledger-accounts';
    export const LEDGER_ENTRIES = 'ledger-entries';

    // LEVEL_2_RESOURCE

    // DATA SERVICE
    // LEVEL_1_RESOURCE
    export const LOCATION_TYPES = 'location-types';
    export const LOCATIONS = 'locations';
    export const LOCATION_GROUPS = 'location-groups';
    export const LOCATION_CODES = 'locationCodes';
    export const LOCATION_CODE_AVAILABILITY = 'checkLocationCodeAvailability';
    export const ROUTES = 'routes';
    export const CITIES = 'cities';
    export const COMMON = 'common';
    export const STATES = 'states';
    export const COMMON_DATA = 'common-data';
    export const COUNTRY_DATA = 'countries';
    export const COSTING_TYPES = 'costing-types';
    export const PRODUCT_DETAILS = 'product-details';
    export const BOARD_BASIS_SETUP = 'board-basis-setup';
    export const CONFIGURATIONS = 'configurations';
    export const BOARD_BASIS_SETUP_SEARCH = 'board-basis-setup/search';
    export const ALL_TYPES = 'list';

    // DOCUMENT SERVICE
    // LEVEL_1_RESOURCE
    export const DOCUMENT_TYPES = 'document-types';
    export const DOCUMENT = 'documents';
    export const RULE_BASED_DOCUMENTS = 'rule-based-documents';

    // LEVEL_2_RESOURCE
    export const DISPATCH_ENTRIES = 'dispatch-entries';
    export const DOCUMENT_QUEUE_ENTRIES = 'document-queue-entries';
    export const DOCUMENT_FILE = 'file';
    export const DOCUMENT_FILES = 'files';

    // Currency setup
    export const CURRENCIES = 'currencies';
    export const CURRENCY_CODES = 'currencyCodes';

    // Resource type setup
    export const RESOURCE_TYPES = 'resource-types';
    export const RESOURCE_CODES = 'resourceCodes';

    // Profile type setup
    export const PROFILE_TYPES = 'profile-types';
    export const PROFILE_TYPE_CODES = 'profileTypeCodes';

    // Mapped Resource type setup
    export const MAPPED_RESOURCE_TYPES = 'mapped-resource-types';
    export const MAPPED_RESOURCE_TYPE_CODES = 'resourceMappingCodes';

    // USER PROFILE SERVICE
    // LEVEL_1_RESOURCE
    export const SERVICE_PROFILE = 'user-service-profiles';
    export const USER_PROFILE = 'user-profiles';
    export const USER_PROFILE_KC = 'user-profile-kc';

    export const GET_RESOURCE_EMAILS = 'getResourceEmails';
    export const GET_ALL_RESOURCE_EMAILS = 'getAllResourceEmails';

    // LEVEL_2_RESOURCE
    export const PROFILE_ME = 'me';
    export const APPROVAL_STATUS = 'approval';

    // websocket urls
    export const WEB_SOCKET = '/web-socket';
    export const START_LISTENING = '/from-client/start';
    export const STOP_LISTENING = '/from-client/stop';
    export const GET_NOTIFICATIONS = '/user/to-client/notification';
    export const GET_MESSAGES = '/user/to-client/message';
    export const SEND_MESSAGE = '/from-client/messageIn';
    export const SEND_BROADCAST_MESSAGE = '/from-client/broadCast';

    // Accommodation setups
    export const ROOM_TYPES = 'room-types';
    export const ROOM_FACILITIES = 'room-facilities';

    //language
    export const LANGUAGES = 'languages';

    //Key Controls
    export const COMPANIES = 'companies';
    export const DIVISIONS = 'divisions';
    export const BRANDS = 'brands';
    export const DISTRIBUTION_CHANNEL = 'distribution-channel';

    export const CODE_NAME = 'code-name';

}
