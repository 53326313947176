<div class="adv-client-search" *ngIf="serviceItemGroupCards">
    <ng-container *ngFor="let groupCard of serviceItemGroupCards |tcAdvPager:pager.currPage:pager.itemsPerPage | tcAdvSorter:sortingHandler:sorter; let i = index;">
        <div class="client-search-result-item-wrapper">
            <div
                    tcExpansionPanel
                    (clickedOutside)="onOutsideClick()"
                    tabindex="-1"
                    [include]= "['cdk-overlay-container','resource-assignment-view', 'assign-panel-footer-section', 'split-passengers-view-wrapper', 'tc-dialog', 'mat-option', 'mat-radio-button','lead-passenger-selection','tc-icon-block','material-icons tc-icon-block__icon tc-icon-block__icon--active', 'tc-overlay', 'cdk-global-overlay-wrapper', 'tc-manage-notes' ]"
                    [enabled]="true"
                    [single]="true"
                    (collapse)="expandedPanelIndex = -1">

                <!-- Content card Strip -->
                <div
                        *tcExpansionPanelSummary
                        id="clientSumExp_{{i}}">
                    <tc-content-strip
                            (actionClick)="onActionClick(groupCard,$event)"
                            (click)="onReservationSelection(groupCard,i)">
                        <tc-result-content-card-reservations
                                [summaryContent]="groupCard"
                                [row2col2MatIcon]="contentCardRow2col2MatIcon"
                        >
                        </tc-result-content-card-reservations>
                    </tc-content-strip>
                </div>

                <div *tcExpansionPanelDetail id="clientDetailExp_{{i}}">
                    <tc-expanded-content-card class="tc-expanded-content-card tc-form-view" [isEdit]="false">
                        <tc-expanded-content-card-header
                                *ngIf="selectedReservation"
                                [headerText]="getRoute(groupCard)"
                                [subHeaderText]="processDate(groupCard)"
                                (headerClick)="onHeaderClick()">

                        </tc-expanded-content-card-header>

                        <tc-reservation-detail-view
                                [isRequestCompleted]="isRequestCompleted"
                                [summaryCardData]="selectedReservation"
                                [reservationServiceItemCards]="reservationServiceItemCards"
                                [footerDataMap]="footerDataMap"
                                [supplierList]="supplierList"
                                [resourceList]="resourceList"
                                [serviceItemChangesInSelectedGroup]="serviceItemChangesInSelectedGroup"
                                [filteredVehicleSuppliers]="filteredVehicleSuppliers"
                                [filteredDriverSuppliers]="filteredDriverSuppliers"
                                [filteredVehicles]="filteredVehicles"
                                [filteredDrivers]="filteredDrivers"
                                [changesAvailableForServiceItems]="changesAvailableForServiceItems"
                                [selectedServiceGroupNotes]="selectedServiceGroupNotes"
                                (headerClick)="onHeaderClick()"
                                (clickOutSide)="onOutsideClick()"
                                (pickUps)="onPickUpsClick($event)"
                                (dropOffs)="onDropOffsClick($event)"
                                (moreClick)="onMoreClick($event)"
                                (historyClick)="onHistoryClick($event)"
                                (splitClick)="onSplitClick($event)"
                                (leadPassengerClick)="onLeadPassengerSelect($event)"
                                (confirmClick)="onConfirmClick($event)"
                                (clickSupplierReject)="onSupplierRejectClick($event)"
                                (saveAssignments)="onSaveAssignments()"
                                (clickMerge)="onClickMerge()"
                                (vehicleAssign)="onSelectVehicle($event)"
                                (driverAssign)="onSelectDriver($event)"
                                (vehicleClear)="clearVehicle($event)"
                                (driverClear)="clearDriver($event)"
                                (vehicleTextChange)="onVehicleTextChange($event)"
                                (autoAssignResources)="onAutoAssignResources($event)"
                                (driverTextChange)="onDriverTextChange($event)"
                                (vehicleTextFocus)="onVehicleFocus($event)"
                                (serviceGroupNote)="addGroupNote()"
                                (emptyServiceGroupNote)="addEmptyGroupNote()"
                                (saveNote)="onNoteSave($event)"
                                (deleteNote)="onDelete($event)"
                                (isEditClicked)="onEdit($event)"
                                (isSavedChanges)="onSavedChanges($event)"
                                (viewMoreFocus)="onViewMoreClick($event)"
                                (vehicleSupplierAssign)="onSelectVehicleSupplier($event)"
                                (driverSupplierAssign)="onSelectDriverSupplier($event)"
                                (vehicleSupplierClear)="clearVehicleSupplier($event)"
                                (driverSupplierClear)="clearDriverSupplier($event)"
                                (driverSupplierTextChange)="onDriverSupplierTextChange($event)"
                                (vehicleSupplierTextChange)="onVehicleSupplierTextChange($event)"
                                (vehicleJobNoChange)="onVehicleJobNoChange($event)"
                                (driverJobNoChange)="onDriverJobNoChange($event)"
                                (clearVehicleAssignment)="onClearVehicleAssignment($event)"
                                (clearDriverAssignment)="onClearDriverAssignment($event)"
                                (bulkDriverAssignment)="onBulkDriverAssignment()"
                                (bulkVehicleAssignment)="onBulkVehicleAssignment()">
                        </tc-reservation-detail-view>
                    </tc-expanded-content-card>
                </div>
            </div>
        </div>
    </ng-container>
</div>

