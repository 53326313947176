import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import { CurrencyFieldModule } from '@tc/currency-field';
import { CurrencyInputModule } from '@tc/currency-input';
import { PipesModule } from '@tc/pipes';
import {SupplierAssignDetailViewComponent} from './supplier-assign-detail-view.component';
import {TranslateModule} from '@ngx-translate/core';
import { MatInputModule, MatSelectModule, MatTableModule } from '@angular/material';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule.forChild(),
        MatInputModule,
        ReactiveFormsModule,
        TranslateModule.forChild(),
        MatSelectModule,
        FormsModule,
        MatTableModule,
        CurrencyInputModule,
        CurrencyFieldModule,
        PipesModule
    ],
    declarations: [SupplierAssignDetailViewComponent],
    exports: [SupplierAssignDetailViewComponent]
})
export class SupplierAssignDetailViewModule {
}
