import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule, MatTooltipModule, MatMenuModule, MatListModule, MatIconModule } from '@angular/material';
import { BrowserModule } from '@angular/platform-browser';
import { SectionHeadingModule } from '@tc/section-heading';
import { AgGridModule } from 'ag-grid-angular';
import { DateEditorComponent } from '../../editors/date-editor/date-editor.component';
import { DropdownEditorComponent } from '../../editors/dropdown-editor/dropdown-editor.component';
import { MultiSelectorEditorComponent } from '../../editors/multi-selector-editor/multi-selector-editor.component';
import { RadioButtonEditorComponent } from '../../editors/radio-button-editor/radio-button-editor.component';
import { AgGridCustomRenderersModule } from '../../renderers/ag-grid-custom-renderers.module';
import { CheckBoxRendererEditorComponent } from '../../renderers/check-box-selector-renderer/check-box-renderer-editor.component';
import { TemplateRendererComponent } from '../../renderers/template-renderer/template-renderer.component';
import { SetupGridComponent } from './setup-grid.component';
import { GroupingSetupGridComponent } from '../grouping-setup-grid/grouping-setup-grid.component';
import {TranslateModule} from '@ngx-translate/core';
import {MaxLengthValidatorComponent} from '../../editors/max-length-validator/max-length-validator.component';
import {MaxLengthTerminatorComponent} from "../../editors/max-length-terminator/max-length-terminator.component";

@NgModule({
    declarations: [SetupGridComponent,GroupingSetupGridComponent],
    imports: [
        CommonModule,
        BrowserModule,
        FormsModule,
        MatButtonModule,
        MatTooltipModule,
        MatMenuModule,
        MatListModule,
        MatButtonModule,
        MatTooltipModule,
        MatIconModule,
        HttpClientModule,
        SectionHeadingModule,
        AgGridCustomRenderersModule,
        AgGridModule.withComponents([
            TemplateRendererComponent,
            CheckBoxRendererEditorComponent,
            DropdownEditorComponent,
            MultiSelectorEditorComponent,
            RadioButtonEditorComponent,
            DateEditorComponent,
            MaxLengthValidatorComponent,
            MaxLengthTerminatorComponent
        ]),
        TranslateModule.forChild()
    ],
    exports: [SetupGridComponent, GroupingSetupGridComponent]
})
export class SetupGridModule {
}
