import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { SummaryCardData } from '@tc-core/model/it/codegen/ui/framework/summary-card-data';
import { Booking } from '../../../models/reservation-v2/booking';
import { PassengersDetailViewComponent } from './passengers-detail-view/passengers-detail-view.component';
import { BookingNotesDetailViewComponent } from './booking-notes-detail-view/booking-notes-detail-view.component';
import { ResponseUtil } from '../../../services/util/response/response-util.service';
import { BookingManagementService } from '../../../services/backend-consumers/reservation-v2/booking-management.service';
import { CommonHelper } from '@tc-core/util/helpers/common-helper.service';
import { ContentSummaryCardService } from '../../../services/util/ui/content-summary-card.service';
import { DataKey, DataStoreService } from '../../../services/util/framework/data-store.service';
import { ModalData } from '@tc-core/model/it/codegen/ui/framework/modal-data';
import { LEVEL } from '@tc-core/model/it/codegen/ui/framework/dialog-model';
import { PassengerSelectionDetailViewComponent } from './booking-items-detail-view/passenger-selection-detail-view/passenger-selection-detail-view.component';
import { ModalService } from '@tc/modal/modal.service';
import { TCO } from '../../../constants';
import { ConfigLoader } from '@tc-core/util/framework/config-loader.service';
import { UserManagementService } from '../../../services/user-management/user-management.service';
var BookingDetailViewComponent = /** @class */ (function () {
    function BookingDetailViewComponent(bookingManagementService, commonHelper, summaryCardService, dataStore, modalService, configLoader, userManagementService) {
        this.bookingManagementService = bookingManagementService;
        this.commonHelper = commonHelper;
        this.summaryCardService = summaryCardService;
        this.dataStore = dataStore;
        this.modalService = modalService;
        this.configLoader = configLoader;
        this.userManagementService = userManagementService;
        this.headerClick = new EventEmitter();
        this.editClick = new EventEmitter();
        this.expandEventEmitter = new EventEmitter();
        this.panelOpenState = false;
        this.bookingItemSummaryCards = [];
        this.readonly = false;
        this.dataChanged = false;
        this.passengerSelectionChangeFn = function (valid) {
        };
    }
    BookingDetailViewComponent.prototype.ngOnInit = function () {
        this.bookingItemCardConfig = this.configLoader.configurations.get(TCO.CONF.CONF_BOOKING_ITEM_CARD_CONFIG);
        if (this.summaryCardData) {
            this.expandEventEmitter.emit(true);
        }
        this.createBookingItemSummaryCards();
    };
    BookingDetailViewComponent.prototype.onHeaderClick = function (event) {
        this.headerClick.emit(event);
    };
    BookingDetailViewComponent.prototype.onEditClick = function (event) {
        this.editClick.emit(event);
    };
    BookingDetailViewComponent.prototype.getBookingDetailsById = function (id) {
        var _this = this;
        if (id !== null) {
            this.bookingManagementService.getById(id)
                .subscribe(function (res) {
                if (_this.commonHelper.dataValidity(res)) {
                    _this.detailedBooking = ResponseUtil.getFirstData(res);
                    console.log(_this.detailedBooking);
                }
            });
        }
    };
    BookingDetailViewComponent.prototype.confirmModal = function (data) {
        this.modalService.confirm(data).subscribe(function (res) {
        });
    };
    BookingDetailViewComponent.prototype.closeModal = function () {
        if (this.modalService.close()) {
            this.modalService.close().subscribe(function (res) {
            });
        }
    };
    BookingDetailViewComponent.prototype.onItemPassengersClick = function (event) {
        this.dataStore.set(DataKey.popupClose, null);
        var output = {
            changed: undefined,
            valid: undefined,
        };
        var dataObject = new ModalData(LEVEL.SUCCESS, 'Item Passengers', null, null, 'modal-basic--alt modal-basic--fixed-size', PassengerSelectionDetailViewComponent, {
            booking: this.booking,
            bookingItem: event,
            readonly: this.readonly,
            onChangeFn: this.passengerSelectionChangeFn.bind(this)
        });
        this.confirmModal(dataObject);
        this.dataStore.get(DataKey.popupClose).subscribe(function (data) {
            if (data != null) {
                // this.closeModal();
            }
        });
    };
    BookingDetailViewComponent.prototype.createBookingItemSummaryCards = function () {
        var e_1, _a;
        if (this.booking && this.booking.bookingItems) {
            var dataArray = [];
            try {
                for (var _b = tslib_1.__values(this.booking.bookingItems), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var bookingItem = _c.value;
                    var iconKey = '';
                    if (bookingItem.genBookingItem) {
                        if (bookingItem.genBookingItem.productCode) {
                            iconKey = bookingItem.genBookingItem.productCode;
                        }
                        else if (bookingItem.genBookingItem.hotelRoomType) {
                            iconKey = 'accom';
                        }
                        else {
                            iconKey = 'generic';
                        }
                    }
                    else if (bookingItem.trsBookingItem) {
                        iconKey = 'vehicle';
                    }
                    dataArray.push({
                        iconType: iconKey,
                        bookingReferenceId: bookingItem.bookingReferenceId
                    });
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_1) throw e_1.error; }
            }
            this.bookingItemSummaryCards = this.summaryCardService.getContentCardsByConfig(this.bookingItemCardConfig, dataArray);
        }
        else {
            this.bookingItemSummaryCards = [];
        }
    };
    return BookingDetailViewComponent;
}());
export { BookingDetailViewComponent };
