/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@tc/directives/expansion-panel/expansion-panel.directive";
import * as i2 from "@tc-core/util/security/authorization.service";
import * as i3 from "../reservation-service-group-note/reservation-service-group-note.component.ngfactory";
import * as i4 from "../reservation-service-group-note/reservation-service-group-note.component";
import * as i5 from "@angular/common";
import * as i6 from "./reservation-service-group-notes.component";
var styles_ReservationServiceGroupNotesComponent = [];
var RenderType_ReservationServiceGroupNotesComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ReservationServiceGroupNotesComponent, data: {} });
export { RenderType_ReservationServiceGroupNotesComponent as RenderType_ReservationServiceGroupNotesComponent };
function View_ReservationServiceGroupNotesComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 5, "div", [["tabindex", "-1"], ["tcExpansionPanel", ""]], null, [[null, "collapse"], ["document", "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("document:click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).handleClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("collapse" === en)) {
        var pd_1 = ((_co.expandedPanelIndex = (0 - 1)) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 606208, null, 1, i1.ExpansionPanelDirective, [i0.ElementRef, i2.AuthorizationService], { enabled: [0, "enabled"] }, { collapse: "collapse" }), i0.ɵqud(335544320, 1, { tooltipDirective: 0 }), (_l()(), i0.ɵeld(4, 0, null, null, 2, "div", [], [[8, "id", 0]], null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 1, "tc-reservation-service-group-note", [], null, [[null, "deleteNote"], [null, "saveNote"], [null, "isEditClicked"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("deleteNote" === en)) {
        var pd_0 = (_co.onDelete($event) !== false);
        ad = (pd_0 && ad);
    } if (("saveNote" === en)) {
        var pd_1 = (_co.onNoteSave($event) !== false);
        ad = (pd_1 && ad);
    } if (("isEditClicked" === en)) {
        var pd_2 = (_co.onEdit($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i3.View_ReservationServiceGroupNoteComponent_0, i3.RenderType_ReservationServiceGroupNoteComponent)), i0.ɵdid(6, 114688, null, 0, i4.ReservationServiceGroupNoteComponent, [], { serviceNote: [0, "serviceNote"], disableGroupNoteEdit: [1, "disableGroupNoteEdit"] }, { saveNote: "saveNote", deleteNote: "deleteNote", isEditClicked: "isEditClicked" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = true; _ck(_v, 2, 0, currVal_0); var currVal_2 = _v.context.$implicit; var currVal_3 = _co.disableGroupNoteEdit; _ck(_v, 6, 0, currVal_2, currVal_3); }, function (_ck, _v) { var currVal_1 = i0.ɵinlineInterpolate(1, "clientSumExp_", _v.context.index, ""); _ck(_v, 4, 0, currVal_1); }); }
function View_ReservationServiceGroupNotesComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", ""]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", ""]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ReservationServiceGroupNotesComponent_2)), i0.ɵdid(3, 278528, null, 0, i5.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.selectedServiceGroupNotes; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_ReservationServiceGroupNotesComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", " container-fluid"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ReservationServiceGroupNotesComponent_1)), i0.ɵdid(2, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.selectedServiceGroupNotes; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ReservationServiceGroupNotesComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-reservation-service-group-notes", [], null, null, null, View_ReservationServiceGroupNotesComponent_0, RenderType_ReservationServiceGroupNotesComponent)), i0.ɵdid(1, 114688, null, 0, i6.ReservationServiceGroupNotesComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ReservationServiceGroupNotesComponentNgFactory = i0.ɵccf("tc-reservation-service-group-notes", i6.ReservationServiceGroupNotesComponent, View_ReservationServiceGroupNotesComponent_Host_0, { selectedServiceGroupNotes: "selectedServiceGroupNotes", disableGroupNoteEdit: "disableGroupNoteEdit" }, { saveNote: "saveNote", deleteNote: "deleteNote", isEditClicked: "isEditClicked" }, []);
export { ReservationServiceGroupNotesComponentNgFactory as ReservationServiceGroupNotesComponentNgFactory };
