import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatAutocompleteModule, MatDatepickerModule, MatExpansionModule, MatFormFieldModule, MatIconModule} from '@angular/material';
import {TranslateModule} from '@ngx-translate/core';
import {ExpandedContentCardModule} from '@tc/expanded-content-card';
import {ExpandedContentCardBodyModule} from '@tc/expanded-content-card-body';
import {ExpandedContentCardFooterModule} from '@tc/expanded-content-card-footer';
import { KeyControlDetailCardModule } from '../../../widgets/shared/key-control-detail-card/key-control-detail-card.module';
import { ItemNotesModule } from '../booking/booking-items/item-notes/item-notes.module';
import {BookingDetailViewComponent} from './booking-detail-view.component';
import {BookingPassengersModule} from '../booking/passengers/booking-passengers.module';
import {PassengersDetailViewComponent} from './passengers-detail-view/passengers-detail-view.component';
import {BookingItemsDetailViewComponent} from './booking-items-detail-view/booking-items-detail-view.component';
import {BookingNotesDetailViewComponent} from './booking-notes-detail-view/booking-notes-detail-view.component';
import {AgGridModule} from 'ag-grid-angular';
import {BookingItemsModule} from '../booking/booking-items/booking-items.module';
import {ItemSummaryDetailViewComponent} from './booking-items-detail-view/item-summary-detail-view/item-summary-detail-view.component';
import {AccomItemDetailViewComponent} from './booking-items-detail-view/accom-item-detail-view/accom-item-detail-view.component';
import {GenItemDetailViewComponent} from './booking-items-detail-view/gen-item-detail-view/gen-item-detail-view.component';
import {TrsItemDetailViewComponent} from './booking-items-detail-view/trs-item-detail-view/trs-item-detail-view.component';
import {PassengerSelectionDetailViewComponent} from './booking-items-detail-view/passenger-selection-detail-view/passenger-selection-detail-view.component';
import {ResourceModule} from '@tc/resource';
import {ReactiveFormsModule} from '@angular/forms';
import {NgxMatDatetimePickerModule} from '@angular-material-components/datetime-picker';
import {BookingItemNotesModule} from '../booking/booking-item-notes/booking-item-notes.module';
import {MatInputModule} from '@angular/material';
import {MatButtonModule} from '@angular/material/button';
import {MatTooltipModule} from '@angular/material/tooltip';
import {BookingDetailSummaryViewComponent} from './booking-detail-summary-view/booking-detail-summary-view.component';

@NgModule({
    declarations: [
        BookingDetailViewComponent,
        PassengersDetailViewComponent,
        BookingItemsDetailViewComponent,
        BookingNotesDetailViewComponent,
        ItemSummaryDetailViewComponent,
        AccomItemDetailViewComponent,
        GenItemDetailViewComponent,
        TrsItemDetailViewComponent,
        PassengerSelectionDetailViewComponent,
        BookingDetailSummaryViewComponent
    ],
    exports: [
        BookingDetailViewComponent
    ],
    imports: [
        CommonModule,
        TranslateModule.forChild(),
        MatExpansionModule,
        ExpandedContentCardModule,
        ExpandedContentCardFooterModule,
        ExpandedContentCardBodyModule,
        BookingPassengersModule,
        AgGridModule,
        BookingItemsModule,
        ResourceModule,
        MatIconModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatAutocompleteModule,
        NgxMatDatetimePickerModule,
        MatDatepickerModule,
        BookingItemNotesModule,
        MatInputModule,
        MatButtonModule,
        MatTooltipModule,
        ItemNotesModule,
        KeyControlDetailCardModule
    ],
    entryComponents: [
        PassengerSelectionDetailViewComponent
    ]
})
export class BookingDetailViewModule {
}
