/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/forms";
import * as i2 from "ngx-material-timepicker";
import * as i3 from "../../../../../../../node_modules/ngx-material-timepicker/ngx-material-timepicker.ngfactory";
import * as i4 from "./time-editor.component";
var styles_TimeEditorComponent = [];
var RenderType_TimeEditorComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TimeEditorComponent, data: {} });
export { RenderType_TimeEditorComponent as RenderType_TimeEditorComponent };
export function View_TimeEditorComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { picker: 0 }), (_l()(), i0.ɵeld(1, 0, null, null, 2, "input", [], [[8, "disabled", 0]], [[null, "input"], [null, "blur"], [null, "click"]], function (_v, en, $event) { var ad = true; if (("input" === en)) {
        var pd_0 = (i0.ɵnov(_v, 3).onInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 3).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (i0.ɵnov(_v, 3).onClick($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i0.ɵprd(5120, null, i1.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.TimepickerDirective]), i0.ɵdid(3, 671744, null, 0, i2.TimepickerDirective, [i0.ElementRef], { timepicker: [0, "timepicker"] }, null), (_l()(), i0.ɵeld(4, 0, null, null, 2, "ngx-material-timepicker", [], null, [[null, "keydown"]], function (_v, en, $event) { var ad = true; if (("keydown" === en)) {
        var pd_0 = (i0.ɵnov(_v, 6).onKeydown($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_NgxMaterialTimepickerComponent_0, i3.RenderType_NgxMaterialTimepickerComponent)), i0.ɵprd(512, null, i2.ɵa, i2.ɵa, []), i0.ɵdid(6, 245760, [[1, 4], ["picker", 4]], 0, i2.NgxMaterialTimepickerComponent, [i2.ɵa, i2.ɵb], null, null)], function (_ck, _v) { var currVal_1 = i0.ɵnov(_v, 6); _ck(_v, 3, 0, currVal_1); _ck(_v, 6, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 3).disabled; _ck(_v, 1, 0, currVal_0); }); }
export function View_TimeEditorComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-time-editor", [], null, null, null, View_TimeEditorComponent_0, RenderType_TimeEditorComponent)), i0.ɵdid(1, 4243456, null, 0, i4.TimeEditorComponent, [], null, null)], null, null); }
var TimeEditorComponentNgFactory = i0.ɵccf("tc-time-editor", i4.TimeEditorComponent, View_TimeEditorComponent_Host_0, {}, {}, []);
export { TimeEditorComponentNgFactory as TimeEditorComponentNgFactory };
