import { ConfigLoader } from '@tc-core/util/framework';
import { DMCQueryParamsService } from '../../util/framework/dmc-query-params.service';
import { RequestService } from '../../util/framework/request.service';
import { UrlPaths } from '../url-paths';
import * as i0 from "@angular/core";
import * as i1 from "@tc-core/util/framework/config-loader.service";
import * as i2 from "../../util/framework/request.service";
import * as i3 from "../../util/framework/dmc-query-params.service";
var DivisionSetupService = /** @class */ (function () {
    function DivisionSetupService(configLoader, requestService, queryParamsService) {
        var _this = this;
        this.configLoader = configLoader;
        this.requestService = requestService;
        this.queryParamsService = queryParamsService;
        this.getRows = function (criteria) {
            var reqPrams = _this.queryParamsService.asQueryParams(criteria);
            return _this.requestService.get(UrlPaths.OP_USER_PROFILE_URL_KEY, [UrlPaths.OP_USER_PROFILE_VERSION, UrlPaths.DIVISIONS, UrlPaths.CODE_NAME], reqPrams);
        };
    }
    DivisionSetupService.prototype.deleteRow = function (code) {
        return this.requestService.delete(UrlPaths.OP_USER_PROFILE_VERSION, [UrlPaths.DIVISIONS, code]);
    };
    DivisionSetupService.prototype.saveRow = function (row) {
        return this.requestService.post(UrlPaths.OP_USER_PROFILE_URL_KEY, [UrlPaths.OP_USER_PROFILE_VERSION, UrlPaths.DIVISIONS], row);
    };
    DivisionSetupService.prototype.updateRow = function (code, row) {
        return this.requestService.put(UrlPaths.OP_USER_PROFILE_VERSION, [UrlPaths.OP_USER_PROFILE_URL_KEY, UrlPaths.DIVISIONS, code], row);
    };
    DivisionSetupService.ngInjectableDef = i0.defineInjectable({ factory: function DivisionSetupService_Factory() { return new DivisionSetupService(i0.inject(i1.ConfigLoader), i0.inject(i2.RequestService), i0.inject(i3.DMCQueryParamsService)); }, token: DivisionSetupService, providedIn: "root" });
    return DivisionSetupService;
}());
export { DivisionSetupService };
