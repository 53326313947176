<div class="detail-group__heading container-fluid" id="groupHeading">
    <div class="item-header tc-my-2 tc-py-3 tc-pl-3 tc-pr-2" id="itemHeading">
        <div class="item-header__content" id="itemHeaderContent"></div>
        <div class="tc-icon-block action-icon" id="actionIcon">
            <button
                    *ngIf="!isHiddenPrimaryResourceButton" mat-icon-button
                    matTooltip="{{'Primary Resources' | translate}}"
                    (click)="selectColumnGroup('primary')"
                    [disabled]=""
                    id="priResBtn">
                <i class="material-icons">looks_one</i>
            </button>
            <button
                    *ngIf="!isHiddenSecondaryResourceButton" mat-icon-button
                    matTooltip="{{'Secondary Resources' | translate}}"
                    [hidden]="isHiddenSecondaryResourceButton"
                    (click)="selectColumnGroup('secondary')"
                    [disabled]=""
                    id="secResBtn">
                <i class="material-icons">looks_two</i>
            </button>

            <button
                    mat-icon-button
                    aria-label="Delete"
                    (click)="onRestoreColDefClick()"
                    matTooltip="{{'Select All Columns' | translate}}"
                    [disabled]=""
                    id="selectAll">
                <i class="material-icons">select_all</i>
            </button>
            <button
                    matTooltip="{{'Show/Hide Columns' | translate}}"
                    mat-icon-button
                    [matMenuTriggerFor]="beforeMenu"
                    [disabled]=""
                    id="viewCol">
                <i class="material-icons">view_column</i>
            </button>
            <mat-menu #beforeMenu="matMenu" xPosition="before">
                <mat-selection-list
                    #colSelector
                    style="max-height: 400px;"
                    (selectionChange)=onSelectionChangeColSelector($event)>
                    <ng-container *ngFor="let coldef of columnDefsInput">
                        <ng-container *ngIf="!coldef.hideInColSelector">
                            <mat-list-option
                                    id="matMenu"
                                    [value]="coldef.field"
                                    [selected]="!coldef.hide"
                                    (click)="onClickColOption($event)">
                                {{coldef.headerName | translate}}
                            </mat-list-option>
                        </ng-container>
                    </ng-container>
                </mat-selection-list>
            </mat-menu>
        </div>
    </div>
</div>


<div *ngIf="columnDefsInput && selectedProducts && selectedProducts.length>0">
    <ag-grid-angular
        #agGrid
        id="bookingGrid"
        class="ag-theme-material tc-ag-grid tc-ag-grid--full-width tc-ag-grid--auto-height tc-border-left-0 tc-border-right-0"
        [ngClass]="operationPanelConfig.productViewOpen ? 'tc-ag-grid--height-m' : 'tc-ag-grid--height-l'"
        [columnDefs]="columnDefsInput"
        [getRowNodeId]=""
        [overlayLoadingTemplate]="overlayLoadingTemplate"
        [defaultColDef]="defaultColDef"
        [gridOptions]="gridOptions"
        [frameworkComponents]="frameworkComponents"
        [enableCellChangeFlash]="true"
        [domLayout]="domLayout"
        [rowMultiSelectWithClick]="true"
        [suppressRowClickSelection]="true"
        [overlayNoRowsTemplate]="overlayNoRowsTemplate"
        [floatingFilter]="false"
        (gridSizeChanged)="onGridSizeChange($event)"
        (selectionChanged)="onSelectionChange($event)"
        (gridReady)="onGridReady($event)"
        (rowDoubleClicked)="onRowDoubleClicked($event)"
        (rowClicked)="onRowClicked($event)"
        (cellValueChanged)="afterCellValueChange($event)"
        (cellEditingStopped)="cellEditingStopped($event)"
        (firstDataRendered)="onFirstDataRendered($event)">
    </ag-grid-angular>
</div>

<div *ngIf="showRowLoading" class="tc-ag-grid-empty">
    <img src="../../../../../assets/img/loading-tab.gif" class="tc-mb-2" alt="Loading">
    <span class="tc-ag-grid-empty__text">Loading...</span>
</div>
