<ag-grid-angular
        #agGrid
        style="width: 500px; height: 202px;"
        id="myGridNoteEditor"
        class="ag-theme-material note-editor-grid mat-elevation-z2"
        [columnDefs]="columnDefs"
        [rowData]="notes"
        [defaultColDef]="defaultColDef"
        [gridOptions]="gridOptions"
        (gridReady)="onGridReady($event)"
></ag-grid-angular>
