<div class="booking-notes-summary">
    <mat-accordion
            class="booking-notes-list-wrapper booking-notes-list include">

        <ng-container>
            <div class="mat-expansion-content-show notes-summary-row">
                <div class="container-fluid direct-client-personal-info-edit-card tc-card-wrapper">

                    <div class="row">

                        <div class="col-md-12 col-lg-12">
                            <div class="">
                                <mat-expansion-panel
                                    #expPanel>

                                    <mat-expansion-panel-header
                                            [collapsedHeight]="'50px'"
                                            [expandedHeight]="'60px'">
                                        <div class="tc-flex-box">
                                            <div class="tc-flex-item tc-item-circle">
                                                <div class="icon-box">
                                                    <i class="material-icons" aria-hidden="true">description</i>
                                                </div>
                                            </div>
                                            <div class="tc-flex-item tc-right-cage">
                                                {{noteList ? noteList.length : ''}} {{'Notes' | translate}}
                                            </div>
                                        </div>
                                    </mat-expansion-panel-header>

                                    <!--<div class="not-expand summary-note">{{summaryContentData.row1col2.value}}</div>-->

                                    <div
                                            class="note-detail container-fluid tc-card-wrapper"
                                            *ngIf="noteList && noteList.length > 0">
                                        <tc-notes
                                                [remarks]="noteList"
                                                (remarksChange)="onNotesModified()">
                                        </tc-notes>

                                    </div>

                                </mat-expansion-panel>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </mat-accordion>

</div>
