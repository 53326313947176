import { Component, AfterViewInit, ViewChild, ViewEncapsulation, ElementRef, HostListener } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { HttpClient } from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';
import {ICellRendererParams} from 'ag-grid-community';

@Component({
    selector: 'multi-select',
    encapsulation: ViewEncapsulation.None,
    //host: {
    //     style: `position: absolute;
		// 			left: 0px;
		// 			top: 0px;
		// 			background-color: transparant;
		// 			`
    // },
    template: `
        <div class="tc-ag-grid__edit">
            <input
                    #input
                    [(ngModel)]="inputValue"
                    (ngModelChange)="processDataInput(inputValue)"
                    [style.width]="params.column.actualWidth + 'px'" class="tc-ag-grid__edit-input">
        </div>
            <ag-grid-angular
                    [style.height]="gridHeight + '%'"
                    [style.max-width]="gridWidth + 'px'"
                    class="ag-theme-material tc-ag-grid__edit-grid"
                    [rowData]="rowData"
                    [columnDefs]="columnDefs"
                    [defaultColDef]="defaultColDef"
                    rowSelection="multiple"
                    [rowMultiSelectWithClick]="true"
                    (gridReady)="onGridReady($event)"
                    (selectionChanged)="onSelectionChanged($event)"
                    (rowClicked)="rowClicked()">
            </ag-grid-angular> 
        
    `
})
export class MultiSelectComponent implements ICellEditorAngularComp, AfterViewInit {
    // variables for agGrid
    public params: any;
    public gridApi: any;
    public rowData: any;
    public columnDefs: [{}];
    public rowSelection: string = 'multiple';
    public columnFilter: any;
    // variables for component
    public returnObject: boolean;
    public cellValue: string;
    public filteredRowData: any;
    public inputValue: string;
    public apiEndpoint: string;
    public gridHeight: number = 100;
    public gridWidth: number = 375;
    public useApi: boolean;
    public propertyName: string;
    public isCanceled: boolean = true;
    public selectedObjects: any[] = [];
    public setValueKey: string='code';

    selectedItems: any[]=[];

    @ViewChild('input') input: ElementRef;

    constructor(
        private httpClient: HttpClient,
        private translateService: TranslateService
    ) {
        this.translateService.onLangChange.subscribe(() => {
            this.gridApi.refreshHeader();
        });
    }

    defaultColDef = {
        resizable: true,
        headerValueGetter: (parameters: ICellRendererParams): string => {
            const headerIdentifier = parameters.colDef.headerName;
            if (headerIdentifier) {
                return this.translateService.instant(headerIdentifier);
            }
            return '';
        }
    };

    ngAfterViewInit() {
        window.setTimeout(() => {
            if (this.inputValue == this.cellValue) {
                this.input.nativeElement.select();
            } else {
                this.input.nativeElement.focus();
            }
            if (this.inputValue && !this.useApi) {
                this.updateFilter();
            }
        });
    }

    // ICellEditorAngularComp functions
    agInit(params: any): void {
        this.params = params;
        /*let days = '';
        for (let i = 0; i < data.length; i++) {
            let dayArray = this.daysOfWeek.find(day => day.key === data.charAt(i)).value;
            days = days.concat(',' + dayArray);
        }
        if (days.startsWith(',')) {days = days.replace(',', '');}
*/
        let dataRow=this.params.data;
        if(dataRow.dayKeys){
            this.selectedItems = dataRow.dayKeys.split('');
        }

        if (!params.rowData) {
            this.apiEndpoint = params.apiEndpoint;
            this.useApi = true;
            this.rowData = [{}];
        } else {
            this.rowData = params.rowData;
        }
        if (params.gridHeight) {
            this.gridHeight = params.gridHeight;
        }
        if (params.gridWidth) {
            this.gridWidth = params.gridWidth;
        }
        this.columnDefs = params.columnDefs;
        this.propertyName = params.propertyRendered;
        this.cellValue = params.rowData[this.propertyName];
        this.setValueKey = params['setValueKey'];
        this.returnObject = params.returnObject;

        if (!params.charPress) {
            if (this.cellValue) {
                this.inputValue = this.cellValue;
            }
        } else {
            this.inputValue = params.charPress;
        }
    }

    getValue(): any {
        this.rowClicked();
        if (!this.returnObject) {
            return this.selectedObjects[this.setValueKey];
        }
        return this.selectedObjects;
    }

    isPopup(): boolean {
        return true;
    }

    isCancelAfterEnd(): boolean {
        return this.isCanceled;
    }

    // ag-Grid functions
    onGridReady(params) {
        this.gridApi = params.api;
        this.gridApi.sizeColumnsToFit();
        this.columnFilter = this.gridApi.getFilterInstance(this.propertyName);
        // this.gridApi.forEachNode((node) => {
        //     if (node.data.value.includes(this.params.data.dayValues)) {
        //         node.setSelected(true);
        //     }
        // });

        this.gridApi.forEachNode((node) => {
            if (this.selectedItems.includes(node.data['key'])) {
                node.setSelected(true);
            }
        });
    }

    // component functions
    rowClicked() {
        if (this.gridApi.getSelectedRows()) {
            let selectedDays = [];
            this.gridApi.getSelectedRows().forEach(params => {
                selectedDays.push(params.value);
            });
            this.selectedObjects = selectedDays;
            this.isCanceled = false;
        }
    }

    rowConfirmed() {
        this.rowClicked();
        this.params.api.stopEditing();
    }

    onSelectionChanged($event) {
        var selectedRows = this.gridApi.getSelectedRows();
        var selectedRowsString = '';
        let selectedDays = [];
        selectedRows.forEach(function(selectedRow, index) {
            if (index !== 0) {
                selectedRowsString += ', ';
            }
            selectedRowsString += selectedRow.value;
            selectedDays.push(selectedRow.value);
        });
        this.selectedObjects = selectedDays;
        // document.querySelector("#selectedRows").innerHTML = selectedRowsString;
    }

    @HostListener('keydown', ['$event'])
    onKeydown(event) {
        event.stopPropagation();
        if (event.key == 'Escape') {
            this.params.api.stopEditing();
            return false;
        }
        if (event.key == 'Enter' || event.key == 'Tab') {
            this.rowConfirmed();
            return false;
        }
        if (event.key == 'ArrowUp' || event.key == 'ArrowDown') {
            this.navigateGrid();
            return false;
        }
    }

    processDataInput(event) {
        if (this.useApi) {
            if (event.length == 0) {
                this.gridApi.setRowData();
            }
            if (event.length == 2) {
                this.getApiData(event).subscribe(data => {
                    this.rowData = data;
                    setTimeout(() => {this.updateFilter();});
                });
            }
            ;
            if (event.length > 2) {
                this.updateFilter();
            }
        } else {
            this.updateFilter();
        }
    }

    getApiData(filter) {
        return this.httpClient.get(this.apiEndpoint + this.toQueryString(filter));
    }

    toQueryString(filter) {
        return '?' + this.propertyName + '=' + filter;
    }

    updateFilter() {
        this.columnFilter.setModel({
            type: 'contains',
            filter: this.inputValue
        });
        this.columnFilter.onFilterChanged();
        if (this.gridApi.getDisplayedRowAtIndex(0)) {
            this.gridApi.getDisplayedRowAtIndex(0).setSelected(true);
            this.gridApi.ensureIndexVisible(0, 'top');
        } else {
            this.gridApi.deselectAll();
        }
    }

    navigateGrid() {
        if (this.gridApi.getFocusedCell() == null || this.gridApi.getDisplayedRowAtIndex(this.gridApi.getFocusedCell().rowIndex) == null) { // check if no cell has focus, or if focused cell is filtered
            this.gridApi.setFocusedCell(this.gridApi.getDisplayedRowAtIndex(0).rowIndex, this.propertyName);
            this.gridApi.getDisplayedRowAtIndex(this.gridApi.getFocusedCell().rowIndex).setSelected(true);
        } else {
            this.gridApi.setFocusedCell(this.gridApi.getFocusedCell().rowIndex, this.propertyName);
            this.gridApi.getDisplayedRowAtIndex(this.gridApi.getFocusedCell().rowIndex).setSelected(true);
        }
    }
}
