import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AgGridCustomDirectivesModule } from '../../../widgets/framework/ag-grid-custom-components/directives/ag-grid-custom-directives.module';
import { AgGridCustomEditorsModule } from '../../../widgets/framework/ag-grid-custom-components/editors/ag-grid-custom-editors.module';
import { AutoCompleteEditorComponent } from '../../../widgets/framework/ag-grid-custom-components/editors/auto-complete-editor/auto-complete-editor.component';
import { DropdownEditorComponent } from '../../../widgets/framework/ag-grid-custom-components/editors/dropdown-editor/dropdown-editor.component';
import { NoteEditorComponent } from '../../../widgets/framework/ag-grid-custom-components/editors/note-editor/note-editor.component';
import { TimeEditorComponent } from '../../../widgets/framework/ag-grid-custom-components/editors/time-editor/time-editor.component';
import { AgGridCustomFiltersModule } from '../../../widgets/framework/ag-grid-custom-components/filters/ag-grid-custom-filters.module';
import { CustomTextFilterComponent } from '../../../widgets/framework/ag-grid-custom-components/filters/custom-text-filter/custom-text-filter.component';
import { ActionsComponent } from '../../../widgets/framework/ag-grid-custom-components/renderers/actions/actions.component';
import { AgGridCustomRenderersModule } from '../../../widgets/framework/ag-grid-custom-components/renderers/ag-grid-custom-renderers.module';
import { TemplateRendererComponent } from '../../../widgets/framework/ag-grid-custom-components/renderers/template-renderer/template-renderer.component';
import { TimeEditorRendererComponent } from '../../../widgets/framework/ag-grid-custom-components/renderers/time-editor-renderer/time-editor-renderer.component';
import { NotesEditorModule } from '../../notes-editor/notes-editor.module';
import { ObjKeysPipePipeModule } from '../../../pipes/obj-keys-pipe-pipe.module';
import { SelectServiceItemsComponent } from '../../select-service-items/select-service-items.component';
import { SelectServiceItemsModule } from '../../select-service-items/select-service-items.module';
import { ResultHeaderModule } from '../../../widgets/shared/result-header/result-header.module';
import { SupplierPriceBreakdownModule } from '../../supplier-price-breakdown/supplier-price-breakdown.module';
import { GenReservationSearchResultsComponent } from './gen-reservation-search-results.component';
import { AgGridModule } from 'ag-grid-angular';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
    declarations: [
        GenReservationSearchResultsComponent
    ],
    imports: [
        CommonModule,
        BrowserAnimationsModule,
        FormsModule,
        ResultHeaderModule,
        MatIconModule,

        ObjKeysPipePipeModule,

        // **** ag grid ***
        AgGridCustomRenderersModule,
        AgGridCustomEditorsModule,
        AgGridCustomDirectivesModule,
        AgGridCustomFiltersModule,
        AgGridModule.withComponents([
            // renderer
            TemplateRendererComponent,
            ActionsComponent,

            // editors
            DropdownEditorComponent,
            AutoCompleteEditorComponent,
            NoteEditorComponent,

            // filters
            CustomTextFilterComponent,
            TimeEditorComponent,
            TimeEditorRendererComponent
        ]),
        MatMenuModule,
        MatListModule,
        MatTooltipModule,
        SelectServiceItemsModule,
        SupplierPriceBreakdownModule,
        NotesEditorModule,
        MatButtonModule,
        TranslateModule
    ],
    exports: [
        GenReservationSearchResultsComponent
    ],
    entryComponents: [
        GenReservationSearchResultsComponent,
        SelectServiceItemsComponent
    ],
    providers:[]
})
export class GenReservationSearchResultsModule {}
