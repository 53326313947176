<div class="row">
    <ng-container>
        <div class="container-fluid">
            <div class="row">
                <div class="col-xs-12 col-sm-3">
                    <mat-form-field class="tc-mt-3">
                        <input
                                matInput
                                [placeholder]="'Adult Count'|translate"
                                [(ngModel)]="adultCount">
                    </mat-form-field>
                </div>
                <div class="col-xs-12 col-sm-3">
                    <mat-form-field class="tc-mt-3">
                        <input
                                matInput
                                [placeholder]="'Teen Count'|translate"
                                [(ngModel)]="teenCount">
                    </mat-form-field>
                </div>
                <div class="col-xs-12 col-sm-3">
                    <mat-form-field class="tc-mt-3">
                        <input
                                matInput
                                [placeholder]="'Children Count'|translate"
                                [(ngModel)]="childCount">
                    </mat-form-field>
                </div>
                <div class="col-xs-12 col-sm-3">
                    <mat-form-field class="tc-mt-3">
                        <input
                                matInput
                                [placeholder]="'Infant Count'|translate"
                                [(ngModel)]="infantCount">
                    </mat-form-field>
                </div>
            </div>

            <div class="row tc-my-4">
                <div class="col-xs-12 col-sm-6">
                    <div class="tc-price--primary">{{'Total Passengers To Generate'|translate}}</div>
                </div>
                <div class="col-xs-12 col-sm-6 text-right">
                    <div class="tc-price tc-price--large tc-price--highlighted">{{getTotalPassengersToGenerate()}}</div>
                </div>
            </div>
        </div>
    </ng-container>
</div>

<div class="modal-footer-area tc-d-block">
    <div class="modal-inner-footer">
        <div class="other-content"><!--Pagination goes here--></div>
        <div class="button-row">
            <button class="button-right tc-button-secondary mat-button" (click)="onClose($event)">
                {{'Close'|translate}}
            </button>
            <button
                    class="button-right tc-button-primary mat-button"
                    (click)="onSave()"
                    [disabled]="!isSaveButtonEnable()">
                {{'Generate'|translate}}
            </button>
        </div>
    </div>
</div>
