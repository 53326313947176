/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../node_modules/@tc/resource/resource.component.ngfactory";
import * as i2 from "@tc/resource/resource.component";
import * as i3 from "@tc-core/util/framework/resource-manager.service";
import * as i4 from "@angular/common";
import * as i5 from "@ngx-translate/core";
import * as i6 from "@tc/pipes/tc-currency-pipe";
import * as i7 from "../../../../../widgets/framework/cg-timeline/timeline/timeline.component.ngfactory";
import * as i8 from "../../../../../widgets/framework/cg-timeline/timeline/timeline.component";
import * as i9 from "./resource-allocation-timeline.component";
import * as i10 from "@tc-core/util/framework/config-loader.service";
var styles_ResourceAllocationTimelineComponent = [];
var RenderType_ResourceAllocationTimelineComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ResourceAllocationTimelineComponent, data: {} });
export { RenderType_ResourceAllocationTimelineComponent as RenderType_ResourceAllocationTimelineComponent };
function View_ResourceAllocationTimelineComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-resource", [], [[8, "id", 0]], null, null, i1.View_ResourceComponent_0, i1.RenderType_ResourceComponent)), i0.ɵdid(1, 114688, null, 0, i2.ResourceComponent, [i3.ResourceManager], { key: [0, "key"], value: [1, "value"], style: [2, "style"], iconType: [3, "iconType"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.summaryContent.icon.key; var currVal_2 = _co.summaryContent.icon.value; var currVal_3 = _co.summaryContent.icon.style; var currVal_4 = _co.summaryContent.icon.iconType; _ck(_v, 1, 0, currVal_1, currVal_2, currVal_3, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "", _co.summaryContent.icon.key, ""); _ck(_v, 0, 0, currVal_0); }); }
function View_ResourceAllocationTimelineComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "tc-strip-text"], ["id", "RESOURCE_NAME"]], null, null, null, null, null)), i0.ɵdid(1, 278528, null, 0, i4.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i0.ɵted(2, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "tc-strip-text"; var currVal_1 = _co.row1col1_customsStyles; _ck(_v, 1, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = ((_co.summaryContent == null) ? null : _co.summaryContent.resourceName); _ck(_v, 2, 0, currVal_2); }); }
function View_ResourceAllocationTimelineComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "tc-strip-text"], ["id", "supplierName"]], null, null, null, null, null)), i0.ɵdid(1, 278528, null, 0, i4.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i0.ɵted(2, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "tc-strip-text"; var currVal_1 = _co.row2col1_customsStyles; _ck(_v, 1, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = ((_co.summaryContent == null) ? null : _co.summaryContent.supplierName); _ck(_v, 2, 0, currVal_2); }); }
function View_ResourceAllocationTimelineComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "tc-flex-item"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ResourceAllocationTimelineComponent_3)), i0.ɵdid(2, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ResourceAllocationTimelineComponent_4)), i0.ɵdid(4, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.summaryContent == null) ? null : _co.summaryContent.resourceName); _ck(_v, 2, 0, currVal_0); var currVal_1 = ((_co.summaryContent == null) ? null : _co.summaryContent.supplierName); _ck(_v, 4, 0, currVal_1); }, null); }
function View_ResourceAllocationTimelineComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "tc-strip-text"], ["id", "SUPPLIER_TOTAL_COST"]], null, null, null, null, null)), i0.ɵdid(1, 278528, null, 0, i4.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i0.ɵted(2, null, ["", " "])), i0.ɵppd(3, 4)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "tc-strip-text"; var currVal_1 = _co.row1col4_customsStyles; _ck(_v, 1, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = i0.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i0.ɵnov(_v.parent.parent, 0), ((_co.summaryContent == null) ? null : ((_co.summaryContent.calculatedCost == null) ? null : _co.summaryContent.calculatedCost.totalCost)), ((_co.currencyFormat == null) ? null : _co.currencyFormat.currencyCode), "code", ((_co.currencyFormat == null) ? null : _co.currencyFormat.pattern))); _ck(_v, 2, 0, currVal_2); }); }
function View_ResourceAllocationTimelineComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "tc-strip-text"], ["id", "SUPPLIER_RESOURCE_COST"]], null, null, null, null, null)), i0.ɵdid(1, 278528, null, 0, i4.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i0.ɵted(2, null, ["", " ", " "])), i0.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i0.ChangeDetectorRef]), i0.ɵppd(4, 4)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "tc-strip-text"; var currVal_1 = _co.row2col4_customsStyles; _ck(_v, 1, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform("Resource Cost:")); var currVal_3 = i0.ɵunv(_v, 2, 1, _ck(_v, 4, 0, i0.ɵnov(_v.parent.parent, 0), ((_co.summaryContent == null) ? null : _co.summaryContent.calculatedCost.unitCost), ((_co.currencyFormat == null) ? null : _co.currencyFormat.currencyCode), "code", ((_co.currencyFormat == null) ? null : _co.currencyFormat.pattern))); _ck(_v, 2, 0, currVal_2, currVal_3); }); }
function View_ResourceAllocationTimelineComponent_8(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "tc-strip-text"], ["id", "SUPPLIER_PERSON_COST"]], null, null, null, null, null)), i0.ɵdid(1, 278528, null, 0, i4.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i0.ɵted(2, null, ["", " ", " | Cld: ", " | Inf: ", " "])), i0.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = "tc-strip-text"; var currVal_1 = _co.row2col4_customsStyles; _ck(_v, 1, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform("Adt:")); var currVal_3 = ((_co.summaryContent == null) ? null : ((_co.summaryContent.calculatedCost == null) ? null : _co.summaryContent.calculatedCost.totalAdultCost.toFixed(2))); var currVal_4 = ((_co.summaryContent == null) ? null : ((_co.summaryContent.calculatedCost == null) ? null : _co.summaryContent.calculatedCost.totalChildCost.toFixed(2))); var currVal_5 = ((_co.summaryContent == null) ? null : ((_co.summaryContent.calculatedCost == null) ? null : _co.summaryContent.calculatedCost.totalInfantCost.toFixed(2))); _ck(_v, 2, 0, currVal_2, currVal_3, currVal_4, currVal_5); }); }
function View_ResourceAllocationTimelineComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "div", [["class", "col-xs-12 col-sm-12 col-md-2"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ResourceAllocationTimelineComponent_6)), i0.ɵdid(2, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ResourceAllocationTimelineComponent_7)), i0.ɵdid(4, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ResourceAllocationTimelineComponent_8)), i0.ɵdid(6, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(7, 0, null, null, 2, "a", [["class", "rs-r-align tc-link__value tc-link__value--regular"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onResourceCalendarClick(_co.summaryContent) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(8, null, ["", ""])), i0.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.summaryContent == null) ? null : ((_co.summaryContent.calculatedCost == null) ? null : _co.summaryContent.calculatedCost.totalCost)); _ck(_v, 2, 0, currVal_0); var currVal_1 = ((_co.summaryContent == null) ? null : _co.summaryContent.calculatedCost.unitCost); _ck(_v, 4, 0, currVal_1); var currVal_2 = !((_co.summaryContent == null) ? null : _co.summaryContent.calculatedCost.unitCost); _ck(_v, 6, 0, currVal_2); }, function (_ck, _v) { var currVal_3 = i0.ɵunv(_v, 8, 0, i0.ɵnov(_v, 9).transform("Resource Calendar")); _ck(_v, 8, 0, currVal_3); }); }
export function View_ResourceAllocationTimelineComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i6.TcCurrencyPipe, [i4.CurrencyPipe]), (_l()(), i0.ɵeld(1, 0, null, null, 15, "div", [["class", "container-fluid content-summary-card"]], null, null, null, null, null)), i0.ɵdid(2, 278528, null, 0, i4.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(3, { "content-summary-card--warning": 0, "content-summary-card--danger": 1 }), (_l()(), i0.ɵeld(4, 0, null, null, 12, "div", [["class", "row  tc-item--center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 6, "div", [["class", "col-xs-12 col-sm-12 col-md-2"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 5, "div", [["class", "tc-flex-box"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 2, "div", [["class", "tc-flex-item"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ResourceAllocationTimelineComponent_1)), i0.ɵdid(9, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ResourceAllocationTimelineComponent_2)), i0.ɵdid(11, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(12, 0, null, null, 2, "div", [["class", "col-xs-12 col-sm-12 col-md-8"], ["style", "padding-bottom: 2px;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(13, 0, null, null, 1, "tc-timeline", [], null, [[null, "targetClick"], [null, "eventClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("targetClick" === en)) {
        var pd_0 = (_co.onTargetClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("eventClick" === en)) {
        var pd_1 = (_co.onEventClick($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i7.View_TimelineComponent_0, i7.RenderType_TimelineComponent)), i0.ɵdid(14, 114688, null, 0, i8.TimelineComponent, [], { summaryContent: [0, "summaryContent"], isTargetBlock: [1, "isTargetBlock"], targetPointTime: [2, "targetPointTime"], targetBlockTimeStart: [3, "targetBlockTimeStart"], targetBlockTimeEnd: [4, "targetBlockTimeEnd"], events: [5, "events"], leftBufferHours: [6, "leftBufferHours"], rightBufferHours: [7, "rightBufferHours"] }, { eventClick: "eventClick", targetClick: "targetClick" }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ResourceAllocationTimelineComponent_5)), i0.ɵdid(16, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(17, 0, null, null, 0, "hr", [], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "container-fluid content-summary-card"; var currVal_1 = _ck(_v, 3, 0, _co.isUpdated, _co.isCancelled); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _co.summaryContent.icon; _ck(_v, 9, 0, currVal_2); var currVal_3 = ((_co.summaryContent == null) ? null : _co.summaryContent.resourceName); _ck(_v, 11, 0, currVal_3); var currVal_4 = _co.summaryContent; var currVal_5 = _co.isBulkAssignment; var currVal_6 = _co.targetPointTime; var currVal_7 = _co.targetBlockTimeStart; var currVal_8 = _co.targetBlockTimeEnd; var currVal_9 = _co.timelineEvents; var currVal_10 = _co.leftBufferHours; var currVal_11 = _co.rightBufferHours; _ck(_v, 14, 0, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11); var currVal_12 = ((_co.summaryContent == null) ? null : _co.summaryContent.calculatedCost); _ck(_v, 16, 0, currVal_12); }, null); }
export function View_ResourceAllocationTimelineComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-resource-allocation-timeline", [], null, null, null, View_ResourceAllocationTimelineComponent_0, RenderType_ResourceAllocationTimelineComponent)), i0.ɵdid(1, 376832, null, 0, i9.ResourceAllocationTimelineComponent, [i10.ConfigLoader, i0.KeyValueDiffers], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ResourceAllocationTimelineComponentNgFactory = i0.ɵccf("tc-resource-allocation-timeline", i9.ResourceAllocationTimelineComponent, View_ResourceAllocationTimelineComponent_Host_0, { summaryContent: "summaryContent", iconConfig: "iconConfig", isRow1col4String: "isRow1col4String", itemGroup: "itemGroup", itemList: "itemList", assignedVehicle: "assignedVehicle", isBulkAssignment: "isBulkAssignment" }, { resourceCalendarClick: "resourceCalendarClick", eventClick: "eventClick", targetClick: "targetClick" }, []);
export { ResourceAllocationTimelineComponentNgFactory as ResourceAllocationTimelineComponentNgFactory };
