import { Component, Input, OnInit } from '@angular/core';
import { TC } from '@tc-core/util/constants';
import { ConfigLoader } from '@tc-core/util/framework';
import { TCO } from '../../../constants';
import { Grade, Supplier, SupplierItems } from '../../../models/supplier/supplier';

@Component({
    selector: 'tc-supplier-personal-info-card',
    templateUrl: './supplier-personal-info-card.component.html'
})
export class SupplierPersonalInfoCardComponent implements OnInit
{
    EMPTY_CHAR = TC.FORMAT.EMPTY_CHAR;
    CLIENT_CONTACTS_CONST = TC.CLIENT_CONTACT_TYPE;
    isActive = false;
    maxGrade = 10;
    grades: Grade[];

    @Input() supplier: Supplier;
    @Input() resourceTypes: any[];
    // @Input() nameCodeMap: Map<string, string>;
    // @Input() authConfigComponent: string = 'tc-trade-client-personal-info-card';

    placeHolders: { id: string, title: string }[];

    constructor(private configLoader: ConfigLoader) { }

    ngOnInit()
    {
        const placeHolderConfig = this.configLoader.configurations.get(TCO.CONF.CONF_SUPPLIER_DETAIL_VIEW_CONFIG).placeHolderConfig;
        if (placeHolderConfig) {
            this.placeHolders = placeHolderConfig.personalInfoCard;
        }

        const gradeConfig = this.configLoader.configurations.get(TCO.CONF.CONF_GRADES_CONFIG);
        if (gradeConfig.grade_values) {
            this.grades = gradeConfig.grade_values.sort((a, b) => (a.order > b.order ? 1 : b.order > a.order ? -1 : 0));
        }
        this.maxGrade = gradeConfig.max_grade ? gradeConfig.max_grade : 10;

    }

    getGradeValue(grade: number | undefined): string
    {
        if (grade && grade !== 0) {
            return this.grades[Math.ceil((grade / this.maxGrade) * this.grades.length) - 1].displayName;
        }
        return this.EMPTY_CHAR;
    }

    getSupplierItems(supplierItems: SupplierItems[]): string
    {
        if (this.resourceTypes && this.resourceTypes.length > 0) {
            const supplierItemNames = [];
            supplierItems.forEach(suppItem => {
                const resource = this.resourceTypes.filter(resType => resType.code === suppItem.code);
                if (resource && resource.length > 0) {
                    supplierItemNames.push(resource[0].name);
                }
            });
            return supplierItemNames.join(',\n');
        }
    }

    // getContact(address: TravellerAddress, type: String) {
    //     if (address) {
    //         for (const contact of address.contacts) {
    //             if (contact.contactType.toString() === type) {
    //                 return contact.value;
    //             }
    //         }
    //
    //     }
    // }
    //
    // getName(code: string) {
    //     if (this.nameCodeMap) {
    //         return this.nameCodeMap.get(code);
    //     }
    // }

    getCardPlaceHolders(id: string): { id: string, title: string }
    {
        // return SupplierConfigLoader.getCardPlaceHolders(id, this.placeHolders, this.getDefaultPlaceHolderFn);
        let placeHolder = this.placeHolders.find(ph => ph.id === id);
        if (!placeHolder) {
            placeHolder = this.getDefaultPlaceHolderFn(id);
        }
        return placeHolder;
    }

    getDefaultPlaceHolderFn(id: string): { id: string, title: string }
    {
        if (id === 'STREET_ADDRESS') {
            return {id: 'STREET_ADDRESS', title: 'Street'};
        } else if (id === 'CITY') {
            return {id: 'CITY', title: 'City'};
        }
        return {id: 'default', title: ''};
    }
}
