import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder} from '@angular/forms';
import { CurrencyFormat } from '@tc-core/model/it/codegen/ui/framework/currency-format';
import { SummaryCardData } from '@tc-core/model/it/codegen/ui/framework/summary-card-data';
import { TC } from '@tc-core/util/constants';
import { ConfigLoader } from '@tc-core/util/framework/config-loader.service';
import { TCO } from '../../../constants';
import { Season } from '../../../models/supplier/season';
import { TrsResourceCost } from '../../../models/supplier/trs-resource-cost-setup';

@Component({
    selector: 'tc-transport-cost-setup-summary-card',
    templateUrl: './transport-cost-setup-summary-card.component.html'
})
export class TransportCostSetupSummaryCardComponent implements OnInit {

    @Input() isEditable: boolean = false;
    @Input() isNew: boolean = false;
    @Input() enableCodeInput: boolean = false;
    @Input() seasonSetup: Season[];
    @Input() selectedResourceCostContent: SummaryCardData;
    @Input() daysOfWeek: any[];
    @Input() transferModes: any[] = [];
    @Input() routes: any[] = [];
    @Input() selectedResourceCost: TrsResourceCost;
    @Input() isSelectable: boolean = true;

    @Output() selectedItem: any = new EventEmitter();
    @Output() deSelectedItem: any = new EventEmitter();
    @Output() validity: any = new EventEmitter();
    @Output() editClick: EventEmitter<any> = new EventEmitter();
    @Output() undoClick: EventEmitter<any> = new EventEmitter();
    @Output() saveClick: EventEmitter<any> = new EventEmitter();
    @Output() deleteClick: EventEmitter<any> = new EventEmitter();
    @Output() changeFormValidity: EventEmitter<boolean> = new EventEmitter();
    @Output() checkChanged: EventEmitter<any> = new EventEmitter();

    currencyFormat: CurrencyFormat;


    constructor(private configLoader: ConfigLoader) { }

    ngOnInit() {
        this.currencyFormat = this.getCurrencyFormat();
    }

    onDeleteClick(event) {
        this.deleteClick.emit(this.selectedResourceCost.tempCostId);
    }
    onEditClick(event) {
        this.editClick.emit(this.selectedResourceCost);
    }

    private getCurrencyFormat(): CurrencyFormat {
        let currencyFormat;
        let currencySymbolPattern = this.configLoader.configurations.get(TC.CONF.CONF_SYSTEM).defaults.default_currency_symbol_pattern;
        currencyFormat = new CurrencyFormat(TCO.AppData.DEF_CURRENCY_TYPE, currencySymbolPattern);
        return currencyFormat;
    }
}
