export class ResourceCost {
    supplierId: number;
    seasonId: number;
    days: string;
    costingType: string;
    currency: string;
    seasonName: string;
    adultFare: number;
    childFare: number;
    infantFare: number;
    teenFare: number;
    unitFare: number;
    single: number;
    twin: number;
    extraBed: number;
    selected: boolean;
}
