import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { TC } from '@tc-core/util/constants';
import { ConfigLoader } from '@tc-core/util/framework';
import { TCO } from '../../../constants';
import { ContactDetails } from '../../../models/profile/contact-details';
import { MasterDataRestService } from '../../../services/backend-consumers/master-data-handler-service/master-data-rest.service';
import { DataKey, DataStoreService } from '../../../services/util/framework/data-store.service';
import { ResponseUtil } from '../../../services/util/response/response-util.service';

@Component({
    selector: 'tc-contact-details-card',
    templateUrl: './contact-details-card.component.html'
})
export class ContactDetailsCardComponent implements OnInit, OnChanges
{

    EMPTY_CHAR = TC.FORMAT.EMPTY_CHAR;

    @Input() contactDetails: ContactDetails[];
    @Input() showTitle: boolean = true;

    contact: ContactDetails = new ContactDetails();
    cityList: any[] = [];
    countryList: any[] = [];

    placeholders: { id: string, title: string }[];

    constructor(
        private dataStore: DataStoreService,
        private configLoader: ConfigLoader,
        private masterDataRestService: MasterDataRestService
    )
    { }

    ngOnInit()
    {
        this.getCityList();
        this.getCountryList();

        const placeHolderConfig = this.configLoader.configurations.get(TCO.CONF.CONF_SUPPLIER_DETAIL_VIEW_CONFIG).placeHolderConfig;
        if (placeHolderConfig) {
            this.placeholders = placeHolderConfig.contactInfoCard;
        }
    }

    public ngOnChanges(changes: SimpleChanges): void
    {
        for (let propName in changes) {
            if (propName === 'contactDetails') {
                if (this.contactDetails) {
                    this.contactDetails.forEach(value => {
                        if (value.status) {
                            this.contact = value;
                        }
                    });
                }
            }
        }
    }

    getCityName(code: any) {
        let type: any;
        if(this.cityList)
        {
            type = this.cityList.find(value => value.code === code);
        }
        return type && type.name ? type.name : code;
    }

    getCountryName(code: any) {
        let type: any;
        if(this.countryList)
        {
            type = this.countryList.find(value => value.code === code);
        }
        return type && type.name ? type.name : code;
    }

    private getCityList() {
        this.dataStore.get(DataKey.cityList).subscribe(value => {
            if(value)
            {
                this.cityList = value;
            }
        });
    }

    private getCountryList() {
        this.dataStore.get(DataKey.countryList).subscribe(value => {
            if(value)
            {
                this.countryList = value;
            }
        });
    }

    getCardPlaceHolders(id: string): { id: string, title: string }
    {
        let placeHolder = this.placeholders.find(ph => ph.id === id);
        if (!placeHolder) {
            placeHolder = this.getDefaultPlaceHolderFn(id);
        }
        return placeHolder;
        // return SupplierConfigLoader.getCardPlaceHolders(id, this.placeholders, this.getDefaultPlaceHolderFn);
    }

    getDefaultPlaceHolderFn(id: string): { id: string, title: string }
    {
        if (id === 'STREET_ADDRESS') {
            return {id: 'STREET_ADDRESS', title: 'Address'};
        } else if (id === 'CITY') {
            return {id: 'CITY', title: 'City'};
        }
        return {id: 'default', title: ''};
    }
}
