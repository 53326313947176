import * as tslib_1 from "tslib";
import { formatDate } from '@angular/common';
import { Injector, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TcErrorType } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-error';
import { DialogModel, LEVEL } from '@tc-core/model/it/codegen/ui/framework/dialog-model';
import { ModalData } from '@tc-core/model/it/codegen/ui/framework/modal-data';
import { Pager } from '@tc-core/model/it/codegen/ui/framework/pager';
import { SorterService } from '@tc-core/service/sorters/sorter.service';
import { TC } from '@tc-core/util/constants';
import { ConfigLoader } from '@tc-core/util/framework/config-loader.service';
import { EventManager } from '@tc-core/util/framework/event-manager.service';
import { FocusViewManager } from '@tc-core/util/framework/focus-view-manager.service';
import { UserJourneyManager } from '@tc-core/util/framework/user-journey-manager.service';
import { CommonHelper } from '@tc-core/util/helpers/common-helper.service';
import { DateFormatter } from '@tc-core/util/system/date-formatter.service';
import { AutoScrollService } from '@tc-core/util/ui/auto-scroll.service';
import { SpinnerService } from '@tc-core/util/ui/spinner.service';
import { DialogService } from '@tc/dialog/dialog.service';
import { FocusViewService } from '@tc/focus-view/focus-view.service';
import { ModalService } from '@tc/modal/modal.service';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { AssignmentFocusViewComponent } from '../../assignment-focus-view/assignment-focus-view.component';
import { TCO } from '../../../constants';
import { DocumentCreationModalComponent } from '../../document-creation-modal/document-creation-modal.component';
import { DocumentCreationCriteria, DocumentFormat, DocumentType } from '../../../models/criteria/document-creation-criteria';
import { DocumentDataCriteria } from '../../../models/criteria/document-data-criteria';
import { GenericSearchCriteria } from '../../../models/criteria/generic-search-criteria';
import { ReservationSearchCriteria } from '../../../models/criteria/reservation-search-criteria';
import { SupplierSearchCriteria } from '../../../models/criteria/supplier-search-criteria';
import { VehicleSearchCriteria } from '../../../models/criteria/vehicle-search-criteria';
import { DocumentCreationModel } from '../../../models/helper/document-creation-model';
import { ResourceAllocationEvent } from '../../../models/helper/resource-allocation-event';
import { ServiceItemChanges } from '../../../models/helper/service-item-changes';
import { LocationType } from '../../../models/master-data/location-type';
import { ResourceType } from '../../../models/reservation/assignment';
import { AssignmentSaveOperation } from '../../../models/reservation/assignment-save-operation';
import { AssignmentStatus } from '../../../models/reservation/assignment-status';
import { NoteWrapper } from '../../../models/reservation/note-wrapper';
import { ServiceAssignment } from '../../../models/reservation/service-assignment';
import { ReservationStatus, ServiceItem } from '../../../models/reservation/service-item';
import { ServiceNote } from '../../../models/reservation/service-note';
import { ResourceAvailability } from '../../../models/resource/resource-availability';
import { DocumentService } from '../../../services/backend-consumers/documents/document.service';
import { DataHandlerService } from '../../../services/backend-consumers/master-data-handler-service/data-handler.service';
import { ReservationManagementService } from '../../../services/backend-consumers/reservation-management-services/reservation-management.service';
import { TrsReservationManagementService } from '../../../services/backend-consumers/reservation-management-services/trs-reservation-management.service';
import { GenericResourceService } from '../../../services/backend-consumers/supplier-service/generic-resource.service';
import { SupplierService } from '../../../services/backend-consumers/supplier-service/supplier.service';
import { TransportResourceService } from '../../../services/backend-consumers/supplier-service/transport-resource.service';
import { CostCalculationUtilsService } from '../../../services/business-utils/cost-calculation/cost-calculation-utils.service';
import { ServiceAssignmentUtilService } from '../../../services/business-utils/service-item-utils/service-assignment-util.service';
import { ChangeDetectorService } from '../../../services/util/change-detector/change-detector.service';
import { DMCCommon } from '../../../services/util/common/dmc-common';
import { UtilService } from '../../../services/util/common/util.service';
import { RootService } from '../../../services/util/core-services/root.service';
import { DataKey, DataStoreService } from '../../../services/util/framework/data-store.service';
import { DMCQueryParamsService } from '../../../services/util/framework/dmc-query-params.service';
import { IdentifierService } from '../../../services/util/identifier/identifier.service';
import { ResponseUtil } from '../../../services/util/response/response-util.service';
import { ContentSummaryCardService } from '../../../services/util/ui/content-summary-card.service';
import { AutoAssignedSummaryWrapperComponent } from '../auto-assigned-summary-wrapper/auto-assigned-summary-wrapper.component';
import { LeadPassengerSelectionWrapperComponent } from '../lead-passenger-selection-wrapper/lead-passenger-selection-wrapper.component';
import { SearchCriteriaSaveModalComponent } from '../search-criteria-save-modal/search-criteria-save-modal.component';
import { ServiceItemDetailFocusViewComponent } from '../service-item-detail-focus-view/service-item-detail-focus-view.component';
import { SplitPassengersViewWrapperComponent } from '../split-passengers-view-wrapper/split-passengers-view-wrapper.component';
var ReservationSearchResultsComponent = /** @class */ (function () {
    function ReservationSearchResultsComponent(userJourneyManager, configLoader, trsReservationManagementService, identifierService, reservationManagementService, spinnerService, injector, route, em, sorterService, utilService, dateFormatter, dataStore, summaryCardService, modalService, dialogService, supplierService, changeDetector, commonHelper, common, focusViewManager, costCalculationUtilsService, focusViewService, transportResourceService, genericResourceService, rootService, queryParamService, dataHandlerService, autoScrollService, documentService) {
        this.userJourneyManager = userJourneyManager;
        this.configLoader = configLoader;
        this.trsReservationManagementService = trsReservationManagementService;
        this.identifierService = identifierService;
        this.reservationManagementService = reservationManagementService;
        this.spinnerService = spinnerService;
        this.injector = injector;
        this.route = route;
        this.em = em;
        this.sorterService = sorterService;
        this.utilService = utilService;
        this.dateFormatter = dateFormatter;
        this.dataStore = dataStore;
        this.summaryCardService = summaryCardService;
        this.modalService = modalService;
        this.dialogService = dialogService;
        this.supplierService = supplierService;
        this.changeDetector = changeDetector;
        this.commonHelper = commonHelper;
        this.common = common;
        this.focusViewManager = focusViewManager;
        this.costCalculationUtilsService = costCalculationUtilsService;
        this.focusViewService = focusViewService;
        this.transportResourceService = transportResourceService;
        this.genericResourceService = genericResourceService;
        this.rootService = rootService;
        this.queryParamService = queryParamService;
        this.dataHandlerService = dataHandlerService;
        this.autoScrollService = autoScrollService;
        this.documentService = documentService;
        // service item groups
        this.serviceItemGroups = [];
        this.serviceItemGroupsDataForCards = [];
        this.serviceItemGroupCards = [];
        this.serviceItemsInSelectedGroupCards = [];
        this.selectedServiceGroupNotes = [];
        this.addingServiceNoteCount = 0;
        this.footerDataMap = new Map();
        this.isSearchResultLoaded = false;
        this.showEditBtn = true;
        this.transferModes = [];
        this.groupingOptions = [];
        this.groupingOptionsRowData = [];
        this.searchCriteria = new ReservationSearchCriteria();
        this.unSavedAssignments = [];
        // isFiltered = false;
        this.isGroupNoteEditClicked = false;
        this.isRequestCompleted = true;
        this.autoCompleteClicked = false;
        this.defaultPageResultCount = 10;
        this.selectionIndex = '';
        this.fetchedReservationsCount = 0;
        this.dataFromMoreClick = false;
        this.paginatorHasMore = true;
        this.isCleared = false;
        this.resultCount = 0;
        this.serviceItemChangesInSelectedGroup = new Map();
        this.savedServiceItems = [];
        this.selectedGroupCriteria = null;
        this.filteredVehicleSuppliers = [];
        this.filteredDriverSuppliers = [];
        this.filteredVehicles = [];
        this.filteredDrivers = [];
        this.changesAvailableForServiceItems = false;
        this.isExportEnable = true;
        this.baseURL = this.configLoader.configurations.get(TCO.CONF.CONF_ENDPOINT).base_url;
        this.defaultVehicleModel = 'ANY';
        this.isFocusViewDataChanged = false;
        this.isPopupDataChanged = false;
        this.unSavedDataAvailable = false;
        this.exit = false;
        this.assignmentList = [];
        this.addCriteriaNameSubscription = new Subscription();
        this.reservationResultsSubscription = new Subscription();
        this.reservationResultsFromServiceSubscription = new Subscription();
        this.reservationResultsFromServiceOnMoreSubscription = new Subscription();
        this.reservationSearchCriteriaSubscription = new Subscription();
        this.pickupDropOffModalCloseSubscription = new Subscription();
        this.splitPassengersModalCloseSubscription = new Subscription();
        this.leadPassengerModalCloseSubscription = new Subscription();
        this.focusviewCloseSubject = new Subscription();
        this.focusViewDataChangeObserver = new Subscription();
        this.popupDataChangeObserver = new Subscription();
        this.vehicleResultObsv = new Subscription();
        this.selectedGroupItemsObserver = new Subscription();
    }
    ReservationSearchResultsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.tReservations = this.configLoader.configurations.get(TCO.CONF.CONF_RESERVATION_SEARCH_RESULTS_CONFIG);
        this.tServiceItems = this.configLoader.configurations.get(TCO.CONF.CONF_RESERVATION_SERVICE_ITEMS_CARD_CONFIG);
        this.tPagination = this.configLoader.configurations.get(TCO.CONF.CONF_PAGINATION_CONFIG)['RESERVATION_SEARCH'];
        this.setupPaginator();
        this.subscribeSearchCriteria();
        this.subscribeSearchResults(); // search results at first
        this.subscribeOnMoreSearchResults(); // search results when click more
        this.subscribeSearchResultList(); // concat search results list
        this.createSorters();
        this.getSelectedGroup();
        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'GENERATE_DOCUMENTS').subscribe(function (e) {
            _this.onGenerateDocumentsClick();
        });
        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'EXPORT').subscribe(function (e) {
            _this.export('', false, true);
        });
        this.em.addEvent(TCO.CONF.CONF_EVENT_MANAGER, 'SAVE_CRITERIA').subscribe(function (e) {
            _this.onSaveSearchCriteriaClick();
        });
        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'SEND_MANIFESTS').subscribe(function (e) {
            _this.sendManifests();
        });
        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'ARRIVAL_LIST').subscribe(function (e) {
            _this.getArrivalList('', false, true);
        });
        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'DEPARTURE_LIST').subscribe(function (e) {
            _this.getDepartureList('', false, true);
        });
    };
    // paginator
    ReservationSearchResultsComponent.prototype.setupPaginator = function () {
        this.defaultPageResultCount = this.tPagination['defaultPageResultCount'];
        this.pager = new Pager(0, this.defaultPageResultCount);
    };
    ReservationSearchResultsComponent.prototype.onPage = function (pageNumber) {
        this.pager.currPage = pageNumber;
        this.selectionIndex = (this.pager.currPage * this.pager.itemsPerPage).toString();
    };
    ReservationSearchResultsComponent.prototype.onSelection = function (event) {
        this.selectionIndex = event;
    };
    ReservationSearchResultsComponent.prototype.onMore = function () {
        this.dataFromMoreClick = true;
        if (this.tPagination && this.tPagination['defaultPageResultCount'] && this.tPagination['fetchingPages']) {
            this.searchCriteria.size = this.tPagination['fetchingPages'] * this.tPagination['defaultPageResultCount'];
            this.searchCriteria.start = this.fetchedReservationsCount;
            this.searchOnMore(this.searchCriteria);
        }
    };
    ReservationSearchResultsComponent.prototype.setPageZero = function () {
        if (this.pager) {
            if (!this.dataFromMoreClick) {
                this.pager.currPage = 0;
            }
            else {
                this.dataFromMoreClick = false;
            }
        }
        else {
            this.pager = new Pager(0, this.defaultPageResultCount);
        }
    };
    // -- paginator--
    ReservationSearchResultsComponent.prototype.searchOnMore = function (searchCriteria) {
        this.dataStore.set(DataKey.reservationSearchResultsFromServiceOnMore, null, true);
        this.trsReservationManagementService.searchTrsItemGroupsOnMore(searchCriteria);
    };
    ReservationSearchResultsComponent.prototype.search = function (searchCriteria) {
        this.dataStore.set(DataKey.reservationSearchResultsFromService, null, true);
        this.trsReservationManagementService.searchTrsItemGroups(searchCriteria);
    };
    ReservationSearchResultsComponent.prototype.subscribeSearchResults = function () {
        var _this = this;
        this.reservationResultsFromServiceSubscription = this.dataStore.get(DataKey.reservationSearchResultsFromService)
            .subscribe(function (data) {
            _this.resultCount = 0;
            if (_this.searchCriteria.transferMode !== null) {
                // this.isFiltered = true;
            }
            if (data) {
                var trsItemGroups = ResponseUtil.getDataArray(data);
                _this.resultCount = ResponseUtil.getTotalCount(data);
                _this.dataStore.set(DataKey.reservationSearchResults, trsItemGroups, true);
            }
        });
    };
    ReservationSearchResultsComponent.prototype.subscribeOnMoreSearchResults = function () {
        var _this = this;
        this.reservationResultsFromServiceOnMoreSubscription = this.dataStore.get(DataKey.reservationSearchResultsFromServiceOnMore)
            .subscribe(function (data) {
            if (data) {
                _this.resultCount = 0;
                var trsItemGroups = ResponseUtil.getDataArray(data);
                trsItemGroups = _this.serviceItemGroups.concat(trsItemGroups);
                _this.resultCount = ResponseUtil.getTotalCount(data);
                _this.dataStore.set(DataKey.reservationSearchResults, trsItemGroups, true);
            }
        });
    };
    ReservationSearchResultsComponent.prototype.subscribeSearchCriteria = function () {
        var _this = this;
        this.reservationSearchCriteriaSubscription = this.dataStore.get(DataKey.reservationSearchCriteria)
            .subscribe(function (data) {
            if (data !== null) {
                _this.searchCriteria = data;
            }
        });
    };
    ReservationSearchResultsComponent.prototype.subscribeSearchResultList = function () {
        var _this = this;
        this.fetchedReservationsCount = 0;
        this.reservationResultsSubscription = this.dataStore.get(DataKey.reservationSearchResults)
            .subscribe(function (data) {
            _this.serviceItemGroups = [];
            if (data != null) {
                _this.spinnerService.hide();
            }
            if (data) {
                _this.serviceItemGroups = data;
                _this.fetchedReservationsCount = _this.serviceItemGroups.length;
                if (_this.fetchedReservationsCount > 0) {
                    _this.enableExportButton();
                    _this.userJourneyManager.enableQuickActions([
                        'TRIGGER_EXPORT_FILE'
                    ]);
                }
                _this.getServiceItemGroupSummaryCards();
            }
            else {
                _this.serviceItemGroupCards = [];
                _this.heading = '';
            }
        });
    };
    ReservationSearchResultsComponent.prototype.enableExportButton = function () {
        this.userJourneyManager.canProceed.next(true);
        this.focusViewManager.canProceed.next(true);
        this.isExportEnable = true;
    };
    ReservationSearchResultsComponent.prototype.getServiceItemGroupSummaryCards = function () {
        if (this.serviceItemGroups.length !== null) {
            var reservations_1 = this.serviceItemGroups;
            if (Array.isArray(reservations_1)) {
                var unique_tms = reservations_1.filter(function (e, i) { return reservations_1.findIndex(function (a) { return a.transferMode === e.transferMode; }) === i; }).map(function (a) { return a.transferMode; });
                this.loadResultsHeader(unique_tms);
            }
            var serviceItemGroupsDataArray = [];
            var _loop_1 = function (i) {
                var transferMode = reservations_1[i].transferMode;
                var dateStart = reservations_1[i].startTime;
                var serviceDate = this_1.dateFormatter.dateAsString(new Date(reservations_1[i].serviceDate), 'DD MMM YY');
                var route = reservations_1[i].route;
                var pickupLocations = reservations_1[i].pickups;
                var allocationsLeft = reservations_1[i].remainingCount;
                var endDate = reservations_1[i].endTime;
                var arrivalDateTime = dateStart + '-' + endDate;
                var passengerCount = reservations_1[i].passengerCount;
                var dropOffLocations = reservations_1[i].dropOffs;
                var allocatedCount = reservations_1[i].allocatedCount;
                var groupStatus = reservations_1[i].groupStatus;
                var reservationStatus = reservations_1[i].reservationStatus;
                var transferModeObj = this_1.transferModes.find(function (value) {
                    if (value['code'] && value['code']['code'] === transferMode) {
                        return true;
                    }
                    else {
                        return false;
                    }
                });
                if (transferModeObj && transferModeObj['code']) {
                    transferModeObj = transferModeObj['code'];
                }
                serviceItemGroupsDataArray.push({
                    'type': transferMode,
                    'serviceDate': serviceDate,
                    'route': route,
                    'pickupLocations': this_1.getPickupLocations(pickupLocations),
                    'allocationsLeft': allocationsLeft,
                    'arrivalDateTime': arrivalDateTime,
                    'passengerCount': passengerCount,
                    'dropOffLocations': this_1.getDropOffLocations(dropOffLocations),
                    'allocatedCount': allocatedCount,
                    'groupStatus': groupStatus,
                    'reservationStatus': reservationStatus,
                    'transferModeObj': transferModeObj,
                    'rawData': reservations_1[i]
                });
            };
            var this_1 = this;
            for (var i = 0; i < reservations_1.length; i++) {
                _loop_1(i);
            }
            this.serviceItemGroupsDataForCards = serviceItemGroupsDataArray;
            this.serviceItemGroupCards = this.getSearchContentAsCardData();
        }
    };
    ReservationSearchResultsComponent.prototype.getSearchContentAsCardData = function () {
        var contentCardList = this.summaryCardService.getContentCardsByConfig(this.tReservations, this.serviceItemGroupsDataForCards);
        this.spinnerService.hide();
        this.heading = this.resultCount + ' result' + (this.resultCount > 1 ? 's' : '') + ' found.';
        this.paginatorHasMore = contentCardList.length !== this.resultCount;
        console.log(this.heading);
        this.setPageZero();
        return contentCardList;
    };
    ReservationSearchResultsComponent.prototype.createSorters = function () {
        var _this = this;
        this.sorterService.sorters = [];
        this.sorters_ = [];
        var sorterMap = new Map();
        this.tReservations.config.placeHolderConfig.forEach(function (config) {
            if (config && config.sort) {
                var sorter = _this.sorterService.createSorter(config);
                var id = config['_id'];
                sorterMap.set(id, sorter);
                _this.sorters_.push(sorter);
            }
        });
    };
    ReservationSearchResultsComponent.prototype.onSort = function (event) {
        this.sorterService.sorter = event;
    };
    ReservationSearchResultsComponent.prototype.getPickupLocations = function (pickupLocations) {
        var pickupLocationsStr = '';
        var length = pickupLocations.length;
        for (var i = 0; i < length; i++) {
            pickupLocationsStr += pickupLocations[i];
            if (i < length - 1) {
                pickupLocationsStr += ', ';
            }
        }
        return pickupLocationsStr;
    };
    ReservationSearchResultsComponent.prototype.getDropOffLocations = function (dropOffLocations) {
        var dropOffLocationsStr = '';
        var length = dropOffLocations.length;
        for (var i = 0; i < length; i++) {
            dropOffLocationsStr += dropOffLocations[i];
            if (i < length - 1) {
                dropOffLocationsStr += ', ';
            }
        }
        return dropOffLocationsStr;
    };
    ReservationSearchResultsComponent.prototype.retrieveSelectedGroupAndSetCriteria = function () {
        this.dataStore.set(DataKey.reservationSelectedGroupCriteria, this.selectedGroupCriteria, true);
        this.retrieveSelectedGroup(false);
    };
    ReservationSearchResultsComponent.prototype.retrieveSelectedGroup = function (considerLocalChanges) {
        var _this = this;
        if (considerLocalChanges === void 0) { considerLocalChanges = true; }
        this.autoAssignResults = [];
        this.autoCompleteClicked = false;
        this.assignmentList = this.getAssignmentsFromMap();
        this.trsReservationManagementService.retrieveServiceItems(this.selectedGroupCriteria)
            .subscribe(function (result) {
            var data = ResponseUtil.getDataArray(result);
            if (considerLocalChanges) {
                if (data && _this.changesAvailableForServiceItems) {
                    var _loop_2 = function (i) {
                        var assignments = _this.assignmentList.filter(function (ass) { return ass.serviceItemId === data[i].serviceItemId; });
                        if (assignments) {
                            _this.unSavedDataAvailable = true;
                        }
                        /*if (assignments && data[i].assignments) {
                         assignment.assignmentId = data[i].assignment.assignmentId;
                         assignment.status = data[i].assignment.status;
                         }*/
                        data[i].serviceAssignments = assignments.length > 0 ?
                            assignments :
                            data[i].serviceAssignments;
                    };
                    for (var i = 0; i < data.length; i++) {
                        _loop_2(i);
                    }
                }
            }
            _this.dataStore.set(DataKey.reservationSelectedGroupData, data, true);
        });
        this.trsReservationManagementService.retrieveReservationGroupNotes(this.selectedGroupCriteria)
            .subscribe(function (result) {
            var serviceGroup = ResponseUtil.getFirstData(result);
            if (serviceGroup) {
                var groupNotes = serviceGroup.notes;
                if (groupNotes && groupNotes.length > 0) {
                    _this.selectedServiceGroupNotes = groupNotes.sort();
                }
                else {
                    _this.selectedServiceGroupNotes = [];
                }
            }
            else {
                _this.selectedServiceGroupNotes = [];
            }
        });
    };
    ReservationSearchResultsComponent.prototype.getSelectedGroup = function () {
        var _this = this;
        this.serviceItemsInSelectedGroup = [];
        this.serviceItemsInSelectedGroupCards = [];
        this.selectedServiceGroupNotes = [];
        this.selectedGroupItemsObserver = this.dataStore.get(DataKey.reservationSelectedGroupData)
            .subscribe(function (result) {
            _this.serviceItemsInSelectedGroup = [];
            _this.serviceItemsInSelectedGroupCards = [];
            _this.selectedServiceGroupNotes = [];
            if (result != null) {
                _this.serviceItemsInSelectedGroup = result;
                _this.createSelectedGroupServiceItemsList();
                _this.serviceItemsInSelectedGroupCards = _this.getServiceItemSummaryCards();
                if (_this.unSavedDataAvailable) {
                    _this.changesAvailableForServiceItems = true;
                }
                else {
                    _this.changesAvailableForServiceItems = false;
                }
                _this.updateSummaryCard();
            }
            _this.savedServiceItems = [];
        });
        /*const n = this.dataStore.get(DataKey.reservationSelectedGroupNoteData).subscribe(result => {
         if (result && result.body) {
         if (result.body.data && result.body.message !== 'No notes for this group') {
         this.selectedServiceGroupNotes = result.body.data.notes;
         this.dataStore.get(DataKey.selectedGroupNoteTemporaryData)
         .pipe(
         take(2)
         )
         .subscribe(result => {
         if (result) {
         this.selectedServiceGroupNotes = this.selectedServiceGroupNotes.concat(result);
         }
         this.dataStore.set(DataKey.selectedGroupNoteTemporaryData, null, true);
         });
         } else {
         this.selectedServiceGroupNotes = [];
         }
         }
         });*/
    };
    ReservationSearchResultsComponent.prototype.createSelectedGroupServiceItemsList = function () {
        var _this = this;
        this.serviceItemChangesInSelectedGroup = new Map();
        this.serviceItemsInSelectedGroup.forEach(function (item) {
            var serviceItemCardData = new ServiceItemChanges();
            serviceItemCardData.serviceItemId = item.serviceItemId;
            serviceItemCardData.checked = _this.shouldCheckItemAfterSave(item);
            serviceItemCardData.firstParentId = item.firstParentId;
            serviceItemCardData.isPrivate = item.privateBooking;
            serviceItemCardData.reservationStatus = item.reservationStatus;
            serviceItemCardData.serviceAssignments = new Map();
            if (item.serviceAssignments) {
                item.serviceAssignments.forEach(function (value) {
                    serviceItemCardData.serviceAssignments.set(value.categoryCode, value);
                });
            }
            // for each service item has a serviceItemData in serviceItemChangesInSelectedGroup
            _this.serviceItemChangesInSelectedGroup.set(item.serviceItemId, serviceItemCardData);
            if (item.serviceAssignments) {
                // serviceItemCardData.serviceAssignments = item.serviceAssignments;
                if (item.serviceAssignments) {
                    var vehicleSupplierId_1 = 0;
                    var driverSupplierId_1 = 0;
                    var driverId_1 = 0;
                    var vehicleId_1 = 0;
                    item.serviceAssignments.forEach(function (value) {
                        if (value.categoryCode === ResourceType.driver) {
                            driverSupplierId_1 = value.supplierId;
                            driverId_1 = value.resourceId;
                            serviceItemCardData.driverCost = _this.costCalculationUtilsService.calculateServiceItemJobCost(item, value);
                        }
                        else if (value.categoryCode === ResourceType.vehicle) {
                            vehicleSupplierId_1 = value.supplierId;
                            vehicleId_1 = value.resourceId;
                            serviceItemCardData.vehicleCost = _this.costCalculationUtilsService.calculateServiceItemJobCost(item, value);
                        }
                        value.serviceItemId = item.serviceItemId;
                        serviceItemCardData.serviceAssignments.set(value.categoryCode, value);
                    });
                    if (vehicleSupplierId_1 > 0) {
                        var criteria = new SupplierSearchCriteria();
                        criteria.transferMode = item.trsServiceItem.transferMode;
                        criteria.route = item.trsServiceItem.route;
                        criteria.serviceDate = item.serviceDate;
                        criteria.status = 'true';
                        criteria.supplierId = vehicleSupplierId_1;
                        _this.getAssignedSupplierById(criteria).subscribe(function (result) {
                            if (_this.commonHelper.dataValidity(result)) {
                                var suppler = ResponseUtil.getFirstData(result);
                                if (suppler) {
                                    if (suppler.supplierId === vehicleSupplierId_1) {
                                        serviceItemCardData.vehicleSupplier = suppler;
                                        _this.changeDetector.notifyChange();
                                    }
                                }
                            }
                        });
                    }
                    if (driverSupplierId_1 > 0) {
                        var criteria = new SupplierSearchCriteria();
                        criteria.status = 'true';
                        criteria.supplierId = driverSupplierId_1;
                        _this.supplierService.searchDriverSuppliersForAssignment(criteria).subscribe(function (result) {
                            if (_this.commonHelper.dataValidity(result)) {
                                var suppler = ResponseUtil.getFirstData(result);
                                if (suppler) {
                                    if (suppler.supplierId === driverSupplierId_1) {
                                        serviceItemCardData.driverSupplier = suppler;
                                        _this.changeDetector.notifyChange();
                                    }
                                }
                            }
                        });
                    }
                    if (vehicleId_1 > 0) {
                        if (_this.autoCompleteClicked == true) {
                            _this.autoAssignCallForResources(serviceItemCardData, item);
                        }
                        else {
                            _this.getAssignedVehicleById(vehicleId_1).subscribe(function (result) {
                                if (_this.commonHelper.dataValidity(result)) {
                                    var vehicle = ResponseUtil.getFirstData(result);
                                    if (vehicle) {
                                        if (vehicle.resourceId === vehicleId_1) {
                                            var resource = new ResourceAvailability();
                                            resource.resourceName = vehicle.vehicleName;
                                            resource.resourceId = vehicle.resourceId;
                                            serviceItemCardData.vehicle = resource;
                                            _this.changeDetector.notifyChange();
                                        }
                                    }
                                }
                            }, function (error2) {
                                _this.spinnerService.hide();
                            });
                        }
                    }
                    if (driverId_1 > 0) {
                        _this.getAssignedDriverById(driverId_1).subscribe(function (result) {
                            if (_this.commonHelper.dataValidity(result)) {
                                var driver = ResponseUtil.getFirstData(result);
                                if (driver) {
                                    if (driver.resourceId === driverId_1) {
                                        // @ts-ignore
                                        serviceItemCardData.driver = driver;
                                        serviceItemCardData.driver.resourceName = driver.firstName +
                                            ' ' +
                                            driver.lastName;
                                        _this.changeDetector.notifyChange();
                                    }
                                }
                            }
                        });
                    }
                }
            }
        });
        // this.autoCompleteClicked = false;
    };
    ReservationSearchResultsComponent.prototype.autoAssignCallForResources = function (serviceItemCardData, item) {
        var _this = this;
        this.getAssignedVehicleByIdBackend(serviceItemCardData.serviceAssignments.get(ResourceType.vehicle).resourceId)
            .subscribe(function (result) {
            if (_this.commonHelper.dataValidity(result)) {
                var vehicle = ResponseUtil.getFirstData(result);
                if (vehicle) {
                    if (vehicle.resourceId ===
                        serviceItemCardData.serviceAssignments.get(ResourceType.vehicle).resourceId) {
                        serviceItemCardData.vehicle = vehicle;
                        if (_this.autoCompleteClicked == true) {
                            var vehicleSearchCriteria = new VehicleSearchCriteria();
                            vehicleSearchCriteria.activeStatus = true;
                            vehicleSearchCriteria.vehicleName = serviceItemCardData.vehicle.resourceName;
                            vehicleSearchCriteria.resourceType = ResourceType.vehicle;
                            vehicleSearchCriteria.transferMode = serviceItemCardData.vehicle.transferMode;
                            vehicleSearchCriteria.serviceDate = item.serviceDate;
                            vehicleSearchCriteria.route = item.trsServiceItem.route;
                            vehicleSearchCriteria.start = 0;
                            vehicleSearchCriteria.size = 10;
                            vehicleSearchCriteria.supplierId = +serviceItemCardData.vehicle.trsSupplier.supplierId;
                            vehicleSearchCriteria.serviceItems = serviceItemCardData.serviceItemId.toString();
                            _this.trsReservationManagementService.searchRecommendedVehiclesFromMock(vehicleSearchCriteria).subscribe(function (result) {
                                _this.spinnerService.hide();
                                if (result) {
                                    if (result) {
                                        _this.filteredVehicles = ResponseUtil.getDataArray(result);
                                        var firstData = ResponseUtil.getFirstData(result);
                                        var event_1 = new ResourceAllocationEvent(serviceItemCardData.serviceItemId, null, firstData, null, null);
                                        _this.changeDetector.notifyChange();
                                        _this.onSelectVehicle(event_1);
                                    }
                                    else {
                                        _this.filteredVehicles = [];
                                    }
                                }
                                else {
                                    _this.filteredVehicles = [];
                                }
                            }, function (error) {
                                _this.spinnerService.hide();
                                _this.filteredVehicles = [];
                            });
                        }
                        else {
                            _this.changeDetector.notifyChange();
                        }
                    }
                }
            }
        }, function (error2) {
            _this.spinnerService.hide();
        });
    };
    ReservationSearchResultsComponent.prototype.getAssignedSupplierById = function (supplierSearchCriteria) {
        return this.supplierService.searchSuppliersForAssignment(supplierSearchCriteria);
    };
    ReservationSearchResultsComponent.prototype.getAssignedVehicleById = function (resourceId) {
        var vehicleSearchCriteria = new VehicleSearchCriteria();
        vehicleSearchCriteria.activeStatus = true;
        vehicleSearchCriteria.resourceId = resourceId;
        return this.transportResourceService.searchVehicleSummaries(vehicleSearchCriteria);
    };
    ReservationSearchResultsComponent.prototype.getAssignedVehicleByIdBackend = function (resourceId) {
        var vehicleSearchCriteria = new VehicleSearchCriteria();
        vehicleSearchCriteria.activeStatus = true;
        vehicleSearchCriteria.resourceId = resourceId;
        return this.transportResourceService.searchVehiclesSummaryNoDataKey(vehicleSearchCriteria);
    };
    ReservationSearchResultsComponent.prototype.getAssignedDriverById = function (resourceId) {
        var driverSearchCriteria = new GenericSearchCriteria();
        driverSearchCriteria.activeStatus = 'true';
        driverSearchCriteria.resourceId = resourceId;
        return this.genericResourceService.searchGenericResource(driverSearchCriteria);
    };
    ReservationSearchResultsComponent.prototype.onReservationGroupSelect = function (summaryCardData) {
        // clear service item cards
        this.serviceItemsInSelectedGroupCards = [];
        this.selectedSummaryCardFromGroups = summaryCardData;
        this.selectedServiceItemGroup = summaryCardData.data.value['rawData'];
        // don't use existing criteria itself. create a new one.
        // existing one is needed for fetching more results in pagination.
        this.selectedGroupCriteria = new ReservationSearchCriteria();
        //copy data from existing criteria
        Object.assign(this.selectedGroupCriteria, this.searchCriteria);
        this.selectedGroupCriteria.start = 0;
        this.selectedGroupCriteria.route = this.selectedServiceItemGroup.route;
        this.selectedGroupCriteria.transferMode = this.selectedServiceItemGroup.transferMode;
        this.selectedGroupCriteria.date = formatDate(this.selectedServiceItemGroup.serviceDate, 'yyyy-MM-dd', 'en-us');
        // time range for the selected group
        this.selectedGroupCriteria.serviceStartTime = this.selectedServiceItemGroup.startTime;
        this.selectedGroupCriteria.groupEndTime = this.selectedServiceItemGroup.endTime;
        this.selectedGroupCriteria.serviceItemIdList = this.selectedServiceItemGroup.serviceItemIdList;
        this.identifierService.resetManuallyResetId();
        this.retrieveSelectedGroupAndSetCriteria();
        this.generateFooterDataMap();
    };
    ReservationSearchResultsComponent.prototype.getServiceItemSummaryCards = function () {
        var _this = this;
        var reservationMap = [];
        this.serviceItemsInSelectedGroup.forEach(function (serviceItem) {
            var vehicleSupplierId = 0;
            var driverSupplierId = 0;
            var driverId = 0;
            var vehicleId = 0;
            var status = '';
            var vehicleAssignmentStatus = '';
            var driverAssignmentStatus = '';
            var jobCost = 0;
            var unitCost = 0;
            if (serviceItem.serviceAssignments) {
                serviceItem.serviceAssignments.forEach(function (value) {
                    if (value.categoryCode === ResourceType.driver) {
                        driverSupplierId = value.supplierId;
                        driverId = value.resourceId;
                        driverAssignmentStatus = value.assignStatus;
                    }
                    else if (value.categoryCode === ResourceType.vehicle) {
                        vehicleSupplierId = value.supplierId;
                        vehicleId = value.resourceId;
                        vehicleAssignmentStatus = value.assignStatus;
                    }
                    jobCost += value.jobCost;
                    unitCost += value.estimatedCost;
                    status = value.assignStatus;
                });
            }
            reservationMap.push({
                'serviceItemId': serviceItem.serviceItemId,
                'bookingId': serviceItem.bookingReferenceId,
                'pickups': serviceItem.trsServiceItem.pickupPointName ?
                    serviceItem.trsServiceItem.pickupPointName :
                    '',
                'dropOffs': serviceItem.trsServiceItem.dropoffPointName ?
                    serviceItem.trsServiceItem.dropoffPointName :
                    '',
                'vehicleSupplier': vehicleSupplierId,
                'driverSupplier': driverSupplierId,
                'resource': vehicleId,
                'driver': driverId,
                'price': jobCost,
                'passengerCount': serviceItem.servicePassengers ? serviceItem.servicePassengers.length : '',
                'leadPassenger': _this.getLeadPassengerName(serviceItem.servicePassengers),
                'estimatedPrice': unitCost,
                'private': serviceItem.privateBooking,
                'split': serviceItem.split,
                'status': status,
                'vehicleAssignmentStatus': vehicleAssignmentStatus,
                'driverAssignmentStatus': driverAssignmentStatus,
                'reservationStatus': serviceItem.reservationStatus,
                'flightNumber': '-',
                'rawData': serviceItem
            });
        });
        var summaryCards = this.summaryCardService.getContentCardsByConfig(this.tServiceItems, reservationMap);
        this.spinnerService.hide();
        return summaryCards;
    };
    ReservationSearchResultsComponent.prototype.getLeadPassengerName = function (passengers) {
        var leadPassenger = this.getLeadPassenger(passengers);
        if (leadPassenger) {
            return leadPassenger.firstName + ' ' + leadPassenger.lastName;
        }
        else {
            return '';
        }
    };
    ReservationSearchResultsComponent.prototype.generateFooterDataMap = function () {
        if (this.serviceItemsInSelectedGroup) {
            this.footerDataMap.set(0, {
                captionText: 'Created on ' + '21/12/2018',
                bodyText: ''
            });
            this.footerDataMap.set(1, {
                captionText: 'Modified on ' + '21/12/2018',
                bodyText: ''
            });
        }
    };
    ReservationSearchResultsComponent.prototype.loadResultsHeader = function (trfModes) {
        var _this = this;
        if (trfModes !== null) {
            var x = this.dataStore.get(DataKey.transferModes).subscribe(function (data) {
                if (data !== null) {
                    _this.transferModes = [];
                    var searchResultModes = data.filter(function (tm) { return trfModes.includes(tm.code); });
                    if (!_this.searchCriteria.transferMode) {
                        _this.transferModes.push({ 'code': null, 'value': 'All Transfer Modes', 'isSelected': true });
                        searchResultModes.forEach(function (val) {
                            _this.transferModes.push({ 'code': val, 'value': val.name, 'isSelected': false });
                        });
                    }
                    else {
                        _this.transferModes.push({ 'code': null, 'value': 'All Transfer Modes', 'isSelected': false });
                        searchResultModes.forEach(function (val) {
                            if (_this.searchCriteria.transferMode === val.code) {
                                _this.transferModes.push({ 'code': val, 'value': val.name, 'isSelected': true });
                            }
                            else {
                                _this.transferModes.push({ 'code': val, 'value': val.name, 'isSelected': false });
                            }
                        });
                    }
                }
                else {
                    _this.transferModes = [];
                }
            });
            this.trsReservationManagementService.getGroupByOptions().subscribe(function (data) {
                if (data !== null) {
                    _this.groupingOptions = [];
                    _this.groupingOptionsRowData = ResponseUtil.getDataArray(data);
                    if (!_this.searchCriteria.groupByType) {
                        _this.groupingOptionsRowData.forEach(function (val) {
                            if (val.id == 5) {
                                _this.groupingOptions.push({ 'code': val.id, 'value': val.name, 'isSelected': true });
                            }
                            else {
                                _this.groupingOptions.push({ 'code': val.id, 'value': val.name, 'isSelected': false });
                            }
                        });
                    }
                    else {
                        _this.groupingOptionsRowData.forEach(function (val) {
                            if (val.id == _this.searchCriteria.groupByType) {
                                _this.groupingOptions.push({ 'code': val.id, 'value': val.name, 'isSelected': true });
                            }
                            else {
                                _this.groupingOptions.push({ 'code': val.id, 'value': val.name, 'isSelected': false });
                            }
                        });
                    }
                }
                else {
                    _this.groupingOptions = [];
                }
            });
            // this.groupingOptions.push({'code': 'oneHour', 'value': '9.00-10-00', 'isSelected': true});
        }
    };
    ReservationSearchResultsComponent.prototype.onTransferModeSelect = function (event) {
        if (event !== null) {
            this.searchCriteria.transferMode = event.code;
        }
        else {
            this.searchCriteria.transferMode = event;
        }
        this.search(this.searchCriteria);
    };
    ReservationSearchResultsComponent.prototype.onGroupingTypeSelect = function (event) {
        this.searchCriteria.groupByType = event;
        var groupOption = this.groupingOptionsRowData.find(function (option) { return option.id === event; });
        // todo set start time for INTERVAL grouping
        this.searchCriteria.filterStartTime = this.utilService.localTimeString(6, 0);
        this.searchCriteria.interval = groupOption['duration'];
        this.searchCriteria.groupBy = groupOption.type;
        if (this.searchCriteria.groupBy === 'RANGE') {
            this.searchCriteria.filterEndTime = this.utilService.localTimeString(groupOption['end'][0], groupOption['end'][1]);
            this.searchCriteria.filterStartTime = this.utilService.localTimeString(groupOption['start'][0], groupOption['start'][1]);
        }
        this.search(this.searchCriteria);
    };
    ReservationSearchResultsComponent.prototype.viewAutoAssignSummary = function () {
        var _this = this;
        this.popupDataObserver();
        this.dataStore.set(DataKey.popupClose, null);
        var dataObject = new ModalData(LEVEL.SUCCESS, 'Auto Assigned Summary', null, null, 'modal-basic--alt modal-basic--fixed-size', AutoAssignedSummaryWrapperComponent, {
            'assignedResources': this.autoAssignResults
        });
        dataObject.disableClose = false;
        this.confirmModal(dataObject);
        var modalSubscription = this.dataStore.get(DataKey.popupClose).subscribe(function (data) {
            if (data != null) {
                modalSubscription.unsubscribe();
                _this.closeModal();
            }
            if (_this.isPopupDataChanged) {
                _this.retrieveSelectedGroup();
            }
        });
    };
    ReservationSearchResultsComponent.prototype.onLeadPassengerClick = function (serviceItemData) {
        var _this = this;
        var selectedCount = 0;
        this.serviceItemChangesInSelectedGroup.forEach(function (value) {
            if (value.checked) {
                selectedCount++;
            }
        });
        if (selectedCount < 2) {
            var serviceItem = serviceItemData.data.value['rawData'];
            var reservationStatus = serviceItemData.data.value['reservationStatus'];
            if (reservationStatus === ReservationStatus.UPDATED) {
                var updatedWarning = new DialogModel(true, LEVEL.ERROR, 'Invalid Attempt', 'Cannot split updated old reference item', true, 2000);
                updatedWarning.disableClose = true;
                this.confirmDialog(updatedWarning);
            }
            else if (reservationStatus === ReservationStatus.CANCELLED) {
                var updatedWarning = new DialogModel(true, LEVEL.ERROR, 'Invalid Attempt', 'Cannot split cancelled item', true, 2000);
                updatedWarning.disableClose = true;
                this.confirmDialog(updatedWarning);
            }
            else if (serviceItem.assignment
                && this.isRestricted(this.serviceItemChangesInSelectedGroup.get(serviceItem.serviceItemId))) {
                var invalidAttemptWarning = new DialogModel(true, LEVEL.WARNING, 'Invalid Attempt', 'Cannot change lead passenger of service items in confirm state', true, 2000);
                invalidAttemptWarning.disableClose = true;
                this.confirmDialog(invalidAttemptWarning);
            }
            else {
                this.popupDataObserver();
                var servicePassengers = serviceItem.servicePassengers;
                this.dataStore.set(DataKey.popupClose, null);
                var dataObject = new ModalData(LEVEL.SUCCESS, 'Select Lead Passenger', null, null, 'add-service-menu', LeadPassengerSelectionWrapperComponent, {
                    'passengers': servicePassengers,
                    'leadPassenger': this.getLeadPassenger(servicePassengers),
                    'serviceItemId': serviceItem.serviceItemId
                });
                dataObject.disableClose = true;
                this.confirmModal(dataObject);
                this.leadPassengerModalCloseSubscription = this.dataStore.get(DataKey.popupClose).subscribe(function (data) {
                    if (data != null) {
                        _this.leadPassengerModalCloseSubscription.unsubscribe();
                        _this.closeModal();
                    }
                    if (_this.isPopupDataChanged) {
                        _this.retrieveSelectedGroup();
                    }
                });
            }
        }
        else {
            var invalidAttemptWarning = new DialogModel(true, LEVEL.ERROR, 'Invalid Attempt', 'Please proceed without selecting multiple service items', true, 2000);
            invalidAttemptWarning.disableClose = true;
            this.confirmDialog(invalidAttemptWarning);
        }
    };
    ReservationSearchResultsComponent.prototype.getLeadPassenger = function (passengers) {
        var leadPassenger = passengers.find(function (passenger) {
            return passenger.lead;
        });
        return leadPassenger;
    };
    ReservationSearchResultsComponent.prototype.onSplitClick = function (data) {
        var _this = this;
        var serviceItemData = data.summary;
        var isSaved = data.isSaved;
        this.popupDataObserver();
        var serviceItem = serviceItemData.data.value['rawData'];
        var serviceAssignmentList = [];
        this.serviceItemChangesInSelectedGroup.get(serviceItem.serviceItemId).serviceAssignments
            .forEach(function (value) { return serviceAssignmentList.push(value); });
        var reservationStatus = serviceItemData.data.value['reservationStatus'];
        if (reservationStatus === ReservationStatus.UPDATED) {
            var updatedWarning = new DialogModel(true, LEVEL.ERROR, 'Invalid Attempt', 'Cannot split updated old reference item', true, 2000);
            updatedWarning.disableClose = true;
            this.confirmDialog(updatedWarning);
        }
        else if (reservationStatus === ReservationStatus.CANCELLED) {
            var updatedWarning = new DialogModel(true, LEVEL.ERROR, 'Invalid Attempt', 'Cannot split cancelled item', true, 2000);
            updatedWarning.disableClose = true;
            this.confirmDialog(updatedWarning);
        }
        else if (!isSaved) {
            var unsavedDataWarning = new DialogModel(true, LEVEL.WARNING, 'Invalid Attempt', 'Cannot split item in unsaved state', true, 2000);
            unsavedDataWarning.disableClose = true;
            this.confirmDialog(unsavedDataWarning);
        }
        else if (serviceItem.assignment
            && this.isRestricted(this.serviceItemChangesInSelectedGroup.get(serviceItem.serviceItemId))) {
            var invalidAttemptWarning = new DialogModel(true, LEVEL.WARNING, 'Invalid Attempt', 'Cannot split service items in confirm state', true, 2000);
            invalidAttemptWarning.disableClose = true;
            this.confirmDialog(invalidAttemptWarning);
        }
        else if (this.serviceItemChangesInSelectedGroup.get(serviceItem.serviceItemId) &&
            serviceAssignmentList &&
            ServiceAssignmentUtilService.isAssignedAny(serviceAssignmentList)) {
            var invalidAttemptWarning = new DialogModel(true, LEVEL.WARNING, 'Invalid Attempt', 'Cannot split service items with Assignments', true, 2000);
            invalidAttemptWarning.disableClose = true;
            this.confirmDialog(invalidAttemptWarning);
        }
        else if (serviceItem.servicePassengers &&
            serviceItem.servicePassengers.length < 2) {
            var invalidAttemptWarning = new DialogModel(true, LEVEL.WARNING, 'Invalid Attempt', 'Cannot split service items with passengers less than 2', true, 2000);
            invalidAttemptWarning.disableClose = true;
            this.confirmDialog(invalidAttemptWarning);
        }
        else if (serviceItem.servicePassengers) {
            this.dataStore.set(DataKey.popupClose, null);
            var dataObject = new ModalData(LEVEL.SUCCESS, 'Split Passengers into Groups', null, null, 'split-passengers-modal', SplitPassengersViewWrapperComponent, { 'serviceItem': serviceItem });
            dataObject.disableClose = true;
            this.confirmModal(dataObject);
            this.splitPassengersModalCloseSubscription = this.dataStore.get(DataKey.popupClose).subscribe(function (data) {
                _this.autoAssignResults = [];
                if (data != null) {
                    _this.splitPassengersModalCloseSubscription.unsubscribe();
                    _this.closeModal();
                }
                if (_this.isPopupDataChanged) {
                    _this.retrieveSelectedGroup();
                }
            });
        }
    };
    ReservationSearchResultsComponent.prototype.popupDataObserver = function () {
        var _this = this;
        this.popupDataChangeObserver = this.dataStore.get(DataKey.popupDataChanged).subscribe(function (isDataChanged) {
            if (isDataChanged) {
                _this.popupDataChangeObserver.unsubscribe();
            }
            _this.isPopupDataChanged = isDataChanged;
        });
    };
    ReservationSearchResultsComponent.prototype.onMoreClick = function ($event) {
        var selectedIndex_ = 0;
        if ($event && $event.item) {
            this.selectedServiceItem = $event.item.data.value['rawData'];
            if (this.isRestricted(this.serviceItemChangesInSelectedGroup.get(this.selectedServiceItem.serviceItemId))) {
                var invalidAttemptWarning = new DialogModel(true, LEVEL.WARNING, 'Invalid Attempt', 'Cannot assign to service items in confirm state', true, 2000);
                invalidAttemptWarning.disableClose = true;
                this.confirmDialog(invalidAttemptWarning);
            }
            else {
                var isVehicleAssignment = false;
                var isDriverAssignment = false;
                if ($event.event === ResourceType.vehicle) {
                    isVehicleAssignment = true;
                }
                else if ($event.event === ResourceType.driver) {
                    isDriverAssignment = true;
                }
                var previousAssignmentDetail = this.selectedServiceItem.serviceAssignments.find(function (value) { return value.categoryCode ===
                    $event.event; });
                var input = {
                    'isVehicleAssignment': isVehicleAssignment,
                    'isDriverAssignment': isDriverAssignment,
                    'selectedIndex': selectedIndex_,
                    'bookingData': this.selectedServiceItem,
                    'previousAssignment': previousAssignmentDetail,
                    'serviceItemChangesInSelectedGroup': this.serviceItemChangesInSelectedGroup
                };
                var passengerCount = this.getPassengerCount(input);
                var serviceTime = moment(this.selectedServiceItem.trsServiceItem.departureDateTime).format('HH:mm');
                var pickupDropOff = this.selectedServiceItem.trsServiceItem.pickupPointName + '-' +
                    this.selectedServiceItem.trsServiceItem.dropoffPointName;
                var secondaryHeader = this.selectedServiceItem.bookingReferenceId + ' | Pax: ' + passengerCount +
                    ' | ' +
                    serviceTime + ' | ' + pickupDropOff;
                // this.setSupplierSearchCriteriatoUrl(false, this.selectedServiceItem);
                this.openResourceAssignmentView(input, secondaryHeader);
            }
        }
    };
    ReservationSearchResultsComponent.prototype.onBulkDriverAssignment = function () {
        this.onBulkAssignment(false, true);
    };
    ReservationSearchResultsComponent.prototype.onBulkVehicleAssignment = function () {
        this.onBulkAssignment(true, false);
    };
    ReservationSearchResultsComponent.prototype.onBulkAssignment = function (isVehicleAssignment, isDriverAssignment) {
        var _this = this;
        var selectedIndex_ = 0;
        var bookingDataArray = [];
        this.serviceItemChangesInSelectedGroup.forEach(function (value) {
            if (value.checked) {
                bookingDataArray.push(_this.serviceItemsInSelectedGroupCards.find(function (itemCard) { return itemCard.data.value.serviceItemId === value.serviceItemId; }).data.value);
            }
        });
        var input = {
            'isVehicleAssignment': isVehicleAssignment,
            'isDriverAssignment': isDriverAssignment,
            'selectedIndex': selectedIndex_,
            'bookingDataArray': bookingDataArray,
            'serviceItemChangesInSelectedGroup': this.serviceItemChangesInSelectedGroup,
            'isBulkAssignment': true
        };
        var passengerCount = this.getPassengerCount(input);
        var secondaryHeader = '[Bulk] | Pax: ' + passengerCount + ' | ' + this.selectedGroupCriteria.serviceStartTime +
            '-' + this.selectedGroupCriteria.groupEndTime;
        // this.setSupplierSearchCriteriatoUrl(true, bookingDataArray);
        this.openResourceAssignmentView(input, secondaryHeader);
    };
    ReservationSearchResultsComponent.prototype.openResourceAssignmentView = function (input, secondaryHeader) {
        var _this = this;
        input.itemGroup = this.selectedServiceItemGroup;
        input.assignmentList = this.unSavedAssignments;
        this.getResourceAssignedStatus();
        setTimeout(function () {
            var fabActions = [];
            var navItemsArray = _this.configLoader.configurations.get(TCO.CONF.CONF_NAVIGATION);
            _this.configLoader.configurations.get(TCO.CONF.CONF_FOCUS_VIEW).ALLOCATION_FOCUS_VIEW.fabAction
                .forEach(function (val) {
                for (var key in navItemsArray) {
                    if (val === navItemsArray[key]['id']) {
                        fabActions.push(navItemsArray[key]);
                    }
                }
            });
            var dataObject = new ModalData(LEVEL.SUCCESS, 'Assign Supplier/Resource', null, null, '', AssignmentFocusViewComponent, input, { 'label': 'close' }, '', '', fabActions, '', secondaryHeader);
            _this.focusviewCloseSubject = _this.dataStore.get(DataKey.supplierCostFocusViewClose).subscribe(function (data) {
                _this.autoAssignResults = [];
                if (data != null) {
                    _this.focusviewCloseSubject.unsubscribe();
                    _this.closeFocusView();
                }
            });
            _this.focusViewService.confirm(dataObject).subscribe(function (res) {
                if (res) {
                    var e = document.getElementsByClassName('header-content__secondary-heading');
                    console.log(e);
                }
            });
        }, 0);
        /*this.dataStore.set(DataKey.popupClose, null);
         let dataObject = new ModalData(
         LEVEL.SUCCESS,
         'Assign Supplier/Resource',
         null,
         null,
         'assign-panel-popup-modal',
         ResourceAssignmentFocusViewComponent,
         input, '', '', '', '', '', ''
         );
         dataObject.disableClose = true;

         let secondaryHeader = this.getPassengerCount(input);

         dataObject.secondaryHeader = '  (' + secondaryHeader + ' Passengers)';
         this.confirmModal(dataObject);

         this.resourceAssignModalCloseSubscription = this.dataStore.get(DataKey.popupClose).subscribe((data) => {
         this.autoAssignResults = [];
         if (data != null) {
         this.resourceAssignModalCloseSubscription.unsubscribe();
         this.closeModal();
         }
         });*/
    };
    ReservationSearchResultsComponent.prototype.getPassengerCount = function (input) {
        if (input.isBulkAssignment) {
            var count_1 = 0;
            input.bookingDataArray.forEach(function (data) {
                count_1 += data.passengerCount;
            });
            return count_1;
        }
        else {
            return input.bookingData.servicePassengers.length;
        }
    };
    ReservationSearchResultsComponent.prototype.setSupplierSearchCriteriatoUrl = function (isBulkAssignment, bookingData) {
        var supplierSearchCriteria = new SupplierSearchCriteria();
        supplierSearchCriteria.resourceTypes = [];
        supplierSearchCriteria.resourceTypes.push(ResourceType.vehicle);
        supplierSearchCriteria.status = 'true';
        if (isBulkAssignment) {
            supplierSearchCriteria.transferMode = bookingData[0]['rawData'].trsServiceItem.transferMode;
            supplierSearchCriteria.route = bookingData[0]['rawData'].trsServiceItem.route;
            supplierSearchCriteria.serviceDate = bookingData[0]['rawData'].serviceDate;
        }
        else {
            supplierSearchCriteria.transferMode = bookingData.trsServiceItem.transferMode;
            supplierSearchCriteria.route = bookingData.trsServiceItem.route;
            supplierSearchCriteria.serviceDate = bookingData.serviceDate;
        }
        var parameters = this.queryParamService.getQueryParamsArray(supplierSearchCriteria);
        this.rootService.setDataToLocalStorage(TCO.AppData.SUPPLIER_SEARCH_CRITERIA, supplierSearchCriteria, true, parameters);
    };
    ReservationSearchResultsComponent.prototype.getResourceAssignedStatus = function () {
        var _this = this;
        this.dataStore.get(DataKey.resourceAssignedStatus).subscribe(function (data) {
            if (data) {
                _this.changesAvailableForServiceItems = true;
            }
        });
    };
    ReservationSearchResultsComponent.prototype.confirmModal = function (data) {
        this.modalService.confirm(data).subscribe(function (res) {
        });
    };
    ReservationSearchResultsComponent.prototype.confirmDialog = function (data) {
        this.dialogService.confirm(data).subscribe(function (res) {
        });
    };
    ReservationSearchResultsComponent.prototype.closeModal = function () {
        if (this.modalService.close()) {
            this.modalService.close().subscribe(function (res) {
            });
        }
    };
    ReservationSearchResultsComponent.prototype.closeFocusView = function () {
        if (this.focusViewService.close()) {
            this.focusViewService.close().subscribe(function (res) {
            });
        }
    };
    ReservationSearchResultsComponent.prototype.grabRowDataList = function (summaryCardDataList) {
        var serviceItems = [];
        summaryCardDataList.forEach(function (summaryCard) {
            if (summaryCard.data.value['rawData']) {
                serviceItems.push(summaryCard.data.value['rawData']);
            }
        });
        return serviceItems;
    };
    ReservationSearchResultsComponent.prototype.getAssignmentsFromMap = function () {
        var _this = this;
        var assignmentInfoList = [];
        this.exit = false;
        var hasAssignmentsToSaveForItem = false;
        this.serviceItemChangesInSelectedGroup.forEach(function (value) {
            hasAssignmentsToSaveForItem = false;
            if (value.serviceAssignments) {
                value.serviceAssignments.forEach(function (assignment) {
                    if (assignment.serviceItemId > 0 && assignment.updated &&
                        assignment.assignStatus !== AssignmentStatus.DMC_CONFIRM &&
                        assignment.assignStatus !== AssignmentStatus.SUPPLIER_CONFIRMED) {
                        if (assignment.tempAssignmentId && isNaN(assignment.tempAssignmentId)) {
                            assignment.tempAssignmentId = assignment.tempAssignmentId.split('_') ?
                                assignment.tempAssignmentId.split('_')[1] :
                                _this.identifierService.getNextManuallyResetId();
                        }
                        assignment.assignStatus = assignment.assignStatus ?
                            assignment.assignStatus :
                            AssignmentStatus.PENDING;
                        assignmentInfoList.push(assignment);
                        hasAssignmentsToSaveForItem = true;
                    }
                });
                if (hasAssignmentsToSaveForItem) {
                    _this.savedServiceItems.push(value.serviceItemId);
                }
            }
        });
        return assignmentInfoList;
    };
    ReservationSearchResultsComponent.prototype.isRestricted = function (value) {
        if (value.serviceAssignments) {
            value.serviceAssignments.forEach(function (assignment) {
                if (assignment.assignStatus === AssignmentStatus.DMC_CONFIRM ||
                    assignment.assignStatus === AssignmentStatus.SUPPLIER_CONFIRMED) {
                    return true;
                }
            });
        }
        return false;
    };
    ReservationSearchResultsComponent.prototype.onConfirmClick = function ($event) {
        var _this = this;
        var assignmentIds = [];
        this.serviceItemChangesInSelectedGroup.forEach(function (entry) {
            var e_1, _a;
            if (entry.checked && entry.serviceAssignments) {
                var serviceAssignmentList_2 = [];
                entry.serviceAssignments.forEach(function (value) { return serviceAssignmentList_2.push(value); });
                try {
                    for (var serviceAssignmentList_1 = tslib_1.__values(serviceAssignmentList_2), serviceAssignmentList_1_1 = serviceAssignmentList_1.next(); !serviceAssignmentList_1_1.done; serviceAssignmentList_1_1 = serviceAssignmentList_1.next()) {
                        var data = serviceAssignmentList_1_1.value;
                        if (data.assignStatus !== AssignmentStatus.DMC_CONFIRM ||
                            data.assignStatus !== AssignmentStatus.SUPPLIER_CONFIRMED && data.assignStatus !==
                                AssignmentStatus.INACTIVE_NOTIFY) {
                            assignmentIds.push(data.assignmentId);
                        }
                    }
                }
                catch (e_1_1) { e_1 = { error: e_1_1 }; }
                finally {
                    try {
                        if (serviceAssignmentList_1_1 && !serviceAssignmentList_1_1.done && (_a = serviceAssignmentList_1.return)) _a.call(serviceAssignmentList_1);
                    }
                    finally { if (e_1) throw e_1.error; }
                }
            }
        });
        if (assignmentIds.length > 0) {
            var confirmSuccess = new DialogModel(true, LEVEL.WARNING, 'Confirm Assignments', "\n                        Confirming this action will trigger the following.<br><br> \n\n                        1. Sending manifests to the allocated Suppliers/ Resources.<br> \n                        2. Creating accounting ledger entries.<br><br> \n\n                        Once confirmed this action cannot be undone.<br>  \n                        Do you wish to proceed?", true, 2000, null, 'No', 'Yes', true);
            confirmSuccess.disableClose = true;
            this.dialogService
                .confirm(confirmSuccess)
                .subscribe(function (res) {
                if (res === true) {
                    _this.isRequestCompleted = false;
                    _this.reservationManagementService.confirmAssignments(assignmentIds).subscribe(function (result) {
                        // refresh service items
                        _this.retrieveSelectedGroup();
                        _this.isRequestCompleted = true;
                        _this.common.showSnackBar('Successfully confirmed the assigned resources.', 3000, TcErrorType.TYPE.INFO);
                        _this.changesAvailableForServiceItems = false;
                    }, function (error) {
                        console.log(error);
                        _this.isRequestCompleted = true;
                        var confirmError = new DialogModel(true, LEVEL.ERROR, 'Failed', 'Failed to confirm the assigned resources', true, 2000);
                        _this.dialogService
                            .confirm(confirmError)
                            .subscribe(function (res) {
                            if (res === true) {
                                console.log('true');
                            }
                        });
                    });
                }
            });
        }
    };
    ReservationSearchResultsComponent.prototype.onSupplierRejectClick = function ($event) {
        var _this = this;
        var assignmentIds = [];
        this.serviceItemChangesInSelectedGroup.forEach(function (entry) {
            var e_2, _a;
            if (entry.checked && entry.serviceAssignments) {
                var serviceAssignmentList_4 = [];
                entry.serviceAssignments.forEach(function (value) { return serviceAssignmentList_4.push(value); });
                try {
                    for (var serviceAssignmentList_3 = tslib_1.__values(serviceAssignmentList_4), serviceAssignmentList_3_1 = serviceAssignmentList_3.next(); !serviceAssignmentList_3_1.done; serviceAssignmentList_3_1 = serviceAssignmentList_3.next()) {
                        var data = serviceAssignmentList_3_1.value;
                        if (data.assignStatus === AssignmentStatus.SUPPLIER_CONFIRMED
                            || data.assignStatus === AssignmentStatus.DMC_CONFIRM) {
                            assignmentIds.push(data.assignmentId);
                        }
                    }
                }
                catch (e_2_1) { e_2 = { error: e_2_1 }; }
                finally {
                    try {
                        if (serviceAssignmentList_3_1 && !serviceAssignmentList_3_1.done && (_a = serviceAssignmentList_3.return)) _a.call(serviceAssignmentList_3);
                    }
                    finally { if (e_2) throw e_2.error; }
                }
            }
        });
        if (assignmentIds.length > 0) {
            var confirmSuccess = new DialogModel(true, LEVEL.WARNING, 'Supplier Reject Assignments', "\n                        Rejecting this action will trigger the following.<br><br> \n\n                        1. Removing Suppliers/ Resources from the assignment.<br> \n                        2. Sending manifests to the rejected Suppliers/ Resources.<br> \n                        3. Removing accounting ledger entries.<br><br> \n\n                        Once confirmed this action cannot be undone.<br>  \n                        Do you wish to proceed?", true, 2000, null, 'No', 'Yes', true);
            confirmSuccess.disableClose = true;
            this.dialogService
                .confirm(confirmSuccess)
                .subscribe(function (res) {
                if (res === true) {
                    _this.isRequestCompleted = false;
                    _this.reservationManagementService.onSupplierRejectAssignments(assignmentIds).subscribe(function (result) {
                        // refresh service items
                        _this.retrieveSelectedGroup();
                        _this.isRequestCompleted = true;
                        _this.common.showSnackBar('Successfully rejected the assigned resources.', 3000, TcErrorType.TYPE.INFO);
                        _this.changesAvailableForServiceItems = false;
                    }, function (error) {
                        console.log(error);
                        _this.isRequestCompleted = true;
                        var confirmError = new DialogModel(true, LEVEL.ERROR, 'Failed', 'Failed to confirm the assigned resources', true, 2000);
                        _this.dialogService
                            .confirm(confirmError)
                            .subscribe(function (res) {
                            if (res === true) {
                                console.log('true');
                            }
                        });
                    });
                }
            });
        }
    };
    ReservationSearchResultsComponent.prototype.updateSummaryCard = function () {
        var e_3, _a;
        if (this.selectedSummaryCardFromGroups && this.selectedSummaryCardFromGroups.data) {
            var data = this.selectedSummaryCardFromGroups.data.value;
            var allocatedCount = 0;
            try {
                for (var _b = tslib_1.__values(this.serviceItemsInSelectedGroup), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var item = _c.value;
                    if (this.isNotUpdatedOrCancelled(item)) {
                        allocatedCount += ServiceAssignmentUtilService.getTrsAllocatedPassengersCount(item);
                    }
                }
            }
            catch (e_3_1) { e_3 = { error: e_3_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_3) throw e_3.error; }
            }
            var allocationsLeft = data['passengerCount'] - allocatedCount;
            var groupStatus = ServiceAssignmentUtilService.getGroupStatus(this.serviceItemsInSelectedGroup);
            var groupDataFroCard = data;
            groupDataFroCard['allocatedCount'] = allocatedCount;
            groupDataFroCard['allocationsLeft'] = allocationsLeft;
            groupDataFroCard['groupStatus'] = groupStatus;
            var contentCardList = this.summaryCardService.getContentCardsByConfig(this.tReservations, [groupDataFroCard]);
            if (contentCardList && contentCardList.length > 0) {
                var summaryCardData = contentCardList[0];
                this.selectedSummaryCardFromGroups.data = summaryCardData.data;
                this.selectedSummaryCardFromGroups.icon = summaryCardData.icon;
                this.selectedSummaryCardFromGroups.row1col1 = summaryCardData.row1col1;
                this.selectedSummaryCardFromGroups.row1col2 = summaryCardData.row1col2;
                this.selectedSummaryCardFromGroups.row1col3 = summaryCardData.row1col3;
                this.selectedSummaryCardFromGroups.row1col4 = summaryCardData.row1col4;
                this.selectedSummaryCardFromGroups.row2col1 = summaryCardData.row2col1;
                this.selectedSummaryCardFromGroups.row2col2 = summaryCardData.row2col2;
                this.selectedSummaryCardFromGroups.row2col3 = summaryCardData.row2col3;
                this.selectedSummaryCardFromGroups.row2col4 = summaryCardData.row2col4;
            }
        }
    };
    ReservationSearchResultsComponent.prototype.isNotUpdatedOrCancelled = function (serviceItem) {
        return (serviceItem.reservationStatus === null ||
            (serviceItem.reservationStatus !== ReservationStatus.UPDATED && serviceItem.reservationStatus !==
                ReservationStatus.CANCELLED));
    };
    ReservationSearchResultsComponent.prototype.getTransferModeCapacity = function () {
        var _this = this;
        return new Promise(function (resolve) {
            _this.dataStore.get(DataKey.transferModes).subscribe(function (data) {
                if (data !== null) {
                    var trsMode = data.filter(function (val) { return val.code === _this.selectedServiceItem.trsServiceItem.transferMode; });
                    resolve(trsMode[0].capacity);
                }
            });
        });
    };
    //Auto Assign Resources
    ReservationSearchResultsComponent.prototype.onAutoAssignResources = function ($event) {
        var _this = this;
        if (this.autoAssignResults && this.autoAssignResults.length > 0) {
            this.viewAutoAssignSummary();
        }
        else {
            var confirmSaveSplit = new DialogModel(true, LEVEL.WARNING, 'Confirm Auto Assigning', 'This operation might be override your previous unsaved changes. Do you want to proceed?', true, 2000, null, 'No', 'Yes');
            confirmSaveSplit.disableClose = true;
            this.dialogService
                .confirm(confirmSaveSplit)
                .subscribe(function (res) {
                if (res === true) {
                    _this.isRequestCompleted = false;
                    _this.spinnerService.show();
                    _this.getAssignedResources($event);
                }
            });
        }
    };
    ReservationSearchResultsComponent.prototype.getAssignedResources = function (summaryCardData) {
        var _this = this;
        var selectedServiceItemGroup = summaryCardData.data.value['rawData'];
        var selectedGroupCriteria = new ReservationSearchCriteria();
        selectedGroupCriteria.start = 0;
        selectedGroupCriteria.route = selectedServiceItemGroup.route;
        selectedGroupCriteria.transferMode = selectedServiceItemGroup.transferMode;
        selectedGroupCriteria.date = this.selectedGroupCriteria.date;
        selectedGroupCriteria.serviceStartTime = selectedServiceItemGroup.startTime;
        selectedGroupCriteria.groupEndTime = selectedServiceItemGroup.endTime;
        this.trsReservationManagementService.searchRecommendedAssignments(selectedGroupCriteria)
            .subscribe(function (result) {
            if (result) {
                try {
                    _this.autoAssignResults = [];
                    var vehicles = ResponseUtil.getDataArray(result);
                    for (var i = 1; i < vehicles.length; i++) {
                        var summary = vehicles[0][i - 1].resource;
                        var item = vehicles[i];
                        var refServiceItem = vehicles[0][i - 1].references ?
                            vehicles[0][i - 1].references.SERVICE_ITEM :
                            new ServiceItem();
                        var summaryObject = void 0;
                        if (summary.id != -11) {
                            summaryObject = {
                                serviceItem: item[2],
                                passengerCount: item[3],
                                resource: summary.name,
                                capacity: summary.capacity,
                                cost: _this.getTotalCost(item[0].trsSupplier, refServiceItem, summary.capacity),
                                supplier: item[0].trsSupplier.name,
                                currency: item[0].trsSupplier.currency
                            };
                            var event_2 = new ResourceAllocationEvent(item[1], null, item[0], null, null);
                            _this.changeDetector.notifyChange();
                            _this._onSelectVehicle(event_2);
                            _this.isRequestCompleted = true;
                        }
                        else {
                            summaryObject = {
                                serviceItem: item[2],
                                passengerCount: item[3],
                                resource: 'Not Assigned',
                                capacity: 'Not Available',
                                cost: 'Not Available',
                                supplier: 'Not Available',
                                currency: item[0].trsSupplier.currency
                            };
                        }
                        _this.autoAssignResults.push(summaryObject);
                    }
                    _this.spinnerService.hide();
                    _this.viewAutoAssignSummary();
                    // this.dataStore.set(DataKey.reservationSelectedGroupData, result, true);
                }
                catch (e) {
                    _this.spinnerService.hide();
                    _this.common.showSnackBar('Client side data processing error', 4000, TcErrorType.TYPE.ERROR);
                }
            }
        }, function (error) {
            _this.spinnerService.hide();
            _this.isRequestCompleted = true;
            _this.common.showSnackBar(error.error, 4000, TcErrorType.TYPE.ERROR);
        });
    };
    ReservationSearchResultsComponent.prototype.getTotalCost = function (resourceCost, serviceItem, resourceCapacity) {
        if (resourceCost.adultFare != null && resourceCost.adultFare > 0) {
            return (resourceCost.adultFare * serviceItem.adult) + (resourceCost.childFare * serviceItem.child) +
                (resourceCost.infantFare * serviceItem.infant);
        }
        else if (resourceCost.unitFare != null && resourceCost.unitFare > 0) {
            return resourceCapacity > 0 ?
                (resourceCost.unitFare / resourceCapacity) * serviceItem.servicePassengers.length :
                resourceCost.unitFare;
        }
        return 0;
    };
    ReservationSearchResultsComponent.prototype.onVehicleFocus = function ($event) {
        this.onVehicleTextChange($event);
    };
    ReservationSearchResultsComponent.prototype.onSaveAssignments = function ($event) {
        var assignmentsAddedModel = new DialogModel(true, LEVEL.WARNING, 'Save Changes', 'Do you want to save assignments', true, 2000, null, 'No', 'Yes');
        var assignmentsClearedModel = new DialogModel(true, LEVEL.WARNING, 'Save Changes', 'Do you want to save Changes', true, 2000, null, 'No', 'Yes');
        assignmentsAddedModel.disableClose = true;
        if (this.isCleared) {
            var message = 'Successfully changed the resources';
            this.saveAssignmentsChangesToBackend(assignmentsClearedModel, message);
        }
        else {
            var message = 'Successfully assigned the resources';
            this.saveAssignmentsChangesToBackend(assignmentsAddedModel, message);
        }
    };
    ReservationSearchResultsComponent.prototype.saveAssignmentsChangesToBackend = function (dialogModel, message) {
        var _this = this;
        this.exit = true;
        this.dialogService
            .confirm(dialogModel)
            .subscribe(function (res) {
            if (res === true) {
                var assignments = _this.getAssignmentsFromMap();
                _this.isRequestCompleted = false;
                _this.trsReservationManagementService.saveAssignments(assignments).subscribe(function (result) {
                    _this.unSavedAssignments = [];
                    // refresh service items
                    _this.retrieveSelectedGroup(false);
                    _this.common.showSnackBar(message, 3000, TcErrorType.TYPE.INFO);
                    _this.unSavedDataAvailable = false;
                    _this.isRequestCompleted = true;
                    _this.autoCompleteClicked = false;
                    _this.changesAvailableForServiceItems = false;
                    _this.isCleared = false;
                }, function (error) {
                    console.log(error);
                    // refresh service items
                    _this.retrieveSelectedGroup();
                    var confirmError = new DialogModel(true, LEVEL.ERROR, 'Failed', error.error ? error.error.message : '', true, 2000);
                    _this.dialogService
                        .confirm(confirmError)
                        .subscribe(function (res) {
                        _this.isRequestCompleted = true;
                        if (res === true) {
                        }
                    });
                });
            }
        });
    };
    ReservationSearchResultsComponent.prototype.onClickMerge = function () {
        var _this = this;
        var confirmSaveSplit = new DialogModel(true, LEVEL.WARNING, 'Confirm Merge', 'Do you want to merge the split items', true, 2000, null, 'No', 'Yes');
        confirmSaveSplit.disableClose = true;
        this.dialogService
            .confirm(confirmSaveSplit)
            .subscribe(function (res) {
            if (res === true) {
                console.log('merge selected');
                _this.isRequestCompleted = false;
                var selectedServiceItemIds = _this.getSelectedServiceItems();
                if (selectedServiceItemIds.length > 1) {
                    _this.trsReservationManagementService.mergeServiceItems(selectedServiceItemIds).subscribe(function (result) {
                        _this.isRequestCompleted = true;
                        // refresh result again
                        _this.retrieveSelectedGroup();
                        _this.common.showSnackBar('Successfully merged service items.', 3000, TcErrorType.TYPE.INFO);
                    }, function (error) {
                        console.log(error);
                        _this.isRequestCompleted = true;
                        var confirmError = new DialogModel(true, LEVEL.ERROR, 'Failed', "" + error.error.message, true, 2000);
                        _this.dialogService
                            .confirm(confirmError)
                            .subscribe(function (res) {
                            if (res === true) {
                                console.log('true');
                                /* this.dataStore.set(DataKey.cart, null, true);
                                 this.spinnerService.show();
                                 */
                            }
                        });
                    });
                }
            }
        });
    };
    ReservationSearchResultsComponent.prototype.getSelectedServiceItems = function () {
        var selectedList = [];
        this.serviceItemChangesInSelectedGroup.forEach(function (value) {
            if (value.checked) {
                selectedList.push(value.serviceItemId);
            }
        });
        return selectedList;
    };
    ReservationSearchResultsComponent.prototype.mapItemSearchCriteriaToDocumentCriteria = function (documentCriteria) {
        documentCriteria.searchStartDate = this.searchCriteria.startDate;
        documentCriteria.searchEndDate = this.searchCriteria.endDate;
        // documentCriteria.size = this.searchCriteria.size;
        if (this.searchCriteria.bookingDate) {
            documentCriteria.bookingDate = this.searchCriteria.bookingDate;
        }
        if (this.searchCriteria.daysFrom) {
            documentCriteria.daysFrom = this.searchCriteria.daysFrom;
        }
        if (this.searchCriteria.resourceType) {
            documentCriteria.categoryCode = this.searchCriteria.resourceType;
        }
        if (this.searchCriteria.assignedStatus) {
            documentCriteria.assignStatus = this.searchCriteria.assignedStatus;
        }
        if (this.searchCriteria.supplierId) {
            documentCriteria.servicingSupplierId = this.searchCriteria.supplierId;
        }
        if (this.searchCriteria.resourceIdList) {
            documentCriteria.resourceIdList = this.searchCriteria.resourceIdList;
        }
        documentCriteria.route = this.searchCriteria.route;
        documentCriteria.dropOffLocation = this.searchCriteria.dropOffLocationCode;
        documentCriteria.pickupLocation = this.searchCriteria.pickUpLocationCode;
        documentCriteria.dropOffCity = this.searchCriteria.dropOffCity;
        documentCriteria.dropOffType = this.searchCriteria.dropOffType;
        documentCriteria.pickupCity = this.searchCriteria.pickupCity;
        documentCriteria.pickupType = this.searchCriteria.pickupType;
        documentCriteria.bookingRefId = this.searchCriteria.bookingRefId;
        documentCriteria.transferType = this.searchCriteria.transferType;
        documentCriteria.transferMode = this.searchCriteria.transferMode;
        documentCriteria.deptFltNumber = this.searchCriteria.flightDeparture;
        documentCriteria.arrFltNumber = this.searchCriteria.flightArrival;
        documentCriteria.arrTerminal = this.searchCriteria.flightArrTerminal;
        documentCriteria.arrTime = this.searchCriteria.flightArrivalTime;
        documentCriteria.deptTime = this.searchCriteria.flightDepartureTime;
        documentCriteria.deptTerminal = this.searchCriteria.flightDepTerminal;
        documentCriteria.serviceItemAttributes = this.searchCriteria.serviceItemAttributes;
    };
    ReservationSearchResultsComponent.prototype.addGroupNote = function () {
        if (this.selectedServiceGroupNotes) {
            var serviceNote = new ServiceNote();
            serviceNote.tempId = this.addingServiceNoteCount++;
            this.selectedServiceGroupNotes.push(serviceNote);
        }
        else {
            this.selectedServiceGroupNotes = [];
            var serviceNote = new ServiceNote();
            serviceNote.tempId = this.addingServiceNoteCount++;
            this.selectedServiceGroupNotes.push(serviceNote);
        }
    };
    ReservationSearchResultsComponent.prototype.addEmptyGroupNote = function () {
        if (this.selectedServiceGroupNotes === undefined || this.selectedServiceGroupNotes.length == 0) {
            var serviceNote = new ServiceNote();
            serviceNote.tempId = this.addingServiceNoteCount++;
            this.selectedServiceGroupNotes.push(serviceNote);
        }
    };
    ReservationSearchResultsComponent.prototype.onDelete = function (event) {
        if (event.tempId) {
            this.selectedServiceGroupNotes = this.selectedServiceGroupNotes.filter(function (value) {
                return value.tempId !== event.tempId;
            });
        }
        if (event.serviceNoteId) {
            this.deleteNote(event.serviceNoteId);
        }
    };
    ReservationSearchResultsComponent.prototype.deleteNote = function (serviceNoteId) {
        var _this = this;
        var confirmDataObject = new DialogModel(true, LEVEL.WARNING, 'Delete Note', 'This Note will be deleted. Do you wish to proceed?', true, 3000);
        confirmDataObject.disableClose = true;
        this.dialogService.confirm(confirmDataObject).subscribe(function (res) {
            if (res === true) {
                _this.deleteItemNote(serviceNoteId);
            }
        });
    };
    ReservationSearchResultsComponent.prototype.deleteItemNote = function (serviceNoteId) {
        var _this = this;
        this.trsReservationManagementService.deleteGroupNote(serviceNoteId).subscribe(function (result) {
            _this.selectedServiceGroupNotes = _this.selectedServiceGroupNotes.filter(function (value) {
                return value.serviceNoteId !== serviceNoteId;
            });
            _this.isGroupNoteEditClicked = false;
            _this.common.showSnackBar('Successfully deleted the note', 3000, TcErrorType.TYPE.INFO);
            // refresh service items
            _this.retrieveSelectedGroup();
        }, function (error) {
            _this.common.showSnackBar('Failed, because ' + error.error.message, 3000, TcErrorType.TYPE.ERROR);
        });
    };
    ReservationSearchResultsComponent.prototype.onNoteSave = function (event) {
        var _this = this;
        event.createdOn = Date.now();
        event.type = 'SERVICE';
        var noteWrapper = new NoteWrapper();
        noteWrapper.serviceNotes = [];
        noteWrapper.serviceNotes.push(event);
        var groupCriteria = this.selectedGroupCriteria;
        for (var key in groupCriteria) {
            if (!groupCriteria[key]) {
                groupCriteria[key] = null;
            }
        }
        // if(!this.selectedGroupCriteria.assignedStatus){groupCriteria.assignedStatus = null;}
        noteWrapper.serviceItemSearchCriteria = groupCriteria;
        var serviceNotes = this.selectedServiceGroupNotes.filter(function (value) {
            return !(value.serviceNoteId > 0) && value.note !== event.note;
        });
        // this.dataStore.set(DataKey.selectedGroupNoteTemporaryData, serviceNotes, true);
        if (this.isGroupNoteEditClicked) {
            this.trsReservationManagementService.editGroupNote(event.serviceNoteId, event).subscribe(function (result) {
                _this.common.showSnackBar('Successfully edited the note', 3000, TcErrorType.TYPE.INFO);
                // refresh service items
                _this.retrieveSelectedGroup();
                _this.isGroupNoteEditClicked = false;
            }, function (error) {
                _this.common.showSnackBar('Failed, because ' + error.error.message, 3000, TcErrorType.TYPE.ERROR);
                // refresh service items
                _this.retrieveSelectedGroup();
                _this.isGroupNoteEditClicked = false;
            });
        }
        else {
            this.trsReservationManagementService.saveGroupNote(noteWrapper).subscribe(function (result) {
                _this.common.showSnackBar('Successfully saved the note', 3000, TcErrorType.TYPE.INFO);
                // refresh service items
                _this.retrieveSelectedGroup();
                _this.isGroupNoteEditClicked = false;
            }, function (error) {
                _this.common.showSnackBar('Failed, because ' + error.error.message, 3000, TcErrorType.TYPE.ERROR);
                // refresh service items
                _this.retrieveSelectedGroup();
                _this.isGroupNoteEditClicked = false;
            });
        }
    };
    ReservationSearchResultsComponent.prototype.onEdit = function (event) {
        if (event === true) {
            this.isGroupNoteEditClicked = true;
        }
        else if (event === false) {
            this.isGroupNoteEditClicked = false;
        }
    };
    ReservationSearchResultsComponent.prototype.onViewMoreClick = function (data) {
        var _this = this;
        this.focusViewDataObserver();
        var fabActions = [];
        var navItemsArray = this.configLoader.configurations.get(TCO.CONF.CONF_NAVIGATION);
        this.configLoader.configurations.get('FOCUS_VIEW_CONFIG').RESERVATION_VIEW_MORE.fabAction
            .forEach(function (val) {
            for (var key in navItemsArray) {
                if (val === navItemsArray[key]['id']) {
                    fabActions.push(navItemsArray[key]);
                }
            }
        });
        var dataObject = new ModalData(LEVEL.SUCCESS, 'Service Item Details', null, null, 'price-breakdown-focus-view', ServiceItemDetailFocusViewComponent, {
            'selectedServiceItem': data
        }, { 'label': 'close' }, '', '', fabActions, null, 'Booking ID : ' + data.bookingId);
        dataObject.disableClose = true;
        this.focusViewService.confirm(dataObject).subscribe(function (res) {
            if (_this.isFocusViewDataChanged) {
                _this.retrieveSelectedGroup();
            }
        });
    };
    ReservationSearchResultsComponent.prototype.focusViewDataObserver = function () {
        var _this = this;
        this.focusViewDataChangeObserver = this.dataStore.get(DataKey.focusViewDataChanged).subscribe(function (isDataChanged) {
            if (isDataChanged) {
                _this.focusViewDataChangeObserver.unsubscribe();
            }
            _this.isFocusViewDataChanged = isDataChanged;
        });
    };
    ReservationSearchResultsComponent.prototype.onCardScroll = function ($event) {
        var _this = this;
        setTimeout(function () {
            _this.autoScrollService.scrollToID('clientDetailExp_' + $event, 'main-scroll-container');
        }, 0);
    };
    ReservationSearchResultsComponent.prototype.onSaveSearchCriteriaClick = function () {
        var _this = this;
        this.dataStore.set(DataKey.popupClose, null);
        var dataObject = new ModalData(LEVEL.SUCCESS, 'Save Criteria', null, null, 'modal-basic--alt modal-basic--fixed-size', SearchCriteriaSaveModalComponent, { 'searchCriteria': this.searchCriteria });
        dataObject.disableClose = true;
        var secondaryHeader = '';
        dataObject.secondaryHeader = secondaryHeader;
        this.confirmModal(dataObject);
        this.dataStore.get(DataKey.popupClose).subscribe(function (data) {
            if (data != null) {
                _this.closeModal();
            }
        });
    };
    ReservationSearchResultsComponent.prototype.shouldCheckItemAfterSave = function (item) {
        if (this.savedServiceItems.includes(item.serviceItemId)) {
            return item.serviceAssignments && ServiceAssignmentUtilService.isAssignedAnyTrs(item.serviceAssignments);
        }
        return false;
    };
    ReservationSearchResultsComponent.prototype.ngOnDestroy = function () {
        if (this.reservationResultsSubscription) {
            this.reservationResultsSubscription.unsubscribe();
        }
        if (this.reservationResultsFromServiceSubscription) {
            this.reservationResultsFromServiceSubscription.unsubscribe();
        }
        if (this.reservationResultsFromServiceOnMoreSubscription) {
            this.reservationResultsFromServiceOnMoreSubscription.unsubscribe();
        }
        if (this.reservationSearchCriteriaSubscription) {
            this.reservationSearchCriteriaSubscription.unsubscribe();
        }
        if (this.splitPassengersModalCloseSubscription) {
            this.splitPassengersModalCloseSubscription.unsubscribe();
        }
        if (this.focusviewCloseSubject) {
            this.focusviewCloseSubject.unsubscribe();
        }
        if (this.focusViewDataChangeObserver) {
            this.focusViewDataChangeObserver.unsubscribe();
        }
        if (this.popupDataChangeObserver) {
            this.popupDataChangeObserver.unsubscribe();
        }
        if (this.vehicleResultObsv) {
            this.vehicleResultObsv.unsubscribe();
        }
        if (this.selectedGroupItemsObserver) {
            this.selectedGroupItemsObserver.unsubscribe();
        }
        this.em.removeEvents([
            'EXPORT', 'SAVE_CRITERIA', 'SEND_MANIFESTS'
        ]);
    };
    ///////////// Smart key Assignment related operations ////////////////////////////////////
    //get search results
    // set this.filteredSuppliers
    ReservationSearchResultsComponent.prototype.onVehicleSupplierTextChange = function ($event) {
        var _this = this;
        var supplierSearchCriteria = this.getSupplierSearchCriteria($event);
        var serviceItem = $event.serviceItem.data.value['rawData'];
        supplierSearchCriteria.resourceTypes = [];
        supplierSearchCriteria.resourceTypes.push(ResourceType.vehicle);
        supplierSearchCriteria.transferMode = serviceItem.trsServiceItem.transferMode;
        supplierSearchCriteria.route = serviceItem.trsServiceItem.route;
        supplierSearchCriteria.vehicleModel = serviceItem.trsServiceItem.vehicleModel ?
            serviceItem.trsServiceItem.vehicleModel :
            this.defaultVehicleModel;
        var previousAssignmentDetail = serviceItem.serviceAssignments.find(function (value) { return value.categoryCode ===
            ResourceType.vehicle; });
        supplierSearchCriteria.rejectedSupplierIdList = previousAssignmentDetail ?
            previousAssignmentDetail.rejectedSupplierIdList :
            [];
        this.supplierService.searchSuppliersForAssignmentNoDataKey(supplierSearchCriteria)
            .subscribe(function (result) {
            _this.filteredVehicleSuppliers = ResponseUtil.getDataArray(result);
        }, function (error) {
            _this.filteredVehicleSuppliers = [];
        });
    };
    ReservationSearchResultsComponent.prototype.onDriverSupplierTextChange = function ($event) {
        var _this = this;
        var serviceItem = $event.serviceItem.data.value['rawData'];
        var supplierSearchCriteria = this.getSupplierSearchCriteria($event);
        supplierSearchCriteria.resourceTypes = [];
        supplierSearchCriteria.resourceTypes.push(ResourceType.driver);
        var previousAssignmentDetail = serviceItem.serviceAssignments.find(function (value) { return value.categoryCode ===
            ResourceType.driver; });
        supplierSearchCriteria.rejectedSupplierIdList = previousAssignmentDetail ?
            previousAssignmentDetail.rejectedSupplierIdList :
            [];
        this.supplierService.searchDriverSuppliersForAssignmentNoDataKey(supplierSearchCriteria)
            .subscribe(function (result) {
            _this.filteredDriverSuppliers = ResponseUtil.getDataArray(result);
        }, function (error) {
            _this.filteredDriverSuppliers = [];
        });
    };
    ReservationSearchResultsComponent.prototype.getSupplierSearchCriteria = function ($event) {
        var codeOrName = '';
        if ($event.text !== undefined) {
            codeOrName = $event.text;
        }
        var serviceItem = $event.serviceItem.data.value['rawData'];
        var supplierSearchCriteria = new SupplierSearchCriteria();
        supplierSearchCriteria.codeOrName = codeOrName;
        supplierSearchCriteria.status = 'true';
        supplierSearchCriteria.serviceDate = serviceItem.serviceDate;
        supplierSearchCriteria.start = 0;
        supplierSearchCriteria.size = 10;
        return supplierSearchCriteria;
    };
    ReservationSearchResultsComponent.prototype.onVehicleTextChange = function ($event) {
        if ($event.text !== undefined ||
            (this.serviceItemChangesInSelectedGroup.get($event.serviceItemId).vehicleSupplier)) {
            var serviceItem = $event.serviceItem.data.value['rawData'];
            var vehicleSearchCriteria = new VehicleSearchCriteria();
            vehicleSearchCriteria.activeStatus = true;
            vehicleSearchCriteria.vehicleName = $event.text;
            vehicleSearchCriteria.resourceType = ResourceType.vehicle;
            vehicleSearchCriteria.transferMode = serviceItem.trsServiceItem.transferMode;
            vehicleSearchCriteria.vehicleModel = serviceItem.trsServiceItem.vehicleModel ?
                serviceItem.trsServiceItem.vehicleModel :
                null;
            vehicleSearchCriteria.serviceDate = serviceItem.serviceDate;
            vehicleSearchCriteria.route = serviceItem.trsServiceItem.route;
            vehicleSearchCriteria.start = 0;
            vehicleSearchCriteria.size = 10;
            vehicleSearchCriteria.serviceItems = serviceItem.serviceItemId.toString();
            var previousAssignmentDetail = serviceItem.serviceAssignments.find(function (value) { return value.categoryCode ===
                ResourceType.vehicle; });
            vehicleSearchCriteria.rejectedResourceIdList = previousAssignmentDetail ?
                previousAssignmentDetail.rejectedResourceIdList :
                [];
            // todo. check assignment map for assigned supplier for this serviceItem
            if (this.serviceItemChangesInSelectedGroup.get($event.serviceItemId).vehicleSupplier &&
                this.serviceItemChangesInSelectedGroup.get($event.serviceItemId).vehicleSupplier.supplierId) {
                vehicleSearchCriteria.supplierId = this.serviceItemChangesInSelectedGroup.get($event.serviceItemId).vehicleSupplier.supplierId;
            }
            this.trsReservationManagementService.searchRecommendedVehiclesWithDataKey(vehicleSearchCriteria);
            this.subscribeForVehicleSearchResults();
        }
        else {
            this.filteredVehicles = [];
        }
    };
    ReservationSearchResultsComponent.prototype.subscribeForVehicleSearchResults = function () {
        var _this = this;
        this.vehicleResultObsv = this.dataStore.get(DataKey.resourceAssignVehicleSearchResults).subscribe(function (result) {
            if (result) {
                if (result) {
                    _this.filteredVehicles = ResponseUtil.getDataArray(result);
                }
                else {
                    _this.filteredVehicles = [];
                }
            }
            else {
                _this.filteredVehicles = [];
            }
        }, function (error) {
            _this.filteredVehicles = [];
        });
    };
    ReservationSearchResultsComponent.prototype.onDriverTextChange = function ($event) {
        if ($event.text !== undefined ||
            (this.serviceItemChangesInSelectedGroup.get($event.serviceItemId).driverSupplier)) {
            var serviceItem = $event.serviceItem.data.value['rawData'];
            var driverSearchCriteria = new GenericSearchCriteria();
            driverSearchCriteria.routes = [];
            driverSearchCriteria.transferModes = [];
            driverSearchCriteria.activeStatus = 'true';
            driverSearchCriteria.name = $event.text;
            driverSearchCriteria.resourceType = ResourceType.driver;
            driverSearchCriteria.transferMode = serviceItem.trsServiceItem.transferMode;
            driverSearchCriteria.transferModes.push(serviceItem.trsServiceItem.transferMode);
            driverSearchCriteria.serviceDate = serviceItem.serviceDate;
            driverSearchCriteria.route = serviceItem.trsServiceItem.route;
            driverSearchCriteria.serviceItems = serviceItem.serviceItemId.toString();
            driverSearchCriteria.start = 0;
            driverSearchCriteria.size = 1000000;
            var previousAssignmentDetail = serviceItem.serviceAssignments.find(function (value) { return value.categoryCode ===
                ResourceType.driver; });
            driverSearchCriteria.rejectedResourceIdList = previousAssignmentDetail ?
                previousAssignmentDetail.rejectedResourceIdList :
                [];
            // todo. check assignment map for assigned supplier for this serviceItem
            if (this.serviceItemChangesInSelectedGroup.get($event.serviceItemId).driverSupplier &&
                this.serviceItemChangesInSelectedGroup.get($event.serviceItemId).driverSupplier.supplierId) {
                driverSearchCriteria.supplierId = this.serviceItemChangesInSelectedGroup.get($event.serviceItemId).driverSupplier.supplierId;
            }
            this.genericResourceService.searchRecommendedDriversForAllocation(driverSearchCriteria);
            this.subscribeForDriverSearchResults();
        }
        else {
            this.filteredDrivers = [];
        }
    };
    ReservationSearchResultsComponent.prototype.subscribeForDriverSearchResults = function () {
        var _this = this;
        this.dataStore.get(DataKey.resourceAssignDriversSearchResults).subscribe(function (result) {
            _this.filteredDrivers = ResponseUtil.getDataArray(result);
        }, function (error) {
            _this.filteredDrivers = [];
        });
    };
    ReservationSearchResultsComponent.prototype.onVehicleJobNoChange = function (event) {
    };
    ReservationSearchResultsComponent.prototype.onDriverJobNoChange = function (event) {
    };
    // select supplier
    ReservationSearchResultsComponent.prototype.onSelectVehicleSupplier = function ($event) {
        var _this = this;
        this.autoAssignResults = [];
        this.exit = false;
        this.selectedServiceItem = this.serviceItemsInSelectedGroup.find(function (value) { return value.serviceItemId === $event.serviceItemId; });
        var selectedIds = new Set();
        this.serviceItemChangesInSelectedGroup.forEach(function (value) {
            if (value.checked) {
                selectedIds.add(value.serviceItemId);
            }
        });
        selectedIds.add($event.serviceItemId);
        if (selectedIds.size > 1) {
            var confirmSuccess = new DialogModel(true, LEVEL.WARNING, 'Multiple Assign', "\n                        There are multiple selected item in this group.\n                        Do you wish to assign for all selected items ?", true, 2000, null, 'Assign only for added one', 'Yes', true);
            confirmSuccess.disableClose = true;
            this.dialogService
                .confirm(confirmSuccess)
                .subscribe(function (res) {
                var e_4, _a;
                if (res === true) {
                    try {
                        for (var _b = tslib_1.__values(Array.from(selectedIds.values())), _c = _b.next(); !_c.done; _c = _b.next()) {
                            var itemId = _c.value;
                            _this.setVehicleSupplierToAssignment(itemId, $event.vehicleSupplier);
                        }
                    }
                    catch (e_4_1) { e_4 = { error: e_4_1 }; }
                    finally {
                        try {
                            if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                        }
                        finally { if (e_4) throw e_4.error; }
                    }
                }
                else {
                    _this.setVehicleSupplierToAssignment($event.serviceItemId, $event.vehicleSupplier);
                }
            });
        }
        else {
            this.setVehicleSupplierToAssignment($event.serviceItemId, $event.vehicleSupplier);
        }
    };
    ReservationSearchResultsComponent.prototype.onSelectDriverSupplier = function ($event) {
        var _this = this;
        this.autoAssignResults = [];
        this.exit = false;
        this.selectedServiceItem = this.serviceItemsInSelectedGroup.find(function (value) { return value.serviceItemId === $event.serviceItemId; });
        var selectedIds = new Set();
        this.serviceItemChangesInSelectedGroup.forEach(function (value) {
            if (value.checked) {
                selectedIds.add(value.serviceItemId);
            }
        });
        selectedIds.add($event.serviceItemId);
        if (selectedIds.size > 1) {
            var confirmSuccess = new DialogModel(true, LEVEL.WARNING, 'Multiple Assign', "\n                        There are multiple selected item in this group.\n                        Do you wish to assign for all selected items ?", true, 2000, null, 'Assign only for added one', 'Yes', true);
            confirmSuccess.disableClose = true;
            this.dialogService
                .confirm(confirmSuccess)
                .subscribe(function (res) {
                var e_5, _a;
                if (res === true) {
                    try {
                        for (var _b = tslib_1.__values(Array.from(selectedIds.values())), _c = _b.next(); !_c.done; _c = _b.next()) {
                            var itemId = _c.value;
                            _this.setDriverSupplierToAssignment(itemId, $event.driverSupplier);
                        }
                    }
                    catch (e_5_1) { e_5 = { error: e_5_1 }; }
                    finally {
                        try {
                            if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                        }
                        finally { if (e_5) throw e_5.error; }
                    }
                }
                else {
                    _this.setDriverSupplierToAssignment($event.serviceItemId, $event.driverSupplier);
                }
            });
        }
        else {
            this.setDriverSupplierToAssignment($event.serviceItemId, $event.driverSupplier);
        }
    };
    ReservationSearchResultsComponent.prototype.setVehicleSupplierToAssignment = function (serviceItemId, supplier) {
        var _this = this;
        var serviceItem = this.serviceItemsInSelectedGroup.find(function (value) { return value.serviceItemId === serviceItemId; });
        this.serviceItemChangesInSelectedGroup.get(serviceItemId).changed = true;
        this.getSupplierCostData(supplier).then(function (value) {
            var assignment = new ServiceAssignment();
            assignment = _this.getSupplierAssignmentMap(assignment, supplier, serviceItemId);
            _this.serviceItemChangesInSelectedGroup.get(serviceItemId).vehicleSupplier = supplier;
            assignment.categoryCode = ResourceType.vehicle;
            _this.serviceItemChangesInSelectedGroup.get(serviceItemId).serviceAssignments = _this.serviceItemChangesInSelectedGroup.get(serviceItemId).serviceAssignments ?
                _this.serviceItemChangesInSelectedGroup.get(serviceItemId).serviceAssignments :
                new Map();
            _this.serviceItemChangesInSelectedGroup.get(serviceItemId).serviceAssignments
                .set(ResourceType.vehicle, assignment);
            _this.serviceItemChangesInSelectedGroup.get(serviceItemId).vehicleCost = _this.costCalculationUtilsService.calculateServiceItemJobCost(serviceItem, assignment);
            _this.changesAvailableForServiceItems = true;
            _this.changeDetector.notifyChange();
        });
    };
    ReservationSearchResultsComponent.prototype.setDriverSupplierToAssignment = function (serviceItemId, supplier) {
        var _this = this;
        var serviceItem = this.serviceItemsInSelectedGroup.find(function (value) { return value.serviceItemId === serviceItemId; });
        this.serviceItemChangesInSelectedGroup.get(serviceItemId).changed = true;
        this.getSupplierCostData(supplier).then(function (value) {
            var assignment = new ServiceAssignment();
            assignment = _this.getSupplierAssignmentMap(assignment, supplier, serviceItemId);
            _this.serviceItemChangesInSelectedGroup.get(serviceItemId).driverSupplier = supplier;
            assignment.categoryCode = ResourceType.driver;
            _this.serviceItemChangesInSelectedGroup.get(serviceItemId).serviceAssignments = _this.serviceItemChangesInSelectedGroup.get(serviceItemId).serviceAssignments ?
                _this.serviceItemChangesInSelectedGroup.get(serviceItemId).serviceAssignments :
                new Map();
            _this.serviceItemChangesInSelectedGroup.get(serviceItemId).serviceAssignments
                .set(ResourceType.driver, assignment);
            _this.serviceItemChangesInSelectedGroup.get(serviceItemId).driverCost = _this.costCalculationUtilsService.calculateServiceItemJobCost(serviceItem, assignment);
            _this.changesAvailableForServiceItems = true;
            _this.changeDetector.notifyChange();
        });
    };
    ReservationSearchResultsComponent.prototype.getSupplierCostData = function (supplier) {
        var _this = this;
        return new Promise(function (resolve) {
            if (supplier.unitFare) {
                supplier.isUnitFare = true;
                supplier.estimatedTotalCost = supplier.totalCost;
                if (!supplier.capacity) {
                    _this.getTransferModeCapacity().then(function (value) {
                        supplier.capacity = value;
                        supplier.totalCost = _this.costCalculationUtilsService.getSupplierTotalFromUnitCost(supplier, false, _this.selectedServiceItem, null);
                        resolve(supplier);
                    });
                    // supplier.capacity = trsMode ? trsMode['__zone_symbol__value'] : 1;
                }
                else {
                    supplier.estimatedTotalCost = supplier.totalCost;
                    supplier.totalCost = _this.costCalculationUtilsService.getSupplierTotalFromUnitCost(supplier, false, _this.selectedServiceItem, null);
                    resolve(supplier);
                }
            }
            else {
                supplier.isUnitFare = false;
                var passengerCount = _this.costCalculationUtilsService.getTotalPassengerCount(false, _this.selectedServiceItem, null);
                var costSummary = _this.costCalculationUtilsService.getSupplierTotalCost(supplier, false, _this.selectedServiceItem, null);
                supplier.estimatedTotalCost = supplier.totalCost;
                supplier.totalCost = costSummary.totalCost;
                supplier.totalAdultFare = costSummary.adultFare;
                supplier.totalChildFare = costSummary.childFare;
                supplier.totalInfantFare = costSummary.infantFare;
                resolve(supplier);
            }
        });
    };
    ReservationSearchResultsComponent.prototype.getSupplierAssignmentMap = function (assignment, supplier, serviceItemId) {
        assignment.tempAssignmentId = assignment.tempAssignmentId ?
            assignment.tempAssignmentId :
            'Temp_' + this.identifierService.getNextManuallyResetId();
        assignment.supplierId = supplier.supplierId;
        assignment.supplierCode = supplier.supplierCode;
        assignment.supplierName = supplier.name;
        assignment.supplierCode = supplier.code;
        assignment.currency = supplier.currency;
        assignment.adults = this.selectedServiceItem.adult;
        assignment.children = this.selectedServiceItem.child;
        assignment.infants = this.selectedServiceItem.infant;
        assignment.adultPrice = supplier.adultFare;
        assignment.childPrice = supplier.childFare;
        assignment.infantPrice = supplier.infantFare;
        assignment.unitPrice = supplier.unitFare;
        assignment.jobCost = supplier.totalCost;
        assignment.estimatedCost = supplier.estimatedTotalCost ?
            supplier.estimatedTotalCost :
            supplier.totalCost;
        assignment.serviceItemIdList = [];
        assignment.serviceItemId = serviceItemId;
        assignment.jobDate = this.selectedServiceItem.serviceDate;
        assignment.updated = true;
        assignment.assignStatus = assignment.assignStatus ? assignment.assignStatus : AssignmentStatus.PENDING;
        return assignment;
    };
    // select vehicle
    ReservationSearchResultsComponent.prototype.onSelectVehicle = function (event) {
        this.autoAssignResults = [];
        this._onSelectVehicle(event);
    };
    ReservationSearchResultsComponent.prototype._onSelectVehicle = function ($event) {
        var _this = this;
        this.exit = false;
        this.selectedServiceItem = this.serviceItemsInSelectedGroup.find(function (value) { return value.serviceItemId === $event.serviceItemId; });
        var selectedIds = new Set();
        this.serviceItemChangesInSelectedGroup.forEach(function (value) {
            if (value.checked) {
                selectedIds.add(value.serviceItemId);
            }
        });
        selectedIds.add($event.serviceItemId);
        if (selectedIds.size > 1) {
            var confirmSuccess = new DialogModel(true, LEVEL.WARNING, 'Multiple Assign', "\n                        There are multiple selected item in this group.\n                        Do you wish to assign for all selected items ?", true, 2000, null, 'Assign only for added one', 'Yes', true);
            confirmSuccess.disableClose = true;
            this.dialogService
                .confirm(confirmSuccess)
                .subscribe(function (res) {
                var e_6, _a;
                if (res === true) {
                    try {
                        for (var _b = tslib_1.__values(Array.from(selectedIds.values())), _c = _b.next(); !_c.done; _c = _b.next()) {
                            var itemId = _c.value;
                            _this.setVehicleToAssignment(itemId, $event.vehicle);
                            // let supplier = $event.vehicle.trsSupplier;
                            // this.setVehicleSupplierToAssignment(itemId, supplier);
                        }
                    }
                    catch (e_6_1) { e_6 = { error: e_6_1 }; }
                    finally {
                        try {
                            if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                        }
                        finally { if (e_6) throw e_6.error; }
                    }
                }
                else {
                    _this.setVehicleToAssignment($event.serviceItemId, $event.vehicle);
                    // let supplier = $event.vehicle.trsSupplier;
                    // this.setVehicleSupplierToAssignment($event.serviceItemId, supplier);
                }
            });
        }
        else {
            this.setVehicleToAssignment($event.serviceItemId, $event.vehicle);
        }
        this.changeDetector.notifyChange();
    };
    // set vehicle assignment
    ReservationSearchResultsComponent.prototype.setVehicleToAssignment = function (serviceItemId, vehicle) {
        var serviceItem = this.serviceItemsInSelectedGroup.find(function (value) { return value.serviceItemId === serviceItemId; });
        this.serviceItemChangesInSelectedGroup.get(serviceItemId).changed = true;
        this.serviceItemChangesInSelectedGroup.get(serviceItemId).vehicle = vehicle;
        this.serviceItemChangesInSelectedGroup.get(serviceItemId).vehicleSupplier = vehicle.trsSupplier;
        var assignmentMap = new ServiceAssignment();
        var selectedAssignment = vehicle.assignments ?
            vehicle.assignments.filter(function (value) { return value.recommended; })[0] :
            null;
        assignmentMap = this.getResourceAssignmentMap(selectedAssignment, vehicle, serviceItemId);
        if (selectedAssignment) {
            assignmentMap.assignmentId = selectedAssignment.assignmentId;
            var vehicleHasDriver = selectedAssignment.childrenAssignments ?
                selectedAssignment.childrenAssignments.find(function (value) { return value.categoryCode === ResourceType.driver; }) :
                null;
            if (vehicleHasDriver) {
                assignmentMap.childrenAssignments = [];
                assignmentMap.childrenAssignments.push(this.setChildAssignment(serviceItemId, vehicleHasDriver));
            }
        }
        else {
            assignmentMap.tempAssignmentId = 'Temp_' + this.identifierService.getNextManuallyResetId();
        }
        assignmentMap.resourceId = vehicle.resourceId;
        assignmentMap.categoryCode = ResourceType.vehicle;
        this.serviceItemChangesInSelectedGroup.get(serviceItemId).serviceAssignments
            .set(ResourceType.vehicle, assignmentMap);
        this.serviceItemChangesInSelectedGroup.get(serviceItemId).vehicleCost = this.costCalculationUtilsService.calculateServiceItemJobCost(serviceItem, assignmentMap);
        this.changesAvailableForServiceItems = true;
        this.changeDetector.notifyChange();
    };
    ReservationSearchResultsComponent.prototype.getResourceAssignmentMap = function (selectedAssignment, resource, serviceItemId) {
        var assignment = new ServiceAssignment();
        var supplier = resource.trsSupplier ? resource.trsSupplier : resource.driverSupplier;
        supplier.totalCost = supplier.totalCost ?
            supplier.totalCost :
            this.getTotalCost(supplier, this.selectedServiceItem, resource.capacity);
        assignment = this.getSupplierAssignmentMap(assignment, supplier, serviceItemId);
        assignment.assignmentId = selectedAssignment ? selectedAssignment.assignmentId : 0;
        assignment = this.mapServiceItemDataToAssignment(assignment, serviceItemId);
        assignment.resourceId = resource.resourceId;
        assignment.resourceName = resource.resourceName;
        this.unSavedAssignments.push(resource);
        return assignment;
    };
    ReservationSearchResultsComponent.prototype.mapServiceItemDataToAssignment = function (assignment, serviceItemId) {
        var serviceItem = this.serviceItemsInSelectedGroup.find(function (value) { return value.serviceItemId === serviceItemId; });
        if (assignment.startTime && assignment.endTime && assignment.waitingEndTime) {
            if (assignment.startTime > this.getTimeFormatFromTimestamp(serviceItem.trsServiceItem.departureDateTime)) {
                assignment.startTime = this.getTimeFormatFromTimestamp(serviceItem.trsServiceItem.departureDateTime);
            }
            if (assignment.endTime < this.getTimeFormatFromTimestamp(serviceItem.trsServiceItem.arrivalDateTime)) {
                assignment.startTime = this.getTimeFormatFromTimestamp(serviceItem.trsServiceItem.arrivalDateTime);
            }
        }
        else {
            if (serviceItem.trsServiceItem.arrivalDateTime > serviceItem.trsServiceItem.departureDateTime) {
                assignment.startTime = this.getTimeFormatFromTimestamp(serviceItem.trsServiceItem.departureDateTime);
                assignment.endTime = this.getTimeFormatFromTimestamp(serviceItem.trsServiceItem.arrivalDateTime);
                assignment.waitingEndTime = this.getTimeFormatFromTimestamp(serviceItem.trsServiceItem.departureDateTime);
            }
            else {
                assignment.startTime = this.getTimeFormatFromTimestamp(serviceItem.trsServiceItem.arrivalDateTime);
                assignment.endTime = this.getTimeFormatFromTimestamp(serviceItem.trsServiceItem.departureDateTime);
                assignment.waitingEndTime = this.getTimeFormatFromTimestamp(serviceItem.trsServiceItem.arrivalDateTime);
            }
        }
        assignment.adults = this.selectedServiceItem.adult;
        assignment.children = this.selectedServiceItem.child;
        assignment.infants = this.selectedServiceItem.infant;
        assignment.startLocationGroup = serviceItem.trsServiceItem.pickupPointCode.substring(0, 3);
        assignment.endLocationGroup = serviceItem.trsServiceItem.dropoffPointCode.substring(0, 3);
        assignment.assignmentSaveOperation = AssignmentSaveOperation.SAVE;
        return assignment;
    };
    ReservationSearchResultsComponent.prototype.getTimeFormatFromTimestamp = function (dateTime) {
        return moment(dateTime).format('HH:mm');
    };
    ReservationSearchResultsComponent.prototype.onSelectDriver = function ($event) {
        var _this = this;
        this.autoAssignResults = [];
        this.exit = false;
        this.selectedServiceItem = this.serviceItemsInSelectedGroup.find(function (value) { return value.serviceItemId === $event.serviceItemId; });
        var selectedIds = new Set();
        this.serviceItemChangesInSelectedGroup.forEach(function (value) {
            if (value.checked) {
                selectedIds.add(value.serviceItemId);
            }
        });
        selectedIds.add($event.serviceItemId);
        if (selectedIds.size > 1) {
            var confirmSuccess = new DialogModel(true, LEVEL.WARNING, 'Multiple Assign', "\n                        There are multiple selected item in this group.\n                        Do you wish to assign for all selected items ?", true, 2000, null, 'Assign only for added one', 'Yes', true);
            confirmSuccess.disableClose = true;
            this.dialogService
                .confirm(confirmSuccess)
                .subscribe(function (res) {
                var e_7, _a;
                if (res === true) {
                    try {
                        for (var _b = tslib_1.__values(Array.from(selectedIds.values())), _c = _b.next(); !_c.done; _c = _b.next()) {
                            var itemId = _c.value;
                            _this.setDriverToAssignment(itemId, $event.driver);
                            // let supplier = $event.driver.driverSupplier;
                            // this.setSupplierToAssignment(itemId, supplier);
                        }
                    }
                    catch (e_7_1) { e_7 = { error: e_7_1 }; }
                    finally {
                        try {
                            if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                        }
                        finally { if (e_7) throw e_7.error; }
                    }
                }
                else {
                    _this.setDriverToAssignment($event.serviceItemId, $event.driver);
                    // let supplier = $event.driver.driverSupplier;
                    // this.setSupplierToAssignment($event.serviceItemId, supplier);
                }
            });
        }
        else {
            this.setDriverToAssignment($event.serviceItemId, $event.driver);
        }
        this.changeDetector.notifyChange();
    };
    // set driver assignment
    ReservationSearchResultsComponent.prototype.setDriverToAssignment = function (serviceItemId, driver) {
        var serviceItem = this.serviceItemsInSelectedGroup.find(function (value) { return value.serviceItemId === serviceItemId; });
        this.serviceItemChangesInSelectedGroup.get(serviceItemId).changed = true;
        this.serviceItemChangesInSelectedGroup.get(serviceItemId).driver = driver;
        this.serviceItemChangesInSelectedGroup.get(serviceItemId).driverSupplier = driver.driverSupplier;
        var selectedAssignment = driver.assignments ?
            driver.assignments.filter(function (value) { return value.recommended; })[0] :
            null;
        var assignmentMap = this.getResourceAssignmentMap(selectedAssignment, driver, serviceItemId);
        if (selectedAssignment) {
            assignmentMap.assignmentId = selectedAssignment.assignmentId;
            var driverHasVehicle = selectedAssignment.parentAssignment;
            if (driverHasVehicle) {
                assignmentMap.parentAssignment = this.setParentAssignment(serviceItemId, driverHasVehicle);
            }
        }
        else {
            assignmentMap.tempAssignmentId = 'Temp_' + this.identifierService.getNextManuallyResetId();
        }
        assignmentMap.resourceId = driver.resourceId;
        assignmentMap.resourceName = driver.resourceName;
        assignmentMap.categoryCode = ResourceType.driver;
        if (this.serviceItemChangesInSelectedGroup.get(serviceItemId).serviceAssignments.get(ResourceType.vehicle)) {
            assignmentMap.parentAssignment = this.serviceItemChangesInSelectedGroup.get(serviceItemId)
                .serviceAssignments.get(ResourceType.vehicle);
        }
        this.serviceItemChangesInSelectedGroup.get(serviceItemId).serviceAssignments
            .set(ResourceType.driver, assignmentMap);
        this.serviceItemChangesInSelectedGroup.get(serviceItemId).driverCost = this.costCalculationUtilsService.calculateServiceItemJobCost(serviceItem, assignmentMap);
        this.changesAvailableForServiceItems = true;
        this.changeDetector.notifyChange();
    };
    // set vehicle from driver assignment
    ReservationSearchResultsComponent.prototype.setParentAssignment = function (serviceItemId, driverHasVehicle) {
        var parentAssignment = new ServiceAssignment();
        var vehicle = new ResourceAvailability();
        vehicle.resourceId = driverHasVehicle.resourceId;
        vehicle.resourceName = driverHasVehicle.resourceName;
        this.serviceItemChangesInSelectedGroup.get(serviceItemId).changed = true;
        this.serviceItemChangesInSelectedGroup.get(serviceItemId).vehicle = vehicle;
        parentAssignment = this.mapServiceItemDataToAssignment(parentAssignment, serviceItemId);
        this.unSavedAssignments.push(parentAssignment);
        return parentAssignment;
    };
    // set driver from vehicle assignment
    ReservationSearchResultsComponent.prototype.setChildAssignment = function (serviceItemId, vehicleHasDriver) {
        var childAssignment = new ServiceAssignment();
        var driver = new ResourceAvailability();
        driver.resourceName = childAssignment.resourceName;
        driver.resourceId = childAssignment.resourceId;
        this.serviceItemChangesInSelectedGroup.get(serviceItemId).changed = true;
        this.serviceItemChangesInSelectedGroup.get(serviceItemId).driver = driver;
        childAssignment = this.mapServiceItemDataToAssignment(childAssignment, serviceItemId);
        this.unSavedAssignments.push(childAssignment);
        return childAssignment;
    };
    // clear Assignment
    ReservationSearchResultsComponent.prototype.onClearVehicleAssignment = function ($event) {
        this.clearVehicle($event);
        this.clearVehicleSupplier($event);
    };
    // clear Assignment
    ReservationSearchResultsComponent.prototype.onClearDriverAssignment = function ($event) {
        this.clearDriver($event);
        this.clearDriverSupplier($event);
    };
    // clear Supplier
    ReservationSearchResultsComponent.prototype.clearVehicleSupplier = function (event) {
        this.autoAssignResults = [];
        this.isCleared = true;
        this.exit = false;
        this.serviceItemChangesInSelectedGroup.get(event.serviceItemId).vehicleSupplier = null;
        this.serviceItemChangesInSelectedGroup.get(event.serviceItemId).vehicle = null;
        if (this.serviceItemChangesInSelectedGroup.get(event.serviceItemId).serviceAssignments) {
            this.serviceItemChangesInSelectedGroup.get(event.serviceItemId).changed = true;
            var assignment = this.serviceItemChangesInSelectedGroup.get(event.serviceItemId).serviceAssignments
                .get(ResourceType.vehicle);
            this.removeSupplierFromAssignment(event.serviceItemId, assignment);
            this.serviceItemChangesInSelectedGroup.get(event.serviceItemId).vehicleCost = 0;
            this.changesAvailableForServiceItems = true;
        }
    };
    ReservationSearchResultsComponent.prototype.clearDriverSupplier = function (event) {
        this.isCleared = true;
        this.exit = false;
        this.serviceItemChangesInSelectedGroup.get(event.serviceItemId).driverSupplier = null;
        if (this.serviceItemChangesInSelectedGroup.get(event.serviceItemId).serviceAssignments) {
            this.serviceItemChangesInSelectedGroup.get(event.serviceItemId).changed = true;
            var assignment = this.serviceItemChangesInSelectedGroup.get(event.serviceItemId).serviceAssignments
                .get(ResourceType.driver);
            this.removeSupplierFromAssignment(event.serviceItemId, assignment);
            this.serviceItemChangesInSelectedGroup.get(event.serviceItemId).driverCost = 0;
            this.changesAvailableForServiceItems = true;
        }
    };
    ReservationSearchResultsComponent.prototype.removeSupplierFromAssignment = function (serviceItemId, assignment) {
        assignment.supplierId = undefined;
        assignment.supplierName = undefined;
        assignment.supplierCode = undefined;
        assignment.resourceId = undefined;
        assignment.resourceName = undefined;
        assignment.serviceItemId = serviceItemId;
        assignment.updated = true;
        assignment.jobCost = 0;
        assignment.unitPrice = 0;
        assignment.assignmentSaveOperation = AssignmentSaveOperation.DELETE;
        return assignment;
    };
    // clear Vehicle
    ReservationSearchResultsComponent.prototype.clearVehicle = function ($event) {
        this.autoAssignResults = [];
        this.isCleared = true;
        this.exit = false;
        this.serviceItemChangesInSelectedGroup.get($event.serviceItemId).vehicle = null;
        if (this.serviceItemChangesInSelectedGroup.get($event.serviceItemId).serviceAssignments &&
            this.serviceItemChangesInSelectedGroup.get($event.serviceItemId).serviceAssignments
                .get(ResourceType.vehicle)) {
            this.serviceItemChangesInSelectedGroup.get($event.serviceItemId).changed = true;
            var assignment = this.serviceItemChangesInSelectedGroup.get($event.serviceItemId).serviceAssignments
                .get(ResourceType.vehicle);
            if (assignment.resourceId) {
                this.serviceItemChangesInSelectedGroup.get($event.serviceItemId).serviceAssignments.set(ResourceType.vehicle, this.removeResourceFromAssignment($event.serviceItemId, this.serviceItemChangesInSelectedGroup.get($event.serviceItemId).vehicle, assignment));
            }
            if (assignment.assignmentId > 0) {
                assignment.assignmentSaveOperation = AssignmentSaveOperation.UPDATE;
            }
            if (this.serviceItemChangesInSelectedGroup.get($event.serviceItemId).serviceAssignments
                .get(ResourceType.driver)) {
                assignment.childrenAssignments = assignment.childrenAssignments ?
                    assignment.childrenAssignments.filter(function (value) { return value.categoryCode !== ResourceType.driver; }) :
                    [];
                assignment.childrenAssignments.push(this.serviceItemChangesInSelectedGroup.get($event.serviceItemId)
                    .serviceAssignments
                    .get(ResourceType.driver));
            }
            assignment.resourceId = undefined;
            assignment.resourceName = undefined;
            this.serviceItemChangesInSelectedGroup.get($event.serviceItemId).serviceAssignments
                .set(ResourceType.vehicle, assignment);
            this.changesAvailableForServiceItems = true;
        }
    };
    // clear driver
    ReservationSearchResultsComponent.prototype.clearDriver = function ($event) {
        this.isCleared = true;
        this.exit = false;
        this.serviceItemChangesInSelectedGroup.get($event.serviceItemId).driver = null;
        if (this.serviceItemChangesInSelectedGroup.get($event.serviceItemId).serviceAssignments &&
            this.serviceItemChangesInSelectedGroup.get($event.serviceItemId).serviceAssignments
                .get(ResourceType.driver)) {
            var assignment = this.serviceItemChangesInSelectedGroup.get($event.serviceItemId).serviceAssignments
                .get(ResourceType.driver);
            this.serviceItemChangesInSelectedGroup.get($event.serviceItemId).changed = true;
            assignment.resourceId = undefined;
            assignment.resourceName = undefined;
            var hasVehicleAssignment = this.serviceItemChangesInSelectedGroup.get($event.serviceItemId)
                .serviceAssignments
                .get(ResourceType.vehicle);
            assignment.updated = true;
            if (hasVehicleAssignment) {
                this.serviceItemChangesInSelectedGroup.get($event.serviceItemId)
                    .serviceAssignments
                    .get(ResourceType.vehicle).childrenAssignments = [];
            }
            if (assignment.assignmentId > 0) {
                //should set as update after view change - seperate supplier to driver
                assignment.assignmentSaveOperation = hasVehicleAssignment ?
                    AssignmentSaveOperation.DELETE :
                    AssignmentSaveOperation.UPDATE;
            }
            this.changesAvailableForServiceItems = true;
            this.serviceItemChangesInSelectedGroup.get($event.serviceItemId).serviceAssignments
                .set(ResourceType.driver, assignment);
        }
    };
    ReservationSearchResultsComponent.prototype.removeResourceFromAssignment = function (serviceItemId, vehicle, assignment) {
        assignment.resourceId = undefined;
        assignment.resourceName = undefined;
        assignment.updated = true;
        assignment.assignmentSaveOperation = AssignmentSaveOperation.UPDATE;
        return assignment;
    };
    ReservationSearchResultsComponent.prototype.sendManifests = function () {
        var _this = this;
        // this.spinnerService.show();
        var confirmSendManifests = new DialogModel(true, LEVEL.WARNING, 'Send Manifests', " Do you want to send manifests for all confirmed assignments\n                       from " + this.searchCriteria.startDate + " to " + this.searchCriteria.endDate + "?", true, 2000, null, 'Cancel', 'Send', true);
        confirmSendManifests.disableClose = true;
        this.dialogService
            .confirm(confirmSendManifests)
            .subscribe(function (res) {
            if (res === true) {
                var documentCreationCriteria = new DocumentCreationCriteria();
                documentCreationCriteria.documentFormat = DocumentFormat.pdf;
                documentCreationCriteria.documentType = DocumentType.supplierManifest;
                documentCreationCriteria.download = true;
                var documentDataCriteria = new DocumentDataCriteria();
                documentDataCriteria.searchStartDate = _this.searchCriteria.startDate;
                documentDataCriteria.searchEndDate = _this.searchCriteria.endDate;
                _this.documentService.createAndDownloadDocument(documentCreationCriteria, documentDataCriteria);
            }
        });
    };
    ReservationSearchResultsComponent.prototype.export = function (docName, persist, download) {
        // this.spinnerService.show();
        if (!this.searchCriteria.assignedStatus) {
            this.searchCriteria.assignedStatus = null;
        }
        var documentCreationCriteria = new DocumentCreationCriteria();
        documentCreationCriteria.documentFormat = DocumentFormat.excel;
        documentCreationCriteria.documentType = DocumentType.trsExportReport;
        documentCreationCriteria.documentName = docName;
        documentCreationCriteria.download = download;
        documentCreationCriteria.persist = persist;
        var documentDataCriteria = new DocumentDataCriteria();
        this.mapItemSearchCriteriaToDocumentCriteria(documentDataCriteria);
        documentDataCriteria.searchStartDate = this.searchCriteria.startDate;
        documentDataCriteria.searchEndDate = this.searchCriteria.endDate;
        documentDataCriteria.serviceType = 'TRS';
        this.documentService.createAndDownloadDocument(documentCreationCriteria, documentDataCriteria);
    };
    ReservationSearchResultsComponent.prototype.getArrivalList = function (docName, persist, download) {
        if (this.searchCriteria) {
            var documentCreationCriteria = new DocumentCreationCriteria();
            documentCreationCriteria.documentFormat = DocumentFormat.excel;
            documentCreationCriteria.documentType = DocumentType.arrivalList;
            documentCreationCriteria.documentName = docName;
            documentCreationCriteria.download = download;
            documentCreationCriteria.persist = persist;
            var documentDataCriteria = new DocumentDataCriteria();
            this.mapItemSearchCriteriaToDocumentCriteria(documentDataCriteria);
            documentDataCriteria.serviceType = 'TRS';
            documentDataCriteria.pickupType = LocationType.AIRPORT;
            this.documentService.createAndDownloadDocument(documentCreationCriteria, documentDataCriteria);
        }
    };
    ReservationSearchResultsComponent.prototype.getDepartureList = function (docName, persist, download) {
        if (this.searchCriteria) {
            var documentCreationCriteria = new DocumentCreationCriteria();
            documentCreationCriteria.documentFormat = DocumentFormat.excel;
            documentCreationCriteria.documentType = DocumentType.departureList;
            documentCreationCriteria.documentName = docName;
            documentCreationCriteria.download = download;
            documentCreationCriteria.persist = persist;
            var documentDataCriteria = new DocumentDataCriteria();
            this.mapItemSearchCriteriaToDocumentCriteria(documentDataCriteria);
            documentDataCriteria.serviceType = 'TRS';
            documentDataCriteria.dropOffType = LocationType.AIRPORT;
            this.documentService.createAndDownloadDocument(documentCreationCriteria, documentDataCriteria);
        }
    };
    ReservationSearchResultsComponent.prototype.onGenerateDocumentsClick = function () {
        var _this = this;
        this.dataStore.set(DataKey.popupClose, null);
        var documentTypes = [
            {
                name: 'Trs Export Report',
                code: DocumentType.trsExportReport,
                icon: 'unarchive'
            },
            {
                name: 'Arrival List',
                code: DocumentType.arrivalList,
                icon: 'flight_land'
            },
            {
                name: 'Departure List',
                code: DocumentType.departureList,
                icon: 'flight_takeoff'
            }
        ];
        var documentCreationModel = new DocumentCreationModel();
        documentCreationModel.autoName = true;
        documentCreationModel.download = true;
        var dataObject = new ModalData(LEVEL.SUCCESS, 'Generate Documents', null, null, 'modal-basic--alt modal-basic--fixed-size', DocumentCreationModalComponent, {
            documentTypes: documentTypes,
            documentCreationModel: documentCreationModel
        });
        dataObject.disableClose = false;
        this.confirmModal(dataObject);
        var modalSubscription = this.dataStore.get(DataKey.popupClose).subscribe(function (data) {
            if (data != null) {
                modalSubscription.unsubscribe();
                _this.closeModal();
                if (data) {
                    if (documentCreationModel.documentType) {
                        switch (documentCreationModel.documentType.code) {
                            case DocumentType.arrivalList:
                                _this.getArrivalList(documentCreationModel.documentName, !!documentCreationModel.persist, !!documentCreationModel.download);
                                break;
                            case DocumentType.departureList:
                                _this.getDepartureList(documentCreationModel.documentName, !!documentCreationModel.persist, !!documentCreationModel.download);
                                break;
                            case DocumentType.trsExportReport:
                                _this.export(documentCreationModel.documentName, !!documentCreationModel.persist, !!documentCreationModel.download);
                                break;
                        }
                    }
                }
            }
        });
    };
    ReservationSearchResultsComponent.prototype.onHistoryClick = function (event) {
        var e_8, _a, e_9, _b;
        var serviceItem = event.data.value.rawData;
        if (serviceItem && serviceItem.serviceItemHistories.length > 0) {
            var historyText = [];
            try {
                for (var _c = tslib_1.__values(serviceItem.serviceItemHistories), _d = _c.next(); !_d.done; _d = _c.next()) {
                    var serviceItemHistory = _d.value;
                    if (serviceItemHistory.description) {
                        historyText.push.apply(historyText, tslib_1.__spread(serviceItemHistory.description.split('. ')));
                    }
                }
            }
            catch (e_8_1) { e_8 = { error: e_8_1 }; }
            finally {
                try {
                    if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
                }
                finally { if (e_8) throw e_8.error; }
            }
            console.log(historyText);
            var historyTextFormatted = "<ul>";
            try {
                for (var historyText_1 = tslib_1.__values(historyText), historyText_1_1 = historyText_1.next(); !historyText_1_1.done; historyText_1_1 = historyText_1.next()) {
                    var historyTextElement = historyText_1_1.value;
                    historyTextFormatted += "<li>" + historyTextElement + "</li>";
                }
            }
            catch (e_9_1) { e_9 = { error: e_9_1 }; }
            finally {
                try {
                    if (historyText_1_1 && !historyText_1_1.done && (_b = historyText_1.return)) _b.call(historyText_1);
                }
                finally { if (e_9) throw e_9.error; }
            }
            historyTextFormatted += "</ul>";
            console.log(historyTextFormatted);
            var confirmSuccess = new DialogModel(true, LEVEL.INFO, 'Service Item History', historyTextFormatted, false, 2000, null, 'Cancel', 'Ok', true);
            confirmSuccess.disableClose = true;
            this.dialogService
                .confirm(confirmSuccess)
                .subscribe(function (res) { });
        }
    };
    return ReservationSearchResultsComponent;
}());
export { ReservationSearchResultsComponent };
