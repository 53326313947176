import * as tslib_1 from "tslib";
import { OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { TC } from '@tc-core/util/constants';
import { ConfigLoader } from '@tc-core/util/framework';
import { EventManager } from '@tc-core/util/framework/event-manager.service';
import { FocusViewManager } from '@tc-core/util/framework/focus-view-manager.service';
import { FocusViewService } from '@tc/focus-view/focus-view.service';
import { ModalService } from '@tc/modal/modal.service';
import { Subscription, of } from 'rxjs';
import { SetupGridComp } from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid-comp';
import { SetupGridComponent } from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid.component';
import { TCO } from '../../../constants';
import { DispatchEntrySearchCriteria } from '../../../models/criteria/dispatch-entry-search-criteria';
import { DispatchStatus } from '../../../models/document/dispatch-entry';
import { DocumentService } from '../../../services/backend-consumers/documents/document.service';
import { DataKey, DataStoreService } from '../../../services/util/framework/data-store.service';
import { TBXResponseWrapper } from '@tc-core/model/it/codegen/tbx/api/tbx-response-wrapper';
var EMPTY_CHAR = TC.FORMAT.EMPTY_CHAR;
var DocumentQueueFocusViewComponent = /** @class */ (function (_super) {
    tslib_1.__extends(DocumentQueueFocusViewComponent, _super);
    function DocumentQueueFocusViewComponent(em, focusViewManager, focusViewService, configLoader, dataStoreService, documentService, modalService) {
        var _this = _super.call(this) || this;
        _this.em = em;
        _this.focusViewManager = focusViewManager;
        _this.focusViewService = focusViewService;
        _this.configLoader = configLoader;
        _this.dataStoreService = dataStoreService;
        _this.documentService = documentService;
        _this.modalService = modalService;
        _this.colDefConfig = [];
        _this.dispatchEntrySearchCriteria = new DispatchEntrySearchCriteria();
        _this.gridActions = [];
        _this.dispatchPostPatchModalCloseSubscription = new Subscription();
        _this.tbx = new TBXResponseWrapper();
        _this.dataUpdated = false;
        /*
         grid config methods which are called by grid setup (ag grid)
         and set by grid config
         */
        _this.getSupplierName = function (params) {
            var supText = EMPTY_CHAR;
            if (params && params.data) {
                if (params.data.supplierName) {
                    supText = params.data.supplierName;
                }
                else {
                    supText = EMPTY_CHAR;
                }
                supText += ' | ';
                if (params.data.supplierCode) {
                    supText += params.data.supplierCode;
                }
                else {
                    supText += EMPTY_CHAR;
                }
            }
            return supText;
        };
        _this.getUserName = function (params) {
            if (params && params.data && params.data.userName) {
                return params.data.systemGenerated ? 'System' : params.data.userName;
            }
            else if (params && params.data && params.data.systemGenerated) {
                return 'System';
            }
            else {
                return EMPTY_CHAR;
            }
        };
        _this.statusCellClass = function () {
            return {
                'tc-ag-grid-cell--warning': function (params) {
                    if (params.data && params.data.dispatchStatus && params.data.dispatchStatus ===
                        DispatchStatus.PENDING) {
                        return true;
                    }
                },
                'tc-ag-grid-cell--success': function (params) {
                    if (params.data && params.data.dispatchStatus && params.data.dispatchStatus ===
                        DispatchStatus.SUCCESS) {
                        return true;
                    }
                },
                'tc-ag-grid-cell--danger': function (params) {
                    if (params.data && params.data.dispatchStatus && params.data.dispatchStatus ===
                        DispatchStatus.FAILED) {
                        return true;
                    }
                }
            };
        };
        _this.statusPending = function (params) {
            return params.data && params.data.dispatchStatus && params.data.dispatchStatus ===
                DispatchStatus.PENDING;
        };
        _this.statusSuccess = function (params) {
            return params.data && params.data.dispatchStatus && params.data.dispatchStatus ===
                DispatchStatus.SUCCESS;
        };
        _this.statusFailed = function (params) {
            return params.data && params.data.dispatchStatus && params.data.dispatchStatus ===
                DispatchStatus.FAILED;
        };
        _this.clickSendAction = function (params) {
            console.log('click send action');
            console.log(params);
            var dispatchEntry = params.data;
        };
        return _this;
    }
    DocumentQueueFocusViewComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.focusViewManager.canProceed.next(true);
        this.focusViewManager.canProceedState.next(true);
        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'CLOSE_FOCUS_VIEW')
            .subscribe(function (e) {
            _this.focusViewService.close(true);
        });
        this.colDefConfig = this.configLoader.configurations.get(TCO.CONF.CONF_DOCUMENT_QUEUE_FOCUS_COL_DEF_CONFIG);
        this.addGridActions();
    };
    DocumentQueueFocusViewComponent.prototype.createNewRow = function () {
    };
    DocumentQueueFocusViewComponent.prototype.deleteRow = function (row) {
        return undefined;
    };
    DocumentQueueFocusViewComponent.prototype.getRows = function (params) {
        console.log('fetch requesting for ' + params.startRow + ' to ' + params.endRow);
        console.log(params.sortModel);
        this.dataStoreService.set(DataKey.documentQueueSummaryResultsForCriteria, this.documentQueue);
        this.tbx.data = this.documentQueue;
        return of(this.tbx);
    };
    DocumentQueueFocusViewComponent.prototype.isInvalidRow = function (params) {
        return false;
    };
    DocumentQueueFocusViewComponent.prototype.isUnsavedRow = function (row) {
        return false;
    };
    DocumentQueueFocusViewComponent.prototype.ngOnDestroy = function () {
    };
    DocumentQueueFocusViewComponent.prototype.saveRow = function (row, event) {
        return undefined;
    };
    DocumentQueueFocusViewComponent.prototype.addGridActions = function () {
        this.gridActions.push({
            icon: 'send',
            action: this.clickSendAction.bind(this),
            disable: false,
            tooltip: 'Send Now'
        });
    };
    DocumentQueueFocusViewComponent.prototype.confirmModal = function (data) {
        this.modalService.confirm(data).subscribe(function (res) {
        });
    };
    DocumentQueueFocusViewComponent.prototype.closeModal = function () {
        if (this.modalService.close()) {
            this.modalService.close().subscribe(function (res) {
            });
        }
    };
    return DocumentQueueFocusViewComponent;
}(SetupGridComp));
export { DocumentQueueFocusViewComponent };
