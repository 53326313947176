import * as tslib_1 from "tslib";
/**
 * Created by hareen on 6/25/2018
 */
var ActionHandlerService = /** @class */ (function () {
    function ActionHandlerService() {
    }
    /**
     * Fill the given criteria using action data
     * @param {string} data
     * @param {BaseGETCriteria} criteria
     * @returns {BaseGETCriteria}
     */
    ActionHandlerService.prototype.fillCriteriaByActionData = function (data, criteria) {
        var e_1, _a;
        try {
            for (var _b = tslib_1.__values(data.split('&')), _c = _b.next(); !_c.done; _c = _b.next()) {
                var pair = _c.value;
                var keyValue = pair.split('=');
                console.log(Object.prototype.hasOwnProperty.call(criteria, keyValue[0]));
                console.log(criteria);
                if (Object.prototype.hasOwnProperty.call(criteria, keyValue[0])) {
                    var value_ = keyValue[1];
                    if (value_.charAt(0) === '@') {
                        value_ = this.parseActionQueryData(value_);
                    }
                    criteria[keyValue[0]] = value_;
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
    };
    /**
     *
     * @param {string} query
     * @returns {string}
     */
    ActionHandlerService.prototype.parseActionQueryData = function (query_) {
        var regexDate = new RegExp(ActionHandlerService.DATE_PATTERN);
        if (regexDate.exec(query_)) {
            var date = this.resolveDate(query_);
            return date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
        }
    };
    /**
     * Handle all parse calls. Method will call different parsers based on the given query
     * @param data any data need to be parsed
     * @returns {any} parsed data or original data
     */
    ActionHandlerService.prototype.parse = function (data) {
        if (typeof data === 'string') { // should only parse strings
            if (data.charAt(0) === '@') { // should only parse if string starts with a @ sign
                if (data.match('@DATE')) {
                    return this.resolveDate(data); // call date parser
                }
            }
        }
        return data;
    };
    /**
     * Parse a date (range) given by string of format '@DATE(operation)(number)(unit)
     * to a CGDate. '@DATE' means the current date. '+' means to the future, i.e. add to the
     * current date. '-' means to the past, i.e. subtract from current date. 'number' defines
     * how many units to go forward or backward. And 'unit' defines the time measurement unit.
     *
     * operation => +|-
     * number => any (reasonable) number
     * unit => D|d|W|w|M|m|Y|y
     *
     * Ex:
     * '@DATE'   : get current date
     * '@DATE+2W': go two weeks forward from current date
     * '@DATE-1Y': go one year backward from current date
     *
     * @param data string needed to be parsed
     * @returns {CGDate} parsed date as CGDate object
     */
    ActionHandlerService.prototype.resolveDate = function (data) {
        var regex = new RegExp(ActionHandlerService.DATE_PATTERN); // RegEx for parsing date
        var date = new Date(); // current date
        var match = regex.exec(data); // split given string
        if (!match) {
            return null;
        }
        // If only @DATE - return date
        if (!match[1]) {
            return date;
        }
        try {
            // try to split into operations, amounts and measurements if any
            var amount = parseInt(match[1], 10);
            var measure = match[2].toUpperCase();
            // add, or subtract dates, weeks, months, or years based on measurement
            switch (measure) {
                case 'D':
                    date.setDate(date.getDate() + amount);
                    break;
                case 'W':
                    date.setDate(date.getDate() + amount * 7); // 7 days per week
                    break;
                case 'M':
                    date.setMonth(date.getMonth() + amount);
                    break;
                case 'Y':
                    date.setFullYear(date.getFullYear() + amount);
            }
        }
        catch (e) {
        }
        return date;
    };
    ActionHandlerService.DATE_PATTERN = '@DATE(([+-]\\\d)([DWMYdwmy]))*';
    return ActionHandlerService;
}());
export { ActionHandlerService };
