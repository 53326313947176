<div *ngFor="let data of timeOffset">
  <div class="tc-item-wrapper">
    <div class="tc-item-header tc-flex-box">
      <div class="tc-flex-item tc-item-header__primary"></div>
      <div class="tc-flex-item tc-item-header__actions tc-icon-block">
      </div>
    </div>
  </div>
  <div class="container-fluid direct-client-personal-info-edit-card tc-card-wrapper">
    <div class="row">
      <div class="col-md-12 col-lg-12">
        <div class="tc-flex-box">
          <div class="tc-flex-item tc-item-circle">
            <div class="icon-box">
              <i class="material-icons">people</i>
            </div>
          </div>
          <div class="tc-flex-item tc-right-cage">
            <div class="row">
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                <div class="tc-content--wrap tc-input-view">
                  <div class="tc-caption-txt txt-light">{{'Offset Rule Name' | translate}}</div>
                  <div class="tc-body1--txt">{{data.offsetRuleName}}</div>
                </div>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                <div class="tc-content--wrap tc-input-view">
                  <div class="tc-caption-txt txt-light">{{'Offset Rule Code' | translate}}</div>
                  <div class="tc-body1--txt">{{data.offsetRuleCode}}</div>
                </div>
              </div>

            </div>
            <!--<div class="tc-caption-txt txt-light">Pickup Points</div>-->
            <table  class="table">
              <thead class="text-primary">
              <tr>
                <th>{{'Pickup Points' | translate}}</th>
                <th>{{'Time Offset(Minutes)' | translate}}</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let point of data.pickupData">
                <td>{{point.pickupPointName}}</td>
                <td><input type="text" placeholder="{{point.pickupTimeOffset}}"></td>
              </tr>
              </tbody>
            </table>
            <!--<div *ngFor="let loc of data.pickupPoints">
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                <div class="tc-content&#45;&#45;wrap tc-input-view">
                  <div class="tc-caption-txt txt-light">{{loc.timeOffset}}</div>
                  <div class="tc-body1&#45;&#45;txt">{{loc.name}}</div>
                </div>
              </div>
            </div>-->
          </div>
          <div class="tc-flex-item tc-item-circle">
            <div class="icon-box">
              <i class="material-icons tc-icon-block__icon tc-icon-block__icon--active" (click)="editTimeOffset(data)">edit</i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
