<div class="tc-item-wrapper">
  <div class="tc-item-header tc-flex-box">
    <div class="tc-flex-item tc-item-header__primary">{{'Service and Resources' | translate}}</div>
    <div class="tc-flex-item tc-item-header__actions tc-icon-block">
        <div class="tc-flex-item tc-item-header__actions tc-icon-block">
                                        <span>
        <i
                class="material-icons tc-icon-block__icon tc-icon-block__icon--active" (click)="addRoute()"

        >add</i>
      </span>
      </div>
    </div>
  </div>
</div>
<form [formGroup]="resourceSetupForm">

  <div class="container-fluid direct-client-personal-info-edit-card tc-card-wrapper">
    <div class="col-md-12 col-lg-12">
      <div class="row" formArrayName="setupData"
           *ngFor="let cost of resourceSetupForm.get('setupData')['controls']; let i = index;">
        <div [formGroupName]="i">
          <div class="tc-flex-box">

            <div class="tc-flex-item tc-right-cage">
              <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                  <div class="tc-select-edit dcpiec-select-title">
                    <mat-form-field>
                      <mat-select #serviceType
                                  id="DIR_PER_INFO_EDIT_SERVICE"
                                  formControlName="serviceTypeName"
                                  [placeholder]="'service Type'"
                                  (change)="getResources($event)"
                                  required>
                        <mat-option *ngFor="let option of services"
                                    [value]="option.serviceTypeCode">
                          {{option.serviceTypeName}}
                        </mat-option>
                      </mat-select>
                      <!--<mat-error>{{getErrorMessage('title')}}</mat-error>-->
                    </mat-form-field>
                  </div>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 remove-bootstrap-padding-mobile">
                  <div class="tc-input-edit dcpiec-input-fname">
                    <mat-form-field>
                      <mat-select #mainResourceType
                                  id="DIR_PER_INFO_EDIT_MRESOURCE"
                                  formControlName="mainResource"
                                  [placeholder]="'Main Resource'"
                                  required>
                        <mat-option *ngFor="let option of mainResources"
                                    [value]="option">
                          {{option}}
                        </mat-option>
                      </mat-select>
                      <!--<mat-error>{{getErrorMessage('title')}}</mat-error>-->
                    </mat-form-field>
                  </div>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 remove-bootstrap-padding-mobile">
                  <div class="tc-input-edit dcpiec-input-lname">
<!--<div *ngIf="serviceType.value === '01'? "></div>-->
                    <mat-form-field>
                      <mat-select placeholder="Additional Services" (change)="onChange($event)" multiple>
                        <mat-option *ngFor="let option of otherResources" [value]="option">{{option}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>


              </div>
              <!--<button class="btn btn-primary" type="submit" (click)="result()" [disabled]="!personalInfoForm.valid">Save</button>-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>

