import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule, MatInputModule } from '@angular/material';
import { SearchCriteriaSaveModalComponent } from './search-criteria-save-modal.component';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        MatFormFieldModule,
        MatInputModule,
        TranslateModule.forChild()
    ],
    declarations: [SearchCriteriaSaveModalComponent]
})
export class SearchCriteriaSaveModalModule {}
