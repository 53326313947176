export enum ProductLevel {
    ANY = 'ANY',
    PKG = 'PKG',
    COMP = 'COMP',
    TOU_D = 'TOU_D'
}

export const PRODUCT_LEVELS_SHOW_AS = {
    TOUR_D: 'TOUR-D',
    COMP: 'COMP',
    PKG: 'PKG'
};
