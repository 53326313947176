import { Component, Input, OnInit } from '@angular/core';
import { DialogModel } from '@tc-core/model/it/codegen/ui/framework/dialog-model';
import { LEVEL } from '@tc-core/model/it/codegen/ui/framework/modal-data';
import { DialogService } from '@tc/dialog/dialog.service';
import { ReservationSearchCriteria } from '../../../models/criteria/reservation-search-criteria';
import { ServicePassenger } from '../../../models/reservation/service-passenger';
import { TcErrorType } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-error';
import { TrsReservationManagementService } from '../../../services/backend-consumers/reservation-management-services/trs-reservation-management.service';
import { DMCCommon } from '../../../services/util/common/dmc-common';
import { DataKey, DataStoreService } from '../../../services/util/framework/data-store.service';

@Component({
    selector: 'tc-lead-passenger-selection-wrapper',
    templateUrl: './lead-passenger-selection-wrapper.component.html'
})
export class LeadPassengerSelectionWrapperComponent implements OnInit {

    @Input() serviceItemId: number;
    @Input() leadPassenger: ServicePassenger;
    @Input() passengers: ServicePassenger[] = [];

    searchCriteria: ReservationSearchCriteria = null;
    isCompleted = true;
    constructor(
        private dataStore: DataStoreService,
        private dialogService: DialogService,
        private reservationManagementService: TrsReservationManagementService,
        private common: DMCCommon
    ) { }

    ngOnInit() {
        this.dataStore.get(DataKey.reservationSelectedGroupCriteria).subscribe((data) => {
            if (data !== null) {
                this.searchCriteria = data;
            }
        });
    }

    public onClosePopup($event: any) {
        this.retrieveSelectedGroup(false);
        this.dataStore.set(DataKey.popupClose, true);
    }

    public onSaveLeadPassenger(selectedPassenger: ServicePassenger) {
        console.log(selectedPassenger);
        this.isCompleted = false;
        this.reservationManagementService.setLeadPassenger(this.serviceItemId, selectedPassenger.passengerId)
            .subscribe(value => {

                this.retrieveSelectedGroup(true);

                this.common.showSnackBar('Successfully set the lead passenger', 3000, TcErrorType.TYPE.INFO);

                this.dataStore.set(DataKey.popupClose, true);
                this.isCompleted = true;

            }, error => {
                console.log(error);
                let confirmError = new DialogModel(
                    true,
                    LEVEL.ERROR,
                    'Failed',
                    error.error.message,
                    true,
                    2000
                );
                this.confirmDialog(confirmError);
                this.isCompleted = true;
            });
    }

    private confirmDialog(data: any): void {
        this.dialogService.confirm(data).subscribe((res) => {
        });
    }

    retrieveSelectedGroup(status: boolean) {
        if (this.searchCriteria !== null) {
            this.dataStore.set(DataKey.popupDataChanged, status, true);
            /*this.reservationManagementService.retrieveServiceItems(this.searchCriteria).subscribe(data => {
                this.dataStoreService.set(DataKey.reservationSelectedGroupData, data, true);
            });*/
        }
    }
}
