/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./calendar-header-controller/calendar-header-controller.component.ngfactory";
import * as i2 from "./calendar-header-controller/calendar-header-controller.component";
import * as i3 from "./calendar-body/calendar-body.component.ngfactory";
import * as i4 from "./calendar-body/calendar-body.component";
import * as i5 from "./cg-calendar.component";
var styles_CgCalendarComponent = [];
var RenderType_CgCalendarComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CgCalendarComponent, data: {} });
export { RenderType_CgCalendarComponent as RenderType_CgCalendarComponent };
export function View_CgCalendarComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { calendarBody: 0 }), (_l()(), i0.ɵeld(1, 0, null, null, 4, "div", [["class", "cg-cal"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "tc-calendar-header-controller", [], null, [[null, "menuItemClick"], [null, "selectCalendarType"], [null, "clickNextBtn"], [null, "clickPreviousBtn"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("menuItemClick" === en)) {
        var pd_0 = (_co.onMenuItemClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("selectCalendarType" === en)) {
        var pd_1 = (_co.onSelectCalendarType($event) !== false);
        ad = (pd_1 && ad);
    } if (("clickNextBtn" === en)) {
        var pd_2 = (_co.onClickNextBtn($event) !== false);
        ad = (pd_2 && ad);
    } if (("clickPreviousBtn" === en)) {
        var pd_3 = (_co.onClickPreviousBtn($event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, i1.View_CalendarHeaderControllerComponent_0, i1.RenderType_CalendarHeaderControllerComponent)), i0.ɵdid(3, 114688, null, 0, i2.CalendarHeaderControllerComponent, [], { calendarNavData: [0, "calendarNavData"], calendarMenuItems: [1, "calendarMenuItems"], validCalendarOptions: [2, "validCalendarOptions"] }, { menuItemClick: "menuItemClick", selectCalendarType: "selectCalendarType", clickNextBtn: "clickNextBtn", clickPreviousBtn: "clickPreviousBtn" }), (_l()(), i0.ɵeld(4, 0, null, null, 1, "tc-calendar-body", [], null, [[null, "selectDate"], ["window", "resize"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:resize" === en)) {
        var pd_0 = (i0.ɵnov(_v, 5).onResize($event) !== false);
        ad = (pd_0 && ad);
    } if (("selectDate" === en)) {
        var pd_1 = (_co.onSelectDate($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i3.View_CalendarBodyComponent_0, i3.RenderType_CalendarBodyComponent)), i0.ɵdid(5, 4308992, [[1, 4]], 0, i4.CalendarBodyComponent, [i0.ElementRef], { calendarNavData: [0, "calendarNavData"], yearsArray: [1, "yearsArray"], rangeGroups: [2, "rangeGroups"], calendarRefreshEvent: [3, "calendarRefreshEvent"] }, { selectDate: "selectDate" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.calendarNavData; var currVal_1 = _co.calendarMenuItems; var currVal_2 = _co.validCalendarOptions; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = _co.calendarNavData; var currVal_4 = _co.yearsArray; var currVal_5 = _co.rangeGroups; var currVal_6 = _co.calendarRefreshEvent; _ck(_v, 5, 0, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
export function View_CgCalendarComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-cg-calendar", [], null, null, null, View_CgCalendarComponent_0, RenderType_CgCalendarComponent)), i0.ɵdid(1, 4440064, null, 0, i5.CgCalendarComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CgCalendarComponentNgFactory = i0.ɵccf("tc-cg-calendar", i5.CgCalendarComponent, View_CgCalendarComponent_Host_0, { fromYear: "fromYear", toYear: "toYear", rangeGroups: "rangeGroups", calendarRefreshEvent: "calendarRefreshEvent", calendarMenuItems: "calendarMenuItems", validCalendarOptions: "validCalendarOptions", calendarNavData: "calendarNavData", viewOnlyMode: "viewOnlyMode" }, { selectDate: "selectDate", menuItemClick: "menuItemClick" }, []);
export { CgCalendarComponentNgFactory as CgCalendarComponentNgFactory };
