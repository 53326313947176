/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../widgets/shared/manage-supplier-attributes/manage-supplier-attributes.component.ngfactory";
import * as i2 from "../../../widgets/shared/manage-supplier-attributes/manage-supplier-attributes.component";
import * as i3 from "@ngx-translate/core";
import * as i4 from "./manage-supplier-attributes-wrapper.component";
import * as i5 from "../../../services/util/framework/data-store.service";
import * as i6 from "../../../services/backend-consumers/supplier-service/profile-management.service";
var styles_ManageSupplierAttributesWrapperComponent = [];
var RenderType_ManageSupplierAttributesWrapperComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ManageSupplierAttributesWrapperComponent, data: {} });
export { RenderType_ManageSupplierAttributesWrapperComponent as RenderType_ManageSupplierAttributesWrapperComponent };
export function View_ManageSupplierAttributesWrapperComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-manage-supplier-attributes", [], null, [[null, "attributesChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("attributesChanged" === en)) {
        var pd_0 = (_co.onSupplierAttributesChanged($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_ManageSupplierAttributesComponent_0, i1.RenderType_ManageSupplierAttributesComponent)), i0.ɵdid(1, 114688, null, 0, i2.ManageSupplierAttributesComponent, [], { supplierAttributes: [0, "supplierAttributes"] }, { attributesChanged: "attributesChanged" }), (_l()(), i0.ɵeld(2, 0, null, null, 3, "div", [["class", "payment__actions"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 2, "button", [["class", "button-right tc-button-primary mat-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeModalClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(4, null, [" ", " "])), i0.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.supplierAttributes; _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = i0.ɵunv(_v, 4, 0, i0.ɵnov(_v, 5).transform("Ok")); _ck(_v, 4, 0, currVal_1); }); }
export function View_ManageSupplierAttributesWrapperComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-manage-supplier-attributes-wrapper", [], null, null, null, View_ManageSupplierAttributesWrapperComponent_0, RenderType_ManageSupplierAttributesWrapperComponent)), i0.ɵdid(1, 114688, null, 0, i4.ManageSupplierAttributesWrapperComponent, [i5.DataStoreService, i6.ProfileManagementService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ManageSupplierAttributesWrapperComponentNgFactory = i0.ɵccf("tc-manage-supplier-attributes-wrapper", i4.ManageSupplierAttributesWrapperComponent, View_ManageSupplierAttributesWrapperComponent_Host_0, { supplierAttributes: "supplierAttributes" }, {}, []);
export { ManageSupplierAttributesWrapperComponentNgFactory as ManageSupplierAttributesWrapperComponentNgFactory };
