export class LeaveSetup {
    startDate: string;
    endDate: string;
    resourceIdList: number[];
    startTime: string;
    endTime: string;
    leaveType:string;
    note: string;
    dmcLeave: boolean;
    hasTask: boolean;
}
