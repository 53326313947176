import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { GenQueueUtil } from '../../../business/generic-reservation-management/gen-reservation-search-results/gen-queue-util';
import { Pax } from '../../../models/common/pax';
import { CostCalculationUtilsService } from '../../business-utils/cost-calculation/cost-calculation-utils.service';
import { DataKey, DataStoreService } from '../framework/data-store.service';
import { ResponseUtil } from '../response/response-util.service';
import * as i0 from "@angular/core";
import * as i1 from "../framework/data-store.service";
import * as i2 from "../../business-utils/cost-calculation/cost-calculation-utils.service";
var GenQueueGridDataProcessorService = /** @class */ (function () {
    function GenQueueGridDataProcessorService(dataStore, costCalculationUtil) {
        this.dataStore = dataStore;
        this.costCalculationUtil = costCalculationUtil;
        this.rowHeight = 45;
        this.EMPTY_STRING = '--';
        this.resourceTypes = [];
        this.resourceTypesSubscription = new Subscription();
        this.getFromConsumer = function (handlerObj, consumer) {
            if (consumer && handlerObj.handlerParam) {
                var item = _.get(consumer, handlerObj.handlerParam);
                if (item && handlerObj.type && handlerObj.type === 'function') {
                    return item.bind(consumer);
                }
                else {
                    if (!item) {
                        if (consumer.constructor && consumer.constructor.name) {
                            console.error(handlerObj.handlerParam + ' not found in ' + consumer.constructor.name);
                        }
                        else {
                            console.error(handlerObj.handlerParam + ' not found in ' + consumer);
                        }
                    }
                    return item;
                }
            }
        };
        this.showAssignFocusView = function (handlerObj, consumer) {
            if (consumer && handlerObj['handlerParam']) {
                var item = _.get(consumer, handlerObj['handlerParam']);
                if (handlerObj['type'] && handlerObj['type'] === 'function') {
                    return item.bind(consumer);
                }
                else {
                    return item;
                }
            }
        };
        this.getDateAndTour = function (handlerObj, consumer) {
            if (consumer && handlerObj['handlerParam']) {
                var item = _.get(consumer, handlerObj['handlerParam']);
                if (handlerObj['type'] && handlerObj['type'] === 'function') {
                    return item.bind(consumer);
                }
                else {
                    return item;
                }
            }
        };
        this.tourCellRowSpan = function (params) {
            if (params.data && params.data['tourCode']) {
                return params.node.allChildrenCount;
            }
            else {
                return 1;
            }
        };
        this.showCellTour = function (params) {
            return params && params.value !== undefined && GenQueueUtil.isTourItineraryRow(params);
        };
        this.havingMultiChildren = function (params) {
            return params.node.allChildrenCount > 1;
        };
        this.showCellTourItem = function (params) {
            return params && params.value !== undefined && GenQueueUtil.isTourItemRow(params);
        };
        this.categoryCellRowSpan = function (params) {
            if (params.data && params.data['categoryCode']) {
                return params.node.allChildrenCount;
            }
            else {
                return 1;
            }
        };
        this.getHeight = function (params) {
            return { 'height': (params.node.allChildrenCount * this.height) + 'px !important' };
        };
        this.valueSetter = function (params) {
            _.set(params.data, params.colDef.cellEditorParams.rowObject, params.newValue); // calling lodash deep property value set
            return true;
        };
        this.numericSetter = function (params) {
            if (params.newValue > 0 || params.newValue === '') {
                _.set(params.data, params.colDef.field, params.newValue); // calling lodash deep property value set
                return true;
            }
            return false;
        };
        this.currencyCellRenderer = function (params) {
            params.data.currency = params.data.currency ? params.data.currency : 'AED';
            if (params.data && params.data.currency && params.value) {
                var inrFormat = new Intl.NumberFormat('en-Us', {
                    style: 'currency',
                    currency: params.data.currency.code ?
                        params.data.currency.code :
                        params.data.currency,
                    minimumFractionDigits: 2
                });
                // return inrFormat.format(params.value).replace(/[a-z]{3}/i, '').trim();
                return inrFormat.format(params.value);
            }
            else {
                return this.EMPTY_STRING;
            }
        };
        this.normalCellValueRenderer = function (params) {
            if (params.value) {
                return params.value;
            }
            else {
                return this.EMPTY_STRING;
            }
        };
        this.tourRowSpan = function (params) {
            if (GenQueueUtil.isTourItineraryRow(params)) {
                return params.node.allChildrenCount;
            }
            else {
                return 0;
            }
        };
        this.tourItemRowSpan = function (params) {
            if (GenQueueUtil.isTourItemRow(params)) {
                return params.node.allChildrenCount;
            }
            else {
                return 0;
            }
        };
        this.getHeightFixBorder = function (params) {
            return {
                'height': (params.node.allChildrenCount * this.rowHeight) + 'px !important',
                // 'justify-content': 'flex-end',
                'border-width': (params.node.allChildrenCount) === 0 ? '0' : '1px'
            };
        };
        this.getHeightFixBorderFlexEnd = function (params) {
            return {
                'height': (params.node.allChildrenCount * this.rowHeight) + 'px !important',
                'justify-content': 'flex-end',
                'border-width': (params.node.allChildrenCount) === 0 ? '0' : '1px'
            };
        };
        this.getTotalCountInCategoryTypeText = function (params) {
            var pax;
            if (GenQueueUtil.isTourItemRow(params)) {
                pax = this.getCountByPax(params.data);
            }
            if (pax) {
                var text = pax.total + ' (' + pax.adults + ' ' + pax.children + ' ' + pax.infants + ')';
                return text;
            }
            else {
                return '';
            }
        };
        this.getTotalCountInAssignment = function (params) {
            var total = 0;
            if (GenQueueUtil.isAssignmentRow(params)) {
                total = params.data.adults + params.data.children + params.data.infants;
            }
            return total;
        };
        this.getTotalCountInAssignmentDetailed = function (params) {
            var text = '';
            if (GenQueueUtil.isAssignmentRow(params)) {
                var pax = this.getCountByPax(params.data);
                text = pax.total + ' (' + pax.adults + ' ' + pax.children + ' ' + pax.infants + ')';
            }
            return text;
        };
        this.getAllocatedCount = function (params) {
            var assignedCount = 0;
            var adults = 0;
            var teens = 0;
            var children = 0;
            var infants = 0;
            if (GenQueueUtil.isTourItemRow(params)) {
                if (params.data.assignments) {
                    for (var i = 0; i < params.data.assignments.length; i++) {
                        var assignment = params.data.assignments[i];
                        if (assignment && assignment.supplierId) {
                            var countByPax = this.getCountByPax(assignment);
                            assignedCount += countByPax.total;
                            adults += countByPax.adults;
                            teens += countByPax.teens;
                            children += countByPax.children;
                            infants += countByPax.infants;
                        }
                    }
                }
            }
            var text = assignedCount + ' (' + adults + ' ' + children + ' ' + infants + ')';
            return text;
        };
        this.displayObjName = function (option) {
            if (option && option.name) {
                return option.name;
            }
            else {
                return '';
            }
        };
        this.displayResourceObjName = function (option) {
            if (option && option.firstName) {
                return option.firstName + ' ' + option.lastName;
            }
            else {
                return '';
            }
        };
        this.displaySupplierObjName = function (option) {
            if (option && option.firstName) {
                return option.firstName + ' ' + option.lastName;
            }
            else {
                return '';
            }
        };
        this.getTotalCost = function (params) {
            if (GenQueueUtil.isAssignmentRow(params)) {
                return this.costCalculationUtil.getGenSupplierTotalCost(params.data);
            }
            else {
                return 0;
            }
        };
        this.noClientSideSortComparator = function (valueA, valueB, nodeA, nodeB, isInverted) {
            return 0;
        };
        this.getAllResourceTypes();
    }
    GenQueueGridDataProcessorService.prototype.getAllResourceTypes = function () {
        var _this = this;
        this.resourceTypesSubscription = this.dataStore.get(DataKey.resourceTypes).subscribe(function (response) {
            if (response != null) {
                _this.resourceTypesSubscription.unsubscribe();
                _this.resourceTypes = ResponseUtil.getArray(response);
            }
        });
    };
    GenQueueGridDataProcessorService.prototype.getCountByPax = function (pax) {
        var paxObj = new Pax();
        if (!isNaN(pax.adults)) {
            paxObj.adults += Number(pax.adults);
        }
        if (!isNaN(pax.teens)) {
            paxObj.teens += Number(pax.teens);
        }
        if (!isNaN(pax.children)) {
            paxObj.children += Number(pax.children);
        }
        if (!isNaN(pax.infants)) {
            paxObj.infants += Number(pax.infants);
        }
        paxObj.total = paxObj.adults + paxObj.teens + paxObj.children + paxObj.infants;
        return paxObj;
    };
    GenQueueGridDataProcessorService.ngInjectableDef = i0.defineInjectable({ factory: function GenQueueGridDataProcessorService_Factory() { return new GenQueueGridDataProcessorService(i0.inject(i1.DataStoreService), i0.inject(i2.CostCalculationUtilsService)); }, token: GenQueueGridDataProcessorService, providedIn: "root" });
    return GenQueueGridDataProcessorService;
}());
export { GenQueueGridDataProcessorService };
