<table mat-table [dataSource]="passengerAttributes" class="mat-elevation-z8">
    <!-- Position Column -->
    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Name</th>
        <td mat-cell *matCellDef="let element"> {{element.name | translate}} </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="value">
        <th mat-header-cell *matHeaderCellDef> Value</th>
        <td mat-cell *matCellDef="let element"> {{element.value | translate}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
