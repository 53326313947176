import { Component, OnInit } from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {IAfterGuiAttachedParams, ICellRendererParams} from 'ag-grid-community';

@Component({
  selector: 'tc-note-editor-input',
  templateUrl: './note-editor-input.component.html'
})
export class NoteEditorInputComponent implements ICellRendererAngularComp {

  params: any;
  inputTextLength: any;
  cellStyles: any;

  constructor() {
  }

  afterGuiAttached(params?: IAfterGuiAttachedParams): void {
  }

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.inputTextLength = params['value'];

    if (this.inputTextLength) {
      if (this.inputTextLength.length > 1024) {
        this.cellStyles = {color: 'red'};
        params['value'] = 'You have exceeded the maximum character limit!';
      }
    }
  }

  refresh(params: any): boolean {
    return false;
  }

}
