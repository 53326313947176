import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Action } from '@tc-core/model/it/codegen/ui/action';
import { PageSummary } from '@tc-core/model/it/codegen/ui/framework/page-summary';
import { TC } from '@tc-core/util/constants';
import { ConfigLoader } from '@tc-core/util/framework/config-loader.service';
import { EventManager } from '@tc-core/util/framework/event-manager.service';
import { UserJourneyManager } from '@tc-core/util/framework/user-journey-manager.service';
import { PageSummaryService } from '@tc-core/util/ui/page-summary.service';
import { SideNavBarService } from '@tc-core/util/ui/side-nav-bar.service';
import { LaunchPadComponent } from '@tc/launch-pad';
import { TCO } from '../../../constants';
import { ReservationManagementService } from '../../../services/backend-consumers/reservation-management-services/reservation-management.service';
import { ReservationV2ManagementService } from '../../../services/backend-consumers/reservation-v2/reservation-v2-management.service';
import { RootService } from '../../../services/util/core-services/root.service';
import { DataKey, DataStoreService } from '../../../services/util/framework/data-store.service';
import { DMCQueryParamsService } from '../../../services/util/framework/dmc-query-params.service';
import { ResponseUtil } from '../../../services/util/response/response-util.service';
// @ts-ignore
var LaunchPadPageComponent = /** @class */ (function () {
    function LaunchPadPageComponent(configLoader, pageSummaryService, sideNavBarService, userJourneyManager, dataStore, em, reservationService, reservationV2ManagementService, queryParamService, rootService) {
        this.configLoader = configLoader;
        this.pageSummaryService = pageSummaryService;
        this.sideNavBarService = sideNavBarService;
        this.userJourneyManager = userJourneyManager;
        this.dataStore = dataStore;
        this.em = em;
        this.reservationService = reservationService;
        this.reservationV2ManagementService = reservationV2ManagementService;
        this.queryParamService = queryParamService;
        this.rootService = rootService;
        this.pageSummary = new PageSummary();
        this.blocks = [];
        this.focusBlockIndex = -1;
        this.focusActionIndex = 0;
        this.userSavedSearchCriteriaListV1 = [];
        this.definedSearchCriteriaListV1 = [];
        this.userSavedSearchCriteriaListV2 = [];
        this.definedSearchCriteriaListV2 = [];
        this.systemId = 0; //for system defined saved search criteria
        this.defaultCriteriaCount = 6;
        this.TRANSPORT_QUEUE = 'BEGIN_RESOURCE_ALLOCATION_AS_TRANSFER_PANEL';
        this.GENERIC_QUEUE = 'BEGIN_RESOURCE_ALLOCATION_AS_GENERIC_QUEUE';
        this.COMMON_QUEUE_V2 = 'BEGIN_RESOURCE_ALLOCATION_AS_COMMON_PANEL';
        this.SAVED_CRITERIA = 'SAVED_CRITERIA';
        this.userCriteriaBlockIndex = -1;
        this.maxNoOfVisibleCriteria = -3;
        this.sideNavBarService.addSideNavItemsList([
            'dashboard',
            'productSearch',
            'bookingMgt',
            'backOffice',
            'finance',
            'queues',
            'salesAssistant',
            'settings'
        ]);
        this.pageSummary.page = 'DASHBOARD';
        this.pageSummaryService.setPageSummary(this.pageSummary);
    }
    LaunchPadPageComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.userJourneyManager.canProceed.next(true);
        this.blocks = this.configLoader.configurations.get(TC.CONF.CONF_LAUNCH_PAD).blocks;
        this.userCriteriaBlockIndex = this.blocks.findIndex(function (block) { return block.id === 'USER_SAVED_CRITERIA'; });
        var userSavedCriteriaBlock = this.blocks.filter(function (block) { return block.id === 'USER_SAVED_CRITERIA'; })[0];
        this.maxNoOfVisibleCriteria = userSavedCriteriaBlock.maxNoOfVisibleCriteria;
        this.getSystemDefinedCriteria();
        this.user = this.dataStore.get(DataKey.userDetail).getValue();
        this.getUserCriteria();
        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'FOCUS_FIRST_BLOCK').subscribe(function (e) {
            _this.isBlockFocus = true;
            _this.focusBlockIndex = (_this.focusBlockIndex + 1) % (_this.blocks.length);
            _this.focusActionIndex = 0;
        });
        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'MOVE_FOCUS_ACTION_NEXT').subscribe(function (e) {
            if (_this.isBlockFocus) {
                _this.focusActionIndex++;
            }
        });
        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'MOVE_FOCUS_ACTION_PREVIOUS').subscribe(function (e) {
            if (_this.isBlockFocus && _this.focusActionIndex > 0) {
                _this.focusActionIndex--;
            }
        });
        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'TRIGGER_ACTION').subscribe(function (e) {
            if (_this.isBlockFocus) {
                _this.launchPad.triggerAction();
            }
        });
    };
    LaunchPadPageComponent.prototype.getUserCriteria = function () {
        var _this = this;
        this.dataStore.set(DataKey.userSavedSearchCriteriaListV2, null, true);
        if (this.user) {
            //For V2
            this.reservationV2ManagementService.getUserSearchCriteriaList(this.user.id).subscribe(function (result) {
                if (result) {
                    _this.userSavedSearchCriteriaListV2 = Object.values(ResponseUtil.getDataArray(result))
                        .sort();
                    _this.dataStore.set(DataKey.userSavedSearchCriteriaListV2, _this.userSavedSearchCriteriaListV2, true);
                    _this.userSavedSearchCriteriaListV2 = _this.processDynamicBlocks(_this.userSavedSearchCriteriaListV2.slice(-_this.maxNoOfVisibleCriteria), _this.userCriteriaBlockIndex);
                }
            });
        }
    };
    LaunchPadPageComponent.prototype.getSystemDefinedCriteria = function () {
        this.dataStore.set(DataKey.systemDefinedCriteriaV2, null, true);
        //V2 - system saved criteria currently not using
        // this.reservationV2ManagementService.getUserSearchCriteriaList(this.systemId).subscribe(result => {
        //     if (result) {
        //         this.definedSearchCriteriaListV2 = Object.values(ResponseUtil.getDataArray<SavedCriteriaV2>(result)).sort();
        //         this.dataStore.set(DataKey.systemDefinedCriteriaV2, this.definedSearchCriteriaListV2, true);
        //         this.definedSearchCriteriaListV2 = this.processDynamicBlocks(this.definedSearchCriteriaListV2, 2);
        //     }
        // });
    };
    LaunchPadPageComponent.prototype.processQuickLinks = function (criteriaList, i) {
        if (this.blocks[i] && this.blocks[i].actionHandler !== '') {
            for (var j = 0; j < criteriaList.length; j++) {
                if (j <= this.defaultCriteriaCount) {
                    this.blocks[i].actions[j].primaryText = criteriaList[j].name;
                    this.blocks[i].actions[j].actionRef = this.SAVED_CRITERIA;
                }
            }
        }
        return criteriaList;
    };
    LaunchPadPageComponent.prototype.processDynamicBlocks = function (criteriaList, i) {
        var e_1, _a;
        if (this.blocks[i] && this.blocks[i].actionHandler !== '') {
            this.blocks[i].actions = new Array();
            var tAction = void 0;
            try {
                for (var criteriaList_1 = tslib_1.__values(criteriaList), criteriaList_1_1 = criteriaList_1.next(); !criteriaList_1_1.done; criteriaList_1_1 = criteriaList_1.next()) {
                    var data = criteriaList_1_1.value;
                    if (this.blocks[i].actions.length === this.defaultCriteriaCount) {
                        break;
                    }
                    tAction = new Action(this.blocks[i].defaultAction);
                    tAction.primaryText = data.criteriaName;
                    tAction.actionRef = this.SAVED_CRITERIA;
                    this.blocks[i].actions.push(tAction);
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (criteriaList_1_1 && !criteriaList_1_1.done && (_a = criteriaList_1.return)) _a.call(criteriaList_1);
                }
                finally { if (e_1) throw e_1.error; }
            }
        }
        return criteriaList;
    };
    LaunchPadPageComponent.prototype.onActionClick = function (action) {
        // todo handle this using journey manager framework
        //For V1
        // if (action.actionRef.startsWith(this.SAVED_CRITERIA)) {
        //     let qparams: Map<string, any> = new Map();
        //     let criteriaObj = this.getSelectedCriteriaV1(action.primaryText);
        //     if (criteriaObj.criteria.serviceType === 'GEN') {
        //         action.actionRef = this.GENERIC_QUEUE;
        //         this.rootService.setDataToLocalStorage(TCO.AppData.GEN_RESERVATION_SEARCH_CRITERIA,
        //             criteriaObj.criteria,
        //             true,
        //             null,
        //             true
        //         );
        //     } else {
        //         action.actionRef = this.TRANSPORT_QUEUE;
        //         this.rootService.setDataToLocalStorage(TCO.AppData.RESERVATION_SEARCH_CRITERIA,
        //             criteriaObj.criteria,
        //             true,
        //             null,
        //             true
        //         );
        //     }
        // }
        //For V2
        if (action.actionRef.startsWith(this.SAVED_CRITERIA)) {
            var qparams = new Map();
            var criteriaObj = this.getSelectedCriteriaV2(action.primaryText);
            action.actionRef = this.TRANSPORT_QUEUE;
            if (criteriaObj.criteria) {
                // set criteria parameters to url
                var queryParams = [];
                queryParams = this.queryParamService.getQueryParamsArray(criteriaObj.criteria);
                queryParams = queryParams.filter(function (val) { return val !== 'page'; });
                this.rootService.setDataToLocalStorage(TCO.AppData.PRODUCT_QUEUE_SEARCH_CRITERIA_TRS, criteriaObj.criteria, true, queryParams, true);
            }
        }
        this.userJourneyManager.goForKey(action.actionRef);
        console.log('Success');
    };
    LaunchPadPageComponent.prototype.getSelectedCriteriaV1 = function (actionText) {
        var criteria = this.userSavedSearchCriteriaListV1.find(function (data) { return data.name === actionText; });
        if (!criteria) {
            criteria = this.definedSearchCriteriaListV1.find(function (data) { return data.name === actionText; });
        }
        if (criteria.criteria.daysFrom) {
            criteria.criteria.date = new Date(+new Date() + criteria.criteria.daysFrom * 86400000).toISOString()
                .split('T')[0];
        }
        return criteria;
    };
    LaunchPadPageComponent.prototype.getSelectedCriteriaV2 = function (actionText) {
        var criteria = this.userSavedSearchCriteriaListV2.find(function (data) { return data.criteriaName === actionText; });
        if (!criteria) {
            criteria = this.definedSearchCriteriaListV2.find(function (data) { return data.criteriaName === actionText; });
        }
        return criteria;
    };
    return LaunchPadPageComponent;
}());
export { LaunchPadPageComponent };
