import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule, MatInputModule, MatSelectModule } from '@angular/material';
import { MatRadioModule } from '@angular/material/radio';
import { TranslateModule } from '@ngx-translate/core';
import { LeadPassengerSelectionComponent } from './lead-passenger-selection.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        TranslateModule.forChild(),

        MatRadioModule,
        MatInputModule,
        MatFormFieldModule,
        MatSelectModule
    ],
    exports: [
        LeadPassengerSelectionComponent
    ],
    declarations: [LeadPassengerSelectionComponent]
})
export class LeadPassengerSelectionModule {}
