<div class="container-fluid tc-item-wrapper tcpi-card">
  <div class="tc-item-wrapper__body">
    <div class="row">
      <div class="col-md-12">
        <div class="tc-flex-box">
          <div class="tc-flex-item icon-cage input-icon">
            <div class="card-icon--small icn-color-primary"><i class="material-icons">directions_car</i>
            </div>
          </div>
          <div class="tc-flex-item input-cage">
            <div class="col-xs-12 col-sm-6  col-md-3 col-lg-3">
              <div class="tc-content--wrap tc-input-view">
                <div class="tc-caption-txt txt-light">
                  {{"Transfer Mode" | translate}}
                </div>
                <div class="tc-body1--txt">{{resource?.vehicle?.trsModeCodeName ?
                        resource.vehicle.trsModeCodeName : EMPTY_CHAR}}
                </div>
              </div>
            </div>
            <div class="col-xs-12 col-sm-6  col-md-3 col-lg-3">
              <div class="tc-content--wrap tc-input-view">
                <div class="tc-caption-txt txt-light">{{"Vehicle Model" | translate}}
                </div>
                <div class="tc-body1--txt  ">{{resource?.vehicle?.vehicleModel?
                        resource.vehicle.vehicleModel : EMPTY_CHAR}}
                </div>
              </div>
            </div>
            <div class="col-xs-12 col-sm-6  col-md-3 col-lg-3">
              <div class="tc-content--wrap tc-input-view">
                <div class="tc-caption-txt txt-light">{{"Vehicle Name | Id" | translate}}
                </div>
                <div class="tc-body1--txt  ">{{resource?.vehicle?.vehicleName?
                        resource.vehicle.vehicleName + " | " + resource.resourceId : EMPTY_CHAR}}
                </div>
              </div>
            </div>
            <div class="col-xs-12 col-sm-6  col-md-2 col-lg-2">
              <div class="tc-content--wrap tc-input-view">
                <div class="tc-caption-txt txt-light">{{"License Plate Number" | translate}}
                </div>
                <div class="tc-body1--txt  ">{{resource?.vehicle?.licencePlate?
                        resource.vehicle.licencePlate : EMPTY_CHAR}}
                </div>
              </div>
            </div>
            <div class="col-xs-12 col-sm-6  col-md-1 col-lg-1">
              <div class="tc-content--wrap">
                <div class="card-chips item-right">
                  <div
                          *ngIf="resource?.activeStatus !== false"
                          [ngClass]="resource?.activeStatus ?'active':'' "
                          class="chip">
                    {{"Active" | translate}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="tc-flex-box">
          <div class="tc-flex-item icon-cage ">
            <div class="card-icon--small icn-color-primary"></div>
          </div>
          <div class="tc-flex-item input-cage ">
            <div class="col-xs-12 col-sm-6  col-md-3 col-lg-3">
              <div class="tc-content--wrap tc-input-view">
                <div class="tc-caption-txt txt-light">{{"Fuel Type" | translate}}
                </div>
                <div class="tc-body1--txt  ">{{resource?.vehicle?.fuelType?
                        resource.vehicle.fuelType : EMPTY_CHAR}}
                </div>
              </div>
            </div>
            <div class="col-xs-12 col-sm-6  col-md-3 col-lg-3">
              <div class="tc-content--wrap tc-input-view">
                <div
                        class="tc-caption-txt txt-light">{{"Fuel Consumption" | translate}}
                </div>
                <div class="tc-body1--txt">{{resource?.vehicle?.fuelConsumption ? resource.vehicle.fuelConsumption : EMPTY_CHAR}}</div>
              </div>
            </div>
            <div class="col-xs-12 col-sm-6  col-md-3 col-lg-3">
              <div class="tc-content--wrap tc-input-view">
                <div
                        class="tc-caption-txt txt-light">{{"Driver" | translate}}
                </div>
                <div class="tc-body1--txt">{{getDriverName(resource.vehicle.driverId)}}</div>
              </div>
            </div>
            <div class="col-xs-12 col-sm-6  col-md-3 col-lg-3">
              <div class="tc-content--wrap tc-input-view">
                <div class="tc-caption-txt txt-light">{{"Seating Capacity" | translate}}
                </div>
                <div
                        class="tc-body1--txt  ">{{resource?.capacity ? resource.capacity : EMPTY_CHAR}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="tc-flex-box">
          <div class="tc-flex-item icon-cage ">
            <div class="card-icon--small icn-color-primary"></div>
          </div>
          <div class="tc-flex-item input-cage ">
            <div class="col-xs-12 col-sm-6  col-md-3 col-lg-3">
              <div class="tc-content--wrap tc-input-view">
                <div class="tc-caption-txt txt-light">{{"Min Pax" | translate}}
                </div>
                <div class="tc-body1--txt  ">{{resource?.minPax ?
                        resource.minPax : EMPTY_CHAR}}
                </div>
              </div>
            </div>
            <div class="col-xs-12 col-sm-6  col-md-3 col-lg-3">
              <div class="tc-content--wrap tc-input-view">
                <div
                        class="tc-caption-txt txt-light">{{"Max Pax" | translate}}
                </div>
                <div class="tc-body1--txt">{{resource?.maxPax? resource.maxPax : EMPTY_CHAR}}</div>
              </div>
            </div>
            <div class="col-xs-12 col-sm-6  col-md-3 col-lg-3">
              <div class="tc-content--wrap tc-input-view">
                <div
                        class="tc-caption-txt txt-light">{{"Supplier Name | Code | Id" | translate}}
                </div>
                <div class="tc-body1--txt">{{resource?.supplierId ? resource.supplierName + " | " + resource.supplierCode + " | " + resource.supplierId : EMPTY_CHAR}}</div>
              </div>
            </div>
            <div class="col-xs-12 col-sm-6  col-md-3 col-lg-3">
              <div class="tc-content--wrap tc-input-view">
                <div class="tc-caption-txt txt-light">{{"Business Type" | translate}}
                </div>
                <div
                        class="tc-body1--txt  ">{{resource?.businessType ? resource.businessType : EMPTY_CHAR}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="tc-flex-box">
          <div class="tc-flex-item icon-cage ">
            <div class="card-icon--small icn-color-primary"></div>
          </div>
          <div class="tc-flex-item input-cage ">
            <div class="col-xs-12 col-sm-6  col-md-3 col-lg-3">
              <div class="tc-content--wrap tc-input-view">
                <div class="tc-caption-txt txt-light">{{"Preference" | translate}}
                </div>
                <div class="tc-body1--txt  ">{{resource?.preference ?
                        resource.preference : EMPTY_CHAR}}
                </div>
              </div>
            </div>
            <div class="col-xs-12 col-sm-6  col-md-3 col-lg-3">
              <div class="tc-content--wrap tc-input-view">
                <div class="tc-caption-txt txt-light">{{"Grade" | translate}}
                </div>
                <div class="tc-body1--txt  ">{{getGradeValue(resource.grade)}}
                </div>
              </div>
            </div>
            <div class="col-xs-12 col-sm-6  col-md-3 col-lg-3">
              <div class="tc-content--wrap tc-input-view">
                <div class="tc-caption-txt txt-light">{{"Linked User" | translate}}
                </div>
                <div class="tc-body1--txt">{{getUserName()}}
                </div>
              </div>
            </div>
            <div class="col-xs-12 col-sm-6  col-md-3 col-lg-3">
              <div class="tc-content--wrap tc-input-view">
              </div>
              <img
                      *ngIf="resource?.userProfile?.imageUrl"
                      width="50px"
                      height="50px"
                      style="border-radius: 50%"
                      [src]="resource?.userProfile?.imageUrl"
                      alt="user image">
            </div>

          </div>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="resource?.vehicle?.facilities">
      <div class="col-md-12">
        <div class="tc-flex-box">
          <div class="tc-flex-item icon-cage ">
            <div class="card-icon--small icn-color-primary"></div>
          </div>
          <div class="tc-flex-item input-cage ">
            <div class="col-xs-12">
              <div class="tc-content--wrap tc-input-view">
                <div class="tc-caption-txt txt-light">
                  {{"Vehicle Amenities" | translate}}
                </div>
                <div class="tc-action-chip-list-wrapper tc-chip-selector__action-chips">
                <div class="tc-action-chip tc-action-chip--inactive-disabled" *ngFor="let fac of resource?.vehicle?.facilities">
                  <span class="tc-action-chip__icon"><i class="material-icons">done</i></span>
                    <div class="tc-action-chip__text">
                      {{fac.facilityCode}}
                    </div>
                  </div>
                </div>
                <div class="tc-body1 --txt"
                     *ngIf="!resource.vehicle.facilities ||
                                         (resource.vehicle.facilities && resource.vehicle.facilities.length===0)">
                  {{EMPTY_CHAR}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
