import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TcErrorType } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-error';
import { LEVEL } from '@tc-core/model/it/codegen/ui/framework/dialog-model';
import { ModalData } from '@tc-core/model/it/codegen/ui/framework/modal-data';
import { TC } from '@tc-core/util';
import { ConfigLoader } from '@tc-core/util/framework';
import { ModalService } from '@tc/modal/modal.service';
import { IGetRowsParams } from 'ag-grid-community';
import { Observable, of, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SetupGridComp } from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid-comp';
import { SetupGridDataSource } from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid-data-source';
import { SetupGridComponent } from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid.component';
import { ActionRendererAction } from '../../../widgets/framework/ag-grid-custom-components/renderers/actions/actions.component';
import { TCO } from '../../../constants';
import { CitySearchCriteria } from '../../../models/criteria/city-search-criteria';
import { LocationGroupsSearchCriteria } from '../../../models/criteria/location-groups-search-criteria';
import { LocationTypeSearchCriteria } from '../../../models/criteria/location-type-search-criteria';
import { LocationsSearchCriteria } from '../../../models/criteria/locations-search-criteria';
import { SortDirection } from '../../../models/helper/sort-direction';
import { DataHandlerService } from '../../../services/backend-consumers/master-data-handler-service/data-handler.service';
import { MasterDataRestService } from '../../../services/backend-consumers/master-data-handler-service/master-data-rest.service';
import { LocationsService } from '../../../services/backend-consumers/setups/locations.service';
import { DMCCommon } from '../../../services/util/common/dmc-common';
import { DataKey, DataStoreService } from '../../../services/util/framework/data-store.service';
import { AddGeoLocationModalComponent } from '../add-geo-location-modal/add-geo-location-modal.component';
import EMPTY_CHAR = TC.CHIP_INPUT_DEFAULT_VALUES.EMPTY_CHAR;

@Component({
    selector: 'tc-locations-grid-setup',
    templateUrl: './locations-grid-setup.component.html'
})
export class LocationsGridSetupComponent extends SetupGridComp implements OnInit, OnDestroy, SetupGridDataSource {

    @ViewChild('setupGrid')
    private setupGrid: SetupGridComponent;
    public colDefConfig = [];
    gridActions: ActionRendererAction[] = [];
    private allReadyTakenLocationCodes = [];

    private locationsSearchCriteria: LocationsSearchCriteria = new LocationsSearchCriteria();

    // required options for grid editors
    // nothing

    private searchCriteriaObserver: Subscription = new Subscription();
    private geoLocationDataObserver: Subscription = new Subscription();

    constructor(
        private configLoader: ConfigLoader,
        private dataStoreService: DataStoreService,
        private dataHandlerService: DataHandlerService,
        private modalService: ModalService,
        private locationsService: LocationsService,
        private masterDataRestService: MasterDataRestService,
        private cd: ChangeDetectorRef,
        private common: DMCCommon,
    ) {
        super();
    }

    ngOnInit() {
        this.colDefConfig = this.configLoader.configurations.get(TCO.CONF.CONF_LOCATIONS_SETUP);
        this.addGridActions();
        this.subscribeSearchCriteria();
        this.fillRequiredGridOptionData();
        this.fillDataKeyForEditorData();
        this.getLocationCodes();
    }

    private addGridActions() {
        this.gridActions.push(
            {
                icon: 'edit_location',
                action: this.onGeoLocClick.bind(this),
                disable: this.isDisableGeoClick.bind(this),
                tooltip: 'Add Geo Location'
            }
        );
    }

    /**
     * subscribe search criteria changes in search bar
     * then force grid to refresh data
     * grid refresh will be call getRaws method which is using this subscribed criteria for backend call
     */
    subscribeSearchCriteria() {
        this.searchCriteriaObserver = this.dataStoreService.get(DataKey.locationsSearchCriteria)
                                          .subscribe(value => {
                                              this.locationsSearchCriteria = value;
                                              this.setupGrid.runForceSearch();
                                          });
    }

    onGeoLocClick = (params) => {
        this.openGeoLocationDialog(params.data);
    };

    private openGeoLocationDialog(data: any) {
        this.dataStoreService.set(DataKey.geoCodes, null, true);
        this.dataStoreService.set(DataKey.popupClose, null);
        const dataObject = new ModalData(
            LEVEL.SUCCESS,
            'Add Geo Location',
            {'label': 'OK'},
            {'label': 'Close'},
            'modal-basic--medium modal-basic--alt',
            AddGeoLocationModalComponent,
            {
                'locationData': data,
                'longitude': data.longitude,
                'latitude': data.latitude
            },
            '',
            '',
            '',
            '',
            '',
            '  ' + data.code + ' - ' + data.name
        );
        this.confirmModal(dataObject, data);
        this.dataStoreService.get(DataKey.popupClose).subscribe((data) => {
            if (data != null) {
                this.closeModal();
            }
        });
    }

    subscribeGeoLocation(data: any): any {
        this.geoLocationDataObserver = this.dataStoreService.get(DataKey.geoCodes).subscribe(
            value => {
                if (value != null) {
                    this.setupGrid.refreshCells();
                    if (!this.checkRowInvalid(value)) {
                        this.setupGrid.saveRowData(value);
                    }
                }
            });
    }

    isDisableGeoClick = (params) => {
        return this.checkRowInvalid(params.data);
    };

    checkRowInvalid(data: any): boolean {
        let event = {
            data: data
        };
        return this.isInvalidRow(event);
    }

    closeModal() {
        if (this.modalService.close()) {
            this.modalService.close().subscribe(
                (res) => {
                    // this.cd.detectChanges();
                }
            );
        }
    }

    private confirmModal(dataObject: any, locationData: any): void {
        this.modalService.confirm(dataObject).subscribe((res) => {
            this.subscribeGeoLocation(locationData);
        });
    }

    private fillRequiredGridOptionData() {
        // nothing
    }

    private fillDataKeyForEditorData() {
        this.addLocationTypes();
        this.addLocationGroups();
    }

    private addLocationTypes() {
        const locationTypeSearchCriteria = new LocationTypeSearchCriteria();
        locationTypeSearchCriteria.size = 1000000;
        this.locationsService.getLocationTypes(locationTypeSearchCriteria).subscribe(
            result => {
                this.dataStoreService.set(DataKey.locationTypes, result.data, true);
            });
    }

    private addLocationGroups() {
        const locationGroupsSearchCriteria = new LocationGroupsSearchCriteria();
        locationGroupsSearchCriteria.size = 1000000;
        this.locationsService.getLocationGroups(locationGroupsSearchCriteria).subscribe(
            result => {
                this.dataStoreService.set(DataKey.locationGroups, result.data, true);
            });
    }

    public isInvalidRow(params): boolean {
        const data = params.data;
        if (data) {
            let invalid = true;
            if (
                data.code &&
                data.name &&
                data.locationType
            ) {
                invalid = false;
            }
            return invalid;
        } else {
            return false;
        }
    }

    public createNewRow(): any {
        return {unsaved: true};
    }

    public isUnsavedRow(row: any): boolean {
        return !!row.unsaved;
    }

    public deleteRow(row: any) {
        if (row && row.code) {
            return this.locationsService.deleteLocation(row.code);
        } else {
            return of('success');
        }
    }

    public saveRow(row: any, event: any) {
        let saveData = row ? row : event;
        return this.locationsService.saveLocation(saveData)
                   .pipe(tap(data => row.unsaved = undefined));
    }

    public getRows(params: IGetRowsParams): Observable<any> {
        const pageSize = params.endRow - params.startRow;
        if (!this.locationsSearchCriteria) {
            this.locationsSearchCriteria = new LocationsSearchCriteria();
        }
        this.locationsSearchCriteria.start = params.startRow;
        this.locationsSearchCriteria.size = pageSize;
        if (params.sortModel && params.sortModel[0]) {
            this.locationsSearchCriteria.sortBy = params.sortModel[0].colId;
            if (params.sortModel[0].sort === SetupGridComp.GRID_SORT_ASCENDING) {
                this.locationsSearchCriteria.sortDirection = SortDirection.ASC;
            } else {
                this.locationsSearchCriteria.sortDirection = SortDirection.DESC;
            }
        } else {
            this.locationsSearchCriteria.sortBy = 'createdOn';
            this.locationsSearchCriteria.sortDirection = SortDirection.DESC;
        }
        console.log(this.locationsSearchCriteria);
        return this.locationsService.getLocations(this.locationsSearchCriteria)
                   .pipe(
                       tap(data =>
                           this.dataStoreService.set(DataKey.locationsSearchResultsForCriteria, data)
                       )
                   );
    }

    public ngOnDestroy(): void {
        if (this.searchCriteriaObserver) {
            this.searchCriteriaObserver.unsubscribe();
        }
        if (this.geoLocationDataObserver) {
            this.geoLocationDataObserver.unsubscribe();
        }
    }

    getLocationCodes() {
        this.locationsService.getLocationCodes().subscribe(
            result => {
                this.allReadyTakenLocationCodes = result.data;
            });
    }

    /*
     grid config methods which are called by grid setup (ag grid)
     and set by grid config
     */

    getLocationTypeText = (params) => {
        let typeText = '';
        if (params && params.data) {
            if (params.data.locationTypeName) {
                typeText = params.data.locationTypeName;
            } else if (params.data.locationType) {
                typeText = params.data.locationType;
            } else {
                typeText = EMPTY_CHAR;
            }
        }
        return typeText;
    };

    setLocationType = (params) => {
        if (params && params.data && params.newValue) {
            if ( params.oldValue === '--' && this.allReadyTakenLocationCodes.includes(params.data.code) ) {
                this.common.showSnackBar(
                    'Location Code Already Exist. Please Select a Different Code',
                    3000,
                    TcErrorType.TYPE.ERROR
                );
            } else {
                params.data.locationType = params.newValue.code;
                params.data.locationTypeName = params.newValue.name;
            }
        }
    };

    getLocationGroupText = (params) => {
        let groupText = EMPTY_CHAR;
        if (params && params.data) {
            if (params.data.locationGroup) {
                groupText = params.data.locationGroup;
            } else {
                groupText = EMPTY_CHAR;
            }
            groupText += ' | ';
            if (params.data.locationGroupName) {
                groupText += params.data.locationGroupName;
            } else if (params.data.locationGroup) {
                groupText += EMPTY_CHAR;
            } else {
                groupText = EMPTY_CHAR;
            }
        }
        return groupText;
    };

    setLocationGroup = (params) => {
        if (params && params.data && params.newValue) {
            params.data.locationGroup = params.newValue.code;
            params.data.locationGroupName = params.newValue.name;
        }
    };

    getLocationCityText = (params) => {
        let cityText = EMPTY_CHAR;
        if (params && params.data) {
            if (params.data.city) {
                cityText = params.data.city;
            } else {
                cityText = EMPTY_CHAR;
            }
            cityText += ' | ';
            if (params.data.cityName) {
                cityText += params.data.cityName;
            } else if (params.data.city) {
                cityText += EMPTY_CHAR;
            }else {
                cityText = EMPTY_CHAR;
            }
        }
        return cityText;
    };

    setCity = (params) => {
        if (params && params.data && params.newValue) {
            params.data.city = params.newValue.code;
            params.data.cityName = params.newValue.name;
            if (params.newValue.country) {
                params.data.country = params.newValue.country.code;
                params.data.countryName = params.newValue.country.name;
            }
        }
    };

    getLocationTypes = (params, rowData, text) => {
        return this.dataStoreService.get(DataKey.locationTypes)
                   .map(
                       aEvents =>
                           aEvents.filter(aEvent => {
                                   if (text) {
                                       if (aEvent.name) {
                                           return aEvent.name.toLowerCase().indexOf(text.toLocaleString()) > -1;
                                       }
                                   } else {
                                       return true;
                                   }
                               }
                           )
                   );
    };

    getLocationGroups = (params, rowData, text) => {
        return this.dataStoreService.get(DataKey.locationGroups)
            .map(
                aEvents =>
                    aEvents.filter(aEvent => {
                            if (text) {
                                if (aEvent.name) {
                                    if (aEvent.name.toLowerCase().indexOf(text.toLowerCase().toLocaleString()) > -1) {
                                        return aEvent.name.toLowerCase().indexOf(text.toLowerCase().toLocaleString()) > -1;
                                    } else {
                                        return aEvent.code.toLowerCase().indexOf(text.toLowerCase().toLocaleString()) > -1;
                                    }
                                }
                            } else {
                                return true;
                            }
                        }
                    )
            );
    };

    getCities = (params, rowData, text) => {
        let citySearchCriteria = new CitySearchCriteria();
        citySearchCriteria.codeOrName = text.toLowerCase();
        return this.masterDataRestService.searchCities(citySearchCriteria).map(
            result => result.data
        );
    };

}
