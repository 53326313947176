import * as tslib_1 from "tslib";
import { KeyControlAndAbstractSearchCriteria } from './key-control-and-abstract-search-criteria';
var JobSearchCriteria = /** @class */ (function (_super) {
    tslib_1.__extends(JobSearchCriteria, _super);
    function JobSearchCriteria() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.jobName = '';
        _this.startTime = '';
        _this.finishedTime = '';
        _this.jobHistoryStatus = 'ANY';
        _this.lockStatus = 'ANY'; // lock status
        return _this;
    }
    return JobSearchCriteria;
}(KeyControlAndAbstractSearchCriteria));
export { JobSearchCriteria };
