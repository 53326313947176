import { AbstractSearchCriteria } from './abstract-search-criteria';

export class LeaveSearchCriteria extends AbstractSearchCriteria {
    resourceId: number;
    groupId: number;
    resourceType: string = '';
    activeStatus: boolean;
    isAvailable: boolean;
    supplierName: string;
    supplierCode: string;
    leaveType: string;
    leaveDate: any;
    resourceName: string;
}
