import { Location } from '@angular/common';
import { AfterViewInit, Component, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Currency } from '@tc-core/model/it/codegen/tbx/api/data/controls/currency';
import { TcApiError } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-api-error';
import { TcErrorType } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-error';
import { TcHttpError } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-http-error';
import { DialogModel, LEVEL } from '@tc-core/model/it/codegen/ui/framework/dialog-model';
import { ModalData } from '@tc-core/model/it/codegen/ui/framework/modal-data';
import { NavItem } from '@tc-core/model/it/codegen/ui/framework/nav-item';
import { ERROR_LEVEL, PageSection } from '@tc-core/model/it/codegen/ui/framework/page-section';
import { SummaryCardData } from '@tc-core/model/it/codegen/ui/framework/summary-card-data';
import { TC } from '@tc-core/util/constants';
import { EventManager } from '@tc-core/util/framework/event-manager.service';
import { FocusViewManager } from '@tc-core/util/framework/focus-view-manager.service';
import { UserJourneyManager } from '@tc-core/util/framework/user-journey-manager.service';
import { CommonHelper } from '@tc-core/util/helpers/common-helper.service';
import { KebabService } from '@tc-core/util/ui';
import { SpinnerService } from '@tc-core/util/ui/spinner.service';
import { DialogService } from '@tc/dialog/dialog.service';
import { ExpansionPanelDirective } from '@tc/directives/expansion-panel';
import { ModalService } from '@tc/modal/modal.service';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { UserProfile } from '../../../authentication/user-profile';
import { TCO } from '../../../constants';
import {
    CityChange,
    CountryChange,
    CountryStateCityWrapper,
    InitialCountryStateCityCodes,
    StateChange
} from '../../../models/common/country-state-city-wrapper';
import { City, Country, State } from '../../../models/common/locale';
import { PaymentInfo } from '../../../models/common/payment-info';
import { CurrencySearchCriteria } from '../../../models/criteria/currency-search-criteria';
import { ResourceTypeSearchCriteria } from '../../../models/criteria/resource-type-search-criteria';
import { RoomFacilitySearchCriteria } from '../../../models/criteria/room-facility-search-criteria';
import { RoomTypeSearchCriteria } from '../../../models/criteria/room-type-search-criteria';
import { SupplierSearchCriteria } from '../../../models/criteria/supplier-search-criteria';
import { PageType } from '../../../models/helper/page-type';
import { ContactDetails } from '../../../models/profile/contact-details';
import { DocumentData } from '../../../models/profile/document';
import { GenericGroup } from '../../../models/supplier/collection/generic-group';
import { ResourceCollection } from '../../../models/supplier/collection/resource-collection';
import { ResourceGroup } from '../../../models/supplier/collection/resource-group';
import { RoomGroup } from '../../../models/supplier/collection/room-group';
import { VehicleGroup } from '../../../models/supplier/collection/vehicle-group';
import { ResourceCategory, Supplier } from '../../../models/supplier/supplier';
import { DataHandlerService } from '../../../services/backend-consumers/master-data-handler-service/data-handler.service';
import { AccommodationService } from '../../../services/backend-consumers/setups/accommodation.service';
import { CurrencyService } from '../../../services/backend-consumers/setups/currency.service';
import { ResourceTypeService } from '../../../services/backend-consumers/setups/resource-type.service';
import { SupplierService } from '../../../services/backend-consumers/supplier-service/supplier.service';
import { CountryStateCityService } from '../../../services/business-utils/country-state-city/country-state-city.service';
import { ChangeDetectorService } from '../../../services/util/change-detector/change-detector.service';
import { DMCCommon } from '../../../services/util/common/dmc-common';
import { RootService } from '../../../services/util/core-services/root.service';
import { DataKey, DataStoreService } from '../../../services/util/framework/data-store.service';
import { DMCQueryParamsService } from '../../../services/util/framework/dmc-query-params.service';
import { ResponseUtil } from '../../../services/util/response/response-util.service';
import { DMCLocalStorageService, LocalDataKey } from '../../../services/util/system/dmc-local-storage.service';
import { CardDataHandlerService } from '../../../services/util/ui/card-data-handler-service.service';
import { AdditionalContactsComponent } from '../../../widgets/shared/additional-contacts/additional-contacts.component';
import { RoomsComponent } from '../../../widgets/shared/rooms/rooms.component';
import { TaxSchemesComponent } from '../../../widgets/shared/tax-schemes/tax-schemes.component';
import { AddDocumentsComponent } from '../add-documents/add-documents.component';
import { ManageSupplierAttributesWrapperComponent } from '../manage-supplier-attributes-wrapper/manage-supplier-attributes-wrapper.component';
import { AddNoteComponent } from '../shared/add-note/add-note.component';
import { OccupancySetupComponent } from './occupancy-setup/occupancy-setup.component';
import { SupplierCostSetupComponent } from './supplier-cost-setup/supplier-cost-setup.component';

@Component({
    selector: 'tc-supplier',
    templateUrl: './supplier.component.html'
})
export class SupplierComponent implements OnInit, OnDestroy, AfterViewInit {
    private supplierPaymentValid = false;
    private supplierInformationValid = false;
    private keyControlInformationValid = false;
    private personalContactValid = false;
    private additionalContactValid = true;
    private genericGrpValid = true;
    private vehicleGrpValid = true;
    private roomGrpValid = true;
    private roomsAllocationValid = true;
    private supplierCostSetupValid = true;
    private supplierGenCostSetupValid = true;
    private supplierRoomCostSetupValid = true;
    private dataChanged = false;
    private formSaved = false;
    private isSaveEnable = false;
    supplier: Supplier;
    editSupplierUnedited: Supplier;
    contactDetails: ContactDetails = new ContactDetails();
    additionalContactDetails: ContactDetails[] = [];
    supplierID: number;
    pageType = '';
    testCountry = '';
    testState = '';
    public selectedCountry: string;
    public selectedState: string;
    public resourceTypes: any[] = [];
    public selectedResProfTypes: any[] = [];
    resourceType = '';
    validResource = [];
    selectedResTypes = [];
    supplierTypes = [];
    profileTypes = [];
    masterRoomFacilities = [];
    locales = [];
    showTransportCostAddButton = true;
    showGenericCostAddButton = true;
    showRoomCostAddButton = true;
    disableKeyControlEdit = false;

    // additional contacts
    additionalContactSummary: SummaryCardData;
    CARD_CONFIG_ADDITIONALCONTACT = 'additionalContact';
    additionalContactContents: SummaryCardData[] = [];

    // generics
    CARD_CONFIG_GENERICS = 'generics';
    genericTypeOptions: any[];
    genericsContents: SummaryCardData[] = [];
    genericsSummary: SummaryCardData;
    genericTypes = [];
    addingGenericCount = 0;

    // vehicle
    CARD_CONFIG_VEHICLES = 'vehicles';
    vehicleTypeOptions: any[];
    vehiclesContents: SummaryCardData[] = [];
    vehiclesSummary: SummaryCardData;
    vehicleTypes = [];
    addingVehiclesCount = 0;

    // rooms
    CARD_CONFIG_ROOMS = 'rooms';
    roomTypeOptions: any[];
    roomsContents: SummaryCardData[] = [];
    roomsSummary: SummaryCardData;
    roomTypes = [];
    addingResourceGrpCount = 0;

    // tax scheme
    CARD_CONFIG_TAXSCHEME = 'taxScheme';
    taxSchemeSummary: SummaryCardData;
    taxSchemeContents: SummaryCardData[] = [];
    taxSchemes: any[];
    supplierItems: any[];

    // documents
    docTypeOptions: any[];
    formatOptions = [];
    dispatchTypes = [];
    frequencyOptions = [];
    documentSummary: SummaryCardData;
    CARD_CONFIG_DOCUMENT = 'documents';
    documentContents: SummaryCardData[] = [];
    addingDocumentCount = 0;
    docTypes = [];

    supplierAttributes: any[] = [];

    categories: any[] = [];
    titles: string[] = [];
    currencyList: any[];
    payMethods: any[];

    isNewSupplier = false;

    userProfile: UserProfile;

    private documentTypesSubscription: Subscription = new Subscription();
    private roomTypesSubscription: Subscription = new Subscription();
    private modalCloseSubscription: Subscription = new Subscription();
    private createSupplierObsv: Subscription = new Subscription();
    private updateSupplierObsv: Subscription = new Subscription();
    private focusviewCloseSubject: Subscription = new Subscription();
    private resourceCostDataSubscription: Subscription = new Subscription();

    @ViewChildren(ExpansionPanelDirective) expansionPanelDirective: QueryList<ExpansionPanelDirective>;
    private attributesSubscription: Subscription = new Subscription();

    defaultContactCategory: any;

    // country state city
    countryStateCityChangeMap: Map<number, CountryStateCityWrapper> = new Map();
    cityList: City[];
    stateList: State[];
    countryList: Country[];
    routes = [];
    transferModes = [];

    // --country state city--//

    // page section
    private pageSectionsMap: Map<string, PageSection>;
    public supplierInfoSection: PageSection;
    public supplierKeyControlSection: PageSection;
    public contactDetailsSection: PageSection;
    public paymentInfoSection: PageSection;
    public additionalContactsSection: PageSection;
    public supplierCostSetupSection: PageSection;
    public supplierAttributesSection: PageSection;
    public taxSchemeSection: PageSection;
    public documentSection: PageSection;
    public genericGrpSection: PageSection;
    public vehicleGrpSection: PageSection;
    public roomGrpSection: PageSection;
    public roomAllocationSection: PageSection;
    private CARD_CONFIG_GENERIC_EDIT = 'supplier_edit';
    private headerTextNew = 'New Supplier Profile';
    private headerTextEdit = 'Edit Supplier Profile';

    public costSetupKebabMenu: NavItem[] = [];

    // -- page section --//

    @ViewChild(AdditionalContactsComponent) additionalContactComponent: AdditionalContactsComponent;
    @ViewChild(TaxSchemesComponent) taxSchemeComponent: TaxSchemesComponent;
    @ViewChild(SupplierCostSetupComponent) supplierCostSetupComponent: SupplierCostSetupComponent;
    @ViewChild(RoomsComponent) roomsComponent: RoomsComponent;

    constructor(
        private dialogService: DialogService,
        private userJourneyManager: UserJourneyManager,
        private location: Location,
        private supplierService: SupplierService,
        private rootService: RootService,
        private queryParamService: DMCQueryParamsService,
        private focusViewManager: FocusViewManager,
        private em: EventManager,
        private dataStore: DataStoreService,
        private dataHandlerService: DataHandlerService,
        private resourceTypeService: ResourceTypeService,
        private route: ActivatedRoute,
        private modalService: ModalService,
        private cardDataHandlerService: CardDataHandlerService,
        private commonHelper: CommonHelper,
        private common: DMCCommon,
        private countryStateCityService: CountryStateCityService,
        private changeDetectorService: ChangeDetectorService,
        private spinnerService: SpinnerService,
        private kebabService: KebabService,
        private localStorage: DMCLocalStorageService,
        private currencyService: CurrencyService,
        private accommodationService: AccommodationService
    ) {
    }

    ngOnInit() {

        this.dataStore.set(DataKey.supplierSearchResults, null, true);

        this.supplier = new Supplier();
        this.supplier.paymentInfo = new PaymentInfo();
        this.supplier.activeStatus = true;
        this.supplier.taxSchemes = [];
        this.supplier.documents = [];
        this.supplier.rooms = [];
        this.supplier.contactDetails = [];
        this.supplier.seasons = [];
        this.supplier.supplierAttributes = [];
        this.supplier.supplierItems = [];
        this.selectedResTypes = this.supplier.supplierItems;

        this.showTransportCostAddButton = this.selectedResTypes && this.selectedResTypes.length > 0 &&
            this.selectedResTypes.filter(type => type['resourceCategory'] === 'transport').length > 0;

        this.showGenericCostAddButton = this.selectedResTypes && this.selectedResTypes.length > 0 &&
            this.selectedResTypes.filter(type => type['resourceCategory'] === 'generic').length > 0;

        this.showRoomCostAddButton = this.selectedResTypes && this.selectedResTypes.length > 0 &&
            this.selectedResTypes.filter(type => type['code'] && type['code'].toUpperCase() === 'HTL').length > 0;

        this.dataStore.set(DataKey.selectedCountry, null, true);
        this.pageType = this.route.snapshot.queryParamMap.get('page').split('_')[0];
        this.getPaymentMethods();
        this.getCurrencies();
        this.onChangeCostSetupForm();
        this.onChangeAdditionalContactForm(true);
        this.getUserDetails();

        if (this.pageType === PageType.CREATE) {
            // this.editSupplier = undefined;
            this.dataStore.set(DataKey.selectedSupplierForEdit, null, true);

            this.dataStore.get(DataKey.selectedSupplierForEditAsNew, true)
                .pipe(take(1))
                .subscribe(data => {
                    if (data != null) {
                        this.dataStore.set(DataKey.selectedSupplierForEditAsNew, null, true);
                        if (data.paymentInfo == null) {
                            data.paymentInfo = new PaymentInfo();
                        }
                        this.fillSupplierObject(data);
                    } else {
                        let data: Supplier = this.localStorage.load<Supplier>(LocalDataKey.unSavedSupplier);
                        if (data && !data.supplierId) {
                            if (data.paymentInfo == null) {
                                data.paymentInfo = new PaymentInfo();
                            }
                            this.fillSupplierObject(data);
                        }
                    }
                    this.initPageSectionReferences();
                });

            this.isNewSupplier = true;
        } else if (this.pageType === PageType.EDIT) {
            this.isNewSupplier = false;
            this.dataStore.get(DataKey.selectedSupplierForEdit, true).subscribe(data => {
                if (data != null) {
                    this.spinnerService.hide();
                    this.editSupplierUnedited = JSON.parse(JSON.stringify(data));
                    this.disableKeyControlEdit = this.editSupplierUnedited.isEditTriggered;
                    this.fillSupplierObject(data);
                } else {
                    let data: Supplier = this.localStorage.load<Supplier>(LocalDataKey.unSavedSupplier);
                    this.editSupplierUnedited = JSON.parse(JSON.stringify(data));

                    this.fillSupplierObject(data);
                }
                this.initPageSectionReferences();
            });
        }

        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'SAVE').subscribe(e => {
            this.saveSupplier();
        });

        this.retrieveMasterData();

        this.getSupplierAttributes();
        // country state city
        this.subscribeCountryStateCityListChange();
        // --country state city--//
    }

    /**
     * adding supplier object from loaded data store
     * @param data
     */
    fillSupplierObject(data: Supplier) {
        this.supplier = data;
        if (this.supplier) {
            this.localStorage.store(LocalDataKey.unSavedSupplier, this.supplier);
            if (data && data.contactDetails) {
                this.contactDetails = data.contactDetails.filter(e => e['status'] === true)[0];
                this.additionalContactDetails = data.contactDetails.filter(e => e['status'] === false);
            }
            if (this.supplier.supplierItems) {
                this.selectedResTypes = [];
                this.validResource = [];
                for (let item of this.supplier.supplierItems) {
                    if (!this.findObject(this.validResource, item)) {
                        this.selectedResTypes.push(item);
                        this.validResource.push(item.code);
                    }
                }
            }
            if (!this.supplier.paymentInfo) {
                this.supplier.paymentInfo = new PaymentInfo();
            }
            this.supplierID = this.supplier.supplierId;
            if (!this.supplier.resourceCollection) {
                const resourceCollection = new ResourceCollection();
                resourceCollection.roomCollection = [];
                resourceCollection.vehicleCollection = [];
                resourceCollection.genericCollection = [];
                this.supplier.resourceCollection = resourceCollection;
            } else {
                if (this.supplier.resourceCollection.roomCollection) {
                    for (const resourceGroup of this.supplier.resourceCollection.roomCollection) {
                        resourceGroup.tempId = this.addingResourceGrpCount++;
                    }
                } else {
                    this.supplier.resourceCollection.roomCollection = [];
                }
                if (this.supplier.resourceCollection.genericCollection) {
                    for (const resourceGroup of this.supplier.resourceCollection.genericCollection) {
                        resourceGroup.tempId = this.addingResourceGrpCount++;
                    }
                } else {
                    this.supplier.resourceCollection.genericCollection = [];
                }
                if (this.supplier.resourceCollection.vehicleCollection) {
                    for (const resourceGroup of this.supplier.resourceCollection.vehicleCollection) {
                        resourceGroup.tempId = this.addingResourceGrpCount++;
                    }
                } else {
                    this.supplier.resourceCollection.vehicleCollection = [];
                }
            }

            this.onChangeSupplierInfoForm(true);
            this.onChangeSupplierPaymentForm(true);
            this.onChangePersonalContactForm(true);
            this.onChangeRooms(true);
        }
    }

    findObject(array: any[], item: any) {
        let found = null;
        for (let obj of array) {
            if (obj === item['code']) {
                found = obj;
                return found;
            }
        }
        if (!found) {
            return found;
        }
    }

    private initPageSectionReferences() {
        this.setKebabMenus();
        this.addKebabMenuActions();

        this.pageSectionsMap = this.cardDataHandlerService.getAllPageSections(
            this.CARD_CONFIG_GENERIC_EDIT,
            this.supplier
        );

        this.supplierInfoSection = this.pageSectionsMap.get('supplier_info');
        this.supplierKeyControlSection = this.pageSectionsMap.get('supplier_key_controls');
        this.contactDetailsSection = this.pageSectionsMap.get('contact_details');
        this.paymentInfoSection = this.pageSectionsMap.get('payment_details');
        this.additionalContactsSection = this.pageSectionsMap.get('additional_contacts');
        this.supplierCostSetupSection = this.pageSectionsMap.get('supplier_cost_setup');
        this.supplierAttributesSection = this.pageSectionsMap.get('supplier_attributes');
        this.genericGrpSection = this.pageSectionsMap.get('generics');
        this.vehicleGrpSection = this.pageSectionsMap.get('vehicles');
        this.roomGrpSection = this.pageSectionsMap.get('rooms');
        this.roomAllocationSection = this.pageSectionsMap.get('roomAllocations');
        this.taxSchemeSection = this.pageSectionsMap.get('tax_schemes');
        this.documentSection = this.pageSectionsMap.get('documents');

        this.setupHeader();
        this.filterPageSectionActions();
    }

    filterPageSectionActions() {
        // if (this.supplier && this.supplier.supplierItems.includes()) {
        //     this.taxSchemeSection.actions = this.taxSchemeSection.actions.filter(action => {
        //         return action !== 'TRIGGER_ADD_TAX_SCHEME';
        //     });
        // }
    }

    public onHeaderActionClick(event) {
        switch (event.id) {
            case 'TRIGGER_ADD_ADDITIONAL_CONTACT': {
                console.log('add addition contact');
                this.additionalContactComponent.addAdditionalContact();
                break;
            }
            case 'TRIGGER_SET_SUPPLIER_ATTRIBUTES': {
                console.log('add supplier attribute');
                this.onSupplierAttributesClick();
                break;
            }
            case 'TRIGGER_ADD_NOTE': {
                console.log('add note');
                this.onAddNote();
                break;
            }
            case 'TRIGGER_ADD_TAX_SCHEME': {
                console.log('add tax scheme');
                this.taxSchemeComponent.addTaxScheme();

                break;
            }
            case 'TRIGGER_ADD_DOCUMENTS': {
                console.log('add document');
                this.onAddDocument();
                break;
            }
            case 'TRIGGER_ADD_GENERIC_GRP': {
                console.log('add rooms');
                this.onAddGenericGrp();
                break;
            }
            case 'TRIGGER_ADD_VEHICLE_GRP': {
                console.log('add rooms');
                this.onAddVehicleGrp();
                break;
            }
            case 'TRIGGER_ADD_ROOM_GRP': {
                console.log('add rooms');
                this.onAddRoomGrp();
                break;
            }
        }
    }

    setupSectionTabError(section: PageSection): boolean {
        if (!section) {
            return false;
        }
        const shouldShow = section.showError === ERROR_LEVEL.SHOW_ERROR_TAB || section.showError ===
            ERROR_LEVEL.SHOW_ERROR_TAB_FORM;
        return section.error && shouldShow;
    }

    private setupHeader() {
        if (this.pageType === PageType.CREATE) {
            this.userJourneyManager.setCurrentHeading(this.headerTextNew);
        } else if (this.pageType === PageType.EDIT) {
            this.userJourneyManager.setCurrentHeading(this.headerTextEdit);
        }
        this.setHeaderActions();
        this.setHeaderActionTriggers();
        // this.setHeaderKebab();
    }

    private setHeaderActions() {
        let actionKeys = [];
        actionKeys.push('TRIGGER_CLOSE');
        this.userJourneyManager.setHeaderActions(actionKeys);
    }

    private removeHeaderActions() {
        this.userJourneyManager.setHeaderActions([]);
        this.em.removeEvents([
            'TRIGGER_CLOSE'
        ]);
    }

    private setHeaderActionTriggers() {
        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'TRIGGER_CLOSE').subscribe(e => {
            this.onCloseClick(null);
        });
    }

    public onCloseClick(event) {
        this.location.back();
    }

    private addKebabMenuActions() {
        this.addCostSetupKebabMenuActions();
    }

    private setKebabMenus() {
        this.costSetupKebabMenu = this.kebabService.get('SUPPLIER_COST_SETUP').items;
    }

    private addCostSetupKebabMenuActions() {

        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'TRIGGER_SEASON_SETUP').subscribe(e => {
            this.supplierCostSetupComponent.onSeasonSetupClick();
        });

        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'TRIGGER_TRA_COST_SETUP').subscribe(e => {
            if (this.showTransportCostAddButton) {
                this.supplierCostSetupComponent.onTransportCostSetupClick();
            } else {
                let confirmError = new DialogModel(
                    true,
                    LEVEL.ERROR,
                    'Failed',
                    'Please add transport resource types',
                    true,
                    2000
                );
                this.dialogService
                    .confirm(confirmError)
                    .subscribe((res) => {
                        if (res === true) {
                            console.log('true');
                            /* this.dataStore.set(DataKey.cart, null, true);
                             this.spinnerService.show();
                             this.addPassengers();*/
                        }
                    });
            }

        });

        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'TRIGGER_GEN_COST_SETUP').subscribe(e => {
            if (this.showGenericCostAddButton) {
                this.supplierCostSetupComponent.onGenericCostSetupClick();
            } else {
                let confirmError = new DialogModel(
                    true,
                    LEVEL.ERROR,
                    'Failed',
                    'Please add generic resource types',
                    true,
                    2000
                );
                this.dialogService
                    .confirm(confirmError)
                    .subscribe((res) => {
                        if (res === true) {
                            console.log('true');
                            /* this.dataStore.set(DataKey.cart, null, true);
                             this.spinnerService.show();
                             this.addPassengers();*/
                        }
                    });
            }
        });

        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'TRIGGER_ROOM_COST_SETUP').subscribe(e => {
            if (this.showRoomCostAddButton) {
                this.supplierCostSetupComponent.onRoomCostSetupClick();
            } else {
                const confirmError = new DialogModel(
                    true,
                    LEVEL.ERROR,
                    'Failed',
                    'Please add room resource types',
                    true,
                    2000
                );
                this.dialogService
                    .confirm(confirmError)
                    .subscribe((res) => {
                        if (res === true) {
                            console.log('true');
                            /* this.dataStore.set(DataKey.cart, null, true);
                             this.spinnerService.show();
                             this.addPassengers();*/
                        }
                    });
            }
        });

    }

    retrieveMasterData() {
        this.dataHandlerService.retrieveTitleDetails().subscribe(
            result => {
                this.titles = result.data;
            });

        this.dataHandlerService.retrieveCategories().subscribe(
            result => {
                this.categories = result.data;
                this.defaultContactCategory = this.categories.find(value => value.name === 'Reservation');
            });
        this.resourceType = this.route.snapshot.queryParamMap.get('page').split('_')[1];

        this.supplier.resourceCategory = ResourceCategory.GENERIC;

        // if (this.resourceType === 'generic') {
        //     this.supplier.resourceCategory = ResourceCategory.GENERIC;
        //     this.showCostAddButton = false;
        // } else {
        //     this.supplier.resourceCategory = ResourceCategory.TRANSPORT;
        // }

        const resourceTypeSearchCriteria = new ResourceTypeSearchCriteria();
        resourceTypeSearchCriteria.size = 1000000;
        this.resourceTypeService.getResourceTypes(resourceTypeSearchCriteria).subscribe(
            result => {
                if (result.data) {
                    result.data.forEach(type => {
                        this.resourceTypes.push(type);
                    });
                }
            });

        // this.resourceTypes = result['data']['resourceTypes'];
        // console.log(this.resourceType);

        this.dataHandlerService.retrieveTaxSchemes().subscribe(
            result => {
                this.taxSchemes = result.data;
            });
        this.dataHandlerService.retrieveDocumentTypes().subscribe(result => {
            this.docTypeOptions = result.data;
        });
        this.dataHandlerService.retrieveFormatOptions().subscribe(
            result => {
                this.formatOptions = result.data;
            });

        this.dataHandlerService.retrieveDispatchTypes().subscribe(
            result => {
                this.dispatchTypes = result.data;
            });

        this.dataHandlerService.retrieveFrequencyOptions().subscribe(
            result => {
                this.frequencyOptions = result.data;
            });

        this.dataHandlerService.retrieveSupplierTypes().subscribe(
            result => {
                this.supplierTypes = result.data;
            });
        this.dataHandlerService.getProfileTypes().subscribe(
            result => {
                this.profileTypes = result.data;
            });
        this.dataHandlerService.retrieveLocales().subscribe(
            result => {
                this.locales = result.data;
            });

        this.dataStore.get(DataKey.routes).subscribe(data => {
            if (data !== null) {
                this.routes = data;
            } else {
                this.routes = [];
            }
        });
        const x = this.dataStore.get(DataKey.transferModes).subscribe((data) => {
            if (data !== null) {
                this.transferModes = data;

            } else {
                this.transferModes = [];
            }
        });

        this.accommodationService.getRoomTypes(new RoomTypeSearchCriteria()).subscribe(data => {
            this.roomTypeOptions = data.data;
        });

        this.accommodationService.getRoomFacilities(new RoomFacilitySearchCriteria()).subscribe(data => {
            this.masterRoomFacilities = data.data;
        });
    }

    public onAddNote() {
        this.dataStore.set(DataKey.modalClose, null);
        let dataObject = new ModalData(LEVEL.SUCCESS, 'Add a note',

            null,
            null,
            'payment-modal',
            AddNoteComponent,
            {}
        );

        this.confirmModal(dataObject);

        this.modalCloseSubscription = this.dataStore.get(DataKey.modalClose).subscribe((data) => {
            if (data != null) {
                this.modalCloseSubscription.unsubscribe();
                this.closeModal();
            }
        });
    }

    public onAddDocument() {
        this.setOldDocsTypesAndSubscribeForNewTypes();
        this.dataStore.set(DataKey.modalClose, null);
        let dataObject = new ModalData(
            LEVEL.SUCCESS,
            'Add Documents',
            null,
            null,
            'modal-basic--alt modal-basic--small add-service-menu',
            AddDocumentsComponent,
            {
                'docTypeOptions': this.docTypeOptions,
                'selectedDocs': this.docTypes
            }
        );
        this.confirmModal(dataObject);

        this.modalCloseSubscription = this.dataStore.get(DataKey.modalClose).subscribe((data) => {
            if (data != null) {
                this.modalCloseSubscription.unsubscribe();
                this.closeModal();
            }
        });
    }

    public onAddGenericGrp() {
        const resourceGroup = new ResourceGroup();
        resourceGroup.genericGroup = new GenericGroup();
        resourceGroup.tempId = this.addingResourceGrpCount++;
        resourceGroup.resourceGroupAvailabilities = [];
        resourceGroup.noOfUnits = 0;
        this.supplier.resourceCollection.genericCollection.push(resourceGroup);
        this.expansionPanelDirective.forEach((expansionElement) => {
            if (expansionElement.id === 'generic_summary_strip') {
                expansionElement.expansionState.next(false);
            }
        });
    }

    public onAddVehicleGrp() {
        const resourceGroup = new ResourceGroup();
        resourceGroup.vehicleGroup = new VehicleGroup();
        resourceGroup.tempId = this.addingResourceGrpCount++;
        resourceGroup.resourceGroupAvailabilities = [];
        resourceGroup.noOfUnits = 0;
        this.supplier.resourceCollection.vehicleCollection.push(resourceGroup);
        this.expansionPanelDirective.forEach((expansionElement) => {
            if (expansionElement.id === 'vehicle_summary_strip') {
                expansionElement.expansionState.next(false);
            }
        });
    }

    public onAddRoomGrp() {
        const resourceGroup = new ResourceGroup();
        resourceGroup.roomGroup = new RoomGroup();
        resourceGroup.tempId = this.addingResourceGrpCount++;
        resourceGroup.resourceGroupAvailabilities = [];
        resourceGroup.noOfUnits = 0;
        resourceGroup.roomGroup.roomFacilities = [];
        this.supplier.resourceCollection.roomCollection.push(resourceGroup);
        this.onRoomGrpAddOrDelete();

        this.expansionPanelDirective.forEach((expansionElement) => {
            if (expansionElement.id === 'room_summary_strip') {
                expansionElement.expansionState.next(false);
            }
        });
    }

    closeModal() {
        if (this.modalService.close()) {
            this.modalService.close().subscribe(
                (res) => {
                }
            );
        }
    }

    getSupplierAttributes() {
        this.dataStore.set(DataKey.supplierAttributes, null, true);
        this.attributesSubscription = this.dataStore.get(DataKey.supplierAttributes).subscribe(
            data => {
                if (data != null) {
                    this.supplier.supplierAttributes = data;
                    this.dataChanged = true;
                    this.enableSaveButton();
                }
            });
    }

    public onAddressModified() {
        this.additionalContactSummary = this.cardDataHandlerService.processCardData(
            this.CARD_CONFIG_ADDITIONALCONTACT,
            this.additionalContactDetails,
            'additional_contact_summary'
        );

        if (this.additionalContactDetails.length > 0) {
            // this.additionalContactValid = false;
            this.additionalContactContents = [];
            this.additionalContactDetails.forEach(address => {
                let additionalContactContent = this.cardDataHandlerService.processCardData(
                    this.CARD_CONFIG_ADDITIONALCONTACT, address, 'additional_contact_detail');
                this.additionalContactContents.push(additionalContactContent);
            });
        }
    }

    public onGenericsModified() {
        this.genericsSummary = this.cardDataHandlerService.processCardData(
            this.CARD_CONFIG_GENERICS,
            this.supplier.resourceCollection.genericCollection,
            'generics_summary'
        );

        let valid = true;
        if (this.supplier.resourceCollection.genericCollection && this.supplier.resourceCollection.genericCollection.length > 0) {
            this.genericsContents = [];
            this.supplier.resourceCollection.genericCollection.forEach(genGrp => {
                const genContent = this.cardDataHandlerService.processCardData(
                    this.CARD_CONFIG_GENERICS, genGrp, 'generics_detail');
                this.genericsContents.push(genContent);
            });

            for (const genGrp of this.supplier.resourceCollection.genericCollection) {
                if (genGrp.resourceType && genGrp.genTypeCode && genGrp.noOfUnits) {
                } else {
                    valid = false;
                    break;
                }
            }
        }
        this.onChangeRooms(valid);
    }

    public onVehiclesModified() {
        this.vehiclesSummary = this.cardDataHandlerService.processCardData(
            this.CARD_CONFIG_VEHICLES,
            this.supplier.resourceCollection.vehicleCollection,
            'vehicles_summary'
        );

        let valid = true;
        if (this.supplier.resourceCollection.vehicleCollection && this.supplier.resourceCollection.vehicleCollection.length > 0) {
            this.vehiclesContents = [];
            this.supplier.resourceCollection.vehicleCollection.forEach(genGrp => {
                const genContent = this.cardDataHandlerService.processCardData(
                    this.CARD_CONFIG_VEHICLES, genGrp, 'vehicles_detail');
                this.vehiclesContents.push(genContent);
            });

            for (const vehGrp of this.supplier.resourceCollection.vehicleCollection) {
                if (vehGrp.resourceType && vehGrp.genTypeCode && vehGrp.noOfUnits && vehGrp.transferMode && vehGrp.vehicleModelCode) {
                } else {
                    valid = false;
                    break;
                }
            }
        }
        this.onChangeRooms(valid);
    }

    public onRoomGrpAddOrDelete() {
        let valid = true;
        if (this.supplier.resourceCollection.roomCollection && this.supplier.resourceCollection.roomCollection.length > 0) {
            this.roomsContents = [];
            for (const roomGrp of this.supplier.resourceCollection.roomCollection) {
                if (roomGrp.resourceType && roomGrp.genTypeCode && roomGrp.noOfUnits && roomGrp.roomTypeCode && roomGrp.roomGroup &&
                    roomGrp.roomGroup.minimumOccupancy && roomGrp.roomGroup.maximumOccupancy && roomGrp.roomGroup.standardOccupancy
                ) {
                } else {
                    valid = false;
                    break;
                }
            }
        }
        this.onChangeRooms(valid);
    }

    public onOccupancyClick(tempId: number) {
        const roomGrp = this.supplier.resourceCollection.roomCollection.find(value => value.tempId === tempId);
        this.dataStore.set(DataKey.popupClose, null);
        const dataObject = new ModalData(
            LEVEL.SUCCESS,
            'Occupancies of \'' + roomGrp.roomTypeName + '\' room group',
            {label: 'OK'},
            {label: 'Cancel'},
            'split-passengers-modal',
            OccupancySetupComponent,
            {
                roomGroup: roomGrp
            },
            '',
            '',
            '',
            '',
            '',
            '  '
        );
        this.confirmModal(dataObject);
        this.dataStore.get(DataKey.popupClose).subscribe((data) => {
            if (data != null) {
                this.closeModal();
                this.onRoomGrpAddOrDelete();
            }
        });
    }

    public onTaxSchemeModified() {

        this.taxSchemeSummary = this.cardDataHandlerService.processCardData(
            this.CARD_CONFIG_TAXSCHEME,
            this.supplier.taxSchemes,
            'tax_scheme_summary'
        );

        if (this.supplier.taxSchemes.length > 0) {
            this.taxSchemeContents = [];
            this.supplier.taxSchemes.forEach(address => {
                let taxSchemeContent = this.cardDataHandlerService.processCardData(
                    this.CARD_CONFIG_TAXSCHEME, address, 'tax_scheme_detail');
                this.taxSchemeContents.push(taxSchemeContent);
            });
        }
    }

    public onDocumentsModified() {

        this.documentSummary = this.cardDataHandlerService.processCardData(
            this.CARD_CONFIG_DOCUMENT,
            this.supplier.documents,
            'document_summary'
        );

        if (this.supplier.documents.length > 0) {
            this.documentContents = [];
            this.supplier.documents.forEach(doc => {
                let documentContent = this.cardDataHandlerService.processCardData(
                    this.CARD_CONFIG_DOCUMENT, doc, 'document_detail');
                this.documentContents.push(documentContent);
            });
        }
    }

    public onAddDocuments() {
        this.setOldDocsTypesAndSubscribeForNewTypes();
        this.dataStore.set(DataKey.modalClose, null);
        let dataObject = new ModalData(
            LEVEL.SUCCESS,
            'Add Documents',
            null,
            null,
            'modal-basic--alt modal-basic--small add-service-menu',
            AddDocumentsComponent,
            {
                'docTypeOptions': this.docTypeOptions,
                'selectedDocs': this.docTypes
            }
        );
        this.confirmModal(dataObject);

        this.modalCloseSubscription = this.dataStore.get(DataKey.modalClose).subscribe((data) => {
            if (data != null) {
                this.modalCloseSubscription.unsubscribe();
                this.closeModal();
            }
        });
    }

    setOldDocsTypesAndSubscribeForNewTypes() {
        let oldDocTypes = [];
        this.supplier.documents.forEach(
            doc => {
                let docTypeOption = this.docTypeOptions.find(docType => docType.code === doc.template);
                if (docTypeOption) {
                    oldDocTypes.push(docTypeOption);
                    doc.name = docTypeOption.name;
                }
            }
        );
        this.dataStore.set(DataKey.documentTypes, oldDocTypes, true);
        this.getDocumentTypes();
    }

    setOldRoomTypesAndSubscribeForNewTypes() {
        const oldRoomTypes = [];
        this.supplier.rooms.forEach(
            room => {
                const roomTypeOption = this.roomTypeOptions.find(roomType => roomType.name === room.roomType);
                if (roomTypeOption) {
                    oldRoomTypes.push(roomTypeOption);
                    room.roomType = roomTypeOption.name;
                }
            }
        );
        this.dataStore.set(DataKey.roomTypes, oldRoomTypes, true);
    }

    getDocumentTypes() {
        this.documentTypesSubscription = this.dataStore.get(DataKey.documentTypes).subscribe(data => {
            if (data != null) {
                this.docTypes = data;
                let existingDocTypes = [];
                this.supplier.documents.forEach(
                    doc => {
                        let DocTypes_ = this.docTypes.find(docType => docType.code === doc.template);
                        existingDocTypes.push(DocTypes_);
                    }
                );
                let newTypes = this.docTypes.filter(docType => !existingDocTypes.includes(docType));
                if (newTypes.length) {
                    newTypes.forEach(docType => {
                        let document = new DocumentData();
                        document.tempId = this.addingDocumentCount++;
                        document.name = docType.name;
                        document.template = docType.code;
                        this.supplier.documents.push(document);
                        this.expansionPanelDirective.forEach((expansionElement) => {
                            if (expansionElement.id === 'document_summary_strip') {
                                expansionElement.expansionState.next(false);
                            }
                        });
                    });
                }
            }
            this.onDocumentsModified();
        });
    }

    public onChangeSupplierInfoForm(event) {
        this.supplierInformationValid = event;
        this.enableSaveButton();
        if (this.supplierInfoSection) {
            this.supplierInfoSection.error = !this.supplierInformationValid;
        }
    }

    public onChangeKeyControlInfoForm(event) {
        this.keyControlInformationValid = event;
        this.enableSaveButton();
        if (this.supplierKeyControlSection) {
            this.supplierKeyControlSection.error = !this.keyControlInformationValid;
        }
    }

    public onChangeSupplierPaymentForm(event) {
        this.supplierPaymentValid = event;
        this.enableSaveButton();
        if (this.paymentInfoSection) {
            this.paymentInfoSection.error = !this.supplierInformationValid;
        }
    }

    public onChangeCostSetupForm() {
        let x = this.dataStore.get(DataKey.supplierTrsCostSetupValid).subscribe(data => {
            if (data !== null) {
                this.supplierCostSetupValid = data;
                if (this.supplierCostSetupSection) {
                    this.supplierCostSetupSection.error = !this.supplierCostSetupValid;
                }
                this.enableSaveButton();
            }
        });
        let y = this.dataStore.get(DataKey.supplierGenCostSetupValid).subscribe(data => {
            if (data !== null) {
                this.supplierGenCostSetupValid = data;
                if (this.supplierCostSetupSection) {
                    this.supplierCostSetupSection.error = !this.supplierGenCostSetupValid;
                }
                this.enableSaveButton();
            }
        });
        let z = this.dataStore.get(DataKey.supplierRoomCostSetupValid).subscribe(data => {
            if (data !== null) {
                this.supplierRoomCostSetupValid = data;
                if (this.supplierCostSetupSection) {
                    this.supplierCostSetupSection.error = !this.supplierRoomCostSetupValid;
                }
                this.enableSaveButton();
            }
        });
    }

    public onChangePersonalContactForm(event) {
        this.personalContactValid = event;
        if (this.contactDetailsSection) {
            this.contactDetailsSection.error = !this.personalContactValid;
        }
        this.enableSaveButton();
    }

    public onChangeAdditionalContactForm(event) {
        this.additionalContactValid = event;
        if (this.additionalContactsSection) {
            this.additionalContactsSection.error = !this.additionalContactValid;
        }
        this.enableSaveButton();
    }

    public onChangeGenerics(event) {
        this.genericGrpValid = event;
        if (this.genericGrpSection) {
            this.genericGrpSection.error = !this.genericGrpValid;
        }
        this.enableSaveButton();
    }

    public onChangeVehicles(event) {
        this.vehicleGrpValid = event;
        if (this.vehicleGrpSection) {
            this.vehicleGrpSection.error = !this.vehicleGrpValid;
        }
        this.enableSaveButton();
    }

    public onChangeRooms(event) {
        this.roomsSummary = this.cardDataHandlerService.processCardData(
            this.CARD_CONFIG_ROOMS,
            this.supplier.resourceCollection.roomCollection,
            'rooms_summary'
        );
        this.supplier.resourceCollection.roomCollection.forEach(genGrp => {
            const genContent = this.cardDataHandlerService.processCardData(
                this.CARD_CONFIG_ROOMS, genGrp, 'rooms_detail');
            this.roomsContents.push(genContent);
        });
        this.roomGrpValid = event;
        if (this.roomGrpSection) {
            this.roomGrpSection.error = !this.roomGrpValid;
        }
        this.enableSaveButton();
    }

    onChangeActiveStatus($event: boolean) {
        this.enableSaveButton();
    }

    onChangeServiceRequiredStatus($event: boolean) {
        this.enableSaveButton();
    }

    private enableSaveButton() {
        this.updateDataChanged();
        if ((this.dataChanged && !this.formSaved)
            && this.supplierPaymentValid
            && this.supplierInformationValid
            && this.personalContactValid
            && this.additionalContactValid
            && this.roomGrpValid
            && this.roomsAllocationValid
            && this.supplierCostSetupValid && this.supplierGenCostSetupValid) {

            this.userJourneyManager.canProceed.next(true);
            this.focusViewManager.canProceed.next(true);
            this.isSaveEnable = true;
            this.localStorage.store(LocalDataKey.unSavedSupplier, this.supplier);
        } else {
            this.disableSaveButton();
        }
    }

    private disableSaveButton() {
        this.userJourneyManager.canProceed.next(false);
        this.focusViewManager.canProceed.next(false);
        this.isSaveEnable = false;
    }

    private updateDataChanged() {
        this.updateSupplierChanges();
        // to make it same as resourceUnedited
        this.supplier.contactDetails.forEach(value => {
            if (value) {
                if (value.state === '') {
                    value.state = null;
                }
            }
        });
        if (JSON.stringify(this.supplier) !== JSON.stringify(this.editSupplierUnedited)) {
            this.dataChanged = true;
            this.formSaved = false;
        } else {
            this.dataChanged = false;
        }

    }

    updateSupplierChanges() {
        if (this.supplier) {
            this.supplier.contactDetails = [];
            this.supplier.contactDetails.push(this.contactDetails);
            Array.prototype.push.apply(this.supplier.contactDetails, this.additionalContactDetails);

            this.resourceCostDataSubscription = this.dataStore.get(DataKey.resourceCostData).subscribe(data => {
                if (data) {
                    this.supplier.seasons = data.seasons;
                }
            });
        }

    }
    saveSupplier() {
        this.updateSupplierChanges();
        if (this.supplierID){
            this.dataStore.set(DataKey.updateSupplier, null, true);
            this.updateSupplierObserver();
            if (this.supplier && this.supplier.locale && this.supplier.locale instanceof Array) {
                this.supplier.locale = this.supplier.locale.toString();
            }
            this.supplierService.updateSupplier(this.supplier, this.supplierID);
            this.spinnerService.show();
        } else {
            this.dataStore.set(DataKey.saveSupplier, null, true);
            this.createSupplierObserver();
            if (this.supplier && this.supplier.locale && this.supplier.locale instanceof Array) {
                this.supplier.locale = this.supplier.locale.toString();
            }
            this.spinnerService.show();
            this.supplierService.saveSupplier(this.supplier);
        }
    }

    updateSupplierObserver() {
        this.updateSupplierObsv = this.dataStore.get(DataKey.updateSupplier, true).subscribe(result => {
            // console.log(result);
            if (this.commonHelper.dataValidity(result)) {
                this.updateSupplierObsv.unsubscribe();
                this.dataStore.set(DataKey.updateSupplier, null, true);
                this.spinnerService.hide();
                if (result.status.code === TC.ErrorCodes.SUCCESS) {
                    this.editSupplierUnedited = JSON.parse(JSON.stringify(this.supplier));
                    this.formSaved = true;
                    this.dataChanged = false;
                    this.disableSaveButton();
                    this.localStorage.delete(LocalDataKey.unSavedSupplier);

                    this.common.showSnackBar('Successfully updated the supplier data', 3000, TcErrorType.TYPE.INFO);
                    this.handleNavigation(result);
                    // let confirmSuccess = new DialogModel(
                    //     true,
                    //     LEVEL.SUCCESS,
                    //     'Success',
                    //     'Successfully updated the supplier data',
                    //     true,
                    //     2000
                    // );
                    // this.dialogService
                    //     .confirm(confirmSuccess)
                    //     .subscribe((res) => {
                    //         if (res === true) {
                    //             console.log('true');
                    //             /* this.dataStore.set(DataKey.cart, null, true);
                    //              this.spinnerService.show();
                    //              this.addPassengers();*/
                    //         }
                    //     });
                } else if (result.status.code === TC.ErrorCodes.ERROR) {
                    let confirmError = new DialogModel(
                        true,
                        LEVEL.ERROR,
                        'Failed',
                        'Failed to update the supplier data',
                        true,
                        2000
                    );
                    this.dialogService
                        .confirm(confirmError)
                        .subscribe((res) => {
                            if (res === true) {
                                console.log('true');
                                /* this.dataStore.set(DataKey.cart, null, true);
                                 this.spinnerService.show();
                                 this.addPassengers();*/
                            }
                        });
                }
            } else if (result instanceof TcApiError) {
                this.spinnerService.hide();
            } else if (result instanceof TcHttpError) {
                this.spinnerService.hide();
            }

        });
    }

    createSupplierObserver() {
        this.createSupplierObsv = this.dataStore.get(DataKey.saveSupplier, true).subscribe(result => {
            // console.log(result);
            if (this.commonHelper.dataValidity(result)) {
                this.createSupplierObsv.unsubscribe();
                this.spinnerService.hide();
                this.dataStore.set(DataKey.saveSupplier, null, true);
                if (result.status.code === TC.ErrorCodes.SUCCESS) {
                    this.editSupplierUnedited = JSON.parse(JSON.stringify(this.supplier));
                    this.formSaved = true;
                    this.dataChanged = false;
                    this.disableSaveButton();
                    this.localStorage.delete(LocalDataKey.unSavedSupplier);
                    this.common.showSnackBar('Supplier Saved Successfully.', 3000, TcErrorType.TYPE.INFO);
                    this.handleNavigation(result);
                } else if (result.status.code === TC.ErrorCodes.ERROR) {
                    if (result.error.message.includes('OP_SUPPLIER_UK1')) {
                        let confirmError = new DialogModel(
                            true,
                            LEVEL.ERROR,
                            'CONFLICT',
                            'Supplier Code is already exists',
                            true,
                            2000
                        );
                        this.dialogService
                            .confirm(confirmError)
                            .subscribe((res) => {
                                if (res === true) {
                                    console.log('true');
                                }
                            });
                    } else {
                        let confirmError = new DialogModel(
                            true,
                            LEVEL.ERROR,
                            'Failed',
                            'Failed to Save the Supplier Details',
                            true,
                            2000
                        );
                        this.dialogService
                            .confirm(confirmError)
                            .subscribe((res) => {
                                if (res === true) {
                                    console.log('true');
                                }
                            });
                    }
                }
            } else if (result instanceof TcApiError) {
                this.spinnerService.hide();
            } else if (result instanceof TcHttpError) {
                this.spinnerService.hide();
            }
        });
    }

    // country state city
    public onNewCountryStateCityObserver($event: InitialCountryStateCityCodes) {
        let wrapper = new CountryStateCityWrapper();
        this.countryStateCityChangeMap.set($event.id, wrapper);

        this.countryStateCityService.getInitialCity($event.countryCode, $event.stateCode, $event.cityCode)
            .then((value: City) => {
                if (value) {
                    wrapper.city = value;
                    this.changeDetectorService.notifyCountryStateCityChange();
                }
            });

        this.countryStateCityService.getInitialState($event.countryCode, $event.stateCode)
            .then((value: State) => {
                if (value) {
                    wrapper.state = value;
                    this.changeDetectorService.notifyCountryStateCityChange();
                }
            });

        this.countryStateCityService.getInitialCountry($event.countryCode)
            .then((value: Country) => {
                if (value) {
                    wrapper.country = value;
                    this.changeDetectorService.notifyCountryStateCityChange();
                }
            });
    }

    public onSelectCountry($event: CountryChange) {
        let wrapper = this.countryStateCityChangeMap.get($event.id);
        this.countryStateCityService.onSelectCountry($event.country, wrapper);
        this.changeDetectorService.notifyCountryStateCityChange();
    }

    public onSelectState($event: StateChange) {
        let wrapper = this.countryStateCityChangeMap.get($event.id);
        this.countryStateCityService.onSelectState($event.state, wrapper);
        this.changeDetectorService.notifyCountryStateCityChange();
    }

    public onSelectCity($event: CityChange) {
        let wrapper = this.countryStateCityChangeMap.get($event.id);
        this.countryStateCityService.onSelectCity($event.city, wrapper);
        this.changeDetectorService.notifyCountryStateCityChange();
    }

    public onCountryTextChange($event: CountryChange) {
        this.countryStateCityService.onCountryTextChange($event.text);
    }

    public onStateTextChange($event: StateChange) {
        let wrapper = this.countryStateCityChangeMap.get($event.id);
        this.countryStateCityService.onStateTextChange($event.text, wrapper);
    }

    public onCityTextChange($event: CityChange) {
        let wrapper = this.countryStateCityChangeMap.get($event.id);
        this.countryStateCityService.onCityTextChange($event.text, wrapper);
    }

    private subscribeCountryStateCityListChange() {
        this.countryStateCityService.countryListObserver.subscribe(
            value => {
                this.countryList = value;
            }
        );

        this.countryStateCityService.stateListObserver.subscribe(
            value => {
                this.stateList = value;
            }
        );

        this.countryStateCityService.cityListObserver.subscribe(
            value => {
                this.cityList = value;
            }
        );
    }

    // --country state city--//

    public getCurrencies() {
        let currencySearchCriteria = new CurrencySearchCriteria();
        currencySearchCriteria.size = 1000000;
        return this.currencyService.getRows(currencySearchCriteria).subscribe(
            result => {
                this.currencyList = ResponseUtil.getDataArray<Currency>(result);
                this.currencyList = this.currencyList.filter(value => value.contract);
            });
    }

    public getPaymentMethods() {
        this.dataHandlerService.retrievePaymentMethods().subscribe(
            result => {
                this.payMethods = result.data;
            });
    }

    public onSupplierAttributesClick() {
        this.dataStore.set(DataKey.modalClose, null);
        let dataObject = new ModalData(LEVEL.SUCCESS, 'Supplier Attributes',

            null,
            null,
            'supplier-attributes-modal',
            ManageSupplierAttributesWrapperComponent,
            {'supplierAttributes': this.supplier.supplierAttributes}
        );

        this.confirmModal(dataObject);
        this.modalCloseSubscription = this.dataStore.get(DataKey.modalClose).subscribe((data) => {
            if (data != null) {
                this.modalCloseSubscription.unsubscribe();
                this.closeModal();
            }
        });
    }

    private confirmModal(data: any): void {
        this.modalService.confirm(data).subscribe((res) => {
            console.log(res);
        });
    }

    onResourceTypeSelection(event) {
        if (event) {
            this.selectedResTypes = event;
            this.showTransportCostAddButton = this.selectedResTypes && this.selectedResTypes.length > 0 &&
                this.selectedResTypes.filter(type => type && type['resourceCategory'] === 'transport').length > 0;

            this.showGenericCostAddButton = this.selectedResTypes && this.selectedResTypes.length > 0 &&
                this.selectedResTypes.filter(type => type && type['resourceCategory'] === 'generic').length > 0;

            this.showRoomCostAddButton = this.selectedResTypes && this.selectedResTypes.length > 0 &&
                this.selectedResTypes.filter(type => type['code'] && type['code'].toUpperCase() === 'HTL').length > 0;

            let types = [];
            this.selectedResTypes.forEach(value => {
                types.push(value.code);
            });
            this.selectedResProfTypes = this.resourceTypes.filter(value => types.includes(value.code));
        } else {
            let confirmError = new DialogModel(
                true,
                LEVEL.ERROR,
                'Failed',
                'Failed to update the resource type. Please remove Resource Cost and Tax Schemes related to resource type before you save.',
                true,
                2000
            );
            this.dialogService
                .confirm(confirmError)
                .subscribe((res) => {
                    if (res === true) {
                        console.log('true');
                    }
                });
        }

    }

    handleNavigation(data: any) {
        if (data && JSON.stringify(data) !== JSON.stringify({})) {
            if (data.status.code === TC.ErrorCodes.SUCCESS) {

                // if the client is trade client
                if (data && data.data && data.data.length > 0 && data.data[0].code) {
                    const supplierSearchCriteria: SupplierSearchCriteria = new SupplierSearchCriteria();
                    supplierSearchCriteria.code = data.data[0].code;
                    supplierSearchCriteria.supplierId = data.data[0].supplierId;
                    supplierSearchCriteria.status = data.data[0].activeStatus;
                    let parameters = this.queryParamService.getQueryParamsArray(supplierSearchCriteria);
                    this.rootService.setDataToLocalStorage(
                        TCO.AppData.SUPPLIER_SEARCH_CRITERIA,
                        supplierSearchCriteria,
                        true,
                        parameters
                    );
                }

                setTimeout(() => {

                    let journeyToProceed = 'BEGIN_SUPPLIER_MANAGEMENT';

                    // TODO chnage to DataKey.client after changing service
                    this.dataStore.set(DataKey.updateSupplier, null, true);
                    this.dataStore.set(DataKey.saveSupplier, null, true);

                    if (journeyToProceed) {
                        this.userJourneyManager.goForKey(journeyToProceed);
                    }

                }, 100);
            } else {
                if (data.status.code === TC.ErrorCodes.ERROR && data.error) {
                }
            }
        }
    }

    ngOnDestroy() {
        this.documentTypesSubscription.unsubscribe();
        this.modalCloseSubscription.unsubscribe();
        this.createSupplierObsv.unsubscribe();
        this.updateSupplierObsv.unsubscribe();
        if (this.roomTypesSubscription) {
            this.roomTypesSubscription.unsubscribe();
        }
        if (this.resourceCostDataSubscription) {
            this.resourceCostDataSubscription.unsubscribe();
        }
        this.removeHeaderActions();

        this.localStorage.delete(LocalDataKey.unSavedSupplier);
    }

    public ngAfterViewInit(): void {
        let element = document.querySelector('.main-panel');
        if (element) {
            element.scrollTop = 0;
        }
    }

    private getUserDetails() {
        this.userProfile = this.dataStore.get(DataKey.userProfile).getValue();
    }
}
