/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../body-view-year/body-view-year.component.ngfactory";
import * as i2 from "../body-view-year/body-view-year.component";
import * as i3 from "./calendar-body.component";
var styles_CalendarBodyComponent = [];
var RenderType_CalendarBodyComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CalendarBodyComponent, data: {} });
export { RenderType_CalendarBodyComponent as RenderType_CalendarBodyComponent };
export function View_CalendarBodyComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { bodyViewYear: 0 }), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "tc-sc__cal-panel"]], null, [[null, "scroll"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("scroll" === en)) {
        var pd_0 = (_co.onScrollYears() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "tc-body-view-year", [], null, [[null, "selectDate"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectDate" === en)) {
        var pd_0 = (_co.onSelectDate($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_BodyViewYearComponent_0, i1.RenderType_BodyViewYearComponent)), i0.ɵdid(3, 114688, [[1, 4]], 0, i2.BodyViewYearComponent, [], { calendarNavData: [0, "calendarNavData"], yearsArray: [1, "yearsArray"], rangeGroups: [2, "rangeGroups"], calendarRefreshEvent: [3, "calendarRefreshEvent"] }, { selectDate: "selectDate" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.calendarNavData; var currVal_1 = _co.yearsArray; var currVal_2 = _co.rangeGroups; var currVal_3 = _co.calendarRefreshEvent; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_CalendarBodyComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-calendar-body", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onResize($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_CalendarBodyComponent_0, RenderType_CalendarBodyComponent)), i0.ɵdid(1, 4308992, null, 0, i3.CalendarBodyComponent, [i0.ElementRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CalendarBodyComponentNgFactory = i0.ɵccf("tc-calendar-body", i3.CalendarBodyComponent, View_CalendarBodyComponent_Host_0, { calendarNavData: "calendarNavData", yearsArray: "yearsArray", rangeGroups: "rangeGroups", calendarRefreshEvent: "calendarRefreshEvent" }, { selectDate: "selectDate" }, []);
export { CalendarBodyComponentNgFactory as CalendarBodyComponentNgFactory };
