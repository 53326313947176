import { Direction } from '@angular/cdk/bidi/typings/directionality';
import {Injectable} from '@angular/core';
import { ConfigLoader } from '@tc-core/util/framework';
import { Observable } from 'rxjs';
import { LanguageSearchCriteria } from '../../../models/criteria/language-search-criteria';
import { UrlPaths } from '../../backend-consumers/url-paths';
import { DMCQueryParamsService } from '../framework/dmc-query-params.service';
import { RequestService } from '../framework/request.service';

@Injectable({
    providedIn: 'root'
})
export class LanguageService {

    public languageDirection: Direction = 'ltr';
    public language: string;

    constructor(
        private configLoader: ConfigLoader,
        private requestService: RequestService,
        private queryParamsService: DMCQueryParamsService
    ) {
    }

    checkLangDirection(lang: string) {
        if (lang === 'ar') {
            this.languageDirection = 'rtl';
        } else {
            this.languageDirection = 'ltr';
        }
    }

    public getLanguages(criteria: LanguageSearchCriteria): Observable<any> {
        const reqPrams = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.get(
            UrlPaths.OP_DATA_URL_KEY,
            [UrlPaths.OP_DATA_VERSION, UrlPaths.LANGUAGES],
            reqPrams
        );
    }

}
