import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { LEVEL } from '@tc-core/model/it/codegen/ui/framework/dialog-model';
import { ModalData } from '@tc-core/model/it/codegen/ui/framework/modal-data';
import { TC } from '@tc-core/util/constants';
import { ConfigLoader } from '@tc-core/util/framework';
import { EventManager } from '@tc-core/util/framework/event-manager.service';
import { FocusViewManager } from '@tc-core/util/framework/focus-view-manager.service';
import { SpinnerService } from '@tc-core/util/ui';
import { FocusViewService } from '@tc/focus-view/focus-view.service';
import { ModalService } from '@tc/modal/modal.service';
import { IGetRowsParams } from 'ag-grid-community';
import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { TCO } from '../../../constants';
import { DispatchEntrySearchCriteria } from '../../../models/criteria/dispatch-entry-search-criteria';
import { SupplierSearchCriteria } from '../../../models/criteria/supplier-search-criteria';
import { DispatchEntry, DispatchStatus } from '../../../models/document/dispatch-entry';
import { SortDirection } from '../../../models/helper/sort-direction';
import { OPResponseWrapper } from '../../../models/response/op-response-wrapper';
import { SupplierSummary } from '../../../models/summary/supplier-summary';
import { DocumentService } from '../../../services/backend-consumers/documents/document.service';
import { SupplierService } from '../../../services/backend-consumers/supplier-service/supplier.service';
import { DataKey, DataStoreService } from '../../../services/util/framework/data-store.service';
import { ResponseUtil } from '../../../services/util/response/response-util.service';
import {
    SetupGridComp
} from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid-comp';
import {
    SetupGridDataSource
} from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid-data-source';
import {
    SetupGridComponent
} from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid.component';
import {
    ActionRendererAction
} from '../../../widgets/framework/ag-grid-custom-components/renderers/actions/actions.component';
import {
    DispatchDataModalComponent,
    DispatchDataModalType
} from '../dispatch-data-modal/dispatch-data-modal.component';
import EMPTY_CHAR = TC.FORMAT.EMPTY_CHAR;

@Component({
    selector: 'tc-document-detail-focus-view',
    templateUrl: './document-detail-focus-view.component.html'
})
export class DocumentDetailFocusViewComponent extends SetupGridComp implements OnInit, OnDestroy, SetupGridDataSource {

    @Input() document: any;
    @Input() defaultSupplierCode: string;

    defaultSupplier: SupplierSummary;
    documentId = -1;

    @ViewChild('dispatchViewGrid')
    private setupGrid: SetupGridComponent;

    public colDefConfig = [];
    dispatchEntrySearchCriteria: DispatchEntrySearchCriteria = new DispatchEntrySearchCriteria();

    gridActions: ActionRendererAction[] = [];

    @ViewChild('statusCell') statusCell: TemplateRef<any>;
    @ViewChild('dispatchIdCell') dispatchIdCell: TemplateRef<any>;

    dispatchPostPatchModalCloseSubscription: Subscription = new Subscription();

    dataUpdated = false;

    constructor(
        private em: EventManager,
        private focusViewManager: FocusViewManager,
        private focusViewService: FocusViewService,
        private configLoader: ConfigLoader,
        private dataStoreService: DataStoreService,
        private documentService: DocumentService,
        private modalService: ModalService,
        private supplierService: SupplierService,
        private spinnerService: SpinnerService
    ) {
        super();
    }

    ngOnInit() {
        this.documentId = this.document.documentId;
        this.focusViewManager.canProceed.next(true);
        this.focusViewManager.canProceedState.next(true);
        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'CLOSE_FOCUS_VIEW')
            .subscribe(e => {
                this.focusViewService.close(true);
            });
        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'ADD_NEW_DISPATCH_ENTRY').subscribe(e => {
            this.onAddDispatchEntryClick();
        });
        this.colDefConfig = this.configLoader.configurations.get(TCO.CONF.CONF_DISPATCH_ENTRIES);
        this.addGridActions();
    }

    public createNewRow(): any {
    }

    public deleteRow(row: any): Observable<any> {
        return undefined;
    }

    public getRows(params: IGetRowsParams): Observable<any> {
        console.log('fetch requesting for ' + params.startRow + ' to ' + params.endRow);
        console.log(params.sortModel);

        // default sort
        if (!(params.sortModel && params.sortModel[0])) {
            const sort = [
                {
                    colId: 'sentTime',
                    sort: 'desc'
                }
            ];
            this.setupGrid.gridApi.setSortModel(sort);
            params.sortModel = sort;
        }

        const pageSize = params.endRow - params.startRow;
        if (!this.dispatchEntrySearchCriteria) {
            this.dispatchEntrySearchCriteria = new DispatchEntrySearchCriteria();
        }
        this.dispatchEntrySearchCriteria.filterByScheduleTime = false;
        this.dispatchEntrySearchCriteria.start = params.startRow;
        this.dispatchEntrySearchCriteria.size = pageSize;
        if (params.sortModel && params.sortModel[0]) {
            this.dispatchEntrySearchCriteria.sortBy = params.sortModel[0].colId;
            if (params.sortModel[0].sort === SetupGridComp.GRID_SORT_ASCENDING) {
                this.dispatchEntrySearchCriteria.sortDirection = SortDirection.ASC;
            } else {
                this.dispatchEntrySearchCriteria.sortDirection = SortDirection.DESC;
            }
        }
        return this.documentService.searchDispatchEntries(this.documentId, this.dispatchEntrySearchCriteria)
                   .pipe(
                       tap(data =>
                           this.dataStoreService.set(DataKey.dispatchEntrySearchResultsForCriteria, data)
                       )
                   );
    }

    public isInvalidRow(params): boolean {
        return false;
    }

    public isUnsavedRow(row: any): boolean {
        return false;
    }

    public ngOnDestroy(): void {
    }

    public saveRow(row: any, event: any): Observable<any> {
        return undefined;
    }

    /*
     grid config methods which are called by grid setup (ag grid)
     and set by grid config
     */

    getSupplierName = (params) => {
        let supText = EMPTY_CHAR;
        if (params && params.data) {
            if (params.data.supplierName) {
                supText = params.data.supplierName;
            } else {
                supText = EMPTY_CHAR;
            }
            supText += ' | ';
            if (params.data.supplierCode) {
                supText += params.data.supplierCode;
            } else {
                supText += EMPTY_CHAR;
            }
        }
        return supText;
    };

    getUserName = (params) => {
        if (params && params.data && params.data.userName) {
            return params.data.systemGenerated ? 'System' : params.data.userName;
        } else if (params && params.data && params.data.systemGenerated) {
            return 'System';
        } else {
            return EMPTY_CHAR;
        }
    };

    statusCellClass = () => {
        return {
            'tc-ag-grid-cell--warning'(params)
            {
                if (params.data && params.data.dispatchStatus && params.data.dispatchStatus ===
                    DispatchStatus.PENDING) {
                    return true;
                }
            },
            'tc-ag-grid-cell--success'(params)
            {
                if (params.data && params.data.dispatchStatus && params.data.dispatchStatus ===
                    DispatchStatus.SUCCESS) {
                    return true;
                }
            },
            'tc-ag-grid-cell--danger'(params)
            {
                if (params.data && params.data.dispatchStatus && params.data.dispatchStatus ===
                    DispatchStatus.FAILED) {
                    return true;
                }
            }
        };
    };

    statusPending = (params) => {
        return params.data && params.data.dispatchStatus && params.data.dispatchStatus ===
            DispatchStatus.PENDING;
    };

    statusSuccess = (params) => {
        return params.data && params.data.dispatchStatus && params.data.dispatchStatus ===
            DispatchStatus.SUCCESS;
    };

    statusFailed = (params) => {
        return params.data && params.data.dispatchStatus && params.data.dispatchStatus ===
            DispatchStatus.FAILED;
    };

    private addGridActions() {
        this.gridActions.push(
            {
                icon: 'send',
                action: this.clickSendAction.bind(this),
                disable: this.isDisabledSendButton.bind(this),
                tooltip: 'Send Now'
            }
        );
    }

    isDisabledSendButton = (params): boolean => {
        const dispatchEntry: DispatchEntry = params.data;
        return !dispatchEntry.email;
    };

    clickSendAction = (params) => {
        this.spinnerService.show();
        const dispatchEntry: DispatchEntry = params.data;
        this.openDispatchPostPatchDialogWithSupplier('Add New Dispatch Entry', DispatchDataModalType.SEND_DISPATCH, dispatchEntry);
    };

    public onAddDispatchEntryClick() {
        this.spinnerService.show();
        console.log('add clicked');
        const disPatchEntry: DispatchEntry = null;
        this.openDispatchPostPatchDialogWithSupplier('Add New Dispatch Entry', DispatchDataModalType.ADD_DISPATCH, disPatchEntry);
    }

    private openDispatchPostPatchDialogWithSupplier(heading: string, type: DispatchDataModalType, dispatchEntryInput: DispatchEntry) {
        this.spinnerService.show();
        const criteria = new SupplierSearchCriteria();
        criteria.code = this.defaultSupplierCode;

        this.supplierService.searchSuppliersNoDataKey(criteria).subscribe(
            (result: OPResponseWrapper<SupplierSummary>) => {
                const defaultSupplier = ResponseUtil.getFirstData(result);
                this.defaultSupplier = new SupplierSummary();
                this.defaultSupplier.supplierId = defaultSupplier.supplierId;
                this.defaultSupplier.code = defaultSupplier.code;
                this.defaultSupplier.name = defaultSupplier.name;
                this.defaultSupplier.email = defaultSupplier.email;
            }).add(() => {
            this.openDispatchPostPatchDialog(heading, type, dispatchEntryInput);
            this.spinnerService.hide();
        });
    }

    private openDispatchPostPatchDialog(
        heading: string, type: DispatchDataModalType, dispatchEntryInput: DispatchEntry) {
        this.spinnerService.show();
        this.dataStoreService.set(DataKey.popupClose, null);
        const dataObject = new ModalData(
            LEVEL.SUCCESS,
            heading,
            true,
            true,
            'split-passengers-modal',
            DispatchDataModalComponent,
            {
                modalType: type,
                document: this.document,
                dispatchEntry: dispatchEntryInput,
                defaultSupplier: this.defaultSupplier
            }
        );
        dataObject.disableClose = true;
        this.confirmModal(dataObject);

        this.dispatchPostPatchModalCloseSubscription = this.dataStoreService.get(DataKey.popupClose)
            .subscribe((data) => {
                if (data != null) {
                    this.dispatchPostPatchModalCloseSubscription.unsubscribe();
                    this.closeModal();
                    if (data) {
                        this.dataUpdated = true;
                        this.setupGrid.runForceSearch();
                        this.dataStoreService.set(
                            DataKey.docDetailsFocusViewDataChanged,
                            true
                        );
                    }
                }
                this.spinnerService.hide();
            });
    }

    private confirmModal(data: any): void {
        this.modalService.confirm(data).subscribe((res) => {
        });
    }

    closeModal() {
        if (this.modalService.close()) {
            this.modalService.close().subscribe(
                (res) => {
                }
            );
        }
    }
}
