import { ApprovalStatus } from '../user/user-service-profile';
import { AbstractSearchCriteria } from './abstract-search-criteria';
import {UserBusinessType} from '../user/user-business-type';

export class UserProfileSearchCriteria extends AbstractSearchCriteria {
    firstName = "";
    lastName = "";
    name = '';
    email = "";
    phoneNumber = "";
    username = "";
    supplierCode = "";
    supplierName = "";
    businessType: UserBusinessType;
    serviceType = "";
    resourceId: number;
    supplierId: number;
    serviceTypeCodes: string[] = [];
    restrictedServiceTypes: string[] = [];
    allowedServiceTypes: string[] = [];
    approvalStatus: ApprovalStatus;
    resourceLinkSuggestion = false;
    resourceSuggestIncludingId: number;

    // grid sort
    gridSorting: string;
    gridFiltering: string;
}
