<div class="container-fluid tc-item-wrapper tcpi-card">
    <div class="tc-item-wrapper__body">
        <div class="row">
            <div class="col-md-12">
                <div class="tc-flex-box">
                    <div class="tc-flex-item icon-cage input-icon">
                        <div class="card-icon--small icn-color-primary"><i class="material-icons">account_circle</i>
                        </div>
                    </div>
                    <div class="tc-flex-item input-cage">
                        <div class="col-xs-12 col-sm-6  col-md-3 col-lg-3">
                            <div class="tc-content--wrap tc-input-view">
                                <div class="tc-caption-txt txt-light">
                                    {{"Type" | translate}}
                                </div>
                                <div class="tc-body1--txt">{{resourceType ?
                                    resourceType.name : EMPTY_CHAR}}
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-6  col-md-3 col-lg-3">
                            <div class="tc-content--wrap tc-input-view">
                                <div class="tc-caption-txt txt-light">{{"Id" | translate}}
                                </div>
                                <div class="tc-body1--txt  ">{{resource?.resourceId ?
                                    resource?.resourceId : EMPTY_CHAR}}
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-6  col-md-3 col-lg-3">
                            <div class="tc-content--wrap tc-input-view">
                                <div class="tc-caption-txt txt-light">{{"Business Type" | translate}}
                                </div>
                                <div class="tc-body1--txt  ">{{resource?.businessType ?
                                    resource?.businessType : EMPTY_CHAR}}
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                            <div class="tc-content--wrap">
                                <div class="card-chips item-right">
                                    <div
                                            id="TRD_PER_INFO_CLI_STATUS"
                                            *ngIf="resource?.activeStatus !== false"
                                            [ngClass]="resource?.activeStatus ?'active':'' "
                                            class="chip">
                                        {{"Active" | translate}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="tc-flex-box">
                    <div class="tc-flex-item icon-cage ">
                        <div class="card-icon--small icn-color-primary"></div>
                    </div>
                    <div class="tc-flex-item input-cage ">
                        <div class="col-xs-12 col-sm-6  col-md-3 col-lg-3">
                            <div class="tc-content--wrap tc-input-view">
                                <div class="tc-caption-txt txt-light">{{"Gender" | translate}}
                                </div>
                                <div class="tc-body1--txt  ">{{resource?.genericResource?.gender ?
                                    resource?.genericResource?.gender : EMPTY_CHAR}}
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-6  col-md-3 col-lg-3">
                            <div class="tc-content--wrap tc-input-view">
                                <div
                                        class="tc-caption-txt txt-light">{{"Date Of Birth" | translate}}
                                </div>
                                <div class="tc-body1--txt">{{resource?.genericResource?.dateOfBirth ? resource?.genericResource?.dateOfBirth : EMPTY_CHAR}}</div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-6  col-md-3 col-lg-3">
                            <div class="tc-content--wrap tc-input-view">
                                <div
                                        id="TRD_PER_INFO_HOD_REF"
                                        class="tc-caption-txt txt-light">{{"Profile Type" | translate}}
                                </div>
                                <div class="tc-body1--txt">{{resource?.supplierType ? resource?.supplierType : EMPTY_CHAR}}</div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-6  col-md-3 col-lg-3">
                            <div class="tc-content--wrap tc-input-view">
                                <div class="tc-caption-txt txt-light">{{"Supplier" | translate}}
                                </div>
                                <div
                                        id="TRADE_PER_INFO_EXT_REF"
                                        class="tc-body1--txt  ">{{resource?.supplierName ? resource.supplierName : EMPTY_CHAR}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="tc-flex-box">
                    <div class="tc-flex-item icon-cage ">
                        <div class="card-icon--small icn-color-primary"></div>
                    </div>
                    <div class="tc-flex-item input-cage ">

                        <div class="col-xs-12 col-sm-6  col-md-3 col-lg-3">
                            <div class="tc-content--wrap tc-input-view">
                                <div class="tc-caption-txt txt-light">{{"Preference" | translate}}
                                </div>
                                <div
                                        id="TRADE_PER_INFO_EXT_PREFERENCE"
                                        class="tc-body1--txt  ">{{resource?.preference ? resource.preference : EMPTY_CHAR}}
                                </div>
                            </div>
                        </div>

                        <div class="col-xs-12 col-sm-6  col-md-3 col-lg-3">
                            <div class="tc-content--wrap tc-input-view">
                                <div class="tc-caption-txt txt-light">{{"Grade" | translate}}
                                </div>
                                <div
                                        id="TRADE_PER_INFO_EXT_GRADE"
                                        class="tc-body1--txt  ">{{getGradeValue(resource.grade)}}
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-6  col-md-3 col-lg-3">
                            <div class="tc-content--wrap tc-input-view">
                                <div class="tc-caption-txt txt-light">{{"Linked User" | translate}}
                                </div>
                                <div class="tc-body1--txt">{{getUserName()}}
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-6  col-md-3 col-lg-3">
                            <div class="tc-content--wrap tc-input-view">
                            </div>
                            <img
                                    *ngIf="resource?.userProfile?.imageUrl"
                                    width="50px"
                                    height="50px"
                                    style="border-radius: 50%"
                                    [src]="resource?.userProfile?.imageUrl"
                                    alt="user image">
                        </div>
                        <div class="col-xs-12 col-sm-6  col-md-3 col-lg-3">
                            <div class="tc-content--wrap tc-input-view">
                                <div class="tc-caption-txt txt-light">{{"Capacity" | translate}}
                                </div>
                                <div
                                        class="tc-body1--txt  ">{{resource?.capacity ? resource.capacity : EMPTY_CHAR}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
