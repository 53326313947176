/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../grid-search-criteria.component.ngfactory";
import * as i2 from "../../../../services/helpers/chip-handler.service";
import * as i3 from "@tc/chip-input/chip-input.service";
import * as i4 from "@tc/chip-input/shared/parser.service";
import * as i5 from "@tc-core/util/system/date-formatter.service";
import * as i6 from "../../../../services/util/framework/data-store.service";
import * as i7 from "@tc-core/util/framework/config-loader.service";
import * as i8 from "@tc-core/util/helpers/common-helper.service";
import * as i9 from "../../../../services/backend-consumers/master-data-handler-service/data-handler.service";
import * as i10 from "../grid-search-criteria.component";
import * as i11 from "@angular/router";
import * as i12 from "@tc-core/util/framework/user-journey-manager.service";
import * as i13 from "../../../../services/util/framework/action-handler-service.service";
import * as i14 from "@tc-core/util/framework/event-manager.service";
import * as i15 from "../../../../services/util/framework/dmc-query-params.service";
import * as i16 from "@tc-core/util/ui/spinner.service";
import * as i17 from "../../../../services/util/core-services/root.service";
import * as i18 from "../../../../services/user-management/user-management.service";
import * as i19 from "./reports-search-criteria.component";
var styles_ReportsSearchCriteriaComponent = [];
var RenderType_ReportsSearchCriteriaComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ReportsSearchCriteriaComponent, data: {} });
export { RenderType_ReportsSearchCriteriaComponent as RenderType_ReportsSearchCriteriaComponent };
export function View_ReportsSearchCriteriaComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "tc-grid-search-criteria", [], null, null, null, i1.View_GridSearchCriteriaComponent_0, i1.RenderType_GridSearchCriteriaComponent)), i0.ɵprd(6144, null, "ChipHandlerService", null, [i2.ChipHandlerService]), i0.ɵprd(4608, null, i3.ChipInputService, i3.ChipInputService, [i4.Parser, i5.DateFormatter]), i0.ɵprd(512, null, i2.ChipHandlerService, i2.ChipHandlerService, [i0.Injector, i6.DataStoreService, i7.ConfigLoader, i8.CommonHelper, i5.DateFormatter, i9.DataHandlerService]), i0.ɵdid(4, 245760, null, 0, i10.GridSearchCriteriaComponent, [i11.ActivatedRoute, i7.ConfigLoader, i12.UserJourneyManager, i6.DataStoreService, i13.ActionHandlerService, i14.EventManager, i2.ChipHandlerService, i15.DMCQueryParamsService, i8.CommonHelper, i16.SpinnerService, i17.RootService, i5.DateFormatter, i18.UserManagementService], { inputSearchCriteria: [0, "inputSearchCriteria"], createNewSearchCriteriaFn: [1, "createNewSearchCriteriaFn"], searchCriteriaUpdatingDataKey: [2, "searchCriteriaUpdatingDataKey"], searchResultsDataKey: [3, "searchResultsDataKey"], searchCriteriaLoadingDataKey: [4, "searchCriteriaLoadingDataKey"], chipConfigKey: [5, "chipConfigKey"], clearLoadedSearchCriteriaFn: [6, "clearLoadedSearchCriteriaFn"], placeHolderText: [7, "placeHolderText"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.inputSearchCriteria; var currVal_1 = _co.createNewSearchCriteriaFn; var currVal_2 = _co.searchCriteriaUpdatingDataKey; var currVal_3 = _co.searchResultsDataKey; var currVal_4 = _co.searchCriteriaLoadingDataKey; var currVal_5 = _co.chipConfigKey; var currVal_6 = _co.clearLoadedSearchCriteriaFn; var currVal_7 = _co.placeHolderText; _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }, null); }
export function View_ReportsSearchCriteriaComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-reports-search-criteria", [], null, null, null, View_ReportsSearchCriteriaComponent_0, RenderType_ReportsSearchCriteriaComponent)), i0.ɵdid(1, 49152, null, 0, i19.ReportsSearchCriteriaComponent, [], null, null)], null, null); }
var ReportsSearchCriteriaComponentNgFactory = i0.ɵccf("tc-reports-search-criteria", i19.ReportsSearchCriteriaComponent, View_ReportsSearchCriteriaComponent_Host_0, {}, {}, []);
export { ReportsSearchCriteriaComponentNgFactory as ReportsSearchCriteriaComponentNgFactory };
