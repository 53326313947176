import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatExpansionModule } from '@angular/material';
import { NotesModule } from '../../../../widgets/shared/notes/notes.module';
import { AddNoteModule } from '../add-note/add-note.module';
import { RemarksComponent } from './remarks.component';
import {TranslateModule} from '@ngx-translate/core';


@NgModule({
    imports: [
        CommonModule,
        MatExpansionModule,
        AddNoteModule,
        NotesModule,
        TranslateModule.forChild()
    ],
    declarations: [RemarksComponent],
    exports: [RemarksComponent]
})
export class RemarksModule {}
