import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { TcErrorType } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-error';
import { LEVEL } from '@tc-core/model/it/codegen/ui/framework/dialog-model';
import { ModalData } from '@tc-core/model/it/codegen/ui/framework/modal-data';
import { ConfigLoader } from '@tc-core/util/framework';
import { SpinnerService } from '@tc-core/util/ui';
import { FocusViewService } from '@tc/focus-view/focus-view.service';
import { IGetRowsParams } from 'ag-grid-community';
import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SetupGridComp } from '../../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid-comp';
import { SetupGridDataSource } from '../../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid-data-source';
import { SetupGridComponent } from '../../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid.component';
import { ActionRendererAction } from '../../../../widgets/framework/ag-grid-custom-components/renderers/actions/actions.component';
import { TCO } from '../../../../constants';
import { JOB_UNLOCKED } from '../../../../models/constants/success-api-message';
import { JobSearchCriteria } from '../../../../models/criteria/job-search-criteria';
import { SortDirection } from '../../../../models/helper/sort-direction';
import { JobHistoryStatus } from '../../../../models/job/job-history-status';
import { JobLockStatus } from '../../../../models/job/job-lock-status';
import { JobService } from '../../../../services/backend-consumers/job/job-service';
import { DataHandlerService } from '../../../../services/backend-consumers/master-data-handler-service/data-handler.service';
import { DMCCommon } from '../../../../services/util/common/dmc-common';
import { DataKey, DataStoreService } from '../../../../services/util/framework/data-store.service';
import { ResponseUtil } from '../../../../services/util/response/response-util.service';
import { CommonJobHistoryGridComponent } from '../common-job-history-grid/common-job-history-grid.component';
import {JobParameterFocusViewComponent} from '../job-parameter-focus-view/job-parameter-focus-view.component';
import {UserManagementService} from '../../../../services/user-management/user-management.service';

@Component({
    selector: 'tc-common-job-grid',
    templateUrl: './common-job-grid.component.html'
})
export class CommonJobGridComponent extends SetupGridComp implements OnInit, OnDestroy, SetupGridDataSource
{
    @ViewChild('jobsView') setupGrid: SetupGridComponent;
    @ViewChild('statusCell') statusCell: TemplateRef<any>;
    @ViewChild('latestHistoryStatusCell') latestHistoryStatusCell: TemplateRef<any>;
    @ViewChild('menuCell') menuCell: TemplateRef<any>;

    colDefConfig = [];
    gridActions: ActionRendererAction[] = [];

    private searchCriteria: JobSearchCriteria = new JobSearchCriteria();

    private searchCriteriaObserver: Subscription = new Subscription();
    private focusViewDataChangeObserver: Subscription = new Subscription();
    private focusViewConfirmObserver: Subscription = new Subscription();
    private focusCloseSubscription: Subscription = new Subscription();

    isFocusViewDataChanged = false;

    constructor(
        private focusViewService: FocusViewService,
        private configLoader: ConfigLoader,
        private dataStoreService: DataStoreService,
        private dataHandlerService: DataHandlerService,
        private jobService: JobService,
        private common: DMCCommon,
        private spinnerService: SpinnerService,
        public userManagementService: UserManagementService
    )
    {
        super();
    }

    ngOnInit()
    {
        this.colDefConfig = this.configLoader.configurations.get(TCO.CONF.CONF_JOB_SETUP);
        this.addGridActions();
        this.subscribeSearchCriteria();
    }

    private addGridActions() {
    }

    // selection
    onJobParamClick = (params) => {
        this.openJobParameterModal(params.data);
    };


    openJobParameterModal(data: any) {
        setTimeout(() => {

            const dataObject = new ModalData(
                LEVEL.SUCCESS,
                'Job Parameter',
                null,
                null,
                'grid-focus-view',
                JobParameterFocusViewComponent,
                {data},
                {label: 'close'},
                '',
                '',
                '',
                ''
            );

            this.focusViewService.confirm(dataObject).subscribe();
        }, 0);
    }

    closeFocusView() {
        if (this.focusViewService.close()) {
            this.focusCloseSubscription = this.focusViewService.close().subscribe(
                (res) => {
                }
            );
        }
    }

    subscribeSearchCriteria()
    {
        this.searchCriteriaObserver = this.dataStoreService.get(DataKey.commonJobSearchCriteria)
                                          .subscribe(value => {
                                              this.searchCriteria = value;
                                              this.setupGrid.runForceSearch();
                                          });
    }

    public getRows(params: IGetRowsParams): Observable<any>
    {
        this.addSearchCriteria(params);

        return this.jobService.getCommonJobs(this.searchCriteria)
                   .pipe(
                       tap(data =>
                           this.dataStoreService.set(DataKey.commonJobSearchResults, data)
                       )
                   );
    }

    onUnlockClick = (params) => {
        const jobName: string = params.jobName;
        if (jobName) {
            this.jobService.unlockJob(jobName)
                .subscribe(
                    data => {
                        if (data) {
                            const message = ResponseUtil.getFirstData<string>(data);
                            if (message === JOB_UNLOCKED) {
                                //
                            }
                            this.common.showSnackBar(message, 3000, TcErrorType.TYPE.INFO);
                        }
                    },
                    (error) => {
                        this.common.showSnackBar(error, 3000, TcErrorType.TYPE.ERROR);
                    }, () => {
                        this.setupGrid.gridApi.refreshInfiniteCache();
                    }
                );
        }
    };

    disableUnlockButton = (params): boolean => {
        if (params) {
            return params.status !== JobLockStatus.LOCKED;
        }
        return true;
    };

    disableHistoryButton = (params): boolean => {
        if (params) {
            return !params.historyAvailable;
        }
        return true;
    };

    public ngOnDestroy(): void
    {
        if (this.searchCriteriaObserver) {
            this.searchCriteriaObserver.unsubscribe();
        }
        if (this.focusViewDataChangeObserver) {
            this.focusViewDataChangeObserver.unsubscribe();
        }
    }

    focusViewDataObserver()
    {
        this.focusViewDataChangeObserver = this.dataStoreService.get(DataKey.docDetailsFocusViewDataChanged)
                                               .subscribe(isDataChanged => {
                                                   if (isDataChanged) {
                                                       this.focusViewDataChangeObserver.unsubscribe();
                                                   }
                                                   this.isFocusViewDataChanged = isDataChanged;
                                               });
    }

    onJobHistoryClick(params: any)
    {
        this.focusViewDataObserver();
        const fabActions = [];

        const dataObject = new ModalData(
            LEVEL.SUCCESS,
            'Job History',
            null,
            null,
            'grid-focus-view',
            CommonJobHistoryGridComponent,
            {
                jobName: params.jobName
            },
            {label: 'close'},
            '', '', fabActions, null,
            'Job : ' + params.jobDisplayName
        );
        dataObject.disableClose = true;
        this.focusViewConfirmObserver = this.focusViewService.confirm(dataObject)
                                            .subscribe(
                                                res => {
                                                    if (this.isFocusViewDataChanged) {
                                                        // after view closed
                                                        this.setupGrid.runForceSearch();
                                                    }
                                                }
                                            );
    }

    addSearchCriteria(params: IGetRowsParams)
    {
        // search criteria populate
        const pageSize = params.endRow - params.startRow;
        if (!this.searchCriteria) {
            this.searchCriteria = new JobSearchCriteria();
        }
        this.searchCriteria.start = params.startRow;
        this.searchCriteria.size = pageSize;
        if (params.sortModel && params.sortModel[0]) {
            this.searchCriteria.sortBy = params.sortModel[0].colId;
            if (params.sortModel[0].sort === SetupGridComp.GRID_SORT_ASCENDING) {
                this.searchCriteria.sortDirection = SortDirection.ASC;
            } else {
                this.searchCriteria.sortDirection = SortDirection.DESC;
            }
        } else {
            this.searchCriteria.sortDirection = SortDirection.DESC;
        }
    }

    displayStatus(status: string): string
    {
        const splitsByUnderscore = status.split('_');
        if (splitsByUnderscore.length > 1) {
            return splitsByUnderscore.map(part => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase()).join(' ');
        }
        return status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();
    }

    /**
     * Grid cell render styles
     * Do not remove
     */

    statusLocked = (params) => {
        return params.data && params.data.status === JobLockStatus.LOCKED;
    };

    statusUnlocked = (params) => {
        return params.data && (params.data.status === JobLockStatus.UNLOCKED || params.data.status === JobLockStatus.FORCEFULLY_UNLOCKED);
    };

    // history

    historyStatusStarted = (params) => {
        return params.data &&
            (params.data.latestHistoryStatus === JobHistoryStatus.STARTED || params.data.latestHistoryStatus === JobHistoryStatus.RUNNING);
    };

    historyStatusFailed = (params) => {
        return params.data && params.data.latestHistoryStatus === JobHistoryStatus.FAILED;
    };

    historyStatusFinished = (params) => {
        return params.data && params.data.latestHistoryStatus === JobHistoryStatus.FINISHED;
    };

    historyStatusTerminated = (params) => {
        return params.data && params.data.latestHistoryStatus === JobHistoryStatus.TERMINATED;
    };

    //

    /**
     * Not applicable to jobs
     */
    public saveRow(row: any, event: any): Observable<any>
    {
        return undefined;
    }

    /**
     * Not applicable to jobs
     */
    public deleteRow(row: any): Observable<any>
    {
        return undefined;
    }

    /**
     * Not applicable to jobs
     */
    public isUnsavedRow(row: any): boolean
    {
        return false;
    }

    /**
     * Not applicable to jobs
     */
    public createNewRow(): any
    {
    }

    /**
     * Not applicable to jobs
     */
    public isInvalidRow(params): boolean
    {
        return false;
    }
}
