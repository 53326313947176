export class ServiceInformation {
    serviceInfoId: number;
    serviceCompanyName: string;
    lastServiceDate: string;
    nextServiceDate: string;
    email: string;
    companyContactNum: string;
    altContactNum: string;
    serviceNote: string;
}
