import { GenResourceCost } from '../supplier/gen-resource-cost';
import { TrsResourceCost } from '../supplier/trs-resource-cost';
import { PaxCost } from './pax-cost';

export class SupplierAllocationSummary {
    supplierId: number;
    supplierCode: string;
    supplierName: string;
    serviceType: string;
    profileType: string;
    vehicleModel: string;
    route: string;
    transferMode: string;
    costingType: any;
    calculatedCost: PaxCost; // cost for newly allocating passengers only
    genResourceCostSummaries: GenResourceCost[];
    trsResourceCostSummaries: TrsResourceCost[];

    // front end attributes
    nullItemForClearing: boolean;
    selected: boolean;

    isPrimaryAssignment: boolean;
    isSecondaryAssignment: boolean;
    onlineStatus: boolean;
}
