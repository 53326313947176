import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'tc-res-calendar-side-nav',
    templateUrl: './res-calendar-side-nav.component.html'
})
export class ResCalendarSideNavComponent implements OnInit {

    @Input() viewOnlyMode: boolean = false;
    @Input() navItems: any[] = [];
    @Input() isRadioButton: boolean = false;

    @Output() itemsSelected: EventEmitter<any[]> = new EventEmitter();

    constructor() { }

    ngOnInit() {
    }

    onSelect(navItem) {
        let selected = [];
        this.navItems.forEach(value => {
            if (value.checked) {
                selected.push(value.code);
            }
        });
        this.itemsSelected.emit(selected);
    }
}
