<div class="header-panel-section tc-px-3 tc-py-1">
    <div class="item-header tc-my-2">
        <div class="item-header__content">{{resultHeaderHeading.length < 1 ? '--' : resultHeaderHeading}}</div>
        <div class="tc-icon-block action-icon">
        </div>
    </div>
</div>
<ag-grid-angular style="height: calc(100vh - 182px)"
                 class="ag-theme-material tc-ag-grid tc-ag-grid--full-width tc-ag-grid--auto-height tc-ag-grid--height-full tc-border-left-0 tc-border-right-0"
                 [columnDefs]="columnDefs"
                 [defaultColDef]="defaultColDef"
                 [pagination]="true"
                 [paginationAutoPageSize]="true"
                 (cellValueChanged)="editCellValue($event)"
                 (gridSizeChanged)="onGridSizeChange($event)"
                 (gridReady)="onGridReady($event)">
</ag-grid-angular>

<ng-template #approvalStatusCell let-row>
    <div class="tc-ag-grid-chip"
         *ngIf="row && row.approvalStatus"
         [ngClass]="{'tc-ag-grid-chip--warning': ( row?.approvalStatus === PENDING ),
                                            'tc-ag-grid-chip--success': ( row?.approvalStatus === APPROVED ),
                                            'tc-ag-grid-chip--danger': ( row?.approvalStatus === REJECTED )
                                            }">
        <span>{{row?.approvalStatus}}</span>
    </div>
</ng-template>

<!--resource column view-->
<ng-template #resourceCell let-row>
    <div *ngIf="row && row.resourceSummary">
        <span> {{row?.resourceSummary?.name ? row?.resourceSummary?.name : EMPTY_STRING}}</span>
    </div>
</ng-template>

<!--supplier code column view-->
<ng-template #supplierCodeCell let-row>
    <span> {{row?.resourceSummary && row?.resourceSummary?.supplierCode ? row?.resourceSummary?.supplierCode : EMPTY_STRING}}</span>
</ng-template>

<!--supplier name column view-->
<ng-template #supplierNameCell let-row>
    <span> {{row?.resourceSummary && row?.resourceSummary?.supplierName ? row?.resourceSummary?.supplierName : EMPTY_STRING}}</span>
</ng-template>

<!--action column view-->
<ng-template #actionCell let-row>
    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Go to option">
        <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="onClickRoles(row)">
            <mat-icon>admin_panel_settings</mat-icon>
            <span>{{'Roles' | translate}}</span>
        </button>
        <button mat-menu-item [disabled]="isApproveResourceRestricted(row)" (click)="onApprove(row)">
            <mat-icon>thumb_up_alt</mat-icon>
            <span>{{'Accept User' | translate}}</span>
        </button>
        <button mat-menu-item [disabled]="isRejectResourceRestricted(row)" (click)="onReject(row)">
            <mat-icon>thumb_down_alt</mat-icon>
            <span>{{'Reject User' | translate}}</span>
        </button>
    </mat-menu>
</ng-template>

