import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { CurrencyFormat } from '@tc-core/model/it/codegen/ui/framework/currency-format';
import { SummaryCardData } from '@tc-core/model/it/codegen/ui/framework/summary-card-data';
import { TC } from '@tc-core/util';
import { ConfigLoader } from '@tc-core/util/framework/config-loader.service';
import { TCO } from '../../../constants';
import { Resource } from '../../../models/resource/resource';
import { Season } from '../../../models/supplier/season';
import {UserManagementService} from '../../../services/user-management/user-management.service';

@Component({
  selector: 'tc-vehicle-detail-view',
  templateUrl: './vehicle-detail-view.component.html'
})
export class VehicleDetailViewComponent implements OnInit {

  @Input() resource: Resource;
  @Input() selectedVehicleCost: Season[] = [];
  @Input() summaryCardData: SummaryCardData;
  @Input() controlMap: Map<any, string>;
  @Input() footerDataMap: Map<number, object>;

  @Output() headerClick: EventEmitter<any> = new EventEmitter();
  @Output() editClick: EventEmitter<any> = new EventEmitter();
  @Output() expandEventEmitter: EventEmitter<boolean> = new EventEmitter();

  resourceType: any = null;
  currencyFormat: CurrencyFormat;

  constructor(
      private configLoader: ConfigLoader,
      public userManagementService: UserManagementService
  ) { }

  ngOnInit() {
    this.currencyFormat = this.getCurrencyFormat();
    if (this.summaryCardData) {
      this.expandEventEmitter.emit(true);
    }
  }
  private getCurrencyFormat(): CurrencyFormat {
    let currencyFormat;
    let currencySymbolPattern = this.configLoader.configurations.get(TC.CONF.CONF_SYSTEM).defaults.default_currency_symbol_pattern;
    currencyFormat = new CurrencyFormat(TCO.AppData.DEF_CURRENCY_TYPE, currencySymbolPattern);
    return currencyFormat;
  }

  onHeaderClick(event) {
    this.headerClick.emit(event);
  }

  onEditClick(event) {
    this.editClick.emit(event);
  }

}
