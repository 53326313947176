<tc-setup-grid
        #docSetupGrid
        [readOnly]="true"
        [noSelection]="true"
        [colDefConfig]="colDefConfig"
        [setupGridComp]="this"
        [actions]="gridActions"
        [headerActions]="headerActions"
        [dataSource]="this"
        (rowDoubleClicked)="onRowDoubleClicked($event)">
</tc-setup-grid>
