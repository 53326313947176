import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TooltipPosition } from '@angular/material';
import { SyncStatus } from '@tc-core/model/it/codegen/tbx/api/product/external-Info';
import { BookingItemView } from '@tc-core/model/it/codegen/ui/booking/booking-item-view';
import { NavItem } from '@tc-core/model/it/codegen/ui/framework/nav-item';
import { KeyValue } from '@tc-core/model/it/codegen/ui/key-value';
import { TC } from '@tc-core/util/constants';
import STATUS = SyncStatus.STATUS;

@Component({
    selector: 'tc-expanded-content-header-card',
    templateUrl: './expanded-content-header-card.component.html'
})
export class ExpandedContentHeaderCardComponent implements OnInit {

    @Input() headerText: string;
    @Input() bookingItemView: BookingItemView;
    @Input() secondaryHeaderText: string;
    @Input() icon: KeyValue;
    @Input() showProgress: boolean;
    @Input() dataRemoveKey: any;
    @Input() dataEditKey: any;
    @Input() dataViewKey: string;
    @Input() enableItemDelete: boolean;
    @Input() enableItemEdit: boolean;
    @Input() enableUndo = false;
    @Input() isOptional = false;
    @Input() showTravellerIcon = false;
    @Input() removeTraveller = false;
    @Input() trvToolTip = '';
    @Input() editToolTip = '';
    @Input() deleteToolTip = '';
    @Input() realizedTransferToolTip = '';
    @Input() item: any;
    @Input() showMenu: boolean;
    @Input() menuItems: Array<string> = ['Cancellation Charge Rules', 'Amendment Charge Rules'];
    @Input() optionalSelected = false;
    @Input() showPKGSync: boolean = false;
    @Input() enableShareWith: boolean = false;
    @Input() productItemKey: string;
    @Input() shareChainId: number;
    @Input() kebabMenu: NavItem[] = [];
    @Input() priceInfo: any;
    @Input() showRealizedTransferIcon = false;

    public toolTipPosition: TooltipPosition = 'below';

    @Output() headerClick: EventEmitter<any> = new EventEmitter();
    @Output() syncClick: EventEmitter<any> = new EventEmitter();
    @Output() itemDeleteClick: EventEmitter<any> = new EventEmitter();
    @Output() itemEditClick: EventEmitter<any> = new EventEmitter();
    @Output() undoProductClick: EventEmitter<any> = new EventEmitter();
    @Output() selectedItem: any = new EventEmitter();
    @Output() nightlyPriceBreakDownClick: EventEmitter<any> = new EventEmitter();
    @Output() shareWithClick: EventEmitter<any> = new EventEmitter();
    @Output() cnxChargeClick: EventEmitter<any> = new EventEmitter();
    @Output() amdChargeClick: EventEmitter<any> = new EventEmitter();
    @Output() viewChainClick: EventEmitter<any> = new EventEmitter();
    @Output() trvIconClick: EventEmitter<any> = new EventEmitter();
    @Output() trvRemoveIconClick: EventEmitter<any> = new EventEmitter();
    @Output() realizedTransferClick: EventEmitter<any> = new EventEmitter();

    showKebab: boolean;

    constructor() {
    }

    ngOnInit() {
        this.showKebab = this.kebabMenu !== null;
        if (this.showKebab) {
            this.showKebab = this.kebabMenu !== undefined; /// && this.kebabMenuItems.length > 0;
        }
    }

    onHeaderClick(event) {
        this.headerClick.emit(event);
    }

    removeItemClick(event: any, key: string) {
        event.stopPropagation();
        if (key) {
            this.itemDeleteClick.emit({'data': key});
        }
    }

    editItemClick(event: any, key: string) {
        event.stopPropagation();
        if (key) {
            this.itemEditClick.emit({'data': key});
        }
    }

    undoItemClick(event: any) {
        event.stopPropagation();
        this.undoProductClick.emit(event);
    }

    onTravellerClick(event: any) {
        event.stopPropagation();
        this.trvIconClick.emit(event);
    }

    onTravellerRemoveClick() {
        event.stopPropagation();
        this.trvRemoveIconClick.emit(event);
    }

    viewNightlyPriceBreakDown(event: any, key: string) {
        event.stopPropagation();
        if (key) {
            this.nightlyPriceBreakDownClick.emit({'data': key});
        }
    }

    startShareWith(event: any, key: string) {
        event.stopPropagation();
        if (key) {
            this.shareWithClick.emit({'data': key});
        }
    }

    viewShareChain(event) {
        event.stopPropagation();
        this.viewChainClick.emit(event);
        console.log('chain from sum', event);
    }

    public viewCancellationCharges(event) {
        console.log('CancellationCharges');
        this.cnxChargeClick.emit(event);
    }

    public viewAmendmentCharges(event) {
        console.log('AmendmentCharges');
        this.amdChargeClick.emit(event);
    }

    optionalSelection(event) {
        if (!this.optionalSelected) {
            this.optionalSelected = true;
        } else {
            this.optionalSelected = false;
        }

        let returnObj = {
            'selectedItem': '',
            'isSelect': false,
            'itemPrice': 0,
            'tax': 0,
            'commission': 0,
            'discount': 0
        };
        if (this.optionalSelected) {
            returnObj.selectedItem = this.item.data.value;
            returnObj.isSelect = true;
            returnObj.itemPrice = this.item.row1col5.value;
            returnObj.tax = this.item.row1col6.value;
            returnObj.commission = this.item.row1col7.value;
            returnObj.discount = this.item.row1col8.value;
            this.selectedItem.emit(returnObj);
        } else {
            returnObj.selectedItem = this.item.data.value;
            returnObj.isSelect = false;
            returnObj.itemPrice = this.item.row1col5.value;
            returnObj.tax = this.item.row1col6.value;
            returnObj.commission = this.item.row1col7.value;
            returnObj.discount = this.item.row1col8.value;
            this.selectedItem.emit(returnObj);
        }

        if (event) {
            event.stopPropagation();
        }
    }

    clickOnKebarb(event) {
        event.stopPropagation();
    }

    displayKebarb(key) {
        if (key) {
            if (key.indexOf(TC.Product.ACCOMMODATION) >= 0) {
                return true;
            }
        }
        return false;
    }

    public onSyncClick(event) {
        if (this.bookingItemView && this.bookingItemView.details.summary.row7col1) {
            if (!(this.bookingItemView.details.summary.row7col1.value === SyncStatus.TYPE.IN_SYNC)) {
                this.syncClick.emit(event);
            }
        }
        // stopPropagation added not to close expanded panel when clicked on the header.
        event.stopPropagation();
    }

    public onRealizedTransferClick(event) {
        if (event) {
            this.realizedTransferClick.emit(event);
        }
    }

}
