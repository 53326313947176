<div class="focus-view__content-wrapper">
    <div class="container-fluid">
        <div class="assign-panel-search">
            <tc-generic-search-criteria
                    [searchCriteria]="" ]="resourceSearchCriteria"
                    [searchType]="_searchType"
                    [genericTypePathname]="genTourItem?.categoryCode">
            </tc-generic-search-criteria>
        </div>
    </div>

    <div class="container-fluid">
        <tc-result-header
                [transferModeList]="[]"
                [groupingOptionList]="[]"
                [heading]="heading"
                [sorters]="sorters_"
                (selectSorter)="onSort($event)">
        </tc-result-header>
    </div>

    <div class="container-fluid">
        <tc-adv-resource-assign-search-result
                [sortingHandler]="sorterService.method"
                [sorter]="sorterService.sorter"
                [sorters]="sorters_"
                [resultsList]="contentCardDataList"
                [selectedDriver]="selectedSummaryCardData"
                [bookingData]="genTourItem"
                [bookingDataArray]="bookingDataArray"
                [isBulkAssignment]="isBulkAssignment"
                [assignedDriver]="selectedResource"
                [pager]="pager"
                (driverSelect)="onDriverSelect($event)"
                (resourceCalendarClick)="onResourceCalendarClick($event)"
                (targetClick)="onTargetClick($event)"
                (eventClick)="onEventClick($event)">
        </tc-adv-resource-assign-search-result>
    </div>
    <tc-paginator
            *ngIf="contentCardDataList.length > defaultPageResultCount"
            [length]="contentCardDataList.length"
            [index]="pager.currPage"
            [size]="pager.itemsPerPage"
            [hasMore]="hasMore"
            (page)="onPage($event)"
            (more)="onMore()">
    </tc-paginator>
</div>
