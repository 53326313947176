import { MainType } from '../reservation-v2/main-type';
import { AbstractSearchCriteria } from './abstract-search-criteria';
import {KeyControlAndAbstractSearchCriteria} from './key-control-and-abstract-search-criteria';

export class DocumentSearchCriteria extends KeyControlAndAbstractSearchCriteria
{
    documentType: string[] = [];
    manifestsOnly = false;
    reportsOnly = false;
    startDate = '';
    endDate = '';
    name = '';
    generatedBy = 'ANY';
    mainType: MainType = MainType.TRS;
    userId: number = null;
    userName = '';
    bookingId: number = null;
    bookingTableId: number = null;
    bookingSourceItemNo: number = null;
    dispatchStatus = 'SUCCESS';
    supplierCode: any; //
    supplierName: any; //
    documentFormat = 'ANY';
    backgroundTaskId: number = null;

    constructor(manifestOnly: boolean, reportsOnly: boolean)
    {
        super();
        this.manifestsOnly = manifestOnly;
        this.reportsOnly = reportsOnly;
    }
}
