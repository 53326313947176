import { Component } from '@angular/core';
import { TCO } from '../../../../constants';
import { LedgerEntrySearchCriteria } from '../../../../models/criteria/ledger-entry-search-criteria';
import { DataKey } from '../../../../services/util/framework/data-store.service';

@Component({
    selector: 'tc-ledger-entry-search-criteria',
    templateUrl: './ledger-entry-search-criteria.component.html'
})
export class LedgerEntrySearchCriteriaComponent {

    inputSearchCriteria = new LedgerEntrySearchCriteria();
    searchCriteriaUpdatingDataKey = DataKey.ledgerEntrySearchCriteria;
    searchResultsDataKey = DataKey.ledgerEntrySearchResultsForCriteria;
    searchCriteriaLoadingDataKey = TCO.AppData.LEDGER_ENTRY_SEARCH_CRITERIA;
    chipConfigKey = TCO.CONF.CONF_LEDGER_ENTRY_SEARCH_CRITERIA_CHIP;
    placeHolderText = 'Search Ledger Entries';

    createNewSearchCriteriaFn = () => {
        return new LedgerEntrySearchCriteria();
    };

    clearLoadedSearchCriteriaFn = (searchCriteria: LedgerEntrySearchCriteria) => {
    };

}
