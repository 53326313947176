<div class="trade-client-detail-view" *ngIf="selectedResources && selectedResources.length > 0">
    <div
            class="detail-group">
        <div class=" detail-group__heading tc-results-header">
            <div class="tc-item-wrapper">
                <div class="tc-item-header tc-flex-box">
                    <div class="tc-flex-item tc-item-header__primary">{{'Selected Resources' | translate}}</div>
                </div>
            </div>
        </div>
        <div
                class="tc-border-list"
                [id]="'RESULT_CONTAINER'" @list>
            <div
                    class="tc-border-list__repeat-wrapper" @items
                    *ngFor="let cardData of selectedResources let i = index">
                <tc-card-strip
                        [id]="'RESULT_INDEX_'+i" class="tc-card-strip__temp-border"
                        [cardData]="cardData"
                        [type]="cardType"
                        [checked]="isSelected(cardData)">
                </tc-card-strip>
            </div>
        </div>
    </div>
</div>
