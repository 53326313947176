import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResourceMessagePanelComponent } from './resource-message-panel.component';
import {MatListModule} from '@angular/material/list';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {TranslateModule} from '@ngx-translate/core';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {ReactiveFormsModule} from "@angular/forms";
@NgModule({
  declarations: [ResourceMessagePanelComponent],
    imports: [
        CommonModule,
        MatListModule,
        MatButtonModule,
        MatIconModule,
        MatTooltipModule,
        TranslateModule,
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule
    ],
  exports: [ResourceMessagePanelComponent]
})
export class ResourceMessagePanelModule { }
