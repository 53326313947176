import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UtilService } from '../common/util.service';

@Injectable()
export class DataStoreService {

    private data: Map<string, any> = new Map();
    private asyncData: Map<DataKey, BehaviorSubject<any>> = new Map();

    constructor(private utilService: UtilService) {
        for (const key in DataKey) {
            if (parseInt(key, 10) >= 0) {
                // create a new subject and store it there
                if (!this.asyncData.has(DataKey[DataKey[key]])) {
                    this.asyncData.set(DataKey[DataKey[key]], new BehaviorSubject(null));
                }
            }
        }
    }

    // public getData(key: string) {
    //   return this.data.get(key);
    // }
    //
    // public setData(key: string, obj: any) {
    //   this.data.set(key, obj);
    // }
    //
    // public getAsyncData(key: DataKey) {
    //   return this.asyncData.get(key);
    // }
    //
    // public setAsyncData(key: DataKey, obj: string) {
    //   this.asyncData.get(key).next(obj);
    // }

    public get(key: any, isAsync: boolean = true) {
        if (isAsync) {
            return this.asyncData.get(key);
        } else {
            return this.data.get(key);
        }
    }

    public set(key: any, data: any, isAsync: boolean = true) {
        if (isAsync) {
            if (!this.asyncData.has(key)) {
                this.asyncData.set(key, null);
            }
            this.asyncData.get(key).next(data);
        } else {
            this.data.set(key, data);
        }
    }

    public updateArray(key: any, data: any, filterKey: any, isAsync: boolean = true) {
        const dataArray = this.get(key).value;
        if (Array.isArray(dataArray)) {
            const updatedArrayData = this.utilService.addOrUpdateToArray(dataArray, filterKey, data);
            this.set(key, updatedArrayData, isAsync);
        } else if (!dataArray) {
            this.set(key, [data], isAsync);
        }
    }

    public has(key: any, isAsync: boolean = true) {
        if (isAsync) {
            return this.asyncData.has(key);
        } else {
            return this.data.has(key);
        }
    }
}

export enum DataKey {

    fileUploadPercentage,
    serviceConfiguration,
    catchErrors,

    airportArr,
    locationsOfDropOffType,
    locationsOfPickupType,
    locationTypeOfLocation,
    locationsArr,
    locationTypesArr,
    guide,
    notes,
    taxSchemes,
    documentTypes,
    languages,
    allLanguages,
    vehicleFacilities,
    trsResourceCostData,
    locations,
    additionalContacts,
    locationGroup,
    modalClose,
    popupClose,
    supplierInfor,
    supplierPaymentInfor,
    personalContactData,
    selectedTaxSchemes,
    additionalContactList,
    documentData,
    seasonsData,
    contactDetailData,
    resourceSpecificData,
    vehicleFacilityData,
    insuranceInformationData,
    serviceInformationData,
    vehicleInfor,
    supplierAttributes,
    supplierItems,
    supplierSearchResults,
    supplierAllFetchedResults,
    resourceAssignSupplierSearchResults,
    chips,
    error,
    chipListDisplay,
    document,
    resourceCostData,

    resourceTypes,
    locale,
    countryList,
    cityList,
    citiesOfCountry,
    fuelTypes,
    transferModes,
    vehicleModels,
    currencyList,
    paymentMethods,
    contactCategories,
    vehicleAmenities,
    selectedSupplierForEdit,
    selectedSupplierForEditAsNew,
    filteredStates,
    countryOfState,
    filteredCities,
    selectedCountry,
    countryOfCity,
    stateOfCity,
    allCountries,
    transportServiceSearchResults,
    genericResourceSearchResult,
    genericResourceSearchResultFromService,
    routes,
    reservationSearchResultsFromService,
    genReservationSearchResultsFromService,
    reservationSearchResultsFromServiceOnMore,
    genReservationSearchResultsFromServiceOnMore,
    reservationSearchResults,
    genReservationSearchResults,
    reservationSearchCriteria,
    genReservationSearchCriteria,
    genericSearchCriteria,
    reservationSelectedGroupCriteria,
    reservationSelectedGroupData,
    reservationSelectedGroupNoteData,
    selectedGroupNoteTemporaryData,
    vehicleSearchResultsService,
    vehicleSearchResults,
    vehicleSearchPagingTempResult,
    vehicleSearchCriteria,
    resourceAssignVehicleSearchResults,
    supplierSearchCriteria,
    resourceAssignedStatus,
    unauthorizedError,
    warning,
    user,
    suppliersOptions,
    retrievedSupplierResults,
    supplier,
    suppliersForAssignment,
    searchSuppliersForGenAssignment,
    saveSupplier,
    updateSupplier,
    saveVehicle,
    updateBooking,
    updateResource,
    saveSupplierProf,
    updateSupplierProf,
    saveSeasons,
    searchSupplierByCode,
    AllocationSupplierSummary,
    selectedGenericResourceForEdit,
    selectedGenericResourceForEditAsNew,
    selectedVehicleForEdit,
    selectedVehicleForEditAsNew,
    focusViewDataChanged,
    docDetailsFocusViewDataChanged,
    supplierCostFocusViewClose,
    trsResourceCosts,
    genericResourceAllocationSearchResult,
    userDetail,
    userProfile,
    supplierTrsCostSetupValid,
    userRestrictions,
    supplierAssignAllFetchedResults,
    vehicleAssignAllFetchedResults,
    driverAssignAllFetchedResults,
    popupDataChanged,
    userSavedSearchCriteriaList,
    userSavedSearchCriteriaListV2,
    searchCriteriaName,
    systemDefinedCriteria,
    systemDefinedCriteriaV2,
    searchFromSavedCriteria,
    assignedSupplier,
    assignedSupplierFromDriver,
    assignedSupplierFromVehicle,
    assignedResource,
    assignedGenResourceSupp,
    genResourceCosts,
    roomResourceCosts,
    supplierGenCostSetupValid,
    supplierRoomCostSetupValid,
    resourceLeaves,
    deletedLeaves,
    availabilityCalendarFocusViewClose,
    leaveSetupFocusViewClose,
    resourceLeaveSearchResult,
    leaveSetupSearchCriteria,
    leaveSetupSearchResults,
    saveLeaveData,
    kcCompanies,
    kcCompany,
    kcBrands,
    kcDivisions,
    kcDistributionChannels,

    //all brands
    kcBrandsList,
    //all dist. channels
    kcDistributionChannelList,

    // Assignments
    resourceAssignments,
    suppliersForGenAssignment,
    resourcesForGenAssignment,
    driverSuppliersForAssignment,

    // save items
    genericItemsSave,

    // chip observable list
    supplierList,
    accountingEvents,
    accountingUnits,
    ledgerAccounts,

    // resource assign trs
    resourceAssignDriversSearchResults,
    resourceAssignResourcesSearchResults,

    // grid editor data
    locationTypes,
    locationGroups,

    profileTypes,

    documentTypesReports,
    documentTypesManifests,

    // setup search criteria
    accountingRuleSearchCriteria,
    locationsSearchCriteria,
    mappedResourceTypeSearchCriteria,
    locationGroupsSearchCriteria,
    routesSearchCriteria,
    ledgerAccountsSearchCriteria,
    currencySearchCriteria,
    documentQueueSearchCriteria,
    productDetailSearchCriteria,
    costingTypeSearchCriteria,
    companySetupSearchCriteria,
    divisionSetupSearchCriteria,
    brandSetupSearchCriteria,
    distributionChannelSetupSearchCriteria,
    resourceTypeSearchCriteria,
    profileTypeSearchCriteria,
    roomTypeSearchCriteria,
    roomFacilitySearchCriteria,
    roomOccupancySearchCriteria,
    boardBasisSetupSearchCriteria,

    // setup search results
    accountingRuleSearchResultsForCriteria,
    locationsSearchResultsForCriteria,
    mappedReourceTypeSearchResultsForCriteria,
    locationGroupsSearchResultsForCriteria,
    routesSearchResultsForCriteria,
    ledgerAccountsSearchResultsForCriteria,
    currencySearchResultsForCriteria,
    documentQueueSearchResultsForCriteria,
    productDetailsSearchResultsForCriteria,
    costingTypeSearchResultsForCriteria,
    companySearchResultsForCriteria,
    divisionSetupSearchResultsForCriteria,
    brandSetupSearchResultsForCriteria,
    distributionChannelSetupSearchResultsForCriteria,
    boardBasisSetupSearchResultsForCriteria,
    resourceTypeSearchResultsForCriteria,
    profileTypeSearchResultsForCriteria,
    roomTypeSearchResultsForCriteria,
    roomFacilitySearchResultsForCriteria,
    roomOccupancySearchResultsForCriteria,

    // document search criteria
    manifestsSearchCriteria,

    // notification state
    notificationStateChange = 197,

    reportsSearchCriteria,
    documentSearchCriteria,

    ledgerEntrySearchCriteria,

    // document search criteria
    manifestsSearchResults,
    reportsSearchResults,
    documentSearchResults,
    manifestsSearchResultsForCriteria,
    reportsSearchResultsForCriteria,
    documentSearchResultsForCriteria,

    ledgerEntrySearchResultsForCriteria,

    dispatchEntrySearchResultsForCriteria,
    documentQueueSummaryResultsForCriteria,

    opeAcceptanceSearchResultsForCriteria,
    resAcceptanceSearchResultsForCriteria,
    supAcceptanceSearchResultsForCriteria,
    opeAcceptanceSearchCriteria,
    resAcceptanceSearchCriteria,
    supAcceptanceSearchCriteria,
    opeAcceptanceSearchResultsFromService,
    resAcceptanceSearchResultsFromService,
    supAcceptanceSearchResultsFromService,
    opeAcceptanceSearchResultsFromServiceOnMore,
    resAcceptanceSearchResultsFromServiceOnMore,
    supAcceptanceSearchResultsFromServiceOnMore,
    pendingResourceAcceptanceData,
    pendingSupplierAcceptanceData,
    serviceConfigurationSearchResultsForCriteria,

    // generic queue serviceItem Selection
    selectedServiceItemIdList,

    // generic queue notes
    editedNotes,

    // location geo codes
    geoCodes,

    // locations selection panel
    locationSelectionFocusViewClose,
    saveLocationGroups,

    // notifications
    newNotifications,
    notificationSearchResultsForCriteria,
    notificationSearchCriteria,
    notificationSearchResultsFromService,
    notificationSearchResultsFromServiceOnMore,

    // messages
    newMessages,
    messageSearchResultsFromService,
    groupThreadData,

    // bookings
    bookingSummarySearchCriteria,
    bookingSummarySearchResults,
    bookingSummarySearchResultsFromService,
    selectedBookingForEdit,
    selectedBookingForEditAsNew,

    // operation queue
    // product queue search criteria
    productQueueSearchCriteria,
    productQueueSearchResultsFromService,

    // reservationV2 allocation
    resourceAllocationFocusViewClose,
    resourceAllocationSearchCriteria,
    resourceAllocationSearchResults,
    supplierAllocationSearchCriteria,
    accomSupplierAllocationSearchCriteria,
    supplierAllocationSearchResults,

    selectedSupplierForAllocation,
    selectedResourceForAllocation,
    ResourceAllocationTimeRange,

    // cost setup
    costingTypes,
    fileToUpload,
    bookingItemFileToUpload,

    rooms,
    selectedRoom,
    roomTypes,
    roomData,

    assignmentTotalCost,

    // allocation-feature
    allocationFeatureSearchCriteria,
    allocationFeatureSearchResultsFromService,
    saveAllocationFeature,
    updateAllocationFeature,
    userBusinessType,

    queueBookingSearchResults,
    queueServiceSearchResults,

    commonJobSearchResults,
    commonJobSearchCriteria,
    commonJobList,
    commonJobHistorySearchResults,
    jobParameterSearchResults,
    commonJobHistorySearchCriteria
}
