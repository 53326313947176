import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { TC } from '@tc-core/util/constants';
import { ConfigLoader } from '@tc-core/util/framework';
import { TCO } from '../../../constants';
import { ContactDetails } from '../../../models/profile/contact-details';

@Component({
    selector: 'tc-additional-contacts-card',
    templateUrl: './additional-contacts-card.component.html'
})
export class AdditionalContactsCardComponent implements OnInit, OnChanges
{

    EMPTY_CHAR = TC.FORMAT.EMPTY_CHAR;

    @Input() contactDetails: ContactDetails[];
    @Input() showTitle: boolean = true;

    additionalContactsCount: number = 0;

    placeholders: { id: string, title: string }[];

    constructor(
        private configLoader: ConfigLoader
    )
    { }

    ngOnInit()
    {
        const placeHolderConfig = this.configLoader.configurations.get(TCO.CONF.CONF_SUPPLIER_DETAIL_VIEW_CONFIG).placeHolderConfig;
        if (placeHolderConfig) {
            this.placeholders = placeHolderConfig.additionalContactInfoCard;
        }
    }

    public ngOnChanges(changes: SimpleChanges): void
    {
        for (const propName in changes) {
            if (propName === 'contactDetails') {
                if (this.contactDetails) {
                    this.additionalContactsCount = 0;
                    this.contactDetails.forEach(value => {
                        if (!value.status) {
                            this.additionalContactsCount++;
                        }
                    });
                }
            }
        }
    }

    getCardPlaceHolders(id: string): { id: string, title: string }
    {
        // return SupplierConfigLoader.getCardPlaceHolders(id, this.placeholders, this.getDefaultPlaceHolderFn);
        let placeHolder = this.placeholders.find(ph => ph.id === id);
        if (!placeHolder) {
            placeHolder = this.getDefaultPlaceHolderFn(id);
        }
        return placeHolder;
    }

    getDefaultPlaceHolderFn(id: string): { id: string, title: string }
    {
        if (id === 'STREET_ADDRESS') {
            return {id: 'STREET_ADDRESS', title: 'Address'};
        } else if (id === 'CITY') {
            return {id: 'CITY', title: 'City'};
        }
        return {id: 'default', title: ''};
    }
}
