<div class="tc-strip-timeline" #timelinePart (resized)="onResize($event)">
    <tc-timeline-unit-wrapper
            [summaryContent]="summaryContent"

            [events]="eventsData"

            [unitSize]="unitSize"
            [unitWidth]="unitWidth"
            [targetIndex]="targetUnitIndex"
            [targetUnitCount]="targetUnitCount"
            [targetMargin]="targetMargin"
            [isTargetBlock]="isTargetBlock"

            [leftBufferUnits]="leftBufferUnits"
            [rightBufferUnits]="rightBufferUnits"
            [showingIndexes]="showingIndexes"

            (targetClick)="onTargetClick()"
            (eventClick)="onEventClick($event)"
            class="tc-strip-timeline__ruler">
    </tc-timeline-unit-wrapper>
</div>
