import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {User} from '@tc-core/model/it/codegen/tbx/api/authority/v2/users/user';
import {TcErrorType} from '@tc-core/model/it/codegen/tbx/ext/errors/tc-error';
import {NavItem} from '@tc-core/model/it/codegen/ui/framework/nav-item';
import {UserJourneyManager} from '@tc-core/util/framework';
import * as moment from 'moment';
import {Subscription} from 'rxjs';
import {TCO} from '../../../constants';
import {NotificationToastEvent} from '../notification-toast/notification-toast-model';
import {NotificationToastService} from '../notification-toast/notification-toast.service';
import {BackgroundTask} from '../../../models/background/background-task';
import {BackgroundTaskEvent} from '../../../models/background/background-task-event';
import {TaskStatus} from '../../../models/background/task-status';
import {DocumentSearchCriteria} from '../../../models/criteria/document-search-criteria';
import {Document} from '../../../models/document/document';
import {SortDirection} from '../../../models/helper/sort-direction';
import {MainType} from '../../../models/reservation-v2/main-type';
import {DocumentService} from '../../../services/backend-consumers/documents/document.service';
import {NotificationService} from '../../../services/backend-consumers/notifications/notification.service';
import {BackgroundTaskService} from '../../../services/util/change-detector/background-task.service';
import {DMCCommon} from '../../../services/util/common/dmc-common';
import {DataStoreService} from '../../../services/util/framework/data-store.service';
import {ResponseUtil} from '../../../services/util/response/response-util.service';
import {DMCLocalStorageService} from '../../../services/util/system/dmc-local-storage.service';
import {DocumentQueueService} from "../../../services/backend-consumers/documents/document-queue.service";
import {BackgroundTaskType} from "../../../models/background/background-task-type";
import {DocumentQueueSearchCriteria} from "../../../models/criteria/document-queue-search-criteria";
import {DocumentQueueGroup} from "../../../models/criteria/DocumentQueueGroup";

@Component({
    selector: 'tc-header',
    templateUrl: 'header.component.html'
})

export class HeaderComponent implements OnInit, OnDestroy {
    public static SUB_HEADER_SPLITTER = '|@SUB|';

    @Input() logoLocation: string;
    @Input() isSidebarOpen: boolean;
    @Input() inputThemeCode: string;
    @Input() headings: string[];
    @Input() activeLanguage: string;
    @Input() user: User;
    @Input() languages: string[] = [];
    @Input() actions: Array<NavItem> = [];
    @Input() isUserLoaded: boolean;

    @Input() disableLogOut: boolean;
    @Input() disableUserObjects: boolean;
    @Output() themeSelection: EventEmitter<any> = new EventEmitter();
    @Output() toggleSidebar: EventEmitter<boolean> = new EventEmitter();
    @Output() changeLanguage: EventEmitter<string> = new EventEmitter();
    @Output() actionClick: EventEmitter<any> = new EventEmitter();
    @Output() logout: EventEmitter<void> = new EventEmitter<void>();
    @Output() viewUserLoadedObjects: EventEmitter<void> = new EventEmitter<void>();
    @Output() openUserProfile: EventEmitter<void> = new EventEmitter<void>();

    public userImageUrl = '';
    public FEMALE_DEFAULT_IMAGE = 'assets/img/user-profile/female_user.svg';
    public MALE_DEFAULT_IMAGE = 'assets/img/user-profile/male_user.svg';
    public MALE_OR_FEMALE_DEFAULT_IMAGE = 'assets/img/user-profile/gender_unknown_user.svg';

    public edited = false;
    public HEADER_DIVIDER = ' > ';

    newNotification: boolean;
    newNotificationData = [];

    newMessageList = [];
    showNotifications = false;

    showBackgroundTaskMenu = false;
    showBackgroundTaskRunning = false;
    showBackgroundTaskCompletion = false;
    showBackgroundTaskFailed = false;
    isDownloadable = true;
    backgroundTaskData = null;
    backgroundTask: BackgroundTask = null;
    hasUnreadDocs = false;


    notificationSubscription = new Subscription();
    notificationReadSubscription = new Subscription();
    manualBackgroundTaskSubscription = new Subscription();
    docQueueBackgroundTaskSubscription = new Subscription();

    constructor(
        private dataStore: DataStoreService,
        private userJourneyManager: UserJourneyManager,
        private notificationToastService: NotificationToastService,
        private notificationService: NotificationService,
        private backgroundTaskService: BackgroundTaskService,
        private documentService: DocumentService,
        private localStorage: DMCLocalStorageService,
        private common: DMCCommon,
        private documentQueueService: DocumentQueueService
    ) {

    }

    ngOnInit() {
        // notification listener
        this.setupIMageUrl();
        this.newNotification = false;
        this.getNewNotifications();
        this.updateUnReadMessageList();
        this.getUpdateBackgroundTasks();
    }

    setupIMageUrl() {
        if (this.user && this.user.userSummary && this.user.userSummary.additionalInfomation &&
            this.user.userSummary.additionalInfomation.imageUrl) {
            this.userImageUrl = this.user.userSummary.additionalInfomation.imageUrl;
        } else {
            this.setDefaultImage();
        }
    }

    setDefaultImage() {
        if (this.user && this.user.userSummary && this.user.userSummary.gender) {
            if (this.user.userSummary.gender === 'F') {
                this.userImageUrl = this.FEMALE_DEFAULT_IMAGE;
            } else if (this.user.userSummary.gender === 'M') {
                this.userImageUrl = this.MALE_DEFAULT_IMAGE;
            }
        } else {
            this.userImageUrl = this.MALE_OR_FEMALE_DEFAULT_IMAGE;
        }
    }

    public onImageError($event: ErrorEvent) {
        this.setDefaultImage();
    }

    setVisible() {
        this.edited = !this.edited;
    }

    onThemeSelect(event) {
        this.themeSelection.emit(event);
    }

    public onToggleSidebar() {
        this.isSidebarOpen = !this.isSidebarOpen;
        this.toggleSidebar.emit(this.isSidebarOpen);
    }

    public onActionClick(action: NavItem) {
        if (!action.disable && !action.restricted) {
            this.actionClick.emit(action);
        }
    }

    public onLogout() {
        this.logout.emit();
    }

    public getMainHeader(heading: string) {
        /// MainHeading|@SUB|SubHeading
        return heading.split(HeaderComponent.SUB_HEADER_SPLITTER)[0];
    }

    public getSubHeader(heading: string) {
        /// MainHeading|@SUB|SubHeading
        return heading.split(HeaderComponent.SUB_HEADER_SPLITTER)[1];
    }

    public onViewUserLoadedObjects() {
        this.viewUserLoadedObjects.emit();
    }

    public userProfileLoader() {
        this.openUserProfile.emit();
    }

    getNewNotifications(): any {
        this.notificationSubscription = this.notificationToastService.events.subscribe(
            (event: NotificationToastEvent) => {
                if (event) {
                    if (this.newNotificationData.length === 10) {
                        this.newNotificationData.shift();
                    }
                    if (!event.toast.rawData.sendingDmc) {
                        this.newNotificationData.push(event.toast.rawData);
                        this.newNotification = true;
                    }
                }
            });
    }

    updateUnReadMessageList(): any {
        this.notificationReadSubscription = this.notificationService.readMessageThread.subscribe((thread) => {
            if (thread) {
                // thread.mostRecentMessages.forEach((message) => {
                //   this.newNotificationData.forEach((notification) => {
                //     if(message.notificationId === notification.notificationId) {
                //       this.newNotificationData = this.newNotificationData.splice(this.newNotificationData.indexOf(notification), 1);
                //     }
                //   });
                // });
                this.newNotificationData = [];
            }
        });
    }

    getUpdateBackgroundTasks() {
        this.manualBackgroundTaskSubscription = this.backgroundTaskService.manualDocsToUi
            .subscribe(
                event => {
                    if (event && event.task) {
                        this.hasUnreadDocs = false;
                        this.backgroundTask = event.task;
                        if (event.task.taskStatus === TaskStatus.RUNNING) {
                            this.showBackgroundTaskRunning = true;
                            this.showBackgroundTaskCompletion = false;
                        }
                        if ((event.task.taskStatus === TaskStatus.FINISHED || event.task.taskStatus === TaskStatus.FAILED) && !event.data) {
                            this.onTaskComplete(event.task);
                        }
                    }
                }
            );
        this.docQueueBackgroundTaskSubscription = this.backgroundTaskService.docQueueToUi
            .subscribe(
                event => {
                    if (event && event.task) {
                        this.hasUnreadDocs = false;
                        this.backgroundTask = event.task;
                        if (event.task.taskStatus === TaskStatus.RUNNING) {
                            this.showBackgroundTaskRunning = true;
                            this.showBackgroundTaskCompletion = false;
                        }
                        if ((event.task.taskStatus === TaskStatus.FINISHED || event.task.taskStatus === TaskStatus.FAILED) && !event.data) {
                            this.onTaskComplete(event.task);
                        }
                    }
                }
            );
    }

    private onTaskComplete(task: BackgroundTask) {
        if (task) {
            this.hasUnreadDocs = true;
            this.backgroundTask = task;
            if (task && task.createdFor && task.createdFor === BackgroundTaskType.MANUAL_DOC) {
                const documentSearchCriteria = new DocumentSearchCriteria(false, false);
                documentSearchCriteria.backgroundTaskId = task.taskId;
                documentSearchCriteria.dispatchStatus = null;
                documentSearchCriteria.mainType = null;
                documentSearchCriteria.size = 1000000000;
                this.documentService.searchDocuments(documentSearchCriteria)
                    .subscribe(
                        result => {
                            const docs: Document[] = ResponseUtil.getDataArray(result);
                            this.backgroundTaskData = docs;
                            this.isDownloadable = true;
                            this.showBackgroundTaskRunning = false;
                            this.showBackgroundTaskCompletion = true;
                            if (this.backgroundTask && this.backgroundTask.taskStatus
                                && this.backgroundTask.taskStatus === TaskStatus.FINISHED) {
                                this.common.showSnackBar(
                                    `Background task id: ${this.backgroundTask.taskId} completed.`,
                                    3000,
                                    TcErrorType.TYPE.INFO
                                );
                            } else if (this.backgroundTask && this.backgroundTask.taskStatus
                                && this.backgroundTask.taskStatus === TaskStatus.FAILED) {
                                this.common.showSnackBar(
                                    `Background task id: ${this.backgroundTask.taskId} failed.`,
                                    3000,
                                    TcErrorType.TYPE.ERROR
                                );
                            }

                        },
                        error => {
                            this.backgroundTaskData(new BackgroundTaskEvent<Document[]>(task, null));
                        }
                    );
            } else if (task && task.createdFor && task.createdFor === BackgroundTaskType.DOC_QUEUE) {
                const documentQueueSearchCriteria = new DocumentQueueSearchCriteria();
                documentQueueSearchCriteria.backgroundTaskId = task.taskId;
                documentQueueSearchCriteria.dispatchStatus = null;
                documentQueueSearchCriteria.mainType = null;
                documentQueueSearchCriteria.size = 1000000000;
                this.documentQueueService.searchDocumentQueues(documentQueueSearchCriteria)
                    .subscribe(
                        result => {
                            const docs: DocumentQueueGroup[] = ResponseUtil.getDataArray(result);
                            this.backgroundTaskData = docs;
                            this.showBackgroundTaskRunning = false;
                            this.isDownloadable = false;
                            this.showBackgroundTaskCompletion = true;
                            this.documentQueueService.sendTaskStatus(TaskStatus.FINISHED);

                            if (this.backgroundTask && this.backgroundTask.taskStatus
                                && this.backgroundTask.taskStatus === TaskStatus.FINISHED) {
                                this.common.showSnackBar(
                                    `Background task id: ${this.backgroundTask.taskId} completed.`,
                                    3000,
                                    TcErrorType.TYPE.INFO
                                );
                            } else if (this.backgroundTask && this.backgroundTask.taskStatus
                                && this.backgroundTask.taskStatus === TaskStatus.FAILED) {
                                this.common.showSnackBar(
                                    `One or more transfer manifest email sending has been failed.`,
                                    3000,
                                    TcErrorType.TYPE.ERROR
                                );
                            }
                        },
                        error => {
                            this.backgroundTaskData(new BackgroundTaskEvent<Document[]>(task, null));
                        }
                    );
            }
        }
    }

    onClickNewNotification() {
        this.newNotification = false;
    }

    navigateToNotificationsPanel(indicator) {
        this.newNotificationData = [];
        this.onClickNewNotification();
        if (indicator === 'message') {
            this.userJourneyManager.goForKey('BEGIN_MESSAGE_QUEUE');
        } else {
            this.userJourneyManager.goForKey('BEGIN_NOTIFICATION_QUEUE');
        }
    }

    public onBackgroundTaskClick() {
        this.showBackgroundTaskCompletion = !this.showBackgroundTaskCompletion;
        this.showBackgroundTaskMenu = false;
        this.showBackgroundTaskRunning = !this.showBackgroundTaskRunning;
    }

    public onBackgroundDataDownloadButtonClick() {
        this.hasUnreadDocs = false;
        const docIds = [];
        for (const doc of this.backgroundTaskData) {
            docIds.push(doc.documentId);
        }
        this.documentService.downloadDocuments(docIds);
        this.showBackgroundTaskMenu = false;
    }

    public onOpenInManifestsButtonClick() {
        this.hasUnreadDocs = false;
        if (this.backgroundTask) {
            const criteria = new DocumentSearchCriteria(true, false);
            criteria.backgroundTaskId = this.backgroundTask.taskId;
            criteria.dispatchStatus = 'SUCCESS,FAILED,NOT_DISPATCHED,PENDING';
            criteria.mainType = MainType.ANY;
            criteria.startDate = moment().startOf('year').format('YYYY-MM-DD');
            criteria.endDate = moment().add(1, 'year').endOf('year').format('YYYY-MM-DD');
            criteria.sortDirection = SortDirection.ASC;
            this.localStorage.store(TCO.AppData.MANIFESTS_SEARCH_CRITERIA, criteria);
        }
        // this.showBackgroundTaskMenu = false;
        if (this.userJourneyManager.getCurrentPage() === 'MANIFESTS_PAGE') {
            this.userJourneyManager.goForKey('BEGIN_DUMMY');
            setTimeout(() => this.userJourneyManager.goForKey('BEGIN_MANIFESTS'), 0);
        } else {
            this.userJourneyManager.goForKey('BEGIN_MANIFESTS');
        }
    }

    public onBackgroundDataClearButtonClick() {
        this.hasUnreadDocs = false;
        this.showBackgroundTaskMenu = false;
        this.showBackgroundTaskRunning = false;
        this.showBackgroundTaskCompletion = false;
        this.backgroundTask = null;
        this.backgroundTaskData = null;
    }

    public onBackgroundTaskCancelButtonClick() {
        this.hasUnreadDocs = false;
        if (this.backgroundTask) {
            this.backgroundTaskService.cancelStatusChecking(this.backgroundTask);
            this.showBackgroundTaskMenu = false;
            this.showBackgroundTaskRunning = false;
            this.showBackgroundTaskCompletion = false;
            this.backgroundTask = null;
            this.backgroundTaskData = null;
        }
    }

    public ngOnDestroy(): void {
        if (this.notificationSubscription) {
            this.notificationSubscription.unsubscribe();
        }
        if (this.notificationReadSubscription) {
            this.notificationReadSubscription.unsubscribe();
        }
        if (this.manualBackgroundTaskSubscription) {
            this.manualBackgroundTaskSubscription.unsubscribe();
        }
        if (this.docQueueBackgroundTaskSubscription) {
            this.manualBackgroundTaskSubscription.unsubscribe();
        }
    }
}
