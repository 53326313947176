import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { DirectivesModule } from '@tc/directives';
import { PipesModule } from '@tc/pipes';
import { SecurityModule } from '@tc/security';
import { SingleLineStripModule } from '@tc/single-line-strip';
import { SummaryStripModule } from '@tc/summary-strip';
import { ExpandedContentHeaderCardModule } from '../../../../../widgets/shared/expanded-content-secondary-header-card/expanded-content-header-card.module';
import { ItemNotesComponent } from './item-notes.component';

@NgModule({
  declarations: [ItemNotesComponent],
  exports: [ItemNotesComponent],
  imports: [
    CommonModule,
    MatExpansionModule,
    SingleLineStripModule,
    SummaryStripModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    ExpandedContentHeaderCardModule,
    PipesModule,
    DirectivesModule,
    SecurityModule,
    TranslateModule.forChild()
  ],
})
export class ItemNotesModule { }
