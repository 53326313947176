import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ConfigLoader } from '@tc-core/util/framework';
import { of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SetupGridComp } from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid-comp';
import { SetupGridComponent } from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid.component';
import { TCO } from '../../../constants';
import { SortDirection } from '../../../models/helper/sort-direction';
import { AccountingRuleService } from '../../../services/backend-consumers/setups/accounting-rule.service';
import { DataKey, DataStoreService } from '../../../services/util/framework/data-store.service';
import { LedgerAccountSearchCriteria } from '../../../models/criteria/ledger-account-search-criteria';
var LedgerAccountSetupComponent = /** @class */ (function (_super) {
    tslib_1.__extends(LedgerAccountSetupComponent, _super);
    function LedgerAccountSetupComponent(configLoader, accountingRuleService, dataStoreService) {
        var _this = _super.call(this) || this;
        _this.configLoader = configLoader;
        _this.accountingRuleService = accountingRuleService;
        _this.dataStoreService = dataStoreService;
        _this.colDefConfig = [];
        // search criteria obj. used to
        _this.accountSearchCriteria = new LedgerAccountSearchCriteria();
        // required options for grid editors
        _this.genericTypes = [];
        _this.GENERIC_CATEGORY = 'generic';
        /*
         grid config methods which are called by grid setup (ag grid)
         and set by grid config
         */
        _this.getCurrencies = function (params, rowData, text) {
            return _this.dataStoreService.get(DataKey.currencyList)
                .map(function (currencies) {
                return currencies.filter(function (currency) {
                    if (text) {
                        if (currency.name) {
                            return currency.name.toLowerCase().indexOf(text.toLocaleString()) > -1 ||
                                currency.code.toLowerCase().indexOf(text.toLocaleString()) > -1;
                        }
                    }
                    else {
                        return true;
                    }
                });
            });
        };
        _this.onCurrencySet = function (params) {
            if (params.data && params.newValue) {
                if (params.newValue['code']) {
                    params.data['currency'] = params.newValue['code'];
                }
                else {
                    params.data['currency'] = params.newValue;
                }
            }
            return true;
        };
        _this.cellEditingStart = function (event) {
            // if (event && event.colDef) {
            //     // if (event.colDef.colId === 'account') {
            //     // }
            // }
        };
        return _this;
    }
    LedgerAccountSetupComponent.prototype.ngOnInit = function () {
        this.colDefConfig = this.configLoader.configurations.get(TCO.CONF.CONF_LEDGER_ACCOUNT_SETUP);
        this.subscribeSearchCriteria();
        this.fillRequiredGridOptionData();
    };
    /**
     * subscribe search criteria changes in search bar
     * then force grid to refresh data
     * grid refresh will be call getRaws method which is using this subscribed criteria for backend call
     */
    LedgerAccountSetupComponent.prototype.subscribeSearchCriteria = function () {
        var _this = this;
        this.dataStoreService.get(DataKey.ledgerAccountsSearchCriteria).subscribe(function (value) {
            _this.accountSearchCriteria = value;
            _this.setupGrid.runForceSearch();
        });
    };
    LedgerAccountSetupComponent.prototype.isInvalidRow = function (params) {
        var data = params.data;
        if (data) {
            var invalid = true;
            if (data['code'] != null &&
                data['code'] != '' &&
                data['name'] != null &&
                data['name'] != '') {
                invalid = false;
            }
            return invalid;
        }
        else {
            return false;
        }
    };
    LedgerAccountSetupComponent.prototype.isUnsavedRow = function (row) {
        return !!row.unsaved;
    };
    LedgerAccountSetupComponent.prototype.deleteRow = function (row) {
        if (row && row.code) {
            return this.accountingRuleService.deleteLedgerAccount(row.code);
        }
        else {
            return of('success');
        }
    };
    LedgerAccountSetupComponent.prototype.saveRow = function (row, event) {
        return this.accountingRuleService.saveLedgerAccount(row);
    };
    LedgerAccountSetupComponent.prototype.getRows = function (params) {
        var _this = this;
        console.log('fetch requesting for ' + params.startRow + ' to ' + params.endRow);
        console.log(params.sortModel);
        var pageSize = params.endRow - params.startRow;
        if (!this.accountSearchCriteria) {
            this.accountSearchCriteria = new LedgerAccountSearchCriteria();
        }
        this.accountSearchCriteria.start = params.startRow;
        this.accountSearchCriteria.size = pageSize;
        if (params.sortModel && params.sortModel[0]) {
            this.accountSearchCriteria.sortBy = params.sortModel[0].colId;
            if (params.sortModel[0].sort === SetupGridComp.GRID_SORT_ASCENDING) {
                this.accountSearchCriteria.sortDirection = SortDirection.ASC;
            }
            else {
                this.accountSearchCriteria.sortDirection = SortDirection.DESC;
            }
        }
        return this.accountingRuleService.getLedgerAccounts(this.accountSearchCriteria)
            .pipe(tap(function (data) {
            return _this.dataStoreService.set(DataKey.ledgerAccountsSearchResultsForCriteria, data);
        }));
    };
    LedgerAccountSetupComponent.prototype.createNewRow = function () {
        return {
            unsaved: true
        };
    };
    LedgerAccountSetupComponent.prototype.fillRequiredGridOptionData = function () {
    };
    return LedgerAccountSetupComponent;
}(SetupGridComp));
export { LedgerAccountSetupComponent };
