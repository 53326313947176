import { Injectable } from '@angular/core';
import { LocalStorageService } from '@tc-core/service/helpers/local-storage';

// import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';

@Injectable()
export class DMCLocalStorageService {

    constructor(
        // @Inject(LOCAL_STORAGE) private storage: StorageService
        private storage: LocalStorageService
    ) {}

    public store(key: any, value: any) {
        // this.storage.set(key, value);
        this.storage.put(key, value);
    }

    public load<T>(key: any): T {
        return this.storage.get(key);
    }

    public delete<T>(key: any): T {
        const obj = this.load<T>(key);
        this.storage.remove(key);
        return obj;
    }

    public clear() {
        this.storage.removeAll();
    }
}

export enum LocalDataKey {

    //loggedUserData
    userBusinessType='userBusinessType',
    kcCompany='kcCompany',

    // unsaved data
    unSavedBooking = 'unSavedBooking',
    unSavedVehicle = 'unSavedVehicle',
    unSavedGeneric = 'unSavedGeneric',
    unSavedSupplier = 'unSavedSupplier',
    unSavedFeature = 'unSavedFeature',

    // search criterias
    genQueueSearchCriteria= 'genQueueSearchCriteria',
    trsQueueSearchCriteria= 'trsQueueSearchCriteria',
    ledgerEntrySearchCriteria= 'ledgerEntrySearchCriteria',
    manifestSearchCriteria= 'manifestSearchCriteria',
    reportsSearchCriteria= 'reportsSearchCriteria',
    genericResourceSearchCriteria= 'genericResourceSearchCriteria',
    supplierSearchCriteria= 'supplierSearchCriteria',
    vehicleSearchCriteria= 'vehicleSearchCriteria',
    accountingRulesSearchCriteria= 'accountingRulesSearchCriteria',
    currencySearchCriteria= 'currencySearchCriteria',
    ledgerAccountSearchCriteria= 'ledgerAccountSearchCriteria',
    locationGroupSearchCriteria= 'locationGroupSearchCriteria',
    locationSearchCriteria= 'locationGroupSearchCriteria',
    profileTypeSearchCriteria= 'profileTypeSearchCriteria',
    resourceTypeSearchCriteria= 'resourceTypeSearchCriteria',
    routesSearchCriteria= 'routesSearchCriteria',
    resourceAcceptanceSearchCriteria= 'resourceAcceptanceSearchCriteria',
    notificationSearchCriteria= 'notificationSearchCriteria',
}
