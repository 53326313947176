import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import * as StompJs from '@stomp/stompjs';
import { TcErrorType } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-error';
import { ConfigLoader } from '@tc-core/util/framework';
import * as moment from 'moment';
import { Observable, of, Subscription } from 'rxjs';
import { catchError, debounceTime, map, startWith, switchMap } from 'rxjs/operators';
import { Criteria } from '../../models/criteria/criteria';
import { GenericSearchCriteria } from '../../models/criteria/generic-search-criteria';
import { NotificationSearchCriteria } from '../../models/criteria/notification-search-criteria';
import { UserProfileSearchCriteria } from '../../models/criteria/user-profile-search-criteria';
import { BroadcastReceiver } from '../../models/notification/broadcastReceiver';
import { GroupMsgMember } from '../../models/notification/group-msg-member';
import { GroupMsgThread } from '../../models/notification/group-msg-thread';
import { MessageThread } from '../../models/notification/message-thread';
import { NotificationData, NotificationType } from '../../models/notification/notification-data';
import { NotificationStateChangeData } from '../../models/notification/notification-state-change-data';
import { OPResponseWrapper } from '../../models/response/op-response-wrapper';
import { UserServiceProfile } from '../../models/user/user-service-profile';
import { NotificationService } from '../../services/backend-consumers/notifications/notification.service';
import { GenericResourceService } from '../../services/backend-consumers/supplier-service/generic-resource.service';
import { UserProfileService } from '../../services/backend-consumers/user-service/user-profile.service';
import { DMCCommon } from '../../services/util/common/dmc-common';
import { DataKey, DataStoreService } from '../../services/util/framework/data-store.service';
import { ResponseUtil } from '../../services/util/response/response-util.service';
import { DMCLocalStorageService } from '../../services/util/system/dmc-local-storage.service';

@Component({
    selector: 'tc-messaging-queue',
    templateUrl: './messaging-queue.component.html'
})
export class MessagingQueueComponent implements OnInit, OnDestroy, AfterViewInit {

    messageThreadList: MessageThread[] = [];
    selectedThread: MessageThread;
    notificationSearchCriteria: NotificationSearchCriteria;
    newMessage: NotificationData;
    private client: StompJs.Client;

    //search resource and send msg
    nameCtrl: FormControl;
    genericSearchCriteria: GenericSearchCriteria;
    filteredData: Observable<Array<any>> = null;
    selectedChats: Array<BroadcastReceiver> = [];
    group: Array<BroadcastReceiver> = [];
    groupMsgThreadId: number;
    groupMsgThread: GroupMsgThread;
    singleChatIcon: Observable<string>;
    isBroadCast: boolean = false;
    searchErr: boolean = false;
    searchingInit: boolean = false;
    resourceData: any;
    selectedNotificationId: number = -1;
    createdThreadGroup: Array<number> = [];

    messagePresent: boolean;

    private searchResultSubscription: Subscription = new Subscription();
    private newMessageSubscription: Subscription = new Subscription();

    userEmailAndResourceIdList: Map<number, string> = new Map<number, string>();
    userNameAndResourceIdList: Map<number, string> = new Map<number, string>();

    sidebarHeaderHeight: any;
    @ViewChild('mq_sidebar_header') mq_sidebar_header;

    constructor(
        private notificationService: NotificationService,
        private dataStore: DataStoreService,
        private genericResourceService: GenericResourceService,
        private configLoader: ConfigLoader,
        private userService: UserProfileService,
        private dataService: DataStoreService,
        private localStorageService: DMCLocalStorageService,
        private common: DMCCommon
    ) {
        this.nameCtrl = new FormControl();
        //for configs
        // this.resourceData = this.configLoader.configurations.get(TCO.CONF.CONF_RESERVATION_RESOURCE_ASSIGN_CONFIG).resource;
    }

    ngOnInit() {
        this.genericSearchCriteria = new GenericSearchCriteria();
        this.genericSearchCriteria.activeStatus = 'true';
        //search for resource
        this.filteredData = this.nameCtrl.valueChanges.pipe(
            startWith(''),
            debounceTime(300),
            switchMap(value => {
                this.searchingInit = true; // show/hide search init text
                if (value !== '') {
                    return this.searchForResource(value);
                } else {
                    this.searchErr = false; //show/hide search err
                    this.searchingInit = false;
                    return of(null);
                }
            })
        );
        this.getMessageThreadList();
        this.subscribeNewMessages();
    }

    ngOnDestroy() {
        this.localStorageService.delete('resourceId');
        this.localStorageService.delete('job');
        this.localStorageService.delete('notificationId');
        this.localStorageService.delete('assignmentData');
        if (this.newMessageSubscription) {
            this.newMessageSubscription.unsubscribe();
        }
        if (this.searchResultSubscription) {
            this.searchResultSubscription.unsubscribe();
        }
    }

    ngAfterViewInit() {
        this.getSidenavHeaderHeight();
    }

    getMessageThreadList() {
        this.notificationSearchCriteria = new NotificationSearchCriteria();
        this.notificationSearchCriteria.message = true;
        this.notificationSearchCriteria.dmc = true;
        // also get all groupMsgThreadData
        this.notificationService.searchMessageThreads(this.notificationSearchCriteria);
        this.subscribeSearchResults();

    }

    pushToChip(newMsgRecipient) {
        if (newMsgRecipient.source.value) {
            let data = newMsgRecipient.source.value;
            let name = (data.firstName ? data.firstName : '') + ' ' + (data.lastName ? data.lastName : '');
            this.singleChatIcon = of(data.icon);
            this.nameCtrl.setValue('');
            this.selectedChats.push({resourceId: data.resourceId, name: name, email: null});
        }

        // this.getSidenavHeaderHeight();
    }

    /**
     * select new resource to send msg
     * @param newMsgRecipient
     */
    addNewChat() {
        if (this.selectedChats && this.selectedChats.length > 0) {
            if (this.selectedChats.length == 1) {
                this.isBroadCast = false;
                let name = this.selectedChats[0].name;
                let isExistingThread = false;
                this.deselectAll();
                this.messageThreadList.forEach((thread, i) => {
                    if (thread.supplierId == this.selectedChats[0].resourceId) {
                        isExistingThread = true;
                        thread.isSelected = true;
                        this.selectedThread = thread;
                    }
                });
                if (!isExistingThread) {
                    this.addNewThread(this.selectedChats[0], name);
                }
                this.selectedChats.splice(0, 1);
            } else {
                let headerName: string = '';
                this.isBroadCast = true;
                this.selectedChats.forEach((chat, index) => {
                    this.userNameAndResourceIdList.set(chat.resourceId, chat.name);
                    this.createdThreadGroup.push(chat.resourceId);
                    if (index == 0) {
                        headerName = headerName + chat.name;
                    } else {
                        headerName = headerName + ',' + chat.name;
                    }
                });
                this.deselectAll();
                let groupMsgThread: GroupMsgThread = new GroupMsgThread();
                let groupMsgMembers: Array<GroupMsgMember> = [];
                for (let groupMember of this.createdThreadGroup) {
                    let groupMsgMember: GroupMsgMember = new GroupMsgMember();
                    groupMsgMember.resourceId = groupMember;
                    groupMsgMember.email = this.userEmailAndResourceIdList.get(groupMsgMember.resourceId);
                    groupMsgMember.name = this.userNameAndResourceIdList.get(groupMsgMember.resourceId);
                    groupMsgMembers.push(groupMsgMember);
                }
                groupMsgThread.groupMsgMembers = groupMsgMembers;
                groupMsgThread.groupThreadName = this.setShortNameForNew(headerName);
                this.saveGroupThread(groupMsgThread);

            }
            // this.getSidenavHeaderHeight();
        }
    }

    /**
     * save group Thread
     * GroupMsgThread
     */
    saveGroupThread(saveGroupThread: GroupMsgThread) {
        let savingResourceIds: Array<number> = [];
        saveGroupThread.groupMsgMembers.forEach(member => {
            savingResourceIds.push(member.resourceId);
        });
        this.userService.getEmailsForResourceIds(savingResourceIds).subscribe((result) => {
            if (result) {
                result.data.forEach(data => {
                    this.userEmailAndResourceIdList.set(data.resourceId, data.email);
                });
                saveGroupThread.groupMsgMembers.forEach(member => {
                    member.email = this.userEmailAndResourceIdList.get(member.resourceId);
                });
            }
            this.notificationService.saveGroupThread(saveGroupThread).subscribe(data => {
                if (data) {
                    if (data.data.length > 0) {
                        this.groupMsgThreadId = data.data[0].groupMsgThreadId;
                        this.groupMsgThread = data.data[0];

                        let msgThread: MessageThread = new MessageThread();
                        msgThread.groupMsgThreadId = this.groupMsgThread.groupMsgThreadId;
                        msgThread.mostRecentMessages = [];
                        msgThread.chatHeadDisplayNameShort = this.setShortNameForNew(this.groupMsgThread.groupThreadName);
                        msgThread.groupThreadName = this.setShortNameForNew(this.groupMsgThread.groupThreadName);
                        msgThread.icon = of('assets/img/user-profile/chat-group.svg');
                        msgThread.isSelected = true;

                        this.selectedThread = msgThread;
                        this.messageThreadList.unshift(msgThread);

                        let receiverList: Array<BroadcastReceiver> = [];
                        this.groupMsgThread.groupMsgMembers.forEach(function(member) {
                            if (member.resourceId > 0) {
                                receiverList.push({resourceId: member.resourceId, name: name, email: member.email});
                            }
                            if (member.supplierId > 0) {
                                receiverList.push({resourceId: member.supplierId, name: name, email: member.email});
                            }
                        });
                        this.group = receiverList;
                        msgThread.receivingResourcesIds = receiverList;
                        this.selectedChats = [];
                    }

                    this.common.showSnackBar('Group Thread Initiated Successfully.', 3000, TcErrorType.TYPE.INFO);
                    // this.getGroupThreadData();
                }
            }, error => {
                this.common.showSnackBar('Failed to initiate Group Thread.', 3000, TcErrorType.TYPE.ERROR);
            });
        });
    }

    /**
     * search groupThread
     * @param groupThreadId
     */
    getGroupThreadData() {
        this.dataStore.set(DataKey.groupThreadData, null, true);
        this.notificationService.getGroupThread('savedThreadId').subscribe(result => {
            if (result) {
                let groupThread = ResponseUtil.getDataArray<Criteria>(result).sort();
                this.dataStore.set(DataKey.groupThreadData, groupThread, true);
            }
        });
    }

    removeChatChip(index) {
        this.selectedChats.splice(index, 1);
        // this.getSidenavHeaderHeight();
    }

    addNewThread(resource, name) {
        let msgThread: MessageThread = new MessageThread();
        msgThread.resourceId = resource.resourceId;
        msgThread.email = resource.email;
        msgThread.resourceName = name;
        msgThread.mostRecentMessages = [];
        msgThread.isSelected = true;
        msgThread.chatHeadDisplayNameShort = this.setShortNameForNew(msgThread.resourceName);
        msgThread.icon = this.singleChatIcon;
        this.messageThreadList.unshift(msgThread);
        this.selectedThread = msgThread;
    }

    /**
     * search for resource and process data
     * @param searchKey
     */
    searchForResource(searchKey: string): Observable<any> {
        this.genericSearchCriteria.name = searchKey;
        return this.genericResourceService.searchGenericResourceWithoutDataKey(this.genericSearchCriteria).pipe(
            map(results => {
                let resourceList: Array<any> = [];
                let finalResourceList: Array<any> = [];
                if (results.data && results.data.length == 0) {
                    this.searchingInit = false;
                    this.searchErr = true;
                } else {
                    resourceList = results.data;
                    for (let resource of resourceList) {
                        resource = this.processData(resource);
                    }
                    // resourceList.filter( ( el ) => !this.selectedResourceList.includes( el ) );
                    this.searchingInit = false;
                    this.searchErr = false;
                }

                if (this.selectedChats.length == 0) {
                    finalResourceList = resourceList;
                } else {
                    resourceList.forEach(resource => {
                        let found = false;
                        this.selectedChats.forEach(chat => {
                            if (resource.resourceId === chat.resourceId) {
                                found = true;
                            }
                        });
                        if (!found) {
                            finalResourceList.push(resource);
                        }
                    });
                }
                return finalResourceList;
            }),
            catchError(_ => {
                return of(null);
            })
        );
    }

    /**
     * get ad process data for resource search for new msg
     * @param resource
     * @param arr
     */
    processData(resource) {
        if (resource.resourceId || resource.supplierId) {
            let id = resource.resourceId !== null ? resource.resourceId : resource.supplierId;
            let searchCriteria: UserProfileSearchCriteria = new UserProfileSearchCriteria();
            searchCriteria.resourceId = id;
            this.userService.getResourceServiceProfilesWithoutKey(searchCriteria).subscribe(
                    result => {
                        let data = ResponseUtil.getDataArray<UserServiceProfile>(result);
                        if (data[0] && data[0].imageUrl && data[0].imageUrl != null) {
                            resource.icon = data[0].imageUrl;
                        } else {
                            resource.icon = 'assets/img/user-profile/gender_unknown_user.svg';
                        }
                    }
                );
        } else {
            resource.icon = 'assets/img/user-profile/gender_unknown_user.svg';
        }
        //load icon from configs
        // let iconView = new Icon();
        // let summaryIcon: any;
        // let iconValue: any;
        // summaryIcon = arr.find(
        //     item => item['iconName'] === 'GENERIC_RESOURCE_ICN');
        // iconValue = summaryIcon['key'];
        // if(iconValue === "resource"){
        //     iconValue = resource.resourceType;
        // }
        // if (summaryIcon) {
        //     if (summaryIcon.iconKeys[iconValue]) {
        //         iconView.key = summaryIcon.iconKeys[iconValue];
        //     }
        //     iconView.iconType = summaryIcon['iconType'];
        //     iconView.value = summaryIcon.values[iconValue];
        //     iconView.style = summaryIcon['style'] + ' ' +
        //         summaryIcon['activeClass'];
        //     iconView.value = iconView.value ? iconView.value : '';
        // }
        // resource.icon = iconView;
    }

    subscribeSearchResults() {
        this.searchResultSubscription = this.dataStore.get(DataKey.messageSearchResultsFromService)
                                            .subscribe((data: OPResponseWrapper<MessageThread>) => {
                                                if (data) {
                                                    this.messageThreadList = ResponseUtil.getDataArray<MessageThread>(
                                                        data);
                                                    if (this.messageThreadList.length > 0) {
                                                        this.messageThreadList.forEach(thread => {
                                                                this.calculateUnread(thread);
                                                            }
                                                        );

                                                        if (this.localStorageService.load('resourceId') !== null &&
                                                            this.localStorageService.load('resourceId') !== '') {
                                                            let id = this.localStorageService.load('resourceId') as number;
                                                            let isAssignmentMsg = this.localStorageService.load(
                                                                'isAssignment');
                                                            let notificationId = this.localStorageService.load(
                                                                'notificationId');
                                                            if (notificationId && notificationId > 0) {
                                                                this.selectedNotificationId = +notificationId;
                                                            }
                                                            let hasThread = false;
                                                            this.messageThreadList.forEach(thread => {
                                                                if (thread.supplierId == id || thread.resourceId ==
                                                                    id) {
                                                                    hasThread = true;
                                                                    thread.isSelected = true;
                                                                    this.selectedThread = thread;
                                                                    console.log('Thread selected');
                                                                } else {
                                                                    console.log('Thread is not selected');
                                                                    thread.isSelected = false;
                                                                }
                                                            });
                                                            if (isAssignmentMsg && isAssignmentMsg == 'true') {
                                                                let msgData: NotificationData = this.localStorageService.load(
                                                                    'assignmentData');
                                                                if (msgData !== null && msgData && !hasThread) {
                                                                    let thread = new MessageThread();
                                                                    thread.resourceName = msgData.resourceName;
                                                                    thread.resourceId = msgData.resourceId;
                                                                    if (thread.groupMsgThreadId > 0) {
                                                                        thread.chatHeadDisplayNameShort = this.setShortNameForNew(
                                                                            thread.groupThreadName);
                                                                    } else {
                                                                        thread.chatHeadDisplayNameShort = this.setShortNameForNew(
                                                                            thread.resourceName);
                                                                    }
                                                                    thread.isSelected = true;
                                                                    thread.mostRecentMessages = [];
                                                                    thread.mostRecentMessages.push(msgData);
                                                                    this.messageThreadList.unshift(thread);
                                                                    this.selectedThread = this.messageThreadList[0];
                                                                } else if (msgData !== null && msgData && hasThread) {
                                                                    this.selectedThread.mostRecentMessages.push(msgData);
                                                                }
                                                            }
                                                            if (!hasThread) {
                                                                this.messageThreadList[0].isSelected = true;
                                                                this.updateReadStatus(this.messageThreadList[0]);
                                                                this.selectedThread = this.messageThreadList[0];
                                                            }
                                                        } else {
                                                            this.messageThreadList[0].isSelected = true;
                                                            this.updateReadStatus(this.messageThreadList[0]);
                                                            this.selectedThread = this.messageThreadList[0];
                                                        }
                                                        this.messagePresent = true;
                                                    } else {
                                                        this.messagePresent = false;
                                                    }
                                                } else {
                                                    this.messageThreadList = [];
                                                }
                                            });
    }

    updateReadStatus(thread: MessageThread) {
        thread.mostRecentMessages.forEach(msg => {
            if (!msg.sendingDmc) {
                let notificationStateChangeData: NotificationStateChangeData = new NotificationStateChangeData();
                notificationStateChangeData.dmcUser = true;
                notificationStateChangeData.notificationId = msg.notificationId;
                notificationStateChangeData.resourceId = msg.resourceId;
                notificationStateChangeData.supplierId = msg.supplierId;
                this.notificationService.changeNotificationState(notificationStateChangeData).subscribe(
                    response => {
                        console.log(response);
                    }
                );
            }

        });

    }

    /**
     *
     * @param thread
     */
    calculateUnread(thread: MessageThread) {
        let count = 0;
        thread.mostRecentMessages.forEach(message => {
                if (!message.readStatus && !message.sendingDmc) {
                    count++;
                }
            }
        );
        if (count != 0) {
            thread.hasNewNotification = true;
            thread.mostRecentNotificationCount = count;
        }
    }

    /**
     *
     * @param thread
     */
    setGroupThreadIcon(thread: MessageThread) {
        if (thread.groupMsgThreadId > 0) {
            thread.icon = of('assets/img/user-profile/chat-group.svg');
        }
    }

    /***
     * select thread using id and index
     * @param data
     */
    selectThread(data) {
        if ((this.messageThreadList.length > data.index && data.index >= 0) && this.messageThreadList[data.index]) {

            this.selectedThread = this.messageThreadList[data.index];
            if (!this.selectedThread) {
                console.log(' resourceId or supplierId or groupMsgThreadId not found');
                this.findThreadById(data.id);
            }
        }
    }

    /***
     * find thread with resource id
     * @param id
     */
    findThreadById(id: number) {
        this.messageThreadList.forEach(thread => {
            if (thread.supplierId == id || thread.resourceId == id || thread.groupMsgThreadId == id) {
                this.selectedThread = thread;
            }
        });
    }

    subscribeNewMessages() {
        this.newMessageSubscription = this.dataStore.get(DataKey.newMessages)
                                          .subscribe((data: OPResponseWrapper<NotificationData>) => {
                                              if (data) {
                                                  this.newMessage = data[0];
                                                  if (this.newMessage.type === NotificationType.RESOURCE_COMMUNICATION
                                                      ||
                                                      (this.newMessage.type === NotificationType.RESOURCE_BROADCAST)) {
                                                      if (this.selectedThread &&
                                                          (this.selectedThread.supplierId ===
                                                              this.newMessage.sendingResourceId)
                                                          || (this.selectedThread.groupMsgThreadId ===
                                                              this.newMessage.groupMsgThreadId)) {
                                                          this.newMessage.displayTimeStamp = moment().calendar();
                                                          // TODO need to change update readStatus based on groupThreadId
                                                          this.updateReadStatus(this.selectedThread);
                                                      }
                                                      let isNewThread = true;
                                                      this.messageThreadList.forEach(thread => {
                                                          if (( thread.resourceId > 0 && thread.resourceId ===
                                                              this.newMessage.sendingResourceId &&
                                                              this.newMessage.groupMsgThreadId === 0)
                                                              || (thread.resourceId > 0 && thread.resourceId ===
                                                                  this.newMessage.receivingResourceId &&
                                                                  this.newMessage.groupMsgThreadId === 0)
                                                              || (thread.supplierId > 0 && thread.supplierId ===
                                                                  this.newMessage.sendingSupplierId &&
                                                                  this.newMessage.groupMsgThreadId === 0)
                                                              || (thread.supplierId > 0 && thread.supplierId ===
                                                                  this.newMessage.receivingSupplierId &&
                                                                  this.newMessage.groupMsgThreadId === 0)
                                                              || (thread.groupMsgThreadId > 0 && thread.groupMsgThreadId ===
                                                                  this.newMessage.groupMsgThreadId)) {
                                                              if (!this.newMessage.sendingDmc) {
                                                                  thread.hasNewNotification = true;
                                                              }
                                                              thread.displayTimeStamp = moment().calendar();
                                                              this.newMessage.description.length > 25 ?
                                                                  thread.mostRecentShortDescription = this.newMessage.description.slice(
                                                                      0,
                                                                      22
                                                                  ) + '...' :
                                                                  thread.mostRecentShortDescription = this.newMessage.description;
                                                              if (thread.mostRecentNotificationCount &&
                                                                  !this.newMessage.sendingDmc) {
                                                                  thread.mostRecentNotificationCount = thread.mostRecentNotificationCount +
                                                                      1;
                                                              } else {
                                                                  thread.mostRecentNotificationCount = 1;
                                                              }
                                                              this.newMessage.displayTimeStamp = moment().calendar();
                                                              if (thread.mostRecentMessagesFE &&
                                                                  thread.mostRecentMessagesFE.length > 0) {
                                                                  // this.newMessage.notificationId = thread.mostRecentMessagesFE.length+1;
                                                                  // thread.mostRecentMessagesFE.push(this.newMessage);
                                                                  thread.mostRecentMessages.push(this.newMessage);
                                                              } else {
                                                                  thread.mostRecentMessagesFE = [];
                                                                  // this.newMessage.notificationId = 1;
                                                                  // thread.mostRecentMessagesFE.push(this.newMessage);
                                                                  thread.mostRecentMessages.push(this.newMessage);
                                                              }
                                                              if (thread.groupMsgThreadId > 0 &&
                                                                  thread.chatHeadDisplayNameShort == null) {
                                                                  thread.chatHeadDisplayNameShort = this.setShortNameForNew(
                                                                      thread.groupThreadName);
                                                              } else if (thread.chatHeadDisplayNameShort == null) {
                                                                  thread.chatHeadDisplayNameShort = this.setShortNameForNew(
                                                                      thread.resourceName);
                                                              }
                                                              isNewThread = false;
                                                          }
                                                      });
                                                      if (isNewThread) {
                                                          const msgThread: MessageThread = new MessageThread();
                                                          msgThread.groupMsgThreadId = this.newMessage.groupMsgThreadId;
                                                          if (this.newMessage.sendingResourceId) {
                                                              msgThread.resourceId = this.newMessage.sendingResourceId;
                                                          } else if (this.newMessage.receivingResourceId) {
                                                              msgThread.resourceId = this.newMessage.receivingResourceId;
                                                          }
                                                          if (this.newMessage.sendingSupplierId) {
                                                              msgThread.supplierId = this.newMessage.sendingSupplierId;
                                                          } else if (this.newMessage.receivingSupplierId) {
                                                              msgThread.supplierId = this.newMessage.receivingSupplierId;
                                                          }
                                                          msgThread.groupThreadName = this.newMessage.groupThreadName;
                                                          msgThread.resourceName = this.newMessage.resourceName;
                                                          msgThread.supplierName = this.newMessage.supplierName;
                                                          msgThread.mostRecentMessages.push(this.newMessage);
                                                          msgThread.hasNewNotification = true;
                                                          msgThread.displayTimeStamp = moment().calendar();
                                                          this.newMessage.description.length > 25 ?
                                                              msgThread.mostRecentShortDescription = this.newMessage.description.slice(
                                                                  0,
                                                                  22
                                                              ) + '...' :
                                                              msgThread.mostRecentShortDescription = this.newMessage.description;
                                                          if (msgThread.mostRecentNotificationCount) {
                                                              msgThread.mostRecentNotificationCount = msgThread.mostRecentNotificationCount +
                                                                  1;
                                                          } else {
                                                              msgThread.mostRecentNotificationCount = 1;
                                                          }
                                                          this.newMessage.displayTimeStamp = moment().calendar();
                                                          // if(msgThread.mostRecentMessagesFE && msgThread.mostRecentMessagesFE.length>0){
                                                          //     this.newMessage.notificationId = msgThread.mostRecentMessagesFE.length+1;
                                                          //     msgThread.mostRecentMessagesFE.push(this.newMessage);
                                                          // }else{
                                                          //     msgThread.mostRecentMessagesFE=[];
                                                          //     this.newMessage.notificationId = 1;
                                                          //     msgThread.mostRecentMessagesFE.push(this.newMessage);
                                                          // }

                                                          if (msgThread.groupMsgThreadId > 0) {
                                                              msgThread.chatHeadDisplayNameShort = this.setShortNameForNew(
                                                                  msgThread.groupThreadName);
                                                          } else {
                                                              msgThread.chatHeadDisplayNameShort = this.setShortNameForNew(
                                                                  msgThread.resourceName);
                                                          }

                                                          if (this.newMessage.groupMsgThreadId > 0) {
                                                              msgThread.icon = of(
                                                                  'assets/img/user-profile/chat-group.svg');
                                                          } else {
                                                              let searchCriteria: UserProfileSearchCriteria = new UserProfileSearchCriteria();
                                                              searchCriteria.resourceId = this.newMessage.sendingResourceId;
                                                              this.userService.getResourceServiceProfilesWithoutKey(searchCriteria)
                                                                  .subscribe(
                                                                  results => {
                                                                      searchCriteria.resourceId = null;
                                                                      msgThread.icon = of(
                                                                          'assets/img/user-profile/gender_unknown_user.svg');
                                                                      if (results.data[0] && results.data[0].imageUrl &&
                                                                          results.data[0].imageUrl != null) {
                                                                          msgThread.icon = of(results.data[0].imageUrl);
                                                                      }
                                                                  }
                                                              );
                                                          }
                                                          this.messageThreadList.unshift(msgThread);
                                                      }
                                                  }
                                              }
                                          });
    }

    setShortNameForNew(name: string): string {
        return name.length > 30 ? name.slice(0, 28) + '.. ' : name;
    }

    deselectAll() {
        this.messageThreadList.forEach(thread => {
            thread.isSelected =
                false;
        });
    }

    //receive dummy msg
    receiveDummy() {
        this.notificationService.receiveDummyMsg();
    }

    sendDummy() {
        this.notificationService.sendDummyMessage();
    }

    sendBroadCast() {
        this.notificationService.sendBroadCast();
    }

    getSidenavHeaderHeight() {
        this.sidebarHeaderHeight = this.mq_sidebar_header.nativeElement.offsetHeight;
        console.log('Height: ' + this.sidebarHeaderHeight);
    }

}
