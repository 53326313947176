import * as tslib_1 from "tslib";
import { OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { LEVEL } from '@tc-core/model/it/codegen/ui/framework/dialog-model';
import { ModalData } from '@tc-core/model/it/codegen/ui/framework/modal-data';
import { TC } from '@tc-core/util/constants';
import { ConfigLoader } from '@tc-core/util/framework';
import { EventManager } from '@tc-core/util/framework/event-manager.service';
import { FocusViewManager } from '@tc-core/util/framework/focus-view-manager.service';
import { SpinnerService } from '@tc-core/util/ui';
import { FocusViewService } from '@tc/focus-view/focus-view.service';
import { ModalService } from '@tc/modal/modal.service';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { TCO } from '../../../constants';
import { DispatchEntrySearchCriteria } from '../../../models/criteria/dispatch-entry-search-criteria';
import { SupplierSearchCriteria } from '../../../models/criteria/supplier-search-criteria';
import { DispatchStatus } from '../../../models/document/dispatch-entry';
import { SortDirection } from '../../../models/helper/sort-direction';
import { SupplierSummary } from '../../../models/summary/supplier-summary';
import { DocumentService } from '../../../services/backend-consumers/documents/document.service';
import { SupplierService } from '../../../services/backend-consumers/supplier-service/supplier.service';
import { DataKey, DataStoreService } from '../../../services/util/framework/data-store.service';
import { ResponseUtil } from '../../../services/util/response/response-util.service';
import { SetupGridComp } from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid-comp';
import { SetupGridComponent } from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid.component';
import { DispatchDataModalComponent, DispatchDataModalType } from '../dispatch-data-modal/dispatch-data-modal.component';
var EMPTY_CHAR = TC.FORMAT.EMPTY_CHAR;
var DocumentDetailFocusViewComponent = /** @class */ (function (_super) {
    tslib_1.__extends(DocumentDetailFocusViewComponent, _super);
    function DocumentDetailFocusViewComponent(em, focusViewManager, focusViewService, configLoader, dataStoreService, documentService, modalService, supplierService, spinnerService) {
        var _this = _super.call(this) || this;
        _this.em = em;
        _this.focusViewManager = focusViewManager;
        _this.focusViewService = focusViewService;
        _this.configLoader = configLoader;
        _this.dataStoreService = dataStoreService;
        _this.documentService = documentService;
        _this.modalService = modalService;
        _this.supplierService = supplierService;
        _this.spinnerService = spinnerService;
        _this.documentId = -1;
        _this.colDefConfig = [];
        _this.dispatchEntrySearchCriteria = new DispatchEntrySearchCriteria();
        _this.gridActions = [];
        _this.dispatchPostPatchModalCloseSubscription = new Subscription();
        _this.dataUpdated = false;
        /*
         grid config methods which are called by grid setup (ag grid)
         and set by grid config
         */
        _this.getSupplierName = function (params) {
            var supText = EMPTY_CHAR;
            if (params && params.data) {
                if (params.data.supplierName) {
                    supText = params.data.supplierName;
                }
                else {
                    supText = EMPTY_CHAR;
                }
                supText += ' | ';
                if (params.data.supplierCode) {
                    supText += params.data.supplierCode;
                }
                else {
                    supText += EMPTY_CHAR;
                }
            }
            return supText;
        };
        _this.getUserName = function (params) {
            if (params && params.data && params.data.userName) {
                return params.data.systemGenerated ? 'System' : params.data.userName;
            }
            else if (params && params.data && params.data.systemGenerated) {
                return 'System';
            }
            else {
                return EMPTY_CHAR;
            }
        };
        _this.statusCellClass = function () {
            return {
                'tc-ag-grid-cell--warning': function (params) {
                    if (params.data && params.data.dispatchStatus && params.data.dispatchStatus ===
                        DispatchStatus.PENDING) {
                        return true;
                    }
                },
                'tc-ag-grid-cell--success': function (params) {
                    if (params.data && params.data.dispatchStatus && params.data.dispatchStatus ===
                        DispatchStatus.SUCCESS) {
                        return true;
                    }
                },
                'tc-ag-grid-cell--danger': function (params) {
                    if (params.data && params.data.dispatchStatus && params.data.dispatchStatus ===
                        DispatchStatus.FAILED) {
                        return true;
                    }
                }
            };
        };
        _this.statusPending = function (params) {
            return params.data && params.data.dispatchStatus && params.data.dispatchStatus ===
                DispatchStatus.PENDING;
        };
        _this.statusSuccess = function (params) {
            return params.data && params.data.dispatchStatus && params.data.dispatchStatus ===
                DispatchStatus.SUCCESS;
        };
        _this.statusFailed = function (params) {
            return params.data && params.data.dispatchStatus && params.data.dispatchStatus ===
                DispatchStatus.FAILED;
        };
        _this.isDisabledSendButton = function (params) {
            var dispatchEntry = params.data;
            return !dispatchEntry.email;
        };
        _this.clickSendAction = function (params) {
            _this.spinnerService.show();
            var dispatchEntry = params.data;
            _this.openDispatchPostPatchDialogWithSupplier('Add New Dispatch Entry', DispatchDataModalType.SEND_DISPATCH, dispatchEntry);
        };
        return _this;
    }
    DocumentDetailFocusViewComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.documentId = this.document.documentId;
        this.focusViewManager.canProceed.next(true);
        this.focusViewManager.canProceedState.next(true);
        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'CLOSE_FOCUS_VIEW')
            .subscribe(function (e) {
            _this.focusViewService.close(true);
        });
        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'ADD_NEW_DISPATCH_ENTRY').subscribe(function (e) {
            _this.onAddDispatchEntryClick();
        });
        this.colDefConfig = this.configLoader.configurations.get(TCO.CONF.CONF_DISPATCH_ENTRIES);
        this.addGridActions();
    };
    DocumentDetailFocusViewComponent.prototype.createNewRow = function () {
    };
    DocumentDetailFocusViewComponent.prototype.deleteRow = function (row) {
        return undefined;
    };
    DocumentDetailFocusViewComponent.prototype.getRows = function (params) {
        var _this = this;
        console.log('fetch requesting for ' + params.startRow + ' to ' + params.endRow);
        console.log(params.sortModel);
        // default sort
        if (!(params.sortModel && params.sortModel[0])) {
            var sort = [
                {
                    colId: 'sentTime',
                    sort: 'desc'
                }
            ];
            this.setupGrid.gridApi.setSortModel(sort);
            params.sortModel = sort;
        }
        var pageSize = params.endRow - params.startRow;
        if (!this.dispatchEntrySearchCriteria) {
            this.dispatchEntrySearchCriteria = new DispatchEntrySearchCriteria();
        }
        this.dispatchEntrySearchCriteria.filterByScheduleTime = false;
        this.dispatchEntrySearchCriteria.start = params.startRow;
        this.dispatchEntrySearchCriteria.size = pageSize;
        if (params.sortModel && params.sortModel[0]) {
            this.dispatchEntrySearchCriteria.sortBy = params.sortModel[0].colId;
            if (params.sortModel[0].sort === SetupGridComp.GRID_SORT_ASCENDING) {
                this.dispatchEntrySearchCriteria.sortDirection = SortDirection.ASC;
            }
            else {
                this.dispatchEntrySearchCriteria.sortDirection = SortDirection.DESC;
            }
        }
        return this.documentService.searchDispatchEntries(this.documentId, this.dispatchEntrySearchCriteria)
            .pipe(tap(function (data) {
            return _this.dataStoreService.set(DataKey.dispatchEntrySearchResultsForCriteria, data);
        }));
    };
    DocumentDetailFocusViewComponent.prototype.isInvalidRow = function (params) {
        return false;
    };
    DocumentDetailFocusViewComponent.prototype.isUnsavedRow = function (row) {
        return false;
    };
    DocumentDetailFocusViewComponent.prototype.ngOnDestroy = function () {
    };
    DocumentDetailFocusViewComponent.prototype.saveRow = function (row, event) {
        return undefined;
    };
    DocumentDetailFocusViewComponent.prototype.addGridActions = function () {
        this.gridActions.push({
            icon: 'send',
            action: this.clickSendAction.bind(this),
            disable: this.isDisabledSendButton.bind(this),
            tooltip: 'Send Now'
        });
    };
    DocumentDetailFocusViewComponent.prototype.onAddDispatchEntryClick = function () {
        this.spinnerService.show();
        console.log('add clicked');
        var disPatchEntry = null;
        this.openDispatchPostPatchDialogWithSupplier('Add New Dispatch Entry', DispatchDataModalType.ADD_DISPATCH, disPatchEntry);
    };
    DocumentDetailFocusViewComponent.prototype.openDispatchPostPatchDialogWithSupplier = function (heading, type, dispatchEntryInput) {
        var _this = this;
        this.spinnerService.show();
        var criteria = new SupplierSearchCriteria();
        criteria.code = this.defaultSupplierCode;
        this.supplierService.searchSuppliersNoDataKey(criteria).subscribe(function (result) {
            var defaultSupplier = ResponseUtil.getFirstData(result);
            _this.defaultSupplier = new SupplierSummary();
            _this.defaultSupplier.supplierId = defaultSupplier.supplierId;
            _this.defaultSupplier.code = defaultSupplier.code;
            _this.defaultSupplier.name = defaultSupplier.name;
            _this.defaultSupplier.email = defaultSupplier.email;
        }).add(function () {
            _this.openDispatchPostPatchDialog(heading, type, dispatchEntryInput);
            _this.spinnerService.hide();
        });
    };
    DocumentDetailFocusViewComponent.prototype.openDispatchPostPatchDialog = function (heading, type, dispatchEntryInput) {
        var _this = this;
        this.spinnerService.show();
        this.dataStoreService.set(DataKey.popupClose, null);
        var dataObject = new ModalData(LEVEL.SUCCESS, heading, true, true, 'split-passengers-modal', DispatchDataModalComponent, {
            modalType: type,
            document: this.document,
            dispatchEntry: dispatchEntryInput,
            defaultSupplier: this.defaultSupplier
        });
        dataObject.disableClose = true;
        this.confirmModal(dataObject);
        this.dispatchPostPatchModalCloseSubscription = this.dataStoreService.get(DataKey.popupClose)
            .subscribe(function (data) {
            if (data != null) {
                _this.dispatchPostPatchModalCloseSubscription.unsubscribe();
                _this.closeModal();
                if (data) {
                    _this.dataUpdated = true;
                    _this.setupGrid.runForceSearch();
                    _this.dataStoreService.set(DataKey.docDetailsFocusViewDataChanged, true);
                }
            }
            _this.spinnerService.hide();
        });
    };
    DocumentDetailFocusViewComponent.prototype.confirmModal = function (data) {
        this.modalService.confirm(data).subscribe(function (res) {
        });
    };
    DocumentDetailFocusViewComponent.prototype.closeModal = function () {
        if (this.modalService.close()) {
            this.modalService.close().subscribe(function (res) {
            });
        }
    };
    return DocumentDetailFocusViewComponent;
}(SetupGridComp));
export { DocumentDetailFocusViewComponent };
