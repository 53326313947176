<div
        class="container-fluid content-summary-card"
        [ngClass]="{ 'content-summary-card--warning':isUpdated,'content-summary-card--danger':isCancelled}">
    <div class="row  tc-item--center">
        <div class="col-xs-12 col-sm-12 col-md-2">
            <div class="tc-flex-box">
                <div class="tc-flex-item">
                    <tc-resource
                            *ngIf="summaryContent.icon"
                            id="{{summaryContent.icon.key}}"
                            [key]="summaryContent.icon.key"
                            [value]="summaryContent.icon.value"
                            [style]="summaryContent.icon.style"
                            [iconType]="summaryContent.icon.iconType">
                    </tc-resource>
                </div>
                <div
                        class="tc-flex-item"
                        *ngIf="summaryContent?.resourceName">
                    <div
                            *ngIf="summaryContent?.resourceName" class="tc-strip-text"
                            [ngClass]="row1col1_customsStyles"
                            id="RESOURCE_NAME">
                        {{summaryContent?.resourceName}}
                    </div>
                    <div
                            *ngIf="summaryContent?.driverSupplier?.name" class="tc-strip-text"
                            [ngClass]="row2col1_customsStyles"
                            id="DRIVER_SUPPLIER_NAME">
                        {{summaryContent?.driverSupplier?.name}}
                    </div>
                    <div
                            *ngIf="summaryContent?.trsSupplier?.name" class="tc-strip-text"
                            [ngClass]="row2col1_customsStyles"
                            id="SUPPLIER_NAME">
                        {{summaryContent?.trsSupplier?.name}}
                    </div>
                    <div
                            class="rs-item-card__text-group" [ngClass]="row3col1_customsStyles"
                            id="PASSENGER_COUNT">
                        <i class="material-icons rs-item-card__icon">seat_empty</i>
                        <span class="rs-txt-tertiary">{{summaryContent?.capacity}}</span>
                    </div>
                </div>
            </div>
        </div>
        <!--        <div class="col-xs-12 col-sm-12 col-md-8">-->
        <!--            <div class="tc-strip-timeline" #timelinePart (resized)="onResize($event)" >-->
        <!--                    <tc-availability-timeline-->
        <!--                            [assignmentData]="getTimelineEvents()"-->
        <!--                            [noOfUnits]="noOfUnits"-->
        <!--                            [unitSize]="unitSize"-->
        <!--                            [unitWidth]="unitWidth"-->
        <!--                            [targetIndex]="targetUnitIndex"-->
        <!--                            [targetNoOfUnits]="targetNoOfUnits"-->
        <!--                            [targetMargin]="targetMargin"-->
        <!--                            [timelineStartTime]="timelineStartTime"-->
        <!--                            [summaryContent]="summaryContent"-->
        <!--                            [isBulkAssignment]="isBulkAssignment"-->
        <!--                            (targetClick)="onTargetClick(summaryContent)"-->
        <!--                            (eventClick)="onEventClick($event)"-->
        <!--                            class="tc-strip-timeline__ruler">-->
        <!--                    </tc-availability-timeline>-->
        <!--                </div>-->


        <!--        </div>-->
        <div class="col-xs-12 col-sm-12 col-md-8" style="padding-bottom: 2px;">

            <div *ngIf="summaryContent?.assignments?.length>0" class="row  tc-item--center">
                <div  class="col-xs-12 col-sm-12 col-md-4">
                    <label class="tc-strip-text">{{'Start Time' | translate}}: </label>
                    <input
                           [value]="jobStartTime?jobStartTime:defaultJobStartTime"
                           (keyup.enter)="onInputChange($event,true)"
                           (focusout)="onInputChange($event,true)"
                           (focusin)="jobStartTime===defaultJobStartTime?jobStartTime='':''"
                            style="width: 40px; border: none">
                <button
                        style="color:rgba(0,0,0,0.54)"
                        mat-button
                        mat-icon-button
                        aria-label="more"
                        (click)="startTimePicker.open()">
                    <mat-icon matTooltip="{{'Select Time' | translate}}">schedule</mat-icon>
                </button>
                <ngx-material-timepicker [ngxMaterialTimepickerTheme]="theme" [format]="24" (timeSet)="setTimes($event,true)"  #startTimePicker></ngx-material-timepicker>
                </div>
                <div class="col-md-5"></div>
                <div class="col-xs-12 col-sm-12 col-md-3">
                    <label class="tc-strip-text">{{'End Time' | translate}}: </label>
                    <input
                            [value]="jobEndTime?jobEndTime:defaultJobEndTime"
                            (keyup.enter)="onInputChange($event,false)"
                            (focusout)="onInputChange($event,false)"
                            (focusin)="jobEndTime===defaultJobEndTime?jobEndTime='':''"
                            style="width: 40px; border: none">
                <button
                        style="color:rgba(0,0,0,0.54)"
                        mat-button
                        mat-icon-button
                        aria-label="more"
                        (click)="endTimePicker.open()">
                    <mat-icon matTooltip="{{'Select Time' | translate}}">schedule</mat-icon>
                </button>
                <ngx-material-timepicker [ngxMaterialTimepickerTheme]="theme" [format]="24" (timeSet)="setTimes($event,false)"  #endTimePicker></ngx-material-timepicker>
                </div>
            </div>
            <tc-timeline
                    [summaryContent]="summaryContent"
                    [isTargetBlock]="isBulkAssignment"
                    [leftBufferHours]="leftBufferHours"
                    [rightBufferHours]="rightBufferHours"
                    [targetPointTime]="targetPointTime"
                    [targetBlockTimeStart]="targetBlockTimeStart"
                    [targetBlockTimeEnd]="targetBlockTimeEnd"
                    [events]="timelineEvents"
                    (targetClick)="onTargetClick($event)"
                    (eventClick)="onEventClick($event)">
            </tc-timeline>
        </div>

        <div
                class="col-xs-12 col-sm-12 col-md-2"
                *ngIf="summaryContent?.driverSupplier">
            <div
                    *ngIf="summaryContent?.driverSupplier?.totalCost"
                    class="tc-strip-text"
                    [ngClass]="row1col4_customsStyles"
                    id="TOTAL_COST">{{summaryContent?.driverSupplier?.totalCost |
                tcCurrencyPipe:currencyFormat?.currencyCode:"code":currencyFormat?.pattern}}
            </div>
            <div
                    *ngIf="summaryContent?.resourceCost" class="tc-strip-text" [ngClass]="row2col4_customsStyles"
                    id="RESOURCE_COST">{{'Resource Cost' | translate}}: {{summaryContent?.resourceCost |
                tcCurrencyPipe:currencyFormat?.currencyCode:"code":currencyFormat?.pattern}}
            </div>
            <a
                    class="rs-r-align tc-link__value tc-link__value--regular"
                    (click)="onResourceCalendarClick(summaryContent)">{{'Resource Calendar' | translate}}</a>
        </div>
        <div
                class="col-xs-12 col-sm-12 col-md-2"
                *ngIf="summaryContent?.trsSupplier">
            <!--<div class="col-4-content-wraper">-->
            <div
                    *ngIf="summaryContent?.trsSupplier?.totalCost"
                    class="tc-strip-text"
                    [ngClass]="row1col4_customsStyles"
                    id="SUPPLIER_TOTAL_COST">{{summaryContent?.trsSupplier?.totalCost |
                tcCurrencyPipe:currencyFormat?.currencyCode:"code":currencyFormat?.pattern}}
            </div>
            <div
                    *ngIf="summaryContent?.resourceCost" class="tc-strip-text" [ngClass]="row2col4_customsStyles"
                    id="SUPPLIER_RESOURCE_COST">{{'Resource Cost' | translate}}: {{summaryContent?.resourceCost |
                tcCurrencyPipe:currencyFormat?.currencyCode:"code":currencyFormat?.pattern}}
            </div>
            <a
                    class="rs-r-align tc-link__value tc-link__value--regular"
                    (click)="onResourceCalendarClick(summaryContent)">{{'Resource Calendar' | translate}}</a>
        </div>
    </div>
</div>
<hr>

