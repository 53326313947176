import { HttpClient } from '@angular/common/http';
import { TcErrorType } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-error';
import { SpinnerService } from '@tc-core/util/ui/spinner.service';
import { DocumentCreationCriteria, DocumentType } from '../../../models/criteria/document-creation-criteria';
import { DateTimeProcessor } from '../../business-utils/time-zone/date-time-processor';
import { BackgroundTaskService } from '../../util/change-detector/background-task.service';
import { DMCCommon } from '../../util/common/dmc-common';
import { DMCQueryParamsService } from '../../util/framework/dmc-query-params.service';
import { RequestService } from '../../util/framework/request.service';
import { ResponseUtil } from '../../util/response/response-util.service';
import { DMCBaseService } from '../../util/system/dmc-base.service';
import { UrlPaths } from '../url-paths';
import * as i0 from "@angular/core";
import * as i1 from "../../util/framework/dmc-query-params.service";
import * as i2 from "../../util/framework/request.service";
import * as i3 from "@angular/common/http";
import * as i4 from "@tc-core/util/ui/spinner.service";
import * as i5 from "../../util/common/dmc-common";
import * as i6 from "../../util/change-detector/background-task.service";
import * as i7 from "../../util/system/dmc-base.service";
var DocumentService = /** @class */ (function () {
    function DocumentService(queryParamsService, requestService, http, spinnerService, common, backgroundTaskService, baseService) {
        this.queryParamsService = queryParamsService;
        this.requestService = requestService;
        this.http = http;
        this.spinnerService = spinnerService;
        this.common = common;
        this.backgroundTaskService = backgroundTaskService;
        this.baseService = baseService;
    }
    DocumentService.prototype.downloadDocument = function (documentId) {
        var _this = this;
        var criteria = new DocumentCreationCriteria();
        var params = this.queryParamsService.asString(criteria);
        criteria.documentType = DocumentType.SUPPLIER_TRAN_COST_SHEET;
        criteria.download = true;
        var url = this.requestService.getUrl(UrlPaths.OP_DOCUMENT_URL_KEY, [UrlPaths.OP_DOCUMENT_VERSION, UrlPaths.DOCUMENT, documentId.toString(), UrlPaths.DOCUMENT_FILE]);
        this.spinnerService.show();
        this.http.get(url + params, {
            observe: 'response',
            responseType: 'blob'
        }).subscribe(function (response) {
            _this.spinnerService.hide();
            if (criteria.download) {
                var headers = response.headers;
                var contentDisposition = headers.get('Content-Disposition');
                var filename = _this.getFileName(contentDisposition).replace(/[&\/\\#,+()$~%'":*?<>{}]/g, '');
                _this.downloadFile(response, filename);
            }
        }, function (error) {
            _this.spinnerService.hide();
            _this.common.showSnackBar('Error occurred', 3000, TcErrorType.TYPE.WARN);
        });
    };
    DocumentService.prototype.downloadDocuments = function (documentIds) {
        var _this = this;
        var params = this.queryParamsService.asQueryParams({ documentIds: documentIds });
        var url = this.requestService.getUrl(UrlPaths.OP_DOCUMENT_URL_KEY, [UrlPaths.OP_DOCUMENT_VERSION, UrlPaths.DOCUMENT, UrlPaths.DOCUMENT_FILES]);
        this.spinnerService.show();
        this.http.get(url, {
            observe: 'response',
            responseType: 'blob',
            params: params
        }).subscribe(function (response) {
            _this.spinnerService.hide();
            var headers = response.headers;
            var contentDisposition = headers.get('Content-Disposition');
            var filename = _this.getFileName(contentDisposition).replace(/[&\/\\#,+()$~%'":*?<>{}]/g, '');
            _this.downloadFile(response, filename);
        }, function (error) {
            _this.spinnerService.hide();
            _this.common.showSnackBar('Error occurred', 3000, TcErrorType.TYPE.WARN);
        });
    };
    DocumentService.prototype.downloadFile = function (response, filename) {
        if (filename) {
            var blob = new Blob([response.body], { type: 'octet/stream' });
            if (window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveBlob(blob, filename);
            }
            else {
                var anchorElement = document.createElement('a');
                anchorElement.href = window.URL.createObjectURL(blob);
                if (filename) {
                    anchorElement.setAttribute('download', filename);
                }
                document.body.appendChild(anchorElement);
                anchorElement.click();
                document.body.removeChild(anchorElement);
            }
        }
        else {
            this.common.showSnackBar('No Files', 3000, TcErrorType.TYPE.WARN);
        }
    };
    DocumentService.prototype.getDocumentTypes = function (reportsOnly, manifestsOnly) {
        var reqPrams = this.queryParamsService.asQueryParams({
            reportsOnly: reportsOnly,
            manifestsOnly: manifestsOnly
        });
        return this.requestService.get(UrlPaths.OP_DOCUMENT_URL_KEY, [UrlPaths.OP_DOCUMENT_VERSION, UrlPaths.DOCUMENT_TYPES], reqPrams);
    };
    DocumentService.prototype.createAndDownloadDocument = function (criteria, dataCriteria) {
        this.postForDownloadFile(criteria, dataCriteria);
    };
    DocumentService.prototype.postForDownloadFile = function (criteria, dataCriteria) {
        var _this = this;
        var params = this.queryParamsService.asString(criteria);
        var url = this.requestService.getUrl(UrlPaths.OP_DOCUMENT_URL_KEY, [UrlPaths.OP_DOCUMENT_VERSION, UrlPaths.DOCUMENT]);
        this.spinnerService.show();
        this.baseService.getForBlob(url, params, dataCriteria)
            .subscribe(function (response) {
            _this.spinnerService.hide();
            if (response && response.body && response.body.type === 'application/octet-stream') {
                if (criteria.download) {
                    var headers = response.headers;
                    var contentDisposition = headers.get('Content-Disposition');
                    var filename = _this.getFileName(contentDisposition);
                    _this.downloadFile(response, filename);
                }
            }
            else if (response && response.body && response.body.type === 'application/json') {
                response.body.text().then(function (t) {
                    var backgroundTask = ResponseUtil.getFirstData(JSON.parse(t));
                    _this.backgroundTaskService.startStatusChecking(backgroundTask);
                    _this.common.showSnackBar("Transfer manifest generation background task id: " + backgroundTask.taskId + " is started. You will be notified on the generation status.", 5000, TcErrorType.TYPE.INFO);
                });
            }
        }, function (error) {
            _this.spinnerService.hide();
            _this.common.showSnackBar('Error occurred', 3000, TcErrorType.TYPE.WARN);
        });
    };
    DocumentService.prototype.getFileName = function (contentDisposition) {
        // contentDisposition=attachment; filename=ARRIVAL_LIST_REPORT1593856889219.xlsx
        if (contentDisposition) {
            var strings = contentDisposition.split('attachment; filename=');
            if (strings && strings.length > 1) {
                return strings[1];
            }
        }
        return null;
    };
    // postForDownload(criteria: DocumentCreationCriteria, dataCriteria: DocumentDataCriteria) {
    //     const params = this.queryParamsService.asString(criteria);
    //     const url = this.requestService.getUrl(
    //         UrlPaths.OP_DOCUMENT_URL_KEY,
    //         [UrlPaths.OP_DOCUMENT_VERSION, UrlPaths.DOCUMENT]
    //     );
    //
    //     return this.http.post<HttpResponse<Blob>>(
    //         url + params,
    //         dataCriteria,
    //         {
    //             responseType: 'blob' as 'json'
    //         }
    //     );
    // }
    DocumentService.prototype.searchDocuments = function (criteria) {
        DateTimeProcessor.processDocumentSearchCriteriaDateTimes(criteria);
        var reqPrams = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.get(UrlPaths.OP_DOCUMENT_URL_KEY, [UrlPaths.OP_DOCUMENT_VERSION, UrlPaths.DOCUMENT], reqPrams);
    };
    DocumentService.prototype.postDispatchEntry = function (documentId, dispatchEntry) {
        return this.requestService.post(UrlPaths.OP_DOCUMENT_URL_KEY, [UrlPaths.OP_DOCUMENT_VERSION, UrlPaths.DOCUMENT, documentId.toString(), UrlPaths.DISPATCH_ENTRIES], dispatchEntry);
    };
    DocumentService.prototype.patchDispatchEntry = function (documentId, dispatchId, dispatchPatch, operation) {
        var reqPrams = this.queryParamsService.asQueryParams({ operation: operation });
        return this.requestService.patch(UrlPaths.OP_DOCUMENT_URL_KEY, [
            UrlPaths.OP_DOCUMENT_VERSION,
            UrlPaths.DOCUMENT,
            documentId.toString(),
            UrlPaths.DISPATCH_ENTRIES,
            dispatchId.toString()
        ], dispatchPatch, reqPrams);
    };
    DocumentService.prototype.searchDispatchEntries = function (documentId, criteria) {
        var reqPrams = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.get(UrlPaths.OP_DOCUMENT_URL_KEY, [UrlPaths.OP_DOCUMENT_VERSION, UrlPaths.DOCUMENT, documentId.toString(), UrlPaths.DISPATCH_ENTRIES], reqPrams);
    };
    DocumentService.ngInjectableDef = i0.defineInjectable({ factory: function DocumentService_Factory() { return new DocumentService(i0.inject(i1.DMCQueryParamsService), i0.inject(i2.RequestService), i0.inject(i3.HttpClient), i0.inject(i4.SpinnerService), i0.inject(i5.DMCCommon), i0.inject(i6.BackgroundTaskService), i0.inject(i7.DMCBaseService)); }, token: DocumentService, providedIn: "root" });
    return DocumentService;
}());
export { DocumentService };
