import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChipInputModule } from '@tc/chip-input';
import { ResourceAcceptanceSearchCriteriaComponent } from './resource-acceptance-search-criteria.component';

@NgModule({
  declarations: [ResourceAcceptanceSearchCriteriaComponent],
  imports: [
    CommonModule,
    ChipInputModule
  ],
  exports: [ResourceAcceptanceSearchCriteriaComponent],
  entryComponents: [ResourceAcceptanceSearchCriteriaComponent]
})
export class ResourceAcceptanceSearchCriteriaModule { }
