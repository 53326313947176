import * as tslib_1 from "tslib";
import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { TcApiError } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-api-error';
import { TcHttpError } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-http-error';
import { TC } from '@tc-core/util';
import { ConfigLoader } from '@tc-core/util/framework/config-loader.service';
import { EventManager } from '@tc-core/util/framework/event-manager.service';
import { UserJourneyManager } from '@tc-core/util/framework/user-journey-manager.service';
import { CommonHelper } from '@tc-core/util/helpers/common-helper.service';
import { SpinnerService } from '@tc-core/util/ui';
import { ChipInputComponent } from '@tc/chip-input';
import { Subscription } from 'rxjs';
import { TCO } from '../../constants';
import { UserProfileSearchCriteria } from '../../models/criteria/user-profile-search-criteria';
import { ApprovalStatus } from '../../models/user/user-service-profile';
import { UserProfileService } from '../../services/backend-consumers/user-service/user-profile.service';
import { ChipHandlerService } from '../../services/helpers/chip-handler.service';
import { RootService } from '../../services/util/core-services/root.service';
import { ActionHandlerService } from '../../services/util/framework/action-handler-service.service';
import { DataKey, DataStoreService } from '../../services/util/framework/data-store.service';
import { DMCQueryParamsService } from '../../services/util/framework/dmc-query-params.service';
import { ResourceType } from '../../models/reservation/assignment';
import { UserManagementService } from '../../services/user-management/user-management.service';
import { DateFormatter } from '@tc-core/util/system';
var SupplierAcceptanceSearchCriteriaComponent = /** @class */ (function () {
    function SupplierAcceptanceSearchCriteriaComponent(configLoader, dataStoreService, actionHandlerService, userJourneyManager, em, chipHandler, queryParamService, commonHelper, spinnerService, userProfileService, rootService, dateFormatter, userManagementService) {
        this.configLoader = configLoader;
        this.dataStoreService = dataStoreService;
        this.actionHandlerService = actionHandlerService;
        this.userJourneyManager = userJourneyManager;
        this.em = em;
        this.chipHandler = chipHandler;
        this.queryParamService = queryParamService;
        this.commonHelper = commonHelper;
        this.spinnerService = spinnerService;
        this.userProfileService = userProfileService;
        this.rootService = rootService;
        this.dateFormatter = dateFormatter;
        this.userManagementService = userManagementService;
        this.isInsideForm = false;
        this.isSearchBarStyleNotAvailable = false;
        this.searchTrigger = new EventEmitter();
        this.chipHandlerSubject = new Subscription();
        this.placeHolderText = 'Supplier Acceptance Search';
        this.isChipsInit = false;
        this.availableChips = [];
        this.persistentChipsArr = [];
        this.persistentQParams = [];
        this.persistentQParamMap = new Map();
        this.selectedChips = [];
        this.isDataLoaded = true;
        this.isTriggerSearch = true;
        this.displayChipsArr = [];
        this.isFocus = false;
        this.isChipListDisplay = false;
        this.relatedChipsSub = new Subscription();
        this.routerChangeSub = new Subscription();
        this.selectedChipsSub = new Subscription();
        this.selectedFieldsSub = new Subscription();
        this.chipValueUpdateSub = new Subscription();
        this.criteriaUpdateSub = new Subscription();
        this.deletedChipSub = new Subscription();
        this.searchResultSub = new Subscription();
        this.errorSub = new Subscription();
        this.relatedChipsProcessObsv = new Subscription();
        this.shortCutFocusSearchSub = new Subscription();
        this.shortCutFocusCloseSub = new Subscription();
        this.chipsSubjectSub = new Subscription();
        this.searchCriteriaUpdateSub = new Subscription();
        this.triggerSearchSub = new Subscription();
    }
    SupplierAcceptanceSearchCriteriaComponent.prototype.getQueryParams = function () {
        var queryParams = [];
        queryParams = this.queryParamService.getQueryParamsArray(new UserProfileSearchCriteria());
        this.searchCriteria = this.rootService.loadDataFromLocalStorage(queryParams, new UserProfileSearchCriteria(), TCO.AppData.SUPPLIER_ACCEPTANCE_SEARCH_CRITERIA);
        if (!this.searchCriteria) {
            this.searchCriteria = new UserProfileSearchCriteria();
        }
        this.searchCriteria.approvalStatus =
            this.searchCriteria.approvalStatus ? this.searchCriteria.approvalStatus : ApprovalStatus.PENDING;
        this.searchCriteria = this.userManagementService.updateSearchCriteriaWithUserKC(this.searchCriteria);
        if (this.searchCriteria) {
            this.persistentQParams = [];
            this.persistentQParams = this.queryParamService.getNotNullQueryParamsArray(this.searchCriteria);
            // clear loaded search criteria here
        }
    };
    SupplierAcceptanceSearchCriteriaComponent.prototype.shortCutObserver = function () {
        var _this = this;
        this.shortCutFocusSearchSub = this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'FOCUS_SEARCH_BAR').subscribe(function (e) {
            _this.isFocus = true;
        });
        this.shortCutFocusCloseSub = this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'CLOSE_POPUPS').subscribe(function (e) {
            _this.isFocus = false;
        });
    };
    SupplierAcceptanceSearchCriteriaComponent.prototype.onFocus = function (event) {
        this.isFocus = event;
    };
    SupplierAcceptanceSearchCriteriaComponent.prototype.initSearchCriteriaChips = function () {
        var _this = this;
        this.isChipsInit = true;
        this.chipHandler.chipsArr = [];
        // read chip config
        var criteriaChipConfigs = null;
        if (!this.isInsideForm) {
            var chipConfigs = this.userManagementService.updateSearchChipsAccordingToUserKC(this.configLoader.configurations.get(TCO.CONF.CONF_SUPPLIER_ACCEPTANCE_QUEUE_CHIP));
            criteriaChipConfigs = this.commonHelper.recreateJsonObject(chipConfigs);
        }
        else {
            var chipConfigs = this.userManagementService.updateSearchChipsAccordingToUserKC(this.configLoader.configurations.get(this.searchCriteriaChipConfig));
            criteriaChipConfigs = this.commonHelper.recreateJsonObject(chipConfigs);
        }
        if (criteriaChipConfigs && criteriaChipConfigs.chips) {
            criteriaChipConfigs.chips.forEach(function (chip) {
                _this.chipHandler.preProcessChips(chip, criteriaChipConfigs.permissionKeys.keys);
            });
        }
        this.chipsSubjectSub = this.chipHandler.chipsSubject.subscribe(function (data) {
            var e_1, _a;
            if (data && JSON.stringify(data) !== JSON.stringify([]) && data.length ===
                criteriaChipConfigs.chips.length) {
                _this.availableChips = data;
                _this.chipHandler.chipsSubject.next(null);
                if (_this.chipHandlerSubject) {
                    _this.chipHandlerSubject.unsubscribe();
                }
                // set values to display chips
                _this.displayChipsArr = [];
                _this.displayChipsArr = _this.availableChips;
                _this.dataStoreService.set(DataKey.chips, _this.availableChips, true);
                _this.chipInputService.init(_this.searchCriteria, _this.availableChips, false, true);
                _this.isChipsInit = true;
                // implement chip persistence
                if (_this.persistentQParams && _this.persistentQParams.length > 0) {
                    try {
                        // create a queryParamMap
                        for (var _b = tslib_1.__values(_this.persistentQParams), _c = _b.next(); !_c.done; _c = _b.next()) {
                            var qParam = _c.value;
                            var qParamValue = _this.searchCriteria[qParam];
                            if (qParam && qParamValue) {
                                _this.persistentQParamMap.set(qParam, qParamValue);
                            }
                        }
                    }
                    catch (e_1_1) { e_1 = { error: e_1_1 }; }
                    finally {
                        try {
                            if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                        }
                        finally { if (e_1) throw e_1.error; }
                    }
                    _this.persistentChipsArr = [];
                    // fill persistent chips
                    _this.availableChips.forEach(function (chip) {
                        var persistChip = Object.assign({}, chip);
                        persistChip.optionParams.data = [];
                        var isPersist = false;
                        if (chip.dataParams && chip.dataParams.length > 0) {
                            chip.dataParams.forEach(function (dataParam) {
                                if (_this.persistentQParamMap.has(dataParam.paramValue)) {
                                    isPersist = true;
                                    var dataObj = {
                                        key: dataParam.paramValue,
                                        value: _this.persistentQParamMap.get(dataParam.paramValue)
                                    };
                                    persistChip.optionParams.data.push(dataObj);
                                }
                            });
                        }
                        // push persist chips to an array
                        if (isPersist) {
                            _this.persistentChipsArr.push(persistChip);
                        }
                    });
                    // persist chips
                    if (_this.persistentChipsArr) {
                        _this.chipInputService.persistChips(_this.persistentChipsArr);
                        // trigger search when chips persisting
                        _this.isDataLoaded = false;
                        // this.isTriggerSearch = false;
                        _this.searchCriteria.approvalStatus = ApprovalStatus.PENDING;
                        _this.onSearch(_this.searchCriteria);
                    }
                }
                _this.searchCriteriaUpdateSub = _this.chipInputService.criteriaUpdate.subscribe(function (res) {
                    _this.searchCriteria = res;
                });
            }
        });
    };
    SupplierAcceptanceSearchCriteriaComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.chipInputService = this.searchChipInput.criteriaInputService;
        this.tPagination = this.configLoader.configurations.get(TCO.CONF.CONF_PAGINATION_CONFIG).SUP_ACCEPTANCE_SEARCH;
        this.dataStoreService.set(DataKey.supAcceptanceSearchCriteria, null, true);
        this.handleQuickActions();
        this.routerChangeSub = this.userJourneyManager.routeChanges
            .subscribe(function (e) {
            if (e) {
                _this.chipInputService.fullReset();
                _this.chipHandler.chipsArr = [];
                if (!_this.isInsideForm) {
                    _this.getQueryParams();
                    _this.initSearchCriteriaChips();
                }
                console.log('displayChipsArr', _this.displayChipsArr);
                _this.resetQueryParams();
            }
        });
        if (!this.isInsideForm) {
            this.getQueryParams();
            if (!this.isChipsInit) {
                this.initSearchCriteriaChips();
            }
        }
        this.setSearchResultsObserver();
        this.errorObserver();
        this.selectedChipsUpdate();
        this.handleInterRelatedChips();
        this.notifyChipDelete();
        this.shortCutObserver();
    };
    SupplierAcceptanceSearchCriteriaComponent.prototype.onSearch = function (event) {
        var _this = this;
        this.isDataLoaded = false;
        this.searchCriteria = event;
        if (this.tPagination && this.tPagination.initialFetchingPages &&
            this.tPagination.defaultPageResultCount) {
            this.searchCriteria.size = this.tPagination.initialFetchingPages *
                this.tPagination.defaultPageResultCount;
            this.searchCriteria.start = 0;
        }
        this.userJourneyManager.canProceed.next(false);
        if (JSON.stringify(this.searchCriteria) !== JSON.stringify({})) {
            this.dataStoreService.set(DataKey.supAcceptanceSearchCriteria, this.searchCriteria, true);
            this.dataStoreService.set(DataKey.supAcceptanceSearchResultsFromService, null, true);
            // this.userProfileService.getSupplierServiceProfiles(JSON.parse(JSON.stringify(this.searchCriteria)));
            var queryParams = [];
            queryParams = this.queryParamService.getQueryParamsArray(this.searchCriteria);
            this.rootService.setDataToLocalStorage(TCO.AppData.SUPPLIER_ACCEPTANCE_SEARCH_CRITERIA, this.searchCriteria, true, queryParams, true);
            // remove default serviceType
            this.searchCriteria.restrictedServiceTypes = [];
            this.searchCriteria.restrictedServiceTypes.push(ResourceType.default);
            // add allowed serviceTypes
            this.searchCriteria.allowedServiceTypes = [];
            this.searchCriteria.allowedServiceTypes.push(ResourceType.supplier);
            // search data
            this.userProfileService.getSupplierServiceProfiles(this.searchCriteria);
            this.isTriggerSearch = false;
            this.isDataLoaded = false;
            this.searchTrigger.emit(true);
        }
        else {
            setTimeout(function () {
                _this.isDataLoaded = true;
                _this.isTriggerSearch = true;
            }, 0);
        }
    };
    SupplierAcceptanceSearchCriteriaComponent.prototype.getUpdatedFieldsDataObject = function (chip, criteria) {
        var dataObjArr = [];
        if (chip && chip.dataParams && chip.dataParams.length > 0) {
            chip.dataParams.forEach(function (dataParam) {
                var dataObj = {
                    key: '',
                    value: ''
                };
                dataObj.key = dataParam.paramValue;
                dataObj.value = criteria[dataParam.paramValue];
                dataObjArr.push(dataObj);
            });
        }
        return dataObjArr;
    };
    SupplierAcceptanceSearchCriteriaComponent.prototype.setSearchResultsObserver = function () {
        var _this = this;
        this.searchResultSub = this.dataStoreService.get(DataKey.supAcceptanceSearchResultsFromService)
            .subscribe(function (data) {
            if (_this.commonHelper.dataValidity(data)) {
                _this.isDataLoaded = true;
                _this.isTriggerSearch = false;
            }
            else if (_this.commonHelper.isEmptyData(data)) {
                _this.isDataLoaded = true;
            }
            else if (data instanceof TcApiError) {
                _this.isDataLoaded = true;
            }
            else if (data instanceof TcHttpError) {
                _this.isDataLoaded = true;
            }
        }, function (error) {
            console.log(error);
        });
    };
    SupplierAcceptanceSearchCriteriaComponent.prototype.errorObserver = function () {
        var _this = this;
        this.errorSub = this.dataStoreService.get(DataKey.error).subscribe(function (data) {
            if (data && JSON.stringify(data) !== JSON.stringify({})) {
                setTimeout(function () {
                    _this.isDataLoaded = true;
                }, 0);
                _this.dataStoreService.set(DataKey.error, null);
            }
        });
    };
    SupplierAcceptanceSearchCriteriaComponent.prototype.resetQueryParams = function () {
        var criteria = this.rootService.loadDataFromLocalStorage([], new UserProfileSearchCriteria(), TCO.AppData.SUPPLIER_ACCEPTANCE_SEARCH_CRITERIA);
        var queryParams = [];
        queryParams = this.queryParamService.getQueryParamsArray(criteria);
        this.rootService.setDataToLocalStorage(TCO.AppData.SUPPLIER_ACCEPTANCE_SEARCH_CRITERIA, this.searchCriteria, true, queryParams, true);
    };
    SupplierAcceptanceSearchCriteriaComponent.prototype.onCancel = function (event) {
        console.log('cancelled');
    };
    SupplierAcceptanceSearchCriteriaComponent.prototype.onChipListDisplay = function (event) {
        this.dataStoreService.set(DataKey.chipListDisplay, null, true);
        this.isChipListDisplay = event;
        this.dataStoreService.set(DataKey.chipListDisplay, this.isChipListDisplay, true);
    };
    SupplierAcceptanceSearchCriteriaComponent.prototype.handleQuickActions = function () {
        var _this = this;
        this.triggerSearchSub = this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'TRIGGER_SEARCH_CRITERIA').subscribe(function (e) {
            _this.triggerSearch(e.data);
        });
    };
    SupplierAcceptanceSearchCriteriaComponent.prototype.triggerSearch = function (data) {
        this.searchCriteria = new UserProfileSearchCriteria();
        this.actionHandlerService.fillCriteriaByActionData(data, this.searchCriteria);
        this.chipInputService.hardReset();
        var requiredChips = this.chipHandler.getChipsFromCriteria(this.searchCriteria, this.availableChips, false);
        this.chipInputService.hardReset();
        this.chipInputService.persistChips(requiredChips, true);
        this.onSearch(this.searchCriteria);
    };
    SupplierAcceptanceSearchCriteriaComponent.prototype.handleInterRelatedChips = function () {
        var _this = this;
        this.relatedChipsSub = this.chipInputService.relatedChipsSubject.subscribe(function (data) {
            var toggleChips = [];
            if (data && data.chip && JSON.stringify(data) !== JSON.stringify({})) {
                _this.modifiedChip = data.chip;
                // TODO: refine chip input service: inter related chips
                if (data.chip.type === 'CALENDAR') {
                    if (data.chip.id === 'START_DATE') {
                        var startDate = data.date;
                        var nights = 2;
                        var currentEndDate = _this.checkDependentForCalculateRelatedDate(data.chip);
                        if (currentEndDate) {
                            _this.setMinDateForRelatedDateChips(data.chip, data.date);
                            if (_this.dateFormatter.isAfter(startDate, currentEndDate)) {
                                _this.chipHandler.handleInterRelatedChips(data.chip, _this.dateFormatter.addDaysToDate(startDate, nights));
                            }
                            else {
                                _this.chipHandler.handleInterRelatedChips(data.chip, currentEndDate);
                            }
                        }
                    }
                }
                else {
                    _this.chipHandler.handleInterRelatedChips(data.chip, data.options.value[0]);
                }
                if (data.chip.optionParams.toggleChips && data.chip.optionParams.toggleChips.length > 0) {
                    toggleChips = data.chip.optionParams.toggleChips;
                    if (toggleChips && data.options && data.options.value[0].value) {
                        _this.chipInputService.selectedChipsBasedOnStateChange(toggleChips);
                    }
                    else {
                        _this.chipInputService.deleteChipsBasedOnStateChange(toggleChips);
                    }
                }
            }
        });
        this.relatedChipsProcessObsv = this.chipHandler.relatedChipsProcessSubject.subscribe(function (result) {
            if (result && JSON.stringify(result) !== JSON.stringify([]) &&
                _this.modifiedChip.optionParams.relatedChips &&
                _this.modifiedChip.optionParams.relatedChips.length > 0 &&
                result.length === _this.modifiedChip.optionParams.relatedChips.length) {
                // if chip date is passed date it will be reset for today
                result.forEach(function (chip) {
                    if (chip.dataParam === 'DATE' && chip.criteriaId === 'START_DATE') {
                        chip.options = _this.dateFormatter.dateFromString(chip.options) > _this.dateFormatter.getToday()
                            ? chip.options
                            : _this.dateFormatter.dateAsString(_this.dateFormatter.getToday());
                    }
                    if (chip.dataParam === 'DATE' && chip.criteriaId === 'END_DATE') {
                        /** Following code lines are commented to give the access to set past dates for End Date chip. uncomment below lines when you need to allow the restrictions * */
                        //     chip.options = this.dateFormatter.dateFromString(chip.options) > this.dateFormatter.getToday()
                        //         ? chip.options
                        //         : this.dateFormatter.dateAsString(this.dateFormatter.getToday());
                    }
                });
                // pass related chips to chip input service
                _this.chipInputService.handleInterRelatedChips(result);
            }
        });
    };
    SupplierAcceptanceSearchCriteriaComponent.prototype.selectedChipsUpdate = function () {
        var _this = this;
        this.criteriaUpdateSub = this.chipInputService.selectedChipsSubject.subscribe(function (selectedChips) {
            var e_2, _a;
            _this.selectedChips = selectedChips;
            if (selectedChips && JSON.stringify(selectedChips) !== JSON.stringify([])) {
                try {
                    // toggle chips when selecting BRANCH_OF_CLIENT_ID chip
                    for (var _b = tslib_1.__values(_this.selectedChips), _c = _b.next(); !_c.done; _c = _b.next()) {
                        var selectedChip = _c.value;
                        if (selectedChip.chip.id === 'BRANCH_OF_CLIENT_ID' && selectedChip.chip.optionParams.toggleChips &&
                            selectedChip.chip.optionParams.toggleChips.length > 0) {
                            _this.deleteToggleChips(selectedChip.chip.optionParams.toggleChips);
                            _this.disableChips(selectedChip.chip.optionParams.toggleChips);
                            break;
                        }
                    }
                }
                catch (e_2_1) { e_2 = { error: e_2_1 }; }
                finally {
                    try {
                        if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                    }
                    finally { if (e_2) throw e_2.error; }
                }
            }
        });
    };
    SupplierAcceptanceSearchCriteriaComponent.prototype.notifyChipDelete = function () {
        var _this = this;
        this.deletedChipSub = this.chipInputService.deletedChipsSubject.subscribe(function (deletedChip) {
            if (JSON.stringify(deletedChip) !== JSON.stringify({}) && deletedChip !== null && deletedChip !==
                undefined) {
                if (deletedChip.id === 'CLIENT_TYPE') {
                    var deleteChips = _this.selectedChips.map(function (chip) { return chip.chip; });
                    _this.deleteSelectedChips(deleteChips);
                    _this.enableChips(_this.availableChips);
                }
                else if (deletedChip.id === 'BRANCH_OF_CLIENT_ID') {
                    if (deletedChip.optionParams.toggleChips && deletedChip.optionParams.toggleChips.length > 0) {
                        _this.enableChips(deletedChip.optionParams.toggleChips);
                    }
                }
            }
        });
    };
    /**
     * Delete toggle chips if already selected
     *
     * @param toggleChips
     */
    SupplierAcceptanceSearchCriteriaComponent.prototype.deleteToggleChips = function (toggleChips) {
        var _this = this;
        var deleteChips = [];
        toggleChips.forEach(function (toggleChip) {
            var e_3, _a;
            try {
                for (var _b = tslib_1.__values(_this.selectedChips), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var selectedChip = _c.value;
                    if (selectedChip.chip.id === toggleChip.criteriaId) {
                        deleteChips.push(selectedChip.chip);
                        break;
                    }
                }
            }
            catch (e_3_1) { e_3 = { error: e_3_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_3) throw e_3.error; }
            }
        });
        this.deleteSelectedChips(deleteChips);
    };
    SupplierAcceptanceSearchCriteriaComponent.prototype.deleteSelectedChips = function (deleteChips) {
        var _this = this;
        deleteChips.forEach(function (chip) {
            _this.chipInputService.deleteChip(chip, true);
        });
    };
    SupplierAcceptanceSearchCriteriaComponent.prototype.enableChips = function (enableChips) {
        var _this = this;
        enableChips.forEach(function (enableChip) {
            _this.chipInputService.modifyChipsStatus(enableChip, false);
        });
    };
    SupplierAcceptanceSearchCriteriaComponent.prototype.disableChips = function (disableChips) {
        var _this = this;
        disableChips.forEach(function (disableChip) {
            _this.chipInputService.modifyChipsStatus(disableChip, true);
        });
    };
    SupplierAcceptanceSearchCriteriaComponent.prototype.checkDependentForCalculateRelatedDate = function (chip) {
        var dateParam;
        if (chip.optionParams && chip.optionParams.relatedChips) {
            var dependent = chip.optionParams.behaviouralData.find(function (behData) { return behData.key === 'DATE_RELATED_DEPENDENT'; });
            if (dependent) {
                dateParam = this.getValueOfDependentParameter(dependent.value);
            }
        }
        return dateParam;
    };
    SupplierAcceptanceSearchCriteriaComponent.prototype.getValueOfDependentParameter = function (dependent) {
        if (this.chipInputService && this.chipInputService.criteria &&
            this.chipInputService.criteria[dependent.paramValue]) {
            return this.chipInputService.criteria[dependent.paramValue];
        }
        return '';
    };
    SupplierAcceptanceSearchCriteriaComponent.prototype.setMinDateForRelatedDateChips = function (chip, date) {
        if (date && chip && chip.optionParams && chip.optionParams.relatedChips) {
            var minDate_1 = this.dateFormatter.dateFromString(date);
            chip.optionParams.relatedChips.forEach(function (relateChip) {
                if (relateChip.criteriaType === 'CALENDAR') {
                    relateChip.data = [{ key: 'minDate', value: minDate_1 }];
                }
            });
        }
    };
    SupplierAcceptanceSearchCriteriaComponent.prototype.ngOnDestroy = function () {
        if (this.relatedChipsSub) {
            this.relatedChipsSub.unsubscribe();
        }
        if (this.routerChangeSub) {
            this.routerChangeSub.unsubscribe();
        }
        if (this.selectedChipsSub) {
            this.selectedChipsSub.unsubscribe();
        }
        if (this.selectedFieldsSub) {
            this.selectedFieldsSub.unsubscribe();
        }
        if (this.chipValueUpdateSub) {
            this.chipValueUpdateSub.unsubscribe();
        }
        if (this.criteriaUpdateSub) {
            this.criteriaUpdateSub.unsubscribe();
        }
        if (this.deletedChipSub) {
            this.deletedChipSub.unsubscribe();
        }
        if (this.searchResultSub) {
            this.searchResultSub.unsubscribe();
        }
        if (this.errorSub) {
            this.errorSub.unsubscribe();
        }
        if (this.relatedChipsProcessObsv) {
            this.relatedChipsProcessObsv.unsubscribe();
        }
        if (this.shortCutFocusSearchSub) {
            this.shortCutFocusSearchSub.unsubscribe();
        }
        if (this.shortCutFocusCloseSub) {
            this.shortCutFocusCloseSub.unsubscribe();
        }
        if (this.chipsSubjectSub) {
            this.chipsSubjectSub.unsubscribe();
        }
        if (this.searchCriteriaUpdateSub) {
            this.searchCriteriaUpdateSub.unsubscribe();
        }
        if (this.triggerSearchSub) {
            this.triggerSearchSub.unsubscribe();
        }
    };
    return SupplierAcceptanceSearchCriteriaComponent;
}());
export { SupplierAcceptanceSearchCriteriaComponent };
