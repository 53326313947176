import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SorterService } from '@tc-core/service/sorters/sorter.service';
import { PaginatorModule } from '@tc/paginator';
import { SectionHeadingModule } from '@tc/section-heading';
import { TransferSupplierSearchCriteriaModule } from '../../transfer-supplier-search-criteria/transfer-supplier-search-criteria.module';
import { ResultHeaderModule } from '../../../widgets/shared/result-header/result-header.module';
import { SupplierAssignPanelComponent } from './supplier-assign-panel.component';
import { AdvSupplierAssignSearchResultModule } from '../../../widgets/shared/adv-supplier-assign-search-result/adv-supplier-assign-search-result.module';

@NgModule({
    imports: [
        CommonModule,
        TransferSupplierSearchCriteriaModule,
        ResultHeaderModule,
        AdvSupplierAssignSearchResultModule,
        PaginatorModule,
        SectionHeadingModule
    ],
    providers: [SorterService],
    exports: [SupplierAssignPanelComponent],
    declarations: [SupplierAssignPanelComponent]
})
export class SupplierAssignPanelModule {}
