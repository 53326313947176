import { Component, Input, OnInit } from '@angular/core';
import { DataKey, DataStoreService } from '../../../services/util/framework/data-store.service';

@Component({
  selector: 'tc-pickup-dropoff-details-wrapper',
  templateUrl: './pickup-dropoff-details-wrapper.component.html'
})
export class PickupDropOffDetailsWrapperComponent implements OnInit {

  @Input() dropOffs = null;
  @Input() pickUps = null;
  @Input() selectedIndex = 0;

  constructor(
      private dataStore: DataStoreService
  ) { }

  ngOnInit() {
  }

  public onClosePopup($event: any) {
    this.dataStore.set(DataKey.popupClose, true);
  }
}
