import * as tslib_1 from "tslib";
import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TcApiError } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-api-error';
import { TcHttpError } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-http-error';
import { TC } from '@tc-core/util';
import { ConfigLoader, EventManager, UserJourneyManager } from '@tc-core/util/framework';
import { CommonHelper } from '@tc-core/util/helpers';
import { DateFormatter } from '@tc-core/util/system';
import { SpinnerService } from '@tc-core/util/ui';
import { ChipInputComponent } from '@tc/chip-input';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { DateTimeProcessor } from '../../../services/business-utils/time-zone/date-time-processor';
import { ChipHandlerService } from '../../../services/helpers/chip-handler.service';
import { RootService } from '../../../services/util/core-services/root.service';
import { ActionHandlerService } from '../../../services/util/framework/action-handler-service.service';
import { DataKey, DataStoreService } from '../../../services/util/framework/data-store.service';
import { DMCQueryParamsService } from '../../../services/util/framework/dmc-query-params.service';
import { UserManagementService } from '../../../services/user-management/user-management.service';
var GridSearchCriteriaComponent = /** @class */ (function () {
    function GridSearchCriteriaComponent(activatedRoute, configLoader, userJourneyManager, dataStoreService, actionHandlerService, em, chipHandler, queryParamService, commonHelper, spinnerService, rootService, dateFormatter, userManagementService) {
        this.activatedRoute = activatedRoute;
        this.configLoader = configLoader;
        this.userJourneyManager = userJourneyManager;
        this.dataStoreService = dataStoreService;
        this.actionHandlerService = actionHandlerService;
        this.em = em;
        this.chipHandler = chipHandler;
        this.queryParamService = queryParamService;
        this.commonHelper = commonHelper;
        this.spinnerService = spinnerService;
        this.rootService = rootService;
        this.dateFormatter = dateFormatter;
        this.userManagementService = userManagementService;
        this.placeHolderText = 'Search Items';
        this.isInsideForm = false;
        this.isSearchBarStyleNotAvailable = false;
        this.searchTrigger = new EventEmitter();
        this.searchCriteria = new EventEmitter();
        this.journey = null;
        this.isChipsInit = false;
        this.availableChips = [];
        this.persistentChipsArr = [];
        this.persistentQParams = [];
        this.persistentQParamMap = new Map();
        this.isDataLoaded = true;
        this.isTriggerSearch = true;
        this.displayChipsArr = [];
        this.isFocus = false;
        this.isChipListDisplay = false;
        this.relatedChipsObsv = new Subscription();
        this.selectedChipsObsv = new Subscription();
        this.selectedFieldsObsv = new Subscription();
        this.chipValueUpdateSub = new Subscription();
        this.criteriaUpdateSubscription = new Subscription();
        this.deletedChipObsv = new Subscription();
        this.searchResultObserver = new Subscription();
        this.errorsObserver = new Subscription();
        this.userJourneyObserver = new Subscription();
        this.focusSearchBarObserver = new Subscription();
        this.closePopUpObserver = new Subscription();
        this.triggerSearchCriteriaObserver = new Subscription();
        this.selectedChips = [];
        this.chipHandlerSubject = new Subscription();
        this.relatedChipsProcessObsv = new Subscription();
    }
    GridSearchCriteriaComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.chipInputService = this.searchChipInput.criteriaInputService;
        // clear data store
        this.dataStoreService.set(this.searchCriteriaUpdatingDataKey, null, true);
        this.dataStoreService.set(this.searchResultsDataKey, null, true);
        this.handleQuickActions();
        this.userJourneyObserver = this.userJourneyManager.journey
            .subscribe(function (journey) {
            if (journey && JSON.stringify(journey) !== JSON.stringify({})) {
                _this.journey = journey.id;
            }
        });
        // resetting the view
        this.routerChangeSubscription = this.userJourneyManager.routeChanges
            .subscribe(function (e) {
            if (e) {
                _this.chipInputService.fullReset(); // fully reset chips
                _this.chipHandler.chipsArr = []; // reset chips array loaded from config
                // check journey begins from quick book
                if (!_this.isInsideForm) {
                    _this.getQueryParams();
                    _this.initSearchCriteriaChips();
                }
                console.log('displayChipsArr', _this.displayChipsArr);
                _this.resetQueryParams();
            }
        });
        if (!this.isInsideForm) {
            this.getQueryParams();
            if (!this.isChipsInit) {
                this.initSearchCriteriaChips(); // load everything from start
            }
            this.inputSearchCriteria = this.createNewSearchCriteriaFn();
        }
        this.getSearchObserver();
        this.errorObserver();
        this.selectedChipsUpdate();
        this.handleInterRelatedChips();
        this.notifyChipDelete();
        this.shortCutObserver();
    };
    GridSearchCriteriaComponent.prototype.getQueryParams = function () {
        var queryParams;
        queryParams = this.queryParamService.getQueryParamsArray(this.inputSearchCriteria);
        this.inputSearchCriteria = this.rootService.loadDataFromLocalStorage(queryParams, this.createNewSearchCriteriaFn(), this.searchCriteriaLoadingDataKey);
        if (this.inputSearchCriteria && this.inputSearchCriteria.startDate && this.inputSearchCriteria.endDate) {
            DateTimeProcessor.convertLocalStorageDatesToISOString(this.inputSearchCriteria);
        }
        if (this.inputSearchCriteria && this.inputSearchCriteria.startDate && !this.inputSearchCriteria.endDate) {
            DateTimeProcessor.convertLocalStorageStartDatesToISOString(this.inputSearchCriteria);
        }
        if (this.inputSearchCriteria && this.inputSearchCriteria.endDate && !this.inputSearchCriteria.startDate) {
            DateTimeProcessor.convertLocalStorageEndDatesToISOString(this.inputSearchCriteria);
        }
        if (this.inputSearchCriteria && this.inputSearchCriteria.dispatchStartDate && this.inputSearchCriteria.dispatchEndDate) {
            DateTimeProcessor.convertLocalStorageDispatchDatesToISOString(this.inputSearchCriteria);
        }
        if (this.inputSearchCriteria && this.inputSearchCriteria.dispatchStartDate && !this.inputSearchCriteria.dispatchEndDate) {
            DateTimeProcessor.convertLocalStorageDispatchStartDatesToISOString(this.inputSearchCriteria);
        }
        if (this.inputSearchCriteria && this.inputSearchCriteria.dispatchEndDate && !this.inputSearchCriteria.dispatchStartDate) {
            DateTimeProcessor.convertLocalStorageDispatchEndDatesToISOString(this.inputSearchCriteria);
        }
        this.userManagementService.updateSearchCriteriaWithUserKC(this.inputSearchCriteria);
        // get persisting query params
        if (this.inputSearchCriteria) {
            this.persistentQParams = [];
            this.persistentQParams = this.queryParamService.getNotNullQueryParamsArray(this.inputSearchCriteria);
        }
    };
    GridSearchCriteriaComponent.prototype.shortCutObserver = function () {
        var _this = this;
        this.focusSearchBarObserver = this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'FOCUS_SEARCH_BAR')
            .subscribe(function (e) {
            _this.isFocus = true;
        });
        this.closePopUpObserver = this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'CLOSE_POPUPS')
            .subscribe(function (e) {
            _this.isFocus = false;
        });
    };
    GridSearchCriteriaComponent.prototype.onFocus = function (event) {
        this.isFocus = event;
    };
    GridSearchCriteriaComponent.prototype.initSearchCriteriaChips = function () {
        var _this = this;
        this.isChipsInit = true;
        this.chipInputService.fullReset();
        this.chipHandler.chipsArr = [];
        // read chip config
        var criteriaChipConfigs = null;
        if (!this.isInsideForm) {
            var chipConfigs = this.configLoader.configurations.get(this.chipConfigKey);
            criteriaChipConfigs = this.commonHelper.recreateJsonObject(chipConfigs);
        }
        else {
            var chipConfigs = this.configLoader.configurations.get(this.searchCriteriaChipConfig);
            criteriaChipConfigs = this.commonHelper.recreateJsonObject(chipConfigs);
        }
        if (this.inputSearchCriteria && this.inputSearchCriteria.startDate && (this.inputSearchCriteria.endDate === null || this.inputSearchCriteria.endDate === '')) {
            this.chipHandler.setMinDateForRelatedChips(criteriaChipConfigs, this.inputSearchCriteria.startDate, 'END_DATE');
        }
        if (this.inputSearchCriteria && this.inputSearchCriteria.startDate) {
            this.chipHandler.setMinDateForRelatedChips(criteriaChipConfigs, this.inputSearchCriteria.startDate, 'DOC_QUEUE_END_DATE');
        }
        if (this.inputSearchCriteria && this.inputSearchCriteria.dispatchStartDate) {
            this.chipHandler.setMinDateForRelatedChips(criteriaChipConfigs, this.inputSearchCriteria.dispatchStartDate.substring(0, 10), 'DISPATCH_END_DATE');
        }
        if (criteriaChipConfigs && criteriaChipConfigs.chips) {
            criteriaChipConfigs.chips.forEach(function (chip) {
                chip = _this.chipHandler.preProcessChips(chip, criteriaChipConfigs.permissionKeys.keys);
            });
        }
        var isDataLoaded = false;
        this.chipHandler.chipsSubject.subscribe(function (data) {
            if (data && JSON.stringify(data) !== JSON.stringify([]) && !isDataLoaded) {
                isDataLoaded = true;
                _this.availableChips = data; // new json object here;
                _this.userManagementService.updateSearchChipsAccordingToUserKC(_this.availableChips);
                _this.chipHandler.chipsSubject.next(null);
                if (_this.chipHandlerSubject) {
                    _this.chipHandlerSubject.unsubscribe();
                }
                // set values to display chips
                _this.displayChipsArr = [];
                _this.displayChipsArr = _this.availableChips;
                _this.dataStoreService.set(DataKey.chips, _this.availableChips, true);
                _this.chipInputService.init(_this.inputSearchCriteria, _this.availableChips, false, true);
                _this.isChipsInit = true;
                // implement chip persistence
                if (_this.persistentQParams && _this.persistentQParams.length > 0) {
                    // create a queryParamMap
                    _this.persistentQParams.forEach(function (qParam) {
                        var qParamValue = _this.inputSearchCriteria[qParam];
                        if (qParam && qParamValue) {
                            _this.persistentQParamMap.set(qParam, qParamValue);
                        }
                    });
                    _this.persistentChipsArr = [];
                    // fill persistent chips
                    _this.availableChips.forEach(function (chip) {
                        var persistChip = Object.assign({}, chip);
                        persistChip.optionParams.data = [];
                        var isPersist = false;
                        if (chip.dataParams && chip.dataParams.length > 0) {
                            chip.dataParams.forEach(function (dataParam) {
                                if (_this.persistentQParamMap.has(dataParam.paramValue)) {
                                    isPersist = true;
                                    var dataObj = {
                                        key: dataParam.paramValue,
                                        value: _this.persistentQParamMap.get(dataParam.paramValue)
                                    };
                                    persistChip.optionParams.data.push(dataObj);
                                }
                            });
                        }
                        // push persist chips to an array
                        if (isPersist) {
                            _this.persistentChipsArr.push(persistChip);
                        }
                    });
                    // persist chips
                    if (_this.persistentChipsArr && _this.persistentChipsArr.length > 0) {
                        _this.persistentChipsArr.forEach(function (value) {
                            if (value && value.optionParams && value.optionParams.isMandatory === true) {
                                if (value.criteriaType === 'CALENDAR' && typeof value.optionParams.data[0].value !==
                                    'string') {
                                    value.optionParams.data[0].value = moment(value.optionParams.data[0].value)
                                        .format('YYYY-MM-DD');
                                }
                            }
                        });
                        _this.chipInputService.persistChips(_this.persistentChipsArr);
                        // trigger search when chips persisting
                        _this.isDataLoaded = false;
                        // this.isTriggerSearch = false;
                        _this.onSearch(_this.inputSearchCriteria);
                    }
                }
                _this.chipInputService.criteriaUpdate.subscribe(function (updatedCriteriaData) {
                    _this.inputSearchCriteria = updatedCriteriaData;
                });
            }
        });
    };
    GridSearchCriteriaComponent.prototype.onSearch = function (event) {
        var _this = this;
        this.isDataLoaded = false;
        this.inputSearchCriteria = event;
        this.inputSearchCriteria = this.userManagementService.updateSearchCriteriaWithUserKC(this.inputSearchCriteria);
        this.chipInputService.selectedChipsSubject
            .subscribe(function (selectedChips) {
            _this.selectedChips = selectedChips;
        });
        var selectedChipsParamValues = [];
        this.selectedChips.forEach(function (value) {
            if ((value['chip'] && value['chip']['dataParams'] && value['chip']['dataParams'][0]['paramValue'])) {
                selectedChipsParamValues.push(value['chip']['dataParams'][0]['paramValue']);
            }
        });
        if (this.inputSearchCriteria && selectedChipsParamValues) {
            for (var item in (this.inputSearchCriteria)) {
                if (!selectedChipsParamValues.includes(item)) {
                    this.inputSearchCriteria[item] = null;
                }
            }
        }
        this.userJourneyManager.canProceed.next(false);
        if (JSON.stringify(this.inputSearchCriteria) !== JSON.stringify({})) {
            this.inputSearchCriteria.isForceSearch = true;
            this.dataStoreService.set(this.searchCriteriaUpdatingDataKey, this.inputSearchCriteria, true);
            // set criteria parameters to url
            var queryParams = [];
            queryParams = this.queryParamService.getQueryParamsArray(this.inputSearchCriteria);
            this.rootService.setDataToLocalStorage(this.searchCriteriaLoadingDataKey, this.inputSearchCriteria, true, queryParams, true);
            this.isTriggerSearch = false;
            this.isDataLoaded = false;
            this.searchTrigger.emit(true);
        }
        else {
            setTimeout(function () {
                _this.isDataLoaded = true;
                _this.isTriggerSearch = true;
            }, 0);
        }
    };
    GridSearchCriteriaComponent.prototype.getUpdatedFieldsDataObject = function (chip, criteria) {
        var dataObjArr = [];
        if (chip && chip.dataParams && chip.dataParams.length > 0) {
            chip.dataParams.forEach(function (dataParam) {
                var dataObj = {
                    key: '',
                    value: ''
                };
                dataObj.key = dataParam.paramValue;
                dataObj.value = criteria[dataParam.paramValue];
                dataObjArr.push(dataObj);
            });
        }
        return dataObjArr;
    };
    GridSearchCriteriaComponent.prototype.getSearchObserver = function () {
        var _this = this;
        this.searchResultObserver = this.dataStoreService.get(this.searchResultsDataKey)
            .subscribe(function (data) {
            if (_this.commonHelper.dataValidity(data)) {
                _this.isDataLoaded = true;
                _this.isTriggerSearch = false;
            }
            else if (_this.commonHelper.isEmptyData(data)) {
                _this.isDataLoaded = true;
            }
            else if (data instanceof TcApiError) {
                _this.isDataLoaded = true;
            }
            else if (data instanceof TcHttpError) {
                _this.isDataLoaded = true;
            }
        }, function (error) {
            console.log(error);
        });
    };
    GridSearchCriteriaComponent.prototype.errorObserver = function () {
        var _this = this;
        this.errorsObserver = this.dataStoreService.get(DataKey.error)
            .subscribe(function (data) {
            if (data && JSON.stringify(data) !== JSON.stringify({})) {
                setTimeout(function () {
                    _this.isDataLoaded = true;
                }, 0);
                _this.dataStoreService.set(DataKey.error, null);
            }
        });
    };
    GridSearchCriteriaComponent.prototype.resetQueryParams = function () {
        var qParamsArray = [];
        qParamsArray = this.queryParamService.getQueryParamsArray(this.createNewSearchCriteriaFn());
        var searchCriteria = this.rootService.loadDataFromLocalStorage(qParamsArray, this.createNewSearchCriteriaFn(), this.searchCriteriaLoadingDataKey);
        // set criteria parameters to url
        var queryParams = [];
        queryParams = this.queryParamService.getQueryParamsArray(searchCriteria);
        this.rootService.setDataToLocalStorage(this.searchCriteriaLoadingDataKey, this.inputSearchCriteria, true, queryParams, true);
    };
    GridSearchCriteriaComponent.prototype.onCancel = function ($event) {
        console.log('cancelled');
    };
    GridSearchCriteriaComponent.prototype.onChipListDisplay = function (event) {
        this.dataStoreService.set(DataKey.chipListDisplay, null, true);
        this.isChipListDisplay = event;
        this.dataStoreService.set(DataKey.chipListDisplay, this.isChipListDisplay, true);
    };
    GridSearchCriteriaComponent.prototype.handleQuickActions = function () {
        var _this = this;
        this.triggerSearchCriteriaObserver = this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'TRIGGER_SEARCH_CRITERIA')
            .subscribe(function (e) {
            _this.triggerSearch(e.data);
        });
    };
    GridSearchCriteriaComponent.prototype.triggerSearch = function (data) {
        this.inputSearchCriteria = this.createNewSearchCriteriaFn();
        // todo criteria
        this.actionHandlerService.fillCriteriaByActionData(data, this.inputSearchCriteria);
        var requiredChips = this.chipHandler.getChipsFromCriteria(this.inputSearchCriteria, this.chipConfigKey, true);
        this.chipInputService.hardReset();
        this.chipInputService.persistChips(requiredChips, true);
        this.onSearch(this.inputSearchCriteria);
    };
    GridSearchCriteriaComponent.prototype.handleInterRelatedChips = function () {
        var _this = this;
        this.relatedChipsObsv = this.chipInputService.relatedChipsSubject.subscribe(function (data) {
            var toggleChips = [];
            if (data && data.chip && JSON.stringify(data) !== JSON.stringify({})) {
                _this.modifiedChip = data.chip;
                // TODO: refine chip input service: inter related chips
                if (data.chip.type === 'CALENDAR') {
                    // startDate & returnDate validate
                    if (data.chip.id === 'START_DATE' || data.chip.id === 'DOC_QUEUE_START_DATE' || data.chip.id === 'DISPATCH_START_DATE') {
                        var startDate = data.date;
                        var nights = 2;
                        var currentEndDate = _this.checkDependentForCalculateRelatedDate(data.chip);
                        _this.setMinDateForRelatedDateChips(data.chip, data.date);
                        if (currentEndDate) {
                            if (_this.dateFormatter.isAfter(startDate, currentEndDate)) {
                                _this.chipHandler.handleInterRelatedChips(data.chip, _this.dateFormatter.addDaysToDate(startDate, nights));
                            }
                            else {
                                _this.chipHandler.handleInterRelatedChips(data.chip, currentEndDate);
                            }
                        }
                        else {
                            _this.chipHandler.handleInterRelatedChips(data.chip, _this.dateFormatter.addDaysToDate(startDate, nights));
                        }
                    }
                }
                else {
                    _this.chipHandler.handleInterRelatedChips(data.chip, data.options.value[0]);
                }
                if (data.chip.optionParams.toggleChips && data.chip.optionParams.toggleChips.length > 0) {
                    toggleChips = data.chip.optionParams.toggleChips;
                    if (toggleChips && data.options && data.options.value[0].value) {
                        _this.chipInputService.selectedChipsBasedOnStateChange(toggleChips);
                    }
                    else {
                        _this.chipInputService.deleteChipsBasedOnStateChange(toggleChips);
                    }
                }
            }
        });
        this.relatedChipsProcessObsv = this.chipHandler.relatedChipsProcessSubject.subscribe(function (result) {
            if (result && JSON.stringify(result) !== JSON.stringify([]) &&
                _this.modifiedChip && _this.modifiedChip.optionParams.relatedChips &&
                _this.modifiedChip.optionParams.relatedChips.length > 0 &&
                result.length === _this.modifiedChip.optionParams.relatedChips.length) {
                // pass related chips to chip input service
                _this.chipInputService.handleInterRelatedChips(result);
            }
        });
    };
    GridSearchCriteriaComponent.prototype.setMinDateForRelatedDateChips = function (chip, date) {
        if (date && chip && chip.optionParams && chip.optionParams.relatedChips) {
            var minDate_1 = this.dateFormatter.dateFromString(date);
            chip.optionParams.relatedChips.forEach(function (relateChip) {
                if (relateChip.criteriaType === 'CALENDAR') {
                    relateChip.data = [{ key: 'minDate', value: minDate_1 }];
                }
            });
        }
    };
    GridSearchCriteriaComponent.prototype.checkDependentForCalculateRelatedDate = function (chip) {
        var dateParam;
        if (chip.optionParams && chip.optionParams.relatedChips) {
            var dependent = chip.optionParams.behaviouralData.find(function (behData) { return behData.key === 'DATE_RELATED_DEPENDENT'; });
            if (dependent) {
                dateParam = this.getValueOfDependentParameter(dependent.value);
            }
        }
        return dateParam;
    };
    GridSearchCriteriaComponent.prototype.getValueOfDependentParameter = function (dependent) {
        if (this.chipInputService && this.chipInputService.criteria &&
            this.chipInputService.criteria[dependent.paramValue]) {
            return this.chipInputService.criteria[dependent.paramValue];
        }
        return '';
    };
    GridSearchCriteriaComponent.prototype.selectedChipsUpdate = function () {
        var _this = this;
        this.criteriaUpdateSubscription = this.chipInputService.selectedChipsSubject
            .subscribe(function (selectedChips) {
            var e_1, _a;
            _this.selectedChips = selectedChips;
            if (selectedChips && JSON.stringify(selectedChips) !==
                JSON.stringify([])) {
                try {
                    // toggle chips when selecting BRANCH_OF_CLIENT_ID chip
                    for (var _b = tslib_1.__values(_this.selectedChips), _c = _b.next(); !_c.done; _c = _b.next()) {
                        var selectedChip = _c.value;
                        if (selectedChip.chip.id === 'BRANCH_OF_CLIENT_ID' &&
                            selectedChip.chip.optionParams.toggleChips &&
                            selectedChip.chip.optionParams.toggleChips.length > 0) {
                            _this.deleteToggleChips(selectedChip.chip.optionParams.toggleChips);
                            _this.disableChips(selectedChip.chip.optionParams.toggleChips);
                            break;
                        }
                    }
                }
                catch (e_1_1) { e_1 = { error: e_1_1 }; }
                finally {
                    try {
                        if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                    }
                    finally { if (e_1) throw e_1.error; }
                }
            }
        });
    };
    GridSearchCriteriaComponent.prototype.notifyChipDelete = function () {
        var _this = this;
        this.deletedChipObsv = this.chipInputService.deletedChipsSubject
            .subscribe(function (deletedChip) {
            if (JSON.stringify(deletedChip) !== JSON.stringify({}) && deletedChip !== null &&
                deletedChip !==
                    undefined) {
                if (deletedChip.id === 'CLIENT_TYPE') {
                    var deleteChips = _this.selectedChips.map(function (chip) { return chip.chip; });
                    _this.deleteSelectedChips(deleteChips);
                    _this.enableChips(_this.availableChips);
                }
                else if (deletedChip.id === 'BRANCH_OF_CLIENT_ID') {
                    4;
                    if (deletedChip.optionParams.toggleChips &&
                        deletedChip.optionParams.toggleChips.length > 0) {
                        _this.enableChips(deletedChip.optionParams.toggleChips);
                    }
                }
            }
            if (deletedChip && (deletedChip.id === 'DOC_QUEUE_START_DATE')) {
                var startDate = new Date();
                startDate.setFullYear(startDate.getFullYear() - 5);
                _this.setMinDateForRelatedDateChips(deletedChip, startDate.toISOString().substring(0, 10));
                var currentEndDate = _this.checkDependentForCalculateRelatedDate(deletedChip);
                if (currentEndDate) {
                    _this.chipHandler.handleInterRelatedChips(deletedChip, currentEndDate);
                }
                else {
                    _this.chipHandler.handleInterRelatedChips(deletedChip, startDate);
                }
            }
        });
    };
    GridSearchCriteriaComponent.prototype.deleteToggleChips = function (toggleChips) {
        var _this = this;
        var deleteChips = [];
        toggleChips.forEach(function (toggleChip) {
            var e_2, _a;
            try {
                for (var _b = tslib_1.__values(_this.selectedChips), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var selectedChip = _c.value;
                    if (selectedChip.chip.id === toggleChip.criteriaId) {
                        deleteChips.push(selectedChip.chip);
                        break;
                    }
                }
            }
            catch (e_2_1) { e_2 = { error: e_2_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_2) throw e_2.error; }
            }
        });
        this.deleteSelectedChips(deleteChips);
    };
    GridSearchCriteriaComponent.prototype.deleteSelectedChips = function (deleteChips) {
        var _this = this;
        deleteChips.forEach(function (chip) {
            _this.chipInputService.deleteChip(chip, true);
        });
    };
    GridSearchCriteriaComponent.prototype.enableChips = function (enableChips) {
        var _this = this;
        enableChips.forEach(function (enableChip) {
            _this.chipInputService.modifyChipsStatus(enableChip, false);
        });
    };
    GridSearchCriteriaComponent.prototype.disableChips = function (disableChips) {
        var _this = this;
        disableChips.forEach(function (disableChip) {
            _this.chipInputService.modifyChipsStatus(disableChip, true);
        });
    };
    GridSearchCriteriaComponent.prototype.ngOnDestroy = function () {
        this.userJourneyManager.routeChanges.next(false);
        if (this.routerChangeSubscription) {
            this.routerChangeSubscription.unsubscribe();
        }
        if (this.relatedChipsObsv) {
            this.relatedChipsObsv.unsubscribe();
        }
        if (this.selectedChipsObsv) {
            this.selectedChipsObsv.unsubscribe();
        }
        if (this.selectedFieldsObsv) {
            this.selectedFieldsObsv.unsubscribe();
        }
        if (this.chipValueUpdateSub) {
            this.chipValueUpdateSub.unsubscribe();
        }
        if (this.criteriaUpdateSubscription) {
            this.criteriaUpdateSubscription.unsubscribe();
        }
        if (this.deletedChipObsv) {
            this.deletedChipObsv.unsubscribe();
        }
        if (this.searchResultObserver) {
            this.searchResultObserver.unsubscribe();
        }
        if (this.errorsObserver) {
            this.errorsObserver.unsubscribe();
        }
        if (this.userJourneyObserver) {
            this.userJourneyObserver.unsubscribe();
        }
        if (this.focusSearchBarObserver) {
            this.focusSearchBarObserver.unsubscribe();
        }
        if (this.closePopUpObserver) {
            this.closePopUpObserver.unsubscribe();
        }
        if (this.triggerSearchCriteriaObserver) {
            this.triggerSearchCriteriaObserver.unsubscribe();
        }
        this.em.removeEvents([
            'TRIGGER_SEARCH_CRITERIA'
        ]);
    };
    return GridSearchCriteriaComponent;
}());
export { GridSearchCriteriaComponent };
