import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ChipInputModule } from '@tc/chip-input';
import { SupplierAcceptanceSearchCriteriaComponent } from './supplier-acceptance-search-criteria.component';

@NgModule({
    declarations: [SupplierAcceptanceSearchCriteriaComponent],
    imports: [
        CommonModule,
        ChipInputModule
    ],
    exports: [SupplierAcceptanceSearchCriteriaComponent],
    entryComponents: [SupplierAcceptanceSearchCriteriaComponent]
})
export class SupplierAcceptanceSearchCriteriaModule
{
}
