import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Pager} from '@tc-core/model/it/codegen/ui/framework/pager';
import {Sorter} from '@tc-core/model/it/codegen/ui/framework/sorter';
import {SummaryCardData} from '@tc-core/model/it/codegen/ui/framework/summary-card-data';
import {SorterService} from '@tc-core/service/sorters/sorter.service';
import {ConfigLoader} from '@tc-core/util/framework/config-loader.service';
import {TCO} from '../../../constants';
import {GenericSearchCriteria} from '../../../models/criteria/generic-search-criteria';
import {GenericTypePathname} from '../../../models/helper/generic-type-pathname';
import {ResourceType} from '../../../models/reservation/assignment';
import {ServiceItem} from '../../../models/reservation/service-item';
import {ServiceItemChanges} from '../../../models/helper/service-item-changes';
import {GenericResourceSearchType} from '../../generic-search-criteria/generic-search-criteria.component';
import * as moment from 'moment';
import {SearchResultSummary} from '../../../models/reservation/search-result-summary';
import {ResourceAvailability} from '../../../models/resource/resource-availability';
import {GenTourItem} from "../../../models/reservation/generic/gen-tour-item";

@Component({
    selector: 'tc-resource-assign-panel',
    templateUrl: './resource-assign-panel.component.html'
})
export class ResourceAssignPanelComponent implements OnInit {

    @Input() isDataLoaded: true;
    @Input() isTriggerSearch: true;
    @Input() isFocus = false;
    @Input() isSearchBarStyleNotAvailable = true;
    @Input() placeHolderText = 'Search Resources';
    @Input() selectedResource: ResourceAvailability;
    @Input() genTourItem: GenTourItem;

    @Input() tAssignedResources: any = null;
    @Input() contentCardDataList: Array<any> = [];
    @Input() selectedSummaryCardData: any = null;
    @Input() bookingDataArray: ServiceItem[];
    @Input() isBulkAssignment: boolean = false;
    @Input() heading = '';
    @Input() supplierIdList = [];
    @Input() hasMore = true;
    @Input() fetchedResourceCount = 0;
    @Input() dataFromMoreClick = false;
    @Input() pager: Pager;

    @Output() resourceSelect: EventEmitter<any> = new EventEmitter();
    @Output() onPaginationMoreClick: EventEmitter<any> = new EventEmitter();
    @Output() resourceCalendarClick: EventEmitter<any> = new EventEmitter<number>();
    @Output() eventClick: EventEmitter<any> = new EventEmitter();
    @Output() targetClick: EventEmitter<any> = new EventEmitter();


    resourceSearchCriteria = new GenericSearchCriteria();
    sorters_: Sorter[];
    _searchType = GenericResourceSearchType.GenResourceAssign;
    public defaultPageResultCount = 4;
    selectionIndex = '';
    tPagination: any;


    constructor(public sorterService: SorterService, private configLoader: ConfigLoader) {
    }

    ngOnInit() {
        this.tPagination = this.configLoader.configurations.get(TCO.CONF.CONF_PAGINATION_CONFIG)['RESOURCE_ALLOCATION_SEARCH'];
        // this.createSorters();
        this.createResourceSearchCriteria();
        this.setupPaginator();
    }

    createSorters() {
        if (this.tAssignedResources) {
            this.sorterService.sorters = [];
            this.sorters_ = [];
            let sorterMap = new Map<string, Sorter>();
            this.tAssignedResources.config.placeHolderConfig.forEach(config => {
                if (config && config.sort) {
                    let sorter = this.sorterService.createSorter(config);
                    let id = config['_id'];
                    sorterMap.set(id, sorter);
                    this.sorters_.push(sorter);
                }
            });
        }
    }

    public onSort(event) {
        this.sorterService.sorter = event;
    }

    public onDriverSelect(summaryCardData: SummaryCardData) {
        this.selectedSummaryCardData = summaryCardData;

        if (summaryCardData) {
            this.resourceSelect.emit(this.selectedSummaryCardData);
        }
    }

    createResourceSearchCriteria() {
        let previousAssignmentDetail = this.genTourItem.assignments.find(value => value.categoryCode === this.genTourItem.categoryCode);
        this.resourceSearchCriteria.rejectedResourceIdList = previousAssignmentDetail ? previousAssignmentDetail.rejectedResourceIdList : [];
        this.resourceSearchCriteria.serviceDate = this.genTourItem.serviceItems[0].serviceDate;
        this.resourceSearchCriteria.serviceItems = this.genTourItem.serviceItems.map(function (elem) {
            return elem.serviceItemId;
        }).join(",");
        // this.resourceSearchCriteria.workingHours = this.genTourItem.jobPeriod;
        console.log(this.resourceSearchCriteria);

        this.resourceSearchCriteria.resourceType = this.genTourItem.categoryCode;
        this.resourceSearchCriteria.activeStatus = 'true';
        this.resourceSearchCriteria.supplierIdList = this.supplierIdList;
        this.resourceSearchCriteria.serviceType = "NON_TRS";
    }

    private setupPaginator() {
        this.defaultPageResultCount = this.tPagination['defaultPageResultCount'];
        this.pager = new Pager(0, this.defaultPageResultCount);
    }

    public onPage(pageNumber: number) {
        this.pager.currPage = pageNumber;
        this.selectionIndex = (this.pager.currPage * this.pager.itemsPerPage).toString();
    }

    public onMore() {
        this.dataFromMoreClick = true;
        if (this.tPagination && this.tPagination['defaultPageResultCount'] && this.tPagination['fetchingPages']) {
            this.resourceSearchCriteria.size = this.tPagination['fetchingPages'] *
                this.tPagination['defaultPageResultCount'];
            this.onPaginationMoreClick.emit(this.resourceSearchCriteria);
            // this.retrieveAndCollectSearchResults(this.supplierSearchCriteria);
        }
    }

    onResourceCalendarClick(resourceId: number) {
        this.resourceCalendarClick.emit(resourceId);
    }

    onTargetClick(target: any) {
        this.targetClick.emit(target);
    }

    onEventClick(event: any) {
        this.eventClick.emit(event);
    }

}
