import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import {
  MatChipsModule,
  MatDatepickerModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatMenuModule,
  MatTooltipModule
} from '@angular/material';
import { TranslateModule } from '@ngx-translate/core';
import { FocusHandlerService } from '@tc-core/util/framework/focus-handler.service';
import { DynamicRenderer } from '@tc-core/util/ui/dynamic-renderer.service';
// import { CgCalendarModule } from '@tc/cg-calendar';
// import { SeasonCalendarBodyComponent } from '@tc/cg-season-calendar/season-calendar-body/season-calendar-body.component';
// import { SeasonCalendarNavigationComponent } from '@tc/cg-season-calendar/season-calendar-navigation/season-calendar-navigation.component';
// import { SeasonCalendarService } from '@tc/cg-season-calendar/season-calendar.service';
// import { SeasonRangeEditCardComponent } from '@tc/cg-season-calendar/season-range-edit-card/season-range-edit-card.component';
// import { SeasonSetupSummaryComponent } from '@tc/cg-season-calendar/season-setup-summary/season-setup-summary.component';
// import { SeasonsEditCardComponent } from '@tc/cg-season-calendar/seasons-edit-card/seasons-edit-card.component';
import { ChipDropdownModule } from '@tc/chip-dropdown';
import { DialogComponent, DialogModule } from '@tc/dialog';
import { DialogService } from '@tc/dialog/dialog.service';
import { ModalComponent, ModalModule } from '@tc/modal';
import { ModalService } from '@tc/modal/modal.service';
import { CgCalendarModule } from '../cg-calendar';
import { CgSeasonCalendarComponent } from './cg-season-calendar.component';
import { SeasonCalendarBodyComponent } from './season-calendar-body/season-calendar-body.component';
import { SeasonCalendarNavigationComponent } from './season-calendar-navigation/season-calendar-navigation.component';
import { SeasonCalendarService } from './season-calendar.service';
import { SeasonRangeEditCardComponent } from './season-range-edit-card/season-range-edit-card.component';
import { SeasonSetupSummaryComponent } from './season-setup-summary/season-setup-summary.component';
import { SeasonsEditCardComponent } from './seasons-edit-card/seasons-edit-card.component';
import {MatButtonModule} from "@angular/material/button";

@NgModule({
  declarations: [
    CgSeasonCalendarComponent,
    SeasonCalendarNavigationComponent,
    SeasonCalendarBodyComponent,
    SeasonsEditCardComponent,
    SeasonRangeEditCardComponent,
    SeasonSetupSummaryComponent
  ],
    imports: [
        CommonModule,
        TranslateModule.forChild(),
        ModalModule,
        DialogModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatTooltipModule,
        MatChipsModule,
        MatDatepickerModule,
        FormsModule,
        MatFormFieldModule,
        ChipDropdownModule,
        CgCalendarModule,
        MatButtonModule
    ],
  providers: [
    ModalService,
    DialogService,
    FocusHandlerService,
    DynamicRenderer,
    SeasonCalendarService
  ],
  exports: [
    CgSeasonCalendarComponent
  ],
  entryComponents: [
    ModalComponent,
    DialogComponent,
    SeasonsEditCardComponent,
    SeasonSetupSummaryComponent,
    SeasonRangeEditCardComponent
  ]
})
export class CgSeasonCalendarModule {
}
