/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "@ngx-translate/core";
import * as i3 from "../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid.component.ngfactory";
import * as i4 from "../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid.component";
import * as i5 from "../../../services/util/pre-processors/grid-column-definition-processor.service";
import * as i6 from "@tc/dialog/dialog.service";
import * as i7 from "../../../services/util/framework/data-store.service";
import * as i8 from "../../../services/util/common/dmc-common";
import * as i9 from "./documents-search-results.component";
import * as i10 from "@tc-core/util/framework/config-loader.service";
import * as i11 from "../../../services/backend-consumers/master-data-handler-service/data-handler.service";
import * as i12 from "../../../services/backend-consumers/documents/document.service";
import * as i13 from "@angular/router";
import * as i14 from "@tc/focus-view/focus-view.service";
var styles_DocumentsSearchResultsComponent = [];
var RenderType_DocumentsSearchResultsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DocumentsSearchResultsComponent, data: {} });
export { RenderType_DocumentsSearchResultsComponent as RenderType_DocumentsSearchResultsComponent };
function View_DocumentsSearchResultsComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "span", [["class", ""]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-lg fa-file-pdf-o no-color--state"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, [" \u00A0 DOC_", " "]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.documentId; _ck(_v, 4, 0, currVal_0); }); }
function View_DocumentsSearchResultsComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "span", [["class", ""]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-lg fa-file-excel-o active-color--state "]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, [" \u00A0 DOC_", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.documentId; _ck(_v, 4, 0, currVal_0); }); }
function View_DocumentsSearchResultsComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-html5"], ["style", "color: coral;font-size: 1.5em;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, [" \u00A0DOC_", " "]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.documentId; _ck(_v, 3, 0, currVal_0); }); }
function View_DocumentsSearchResultsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_DocumentsSearchResultsComponent_2)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DocumentsSearchResultsComponent_3)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DocumentsSearchResultsComponent_4)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = (((_v.context.$implicit == null) ? null : _v.context.$implicit.documentFormat) && (((_v.context.$implicit == null) ? null : _v.context.$implicit.documentFormat) == "pdf")); _ck(_v, 1, 0, currVal_0); var currVal_1 = (((_v.context.$implicit == null) ? null : _v.context.$implicit.documentFormat) && (((_v.context.$implicit == null) ? null : _v.context.$implicit.documentFormat) == "excel")); _ck(_v, 3, 0, currVal_1); var currVal_2 = (((_v.context.$implicit == null) ? null : _v.context.$implicit.documentFormat) && (((_v.context.$implicit == null) ? null : _v.context.$implicit.documentFormat) == "html")); _ck(_v, 5, 0, currVal_2); }, null); }
function View_DocumentsSearchResultsComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "span", [["class", ""]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-lg fa-file-pdf-o no-color--state"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, [" \u00A0", " "]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.name; _ck(_v, 4, 0, currVal_0); }); }
function View_DocumentsSearchResultsComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "span", [["class", ""]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-lg fa-file-excel-o active-color--state "]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, [" \u00A0", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.name; _ck(_v, 4, 0, currVal_0); }); }
function View_DocumentsSearchResultsComponent_8(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "span", [["class", ""]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-lg fa-file-excel-o active-color--state "]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, [" \u00A0", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.name; _ck(_v, 4, 0, currVal_0); }); }
function View_DocumentsSearchResultsComponent_9(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-html5"], ["style", "color: coral;font-size: 1.5em;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, [" \u00A0", " "]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.name; _ck(_v, 3, 0, currVal_0); }); }
function View_DocumentsSearchResultsComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_DocumentsSearchResultsComponent_6)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DocumentsSearchResultsComponent_7)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DocumentsSearchResultsComponent_8)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DocumentsSearchResultsComponent_9)), i0.ɵdid(7, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = (((_v.context.$implicit == null) ? null : _v.context.$implicit.documentFormat) && (((_v.context.$implicit == null) ? null : _v.context.$implicit.documentFormat) == "pdf")); _ck(_v, 1, 0, currVal_0); var currVal_1 = (((_v.context.$implicit == null) ? null : _v.context.$implicit.documentFormat) && (((_v.context.$implicit == null) ? null : _v.context.$implicit.documentFormat) == "excel")); _ck(_v, 3, 0, currVal_1); var currVal_2 = (((_v.context.$implicit == null) ? null : _v.context.$implicit.documentFormat) && (((_v.context.$implicit == null) ? null : _v.context.$implicit.documentFormat) == "csv")); _ck(_v, 5, 0, currVal_2); var currVal_3 = (((_v.context.$implicit == null) ? null : _v.context.$implicit.documentFormat) && (((_v.context.$implicit == null) ? null : _v.context.$implicit.documentFormat) == "html")); _ck(_v, 7, 0, currVal_3); }, null); }
function View_DocumentsSearchResultsComponent_11(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "span", [["class", ""]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-lg fa-cog no-color--state"]], null, null, null, null, null))], null, null); }
function View_DocumentsSearchResultsComponent_12(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "span", [["class", ""]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-lg fa-user-o no-color--state"]], null, null, null, null, null))], null, null); }
function View_DocumentsSearchResultsComponent_10(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_DocumentsSearchResultsComponent_11)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DocumentsSearchResultsComponent_12)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(4, null, [" \u00A0", "\n"])), i0.ɵppd(5, 2)], function (_ck, _v) { var currVal_0 = (_v.context.$implicit && ((_v.context.$implicit == null) ? null : _v.context.$implicit.systemGenerated)); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_v.context.$implicit && !_v.context.$implicit.systemGenerated); _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var currVal_2 = (_v.context.$implicit ? i0.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i0.ɵnov(_v.parent, 0), _v.context.$implicit.generatedOn, "yyyy-MM-dd HH:mm a")) : ""); _ck(_v, 4, 0, currVal_2); }); }
function View_DocumentsSearchResultsComponent_14(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "tc-w-100 text-center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "span", [["class", ""]], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, [" ", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getDisplayDispatchEntry(_v.parent.context.$implicit.recentDispatchEntry.dispatchStatus); _ck(_v, 3, 0, currVal_0); }); }
function View_DocumentsSearchResultsComponent_15(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 6, "div", [["class", "tc-ag-grid-cell tc-ag-grid-cell--inactive"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 5, "div", [["class", "tc-w-100 text-center"]], null, null, null, null, null)), i0.ɵdid(3, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(4, { "": 0 }), (_l()(), i0.ɵeld(5, 0, null, null, 2, "span", [["class", ""]], null, null, null, null, null)), (_l()(), i0.ɵted(6, null, [" ", ""])), i0.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i0.ChangeDetectorRef])], function (_ck, _v) { var currVal_0 = "tc-w-100 text-center"; var currVal_1 = _ck(_v, 4, 0, true); _ck(_v, 3, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = i0.ɵunv(_v, 6, 0, i0.ɵnov(_v, 7).transform("NOT DISPATCHED")); _ck(_v, 6, 0, currVal_2); }); }
function View_DocumentsSearchResultsComponent_13(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_DocumentsSearchResultsComponent_14)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DocumentsSearchResultsComponent_15)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = (((_v.context.$implicit == null) ? null : _v.context.$implicit.recentDispatchEntry) && _v.context.$implicit.recentDispatchEntry.dispatchStatus); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_v.context.$implicit && (_v.context.$implicit.recentDispatchEntry === null)); _ck(_v, 3, 0, currVal_1); }, null); }
export function View_DocumentsSearchResultsComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i1.DatePipe, [i0.LOCALE_ID]), i0.ɵqud(402653184, 1, { setupGrid: 0 }), i0.ɵqud(402653184, 2, { statusCell: 0 }), i0.ɵqud(402653184, 3, { nameFormatCell: 0 }), i0.ɵqud(402653184, 4, { formatCell: 0 }), i0.ɵqud(402653184, 5, { generatedByCell: 0 }), (_l()(), i0.ɵeld(6, 0, null, null, 1, "tc-setup-grid", [], null, [[null, "rowDoubleClicked"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("rowDoubleClicked" === en)) {
        var pd_0 = (_co.onRowDoubleClicked($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_SetupGridComponent_0, i3.RenderType_SetupGridComponent)), i0.ɵdid(7, 638976, [[1, 4], ["docSetupGrid", 4]], 0, i4.SetupGridComponent, [i0.ChangeDetectorRef, i5.GridColumnDefinitionProcessorService, i2.TranslateService, i6.DialogService, i7.DataStoreService, i8.DMCCommon], { readOnly: [0, "readOnly"], actions: [1, "actions"], noSelection: [2, "noSelection"], dataSource: [3, "dataSource"], setupGridComp: [4, "setupGridComp"], colDefConfig: [5, "colDefConfig"] }, { rowDoubleClicked: "rowDoubleClicked" }), (_l()(), i0.ɵand(0, [[4, 2], ["formatCell", 2]], null, 0, null, View_DocumentsSearchResultsComponent_1)), (_l()(), i0.ɵand(0, [[3, 2], ["nameFormatCell", 2]], null, 0, null, View_DocumentsSearchResultsComponent_5)), (_l()(), i0.ɵand(0, [[5, 2], ["generatedByCell", 2]], null, 0, null, View_DocumentsSearchResultsComponent_10)), (_l()(), i0.ɵand(0, [[2, 2], ["statusCell", 2]], null, 0, null, View_DocumentsSearchResultsComponent_13))], function (_ck, _v) { var _co = _v.component; var currVal_0 = true; var currVal_1 = _co.gridActions; var currVal_2 = true; var currVal_3 = _co; var currVal_4 = _co; var currVal_5 = _co.colDefConfig; _ck(_v, 7, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
export function View_DocumentsSearchResultsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-documents-search-results", [], null, null, null, View_DocumentsSearchResultsComponent_0, RenderType_DocumentsSearchResultsComponent)), i0.ɵdid(1, 245760, null, 0, i9.DocumentsSearchResultsComponent, [i10.ConfigLoader, i7.DataStoreService, i11.DataHandlerService, i12.DocumentService, i13.ActivatedRoute, i14.FocusViewService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DocumentsSearchResultsComponentNgFactory = i0.ɵccf("tc-documents-search-results", i9.DocumentsSearchResultsComponent, View_DocumentsSearchResultsComponent_Host_0, { colDefConfig: "colDefConfig" }, {}, []);
export { DocumentsSearchResultsComponentNgFactory as DocumentsSearchResultsComponentNgFactory };
