import { Component, OnDestroy, OnInit } from '@angular/core';
import { SpinnerService } from '@tc-core/util/ui/spinner.service';
import { Subscription } from 'rxjs';
import { LoaderState } from '@tc-core/model/it/codegen/ui/framework/loader-state';
import { MatSnackBar } from '@angular/material';
import { SnackBarComponent } from '@tc/snack-bar';
import { ErrorView } from '@tc-core/model/it/codegen/ui/error-view';
import { DataKey, DataStoreService } from '../../../services/util/framework/data-store.service';

@Component({
  selector: 'tc-dmc-spinner-wrapper',
  templateUrl: './dmc-spinner-wrapper.component.html'
})
export class DMCSpinnerWrapperComponent implements OnInit, OnDestroy {

  private subscription: Subscription;
  show = false;
  snackBarRef = null;

  constructor(
      private spinnerService: SpinnerService,
      private dataStore: DataStoreService,
      public snackBar: MatSnackBar
  ) {}

  ngOnInit() {

    this.subscription = this.spinnerService.loaderState
                            .subscribe((state: LoaderState) => {
                              this.show = state.show;
                            });

    this.dataStore.get(DataKey.error).subscribe(data => {
      if (data && JSON.stringify(data) !== JSON.stringify({})) {

        let errorView: ErrorView = <ErrorView> data;

        this.snackBarRef = this.snackBar.openFromComponent(SnackBarComponent, {
          data: errorView,
          duration: errorView.dismissDuration
        });

        this.spinnerService.hide();

        this.dataStore.set(DataKey.error, null);
      } else if (JSON.stringify(data) === JSON.stringify({})) {
        /*if (this.snackBarRef) {
          this.snackBarRef.dismiss();
        }*/
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
