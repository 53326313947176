<div class="">
  <mat-tab-group [selectedIndex]="selectedIndex">
    <mat-tab label="Pick-up">
      <ul>
        <li *ngFor="let pickup of pickUps">
          <td class="example-margin">{{pickup}}</td>
        </li>
      </ul>
    </mat-tab>
    <mat-tab label="Drop-off">
      <ul>
        <li *ngFor="let dropOff of dropOffs">
          <td class="example-margin">{{dropOff}}</td>
        </li>
      </ul>
    </mat-tab>
  </mat-tab-group>
</div>
<div class="payment__actions">
  <button class="button-right tc-button-primary mat-button" (click)="onClosePopUp($event)">
    {{'Close' | translate}}
  </button>
</div>
