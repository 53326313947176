/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../widgets/framework/card-strip/card-strip.component.ngfactory";
import * as i2 from "../../widgets/framework/card-strip/card-strip.component";
import * as i3 from "@ngx-translate/core";
import * as i4 from "@angular/common";
import * as i5 from "./leave-setup-resource-summary-view.component";
import * as i6 from "@tc-core/util/helpers/common-helper.service";
var styles_LeaveSetupResourceSummaryViewComponent = [];
var RenderType_LeaveSetupResourceSummaryViewComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_LeaveSetupResourceSummaryViewComponent, data: {} });
export { RenderType_LeaveSetupResourceSummaryViewComponent as RenderType_LeaveSetupResourceSummaryViewComponent };
function View_LeaveSetupResourceSummaryViewComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "tc-border-list__repeat-wrapper"]], [[24, "@items", 0]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "tc-card-strip", [["class", "tc-card-strip__temp-border"]], [[8, "id", 0]], null, null, i1.View_CardStripComponent_0, i1.RenderType_CardStripComponent)), i0.ɵdid(2, 638976, null, 0, i2.CardStripComponent, [i0.Injector], { type: [0, "type"], cardData: [1, "cardData"], checked: [2, "checked"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.cardType; var currVal_3 = _v.context.$implicit; var currVal_4 = _co.isSelected(_v.context.$implicit); _ck(_v, 2, 0, currVal_2, currVal_3, currVal_4); }, function (_ck, _v) { var currVal_0 = undefined; _ck(_v, 0, 0, currVal_0); var currVal_1 = ("RESULT_INDEX_" + _v.context.index); _ck(_v, 1, 0, currVal_1); }); }
function View_LeaveSetupResourceSummaryViewComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "div", [["class", "trade-client-detail-view"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 9, "div", [["class", "detail-group"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 5, "div", [["class", " detail-group__heading tc-results-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 4, "div", [["class", "tc-item-wrapper"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 3, "div", [["class", "tc-item-header tc-flex-box"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 2, "div", [["class", "tc-flex-item tc-item-header__primary"]], null, null, null, null, null)), (_l()(), i0.ɵted(6, null, ["", ""])), i0.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(8, 0, null, null, 2, "div", [["class", "tc-border-list"]], [[8, "id", 0], [24, "@list", 0]], null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_LeaveSetupResourceSummaryViewComponent_2)), i0.ɵdid(10, 278528, null, 0, i4.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.selectedResources; _ck(_v, 10, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 6, 0, i0.ɵnov(_v, 7).transform("Selected Resources")); _ck(_v, 6, 0, currVal_0); var currVal_1 = "RESULT_CONTAINER"; var currVal_2 = undefined; _ck(_v, 8, 0, currVal_1, currVal_2); }); }
export function View_LeaveSetupResourceSummaryViewComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_LeaveSetupResourceSummaryViewComponent_1)), i0.ɵdid(1, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.selectedResources && (_co.selectedResources.length > 0)); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_LeaveSetupResourceSummaryViewComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-leave-setup-resource-summary-view", [], null, null, null, View_LeaveSetupResourceSummaryViewComponent_0, RenderType_LeaveSetupResourceSummaryViewComponent)), i0.ɵdid(1, 114688, null, 0, i5.LeaveSetupResourceSummaryViewComponent, [i6.CommonHelper], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LeaveSetupResourceSummaryViewComponentNgFactory = i0.ɵccf("tc-leave-setup-resource-summary-view", i5.LeaveSetupResourceSummaryViewComponent, View_LeaveSetupResourceSummaryViewComponent_Host_0, { selectedResources: "selectedResources", selectedResourceIds: "selectedResourceIds" }, {}, []);
export { LeaveSetupResourceSummaryViewComponentNgFactory as LeaveSetupResourceSummaryViewComponentNgFactory };
