import { Component, Input, OnInit } from '@angular/core';
import { TC } from '@tc-core/util/constants';

@Component({
  selector: 'tc-service-item-focus-view-flight-info',
  templateUrl: './service-item-focus-view-flight-info.component.html'
})
export class ServiceItemFocusViewFlightInfoComponent implements OnInit {
    @Input() summaryInfo: any;
    EMPTY_CHAR = TC.FORMAT.EMPTY_CHAR;
  constructor() { }

  ngOnInit() {
  }

}
