/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../widgets/shared/room-add-card/room-add-card.component.ngfactory";
import * as i2 from "../../../widgets/shared/room-add-card/room-add-card.component";
import * as i3 from "@tc-core/util/helpers/common-helper.service";
import * as i4 from "./add-rooms.component";
import * as i5 from "../../../services/util/framework/data-store.service";
var styles_AddRoomsComponent = [];
var RenderType_AddRoomsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AddRoomsComponent, data: {} });
export { RenderType_AddRoomsComponent as RenderType_AddRoomsComponent };
export function View_AddRoomsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-room-add-card", [], null, [[null, "closeModal"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("closeModal" === en)) {
        var pd_0 = (_co.closeModal($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_RoomAddCardComponent_0, i1.RenderType_RoomAddCardComponent)), i0.ɵdid(1, 114688, null, 0, i2.RoomAddCardComponent, [i3.CommonHelper], { roomTypeOptions: [0, "roomTypeOptions"], selectedRooms: [1, "selectedRooms"] }, { closeModal: "closeModal" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.roomTypeOptions; var currVal_1 = _co.selectedRooms; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_AddRoomsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-add-rooms", [], null, null, null, View_AddRoomsComponent_0, RenderType_AddRoomsComponent)), i0.ɵdid(1, 114688, null, 0, i4.AddRoomsComponent, [i5.DataStoreService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AddRoomsComponentNgFactory = i0.ɵccf("tc-add-rooms", i4.AddRoomsComponent, View_AddRoomsComponent_Host_0, { selectedRooms: "selectedRooms", roomTypeOptions: "roomTypeOptions" }, {}, []);
export { AddRoomsComponentNgFactory as AddRoomsComponentNgFactory };
