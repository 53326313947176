import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/map';
import { Language } from '../../../models/common/language';
import { Resource } from '../../../models/resource/resource';
import { OPResponseWrapper } from '../../../models/response/op-response-wrapper';
import { DMCQueryParamsService } from '../../util/framework/dmc-query-params.service';
import { RequestService } from '../../util/framework/request.service';
import { UrlPaths } from '../url-paths';

@Injectable()
export class ProfileManagementService {

    mockURL = 'assets/mockdata/';

    constructor(
        private queryParamsService: DMCQueryParamsService,
        private requestService: RequestService
    ) {
    }

    public retrieveSupplierAttributes(): Observable<any[]> {
        return this.requestService.getByUrl<any[]>(this.mockURL, this.mockURL + 'supplierAttributes.json');
    }

    public retrieveDefaultLanguages(): Observable<Language[]> {
        return this.requestService.getByUrl<Language[]>(this.mockURL, this.mockURL + 'defaultLanguages.json');
    }

    public retrieveAdditionalLanguages(): Observable<Language[]> {
        return this.requestService.getByUrl<Language[]>(this.mockURL, this.mockURL + 'additionalLanguages.json');
    }

    saveResource(resource: Resource): Observable<OPResponseWrapper<Resource>> {
        return this.requestService.post(
            UrlPaths.OP_SUP_URL_KEY,
            [UrlPaths.OP_SUP_VERSION, UrlPaths.RESOURCES],
            resource
        );
    }

    updateResource(resource: Resource, resourceId: string): Observable<OPResponseWrapper<Resource>> {
        return this.requestService.put(
            UrlPaths.OP_SUP_URL_KEY,
            [UrlPaths.OP_SUP_VERSION, UrlPaths.RESOURCES, resourceId.toString()],
            resource
        );
    }

}
