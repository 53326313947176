<div class="card-strip multi-line-strip tc-strip tc-border-list__item">
  <div class="row" (click)="onCardClick()">

    <div class="tc-flex tc-flex--center-vertical tc-strip-col-1" [ngClass]="gridPattern[0]">
      <div class="tc-flex-item icon-cage tc-strip-col-1__circle" [ngSwitch]="type">
        <div class="icon-txt" *ngSwitchCase="'icon'">
          <tc-resource
            *ngIf="cardData?.icon?.value"
            [id]="cardData?.icon?.key"
            [key]="cardData?.icon?.value?.key"
            [value]="cardData?.icon?.value?.value"
            [style]="cardData?.icon?.value?.style"
            [iconType]="cardData?.icon?.value?.iconType"
            [badge]="cardData?.icon?.value?.badge">
          </tc-resource>
        </div>

        <div class="mls-col-1__checkbox" *ngSwitchCase="'checkbox'">
          <mat-checkbox
          [checked]="checked"
          (change)="onCheckboxSelect($event)">
          </mat-checkbox>
        </div>
      </div>

      <div class="tc-strip-col-1__text" style="min-width: 0">
        <div class="tc-strip__primary" style="word-break: break-all" *ngIf="cardData?.row1col1?.value" [id]="cardData?.row1col1?.key">
          {{cardData?.row1col1?.value}}
        </div>

        <div class="tc-strip__secondary" style="word-break: break-all" *ngIf="cardData?.row2col1?.value" [id]="cardData?.row2col1?.key">
          {{cardData?.row2col1?.value}}
        </div>
      </div>
    </div>

    <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4" [ngClass]="">
      <div class="tc-strip__secondary" *ngIf="cardData?.row1col2?.value" [id]="cardData?.row1col2?.key">
        {{cardData?.row1col2?.value}}
      </div>

      <div class="tc-strip__secondary" *ngIf="cardData?.row2col2?.value" [id]="cardData?.row2col2?.key">
        {{cardData?.row2col2?.value}}
      </div>
    </div>

    <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4" [ngClass]="">
      <div class="tc-strip__secondary" *ngIf="cardData?.row1col3?.value" [id]="cardData?.row1col3?.key">
        {{cardData?.row1col3?.value}}
      </div>

      <div class="tc-strip__secondary" *ngIf="cardData?.row2col3?.value" [id]="cardData?.row2col3?.key">
        {{cardData?.row2col3?.value}}
      </div>
    </div>

    <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4">
      <div class="tc-strip__secondary" *ngIf="cardData?.row1col3?.value" [id]="cardData?.row1col3?.key">
        {{cardData?.row1col4?.value}}
      </div>

      <div class="tc-strip__secondary" *ngIf="cardData?.row2col3?.value" [id]="cardData?.row2col3?.key">
        {{cardData?.row2col4?.value}}
      </div>
    </div>
  </div>
</div>

