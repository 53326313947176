/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid.component.ngfactory";
import * as i3 from "../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid.component";
import * as i4 from "../../../services/util/pre-processors/grid-column-definition-processor.service";
import * as i5 from "@ngx-translate/core";
import * as i6 from "@tc/dialog/dialog.service";
import * as i7 from "../../../services/util/framework/data-store.service";
import * as i8 from "../../../services/util/common/dmc-common";
import * as i9 from "./document-queue-results.component";
import * as i10 from "@tc-core/util/framework/config-loader.service";
import * as i11 from "../../../services/backend-consumers/master-data-handler-service/data-handler.service";
import * as i12 from "../../../services/backend-consumers/documents/document-queue.service";
import * as i13 from "@angular/router";
import * as i14 from "@tc/focus-view/focus-view.service";
import * as i15 from "../../../services/util/pre-processors/setup-common-data-processor.service";
import * as i16 from "@tc-core/util/ui/spinner.service";
import * as i17 from "../../../services/util/change-detector/background-task.service";
var styles_DocumentQueueResultsComponent = [];
var RenderType_DocumentQueueResultsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DocumentQueueResultsComponent, data: {} });
export { RenderType_DocumentQueueResultsComponent as RenderType_DocumentQueueResultsComponent };
function View_DocumentQueueResultsComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "tc-w-100 text-center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "span", [["class", ""]], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, [" ", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.dispatchStatus; _ck(_v, 3, 0, currVal_0); }); }
function View_DocumentQueueResultsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_DocumentQueueResultsComponent_2)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = ((_v.context.$implicit == null) ? null : _v.context.$implicit.dispatchStatus); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_DocumentQueueResultsComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { setupGrid: 0 }), i0.ɵqud(402653184, 2, { statusCell: 0 }), i0.ɵqud(402653184, 3, { nameFormatCell: 0 }), i0.ɵqud(402653184, 4, { formatCell: 0 }), i0.ɵqud(402653184, 5, { generatedByCell: 0 }), i0.ɵqud(402653184, 6, { checkBoxDisabledCell: 0 }), (_l()(), i0.ɵeld(6, 0, null, null, 1, "tc-setup-grid", [], null, [[null, "bulkSendClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("bulkSendClick" === en)) {
        var pd_0 = (_co.onBulkSendClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_SetupGridComponent_0, i2.RenderType_SetupGridComponent)), i0.ɵdid(7, 638976, [[1, 4], ["setupGrid", 4]], 0, i3.SetupGridComponent, [i0.ChangeDetectorRef, i4.GridColumnDefinitionProcessorService, i5.TranslateService, i6.DialogService, i7.DataStoreService, i8.DMCCommon], { readOnly: [0, "readOnly"], enableBulkSendButton: [1, "enableBulkSendButton"], kebabMenus: [2, "kebabMenus"], noSelection: [3, "noSelection"], dataSource: [4, "dataSource"], setupGridComp: [5, "setupGridComp"], colDefConfig: [6, "colDefConfig"] }, { bulkSendClick: "bulkSendClick" }), (_l()(), i0.ɵand(0, [[2, 2], ["statusCell", 2]], null, 0, null, View_DocumentQueueResultsComponent_1))], function (_ck, _v) { var _co = _v.component; var currVal_0 = true; var currVal_1 = true; var currVal_2 = _co.gridActions; var currVal_3 = true; var currVal_4 = _co; var currVal_5 = _co; var currVal_6 = _co.colDefConfig; _ck(_v, 7, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
export function View_DocumentQueueResultsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-document-queue-results", [], null, null, null, View_DocumentQueueResultsComponent_0, RenderType_DocumentQueueResultsComponent)), i0.ɵdid(1, 245760, null, 0, i9.DocumentQueueResultsComponent, [i10.ConfigLoader, i7.DataStoreService, i11.DataHandlerService, i12.DocumentQueueService, i13.ActivatedRoute, i14.FocusViewService, i15.SetupCommonDataProcessorService, i16.SpinnerService, i6.DialogService, i8.DMCCommon, i17.BackgroundTaskService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DocumentQueueResultsComponentNgFactory = i0.ɵccf("tc-document-queue-results", i9.DocumentQueueResultsComponent, View_DocumentQueueResultsComponent_Host_0, {}, {}, []);
export { DocumentQueueResultsComponentNgFactory as DocumentQueueResultsComponentNgFactory };
