export class BookingNote {
    note: string;
    type: BookingNoteType;
    typeString: string;
    createdOn: Date;
    bookingItemId: number;
    itemNoteType: BookingItemNoteType;

}

export enum BookingNoteType {
    SUPPLIER = 'SUPPLIER',
    INVOICE = 'INVOICE',
    TICKETING = 'TICKETING',
    BOOKING = 'BOOKING',
    ITINERARY = 'ITINERARY',
    VOUCHER = 'VOUCHER',
    TRANSFER_INFO = 'TRANSFER_INFO',
    OSI = 'OSI',
    OTHER = 'OTHER'
}

export enum BookingItemNoteType {
    BOOKING = 'BOOKING',
    BOOKING_ITEM = 'BOOKING_ITEM'
}

