/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../node_modules/@tc/section-heading/section-heading.component.ngfactory";
import * as i2 from "@tc/section-heading/section-heading.component";
import * as i3 from "@tc-core/util/framework/user-journey-manager.service";
import * as i4 from "../../../../../../node_modules/ag-grid-angular/dist/agGridAngular.ngfactory";
import * as i5 from "ag-grid-angular/dist/angularFrameworkOverrides";
import * as i6 from "ag-grid-angular/dist/angularFrameworkComponentWrapper";
import * as i7 from "ag-grid-angular/dist/agGridAngular";
import * as i8 from "@ngx-translate/core";
import * as i9 from "./occupancy-setup.component";
import * as i10 from "../../../../services/util/framework/data-store.service";
import * as i11 from "../../../../services/util/ui/card-data-handler-service.service";
import * as i12 from "../../../../services/backend-consumers/supplier-service/supplier.service";
import * as i13 from "@tc/dialog/dialog.service";
var styles_OccupancySetupComponent = [];
var RenderType_OccupancySetupComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_OccupancySetupComponent, data: {} });
export { RenderType_OccupancySetupComponent as RenderType_OccupancySetupComponent };
export function View_OccupancySetupComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "div", [["class", "grid-focus-view"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "tc-px-2"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "tc-section-heading", [], null, [[null, "actionClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("actionClick" === en)) {
        var pd_0 = (_co.onHeaderActionClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_SectionHeadingComponent_0, i1.RenderType_SectionHeadingComponent)), i0.ɵdid(3, 638976, null, 0, i2.SectionHeadingComponent, [i3.UserJourneyManager], { pageSection: [0, "pageSection"] }, { actionClick: "actionClick" }), (_l()(), i0.ɵeld(4, 16777216, null, null, 4, "ag-grid-angular", [["class", "ag-theme-material tc-ag-grid tc-ag-grid--full-width tc-ag-grid--auto-height tc-ag-grid--height-full"], ["rowSelection", "multiple"], ["singleClickEdit", "true"], ["style", "height: 400px"]], null, [[null, "gridReady"], [null, "selectionChanged"], [null, "cellValueChanged"], [null, "rowValueChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("gridReady" === en)) {
        var pd_0 = (_co.onGridReady($event) !== false);
        ad = (pd_0 && ad);
    } if (("selectionChanged" === en)) {
        var pd_1 = (_co.onSelectionChanged($event) !== false);
        ad = (pd_1 && ad);
    } if (("cellValueChanged" === en)) {
        var pd_2 = (_co.onCellValueChanged($event) !== false);
        ad = (pd_2 && ad);
    } if (("rowValueChanged" === en)) {
        var pd_3 = (_co.validateUniqueValues($event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, i4.View_AgGridAngular_0, i4.RenderType_AgGridAngular)), i0.ɵprd(512, null, i5.AngularFrameworkOverrides, i5.AngularFrameworkOverrides, [i0.NgZone]), i0.ɵprd(512, null, i6.AngularFrameworkComponentWrapper, i6.AngularFrameworkComponentWrapper, []), i0.ɵdid(7, 4898816, null, 1, i7.AgGridAngular, [i0.ElementRef, i0.ViewContainerRef, i5.AngularFrameworkOverrides, i6.AngularFrameworkComponentWrapper, i0.ComponentFactoryResolver], { rowData: [0, "rowData"], columnDefs: [1, "columnDefs"], frameworkComponents: [2, "frameworkComponents"], defaultColDef: [3, "defaultColDef"], rowClassRules: [4, "rowClassRules"], rowSelection: [5, "rowSelection"], singleClickEdit: [6, "singleClickEdit"], pagination: [7, "pagination"], paginationAutoPageSize: [8, "paginationAutoPageSize"] }, { cellValueChanged: "cellValueChanged", rowValueChanged: "rowValueChanged", selectionChanged: "selectionChanged", gridReady: "gridReady" }), i0.ɵqud(603979776, 1, { columns: 1 }), (_l()(), i0.ɵeld(9, 0, null, null, 6, "div", [["class", "container assign-panel-footer-section"]], null, null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 2, "button", [["class", "assign-panel-footer-section__button tc-button-secondary mat-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClose($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(11, null, [" ", " "])), i0.ɵpid(131072, i8.TranslatePipe, [i8.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(13, 0, null, null, 2, "button", [["class", "assign-panel-footer-section__button tc-button-primary mat-button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSave() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(14, null, [" ", " "])), i0.ɵpid(131072, i8.TranslatePipe, [i8.TranslateService, i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.occupancyHeaderSection; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.occupancies; var currVal_2 = _co.columnDefs; var currVal_3 = _co.frameworkComponents; var currVal_4 = _co.defaultColDef; var currVal_5 = _co.rowClassRules; var currVal_6 = "multiple"; var currVal_7 = "true"; var currVal_8 = true; var currVal_9 = true; _ck(_v, 7, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); }, function (_ck, _v) { var _co = _v.component; var currVal_10 = i0.ɵunv(_v, 11, 0, i0.ɵnov(_v, 12).transform("Close")); _ck(_v, 11, 0, currVal_10); var currVal_11 = !_co.isSaveButtonEnable(); _ck(_v, 13, 0, currVal_11); var currVal_12 = i0.ɵunv(_v, 14, 0, i0.ɵnov(_v, 15).transform("Save")); _ck(_v, 14, 0, currVal_12); }); }
export function View_OccupancySetupComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-occupancy-setup", [], null, null, null, View_OccupancySetupComponent_0, RenderType_OccupancySetupComponent)), i0.ɵdid(1, 114688, null, 0, i9.OccupancySetupComponent, [i10.DataStoreService, i11.CardDataHandlerService, i12.SupplierService, i13.DialogService, i8.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var OccupancySetupComponentNgFactory = i0.ɵccf("tc-occupancy-setup", i9.OccupancySetupComponent, View_OccupancySetupComponent_Host_0, { roomGroup: "roomGroup" }, {}, []);
export { OccupancySetupComponentNgFactory as OccupancySetupComponentNgFactory };
