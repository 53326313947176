

<div class="">
  <div class="example-header">
  </div>

  <mat-table #table [dataSource]="dataSource" class="">
    <ng-container *ngFor="let col of columnsArray; let i = index">
      <!-- Column -->
      <ng-container matColumnDef={{col}}>
        <mat-header-cell *matHeaderCellDef  class=""> {{columnsArray.length - 1 !== i ? columnNamesArray[i].name : ''}} </mat-header-cell>
        <!--[ngClass]="columnsArray.length - 1 !== i ? '': 'sticky-action-cell&#45;&#45;stick'"-->
        <mat-cell *matCellDef="let element; let j = index;" class="" >
          <mat-form-field floatLabel="never" *ngIf="columnsArray.length - 1 !== i && (j === clickedRow || checkUnsaved(j))">
            <!-- Input -->
            <input matInput placeholder="" *ngIf="columnNamesArray[i].type === 'INPUT'" [value]="element[col]" [(ngModel)]="element[col]">
            <!-- Drop down -->
            <mat-select *ngIf="columnNamesArray[i].type === 'SELECT'" [(value)]="element[col]" placeholder="">
              <mat-option *ngFor="let option of columnNamesArray[i].options" [value]="option">
                {{option}}
              </mat-option>
            </mat-select>
            <!-- Multi Select -->
            <mat-select *ngIf="columnNamesArray[i].type === 'MULTI_SELECT'" [(ngModel)]="element[col]" placeholder="" multiple>
              <mat-option *ngFor="let option of columnNamesArray[i].options" [value]="option.code">
                {{option.name}}
              </mat-option>
            </mat-select>
            <!-- Check box -->
            <mat-checkbox *ngIf="columnNamesArray[i].type === 'CHECK_BOX'" [(ngModel)]="element[col]" class=""></mat-checkbox>
          </mat-form-field>
          <div *ngIf="j !== clickedRow && !checkUnsaved(j)">{{element[col]}}</div>
          <!--<div *ngIf="showDeleteIcon || showEditIcon || showSaveIcon" class="sticky-action-cell__actions">-->
            <!--<i class="material-icons  tc-icon-block__icon&#45;&#45;active" *ngIf="columnsArray.length-1 === i && j !== clickedRow && !checkUnsaved(j) && showEditIcon" (click)="onEditClick(j)">edit</i>-->
            <!--&lt;!&ndash;<i class="material-icons  tc-icon-block__icon&#45;&#45;active" *ngIf="columnsArray.length-1 === i && (j === clickedRow || checkUnsaved(j))" (click)="onRevertClick(j)">undo</i>&ndash;&gt;-->
            <!--<i class="material-icons  tc-icon-block__icon&#45;&#45;active" *ngIf="columnsArray.length-1 === i &&  (j === clickedRow || checkUnsaved(j))" (click)="onSaveClick(j)">save</i>-->
            <!--<i class="material-icons  tc-icon-block__icon&#45;&#45;active" *ngIf="columnsArray.length-1 === i && showDeleteIcon" (click)="onDeleteClick(j)">delete</i>-->
          <!--</div>-->

        </mat-cell>
      </ng-container>
    </ng-container>

    <mat-header-row *matHeaderRowDef="columnsArray; sticky: true" class=""></mat-header-row>
    <div class="tc-mat-data-grid__body">
      <mat-row *matRowDef="let row; columns: columnsArray;" class=""></mat-row>
    </div>
  </mat-table>

</div>







LOCATIONS-SETUP

































<!--<div class="example-container mat-elevation-z8">-->
<!--<div class="example-header">-->
<!--&lt;!&ndash;<mat-form-field>&ndash;&gt;-->
<!--&lt;!&ndash;<input matInput #filter (keyup)="applyFilter($event.target.value)" placeholder="Filter">&ndash;&gt;-->
<!--&lt;!&ndash;<button mat-icon-button matSuffix aria-label="clear" *ngIf="filter.value" (click)="filter.value=''; applyFilter('');">&ndash;&gt;-->
<!--&lt;!&ndash;<mat-icon>close</mat-icon>&ndash;&gt;-->
<!--&lt;!&ndash;</button>&ndash;&gt;-->
<!--&lt;!&ndash;</mat-form-field>&ndash;&gt;-->
<!--&lt;!&ndash;<mat-form-field>&ndash;&gt;-->
<!--&lt;!&ndash;<input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">&ndash;&gt;-->
<!--&lt;!&ndash;</mat-form-field>&ndash;&gt;-->
<!--</div>-->

<!--<mat-table #table [dataSource]="dataSource">-->
<!--<ng-container *ngFor="let col of columnsArray; let i = index">-->
<!--&lt;!&ndash; Position Column &ndash;&gt;-->
<!--<ng-container matColumnDef={{col}}>-->
<!--<mat-header-cell *matHeaderCellDef > {{columnsArray.length - 1 !== i ? columnNamesArray[i] : ''}} </mat-header-cell>-->
<!--<mat-cell *matCellDef="let element; let j = index;">-->
<!--<mat-form-field floatLabel="never" *ngIf="columnsArray.length - 1 !== i" >-->
<!--<input matInput placeholder="" [value]="element[col]" [(ngModel)]="element[col]">-->
<!--</mat-form-field>-->
<!--<span><i class="material-icons tc-icon-block__icon tc-icon-block__icon&#45;&#45;active" *ngIf="columnsArray.length-1 === i" (click)="onSaveClick($event)">save</i></span>-->
<!--<span><i class="material-icons tc-icon-block__icon tc-icon-block__icon&#45;&#45;active" *ngIf="columnsArray.length-1 === i" (click)="onDeleteClick(j)">delete</i></span>-->
<!--</mat-cell>-->
<!--</ng-container>-->
<!--</ng-container>-->
<!--&lt;!&ndash; Position Column &ndash;&gt;-->
<!--&lt;!&ndash;<ng-container matColumnDef="save">&ndash;&gt;-->
<!--&lt;!&ndash;<mat-header-cell *matHeaderCellDef mat-sort-header>  </mat-header-cell>&ndash;&gt;-->
<!--&lt;!&ndash;<mat-cell *matCellDef="let element">&ndash;&gt;-->
<!--&lt;!&ndash;<span><i class="material-icons tc-icon-block__icon tc-icon-block__icon&#45;&#45;active"(click)="onSaveClick($event)">save</i></span>&ndash;&gt;-->
<!--&lt;!&ndash;<span><i class="material-icons tc-icon-block__icon tc-icon-block__icon&#45;&#45;active" (click)="onDeleteClick($event)">delete</i></span>&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;<span class="tc-icon-block__icon tc-icon-block__icon&#45;&#45;active">&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;<i class="material-icons"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;(click)="onDelete($event)">delete</i>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;</span>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;</mat-cell>&ndash;&gt;-->
<!--&lt;!&ndash;</ng-container>&ndash;&gt;-->

<!--&lt;!&ndash; Name Column &ndash;&gt;-->
<!--&lt;!&ndash;<ng-container matColumnDef="col2">&ndash;&gt;-->
<!--&lt;!&ndash;<mat-header-cell *matHeaderCellDef mat-sort-header> Country </mat-header-cell>&ndash;&gt;-->
<!--&lt;!&ndash;<mat-cell *matCellDef="let element">&ndash;&gt;-->
<!--&lt;!&ndash;<mat-form-field floatLabel="never">&ndash;&gt;-->
<!--&lt;!&ndash;<input matInput placeholder="" [value]="element.col2" [(ngModel)]="element.col2">&ndash;&gt;-->
<!--&lt;!&ndash;</mat-form-field>&ndash;&gt;-->
<!--&lt;!&ndash;</mat-cell>&ndash;&gt;-->
<!--&lt;!&ndash;</ng-container>&ndash;&gt;-->

<!--&lt;!&ndash; Weight Column &ndash;&gt;-->
<!--&lt;!&ndash;<ng-container matColumnDef="col3">&ndash;&gt;-->
<!--&lt;!&ndash;<mat-header-cell *matHeaderCellDef mat-sort-header> Region </mat-header-cell>&ndash;&gt;-->
<!--&lt;!&ndash;<mat-cell *matCellDef="let element">&ndash;&gt;-->
<!--&lt;!&ndash;<mat-form-field floatLabel="never">&ndash;&gt;-->
<!--&lt;!&ndash;<input matInput placeholder="" [value]="element.col3" [(ngModel)]="element.col3">&ndash;&gt;-->
<!--&lt;!&ndash;</mat-form-field>&ndash;&gt;-->
<!--&lt;!&ndash;</mat-cell>&ndash;&gt;-->
<!--&lt;!&ndash;</ng-container>&ndash;&gt;-->

<!--&lt;!&ndash; Symbol Column &ndash;&gt;-->
<!--&lt;!&ndash;<ng-container matColumnDef="col4">&ndash;&gt;-->
<!--&lt;!&ndash;<mat-header-cell *matHeaderCellDef mat-sort-header> Type </mat-header-cell>&ndash;&gt;-->
<!--&lt;!&ndash;<mat-cell *matCellDef="let element">&ndash;&gt;-->
<!--&lt;!&ndash;<mat-form-field floatLabel="never">&ndash;&gt;-->
<!--&lt;!&ndash;<input matInput placeholder="" [value]="element.col4" [(ngModel)]="element.col4">&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;<mat-checkbox></mat-checkbox>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;</mat-form-field>&ndash;&gt;-->
<!--&lt;!&ndash;</mat-cell>&ndash;&gt;-->
<!--&lt;!&ndash;</ng-container>&ndash;&gt;-->

<!--&lt;!&ndash; Fav Column &ndash;&gt;-->
<!--&lt;!&ndash;<ng-container matColumnDef="col5">&ndash;&gt;-->
<!--&lt;!&ndash;<mat-header-cell *matHeaderCellDef mat-sort-header> Location Name </mat-header-cell>&ndash;&gt;-->
<!--&lt;!&ndash;<mat-cell *matCellDef="let element">&ndash;&gt;-->
<!--&lt;!&ndash;<mat-form-field floatLabel="never">&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;<mat-select [(value)]="element.locationName" placeholder="">&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;<mat-option>None</mat-option>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;<mat-option value="Yes">Yes</mat-option>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;</mat-select>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;<input matInput placeholder="" [value]="element.col5" [(ngModel)]="element.col5">&ndash;&gt;-->
<!--&lt;!&ndash;</mat-form-field>&ndash;&gt;-->
<!--&lt;!&ndash;</mat-cell>&ndash;&gt;-->
<!--&lt;!&ndash;</ng-container>&ndash;&gt;-->

<!--&lt;!&ndash;<ng-container matColumnDef="col6">&ndash;&gt;-->
<!--&lt;!&ndash;<mat-header-cell *matHeaderCellDef mat-sort-header> City </mat-header-cell>&ndash;&gt;-->
<!--&lt;!&ndash;<mat-cell *matCellDef="let element">&ndash;&gt;-->
<!--&lt;!&ndash;<mat-form-field floatLabel="never">&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;<mat-select [(value)]="element.locationName" placeholder="">&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;<mat-option>None</mat-option>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;<mat-option value="Yes">Yes</mat-option>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;</mat-select>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;<input matInput placeholder="" [value]="element.col6" [(ngModel)]="element.col6">&ndash;&gt;-->
<!--&lt;!&ndash;</mat-form-field>&ndash;&gt;-->
<!--&lt;!&ndash;</mat-cell>&ndash;&gt;-->
<!--&lt;!&ndash;</ng-container>&ndash;&gt;-->

<!--<mat-header-row *matHeaderRowDef="columnsArray"></mat-header-row>-->
<!--<mat-row *matRowDef="let row; columns: columnsArray;"></mat-row>-->
<!--</mat-table>-->
<!--&lt;!&ndash;<mat-paginator #paginator&ndash;&gt;-->
<!--&lt;!&ndash;[pageSize]="5"&ndash;&gt;-->
<!--&lt;!&ndash;[pageSizeOptions]="[5, 10, 20]">&ndash;&gt;-->
<!--&lt;!&ndash;</mat-paginator>&ndash;&gt;-->
<!--&lt;!&ndash;<mat-paginator #paginator&ndash;&gt;-->
<!--&lt;!&ndash;[length]="dataSource?.totalData.length"&ndash;&gt;-->
<!--&lt;!&ndash;[pageIndex]="0"&ndash;&gt;-->
<!--&lt;!&ndash;[pageSize]="25"&ndash;&gt;-->
<!--&lt;!&ndash;[pageSizeOptions]="[5, 10, 25, 100]">&ndash;&gt;-->
<!--&lt;!&ndash;</mat-paginator>&ndash;&gt;-->
<!--&lt;!&ndash;<tc-paginator&ndash;&gt;-->
<!--&lt;!&ndash;*ngIf="ELEMENT_DATA.length > defaultPageResultCount"&ndash;&gt;-->
<!--&lt;!&ndash;[length]="ELEMENT_DATA.length"&ndash;&gt;-->
<!--&lt;!&ndash;[index]="pager.currPage"&ndash;&gt;-->
<!--&lt;!&ndash;[size]="pager.itemsPerPage"&ndash;&gt;-->
<!--&lt;!&ndash;(page)="onPage($event)"&ndash;&gt;-->
<!--&lt;!&ndash;(more)="onMore()">&ndash;&gt;-->
<!--&lt;!&ndash;</tc-paginator>&ndash;&gt;-->

<!--</div>-->

<!--&lt;!&ndash;<button (click)="onAddRow($event)">Add</button>&ndash;&gt;-->

