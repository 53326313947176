<div class="container-fluid tc-gwacs-card">
  <div class="row tc-gwacs-card__row">
    <div class="tc-flex-item tc-item-circle">
                <span class="icon-box">
                  <i class="material-icons">short_text</i>
                </span>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-10 tc-flex-box">

      <div class="mat-input-container">
        <mat-form-field>
                    <textarea matInput
                              [(ngModel)]="remarkTemp.remark"
                              [placeholder]="'Note'"
                              [disabled]="isSaved"
                    ></textarea>
        </mat-form-field>
      </div>
    </div>

    <div class="col-lg-2 remove-bootstrap-padding-all-devices">

      <div class="tc-action-column">
        <div class="tc-icon-block">
                <span class="tc-icon-block__icon tc-icon-block__icon--active" *ngIf='!isSaved && hasValue(remarkTemp.remark)'>
                  <i class="material-icons"
                     [matTooltip]="'Save' | translate"
                     [matTooltipPosition]="'below'"
                     (click)="onNoteSave($event)">save</i>
                </span>
          <span class="tc-icon-block__icon tc-icon-block__icon--active" *ngIf="isSaved || isEdited">
                  <i
                          class="material-icons" *ngIf="isSaved"
                          [matTooltip]="'Edit' | translate"
                          [matTooltipPosition]="'below'"
                          (click)="onEdit($event)">edit</i>
                    <i
                            class="material-icons" *ngIf="isEdited && !isSaved"
                            [matTooltip]="'Undo' | translate"
                            [matTooltipPosition]="'below'"
                            (click)="onUndo($event)">undo</i>

                </span>
          <span class="tc-icon-block__icon tc-icon-block__icon--active">
                  <i
                          class="material-icons"
                          [matTooltip]="'Delete' | translate"
                          [matTooltipPosition]="'below'"
                          (click)="onDelete($event)">delete</i>
                </span>
        </div>
      </div>
    </div>
  </div>
</div>
