<div class="header navbar top navbar-absolute">

    <nav class="navbar-transparent">
        <div class="container-fluid">

            <div class="tc-nav-bar-main tc-header">

                <div class="tc-header__content">
                    <button
                            mat-icon-button matTooltip="{{'Toggle menu' | translate }}"
                            id="MENU_BTN"
                            class="tc-header__hamburger-icon navbar-toggle tc-burger-menu"
                            type="button"
                            (click)="onToggleSidebar()">
                        <i class="material-icons">menu</i>
                    </button>

                    <div class="tc-header__title">
                        <ng-container *ngFor="let headText of headings; let i = index;">
                            <h4 class="tc-title-txt">{{getMainHeader(headText) | translate}}</h4>
                            <span class="">{{getSubHeader(headText) | translate}}</span>
                            <span
                                    *ngIf="i < headings.length - 1"
                                    class="tc-title-divider">{{HEADER_DIVIDER | translate}}</span>
                        </ng-container>
                    </div>
                </div>

                <div class="tc-header__icon-block">
                    <ul class="tc-nav-header">
                        <li tcDropdown>
                            <button
                                    mat-icon-button
                                    class="tc-dropdown-origin tc-header__icon"
                                    (click)="userProfileLoader()">
                                <!--                <mat-icon>person</mat-icon>-->
                                <span class="mat-button-wrapper">
                <img
                        alt="pro. pic" class="tc-user-profile__img"
                        [src]="userImageUrl"
                        (error)="onImageError($event)"
                        style="border-radius: 100px;
                    width: 22px;">
                </span>
                            </button>

                            <tc-user-profile
                                    class="dropdown-menu tc-dropdown dropdown-menu--user-profile"
                                    [user]="user"
                                    [isUserLoaded]="isUserLoaded"
                                    (logout)="onLogout()"
                                    [disableLogOut]="disableLogOut"
                                    [disableUserObjects]="disableUserObjects"
                                    (viewUserLoadedObjects)="onViewUserLoadedObjects()">
                            </tc-user-profile>
                        </li>

                        <li tcDropdown>
                            <button mat-icon-button class="tc-dropdown-origin">
                                <mat-icon>settings</mat-icon>
                            </button>

                            <tc-theme-change
                                    class="dropdown-menu tc-dropdown"
                                    [inputThemeCode]="inputThemeCode"
                                    (themeSelect)="onThemeSelect($event)">
                            </tc-theme-change>
                        </li>

                        <li tcDropdown>
                            <button mat-icon-button class="tc-dropdown-origin">
                                <mat-icon>language</mat-icon>
                            </button>

                            <div class="dropdown-menu tc-dropdown">
                                <div class="tc-menu-wrapper tc-menu-lang">
                                    <div class="tc-menu-wrapper__header">{{"Change Language" | translate}}</div>
                                    <div class="tc-menu-wrapper__content tc-menu-lang__content">
                    <span
                            *ngFor="let lang of languages"
                            class="tc-menu-lang__item"
                            [ngClass]="{'tc-menu-lang__item--active': lang === activeLanguage}"
                            (click)="changeLanguage.emit(lang)">
                      {{lang}}
                    </span>
                                    </div>
                                </div>
                            </div>
                        </li>

                        <!--notification icon-->
                        <li tcDropdown>
                            <button
                                    mat-icon-button
                                    class="tc-dropdown-origin tc-dropdown-origin--alert"
                                    [ngClass]="{'tc-dropdown-origin--alert' : newNotificationData.length > 0}"
                                    (click)="showNotifications=!showNotifications">
                                <!--                  TODO:  Add  [disabled]="condition" to the button if no notifications are available-->
                                <!--                <mat-icon *ngIf="!newNotification">notifications_none</mat-icon>-->
                                <mat-icon>notifications</mat-icon>
                            </button>

                            <div
                                    class="dropdown-menu tc-dropdown"
                                    *ngIf="showNotifications && newNotificationData.length > 0"
                                    [style.display]="'block'">
                                <div
                                        class="tc-menu-wrapper"
                                        style="cursor: pointer; margin-bottom: 5px"
                                        (click)="navigateToNotificationsPanel(notification.indicator)"
                                        *ngFor="let notification of newNotificationData">
                                    <p *ngIf="notification.indicator == 'message'" style="font-size: 12px">
                                        <b>{{'New Message from' | translate}} {{notification.resourceName}}</b></p>
                                    <p *ngIf="notification.indicator != 'message'"><b>{{notification.type}}</b></p>
                                    <p [innerHTML]="notification.description"></p>
                                    <hr/>
                                </div>
                            </div>
                        </li>
                        <li class="separator" *ngIf="actions.length>0"></li>

                        <!--background task icon-->
                        <button
                                *ngIf="showBackgroundTaskRunning || showBackgroundTaskCompletion"
                                mat-icon-button
                                class="tc-dropdown-origin"
                                [ngClass]="{'tc-dropdown-origin--alert' : hasUnreadDocs}"
                                [matMenuTriggerFor]="menu">
                            <img
                                    height="25"
                                    *ngIf="showBackgroundTaskRunning"
                                    src="../../../../assets/img/loading/loading.gif"
                                    alt="loading background task">
                            <mat-icon
                                    style="color:#3949ab"
                                    *ngIf="showBackgroundTaskCompletion">library_books
                            </mat-icon>
                            <!--                            <img-->
                            <!--                                    height="22"-->
                            <!--                                    *ngIf="showBackgroundTaskCompletion"-->
                            <!--                                    src="../../assets/img/tasks/docs.png"-->
                            <!--                                    alt="loaded background task">-->
                        </button>
                        <p *ngIf="showBackgroundTaskRunning">{{'Background Task Id: ' | translate}}{{backgroundTask ? backgroundTask?.taskId : '-'}}</p>

                        <mat-menu #menu="matMenu">
                            <button
                                    *ngIf="showBackgroundTaskCompletion"
                                    mat-menu-item
                                    (click)="onOpenInManifestsButtonClick()">
                                <mat-icon>library_books</mat-icon>
                                <span>{{'Open in Manifests' | translate}}</span>
                            </button>
                            <button
                                    *ngIf="showBackgroundTaskCompletion && isDownloadable"
                                    mat-menu-item
                                    (click)="onBackgroundDataDownloadButtonClick()">
                                <mat-icon>get_app</mat-icon>
                                <span>{{'Download' | translate}}</span>
                            </button>
                            <button
                                    *ngIf="showBackgroundTaskCompletion"
                                    mat-menu-item
                                    (click)="onBackgroundDataClearButtonClick()">
                                <mat-icon>clear</mat-icon>
                                <span>{{'Clear' | translate}}</span>
                            </button>
                            <button
                                    *ngIf="showBackgroundTaskRunning"
                                    mat-menu-item
                                    (click)="onBackgroundTaskCancelButtonClick()">
                                <mat-icon>stop</mat-icon>
                                <span>{{'Stop Notifying' | translate}}</span>
                            </button>
                        </mat-menu>
                        <li class="separator" *ngIf="actions.length>0"></li>
                        <li
                                class="icons-group"
                                *ngFor="let action of actions | reverse"
                                (click)="onActionClick(action)">
                            <a
                                    [ngClass]="action.disable ? 'tc-icon-block__icon--inactive' : ''">
                                <i
                                        class="material-icons"
                                        [tcSectionRestricted]="action.restricted"
                                        [matTooltip]="action.title | translate"
                                        [matTooltipPosition]="'below'">
                                    {{action.icon}}
                                </i>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>

        </div>
    </nav>
</div>
