<div class="container-fluid tc-item-wrapper tcpi-card">
    <div class="tc-item-wrapper__header tc-item-header-highlighted">
        <div class="tc-item-header-highlighted__icon">
            <i class="material-icons material-icons">people</i>
        </div>
        <div class="tc-item-header-highlighted__primary">{{"Driver Information" | translate}}</div>
    </div>
    <div class="tc-item-wrapper__body">
        <div class="row">
            <div class="col-md-12">
                <div class="tc-flex-box">
                    <div class="tc-flex-item icon-cage input-icon">
                        <div class="card-icon--small icn-color-primary">
                        </div>
                    </div>
                    <div class="tc-flex-item input-cage">
                        <div class="col-xs-12 col-sm-6  col-md-3 col-lg-3">
                            <div class="tc-content--wrap tc-input-view">
                                <div class="tc-caption-txt txt-light">
                                    {{"Grade" | translate}}
                                </div>
                                <div
                                        class="tc-body1--txt">
                                    {{resource?.genericResource?.grade ?
                                    resource.genericResource.grade : EMPTY_CHAR}}
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-6  col-md-3 col-lg-3">
                            <div class="tc-content--wrap tc-input-view">
                                <div class="tc-caption-txt txt-light">
                                    {{"Working Hours" | translate}}
                                </div>
                                <div
                                        class="tc-body1--txt  ">
                                    {{resource?.genericResource?.workingHours ?
                                    resource.genericResource.workingHours : EMPTY_CHAR}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="tc-flex-box">
                    <div class="tc-flex-item icon-cage ">
                        <div class="card-icon--small icn-color-primary"></div>
                    </div>
                    <div class="tc-flex-item input-cage ">
                        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                            <div class="tc-content--wrap tc-input-view">
                                <div class="tc-caption-txt txt-light">
                                    {{"Preferred Transfer Modes" | translate}}
                                </div>
                                <div class="tc-body1--txt" *ngIf="resource?.genericResource?.preferredTransferModes.length>0">
                                    <span *ngFor="let item of resource?.genericResource?.preferredTransferModes, let i=index">
                                     {{getTransferModeCodeName(item.transferMode)}}{{i === resource?.genericResource?.preferredTransferModes.length - 1 ? '' : ', '}}
                                     </span>
                                </div>
                                <div class="tc-body1--txt" *ngIf="resource?.genericResource?.preferredTransferModes.length===0">
                                    {{EMPTY_CHAR}}
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-6  col-md-6 col-lg-6">
                            <div class="tc-content--wrap tc-input-view">
                                <div class="tc-caption-txt txt-light">
                                    {{"Preferred Routes" | translate}}
                                </div>
                                <div class="tc-body1--txt" *ngIf="resource?.genericResource?.preferredRoutes?.length>0">
                                    <span *ngFor="let item of resource?.genericResource?.preferredRoutes, let i=index">
                                        {{item.route}}{{i === resource?.genericResource?.preferredRoutes.length - 1 ? '' : ', '}}
                                    </span>
                                </div>
                                <div class="tc-body1--txt" *ngIf="resource?.genericResource?.preferredRoutes?.length===0">
                                    {{EMPTY_CHAR}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" *ngIf=" resource?.genericResource?.languages">
            <div class="col-md-12">
                <div class="tc-flex-box">
                    <div class="tc-flex-item icon-cage ">
                        <div class="card-icon--small icn-color-primary"></div>
                    </div>
                    <div class="tc-flex-item input-cage ">
                        <div class="col-xs-12">
                            <div class="tc-content--wrap tc-input-view">
                                <div class="tc-caption-txt txt-light">
                                    {{"Language Fluency" | translate}}
                                </div>
                                <div class="tc-action-chip-list-wrapper tc-chip-selector__action-chips">
                                    <div
                                            class="tc-action-chip tc-action-chip--active"
                                            *ngFor="let lang of resource.genericResource.languages">
                                        <span class="tc-action-chip__icon tc-action-chip__icon-man"></span>
                                        <div class="tc-action-chip__text">
                                            {{lang.language}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
