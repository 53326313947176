/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../timeline-event/timeline-event.component.ngfactory";
import * as i2 from "@angular/common";
import * as i3 from "../timeline-event/timeline-event.component";
import * as i4 from "@tc-core/util/framework/config-loader.service";
import * as i5 from "./timeline-unit.component";
var styles_TimelineUnitComponent = [];
var RenderType_TimelineUnitComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TimelineUnitComponent, data: {} });
export { RenderType_TimelineUnitComponent as RenderType_TimelineUnitComponent };
function View_TimelineUnitComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "label", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.unitTime; _ck(_v, 1, 0, currVal_0); }); }
function View_TimelineUnitComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "tc-timeline-event", [["class", "event"]], null, [[null, "eventClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("eventClick" === en)) {
        var pd_0 = (_co.onEventClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_TimelineEventComponent_0, i1.RenderType_TimelineEventComponent)), i0.ɵdid(1, 278528, null, 0, i2.NgStyle, [i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i0.ɵpod(2, { "width": 0, "left": 1 }), i0.ɵdid(3, 49152, null, 0, i3.TimelineEventComponent, [i4.ConfigLoader], { event: [0, "event"] }, { eventClick: "eventClick" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 0, (_v.context.$implicit ? ((_v.context.$implicit.unitCount * _co.unitWidth) + "px") : "0px"), (_v.context.$implicit ? ((_v.context.$implicit.margin * _co.unitWidth) + "px") : "0px")); _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.context.$implicit; _ck(_v, 3, 0, currVal_1); }, null); }
function View_TimelineUnitComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "tc-strip-timeline__target tc-strip-timeline__target--group"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onTargetClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 278528, null, 0, i2.NgStyle, [i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i0.ɵpod(2, { "width": 0 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 0, ((_co.targetUnitCount * _co.unitWidth) + "px")); _ck(_v, 1, 0, currVal_0); }, null); }
function View_TimelineUnitComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "tc-strip-timeline__target tc-strip-timeline__target--single"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onTargetClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 278528, null, 0, i2.NgStyle, [i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i0.ɵpod(2, { "left": 0 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 0, ((_co.targetMargin * _co.unitWidth) + "px")); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_TimelineUnitComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "div", [["class", ""]], null, null, null, null, null)), i0.ɵdid(1, 278528, null, 0, i2.NgStyle, [i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i0.ɵpod(2, { "width": 0 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TimelineUnitComponent_1)), i0.ɵdid(4, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TimelineUnitComponent_2)), i0.ɵdid(6, 278528, null, 0, i2.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TimelineUnitComponent_3)), i0.ɵdid(8, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TimelineUnitComponent_4)), i0.ɵdid(10, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 0, (_co.unitWidth + "px")); _ck(_v, 1, 0, currVal_0); var currVal_1 = ((_co.unitIndex % 4) === 0); _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.events; _ck(_v, 6, 0, currVal_2); var currVal_3 = (_co.isTarget && _co.isTargetBlock); _ck(_v, 8, 0, currVal_3); var currVal_4 = (_co.isTarget && !_co.isTargetBlock); _ck(_v, 10, 0, currVal_4); }, null); }
export function View_TimelineUnitComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-timeline-unit", [], null, null, null, View_TimelineUnitComponent_0, RenderType_TimelineUnitComponent)), i0.ɵdid(1, 49152, null, 0, i5.TimelineUnitComponent, [], null, null)], null, null); }
var TimelineUnitComponentNgFactory = i0.ɵccf("tc-timeline-unit", i5.TimelineUnitComponent, View_TimelineUnitComponent_Host_0, { events: "events", isTargetBlock: "isTargetBlock", targetUnitIndex: "targetUnitIndex", targetUnitCount: "targetUnitCount", targetMargin: "targetMargin", unitIndex: "unitIndex", unitSize: "unitSize", unitWidth: "unitWidth", unitTime: "unitTime", isTarget: "isTarget" }, { eventClick: "eventClick", targetClick: "targetClick" }, []);
export { TimelineUnitComponentNgFactory as TimelineUnitComponentNgFactory };
