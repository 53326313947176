import { Component, Input, OnInit } from '@angular/core';
import { DataKey, DataStoreService } from '../../../services/util/framework/data-store.service';

@Component({
  selector: 'tc-add-rooms',
  templateUrl: './add-rooms.component.html'
})
export class AddRoomsComponent implements OnInit {

  @Input() selectedRooms: any[];
  @Input() roomTypeOptions: any[];

  constructor(
      private dataStore: DataStoreService
  ) {
  }

  ngOnInit() {
  }

  closeModal(event: any[]) {
    this.dataStore.set(DataKey.roomTypes, event, true);
    this.dataStore.set(DataKey.modalClose, true);
  }


}
