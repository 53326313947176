import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { CommonHelper } from '@tc-core/util/helpers';
var DocumentAddCardComponent = /** @class */ (function () {
    function DocumentAddCardComponent(commonHelper) {
        this.commonHelper = commonHelper;
        this.closeModal = new EventEmitter();
        this.selectedDocTypes = [];
        this.selectedDocCodes = [];
        this.selected = [];
    }
    DocumentAddCardComponent.prototype.ngOnInit = function () {
        this.loadDocumentsTypes();
    };
    DocumentAddCardComponent.prototype.loadDocumentsTypes = function () {
        var e_1, _a;
        // let selectedDocs = this.dataStore.get(DataKey.documentTypes).value;
        if (this.selectedDocs) {
            try {
                for (var _b = tslib_1.__values(this.selectedDocs), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var doc = _c.value;
                    // this.selectedDocTypes.push(doc);
                    this.selectedDocCodes.push(doc.code);
                    this.selected.push(doc.code);
                    this.selectedDocTypes.push(doc);
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_1) throw e_1.error; }
            }
        }
    };
    DocumentAddCardComponent.prototype.addType = function (isSelected, docType) {
        var _this = this;
        if (isSelected.checked) {
            this.selectedDocCodes.push(docType.code);
            this.selectedDocTypes.push(docType);
        }
        else {
            this.selectedDocCodes.splice(this.selectedDocCodes.indexOf(docType.code), 1);
            this.selectedDocTypes.forEach(function (d) {
                if (d.code === docType.code) {
                    _this.selectedDocTypes.splice(_this.selectedDocTypes.indexOf(d), 1);
                }
            });
        }
    };
    DocumentAddCardComponent.prototype.addDocuments = function () {
        this.closeModal.emit(this.selectedDocTypes);
        /*this.dataStore.set(DataKey.documentTypes, this.selectedDocTypes, true);
        this.dataStore.set(DataKey.modalClose, true);*/
    };
    DocumentAddCardComponent.prototype.closePopUp = function (event) {
    };
    return DocumentAddCardComponent;
}());
export { DocumentAddCardComponent };
