import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material';
import { KebabMenuModule } from '@tc/kebab-menu';
import { PipesModule } from '@tc/pipes';
import { ResourceModule } from '@tc/resource';
import { SecurityModule } from '@tc/security';
import { CardStripComponent } from './card-strip.component';

@NgModule({
  imports: [
    CommonModule,
    ResourceModule,
    PipesModule,
    KebabMenuModule,
    MatCheckboxModule,
    SecurityModule,
    FormsModule
  ],
  declarations: [
    CardStripComponent
  ],
  exports: [
    CardStripComponent
  ]
})
export class CardStripModule {}
