import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResultContentCardModule } from '../result-content-card/result-content-card.module';
import { ResultContentCardReservationsComponent } from './result-content-card-reservations.component';
import {ResourceModule} from '@tc/resource';

@NgModule({
  imports: [
    CommonModule,
    ResourceModule,
    ResultContentCardModule
  ],
  exports: [
    ResultContentCardReservationsComponent
],
  declarations: [ResultContentCardReservationsComponent]
})
export class ResultContentCardReservationsModule { }
