import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SplitPassengersViewModule } from '../../../widgets/shared/split-passengers-view/split-passengers-view.module';
import { SplitPassengersViewWrapperComponent } from './split-passengers-view-wrapper.component';

@NgModule({
    imports: [
        CommonModule,
        SplitPassengersViewModule
    ],
    exports: [SplitPassengersViewWrapperComponent],
    declarations: [SplitPassengersViewWrapperComponent],
    entryComponents:[SplitPassengersViewWrapperComponent]
})
export class SplitPassengersViewWrapperModule {}
