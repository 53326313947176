import * as tslib_1 from "tslib";
import { ItemCostPortion } from '../../../models/reservation-v2/item-cost-portion';
import { PassengerType } from '../../../models/reservation-v2/passenger-type';
import { PaxCost } from '../../../models/reservation-v2/pax-cost';
import * as i0 from "@angular/core";
/**
 * this class must sync with
 * it.codegen.tbx.reservationv2.service.bookingitemservice.CostCalculationService
 * backend service
 */
var CostCalculationUtilsV2Service = /** @class */ (function () {
    function CostCalculationUtilsV2Service() {
    }
    CostCalculationUtilsV2Service.prototype.calculateCost = function (adultCount, teenCount, childCount, infantCount, unitCost, perAdultCost, perTeenCost, perChildCost, perInfantCost, currency) {
        var paxCost = new PaxCost(currency);
        if (unitCost && unitCost > 0) {
            paxCost.totalCost = unitCost;
            paxCost.unitCost = unitCost;
        }
        else {
            var adultCost = 0;
            var teensCost = 0;
            var childrenCost = 0;
            var infantsPrice = 0;
            if (perAdultCost && perAdultCost > 0) {
                adultCost = adultCount * perAdultCost;
                paxCost.perAdultCost = perAdultCost;
            }
            if (perTeenCost && perTeenCost > 0) {
                teensCost = teenCount * perTeenCost;
                paxCost.perTeenCost = perTeenCost;
            }
            if (perChildCost && perChildCost > 0) {
                childrenCost = childCount * perChildCost;
                paxCost.perChildCost = perChildCost;
            }
            if (perInfantCost && perInfantCost > 0) {
                infantsPrice = infantCount * perInfantCost;
                paxCost.perInfantCost = perInfantCost;
            }
            paxCost.totalCost = this.roundTo2Decimals(adultCost + teensCost + childrenCost + infantsPrice);
        }
        return paxCost;
    };
    CostCalculationUtilsV2Service.prototype.fixAssignmentCost = function (rawAssignment) {
        var e_1, _a;
        var adultCount = 0;
        var teenCount = 0;
        var childCount = 0;
        var infantCount = 0;
        if (rawAssignment.bookingPaxMappings) {
            try {
                for (var _b = tslib_1.__values(rawAssignment.bookingPaxMappings), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var bookingPaxMapping = _c.value;
                    switch (bookingPaxMapping.type) {
                        case PassengerType.A:
                            adultCount++;
                            break;
                        case PassengerType.T:
                            teenCount++;
                            break;
                        case PassengerType.C:
                            childCount++;
                            break;
                        case PassengerType.I:
                            infantCount++;
                            break;
                    }
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_1) throw e_1.error; }
            }
        }
        var resourceCost = rawAssignment.genResourceCost ?
            rawAssignment.genResourceCost :
            rawAssignment.trsResourceCost;
        if (resourceCost) {
            var paxCost = this.calculateCost(adultCount, teenCount, childCount, infantCount, resourceCost.unitFare, resourceCost.adultFare, resourceCost.teenFare, resourceCost.childFare, resourceCost.infantFare, resourceCost.currency);
            rawAssignment.estimatedCost = rawAssignment.calculatedCost ? rawAssignment.calculatedCost.estimatedCost : paxCost.totalCost;
            rawAssignment.jobCost = rawAssignment.calculatedCost ? rawAssignment.calculatedCost.totalCost : paxCost.totalCost;
        }
    };
    CostCalculationUtilsV2Service.prototype.getItemCostPortion = function (item, rawAssignment) {
        var e_2, _a;
        var resourceCost = rawAssignment.genResourceCost ?
            rawAssignment.genResourceCost :
            rawAssignment.trsResourceCost;
        var paxCost = new PaxCost('');
        if (resourceCost) {
            paxCost.currency = resourceCost.currency;
            if (item.bookingPassengers.length > 0 && rawAssignment.bookingPaxMappings.length) {
                if (resourceCost.unitFare > 0) {
                    paxCost.unitCost = resourceCost.unitFare;
                    // if (resourceCost.costingType === CostingType.UNIT ){
                    //     paxCost.totalCost = this.roundTo2Decimals((rawAssignment.jobCost / rawAssignment.bookingPaxMappings.length) *
                    //         item.bookingPassengers.length);
                    // }
                    // else
                    // TODO need to calculate cost seperatly
                    if (rawAssignment.supplierObj && rawAssignment.supplierObj.calculatedCost) {
                        paxCost.totalCost = rawAssignment.supplierObj.calculatedCost.totalCost;
                        paxCost.estimatedCost = rawAssignment.supplierObj.calculatedCost.estimatedCost ?
                            rawAssignment.supplierObj.calculatedCost.estimatedCost :
                            rawAssignment.supplierObj.calculatedCost.totalCost;
                    }
                    else {
                        paxCost.totalCost = rawAssignment.jobCost;
                        paxCost.estimatedCost = rawAssignment.estimatedCost ? rawAssignment.estimatedCost : rawAssignment.jobCost;
                    }
                }
                else {
                    var adultCount = 0;
                    var teenCount = 0;
                    var childCount = 0;
                    var infantCount = 0;
                    try {
                        for (var _b = tslib_1.__values(item.bookingPassengers), _c = _b.next(); !_c.done; _c = _b.next()) {
                            var passenger = _c.value;
                            switch (passenger.type) {
                                case PassengerType.A:
                                    adultCount++;
                                    break;
                                case PassengerType.T:
                                    teenCount++;
                                    break;
                                case PassengerType.C:
                                    childCount++;
                                    break;
                                case PassengerType.I:
                                    infantCount++;
                                    break;
                            }
                        }
                    }
                    catch (e_2_1) { e_2 = { error: e_2_1 }; }
                    finally {
                        try {
                            if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                        }
                        finally { if (e_2) throw e_2.error; }
                    }
                    return this.calculateCost(adultCount, teenCount, childCount, infantCount, resourceCost.unitFare, resourceCost.adultFare, resourceCost.teenFare, resourceCost.childFare, resourceCost.infantFare, resourceCost.currency);
                }
            }
            else {
                paxCost.unitCost = resourceCost.unitFare;
                paxCost.perAdultCost = resourceCost.adultFare;
                paxCost.perTeenCost = resourceCost.teenFare;
                paxCost.perChildCost = resourceCost.childFare;
                paxCost.perInfantCost = resourceCost.infantFare;
                paxCost.totalCost = 0;
            }
        }
        return paxCost;
    };
    CostCalculationUtilsV2Service.prototype.setupItemCostPortion = function (item) {
        var primaryAssignment = item.primaryAssignment;
        var secondaryAssignment = item.secondaryAssignment;
        var itemCostPortion = new ItemCostPortion();
        if (primaryAssignment) {
            itemCostPortion.primaryJobCost = this.getItemCostPortion(item, primaryAssignment);
            if (primaryAssignment.jobCost) {
                itemCostPortion.primaryJobCost.totalCost = primaryAssignment.jobCost;
                itemCostPortion.primaryJobCost.estimatedCost = primaryAssignment.estimatedCost ? primaryAssignment.estimatedCost : primaryAssignment.jobCost;
            }
        }
        if (secondaryAssignment) {
            itemCostPortion.secondaryJobCost = this.getItemCostPortion(item, secondaryAssignment);
            if (secondaryAssignment.jobCost) {
                itemCostPortion.secondaryJobCost.totalCost = secondaryAssignment.jobCost;
                itemCostPortion.secondaryJobCost.estimatedCost = secondaryAssignment.estimatedCost ? secondaryAssignment.estimatedCost : secondaryAssignment.jobCost;
            }
        }
        if (itemCostPortion.primaryJobCost && !itemCostPortion.secondaryJobCost) {
            itemCostPortion.totalCost = itemCostPortion.primaryJobCost.totalCost;
            itemCostPortion.currency = itemCostPortion.primaryJobCost.currency;
        }
        else if (!itemCostPortion.primaryJobCost && itemCostPortion.secondaryJobCost) {
            itemCostPortion.totalCost = itemCostPortion.secondaryJobCost.totalCost;
            itemCostPortion.currency = itemCostPortion.secondaryJobCost.currency;
        }
        else if ( // both currencies are equal or empty
        itemCostPortion.primaryJobCost && itemCostPortion.secondaryJobCost &&
            ((itemCostPortion.primaryJobCost.currency && itemCostPortion.primaryJobCost.currency ===
                itemCostPortion.secondaryJobCost.currency)
                ||
                    !itemCostPortion.primaryJobCost.currency && !itemCostPortion.secondaryJobCost.currency)) {
            itemCostPortion.totalCost = itemCostPortion.primaryJobCost.totalCost + itemCostPortion.secondaryJobCost.totalCost;
            itemCostPortion.currency = itemCostPortion.primaryJobCost.currency;
        }
        item.itemCostPortion = itemCostPortion;
    };
    CostCalculationUtilsV2Service.prototype.roundTo2Decimals = function (value) {
        return Math.round((value + Number.EPSILON) * 100) / 100;
    };
    CostCalculationUtilsV2Service.ngInjectableDef = i0.defineInjectable({ factory: function CostCalculationUtilsV2Service_Factory() { return new CostCalculationUtilsV2Service(); }, token: CostCalculationUtilsV2Service, providedIn: "root" });
    return CostCalculationUtilsV2Service;
}());
export { CostCalculationUtilsV2Service };
