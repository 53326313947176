import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { JobSearchCriteria } from '../../../models/criteria/job-search-criteria';
import { CommonJob } from '../../../models/job/common-job';
import { CommonJobHistory } from '../../../models/job/common-job-history';
import { OPResponseWrapper } from '../../../models/response/op-response-wrapper';
import { DMCQueryParamsService } from '../../util/framework/dmc-query-params.service';
import { RequestService } from '../../util/framework/request.service';
import { UrlPaths } from '../url-paths';
import {JobParameterSearchCriteria} from '../../../models/criteria/job-parameter-search-criteria';
import {JobParameter} from '../../../models/job/JobParameter';

@Injectable({
    providedIn: 'root'
})
export class JobService
{
    constructor(
        private requestService: RequestService,
        private queryParamsService: DMCQueryParamsService
    )
    {}

    // common job
    getCommonJobs(criteria: JobSearchCriteria): Observable<OPResponseWrapper<CommonJob>>
    {
        const params = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.get(
            UrlPaths.OP_JOB_URL_KEY,
            [UrlPaths.OP_JOB_VERSION, UrlPaths.JOBS],
            params
        );
    }

    getCommonJobHistories(criteria: JobSearchCriteria): Observable<OPResponseWrapper<CommonJobHistory>>
    {
        const params = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.get(
            UrlPaths.OP_JOB_URL_KEY,
            [UrlPaths.OP_JOB_VERSION, UrlPaths.JOBS, UrlPaths.JOB_HISTORIES],
            params
        );
    }

    unlockJob(jobName: string): Observable<OPResponseWrapper<string>>
    {
        const criteria = new JobSearchCriteria();
        criteria.jobName = jobName;

        return this.requestService.post(
            UrlPaths.OP_JOB_URL_KEY,
            [UrlPaths.OP_JOB_VERSION, UrlPaths.JOBS],
            criteria
        );
    }

    // Get Job Parameter data
    getJobParameter(criteria: JobParameterSearchCriteria): Observable<OPResponseWrapper<JobParameter>>
    {
        const params = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.get(
            UrlPaths.OP_JOB_URL_KEY,
            [UrlPaths.OP_JOB_VERSION, UrlPaths.JOB_PARAMETER],
            params
        );
    }

    addJobParameter(row: any): Observable<any> {
        return this.requestService.post(
            UrlPaths.OP_JOB_URL_KEY,
            [UrlPaths.OP_JOB_VERSION, UrlPaths.JOB_PARAMETER],
            row
        );
    }

    deleteJobParameter(code: string): Observable<any> {
        return this.requestService.delete(
            UrlPaths.OP_JOB_URL_KEY,
            [UrlPaths.OP_JOB_VERSION, UrlPaths.JOB_PARAMETER, code]
        );
    }
}
