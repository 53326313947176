import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChipInputModule } from '@tc/chip-input';
import { SupplierAllocationSearchCriteriaComponent } from './supplier-allocation-search-criteria.component';

@NgModule({
    declarations: [SupplierAllocationSearchCriteriaComponent],
    exports: [
        SupplierAllocationSearchCriteriaComponent
    ],
    imports: [
        CommonModule,
        ChipInputModule
    ],
    entryComponents:[SupplierAllocationSearchCriteriaComponent]
})
export class SupplierAllocationSearchCriteriaModule { }
