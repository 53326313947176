import * as tslib_1 from "tslib";
import { AbstractSearchCriteria } from './abstract-search-criteria';
var LocationGroupsSearchCriteria = /** @class */ (function (_super) {
    tslib_1.__extends(LocationGroupsSearchCriteria, _super);
    function LocationGroupsSearchCriteria() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.code = '';
        _this.locationCode = '';
        _this.name = '';
        _this.routeCode = '';
        return _this;
    }
    return LocationGroupsSearchCriteria;
}(AbstractSearchCriteria));
export { LocationGroupsSearchCriteria };
