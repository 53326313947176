<div class="detail-group__body-service-item container-fluid">
    <div class="detail-group__heading container-fluid">
        <div class="item-header">
            <div class="item-header__content">{{'Job Notes' | translate}}</div>
            <div class="tc-icon-block action-icon">
                <button
                        mat-icon-button
                        aria-label="Delete"
                        (click)="onAddNote()"
                        matTooltip="'Add Note' | translate"
                        [disabled]="!isEditable()">
                    <i class="material-icons">add</i>
                </button>
            </div>
        </div>
    </div>
    <div class="tc-px-3">
        <ag-grid-angular
                class="ag-theme-material tc-ag-grid tc-ag-grid--auto-height"
                [rowData]="assignmentNotes"
                [columnDefs]="columnDefs"
                [defaultColDef]="defaultColDef"
                [domLayout]="'autoHeight'"
                [frameworkComponents]="frameworkComponents"
                (gridReady)="onGridReadyAssignmentNotes($event)">
        </ag-grid-angular>
    </div>

    <div class="detail-group__heading container-fluid">
        <div class="item-header">
            <div class="item-header__content">{{'Booking Notes' | translate}}</div>
        </div>
    </div>
    <div class="tc-px-3">
        <mat-accordion *ngFor="let noteGroup of bookingNoteGroups;let i = index;">
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title id="booking-item-ref-{{i}}">
                        {{noteGroup.bookingReferenceId}}
                    </mat-panel-title>
                    <mat-panel-description>
                        <div id="booking-item-note-count-{{i}}">{{noteGroup.notes.length + ' Notes Available' | translate}}</div>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <tc-booking-note-group
                        [noteGroup]="noteGroup"
                        [columnDefs]="bookingNotesColumnDefs">
                </tc-booking-note-group>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>

<div class="container assign-panel-footer-section">
    <button class="assign-panel-footer-section__button tc-button-secondary mat-button" (click)="onClose($event)">
        Close
    </button>
    <button
            class="assign-panel-footer-section__button tc-button-primary mat-button"
            (click)="onSave()"
            [disabled]="!isSaveButtonEnable()">
        {{'Save' | translate}}
    </button>
</div>
