import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PaginatorModule} from '@tc/paginator';
import {ResultHeaderModule} from '../../widgets/shared/result-header/result-header.module';
import {RoomSearchResultsComponent} from './room-search-results.component';
import {AdvRoomSearchResultsModule} from '../../widgets/shared/adv-room-search-results/adv-room-search-results.module';

@NgModule({
    imports: [
        CommonModule,
        ResultHeaderModule,
        PaginatorModule,
        AdvRoomSearchResultsModule,

    ],
    declarations: [RoomSearchResultsComponent],
    exports: [RoomSearchResultsComponent],
    entryComponents: [RoomSearchResultsComponent]
})
export class RoomSearchResultsModule {
}
