import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Remark } from '../../../models/common/remark';

@Component({
  selector: 'tc-notes',
  templateUrl: './notes.component.html'
})
export class NotesComponent implements OnInit {

  @Input() remarks: Remark[];

  @Output() remarksChange: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  public onNoteSave(event: Remark) {
    this.remarksChange.emit(event);
  }

  public onDelete(event: Remark) {
    this.remarks.splice(this.remarks.indexOf(event), 1);
    this.remarksChange.emit(event);
  }

}
