import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from '@tc/pipes';
import { ServiceItemFocusViewSummaryComponent } from './service-item-focus-view-summary.component';

@NgModule({
  imports: [
    CommonModule,
      PipesModule,
      TranslateModule.forChild()
  ],
  declarations: [ServiceItemFocusViewSummaryComponent],
    exports: [ServiceItemFocusViewSummaryComponent]
})
export class ServiceItemFocusViewSummaryModule { }
