<form [formGroup]="serviceInformationForm">
    <div class="container-fluid direct-client-personal-info-edit-card tc-card-wrapper">
        <div class="row">
            <div class="col-md-12 col-lg-12">
                <div class="tc-flex-box">
                    <div class="tc-flex-item tc-item-circle">
                        <div class="icon-box">
                            <i class="material-icons">business_center</i>
                        </div>
                    </div>
                    <div class="tc-flex-item tc-right-cage">
                        <div class="row">
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                <div class="tc-select-edit dcpiec-select-title">
                                    <mat-form-field>
                                        <input
                                                matInput
                                                formControlName="serCmpName"
                                                [placeholder]="'Service Company Name'"
                                        >

                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                <div class="tc-select-edit dcpiec-select-title">
                                    <mat-form-field>
                                        <input
                                                matInput
                                                formControlName="contactNumber"
                                                [placeholder]="'Service Company Contact Number'"
                                                maxlength="15"
                                        >
                                        <mat-error>{{getErrorMessageOfControl(serviceInformationForm, 'Service Company Contact Number')}}</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                <div class="tc-select-edit dcpiec-select-title">
                                    <mat-form-field>
                                        <input
                                                matInput
                                                formControlName="alternateContactNumber"
                                                [placeholder]="'Alternate Contact Number'"
                                                maxlength="15"
                                        >
                                        <mat-error>{{getErrorMessageOfControl(serviceInformationForm, 'Alternate Contact Number')}}</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                <div class="tc-input-edit dcpiec-input-lname">
                                    <mat-form-field>
                                        <input
                                                matInput
                                                formControlName="email"
                                                [placeholder]="'Email'">
                                        <mat-error>{{getErrorMessageOfControl(serviceInformationForm, 'Email')}}
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>


                        </div>
                        <div class="row">
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                <div class="tc-input-edit dcpiec-input-fname">
                                    <mat-form-field>
                                        <input
                                                matInput formControlName="lastServiceDate" [matDatepicker]="picker1"
                                                placeholder="Last Service Date">
                                        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                        <mat-datepicker #picker1></mat-datepicker>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                <div class="tc-input-edit dcpiec-input-fname">
                                    <mat-form-field>
                                        <input
                                                matInput formControlName="nextServiceDate" [matDatepicker]="picker2"
                                                placeholder="Next Service Date">
                                        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                        <mat-datepicker #picker2></mat-datepicker>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 remove-bootstrap-padding-mobile">
                                <div class="tc-select-edit dcpiec-select-title">
                                    <mat-form-field>
                                        <textarea
                                                matInput
                                                placeholder="Service Note"
                                                formControlName="serviceNote"
                                                maxlength="100">
                                        </textarea>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
