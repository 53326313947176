/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../node_modules/@angular/material/tabs/typings/index.ngfactory";
import * as i2 from "@angular/material/tabs";
import * as i3 from "../resource-allocation-panel/resource-allocation-panel.component.ngfactory";
import * as i4 from "../resource-allocation-panel/resource-allocation-panel.component";
import * as i5 from "@tc-core/service/sorters/sorter.service";
import * as i6 from "@tc-core/util/framework/config-loader.service";
import * as i7 from "../supplier-allocation-panel/supplier-allocation-panel.component.ngfactory";
import * as i8 from "../supplier-allocation-panel/supplier-allocation-panel.component";
import * as i9 from "../../../../services/util/framework/dmc-query-params.service";
import * as i10 from "../../../../services/util/framework/data-store.service";
import * as i11 from "../../../../services/backend-consumers/supplier-service/supplier.service";
import * as i12 from "@tc-core/util/helpers/common-helper.service";
import * as i13 from "@tc-core/util/ui/auto-scroll.service";
import * as i14 from "@angular/common";
import * as i15 from "./allocation-focus-view.component";
import * as i16 from "../../../../services/util/ui/content-summary-card.service";
import * as i17 from "@tc/focus-view/focus-view.service";
import * as i18 from "@tc/dialog/dialog.service";
import * as i19 from "@tc-core/util/framework/user-journey-manager.service";
import * as i20 from "@tc-core/util/framework/focus-view-manager.service";
import * as i21 from "@tc-core/util/framework/event-manager.service";
import * as i22 from "../../../../services/util/common/dmc-common";
import * as i23 from "@tc/modal/modal.service";
var styles_AllocationFocusViewComponent = [];
var RenderType_AllocationFocusViewComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AllocationFocusViewComponent, data: {} });
export { RenderType_AllocationFocusViewComponent as RenderType_AllocationFocusViewComponent };
function View_AllocationFocusViewComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 5, "mat-tab", [["label", "Resource"]], null, null, null, i1.View_MatTab_0, i1.RenderType_MatTab)), i0.ɵdid(1, 770048, [[1, 4]], 2, i2.MatTab, [i0.ViewContainerRef], { textLabel: [0, "textLabel"] }, null), i0.ɵqud(335544320, 4, { templateLabel: 0 }), i0.ɵqud(335544320, 5, { _explicitContent: 0 }), (_l()(), i0.ɵeld(4, 0, null, 0, 1, "tc-resource-allocation-panel", [], null, [[null, "resourceSelect"], [null, "resourceCalendarClick"], [null, "targetClick"], [null, "eventClick"], [null, "onTimeRangeChangeClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("resourceSelect" === en)) {
        var pd_0 = (_co.onResourceSelect($event) !== false);
        ad = (pd_0 && ad);
    } if (("resourceCalendarClick" === en)) {
        var pd_1 = (_co.onResourceCalendarClick($event) !== false);
        ad = (pd_1 && ad);
    } if (("targetClick" === en)) {
        var pd_2 = (_co.onTargetClick($event) !== false);
        ad = (pd_2 && ad);
    } if (("eventClick" === en)) {
        var pd_3 = (_co.onEventClick($event) !== false);
        ad = (pd_3 && ad);
    } if (("onTimeRangeChangeClick" === en)) {
        var pd_4 = (_co.onTimeRangeChangeClick($event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, i3.View_ResourceAllocationPanelComponent_0, i3.RenderType_ResourceAllocationPanelComponent)), i0.ɵdid(5, 114688, null, 0, i4.ResourceAllocationPanelComponent, [i5.SorterService, i6.ConfigLoader], { contentCardDataList: [0, "contentCardDataList"], itemList: [1, "itemList"], heading: [2, "heading"], paxCount: [3, "paxCount"], isPrimary: [4, "isPrimary"], isSecondary: [5, "isSecondary"], startTime: [6, "startTime"], endTime: [7, "endTime"], bookingIdList: [8, "bookingIdList"], resourceSearchCriteria: [9, "resourceSearchCriteria"] }, { resourceSelect: "resourceSelect", resourceCalendarClick: "resourceCalendarClick", eventClick: "eventClick", targetClick: "targetClick", onTimeRangeChangeClick: "onTimeRangeChangeClick" }), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Resource"; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.contentCardDataListResources; var currVal_2 = _co.itemList; var currVal_3 = _co.resourcePanelHeading; var currVal_4 = _co.paxCount; var currVal_5 = _co.isPrimary; var currVal_6 = _co.isSecondary; var currVal_7 = _co.startTime; var currVal_8 = _co.endTime; var currVal_9 = _co.bookingIdList; var currVal_10 = _co.resourceSearchCriteria; _ck(_v, 5, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10); }, null); }
export function View_AllocationFocusViewComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "div", [["class", "resource-assignment-view"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 10, "mat-tab-group", [["class", "mat-tab-group"]], [[2, "mat-tab-group-dynamic-height", null], [2, "mat-tab-group-inverted-header", null]], null, null, i1.View_MatTabGroup_0, i1.RenderType_MatTabGroup)), i0.ɵdid(2, 3325952, null, 1, i2.MatTabGroup, [i0.ElementRef, i0.ChangeDetectorRef, [2, i2.MAT_TABS_CONFIG]], { selectedIndex: [0, "selectedIndex"] }, null), i0.ɵqud(603979776, 1, { _tabs: 1 }), (_l()(), i0.ɵeld(4, 16777216, null, null, 5, "mat-tab", [["label", "Supplier"]], null, null, null, i1.View_MatTab_0, i1.RenderType_MatTab)), i0.ɵdid(5, 770048, [[1, 4]], 2, i2.MatTab, [i0.ViewContainerRef], { textLabel: [0, "textLabel"] }, null), i0.ɵqud(335544320, 2, { templateLabel: 0 }), i0.ɵqud(335544320, 3, { _explicitContent: 0 }), (_l()(), i0.ɵeld(8, 0, null, 0, 1, "tc-supplier-allocation-panel", [], null, [[null, "supplierSelect"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("supplierSelect" === en)) {
        var pd_0 = (_co.onSupplierSelect($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_SupplierAllocationPanelComponent_0, i7.RenderType_SupplierAllocationPanelComponent)), i0.ɵdid(9, 114688, null, 0, i8.SupplierAllocationPanelComponent, [i5.SorterService, i9.DMCQueryParamsService, i10.DataStoreService, i6.ConfigLoader, i11.SupplierService, i12.CommonHelper, i13.AutoScrollService], { contentCardDataList: [0, "contentCardDataList"], bookingDataList: [1, "bookingDataList"], heading: [2, "heading"], isPrimary: [3, "isPrimary"], isSecondary: [4, "isSecondary"], paxCount: [5, "paxCount"], supplierSearchCriteria: [6, "supplierSearchCriteria"], startTime: [7, "startTime"], endTime: [8, "endTime"], bookingIdList: [9, "bookingIdList"], itemList: [10, "itemList"] }, { supplierSelect: "supplierSelect" }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AllocationFocusViewComponent_1)), i0.ɵdid(11, 16384, null, 0, i14.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.selectedIndex; _ck(_v, 2, 0, currVal_2); var currVal_3 = "Supplier"; _ck(_v, 5, 0, currVal_3); var currVal_4 = _co.contentCardDataListSuppliers; var currVal_5 = _co.itemList; var currVal_6 = _co.supplierPanelHeading; var currVal_7 = _co.isPrimary; var currVal_8 = _co.isSecondary; var currVal_9 = _co.paxCount; var currVal_10 = _co.supplierSearchCriteria; var currVal_11 = _co.startTime; var currVal_12 = _co.endTime; var currVal_13 = _co.bookingIdList; var currVal_14 = _co.itemList; _ck(_v, 9, 1, [currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14]); var currVal_15 = _co.resourceAllocationEnable(); _ck(_v, 11, 0, currVal_15); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 2).dynamicHeight; var currVal_1 = (i0.ɵnov(_v, 2).headerPosition === "below"); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
export function View_AllocationFocusViewComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-resource-allocation-focus-view", [], null, null, null, View_AllocationFocusViewComponent_0, RenderType_AllocationFocusViewComponent)), i0.ɵdid(1, 114688, null, 0, i15.AllocationFocusViewComponent, [i10.DataStoreService, i6.ConfigLoader, i16.ContentSummaryCardService, i17.FocusViewService, i18.DialogService, i19.UserJourneyManager, i20.FocusViewManager, i21.EventManager, i12.CommonHelper, i22.DMCCommon, i23.ModalService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AllocationFocusViewComponentNgFactory = i0.ɵccf("tc-resource-allocation-focus-view", i15.AllocationFocusViewComponent, View_AllocationFocusViewComponent_Host_0, { itemList: "itemList", isPrimary: "isPrimary", isSecondary: "isSecondary", allocationTimeRange: "allocationTimeRange", startTime: "startTime", endTime: "endTime", bookingIdList: "bookingIdList" }, {}, []);
export { AllocationFocusViewComponentNgFactory as AllocationFocusViewComponentNgFactory };
