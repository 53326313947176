import { HttpClient } from '@angular/common/http';
import { DMCQueryParamsService } from '../../util/framework/dmc-query-params.service';
import { RequestService } from '../../util/framework/request.service';
import { UrlPaths } from '../url-paths';
import * as i0 from "@angular/core";
import * as i1 from "../../util/framework/dmc-query-params.service";
import * as i2 from "../../util/framework/request.service";
import * as i3 from "@angular/common/http";
var DashboardSearchService = /** @class */ (function () {
    function DashboardSearchService(queryParamsService, requestService, http) {
        this.queryParamsService = queryParamsService;
        this.requestService = requestService;
        this.http = http;
    }
    DashboardSearchService.prototype.searchDashboardAssignmentItems = function (text) {
        var params = this.queryParamsService.asQueryParams({ text: text });
        return this.requestService.get(UrlPaths.OP_RES_URL_KEY, [UrlPaths.OP_RES_VERSION, UrlPaths.DASHBOARD_SEARCH_ITEM_V2], params);
    };
    DashboardSearchService.prototype.searchDashboardSupplierResourceItems = function (text) {
        var params = this.queryParamsService.asQueryParams({ text: text });
        return this.requestService.get(UrlPaths.OP_SUP_URL_KEY, [UrlPaths.OP_SUP_VERSION, UrlPaths.DASHBOARD_SEARCH_ITEM_V2], params);
    };
    DashboardSearchService.ngInjectableDef = i0.defineInjectable({ factory: function DashboardSearchService_Factory() { return new DashboardSearchService(i0.inject(i1.DMCQueryParamsService), i0.inject(i2.RequestService), i0.inject(i3.HttpClient)); }, token: DashboardSearchService, providedIn: "root" });
    return DashboardSearchService;
}());
export { DashboardSearchService };
