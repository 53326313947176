import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PipesModule } from '@tc/pipes';
import { ResourceModule } from '@tc/resource';
import { TimelineModule } from '../../widgets/framework/cg-timeline/timeline/timeline.module';
import { ResultContentCardModule } from '../../widgets/shared/result-content-card/result-content-card.module';
import { ResourceAllocationCardComponent } from './resource-allocation-card.component';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
    declarations: [ResourceAllocationCardComponent],
    imports: [
        CommonModule,
        ResourceModule,
        ResultContentCardModule,
        PipesModule,
        TimelineModule,
        TranslateModule
    ],
    exports: [ResourceAllocationCardComponent]
})
export class ResourceAllocationCardModule {
}
