import { AssignmentRawData } from './assignment-raw-data';
import { BookingClient } from './booking-client';
import { BookingItemAttribute } from './booking-item-attribute';
import { BookingItemHistory } from './booking-item-history';
import { BookingNote } from './booking-note';
import { BookingPassenger } from './booking-passenger';
import { BookingSupplement } from './booking-supplement';
import { GenBookingItem } from './gen-booking-item';
import { ItemCostPortion } from './item-cost-portion';
import { MainType } from './main-type';
import { Passenger } from './passenger';
import { ProductLevel } from './product-level';
import { ServiceSharing } from './service-sharing';
import { TrsBookingItem } from './trs-booking-item';

export class BookingItem {
    id: number;
    parentBookingId: number;
    bookingReferenceId: string;
    bookingReferenceIdWithoutReturnFlag: string;
    bookingSource: string;
    bookingSourceId: number;
    bookingSourceItemNo: number;
    confirmationNo: string;
    productLevel: ProductLevel;
    mainType: MainType;
    productCategory: string;
    bookingDate: Date;
    contractReference: string;
    contractId: string;
    pkgId: number;
    pkgCode: string;
    pkgName: string;
    plCode: string;
    plName: string;
    itemName: string;
    defaultSupplier: any;
    defaultSupplierCode: string;
    defaultSupplierName: string;
    serviceRequired: boolean;
    operator: string;
    serviceDate: Date;
    startTime: any;
    endTime: any;
    productSubTypeCode: string;
    productSubTypeName: string;
    bookingStatus: string; // todo add enum
    bookingSourceStatus: string; // todo add enum
    pvtShared: ServiceSharing;
    bookingClient: BookingClient;
    trsBookingItem: TrsBookingItem;
    genBookingItem: GenBookingItem;
    bookingPassengers: Passenger[];
    leadPassenger: Passenger;
    adultCount: number;
    teenCount: number;
    childrenCount: number;
    infantCount: number;
    primaryAssignment: AssignmentRawData;
    secondaryAssignment: AssignmentRawData;
    itemCostPortion: ItemCostPortion;       // cost portion of item in jobs
    bookingNotes: BookingNote[];
    supplementNotes: BookingSupplement[];
    bookingItemAttributes: BookingItemAttribute[];
    bookingItemHistories: BookingItemHistory[];
    language: string;
    tourName: string;
    tourCode: string;
    tourItemNo: number;
    split: boolean;
    disabled: boolean;
    manifestStatus: string;

    /* service date time duration */
    serviceStartDateTime: Date;
    serviceEndDateTime: Date;
    nights: number;

    itemImportedDate: Date;
    itemNotes: BookingNote[]; // item only notes (without booking notes)
    passengerTempIds: number[]; // passenger temp ids of booking. transient

    // front end attributes
    selected: boolean;
    vehicleModel: string;

    removedPrimaryAssignments: AssignmentRawData[];
    removedSecondaryAssignments: AssignmentRawData[];

    //serviceType
    primaryServiceType: any;
    secondaryServiceType: any;

    //accommodation
    roomCount: number;
    occAdultCount: number;
    occTeenCount: number;
    occChildrenCount: number;
    occInfantCount: number;
    boardBasis: string;

    //costing fields
    costingType: any;
    noOfUnits: number;

    //secondary costing fields
    secondaryCostingType: any;
    secondaryNoOfUnits: number;

    /* if there are booking items with same type and same date range then that booking item passengers will remove
     automatically from this flag true item */
    passengerValidated: boolean;

    //Key Controls
    kcCompany: string;
    kcDivision: string;
    kcBrand: string;
    kcDistributionChannel: string;

    itemSavedStatus: boolean;

}

export class BookingItemGridParam {
    data: BookingItem;
}
