import { DMCQueryParamsService } from '../../util/framework/dmc-query-params.service';
import { RequestService } from '../../util/framework/request.service';
import { UrlPaths } from '../url-paths';
import * as i0 from "@angular/core";
import * as i1 from "../../util/framework/dmc-query-params.service";
import * as i2 from "../../util/framework/request.service";
var BackgroundTaskApiService = /** @class */ (function () {
    function BackgroundTaskApiService(queryParamsService, requestService) {
        this.queryParamsService = queryParamsService;
        this.requestService = requestService;
    }
    BackgroundTaskApiService.prototype.getTask = function (taskId) {
        return this.requestService.get(UrlPaths.OP_BACKGROUND_URL_KEY, [UrlPaths.OP_BACKGROUND_VERSION, UrlPaths.BACKGROUND_TASKS, taskId ? taskId.toString() : '0'], null);
    };
    BackgroundTaskApiService.ngInjectableDef = i0.defineInjectable({ factory: function BackgroundTaskApiService_Factory() { return new BackgroundTaskApiService(i0.inject(i1.DMCQueryParamsService), i0.inject(i2.RequestService)); }, token: BackgroundTaskApiService, providedIn: "root" });
    return BackgroundTaskApiService;
}());
export { BackgroundTaskApiService };
