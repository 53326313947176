<div class="tc-form-edit">


    <div
            class="detail-group"
            *tcScrollSection="supplierInfoSection;id:supplierInfoSection?.id;title:supplierInfoSection?.tabTitle;error:setupSectionTabError(supplierInfoSection)">
        <div
                class="detail-group__heading">
            <tc-section-heading
                    [pageSection]="supplierInfoSection"
                    (actionClick)="onHeaderActionClick($event)">
            </tc-section-heading>
        </div>
        <div class="detail-group__content">
            <div *ngIf="resourceTypes && resourceTypes.length > 0">
                <tc-supplier-information
                        [supplier]="supplier"
                        [titleMap]="titles"
                        [resourceTypes]="resourceTypes"
                        [selectedResTypes]="selectedResTypes"
                        [validResource]="validResource"

                        [cityList]="cityList"
                        [stateList]="stateList"
                        [countryList]="countryList"
                        [countryStateCityChangeMap]="countryStateCityChangeMap"
                        (newCountryStateCityObserver)="onNewCountryStateCityObserver($event)"
                        (selectCountry)="onSelectCountry($event)"
                        (selectState)="onSelectState($event)"
                        (selectCity)="onSelectCity($event)"
                        (countryTextChange)="onCountryTextChange($event)"
                        (stateTextChange)="onStateTextChange($event)"
                        (cityTextChange)="onCityTextChange($event)"
                        [profileTypes]="profileTypes"
                        [supplierTypes]="supplierTypes"
                        [localeOptions]="locales"
                        (selectResourceTypes)="onResourceTypeSelection($event)"
                        (changeActiveStatus)="onChangeActiveStatus($event)"
                        (changeServiceRequiredStatus)="onChangeServiceRequiredStatus($event)"
                        (changeFormValidity)="onChangeSupplierInfoForm($event)">
                </tc-supplier-information>
            </div>
        </div>
    </div>
    <div
            class="detail-group"
            *tcScrollSection="supplierKeyControlSection;id:supplierKeyControlSection?.id;title:supplierKeyControlSection?.tabTitle;error:setupSectionTabError(supplierKeyControlSection)">
        <div
                class="detail-group__heading">
            <tc-section-heading
                    [pageSection]="supplierKeyControlSection"
                    (actionClick)="onHeaderActionClick($event)">
            </tc-section-heading>
        </div>
        <div class="detail-group__content">
                <tc-key-controls-edit-view
                        [object]="supplier"
                        [userProfile]="userProfile"
                        [disableKeyControlEdit]="disableKeyControlEdit"
                        (changeFormValidity)="onChangeKeyControlInfoForm($event)">
                </tc-key-controls-edit-view>
        </div>
    </div>

    <div
            class="detail-group"
            *tcScrollSection="contactDetailsSection;id:contactDetailsSection?.id;title:contactDetailsSection?.tabTitle;error:setupSectionTabError(contactDetailsSection)">
        <div
                class="detail-group__heading">
            <tc-section-heading
                    [pageSection]="contactDetailsSection"
                    (actionClick)="onHeaderActionClick($event)">
            </tc-section-heading>
        </div>
        <div class="detail-group__content">
            <tc-contact-details
                    [titles]="titles"
                    [categories]="categories"
                    [contactDetails]="contactDetails"
                    [defaultContactCategory]="defaultContactCategory"

                    [cityList]="cityList"
                    [stateList]="stateList"
                    [countryList]="countryList"
                    [countryStateCityChangeMap]="countryStateCityChangeMap"
                    (newCountryStateCityObserver)="onNewCountryStateCityObserver($event)"
                    (selectCountry)="onSelectCountry($event)"
                    (selectState)="onSelectState($event)"
                    (selectCity)="onSelectCity($event)"
                    (countryTextChange)="onCountryTextChange($event)"
                    (stateTextChange)="onStateTextChange($event)"
                    (cityTextChange)="onCityTextChange($event)"

                    (changeFormValidity)="onChangePersonalContactForm($event)">
            </tc-contact-details>
        </div>
    </div>

    <div
            class="detail-group"
            *tcScrollSection="paymentInfoSection;id:paymentInfoSection?.id;title:paymentInfoSection?.tabTitle;error:setupSectionTabError(paymentInfoSection)">
        <div
                class="detail-group__heading">
            <tc-section-heading
                    [pageSection]="paymentInfoSection"
                    (actionClick)="onHeaderActionClick($event)">
            </tc-section-heading>
        </div>
        <div class="detail-group__content">
            <tc-payment-information
                    [currencyList]="currencyList"
                    [payMethods]="payMethods"
                    [paymentInfo]="supplier?.paymentInfo"
                    (changeFormValidity)="onChangeSupplierPaymentForm($event)"
            ></tc-payment-information>
        </div>
    </div>

    <div
            class=""
            *tcScrollSection="additionalContactsSection;id:additionalContactsSection?.id;title:additionalContactsSection?.tabTitle;error:setupSectionTabError(additionalContactsSection)">
        <div
                class="">
            <tc-section-heading
                    [pageSection]="additionalContactsSection"
                    (actionClick)="onHeaderActionClick($event)">
            </tc-section-heading>
        </div>
        <div class="">
            <tc-additional-contacts
                    [additionalContactList]="additionalContactDetails"
                    [titles]="titles"
                    [categories]="categories"
                    [additionalContactSummary]="additionalContactSummary"
                    [additionalContactContents]="additionalContactContents"
                    [defaultContactCategory]="defaultContactCategory"
                    [cityList]="cityList"
                    [stateList]="stateList"
                    [countryList]="countryList"
                    [countryStateCityChangeMap]="countryStateCityChangeMap"
                    (newCountryStateCityObserver)="onNewCountryStateCityObserver($event)"
                    (selectCountry)="onSelectCountry($event)"
                    (selectState)="onSelectState($event)"
                    (selectCity)="onSelectCity($event)"
                    (countryTextChange)="onCountryTextChange($event)"
                    (stateTextChange)="onStateTextChange($event)"
                    (cityTextChange)="onCityTextChange($event)"
                    (addressModified)="onAddressModified()"
                    (changeFormValidity)="onChangeAdditionalContactForm($event)">
            </tc-additional-contacts>
        </div>
    </div>

    <!--Rooms-->
<!--    <div-->
<!--            class=""-->
<!--            *tcScrollSection="roomGrpSection;id:roomGrpSection?.id;title:roomGrpSection?.tabTitle;error:setupSectionTabError(roomGrpSection)">-->

<!--        <div class="">-->
<!--            <tc-section-heading-->
<!--                    [pageSection]="roomGrpSection"-->
<!--                    (actionClick)="onHeaderActionClick($event)">-->
<!--            </tc-section-heading>-->
<!--        </div>-->
<!--        <div class="">-->
<!--            <tc-room-grp-view-card-->
<!--                    [roomTypeOptions]="roomTypeOptions"-->
<!--                    [roomGrpList]="supplier?.resourceCollection?.roomCollection? supplier.resourceCollection?.roomCollection : []"-->
<!--                    [roomGrpSummaries]="roomsContents"-->
<!--                    [roomGrpCollectionSummaryCard]="roomsSummary"-->
<!--                    [masterRoomFacilities]="masterRoomFacilities"-->
<!--                    (roomGroupsModified)="onChangeRooms($event)"-->
<!--                    (occupancyClicked)="onOccupancyClick($event)">-->
<!--            </tc-room-grp-view-card>-->
<!--        </div>-->
<!--    </div>-->

<!--    &lt;!&ndash;Rooms Allocation Groups&ndash;&gt;-->
<!--    <div-->
<!--            [style.display]="showRoomCostAddButton?'block':'none'"-->
<!--            class=""-->
<!--            *tcScrollSection="roomsSection;id:roomsSection?.id;title:roomsSection?.tabTitle;error:setupSectionTabError(roomsSection)">-->
<!--        <div class="">-->
<!--            <tc-section-heading-->
<!--                    [pageSection]="roomsSection"-->
<!--                    (actionClick)="onHeaderActionClick($event)">-->
<!--            </tc-section-heading>-->
<!--        </div>-->
<!--        <div class="">-->
<!--            <tc-rooms-view-card-->
<!--                    [roomTypeOptions]="roomTypeOptions"-->
<!--                    [roomsList]="supplier?.rooms ? supplier.rooms : []"-->
<!--                    [roomContents]="roomsContents"-->
<!--                    [roomSummary]="roomsSummary"-->
<!--                    [masterRoomFacilities]="masterRoomFacilities"-->
<!--                    (roomsModified)="onRoomsModified()"-->
<!--                    (occupancyClicked)="onOccupancyClick($event)">-->
<!--            </tc-rooms-view-card>-->
<!--        </div>-->
<!--    </div>-->

    <div>
        <div
                class=""
                *tcScrollSection="supplierCostSetupSection;id:supplierCostSetupSection?.id;title:supplierCostSetupSection?.tabTitle;error:setupSectionTabError(supplierCostSetupSection)">
            <div
                    class="">
                <tc-section-heading
                        [title]="supplierCostSetupSection?.mainTitle"
                        [kebabItems]="costSetupKebabMenu">
                </tc-section-heading>
            </div>
            <div class="">
                <tc-supplier-cost-setup
                        [resourceCostData]="supplier?.seasons ? supplier?.seasons : []"
                        [showAddButton]="showTransportCostAddButton"
                        [supplier]="supplier"
                        [resourceTypes]="resourceTypes"
                        [selectedResourceTypes]="selectedResProfTypes"
                        [selectedProfileTypes]="profileTypes"
                        [transferModes]="transferModes"
                        [routes]="routes">
                </tc-supplier-cost-setup>
            </div>
        </div>
    </div>

    <div
            class=""
            *tcScrollSection="supplierAttributesSection;id:supplierAttributesSection?.id;title:supplierAttributesSection?.tabTitle;error:setupSectionTabError(supplierAttributesSection)">
        <div
                class="">
            <tc-section-heading
                    [pageSection]="supplierAttributesSection"
                    (actionClick)="onHeaderActionClick($event)">
            </tc-section-heading>
        </div>
        <div class="">
            <tc-supplier-attributes
                    [supplierAttributes]="supplier?.supplierAttributes">
            </tc-supplier-attributes>
        </div>
    </div>

    <div
            class=""
            *tcScrollSection="taxSchemeSection;id:taxSchemeSection?.id;title:taxSchemeSection?.tabTitle;error:setupSectionTabError(taxSchemeSection)">
        <div
                class="">
            <tc-section-heading
                    [pageSection]="taxSchemeSection"
                    (actionClick)="onHeaderActionClick($event)">
            </tc-section-heading>
        </div>
        <div class="">
            <tc-tax-schemes
                    [taxSchemeList]="supplier?.taxSchemes ? supplier.taxSchemes : []"
                    [taxSchemes]="taxSchemes"
                    [taxSchemeContents]="taxSchemeContents"
                    [taxSchemeSummary]="taxSchemeSummary"
                    [resourceType]="resourceType"
                    [supplierItems]="selectedResTypes"
                    (taxSchemeModified)="onTaxSchemeModified()">
            </tc-tax-schemes>
        </div>
    </div>

    <div
            class=""
            *tcScrollSection="documentSection;id:documentSection?.id;title:documentSection?.tabTitle;error:setupSectionTabError(documentSection)">
        <div
                class="">
            <tc-section-heading
                    [pageSection]="documentSection"
                    (actionClick)="onHeaderActionClick($event)">
            </tc-section-heading>
        </div>
        <div class="">
            <tc-document-view-card
                    [formatOptions]="formatOptions"
                    [frequencyOptions]="frequencyOptions"
                    [contactCategoryOptions]="categories"
                    [dispatchTypes]="dispatchTypes"
                    [docTypeOptions]="docTypeOptions"
                    [documentList]="supplier?.documents ? supplier.documents : []"
                    [documentContents]="documentContents"
                    [documentSummary]="documentSummary"
                    (documentsModified)="onDocumentsModified()">
            </tc-document-view-card>
        </div>
    </div>
</div>
