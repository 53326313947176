import { Component, Input, OnInit } from '@angular/core';
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import { TcApiError } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-api-error';
import { TcHttpError } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-http-error';
import { DialogModel } from '@tc-core/model/it/codegen/ui/framework/dialog-model';
import { LEVEL, ModalData } from '@tc-core/model/it/codegen/ui/framework/modal-data';
import { ConfigLoader, EventManager, FocusViewManager } from '@tc-core/util/framework';
import { CommonHelper } from '@tc-core/util/helpers';
import { SpinnerService } from '@tc-core/util/ui';
import { DialogService } from '@tc/dialog/dialog.service';
import { FocusViewService } from '@tc/focus-view/focus-view.service';
import { Subscription } from 'rxjs';
import { TCO } from '../../constants';
import { LeaveSetupFocusViewComponent } from '../leave-setup-focus-view/leave-setup-focus-view.component';
import { LeaveSearchCriteria } from '../../models/criteria/leave-search-criteria';
import { ResourceLeaveSummary } from '../../models/resource-leave-summary';
import { Leave } from '../../models/resource/leave';
import { RootService } from '../../services/util/core-services/root.service';
import { DataKey, DataStoreService } from '../../services/util/framework/data-store.service';
import { DMCQueryParamsService } from '../../services/util/framework/dmc-query-params.service';
import {ResourceAvailabilityService} from '../../services/backend-consumers/supplier-service/resource-availability.service';
import {ResponseUtil} from '../../services/util/response/response-util.service';
import {ServiceNote} from '../../models/reservation/service-note';

@Component({
    selector: 'tc-availability-calendar-page',
    templateUrl: './availability-calendar-page.component.html'
})
export class AvailabilityCalendarPageComponent implements OnInit {

    calendarPlugins = [dayGridPlugin, interactionPlugin];

    @Input() resourceSummary: any;
    assignments = [];
    events = [];
    leaveEvents = [];
    calendarEvents = [];
    selectedResourceType:string;
    resourceLeaveSummary: ResourceLeaveSummary = new ResourceLeaveSummary();
    leaveSetupSearchCriteria: LeaveSearchCriteria = new LeaveSearchCriteria();
    navConfig: any[] = [];
    leaveTypeConfig: any[] = [];
    private focusviewCloseSubject: Subscription = new Subscription();
    private deletedEventObsv: Subscription = new Subscription();

    constructor(private resourceAvailabilityService: ResourceAvailabilityService, private spinnerService: SpinnerService,
                private dataStore: DataStoreService,
                private em: EventManager,
                private configLoader: ConfigLoader,
                private focusViewService: FocusViewService,
                private queryParamService: DMCQueryParamsService,
                private rootService: RootService,
                private focusViewManager: FocusViewManager,
                private dialogService: DialogService,
                private commonHelper: CommonHelper
    ) { }

    ngOnInit() {
        this.navConfig = this.configLoader.configurations.get(TCO.CONF.CONF_PAGE_SECTION_CONFIG)['availability_calendar_side_nav']['calendar_page'];
    }

    getLeaves() {
        let criteria = new LeaveSearchCriteria();
        criteria.activeStatus = true;
        this.resourceAvailabilityService.searchLeaveSummaries(criteria, DataKey.resourceLeaves);
        this.spinnerService.show();
        let x = this.dataStore.get(DataKey.resourceLeaves).subscribe(value => {
            let leaveData: Leave[];
            this.resourceLeaveSummary = ResponseUtil.getFirstData(value);
            leaveData = this.resourceLeaveSummary ? this.resourceLeaveSummary.leaves : [];
            this.spinnerService.hide();
            this.events = [];
            let leaveConfig: any;
            if (leaveData && leaveData.length > 0) {
                let events = [];
                leaveData.forEach(leave => {
                    let event: any = {};
                    event.leaveId = {
                        'leaveDate': leave.leaveDate,
                        'resourceId': leave.resourceId,
                        'groupId': leave.groupId
                    };
                    leaveConfig = this.leaveTypeConfig.filter(val => val.code === leave.leaveType)[0];
                    event.groupId = leave.groupId;
                    event.resourceId = leave.resourceId;
                    event.startTime = leave.startTime;
                    event.endTime = leave.endTime;
                    event.leaveType = leave.leaveType;
                    event.type = leaveConfig.code;
                    // event.color = this.leaveTypeConfig.filter(val => val.code === leave.leaveType)[0].color;
                    event.innerHtml = leaveConfig.innerHtml;
                    event.title = leave.note;
                    event.date = leave.leaveDate;

                    events.push(event);
                });
                this.leaveEvents = events;
                this.events = this.leaveEvents;

            }
            if(this.assignments && this.assignments.length > 0){
                this.assignments.forEach(val => this.events.push(val))
            }
            this.calendarEvents = this.events;
        });
        // this.calendarComponent.fullCalendar('renderEvents', this.leaveEvents, true);
    }

    eventClick(calEvent) {
        if (calEvent.jsEvent && calEvent.jsEvent.target.id === 'Delete') {
            this.removeEvent(calEvent.event);
        }
    }

    removeEvent(event: any) {
        let confirmDataObject = new DialogModel(
            true,
            LEVEL.WARNING,
            'Delete Leave',
            'This Leave will be deleted. Do you wish to proceed?',
            true,
            3000
        );
        this.dialogService.confirm(confirmDataObject).subscribe(
            (res) => {
                if (res === true) {
                    this.getDeleteObserver();
                    this.dataStore.set(DataKey.deletedLeaves, null, true);
                    let criteria: LeaveSearchCriteria;
                    criteria = event.extendedProps.leaveId;
                    this.resourceAvailabilityService.deleteLeave(criteria);
                    this.dataStore.set(DataKey.resourceLeaves, null, true);
                }
            });
    }

    private getDeleteObserver() {
        this.deletedEventObsv = this.dataStore.get(DataKey.deletedLeaves).subscribe((data) => {
            if (this.commonHelper.dataValidity(data)) {
                this.deletedEventObsv.unsubscribe();
                setTimeout(() => {
                    this.getLeaves();
                }, 0);
            } else if (this.commonHelper.isEmptyData(data)) {

            } else if (data instanceof TcApiError) {

            } else if (data instanceof TcHttpError) {

            }
        }, (error: any) => {
            console.log(error);
        });

    }

    handleDateClick(arg) { // handler method
        // this.leaveSetupSearchCriteria.leaveDate = arg.dateStr;
        this.leaveSetupSearchCriteria.resourceType = this.selectedResourceType;
        this.leaveSetupSearchCriteria.activeStatus = true;
        let endDate = new Date(arg.end - 1).toISOString().slice(0,10);
        setTimeout(() => {
            let fabActions = [];

            let navItemsArray = this.configLoader.configurations.get(TCO.CONF.CONF_NAVIGATION);
            this.configLoader.configurations.get(TCO.CONF.CONF_FOCUS_VIEW).LEAVE_SETUP_FOCUS_VIEW.fabAction
                .forEach(val => {
                    for (let key in navItemsArray) {
                        if (val === navItemsArray[key]['id']) {
                            fabActions.push(navItemsArray[key]);
                        }
                    }
                });
            let dataObject = new ModalData(
                LEVEL.SUCCESS,
                'Leave Setup',
                null,
                null,
                '',
                LeaveSetupFocusViewComponent,
                {
                    'leaveSearchCriteria': this.leaveSetupSearchCriteria,
                    'isInsideForm': true,
                    'selectedStartDate': arg.startStr,
                    'selectedEndDate': endDate,
                    'leaveTypes': this.leaveTypeConfig
                },
                {'label': 'close'},
                '',
                '',
                fabActions,
                '', "From: " + arg.startStr +" To: " + endDate
            );

            this.focusviewCloseSubject = this.dataStore.get(DataKey.leaveSetupFocusViewClose).subscribe(c => {
                if (c) {
                    this.getLeaves();
                }
            });
            this.focusViewService.confirm(dataObject).subscribe(res => {
                if (res) {
                    console.log(res);
                }
            });
        }, 0);
    }

    filterSelectedEvents(event: any){
        this.selectedResourceType = event;
        // this.calendarEvents = this.events.filter(value => event.includes(value.type));
    }



}
