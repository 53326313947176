/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../node_modules/@tc/content-strip/content-strip.component.ngfactory";
import * as i2 from "@tc/content-strip/content-strip.component";
import * as i3 from "../../../business/resource-allocation-card/resource-allocation-card.component.ngfactory";
import * as i4 from "../../../business/resource-allocation-card/resource-allocation-card.component";
import * as i5 from "@tc-core/util/framework/config-loader.service";
import * as i6 from "../vehicle-assign-detail-view/vehicle-assign-detail-view.component.ngfactory";
import * as i7 from "../vehicle-assign-detail-view/vehicle-assign-detail-view.component";
import * as i8 from "@angular/forms";
import * as i9 from "../../../../../node_modules/@tc/expanded-content-card/expanded-content-card.component.ngfactory";
import * as i10 from "@tc/expanded-content-card/expanded-content-card.component";
import * as i11 from "@angular/common";
import * as i12 from "@tc/directives/expansion-panel/expansion-panel.directive";
import * as i13 from "@tc-core/util/security/authorization.service";
import * as i14 from "@tc/pipes/advanced-sorter.pipe";
import * as i15 from "@tc/pipes/advanced-pager.pipe";
import * as i16 from "./adv-vehicle-assign-search-result.component";
import * as i17 from "../../../services/util/change-detector/change-detector.service";
var styles_AdvVehicleAssignSearchResultComponent = [];
var RenderType_AdvVehicleAssignSearchResultComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AdvVehicleAssignSearchResultComponent, data: {} });
export { RenderType_AdvVehicleAssignSearchResultComponent as RenderType_AdvVehicleAssignSearchResultComponent };
function View_AdvVehicleAssignSearchResultComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [], [[8, "id", 0]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "tc-content-strip", [], null, [[null, "actionClick"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("actionClick" === en)) {
        var pd_0 = (_co.onActionClick(_v.parent.context.$implicit, $event) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.onVehicleSelection(_v.parent.context.$implicit, _v.parent.context.index) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_ContentStripComponent_0, i1.RenderType_ContentStripComponent)), i0.ɵdid(2, 114688, null, 0, i2.ContentStripComponent, [], null, { actionClick: "actionClick" }), (_l()(), i0.ɵeld(3, 0, null, 0, 1, "tc-resource-allocation-card", [], null, [[null, "resourceCalendarClick"], [null, "eventClick"], [null, "targetClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("resourceCalendarClick" === en)) {
        var pd_0 = (_co.onResourceCalendarClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("eventClick" === en)) {
        var pd_1 = (_co.onEventClick($event, _v.parent.context.$implicit) !== false);
        ad = (pd_1 && ad);
    } if (("targetClick" === en)) {
        var pd_2 = (_co.onTargetClick($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i3.View_ResourceAllocationCardComponent_0, i3.RenderType_ResourceAllocationCardComponent)), i0.ɵdid(4, 376832, null, 0, i4.ResourceAllocationCardComponent, [i5.ConfigLoader, i0.KeyValueDiffers], { summaryContent: [0, "summaryContent"], itemGroup: [1, "itemGroup"], bookingData: [2, "bookingData"], bookingDataArray: [3, "bookingDataArray"], isBulkAssignment: [4, "isBulkAssignment"] }, { resourceCalendarClick: "resourceCalendarClick", eventClick: "eventClick", targetClick: "targetClick" })], function (_ck, _v) { var _co = _v.component; _ck(_v, 2, 0); var currVal_1 = _v.parent.context.$implicit; var currVal_2 = _co.itemGroup; var currVal_3 = _co.bookingData; var currVal_4 = _co.bookingDataArray; var currVal_5 = _co.isBulkAssignment; _ck(_v, 4, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, function (_ck, _v) { var currVal_0 = i0.ɵinlineInterpolate(1, "clientSumExp_", _v.parent.context.index, ""); _ck(_v, 0, 0, currVal_0); }); }
function View_AdvVehicleAssignSearchResultComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-vehicle-assign-detail-view", [], null, [[null, "headerClick"], [null, "clickOutSide"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("headerClick" === en)) {
        var pd_0 = (_co.onHeaderClick(_co.selectedVehicle) !== false);
        ad = (pd_0 && ad);
    } if (("clickOutSide" === en)) {
        var pd_1 = (_co.onOutsideClick($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i6.View_VehicleAssignDetailViewComponent_0, i6.RenderType_VehicleAssignDetailViewComponent)), i0.ɵdid(1, 114688, null, 0, i7.VehicleAssignDetailViewComponent, [i8.FormBuilder, i5.ConfigLoader], { summaryCardData: [0, "summaryCardData"], bookingData: [1, "bookingData"], bookingDataArray: [2, "bookingDataArray"], assignedVehicle: [3, "assignedVehicle"], footerDataMap: [4, "footerDataMap"], isBulkAssignment: [5, "isBulkAssignment"] }, { headerClick: "headerClick", clickOutSide: "clickOutSide" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.selectedVehicle; var currVal_1 = _co.bookingData; var currVal_2 = _co.bookingDataArray; var currVal_3 = _co.assignedVehicle; var currVal_4 = _co.footerDataMap; var currVal_5 = _co.isBulkAssignment; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
function View_AdvVehicleAssignSearchResultComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [], [[8, "id", 0]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 5, "tc-expanded-content-card", [["class", "tc-expanded-content-card tc-form-view"]], null, null, null, i9.View_ExpandedContentCardComponent_0, i9.RenderType_ExpandedContentCardComponent)), i0.ɵdid(2, 114688, null, 0, i10.ExpandedContentCardComponent, [], { isEdit: [0, "isEdit"] }, null), (_l()(), i0.ɵeld(3, 0, null, 0, 1, "tc-resource-allocation-card", [], null, [[null, "resourceCalendarClick"], [null, "eventClick"], [null, "targetClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("resourceCalendarClick" === en)) {
        var pd_0 = (_co.onResourceCalendarClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("eventClick" === en)) {
        var pd_1 = (_co.onEventClick($event, _v.parent.context.$implicit) !== false);
        ad = (pd_1 && ad);
    } if (("targetClick" === en)) {
        var pd_2 = (_co.onTargetClick($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i3.View_ResourceAllocationCardComponent_0, i3.RenderType_ResourceAllocationCardComponent)), i0.ɵdid(4, 376832, null, 0, i4.ResourceAllocationCardComponent, [i5.ConfigLoader, i0.KeyValueDiffers], { summaryContent: [0, "summaryContent"], itemGroup: [1, "itemGroup"], bookingData: [2, "bookingData"], bookingDataArray: [3, "bookingDataArray"], isBulkAssignment: [4, "isBulkAssignment"] }, { resourceCalendarClick: "resourceCalendarClick", eventClick: "eventClick", targetClick: "targetClick" }), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_AdvVehicleAssignSearchResultComponent_5)), i0.ɵdid(6, 16384, null, 0, i11.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = false; _ck(_v, 2, 0, currVal_1); var currVal_2 = _v.parent.context.$implicit; var currVal_3 = _co.itemGroup; var currVal_4 = _co.bookingData; var currVal_5 = _co.bookingDataArray; var currVal_6 = _co.isBulkAssignment; _ck(_v, 4, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_7 = _co.selectedVehicle; _ck(_v, 6, 0, currVal_7); }, function (_ck, _v) { var currVal_0 = i0.ɵinlineInterpolate(1, "clientDetailExp_", _v.parent.context.index, ""); _ck(_v, 0, 0, currVal_0); }); }
function View_AdvVehicleAssignSearchResultComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 9, "div", [["class", ""]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 8, "div", [["tabindex", "-1"], ["tcExpansionPanel", ""]], null, [[null, "clickedOutside"], [null, "collapse"], ["document", "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("document:click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 4).handleClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("clickedOutside" === en)) {
        var pd_1 = (_co.onOutsideClick(_co.selectedVehicle) !== false);
        ad = (pd_1 && ad);
    } if (("collapse" === en)) {
        var pd_2 = ((_co.expandedPanelIndex = (0 - 1)) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i0.ɵdid(3, 278528, null, 0, i11.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { ngClass: [0, "ngClass"] }, null), i0.ɵdid(4, 606208, null, 1, i12.ExpansionPanelDirective, [i0.ElementRef, i13.AuthorizationService], { enabled: [0, "enabled"], exclude: [1, "exclude"] }, { clickedOutside: "clickedOutside", collapse: "collapse" }), i0.ɵqud(335544320, 1, { tooltipDirective: 0 }), i0.ɵpad(6, 3), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AdvVehicleAssignSearchResultComponent_3)), i0.ɵdid(8, 81920, null, 0, i12.ExpansionPanelSummaryDirective, [i0.ViewContainerRef, i0.TemplateRef, i12.ExpansionPanelDirective], null, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AdvVehicleAssignSearchResultComponent_4)), i0.ɵdid(10, 81920, null, 0, i12.ExpansionPanelDetailDirective, [i0.ViewContainerRef, i0.TemplateRef, i12.ExpansionPanelDirective], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.hasSelectedThis(_v.context.$implicit) ? "tc-expanded-content-card--selected" : ""); _ck(_v, 3, 0, currVal_0); var currVal_1 = true; var currVal_2 = _ck(_v, 6, 0, "event", "tc-strip-timeline__target tc-strip-timeline__target--single ng-star-inserted", "tc-strip-timeline__target tc-strip-timeline__target--group"); _ck(_v, 4, 0, currVal_1, currVal_2); _ck(_v, 8, 0); _ck(_v, 10, 0); }, null); }
function View_AdvVehicleAssignSearchResultComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", ""], ["id", "vehicle-assign-scroll-container"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 3, null, View_AdvVehicleAssignSearchResultComponent_2)), i0.ɵdid(2, 278528, null, 0, i11.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i0.ɵppd(3, 3), i0.ɵppd(4, 3)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 2, 0, _ck(_v, 4, 0, i0.ɵnov(_v.parent, 0), i0.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i0.ɵnov(_v.parent, 1), _co.resultsList, _co.pager.currPage, _co.pager.itemsPerPage)), _co.sortingHandler, _co.sorter)); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_AdvVehicleAssignSearchResultComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i14.AdvancedSorterPipe, []), i0.ɵpid(0, i15.AdvancedPagerPipe, []), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AdvVehicleAssignSearchResultComponent_1)), i0.ɵdid(3, 16384, null, 0, i11.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.resultsList; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_AdvVehicleAssignSearchResultComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-adv-vehicle-assign-search-result", [], null, null, null, View_AdvVehicleAssignSearchResultComponent_0, RenderType_AdvVehicleAssignSearchResultComponent)), i0.ɵdid(1, 114688, null, 0, i16.AdvVehicleAssignSearchResultComponent, [i17.ChangeDetectorService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AdvVehicleAssignSearchResultComponentNgFactory = i0.ɵccf("tc-adv-vehicle-assign-search-result", i16.AdvVehicleAssignSearchResultComponent, View_AdvVehicleAssignSearchResultComponent_Host_0, { sorter: "sorter", sortingHandler: "sortingHandler", sorters: "sorters", resultsList: "resultsList", selectedVehicle: "selectedVehicle", bookingData: "bookingData", bookingDataArray: "bookingDataArray", isBulkAssignment: "isBulkAssignment", assignedVehicle: "assignedVehicle", pager: "pager", itemGroup: "itemGroup" }, { vehicleSelect: "vehicleSelect", cardScroll: "cardScroll", actionClick: "actionClick", outsideSelect: "outsideSelect", headerClick: "headerClick", resourceCalendarClick: "resourceCalendarClick", eventClick: "eventClick", targetClick: "targetClick" }, []);
export { AdvVehicleAssignSearchResultComponentNgFactory as AdvVehicleAssignSearchResultComponentNgFactory };
