import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DocumentAddCardComponent} from './document-add-card.component';
import {MatCheckboxModule} from '@angular/material';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
    imports: [
        CommonModule,
        MatCheckboxModule,
        TranslateModule.forChild()
    ],
    declarations: [DocumentAddCardComponent],
    exports: [DocumentAddCardComponent]
})
export class DocumentAddCardModule {
}
