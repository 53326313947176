import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ConfigLoader } from '@tc-core/util/framework';
import { ICellRendererParams } from 'ag-grid-community';
import { ActionsComponent } from '../../../widgets/framework/ag-grid-custom-components/renderers/actions/actions.component';
// tslint:disable-next-line:max-line-length
import { CheckBoxRendererEditorComponent } from '../../../widgets/framework/ag-grid-custom-components/renderers/check-box-selector-renderer/check-box-renderer-editor.component';
import { NoteEditorInputComponent } from '../../../widgets/framework/ag-grid-custom-components/renderers/note-editor-input/note-editor-input.component';
import { GridToolTipComponent } from '../../../widgets/framework/ag-grid-custom-components/renderers/tooltip/grid-tool-tip.component';
import { TCO } from '../../../constants';
import {BookingNote, BookingNoteType} from '../../../models/reservation-v2/booking-note';
import { DataKey, DataStoreService } from '../../../services/util/framework/data-store.service';
import { GridColumnDefinitionProcessorService } from '../../../services/util/pre-processors/grid-column-definition-processor.service';

export class BookingNoteGroup
{
    bookingReferenceId: string;
    bookingReferenceIdWithoutReturnFlag: string;
    bookingNotes: BookingNote[] = [];
}

@Component({
    selector: 'tc-notes-editor',
    templateUrl: './notes-editor.component.html'
})
export class NotesEditorComponent implements OnInit
{
    constructor(
        private dataStore: DataStoreService,
        private translateService: TranslateService,
        private configLoader: ConfigLoader,
        private columnDefinitionProcessorService: GridColumnDefinitionProcessorService
    )
    {
        this.translateService.onLangChange.subscribe(() => {
            this.gridApi.refreshHeader();
        });
    }

    @Input() bookingNoteGroups: BookingNoteGroup[] = [];
    @Input() assignmentNotes: any[] = [];
    @Input() bookingNotesOnly = false;

    bookingNotesColumnDefs: any[];

    pageOptionsAvailability: any;
    bookingReferenceIdWithoutReturnFlag: any;

    gridApi;
    public frameworkComponents: any = {
        tooltipComponent: GridToolTipComponent,
        noteEditorInputComponent: NoteEditorInputComponent,
        checkBoxRendererEditor: CheckBoxRendererEditorComponent
    };
    defColumnDefs = {
        headerValueGetter: (parameters: ICellRendererParams): string => {
            const headerIdentifier = parameters.colDef.headerName;
            if (headerIdentifier) {
                return this.translateService.instant(headerIdentifier);
            }
            return '';
        }
    };

    columnDefs = [
        {
            headerName: 'Note',
            field: 'text',
            sortable: false,
            resizable: true,
            editable: true,
            cellEditor: 'agLargeTextCellEditor',
            cellEditorParams: {maxLength: 1000},
            tooltipField: 'text',
            cellRenderer: 'noteEditorInputComponent',
            tooltipComponent: 'tooltipComponent',
            tooltipComponentParams: {
                tooltipFunc: (data) => {
                    return data.text;
                }
            }
        },
        {
            headerName: 'Actions',
            field: 'actions',
            width: 40,
            cellRendererFramework: ActionsComponent,
            headerClass: 'actions-header',
            cellRendererParams: {
                actions: [
                    {
                        icon: 'delete',
                        action: this.onDeleteNote.bind(this)
                    }
                ]
            },
            resizable: true,
            pinned: 'right',
            cellClass: ['tc-ag-grid-cell--read-only', 'actions-column']
        }
    ];

    // bookingNotesColumnDefs = [
    //     {
    //         headerName: 'Note',
    //         field: 'note',
    //         minWidth: 400,
    //         sortable: true,
    //         editable: false,
    //         resizable: true,
    //         tooltipField: 'note',
    //         tooltipComponent: 'tooltipComponent',
    //         tooltipComponentParams: {
    //             tooltipFunc: (data) => {
    //                 return data.note ? data.note.replaceAll('• ', '<br>• ') : '';
    //             },
    //             width: '300px'
    //         }
    //     },
    //     {
    //         headerName: 'Type',
    //         field: 'type',
    //         minWidth: 60,
    //         sortable: true,
    //         editable: false,
    //         resizable: true
    //     },
    //     {
    //         headerName: 'System Generated',
    //         field: 'systemGenerated',
    //         minWidth: 120,
    //         editable: false,
    //         cellRendererFramework: this.frameworkComponents.checkBoxRendererEditor
    //     },
    // ];

    ngOnInit()
    {
        this.pageOptionsAvailability = this.configLoader.configurations.get(TCO.CONF.CONF_PAGE_OPTION_AVAILABILITY).NOTES;
        this.bookingNotesColumnDefs = this.columnDefinitionProcessorService.createColumnDefs(
            this.configLoader.configurations.get(TCO.CONF.CONF_NOTES_GRID),
            this
        );
        this.bookingReferenceIdWithoutReturnFlag = this.configLoader.configurations
                                                       .get(TCO.CONF.CONF_PAGE_OPTION_AVAILABILITY).BOOKING_MANAGEMENT;

        if (this.bookingReferenceIdWithoutReturnFlag) {
            if (this.bookingReferenceIdWithoutReturnFlag.bookingItemReferenceWithReturnFlag
                && this.bookingReferenceIdWithoutReturnFlag.bookingItemReferenceWithReturnFlag !== true
                && this.bookingNoteGroups) {
                this.bookingNoteGroups.forEach(group => {
                    if (group.bookingReferenceId) {
                        group.bookingReferenceIdWithoutReturnFlag = group.bookingReferenceId
                            .replace('_R', '')
                            .replace(/[_][S][0-9]+/g, '');
                    }
                });
            }
        }
    }

    public onSave()
    {
        this.dataStore.set(DataKey.popupClose, true);
    }

    public onClose($event: MouseEvent)
    {
        this.dataStore.set(DataKey.popupClose, false);
    }

    public isSaveButtonEnable()
    {
        return true;
    }

    public onGridReadyAssignmentNotes(params: any)
    {
        this.gridApi = params.api;
        this.gridApi.sizeColumnsToFit();
    }

    onFirstDataRendered(params)
    {
        this.gridApi = params.api;
        this.gridApi.sizeColumnsToFit();
    }

    public onDeleteNote(params: any)
    {
        if (params && params.data && this.assignmentNotes.indexOf(params.data) !== -1) {
            this.assignmentNotes.splice(this.assignmentNotes.indexOf(params.data), 1);
            // if (!this.assignmentNotes.length) {
            //     this.assignmentNotes.push(this.getEmptyNote());
            // }
            this.gridApi.setRowData(this.assignmentNotes);
            this.gridApi.setDomLayout('autoHeight');
        }
    }

    private getEmptyNote()
    {
        return {};
    }

    public onAddNote()
    {
        this.assignmentNotes.splice(
            0,
            0,
            this.getEmptyNote()
        );
        this.gridApi.setRowData(this.assignmentNotes);
    }

    public isEditable()
    {
        return true;
    }

    public isEditableNote(params)
    {
        return (!params.node.data.text || (params.node.data.text && params.node.data.text.length < 20));
    }

    tooltipFunction(data: any)
    {
        return data.note ? data.note.replaceAll('• ', '<br>• ') : '';
    }

    systemGeneratedStatusUpdate(params: any)
    {
        if (params.data) {
            if (params.data.itemNoteType === 'CONTRACT') {
                return true;
            } else {
                return false;
            }
        }
    }

    noteType = (params) => {
        if ( params.data && params.data.type  && params.data.type === 'TRANSFER_INFO' ){
            return 'TRANSFER INFO';
        }else if (params.data && params.data.type){
            return params.data.type;
        }
    };
}
