import { Injectable } from '@angular/core';
import { ConfigLoader } from '@tc-core/util/framework';
import { Observable } from 'rxjs';
import { MappedResourceTypeSearchCriteria } from '../../../models/criteria/mapped-resource-type-search-criteria';
import { ProfileTypeSearchCriteria } from '../../../models/criteria/profile-type-search-criteria';
import { ResourceTypeSearchCriteria } from '../../../models/criteria/resource-type-search-criteria';
import { DMCQueryParamsService } from '../../util/framework/dmc-query-params.service';
import { RequestService } from '../../util/framework/request.service';
import { UrlPaths } from '../url-paths';

@Injectable({
    providedIn: 'root'
})

export class ResourceTypeService {
    constructor(
        private configLoader: ConfigLoader,
        private requestService: RequestService,
        private queryParamsService: DMCQueryParamsService
    ) { }

    /*********
     Resource Types
     *********/

    public getResourceTypes(criteria: ResourceTypeSearchCriteria): Observable<any> {
        const reqPrams = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.get(
            UrlPaths.OP_DATA_URL_KEY,
            [UrlPaths.OP_DATA_VERSION, UrlPaths.RESOURCE_TYPES],
            reqPrams
        );
    }

    public getResourceCodes(): Observable<any> {
        return this.requestService.get(
            UrlPaths.OP_DATA_URL_KEY,
            [UrlPaths.OP_DATA_VERSION, UrlPaths.RESOURCE_TYPES, UrlPaths.RESOURCE_CODES]
        );
    }

    public deleteResourceType(code: string): Observable<any> {
        return this.requestService.delete(
            UrlPaths.OP_DATA_URL_KEY,
            [UrlPaths.OP_DATA_VERSION, UrlPaths.RESOURCE_TYPES, code]
        );
    }

    public saveResourceType(row: any): Observable<any> {
        return this.requestService.post(
            UrlPaths.OP_DATA_URL_KEY,
            [UrlPaths.OP_DATA_VERSION, UrlPaths.RESOURCE_TYPES],
            row
        );
    }

    public updateResourceType(code: string, row: any): Observable<any> {
        return this.requestService.put(
            UrlPaths.OP_DATA_URL_KEY,
            [UrlPaths.OP_DATA_VERSION, UrlPaths.RESOURCE_TYPES, code],
            row
        );
    }

    /*********
     Profile Types
     *********/

    public getProfileTypes(criteria: ProfileTypeSearchCriteria): Observable<any> {
        const reqPrams = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.get(
            UrlPaths.OP_DATA_URL_KEY,
            [UrlPaths.OP_DATA_VERSION, UrlPaths.PROFILE_TYPES],
            reqPrams
        );
    }

    public getProfileTypeCodes(): Observable<any> {
        return this.requestService.get(
            UrlPaths.OP_DATA_URL_KEY,
            [UrlPaths.OP_DATA_VERSION, UrlPaths.PROFILE_TYPES, UrlPaths.PROFILE_TYPE_CODES],
        );
    }

    public deleteProfileType(code: string): Observable<any> {
        return this.requestService.delete(
            UrlPaths.OP_DATA_URL_KEY,
            [UrlPaths.OP_DATA_VERSION, UrlPaths.PROFILE_TYPES, code]
        );
    }

    public saveProfileType(row: any): Observable<any> {
        return this.requestService.post(
            UrlPaths.OP_DATA_URL_KEY,
            [UrlPaths.OP_DATA_VERSION, UrlPaths.PROFILE_TYPES],
            row
        );
    }

    public updateProfileType(code: string, row: any): Observable<any> {
        return this.requestService.put(
            UrlPaths.OP_DATA_URL_KEY,
            [UrlPaths.OP_DATA_VERSION, UrlPaths.PROFILE_TYPES, code],
            row
        );
    }

    /*********
     Mapped Resource Types
     *********/

    public getMappedResourceTypes(criteria: MappedResourceTypeSearchCriteria): Observable<any> {
        const reqPrams = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.get(
            UrlPaths.OP_DATA_URL_KEY,
            [UrlPaths.OP_DATA_VERSION, UrlPaths.MAPPED_RESOURCE_TYPES],
            reqPrams
        );
    }

    public getMappedResourceTypeCodes(): Observable<any> {
        return this.requestService.get(
            UrlPaths.OP_DATA_URL_KEY,
            [UrlPaths.OP_DATA_VERSION, UrlPaths.MAPPED_RESOURCE_TYPES, UrlPaths.MAPPED_RESOURCE_TYPE_CODES]
        );
    }

    public deleteMappedResourceType(code: string): Observable<any> {
        return this.requestService.delete(
            UrlPaths.OP_DATA_URL_KEY,
            [UrlPaths.OP_DATA_VERSION, UrlPaths.MAPPED_RESOURCE_TYPES, code]
        );
    }

    public saveMappedResourceType(row: any): Observable<any> {
        return this.requestService.post(
            UrlPaths.OP_DATA_URL_KEY,
            [UrlPaths.OP_DATA_VERSION, UrlPaths.MAPPED_RESOURCE_TYPES],
            row
        );
    }
}
