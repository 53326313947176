<div class="focus-view__content-wrapper">
    <div class="container-fluid">
        <div class="assign-panel-search">
            <tc-vehicle-search-criteria
                    [searchCriteria]="vehicleSearchCriteria"
                    [searchType]="_searchType"
                    [placeHolderText]="placeHolderText">
            </tc-vehicle-search-criteria>
        </div>
    </div>

    <div class="container-fluid">
        <tc-result-header
                [transferModeList]="[]"
                [groupingOptionList]="[]"
                [heading]="heading"
                [sorters]="sorters_"
                (selectSorter)="onSort($event)">
        </tc-result-header>
    </div>

    <div class="container-fluid">
        <tc-adv-vehicle-assign-search-result
                [sortingHandler]="sorterService.method"
                [sorter]="sorterService.sorter"
                [sorters]="sorters_"
                [resultsList]="contentCardDataList"
                [selectedVehicle]="selectedSummaryCardData"
                [bookingData]="bookingData"
                [bookingDataArray]="bookingDataArray"
                [isBulkAssignment]="isBulkAssignment"
                [assignedVehicle]="selectedVehicle"
                [pager]="pager"
                [itemGroup]="itemGroup"
                (vehicleSelect)="onVehicleSelect($event)"
                (cardScroll)="onCardScroll($event)"
                (resourceCalendarClick)="onResourceCalendarClick($event)"
                (targetClick)="onTargetClick($event)"
                (eventClick)="onEventClick($event)">
        </tc-adv-vehicle-assign-search-result>
    </div>
    <tc-paginator
            *ngIf="contentCardDataList.length > defaultPageResultCount"
            [length]="contentCardDataList.length"
            [index]="pager.currPage"
            [size]="pager.itemsPerPage"
            [hasMore]="hasMore"
            (page)="onPage($event)"
            (more)="onMore()">
    </tc-paginator>
</div>

