import { Injectable, Injector } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GridColumnDefinitionProcessorService {

  constructor(
    private injector: Injector
  ) {}

  createColumnDefs(configs: any[], consumer: any): any[] {
    const colDef = [];
    for (const config of configs) {
      this.checkForHandlers(config, null, null, consumer);
      colDef.push(config);
    }
    return colDef;
  }

  checkForHandlers(obj: any, parentKey: string, parent: any, consumer: any) {
    if (obj) {
      for (const key of Object.keys(obj)) {
        if (typeof obj[key] === 'object') {
          this.checkForHandlers(obj[key], key, obj, consumer);
        } else if (key === 'handler') {
          this.addFunctionToParent(obj, obj[key], parentKey, parent, consumer);
        }
      }
    }
  }

  addFunctionToParent(obj: any, handler: string, parentKey: string, parent: any, consumer: any) {
    if (parent !== null && parentKey !== null) {
      parent[parentKey] = this.handle(handler, obj, consumer);
    }
  }

  handle(handler: string, handlerObj: any, consumer: any) {
    const split = handler.split('#');
    if (split && split.length > 1) {
      const service = split[0];
      const func = split[1];
      const handlerSer = this.injector.get(service);
      try {
        if (handlerObj['handlerParam']) {
          return handlerSer[func](handlerObj, consumer);
        } else {
          return handlerSer[func].bind(handlerSer);
        }
      } catch (e) {
        console.log(e);
        console.error(service + '.' + func + ' found error');
      }
    }
  }
}
