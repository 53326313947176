/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/forms";
import * as i2 from "ngx-material-timepicker";
import * as i3 from "../../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i4 from "@angular/material/button";
import * as i5 from "@angular/cdk/platform";
import * as i6 from "@angular/cdk/a11y";
import * as i7 from "@angular/platform-browser/animations";
import * as i8 from "../../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i9 from "@angular/material/icon";
import * as i10 from "@angular/material/tooltip";
import * as i11 from "@angular/cdk/overlay";
import * as i12 from "@angular/cdk/scrolling";
import * as i13 from "@angular/cdk/bidi";
import * as i14 from "@angular/platform-browser";
import * as i15 from "@ngx-translate/core";
import * as i16 from "../../../../../../../node_modules/ngx-material-timepicker/ngx-material-timepicker.ngfactory";
import * as i17 from "./time-editor-renderer.component";
var styles_TimeEditorRendererComponent = [];
var RenderType_TimeEditorRendererComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TimeEditorRendererComponent, data: {} });
export { RenderType_TimeEditorRendererComponent as RenderType_TimeEditorRendererComponent };
export function View_TimeEditorRendererComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "input", [["style", "width: 50px; border: none;background: transparent"]], [[8, "readOnly", 0], [8, "disabled", 0]], [[null, "keyup.enter"], [null, "focusout"], [null, "focusin"], [null, "input"], [null, "blur"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 2).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (i0.ɵnov(_v, 2).onClick($event) !== false);
        ad = (pd_2 && ad);
    } if (("keyup.enter" === en)) {
        var pd_3 = (_co.onInputChange($event) !== false);
        ad = (pd_3 && ad);
    } if (("focusout" === en)) {
        var pd_4 = (_co.onInputChange($event) !== false);
        ad = (pd_4 && ad);
    } if (("focusin" === en)) {
        var pd_5 = (_co.onFocusIn() !== false);
        ad = (pd_5 && ad);
    } return ad; }, null, null)), i0.ɵprd(5120, null, i1.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.TimepickerDirective]), i0.ɵdid(2, 671744, null, 0, i2.TimepickerDirective, [i0.ElementRef], { format: [0, "format"], min: [1, "min"], max: [2, "max"], timepicker: [3, "timepicker"], value: [4, "value"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 6, "button", [["aria-label", "more"], ["mat-button", ""], ["mat-icon-button", ""], ["style", "color:rgba(0,0,0,0.54)"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.setTime();
        var pd_0 = (i0.ɵnov(_v, 12).open() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i0.ɵdid(4, 180224, null, 0, i4.MatButton, [i0.ElementRef, i5.Platform, i6.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], { disabled: [0, "disabled"] }, null), (_l()(), i0.ɵeld(5, 16777216, null, 0, 4, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], [[null, "longpress"], [null, "keydown"], [null, "touchend"]], function (_v, en, $event) { var ad = true; if (("longpress" === en)) {
        var pd_0 = (i0.ɵnov(_v, 7).show() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i0.ɵnov(_v, 7)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (i0.ɵnov(_v, 7)._handleTouchend() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i8.View_MatIcon_0, i8.RenderType_MatIcon)), i0.ɵdid(6, 9158656, null, 0, i9.MatIcon, [i0.ElementRef, i9.MatIconRegistry, [8, null], [2, i9.MAT_ICON_LOCATION]], null, null), i0.ɵdid(7, 147456, null, 0, i10.MatTooltip, [i11.Overlay, i0.ElementRef, i12.ScrollDispatcher, i0.ViewContainerRef, i0.NgZone, i5.Platform, i6.AriaDescriber, i6.FocusMonitor, i10.MAT_TOOLTIP_SCROLL_STRATEGY, [2, i13.Directionality], [2, i10.MAT_TOOLTIP_DEFAULT_OPTIONS], [2, i14.HAMMER_LOADER]], { message: [0, "message"] }, null), i0.ɵpid(131072, i15.TranslatePipe, [i15.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵted(-1, 0, ["schedule"])), (_l()(), i0.ɵeld(10, 0, null, null, 3, "ngx-material-timepicker", [], null, [[null, "timeSet"], [null, "keydown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("keydown" === en)) {
        var pd_0 = (i0.ɵnov(_v, 12).onKeydown($event) !== false);
        ad = (pd_0 && ad);
    } if (("timeSet" === en)) {
        var pd_1 = (_co.onValueChange($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i16.View_NgxMaterialTimepickerComponent_0, i16.RenderType_NgxMaterialTimepickerComponent)), i0.ɵprd(512, null, i2.ɵa, i2.ɵa, []), i0.ɵdid(12, 245760, [["picker", 4]], 0, i2.NgxMaterialTimepickerComponent, [i2.ɵa, i2.ɵb], { format: [0, "format"] }, { timeSet: "timeSet" }), i0.ɵdid(13, 4210688, null, 0, i2.NgxMaterialTimepickerThemeDirective, [i0.ElementRef], { theme: [0, "theme"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.format; var currVal_3 = _co.minTime; var currVal_4 = _co.maxTime; var currVal_5 = i0.ɵnov(_v, 12); var currVal_6 = _co.rawValue; _ck(_v, 2, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_9 = _co.isDisabled(); _ck(_v, 4, 0, currVal_9); _ck(_v, 6, 0); var currVal_12 = i0.ɵinlineInterpolate(1, "", i0.ɵunv(_v, 7, 0, i0.ɵnov(_v, 8).transform("Select Time")), ""); _ck(_v, 7, 0, currVal_12); var currVal_13 = _co.format; _ck(_v, 12, 0, currVal_13); var currVal_14 = _co.theme; _ck(_v, 13, 0, currVal_14); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isDisabled(); var currVal_1 = i0.ɵnov(_v, 2).disabled; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_7 = (i0.ɵnov(_v, 4).disabled || null); var currVal_8 = (i0.ɵnov(_v, 4)._animationMode === "NoopAnimations"); _ck(_v, 3, 0, currVal_7, currVal_8); var currVal_10 = i0.ɵnov(_v, 6).inline; var currVal_11 = (((i0.ɵnov(_v, 6).color !== "primary") && (i0.ɵnov(_v, 6).color !== "accent")) && (i0.ɵnov(_v, 6).color !== "warn")); _ck(_v, 5, 0, currVal_10, currVal_11); }); }
export function View_TimeEditorRendererComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-time-editor-renderer", [], null, null, null, View_TimeEditorRendererComponent_0, RenderType_TimeEditorRendererComponent)), i0.ɵdid(1, 49152, null, 0, i17.TimeEditorRendererComponent, [], null, null)], null, null); }
var TimeEditorRendererComponentNgFactory = i0.ɵccf("tc-time-editor-renderer", i17.TimeEditorRendererComponent, View_TimeEditorRendererComponent_Host_0, {}, {}, []);
export { TimeEditorRendererComponentNgFactory as TimeEditorRendererComponentNgFactory };
