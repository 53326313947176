export namespace CgCalendarCssClassConstants {

  export namespace RangeState {
    export const RANGE_START_DATE = 'tc-sc-range--start-date';
    export const RANGE_END_DATE = 'tc-sc-range--end-date';
    export const RANGE_SELECTED = 'tc-sc-range--selected';
  }

  export namespace ColorId {
    export const BLUE_GREY: CgCalColor = {
      class: 'tc-sc-color--blueGrey',
      name: 'Blue Gray'
    };
    export const BLUE: CgCalColor = {
      class: 'tc-sc-color--blue',
      name: 'Blue'
    };
    export const INDIGO: CgCalColor = {
      class: 'tc-sc-color--indigo',
      name: 'Indigo'
    };
    export const PURPLE: CgCalColor = {
      class: 'tc-sc-color--purple',
      name: 'Purple'
    };
    export const YELLOW: CgCalColor = {
      class: 'tc-sc-color--yellow',
      name: 'Yellow'
    };
    export const LIME: CgCalColor = {
      class: 'tc-sc-color--lime',
      name: 'Lime'
    };
    export const LIGHT_GREEN: CgCalColor = {
      class: 'tc-sc-color--lightGreen',
      name: 'Light green'
    };
    export const TEAL: CgCalColor = {
      class: 'tc-sc-color--teal',
      name: 'Teal'
    };
    export const RED: CgCalColor = {
      class: 'tc-sc-color--red',
      name: 'Red'
    };
    export const AMBER: CgCalColor = {
      class: 'tc-sc-color--amber',
      name: 'Amber'
    };
    export const DEEP_ORANGE: CgCalColor = {
      class: 'tc-sc-color--deepOrange',
      name: 'Deep Orange'
    };
    export const BROWN: CgCalColor = {
      class: 'tc-sc-color--brown',
      name: 'Brown'
    };
  }

  export class CgCalColor {
    class: string;
    name: string;
  }
}
