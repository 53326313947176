import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ChangeDetectorService {

    private state = new BehaviorSubject(1);
    notifierState = this.state.asObservable();

    private supplierCostState = new BehaviorSubject(1);
    notifierSupplierCostState = this.supplierCostState.asObservable();

    private countryStateCityChange = new BehaviorSubject(1);
    notifierCountryStateCityChange = this.countryStateCityChange.asObservable();

    // For Accom item in booking management
    private countryCityChange = new BehaviorSubject(1);
    notifierCountryCityChange = this.countryCityChange.asObservable();

    constructor() { }

    public notifyChange() {
        this.state.next(this.state.value + 1);
    }

    public notifySupplierCostChange() {
        this.supplierCostState.next(this.supplierCostState.value + 1);
    }

    public notifyCountryStateCityChange(){
        this.countryStateCityChange.next(this.countryStateCityChange.value + 1);
    }

    public notifyCountryCityChange(){
        this.countryCityChange.next(this.countryCityChange.value + 1);
    }

}
