/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../single-year/single-year.component.ngfactory";
import * as i2 from "../single-year/single-year.component";
import * as i3 from "@angular/common";
import * as i4 from "./body-view-year.component";
var styles_BodyViewYearComponent = [];
var RenderType_BodyViewYearComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_BodyViewYearComponent, data: {} });
export { RenderType_BodyViewYearComponent as RenderType_BodyViewYearComponent };
function View_BodyViewYearComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 16777216, null, null, 1, "tc-single-year", [], [[8, "id", 0]], [[null, "selectDate"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectDate" === en)) {
        var pd_0 = (_co.onSelectDate($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_SingleYearComponent_0, i1.RenderType_SingleYearComponent)), i0.ɵdid(2, 4440064, null, 0, i2.SingleYearComponent, [i0.ViewContainerRef], { year: [0, "year"], rangeGroups: [1, "rangeGroups"], calendarRefreshEvent: [2, "calendarRefreshEvent"] }, { selectDate: "selectDate" })], function (_ck, _v) { var _co = _v.component; var currVal_1 = _v.context.$implicit; var currVal_2 = _co.rangeGroups; var currVal_3 = _co.calendarRefreshEvent; _ck(_v, 2, 0, currVal_1, currVal_2, currVal_3); }, function (_ck, _v) { var currVal_0 = i0.ɵinlineInterpolate(1, "", ("cg-cal-year-" + _v.context.$implicit), ""); _ck(_v, 1, 0, currVal_0); }); }
export function View_BodyViewYearComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_BodyViewYearComponent_1)), i0.ɵdid(1, 278528, null, 0, i3.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.yearsArray; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_BodyViewYearComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-body-view-year", [], null, null, null, View_BodyViewYearComponent_0, RenderType_BodyViewYearComponent)), i0.ɵdid(1, 114688, null, 0, i4.BodyViewYearComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BodyViewYearComponentNgFactory = i0.ɵccf("tc-body-view-year", i4.BodyViewYearComponent, View_BodyViewYearComponent_Host_0, { calendarNavData: "calendarNavData", yearsArray: "yearsArray", rangeGroups: "rangeGroups", calendarRefreshEvent: "calendarRefreshEvent" }, { selectDate: "selectDate" }, []);
export { BodyViewYearComponentNgFactory as BodyViewYearComponentNgFactory };
