import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material';
import { CurrencyFormat } from '@tc-core/model/it/codegen/ui/framework/currency-format';
import { SummaryCardData } from '@tc-core/model/it/codegen/ui/framework/summary-card-data';
import { TC } from '@tc-core/util';
import { ConfigLoader } from '@tc-core/util/framework/config-loader.service';
import { TCO } from '../../../constants';
import { ResourceAllocationEvent } from '../../../models/helper/resource-allocation-event';
import { ResourceAllocationSearchEvent } from '../../../models/helper/resource-allocation-search-event';
import { ServiceItemChanges } from '../../../models/helper/service-item-changes';
import { ResourceType } from '../../../models/reservation/assignment';
import { AssignmentStatus, StatusCodes } from '../../../models/reservation/assignment-status';
import { ServiceAssignment } from '../../../models/reservation/service-assignment';
import { ReservationStatus } from '../../../models/reservation/service-item';
import { ResourceAvailability } from '../../../models/resource/resource-availability';
import { DriverSupplierCostSummary } from '../../../models/summary/driver-supplier-cost-summary';
import { TrsSupplierCostSummary } from '../../../models/summary/supplier-cost-summary';
import { TrsSupplierAllocationSummary } from '../../../models/summary/trs-supplier-allocation-summary';
import { ChangeDetectorService } from '../../../services/util/change-detector/change-detector.service';

@Component({
    selector: 'tc-reservation-service-item',
    templateUrl: './reservation-service-item.component.html'
})
export class ReservationServiceItemComponent implements OnInit {

    EMPTY_CHAR = TC.FORMAT.EMPTY_CHAR;

    @Input() summaryContent: SummaryCardData = null;
    @Input() checkList: boolean[] = null;
    @Input() isSideIcon = false;
    @Input() changeCorner = false;
    @Input() isSavedChild: boolean;
    @Input() autoAssign: boolean;

    @Input() serviceItemChangesInSelectedGroup: Map<number, ServiceItemChanges> = new Map();
    @Input() filteredVehicleSuppliers: TrsSupplierAllocationSummary[];
    @Input() filteredDriverSuppliers: any[];
    @Input() filteredVehicles: ResourceAvailability[];
    @Input() filteredDrivers: ResourceAvailability[];

    @Output() moreClick: EventEmitter<any> = new EventEmitter<any>();
    @Output() historyClick: EventEmitter<any> = new EventEmitter<any>();
    @Output() leadPassengerClick: EventEmitter<any> = new EventEmitter<any>();
    @Output() splitClick: EventEmitter<any> = new EventEmitter<{ summary: SummaryCardData, isSaved: boolean }>();
    @Output() changeFormValidity: EventEmitter<boolean> = new EventEmitter();

    @Output() checkChanged: EventEmitter<any> = new EventEmitter();

    @Output() vehicleSupplierAssign: EventEmitter<ResourceAllocationEvent> = new EventEmitter();
    @Output() driverSupplierAssign: EventEmitter<ResourceAllocationEvent> = new EventEmitter();
    @Output() vehicleAssign: EventEmitter<ResourceAllocationEvent> = new EventEmitter();
    @Output() driverAssign: EventEmitter<ResourceAllocationEvent> = new EventEmitter();

    @Output() vehicleSupplierClear: EventEmitter<ResourceAllocationEvent> = new EventEmitter();
    @Output() driverSupplierClear: EventEmitter<ResourceAllocationEvent> = new EventEmitter();
    @Output() vehicleClear: EventEmitter<ResourceAllocationEvent> = new EventEmitter();
    @Output() driverClear: EventEmitter<ResourceAllocationEvent> = new EventEmitter();
    @Output() clearVehicleAssignment: EventEmitter<ResourceAllocationEvent> = new EventEmitter();
    @Output() clearDriverAssignment: EventEmitter<ResourceAllocationEvent> = new EventEmitter();

    @Output() vehicleSupplierTextChange: EventEmitter<ResourceAllocationSearchEvent> = new EventEmitter();
    @Output() driverSupplierTextChange: EventEmitter<ResourceAllocationSearchEvent> = new EventEmitter();
    @Output() vehicleTextChange: EventEmitter<ResourceAllocationSearchEvent> = new EventEmitter();
    @Output() vehicleJobNoChange: EventEmitter<ResourceAllocationSearchEvent> = new EventEmitter();
    @Output() driverTextChange: EventEmitter<ResourceAllocationSearchEvent> = new EventEmitter();
    @Output() driverJobNoChange: EventEmitter<ResourceAllocationSearchEvent> = new EventEmitter();

    @Output() vehicleTextFocus: EventEmitter<ResourceAllocationSearchEvent> = new EventEmitter();

    @Output() viewMoreFocus: EventEmitter<any> = new EventEmitter();

    isUpdated: boolean = false;
    isCancelled: boolean = false;

    allocationForm: FormGroup;

    selectedVehicleSupplier: TrsSupplierCostSummary;
    selectedDriverSupplier: DriverSupplierCostSummary;
    selectedVehicle: ResourceAvailability;
    selectedDriver: ResourceAvailability;
    selectedVehicleJobNo: number;
    selectedDriverJobNo: number;

    checked: boolean = false;
    serviceItemId: number;

    estimatedCost = 0;
    jobCost = 0;

    flightNumber = '';

    status: string;
    driverAssignmentStatus: string;
    vehicleAssignmentStatus: string;

    rawData: any;

    NEUTRAL_STATUS = AssignmentStatus.PENDING;
    CONFIRM_STATUS = AssignmentStatus.DMC_CONFIRM;
    SUPPLIER_CONFIRM_STATUS = AssignmentStatus.SUPPLIER_CONFIRMED;
    SUPPLIER_REJECTED_STATUS = AssignmentStatus.SUPPLIER_REJECTED;
    BOOKING_UPDATE_STATUS = AssignmentStatus.BOOKING_UPDATE;
    INACTIVE_NOTIFY_STATUS = AssignmentStatus.INACTIVE_NOTIFY;
    INACTIVE_STATUS = AssignmentStatus.INACTIVE;
    COMPLETED_STATUS = AssignmentStatus.COMPLETED;
    NO_SHOW_STATUS = AssignmentStatus.NO_SHOW;
    REQUEST_EXPIRED_STATUS = AssignmentStatus.REQUEST_EXPIRED;

    statusCodes = StatusCodes;
    defaultCost = 0.00;
    currencyFormat: CurrencyFormat;
    isFormCreated = false;

    constructor(
        private fb: FormBuilder,
        private cd: ChangeDetectorRef,
        private changeDetector: ChangeDetectorService, private configLoader: ConfigLoader
    ) {
    }

    ngOnInit() {
        this.rawData = this.summaryContent.data.value['rawData'];
        this.serviceItemId = this.summaryContent.data.value['serviceItemId'];
        this.status = this.summaryContent.data.value['status'];
        this.vehicleAssignmentStatus = this.summaryContent.data.value['vehicleAssignmentStatus'];
        this.driverAssignmentStatus = this.summaryContent.data.value['driverAssignmentStatus'];
        let trsServiceItem = this.summaryContent.data.value['rawData']['trsServiceItem'];
        this.flightNumber = trsServiceItem['arrFltNo'] ? trsServiceItem['arrFltNo'] : trsServiceItem['depFltNo'];
        this.getCost();
        this.getEstimatedCost();

        this.createForm();
        this.changeDetector.notifierState.subscribe(
            value => {
                this.updateForm();
            }
        );
        this.allocationForm.valueChanges.subscribe(
            data => {
                if (JSON.stringify(data) !== JSON.stringify({})) {
                    if (data.vehicleSupplier !== undefined && typeof (data.vehicleSupplier) !== 'object') {
                        this.filteredVehicleSuppliers = [];
                        this.vehicleSupplierTextChange.emit({
                            serviceItemId: this.serviceItemId,
                            text: data.vehicleSupplier,
                            serviceItem: this.summaryContent
                        });

                    }
                    if (data.driverSupplier !== undefined && typeof (data.driverSupplier) !== 'object') {
                        this.filteredDriverSuppliers = [];
                        this.driverSupplierTextChange.emit({
                            serviceItemId: this.serviceItemId,
                            text: data.driverSupplier,
                            serviceItem: this.summaryContent
                        });

                    }
                    if (data.vehicle !== undefined && typeof (data.vehicle) !== 'object') {
                        this.filteredVehicles = [];
                        this.vehicleTextChange.emit({
                            serviceItemId: this.serviceItemId,
                            text: data.vehicle,
                            serviceItem: this.summaryContent
                        });
                    }
                    if (data.driver !== undefined && typeof (data.driver) !== 'object') {
                        this.filteredDrivers = [];
                        this.driverTextChange.emit({
                            serviceItemId: this.serviceItemId,
                            text: data.driver,
                            serviceItem: this.summaryContent
                        });
                    }
                    if (data.vehicleJobNo !== undefined && typeof (data.vehicleJobNo) !== 'object') {
                        this.vehicleJobNoChange.emit({
                            serviceItemId: this.serviceItemId,
                            text: data.vehicleJobNo,
                            serviceItem: this.summaryContent
                        });
                    }
                    if (data.driverJobNo !== undefined && typeof (data.driverJobNo) !== 'object') {
                        this.driverJobNoChange.emit({
                            serviceItemId: this.serviceItemId,
                            text: data.driverJobNo,
                            serviceItem: this.summaryContent
                        });
                    }
                }
            }
        );

        // reservation status
        if (this.summaryContent.data.value['reservationStatus']) {
            if (this.summaryContent.data.value['reservationStatus'] === ReservationStatus.CANCELLED) {
                this.isCancelled = true;
            } else if (this.summaryContent.data.value['reservationStatus'] === ReservationStatus.UPDATED) {
                this.isUpdated = true;
            }
        }
        this.currencyFormat = this.getCurrencyFormat();

    }

    private getCurrencyFormat(): CurrencyFormat {
        let currencyFormat;
        let currencySymbolPattern = this.configLoader.configurations.get(TC.CONF.CONF_SYSTEM).defaults.default_currency_symbol_pattern;
        currencyFormat = new CurrencyFormat(TCO.AppData.DEF_CURRENCY_TYPE, currencySymbolPattern);
        return currencyFormat;
    }

    private getFilteredArray(val: string, array: any[]) {
        if (val && val.length > 0) {
            let filteredArray: any[] = [];
            array.forEach((data: any) => {
                if (data.name.length > 0 && ((data.name.toLowerCase().indexOf(val.toLowerCase()) >= 0) ||
                    (data.code.toLowerCase().indexOf(val.toLowerCase()) >= 0))) {
                    filteredArray.push(data);
                }
            });
            return filteredArray;
        } else {
            return array;
        }
    }

    private createForm() {
        this.allocationForm = this.fb.group({
            vehicleSupplier: '',
            driverSupplier: '',
            vehicle: '',
            driver: '',
            vehicleJobNo: '',
            driverJobNo: ''
        });
        this.isFormCreated = true;
    }

    updateForm() {
        this.selectedVehicleSupplier = this.serviceItemChangesInSelectedGroup.get(this.serviceItemId).vehicleSupplier;
        this.selectedDriverSupplier = this.serviceItemChangesInSelectedGroup.get(this.serviceItemId).driverSupplier;
        this.selectedVehicle = this.serviceItemChangesInSelectedGroup.get(this.serviceItemId).vehicle;
        this.selectedDriver = this.serviceItemChangesInSelectedGroup.get(this.serviceItemId).driver;
        if (this.selectedVehicle || this.selectedVehicleSupplier) {
            this.selectedVehicleJobNo = this.serviceItemChangesInSelectedGroup.get(this.serviceItemId)
                                            .serviceAssignments.get(ResourceType.vehicle) &&
            this.serviceItemChangesInSelectedGroup.get(this.serviceItemId)
                .serviceAssignments.get(ResourceType.vehicle).assignmentId > 0 ?
                this.serviceItemChangesInSelectedGroup.get(this.serviceItemId)
                    .serviceAssignments.get(ResourceType.vehicle).assignmentId :
                this.serviceItemChangesInSelectedGroup.get(this.serviceItemId)
                    .serviceAssignments.get(ResourceType.vehicle).tempAssignmentId;
        }

        if (this.selectedDriver || this.selectedDriverSupplier) {
            this.selectedDriverJobNo = this.serviceItemChangesInSelectedGroup.get(this.serviceItemId)
                                           .serviceAssignments.get(ResourceType.driver) &&
            this.serviceItemChangesInSelectedGroup.get(this.serviceItemId).serviceAssignments
                .get(ResourceType.driver).assignmentId > 0 ?
                this.serviceItemChangesInSelectedGroup.get(this.serviceItemId).serviceAssignments
                    .get(ResourceType.driver).assignmentId :
                this.serviceItemChangesInSelectedGroup.get(this.serviceItemId).serviceAssignments
                    .get(ResourceType.driver).tempAssignmentId;
        }

        this.allocationForm.patchValue({
            vehicleSupplier: this.selectedVehicleSupplier,
            driverSupplier: this.selectedDriverSupplier,
            vehicle: this.selectedVehicle,
            driver: this.selectedDriver,
            vehicleJobNo: this.selectedVehicleJobNo,
            driverJobNo: this.selectedDriverJobNo
        });
    }

    // display
    displayVehicleSupplierFn(supplier: any) {
        return supplier ? supplier.name : supplier;
    }

    displayDriverSupplierFn(supplier: any) {
        return supplier ? supplier.name : supplier;
    }

    displayVehicleFn(resource: any) {
        return resource ? resource.resourceName : resource;
    }

    displayDriverFn(driver: any) {
        return driver ? driver.resourceName : driver;
    }

    // select
    public onSelectVehicleSupplier(supplier: TrsSupplierCostSummary) {
        this.selectedVehicleSupplier = supplier;
        this.vehicleSupplierAssign.emit(new ResourceAllocationEvent(
            this.serviceItemId,
            this.selectedVehicleSupplier,
            this.selectedVehicle,
            this.selectedDriverSupplier,
            this.selectedDriver
        ));
        this.isSavedChild = false;
        this.getEstimatedCost();
        this.getCost();
    }

    public onSelectDriverSupplier(supplier: any) {
        this.selectedDriverSupplier = supplier;
        this.driverSupplierAssign.emit(new ResourceAllocationEvent(
            this.serviceItemId,
            this.selectedVehicleSupplier,
            this.selectedVehicle,
            this.selectedDriverSupplier,
            this.selectedDriver
        ));
        this.isSavedChild = false;
        this.getEstimatedCost();
        this.getCost();
    }

    public onSelectVehicle(resource: ResourceAvailability) {
        this.selectedVehicle = resource;
        this.vehicleAssign.emit(new ResourceAllocationEvent(
            this.serviceItemId,
            this.selectedVehicleSupplier,
            this.selectedVehicle,
            this.selectedDriverSupplier,
            this.selectedDriver
        ));
        this.isSavedChild = false;
        this.getEstimatedCost();
        this.getCost();
    }

    public onSelectDriver(driver: ResourceAvailability) {
        this.selectedDriver = driver;
        this.driverAssign.emit(new ResourceAllocationEvent(
            this.serviceItemId,
            this.selectedVehicleSupplier,
            this.selectedVehicle,
            this.selectedDriverSupplier,
            this.selectedDriver
        ));
        this.isSavedChild = false;
        this.getEstimatedCost();
        this.getCost();
    }

    // clear
    public clearVehicleSupplier() {
        if (this.selectedVehicleSupplier != null) {
            this.isSavedChild = false;
        }
        this.allocationForm.get('vehicleSupplier').setValue('');
        this.selectedVehicleSupplier = null;
        this.vehicleSupplierClear.emit(new ResourceAllocationEvent(
            this.serviceItemId,
            this.selectedVehicleSupplier,
            this.selectedVehicle,
            this.selectedDriverSupplier,
            this.selectedDriver
        ));
        this.getEstimatedCost();
        this.getCost();

    }

    public clearDriverSupplier() {
        if (this.selectedDriverSupplier != null) {
            this.isSavedChild = false;
        }
        this.allocationForm.get('driverSupplier').setValue('');
        this.selectedDriverSupplier = null;
        this.driverSupplierClear.emit(new ResourceAllocationEvent(
            this.serviceItemId,
            this.selectedVehicleSupplier,
            this.selectedVehicle,
            this.selectedDriverSupplier,
            this.selectedDriver
        ));
        this.getEstimatedCost();
        this.getCost();

    }

    public clearVehicle() {
        if (this.selectedVehicle != null) {
            console.log(this.selectedVehicle);
            this.isSavedChild = false;
        }
        this.allocationForm.get('vehicle').setValue('');
        this.selectedVehicle = null;
        this.vehicleClear.emit(new ResourceAllocationEvent(
            this.serviceItemId,
            this.selectedVehicleSupplier,
            this.selectedVehicle,
            this.selectedDriverSupplier,
            this.selectedDriver
        ));
    }

    public clearDriver() {
        if (this.selectedDriver != null) {
            this.isSavedChild = false;
        }
        this.allocationForm.get('driver').setValue('');
        this.selectedDriver = null;
        this.driverClear.emit(new ResourceAllocationEvent(
            this.serviceItemId,
            this.selectedVehicleSupplier,
            this.selectedVehicle,
            this.selectedDriverSupplier,
            this.selectedDriver
        ));
        this.getEstimatedCost();
        this.getCost();
    }

    onClearVehicleAssignment() {
        this.selectedVehicle = null;
        this.selectedVehicleSupplier = null;
        this.selectedVehicleJobNo = null;
        this.allocationForm.get('vehicleSupplier').setValue('');
        this.allocationForm.get('vehicle').setValue('');
        this.allocationForm.get('vehicleJobNo').setValue('');
        this.clearVehicleAssignment.emit(new ResourceAllocationEvent(
            this.serviceItemId,
            this.selectedVehicleSupplier,
            this.selectedVehicle,
            this.selectedDriverSupplier,
            this.selectedDriver
        ));
    }

    onClearDriverAssignment() {
        this.selectedDriver = null;
        this.selectedDriverSupplier = null;
        this.selectedDriverJobNo = null;
        this.allocationForm.get('driverSupplier').setValue('');
        this.allocationForm.get('driver').setValue('');
        this.allocationForm.get('driverJobNo').setValue('');
        this.clearDriverAssignment.emit(new ResourceAllocationEvent(
            this.serviceItemId,
            this.selectedVehicleSupplier,
            this.selectedVehicle,
            this.selectedDriverSupplier,
            this.selectedDriver
        ));
    }

    // focus
    public onVehicleSupplierFocus($event: FocusEvent) {
        this.filteredVehicleSuppliers = [];
        this.vehicleSupplierTextChange.emit({
            serviceItemId: this.serviceItemId,
            text: this.allocationForm.get('vehicleSupplier').value,
            serviceItem: this.summaryContent
        });
    }

    public onDriverSupplierFocus($event: FocusEvent) {
        this.filteredDriverSuppliers = [];
        this.driverSupplierTextChange.emit({
            serviceItemId: this.serviceItemId,
            text: this.allocationForm.get('driverSupplier').value,
            serviceItem: this.summaryContent
        });
    }

    public onVehicleFocus($event: FocusEvent) {
        this.filteredVehicles = [];
        this.vehicleTextChange.emit({
            serviceItemId: this.serviceItemId,
            text: this.allocationForm.get('vehicle').value,
            serviceItem: this.summaryContent
        });
    }

    onVehicleJobNoFocus($event) {
        this.vehicleJobNoChange.emit({
            serviceItemId: this.serviceItemId,
            text: this.allocationForm.get('vehicleJobNo').value,
            serviceItem: this.summaryContent
        });
    }

    public onDriverFocus($event: FocusEvent) {
        this.filteredDrivers = [];
        this.driverTextChange.emit({
            serviceItemId: this.serviceItemId,
            text: this.allocationForm.get('driver').value,
            serviceItem: this.summaryContent
        });
    }

    onDriverJobNoFocus($event) {
        this.driverJobNoChange.emit({
            serviceItemId: this.serviceItemId,
            text: this.allocationForm.get('driverJobNo').value,
            serviceItem: this.summaryContent
        });
    }

    public getEstimatedCost() {
        this.estimatedCost = 0;
        if (this.serviceItemChangesInSelectedGroup.get(this.serviceItemId).serviceAssignments) {

            if (this.serviceItemChangesInSelectedGroup.get(this.serviceItemId).serviceAssignments
                    .get(ResourceType.vehicle)) {
                this.estimatedCost = this.serviceItemChangesInSelectedGroup.get(this.serviceItemId)
                                         .serviceAssignments.get(ResourceType.vehicle).estimatedCost;
            }
            if (this.serviceItemChangesInSelectedGroup.get(this.serviceItemId).serviceAssignments
                    .get(ResourceType.driver)) {
                this.estimatedCost += this.serviceItemChangesInSelectedGroup.get(this.serviceItemId)
                                          .serviceAssignments.get(ResourceType.driver).estimatedCost;
            }
        }
        return this.estimatedCost ? this.estimatedCost.toFixed(2) : this.defaultCost.toFixed(2);
        // if(this.isFormCreated){ this.cd.detectChanges();}

    }

    public getCost() {
        this.jobCost = 0;
        if (this.serviceItemChangesInSelectedGroup.get(this.serviceItemId).serviceAssignments) {

            let driverCost = this.serviceItemChangesInSelectedGroup.get(this.serviceItemId).driverCost ?
                this.serviceItemChangesInSelectedGroup.get(this.serviceItemId).driverCost :
                0;
            let vehicleCost = this.serviceItemChangesInSelectedGroup.get(this.serviceItemId).vehicleCost ?
                this.serviceItemChangesInSelectedGroup.get(this.serviceItemId).vehicleCost :
                0;
            this.jobCost = driverCost + vehicleCost;

            // if (this.serviceItemChangesInSelectedGroup.get(this.serviceItemId).serviceAssignments
            //         .get(ResourceType.vehicle)) {
            //     this.jobCost = this.serviceItemChangesInSelectedGroup.get(this.serviceItemId).serviceAssignments
            //                        .get(ResourceType.vehicle).jobCost;
            // }
            // if (this.serviceItemChangesInSelectedGroup.get(this.serviceItemId).serviceAssignments
            //         .get(ResourceType.driver)) {
            //     this.jobCost += this.serviceItemChangesInSelectedGroup.get(this.serviceItemId).serviceAssignments
            //                         .get(ResourceType.driver).jobCost;
            // }
        }
        return this.jobCost ? this.jobCost.toFixed(2) : this.defaultCost.toFixed(2);
        // if(this.isFormCreated){ this.cd.detectChanges();}
    }

    public onChangeCheckBox($event: MatCheckboxChange) {
        this.checkChanged.emit($event.checked);
    }

    onMoreClick(event) {
        this.moreClick.emit(event);
    }

    onLeadPassengerSelect($event) {
        this.leadPassengerClick.emit(event);
    }

    onSplitSelect($event) {
        this.splitClick.emit({summary: this.summaryContent, isSaved: this.isSavedChild});
    }

    public isDisableVehicleSupplierClear() {
        let disable = false;
        if (this.serviceItemChangesInSelectedGroup.get(this.serviceItemId)) {
            if (this.serviceItemChangesInSelectedGroup.get(this.serviceItemId).serviceAssignments &&
                (this.serviceItemChangesInSelectedGroup.get(this.serviceItemId).serviceAssignments
                     .get(ResourceType.vehicle))) {
                disable = true;
            }
        }
        return disable;
    }

    public isDisableDriverSupplierClear() {
        let disable = false;
        if (this.serviceItemChangesInSelectedGroup.get(this.serviceItemId)) {
            if (this.serviceItemChangesInSelectedGroup.get(this.serviceItemId).serviceAssignments &&
                (this.serviceItemChangesInSelectedGroup.get(this.serviceItemId).serviceAssignments
                     .get(ResourceType.driver))) {
                disable = true;
            }
        }
        return disable;
    }

    public isRestrictedEdit(): boolean {
        return this.isRestricted(this.serviceItemChangesInSelectedGroup.get(this.serviceItemId));
    }

    public isRestrictedEditFields(event: any): boolean {
        let restrict = false;
        let value = this.serviceItemChangesInSelectedGroup.get(this.serviceItemId);
        if (value) {
            restrict = this.isCancelled || this.isUpdated;
            if (!restrict) {
                if ((this.vehicleAssignmentStatus === '' && this.driverAssignmentStatus === '')
                    || (this.vehicleAssignmentStatus === null && this.driverAssignmentStatus === null)
                    || (this.vehicleAssignmentStatus === AssignmentStatus.PENDING && this.driverAssignmentStatus === AssignmentStatus.PENDING)
                    || (this.vehicleAssignmentStatus === AssignmentStatus.SUPPLIER_REJECTED && this.driverAssignmentStatus === AssignmentStatus.SUPPLIER_REJECTED)
                    || (this.vehicleAssignmentStatus === AssignmentStatus.BOOKING_UPDATE && this.driverAssignmentStatus === AssignmentStatus.BOOKING_UPDATE)
                    || (this.vehicleAssignmentStatus === AssignmentStatus.SUPPLIER_CONFIRMED && this.driverAssignmentStatus === AssignmentStatus.SUPPLIER_CONFIRMED)
                    || (this.vehicleAssignmentStatus === AssignmentStatus.DMC_CONFIRM && this.driverAssignmentStatus === AssignmentStatus.DMC_CONFIRM)
                ) {
                    restrict = false;
                } else {
                    restrict = true;
                }
            }
        }
        return restrict;
    }

    public isRestricted(value: ServiceItemChanges) {
        let restrict = false;
        if (value) {
            restrict = this.isCancelled || this.isUpdated;
            if (!restrict) {
                let serviceAssignmentList: ServiceAssignment[] = [];
                this.serviceItemChangesInSelectedGroup.get(value.serviceItemId).serviceAssignments
                    .forEach(value => serviceAssignmentList.push(value));
                if (value.serviceAssignments) {
                    for (const data of serviceAssignmentList) {
                        if (data && (data.assignStatus !== AssignmentStatus.PENDING && data.assignStatus !==
                            AssignmentStatus.SUPPLIER_REJECTED && data.assignStatus !==
                            AssignmentStatus.BOOKING_UPDATE && data.assignStatus !==
                            AssignmentStatus.REQUEST_EXPIRED)) {
                            restrict = true;
                            break;
                        }
                    }
                }
            }
        }
        return restrict;
    }

    onViewMoreClick(serviceItem: any) {
        this.viewMoreFocus.emit(serviceItem.data.value);
    }

    public isDisabledCheckbox(): boolean {
        let disable = false;
        if (this.vehicleAssignmentStatus === AssignmentStatus.STARTED
            || this.driverAssignmentStatus === AssignmentStatus.STARTED
            || this.vehicleAssignmentStatus === AssignmentStatus.COMPLETED
            || this.driverAssignmentStatus === AssignmentStatus.COMPLETED
            || this.vehicleAssignmentStatus === AssignmentStatus.NO_SHOW
            || this.driverAssignmentStatus === AssignmentStatus.NO_SHOW
        ) {
            disable = true;
        }
        if (this.isCancelled || this.isUpdated) {
            disable = true;
        }
        return disable;
    }

    public onViewHistoryClick(summaryContent: SummaryCardData) {
        this.historyClick.emit(summaryContent);
    }

    public historyAvailable(summaryContent: SummaryCardData) {
        return this.rawData && this.rawData.serviceItemHistories && this.rawData.serviceItemHistories.length > 0;
    }
}

export function StringValidator(control: AbstractControl) {
    if (control.value !== '' && (!control.value.name || (control.value.name === 'NOT SPECIFIED'))) {
        return {invalidString: true};
    } else {
        return null;
    }
}
