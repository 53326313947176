import { Component, Input, OnInit } from '@angular/core';
import { ConfigLoader } from '@tc-core/util/framework';
import { ServiceAssignment } from '../../models/reservation/service-assignment';
import { DataKey, DataStoreService } from '../../services/util/framework/data-store.service';
import {TranslateService} from '@ngx-translate/core';
import {ICellRendererParams} from 'ag-grid-community';

@Component({
    selector: 'tc-supplier-price-breakdown',
    templateUrl: './supplier-price-breakdown.component.html'
})
export class SupplierPriceBreakdownComponent implements OnInit {
    @Input() serviceAssignment: ServiceAssignment;
    columnDefs = [
        {
            headerName: 'Type',
            field: 'type',
            sortable: false,
            editable: false
        },
        {
            headerName: 'Cost',
            field: 'cost',
            sortable: true,
            editable: false
        }
    ];
    public rawData: any = [];
    gridApi;

    constructor(
        private dataStore: DataStoreService,
        private translateService: TranslateService
    ) {
        this.translateService.onLangChange.subscribe(() => {
            this.gridApi.refreshHeader();
        });
    }

    defaultColDef = {
        resizable: true,
        headerValueGetter: (parameters: ICellRendererParams): string => {
            const headerIdentifier = parameters.colDef.headerName;
            if (headerIdentifier) {
                return this.translateService.instant(headerIdentifier);
            }
            return '';
        }
    };

    public ngOnInit(): void {
        const inrFormat = new Intl.NumberFormat('en-Us', {
            style: 'currency',
            currency: this.serviceAssignment.currency ? this.serviceAssignment.currency : 'AED',
            minimumFractionDigits: 2
        });

        this.rawData.push(...[
                {
                    type: 'Per Unit',
                    cost: inrFormat.format(this.serviceAssignment.unitPrice)
                },
                {
                    type: 'Per Adult',
                    cost: inrFormat.format(this.serviceAssignment.adultPrice)
                },
                {
                    type: 'Per Teen',
                    cost: inrFormat.format(this.serviceAssignment.teenPrice)
                },
                {
                    type: 'Per Child',
                    cost: inrFormat.format(this.serviceAssignment.childPrice)
                },
                {
                    type: 'Per Infant',
                    cost: inrFormat.format(this.serviceAssignment.infantPrice)
                }
            ]
        );
    }

    public onClose($event: MouseEvent) {
        this.dataStore.set(DataKey.popupClose, true);
    }

    public onGridReady(event: any) {
        this.gridApi = event.api;
        this.gridApi.sizeColumnsToFit();
    }
}
