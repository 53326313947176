<div class="">

    <div class="price-break-down__detail-view">
        <div class="split-passengers-results__container">

            <!-- header -->
            <div class="container split-passengers-results__header">
                <div class="row item-header__content">
                    <ng-container>
                        <div class="col-xs-6 col-md-5">
                            <span>{{"Passenger Name" |translate}}</span>
                        </div>
                    </ng-container>
                    <ng-container>
                        <div class="col-xs-6 col-md-3">
                            <span>{{"Type" |translate}}</span>
                        </div>
                    </ng-container>
                    <ng-container>
                        <div class="col-xs-6 col-md-4">
                            <span>{{"Need Assistance" |translate}}</span>
                        </div>
                    </ng-container>
                </div>
            </div>
            <div class="container split-passengers-results__body">
                <mat-radio-group aria-label="Select a Passenger" [(ngModel)]="selectedPassenger">
                    <div class="row tc-item--center split-passengers-results__item" *ngFor="let item of passengers">
                        <div class="col-xs-6 col-md-5">
                            <mat-radio-button [value]="item" [disabled]="item.type!=='A'">
                                {{item.firstName + ' ' + item.lastName}}
                            </mat-radio-button>
                        </div>
                        <div class="col-xs-6 col-md-3">{{getPassengerType(item.type)}}</div>
                        <div class="col-xs-6 col-md-4">
                            <i *ngIf="item.needAssistance" class="material-icons">accessibility_new</i>
                        </div>
                    </div>
                </mat-radio-group>
            </div>
        </div>
    </div>
</div>

<div class="tc-modal-footer">
    <button class="button-right tc-button-secondary mat-button" (click)="onClose($event)">
        Close
    </button>
    <button
            class="button-right tc-button-primary mat-button" (click)="onSave($event)"
            [disabled]="!isCompleted || !(selectedPassenger)">
        Save
    </button>
</div>
