import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppHeaderComponent } from './app-header.component';
import { UserServiceHandlerService } from '@tc-core/service/service-handlers/user-service-handler.service';
import { TranslateModule } from '@ngx-translate/core';
import { HeaderModule } from '../header/header.module';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule.forChild(),
        HeaderModule
    ],
    declarations: [AppHeaderComponent],
    exports: [AppHeaderComponent],
    providers: [UserServiceHandlerService]
})
export class AppHeaderModule {}
