<div class="resource-assignment-view">
    <mat-tab-group [selectedIndex]="selectedIndex">
        <mat-tab label="Supplier">
            <tc-supplier-allocation-panel
                    [contentCardDataList]="contentCardDataListSuppliers"
                    [bookingDataList]="itemList"
                    [paxCount]="paxCount"
                    [heading]="supplierPanelHeading"
                    [isPrimary]="isPrimary"
                    [isSecondary]="isSecondary"
                    [startTime]="startTime"
                    [endTime]="endTime"
                    [bookingIdList]="bookingIdList"
                    [itemList]="itemList"
                    [supplierSearchCriteria]="supplierSearchCriteria"
                    (supplierSelect)="onSupplierSelect($event)">
            </tc-supplier-allocation-panel>
        </mat-tab>
        <mat-tab label="Resource" *ngIf="resourceAllocationEnable()">
            <tc-resource-allocation-panel
                    [contentCardDataList]="contentCardDataListResources"
                    [itemList]="itemList"
                    [heading]="resourcePanelHeading"
                    [paxCount]="paxCount"
                    [isPrimary]="isPrimary"
                    [isSecondary]="isSecondary"
                    [resourceSearchCriteria]="resourceSearchCriteria"
                    [startTime]="startTime"
                    [endTime]="endTime"
                    [bookingIdList]="bookingIdList"
                    (resourceSelect)="onResourceSelect($event)"
                    (resourceCalendarClick)="onResourceCalendarClick($event)"
                    (targetClick)="onTargetClick($event)"
                    (eventClick)="onEventClick($event)"
                    (onTimeRangeChangeClick)="onTimeRangeChangeClick($event)">
            </tc-resource-allocation-panel>
        </mat-tab>
    </mat-tab-group>
</div>
