import * as tslib_1 from "tslib";
import { ResourceCost } from './resource-cost';
import { ResourceCostSetup } from './resource-cost-setup';
var RoomResourceCost = /** @class */ (function (_super) {
    tslib_1.__extends(RoomResourceCost, _super);
    function RoomResourceCost() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return RoomResourceCost;
}(ResourceCost));
export { RoomResourceCost };
var RoomResourceCostSetup = /** @class */ (function (_super) {
    tslib_1.__extends(RoomResourceCostSetup, _super);
    function RoomResourceCostSetup() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return RoomResourceCostSetup;
}(ResourceCostSetup));
export { RoomResourceCostSetup };
