import * as tslib_1 from "tslib";
import { DataKey, DataStoreService } from '../util/framework/data-store.service';
import { KeyControlAndAbstractSearchCriteria } from '../../models/criteria/key-control-and-abstract-search-criteria';
import { DataHandlerService } from '../backend-consumers/master-data-handler-service/data-handler.service';
import { BrandSearchCriteria } from '../../models/criteria/brand-search-criteria';
import { BrandSetupService } from '../backend-consumers/setups/brand-setup-service';
import { DistributionChannelSearchCriteria } from '../../models/criteria/distribution-channel-search-criteria';
import { DistributionChannelSetupService } from '../backend-consumers/setups/distribution-channel-setup-service';
import { TCO } from '../../constants';
import { ConfigLoader } from '@tc-core/util/framework';
import * as i0 from "@angular/core";
import * as i1 from "../util/framework/data-store.service";
import * as i2 from "../backend-consumers/master-data-handler-service/data-handler.service";
import * as i3 from "../backend-consumers/setups/brand-setup-service";
import * as i4 from "../backend-consumers/setups/distribution-channel-setup-service";
import * as i5 from "@tc-core/util/framework/config-loader.service";
var UserManagementService = /** @class */ (function () {
    function UserManagementService(dataStore, dataHandlerService, dataStoreService, brandSetupService, distributionChannelSetupService, configLoader) {
        var _this = this;
        this.dataStore = dataStore;
        this.dataHandlerService = dataHandlerService;
        this.dataStoreService = dataStoreService;
        this.brandSetupService = brandSetupService;
        this.distributionChannelSetupService = distributionChannelSetupService;
        this.configLoader = configLoader;
        this.ANY = 'ANY';
        this.userProfile = this.dataStore.get(DataKey.userProfile).getValue();
        this.anyOption = { code: 'ANY', name: 'Any' };
        this.allItems = 1000000;
        /**
         * This method is used to update the column editable privileges according to the logged user key controls.
         *
         * @param defaultColDef default column definitions
         */
        this.updateColDefAccordingToLoggedUserKC = function (defaultColDef) {
            var e_1, _a;
            _this.userProfile = _this.dataStore.get(DataKey.userProfile).getValue();
            try {
                for (var defaultColDef_1 = tslib_1.__values(defaultColDef), defaultColDef_1_1 = defaultColDef_1.next(); !defaultColDef_1_1.done; defaultColDef_1_1 = defaultColDef_1.next()) {
                    var column = defaultColDef_1_1.value;
                    switch (column.field) {
                        case 'kcCompany':
                            if (_this.userProfile.kcCompany != null) {
                                column = column.editable = false;
                            }
                            break;
                        case 'kcDivision':
                            if (_this.userProfile.kcDivision != null) {
                                column = column.editable = false;
                            }
                            break;
                        case 'kcBrand':
                            if (_this.userProfile.kcBrand != null) {
                                column = column.editable = false;
                            }
                            break;
                        case 'kcDistributionChannel':
                            if (_this.userProfile.kcDistributionChannel != null) {
                                column = column.editable = false;
                            }
                            break;
                    }
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (defaultColDef_1_1 && !defaultColDef_1_1.done && (_a = defaultColDef_1.return)) _a.call(defaultColDef_1);
                }
                finally { if (e_1) throw e_1.error; }
            }
            return defaultColDef;
        };
        /**
         * This method is used to update the given search criteria chip configs according to the logged user key controls.
         *
         * @param chipsConfig The chips configs related to the search chips
         */
        this.updateSearchChipsAccordingToUserKC = function (chipsConfig) {
            var e_2, _a;
            _this.userProfile = _this.dataStore.get(DataKey.userProfile).getValue();
            if (_this.notAvailableFor('FC') && chipsConfig && chipsConfig.chips) {
                try {
                    for (var _b = tslib_1.__values(chipsConfig.chips), _c = _b.next(); !_c.done; _c = _b.next()) {
                        var chip = _c.value;
                        switch (chip.criteriaId) {
                            case 'KC_COMPANY':
                                _this.chipOptionParamsUpdate(chip, true, true, true, false, null, null);
                                break;
                            case 'KC_DIVISION':
                                _this.chipOptionParamsUpdate(chip, true, true, true, false, null, null);
                                break;
                            case 'KC_BRAND':
                                _this.chipOptionParamsUpdate(chip, true, true, true, false, null, null);
                                if (_this.userProfile.kcBrand != null) {
                                }
                                break;
                            case 'KC_DIST_CHANNEL':
                                _this.chipOptionParamsUpdate(chip, true, true, true, false, null, null);
                                if (_this.userProfile.kcDistributionChannel != null) {
                                }
                                break;
                        }
                    }
                }
                catch (e_2_1) { e_2 = { error: e_2_1 }; }
                finally {
                    try {
                        if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                    }
                    finally { if (e_2) throw e_2.error; }
                }
            }
            return chipsConfig;
        };
        this.chipOptionParamsUpdate = function (chip, isMandatory, isMandatoryDisplay, isInitialPresent, disabled, options, data) {
            if (chip && chip.optionParams) {
                chip.optionParams.isMandatory = isMandatory;
                chip.optionParams.isMandatoryDisplay = isMandatoryDisplay;
                chip.optionParams.isInitialPresent = isInitialPresent;
                chip.optionParams.disabled = disabled;
                if (options) {
                    chip.optionParams.options = options;
                }
                if (data) {
                    chip.optionParams.data = data;
                }
            }
        };
        /**
         * The updateSearchCriteriaWithUserKC method will update search criteria with logged in user key control values.
         *
         * @param criteria The search criteria
         */
        this.updateSearchCriteriaWithUserKC = function (criteria) {
            _this.userProfile = _this.dataStore.get(DataKey.userProfile).getValue();
            if (_this.notAvailableFor('FC')) {
                if (_this.userProfile.kcCompany) {
                    criteria.kcCompany = _this.userProfile.kcCompany;
                }
                else if (!criteria.kcCompany) {
                    criteria.kcCompany = _this.ANY;
                }
                if (_this.userProfile.kcDivision) {
                    criteria.kcDivision = _this.userProfile.kcDivision;
                }
                else if (!criteria.kcDivision) {
                    criteria.kcDivision = _this.ANY;
                }
                if (_this.userProfile.kcBrand) {
                    criteria.kcBrand = _this.userProfile.kcBrand;
                }
                else if (!criteria.kcBrand) {
                    criteria.kcBrand = _this.ANY;
                }
                if (_this.userProfile.kcDistributionChannel) {
                    criteria.kcDistributionChannel = _this.userProfile.kcDistributionChannel;
                }
                else if (!criteria.kcDistributionChannel) {
                    criteria.kcDistributionChannel = _this.ANY;
                }
            }
            return criteria;
        };
        /**
         * updateAllocationSearchChipsWithKC used for the update search chips in allocation panels
         *
         * @param chipsConfig the chipsConfig
         * @param bookingItemCompany the code of company in the booking item.
         * @param bookingItemDivision the code of division in the booking item.
         * @param bookingItemBrand the code of brand in the booking item.
         * @param bookingItemDistributionChannel the code of distribution channel in the booking item.
         */
        this.updateAllocationSearchChipsWithKC = function (chipsConfig, bookingItemCompany, bookingItemDivision, bookingItemBrand, bookingItemDistributionChannel) {
            var e_3, _a;
            var company = [];
            var brand = [];
            var division = [];
            var disChannel = [];
            var COMMON_DATA;
            _this.userProfile = _this.dataStore.get(DataKey.userProfile).getValue();
            company = _this.setOptions(_this.userProfile.kcCompany, company, DataKey.kcCompanies, bookingItemCompany);
            division = _this.setOptions(_this.userProfile.kcDivision, division, DataKey.kcDivisions, bookingItemDivision);
            brand = _this.setOptions(_this.userProfile.kcBrand, brand, DataKey.kcBrandsList, bookingItemBrand);
            disChannel = _this.setOptions(_this.userProfile.kcDistributionChannel, disChannel, DataKey.kcDistributionChannelList, bookingItemDistributionChannel);
            if (chipsConfig && chipsConfig.chips) {
                try {
                    for (var _b = tslib_1.__values(chipsConfig.chips), _c = _b.next(); !_c.done; _c = _b.next()) {
                        var chip = _c.value;
                        switch (chip.criteriaId) {
                            case 'KC_COMPANY':
                                COMMON_DATA = _this.updateCommonDataWithKC(bookingItemCompany);
                                _this.chipOptionParamsUpdate(chip, true, true, true, false, _this.addDataToOptions(company, COMMON_DATA), company);
                                break;
                            case 'KC_DIVISION':
                                COMMON_DATA = _this.updateCommonDataWithKC(bookingItemDivision);
                                _this.chipOptionParamsUpdate(chip, true, true, true, false, _this.addDataToOptions(division, COMMON_DATA), division);
                                break;
                            case 'KC_BRAND':
                                COMMON_DATA = _this.updateCommonDataWithKC(bookingItemBrand);
                                _this.chipOptionParamsUpdate(chip, true, true, true, false, _this.addDataToOptions(brand, COMMON_DATA), brand);
                                break;
                            case 'KC_DIST_CHANNEL':
                                COMMON_DATA = _this.updateCommonDataWithKC(bookingItemDistributionChannel);
                                _this.chipOptionParamsUpdate(chip, true, true, true, false, _this.addDataToOptions(disChannel, COMMON_DATA), disChannel);
                                break;
                        }
                    }
                }
                catch (e_3_1) { e_3 = { error: e_3_1 }; }
                finally {
                    try {
                        if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                    }
                    finally { if (e_3) throw e_3.error; }
                }
            }
            return chipsConfig;
        };
    }
    /**
     * @description In order to configure front-end user interfaces, panels, and certain specialized functions linked to the particular
     * client needs, the configCompany value is updated using the loadConfiguredCompany method.
     * <br>
     * configCompany: the company code/name value configured in config project related to the logged in user
     * <br>
     * configCompany values are located on TCO-Config > config > DMC(Or Relevant folder) > common > system.config.json
     */
    UserManagementService.prototype.loadConfiguredCompany = function () {
        this.configCompany = this.configLoader.configurations.get(TCO.CONF.CONF_SYSTEM).configCompany;
    };
    /**
     * @description notAvailableFor method is used to check the availability of the privileges with the given companies list
     * (input as a comma separated string).
     * @param companies The companies list which you need to restrict. Input as comma separated string. (Ex: "DMC,FC,DS")
     * @return boolean value (true/false) will return according to the logged in user configs
     */
    UserManagementService.prototype.notAvailableFor = function (companies) {
        var e_4, _a;
        var isAvailable = true;
        var companyList = companies.replace(/\s/g, '').split(',');
        if (companyList.length > 0) {
            try {
                for (var companyList_1 = tslib_1.__values(companyList), companyList_1_1 = companyList_1.next(); !companyList_1_1.done; companyList_1_1 = companyList_1.next()) {
                    var comp = companyList_1_1.value;
                    if (comp === this.configCompany) {
                        isAvailable = false;
                        break;
                    }
                }
            }
            catch (e_4_1) { e_4 = { error: e_4_1 }; }
            finally {
                try {
                    if (companyList_1_1 && !companyList_1_1.done && (_a = companyList_1.return)) _a.call(companyList_1);
                }
                finally { if (e_4) throw e_4.error; }
            }
        }
        return isAvailable;
    };
    /**
     * @description onlyAvailableFor method is used to check the availability of the privileges with the given companies list
     * (input as a comma separated string).
     * @param companies The companies list which you need to given permissions. Input as comma separated string. (Ex: "DMC,FC,DS")
     * @return boolean value (true/false) will return according to the logged in user configs
     */
    UserManagementService.prototype.onlyAvailableFor = function (companies) {
        var e_5, _a;
        var isAvailable = false;
        var companyList = companies.replace(/\s/g, '').split(',');
        if (companyList.length > 0) {
            try {
                for (var companyList_2 = tslib_1.__values(companyList), companyList_2_1 = companyList_2.next(); !companyList_2_1.done; companyList_2_1 = companyList_2.next()) {
                    var comp = companyList_2_1.value;
                    if (comp === this.configCompany) {
                        isAvailable = true;
                        break;
                    }
                }
            }
            catch (e_5_1) { e_5 = { error: e_5_1 }; }
            finally {
                try {
                    if (companyList_2_1 && !companyList_2_1.done && (_a = companyList_2.return)) _a.call(companyList_2);
                }
                finally { if (e_5) throw e_5.error; }
            }
        }
        return isAvailable;
    };
    UserManagementService.prototype.chipKCDataParamsUpdate = function (chip, data) {
        if (chip && chip.optionParams) {
            chip.optionParams.data = data;
        }
        return chip;
    };
    /**
     * updateCommonDataWithKC method is used to update (by concatenating) common data code with booking item kc value
     *
     * @param code the code of relevant key control.
     * @return Common data array that including the concatenated key control code.
     */
    UserManagementService.prototype.updateCommonDataWithKC = function (code) {
        var value = 'NOT_SPECIFY_' + (code ? code : '');
        return { name: 'Not Specified', code: value };
    };
    /**
     * This method used for adding new data to existing options list.
     *
     * @param options  the list of existing options
     * @param data  the new data that need to add to the options list
     * @return optionsTemp This method will return a list of options.
     */
    UserManagementService.prototype.addDataToOptions = function (options, data) {
        var optionsTemp;
        optionsTemp = options.filter(function (item, index, self) { return self.indexOf(item) === index; });
        optionsTemp.push(data);
        return optionsTemp;
    };
    /**
     * This method is used to filter options by given code
     *
     * @param userProfileKCKey the Data key which is stored the user profile data.
     * @param options  the expected options list for the relevant dropdown.
     * @param optionsDataKey  the Data key which is stored the relevant key controls data related to the logged in user.
     * @param optionsFilterKey  the key that the data need to filter
     * @return options list will return by this method
     */
    UserManagementService.prototype.setOptions = function (userProfileKCKey, options, optionsDataKey, optionsFilterKey) {
        if (userProfileKCKey) {
            options = this.dataStore.get(optionsDataKey).getValue();
        }
        else {
            options = this.dataStore.get(optionsDataKey).getValue().filter(function (data) { return data.code === optionsFilterKey; });
        }
        return options;
    };
    /**
     * getKcCompaniesAndDivisions method retrieve the company and division data relevant to the system logged user key controls.
     * As well, this method will update the related data keys which are the kcCompanies and kcDivisions.
     */
    UserManagementService.prototype.getKcCompaniesAndDivisions = function () {
        var _this = this;
        var criteria = new KeyControlAndAbstractSearchCriteria();
        criteria.kcCompany = this.userProfile.kcCompany;
        var divisionList = [];
        if (this.userProfile.kcDivision) {
            criteria.kcDivision = this.userProfile.kcDivision;
        }
        else {
            divisionList.push(this.anyOption);
        }
        this.dataHandlerService.getCompaniesAndDivisions(criteria).subscribe(function (result) {
            var data = result.data[0];
            if (data) {
                _this.dataStoreService.set(DataKey.kcCompanies, [data], true);
                if (data.divisions) {
                    if (_this.userProfile.kcDivision) {
                        divisionList = data.divisions.filter(function (div) { return div.code === _this.userProfile.kcDivision; });
                    }
                    else {
                        divisionList.push.apply(divisionList, tslib_1.__spread(data.divisions));
                    }
                }
                _this.dataStoreService.set(DataKey.kcDivisions, divisionList, true);
            }
        });
    };
    /**
     * getKcBrands method retrieve the brand data relevant to the system logged user key controls.
     * As well, this method will update the related data key which is the kcBrandsList.
     */
    UserManagementService.prototype.getKcBrands = function () {
        var _this = this;
        var criteria = new BrandSearchCriteria();
        var brandList = [];
        criteria.size = this.allItems;
        if (this.userProfile.kcBrand) {
            criteria.code = this.userProfile.kcBrand;
        }
        else {
            brandList.push(this.anyOption);
        }
        this.brandSetupService.getRows(criteria).subscribe(function (result) {
            if (result) {
                brandList.push.apply(brandList, tslib_1.__spread(result.data));
                _this.dataStoreService.set(DataKey.kcBrandsList, brandList, true);
            }
        });
    };
    /**
     * getKcDistChannels method retrieve the distribution channel data relevant to the system logged user key controls.
     * As well, this method will update the related data key which is the kcDistributionChannelList.
     */
    UserManagementService.prototype.getKcDistChannels = function () {
        var _this = this;
        var criteria = new DistributionChannelSearchCriteria();
        criteria.size = this.allItems;
        var distChannelList = [];
        if (this.userProfile.kcDistributionChannel) {
            criteria.code = this.userProfile.kcDistributionChannel;
        }
        else {
            distChannelList.push(this.anyOption);
        }
        this.distributionChannelSetupService.getRows(criteria).subscribe(function (result) {
            if (result) {
                distChannelList.push.apply(distChannelList, tslib_1.__spread(result.data));
                _this.dataStoreService.set(DataKey.kcDistributionChannelList, distChannelList, true);
            }
        });
    };
    UserManagementService.ngInjectableDef = i0.defineInjectable({ factory: function UserManagementService_Factory() { return new UserManagementService(i0.inject(i1.DataStoreService), i0.inject(i2.DataHandlerService), i0.inject(i1.DataStoreService), i0.inject(i3.BrandSetupService), i0.inject(i4.DistributionChannelSetupService), i0.inject(i5.ConfigLoader)); }, token: UserManagementService, providedIn: "root" });
    return UserManagementService;
}());
export { UserManagementService };
