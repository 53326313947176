import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { TC } from '@tc-core/util';
import { ConfigLoader } from '@tc-core/util/framework';
import { of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { SetupGridComp } from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid-comp';
import { SetupGridComponent } from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid.component';
import { TCO } from '../../../constants';
import { AccountingRuleSearchCriteria } from '../../../models/criteria/accounting-rule-search-criteria';
import { ResourceTypeSearchCriteria } from '../../../models/criteria/resource-type-search-criteria';
import { SupplierSearchCriteria } from '../../../models/criteria/supplier-search-criteria';
import { SortDirection } from '../../../models/helper/sort-direction';
import { DataHandlerService } from '../../../services/backend-consumers/master-data-handler-service/data-handler.service';
import { AccountingRuleService } from '../../../services/backend-consumers/setups/accounting-rule.service';
import { ResourceTypeService } from '../../../services/backend-consumers/setups/resource-type.service';
import { SupplierService } from '../../../services/backend-consumers/supplier-service/supplier.service';
import { DataKey, DataStoreService } from '../../../services/util/framework/data-store.service';
import { ResponseUtil } from '../../../services/util/response/response-util.service';
var EMPTY_CHAR = TC.CHIP_INPUT_DEFAULT_VALUES.EMPTY_CHAR;
var AccountingRulesSetupComponent = /** @class */ (function (_super) {
    tslib_1.__extends(AccountingRulesSetupComponent, _super);
    function AccountingRulesSetupComponent(configLoader, accountingRuleService, dataStoreService, dataHandlerService, resourceTypeService, supplierService) {
        var _this = _super.call(this) || this;
        _this.configLoader = configLoader;
        _this.accountingRuleService = accountingRuleService;
        _this.dataStoreService = dataStoreService;
        _this.dataHandlerService = dataHandlerService;
        _this.resourceTypeService = resourceTypeService;
        _this.supplierService = supplierService;
        _this.colDefConfig = [];
        // search criteria obj. used to
        _this.accRuleSearchCriteria = new AccountingRuleSearchCriteria();
        // required options for grid editors
        _this.genericTypes = [];
        _this.GENERIC_CATEGORY = 'generic';
        /*
         grid config methods which are called by grid setup (ag grid)
         and set by grid config
         */
        _this.getAccountingEvents = function (params, rowData, text) {
            return _this.dataStoreService.get(DataKey.accountingEvents)
                .map(function (aEvents) {
                return aEvents.filter(function (aEvent) {
                    if (text) {
                        if (aEvent.name) {
                            return aEvent.name.toLowerCase().indexOf(text.toLocaleString()) > -1;
                        }
                    }
                    else {
                        return true;
                    }
                });
            });
        };
        _this.getAccountingUnits = function (params, rowData, text) {
            return _this.dataStoreService.get(DataKey.accountingUnits)
                .map(function (aUnits) {
                return aUnits.filter(function (aUnit) {
                    if (text) {
                        if (aUnit.name) {
                            return aUnit.name.toLowerCase().indexOf(text.toLocaleString()) > -1;
                        }
                    }
                    else {
                        return true;
                    }
                });
            });
        };
        _this.getLedgerAccounts = function (params, rowData, text) {
            return _this.dataStoreService.get(DataKey.ledgerAccounts)
                .map(function (lAccounts) {
                return lAccounts.filter(function (lAccount) {
                    if (text) {
                        if (lAccount.name) {
                            return lAccount.name.toLowerCase().indexOf(text.toLocaleString()) > -1;
                        }
                    }
                    else {
                        return true;
                    }
                });
            });
        };
        _this.getCurrencies = function (params, rowData, text) {
            return _this.dataStoreService.get(DataKey.currencyList)
                .map(function (currencies) {
                return currencies.filter(function (currency) {
                    if (text) {
                        if (currency.name) {
                            return currency.name.toLowerCase().indexOf(text.toLocaleString()) > -1 ||
                                currency.code.toLowerCase().indexOf(text.toLocaleString()) > -1;
                        }
                    }
                    else {
                        return true;
                    }
                });
            });
        };
        _this.getEventName = function (params) {
            if (params && params.data && params.data.accountingEvent && params.data.accountingEvent.code) {
                return params.data.accountingEvent.code;
            }
            else {
                return EMPTY_CHAR;
            }
        };
        _this.getUnitName = function (params) {
            if (params && params.data && params.data.accountingUnit && params.data.accountingUnit.code) {
                return params.data.accountingUnit.code;
            }
            else {
                return EMPTY_CHAR;
            }
        };
        _this.getAccountCodeName = function (params) {
            if (params && params.data && params.data.ledgerAccount && params.data.ledgerAccount.code) {
                return params.data.ledgerAccount.code + ' | ' + params.data.ledgerAccount.name;
            }
            else {
                return EMPTY_CHAR;
            }
        };
        _this.onEventValueSet = function (params) {
            if (params.data && params.newValue) {
                params.data['accountingEvent'] = params.newValue;
                params.data['eventId'] = params.newValue['eventId'];
            }
            return true;
        };
        _this.onUnitValueSet = function (params) {
            if (params.data && params.newValue) {
                params.data['accountingUnit'] = params.newValue;
                params.data['unitId'] = params.newValue['unitId'];
            }
            return true;
        };
        _this.onLedgerAccountValueSet = function (params) {
            if (params.data && params.newValue) {
                params.data['ledgerAccount'] = params.newValue;
                params.data['account'] = params.newValue['code'];
            }
            return true;
        };
        _this.onCurrencySet = function (params) {
            if (params.data && params.newValue) {
                if (params.newValue['code']) {
                    params.data['currency'] = params.newValue['code'];
                }
                else {
                    params.data['currency'] = params.newValue;
                }
            }
            return true;
        };
        _this.onEffectiveFromDateSet = function (params) {
            if (params.data && params.newValue) {
                params.data['_effectiveFromDate'] = params.newValue;
                params.data['effectiveFrom'] = params.newValue;
            }
            return true;
        };
        _this.onEffectiveToDateSet = function (params) {
            if (params.data && params.newValue) {
                params.data['_effectiveToDate'] = params.newValue;
                params.data['effectiveTo'] = params.newValue;
            }
            return true;
        };
        _this.getSuppliers = function (params, rowData, text) {
            return _this.onSupplierTextChange(params, rowData, text);
        };
        _this.onSetSupplier = function (params) {
            var rule = params.data;
            var supplier = params.newValue;
            if (supplier) {
                rule.supplierId = supplier.supplierId;
                rule.supplierCode = supplier.code;
                rule.supplierName = supplier.name;
            }
            return true;
        };
        _this.getSupplierName = function (params) {
            var rule = params.data;
            if (rule && (rule.supplierName || rule.supplierCode)) {
                return (rule.supplierName ? rule.supplierName : '') + ' | ' + (rule.supplierCode ? rule.supplierCode : '');
            }
            else {
                return EMPTY_CHAR;
            }
        };
        _this.cellEditingStart = function (event) {
            if (event && event.colDef) {
                if (event.colDef.colId === 'account') {
                    _this.loadAccounts();
                }
            }
        };
        return _this;
    }
    AccountingRulesSetupComponent.prototype.ngOnInit = function () {
        this.colDefConfig = this.configLoader.configurations.get(TCO.CONF.CONF_ACCOUNTING_RULE_SETUP);
        this.subscribeSearchCriteria();
        this.fillRequiredGridOptionData();
    };
    /**
     * subscribe search criteria changes in search bar
     * then force grid to refresh data
     * grid refresh will be call getRaws method which is using this subscribed criteria for backend call
     */
    AccountingRulesSetupComponent.prototype.subscribeSearchCriteria = function () {
        var _this = this;
        this.dataStoreService.get(DataKey.accountingRuleSearchCriteria).subscribe(function (value) {
            _this.accRuleSearchCriteria = value;
            _this.setupGrid.runForceSearch();
        });
    };
    AccountingRulesSetupComponent.prototype.isInvalidRow = function (params) {
        var data = params.data;
        if (data) {
            var invalid = true;
            if (data['eventId'] > 0 &&
                data['unitId'] > 0 &&
                data['account'] != null &&
                data['account'] != '' &&
                data['drOrCr'] != null &&
                data['drOrCr'] != '' &&
                data['supplierCode'] != null &&
                data['supplierCode'] != '' &&
                data['effectiveFrom'] != null &&
                data['effectiveTo'] != null) {
                invalid = false;
            }
            return invalid;
        }
        else {
            return false;
        }
    };
    AccountingRulesSetupComponent.prototype.isUnsavedRow = function (row) {
        return false;
    };
    AccountingRulesSetupComponent.prototype.deleteRow = function (row) {
        if (row && row.ruleId) {
            return this.accountingRuleService.deleteRow(row.ruleId);
        }
        else {
            return of('success');
        }
    };
    AccountingRulesSetupComponent.prototype.saveRow = function (row, event) {
        return this.accountingRuleService.saveRow(row);
    };
    AccountingRulesSetupComponent.prototype.getRows = function (params) {
        var _this = this;
        console.log('fetch requesting for ' + params.startRow + ' to ' + params.endRow);
        console.log(params.sortModel);
        var pageSize = params.endRow - params.startRow;
        if (!this.accRuleSearchCriteria) {
            this.accRuleSearchCriteria = new AccountingRuleSearchCriteria();
        }
        this.accRuleSearchCriteria.start = params.startRow;
        this.accRuleSearchCriteria.size = pageSize;
        if (params.sortModel && params.sortModel[0]) {
            this.accRuleSearchCriteria.sortBy = params.sortModel[0].colId;
            if (params.sortModel[0].sort === SetupGridComp.GRID_SORT_ASCENDING) {
                this.accRuleSearchCriteria.sortDirection = SortDirection.ASC;
            }
            else {
                this.accRuleSearchCriteria.sortDirection = SortDirection.DESC;
            }
        }
        return this.accountingRuleService.getRows(this.accRuleSearchCriteria)
            .pipe(tap(function (data) {
            return _this.dataStoreService.set(DataKey.accountingRuleSearchResultsForCriteria, data);
        }));
    };
    AccountingRulesSetupComponent.prototype.createNewRow = function () {
        return {};
    };
    AccountingRulesSetupComponent.prototype.onSupplierTextChange = function (params, rowData, text) {
        var supplierSearchCriteria = new SupplierSearchCriteria();
        supplierSearchCriteria.name = text;
        supplierSearchCriteria.status = 'true';
        //supplierSearchCriteria.serviceRequired = 'true';
        supplierSearchCriteria.start = 0;
        supplierSearchCriteria.size = 10;
        return this.supplierService.searchSuppliers(supplierSearchCriteria)
            .pipe(map(function (response) {
            return ResponseUtil.getDataArray(response);
        }), catchError(function (error) {
            return [];
        }));
    };
    AccountingRulesSetupComponent.prototype.fillRequiredGridOptionData = function () {
        var _this = this;
        var resourceTypeSearchCriteria = new ResourceTypeSearchCriteria();
        resourceTypeSearchCriteria.size = 1000000;
        this.resourceTypeService.getResourceTypes(resourceTypeSearchCriteria).subscribe(function (result) {
            if (result.data) {
                result.data.forEach(function (type) {
                    if (type['resourceCategory'] === _this.GENERIC_CATEGORY) {
                        _this.genericTypes.push(type);
                    }
                });
            }
        });
    };
    AccountingRulesSetupComponent.prototype.loadAccounts = function () {
        var _this = this;
        var criteria = {};
        criteria.size = 1000000;
        this.accountingRuleService.getLedgerAccounts(criteria).subscribe(function (result) {
            _this.dataStoreService.set(DataKey.ledgerAccounts, result['data'], true);
        });
    };
    return AccountingRulesSetupComponent;
}(SetupGridComp));
export { AccountingRulesSetupComponent };
