<div class = "adv-client-search" *ngIf="locationGroupsResultsList">

    <tc-section-heading *ngIf="cardHeading"
                        [title]="cardHeading">
    </tc-section-heading>

    <ng-container
            *ngFor="let locGroup of locationGroupsResultsList; let i = index;">

        <div class = "client-search-result-item-wrapper">
            <div class="result-item"
                 tcExpansionPanel
                 #resultExp
                 tabindex="-1"
                 [collapseTriggerClass]="'card-header'"
                 [include]="['sub-items','tc-expanded-content-card tc-form-view']">

                <!-- Content card Strip -->
                <div *tcExpansionPanelSummary
                     id="resultSumExp_{{i}}"
                     [ngClass]="{'selected-card': (selectionIndex !== '' && (pager.currPage * pager.itemsPerPage + (i + 1)).toString() === selectionIndex)}">

                    <tc-content-strip (click)="onLocationGroupSelection(locGroup,i)">
                        <tc-content-summary-card [summaryContent]="locGroup">
                        </tc-content-summary-card>
                        <!--<span *ngIf="showIndex"-->
                              <!--class="tc-strip-index zoomIn animated">{{pager.currPage * pager.itemsPerPage + (i + 1)}}-->
                        <!--</span>-->
                    </tc-content-strip>
                </div>
                <!-- End of  Content card Strip -->

                <!-- Detail View -->
                <div *tcExpansionPanelDetail
                     id="resultDetailExp_{{i}}">
                    <tc-expanded-content-card class="tc-expanded-content-card tc-form-view" [isEdit]=false>

                        <!--content card header-->
                        <tc-expanded-content-card-header
                                *ngIf="selectedLocationGroupCard && (locGroup?.row1col1.value === selectedLocationGroupCard?.row1col1.value)"
                                [headerText]="locGroup?.row1col2?.value"
                                [subHeaderText]="getSubHeaderText(locGroup?.row1col1.value)"
                                [showEdit]="true"
                                (headerClick)="onHeaderClick($event)"
                                (editClick)="editLocationGroup(locGroup.data.value)">
                        </tc-expanded-content-card-header>

                        <div class="container-fluid tc-item-wrapper gw-group-team-card"
                                *ngIf="locGroup?.data?.value?.locations && locGroup?.data?.value?.locations.length > 0">
                            <div class="tc-item-wrapper__header tc-item-header-highlighted">
                                <div class="tc-item-header-highlighted__left">
                                    <div class="tc-item-header-highlighted__icon">
                                        <i class="material-icons material-icons--fa">map</i>
                                    </div>
                                    <div class="tc-item-header-highlighted__primary">{{"Locations"}}</div>
                                </div>
                                <div class="tc-item-header-highlighted__right">

                                </div>
                            </div>
                            <div class="tc-item-wrapper__body">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="tc-flex-box">
                                            <div class="tc-flex-item icon-cage ">
                                                <div class="card-icon--small icn-color-primary"></div>
                                            </div>
                                            <div class="tc-flex-item input-cage">
                                                <div class="row height-fix-row">
                                                    <!--Repeating row-->
                                                    <ng-container
                                                            *ngFor="let location of locGroup.data.value.locations; let i = index;">

                                                        <!--<div-->
                                                                <!--class="col-xs-12 col-sm-12 col-md-3 col-lg-3"-->
                                                                <!--*ngIf="(i % 4) === 0">-->
                                                            <!--<div class="tc-content--wrap mat-select-wrapper">-->
                                                                <!--{{ location.name }}-->
                                                            <!--</div>-->
                                                        <!--</div>-->
                                                        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3"
                                                                *ngIf="(i % 4) === 0">
                                                            <div class="tc-content--wrap tc-input-view">
                                                                <!--<div class="tc-caption-txt txt-light" *ngIf="i === 0">-->
                                                                    <!--{{"Locations"}}-->
                                                                <!--</div>-->
                                                                <div class="tc-body1--txt">{{ location.name }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3"
                                                             *ngIf="(i % 4) === 1">
                                                            <div class="tc-content--wrap tc-input-view">
                                                                <div class="tc-body1--txt">{{ location.name }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3"
                                                             *ngIf="(i % 4) === 2">
                                                            <div class="tc-content--wrap tc-input-view">
                                                                <div class="tc-body1--txt">{{ location.name }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3"
                                                             *ngIf="(i % 4) === 3">
                                                            <div class="tc-content--wrap tc-input-view">
                                                                <div class="tc-body1--txt">{{ location.name }}
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </ng-container>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <!--<div class="container-fluid tc-item-wrapper tcpi-card"  *ngIf="locGroup?.data?.value?.locations && locGroup?.data?.value?.locations.length > 0">-->
                            <!--<div class="tc-item-wrapper__body">-->
                                <!--<div class="row">-->
                                    <!--<div class="col-md-12">-->
                                        <!--<div class="tc-flex-box">-->
                                            <!--<div class="tc-flex-item icon-cage input-icon">-->
                                                <!--<div class="card-icon&#45;&#45;small icn-color-primary"><i class="material-icons">map</i>-->
                                                <!--</div>-->
                                            <!--</div>-->
                                            <!--<div class="tc-flex-item input-cage">-->
                                                <!--<ng-container-->
                                                        <!--*ngFor="let location of locGroup.data.value.locations; let i = index;">-->
                                                    <!--<div class="col-xs-12 col-sm-12 col-md-3 col-lg-3"-->
                                                         <!--*ngIf="(i % 4) === 0">-->
                                                        <!--<div class="tc-content&#45;&#45;wrap tc-input-view">-->
                                                            <!--<div class="tc-caption-txt txt-light" *ngIf="i === 0">-->
                                                                <!--{{"Locations"}}-->
                                                            <!--</div>-->
                                                            <!--<div class="tc-body1&#45;&#45;txt">{{ location.name }}-->
                                                            <!--</div>-->
                                                        <!--</div>-->
                                                    <!--</div>-->
                                                    <!--<div class="col-xs-12 col-sm-12 col-md-3 col-lg-3"-->
                                                         <!--*ngIf="(i % 4) === 1">-->
                                                        <!--<div class="tc-content&#45;&#45;wrap tc-input-view">-->
                                                            <!--<div class="tc-caption-txt txt-light">-->
                                                                <!--{{ "" }}-->
                                                            <!--</div>-->
                                                            <!--<div class="tc-body1&#45;&#45;txt">{{ location.name }}-->
                                                            <!--</div>-->
                                                        <!--</div>-->
                                                    <!--</div>-->
                                                    <!--<div class="col-xs-12 col-sm-12 col-md-3 col-lg-3"-->
                                                         <!--*ngIf="(i % 4) === 2">-->
                                                        <!--<div class="tc-content&#45;&#45;wrap tc-input-view">-->
                                                            <!--<div class="tc-caption-txt txt-light">-->
                                                                <!--{{ "" }}-->
                                                            <!--</div>-->
                                                            <!--<div class="tc-body1&#45;&#45;txt">{{ location.name }}-->
                                                            <!--</div>-->
                                                        <!--</div>-->
                                                    <!--</div>-->
                                                    <!--<div class="col-xs-12 col-sm-12 col-md-3 col-lg-3"-->
                                                         <!--*ngIf="(i % 4) === 3">-->
                                                        <!--<div class="tc-content&#45;&#45;wrap tc-input-view">-->
                                                            <!--<div class="tc-caption-txt txt-light">-->
                                                            <!--</div>-->
                                                            <!--<div class="tc-body1&#45;&#45;txt">{{ location.name }}-->
                                                            <!--</div>-->
                                                        <!--</div>-->
                                                    <!--</div>-->
                                                <!--</ng-container>-->
                                            <!--</div>-->
                                        <!--</div>-->
                                    <!--</div>-->
                                <!--</div>-->
                            <!--</div>-->
                            <!--&lt;!&ndash;<div class="tc-item-wrapper__header tc-item-header-highlighted">-->
                              <!--<div class="tc-item-header-highlighted__icon">-->
                                <!--<i class="material-icons material-icons&#45;&#45;fa">fa_address_book</i>-->
                              <!--</div>-->
                              <!--<div class="tc-item-header-highlighted__primary">{{"Contact details" | translate}}</div>-->
                            <!--</div>-->
                            <!--<div class="tc-item-wrapper__body">-->
                              <!--<div class="row">-->
                                <!--<div class="col-md-12">-->
                                  <!--<div class="tc-flex-box">-->
                                    <!--<div class="tc-flex-item icon-cage ">-->
                                      <!--<div class="card-icon&#45;&#45;small icn-color-primary"></div>-->
                                    <!--</div>-->
                                    <!--<div class="tc-flex-item input-cage ">-->
                                      <!--<div class="row">-->
                                        <!--<div class="col-xs-12 col-sm-6  col-md-4 col-lg-4">-->
                                          <!--<div class="tc-content&#45;&#45;wrap tc-input-view">-->
                                            <!--<div id="TRD_PER_INFO_ADDRESS" class="tc-caption-txt txt-light">{{"Address" | translate}}-->
                                            <!--</div>-->
                                            <!--<div class="tc-body1&#45;&#45;txt ">-->
                                              <!--&lt;!&ndash;can not check houseNo != '0' if it is not coming when masked&ndash;&gt;-->
                                              <!--<ng-container-->
                                                      <!--id="TRD_PER_INFO_HOU_NO"-->
                                                      <!--*ngIf="client?.summary?.address?.houseNo  && client?.summary?.address?.houseNo != '0'">-->
                                                <!--{{client?.summary?.address?.houseNo | tcAuthMask : (authConfigComponent + '.CLIENT_ADDRESS'): '***********'}},-->
                                              <!--</ng-container>-->
                                              <!--<ng-container-->
                                                      <!--id="TRD_PER_INFO_STREET"-->
                                                      <!--*ngIf="(client?.summary?.address?.street | tcAuthMask : (authConfigComponent + '.CLIENT_ADDRESS'): '***********') as result">-->
                                                <!--{{result}}<br>-->
                                              <!--</ng-container>-->
                                              <!--<ng-container-->
                                                      <!--id="TRD_PER_INFO_CITY"-->
                                                      <!--*ngIf="(client?.summary?.address?.city | tcAuthMask : (authConfigComponent + '.CLIENT_ADDRESS'): '***********') as result">-->
                                                <!--{{result}},-->
                                              <!--</ng-container>-->
                                              <!--<ng-container-->
                                                      <!--id="TRD_PER_INFO_STATE"-->
                                                      <!--*ngIf="(client?.summary?.address?.county | tcAuthMask : (authConfigComponent + '.CLIENT_ADDRESS'): '***********') as result">-->
                                                <!--{{result}}<br>-->
                                              <!--</ng-container>-->
                                              <!--<ng-container-->
                                                      <!--id="TRD_PER_INFO_POST_CODE"-->
                                                      <!--*ngIf="(client?.summary?.address?.postalCode | tcAuthMask : (authConfigComponent + '.CLIENT_ADDRESS'): '***********') as result">-->
                                                <!--{{result}},-->
                                              <!--</ng-container>-->
                                              <!--<ng-container id="TRD_PER_INFO_COUNTRY"-->
                                                            <!--*ngIf="(client?.summary?.address?.country | tcAuthMask : (authConfigComponent + '.CLIENT_ADDRESS'): '***********') as result">-->
                                                <!--{{result}}<br>-->
                                              <!--</ng-container>-->
                                            <!--</div>-->
                                          <!--</div>-->
                                        <!--</div>-->
                                        <!--<div class="col-xs-12 col-sm-6  col-md-3 col-lg-3">-->
                                          <!--<div class="tc-content&#45;&#45;wrap tc-input-view">-->
                                            <!--<div class="tc-caption-txt txt-light">{{"Email" | translate}}-->
                                            <!--</div>-->
                                            <!--<div-->
                                                    <!--id="TRD_PER_INFO_EMAIL"-->
                                                    <!--class="tc-body1&#45;&#45;txt  ">-->
                                              <!--{{(getContact(client?.summary?.address ,CLIENT_CONTACTS_CONST.email) ? getContact(client?.summary?.address ,CLIENT_CONTACTS_CONST.email) :EMPTY_CHAR)-->
                                                <!--| tcAuthMask : (authConfigComponent + '.CLIENT_ADDRESS'): '***********'}}-->
                                            <!--</div>-->
                                          <!--</div>-->
                                          <!--<div class="tc-content&#45;&#45;wrap tc-input-view">-->
                                            <!--<div class="tc-caption-txt txt-light">-->
                                              <!--Fax-->
                                            <!--</div>-->
                                            <!--<div-->
                                                    <!--id="TRD_PER_INFO_FAX"-->
                                                    <!--class="tc-body1&#45;&#45;txt  ">-->
                                              <!--{{(getContact(client?.summary?.address ,CLIENT_CONTACTS_CONST.fax) ? getContact(client?.summary?.address ,CLIENT_CONTACTS_CONST.fax) :EMPTY_CHAR)-->
                                                <!--| tcAuthMask : (authConfigComponent + '.CLIENT_ADDRESS'): '***********'}}-->
                                            <!--</div>-->
                                          <!--</div>-->
                                        <!--</div>-->
                                        <!--<div class="col-xs-12 col-sm-6  col-md-3 col-lg-3">-->
                                          <!--<div class="tc-content&#45;&#45;wrap tc-input-view">-->
                                            <!--<div class="tc-caption-txt txt-light">{{"Telephone" | translate}}-->
                                            <!--</div>-->
                                            <!--<div-->
                                                    <!--id="TRD_PER_INFO_PHONE"-->
                                                    <!--class="tc-body1&#45;&#45;txt  ">-->
                                              <!--{{(getContact(client?.summary?.address ,CLIENT_CONTACTS_CONST.telephone) ? getContact(client?.summary?.address ,CLIENT_CONTACTS_CONST.telephone) :EMPTY_CHAR)-->
                                                <!--| tcAuthMask : (authConfigComponent + '.CLIENT_ADDRESS'): '***********'}}-->
                                            <!--</div>-->
                                          <!--</div>-->
                                          <!--<div class="tc-content&#45;&#45;wrap tc-input-view">-->
                                            <!--<div class="tc-caption-txt txt-light">-->
                                              <!--{{"Web" | translate}}-->
                                            <!--</div>-->
                                            <!--<div id="TRD_PER_INFO_WEB" class="tc-body1&#45;&#45;txt  ">{{client?.detail?.tradeProfile?.web ?-->
                                                <!--client?.detail?.tradeProfile?.web :EMPTY_CHAR}}-->
                                            <!--</div>-->
                                          <!--</div>-->
                                        <!--</div>-->
                                        <!--<div class="col-xs-12 col-sm-6  col-md-2 col-lg-2 last-column">-->
                                          <!--<div class="tc-content&#45;&#45;wrap tc-input-view">-->
                                            <!--<div class="tc-caption-txt txt-light">{{"Mail method" | translate}}-->
                                            <!--</div>-->
                                            <!--<div-->
                                                    <!--id="TRD_PER_INFO_MAIL_METHOD"-->
                                                    <!--class="tc-body1&#45;&#45;txt  ">{{client?.detail?.tradeProfile?.mailMethod ? getName(client?.detail?.tradeProfile?.mailMethod) : '&#45;&#45;'}}-->
                                            <!--</div>-->
                                          <!--</div>-->
                                          <!--<div class="tc-content&#45;&#45;wrap">-->
                                            <!--&lt;!&ndash;<div class="card-chips">&ndash;&gt;-->
                                            <!--&lt;!&ndash;<div&ndash;&gt;-->
                                            <!--&lt;!&ndash;id="TRD_PER_INFO_EXTERNAL"&ndash;&gt;-->
                                            <!--&lt;!&ndash;[ngClass]="client?.summary?.address?.external ? 'active':('' | tcAuthMask : (authConfigComponent + '.CLIENT_ADDRESS'): '':'masked-chip')"&ndash;&gt;-->
                                            <!--&lt;!&ndash;class="chip">&ndash;&gt;-->
                                            <!--&lt;!&ndash;{{"External" | translate}}&ndash;&gt;-->
                                            <!--&lt;!&ndash;</div>&ndash;&gt;-->
                                            <!--&lt;!&ndash;</div>&ndash;&gt;-->
                                          <!--</div>-->
                                        <!--</div>-->
                                      <!--</div>-->
                                    <!--</div>-->
                                  <!--</div>-->
                                <!--</div>-->
                              <!--</div>-->
                            <!--</div>&ndash;&gt;-->
                        <!--</div>-->
                    </tc-expanded-content-card>
                </div>
                <!-- End of Trade/Direct or Groupmaster/Wholesaler Client Detail View -->

            </div>
            <hr>
        </div>

    </ng-container>

    <tc-paginator
            *ngIf="locationGroupsResultsList.length > defaultPageResultCount"
            [length]="locationGroupsResultsList.length"
            [index]="pager.currPage"
            [size]="pager.itemsPerPage"
            [hasMore]="false"
            (page)="onPage($event)">
    </tc-paginator>

</div>



















<!--<div class="tc-item-wrapper">-->
    <!--<div class="tc-item-header tc-flex-box">-->
        <!--<div class="tc-flex-item tc-item-header__primary"></div>-->
        <!--<div class="tc-flex-item tc-item-header__actions tc-icon-block">-->
                       <!--<span>-->
        <!--<button class="btn-primary" (click)="addLocationGroup()">Add new Group</button>-->
                       <!--</span></div>-->
    <!--</div>-->
<!--</div>-->
<!--<div *ngFor="let data of locationGroups">-->
<!--<div class="tc-item-wrapper">-->
    <!--<div class="tc-item-header tc-flex-box">-->
        <!--<div class="tc-flex-item tc-item-header__primary"></div>-->
    <!--</div>-->
<!--</div>-->
<!--<div class="container-fluid direct-client-personal-info-edit-card tc-card-wrapper">-->
    <!--<div class="row">-->
        <!--<div class="col-md-12 col-lg-12">-->
            <!--<div class="tc-flex-box">-->
                <!--<div class="tc-flex-item tc-item-circle">-->
                    <!--<div class="icon-box">-->
                        <!--<i class="material-icons">people</i>-->
                    <!--</div>-->
                <!--</div>-->
                <!--<div class="tc-flex-item tc-right-cage">-->
                    <!--<div class="row">-->
                        <!--<div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">-->
                            <!--<div class="tc-content&#45;&#45;wrap tc-input-view">-->
                                <!--<div class="tc-caption-txt txt-light">Group Name</div>-->
                                <!--<div class="tc-body1&#45;&#45;txt">{{data.groupName}}</div>-->
                            <!--</div>-->
                        <!--</div>-->
                        <!--<div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">-->
                            <!--<div class="tc-content&#45;&#45;wrap tc-input-view">-->
                                <!--<div class="tc-caption-txt txt-light">Group Code</div>-->
                                <!--<div class="tc-body1&#45;&#45;txt">{{data.groupCode}}</div>-->
                            <!--</div>-->
                        <!--</div>-->

                    <!--</div>-->
                    <!--<div class="tc-caption-txt txt-light">Locations</div>-->
                    <!--<div *ngFor="let loc of data.locations">-->
                    <!--<div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">-->
                        <!--<div class="tc-content&#45;&#45;wrap tc-input-view">-->
                            <!--<div class="tc-caption-txt txt-light"></div>-->
                            <!--<div class="tc-body1&#45;&#45;txt">{{loc.name}}</div>-->
                        <!--</div>-->
                    <!--</div>-->
                <!--</div>-->
            <!--</div>-->
            <!--<div class="tc-flex-item tc-item-circle">-->
                <!--<div class="icon-box">-->
                    <!--<i class="material-icons tc-icon-block__icon tc-icon-block__icon&#45;&#45;active" (click)="editLocationGroup(data)">edit</i>-->
                <!--</div>-->
            <!--</div>-->
        <!--</div>-->
    <!--</div>-->
<!--</div>-->
<!--</div>-->
<!--</div>-->