import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TcApiError } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-api-error';
import { TcHttpError } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-http-error';
import { LEVEL, ModalData } from '@tc-core/model/it/codegen/ui/framework/modal-data';
import { Pager } from '@tc-core/model/it/codegen/ui/framework/pager';
import { SorterService } from '@tc-core/service/sorters/sorter.service';
import { ConfigLoader } from '@tc-core/util/framework/config-loader.service';
import { UserJourneyManager } from '@tc-core/util/framework/user-journey-manager.service';
import { CommonHelper } from '@tc-core/util/helpers/common-helper.service';
import { AutoScrollService } from '@tc-core/util/ui/auto-scroll.service';
import { SpinnerService } from '@tc-core/util/ui/spinner.service';
import { FocusViewService } from '@tc/focus-view/focus-view.service';
import { Subscription } from 'rxjs';
import { TCO } from '../../constants';
import { GenericSearchCriteria } from '../../models/criteria/generic-search-criteria';
import { ResourceTypeSearchCriteria } from '../../models/criteria/resource-type-search-criteria';
import { DataHandlerService } from '../../services/backend-consumers/master-data-handler-service/data-handler.service';
import { ResourceTypeService } from '../../services/backend-consumers/setups/resource-type.service';
import { GenericResourceService } from '../../services/backend-consumers/supplier-service/generic-resource.service';
import { SupplierService } from '../../services/backend-consumers/supplier-service/supplier.service';
import { RootService } from '../../services/util/core-services/root.service';
import { DataKey, DataStoreService } from '../../services/util/framework/data-store.service';
import { DMCQueryParamsService } from '../../services/util/framework/dmc-query-params.service';
import { ResponseUtil } from '../../services/util/response/response-util.service';
import { ContentSummaryCardService } from '../../services/util/ui/content-summary-card.service';
import { ResAvailabilityCalendarFocusViewComponent } from '../../widgets/shared/res-availability-calendar-focus-view/res-availability-calendar-focus-view.component';
var GenericSearchResultsComponent = /** @class */ (function () {
    function GenericSearchResultsComponent(configLoader, genericResourceService, dataStore, supplierService, sorterService, summaryCardService, spinnerService, userJourneyManager, dataHandlerService, resourceTypeService, commonHelper, activatedRoute, rootService, focusViewService, queryParamService, autoScrollService) {
        this.configLoader = configLoader;
        this.genericResourceService = genericResourceService;
        this.dataStore = dataStore;
        this.supplierService = supplierService;
        this.sorterService = sorterService;
        this.summaryCardService = summaryCardService;
        this.spinnerService = spinnerService;
        this.userJourneyManager = userJourneyManager;
        this.dataHandlerService = dataHandlerService;
        this.resourceTypeService = resourceTypeService;
        this.commonHelper = commonHelper;
        this.activatedRoute = activatedRoute;
        this.rootService = rootService;
        this.focusViewService = focusViewService;
        this.queryParamService = queryParamService;
        this.autoScrollService = autoScrollService;
        this.configs = {};
        this.heading = '';
        this.defaultPageResultCount = 10;
        this.selectionIndex = '';
        this.fetchedReservationsCount = 0;
        this.dataFromMoreClick = false;
        this.paginatorHasMore = true;
        this.resultCount = 0;
        this.searchCriteria = new GenericSearchCriteria();
        this.genericResourceDataArray = [];
        this.contentCardDataList = [];
        this.selectedGenericResourceSummary = null;
        this.resourceTypes = [];
        this.transferModes = [];
        this.resourceCost = [];
        this.firstTimeSupplierResults = false;
        this.navConfig = [];
        this.focusViewCloseSubject = new Subscription();
        this.genericSearchResultSubscription = new Subscription();
        this.genericSearchResultFromServiceSubscription = new Subscription();
        this.genericSearchResultFromServiceOnMoreSubscription = new Subscription();
        this.genericSearchCriteriaSubscription = new Subscription();
        this.supplierSubscription = new Subscription();
    }
    GenericSearchResultsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.tGenericResourceGeneric = this.configLoader.configurations.get(TCO.CONF.CONF_GENERIC_SEARCH_RESULTS_CONFIG);
        this.tGenericResourceGuide = this.configLoader.configurations.get(TCO.CONF.CONF_GUIDE_SEARCH_RESULTS_CONFIG);
        this.tGenericResourceDrive = this.configLoader.configurations.get(TCO.CONF.CONF_DRIVER_SEARCH_RESULTS_CONFIG);
        this.createConfigMap();
        this.tPagination = this.configLoader.configurations.get(TCO.CONF.CONF_PAGINATION_CONFIG)['GENERIC_RESOURCE_SEARCH'];
        this.setupPaginator();
        this.getSearchCriteria();
        this.subscribeSearchResults();
        this.getSearchResults();
        this.createSorters();
        var resourceTypeSearchCriteria = new ResourceTypeSearchCriteria();
        resourceTypeSearchCriteria.size = 1000000;
        this.resourceTypeService.getResourceTypes(resourceTypeSearchCriteria).subscribe(function (result) {
            if (result.data) {
                _this.resourceTypes = result.data;
            }
        });
        this.dataHandlerService.retrieveTransferModes().subscribe(function (result) {
            if (result.data) {
                _this.transferModes = result.data;
            }
        });
    };
    GenericSearchResultsComponent.prototype.createConfigMap = function () {
        this.configs['generic'] = this.tGenericResourceGeneric;
        this.configs['driver'] = this.tGenericResourceDrive;
        this.configs['guide'] = this.tGenericResourceGuide;
    };
    GenericSearchResultsComponent.prototype.setupPaginator = function () {
        this.defaultPageResultCount = this.tPagination['defaultPageResultCount'];
        this.pager = new Pager(0, this.defaultPageResultCount);
    };
    GenericSearchResultsComponent.prototype.onPage = function (pageNumber) {
        this.pager.currPage = pageNumber;
        this.selectionIndex = (this.pager.currPage * this.pager.itemsPerPage).toString();
    };
    GenericSearchResultsComponent.prototype.onSelection = function (event) {
        this.selectionIndex = event;
    };
    GenericSearchResultsComponent.prototype.onMore = function () {
        this.dataFromMoreClick = true;
        if (this.tPagination && this.tPagination['defaultPageResultCount'] && this.tPagination['fetchingPages']) {
            this.searchCriteria.size = this.tPagination['fetchingPages'] * this.tPagination['defaultPageResultCount'];
            this.searchCriteria.start = this.fetchedReservationsCount;
            this.retrieveAndCollectSearchResults(this.searchCriteria);
        }
    };
    GenericSearchResultsComponent.prototype.setPageZero = function () {
        if (this.pager) {
            if (!this.dataFromMoreClick) {
                this.pager.currPage = 0;
            }
            else {
                this.dataFromMoreClick = false;
            }
        }
        else {
            this.pager = new Pager(0, this.defaultPageResultCount);
        }
    };
    GenericSearchResultsComponent.prototype.subscribeSearchResults = function () {
        var _this = this;
        this.genericSearchResultFromServiceSubscription = this.dataStore.get(DataKey.genericResourceSearchResultFromService)
            .subscribe(function (result) {
            _this.resultCount = 0;
            if (result) {
                _this.resultCount = ResponseUtil.getTotalCount(result);
                var dataList = ResponseUtil.getDataArray(result);
                _this.dataStore.set(DataKey.genericResourceSearchResult, dataList, true);
            }
        });
    };
    // -- paginator--
    /**
     * keep fetched data and add new data to dataStore for pagination. only use in pagination more
     *
     * @param searchCriteria
     */
    GenericSearchResultsComponent.prototype.retrieveAndCollectSearchResults = function (searchCriteria) {
        var _this = this;
        if (this.genericSearchResultFromServiceOnMoreSubscription) {
            this.genericSearchResultFromServiceOnMoreSubscription.unsubscribe();
        }
        this.genericSearchResultFromServiceOnMoreSubscription = this.genericResourceService.searchGenericResourceForPaging(searchCriteria)
            .subscribe(function (result) {
            if (result) {
                _this.resultCount = ResponseUtil.getTotalCount(result);
                var dataList = _this.searchResults.concat(ResponseUtil.getDataArray(result));
                _this.dataStore.set(DataKey.genericResourceSearchResult, dataList, true);
            }
        });
    };
    GenericSearchResultsComponent.prototype.getSearchCriteria = function () {
        var _this = this;
        this.genericSearchCriteriaSubscription = this.dataStore.get(DataKey.genericSearchCriteria)
            .subscribe(function (data) {
            if (data !== null) {
                _this.searchCriteria = data;
                _this.createSorters();
            }
        });
    };
    GenericSearchResultsComponent.prototype.createSorters = function () {
        var _this = this;
        if (this.searchCriteria) {
            this.sorterService.sorters = [];
            this.sorters_ = [];
            var sorterMap_1 = new Map();
            var specificConfig = null;
            if (this.searchCriteria.resourceType && this.configs[this.searchCriteria.resourceType]) {
                specificConfig = this.configs[this.searchCriteria.resourceType];
            }
            else if (this.configs['generic']) {
                specificConfig = this.configs['generic'];
            }
            if (specificConfig.config && specificConfig.config.placeHolderConfig) {
                specificConfig.config.placeHolderConfig.forEach(function (config) {
                    if (config && config.sort) {
                        var sorter = _this.sorterService.createSorter(config);
                        var id = config['_id'];
                        sorterMap_1.set(id, sorter);
                        _this.sorters_.push(sorter);
                    }
                });
            }
        }
    };
    GenericSearchResultsComponent.prototype.onSort = function (event) {
        this.sorterService.sorter = event;
    };
    GenericSearchResultsComponent.prototype.getSearchResults = function () {
        var _this = this;
        this.fetchedReservationsCount = 0;
        this.genericSearchResultSubscription = this.dataStore.get(DataKey.genericResourceSearchResult)
            .subscribe(function (data) {
            _this.selectionIndex = '';
            _this.searchResults = [];
            if (data != null) {
                _this.spinnerService.hide();
            }
            if (data) {
                _this.searchResults = data;
                _this.fetchedReservationsCount = _this.searchResults.length;
                if (_this.fetchedReservationsCount > 0) {
                }
                _this.getGenericResources();
            }
            else {
                _this.contentCardDataList = [];
                _this.heading = '';
            }
        });
    };
    GenericSearchResultsComponent.prototype.getGenericResources = function () {
        if (this.searchResults.length !== null) {
            var genericResources_1 = this.searchResults;
            var genericResourceMap = [];
            var _loop_1 = function (i) {
                var id = genericResources_1[i].resourceId;
                var name_1 = (genericResources_1[i].firstName ?
                    genericResources_1[i].firstName + ' ' :
                    '') + (genericResources_1[i].lastName ? genericResources_1[i].lastName : '-');
                var grade = genericResources_1[i].grade;
                var workingHours = genericResources_1[i].workingHours;
                var supplierId = genericResources_1[i].supplierId;
                var supplierName = genericResources_1[i].supplierName;
                var supplierCode = genericResources_1[i].supplierCode;
                var minPax = genericResources_1[i].minPax;
                var maxPax = genericResources_1[i].maxPax;
                var activeStatus = genericResources_1[i].activeStatus;
                var type = genericResources_1[i].resourceType;
                var typeName = this_1.resourceTypes.find(function (value) { return value.code === genericResources_1[i].resourceType; }) ? this_1.resourceTypes.find(function (value) { return value.code === genericResources_1[i].resourceType; }).name : null;
                var currency = genericResources_1[i].currency;
                var paymentMethod = genericResources_1[i].paymentMethod;
                var phoneNumber = genericResources_1[i].phoneNumber;
                var city = genericResources_1[i].city;
                var cityName = genericResources_1[i].cityName;
                var preferredRoute = genericResources_1[i].preferredRoute;
                var preferredTransferMode = genericResources_1[i].preferredTransferMode;
                var preferredTransferModes = genericResources_1[i].preferredTransferModes;
                var languages = genericResources_1[i].languages;
                var yearsOfExperience = genericResources_1[i].yearsOfExperience;
                genericResourceMap.push({
                    'resourceId': id,
                    'name': name_1,
                    'supplierId': supplierId,
                    'supplierName': supplierName,
                    'supplierCode': supplierCode,
                    'supplier': supplierName + ' (' + supplierCode + ')',
                    'grade': grade,
                    'workingHours': workingHours,
                    'minPax': minPax,
                    'maxPax': maxPax,
                    'activeStatus': activeStatus,
                    'type': type,
                    'typeName': typeName,
                    'currency': currency,
                    'paymentMethod': paymentMethod,
                    'phoneNumber': phoneNumber,
                    'city': city,
                    'cityName': cityName,
                    'preferredRoute': preferredRoute,
                    'preferredTransferMode': preferredTransferMode,
                    'preferredTransferModes': preferredTransferModes,
                    'languages': languages,
                    'yearsOfExperience': yearsOfExperience,
                    'rawData': genericResources_1[i]
                });
            };
            var this_1 = this;
            for (var i = 0; i < genericResources_1.length; i++) {
                _loop_1(i);
            }
            this.genericResourceDataArray = genericResourceMap;
            this.contentCardDataList = this.getSearchContent();
        }
    };
    GenericSearchResultsComponent.prototype.getSearchContent = function () {
        var contentCardList = this.summaryCardService.getContentCardsByTypeConfig(this.configs, this.genericResourceDataArray);
        this.spinnerService.hide();
        this.heading = this.resultCount + ' result' + (this.resultCount > 1 ? 's' : '') + ' found.';
        this.paginatorHasMore = this.resultCount !== contentCardList.length;
        this.setPageZero();
        return contentCardList;
    };
    GenericSearchResultsComponent.prototype.onResourceSelect = function ($event) {
        var _this = this;
        this.selectedGenericResourceSummary = $event;
        this.getSelectedResourceCost();
        var id = $event.data['value']['resourceId'];
        if (id !== null) {
            this.spinnerService.show();
            this.genericResourceService.retrieveResourceById(id)
                .subscribe(function (res) {
                if (_this.commonHelper.dataValidity(res)) {
                    _this.spinnerService.hide();
                    _this.detailedResource = ResponseUtil.getFirstData(res);
                    var selectedResource = {
                        'resourceId': _this.detailedResource.resourceId,
                        'resourceType': _this.detailedResource.resourceType
                    };
                    var parameters = _this.queryParamService.getQueryParamsArray(selectedResource);
                    _this.rootService.setDataToLocalStorage(TCO.AppData.RESOURCE, selectedResource, true, parameters);
                    // this.detailSupplier = res;
                    console.log(_this.detailedResource);
                }
                else if (res instanceof TcApiError) {
                    _this.spinnerService.hide();
                }
                else if (res instanceof TcHttpError) {
                    _this.spinnerService.hide();
                }
            });
        }
    };
    GenericSearchResultsComponent.prototype.getSelectedResourceCost = function () {
        this.dataStore.set(DataKey.supplier, null, true);
        this.firstTimeSupplierResults = true;
        var id = this.selectedGenericResourceSummary.data['value']['supplierId'];
        this.supplierService.retrieveSupplierById(String(id));
        this.supplierResultObserver();
    };
    GenericSearchResultsComponent.prototype.supplierResultObserver = function () {
        var _this = this;
        this.supplierSubscription = this.dataStore.get(DataKey.supplier).subscribe(function (result) {
            var e_1, _a, e_2, _b;
            if (result != null && _this.firstTimeSupplierResults) {
                _this.firstTimeSupplierResults = false;
                _this.resourceCost = [];
                var supplier = ResponseUtil.getFirstData(result);
                if (supplier) {
                    try {
                        for (var _c = tslib_1.__values(supplier.seasons), _d = _c.next(); !_d.done; _d = _c.next()) {
                            var value = _d.value;
                            var genCosts = [];
                            try {
                                for (var _e = tslib_1.__values(value.genResourceCosts), _f = _e.next(); !_f.done; _f = _e.next()) {
                                    var cost = _f.value;
                                    if (cost.resourceType === _this.selectedGenericResourceSummary.data['value']['type']) {
                                        genCosts.push(cost);
                                    }
                                }
                            }
                            catch (e_2_1) { e_2 = { error: e_2_1 }; }
                            finally {
                                try {
                                    if (_f && !_f.done && (_b = _e.return)) _b.call(_e);
                                }
                                finally { if (e_2) throw e_2.error; }
                            }
                            value.genResourceCosts = genCosts;
                            if (value.genResourceCosts && value.genResourceCosts.length > 0) {
                                _this.resourceCost.push(value);
                            }
                        }
                    }
                    catch (e_1_1) { e_1 = { error: e_1_1 }; }
                    finally {
                        try {
                            if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
                        }
                        finally { if (e_1) throw e_1.error; }
                    }
                }
            }
        }, function (error) {
        });
    };
    GenericSearchResultsComponent.prototype.onEditResource = function ($event) {
        this.userJourneyManager.goForKey('GO_TO_EDIT_GENERIC_RESOURCE');
        $event.isEditTriggered = true;
        this.dataStore.set(DataKey.selectedGenericResourceForEdit, $event, true);
    };
    GenericSearchResultsComponent.prototype.onCalendarClick = function (event) {
        var _this = this;
        this.navConfig = this.configLoader.configurations.get(TCO.CONF.CONF_PAGE_SECTION_CONFIG)['availability_calendar_side_nav']['resource_level'];
        setTimeout(function () {
            var fabActions = [];
            /*let navItemsArray = this.configLoader.configurations.get(TCO.CONF.CONF_NAVIGATION);
             this.configLoader.configurations.get(TCO.CONF.CONF_FOCUS_VIEW).AVAILABILITY_CAL_FOCUS_VIEW.fabAction
             .forEach(val => {
             for (let key in navItemsArray) {
             if (val === navItemsArray[key]['id']) {
             fabActions.push(navItemsArray[key]);
             }
             }
             });*/
            var dataObject = new ModalData(LEVEL.SUCCESS, 'Availability Calendar', null, null, 'season-setup-focus-view', ResAvailabilityCalendarFocusViewComponent, {
                'resourceSummary': event.data.value,
                'navConfig': _this.navConfig
            }, { 'label': 'close' }, '', '', fabActions, '', (event && event.data.value) ? (event.data.value.name) + ' | Supplier: ' + (event.data.value.supplierName) : '');
            _this.focusViewCloseSubject = _this.dataStore.get(DataKey.availabilityCalendarFocusViewClose).subscribe(function (c) {
                if (c) {
                }
            });
            _this.focusViewService.confirm(dataObject).subscribe(function (res) {
                if (res) {
                    console.log(res);
                }
            });
        }, 0);
    };
    GenericSearchResultsComponent.prototype.onCardScroll = function ($event) {
        var _this = this;
        setTimeout(function () {
            _this.autoScrollService.scrollToID('resourceDetailExp_' + $event, 'main-scroll-container');
        }, 0);
    };
    GenericSearchResultsComponent.prototype.onEditAsNewResource = function ($event) {
        this.clearIdsInResource($event);
        this.userJourneyManager.goForKey('GO_TO_CREATE_GENERIC');
        this.dataStore.set(DataKey.selectedGenericResourceForEditAsNew, $event, true);
    };
    /**
     * IMPORTANT : clear all the existing ids that needs to be saved as new entities
     *
     * @param resource
     */
    GenericSearchResultsComponent.prototype.clearIdsInResource = function (resource) {
        resource.resourceId = 0;
        if (resource.contactDetails) {
            resource.contactDetails.forEach(function (value) { return value.contactId = 0; });
        }
        if (resource.documents) {
            resource.documents.forEach(function (value) { return value.id = 0; });
        }
        if (resource.remarks) {
            resource.remarks.forEach(function (value) { return value.remarkId = 0; });
        }
        if (resource.taxSchemes) {
            resource.taxSchemes.forEach(function (value) { return value.taxSchemeId = 0; });
        }
        if (resource.paymentInfo) {
            resource.paymentInfo.paymentId = 0;
        }
        if (resource.supplierAttributes) {
            resource.supplierAttributes.forEach(function (value) { return value.id = 0; });
        }
        if (resource.genericResource) {
            resource.genericResource.genericResId = 0;
            if (resource.genericResource.languages) {
                resource.genericResource.languages.forEach(function (value) {
                    value.tblId = 0;
                    value.genericResourceId = 0;
                });
            }
        }
        if (resource.vehicle) {
            resource.vehicle.vehicleId = 0;
            if (resource.vehicle.facilities) {
                resource.vehicle.facilities.forEach(function (value) {
                    value.tblFacilityId = 0;
                    value.vehicleId = 0;
                });
            }
            if (resource.vehicle.resServiceInfo) {
                resource.vehicle.resServiceInfo.serviceInfoId = 0;
            }
            if (resource.vehicle.resInsuranceInfo) {
                resource.vehicle.resInsuranceInfo.insuranceInfoId = 0;
            }
        }
    };
    GenericSearchResultsComponent.prototype.ngOnDestroy = function () {
        if (this.genericSearchResultSubscription) {
            this.genericSearchResultSubscription.unsubscribe();
        }
        if (this.genericSearchResultFromServiceSubscription) {
            this.genericSearchResultFromServiceSubscription.unsubscribe();
        }
        if (this.genericSearchResultFromServiceOnMoreSubscription) {
            this.genericSearchResultFromServiceOnMoreSubscription.unsubscribe();
        }
        if (this.genericSearchCriteriaSubscription) {
            this.genericSearchCriteriaSubscription.unsubscribe();
        }
        if (this.supplierSubscription) {
            this.supplierSubscription.unsubscribe();
        }
        if (this.focusViewCloseSubject) {
            this.focusViewCloseSubject.unsubscribe();
        }
    };
    return GenericSearchResultsComponent;
}());
export { GenericSearchResultsComponent };
