import { AfterViewInit, Component, ViewChild, ViewContainerRef } from '@angular/core';
import { MatSelect } from '@angular/material';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import * as _ from 'lodash';

@Component({
    selector: 'tc-dropdown-editor',
    templateUrl: './radio-button-editor.component.html'
})
export class RadioButtonEditorComponent implements ICellEditorAngularComp, AfterViewInit {

    @ViewChild('selectBox', {read: ViewContainerRef}) public selectBoxContainerRef;
    @ViewChild('selectBox', {read: MatSelect}) public selectBox: MatSelect;

    // input parameters
    params: any;
    options: any[];
    plainOptionArray: false;
    selectMultiple: false;
    viewKey = 'name';
    valueKey = 'code';
    rawValue: any;
    returnObject = false;
    rowObject: string;
    rowData: any;
    displayItemFn: any;

    // selected
    selectedItem: any;
    columnWidth: string;

    constructor() {
    }

    ngAfterViewInit(): void {
        window.setTimeout(() => {
            this.selectBoxContainerRef.element.nativeElement.focus();
        });
    }

    agInit(params): void {
        this.params = params;
        this.columnWidth = params.column.actualWidth + 'px';
        this.options = params['options'];
        this.plainOptionArray = params['plainOptionArray'] ? params['plainOptionArray'] : this.plainOptionArray;
        this.viewKey = params['viewKey'] ? params['viewKey'] : this.viewKey;
        this.valueKey = params['valueKey'] ? params['valueKey'] : this.valueKey;
        this.returnObject = params['returnObject'] ? params['returnObject'] : this.returnObject;
        this.rowData = params['data'];
        this.rowObject = params['rowObject'];
        this.rawValue = params['value'];
        this.selectedItem = this.getSelectedOption();
        this.displayItemFn = params['displayItemFn'];

        // initial display function
        if (!this.displayItemFn) {
            this.displayItemFn = (option) => {
                if (this.plainOptionArray) {
                    return option;
                } else {
                    return option[this.viewKey];
                }
            };
        }

    }

    getValue(): any {
        if (this.selectedItem) {
            if (this.returnObject || this.plainOptionArray) {
                return this.selectedItem;
            } else {
                return this.selectedItem[this.valueKey] ? this.selectedItem[this.valueKey] : this.selectedItem;
            }
        } else {
            return this.rawValue;
        }
    }

    isPopup(): boolean {
        return true;
    }

    onValueChange($event: any) {
        if (!this.selectMultiple) {
            this.params.api.stopEditing();
        }
    }

    public getSelectedOption() {
        let selected = null;
        if (this.rawValue) {
            if (this.plainOptionArray) {
                selected = this.options.find(
                    value => value === this.rawValue
                );
            } else {
                selected = this.options.find(
                    value => value[this.valueKey] === this.rawValue
                );
            }
        }
        return selected;
    }
}
