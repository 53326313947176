import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {OperatorAcceptanceQueueComponent} from './operator-acceptance-queue.component';
import {ChipInputModule} from '@tc/chip-input';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule} from '@angular/forms';
import {ResultHeaderModule} from '../../widgets/shared/result-header/result-header.module';
import {MatIconModule} from '@angular/material/icon';
import {ObjKeysPipePipeModule} from '../../pipes/obj-keys-pipe-pipe.module';
import {AgGridCustomRenderersModule} from '../../widgets/framework/ag-grid-custom-components/renderers/ag-grid-custom-renderers.module';
import {AgGridCustomEditorsModule} from '../../widgets/framework/ag-grid-custom-components/editors/ag-grid-custom-editors.module';
import {AgGridCustomDirectivesModule} from '../../widgets/framework/ag-grid-custom-components/directives/ag-grid-custom-directives.module';
import {AgGridCustomFiltersModule} from '../../widgets/framework/ag-grid-custom-components/filters/ag-grid-custom-filters.module';
import {AgGridModule} from 'ag-grid-angular';
import {TemplateRendererComponent} from '../../widgets/framework/ag-grid-custom-components/renderers/template-renderer/template-renderer.component';
import {ActionsComponent} from '../../widgets/framework/ag-grid-custom-components/renderers/actions/actions.component';
import {DropdownEditorComponent} from '../../widgets/framework/ag-grid-custom-components/editors/dropdown-editor/dropdown-editor.component';
import {AutoCompleteEditorComponent} from '../../widgets/framework/ag-grid-custom-components/editors/auto-complete-editor/auto-complete-editor.component';
import {NoteEditorComponent} from '../../widgets/framework/ag-grid-custom-components/editors/note-editor/note-editor.component';
import {CustomTextFilterComponent} from '../../widgets/framework/ag-grid-custom-components/filters/custom-text-filter/custom-text-filter.component';
import {MatMenuModule} from '@angular/material/menu';
import {MatListModule} from '@angular/material/list';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatButtonModule} from '@angular/material/button';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
    declarations: [OperatorAcceptanceQueueComponent],
    imports: [
        CommonModule,
        BrowserAnimationsModule,
        FormsModule,
        ResultHeaderModule,
        MatIconModule,

        ObjKeysPipePipeModule,

        // **** ag grid ***
        AgGridCustomRenderersModule,
        AgGridCustomEditorsModule,
        AgGridCustomDirectivesModule,
        AgGridCustomFiltersModule,
        AgGridModule.withComponents([
            // renderer
            TemplateRendererComponent,
            ActionsComponent,

            // editors
            DropdownEditorComponent,
            AutoCompleteEditorComponent,
            NoteEditorComponent,

            // filters
            CustomTextFilterComponent

        ]),
        MatMenuModule,
        MatListModule,
        MatTooltipModule,
        MatButtonModule,
        TranslateModule.forChild(),
    ],
    exports: [OperatorAcceptanceQueueComponent],
    entryComponents: [OperatorAcceptanceQueueComponent]
})
export class OperatorAcceptanceQueueModule {
}
