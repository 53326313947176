import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { isNumeric } from 'rxjs/internal/util/isNumeric';
import { Booking } from '../../../../models/reservation-v2/booking';

@Component({
    selector: 'tc-booking-info',
    templateUrl: './booking-info.component.html',
    styleUrls: ['./booking-info.component.scss']
})
export class BookingInfoComponent implements OnInit {

    bookingIdSourceSeparator = '-';
    @Input() booking: Booking;
    @Input() readonly = false;

    @Output() changeValidity: EventEmitter<boolean> = new EventEmitter();

    bookingInfoForm: FormGroup;

    constructor(
        private fb: FormBuilder
    ) { }

    ngOnInit() {
        this.createForm();
        this.validateForm();
        this.bookingInfoForm.valueChanges.subscribe(
            data => {
                if (JSON.stringify(data) !== JSON.stringify({})) {
                    this.booking.bookingIdCombined = data.bookingIdCombined;
                    this.changeValidity.emit(this.bookingInfoForm.valid || this.readonly);
                }
            }
        );
    }

    public validateForm() {
        Object.keys(this.bookingInfoForm.controls).forEach(field => {
            const control = this.bookingInfoForm.get(field);
            control.markAsTouched({onlySelf: true});
        });
    }

    private createForm() {
        this.bookingInfoForm = this.fb.group({
                bookingIdCombined: new FormControl(
                    {value: this.booking.bookingIdCombined ? this.booking.bookingIdCombined : '', disabled: this.readonly},
                    Validators.compose([
                        this.isBookingIdValid.bind(this)
                    ])
                )
            }
        );
        this.changeValidity.emit(this.bookingInfoForm.valid || this.readonly);
    }

    public getErrorMessage(field: string) {
        if (this.bookingInfoForm) {
            switch (field) {
                case 'bookingIdCombined' :
                    if (this.bookingInfoForm.get('bookingIdCombined').hasError('formatError')) {
                        return 'Booking Id Format Error. Format: SourceCode-ID. Ex: DMC-1010';
                    } else if (this.bookingInfoForm.get('bookingIdCombined').hasError('required')) {
                        return 'Booking Id Required';
                    } else {
                        return '';
                    }
                default:
                    return '';
            }
        }
    }

    private isBookingIdValid(control: AbstractControl): ValidationErrors | null {
        const value = control.value;
        if (value) {
            const split = (value + '').split(this.bookingIdSourceSeparator);
            if (split.length === 2 && split[0] && split[1]) {
                if (!isNumeric(split[1])) {
                    return {formatError: true};
                }
                return null;
            } else {
                return {formatError: true};
            }
        } else {
            return null;
        }
    }
}
