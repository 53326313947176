<div class="mat-input-container">
    <div class="tc-modal-content">
    <mat-form-field>
        <input
                matInput
                maxlength="40"
                [placeholder]="'Criteria Name' | translate"
                [formControl]="criteriaName"
        >
    </mat-form-field>
    </div>
</div>

<div class="container assign-panel-footer-section">
    <button class="assign-panel-footer-section__button tc-button-secondary mat-button" (click)="onClose($event)">
        {{'Close' | translate}}
    </button>
    <button
            class="assign-panel-footer-section__button tc-button-primary mat-button"
            (click)="onSave()"
            [disabled]="criteriaName?.value === '' ">
        {{'Save' | translate}}
    </button>
</div>
