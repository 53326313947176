import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BookingItem} from '../../../../../models/reservation-v2/booking-item';
import {BookingNoteType} from '../../../../../models/reservation-v2/booking-note';
import {Booking} from '../../../../../models/reservation-v2/booking';

@Component({
  selector: 'tc-gen-item-detail-view',
  templateUrl: './gen-item-detail-view.component.html'
})
export class GenItemDetailViewComponent implements OnInit {

  @Input() readonly = false;
  @Input() bookingItem: BookingItem;
  @Input() noteTypes: BookingNoteType[] = [];
  @Input() booking: Booking;

  @Output() changeValidity: EventEmitter<boolean> = new EventEmitter();
  constructor() { }

  ngOnInit() {
  }

}
