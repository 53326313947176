<div class="detail-group__heading container-fluid" >
    <div class="item-header tc-my-2 tc-py-3 tc-pl-3 tc-pr-2" id="itemHeading">
        <div class="item-header__content" id="itemHeaderContent"></div>
        <div class="tc-icon-block action-icon" id="actionIcon">
            <div *ngIf="isTimeGroupingVisible()" class="tc-icon-block__icon results-header__action-icon tc-mr-2">
                <tc-chip-dropdown
                        class="transfer-mode-selection"
                        [dropdownOptions]="serviceQueueTimeGroupingOptions"
                        (selectedOption)="onSelectTimeGrouping($event)">
                </tc-chip-dropdown>
            </div>

            <button mat-icon-button
                    matTooltip="{{'Primary Resources' | translate}}"

                    (click)="selectColumnGroup('primary')"
                    [disabled]="">
                <i class="material-icons">looks_one</i>
            </button>
            <button mat-icon-button
                    matTooltip="{{'Secondary Resources' | translate}}"
                    (click)="selectColumnGroup('secondary')"
                    [disabled]="">
                <i class="material-icons">looks_two</i>
            </button>

            <button mat-icon-button
                    aria-label="Delete"
                    (click)="onRestoreColDefClick()"
                    matTooltip="{{'Select All Columns' | translate}}"
                    [disabled]="">
                <i class="material-icons">select_all</i>
            </button>
            <button
                    matTooltip="{{'Show/Hide Columns' | translate}}"
                    mat-icon-button
                    [matMenuTriggerFor]="beforeMenu"
                    [disabled]="">
                <i class="material-icons">view_column</i>
            </button>
            <mat-menu #beforeMenu="matMenu" xPosition="before">
                <mat-selection-list
                    #colSelector
                    style="max-height: 400px;"
                    (selectionChange)=onSelectionChangeColSelector($event)>
                    <ng-container *ngFor="let coldef of columnDefsInput">
                        <ng-container *ngIf="!coldef.hideInColSelector">
                            <mat-list-option
                                    [value]="coldef.field"
                                    [selected]="!coldef.hide"
                                    (click)="onClickColOption($event)">
                                {{coldef.headerName | translate}}
                            </mat-list-option>
                        </ng-container>
                    </ng-container>
                </mat-selection-list>
            </mat-menu>
        </div>
    </div>
</div>

<div class="">
    <ag-grid-angular
        #agGrid
        id="serviceGrid"
        class="ag-theme-material tc-ag-grid tc-ag-grid--full-width tc-ag-grid--auto-height tc-border-left-0 tc-border-right-0"
        [ngClass]="operationPanelConfig.productViewOpen ? 'tc-ag-grid--height-m' : 'tc-ag-grid--height-l'"
        [columnDefs]="columnDefsInput"
        [colResizeDefault]="colResizeDefault"
        [enableColResize]="true"
        [suppressRowTransform]=true
        [overlayLoadingTemplate]="overlayLoadingTemplate"
        [defaultColDef]="defaultColDef"
        [gridOptions]="gridOptions"
        [frameworkComponents]="frameworkComponents"
        [domLayout]="domLayout"
        [suppressRowClickSelection]="true"
        [overlayNoRowsTemplate]="overlayNoRowsTemplate"
        (gridSizeChanged)="onGridSizeChange($event)"
        (selectionChanged)="onSelectionChange($event)"
        (gridReady)="onGridReady($event)">
    </ag-grid-angular>
</div>

<div *ngIf="showRowLoading" class="tc-ag-grid-empty">
    <img src="../../../../../assets/img/loading-tab.gif" class="tc-mb-2" alt="Loading">
    <span class="tc-ag-grid-empty__text">Loading...</span>
</div>
