<div class="container-fluid tc-item-wrapper tcpi-card">
    <div class="tc-item-wrapper__header tc-item-header-highlighted" *ngIf="showTitle">
        <div class="tc-item-header-highlighted__icon">
            <i class="material-icons material-icons">insert_drive_file</i>
        </div>
        <div class="tc-item-header-highlighted__primary">{{"Documents" | translate}}</div>
    </div>
    <div class="tc-item-wrapper__body" *ngIf="documents.length===0">
        <div class="row">
            <div class="col-md-12">
                <div class="tc-flex-box">
                    <div class="tc-flex-item icon-cage input-icon">
                        <div class="card-icon--small icn-color-primary">
                            <i class="material-icons material-icons">insert_drive_file</i>
                        </div>
                    </div>
                    <div class="tc-flex-item input-cage">
                        <div class="container-fluid">
                            <span class="txt-light">
                            {{'No Documents' | translate}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


        <div *ngFor="let doc of documents; let i = index">
            <div class="tc-item-wrapper__body">

                <div class="row">
                    <div class="col-md-12 col-lg-12">
                        <div class="tc-flex-box">
                            <div class="tc-flex-item icon-cage input-icon">
                                <div class="card-icon--small icn-color-primary">
                                </div>
                            </div>

                            <div class="tc-flex-item input-cage">
                                <div class="col-xs-12">
                                    <div class="tc-content--wrap tc-input-view">
                                        <div class="tc-caption-txt txt-light">
                                            {{"Document Type" | translate}}
                                        </div>
                                        <div id="DOC_CARD_DOC_TYPE"
                                             class="tc-body1--txt">{{doc?.documentCode ?
                                            doc?.documentCode : EMPTY_CHAR}}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="tc-flex-item input-cage">
                                <div class="col-xs-12">
                                    <div class="tc-content--wrap tc-input-view">
                                        <div class="tc-caption-txt txt-light">
                                            {{"Template" | translate}}
                                        </div>
                                        <div id="DOC_CARD_TEMPLATE"
                                             class="tc-body1--txt">{{doc?.template ?
                                            doc?.template : EMPTY_CHAR}}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="tc-flex-item input-cage">
                                <div class="col-xs-12">
                                    <div class="tc-content--wrap tc-input-view">
                                        <div class="tc-caption-txt txt-light">
                                            {{"Format" | translate}}
                                        </div>
                                        <div id="DOC_CARD_FORMAT"
                                             class="tc-body1--txt">{{doc?.format ?
                                            doc?.format : EMPTY_CHAR}}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="tc-flex-item input-cage">
                                <div class="col-xs-12">
                                    <div class="tc-content--wrap tc-input-view">
                                        <div class="tc-caption-txt txt-light">
                                            {{"Dispatch Method" | translate}}
                                        </div>
                                        <div id="DOC_CARD_DISPATCH_METHOD"
                                             class="tc-body1--txt">
                                            <span *ngIf="doc?.dispatchMethodName">
                                                {{doc?.dispatchMethodName ? doc.dispatchMethodName : EMPTY_CHAR}}
                                            </span>
                                            <span *ngIf="!doc?.dispatchMethodName">
                                                {{doc?.dispatchMethod ? doc.dispatchMethod : EMPTY_CHAR}}
                                            </span>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12 col-lg-12">
                        <div class="tc-flex-box">
                            <div class="tc-flex-item icon-cage input-icon">
                                <div class="card-icon--small icn-color-primary">
                                </div>
                            </div>

                            <div class="tc-flex-item input-cage">
                                <div class="col-xs-12">
                                    <div class="tc-content--wrap tc-input-view">
                                        <div class="tc-caption-txt txt-light">
                                            {{"Frequency" | translate}}
                                        </div>
                                        <div id="DOC_CARD_FREQUENCY"
                                             class="tc-body1--txt">{{doc?.frequencyName ?
                                            doc?.frequencyName : EMPTY_CHAR}}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="tc-flex-item input-cage">
                                <div class="col-xs-12">
                                    <div class="tc-content--wrap tc-input-view">
                                        <div class="tc-caption-txt txt-light">
                                            {{"Frequency Parameter 1" | translate}}
                                        </div>
                                        <div id="DOC_CARD_FREQ_PARAM_1"
                                             class="tc-body1--txt">{{doc?.freqParam1 && doc?.freqParam1 > 0 ?
                                            doc?.freqParam1 : EMPTY_CHAR}}{{(doc?.frequency == "BDP" && doc?.freqParam1 > 1) ?
                                            ' days' : ''}}{{(doc?.frequency == "BDP" && doc?.freqParam1 == 1) ?
                                            ' day' : ''}}
                                        </div>
                                    </div>
                                </div>
                            </div>                   

                            <div class="tc-flex-item input-cage">
                                <div class="col-xs-12">
                                    <div class="tc-content--wrap tc-input-view">
                                        <div class="tc-caption-txt txt-light">
                                            {{"Frequency Parameter 2" | translate}}
                                        </div>
                                        <div id="DOC_CARD_FREQ_PARAM_2"
                                             class="tc-body1--txt">{{(doc?.freqParam2 && doc?.freqParam2 > 0) ?
                                            doc?.freqParam2 : EMPTY_CHAR}}{{(doc?.frequency == "BDP" && doc?.freqParam2 > 1) ?
                                            ' days' : ''}}{{(doc?.frequency == "BDP" && doc?.freqParam2 == 1) ?
                                            ' day' : ''}}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="tc-flex-item input-cage">
                                <div class="col-xs-12">
                                    <div class="tc-content--wrap tc-input-view">
                                        <div class="tc-caption-txt txt-light">
                                            {{"Contact Category" | translate}}
                                        </div>
                                        <div id="DOC_CARD_CONTACT_CATEGORY"
                                             class="tc-body1--txt">{{doc?.contactCategory ?
                                            doc?.contactCategory : EMPTY_CHAR}}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>


            <mat-divider *ngIf="documents.length > 1 && documents.length != (i + 1)" class="tc-my-2"></mat-divider>  
        </div>

</div>
