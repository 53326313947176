import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaginatorModule } from '@tc/paginator';
import { VehicleSearchCriteriaModule } from '../../vehicle-search-criteria/vehicle-search-criteria.module';
import { VehicleAssignPanelComponent } from './vehicle-assign-panel.component';
import { ResultHeaderModule } from '../../../widgets/shared/result-header/result-header.module';
import { SorterService } from '@tc-core/service/sorters/sorter.service';
import { AdvVehicleAssignSearchResultModule } from '../../../widgets/shared/adv-vehicle-assign-search-result/adv-vehicle-assign-search-result.module';

@NgModule({
    imports: [
        CommonModule,
        VehicleSearchCriteriaModule,
        ResultHeaderModule,
        AdvVehicleAssignSearchResultModule,
        PaginatorModule
    ],
    providers: [SorterService],
    exports: [VehicleAssignPanelComponent],
    declarations: [VehicleAssignPanelComponent]
})
export class VehicleAssignPanelModule {
}
