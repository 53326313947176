import { Component, EventEmitter, Injector, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/typings/esm5/checkbox';
import { CurrencyFormat } from '@tc-core/model/it/codegen/ui/framework/currency-format';
import { NavItem } from '@tc-core/model/it/codegen/ui/framework/nav-item';
import { SummaryCardData } from '@tc-core/model/it/codegen/ui/framework/summary-card-data';

/**
 *
 */
@Component({
  selector: 'tc-card-strip',
  templateUrl: 'card-strip.component.html'
})
export class CardStripComponent implements OnInit, OnChanges {

  @Input() type: 'icon'|'checkbox'|'radio' = 'icon';
  @Input() kebabMode: 'FULL'|'COMPACT' = 'COMPACT';
  @Input() kebabActions: NavItem[];
  @Input() grid: 'default'|'x'|'y' = 'default';
  @Input() cardData: SummaryCardData;
  @Input() currencyFormat: CurrencyFormat;
  @Input() dateFormat: string;
  @Input() authConfigComponent = 'tc-card-strip';
  @Input() checked = false;

  @Output() select: EventEmitter<{checked: boolean, data: any}> = new EventEmitter();
  @Output() cardClick: EventEmitter<any> = new EventEmitter();
  @Output() actionClick: EventEmitter<{action: NavItem, card: SummaryCardData}> = new EventEmitter();

  public gridPattern: string[] = [];

  constructor(private injector: Injector) {}

  public ngOnInit() {

    this.gridPattern = this.generateGridPattern();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.grid && changes.grid.currentValue) {
      this.gridPattern = this.generateGridPattern();
    }
  }

  /**
   * handle checkbox select event
   * @param event
   */
  public onCheckboxSelect(event: MatCheckboxChange) {
    this.select.emit({
      checked: event.checked,
      data: this.cardData.data.value
    });
  }

  /**
   * handle click event on the card
   */
  public onCardClick() {
    this.cardClick.emit(this.cardData.data);
  }

  /**
   * Handle kebab action selection event
   * @param kebabItem
   */
  public onKebabSelect(kebabItem: NavItem) {
    this.actionClick.emit({
      action: kebabItem,
      card: this.cardData
    });
  }

  /**
   * todo simplify this method
   */
  private generateGridPattern() {
    switch (this.grid) {
      case 'default':
        return [
          'col-xs-12 col-sm-6 col-md-4 col-lg-4',
          'col-xs-12 col-sm-6 col-md-3 col-lg-3',
          'col-xs-12 col-sm-6 col-md-3 col-lg-3',
          'col-xs-12 col-sm-6 col-md-2 col-lg-2'
        ];
      case 'x':
        return [
          'col-xs-12 col-sm-6 col-md-4 col-lg-4',
          'col-xs-12 col-sm-6 col-md-6 col-lg-6',
          'hidden-xs hidden-sm hidden-md hidden-lg',
          'col-xs-12 col-sm-6 col-md-2 col-lg-2'
        ];
      case 'y':
        return [
          'col-xs-12 col-sm-6 col-md-4 col-lg-4',
          'col-xs-12 col-sm-6 col-md-8 col-lg-8',
          'hidden-xs hidden-sm hidden-md hidden-lg',
          'hidden-xs hidden-sm hidden-md hidden-lg'
        ];
    }
  }

}
