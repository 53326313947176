import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { CurrencyFormat } from '@tc-core/model/it/codegen/ui/framework/currency-format';
import {TC} from '@tc-core/util/constants';
import {FormBuilder, FormGroup} from '@angular/forms';
import { ConfigLoader } from '@tc-core/util/framework/config-loader.service';
import { TCO } from '../../../constants';
import {ServiceItemChanges} from '../../../models/helper/service-item-changes';
import {ServiceItem} from "../../../models/reservation/service-item";

@Component({
    selector: 'tc-supplier-assign-detail-view',
    templateUrl: './supplier-assign-detail-view.component.html'
})
export class SupplierAssignDetailViewComponent implements OnInit {

    CURRENCY_TYPE = TCO.AppData.DEF_CURRENCY_TYPE;
    EMPTY_CHAR = TC.FORMAT.EMPTY_CHAR;

    @Input() summaryCardData: any;
    @Input() bookingData: any;
    @Input() bookingDataArray: any[];
    @Input() assignedSupplier: ServiceItemChanges;
    @Input() footerDataMap: Map<number, object>;
    @Input() isBulkAssignment: boolean = false;
    @Input() isGenResourceAssignment: boolean = false;
    @Input() currencyFormat: CurrencyFormat;

    @Output() headerClick: EventEmitter<any> = new EventEmitter();
    @Output() clickOutSide: EventEmitter<any> = new EventEmitter();

    supplierCostChangeForm: FormGroup;
    totalCost = 0;
    passengerCount = 1;
    vehicleCapacity = 1;
    isUnitFare = false;
    adultCount = 0;
    childCount=0;
    infantCount=0;

    constructor(
        private fb: FormBuilder,
        private configLoader: ConfigLoader
    ) {
    }

    ngOnInit() {
        this.createForm();
        this.getPassengerCounts();
        this.currencyFormat = this.getCurrencyFormat();

        this.vehicleCapacity = this.summaryCardData.data.value.capacity ? this.summaryCardData.data.value.capacity : 1;
        this.assignedSupplier.commonSupplier = this.summaryCardData.data.value;
        this.summaryCardData.data.value.estimatedUnitCost = this.summaryCardData.data.value.cost;
    }
    private getCurrencyFormat(): CurrencyFormat {
        let currencyFormat;
        let currencySymbolPattern = this.configLoader.configurations.get(TC.CONF.CONF_SYSTEM).defaults.default_currency_symbol_pattern;
        currencyFormat = new CurrencyFormat(TCO.AppData.DEF_CURRENCY_TYPE, currencySymbolPattern);
        return currencyFormat;
    }
    onAdultFareChange(data: any){
        if (JSON.stringify(data) !== JSON.stringify({})) {
            this.summaryCardData.commonSupplier.adultFare = parseFloat(data);
            this.assignedSupplier.commonSupplier.adultFare = data * this.adultCount;
            this.assignedSupplier.commonSupplier.totalCost = this.assignedSupplier.commonSupplier.adultFare + this.assignedSupplier.commonSupplier.childFare + this.assignedSupplier.commonSupplier.infantFare
        }
    }
    onChildFareChange(data: any){
        if (JSON.stringify(data) !== JSON.stringify({})) {
            this.summaryCardData.commonSupplier.childFare = parseFloat(data);
            this.assignedSupplier.commonSupplier.childFare = data * this.childCount;
            this.assignedSupplier.commonSupplier.totalCost = this.assignedSupplier.commonSupplier.adultFare + this.assignedSupplier.commonSupplier.childFare + this.assignedSupplier.commonSupplier.infantFare
        }
    }
    onInfantFareChange(data: any){
        if (JSON.stringify(data) !== JSON.stringify({})) {
            this.summaryCardData.commonSupplier.infantFare = parseFloat(data);
            this.assignedSupplier.commonSupplier.infantFare = data * this.infantCount;
            this.assignedSupplier.commonSupplier.totalCost = this.assignedSupplier.commonSupplier.adultFare + this.assignedSupplier.commonSupplier.childFare + this.assignedSupplier.commonSupplier.infantFare
        }
    }
    onUnitFareChange(data: any){
        if (JSON.stringify(data) !== JSON.stringify({})) {
            this.summaryCardData.commonSupplier.unitFare = parseFloat(data);
            this.assignedSupplier.commonSupplier.unitFare = data/this.assignedSupplier.commonSupplier.capacity * this.passengerCount;
            // this.assignedSupplier.commonSupplier.totalCost = this.assignedSupplier.commonSupplier.unitFare;
        }
    }

    createForm() {
        this.supplierCostChangeForm = this.fb.group({
            unit: [this.summaryCardData.data.value ? this.summaryCardData.data.value.unitFare : ''],
            adult: [this.summaryCardData.data.value ? this.summaryCardData.data.value.adultFare : ''],
            child: [this.summaryCardData.data.value ? this.summaryCardData.data.value.childFare : ''],
            infant: [this.summaryCardData.data.value ? this.summaryCardData.data.value.infantFare : '']
        });
    }

    private getPassengerCounts() {
        this.passengerCount = 0;
        this.adultCount = 0;
        this.childCount=0;
        this.infantCount=0;
        if (this.isBulkAssignment && this.bookingDataArray) {
            this.bookingDataArray.forEach(
                value => {
                    this.passengerCount += value['rawData'].adult + value['rawData'].child + value['rawData'].infant;
                    this.adultCount += value['rawData'].adult;
                    this.childCount += value['rawData'].child;
                    this.infantCount += value['rawData'].infant;
                }
            );
        } else if (this.bookingData) {
            if(this.isGenResourceAssignment){
                let serviceItems:Array<ServiceItem> = this.bookingData.serviceItems;
                let adults:number=0
                let children:number=0
                let infants:number=0
                serviceItems.forEach(item=>{
                    // if(item.checked){
                        adults = adults + item.adult;
                        children = children + item.child;
                        infants = infants + item.infant;
                    // }
                });
                this.passengerCount = adults + children + infants;
                this.adultCount = adults;
                this.childCount = children;
                this.infantCount = infants;
            }else{
                this.passengerCount = this.bookingData.adult + this.bookingData.child + this.bookingData.infant;
                this.adultCount = this.bookingData.adult;
                this.childCount = this.bookingData.child;
                this.infantCount = this.bookingData.infant;
            }

        }
    }

}
