import { Injectable } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NavigationConfig } from '@tc-core/model/it/codegen/ui/navigation-config';
import { Routing } from '@tc-core/model/it/codegen/ui/framework/routing';
import { ConfigLoader } from '@tc-core/util/framework/config-loader.service';
import { DMCQueryParamsService } from './dmc-query-params.service';
import { TC } from '@tc-core/util/constants';

/**
 * Created by charitha on 8/11/2017.
 */
@Injectable()
export class DMCRouteManager {

  private currentRoute: Routing;
  private navigationHistory: string[];
  private useAbsoluteUrl: boolean = false;

  constructor(private configLoader: ConfigLoader,
              private queryParamService: DMCQueryParamsService,
              private activatedRoute: ActivatedRoute,
              private router: Router) {

    this.useAbsoluteUrl = this.configLoader.configurations.get('SYSTEM_CONFIG').defaults.useAbsoluteUrl;

  }

  public next() {
    /*if (!this.currentRoute) {
     this.currentRoute = this.findCurrentNavigationConfig();
     }*/
    this.currentRoute = this.findCurrentNavigationConfig(); // build router config for each routing
    this.navigate(this.configLoader.conf([TC.CONF.CONF_ROUTING])[this.currentRoute['nextAnchor']]); // todo there is no next anchor
  }

  public goto(key: string, noHistory?: boolean, navigateByUrl?: boolean) {
    this.navigate(this.configLoader.conf([TC.CONF.CONF_ROUTING])[key], noHistory);
  }

  /*public gotoNavItem(navItem: CalendarCheckBox, noHistory?: boolean) {
    const navigationKey = navItem.action.substr(1);
    const routingKey = this.configLoader.conf([TC.CONF.CONF_ROUTING])[navigationKey];
    this.navigateToNavItem(routingKey, navItem, noHistory);
  }*/

  private navigate(conf: NavigationConfig, noHistory?: boolean) {
    // Read existing url params
    let urlParams: Params = {};
    const params = this.activatedRoute.snapshot.queryParams;
    for (let key in params) {
      if (params.hasOwnProperty(key)) {
        urlParams[key] = params[key];
      }
    }

    if (conf.newWindow) {
      window.open(conf.url + this.queryParamService.asString(urlParams), '_blank', 'resizable');
    } else if (conf.newTab) {
      window.open(conf.url + this.queryParamService.asString(urlParams));
    } else if (this.useAbsoluteUrl) {
      window.location.href = conf.absUrlPrefix + conf.url + this.queryParamService.asString(urlParams);
    } else {
      this.router.navigate([conf.primary, {outlets: conf.aux}], {
        queryParams: urlParams,
        replaceUrl: noHistory ? noHistory : false
      });

      this.currentRoute = conf;
    }
  }

  /*private navigateToNavItem(conf: NavigationConfig, navItem: CalendarCheckBox, noHistory?: boolean) {
    // Read existing url params
    let urlParams: Params = {};
    const params = this.activatedRoute.snapshot.queryParams;
    for (let key in params) {
      if (params.hasOwnProperty(key)) {
        urlParams[key] = params[key];
      }
    }

    switch (navItem.target) {
      case UserJourneyManager.TARGET_TAB:
        // open in a new tab
        window.open(conf.url + this.queryParamService.asString(urlParams));
        break;
      case UserJourneyManager.TARGET_WINDOW:
        // open in a new window
        window.open(conf.url + this.queryParamService.asString(urlParams), '_blank', 'resizable');
        break;
      default:
        this.router.navigate([conf.primary, {outlets: conf.aux}], {
          queryParams: urlParams,
          replaceUrl: noHistory ? noHistory : false
        });
        this.currentRoute = conf;
    }
  }*/

  /*public openUrl(navItem: CalendarCheckBox) {
    const url = navItem.action.substr(1);
    switch (navItem.target) {
      case UserJourneyManager.TARGET_TAB:
        // open in a new tab
        window.open(url);
        break;
      case UserJourneyManager.TARGET_WINDOW:
        // open in a new window
        window.open(url, '_blank', 'resizable');
        break;
      default:
        window.location.href = url;
    }
  }*/

  /*public openNewJourney(journey: string, navConfig: Routing, navItem: CalendarCheckBox) {
    // replace journey param and page param
    const urlParams: Params = {};
    const params = this.activatedRoute.snapshot.queryParams;
    params['journey'] = journey.toLowerCase();
    params['page'] = navConfig.id.toLowerCase();

    switch (navItem.target) {
      case UserJourneyManager.TARGET_TAB:
        // open in a new tab
        window.open(navConfig.url + this.queryParamService.asString(urlParams));
        break;
      case UserJourneyManager.TARGET_WINDOW:
        // open in a new window
        window.open(navConfig.url + this.queryParamService.asString(urlParams), '_blank', 'resizable');
        break;
      default:
        this.router.navigate([navConfig.primary, {outlets: navConfig.aux}], {
          queryParams: urlParams
        });
        this.currentRoute = navConfig;
    }
  }*/

  private findCurrentNavigationConfig(): NavigationConfig {
    // get key from query param
    const currentPageKey = this.activatedRoute.snapshot.queryParams['page'].toUpperCase();
    // load navigation config
    return this.configLoader.conf([TC.CONF.CONF_ROUTING, currentPageKey]);
  }
}
