<div class="focus-view__content-wrapper">
  <div class="container-fluid">
    <div class="assign-panel-search">
      <tc-resource-allocation-search-criteria
      [inputSearchCriteria]="resourceSearchCriteria"
      [isTriggerSearch]="true"
      [isSearchBarStyleNotAvailable]="true">
      </tc-resource-allocation-search-criteria>
    </div>
  </div>


  <div class="container-fluid">
    <div class="container-fluid">
      <div class="item-header">
        <div class="item-header__content">{{heading | translate}}</div>
        <div class="tc-icon-block action-icon">
          <button  mat-icon-button color="primary" class="tc-icon-block__icon tc-icon-block__icon--active" matTooltip="{{'Add time range' | translate}}"
                   (click)="onClickAddTimeRange()">
            <mat-icon>schedule</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid">
    <tc-adv-resource-allocation-search-results
            [resultsList]="contentCardDataList"
            [itemList]="itemList"
            [pager]="pager"
            [paxCount]="paxCount"
            (resourceSelect)="onResourceSelect($event)"
            (resourceCalendarClick)="onResourceCalendarClick($event)"
            (targetClick)="onTargetClick($event)"
            (eventClick)="onEventClick($event)"
    >
    </tc-adv-resource-allocation-search-results>
  </div>
  <tc-paginator
          *ngIf="contentCardDataList.length > defaultPageResultCount"
          [length]="contentCardDataList.length"
          [index]="pager.currPage"
          [size]="pager.itemsPerPage"
          [hasMore]="hasMore"
          (page)="onPage($event)"
          (more)="onMore()">
  </tc-paginator>

</div>
