import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChipInputModule } from '@tc/chip-input';
import { VehicleSearchCriteriaComponent } from './vehicle-search-criteria.component';

@NgModule({
    imports: [
        CommonModule,
        ChipInputModule
    ],
    declarations: [VehicleSearchCriteriaComponent],
    exports: [VehicleSearchCriteriaComponent],
    entryComponents: [VehicleSearchCriteriaComponent]
})
export class VehicleSearchCriteriaModule {}
