<form [formGroup]="trsBookingItemForm">
    <div class="container-fluid direct-client-contact-details-edit tc-card-wrapper">
        <div class="row" style="border-bottom: 1px solid #eee;">
            <div class="col-md-12 col-lg-12">
                <div class="tc-flex-box">
                    <div class="tc-flex-item tc-item-circle">
                        <div class="icon-box">
                            <i class="material-icons" aria-hidden="true">format_list_bulleted</i>
                        </div>
                    </div>
                    <div class="tc-flex-item tc-right-cage">
                        <div class="row">
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                <div class="tc-select-edit dcpiec-select-title">
                                    <mat-form-field>
                                        <input
                                                matInput
                                                formControlName="itemName"
                                                [placeholder]="'Item Name'|translate"
                                                required>
                                        <mat-error
                                                class="mat-error"
                                                role="alert">{{getErrorMessage('itemName')|translate}}</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                <div class="tc-input-edit dcpiec-input-format">
                                    <mat-form-field>
                                        <input
                                                matInput
                                                formControlName="contractReference"
                                                [placeholder]="'Contract Reference'|translate"
                                                required>
                                        <mat-error
                                                class="mat-error"
                                                role="alert">{{getErrorMessage('contractReference')|translate}}</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                <div class="tc-input-edit dcpiec-input-dispatchMethod">
                                    <mat-form-field>
                                        <input
                                                matInput
                                                formControlName="plCode"
                                                [placeholder]="'PL Code'|translate"
                                                required>
                                        <mat-error
                                                class="mat-error"
                                                role="alert">{{getErrorMessage('plCode')|translate}}</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                <div class="tc-input-edit dcpiec-input-dispatchMethod">
                                    <!--                                    <mat-form-field>-->
                                    <!--                                        <input-->
                                    <!--                                                matInput-->
                                    <!--                                                formControlName="defaultSupplier"-->
                                    <!--                                                [placeholder]="'Default Supplier'|translate"-->
                                    <!--                                                required>-->
                                    <!--                                        <mat-error-->
                                    <!--                                                class="mat-error"-->
                                    <!--                                                role="alert">{{getErrorMessage('defaultSupplier')|translate}}</mat-error>-->
                                    <!--                                    </mat-form-field>-->

                                    <mat-form-field>
                                        <input
                                                matInput
                                                #trsModeInput
                                                formControlName="defaultSupplier"
                                                [matAutocomplete]="supAutoComplete"
                                                (focus)="onSupplierFocus($event)"
                                                (focusout)="onSupplierFocusOut($event)"
                                                [placeholder]="'Default Supplier'|translate">
                                        <mat-error
                                                class="mat-error"
                                                role="alert">{{getErrorMessage('defaultSupplier')|translate}}</mat-error>
                                    </mat-form-field>
                                    <mat-autocomplete
                                        #supAutoComplete="matAutocomplete"
                                        [displayWith]="displaySupplierFn">
                                        <mat-option
                                                *ngFor="let sup of suppliersFiltered"
                                                [value]="sup.name"
                                                (onSelectionChange)="onSelectSupplier(sup)">
                                            {{ sup?.name }}
                                        </mat-option>
                                    </mat-autocomplete>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                <div class="tc-input-edit dcpiec-input-frequency">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                <div class="tc-input-edit dcpiec-input-frequency">
                                    <mat-form-field>
                                        <input
                                                type="number"
                                                matInput
                                                formControlName="occAdultCount"
                                                [placeholder]="'Adult Count'|translate"
                                                required
                                        >
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                <div class="tc-input-edit dcpiec-input-frequency">
                                    <mat-form-field>
                                        <input
                                                type="number"
                                                matInput
                                                formControlName="occTeenCount"
                                                [placeholder]="'Teen Count'|translate"
                                        >
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                <div class="tc-input-edit dcpiec-input-frequency">
                                    <mat-form-field>
                                        <input
                                                type="number"
                                                matInput
                                                formControlName="occChildCount"
                                                [placeholder]="'Child Count'|translate"
                                        >
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                <div class="tc-input-edit dcpiec-input-frequency">
                                    <mat-form-field>
                                        <input
                                                type="number"
                                                matInput
                                                formControlName="occInfantCount"
                                                [placeholder]="'Infant Count'|translate"

                                        >
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                <div class="tc-select-edit dcpiec-select-title">
                                    <mat-form-field>
                                        <input
                                                matInput [ngxMatDatetimePicker]="pickerStart"
                                                [placeholder]="'Start Date'|translate"
                                                formControlName="startDate"
                                                [min]="today"
                                                (dateChange)="onStartDateChange()"
                                                required>
                                        <mat-datepicker-toggle matSuffix [for]="pickerStart">
                                        </mat-datepicker-toggle>
                                        <ngx-mat-datetime-picker
                                            #pickerStart
                                            [showSpinners]="true"
                                            [showSeconds]="false"
                                            [stepHour]="1"
                                            [stepMinute]="1"
                                            [stepSecond]="1"
                                            [touchUi]="false"
                                            [color]="dateTimePickerColor">
                                        </ngx-mat-datetime-picker>
                                        <mat-error
                                                class="mat-error"
                                                role="alert">{{getErrorMessage('startDate')|translate}}</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                <div class="tc-input-edit dcpiec-input-format">
                                    <mat-form-field>
                                        <input
                                                matInput [ngxMatDatetimePicker]="pickerEnd"
                                                [placeholder]="'End Date'|translate"
                                                formControlName="endDate"
                                                [min]="trsBookingItem.departureDateTime ? trsBookingItem.departureDateTime : today"
                                                (dateChange)="onEndDateChange()"
                                                required>
                                        <mat-datepicker-toggle matSuffix [for]="pickerEnd">
                                        </mat-datepicker-toggle>
                                        <ngx-mat-datetime-picker
                                            #pickerEnd
                                            [showSpinners]="true"
                                            [showSeconds]="false"
                                            [stepHour]="1"
                                            [stepMinute]="1"
                                            [stepSecond]="1"
                                            [touchUi]="false"
                                            [color]="dateTimePickerColor">
                                        </ngx-mat-datetime-picker>
                                        <mat-error
                                                class="mat-error"
                                                role="alert">{{getErrorMessage('endDate')|translate}}</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                            </div>
                            <div
                                    class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile"
                                    *ngIf="sameDateTypeItems && sameDateTypeItems.length > 0">
                                <div class="tc-input-edit dcpiec-input-lname">
                                    <mat-checkbox
                                            formControlName="passengerValidated"
                                            (ngModelChange)="onChangeCheckBox($event)">
                                        {{'Validate Passengers' |translate}}
                                    </mat-checkbox>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--        transfer item specific fields-->
        <div class="row" style="border-bottom: 2px solid #eee;">
            <div class="col-md-12 col-lg-12">
                <div class="tc-flex-box">
                    <div class="tc-flex-item tc-item-circle">
                        <div class="icon-box">
                            <i class="material-icons" aria-hidden="true">directions_car</i>
                        </div>
                    </div>
                    <div class="tc-flex-item tc-right-cage">
                        <div class="row">
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                <div class="tc-select-edit dcpiec-select-title">
                                    <mat-form-field>
                                        <input
                                            #trsModeInput
                                            matInput
                                            formControlName="transferMode"
                                            [matAutocomplete]="trsModeAutoComplete"
                                            (focus)="onTrsModeFocus($event)"
                                            (focusout)="onTrsModeFocusOut($event)"
                                            [placeholder]="'Transfer Mode'|translate">
                                        <mat-error
                                                class="mat-error"
                                                role="alert">{{getErrorMessage('transferMode')|translate}}</mat-error>
                                    </mat-form-field>
                                    <mat-autocomplete
                                        #trsModeAutoComplete="matAutocomplete"
                                        [displayWith]="displayTrsModeFn">
                                        <mat-option
                                                *ngFor="let trsMode of trsModeListFiltered"
                                                [value]="trsMode.code"
                                                (onSelectionChange)="onSelectTrsMode(trsMode)">
                                            {{ trsMode?.code + ' | ' + trsMode?.name }}
                                        </mat-option>
                                    </mat-autocomplete>

                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                <div class="tc-input-edit dcpiec-input-format">
                                    <mat-form-field>
                                        <input
                                                matInput
                                                #trsModeInput
                                                formControlName="pickupLocation"
                                                [matAutocomplete]="pickupAutoComplete"
                                                (focus)="onPickupLocationFocus($event)"
                                                (focusout)="onPickupLocationFocusOut($event)"
                                                [placeholder]="'Pickup Location'|translate">
                                        <mat-error
                                                class="mat-error"
                                                role="alert">{{getErrorMessage('pickupLocation')|translate}}</mat-error>
                                    </mat-form-field>
                                    <mat-autocomplete
                                        #pickupAutoComplete="matAutocomplete"
                                        [displayWith]="displayLocationFn">
                                        <mat-option
                                                *ngFor="let loc of pickupLocationsFiltered"
                                                [value]="loc.name"
                                                (onSelectionChange)="onSelectPickupLocation(loc)">
                                            {{ loc?.code + ' | ' + loc?.name }}
                                        </mat-option>
                                    </mat-autocomplete>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                <div class="tc-input-edit dcpiec-input-dispatchMethod">
                                    <mat-form-field>
                                        <input
                                                matInput
                                                #trsModeInput
                                                formControlName="dropOffLocation"
                                                [matAutocomplete]="dropOffAutoComplete"
                                                (focus)="onDropOffLocationFocus($event)"
                                                (focusout)="onDropOffLocationFocusOut($event)"
                                                [placeholder]="'Drop Off Location'|translate">
                                        <mat-error
                                                class="mat-error"
                                                role="alert">{{getErrorMessage('dropOffLocation')|translate}}</mat-error>
                                    </mat-form-field>
                                    <mat-autocomplete
                                        #dropOffAutoComplete="matAutocomplete"
                                        [displayWith]="displayLocationFn">
                                        <mat-option
                                                *ngFor="let loc of dropOffLocationsFiltered"
                                                [value]="loc.name"
                                                (onSelectionChange)="onSelectDropOffLocation(loc)">
                                            {{ loc?.code + ' | ' + loc?.name }}
                                        </mat-option>
                                    </mat-autocomplete>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                <div class="tc-input-edit dcpiec-input-frequency">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--        <tc-booking-item-notes-->
        <!--                [bookingItem]="bookingItem"-->
        <!--                [noteTypes]="noteTypes"-->
        <!--                [readonly]="readonly"-->
        <!--                (changeValidity)="onNotesChange($event)">-->
        <!--        </tc-booking-item-notes>-->

        <tc-item-notes
                [readonly]="readonly"
                [noteList]="bookingItem.itemNotes"
                [heading]="'Notes'"
                (changeValidity)="onNotesChange($event)"
                class="bdv-booking-notes-summary-wrapper">
        </tc-item-notes>

    </div>
</form>
