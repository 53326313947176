<!--recommended events-->
<div class="tc-strip-timeline__event tc-strip-timeline__event--success" (click)="onEventClick($event)" *ngIf="eventData?.assignment?.recommended && !eventData?.assignment?.selected">
    <i class="material-icons tc-strip-timeline__event-select-icon">check_circle</i>
  <div class="tc-strip-timeline__event-content">
    <span class="tc-strip-timeline__event-info" *ngIf="eventData?.assignment?.remainingCount == 0 || eventData?.assignment?.remainingCount > 0">
      <i class="material-icons">seat_empty</i>
      <span class="tc-strip-timeline__event-text">{{eventData?.assignment?.remainingCount}}</span>
    </span>
    <span class="tc-strip-timeline__event-info">
      <i class="material-icons">seat</i>
      <span class="tc-strip-timeline__event-text">{{eventData?.assignment?.allocatedCount}}</span>
    </span>
    <div class="tc-strip-timeline__event-info">
      <i class="material-icons">money_bag </i>
      <span class="tc-strip-timeline__event-text tc-strip-timeline__event-text--large">{{eventData?.assignment?.jobCost | tcCurrencyPipe:currencyFormat?.currencyCode:"code":currencyFormat?.pattern}}</span>
    </div>
  </div>
  <div class="tc-strip-timeline__event-time-gap">
    <div class="tc-strip-timeline__event-time-tag tc-strip-timeline__event-time-tag--start" [matTooltip]="eventData?.assignment?.startLocationGroup"></div>
    <div class="tc-strip-timeline__event-time-tag tc-strip-timeline__event-time-tag--end" [matTooltip]="eventData?.assignment?.endLocationGroup"></div>
    <div class="tc-strip-timeline__event-time"></div>
    <!-- todo: @heshan check this. this is to show that waiting time-->
    <div [ngStyle]="{'width': eventData?eventData.waitingUnitCount*unitWidth+'px':'0px'}"></div>
  </div>
</div>

<!--selected event-->
<div *ngIf="eventData?.assignment?.recommended && eventData?.assignment?.selected" class="tc-strip-timeline__event tc-strip-timeline__event--selected" (click)="onEventClick($event)">
  <i class="material-icons tc-strip-timeline__event-select-icon">check_circle</i>
  <div class="tc-strip-timeline__event-content">
    <span class="tc-strip-timeline__event-info">
      <i class="material-icons">seat_empty</i>
      <span class="tc-strip-timeline__event-text">{{eventData?.assignment?.remainingCount}}</span>
    </span>
    <span class="tc-strip-timeline__event-info">
      <i class="material-icons">seat</i>
      <span class="tc-strip-timeline__event-text">{{eventData?.assignment?.allocatedCount}}</span>
    </span>
    <div class="tc-strip-timeline__event-info">
      <i class="material-icons">money_bag </i>
      <span class="tc-strip-timeline__event-text tc-strip-timeline__event-text--large">{{eventData?.assignment?.jobCost | tcCurrencyPipe:currencyFormat?.currencyCode:"code":currencyFormat?.pattern}}</span>
    </div>
  </div>
  <div class="tc-strip-timeline__event-time-gap">
    <div class="tc-strip-timeline__event-time-tag tc-strip-timeline__event-time-tag--start" [matTooltip]="eventData?.assignment?.startLocationGroup"></div>
    <div class="tc-strip-timeline__event-time-tag tc-strip-timeline__event-time-tag--end" [matTooltip]="eventData?.assignment?.endLocationGroup"></div>
    <div class="tc-strip-timeline__event-time"></div>
  </div>
</div>

<!--not recommended events-->
<div
        class="tc-strip-timeline__event" *ngIf="!eventData?.assignment?.recommended && !eventData?.assignment?.selected">
  <div class="tc-strip-timeline__event-time-gap">
    <div class="tc-strip-timeline__event-time-tag tc-strip-timeline__event-time-tag--start" [matTooltip]="eventData?.assignment?.startLocationGroup"></div>
    <div class="tc-strip-timeline__event-time-tag tc-strip-timeline__event-time-tag--end" [matTooltip]="eventData?.assignment?.endLocationGroup"></div>
    <div class="tc-strip-timeline__event-time"></div>
  </div>
</div>


