import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material';
import { TranslateModule } from '@ngx-translate/core';
import { ChipInputModule } from '@tc/chip-input';
import { DirectivesModule } from '@tc/directives';
import { FocusViewModule } from '@tc/focus-view';
import { SorterPipe } from '@tc/pipes/sorter.pipe';
import { SectionHeadingModule } from '@tc/section-heading';
import { SummaryStripModule } from '@tc/summary-strip';
import { ManageNotesModule } from '../../../widgets/framework/manage-notes';
import { ServiceItemFocusViewFlightInfoModule } from '../../../widgets/shared/service-item-focus-view-flight-info/service-item-focus-view-flight-info.module';
import { ServiceItemFocusViewSummaryModule } from '../../../widgets/shared/service-item-focus-view-summary/service-item-focus-view-summary.module';
import { ServiceItemNotesModule } from '../../../widgets/shared/service-item-notes/service-item-notes.module';
import { ServiceItemDetailFocusViewComponent } from './service-item-detail-focus-view.component';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule.forChild(),
        FocusViewModule,
        SectionHeadingModule,
        ServiceItemFocusViewSummaryModule,
        ServiceItemFocusViewFlightInfoModule,
        ServiceItemNotesModule,
        MatTooltipModule,
        ChipInputModule,
        DirectivesModule,
        ManageNotesModule,
        SummaryStripModule
    ],
    exports: [ServiceItemDetailFocusViewComponent],
    declarations: [ServiceItemDetailFocusViewComponent],
    providers: [SorterPipe]
})
export class ServiceItemDetailFocusViewModule {}
