import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { TooltipPosition } from '@angular/material/tooltip';
import { TranslateService } from '@ngx-translate/core';
import { TcApiError } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-api-error';
import { TcHttpError } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-http-error';
import { LEVEL } from '@tc-core/model/it/codegen/ui/framework/dialog-model';
import { ModalData } from '@tc-core/model/it/codegen/ui/framework/modal-data';
import { ConfigLoader, EventManager } from '@tc-core/util/framework';
import { UserJourneyManager } from '@tc-core/util/framework/user-journey-manager.service';
import { CommonHelper } from '@tc-core/util/helpers/common-helper.service';
import { SpinnerService } from '@tc-core/util/ui';
import { FocusViewService } from '@tc/focus-view/focus-view.service';
import { AgGridAngular } from 'ag-grid-angular';
import { ICellRendererParams, IGetRowsParams } from 'ag-grid-community';
import * as moment from 'moment';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SetupGridComp } from '../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid-comp';
import { SetupGridDataSource } from '../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid-data-source';
import { SetupGridComponent } from '../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid.component';
import { ButtonState } from '../../widgets/framework/ag-grid-custom-components/directives/button-state';
import { AutoCompleteEditorComponent } from '../../widgets/framework/ag-grid-custom-components/editors/auto-complete-editor/auto-complete-editor.component';
import { DateEditorComponent } from '../../widgets/framework/ag-grid-custom-components/editors/date-editor/date-editor.component';
import { DropdownEditorComponent } from '../../widgets/framework/ag-grid-custom-components/editors/dropdown-editor/dropdown-editor.component';
import { MultiSelectorEditorComponent } from '../../widgets/framework/ag-grid-custom-components/editors/multi-selector-editor/multi-selector-editor.component';
import { RadioButtonEditorComponent } from '../../widgets/framework/ag-grid-custom-components/editors/radio-button-editor/radio-button-editor.component';
import { ActionRendererAction } from '../../widgets/framework/ag-grid-custom-components/renderers/actions/actions.component';
import { CheckBoxRendererEditorComponent } from '../../widgets/framework/ag-grid-custom-components/renderers/check-box-selector-renderer/check-box-renderer-editor.component';
import { TemplateRendererComponent } from '../../widgets/framework/ag-grid-custom-components/renderers/template-renderer/template-renderer.component';
import { TCO } from '../../constants';
import { LedgerEntrySearchCriteria } from '../../models/criteria/ledger-entry-search-criteria';
import { NotificationSearchCriteria } from '../../models/criteria/notification-search-criteria';
import { ReservationSearchCriteria } from '../../models/criteria/reservation-search-criteria';
import { GroupBy } from '../../models/helper/group-by';
import { SortDirection } from '../../models/helper/sort-direction';
import { ActionString, NotificationData, NotificationType, TypeString } from '../../models/notification/notification-data';
import { NotificationStateChangeData } from '../../models/notification/notification-state-change-data';
import { ProductQueueSearchCriteria } from '../../models/reservation-v2/criteria/product-queue-search-criteria';
import { ResourceType } from '../../models/reservation/assignment';
import { AssignmentStatus, StatusCodes } from '../../models/reservation/assignment-status';
import { NotificationService } from '../../services/backend-consumers/notifications/notification.service';
import { GenericResourceService } from '../../services/backend-consumers/supplier-service/generic-resource.service';
import { DataKey, DataStoreService } from '../../services/util/framework/data-store.service';
import { GridColumnDefinitionProcessorService } from '../../services/util/pre-processors/grid-column-definition-processor.service';
import { ResponseUtil } from '../../services/util/response/response-util.service';
import { DMCLocalStorageService } from '../../services/util/system/dmc-local-storage.service';
import { ResAvailabilityCalendarFocusViewComponent } from '../../widgets/shared/res-availability-calendar-focus-view/res-availability-calendar-focus-view.component';

@Component({
    selector: 'tc-notification-queue-results',
    templateUrl: './notification-queue-results.component.html'
})
export class NotificationQueueResultsComponent  extends SetupGridComp implements OnInit, OnDestroy, SetupGridDataSource {

    colDefConfig = [];
    defaultColDef = {
        filter: false,
        sortable: false,
        resizable: false,
        headerValueGetter: (parameters: ICellRendererParams): string => {
            const headerIdentifier = parameters.colDef.headerName;
            if (headerIdentifier) {
                return this.translateService.instant(headerIdentifier);
            }
            return '';
        }
    };
    headerActions: ActionRendererAction[] = [];

    @ViewChild('notificationSetupGrid')
    private setupGrid: SetupGridComponent;

    searchCriteria = new NotificationSearchCriteria();

    gridActions: ActionRendererAction[] = [];

    tPagination: any;

    typeString = TypeString;
    actionString = ActionString;
    ASSIGNMENT_UPDATE = NotificationType.ASSIGNMENT_UPDATE;
    LEAVE_REQUEST = NotificationType.LEAVE_REQUEST;
    NEW_USER_REGISTRATION = NotificationType.NEW_USER_REGISTRATION;
    RESOURCE_COMMUNICATION = NotificationType.RESOURCE_COMMUNICATION;

    PENDING = AssignmentStatus.PENDING;
    DMC_CONFIRM = AssignmentStatus.DMC_CONFIRM;
    REQUEST_EXPIRED = AssignmentStatus.REQUEST_EXPIRED;
    SUPPLIER_CONFIRMED = AssignmentStatus.SUPPLIER_CONFIRMED;
    SUPPLIER_REJECTED = AssignmentStatus.SUPPLIER_REJECTED;
    BOOKING_UPDATE = AssignmentStatus.BOOKING_UPDATE;
    INACTIVE = AssignmentStatus.INACTIVE;
    INACTIVE_NOTIFY = AssignmentStatus.INACTIVE_NOTIFY;
    NO_SHOW = AssignmentStatus.NO_SHOW;
    STARTED = AssignmentStatus.STARTED;
    COMPLETED = AssignmentStatus.COMPLETED;

    statusCodes = StatusCodes;
    public toolTipPosition: TooltipPosition = 'below';
    navConfig: any[] = [];

    @ViewChild('typeCell') typeCell: TemplateRef<any>;
    @ViewChild('descriptionCell') descriptionCell: TemplateRef<any>;
    @ViewChild('generatedOnCell') generatedOnCell: TemplateRef<any>;
    @ViewChild('actionCell') actionCell: TemplateRef<any>;


    private searchCriteriaSubscription: Subscription = new Subscription();
    private newNotificationResultsSubscription: Subscription = new Subscription();
    private focusViewCloseSubject: Subscription = new Subscription();

    rowClassRules = {
        'tc-ag-grid-row--error'(params) { return !params || !params.data || !params.data.readStatus; }
    };

    constructor(
        private cd: ChangeDetectorRef,
        private columnDefinitionProcessorService: GridColumnDefinitionProcessorService,
        public em: EventManager,
        private configLoader: ConfigLoader,
        private dataStore: DataStoreService,
        private spinnerService: SpinnerService,
        private notificationService: NotificationService,
        private genericResourceService: GenericResourceService,
        private commonHelper: CommonHelper,
        private focusViewService: FocusViewService,
        private localStorageService: DMCLocalStorageService,
        private userJourneyManager: UserJourneyManager,
        private translateService: TranslateService
    ) {
        super();
    }

    ngOnInit() {
        this.colDefConfig = this.configLoader.configurations.get(TCO.CONF.CONF_NOTIFICATION_QUEUE_COL_DEF);
        this.subscribeSearchCriteria();
        this.getNewNotifications();
    }

    subscribeSearchCriteria() {
        this.searchCriteriaSubscription = this.dataStore.get(DataKey.notificationSearchCriteria).subscribe((data) => {
            if (data !== null) {
                this.searchCriteria = data;
                this.setupGrid.runForceSearch();
            }
        });
    }

    public isInvalidRow(params): boolean {
        return false;
    }

    public createNewRow(): any {
        return null;
    }

    public isUnsavedRow(row: any): boolean {
        return false;
    }

    public deleteRow(row: any) {
        return null;
    }

    public saveRow(row: any, event: any) {
        return null;
    }

    public getRows(params: IGetRowsParams): Observable<any> {
        console.log('fetch requesting for ' + params.startRow + ' to ' + params.endRow);
        console.log(params.sortModel);

        const pageSize = params.endRow - params.startRow;
        if (!this.searchCriteria) {
            this.searchCriteria = new NotificationSearchCriteria();
        }
        this.searchCriteria.start = params.startRow;
        this.searchCriteria.size = pageSize;
        if (params.sortModel && params.sortModel[0]) {
            this.searchCriteria.sortBy = params.sortModel[0].colId;
            if (params.sortModel[0].sort === SetupGridComp.GRID_SORT_ASCENDING) {
                this.searchCriteria.sortDirection = SortDirection.ASC;
            } else {
                this.searchCriteria.sortDirection = SortDirection.DESC;
            }
        }
        return this.notificationService.searchNotifications(this.searchCriteria)
                   .pipe(
                       tap(data =>
                           this.dataStore.set(DataKey.notificationSearchResultsForCriteria, data)
                       )
                   );
    }

    getNewNotifications(): any {
        this.newNotificationResultsSubscription = this.dataStore.get(DataKey.newNotifications).subscribe(
            (data: NotificationData[]) => {
                if (data) {
                    for (const notif of data) {
                        this.setupGrid.addNewRow(notif, false, 0);
                    }
                }
            }
        );
    }

    public onRawClick($event: any) {
        this.onRowClicked($event.data);
    }

    onClickAction(notification: NotificationData, subType?: string) {
        this.onRowClicked(notification);
        switch (notification.type) {
            case NotificationType.NEW_USER_REGISTRATION :
                return this.openUserQueue(notification);
            case NotificationType.LEAVE_REQUEST :
                return this.onResCalendarClick(notification);
            case NotificationType.ASSIGNMENT_UPDATE :
                if (subType && subType === 'message') {
                    return this.openMessageQueueWithJob(notification);
                } else if (subType && subType === 'service') {
                    return this.openServiceQueue(notification);
                }
                break;
            case NotificationType.RESOURCE_COMMUNICATION :
                return this.openMessageQueue(notification);
            default :
                return '';
        }
    }

    openMessageQueue(notification: NotificationData): any {
        this.localStorageService.delete('resourceId');
        this.localStorageService.delete('job');
        this.localStorageService.delete('notificationId');
        this.localStorageService.store(
            'resourceId',
            notification.sendingResourceId ? notification.sendingResourceId.toString() : ''
        );
        this.localStorageService.store(
            'notificationId',
            notification.notificationId ? notification.notificationId.toString() : ''
        );
        this.localStorageService.store('isAssignment', 'false');
        this.userJourneyManager.goForKey('BEGIN_MESSAGE_QUEUE');
    }

    openMessageQueueWithJob(notification: NotificationData): any {
        this.localStorageService.delete('resourceId');
        this.localStorageService.delete('job');
        this.localStorageService.store('resourceId', notification.resourceId ? notification.resourceId.toString() : '');
        this.localStorageService.store('isAssignment', 'true');
        this.localStorageService.store('assignmentData', notification);
        this.userJourneyManager.goForKey('BEGIN_MESSAGE_QUEUE');
    }

    openServiceQueue(notification: NotificationData): any {
        if (notification.assignmentId && notification.assignmentId > 0) {
            const criteria = new ProductQueueSearchCriteria();
            criteria.assignmentId = notification.assignmentId;
            criteria.startDate = moment().startOf('year').format('YYYY-MM-DD');
            criteria.endDate = moment().add(1, 'year').endOf('year').format('YYYY-MM-DD');
            criteria.sortDirection = SortDirection.ASC;
            this.localStorageService.store(TCO.AppData.PRODUCT_QUEUE_SEARCH_CRITERIA_COMMON, criteria);
        }
        this.userJourneyManager.goForKey('BEGIN_RESOURCE_ALLOCATION_AS_COMMON_PANEL');
    }

    onResCalendarClick(notification: NotificationData): any {
        if (notification.resourceId) {
            this.retrieveResourceById(notification);
        }

    }

    openResCalendar(resource: any): any {
        const resourceName = resource.resourceType === ResourceType.vehicle ?
            resource.vehicle.vehicleName :
            resource.genericResource.firstName;
        this.navConfig = this.configLoader
                             .configurations
                             .get(TCO.CONF.CONF_PAGE_SECTION_CONFIG)['availability_calendar_side_nav']['resource_level'];
        setTimeout(() => {
            const fabActions = [];
            const dataObject = new ModalData(
                LEVEL.SUCCESS,
                'Availability Calendar',
                null,
                null,
                'season-setup-focus-view',
                ResAvailabilityCalendarFocusViewComponent,
                {
                    'resourceSummary': resource,
                    'navConfig': this.navConfig
                },
                {'label': 'close'},
                '',
                '',
                fabActions,
                '',
                resource ?
                    resourceName + ' | Supplier: ' + (resource.supplierName) :
                    ''
            );

            this.focusViewCloseSubject = this.dataStore.get(DataKey.availabilityCalendarFocusViewClose).subscribe(c => {
                if (c) {
                }
            });
            this.focusViewService.confirm(dataObject).subscribe(res => {
                if (res) {
                    console.log(res);
                }
            });
        }, 0);
    }

    retrieveResourceById(notification: NotificationData): any {
        this.genericResourceService.retrieveResourceById(notification.resourceId)
            .subscribe(
                res => {
                    if (this.commonHelper.dataValidity(res)) {
                        this.spinnerService.hide();
                        const resource = ResponseUtil.getFirstData(res);
                        this.openResCalendar(resource);
                    } else if (res instanceof TcApiError) {
                        this.spinnerService.hide();
                    } else if (res instanceof TcHttpError) {
                        this.spinnerService.hide();
                    }
                }
            );
        return null;
    }

    openUserQueue(notification: NotificationData): any {
        this.userJourneyManager.goForKey('BEGIN_RESOURCE_ACCEPTANCE_QUEUE');
    }

    processResourceId = (params) => {
        return params && params.data && params.data.resourceId > 0 ? params.data.resourceId : '';
    };

    onRowClicked(event: any) {
        event.readStatus = true;
        this.createNotificationStateChangeObj(event);
    }

    createNotificationStateChangeObj(notification: NotificationData) {
        if (notification.receivingResourceId === 0) {
            notification.receivingResourceId = null;
        }
        if (notification.supplierId === 0) {
            notification.supplierId = null;
        }
        const notificationStateChangeObj: NotificationStateChangeData = new NotificationStateChangeData();
        notificationStateChangeObj.notificationId = notification.notificationId;
        notificationStateChangeObj.dmcUser = true;
        notificationStateChangeObj.resourceId = notification.receivingResourceId;
        notificationStateChangeObj.supplierId = notification.supplierId;

        this.changeNotificationReadState(notificationStateChangeObj);
    }

    changeNotificationReadState(obj: NotificationStateChangeData) {
        this.notificationService.changeNotificationState(obj).subscribe((result) => {
        });
    }

    public ngOnDestroy(): void {
        if (this.searchCriteriaSubscription) {
            this.searchCriteriaSubscription.unsubscribe();
        }
        if (this.newNotificationResultsSubscription) {
            this.newNotificationResultsSubscription.unsubscribe();
        }
        if (this.focusViewCloseSubject) {
            this.focusViewCloseSubject.unsubscribe()
        }
    }
}
