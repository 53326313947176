import {Component, Input, OnInit} from '@angular/core';
import {Booking} from '../../../../models/reservation-v2/booking';
import {ICellRendererParams} from 'ag-grid-community';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'tc-passengers-detail-view',
    templateUrl: './passengers-detail-view.component.html'
})
export class PassengersDetailViewComponent implements OnInit {

    @Input() booking: Booking;
    @Input() readonly = true;
    @Input() titles: any[] = [];
    @Input() passengerTypes: any[] = [];

    overlayNoRowsTemplate = `<span class="ag-overlay-no-rows-center">No Passengers</span>`;

    gridApi;
    columnDefs;
    defaultColDef = {
        headerValueGetter: (parameters: ICellRendererParams): string => {
            const headerIdentifier = parameters.colDef.headerName;
            if (headerIdentifier) {
                return this.translateService.instant(headerIdentifier);
            }
            return '';
        }
    };

    frameworkComponents = {};

    constructor(
        private translateService: TranslateService
    ) {
        this.translateService.onLangChange.subscribe(() => {
            this.gridApi.refreshHeader();
        });
    }

    ngOnInit() {
        this.setColDefs();
    }

    setColDefs() {
        this.columnDefs = [
            {
                headerName: 'Title',
                field: 'title',
                maxWidth: 100,
                resizable: true,
                editable: !this.readonly,
                cellClass: [
                    "tc-ag-grid-cell--read-only"
                ],
            },
            {
                headerName: 'First Name',
                field: 'firstName',
                flex: 1,
                resizable: true,
                editable: !this.readonly,
                cellClass: [
                    "tc-ag-grid-cell--read-only"
                ],

            },
            {
                headerName: 'Middle Name',
                field: 'middleName',
                hide: true,
                flex: 1,
                resizable: true,
                editable: !this.readonly,
                cellClass: [
                    "tc-ag-grid-cell--read-only"
                ],
            },
            {
                headerName: 'Last Name',
                editable: !this.readonly,
                flex: 1,
                resizable: true,
                field: 'lastName',
                cellClass: [
                    "tc-ag-grid-cell--read-only"
                ],
            },
            {
                headerName: 'Type',
                field: 'typeName',
                flex: 1,
                resizable: true,
                editable: !this.readonly,
                cellClass: [
                    "tc-ag-grid-cell--read-only",
                    "tc-ag-grid-cell--no-focus"
                ],
            },
            {
                headerName: 'Email',
                field: 'email',
                flex: 1,
                editable: !this.readonly
            },
            {
                headerName: 'Contact No.',
                field: 'telephone',
                flex: 1,
                editable: !this.readonly
            },
        ];
    }

    public onGridReady(params: any) {
        this.gridApi = params.api;
        this.gridApi.sizeColumnsToFit();
        this.gridApi.suppressCellSelection = true
    }
}
