/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../node_modules/ag-grid-angular/dist/agGridAngular.ngfactory";
import * as i2 from "ag-grid-angular/dist/angularFrameworkOverrides";
import * as i3 from "ag-grid-angular/dist/angularFrameworkComponentWrapper";
import * as i4 from "ag-grid-angular/dist/agGridAngular";
import * as i5 from "./booking-supplement-group.component";
import * as i6 from "@ngx-translate/core";
var styles_BookingSupplementGroupComponent = [];
var RenderType_BookingSupplementGroupComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_BookingSupplementGroupComponent, data: {} });
export { RenderType_BookingSupplementGroupComponent as RenderType_BookingSupplementGroupComponent };
export function View_BookingSupplementGroupComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 4, "ag-grid-angular", [["class", "ag-theme-material tc-ag-grid tc-ag-grid--full-width tc-ag-grid--popup-content"], ["id", "supplement-group"]], null, [[null, "gridReady"], [null, "firstDataRendered"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("gridReady" === en)) {
        var pd_0 = (_co.onGridReadyBookingSupplements($event) !== false);
        ad = (pd_0 && ad);
    } if (("firstDataRendered" === en)) {
        var pd_1 = (_co.onFirstDataRendered($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_AgGridAngular_0, i1.RenderType_AgGridAngular)), i0.ɵprd(512, null, i2.AngularFrameworkOverrides, i2.AngularFrameworkOverrides, [i0.NgZone]), i0.ɵprd(512, null, i3.AngularFrameworkComponentWrapper, i3.AngularFrameworkComponentWrapper, []), i0.ɵdid(3, 4898816, null, 1, i4.AgGridAngular, [i0.ElementRef, i0.ViewContainerRef, i2.AngularFrameworkOverrides, i3.AngularFrameworkComponentWrapper, i0.ComponentFactoryResolver], { gridOptions: [0, "gridOptions"], rowData: [1, "rowData"], columnDefs: [2, "columnDefs"], frameworkComponents: [3, "frameworkComponents"], defaultColDef: [4, "defaultColDef"] }, { gridReady: "gridReady", firstDataRendered: "firstDataRendered" }), i0.ɵqud(603979776, 1, { columns: 1 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.gridOptions; var currVal_1 = ((_co.supplementGroup == null) ? null : _co.supplementGroup.supplementNotes); var currVal_2 = _co.columnDefs; var currVal_3 = _co.frameworkComponents; var currVal_4 = _co.defaultColDef; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
export function View_BookingSupplementGroupComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-booking-supplement-group", [], null, null, null, View_BookingSupplementGroupComponent_0, RenderType_BookingSupplementGroupComponent)), i0.ɵdid(1, 114688, null, 0, i5.BookingSupplementGroupComponent, [i6.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BookingSupplementGroupComponentNgFactory = i0.ɵccf("tc-booking-supplement-group", i5.BookingSupplementGroupComponent, View_BookingSupplementGroupComponent_Host_0, { supplementGroup: "supplementGroup", columnDefs: "columnDefs" }, {}, []);
export { BookingSupplementGroupComponentNgFactory as BookingSupplementGroupComponentNgFactory };
