import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VehicleSpecificInformationComponent } from './vehicle-specific-information.component';
import {TranslateModule} from '@ngx-translate/core';
import {
    MatAutocompleteModule, MatButtonModule, MatDatepickerModule,
    MatFormFieldModule, MatIconModule,
    MatInputModule, MatNativeDateModule,
    MatOptionModule,
    MatSelectModule, MatSliderModule,
    MatSlideToggleModule
} from '@angular/material';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {PipesModule} from '@tc/pipes';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        MatInputModule,
        ReactiveFormsModule,
        MatSlideToggleModule,
        MatSelectModule,
        FormsModule,
        MatAutocompleteModule,
        MatFormFieldModule,
        PipesModule,
        MatOptionModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatButtonModule,
        MatIconModule,
        MatSliderModule
    ],
  declarations: [VehicleSpecificInformationComponent],
  exports: [VehicleSpecificInformationComponent]
})
export class VehicleSpecificInformationModule { }
