import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditTimeRangeComponent } from './edit-time-range.component';
import {NgxMaterialTimepickerModule} from "ngx-material-timepicker";
import {MatInputModule} from "@angular/material/input";
import {ReactiveFormsModule} from "@angular/forms";
import {MatButtonModule} from "@angular/material/button";
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
  declarations: [EditTimeRangeComponent],
    imports: [
        CommonModule,
        NgxMaterialTimepickerModule,
        MatInputModule,
        ReactiveFormsModule,
        MatButtonModule,
        TranslateModule.forChild()
    ],
  entryComponents:[EditTimeRangeComponent]
})
export class EditTimeRangeModule { }
