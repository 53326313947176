<form [formGroup]="supplierCostChangeForm">
    <div class="container-fluid cost-break-down">
        <div class="row" *ngIf="summaryCardData && summaryCardData.commonSupplier && !summaryCardData.commonSupplier.isUnitFare">
            <div class="cost-break-down__detail-view">
                <div class="fixed-column">
                    <!-- header -->
                    <div class="row table-header pbc-item-header item-header">
                        <div class="heading item-header__content"></div>
                    </div>

                    <!-- body -->
                    <div class="row pbc-item-header item-header">
                        <div class="heading item-header__content item-header__price">
                            <span>Adult</span>
                        </div>
                    </div>
                    <div class="row pbc-item-header item-header">
                        <div class="heading item-header__content item-header__price">
                            <span>Child</span>
                        </div>
                    </div>
                    <div class="row pbc-item-header item-header">
                        <div class="heading item-header__content item-header__price">
                            <span>Infant</span>
                        </div>
                    </div>
                    <div class="row pbc-item-header item-header">
                        <div class="heading item-header__content item-header__price">
                            <span>Grand Total</span>
                        </div>
                    </div>
                </div>
                <div class="fixed-column">

                    <!-- header -->
                    <div class="row table-price-field">
                        <div class="item-header__content">Passenger Count</div>
                    </div>

                    <!-- body -->
                    <div class="row table-price-field item-header">
                        <div class="item-header__content item-header__price">
                            <span>{{adultCount}}</span>
                        </div>
                    </div>
                    <div class="row table-price-field item-header">
                        <div class="item-header__content item-header__price">
                            <span>{{childCount}}</span>
                        </div>
                    </div>
                    <div class="row table-price-field item-header">
                        <div class="item-header__content item-header__price">
                            <span>{{infantCount}}</span>
                        </div>
                    </div>
                    <div class="row table-price-field item-header">
                        <div class="item-header__content item-header__price">
                        </div>
                    </div>
                </div>

                <div class="fixed-column">

                    <!-- header -->
                    <div class="row table-price-field">
                        <div class="item-header__content">Cost ( {{currencyFormat.currencyCode}} )</div>
                    </div>

                    <!-- body -->
                    <div class="row table-price-field item-header">
                        <div class="assign-panel-table__price">
                            <div class="payment__info-currency-input assign-panel-table__price-input">
                                <tc-currency-field
                                        [amount]="summaryCardData?.data?.value?.adultFare"
                                        [disableRevert]="true"
                                        [showPreviousAmount]="false"
                                        formControlName="adult"
                                        [currencyFormat]="currencyFormat"
                                        [readOnly]="true"
                                        (onInputValueChange)="onAdultFareChange($event)">
                                </tc-currency-field>
                            </div>
                        </div>
                    </div>
                    <div class="row table-price-field item-header">
                        <div class="assign-panel-table__price">
                            <div class="payment__info-currency-input assign-panel-table__price-input">
                                <tc-currency-field
                                        [amount]="summaryCardData?.data?.value?.childFare"
                                        [disableRevert]="true"
                                        [showPreviousAmount]="false"
                                        formControlName="child"
                                        [currencyFormat]="currencyFormat"
                                        [readOnly]="true"
                                        (onInputValueChange)="onChildFareChange($event)">
                                </tc-currency-field>
                            </div>
                        </div>
                    </div>
                    <div class="row table-price-field item-header">
                        <div class="assign-panel-table__price">
                            <div class="payment__info-currency-input assign-panel-table__price-input">
                                <tc-currency-field
                                        [amount]="summaryCardData?.data?.value?.infantFare"
                                        [disableRevert]="true"
                                        [showPreviousAmount]="false"
                                        formControlName="infant"
                                        [currencyFormat]="currencyFormat"
                                        [readOnly]="true"
                                        (onInputValueChange)="onInfantFareChange($event)">
                                </tc-currency-field>
                            </div>
                        </div>
                    </div>
                    <div class="row table-price-field item-header">
                        <div class=" ">
                        </div>
                    </div>
                </div>
                <div class="fixed-column">

                    <!-- header -->
                    <div class="row table-price-field">
                        <div class="item-header__content">Total</div>
                    </div>

                    <!-- body -->
                    <div class="row table-price-field">
                        <div class="item-header__content item-header__price assign-panel-table__price">
                            <span>{{assignedSupplier?.commonSupplier?.totalAdultFare | tcCurrencyPipe:currencyFormat?.currencyCode:"code":currencyFormat?.pattern}}</span>
                        </div>
                    </div>
                    <div class="row table-price-field">
                        <div class="item-header__content item-header__price assign-panel-table__price">
                            <span>{{assignedSupplier?.commonSupplier?.totalChildFare| tcCurrencyPipe:currencyFormat?.currencyCode:"code":currencyFormat?.pattern}}</span>
                        </div>
                    </div>
                    <div class="row table-price-field">
                        <div class="item-header__content item-header__price assign-panel-table__price">
                            <span>{{assignedSupplier?.commonSupplier?.totalInfantFare| tcCurrencyPipe:currencyFormat?.currencyCode:"code":currencyFormat?.pattern}}</span>
                        </div>
                    </div>
                    <div class="row table-price-field">
                        <div class="item-header__content item-header__price assign-panel-table__price-grand-total">
                            <span>{{assignedSupplier?.commonSupplier?.totalCost| tcCurrencyPipe:currencyFormat?.currencyCode:"code":currencyFormat?.pattern}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row"  *ngIf="summaryCardData && summaryCardData.commonSupplier && summaryCardData.commonSupplier.isUnitFare">
            <div class="cost-break-down__detail-view">
                <div class="fixed-column">
                    <!-- header -->
                    <div class="row table-header pbc-item-header item-header">
                        <div class="heading item-header__content "></div>
                    </div>

                    <!-- body -->
                    <div class="row pbc-item-header item-header">
                        <div class="heading item-header__content item-header__price">
                            <span>Unit</span>
                        </div>
                    </div>
                    <div class="row pbc-item-header item-header">
                        <div class="heading item-header__content item-header__price">
                            <span>Grand Total</span>
                        </div>
                    </div>
                </div>
                <div class="fixed-column">

                    <!-- header -->
                    <div class="row table-price-field">
                        <div class="item-header__content">Passenger Count</div>
                    </div>

                    <!-- body -->
                    <div class="row table-price-field item-header">
                        <div class="item-header__content">
                            <span>{{passengerCount}}</span>
                        </div>
                    </div>
                    <div class="row table-price-field item-header">
                        <div class=" item-header__content">
                        </div>
                    </div>
                </div>

                <div class="fixed-column">

                    <!-- header -->
                    <div class="row table-price-field ">
                        <div class="item-header__content">Cost ( {{currencyFormat.currencyCode}} )</div>
                    </div>

                    <!-- body -->
                    <div class="row table-price-field item-header">
                        <div class="assign-panel-table__price">
                            <div class="payment__info-currency-input assign-panel-table__price-input">
                                <tc-currency-field
                                        [amount]="summaryCardData?.data?.value?.unitFare"
                                        [disableRevert]="true"
                                        [showPreviousAmount]="false"
                                        formControlName="unit"
                                        [currencyFormat]="currencyFormat"
                                        [readOnly]="true"
                                        (onInputValueChange)="onUnitFareChange($event)">
                                </tc-currency-field>
                            </div>
                        </div>
                    </div>
                    <div class="row table-price-field item-header">
                        <div class="item-header__content item-header__price">
                        </div>
                    </div>
                </div>
                <div class="fixed-column">

                    <!-- header -->
                    <div class="row table-price-field">
                        <div class="item-header__content">Total</div>
                    </div>

                    <!-- body -->
                    <div class="row table-price-field">
                        <div class="item-header__content item-header__price">
                            <span>{{assignedSupplier?.commonSupplier?.unitFare | tcCurrencyPipe:currencyFormat?.currencyCode:"code":currencyFormat?.pattern}}</span>
                        </div>
                    </div>
                    <div class="row table-price-field">
                        <div class="item-header__content item-header__price assign-panel-table__price-grand-total">
                            <span>{{assignedSupplier?.commonSupplier?.totalCost| tcCurrencyPipe:currencyFormat?.currencyCode:"code":currencyFormat?.pattern}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="!isGenResourceAssignment">
            <div class="col-xs-12 col-sm-12  col-md-3 col-lg-3">
                <div class="tc-flex-box">
                    <div class="tc-flex-item input-cage ">
                        <div class="tc-content--wrap tc-input-view">
                            <div
                                    id="TRD_PER_INFO_COU"
                                    class="tc-caption-txt txt-light">{{"Vehicle Capacity" | translate}}
                            </div>
                            <div class="tc-body1--txt">
                                <span>{{summaryCardData?.data?.value?.capacity ? summaryCardData?.data?.value?.capacity : EMPTY_CHAR}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
