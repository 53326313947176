import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { TcApiError } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-api-error';
import { TcHttpError } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-http-error';
import { TC } from '@tc-core/util';
import { ConfigLoader } from '@tc-core/util/framework/config-loader.service';
import { EventManager } from '@tc-core/util/framework/event-manager.service';
import { UserJourneyManager } from '@tc-core/util/framework/user-journey-manager.service';
import { CommonHelper } from '@tc-core/util/helpers/common-helper.service';
import { SpinnerService } from '@tc-core/util/ui';
import { ChipInputComponent } from '@tc/chip-input';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { TCO } from '../../../../../constants';
import { SupplierAllocationSearchCriteria } from '../../../../../models/reservation-v2/criteria/supplier-allocation-search-criteria';
import { ResourceType } from '../../../../../models/reservation/assignment';
import { ReservationV2ManagementService } from '../../../../../services/backend-consumers/reservation-v2/reservation-v2-management.service';
import { ChipHandlerService } from '../../../../../services/helpers/chip-handler.service';
import { RootService } from '../../../../../services/util/core-services/root.service';
import { ActionHandlerService } from '../../../../../services/util/framework/action-handler-service.service';
import { DataKey, DataStoreService } from '../../../../../services/util/framework/data-store.service';
import { DMCQueryParamsService } from '../../../../../services/util/framework/dmc-query-params.service';
import { UserManagementService } from '../../../../../services/user-management/user-management.service';
var SupplierAllocationSearchCriteriaComponent = /** @class */ (function () {
    function SupplierAllocationSearchCriteriaComponent(configLoader, chipHandler, rootService, em, userJourneyManager, queryParamService, commonHelper, reservationV2Service, spinnerService, dataStoreService, actionHandlerService, userManagementService) {
        this.configLoader = configLoader;
        this.chipHandler = chipHandler;
        this.rootService = rootService;
        this.em = em;
        this.userJourneyManager = userJourneyManager;
        this.queryParamService = queryParamService;
        this.commonHelper = commonHelper;
        this.reservationV2Service = reservationV2Service;
        this.spinnerService = spinnerService;
        this.dataStoreService = dataStoreService;
        this.actionHandlerService = actionHandlerService;
        this.userManagementService = userManagementService;
        this.isInsideForm = false;
        this.inputSearchCriteria = new SupplierAllocationSearchCriteria();
        this.isSearchBarStyleNotAvailable = false;
        this.isDataLoaded = true;
        this.displayChipsArr = [];
        this.isFocus = false;
        this.placeHolderText = 'Search Suppliers';
        this.isTriggerSearch = true;
        this.isVehicleAssignment = false;
        this.searchTrigger = new EventEmitter();
        this.availableChips = [];
        this.isChipListDisplay = false;
        this.isChipsInit = false;
        this.persistentQParams = [];
        this.persistentQParamMap = new Map();
        this.persistentChipsArr = [];
        this.journey = null;
        this.relatedChipsObsv = new Subscription();
        this.selectedChipsObsv = new Subscription();
        this.selectedFieldsObsv = new Subscription();
        this.chipValueUpdateSub = new Subscription();
        this.criteriaUpdateSubscription = new Subscription();
        this.deletedChipObsv = new Subscription();
        this.chipHandlerSubject = new Subscription();
        this.selectedChips = [];
    }
    SupplierAllocationSearchCriteriaComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.chipInputService = this.searchChipInput.criteriaInputService;
        this.tPagination = this.configLoader.configurations.get(TCO.CONF.CONF_PAGINATION_CONFIG).RESOURCE_ALLOCATION_SEARCH;
        // reset the search results
        this.dataStoreService.set(DataKey.supplierAllocationSearchCriteria, null, true);
        this.handleQuickActions();
        this.userJourneyManager.journey.subscribe(function (journey) {
            if (journey && JSON.stringify(journey) !== JSON.stringify({})) {
                _this.journey = journey.id;
            }
        });
        // resetting the view
        this.routerChangeSubscription = this.userJourneyManager.routeChanges.subscribe(function (e) {
            if (e) {
                _this.chipInputService.fullReset(); // fully reset chips
                _this.chipHandler.chipsArr = []; // reset chips array loaded from config
                console.log('displayChipsArr', _this.displayChipsArr);
                _this.resetQueryParams();
            }
        });
        if (!this.isInsideForm) {
            this.supplierSearchCriteria = new SupplierAllocationSearchCriteria();
            if (this.inputSearchCriteria) {
                this.chipInputService.fullReset(); // fully reset chips
                this.chipHandler.chipsArr = []; // reset chips array loaded from config
                this.supplierSearchCriteria.serviceDate = this.inputSearchCriteria.serviceDate;
                this.supplierSearchCriteria.serviceType = this.inputSearchCriteria.serviceType;
                this.supplierSearchCriteria.adultCount = this.inputSearchCriteria.adultCount;
                this.supplierSearchCriteria.childCount = this.inputSearchCriteria.childCount;
                this.supplierSearchCriteria.infantCount = this.inputSearchCriteria.infantCount;
                this.supplierSearchCriteria.serviceStartTime = this.inputSearchCriteria.serviceStartTime;
                this.supplierSearchCriteria.serviceEndTime = this.inputSearchCriteria.serviceEndTime;
                this.supplierSearchCriteria.bookingIdList = this.inputSearchCriteria.bookingIdList;
                this.supplierSearchCriteria.profileType = this.inputSearchCriteria.profileType;
                this.supplierSearchCriteria.costingType = this.inputSearchCriteria.costingType;
                this.supplierSearchCriteria.noOfUnits = this.inputSearchCriteria.noOfUnits;
                this.supplierSearchCriteria.kcCompany = this.inputSearchCriteria.kcCompany;
                this.supplierSearchCriteria.kcDivision = this.inputSearchCriteria.kcDivision;
                this.supplierSearchCriteria.kcBrand = this.inputSearchCriteria.kcBrand;
                this.supplierSearchCriteria.kcDistributionChannel = this.inputSearchCriteria.kcDistributionChannel;
                if (this.inputSearchCriteria.serviceType === ResourceType.vehicle) {
                    this.isVehicleAssignment = true;
                    this.supplierSearchCriteria.transferMode = this.inputSearchCriteria.transferMode;
                    this.supplierSearchCriteria.route = this.inputSearchCriteria.route;
                    this.supplierSearchCriteria.vehicleModel = this.inputSearchCriteria.vehicleModel ? this.inputSearchCriteria.vehicleModel : 'Any';
                }
                if (!this.isChipsInit) {
                    this.initSearchCriteriaChips(); // load everything from start
                }
            }
        }
        this.getSupplierSearchObserver();
        this.errorObserver();
        this.selectedChipsUpdate();
        this.handleInterRelatedChips();
        this.notifyChipDelete();
        this.shortCutObserver();
    };
    SupplierAllocationSearchCriteriaComponent.prototype.getQueryParams = function () {
        var queryParams = [];
        queryParams = this.queryParamService.getQueryParamsArray(new SupplierAllocationSearchCriteria());
        this.supplierSearchCriteria = this.rootService.loadDataFromLocalStorage(queryParams, new SupplierAllocationSearchCriteria(), TCO.AppData.SUPPLIER_ALLOCATION_SEARCH_CRITERIA);
        // get persisting query params
        if (this.supplierSearchCriteria) {
            this.persistentQParams = [];
            this.persistentQParams = this.queryParamService.getNotNullQueryParamsArray(this.supplierSearchCriteria);
        }
    };
    SupplierAllocationSearchCriteriaComponent.prototype.shortCutObserver = function () {
        var _this = this;
        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'FOCUS_SEARCH_BAR').subscribe(function (e) {
            _this.isFocus = true;
        });
        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'CLOSE_POPUPS').subscribe(function (e) {
            _this.isFocus = false;
        });
    };
    SupplierAllocationSearchCriteriaComponent.prototype.onFocus = function (event) {
        this.isFocus = event;
    };
    SupplierAllocationSearchCriteriaComponent.prototype.initSearchCriteriaChips = function () {
        var _this = this;
        this.isChipsInit = true;
        // read chip config
        var criteriaChipConfigs = null;
        if (!this.isInsideForm) {
            var chipConfigs = this.configLoader.configurations.get(TCO.CONF.CONF_SUPPLIER_ALLOCATION_CHIP);
            criteriaChipConfigs = this.commonHelper.recreateJsonObject(chipConfigs);
        }
        else {
            var chipConfigs = this.configLoader.configurations.get(this.searchCriteriaChipConfig);
            criteriaChipConfigs = this.commonHelper.recreateJsonObject(chipConfigs);
        }
        if (criteriaChipConfigs.chips) {
            criteriaChipConfigs.chips.forEach(function (chip) {
                chip = _this.chipHandler.preProcessChips(chip, criteriaChipConfigs.permissionKeys.keys);
            });
        }
        this.chipHandler.chipsSubject.subscribe(function (data) {
            if (data && JSON.stringify(data) !== JSON.stringify([]) && data.length ===
                criteriaChipConfigs.chips.length) {
                _this.userManagementService.updateAllocationSearchChipsWithKC(criteriaChipConfigs, _this.supplierSearchCriteria.kcCompany, _this.supplierSearchCriteria.kcDivision, _this.supplierSearchCriteria.kcBrand, _this.supplierSearchCriteria.kcDistributionChannel);
                _this.availableChips = data;
                _this.chipHandler.chipsSubject.next(null);
                if (_this.chipHandlerSubject) {
                    _this.chipHandlerSubject.unsubscribe();
                }
                // set values to display chips
                _this.displayChipsArr = [];
                _this.displayChipsArr = _this.availableChips;
                _this.availableChips.forEach(function (chip) {
                    if (chip.criteriaId === 'SERVICE_TYPE') {
                        chip.optionParams.isInitialPresent = true;
                        chip.optionParams.isInitialPresent = true;
                        var choice = chip.optionParams.options
                            .filter(function (option) { return option.code === _this.supplierSearchCriteria.serviceType; });
                        chip.optionParams.data = choice;
                    }
                    if (chip.criteriaId === 'SERVICE_DATE') {
                        chip.optionParams.isInitialPresent = true;
                        chip.optionParams.data = [moment(_this.supplierSearchCriteria.serviceDate).toDate()];
                    }
                    if (_this.isVehicleAssignment) {
                        if (chip.criteriaId === 'TRANSFER_MODE') {
                            chip.optionParams.isInitialPresent = true;
                            var choice = chip.optionParams.options
                                .filter(function (option) { return option.code === _this.supplierSearchCriteria.transferMode; });
                            chip.optionParams.data = choice;
                            chip.optionParams.isMandatory = true;
                        }
                        if (chip.criteriaId === 'ROUTE') {
                            chip.optionParams.isInitialPresent = true;
                            var choice = chip.optionParams.options
                                .filter(function (option) { return option.code === _this.supplierSearchCriteria.route; });
                            chip.optionParams.data = choice;
                            chip.optionParams.isMandatory = true;
                        }
                        if (chip.criteriaId === 'VEHICLE_MODEL') {
                            chip.optionParams.isInitialPresent = true;
                            var choice = chip.optionParams.options
                                .filter(function (option) { return option.code === _this.supplierSearchCriteria.vehicleModel; });
                            chip.optionParams.data = choice;
                            chip.optionParams.isMandatory = true;
                        }
                    }
                });
                _this.dataStoreService.set(DataKey.chips, _this.availableChips, true);
                // trigger search when chips persisting
                _this.isDataLoaded = false;
                // this.isTriggerSearch = false;
                _this.onSupplierSearch(_this.supplierSearchCriteria);
                _this.chipInputService.init(_this.supplierSearchCriteria, _this.availableChips, false, true);
                // implement chip persistance
                if (_this.persistentQParams && _this.persistentQParams.length > 0) {
                    // create a queryParamMap
                    for (var i = 0; i < _this.persistentQParams.length; i++) {
                        var qParam = _this.persistentQParams[i];
                        var qParamValue = _this.supplierSearchCriteria[qParam];
                        _this.persistentQParamMap.set(qParam, qParamValue);
                    }
                    // fill persistent chips
                    _this.availableChips.forEach(function (chip) {
                        var persistChip = Object.assign({}, chip);
                        persistChip.optionParams.data = [];
                        var isPersist = false;
                        if (chip.dataParams && chip.dataParams.length > 0) {
                            chip.dataParams.forEach(function (dataParam) {
                                if (_this.persistentQParamMap.has(dataParam.paramValue)) {
                                    isPersist = true;
                                    var dataObj = {
                                        key: dataParam.paramValue,
                                        value: _this.persistentQParamMap.get(dataParam.paramValue)
                                    };
                                    persistChip.optionParams.data.push(dataObj);
                                }
                            });
                        }
                        // push persist chips to an array
                        if (isPersist) {
                            _this.persistentChipsArr.push(persistChip);
                        }
                    });
                    // persist chips
                    if (_this.persistentChipsArr && _this.persistentChipsArr.length > 0) {
                        var uniqueChipList = _this.persistentChipsArr.filter(function (e, i) {
                            return _this.persistentChipsArr.findIndex(function (x) {
                                return x.criteriaId === e.criteriaId;
                            }) === i;
                        });
                        _this.chipInputService.persistChips(uniqueChipList);
                        // trigger search when chips persisting
                        _this.isDataLoaded = false;
                        // this.isTriggerSearch = false;
                        _this.onSupplierSearch(_this.supplierSearchCriteria);
                    }
                }
                _this.chipInputService.criteriaUpdate.subscribe(function (_data) {
                    _this.supplierSearchCriteria = _data;
                });
            }
        });
    };
    SupplierAllocationSearchCriteriaComponent.prototype.onSupplierSearch = function (event) {
        var _this = this;
        this.isDataLoaded = false;
        this.supplierSearchCriteria = event;
        if (this.tPagination && this.tPagination.initialFetchingPages &&
            this.tPagination.defaultPageResultCount) {
            this.supplierSearchCriteria.size = this.tPagination.initialFetchingPages *
                this.tPagination.defaultPageResultCount;
        }
        this.supplierSearchCriteria.start = 0;
        this.userJourneyManager.canProceed.next(false);
        if (JSON.stringify(this.supplierSearchCriteria) !== JSON.stringify({})) {
            this.dataStoreService.set(DataKey.supplierAllocationSearchCriteria, this.supplierSearchCriteria, true);
            this.dataStoreService.set(DataKey.supplierAllocationSearchResults, null, true);
            this.reservationV2Service.searchSuppliersForAllocationWithKey(this.supplierSearchCriteria);
            this.isTriggerSearch = false;
            this.isDataLoaded = false;
            this.searchTrigger.emit(true);
        }
        else {
            setTimeout(function () {
                _this.isDataLoaded = true;
                _this.isTriggerSearch = true;
            }, 0);
        }
    };
    SupplierAllocationSearchCriteriaComponent.prototype.getUpdatedFieldsDataObject = function (chip, criteria) {
        var dataObjArr = [];
        if (chip && chip.dataParams && chip.dataParams.length > 0) {
            chip.dataParams.forEach(function (dataParam) {
                var dataObj = {
                    key: '',
                    value: ''
                };
                dataObj.key = dataParam.paramValue;
                dataObj.value = criteria[dataParam.paramValue];
                dataObjArr.push(dataObj);
            });
        }
        return dataObjArr;
    };
    SupplierAllocationSearchCriteriaComponent.prototype.getSupplierSearchObserver = function () {
        var _this = this;
        var x = this.dataStoreService.get(DataKey.supplierAllocationSearchResults).subscribe(function (data) {
            if (_this.commonHelper.dataValidity(data)) {
                setTimeout(function () {
                    _this.isDataLoaded = true;
                    _this.isTriggerSearch = true;
                }, 0);
            }
            else if (_this.commonHelper.isEmptyData(data)) {
                _this.isDataLoaded = true;
            }
            else if (data instanceof TcApiError) {
                _this.isDataLoaded = true;
            }
            else if (data instanceof TcHttpError) {
                _this.isDataLoaded = true;
            }
        }, function (error) {
        });
    };
    SupplierAllocationSearchCriteriaComponent.prototype.errorObserver = function () {
        var _this = this;
        this.dataStoreService.get(DataKey.error).subscribe(function (data) {
            if (data && JSON.stringify(data) !== JSON.stringify({})) {
                setTimeout(function () {
                    _this.isDataLoaded = true;
                }, 0);
                _this.dataStoreService.set(DataKey.error, null);
            }
        });
    };
    SupplierAllocationSearchCriteriaComponent.prototype.resetQueryParams = function () {
        var supplierQueryParams = [];
        supplierQueryParams = this.queryParamService.getQueryParamsArray(new SupplierAllocationSearchCriteria());
        var supplierSearchCriteria = this.rootService.loadDataFromLocalStorage(supplierQueryParams, new SupplierAllocationSearchCriteria(), TCO.AppData.SUPPLIER_ALLOCATION_SEARCH_CRITERIA);
        // set criteria parameters to url
        var queryParams = [];
        queryParams = this.queryParamService.getQueryParamsArray(supplierSearchCriteria);
        this.rootService.setDataToLocalStorage(TCO.AppData.SUPPLIER_ALLOCATION_SEARCH_CRITERIA, this.supplierSearchCriteria, true, queryParams, true);
    };
    SupplierAllocationSearchCriteriaComponent.prototype.onCancel = function (event) {
        console.log('cancelled');
    };
    SupplierAllocationSearchCriteriaComponent.prototype.onChipListDisplay = function (event) {
        this.dataStoreService.set(DataKey.chipListDisplay, null, true);
        this.isChipListDisplay = event;
        this.dataStoreService.set(DataKey.chipListDisplay, this.isChipListDisplay, true);
    };
    SupplierAllocationSearchCriteriaComponent.prototype.handleQuickActions = function () {
        var _this = this;
        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'TRIGGER_SEARCH_CRITERIA').subscribe(function (e) {
            _this.triggerSearch(e.data);
        });
    };
    SupplierAllocationSearchCriteriaComponent.prototype.triggerSearch = function (data) {
        this.supplierSearchCriteria = new SupplierAllocationSearchCriteria();
        this.actionHandlerService.fillCriteriaByActionData(data, this.supplierSearchCriteria);
        var requiredChips = this.chipHandler.getChipsFromCriteria(this.supplierSearchCriteria, TCO.CONF.CONF_SUPPLIER_ALLOCATION_CHIP, true);
        this.chipInputService.hardReset();
        this.chipInputService.persistChips(requiredChips, true);
        this.onSupplierSearch(this.supplierSearchCriteria);
    };
    SupplierAllocationSearchCriteriaComponent.prototype.observeChipValueUpdate = function () {
    };
    SupplierAllocationSearchCriteriaComponent.prototype.handleInterRelatedChips = function () {
        var _this = this;
        this.relatedChipsObsv = this.chipInputService.relatedChipsSubject.subscribe(function (data) {
            var toggleChips = [];
            if (data && data.chip && JSON.stringify(data) !== JSON.stringify({})) {
                _this.modifiedChip = data.chip;
                // TODO: refine chip input service: inter related chips
                if (data.chip.type === 'CALENDAR') {
                    _this.chipHandler.handleInterRelatedChips(data.chip, data.date);
                }
                else {
                    _this.chipHandler.handleInterRelatedChips(data.chip, data.options.value[0]);
                }
                if (data.chip.optionParams.toggleChips && data.chip.optionParams.toggleChips.length > 0) {
                    toggleChips = data.chip.optionParams.toggleChips;
                    if (toggleChips && data.options && data.options.value[0].value) {
                        _this.chipInputService.selectedChipsBasedOnStateChange(toggleChips);
                    }
                    else {
                        _this.chipInputService.deleteChipsBasedOnStateChange(toggleChips);
                    }
                }
            }
        });
    };
    SupplierAllocationSearchCriteriaComponent.prototype.resetResourceAssignChips = function () {
        this.availableChips.forEach(function (chip) {
            if (chip.criteriaId === 'SERVICE_TYPE') {
                chip.optionParams.isInitialPresent = true;
                chip.optionParams.data = [];
            }
            if (chip.criteriaId === 'SERVICE_DATE') {
                chip.optionParams.isInitialPresent = false;
                chip.optionParams.data = [];
            }
            if (chip.criteriaId === 'TRANSFER_MODE') {
                chip.optionParams.isInitialPresent = false;
                chip.optionParams.data = [];
            }
            if (chip.criteriaId === 'ROUTE') {
                chip.optionParams.isInitialPresent = false;
                chip.optionParams.data = [];
            }
        });
    };
    SupplierAllocationSearchCriteriaComponent.prototype.ngOnDestroy = function () {
        this.routerChangeSubscription.unsubscribe();
        this.userJourneyManager.routeChanges.next(false);
        if (this.selectedChipsObsv) {
            this.selectedChipsObsv.unsubscribe();
        }
        if (this.selectedFieldsObsv) {
            this.selectedFieldsObsv.unsubscribe();
        }
        if (this.chipValueUpdateSub) {
            this.chipValueUpdateSub.unsubscribe();
        }
        if (this.criteriaUpdateSubscription) {
            this.criteriaUpdateSubscription.unsubscribe();
        }
        if (this.deletedChipObsv) {
            this.deletedChipObsv.unsubscribe();
        }
        this.em.removeEvents([
            'TRIGGER_SEARCH_CRITERIA'
        ]);
    };
    SupplierAllocationSearchCriteriaComponent.prototype.selectedChipsUpdate = function () {
        var _this = this;
        this.criteriaUpdateSubscription = this.chipInputService.selectedChipsSubject.subscribe(function (selectedChips) {
            var e_1, _a;
            _this.selectedChips = selectedChips;
            if (selectedChips && JSON.stringify(selectedChips) !== JSON.stringify([])) {
                try {
                    // toggle chips when selecting BRANCH_OF_CLIENT_ID chip
                    for (var _b = tslib_1.__values(_this.selectedChips), _c = _b.next(); !_c.done; _c = _b.next()) {
                        var selectedChip = _c.value;
                        if (selectedChip.chip.id === 'BRANCH_OF_CLIENT_ID' && selectedChip.chip.optionParams.toggleChips &&
                            selectedChip.chip.optionParams.toggleChips.length > 0) {
                            _this.deleteToggleChips(selectedChip.chip.optionParams.toggleChips);
                            _this.disableChips(selectedChip.chip.optionParams.toggleChips);
                            break;
                        }
                    }
                }
                catch (e_1_1) { e_1 = { error: e_1_1 }; }
                finally {
                    try {
                        if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                    }
                    finally { if (e_1) throw e_1.error; }
                }
            }
        });
    };
    SupplierAllocationSearchCriteriaComponent.prototype.notifyChipDelete = function () {
        var _this = this;
        this.deletedChipObsv = this.chipInputService.deletedChipsSubject.subscribe(function (deletedChip) {
            if (JSON.stringify(deletedChip) !== JSON.stringify({}) && deletedChip !== null && deletedChip !==
                undefined) {
                if (deletedChip.id === 'CLIENT_TYPE') {
                    var deleteChips = _this.selectedChips.map(function (chip) { return chip.chip; });
                    _this.deleteSelectedChips(deleteChips);
                    _this.enableChips(_this.availableChips);
                }
                else if (deletedChip.id === 'BRANCH_OF_CLIENT_ID') {
                    if (deletedChip.optionParams.toggleChips && deletedChip.optionParams.toggleChips.length > 0) {
                        _this.enableChips(deletedChip.optionParams.toggleChips);
                    }
                }
            }
        });
    };
    /**
     * Delete toggle chips if already selected
     *
     * @param toggleChips
     */
    SupplierAllocationSearchCriteriaComponent.prototype.deleteToggleChips = function (toggleChips) {
        var _this = this;
        var deleteChips = [];
        toggleChips.forEach(function (toggleChip) {
            var e_2, _a;
            try {
                for (var _b = tslib_1.__values(_this.selectedChips), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var selectedChip = _c.value;
                    if (selectedChip.chip.id === toggleChip.criteriaId) {
                        deleteChips.push(selectedChip.chip);
                        break;
                    }
                }
            }
            catch (e_2_1) { e_2 = { error: e_2_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_2) throw e_2.error; }
            }
        });
        this.deleteSelectedChips(deleteChips);
    };
    SupplierAllocationSearchCriteriaComponent.prototype.deleteSelectedChips = function (deleteChips) {
        var _this = this;
        deleteChips.forEach(function (chip) {
            _this.chipInputService.deleteChip(chip, true);
        });
    };
    SupplierAllocationSearchCriteriaComponent.prototype.enableChips = function (enableChips) {
        var _this = this;
        enableChips.forEach(function (enableChip) {
            _this.chipInputService.modifyChipsStatus(enableChip, false);
        });
    };
    SupplierAllocationSearchCriteriaComponent.prototype.disableChips = function (disableChips) {
        var _this = this;
        disableChips.forEach(function (disableChip) {
            _this.chipInputService.modifyChipsStatus(disableChip, true);
        });
    };
    return SupplierAllocationSearchCriteriaComponent;
}());
export { SupplierAllocationSearchCriteriaComponent };
