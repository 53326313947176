<div class="tc-user-profile">
    <mat-progress-bar
            *ngIf="!isUserLoaded"
            mode="indeterminate"
            class="progress-main-color">
    </mat-progress-bar>

    <ng-container *ngIf="isUserLoaded">
        <div class="tc-user-profile__row tc-user-profile__row--header tc-user-profile__header">
            <div class="tc-user-profile__thumbnail">
                <img
                        class="tc-user-profile__img"
                        alt="pro. pic"
                        (error)="onImageError($event)"
                        [src]="userImageUrl"/>
            </div>
            <div class="tc-user-profile__content-cage tc-user-profile__general-info">
                <h3 class="tc-user-profile__name">
                    {{user?.userSummary?.firstName + ' '}}{{user?.userSummary?.lastName}}
                </h3>
            </div>
        </div>
        <div class="tc-user-profile__wrapper">
            <ng-container *ngIf="userManagementService.notAvailableFor('FC')">
                    <div class="tc-user-profile__row tc-user-profile__username">
                        <div class="tc-user-profile__icon-cage">
                            <div class="tc-flex-item tc-item-circle">
                                <div class="icon-box">
                                    <i class="material-icons">person</i>
                                </div>
                            </div>
                        </div>
                        <div class="tc-user-profile__content-cage">
                            <div class="tc-user-profile__username tc-input-view">
                                <div class="tc-input-view__label tc-input-view__label--light">{{'Username' | translate}}</div>
                                <div class="tc-input-view__value tc-input-view__value--regular">{{user?.userSummary?.username}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="tc-user-profile__row tc-user-profile__company">
                        <div class="tc-user-profile__icon-cage">
                            <div class="tc-flex-item tc-item-circle">
                                <div class="icon-box">
                                    <i class="material-icons">business</i>
                                </div>
                            </div>
                        </div>
                        <div class="tc-user-profile__content-cage">
                            <div class="tc-user-profile__email tc-input-view">
                                <div class="tc-input-view__label tc-input-view__label--light">{{'Company' | translate}}</div>
                                <div class="tc-input-view__value tc-input-view__value--regular">{{user?.userDetail?.defaultCom}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="tc-user-profile__row tc-user-profile__brand">
                        <div class="tc-user-profile__icon-cage">
                            <div class="tc-flex-item tc-item-circle">
                                <div class="icon-box">
                                    <i class="material-icons">local_activity</i>
                                </div>
                            </div>
                        </div>
                        <div class="tc-user-profile__content-cage">
                            <div class="tc-user-profile__email tc-input-view">
                                <div class="tc-input-view__label tc-input-view__label--light">{{'Brand' | translate}}</div>
                                <div class="tc-input-view__value tc-input-view__value--regular">{{user?.userDetail?.defaultBrand}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="tc-user-profile__row tc-user-profile__email">
                        <div class="tc-user-profile__icon-cage">
                            <div class="tc-flex-item tc-item-circle">
                                <div class="icon-box">
                                    <i class="material-icons">email</i>
                                </div>
                            </div>
                        </div>
                        <div class="tc-user-profile__content-cage">
                            <div class="tc-user-profile__email tc-input-view">
                                <div class="tc-input-view__label tc-input-view__label--light">{{'Email' | translate}}</div>
                                <div class="tc-input-view__value tc-input-view__value--regular">{{user?.userSummary?.email}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="tc-user-profile__row tc-user-profile__division">
                        <div class="tc-user-profile__icon-cage">
                            <div class="tc-flex-item tc-item-circle">
                                <div class="icon-box">
                                    <i class="material-icons">dashboard</i>
                                </div>
                            </div>
                        </div>
                        <div class="tc-user-profile__content-cage">
                            <div class="tc-user-profile__email tc-input-view">
                                <div class="tc-input-view__label tc-input-view__label--light">{{'Division' | translate}}</div>
                                <div class="tc-input-view__value tc-input-view__value--regular">{{user?.userDetail?.defaultDivison}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="tc-user-profile__row tc-user-profile__channel">
                        <div class="tc-user-profile__icon-cage">
                            <div class="tc-flex-item tc-item-circle">
                                <div class="icon-box">
                                    <i class="material-icons">insert_chart_outlined</i>
                                </div>
                            </div>
                        </div>
                        <div class="tc-user-profile__content-cage">
                            <div class="tc-user-profile__email tc-input-view">
                                <div class="tc-input-view__label tc-input-view__label--light">{{'Distribution Channel' | translate}}</div>
                                <div class="tc-input-view__value tc-input-view__value--regular">{{user?.userDetail?.defaultChannel}}</div>
                            </div>
                        </div>
                    </div>
            </ng-container>

            <ng-container *ngIf="userManagementService.onlyAvailableFor('FC')">
                    <div class="tc-user-profile__row tc-user-profile__username">
                        <div class="tc-user-profile__icon-cage">
                            <div class="tc-flex-item tc-item-circle">
                                <div class="icon-box">
                                    <i class="material-icons">person</i>
                                </div>
                            </div>
                        </div>
                        <div class="tc-user-profile__content-cage">
                            <div class="tc-user-profile__username tc-input-view">
                                <div class="tc-input-view__label tc-input-view__label--light">{{'Username' | translate}}</div>
                                <div class="tc-input-view__value tc-input-view__value--regular">{{user?.userSummary?.username}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="tc-user-profile__row tc-user-profile__email">
                        <div class="tc-user-profile__icon-cage">
                            <div class="tc-flex-item tc-item-circle">
                                <div class="icon-box">
                                    <i class="material-icons">email</i>
                                </div>
                            </div>
                        </div>
                        <div class="tc-user-profile__content-cage">
                            <div class="tc-user-profile__email tc-input-view">
                                <div class="tc-input-view__label tc-input-view__label--light">{{'Email' | translate}}</div>
                                <div class="tc-input-view__value tc-input-view__value--regular">{{user?.userSummary?.email}}</div>
                            </div>
                        </div>
                    </div>
            </ng-container>
        </div>

        <div class="tc-user-profile__row tc-user-profile__row--footer">
            <button
                    mat-button
                    class="tc-user-profile__button tc-user-profile__button--logout mat-button tc-button-primary"
                    [disabled]="disableLogOut"
                    (click)="onLogout()">
                {{'Log Out' | translate}}
            </button>
        </div>
    </ng-container>
</div>