/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/cdk/bidi";
import * as i3 from "./widgets/framework/fab-wrapper/fab-wrapper.component.ngfactory";
import * as i4 from "./widgets/framework/fab-wrapper/fab-wrapper.component";
import * as i5 from "@tc-core/util/framework/user-journey-manager.service";
import * as i6 from "@tc-core/util/framework/event-manager.service";
import * as i7 from "@tc-core/util/framework/focus-handler.service";
import * as i8 from "./widgets/framework/dmc-spinner-wrapper/dmc-spinner-wrapper.component.ngfactory";
import * as i9 from "./widgets/framework/dmc-spinner-wrapper/dmc-spinner-wrapper.component";
import * as i10 from "@tc-core/util/ui/spinner.service";
import * as i11 from "./services/util/framework/data-store.service";
import * as i12 from "@angular/material/snack-bar";
import * as i13 from "./widgets/framework/notification-toast/notification-toast-wrapper/notification-toast-wrapper.component.ngfactory";
import * as i14 from "./widgets/framework/notification-toast/notification-toast-wrapper/notification-toast-wrapper.component";
import * as i15 from "./widgets/framework/notification-toast/notification-toast.service";
import * as i16 from "./widgets/framework/notification-toast/notification-toast-model";
import * as i17 from "@angular/router";
import * as i18 from "./app.component";
import * as i19 from "./services/backend-consumers/master-data-handler-service/data-handler.service";
import * as i20 from "./services/backend-consumers/master-data-handler-service/master-data-rest.service";
import * as i21 from "./services/backend-consumers/supplier-service/supplier.service";
import * as i22 from "./app.service";
import * as i23 from "@tc-core/util/framework/scaffolding-helper.service";
import * as i24 from "./services/util/common/dmc-common";
import * as i25 from "./services/backend-consumers/reservation-management-services/trs-reservation-management.service";
import * as i26 from "./services/backend-consumers/reservation-management-services/reservation-management.service";
import * as i27 from "./services/backend-consumers/reservation-v2/reservation-v2-management.service";
import * as i28 from "./services/backend-consumers/master-data-handler-service/master-data-handler.service";
import * as i29 from "./services/backend-consumers/setups/accounting-rule.service";
import * as i30 from "./services/backend-consumers/documents/document.service";
import * as i31 from "./services/backend-consumers/notifications/notification.service";
import * as i32 from "./authentication/auth-token.service";
import * as i33 from "./services/util/language/language.service";
import * as i34 from "@tc-core/util/helpers/common-helper.service";
import * as i35 from "./services/util/framework/dmc-query-params.service";
import * as i36 from "./services/util/core-services/root.service";
import * as i37 from "./services/util/system/dmc-local-storage.service";
import * as i38 from "./console-toggle.service";
import * as i39 from "./services/backend-consumers/setups/brand-setup-service";
import * as i40 from "./services/backend-consumers/setups/distribution-channel-setup-service";
import * as i41 from "./services/backend-consumers/job/job-service";
import * as i42 from "./services/user-management/user-management.service";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "lang"]], [[1, "dir", 0]], null, null, null, null)), i1.ɵprd(6144, null, i2.Directionality, null, [i2.Dir]), i1.ɵdid(2, 1196032, null, 0, i2.Dir, [], { dir: [0, "dir"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "tc-fab-wrapper", [], null, null, null, i3.View_FabWrapperComponent_0, i3.RenderType_FabWrapperComponent)), i1.ɵdid(4, 245760, null, 0, i4.FabWrapperComponent, [i5.UserJourneyManager, i6.EventManager, i1.ElementRef, i7.FocusHandlerService], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "tc-dmc-spinner-wrapper", [], null, null, null, i8.View_DMCSpinnerWrapperComponent_0, i8.RenderType_DMCSpinnerWrapperComponent)), i1.ɵdid(6, 245760, null, 0, i9.DMCSpinnerWrapperComponent, [i10.SpinnerService, i11.DataStoreService, i12.MatSnackBar], null, null), (_l()(), i1.ɵeld(7, 0, null, null, 1, "tc-notification-toast-wrapper", [], null, null, null, i13.View_NotificationToastWrapperComponent_0, i13.RenderType_NotificationToastWrapperComponent)), i1.ɵdid(8, 114688, null, 0, i14.NotificationToastWrapperComponent, [i15.NotificationToastService, i16.NotificationToastConfig], null, null), (_l()(), i1.ɵeld(9, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(10, 212992, null, 0, i17.RouterOutlet, [i17.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.langService.languageDirection; _ck(_v, 2, 0, currVal_1); _ck(_v, 4, 0); _ck(_v, 6, 0); _ck(_v, 8, 0); _ck(_v, 10, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2)._rawDir; _ck(_v, 0, 0, currVal_0); }); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "tc-root", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onResize($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 245760, null, 0, i18.AppComponent, [i19.DataHandlerService, i20.MasterDataRestService, i21.SupplierService, i22.AppService, i17.Router, i23.ScaffoldingHelper, i11.DataStoreService, i24.DMCCommon, i10.SpinnerService, i12.MatSnackBar, i25.TrsReservationManagementService, i26.ReservationManagementService, i27.ReservationV2ManagementService, i28.MasterDataHandlerService, i29.AccountingRuleService, i30.DocumentService, i17.ActivatedRoute, i31.NotificationService, i32.AuthTokenService, i33.LanguageService, i34.CommonHelper, i35.DMCQueryParamsService, i36.RootService, i37.DMCLocalStorageService, i38.ConsoleToggleService, i39.BrandSetupService, i40.DistributionChannelSetupService, i41.JobService, i42.UserManagementService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("tc-root", i18.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
