import { ConfigLoader } from '@tc-core/util/framework';
import { DMCQueryParamsService } from '../../util/framework/dmc-query-params.service';
import { RequestService } from '../../util/framework/request.service';
import { UrlPaths } from '../url-paths';
import * as i0 from "@angular/core";
import * as i1 from "@tc-core/util/framework/config-loader.service";
import * as i2 from "../../util/framework/request.service";
import * as i3 from "../../util/framework/dmc-query-params.service";
var CurrencyService = /** @class */ (function () {
    function CurrencyService(configLoader, requestService, queryParamsService) {
        this.configLoader = configLoader;
        this.requestService = requestService;
        this.queryParamsService = queryParamsService;
    }
    CurrencyService.prototype.getRows = function (criteria) {
        var reqPrams = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.get(UrlPaths.OP_DATA_URL_KEY, [UrlPaths.OP_DATA_VERSION, UrlPaths.CURRENCIES], reqPrams);
    };
    CurrencyService.prototype.deleteRow = function (code) {
        return this.requestService.delete(UrlPaths.OP_DATA_URL_KEY, [UrlPaths.OP_DATA_VERSION, UrlPaths.CURRENCIES, code]);
    };
    CurrencyService.prototype.saveRow = function (row) {
        return this.requestService.post(UrlPaths.OP_DATA_URL_KEY, [UrlPaths.OP_DATA_VERSION, UrlPaths.CURRENCIES], row);
    };
    CurrencyService.prototype.updateRow = function (code, row) {
        return this.requestService.put(UrlPaths.OP_DATA_URL_KEY, [UrlPaths.OP_DATA_VERSION, UrlPaths.CURRENCIES, code], row);
    };
    CurrencyService.prototype.getAllCurrencyCodes = function () {
        return this.requestService.get(UrlPaths.OP_DATA_URL_KEY, [UrlPaths.OP_DATA_VERSION, UrlPaths.CURRENCIES, UrlPaths.CURRENCY_CODES]);
    };
    CurrencyService.ngInjectableDef = i0.defineInjectable({ factory: function CurrencyService_Factory() { return new CurrencyService(i0.inject(i1.ConfigLoader), i0.inject(i2.RequestService), i0.inject(i3.DMCQueryParamsService)); }, token: CurrencyService, providedIn: "root" });
    return CurrencyService;
}());
export { CurrencyService };
