import * as tslib_1 from "tslib";
import { AfterViewInit, ChangeDetectorRef, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { MatSelectionList } from '@angular/material/list';
// import { TBXResponseWrapper } from '@tc-core/model/it/codegen/tbx/api/tbx-response-wrapper';
import { TcErrorType } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-error';
import { DialogModel } from '@tc-core/model/it/codegen/ui/framework/dialog-model';
import { LEVEL, ModalData } from '@tc-core/model/it/codegen/ui/framework/modal-data';
import { TC } from '@tc-core/util';
import { ConfigLoader, EventManager, FocusViewManager, UserJourneyManager } from '@tc-core/util/framework';
import { CommonHelper } from '@tc-core/util/helpers';
import { SpinnerService } from '@tc-core/util/ui';
import { DialogService } from '@tc/dialog/dialog.service';
import { FocusViewService } from '@tc/focus-view/focus-view.service';
import { ModalService } from '@tc/modal/modal.service';
import * as _ from 'lodash';
import * as moment from 'moment';
import { BehaviorSubject, of, Subscription } from 'rxjs';
import 'rxjs-compat/add/operator/filter';
import { catchError, map } from 'rxjs/operators';
import { AutoCompleteEditorComponent } from '../../../widgets/framework/ag-grid-custom-components/editors/auto-complete-editor/auto-complete-editor.component';
import { DropdownEditorComponent } from '../../../widgets/framework/ag-grid-custom-components/editors/dropdown-editor/dropdown-editor.component';
import { NoteEditorComponent } from '../../../widgets/framework/ag-grid-custom-components/editors/note-editor/note-editor.component';
import { CustomTextFilterComponent } from '../../../widgets/framework/ag-grid-custom-components/filters/custom-text-filter/custom-text-filter.component';
import { ActionsComponent } from '../../../widgets/framework/ag-grid-custom-components/renderers/actions/actions.component';
import { TemplateRendererComponent } from '../../../widgets/framework/ag-grid-custom-components/renderers/template-renderer/template-renderer.component';
import { TimeEditorRendererComponent } from '../../../widgets/framework/ag-grid-custom-components/renderers/time-editor-renderer/time-editor-renderer.component';
import { GridCommonUtil } from '../../../widgets/framework/ag-grid-custom-components/utils/grid-common-util';
import { AssignmentFocusViewComponent } from '../../assignment-focus-view/assignment-focus-view.component';
import { TCO } from '../../../constants';
import { DocumentCreationModalComponent } from '../../document-creation-modal/document-creation-modal.component';
import { DocumentCreationCriteria, DocumentFormat, DocumentType } from '../../../models/criteria/document-creation-criteria';
import { DocumentDataCriteria } from '../../../models/criteria/document-data-criteria';
import { GenericSearchCriteria } from '../../../models/criteria/generic-search-criteria';
import { ReservationSearchCriteria } from '../../../models/criteria/reservation-search-criteria';
import { SupplierSearchCriteria } from '../../../models/criteria/supplier-search-criteria';
import { DocumentCreationModel } from '../../../models/helper/document-creation-model';
import { AssignmentStatus, StatusCodes } from '../../../models/reservation/assignment-status';
import { GenTourItinerary } from '../../../models/reservation/generic/gen-tour-itinerary';
import { ServiceAssignment } from '../../../models/reservation/service-assignment';
import { GenResourceAllocationSummary } from '../../../models/summary/gen-resource-allocation-summary';
import { GenSupplierAllocationSummary } from '../../../models/summary/gen-supplier-allocation-summary';
import { NotesEditorComponent } from '../../notes-editor/notes-editor.component';
import { SearchCriteriaSaveModalComponent } from '../../reservation-management/search-criteria-save-modal/search-criteria-save-modal.component';
import { SelectServiceItemsComponent } from '../../select-service-items/select-service-items.component';
import { DocumentService } from '../../../services/backend-consumers/documents/document.service';
import { GenReservationManagementService } from '../../../services/backend-consumers/reservation-management-services/gen-reservation-management.service';
import { ReservationManagementService } from '../../../services/backend-consumers/reservation-management-services/reservation-management.service';
import { GenericResourceService } from '../../../services/backend-consumers/supplier-service/generic-resource.service';
import { SupplierService } from '../../../services/backend-consumers/supplier-service/supplier.service';
import { CostCalculationUtilsService } from '../../../services/business-utils/cost-calculation/cost-calculation-utils.service';
import { ServiceAssignmentUtilService } from '../../../services/business-utils/service-item-utils/service-assignment-util.service';
import { DMCCommon } from '../../../services/util/common/dmc-common';
import { DataKey, DataStoreService } from '../../../services/util/framework/data-store.service';
import { IdentifierService } from '../../../services/util/identifier/identifier.service';
import { GridColumnDefinitionProcessorService } from '../../../services/util/pre-processors/grid-column-definition-processor.service';
import { ResponseUtil } from '../../../services/util/response/response-util.service';
import { SupplierPriceBreakdownComponent } from '../../supplier-price-breakdown/supplier-price-breakdown.component';
import { GenQueueUtil } from './gen-queue-util';
var GenReservationSearchResultsComponent = /** @class */ (function () {
    function GenReservationSearchResultsComponent(em, configLoader, dataStore, spinnerService, modalService, dialogService, common, genReservationManagementService, reservationManagementService, cd, costCalculationUtil, userJourneyManager, focusViewManager, supplierService, genericResourceService, dataStoreService, columnDefinitionProcessorService, commonHelper, documentService, identifierService, focusViewService) {
        this.em = em;
        this.configLoader = configLoader;
        this.dataStore = dataStore;
        this.spinnerService = spinnerService;
        this.modalService = modalService;
        this.dialogService = dialogService;
        this.common = common;
        this.genReservationManagementService = genReservationManagementService;
        this.reservationManagementService = reservationManagementService;
        this.cd = cd;
        this.costCalculationUtil = costCalculationUtil;
        this.userJourneyManager = userJourneyManager;
        this.focusViewManager = focusViewManager;
        this.supplierService = supplierService;
        this.genericResourceService = genericResourceService;
        this.dataStoreService = dataStoreService;
        this.columnDefinitionProcessorService = columnDefinitionProcessorService;
        this.commonHelper = commonHelper;
        this.documentService = documentService;
        this.identifierService = identifierService;
        this.focusViewService = focusViewService;
        this.searchCriteria = new ReservationSearchCriteria();
        this.fetchedReservationsCount = 0;
        this.totalReservationsCount = 0;
        this.resultHeaderHeading = '';
        this.isExportEnable = true;
        this.searchFromSortingOrFiltering = false;
        this.sortFilterModelProgrammaticallyChanging = false;
        this.assignmentChangedTours = [];
        this.changesAvailableForSave = false;
        this.resourceTypes = [];
        this.selectedSupplierMap = new Map();
        this.buttonState = new BehaviorSubject([]);
        this.buttonStateObs = this.buttonState.asObservable();
        this.searchCriteriaSubscription = new Subscription();
        this.searchResultSubscription = new Subscription();
        this.moreSearchResultSubscription = new Subscription();
        this.finalSearchResultsSubscription = new Subscription();
        this.saveGenericSubscription = new Subscription();
        this.resourceTypesSubscription = new Subscription();
        this.modalCloseSubscription = new Subscription();
        this.subscribeOnSelectedServiceItemList = new Subscription();
        this.focusviewCloseSubject = new Subscription();
        // focus view assignments
        this.assignedSupplierSubscription = new Subscription();
        this.assignedResourceSubscription = new Subscription();
        this.isFocusViewAssignment = false;
        // *********************ag grid data*****************
        this.EMPTY_STRING = '--';
        this.overlayNoRowsTemplate = "<span class=\"ag-overlay-no-rows-center\">No Booking items</span>";
        this.statusCodes = StatusCodes;
        this.rowHeight = 42;
        this.columnDefs = [];
        this.colResizeDefault = '';
        this.PENDING = AssignmentStatus.PENDING;
        this.DMC_CONFIRM = AssignmentStatus.DMC_CONFIRM;
        this.REQUEST_EXPIRED = AssignmentStatus.REQUEST_EXPIRED;
        this.SUPPLIER_CONFIRMED = AssignmentStatus.SUPPLIER_CONFIRMED;
        this.SUPPLIER_REJECTED = AssignmentStatus.SUPPLIER_REJECTED;
        this.BOOKING_UPDATE = AssignmentStatus.BOOKING_UPDATE;
        this.INACTIVE = AssignmentStatus.INACTIVE;
        this.INACTIVE_NOTIFY = AssignmentStatus.INACTIVE_NOTIFY;
        this.NO_SHOW = AssignmentStatus.NO_SHOW;
        this.COMPLETED = AssignmentStatus.COMPLETED;
        this.defaultColDef = {
            resizable: true,
            singleClickEdit: false
        };
        this.rowClassRules = {
            'tc-ag-grid-row--error': function (params) { return params.data.isInvalid; }
        };
        this.gridOptions = {
            getNodeChildDetails: function (parent) {
                if (parent === null) {
                    return null;
                }
                if (parent.itineraryItems) { // itineraryItems of tour
                    return {
                        group: true,
                        children: parent.itineraryItems,
                        expanded: true
                    };
                }
                else if (parent.assignments) { // assignments of itineraryItem
                    return {
                        group: true,
                        children: parent.assignments,
                        expanded: true
                    };
                }
                else {
                    return null;
                }
            },
            rowSelection: 'multiple',
            pagination: true,
            resizable: true,
            floatingFilter: false
        };
        this.paginationPageSize = 10;
        this.getRowHeight = function (params) {
            if (params && params.data) {
                if (params.data.itineraryItems) {
                    return 0;
                }
                else if (params.data.assignments) {
                    return 0;
                }
                else {
                    return this.rowHeight;
                }
            }
        }.bind(this);
        this.getRowStyle = function (params) {
            if (params.data.itineraryItems) {
                return { border: 'none' };
            }
            else if (params.data.assignments) {
                return { border: 'none' };
            }
            else {
                return '';
            }
        }.bind(this);
        this.frameworkComponents = {
            templateRenderer: TemplateRendererComponent,
            actionRenderer: ActionsComponent,
            autoCompleteEditor: AutoCompleteEditorComponent,
            dropDownEditor: DropdownEditorComponent,
            noteEditor: NoteEditorComponent,
            customTextFilter: CustomTextFilterComponent,
            timeEditorRenderer: TimeEditorRendererComponent,
        };
        this.saveButtonEnabled = false;
        this.changedAssignments = [];
        // used in ag-grid value setter
        this.onSetSupplierItem = function (params) {
            var assignment = params.data;
            var supplierItem = params.newValue;
            assignment.assignStatus = AssignmentStatus.PENDING;
            if (supplierItem) {
                if (!supplierItem.nullSupplierItemForClearing) {
                    assignment.supplierItem = supplierItem.code;
                    assignment.supplierItemName = supplierItem.name;
                    this.setSupplierCostForSelectedType(params);
                }
                else {
                    assignment.supplierItem = '';
                    assignment.supplierItemName = '';
                }
            }
            this.addParentTourOfAssignmentToChangedSet(params);
            return true;
        };
        // used in ag grid col def by method reference
        this.onSetSupplier = function (params) {
            var assignment = params.data;
            var supplier = params.newValue;
            this.setSupplierToAssignment(assignment, supplier);
            this.mapServiceItemIdList(params, assignment);
            // this.setGridData();
            this.addParentTourOfAssignmentToChangedSet(params);
            return true;
        };
        // used in ag-grid value-setter
        this.onSetTotalCost = function (params) {
            params.data.jobCost = params.newValue;
            this.addParentTourOfAssignmentToChangedSet(params);
            this.enableSaveButtonValidateFields();
        };
        // used in ag-grid value-setter
        this.onSetStartTime = function (params) {
            if (params.newValue && new Date(params.newValue) instanceof Date) {
                var date = params.node.parent.parent.data.serviceDate;
                // params.data.startTime = new Date(date + 'T' + params.newValue);
                params.data.startTime = moment(new Date(date + 'T' + params.newValue)).format('HH:mm');
                params.data.assignStatus = AssignmentStatus.PENDING;
                this.addParentTourOfAssignmentToChangedSet(params);
                this.enableSaveButtonValidateFields();
            }
        };
        // used in ag-grid value-setter
        this.onSetEndTime = function (params) {
            if (params.newValue && new Date(params.newValue) instanceof Date) {
                var date = params.node.parent.parent.data.serviceDate;
                params.data.endTime = moment(new Date(date + 'T' + params.newValue)).format('HH:mm');
                params.data.assignStatus = AssignmentStatus.PENDING;
                this.addParentTourOfAssignmentToChangedSet(params);
                this.enableSaveButtonValidateFields();
            }
        };
        // used in ag grid col def by method reference
        this.onSetResource = function (params) {
            var assignment = params.data;
            var resource = params.newValue;
            this.setResourceToAssignment(assignment, resource);
            this.mapServiceItemIdList(params, assignment);
            this.addParentTourOfAssignmentToChangedSet(params);
            return true;
        };
        // used in ag grid col def by method reference
        this.setMaxRemainingAdults = function (params) {
            if (params.newValue >= 0 || params.newValue === '') {
                var value = +params.newValue;
                var tourItem = params.node.parent.data;
                if (tourItem) {
                    _.set(params.data, params.colDef.field, 0);
                    var adults = tourItem.adults;
                    var allocatedAdults = this.getAllocatedAdults(tourItem);
                    var remaining = adults - allocatedAdults;
                    if (value <= remaining) {
                        _.set(params.data, params.colDef.field, value);
                    }
                    else if (remaining > 0) {
                        _.set(params.data, params.colDef.field, remaining);
                    }
                    else {
                        _.set(params.data, params.colDef.field, 0);
                    }
                    // this.setGridData();
                    this.updateJobCost(params.data);
                    this.addParentTourOfAssignmentToChangedSet(params);
                }
            }
            return true;
        };
        // used in ag grid col def by method reference
        this.setMaxRemainingTeens = function (params) {
            if (params.newValue >= 0 || params.newValue === '') {
                var value = +params.newValue;
                var tourItem = params.node.parent.data;
                if (tourItem) {
                    _.set(params.data, params.colDef.field, 0);
                    var teens = tourItem.teens;
                    var allocatedTeens = this.getAllocatedTeens(tourItem);
                    var remaining = teens - allocatedTeens;
                    if (value <= remaining) {
                        _.set(params.data, params.colDef.field, value);
                    }
                    else if (remaining > 0) {
                        _.set(params.data, params.colDef.field, remaining);
                    }
                    else {
                        _.set(params.data, params.colDef.field, 0);
                    }
                    // this.setGridData();
                    this.updateJobCost(params.data);
                    this.addParentTourOfAssignmentToChangedSet(params);
                }
            }
            return false;
        };
        // used in ag grid col def by method reference
        this.setMaxRemainingChildren = function (params) {
            if (params.newValue >= 0 || params.newValue === '') {
                var value = +params.newValue;
                var tourItem = params.node.parent.data;
                if (tourItem) {
                    _.set(params.data, params.colDef.field, 0);
                    var children = tourItem.children;
                    var allocatedChildren = this.getAllocatedChildren(tourItem);
                    var remaining = children - allocatedChildren;
                    if (value <= remaining) {
                        _.set(params.data, params.colDef.field, value);
                    }
                    else if (remaining > 0) {
                        _.set(params.data, params.colDef.field, remaining);
                    }
                    else {
                        _.set(params.data, params.colDef.field, 0);
                    }
                    // this.setGridData();
                    this.updateJobCost(params.data);
                    this.addParentTourOfAssignmentToChangedSet(params);
                }
            }
            return true;
        };
        // used in ag grid col def by method reference
        this.setMaxRemainingInfants = function (params) {
            if (params.newValue >= 0 || params.newValue === '') {
                var value = +params.newValue;
                var tourItem = params.node.parent.data;
                if (tourItem) {
                    _.set(params.data, params.colDef.field, 0);
                    var infants = tourItem.infants;
                    var allocatedInfants = this.getAllocatedInfants(tourItem);
                    var remaining = infants - allocatedInfants;
                    if (value <= remaining) {
                        _.set(params.data, params.colDef.field, value);
                    }
                    else if (remaining > 0) {
                        _.set(params.data, params.colDef.field, remaining);
                    }
                    else {
                        _.set(params.data, params.colDef.field, 0);
                    }
                    // this.setGridData();
                    this.updateJobCost(params.data);
                    this.addParentTourOfAssignmentToChangedSet(params);
                }
            }
            return false;
        };
        // used in ag grid col def by method reference
        this.getAllocatedAdults = function (tourItem) {
            var allocatedAdults = 0;
            if (GenQueueUtil.isTourItemRow_(tourItem)) {
                if (tourItem.assignments) {
                    for (var i = 0; i < tourItem.assignments.length; i++) {
                        var assignment = tourItem.assignments[i];
                        if (!isNaN(assignment.adults)) {
                            allocatedAdults += Number(assignment.adults);
                        }
                    }
                }
            }
            return allocatedAdults;
        };
        // used in ag grid col def by method reference
        this.getAllocatedTeens = function (tourItem) {
            var allocatedTeens = 0;
            if (GenQueueUtil.isTourItemRow_(tourItem)) {
                if (tourItem.assignments) {
                    for (var i = 0; i < tourItem.assignments.length; i++) {
                        var assignment = tourItem.assignments[i];
                        if (!isNaN(assignment.teens)) {
                            allocatedTeens += Number(assignment.teens);
                        }
                    }
                }
            }
            return allocatedTeens;
        };
        // used in ag grid col def by method reference
        this.getAllocatedChildren = function (tourItem) {
            var allocatedChildren = 0;
            if (GenQueueUtil.isTourItemRow_(tourItem)) {
                if (tourItem.assignments) {
                    for (var i = 0; i < tourItem.assignments.length; i++) {
                        var assignment = tourItem.assignments[i];
                        if (!isNaN(assignment.children)) {
                            allocatedChildren += Number(assignment.children);
                        }
                    }
                }
            }
            return allocatedChildren;
        };
        // used in ag grid col def by method reference
        this.getAllocatedInfants = function (tourItem) {
            var allocatedInfants = 0;
            if (GenQueueUtil.isTourItemRow_(tourItem)) {
                if (tourItem.assignments) {
                    for (var i = 0; i < tourItem.assignments.length; i++) {
                        var assignment = tourItem.assignments[i];
                        if (!isNaN(assignment.infants)) {
                            allocatedInfants += Number(assignment.infants);
                        }
                    }
                }
            }
            return allocatedInfants;
        };
        this.isDeleteAssignmentRestricted = function (params) {
            var assignment = params.data;
            return ServiceAssignment.isRestrictedEdit(assignment);
        };
        this.isAddAssignmentRestricted = function (params) {
            if (params.node.parent.data) {
                var serviceItems = params.node.parent.data.serviceItems ?
                    params.node.parent.data.serviceItems :
                    [];
                var previousAssignments = params.node.parent.data.assignments ? params.node.parent.data.assignments : [];
                if (previousAssignments && previousAssignments.length > 0) {
                    var alreadySelectedItems = previousAssignments && previousAssignments.length > 0 ?
                        this.getAlreadyAddedItems(previousAssignments) :
                        [];
                    if (alreadySelectedItems && alreadySelectedItems.length > 0) {
                        return (serviceItems.length === alreadySelectedItems.length || serviceItems.length <
                            alreadySelectedItems.length);
                    }
                }
                return serviceItems.length < 2;
            }
            return !params.data.supplierId;
        };
        // not using. allow to see always at least in readonly mode
        this.isPassengerAllocationRestricted = function (params) {
            var assignment = params.data;
            return ServiceAssignment.isRestrictedEdit(assignment);
        };
        // *********************allocation************************** //
        this.isRowSelectable = function (params) {
            return GenQueueUtil.isAssignmentRow(params);
        };
        this.isEditableRow = function (params) {
            return !(params && params.data && ServiceAssignment.isRestrictedEdit(params.data));
        };
        this.isNotesEditable = function (dataRow) {
            return !(dataRow && ServiceAssignment.isRestrictedEdit(dataRow));
        };
        this.isNoteActionsRestricted = function (dataRow) {
            return (dataRow && ServiceAssignment.isRestrictedEdit(dataRow));
        };
        /***
         * intialize allocation search focus view operations
         * @param params
         */
        this.onAssignment = function (params) {
            var selectedIndex_ = 0;
            this.paramsOfFocusView = params;
            var selectedServiceItems = [];
            var groupData;
            var passengerCount = 0;
            var assignment = params.data;
            if (!(assignment && assignment.serviceItemIdList && assignment.serviceItemIdList.length > 0)) {
                this.mapServiceItemIdList(params, assignment);
            }
            if (params && params.node && params.node.parent && params.node.parent.data && params.node.parent.data.serviceItems && params.node.parent.data.serviceItems.length > 0) {
                var serviceItems = params.node.parent.data.serviceItems;
                var serviceItemIds_1 = params.data.serviceItemIdList;
                serviceItems.forEach(function (item) {
                    serviceItemIds_1.forEach(function (id) {
                        if (item.serviceItemId == id) {
                            selectedServiceItems.push(item);
                            passengerCount = passengerCount + item.servicePassengers.length;
                        }
                    });
                });
                groupData = params.node.parent.data;
                if (ServiceAssignment.isRestrictedEdit(assignment)) {
                    var invalidAttemptWarning = new DialogModel(true, LEVEL.WARNING, 'Invalid Attempt', 'Cannot change assignment', true, 2000);
                    invalidAttemptWarning.disableClose = true;
                    this.confirmDialog(invalidAttemptWarning);
                }
                else {
                    var serviceTimeRange = groupData.jobPeriod;
                    var categoryCode = groupData.categoryCode;
                    var bookingReferenceIdStr_1 = '';
                    selectedServiceItems.forEach(function (item, index) {
                        if (index === 0) {
                            bookingReferenceIdStr_1 += item.bookingReferenceId;
                        }
                        else {
                            bookingReferenceIdStr_1 += ',' + item.bookingReferenceId;
                        }
                    });
                    var secondaryHeader = bookingReferenceIdStr_1 + ' | Pax: ' + passengerCount + ' | ' +
                        serviceTimeRange + ' | ' + categoryCode;
                    var input = {
                        isGenResourceAssignment: true,
                        selectedIndex: 0,
                        genTourItem: groupData
                    };
                    this.openResourceAssignmentView(input, secondaryHeader);
                }
            }
        };
        // used in ag grid col def by method reference
        this.isOnAssignmentRestricted = function (params) {
            var assignment = params.data;
            return ServiceAssignment.isRestrictedEdit(assignment);
        };
        // used in ag grid col def by method reference
        this.onDeleteAssignment = function (params) {
            console.log('delete' + params);
            if (params && params.data && params.node && params.node.parent && params.node.parent.data &&
                params.node.parent.data.assignments && params.node.parent.data.assignments.indexOf(params.data) !=
                -1) {
                params.node.parent.data.assignments.splice(params.node.parent.data.assignments.indexOf(params.data), 1);
                if (!params.node.parent.data.assignments.length) {
                    params.node.parent.data.assignments.push(this.getEmptyAssignment());
                }
                this.gridApi.setRowData(this.genericGroupResults);
            }
            this.addParentTourOfAssignmentToChangedSet(params);
        };
        // used in ag grid col def by method reference
        this.onAddAssignment = function (params) {
            console.log('add' + params);
            if (params && params.node && params.node.parent && params.node.parent.data &&
                params.node.parent.data.assignments) {
                // params.node.parent.data['assignments'].push(this.getEmptyAssignment());
                var serviceItems = params.node.parent.data ? params.node.parent.data.serviceItems : [];
                var allOtherAssignmentsInItem = params.node.parent.data.assignments ?
                    params.node.parent.data.assignments :
                    [];
                var alreadySelectedItems_1 = allOtherAssignmentsInItem && allOtherAssignmentsInItem.length >
                    0 ?
                    this.getAlreadyAddedItems(allOtherAssignmentsInItem) :
                    [];
                var remainingItems = serviceItems.filter(function (item) {
                    return !alreadySelectedItems_1.find(function (i) { return i === item.serviceItemId; });
                });
                var newAssignment = this.getEmptyAssignment();
                newAssignment.tempAssignmentId = 'Temp_' + allOtherAssignmentsInItem.length;
                newAssignment.serviceItemIdList = remainingItems.map(function (i) { return i.serviceItemId; });
                params.node.parent.data.assignments.splice(params.node.parent.data.assignments.indexOf(params.data) + 1, 0, newAssignment);
                this.gridApi.setRowData(this.genericGroupResults);
            }
        };
        this.onSelectServiceItems = function (params) {
            console.log('popup' + params);
            this.openItemSelectionModal(params);
        };
        // get resourceType/SupplierItem relevant to search
        this.getSupplierItems = function (params, rowData, text) {
            // if supplier exists - return only supplierItems
            if (rowData && rowData.supplierId) {
                return this.searchSupplierItem(params, rowData, text);
            }
            else {
                return this.getResourceTypes(text);
            }
        };
        this.processStartTime = function (params) {
            var assignment = params.data;
            if (assignment.startTime) {
                return assignment.startTime.toString().split(' ')[4];
            }
            return assignment.startTime;
        };
        this.processEndTime = function (params) {
            var assignment = params.data;
            if (assignment.endTime) {
                return assignment.endTime.toString().split(' ')[4];
            }
            return assignment.endTime;
        };
        this.processSupplierItem = function (params) {
            return this.getSupplierItemName(params);
        };
        // used in ag grid col def by method reference
        this.getSuppliers = function (params, rowData, text) {
            return this.onSupplierTextChange(params, rowData, text);
        };
        // used in ag grid col def by method reference
        this.getResources = function (params, rowData, text) {
            return this.onResourceTextChange(params, rowData, text);
        };
    }
    GenReservationSearchResultsComponent.prototype.ngOnInit = function () {
        this.tPagination = this.configLoader.configurations.get(TCO.CONF.CONF_PAGINATION_CONFIG).GEN_RESERVATION_SEARCH;
        this.paginationPageSize = this.tPagination.defaultPageResultCount;
        this.resourceCategoryIcons = this.configLoader.configurations.get(TCO.CONF.CONF_GENERIC_QUEUE_ICONS).RESOURCE_CATEGORY_ICONS;
        this.colDefConfig = this.configLoader.configurations.get(TCO.CONF.CONF_GENERIC_QUEUE_COL_DEF);
        this.subscribeSearchCriteria();
        this.subscribeSearchResults();
        this.subscribeOnMoreSearchResults();
        this.subscribeToAllocationSelection();
        this.subscribeSearchResultList();
        this.addQuickActionEvents();
        this.getAllResourceTypes();
    };
    // get all resource types for supplier item
    GenReservationSearchResultsComponent.prototype.getAllResourceTypes = function () {
        var _this = this;
        this.resourceTypesSubscription = this.dataStore.get(DataKey.resourceTypes).subscribe(function (response) {
            if (response != null) {
                _this.resourceTypesSubscription.unsubscribe();
                _this.resourceTypes = ResponseUtil.getArray(response);
            }
        });
    };
    GenReservationSearchResultsComponent.prototype.addQuickActionEvents = function () {
        var _this = this;
        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'GENERATE_DOCUMENTS').subscribe(function (e) {
            _this.onGenerateDocumentsClick();
        });
        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'EXPORT').subscribe(function (e) {
            _this.export('', false, true);
        });
        this.em.addEvent(TCO.CONF.CONF_EVENT_MANAGER, 'SAVE_CRITERIA').subscribe(function (e) {
            _this.onSaveSearchCriteriaClick();
        });
        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'SEND_MANIFESTS').subscribe(function (e) {
            _this.sendManifests();
        });
        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'TOUR_LIST').subscribe(function (e) {
            _this.getTourList('', false, true);
        });
    };
    GenReservationSearchResultsComponent.prototype.subscribeSearchCriteria = function () {
        var _this = this;
        this.searchCriteriaSubscription = this.dataStore.get(DataKey.genReservationSearchCriteria).subscribe(function (data) {
            if (data !== null) {
                _this.searchCriteria = data;
            }
        });
    };
    GenReservationSearchResultsComponent.prototype.subscribeSearchResults = function () {
        var _this = this;
        this.searchResultSubscription = this.dataStore.get(DataKey.genReservationSearchResultsFromService)
            // this.searchResultSubscription = this.genReservationManagementService.searchReservationsFromMock()
            .subscribe(function (data) {
            _this.totalReservationsCount = 0;
            if (data) {
                _this.totalReservationsCount = ResponseUtil.getTotalCount(data);
                var dataArray = ResponseUtil.getDataArray(data);
                _this.dataStore.set(DataKey.genReservationSearchResults, dataArray, true);
            }
        });
    };
    GenReservationSearchResultsComponent.prototype.subscribeToAllocationSelection = function () {
        var _this = this;
        this.assignedSupplierSubscription = this.dataStore.get(DataKey.assignedGenResourceSupp).subscribe(function (data) {
            if (data) {
                _this.dataStore.get(DataKey.assignedSupplier).subscribe(function (dataSupp) {
                    if (dataSupp) {
                        _this.paramsOfFocusView.newValue = dataSupp;
                        _this.isFocusViewAssignment = true;
                        _this.isFocusViewAssignmentCategory = dataSupp.resourceCostSummaries[0].resourceType;
                        _this.onSetSupplier(_this.paramsOfFocusView);
                    }
                });
            }
        });
        this.assignedResourceSubscription = this.dataStore.get(DataKey.assignedResource).subscribe(function (data) {
            if (data) {
                _this.paramsOfFocusView.newValue = data;
                _this.isFocusViewAssignment = true;
                _this.isFocusViewAssignmentCategory = data.driverSupplier.resourceType;
                _this.onSetResource(_this.paramsOfFocusView);
            }
        });
    };
    GenReservationSearchResultsComponent.prototype.subscribeOnMoreSearchResults = function () {
        var _this = this;
        this.moreSearchResultSubscription = this.dataStore.get(DataKey.genReservationSearchResultsFromServiceOnMore)
            .subscribe(function (data) {
            _this.totalReservationsCount = 0;
            if (data) {
                _this.totalReservationsCount = ResponseUtil.getTotalCount(data);
                var dataArray = ResponseUtil.getDataArray(data);
                dataArray = _this.genericGroupResults.concat(dataArray);
                _this.dataStore.set(DataKey.genReservationSearchResults, dataArray, true);
            }
        });
    };
    GenReservationSearchResultsComponent.prototype.subscribeSearchResultList = function () {
        var _this = this;
        this.fetchedReservationsCount = 0;
        this.finalSearchResultsSubscription = this.dataStore.get(DataKey.genReservationSearchResults)
            .subscribe(function (data) {
            _this.genericGroupResults = [];
            if (data != null) {
                _this.spinnerService.hide();
                // **** result counts *** //
                _this.genericGroupResults = data;
                _this.fetchedReservationsCount = _this.genericGroupResults.length;
                // **** custom buttons configuring *** //
                _this.paginatorLoaded = true;
                _this.disableAllPaginatorButtons();
                if (_this.fetchedReservationsCount <
                    _this.totalReservationsCount) {
                    _this.enableMoreButton();
                }
                else if (_this.fetchedReservationsCount >=
                    _this.totalReservationsCount) {
                    _this.disableMoreButton();
                }
                // **** export button configuring *** //
                if (_this.fetchedReservationsCount > 0) {
                    _this.enableExportButton();
                    _this.userJourneyManager.enableQuickActions([
                        'TRIGGER_EXPORT_FILE'
                    ]);
                }
                // **** setup result header *** //
                _this.setupResultHeader(_this.totalReservationsCount);
            }
            else {
                // **** clear previous data*** //
                _this.genericGroupResults = [];
                _this.resultHeaderHeading = '';
                _this.disableAllPaginatorButtons();
            }
            // **** set genericGroupResults to grid*** //
            _this.setGridData();
        });
    };
    GenReservationSearchResultsComponent.prototype.setupResultHeader = function (count) {
        this.resultHeaderHeading = count + ' result' +
            (count > 1 ? 's' : '') + ' found.';
    };
    GenReservationSearchResultsComponent.prototype.enableExportButton = function () {
        this.userJourneyManager.canProceed.next(true);
        this.focusViewManager.canProceed.next(true);
        this.isExportEnable = true;
    };
    GenReservationSearchResultsComponent.prototype.export = function (docName, persist, download) {
        this.spinnerService.show();
        var documentCreationCriteria = new DocumentCreationCriteria();
        documentCreationCriteria.documentFormat = DocumentFormat.excel;
        documentCreationCriteria.documentType = DocumentType.genExportReport;
        documentCreationCriteria.documentName = docName;
        documentCreationCriteria.download = download;
        documentCreationCriteria.persist = persist;
        var documentDataCriteria = new DocumentDataCriteria();
        this.createDocSearchCriteria(documentDataCriteria);
        documentDataCriteria.serviceType = 'GEN';
        this.documentService.createAndDownloadDocument(documentCreationCriteria, documentDataCriteria);
    };
    GenReservationSearchResultsComponent.prototype.onSaveSearchCriteriaClick = function () {
        var _this = this;
        this.dataStore.set(DataKey.popupClose, null);
        var dataObject = new ModalData(LEVEL.SUCCESS, 'Save Criteria', null, null, 'modal-basic--alt modal-basic--fixed-size', SearchCriteriaSaveModalComponent, { searchCriteria: this.searchCriteria });
        var secondaryHeader = '';
        dataObject.secondaryHeader = secondaryHeader;
        this.confirmModal(dataObject);
        this.dataStore.get(DataKey.popupClose).subscribe(function (data) {
            if (data != null) {
                _this.closeModal();
            }
        });
    };
    GenReservationSearchResultsComponent.prototype.confirmModal = function (data) {
        this.modalService.confirm(data).subscribe(function (res) {
        });
    };
    GenReservationSearchResultsComponent.prototype.closeModal = function () {
        if (this.modalService.close()) {
            this.modalService.close().subscribe(function (res) {
            });
        }
    };
    // *********************ag grid implementation************************** //
    GenReservationSearchResultsComponent.prototype.setGridData = function () {
        var _this = this;
        if (this.gridApi) {
            this.preProcessDataForGrid(this.genericGroupResults);
            this.gridApi.setRowData(this.genericGroupResults);
            // clear grid sort filter marks on search from criteria component
            if (!this.searchFromSortingOrFiltering) {
                this.sortFilterModelProgrammaticallyChanging = true;
                this.gridApi.setSortModel(null);
                this.gridApi.setFilterModel(null);
            }
            else {
                this.searchFromSortingOrFiltering = false;
            }
            this.paginatorLoaded = true;
        }
        else {
            setTimeout(function () { return _this.setGridData(); }, 0);
        }
    };
    GenReservationSearchResultsComponent.prototype.preProcessDataForGrid = function (genTourItineraries) {
        var e_1, _a, e_2, _b;
        try {
            for (var genTourItineraries_1 = tslib_1.__values(genTourItineraries), genTourItineraries_1_1 = genTourItineraries_1.next(); !genTourItineraries_1_1.done; genTourItineraries_1_1 = genTourItineraries_1.next()) {
                var genTourItinerary = genTourItineraries_1_1.value;
                try {
                    for (var _c = tslib_1.__values(genTourItinerary.itineraryItems), _d = _c.next(); !_d.done; _d = _c.next()) {
                        var itineraryItem = _d.value;
                        if (!itineraryItem.assignments || itineraryItem.assignments.length === 0) {
                            itineraryItem.assignments = [this.getEmptyAssignment()];
                        }
                    }
                }
                catch (e_2_1) { e_2 = { error: e_2_1 }; }
                finally {
                    try {
                        if (_d && !_d.done && (_b = _c.return)) _b.call(_c);
                    }
                    finally { if (e_2) throw e_2.error; }
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (genTourItineraries_1_1 && !genTourItineraries_1_1.done && (_a = genTourItineraries_1.return)) _a.call(genTourItineraries_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
    };
    GenReservationSearchResultsComponent.prototype.onGridReady = function (params) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.gridApi.setGroupHeaderHeight(20);
        this.gridApi.setGroupHeaderHeight(20);
        this.gridApi.setHeaderHeight(35);
        // this.gridApi.setRowHeight(40);
        // this.gridApi.groupSuppressBlankHeader(true);
        // this.gridApi.sizeColumnsToFit();
        // window.addEventListener('resize', function () {
        //   setTimeout(function () {
        //     // params.api.sizeColumnsToFit();
        //   });
        // });
        this.autoSizeAll();
        // this.gridApi.sizeColumnsToFit();
    };
    GenReservationSearchResultsComponent.prototype.ngAfterViewInit = function () {
        this.setColumnDefs();
    };
    GenReservationSearchResultsComponent.prototype.setColumnDefs = function () {
        var colDefConfig = JSON.parse(JSON.stringify(this.colDefConfig));
        this.columnDefs = this.columnDefinitionProcessorService.createColumnDefs(colDefConfig, this);
        this.cd.detectChanges();
    };
    GenReservationSearchResultsComponent.prototype.autoSizeAll = function () {
        var allColumnIds = [];
        if (this.gridColumnApi) {
            this.gridColumnApi.getAllColumns().forEach(function (column) {
                allColumnIds.push(column.colId);
            });
            this.gridColumnApi.autoSizeColumns(allColumnIds);
        }
    };
    GenReservationSearchResultsComponent.prototype.onFilterChanged = function ($event) {
        var _this = this;
        if (!this.sortFilterModelProgrammaticallyChanging) {
            this.searchGenericGroupResults();
            this.searchFromSortingOrFiltering = true;
        }
        else {
            setTimeout(function () { return _this.sortFilterModelProgrammaticallyChanging = false; }, 100);
        }
    };
    GenReservationSearchResultsComponent.prototype.onSortChanged = function ($event) {
        var _this = this;
        if (!this.sortFilterModelProgrammaticallyChanging) {
            this.searchGenericGroupResults();
            this.searchFromSortingOrFiltering = true;
        }
        else {
            setTimeout(function () { return _this.sortFilterModelProgrammaticallyChanging = false; }, 100);
        }
    };
    GenReservationSearchResultsComponent.prototype.onClickColOption = function (event) {
        event.stopPropagation();
    };
    GenReservationSearchResultsComponent.prototype.onSelectionChange = function (event) {
        if (event && event.option) {
            var option = event.option.value;
            var selected = event.option.selected;
            if (this.gridColumnApi) {
                this.gridColumnApi.setColumnVisible(option, selected);
                // this.gridApi.sizeColumnsToFit();
                this.autoSizeAll();
            }
        }
    };
    GenReservationSearchResultsComponent.prototype.onRestoreClick = function () {
        if (this.gridColumnApi) {
            this.gridColumnApi.resetColumnState();
            this.colSelector.selectAll();
        }
    };
    GenReservationSearchResultsComponent.prototype.addFilterModelToCriteria = function () {
        var filterModelArray = [];
        var filterModel = this.gridApi.getFilterModel();
        Object.keys(filterModel).forEach(function (key) {
            filterModel[key].colId = key;
            filterModelArray.push(filterModel[key]);
        });
        this.searchCriteria.gridFiltering = JSON.stringify(filterModelArray);
    };
    GenReservationSearchResultsComponent.prototype.addSortModelToCriteria = function () {
        var sortModel = this.gridApi.getSortModel();
        if (sortModel && sortModel.length > 0) {
            this.searchCriteria.gridSorting = JSON.stringify(sortModel);
        }
        else {
            this.searchCriteria.gridSorting = '[{\'colId\':\'serviceDate\',\'sort\':\'asc\'},{\'colId\':\'tourItemName\',\'sort\':\'asc\'},{\'colId\':\'assignmentId\',\'sort\':\'asc\'}]';
        }
    };
    GenReservationSearchResultsComponent.prototype.onColumnGroupOpen = function ($event) {
        console.log($event);
    };
    GenReservationSearchResultsComponent.prototype.getRelatedIcon = function (row) {
        if (row && row.categoryCode) {
            if (this.resourceCategoryIcons) {
                if (this.resourceCategoryIcons[row.categoryCode]) {
                    return this.resourceCategoryIcons[row.categoryCode];
                }
                else {
                    return this.resourceCategoryIcons.default;
                }
            }
        }
    };
    GenReservationSearchResultsComponent.prototype.getEmptyAssignment = function () {
        return new ServiceAssignment();
    };
    GenReservationSearchResultsComponent.prototype.getTotalCost = function (params) {
        if (GenQueueUtil.isAssignmentRow(params)) {
            return this.costCalculationUtil.getGenSupplierTotalCost(params.data);
        }
        else {
            return 0;
        }
    };
    GenReservationSearchResultsComponent.prototype.onClickGroupNote = function () {
        console.log('group clicked');
        console.log();
    };
    GenReservationSearchResultsComponent.prototype.onClickMore = function () {
        if (this.tPagination && this.tPagination.defaultPageResultCount && this.tPagination.fetchingPages) {
            this.searchCriteria.size = this.tPagination.fetchingPages * this.tPagination.defaultPageResultCount;
            this.searchCriteria.start = this.fetchedReservationsCount;
            this.searchOnMore(this.searchCriteria);
        }
    };
    GenReservationSearchResultsComponent.prototype.searchOnMore = function (searchCriteria) {
        this.dataStore.set(DataKey.genReservationSearchResultsFromServiceOnMore, null, true);
        this.genReservationManagementService.searchReservationsOnMore(this.searchCriteria);
    };
    GenReservationSearchResultsComponent.prototype.setSupplierCostForSelectedType = function (params) {
        var assignment = params.data;
        if (this.selectedSupplierMap) {
            var resourceCosts = this.selectedSupplierMap.get(params.data.supplierId);
            if (resourceCosts) {
                var selectedCost = resourceCosts.find(function (value) { return value.resourceType === params.newValue.code; });
                if (selectedCost) {
                    assignment.unitPrice = selectedCost.unitFare;
                    assignment.adultPrice = selectedCost.adultFare;
                    assignment.teenPrice = selectedCost.teenFare;
                    assignment.childPrice = selectedCost.childFare;
                    assignment.infantPrice = selectedCost.infantFare;
                    assignment.currency = selectedCost.currency;
                }
            }
            this.clearResource(assignment);
            this.updateJobCost(assignment);
        }
    };
    GenReservationSearchResultsComponent.prototype.mapServiceItemIdList = function (params, assignment) {
        if (params.node.parent && params.node.parent.data.serviceItems) {
            var serviceItems = params.node.parent.data.serviceItems;
            // check for previous assignments
            var previousAssignments = params.node.parent.data.assignments;
            var alreadyAddedItems = [];
            previousAssignments = previousAssignments.filter(function (value) { return value.tempAssignmentId !=
                params.data.tempAssignmentId; });
            // get already added service items to disable from the new assignment popup
            if (previousAssignments && previousAssignments.length > 0) {
                alreadyAddedItems = this.getAlreadyAddedItems(previousAssignments);
            }
            assignment.assignStatus = AssignmentStatus.PENDING;
            assignment.serviceItemIdList = assignment.serviceItemIdList ? assignment.serviceItemIdList : [];
            for (var i = 0; i < serviceItems.length; i++) {
                if (!alreadyAddedItems.includes(serviceItems[i].serviceItemId) &&
                    !assignment.serviceItemIdList.includes(serviceItems[i].serviceItemId)) {
                    // set the pax count for each assignment
                    if (i == 0) {
                        assignment.adults = serviceItems[i].adult;
                        assignment.children = serviceItems[i].child;
                        assignment.infants = serviceItems[i].infant;
                    }
                    else {
                        assignment.adults += serviceItems[i].adult;
                        assignment.children += serviceItems[i].child;
                        assignment.infants += serviceItems[i].infant;
                    }
                    assignment.serviceItemIdList.push(serviceItems[i].serviceItemId);
                }
            }
            this.refreshDataGrid();
        }
    };
    GenReservationSearchResultsComponent.prototype.addParentTourOfAssignmentToChangedSet = function (params) {
        var _this = this;
        var tour = params.node.parent.parent.data;
        this.changesAvailableForSave = true;
        if (tour) {
            var assignments = [];
            if (params.newValue) {
                assignments = params.newValue.assignments;
            }
            if (this.isFocusViewAssignment && tour.itineraryItems.length > 0 && assignments && assignments.length > 0) {
                assignments.forEach(function (assignment) {
                    tour.itineraryItems.forEach(function (itinItem) {
                        if (itinItem.assignments.length > 0 && itinItem.categoryCode === _this.isFocusViewAssignmentCategory) {
                            itinItem.assignments.forEach(function (assignmentIti) {
                                if (assignmentIti.assignStatus === AssignmentStatus.PENDING) {
                                    assignmentIti.startTime = assignment.startTime;
                                    assignmentIti.endTime = assignment.endTime;
                                }
                            });
                        }
                    });
                    _this.isFocusViewAssignment = false;
                });
            }
            this.addToDataChangedArray(tour);
            this.enableSaveButtonValidateFields();
        }
    };
    GenReservationSearchResultsComponent.prototype.addToDataChangedArray = function (tour) {
        if (this.assignmentChangedTours) {
            var genTourItinerary = this.assignmentChangedTours.find(function (t) { return GenTourItinerary.issEqual(t, tour); });
            if (genTourItinerary) {
                var index = this.assignmentChangedTours.indexOf(genTourItinerary);
                if (index > -1) {
                    this.assignmentChangedTours.splice(index, 1);
                }
            }
            this.assignmentChangedTours.push(tour);
        }
    };
    GenReservationSearchResultsComponent.prototype.updateJobCost = function (assignment) {
        assignment.jobCost = this.costCalculationUtil.getGenSupplierTotalCost(assignment);
    };
    GenReservationSearchResultsComponent.prototype.setSupplierToAssignment = function (assignment, supplier) {
        if (supplier) {
            if (!supplier.nullSupplierForClearing) {
                // assignment.supplier = supplier;
                assignment.tempAssignmentId = this.identifierService.getNextManuallyResetId();
                assignment.supplierName = supplier.name;
                assignment.supplierCode = supplier.code;
                assignment.supplierId = supplier.supplierId;
                assignment.assignStatus = assignment.assignStatus ? assignment.assignStatus : AssignmentStatus.PENDING;
                // if supplier has resource costs
                if (supplier.resourceCostSummaries && supplier.resourceCostSummaries.length > 0) {
                    this.selectedSupplierMap.set(supplier.supplierId, supplier.resourceCostSummaries);
                    assignment.unitPrice = 0;
                    assignment.adultPrice = 0;
                    assignment.teenPrice = 0;
                    assignment.childPrice = 0;
                    assignment.infantPrice = 0;
                    assignment.currency = '';
                    var supplierCost = void 0;
                    // if resource cost matches with supplier item set the supplier cost
                    if (assignment.supplierItem &&
                        supplier.resourceCostSummaries.find(function (value) { return value.resourceType === assignment.supplierItem; })) {
                        supplierCost = supplier.resourceCostSummaries.find(function (value) { return value.resourceType ===
                            assignment.supplierItem; });
                    }
                    else if (assignment.categoryCode &&
                        supplier.resourceCostSummaries.find(function (value) { return value.resourceType === assignment.categoryCode; })) {
                        supplierCost = supplier.resourceCostSummaries.find(function (value) { return value.resourceType ===
                            assignment.categoryCode; });
                        this.clearSupplierItem(assignment);
                    }
                    else if (supplier.resourceCostSummaries.length === 1) {
                        supplierCost = supplier.resourceCostSummaries[0];
                        this.clearSupplierItem(assignment);
                    }
                    else {
                        this.clearSupplierItem(assignment);
                    }
                    if (supplierCost) {
                        assignment.unitPrice = supplierCost.unitFare;
                        assignment.adultPrice = supplierCost.adultFare;
                        assignment.teenPrice = supplierCost.teenFare;
                        assignment.childPrice = supplierCost.childFare;
                        assignment.infantPrice = supplierCost.infantFare;
                        assignment.currency = supplierCost.currency;
                    }
                }
                else {
                    assignment.unitPrice = 0;
                    assignment.adultPrice = 0;
                    assignment.teenPrice = 0;
                    assignment.childPrice = 0;
                    assignment.infantPrice = 0;
                    assignment.currency = '';
                }
                this.clearResource(assignment);
                this.updateJobCost(assignment);
            }
            else {
                assignment.supplierName = '';
                assignment.supplierCode = '';
                assignment.supplierId = 0;
                assignment.unitPrice = 0;
                assignment.adultPrice = 0;
                assignment.teenPrice = 0;
                assignment.childPrice = 0;
                assignment.infantPrice = 0;
                assignment.currency = '';
                assignment.serviceItemIdList = [];
                this.clearResource(assignment);
                this.updateJobCost(assignment);
            }
        }
    };
    GenReservationSearchResultsComponent.prototype.clearSupplierItem = function (assignment) {
        assignment.supplierItemName = '';
        assignment.supplierItem = '';
    };
    GenReservationSearchResultsComponent.prototype.clearResource = function (assignment) {
        assignment.resourceName = '';
        assignment.resourceId = 0;
    };
    GenReservationSearchResultsComponent.prototype.setResourceToAssignment = function (assignment, resource) {
        if (resource) {
            assignment.assignStatus = AssignmentStatus.PENDING;
            if (!resource.nullResourceForClearing) {
                if (!assignment.supplierId && resource && resource.supplier) {
                    this.setSupplierToAssignment(assignment, resource.supplier);
                }
                if (!assignment.supplierId && resource && resource.resourceSupplier) {
                    this.setSupplierToAssignment(assignment, resource.resourceSupplier);
                }
                // assignment.resource = resource;
                assignment.resourceName = this.isFocusViewAssignment ? resource.resourceName : resource.name;
                assignment.resourceId = resource.resourceId;
                assignment.supplierItem = this.isFocusViewAssignment ? this.isFocusViewAssignmentCategory : resource.resourceType;
                var supplierItem = this.resourceTypes.find(function (value) { return value.code === resource.resourceType; });
                assignment.supplierItemName = supplierItem ? supplierItem.name : '';
            }
            else {
                assignment.assignStatus = AssignmentStatus.PENDING;
                this.clearResource(assignment);
            }
        }
    };
    GenReservationSearchResultsComponent.prototype.refreshDataGrid = function () {
        if (this.gridApi) {
            // this.gridApi.setRowData(this.genericGroupResults);
            this.gridApi.refreshCells({ force: true });
        }
    };
    GenReservationSearchResultsComponent.prototype.clearDataGrid = function () {
        this.genericGroupResults = [];
        this.setupResultHeader(0);
        if (this.gridApi) {
            this.gridApi.setRowData(this.genericGroupResults);
        }
    };
    GenReservationSearchResultsComponent.prototype.onCellValueChanged = function (params) {
        if (params &&
            this.isEditableColumn(params) &&
            (((isNaN(params.newValue) || isNaN(params.oldValue)) && params.newValue !== params.oldValue) ||
                (!isNaN(params.newValue) && !isNaN(params.oldValue) && Number(params.newValue) !==
                    Number(params.oldValue)) ||
                params.colDef.field === 'assignmentNotes')) {
            this.addParentTourOfAssignmentToChangedSet(params);
        }
        this.cellEditingStopped(params);
    };
    GenReservationSearchResultsComponent.prototype.cellEditingStopped = function (event) {
        var nextCell = GridCommonUtil.getNextCell(event, true);
        this.gridApi.setFocusedCell(nextCell.rowIndex, nextCell.colKey);
    };
    GenReservationSearchResultsComponent.prototype.isEditableColumn = function (params) {
        var editable = false;
        var editableFields = ['supplierName', 'resourceName', 'assignmentNotes', 'adults', 'children', 'infants', 'assignmentNotes'];
        if (params && params.colDef && params.colDef.field &&
            editableFields.includes(params.colDef.field)) {
            editable = true;
        }
        return editable;
    };
    GenReservationSearchResultsComponent.prototype.openResourceAssignmentView = function (input, secondaryHeader) {
        var _this = this;
        setTimeout(function () {
            var fabActions = [];
            var navItemsArray = _this.configLoader.configurations.get(TCO.CONF.CONF_NAVIGATION);
            _this.configLoader.configurations.get(TCO.CONF.CONF_FOCUS_VIEW).ALLOCATION_FOCUS_VIEW.fabAction
                .forEach(function (val) {
                for (var key in navItemsArray) {
                    if (val === navItemsArray[key].id) {
                        fabActions.push(navItemsArray[key]);
                    }
                }
            });
            var dataObject = new ModalData(LEVEL.SUCCESS, 'Assign Supplier/Resource', null, null, '', AssignmentFocusViewComponent, input, { label: 'close' }, '', '', fabActions, '', secondaryHeader);
            _this.focusviewCloseSubject = _this.dataStore.get(DataKey.supplierCostFocusViewClose).subscribe(function (data) {
                if (data != null) {
                    _this.focusviewCloseSubject.unsubscribe();
                    _this.closeFocusView();
                }
            });
            _this.focusViewService.confirm(dataObject).subscribe(function (res) {
                if (res) {
                    var e = document.getElementsByClassName('header-content__secondary-heading');
                }
            });
        }, 0);
    };
    GenReservationSearchResultsComponent.prototype.closeFocusView = function () {
        if (this.focusViewService.close()) {
            this.focusViewService.close().subscribe(function (res) {
            });
        }
    };
    // show dialog
    GenReservationSearchResultsComponent.prototype.confirmDialog = function (data) {
        this.dialogService.confirm(data).subscribe(function (res) {
        });
    };
    GenReservationSearchResultsComponent.prototype.openItemSelectionModal = function (params) {
        var _this = this;
        var assignment = params.data;
        var readOnly = ServiceAssignment.isRestrictedEdit(assignment);
        this.dataStore.set(DataKey.selectedServiceItemIdList, null, true);
        this.dataStore.set(DataKey.popupClose, null);
        var selectedItems = params.data && params.data.serviceItemIdList ? params.data.serviceItemIdList : [];
        var serviceItems;
        serviceItems = params.node.parent.data ? params.node.parent.data.serviceItems : [];
        if (serviceItems && serviceItems.length > 0) {
            this.setSelectedItems(serviceItems, selectedItems);
        }
        var previousAssignments = params.node.parent.data.assignments;
        previousAssignments = previousAssignments.filter(function (value) { return value.tempAssignmentId === undefined ||
            value.tempAssignmentId !== params.data.tempAssignmentId; });
        var alreadySelectedItems = previousAssignments && previousAssignments.length > 0 ?
            this.getAlreadyAddedItems(previousAssignments) :
            [];
        alreadySelectedItems = alreadySelectedItems.filter(function (i) { return !selectedItems.includes(i); });
        this.dataStore.set(DataKey.modalClose, null);
        var dataObject = new ModalData(LEVEL.SUCCESS, 'Select Service Items', null, null, 'modal-basic--alt modal-basic--fixed-size', SelectServiceItemsComponent, {
            alreadySelectedItems: alreadySelectedItems,
            serviceItems: serviceItems,
            selectedItems: selectedItems,
            serviceAssignment: params,
            readOnly: readOnly
        });
        this.confirmModal(dataObject);
        this.dataStore.get(DataKey.popupClose).subscribe(function (data) {
            if (data != null) {
                _this.closeModal();
            }
        });
        this.subscribeForSelectedItems(params);
    };
    GenReservationSearchResultsComponent.prototype.onNotesClick = function (params) {
        var _this = this;
        var e_3, _a;
        var serviceAssignment = params.data;
        this.dataStore.set(DataKey.popupClose, null);
        var selectedItemIds = params.data && params.data.serviceItemIdList ? params.data.serviceItemIdList : [];
        var allServiceItemsInItem = params.node.parent.data ? params.node.parent.data.serviceItems : [];
        var selectedServiceItemsInAssignment = allServiceItemsInItem.filter(function (sItem) { return selectedItemIds.includes(sItem.serviceItemId); });
        var bookingNoteGroupsList = [];
        try {
            for (var selectedServiceItemsInAssignment_1 = tslib_1.__values(selectedServiceItemsInAssignment), selectedServiceItemsInAssignment_1_1 = selectedServiceItemsInAssignment_1.next(); !selectedServiceItemsInAssignment_1_1.done; selectedServiceItemsInAssignment_1_1 = selectedServiceItemsInAssignment_1.next()) {
                var sItem = selectedServiceItemsInAssignment_1_1.value;
                bookingNoteGroupsList.push({
                    bookingReferenceId: sItem.bookingReferenceId,
                    serviceItem: sItem,
                    notes: sItem.serviceNotes
                });
            }
        }
        catch (e_3_1) { e_3 = { error: e_3_1 }; }
        finally {
            try {
                if (selectedServiceItemsInAssignment_1_1 && !selectedServiceItemsInAssignment_1_1.done && (_a = selectedServiceItemsInAssignment_1.return)) _a.call(selectedServiceItemsInAssignment_1);
            }
            finally { if (e_3) throw e_3.error; }
        }
        serviceAssignment.assignmentNotes = serviceAssignment.assignmentNotes ? serviceAssignment.assignmentNotes : [];
        var clonedNotesArray = JSON.parse(JSON.stringify(serviceAssignment.assignmentNotes));
        var dataObject = new ModalData(LEVEL.SUCCESS, 'Notes', null, null, 'modal-basic--alt modal-basic--fixed-size', NotesEditorComponent, {
            assignmentNotes: clonedNotesArray,
            bookingNoteGroups: bookingNoteGroupsList
        });
        this.confirmModal(dataObject);
        this.dataStore.get(DataKey.popupClose).subscribe(function (data) {
            if (data != null) {
                _this.closeModal();
                if (data) {
                    serviceAssignment.assignmentNotes = clonedNotesArray;
                    params.colDef.field = 'assignmentNotes';
                    _this.onCellValueChanged(params);
                }
            }
        });
    };
    // get all resourceTypes from datakey
    GenReservationSearchResultsComponent.prototype.getResourceTypes = function (text) {
        var clearItem = {
            name: '-- CLEAR --',
            nullSupplierForClearing: true
        };
        var array = [];
        if (text) {
            array = this.resourceTypes.filter(function (type) {
                if (type.code && type.code.toLowerCase().includes(text.toLowerCase())) {
                    return true;
                }
                else if (type.name) {
                    return type.name.toLowerCase().includes(text.toLowerCase());
                }
            });
        }
        else {
            array = Array.from(this.resourceTypes);
        }
        array.unshift(clearItem);
        return of(array);
    };
    GenReservationSearchResultsComponent.prototype.searchSupplierItem = function (params, rowData, text) {
        var clearItem = {
            name: '-- CLEAR --',
            nullSupplierItemForClearing: true
        };
        return this.supplierService.retrieveSupplierItemsById(String(rowData.supplierId), text)
            .map(function (response) {
            if (response != null) {
                var items = ResponseUtil.getDataArray(response);
                items.unshift(clearItem);
                return items;
            }
            else {
                return [clearItem];
            }
        }, catchError(function (error) {
            return [clearItem];
        }));
    };
    GenReservationSearchResultsComponent.prototype.getSupplierItemName = function (params) {
        var assignment = params.data;
        var supplierItemCode = assignment ? assignment.supplierItem : null;
        if (supplierItemCode) {
            var supplierItem = this.resourceTypes.find(function (value) { return value.code === supplierItemCode; });
            assignment.supplierItemName = supplierItem ? supplierItem.name : '';
        }
        return assignment;
    };
    GenReservationSearchResultsComponent.prototype.onSupplierTextChange = function (params, rowData, text) {
        var supplierSearchCriteria = new SupplierSearchCriteria();
        supplierSearchCriteria.name = text;
        supplierSearchCriteria.status = 'true';
        supplierSearchCriteria.rejectedSupplierIdList = rowData.rejectedSupplierIdList;
        var itemGroup = params.node.parent.data;
        if (itemGroup) {
            // supplierSearchCriteria.profileType = itemGroup.typeCode;
            supplierSearchCriteria.serviceDate = params.node.parent.parent.data ?
                params.node.parent.parent.data.serviceDate :
                '';
        }
        var category = params.node.data.categoryCode;
        supplierSearchCriteria.resourceTypes = [];
        if (category) {
            supplierSearchCriteria.resourceTypes.push(itemGroup.supplierItemCode);
        }
        supplierSearchCriteria.start = 0;
        supplierSearchCriteria.size = 10;
        return this.supplierService.searchGenSuppliersForAssignment(supplierSearchCriteria)
            .pipe(map(function (response) {
            var suppliers = ResponseUtil.getDataArray(response);
            var clearSupplier = new GenSupplierAllocationSummary();
            clearSupplier.name = '-- CLEAR --';
            clearSupplier.nullSupplierForClearing = true;
            if (!suppliers.find(function (value) { return value.name === clearSupplier.name; })) {
                suppliers.unshift(clearSupplier);
            }
            return suppliers;
        }), catchError(function (error) {
            return [];
        }));
    };
    GenReservationSearchResultsComponent.prototype.onResourceTextChange = function (params, rowData, text) {
        var _this = this;
        var genericSearchCriteria = new GenericSearchCriteria();
        genericSearchCriteria.activeStatus = 'true';
        genericSearchCriteria.name = text;
        genericSearchCriteria.rejectedResourceIdList = rowData.rejectedResourceIdList;
        var itemGroup = params.node.parent.data;
        if (itemGroup) {
            genericSearchCriteria.resourceType = itemGroup.categoryCode;
        }
        if (params && params.node && params.node.parent && params.node.parent.parent &&
            params.node.parent.parent.data && params.node.parent.parent.data.serviceDate) {
            genericSearchCriteria.serviceDate = params.node.parent.parent.data.serviceDate;
        }
        if (rowData && rowData.supplierItem) {
            genericSearchCriteria.resourceType = rowData.supplierItem;
        }
        genericSearchCriteria.start = 0;
        genericSearchCriteria.size = 10;
        if (rowData && rowData.supplierId) {
            genericSearchCriteria.supplierId = rowData.supplierId;
        }
        if (!(rowData && rowData.serviceItemIdList && rowData.serviceItemIdList.length > 0)) {
            this.mapServiceItemIdList(params, rowData);
        }
        if (rowData && rowData.serviceItemIdList && rowData.serviceItemIdList.length > 0) {
            genericSearchCriteria.serviceItems = rowData.serviceItemIdList.map(function (elem) {
                return elem;
            }).join(',');
        }
        genericSearchCriteria.serviceType = 'NON_TRS';
        return this.genericResourceService.searchRecommendedResourcesForAllocation(genericSearchCriteria)
            .pipe(map(function (response) {
            var resources = [];
            var resourcesUnProcessed = ResponseUtil.getDataArray(response);
            resources.push.apply(resources, tslib_1.__spread(_this.mapRecommenderResourcesToGenResourceAllocationSummary(resourcesUnProcessed)));
            var clearResource = new GenResourceAllocationSummary();
            clearResource.name = '-- CLEAR --';
            clearResource.nullResourceForClearing = true;
            if (!resources.find(function (value) { return value.name === clearResource.name; })) {
                resources.unshift(clearResource);
            }
            return resources;
        }), catchError(function (error) {
            return [];
        }));
    };
    GenReservationSearchResultsComponent.prototype.mapRecommenderResourcesToGenResourceAllocationSummary = function (resourcesUnProcessed) {
        var e_4, _a;
        var list = [];
        if (resourcesUnProcessed && resourcesUnProcessed.length > 0) {
            try {
                for (var resourcesUnProcessed_1 = tslib_1.__values(resourcesUnProcessed), resourcesUnProcessed_1_1 = resourcesUnProcessed_1.next(); !resourcesUnProcessed_1_1.done; resourcesUnProcessed_1_1 = resourcesUnProcessed_1.next()) {
                    var recSummary = resourcesUnProcessed_1_1.value;
                    var mapped = new GenResourceAllocationSummary();
                    mapped.name = recSummary.resourceName;
                    mapped.resourceName = recSummary.resourceName;
                    mapped.resourceId = recSummary.resourceId;
                    mapped.supplier = recSummary.resourceSupplier;
                    if (recSummary.resourceSupplier && recSummary.resourceSupplier.resourceCostSummaries && recSummary.resourceSupplier.resourceCostSummaries && recSummary.resourceSupplier.resourceCostSummaries[0]) {
                        mapped.resourceType = recSummary.resourceSupplier.resourceCostSummaries[0].resourceType;
                    }
                    list.push(mapped);
                }
            }
            catch (e_4_1) { e_4 = { error: e_4_1 }; }
            finally {
                try {
                    if (resourcesUnProcessed_1_1 && !resourcesUnProcessed_1_1.done && (_a = resourcesUnProcessed_1.return)) _a.call(resourcesUnProcessed_1);
                }
                finally { if (e_4) throw e_4.error; }
            }
        }
        return list;
    };
    GenReservationSearchResultsComponent.prototype.onClickSave = function () {
        var _this = this;
        console.log(this.assignmentChangedTours);
        if (this.saveGenericSubscription) {
            this.saveGenericSubscription.unsubscribe();
        }
        this.spinnerService.show();
        this.saveGenericSubscription = this.genReservationManagementService.saveGenReservations(this.assignmentChangedTours)
            .subscribe(function (response) {
            _this.spinnerService.hide();
            if (_this.commonHelper.dataValidity(response)) {
                if (ResponseUtil.isSuccess(response)) {
                    _this.common.showSnackBar('Successfully saved changes', 3000, TcErrorType.TYPE.INFO);
                    _this.assignmentChangedTours = [];
                    _this.changesAvailableForSave = false;
                    _this.searchGenericGroupResults();
                }
                else if (ResponseUtil.isError(response)) {
                    _this.common.showSnackBar('Failed saving changes', 3000, TcErrorType.TYPE.INFO);
                }
                if (_this.saveGenericSubscription) {
                    _this.saveGenericSubscription.unsubscribe();
                }
            }
        }, function (error) {
            console.log(error);
            _this.spinnerService.hide();
            _this.common.showSnackBar('Failed to save changes', 3000, TcErrorType.TYPE.ERROR);
        });
    };
    GenReservationSearchResultsComponent.prototype.searchGenericGroupResults = function () {
        this.addFilterModelToCriteria();
        this.addSortModelToCriteria();
        this.dataStoreService.set(DataKey.genReservationSearchResultsFromService, null, true);
        this.genReservationManagementService.searchReservations(this.searchCriteria);
    };
    GenReservationSearchResultsComponent.prototype.onClickConfirm = function () {
        var _this = this;
        var e_5, _a;
        var assignments = this.gridApi.getSelectedRows();
        var assignmentIds = [];
        try {
            for (var assignments_1 = tslib_1.__values(assignments), assignments_1_1 = assignments_1.next(); !assignments_1_1.done; assignments_1_1 = assignments_1.next()) {
                var data = assignments_1_1.value;
                if (data.status !== AssignmentStatus.DMC_CONFIRM || data.status !== AssignmentStatus.SUPPLIER_CONFIRMED &&
                    data.status !==
                        AssignmentStatus.SUPPLIER_REJECTED) {
                    assignmentIds.push(data.assignmentId);
                }
            }
        }
        catch (e_5_1) { e_5 = { error: e_5_1 }; }
        finally {
            try {
                if (assignments_1_1 && !assignments_1_1.done && (_a = assignments_1.return)) _a.call(assignments_1);
            }
            finally { if (e_5) throw e_5.error; }
        }
        if (assignmentIds.length > 0) {
            var confirmSuccess = new DialogModel(true, LEVEL.WARNING, 'Confirm Assignments', "\n                        Confirming this action will trigger the following.<br><br>\n\n                        1. Sending manifests to the allocated Suppliers/ Resources.<br>\n                        2. Creating accounting ledger entries.<br><br>\n\n                        Once confirmed this action cannot be undone.<br>\n                        Do you wish to proceed?", true, 2000, null, 'No', 'Yes', true);
            confirmSuccess.disableClose = true;
            this.dialogService
                .confirm(confirmSuccess)
                .subscribe(function (res) {
                if (res === true) {
                    _this.spinnerService.show();
                    _this.reservationManagementService.confirmAssignments(assignmentIds).subscribe(function (result) {
                        _this.spinnerService.hide();
                        // refresh service items
                        _this.searchGenericGroupResults();
                        // let confirmSuccess = new DialogModel(
                        //     true,
                        //     LEVEL.SUCCESS,
                        //     'Success',
                        //     `Successfully confirmed the assigned resources`,
                        //     true,
                        //     2000
                        // );
                        // this.dialogService
                        //     .confirm(confirmSuccess)
                        //     .subscribe();
                        _this.common.showSnackBar('Successfully confirmed the assigned resources', 3000, TcErrorType.TYPE.INFO);
                    }, function (error) {
                        console.log(error);
                        _this.spinnerService.hide();
                        // let confirmError = new DialogModel(
                        //     true,
                        //     LEVEL.ERROR,
                        //     'Failed',
                        //     'Failed to confirm the assigned resources',
                        //     true,
                        //     2000
                        // );
                        // this.dialogService
                        //     .confirm(confirmError)
                        //     .subscribe();
                        _this.common.showSnackBar('Failed to confirm the assigned resources', 3000, TcErrorType.TYPE.ERROR);
                    });
                }
            });
        }
    };
    GenReservationSearchResultsComponent.prototype.onSupplierRejectClick = function () {
        var _this = this;
        var e_6, _a;
        console.log('sup reject');
        var assignments = this.gridApi.getSelectedRows();
        var assignmentIds = [];
        try {
            for (var assignments_2 = tslib_1.__values(assignments), assignments_2_1 = assignments_2.next(); !assignments_2_1.done; assignments_2_1 = assignments_2.next()) {
                var data = assignments_2_1.value;
                if ((data.assignStatus === AssignmentStatus.SUPPLIER_CONFIRMED || data.assignStatus === AssignmentStatus.DMC_CONFIRM) &&
                    !assignmentIds.includes(data.assignmentId)) {
                    assignmentIds.push(data.assignmentId);
                }
            }
        }
        catch (e_6_1) { e_6 = { error: e_6_1 }; }
        finally {
            try {
                if (assignments_2_1 && !assignments_2_1.done && (_a = assignments_2.return)) _a.call(assignments_2);
            }
            finally { if (e_6) throw e_6.error; }
        }
        if (assignmentIds.length > 0) {
            var confirmSuccess = new DialogModel(true, LEVEL.WARNING, 'Supplier Reject Assignments', "\n                        Rejecting this action will trigger the following.<br><br>\n\n                        1. Removing Suppliers/ Resources from the assignment.<br>\n                        2. Sending manifests to the rejected Suppliers/ Resources.<br>\n                        3. Removing accounting ledger entries.<br><br>\n\n                        Once confirmed this action cannot be undone.<br>\n                        Do you wish to proceed?", true, 2000, null, 'No', 'Yes', true);
            confirmSuccess.disableClose = true;
            this.spinnerService.show();
            this.dialogService
                .confirm(confirmSuccess)
                .subscribe(function (res) {
                if (res === true) {
                    _this.reservationManagementService.onSupplierRejectAssignments(assignmentIds).subscribe(function (result) {
                        _this.spinnerService.hide();
                        // refresh service items
                        _this.searchGenericGroupResults();
                        _this.common.showSnackBar('Successfully set as supplier rejected the assigned resources', 3000, TcErrorType.TYPE.INFO);
                    }, function (error) {
                        _this.spinnerService.hide();
                        _this.common.showSnackBar('Failed to set as supplier rejected the assigned resources', 3000, TcErrorType.TYPE.ERROR);
                    });
                }
            });
        }
    };
    GenReservationSearchResultsComponent.prototype.enableSaveButtonValidateFields = function () {
        var e_7, _a, e_8, _b, e_9, _c;
        this.saveButtonEnabled = true;
        this.changedAssignments = [];
        try {
            for (var _d = tslib_1.__values(this.assignmentChangedTours), _e = _d.next(); !_e.done; _e = _d.next()) {
                var assignmentChangedTour = _e.value;
                try {
                    for (var _f = tslib_1.__values(assignmentChangedTour.itineraryItems), _g = _f.next(); !_g.done; _g = _f.next()) {
                        var itineraryItem = _g.value;
                        try {
                            for (var _h = tslib_1.__values(itineraryItem.assignments), _j = _h.next(); !_j.done; _j = _h.next()) {
                                var assignment = _j.value;
                                this.changedAssignments.push(assignment);
                                if (!this.isEmptyAssignment(assignment) &&
                                    this.isIncompleteAssignment(assignment)) {
                                    this.saveButtonEnabled = false;
                                }
                            }
                        }
                        catch (e_9_1) { e_9 = { error: e_9_1 }; }
                        finally {
                            try {
                                if (_j && !_j.done && (_c = _h.return)) _c.call(_h);
                            }
                            finally { if (e_9) throw e_9.error; }
                        }
                    }
                }
                catch (e_8_1) { e_8 = { error: e_8_1 }; }
                finally {
                    try {
                        if (_g && !_g.done && (_b = _f.return)) _b.call(_f);
                    }
                    finally { if (e_8) throw e_8.error; }
                }
            }
        }
        catch (e_7_1) { e_7 = { error: e_7_1 }; }
        finally {
            try {
                if (_e && !_e.done && (_a = _d.return)) _a.call(_d);
            }
            finally { if (e_7) throw e_7.error; }
        }
        if (this.saveButtonEnabled) {
            this.enableSaveButton();
        }
        else {
            this.disableSaveButton();
        }
    };
    GenReservationSearchResultsComponent.prototype.isEmptyAssignment = function (assignment) {
        if (assignment) {
            if (assignment.assignmentId > 0
                ||
                    assignment.supplierId > 0
                ||
                    assignment.resourceId > 0
                ||
                    !!assignment.supplierItem
                ||
                    !!assignment.startTime
                ||
                    !!assignment.endTime
                ||
                    (assignment.assignmentNotes && assignment.assignmentNotes.length > 0)) {
                return false;
            }
        }
        return true;
    };
    GenReservationSearchResultsComponent.prototype.isIncompleteAssignment = function (assignment) {
        if (assignment) {
            if (assignment.supplierId &&
                assignment.supplierItem &&
                assignment.startTime &&
                assignment.endTime &&
                assignment.startTime !== '--' &&
                assignment.endTime !== '--') {
                return false;
            }
        }
        return true;
    };
    GenReservationSearchResultsComponent.prototype.disableAllPaginatorButtons = function () {
        var config = [
            {
                buttonId: 'more',
                state: 'disable'
            },
            {
                buttonId: 'save',
                state: 'disable'
            },
            {
                buttonId: 'confirm',
                state: 'disable'
            },
            {
                buttonId: 'supplierReject',
                state: 'disable'
            }
        ];
        this.buttonState.next(config);
    };
    GenReservationSearchResultsComponent.prototype.enableMoreButton = function () {
        var config = [
            {
                buttonId: 'more',
                state: 'enable'
            }
        ];
        this.buttonState.next(config);
    };
    GenReservationSearchResultsComponent.prototype.disableMoreButton = function () {
        var config = [
            {
                buttonId: 'more',
                state: 'disable'
            }
        ];
        this.buttonState.next(config);
    };
    GenReservationSearchResultsComponent.prototype.enableSaveButton = function () {
        var config = [
            {
                buttonId: 'save',
                state: 'enable'
            }
        ];
        this.buttonState.next(config);
    };
    GenReservationSearchResultsComponent.prototype.disableSaveButton = function () {
        var config = [
            {
                buttonId: 'save',
                state: 'disable'
            }
        ];
        this.buttonState.next(config);
    };
    GenReservationSearchResultsComponent.prototype.enableConfirmButton = function () {
        var config = [
            {
                buttonId: 'confirm',
                state: 'enable'
            }
        ];
        this.buttonState.next(config);
    };
    GenReservationSearchResultsComponent.prototype.disableConfirmButton = function () {
        var config = [
            {
                buttonId: 'confirm',
                state: 'disable'
            }
        ];
        this.buttonState.next(config);
    };
    GenReservationSearchResultsComponent.prototype.enableRejectButton = function () {
        var config = [
            {
                buttonId: 'supplierReject',
                state: 'enable'
            }
        ];
        this.buttonState.next(config);
    };
    GenReservationSearchResultsComponent.prototype.disableRejectButton = function () {
        var config = [
            {
                buttonId: 'supplierReject',
                state: 'disable'
            }
        ];
        this.buttonState.next(config);
    };
    GenReservationSearchResultsComponent.prototype.ngOnDestroy = function () {
        if (this.searchCriteriaSubscription) {
            this.searchCriteriaSubscription.unsubscribe();
        }
        if (this.searchResultSubscription) {
            this.searchResultSubscription.unsubscribe();
        }
        if (this.moreSearchResultSubscription) {
            this.moreSearchResultSubscription.unsubscribe();
        }
        if (this.finalSearchResultsSubscription) {
            this.finalSearchResultsSubscription.unsubscribe();
        }
        if (this.subscribeOnSelectedServiceItemList) {
            this.subscribeOnSelectedServiceItemList.unsubscribe();
        }
        if (this.assignedSupplierSubscription) {
            this.assignedSupplierSubscription.unsubscribe();
        }
        if (this.assignedResourceSubscription) {
            this.assignedResourceSubscription.unsubscribe();
        }
        this.em.removeEvents([
            'EXPORT', 'SAVE_CRITERIA', 'SEND_MANIFESTS', 'TOUR_LIST'
        ]);
    };
    GenReservationSearchResultsComponent.prototype.onRowSelected = function ($event) {
        var selectedRows = this.gridApi.getSelectedRows();
        console.log(selectedRows);
        if (!this.changesAvailableForSave && selectedRows && selectedRows.length > 0 &&
            ServiceAssignmentUtilService.isAssignedAll(selectedRows)) {
            if (!ServiceAssignmentUtilService.isRestrictedAny(selectedRows)) {
                this.enableConfirmButton();
            }
            else if (ServiceAssignmentUtilService.isSupplierConfirmedOrDmcConfirmedAll(selectedRows)) {
                this.enableRejectButton();
            }
            else {
                this.disableConfirmButton();
                this.disableRejectButton();
            }
        }
        else {
            this.disableRejectButton();
            this.disableConfirmButton();
        }
    };
    GenReservationSearchResultsComponent.prototype.sendManifests = function () {
        var _this = this;
        // this.spinnerService.show();
        var confirmSendManifests = new DialogModel(true, LEVEL.WARNING, 'Send Manifests', " Do you want to send manifests for all confirmed assignments\n                       on service date " + this.searchCriteria.date + " ?", true, 2000, null, 'Cancel', 'Send', true);
        confirmSendManifests.disableClose = true;
        this.dialogService
            .confirm(confirmSendManifests)
            .subscribe(function (res) {
            if (res === true) {
                var documentCreationCriteria = new DocumentCreationCriteria();
                documentCreationCriteria.documentFormat = DocumentFormat.pdf;
                documentCreationCriteria.documentType = DocumentType.supplierManifest;
                documentCreationCriteria.download = true;
                var documentDataCriteria = new DocumentDataCriteria();
                documentDataCriteria.searchStartDate = _this.searchCriteria.startDate;
                documentDataCriteria.searchEndDate = _this.searchCriteria.endDate;
                _this.documentService.createAndDownloadDocument(documentCreationCriteria, documentDataCriteria);
            }
        });
    };
    GenReservationSearchResultsComponent.prototype.getTourList = function (docName, persist, download) {
        var documentCreationCriteria = new DocumentCreationCriteria();
        documentCreationCriteria.documentFormat = DocumentFormat.excel;
        documentCreationCriteria.documentType = DocumentType.tourList;
        documentCreationCriteria.documentName = docName;
        documentCreationCriteria.download = download;
        documentCreationCriteria.persist = persist;
        var documentDataCriteria = new DocumentDataCriteria();
        documentDataCriteria.searchStartDate = this.searchCriteria.startDate;
        documentDataCriteria.searchEndDate = this.searchCriteria.endDate;
        documentDataCriteria.serviceType = 'TRS';
        documentDataCriteria.productType = 'TOU';
        this.createDocSearchCriteria(documentDataCriteria);
        this.documentService.createAndDownloadDocument(documentCreationCriteria, documentDataCriteria);
    };
    GenReservationSearchResultsComponent.prototype.createDocSearchCriteria = function (documentCreationCriteria) {
        if (!this.searchCriteria.assignedStatus) {
            this.searchCriteria.assignedStatus = null;
        }
        documentCreationCriteria.searchStartDate = this.searchCriteria.startDate;
        documentCreationCriteria.searchEndDate = this.searchCriteria.endDate;
        if (this.searchCriteria.tourName) {
            documentCreationCriteria.tourName = this.searchCriteria.tourName;
        }
        if (this.searchCriteria.bookingDate) {
            documentCreationCriteria.bookingDate = this.searchCriteria.bookingDate;
        }
        if (this.searchCriteria.daysFrom) {
            documentCreationCriteria.daysFrom = this.searchCriteria.daysFrom;
        }
        if (this.searchCriteria.resourceType) {
            documentCreationCriteria.categoryCode = this.searchCriteria.resourceType;
        }
        if (this.searchCriteria.assignedStatus) {
            documentCreationCriteria.assignStatus = this.searchCriteria.assignedStatus;
        }
        if (this.searchCriteria.supplierId) {
            documentCreationCriteria.servicingSupplierId = this.searchCriteria.supplierId;
        }
        // documentCreationCriteria.size = this.searchCriteria.size;
        // documentCreationCriteria.start = this.searchCriteria.start;
    };
    GenReservationSearchResultsComponent.prototype.onGenerateDocumentsClick = function () {
        var _this = this;
        this.dataStore.set(DataKey.popupClose, null);
        var documentTypes = [
            {
                name: 'Gen Export Report',
                code: DocumentType.genExportReport,
                icon: 'unarchive'
            },
            {
                name: 'Tour List',
                code: DocumentType.tourList,
                icon: 'flight_takeoff'
            }
        ];
        var documentCreationModel = new DocumentCreationModel();
        documentCreationModel.autoName = true;
        documentCreationModel.download = true;
        var dataObject = new ModalData(LEVEL.SUCCESS, 'Generate Documents', null, null, 'modal-basic--alt modal-basic--fixed-size', DocumentCreationModalComponent, {
            documentTypes: documentTypes,
            documentCreationModel: documentCreationModel
        });
        dataObject.disableClose = false;
        this.confirmModal(dataObject);
        var modalSubscription = this.dataStore.get(DataKey.popupClose).subscribe(function (data) {
            if (data != null) {
                modalSubscription.unsubscribe();
                _this.closeModal();
                if (data) {
                    modalSubscription.unsubscribe();
                    _this.closeModal();
                    if (documentCreationModel.documentType) {
                        switch (documentCreationModel.documentType.code) {
                            case DocumentType.genExportReport:
                                _this.export(documentCreationModel.documentName, !!documentCreationModel.persist, !!documentCreationModel.download);
                                break;
                            case DocumentType.tourList:
                                _this.getTourList(documentCreationModel.documentName, !!documentCreationModel.persist, !!documentCreationModel.download);
                                break;
                        }
                    }
                }
            }
        });
    };
    GenReservationSearchResultsComponent.prototype.subscribeForSelectedItems = function (params) {
        var _this = this;
        this.subscribeOnSelectedServiceItemList = this.dataStore.get(DataKey.selectedServiceItemIdList).subscribe(function (data) {
            if (data != null) {
                _this.subscribeOnSelectedServiceItemList.unsubscribe();
                // params.data = data;
                _this.addParentTourOfAssignmentToChangedSet(params);
                _this.enableSaveButtonValidateFields();
                _this.refreshDataGrid();
            }
        });
    };
    GenReservationSearchResultsComponent.prototype.setSelectedItems = function (serviceItems, selectedItems) {
        serviceItems.forEach(function (value) {
            value.checked = selectedItems.includes(value.serviceItemId);
        });
    };
    GenReservationSearchResultsComponent.prototype.getAlreadyAddedItems = function (previousAssignments) {
        var e_10, _a;
        var alreadySelectedItems = [];
        if (previousAssignments && previousAssignments.length > 0) {
            try {
                for (var previousAssignments_1 = tslib_1.__values(previousAssignments), previousAssignments_1_1 = previousAssignments_1.next(); !previousAssignments_1_1.done; previousAssignments_1_1 = previousAssignments_1.next()) {
                    var previousAssignment = previousAssignments_1_1.value;
                    if (previousAssignment.serviceItemIdList) {
                        alreadySelectedItems.push.apply(alreadySelectedItems, tslib_1.__spread(previousAssignment.serviceItemIdList));
                    }
                }
            }
            catch (e_10_1) { e_10 = { error: e_10_1 }; }
            finally {
                try {
                    if (previousAssignments_1_1 && !previousAssignments_1_1.done && (_a = previousAssignments_1.return)) _a.call(previousAssignments_1);
                }
                finally { if (e_10) throw e_10.error; }
            }
        }
        return alreadySelectedItems;
    };
    GenReservationSearchResultsComponent.prototype.onShowCostBreakDown = function (params) {
        var _this = this;
        var serviceAssignment = params.data;
        this.dataStore.set(DataKey.popupClose, null);
        var dataObject = new ModalData(LEVEL.SUCCESS, 'Cost Line', null, null, 'modal-basic--alt modal-basic--fixed-size', SupplierPriceBreakdownComponent, {
            serviceAssignment: serviceAssignment
        });
        this.confirmModal(dataObject);
        this.dataStore.get(DataKey.popupClose).subscribe(function (data) {
            if (data != null) {
                _this.closeModal();
            }
        });
    };
    GenReservationSearchResultsComponent.prototype.showAssignmentIncomplete = function (row) {
        if (!this.saveButtonEnabled && row) {
            if (!this.changedAssignments.includes(row)) {
                return false;
            }
            if (this.isEmptyAssignment(row)) {
                return false;
            }
            return this.isIncompleteAssignment(row);
        }
        return false;
    };
    GenReservationSearchResultsComponent.prototype.getIncompleteToolTip = function (row) {
        var text = '';
        if (row) {
            if (!row.supplierId || row.supplierId < 0) {
                text += 'Supplier is empty';
            }
            if (!row.supplierItem || row.supplierItem === '') {
                if (text) {
                    text += ' & ';
                }
                text += 'Supplier Item is empty';
            }
            if (!row.startTime || row.startTime === 'Invalid Time') {
                if (text) {
                    text += ' & ';
                }
                text += 'Start Time is empty';
            }
            if (!row.endTime || row.endTime === 'Invalid Time') {
                if (text) {
                    text += ' & ';
                }
                text += 'End Time is empty';
            }
        }
        return text;
    };
    GenReservationSearchResultsComponent.prototype.showPrivateIcon = function (row) {
        return !!row.bookingRefIfPrivate;
    };
    return GenReservationSearchResultsComponent;
}());
export { GenReservationSearchResultsComponent };
