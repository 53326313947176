/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid.component.ngfactory";
import * as i2 from "../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid.component";
import * as i3 from "../../../services/util/pre-processors/grid-column-definition-processor.service";
import * as i4 from "@ngx-translate/core";
import * as i5 from "@tc/dialog/dialog.service";
import * as i6 from "../../../services/util/framework/data-store.service";
import * as i7 from "../../../services/util/common/dmc-common";
import * as i8 from "./routes-grid-setup.component";
import * as i9 from "@tc-core/util/framework/config-loader.service";
import * as i10 from "../../../services/backend-consumers/master-data-handler-service/data-handler.service";
import * as i11 from "../../../services/backend-consumers/setups/locations.service";
var styles_RoutesGridSetupComponent = [];
var RenderType_RoutesGridSetupComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_RoutesGridSetupComponent, data: {} });
export { RenderType_RoutesGridSetupComponent as RenderType_RoutesGridSetupComponent };
export function View_RoutesGridSetupComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { setupGrid: 0 }), (_l()(), i0.ɵeld(1, 0, null, null, 1, "tc-setup-grid", [], null, null, null, i1.View_SetupGridComponent_0, i1.RenderType_SetupGridComponent)), i0.ɵdid(2, 638976, [[1, 4], ["setupGrid", 4]], 0, i2.SetupGridComponent, [i0.ChangeDetectorRef, i3.GridColumnDefinitionProcessorService, i4.TranslateService, i5.DialogService, i6.DataStoreService, i7.DMCCommon], { readOnly: [0, "readOnly"], dataSource: [1, "dataSource"], setupGridComp: [2, "setupGridComp"], colDefConfig: [3, "colDefConfig"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = false; var currVal_1 = _co; var currVal_2 = _co; var currVal_3 = _co.colDefConfig; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_RoutesGridSetupComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-routes-grid-setup", [], null, null, null, View_RoutesGridSetupComponent_0, RenderType_RoutesGridSetupComponent)), i0.ɵdid(1, 245760, null, 0, i8.RoutesGridSetupComponent, [i9.ConfigLoader, i6.DataStoreService, i10.DataHandlerService, i11.LocationsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RoutesGridSetupComponentNgFactory = i0.ɵccf("tc-routes-grid-setup", i8.RoutesGridSetupComponent, View_RoutesGridSetupComponent_Host_0, {}, {}, []);
export { RoutesGridSetupComponentNgFactory as RoutesGridSetupComponentNgFactory };
