import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule, MatMenuModule, MatTooltipModule } from '@angular/material';
import { TranslateModule } from '@ngx-translate/core';
import { ResourceManager } from '@tc-core/util/framework/resource-manager.service';
import { DirectivesModule } from '@tc/directives';
import { PipesModule } from '@tc/pipes';
import { ResourceModule } from '@tc/resource/resource.module';
import { SecurityModule } from '@tc/security';
import { ThemeChangeComponent } from '@tc/theme-change/theme-change.component';
import { ThemeChangeModule } from '@tc/theme-change/theme-change.module';
import { HeaderComponent } from './header.component';
import { MatButtonModule } from '@angular/material/button';
import { UserProfileModule } from '../../../business/user-profile/user-profile.module';

@NgModule({
    imports: [
        TranslateModule.forChild(),
        CommonModule,
        ResourceModule,
        MatTooltipModule,
        ThemeChangeModule,
        PipesModule,
        UserProfileModule,
        SecurityModule,
        DirectivesModule,
        MatButtonModule,
        MatIconModule,
        MatMenuModule
    ],
    exports: [
        HeaderComponent,
        ThemeChangeComponent
    ],
    declarations: [
        HeaderComponent
    ],
    providers: [
        ResourceManager
    ]
})
export class HeaderModule {
}
