import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { PickupDropoffExpandedViewComponent } from './pickup-dropoff-expanded-view.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule.forChild()
  ],
  declarations: [PickupDropoffExpandedViewComponent],
  exports:[PickupDropoffExpandedViewComponent]
})
export class PickupDropoffExpandedViewModule { }
