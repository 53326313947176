import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataStore } from '@tc-core/util/framework/data-store.service';
import { CommonHelper } from '@tc-core/util/helpers/common-helper.service';
import { ContentStripModule } from '@tc/content-strip';
import { DirectivesModule } from '@tc/directives';
import { ExpandedContentCardModule } from '@tc/expanded-content-card';
import { PipesModule } from '@tc/pipes';
import { ResourceExpandedContentHeaderModule } from '../resource-expanded-content-header/resource-expanded-content-header.module';
import { ResultContentCardResourceAssignModule } from '../result-content-card-resource-assign/result-content-card-resource-assign.module';
import {AdvResourceAssignSearchResultComponent} from "./adv-resource-assign-search-result.component";
import {ResourceAllocationCardGenModule} from "../../../business/resource-allocation-card-gen/resource-allocation-card-gen.module";

@NgModule({
    imports: [
        CommonModule,
        DirectivesModule,
        PipesModule,
        ContentStripModule,
        ResultContentCardResourceAssignModule,
        ExpandedContentCardModule,
        ResourceExpandedContentHeaderModule,
        ResourceAllocationCardGenModule
    ],
    providers: [
        DataStore,
        CommonHelper
    ],
    declarations: [AdvResourceAssignSearchResultComponent],
    exports: [AdvResourceAssignSearchResultComponent]
})
export class AdvResourceAssignSearchResultModule {}
