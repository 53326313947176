import {Component, OnInit, HostListener, AfterViewInit} from '@angular/core';
import {ResultSummary} from '@tc-core/model/it/codegen/ui/framework/result-summary';
import {RootService} from '@tc-core/service/root.service';
import { UserJourneyManager } from '@tc-core/util/framework/user-journey-manager.service';

@Component({
  selector: 's01',
  templateUrl: './scaff-s7v0.component.html'
})
export class ScaffS7v0Component implements OnInit, AfterViewInit {

  showP3: boolean = false;
  headerClass: string;

  constructor(private journeyManager: UserJourneyManager) {
  }

  ngOnInit() {
    this.journeyManager.pageId.subscribe(page => {
      if (page) {
        this.headerClass = page;
      }
    });
  }

  onActivatePageSummary(event) {

    if (event) {
      this.showP3 = true;
    }
  }

  ngAfterViewInit() {

    // sticky header
    if (document.getElementsByClassName('main-panel')[0] !== undefined &&
      document.getElementsByClassName('dashboard-header')[0] !== undefined) {
      let mainPanel = document.getElementsByClassName('main-panel')[0];
      let dashboardHeader = document.getElementsByClassName('dashboard-header')[0];
      mainPanel.addEventListener('scroll', (event) => {
        if (mainPanel.scrollTop >= 72) {
          dashboardHeader.classList.add('sticky-summary-header');
          setTimeout(() => {
            dashboardHeader.classList.add('sticky-summary-header-remove');
            return false;
          }, 50);
        } else {
          // dashboardHeader.classList.remove('sticky-summary-header-remove');

          if (dashboardHeader.classList.contains('sticky-summary-header-remove') && mainPanel.scrollTop <= 10) {
            dashboardHeader.classList.remove('sticky-summary-header-remove');
            if (dashboardHeader.classList.contains('sticky-summary-header-remove-space')) {
              dashboardHeader.classList.remove('sticky-summary-header-remove-space');
              return false;
            }
          }

        }

        if ((mainPanel.scrollTop >= 150) || ((mainPanel.scrollTop >= 155))) {
          if (dashboardHeader.classList.contains('sticky-summary-header-remove') && mainPanel.scrollTop >= 155) {
            dashboardHeader.classList.add('sticky-summary-header-remove-space');
            return false;
          } else {
            if (dashboardHeader.classList.contains('sticky-summary-header-remove-space')) {
              dashboardHeader.classList.remove('sticky-summary-header-remove-space');
              return false;
            }
            return false;
          }

        }

        if (mainPanel.scrollTop < 50) {

          if (dashboardHeader.classList.contains('sticky-summary-header-remove-space')) {
            dashboardHeader.classList.remove('sticky-summary-header-remove-space');
          }

        }

      });
    }
  }
}
