import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TooltipPosition } from '@angular/material/tooltip';
import { SummaryCardData } from '@tc-core/model/it/codegen/ui/framework/summary-card-data';
import { TC } from '@tc-core/util/constants';
import { ConfigLoader } from '@tc-core/util/framework/config-loader.service';
import { Chip } from '@tc/chip-selector/chip.model';
import { DialogService } from '@tc/dialog/dialog.service';
import { SorterPipe } from '@tc/pipes/sorter.pipe';
import { TCO } from '../../../../../constants';
import { ManageNotesComponent } from '../../../../../widgets/framework/manage-notes';
import {BookingNote, BookingNoteType} from '../../../../../models/reservation-v2/booking-note';
import { NoteItemView } from '../../../../../models/reservation/note-item-view';
import { CardDataHandlerService } from '../../../../../services/util/ui/card-data-handler-service.service';
/*
common component for notes module in bookings
 */
@Component({
  selector: 'tc-item-notes',
  templateUrl: './item-notes.component.html',
  styleUrls: ['./item-notes.component.scss']
})
export class ItemNotesComponent implements OnInit {

  @Input() heading: string;
  @Input() authConfigComponent: string = "booking-notes-summary";
  @Input() noteContents: NoteItemView[] = [];
  @Input() noteSummary: SummaryCardData;
  @Input() masterNoteTypes = [];

  @Input() noteList: BookingNote[] = [];
  @Input() readonly = false;

  @Output() addNoteClick: EventEmitter<any> = new EventEmitter();
  @Output() editClick: EventEmitter<any> = new EventEmitter();
  @Output() deleteClick: EventEmitter<any> = new EventEmitter();
  @Output() selectedNote: EventEmitter<SummaryCardData> = new EventEmitter();
  @Output() changeValidity: EventEmitter<boolean> = new EventEmitter();


  public editToolTip = 'Edit';
  public deleteToolTip = 'Delete';
  public addNoteToolTip = 'Add Note';
  public toolTipPosition: TooltipPosition = 'below';

  addNoteTitle = 'Add New Note';
  CARD_CONFIG_NOTES = 'notes';
  isConfirmed: boolean = false;
  editNoteTitle = 'Edit Note';
  historyText = '';

  showSummary: boolean = false;
  hideToggle: boolean = true;
  EMPTY_CHAR = TC.FORMAT.EMPTY_CHAR;

  constructor(
      public dialog: MatDialog, private dialogService: DialogService,
      private configLoader: ConfigLoader,
      private sortPipe: SorterPipe,
      private cardDataHandlerService: CardDataHandlerService,

  ) { }

  ngOnInit() {
    this.onNoteListUpdate();
  }

  // create note summary view - should call when noteList update
  onNoteListUpdate() {
    this.masterNoteTypes = this.configLoader.configurations.get(TCO.CONF.CONF_CONTENT_LIST_CARD).master_note_types;
    this.noteSummary = this.cardDataHandlerService.processCardData(
        this.CARD_CONFIG_NOTES,
        this.noteList,
        'note-summary'
    );

    if (this.noteList && this.noteList.length > 0) {
      this.noteContents = [];
      for (const note of this.noteList) {
        this.convertCardNoteType(note);
        const noteContent: NoteItemView = new NoteItemView();
        noteContent.header = this.cardDataHandlerService.processCardData(
            this.CARD_CONFIG_NOTES,
            note,
            'note-detail.header'
        );
        noteContent.summary = this.cardDataHandlerService.processCardData(
            this.CARD_CONFIG_NOTES,
            note,
            'note-detail.summary'
        );
        noteContent.detail = this.cardDataHandlerService.processCardData(
            this.CARD_CONFIG_NOTES,
            note,
            'note-detail.detail'
        );
        this.noteContents.push(noteContent);
      }
    }
  }

  showSummaryPanel(event) {
    this.showSummary = !event;
  }

  convertCardNoteType(data){
    if (data && data.type && data.type === BookingNoteType.TRANSFER_INFO){
      data.typeString = 'TRANSFER INFO';
    }else if (data && data.type ){
      data.typeString = data.type;
    }
  }

  isEditable(data) {
    return !!(data && this.masterNoteTypes.find(type => type.code === data));

  }

  onEditClick(summaryContent: any) {
    if (!this.readonly && summaryContent && summaryContent.detail && summaryContent.detail.data && summaryContent.detail.data.value) {
      this.openEditNoteDialog(summaryContent.detail.data.value);
    }
  }

  openEditNoteDialog(noteDetail): void {
    let editNoteDialogRef = this.getNoteDialogRef(
        this.editNoteTitle,
        noteDetail.note, noteDetail.type,
        'tc-manage-notes-edit.NOTE_EDIT',
        '@tc-manage-notes-edit.NOTE_EDIT',
        true
    );
    editNoteDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.editItemNote(noteDetail, result);
      }
    });
  }

  editItemNote(noteDetail: BookingNote, editedNoteContent: any) {
    noteDetail.note = editedNoteContent.note;
    this.onValueChange();
  }

  onDeleteClick(summaryContent: any) {
    if (!this.readonly && summaryContent && summaryContent.detail && summaryContent.detail.data && summaryContent.detail.data.value) {
      const index = this.noteList.indexOf(summaryContent.detail.data.value);
      this.noteList.splice(index, 1);
      this.onValueChange();
    }
  }

  public onValueChange() {
    let allValid = true;
    for (const value of this.noteList) {
      if (!(value && value.note && value.type)) {
        allValid = false;
        break;
      }
    }
    this.onNoteListUpdate();
    this.changeValidity.emit(allValid);
  }

  onPanelExpand($event, note: any, selectedIndex: number) {
    $event.stopPropagation();
    this.selectedNote.emit(note);
  }

  onAddNotesClick(event) {
    let addNoteDialogRef = this.getNoteDialogRef(
        this.addNoteTitle,
        '',
        '',
        '',
        '',
        false
    );
    addNoteDialogRef.afterClosed().subscribe(result => {
      if (result) {
        console.log(result);
        this.saveItemNote(result);
      }
    });
  }

  getNoteDialogRef(
      noteTitle: any, noteText: any, noteType: any, noteMethodCodes: string, noteResourcePath: string,
      isEdit: boolean = false
  ): any {
    let noteTypes = this.sortPipe.transform(this.masterNoteTypes, 'name');

    return this.dialog.open(
        ManageNotesComponent, {
          width: '', height: '',
          data: {
            title: noteTitle,
            note: noteText,
            noteTypes: noteTypes,
            selectedNoteType: noteType,
            isNoteEditMode: isEdit,
            selectedNoteTypes: this.getSelectedNoteType(noteTypes, noteType),
            methodCodes: noteMethodCodes,
            resourcePath: noteResourcePath,
            isMultiSelect: false
          }
        }
    );
  }
  private getSelectedNoteType(noteTypes: any, noteType: any) {
    let selectedNotes = [];
    if (noteTypes && noteTypes.length > 0 && noteType) {
      let selectedNoteTypes = noteTypes.filter(note => note.code === noteType);
      let chip = new Chip();
      chip.selected = true;
      chip.value = selectedNoteTypes[0];
      chip.restricted = false;
      selectedNotes.push(chip);
    }
    return selectedNotes;
  }

  public onHeaderClick() {
    this.noteSummary = null;
  }

  public onOutsideClick() {
    this.noteSummary = null;
  }

  private saveItemNote(result: any) {
    if (!this.readonly && result) {
      const note = new BookingNote();
      note.note = result.note;
      note.type = result.selectedNoteTypes && result.selectedNoteTypes.length > 0 ? result.selectedNoteTypes[0].value.code : 'BOOKING';
      if (!this.noteList) {
        this.noteList = [];
      }
      this.noteList.push(note);
      this.changeValidity.emit(true);
      this.onNoteListUpdate();
    }
  }
}
