<!--summary strip-->
<div
        class="groupmaster-wholeseller-additional-contacts-edit-card"
        tcExpansionPanel
        [include]= "['tc-dialog', 'mat-option', 'mat-radio-button']"
        [enabled]="documentList.length>0"
        [id]="'document_summary_strip'">
    <tc-summary-strip
            *tcExpansionPanelSummary
            [summaryContent]="documentSummary"
            class="gwace-summary-strip-wrapper">
    </tc-summary-strip>
    <div
            *tcExpansionPanelDetail>
        <mat-accordion *ngIf="documentList.length>0" [multi]=false class="additional-contacts-list-wrapper">
            <ng-container *ngFor="let document of documentList; index as i">
                <mat-expansion-panel
                        #expPanel
                        [attr.id]="'expansionPanel-'+i"
                        [hideToggle]="hideToggle">
                    <mat-expansion-panel-header [collapsedHeight]="'48px'" [expandedHeight]="'60px'">
                        <tc-document-summary-card
                                class="card-strip"
                                [hidden]="expPanel.expanded"
                                [documentContent]="documentContents[i]"
                                [isEditable]="true"
                                [documentCategoryMap]="categories"
                                [nameCodeMap]="nameCodeMap"
                                [selectedDocument]="document"
                                [showIcons]="selectedIndex === index"
                                (mouseleave)="hideIcons(index)"
                                (mouseenter)="showIcons()"
                                (deleteDocument)="onDelete($event)"
                                class="gwacs-edit-card-wrapper">

                        </tc-document-summary-card>
                        <div [hidden]="!expPanel.expanded" class="expand-heading">
                            <tc-document-summary-card *ngIf="documentContents"
                                                      [isEditable]="true"
                                                      [documentContent]="documentContents[i]"
                                                      [selectedDocument]="document"
                                                      [isHeader]="true"
                                                      (deleteDocument)="onDelete($event)"
                                                      class="groupmaster-wholesaler-additional-contact-summary-card">
                            </tc-document-summary-card>
                        </div>

                    </mat-expansion-panel-header>
                    <tc-document-edit-card
                            [document]="document"
                            [contactCategoryOptions]="contactCategoryOptions"
                            [dispatchTypes]="dispatchTypes"
                            [formatOptions]="formatOptions"
                            [frequencyOptions]="frequencyOptions"
                            [documentContent]="documentContents[i]"
                            (changeFormValidity)="checkValidity(document, $event)">

                    </tc-document-edit-card>
                </mat-expansion-panel>
                <hr/>
            </ng-container>
        </mat-accordion>
    </div>
</div>
