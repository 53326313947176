import { Component, OnInit } from '@angular/core';
import { TcErrorType } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-error';
import { Common } from '@tc-core/util/common/common';
import { SupplierService } from '../../../services/backend-consumers/supplier-service/supplier.service';
import { DataKey, DataStoreService } from '../../../services/util/framework/data-store.service';

@Component({
    selector: 'tc-file-uploader',
    templateUrl: './file-uploader.component.html'
})
export class FileUploaderComponent implements OnInit {
    fileToUpload: File = null;

    constructor(
        private dataStore: DataStoreService
    ) {}

    ngOnInit() {
    }

    public uploadFile() {
        this.dataStore.set(DataKey.popupClose, true);
        this.dataStore.set(DataKey.fileToUpload, this.fileToUpload, true);
    }

    public closePopUp() {
        this.dataStore.set(DataKey.popupClose, true);
    }

    public handleFileInput(files: any) {
        this.fileToUpload = files.item(0);
    }
}
