/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../availability-calendar/availability-calendar.component.ngfactory";
import * as i2 from "../availability-calendar/availability-calendar.component";
import * as i3 from "./res-availability-calendar-focus-view.component";
import * as i4 from "../../../services/backend-consumers/supplier-service/resource-availability.service";
import * as i5 from "../../../services/backend-consumers/reservation-management-services/trs-reservation-management.service";
import * as i6 from "@tc-core/util/ui/spinner.service";
import * as i7 from "../../../services/util/framework/data-store.service";
import * as i8 from "@tc-core/util/framework/event-manager.service";
import * as i9 from "@tc-core/util/framework/config-loader.service";
import * as i10 from "@tc/focus-view/focus-view.service";
import * as i11 from "../../../services/util/framework/dmc-query-params.service";
import * as i12 from "../../../services/util/core-services/root.service";
import * as i13 from "@tc-core/util/framework/focus-view-manager.service";
import * as i14 from "@tc/dialog/dialog.service";
import * as i15 from "@tc-core/util/helpers/common-helper.service";
import * as i16 from "../../../services/util/common/dmc-common";
import * as i17 from "../../../services/backend-consumers/reservation-v2/reservation-v2-management.service";
var styles_ResAvailabilityCalendarFocusViewComponent = [];
var RenderType_ResAvailabilityCalendarFocusViewComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ResAvailabilityCalendarFocusViewComponent, data: {} });
export { RenderType_ResAvailabilityCalendarFocusViewComponent as RenderType_ResAvailabilityCalendarFocusViewComponent };
export function View_ResAvailabilityCalendarFocusViewComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-availability-calendar", [], null, [[null, "dateClick"], [null, "sideNavItemClick"], [null, "eventClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("dateClick" === en)) {
        var pd_0 = (_co.handleDateClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("sideNavItemClick" === en)) {
        var pd_1 = (_co.filterSelectedEvents($event) !== false);
        ad = (pd_1 && ad);
    } if (("eventClick" === en)) {
        var pd_2 = (_co.eventClick($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i1.View_AvailabilityCalendarComponent_0, i1.RenderType_AvailabilityCalendarComponent)), i0.ɵdid(1, 114688, null, 0, i2.AvailabilityCalendarComponent, [], { calendarEvents: [0, "calendarEvents"], navConfig: [1, "navConfig"], calendarPlugins: [2, "calendarPlugins"], viewOptions: [3, "viewOptions"] }, { dateClick: "dateClick", eventClick: "eventClick", sideNavItemClick: "sideNavItemClick" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.calendarEvents; var currVal_1 = _co.navConfig; var currVal_2 = _co.calendarPlugins; var currVal_3 = _co.viewOptions; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_ResAvailabilityCalendarFocusViewComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-resource-availability-calendar-focus-view", [], null, null, null, View_ResAvailabilityCalendarFocusViewComponent_0, RenderType_ResAvailabilityCalendarFocusViewComponent)), i0.ɵdid(1, 245760, null, 0, i3.ResAvailabilityCalendarFocusViewComponent, [i4.ResourceAvailabilityService, i5.TrsReservationManagementService, i6.SpinnerService, i7.DataStoreService, i8.EventManager, i9.ConfigLoader, i10.FocusViewService, i11.DMCQueryParamsService, i12.RootService, i13.FocusViewManager, i14.DialogService, i15.CommonHelper, i16.DMCCommon, i17.ReservationV2ManagementService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ResAvailabilityCalendarFocusViewComponentNgFactory = i0.ɵccf("tc-resource-availability-calendar-focus-view", i3.ResAvailabilityCalendarFocusViewComponent, View_ResAvailabilityCalendarFocusViewComponent_Host_0, { resourceSummary: "resourceSummary", navConfig: "navConfig" }, {}, []);
export { ResAvailabilityCalendarFocusViewComponentNgFactory as ResAvailabilityCalendarFocusViewComponentNgFactory };
