import { Component, Input, OnInit } from '@angular/core';
import { CurrencyFormat } from '@tc-core/model/it/codegen/ui/framework/currency-format';
import { SummaryCardData } from '@tc-core/model/it/codegen/ui/framework/summary-card-data';
import { TC } from '@tc-core/util';
import { ConfigLoader } from '@tc-core/util/framework';
import { TCO } from '../../../constants';
import { ResultContentCardComponent } from '../result-content-card/result-content-card.component';

@Component({
  selector: 'tc-result-content-card-resource-assign',
  templateUrl: './result-content-card-resource-assign.component.html'
})
export class ResultContentCardResourceAssignComponent implements OnInit {

    @Input() summaryContent: SummaryCardData;
    @Input() row2col2MatIcon: string;
    @Input() isRow1col4String: boolean = false;
    currencyFormat: CurrencyFormat;
    isUpdated: boolean = false;
    isCancelled: boolean = false;

    row1col1_customsStyles = [];
    row1col2_customsStyles = [];
    row1col3_customsStyles = [];
    row1col4_customsStyles = [];

    row2col1_customsStyles = [];
    row2col2_customsStyles = [];
    row2col3_customsStyles = [];
    row2col4_customsStyles = [];
  constructor(private configLoader: ConfigLoader) {
  }

  ngOnInit() {
    this.overrideStyles();
      this.currencyFormat = this.getCurrencyFormat();
  }

  private overrideStyles(){
    this.row1col1_customsStyles = ["tc-strip-text--primary"];
    this.row1col2_customsStyles = ["tc-strip-text--primary"];
    this.row1col3_customsStyles = [];
    this.row1col4_customsStyles = ["tc-strip-text--right", "tc-strip-text--primary", "tc-strip__price"];

    this.row2col1_customsStyles = [];
    this.row2col2_customsStyles = ["tc-strip-text--primary"];
    this.row2col3_customsStyles = [];
    this.row2col4_customsStyles = ["tc-strip-text--primary", "tc-strip-text--right"];
  }

    private getCurrencyFormat(): CurrencyFormat {
        let currencyFormat;
        let currencySymbolPattern = this.configLoader.configurations.get(TC.CONF.CONF_SYSTEM).defaults.default_currency_symbol_pattern;
        currencyFormat = new CurrencyFormat(TCO.AppData.DEF_CURRENCY_TYPE, currencySymbolPattern);
        return currencyFormat;
    }
}
