import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {MessageThread} from '../../models/notification/message-thread';
import * as moment from 'moment';
import {GenericResourceService} from '../../services/backend-consumers/supplier-service/generic-resource.service';
import {of, Subscription} from 'rxjs';
import {DMCLocalStorageService} from '../../services/util/system/dmc-local-storage.service';
import {NotificationService} from '../../services/backend-consumers/notifications/notification.service';
import {NotificationStateChangeData} from '../../models/notification/notification-state-change-data';
import {UserProfileService} from '../../services/backend-consumers/user-service/user-profile.service';
import {UserProfileSearchCriteria} from '../../models/criteria/user-profile-search-criteria';
import {ResponseUtil} from '../../services/util/response/response-util.service';
import {UserServiceProfile} from '../../models/user/user-service-profile';
import {DataStoreService} from '../../services/util/framework/data-store.service';

@Component({
    selector: 'tc-message-queue-search-results',
    templateUrl: './message-queue-search-results.component.html'
})
export class MessageQueueSearchResultsComponent implements OnInit, OnChanges {

    @Input() messageThreads: MessageThread[] = [];
    @Output() selectedThread: EventEmitter<any> = new EventEmitter(); //output selected thread

    constructor(
        private genericResourceService: GenericResourceService,
        private notificationService: NotificationService,
        private localStorageService: DMCLocalStorageService,
        private dataService: DataStoreService,
        private userProfileService: UserProfileService
    ) {
    }

    ngOnInit() {
        this.processNotifications();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['messageThreads'] && changes['messageThreads'].currentValue) {
            this.messageThreads = changes['messageThreads'].currentValue;
            this.processNotifications();
        }
    }

    /***
     * select thread using the index in array , for secondary selection send id
     * @param index
     * @param id
     */
    selectThread(index: number, thread: MessageThread) {
        this.deselectAll();
        this.localStorageService.delete('resourceId');
        this.localStorageService.delete('isAssignment');
        this.notificationService.updateTotalUnreadMessageCount(this.messageThreads[index].mostRecentNotificationCount);
        this.notificationService.updateUnReadMessageList(this.messageThreads[index]);
        this.messageThreads[index].mostRecentNotificationCount = 0;
        this.messageThreads[index].isSelected = true;
        this.messageThreads[index].hasNewNotification = false;
        if (thread.groupMsgThreadId != null && thread.groupMsgThreadId > 0) {
            this.selectedThread.emit({index: index, id: thread.groupMsgThreadId});
        } else {
            this.selectedThread.emit({index: index, id: thread.supplierId});
        }

        this.updateReadStatus(this.messageThreads[index]);
    }

    updateReadStatus(thread: MessageThread) {
        thread.mostRecentMessages.forEach(msg => {
            if (!msg.sendingDmc) {
                let notificationStateChangeData: NotificationStateChangeData = new NotificationStateChangeData();
                notificationStateChangeData.dmcUser = true;
                notificationStateChangeData.notificationId = msg.notificationId;
                notificationStateChangeData.resourceId = msg.resourceId;
                notificationStateChangeData.supplierId = msg.supplierId;
                this.notificationService.changeNotificationState(notificationStateChangeData).subscribe(
                    response => {

                    });
            }

        });
    }

    /***
     * shorten names and process dates for display
     */
    processNotifications() {
        this.messageThreads.forEach(thread => {
            if (thread.mostRecentMessages.length > 0 && thread.mostRecentMessages[0].type !== 'ASSIGNMENT_UPDATE') {
                thread.mostRecentMessages[0].description.length > 44 ?
                    thread.mostRecentShortDescription = thread.mostRecentMessages[0].description.slice(0, 44) + '...' :
                    thread.mostRecentShortDescription = thread.mostRecentMessages[0].description;
            }

            if (thread.groupMsgThreadId > 0) {
                thread.chatHeadDisplayNameShort = this.setShortName(thread.groupThreadName);
            } else {

                if (thread.resourceName) {
                    thread.chatHeadDisplayNameShort = this.setShortName(thread.resourceName);
                } else if (thread.supplierName) {
                    thread.chatHeadDisplayNameShort = this.setShortName(thread.supplierName);
                }

            }
            if (moment(thread.latestTime).format('MMM Do YY') !== moment().format('MMM Do YY')) {
                thread.displayTimeStamp = moment(thread.latestTime).format('MMM Do, h:mm a');
            } else {
                thread.displayTimeStamp = moment(thread.latestTime).calendar();
            }
            let id = thread.resourceId !== null ? thread.resourceId : thread.supplierId;
            let searchCriteria: UserProfileSearchCriteria = new UserProfileSearchCriteria();
            searchCriteria.resourceId = id;
            let subcriberForDP: Subscription = this.userProfileService.getResourceServiceProfilesWithoutKey(
                searchCriteria).subscribe(
                result => {
                    if (result.data.length > 0) {
                        result.data.forEach(user => {
                            if (thread.resourceId === user.resourceId) {
                                thread.resourceTypeName = user.serviceTypeName;
                            }
                        });
                    }
                    searchCriteria.resourceId = null;
                    thread.icon = of('assets/img/user-profile/gender_unknown_user.svg');
                    let data = ResponseUtil.getDataArray<UserServiceProfile>(result);
                    if (data[0] && data[0].imageUrl && data[0].imageUrl != null) {
                        thread.icon = of(data[0].imageUrl);
                    }
                    subcriberForDP.unsubscribe();
                }
            );

        });
    }

    setShortName(name: string): string {
        return name.length > 22 ? name.slice(0, 22) + '...' : name;
    }

    deselectAll() {
        this.messageThreads.forEach(thread => {
            thread.isSelected = false;
        });
    }

}
