import { ResourceCostSetup } from './resource-cost-setup';

export class GenResourceCostSetup extends ResourceCostSetup {
    profileType: any;
    resourceType: any;
}

export class GenResourceCost {
    tempCostId: number;
    supplierId: number;
    seasonId: number;
    profileType: string;
    resourceType: string;
    days: string;
    currency: any;
    costingType: string;
    adultFare: number;
    childFare: number;
    infantFare: number;
    teenFare: number;
    unitFare: number;
    seasonName: string;
}
