import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MatDatepickerModule,
  MatIconModule,
  MatMenuModule,
  MatNativeDateModule,
  MatTooltipModule
} from '@angular/material';
// import { BodyViewYearComponent } from '@tc/cg-calendar/body-view-year/body-view-year.component';
// import { CalendarBodyComponent } from '@tc/cg-calendar/calendar-body/calendar-body.component';
// import { CalendarHeaderControllerComponent } from '@tc/cg-calendar/calendar-header-controller/calendar-header-controller.component';
// import { MonthDateDirective } from '@tc/cg-calendar/month-directive/month-date.directive';
// import { MonthHeaderDirective } from '@tc/cg-calendar/month-header/month-header.directive';
// import { SingleYearComponent } from '@tc/cg-calendar/single-year/single-year.component';
import { ChipDropdownModule } from '@tc/chip-dropdown';
import { BodyViewYearComponent } from './body-view-year/body-view-year.component';
import { CalendarBodyComponent } from './calendar-body/calendar-body.component';
import { CalendarHeaderControllerComponent } from './calendar-header-controller/calendar-header-controller.component';
import { CgCalendarComponent } from './cg-calendar.component';
import { MonthDateDirective } from './month-directive/month-date.directive';
import { MonthHeaderDirective } from './month-header/month-header.directive';
import { SingleYearComponent } from './single-year/single-year.component';
import {TranslateModule} from '@ngx-translate/core';
import {MatButtonModule} from "@angular/material/button";

@NgModule({
    imports: [
        CommonModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatIconModule,
        MatMenuModule,
        MatTooltipModule,
        ChipDropdownModule,
        TranslateModule,
        MatButtonModule
    ],
  declarations: [
    CalendarHeaderControllerComponent,
    CgCalendarComponent,
    CalendarBodyComponent,
    BodyViewYearComponent,
    SingleYearComponent,

    MonthHeaderDirective,
    MonthDateDirective
  ],
  exports: [CgCalendarComponent],
  entryComponents: [CgCalendarComponent]
})
export class CgCalendarModule {}
