import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfigLoader } from '@tc-core/util/framework';
import { IGetRowsParams } from 'ag-grid-community';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SetupGridComp } from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid-comp';
import { SetupGridDataSource } from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid-data-source';
import { SetupGridComponent } from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid.component';
import { TCO } from '../../../constants';
import { SortDirection } from '../../../models/helper/sort-direction';
import { AccountingRuleService } from '../../../services/backend-consumers/setups/accounting-rule.service';
import { DataKey, DataStoreService } from '../../../services/util/framework/data-store.service';
import { LedgerAccountSearchCriteria } from '../../../models/criteria/ledger-account-search-criteria';

@Component({
  selector: 'tc-ledger-account-setup',
  templateUrl: './ledger-account-setup.component.html'
})
export class LedgerAccountSetupComponent extends SetupGridComp implements OnInit, SetupGridDataSource {

  @ViewChild('setupGrid')
  private setupGrid: SetupGridComponent;
  public colDefConfig = [];

  // search criteria obj. used to
  private accountSearchCriteria: LedgerAccountSearchCriteria = new LedgerAccountSearchCriteria();

  // required options for grid editors
  genericTypes: any[] = [];
  GENERIC_CATEGORY = 'generic';

  constructor(
    private configLoader: ConfigLoader,
    private accountingRuleService: AccountingRuleService,
    private dataStoreService: DataStoreService
  ) {
    super();
  }

  ngOnInit() {
    this.colDefConfig = this.configLoader.configurations.get(TCO.CONF.CONF_LEDGER_ACCOUNT_SETUP);
    this.subscribeSearchCriteria();
    this.fillRequiredGridOptionData();
  }

  /**
   * subscribe search criteria changes in search bar
   * then force grid to refresh data
   * grid refresh will be call getRaws method which is using this subscribed criteria for backend call
   */
  subscribeSearchCriteria() {
    this.dataStoreService.get(DataKey.ledgerAccountsSearchCriteria).subscribe(value => {
      this.accountSearchCriteria = value;
      this.setupGrid.runForceSearch();
    });
  }

  public isInvalidRow(params): boolean {
    const data = params.data;
    if (data) {
      let invalid = true;
      if (
        data['code'] != null &&
        data['code'] != '' &&
        data['name'] != null &&
        data['name'] != ''
      ) {
        invalid = false;
      }
      return invalid;
    } else {
      return false;
    }
  }

  public isUnsavedRow(row: any): boolean {
    return !!row.unsaved;
  }

  public deleteRow(row: any) {
    if (row && row.code) {
      return this.accountingRuleService.deleteLedgerAccount(row.code);
    } else {
      return of('success');
    }
  }

  public saveRow(row: any, event: any) {
    return this.accountingRuleService.saveLedgerAccount(row);
  }

  public getRows(params: IGetRowsParams): Observable<any> {
    console.log('fetch requesting for ' + params.startRow + ' to ' + params.endRow);
    console.log(params.sortModel);

    const pageSize = params.endRow - params.startRow;
    if (!this.accountSearchCriteria) {
      this.accountSearchCriteria = new LedgerAccountSearchCriteria();
    }
    this.accountSearchCriteria.start = params.startRow;
    this.accountSearchCriteria.size = pageSize;
    if (params.sortModel && params.sortModel[0]) {
      this.accountSearchCriteria.sortBy = params.sortModel[0].colId;
      if (params.sortModel[0].sort === SetupGridComp.GRID_SORT_ASCENDING) {
        this.accountSearchCriteria.sortDirection = SortDirection.ASC;
      } else {
        this.accountSearchCriteria.sortDirection = SortDirection.DESC;
      }
    }
    return this.accountingRuleService.getLedgerAccounts(this.accountSearchCriteria)
      .pipe(
        tap(data =>
          this.dataStoreService.set(DataKey.ledgerAccountsSearchResultsForCriteria, data)
        )
      );
  }

  public createNewRow(): any {
    return {
      unsaved: true
    };
  }

  /*
   grid config methods which are called by grid setup (ag grid)
   and set by grid config
   */

  getCurrencies = (params, rowData, text) => {
    return this.dataStoreService.get(DataKey.currencyList)
      .map(
        currencies =>
          currencies.filter(currency => {
            if (text) {
              if (currency.name) {
                return currency.name.toLowerCase().indexOf(text.toLocaleString()) > -1 ||
                  currency.code.toLowerCase().indexOf(text.toLocaleString()) > -1;
              }
            } else {
              return true;
            }
          }
          )
      );
  };

  onCurrencySet = function (params) {
    if (params.data && params.newValue) {
      if (params.newValue['code']) {
        params.data['currency'] = params.newValue['code'];
      } else {
        params.data['currency'] = params.newValue;
      }
    }
    return true;
  };


  private fillRequiredGridOptionData() {

  }

  public cellEditingStart: any = (event) => {
    // if (event && event.colDef) {
    //     // if (event.colDef.colId === 'account') {
    //     // }
    // }
  }
}
