import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NoteInfoCardComponent } from './note-info-card.component';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule.forChild()
    ],
    declarations: [NoteInfoCardComponent],
    exports: [NoteInfoCardComponent]
})
export class NoteInfoCardModule {}
