<div class="container-fluid tc-item-wrapper tcpi-card">
    <div class="tc-item-wrapper__body">
        <div class="row">
            <div class="col-md-12">
                <div class="tc-flex-box">
                    <div class="tc-flex-item icon-cage input-icon">
                        <div class="card-icon--small icn-color-primary"><i class="material-icons">shop_two</i>
                        </div>
                    </div>
                    <div class="tc-flex-item input-cage">
                        <div class="col-xs-12 col-sm-6  col-md-3 col-lg-3">
                            <div class="tc-content--wrap tc-input-view">
                                <div class="tc-caption-txt txt-light">
                                    {{"Booking ID" | translate}}
                                </div>
                                <div id="booking-id" class="tc-body1--txt">{{booking?.bookingIdCombined ? booking.bookingIdCombined : EMPTY_CHAR}}
                                </div>
                            </div>
                        </div>
<!--                        todo: For FC scope this is not needed-->
<!--                        <div class="col-xs-12 col-sm-6  col-md-3 col-lg-3">-->
<!--                            <div class="tc-content&#45;&#45;wrap tc-input-view">-->
<!--                                <div class="tc-caption-txt txt-light">{{"Created Date" | translate}}-->
<!--                                </div>-->
<!--                                <div id = "created-date" class="tc-body1&#45;&#45;txt  ">{{(booking?.createdOn ? booking.createdOn : EMPTY_CHAR) | date:'yyyy-MM-dd':'GMT' }}-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
                        <div class="col-xs-12 col-sm-6  col-md-3 col-lg-3">
                            <div class="tc-content--wrap tc-input-view">
                                <div class="tc-caption-txt txt-light">{{"No. of Generic Items" | translate}}
                                </div>
                                <div id="no-of-gen-items" class="tc-body1--txt  ">{{bookingItemsCountByCategory.GEN > 0 ? bookingItemsCountByCategory.GEN : EMPTY_CHAR}}
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-6  col-md-3 col-lg-3">
                            <div class="tc-content--wrap tc-input-view">
                                <div class="tc-caption-txt txt-light">{{"No. of Transport Items" | translate}}
                                </div>
                                <div id="no-of-transport-items" class="tc-body1--txt  ">{{bookingItemsCountByCategory.TRS > 0 ? bookingItemsCountByCategory.TRS : EMPTY_CHAR}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="tc-flex-box">
                    <div class="tc-flex-item icon-cage ">
                        <div class="card-icon--small icn-color-primary"></div>
                    </div>
                    <div class="tc-flex-item input-cage ">
                        <div class="col-xs-12 col-sm-6  col-md-3 col-lg-3">
                            <div class="tc-content--wrap tc-input-view">
                                <div
                                        class="tc-caption-txt txt-light">{{"Booking Date & Time" | translate}}
                                </div>
                                <div id="booking-date-time" class="tc-body1--txt">{{(booking?.bookingDate ? booking.bookingDate : EMPTY_CHAR) | date: DATE_FORMAT:getTimeZone()}}</div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-6  col-md-3 col-lg-3">
                            <div class="tc-content--wrap tc-input-view">
                                <div class="tc-caption-txt txt-light">{{"Service Start Date & Time" | translate}}
                                </div>
                                <div id="start-date-time" class="tc-body1--txt">{{(booking?.startTime ? booking.startTime : EMPTY_CHAR) | date: DATE_FORMAT:getTimeZone()}}</div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-6  col-md-3 col-lg-3">
                            <div class="tc-content--wrap tc-input-view">
                                <div
                                        class="tc-caption-txt txt-light">{{"Service End Date & Time" | translate}}
                                </div>
                                <div id="end-date-time" class="tc-body1--txt">{{(booking?.endTime ? booking.endTime : EMPTY_CHAR) | date: DATE_FORMAT:getTimeZone()}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="tc-flex-box">
                    <div class="tc-flex-item icon-cage ">
                        <div class="card-icon--small icn-color-primary"></div>
                    </div>
                    <div class="tc-flex-item input-cage ">
                        <div class="col-xs-12 col-sm-6  col-md-3 col-lg-3">
                            <div class="tc-content--wrap tc-input-view">
                                <div class="tc-caption-txt txt-light">{{"Adults" | translate}}
                                </div>
                                <div id="adults" class="tc-body1--txt">{{ passengerCount.Adult > 0 ? passengerCount.Adult : EMPTY_CHAR }}</div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-6  col-md-3 col-lg-3">
                            <div class="tc-content--wrap tc-input-view">
                                <div class="tc-caption-txt txt-light">{{"Children" | translate}}
                                </div>
                                <div id="children" class="tc-body1--txt">{{ passengerCount.Children > 0 ? passengerCount.Children : EMPTY_CHAR }}</div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-6  col-md-3 col-lg-3">
                            <div class="tc-content--wrap tc-input-view">
                                <div class="tc-caption-txt txt-light">{{"Teen" | translate}}
                                </div>
                                <div id="teen" class="tc-body1--txt  ">{{ passengerCount.Teen > 0 ? passengerCount.Teen : EMPTY_CHAR }}
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-6  col-md-3 col-lg-3">
                            <div class="tc-content--wrap tc-input-view">
                                <div class="tc-caption-txt txt-light">{{"Infant" | translate}}
                                </div>
                                <div id="infant" class="tc-body1--txt  ">{{ passengerCount.Infant > 0 ? passengerCount.Infant : EMPTY_CHAR }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
