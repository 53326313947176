<ag-grid-angular
        id="supplement-group"
        class="ag-theme-material tc-ag-grid tc-ag-grid--full-width tc-ag-grid--popup-content"
        [rowData]="supplementGroup?.supplementNotes"
        [gridOptions]="gridOptions"
        [columnDefs]="columnDefs"
        [defaultColDef]="defaultColDef"
        [frameworkComponents]="frameworkComponents"
        (gridReady)="onGridReadyBookingSupplements($event)"
        (firstDataRendered)="onFirstDataRendered($event)">
</ag-grid-angular>


