import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ModalModule} from '@tc/modal';
import {MatExpansionModule, MatInputModule, MatMenuModule, MatSelectModule} from '@angular/material';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {MatAutocompleteModule} from '@angular/material/typings/autocomplete';
import {MatFormFieldModule} from '@angular/material/typings/form-field';
import {PipesModule} from '@tc/pipes';
import {ExpandedContentCardBodyModule} from '@tc/expanded-content-card-body';
import {ContentStripModule} from '@tc/content-strip';
import {DirectivesModule} from '@tc/directives';
import {SummaryStripModule} from '@tc/summary-strip';
import {DocumentViewCardComponent} from './document-view-card.component';
import {ResultContentCardModule} from '../result-content-card/result-content-card.module';
import {DocumentAddCardModule} from '../document-add-card/document-add-card.module';
import {DocumentEditCardModule} from '../document-edit-card/document-edit-card.module';
import {DocumentSummaryCardModule} from '../document-summary-card/document-summary-card.module';

@NgModule({
    imports: [
        CommonModule,
        ModalModule,
        MatInputModule,
        ReactiveFormsModule,
        TranslateModule.forChild(),
        PipesModule,
        ExpandedContentCardBodyModule,
        MatExpansionModule,
        ContentStripModule,
        ResultContentCardModule,
        DirectivesModule,
        SummaryStripModule,
        DocumentAddCardModule,
        DocumentEditCardModule,
        DocumentSummaryCardModule
    ],
    exports: [DocumentViewCardComponent],
    declarations: [DocumentViewCardComponent]
})
export class DocumentViewCardModule {
}
