import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LEVEL } from '@tc-core/model/it/codegen/ui/framework/dialog-model';
import { ModalData } from '@tc-core/model/it/codegen/ui/framework/modal-data';
import { ModalService } from '@tc/modal/modal.service';
import { Language, PreferredRoute, PreferredTransferMode } from '../../../../models/resource/generic-resource';
import { Resource } from '../../../../models/resource/resource';
import { ChangeDetectSupporter } from '../../../../models/supplier/change-detect-supporter';
import { DataHandlerService } from '../../../../services/backend-consumers/master-data-handler-service/data-handler.service';
import { ProfileManagementService } from '../../../../services/backend-consumers/supplier-service/profile-management.service';
import { DataKey, DataStoreService } from '../../../../services/util/framework/data-store.service';
import { AddMoreLangDialogComponent } from '../../shared/add-more-lang-dialog/add-more-lang-dialog.component';

@Component({
    selector: 'tc-driver-specific',
    templateUrl: './driver-specific.component.html'
})
export class DriverSpecificComponent implements OnInit, OnDestroy {

    @Input() resource: Resource;
    @Input() changeDetectSupporter: ChangeDetectSupporter;

    @Output() changeFormValidity: EventEmitter<boolean> = new EventEmitter();

    defaultLanguages = [];
    addedLanguages = [];
    languages: string[] = [];
    selectedRoutes: string[] = [];
    selectedTrsModes: string[] = [];

    selectedFromAddedLanguages = [];

    routes: any[] = [];
    transferModes: any[] = [];

    driverSpecificForm: FormGroup;

    constructor(
        private profileManagementService: ProfileManagementService,
        private dataStore: DataStoreService,
        private fb: FormBuilder,
        private modalService: ModalService,
        private dataHandlerService: DataHandlerService
    ) {}

    ngOnInit() {
        this.changeDetectSupporter.checkedSpecific=true;

        this.createForm();
        this.retrieveMasterData();

        this.dataStore.set(DataKey.languages, null, true);
        this.getLanguages();

        this.driverSpecificForm.valueChanges.subscribe(
            (data) => {
                if (JSON.stringify(data) !== JSON.stringify({})) {
                    this.resource.genericResource.workingHours = data.workingHours;
                    this.changeFormValidity.emit(this.driverSpecificForm.valid);

                }
            }
        );

        this.profileManagementService.retrieveDefaultLanguages().subscribe(result => {
            this.defaultLanguages = result['data'];

            // adding languages to form
            this.setLanguagesFromResource();
        });

        this.validateForm();
    }

    public validateForm() {
        Object.keys(this.driverSpecificForm.controls).forEach(field => {
            const control = this.driverSpecificForm.get(field);
            control.markAsTouched({onlySelf: true});
        });
    }

    private createForm() {
        this.driverSpecificForm = this.fb.group({
            workingHours: [
                this.resource.genericResource ? this.resource.genericResource.workingHours : '',
                Validators.required
            ],
            preferredRoutes: [this.resource.genericResource ? this.selectedRoutes : ''],
            preferredTransferModes: [
                this.resource.genericResource ?
                    this.selectedTrsModes :
                    ''
            ],

            langs: [this.languages]
        });

        this.changeFormValidity.emit(this.driverSpecificForm.valid);
    }

    retrieveMasterData() {
        this.dataHandlerService.retrieveRoutes().subscribe(
            result => {
                this.selectedRoutes = [];
                this.routes = result.data;
                if(this.resource && this.resource.genericResource && this.resource.genericResource.preferredRoutes){
                    this.resource.genericResource.preferredRoutes.forEach(value => {
                        this.selectedRoutes.push(value.route);
                    });
                }
            });

        this.dataHandlerService.retrieveTransferModes().subscribe(
            result => {
                this.selectedTrsModes = [];
                this.transferModes = result.data;
                if(this.resource && this.resource.genericResource && this.resource.genericResource.preferredTransferModes) {
                    this.resource.genericResource.preferredTransferModes.forEach(value => {
                        this.selectedTrsModes.push(value.transferMode);
                    });
                }
            });
    }

    addTrsMode(event){
        this.resource.genericResource.preferredTransferModes = [];
        this.selectedTrsModes.forEach(value => {
            this.resource.genericResource.preferredTransferModes.push(new PreferredTransferMode(value))
        });
        this.changeFormValidity.emit(this.driverSpecificForm.valid);
    }

    addRoute(event){
        this.resource.genericResource.preferredRoutes = [];
        this.selectedRoutes.forEach(value => {
            this.resource.genericResource.preferredRoutes.push(new PreferredRoute(value))
        });
        this.changeFormValidity.emit(this.driverSpecificForm.valid);
    }

    private getLanguages() {
        this.dataStore.get(DataKey.languages).subscribe(data => {
            if (data != null) {
                this.addedLanguages = data;
                this.addedLanguages.forEach(lang => {
                        if (!this.languages.includes(lang)) {
                            this.languages.push(lang);
                            if (!this.selectedFromAddedLanguages.includes(lang)) {
                                this.selectedFromAddedLanguages.push(lang);
                            }
                        }
                    }
                );
                this.languages.forEach(
                    lang => {
                        if (!this.addedLanguages.includes(lang) && !this.findLang(lang, this.defaultLanguages)) {
                            this.languages.splice(this.languages.indexOf(lang), 1);
                        }
                    }
                );

                this.updateLanguagesInResource();
            }
        });
    }

    setLanguagesFromResource() {
        this.languages = [];
        this.addedLanguages = [];
        this.selectedFromAddedLanguages = [];
        if (this.resource && this.resource.genericResource && this.resource.genericResource.languages) {
            this.resource.genericResource.languages.forEach(value => {
                this.languages.push(value.language);
                if (!this.addedLanguages.includes(value.language) && !this.findLang(value.language,this.defaultLanguages)) {
                    this.addedLanguages.push(value.language);
                    this.selectedFromAddedLanguages.push(value.language);
                }
            });
        }
    }

    findLang(lang: string, arr: any[]): any {
        return arr.find(value => value.name === lang);
    }

    toggleValue(language: string) {
        if (!this.languages.includes(language)) {
            this.languages.push(language);
            console.log(this.languages);
        } else {
            this.languages.splice(this.languages.indexOf(language), 1);
            if (this.selectedFromAddedLanguages.includes(language)) {
                this.selectedFromAddedLanguages.splice(this.selectedFromAddedLanguages.indexOf(language), 1);
            }

        }
        this.updateLanguagesInResource();
        this.changeFormValidity.emit(this.driverSpecificForm.valid);

    }

    public openDialog(): void {

        let dataObject = new ModalData(LEVEL.SUCCESS, 'Add More Languages',

            {'label': 'OK'},
            {'label': 'Close'},
            null,
            AddMoreLangDialogComponent,
            {'selectedLanguages': this.selectedFromAddedLanguages}
        );

        this.confirmModal(dataObject);

    }

    private confirmModal(data: any): void {
        this.modalService.confirm(data).subscribe((res) => {
            console.log(res);
            this.changeFormValidity.emit(this.driverSpecificForm.valid);
        });
    }

    private updateLanguagesInResource() {
        if (this.resource.genericResource.languages) {
            this.resource.genericResource.languages.forEach((lang: Language) => {
                if (!this.languages.includes(lang.language)) {
                    this.resource.genericResource.languages = this.resource.genericResource.languages.filter(
                        l => l !== lang
                    );
                }
            });
            this.languages.forEach((langStr: string) => {
                let langIn: boolean = false;
                this.resource.genericResource.languages.forEach((lang: Language) => {
                    if (lang.language === langStr) {
                        langIn = true;
                    }
                });
                if (!langIn) {
                    this.resource.genericResource.languages.push(new Language(langStr));
                }
            });
        }
    }

    public getErrorMessage(field: string): string {
        switch (field) {
            case 'workingHours' :
                return this.driverSpecificForm.get('workingHours').hasError('required') ?
                    'Working hours required' :
                    '';
            case 'preferredRoutes' :
                return this.driverSpecificForm.get('preferredRoutes').hasError('required') ?
                    'Preferred route required' :
                    '';
            case 'preferredTransferModes' :
                return this.driverSpecificForm.get('preferredTransferModes').hasError('required') ?
                    'Preferred Transfer Mode required' :
                    '';
            default :
                return '';
        }
    }

    public ngOnDestroy(): void {
        this.changeDetectSupporter.checkedSpecific = false;
    }

}
