import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTable, MatTableDataSource } from '@angular/material';
import { TC } from '@tc-core/util/constants';
import { SupplierAttributes } from '../../../models/common/supplier-attributes';

@Component({
    selector: 'tc-supplier-attributes-card',
    templateUrl: './supplier-attributes-card.component.html'
})
export class SupplierAttributesCardComponent implements OnInit, OnChanges {

    EMPTY_CHAR = TC.FORMAT.EMPTY_CHAR;

    @Input() supplierAttributes: SupplierAttributes[]=[];
    @Input() showTitle: boolean = true;

    displayedColumns: string[] = ['index', 'attributeCode', 'attributeValue'];
    dataSource =  new MatTableDataSource<SupplierAttributes>(this.supplierAttributes);

    @ViewChild(MatTable) table: MatTable<SupplierAttributes>;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    constructor() { }

    ngOnInit() {
        this.dataSource.data = this.supplierAttributes;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    }

    public ngOnChanges(changes: SimpleChanges): void {
        for (let propName in changes) {
            if (propName === 'supplierAttributes') {
                this.dataSource.data = this.supplierAttributes;
                this.dataSource.paginator = this.paginator;
                this.dataSource.sort = this.sort;
            }
        }
    }

}
