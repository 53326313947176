import {Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren} from '@angular/core';
import { SummaryCardData } from '@tc-core/model/it/codegen/ui/framework/summary-card-data';
import { Booking } from '../../../../models/reservation-v2/booking';
import { BookingItem } from '../../../../models/reservation-v2/booking-item';
import { BookingNoteType } from '../../../../models/reservation-v2/booking-note';
import { ExpansionPanelDirective } from '@tc/directives/expansion-panel';
import { MainType } from '../../../../models/reservation-v2/main-type';

@Component({
    selector: 'tc-booking-items',
    templateUrl: './booking-items.component.html',
    styleUrls: ['./booking-items.component.scss']
})
export class BookingItemsComponent implements OnInit {

    @Input() booking: Booking;
    @Input() readonly = false;
    @Input() summaryCards: SummaryCardData[] = [];
    @Input() summaryCardsData: SummaryCardData = new SummaryCardData();
    @Input() noteTypes: BookingNoteType[] = [];
    @Input() trsModes: any[] = [];

    @Output() passengersClick: EventEmitter<BookingItem> = new EventEmitter();
    @Output() changeValidity: EventEmitter<boolean> = new EventEmitter();
    @Output() deleteItem: EventEmitter<BookingItem> = new EventEmitter();
    @Output() editAsNewItem: EventEmitter<BookingItem> = new EventEmitter();
    @ViewChildren(ExpansionPanelDirective) _expansionPanels: QueryList<ExpansionPanelDirective>;

    public hoveredIndex = -1;
    public selectedItem: BookingItem;
    allPassengerIds = [];

    private validityMap: Map<BookingItem, boolean> = new Map();

    constructor() { }

    ngOnInit() {
        if(this.booking.bookingPassengers && this.booking.bookingPassengers.length > 0)
        {
            this.booking.bookingPassengers.forEach(value => this.allPassengerIds.push(value.tempId));
        }
    }

    public getItemType(bookingItem: BookingItem) {
        if (bookingItem.trsBookingItem != null) {
            return 'trs';
        } else if (bookingItem.genBookingItem != null && bookingItem.mainType !== MainType.HTL) {
            return 'gen';
        } else if (bookingItem.genBookingItem != null && bookingItem.mainType === MainType.HTL) {
            return 'accom';
        }
    }

    public getSameTypeItems(type: any, bookingItem: BookingItem) {
        if(this.booking && this.booking.bookingItems)
        {
            if(type === 'trs') {
                return this.booking.bookingItems.filter(value => value.trsBookingItem !== null && value.bookingReferenceId !== bookingItem.bookingReferenceId);
            } else if (type === 'accom') {
                return this.booking.bookingItems.filter(value => value.genBookingItem !== null  && value.mainType === MainType.HTL && value.bookingReferenceId !== bookingItem.bookingReferenceId);
            } else  {
                return this.booking.bookingItems.filter(value => value.genBookingItem !== null  && value.mainType !== MainType.HTL && value.bookingReferenceId !== bookingItem.bookingReferenceId);
            }
        }

    }

    public hideIcons() {
        this.hoveredIndex = -1;
    }

    public showIcons(index) {
        this.hoveredIndex = index;
    }

    onDelete(event: BookingItem) {
        this.deleteItem.emit(event);
    }

    editAsNew(event: BookingItem) {
        this.editAsNewItem.emit(event);
    }

    onPassengersClick(event: BookingItem) {
        this.passengersClick.emit(event);
    }

    public onChangeValidity(bookingItem: BookingItem, event: boolean) {
        this.validityMap.set(bookingItem, event);
        let allValid = true;
        this.validityMap.forEach((value, key) => {
            if (!value) {
                allValid = value;
            }
        });
        this.changeValidity.emit(allValid);
    }

    public onExpand(event, index) {
        this.hoveredIndex = index;
        this.selectedItem = this.booking.bookingItems[index];
    }
}
