export class TrsBookingItem {
    id: number;
    bookingItemId: number;

    airportOperationTimeMinutes: number;
    arrivalAirPort: string;
    arrivalAirlineCode: string;
    arrivalDateTime: Date;
    arrivalFlightNo: string;
    departureAirPort: string;
    departureAirlineCode: string;
    departureDateTime: Date;
    departureFlightNo: string;
    dropOffCity: string;
    dropOffPointCode: string;
    dropOffPointLatitude: number;
    dropOffPointLongitude: number;
    dropOffPointGroup: string;
    dropOffPointName: string;
    dropOffType: string;
    durationMinutes: number;
    embarkationTimeMinutes: number;
    flightArrTerminal: string;
    flightArrTime: Date;
    flightDeptTerminal: string;
    flightDeptTime: Date;
    pickupCity: string;
    pickupPointCode: string;
    pickupPointLatitude: number;
    pickupPointLongitude: number;
    pickupPointGroup: string;
    pickupPointName: string;
    pickupType: string;
    route: string;
    serviceGrouping: string;
    transferMode: string;
    transferModeName: string;

    vehicleType: string;
    vehicleModel: string;
    vehicleCount: string;

}
