<div class="container-fluid tc-item-wrapper tcpi-card">
    <div class="tc-item-wrapper__header tc-item-header-highlighted" *ngIf="showTitle">
        <div class="tc-item-header-highlighted__icon">
            <i class="material-icons material-icons">credit_card</i>
        </div>
        <div class="tc-item-header-highlighted__primary">{{"Payment Information" | translate}}</div>
    </div>
    <div class="tc-item-wrapper__body">
        <div class="row">
            <div class="col-md-12">
                <div class="tc-flex-box">
                    <div class="tc-flex-item icon-cage input-icon">
                        <div class="card-icon--small icn-color-primary">
                            <i class="material-icons material-icons">credit_card</i>
                        </div>
                    </div>
                    <div class="tc-flex-item input-cage">
                        <div class="col-xs-12 col-sm-6  col-md-3 col-lg-3">
                            <div class="tc-content--wrap tc-input-view">
                                <div class="tc-caption-txt txt-light">
                                    {{"Payment Currency" | translate}}
                                </div>
                                <div
                                        id="TRD_PER_INFO_CLI_NAME"
                                        class="tc-body1--txt">{{paymentInfo?.currency ? paymentInfo?.currency : EMPTY_CHAR}}
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-6  col-md-3 col-lg-3">
                            <div class="tc-content--wrap tc-input-view">
                                <div class="tc-caption-txt txt-light">{{"Payment Method" | translate}}
                                </div>
                                <div
                                        id="TRADE_PER_INFO_CLI_TYPE"
                                        class="tc-body1--txt">{{paymentInfo?.paymentMethod? getPaymentMethodName(paymentInfo.paymentMethod) : EMPTY_CHAR}}
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                            <div class="tc-content--wrap tc-input-view">
                                <div class="tc-caption-txt txt-light">{{"Beneficiary Name" | translate}}
                                </div>
                                <div class="tc-body1--txt  ">{{paymentInfo?.beneficiaryName ? paymentInfo?.beneficiaryName : EMPTY_CHAR}}
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-6  col-md-3 col-lg-3">
                            <div class="tc-content--wrap tc-input-view">
                                <div class="tc-caption-txt txt-light">{{"Bank Name" | translate}}
                                </div>
                                <div class="tc-body1--txt  ">{{paymentInfo?.bankName ? paymentInfo?.bankName : EMPTY_CHAR}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="tc-flex-box">
                    <div class="tc-flex-item icon-cage ">
                        <div class="card-icon--small icn-color-primary"></div>
                    </div>
                    <div class="tc-flex-item input-cage ">
                        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                            <div class="tc-content--wrap tc-input-view">
                                <div class="tc-caption-txt txt-light">{{"Account Number" | translate}}
                                </div>
                                <div class="tc-body1--txt  ">{{paymentInfo?.bankAccNum ? paymentInfo?.bankAccNum : EMPTY_CHAR}}
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                            <div class="tc-content--wrap tc-input-view">
                                <div class="tc-caption-txt txt-light">{{"IBAN" | translate}}
                                </div>
                                <div class="tc-body1--txt  ">{{paymentInfo?.iban ? paymentInfo?.iban : EMPTY_CHAR}}
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                            <div class="tc-content--wrap tc-input-view">
                                <div class="tc-caption-txt txt-light">{{"Sort Code" | translate}}
                                </div>
                                <div class="tc-body1--txt  ">{{paymentInfo?.sortCode ? paymentInfo?.sortCode : EMPTY_CHAR}}
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                            <div class="tc-content--wrap tc-input-view">
                                <div class="tc-caption-txt txt-light">{{"Swift Code" | translate}}
                                </div>
                                <div class="tc-body1--txt  ">{{paymentInfo?.swiftCode ? paymentInfo?.swiftCode : EMPTY_CHAR}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
