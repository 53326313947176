
<ng-container *ngIf="showSidebar">
    <tc-dmc-side-panel
            [navItems]="navItems"
            [cart]="shoppingCart"
            [cartItemCount]="cartItemCount"
            [client]="client"
            [navItemIndex]="selectionIndex"
            [showSideNav]="showSideNav"
            [environmentName]="''"
            [releaseTitle]="releaseTitle"
            (select)="onNavSelect($event)"
            (clientSelect)="onClientSelect($event)"
            (cartSelect)="onCartSelect($event)">
    </tc-dmc-side-panel>
</ng-container>
