export class ContactDetails {
    contactId: number;
    tempId: number;
    category: string;
    title: string;
    firstName: string;
    lastName: string;
    email: string;
    altEmail: string;
    mobNum: string;
    telNum: string;
    altTelNum: string;
    faxNum: string;
    street: string;
    city: string;
    cityName: string;
    state: string;
    stateName: string;
    country: string;
    countryName: string;
    postCode: string;
    status: boolean;
}

