import * as tslib_1 from "tslib";
import { AbstractSearchCriteria } from './abstract-search-criteria';
var LocationsSearchCriteria = /** @class */ (function (_super) {
    tslib_1.__extends(LocationsSearchCriteria, _super);
    function LocationsSearchCriteria() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return LocationsSearchCriteria;
}(AbstractSearchCriteria));
export { LocationsSearchCriteria };
