import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { TimeOffsetSetup } from '../../../../../models/master-data/time-offset-setup';
import { FormBuilder } from '@angular/forms';
import { RoutesSetupService } from '../../../../../services/backend-consumers/deprecated/routes-setup.service';
import { DataKey, DataStoreService } from '../../../../../services/util/framework/data-store.service';
var EditTimeOffsetRulesComponent = /** @class */ (function () {
    function EditTimeOffsetRulesComponent(fb, routeService, dataStore) {
        this.fb = fb;
        this.routeService = routeService;
        this.dataStore = dataStore;
        this.selectedPoints = [];
        this.searchCriteria = ['Airport', 'Cruise Port', 'Hotel', 'Other'];
        this.editLocations = false;
        this.isActive = true;
        this.countryList = [];
        this.regionList = [];
        this.cityList = [];
        this.createForm();
    }
    EditTimeOffsetRulesComponent.prototype.ngOnInit = function () {
        var _this = this;
        console.log(this.searchCriteria);
        this.routeService.regionList().subscribe(function (list) {
            _this.regionList = list['data'];
        });
        this.routeService.countryList().subscribe(function (list) {
            _this.countryList = list['data'];
        });
        this.routeService.cityList().subscribe(function (list) {
            _this.cityList = list['data'];
        });
    };
    EditTimeOffsetRulesComponent.prototype.toggleValue = function (value) {
        this.selectedCriteria = value;
        this.searchLocations();
    };
    EditTimeOffsetRulesComponent.prototype.searchLocations = function () {
        var _this = this;
        this.routeService.getLocationTypes().subscribe(function (data) {
            var e_1, _a;
            if (data != null) {
                try {
                    for (var data_1 = tslib_1.__values(data), data_1_1 = data_1.next(); !data_1_1.done; data_1_1 = data_1.next()) {
                        var key = data_1_1.value;
                        if (key['locationType'] === _this.selectedCriteria) {
                            _this.searchResults = key['locations'];
                        }
                    }
                }
                catch (e_1_1) { e_1 = { error: e_1_1 }; }
                finally {
                    try {
                        if (data_1_1 && !data_1_1.done && (_a = data_1.return)) _a.call(data_1);
                    }
                    finally { if (e_1) throw e_1.error; }
                }
            }
        });
    };
    EditTimeOffsetRulesComponent.prototype.createForm = function () {
        this.timeOffsetForm = this.fb.group({
            region: [''],
            country: [''],
            city: [''],
            locationTimeOffset: this.fb.array([this.createTimeOffset()])
        });
    };
    EditTimeOffsetRulesComponent.prototype.createTimeOffset = function () {
        return this.fb.group({
            pointName: [''],
            timeOffsetVal: ['']
        });
    };
    EditTimeOffsetRulesComponent.prototype.addRule = function (isSelected, loc) {
        if (isSelected.checked) {
            var obj = {
                pickupPointCode: loc['code'],
                pickupPointName: loc['name'],
                pickupTimeOffset: 0
            };
            this.selectedPoints.push(obj);
            this.timeOffsetRule.pickupData.push(obj);
            console.log(this.timeOffsetRule.pickupData);
        }
        else {
            this.timeOffsetRule.pickupData.splice(loc['code'], 1);
            console.log(this.selectedPoints);
        }
        // TODO: should pass the selectedLocations with LocationGroup
        this.dataStore.set(DataKey.locations, this.timeOffsetRule, true);
    };
    EditTimeOffsetRulesComponent.prototype.editTimeOffset = function () {
        this.editLocations = true;
    };
    return EditTimeOffsetRulesComponent;
}());
export { EditTimeOffsetRulesComponent };
