import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatHorizontalStepper } from '@angular/material';
import { TcApiError } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-api-error';
import { TcErrorType } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-error';
import { TcHttpError } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-http-error';
import { TC } from '@tc-core/util';
import { ConfigLoader, EventManager, FocusViewManager, UserJourneyManager } from '@tc-core/util/framework';
import { CommonHelper } from '@tc-core/util/helpers';
import { SpinnerService } from '@tc-core/util/ui';
import { FocusViewService } from '@tc/focus-view/focus-view.service';
import { Subscription } from 'rxjs';
import { LeaveSearchCriteria } from '../../models/criteria/leave-search-criteria';
import { LeaveSetup } from '../../models/leave-setup';
import { LeaveSetupResultItemView } from '../../models/leave-setup-result-item-view';
import { ResourceAvailabilityService } from '../../services/backend-consumers/supplier-service/resource-availability.service';
import { DMCCommon } from '../../services/util/common/dmc-common';
import { DataKey, DataStoreService } from '../../services/util/framework/data-store.service';
import { ResponseUtil } from '../../services/util/response/response-util.service';

export enum LeaveSetupSteps {
    RESOURCE_SELECTION,
    SET_LEAVE,
    CONFIRM
}

@Component({
    selector: 'tc-leave-setup-focus-view',
    templateUrl: './leave-setup-focus-view.component.html'
})
export class LeaveSetupFocusViewComponent implements OnInit {

    @Input() leaveSearchCriteria: LeaveSearchCriteria;
    @Input() isInsideForm: boolean;
    @Input() hasTaskForDates: boolean;
    @Input() selectedStartDate: string;
    @Input() selectedEndDate: string;
    @Input() leaveTypes: any[] = [];

    leaveSetupData: LeaveSetup;

    public isResourceSelectionStepComplete = false;
    public isSetLeaveStepComplete = false;
    public isConfirmStepComplete = false;

    selectedResourceIds = [];
    selectedResourceCards: Array<LeaveSetupResultItemView> = [];
    private createLeaveObsv: Subscription = new Subscription();
    @ViewChild('stepper') stepper: MatHorizontalStepper;

    constructor(
        private em: EventManager,
        private configLoader: ConfigLoader,
        private journeyManager: UserJourneyManager,
        private focusViewManager: FocusViewManager,
        private dataStore: DataStoreService,
        private focusViewService: FocusViewService,
        private resourceAvailabilityService: ResourceAvailabilityService,
        private commonHelper: CommonHelper,
        private spinnerService: SpinnerService,
        private common: DMCCommon
    ) { }

    ngOnInit() {
        // create leaveSetupData object to save leave
        this.leaveSetupData = new LeaveSetup();
        this.leaveSetupData.startDate = this.selectedStartDate;
        this.leaveSetupData.endDate = this.selectedEndDate;
        this.leaveSetupData.hasTask = this.hasTaskForDates;
        this.journeyManager.canProceed.next(false);
        this.focusViewManager.canProceed.next(false);
        this.focusViewManager.canProceedState.next(true);

        this.continueToSetLeave(this.selectedResourceIds);
        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'TRIGGER_CONTINUE').subscribe(e => {
            this.onContinueFabTrigger();
        });
        this.createLeaveObserver();
    }

    /**
     * enable the fab in stepper
     */
    private onContinueFabTrigger() {
        switch (this.stepper.selectedIndex) {
            case LeaveSetupSteps.RESOURCE_SELECTION:
                if (this.selectedResourceIds && this.selectedResourceIds.length > 0) {
                    this.leaveSetupData.resourceIdList = this.selectedResourceIds;
                    this.leaveSetupData.dmcLeave = true;
                    this.isResourceSelectionStepComplete = true;
                    this.stepper.selected.completed = true;
                    this.stepper.next();
                    this.journeyManager.canProceed.next(false);
                    this.focusViewManager.canProceed.next(false);
                }
                break;
            case LeaveSetupSteps.SET_LEAVE:
                // just navigate to next step. advisory-notes component handles fab enable, disable functionality
                this.isSetLeaveStepComplete = true;
                this.stepper.selected.completed = true;
                this.stepper.next();
                break;
            case LeaveSetupSteps.CONFIRM:
                this.leaveSetupData.resourceIdList = this.selectedResourceIds;
                if(this.isSetLeaveStepComplete){
                    this.saveLeaves();
                }
                break;
        }
    }

    /**
     * Check for the fist step complete in stepper - selected resources
     * @param selectedResourceIds
     */
    public continueToSetLeave(selectedResources: any) {
        this.selectedResourceIds = selectedResources.resourceIds;
        this.selectedResourceCards = selectedResources.contentCardData;
        // check enable to proceed to next step
        if (this.selectedResourceIds && this.selectedResourceIds.length > 0) {
            this.isResourceSelectionStepComplete = true;
            this.focusViewManager.canProceed.next(true);
            // this.continueToSave();
        } else {
            this.isResourceSelectionStepComplete = false;
            this.focusViewManager.canProceed.next(false);
        }
    }

    /**
     * check leave setup complete to go to the confirmation step
     * @param event
     */
    public continueToConfirm(event) {
        if (event) {
            this.isSetLeaveStepComplete = true;
            this.journeyManager.canProceed.next(true);
            this.focusViewManager.canProceed.next(true);
            this.continueToSave();
        } else {
            this.isSetLeaveStepComplete = false;
            this.journeyManager.canProceed.next(false);
            this.focusViewManager.canProceed.next(false);
        }
    }

    /**
     * Enable the save button
     */
    public continueToSave() {
        if (this.isResourceSelectionStepComplete && this.isSetLeaveStepComplete) {
            this.journeyManager.canProceed.next(true);
            this.focusViewManager.canProceed.next(true);
        } else {
            this.journeyManager.canProceed.next(false);
            this.focusViewManager.canProceed.next(false);
        }
    }

    /**
     * service call to save leaves
     */
    private saveLeaves() {
        this.resourceAvailabilityService.saveLeaves(this.leaveSetupData);
    }

    /**
     * subscribe for data save response
     */
    createLeaveObserver() {
        this.createLeaveObsv = this.dataStore.get(DataKey.saveLeaveData, true).subscribe(value => {
            if (this.commonHelper.dataValidity(value)) {
                this.createLeaveObsv.unsubscribe();
                this.dataStore.set(DataKey.saveLeaveData, null, true);
                this.spinnerService.hide();
                if (ResponseUtil.isSuccess(value)) {
                    this.common.showSnackBar('Successfully saved the resource leaves.', 3000, TcErrorType.TYPE.INFO);
                    this.dataStore.set(DataKey.leaveSetupFocusViewClose, null);
                    this.dataStore.set(DataKey.leaveSetupFocusViewClose, true);
                    this.focusViewService.close(true);
                } else if (ResponseUtil.isError(value)) {
                    this.common.showSnackBar('Failed to save the resource leaves', 3000, TcErrorType.TYPE.ERROR);
                }
            } else if (value instanceof TcApiError) {
                this.spinnerService.hide();
            } else if (value instanceof TcHttpError) {
                this.spinnerService.hide();
            }
        }, error => {
        });
    }
}
