import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { SetupGridDataSource } from '../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid-data-source';
import { IGetRowsParams } from 'ag-grid-community';
import { Observable } from 'rxjs';

@Component({
    selector: 'tc-ui-test',
    templateUrl: './ui-test.component.html'
})
export class UiTestComponent implements OnInit, SetupGridDataSource {

    pageSize = 20;
    searchPage = 0;
    sortBy = 'id';
    sortDirection = 'ASC';

    leftSideData = [];
    rightSideData = [];

    colDefConfigLeft = [];
    colDefConfigRight = [];

    identityFunction;

    url = 'assets/mockdata/city-data.json';


    constructor(
        private http: HttpClient
    ) {
    }

    getRows(params: IGetRowsParams): Observable<any> {
        return this.http.get(this.url);
    }
    saveRow(row: any, event: any): Observable<any> {
        throw new Error("Method not implemented.");
    }
    deleteRow(row: any): Observable<any> {
        throw new Error("Method not implemented.");
    }

    public ngOnInit(): void {
        this.identityFunction = (row) => {
            return row.id;
        }

        this.http.get(
            'assets/mockdata/city-group-col-def.config.json'
        ).subscribe((result: any[]) => {
            this.colDefConfigLeft = result;
            this.colDefConfigRight = result;
        });

        this.getRows(null);
    }

    onSearch() {
        console.log('asking for page=' + this.searchPage + ' size=' + this.pageSize + ' sortBy=' + this.sortBy + ' direction=' + this.sortDirection);
        let reqParams = '?sortBy=' + this.sortBy + '&direction=' + this.sortDirection;
        reqParams += '&pageSize=' + this.pageSize + '&pageNo=' + this.searchPage;
        return this.http.get(this.url + reqParams)
            .subscribe(
                data => {
                    this.leftSideData = this.getDataFromResponse(data)
                });
    }

    onSearchPrevious() {
        if (this.searchPage > 0) {
            this.searchPage--;
        }
        console.log('asking for page=' + this.searchPage + ' size=' + this.pageSize + ' sortBy=' + this.sortBy + ' direction=' + this.sortDirection);
        let reqParams = '?sortBy=' + this.sortBy + '&direction=' + this.sortDirection;
        reqParams += '&pageSize=' + this.pageSize + '&pageNo=' + this.searchPage;
        return this.http.get(this.url + reqParams).subscribe(
            data => {
                this.leftSideData = this.getDataFromResponse(data)
            });
    }

    onSearchNext() {
        this.searchPage++;
        console.log('asking for page=' + this.searchPage + ' size=' + this.pageSize + ' sortBy=' + this.sortBy + ' direction=' + this.sortDirection);
        let reqParams = '?sortBy=' + this.sortBy + '&direction=' + this.sortDirection;
        reqParams += '&pageSize=' + this.pageSize + '&pageNo=' + this.searchPage;
        return this.http.get(this.url + reqParams)
            .subscribe(
                data => {
                    this.leftSideData = this.getDataFromResponse(data)
                });
    }

    public getDataFromResponse<T>(response: any): T[] {
        let data = [];
        if (response) {
            data = response['content'];
        }
        return data;
    }

    onLogRightSideData() {
        if (this.rightSideData) {
            console.table(this.rightSideData)
        }
    }
}
