import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ModalService } from '@tc/modal/modal.service';
import { ModalData } from '@tc-core/model/it/codegen/ui/framework/modal-data';
import { LEVEL } from '@tc-core/model/it/codegen/ui/framework/dialog-model';
import { TimeOffsetSetup } from '../../../../models/master-data/time-offset-setup';
import { RoutesSetupService } from '../../../../services/backend-consumers/deprecated/routes-setup.service';
import { DataKey, DataStoreService } from '../../../../services/util/framework/data-store.service';
import { EditTimeOffsetRulesComponent } from './edit-time-offset-rules/edit-time-offset-rules.component';
import { Subscription } from 'rxjs';

@Component({
    selector: 'tc-time-offset-rules',
    templateUrl: './time-offset-rules.component.html'
})
export class TimeOffsetRulesComponent implements OnInit {
    timeOffset: TimeOffsetSetup[];
    private timeOffsetSubscription: Subscription = new Subscription();
    selectedRule: TimeOffsetSetup;

    constructor(
        private fb: FormBuilder,
        private routeService: RoutesSetupService,
        private modalService: ModalService,
        private dataStore: DataStoreService
    ) {
    }

    ngOnInit() {
        this.routeService.retrieveTimeOffsetData().subscribe(data => {
            this.timeOffset = data;
        });
        this.getAddedData();
    }

    getAddedData() {
        this.timeOffsetSubscription = this.dataStore.get(DataKey.locations).subscribe(data => {
            if (data != null) {
                this.selectedRule = data;
                console.log(data);
            }
        });
    }

    editTimeOffset(rule: TimeOffsetSetup) {
        this.selectedRule = rule;

        let dataObject = new ModalData(LEVEL.SUCCESS, 'Location / Location Group Time Offset Rules',
            {'label': 'OK'},
            {'label': 'Close'},
            '',
            EditTimeOffsetRulesComponent,
            {
                'timeOffsetRule': rule
            }
        );

        this.confirmModal(dataObject);

    }

    private confirmModal(data: any): void {
        this.modalService.confirm(data).subscribe((res) => {
            console.log(res);
        });
    }
}
