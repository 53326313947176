import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'tc-pickup-dropoff-details',
  templateUrl: './pickup-dropoff-details.component.html'
})
export class PickupDropoffDetailsComponent implements OnInit {

  @Input() dropOffs = null;
  @Input() pickUps = null;
  @Input() selectedIndex = 0;

  @Output() closePopUp: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  public onClosePopUp($event: MouseEvent) {
    this.closePopUp.emit($event);
  }
}
