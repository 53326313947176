import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
    selector: 'tc-check-box-selector',
    templateUrl: './check-box-selector.component.html'
})
export class CheckBoxSelectorComponent implements ICellRendererAngularComp {

    params: any;
    date: any;
    day: any;
    isChecked = false;
    click: any;
    isError: false;

    constructor() { }

    public afterGuiAttached(params?: any): void {
    }

    public agInit(params: any): void {
        this.params = params;
        this.date = params['date'];
        this.click = params['click'];
        this.day = params['day'];
        this.isError = this.params.data.isInvalid;
        this.params.value.forEach((dayObj) => {
            if (this.date == parseInt(dayObj.key)) {
                this.isChecked = true;
            }
        });
    }

    public refresh(params: any): boolean {
        return false;
    }

    public onChange($event) {
        let dateObj = {
            key: this.date.toString(),
            day: this.day
        };
        if (this.isChecked) {
            this.params.value.push(dateObj);
        } else {
            const index = this.params.value.findIndex(obj => obj.day == this.day);
            if (index !== -1) {
                this.params.value.splice(index, 1);
            }
        }
        this.click(this.params);

    }

}
