import { Component, Input, OnInit } from '@angular/core';
import { el } from '@angular/platform-browser/testing/src/browser_util';
import { TcApiError } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-api-error';
import { TcErrorType } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-error';
import { TcHttpError } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-http-error';
import { DialogModel, LEVEL } from '@tc-core/model/it/codegen/ui/framework/dialog-model';
import { Icon } from '@tc-core/model/it/codegen/ui/framework/icon';
import { ModalData } from '@tc-core/model/it/codegen/ui/framework/modal-data';
import { TC } from '@tc-core/util';
import { ConfigLoader } from '@tc-core/util/framework';
import { EventManager } from '@tc-core/util/framework/event-manager.service';
import { FocusViewManager } from '@tc-core/util/framework/focus-view-manager.service';
import { UserJourneyManager } from '@tc-core/util/framework/user-journey-manager.service';
import { CommonHelper } from '@tc-core/util/helpers';
import { DialogService } from '@tc/dialog/dialog.service';
import { FocusViewService } from '@tc/focus-view/focus-view.service';
import { ModalService } from '@tc/modal/modal.service';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { TCO } from '../../../../constants';
import { AssignStatus } from '../../../../models/reservation-v2/assignment';
import { BookingItem } from '../../../../models/reservation-v2/booking-item';
import { CostingType } from '../../../../models/reservation-v2/costing-type';
import { ResourceAllocationSearchCriteria } from '../../../../models/reservation-v2/criteria/resource-allocation-search-criteria';
import { SupplierAllocationSearchCriteria } from '../../../../models/reservation-v2/criteria/supplier-allocation-search-criteria';
import { ExistingAssignment } from '../../../../models/reservation-v2/existing-assignment';
import { PaxCount } from '../../../../models/reservation-v2/pax-count';
import { AllocationTimeRange, ResourceAllocationSummary } from '../../../../models/reservation-v2/resource-allocation-summary';
import { ServiceItem } from '../../../../models/reservation-v2/service-item';
import { SupplierAllocationSummary } from '../../../../models/reservation-v2/supplier-allocation-summary';
import { OPResponseWrapper } from '../../../../models/response/op-response-wrapper';
import { AddMoreLangDialogComponent } from '../../../profile-management/shared/add-more-lang-dialog/add-more-lang-dialog.component';
import { DMCCommon } from '../../../../services/util/common/dmc-common';
import { DataKey, DataStoreService } from '../../../../services/util/framework/data-store.service';
import { ResponseUtil } from '../../../../services/util/response/response-util.service';
import { ContentSummaryCardService } from '../../../../services/util/ui/content-summary-card.service';
import { ResAvailabilityCalendarFocusViewComponent } from '../../../../widgets/shared/res-availability-calendar-focus-view/res-availability-calendar-focus-view.component';
import { EditTimeRangeComponent } from '../resource-allocation-panel/edit-time-range/edit-time-range.component';

@Component({
    selector: 'tc-resource-allocation-focus-view',
    templateUrl: './allocation-focus-view.component.html'
})
export class AllocationFocusViewComponent implements OnInit {

    @Input() itemList: (ServiceItem | BookingItem)[] = [];
    @Input() isPrimary = true;
    @Input() isSecondary = false;
    @Input() allocationTimeRange = new AllocationTimeRange();
    @Input() startTime: any;
    @Input() endTime: any;
    @Input() bookingIdList: number[] = [];


    public selectedIndex: number;
    paxCount = new PaxCount();

    // supplier panel
    tAssignedSuppliers: any;
    supplierPanelHeading = '';
    selectedSupplier: SupplierAllocationSummary;
    contentCardDataListSuppliers: Array<any> = [];
    numberOfSuppliers = 0;
    supplierSearchResults: SupplierAllocationSummary[];
    suppliersShowing: SupplierAllocationSummary[] = [];
    supplierSearchCriteria = new SupplierAllocationSearchCriteria();

    // resource
    tAssignedResources: any;
    numberOfResources = 0;
    selectedResource: ResourceAllocationSummary;
    contentCardDataListResources: ResourceAllocationSummary[] = [];
    resourceSearchResults: ResourceAllocationSummary[];
    resourcesShowing: ResourceAllocationSummary[] = [];
    resourcePanelHeading = '';
    resourceSearchCriteria = new ResourceAllocationSearchCriteria();

    private supplierSearchResultsSubscription: Subscription = new Subscription();
    private resourceSearchResultsSubscription: Subscription = new Subscription();
    private focusViewCloseSubject: Subscription = new Subscription();

    private navConfig: any;

    constructor(
        private dataStore: DataStoreService,
        private configLoader: ConfigLoader,
        private summaryCardService: ContentSummaryCardService,
        private focusViewService: FocusViewService,
        private dialogService: DialogService,
        private journeyManager: UserJourneyManager,
        private focusViewManager: FocusViewManager,
        private em: EventManager,
        private commonHelper: CommonHelper,
        private common: DMCCommon,
        private modalService: ModalService
    ) { }

    ngOnInit() {
        this.journeyManager.canProceed.next(false);
        this.focusViewManager.canProceed.next(false);
        this.focusViewManager.canProceedState.next(true);

        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'CLOSE_FOCUS_VIEW').subscribe(e => {
            this.dataStore.set(DataKey.availabilityCalendarFocusViewClose, null);
            this.dataStore.set(DataKey.availabilityCalendarFocusViewClose, true);
            this.onSave();
            this.focusViewService.close(true);
        });

        // this.resourceCategoryIcons = this.configLoader.configurations.get(TCO.CONF.CONF_GENERIC_QUEUE_ICONS).RESOURCE_CATEGORY_ICONS;
        this.enableFocusViewProceed();

        this.getPaxCount();

        this.dataStore.set(DataKey.supplierAllocationSearchResults, null, true);
        this.dataStore.set(DataKey.resourceAllocationSearchResults, null, true);

        this.subscribeSupplierSearchResults();
        this.loadSupplierPanel();

        this.loadResourcePanel();
    }

    private enableFocusViewProceed() {
        if (this.selectedSupplier) {
            this.focusViewManager.canProceed.next(true);
        } else {
            this.focusViewManager.canProceed.next(false);
        }
    }

    private getPaxCount() {
        const adults: number = this.itemList.map(a => a.adultCount).reduce(function(a, b) {return a + b;});
        const children: number = this.itemList.map(a => a.childrenCount).reduce(function(a, b) {return a + b;});
        const infants: number = this.itemList.map(a => a.infantCount).reduce(function(a, b) {return a + b;});
        this.paxCount.adultCount = adults;
        this.paxCount.childCount = children;
        this.paxCount.infantCount = infants;
        this.paxCount.totalPax = adults + children + infants;
    }

    private onSave() {
        if (this.selectedResource) {
            this.selectedResource.isPrimaryAssignment = this.isPrimary;
            this.selectedResource.isSecondaryAssignment = this.isSecondary;
            this.dataStore.set(DataKey.selectedResourceForAllocation, this.selectedResource, true);
        } else if (this.selectedSupplier) {
            this.selectedSupplier.isPrimaryAssignment = this.isPrimary;
            this.selectedSupplier.isSecondaryAssignment = this.isSecondary;
            this.dataStore.set(DataKey.selectedSupplierForAllocation, this.selectedSupplier, true);
        }

    }

    // load supplier details
    private loadSupplierPanel() {
        // load supplier summary card configs
        this.dataStore.set(DataKey.assignedSupplier, null, true);
        this.tAssignedSuppliers = this.configLoader.configurations.get(TCO.CONF.CONF_ALLOCATIONV2_RESULT_CARD_CONFIG).supplier;
    }

    subscribeSupplierSearchResults() {
        this.supplierSearchResultsSubscription = this.dataStore.get(DataKey.supplierAllocationSearchResults)
                                                     .subscribe((data: OPResponseWrapper<SupplierAllocationSummary>) => {
                                                         if (data) {
                                                             this.numberOfSuppliers = ResponseUtil.getTotalCount(data);
                                                             this.supplierSearchResults = ResponseUtil.getDataArray<SupplierAllocationSummary>(data);
                                                             this.supplierSearchResults = this.supplierSearchResults.filter(value => value.calculatedCost != null);
                                                             // this.suppliersShowing.push(...this.supplierSearchResults);
                                                             this.contentCardDataListSuppliers = this.getSupplierSummaryCards(this.supplierSearchResults);

                                                             this.supplierPanelHeading = this.supplierSearchResults.length > 1 ?
                                                                 this.supplierSearchResults.length + ' Results Found' :
                                                                 this.supplierSearchResults.length + ' Result Found';
                                                         } else if (this.commonHelper.isEmptyData(data)) {
                                                             this.supplierSearchResults = [];
                                                         } else if (data instanceof TcApiError) {
                                                             this.supplierSearchResults = [];
                                                         } else if (data instanceof TcHttpError) {
                                                             this.supplierSearchResults = [];
                                                         }
                                                     });
    }

    private getSupplierSummaryCards(suppliers: SupplierAllocationSummary[]) {
        return this.summaryCardService.getContentCardsByConfig(
            this.tAssignedSuppliers,
            suppliers
        );
    }

    //load resource details

    private loadResourcePanel() {
        let isTrsAssignment: boolean;
        // load resource summary card configs
        if (this.itemList[0].trsBookingItem) {
            // this.allocationTimeRange.startTime = new Date(this.itemList[0].trsBookingItem.departureDateTime)//moment(this.itemList[0].trsBookingItem.departureDateTime).format('HH:mm');
            // this.allocationTimeRange.endTime = new Date(this.itemList[0].trsBookingItem.arrivalDateTime)//moment(this.itemList[0].trsBookingItem.arrivalDateTime).format('HH:mm');
            this.tAssignedResources = this.configLoader.configurations.get(TCO.CONF.CONF_RESERVATION_RESOURCE_ASSIGN_CONFIG).vehicle;
            isTrsAssignment = true;
        } else {
            // this.allocationTimeRange.startTime = new Date(this.itemList[0].serviceDate);//moment(this.itemList[0].serviceDate).format('HH:mm');
            this.tAssignedResources = this.configLoader.configurations.get(TCO.CONF.CONF_RESERVATION_RESOURCE_ASSIGN_CONFIG).resource;
            isTrsAssignment = false;
        }
        this.subscribeResourceSearchResults(isTrsAssignment);
    }

    subscribeResourceSearchResults(isTrsAssignment: boolean) {
        this.resourceSearchResultsSubscription = this.dataStore.get(DataKey.resourceAllocationSearchResults, true)
                                                     .subscribe((data: OPResponseWrapper<ResourceAllocationSummary>) => {
                                                         if (data) {

                                                             this.numberOfResources = ResponseUtil.getTotalCount(data);
                                                             this.resourceSearchResults = ResponseUtil.getDataArray<ResourceAllocationSummary>(
                                                                 data);
                                                             this.processResourceData(isTrsAssignment);
                                                             // this.resourcesShowing.push(...this.resourceSearchResults);
                                                             this.contentCardDataListResources = this.resourceSearchResults;
                                                             this.resourcePanelHeading = this.resourceSearchResults.length > 1 ?
                                                                 this.resourceSearchResults.length + ' Results Found' :
                                                                 this.resourceSearchResults.length + ' Result Found';
                                                         }
                                                         // else {
                                                         //     this.resourcesShowing = [];
                                                         //     this.contentCardDataListResources = this.resourcesShowing;
                                                         // }

                                                     });
    }

    private processResourceData(isTrsAssignment: boolean) {
        this.resourceSearchResults.forEach(value => {
            this.processIcon(value, this.tAssignedResources.config.icon, isTrsAssignment);
        });
    }

    processIcon(resource: ResourceAllocationSummary, arr: any[], isTrsAssignment: boolean): ResourceAllocationSummary {
        let iconView = new Icon();
        let summaryIcon: any;
        let iconValue: any;

        if (isTrsAssignment) {
            summaryIcon = arr.find(
                item => item['iconName'] === 'TRANSFER_MODE_ICN');
            let valueField = summaryIcon['key'];
            iconValue = resource[valueField];
            if (summaryIcon) {
                iconView.key = summaryIcon['iconName'];
                iconView.iconType = iconValue;
                iconView.value = iconValue;
                iconView.value = iconView.value ? iconView.value : '';
                iconView.style = summaryIcon['style'] + ' ' +
                    summaryIcon['neutralClass'];
            }
        } else {
            summaryIcon = arr.find(
                item => item['iconName'] === 'GENERIC_RESOURCE_ICN');
            iconValue = summaryIcon['key'];
            if (iconValue === 'resource') {
                iconValue = resource.serviceType;
            }
            if (summaryIcon) {
                if (summaryIcon.iconKeys[iconValue]) {
                    iconView.key = summaryIcon.iconKeys[iconValue];
                }
                iconView.iconType = summaryIcon['iconType'];
                iconView.value = summaryIcon.values[iconValue];
                iconView.style = summaryIcon['style'] + ' ' +
                    summaryIcon['activeClass'];
                iconView.value = iconView.value ? iconView.value : '';
            }
        }

        if (iconView.value.length > 3) {
            iconView.style = iconView.style + ' ' + summaryIcon['smallTextClass'];
        }
        resource.icon = iconView;
        return resource;
    }

    onResourceCalendarClick(resource: ResourceAllocationSummary) {
        this.navConfig = this.configLoader.configurations.get(TCO.CONF.CONF_PAGE_SECTION_CONFIG)['availability_calendar_side_nav']['resource_level'];
        setTimeout(() => {
            let fabActions = [];

            let dataObject = new ModalData(
                LEVEL.SUCCESS,
                'Availability Calendar',
                null,
                null,
                'season-setup-focus-view',
                ResAvailabilityCalendarFocusViewComponent,
                {
                    'resourceSummary': resource,
                    'navConfig': this.navConfig
                },
                {'label': 'close'},
                '',
                '',
                fabActions,
                '',
                (resource.resourceName) + ' | Supplier: ' + (resource.supplierName)
            );

            this.focusViewCloseSubject = this.dataStore.get(DataKey.availabilityCalendarFocusViewClose).subscribe(c => {
                if (c) {
                }
            });
            this.focusViewService.confirm(dataObject).subscribe(res => {
                if (res) {
                    console.log(res);
                }
            });
        }, 0);

    }

    onEventClick(event: ResourceAllocationSummary) {
        if (event.selected) {
            this.deleteResourceAssignment(event);
        } else {
            this.updateResourceAssignment(event, false);
        }
    }

    private deleteResourceAssignment(event: ResourceAllocationSummary) {
        let confirmDataObject = new DialogModel(
            true,
            LEVEL.ERROR,
            'Delete Job',
            'Do you want to remove job?',
            true,
            3000
        );
        this.dialogService.confirm(confirmDataObject).subscribe(
            (res) => {
                if (res === true) {
                    this.removeAssignment(event);
                    // this.dataStore.set(DataKey.assignedSupplierFromDriver, null, true);
                    this.selectedSupplier = null;
                    this.selectedResource = null;
                    this.enableFocusViewProceed();
                }
            });
    }

    private removeAssignment(event: ResourceAllocationSummary) {
        if (event.existingAssignments) {
            let selectedAssignment = event.existingAssignments.find(value => value.selected);

            selectedAssignment.allocatedCapacity -= this.paxCount.totalPax;
            // selectedAssignment.allocatedCount -= this.paxCount.totalPax;
            // selectedAssignment.remainingCount += this.paxCount.totalPax;

            if (selectedAssignment.allocatedCapacity > 0) {
                selectedAssignment.updated = false;
                selectedAssignment.selected = false;
                event.selected = false;
                event.existingAssignments.forEach(value => {
                    if (value.assignmentId === selectedAssignment.assignmentId ||
                        value.temporaryAssignmentId === selectedAssignment.temporaryAssignmentId) {
                        value = selectedAssignment;
                    }
                });

            } else {
                event.selected = false;
                event.existingAssignments = event.existingAssignments.filter(value => !value.selected);
                selectedAssignment = null;
            }

        }
    }

    private updateResourceAssignment(event: ResourceAllocationSummary, isAfterVehicle: boolean) {
        let confirmDataObject = new DialogModel(
            true,
            LEVEL.INFO,
            'Add to Existing Job',
            'Do you want to assign resource with an existing job?',
            true,
            3000
        );
        this.dialogService.confirm(confirmDataObject).subscribe(
            (res) => {
                if (res === true) {
                    let assignment = event.existingAssignments.find(value => value.tobeSelected);

                    assignment.selected = true;
                    assignment.recommended = true;
                    assignment.allocatedCapacity += this.paxCount.totalPax;

                    assignment.supplierId = event.supplierId;
                    assignment.supplierCode = event.supplierCode;
                    assignment.supplierName = event.supplierName;

                    assignment.resourceId = event.resourceId;
                    assignment.resourceName = event.resourceName;

                    assignment.genResourceCost = event.genResourceCostSummaries.find(value => value.selected);
                    assignment.trsResourceCost = event.trsResourceCostSummaries.find(value => value.selected);

                    assignment.startTime = this.startTime ?
                        this.startTime :
                        assignment.startTime;
                    assignment.endTime = this.endTime ?
                        this.endTime :
                        assignment.endTime;

                    event.selected = true;

                    if(!assignment.costingType)
                    {
                        if(assignment.genResourceCost)
                        {
                            assignment.costingType = assignment.genResourceCost.costingType;
                        } else if(assignment.trsResourceCost) {
                            assignment.costingType = assignment.trsResourceCost.costingType;
                        }
                    }

                    this.selectedResource = event;
                    // this.dataStore.set(DataKey.assignedSupplierFromDriver, this.selectedDriver.supplier, true);
                    this.selectedSupplier = event;
                    this.enableFocusViewProceed();
                }
            });
    }

    onTargetClick(target: ResourceAllocationSummary) {
        let confirmDataObject = new DialogModel(
            true,
            LEVEL.INFO,
            'Create New Job',
            'Do you want to create new job for this resource?',
            true,
            3000
        );
        this.dialogService.confirm(confirmDataObject).subscribe(
            (res) => {
                if (res === true) {
                    if (target && target.capacityExceeded) {
                        this.common.showSnackBar('Exceeding the capacity', 3000, TcErrorType.TYPE.WARN);
                    }
                    let assignment = this.createResourceAssignment(target);
                    console.log(target);

                    target.existingAssignments = target.existingAssignments ? target.existingAssignments : [];
                    target.existingAssignments.push(assignment);
                    target.selected = true;
                    this.selectedResource = target;
                    this.selectedSupplier = target;

                    this.enableFocusViewProceed();
                }
            });
    }

    private createResourceAssignment(target: ResourceAllocationSummary): ExistingAssignment {
        let assignment = new ExistingAssignment();
        // assignment.temporaryAssignmentId =
        assignment.assignStatus = AssignStatus.PENDING;
        assignment.selected = true;
        assignment.recommended = true;
        assignment.allocatedCapacity = this.paxCount.totalPax;
        let startDateTime = new Date();
        let endDateTime = new Date();
        if (this.itemList[0].trsBookingItem) {
            startDateTime = this.startTime ?
                this.startTime :
                this.itemList[0].trsBookingItem.departureDateTime;
            endDateTime = this.endTime ?
                this.endTime :
                this.itemList[0].trsBookingItem.arrivalDateTime;
        } else if (this.itemList[0].genBookingItem) {
            if (this.startTime) {
                startDateTime = this.startTime;
            } else {
                startDateTime.setHours(8, 0);
                startDateTime.setFullYear(
                    moment(this.itemList[0].serviceDate).year(),
                    moment(this.itemList[0].serviceDate).month(),
                    moment(this.itemList[0].serviceDate).date()
                );
            }
            if (this.endTime) {
                endDateTime = this.endTime;
            } else {
                endDateTime.setHours(5, 0);
                endDateTime.setFullYear(
                    moment(this.itemList[0].serviceDate).year(),
                    moment(this.itemList[0].serviceDate).month(),
                    moment(this.itemList[0].serviceDate).date()
                );
            }
        }

        assignment.startTimeStr = moment(startDateTime).format('HH:mm');
        assignment.endTimeStr = moment(endDateTime).format('HH:mm');
        assignment.startTimeObj = new Date(startDateTime);
        assignment.startTime = new Date(startDateTime);
        assignment.endTimeObj = new Date(endDateTime);
        assignment.endTime = new Date(endDateTime);
        assignment.waitingEndTime = new Date(startDateTime);
        assignment.jobDate = this.itemList[0].serviceDate;
        assignment.currency = target.calculatedCost ? target.calculatedCost.currency : 'AED';
        assignment.remainingCapacity = target.totalCapacity > 0 ? (target.totalCapacity - assignment.allocatedCapacity) : 0;
        assignment.estimatedCost = target.calculatedCost.totalCost;
        assignment.jobCost = target.calculatedCost.totalCost;
        assignment.selected = true;

        assignment.supplierId = target.supplierId;
        assignment.supplierCode = target.supplierCode;
        assignment.supplierName = target.supplierName;

        assignment.resourceId = target.resourceId;
        assignment.resourceName = target.resourceName;

        assignment.genResourceCost = target.genResourceCostSummaries.find(value => value.selected);
        assignment.trsResourceCost = target.trsResourceCostSummaries.find(value => value.selected);
        assignment.costingType = target.costingType;
        if(!assignment.costingType)
        {
            if(assignment.genResourceCost)
            {
                assignment.costingType = assignment.genResourceCost.costingType;
            } else if(assignment.trsResourceCost) {
                assignment.costingType = assignment.trsResourceCost.costingType;
            }
        }
        return assignment;
    }

    public onResourceSelect($event: any) {
        this.selectedResource = $event;
        if(this.selectedResource.capacityExceeded) {
            this.common.showSnackBar('Exceeding the capacity', 3000, TcErrorType.TYPE.WARN);
        }
        if(!this.selectedSupplier)
        {
            this.mapSupplierDataFromResource(this.selectedResource);
        }
    }

    public onSupplierSelect($event: any) {
        this.selectedSupplier = $event;
        this.dataStore.set(DataKey.assignedSupplier, this.selectedSupplier, true);
        this.enableFocusViewProceed();
    }

    public onTimeRangeChangeClick($event: any) {
        this.dataStore.set(DataKey.popupClose, null, true);
        let dataObject = new ModalData(LEVEL.SUCCESS, 'Edit Time Range',

            null,
            null,
            'modal-basic--medium modal-basic--no-footer',
            EditTimeRangeComponent,
            {
                'selectedResource': this.selectedResource,
                'jobStartTime': this.allocationTimeRange.startTime,
                'jobEndTime': this.allocationTimeRange.endTime
            }
        );

        this.subscribeForDateRange();
        this.confirmModal(dataObject);
        this.dataStore.get(DataKey.popupClose).subscribe((data) => {
            if (data != null) {
                this.closeModal();
            }
        });
    }

    private confirmModal(data: any): void {
        this.modalService.confirm(data).subscribe((res) => {
            console.log(res);
        });
    }

    closeModal() {
        if (this.modalService.close()) {
            this.modalService.close().subscribe(
                (res) => {
                }
            );
        }
    }

    private subscribeForDateRange() {
        this.dataStore.get(DataKey.ResourceAllocationTimeRange).subscribe((data) => {
            if (data != null) {
                this.allocationTimeRange = data;
            }
        });
    }

    public resourceAllocationEnable() {
        return !(this.itemList[0] && this.itemList[0].noOfUnits > 1 && this.itemList[0].costingType &&
            (this.itemList[0].costingType.code == CostingType.UNIT || this.itemList[0].costingType.code == CostingType.PERSON));

    }

    //when allocation popup select resource only, map supplier data and set selectedSupplier
    private mapSupplierDataFromResource(selectedResource: ResourceAllocationSummary) {
       if(selectedResource){
           let supplier = new SupplierAllocationSummary();
           supplier.supplierId = selectedResource.supplierId;
           supplier.supplierCode = selectedResource.supplierCode;
           supplier.supplierName = selectedResource.supplierName;
           supplier.serviceType = selectedResource.serviceType;
           supplier.profileType = selectedResource.profileType;
           supplier.vehicleModel = selectedResource.vehicleModel;
           supplier.route = selectedResource.route;
           supplier.transferMode = selectedResource.transferMode;
           supplier.costingType = selectedResource.costingType;
           supplier.calculatedCost = selectedResource.calculatedCost;
           supplier.genResourceCostSummaries = selectedResource.genResourceCostSummaries;
           supplier.trsResourceCostSummaries = selectedResource.trsResourceCostSummaries;
           supplier.nullItemForClearing = selectedResource.nullItemForClearing;
           supplier.selected = selectedResource.selected;
           supplier.isPrimaryAssignment = selectedResource.isPrimaryAssignment;
           supplier.isSecondaryAssignment = selectedResource.isSecondaryAssignment;
           this.onSupplierSelect(supplier);
       }

    }
}
