import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatPaginatorModule, MatSortModule, MatTableModule } from '@angular/material';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from '@tc/pipes';
import { GenCostSetupDataCardComponent } from './gen-cost-setup-data-card.component';

@NgModule({
    declarations: [GenCostSetupDataCardComponent],
    imports: [
        TranslateModule.forChild(),
        CommonModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        PipesModule
    ],
    exports: [
        GenCostSetupDataCardComponent
    ]
})
export class GenCostSetupDataCardModule {}
