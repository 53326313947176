import {
    Component,
    DoCheck,
    EventEmitter,
    Input,
    KeyValueDiffer,
    KeyValueDiffers,
    OnInit,
    Output
} from '@angular/core';
import { CurrencyFormat } from '@tc-core/model/it/codegen/ui/framework/currency-format';
import { Icon } from '@tc-core/model/it/codegen/ui/framework/icon';
import { TC } from '@tc-core/util';
import { ConfigLoader } from '@tc-core/util/framework';
import { TCO } from '../../constants';
import { TimelineEvent } from '../../widgets/framework/cg-timeline/timeline-event';
import { AssignmentSummary } from '../../models/assignment-summary';
import { ServiceItemChanges } from '../../models/helper/service-item-changes';
import { SearchResultSummary } from '../../models/reservation/search-result-summary';
import { ResourceAvailability } from '../../models/resource/resource-availability';
import {DriverSupplierCostSummary} from "../../models/summary/driver-supplier-cost-summary";
import {NgxMaterialTimepickerTheme} from "ngx-material-timepicker/src/app/material-timepicker/models/ngx-material-timepicker-theme.interface";
import {$e} from "codelyzer/angular/styles/chars";
import {ServiceItem} from "../../models/reservation/service-item";

@Component({
    selector: 'tc-resource-allocation-card-gen',
    templateUrl: './resource-allocation-card-gen.component.html'
})
export class ResourceAllocationCardGenComponent implements OnInit, DoCheck {

    @Input() summaryContent: ResourceAvailability;
    @Input() iconConfig: Icon;
    @Input() isRow1col4String = false;
    @Input() itemGroup: SearchResultSummary;
    @Input() bookingData: any;
    @Input() bookingDataArray: any[];
    @Input() assignedVehicle: ServiceItemChanges;
    @Input() isBulkAssignment = false;

    @Output() resourceCalendarClick: EventEmitter<any> = new EventEmitter();
    @Output() eventClick: EventEmitter<AssignmentSummary> = new EventEmitter();
    @Output() targetClick: EventEmitter<any> = new EventEmitter();

    currencyFormat: CurrencyFormat;
    isUpdated = false;
    isCancelled = false;
    assignments: AssignmentSummary[] = [];

    timelineEvents: TimelineEvent[] = [];
    leftBufferHours = -1;
    rightBufferHours = -1;

    isTargetBlock = false;
    targetPointTime: Date = new Date();
    targetBlockTimeStart: Date = null;
    targetBlockTimeEnd: Date = null;

    row1col1_customsStyles = [];
    row1col2_customsStyles = [];
    row1col3_customsStyles = [];
    row1col4_customsStyles = [];

    row2col1_customsStyles = [];
    row2col2_customsStyles = [];
    row2col3_customsStyles = [];
    row2col4_customsStyles = [];

    row3col1_customsStyles = [];

    private summaryCardDiffer: KeyValueDiffer<string, any>;


    //timepickers
    public theme: NgxMaterialTimepickerTheme = {
        dial: {
            dialBackgroundColor: '#3f51b5'
        },
        container: {
            buttonColor: '#3f51b5'
        },
        clockFace: {
            clockHandColor: '#3f51b5'
        }
    };
    jobStartTime:any;
    jobEndTime:any;
    defaultJobStartTime:any;
    defaultJobEndTime:any;


    constructor(private configLoader: ConfigLoader, private differs: KeyValueDiffers) {
    }

    ngOnInit() {
        this.mapToObject()
        this.createTimelineEvents();
        this.initTimeline();
        this.createTargetPointOrBlock();
        this.overrideStyles();
        this.currencyFormat = this.getCurrencyFormat();
        this.summaryCardDiffer = this.differs.find(this.summaryContent).create();
    }

    mapToObject(){
        let resourceSummaryContent:any = this.summaryContent;
        let supplierData:DriverSupplierCostSummary = new DriverSupplierCostSummary();
        // this.summaryContent.resourceName = resourceSummaryContent.resourceName;
        supplierData.adultFare = resourceSummaryContent.resourceSupplier.resourceCostSummaries[0].adultFare;
        supplierData.childFare = resourceSummaryContent.resourceSupplier.resourceCostSummaries[0].childFare;
        supplierData.infantFare = resourceSummaryContent.resourceSupplier.resourceCostSummaries[0].infantFare;
        supplierData.unitFare = resourceSummaryContent.resourceSupplier.resourceCostSummaries[0].unitFare;
        supplierData.resourceType = resourceSummaryContent.resourceSupplier.resourceCostSummaries[0].resourceType;
        supplierData.city = resourceSummaryContent.resourceSupplier.city;
        supplierData.code = resourceSummaryContent.resourceSupplier.code;
        supplierData.country = resourceSummaryContent.resourceSupplier.country;
        supplierData.name = resourceSummaryContent.resourceSupplier.name;
        if (supplierData.unitFare) {
            supplierData.isUnitFare = true;
            supplierData.totalCost = supplierData.unitFare;
        }else{
            supplierData.isUnitFare = false;
            supplierData.isUnitFare = true;
            let costs:any = {};
            let serviceItems:Array<ServiceItem> = this.bookingData.serviceItems;
            let adult = 0;
            let child = 0;
            let infant = 0;
            serviceItems.forEach(item=>{
                // if(item.checked){
                    adult = adult+item.adult;
                    child = child+item.child;
                    infant = infant+item.infant;
                // }

            })
            costs.adultFare = (supplierData.adultFare * adult);
            costs.childFare = (supplierData.childFare * child);
            costs.infantFare = (supplierData.infantFare * infant);
            costs.totalCost = costs.adultFare + costs.childFare +
                costs.infantFare;
            supplierData.totalCost = costs.totalCost;
        }
        this.summaryContent.driverSupplier = supplierData;
        this.summaryContent.capacity = 0;
        this.summaryContent.resourceCost = supplierData.totalCost;

    }

    ngDoCheck(): void {
        this.mapToObject()
        const changes = this.summaryCardDiffer.diff(this.summaryContent);
        if (changes) {
            this.createTimelineEvents();
        }
    }

    initTimeline() {
        const timelineConfig = this.configLoader.configurations.get(TCO.CONF.CONF_TIMELINE_UNIT_CONFIG)['resource-allocation-timeline'];
        this.leftBufferHours = timelineConfig.leftBufferHours;
        this.rightBufferHours = timelineConfig.rightBufferHours;
    }

    private overrideStyles() {
        this.row1col1_customsStyles = ['tc-strip-text--primary'];
        this.row1col2_customsStyles = ['tc-strip-text--primary'];
        this.row1col3_customsStyles = [];
        this.row1col4_customsStyles = [
            'tc-strip-text--right',
            'tc-strip-text--primary',
            'tc-strip__price',
            'tc-strip__price--x-large'
        ];

        this.row2col1_customsStyles = [];
        this.row2col2_customsStyles = ['tc-strip-text--primary'];
        this.row2col3_customsStyles = [];
        this.row2col4_customsStyles = ['tc-strip-text__label', 'tc-strip-text--right'];
    }

    private getCurrencyFormat(): CurrencyFormat {
        let currencyFormat;
        const currencySymbolPattern = this.configLoader.configurations.get(TC.CONF.CONF_SYSTEM).defaults.default_currency_symbol_pattern;
        currencyFormat = new CurrencyFormat(TCO.AppData.DEF_CURRENCY_TYPE, currencySymbolPattern);
        return currencyFormat;
    }

    public createTimelineEvents() {
        this.assignments = this.summaryContent.assignments ? this.summaryContent.assignments : [];
        this.timelineEvents = [];
        for (const assignment of this.assignments) {
            this.defaultJobStartTime = assignment.startTime;
            this.defaultJobEndTime = assignment.endTime;
            const timelineEvent = new TimelineEvent();
            timelineEvent.startTime = assignment.startTimeObj;
            timelineEvent.endTime = assignment.endTimeObj;
            timelineEvent.waitingEndTime = assignment.waitingEndTimeObj;
            timelineEvent.assignment = assignment;
            this.timelineEvents.push(timelineEvent);
        }
    }

    createTargetPointOrBlock() {
        if (this.isBulkAssignment) {
            this.targetBlockTimeStart = this.itemGroup.startTimeObj;
            this.targetBlockTimeEnd = this.itemGroup.endTimeObj;
        } else {
            this.targetPointTime = new Date(this.bookingData.serviceItems[0].serviceDate);
        }
    }

    onResourceCalendarClick(resource: any) {
        this.resourceCalendarClick.emit(resource);
    }

    onTargetClick(target: any) {
        this.targetClick.emit(this.summaryContent);
    }

    onEventClick(event: TimelineEvent) {
        this.eventClick.emit(event.assignment);
    }

    setTimes($event,isStartTime:boolean){
        let time = $event;
        this.jobStartTime = this.defaultJobStartTime;
        this.jobEndTime = this.defaultJobEndTime;
        let timeArr:Array<string> = [];
        if(time != null && time !=""){
            if(isStartTime){
                timeArr  = time.split(':');
                this.jobStartTime = time;
            }else{
                timeArr  = time.split(':');
                this.jobEndTime = time;
            }
            if(timeArr.length>1 && this.timelineEvents.length>0){
                this.timelineEvents.forEach(
                    (timeline,index)=>{
                        if(isStartTime){
                            timeline.assignment.startTime = this.jobStartTime;
                            timeline.assignment.startTimeObj.setHours(+timeArr[0]);
                            timeline.assignment.startTimeObj.setMinutes(+timeArr[1]);
                            timeline.startTime.setHours(+timeArr[0]);
                            timeline.startTime.setMinutes(+timeArr[1]);
                            this.summaryContent.assignments[index].startTime = this.jobStartTime;
                            this.summaryContent.assignments[index].startTimeObj.setHours(+timeArr[0]);
                            this.summaryContent.assignments[index].startTimeObj.setMinutes(+timeArr[1]);

                        }else{
                            timeline.assignment.endTime = this.jobEndTime;
                            timeline.assignment.endTimeObj.setHours(+timeArr[0]);
                            timeline.assignment.endTimeObj.setMinutes(+timeArr[1]);
                            timeline.endTime.setHours(+timeArr[0]);
                            timeline.endTime.setMinutes(+timeArr[1]);
                            this.summaryContent.assignments[index].endTime = this.jobEndTime;
                            this.summaryContent.assignments[index].endTimeObj.setHours(+timeArr[0]);
                            this.summaryContent.assignments[index].endTimeObj.setMinutes(+timeArr[1]);

                        }
                    }
                )
            }
        }
    }

    onInputChange($event,isStartTime:boolean){
        let time = $event.target.value;
        this.jobStartTime = this.defaultJobStartTime;
        this.jobEndTime = this.defaultJobEndTime;
        let timeArr:Array<string> = [];
        if(time!= null && time!=""){
            if(/^([2][0-3]|[01]?[0-9])([.:][0-5][0-9])?$/.test(time)){
                if(isStartTime){
                    timeArr  = time.split(':');
                    this.jobStartTime = time;
                }else{
                    timeArr  = time.split(':');
                    this.jobEndTime = time;
                }
                if(timeArr.length>1 && this.timelineEvents.length>0){
                    this.timelineEvents.forEach(
                        (timeline,index)=>{
                            if(isStartTime){
                                timeline.assignment.startTime = this.jobStartTime;
                                timeline.assignment.startTimeObj.setHours(+timeArr[0]);
                                timeline.assignment.startTimeObj.setMinutes(+timeArr[1]);
                                timeline.startTime.setHours(+timeArr[0]);
                                timeline.startTime.setMinutes(+timeArr[1]);
                                this.summaryContent.assignments[index].startTime = this.jobStartTime;
                                this.summaryContent.assignments[index].startTimeObj.setHours(+timeArr[0]);
                                this.summaryContent.assignments[index].startTimeObj.setMinutes(+timeArr[1]);

                            }else{
                                timeline.assignment.endTime = this.jobEndTime;
                                timeline.assignment.endTimeObj.setHours(+timeArr[0]);
                                timeline.assignment.endTimeObj.setMinutes(+timeArr[1]);
                                timeline.endTime.setHours(+timeArr[0]);
                                timeline.endTime.setMinutes(+timeArr[1]);
                                this.summaryContent.assignments[index].endTime = this.jobStartTime;
                                this.summaryContent.assignments[index].endTimeObj.setHours(+timeArr[0]);
                                this.summaryContent.assignments[index].endTimeObj.setMinutes(+timeArr[1]);

                            }
                        }
                    )
                }
            }else if(isStartTime){
                this.jobStartTime = this.defaultJobStartTime;
                $event.target.value = this.jobStartTime
            }else{
                this.jobEndTime = this.defaultJobEndTime;
                $event.target.value = this.jobEndTime;
            }
        }
    }
}
