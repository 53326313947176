import { Component, OnInit } from '@angular/core';
import { LocationDepricated } from '../../../models/master-data/locationDepricated';
import { GridDataMapperService } from '../../../services/util/mapper/grid-data-mapper.service';
import { TableDataSource, TableService } from '../../../services/util/ui/table.service';
import { GridData } from '../../../models/ui/grid-data';
import { DataGridColumn, InputType } from '../../../models/ui/data-grid-column';
import { TCO } from '../../../constants';
import { EventManager } from '@tc-core/util/framework/event-manager.service';

@Component({
    selector: 'tc-locations-setup',
    templateUrl: './locations-setup.component.html'
})
export class LocationsSetupComponent implements OnInit {

    displayedColumns: any[];
    columnNamesArray: any[];
    locations: LocationDepricated[];
    columns: DataGridColumn[];
    dataSource: TableDataSource<any>;
    savedDataSource: TableDataSource<any>;

    deleteRowID: number;
    clickedRow: number;
    unsavedRows = 0;

    constructor(private gridDataMapperService: GridDataMapperService, private tableService: TableService, public em: EventManager ) { }

    ngOnInit() {
        this.locations = [
            {locationCode: 'LOC001', country: 'UK', region: null, type: 'Hotel', locationName: 'Grand Plaza', city: 'London'},
            {locationCode: 'LOC002', country: 'UK', region: null, type: 'Airport', locationName: 'Heathrow Airport', city: 'London'},
            {locationCode: 'LOC003', country: 'UK', region: null, type: 'Hotel', locationName: 'The Rembrandt', city: 'London'},
            {locationCode: 'LOC004', country: 'UK', region: null, type: 'Cruise Port', locationName: 'Norwegian Fjords', city: 'Southampton'}
        ];
        let gridDataArray: GridData[] = [];
        this.locations.forEach(data => {
            gridDataArray.push(this.gridDataMapperService
                                   .mapEachToGridData(data.locationCode, data.locationName, data.type, data.city, data.region, data.country));
        });
        this.dataSource = this.tableService.getTableDataSource(gridDataArray);
        this.savedDataSource = JSON.parse(JSON.stringify(this.dataSource));
        this.columnNamesArray = ['Location Code', 'Location Name',  'Type',  'City', 'Region', 'Country', 'save'];
        this.displayedColumns = ['col1', 'col2', 'col3', 'col4', 'col5', 'col6', 'save'];
        // this.displayedColumns = ['col1', 'col2', 'save'];
        this.columns = [
            { name: 'Location Code', type: InputType.INPUT, options: null },
            { name: 'Location Name', type: InputType.INPUT, options: null},
            { name: 'Type', type: InputType.SELECT, options: ['AirPort', 'Hotel', 'Cruise Port'] },
            { name: 'City', type: InputType.SELECT, options: ['New York', 'London', 'Southampton'] },
            { name: 'Region', type: InputType.INPUT, options: null},
            { name: 'Country', type: InputType.SELECT, options: ['USA', 'UK']}
        ];

        this.em.addEvent(TCO.CONF.CONF_EVENT_MANAGER, 'ADD_LOCATION').subscribe(e => {
            this.addRow();
        });

        this.em.addEvent(TCO.CONF.CONF_EVENT_MANAGER, 'SAVE_LOCATION').subscribe(e => {
            this.saveLocations();
        });

        this.em.addEvent(TCO.CONF.CONF_EVENT_MANAGER, 'DELETE_LOCATION').subscribe(e => {
            this.deleteRow();
        });
    }

    onAddRow(event) {
        let location = new LocationDepricated();
        let gridDataArray = JSON.parse(JSON.stringify(this.dataSource.totalData));
        gridDataArray.unshift(
            this.gridDataMapperService
                .mapEachToGridData(
                    location.locationCode,
                    location.locationName,
                    location.type,
                    location.city,
                    location.region,
                    location.country));
        this.dataSource = this.tableService.getTableDataSource(gridDataArray);
    }

    addRow() {
        let location = new LocationDepricated();
        let gridDataArray = JSON.parse(JSON.stringify(this.dataSource.totalData));
        gridDataArray.push(
            this.gridDataMapperService
                .mapEachToGridData(
                    location.locationCode,
                    location.locationName,
                    location.type,
                    location.city,
                    location.region,
                    location.country));
        this.dataSource = this.tableService.getTableDataSource(gridDataArray);
        this.clickedRow = this.dataSource.totalData.length  - 1;
        this.generateUnsavedRows(this.clickedRow, true);
    }

    onDeleteRow(event) {
        this.dataSource = this.tableService.getTableDataSource(event);
    }

    saveLocations() {
        console.log('save');
        console.log(this.dataSource.totalData);
        this.savedDataSource = JSON.parse(JSON.stringify(this.dataSource));
    }

    onDeleteRowClick(event) {
        this.deleteRowID = event;
    }

    deleteRow() {
        // if ( this.deleteRowID ) {
        //     this.dataSource.totalData.splice(this.deleteRowID, 1);
        //     // console.log(this.dataSource.totalData);
        //     // this.deleteRow.emit(this.dataSource.totalData);
        //     this.dataSource = this.tableService.getTableDataSource(this.dataSource.totalData);
        //     this.deleteRowID = null;
        // }
    }

    onEditClick(event) {
        this.generateUnsavedRows(event, true);
    }

    onRevertClick(event) {
        // this.dataSource.totalData.splice(event, 1, this.savedDataSource.totalData[event] );
        // this.dataSource = this.tableService.getTableDataSource(this.dataSource.totalData);
        this.generateUnsavedRows(event, false);
    }
    onSaveClick(event) {
        // this.savedDataSource.totalData.splice(event, 1, this.dataSource.totalData[event] );
        this.generateUnsavedRows(event, false);
    }

    generateUnsavedRows(id: number, isAdd: boolean) {

        this.unsavedRows = isAdd ? this.unsavedRows + Math.pow(2, id) : this.unsavedRows - Math.pow(2, id);
        // console.log(2..toExponential(this.clickedRow));
        console.log(this.unsavedRows);
        console.log(id);
        // console.log(Math.pow(2, id));
        // console.log(Math.pow(2, id) & 32);
        // console.log(Math.pow(2, id) & 8);
        // console.log(parseInt(Math.pow(2, this.clickedRow).toString(), 2));
    }

}
