import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfigLoader } from '@tc-core/util/framework/config-loader.service';
import { SpinnerService } from '@tc-core/util/ui';
import { TCO } from '../../../constants';
import { SupplierSearchCriteria } from '../../../models/criteria/supplier-search-criteria';
import { DispatchEntry, DispatchStatus } from '../../../models/document/dispatch-entry';
import { DispatchEntryOperation } from '../../../models/document/dispatch-patch';
import { Document } from '../../../models/document/document';
import { SupplierSummary } from '../../../models/summary/supplier-summary';
import { DocumentService } from '../../../services/backend-consumers/documents/document.service';
import { SupplierService } from '../../../services/backend-consumers/supplier-service/supplier.service';
import { DataKey, DataStoreService } from '../../../services/util/framework/data-store.service';
import { ResponseUtil } from '../../../services/util/response/response-util.service';
import { RegXPatterns } from '../../../widgets/shared/regx-patterns/regx-patterns';

export enum DispatchDataModalType
{
    ADD_DISPATCH,
    SEND_DISPATCH
}

@Component({
    selector: 'tc-dispatch-data-modal',
    templateUrl: './dispatch-data-modal.component.html'
})
export class DispatchDataModalComponent implements OnInit
{
    public supplierSummaries: SupplierSummary[] = [];
    public selectedSupplier: SupplierSummary = null;

    dispatchForm: FormGroup;
    private fromSupplierSelectLock = false;
    okBtnText: string;

    isHiddenEmailSubject = false;
    isHiddenEmailBody = false;
    isDisableEmailSubject = false;
    isDisableEmailBody = false;

    isDisableSupplierInput = false;
    isDisableEmailInput = false;

    @Input() modalType: DispatchDataModalType;
    @Input() document: Document;
    @Input() dispatchEntry: DispatchEntry;
    @Input() defaultSupplier: SupplierSummary;

    constructor(
        private dataStoreService: DataStoreService,
        private fb: FormBuilder,
        private supplierService: SupplierService,
        private documentService: DocumentService,
        private configLoader: ConfigLoader,
        private spinnerService: SpinnerService
    )
    { }

    ngOnInit()
    {
        const emailConfig = this.configLoader.configurations.get(TCO.CONF.CONF_MANUAL_SEND_MANIFEST_MODAL);
        this.isDisableEmailSubject = emailConfig.emailSubject.disable;
        this.isDisableEmailBody = emailConfig.emailBody.disable;
        this.isHiddenEmailSubject = emailConfig.emailSubject.hide;
        this.isHiddenEmailBody = emailConfig.emailBody.hide;

        this.createForm();
        if (this.modalType === DispatchDataModalType.ADD_DISPATCH) {
            this.okBtnText = 'ADD';
        } else if (this.modalType === DispatchDataModalType.SEND_DISPATCH && this.dispatchEntry) {
            // disable supplier and email fields send and resend scenarios
            this.isDisableSupplierInput = true;
            this.isDisableEmailInput = true;

            if (this.dispatchEntry.dispatchStatus === DispatchStatus.NOT_DISPATCHED) {
                this.okBtnText = 'SEND';
            } else if (this.dispatchEntry.dispatchStatus === DispatchStatus.PENDING) {
                this.okBtnText = 'SEND';
            } else if (this.dispatchEntry.dispatchStatus === DispatchStatus.SUCCESS) {
                this.okBtnText = 'RESEND';
            } else if (this.dispatchEntry.dispatchStatus === DispatchStatus.FAILED) {
                this.okBtnText = 'RESEND';
            } else {
                this.okBtnText = 'SEND';
            }
        }
        if (!this.dispatchEntry) {
            this.dispatchEntry = new DispatchEntry();
        }
        this.dispatchForm.valueChanges.subscribe(
            data => {
                if (JSON.stringify(data) !== JSON.stringify({})) {
                    if (data.supplier && typeof (data.supplier) !== 'object') {
                        this.onSupplierTextChange(data.supplier);
                    }
                    if (data.email && typeof (data.email) !== 'object') {
                        this.onEmailTextChange(data.email);
                    }
                    this.updateDataModel();
                }
            }
        );
        this.validateForm();
    }

    public validateForm()
    {
        Object.keys(this.dispatchForm.controls).forEach(field => {
            const control = this.dispatchForm.get(field);
            control.markAsTouched({onlySelf: true});
        });
    }

    private createForm()
    {
        let email = '';
        let emailSubject = '';
        let emailBody = '';
        if (this.dispatchEntry && this.dispatchEntry.email) {
            this.selectedSupplier = new SupplierSummary();
            this.selectedSupplier.supplierId = this.dispatchEntry.supplierId;
            this.selectedSupplier.code = this.dispatchEntry.supplierCode;
            this.selectedSupplier.name = this.dispatchEntry.supplierName;
            this.selectedSupplier.email = this.dispatchEntry.email;

            email = this.dispatchEntry.email;
            emailSubject = this.dispatchEntry.emailSubject;
            emailBody = this.dispatchEntry.emailBody;
        } else {
            if (this.defaultSupplier) {
                email = this.defaultSupplier.email;
                this.dispatchEntry = new DispatchEntry();
                this.dispatchEntry.supplierId = this.defaultSupplier.supplierId;
                this.dispatchEntry.supplierCode = this.defaultSupplier.code;
                this.dispatchEntry.supplierName = this.defaultSupplier.name;
                this.dispatchEntry.email = this.defaultSupplier.email;
                this.selectedSupplier = this.defaultSupplier;
            }
        }
        this.dispatchForm = this.fb.group({
            supplier: this.selectedSupplier,
            email: [email, [Validators.pattern(RegXPatterns.EMAIL), Validators.required]],
            emailSubject: [
                emailSubject,
                [Validators.required]
            ],
            emailBody: [emailBody, [Validators.required]]
        });
    }

    private updateDataModel()
    {
        const supplier: SupplierSummary = this.dispatchForm.get('supplier').value;
        if (supplier) {
            this.dispatchEntry.supplierId = supplier.supplierId;
            this.dispatchEntry.supplierCode = supplier.code;
            this.dispatchEntry.supplierName = supplier.name;
            this.dispatchEntry.supplierId = supplier.supplierId;
        } else {
            this.selectedSupplier = null;
        }
        this.dispatchEntry.email = this.dispatchForm.get('email').value;
        this.dispatchEntry.emailSubject = this.dispatchForm.get('emailSubject').value;
        this.dispatchEntry.emailBody = this.dispatchForm.get('emailBody').value;
    }

    public onClose()
    {
        this.dataStoreService.set(DataKey.popupClose, false);
    }

    public afterOk()
    {
        this.dataStoreService.set(DataKey.popupClose, true);
    }

    public getErrorMessage(field: string)
    {
        switch (field) {
            case 'email' :
                return this.dispatchForm && this.dispatchForm.get('email').hasError('required') ? 'Email Required' :
                    this.dispatchForm && this.dispatchForm.controls.email.hasError('pattern') ? 'Email invalid' : '';
            // case 'emailSubject' :
            //     return this.dispatchForm.get('emailSubject').hasError('required') ? 'Email Subject Required' : '';
            // case 'emailBody' :
            //     return this.dispatchForm.get('emailBody').hasError('required') ? 'Email Body Required' : '';
            default :
                return '';
        }
    }

    public onOkClick()
    {
        if (this.modalType === DispatchDataModalType.ADD_DISPATCH) {
            this.addNewDispatchEntry();
        } else if (this.modalType === DispatchDataModalType.SEND_DISPATCH) {
            this.sendDispatchEntry();
        }
    }

    private addNewDispatchEntry()
    {
        this.spinnerService.show();
        this.documentService.postDispatchEntry(this.document.documentId, this.dispatchEntry).subscribe(
            value => {
                console.log('save dispatch entry success');
                this.afterOk();
            },
            error => {
                console.log('save dispatch entry error');
            }, () => {
                this.spinnerService.hide();
            }
        );
    }

    private sendDispatchEntry()
    {
        this.spinnerService.show();
        let operation = DispatchEntryOperation.RESEND;
        if (this.dispatchEntry && this.dispatchEntry.dispatchStatus === DispatchStatus.PENDING) {
            operation = DispatchEntryOperation.SEND;
        }
        this.documentService.patchDispatchEntry(
            this.document.documentId,
            this.dispatchEntry.id,
            this.dispatchEntry,
            operation
        ).subscribe(
            value => {
                console.log('patch dispatch entry success');
                this.afterOk();
            },
            error => {
                console.log('patch dispatch entry error');
            }, () => {
                this.spinnerService.hide();
            }
        );
    }

    public isOkDisabled()
    {
        return !(this.selectedSupplier && this.dispatchForm.get('email').valid);
    }

    displaySupplierFn(supplier: any)
    {
        return supplier ? supplier.name : supplier;
    }

    public onSupplierFocus($event: FocusEvent)
    {

    }

    public isRestrictedEditSupplier()
    {
        return false;
    }

    private onSupplierTextChange(text: string)
    {
        const supplierSearchCriteria = new SupplierSearchCriteria();
        supplierSearchCriteria.name = text;
        this.supplierSummaries = [];
        this.supplierService.searchSuppliers(supplierSearchCriteria)
            .subscribe(
                result => {
                    this.supplierSummaries = ResponseUtil.getDataArray(result);
                }, error => {
                    this.supplierSummaries = [];
                }
            );
    }

    public onSelectSupplier(supplier: SupplierSummary)
    {
        this.selectedSupplier = supplier;
        if (supplier) {
            this.dispatchForm.get('email').setValue(supplier.email, {onlySelf: true});
        }
    }

    private onEmailTextChange(email: string)
    {
    }

}
