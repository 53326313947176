import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { locationGroupSetup } from '../../../../../models/master-data/location-group-setup';
import { RoutesSetupService } from '../../../../../services/backend-consumers/deprecated/routes-setup.service';
import { DataKey, DataStoreService } from '../../../../../services/util/framework/data-store.service';

@Component({
    selector: 'tc-add-location-group',
    templateUrl: './add-location-group.component.html'
})
export class AddLocationGroupComponent implements OnInit {
    locationInforForm: FormGroup;
    locationList: any;
    newLocationGroup = new locationGroupSetup;
    newLocations = [];
    @Output() changeFormValidity: EventEmitter<boolean> = new EventEmitter();

    constructor(
        private fb: FormBuilder,
        private routeService: RoutesSetupService,
        private dataStore: DataStoreService
    ) {
        this.createForm();
    }

    ngOnInit() {
        this.routeService.retrievelocations().subscribe(data => {
            this.locationList = data['locations'];
        });
        this.locationInforForm.valueChanges.subscribe(
            (data) => {
                if (JSON.stringify(data) !== JSON.stringify({})) {
                    this.newLocationGroup.groupCode = data.groupCode;
                    this.newLocationGroup.groupName = data.groupName;
                    this.newLocationGroup.locations = this.newLocations;
                    console.log(this.newLocationGroup);
                    this.changeFormValidity.emit(this.locationInforForm.valid);
                }

            }
        );

    }

    onChange(selectedLocations: any) {
        this.newLocations = selectedLocations;
        // this.locationInforForm.controls.locations = selectedLocations;
        // console.log(this.newLocationGroup);
        // const resourceArray = this.locationInforForm.get('locations') as FormArray;
    }

    createForm() {
        this.locationInforForm = this.fb.group({
            groupCode: [''],
            groupName: [''],
            locations: ['']
        });
    }

    setLocationGroup() {
        if (this.newLocationGroup.groupCode && this.newLocationGroup.groupName && this.newLocationGroup.locations) {
            this.dataStore.set(DataKey.locationGroup, this.newLocationGroup, true);
        }
    }

    closePopUp(event: any) {
        this.dataStore.set(DataKey.modalClose, true);
    }
}
