import * as tslib_1 from "tslib";
import { AbstractSearchCriteria } from './abstract-search-criteria';
var CurrencySearchCriteria = /** @class */ (function (_super) {
    tslib_1.__extends(CurrencySearchCriteria, _super);
    function CurrencySearchCriteria() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.code = '';
        _this.name = '';
        _this.category = '';
        return _this;
    }
    return CurrencySearchCriteria;
}(AbstractSearchCriteria));
export { CurrencySearchCriteria };
