import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { AssignmentSaveOperation } from '../../models/reservation/assignment-save-operation';
import { AssignmentStatus } from '../../models/reservation/assignment-status';
import { ServiceItem } from '../../models/reservation/service-item';
import { SearchResultSummary } from '../../models/reservation/search-result-summary';
import { Subscription } from 'rxjs';
import { ConfigLoader, EventManager, FocusViewManager, UserJourneyManager } from '@tc-core/util/framework';
import { TC } from '@tc-core/util';
import { DataKey, DataStoreService } from '../../services/util/framework/data-store.service';
import { FocusViewService } from '@tc/focus-view/focus-view.service';
import { CurrencyFormat } from '@tc-core/model/it/codegen/ui/framework/currency-format';
import { TCO } from '../../constants';
import { Pager } from '@tc-core/model/it/codegen/ui/framework/pager';
import { ChangeDetectorService } from '../../services/util/change-detector/change-detector.service';
import { ResponseUtil } from '../../services/util/response/response-util.service';
import { TcApiError } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-api-error';
import { TcHttpError } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-http-error';
import { CommonHelper } from '@tc-core/util/helpers';
import { SpinnerService } from '@tc-core/util/ui';
import { CostCalculationUtilsService } from '../../services/business-utils/cost-calculation/cost-calculation-utils.service';
import { SupplierService } from '../../services/backend-consumers/supplier-service/supplier.service';
import { ContentSummaryCardService } from '../../services/util/ui/content-summary-card.service';
import { ServiceAssignment } from '../../models/reservation/service-assignment';
import { LEVEL, DialogModel } from '@tc-core/model/it/codegen/ui/framework/dialog-model';
import { TcErrorType } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-error';
import { DMCCommon } from '../../services/util/common/dmc-common';
import { DialogService } from '@tc/dialog/dialog.service';
import { VehicleSearchCriteria } from '../../models/criteria/vehicle-search-criteria';
import { AssignmentSummary } from '../../models/assignment-summary';
import * as moment from 'moment';
import { ResourceType } from '../../models/reservation/assignment';
import { Icon } from '@tc-core/model/it/codegen/ui/framework/icon';
import { TransportResourceService } from '../../services/backend-consumers/supplier-service/transport-resource.service';
import { ModalData } from '@tc-core/model/it/codegen/ui/framework/modal-data';
import { ResAvailabilityCalendarFocusViewComponent } from '../../widgets/shared/res-availability-calendar-focus-view/res-availability-calendar-focus-view.component';
import { IdentifierService } from '../../services/util/identifier/identifier.service';
import { GenTourItem } from "../../models/reservation/generic/gen-tour-item";
import { GenericResourceService } from "../../services/backend-consumers/supplier-service/generic-resource.service";
var AssignmentFocusViewComponent = /** @class */ (function () {
    function AssignmentFocusViewComponent(dataStore, em, focusViewManager, journeyManager, focusViewService, configLoader, changeDetector, commonHelper, spinnerService, costCalculationUtilsService, supplierService, summaryCardService, common, dialogService, transportResourceService, genericResourceService, identifierService) {
        this.dataStore = dataStore;
        this.em = em;
        this.focusViewManager = focusViewManager;
        this.journeyManager = journeyManager;
        this.focusViewService = focusViewService;
        this.configLoader = configLoader;
        this.changeDetector = changeDetector;
        this.commonHelper = commonHelper;
        this.spinnerService = spinnerService;
        this.costCalculationUtilsService = costCalculationUtilsService;
        this.supplierService = supplierService;
        this.summaryCardService = summaryCardService;
        this.common = common;
        this.dialogService = dialogService;
        this.transportResourceService = transportResourceService;
        this.genericResourceService = genericResourceService;
        this.identifierService = identifierService;
        this.selectedIndex = 0;
        this.isBulkAssignment = false;
        this.isVehicleAssignment = false;
        this.isGenResourceAssignment = false;
        this.isDriverAssignment = false;
        this.unSavedAssignments = [];
        this.isDataLoaded = true;
        // serviceItems
        this.changesAvailableForServiceItems = false;
        this.defaultPageResultCount = 4;
        this.supplierPanelHeading = '';
        this.contentCardDataListSuppliers = [];
        this.suppliers = [];
        this.suppliersResultCount = 0;
        this.fetchedSupplierCount = 0;
        this.suppliersFromMoreClick = false;
        this.hasMoreSuppliers = true;
        this.dataFromMoreClick = false;
        this.supplierIdList = [];
        this.displayChipsArrSuppliers = [];
        this.supplierLabel = "";
        this.vehiclePanelHeading = '';
        this.vehicles = [];
        this.fetchedVehicleCount = 0;
        this.vehiclesResultCount = 0;
        this.vehiclesFromMoreClick = false;
        this.hasMoreVehicles = true;
        this.contentCardDataListVehicles = [];
        this.vehicleSearchCriteria = new VehicleSearchCriteria;
        this.displayChipsArrVehicles = [];
        this.drivers = [];
        this.contentCardDataListDrivers = [];
        this.driverPanelHeading = '';
        this.fetchedDriverCount = 0;
        this.driversResultCount = 0;
        this.driversFromMoreClick = false;
        this.hasMoreDrivers = true;
        this.resources = [];
        this.fetchedResourceCount = 0;
        this.resourceResultCount = 0;
        this.resourcePanelHeading = '';
        this.contentCardDataListResources = [];
        this.hasMoreResources = true;
        this.resourcesFromMoreClick = false;
        //availability calendar
        this.navConfig = [];
        this.supplierResultObsv = new Subscription();
        this.supplierMoreResultObsv = new Subscription();
        this.vehicleResultObsv = new Subscription();
        this.vehicleMoreResultObsv = new Subscription();
        this.driverResultObsv = new Subscription();
        this.resourceResultObsv = new Subscription();
        this.driverMoreResultObsv = new Subscription();
        this.transferModesObsv = new Subscription();
        this.focusViewCloseSubject = new Subscription();
    }
    AssignmentFocusViewComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.currencyFormat = this.getCurrencyFormat();
        this.journeyManager.canProceed.next(false);
        this.focusViewManager.canProceed.next(false);
        this.focusViewManager.canProceedState.next(true);
        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'CLOSE_FOCUS_VIEW').subscribe(function (e) {
            _this.dataStore.set(DataKey.availabilityCalendarFocusViewClose, null);
            _this.dataStore.set(DataKey.availabilityCalendarFocusViewClose, true);
            _this.onSave();
            _this.focusViewService.close(true);
        });
        this.resourceCategoryIcons = this.configLoader.configurations.get(TCO.CONF.CONF_GENERIC_QUEUE_ICONS).RESOURCE_CATEGORY_ICONS;
        this.enableFocusViewProceed();
        //pagination
        this.pager = new Pager(0, this.defaultPageResultCount);
        this.loadSupplierPanel();
        if (this.isVehicleAssignment) {
            this.supplierLabel = "Vehicle Supplier";
            this.loadVehiclePanel();
        }
        else if (this.isDriverAssignment) {
            this.supplierLabel = "Driver Supplier";
            this.loadDriverPanel();
        }
        else if (this.isGenResourceAssignment) {
            this.supplierLabel = "Resource Supplier";
            this.loadResourcePanel();
        }
        this.changeDetector.notifierSupplierCostState.subscribe(function (value) {
            _this.updateSupplierSearchResults();
        });
    };
    AssignmentFocusViewComponent.prototype.getCurrencyFormat = function () {
        var currencyFormat;
        var currencySymbolPattern = this.configLoader.configurations.get(TC.CONF.CONF_SYSTEM).defaults.default_currency_symbol_pattern;
        currencyFormat = new CurrencyFormat(TCO.AppData.DEF_CURRENCY_TYPE, currencySymbolPattern);
        return currencyFormat;
    };
    AssignmentFocusViewComponent.prototype.enableFocusViewProceed = function () {
        if (this.selectedSupplier) {
            this.focusViewManager.canProceed.next(true);
        }
        else {
            this.focusViewManager.canProceed.next(false);
        }
    };
    AssignmentFocusViewComponent.prototype.setPageZero = function () {
        if (this.pager) {
            if (!this.dataFromMoreClick) {
                this.pager.currPage = 0;
            }
            else {
                this.dataFromMoreClick = false;
            }
        }
        else {
            this.pager = new Pager(0, this.defaultPageResultCount);
        }
    };
    //**************** supplier panel*********************
    AssignmentFocusViewComponent.prototype.loadSupplierPanel = function () {
        // load supplier summary card configs
        this.tAssignedSuppliers = this.configLoader.configurations.get(TCO.CONF.CONF_RESERVATION_RESOURCE_ASSIGN_CONFIG).supplier;
        if (!this.isGenResourceAssignment) {
            this.getTransferModeCapacity();
        }
        else if (this.isGenResourceAssignment) {
            this.dataStore.set(DataKey.assignedGenResourceSupp, null, true);
            this.getGenSuppliers();
        }
        this.moreSupplierObserver();
    };
    AssignmentFocusViewComponent.prototype.getGenSuppliers = function () {
        var _this = this;
        this.dataStore.set(DataKey.searchSuppliersForGenAssignment, null, true);
        this.supplierResultObsv = this.dataStore.get(DataKey.searchSuppliersForGenAssignment).subscribe(function (result) {
            if (_this.commonHelper.dataValidity(result)) {
                _this.spinnerService.hide();
                if (ResponseUtil.isSuccess(result)) {
                    _this.suppliers = ResponseUtil.getDataArray(result);
                    _this.contentCardDataListSuppliers = [];
                    _this.suppliers = _this.processSupplierResults(_this.suppliers);
                    _this.fetchedSupplierCount = _this.suppliers.length;
                    _this.suppliersResultCount = ResponseUtil.getTotalCount(result);
                    if (_this.suppliers) {
                        _this.supplierPanelHeading = _this.suppliersResultCount + ' result' +
                            (_this.suppliersResultCount > 1 ? 's' : '') + ' found.';
                    }
                    _this.contentCardDataListSuppliers = _this.getSupplierSummaryCards(_this.suppliers);
                }
            }
            else if (_this.commonHelper.isEmptyData(result)) {
                _this.isDataLoaded = true;
            }
            else if (result instanceof TcApiError) {
                _this.spinnerService.hide();
            }
            else if (result instanceof TcHttpError) {
                _this.spinnerService.hide();
            }
            // this.getSuppliersSearchResult();
        });
    };
    AssignmentFocusViewComponent.prototype.getTransferModeCapacity = function () {
        var _this = this;
        this.transferModesObsv = this.dataStore.get(DataKey.transferModes).subscribe(function (data) {
            if (data !== null) {
                if (!_this.isBulkAssignment) {
                    _this.serviceDate = _this.bookingData.serviceDate;
                    _this.transferMode = data.find(function (val) { return val.code === _this.bookingData.trsServiceItem.transferMode; });
                    _this.route = _this.bookingData.trsServiceItem.route;
                }
                else {
                    _this.serviceDate = _this.bookingDataArray[0].serviceDate;
                    _this.transferMode = data.find(function (val) { return val.code === _this.bookingDataArray[0]['rawData'].trsServiceItem.transferMode; });
                    _this.route = _this.bookingDataArray[0]['rawData'].trsServiceItem.route;
                }
                _this.getSuppliers();
            }
        });
    };
    AssignmentFocusViewComponent.prototype.getSuppliers = function () {
        this.dataStore.set(DataKey.assignedSupplier, null, true);
        if (this.isVehicleAssignment) {
            this.loadTrsSuppliers();
        }
        else {
            this.loadGenSuppliers();
        }
    };
    AssignmentFocusViewComponent.prototype.loadTrsSuppliers = function () {
        var _this = this;
        this.supplierResultObsv = this.dataStore.get(DataKey.suppliersForAssignment).subscribe(function (result) {
            if (_this.commonHelper.dataValidity(result)) {
                _this.spinnerService.hide();
                if (ResponseUtil.isSuccess(result)) {
                    _this.suppliers = ResponseUtil.getDataArray(result);
                    _this.contentCardDataListSuppliers = [];
                    _this.suppliers = _this.processSupplierResults(_this.suppliers);
                    _this.fetchedSupplierCount = _this.suppliers.length;
                    _this.suppliersResultCount = ResponseUtil.getTotalCount(result);
                    if (_this.suppliers) {
                        _this.supplierPanelHeading = _this.suppliersResultCount + ' result' +
                            (_this.suppliersResultCount > 1 ? 's' : '') + ' found.';
                    }
                    _this.contentCardDataListSuppliers = _this.getSupplierSummaryCards(_this.suppliers);
                }
            }
            else if (_this.commonHelper.isEmptyData(result)) {
                _this.isDataLoaded = true;
            }
            else if (result instanceof TcApiError) {
                _this.spinnerService.hide();
            }
            else if (result instanceof TcHttpError) {
                _this.spinnerService.hide();
            }
            // this.getSuppliersSearchResult();
        });
    };
    AssignmentFocusViewComponent.prototype.loadGenSuppliers = function () {
        var _this = this;
        this.supplierResultObsv = this.dataStore.get(DataKey.driverSuppliersForAssignment).subscribe(function (result) {
            if (_this.commonHelper.dataValidity(result)) {
                _this.spinnerService.hide();
                if (ResponseUtil.isSuccess(result)) {
                    _this.suppliers = ResponseUtil.getDataArray(result);
                    _this.contentCardDataListSuppliers = [];
                    _this.suppliers = _this.processSupplierResults(_this.suppliers);
                    _this.fetchedSupplierCount = _this.suppliers.length;
                    _this.suppliersResultCount = ResponseUtil.getTotalCount(result);
                    if (_this.suppliers) {
                        _this.supplierPanelHeading = _this.suppliersResultCount + ' result' +
                            (_this.suppliersResultCount > 1 ? 's' : '') + ' found.';
                    }
                    _this.contentCardDataListSuppliers = _this.getSupplierSummaryCards(_this.suppliers);
                }
            }
            else if (_this.commonHelper.isEmptyData(result)) {
                _this.isDataLoaded = true;
            }
            else if (result instanceof TcApiError) {
                _this.spinnerService.hide();
            }
            else if (result instanceof TcHttpError) {
                _this.spinnerService.hide();
            }
            // this.getSuppliersSearchResult();
        });
    };
    AssignmentFocusViewComponent.prototype.processSupplierResults = function (suppliers) {
        var e_1, _a;
        this.supplierIdList = [];
        try {
            // console.log(this.genTourItem);
            for (var suppliers_1 = tslib_1.__values(suppliers), suppliers_1_1 = suppliers_1.next(); !suppliers_1_1.done; suppliers_1_1 = suppliers_1.next()) {
                var supplier = suppliers_1_1.value;
                this.supplierIdList.push(supplier.supplierId);
                if (this.transferMode) {
                    supplier.capacity = this.transferMode.capacity;
                }
                if (this.isGenResourceAssignment) {
                    supplier = this.mapGenSuppToDriverSupp(supplier);
                }
                supplier = this.getSupplierCostData(supplier);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (suppliers_1_1 && !suppliers_1_1.done && (_a = suppliers_1.return)) _a.call(suppliers_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return suppliers;
    };
    AssignmentFocusViewComponent.prototype.mapGenSuppToDriverSupp = function (supplier) {
        if (supplier.resourceCostSummaries.length > 0) {
            var costSummaries = supplier.resourceCostSummaries[0];
            supplier.unitCost = costSummaries.unitCost;
            supplier.totalCost = null;
            supplier.adultFare = costSummaries.adultFare;
            supplier.childFare = costSummaries.childFare;
            supplier.infantFare = costSummaries.infantFare;
            supplier.teenFare = costSummaries.teenFare;
            supplier.unitFare = costSummaries.unitFare;
        }
        return supplier;
    };
    AssignmentFocusViewComponent.prototype.getSupplierCostData = function (supplier) {
        if (supplier.unitFare) {
            supplier.isUnitFare = true;
            supplier.estimatedTotalCost = supplier.totalCost;
            supplier.totalCost = this.costCalculationUtilsService.getSupplierTotalFromUnitCost(supplier, this.isBulkAssignment, this.isGenResourceAssignment ? this.genTourItem : this.bookingData, this.bookingDataArray, this.isGenResourceAssignment);
        }
        else {
            supplier.isUnitFare = false;
            var costSummary = this.costCalculationUtilsService.getSupplierTotalCost(supplier, this.isBulkAssignment, this.isGenResourceAssignment ? this.genTourItem : this.bookingData, this.bookingDataArray, this.isGenResourceAssignment);
            supplier.estimatedTotalCost = supplier.totalCost;
            supplier.totalCost = costSummary.totalCost;
            supplier.totalAdultFare = costSummary.adultFare;
            supplier.totalChildFare = costSummary.childFare;
            supplier.totalInfantFare = costSummary.infantFare;
        }
        return supplier;
    };
    AssignmentFocusViewComponent.prototype.getSupplierSummaryCards = function (suppliers) {
        suppliers.sort(function (a, b) { return a.totalCost - b.totalCost; });
        var summaryCards = this.summaryCardService.getContentCardsByConfig(this.tAssignedSuppliers, suppliers);
        if (this.isGenResourceAssignment && summaryCards.length > 0) {
            var iconView_1 = new Icon();
            var summaryIcon = void 0;
            var iconValue = void 0;
            var arr = this.tAssignedResources.config.icon;
            summaryIcon = arr.find(function (item) { return item['iconName'] === 'GENERIC_RESOURCE_ICN'; });
            iconValue = summaryIcon['key'];
            if (iconValue === "resource") {
                iconValue = this.genTourItem.categoryCode;
            }
            if (summaryIcon) {
                if (summaryIcon.iconKeys[iconValue]) {
                    iconView_1.key = summaryIcon.iconKeys[iconValue];
                }
                iconView_1.iconType = summaryIcon['iconType'];
                iconView_1.value = summaryIcon.values[iconValue];
                iconView_1.style = summaryIcon['style'] + ' ' +
                    summaryIcon['activeClass'];
                iconView_1.value = iconView_1.value ? iconView_1.value : '';
            }
            if (iconView_1.value.length > 3) {
                iconView_1.style = iconView_1.style + ' ' + summaryIcon['smallTextClass'];
            }
            summaryCards.forEach(function (summaryCard) {
                summaryCard.icon.value = iconView_1;
            });
        }
        this.spinnerService.hide();
        this.setPageZero();
        return summaryCards;
    };
    AssignmentFocusViewComponent.prototype.updateSupplierSearchResults = function () {
        var _this = this;
        if (this.selectedSupplier) {
            this.contentCardDataListSuppliers.forEach(function (da) {
                if (da.data.value.supplierId == _this.selectedSupplier.supplierId) {
                    da = _this.selectedSupplier;
                }
            });
            this.contentCardDataListSuppliers = this.getSupplierSummaryCards(this.suppliers);
        }
    };
    AssignmentFocusViewComponent.prototype.onSupPaginationMore = function (criteria) {
        criteria.start = this.fetchedSupplierCount;
        this.dataStore.set(DataKey.supplierAssignAllFetchedResults, null, true);
        this.supplierService.searchSuppliersForAssignmentOnMore(criteria);
    };
    AssignmentFocusViewComponent.prototype.moreSupplierObserver = function () {
        var _this = this;
        this.supplierMoreResultObsv = this.dataStore.get(DataKey.supplierAssignAllFetchedResults).subscribe(function (result) {
            var _a, _b;
            if (_this.commonHelper.dataValidity(result)) {
                _this.supplierMoreResultObsv.unsubscribe();
                var dataList = ResponseUtil.getDataArray(result);
                if (dataList) {
                    if (dataList.length > 0) {
                        var moreSuppliers = _this.processSupplierResults(dataList);
                        (_a = _this.suppliers).push.apply(_a, tslib_1.__spread(moreSuppliers));
                        _this.fetchedSupplierCount = _this.suppliers.length;
                        _this.hasMoreSuppliers = _this.suppliersResultCount !== _this.fetchedSupplierCount;
                        var contentCardDataList = _this.getSupplierSummaryCards(moreSuppliers);
                        (_b = _this.contentCardDataListSuppliers).push.apply(_b, tslib_1.__spread(contentCardDataList));
                    }
                    else {
                        _this.hasMoreSuppliers = false;
                    }
                }
            }
            else if (result instanceof TcApiError) {
                _this.hasMoreSuppliers = false;
            }
            else if (result instanceof TcHttpError) {
                _this.hasMoreSuppliers = false;
            }
        });
    };
    AssignmentFocusViewComponent.prototype.onSupplierSelect = function (event) {
        this.changesAvailableForServiceItems = true;
        this.selectedSupplier = event;
        this.enableFocusViewProceed();
        this.dataStore.set(DataKey.assignedSupplier, this.selectedSupplier.data.value, true);
    };
    AssignmentFocusViewComponent.prototype.assignSupplier = function (summaryCard) {
        var _this = this;
        var tempAssignmentId = 'Temp_' + this.identifierService.getNextManuallyResetId();
        // TODO: assign supplier only
        if (this.isBulkAssignment) {
            this.serviceItemChangesInSelectedGroup.forEach(function (value) {
                if (value.checked) {
                    if (_this.isVehicleAssignment) {
                        _this.setVehicleSupplierToAssignment(value.serviceItemId, summaryCard.commonSupplier, tempAssignmentId);
                    }
                    else if (_this.isDriverAssignment) {
                        _this.setDriverSupplierToAssignment(value.serviceItemId, summaryCard.commonSupplier, tempAssignmentId);
                    }
                }
            });
        }
        else {
            if (this.isVehicleAssignment) {
                this.setVehicleSupplierToAssignment(this.bookingData.serviceItemId, summaryCard.commonSupplier, tempAssignmentId);
            }
            else if (this.isDriverAssignment) {
                this.setDriverSupplierToAssignment(this.bookingData.serviceItemId, summaryCard.commonSupplier, tempAssignmentId);
            }
        }
        this.changeDetector.notifyChange();
    };
    AssignmentFocusViewComponent.prototype.setVehicleSupplierToAssignment = function (serviceItemId, supplier, tempAssignmentId) {
        var serviceItem = this.isBulkAssignment ? this.bookingDataArray.find(function (value) { return value.serviceItemId === serviceItemId; }) : this.bookingData;
        this.serviceItemChangesInSelectedGroup.get(serviceItemId).changed = true;
        this.serviceItemChangesInSelectedGroup.get(serviceItemId).vehicleSupplier = supplier;
        var serviceAssignments = this.serviceItemChangesInSelectedGroup.get(serviceItemId).serviceAssignments;
        var assignment = new ServiceAssignment();
        assignment = this.getSupplierAssignmentMap(assignment, supplier, serviceItemId);
        assignment.estimatedCost = supplier.estimatedTotalCost;
        assignment.categoryCode = ResourceType.vehicle;
        assignment.tempAssignmentId = tempAssignmentId;
        this.serviceItemChangesInSelectedGroup.get(serviceItemId).serviceAssignments = this.serviceItemChangesInSelectedGroup.get(serviceItemId).serviceAssignments ?
            this.serviceItemChangesInSelectedGroup.get(serviceItemId).serviceAssignments :
            new Map();
        this.serviceItemChangesInSelectedGroup.get(serviceItemId).serviceAssignments
            .set(ResourceType.vehicle, assignment);
        this.serviceItemChangesInSelectedGroup.get(serviceItemId).vehicleCost = this.costCalculationUtilsService.calculateServiceItemJobCost(serviceItem, assignment);
    };
    AssignmentFocusViewComponent.prototype.setDriverSupplierToAssignment = function (serviceItemId, supplier, tempAssignmentId) {
        var serviceItem = this.isBulkAssignment ? this.bookingDataArray.find(function (value) { return value.serviceItemId === serviceItemId; }) : this.bookingData;
        this.serviceItemChangesInSelectedGroup.get(serviceItemId).changed = true;
        this.serviceItemChangesInSelectedGroup.get(serviceItemId).driverSupplier = supplier;
        var serviceAssignments = this.serviceItemChangesInSelectedGroup.get(serviceItemId).serviceAssignments;
        var assignment = new ServiceAssignment();
        assignment = this.getSupplierAssignmentMap(assignment, supplier, serviceItemId);
        assignment.estimatedCost = supplier.estimatedTotalCost;
        assignment.categoryCode = ResourceType.driver;
        assignment.tempAssignmentId = tempAssignmentId;
        this.serviceItemChangesInSelectedGroup.get(serviceItemId).serviceAssignments = this.serviceItemChangesInSelectedGroup.get(serviceItemId).serviceAssignments ?
            this.serviceItemChangesInSelectedGroup.get(serviceItemId).serviceAssignments :
            new Map();
        this.serviceItemChangesInSelectedGroup.get(serviceItemId).serviceAssignments
            .set(ResourceType.driver, assignment);
        this.serviceItemChangesInSelectedGroup.get(serviceItemId).driverCost = this.costCalculationUtilsService.calculateServiceItemJobCost(serviceItem, assignment);
    };
    AssignmentFocusViewComponent.prototype.getSupplierAssignmentMap = function (assignment, supplier, serviceItemId) {
        assignment.supplierId = supplier.supplierId;
        assignment.supplierName = supplier.name;
        assignment.supplierCode = supplier.code;
        assignment.currency = supplier.currency;
        assignment.adults = this.passengerCount.adult ? this.passengerCount.adult : 0;
        assignment.children = this.passengerCount.children ? this.passengerCount.children : 0;
        assignment.infants = this.passengerCount.infants ? this.passengerCount.infants : 0;
        assignment.adultPrice = supplier.adultFare;
        assignment.childPrice = supplier.childFare;
        assignment.infantPrice = supplier.infantFare;
        assignment.unitPrice = supplier.unitFare;
        assignment.jobCost = supplier.totalCost;
        assignment.serviceItemId = serviceItemId;
        if (this.bookingData && this.bookingData.serviceDate) {
            assignment.jobDate = this.bookingData.serviceDate;
        }
        else {
            assignment.jobDate = this.bookingDataArray[0] && this.bookingDataArray[0].rawData ? this.bookingDataArray[0].rawData.serviceDate : "";
        }
        assignment.updated = true;
        if (assignment.assignmentId > 0) {
            assignment.assignmentSaveOperation = AssignmentSaveOperation.UPDATE;
        }
        else {
            assignment.assignmentSaveOperation = AssignmentSaveOperation.SAVE;
        }
        assignment.assignStatus = AssignmentStatus.PENDING;
        return assignment;
    };
    //**************** vehicle panel*********************
    AssignmentFocusViewComponent.prototype.loadVehiclePanel = function () {
        // load vehicle summary card configs
        this.tAssignedVehicles = this.configLoader.configurations.get(TCO.CONF.CONF_RESERVATION_RESOURCE_ASSIGN_CONFIG).vehicle;
        this.getVehicles();
    };
    AssignmentFocusViewComponent.prototype.getVehicles = function () {
        var _this = this;
        this.vehicleResultObsv = this.dataStore.get(DataKey.resourceAssignVehicleSearchResults).subscribe(
        // this.vehicleSearchResultObsv = this.reservationService.searchRecommendedVehiclesFromMock(vehicleSearchCriteria).subscribe(
        function (result) {
            if (_this.commonHelper.dataValidity(result)) {
                if (result.status.code === TC.ErrorCodes.SUCCESS) {
                    var dataList = ResponseUtil.getDataArray(result);
                    if (dataList) {
                        _this.vehicles = dataList;
                        _this.fetchedVehicleCount = _this.vehicles.length;
                        _this.vehiclesResultCount = ResponseUtil.getTotalCount(result);
                        _this.vehiclePanelHeading = _this.vehiclesResultCount + ' result' +
                            (_this.vehiclesResultCount > 1 ? 's' : '') +
                            ' found.';
                        if (_this.vehicles.length > 0) {
                            _this.contentCardDataListVehicles = _this.vehicles;
                            _this.getVehicleSummaryCards();
                        }
                    }
                }
            }
        });
    };
    AssignmentFocusViewComponent.prototype.getVehicleSummaryCards = function () {
        var e_2, _a;
        try {
            for (var _b = tslib_1.__values(this.contentCardDataListVehicles), _c = _b.next(); !_c.done; _c = _b.next()) {
                var vehicle = _c.value;
                vehicle = this.processIcon(vehicle, this.tAssignedVehicles.config.icon, true);
                // if (this.unSavedAssignments && this.unSavedAssignments.length > 0) {
                //     let resource: ResourceAvailability = this.unSavedAssignments.filter(value => vehicle.resourceId === value.resourceId);
                //     if (resource && resource.assignments) {
                //         let selectedAssignmentExists = vehicle.assignments.filter(value => value.assignmentId ===resource.assignments[0] || value.tempAssignmentId === resource.assignments[0].tempAssignmentId);
                //         if(!selectedAssignmentExists){
                //             resource.assignments[0].selected = false;
                //             resource.assignments[0].recommended = false;
                //             vehicle.assignments.push(resource.assignments[0]);
                //         }
                //     }
                // }
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_2) throw e_2.error; }
        }
        this.spinnerService.hide();
    };
    AssignmentFocusViewComponent.prototype.processIcon = function (resource, arr, isVehicle) {
        var iconView = new Icon();
        var summaryIcon;
        var iconValue;
        if (isVehicle) {
            summaryIcon = arr.find(function (item) { return item['iconName'] === 'TRANSFER_MODE_ICN'; });
            var valueField = summaryIcon['key'];
            iconValue = resource[valueField];
            if (summaryIcon) {
                iconView.key = summaryIcon['iconName'];
                iconView.iconType = iconValue;
                iconView.value = iconValue;
                iconView.value = iconView.value ? iconView.value : '';
                iconView.style = summaryIcon['style'] + ' ' +
                    summaryIcon['neutralClass'];
            }
        }
        else {
            summaryIcon = arr.find(function (item) { return item['iconName'] === 'GENERIC_RESOURCE_ICN'; });
            iconValue = summaryIcon['key'];
            if (iconValue === "resource") {
                iconValue = this.genTourItem.categoryCode;
            }
            if (summaryIcon) {
                if (summaryIcon.iconKeys[iconValue]) {
                    iconView.key = summaryIcon.iconKeys[iconValue];
                }
                iconView.iconType = summaryIcon['iconType'];
                iconView.value = summaryIcon.values[iconValue];
                iconView.style = summaryIcon['style'] + ' ' +
                    summaryIcon['activeClass'];
                iconView.value = iconView.value ? iconView.value : '';
            }
        }
        if (iconView.value.length > 3) {
            iconView.style = iconView.style + ' ' + summaryIcon['smallTextClass'];
        }
        resource.icon = iconView;
        return resource;
    };
    AssignmentFocusViewComponent.prototype.onVehiclesPaginationMore = function (criteria) {
        criteria.start = this.fetchedVehicleCount;
        this.dataStore.set(DataKey.vehicleAssignAllFetchedResults, null, true);
        this.transportResourceService.searchVehiclesForAssignmentOnMore(criteria);
        this.moreVehiclesObserver();
    };
    AssignmentFocusViewComponent.prototype.moreVehiclesObserver = function () {
        var _this = this;
        this.vehicleMoreResultObsv = this.dataStore.get(DataKey.vehicleAssignAllFetchedResults).subscribe(function (result) {
            var _a;
            if (_this.commonHelper.dataValidity(result)) {
                _this.vehicleMoreResultObsv.unsubscribe();
                var dataList = ResponseUtil.getDataArray(result);
                if (dataList) {
                    if (dataList.length > 0) {
                        (_a = _this.vehicles).push.apply(_a, tslib_1.__spread(dataList));
                        _this.fetchedVehicleCount = _this.vehicles.length;
                        _this.hasMoreVehicles = _this.vehiclesResultCount !== _this.fetchedVehicleCount;
                        // this.getAllocatedSeatCount(this.vehicles);
                    }
                    else {
                        _this.hasMoreVehicles = false;
                    }
                }
                else {
                    _this.hasMoreVehicles = false;
                }
            }
            else if (result instanceof TcApiError) {
                _this.hasMoreVehicles = false;
            }
            else if (result instanceof TcHttpError) {
                _this.hasMoreVehicles = false;
            }
        });
    };
    AssignmentFocusViewComponent.prototype.onVehicleSelect = function (event) {
        this.changesAvailableForServiceItems = true;
    };
    AssignmentFocusViewComponent.prototype.assignVehicle = function (vehicle) {
        var _this = this;
        if (this.isBulkAssignment) {
            var serviceItemIds = [];
            this.serviceItemChangesInSelectedGroup.forEach(function (value) {
                if (value.checked) {
                    _this.setVehicleToAssignment(value.serviceItemId, vehicle);
                }
            });
        }
        else {
            this.setVehicleToAssignment(this.bookingData.serviceItemId, vehicle);
        }
        this.unSavedAssignments.push(vehicle);
        this.changeDetector.notifyChange();
    };
    AssignmentFocusViewComponent.prototype.setVehicleToAssignment = function (serviceItemId, vehicle) {
        this.serviceItemChangesInSelectedGroup.get(serviceItemId).changed = true;
        this.serviceItemChangesInSelectedGroup.get(serviceItemId).vehicle = vehicle;
        this.serviceItemChangesInSelectedGroup.get(serviceItemId).vehicleSupplier = vehicle.trsSupplier;
        var assignmentMap = new ServiceAssignment();
        var assignments = this.serviceItemChangesInSelectedGroup.get(serviceItemId).serviceAssignments;
        if (assignments && assignments.get(ResourceType.vehicle) &&
            assignments.get(ResourceType.vehicle).resourceId !== vehicle.resourceId) {
            assignmentMap = this.getResourceAssignmentMap(assignments.get(ResourceType.vehicle), vehicle, serviceItemId);
        }
        else {
            assignmentMap = this.getResourceAssignmentMap(assignmentMap, vehicle, serviceItemId);
        }
        assignmentMap.categoryCode = ResourceType.vehicle;
        if (this.serviceItemChangesInSelectedGroup.get(serviceItemId).serviceAssignments.get(ResourceType.driver)) {
            this.serviceItemChangesInSelectedGroup.get(serviceItemId).serviceAssignments
                .get(ResourceType.driver).parentAssignment = assignmentMap;
        }
        this.serviceItemChangesInSelectedGroup.get(serviceItemId).serviceAssignments
            .set(ResourceType.vehicle, assignmentMap);
        this.serviceItemChangesInSelectedGroup.get(serviceItemId).vehicleCost = this.getServiceItemCost(serviceItemId, assignmentMap);
        this.changesAvailableForServiceItems = true;
    };
    AssignmentFocusViewComponent.prototype.onVehicleEventClick = function (event) {
        if (event.selected) {
            this.deleteVehicleAssignment(event);
        }
        else {
            this.updateVehicleAssignment(event, false);
        }
    };
    AssignmentFocusViewComponent.prototype.deleteVehicleAssignment = function (event) {
        var _this = this;
        var confirmDataObject = new DialogModel(true, LEVEL.ERROR, 'Delete Job', 'Do you want to remove job?', true, 3000);
        this.dialogService.confirm(confirmDataObject).subscribe(function (res) {
            if (res === true) {
                // let resource = this.vehicles.find(value => event.resourceId === value.resourceId);
                _this.removeAssignment(event);
                _this.dataStore.set(DataKey.assignedSupplierFromVehicle, null, true);
                _this.enableFocusViewProceed();
            }
        });
    };
    AssignmentFocusViewComponent.prototype.updateVehicleAssignment = function (event, isAfterDriver) {
        var _this = this;
        var confirmDataObject = new DialogModel(true, LEVEL.INFO, 'Add to Existing job', 'Do you want to assign vehicle with an existing job?', true, 3000);
        this.dialogService.confirm(confirmDataObject).subscribe(function (res) {
            if (res === true) {
                var assignment_1 = event.assignments.find(function (value) { return value.tobeSelected; });
                var passengerCount = _this.costCalculationUtilsService.getTotalPassengerCount(_this.isBulkAssignment, _this.bookingData, _this.bookingDataArray);
                if (assignment_1 && passengerCount > assignment_1.remainingCount) {
                    _this.common.showSnackBar('Exceeding the capacity', 3000, TcErrorType.TYPE.WARN);
                }
                assignment_1.startTime = assignment_1 &&
                    assignment_1.startTime > _this.vehicleSearchCriteria.serviceStartTime ?
                    _this.vehicleSearchCriteria.serviceStartTime :
                    assignment_1.startTime;
                assignment_1.passengerCount += passengerCount;
                assignment_1.allocatedCount += passengerCount;
                assignment_1.remainingCount -= passengerCount;
                assignment_1.selected = true;
                assignment_1.recommended = true;
                assignment_1.serviceItemIds = [];
                if (_this.isBulkAssignment) {
                    _this.bookingDataArray.forEach(function (value) {
                        assignment_1.serviceItemIds.push(value.serviceItemId);
                    });
                }
                else {
                    assignment_1.serviceItemIds.push(_this.bookingData.serviceItemId);
                }
                assignment_1.jobCost = _this.getJobCost(event, assignment_1.passengerCount);
                event.selected = true;
                var resource = _this.contentCardDataListVehicles.find(function (value) { return event.resourceId === value.resourceId; });
                event.assignments = resource.assignments;
                event.assignments.forEach(function (value) {
                    if (value.assignmentId === assignment_1.assignmentId) {
                        value = assignment_1;
                    }
                });
                _this.selectedVehicle = event;
                if (assignment_1 && assignment_1.childrenAssignments && !isAfterDriver) {
                    var driverAssignment = assignment_1.childrenAssignments.find(function (value) { return value.categoryCode === ResourceType.driver; });
                    if (driverAssignment) {
                        _this.setSelectedDriver(driverAssignment);
                    }
                }
                _this.selectedVehicle.trsSupplier.serviceDate = _this.serviceDate;
                // this.dataStore.set(DataKey.assignedSupplierFromVehicle, this.selectedVehicle.supplier, true);
                _this.selectedSupplier = resource.trsSupplier;
                _this.enableFocusViewProceed();
            }
        });
    };
    AssignmentFocusViewComponent.prototype.onDriverCalendarClick = function (resource) {
        var _this = this;
        var supplier = resource.trsSupplier ? resource.trsSupplier : resource.driverSupplier;
        this.navConfig = this.configLoader.configurations.get(TCO.CONF.CONF_PAGE_SECTION_CONFIG)['availability_calendar_side_nav']['resource_level'];
        setTimeout(function () {
            var fabActions = [];
            /*let navItemsArray = this.configLoader.configurations.get(TCO.CONF.CONF_NAVIGATION);
             this.configLoader.configurations.get(TCO.CONF.CONF_FOCUS_VIEW).AVAILABILITY_CAL_FOCUS_VIEW.fabAction
             .forEach(val => {
             for (let key in navItemsArray) {
             if (val === navItemsArray[key]['id']) {
             fabActions.push(navItemsArray[key]);
             }
             }
             });*/
            var dataObject = new ModalData(LEVEL.SUCCESS, 'Availability Calendar', null, null, 'season-setup-focus-view', ResAvailabilityCalendarFocusViewComponent, {
                'resourceSummary': resource,
                'navConfig': _this.navConfig
            }, { 'label': 'close' }, '', '', fabActions, '', supplier ?
                (resource.resourceName) + ' | Supplier: ' + (supplier.name) :
                '');
            _this.focusViewCloseSubject = _this.dataStore.get(DataKey.availabilityCalendarFocusViewClose).subscribe(function (c) {
                if (c) {
                }
            });
            _this.focusViewService.confirm(dataObject).subscribe(function (res) {
                if (res) {
                    console.log(res);
                }
            });
        }, 0);
    };
    //**************** driver panel*********************
    AssignmentFocusViewComponent.prototype.loadDriverPanel = function () {
        // load driver summary card configs
        this.tAssignedDrivers = this.configLoader.configurations.get(TCO.CONF.CONF_RESERVATION_RESOURCE_ASSIGN_CONFIG).driver;
        this.getDrivers();
    };
    AssignmentFocusViewComponent.prototype.getDrivers = function () {
        var _this = this;
        this.driverResultObsv = this.dataStore.get(DataKey.resourceAssignDriversSearchResults).subscribe(function (result) {
            if (_this.commonHelper.dataValidity(result)) {
                if (result.status.code === TC.ErrorCodes.SUCCESS) {
                    var dataList = ResponseUtil.getDataArray(result);
                    if (dataList) {
                        _this.drivers = dataList;
                        _this.fetchedDriverCount = _this.drivers.length;
                        _this.driversResultCount = ResponseUtil.getTotalCount(result);
                        _this.driverPanelHeading = _this.driversResultCount + ' result' +
                            (_this.driversResultCount > 1 ? 's' : '') +
                            ' found.';
                        if (_this.drivers.length > 0) {
                            _this.contentCardDataListDrivers = _this.drivers;
                            _this.getDriverSummaryCards();
                        }
                    }
                }
            }
            // this.getSuppliersSearchResult();
        });
    };
    AssignmentFocusViewComponent.prototype.getDriverSummaryCards = function () {
        var e_3, _a;
        try {
            for (var _b = tslib_1.__values(this.contentCardDataListDrivers), _c = _b.next(); !_c.done; _c = _b.next()) {
                var driver = _c.value;
                driver = this.processIcon(driver, this.tAssignedDrivers.config.icon, false);
            }
        }
        catch (e_3_1) { e_3 = { error: e_3_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_3) throw e_3.error; }
        }
        this.spinnerService.hide();
    };
    AssignmentFocusViewComponent.prototype.onDriversPaginationMore = function (criteria) {
        criteria.start = this.fetchedDriverCount;
    };
    AssignmentFocusViewComponent.prototype.onDriverSelect = function (event) {
        this.changesAvailableForServiceItems = true;
    };
    AssignmentFocusViewComponent.prototype.assignDriver = function (driver) {
        var _this = this;
        if (this.isBulkAssignment) {
            var serviceItemIds = [];
            this.serviceItemChangesInSelectedGroup.forEach(function (value) {
                if (value.checked) {
                    _this.setDriverToAssignment(value.serviceItemId, driver);
                }
            });
        }
        else {
            this.setDriverToAssignment(this.bookingData.serviceItemId, driver);
        }
        this.unSavedAssignments.push(driver);
        this.changeDetector.notifyChange();
    };
    AssignmentFocusViewComponent.prototype.setDriverToAssignment = function (serviceItemId, driver) {
        this.serviceItemChangesInSelectedGroup.get(serviceItemId).changed = true;
        this.serviceItemChangesInSelectedGroup.get(serviceItemId).driver = driver;
        this.serviceItemChangesInSelectedGroup.get(serviceItemId).driverSupplier = driver.driverSupplier;
        var assignmentMap = new ServiceAssignment();
        var assignments = this.serviceItemChangesInSelectedGroup.get(serviceItemId).serviceAssignments;
        if (assignments && assignments.get(ResourceType.driver) &&
            assignments.get(ResourceType.driver).resourceId !== driver.resourceId) {
            assignmentMap = this.getResourceAssignmentMap(assignments.get(ResourceType.driver), driver, serviceItemId);
        }
        else {
            assignmentMap = this.getResourceAssignmentMap(assignmentMap, driver, serviceItemId);
        }
        assignmentMap.categoryCode = ResourceType.driver;
        if (assignments.get(ResourceType.vehicle)) {
            assignmentMap.parentAssignment = assignments.get(ResourceType.vehicle);
        }
        this.serviceItemChangesInSelectedGroup.get(serviceItemId).serviceAssignments
            .set(ResourceType.driver, assignmentMap);
        this.serviceItemChangesInSelectedGroup.get(serviceItemId).driverCost = this.getServiceItemCost(serviceItemId, assignmentMap);
        this.changesAvailableForServiceItems = true;
    };
    AssignmentFocusViewComponent.prototype.getResourceAssignmentMap = function (assignment, resource, serviceItemId) {
        var supplier = resource.trsSupplier ? resource.trsSupplier : resource.driverSupplier;
        assignment.resourceId = resource.resourceId;
        var selectedAssignment = resource.assignments.find(function (value) { return value.selected; });
        if (selectedAssignment) {
            assignment.startTime = selectedAssignment.startTime;
            assignment.endTime = selectedAssignment.endTime;
            assignment.waitingEndTime = selectedAssignment.waitingEndTime;
            assignment.jobCost = selectedAssignment.jobCost;
            assignment.estimatedCost = selectedAssignment.estimatedPrice ?
                selectedAssignment.estimatedPrice :
                selectedAssignment.jobCost;
            assignment.startLocationGroup = selectedAssignment.startLocationGroup;
            assignment.endLocationGroup = selectedAssignment.endLocationGroup;
            assignment.resourceName = resource.resourceName;
            assignment.serviceItemIdList = selectedAssignment.serviceItemIds;
            assignment.assignmentId = selectedAssignment.assignmentId;
            if (!assignment.assignmentId) {
                assignment.tempAssignmentId = selectedAssignment.tempAssignmentId;
            }
            assignment.updated = selectedAssignment.updated;
        }
        else if (assignment.assignStatus) {
            assignment.assignmentId = this.previousAssignment ? this.previousAssignment.assignmentId : 0;
        }
        return this.getSupplierAssignmentMap(assignment, supplier, serviceItemId);
    };
    AssignmentFocusViewComponent.prototype.getServiceItemCost = function (serviceItemId, assignment) {
        var bookingData;
        if (this.isBulkAssignment) {
            bookingData = this.bookingDataArray.find(function (value) { return value.serviceItemId === serviceItemId; });
            var passengerCount = this.costCalculationUtilsService.getTotalSpecificPassengerCount(false, bookingData, null);
            var adultCost = 0;
            var childCost = 0;
            var infantCost = 0;
            if (assignment.unitPrice) {
                return this.passengerCount && this.passengerCount.total > 0 ? (assignment.jobCost / this.passengerCount.total) * passengerCount.total : assignment.unitPrice;
            }
            else {
                adultCost = assignment.adults > 0 ?
                    (assignment.adultPrice / assignment.adults) * passengerCount.adult :
                    assignment.adultPrice;
                childCost = assignment.children > 0 ?
                    (assignment.childPrice / assignment.children) * passengerCount.children :
                    assignment.adultPrice;
                infantCost = assignment.infants > 0 ?
                    (assignment.infantPrice / assignment.infants) * passengerCount.infants :
                    assignment.adultPrice;
                return adultCost + childCost + infantCost;
            }
        }
        else {
            return this.costCalculationUtilsService.calculateServiceItemJobCost(this.bookingData, assignment);
        }
    };
    AssignmentFocusViewComponent.prototype.onSave = function () {
        this.passengerCount = this.isGenResourceAssignment ? this.costCalculationUtilsService.getTotalSpecificPassengerCountGen(this.genTourItem) : this.costCalculationUtilsService.getTotalSpecificPassengerCount(this.isBulkAssignment, this.bookingData, this.bookingDataArray);
        if (this.selectedVehicle || this.selectedDriver || this.selectedResource) {
            if (this.selectedVehicle) {
                this.assignVehicle(this.selectedVehicle);
            }
            if (this.selectedDriver) {
                this.assignDriver(this.selectedDriver);
            }
            if (this.selectedResource) {
                this.assignResource(this.selectedResource);
            }
        }
        else if (this.selectedSupplier) {
            if (!this.isGenResourceAssignment) {
                this.assignSupplier(this.selectedSupplier);
            }
            else {
                this.assignGenSupplier(this.selectedSupplier);
            }
        }
        if (!this.isGenResourceAssignment) {
            this.dataStore.set(DataKey.resourceAssignedStatus, this.changesAvailableForServiceItems, true);
        }
    };
    AssignmentFocusViewComponent.prototype.assignGenSupplier = function (supplier) {
        this.dataStore.set(DataKey.assignedGenResourceSupp, true, true);
    };
    AssignmentFocusViewComponent.prototype.assignResource = function (resource) {
        this.unSavedAssignments.push(resource);
        this.changeDetector.notifyChange();
        this.dataStore.set(DataKey.assignedResource, resource, true);
    };
    AssignmentFocusViewComponent.prototype.createAssignmentSummary = function (target) {
        var supplier = target.trsSupplier ? target.trsSupplier : target.driverSupplier;
        var assignment = new AssignmentSummary();
        assignment.tempAssignmentId = 'Temp_' + this.identifierService.getNextManuallyResetId();
        assignment.status = AssignmentStatus.PENDING;
        assignment.selected = true;
        assignment.recommended = true;
        assignment.serviceItemIds = [];
        assignment.passengerCount = this.costCalculationUtilsService.getTotalPassengerCount(this.isBulkAssignment, this.bookingData, this.bookingDataArray);
        if (this.isBulkAssignment) {
            assignment.startLocationGroup = this.itemGroup.route.split('-')[0];
            assignment.endLocationGroup = this.itemGroup.route.split('-')[1];
            assignment.startTime = this.itemGroup.startTime;
            assignment.endTime = moment(this.bookingDataArray[0]['rawData'].trsServiceItem.arrivalDateTime)
                .format('HH:mm');
            assignment.startTimeObj = this.itemGroup.startTimeObj;
            assignment.endTimeObj = this.bookingDataArray[0]['rawData'].trsServiceItem.arrivalDateTime;
            assignment.waitingEndTime = this.itemGroup.endTime;
            assignment.assignDate = this.bookingDataArray[0]['rawData'].serviceDate;
            this.bookingDataArray.forEach(function (value) {
                assignment.serviceItemIds.push(value.serviceItemId);
            });
        }
        else {
            assignment.startLocationGroup = this.bookingData.trsServiceItem.route.split('-')[0];
            assignment.endLocationGroup = this.bookingData.trsServiceItem.route.split('-')[1];
            if (this.bookingData.trsServiceItem.arrivalDateTime > this.bookingData.trsServiceItem.departureDateTime) {
                assignment.startTime = moment(this.bookingData.trsServiceItem.departureDateTime).format('HH:mm');
                assignment.endTime = moment(this.bookingData.trsServiceItem.arrivalDateTime).format('HH:mm');
                assignment.startTimeObj = new Date(this.bookingData.trsServiceItem.departureDateTime);
                assignment.endTimeObj = new Date(this.bookingData.trsServiceItem.arrivalDateTime);
                assignment.waitingEndTime = moment(this.bookingData.trsServiceItem.departureDateTime).format('HH:mm');
            }
            else {
                assignment.startTime = moment(this.bookingData.trsServiceItem.arrivalDateTime).format('HH:mm');
                assignment.endTime = moment(this.bookingData.trsServiceItem.departureDateTime).format('HH:mm');
                assignment.startTimeObj = new Date(this.bookingData.trsServiceItem.arrivalDateTime);
                assignment.endTimeObj = new Date(this.bookingData.trsServiceItem.departureDateTime);
                assignment.waitingEndTime = moment(this.bookingData.trsServiceItem.arrivalDateTime).format('HH:mm');
            }
            assignment.serviceItemIds.push(this.bookingData.serviceItemId);
            assignment.assignDate = this.bookingData.serviceDate;
        }
        assignment.status = AssignmentStatus.PENDING;
        assignment.currency = supplier.currency;
        assignment.allocatedCount = assignment.passengerCount;
        assignment.remainingCount = target.capacity - assignment.passengerCount;
        assignment.jobCost = supplier.totalCost;
        assignment.selected = true;
        return assignment;
    };
    /*private setTempIdsToSeasons() {
     let initId = 1;
     for (let i = 0; i < this.resourceCostData.length; i++) {
     let season: Season = this.resourceCostData[i];
     season.tempSeasonId = initId++;
     }
     }*/
    // updateVehicleSearchResults() {
    //     if (this.selectedVehicle) {
    //         this.contentCardDataListVehicles.forEach(da => {
    //             if (da.resourceId == this.selectedVehicle.resourceId) {
    //                 da = this.selectedVehicle;
    //             }
    //         });
    //     }
    //
    // }
    AssignmentFocusViewComponent.prototype.onVehicleTargetClick = function (target) {
        var _this = this;
        var confirmDataObject = new DialogModel(true, LEVEL.INFO, 'Create New Job', 'Do you want to create new job for this vehicle?', true, 3000);
        this.dialogService.confirm(confirmDataObject).subscribe(function (res) {
            if (res === true) {
                var assignment = _this.createAssignmentSummary(target);
                if (assignment.passengerCount > assignment.remainingCount) {
                    _this.common.showSnackBar('Exceeding the capacity', 3000, TcErrorType.TYPE.WARN);
                }
                assignment.resourceType = ResourceType.vehicle;
                target.assignments = target.assignments ? target.assignments : [];
                target.assignments.push(assignment);
                target.selected = true;
                _this.selectedVehicle = target;
                _this.selectedSupplier = target.trsSupplier;
                if (assignment.childrenAssignments) {
                    var driver = assignment.childrenAssignments.find(function (value) { return value.categoryCode === ResourceType.driver; });
                    if (driver) {
                        _this.setSelectedDriver(driver);
                    }
                }
                _this.enableFocusViewProceed();
            }
        });
    };
    AssignmentFocusViewComponent.prototype.ngOnDestroy = function () {
        if (this.supplierResultObsv) {
            this.supplierResultObsv.unsubscribe();
        }
        if (this.supplierMoreResultObsv) {
            this.supplierMoreResultObsv.unsubscribe();
        }
        if (this.vehicleResultObsv) {
            this.vehicleResultObsv.unsubscribe();
        }
        if (this.vehicleMoreResultObsv) {
            this.vehicleMoreResultObsv.unsubscribe();
        }
        if (this.driverResultObsv) {
            this.driverResultObsv.unsubscribe();
        }
        if (this.driverMoreResultObsv) {
            this.driverMoreResultObsv.unsubscribe();
        }
        if (this.transferModesObsv) {
            this.transferModesObsv.unsubscribe();
        }
    };
    AssignmentFocusViewComponent.prototype.onDriverEventClick = function (event) {
        if (event.selected) {
            this.deleteDriverAssignment(event);
        }
        else {
            this.updateDriverAssignment(event, false);
        }
    };
    AssignmentFocusViewComponent.prototype.onDriverTargetClick = function (target) {
        var _this = this;
        var confirmDataObject = new DialogModel(true, LEVEL.INFO, 'Create New Job', 'Do you want to create new job for this resource?', true, 3000);
        this.dialogService.confirm(confirmDataObject).subscribe(function (res) {
            if (res === true) {
                var assignment = _this.createAssignmentSummary(target);
                assignment.resourceType = ResourceType.driver;
                target.assignments = target.assignments ? target.assignments : [];
                target.assignments.push(assignment);
                target.selected = true;
                _this.selectedDriver = target;
                _this.selectedSupplier = target.driverSupplier;
                if (assignment.parentAssignment) {
                    _this.setSelectedVehicle(assignment.parentAssignment);
                }
                _this.enableFocusViewProceed();
            }
        });
    };
    AssignmentFocusViewComponent.prototype.deleteDriverAssignment = function (event) {
        var _this = this;
        var confirmDataObject = new DialogModel(true, LEVEL.ERROR, 'Delete Job', 'Do you want to remove job?', true, 3000);
        this.dialogService.confirm(confirmDataObject).subscribe(function (res) {
            if (res === true) {
                _this.removeAssignment(event);
                _this.dataStore.set(DataKey.assignedSupplierFromDriver, null, true);
                _this.selectedSupplier = null;
                _this.enableFocusViewProceed();
            }
        });
        // if (event && event.assignments){
        //     let selectedEvent = event.assignments.find(value => value.selected);
        //     if(selectedEvent.parentAssignment){
        //         this.serviceItemChangesInSelectedGroup.get(this.serviceItemId)
        //     }
        // }
    };
    AssignmentFocusViewComponent.prototype.updateDriverAssignment = function (event, isAfterVehicle) {
        var _this = this;
        var confirmDataObject = new DialogModel(true, LEVEL.INFO, 'Add to Existing Job', 'Do you want to assign driver with an existing job?', true, 3000);
        this.dialogService.confirm(confirmDataObject).subscribe(function (res) {
            if (res === true) {
                var assignment_2 = event.assignments.find(function (value) { return value.tobeSelected; });
                var passengerCount = _this.costCalculationUtilsService.getTotalPassengerCount(_this.isBulkAssignment, _this.bookingData, _this.bookingDataArray);
                if (assignment_2 && (assignment_2.startTime > _this.vehicleSearchCriteria.serviceStartTime)) {
                    assignment_2.startTime = _this.vehicleSearchCriteria.serviceStartTime;
                }
                assignment_2.passengerCount += passengerCount;
                assignment_2.allocatedCount += passengerCount;
                if (event.capacity > 0) {
                    assignment_2.remainingCount -= passengerCount;
                }
                else {
                    assignment_2.remainingCount = 0;
                }
                assignment_2.selected = true;
                assignment_2.recommended = true;
                assignment_2.serviceItemIds = [];
                if (_this.isBulkAssignment) {
                    _this.bookingDataArray.forEach(function (value) {
                        assignment_2.serviceItemIds.push(value.serviceItemId);
                    });
                }
                else {
                    assignment_2.serviceItemIds.push(_this.bookingData.serviceItemId);
                }
                assignment_2.jobCost = _this.getJobCost(event, assignment_2.passengerCount);
                event.selected = true;
                var resource = _this.contentCardDataListDrivers.find(function (value) { return event.resourceId === value.resourceId; });
                event.assignments = resource.assignments;
                event.assignments.forEach(function (value) {
                    if (value.assignmentId === assignment_2.assignmentId) {
                        value = assignment_2;
                    }
                });
                _this.selectedDriver = event;
                if (assignment_2.parentAssignment && !isAfterVehicle) {
                    _this.setSelectedVehicle(assignment_2.parentAssignment);
                }
                _this.selectedDriver.driverSupplier.serviceDate = _this.serviceDate;
                // this.dataStore.set(DataKey.assignedSupplierFromDriver, this.selectedDriver.supplier, true);
                _this.selectedSupplier = event.driverSupplier;
                _this.enableFocusViewProceed();
            }
        });
    };
    AssignmentFocusViewComponent.prototype.getJobCost = function (event, paxCount) {
        var supplier = event.trsSupplier ? event.trsSupplier : event.driverSupplier;
        if (supplier.unitFare && event.capacity > 0) {
            return paxCount * (supplier.unitFare / event.capacity);
        }
        else if (supplier.unitFare) {
            return supplier.unitFare;
        }
        else {
            return paxCount * supplier.adultFare;
        }
    };
    AssignmentFocusViewComponent.prototype.removeAssignment = function (event) {
        if (event.assignments) {
            var selectedAssignment_1 = event.assignments.find(function (value) { return value.selected; });
            var passengerCount = this.costCalculationUtilsService.getTotalPassengerCount(this.isBulkAssignment, this.bookingData, this.bookingDataArray);
            selectedAssignment_1.passengerCount -= passengerCount;
            selectedAssignment_1.allocatedCount -= passengerCount;
            selectedAssignment_1.remainingCount += passengerCount;
            if (selectedAssignment_1.allocatedCount > 0) {
                selectedAssignment_1.updated = false;
                selectedAssignment_1.selected = false;
                selectedAssignment_1.startTime = selectedAssignment_1.startTime >
                    this.vehicleSearchCriteria.serviceStartTime ?
                    this.vehicleSearchCriteria.serviceStartTime :
                    selectedAssignment_1.startTime;
                // selectedAssignment.endTime = assignment.endTime > this.vehicleSearchCriteria. ? this.vehicleSearchCriteria.serviceStartTime : assignment.startTime;
                if (this.isBulkAssignment) {
                    this.bookingDataArray.forEach(function (value) {
                        selectedAssignment_1.serviceItemIds.splice(value.serviceItemId, 1);
                    });
                }
                else {
                    selectedAssignment_1.serviceItemIds.splice(this.bookingData.serviceItemId, 1);
                }
                selectedAssignment_1.jobCost = this.getJobCost(event, selectedAssignment_1.passengerCount);
                // assignment.adultCost =
                // assignment.childCost =
                // assignment.infantCost =
                // assignment.unitCost =
                event.selected = false;
                if (selectedAssignment_1.allocatedCount > 0) {
                    event.assignments.forEach(function (value) {
                        if (value.assignmentId === selectedAssignment_1.assignmentId ||
                            value.tempAssignmentId === selectedAssignment_1.tempAssignmentId) {
                            value = selectedAssignment_1;
                        }
                    });
                }
                else {
                    event.assignments = event.assignments.filter(function (value) { return !value.selected; });
                    event.selected = false;
                    selectedAssignment_1.recommended = false;
                }
            }
            else {
                event.selected = false;
                event.assignments = event.assignments.filter(function (value) { return !value.selected; });
                selectedAssignment_1 = null;
            }
        }
    };
    AssignmentFocusViewComponent.prototype.setSelectedVehicle = function (parentAssignment) {
        if (this.vehicles && parentAssignment) {
            var vehicle = this.vehicles.find(function (value) { return value.resourceId === parentAssignment.resourceId; });
            vehicle.assignments.forEach(function (value) {
                if (value.assignmentId === parentAssignment.assignmentId) {
                    value.selected = true;
                }
            });
            this.updateVehicleAssignment(vehicle, true);
        }
    };
    AssignmentFocusViewComponent.prototype.setSelectedDriver = function (driverAssignment) {
        if (this.drivers && driverAssignment) {
            var driver = this.drivers.find(function (value) { return value.resourceId === driverAssignment.resourceId; });
            driver.assignments.forEach(function (value) {
                if (value.assignmentId === driverAssignment.assignmentId) {
                    value.selected = true;
                }
            });
            this.updateDriverAssignment(driver, true);
        }
    };
    //**************** resource panel*********************
    AssignmentFocusViewComponent.prototype.loadResourcePanel = function () {
        // load resource summary card configs
        this.tAssignedResources = this.configLoader.configurations.get(TCO.CONF.CONF_RESERVATION_RESOURCE_ASSIGN_CONFIG).resource;
        this.dataStore.set(DataKey.assignedResource, null, true);
        this.getResources();
    };
    AssignmentFocusViewComponent.prototype.getResources = function () {
        var _this = this;
        // let genericSearchCriteria = new GenericSearchCriteria();
        // genericSearchCriteria.activeStatus = 'true';
        // genericSearchCriteria.resourceType = this.genTourItem.categoryCode;
        // genericSearchCriteria.serviceDate = this.genTourItem.serviceItems[0].serviceDate;
        // genericSearchCriteria.start = 0;
        // genericSearchCriteria.size = 10;
        this.resourceResultObsv = this.dataStore.get(DataKey.resourceAssignResourcesSearchResults).subscribe(function (result) {
            if (_this.commonHelper.dataValidity(result)) {
                if (result.status.code === TC.ErrorCodes.SUCCESS) {
                    var dataList = ResponseUtil.getDataArray(result);
                    if (dataList) {
                        _this.resources = dataList;
                        _this.fetchedResourceCount = _this.resources.length;
                        _this.resourceResultCount = ResponseUtil.getTotalCount(result);
                        _this.resourcePanelHeading = _this.resourceResultCount + ' result' +
                            (_this.resourceResultCount > 1 ? 's' : '') +
                            ' found.';
                        if (_this.resources.length > 0) {
                            _this.contentCardDataListResources = _this.resources;
                            _this.getResourceSummaryCards();
                        }
                    }
                }
            }
        });
    };
    AssignmentFocusViewComponent.prototype.getResourceSummaryCards = function () {
        var e_4, _a;
        try {
            for (var _b = tslib_1.__values(this.contentCardDataListResources), _c = _b.next(); !_c.done; _c = _b.next()) {
                var resource = _c.value;
                resource = this.processIcon(resource, this.tAssignedResources.config.icon, false);
            }
        }
        catch (e_4_1) { e_4 = { error: e_4_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_4) throw e_4.error; }
        }
        this.spinnerService.hide();
    };
    AssignmentFocusViewComponent.prototype.onResourcesPaginationMore = function (criteria) {
        criteria.start = this.fetchedResourceCount;
    };
    AssignmentFocusViewComponent.prototype.onResourceSelect = function (event) {
        this.changesAvailableForServiceItems = true;
    };
    AssignmentFocusViewComponent.prototype.onResourceCalendarClick = function (resource) {
        var _this = this;
        var supplier = resource.trsSupplier ? resource.trsSupplier : resource.driverSupplier;
        this.navConfig = this.configLoader.configurations.get(TCO.CONF.CONF_PAGE_SECTION_CONFIG)['availability_calendar_side_nav']['resource_level'];
        setTimeout(function () {
            var fabActions = [];
            /*let navItemsArray = this.configLoader.configurations.get(TCO.CONF.CONF_NAVIGATION);
             this.configLoader.configurations.get(TCO.CONF.CONF_FOCUS_VIEW).AVAILABILITY_CAL_FOCUS_VIEW.fabAction
             .forEach(val => {
             for (let key in navItemsArray) {
             if (val === navItemsArray[key]['id']) {
             fabActions.push(navItemsArray[key]);
             }
             }
             });*/
            var dataObject = new ModalData(LEVEL.SUCCESS, 'Availability Calendar', null, null, 'season-setup-focus-view', ResAvailabilityCalendarFocusViewComponent, {
                'resourceSummary': resource,
                'navConfig': _this.navConfig
            }, { 'label': 'close' }, '', '', fabActions, '', supplier ?
                (resource.resourceName) + ' | Supplier: ' + (supplier.name) :
                '');
            _this.focusViewCloseSubject = _this.dataStore.get(DataKey.availabilityCalendarFocusViewClose).subscribe(function (c) {
                if (c) {
                }
            });
            _this.focusViewService.confirm(dataObject).subscribe(function (res) {
                if (res) {
                    console.log(res);
                }
            });
        }, 0);
    };
    AssignmentFocusViewComponent.prototype.onResourceEventClick = function (event) {
        if (event.selected) {
            this.deleteResouceAssignment(event);
        }
        else {
            this.updateResouceAssignment(event, false);
        }
    };
    AssignmentFocusViewComponent.prototype.deleteResouceAssignment = function (event) {
        var _this = this;
        var confirmDataObject = new DialogModel(true, LEVEL.ERROR, 'Delete Job', 'Do you want to remove job?', true, 3000);
        this.dialogService.confirm(confirmDataObject).subscribe(function (res) {
            if (res === true) {
                _this.removeAssignment(event);
                _this.dataStore.set(DataKey.assignedSupplierFromDriver, null, true);
                _this.selectedSupplier = null;
                _this.enableFocusViewProceed();
            }
        });
    };
    AssignmentFocusViewComponent.prototype.updateResouceAssignment = function (event, isAfterVehicle) {
        var _this = this;
        var confirmDataObject = new DialogModel(true, LEVEL.INFO, 'Add to Existing Job', 'Do you want to assign driver with an existing job?', true, 3000);
        this.dialogService.confirm(confirmDataObject).subscribe(function (res) {
            if (res === true) {
                var assignment_3 = event.assignments.find(function (value) { return value.tobeSelected; });
                var passengerCount = _this.costCalculationUtilsService.getTotalPassengerCount(_this.isBulkAssignment, _this.bookingData, _this.bookingDataArray);
                if (assignment_3 && (assignment_3.startTime > _this.vehicleSearchCriteria.serviceStartTime)) {
                    assignment_3.startTime = _this.vehicleSearchCriteria.serviceStartTime;
                }
                assignment_3.passengerCount += passengerCount;
                assignment_3.allocatedCount += passengerCount;
                if (event.capacity > 0) {
                    assignment_3.remainingCount -= passengerCount;
                }
                else {
                    assignment_3.remainingCount = 0;
                }
                assignment_3.selected = true;
                assignment_3.recommended = true;
                assignment_3.serviceItemIds = [];
                if (_this.isBulkAssignment) {
                    _this.bookingDataArray.forEach(function (value) {
                        assignment_3.serviceItemIds.push(value.serviceItemId);
                    });
                }
                else {
                    assignment_3.serviceItemIds.push(_this.bookingData.serviceItemId);
                }
                assignment_3.jobCost = _this.getJobCost(event, assignment_3.passengerCount);
                event.selected = true;
                var resource = _this.contentCardDataListDrivers.find(function (value) { return event.resourceId === value.resourceId; });
                event.assignments = resource.assignments;
                event.assignments.forEach(function (value) {
                    if (value.assignmentId === assignment_3.assignmentId) {
                        value = assignment_3;
                    }
                });
                _this.selectedDriver = event;
                if (assignment_3.parentAssignment && !isAfterVehicle) {
                    _this.setSelectedVehicle(assignment_3.parentAssignment);
                }
                _this.selectedDriver.driverSupplier.serviceDate = _this.serviceDate;
                // this.dataStore.set(DataKey.assignedSupplierFromDriver, this.selectedDriver.supplier, true);
                _this.selectedSupplier = event.driverSupplier;
                _this.enableFocusViewProceed();
            }
        });
    };
    AssignmentFocusViewComponent.prototype.onResourceTargetClick = function (target) {
        var _this = this;
        var confirmDataObject = new DialogModel(true, LEVEL.INFO, 'Create New Job', 'Do you want to create new job for this resource?', true, 3000);
        this.dialogService.confirm(confirmDataObject).subscribe(function (res) {
            if (res === true) {
                var assignment = _this.createResourceAssignmentSummary(target);
                console.log(target);
                // assignment.resourceType = ResourceType.driver;
                target.assignments = target.assignments ? target.assignments : [];
                target.assignments.push(assignment);
                target.selected = true;
                _this.selectedResource = target;
                _this.selectedSupplier = target.driverSupplier;
                _this.enableFocusViewProceed();
            }
        });
    };
    AssignmentFocusViewComponent.prototype.createResourceAssignmentSummary = function (target) {
        var supplier = target.trsSupplier ? target.trsSupplier : target.driverSupplier;
        var assignment = new AssignmentSummary();
        assignment.tempAssignmentId = 'Temp_' + this.identifierService.getNextManuallyResetId();
        assignment.status = AssignmentStatus.PENDING;
        assignment.selected = true;
        assignment.recommended = true;
        assignment.serviceItemIds = [];
        assignment.passengerCount = this.costCalculationUtilsService.getTotalPassengerCountGen(this.genTourItem);
        var startDateTime = new Date();
        var endDateTime = new Date();
        if (this.genTourItem.jobPeriod) {
            var timesArr = this.genTourItem.jobPeriod.split('-');
            var startTimeArr = timesArr[0].split(':');
            var endTimeArr = timesArr[1].split(':');
            startDateTime.setHours(+startTimeArr[0], +startTimeArr[1]);
            endDateTime.setHours(+endTimeArr[0], +endTimeArr[1]);
            startDateTime.setFullYear(moment(this.genTourItem.serviceItems[0].serviceDate).year(), moment(this.genTourItem.serviceItems[0].serviceDate).month(), moment(this.genTourItem.serviceItems[0].serviceDate).date());
            endDateTime.setFullYear(moment(this.genTourItem.serviceItems[0].serviceDate).year(), moment(this.genTourItem.serviceItems[0].serviceDate).month(), moment(this.genTourItem.serviceItems[0].serviceDate).date());
        }
        assignment.startTime = moment(startDateTime).format('HH:mm');
        assignment.endTime = moment(endDateTime).format('HH:mm');
        assignment.startTimeObj = new Date(startDateTime);
        assignment.endTimeObj = new Date(endDateTime);
        assignment.waitingEndTime = moment(startDateTime).format('HH:mm');
        this.genTourItem.serviceItems.forEach(function (serviceItem) {
            assignment.serviceItemIds.push(serviceItem.serviceItemId);
        });
        assignment.assignDate = this.genTourItem.serviceItems[0].serviceDate;
        assignment.status = AssignmentStatus.PENDING;
        assignment.currency = target.resourceSupplier.currency;
        assignment.allocatedCount = assignment.passengerCount;
        assignment.remainingCount = target.capacity - assignment.passengerCount;
        assignment.jobCost = supplier.totalCost;
        assignment.selected = true;
        return assignment;
    };
    return AssignmentFocusViewComponent;
}());
export { AssignmentFocusViewComponent };
