/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "./file-uploader.component";
import * as i3 from "../../../services/util/framework/data-store.service";
var styles_FileUploaderComponent = [];
var RenderType_FileUploaderComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_FileUploaderComponent, data: {} });
export { RenderType_FileUploaderComponent as RenderType_FileUploaderComponent };
export function View_FileUploaderComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "input", [["id", "file"], ["type", "file"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.handleFileInput((($event == null) ? null : (($event.target == null) ? null : $event.target.files))) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 6, "div", [["class", "rs-footer-section"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 2, "button", [["class", "tc-button-secondary mat-button rs-footer-section__button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closePopUp() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(4, null, [" ", " "])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(6, 0, null, null, 2, "button", [["class", "tc-button-primary mat-button rs-footer-section__button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.uploadFile() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(7, null, [" ", "\n"])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = false; _ck(_v, 3, 0, currVal_0); var currVal_1 = i0.ɵunv(_v, 4, 0, i0.ɵnov(_v, 5).transform("Cancel")); _ck(_v, 4, 0, currVal_1); var currVal_2 = !_co.fileToUpload; _ck(_v, 6, 0, currVal_2); var currVal_3 = i0.ɵunv(_v, 7, 0, i0.ɵnov(_v, 8).transform("Upload")); _ck(_v, 7, 0, currVal_3); }); }
export function View_FileUploaderComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-file-uploader", [], null, null, null, View_FileUploaderComponent_0, RenderType_FileUploaderComponent)), i0.ɵdid(1, 114688, null, 0, i2.FileUploaderComponent, [i3.DataStoreService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FileUploaderComponentNgFactory = i0.ɵccf("tc-file-uploader", i2.FileUploaderComponent, View_FileUploaderComponent_Host_0, {}, {}, []);
export { FileUploaderComponentNgFactory as FileUploaderComponentNgFactory };
