import * as tslib_1 from "tslib";
import { AssignmentRawData } from './assignment-raw-data';
var ExistingAssignment = /** @class */ (function (_super) {
    tslib_1.__extends(ExistingAssignment, _super);
    function ExistingAssignment() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return ExistingAssignment;
}(AssignmentRawData));
export { ExistingAssignment };
