import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChipInputModule } from '@tc/chip-input';
import {RoomSearchCriteriaComponent} from './room-search-criteria.component';

@NgModule({
    imports: [
        CommonModule,
        ChipInputModule
    ],
    declarations: [RoomSearchCriteriaComponent],
    exports: [RoomSearchCriteriaComponent],
    entryComponents: [RoomSearchCriteriaComponent]
})
export class RoomSearchCriteriaModule {}
