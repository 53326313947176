import { ConfigLoader } from '@tc-core/util/framework';
import { DMCQueryParamsService } from '../../util/framework/dmc-query-params.service';
import { RequestService } from '../../util/framework/request.service';
import { UrlPaths } from '../url-paths';
import * as i0 from "@angular/core";
import * as i1 from "@tc-core/util/framework/config-loader.service";
import * as i2 from "../../util/framework/request.service";
import * as i3 from "../../util/framework/dmc-query-params.service";
var CostingTypeService = /** @class */ (function () {
    function CostingTypeService(configLoader, requestService, queryParamsService) {
        this.configLoader = configLoader;
        this.requestService = requestService;
        this.queryParamsService = queryParamsService;
    }
    CostingTypeService.prototype.getRows = function (criteria) {
        var reqPrams = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.get(UrlPaths.OP_DATA_URL_KEY, [UrlPaths.OP_DATA_VERSION, UrlPaths.COSTING_TYPES], reqPrams);
    };
    CostingTypeService.prototype.deleteRow = function (code) {
        return this.requestService.delete(UrlPaths.OP_DATA_URL_KEY, [UrlPaths.OP_DATA_VERSION, UrlPaths.COSTING_TYPES, code]);
    };
    CostingTypeService.prototype.saveRow = function (row) {
        return this.requestService.post(UrlPaths.OP_DATA_URL_KEY, [UrlPaths.OP_DATA_VERSION, UrlPaths.COSTING_TYPES], row);
    };
    CostingTypeService.prototype.updateRow = function (code, row) {
        return this.requestService.put(UrlPaths.OP_DATA_URL_KEY, [UrlPaths.OP_DATA_VERSION, UrlPaths.COSTING_TYPES, code], row);
    };
    CostingTypeService.ngInjectableDef = i0.defineInjectable({ factory: function CostingTypeService_Factory() { return new CostingTypeService(i0.inject(i1.ConfigLoader), i0.inject(i2.RequestService), i0.inject(i3.DMCQueryParamsService)); }, token: CostingTypeService, providedIn: "root" });
    return CostingTypeService;
}());
export { CostingTypeService };
