<div class="wrapper">
  <div class="sidebar">
    <router-outlet name="p1"></router-outlet>
  </div>
  <div class="main-panel" [ngClass]="headerClass">

    <div class="main-panel--header">
      <div class="main-content">
        <div class="container-fluid">
          <div class="scaff-container menu--bar">
            <router-outlet name="p2"></router-outlet>
          </div>
          <div class="scaff-container">
            <router-outlet name="p10"></router-outlet>
          </div>
        </div>
      </div>
    </div>

    <div>
      <router-outlet name="p3"></router-outlet>
    </div>

    <div class="main-content">
      <div class="container-fluid">
        <div class="scaff-container">
          <div class="dashboard-components fix-container-space">
            <router-outlet name="p4"></router-outlet>
            <router-outlet name="p5"></router-outlet>
            <router-outlet name="p6"></router-outlet>
            <router-outlet name="p7"></router-outlet>
            <router-outlet name="p8"></router-outlet>
            <router-outlet name="p9"></router-outlet>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

