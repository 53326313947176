import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { TcApiError } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-api-error';
import { TcErrorType } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-error';
import { TcHttpError } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-http-error';
import { TC } from '@tc-core/util';
import { ConfigLoader, EventManager, FocusViewManager, UserJourneyManager } from '@tc-core/util/framework';
import { CommonHelper } from '@tc-core/util/helpers';
import { SpinnerService } from '@tc-core/util/ui';
import { FocusViewService } from '@tc/focus-view/focus-view.service';
import { IGetRowsParams } from 'ag-grid-community';
import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SetupGridComp } from '../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid-comp';
import { SetupGridDataSource } from '../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid-data-source';
import { TCO } from '../../constants';
import { LocationsSearchCriteria } from '../../models/criteria/locations-search-criteria';
import { SortDirection } from '../../models/helper/sort-direction';
import { SearchResultSummary } from '../../models/reservation/search-result-summary';
import { LocationsService } from '../../services/backend-consumers/setups/locations.service';
import { DMCCommon } from '../../services/util/common/dmc-common';
import { DataKey, DataStoreService } from '../../services/util/framework/data-store.service';
import { ResponseUtil } from '../../services/util/response/response-util.service';
import EMPTY_CHAR = TC.FORMAT.EMPTY_CHAR;

@Component({
    selector: 'tc-location-selection-focus-view',
    templateUrl: './location-selection-focus-view.component.html'
})
export class LocationSelectionFocusViewComponent extends SetupGridComp implements OnInit {

    @ViewChild('setupGrid')
    pageSize = 20;
    searchPage = 0;
    sortBy = 'id';
    sortDirection = 'ASC';

    leftSideData = [];
    @Input() rightSideData = [];
    @Input() locationGroup: any;

    colDefConfigLeft = [];
    colDefConfigRight = [];

    identityFunction;
    public colDefConfig = [];

    private locationsSearchCriteria: LocationsSearchCriteria = new LocationsSearchCriteria();
    private searchCriteriaObserver: Subscription = new Subscription();
    private saveLocationsObsv: Subscription = new Subscription();

    constructor(
        private locationsService: LocationsService,
        private dataStoreService: DataStoreService,
        private configLoader: ConfigLoader,
        private journeyManager: UserJourneyManager,
        private focusViewManager: FocusViewManager,
        private commonHelper: CommonHelper,
        private spinnerService: SpinnerService,
        private common: DMCCommon,
        private focusViewService: FocusViewService,
        private em: EventManager
    ) {
        super();
    }

    public ngOnInit(): void {
        this.identityFunction = (row) => {
            return row.code;
        };
        this.subscribeSearchCriteria();
        this.getLeftSideRows();
        this.journeyManager.canProceed.next(true);
        this.focusViewManager.canProceed.next(true);
        this.focusViewManager.canProceedState.next(true);
        this.colDefConfig = this.configLoader.configurations.get(TCO.CONF.CONF_LOCATIONS_SELECTION_GRID);
        this.colDefConfigLeft = this.colDefConfig;
        this.colDefConfigRight = this.colDefConfig;
        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'CLOSE_FOCUS_VIEW').subscribe(e => {
            this.onSaveLocations();
        });
    }

    onSaveLocations() {
        this.dataStoreService.set(DataKey.saveLocationGroups, null, true);
        this.rightSideData.forEach(value => {
            value.locationGroup = this.locationGroup.code;
        });
        // this.locationGroup.locations = this.rightSideData;
        this.locationsService.saveLocationGroupWithDataKey(this.locationGroup.code, this.rightSideData);
        this.dataStoreService.set(DataKey.locationSelectionFocusViewClose, null);
        this.dataStoreService.set(DataKey.locationSelectionFocusViewClose, true);
        this.focusViewService.close(true);
        this.saveLocationsObserver();
    }

    /**
     * subscribe for data save response
     */
    saveLocationsObserver() {
        this.saveLocationsObsv = this.dataStoreService.get(DataKey.saveLocationGroups, true).subscribe(value => {
            if (value) {
                // this.saveLocationsObsv.unsubscribe();
                // this.dataStoreService.set(DataKey.saveLocationGroups, null, true);
                this.spinnerService.hide();
                if (ResponseUtil.isSuccess(value)) {
                    this.common.showSnackBar('Successfully saved locations.', 3000, TcErrorType.TYPE.INFO);
                    this.dataStoreService.set(DataKey.locationSelectionFocusViewClose, null);
                    this.dataStoreService.set(DataKey.locationSelectionFocusViewClose, true);
                    this.focusViewService.close(true);
                } else if (ResponseUtil.isError(value)) {
                    this.common.showSnackBar('Failed to save the locations', 3000, TcErrorType.TYPE.ERROR);
                }
            } else if (value instanceof TcApiError) {
                this.spinnerService.hide();
            } else if (value instanceof TcHttpError) {
                this.spinnerService.hide();
            }
        }, error => {
        });
    }

    getLeftSideRows() {
        this.locationsSearchCriteria = this.locationsSearchCriteria ? this.locationsSearchCriteria : new LocationsSearchCriteria();
        this.locationsSearchCriteria.size = 100000;
        this.locationsService.getLocations(this.locationsSearchCriteria).subscribe(data => {
            if (data) {
                this.leftSideData = ResponseUtil.getDataArray<any>(data);
                this.dataStoreService.set(DataKey.locationsSearchResultsForCriteria, this.leftSideData, true);
            }
        });
    }

    saveRow(row: any): Observable<any> {
        throw new Error('Method not implemented.');
    }

    deleteRow(row: any): Observable<any> {
        throw new Error('Method not implemented.');
    }

    isInvalidRow(params: any): boolean {
        throw new Error('Method not implemented.');
    }

    isUnsavedRow(row: any): boolean {
        throw new Error('Method not implemented.');
    }

    createNewRow() {
        throw new Error('Method not implemented.');
    }

    /**
     * subscribe search criteria changes in search bar
     * then force grid to refresh data
     * grid refresh will be call getRaws method which is using this subscribed criteria for backend call
     */
    subscribeSearchCriteria() {
        this.searchCriteriaObserver = this.dataStoreService.get(DataKey.locationsSearchCriteria)
                                          .subscribe(value => {
                                              this.locationsSearchCriteria = value;
                                              this.getLeftSideRows();
                                          });
    }

    /*onSearch() {
     return this.dataStoreService.get(DataKey.locationsSearchResultsForCriteria)
     .subscribe(
     data => {
     this.leftSideData = this.getDataFromResponse(data);
     });
     }*/

    public getDataFromResponse<T>(response: any): T[] {
        let data = [];
        if (response) {
            data = response['content'];
        }
        return data;
    }

    onLogRightSideData() {
        if (this.rightSideData) {
            console.table(this.rightSideData);
        }
    }

    /*
     grid config methods which are called by grid setup (ag grid)
     and set by grid config
     */

    getLocationTypeText = (params) => {
        let typeText = '';
        if (params && params.data) {
            if (params.data.locationTypeName) {
                typeText = params.data.locationTypeName;
            } else if (params.data.locationType) {
                typeText = params.data.locationType;
            } else {
                typeText = EMPTY_CHAR;
            }
        }
        return typeText;
    };

    getLocationGroupText = (params) => {
        let groupText = EMPTY_CHAR;
        if (params && params.data && params.data.locationGroup) {
            if (params.data.locationGroup.code) {
                groupText = params.data.locationGroup.code;
            } else {
                groupText = EMPTY_CHAR;
            }
            groupText += ' | ';
            if (params.data.locationGroup.name) {
                groupText += params.data.locationGroup.name;
            } else {
                groupText += EMPTY_CHAR;
            }
        }
        return groupText;
    };

    getLocationTypes = (params, rowData, text) => {
        return this.dataStoreService.get(DataKey.locationTypes)
                   .map(
                       aEvents =>
                           aEvents.filter(aEvent => {
                                   if (text) {
                                       if (aEvent.name) {
                                           return aEvent.name.toLowerCase().indexOf(text.toLocaleString()) > -1;
                                       }
                                   } else {
                                       return true;
                                   }
                               }
                           )
                   );
    };

    getLocationGroups = (params, rowData, text) => {
        return this.dataStoreService.get(DataKey.locationGroups)
                   .map(
                       aEvents =>
                           aEvents.filter(aEvent => {
                                   if (text) {
                                       if (aEvent.name) {
                                           return aEvent.name.toLowerCase().indexOf(text.toLocaleString()) > -1;
                                       }
                                   } else {
                                       return true;
                                   }
                               }
                           )
                   );
    };

}
