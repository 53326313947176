<form>

  <div class="container-fluid direct-client-contact-details-edit tc-card-wrapper">
    <div class="row">
      <div class="col-md-12 col-lg-12">
        <div class="tc-flex-box">
          <div class="tc-flex-item tc-item-circle">
            <div class="icon-box">
              <i class="material-icons" aria-hidden="true">present_to_all</i>
            </div>
          </div>
          <div class="tc-flex-item tc-right-cage">
            <div class="row">
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div class="row">
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">

                    <div class="tc-action-chip-list-wrapper tc-chip-selector__action-chips">
                      <div class="tc-action-chip" *ngFor="let fac of defaultFacilityDetails"
                           (click)="toggleValue(fac)"
                           [ngClass]="facilityList.includes(fac.code) ?'tc-action-chip--active':'tc-action-chip--inactive' ">
                        <span class="tc-action-chip__icon tc-action-chip__icon-man"></span>
                        <span class="tc-action-chip__icon tc-action-chip__icon-select">
                                  <i class="material-icons">done</i>
                                </span>
                        <div class="tc-action-chip__text">
                          {{fac.name}}
                        </div>
                      </div>
                    </div>
                    <!--<div class="tc-input-edit dcpiec-chip-group">-->
                      <!--<div class="card-chips">-->
                        <!--<div class="chip" *ngFor="let fac of defaultFacilityDetails"-->
                             <!--(click)="toggleValue(fac.name)"-->
                             <!--[ngClass]="facilities.includes(fac.name) ?'active':'' ">-->
                          <!--{{fac.name}}-->
                        <!--</div>-->
                      <!--</div>-->
                    <!--</div>-->
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>




