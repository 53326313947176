<div id ="{{uId}}"
     class="main-header-wrapper"
     [@scaffAnimation]="toggleAnimation"
     (@scaffAnimation.done)="closeAfterAnimation($event)"><!--main-head-wrapper-Start-->

  <div class="sidebar"><!--Left-sidebar-Start-->
    <router-outlet name="s1"></router-outlet>
  </div><!--Left-sidebar-End-->

  <div id="main-scroll-container"
       class="main-panel" [ngClass]="headerClass"
       tcScrollContainer><!--Right-panel--Start-->
    <div class="header-panel main-panel--header"
         tcSticky="1"
         [containerId]="'main-scroll-container'"
         [conditionalStickyClass]="'tc-header-wrapper--fixed'"><!--App -Header--Start-->
      <div class="wrapper-panels">
        <div class="container-fluid">
          <div class="menu-bar-search tc-header-wrapper__header">
            <router-outlet name="h1"></router-outlet>
          </div>
          <div class="menu-bar-search tc-header-wrapper__search">
            <router-outlet name="h2"></router-outlet>
          </div>
        </div>
      </div>
    </div><!--App -Header--End-->

    <!--H2 - H6--Start-->
    <div class="inner-panel quick-action-menu"
         first-animation-type
         tcSticky>
      <div class="inner-panel-wrapper">
        <div class="icon-head-main">
          <router-outlet name="h3"></router-outlet>
        </div>
      </div>
    </div><!--H3-Quick-Action-Component-->
    <div class="inner-panel secondary-header-bar"
         first-animation-type
         tcSticky="2"
         [containerId]="'main-scroll-container'"
         [stickyClass]="'sticky-panel'">
      <div class="inner-panel-wrapper">
        <div class="icon-head-main">
          <router-outlet name="h4"></router-outlet>
        </div>
      </div>
    </div><!--H4-Secondary-Header-Component-->
    <div class="inner-panel">
      <div class="inner-panel-wrapper">
        <div class="icon-head-main">
          <router-outlet name="h5"></router-outlet>
        </div>
      </div>
    </div><!--H5-Component-->
    <div class="inner-panel">
      <div class="inner-panel-wrapper">
        <div class="icon-head-main">
          <router-outlet name="h6"></router-outlet>
        </div>
      </div>
    </div><!--H6-Component-->
    <!--H2 - H6--End-->

    <div class="body-content"><!--body-content-Start-->
      <div class="inner-body ">
        <div class="container-fluid">
          <router-outlet name="b1"></router-outlet>
          <router-outlet name="b2"></router-outlet>
        </div>
      </div>
    </div><!--body-content-End-->
  </div><!--Right-panel--End-->
</div><!--main-head-wrapper-End-->
