<div class="container-fluid" *ngIf="!isHeader">
    <div class="row tc-flex-box">
        <div class="col-xs-12 col-sm-12 col-md-4 tc-flex-item">
            <div class="tc-flex-box">
                <div class="tc-flex-item">
                    <tc-resource
                            *ngIf="summaryCard?.icon?.value"
                            id="{{summaryCard?.icon?.key}}"
                            [key]="summaryCard?.icon?.value?.key"
                            [value]="summaryCard?.icon?.value?.value"
                            [style]="summaryCard?.icon?.value?.style"
                            [iconType]="summaryCard?.icon?.value?.iconType">     
                    </tc-resource>
                </div>
                <div
                        *ngIf="bookingItem" class="tc-flex-item"
                        [ngClass]="isHeader? 'additional-contact--header' : ''">
                    {{bookingItem.bookingReferenceId}}
                </div>
            </div>
        </div>
        <div
                class="col-xs-12 col-sm-12  col-md-3 tc-flex-item"
                *ngIf="bookingItem && bookingItem.genBookingItem && bookingItem.mainType !== 'HTL' && !isHeader">
            <div
                    class="">{{bookingItem.genBookingItem.productName ? bookingItem.genBookingItem.productName : '--'}}
            </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3  tc-flex-item"
             *ngIf="bookingItem && bookingItem.genBookingItem && bookingItem.mainType === 'HTL'  && !isHeader">
            <div class="">{{bookingItem.genBookingItem ? 'Hotel' : ''}}
            </div>
        </div>
        <div
                class="col-xs-12 col-sm-12  col-md-3 tc-flex-item"
                *ngIf="bookingItem && bookingItem.trsBookingItem && !isHeader">
            <div
                    class="">{{bookingItem.trsBookingItem ? 'Vehicle' : ''}}
            </div>
        </div>
        <div class="col-xs-12 col-sm-12  col-md-2 tc-flex-item ">
            <div class="tc-flex tc-flex--center-vertical"
                    *ngIf="bookingItem && !isHeader && bookingItem.passengerTempIds">
                <mat-icon class="icon icon--inactive tc-mr-1">people</mat-icon>
                <span>{{' ' + bookingItem.passengerTempIds.length}}</span>
            </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 tc-flex-item ">
            <div class="tc-icon-block tc-flex tc-flex--center-vertical"
                    *ngIf="bookingItem && !isHeader ">
                <mat-icon class="icon icon--inactive tc-mr-1">schedule</mat-icon>
                <span>{{bookingItem.startTime ? (' ' + dateFormatter.dateAsString(bookingItem.startTime, 'YYYY-MM-DD HH:mm')) : '--'}}</span>
            </div>
        </div>
<!--        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 ">-->
<!--        </div>-->
        <div class="col-xs-12 col-sm-12 col-md-1 tc-flex-item text-right" *ngIf="isEditable && showIcons">
            <div class="tc-icon-block">
<!--                <span class="tc-icon-block__icon tc-icon-block__icon&#45;&#45;active">-->
<!--                  <i-->
<!--                          class="material-icons" *ngIf="showIcons && isHeader"-->
<!--                          (click)="onPassengersClick($event)">people</i>-->
<!--                </span>-->
                <button class="tc-icon-block__icon sort-icon tc-icon-block__icon--active"
                        mat-icon-button
                        matTooltip="{{'Delete' | translate}}"
                        (click)="onDelete()">
                    <i class="material-icons">delete</i>
                </button>
            </div>
        </div>
    </div>
</div>


<div class="ecshc-card" *ngIf="showIcons && isHeader">
    <div class="ecshc-card__wrapper">
        <div class="ecshc-card__content ecshc-content-wrapper">
            <h5 class="ecshc-content-wrapper__heading" *ngIf="bookingItem">{{bookingItem.bookingReferenceId}}</h5>
        </div>
        <div class="tc-icon-block ecshc-card__actions" *ngIf="showIcons && isHeader">
            <button class="tc-icon-block__icon sort-icon tc-icon-block__icon--active"
                    mat-icon-button
                    matTooltip="{{'View Passengers' | translate}}"
                    (click)="onPassengersClick($event)">
                <i class="material-icons">people</i>
            </button>
            <button class="tc-icon-block__icon sort-icon tc-icon-block__icon--active"
                    mat-icon-button
                    matTooltip="{{'Edit as new' | translate}}"
                    (click)="onEditAsNew()">
                <i class="material-icons">add_to_photos</i>
            </button>
            <button class="tc-icon-block__icon sort-icon tc-icon-block__icon--active"
                    mat-icon-button
                    matTooltip="{{'Delete' | translate}}"
                    (click)="onDelete()">
                <i class="material-icons">delete</i>
            </button>
        </div>
    </div>
</div>
