import * as tslib_1 from "tslib";
import { DatePipe } from '@angular/common';
import { EventEmitter } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import { TCO } from '../../../constants';
import { MainType } from '../../../models/reservation-v2/main-type';
import { PRODUCT_LEVELS_SHOW_AS } from '../../../models/reservation-v2/product-level';
import { ServiceSharing } from '../../../models/reservation-v2/service-sharing';
import { DataKey, DataStoreService } from '../framework/data-store.service';
import * as i0 from "@angular/core";
import * as i1 from "../framework/data-store.service";
var OperationV2DataProcessorService = /** @class */ (function () {
    function OperationV2DataProcessorService(dataStoreService) {
        var _this = this;
        this.dataStoreService = dataStoreService;
        this.confirmButtonEnable = false;
        this.supplierConfirmButtonEnable = false;
        this.supplierRejectButtonEnable = false;
        this.autoAllocationButtonEnable = false;
        this.EMPTY_STRING = '--';
        this.NOT_AVAILABLE_STRING = 'N/A';
        this.datePipe = new DatePipe('en-US');
        this.simpleDateFormat = 'dd-MM-yyyy';
        this.simpleTimeFormat = 'h:mm a';
        this.invokeShowSearchCriteriaFunction = new EventEmitter();
        // common technical functions
        this.getFromConsumer = function (handlerObj, consumer) {
            if (consumer && handlerObj.handlerParam) {
                var item = _.get(consumer, handlerObj.handlerParam);
                if (item && handlerObj.type && handlerObj.type === 'function') {
                    return item.bind(consumer);
                }
                else {
                    if (!item) {
                        if (consumer.constructor && consumer.constructor.name) {
                            console.error(handlerObj.handlerParam + ' not found in ' + consumer.constructor.name);
                        }
                        else {
                            console.error(handlerObj.handlerParam + ' not found in ' + consumer);
                        }
                    }
                    return item;
                }
            }
        };
        this.normalCellValueRenderer = function (params) {
            if (params.value) {
                return params.value;
            }
            else {
                return _this.EMPTY_STRING;
            }
        };
        // common for service queue and booking queue
        this.displayObjName = function (option) {
            if (option && option.name) {
                return option.name;
            }
            else {
                return '';
            }
        };
        this.displayObjCodeName = function (option) {
            var codeName = '';
            if (option) {
                if (option.code) {
                    codeName = option.code;
                }
                codeName += ' | ';
                if (option.name) {
                    codeName += option.name;
                }
                return codeName;
            }
            else {
                return '';
            }
        };
        this.getServiceBookingKcCompany = function (params) {
            if (params.data && params.data.kcCompany) {
                return params.data.kcCompany;
            }
            else {
                return _this.EMPTY_STRING;
            }
        };
        this.getServiceBookingKcDivision = function (params) {
            if (params.data && params.data.kcDivision) {
                var divisions = _this.dataStoreService.get(DataKey.kcDivisions);
                var divName_1 = params.data.kcDivision;
                divisions._value.forEach(function (division) {
                    if (division && division.code && division.code === params.data.kcDivision) {
                        divName_1 = division.name;
                    }
                });
                return divName_1;
            }
            else {
                return _this.EMPTY_STRING;
            }
        };
        this.getServiceBookingKcBrand = function (params) {
            if (params.data && params.data.kcBrand) {
                var brands = _this.dataStoreService.get(DataKey.kcBrandsList);
                var brandName_1 = params.data.kcBrand;
                brands._value.forEach(function (brand) {
                    if (brand && brand.code && brand.code === params.data.kcBrand) {
                        brandName_1 = brand.name;
                    }
                });
                return brandName_1;
            }
            else {
                return _this.EMPTY_STRING;
            }
        };
        this.getServiceBookingKcDistributionChannel = function (params) {
            if (params.data && params.data.kcDistributionChannel) {
                var disChannels = _this.dataStoreService.get(DataKey.kcDistributionChannelList);
                var disChannelName_1 = params.data.kcDistributionChannel;
                disChannels._value.forEach(function (disChannel) {
                    if (disChannel && disChannel.code && disChannel.code === params.data.kcDistributionChannel) {
                        disChannelName_1 = disChannel.name;
                    }
                });
                return disChannelName_1;
            }
            else {
                return _this.EMPTY_STRING;
            }
        };
        this.getServiceBookingServiceDate = function (params) {
            if (params.data && params.data.serviceDate) {
                return _this.datePipe.transform(params.data.serviceDate, _this.simpleDateFormat, TCO.AppData.GMT_TIME_ZONE);
            }
            else {
                return _this.EMPTY_STRING;
            }
        };
        this.getBookingDate = function (params) {
            if (params.data && params.data.bookingDate) {
                return _this.datePipe.transform(params.data.bookingDate, _this.simpleDateFormat, TCO.AppData.GMT_TIME_ZONE);
            }
            else {
                return _this.EMPTY_STRING;
            }
        };
        this.getServiceBookingSharingType = function (params) {
            if (params.data && params.data.pvtShared) {
                if (ServiceSharing.SHARED === params.data.pvtShared) {
                    return 'Shared';
                }
                else if (ServiceSharing.PVT === params.data.pvtShared) {
                    return 'Pvt';
                }
            }
            else {
                return _this.NOT_AVAILABLE_STRING;
            }
        };
        this.getServiceBookingQueueItemType = function (params) {
            if (params.data && params.data) {
                if (params.data.trsBookingItem) {
                    return 'Vehicle';
                }
                else if (params.data.genBookingItem) {
                    var product = params.data.genBookingItem.productName;
                    if (product) {
                        return product;
                    }
                    else {
                        if (params.data.mainType) {
                            if (params.data.mainType === MainType.HTL) {
                                return 'Hotel';
                            }
                            else if (params.data.mainType === MainType.FLT) {
                                return 'Flight';
                            }
                            else if (params.data.mainType === MainType.CAR) {
                                return 'Car';
                            }
                            else if (params.data.mainType === MainType.EXC) {
                                return 'Excursion';
                            }
                        }
                        return _this.EMPTY_STRING;
                    }
                }
            }
            else {
                return _this.EMPTY_STRING;
            }
        };
        this.getServiceBookingQueueHotelCity = function (params) {
            if (params.data && params.data.genBookingItem && params.data.genBookingItem.city) {
                return params.data.genBookingItem.city;
            }
            else {
                return _this.EMPTY_STRING;
            }
        };
        this.getServiceStartTime = function (params) {
            if (params.data && params.data.startTime) {
                return moment(new Date(params.data.startTime)).format('HH:mm');
            }
            else if (params.data && params.data.primaryAssignment && params.data.primaryAssignment.startTime) {
                return moment(new Date(params.data.primaryAssignment.startTime)).format('HH:mm');
            }
            else if (params.data && params.data.secondaryAssignment && params.data.secondaryAssignment.startTime) {
                return moment(new Date(params.data.secondaryAssignment.startTime)).format('HH:mm');
            }
            else if (params.data && !params.data.startTime && params.data.trsBookingItem) {
                params.data.startTime = new Date(params.data.trsBookingItem.departureDateTime);
                return moment(params.data.startTime).format('HH:mm');
            }
            else if (params.data && params.data.serviceDate) {
                return moment(params.data.serviceDate).format('HH:mm');
            }
        };
        this.getServiceEndTime = function (params) {
            if (params.data && params.data.endTime) {
                return moment(new Date(params.data.endTime)).format('HH:mm');
            }
            else if (params.data && params.data.primaryAssignment && params.data.primaryAssignment.endTime) {
                return moment(new Date(params.data.primaryAssignment.endTime)).format('HH:mm');
            }
            else if (params.data && params.data.secondaryAssignment && params.data.secondaryAssignment.endTime) {
                return moment(new Date(params.data.secondaryAssignment.endTime)).format('HH:mm');
            }
            else if (params.data && !params.data.endTime && params.data.trsBookingItem) {
                params.data.endTime = new Date(params.data.trsBookingItem.arrivalDateTime);
                return moment(params.data.endTime).format('HH:mm');
            }
            else if (params.data && params.data.serviceDate) {
                return moment(params.data.serviceDate).format('HH:mm');
            }
        };
        this.getServiceBookingQueuePrimaryJobId = function (params) {
            if (params.data && params.data.primaryAssignment) {
                if (params.data.primaryAssignment.assignmentId) {
                    return params.data.primaryAssignment.assignmentId;
                }
                else {
                    return 'TEMP-' + params.data.primaryAssignment.temporaryAssignmentId;
                }
            }
            else {
                return _this.EMPTY_STRING;
            }
        };
        this.getServiceBookingQueuePrimaryServiceType = function (params) {
            if (params.data && params.data.primaryAssignment && params.data.primaryAssignment.resourceType) {
                return params.data.primaryAssignment.resourceType;
            }
            else {
                return _this.EMPTY_STRING;
            }
        };
        this.getServiceBookingQueuePrimarySupplier = function (params) {
            if (params.data && params.data.primaryAssignment && params.data.primaryAssignment.supplierName) {
                return params.data.primaryAssignment.supplierName;
            }
            else {
                return _this.EMPTY_STRING;
            }
        };
        this.getServiceBookingQueueSupplierConfirmationNo = function (params) {
            if (params.data && params.data.confirmationNo) {
                return params.data.confirmationNo;
            }
            else {
                return _this.EMPTY_STRING;
            }
        };
        this.getServiceBookingQueuePrimaryResource = function (params) {
            if (params.data && params.data.primaryAssignment && params.data.primaryAssignment.resourceName) {
                return params.data.primaryAssignment.resourceName;
            }
            else {
                return _this.EMPTY_STRING;
            }
        };
        this.getServiceBookingQueuePrimaryJobPax = function (params) {
            if (params.data && params.data.primaryAssignment) {
                return (params.data.primaryAssignment.adultCount + params.data.primaryAssignment.teenCount +
                    params.data.primaryAssignment.childCount + params.data.primaryAssignment.infantCount) + ' - (' +
                    (params.data.primaryAssignment.adultCount + params.data.primaryAssignment.teenCount) + ' ' +
                    params.data.primaryAssignment.childCount + ' ' + params.data.primaryAssignment.infantCount + ')';
            }
            else {
                return _this.EMPTY_STRING;
            }
        };
        this.getServiceBookingQueueSecondaryJobId = function (params) {
            if (params.data && params.data.secondaryAssignment) {
                if (params.data.secondaryAssignment.assignmentId) {
                    return params.data.secondaryAssignment.assignmentId;
                }
                else {
                    return 'TEMP-' + params.data.secondaryAssignment.temporaryAssignmentId;
                }
            }
            else {
                return _this.EMPTY_STRING;
            }
        };
        this.getServiceBookingQueueSecondaryServiceType = function (params) {
            if (params.data && params.data.secondaryAssignment && params.data.secondaryAssignment.resourceType) {
                return params.data.secondaryAssignment.resourceType;
            }
            else {
                return _this.EMPTY_STRING;
            }
        };
        this.getServiceBookingQueueSecondarySupplier = function (params) {
            if (params.data && params.data.secondaryAssignment && params.data.secondaryAssignment.supplierName) {
                return params.data.secondaryAssignment.supplierName;
            }
            else {
                return _this.EMPTY_STRING;
            }
        };
        this.getServiceBookingQueueSecondaryResource = function (params) {
            if (params.data && params.data.secondaryAssignment && params.data.secondaryAssignment.resourceName) {
                return params.data.secondaryAssignment.resourceName;
            }
            else {
                return _this.EMPTY_STRING;
            }
        };
        this.getServiceBookingQueueSecondaryJobPax = function (params) {
            if (params.data && params.data.secondaryAssignment) {
                return (params.data.secondaryAssignment.adultCount + params.data.secondaryAssignment.teenCount +
                    params.data.secondaryAssignment.childCount + params.data.secondaryAssignment.infantCount) + ' - (' +
                    (params.data.secondaryAssignment.adultCount + params.data.secondaryAssignment.teenCount) + ' ' +
                    params.data.secondaryAssignment.childCount + ' ' + params.data.secondaryAssignment.infantCount + ')';
            }
            else {
                return _this.EMPTY_STRING;
            }
        };
        this.getServiceBookingQueueTransferMode = function (params) {
            if (params.data && params.data.trsBookingItem && params.data.trsBookingItem.transferModeName) {
                return params.data.trsBookingItem.transferModeName;
            }
            else if (params.data && params.data.trsBookingItem && params.data.trsBookingItem.transferMode) {
                return params.data.trsBookingItem.transferMode;
            }
            else {
                return _this.EMPTY_STRING;
            }
        };
        this.getServiceBookingQueueRoute = function (params) {
            if (params.data && params.data.trsBookingItem && params.data.trsBookingItem.route) {
                return params.data.trsBookingItem.route;
            }
            else {
                return _this.EMPTY_STRING;
            }
        };
        this.getServiceBookingQueuePickupDate = function (params) {
            if (params.data && params.data.trsBookingItem && params.data.trsBookingItem.departureDateTime) {
                return _this.datePipe.transform(params.data.trsBookingItem.departureDateTime, _this.simpleDateFormat, TCO.AppData.GMT_TIME_ZONE);
            }
            else {
                return _this.EMPTY_STRING;
            }
        };
        this.getServiceBookingQueuePickupTime = function (params) {
            if (params.data && params.data.trsBookingItem && params.data.trsBookingItem.departureDateTime) {
                return _this.datePipe.transform(params.data.trsBookingItem.departureDateTime, _this.simpleTimeFormat, TCO.AppData.GMT_TIME_ZONE);
            }
            else {
                return _this.EMPTY_STRING;
            }
        };
        this.getServiceBookingQueuePickupLocation = function (params) {
            if (params.data && params.data.trsBookingItem && params.data.trsBookingItem.pickupPointName) {
                return params.data.trsBookingItem.pickupPointName;
            }
            else {
                return _this.EMPTY_STRING;
            }
        };
        this.getServiceBookingQueuePickupType = function (params) {
            if (params.data && params.data.trsBookingItem && params.data.trsBookingItem.pickupType) {
                return params.data.trsBookingItem.pickupType;
            }
            else {
                return _this.EMPTY_STRING;
            }
        };
        this.getServiceBookingQueueDropOffDate = function (params) {
            if (params.data && params.data.trsBookingItem && params.data.trsBookingItem.arrivalDateTime) {
                return _this.datePipe.transform(params.data.trsBookingItem.arrivalDateTime, _this.simpleDateFormat, TCO.AppData.GMT_TIME_ZONE);
            }
            else {
                return _this.EMPTY_STRING;
            }
        };
        this.getServiceBookingQueueDropOffTime = function (params) {
            if (params.data && params.data.trsBookingItem && params.data.trsBookingItem.arrivalDateTime) {
                return _this.datePipe.transform(params.data.trsBookingItem.arrivalDateTime, _this.simpleTimeFormat, TCO.AppData.GMT_TIME_ZONE);
            }
            else {
                return _this.EMPTY_STRING;
            }
        };
        this.getServiceBookingQueueDropOffLocation = function (params) {
            if (params.data && params.data.trsBookingItem && params.data.trsBookingItem.dropOffPointName) {
                return params.data.trsBookingItem.dropOffPointName;
            }
            else {
                return _this.EMPTY_STRING;
            }
        };
        this.getServiceBookingQueueDropOffType = function (params) {
            if (params.data && params.data.trsBookingItem && params.data.trsBookingItem.dropOffType) {
                return params.data.trsBookingItem.dropOffType;
            }
            else {
                return _this.EMPTY_STRING;
            }
        };
        // todo consider arrival departure
        this.getServiceBookingQueueDeptFltDate = function (params) {
            if (params.data && params.data.trsBookingItem && params.data.trsBookingItem.flightDeptTime) {
                return _this.datePipe.transform(params.data.trsBookingItem.flightDeptTime, _this.simpleDateFormat, TCO.AppData.GMT_TIME_ZONE);
            }
            else {
                return _this.EMPTY_STRING;
            }
        };
        this.getServiceBookingQueueDeptFltTime = function (params) {
            if (params.data && params.data.trsBookingItem && params.data.trsBookingItem.flightDeptTime) {
                return _this.datePipe.transform(params.data.trsBookingItem.flightDeptTime, _this.simpleTimeFormat, TCO.AppData.GMT_TIME_ZONE);
            }
        };
        this.getServiceBookingQueueDeptFltNo = function (params) {
            if (params.data && params.data.trsBookingItem && params.data.trsBookingItem.departureFlightNo) {
                return params.data.trsBookingItem.departureFlightNo;
            }
            else {
                return _this.EMPTY_STRING;
            }
        };
        this.getServiceBookingQueueDeptFltAirportCode = function (params) {
            if (params.data && params.data.trsBookingItem && params.data.trsBookingItem.departureAirPort) {
                return params.data.trsBookingItem.departureAirPort;
            }
            else {
                return _this.EMPTY_STRING;
            }
        };
        this.getServiceBookingQueueDeptFltAirlineCode = function (params) {
            if (params.data && params.data.trsBookingItem && params.data.trsBookingItem.departureAirlineCode) {
                return params.data.trsBookingItem.departureAirlineCode;
            }
            else {
                return _this.EMPTY_STRING;
            }
        };
        this.getServiceBookingQueueDeptFltTerminal = function (params) {
            if (params.data && params.data.trsBookingItem && params.data.trsBookingItem.flightDeptTerminal) {
                return params.data.trsBookingItem.flightDeptTerminal;
            }
            else {
                return _this.EMPTY_STRING;
            }
        };
        this.getServiceBookingQueueArrFltDate = function (params) {
            if (params.data && params.data.trsBookingItem && params.data.trsBookingItem.flightArrTime) {
                return _this.datePipe.transform(params.data.trsBookingItem.flightArrTime, _this.simpleDateFormat, TCO.AppData.GMT_TIME_ZONE);
            }
            else {
                return _this.EMPTY_STRING;
            }
        };
        this.getServiceBookingQueueArrFltTime = function (params) {
            if (params.data && params.data.trsBookingItem && params.data.trsBookingItem.flightArrTime) {
                return _this.datePipe.transform(params.data.trsBookingItem.flightArrTime, _this.simpleTimeFormat, TCO.AppData.GMT_TIME_ZONE);
            }
        };
        this.getServiceBookingQueueArrFltNo = function (params) {
            if (params.data && params.data.trsBookingItem && params.data.trsBookingItem.arrivalFlightNo) {
                return params.data.trsBookingItem.arrivalFlightNo;
            }
            else {
                return _this.EMPTY_STRING;
            }
        };
        this.getServiceBookingQueueArrFltAirportCode = function (params) {
            if (params.data && params.data.trsBookingItem && params.data.trsBookingItem.arrivalAirPort) {
                return params.data.trsBookingItem.arrivalAirPort;
            }
            else {
                return _this.EMPTY_STRING;
            }
        };
        this.getServiceBookingQueueArrFltAirlineCode = function (params) {
            if (params.data && params.data.trsBookingItem && params.data.trsBookingItem.arrivalAirlineCode) {
                return params.data.trsBookingItem.arrivalAirlineCode;
            }
            else {
                return _this.EMPTY_STRING;
            }
        };
        this.getServiceBookingQueueArrFltTerminal = function (params) {
            if (params.data && params.data.trsBookingItem &&
                params.data.trsBookingItem.flightArrTerminal) {
                return params.data.trsBookingItem.flightArrTerminal;
            }
            else {
                return _this.EMPTY_STRING;
            }
        };
        this.getServiceBookingQueueTotalCost = function (params) {
            var cost = 0;
            if (params.data && params.data.itemCostPortion) {
                cost = params.data.itemCostPortion.totalCost;
            }
            if (cost) {
                return cost.toFixed(2) + ' ' + params.data.itemCostPortion.currency;
            }
            else {
                return _this.EMPTY_STRING;
            }
        };
        // for booking queue
        this.getBookingQueueClientGroup = function (params) {
            if (params.data && params.data.bookingClient && params.data.bookingClient.clientGroupName) {
                return params.data.bookingClient.clientGroupName;
            }
            else {
                return _this.EMPTY_STRING;
            }
        };
        this.getBookingQueueClientGrade = function (params) {
            if (params.data && params.data.bookingClient && params.data.bookingClient.grade) {
                return params.data.bookingClient.grade;
            }
            else {
                return _this.EMPTY_STRING;
            }
        };
        this.getBookingQueueCompany = function (params) {
            if (params.data && params.data.kcCompany) {
                return params.data.kcCompany;
            }
            else {
                return _this.EMPTY_STRING;
            }
        };
        this.getBookingQueueDivision = function (params) {
            if (params.data && params.data.kcDivision) {
                var divisions = _this.dataStoreService.get(DataKey.kcDivisions);
                var divName_2 = params.data.kcDivision;
                divisions._value.forEach(function (division) {
                    if (division && division.code && division.code === params.data.kcDivision) {
                        divName_2 = division.name;
                    }
                });
                return divName_2;
            }
            else {
                return _this.EMPTY_STRING;
            }
        };
        this.getBookingQueueBrand = function (params) {
            if (params.data && params.data.kcBrand) {
                var brands = _this.dataStoreService.get(DataKey.kcBrandsList);
                var brandName_2 = params.data.kcBrand;
                brands._value.forEach(function (brand) {
                    if (brand && brand.code && brand.code === params.data.kcBrand) {
                        brandName_2 = brand.name;
                    }
                });
                return brandName_2;
            }
            else {
                return _this.EMPTY_STRING;
            }
        };
        this.getBookingQueueDistributionChannel = function (params) {
            if (params.data && params.data.kcDistributionChannel) {
                var disChannels = _this.dataStoreService.get(DataKey.kcDistributionChannelList);
                var disChannelName_2 = params.data.kcDistributionChannel;
                disChannels._value.forEach(function (disChannel) {
                    if (disChannel && disChannel.code && disChannel.code === params.data.kcDistributionChannel) {
                        disChannelName_2 = disChannel.name;
                    }
                });
                return disChannelName_2;
            }
            else {
                return _this.EMPTY_STRING;
            }
        };
        // for service queue
        this.getServiceQueueClientGroup = function (params) {
            var e_1, _a;
            if (params.data && params.data.bookingClients && params.data.bookingClients.length) {
                var groupNames = [];
                try {
                    for (var _b = tslib_1.__values(params.data.bookingClients), _c = _b.next(); !_c.done; _c = _b.next()) {
                        var bookingClient = _c.value;
                        if (bookingClient.clientGroupName && !groupNames.includes(bookingClient.clientGroupName)) {
                            groupNames.push(bookingClient.clientGroupName);
                        }
                    }
                }
                catch (e_1_1) { e_1 = { error: e_1_1 }; }
                finally {
                    try {
                        if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                    }
                    finally { if (e_1) throw e_1.error; }
                }
                return groupNames.join(', ');
            }
            else {
                return _this.EMPTY_STRING;
            }
        };
        this.getServiceQueueClientGrade = function (params) {
            var e_2, _a;
            if (params.data && params.data.bookingClients && params.data.bookingClients.length) {
                var grades = [];
                try {
                    for (var _b = tslib_1.__values(params.data.bookingClients), _c = _b.next(); !_c.done; _c = _b.next()) {
                        var bookingClient = _c.value;
                        if (bookingClient.grade && !grades.includes(bookingClient.grade)) {
                            grades.push(bookingClient.grade);
                        }
                    }
                }
                catch (e_2_1) { e_2 = { error: e_2_1 }; }
                finally {
                    try {
                        if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                    }
                    finally { if (e_2) throw e_2.error; }
                }
                return grades.join(', ');
            }
            else {
                return _this.EMPTY_STRING;
            }
        };
        //
        /*
         product queue specific functions
         */
        this.getProductQueueProductLevel = function (params) {
            if (params.data && params.data.productLevel) {
                var prodLevel = PRODUCT_LEVELS_SHOW_AS[params.data.productLevel];
                return prodLevel ? prodLevel : _this.EMPTY_STRING;
            }
            else {
                return _this.EMPTY_STRING;
            }
        };
        this.getProductQueueMainTypes = function (params) {
            if (params.data && params.data.mainTypes) {
                return params.data.mainTypes.join();
            }
            else {
                return _this.EMPTY_STRING;
            }
        };
        this.getProductQueueSubTypes = function (params) {
            if (params.data && params.data.subTypes && params.data.subTypes.length) {
                // todo: remove this code fragment when data is cleared.
                var indexSharedTour = params.data.subTypes.indexOf('Shared');
                if (indexSharedTour !== -1) {
                    params.data.subTypes[indexSharedTour] = 'Shared Tour';
                }
                var indexPvtTour = params.data.subTypes.indexOf('Private');
                if (indexPvtTour !== -1) {
                    params.data.subTypes[indexPvtTour] = 'Private Tour';
                }
                return params.data.subTypes.join();
            }
            else {
                return _this.NOT_AVAILABLE_STRING;
            }
        };
        this.getProductQueueSharingType = function (params) {
            if (params.data && params.data.subTypes && params.data.pvtShared) {
                if ('SHARED' === params.data.pvtShared) {
                    return 'Shared';
                }
                else if ('PVT' === params.data.pvtShared) {
                    return 'Pvt';
                }
            }
            else {
                return _this.NOT_AVAILABLE_STRING;
            }
        };
        this.getProductQueueSupplierName = function (params) {
            if (params.data) {
                if (params.data.supplierName) {
                    return params.data.supplierName;
                }
                else if (params.data.supplierCode) {
                    return params.data.supplierCode;
                }
                else {
                    return _this.EMPTY_STRING;
                }
            }
            else {
                return _this.EMPTY_STRING;
            }
        };
        this.getProductQueueStartDate = function (params) {
            if (params.data && params.data.startDate) {
                return _this.datePipe.transform(params.data.startDate, _this.simpleDateFormat, TCO.AppData.GMT_TIME_ZONE);
            }
            else {
                return _this.NOT_AVAILABLE_STRING;
            }
        };
        this.getProductQueueEndDate = function (params) {
            if (params.data && params.data.endDate) {
                return _this.datePipe.transform(params.data.endDate, _this.simpleDateFormat, TCO.AppData.GMT_TIME_ZONE);
            }
            else {
                return _this.NOT_AVAILABLE_STRING;
            }
        };
        this.getProductQueueLanguage = function (params) {
            if (params.data && params.data.languages && params.data.languages.length) {
                return params.data.languages.join();
            }
            else {
                return _this.NOT_AVAILABLE_STRING;
            }
        };
        // booking queue specific functions
        this.getBookingQueueBookingId = function (params) {
            var id = '';
            if (params.data) {
                if (params.data.bookingSource) {
                    id = params.data.bookingSource + '-';
                }
                if (params.data.bookingSourceId) {
                    id += params.data.bookingSourceId;
                }
                return id;
            }
            else {
                return _this.EMPTY_STRING;
            }
        };
        // booking queue specific functions
        this.getBookingQueueTbxBookingRefId = function (params) {
            var id = '';
            if (params.data) {
                if (params.data.bookingSourceId) {
                    id += params.data.bookingSourceId;
                }
                if (params.data.productCategory) {
                    id += '_' + params.data.productCategory;
                }
                if (params.data.bookingSourceItemNo) {
                    id += '_' + params.data.bookingSourceItemNo;
                }
                return id;
            }
            else {
                return _this.EMPTY_STRING;
            }
        };
        this.getBookingQueueTotalPax = function (params) {
            if (params.data) {
                return (params.data.adultCount + params.data.teenCount + params.data.childrenCount +
                    params.data.infantCount) + ' - (' +
                    (params.data.adultCount + params.data.teenCount) + ' ' + params.data.childrenCount + ' ' +
                    params.data.infantCount + ')';
            }
            else {
                return _this.EMPTY_STRING;
            }
        };
        // service queue specific functions
        this.getServiceQueueServiceId = function (params) {
            return _this.EMPTY_STRING;
        };
        this.getServiceQueueItemName = function (params) {
            return _this.EMPTY_STRING;
        };
        this.getServiceQueueTotalPax = function (params) {
            if (params.data) {
                return (params.data.adultCount + params.data.teenCount + params.data.childrenCount +
                    params.data.infantCount) + ' - (' +
                    (params.data.adultCount + params.data.teenCount) + ' ' + params.data.childrenCount + ' ' +
                    params.data.infantCount + ')';
            }
            else {
                return _this.EMPTY_STRING;
            }
        };
        // showSavedCriteriaClickObserver(): Observable<any> {
        //     return this.subject.asObservable();
        // }
        // vehicle
        this.getServiceBookingQueueVehicleCount = function (params) {
            if (params.data && params.data.trsBookingItem && params.data.trsBookingItem.vehicleCount) {
                return params.data.trsBookingItem.vehicleCount;
            }
            else {
                return _this.EMPTY_STRING;
            }
        };
    }
    OperationV2DataProcessorService.prototype.onShowSavedCriteriaClick = function () {
        this.invokeShowSearchCriteriaFunction.emit();
    };
    OperationV2DataProcessorService.ngInjectableDef = i0.defineInjectable({ factory: function OperationV2DataProcessorService_Factory() { return new OperationV2DataProcessorService(i0.inject(i1.DataStoreService)); }, token: OperationV2DataProcessorService, providedIn: "root" });
    return OperationV2DataProcessorService;
}());
export { OperationV2DataProcessorService };
