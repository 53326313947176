export class DataGridColumn {
    name: string;
    type: InputType;
    options: any[];
}

export enum InputType {
    INPUT = 'INPUT',
    SELECT = 'SELECT',
    MULTI_SELECT = 'MULTI_SELECT',
    CHECK_BOX = 'CHECK_BOX'
}
