import { ResourceCost } from './resource-cost';
import { ResourceCostSetup } from './resource-cost-setup';
import { Room } from './room';
import { RoomOccupancy } from './room-occupancy';

export class RoomResourceCost extends ResourceCost {
    tempCostId: number;
    roomType: string;
    boardBasis: string;
    nights: number;
}

export class RoomResourceCostSetup extends ResourceCostSetup {
    roomType: string;
    boardBasis: string;
    nights: number;
    noOfUnits: number;
    occupancyId: number;
    occupancyTempId: number;
    occupancyName: string;
    roomOccupancy: RoomOccupancy;
    room: Room; // front end only
}
