import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DialogModel, LEVEL } from '@tc-core/model/it/codegen/ui/framework/dialog-model';
import { DialogService } from '@tc/dialog/dialog.service';
import { catchError, map } from 'rxjs/operators';
import { AutoCompleteEditorComponent } from '../../../../widgets/framework/ag-grid-custom-components/editors/auto-complete-editor/auto-complete-editor.component';
import { RoomOccupancySearchCriteria } from '../../../../models/criteria/room-occupancy-search-criteria';
import { ResourceGroup } from '../../../../models/supplier/collection/resource-group';
import { RoomOccupancy } from '../../../../models/supplier/room-occupancy';
import { SupplierService } from '../../../../services/backend-consumers/supplier-service/supplier.service';
import { DataKey, DataStoreService } from '../../../../services/util/framework/data-store.service';
import { ResponseUtil } from '../../../../services/util/response/response-util.service';
import { CardDataHandlerService } from '../../../../services/util/ui/card-data-handler-service.service';
var OccupancySetupComponent = /** @class */ (function () {
    function OccupancySetupComponent(dataStore, cardDataHandlerService, supplierService, dialogService, translateService) {
        var _this = this;
        this.dataStore = dataStore;
        this.cardDataHandlerService = cardDataHandlerService;
        this.supplierService = supplierService;
        this.dialogService = dialogService;
        this.translateService = translateService;
        this.columnDefs = [];
        this.defaultColDef = {
            headerValueGetter: function (parameters) {
                var headerIdentifier = parameters.colDef.headerName;
                if (headerIdentifier) {
                    return _this.translateService.instant(headerIdentifier);
                }
                return '';
            }
        };
        this.CARD_CONFIG_GENERIC_EDIT = 'supplier_edit';
        this.frameworkComponents = {
            autoComplete: AutoCompleteEditorComponent
        };
        this.translateService.onLangChange.subscribe(function () {
            _this.gridApi.refreshHeader();
        });
    }
    OccupancySetupComponent.prototype.ngOnInit = function () {
        if (!(this.roomGroup && this.roomGroup.roomGroup && this.roomGroup.roomGroup.roomOccupancies)) {
            this.roomGroup.roomGroup.roomOccupancies = [];
        }
        this.occupancies = JSON.parse(JSON.stringify(this.roomGroup.roomGroup.roomOccupancies));
        this.initPageSectionReferences();
        this.setColDefs();
    };
    OccupancySetupComponent.prototype.initPageSectionReferences = function () {
        this.pageSectionsMap = this.cardDataHandlerService.getAllPageSections(this.CARD_CONFIG_GENERIC_EDIT, []);
        this.occupancyHeaderSection = this.pageSectionsMap.get('occupancy_setup');
    };
    OccupancySetupComponent.prototype.onGridReady = function (params) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.gridApi.setFloatingFiltersHeight(38);
        this.gridApi.setHeaderHeight(40);
        this.gridApi.sizeColumnsToFit();
    };
    OccupancySetupComponent.prototype.onSelectionChanged = function ($event) {
    };
    OccupancySetupComponent.prototype.onCellValueChanged = function ($event) {
    };
    OccupancySetupComponent.prototype.validateUniqueValues = function ($event) {
    };
    OccupancySetupComponent.prototype.onHeaderActionClick = function (event) {
        if (event.id === 'TRIGGER_ADD_OCCUPANCY') {
            this.addRow();
        }
        else if (event.id === 'TRIGGER_DELETE_OCCUPANCY') {
            this.deleteSelected();
        }
        else if (event.id === 'TRIGGER_GEN_OCCUPANCY_1A') {
            this.generateWithAtLeastOneAdult();
        }
        else if (event.id === 'TRIGGER_GEN_OCCUPANCY_2A') {
            this.generateWithAtLeastTwoAdult();
        }
        else if (event.id === 'TRIGGER_GEN_OCCUPANCY_3A') {
            this.generateWithAtLeastThreeAdult();
        }
    };
    OccupancySetupComponent.prototype.addRow = function () {
        var roomOccupancy = new RoomOccupancy();
        roomOccupancy.adults = 0;
        roomOccupancy.teens = 0;
        roomOccupancy.children = 0;
        roomOccupancy.infants = 0;
        roomOccupancy.total = 0;
        roomOccupancy.occupancyId = 0;
        var gridData = this.getGridData(false);
        gridData.push(roomOccupancy);
        this.gridApi.setRowData(gridData);
    };
    OccupancySetupComponent.prototype.getRandomInt = function (min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    };
    OccupancySetupComponent.prototype.deleteSelected = function () {
        var e_1, _a;
        var selectedRows = this.gridApi.getSelectedRows();
        var gridData = this.getGridData(false);
        try {
            for (var selectedRows_1 = tslib_1.__values(selectedRows), selectedRows_1_1 = selectedRows_1.next(); !selectedRows_1_1.done; selectedRows_1_1 = selectedRows_1.next()) {
                var selectedRow = selectedRows_1_1.value;
                gridData.splice(gridData.indexOf(selectedRow), 1);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (selectedRows_1_1 && !selectedRows_1_1.done && (_a = selectedRows_1.return)) _a.call(selectedRows_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        this.gridApi.setRowData(gridData);
    };
    OccupancySetupComponent.prototype.generateWithAtLeastOneAdult = function () {
        if (this.roomGroup.roomGroup.maximumOccupancy > 0 && this.roomGroup.roomGroup.minimumOccupancy > 0) {
            this.searchGeneratedOccupanciesAndFill(1);
        }
        else {
            var invalidAttemptWarning = new DialogModel(true, LEVEL.ERROR, 'Invalid Attempt', 'Please add valid maximum and minimum occupancies for the room before generating', true, 2000);
            var subscriptionInvalid_1 = this.dialogService.confirm(invalidAttemptWarning).subscribe(function (res) {
                subscriptionInvalid_1.unsubscribe();
                return;
            });
        }
    };
    OccupancySetupComponent.prototype.generateWithAtLeastTwoAdult = function () {
        if (this.roomGroup.roomGroup.maximumOccupancy > 0 && this.roomGroup.roomGroup.minimumOccupancy > 0) {
            if (this.roomGroup.roomGroup.maximumOccupancy < 2) {
                var invalidAttemptWarning = new DialogModel(true, LEVEL.ERROR, 'Invalid Attempt', 'Maximum occupancy is lower to generate with 2 adults', true, 2000);
                var subscriptionInvalid_2 = this.dialogService.confirm(invalidAttemptWarning).subscribe(function (res) {
                    subscriptionInvalid_2.unsubscribe();
                    return;
                });
            }
            else {
                this.searchGeneratedOccupanciesAndFill(2);
            }
        }
        else {
            var invalidAttemptWarning = new DialogModel(true, LEVEL.ERROR, 'Invalid Attempt', 'Please add valid maximum and minimum occupancies for the room before generating', true, 2000);
            var subscriptionInvalid_3 = this.dialogService.confirm(invalidAttemptWarning).subscribe(function (res) {
                subscriptionInvalid_3.unsubscribe();
                return;
            });
        }
    };
    OccupancySetupComponent.prototype.generateWithAtLeastThreeAdult = function () {
        if (this.roomGroup.roomGroup.maximumOccupancy > 0 && this.roomGroup.roomGroup.minimumOccupancy > 0) {
            if (this.roomGroup.roomGroup.maximumOccupancy < 3) {
                var invalidAttemptWarning = new DialogModel(true, LEVEL.ERROR, 'Invalid Attempt', 'Maximum occupancy is lower to generate with 3 adults', true, 2000);
                var subscriptionInvalid_4 = this.dialogService.confirm(invalidAttemptWarning).subscribe(function (res) {
                    subscriptionInvalid_4.unsubscribe();
                    return;
                });
            }
            else {
                this.searchGeneratedOccupanciesAndFill(3);
            }
        }
        else {
            var invalidAttemptWarning = new DialogModel(true, LEVEL.ERROR, 'Invalid Attempt', 'Please add valid maximum and minimum occupancies for the room before generating', true, 2000);
            var subscriptionInvalid_5 = this.dialogService.confirm(invalidAttemptWarning).subscribe(function (res) {
                subscriptionInvalid_5.unsubscribe();
                return;
            });
        }
    };
    OccupancySetupComponent.prototype.searchGeneratedOccupanciesAndFill = function (minAdults) {
        var _this = this;
        var occupancySearchCriteria = new RoomOccupancySearchCriteria();
        occupancySearchCriteria.maximumOccupancy = this.roomGroup.roomGroup.maximumOccupancy;
        occupancySearchCriteria.minimumOccupancy = this.roomGroup.roomGroup.minimumOccupancy;
        occupancySearchCriteria.minAdultsRequired = minAdults;
        occupancySearchCriteria.size = 100000;
        this.supplierService.searchOccupancies(occupancySearchCriteria).subscribe(function (value) {
            if (value) {
                var data = value.data;
                _this.fillOccupanciesWithGeneratedList(data);
            }
        });
    };
    OccupancySetupComponent.prototype.fillOccupanciesWithGeneratedList = function (generated) {
        var e_2, _a;
        var gridData = this.getGridData(false);
        var _loop_1 = function (g) {
            var roomOccupancyFound = gridData.find(function (value) {
                return value.occupancyId === g.occupancyId;
            });
            if (!roomOccupancyFound) {
                gridData.unshift(g);
            }
        };
        try {
            for (var generated_1 = tslib_1.__values(generated), generated_1_1 = generated_1.next(); !generated_1_1.done; generated_1_1 = generated_1.next()) {
                var g = generated_1_1.value;
                _loop_1(g);
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (generated_1_1 && !generated_1_1.done && (_a = generated_1.return)) _a.call(generated_1);
            }
            finally { if (e_2) throw e_2.error; }
        }
        this.gridApi.setRowData(gridData);
    };
    OccupancySetupComponent.prototype.setColDefs = function () {
        var _this = this;
        this.columnDefs = [
            {
                headerName: 'Occupancy',
                field: 'occupancyName',
                editable: true,
                sortable: true,
                resizable: true,
                minWidth: 300,
                flex: 3,
                cellEditorFramework: AutoCompleteEditorComponent,
                cellEditorParams: {
                    viewKey: 'occupancyName',
                    returnObject: true,
                    filterItemAsyncFn: function (params, rowData, text) {
                        var roomOccupancySearchCriteria = new RoomOccupancySearchCriteria();
                        roomOccupancySearchCriteria.name = text;
                        roomOccupancySearchCriteria.minimumOccupancy = _this.roomGroup.roomGroup.minimumOccupancy;
                        roomOccupancySearchCriteria.maximumOccupancy = _this.roomGroup.roomGroup.maximumOccupancy;
                        return _this.supplierService.searchOccupancies(roomOccupancySearchCriteria)
                            .pipe(map(function (response) {
                            return ResponseUtil.getDataArray(response);
                        }), catchError(function (error) {
                            return [];
                        }));
                    }
                },
                valueSetter: function (params) {
                    var data = params.data;
                    var occupancy = params.newValue;
                    params.node.setData(occupancy);
                    return true;
                },
                valueFormatter: function (params) {
                    if (params.value) {
                        return params.value.name;
                    }
                    return '';
                }
            },
            {
                headerName: 'Adult',
                field: 'adults',
                editable: true,
                sortable: true,
                resizable: true,
                flex: 1,
                valueSetter: function (params) {
                    var data = params.data;
                    if (!isNaN(params.newValue)) {
                        data.adults = params.newValue;
                        _this.searchAndSetOccupancy(data, params);
                    }
                }
            },
            {
                headerName: 'Teen',
                field: 'teens',
                editable: true,
                sortable: true,
                resizable: true,
                flex: 1,
                valueSetter: function (params) {
                    var data = params.data;
                    if (!isNaN(params.newValue)) {
                        data.teens = params.newValue;
                        _this.searchAndSetOccupancy(data, params);
                    }
                }
            },
            {
                headerName: 'Child',
                field: 'children',
                editable: true,
                sortable: true,
                resizable: true,
                flex: 1,
                valueSetter: function (params) {
                    var data = params.data;
                    if (!isNaN(params.newValue)) {
                        data.children = params.newValue;
                        _this.searchAndSetOccupancy(data, params);
                    }
                }
            },
            {
                headerName: 'Infant',
                field: 'infants',
                editable: true,
                sortable: true,
                resizable: true,
                flex: 1,
                valueSetter: function (params) {
                    var data = params.data;
                    if (!isNaN(params.newValue)) {
                        data.infants = params.newValue;
                        _this.searchAndSetOccupancy(data, params);
                    }
                }
            }
        ];
    };
    OccupancySetupComponent.prototype.searchAndSetOccupancy = function (occupancyData, params) {
        var roomOccupancySearchCriteria = new RoomOccupancySearchCriteria();
        roomOccupancySearchCriteria.minimumOccupancy = this.roomGroup.roomGroup.minimumOccupancy;
        roomOccupancySearchCriteria.maximumOccupancy = this.roomGroup.roomGroup.maximumOccupancy;
        roomOccupancySearchCriteria.adults = occupancyData.adults;
        roomOccupancySearchCriteria.teens = occupancyData.teens;
        roomOccupancySearchCriteria.children = occupancyData.children;
        roomOccupancySearchCriteria.infants = occupancyData.infants;
        return this.supplierService.searchOccupancies(roomOccupancySearchCriteria).subscribe(function (response) {
            var dataArray = ResponseUtil.getDataArray(response);
            if (dataArray && dataArray.length) {
                params.node.setData(dataArray[0]);
            }
        });
    };
    OccupancySetupComponent.prototype.isSaveButtonEnable = function () {
        return true;
    };
    OccupancySetupComponent.prototype.getGridData = function (onlyValid) {
        var validOccupancies = [];
        this.gridApi.forEachNode(function (node) {
            if (!onlyValid || node.data.occupancyId) {
                validOccupancies.push(node.data);
            }
        });
        return validOccupancies;
    };
    OccupancySetupComponent.prototype.onSave = function () {
        this.roomGroup.roomGroup.roomOccupancies = this.getGridData(true);
        this.dataStore.set(DataKey.popupClose, true);
    };
    OccupancySetupComponent.prototype.onClose = function ($event) {
        this.dataStore.set(DataKey.popupClose, false);
    };
    return OccupancySetupComponent;
}());
export { OccupancySetupComponent };
