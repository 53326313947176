import { Injectable } from '@angular/core';
import { ConfigLoader } from '@tc-core/util/framework';
import { Observable } from 'rxjs';
import { CurrencySearchCriteria } from '../../../models/criteria/currency-search-criteria';
import { DMCQueryParamsService } from '../../util/framework/dmc-query-params.service';
import { RequestService } from '../../util/framework/request.service';
import { UrlPaths } from '../url-paths';

@Injectable({
    providedIn: 'root'
})
export class CostingTypeService {
    constructor(
        private configLoader: ConfigLoader,
        private requestService: RequestService,
        private queryParamsService: DMCQueryParamsService
    ) {
    }

    public getRows(criteria: CurrencySearchCriteria): Observable<any> {
        const reqPrams = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.get(
            UrlPaths.OP_DATA_URL_KEY,
            [UrlPaths.OP_DATA_VERSION, UrlPaths.COSTING_TYPES],
            reqPrams
        );
    }

    public deleteRow(code: string): Observable<any> {
        return this.requestService.delete(
            UrlPaths.OP_DATA_URL_KEY,
            [UrlPaths.OP_DATA_VERSION, UrlPaths.COSTING_TYPES, code]
        );
    }

    public saveRow(row: any): Observable<any> {
        return this.requestService.post(
            UrlPaths.OP_DATA_URL_KEY,
            [UrlPaths.OP_DATA_VERSION, UrlPaths.COSTING_TYPES],
            row
        );
    }

    public updateRow(code: string, row: any): Observable<any> {
        return this.requestService.put(
            UrlPaths.OP_DATA_URL_KEY,
            [UrlPaths.OP_DATA_VERSION, UrlPaths.COSTING_TYPES, code],
            row
        );
    }

}
