<tc-setup-grid
    #docSetupGrid
    [readOnly]="true"
    [noSelection]="true"
    [colDefConfig]="colDefConfig"
    [setupGridComp]="this"
    [actions]="gridActions"
    [dataSource]="this"
    (rowDoubleClicked)="onRowDoubleClicked($event)">
</tc-setup-grid>


<!--id cell-->
<ng-template #formatCell let-row>
    <ng-container *ngIf="row?.documentFormat && row?.documentFormat=='pdf'">
        <span class=""><i
                class="fa fa-lg fa-file-pdf-o no-color--state"
                aria-hidden="true"></i> <span> &nbsp; DOC_{{row.documentId}}    </span></span>
    </ng-container>

    <ng-container *ngIf="row?.documentFormat && row?.documentFormat=='excel'">
        <span class=""><i
                class="fa fa-lg fa-file-excel-o active-color--state "
                aria-hidden="true"></i>  <span> &nbsp; DOC_{{row.documentId}}</span>    </span>
    </ng-container>

    <ng-container *ngIf="row?.documentFormat && row?.documentFormat=='html'">
        <i class="fa fa-html5" aria-hidden="true" style="color: coral;font-size: 1.5em;"></i>
        <span>  &nbsp;DOC_{{row.documentId}} </span>
    </ng-container>
</ng-template>


<ng-template #nameFormatCell let-row>
    <ng-container *ngIf="row?.documentFormat && row?.documentFormat=='pdf'">
        <span class=""><i
                class="fa fa-lg fa-file-pdf-o no-color--state"
                aria-hidden="true"></i> <span> &nbsp;{{row.name}}    </span></span>
    </ng-container>

    <ng-container *ngIf="row?.documentFormat && row?.documentFormat=='excel'">
        <span class=""><i
                class="fa fa-lg fa-file-excel-o active-color--state "
                aria-hidden="true"></i>  <span> &nbsp;{{row.name}}</span>    </span>
    </ng-container>

    <ng-container *ngIf="row?.documentFormat && row?.documentFormat=='csv'">
        <span class=""><i
                class="fa fa-lg fa-file-excel-o active-color--state "
                aria-hidden="true"></i>  <span> &nbsp;{{row.name}}</span>    </span>
    </ng-container>

    <ng-container *ngIf="row?.documentFormat && row?.documentFormat=='html'">
        <i class="fa fa-html5" aria-hidden="true" style="color: coral;font-size: 1.5em;"></i>
        <span>  &nbsp;{{row.name}} </span>
    </ng-container>
</ng-template>

<!--generated by cell-->
<ng-template #generatedByCell let-row>
    <ng-container *ngIf="row && row?.systemGenerated">
        <span class=""><i
                class="fa fa-lg fa-cog no-color--state"
                aria-hidden="true"></i></span>
    </ng-container>

    <ng-container *ngIf="row && !row.systemGenerated">
        <span class=""><i
                class="fa fa-lg fa-user-o no-color--state"
                aria-hidden="true"></i></span>
    </ng-container>
    &nbsp;{{row?(row.generatedOn | date:'yyyy-MM-dd HH:mm a'):''}}
</ng-template>

<!--status column view-->
<ng-template #statusCell let-row>
    <ng-container *ngIf="row?.recentDispatchEntry && row.recentDispatchEntry.dispatchStatus">
        <div class="tc-w-100 text-center">
<!--            <div-->
<!--                    class="tc-status-badge tc-status-badge&#45;&#45;success" style=""-->
<!--                    [ngClass]="{'tc-status-badge&#45;&#45;warning': row.recentDispatchEntry.dispatchStatus === 'PENDING',-->
<!--                                            'tc-status-badge&#45;&#45;success': row.recentDispatchEntry.dispatchStatus === 'SUCCESS',-->
<!--                                            'tc-status-badge&#45;&#45;danger': row.recentDispatchEntry.dispatchStatus === 'FAILED'-->
<!--                                            }">-->
                <span class=""> {{getDisplayDispatchEntry(row.recentDispatchEntry.dispatchStatus)}}</span>
        </div>
    </ng-container>
    <ng-container *ngIf="row && row.recentDispatchEntry===null">
        <div class="tc-ag-grid-cell tc-ag-grid-cell--inactive">
            <div class="tc-w-100 text-center" [ngClass]="{'': true}">
                <span class=""> {{'NOT DISPATCHED' | translate}}</span>
            </div>
        </div>
    </ng-container>
</ng-template>

