import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTable, MatTableDataSource } from '@angular/material';
import { CurrencyFormat } from '@tc-core/model/it/codegen/ui/framework/currency-format';
import { TC } from '@tc-core/util';
import { DataKey, DataStoreService } from '../../../services/util/framework/data-store.service';
import { GenResourceCost } from '../../../models/supplier/gen-resource-cost-setup';
import { Season } from '../../../models/supplier/season';
import { TrsResourceCost } from '../../../models/supplier/trs-resource-cost-setup';

@Component({
    selector: 'tc-gen-cost-setup-data-card',
    templateUrl: './gen-cost-setup-data-card.component.html'
})
export class GenCostSetupDataCardComponent implements OnInit, OnChanges {

    EMPTY_CHAR = TC.FORMAT.EMPTY_CHAR;

    @Input() showTitle: boolean = true;
    @Input() seasons: Season[] = [];
    @Input() currencyFormat: CurrencyFormat;
    @Input() resourceTypes = [];
    @Input() costingTypes = [];
    @Input() profileTypes = [];

    genResourceCosts: GenResourceCost[] = [];
    costingTypeList: any[] = [];

    displayedColumns: string[] = [
        'seasonName',
        'resourceType',
        'profileType',
        'days',
        'costingType',
        'unitFare',
        'adultFare',
        'childFare',
        'infantFare',
        'teenFare'
    ];
    dataSource = new MatTableDataSource<GenResourceCost>(this.genResourceCosts);

    @ViewChild(MatTable) table: MatTable<TrsResourceCost>;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    constructor(
        private dataStore: DataStoreService,
    ) { }

    ngOnInit() {
        this.grabResourceCostsList();
        this.dataSource.data = this.genResourceCosts;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    }

    grabResourceCostsList() {
        this.genResourceCosts = [];
        if (this.seasons) {
            for (let i = 0; i < this.seasons.length; i++) {
                let season = this.seasons[i];
                for (let j = 0; j < season.genResourceCosts.length; j++) {
                    let resCost = season.genResourceCosts[j];
                    resCost.seasonName = season.name;
                    this.genResourceCosts.push(resCost);
                }
            }
        }
    }

    public ngOnChanges(changes: SimpleChanges): void {
        for (let propName in changes) {
            if (propName === 'seasons') {
                this.grabResourceCostsList();
                this.dataSource.data = this.genResourceCosts;
                this.dataSource.paginator = this.paginator;
                this.dataSource.sort = this.sort;
            }
        }
    }

    public getCostingTypeNameFromList(code: any) {
        this.dataStore.get(DataKey.costingTypes).subscribe(value => {
            if(value && Array.isArray(value))
            {
                this.costingTypeList = value;
            }
            else if (value && value.data && Array.isArray(value.data))
            {
                this.costingTypeList = value.data;
            }
        });
        const type = this.costingTypeList.find(value => value.code === code);
        return type ? type.name : code;
    }

    public getCostingTypeName(code: any) {
        if (this.costingTypes) {
            const type = this.costingTypes.find(value => value.code === code);
            return type ? type.name : code;
        }
        return code;
    }

    public getResourceTypeName(code: any) {
        if (this.resourceTypes) {
            const type = this.resourceTypes.find(value => value.code === code);
            return type ? type.name : code;
        }
        return code;
    }

    public getProfileTypeName(code: any) {
        if (this.profileTypes) {
            const type = this.profileTypes.find(value => value.code === code);
            return type ? type.name : code;
        }
        return code;
    }

}

