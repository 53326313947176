import { Injectable, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { AuthTokenUtil } from './auth-token-util';
import { LoginResponseParams } from './login-response-params';
import { TokenParams } from './token-params';

@Injectable({
    providedIn: 'root'
})
export class AuthTokenService implements OnInit {

    saveToCookies = false;

    constructor(
        private cookieService: CookieService,
    ) {
    }

    LOCAL_AUTHORIZED_KEY = 'authorized';
    LOCAL_USER_NAME_KEY = 'user_name';
    LOCAL_USER_PROFILE_STATUS_KEY = 'approval_status';
    LOCAL_WAITING_VIEW_MESSAGE_KEY = 'message';
    LOCAL_TOKEN_KEY = 'token';
    LOCAL_REFRESH_TOKEN_KEY = 'refreshToken';
    LOCAL_TOKEN_EXPIRE_DATE_KEY = 'tokenExpireDate';
    LOCAL_REFRESH_TOKEN_EXPIRE_DATE_KEY = 'refreshTokenExpireDate';
    LOCAL_AUTHORIZATION_ERROR = 'error';

    authorized: boolean | undefined | null;

    ngOnInit(): void {
        // this.saveToCookies = this.commonService.getTokenSaveConfig() ?
        //     this.commonService.getTokenSaveConfig() :
        //     this.saveToCookies;
    }

    updateTokenParams(tokenParams: TokenParams): boolean {
        if (tokenParams.token) {
            if (this.saveToCookies) {
                this.addTokenToCookie(this.LOCAL_TOKEN_KEY, tokenParams.token, tokenParams.tokenExpireDate);
                // todo: when the refresh token comes to play consider this
                // this.addTokenToCookie(
                //     this.LOCAL_REFRESH_TOKEN_KEY,
                //     tokenParams.refreshToken,
                //     tokenParams.refreshTokenExpireDate
                // );
            } else {
                localStorage.setItem(this.LOCAL_TOKEN_KEY, tokenParams.token);
                localStorage.setItem(this.LOCAL_REFRESH_TOKEN_KEY, tokenParams.refreshToken);
            }
            return true;
        } else {
            return false;
        }
    }

    addTokenToCookie(key: string, token: string, expireDate: string) {
        let expireDateNumber = 0;
        if (expireDate) {
            expireDateNumber = expireDate as unknown as number;
        }
        this.cookieService.set(
            key,
            token,
            new Date(expireDateNumber),
            '',
            '',
            false
        );
    }

    updateTokenParamsByUrl(url: string): boolean {
        const loginResponseParam = new LoginResponseParams();
        const tokenParams = new TokenParams();
        try {

            const authorizedParam = AuthTokenUtil.getQueryParameterByName(url, this.LOCAL_AUTHORIZED_KEY);
            loginResponseParam.authorized = authorizedParam ? JSON.parse(authorizedParam) : undefined;

            if (loginResponseParam.authorized) {
                tokenParams.token = AuthTokenUtil.getQueryParameterByName(url, this.LOCAL_TOKEN_KEY);
                tokenParams.refreshToken = AuthTokenUtil.getQueryParameterByName(url, this.LOCAL_REFRESH_TOKEN_KEY);
                tokenParams.tokenExpireDate = AuthTokenUtil.getQueryParameterByName(
                    url,
                    this.LOCAL_TOKEN_EXPIRE_DATE_KEY
                );
                tokenParams.refreshTokenExpireDate = AuthTokenUtil.getQueryParameterByName(
                    url,
                    this.LOCAL_REFRESH_TOKEN_EXPIRE_DATE_KEY
                );
            } else {
                const userNameParam = AuthTokenUtil.getQueryParameterByName(url, this.LOCAL_USER_NAME_KEY);
                const approvalStatusParam = AuthTokenUtil.getQueryParameterByName(
                    url,
                    this.LOCAL_USER_PROFILE_STATUS_KEY
                );
                const waitingMessageParam = AuthTokenUtil.getQueryParameterByName(
                    url,
                    this.LOCAL_WAITING_VIEW_MESSAGE_KEY
                );
                const authorizedErrorParam = AuthTokenUtil.getQueryParameterByName(url, this.LOCAL_AUTHORIZATION_ERROR);

                loginResponseParam.userName = userNameParam ? userNameParam : undefined;
                loginResponseParam.approvalStatus = approvalStatusParam ? approvalStatusParam : 'PENDING';
                loginResponseParam.message = waitingMessageParam ? waitingMessageParam : undefined;
                loginResponseParam.error = authorizedErrorParam ? authorizedErrorParam : '';
            }
        } catch (err) {
            console.log(err);
        }

        if (loginResponseParam.authorized !== undefined && loginResponseParam.authorized != null) {
            localStorage.setItem(this.LOCAL_AUTHORIZED_KEY, loginResponseParam.authorized as unknown as string);
            localStorage.setItem(this.LOCAL_AUTHORIZED_KEY, loginResponseParam.authorized as unknown as string);
            // this.authorized = loginResponseParam.authorized;
        }
        if (loginResponseParam.authorized && tokenParams.token) {
            if (this.saveToCookies) {
                this.addTokenToCookie(this.LOCAL_TOKEN_KEY, tokenParams.token, tokenParams.tokenExpireDate);
                // todo: when the refresh token comes to play consider this
                // this.addTokenToCookie(
                //     this.LOCAL_REFRESH_TOKEN_KEY,
                //     tokenParams.refreshToken,
                //     tokenParams.refreshTokenExpireDate
                // );
            } else {
                localStorage.setItem(this.LOCAL_TOKEN_KEY, tokenParams.token);
                localStorage.setItem(this.LOCAL_REFRESH_TOKEN_KEY, tokenParams.refreshToken);
                localStorage.setItem(this.LOCAL_TOKEN_EXPIRE_DATE_KEY, tokenParams.tokenExpireDate);
                localStorage.setItem(this.LOCAL_REFRESH_TOKEN_EXPIRE_DATE_KEY, tokenParams.refreshTokenExpireDate);
            }
            return true;
        } else if (loginResponseParam.authorized === false) {
            if (loginResponseParam.userName) {
                localStorage.setItem(this.LOCAL_USER_NAME_KEY, loginResponseParam.userName);
            }
            if (loginResponseParam.approvalStatus) {
                localStorage.setItem(
                    this.LOCAL_USER_PROFILE_STATUS_KEY,
                    loginResponseParam.approvalStatus
                );
            }
            if (loginResponseParam.message) {
                localStorage.setItem(this.LOCAL_WAITING_VIEW_MESSAGE_KEY, loginResponseParam.message);
            }
            return false;
        } else {
            localStorage.setItem(this.LOCAL_AUTHORIZATION_ERROR, loginResponseParam.error);
            return false;
        }
    }

    clearTokenParams() {
        if (this.saveToCookies) {
            this.cookieService.delete(this.LOCAL_TOKEN_KEY);
            this.cookieService.delete(this.LOCAL_REFRESH_TOKEN_KEY);
        } else {
            localStorage.removeItem(this.LOCAL_TOKEN_KEY);
            localStorage.removeItem(this.LOCAL_REFRESH_TOKEN_KEY);
            localStorage.removeItem(this.LOCAL_TOKEN_EXPIRE_DATE_KEY);
            localStorage.removeItem(this.LOCAL_REFRESH_TOKEN_EXPIRE_DATE_KEY);
        }
    }

    getToken() {
        let token;
        if (this.saveToCookies) {
            token = this.cookieService.get(this.LOCAL_TOKEN_KEY);
        } else {
            token = localStorage.getItem(this.LOCAL_TOKEN_KEY);
        }
        return token ? token : undefined;
    }

    isAuthorized(): boolean | undefined {
        const localAuthorized = localStorage.getItem(this.LOCAL_AUTHORIZED_KEY);
        return localAuthorized && localAuthorized !== 'null' ? JSON.parse(localAuthorized) : undefined;
    }

    getAuthorizationError(): string {
        const errorMessage = localStorage.getItem(this.LOCAL_AUTHORIZATION_ERROR);
        return errorMessage ? errorMessage : null;
    }

    getUserName(): string {
        return localStorage.getItem(this.LOCAL_USER_NAME_KEY);
    }

    getApprovalStatus(): string {
        return localStorage.getItem(this.LOCAL_USER_PROFILE_STATUS_KEY);
    }

    getMessage(): string {
        return localStorage.getItem(this.LOCAL_WAITING_VIEW_MESSAGE_KEY);
    }

    getRefreshToken() {
        if (this.saveToCookies) {
            return this.cookieService.get(this.LOCAL_REFRESH_TOKEN_KEY);
        } else {
            return localStorage.getItem(this.LOCAL_REFRESH_TOKEN_KEY);
        }
    }
}
