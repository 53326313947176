<div class="add-document-wrapper">
  <div class="tc-ml-2  tc-mb-2" *ngFor="let txt of roomTypeOptions">
      <mat-checkbox class="example-margin" [checked]="selected.includes(txt['code'])" (change)="addType($event, txt)">{{txt['name']}}</mat-checkbox>
  </div>
</div>

<div class="tc-modal__footer">
  <button class="button-right tc-button-primary tc-flex-item mat-button" (click)="addRooms()">
    Add
  </button>
</div>