import { GenSupplierAllocationSummary } from './gen-supplier-allocation-summary';

export class GenResourceAllocationSummary {
    city: string;
    country: string;
    name: string;
    resourceName: string;
    profileType: string;
    resourceId: number;
    resourceType: string;
    resourceSupplier: GenSupplierAllocationSummary;
    supplier: GenSupplierAllocationSummary;
    nullResourceForClearing: boolean = false;
}
