import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { BootController } from './app/boot-controller';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

const init = () => {
  platformBrowserDynamic().bootstrapModule(AppModule);
};

init();

// Init on reboot request
const boot = BootController.getbootControl().watchReboot().subscribe(() => init());
