<div class="grid-focus-view">
  <div class="tc-px-2">
    <tc-section-heading
            [pageSection]="occupancyHeaderSection"
            (actionClick)="onHeaderActionClick($event)">
    </tc-section-heading>
  </div>
  <ag-grid-angular
          style="height: 400px"
          class="ag-theme-material tc-ag-grid tc-ag-grid--full-width tc-ag-grid--auto-height tc-ag-grid--height-full"
          [rowData]="occupancies"
          [columnDefs]="columnDefs"
          [defaultColDef]="defaultColDef"
          [pagination]="true"
          [paginationAutoPageSize]="true"
          rowSelection="multiple"
          [rowClassRules]="rowClassRules"
          singleClickEdit=true
          [frameworkComponents]="frameworkComponents"
          (gridReady)="onGridReady($event)"
          (selectionChanged)="onSelectionChanged($event)"
          (cellValueChanged)="onCellValueChanged($event)"
          (rowValueChanged)="validateUniqueValues($event)">
  </ag-grid-angular>
</div>

<div class="container assign-panel-footer-section">
  <button class="assign-panel-footer-section__button tc-button-secondary mat-button" (click)="onClose($event)">
    {{'Close' | translate}}
  </button>
  <button
          class="assign-panel-footer-section__button tc-button-primary mat-button"
          (click)="onSave()"
          [disabled]="!isSaveButtonEnable()">
    {{'Save' | translate}}
  </button>
</div>
