import {Injectable} from '@angular/core';
import {BaseGETCriteria} from '@tc-core/model/it/codegen/ui/search/criteria/base-GET-criteria';
import { ResourceSearchCriteria } from '../../../models/criteria/resource-search-criteria';

/**
 * Created by hareen on 6/25/2018
 */

@Injectable()
export class ActionHandlerService {

  public static DATE_PATTERN = '@DATE(([+-]\\\d)([DWMYdwmy]))*';

  /**
   * Fill the given criteria using action data
   * @param {string} data
   * @param {BaseGETCriteria} criteria
   * @returns {BaseGETCriteria}
   */
  public fillCriteriaByActionData(data: string, criteria: any) {
    for (let pair of data.split('&')) {
      let keyValue = pair.split('=');
      console.log(Object.prototype.hasOwnProperty.call(criteria, keyValue[0]));
      console.log(criteria);

      if (Object.prototype.hasOwnProperty.call(criteria, keyValue[0])) {
        let value_ = keyValue[1];
        if (value_.charAt(0) === '@') {
          value_ = this.parseActionQueryData(value_);
        }
        criteria[keyValue[0]] = value_;
      }
    }
  }

  /**
   *
   * @param {string} query
   * @returns {string}
   */
  public parseActionQueryData(query_: string) {
    let regexDate = new RegExp(ActionHandlerService.DATE_PATTERN);
    if (regexDate.exec(query_)) {
      let date = this.resolveDate(query_);
      return date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
    }
  }

  /**
   * Handle all parse calls. Method will call different parsers based on the given query
   * @param data any data need to be parsed
   * @returns {any} parsed data or original data
   */
  parse(data: any): any {
    if (typeof data === 'string') { // should only parse strings
      if (data.charAt(0) === '@') { // should only parse if string starts with a @ sign
        if (data.match('@DATE')) {
          return this.resolveDate(data); // call date parser
        }
      }
    }

    return data;
  }

  /**
   * Parse a date (range) given by string of format '@DATE(operation)(number)(unit)
   * to a CGDate. '@DATE' means the current date. '+' means to the future, i.e. add to the
   * current date. '-' means to the past, i.e. subtract from current date. 'number' defines
   * how many units to go forward or backward. And 'unit' defines the time measurement unit.
   *
   * operation => +|-
   * number => any (reasonable) number
   * unit => D|d|W|w|M|m|Y|y
   *
   * Ex:
   * '@DATE'   : get current date
   * '@DATE+2W': go two weeks forward from current date
   * '@DATE-1Y': go one year backward from current date
   *
   * @param data string needed to be parsed
   * @returns {CGDate} parsed date as CGDate object
   */
  public resolveDate(data: string) {
    let regex = new RegExp(ActionHandlerService.DATE_PATTERN); // RegEx for parsing date
    let date: Date = new Date(); // current date

    let match = regex.exec(data); // split given string

    if (!match) {
      return null;
    }

    // If only @DATE - return date
    if (!match[1]) {
      return date;
    }
    try {
      // try to split into operations, amounts and measurements if any
      let amount = parseInt(match[1], 10);
      let measure = match[2].toUpperCase();

      // add, or subtract dates, weeks, months, or years based on measurement
      switch (measure) {
        case 'D':
          date.setDate(date.getDate() + amount);
          break;
        case 'W':
          date.setDate(date.getDate() + amount * 7); // 7 days per week
          break;
        case 'M':
          date.setMonth(date.getMonth() + amount);
          break;
        case 'Y':
          date.setFullYear(date.getFullYear() + amount);
      }
    } catch (e) {
    }
    return date;
  }


}
