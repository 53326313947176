export enum MainType {
    ANY = 'ANY',
    TRS = 'TRS',
    GEN = 'GEN',
    EXC = 'EXC',
    HTL = 'HTL',
    FLT = 'FLT',
    CRU = 'CRU',
    RAL = 'RAL',
    CAR = 'CAR',
    TOU = 'TOU',
}
