import { Component, Input, OnInit } from '@angular/core';
import { TC } from '@tc-core/util/constants';
import { ServiceInformation } from '../../../models/supplier/service-information';

@Component({
    selector: 'tc-service-info-card',
    templateUrl: './service-info-card.component.html'
})
export class ServiceInfoCardComponent implements OnInit {
    EMPTY_CHAR = TC.FORMAT.EMPTY_CHAR;

    @Input() serviceInformation: ServiceInformation;
    @Input() showTitle: boolean = true;

    constructor() { }

    ngOnInit() {
    }

}
