import { ConfigLoader } from '@tc-core/util/framework';
import { UrlPaths } from '../../backend-consumers/url-paths';
import { DMCQueryParamsService } from '../framework/dmc-query-params.service';
import { RequestService } from '../framework/request.service';
import * as i0 from "@angular/core";
import * as i1 from "@tc-core/util/framework/config-loader.service";
import * as i2 from "../framework/request.service";
import * as i3 from "../framework/dmc-query-params.service";
var LanguageService = /** @class */ (function () {
    function LanguageService(configLoader, requestService, queryParamsService) {
        this.configLoader = configLoader;
        this.requestService = requestService;
        this.queryParamsService = queryParamsService;
        this.languageDirection = 'ltr';
    }
    LanguageService.prototype.checkLangDirection = function (lang) {
        if (lang === 'ar') {
            this.languageDirection = 'rtl';
        }
        else {
            this.languageDirection = 'ltr';
        }
    };
    LanguageService.prototype.getLanguages = function (criteria) {
        var reqPrams = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.get(UrlPaths.OP_DATA_URL_KEY, [UrlPaths.OP_DATA_VERSION, UrlPaths.LANGUAGES], reqPrams);
    };
    LanguageService.ngInjectableDef = i0.defineInjectable({ factory: function LanguageService_Factory() { return new LanguageService(i0.inject(i1.ConfigLoader), i0.inject(i2.RequestService), i0.inject(i3.DMCQueryParamsService)); }, token: LanguageService, providedIn: "root" });
    return LanguageService;
}());
export { LanguageService };
