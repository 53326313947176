import { Component, OnInit, ViewChild } from '@angular/core';
import { TC } from '@tc-core/util';
import { ConfigLoader } from '@tc-core/util/framework';
import { IGetRowsParams } from 'ag-grid-community';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { SetupGridComp } from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid-comp';
import { SetupGridDataSource } from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid-data-source';
import { SetupGridComponent } from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid.component';
import { TCO } from '../../../constants';
import { AccountingRuleSearchCriteria } from '../../../models/criteria/accounting-rule-search-criteria';
import { ResourceTypeSearchCriteria } from '../../../models/criteria/resource-type-search-criteria';
import { SupplierSearchCriteria } from '../../../models/criteria/supplier-search-criteria';
import { SortDirection } from '../../../models/helper/sort-direction';
import { AccountingRule } from '../../../models/ledger/accounting-rule';
import { ServiceAssignment } from '../../../models/reservation/service-assignment';
import { SupplierSummary } from '../../../models/summary/supplier-summary';
import { DataHandlerService } from '../../../services/backend-consumers/master-data-handler-service/data-handler.service';
import { AccountingRuleService } from '../../../services/backend-consumers/setups/accounting-rule.service';
import { ResourceTypeService } from '../../../services/backend-consumers/setups/resource-type.service';
import { SupplierService } from '../../../services/backend-consumers/supplier-service/supplier.service';
import { DataKey, DataStoreService } from '../../../services/util/framework/data-store.service';
import { ResponseUtil } from '../../../services/util/response/response-util.service';
import EMPTY_CHAR = TC.CHIP_INPUT_DEFAULT_VALUES.EMPTY_CHAR;

@Component({
    selector: 'tc-accounting-rules-setup',
    templateUrl: './accounting-rules-setup.component.html'
})
export class AccountingRulesSetupComponent extends SetupGridComp implements OnInit, SetupGridDataSource {

    @ViewChild('setupGrid')
    private setupGrid: SetupGridComponent;
    public colDefConfig = [];

    // search criteria obj. used to
    private accRuleSearchCriteria: AccountingRuleSearchCriteria = new AccountingRuleSearchCriteria();

    // required options for grid editors
    genericTypes: any[] = [];
    GENERIC_CATEGORY = 'generic';

    constructor(
        private configLoader: ConfigLoader,
        private accountingRuleService: AccountingRuleService,
        private dataStoreService: DataStoreService,
        private dataHandlerService: DataHandlerService,
        private resourceTypeService: ResourceTypeService,
        private supplierService: SupplierService
    ) {
        super();
    }

    ngOnInit() {
        this.colDefConfig = this.configLoader.configurations.get(TCO.CONF.CONF_ACCOUNTING_RULE_SETUP);
        this.subscribeSearchCriteria();
        this.fillRequiredGridOptionData();
    }

    /**
     * subscribe search criteria changes in search bar
     * then force grid to refresh data
     * grid refresh will be call getRaws method which is using this subscribed criteria for backend call
     */
    subscribeSearchCriteria() {
        this.dataStoreService.get(DataKey.accountingRuleSearchCriteria).subscribe(value => {
            this.accRuleSearchCriteria = value;
            this.setupGrid.runForceSearch();
        });
    }

    public isInvalidRow(params): boolean {
        const data = params.data;
        if (data) {
            let invalid = true;
            if (
                data['eventId'] > 0 &&
                data['unitId'] > 0 &&
                data['account'] != null &&
                data['account'] != '' &&
                data['drOrCr'] != null &&
                data['drOrCr'] != '' &&
                data['supplierCode'] != null &&
                data['supplierCode'] != '' &&
                data['effectiveFrom'] != null &&
                data['effectiveTo'] != null
            ) {
                invalid = false;
            }
            return invalid;
        } else {
            return false;
        }
    }

    public isUnsavedRow(row: any): boolean {
        return false;
    }

    public deleteRow(row: any) {
        if (row && row.ruleId) {
            return this.accountingRuleService.deleteRow(row.ruleId);
        } else {
            return of('success');
        }
    }

    public saveRow(row: any, event: any) {
        return this.accountingRuleService.saveRow(row);
    }

    public getRows(params: IGetRowsParams): Observable<any> {
        console.log('fetch requesting for ' + params.startRow + ' to ' + params.endRow);
        console.log(params.sortModel);

        const pageSize = params.endRow - params.startRow;
        if (!this.accRuleSearchCriteria) {
            this.accRuleSearchCriteria = new AccountingRuleSearchCriteria();
        }
        this.accRuleSearchCriteria.start = params.startRow;
        this.accRuleSearchCriteria.size = pageSize;
        if (params.sortModel && params.sortModel[0]) {
            this.accRuleSearchCriteria.sortBy = params.sortModel[0].colId;
            if (params.sortModel[0].sort === SetupGridComp.GRID_SORT_ASCENDING) {
                this.accRuleSearchCriteria.sortDirection = SortDirection.ASC;
            } else {
                this.accRuleSearchCriteria.sortDirection = SortDirection.DESC;
            }
        }
        return this.accountingRuleService.getRows(this.accRuleSearchCriteria)
                   .pipe(
                       tap(data =>
                           this.dataStoreService.set(DataKey.accountingRuleSearchResultsForCriteria, data)
                       )
                   );
    }

    public createNewRow(): any {
        return {};
    }

    /*
     grid config methods which are called by grid setup (ag grid)
     and set by grid config
     */

    getAccountingEvents = (params, rowData, text) => {
        return this.dataStoreService.get(DataKey.accountingEvents)
                   .map(
                       aEvents =>
                           aEvents.filter(aEvent => {
                                   if (text) {
                                       if (aEvent.name) {
                                           return aEvent.name.toLowerCase().indexOf(text.toLocaleString()) > -1;
                                       }
                                   } else {
                                       return true;
                                   }
                               }
                           )
                   );
    };

    getAccountingUnits = (params, rowData, text) => {
        return this.dataStoreService.get(DataKey.accountingUnits)
                   .map(
                       aUnits =>
                           aUnits.filter(aUnit => {
                                   if (text) {
                                       if (aUnit.name) {
                                           return aUnit.name.toLowerCase().indexOf(text.toLocaleString()) > -1;
                                       }
                                   } else {
                                       return true;
                                   }
                               }
                           )
                   );
    };

    getLedgerAccounts = (params, rowData, text) => {
        return this.dataStoreService.get(DataKey.ledgerAccounts)
                   .map(
                       lAccounts =>
                           lAccounts.filter(lAccount => {
                                   if (text) {
                                       if (lAccount.name) {
                                           return lAccount.name.toLowerCase().indexOf(text.toLocaleString()) > -1;
                                       }
                                   } else {
                                       return true;
                                   }
                               }
                           )
                   );
    };

    getCurrencies = (params, rowData, text) => {
        return this.dataStoreService.get(DataKey.currencyList)
                   .map(
                       currencies =>
                           currencies.filter(currency => {
                                   if (text) {
                                       if (currency.name) {
                                           return currency.name.toLowerCase().indexOf(text.toLocaleString()) > -1 ||
                                               currency.code.toLowerCase().indexOf(text.toLocaleString()) > -1;
                                       }
                                   } else {
                                       return true;
                                   }
                               }
                           )
                   );
    };

    getEventName = (params) => {
        if (params && params.data && params.data.accountingEvent && params.data.accountingEvent.code) {
            return params.data.accountingEvent.code;
        } else {
            return EMPTY_CHAR;
        }
    };

    getUnitName = (params) => {
        if (params && params.data && params.data.accountingUnit && params.data.accountingUnit.code) {
            return params.data.accountingUnit.code;
        } else {
            return EMPTY_CHAR;
        }
    };

    getAccountCodeName = (params) => {
        if (params && params.data && params.data.ledgerAccount && params.data.ledgerAccount.code) {
            return params.data.ledgerAccount.code + ' | ' + params.data.ledgerAccount.name;
        } else {
            return EMPTY_CHAR;
        }
    };

    onEventValueSet = (params) => {
        if (params.data && params.newValue) {
            params.data['accountingEvent'] = params.newValue;
            params.data['eventId'] = params.newValue['eventId'];
        }
        return true;
    };

    onUnitValueSet = function(params) {
        if (params.data && params.newValue) {
            params.data['accountingUnit'] = params.newValue;
            params.data['unitId'] = params.newValue['unitId'];
        }
        return true;
    };

    onLedgerAccountValueSet = function(params) {
        if (params.data && params.newValue) {
            params.data['ledgerAccount'] = params.newValue;
            params.data['account'] = params.newValue['code'];
        }
        return true;
    };

    onCurrencySet = function(params) {
        if (params.data && params.newValue) {
            if (params.newValue['code']) {
                params.data['currency'] = params.newValue['code'];
            } else {
                params.data['currency'] = params.newValue;
            }
        }
        return true;
    };

    onEffectiveFromDateSet = function(params) {
        if (params.data && params.newValue) {
            params.data['_effectiveFromDate'] = params.newValue;
            params.data['effectiveFrom'] = params.newValue;
        }
        return true;
    };

    onEffectiveToDateSet = function(params) {
        if (params.data && params.newValue) {
            params.data['_effectiveToDate'] = params.newValue;
            params.data['effectiveTo'] = params.newValue;
        }
        return true;
    };

    getSuppliers = (params, rowData, text) => {
        return this.onSupplierTextChange(params, rowData, text);
    };

    public onSupplierTextChange(params: any, rowData: ServiceAssignment, text: string): Observable<any> {
        let supplierSearchCriteria = new SupplierSearchCriteria();
        supplierSearchCriteria.name = text;
        supplierSearchCriteria.status = 'true';

        //supplierSearchCriteria.serviceRequired = 'true';
        supplierSearchCriteria.start = 0;
        supplierSearchCriteria.size = 10;

        return this.supplierService.searchSuppliers(supplierSearchCriteria)
                   .pipe(
                       map(
                           response => {
                               return ResponseUtil.getDataArray(response);
                           }
                       ), catchError(
                           error => {
                               return [];
                           }
                       )
                   );
    }

    onSetSupplier = function(params) {
        let rule: AccountingRule = params.data;
        let supplier: SupplierSummary = params.newValue;

        if (supplier) {
            rule.supplierId = supplier.supplierId;
            rule.supplierCode = supplier.code;
            rule.supplierName = supplier.name;
        }

        return true;
    };

    getSupplierName = function(params) {
        let rule: AccountingRule = params.data;
        if (rule && (rule.supplierName || rule.supplierCode)) {
            return (rule.supplierName ? rule.supplierName : '') + ' | ' + (rule.supplierCode ? rule.supplierCode : '');
        } else {
            return EMPTY_CHAR;
        }
    };

    private fillRequiredGridOptionData() {
        const resourceTypeSearchCriteria = new ResourceTypeSearchCriteria();
        resourceTypeSearchCriteria.size = 1000000;
        this.resourceTypeService.getResourceTypes(resourceTypeSearchCriteria).subscribe(
            result => {
                if (result.data) {
                    result.data.forEach(type => {
                        if (type['resourceCategory'] === this.GENERIC_CATEGORY) {
                            this.genericTypes.push(type);
                        }
                    });
                }
            });
    }

    public cellEditingStart: any = (event) => {
        if (event && event.colDef) {
            if (event.colDef.colId === 'account') {
                this.loadAccounts();
            }
        }
    };

    loadAccounts() {
        const criteria: any = {};
        criteria.size = 1000000;
        this.accountingRuleService.getLedgerAccounts(criteria).subscribe(
            result => {
                this.dataStoreService.set(DataKey.ledgerAccounts, result['data'], true);
            });
    }

}
