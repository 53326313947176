<div class="focus-view__content-wrapper">
    <div class="container-fluid">
        <div class="assign-panel-search">
<!--            <tc-transfer-supplier-search-criteria-->
<!--                    [isSearchBarStyleNotAvailable]="true"-->
<!--                    [searchType]="_searchType"-->
<!--                    [inputSearchCriteria]="supplierSearchCriteria"-->
<!--                    [isDataLoaded]="isDataLoaded"-->
<!--                    [isTriggerSearch]="isTriggerSearch"-->
<!--                    [displayChipsArr]="displayChipsArr"-->
<!--                    [placeHolderText]="placeHolderText"-->
<!--                    [isFocus]="isFocus"-->
<!--                    [isDriverAssignment]="isDriverAssignment"-->
<!--                    [isVehicleAssignment]="isVehicleAssignment"-->
<!--                    [isGenResourceAssignment]="isGenResourceAssignment">-->
<!--            </tc-transfer-supplier-search-criteria>-->
        </div>
    </div>

    <div class="container-fluid">
        <tc-result-header
                [transferModeList]="[]"
                [groupingOptionList]="[]"
                [heading]="heading"
                [sorters]="sorters_"
                (selectSorter)="onSort($event)">
        </tc-result-header>
    </div>

    <div class="container-fluid">
        <tc-adv-supplier-assign-search-result
                [sortingHandler]="sorterService.method"
                [sorter]="sorterService.sorter"
                [sorters]="sorters_"
                [pager]="pager"
                [resultsList]="contentCardDataList"
                [selectedSupplier]="selectedSummaryCardData"
                [bookingData]="isGenResourceAssignment?genTourItem:bookingData"
                [bookingDataArray]="bookingDataArray"
                [isBulkAssignment]="isBulkAssignment"
                [isGenResourceAssignment]="isGenResourceAssignment"
                [assignedSupplier]="selectedSupplier"
                (supplierSelect)="onSupplierSelect($event)"
                (cardScroll)="onCardScroll($event)">
        </tc-adv-supplier-assign-search-result>
    </div>
    <tc-paginator
            *ngIf="contentCardDataList.length > defaultPageResultCount"
            [length]="contentCardDataList.length"
            [index]="pager.currPage"
            [size]="pager.itemsPerPage"
            [hasMore]="hasMore"
            (page)="onPage($event)"
            (more)="onMore()">
    </tc-paginator>

</div>
