import * as tslib_1 from "tslib";
import { OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DialogModel, LEVEL } from '@tc-core/model/it/codegen/ui/framework/dialog-model';
import { ModalData } from '@tc-core/model/it/codegen/ui/framework/modal-data';
import { ConfigLoader } from '@tc-core/util/framework';
import { SpinnerService } from '@tc-core/util/ui/spinner.service';
import { DialogService } from '@tc/dialog/dialog.service';
import { FocusViewService } from '@tc/focus-view/focus-view.service';
import { Subscription } from 'rxjs/index';
import { tap } from 'rxjs/operators';
import { SetupGridComp } from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid-comp';
import { SetupGridComponent } from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid.component';
import { TCO } from '../../../constants';
import { DispatchStatus } from '../../../models/document/dispatch-entry';
import { SortDirection } from '../../../models/helper/sort-direction';
import { DocumentQueueService } from '../../../services/backend-consumers/documents/document-queue.service';
import { DataHandlerService } from '../../../services/backend-consumers/master-data-handler-service/data-handler.service';
import { DataKey, DataStoreService } from '../../../services/util/framework/data-store.service';
import { SetupCommonDataProcessorService } from '../../../services/util/pre-processors/setup-common-data-processor.service';
import { ResponseUtil } from '../../../services/util/response/response-util.service';
import { DocumentQueueFocusViewComponent } from '../document-queue-focus-view/document-queue-focus-view.component';
import { DMCCommon } from '../../../services/util/common/dmc-common';
import { TcErrorType } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-error';
import { BackgroundTaskService } from '../../../services/util/change-detector/background-task.service';
import { TaskStatus } from "../../../models/background/task-status";
var DocumentQueueResultsComponent = /** @class */ (function (_super) {
    tslib_1.__extends(DocumentQueueResultsComponent, _super);
    function DocumentQueueResultsComponent(configLoader, dataStoreService, dataHandlerService, documentQueueService, activatedRoute, focusViewService, setupCommonDataProcessorService, spinnerService, dialogService, common, backgroundTaskService) {
        var _this = _super.call(this) || this;
        _this.configLoader = configLoader;
        _this.dataStoreService = dataStoreService;
        _this.dataHandlerService = dataHandlerService;
        _this.documentQueueService = documentQueueService;
        _this.activatedRoute = activatedRoute;
        _this.focusViewService = focusViewService;
        _this.setupCommonDataProcessorService = setupCommonDataProcessorService;
        _this.spinnerService = spinnerService;
        _this.dialogService = dialogService;
        _this.common = common;
        _this.backgroundTaskService = backgroundTaskService;
        _this.colDefConfig = [];
        _this.gridActions = [];
        _this.documentQueueSearchResults = [];
        _this.searchCriteriaObserver = new Subscription();
        _this.focusViewDataChangeObserver = new Subscription();
        _this.focusViewConfirmObserver = new Subscription();
        _this.dialogSubscription = new Subscription();
        _this.docQueueResultsSubscription = new Subscription();
        _this.dialogSubscription1 = new Subscription();
        _this.isFocusViewDataChanged = false;
        // Send action on individual items
        _this.clickSendAction = function (params) {
            if (_this.backgroundTaskService.isTaskRunning()) {
                _this.common.showSnackBar('Already a background task is being in progress. Please wait for the completion or stop notifying the task status.', 5000, TcErrorType.TYPE.ERROR);
                return;
            }
            var confirmSendManifests = new DialogModel(true, LEVEL.WARNING, 'Send Manifests', 'Do you want to send manifest for this item? Task may be handled in background.', true, 2000, null, 'Cancel', 'Send', true);
            confirmSendManifests.disableClose = true;
            if (_this.dialogSubscription) {
                _this.dialogSubscription.unsubscribe();
            }
            var dialogSubscription = _this.dialogService
                .confirm(confirmSendManifests)
                .subscribe(function (res) {
                _this.spinnerService.show();
                dialogSubscription.unsubscribe();
                if (res === true) {
                    var documentQueueGroupList = [];
                    var documentQueueGroup = params.data;
                    if (documentQueueGroup) {
                        documentQueueGroupList.push(documentQueueGroup);
                        _this.docQueueResultsSubscription = _this.documentQueueService.patchDispatchEntry(documentQueueGroupList)
                            .subscribe(function (response) {
                            if (response && response.data && response.data[0] && response.data[0].taskStatus) {
                                var backgroundTask = response.data[0];
                                _this.backgroundTaskService.startStatusChecking(backgroundTask);
                                _this.common.showSnackBar(
                                // tslint:disable-next-line:max-line-length
                                "Transfer manifest generation background task id: " + backgroundTask.taskId + " is started. You will be notified on the generation status.", 5000, TcErrorType.TYPE.INFO);
                            }
                            else if (response && response.status && response.status.code === 1) {
                                _this.common.showSnackBar(
                                // tslint:disable-next-line:max-line-length
                                "Transfer manifest generation is successful.", 5000, TcErrorType.TYPE.INFO);
                                _this.setupGrid.runForceSearch();
                            }
                            else if (response && response.status && response.status.code === -1) {
                                _this.errorMessage();
                                _this.setupGrid.runForceSearch();
                            }
                            _this.spinnerService.hide();
                        }, function (error) {
                            _this.errorMessage();
                            _this.setupGrid.runForceSearch();
                            _this.spinnerService.hide();
                        }, function () {
                        });
                    }
                    _this.spinnerService.hide();
                }
                _this.spinnerService.hide();
            });
        };
        _this.isDisableKebabClick = function (params) {
            return _this.checkRowDisabled(params.data);
        };
        _this.isDisableCheckBox = function (params) {
            return _this.checkRowDisabled(params.data);
        };
        /*
         grid config methods which are called by grid setup (ag grid)
         and set by grid config
         */
        _this.statusSuccess = function (params) {
            return params.data && params.data.dispatchStatus && params.data.dispatchStatus === DispatchStatus.SUCCESS;
        };
        _this.statusPending = function (params) {
            return params.data && params.data.dispatchStatus && params.data.dispatchStatus === DispatchStatus.PENDING;
        };
        _this.statusFailed = function (params) {
            return params.data && params.data.dispatchStatus && params.data.dispatchStatus === DispatchStatus.FAILED;
        };
        _this.statusIgnored = function (params) {
            return params.data && params.data.dispatchStatus && params.data.dispatchStatus === DispatchStatus.IGNORED;
        };
        _this.statusGenerated = function (params) {
            return params.data && params.data.dispatchStatus && params.data.dispatchStatus === DispatchStatus.GENERATED;
        };
        return _this;
    }
    DocumentQueueResultsComponent.prototype.ngOnInit = function () {
        this.colDefConfig = this.loadGridConfig();
        this.subscribeSearchCriteria();
        this.fillRequiredGridOptionData();
        this.addGridActions();
        this.refreshGrid();
    };
    /**
     * subscribe search criteria changes in search bar
     * then force grid to refresh data
     * grid refresh will be call getRaws method which is using this subscribed criteria for backend call
     */
    DocumentQueueResultsComponent.prototype.loadGridConfig = function () {
        return this.configLoader.configurations.get(TCO.CONF.CONF_DOCUMENT_QUEUE_COL_DEF_CONFIG);
    };
    DocumentQueueResultsComponent.prototype.fillRequiredGridOptionData = function () {
        // nothing
    };
    DocumentQueueResultsComponent.prototype.isDisabledRow = function (params) {
        var data = params.data;
        if (data) {
            var invalid = true;
            if (data.dispatchStatus !== DispatchStatus.SUCCESS
                && data.dispatchStatus !== DispatchStatus.GENERATED) {
                invalid = false;
            }
            return invalid;
        }
        else {
            return false;
        }
    };
    DocumentQueueResultsComponent.prototype.isEditableRow = function (params) {
        var data = params.data;
        if (data) {
            var invalid = true;
            if (data.dispatchStatus !== DispatchStatus.SUCCESS
                && data.dispatchStatus !== DispatchStatus.GENERATED) {
                invalid = false;
            }
            return !invalid;
        }
        else {
            return true;
        }
    };
    DocumentQueueResultsComponent.prototype.getSelectedRows = function () {
        var selectedNodes = [];
        if (this.setupGrid && this.setupGrid.gridApi) {
            this.setupGrid.gridApi.forEachNode(function (node) {
                if (node && node.data && node.data.selected) {
                    selectedNodes.push(node.data);
                }
            });
        }
        return selectedNodes;
    };
    DocumentQueueResultsComponent.prototype.createNewRow = function () {
        return { unsaved: true };
    };
    DocumentQueueResultsComponent.prototype.isUnsavedRow = function (row) {
        return !!row.unsaved;
    };
    DocumentQueueResultsComponent.prototype.deleteRow = function (row) {
        return null;
    };
    DocumentQueueResultsComponent.prototype.saveRow = function (row, event) {
        return null;
    };
    // search criteria subscription
    DocumentQueueResultsComponent.prototype.subscribeSearchCriteria = function () {
        var _this = this;
        this.searchCriteriaObserver = this.dataStoreService.get(DataKey.documentQueueSearchCriteria)
            .subscribe(function (value) {
            _this.documentQueueSearchCriteria = value;
            if (_this.documentQueueSearchCriteria) {
                _this.setupGrid.runForceSearch();
            }
        });
    };
    DocumentQueueResultsComponent.prototype.refreshGridView = function () {
        var _this = this;
        if (this.gridApi) {
            setTimeout(function () {
                _this.gridApi.refreshCells({ force: true });
                _this.gridApi.setRowData(_this.documentQueueSearchResults);
            }, 0);
        }
    };
    DocumentQueueResultsComponent.prototype.refreshGrid = function () {
        var _this = this;
        this.documentQueueService.getTaskStatus().subscribe(function (taskStatus) {
            if (taskStatus && taskStatus.TaskStatus === TaskStatus.FINISHED) {
                _this.setupGrid.runForceSearch();
                _this.documentQueueService.clearTaskStatus();
            }
        });
    };
    // retrieve grid data
    DocumentQueueResultsComponent.prototype.getRows = function (params) {
        var _this = this;
        console.log('fetch requesting for ' + params.startRow + ' to ' + params.endRow);
        var pageSize = params.endRow - params.startRow;
        this.documentQueueSearchCriteria.start = params.startRow;
        this.documentQueueSearchCriteria.size = pageSize;
        if (params.sortModel && params.sortModel[0]) {
            this.documentQueueSearchCriteria.sortBy = params.sortModel[0].colId;
            if (params.sortModel[0].sort === SetupGridComp.GRID_SORT_ASCENDING) {
                this.documentQueueSearchCriteria.sortDirection = SortDirection.ASC;
            }
            else {
                this.documentQueueSearchCriteria.sortDirection = SortDirection.DESC;
            }
        }
        return this.documentQueueService.searchDocumentQueues(this.documentQueueSearchCriteria)
            .pipe(tap(function (data) {
            _this.dataStoreService.set(DataKey.documentQueueSearchResultsForCriteria, data);
            _this.documentQueueSearchResults = ResponseUtil.getDataArray(data);
            _this.refreshGridView();
        }));
    };
    // view document queue focus view
    DocumentQueueResultsComponent.prototype.onViewClick = function (row) {
        this.onViewDocumentQueueDetails(row.data);
    };
    DocumentQueueResultsComponent.prototype.onDeleteClick = function (row) {
    };
    DocumentQueueResultsComponent.prototype.onViewDocumentQueueDetails = function (data) {
        var _this = this;
        this.focusViewDataObserver();
        var fabActions = [];
        var navItemsArray = this.configLoader.configurations.get(TCO.CONF.CONF_NAVIGATION);
        this.configLoader.configurations.get('FOCUS_VIEW_CONFIG').DOCUMENT_QUEUE_FOCUS_VIEW.fabAction
            .forEach(function (val) {
            for (var key in navItemsArray) {
                if (val === navItemsArray[key].id) {
                    fabActions.push(navItemsArray[key]);
                }
            }
        });
        var dataObject = new ModalData(LEVEL.SUCCESS, 'Document Queue Details', null, null, 'grid-focus-view', DocumentQueueFocusViewComponent, {
            documentQueue: data.documentQueueSummaries
        }, { label: 'close' }, '', '', fabActions, null, 'Name : ' + data.supplierName + ' - ' +
            this.setupCommonDataProcessorService.dateValueConvertor(data.scheduledTime));
        dataObject.disableClose = true;
        this.focusViewConfirmObserver = this.focusViewService.confirm(dataObject)
            .subscribe(function (res) {
            if (_this.isFocusViewDataChanged) {
                // after view closed
                _this.setupGrid.runForceSearch();
            }
        });
    };
    // add grid actions
    DocumentQueueResultsComponent.prototype.addGridActions = function () {
        this.gridActions.push({
            kebabIcon: 'more_vert',
            kebabDisable: this.isDisableKebabClick.bind(this),
            kebabTooltip: 'more actions',
            kebabActions: [
                // {
                //     actionName: 'View',
                //     icon: 'visibility',
                //     action: this.onViewClick.bind(this),
                //     disable: false,
                //     tooltip: ''
                // },
                {
                    actionName: 'Send',
                    icon: 'send',
                    action: this.clickSendAction.bind(this),
                    disable: false,
                    tooltip: ''
                }
                // {
                //     actionName: 'Delete',
                //     icon: 'delete',
                //     action: this.onDeleteClick.bind(this),
                //     disable: false,
                //     tooltip: ''
                // }
            ]
        });
    };
    DocumentQueueResultsComponent.prototype.errorMessage = function () {
        this.common.showSnackBar(
        // tslint:disable-next-line:max-line-length
        "One or more transfer manifest email sending has been failed.", 5000, TcErrorType.TYPE.ERROR);
    };
    DocumentQueueResultsComponent.prototype.checkRowDisabled = function (data) {
        var event = {
            data: data
        };
        return this.isDisabledRow(event);
    };
    DocumentQueueResultsComponent.prototype.focusViewDataObserver = function () {
        var _this = this;
        this.focusViewDataChangeObserver = this.dataStoreService.get(DataKey.docDetailsFocusViewDataChanged)
            .subscribe(function (isDataChanged) {
            if (isDataChanged) {
                _this.focusViewDataChangeObserver.unsubscribe();
            }
            _this.isFocusViewDataChanged = isDataChanged;
        });
    };
    // Bulk send
    DocumentQueueResultsComponent.prototype.onBulkSendClick = function (event) {
        var _this = this;
        if (this.backgroundTaskService.isTaskRunning()) {
            this.common.showSnackBar('Already a background task is being in progress. Please wait for the completion or stop notifying the task status.', 5000, TcErrorType.TYPE.ERROR);
            return;
        }
        // this.spinnerService.show();
        var results = this.getSelectedRows();
        var message = '';
        if (results && results.length > 0) {
            message = 'Do you want to send manifests for selected items? Task may be handled in background.';
        }
        else if (results && results.length === 0) {
            message = 'Do you want to send manifest for all the dispatch entries listed in the grid? Task may be handled in background.';
        }
        var confirmSendManifests = new DialogModel(true, LEVEL.WARNING, 'Send Manifests', message, true, 2000, null, 'Cancel', 'Send', true);
        confirmSendManifests.disableClose = true;
        if (this.dialogSubscription) {
            this.dialogSubscription.unsubscribe();
        }
        var nonSuccessEntry = false;
        if (this.documentQueueSearchResults) {
            this.documentQueueSearchResults.forEach(function (value) {
                if (value && value.dispatchStatus && value.dispatchStatus
                    !== DispatchStatus.SUCCESS && value.dispatchStatus !== DispatchStatus.GENERATED) {
                    nonSuccessEntry = true;
                    return;
                }
            });
        }
        if (nonSuccessEntry) {
            var dialogSubscription_1 = this.dialogService
                .confirm(confirmSendManifests)
                .subscribe(function (res) {
                _this.spinnerService.show();
                dialogSubscription_1.unsubscribe();
                if (res === true) {
                    if (results && results.length > 0) {
                        var documentQueueGroupList_1 = [];
                        results.forEach(function (item) {
                            var documentQueueGroup = item;
                            documentQueueGroupList_1.push(documentQueueGroup);
                        });
                        _this.docQueueResultsSubscription = _this.documentQueueService.patchDispatchEntry(documentQueueGroupList_1)
                            .subscribe(function (response) {
                            if (response && response.data && response.data[0] && response.data[0].taskStatus) {
                                var backgroundTask = response.data[0];
                                _this.backgroundTaskService.startStatusChecking(backgroundTask);
                                _this.common.showSnackBar(
                                // tslint:disable-next-line:max-line-length
                                "Transfer manifest generation background task id: " + backgroundTask.taskId + " is started. You will be notified on the generation status.", 5000, TcErrorType.TYPE.INFO);
                            }
                            else if (response && response.status && response.status.code === 1) {
                                _this.common.showSnackBar(
                                // tslint:disable-next-line:max-line-length
                                "Transfer manifest generation is successful.", 5000, TcErrorType.TYPE.INFO);
                                _this.setupGrid.runForceSearch();
                            }
                            else if (response && response.status && response.status.code === -1) {
                                _this.common.showSnackBar(
                                // tslint:disable-next-line:max-line-length
                                "Transfer manifest generation failed.", 5000, TcErrorType.TYPE.ERROR);
                                _this.setupGrid.runForceSearch();
                            }
                            _this.spinnerService.hide();
                        }, function (error) {
                            _this.errorMessage();
                            _this.setupGrid.runForceSearch();
                            _this.spinnerService.hide();
                        }, function () {
                        });
                    }
                    else if (results && results.length === 0 && _this.documentQueueSearchResults) {
                        _this.docQueueResultsSubscription = _this.documentQueueService.patchDispatchEntry(_this.documentQueueSearchResults)
                            .subscribe(function (response) {
                            if (response && response.data && response.data[0] && response.data[0].taskStatus) {
                                var backgroundTask = response.data[0];
                                _this.backgroundTaskService.startStatusChecking(backgroundTask);
                                _this.common.showSnackBar(
                                // tslint:disable-next-line:max-line-length
                                "Transfer manifest generation background task id: " + backgroundTask.taskId + " is started. You will be notified on the generation status.", 5000, TcErrorType.TYPE.INFO);
                            }
                            else if (response && response.status && response.status.code === 1) {
                                _this.common.showSnackBar(
                                // tslint:disable-next-line:max-line-length
                                "Transfer manifest generation is successful.", 5000, TcErrorType.TYPE.INFO);
                                _this.setupGrid.runForceSearch();
                            }
                            else if (response && response.status && response.status.code === -1) {
                                _this.errorMessage();
                                _this.setupGrid.runForceSearch();
                            }
                            _this.spinnerService.hide();
                            // this.refreshGrid();
                        }, function (error) {
                            _this.errorMessage();
                            _this.setupGrid.runForceSearch();
                            _this.spinnerService.hide();
                        }, function () {
                        });
                    }
                }
                _this.spinnerService.hide();
            });
        }
        else {
            var confirmUnableSendManifests = new DialogModel(true, LEVEL.WARNING, 'Send Manifests', 'Manifests cannot be generated for SUCCESS or GENERATED dispatch entries', true, 2000, null, '', 'Ok', true);
            confirmUnableSendManifests.disableClose = true;
            if (this.dialogSubscription) {
                this.dialogSubscription.unsubscribe();
            }
            var dialogSubscription = this.dialogService
                .confirm(confirmUnableSendManifests)
                .subscribe(function (res) {
            });
        }
    };
    DocumentQueueResultsComponent.prototype.ngOnDestroy = function () {
        if (this.searchCriteriaObserver) {
            this.searchCriteriaObserver.unsubscribe();
        }
        if (this.dialogSubscription) {
            this.dialogSubscription.unsubscribe();
        }
        if (this.focusViewConfirmObserver) {
            this.focusViewConfirmObserver.unsubscribe();
        }
        if (this.docQueueResultsSubscription) {
            this.docQueueResultsSubscription.unsubscribe();
        }
    };
    DocumentQueueResultsComponent.prototype.isInvalidRow = function (params) {
        return false;
    };
    return DocumentQueueResultsComponent;
}(SetupGridComp));
export { DocumentQueueResultsComponent };
