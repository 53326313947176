<div class="rs-section-wrapper container-fluid">
    <!--<h5 class="ecshc-content-wrapper__heading">Service Items</h5>-->
    <div class="rs-item-header tc-flex tc-flex--center-vertical tc-flex--space-between">
        <div class="tc-flex-item">
            <div class="">
                <mat-checkbox
                        [disabled]="isDisabledCheckbox()"
                        [(ngModel)]="selectedAll"
                        (change)="checkAllChange($event)"
                        class="rs-item-header__checkbox"> {{'Select All' | translate}}
                </mat-checkbox>
            </div>
        </div>
        <div class="tc-flex-item tc-flex">
            <div matTooltip="{{'Auto-Assign' | translate}}">
                <button mat-icon-button color="primary" aria-label="AutoAssign"
                        (click)="onAutoAssignResources()"
                        [disabled]="isDisabledAutoAssign()">
                    <i class="material-icons">psychology</i>
                </button>
            </div>
            <div matTooltip="{{'Vehicle Assign' | translate}}">
                <button mat-icon-button color="primary" aria-label="Vehicle Assign"
                        (click)="onBulkVehicleAssignment()"
                        [disabled]="isDisabledAssign()">
                    <i class="material-icons">directions_car</i>
                </button>
            </div>

            <div matTooltip="{{'Driver Assign' | translate}}">
                <button mat-icon-button color="primary" aria-label="Driver Assign"
                        (click)="onBulkDriverAssignment()"
                        [disabled]="isDisabledAssign()">
                    <i class="material-icons">airline_seat_recline_normal</i>
                </button>
            </div>

            <div matTooltip="{{'Merge' | translate}}">
                <button mat-icon-button color="primary" aria-label="Merge"
                        (click)="onClickMerge()"
                        [disabled]="isDisabledMerge()">
                    <i class="material-icons">merge_type</i>
                </button>
            </div>

            <div matTooltip="{{'Confirm' | translate}}">
                <button mat-icon-button color="primary" aria-label="Confirm"
                        (click)="onClickConfirm()"
                        [disabled]="isDisabledConfirm()">
                    <i class="material-icons">thumb_up</i>
                </button>
            </div>
            <div matTooltip="{{'Supplier Reject' | translate}}">
                <button mat-icon-button color="primary" aria-label="reject"
                        (click)="onClickSupplierReject()"
                        [disabled]="isDisabledSupplierReject()">
                    <i class="material-icons">app_blocking</i>
                </button>
            </div>

            <div matTooltip="{{'Save' | translate}}">
                <button mat-icon-button color="primary" aria-label="Save"
                        (click)="onSaveAssignments()"
                        [disabled]="isDisabledSave()">
                    <i class="material-icons">save</i>
                </button>
            </div>
        </div>
        <!--<div>Service Items: {{contentCardDataList.length}}</div>-->
    </div>

    <div class="" *ngIf="contentCardDataList">
        <div class="rs-item-card__container">
            <ng-container *ngFor="let serviceItemCard of contentCardDataList; let i = index;">
                <div
                        tcExpansionPanel
                        tabindex="-1"
                        [enabled]="true"
                        (collapse)="expandedPanelIndex = -1">

                    <!-- Content card Strip -->
                    <div id="clientSumExp_{{i}}">

                        <!--<tc-content-strip-->
                        <!--(actionClick)="onActionClick(serviceItemCard,$event)"-->
                        <!--(click)="onServiceItemSelection(serviceItemCard,i)">-->

                        <tc-reservation-service-item
                                [summaryContent]="serviceItemCard"
                                [autoAssign]="isAutoAssignEnabled"
                                [isSideIcon]="isPrivate(serviceItemCard)"
                                [isSavedChild]="isSaved"
                                [changeCorner]="isSplit(serviceItemCard)"
                                [serviceItemChangesInSelectedGroup]="serviceItemChangesInSelectedGroup"
                                [filteredVehicleSuppliers]="filteredVehicleSuppliers"
                                [filteredDriverSuppliers]="filteredDriverSuppliers"
                                [filteredVehicles]="filteredVehicles"
                                [filteredDrivers]="filteredDrivers"
                                (vehicleSupplierAssign)="onSelectVehicleSupplier($event)"
                                (driverSupplierAssign)="onSelectDriverSupplier($event)"
                                (vehicleAssign)="onSelectVehicle($event)"
                                (driverAssign)="onSelectDriver($event)"
                                (vehicleSupplierClear)="clearVehicleSupplier($event)"
                                (driverSupplierClear)="clearDriverSupplier($event)"
                                (vehicleClear)="clearVehicle($event)"
                                (driverClear)="clearDriver($event)"
                                (driverSupplierTextChange)="onDriverSupplierTextChange($event)"
                                (vehicleSupplierTextChange)="onVehicleSupplierTextChange($event)"
                                (vehicleTextChange)="onVehicleTextChange($event)"
                                (driverTextChange)="onDriverTextChange($event)"
                                (vehicleTextFocus)="onVehicleFocus($event)"
                                (vehicleJobNoChange)="onVehicleJobNoChange($event)"
                                (driverJobNoChange)="onDriverJobNoChange($event)"
                                (clearVehicleAssignment)="onClearVehicleAssignment($event)"
                                (clearDriverAssignment)="onClearDriverAssignment($event)"
                                (moreClick)="onMoreClick($event, serviceItemCard)"
                                (splitClick)="onSplitClick($event)"
                                (leadPassengerClick)="onLeadPassengerSelect(serviceItemCard)"
                                (checkChanged)="onItemCheckChanged($event)"
                                (viewMoreFocus)="onViewMoreClick($event)"
                                (historyClick)="onHistoryClick($event)">
                        </tc-reservation-service-item>

                        <!--</tc-content-strip>-->

                    </div>
                </div>
            </ng-container>
        </div>
        <div class="rs-footer-section">
            <!--            <button-->
            <!--                    class="tc-button-primary mat-button rs-footer-section__button"-->
            <!--                    (click)="onAutoAssignResources()"-->
            <!--                    [disabled]="!isAutoAssignEnabled">-->
            <!--                AutoAssign-->
            <!--            </button>-->
            <!--            <button-->
            <!--                    class="tc-button-primary mat-button rs-footer-section__button"-->
            <!--                    (click)="onClickConfirm()"-->
            <!--                    [disabled]="isDisabledConfirm()">-->
            <!--                Confirm-->
            <!--            </button>-->
            <!--            <button-->
            <!--                    class="tc-button-primary mat-button rs-footer-section__button"-->
            <!--                    (click)="onClickMerge()"-->
            <!--                    [disabled]="isDisabledMerge()">-->
            <!--                Merge-->
            <!--            </button>-->
            <!--            <button-->
            <!--                    class="tc-button-primary mat-button rs-footer-section__button"-->
            <!--                    (click)="onSaveAssignments()"-->
            <!--                    [disabled]="isDisabledSave()">-->
            <!--                Save-->
            <!--            </button>-->
            <!--            <button-->
            <!--                    class="tc-button-primary mat-button rs-footer-section__button"-->
            <!--                    (click)="onBulkVehicleAssignment()"-->
            <!--                    [disabled]="isDisabledAssign()">-->
            <!--                Vehicle Assign-->
            <!--            </button>-->
            <!--            <button-->
            <!--                    class="tc-button-primary mat-button rs-footer-section__button"-->
            <!--                    (click)="onBulkDriverAssignment()"-->
            <!--                    [disabled]="isDisabledAssign()">-->
            <!--                Driver Assign-->
            <!--            </button>-->


        </div>
    </div>
</div>
