import * as tslib_1 from "tslib";
import { AbstractSearchCriteria } from './abstract-search-criteria';
var LedgerAccountSearchCriteria = /** @class */ (function (_super) {
    tslib_1.__extends(LedgerAccountSearchCriteria, _super);
    function LedgerAccountSearchCriteria() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return LedgerAccountSearchCriteria;
}(AbstractSearchCriteria));
export { LedgerAccountSearchCriteria };
