<div class="booking-notes-summary ">
</div>
<div
        tcExpansionPanel
        [include]="['include', 'add-note-cage']"
        [enabled]="noteList && noteList?.length > 0"
        class="booking-notes-summary-row booking-detail-block">

    <div *ngIf="noteList && noteList.length === 0">
        <div *tcExpansionPanelSummary>
            <tc-summary-strip
                    class="bns-summary-strip-wrapper"
                    *ngIf="noteSummary!=null"
                    [summaryContent]="noteSummary">
            </tc-summary-strip>
        </div>
    </div>
    <div *ngIf="noteList && noteList?.length > 0">
        <mat-accordion
                *tcExpansionPanelSummary
                class="booking-notes-list include">

            <ng-container *ngFor="let summaryContentData of noteContents;let i=index">
                <div
                        class="mat-expansion-content-show notes-summary-row"
                        [ngClass]="i==0 ? 'notes-summary-row--first' : i==noteContents.length-1 ? 'notes-summary-row--last':'notes-summary-row--expanded'">
                    <mat-expansion-panel
                            [attr.id]="'expansionPanel-'+i"
                            [hideToggle]="hideToggle"
                            (opened)="onPanelExpand(summaryContentData,i)"
                            #expPanel>
                        <mat-expansion-panel-header
                                [collapsedHeight]="'48px'"
                                [expandedHeight]="'60px'">

                            <tc-single-line-strip
                                    class="bns-summary-strip-wrapper"
                                    *ngIf="!expPanel.expanded"
                                    [summaryContent]="summaryContentData.header">
                            </tc-single-line-strip>

                            <tc-expanded-content-header-card
                                    *ngIf="expPanel.expanded"
                                    [headerText]="summaryContentData?.summary?.row1col1?.value"
                                    [secondaryHeaderText]="summaryContentData?.summary?.row1col2.value"
                                    [icon]="summaryContentData.header.icon"
                                    [enableItemDelete]="!isConfirmed && isEditable(summaryContentData?.summary?.row1col1?.value)"
                                    [enableItemEdit]="!isConfirmed && isEditable(summaryContentData?.summary?.row1col1?.value)"
                                    [dataRemoveKey]="summaryContentData?.header?.data?.value?.serviceNoteId"
                                    [dataEditKey]="summaryContentData?.header?.data?.value?.serviceNoteId"
                                    [editToolTip]="editToolTip"
                                    [deleteToolTip]="deleteToolTip"
                                    (headerClick)="onHeaderClick()"
                                    (clickOutSide)="onOutsideClick()"
                                    (itemDeleteClick)="onDeleteClick(summaryContentData.header)"
                                    (itemEditClick)="onEditClick(summaryContentData.header)">
                            </tc-expanded-content-header-card>

                        </mat-expansion-panel-header>

                        <div class="note-detail container-fluid tc-card-wrapper">

                            <!--Notes-->
                            <div #noteBlock class="notes">
                                <div
                                        class="row item-summary-card__row item-summary-card__row--1">
                                    <div class="item-summary-card__column item-summary-card__column--1">
                                        <div class="item-summary-card__icon-cage">
                                            <div class="item-summary-card__icon-box">
                                                <i class="material-icons item-summary-card__icon">info</i>
                                            </div>
                                        </div>
                                        <div class="row item-summary-card__content-cage">
                                            <div class="item-summary-card-sub-column tc-input-view">
                                                <div
                                                        class="tc-input-view__value tc-input-view__value--regular">{{summaryContentData?.header?.data?.value?.note ? summaryContentData?.header?.data?.value?.note : EMPTY_CHAR}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </mat-expansion-panel>
                </div>
            </ng-container>

        </mat-accordion>
    </div>
</div>
