import { Component, OnInit } from '@angular/core';
import { ReservationStatus } from '../../../models/reservation/service-item';
import { ResultContentCardComponent } from '../result-content-card/result-content-card.component';

@Component({
  selector: 'tc-result-content-card-reservations',
  templateUrl: '../result-content-card/result-content-card.component.html'
})
export class ResultContentCardReservationsComponent extends ResultContentCardComponent implements OnInit {

  constructor() {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.overrideStyles();

    // reservation status
    if (this.summaryContent.data.value['reservationStatus']) {
      if (this.summaryContent.data.value['reservationStatus'] === ReservationStatus.CANCELLED) {
        this.isCancelled = true;
      }else if(this.summaryContent.data.value['reservationStatus'] === ReservationStatus.UPDATED){
        this.isUpdated = true;
      }
    }

  }

  private overrideStyles(){
    this.row1col1_customsStyles = ["tc-strip-text--primary"];
    this.row1col2_customsStyles = ["tc-strip-text--primary"];
    this.row1col3_customsStyles = ["tc-strip-text--primary"];
    this.row1col4_customsStyles = ["tc-strip-text--primary", "tc-strip-text--right"];

    this.row2col1_customsStyles = [];
    this.row2col2_customsStyles = [];
    this.row2col3_customsStyles = ["tc-strip-text--primary"];
    this.row2col4_customsStyles = ["tc-strip-text--right"];
  }

}
