import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaymentInformationComponent } from './payment-information.component';
import {
    MatAutocompleteModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatNativeDateModule,
    MatOptionModule,
    MatSelectModule,
    MatTooltipModule
} from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
    imports: [
        CommonModule,
        MatAutocompleteModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatDatepickerModule,
        MatDialogModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule, MatMenuModule,
        MatNativeDateModule,
        MatOptionModule,
        MatSelectModule, MatTooltipModule,
        FormsModule,
        ReactiveFormsModule
    ],
    declarations: [PaymentInformationComponent],
    exports: [PaymentInformationComponent]
})
export class PaymentInformationModule {}
