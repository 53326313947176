import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { CommonHelper } from '@tc-core/util/helpers';
var RoomAddCardComponent = /** @class */ (function () {
    function RoomAddCardComponent(commonHelper) {
        this.commonHelper = commonHelper;
        this.closeModal = new EventEmitter();
        this.selectedRoomTypes = [];
        this.selectedRoomCodes = [];
        this.selected = [];
    }
    RoomAddCardComponent.prototype.ngOnInit = function () {
        this.loadRoomTypes();
    };
    RoomAddCardComponent.prototype.loadRoomTypes = function () {
        var e_1, _a;
        if (this.selectedRooms) {
            try {
                for (var _b = tslib_1.__values(this.selectedRooms), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var room = _c.value;
                    this.selectedRoomCodes.push(room.code);
                    this.selected.push(room.code);
                    this.selectedRoomTypes.push(room);
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_1) throw e_1.error; }
            }
        }
    };
    RoomAddCardComponent.prototype.addType = function (isSelected, roomType) {
        var _this = this;
        if (isSelected.checked) {
            this.selectedRoomCodes.push(roomType.code);
            this.selectedRoomTypes.push(roomType);
        }
        else {
            this.selectedRoomCodes.splice(this.selectedRoomCodes.indexOf(roomType.code), 1);
            this.selectedRoomTypes.forEach(function (d) {
                if (d.code === roomType.code) {
                    _this.selectedRoomTypes.splice(_this.selectedRoomTypes.indexOf(d), 1);
                }
            });
        }
    };
    RoomAddCardComponent.prototype.addRooms = function () {
        this.closeModal.emit(this.selectedRoomTypes);
    };
    return RoomAddCardComponent;
}());
export { RoomAddCardComponent };
