import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
// import { BodyViewYearComponent } from '@tc/cg-calendar/body-view-year/body-view-year.component';
// import { CalendarNavData } from '@tc/cg-calendar/calendar-nav-data';
// import { CgCalendarRefreshData } from '@tc/cg-calendar/cg-calendar-refresh-data';
import { Observable } from 'rxjs';
import { BodyViewYearComponent } from '../body-view-year/body-view-year.component';
import { CalendarNavData } from '../calendar-nav-data';
import { CgCalendarRefreshData } from '../cg-calendar-refresh-data';
import { RangeGroup } from '../range-group';
// import { RangeGroup } from '@tc/cg-calendar/range-group';

@Component({
  selector: 'tc-calendar-body',
  templateUrl: './calendar-body.component.html'
})
export class CalendarBodyComponent implements OnInit, AfterViewInit {

  @Input() calendarNavData: CalendarNavData;
  @Input() yearsArray: number[] = [];
  @Input() rangeGroups: RangeGroup[] = [];
  @Input() calendarRefreshEvent: Observable<CgCalendarRefreshData>;

  @Output() selectDate: EventEmitter<Date> = new EventEmitter();

  @ViewChild(BodyViewYearComponent)
  public bodyViewYear: BodyViewYearComponent;

  private scrollableDiv: HTMLElement = null;
  private singleYearElement: HTMLElement = null;
  private oneYearElementHeight: number = 0;
  private allYears: NodeListOf<HTMLElement> = null;

  isIeOrEdge = false;

  constructor(
    public el: ElementRef
  ) { }

  ngOnInit() {
    this.isIeOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
  }

  onSelectDate($event: Date) {
    this.selectDate.emit($event);
  }

  public ngAfterViewInit(): void {
    try {
      this.scrollableDiv = this.el.nativeElement.querySelector('.tc-sc__cal-panel');
      this.singleYearElement = this.el.nativeElement.querySelector('.cg-cal-single-year-body');
      this.updateSingleYearElementHeight();
      this.updateAllYearElements();
    } catch (e) {
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.updateSingleYearElementHeight();
  }

  public onScrollYears() {
    this.calendarNavData.showingYear = this.getScrolledYear(this.scrollableDiv.scrollTop);
  }

  private updateAllYearElements() {
    this.allYears = this.el.nativeElement.querySelectorAll('tc-single-year');
    this.onScrollYears();
  }

  private updateSingleYearElementHeight() {
    if (this.singleYearElement) {
      this.oneYearElementHeight = this.singleYearElement.offsetHeight;
    }
  }

  private getScrolledYear(scrollPositionY: number): number {
    let year = 0;
    try {
      let index = Math.floor((scrollPositionY + (this.oneYearElementHeight / 2)) / this.oneYearElementHeight);
      if (this.allYears[index]) {
        year = CalendarBodyComponent.getYearFromId(this.allYears[index].id);
      } else {
        this.updateAllYearElements();
        if (this.allYears[index]) {
          year = CalendarBodyComponent.getYearFromId(this.allYears[index].id);
        }
      }
    } catch (e) {
    }
    return year;
  }

  public scrollToYear(year: number) {
    let yearPosition = 0;
    let matched = false;
    if (this.allYears) {
      for (let i = 0; i < this.allYears.length; i++) {
        if (CalendarBodyComponent.getYearFromId(this.allYears[i].id) === year) {
          yearPosition = i;
          matched = true;
        }
      }
      if (matched) {
        this.scrollToHeight(yearPosition * this.oneYearElementHeight);
      } else {
        this.addRecentNewYear(year);
      }
    }
  }

  public scrollToDate(date: Date) {
    if (date) {
      let year = date.getFullYear();
      let month = date.getMonth();
      let scrollingHalfWithinYear = 0;

      let yearPosition = 0;
      let matched = false;
      if (this.allYears) {
        for (let i = 0; i < this.allYears.length; i++) {
          if (CalendarBodyComponent.getYearFromId(this.allYears[i].id) === year) {
            yearPosition = i;
            matched = true;
          }
        }
        if (matched) {
          if (0 <= month && month <= 3) {
            scrollingHalfWithinYear = 0;
          } else if (4 <= month && month <= 7) {
            scrollingHalfWithinYear = 0.33;
          } else if (8 <= month && month <= 11) {
            scrollingHalfWithinYear = 0.66;
          }
          let scrollingHeight = (yearPosition * this.oneYearElementHeight) + (scrollingHalfWithinYear *
            this.oneYearElementHeight);
          this.scrollToHeight(scrollingHeight);
        } else {
          this.addRecentNewYear(year);
          setTimeout(() => {
            this.scrollToDate(date);
          }, 0);

        }
      }
    }
  }

  private scrollToHeight(height: number) {
    if (this.isIeOrEdge) {
      this.scrollableDiv.scrollTo(0, height);
    } else {
      this.scrollableDiv.scrollTo({top: height, left: 0, behavior: 'smooth'});
    }
  }

  private addRecentNewYear(year: number) {
    let added = false;
    if (year + 1 === this.yearsArray[0]) {
      this.yearsArray.unshift(year);
      added = true;
    } else if (year - 1 === this.yearsArray[this.yearsArray.length - 1]) {
      this.yearsArray.push(year);
      added = true;
    }
    if (added) {
      setTimeout(() => {this.updateAllYearElements();}, 0);
      this.scrollToYear(year);
    }
  }

  private static getYearFromId(id: string): number {
    let year = 0;
    // "cg-cal-year-2019"
    let strings = id.split('-');
    try {
      year = Number(strings[strings.length - 1]);
    } catch (e) {
    }
    return year;
  }

}
