import * as tslib_1 from "tslib";
import { Location } from '@angular/common';
import { OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { TcApiError } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-api-error';
import { TcErrorType } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-error';
import { TcHttpError } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-http-error';
import { DialogModel, LEVEL } from '@tc-core/model/it/codegen/ui/framework/dialog-model';
import { ModalData } from '@tc-core/model/it/codegen/ui/framework/modal-data';
import { ERROR_LEVEL } from '@tc-core/model/it/codegen/ui/framework/page-section';
import { TC } from '@tc-core/util/constants';
import { ConfigLoader } from '@tc-core/util/framework/config-loader.service';
import { EventManager } from '@tc-core/util/framework/event-manager.service';
import { FocusViewManager } from '@tc-core/util/framework/focus-view-manager.service';
import { UserJourneyManager } from '@tc-core/util/framework/user-journey-manager.service';
import { CommonHelper } from '@tc-core/util/helpers/common-helper.service';
import { KebabService } from '@tc-core/util/ui';
import { SpinnerService } from '@tc-core/util/ui/spinner.service';
import { Chip } from '@tc/chip-selector/chip.model';
import { DialogService } from '@tc/dialog/dialog.service';
import { ModalService } from '@tc/modal/modal.service';
import { SorterPipe } from '@tc/pipes/sorter.pipe';
import { take } from 'rxjs/operators';
import { TCO } from '../../../constants';
import { SupplierService } from '../../../services/backend-consumers/supplier-service/supplier.service';
import { ManageNotesComponent } from '../../../widgets/framework/manage-notes';
import { PageType } from '../../../models/helper/page-type';
import { Booking } from '../../../models/reservation-v2/booking';
import { BookingItem } from '../../../models/reservation-v2/booking-item';
import { BookingItemNoteType, BookingNote, BookingNoteType } from '../../../models/reservation-v2/booking-note';
import { BookingPassenger } from '../../../models/reservation-v2/booking-passenger';
import { BookingType } from '../../../models/reservation-v2/booking-type';
import { BookingSearchCriteria } from '../../../models/reservation-v2/criteria/booking-search-criteria';
import { GenBookingItem } from '../../../models/reservation-v2/gen-booking-item';
import { MainType } from '../../../models/reservation-v2/main-type';
import { PassengerType, PassengerTypeName } from '../../../models/reservation-v2/passenger-type';
import { ProductLevel } from '../../../models/reservation-v2/product-level';
import { ServiceSharing } from '../../../models/reservation-v2/service-sharing';
import { TrsBookingItem } from '../../../models/reservation-v2/trs-booking-item';
import { ResourceType } from '../../../models/reservation/assignment';
import { NoteItemView } from '../../../models/reservation/note-item-view';
import { Supplier } from '../../../models/supplier/supplier';
import { DataHandlerService } from '../../../services/backend-consumers/master-data-handler-service/data-handler.service';
import { BookingManagementService } from '../../../services/backend-consumers/reservation-v2/booking-management.service';
import { DMCCommon } from '../../../services/util/common/dmc-common';
import { RootService } from '../../../services/util/core-services/root.service';
import { DataKey, DataStoreService } from '../../../services/util/framework/data-store.service';
import { DMCQueryParamsService } from '../../../services/util/framework/dmc-query-params.service';
import { ResponseUtil } from '../../../services/util/response/response-util.service';
import { DMCLocalStorageService, LocalDataKey } from '../../../services/util/system/dmc-local-storage.service';
import { CardDataHandlerService } from '../../../services/util/ui/card-data-handler-service.service';
import { ContentSummaryCardService } from '../../../services/util/ui/content-summary-card.service';
import { BookingUtilService } from '../booking-util.service';
import { PassengerSelectionComponent } from './booking-items/passenger-selection/passenger-selection.component';
import { BookingNotesComponent } from './booking-notes/booking-notes.component';
import { PassengerGenerationComponent } from './passenger-generation/passenger-generation.component';
import { BookingPassengersComponent } from './passengers/booking-passengers.component';
var BookingComponent = /** @class */ (function () {
    function BookingComponent(route, dataStore, localStorage, cardDataHandlerService, userJourneyManager, em, location, bookingManagementService, spinnerService, commonHelper, common, rootService, queryParamService, focusViewManager, dataHandlerService, configLoader, summaryCardService, supplierService, kebabService, modalService, bookingUtil, dialogService, dialog, sortPipe) {
        var _this = this;
        this.route = route;
        this.dataStore = dataStore;
        this.localStorage = localStorage;
        this.cardDataHandlerService = cardDataHandlerService;
        this.userJourneyManager = userJourneyManager;
        this.em = em;
        this.location = location;
        this.bookingManagementService = bookingManagementService;
        this.spinnerService = spinnerService;
        this.commonHelper = commonHelper;
        this.common = common;
        this.rootService = rootService;
        this.queryParamService = queryParamService;
        this.focusViewManager = focusViewManager;
        this.dataHandlerService = dataHandlerService;
        this.configLoader = configLoader;
        this.summaryCardService = summaryCardService;
        this.supplierService = supplierService;
        this.kebabService = kebabService;
        this.modalService = modalService;
        this.bookingUtil = bookingUtil;
        this.dialogService = dialogService;
        this.dialog = dialog;
        this.sortPipe = sortPipe;
        this.pageType = '';
        this.readonly = false;
        this.editable = false;
        // master data
        this.titles = [];
        this.passengerTypes = [];
        this.bookingNoteTypes = [
            BookingNoteType.SUPPLIER,
            BookingNoteType.INVOICE,
            BookingNoteType.TICKETING,
            BookingNoteType.BOOKING,
            BookingNoteType.ITINERARY,
            BookingNoteType.VOUCHER,
            BookingNoteType.TRANSFER_INFO,
            BookingNoteType.OSI,
            BookingNoteType.OTHER
        ];
        this.trsModes = [];
        this.nextPassengerTempId = 1;
        this.dataChanged = false;
        this.formSaved = false;
        this.isSaveEnable = false;
        this.invalidKeyControl = false;
        this.bookingInfoSectionValid = false;
        this.keyControlInformationValid = false;
        this.passengersSectionValid = false;
        this.bookingItemSectionValid = false;
        this.bookingNoteSectionValid = false;
        this.disableKeyControlEdit = false;
        this.CARD_CONFIG_BOOKING_EDIT = 'booking_edit';
        this.headerTextNew = 'New Booking';
        this.headerTextEdit = 'Edit Booking';
        this.addNoteTitle = 'Add New Note';
        this.CARD_CONFIG_NOTES = 'notes';
        this.noteContents = [];
        this.masterNoteTypes = [];
        this.COMMON_TITLE_ADULTS = 'Mx';
        this.COMMON_TITLE_CHILDREN = 'Mx';
        this.COMMON_TITLE_INFANTS = 'Mstr';
        this.DUMMY_FIRST_NAME = 'Dummy_';
        this.DUMMY_LAST_NAME = 'Name';
        this.bookingItemSummaryCards = [];
        this.passengerSelectionChangeFn = function (valid) {
            _this.onBookingItemSectionChange(valid);
        };
        this.passengerGenerationChangeFn = function (passengers) {
            _this.generatePassengers(passengers);
        };
    }
    BookingComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.bookingItemCardConfig = this.configLoader.configurations.get(TCO.CONF.CONF_BOOKING_ITEM_CARD_CONFIG);
        this.pageType = this.route.snapshot.queryParamMap.get('page').split('_')[0];
        this.retrieveMasterData();
        this.initDataModel();
        if (this.pageType === PageType.CREATE) {
            this.dataStore.get(DataKey.selectedBookingForEditAsNew, true)
                .pipe(take(1))
                .subscribe(function (data) {
                if (data != null) {
                    _this.dataStore.set(DataKey.selectedBookingForEditAsNew, null, true);
                    data.bookingType = BookingType.CREATED;
                    _this.fillBookingObject(data);
                }
                else {
                    var unsavedData = _this.localStorage.load(LocalDataKey.unSavedBooking);
                    if (unsavedData && !unsavedData.id) {
                        _this.fillBookingObject(unsavedData);
                    }
                }
                _this.initPageSectionReferences();
            });
        }
        else if (this.pageType === PageType.EDIT) {
            this.editable = true;
            this.dataStore.get(DataKey.selectedBookingForEdit, true)
                .pipe(take(1))
                .subscribe(function (data) {
                if (data != null) {
                    // for change detection purpose
                    _this.bookingUnedited = JSON.parse(JSON.stringify(data));
                    _this.disableKeyControlEdit = _this.bookingUnedited.isEditTriggered;
                    _this.fillBookingObject(data);
                }
                else {
                    var unsavedData = _this.localStorage.load(LocalDataKey.unSavedBooking);
                    _this.bookingUnedited = JSON.parse(JSON.stringify(unsavedData));
                    _this.fillBookingObject(unsavedData);
                }
                _this.initPageSectionReferences();
            });
        }
        // save event subscription
        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'SAVE').subscribe(function (e) {
            _this.saveBooking();
        });
        this.createBookingItemSummaryCards();
        this.setItemAddingKebabMenus();
        this.setKebabActionTriggers();
        this.onNoteListUpdate();
        this.onBookingItemSectionChange(this.checkBookingItemValidity());
        this.getUserDetails();
        this.supplier = new Supplier();
        this.supplier.kcCompany = this.userProfile.kcCompany;
        this.supplier.kcDivision = this.userProfile.kcDivision;
        this.supplier.kcBrand = this.userProfile.kcBrand;
        this.supplier.kcDistributionChannel = this.userProfile.kcDistributionChannel;
    };
    BookingComponent.prototype.retrieveMasterData = function () {
        var _this = this;
        this.dataHandlerService.retrieveTitleDetails().subscribe(function (result) {
            _this.titles = result.data;
        });
        this.passengerTypes = [
            PassengerType.A,
            PassengerType.T,
            PassengerType.C,
            PassengerType.I
        ];
        this.dataHandlerService.getTransferModes().subscribe(function (result) {
            _this.trsModes = result.data;
        });
    };
    BookingComponent.prototype.initPageSectionReferences = function () {
        this.pageSectionsMap = this.cardDataHandlerService.getAllPageSections(this.CARD_CONFIG_BOOKING_EDIT, this.booking);
        this.bookingInfoSection = this.pageSectionsMap.get('booking_info');
        this.supplierKeyControlSection = this.pageSectionsMap.get('supplier_key_controls');
        this.passengersSection = this.pageSectionsMap.get('passengers');
        this.bookingItemSection = this.pageSectionsMap.get('booking_items');
        this.bookingNoteSection = this.pageSectionsMap.get('booking_notes');
        this.setupHeader();
    };
    BookingComponent.prototype.setupHeader = function () {
        if (this.pageType === PageType.CREATE) {
            this.userJourneyManager.setCurrentHeading(this.headerTextNew);
        }
        else if (this.pageType === PageType.EDIT) {
            this.userJourneyManager.setCurrentHeading(this.headerTextEdit);
        }
        this.setHeaderActions();
        this.setHeaderActionTriggers();
    };
    BookingComponent.prototype.setHeaderActionTriggers = function () {
        var _this = this;
        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'TRIGGER_CLOSE')
            .subscribe(function (e) {
            _this.onCloseClick(null);
        });
    };
    BookingComponent.prototype.onCloseClick = function (event) {
        this.location.back();
    };
    BookingComponent.prototype.setHeaderActions = function () {
        var actionKeys = [];
        actionKeys.push('TRIGGER_CLOSE');
        this.userJourneyManager.setHeaderActions(actionKeys);
    };
    BookingComponent.prototype.removeHeaderActions = function () {
        this.userJourneyManager.setHeaderActions([]);
        this.em.removeEvents([
            'TRIGGER_CLOSE'
        ]);
    };
    BookingComponent.prototype.setupSectionTabError = function (section) {
        if (!section) {
            return false;
        }
        var shouldShow = section.showError === ERROR_LEVEL.SHOW_ERROR_TAB || section.showError ===
            ERROR_LEVEL.SHOW_ERROR_TAB_FORM;
        return section.error && shouldShow;
    };
    BookingComponent.prototype.setItemAddingKebabMenus = function () {
        this.itemAddingKebabMenu = this.kebabService.get('BOOKING_ITEMS').items;
    };
    BookingComponent.prototype.setKebabActionTriggers = function () {
        var _this = this;
        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'TRIGGER_ADD_TRS_ITEM')
            .subscribe(function (e) {
            _this.addTrsItem();
        });
        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'TRIGGER_ADD_GEN_ITEM')
            .subscribe(function (e) {
            _this.addGenItem();
        });
        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'TRIGGER_ADD_ACCOM_ITEM')
            .subscribe(function (e) {
            _this.addAccomItem();
        });
    };
    BookingComponent.prototype.removeKebabActionTriggers = function () {
        this.em.removeEvents([
            'TRIGGER_ADD_TRS_ITEM',
            'TRIGGER_ADD_GEN_ITEM',
            'TRIGGER_ADD_ACCOM_ITEM'
        ]);
    };
    BookingComponent.prototype.onHeaderActionClick = function (event) {
        switch (event.id) {
            case 'TRIGGER_ADD_PASSENGER': {
                console.log('add passenger');
                this.addPassenger();
                break;
            }
            case 'TRIGGER_GENERATE_PASSENGERS': {
                console.log('generate passengers');
                this.onGeneratePassengerClick();
                break;
            }
            case 'TRIGGER_ADD_NOTE': {
                console.log('add note');
                this.onAddNotesClick();
                break;
            }
            case 'TRIGGER_ADD_TRS_ITEM': {
                console.log('add trs item');
                this.addTrsItem();
                break;
            }
            case 'TRIGGER_ADD_GEN_ITEM': {
                console.log('add gen item');
                this.addGenItem();
                break;
            }
            case 'TRIGGER_ADD_ACCOM_ITEM': {
                console.log('add accom item');
                this.addAccomItem();
                break;
            }
        }
    };
    BookingComponent.prototype.initDataModel = function () {
        var e_1, _a;
        if (!this.booking) {
            this.booking = new Booking();
        }
        if (!this.booking.bookingItems) {
            this.booking.bookingItems = [];
        }
        if (!this.booking.bookingPassengers) {
            this.booking.bookingPassengers = [];
        }
        if (!this.booking.bookingNotes) {
            this.booking.bookingNotes = [];
        }
        try {
            for (var _b = tslib_1.__values(this.booking.bookingPassengers), _c = _b.next(); !_c.done; _c = _b.next()) {
                var bookingPassenger = _c.value;
                if (bookingPassenger.tempId > this.nextPassengerTempId) {
                    this.nextPassengerTempId = bookingPassenger.tempId;
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
    };
    BookingComponent.prototype.fillBookingObject = function (data) {
        this.booking = data;
        this.localStorage.store(LocalDataKey.unSavedBooking, this.booking);
        this.initDataModel();
        this.checkEditable();
        this.setReadOnlyPageSectionActions();
    };
    BookingComponent.prototype.checkEditable = function () {
        if (this.booking.bookingType === BookingType.CREATED) {
            this.readonly = false;
        }
        else {
            this.readonly = true;
        }
    };
    BookingComponent.prototype.setReadOnlyPageSectionActions = function () {
    };
    BookingComponent.prototype.addPassenger = function () {
        if (!this.readonly) {
            var bookingPassenger = new BookingPassenger();
            bookingPassenger.tempId = ++this.nextPassengerTempId;
            this.booking.bookingPassengers.push(bookingPassenger);
            if (this.passengerComponent) {
                this.passengerComponent.updateGrid();
                this.onPassengerSectionChange(false);
            }
            this.addPassengerToItemPassengers(bookingPassenger);
            this.enableSaveButton();
        }
    };
    BookingComponent.prototype.onAddNotesClick = function () {
        var _this = this;
        var addNoteDialogRef = this.getNoteDialogRef(this.addNoteTitle, '', '', '', '', false);
        addNoteDialogRef.afterClosed().subscribe(function (result) {
            if (result) {
                console.log(result);
                _this.saveBookingNote(result);
            }
        });
    };
    BookingComponent.prototype.getNoteDialogRef = function (noteTitle, noteText, noteType, noteMethodCodes, noteResourcePath, isEdit) {
        if (isEdit === void 0) { isEdit = false; }
        var noteTypes = this.sortPipe.transform(this.masterNoteTypes, 'name');
        return this.dialog.open(ManageNotesComponent, {
            width: '', height: '',
            data: {
                title: noteTitle,
                note: noteText,
                noteTypes: noteTypes,
                selectedNoteType: noteType,
                isNoteEditMode: isEdit,
                selectedNoteTypes: this.getSelectedNoteType(noteTypes, noteType),
                methodCodes: noteMethodCodes,
                resourcePath: noteResourcePath,
                isMultiSelect: false
            }
        });
    };
    BookingComponent.prototype.getSelectedNoteType = function (noteTypes, noteType) {
        var selectedNotes = [];
        if (noteTypes && noteTypes.length > 0 && noteType) {
            var selectedNoteTypes = noteTypes.filter(function (note) { return note.code === noteType; });
            var chip = new Chip();
            chip.selected = true;
            chip.value = selectedNoteTypes[0];
            chip.restricted = false;
            selectedNotes.push(chip);
        }
        return selectedNotes;
    };
    BookingComponent.prototype.onDeletePassenger = function (event) {
        var _this = this;
        if (!this.readonly && !this.isLeadPassenger(event)) {
            var confirmDialog = new DialogModel(true, LEVEL.WARNING, 'Delete', "Are you sure want to delete the passenger ?", true, 2000, null, 'No', 'Yes', true);
            this.dialogService
                .confirm(confirmDialog)
                .subscribe(function (res) {
                if (res == true) {
                    var index = _this.booking.bookingPassengers.indexOf(event);
                    _this.booking.bookingPassengers.splice(index, 1);
                    _this.passengerComponent.updateGrid();
                    _this.onPassengerSectionChange(true);
                    _this.removePassengerFromItemPassengers(event);
                }
            });
        }
        else {
            this.common.showSnackBar('You can\'t delete this passenger', 3000, TcErrorType.TYPE.ERROR);
        }
    };
    BookingComponent.prototype.onDeleteNote = function (event) {
        var _this = this;
        if (!this.readonly) {
            var confirmDialog = new DialogModel(true, LEVEL.WARNING, 'Delete', "Are you sure want to delete the note ?", true, 2000, null, 'No', 'Yes', true);
            this.dialogService
                .confirm(confirmDialog)
                .subscribe(function (res) {
                if (res == true) {
                    var index = _this.booking.bookingNotes.indexOf(event);
                    _this.booking.bookingNotes.splice(index, 1);
                    _this.bookingNotesComponent.updateGrid();
                    _this.onBookingNoteSectionChange(true);
                }
            });
        }
    };
    BookingComponent.prototype.addTrsItem = function () {
        if (!this.readonly) {
            var bookingItem = this.mapTrsItemData();
            this.bookingUtil.setBookingReferenceId(this.booking, bookingItem, 'trs');
            this.booking.bookingItems.push(bookingItem);
            this.createBookingItemSummaryCards();
            this.onBookingItemSectionChange(false);
        }
    };
    BookingComponent.prototype.mapTrsItemData = function () {
        var bookingItem = new BookingItem();
        bookingItem.productLevel = ProductLevel.COMP;
        bookingItem.mainType = MainType.TRS;
        bookingItem.pvtShared = ServiceSharing.SHARED;
        bookingItem.passengerTempIds = this.getAllBookingPassengerTempIds();
        bookingItem.bookingPassengers = [];
        bookingItem.passengerTempIds = this.booking.bookingPassengers.map(function (p) { return p.tempId; });
        if (this.booking.bookingPassengers && this.booking.bookingPassengers.length) {
            bookingItem.leadPassenger = this.booking.bookingPassengers[0];
        }
        bookingItem.trsBookingItem = new TrsBookingItem();
        bookingItem.bookingSourceId = this.booking.bookingId;
        bookingItem.bookingSource = this.booking.bookingSource;
        return bookingItem;
    };
    BookingComponent.prototype.addGenItem = function () {
        if (!this.readonly) {
            var bookingItem = this.mapGenItemData();
            this.bookingUtil.setBookingReferenceId(this.booking, bookingItem, 'gen');
            this.booking.bookingItems.push(bookingItem);
            this.createBookingItemSummaryCards();
            this.onBookingItemSectionChange(false);
        }
    };
    BookingComponent.prototype.addAccomItem = function () {
        if (!this.readonly) {
            var bookingItem = this.mapGenItemData();
            bookingItem.mainType = MainType.HTL;
            bookingItem.genBookingItem.productCode = ResourceType.hotel;
            this.bookingUtil.setBookingReferenceId(this.booking, bookingItem, 'accom');
            this.booking.bookingItems.push(bookingItem);
            this.createBookingItemSummaryCards();
            this.onBookingItemSectionChange(false);
        }
    };
    BookingComponent.prototype.getAllBookingPassengerTempIds = function () {
        var e_2, _a;
        var ids = [];
        if (this.booking.bookingPassengers) {
            try {
                for (var _b = tslib_1.__values(this.booking.bookingPassengers), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var bookingPassenger = _c.value;
                    ids.push(bookingPassenger.tempId);
                }
            }
            catch (e_2_1) { e_2 = { error: e_2_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_2) throw e_2.error; }
            }
        }
        return ids;
    };
    BookingComponent.prototype.onItemPassengersClick = function (event) {
        var _this = this;
        console.log('passenger clicked');
        this.dataStore.set(DataKey.popupClose, null);
        var output = {
            changed: undefined,
            valid: undefined
        };
        if (this.booking.bookingPassengers && this.booking.bookingPassengers.length > 0) {
            var dataObject = new ModalData(LEVEL.SUCCESS, 'Item Passengers', null, null, 'modal-basic--alt modal-basic--fixed-size', PassengerSelectionComponent, {
                booking: this.booking,
                bookingItem: event,
                readonly: this.readonly,
                onChangeFn: this.passengerSelectionChangeFn.bind(this)
            });
            this.confirmModal(dataObject);
            this.dataStore.get(DataKey.popupClose).subscribe(function (data) {
                if (data != null) {
                    _this.closeModal();
                    if (data.changed) {
                        _this.dataChanged = true;
                    }
                }
            });
        }
        else {
            var confirmDataObject = new DialogModel(true, LEVEL.WARNING, 'Passengers', 'No Passengers added.', true, 3000);
            this.dialogService.confirm(confirmDataObject).subscribe(function (res) {
                if (res === true) {
                }
            });
        }
    };
    BookingComponent.prototype.closeModal = function () {
        if (this.modalService.close()) {
            this.modalService.close().subscribe(function (res) {
            });
        }
    };
    BookingComponent.prototype.confirmModal = function (data) {
        this.modalService.confirm(data).subscribe(function (res) {
        });
    };
    BookingComponent.prototype.onBookingItemDelete = function (event) {
        var _this = this;
        if (!this.readonly) {
            var confirmDialog = new DialogModel(true, LEVEL.WARNING, 'Delete', "Are you sure want to delete the Booking Item ?", true, 2000, null, 'No', 'Yes', true);
            this.dialogService
                .confirm(confirmDialog)
                .subscribe(function (res) {
                if (res == true) {
                    var index = _this.booking.bookingItems.indexOf(event);
                    _this.booking.bookingItems.splice(index, 1);
                    _this.createBookingItemSummaryCards();
                    _this.onBookingItemSectionChange(_this.checkBookingItemValidity());
                }
            });
        }
    };
    BookingComponent.prototype.checkBookingItemValidity = function () {
        var e_3, _a;
        var allValid = true;
        try {
            for (var _b = tslib_1.__values(this.booking.bookingItems), _c = _b.next(); !_c.done; _c = _b.next()) {
                var bookingItem = _c.value;
                if (bookingItem.itemName
                    && bookingItem.bookingReferenceId
                    && bookingItem.startTime
                    && bookingItem.endTime
                    && bookingItem.adultCount) {
                    if (bookingItem.genBookingItem && bookingItem.genBookingItem.productCode
                        && bookingItem.plCode) {
                    }
                    else if (bookingItem.genBookingItem
                        && bookingItem.genBookingItem.hotelRoomType && bookingItem.genBookingItem.hotelBoardBasis) {
                    }
                    else if (bookingItem.trsBookingItem && bookingItem.trsBookingItem.transferMode
                        && bookingItem.trsBookingItem.pickupPointName
                        && bookingItem.trsBookingItem.dropOffPointName
                        && bookingItem.plCode) {
                    }
                    else {
                        return false;
                    }
                }
                else {
                    return false;
                }
            }
        }
        catch (e_3_1) { e_3 = { error: e_3_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_3) throw e_3.error; }
        }
        return allValid;
    };
    BookingComponent.prototype.createBookingItemSummaryCards = function () {
        var e_4, _a;
        if (this.booking && this.booking.bookingItems) {
            var dataArray = [];
            try {
                for (var _b = tslib_1.__values(this.booking.bookingItems), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var bookingItem = _c.value;
                    var iconKey = '';
                    if (bookingItem.genBookingItem) {
                        if (bookingItem.genBookingItem && bookingItem.mainType === MainType.HTL) {
                            iconKey = 'accom';
                        }
                        else if (bookingItem.genBookingItem.productCode) {
                            iconKey = bookingItem.genBookingItem.productCode;
                        }
                        else {
                            iconKey = 'generic';
                        }
                    }
                    else if (bookingItem.trsBookingItem) {
                        iconKey = 'vehicle';
                    }
                    dataArray.push({
                        iconType: iconKey,
                        bookingReferenceId: bookingItem.bookingReferenceId
                    });
                }
            }
            catch (e_4_1) { e_4 = { error: e_4_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_4) throw e_4.error; }
            }
            this.bookingItemSummaryCards = this.summaryCardService.getContentCardsByConfig(this.bookingItemCardConfig, dataArray);
        }
        else {
            this.bookingItemSummaryCards = [];
        }
    };
    BookingComponent.prototype.ngOnDestroy = function () {
        this.removeHeaderActions();
        this.removeKebabActionTriggers();
        this.localStorage.delete(LocalDataKey.unSavedBooking);
    };
    BookingComponent.prototype.preProcessBooking = function (booking) {
        var e_5, _a;
        if (booking.bookingItems) {
            var bookingNotes = booking.bookingNotes;
            bookingNotes.forEach(function (n) { return n.itemNoteType = BookingItemNoteType.BOOKING; });
            var _loop_1 = function (bookingItem) {
                var _a, _b, e_6, _c;
                if (bookingItem && bookingItem.defaultSupplier && !this_1.invalidKeyControl) {
                    var detailedSupplier = bookingItem.defaultSupplier;
                    this_1.validateBookingItemKeyControls(detailedSupplier, booking);
                }
                // notes
                bookingItem.bookingNotes = [];
                (_a = bookingItem.bookingNotes).push.apply(_a, tslib_1.__spread(bookingNotes));
                if (bookingItem.itemNotes) {
                    bookingItem.itemNotes.forEach(function (n) { return n.itemNoteType = BookingItemNoteType.BOOKING_ITEM; });
                    (_b = bookingItem.bookingNotes).push.apply(_b, tslib_1.__spread(bookingItem.itemNotes));
                }
                // passengers
                var bookingItemPassengers = [];
                if (bookingItem && bookingItem.bookingPassengers) {
                    bookingItem.bookingPassengers.forEach(function (value) { return bookingItemPassengers.push(value); });
                }
                if (bookingItem.passengerTempIds instanceof Array) {
                    var _loop_2 = function (tId) {
                        if (this_1.booking && this_1.booking.bookingPassengers) {
                            var pInItem = null;
                            if (bookingItem && bookingItem.bookingPassengers) {
                                pInItem = bookingItem.bookingPassengers.find(function (p) { return p.tempId === tId; });
                            }
                            var pInBooking = this_1.booking.bookingPassengers.find(function (p) { return p.tempId === tId; });
                            if (pInBooking) {
                                var pInBookingCloned = JSON.parse(JSON.stringify(pInBooking));
                                if (pInItem) {
                                    pInBookingCloned.id = pInItem.id;
                                    pInBookingCloned.lead = false;
                                }
                                bookingItemPassengers.push(pInBookingCloned);
                            }
                        }
                    };
                    try {
                        for (var _d = tslib_1.__values(bookingItem.passengerTempIds), _e = _d.next(); !_e.done; _e = _d.next()) {
                            var tId = _e.value;
                            _loop_2(tId);
                        }
                    }
                    catch (e_6_1) { e_6 = { error: e_6_1 }; }
                    finally {
                        try {
                            if (_e && !_e.done && (_c = _d.return)) _c.call(_d);
                        }
                        finally { if (e_6) throw e_6.error; }
                    }
                }
                bookingItem.bookingPassengers = bookingItemPassengers;
                if (bookingItem.leadPassenger) {
                    var lead = bookingItem.bookingPassengers.find(function (p) { return p.tempId === bookingItem.leadPassenger.tempId; });
                    lead.lead = true;
                }
                bookingItem.bookingPassengers = bookingItemPassengers;
            };
            var this_1 = this;
            try {
                for (var _b = tslib_1.__values(booking.bookingItems), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var bookingItem = _c.value;
                    _loop_1(bookingItem);
                }
            }
            catch (e_5_1) { e_5 = { error: e_5_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_5) throw e_5.error; }
            }
        }
        console.log(booking);
    };
    BookingComponent.prototype.validateBookingItemKeyControls = function (detailedSupplier, booking) {
        if (detailedSupplier
            && ((detailedSupplier.kcDivision && booking.kcDivision && detailedSupplier.kcDivision !==
                booking.kcDivision)
                || (detailedSupplier.kcBrand && booking.kcBrand && detailedSupplier.kcBrand !==
                    booking.kcBrand)
                || (detailedSupplier.kcDistributionChannel && booking.kcDistributionChannel &&
                    detailedSupplier.kcDistributionChannel !== booking.kcDistributionChannel))) {
            this.invalidKeyControl = true;
        }
    };
    BookingComponent.prototype.saveBooking = function () {
        var _this = this;
        console.log(this.booking);
        if (this.pageType === PageType.CREATE || !this.booking.id) {
            // this.spinnerService.show();
            this.preProcessBooking(this.booking);
            if (!this.invalidKeyControl) {
                this.bookingManagementService.saveBooking(this.booking)
                    .subscribe(function (value) {
                    if (_this.commonHelper.dataValidity(value)) {
                        _this.spinnerService.hide();
                        if (ResponseUtil.isSuccess(value)) {
                            _this.formSaved = true;
                            _this.dataChanged = false;
                            _this.bookingUnedited = JSON.parse(JSON.stringify(_this.booking));
                            _this.localStorage.delete(LocalDataKey.unSavedBooking);
                            _this.common.showSnackBar('Successfully saved the booking.', 3000, TcErrorType.TYPE.INFO);
                            _this.handleNavigation(value);
                        }
                        else if (ResponseUtil.isError(value)) {
                            _this.common.showSnackBar('Failed to save the booking', 3000, TcErrorType.TYPE.ERROR);
                        }
                    }
                    else if (value instanceof TcApiError) {
                        _this.spinnerService.hide();
                    }
                    else if (value instanceof TcHttpError) {
                        _this.spinnerService.hide();
                    }
                }, function (error) {
                });
            }
            else {
                this.invalidKeyControl = false;
                this.common.showSnackBar('Inserted key controls do not match with the default supplier key controls ', 3000, TcErrorType.TYPE.ERROR);
            }
        }
        else if (this.pageType === PageType.EDIT && this.booking.id) {
            this.dataStore.set(DataKey.updateBooking, null, true);
            this.spinnerService.show();
            this.preProcessBooking(this.booking);
            if (!this.invalidKeyControl) {
                this.bookingManagementService.saveBooking(this.booking).subscribe(function (value) {
                    if (_this.commonHelper.dataValidity(value)) {
                        _this.spinnerService.hide();
                        if (ResponseUtil.isSuccess(value)) {
                            _this.formSaved = true;
                            _this.dataChanged = false;
                            _this.bookingUnedited = JSON.parse(JSON.stringify(_this.booking));
                            _this.common.showSnackBar('Successfully updated the booking', 3000, TcErrorType.TYPE.INFO);
                            _this.localStorage.delete(LocalDataKey.unSavedBooking);
                            _this.handleNavigation(value);
                        }
                        else if (ResponseUtil.isError(value)) {
                            _this.common.showSnackBar('Failed to update the booking', 3000, TcErrorType.TYPE.ERROR);
                        }
                    }
                    else if (value instanceof TcApiError) {
                        _this.spinnerService.hide();
                    }
                    else if (value instanceof TcHttpError) {
                        _this.spinnerService.hide();
                    }
                }, function (error) {
                });
            }
            else {
                this.invalidKeyControl = false;
                this.common.showSnackBar('Inserted key controls do not match with the default supplier key controls ', 3000, TcErrorType.TYPE.ERROR);
            }
        }
    };
    BookingComponent.prototype.handleNavigation = function (data) {
        var _this = this;
        if (data && JSON.stringify(data) !== JSON.stringify({})) {
            if (data.status.code === TC.ErrorCodes.SUCCESS) {
                if (data && data.data && data.data.length > 0 && data.data[0].bookingIdCombined) {
                    var bookingSearchCriteria = new BookingSearchCriteria();
                    bookingSearchCriteria.bookingId = data.data[0].bookingId;
                    console.log(bookingSearchCriteria);
                    var parameters = this.queryParamService.getNotNullQueryParamsArray(bookingSearchCriteria);
                    this.rootService.setDataToLocalStorage(TCO.AppData.BOOKING_SEARCH_CRITERIA, bookingSearchCriteria, true, parameters);
                }
                setTimeout(function () {
                    var journeyToProceed = 'BEGIN_BOOKING_MANAGEMENT';
                    if (journeyToProceed) {
                        _this.userJourneyManager.goForKey(journeyToProceed);
                    }
                }, 100);
            }
            else {
                if (data.status.code === TC.ErrorCodes.ERROR && data.error) {
                }
            }
        }
    };
    // -----------change forms--------------------
    BookingComponent.prototype.onBookingInfoSectionChange = function (event) {
        this.bookingInfoSectionValid = event;
        if (this.bookingInfoSection) {
            this.bookingInfoSection.error = !this.bookingInfoSectionValid;
        }
        this.dataChanged = true;
        if (this.bookingInfoSectionValid) {
            this.enableSaveButton();
        }
        else {
            this.disableSaveButton();
        }
    };
    BookingComponent.prototype.onChangeKeyControlInfoForm = function (event) {
        this.keyControlInformationValid = event;
        this.enableSaveButton();
        if (this.supplierKeyControlSection) {
            this.supplierKeyControlSection.error = !this.keyControlInformationValid;
        }
    };
    BookingComponent.prototype.onPassengerSectionChange = function (event) {
        this.passengersSectionValid = event;
        if (this.passengersSection) {
            this.passengersSection.error = !this.passengersSectionValid;
        }
        this.dataChanged = true;
        if (this.passengersSectionValid) {
            this.enableSaveButton();
        }
        else {
            this.disableSaveButton();
        }
    };
    BookingComponent.prototype.onBookingItemSectionChange = function (event) {
        this.bookingItemSectionValid = event;
        var invalidAdultCountBookingItem = this.booking.bookingItems.find(function (bookingItem) { return !(bookingItem.adultCount >
            0); });
        if (invalidAdultCountBookingItem !== undefined) {
            this.bookingItemSectionValid = false;
        }
        if (this.bookingItemSection) {
            this.bookingItemSection.error = !this.bookingItemSectionValid;
        }
        this.dataChanged = true;
        this.onBookingInfoSectionChange(true);
        if (this.bookingItemSectionValid) {
            this.enableSaveButton();
        }
        else {
            this.disableSaveButton();
        }
    };
    BookingComponent.prototype.onBookingNoteSectionChange = function (event) {
        this.bookingNoteSectionValid = event;
        if (this.bookingNoteSection) {
            this.bookingNoteSection.error = !this.bookingNoteSectionValid;
        }
        this.dataChanged = true;
        if (this.bookingNoteSectionValid) {
            this.enableSaveButton();
        }
        else {
            this.disableSaveButton();
        }
    };
    BookingComponent.prototype.enableSaveButton = function () {
        if (JSON.stringify(this.booking) !== JSON.stringify(this.bookingUnedited)) {
            this.dataChanged = true;
            this.formSaved = false;
        }
        else {
            this.dataChanged = false;
        }
        if (this.dataChanged && !this.formSaved
            && this.bookingInfoSectionValid
            && this.passengersSectionValid
            && this.bookingItemSectionValid
            && this.bookingNoteSectionValid
            && this.keyControlInformationValid
            && this.booking && this.booking.bookingItems && this.booking.bookingItems.length
            && this.booking.bookingPassengers && this.booking.bookingPassengers.length) {
            this.userJourneyManager.canProceed.next(true);
            this.focusViewManager.canProceed.next(true);
            this.isSaveEnable = true;
            this.localStorage.store(LocalDataKey.unSavedBooking, this.booking);
        }
        else {
            this.disableSaveButton();
        }
    };
    BookingComponent.prototype.disableSaveButton = function () {
        this.userJourneyManager.canProceed.next(false);
        this.focusViewManager.canProceed.next(false);
        this.isSaveEnable = false;
    };
    BookingComponent.prototype.removePassengerFromItemPassengers = function (passenger) {
        var e_7, _a;
        // const bookingPassengerFound = this.booking.bookingPassengers.find(value => value.bookingId === passenger.bookingId);
        // if (bookingPassengerFound) {
        //     const index = this.booking.bookingPassengers.indexOf(bookingPassengerFound);
        //     this.booking.bookingPassengers.splice(index, 1);
        // }
        if (this.booking.bookingItems) {
            try {
                for (var _b = tslib_1.__values(this.booking.bookingItems), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var bookingItem = _c.value;
                    var found = bookingItem.passengerTempIds.find(function (i) { return i === passenger.tempId; });
                    // const passengerFound = bookingItem.bookingPassengers.find(value => value.bookingId === passenger.bookingId);
                    if (found) {
                        var index = bookingItem.passengerTempIds.indexOf(found);
                        bookingItem.passengerTempIds.splice(index, 1);
                        if (bookingItem.leadPassenger && bookingItem.leadPassenger.tempId === passenger.tempId) {
                            bookingItem.leadPassenger = null;
                        }
                    }
                    // if (passengerFound) {
                    //     const index = bookingItem.bookingPassengers.indexOf(passengerFound);
                    //     bookingItem.bookingPassengers.splice(index, 1);
                    //     if (bookingItem.leadPassenger && bookingItem.leadPassenger.tempId === passenger.tempId) {
                    //         bookingItem.leadPassenger = null;
                    //     }
                    // }
                    if (!bookingItem.passengerTempIds || bookingItem.passengerTempIds.length === 0 ||
                        !bookingItem.leadPassenger) {
                        this.onBookingItemSectionChange(false);
                    }
                }
            }
            catch (e_7_1) { e_7 = { error: e_7_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_7) throw e_7.error; }
            }
        }
    };
    BookingComponent.prototype.addPassengerToItemPassengers = function (passenger) {
        var e_8, _a;
        if (this.booking.bookingItems) {
            try {
                for (var _b = tslib_1.__values(this.booking.bookingItems), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var bookingItem = _c.value;
                    bookingItem.passengerTempIds.push(passenger.tempId);
                }
            }
            catch (e_8_1) { e_8 = { error: e_8_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_8) throw e_8.error; }
            }
        }
    };
    BookingComponent.prototype.isLeadPassenger = function (passenger) {
        var e_9, _a;
        var passengerBookingItems = [];
        if (this.booking.bookingItems) {
            try {
                for (var _b = tslib_1.__values(this.booking.bookingItems), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var bookingItem = _c.value;
                    if (bookingItem.passengerTempIds.includes(passenger.tempId)) {
                        var passengerIsLead = bookingItem.bookingPassengers.find(function (value) { return value.tempId ===
                            passenger.tempId && value.lead; });
                        if (passengerIsLead) {
                            return true;
                        }
                    }
                }
            }
            catch (e_9_1) { e_9 = { error: e_9_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_9) throw e_9.error; }
            }
        }
        return false;
    };
    BookingComponent.prototype.editAsNewItem = function (item) {
        if (item) {
            var bookingItem = void 0;
            if (item.mainType === MainType.TRS) {
                bookingItem = this.mapTrsItemData();
                this.mapCommonItemData(bookingItem, item);
                bookingItem.trsBookingItem.transferMode = item.trsBookingItem.transferMode;
                bookingItem.trsBookingItem.airportOperationTimeMinutes = item.trsBookingItem.airportOperationTimeMinutes;
                bookingItem.trsBookingItem.pickupPointName = item.trsBookingItem.pickupPointName;
                bookingItem.trsBookingItem.pickupPointCode = item.trsBookingItem.pickupPointCode;
                bookingItem.trsBookingItem.dropOffPointName = item.trsBookingItem.dropOffPointName;
                bookingItem.trsBookingItem.dropOffPointCode = item.trsBookingItem.dropOffPointCode;
                bookingItem.trsBookingItem.departureDateTime = item.trsBookingItem.departureDateTime;
                bookingItem.trsBookingItem.arrivalDateTime = item.trsBookingItem.arrivalDateTime;
                bookingItem.trsBookingItem.transferMode = item.trsBookingItem.transferMode;
                this.bookingUtil.setBookingReferenceId(this.booking, bookingItem, 'trs');
            }
            else if (item.mainType === MainType.GEN) {
                bookingItem = this.mapGenItemData();
                this.mapCommonItemData(bookingItem, item);
                bookingItem.genBookingItem.productName = item.genBookingItem.productName;
                bookingItem.genBookingItem.typeName = item.genBookingItem.typeName;
                this.bookingUtil.setBookingReferenceId(this.booking, bookingItem, 'gen');
            }
            else {
                this.addAccomItem();
            }
            this.booking.bookingItems.push(bookingItem);
            this.createBookingItemSummaryCards();
            this.onBookingItemSectionChange(true);
        }
    };
    BookingComponent.prototype.mapCommonItemData = function (bookingItem, item) {
        bookingItem.itemName = item.itemName;
        bookingItem.contractReference = item.contractReference;
        bookingItem.plCode = item.plCode;
        bookingItem.plName = item.plName;
        bookingItem.defaultSupplierCode = item.defaultSupplierCode;
        bookingItem.defaultSupplierName = item.defaultSupplierName;
        bookingItem.startTime = item.startTime;
        bookingItem.endTime = item.endTime;
        bookingItem.serviceStartDateTime = item.serviceStartDateTime;
        bookingItem.serviceEndDateTime = item.serviceEndDateTime;
        bookingItem.serviceDate = item.serviceDate;
        bookingItem.bookingStatus = item.bookingStatus;
        bookingItem.bookingNotes = item.bookingNotes;
    };
    BookingComponent.prototype.mapGenItemData = function () {
        var bookingItem = new BookingItem();
        bookingItem.productLevel = ProductLevel.COMP;
        bookingItem.mainType = MainType.GEN;
        bookingItem.pvtShared = ServiceSharing.SHARED;
        bookingItem.passengerTempIds = this.getAllBookingPassengerTempIds();
        bookingItem.bookingPassengers = [];
        bookingItem.passengerTempIds = this.booking.bookingPassengers.map(function (p) { return p.tempId; });
        if (this.booking.bookingPassengers && this.booking.bookingPassengers.length) {
            bookingItem.leadPassenger = this.booking.bookingPassengers[0];
        }
        bookingItem.genBookingItem = new GenBookingItem();
        bookingItem.bookingSourceId = this.booking.bookingId;
        bookingItem.bookingSource = this.booking.bookingSource;
        return bookingItem;
    };
    // Open Generate passenger madal to each passenger count
    BookingComponent.prototype.onGeneratePassengerClick = function () {
        var _this = this;
        this.dataStore.set(DataKey.popupClose, null);
        var output = {
            changed: undefined,
            valid: undefined
        };
        var dataObject = new ModalData(LEVEL.SUCCESS, 'Passenger Generation', null, null, 'modal-basic--medium', PassengerGenerationComponent, {
            readonly: this.readonly,
            onChangeFn: this.passengerGenerationChangeFn.bind(this)
        });
        this.confirmModal(dataObject);
        this.dataStore.get(DataKey.popupClose).subscribe(function (data) {
            if (data != null) {
                _this.closeModal();
                if (data.changed) {
                    _this.dataChanged = true;
                }
            }
        });
    };
    BookingComponent.prototype.generatePassengers = function (passengers) {
        if (!this.readonly) {
            // generate adults
            var tempIdList = [];
            if (passengers.adults > 0) {
                for (var i = 0; i < passengers.adults; i++) {
                    var bookingPassenger = this.createPassenger();
                    bookingPassenger.type = PassengerType.A;
                    bookingPassenger.typeName = PassengerTypeName[PassengerType.A];
                    bookingPassenger.title = this.COMMON_TITLE_ADULTS;
                    tempIdList.push(bookingPassenger.tempId);
                    this.booking.bookingPassengers.push(bookingPassenger);
                    this.addPassengerToItemPassengers(bookingPassenger);
                }
            }
            // generate teens
            if (passengers.teens > 0) {
                for (var i = 0; i < passengers.teens; i++) {
                    var bookingPassenger = this.createPassenger();
                    bookingPassenger.type = PassengerType.T;
                    bookingPassenger.typeName = PassengerTypeName[PassengerType.T];
                    bookingPassenger.title = this.COMMON_TITLE_ADULTS;
                    tempIdList.push(bookingPassenger.tempId);
                    this.booking.bookingPassengers.push(bookingPassenger);
                    this.addPassengerToItemPassengers(bookingPassenger);
                }
            }
            // generate children
            if (passengers.children > 0) {
                for (var i = 0; i < passengers.children; i++) {
                    var bookingPassenger = this.createPassenger();
                    bookingPassenger.type = PassengerType.C;
                    bookingPassenger.typeName = PassengerTypeName[PassengerType.C];
                    bookingPassenger.title = this.COMMON_TITLE_CHILDREN;
                    tempIdList.push(bookingPassenger.tempId);
                    this.booking.bookingPassengers.push(bookingPassenger);
                    this.addPassengerToItemPassengers(bookingPassenger);
                }
            }
            // generate infants
            if (passengers.infants > 0) {
                for (var i = 0; i < passengers.infants; i++) {
                    var bookingPassenger = this.createPassenger();
                    bookingPassenger.type = PassengerType.I;
                    bookingPassenger.typeName = PassengerTypeName[PassengerType.I];
                    bookingPassenger.title = this.COMMON_TITLE_INFANTS;
                    tempIdList.push(bookingPassenger.tempId);
                    this.booking.bookingPassengers.push(bookingPassenger);
                    this.addPassengerToItemPassengers(bookingPassenger);
                }
            }
            // this.addPassengerListToItemPassengers(tempIdList);
            if (this.passengerComponent) {
                this.passengerComponent.updateGrid();
                this.onPassengerSectionChange(true);
            }
        }
        this.onBookingInfoSectionChange(true);
        this.enableSaveButton();
    };
    // Common passenger creation
    BookingComponent.prototype.createPassenger = function () {
        var bookingPassenger = new BookingPassenger();
        bookingPassenger.tempId = ++this.nextPassengerTempId;
        // For unique identification append passengerTempId
        bookingPassenger.firstName = this.DUMMY_FIRST_NAME + bookingPassenger.tempId;
        bookingPassenger.lastName = this.DUMMY_LAST_NAME;
        return bookingPassenger;
    };
    BookingComponent.prototype.addPassengerListToItemPassengers = function (tempIdList) {
        var e_10, _a, _b;
        if (this.booking.bookingItems) {
            try {
                for (var _c = tslib_1.__values(this.booking.bookingItems), _d = _c.next(); !_d.done; _d = _c.next()) {
                    var bookingItem = _d.value;
                    (_b = bookingItem.passengerTempIds).push.apply(_b, tslib_1.__spread(tempIdList));
                }
            }
            catch (e_10_1) { e_10 = { error: e_10_1 }; }
            finally {
                try {
                    if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
                }
                finally { if (e_10) throw e_10.error; }
            }
        }
    };
    BookingComponent.prototype.saveBookingNote = function (result) {
        if (!this.readonly && result) {
            var note = new BookingNote();
            note.note = result.note;
            note.type = result.selectedNoteTypes && result.selectedNoteTypes.length > 0 ?
                result.selectedNoteTypes[0].value.code :
                'BOOKING';
            if (!this.booking.bookingNotes) {
                this.booking.bookingNotes = [];
            }
            this.booking.bookingNotes.push(note);
            this.onNoteListUpdate();
        }
    };
    BookingComponent.prototype.onNoteListUpdate = function () {
        var e_11, _a;
        this.masterNoteTypes = this.configLoader.configurations.get(TCO.CONF.CONF_CONTENT_LIST_CARD).master_note_types;
        this.noteSummary = this.cardDataHandlerService.processCardData(this.CARD_CONFIG_NOTES, this.booking.bookingNotes, 'note-summary');
        if (this.booking.bookingNotes.length > 0) {
            this.noteContents = [];
            try {
                for (var _b = tslib_1.__values(this.booking.bookingNotes), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var note = _c.value;
                    var noteContent = new NoteItemView();
                    noteContent.header = this.cardDataHandlerService.processCardData(this.CARD_CONFIG_NOTES, note, 'note-detail.header');
                    noteContent.summary = this.cardDataHandlerService.processCardData(this.CARD_CONFIG_NOTES, note, 'note-detail.summary');
                    noteContent.detail = this.cardDataHandlerService.processCardData(this.CARD_CONFIG_NOTES, note, 'note-detail.detail');
                    this.noteContents.push(noteContent);
                }
            }
            catch (e_11_1) { e_11 = { error: e_11_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_11) throw e_11.error; }
            }
        }
        this.bookingNoteSectionValid = true;
        this.onBookingInfoSectionChange(true);
    };
    BookingComponent.prototype.getUserDetails = function () {
        this.userProfile = this.dataStore.get(DataKey.userProfile).getValue();
    };
    return BookingComponent;
}());
export { BookingComponent };
