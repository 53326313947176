/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../documents-search-results.component.ngfactory";
import * as i2 from "../documents-search-results.component";
import * as i3 from "@tc-core/util/framework/config-loader.service";
import * as i4 from "../../../../services/util/framework/data-store.service";
import * as i5 from "../../../../services/backend-consumers/master-data-handler-service/data-handler.service";
import * as i6 from "../../../../services/backend-consumers/documents/document.service";
import * as i7 from "@angular/router";
import * as i8 from "@tc/focus-view/focus-view.service";
import * as i9 from "./manifest-search-results.component";
var styles_ManifestSearchResultsComponent = [];
var RenderType_ManifestSearchResultsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ManifestSearchResultsComponent, data: {} });
export { RenderType_ManifestSearchResultsComponent as RenderType_ManifestSearchResultsComponent };
export function View_ManifestSearchResultsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-documents-search-results", [], null, null, null, i1.View_DocumentsSearchResultsComponent_0, i1.RenderType_DocumentsSearchResultsComponent)), i0.ɵdid(1, 245760, null, 0, i2.DocumentsSearchResultsComponent, [i3.ConfigLoader, i4.DataStoreService, i5.DataHandlerService, i6.DocumentService, i7.ActivatedRoute, i8.FocusViewService], { colDefConfig: [0, "colDefConfig"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.colDefConfig; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ManifestSearchResultsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-manifest-search-results", [], null, null, null, View_ManifestSearchResultsComponent_0, RenderType_ManifestSearchResultsComponent)), i0.ɵdid(1, 114688, null, 0, i9.ManifestSearchResultsComponent, [i3.ConfigLoader], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ManifestSearchResultsComponentNgFactory = i0.ɵccf("tc-manifest-search-results", i9.ManifestSearchResultsComponent, View_ManifestSearchResultsComponent_Host_0, {}, {}, []);
export { ManifestSearchResultsComponentNgFactory as ManifestSearchResultsComponentNgFactory };
