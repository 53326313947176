import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormArray } from '@angular/forms';
import { DocumentData } from '../../../models/profile/document';
import { SummaryCardData } from '@tc-core/model/it/codegen/ui/framework/summary-card-data';
import { ExpansionPanelDirective } from '@tc/directives/expansion-panel';
import { DataKey, DataStoreService } from '../../../services/util/framework/data-store.service';

@Component({
    selector: 'tc-document-view-card',
    templateUrl: './document-view-card.component.html'
})
export class DocumentViewCardComponent implements OnInit {
    docTypes = [];
    categories = [];

    private documentTypesSubscription: Subscription = new Subscription();

    private documentDataSubscription: Subscription = new Subscription();
    docs: FormArray;
    private selectedIndex = -1;
    documentValidityMap: Map<number, boolean>;
    validDocuments: DocumentData[] = [];

    @Input() documentList: DocumentData[] = [];
    @Input() formatOptions = [];
    @Input() dispatchTypes = [];
    @Input() contactCategoryOptions = [];
    @Input() frequencyOptions = [];
    @Input() docTypeOptions = [];
    @Input() documentSummary: SummaryCardData;
    @Input() documentContents: SummaryCardData[] = [];

    @Output() changeFormValidity: EventEmitter<boolean> = new EventEmitter();
    @Output() documentsModified: EventEmitter<boolean> = new EventEmitter();
    @ViewChildren(ExpansionPanelDirective) _expansionPanels: QueryList<ExpansionPanelDirective>;

    addingDocumentCount: number = 0;

    allDocTypeOptions = [];

    constructor(private dataStore: DataStoreService) {
    }

    ngOnInit() {
        this.documentValidityMap = new Map<number, boolean>();
        this.setOldDocsTypesAndSubscribeForNewTypes();
        this.documentsModified.emit();

        /* this.documentInfoForm.valueChanges.subscribe(
         (data) => {
         if (JSON.stringify(data) !== JSON.stringify({})) {
         let doc = this.documentInfoForm.get('documentData') as FormArray;
         this.documentValues = doc.value.filter(val => val.template !== '');
         }
         this.dataStore.set(DataKey.documentData, this.documentValues, true);
         }
         );*/
    }

    setOldDocsTypesAndSubscribeForNewTypes() {

    }

    getDocumentTypes() {
        this.documentTypesSubscription = this.dataStore.get(DataKey.documentTypes).subscribe(data => {
            if (data != null) {
                this.docTypes = data;
                let existingDocTypes = [];
                this.documentList.forEach(
                    doc => {
                        let DocTypes_ = this.docTypes.find(docType => docType.code === doc.template);
                        existingDocTypes.push(DocTypes_);
                    }
                );
                let newTypes = this.docTypes.filter(docType => !existingDocTypes.includes(docType));
                if (newTypes.length) {
                    newTypes.forEach(docType => {
                        let document = new DocumentData();
                        document.tempId = this.addingDocumentCount++;
                        document.name = docType.name;
                        document.template = docType.code;
                        this.documentList.push(document);
                        this._expansionPanels.forEach((expansionElement) => {
                            if (expansionElement.id === 'document_summary_strip') {
                                expansionElement.expansionState.next(false);
                            }
                        });
                    });
                }
            }
            this.documentsModified.emit();
        });
    }

    public hideIcons(index) {
        this.selectedIndex = index;
    }

    public showIcons() {
        this.selectedIndex = -1;
    }

    public checkValidity(doc, event) {
        this.documentValidityMap.set(doc.template, event);
        this.emitValidity();
    }

    emitValidity() {
        this.validDocuments = [];
        let allContactsValid = true;
        this.documentList.forEach(address => {
            if (this.documentValidityMap.entries().next().value[1] === false) {
                allContactsValid = false;
            } else {
                this.validDocuments.push(address);
            }
        });
        this.changeFormValidity.emit(allContactsValid);
        this.dataStore.set(DataKey.documentData, this.validDocuments, true);
    }

    onDelete(event: number) {
        for (let i = 0; i < this.documentList.length; i++) {
            let docNo = this.documentList[i].tempId;
            if (docNo === event) {
                this.documentList.splice(i, 1);
                this.docTypes.splice(i, 1);
                this.documentValidityMap.delete(docNo);
                break;
            }
        }
        this.dataStore.set(DataKey.documentTypes, this.docTypes, true);
        this.documentsModified.emit();
        this.emitValidity();
    }

}
