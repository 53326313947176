<div class="container-fluid direct-client-contact-details-edit tc-card-wrapper">
  <div class="row" style="border-bottom: 1px solid #eee;">
    <div class="col-md-12 col-lg-12">
      <div class="tc-flex-box">
        <div class="tc-flex-item tc-item-circle">
          <div class="icon-box">
            <i class="material-icons" aria-hidden="true">format_list_bulleted</i>
          </div>
        </div>
        <div class="tc-flex-item tc-right-cage">
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
              <div class="tc-select-edit dcpiec-select-title">
                <mat-form-field>
                  <input
                          id="item-name"
                          matInput
                          [placeholder]="'Item Name'|translate"
                          [value]="bookingItem?.itemName? bookingItem.itemName:'--'"
                          readonly>
                </mat-form-field>
              </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
              <div class="tc-input-edit dcpiec-input-format">
                <mat-form-field>
                  <input
                          id="contract-reference"
                          matInput
                          [placeholder]="'Contract Reference'|translate"
                          [value]="bookingItem?.contractReference? bookingItem.contractReference:'--'"
                          readonly>
                </mat-form-field>
              </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
              <div class="tc-input-edit dcpiec-input-dispatchMethod">
                <mat-form-field>
                  <input
                          id="default-supplier"
                          matInput
                          [placeholder]="'Default Supplier'|translate"
                          [value]="bookingItem?.defaultSupplierName? bookingItem.defaultSupplierName:'--'"
                          readonly>
                </mat-form-field>
              </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
              <div class="tc-input-edit dcpiec-input-dispatchMethod">
                <mat-form-field>
                  <input
                          id="board-basis"
                          matInput
                          [placeholder]="'Board Basis'|translate"
                          [value]="bookingItem?.genBookingItem?.hotelBoardBasisName ? bookingItem.genBookingItem?.hotelBoardBasisName:'--'"
                          readonly>
                </mat-form-field>
              </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
              <div class="tc-input-edit dcpiec-input-frequency">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
              <div class="tc-select-edit dcpiec-select-title">
                <!--                  <mat-form-field>-->
                <!--                    <input-->
                <!--                            matInput-->
                <!--                            formControlName="elementGroup"-->
                <!--                            [placeholder]="'Element Group'|translate"-->
                <!--                            required>-->
                <!--                  </mat-form-field>-->

                <mat-form-field>
                  <input
                          id="room-type"
                          matInput
                          [placeholder]="'Room Type'|translate"
                          [value]="bookingItem.genBookingItem?.hotelRoomTypeName? bookingItem.genBookingItem.hotelRoomTypeName:'--'"
                          readonly>
                </mat-form-field>
              </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
              <div class="tc-input-edit dcpiec-input-format">
                <!--                  <mat-form-field>-->
                <!--                    <input-->
                <!--                            matInput-->
                <!--                            formControlName="typeName"-->
                <!--                            [placeholder]="'Type'|translate">-->
                <!--                  </mat-form-field>-->
                <mat-form-field>
                  <input
                          id="room-required"
                          matInput
                          [placeholder]="'Rooms Required'|translate"
                          [value]="bookingItem.genBookingItem?.noOfRoomsRequired ? bookingItem.genBookingItem.noOfRoomsRequired:'--'"
                          readonly>
                </mat-form-field>
              </div>
            </div>
<!--            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">-->
<!--              <div class="tc-input-edit dcpiec-input-frequency">-->
<!--                <mat-form-field>-->
<!--                  <input-->
<!--                          matInput-->
<!--                          [placeholder]="'Occupancy'|translate"-->
<!--                          [value]="bookingItem.genBookingItem?.occupancyName ? bookingItem.genBookingItem.occupancyName:'&#45;&#45;'"-->
<!--                          readonly>-->
<!--                </mat-form-field>-->
<!--              </div>-->
<!--            </div>-->
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
              <div class="tc-input-edit dcpiec-input-frequency">
                <mat-form-field>
                  <input
                          id="city"
                          matInput
                          [placeholder]="'City'|translate"
                          [value]="bookingItem.genBookingItem?.cityName ? bookingItem.genBookingItem.cityName:'--'"
                          readonly>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
              <div class="tc-input-edit dcpiec-input-frequency">
                <mat-form-field>
                  <input
                          id="adult-count"
                          matInput
                          [placeholder]="'Adult Count'|translate"
                          [value]="bookingItem.adultCount ? bookingItem.adultCount:0"
                          readonly>
                </mat-form-field>
              </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
              <div class="tc-input-edit dcpiec-input-frequency">
                <mat-form-field>
                  <input
                          id="child-count"
                          matInput
                          [placeholder]="'Child Count'|translate"
                          [value]="bookingItem.childrenCount ? bookingItem.childrenCount:0"
                          readonly>
                </mat-form-field>
              </div>
            </div>
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
              <div class="tc-input-edit dcpiec-input-frequency">
                <mat-form-field>
                  <input
                          id="teen-count"
                          matInput
                          [placeholder]="'Teen Count'|translate"
                          [value]="bookingItem.teenCount ? bookingItem.teenCount : 0"
                          readonly>
                </mat-form-field>
              </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
              <div class="tc-input-edit dcpiec-input-frequency">
                <mat-form-field>
                  <input
                          id="infant-count"
                          matInput
                          [placeholder]="'Infant Count'|translate"
                          [value]="bookingItem.infantCount ? bookingItem.infantCount : 0"
                          readonly>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
              <div class="tc-select-edit dcpiec-select-title">
                <mat-form-field>
                  <input
                          id="check-in"
                          matInput
                          [placeholder]="'Check In'|translate"
                          [value]="(bookingItem?.startTime? bookingItem.startTime:'--') | date:'M/d/yy, HH:MM':'GMT'"
                          readonly>
                </mat-form-field>
              </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
              <div class="tc-input-edit dcpiec-input-format">
                <mat-form-field>
                  <input
                          id="checkout"
                          matInput
                          [placeholder]="'Check Out'|translate"
                          [value]="(bookingItem?.endTime? bookingItem.endTime:'--') | date:'M/d/yy, HH:MM':'GMT'"
                          readonly>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <tc-item-notes
            [readonly]="true"
            [noteList]="bookingItem.itemNotes"
            [heading]="'Notes'"
            class="bdv-booking-notes-summary-wrapper">
    </tc-item-notes>
  </div>
</div>
