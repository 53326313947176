import {Injectable, Injector} from '@angular/core';
import {Chip} from '@tc-core/model/it/codegen/ui/chip';
import {ConfigLoader} from '@tc-core/util/framework/config-loader.service';
import {CommonHelper} from '@tc-core/util/helpers/common-helper.service';
import {DateFormatter} from '@tc-core/util/system/date-formatter.service';
import {BehaviorSubject, Subscription} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {DataHandlerService} from '../backend-consumers/master-data-handler-service/data-handler.service';
import {DataKey, DataStoreService} from '../util/framework/data-store.service';

@Injectable()
export class ChipHandlerService {

    public chipsSubject: BehaviorSubject<Chip[]> = new BehaviorSubject([]);

    public relatedChipsProcessSubject: BehaviorSubject<any> = new BehaviorSubject([]);
    chipsArr: any[] = [];
    relatedChipsArr: Chip[] = [];

    /*dataServiceHandler: DataServiceHandler;
     clientServiceHandler: ClientSearchServiceHandler;*/

    constructor(
        private injector: Injector,
        private dataStore: DataStoreService,
        // private rootService: RootService,
        // private serviceHandler: ServiceHandler,
        private configLoader: ConfigLoader,
        private commonHelper: CommonHelper,
        private dateFormatter: DateFormatter,
        private dataHandlerService: DataHandlerService
        // private authorizationService: AuthorizationService
    ) {
        // this.dataServiceHandler = this.serviceHandler.getDataServiceHandler();
        // this.clientServiceHandler = this.serviceHandler.getClientSearchServiceHandler();
    }

    public preProcessChips(chip: any, keys?: string []) {
        if (chip.optionParams.optionService && chip.optionParams.optionServiceMethod &&
            chip.optionParams.optionServiceObservable
            && chip.optionParams.optionServiceMethod !== '' && chip.optionParams.optionServiceObservable !== '') {
            const srv = chip.optionParams.optionService;
            const fnc = chip.optionParams.optionServiceMethod;
            const obsv = chip.optionParams.optionServiceObservable;

            const dataLoader = this.injector.get(srv);

            // function call in ChipsDataLoaderService
            try {
                dataLoader[fnc]().subscribe(data => {
                    if (data && JSON.stringify(data) !== JSON.stringify([])) {

                        if (this.chipsArr && this.chipsArr.length > 0) {

                            if (!this.checkIfExistAlready(this.chipsArr, chip)) {
                                chip.optionParams.options = this.authorizeChipOptions(keys, data, chip);
                                this.chipsArr.push(chip);

                                this.chipsSubject.next(this.chipsArr);
                            }

                        } else {
                            chip.optionParams.options = this.authorizeChipOptions(keys, data, chip);
                            if (!this.checkIfExistAlready(this.chipsArr, chip)) {
                                this.chipsArr.push(chip);
                            }
                            this.chipsSubject.next(this.chipsArr);
                        }
                    } else if (data && JSON.stringify(data) === JSON.stringify([])) {

                        if (this.chipsArr && this.chipsArr.length > 0) {

                            if (!this.checkIfExistAlready(this.chipsArr, chip)) {
                                chip.optionParams.options = data;
                                this.chipsArr.push(chip);
                                this.chipsSubject.next(this.chipsArr);
                            }
                        } else {
                            chip.optionParams.options = data;
                            if (!this.checkIfExistAlready(this.chipsArr, chip)) {
                                this.chipsArr.push(chip);
                            }
                            this.chipsSubject.next(this.chipsArr);
                        }
                    }
                }, error => {
                    console.log(error);
                });
            } catch (e) {
                console.log(e);
            }

            // behavior subject subscription
            /*dataLoader[obsv]
             .subscribe(data => {
             if (data && JSON.stringify(data) !== JSON.stringify([])) {
             chip.optionParams.options = data;
             this.chipsArr.push(chip);
             this.chipsSubject.next(this.chipsArr);
             }
             });*/

        } else {
            switch (chip.criteriaType) {
                case 'LIST_MULTI': {
                    chip.dataParams.forEach(dataParam => {
                        switch (dataParam.paramType) {
                            case 'OBJ_LIST': {
                                chip.optionParams.options = this.authorizeChipWithConfigMasterData(keys, chip);
                                break;
                            }
                            case 'TEXT_TILDER': {
                                chip.optionParams.options = this.authorizeChipWithConfigMasterData(keys, chip);
                                break;
                            }
                            case 'OVERRIDE_OBJ_LIST': {
                                chip.optionParams.options = this.authorizeChipWithConfigMasterData(keys, chip);
                                break;
                            }
                        }
                    });
                    if (!this.checkIfExistAlready(this.chipsArr, chip)) {
                        this.chipsArr.push(chip);
                    }
                    this.chipsSubject.next(this.chipsArr);
                    break;
                }
                default: {
                    if (!this.checkIfExistAlready(this.chipsArr, chip)) {
                        this.chipsArr.push(chip);
                    }
                    this.chipsSubject.next(this.chipsArr);
                    break;
                }
            }
            // this.chipsArr.push(chip);
            // this.chipsSubject.next(this.chipsArr);
        }
    }

    private authorizeChipOptions(permissionKeys: string[], data: any, chip: any) {
        // check if permission keys are available for this processing chip's criteria chip config (this has to be set in chip search criteria config)
        /*if(permissionKeys){
         let queryParams: string[] = [];
         let allowedQueryParams : string[] = [];
         // making query param array from returned data
         data.forEach((data: any) => {
         queryParams.push(data.code);
         });
         // calling authorization service : getting allowed query params.
         // allowedQueryParams = this.authorizationService.getAllowedPrams(permissionKeys, chip.dataParams[0].paramValue, queryParams);

         // checking and injecting property.
         data.forEach((data: any) => {
         data["restricted"] = -1;
         });
         return data;
         } else {
         return data;
         }*/
        /*let queryParams: string[] = [];
         let allowedQueryParams : string[] = [];
         // making query param array from returned data
         data.forEach((data: any) => {
         queryParams.push(data.code);
         });
         // calling authorization service : getting allowed query params.
         // allowedQueryParams = this.authorizationService.getAllowedPrams(permissionKeys, chip.dataParams[0].paramValue, queryParams);

         // checking and injecting property.
         data.forEach((data: any) => {
         data["restricted"] = -1;
         });*/
        return data;
    }

    private authorizeChipWithConfigMasterData(permissionKeys: string[], chip: any) {

        return chip.optionParams.options;
        /*let key;
         if (permissionKeys && permissionKeys.length && permissionKeys.length > 0) {
         key = permissionKeys;
         } else if (chip.permissionKey) {
         key = chip.permissionKey;
         }

         if (key && chip.optionParams.options && chip.optionParams.options.length > 0) {
         let queryParams = [];
         chip.optionParams.options.forEach((data: any) => {
         if (typeof(data.code) === 'string') {
         queryParams.push(data.code);
         } else {
         queryParams.push(data.code.value);
         }
         });
         let allowedQueryParams = this.authorizationService.getAllowedPrams(
         key,
         chip.dataParams[0].paramValue,
         queryParams
         );
         // if (queryParams.length > 0 && allowedQueryParams.length === 0) {
         //   // has to implement restricted property after lalinda's color blind related chip modifications
         //   chip.optionParams.disabled = true;
         // }

         // checking and injecting property.
         chip.optionParams.options.forEach((data: any) => {
         if (typeof(data.code) === 'string') {
         data['restricted'] = allowedQueryParams.indexOf(data.code) === -1;
         } else {
         if (typeof data.code.value === 'boolean') {
         data['restricted'] = allowedQueryParams.indexOf((data.code.value ? 'true' : 'false')) === -1;
         } else {
         data['restricted'] = allowedQueryParams.indexOf(data.code.value) === -1;
         }
         }
         if (this.authorizationService.isAccessiblePramDataAvailable(
         key,
         chip.dataParams[0].paramValue
         )) {
         // following only applies to a boolean chip
         if (allowedQueryParams.length === 0 && typeof chip.dataParams[0].defaultValue === 'boolean') {
         data['restricted'] = !chip.dataParams[0].defaultValue;
         } else if (allowedQueryParams.length === 1 && typeof chip.dataParams[0].defaultValue === 'boolean') {
         data['restricted'] = chip.dataParams[0].defaultValue;
         }
         }

         });

         return chip.optionParams.options;
         } else {
         return chip.optionParams.options;
         }*/
    }

    public handleInterRelatedChips(chip: Chip, params: any) {

        this.relatedChipsArr = [];

        if (chip && chip.optionParams.isRelated) {
            if (chip.optionParams.relatedChips && chip.optionParams.relatedChips.length > 0) {
                chip.optionParams.relatedChips.forEach(c => {
                    if (c.optionService && c.optionServiceMethod && c.optionServiceObservable &&
                        c.optionServiceMethod !== ''
                        && c.optionServiceObservable !== '') {
                        const srv = c.optionService;
                        const fnc = c.optionServiceMethod;
                        const obsv = c.optionServiceObservable;

                        // empty options array
                        c.options = [];

                        const dataLoader = this.injector.get(srv);

                        // reset observable
                        this.dataStore.set(DataKey[obsv], null);

                        let dataLoaderSubscription = new Subscription();
                        dataLoaderSubscription = dataLoader[fnc](params)
                            .subscribe(data => {
                                if (data && data.status && data.status.code === 1) {
                                    if (c.isLevelUp) {
                                        if (data.data && data.data[0].parent && data.data[0].parent.length > 0) {

                                            data.data[0].parent.forEach(p => {
                                                if (c.dataParam === p.type) {
                                                    c.options.push(p);
                                                    this.relatedChipsArr.push(c);
                                                    this.relatedChipsProcessSubject.next(this.relatedChipsArr);
                                                }
                                            });

                                            if (dataLoaderSubscription) {
                                                dataLoaderSubscription.unsubscribe();
                                            }
                                        }

                                        // used in not hierarchical scenario
                                        if (data.data && !data.data[0].parent) {
                                            c.options = data.data;
                                            this.relatedChipsArr.push(c);
                                            this.relatedChipsProcessSubject.next(this.relatedChipsArr);

                                            if (dataLoaderSubscription) {
                                                dataLoaderSubscription.unsubscribe();
                                            }
                                        }
                                    } else {
                                        c.options = data.data;
                                        this.relatedChipsArr.push(c);
                                        this.relatedChipsProcessSubject.next(this.relatedChipsArr);

                                        if (dataLoaderSubscription) {
                                            dataLoaderSubscription.unsubscribe();
                                        }
                                    }
                                }
                            });
                    } else {
                        c.options = params;
                        this.relatedChipsArr.push(c);
                        this.relatedChipsProcessSubject.next(this.relatedChipsArr);
                    }
                });
            }
        }
    }

    private getResourceTypes() {
        return this.dataStore.get(DataKey.resourceTypes);
    }

    private getVehicleModels() {
        return this.dataStore.get(DataKey.vehicleModels);
    }

    private getDocumentTypesForReports() {
        return this.dataStore.get(DataKey.documentTypesReports);
    }

    private getDocumentTypesForManifests() {
        return this.dataStore.get(DataKey.documentTypesManifests);
    }

    private getCountries() {
        return this.dataStore.get(DataKey.countryList);
    }

    private getCitiesOfCountry(params: string) {
        return this.dataStore.get(DataKey.cityList);
        // return this.dataStore.get(DataKey.cityList).value;
    }

    private getCities() {
        return this.dataStore.get(DataKey.cityList);
    }

    private getTransferModes() {
        return this.dataStore.get(DataKey.transferModes);
    }

    private getJobNames() {
        return this.dataStore.get(DataKey.commonJobList);
    }

    private getTransferModesWithAny() {
        return this.dataStore.get(DataKey.transferModes)
            .pipe(
                map(
                    (transferModes: any[]) => {
                        const trsModeList = [];
                        const anyTransferMode = {
                            code: 'ANY',
                            name: 'Any',
                            nameOnly: 'ANY'
                        };
                        trsModeList.push(anyTransferMode);
                        if (transferModes) {
                            trsModeList.push(...transferModes);
                        }
                        return trsModeList;
                    }
                ), catchError(
                    error => {
                        return [];
                    }
                )
            );
    }

    public getResourceTypesWithAny() {
        return this.dataStore.get(DataKey.resourceTypes)
            .pipe(
                map(
                    (resourceTypes: any[]) => {
                        const resourceList = [];
                        const anyResource = {
                            code: 'ANY',
                            name: 'Any',
                            nameOnly: 'ANY'
                        };
                        const allResource = {
                            code: 'All',
                            name: 'All',
                            nameOnly: 'All'
                        };
                        resourceList.push(anyResource);
                        resourceList.push(allResource);
                        if (resourceTypes) {
                            resourceList.push(...resourceTypes);
                        }
                        return resourceList;
                    }
                ), catchError(
                    error => {
                        return [];
                    }
                )
            );
    }

    private getCurrencyList() {
        return this.dataStore.get(DataKey.currencyList);
    }

    private getPaymentMethods() {
        return this.dataStore.get(DataKey.paymentMethods);
    }

    private getContactCategories() {
        return this.dataStore.get(DataKey.contactCategories);
    }

    private getVehicleAmenities() {
        return this.dataStore.get(DataKey.vehicleAmenities);
    }

    // not calling on initial loading
    private getAirports() {
        return this.dataStore.get(DataKey.airportArr);
    }

    private getSuppliers() {
        return this.dataStore.get(DataKey.supplierList);
    }

    private getRoutes() {
        return this.dataStore.get(DataKey.routes);
    }

    private getRoutesWithAny() {
        return this.dataStore.get(DataKey.routes)
            .pipe(
                map(
                    (routes: any[]) => {
                        const routeList = [];
                        const anyRoute = {
                            code: 'ANY',
                            name: 'ANY'
                        };
                        routeList.push(anyRoute);
                        if (routes) {
                            routeList.push(...routes);
                        }
                        return routeList;
                    }
                ), catchError(
                    error => {
                        return [];
                    }
                )
            );
    }

    private getLocationTypes() {
        return this.dataStore.get(DataKey.locationTypesArr);
    }

    // not calling on initial loading
    private getLocations() {
        return this.dataStore.get(DataKey.locationsArr);
    }

    private getAccountingEvents() {
        return this.dataStore.get(DataKey.accountingEvents);
    }

    private getLanguages() {
        return this.dataStore.get(DataKey.allLanguages);
    }

    private getAccountingUnits() {
        return this.dataStore.get(DataKey.accountingUnits);
    }

    private getKcDistChannels() {
        return this.dataStore.get(DataKey.kcDistributionChannelList);
    }

    private getKcBrands() {
        return this.dataStore.get(DataKey.kcBrandsList);
    }

    private getKcDivisions() {
        return this.dataStore.get(DataKey.kcDivisions);
    }

    public getKcCompanies() {
        return this.dataStore.get(DataKey.kcCompanies);
    }

    public getKcCompany() {
        this.dataStore.set(DataKey.kcCompany, this.dataStore.get(DataKey.kcCompanies).getValue()[0]);
        return this.dataStore.get(DataKey.kcCompany);
    }

    private getPickupTypeOfPickupLocation(param: string) {
        this.dataHandlerService.getLocationTypeOfLocation(param).subscribe(
            result => {
                if (result.data) {
                    this.dataStore.set(DataKey.locationTypeOfLocation, result.data, true);
                }
                return this.dataStore.get(DataKey.locationTypeOfLocation);
            });
    }

    private getLocationsOfPickupType(param: string) {
        this.dataHandlerService.getLocationsOfLocationType(param).subscribe(
            result => {
                if (result.data) {
                    this.dataStore.set(DataKey.locationsOfPickupType, result.data, true);
                }
                return this.dataStore.get(DataKey.locationsOfPickupType);
            });
    }

    private getLocationsOfDropOffType(param: string) {
        return this.retrieveLocationsByDropOffType(param);
    }

    retrieveLocationsByDropOffType(locationType: string) {
        this.dataHandlerService.getLocationsOfLocationType(locationType).pipe(
            map(
                result => {
                    this.dataStore.set(DataKey.locationsOfDropOffType, result.data, true);
                    return this.dataStore.get(DataKey.locationsOfDropOffType);
                }
            )
        );
    }

    generatePersistentChipsArr(
        persistentQParams, searchCriteria, persistentQParamMap, availableChips, persistentChipsArr) {
        // create a queryParamMap
        for (let i = 0; i < persistentQParams.length; i++) {
            let qParam = persistentQParams[i];
            let qParamValue = searchCriteria[qParam];
            persistentQParamMap.set(qParam, qParamValue);
        }

        // fill persistent chips
        availableChips.forEach(chip => {
            let persistChip = Object.assign({}, chip);
            persistChip.optionParams.data = [];
            let isPersist = false;

            if (chip.dataParams && chip.dataParams.length > 0) {
                chip.dataParams.forEach(dataParam => {
                    if (persistentQParamMap.has(dataParam.paramValue)) {
                        isPersist = true;
                        let dataObj = {
                            'key': dataParam.paramValue,
                            'value': persistentQParamMap.get(dataParam.paramValue)
                        };
                        persistChip.optionParams.data.push(dataObj);
                    }
                });
            }

            // push persist chips to an array
            if (isPersist) {
                persistentChipsArr.push(persistChip);
            }
        });
    }

    ////////////////////////////////////////////////////////////////////////////////

    public getChipsFromCriteria(criteria: any, chipConfig: any, isFromKey: boolean) {
        let propertyChipMap: Map<string, any> = new Map<string, any>();
        let requiredChips = [];
        let chipConfigs = null;
        if (isFromKey) {
            chipConfigs = this.configLoader.configurations.get(chipConfig);
            chipConfigs.chips.forEach(chip => {
                chip.dataParams.forEach(param => {
                    let chip_ = this.commonHelper.deepCopy(chip);
                    propertyChipMap.set(param.paramValue, chip_);
                });
            });
        } else {
            chipConfig.forEach(chip => {
                chip.dataParams.forEach(param => {
                    let chip_ = this.commonHelper.deepCopy(chip);
                    propertyChipMap.set(param.paramValue, chip_);
                });
            });
        }

        for (let key in criteria) {
            if (criteria[key] && criteria[key] !== '' && criteria[key] !== undefined) {
                let chip = propertyChipMap.get(key);
                if (chip) {
                    if (!this.isAvailableChip(chip.criteriaId, requiredChips)) {
                        let dataObj = {
                            'key': key,
                            'value': criteria[key]
                        };
                        chip.optionParams.data = [];
                        chip.optionParams.data.push(dataObj);
                        requiredChips.push(chip);
                    }
                }
            }
        }
        return requiredChips;
    }

    isAvailableChip(key: any, chips: any[]): boolean {
        for (let chip of chips) {
            if (chip.criteriaId && chip.criteriaId == key) {
                return true;
            }
        }
        return false;
    }

    public validateAndFilterChips(chips: Array<Chip>, validators: Array<string>) {
        let propertyChipMap: Map<string, Chip> = new Map<string, any>();
        let tempChips = this.commonHelper.deepCopy(chips);
        tempChips.forEach(chip => {
            chip.optionParams.data.forEach(keyVal => {
                propertyChipMap.set(keyVal.key, chip);
            });
        });
        validators.forEach(validator => {
            let chipToRemove = this.checkToRemove(propertyChipMap, validator);
            if (chipToRemove) {
                let index = tempChips.indexOf(chipToRemove);
                tempChips.splice(index, 1);
            }
        });
        return tempChips;
    }

    private checkToRemove(propertyChipMap: Map<string, Chip>, validator: string) {
        let validatorParams = validator.split('|');
        let chip = propertyChipMap.get(validatorParams[0]);
        if (!chip) {
            return null;
        }
        switch (validatorParams[1]) {
            case '>':
                return chip.optionParams.data.value > +validatorParams[2] ? null : chip;
            case '<':
                return chip.optionParams.data.value < +validatorParams[2] ? null : chip;
            case '===':
                return chip.optionParams.data.value === +validatorParams[2] ? null : chip;
            case '!==':
                return chip.optionParams.data.value !== +validatorParams[2] ? null : chip;
            case 'SNP': // Should not present
                return chip;
        }
    }

    private checkIfExistAlready(chipsArr: any[], chip: any) {
        let isContain = false;

        this.chipsArr.forEach(c => {
            if (c.criteriaId === chip.criteriaId) {
                isContain = true;
            }
        });
        return isContain;
    }

    /**
     * param data formats:
     *  date = 'DD-MM-YYYY'
     *  criteriaId = 'START_DATE' or 'END_DATE' <= this value is related to the given criteriaId in the configs.
     * */
    public setMinDateForRelatedChips(chips: any, date: string, criteriaId: string) {
        const chip = chips.chips.filter(data => data.criteriaId === criteriaId);
        const minDate = this.dateFormatter.dateFromString(date);
        if (chip[0] && chip[0].optionParams && chip[0].optionParams.behaviouralData) {
            const tempChip = chip[0].optionParams.behaviouralData.filter(data => data.key === 'minDate');
            if (tempChip) {
                tempChip[0].value = minDate;
            } else {
                chip[0].optionParams.behaviouralData.push({key: 'minDate', value: minDate});
            }
        }
    }
}
