<div class="detail-group__body-service-item container-fluid">
  <div class="detail-group__heading container-fluid">
    <div class="item-header">
      <div class="item-header__content">{{'Item Passengers' | translate}}</div>
    </div>
  </div>
  <div class="tc-px-3">
    <ag-grid-angular
            class="ag-theme-material tc-ag-grid tc-ag-grid--full-width tc-ag-grid--popup-content"
            [rowData]="bookingItem?.bookingPassengers"
            [defaultColDef]="defaultColDef"
            [columnDefs]="columnDefs"
            [domLayout]="'autoHeight'"
            [overlayNoRowsTemplate]="overlayNoRowsTemplate"
            [frameworkComponents]="frameworkComponents"
            (gridReady)="onGridReady($event)">
    </ag-grid-angular>
  </div>
</div>

<div class="container assign-panel-footer-section">
  <div *ngIf="disableClose" style="color: red">{{disableCloseMsg}}</div>
  <button
          class="assign-panel-footer-section__button tc-button-secondary mat-button"
          (click)="onClose($event)">
    {{'Close' | translate}}
  </button>
</div>
