import {
    Component,
    DoCheck,
    EventEmitter,
    Input,
    KeyValueDiffer,
    KeyValueDiffers,
    OnInit,
    Output
} from '@angular/core';
import { CurrencyFormat } from '@tc-core/model/it/codegen/ui/framework/currency-format';
import { Icon } from '@tc-core/model/it/codegen/ui/framework/icon';
import { TC } from '@tc-core/util';
import { ConfigLoader } from '@tc-core/util/framework';
import { TCO } from '../../constants';
import { TimelineEvent } from '../../widgets/framework/cg-timeline/timeline-event';
import { AssignmentSummary } from '../../models/assignment-summary';
import { ServiceItemChanges } from '../../models/helper/service-item-changes';
import { SearchResultSummary } from '../../models/reservation/search-result-summary';
import { ResourceAvailability } from '../../models/resource/resource-availability';

@Component({
    selector: 'tc-resource-allocation-card',
    templateUrl: './resource-allocation-card.component.html'
})
export class ResourceAllocationCardComponent implements OnInit, DoCheck {

    @Input() summaryContent: ResourceAvailability;
    @Input() iconConfig: Icon;
    @Input() isRow1col4String = false;
    @Input() itemGroup: SearchResultSummary;
    @Input() bookingData: any;
    @Input() bookingDataArray: any[];
    @Input() assignedVehicle: ServiceItemChanges;
    @Input() isBulkAssignment = false;

    @Output() resourceCalendarClick: EventEmitter<any> = new EventEmitter();
    @Output() eventClick: EventEmitter<AssignmentSummary> = new EventEmitter();
    @Output() targetClick: EventEmitter<any> = new EventEmitter();

    currencyFormat: CurrencyFormat;
    isUpdated = false;
    isCancelled = false;
    assignments: AssignmentSummary[] = [];

    timelineEvents: TimelineEvent[] = [];
    leftBufferHours = -1;
    rightBufferHours = -1;

    isTargetBlock = false;
    targetPointTime: Date = new Date();
    targetBlockTimeStart: Date = null;
    targetBlockTimeEnd: Date = null;

    row1col1_customsStyles = [];
    row1col2_customsStyles = [];
    row1col3_customsStyles = [];
    row1col4_customsStyles = [];

    row2col1_customsStyles = [];
    row2col2_customsStyles = [];
    row2col3_customsStyles = [];
    row2col4_customsStyles = [];

    row3col1_customsStyles = [];

    private summaryCardDiffer: KeyValueDiffer<string, any>;


    constructor(private configLoader: ConfigLoader, private differs: KeyValueDiffers) {
    }

    ngOnInit() {
        this.createTimelineEvents();
        this.initTimeline();
        this.createTargetPointOrBlock();
        this.overrideStyles();
        this.currencyFormat = this.getCurrencyFormat();
        this.summaryCardDiffer = this.differs.find(this.summaryContent).create();
    }

    ngDoCheck(): void {
        const changes = this.summaryCardDiffer.diff(this.summaryContent);
        if (changes) {
            console.log('summary card data changed');
            this.createTimelineEvents();
        }
    }

    initTimeline() {
        const timelineConfig = this.configLoader.configurations.get(TCO.CONF.CONF_TIMELINE_UNIT_CONFIG)['resource-allocation-timeline'];
        this.leftBufferHours = timelineConfig.leftBufferHours;
        this.rightBufferHours = timelineConfig.rightBufferHours;
    }

    private overrideStyles() {
        this.row1col1_customsStyles = ['tc-strip-text--primary'];
        this.row1col2_customsStyles = ['tc-strip-text--primary'];
        this.row1col3_customsStyles = [];
        this.row1col4_customsStyles = [
            'tc-strip-text--right',
            'tc-strip-text--primary',
            'tc-strip__price',
            'tc-strip__price--x-large'
        ];

        this.row2col1_customsStyles = [];
        this.row2col2_customsStyles = ['tc-strip-text--primary'];
        this.row2col3_customsStyles = [];
        this.row2col4_customsStyles = ['tc-strip-text__label', 'tc-strip-text--right'];
    }

    private getCurrencyFormat(): CurrencyFormat {
        let currencyFormat;
        const currencySymbolPattern = this.configLoader.configurations.get(TC.CONF.CONF_SYSTEM).defaults.default_currency_symbol_pattern;
        currencyFormat = new CurrencyFormat(TCO.AppData.DEF_CURRENCY_TYPE, currencySymbolPattern);
        return currencyFormat;
    }

    public createTimelineEvents() {
        this.assignments = this.summaryContent.assignments ? this.summaryContent.assignments : [];
        this.timelineEvents = [];
        for (const assignment of this.assignments) {
            const timelineEvent = new TimelineEvent();
            timelineEvent.startTime = assignment.startTimeObj;
            timelineEvent.endTime = assignment.endTimeObj;
            timelineEvent.waitingEndTime = assignment.waitingEndTimeObj;
            timelineEvent.assignment = assignment;
            this.timelineEvents.push(timelineEvent);
        }
    }

    createTargetPointOrBlock() {
        if (this.isBulkAssignment) {
            this.targetBlockTimeStart = this.itemGroup.startTimeObj;
            this.targetBlockTimeEnd = this.itemGroup.endTimeObj;
        } else {
            this.targetPointTime = new Date(this.bookingData.trsServiceItem.departureDateTime);
        }
    }

    onResourceCalendarClick(resource: any) {
        this.resourceCalendarClick.emit(resource);
    }

    onTargetClick(target: any) {
        this.targetClick.emit(this.summaryContent);
    }

    onEventClick(event: TimelineEvent) {
        this.eventClick.emit(event.assignment);
    }
}
