<div class="trade-client-detail-view">
    <tc-expanded-content-card-body>
        <ng-container *ngIf="supplier">
            <tc-supplier-personal-info-card
                    [supplier]="supplier"
                    [resourceTypes]="resourceTypes"
                    class="tc-list-card">
            </tc-supplier-personal-info-card>

            <mat-accordion class="rs-full-width tc-accordion" [multi]="true">
                <!--key-control-card-->
                <mat-expansion-panel *ngIf="userManagementService.notAvailableFor('FC')">
                    <mat-expansion-panel-header class="tc-accordion-header__container">
                        <div class="tc-accordion-header">
                            <span class="tc-accordion-header__title">{{"Key Controls" | translate}}</span>
                            <i class="material-icons tc-accordion-header__down-icon">keyboard_arrow_down</i>
                        </div>
                    </mat-expansion-panel-header>
                    <tc-key-control-detail-card
                            [keyControlHolder]="supplier"
                            class="tc-list-card">
                    </tc-key-control-detail-card>
                </mat-expansion-panel>
                <!--contact-details-card-->
                <mat-expansion-panel>
                    <mat-expansion-panel-header class="tc-accordion-header__container">
                        <div class="tc-accordion-header">
                            <span class="tc-accordion-header__title">{{"Contact details" | translate}}</span>
                            <i class="material-icons tc-accordion-header__down-icon">keyboard_arrow_down</i>
                        </div>
                    </mat-expansion-panel-header>
                    <tc-contact-details-card
                            [showTitle]="false"
                            [contactDetails]="supplier.contactDetails"
                            class="tc-list-card">
                    </tc-contact-details-card>
                </mat-expansion-panel>

                <!--payment-info-card-->
                <mat-expansion-panel>
                    <mat-expansion-panel-header class="tc-accordion-header__container">
                        <div class="tc-accordion-header">
                            <span class="tc-accordion-header__title">{{"Payment Information" | translate}}</span>
                            <i class="material-icons tc-accordion-header__down-icon">keyboard_arrow_down</i>
                        </div>
                    </mat-expansion-panel-header>
                    <tc-payment-info-card
                            [showTitle]="false"
                            [paymentInfo]="supplier.paymentInfo"
                            class="tc-list-card">
                    </tc-payment-info-card>
                </mat-expansion-panel>

                <!--additional-contacts-card-->
                <mat-expansion-panel>
                    <mat-expansion-panel-header class="tc-accordion-header__container">
                        <div class="tc-accordion-header">
                            <span class="tc-accordion-header__title">{{"Additional Contacts" | translate}}</span>
                            <i class="material-icons tc-accordion-header__down-icon">keyboard_arrow_down</i>
                        </div>
                    </mat-expansion-panel-header>
                    <tc-additional-contacts-card
                            [showTitle]="false"
                            [contactDetails]="supplier.contactDetails"
                            class="tc-list-card">
                    </tc-additional-contacts-card>
                </mat-expansion-panel>

                <!--room-card-->
<!--                <mat-expansion-panel>-->
<!--                    <mat-expansion-panel-header class="tc-accordion-header__container">-->
<!--                        <div class="tc-accordion-header">-->
<!--                            <span class="tc-accordion-header__title">{{"Rooms" | translate}}</span>-->
<!--                            <i class="material-icons tc-accordion-header__down-icon">keyboard_arrow_down</i>-->
<!--                        </div>-->
<!--                    </mat-expansion-panel-header>-->
<!--                    <tc-rooms-->
<!--                            [showTitle]="false"-->
<!--                            [rooms]="supplier.rooms"-->
<!--                            class="tc-list-card">-->
<!--                    </tc-rooms>-->
<!--                </mat-expansion-panel>-->

                <!--supplier-attributes-card-->
                <mat-expansion-panel>
                    <mat-expansion-panel-header class="tc-accordion-header__container">
                        <div class="tc-accordion-header">
                            <span class="tc-accordion-header__title">{{"Supplier Attributes" | translate}}</span>
                            <i class="material-icons tc-accordion-header__down-icon">keyboard_arrow_down</i>
                        </div>
                    </mat-expansion-panel-header>
                    <tc-supplier-attributes-card
                            [showTitle]="false"
                            [supplierAttributes]="supplier.supplierAttributes"
                            class="tc-list-card">
                    </tc-supplier-attributes-card>
                </mat-expansion-panel>

                <!--cost-setup-details-card-->
                <mat-expansion-panel>
                    <mat-expansion-panel-header class="tc-accordion-header__container">
                        <div class="tc-accordion-header">
                            <span class="tc-accordion-header__title">{{"Transport Cost Setup Data" | translate}}</span>
                            <i class="material-icons tc-accordion-header__down-icon">keyboard_arrow_down</i>
                        </div>
                    </mat-expansion-panel-header>
                    <tc-cost-setup-data-card
                            [showTitle]="false"
                            [seasons]="supplier.seasons"
                            [currencyFormat]="currencyFormat"
                            [trsModes]="trsModes"
                            [vehicleModels]="vehicleModels"
                            [costingTypes]="costingTypes"
                            class="tc-list-card">
                    </tc-cost-setup-data-card>
                </mat-expansion-panel>

                <!--cost-setup-details-card-->
                <mat-expansion-panel>
                    <mat-expansion-panel-header class="tc-accordion-header__container">
                        <div class="tc-accordion-header">
                            <span class="tc-accordion-header__title">{{"Generic Cost Setup Data" | translate}}</span>
                            <i class="material-icons tc-accordion-header__down-icon">keyboard_arrow_down</i>
                        </div>
                    </mat-expansion-panel-header>
                    <tc-gen-cost-setup-data-card
                            [showTitle]="false"
                            [seasons]="supplier.seasons"
                            [currencyFormat]="currencyFormat"
                            [resourceTypes]="resourceTypes"
                            [profileTypes]="profileTypes"
                            [costingTypes]="costingTypes"
                            class="tc-list-card">
                    </tc-gen-cost-setup-data-card>
                </mat-expansion-panel>


                <!--cost-setup-details-card-->
<!--                <mat-expansion-panel>-->
<!--                    <mat-expansion-panel-header class="tc-accordion-header__container">-->
<!--                        <div class="tc-accordion-header">-->
<!--                            <span class="tc-accordion-header__title">{{"Room Cost Setup Data" | translate}}</span>-->
<!--                            <i class="material-icons tc-accordion-header__down-icon">keyboard_arrow_down</i>-->
<!--                        </div>-->
<!--                    </mat-expansion-panel-header>-->
<!--                    <tc-room-cost-setup-data-card-->
<!--                            [showTitle]="false"-->
<!--                            [seasons]="supplier.seasons"-->
<!--                            [currencyFormat]="currencyFormat"-->
<!--                            [costingTypes]="costingTypes"-->
<!--                            class="tc-list-card">-->
<!--                    </tc-room-cost-setup-data-card>-->
<!--                </mat-expansion-panel>-->

                <!--tax-schemes-card-->
                <mat-expansion-panel>
                    <mat-expansion-panel-header class="tc-accordion-header__container">
                        <div class="tc-accordion-header">
                            <span class="tc-accordion-header__title">{{"Tax Schemes" | translate}}</span>
                            <i class="material-icons tc-accordion-header__down-icon">keyboard_arrow_down</i>
                        </div>
                    </mat-expansion-panel-header>
                    <tc-tax-schemes-card
                            [showTitle]="false"
                            [taxSchemes]="supplier.taxSchemes"
                            class="tc-list-card">
                    </tc-tax-schemes-card>
                </mat-expansion-panel>

                <!--documents-card-->
                <mat-expansion-panel>
                    <mat-expansion-panel-header class="tc-accordion-header__container">
                        <div class="tc-accordion-header">
                            <span class="tc-accordion-header__title">{{"Documents" | translate}}</span>
                            <i class="material-icons tc-accordion-header__down-icon">keyboard_arrow_down</i>
                        </div>
                    </mat-expansion-panel-header>
                    <tc-document-card
                            [showTitle]="false"
                            [documents]="supplier.documents"
                            class="tc-list-card">
                    </tc-document-card>
                </mat-expansion-panel>

            </mat-accordion>
        </ng-container>
    </tc-expanded-content-card-body>
</div>
