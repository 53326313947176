import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {TcApiError} from '@tc-core/model/it/codegen/tbx/ext/errors/tc-api-error';
import {TcHttpError} from '@tc-core/model/it/codegen/tbx/ext/errors/tc-http-error';
import {Journey} from '@tc-core/model/it/codegen/ui/framework/journey';
import {TC} from '@tc-core/util';
import {ConfigLoader, EventManager, UserJourneyManager} from '@tc-core/util/framework';
import {CommonHelper} from '@tc-core/util/helpers';
import {DateFormatter} from '@tc-core/util/system';
import {SpinnerService} from '@tc-core/util/ui';
import {ChipInputComponent} from '@tc/chip-input';
import {ChipInputService} from '@tc/chip-input/chip-input.service';
import * as moment from 'moment';
import {Subscription} from 'rxjs';
import {DateTimeProcessor} from '../../../services/business-utils/time-zone/date-time-processor';
import {ChipHandlerService} from '../../../services/helpers/chip-handler.service';
import {RootService} from '../../../services/util/core-services/root.service';
import {ActionHandlerService} from '../../../services/util/framework/action-handler-service.service';
import {DataKey, DataStoreService} from '../../../services/util/framework/data-store.service';
import {DMCQueryParamsService} from '../../../services/util/framework/dmc-query-params.service';
import {UserManagementService} from '../../../services/user-management/user-management.service';

@Component({
    selector: 'tc-grid-search-criteria',
    templateUrl: './grid-search-criteria.component.html',
    providers: [
        ChipHandlerService,
        {provide: 'ChipHandlerService', useExisting: ChipHandlerService},
        ChipInputService
    ]
})
export class GridSearchCriteriaComponent implements OnInit, OnDestroy {

    // required
    @Input() inputSearchCriteria: any;
    @Input() createNewSearchCriteriaFn: any;
    @Input() searchCriteriaUpdatingDataKey: DataKey;
    @Input() searchResultsDataKey: DataKey;
    @Input() searchCriteriaLoadingDataKey: any;
    @Input() chipConfigKey: any;
    @Input() clearLoadedSearchCriteriaFn: any;
    @Input() placeHolderText = 'Search Items';

    @Input() isInsideForm = false;
    @Input() searchCriteriaChipConfig: string;
    @Input() isSearchBarStyleNotAvailable = false;

    @Output() searchTrigger: EventEmitter<boolean> = new EventEmitter();
    @Output() searchCriteria: EventEmitter<any> = new EventEmitter();

    tPagination: any;

    journey: string = null;

    isChipsInit = false;
    availableChips: any[] = [];
    persistentChipsArr = [];
    persistentQParams: string[] = [];
    persistentQParamMap = new Map<string, string>();
    modifiedChip;
    isDataLoaded = true;
    isTriggerSearch = true;
    displayChipsArr = [];
    isFocus = false;
    isChipListDisplay = false;

    @ViewChild('search_chip_input') searchChipInput: ChipInputComponent;

    private relatedChipsObsv: Subscription = new Subscription();
    private routerChangeSubscription: Subscription;
    private selectedChipsObsv: Subscription = new Subscription();
    private selectedFieldsObsv: Subscription = new Subscription();
    private chipValueUpdateSub: Subscription = new Subscription();
    private criteriaUpdateSubscription: Subscription = new Subscription();
    private deletedChipObsv: Subscription = new Subscription();
    private searchResultObserver: Subscription = new Subscription();
    private errorsObserver: Subscription = new Subscription();
    private userJourneyObserver: Subscription = new Subscription();
    private focusSearchBarObserver: Subscription = new Subscription();
    private closePopUpObserver: Subscription = new Subscription();
    private triggerSearchCriteriaObserver: Subscription = new Subscription();

    private selectedChips = [];
    private chipInputService;
    private chipHandlerSubject: Subscription = new Subscription();
    private relatedChipsProcessObsv: Subscription = new Subscription();

    constructor(
        private activatedRoute: ActivatedRoute,
        private configLoader: ConfigLoader,
        private userJourneyManager: UserJourneyManager,
        private dataStoreService: DataStoreService,
        private actionHandlerService: ActionHandlerService,
        private em: EventManager,
        private chipHandler: ChipHandlerService,
        private queryParamService: DMCQueryParamsService,
        private commonHelper: CommonHelper,
        private spinnerService: SpinnerService,
        private rootService: RootService,
        private dateFormatter: DateFormatter,
        private userManagementService: UserManagementService
    ) {
    }

    ngOnInit() {
        this.chipInputService = this.searchChipInput.criteriaInputService;

        // clear data store
        this.dataStoreService.set(this.searchCriteriaUpdatingDataKey, null, true);
        this.dataStoreService.set(this.searchResultsDataKey, null, true);

        this.handleQuickActions();
        this.userJourneyObserver = this.userJourneyManager.journey
            .subscribe((journey: Journey) => {
                if (journey && JSON.stringify(journey) !== JSON.stringify({})) {
                    this.journey = journey.id;
                }
            });

        // resetting the view
        this.routerChangeSubscription = this.userJourneyManager.routeChanges
            .subscribe(e => {
                if (e) {
                    this.chipInputService.fullReset(); // fully reset chips
                    this.chipHandler.chipsArr = []; // reset chips array loaded from config
                    // check journey begins from quick book
                    if (!this.isInsideForm) {
                        this.getQueryParams();
                        this.initSearchCriteriaChips();
                    }

                    console.log('displayChipsArr', this.displayChipsArr);
                    this.resetQueryParams();
                }
            });

        if (!this.isInsideForm) {
            this.getQueryParams();
            if (!this.isChipsInit) {
                this.initSearchCriteriaChips(); // load everything from start
            }

            this.inputSearchCriteria = this.createNewSearchCriteriaFn();

        }

        this.getSearchObserver();
        this.errorObserver();
        this.selectedChipsUpdate();
        this.handleInterRelatedChips();
        this.notifyChipDelete();
        this.shortCutObserver();
    }

    getQueryParams() {
        let queryParams: string[];
        queryParams = this.queryParamService.getQueryParamsArray(this.inputSearchCriteria);
        this.inputSearchCriteria = this.rootService.loadDataFromLocalStorage(
            queryParams,
            this.createNewSearchCriteriaFn(),
            this.searchCriteriaLoadingDataKey
        );

        if (this.inputSearchCriteria && this.inputSearchCriteria.startDate && this.inputSearchCriteria.endDate) {
            DateTimeProcessor.convertLocalStorageDatesToISOString(this.inputSearchCriteria);
        }

        if (this.inputSearchCriteria && this.inputSearchCriteria.startDate && !this.inputSearchCriteria.endDate) {
            DateTimeProcessor.convertLocalStorageStartDatesToISOString(this.inputSearchCriteria);
        }

        if (this.inputSearchCriteria && this.inputSearchCriteria.endDate && !this.inputSearchCriteria.startDate) {
            DateTimeProcessor.convertLocalStorageEndDatesToISOString(this.inputSearchCriteria);
        }

        if (this.inputSearchCriteria && this.inputSearchCriteria.dispatchStartDate && this.inputSearchCriteria.dispatchEndDate) {
            DateTimeProcessor.convertLocalStorageDispatchDatesToISOString(this.inputSearchCriteria);
        }

        if (this.inputSearchCriteria && this.inputSearchCriteria.dispatchStartDate && !this.inputSearchCriteria.dispatchEndDate) {
            DateTimeProcessor.convertLocalStorageDispatchStartDatesToISOString(this.inputSearchCriteria);
        }

        if (this.inputSearchCriteria && this.inputSearchCriteria.dispatchEndDate && !this.inputSearchCriteria.dispatchStartDate) {
            DateTimeProcessor.convertLocalStorageDispatchEndDatesToISOString(this.inputSearchCriteria);
        }

        this.userManagementService.updateSearchCriteriaWithUserKC(this.inputSearchCriteria);

        // get persisting query params
        if (this.inputSearchCriteria) {
            this.persistentQParams = [];
            this.persistentQParams = this.queryParamService.getNotNullQueryParamsArray(this.inputSearchCriteria);
        }
    }

    private shortCutObserver() {
        this.focusSearchBarObserver = this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'FOCUS_SEARCH_BAR')
            .subscribe(e => {
                this.isFocus = true;
            });
        this.closePopUpObserver = this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'CLOSE_POPUPS')
            .subscribe(e => {
                this.isFocus = false;
            });
    }

    public onFocus(event: boolean) {
        this.isFocus = event;
    }

    initSearchCriteriaChips() {
        this.isChipsInit = true;
        this.chipInputService.fullReset();
        this.chipHandler.chipsArr = [];

        // read chip config
        let criteriaChipConfigs = null;
        if (!this.isInsideForm) {
            const chipConfigs =  this.configLoader.configurations.get(this.chipConfigKey);
            criteriaChipConfigs = this.commonHelper.recreateJsonObject(chipConfigs);
        } else {
            const chipConfigs = this.configLoader.configurations.get(this.searchCriteriaChipConfig);
            criteriaChipConfigs = this.commonHelper.recreateJsonObject(chipConfigs);
        }
        if (this.inputSearchCriteria && this.inputSearchCriteria.startDate && (this.inputSearchCriteria.endDate === null || this.inputSearchCriteria.endDate === '')) {
            this.chipHandler.setMinDateForRelatedChips(criteriaChipConfigs, this.inputSearchCriteria.startDate, 'END_DATE');
        }
        if (this.inputSearchCriteria && this.inputSearchCriteria.startDate) {
            this.chipHandler.setMinDateForRelatedChips(criteriaChipConfigs, this.inputSearchCriteria.startDate, 'DOC_QUEUE_END_DATE');
        }
        if (this.inputSearchCriteria && this.inputSearchCriteria.dispatchStartDate) {
            this.chipHandler.setMinDateForRelatedChips(criteriaChipConfigs, this.inputSearchCriteria.dispatchStartDate.substring(0, 10), 'DISPATCH_END_DATE');
        }
        if (criteriaChipConfigs && criteriaChipConfigs.chips) {
            criteriaChipConfigs.chips.forEach(chip => {
                chip = this.chipHandler.preProcessChips(chip, criteriaChipConfigs.permissionKeys.keys);
            });
        }

        let isDataLoaded = false;
        this.chipHandler.chipsSubject.subscribe(data => {
                if (data && JSON.stringify(data) !== JSON.stringify([]) && !isDataLoaded) {
                    isDataLoaded = true;
                    this.availableChips = data; // new json object here;
                    this.userManagementService.updateSearchChipsAccordingToUserKC(this.availableChips);
                    this.chipHandler.chipsSubject.next(null);

                    if (this.chipHandlerSubject) {
                        this.chipHandlerSubject.unsubscribe();
                    }

                    // set values to display chips
                    this.displayChipsArr = [];
                    this.displayChipsArr = this.availableChips;

                    this.dataStoreService.set(DataKey.chips, this.availableChips, true);
                    this.chipInputService.init(this.inputSearchCriteria, this.availableChips, false, true);
                    this.isChipsInit = true;

                    // implement chip persistence
                    if (this.persistentQParams && this.persistentQParams.length > 0) {
                        // create a queryParamMap
                        this.persistentQParams.forEach(qParam => {
                            const qParamValue = this.inputSearchCriteria[qParam];
                            if (qParam && qParamValue) {
                                this.persistentQParamMap.set(qParam, qParamValue);
                            }
                        });
                        this.persistentChipsArr = [];
                        // fill persistent chips
                        this.availableChips.forEach(chip => {
                            const persistChip = Object.assign({}, chip);
                            persistChip.optionParams.data = [];
                            let isPersist = false;
                            if (chip.dataParams && chip.dataParams.length > 0) {
                                chip.dataParams.forEach(dataParam => {
                                    if (this.persistentQParamMap.has(dataParam.paramValue)) {
                                        isPersist = true;
                                        const dataObj = {
                                            key: dataParam.paramValue,
                                            value: this.persistentQParamMap.get(dataParam.paramValue)
                                        };
                                        persistChip.optionParams.data.push(dataObj);
                                    }
                                });
                            }
                            // push persist chips to an array
                            if (isPersist) {
                                this.persistentChipsArr.push(persistChip);
                            }
                        });

                        // persist chips
                        if (this.persistentChipsArr && this.persistentChipsArr.length > 0) {
                            this.persistentChipsArr.forEach(value => {
                                if (value && value.optionParams && value.optionParams.isMandatory === true) {
                                    if (value.criteriaType === 'CALENDAR' && typeof value.optionParams.data[0].value !==
                                        'string') {
                                        value.optionParams.data[0].value = moment(value.optionParams.data[0].value)
                                            .format('YYYY-MM-DD');
                                    }
                                }
                            });

                            this.chipInputService.persistChips(this.persistentChipsArr);

                            // trigger search when chips persisting
                            this.isDataLoaded = false;
                            // this.isTriggerSearch = false;
                            this.onSearch(this.inputSearchCriteria);
                        }
                    }

                    this.chipInputService.criteriaUpdate.subscribe((updatedCriteriaData) => {
                        this.inputSearchCriteria = updatedCriteriaData;
                    });
                }
            }
        );
    }

    public onSearch(event) {
        this.isDataLoaded = false;
        this.inputSearchCriteria = event;
        this.inputSearchCriteria = this.userManagementService.updateSearchCriteriaWithUserKC(this.inputSearchCriteria);
        this.chipInputService.selectedChipsSubject
            .subscribe(selectedChips => {
                this.selectedChips = selectedChips;
            });

        const selectedChipsParamValues = [];
        this.selectedChips.forEach(value => {
            if ((value['chip'] && value['chip']['dataParams'] && value['chip']['dataParams'][0]['paramValue'])) {
                selectedChipsParamValues.push(value['chip']['dataParams'][0]['paramValue']);
            }
        });

        if (this.inputSearchCriteria && selectedChipsParamValues) {
            for (const item in (this.inputSearchCriteria)) {
                if (!selectedChipsParamValues.includes(item)) {
                    this.inputSearchCriteria[item] = null;
                }
            }
        }

        this.userJourneyManager.canProceed.next(false);

        if (JSON.stringify(this.inputSearchCriteria) !== JSON.stringify({})) {
            this.inputSearchCriteria.isForceSearch = true;
            this.dataStoreService.set(this.searchCriteriaUpdatingDataKey, this.inputSearchCriteria, true);

            // set criteria parameters to url
            let queryParams: string[] = [];
            queryParams = this.queryParamService.getQueryParamsArray(this.inputSearchCriteria);
            this.rootService.setDataToLocalStorage(this.searchCriteriaLoadingDataKey, this.inputSearchCriteria, true,
                queryParams, true
            );

            this.isTriggerSearch = false;
            this.isDataLoaded = false;
            this.searchTrigger.emit(true);

        } else {
            setTimeout(() => {
                this.isDataLoaded = true;
                this.isTriggerSearch = true;
            }, 0);
        }
    }

    getUpdatedFieldsDataObject(chip, criteria: any) {
        const dataObjArr = [];

        if (chip && chip.dataParams && chip.dataParams.length > 0) {
            chip.dataParams.forEach(dataParam => {
                const dataObj = {
                    key: '',
                    value: ''
                };
                dataObj.key = dataParam.paramValue;
                dataObj.value = criteria[dataParam.paramValue];

                dataObjArr.push(dataObj);
            });
        }

        return dataObjArr;
    }

    private getSearchObserver() {
        this.searchResultObserver = this.dataStoreService.get(this.searchResultsDataKey)
            .subscribe((data) => {
                if (this.commonHelper.dataValidity(data)) {
                    this.isDataLoaded = true;
                    this.isTriggerSearch = false;
                } else if (this.commonHelper.isEmptyData(data)) {
                    this.isDataLoaded = true;
                } else if (data instanceof TcApiError) {
                    this.isDataLoaded = true;
                } else if (data instanceof TcHttpError) {
                    this.isDataLoaded = true;
                }
            }, (error: any) => {
                console.log(error);
            });

    }

    private errorObserver() {
        this.errorsObserver = this.dataStoreService.get(DataKey.error)
            .subscribe((data) => {
                if (data && JSON.stringify(data) !== JSON.stringify({})) {
                    setTimeout(() => {
                        this.isDataLoaded = true;
                    }, 0);
                    this.dataStoreService.set(DataKey.error, null);
                }
            });
    }

    private resetQueryParams() {
        let qParamsArray: string[] = [];
        qParamsArray = this.queryParamService.getQueryParamsArray(this.createNewSearchCriteriaFn());

        const searchCriteria: any = this.rootService.loadDataFromLocalStorage(qParamsArray,
            this.createNewSearchCriteriaFn(), this.searchCriteriaLoadingDataKey
        );

        // set criteria parameters to url
        let queryParams: string[] = [];
        queryParams = this.queryParamService.getQueryParamsArray(searchCriteria);

        this.rootService.setDataToLocalStorage(this.searchCriteriaLoadingDataKey, this.inputSearchCriteria,
            true, queryParams, true
        );
    }

    public onCancel($event) {
        console.log('cancelled');
    }

    public onChipListDisplay(event) {
        this.dataStoreService.set(DataKey.chipListDisplay, null, true);
        this.isChipListDisplay = event;
        this.dataStoreService.set(DataKey.chipListDisplay, this.isChipListDisplay, true);

    }

    handleQuickActions() {
        this.triggerSearchCriteriaObserver = this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'TRIGGER_SEARCH_CRITERIA')
            .subscribe((e) => {
                this.triggerSearch(e.data);
            });
    }

    triggerSearch(data: string) {
        this.inputSearchCriteria = this.createNewSearchCriteriaFn();
        // todo criteria

        this.actionHandlerService.fillCriteriaByActionData(data, this.inputSearchCriteria);
        const requiredChips = this.chipHandler.getChipsFromCriteria(
            this.inputSearchCriteria,
            this.chipConfigKey,
            true
        );
        this.chipInputService.hardReset();
        this.chipInputService.persistChips(requiredChips, true);
        this.onSearch(this.inputSearchCriteria);
    }

    private handleInterRelatedChips() {
        this.relatedChipsObsv = this.chipInputService.relatedChipsSubject.subscribe(
            (data) => {
                let toggleChips = [];
                if (data && data.chip && JSON.stringify(data) !== JSON.stringify({})) {
                    this.modifiedChip = data.chip;
                    // TODO: refine chip input service: inter related chips
                    if (data.chip.type === 'CALENDAR') {
                        // startDate & returnDate validate
                        if (data.chip.id === 'START_DATE' || data.chip.id === 'DOC_QUEUE_START_DATE' || data.chip.id === 'DISPATCH_START_DATE') {
                            const startDate = data.date;
                            const nights = 2;
                            const currentEndDate = this.checkDependentForCalculateRelatedDate(data.chip);

                            this.setMinDateForRelatedDateChips(data.chip, data.date);

                            if (currentEndDate) {
                                if (this.dateFormatter.isAfter(startDate, currentEndDate)) {
                                    this.chipHandler.handleInterRelatedChips(
                                        data.chip,
                                        this.dateFormatter.addDaysToDate(startDate, nights)
                                    );
                                } else {
                                    this.chipHandler.handleInterRelatedChips(data.chip, currentEndDate);
                                }
                            } else {
                                this.chipHandler.handleInterRelatedChips(
                                    data.chip,
                                    this.dateFormatter.addDaysToDate(startDate, nights)
                                );
                            }
                        }

                    } else {
                        this.chipHandler.handleInterRelatedChips(data.chip, data.options.value[0]);
                    }


                    if (data.chip.optionParams.toggleChips && data.chip.optionParams.toggleChips.length > 0) {
                        toggleChips = data.chip.optionParams.toggleChips;
                        if (toggleChips && data.options && data.options.value[0].value) {
                            this.chipInputService.selectedChipsBasedOnStateChange(toggleChips);
                        } else {
                            this.chipInputService.deleteChipsBasedOnStateChange(toggleChips);
                        }
                    }
                }
            }
        );

        this.relatedChipsProcessObsv = this.chipHandler.relatedChipsProcessSubject.subscribe((result) => {
            if (result && JSON.stringify(result) !== JSON.stringify([]) &&
                this.modifiedChip && this.modifiedChip.optionParams.relatedChips &&
                this.modifiedChip.optionParams.relatedChips.length > 0 &&
                result.length === this.modifiedChip.optionParams.relatedChips.length) {

                // pass related chips to chip input service
                this.chipInputService.handleInterRelatedChips(result);
            }
        });
    }

    private setMinDateForRelatedDateChips(chip: any, date: string) {
        if (date && chip && chip.optionParams && chip.optionParams.relatedChips) {
            const minDate = this.dateFormatter.dateFromString(date);
            chip.optionParams.relatedChips.forEach(relateChip => {
                if (relateChip.criteriaType === 'CALENDAR') {
                    relateChip.data = [{key: 'minDate', value: minDate}];
                }
            });
        }
    }

    private checkDependentForCalculateRelatedDate(chip: any) {
        let dateParam;
        if (chip.optionParams && chip.optionParams.relatedChips) {
            const dependent = chip.optionParams.behaviouralData.find(
                behData => behData.key === 'DATE_RELATED_DEPENDENT');
            if (dependent) {
                dateParam = this.getValueOfDependentParameter(dependent.value);
            }
        }
        return dateParam;
    }

    private getValueOfDependentParameter(dependent: any): any {
        if (this.chipInputService && this.chipInputService.criteria &&
            this.chipInputService.criteria[dependent.paramValue]) {
            return this.chipInputService.criteria[dependent.paramValue];
        }
        return '';
    }

    public selectedChipsUpdate() {
        this.criteriaUpdateSubscription = this.chipInputService.selectedChipsSubject
            .subscribe(selectedChips => {
                this.selectedChips = selectedChips;
                if (selectedChips && JSON.stringify(selectedChips) !==
                    JSON.stringify([])) {
                    // toggle chips when selecting BRANCH_OF_CLIENT_ID chip
                    for (const selectedChip of this.selectedChips) {
                        if (selectedChip.chip.id === 'BRANCH_OF_CLIENT_ID' &&
                            selectedChip.chip.optionParams.toggleChips &&
                            selectedChip.chip.optionParams.toggleChips.length > 0) {
                            this.deleteToggleChips(selectedChip.chip.optionParams.toggleChips);
                            this.disableChips(selectedChip.chip.optionParams.toggleChips);
                            break;
                        }
                    }
                }
            });
    }

    private notifyChipDelete() {
        this.deletedChipObsv = this.chipInputService.deletedChipsSubject
            .subscribe(deletedChip => {
                if (JSON.stringify(deletedChip) !== JSON.stringify({}) && deletedChip !== null &&
                    deletedChip !==
                    undefined) {
                    if (deletedChip.id === 'CLIENT_TYPE') {
                        const deleteChips = this.selectedChips.map(chip => chip.chip);
                        this.deleteSelectedChips(deleteChips);
                        this.enableChips(this.availableChips);
                    } else if (deletedChip.id === 'BRANCH_OF_CLIENT_ID') {4
                        if (deletedChip.optionParams.toggleChips &&
                            deletedChip.optionParams.toggleChips.length > 0) {
                            this.enableChips(deletedChip.optionParams.toggleChips);
                        }
                    }
                }
                if (deletedChip && (deletedChip.id === 'DOC_QUEUE_START_DATE')) {
                    const startDate = new Date();
                    startDate.setFullYear(startDate.getFullYear() - 5);
                    this.setMinDateForRelatedDateChips(deletedChip, startDate.toISOString().substring(0, 10));
                    const currentEndDate = this.checkDependentForCalculateRelatedDate(deletedChip);
                    if (currentEndDate) {
                        this.chipHandler.handleInterRelatedChips(deletedChip, currentEndDate);
                    } else {
                        this.chipHandler.handleInterRelatedChips(deletedChip, startDate);
                    }
                }
            });
    }

    private deleteToggleChips(toggleChips: any[]) {
        const deleteChips = [];
        toggleChips.forEach(toggleChip => {
            for (const selectedChip of this.selectedChips) {
                if (selectedChip.chip.id === toggleChip.criteriaId) {
                    deleteChips.push(selectedChip.chip);
                    break;
                }
            }
        });
        this.deleteSelectedChips(deleteChips);
    }

    private deleteSelectedChips(deleteChips: any[]) {
        deleteChips.forEach(chip => {
            this.chipInputService.deleteChip(chip, true);
        });
    }

    private enableChips(enableChips: any[]) {
        enableChips.forEach(enableChip => {
            this.chipInputService.modifyChipsStatus(enableChip, false);
        });
    }

    private disableChips(disableChips: any[]) {
        disableChips.forEach(disableChip => {
            this.chipInputService.modifyChipsStatus(disableChip, true);
        });
    }

    ngOnDestroy() {
        this.userJourneyManager.routeChanges.next(false);

        if (this.routerChangeSubscription) {
            this.routerChangeSubscription.unsubscribe();
        }
        if (this.relatedChipsObsv) {
            this.relatedChipsObsv.unsubscribe();
        }
        if (this.selectedChipsObsv) {
            this.selectedChipsObsv.unsubscribe();
        }
        if (this.selectedFieldsObsv) {
            this.selectedFieldsObsv.unsubscribe();
        }
        if (this.chipValueUpdateSub) {
            this.chipValueUpdateSub.unsubscribe();
        }
        if (this.criteriaUpdateSubscription) {
            this.criteriaUpdateSubscription.unsubscribe();
        }
        if (this.deletedChipObsv) {
            this.deletedChipObsv.unsubscribe();
        }
        if (this.searchResultObserver) {
            this.searchResultObserver.unsubscribe();
        }
        if (this.errorsObserver) {
            this.errorsObserver.unsubscribe();
        }
        if (this.userJourneyObserver) {
            this.userJourneyObserver.unsubscribe();
        }
        if (this.focusSearchBarObserver) {
            this.focusSearchBarObserver.unsubscribe();
        }
        if (this.closePopUpObserver) {
            this.closePopUpObserver.unsubscribe();
        }
        if (this.triggerSearchCriteriaObserver) {
            this.triggerSearchCriteriaObserver.unsubscribe();
        }

        this.em.removeEvents([
            'TRIGGER_SEARCH_CRITERIA'
        ]);
    }

}
