import { OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { AuthTokenUtil } from './auth-token-util';
import { LoginResponseParams } from './login-response-params';
import { TokenParams } from './token-params';
import * as i0 from "@angular/core";
import * as i1 from "ngx-cookie-service/cookie-service/cookie.service";
var AuthTokenService = /** @class */ (function () {
    function AuthTokenService(cookieService) {
        this.cookieService = cookieService;
        this.saveToCookies = false;
        this.LOCAL_AUTHORIZED_KEY = 'authorized';
        this.LOCAL_USER_NAME_KEY = 'user_name';
        this.LOCAL_USER_PROFILE_STATUS_KEY = 'approval_status';
        this.LOCAL_WAITING_VIEW_MESSAGE_KEY = 'message';
        this.LOCAL_TOKEN_KEY = 'token';
        this.LOCAL_REFRESH_TOKEN_KEY = 'refreshToken';
        this.LOCAL_TOKEN_EXPIRE_DATE_KEY = 'tokenExpireDate';
        this.LOCAL_REFRESH_TOKEN_EXPIRE_DATE_KEY = 'refreshTokenExpireDate';
        this.LOCAL_AUTHORIZATION_ERROR = 'error';
    }
    AuthTokenService.prototype.ngOnInit = function () {
        // this.saveToCookies = this.commonService.getTokenSaveConfig() ?
        //     this.commonService.getTokenSaveConfig() :
        //     this.saveToCookies;
    };
    AuthTokenService.prototype.updateTokenParams = function (tokenParams) {
        if (tokenParams.token) {
            if (this.saveToCookies) {
                this.addTokenToCookie(this.LOCAL_TOKEN_KEY, tokenParams.token, tokenParams.tokenExpireDate);
                // todo: when the refresh token comes to play consider this
                // this.addTokenToCookie(
                //     this.LOCAL_REFRESH_TOKEN_KEY,
                //     tokenParams.refreshToken,
                //     tokenParams.refreshTokenExpireDate
                // );
            }
            else {
                localStorage.setItem(this.LOCAL_TOKEN_KEY, tokenParams.token);
                localStorage.setItem(this.LOCAL_REFRESH_TOKEN_KEY, tokenParams.refreshToken);
            }
            return true;
        }
        else {
            return false;
        }
    };
    AuthTokenService.prototype.addTokenToCookie = function (key, token, expireDate) {
        var expireDateNumber = 0;
        if (expireDate) {
            expireDateNumber = expireDate;
        }
        this.cookieService.set(key, token, new Date(expireDateNumber), '', '', false);
    };
    AuthTokenService.prototype.updateTokenParamsByUrl = function (url) {
        var loginResponseParam = new LoginResponseParams();
        var tokenParams = new TokenParams();
        try {
            var authorizedParam = AuthTokenUtil.getQueryParameterByName(url, this.LOCAL_AUTHORIZED_KEY);
            loginResponseParam.authorized = authorizedParam ? JSON.parse(authorizedParam) : undefined;
            if (loginResponseParam.authorized) {
                tokenParams.token = AuthTokenUtil.getQueryParameterByName(url, this.LOCAL_TOKEN_KEY);
                tokenParams.refreshToken = AuthTokenUtil.getQueryParameterByName(url, this.LOCAL_REFRESH_TOKEN_KEY);
                tokenParams.tokenExpireDate = AuthTokenUtil.getQueryParameterByName(url, this.LOCAL_TOKEN_EXPIRE_DATE_KEY);
                tokenParams.refreshTokenExpireDate = AuthTokenUtil.getQueryParameterByName(url, this.LOCAL_REFRESH_TOKEN_EXPIRE_DATE_KEY);
            }
            else {
                var userNameParam = AuthTokenUtil.getQueryParameterByName(url, this.LOCAL_USER_NAME_KEY);
                var approvalStatusParam = AuthTokenUtil.getQueryParameterByName(url, this.LOCAL_USER_PROFILE_STATUS_KEY);
                var waitingMessageParam = AuthTokenUtil.getQueryParameterByName(url, this.LOCAL_WAITING_VIEW_MESSAGE_KEY);
                var authorizedErrorParam = AuthTokenUtil.getQueryParameterByName(url, this.LOCAL_AUTHORIZATION_ERROR);
                loginResponseParam.userName = userNameParam ? userNameParam : undefined;
                loginResponseParam.approvalStatus = approvalStatusParam ? approvalStatusParam : 'PENDING';
                loginResponseParam.message = waitingMessageParam ? waitingMessageParam : undefined;
                loginResponseParam.error = authorizedErrorParam ? authorizedErrorParam : '';
            }
        }
        catch (err) {
            console.log(err);
        }
        if (loginResponseParam.authorized !== undefined && loginResponseParam.authorized != null) {
            localStorage.setItem(this.LOCAL_AUTHORIZED_KEY, loginResponseParam.authorized);
            localStorage.setItem(this.LOCAL_AUTHORIZED_KEY, loginResponseParam.authorized);
            // this.authorized = loginResponseParam.authorized;
        }
        if (loginResponseParam.authorized && tokenParams.token) {
            if (this.saveToCookies) {
                this.addTokenToCookie(this.LOCAL_TOKEN_KEY, tokenParams.token, tokenParams.tokenExpireDate);
                // todo: when the refresh token comes to play consider this
                // this.addTokenToCookie(
                //     this.LOCAL_REFRESH_TOKEN_KEY,
                //     tokenParams.refreshToken,
                //     tokenParams.refreshTokenExpireDate
                // );
            }
            else {
                localStorage.setItem(this.LOCAL_TOKEN_KEY, tokenParams.token);
                localStorage.setItem(this.LOCAL_REFRESH_TOKEN_KEY, tokenParams.refreshToken);
                localStorage.setItem(this.LOCAL_TOKEN_EXPIRE_DATE_KEY, tokenParams.tokenExpireDate);
                localStorage.setItem(this.LOCAL_REFRESH_TOKEN_EXPIRE_DATE_KEY, tokenParams.refreshTokenExpireDate);
            }
            return true;
        }
        else if (loginResponseParam.authorized === false) {
            if (loginResponseParam.userName) {
                localStorage.setItem(this.LOCAL_USER_NAME_KEY, loginResponseParam.userName);
            }
            if (loginResponseParam.approvalStatus) {
                localStorage.setItem(this.LOCAL_USER_PROFILE_STATUS_KEY, loginResponseParam.approvalStatus);
            }
            if (loginResponseParam.message) {
                localStorage.setItem(this.LOCAL_WAITING_VIEW_MESSAGE_KEY, loginResponseParam.message);
            }
            return false;
        }
        else {
            localStorage.setItem(this.LOCAL_AUTHORIZATION_ERROR, loginResponseParam.error);
            return false;
        }
    };
    AuthTokenService.prototype.clearTokenParams = function () {
        if (this.saveToCookies) {
            this.cookieService.delete(this.LOCAL_TOKEN_KEY);
            this.cookieService.delete(this.LOCAL_REFRESH_TOKEN_KEY);
        }
        else {
            localStorage.removeItem(this.LOCAL_TOKEN_KEY);
            localStorage.removeItem(this.LOCAL_REFRESH_TOKEN_KEY);
            localStorage.removeItem(this.LOCAL_TOKEN_EXPIRE_DATE_KEY);
            localStorage.removeItem(this.LOCAL_REFRESH_TOKEN_EXPIRE_DATE_KEY);
        }
    };
    AuthTokenService.prototype.getToken = function () {
        var token;
        if (this.saveToCookies) {
            token = this.cookieService.get(this.LOCAL_TOKEN_KEY);
        }
        else {
            token = localStorage.getItem(this.LOCAL_TOKEN_KEY);
        }
        return token ? token : undefined;
    };
    AuthTokenService.prototype.isAuthorized = function () {
        var localAuthorized = localStorage.getItem(this.LOCAL_AUTHORIZED_KEY);
        return localAuthorized && localAuthorized !== 'null' ? JSON.parse(localAuthorized) : undefined;
    };
    AuthTokenService.prototype.getAuthorizationError = function () {
        var errorMessage = localStorage.getItem(this.LOCAL_AUTHORIZATION_ERROR);
        return errorMessage ? errorMessage : null;
    };
    AuthTokenService.prototype.getUserName = function () {
        return localStorage.getItem(this.LOCAL_USER_NAME_KEY);
    };
    AuthTokenService.prototype.getApprovalStatus = function () {
        return localStorage.getItem(this.LOCAL_USER_PROFILE_STATUS_KEY);
    };
    AuthTokenService.prototype.getMessage = function () {
        return localStorage.getItem(this.LOCAL_WAITING_VIEW_MESSAGE_KEY);
    };
    AuthTokenService.prototype.getRefreshToken = function () {
        if (this.saveToCookies) {
            return this.cookieService.get(this.LOCAL_REFRESH_TOKEN_KEY);
        }
        else {
            return localStorage.getItem(this.LOCAL_REFRESH_TOKEN_KEY);
        }
    };
    AuthTokenService.ngInjectableDef = i0.defineInjectable({ factory: function AuthTokenService_Factory() { return new AuthTokenService(i0.inject(i1.CookieService)); }, token: AuthTokenService, providedIn: "root" });
    return AuthTokenService;
}());
export { AuthTokenService };
