import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SectionHeadingModule } from '@tc/section-heading';
import { AgGridModule } from 'ag-grid-angular';
import { DateEditorComponent } from '../../../../widgets/framework/ag-grid-custom-components/editors/date-editor/date-editor.component';
import { DropdownEditorComponent } from '../../../../widgets/framework/ag-grid-custom-components/editors/dropdown-editor/dropdown-editor.component';
import { MultiSelectorEditorComponent } from '../../../../widgets/framework/ag-grid-custom-components/editors/multi-selector-editor/multi-selector-editor.component';
import { RadioButtonEditorComponent } from '../../../../widgets/framework/ag-grid-custom-components/editors/radio-button-editor/radio-button-editor.component';
import { AgGridCustomRenderersModule } from '../../../../widgets/framework/ag-grid-custom-components/renderers/ag-grid-custom-renderers.module';
import { CheckBoxRendererEditorComponent } from '../../../../widgets/framework/ag-grid-custom-components/renderers/check-box-selector-renderer/check-box-renderer-editor.component';
import { CheckBoxSelectorComponent } from '../../../../widgets/framework/ag-grid-custom-components/renderers/check-box-selector/check-box-selector.component';
import { TemplateRendererComponent } from '../../../../widgets/framework/ag-grid-custom-components/renderers/template-renderer/template-renderer.component';
import { FileUploaderModule } from '../../../../widgets/framework/file-uploader/file-uploader.module';
import { TransportCostSetupQueueFocusViewComponent } from './transport-cost-setup-queue-focus-view.component';

@NgModule({
    declarations: [TransportCostSetupQueueFocusViewComponent],
    imports: [
        CommonModule,
        AgGridCustomRenderersModule,
        AgGridModule.withComponents([
            TemplateRendererComponent,
            CheckBoxRendererEditorComponent,
            DropdownEditorComponent,
            MultiSelectorEditorComponent,
            RadioButtonEditorComponent,
            DateEditorComponent,
            CheckBoxSelectorComponent
        ]),
        SectionHeadingModule,
        FileUploaderModule
    ]
})
export class TransportCostSetupQueueFocusViewModule {}
