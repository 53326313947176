import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Pager } from '@tc-core/model/it/codegen/ui/framework/pager';
import { Sorter } from '@tc-core/model/it/codegen/ui/framework/sorter';
import { SorterService } from '@tc-core/service/sorters';
import { ConfigLoader } from '@tc-core/util/framework/config-loader.service';
import * as moment from 'moment';
import { TCO } from '../../../../constants';
import { AssignmentRawData } from '../../../../models/reservation-v2/assignment-raw-data';
import { BookingItem } from '../../../../models/reservation-v2/booking-item';
import { ResourceAllocationSearchCriteria } from '../../../../models/reservation-v2/criteria/resource-allocation-search-criteria';
import { PaxCount } from '../../../../models/reservation-v2/pax-count';
import { ResourceAllocationSummary } from '../../../../models/reservation-v2/resource-allocation-summary';
import { ServiceItem } from '../../../../models/reservation-v2/service-item';
import { ServiceSharing } from '../../../../models/reservation-v2/service-sharing';
import { ResourceType } from '../../../../models/reservation/assignment';

@Component({
    selector: 'tc-resource-allocation-panel',
    templateUrl: './resource-allocation-panel.component.html'
})
export class ResourceAllocationPanelComponent implements OnInit {

    @Input() contentCardDataList: ResourceAllocationSummary[] = [];
    @Input() selectedSummaryCardData: any = null;
    @Input() itemList: (ServiceItem | BookingItem)[] = [];
    @Input() pager: Pager;
    @Input() hasMore = true;
    @Input() heading = '';
    @Input() paxCount: PaxCount;
    @Input() isPrimary = false;
    @Input() isSecondary = false;
    @Input() startTime: any;
    @Input() endTime: any;
    @Input() bookingIdList: number[] = [];
    @Input() resourceSearchCriteria = new ResourceAllocationSearchCriteria();

    @Output() resourceSelect: EventEmitter<any> = new EventEmitter();
    @Output() onPaginationMoreClick: EventEmitter<any> = new EventEmitter();
    @Output() resourceCalendarClick: EventEmitter<any> = new EventEmitter<number>();
    @Output() eventClick: EventEmitter<any> = new EventEmitter();
    @Output() targetClick: EventEmitter<any> = new EventEmitter();
    @Output() onTimeRangeChangeClick: EventEmitter<any> = new EventEmitter();

    sorters_: Sorter[];
    defaultVehicleModel = 'ANY';

    //pagination

    public defaultPageResultCount = 4;
    selectionIndex = '';
    tPagination: any;
    dataFromMoreClick = false;

    constructor(
        private sorterService: SorterService,
        private configLoader: ConfigLoader
    ) { }

    ngOnInit() {
        this.tPagination = this.configLoader.configurations.get(TCO.CONF.CONF_PAGINATION_CONFIG)['RESOURCE_ALLOCATION_SEARCH'];
        this.setupPaginator();
        this.createResourceSearchCriteria();
    }

    createResourceSearchCriteria() {
        this.resourceSearchCriteria = new ResourceAllocationSearchCriteria();
        let previousAssignmentDetail: AssignmentRawData;

        this.resourceSearchCriteria.kcCompany = this.itemList[0].kcCompany;
        this.resourceSearchCriteria.kcDivision = this.itemList[0].kcDivision;
        this.resourceSearchCriteria.kcBrand = this.itemList[0].kcBrand;
        this.resourceSearchCriteria.kcDistributionChannel = this.itemList[0].kcDistributionChannel;

        this.resourceSearchCriteria.serviceDate = this.itemList[0].serviceDate;
        this.resourceSearchCriteria.costingType = this.itemList[0].costingType && this.itemList[0].costingType.code ? this.itemList[0].costingType.code : this.itemList[0].costingType;
        this.resourceSearchCriteria.noOfUnits = this.itemList[0].noOfUnits;
        if (this.itemList[0].genBookingItem) {
            this.resourceSearchCriteria.serviceType = this.itemList[0].genBookingItem.productCode;
            this.resourceSearchCriteria.profileType = this.itemList[0].genBookingItem ? this.itemList[0].genBookingItem.typeCode : '';
            if (this.isSecondary) {
                previousAssignmentDetail = this.itemList[0].secondaryAssignment;
            } else {
                previousAssignmentDetail = this.itemList[0].primaryAssignment;
            }
            this.resourceSearchCriteria.serviceStartTime = this.startTime ?
                moment(new Date(this.startTime)).format('HH:mm') :
                moment(new Date(this.itemList[0].serviceDate)).format('HH:mm');
            this.resourceSearchCriteria.serviceEndTime = this.endTime ?
                moment(new Date(this.endTime)).format('HH:mm') :
                moment(new Date(this.itemList[0].serviceDate)).format('HH:mm');
        } else if (this.itemList[0].trsBookingItem) {
            this.resourceSearchCriteria.serviceType = this.isPrimary ? ResourceType.vehicle : ResourceType.driver;
            this.resourceSearchCriteria.transferMode = this.itemList[0].trsBookingItem.transferMode;
            this.resourceSearchCriteria.route = this.itemList[0].trsBookingItem.route;
            this.resourceSearchCriteria.vehicleModel = this.itemList[0].trsBookingItem.vehicleModel ?
                this.itemList[0].trsBookingItem.vehicleModel :
                this.defaultVehicleModel;
            previousAssignmentDetail = this.itemList[0].primaryAssignment;
            this.resourceSearchCriteria.serviceStartTime = this.startTime ?
                moment(new Date(this.startTime)).format('HH:mm') :
                moment(new Date(this.itemList[0].trsBookingItem.departureDateTime)).format('HH:mm');
            this.resourceSearchCriteria.serviceEndTime = this.endTime ?
                moment(new Date(this.endTime)).format('HH:mm') :
                moment(new Date(this.itemList[0].trsBookingItem.arrivalDateTime)).format('HH:mm');
        }
        this.resourceSearchCriteria.bookingIdList = this.bookingIdList;

        this.resourceSearchCriteria.adultCount = this.paxCount.adultCount;
        this.resourceSearchCriteria.childCount = this.paxCount.childCount;
        this.resourceSearchCriteria.infantCount = this.paxCount.infantCount;
        this.resourceSearchCriteria.isPrivateBooking = this.itemList[0].pvtShared === ServiceSharing.PVT;

        this.resourceSearchCriteria.rejectedResourceIdList = previousAssignmentDetail ?
            previousAssignmentDetail.rejectedResourceIdList :
            [];
    }

    private setupPaginator() {
        this.defaultPageResultCount = this.tPagination['defaultPageResultCount'];
        this.pager = new Pager(0, this.defaultPageResultCount);
    }

    public onPage(pageNumber: number) {
        this.pager.currPage = pageNumber;
        this.selectionIndex = (this.pager.currPage * this.pager.itemsPerPage).toString();
    }

    public onMore() {
        this.dataFromMoreClick = true;
        if (this.tPagination && this.tPagination['defaultPageResultCount'] && this.tPagination['fetchingPages']) {
            this.resourceSearchCriteria.size = this.tPagination['fetchingPages'] *
                this.tPagination['defaultPageResultCount'];
            this.onPaginationMoreClick.emit(this.resourceSearchCriteria);
        }
    }

    public onSelection(event) {
        this.selectionIndex = event;
    }

    public onSort(event) {
        this.sorterService.sorter = event;
    }

    public onResourceSelect(summaryCardData: ResourceAllocationSummary) {
        this.selectedSummaryCardData = summaryCardData;

        if (summaryCardData) {
            this.resourceSelect.emit(this.selectedSummaryCardData);
        }
    }

    onResourceCalendarClick(resourceId: number) {
        this.resourceCalendarClick.emit(resourceId);
    }

    onTargetClick(target: any) {
        this.targetClick.emit(target);
    }

    onEventClick(event: any) {
        this.eventClick.emit(event);
    }

    public onClickAddTimeRange() {
        this.onTimeRangeChangeClick.emit();
    }
}
