import * as tslib_1 from "tslib";
import { ResourceCostSetup } from './resource-cost-setup';
var GenResourceCostSetup = /** @class */ (function (_super) {
    tslib_1.__extends(GenResourceCostSetup, _super);
    function GenResourceCostSetup() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return GenResourceCostSetup;
}(ResourceCostSetup));
export { GenResourceCostSetup };
var GenResourceCost = /** @class */ (function () {
    function GenResourceCost() {
    }
    return GenResourceCost;
}());
export { GenResourceCost };
