import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ConfigLoader } from '@tc-core/util/framework/config-loader.service';
import { TCO } from '../../../constants';
import { Resource, ResourceBusinessType } from '../../../models/resource/resource';
import { GenericResourceSummary } from '../../../models/summary/generic-resource-summary';
import { SupplierSummary } from '../../../models/summary/supplier-summary';
import { SupplierSummaryDepricated } from '../../../models/summary/supplier-summary-depricated';
import { Grade } from '../../../models/supplier/supplier';
import { UserProfile } from '../../../models/user/user-profile';
import { UserServiceProfile } from '../../../models/user/user-service-profile';
import { RegXPatterns } from '../regx-patterns/regx-patterns';

@Component({
    selector: 'tc-vehicle-specific-information',
    templateUrl: './vehicle-specific-information.component.html'
})
export class VehicleSpecificInformationComponent implements OnInit {

    resourceType = '';
    resourceSpecificInfoForm: FormGroup;
    numberRegex = '^[0-9]*$';
    externalFlag = false;
    activeFlag = true;
    selectedSupplier: SupplierSummary;
    selectedDriver: GenericResourceSummary;

    @Input() assignedSupplier: SupplierSummary;
    @Input() assignedDriver: GenericResourceSummary;
    @Input() filteredSuppliers: SupplierSummary[];
    @Input() filteredUsers: UserProfile[];
    @Input() filteredDrivers: GenericResourceSummary[];
    @Input() transferModes: any[] = [];
    @Input() driverName: string[] = [];
    @Input() supplierTypes = [];
    @Input() fuelTypeOptions: string[];
    @Input() resourceSpecificInformationModel: Resource;
    @Input() vehicleModels: any[] = [];
    @Input() missingCostForSupplier = false;
    @Output() changeFormValidity: EventEmitter<boolean> = new EventEmitter();

    @Output() supplierTextChange: EventEmitter<any> = new EventEmitter();
    @Output() supplierClear: EventEmitter<any> = new EventEmitter();
    @Output() supplierAssign: EventEmitter<SupplierSummary> = new EventEmitter();

    @Output() driverTextChange: EventEmitter<any> = new EventEmitter();
    @Output() driverClear: EventEmitter<any> = new EventEmitter();
    @Output() driverAssign: EventEmitter<GenericResourceSummary> = new EventEmitter();

    @Output() userAssign: EventEmitter<UserProfile> = new EventEmitter();
    @Output() userClear: EventEmitter<any> = new EventEmitter();
    @Output() userTextChange: EventEmitter<string> = new EventEmitter();

    filteredVehicleModels: any;
    businessTypes = ResourceBusinessType;
    grades: Grade[];
    maxPreference: number;
    maxGrade = 10;
    gradeNgModel = 0;

    constructor(
        private fb: FormBuilder,
        private route: ActivatedRoute,
        private configLoader: ConfigLoader,
    ) {
    }

    ngOnInit() {

        this.resourceType = this.route.snapshot.queryParamMap.get('page').split('_')[1];

        const gradeConfig = this.configLoader.configurations.get(TCO.CONF.CONF_GRADES_CONFIG);
        if (gradeConfig && gradeConfig.grade_values) {
            this.grades = gradeConfig.grade_values.sort((a, b) => (a.order > b.order ? 1 : b.order > a.order ? -1 : 0));

        }
        if (gradeConfig && gradeConfig.max_grade) {
            this.maxGrade = gradeConfig.max_grade ? gradeConfig.max_grade : 10;
        }

        if (this.configLoader && this.configLoader.configurations) {
            this.maxPreference = this.configLoader.configurations.get(TCO.CONF.CONF_PREFERENCE_CONFIG).MAX_PREFERENCE as number;
        }

        this.createForm();

        this.resourceSpecificInfoForm.valueChanges.subscribe(
            (data) => {
                if (JSON.stringify(data) !== JSON.stringify({})) {
                    this.resourceSpecificInformationModel.vehicle.trsMode = data.transferMode;
                    this.resourceSpecificInformationModel.vehicle.vehicleModel = data.vehicleModel;
                    this.resourceSpecificInformationModel.vehicle.licencePlate = data.licenseNumber;
                    this.resourceSpecificInformationModel.vehicle.fuelType = data.fuelType;
                    this.resourceSpecificInformationModel.vehicle.fuelConsumption = data.fuelConsumption;
                    this.resourceSpecificInformationModel.description = null;
                    this.resourceSpecificInformationModel.capacity = parseInt(data.seatingCapacity);
                    this.resourceSpecificInformationModel.minPax = parseInt(data.minPax);
                    this.resourceSpecificInformationModel.maxPax = parseInt(data.maxPax);
                    this.resourceSpecificInformationModel.vehicle.vehicleName = data.vehicleName;
                    this.resourceSpecificInformationModel.resourceType = this.resourceType;
                    this.resourceSpecificInformationModel.activeStatus = this.activeFlag;
                    this.resourceSpecificInformationModel.supplierType = data.supplierType;
                    this.resourceSpecificInformationModel.businessType = data.businessType;
                    this.resourceSpecificInformationModel.preference = data.preference;
                    this.resourceSpecificInformationModel.grade = data.grade;
                    this.getConfigGradeValue(data.grade);

                    if (data.supplier && typeof (data.supplier) !== 'object') {
                        this.filteredSuppliers = [];
                        this.supplierTextChange.emit(data);
                    }

                    if (data.driver && typeof (data.driver) !== 'object') {
                        this.filteredDrivers = [];
                        this.driverTextChange.emit({text: data.driver});
                    }

                    if (data.linkedUser !== undefined && typeof (data.linkedUser) !== 'object') {
                        this.filteredUsers = [];
                        this.userTextChange.emit(data.linkedUser);
                    }

                    this.changeFormValidity.emit(this.resourceSpecificInfoForm.valid);

                }

            }

        );
        this.validateForm();
        let trsMode = this.resourceSpecificInformationModel && this.resourceSpecificInformationModel.vehicle ? this.resourceSpecificInformationModel.vehicle.trsMode : '';
        if(this.vehicleModels && this.vehicleModels.length > 0){
            this.filterVehicleModels(trsMode)
        }
    }

    // onInputChange(event: MatSliderChange, type: string) {
    //     switch (type) {
    //         case 'grade':
    //             this.resourceSpecificInformationModel.grade = event.value;
    //             break;
    //         case 'preference':
    //             this.resourceSpecificInformationModel.preference = event.value;
    //             break;
    //         default:
    //             console.log('Invalid type');
    //             break;
    //     }
    // }

    public validateForm() {
        Object.keys(this.resourceSpecificInfoForm.controls).forEach(field => {
            const control = this.resourceSpecificInfoForm.get(field);
            control.markAsTouched({onlySelf: true});
        });
    }

    /* public onSupplierTextChange($event: any) {
     this.supplierTextChange.emit($event);
     }*/

    public toggleActiveValue() {
        this.activeFlag = !this.activeFlag;
        this.resourceSpecificInformationModel.activeStatus = this.activeFlag;
        this.changeFormValidity.emit(this.resourceSpecificInfoForm.valid);
    }

    // public toggleExternalValue() {
    //     this.externalFlag = !this.externalFlag;
    //     this.resourceSpecificInformationModel.external = this.externalFlag;
    // }

    // focusvehicleModels
    public onSupplierFocus($event: FocusEvent) {
        if (this.resourceSpecificInfoForm && this.resourceSpecificInfoForm.get('supplier')) {
            let data = {};
            data['supplier'] = this.resourceSpecificInfoForm.get('supplier').value;
            this.supplierTextChange.emit(data);
        }
        this.filteredSuppliers = [];
    }

    public onSelectSupplier(supplier: SupplierSummary) {
        this.selectedSupplier = supplier;
        this.supplierAssign.emit(supplier);
    }

    public clearSupplier() {
        this.resourceSpecificInfoForm.get('supplier').setValue('');
        this.supplierClear.emit();
    }

    displaySupplierFn(supplier: SupplierSummaryDepricated): any {
        return supplier ? supplier.name : supplier;
    }

    public onDriverFocus($event: FocusEvent) {
        this.filteredDrivers = [];
    }

    public clearDriver() {
        this.resourceSpecificInfoForm.get('driver').setValue('');
        this.driverClear.emit();
    }

    public onSelectDriver(driver: GenericResourceSummary) {
        this.selectedDriver = driver;
        this.driverAssign.emit(driver);
    }

    displayDriverFn(driver: GenericResourceSummary): any {
        return driver ? driver.firstName + ' ' + driver.lastName : driver;
    }

    public updateAssignedSupplier(supplier: SupplierSummary) {
        this.assignedSupplier = supplier;
        this.resourceSpecificInfoForm.patchValue({
            supplier: this.assignedSupplier
        });
        this.onSelectSupplier(supplier);
    }

    public updateAssignedDriver(driver: GenericResourceSummary) {
        this.assignedDriver = driver;
        this.resourceSpecificInfoForm.patchValue({
            driver: this.assignedDriver
        });
    }

    public onUserFocus($event: FocusEvent) {
        this.filteredUsers = [];
    }

    public clearUser() {
        this.resourceSpecificInfoForm.get('linkedUser').setValue('');
        this.userClear.emit();
    }

    public onSelectUser(user: UserProfile) {
        this.userAssign.emit(user);
    }

    public getUserName(user: UserProfile) {
        return user ? (user.firstName + ' ' + user.lastName) : null;
    }

    displayUserFn(user: UserServiceProfile): any {
        return user ? (user.firstName + ' ' + user.lastName) : null;
    }

    public result() {
        let vehicle: any;
        vehicle = this.resourceSpecificInfoForm.value;
        console.log(vehicle);
    }

    getErrorMessage(field: string): string {
        switch (field) {
            case 'transferMode' :
                return this.resourceSpecificInfoForm.get('transferMode').hasError('required') ? 'Transfer Mode Required' : '';
            case 'vehicleModel' :
                return this.resourceSpecificInfoForm.get('vehicleModel').hasError('required') ? 'Vehicle Model Required' : '';
            case 'vehicleName' :
                return this.resourceSpecificInfoForm.get('vehicleName').hasError('required') ? 'Vehicle Name Required' : '';
            case 'licenseNumber' :
                return this.resourceSpecificInfoForm.get('licenseNumber').hasError('required') ? 'License Number Required' : '';
            case 'fuelType' :
                return this.resourceSpecificInfoForm.get('fuelType').hasError('required') ? 'Fuel Type Required' : '';
            case 'fuelConsumption' :
                return this.resourceSpecificInfoForm.get('fuelConsumption').hasError('pattern') ? 'Fuel Consumption Invalid' : '';
            case 'supplier' :
                return this.resourceSpecificInfoForm.get('supplier').hasError('required') ? 'Supplier Required' : '';
            case 'businessType' :
                return this.resourceSpecificInfoForm.get('businessType').hasError('required') ? 'Business type Required' : '';
            case 'seatingCapacity' :
                return this.resourceSpecificInfoForm.get('seatingCapacity').hasError('required') ? 'Seating Capacity Required' :
                    this.resourceSpecificInfoForm.get('seatingCapacity').hasError('pattern') ? 'Invalid Capacity' : '';
            case 'minPax' :
                if (this.resourceSpecificInfoForm.get('minPax').hasError('required')) {
                    return 'Min Pax Required';
                } else if (this.resourceSpecificInfoForm.get('minPax').hasError('pattern') ||
                    this.resourceSpecificInfoForm.get('minPax').hasError('minMaxError')) {
                    return 'Invalid Min Pax';
                } else {
                    return '';
                }
            case 'maxPax' :
                if (this.resourceSpecificInfoForm.get('maxPax').hasError('required')) {
                    return 'Max Pax Required';
                } else if (this.resourceSpecificInfoForm.get('maxPax').hasError('pattern') ||
                    this.resourceSpecificInfoForm.get('maxPax').hasError('minMaxError')) {
                    return 'Invalid Max Pax';
                } else {
                    return '';
                }
            case 'preference' :
                return this.resourceSpecificInfoForm.get('preference').hasError('pattern') ? 'Preference invalid' :
                    this.resourceSpecificInfoForm.get('preference').hasError('max') ? 'Preference invalid' :
                        this.resourceSpecificInfoForm.get('preference').hasError('min') ? 'Preference invalid' : '';
            case 'grade' :
                return this.resourceSpecificInfoForm.get('grade').hasError('pattern') ? 'Grade invalid' :
                    this.resourceSpecificInfoForm.get('grade').hasError('max') ? 'Grade invalid' : '';
            default :
                return '';
        }
    }

    createForm() {
        this.resourceSpecificInfoForm = this.fb.group({
            transferMode: [
                (this.resourceSpecificInformationModel && this.resourceSpecificInformationModel.vehicle) ?
                    this.resourceSpecificInformationModel.vehicle.trsMode :
                    '',
                [Validators.required]
            ],
            vehicleModel: [
                (this.resourceSpecificInformationModel && this.resourceSpecificInformationModel.vehicle) ?
                    this.resourceSpecificInformationModel.vehicle.vehicleModel :
                    '',
                [Validators.required]
            ],
            vehicleName: [
                (this.resourceSpecificInformationModel && this.resourceSpecificInformationModel.vehicle) ?
                    this.resourceSpecificInformationModel.vehicle.vehicleName :
                    '',
                [Validators.required]
            ],
            licenseNumber: [
                (this.resourceSpecificInformationModel && this.resourceSpecificInformationModel.vehicle) ?
                    this.resourceSpecificInformationModel.vehicle.licencePlate :
                    '',
                [Validators.required]
            ],
            fuelType: [
                (this.resourceSpecificInformationModel && this.resourceSpecificInformationModel.vehicle) ?
                    this.resourceSpecificInformationModel.vehicle.fuelType :
                    '',
                [Validators.required]
            ],
            supplier: [this.assignedSupplier ? this.assignedSupplier : '', [Validators.required]],
            driver: [this.assignedDriver ? this.assignedDriver : ''],
            fuelConsumption: [
                (this.resourceSpecificInformationModel && this.resourceSpecificInformationModel.vehicle) ?
                    this.resourceSpecificInformationModel.vehicle.fuelConsumption :
                    '',
                [Validators.pattern(RegXPatterns.ANY_MAX10)]
            ],
            seatingCapacity: [
                this.resourceSpecificInformationModel ? this.resourceSpecificInformationModel.capacity : '',
                [Validators.required, Validators.pattern(RegXPatterns.POSITIVE_NUMBERS_3DIGIT)]
            ],
            minPax: [
                this.resourceSpecificInformationModel ? this.resourceSpecificInformationModel.minPax : '',
                [Validators.required, Validators.pattern(RegXPatterns.POSITIVE_NUMBERS_3DIGIT)]
            ],
            maxPax: [
                this.resourceSpecificInformationModel ? this.resourceSpecificInformationModel.maxPax : '',
                [Validators.required, Validators.pattern(RegXPatterns.POSITIVE_NUMBERS_3DIGIT)]
            ],
            active: [this.resourceSpecificInformationModel ? this.resourceSpecificInformationModel.activeStatus : ''],
            resourceType: [this.resourceSpecificInformationModel ? this.resourceSpecificInformationModel.resourceType : ''],
            supplierType: [
                this.resourceSpecificInformationModel ? this.resourceSpecificInformationModel.supplierType : ''
            ],
            businessType: [
                this.resourceSpecificInformationModel ? this.resourceSpecificInformationModel.businessType : '',
                Validators.required
            ],
            linkedUser: [this.resourceSpecificInformationModel ? this.resourceSpecificInformationModel.userProfile : ''],
            preference: [
                this.resourceSpecificInformationModel ? this.resourceSpecificInformationModel.preference : '',
                [Validators.pattern(RegXPatterns.POSITIVE_NUMBERS), Validators.max(this.maxPreference), Validators.min(1)]
            ],
            grade: [
                this.resourceSpecificInformationModel && this.resourceSpecificInformationModel.grade ? this.resourceSpecificInformationModel.grade : 0,
                [Validators.pattern(RegXPatterns.POSITIVE_NUMBERS), Validators.max(this.maxGrade)]
            ]
        }, {
            validator: VehicleSpecificInformationComponent.validateMinMax
        });
        this.changeFormValidity.emit(this.resourceSpecificInfoForm.valid);
    }

    static validateMinMax(group: FormGroup) {
        let min = group.controls['minPax'];
        let max = group.controls['maxPax'];
        if (min.value && max.value && min.value > max.value) {
            min.setErrors({minMaxError: true});
            max.setErrors({minMaxError: true});
        } else {
            if (min.hasError('required')) {
                min.setErrors({required: true});
            } else if (min.hasError('pattern')) {
                min.setErrors({pattern: true});
            } else {
                min.setErrors(null);
            }

            if (max.hasError('required')) {
                max.setErrors({required: true});
            } else if (max.hasError('pattern')) {
                max.setErrors({pattern: true});
            } else {
                max.setErrors(null);
            }
        }

        return null;
    }

    public onTransferModeChange($event: any) {
        this.clearSupplier();
        this.clearDriver();
        this.filterVehicleModels($event);
    }

    filterVehicleModels(event){
        this.filteredVehicleModels = [];
        this.vehicleModels.forEach((model) => {
            if ((model.transferMode === null) || (model.transferMode && event == model.transferMode.code)) {
                this.filteredVehicleModels.push(model);
            }
        });
    }

    onVehicleModeChange($event: any) {
        this.clearSupplier();
    }

    gradeSelectionOnChange($event: any)
    {
        let localGrade = 0;
        if (this.maxGrade && this.grades && $event.value !== 0) {
            localGrade = Math.floor(($event.value / this.grades.length) * this.maxGrade);
            this.gradeNgModel = $event.value;
        }
        this.resourceSpecificInfoForm.get('grade').setValue(localGrade);
    }

    getConfigGradeValue(grade: number)
    {
        this.gradeNgModel = Math.ceil((grade / this.maxGrade) * this.grades.length);
    }
}
