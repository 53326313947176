import { Component, Input, OnInit } from '@angular/core';
import { locationGroupSetup } from '../../../../../models/master-data/location-group-setup';
import { FormBuilder, FormGroup } from '@angular/forms';
import { RoutesSetupService } from '../../../../../services/backend-consumers/deprecated/routes-setup.service';
import { DataKey, DataStoreService } from '../../../../../services/util/framework/data-store.service';

@Component({
    selector: 'tc-edit-location-group',
    templateUrl: './edit-location-group.component.html',
    styleUrls: ['./edit-location-group.component.css']
})
export class EditLocationGroupComponent implements OnInit {
    @Input() locationGroup: locationGroupSetup;
    locationName: string;
    selectedCriteria: string;
    locationGroupForm: FormGroup;
    searchResults: any[];
    selectedLocations = [];
    searchCriteria = ['Airport', 'Cruise Port', 'Hotel', 'Other'];
    countryList = [];
    regionList = [];
    cityList = [];

    constructor(
        private fb: FormBuilder,
        private routeService: RoutesSetupService,
        private dataStore: DataStoreService
    ) {
        this.createForm();
    }

    ngOnInit() {
        this.locationGroup.locations.forEach(loc => {
            this.selectedLocations.push(loc);
        });

        console.log(this.searchCriteria);
        this.locationName = this.locationGroup.groupName;
        // Testing
        this.routeService.regionList().subscribe(list => {
            this.regionList = list['data'];
        });
        this.routeService.countryList().subscribe(list => {
            this.countryList = list['data'];
        });
        this.routeService.cityList().subscribe(list => {
            this.cityList = list['data'];
        });
    }

    toggleValue(value: string) {
        this.selectedCriteria = value;
        this.searchLocations();
    }

    searchLocations() {
        this.routeService.getLocationTypes().subscribe(data => {
            if (data != null) {
                for (const key of data) {
                    if (key['locationType'] === this.selectedCriteria) {
                        this.searchResults = key['locations'];
                    }
                }
            }
        });
    }

    createForm() {
        this.locationGroupForm = this.fb.group({
            region: [''],
            country: [''],
            city: ['']
        });
    }

    addLocation(isSelected: any, loc: Map<string, string>) {

        if (isSelected.checked) {
            this.selectedLocations.push({'code': loc['code'], 'name': loc['name']});
            // this.locationGroup.locations.set(loc['code'], loc['name']);
            console.log(this.locationGroup.locations);
        } else {
            const index = this.selectedLocations.findIndex(order => order.code === loc['code']);
            this.selectedLocations.splice(index, 1);
            // console.log(this.selectedLocations);
        }

        // TODO: should pass the selectedLocations with LocationGroup
        this.dataStore.set(DataKey.locations, this.selectedLocations, true);
    }

}
