import * as tslib_1 from "tslib";
import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { TcApiError } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-api-error';
import { TcHttpError } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-http-error';
import { LEVEL, ModalData } from '@tc-core/model/it/codegen/ui/framework/modal-data';
import { TC } from '@tc-core/util';
import { ConfigLoader, EventManager, UserJourneyManager } from '@tc-core/util/framework';
import { CommonHelper } from '@tc-core/util/helpers';
import { DateFormatter } from '@tc-core/util/system';
import { ChipInputComponent } from '@tc/chip-input';
import { ModalService } from '@tc/modal/modal.service';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { TCO } from '../../../constants';
import { ProductQueueSearchCriteria } from '../../../models/reservation-v2/criteria/product-queue-search-criteria';
import { MainType } from '../../../models/reservation-v2/main-type';
import { ShowSavedCriteriaModalComponent } from '../../reservation-management/show-saved-criteria-modal/show-saved-criteria-modal.component';
import { ReservationV2ManagementService } from '../../../services/backend-consumers/reservation-v2/reservation-v2-management.service';
import { DateTimeProcessor } from '../../../services/business-utils/time-zone/date-time-processor';
import { ChipHandlerService } from '../../../services/helpers/chip-handler.service';
import { RootService } from '../../../services/util/core-services/root.service';
import { ActionHandlerService } from '../../../services/util/framework/action-handler-service.service';
import { DataKey, DataStoreService } from '../../../services/util/framework/data-store.service';
import { DMCQueryParamsService } from '../../../services/util/framework/dmc-query-params.service';
import { OperationV2DataProcessorService } from '../../../services/util/pre-processors/operation-v2-data-processor.service';
import { UserManagementService } from '../../../services/user-management/user-management.service';
var ProductQueueSearchCriteriaComponent = /** @class */ (function () {
    function ProductQueueSearchCriteriaComponent(configLoader, dataStoreService, em, queryParamService, rootService, chipHandler, commonHelper, userJourneyManager, actionHandlerService, modalService, dateFormatter, reservationV2ManagementService, operationV2DataProcessorService, userManagementService) {
        this.configLoader = configLoader;
        this.dataStoreService = dataStoreService;
        this.em = em;
        this.queryParamService = queryParamService;
        this.rootService = rootService;
        this.chipHandler = chipHandler;
        this.commonHelper = commonHelper;
        this.userJourneyManager = userJourneyManager;
        this.actionHandlerService = actionHandlerService;
        this.modalService = modalService;
        this.dateFormatter = dateFormatter;
        this.reservationV2ManagementService = reservationV2ManagementService;
        this.operationV2DataProcessorService = operationV2DataProcessorService;
        this.userManagementService = userManagementService;
        this.placeHolderText = 'Search Items';
        this.isInsideForm = false;
        this.isSearchBarStyleNotAvailable = false;
        this.searchTrigger = new EventEmitter();
        this.availableChips = [];
        this.isDataLoaded = true;
        this.isChipsInit = false;
        this.isChipListDisplay = false;
        this.displayChipsArr = [];
        this.isFocus = false;
        this.persistentQParams = [];
        this.persistentQParamMap = new Map();
        this.persistentChipsArr = [];
        this.isTriggerSearch = true;
        this.journey = null;
        this.systemDefinedCriteriaList = [];
        this.userDefinedCriteriaList = [];
        this.selectedChipsObsv = new Subscription();
        this.selectedFieldsObsv = new Subscription();
        this.chipValueUpdateSub = new Subscription();
        this.relatedChipsObsv = new Subscription();
        this.relatedChipsProcessObsv = new Subscription();
        this.criteriaUpdateSubscription = new Subscription();
        this.deletedChipObsv = new Subscription();
        this.savedCriteriaSearchObs = new Subscription();
        this.searchResultLoadedObs = new Subscription();
        this.selectedChips = [];
        this.chipHandlerSubject = new Subscription();
    }
    ProductQueueSearchCriteriaComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.chipInputService = this.productQueueSearchChipInput.criteriaInputService;
        this.tPagination = this.configLoader.configurations.get(TCO.CONF.CONF_PAGINATION_CONFIG)[this.paginationConfigKey];
        // reset the search results
        this.dataStoreService.set(this.searchCriteriaUpdatingDataKey, null, true);
        this.dataStoreService.set(this.searchResultsDataKey, null, true);
        this.handleQuickActions();
        this.userJourneyManager.journey.subscribe(function (journey) {
            if (journey && JSON.stringify(journey) !== JSON.stringify({})) {
                _this.journey = journey.id;
            }
        });
        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'SHOW_SAVED_CRITERIA').subscribe(function (e) {
            _this.onShowSavedCriteriaClick();
        });
        this.getUserDefinedCriteriaList();
        this.getSystemDefinedCriteriaList();
        if (!this.isInsideForm) {
            this.getQueryParams();
            if (!this.isChipsInit) {
                this.initSearchCriteriaChips(); // load everything from start
            }
        }
        this.getProductQueueSearchObserver();
        this.errorObserver();
        this.selectedChipsUpdate();
        this.handleInterRelatedChips();
        this.notifyChipDelete();
        this.shortCutObserver();
        if (this.operationV2DataProcessorService.subsVar == undefined) {
            this.operationV2DataProcessorService.subsVar = this.operationV2DataProcessorService.invokeShowSearchCriteriaFunction.subscribe(function (name) {
                _this.onShowSavedCriteriaClick();
            });
        }
    };
    ProductQueueSearchCriteriaComponent.prototype.getQueryParams = function () {
        var queryParams = [];
        queryParams = this.queryParamService.getQueryParamsArray(this.inputSearchCriteria);
        this.inputSearchCriteria = this.rootService.loadRawDataFromLocalStorage(queryParams, this.createNewSearchCriteriaFn(), this.searchCriteriaLoadingDataKey);
        DateTimeProcessor.convertLocalStorageDatesToISOString(this.inputSearchCriteria);
        this.inputSearchCriteria = this.userManagementService.updateSearchCriteriaWithUserKC(this.inputSearchCriteria);
        this.inputSearchCriteria.pvtShared = this.inputSearchCriteria.pvtShared ?
            this.inputSearchCriteria.pvtShared :
            'ANY';
        this.inputSearchCriteria.language = this.inputSearchCriteria.mainType === MainType.TOU &&
            !this.inputSearchCriteria.language
            ? 'ANY' : this.inputSearchCriteria.language;
        if (this.inputSearchCriteria) {
            this.persistentQParams = [];
            this.persistentQParams = this.queryParamService.getNotNullQueryParamsArray(this.inputSearchCriteria);
        }
    };
    ProductQueueSearchCriteriaComponent.prototype.shortCutObserver = function () {
        var _this = this;
        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'FOCUS_SEARCH_BAR').subscribe(function (e) {
            _this.isFocus = true;
        });
        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'CLOSE_POPUPS').subscribe(function (e) {
            _this.isFocus = false;
        });
    };
    ProductQueueSearchCriteriaComponent.prototype.onFocus = function (event) {
        this.isFocus = event;
    };
    ProductQueueSearchCriteriaComponent.prototype.initSearchCriteriaChips = function () {
        var _this = this;
        this.isChipsInit = true;
        this.chipInputService.fullReset();
        this.chipHandler.chipsArr = [];
        // read chip config
        var criteriaChipConfigs = null;
        if (!this.isInsideForm) {
            var chipConfigs = this.userManagementService.updateSearchChipsAccordingToUserKC(this.configLoader.configurations.get(this.chipConfigKey));
            criteriaChipConfigs = this.commonHelper.recreateJsonObject(chipConfigs);
        }
        else {
            var chipConfigs = this.userManagementService.updateSearchChipsAccordingToUserKC(this.configLoader.configurations.get(this.searchCriteriaChipConfig));
            criteriaChipConfigs = this.commonHelper.recreateJsonObject(chipConfigs);
        }
        this.chipHandler.setMinDateForRelatedChips(criteriaChipConfigs, this.inputSearchCriteria.startDate, 'END_DATE');
        if (this.inputSearchCriteria && this.inputSearchCriteria.bookingStartDate) {
            this.chipHandler.setMinDateForRelatedChips(criteriaChipConfigs, this.inputSearchCriteria.bookingStartDate, 'BOOKING_END_DATE');
        }
        if (criteriaChipConfigs.chips) {
            criteriaChipConfigs.chips.forEach(function (chip) {
                chip = _this.chipHandler.preProcessChips(chip, criteriaChipConfigs.permissionKeys.keys);
            });
        }
        var isDataLoaded = false;
        this.chipHandler.chipsSubject.subscribe(function (data) {
            if (data && JSON.stringify(data) !== JSON.stringify([]) && !isDataLoaded) {
                isDataLoaded = true;
                _this.availableChips = data;
                _this.chipHandler.chipsSubject.next(null);
                if (_this.chipHandlerSubject) {
                    _this.chipHandlerSubject.unsubscribe();
                }
                // set values to display chips
                _this.displayChipsArr = [];
                _this.displayChipsArr = _this.availableChips;
                _this.dataStoreService.set(DataKey.chips, _this.availableChips, true);
                _this.chipInputService.init(_this.inputSearchCriteria, _this.availableChips, false, true);
                _this.isChipsInit = true;
                // implement chip persistence
                if (_this.persistentQParams && _this.persistentQParams.length > 0) {
                    // create a queryParamMap
                    _this.persistentQParams.forEach(function (qParam) {
                        var qParamValue = _this.inputSearchCriteria[qParam];
                        if (qParam && qParamValue) {
                            _this.persistentQParamMap.set(qParam, qParamValue);
                        }
                    });
                    _this.persistentChipsArr = [];
                    // fill persistent chips
                    _this.availableChips.forEach(function (chip) {
                        var persistChip = Object.assign({}, chip);
                        persistChip.optionParams.data = [];
                        var isPersist = false;
                        if (chip.dataParams && chip.dataParams.length > 0) {
                            chip.dataParams.forEach(function (dataParam) {
                                if (_this.persistentQParamMap.has(dataParam.paramValue)) {
                                    isPersist = true;
                                    var dataObj = {
                                        key: dataParam.paramValue,
                                        value: _this.persistentQParamMap.get(dataParam.paramValue)
                                    };
                                    persistChip.optionParams.data.push(dataObj);
                                }
                            });
                        }
                        // push persist chips to an array
                        if (isPersist) {
                            _this.persistentChipsArr.push(persistChip);
                        }
                    });
                    // persist chips
                    if (_this.persistentChipsArr && _this.persistentChipsArr.length > 0) {
                        _this.persistentChipsArr.forEach(function (value) {
                            if (value.criteriaType === 'CALENDAR' && typeof value.optionParams.data[0].value !==
                                'string') {
                                value.optionParams.data[0].value = moment(value.optionParams.data[0].value)
                                    .format('YYYY-MM-DD');
                            }
                        });
                        _this.chipInputService.persistChips(_this.persistentChipsArr);
                        // trigger search when chips persisting
                        _this.isDataLoaded = false;
                        _this.onProductQueueSearch(_this.inputSearchCriteria);
                    }
                }
                _this.chipInputService.criteriaUpdate.subscribe(function (value) {
                    _this.inputSearchCriteria = value;
                });
            }
        });
    };
    ProductQueueSearchCriteriaComponent.prototype.onProductQueueSearch = function (event) {
        var _this = this;
        this.isDataLoaded = false;
        this.inputSearchCriteria = event;
        this.userJourneyManager.canProceed.next(false);
        if (this.tPagination && this.tPagination.initialFetchingPages &&
            this.tPagination.defaultPageResultCount) {
            this.inputSearchCriteria.size = this.tPagination.initialFetchingPages *
                this.tPagination.defaultPageResultCount;
            this.inputSearchCriteria.start = 0;
        }
        this.userJourneyManager.disableQuickActions([
            'TRIGGER_EXPORT_FILE'
        ]);
        this.inputSearchCriteria = event;
        this.chipInputService.selectedChipsSubject
            .subscribe(function (selectedChips) {
            _this.selectedChips = selectedChips;
        });
        var selectedChipsParamValues = [];
        this.selectedChips.forEach(function (value) {
            if ((value['chip'] && value['chip']['dataParams'] && value['chip']['dataParams'][0]['paramValue'])) {
                selectedChipsParamValues.push(value['chip']['dataParams'][0]['paramValue']);
            }
        });
        if (this.inputSearchCriteria && selectedChipsParamValues) {
            for (var item in (this.inputSearchCriteria)) {
                if (!selectedChipsParamValues.includes(item)) {
                    this.inputSearchCriteria[item] = null;
                }
            }
        }
        if (JSON.stringify(this.inputSearchCriteria) !== JSON.stringify({})) {
            this.dataStoreService.set(this.searchCriteriaUpdatingDataKey, this.inputSearchCriteria, true);
            this.dataStoreService.set(DataKey.productQueueSearchResultsFromService, null, true);
            // set criteria parameters to url
            var queryParams = [];
            queryParams = this.queryParamService.getQueryParamsArray(this.inputSearchCriteria);
            queryParams = queryParams.filter(function (val) { return val !== 'page'; });
            this.rootService.setDataToLocalStorage(this.searchCriteriaLoadingDataKey, this.inputSearchCriteria, true, queryParams, true);
            // remove grid sorting and filtering
            this.inputSearchCriteria.gridSorting = undefined;
            // search data
            this.reservationV2ManagementService.searchProducts(JSON.parse(JSON.stringify(this.inputSearchCriteria)));
            this.isTriggerSearch = false;
            this.searchTrigger.emit(true);
        }
        else {
            setTimeout(function () {
                _this.isDataLoaded = true;
                _this.isTriggerSearch = true;
            }, 0);
        }
    };
    ProductQueueSearchCriteriaComponent.prototype.getUpdatedFieldsDataObject = function (chip, criteria) {
        var dataObjArr = [];
        if (chip && chip.dataParams && chip.dataParams.length > 0) {
            chip.dataParams.forEach(function (dataParam) {
                var dataObj = {
                    key: '', value: ''
                };
                dataObj.key = dataParam.paramValue;
                dataObj.value = criteria[dataParam.paramValue];
                dataObjArr.push(dataObj);
            });
        }
        return dataObjArr;
    };
    ProductQueueSearchCriteriaComponent.prototype.getProductQueueSearchObserver = function () {
        var _this = this;
        this.searchResultLoadedObs = this.dataStoreService.get(this.searchResultsDataKey)
            .subscribe(function (data) {
            if (_this.commonHelper.dataValidity(data)) {
                setTimeout(function () {
                    _this.isDataLoaded = true;
                    _this.isTriggerSearch = true;
                }, 0);
            }
            else if (_this.commonHelper.isEmptyData(data)) {
                _this.isDataLoaded = true;
            }
            else if (data instanceof TcApiError) {
                _this.isDataLoaded = true;
            }
            else if (data instanceof TcHttpError) {
                _this.isDataLoaded = true;
            }
        });
    };
    ProductQueueSearchCriteriaComponent.prototype.errorObserver = function () {
        var _this = this;
        this.dataStoreService.get(DataKey.error).subscribe(function (data) {
            if (data && JSON.stringify(data) !== JSON.stringify({})) {
                setTimeout(function () {
                    _this.isDataLoaded = true;
                }, 0);
                _this.dataStoreService.set(DataKey.error, null);
            }
        });
    };
    ProductQueueSearchCriteriaComponent.prototype.resetQueryParams = function () {
        var productQueueQueryParams = [];
        productQueueQueryParams = this.queryParamService.getQueryParamsArray(new ProductQueueSearchCriteria());
        var productQueueSearchCriteria1 = this.rootService.loadRawDataFromLocalStorage(productQueueQueryParams, this.createNewSearchCriteriaFn(), this.searchCriteriaLoadingDataKey);
        // set criteria parameters to url
        var queryParams = [];
        queryParams = this.queryParamService.getQueryParamsArray(productQueueSearchCriteria1);
        this.rootService.setDataToLocalStorage(this.searchCriteriaLoadingDataKey, this.inputSearchCriteria, true, queryParams, true);
    };
    ProductQueueSearchCriteriaComponent.prototype.onCancel = function (event) {
        console.log('cancelled');
    };
    ProductQueueSearchCriteriaComponent.prototype.onChipListDisplay = function (event) {
        this.dataStoreService.set(DataKey.chipListDisplay, null, true);
        this.isChipListDisplay = event;
        this.dataStoreService.set(DataKey.chipListDisplay, this.isChipListDisplay, true);
    };
    ProductQueueSearchCriteriaComponent.prototype.handleQuickActions = function () {
        var _this = this;
        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'TRIGGER_SEARCH_CRITERIA').subscribe(function (e) {
            _this.triggerSearch(e.data);
        });
    };
    ProductQueueSearchCriteriaComponent.prototype.triggerSearch = function (data) {
        this.inputSearchCriteria = this.createNewSearchCriteriaFn();
        this.actionHandlerService.fillCriteriaByActionData(data, this.inputSearchCriteria);
        var requiredChips = this.chipHandler.getChipsFromCriteria(this.inputSearchCriteria, this.chipConfigKey, true);
        this.chipInputService.hardReset();
        this.chipInputService.persistChips(requiredChips, true);
        this.onProductQueueSearch(this.inputSearchCriteria);
    };
    ProductQueueSearchCriteriaComponent.prototype.handleInterRelatedChips = function () {
        var _this = this;
        this.relatedChipsObsv = this.chipInputService.relatedChipsSubject.subscribe(function (data) {
            var toggleChips = [];
            if (data && data.chip && JSON.stringify(data) !== JSON.stringify({})) {
                _this.modifiedChip = data.chip;
                // TODO: refine chip input service: inter related chips
                if (data.chip.type === 'CALENDAR') {
                    // startDate & returnDate validate
                    if (data.chip.id === 'START_DATE' || data.chip.id === 'BOOKING_START_DATE') {
                        var startDate = data.date;
                        var nights = 2;
                        var currentEndDate = _this.checkDependentForCalculateRelatedDate(data.chip);
                        _this.setMinDateForRelatedDateChips(data.chip, data.date);
                        if (currentEndDate) {
                            if (_this.dateFormatter.isAfter(startDate, currentEndDate)) {
                                _this.chipHandler.handleInterRelatedChips(data.chip, _this.dateFormatter.addDaysToDate(startDate, nights));
                            }
                            else {
                                _this.chipHandler.handleInterRelatedChips(data.chip, currentEndDate);
                            }
                        }
                        else {
                            _this.chipHandler.handleInterRelatedChips(data.chip, _this.dateFormatter.addDaysToDate(startDate, nights));
                        }
                    }
                }
                else {
                    _this.chipHandler.handleInterRelatedChips(data.chip, data.options.value[0]);
                }
                if (data.chip.optionParams.toggleChips && data.chip.optionParams.toggleChips.length > 0) {
                    toggleChips = data.chip.optionParams.toggleChips;
                    if (toggleChips && data.options && data.options.value[0].value) {
                        _this.chipInputService.selectedChipsBasedOnStateChange(toggleChips);
                    }
                    else {
                        _this.chipInputService.deleteChipsBasedOnStateChange(toggleChips);
                    }
                }
            }
        });
        this.relatedChipsProcessObsv = this.chipHandler.relatedChipsProcessSubject.subscribe(function (result) {
            if (result && JSON.stringify(result) !== JSON.stringify([]) &&
                _this.modifiedChip && _this.modifiedChip.optionParams.relatedChips &&
                _this.modifiedChip.optionParams.relatedChips.length > 0 &&
                result.length === _this.modifiedChip.optionParams.relatedChips.length) {
                // if chip date is passed date it will be reset for today
                result.forEach(function (chip) {
                    if (chip && chip.options && chip.dataParam && chip.dataParam === 'DATE'
                        && chip.criteriaId && chip.criteriaId === 'START_DATE') {
                        chip.options = _this.dateFormatter.dateFromString(chip.options) > _this.dateFormatter.getToday()
                            ? chip.options
                            : _this.dateFormatter.dateAsString(_this.dateFormatter.getToday());
                    }
                    if (chip.dataParam === 'DATE' && chip.criteriaId === 'END_DATE') {
                        // tslint:disable-next-line:max-line-length
                        /** Following code lines are commented to give the access to set past dates for End Date chip. uncomment below lines when you need to allow the restrictions * */
                        //     chip.options = this.dateFormatter.dateFromString(chip.options) > this.dateFormatter.getToday()
                        //         ? chip.options
                        //         : this.dateFormatter.dateAsString(this.dateFormatter.getToday());
                    }
                });
                // pass related chips to chip input service
                _this.chipInputService.handleInterRelatedChips(result);
            }
        });
    };
    ProductQueueSearchCriteriaComponent.prototype.setMinDateForRelatedDateChips = function (chip, date) {
        if (date && chip && chip.optionParams && chip.optionParams.relatedChips) {
            var minDate_1 = this.dateFormatter.dateFromString(date);
            chip.optionParams.relatedChips.forEach(function (relateChip) {
                if (relateChip.criteriaType === 'CALENDAR') {
                    relateChip.data = [{ key: 'minDate', value: minDate_1 }];
                }
            });
        }
    };
    ProductQueueSearchCriteriaComponent.prototype.checkDependentForCalculateRelatedDate = function (chip) {
        var dateParam;
        if (chip.optionParams && chip.optionParams.relatedChips) {
            var dependent = chip.optionParams.behaviouralData.find(function (behData) { return behData.key === 'DATE_RELATED_DEPENDENT'; });
            if (dependent) {
                dateParam = this.getValueOfDependentParameter(dependent.value);
            }
        }
        return dateParam;
    };
    ProductQueueSearchCriteriaComponent.prototype.getValueOfDependentParameter = function (dependent) {
        if (this.chipInputService && this.chipInputService.criteria &&
            this.chipInputService.criteria[dependent.paramValue]) {
            return this.chipInputService.criteria[dependent.paramValue];
        }
        return '';
    };
    ProductQueueSearchCriteriaComponent.prototype.ngOnDestroy = function () {
        this.userJourneyManager.routeChanges.next(false);
        if (this.selectedChipsObsv) {
            this.selectedChipsObsv.unsubscribe();
        }
        if (this.selectedFieldsObsv) {
            this.selectedFieldsObsv.unsubscribe();
        }
        if (this.chipValueUpdateSub) {
            this.chipValueUpdateSub.unsubscribe();
        }
        if (this.criteriaUpdateSubscription) {
            this.criteriaUpdateSubscription.unsubscribe();
        }
        if (this.deletedChipObsv) {
            this.deletedChipObsv.unsubscribe();
        }
        if (this.savedCriteriaSearchObs) {
            this.savedCriteriaSearchObs.unsubscribe();
        }
        if (this.searchResultLoadedObs) {
            this.searchResultLoadedObs.unsubscribe();
        }
        this.em.removeEvents([
            'TRIGGER_SEARCH_CRITERIA'
        ]);
    };
    ProductQueueSearchCriteriaComponent.prototype.selectedChipsUpdate = function () {
        var _this = this;
        this.criteriaUpdateSubscription = this.chipInputService.selectedChipsSubject.subscribe(function (selectedChips) {
            var e_1, _a;
            _this.selectedChips = selectedChips;
            if (selectedChips && JSON.stringify(selectedChips) !== JSON.stringify([])) {
                try {
                    // toggle chips when selecting BRANCH_OF_CLIENT_ID chip
                    for (var _b = tslib_1.__values(_this.selectedChips), _c = _b.next(); !_c.done; _c = _b.next()) {
                        var selectedChip = _c.value;
                        if (selectedChip.chip.id === 'BRANCH_OF_CLIENT_ID' && selectedChip.chip.optionParams.toggleChips &&
                            selectedChip.chip.optionParams.toggleChips.length > 0) {
                            _this.deleteToggleChips(selectedChip.chip.optionParams.toggleChips);
                            _this.disableChips(selectedChip.chip.optionParams.toggleChips);
                            break;
                        }
                    }
                }
                catch (e_1_1) { e_1 = { error: e_1_1 }; }
                finally {
                    try {
                        if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                    }
                    finally { if (e_1) throw e_1.error; }
                }
            }
        });
    };
    ProductQueueSearchCriteriaComponent.prototype.notifyChipDelete = function () {
        var _this = this;
        this.deletedChipObsv = this.chipInputService.deletedChipsSubject.subscribe(function (deletedChip) {
            if (JSON.stringify(deletedChip) !== JSON.stringify({}) && deletedChip !== null && deletedChip !==
                undefined) {
                if (deletedChip.id === 'CLIENT_TYPE') {
                    var deleteChips = _this.selectedChips.map(function (chip) { return chip.chip; });
                    _this.deleteSelectedChips(deleteChips);
                    _this.enableChips(_this.availableChips);
                }
                else if (deletedChip.id === 'BRANCH_OF_CLIENT_ID') {
                    if (deletedChip.optionParams.toggleChips && deletedChip.optionParams.toggleChips.length > 0) {
                        _this.enableChips(deletedChip.optionParams.toggleChips);
                    }
                }
            }
            if (deletedChip && (deletedChip.id === 'BOOKING_START_DATE')) {
                var startDate = new Date();
                startDate.setFullYear(startDate.getFullYear() - 5);
                _this.setMinDateForRelatedDateChips(deletedChip, startDate.toISOString().substring(0, 10));
                var currentEndDate = _this.checkDependentForCalculateRelatedDate(deletedChip);
                if (currentEndDate) {
                    _this.chipHandler.handleInterRelatedChips(deletedChip, currentEndDate);
                }
                else {
                    _this.chipHandler.handleInterRelatedChips(deletedChip, startDate);
                }
            }
        });
    };
    ProductQueueSearchCriteriaComponent.prototype.deleteToggleChips = function (toggleChips) {
        var _this = this;
        var deleteChips = [];
        toggleChips.forEach(function (toggleChip) {
            var e_2, _a;
            try {
                for (var _b = tslib_1.__values(_this.selectedChips), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var selectedChip = _c.value;
                    if (selectedChip.chip.id === toggleChip.criteriaId) {
                        deleteChips.push(selectedChip.chip);
                        break;
                    }
                }
            }
            catch (e_2_1) { e_2 = { error: e_2_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_2) throw e_2.error; }
            }
        });
        this.deleteSelectedChips(deleteChips);
    };
    ProductQueueSearchCriteriaComponent.prototype.deleteSelectedChips = function (deleteChips) {
        var _this = this;
        deleteChips.forEach(function (chip) {
            _this.chipInputService.deleteChip(chip, true);
        });
    };
    ProductQueueSearchCriteriaComponent.prototype.enableChips = function (enableChips) {
        var _this = this;
        enableChips.forEach(function (enableChip) {
            _this.chipInputService.modifyChipsStatus(enableChip, false);
        });
    };
    ProductQueueSearchCriteriaComponent.prototype.disableChips = function (disableChips) {
        var _this = this;
        disableChips.forEach(function (disableChip) {
            _this.chipInputService.modifyChipsStatus(disableChip, true);
        });
    };
    ProductQueueSearchCriteriaComponent.prototype.searchFromSavedCriteriaObserver = function () {
        var _this = this;
        this.isChipsInit = false;
        this.savedCriteriaSearchObs = this.dataStoreService.get(DataKey.searchFromSavedCriteria).subscribe(function (data) {
            if (_this.commonHelper.dataValidity(data) && !_this.isChipsInit) {
                var criteriaStartDate = _this.inputSearchCriteria.startDate;
                var criteriaEndDate = _this.inputSearchCriteria.endDate;
                _this.inputSearchCriteria = data;
                // this.inputSearchCriteria.startDate = criteriaStartDate;
                // this.inputSearchCriteria.endDate = criteriaEndDate;
                if (_this.inputSearchCriteria && !_this.inputSearchCriteria.startDate) {
                    _this.inputSearchCriteria.startDate = new Date().toISOString().split('T')[0];
                    _this.inputSearchCriteria.endDate
                        = new Date(+new Date() + 172800000)
                            .toISOString().split('T')[0];
                }
                var queryParams = [];
                queryParams.forEach(function (param) {
                    _this.queryParamService.deleteQueryParam(param);
                });
                queryParams = _this.queryParamService.getQueryParamsArray(_this.inputSearchCriteria);
                queryParams = queryParams.filter(function (val) { return val !== 'page'; });
                _this.rootService.setDataToLocalStorage(_this.searchCriteriaLoadingDataKey, _this.inputSearchCriteria, true, queryParams, true);
                _this.displayChipsArr = [];
                _this.persistentChipsArr = [];
                _this.persistentQParamMap = new Map();
                if (_this.inputSearchCriteria) {
                    _this.persistentQParams = [];
                    _this.persistentQParams = _this.queryParamService.getNotNullQueryParamsArray(_this.inputSearchCriteria);
                }
                if (!_this.isChipsInit) {
                    _this.initSearchCriteriaChips(); // load everything from start
                }
            }
        });
    };
    ProductQueueSearchCriteriaComponent.prototype.onShowSavedCriteriaClick = function () {
        var _this = this;
        this.dataStoreService.set(DataKey.searchFromSavedCriteria, null);
        this.dataStoreService.set(DataKey.popupClose, null);
        var dataObject = new ModalData(LEVEL.SUCCESS, 'Show Criteria', null, null, 'modal-basic--alt modal-basic--fixed-size', ShowSavedCriteriaModalComponent, {
            userDefinedCriteriaList: this.userDefinedCriteriaList,
            systemDefinedCriteriaList: this.systemDefinedCriteriaList,
            searchQueueVersion: 'V2'
        });
        dataObject.secondaryHeader = '';
        this.confirmModal(dataObject);
        this.searchFromSavedCriteriaObserver();
        this.dataStoreService.get(DataKey.popupClose).subscribe(function (data) {
            if (data != null) {
                _this.closeModal();
            }
        });
    };
    ProductQueueSearchCriteriaComponent.prototype.closeModal = function () {
        if (this.modalService.close()) {
            this.modalService.close().subscribe(function (res) { });
        }
    };
    ProductQueueSearchCriteriaComponent.prototype.confirmModal = function (data) {
        this.modalService.confirm(data).subscribe(function (res) { });
    };
    ProductQueueSearchCriteriaComponent.prototype.getUserDefinedCriteriaList = function () {
        var _this = this;
        this.dataStoreService.get(DataKey.userSavedSearchCriteriaListV2).subscribe(function (data) {
            if (data) {
                _this.userDefinedCriteriaList = data;
            }
        });
    };
    ProductQueueSearchCriteriaComponent.prototype.getSystemDefinedCriteriaList = function () {
        var _this = this;
        this.dataStoreService.get(DataKey.systemDefinedCriteriaV2).subscribe(function (data) {
            if (data) {
                _this.systemDefinedCriteriaList = data;
            }
        });
    };
    return ProductQueueSearchCriteriaComponent;
}());
export { ProductQueueSearchCriteriaComponent };
