import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { SummaryCardData } from '@tc-core/model/it/codegen/ui/framework/summary-card-data';
import { take } from 'rxjs/operators';
import { Booking } from '../../../models/reservation-v2/booking';
import { Supplier } from '../../../models/supplier/supplier';
import { PassengersDetailViewComponent } from './passengers-detail-view/passengers-detail-view.component';
import { BookingNotesDetailViewComponent } from './booking-notes-detail-view/booking-notes-detail-view.component';
import { ResponseUtil } from '../../../services/util/response/response-util.service';
import { BookingManagementService } from '../../../services/backend-consumers/reservation-v2/booking-management.service';
import { CommonHelper } from '@tc-core/util/helpers/common-helper.service';
import { BookingItem } from '../../../models/reservation-v2/booking-item';
import { ContentSummaryCardService } from '../../../services/util/ui/content-summary-card.service';
import { DataKey, DataStoreService } from '../../../services/util/framework/data-store.service';
import { ModalData } from '@tc-core/model/it/codegen/ui/framework/modal-data';
import { LEVEL } from '@tc-core/model/it/codegen/ui/framework/dialog-model';
import {
    PassengerSelectionDetailViewComponent
} from './booking-items-detail-view/passenger-selection-detail-view/passenger-selection-detail-view.component';
import { ModalService } from '@tc/modal/modal.service';
import { TCO } from '../../../constants';
import { ConfigLoader } from '@tc-core/util/framework/config-loader.service';
import {UserManagementService} from '../../../services/user-management/user-management.service';

@Component({
    selector: 'tc-booking-detail-view',
    templateUrl: './booking-detail-view.component.html'
})
export class BookingDetailViewComponent implements OnInit {

    @Input() booking: Booking;
    @Input() summaryCardData: SummaryCardData;
    @Input() controlMap: Map<any, string>;
    @Input() footerDataMap: Map<number, object>;

    @Output() headerClick: EventEmitter<any> = new EventEmitter();
    @Output() editClick: EventEmitter<any> = new EventEmitter();
    @Output() expandEventEmitter: EventEmitter<boolean> = new EventEmitter();

    detailedBooking: Booking;
    panelOpenState = false;
    bookingItemSummaryCards: SummaryCardData[] = [];
    bookingItemCardConfig: any;
    readonly = false;
    dataChanged = false;

    @ViewChild('passengersComponent') passengerComponent: PassengersDetailViewComponent;
    @ViewChild('bookingNotesComponent') bookingNotesComponent: BookingNotesDetailViewComponent;

    constructor(
        private bookingManagementService: BookingManagementService,
        private commonHelper: CommonHelper,
        private summaryCardService: ContentSummaryCardService,
        private dataStore: DataStoreService,
        private modalService: ModalService,
        private configLoader: ConfigLoader,
        public userManagementService: UserManagementService
    ) {
    }

    ngOnInit() {
        this.bookingItemCardConfig = this.configLoader.configurations.get(TCO.CONF.CONF_BOOKING_ITEM_CARD_CONFIG);
        if (this.summaryCardData) {
            this.expandEventEmitter.emit(true);
        }
        this.createBookingItemSummaryCards();
    }

    onHeaderClick(event) {
        this.headerClick.emit(event);
    }

    onEditClick(event) {
        this.editClick.emit(event);
    }

    getBookingDetailsById(id) {
        if (id !== null) {
            this.bookingManagementService.getById(id)
                .subscribe(
                    res => {
                        if (this.commonHelper.dataValidity(res)) {
                            this.detailedBooking = ResponseUtil.getFirstData(res);
                            console.log(this.detailedBooking);
                        }
                    }
                );
        }
    }

    passengerSelectionChangeFn = (valid) => {

    };

    private confirmModal(data: any): void {
        this.modalService.confirm(data).subscribe((res) => {
        });
    }

    closeModal() {
        if (this.modalService.close()) {
            this.modalService.close().subscribe((res) => {
            });
        }
    }

    onItemPassengersClick(event: BookingItem) {
        this.dataStore.set(DataKey.popupClose, null);
        const output = {
            changed: undefined,
            valid: undefined,
        };
        const dataObject = new ModalData(
            LEVEL.SUCCESS,
            'Item Passengers',
            null,
            null,
            'modal-basic--alt modal-basic--fixed-size',
            PassengerSelectionDetailViewComponent,
            {
                booking: this.booking,
                bookingItem: event,
                readonly: this.readonly,
                onChangeFn: this.passengerSelectionChangeFn.bind(this)
            }
        );
        this.confirmModal(dataObject);
        this.dataStore.get(DataKey.popupClose).subscribe((data) => {
            if (data != null) {
                // this.closeModal();
            }
        });
    }

    createBookingItemSummaryCards() {
        if (this.booking && this.booking.bookingItems) {
            const dataArray = [];
            for (const bookingItem of this.booking.bookingItems) {
                let iconKey = '';
                if (bookingItem.genBookingItem) {
                    if (bookingItem.genBookingItem.productCode) {
                        iconKey = bookingItem.genBookingItem.productCode;
                    } else if (bookingItem.genBookingItem.hotelRoomType) {
                        iconKey = 'accom';
                    } else {
                        iconKey = 'generic';
                    }
                } else if (bookingItem.trsBookingItem) {
                    iconKey = 'vehicle';
                }
                dataArray.push(
                    {
                        iconType: iconKey,
                        bookingReferenceId: bookingItem.bookingReferenceId
                    }
                );
            }
            this.bookingItemSummaryCards = this.summaryCardService.getContentCardsByConfig(
                this.bookingItemCardConfig,
                dataArray
            );
        } else {
            this.bookingItemSummaryCards = [];
        }
    }
}
