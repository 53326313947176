import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule, MatCheckboxModule } from '@angular/material';
import { TranslateModule } from '@ngx-translate/core';
import { DataStore } from '@tc-core/util/framework/data-store.service';
import { CommonHelper } from '@tc-core/util/helpers/common-helper.service';
import { ContentStripModule } from '@tc/content-strip';
import { DirectivesModule } from '@tc/directives';
import { ReservationServiceGroupNoteModule } from '../reservation-service-group-note/reservation-service-group-note.module';
import { ReservationServiceGroupNotesComponent } from './reservation-service-group-notes.component';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule.forChild(),
        MatCheckboxModule,
        MatButtonModule,
        ReservationServiceGroupNoteModule,
        ContentStripModule,
        DirectivesModule,
        FormsModule
    ],
    providers: [
        DataStore,
        CommonHelper
    ],
    declarations: [ReservationServiceGroupNotesComponent],
    exports: [ReservationServiceGroupNotesComponent]
})
export class ReservationServiceGroupNotesModule {}
