import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule, MatDialogModule, MatFormFieldModule, MatInputModule } from '@angular/material';
import { ChipSelectorModule } from '@tc/chip-selector';
import { ManageNotesComponent } from './manage-notes.component';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
    imports: [
        CommonModule,
        MatDialogModule,
        MatInputModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        ChipSelectorModule,
        MatButtonModule,
        TranslateModule.forChild()
    ],
  exports: [
    ManageNotesComponent
  ],
  declarations: [
    ManageNotesComponent
  ]
})
export class ManageNotesModule {}
