import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule, MatIconModule, MatMenuModule, MatTooltipModule} from '@angular/material';
import {TranslateModule} from '@ngx-translate/core';
import {SetupGridModule} from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid.module';
import {GridSearchCriteriaModule} from '../../search-criteria/grid-search-criteria/grid-search-criteria.module';
import {CommonJobGridComponent} from './common-job-grid/common-job-grid.component';
import {CommonJobHistoryGridComponent} from './common-job-history-grid/common-job-history-grid.component';
import {JobSearchCriteriaComponent} from './job-search-criteria/job-search-criteria.component';
import {JobHistorySearchCriteriaComponent} from './job-history-search-criteria/job-history-search-criteria.component';
import {JobParameterFocusViewComponent} from './job-parameter-focus-view/job-parameter-focus-view.component';
import {LocationsGridSetupSearchCriteriaModule} from '../../setup/locations-grid-setup-search-criteria/locations-grid-setup-search-criteria.module';

@NgModule({
    declarations: [
        CommonJobGridComponent,
        JobSearchCriteriaComponent,
        CommonJobHistoryGridComponent,
        JobHistorySearchCriteriaComponent,
        JobParameterFocusViewComponent
    ],
    imports: [
        CommonModule,
        GridSearchCriteriaModule,
        SetupGridModule,
        MatIconModule,
        MatTooltipModule,
        MatMenuModule,
        MatButtonModule,
        TranslateModule,
        LocationsGridSetupSearchCriteriaModule
    ],
    exports: [CommonJobGridComponent,
        JobSearchCriteriaComponent,
        CommonJobHistoryGridComponent
    ],
    entryComponents: [
        CommonJobGridComponent,
        JobSearchCriteriaComponent,
        CommonJobHistoryGridComponent,
        JobParameterFocusViewComponent
    ]
})
export class CommonJobModule {
}
