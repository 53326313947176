<mat-card class="tc-ag-grid__edit-dropdown">
  <div tabindex="0">
    <mat-select
      panelClass="ag-custom-component-popup" #selectBox [multiple]="selectMultiple"
      [(ngModel)]="selectedItem"
      (ngModelChange)="onValueChange($event)"
      [style.width]="columnWidth">
      <mat-option *ngFor="let option of options" [value]="option">
        {{displayItemFn(option) | translate}}
      </mat-option>
    </mat-select>
  </div>
</mat-card>
