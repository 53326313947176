import { CellEvent } from 'ag-grid-community';

export class GridCommonUtil {

    static toBoolean(value: any): boolean {
        if (typeof value === 'boolean') {
            return value;
        }
        if (typeof value === 'string') {
            return value === 'true' || value === 'True';
        }
    }

    static toBooleanAcceptTrue(value: any, acceptTrue: string): boolean {
        if (typeof value === 'boolean') {
            return value;
        }
        if (typeof value === 'string') {
            return value === acceptTrue;
        }
    }

    static removeElement(array: any[], element: any) {
        const index = array.indexOf(element);
        if (index > -1) {
            array.splice(index, 1);
        }
    }

    static removeElements(array: any[], element: any) {
        let index = array.indexOf(element);
        while (index > -1) {
            array.splice(index, 1);
            index = array.indexOf(element);
        }
    }

    static getNextCell(params: CellEvent, onlyEditableCol: boolean, startRow?: number) {
        let rowIndex = params.rowIndex;

        // to stop the recursive method running infinitely
        let firstIteration = false;
        if (startRow === undefined) {
            startRow = rowIndex;
            firstIteration = true;
        }

        const currentCol = params.column;
        let nextCol = params.columnApi.getDisplayedColAfter(currentCol);
        if (!nextCol) {
            nextCol = params.columnApi.getAllDisplayedColumns()[0];
            rowIndex += 1;
            if (params.api.getDisplayedRowCount() < rowIndex) {
                rowIndex = 0;
            } else if (params.api.paginationGetPageSize() > 0 &&
                (rowIndex + 1) > params.api.paginationGetPageSize() &&
                (rowIndex + 1) % params.api.paginationGetPageSize() === 1) {
                params.api.paginationGoToNextPage();
            }
        }
        if (nextCol && nextCol.getColDef()) {
            if (onlyEditableCol && !nextCol.getColDef()['editable'] && (startRow !== rowIndex || firstIteration)) {
                params.rowIndex = rowIndex;
                params.column = nextCol;
                return GridCommonUtil.getNextCell(params, onlyEditableCol, startRow);
            } else {
                const colKey = nextCol.getColId();
                return {
                    rowIndex: rowIndex,
                    colKey: colKey
                };
            }
        } else {
            return null;
        }
    }

}
