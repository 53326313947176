<mat-card>
    <div>
        <div *ngFor="let option of options">
            <mat-checkbox
                    class="tc-ag-grid__checkbox"
                    [class.tc-ag-grid__checkbox--error]="isError"
                    [ngModel]="isSelected(option)"
                    [disabled]="disabled"
                    (change)="onChange($event,option)">
                {{getName(option) | translate}}
            </mat-checkbox>
        </div>
    </div>
</mat-card>
