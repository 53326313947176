import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild, ViewContainerRef
} from '@angular/core';
import { MatCalendar } from '@angular/material';
// import { CgCalendarCssClassConstants } from '@tc/cg-calendar/cg-calendar-css-class-constants';
import { Observable, Subscription } from 'rxjs';
// import { DateRange } from '@tc/cg-calendar/date-range';
// import { RangeGroup } from '@tc/cg-calendar/range-group';

import * as moment from 'moment';
import { CgCalendarCssClassConstants } from '../cg-calendar-css-class-constants';
import { DateRange } from '../date-range';
import { RangeGroup } from '../range-group';

@Component({
  selector: 'tc-single-year',
  templateUrl: './single-year.component.html'
})
export class SingleYearComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() year: number;
  @Input() rangeGroups: RangeGroup[];
  @Input() calendarRefreshEvent: Observable<any>;

  @Output() selectDate: EventEmitter<Date> = new EventEmitter();

  @ViewChild('matCalendar') matCalendar: MatCalendar<any>;

  selectedDate: Date = null;
  showCalendar: boolean = true;

  numOfRows: number = 3;
  numOfCols: number = 4;

  rowsIndexArray: any[];
  colsIndexArray: any[];

  calendarRefreshEventSubscription: Subscription;

  cgCalDateRangeCssClasses = [];

  constructor(
    private viewContainer: ViewContainerRef
  ) {
  }

  ngOnInit() {
    this.initCustomClassList();
    this.createCalendarMonthArrays();
    if (this.calendarRefreshEvent) {
      this.subscribeCalendarRefreshEvent();
    }
  }

  public ngAfterViewInit(): void {
    this.updateCalendar();
  }

  initCustomClassList() {
    for (let colorIdKey in CgCalendarCssClassConstants.ColorId) {
      this.cgCalDateRangeCssClasses.push(CgCalendarCssClassConstants.ColorId[colorIdKey].class);
    }
    for (let rangeStateKey in CgCalendarCssClassConstants.RangeState) {
      this.cgCalDateRangeCssClasses.push(CgCalendarCssClassConstants.RangeState[rangeStateKey]);
    }
  }

  subscribeCalendarRefreshEvent() {
    this.calendarRefreshEventSubscription = this.calendarRefreshEvent.subscribe(
      () => {
        this.updateCalendar();
      }
    );
  }

  createCalendarMonthArrays() {
    this.rowsIndexArray = SingleYearComponent.arrayOfNumbers(this.numOfRows);
    this.colsIndexArray = SingleYearComponent.arrayOfNumbers(this.numOfCols);
  }

  static arrayOfNumbers(n: number): number[] {
    let arr = [];
    for (let i = 0; i < n; i++) {
      arr.push(i);
    }
    return arr;
  }

  getMonthStartingDate(row_index: number, col_index: number): string {
    let month = this.numOfCols * (row_index) + col_index;
    let date = moment([this.year, month]);
    return date.format('YYYY-MM-DD');
  }

  dateClass = (date: Date) => {
    let classes = [];
    this.rangeGroups.forEach(
      group => {
        if (group.dateRanges) {
          group.dateRanges.forEach(
            range => {
              if (SingleYearComponent.withinRange(date, range)) {
                classes.push(group.colorId);
                if (group.selected) {
                  classes.push(CgCalendarCssClassConstants.RangeState.RANGE_SELECTED);
                }
                if (SingleYearComponent.isLeftEdge(date, range)) {
                  classes.push(CgCalendarCssClassConstants.RangeState.RANGE_START_DATE);
                }
                if (SingleYearComponent.isRightEdge(date, range)) {
                  classes.push(CgCalendarCssClassConstants.RangeState.RANGE_END_DATE);
                }
              }
            }
          );
        }
      });
    return classes;
  };

  public static withinRange(d: Date, dateRange: DateRange): boolean {
    if (dateRange.startDate && dateRange.endDate) {
      if (moment(dateRange.startDate).isSame(d, 'day')) {
        return true;
      } else if (moment(dateRange.endDate).isSame(d, 'day')) {
        return true;
      } else {
        return dateRange.startDate.getTime() <= d.getTime() && d.getTime() <= dateRange.endDate.getTime();
      }
    } else {
      return false;
    }
  }

  public static isLeftEdge(d: Date, dateRange: DateRange): boolean {
    return moment(d).format('YYYY-MM-DD') === moment(dateRange.startDate).format('YYYY-MM-DD');
  }

  public static isRightEdge(d: Date, dateRange: DateRange): boolean {
    return moment(d).format('YYYY-MM-DD') === moment(dateRange.endDate).format('YYYY-MM-DD');
  }

  onSelectDate($event: Date) {
    this.selectedDate = new Date($event);
    this.selectDate.emit($event);
  }

  updateCalendar() {
    this.updateCalendarClasses();
  }

  updateCalendarClasses() {
    let matCalAllDates: NodeListOf<HTMLElement> = this.viewContainer
                                                      .element
                                                      .nativeElement
                                                      .querySelectorAll('.mat-calendar-body-cell');

    for (let i = 0; i < matCalAllDates.length; i++) {
      // for (let matCalAllDatesKey in matCalAllDates) {
      let dateNode = matCalAllDates[i];
      let date = this.getDateFromMatCalDateNode(dateNode);
      if (date) {

        let innerDiv: HTMLElement = dateNode.querySelector('div');

        // clean previous custom css css classes
        this.cgCalDateRangeCssClasses.forEach(customClass => {
          innerDiv.classList.remove(customClass);
        });

        // add new classes
        let newClassList: string[] = this.dateClass(date);
        newClassList.forEach(newClass => {
          innerDiv.classList.add(newClass);
        });

        // tooltip
        let toolTips: NodeListOf<Element> = innerDiv.querySelectorAll('.tc-sc-tooltip__txt');
        if (toolTips && toolTips.length > 0) {
          for (let j = 0; j < toolTips.length; j++) {
            innerDiv.removeChild(toolTips[j]);
          }
        }
        innerDiv.classList.add('tc-sc-tooltip');

        let span = document.createElement('span');
        let seasonNames = this.getSeasonNamesOfDate(date);
        let str = '';
        if (seasonNames.length > 0) {
          seasonNames.forEach(name => {
            str += name + ', ';
          });
          span.innerHTML = str.substring(0, str.length - 2);
          span.classList.add('tc-sc-tooltip__txt');
          innerDiv.appendChild(span);
        }
      }
    }
    this.selectedDate = null;
  }

  private getSeasonNamesOfDate(date: Date): string[] {
    let seasonNames = [];
    this.rangeGroups.forEach(
      group => {
        if (group.dateRanges) {
          group.dateRanges.forEach(
            range => {
              if (SingleYearComponent.withinRange(date, range)) {
                seasonNames.push(group.name);
              }
            }
          );
        }
      });
    return seasonNames;
  }

  private getDateFromMatCalDateNode(node: HTMLElement): Date {
    try {
      let date: Date = new Date(node.attributes['aria-label'].nodeValue);
      if (date) {
        return date;
      } else {
        return null;
      }
    } catch (e) {
      return null;
    }
  }

  ngOnDestroy(): void {
    if (this.calendarRefreshEventSubscription) {
      this.calendarRefreshEventSubscription.unsubscribe();
    }
  }

  public checkScroll($event: UIEvent) {

  }

}
