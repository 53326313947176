import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FullCalendarModule } from '@fullcalendar/angular';
import { ResCalendarSideNavModule } from '../res-calendar-side-nav/res-calendar-side-nav.module';
import { AvailabilityCalendarComponent } from './availability-calendar.component';
import {ChipDropdownModule} from '@tc/chip-dropdown';

@NgModule({
    declarations: [AvailabilityCalendarComponent],
    imports: [
        CommonModule,
        ResCalendarSideNavModule,
        FullCalendarModule,
        ChipDropdownModule
    ],
    exports: [
        AvailabilityCalendarComponent
    ],
})
export class AvailabilityCalendarModule {}
