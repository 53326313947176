export enum CommonDataType {
    CURRENCY_DEPRECATED = 'CURRENCY_DEPRECATED',
    CURRENCY = 'CURRENCY',
    COUNTRY = 'COUNTRY',
    STATE = 'STATE',
    CITY = 'CITY',
    PAYMENT_METHOD = 'PAYMENT_METHOD',
    TAX_SCHEME_MD = 'TAX_SCHEME_MD',
    TITLE = 'TITLE',
    DOCUMENT_TYPE = 'DOCUMENT_TYPE',
    TRANSFER_MODE = 'TRANSFER_MODE',
    DISPATCH_METHOD = 'DISPATCH_METHOD',
    DOCUMENT_FORMAT = 'DOCUMENT_FORMAT',
    DOCUMENT_FREQUENCY = 'DOCUMENT_FREQUENCY',
    RESOURCE_CATEGORY = 'RESOURCE_CATEGORY',
    VEHICLE_FACILITY = 'VEHICLE_FACILITY',
    CATEGORY = 'CATEGORY',
    ROUTE = 'ROUTE',
    LOCATION_GROUP = 'LOCATION_GROUP',
    OP_LOCATION = 'OP_LOCATION',
    LOCATION_TYPE = 'LOCATION_TYPE',
    SUPPLIER_TYPE = 'SUPPLIER_TYPE',
    LOCALE = 'LOCALE',
    PROFILE_TYPE = 'PROFILE_TYPE',
    VEHICLE_MODEL = 'VEHICLE_MODEL',
    DAY_OF_WEEK = 'DAY_OF_WEEK',
}
