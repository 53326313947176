import * as tslib_1 from "tslib";
import { AbstractSearchCriteria } from './abstract-search-criteria';
var NotificationSearchCriteria = /** @class */ (function (_super) {
    tslib_1.__extends(NotificationSearchCriteria, _super);
    function NotificationSearchCriteria() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.supplierCode = "";
        _this.supplierName = "";
        _this.resourceName = "";
        _this.type = "";
        _this.serviceType = "";
        _this.modifiedDate = "";
        _this.startDate = "";
        _this.endDate = "";
        _this.indicator = "";
        return _this;
    }
    return NotificationSearchCriteria;
}(AbstractSearchCriteria));
export { NotificationSearchCriteria };
