import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CurrencyFormat } from '@tc-core/model/it/codegen/ui/framework/currency-format';
import { TC } from '@tc-core/util/constants';
import { ConfigLoader } from '@tc-core/util/framework/config-loader.service';
import { TCO } from '../../../../../constants';
import { ServiceItemChanges } from '../../../../../models/helper/service-item-changes';
import { BookingItem } from '../../../../../models/reservation-v2/booking-item';
import { PaxCount } from '../../../../../models/reservation-v2/pax-count';
import { ServiceItem } from '../../../../../models/reservation/service-item';

@Component({
    selector: 'tc-supplier-allocation-detail-view',
    templateUrl: './supplier-allocation-detail-view.component.html'
})
export class SupplierAllocationDetailViewComponent implements OnInit {

    CURRENCY_TYPE = TCO.AppData.DEF_CURRENCY_TYPE;
    EMPTY_CHAR = TC.FORMAT.EMPTY_CHAR;

    @Input() summaryCardData: any;
    @Input() itemList: (ServiceItem | BookingItem)[] = [];
    @Input() assignedSupplier: any;
    @Input() paxCount: PaxCount;
    @Input() currencyFormat: CurrencyFormat;

    @Output() headerClick: EventEmitter<any> = new EventEmitter();
    @Output() clickOutSide: EventEmitter<any> = new EventEmitter();

    supplierCostChangeForm: FormGroup;
    totalCost = 0;

    constructor(
        private fb: FormBuilder,
        private configLoader: ConfigLoader
    ) {
    }

    ngOnInit() {
        this.createForm();
        this.currencyFormat = this.getCurrencyFormat();

        // this.vehicleCapacity = this.summaryCardData.data.value.capacity ? this.summaryCardData.data.value.capacity : 1;
        this.assignedSupplier = this.summaryCardData.data.value;
        // this.summaryCardData.data.value.estimatedUnitCost = this.summaryCardData.data.value.cost;
    }

    private getCurrencyFormat(): CurrencyFormat {
        let currencyFormat;
        let currencySymbolPattern = this.configLoader.configurations.get(TC.CONF.CONF_SYSTEM).defaults.default_currency_symbol_pattern;
        currencyFormat = new CurrencyFormat(TCO.AppData.DEF_CURRENCY_TYPE, currencySymbolPattern);
        return currencyFormat;
    }

    onAdultCostChange(data: any) {
        if (JSON.stringify(data) !== JSON.stringify({})) {
            this.summaryCardData.calculatedCost.adultCost = parseFloat(data);
            this.assignedSupplier.calculatedCost.adultCost = data * this.paxCount.adultCount;
            this.assignedSupplier.calculatedCost.totalCost = this.assignedSupplier.calculatedCost.adultCost +
                this.assignedSupplier.calculatedCost.childCost + this.assignedSupplier.calculatedCost.infantCost;
        }
    }

    onChildCostChange(data: any) {
        if (JSON.stringify(data) !== JSON.stringify({})) {
            this.summaryCardData.calculatedCost.childCost = parseFloat(data);
            this.assignedSupplier.calculatedCost.childCost = data * this.paxCount.childCount;
            this.assignedSupplier.calculatedCost.totalCost = this.assignedSupplier.calculatedCost.adultCost +
                this.assignedSupplier.calculatedCost.childCost + this.assignedSupplier.calculatedCost.infantCost;
        }
    }

    onInfantCostChange(data: any) {
        if (JSON.stringify(data) !== JSON.stringify({})) {
            this.summaryCardData.calculatedCost.infantCost = parseFloat(data);
            this.assignedSupplier.calculatedCost.infantCost = data * this.paxCount.infantCount;
            this.assignedSupplier.calculatedCost.totalCost = this.assignedSupplier.calculatedCost.adultCost +
                this.assignedSupplier.calculatedCost.childCost + this.assignedSupplier.calculatedCost.infantCost;
        }
    }

    onUnitCostChange(data: any) {
        if (JSON.stringify(data) !== JSON.stringify({})) {
            this.summaryCardData.calculatedCost.unitCost = parseFloat(data);
            this.assignedSupplier.calculatedCost.unitCost = data / this.assignedSupplier.calculatedCost.capacity * this.paxCount.totalPax;
            // this.assignedSupplier.calculatedCost.totalCost = this.assignedSupplier.calculatedCost.unitCost;
        }
    }

    createForm() {
        this.supplierCostChangeForm = this.fb.group({
            unit: [this.summaryCardData.data.value ? this.summaryCardData.data.value.unitCost : ''],
            adult: [this.summaryCardData.data.value ? this.summaryCardData.data.value.adultCost : ''],
            child: [this.summaryCardData.data.value ? this.summaryCardData.data.value.childCost : ''],
            infant: [this.summaryCardData.data.value ? this.summaryCardData.data.value.infantCost : '']
        });
    }


}
