import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatButtonModule, MatCheckboxModule } from '@angular/material';
import { TranslateModule } from '@ngx-translate/core';
import { DataStore } from '@tc-core/util/framework/data-store.service';
import { CommonHelper } from '@tc-core/util/helpers/common-helper.service';
import { ContentStripModule } from '@tc/content-strip';
import { DirectivesModule } from '@tc/directives';
import { ReservationServiceItemModule } from '../reservation-service-item/reservation-service-item.module';
import { ReservationServiceItemsComponent } from './reservation-service-items.component';
import { MatTooltipModule } from '@angular/material/tooltip';
@NgModule({
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    MatCheckboxModule,
    MatButtonModule,
    ReservationServiceItemModule,
    ContentStripModule,
    DirectivesModule,
    FormsModule,
    MatTooltipModule
  ],
  providers: [
    DataStore,
    CommonHelper
  ],
  declarations: [ReservationServiceItemsComponent],
  exports: [ReservationServiceItemsComponent]
})
export class ReservationServiceItemsModule {}
