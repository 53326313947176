import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserProfile } from '../../../../authentication/user-profile';
import { BrandSearchCriteria } from '../../../../models/criteria/brand-search-criteria';
import { DistributionChannelSearchCriteria } from '../../../../models/criteria/distribution-channel-search-criteria';
import { DivisionSearchCriteria } from '../../../../models/criteria/division-search-criteria';
import { Booking } from '../../../../models/reservation-v2/booking';
import { Resource } from '../../../../models/resource/resource';
import { Supplier } from '../../../../models/supplier/supplier';
import { BrandSetupService } from '../../../../services/backend-consumers/setups/brand-setup-service';
import {
    DistributionChannelSetupService
} from '../../../../services/backend-consumers/setups/distribution-channel-setup-service';
import { DivisionSetupService } from '../../../../services/backend-consumers/setups/division-setup-service';
import { UserManagementService } from '../../../../services/user-management/user-management.service';
import { DataKey, DataStoreService } from '../../../../services/util/framework/data-store.service';

@Component({
    selector: 'tc-key-controls-edit-view',
    templateUrl: './key-controls-edit-view.component.html'
})
export class KeyControlsEditViewComponent implements OnInit {

    objectKcInfoForm: FormGroup;
    isBrandReadOnly = false;
    isDivisionReadOnly = false;
    isDistributionChannelReadOnly = false;
    defCompany: any;
    defDivision: any;
    defBrand: any;
    defDisChannel: any;
    hasABrand = false;
    hasADistributionChannel = false;
    hasADivision = false;
    brands = [];
    private brandSearchCriteria: BrandSearchCriteria = new BrandSearchCriteria();
    divisions = [];
    private divisionSearchCriteria: DivisionSearchCriteria = new DivisionSearchCriteria();
    distributionChannels = [];
    private distributionChannelSearchCriteria: DistributionChannelSearchCriteria = new DistributionChannelSearchCriteria();

    @Input() object: (Supplier | Resource | Booking);
    @Input() userProfile: UserProfile;
    @Input() mandatory = false;
    @Input() disableKeyControlEdit = false;
    @Output() changeFormValidity: EventEmitter<boolean> = new EventEmitter();

    constructor(
        private fb: FormBuilder,
        private brandSetupService: BrandSetupService,
        private divisionSetupService: DivisionSetupService,
        private distributionChannelSetupService: DistributionChannelSetupService,
        private userManagementService: UserManagementService,
        private dataStoreService: DataStoreService
    ) {
    }

    ngOnInit() {
        this.getBrands();
        this.getDivisions();
        this.getDistributionChannels();
        this.keyControlAvailability();
        this.createForm();
        // validate profile keycontrols with user keycontrols
        this.mapUserKeyControls();
        this.validateObjectKeyControlsWithUserProfile();
        this.validateForm();

        this.objectKcInfoForm.valueChanges.subscribe(
            (data) => {
                if (JSON.stringify(data) !== JSON.stringify({})) {
                    this.object.kcCompany = data.kcCompany;
                    this.object.kcDivision = data.kcDivision;
                    this.object.kcBrand = data.kcBrand;
                    this.object.kcDistributionChannel = data.kcDistributionChannel;

                    if (this.changeFormValidity) {
                        this.changeFormValidity.emit(this.objectKcInfoForm.valid);
                    }
                }
            }
        );
    }

    public keyControlAvailability() {
        this.defCompany = [
            {
                code: this.userProfile && this.userProfile.kcCompany ? this.userProfile.kcCompany : null,
                name: this.userProfile && this.userProfile.kcCompany ? this.userProfile.kcCompanyName : null
            }
        ];
        this.hasABrand = this.userProfile && this.userProfile.kcBrand;
        if (this.hasABrand) {
            this.brands = [
                {
                    code: this.userProfile && this.userProfile.kcBrand ? this.userProfile.kcBrand : null,
                    name: this.userProfile && this.userProfile.kcBrand ? this.userProfile.kcBrandName : null
                }
            ];
        }
        this.hasADivision = this.userProfile && this.userProfile.kcDivision;
        if (this.hasADivision) {
            this.divisions = [
                {
                    code: this.userProfile && this.userProfile.kcDivision ? this.userProfile.kcDivision : null,
                    name: this.userProfile && this.userProfile.kcDivisionName ? this.userProfile.kcDivisionName : null
                }
            ];
        }
        this.hasADistributionChannel = this.userProfile && this.userProfile.kcDistributionChannel;
        if (this.hasADistributionChannel) {
            this.distributionChannels = [
                {
                    code: this.userProfile && this.userProfile.kcDistributionChannel ?
                        this.userProfile.kcDistributionChannel :
                        null,
                    name: this.userProfile && this.userProfile.kcDistributionChannelName ?
                        this.userProfile.kcDistributionChannelName :
                        null
                }
            ];
        }
    }

    public validateForm() {
        Object.keys(this.objectKcInfoForm.controls).forEach(field => {
            const control = this.objectKcInfoForm.get(field);
            control.markAsTouched();
            control.markAsDirty();
        });
    }

    private createForm() {
        this.objectKcInfoForm = this.fb.group({
            kcCompany: [
                this.object && this.object.kcCompany ?
                    this.object.kcCompany :
                    (this.userProfile && this.userProfile.kcCompany ? this.userProfile.kcCompany : 'Any'),
                Validators.required
            ],
            kcDivision: [
                this.object && this.object.kcDivision ?
                    this.object.kcDivision :
                    (this.userProfile && this.userProfile.kcDivision ? this.userProfile.kcDivision : null),
                Validators.required
            ],
            kcBrand: [
                this.object && this.object.kcBrand ?
                    this.object.kcBrand :
                    (this.userProfile && this.userProfile.kcBrand ? this.userProfile.kcBrand : null),
                this.getValidatorRequired()
            ],
            kcDistributionChannel: [
                this.object && this.object.kcDistributionChannel ?
                    this.object.kcDistributionChannel :
                    (this.userProfile && this.userProfile.kcDistributionChannel ?
                        this.userProfile.kcDistributionChannel :
                        null),
                this.getValidatorRequired()
            ]
        });
    }

    getValidatorRequired() {
        if (this.mandatory) {
            return Validators.required;
        }
        return null;
    }

    getDivisions() {
        this.divisionSearchCriteria = new DivisionSearchCriteria();
        this.divisionSearchCriteria.kcCompany = this.userProfile.kcCompany;
        if (!this.userProfile.kcDivision) {
            this.divisionSetupService.getRows(this.divisionSearchCriteria).subscribe(
                result => {
                    if (result) {
                        const data = result.data;
                        data.forEach(value => this.divisions.push(value));
                    }
                });
        }
        return this.divisions;
    }

    getBrands() {
        this.brandSearchCriteria = new BrandSearchCriteria();
        this.brandSearchCriteria.kcCompany = this.userProfile.kcCompany;
        this.brandSearchCriteria = this.userManagementService.updateSearchCriteriaWithUserKC(this.brandSearchCriteria);
        if (!this.userProfile.kcBrand) {
            this.brandSetupService.getRows(this.brandSearchCriteria).subscribe(
                result => {
                    if (result) {
                        const data = result.data;
                        data.forEach(value => this.brands.push(value));
                        if (!this.mandatory){
                            this.brands.unshift({
                                code: '',
                                name: 'Any'
                            });
                        }
                    }
                });
        }
        return this.brands;
    }

    getDistributionChannels() {
        this.distributionChannelSearchCriteria = new DistributionChannelSearchCriteria();
        this.distributionChannelSearchCriteria.kcCompany = this.userProfile.kcCompany;
        if (!this.userProfile.kcDistributionChannel) {
            this.distributionChannelSetupService.getRows(this.distributionChannelSearchCriteria)
                .subscribe(
                    result => {
                        if (result) {
                            const data = result.data;
                            data.forEach(value => this.distributionChannels.push(value));
                            if (!this.mandatory){
                                this.distributionChannels.unshift({
                                    code: '',
                                    name: 'Any'
                                });
                            }
                        }
                    });
        }
        return this.distributionChannels;
    }

    getErrorMessage(field: string): string {
        switch (field) {
            case 'kcDivision' :
                return this.objectKcInfoForm.get('kcDivision').hasError('required') ? 'Division Required' : '';
            case 'kcBrand' :
                return this.objectKcInfoForm.get('kcBrand').hasError('required') ? 'Brand Required' : '';
            case 'kcDistributionChannel' :
                return this.objectKcInfoForm.get('kcDistributionChannel').hasError('required') ?
                    'Distribution Channel Required' :
                    '';
            default :
                return '';
        }
    }

    private validateObjectKeyControlsWithUserProfile() {
        if (this.userProfile.kcBrand) {
            this.isBrandReadOnly = true;
        }
        if (this.userProfile.kcDivision) {
            this.isDivisionReadOnly = true;
        }
        if (this.userProfile.kcDistributionChannel) {
            this.isDistributionChannelReadOnly = true;
        }
    }

    private mapUserKeyControls() {
        if (this.userProfile) {
            this.object.kcCompany = this.userProfile.kcCompany;
            this.object.kcDivision = this.userProfile.kcDivision;
            this.object.kcBrand = this.userProfile.kcBrand;
            this.object.kcDistributionChannel = this.userProfile.kcDistributionChannel;
        }
    }
}
