<!--<section style="height: 70px; margin-top:30px; margin-left:30px">
  <div class="example-button-row">
    <div class="example-flex-container">

      <button mat-stroked-button color="primary" (click)="onSearch()" style="margin-right: 50px;">Search</button>

      <button mat-icon-button color="primary" (click)="onSearchPrevious()">
        <mat-icon>arrow_back_ios</mat-icon>
      </button>
      <span style="color: gray;">Page: {{this.searchPage}}</span>

      <button mat-icon-button color="primary" (click)="onSearchNext()">
        <mat-icon>arrow_forward_ios</mat-icon>
      </button>

      <button style="float: right;" mat-icon-button color="primary" (click)="onLogRightSideData()">
        <mat-icon>print</mat-icon>
      </button>

    </div>
  </div>
</section>-->
<tc-locations-grid-setup-search-criteria></tc-locations-grid-setup-search-criteria>

<tc-grouping-setup-grid
        [coldefConfigLeft]="colDefConfigLeft"
        [coldefConfigRight]="colDefConfigRight"
        [rowDataLeft]="leftSideData"
        [rowDataRight]="rightSideData"
        [setupGridComp]="this"
        [autoSize]="true"
        [identityFunction]="identityFunction">
</tc-grouping-setup-grid>