import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonHelper } from '@tc-core/util/helpers';

@Component({
  selector: 'tc-room-add-card',
  templateUrl: './room-add-card.component.html'
})
export class RoomAddCardComponent implements OnInit {

  // data: any;
  @Input() roomTypeOptions: any[];
  @Input() selectedRooms: any[];

  @Output() closeModal: EventEmitter<any[]> = new EventEmitter();

  selectedRoomTypes = [];
  selectedRoomCodes = [];
  selected = [];

  constructor(
      private commonHelper: CommonHelper
  ) {
  }

  ngOnInit() {
    this.loadRoomTypes();
  }

  private loadRoomTypes() {
    if (this.selectedRooms) {
      for (let room of this.selectedRooms) {
        this.selectedRoomCodes.push(room.code);
        this.selected.push(room.code);
        this.selectedRoomTypes.push(room);
      }
    }
  }

  public addType(isSelected: any, roomType: any) {
    if (isSelected.checked) {
      this.selectedRoomCodes.push(roomType.code);
      this.selectedRoomTypes.push(roomType);
    }
    else {
      this.selectedRoomCodes.splice(this.selectedRoomCodes.indexOf(roomType.code), 1);
      this.selectedRoomTypes.forEach(d => {
        if (d.code === roomType.code) {
          this.selectedRoomTypes.splice(this.selectedRoomTypes.indexOf(d), 1);
        }
      });
    }
  }

  addRooms() {
    this.closeModal.emit(this.selectedRoomTypes);
  }

}
