import * as tslib_1 from "tslib";
import { AfterViewInit, EventEmitter, OnInit, TemplateRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TemplateRendererComponent } from '../../../../widgets/framework/ag-grid-custom-components/renderers/template-renderer/template-renderer.component';
import { BookingPassengerGroup } from '../passenger-editor.component';
import { CheckBoxRendererEditorComponent } from '../../../../widgets/framework/ag-grid-custom-components/renderers/check-box-selector-renderer/check-box-renderer-editor.component';
import { TCO } from '../../../../constants';
import { ConfigLoader } from '@tc-core/util/framework';
import { GridColumnDefinitionProcessorService } from '../../../../services/util/pre-processors/grid-column-definition-processor.service';
var BookingPassengerGroupComponent = /** @class */ (function () {
    function BookingPassengerGroupComponent(translateService, configLoader, columnDefinitionProcessorService) {
        var _this = this;
        this.translateService = translateService;
        this.configLoader = configLoader;
        this.columnDefinitionProcessorService = columnDefinitionProcessorService;
        this.newRowSelectEvent = new EventEmitter();
        this.gridOptions = {};
        this.frameworkComponents = {
            templateRenderer: TemplateRendererComponent,
            checkBoxRendererEditor: CheckBoxRendererEditorComponent
        };
        this.defaultColDef = {
            resizable: true,
            headerValueGetter: function (parameters) {
                var headerIdentifier = parameters.colDef.headerName;
                if (headerIdentifier) {
                    return _this.translateService.instant(headerIdentifier);
                }
                return '';
            }
        };
        this.translateService.onLangChange.subscribe(function () {
            _this.gridApi.refreshHeader();
        });
    }
    BookingPassengerGroupComponent.prototype.ngOnInit = function () {
        this.columnDefs = this.columnDefinitionProcessorService.createColumnDefs(this.configLoader.configurations.get(TCO.CONF.CONF_PASSENGERS_GRID), this);
    };
    BookingPassengerGroupComponent.prototype.onGridReady = function (params) {
        this.gridApi = params.api;
        this.gridApi.sizeColumnsToFit();
    };
    BookingPassengerGroupComponent.prototype.onFirstDataRendered = function (params) {
        this.gridApi = params.api;
        this.gridApi.sizeColumnsToFit();
    };
    BookingPassengerGroupComponent.prototype.onRowSelected = function (params) {
        this.newRowSelectEvent.emit(params);
    };
    BookingPassengerGroupComponent.prototype.getToolTip = function (row) {
        var e_1, _a;
        var text = '';
        if (row.passengerAttributes && row.passengerAttributes.length) {
            try {
                for (var _b = tslib_1.__values(row.passengerAttributes), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var passengerAttribute = _c.value;
                    text += passengerAttribute.name + '=' + passengerAttribute.value + '  ';
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_1) throw e_1.error; }
            }
        }
        return text;
    };
    BookingPassengerGroupComponent.prototype.getFullName = function (params) {
        if (params.data) {
            return params.data.firstName + ' ' + params.data.lastName;
        }
    };
    BookingPassengerGroupComponent.prototype.validatePaxAge = function (params) {
        if (params.data) {
            if (params.data.age > 0) {
                return params.data.age;
            }
            else {
                return '';
            }
        }
    };
    BookingPassengerGroupComponent.prototype.validateTelephoneNo = function (params) {
        if (params.data) {
            if (params.data.telephone != null) {
                return params.data.telephone;
            }
            else {
                return '';
            }
        }
    };
    BookingPassengerGroupComponent.prototype.ngAfterViewInit = function () {
    };
    return BookingPassengerGroupComponent;
}());
export { BookingPassengerGroupComponent };
