<!--<div class="tc-py-4">-->
    <mat-drawer-container class="tc-mq">
        <mat-drawer class="tc-mq-sidebar"   mode="side" opened>
            <div #mq_sidebar_header class="tc-mq-sidebar__header">
                <div class="tc-mq-sidebar__search">
                    <form class="tc-w-100">
                        <mat-form-field class="tc-py-2 tc-mb-1" [floatLabel]="'never'">
<!--                            <mat-label>Search</mat-label>-->
                            <mat-icon matPrefix>search</mat-icon>
                            <input matInput type="text" placeholder="{{'Resources' | translate}}" [formControl]="nameCtrl"
                                   [matAutocomplete]="auto">
                            <mat-autocomplete #auto="matAutocomplete">
                                <mat-option *ngFor="let newMsgRecipient of filteredData | async"
                                            [value]="newMsgRecipient" (onSelectionChange)="pushToChip($event)">
                                    <div class="tc-flex-box">
                                        <div class="tc-flex-item">
                                            <img [src]="newMsgRecipient?.icon" [alt]="newMsgRecipient?.firstName" style="height: 40px;width: 40px;border-radius: 50%">
                                            <b>  {{ newMsgRecipient?.firstName }} {{ newMsgRecipient?.lastName }}</b>
                                            <small> ({{newMsgRecipient.resourceTypeName}})</small>
                                        </div>
                                    </div>
                                </mat-option>
                                <mat-option *ngIf="searchErr"><b>{{'Resource not available' | translate}}</b></mat-option>
                                <mat-option *ngIf="searchingInit"><b>{{'Searching...' | translate}}</b></mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                        <div class="tc-flex tc-flex--space-between tc-mb-2" *ngIf="selectedChats.length>0">
                            <mat-chip-list #chipList>
                                    <mat-chip
                                            *ngFor="let chat of selectedChats; index as i"
                                            [selectable]="true"
                                            [removable]="true"
                                            (removed)="removeChatChip(i)">
                                        {{ chat?.name }}
                                        <mat-icon matChipRemove >cancel</mat-icon>
                                    </mat-chip>
                            </mat-chip-list>

                            <div class="tc-icon-block">
                                <button mat-button class="tc-m-0 tc-icon-block__icon tc-icon-block__icon--active" matTooltip="{{'Create Group' | translate}}"
                                        matSuffix mat-icon-button aria-label="Send" (click)="addNewChat()">
                                    <mat-icon>group_add</mat-icon>
                                </button>
                            </div>
                        </div>

                    </form>
                </div>
<!--                <hr>-->
                <div mat-subheader>{{'CHATS' | translate}}</div>
                <hr>
            </div>
            <div class="tc-mq-sidebar__list">
                <tc-message-queue-search-results
                        [messageThreads]="messageThreadList"
                        (selectedThread)="selectThread($event)">
                </tc-message-queue-search-results>
            </div>
        </mat-drawer>
        <mat-drawer-content class="tc-mq-content">
            <tc-resource-message-panel *ngIf="selectedThread && (selectedThread.supplierId || selectedThread.resourceId || selectedThread.groupMsgThreadId)"
                    [selectedChat]="selectedThread"
                    [group]="group"
                    [isBroadCast]="isBroadCast"
                    [messagesPresent]= true
                    [groupMsgThreadId]= groupMsgThreadId
                    [selectedNotificationId]="selectedNotificationId">
            </tc-resource-message-panel>
            <tc-resource-message-panel *ngIf="!selectedThread || (!selectedThread.supplierId && !selectedThread.resourceId && !selectedThread.groupMsgThreadId)"
                                       [messagesPresent]=false>
            </tc-resource-message-panel>
        </mat-drawer-content>
    </mat-drawer-container>
<!--</div>-->
<!--<br>-->
<!--<button (click)="receiveDummy()">Receive dummy</button>-->
<!--<button (click)="sendDummy()">send dummy</button>-->
<!--<button (click)="getUser()">send dummy</button>-->
<!--<button (click)="sendBroadCast()">send broadCast</button>-->

