import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSliderChange } from '@angular/material';
import { ConfigLoader } from '@tc-core/util/framework/config-loader.service';
import { TCO } from '../../../constants';
import { GenderOptions, PersonalInformation } from '../../../models/resource/personal-information';
import { Resource } from '../../../models/resource/resource';
import { SupplierSummaryDepricated } from '../../../models/summary/supplier-summary-depricated';
import { Grade } from '../../../models/supplier/supplier';
import { UserBusinessType } from '../../../models/user/user-business-type';
import { UserProfile } from '../../../models/user/user-profile';
import { UserServiceProfile } from '../../../models/user/user-service-profile';
import { RegXPatterns } from '../regx-patterns/regx-patterns';

@Component({
    selector: 'tc-personal-information',
    templateUrl: './personal-information.component.html'
})

export class PersonalInformationComponent implements OnInit, OnChanges
{

    @Input() personalInfo: PersonalInformation;
    @Input() resource: Resource;
    @Input() assignedSupplier: any;
    @Input() filteredSuppliers: SupplierSummaryDepricated[];
    @Input() filteredUsers: UserProfile[];
    @Input() resourceTypeOptions: any[];
    @Input() resourceTypePathName: string;
    @Input() resourceType: any;
    @Input() titles: any[];
    @Input() supplierTypeOptions: any[];
    @Input() isResourceTypeReadOnly = false;
    @Input() missingCostForSupplier = false;

    @Output() changeFormValidity: EventEmitter<boolean> = new EventEmitter();
    @Output() supplierAssign: EventEmitter<SupplierSummaryDepricated> = new EventEmitter();
    @Output() userAssign: EventEmitter<UserProfile> = new EventEmitter();
    @Output() supplierClear: EventEmitter<any> = new EventEmitter();
    @Output() userClear: EventEmitter<any> = new EventEmitter();
    @Output() supplierTextChange: EventEmitter<string> = new EventEmitter();
    @Output() userTextChange: EventEmitter<string> = new EventEmitter();
    @Output() resourceTypeChange: EventEmitter<string> = new EventEmitter();

    personalInfoForm: FormGroup;
    genderOptions: any;
    maxDate: Date = new Date();
    businessTypes = UserBusinessType;

    grades: Grade[];
    maxPreference: number;
    maxGrade = 10;
    gradeNgModel = 0;

    constructor(
        private fb: FormBuilder,
        private configLoader: ConfigLoader,
    ) {}

    ngOnInit()
    {
        const gradeConfig = this.configLoader.configurations.get(TCO.CONF.CONF_GRADES_CONFIG);
        if (gradeConfig && gradeConfig.grade_values) {
            this.grades = gradeConfig.grade_values.sort((a, b) => (a.order > b.order ? 1 : b.order > a.order ? -1 : 0));

        }
        if (gradeConfig && gradeConfig.max_grade) {
            this.maxGrade = gradeConfig.max_grade ? gradeConfig.max_grade : 10;
        }

        if (this.configLoader && this.configLoader.configurations) {
            this.maxPreference = this.configLoader.configurations.get(TCO.CONF.CONF_PREFERENCE_CONFIG).MAX_PREFERENCE as number;
        }

        this.createForm();
        this.personalInfoForm.valueChanges.subscribe(
            (data) => {
                if (JSON.stringify(data) !== JSON.stringify({})) {
                    this.personalInfo.resourceType = data.resourceType;
                    this.personalInfo.title = data.title;
                    this.personalInfo.fName = data.fName;
                    this.personalInfo.lName = data.lName;
                    this.personalInfo.gender = data.gender;
                    this.personalInfo.dateOfBirth = data.dateOfBirth;
                    this.personalInfo.supplierType = data.supplierType;
                    this.personalInfo.businessType = data.businessType;
                    this.resource.preference = data.preference;
                    this.resource.grade = data.grade;
                    this.resource.capacity = parseInt(data.capacity);
                    this.getConfigGradeValue(data.grade);

                    if (data.supplier !== undefined && typeof (data.supplier) !== 'object') {
                        this.filteredSuppliers = [];
                        this.supplierTextChange.emit(data.supplier);
                    }

                    if (data.linkedUser !== undefined && typeof (data.linkedUser) !== 'object') {
                        this.filteredUsers = [];
                        this.userTextChange.emit(data.linkedUser);
                    }

                    if (this.isResourceTypeReadOnly) {
                        this.personalInfo.resourceType = this.resourceType;
                    }

                    this.changeFormValidity.emit(this.personalInfoForm.valid);
                }
            }
        );

        this.genderOptions = GenderOptions.TYPE;
        this.validateForm();
    }

    public validateForm() {
        Object.keys(this.personalInfoForm.controls).forEach(field => {
            const control = this.personalInfoForm.get(field);
            control.markAsTouched({onlySelf: true});
        });
    }

    private createForm() {
        if (this.isResourceTypeReadOnly) {
            this.personalInfo.resourceType = this.resourceType;
        }
        this.personalInfoForm = this.fb.group({
            resourceType: new FormControl(
                {value: this.personalInfo ? this.personalInfo.resourceType : '', disabled: this.isResourceTypeReadOnly},
                Validators.required
            ),
            title: [this.personalInfo ? this.personalInfo.title : '', Validators.required],
            fName: [
                this.personalInfo ? this.personalInfo.fName : '',
                [Validators.required, Validators.pattern(RegXPatterns.LETTERS_ONLY_NAME_MAX30)]
            ],
            lName: [
                this.personalInfo ? this.personalInfo.lName : '',
                [Validators.required, Validators.pattern(RegXPatterns.LETTERS_ONLY_NAME_MAX30)]
            ],
            linkedUser: [this.resource.userProfile ? this.resource.userProfile : ''],
            dateOfBirth: [this.personalInfo ? this.personalInfo.dateOfBirth : ''],
            gender: [this.personalInfo ? this.personalInfo.gender : '', Validators.required],
            supplierType: [this.personalInfo ? this.personalInfo.supplierType : ''],
            businessType: [this.personalInfo ? this.personalInfo.businessType : '', Validators.required],
            supplier: [this.assignedSupplier ? this.assignedSupplier : '', Validators.required],
            preference: [
                this.resource ? this.resource.preference : '',
                [Validators.pattern(RegXPatterns.POSITIVE_NUMBERS), Validators.max(this.maxPreference), Validators.min(1)]
            ],
            grade: [
                this.resource && this.resource.grade ? this.resource.grade : 0,
                [Validators.pattern(RegXPatterns.POSITIVE_NUMBERS), Validators.max(this.maxGrade)]
            ],
            capacity: [
                this.resource ? this.resource.capacity : '',
                [Validators.required, Validators.pattern(RegXPatterns.POSITIVE_NUMBERS_3DIGIT)]
            ],
        }, {validator: this.checkDate});

        this.changeFormValidity.emit(this.personalInfoForm.valid);
    }

    private checkDate(group: FormGroup) {
        let dob = group.controls['dateOfBirth'];
        if (dob && dob.value && dob.value > new Date()) {
            dob.setErrors({notValid: true});
        } else {
            dob.setErrors(null);
        }
        return null;
    }

    public toggleActiveStatus() {
        this.personalInfo.activeStatus = !this.personalInfo.activeStatus;
        this.changeFormValidity.emit(this.personalInfoForm.valid);
    }

    public onSupplierFocus($event: FocusEvent) {
        this.filteredSuppliers = [];
    }

    public clearSupplier() {
        this.personalInfoForm.get('supplier').setValue('');
        this.supplierClear.emit();
    }

    displaySupplierFn(supplier: SupplierSummaryDepricated): any {
        return supplier ? supplier.name : supplier;
    }

    public onSelectSupplier(supplier: SupplierSummaryDepricated) {
        this.supplierAssign.emit(supplier);
    }

    public onUserFocus($event: FocusEvent) {
        this.filteredUsers = [];
    }

    public clearUser() {
        this.personalInfoForm.get('linkedUser').setValue('');
        this.userClear.emit();
    }

    public onSelectUser(user: UserProfile) {
        this.userAssign.emit(user);
    }

    public getUserName(user: UserProfile) {
        return user ? (user.firstName + ' ' + user.lastName) : null;
    }

    displayUserFn(user: UserServiceProfile): any {
        return user ? (user.firstName + ' ' + user.lastName) : null;
    }

    public getErrorMessage(field: string): string {
        switch (field) {
            case 'resourceType' :
                return this.personalInfoForm.get('resourceType').hasError('required') ? 'Resource Type required' : '';
            case 'title' :
                return this.personalInfoForm.get('title').hasError('required') ? 'Title required' : '';
            case 'fName' :
                return this.personalInfoForm.get('fName').hasError('required') ? 'First name required' :
                    this.personalInfoForm.get('fName').hasError('pattern') ? 'First name invalid' : '';
            case 'lName' :
                return this.personalInfoForm.get('lName').hasError('required') ? 'Last name required' :
                    this.personalInfoForm.get('lName').hasError('pattern') ? 'Last name invalid' : '';
            case 'licenseNumber' :
                return this.personalInfoForm.get('licenseNumber').hasError('required') ? 'License number required' : '';
            case 'gender' :
                return this.personalInfoForm.get('gender').hasError('required') ? 'Gender required' : '';
            case 'businessType' :
                return this.personalInfoForm.get('businessType').hasError('required') ? 'Business Type required' : '';
            case 'supplier' :
                return this.personalInfoForm.get('supplier').hasError('required') ? 'Supplier required' : '';
            case 'dateOfBirth' :
                return this.personalInfoForm.get('dateOfBirth').hasError('notValid') ? 'Date of Birth invalid' : '';
            case 'preference' :
                return this.personalInfoForm.get('preference').hasError('pattern') ? 'Preference invalid' :
                    this.personalInfoForm.get('preference').hasError('max') ? 'Preference invalid' :
                        this.personalInfoForm.get('preference').hasError('min') ? 'Preference invalid' : '';
            case 'grade' :
                return this.personalInfoForm.get('grade').hasError('pattern') ? 'Grade invalid' :
                    this.personalInfoForm.get('grade').hasError('max') ? 'Grade invalid' : '';
            case 'capacity' :
                return this.personalInfoForm.get('capacity').hasError('required') ? 'Capacity Required' :
                    this.personalInfoForm.get('capacity').hasError('pattern') ? 'Invalid Capacity' : '';

            default :
                return '';
        }
    }

    public ngOnChanges(changes: SimpleChanges): void {
        for (let propName in changes) {
            if (propName === 'isResourceTypeReadOnly') {
                if (this.personalInfoForm) {
                    if (this.isResourceTypeReadOnly) {
                        this.personalInfoForm.get('resourceType').disable({onlySelf: true});
                    } else {
                        this.personalInfoForm.get('resourceType').enable();
                    }
                }
            }
        }
    }

    public onResourceTypeChange($event: any) {
        this.resourceTypeChange.emit($event);
    }

    gradeSelectionOnChange($event: any) {
        let localGrade = 0;
        if (this.maxGrade && this.grades && $event.value !== 0) {
            localGrade = Math.floor(($event.value / this.grades.length) * this.maxGrade);
            this.gradeNgModel = $event.value;
        }
        this.personalInfoForm.get('grade').setValue(localGrade);
    }

    getConfigGradeValue(grade: number) {
        this.gradeNgModel = Math.ceil((grade / this.maxGrade) * this.grades.length);
    }
}
