import { Component } from '@angular/core';
import { TCO } from '../../../../constants';
import { DocumentSearchCriteria } from '../../../../models/criteria/document-search-criteria';
import { MainType } from '../../../../models/reservation-v2/main-type';
import { DataKey } from '../../../../services/util/framework/data-store.service';
import {UserManagementService} from '../../../../services/user-management/user-management.service';

@Component({
    selector: 'tc-manifests-search-criteria',
    templateUrl: './manifests-search-criteria.component.html'
})
export class ManifestsSearchCriteriaComponent
{
    inputSearchCriteria = new DocumentSearchCriteria(true, false);
    searchCriteriaUpdatingDataKey = DataKey.documentSearchCriteria;
    searchResultsDataKey = DataKey.documentSearchResultsForCriteria;
    searchCriteriaLoadingDataKey = TCO.AppData.MANIFESTS_SEARCH_CRITERIA;
    chipConfigKey = TCO.CONF.CONF_MANIFESTS_SEARCH_CRITERIA_CHIP;
    placeHolderText = 'Search Manifests';

    constructor(
        private userManagementService: UserManagementService
    ) {
    }

    createNewSearchCriteriaFn = () => {

        const criteria = new DocumentSearchCriteria(true, false);
        criteria.startDate = new Date().toISOString().split('T')[0];
        criteria.endDate = new Date(+new Date() + 172800000).toISOString().split('T')[0];
        criteria.mainType = MainType.TRS;
        criteria.dispatchStatus = 'SUCCESS';
        criteria.generatedBy = 'ANY';
        criteria.documentFormat = 'ANY';
        return criteria;
    };

    clearLoadedSearchCriteriaFn = (searchCriteria: DocumentSearchCriteria) => {
        searchCriteria.documentType = [];
        searchCriteria.reportsOnly = false;
        searchCriteria.manifestsOnly = true;
    };
}
