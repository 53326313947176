import { AbstractSearchCriteria } from './abstract-search-criteria';

export class CostingTypeSearchCriteria extends AbstractSearchCriteria {
    code: string = '';
    name: string = '';
    category: string = '';
}


export enum CostingTypeCategory {
    UNIT = 'UNIT',
    PERSON = 'PERSON',
    DISTANCE = 'DISTANCE (Km)',
    DURATION = 'DURATION (h)',
    DAY = 'DAY'
}
