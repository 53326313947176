import { Component, Input, OnInit } from '@angular/core';
import { DialogModel, LEVEL } from '@tc-core/model/it/codegen/ui/framework/dialog-model';
import { ModalData } from '@tc-core/model/it/codegen/ui/framework/modal-data';
import { PageSection } from '@tc-core/model/it/codegen/ui/framework/page-section';
import { SummaryCardData } from '@tc-core/model/it/codegen/ui/framework/summary-card-data';
import { TC } from '@tc-core/util/constants';
import { ConfigLoader } from '@tc-core/util/framework/config-loader.service';
import { EventManager } from '@tc-core/util/framework/event-manager.service';
import { UserJourneyManager } from '@tc-core/util/framework/user-journey-manager.service';
import { SpinnerService } from '@tc-core/util/ui/spinner.service';
import { ModalService } from '@tc/modal/modal.service';
import { DateRange } from '../../../../widgets/framework/cg-calendar/date-range';
import { RangeGroup } from '../../../../widgets/framework/cg-calendar/range-group';
import { DialogService } from '@tc/dialog/dialog.service';
import { FocusViewService } from '@tc/focus-view/focus-view.service';
import { TCO } from '../../../../constants';
import { SeasonSetupSummaryComponent } from '../../../../widgets/framework/cg-season-calendar/season-setup-summary/season-setup-summary.component';
import { Season } from '../../../../models/supplier/season';
import { SeasonDateRange } from '../../../../models/supplier/season-date-range';
import { TrsResourceCostSetup, TrsResourceCost } from '../../../../models/supplier/trs-resource-cost-setup';
import { DataHandlerService } from '../../../../services/backend-consumers/master-data-handler-service/data-handler.service';
import { DataKey, DataStoreService } from '../../../../services/util/framework/data-store.service';
import { CardDataHandlerService } from '../../../../services/util/ui/card-data-handler-service.service';
import { ContentSummaryCardService } from '../../../../services/util/ui/content-summary-card.service';

@Component({
    selector: 'tc-transport-cost-setup-focus-view',
    templateUrl: './transport-cost-setup-focus-view.component.html'
})
export class TransportCostSetupFocusViewComponent implements OnInit {
    @Input() supplierCostSetup: Season[] = [];
    @Input() resourceCosts: TrsResourceCostSetup[] = [];
    @Input() vehicleModels: any[] = [];
    heading: string = '';
    routes: any[] = [];
    transferModes: any[] = [];
    seasons: any[] = [];
    tResourceCosts: any;

    addNew: boolean = false;
    addNewEditableStatus: boolean = false;
    isEdit: boolean = true;
    daysOfWeek: any[] = [];
    resourceCostCardContent: SummaryCardData[] = [];
    trsResourceCosts: TrsResourceCost[] = [];
    selectedResourceCost: any;
    transferModeList: any[] = [];
    routeList: any[] = [];
    filterRoute: string;
    filterSeason: number = 0;
    filterTransferMode: string;

    //calendar
    fromYear = 0;
    toYear = 0;
    rangeGroupsForSeasonCalendar: RangeGroup[] = [];
    public costAddSection: PageSection;
    private pageSectionsMap: Map<string, PageSection>;
    private CARD_CONFIG_TRANSPORT_EDIT = 'supplier_edit';

    constructor(
        private em: EventManager,
        private configLoader: ConfigLoader,
        private journeyManager: UserJourneyManager,
        private dataStore: DataStoreService,
        private focusViewService: FocusViewService,
        private summaryCardService: ContentSummaryCardService,
        private dataHandlerService: DataHandlerService,
        private spinnerService: SpinnerService,
        private dialogService: DialogService,
        private cardDataHandlerService: CardDataHandlerService,
        private modalService: ModalService
    ) { }

    ngOnInit() {
        this.initPageSectionReferences();
        this.mapToSeasonCalendarRanges();
        this.tResourceCosts = this.configLoader.configurations.get(TCO.CONF.CONF_TRANSFER_RESOURCE_COST);

        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'CLOSE_FOCUS_VIEW').subscribe(e => {

            this.dataStore.set(DataKey.supplierCostFocusViewClose, null);
            this.dataStore.set(DataKey.supplierCostFocusViewClose, true);
            this.saveResourceCostData();
            this.focusViewService.close(true);

            this.journeyManager.goForKey('GO_TO_ADD_TRANSFER_SUPPLIER');
        });
        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'ADD_NEW_COST_SETUP').subscribe(e => {
            this.addNewCode();
        });

        this.dataStore.get(DataKey.routes).subscribe(data => {
            if (data !== null) {
                this.routeList = data;
                this.routes.push({'code': '', 'value': 'All Routes', 'isSelected': true});
                data.forEach(val => {
                    this.routes.push({'code': val, 'value': val.code, 'isSelected': false});
                });
            } else {
                this.routes = [];
            }
        });
        const x = this.dataStore.get(DataKey.transferModes).subscribe((data) => {
            if (data !== null) {
                this.transferModeList = data;
                this.transferModes.push({'code': 'any', 'value': 'All Transfer Modes', 'isSelected': true});
                data.forEach(val => {
                    this.transferModes.push({'code': val, 'value': val.name, 'isSelected': false});
                });

            } else {
                this.transferModes = [];
            }
        });
        if (this.supplierCostSetup !== null && this.supplierCostSetup.length > 0) {
            this.seasons.push({'code': 0, 'value': 'Any Season', 'isSelected': true});
            for (let i = 0; i < this.supplierCostSetup.length; i++) {
                this.seasons.push({
                    'code': this.supplierCostSetup[i].tempSeasonId,
                    'value': this.supplierCostSetup[i].name,
                    'isSelected': false
                });
            }
        }

        this.dataHandlerService.retrieveDaysOfWeek().subscribe(days => {
            if (days !== null) {
                this.daysOfWeek = days.data;
                if (this.resourceCosts.length === 0) {
                    this.addNewCode();
                }
            }
        });

        this.resourceCostCardContent = this.getCostSummaryCards();
    }
    private initPageSectionReferences() {
        this.pageSectionsMap = this.cardDataHandlerService.getAllPageSections(
            this.CARD_CONFIG_TRANSPORT_EDIT,
            this.resourceCosts
        );

        this.costAddSection = this.pageSectionsMap.get('supplier_transport_cost_setup');

        // this.setupHeader();
        // this.filterPageSectionActions();
    }

    private mapToSeasonCalendarRanges() {
        // this.rangeGroupsForSeasonCalendar = [];
        for (let i = 0; i < this.supplierCostSetup.length; i++) {
            let season: Season = this.supplierCostSetup[i];
            let rangeGroup = new RangeGroup();
            rangeGroup.id = season.tempSeasonId;
            rangeGroup.name = season.name;
            rangeGroup.colorId = season.colorId;

            let dateRanges: DateRange[] = [];
            for (let j = 0; j < season.seasonDateRanges.length; j++) {

                let seasonDateRange: SeasonDateRange = season.seasonDateRanges[j];
                let dateRange = new DateRange();
                dateRange.id = seasonDateRange.dateRangeId;
                dateRange.startDate = new Date(seasonDateRange.startDate);
                dateRange.endDate = new Date(seasonDateRange.endDate);
                dateRanges.push(dateRange);
            }
            rangeGroup.dateRanges = dateRanges;
            this.rangeGroupsForSeasonCalendar.push(rangeGroup);
        }
    }

    public onChangeCostSetupForm(event) {
        this.dataStore.set(DataKey.supplierTrsCostSetupValid, event, true);
    }

    saveResourceCostData() {
        this.dataStore.set(DataKey.trsResourceCosts, null, true);
        this.dataStore.set(DataKey.trsResourceCosts, this.resourceCosts, true);
    }

    getDays(data: string) {
        let days = '';
        for (let i = 0; i < data.length; i++) {
            let dayArray = this.daysOfWeek.find(day => day.key === data.charAt(i)).value;
            days = days.concat(',' + dayArray);
        }
        if (days.startsWith(',')) {days = days.replace(',', '');}
        return days;
    }

    getCostSummaryCards() {
        let summaryCards = this.summaryCardService.getContentCardsByConfig(
            this.tResourceCosts,
            this.resourceCosts
        );
        this.spinnerService.hide();
        return summaryCards;
    }

    onSelectTransferMode(event: any) {
        if (event.code !== 'any') {
            this.filterTransferMode = event.code;
        } else {this.filterTransferMode = null;}
    }

    onSelectRoute(event: any) {
        if (event.code !== 'any') {
            this.filterRoute = event.code;
        } else {this.filterRoute = null;}
    }

    onSelectSeason(event: any) {
        if (event !== 0) {
            this.filterSeason = event;
        } else {this.filterSeason = 0;}
    }

    addNewCode() {
        window.scrollTo(0, 0);
        if (this.seasons.length > 0) {
            this.addNew = true;
            let resourceCost = new TrsResourceCostSetup();
            resourceCost.tempCostId = this.getNextTempId();
            resourceCost.isEdit = true;
            resourceCost.isNew = true;
            this.resourceCosts.unshift(resourceCost);
            // this.resourceCosts.splice(0, 0, resourceCost);
            this.resourceCostCardContent.unshift(new SummaryCardData());
        } else {
            let confirmError = new DialogModel(
                true,
                LEVEL.ERROR,
                'Failed',
                'Please add seasons',
                true,
                2000
            );
            this.dialogService
                .confirm(confirmError)
                .subscribe((res) => {
                    if (res === true) {
                        console.log('true');
                    }
                });
        }
    }

    private getNextTempId(): number {
        let maxId = 0;
        for (let j = 0; j < this.resourceCosts.length; j++) {
            let resourceCost = this.resourceCosts[j];
            let tempId = resourceCost.tempCostId;
            if (tempId > maxId) {
                maxId = tempId;
            }
        }
        maxId++;
        return maxId;
    }

    onNewCostSaveClick(event) {
        this.resourceCosts.forEach(data => {
            if (data.tempCostId == event.tempCostId) {
                data.isEdit = false;
                data.seasonName = this.supplierCostSetup.find(data => data.tempSeasonId === event.tempSeasonId).name;
                event.dayKeys = event.dayKeys.split('').sort((a, b) => a < b ? -1 : 1).join('');
                data.dayValues = this.getDays(event.dayKeys);
            }
        });
        this.resourceCostCardContent = this.getCostSummaryCards();
    }

    onDeleteClick(event) {
        for (let i = 0; i < this.resourceCosts.length; i++) {
            if (this.resourceCosts[i].tempCostId === event) {
                this.resourceCosts.splice(i, 1);
                this.resourceCostCardContent.splice(i, 1);
                break;
            }
        }
        // this.resourceCosts.splice(this.resourceCosts.findIndex(event), 1);
        // this.trsResourceCosts.splice(this.trsResourceCosts.findIndex(event), 1);
    }

    onEditClick(event) {
        this.isEdit = true;
        this.selectedResourceCost = event;
        this.resourceCosts.find(data => data.tempCostId == event.tempCostId).isEdit = true;
        this.resourceCosts.find(data => data.tempCostId == event.tempCostId).isNew = false;
    }

    onRevertClick(event) {
        this.selectedResourceCost = event;
        this.resourceCosts.forEach(data => {
            if (data.tempCostId == event.tempCostId) {
                data.adultFare = event.adultFare;
                data.dayKeys = event.dayKeys;
                data.childFare = event.childFare;
                data.infantFare = event.infantFare;
                data.unitFare = event.unitFare;
                data.route = event.route;
                data.transferMode = event.transferMode;
                data.isEdit = false;
                data.tempSeasonId = event.tempSeasonId;
                data.seasonName = this.supplierCostSetup.find(data => data.tempSeasonId === event.tempSeasonId).name;
                event.dayKeys = event.dayKeys.split('').sort((a, b) => a < b ? -1 : 1).join('');
                data.dayValues = this.getDays(event.dayKeys);
            }
        });
        this.resourceCostCardContent = this.getCostSummaryCards();
    }



    showSeasonSummary(event) {
        const dataObject = new ModalData(
            LEVEL.SUCCESS,
            'Season Setup Summary',
            null,
            {'label': 'CLOSE'},
            'modal-basic--fixed-size modal-basic--show-footer',
            SeasonSetupSummaryComponent,
            {rangeGroups: this.rangeGroupsForSeasonCalendar}
        );
        this.modalService
            .confirm(dataObject).subscribe(res => {
            if (res) {
            }
        });
    }

}
