import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTabsModule } from '@angular/material';
import { EditTimeRangeModule } from '../resource-allocation-panel/edit-time-range/edit-time-range.module';
import { ResourceAllocationPanelModule } from '../resource-allocation-panel/resource-allocation-panel.module';
import { SupplierAllocationPanelModule } from '../supplier-allocation-panel/supplier-allocation-panel.module';
import { AllocationFocusViewComponent } from './allocation-focus-view.component';

@NgModule({
  declarations: [AllocationFocusViewComponent],
    imports: [
        CommonModule,
        SupplierAllocationPanelModule,
        ResourceAllocationPanelModule,
        MatTabsModule,
        EditTimeRangeModule
    ]
})
export class AllocationFocusViewModule { }
