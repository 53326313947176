import * as tslib_1 from "tslib";
import { AssignmentStatus } from '../../../models/reservation/assignment-status';
import { ServiceAssignment } from '../../../models/reservation/service-assignment';
var ServiceAssignmentUtilService = /** @class */ (function () {
    function ServiceAssignmentUtilService() {
    }
    // todo check does this return all assigned state and is that what required
    ServiceAssignmentUtilService.isAssignedAny = function (assignments) {
        var e_1, _a;
        try {
            for (var assignments_1 = tslib_1.__values(assignments), assignments_1_1 = assignments_1.next(); !assignments_1_1.done; assignments_1_1 = assignments_1.next()) {
                var assignment = assignments_1_1.value;
                if (assignment.resourceId > 0 || assignment.supplierId > 0) {
                    return true;
                }
                else {
                    continue;
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (assignments_1_1 && !assignments_1_1.done && (_a = assignments_1.return)) _a.call(assignments_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return false;
    };
    ServiceAssignmentUtilService.isSupplierConfirmedOrDmcConfirmedAll = function (assignments) {
        var e_2, _a;
        var state = true;
        try {
            for (var assignments_2 = tslib_1.__values(assignments), assignments_2_1 = assignments_2.next(); !assignments_2_1.done; assignments_2_1 = assignments_2.next()) {
                var assignment = assignments_2_1.value;
                if (!(assignment.assignStatus === AssignmentStatus.SUPPLIER_CONFIRMED || assignment.assignStatus ===
                    AssignmentStatus.DMC_CONFIRM)) {
                    state = false;
                }
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (assignments_2_1 && !assignments_2_1.done && (_a = assignments_2.return)) _a.call(assignments_2);
            }
            finally { if (e_2) throw e_2.error; }
        }
        return state;
    };
    ServiceAssignmentUtilService.isSupplierConfirmedAny = function (assignments) {
        var e_3, _a;
        try {
            for (var assignments_3 = tslib_1.__values(assignments), assignments_3_1 = assignments_3.next(); !assignments_3_1.done; assignments_3_1 = assignments_3.next()) {
                var assignment = assignments_3_1.value;
                if (assignment.assignStatus === AssignmentStatus.SUPPLIER_CONFIRMED) {
                    return true;
                }
                else {
                    continue;
                }
            }
        }
        catch (e_3_1) { e_3 = { error: e_3_1 }; }
        finally {
            try {
                if (assignments_3_1 && !assignments_3_1.done && (_a = assignments_3.return)) _a.call(assignments_3);
            }
            finally { if (e_3) throw e_3.error; }
        }
        return false;
    };
    ServiceAssignmentUtilService.isAssignedAnyTrs = function (assignments) {
        var e_4, _a;
        try {
            for (var assignments_4 = tslib_1.__values(assignments), assignments_4_1 = assignments_4.next(); !assignments_4_1.done; assignments_4_1 = assignments_4.next()) {
                var assignment = assignments_4_1.value;
                if (ServiceAssignment.isAssigned(assignment) && ServiceAssignment.isTrsAssign(assignment)) {
                    return true;
                }
            }
        }
        catch (e_4_1) { e_4 = { error: e_4_1 }; }
        finally {
            try {
                if (assignments_4_1 && !assignments_4_1.done && (_a = assignments_4.return)) _a.call(assignments_4);
            }
            finally { if (e_4) throw e_4.error; }
        }
        return false;
    };
    ServiceAssignmentUtilService.isAssignedAll = function (assignments) {
        var e_5, _a;
        var allAreAssigned = true;
        if (assignments) {
            try {
                for (var assignments_5 = tslib_1.__values(assignments), assignments_5_1 = assignments_5.next(); !assignments_5_1.done; assignments_5_1 = assignments_5.next()) {
                    var assignment = assignments_5_1.value;
                    allAreAssigned = assignment && ServiceAssignment.isAssigned(assignment);
                    if (!allAreAssigned) {
                        break;
                    }
                }
            }
            catch (e_5_1) { e_5 = { error: e_5_1 }; }
            finally {
                try {
                    if (assignments_5_1 && !assignments_5_1.done && (_a = assignments_5.return)) _a.call(assignments_5);
                }
                finally { if (e_5) throw e_5.error; }
            }
            return allAreAssigned;
        }
        return allAreAssigned;
    };
    ServiceAssignmentUtilService.isRestrictedAny = function (assignments) {
        var e_6, _a;
        var anyoneRestricted = false;
        if (assignments) {
            try {
                for (var assignments_6 = tslib_1.__values(assignments), assignments_6_1 = assignments_6.next(); !assignments_6_1.done; assignments_6_1 = assignments_6.next()) {
                    var assignment = assignments_6_1.value;
                    anyoneRestricted = assignment && ServiceAssignment.isRestrictedEdit(assignment);
                    if (anyoneRestricted) {
                        break;
                    }
                }
            }
            catch (e_6_1) { e_6 = { error: e_6_1 }; }
            finally {
                try {
                    if (assignments_6_1 && !assignments_6_1.done && (_a = assignments_6.return)) _a.call(assignments_6);
                }
                finally { if (e_6) throw e_6.error; }
            }
            return anyoneRestricted;
        }
        return anyoneRestricted;
    };
    ServiceAssignmentUtilService.getTrsAllocatedPassengersCount = function (serviceItem) {
        var allocatedPassengerCount = 0;
        if (serviceItem && serviceItem.serviceAssignments) {
            if (ServiceAssignmentUtilService.isAssignedAnyTrs(serviceItem.serviceAssignments)) {
                if (serviceItem.servicePassengers) {
                    allocatedPassengerCount += serviceItem.servicePassengers.length;
                }
            }
        }
        return allocatedPassengerCount;
    };
    ServiceAssignmentUtilService.getGroupStatus = function (serviceItems) {
        /*
         Status code priorities:

         SUPPLIER_REJECTED   : 1 or more has been rejected
         PENDING             : no any rejected & 1 or more has been pending
         CONFIRM             : all has been confirmed
         SUPPLIER_CONFIRM    : all has been supplier confirmed

         */
        var e_7, _a, e_8, _b;
        var statusCounts = new Map();
        var totalAssignments = 0;
        try {
            for (var serviceItems_1 = tslib_1.__values(serviceItems), serviceItems_1_1 = serviceItems_1.next(); !serviceItems_1_1.done; serviceItems_1_1 = serviceItems_1.next()) {
                var item = serviceItems_1_1.value;
                if (!item.serviceAssignments || item.serviceAssignments.length === 0) {
                    ServiceAssignmentUtilService.updateStatusCountMap(statusCounts, AssignmentStatus.PENDING);
                }
                else {
                    totalAssignments += item.serviceAssignments.length;
                    try {
                        for (var _c = tslib_1.__values(item.serviceAssignments), _d = _c.next(); !_d.done; _d = _c.next()) {
                            var assignment = _d.value;
                            ServiceAssignmentUtilService.updateStatusCountMap(statusCounts, assignment.assignStatus);
                        }
                    }
                    catch (e_8_1) { e_8 = { error: e_8_1 }; }
                    finally {
                        try {
                            if (_d && !_d.done && (_b = _c.return)) _b.call(_c);
                        }
                        finally { if (e_8) throw e_8.error; }
                    }
                }
            }
        }
        catch (e_7_1) { e_7 = { error: e_7_1 }; }
        finally {
            try {
                if (serviceItems_1_1 && !serviceItems_1_1.done && (_a = serviceItems_1.return)) _a.call(serviceItems_1);
            }
            finally { if (e_7) throw e_7.error; }
        }
        if (statusCounts.has(AssignmentStatus.SUPPLIER_REJECTED) &&
            statusCounts.get(AssignmentStatus.SUPPLIER_REJECTED) > 0) {
            return AssignmentStatus.SUPPLIER_REJECTED;
        }
        else if (statusCounts.has(AssignmentStatus.BOOKING_UPDATE) && statusCounts.get(AssignmentStatus.BOOKING_UPDATE) > 0) {
            return AssignmentStatus.BOOKING_UPDATE;
        }
        else if (statusCounts.has(AssignmentStatus.REQUEST_EXPIRED) && statusCounts.get(AssignmentStatus.REQUEST_EXPIRED) > 0) {
            return AssignmentStatus.REQUEST_EXPIRED;
        }
        else if (statusCounts.has(AssignmentStatus.PENDING) && statusCounts.get(AssignmentStatus.PENDING) > 0) {
            return AssignmentStatus.PENDING;
        }
        else if (statusCounts.has(AssignmentStatus.DMC_CONFIRM) && statusCounts.get(AssignmentStatus.DMC_CONFIRM) === totalAssignments) {
            return AssignmentStatus.DMC_CONFIRM;
        }
        else if (statusCounts.has(AssignmentStatus.SUPPLIER_CONFIRMED) && statusCounts.get(AssignmentStatus.SUPPLIER_CONFIRMED) === totalAssignments) {
            return AssignmentStatus.SUPPLIER_CONFIRMED;
        }
        else if (statusCounts.has(AssignmentStatus.NO_SHOW) && statusCounts.get(AssignmentStatus.NO_SHOW) === totalAssignments) {
            return AssignmentStatus.NO_SHOW;
        }
        else if (statusCounts.has(AssignmentStatus.INACTIVE) && statusCounts.get(AssignmentStatus.INACTIVE) === totalAssignments) {
            return AssignmentStatus.INACTIVE;
        }
        else {
            return AssignmentStatus.PENDING;
        }
    };
    ServiceAssignmentUtilService.updateStatusCountMap = function (statusCounts, status) {
        if (statusCounts.has(status)) {
            statusCounts.set(status, statusCounts.get(status) + 1);
        }
        else {
            statusCounts.set(status, 1);
        }
    };
    return ServiceAssignmentUtilService;
}());
export { ServiceAssignmentUtilService };
