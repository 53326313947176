<div class="tc-ag-grid__container">
    <ag-grid-angular
            style=""
            class="ag-theme-material tc-ag-grid tc-ag-grid--popup-content"
            [rowData]="rowData"
            [columnDefs]="columnDefs"
            [defaultColDef]="defaultColDef"
            [pagination]="true"
            [paginationAutoPageSize]="true">
    </ag-grid-angular>
</div>

<div class="tc-modal-footer tc-modal-footer--space-between">
    <div *ngIf="totalCost" class="tc-modal-footer__content">
        <h4>{{'Total Cost' | translate}}: AED {{totalCost | number : '1.2-2'}} </h4>
    </div>
    <button class="button-right tc-button-primary mat-button" (click)="onClose($event)">
        {{'Ok' | translate}}
    </button>
</div>
