import { TokenParams } from './token-params';

export class LoginResponseParams
{
    authorized: boolean;
    tokenParams: TokenParams;
    userName: string;
    approvalStatus: string;
    message: string;
    error: string;
}
