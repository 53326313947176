import { BackgroundTask } from './background-task';

export class BackgroundTaskEvent<T> {
    task: BackgroundTask;
    data: T;

    constructor(task: BackgroundTask, data: T) {
        this.task = task;
        this.data = data;
    }
}
