import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Remark } from '../../../models/common/remark';

@Component({
  selector: 'tc-note',
  templateUrl: './note.component.html'
})
export class NoteComponent implements OnInit {

  public isSaved = false;
  public isEdited = false;
  public isUndo = false;
  public backupServiceNote : Remark ;

  @Input() noteText: string;
  @Input() remark : Remark;

  @Output() saveNote: EventEmitter<Remark> = new EventEmitter();
  @Output() deleteNote: EventEmitter<Remark> = new EventEmitter();

  @Output() remarkChanged: EventEmitter<boolean> = new EventEmitter();

  remarkTemp : Remark;

  constructor() { }

  ngOnInit() {
    if(this.remark.remark){
      this.isSaved = true;
    }
    this.remarkTemp = JSON.parse(JSON.stringify(this.remark));
  }

  public onNoteSave(event: any) {

    this.remark.remark = this.remarkTemp.remark;

    this.saveNote.emit(this.remark);
    this.isSaved = true;
  }

  public onUndo(event: any) {
    this.remarkTemp = this.backupServiceNote;
    this.isSaved = true;
    this.isUndo = true;
  }

  public onEdit(event: any) {
    this.backupServiceNote = JSON.parse(JSON.stringify(this.remark));
    this.isSaved = false;
    this.isEdited = true;
  }

  public onDelete(event: any) {
    this.remarkTemp = null;
    this.deleteNote.emit(this.remark);
  }

  public hasValue(note: string): boolean{
    if (note){
      return true;
    } else {
      return  false
    }
  }

}
