import { Component, Input, OnInit } from '@angular/core';
import { CommonHelper } from '@tc-core/util/helpers';
import { LeaveSetupResultItemView } from '../../models/leave-setup-result-item-view';

@Component({
    selector: 'tc-leave-setup-resource-summary-view',
    templateUrl: './leave-setup-resource-summary-view.component.html'
})
export class LeaveSetupResourceSummaryViewComponent implements OnInit {

    @Input() selectedResources: Array<LeaveSetupResultItemView> = [];
    @Input() selectedResourceIds: number[] = [];

    public cardType = 'icon';
    constructor(private commonHelper: CommonHelper) { }

    ngOnInit() {
        console.log(this.selectedResources);
    }

    public isSelected(contentCard): boolean {
        if (this.commonHelper.hasProperty(contentCard, 'data.value')) {
            const cardId = contentCard.data.value.resourceId;
            return this.selectedResourceIds.indexOf(cardId) > -1;
        }
    }

}
