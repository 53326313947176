import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule, MatFormFieldModule, MatIconModule, MatOptionModule, MatSelectModule } from '@angular/material';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { BrowserModule } from '@angular/platform-browser';
import { AgGridModule } from 'ag-grid-angular';
import { CustomTextFilterComponent } from './custom-text-filter/custom-text-filter.component';

@NgModule({
    declarations: [
        CustomTextFilterComponent
    ],
    imports: [
        BrowserModule,
        FormsModule,
        AgGridModule,
        ReactiveFormsModule,

        MatIconModule,
        MatSelectModule,
        MatOptionModule,
        MatFormFieldModule,
        MatCardModule,
        MatInputModule,
        MatAutocompleteModule,
        MatButtonModule

    ],
    exports: [
        CustomTextFilterComponent
    ]
})
export class AgGridCustomFiltersModule {
}
