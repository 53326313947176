import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TcErrorType } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-error';
import { DialogModel, LEVEL } from '@tc-core/model/it/codegen/ui/framework/dialog-model';
import { ConfigLoader } from '@tc-core/util/framework';
import { SpinnerService } from '@tc-core/util/ui/spinner.service';
import { DialogService } from '@tc/dialog/dialog.service';
import { IGetRowsParams } from 'ag-grid-community';
import { Observable, of, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { TCO } from '../../../../constants';
import { ConfigurationSearchCriteria } from '../../../../models/criteria/configuration-search-criteria';
import { SortDirection } from '../../../../models/helper/sort-direction';
import {
    DataHandlerService
} from '../../../../services/backend-consumers/master-data-handler-service/data-handler.service';
import {
    MasterDataRestService
} from '../../../../services/backend-consumers/master-data-handler-service/master-data-rest.service';
import { BackendConfigService } from '../../../../services/backend-consumers/service-config/backend-config.service';
import { DMCCommon } from '../../../../services/util/common/dmc-common';
import { DataKey, DataStoreService } from '../../../../services/util/framework/data-store.service';
import {
    SetupGridComp
} from '../../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid-comp';
import {
    SetupGridDataSource
} from '../../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid-data-source';
import {
    SetupGridComponent
} from '../../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid.component';

@Component({
    selector: 'tc-config-setup-grid',
    templateUrl: './config-setup-grid.component.html'
})
export class ConfigSetupGridComponent extends SetupGridComp implements OnInit, OnDestroy, SetupGridDataSource {

    @ViewChild('setupGrid')
    private setupGrid: SetupGridComponent;
    public colDefConfig = [];
    private savableRow: any;
    private searchCriteria: ConfigurationSearchCriteria = new ConfigurationSearchCriteria();

    rowClassRules = {
        'tc-ag-grid-row--even': (params) => params && params.data && !!params.data.unsaved
    };

    private searchCriteriaObserver: Subscription = new Subscription();

    constructor(
        private configLoader: ConfigLoader,
        private dataStoreService: DataStoreService,
        private dataHandlerService: DataHandlerService,
        private masterDataRestService: MasterDataRestService,
        private backendConfigService: BackendConfigService,
        private spinnerService: SpinnerService,
        private common: DMCCommon,
        private dialogService: DialogService
    ) {
        super();
    }

    ngOnInit() {
        this.colDefConfig = this.configLoader.configurations.get(TCO.CONF.CONF_CONFIG_SETUP);
        this.subscribeSearchCriteria();
        this.fillRequiredGridOptionData();
        this.fillDataKeyForEditorData();
    }

    /**
     * subscribe search criteria changes in search bar
     * then force grid to refresh data
     * grid refresh will be call getRaws method which is using this subscribed criteria for backend call
     */
    subscribeSearchCriteria() {
        this.searchCriteriaObserver = this.dataStoreService.get(DataKey.serviceConfiguration)
            .subscribe(value => {
                this.searchCriteria = value;
                this.setupGrid.runForceSearch();
            });
    }

    private fillRequiredGridOptionData() {
        // nothing
    }

    private fillDataKeyForEditorData() {
    }

    public isInvalidRow(params): boolean {
        const data = params.data;
        if (data) {
            let invalid = true;
            if (data.code && data.value && data.description) {
                invalid = false;
            }
            return invalid;
        } else {
            return false;
        }
    }

    public createNewRow(): any {
        return {unsaved: true};
    }

    public isUnsavedRow(row: any): boolean {
        return !!row.unsaved;
    }

    public deleteRow(row: any) {
        if (row && row.code && this.isEditableRow(row)) {
            return this.masterDataRestService.deleteConfiguration(row.code);
        } else {
            return of('success');
        }
    }

    public saveConfiguration() {
        if (this.savableRow) {
            this.masterDataRestService.saveConfiguration(this.savableRow)
                .subscribe(
                    (data) => {
                        this.savableRow.unsaved = undefined;
                        this.common.showSnackBar(
                            'Successfully updated the configuration.',
                            3000,
                            TcErrorType.TYPE.INFO
                        );
                        this.spinnerService.hide();
                    },
                    (error) => {
                        this.common.showSnackBar(
                            'Configuration update is failed.',
                            3000,
                            TcErrorType.TYPE.ERROR
                        );
                        this.spinnerService.hide();

                    },
                    () => {
                        this.spinnerService.hide();
                    }
                );
        }
    }

    public saveRow(row: any, event: any) {
        this.savableRow = row;
        const confirmSaveConfig = new DialogModel(
            true,
            LEVEL.WARNING,
            'Save Configuration Value',
            'Your last changed cell value will be saved. Do you want to continue?',
            true,
            2000,
            null,
            'Cancel',
            'Save',
            true
        );
        this.dialogService
            .confirm(confirmSaveConfig)
            .subscribe((res) => {
                if (res === true) {
                    this.saveConfiguration();
                }
            });
        return undefined;
    }

    public getRows(params: IGetRowsParams): Observable<any> {
        console.log('fetch requesting for ' + params.startRow + ' to ' + params.endRow);
        console.log(params.sortModel);

        const pageSize = params.endRow - params.startRow;
        if (!this.searchCriteria) {
            this.searchCriteria = new ConfigurationSearchCriteria();
        }
        this.searchCriteria.start = params.startRow;
        this.searchCriteria.size = pageSize;
        if (params.sortModel && params.sortModel[0]) {
            this.searchCriteria.sortBy = params.sortModel[0].colId;
            if (params.sortModel[0].sort === SetupGridComp.GRID_SORT_ASCENDING) {
                this.searchCriteria.sortDirection = SortDirection.ASC;
            } else {
                this.searchCriteria.sortDirection = SortDirection.DESC;
            }
        } else {
            this.searchCriteria.sortBy = 'code';
            this.searchCriteria.sortDirection = SortDirection.DESC;
        }
        return this.masterDataRestService.getConfigurations(this.searchCriteria)
                   .pipe(
                       tap(data => {
                               this.dataStoreService.set(DataKey.serviceConfigurationSearchResultsForCriteria, data);
                               this.backendConfigService.updateConfigs(data);
                           }
                       )
                   );
    }

    /*
     grid config methods which are called by grid setup (ag grid)
     and set by grid config
     */

    isEditableItem = (params) => {
        return this.isEditableRow(params.data);
    };

    isEditableRow(row: any) {
        if (row) {
            if (row.unsaved) {
                return true;
            } else {
                return row.code !== 'TEST_MODE';
            }
        } else {
            return false;
        }
    }

    public ngOnDestroy(): void {
        if (this.searchCriteriaObserver) {
            this.searchCriteriaObserver.unsubscribe();
        }
    }
}
