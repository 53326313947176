import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FlightInfoExpandedViewComponent } from './flight-info-expanded-view.component';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule.forChild()
    ],
    declarations: [FlightInfoExpandedViewComponent],
    exports: [FlightInfoExpandedViewComponent]
})
export class FlightInfoExpandedViewModule {}
