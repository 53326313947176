<div class="container-fluid tc-item-wrapper tcpi-card">
    <div class="tc-item-wrapper__body">
        <div class="row" *ngIf="arrFltNumbers.length>0">
            <div class="col-md-12">
                <div class="tc-flex-box">
                    <div class="tc-flex-item icon-cage input-icon">
                        <div class="card-icon--small icn-color-primary">
                            <i class="material-icons material-icons">flight</i>
                        </div>
                    </div>
                    <div class="tc-flex-item input-cage ">
                        <div class="col-xs-12 col-sm-12  col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                            <div class="tc-content--wrap tc-input-view">
                                <div
                                        id="TRD_PER_INFO_REF"
                                        class="tc-caption-txt txt-light">{{"Arrival Flight Numbers" | translate}}
                                </div>
                                <div class="tc-body1--txt" *ngIf="arrFltNumbers.length>0">
                                    <div *ngFor="let item of arrFltNumbers, let i=index">
                                        {{item}}{{i === arrFltNumbers.length - 1 ? '' : ', '}}
                                    </div>
                                </div>
                                <div class="tc-body1--txt" *ngIf="arrFltNumbers.length===0">
                                    {{EMPTY_CHAR}}
                                </div>
                            </div>
                        </div>

                        <div class="col-xs-12 col-sm-12  col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                            <div class="tc-content--wrap tc-input-view">
                                <div
                                        id="TRADE_PER_INFO_EXT_REF"
                                        class="tc-caption-txt txt-light">{{"Arrival Flight Terminals" | translate}}
                                </div>
                                <div class="tc-body1--txt" *ngIf="arrFltTerminals.length>0">
                                    <div *ngFor="let item of arrFltTerminals, let i=index">
                                        {{item}}{{i === arrFltTerminals.length - 1 ? '' : ', '}}
                                    </div>
                                </div>
                                <div class="tc-body1--txt" *ngIf="arrFltTerminals.length===0">
                                    {{EMPTY_CHAR}}
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-6  col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                            <div class="tc-content--wrap tc-input-view">
                                <div
                                        id="TRD_PER_INFO_COU"
                                        class="tc-caption-txt txt-light">{{"Arrival Flight Time" | translate}}
                                </div>
                                <div class="tc-body1--txt" *ngIf="arrFltTime.length>0">
                                    <div *ngFor="let item of arrFltTime, let i=index">
                                        {{item}}{{i === arrFltTime.length - 1 ? '' : ', '}}
                                    </div>
                                </div>
                                <div class="tc-body1--txt" *ngIf="arrFltTime.length===0">
                                    {{EMPTY_CHAR}}
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-12  col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                            <div class="tc-content--wrap tc-input-view">
                                <div
                                        id="TRD_PER_INFO_COU2"
                                        class="tc-caption-txt txt-light">{{"Arrival Airports" | translate}}
                                </div>
                                <div class="tc-body1--txt" *ngIf="arrAirports.length>0">
                                    <div *ngFor="let item of arrAirports, let i=index">
                                        {{item}}{{i === arrAirports.length - 1 ? '' : ', '}}
                                    </div>
                                </div>
                                <div class="tc-body1--txt" *ngIf="arrAirports.length===0">
                                    {{EMPTY_CHAR}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="deptFltNumbers.length>0">
            <div class="col-md-12">
                <div class="tc-flex-box">
                    <div class="tc-flex-item icon-cage input-icon">
                        <div class="card-icon--small icn-color-primary">
                            <i class="material-icons material-icons">flight</i>
                        </div>
                    </div>
                    <div class="tc-flex-item input-cage ">
                        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                            <div class="tc-content--wrap tc-input-view">
                                <div
                                        id="TRD_PER_INFO_HOD_REF"
                                        class="tc-caption-txt txt-light">{{"Departure Flight Numbers" | translate}}
                                </div>
                                <div class="tc-body1--txt" *ngIf="deptFltNumbers.length>0">
                                    <div *ngFor="let item of deptFltNumbers, let i=index">
                                        {{item}}{{i === deptFltNumbers.length - 1 ? '' : ', '}}
                                    </div>
                                </div>
                                <div class="tc-body1--txt" *ngIf="deptFltNumbers.length===0">
                                    {{EMPTY_CHAR}}
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                            <div class="tc-content--wrap tc-input-view">
                                <div class="tc-caption-txt txt-light">{{"Departure Flight Terminals" | translate}}
                                </div>
                                <div class="tc-body1--txt " *ngIf="deptFltTerminals.length>0">
                                    <div *ngFor="let item of deptFltTerminals, let i=index">
                                        {{item}}{{i === deptFltTerminals.length - 1 ? '' : ', '}}
                                    </div>
                                </div>
                                <div class="tc-body1--txt" *ngIf="deptFltTerminals.length===0">
                                    {{EMPTY_CHAR}}
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-12  col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                            <div class="tc-content--wrap tc-input-view">
                                <div
                                        id="TRD_PER_INFO_COU1"
                                        class="tc-caption-txt txt-light">{{"Departure Flight Times" | translate}}
                                </div>
                                <div class="tc-body1--txt" *ngIf="deptFltTime.length>0">
                                    <div *ngFor="let item of deptFltTime, let i=index">
                                        {{item}}{{i === deptFltTime.length - 1 ? '' : ', '}}
                                    </div>
                                </div>
                                <div class="tc-body1--txt" *ngIf="deptFltTime.length===0">
                                    {{EMPTY_CHAR}}
                                </div>
                            </div>
                        </div>
                    <div class="col-xs-12 col-sm-12  col-md-3 col-lg-3 remove-bootstrap-padding-mobile last-column">
                        <div class="tc-content--wrap tc-input-view">
                            <div
                                    id="TRD_PER_INFO_COU3"
                                    class="tc-caption-txt txt-light">{{"Departure Airports" | translate}}
                            </div>
                            <div class="tc-body1--txt" *ngIf="deptAirports.length>0">
                                <div *ngFor="let item of deptAirports, let i=index">
                                    {{item}}{{i === deptAirports.length - 1 ? '' : ', '}}
                                </div>
                            </div>
                            <div class="tc-body1--txt" *ngIf="deptAirports.length===0">
                                {{EMPTY_CHAR}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</div>