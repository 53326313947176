import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { TcErrorType } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-error';
import { TC } from '@tc-core/util';
import { ConfigLoader } from '@tc-core/util/framework';
import { of, Subscription } from 'rxjs/index';
import { tap } from 'rxjs/operators';
import { SetupGridComp } from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid-comp';
import { SetupGridComponent } from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid.component';
import { TCO } from '../../../constants';
import { CurrencySearchCriteria } from '../../../models/criteria/currency-search-criteria';
import { SortDirection } from '../../../models/helper/sort-direction';
import { CurrencyService } from '../../../services/backend-consumers/setups/currency.service';
import { DMCCommon } from '../../../services/util/common/dmc-common';
import { DataKey, DataStoreService } from '../../../services/util/framework/data-store.service';
var EMPTY_CHAR = TC.FORMAT.EMPTY_CHAR;
var CurrencyGridSetupComponent = /** @class */ (function (_super) {
    tslib_1.__extends(CurrencyGridSetupComponent, _super);
    function CurrencyGridSetupComponent(configLoader, currencyService, dataStoreService, common) {
        var _this = _super.call(this) || this;
        _this.configLoader = configLoader;
        _this.currencyService = currencyService;
        _this.dataStoreService = dataStoreService;
        _this.common = common;
        _this.colDefConfig = [];
        _this.allCurrencyCodes = [];
        // search criteria obj. used to
        _this.currencySearchCriteria = new CurrencySearchCriteria();
        _this.searchCriteriaObserver = new Subscription();
        _this.getCountries = function (params, rowData, text) {
            return _this.dataStoreService.get(DataKey.countryList)
                .map(function (countries) {
                return countries.filter(function (countries) {
                    if (text) {
                        if (countries.name) {
                            return countries.name.toLowerCase().indexOf(text.toLocaleString()) > -1 ||
                                countries.code.toLowerCase().indexOf(text.toLocaleString()) > -1;
                        }
                    }
                    else {
                        return true;
                    }
                });
            });
        };
        _this.onCountrySet = function (params) {
            if (params.oldValue === '--' && _this.allCurrencyCodes.includes(params.data.code)) {
                _this.common.showSnackBar('Country Code Already Exist. Please Select a Different Code', 3000, TcErrorType.TYPE.ERROR);
            }
            else {
                if (params.data && params.newValue) {
                    if (params.newValue['code']) {
                        params.data['country'] = params.newValue['code'];
                    }
                    else {
                        params.data['country'] = params.newValue;
                    }
                    return true;
                }
            }
        };
        /*
         grid config methods which are called by grid setup (ag grid)
         and set by grid config
         */
        _this.getCountryText = function (params) {
            var groupText = EMPTY_CHAR;
            if (params && params.data && params.data.country) {
                if (params.data.country instanceof Object) {
                    if (params.data.country.code) {
                        groupText = params.data.country.code;
                    }
                    else {
                        groupText = EMPTY_CHAR;
                    }
                    groupText += ' | ';
                    if (params.data.country.name) {
                        groupText += params.data.country.name;
                    }
                    else {
                        groupText += EMPTY_CHAR;
                    }
                }
                else {
                    groupText = params.data.country;
                }
            }
            return groupText;
        };
        return _this;
    }
    CurrencyGridSetupComponent.prototype.ngOnInit = function () {
        this.colDefConfig = this.configLoader.configurations.get(TCO.CONF.CONF_CURRENCY_SETUP);
        this.subscribeSearchCriteria();
        this.getCurrencyCodeList();
    };
    /**
     * subscribe search criteria changes in search bar
     * then force grid to refresh data
     * grid refresh will be call getRaws method which is using this subscribed criteria for backend call
     */
    CurrencyGridSetupComponent.prototype.subscribeSearchCriteria = function () {
        var _this = this;
        this.searchCriteriaObserver = this.dataStoreService.get(DataKey.currencySearchCriteria).subscribe(function (value) {
            _this.currencySearchCriteria = value;
            _this.setupGrid.runForceSearch();
        });
    };
    CurrencyGridSetupComponent.prototype.getCurrencyCodeList = function () {
        var _this = this;
        this.currencyService.getAllCurrencyCodes().subscribe(function (response) {
            _this.allCurrencyCodes = response.data;
        });
    };
    CurrencyGridSetupComponent.prototype.isInvalidRow = function (params) {
        var data = params.data;
        if (data) {
            var invalid = true;
            if (data.code &&
                data.name &&
                data.symbol &&
                data.country) {
                invalid = false;
            }
            return invalid;
        }
        else {
            return false;
        }
    };
    CurrencyGridSetupComponent.prototype.isUnsavedRow = function (row) {
        return !row.id;
    };
    CurrencyGridSetupComponent.prototype.deleteRow = function (row) {
        this.setupGrid.gridApi.getSelectedRows();
        if (row && row.code) {
            return this.currencyService.deleteRow(row.code);
        }
        else {
            return of('success');
        }
    };
    CurrencyGridSetupComponent.prototype.saveRow = function (row, event) {
        return this.currencyService.saveRow(row);
    };
    CurrencyGridSetupComponent.prototype.getRows = function (params) {
        var _this = this;
        console.log('fetch requesting for ' + params.startRow + ' to ' + params.endRow);
        console.log(params.sortModel);
        var pageSize = params.endRow - params.startRow;
        if (!this.currencySearchCriteria) {
            this.currencySearchCriteria = new CurrencySearchCriteria();
        }
        this.currencySearchCriteria.start = params.startRow;
        this.currencySearchCriteria.size = pageSize;
        if (params.sortModel && params.sortModel[0]) {
            this.currencySearchCriteria.sortBy = params.sortModel[0].colId;
            if (params.sortModel[0].sort === SetupGridComp.GRID_SORT_ASCENDING) {
                this.currencySearchCriteria.sortDirection = SortDirection.ASC;
            }
            else {
                this.currencySearchCriteria.sortDirection = SortDirection.DESC;
            }
        }
        else {
            this.currencySearchCriteria.sortBy = 'createdOn';
            this.currencySearchCriteria.sortDirection = SortDirection.ASC;
        }
        return this.currencyService.getRows(this.currencySearchCriteria)
            .pipe(tap(function (data) {
            return _this.dataStoreService.set(DataKey.currencySearchResultsForCriteria, data);
        }));
    };
    CurrencyGridSetupComponent.prototype.createNewRow = function () {
        return {};
    };
    CurrencyGridSetupComponent.prototype.ngOnDestroy = function () {
        if (this.searchCriteriaObserver) {
            this.searchCriteriaObserver.unsubscribe();
        }
    };
    return CurrencyGridSetupComponent;
}(SetupGridComp));
export { CurrencyGridSetupComponent };
