<div class="cg-cal">
  <tc-calendar-header-controller
    [validCalendarOptions]="validCalendarOptions"
    [calendarNavData]="calendarNavData"
    [calendarMenuItems]="calendarMenuItems"
    (menuItemClick)="onMenuItemClick($event)"
    (selectCalendarType)="onSelectCalendarType($event)"
    (clickNextBtn)="onClickNextBtn($event)"
    (clickPreviousBtn)="onClickPreviousBtn($event)">
  </tc-calendar-header-controller>

  <tc-calendar-body
    [calendarNavData]="calendarNavData"
    [yearsArray]="yearsArray"
    [rangeGroups]="rangeGroups"
    [calendarRefreshEvent]="calendarRefreshEvent"
    (selectDate)="onSelectDate($event)">
  </tc-calendar-body>

</div>

