import {Injectable} from '@angular/core';
import {ConfigLoader} from '@tc-core/util/framework';
import {RequestService} from '../../util/framework/request.service';
import {DMCQueryParamsService} from '../../util/framework/dmc-query-params.service';
import {Observable} from 'rxjs';
import {UrlPaths} from '../url-paths';
import {BoardBasisSetupSearchCriteria} from '../../../models/criteria/board-basis-setup-search-criteria';

@Injectable({
    providedIn: 'root'
})
export class BoardBasisSetupService {

    constructor(
        private configLoader: ConfigLoader,
        private requestService: RequestService,
        private queryParamsService: DMCQueryParamsService
    ) {
    }

    public getRows(criteria: BoardBasisSetupSearchCriteria): Observable<any> {
        const reqPrams = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.get(
            UrlPaths.OP_DATA_URL_KEY,
            [UrlPaths.OP_DATA_VERSION, UrlPaths.BOARD_BASIS_SETUP_SEARCH],
            reqPrams
        );
    }

    public deleteRow(code: string): Observable<any> {
        return this.requestService.delete(
            UrlPaths.OP_DATA_URL_KEY,
            [UrlPaths.OP_DATA_VERSION, UrlPaths.BOARD_BASIS_SETUP, code]
        );
    }

    public saveRow(row: any): Observable<any> {
        console.log(row);
        return this.requestService.post(
            UrlPaths.OP_DATA_URL_KEY,
            [UrlPaths.OP_DATA_VERSION, UrlPaths.BOARD_BASIS_SETUP],
            row
        );
    }

    public updateRow(code: string, row: any): Observable<any> {
        return this.requestService.put(
            UrlPaths.OP_DATA_URL_KEY,
            [UrlPaths.OP_DATA_VERSION, UrlPaths.BOARD_BASIS_SETUP, code],
            row
        );
    }

}
