<div class="trade-client-detail-view">
    <tc-expanded-content-card-body>
        <ng-container *ngIf="resource">
            <tc-vehicle-specific-info-card
                    class="tc-list-card"
                    [resource]="resource">
            </tc-vehicle-specific-info-card>

            <mat-accordion class="rs-full-width tc-accordion">
                <!--key-control-card-->
                <mat-expansion-panel *ngIf="userManagementService.notAvailableFor('FC')">
                    <mat-expansion-panel-header class="tc-accordion-header__container">
                        <div class="tc-accordion-header">
                            <span class="tc-accordion-header__title">{{"Key Controls" | translate}}</span>
                            <i class="material-icons tc-accordion-header__down-icon">keyboard_arrow_down</i>
                        </div>
                    </mat-expansion-panel-header>
                    <tc-key-control-detail-card
                            [keyControlHolder]="resource"
                            class="tc-list-card">
                    </tc-key-control-detail-card>
                </mat-expansion-panel>
                <!--contact-details-card-->
                <mat-expansion-panel>
                    <mat-expansion-panel-header class="tc-accordion-header__container">
                        <div class="tc-accordion-header">
                            <!--<div class="tc-item-wrapper__header tc-item-header-highlighted">-->
                                <!--<div class="tc-item-header-highlighted__icon">-->
                                    <!--<i class="material-icons material-icons">account_box</i>-->
                                <!--</div>-->
                                <!--<div class="tc-item-header-highlighted__primary">{{"Contact details" | translate}}</div>-->
                            <!--</div>-->
                            <span class="tc-accordion-header__title">{{"Contact details" | translate}}</span>
                            <i class="material-icons tc-accordion-header__down-icon">keyboard_arrow_down</i>
                        </div>
                    </mat-expansion-panel-header>
                    <tc-contact-details-card
                            class="tc-list-card"
                            [showTitle]="false"
                            [contactDetails]="resource.contactDetails">
                    </tc-contact-details-card>
                </mat-expansion-panel>

                <!--payment-info-card-->
                <mat-expansion-panel>
                    <mat-expansion-panel-header class="tc-accordion-header__container">
                        <div class="tc-accordion-header">
                            <!--<div class="tc-item-wrapper__header tc-item-header-highlighted">-->
                                <!--<div class="tc-item-header-highlighted__icon">-->
                                    <!--<i class="material-icons material-icons">credit_card</i>-->
                                <!--</div>-->
                                <!--<div class="tc-item-header-highlighted__primary">{{"Payment Information" | translate}}</div>-->
                            <!--</div>-->
                            <span class="tc-accordion-header__title">{{"Payment Information" | translate}}</span>
                            <i class="material-icons tc-accordion-header__down-icon">keyboard_arrow_down</i>
                        </div>
                    </mat-expansion-panel-header>
                    <tc-payment-info-card
                            class="tc-list-card"
                            [showTitle]="false"
                            [paymentInfo]="resource.paymentInfo">
                    </tc-payment-info-card>
                </mat-expansion-panel>

                <!--insurance-info-card-->
                <mat-expansion-panel>
                    <mat-expansion-panel-header class="tc-accordion-header__container">
                        <div class="tc-accordion-header">
                            <!--<div class="tc-item-wrapper__header tc-item-header-highlighted">-->
                                <!--<div class="tc-item-header-highlighted__icon">-->
                                    <!--<i class="material-icons material-icons">security</i>-->
                                <!--</div>-->
                                <!--<div class="tc-item-header-highlighted__primary">{{"Insurance Information" | translate}}</div>-->
                            <!--</div>-->
                            <span class="tc-accordion-header__title">{{"Insurance Information" | translate}}</span>
                            <i class="material-icons tc-accordion-header__down-icon">keyboard_arrow_down</i>
                        </div>
                    </mat-expansion-panel-header>
                    <tc-insurance-info-card
                            class="tc-list-card"
                            [showTitle]="false"
                            [insuranceInformation]="resource?.vehicle?.resInsuranceInfo?resource.vehicle.resInsuranceInfo:null">
                    </tc-insurance-info-card>
                </mat-expansion-panel>

                <!--service-info-card-->
                <mat-expansion-panel>
                    <mat-expansion-panel-header class="tc-accordion-header__container">
                        <div class="tc-accordion-header">
                            <!--<div class="tc-item-wrapper__header tc-item-header-highlighted">-->
                                <!--<div class="tc-item-header-highlighted__icon">-->
                                    <!--<i class="material-icons material-icons">business_center</i>-->
                                <!--</div>-->
                                <!--<div class="tc-item-header-highlighted__primary">{{"Service Information" | translate}}</div>-->
                            <!--</div>-->
                            <span class="tc-accordion-header__title">{{"Service Information" | translate}}</span>
                            <i class="material-icons tc-accordion-header__down-icon">keyboard_arrow_down</i>
                        </div>
                    </mat-expansion-panel-header>
                    <tc-service-info-card
                            class="tc-list-card"
                            [showTitle]="false"
                            [serviceInformation]="resource?.vehicle?.resServiceInfo?resource.vehicle.resServiceInfo:null">
                    </tc-service-info-card>
                </mat-expansion-panel>

                <!--additional-contacts-card-->
                <mat-expansion-panel>
                    <mat-expansion-panel-header class="tc-accordion-header__container">
                        <div class="tc-accordion-header">
                            <!--<div class="tc-item-wrapper__header tc-item-header-highlighted">-->
                                <!--<div class="tc-item-header-highlighted__icon">-->
                                    <!--<i class="material-icons material-icons">account_box</i>-->
                                <!--</div>-->
                                <!--<div class="tc-item-header-highlighted__primary">{{"Additional Contacts" | translate}}</div>-->
                            <!--</div>-->
                            <span class="tc-accordion-header__title">{{"Additional Contacts" | translate}}</span>
                            <i class="material-icons tc-accordion-header__down-icon">keyboard_arrow_down</i>
                        </div>
                    </mat-expansion-panel-header>
                    <tc-additional-contacts-card
                            [showTitle]="false"
                            [contactDetails]="resource.contactDetails"
                            class="tc-list-card">
                    </tc-additional-contacts-card>
                </mat-expansion-panel>

                <!--supplier-attributes-card-->
                <mat-expansion-panel>
                    <mat-expansion-panel-header class="tc-accordion-header__container">
                        <div class="tc-accordion-header">
                            <!--<div class="tc-item-wrapper__header tc-item-header-highlighted">-->
                                <!--<div class="tc-item-header-highlighted__icon">-->
                                    <!--<i class="material-icons material-icons">format_align_justify</i>-->
                                <!--</div>-->
                                <!--<div class="tc-item-header-highlighted__primary">{{"Supplier Attributes" | translate}}</div>-->
                            <!--</div>-->
                            <span class="tc-accordion-header__title">{{"Supplier Attributes" | translate}}</span>
                            <i class="material-icons tc-accordion-header__down-icon">keyboard_arrow_down</i>
                        </div>
                    </mat-expansion-panel-header>
                    <tc-supplier-attributes-card
                            class="tc-list-card"
                            [showTitle]="false"
                            [supplierAttributes]="resource.supplierAttributes">
                    </tc-supplier-attributes-card>
                </mat-expansion-panel>

                <!--cost-setup-details-card-->
                <mat-expansion-panel>
                    <mat-expansion-panel-header class="tc-accordion-header__container">
                        <div class="tc-accordion-header">
                            <span class="tc-accordion-header__title">{{"Transport Cost Setup Data" | translate}}</span>
                            <i class="material-icons tc-accordion-header__down-icon">keyboard_arrow_down</i>
                        </div>
                    </mat-expansion-panel-header>
                    <tc-cost-setup-data-card
                            [showTitle]="false"
                            [seasons]="selectedVehicleCost"
                            [currencyFormat]="currencyFormat"
                            class="tc-list-card">
                    </tc-cost-setup-data-card>
                </mat-expansion-panel>

                <!--note-info-card-->
                <mat-expansion-panel>
                    <mat-expansion-panel-header class="tc-accordion-header__container">
                        <div class="tc-accordion-header">
                            <!--<div class="tc-item-wrapper__header tc-item-header-highlighted">-->
                                <!--<div class="tc-item-header-highlighted__icon">-->
                                    <!--<i class="material-icons material-icons">description</i>-->
                                <!--</div>-->
                                <!--<div class="tc-item-header-highlighted__primary">{{"Remarks" | translate}}</div>-->
                            <!--</div>-->
                            <span class="tc-accordion-header__title">{{"Remarks" | translate}}</span>
                            <i class="material-icons tc-accordion-header__down-icon">keyboard_arrow_down</i>
                        </div>
                    </mat-expansion-panel-header>
                    <tc-note-info-card
                            class="tc-list-card"
                            [showTitle]="false"
                            [noteList]="resource.remarks">
                    </tc-note-info-card>
                </mat-expansion-panel>

                <!--documents-card-->
                <mat-expansion-panel>
                    <mat-expansion-panel-header class="tc-accordion-header__container">
                        <div class="tc-accordion-header">
                            <!--<div class="tc-item-wrapper__header tc-item-header-highlighted">-->
                                <!--<div class="tc-item-header-highlighted__icon">-->
                                    <!--<i class="material-icons material-icons">insert_drive_file</i>-->
                                <!--</div>-->
                                <!--<div class="tc-item-header-highlighted__primary">{{"Documents" | translate}}</div>-->
                            <!--</div>-->
                            <span class="tc-accordion-header__title">{{"Documents" | translate}}</span>
                            <i class="material-icons tc-accordion-header__down-icon">keyboard_arrow_down</i>
                        </div>
                    </mat-expansion-panel-header>
                    <tc-document-card
                            class="tc-list-card"
                            [showTitle]="false"
                            [documents]="resource.documents">
                    </tc-document-card>
                </mat-expansion-panel>

                <!--tax-schemes-card-->
                <mat-expansion-panel>
                    <mat-expansion-panel-header class="tc-accordion-header__container">
                        <div class="tc-accordion-header">
                            <!--<div class="tc-item-wrapper__header tc-item-header-highlighted">-->
                                <!--<div class="tc-item-header-highlighted__icon">-->
                                    <!--<i class="material-icons material-icons">note</i>-->
                                <!--</div>-->
                                <!--<div class="tc-item-header-highlighted__primary">{{"Tax Schemes" | translate}}</div>-->
                            <!--</div>-->
                            <span class="tc-accordion-header__title">{{"Tax Schemes" | translate}}</span>
                            <i class="material-icons tc-accordion-header__down-icon">keyboard_arrow_down</i>
                        </div>
                    </mat-expansion-panel-header>
                    <tc-tax-schemes-card
                            class="tc-list-card"
                            [showTitle]="false"
                            [taxSchemes]="resource.taxSchemes">
                    </tc-tax-schemes-card>
                </mat-expansion-panel>

            </mat-accordion>

        </ng-container>
    </tc-expanded-content-card-body>
</div>
