export enum BackendConfigCodes {
    // test mode
    TEST_MODE = 'TEST_MODE',
    NOTIFY_NO_ARRIVALS_SUPPLIERS = 'NOTIFY_NO_ARRIVALS_SUPPLIERS',
    NOTIFY_NO_ARRIVALS_FILTER = 'NOTIFY_NO_ARRIVALS_FILTER',
    TEST_MODE_EMAIL = 'TEST_MODE_EMAIL',

    // product default time zone
    DEFAULT_PRODUCT_TIME_ZONE = 'DEFAULT_PRODUCT_TIME_ZONE',

    // sign up
    IGNORE_VERIFICATION_MAIL = 'IGNORE_VERIFICATION_MAIL',
    GENERAL_SUPPLIER_REQUIRED = 'GENERAL_SUPPLIER_REQUIRED',
    GENERAL_SUPPLIER_CODE = 'GENERAL_SUPPLIER_CODE',
    GENERAL_VEHICLE_REQUIRED = 'GENERAL_VEHICLE_REQUIRED',
    GENERAL_RESOURCE_ID = 'GENERAL_RESOURCE_ID',

    // auto allocation
    AUTO_ALLOCATION_METHOD = 'AUTO_ALLOCATION_METHOD',

    // manual document generation
    BACKGROUND_ITEM_LIMIT_MAN_DOCS = 'BACKGROUND_ITEM_LIMIT_MAN_DOCS',

}
