import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatSelectionList } from '@angular/material/list';
import { TranslateService } from '@ngx-translate/core';
import { ConfigLoader } from '@tc-core/util/framework/config-loader.service';
import { AgGridAngular } from 'ag-grid-angular';
import { CellValueChangedEvent, GridApi, GridOptions, ICellRendererParams, IGetRowsParams, RowNode } from 'ag-grid-community';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SetupGridComp } from '../../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid-comp';
import { ActionRendererAction } from '../../../../widgets/framework/ag-grid-custom-components/renderers/actions/actions.component';
import { SortDirection } from '../../../../models/helper/sort-direction';
import { ServiceItemSearchCriteria } from '../../../../models/reservation-v2/criteria/service-item-search-criteria';
import { ProductItem } from '../../../../models/reservation-v2/product-item';
import { ServiceItem } from '../../../../models/reservation-v2/service-item';
import { TimeGroupingOptions } from '../../../../models/reservation-v2/time-grouping-options';
import { OPResponseWrapper } from '../../../../models/response/op-response-wrapper';
import { ReservationV2ManagementService } from '../../../../services/backend-consumers/reservation-v2/reservation-v2-management.service';
import { DataKey, DataStoreService } from '../../../../services/util/framework/data-store.service';
import { OperationV2DataProcessorService } from '../../../../services/util/pre-processors/operation-v2-data-processor.service';
import { ResponseUtil } from '../../../../services/util/response/response-util.service';
import { OperationQueuePanelConfig, OperationQueueProfile } from '../operation-queue-panel-config';

@Component({
    selector: 'tc-service-queue',
    templateUrl: './service-queue.component.html'
})
export class ServiceQueueComponent implements OnInit, OnDestroy
{

    constructor(
        private reservationV2ManagementService: ReservationV2ManagementService,
        private translateService: TranslateService,
        private operationV2DataProcessorService: OperationV2DataProcessorService,
        private configLoader: ConfigLoader,
        private dataStoreService: DataStoreService
    )
    {
        this.translateService.onLangChange.subscribe(() => {
            this.gridApi.refreshHeader();
        });
    }

    @Input() set primaryColumnDefs(colDef)
    {
        this.primaryColumnDefsInput = colDef;
    }

    @Input() set secondaryColumnDefs(colDef)
    {
        this.secondaryColumnDefsInput = colDef;
    }

    @Input() set allColumnDefs(colDef)
    {
        this.allColumnDefsInput = colDef;
    }

    @Input() set columnDefs(colDef)
    {
        this.columnDefsInput = colDef;
        if (this.gridApi) {
            setTimeout(() => this.gridApi.sizeColumnsToFit(), 0);
        }
    }

    @ViewChild('agGrid') agGrid: AgGridAngular;
    @ViewChild('colSelector') private colSelector: MatSelectionList;

    @Input() operationPanelConfig = new OperationQueuePanelConfig();
    @Input() serviceQueueTimeGroupingOptions: TimeGroupingOptions[];
    @Input() tabIndex;
    hideOverlay = false;
    showRowLoading = false;

    columnDefsInput: any[] = [];
    primaryColumnDefsInput: any[] = [];
    secondaryColumnDefsInput: any[] = [];
    allColumnDefsInput: any[] = [];
    selectedRowsCount = 0;
    unsavedRows = [];

    @Input() selectedProducts: ProductItem[] = [];
    @Input() frameworkComponents = {};
    @Input() autoSize = true;
    @Input() defaultColDef;
    @Input() gridOptions: Partial<GridOptions>;

    colResizeDefault: any = {};
    domLayout = 'autoHeight';
    overlayNoRowsTemplate: any = `<div class="tc-ag-grid-empty">
            <i class="material-icons tc-ag-grid-empty__icon">assignment_error</i>
            <span class="tc-ag-grid-empty__text">No Records Available</span>
        </div>`;
    overlayLoadingTemplate: any = `<div class="tc-ag-grid-empty">
            <img src="assets/img/loading-tab.gif" class="tc-mb-2" alt="Loading">
            <span class="tc-ag-grid-empty__text">Loading...</span>
        </div>`;

    gridApi: GridApi;
    gridColumnApi;
    gridActions: ActionRendererAction[] = [];

    serviceItemSearchResults: ServiceItem[] = [];

    @Output() rowDoubleClicked: EventEmitter<any> = new EventEmitter();
    @Output() rowClicked: EventEmitter<any> = new EventEmitter();

    public getRawClass = (params) => {
        if (params.node.rowIndex % 2 === 0) {
            return 'tc-ag-grid-row--even';
        }
    };

    ngOnInit()
    {
        this.operationPanelConfig.currentTab = this.tabIndex;
        this.colResizeDefault = 'shift';

        this.gridOptions = {
            cacheBlockSize: 50,
            maxBlocksInCache: 1,
            enableSorting: true,
            rowModelType: 'infinite',
            rowSelection: 'multiple',
            pagination: true,
            paginationPageSize: 50
        };
        this.defaultColDef = {
            resizable: true,
            headerValueGetter: (parameters: ICellRendererParams): string => {
                const headerIdentifier = parameters.colDef.headerName;
                if (headerIdentifier) {
                    return this.translateService.instant(headerIdentifier);
                }
                return '';
            }
        };
        if (this.gridOptions.defaultColDef) {
            this.gridOptions.defaultColDef.resizable = true;
            this.gridOptions.defaultColDef.sortable = true;
        }
        this.autoSizeAll(false);
    }

    onClickColOption(event)
    {
        event.stopPropagation();
    }

    onSelectionChangeColSelector(event)
    {
        if (event && event.option) {
            const option = event.option.value;
            const selected = event.option.selected;
            if (this.gridColumnApi) {
                this.gridColumnApi.setColumnVisible(option, selected);
                // this.gridApi.sizeColumnsToFit();
                this.autoSizeAll(false);
            }
        }
    }

    autoSizeAll(skipHeader: boolean)
    {
        const allColumnIds = [];
        if (this.gridColumnApi) {
            this.gridColumnApi.getAllColumns().forEach((column) => {
                allColumnIds.push(column.colId);
            });
            // this.gridApi.sizeColumnsToFit();
            this.gridColumnApi.autoSizeColumns(allColumnIds, skipHeader);
        }
    }

    public onGridSizeChange($event)
    {
        this.fixGridColumnSizes();
    }

    onRestoreColDefClick()
    {
        this.columnDefsInput = this.allColumnDefsInput;
        if (this.gridColumnApi) {
            this.gridColumnApi.resetColumnState();
            const allColumnIds = [];
            this.gridColumnApi.getAllColumns().forEach((column) => {
                allColumnIds.push(column.colId);
            });
            this.gridColumnApi.hideColumns(allColumnIds, false);
            this.colSelector.selectAll();
        }
    }

    public onSelectTimeGrouping(event)
    {
        this.operationPanelConfig.timeGrouping = event;
        // this.loadServiceItems();
    }

    public isTimeGroupingVisible()
    {
        if (this.operationPanelConfig.activatedProfile === OperationQueueProfile.TRS_PROFILE) {
            return true;
        } else {
            return false;
        }
    }

    public doSomeChange()
    {
        this.operationPanelConfig.preventSwitching = !this.operationPanelConfig.preventSwitching;
        if (this.operationPanelConfig.preventSwitching) {
            this.operationPanelConfig.currentTab = this.tabIndex;
        }
    }

    public onSelectionChange($event: any)
    {
        this.selectedRowsCount = $event.api.getSelectedRows().length;
        this.operationV2DataProcessorService.autoAllocationButtonEnable = this.selectedRowsCount > 0;
        this.operationV2DataProcessorService.confirmButtonEnable = this.selectedRowsCount > 0;
        this.operationV2DataProcessorService.supplierConfirmButtonEnable = this.selectedRowsCount > 0;
        this.operationV2DataProcessorService.supplierRejectButtonEnable = this.selectedRowsCount > 0;
    }

    public onGridReady($event)
    {
        this.gridApi = $event.api;
        this.gridColumnApi = $event.columnApi;
        this.autoSizeAll(false);
        const gridDataSource = {
            getRows: (params: IGetRowsParams) => {
                this.gridApi.showLoadingOverlay();
                const sortBy = params.sortModel && params.sortModel[0] && params.sortModel[0].colId ?
                    params.sortModel[0].colId : null;
                const sortDirection = params.sortModel && params.sortModel[0] && params.sortModel[0].sort ?
                    params.sortModel[0].sort : null;
                this.getDataRows(params.startRow, params.endRow, sortBy, sortDirection).subscribe(
                    (result: any) => {
                        if (result) {
                            this.gridApi.hideOverlay();
                            const data = ResponseUtil.getDataArray(result);
                            const totalRows = ResponseUtil.getTotalCount(result);
                            params.successCallback(data, totalRows);
                            if (totalRows === 0) {
                                this.gridApi.showNoRowsOverlay();
                            }
                            this.addUnSavedRowsAgainAfterReload();
                            this.autoSizeAll(false);
                        }
                    },
                    error => {
                        this.gridApi.hideOverlay();
                        params.successCallback([], 0);
                    }
                );
            }
        };
        this.autoSizeAll(false);
        this.gridApi.setDatasource(gridDataSource);
    }

    public loadData()
    {
        if (this.gridApi) {
            this.gridApi.refreshInfiniteCache();
        } else {
            setTimeout(() => this.loadData(), 100);
        }
        this.autoSizeAll(false);
    }

    public showLoading()
    {
        if (!this.hideOverlay) {
            this.showRowLoading = true;
            if (this.gridApi) {
                this.gridApi.showLoadingOverlay();
            } else {
                setTimeout(() => this.showLoading(), 0);
            }
        }
    }

    public hideLoading()
    {
        this.hideOverlay = true;
        this.showRowLoading = false;
        if (this.gridApi) {
            this.gridApi.hideOverlay();
        } else {
            setTimeout(() => this.hideLoading(), 0);
        }
    }

    getDataRows(startRow, endRow, sortBy, sortDirection): Observable<any>
    {
        const bookingIds = [];
        this.selectedProducts.forEach(productItem => {
            bookingIds.push(...productItem.bookingIdList);
        });
        if (bookingIds.length) {
            const pageSize = endRow - startRow;
            const serviceItemSearchCriteria = new ServiceItemSearchCriteria();
            this.dataStoreService.get(DataKey.productQueueSearchCriteria)
                .subscribe((data) => {
                    if (data && data.itemNumber && data.itemNumber !== '-1' && data.itemNumber.toLowerCase() !== 'any') {
                        // serviceItemSearchCriteria.itemNumber = data.itemNumber;
                    }
                });

            serviceItemSearchCriteria.bookingIdList = bookingIds;
            serviceItemSearchCriteria.start = startRow;
            serviceItemSearchCriteria.size = pageSize;
            if (sortBy) {
                serviceItemSearchCriteria.sortBy = sortBy;
                if (sortDirection === SetupGridComp.GRID_SORT_ASCENDING) {
                    serviceItemSearchCriteria.sortDirection = SortDirection.ASC;
                } else {
                    serviceItemSearchCriteria.sortDirection = SortDirection.DESC;
                }
            } else {
                serviceItemSearchCriteria.sortDirection = null;
            }
            return this.reservationV2ManagementService.searchServiceItems(serviceItemSearchCriteria)
                       .pipe(
                           tap(data => {
                                   if (data) {
                                       this.dataStoreService.set(DataKey.queueServiceSearchResults, data.data);
                                       this.serviceItemSearchResults = data.data;
                                   }
                               }
                           )
                       );
        } else {
            // todo
            return new Observable<any>();
        }
    }


    public fixGridColumnSizes()
    {
        if (this.autoSize) {
            this.autoSizeAll(false);
        } else {
            this.gridApi.sizeColumnsToFit();
        }
    }

    public loadServiceItems()
    {
        this.hideOverlay = false;
        this.showLoading();
        const bookingIds = [];
        for (const productItem of this.selectedProducts) {
            bookingIds.push(...productItem.bookingIdList);
        }
        if (bookingIds.length) {
            const serviceItemSearchCriteria = new ServiceItemSearchCriteria();
            serviceItemSearchCriteria.bookingIdList = bookingIds;
            this.setupGroupingBasedOnQueueProfile(this.operationPanelConfig.activatedProfile, serviceItemSearchCriteria);
            this.reservationV2ManagementService.searchServiceItems(serviceItemSearchCriteria)
                .subscribe((data: OPResponseWrapper<ServiceItem>) => {
                    if (data) {
                        this.serviceItemSearchResults = ResponseUtil.getDataArray<ServiceItem>(data);
                        this.setDataToGrid(this.serviceItemSearchResults);
                    }
                });
        } else {
            this.serviceItemSearchResults = [];
            this.setDataToGrid(this.serviceItemSearchResults);
        }
    }

    private setupGridHeight(searchSize: number)
    {
        if (this.gridApi) {
            if (searchSize > 0) {
                this.gridApi.setDomLayout('autoHeight');
                // @ts-ignore
                // document.querySelector('#serviceGrid').style.height = '';
            } else {
                this.gridApi.setDomLayout('normal');
                // @ts-ignore
                // document.querySelector('#serviceGrid').style.height = '';
            }
        }
    }

    setDataToGrid(data: ServiceItem[])
    {
        if (this.gridApi) {
            this.hideLoading();
            this.gridApi.setRowData(data);
            this.setupGridHeight(data.length);
        } else {
            setTimeout(() => this.setDataToGrid(data), 0);
        }
    }

    public setupGroupingBasedOnQueueProfile(queueProfile: OperationQueueProfile, criteria: ServiceItemSearchCriteria)
    {
        switch (queueProfile) {
            case OperationQueueProfile.COMMON_PROFILE:
                criteria.groupByPlCode = true;
                criteria.groupByProductLevel = true;
                criteria.groupByRoute = true;
                criteria.groupByTransferMode = true;
                criteria.groupByHours = undefined;
                break;
            case OperationQueueProfile.TRS_PROFILE:
                criteria.groupByPlCode = true;
                criteria.groupByProductLevel = true;
                criteria.groupByRoute = true;
                criteria.groupByTransferMode = true;
                if (this.operationPanelConfig.timeGrouping) {
                    criteria.groupByHours = this.operationPanelConfig.timeGrouping;
                }
                break;
            case OperationQueueProfile.TOUR_PROFILE:
                criteria.groupByPlCode = true;
                criteria.groupByProductLevel = true;
                criteria.groupByRoute = true;
                criteria.groupByTransferMode = true;
                criteria.groupByHours = undefined;
                break;
        }
    }

    public refreshGridView()
    {
        if (this.gridApi) {
            setTimeout(() => {
                this.gridApi.refreshCells({force: true});
                this.gridApi.setRowData(this.serviceItemSearchResults);
            }, 0);

        }
    }

    public replaceItem(oldServiceItem: ServiceItem, replacingItems: ServiceItem[])
    {
        if (replacingItems.length > 0) {
            const newServiceItemSearchResults: ServiceItem[] = [];
            this.serviceItemSearchResults.forEach(item => {
                if (item.serviceId === oldServiceItem.serviceId) {
                    replacingItems.forEach(newItem => newServiceItemSearchResults.push(newItem));
                } else {
                    newServiceItemSearchResults.push(item);
                }
            });
            this.serviceItemSearchResults = newServiceItemSearchResults;
            this.setDataToGrid(this.serviceItemSearchResults);
            this.refreshGridView();
        }
    }

    public replaceWithAutoAllocatedServiceItems(serviceIdList: string[], replacingItems: ServiceItem[])
    {
        if (replacingItems.length > 0) {
            const newServiceItemSearchResults: ServiceItem[] = [];

            let index = 0;
            this.serviceItemSearchResults.forEach(oldItem => {
                let found = false;
                serviceIdList.forEach(oldId => {
                    if (oldItem.serviceId === oldId) {
                        found = true;
                        index = this.serviceItemSearchResults.indexOf(oldItem);
                    }
                });
                if (!found) {
                    newServiceItemSearchResults.push(oldItem);
                }
            });

            newServiceItemSearchResults.splice(index, 0, ...replacingItems);

            this.serviceItemSearchResults = newServiceItemSearchResults;
            this.setDataToGrid(this.serviceItemSearchResults);
            this.refreshGridView();
            this.selectRows(replacingItems);
        }
    }

    public selectColumnGroup(type: string)
    {
        this.gridApi.refreshHeader();
        if (type === 'primary') {
            this.columnDefsInput = this.primaryColumnDefsInput;

        } else if (type === 'secondary') {
            this.columnDefsInput = this.secondaryColumnDefsInput;
        }
        this.autoSizeAll(false);
    }

    public gridColumnVisibleChange(fieldName: string, isHide: boolean)
    {
        if (this.gridColumnApi) {
            this.gridColumnApi.setColumnVisible(fieldName, !isHide);
        }
        const val = (this.columnDefsInput.find(res => res.field === fieldName)).hide = isHide;
    }

    saveRowData(event: any)
    {
        this.saveRow(event.data, event).subscribe(
            success => {
                console.log(success);
                this.unsavedRows = this.getUnsavedUnselectedRows();
                this.gridApi.refreshInfiniteCache();
            },
            error => {
                console.log(error);
            }
        );
    }

    public saveRow(row: any, event: any)
    {
        const saveData = row ? row : event;
        return of();
        // this.reservationV2ManagementService.saveLocation(saveData)
        //            .pipe(tap(data => row.unsaved = undefined));
    }

    addUnSavedRowsAgainAfterReload()
    {
        if (this.unsavedRows && this.unsavedRows.length > 0) {
            this.addNewRows(this.unsavedRows);
            this.unsavedRows = [];
        }
    }

    getUnsavedUnselectedRows(): any[]
    {
        const unSelectedRows = [];
        this.gridApi.forEachNode((node: RowNode) => {
            console.log(node);
            if (!node.isSelected() && this.isUnsavedRow(node)) {
                // unSelectedRows.push(node.data);
            }
        });
        return unSelectedRows;
    }

    public isUnsavedRow(row: any): boolean {
        return !!row.unsaved;
    }

    afterCellValueChange = (event: CellValueChangedEvent) => {
        if (event.oldValue !== event.newValue) {
            console.log(event);
            this.saveRowData(event);
        }
    };

    addNewRow(newRow: any, selectRow: boolean, index = 0)
    {
        if (this.gridApi) {
            this.gridApi.updateRowData({add: [newRow], addIndex: index});
            if (selectRow) {
                this.selectRow(newRow);
            }
        } else {
            setTimeout(() => this.addNewRow(newRow, selectRow, index), 0);
        }
    }

    addNewRows(newRows: any[], index = 0)
    {
        newRows.forEach(newRow => {
            this.addNewRow(newRow, false, index);
        });
    }

    public selectRows(serviceItems: ServiceItem[])
    {
        this.gridApi.forEachNode((node) => {
            node.setSelected(serviceItems.includes(node.data));
        });
    }

    selectRow(row: any)
    {
        this.gridApi.forEachNode((node: RowNode) => {
            if (!node.isSelected()) {
                node.setSelected(node.data === row);
            } else {
                node.setSelected(true);
            }
        });
    }

    public isInvalidRow(params): boolean
    {
        const data = params.data;
        if (data) {
            let invalid = true;
            if (data.code && data.name && data.locationType) {
                invalid = false;
            }
            return invalid;
        } else {
            return false;
        }
    }

    public onRowClicked($event: any)
    {
        this.rowClicked.emit($event);
    }

    ngOnDestroy(): void
    {
        console.log('destroyed service view');
        this.operationV2DataProcessorService.autoAllocationButtonEnable = false;
        this.operationV2DataProcessorService.confirmButtonEnable = false;
        this.operationV2DataProcessorService.supplierConfirmButtonEnable = false;
        this.operationV2DataProcessorService.supplierRejectButtonEnable = false;
    }
}
