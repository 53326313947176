import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AdvVehicleAssignSearchResultComponent} from './adv-vehicle-assign-search-result.component';
import {DirectivesModule} from '@tc/directives';
import {PipesModule} from '@tc/pipes';
import {ContentStripModule} from '@tc/content-strip';
import {ResultContentCardResourceAssignModule} from '../result-content-card-resource-assign/result-content-card-resource-assign.module';
import {ExpandedContentCardModule} from '@tc/expanded-content-card';
import {ResourceExpandedContentHeaderModule} from '../resource-expanded-content-header/resource-expanded-content-header.module';
import {DataStore} from '@tc-core/util/framework/data-store.service';
import {CommonHelper} from '@tc-core/util/helpers/common-helper.service';
import {VehicleAssignDetailViewModule} from '../vehicle-assign-detail-view/vehicle-assign-detail-view.module';
import {ResourceAllocationCardModule} from '../../../business/resource-allocation-card/resource-allocation-card.module';

@NgModule({
    imports: [
        CommonModule,
        DirectivesModule,
        PipesModule,
        ContentStripModule,
        ResultContentCardResourceAssignModule,
        ExpandedContentCardModule,
        ResourceExpandedContentHeaderModule,
        VehicleAssignDetailViewModule,
        ResourceAllocationCardModule
    ],
    providers: [
        DataStore,
        CommonHelper
    ],
    declarations: [AdvVehicleAssignSearchResultComponent],
    exports: [AdvVehicleAssignSearchResultComponent],
})
export class AdvVehicleAssignSearchResultModule {
}
