import { ApprovalStatus } from '../user/user-service-profile';

export class UserApprovalData {
    approvalStatus: ApprovalStatus;
    activation = false;
    resourceId: number;
    supplierId: number;
    serviceTypeCode: string;
    kcParent: string;
    kcCompany: any;
    kcDivision: any;
    kcBrand: any;
    kcDistributionChannel: any;
}
