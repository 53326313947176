import * as tslib_1 from "tslib";
import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { User } from '@tc-core/model/it/codegen/tbx/api/authority/v2/users/user';
import { TcErrorType } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-error';
import { UserJourneyManager } from '@tc-core/util/framework';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { TCO } from '../../../constants';
import { NotificationToastService } from '../notification-toast/notification-toast.service';
import { BackgroundTaskEvent } from '../../../models/background/background-task-event';
import { TaskStatus } from '../../../models/background/task-status';
import { DocumentSearchCriteria } from '../../../models/criteria/document-search-criteria';
import { SortDirection } from '../../../models/helper/sort-direction';
import { MainType } from '../../../models/reservation-v2/main-type';
import { DocumentService } from '../../../services/backend-consumers/documents/document.service';
import { NotificationService } from '../../../services/backend-consumers/notifications/notification.service';
import { BackgroundTaskService } from '../../../services/util/change-detector/background-task.service';
import { DMCCommon } from '../../../services/util/common/dmc-common';
import { DataStoreService } from '../../../services/util/framework/data-store.service';
import { ResponseUtil } from '../../../services/util/response/response-util.service';
import { DMCLocalStorageService } from '../../../services/util/system/dmc-local-storage.service';
import { DocumentQueueService } from "../../../services/backend-consumers/documents/document-queue.service";
import { BackgroundTaskType } from "../../../models/background/background-task-type";
import { DocumentQueueSearchCriteria } from "../../../models/criteria/document-queue-search-criteria";
var HeaderComponent = /** @class */ (function () {
    function HeaderComponent(dataStore, userJourneyManager, notificationToastService, notificationService, backgroundTaskService, documentService, localStorage, common, documentQueueService) {
        this.dataStore = dataStore;
        this.userJourneyManager = userJourneyManager;
        this.notificationToastService = notificationToastService;
        this.notificationService = notificationService;
        this.backgroundTaskService = backgroundTaskService;
        this.documentService = documentService;
        this.localStorage = localStorage;
        this.common = common;
        this.documentQueueService = documentQueueService;
        this.languages = [];
        this.actions = [];
        this.themeSelection = new EventEmitter();
        this.toggleSidebar = new EventEmitter();
        this.changeLanguage = new EventEmitter();
        this.actionClick = new EventEmitter();
        this.logout = new EventEmitter();
        this.viewUserLoadedObjects = new EventEmitter();
        this.openUserProfile = new EventEmitter();
        this.userImageUrl = '';
        this.FEMALE_DEFAULT_IMAGE = 'assets/img/user-profile/female_user.svg';
        this.MALE_DEFAULT_IMAGE = 'assets/img/user-profile/male_user.svg';
        this.MALE_OR_FEMALE_DEFAULT_IMAGE = 'assets/img/user-profile/gender_unknown_user.svg';
        this.edited = false;
        this.HEADER_DIVIDER = ' > ';
        this.newNotificationData = [];
        this.newMessageList = [];
        this.showNotifications = false;
        this.showBackgroundTaskMenu = false;
        this.showBackgroundTaskRunning = false;
        this.showBackgroundTaskCompletion = false;
        this.showBackgroundTaskFailed = false;
        this.isDownloadable = true;
        this.backgroundTaskData = null;
        this.backgroundTask = null;
        this.hasUnreadDocs = false;
        this.notificationSubscription = new Subscription();
        this.notificationReadSubscription = new Subscription();
        this.manualBackgroundTaskSubscription = new Subscription();
        this.docQueueBackgroundTaskSubscription = new Subscription();
    }
    HeaderComponent.prototype.ngOnInit = function () {
        // notification listener
        this.setupIMageUrl();
        this.newNotification = false;
        this.getNewNotifications();
        this.updateUnReadMessageList();
        this.getUpdateBackgroundTasks();
    };
    HeaderComponent.prototype.setupIMageUrl = function () {
        if (this.user && this.user.userSummary && this.user.userSummary.additionalInfomation &&
            this.user.userSummary.additionalInfomation.imageUrl) {
            this.userImageUrl = this.user.userSummary.additionalInfomation.imageUrl;
        }
        else {
            this.setDefaultImage();
        }
    };
    HeaderComponent.prototype.setDefaultImage = function () {
        if (this.user && this.user.userSummary && this.user.userSummary.gender) {
            if (this.user.userSummary.gender === 'F') {
                this.userImageUrl = this.FEMALE_DEFAULT_IMAGE;
            }
            else if (this.user.userSummary.gender === 'M') {
                this.userImageUrl = this.MALE_DEFAULT_IMAGE;
            }
        }
        else {
            this.userImageUrl = this.MALE_OR_FEMALE_DEFAULT_IMAGE;
        }
    };
    HeaderComponent.prototype.onImageError = function ($event) {
        this.setDefaultImage();
    };
    HeaderComponent.prototype.setVisible = function () {
        this.edited = !this.edited;
    };
    HeaderComponent.prototype.onThemeSelect = function (event) {
        this.themeSelection.emit(event);
    };
    HeaderComponent.prototype.onToggleSidebar = function () {
        this.isSidebarOpen = !this.isSidebarOpen;
        this.toggleSidebar.emit(this.isSidebarOpen);
    };
    HeaderComponent.prototype.onActionClick = function (action) {
        if (!action.disable && !action.restricted) {
            this.actionClick.emit(action);
        }
    };
    HeaderComponent.prototype.onLogout = function () {
        this.logout.emit();
    };
    HeaderComponent.prototype.getMainHeader = function (heading) {
        /// MainHeading|@SUB|SubHeading
        return heading.split(HeaderComponent.SUB_HEADER_SPLITTER)[0];
    };
    HeaderComponent.prototype.getSubHeader = function (heading) {
        /// MainHeading|@SUB|SubHeading
        return heading.split(HeaderComponent.SUB_HEADER_SPLITTER)[1];
    };
    HeaderComponent.prototype.onViewUserLoadedObjects = function () {
        this.viewUserLoadedObjects.emit();
    };
    HeaderComponent.prototype.userProfileLoader = function () {
        this.openUserProfile.emit();
    };
    HeaderComponent.prototype.getNewNotifications = function () {
        var _this = this;
        this.notificationSubscription = this.notificationToastService.events.subscribe(function (event) {
            if (event) {
                if (_this.newNotificationData.length === 10) {
                    _this.newNotificationData.shift();
                }
                if (!event.toast.rawData.sendingDmc) {
                    _this.newNotificationData.push(event.toast.rawData);
                    _this.newNotification = true;
                }
            }
        });
    };
    HeaderComponent.prototype.updateUnReadMessageList = function () {
        var _this = this;
        this.notificationReadSubscription = this.notificationService.readMessageThread.subscribe(function (thread) {
            if (thread) {
                // thread.mostRecentMessages.forEach((message) => {
                //   this.newNotificationData.forEach((notification) => {
                //     if(message.notificationId === notification.notificationId) {
                //       this.newNotificationData = this.newNotificationData.splice(this.newNotificationData.indexOf(notification), 1);
                //     }
                //   });
                // });
                _this.newNotificationData = [];
            }
        });
    };
    HeaderComponent.prototype.getUpdateBackgroundTasks = function () {
        var _this = this;
        this.manualBackgroundTaskSubscription = this.backgroundTaskService.manualDocsToUi
            .subscribe(function (event) {
            if (event && event.task) {
                _this.hasUnreadDocs = false;
                _this.backgroundTask = event.task;
                if (event.task.taskStatus === TaskStatus.RUNNING) {
                    _this.showBackgroundTaskRunning = true;
                    _this.showBackgroundTaskCompletion = false;
                }
                if ((event.task.taskStatus === TaskStatus.FINISHED || event.task.taskStatus === TaskStatus.FAILED) && !event.data) {
                    _this.onTaskComplete(event.task);
                }
            }
        });
        this.docQueueBackgroundTaskSubscription = this.backgroundTaskService.docQueueToUi
            .subscribe(function (event) {
            if (event && event.task) {
                _this.hasUnreadDocs = false;
                _this.backgroundTask = event.task;
                if (event.task.taskStatus === TaskStatus.RUNNING) {
                    _this.showBackgroundTaskRunning = true;
                    _this.showBackgroundTaskCompletion = false;
                }
                if ((event.task.taskStatus === TaskStatus.FINISHED || event.task.taskStatus === TaskStatus.FAILED) && !event.data) {
                    _this.onTaskComplete(event.task);
                }
            }
        });
    };
    HeaderComponent.prototype.onTaskComplete = function (task) {
        var _this = this;
        if (task) {
            this.hasUnreadDocs = true;
            this.backgroundTask = task;
            if (task && task.createdFor && task.createdFor === BackgroundTaskType.MANUAL_DOC) {
                var documentSearchCriteria = new DocumentSearchCriteria(false, false);
                documentSearchCriteria.backgroundTaskId = task.taskId;
                documentSearchCriteria.dispatchStatus = null;
                documentSearchCriteria.mainType = null;
                documentSearchCriteria.size = 1000000000;
                this.documentService.searchDocuments(documentSearchCriteria)
                    .subscribe(function (result) {
                    var docs = ResponseUtil.getDataArray(result);
                    _this.backgroundTaskData = docs;
                    _this.isDownloadable = true;
                    _this.showBackgroundTaskRunning = false;
                    _this.showBackgroundTaskCompletion = true;
                    if (_this.backgroundTask && _this.backgroundTask.taskStatus
                        && _this.backgroundTask.taskStatus === TaskStatus.FINISHED) {
                        _this.common.showSnackBar("Background task id: " + _this.backgroundTask.taskId + " completed.", 3000, TcErrorType.TYPE.INFO);
                    }
                    else if (_this.backgroundTask && _this.backgroundTask.taskStatus
                        && _this.backgroundTask.taskStatus === TaskStatus.FAILED) {
                        _this.common.showSnackBar("Background task id: " + _this.backgroundTask.taskId + " failed.", 3000, TcErrorType.TYPE.ERROR);
                    }
                }, function (error) {
                    _this.backgroundTaskData(new BackgroundTaskEvent(task, null));
                });
            }
            else if (task && task.createdFor && task.createdFor === BackgroundTaskType.DOC_QUEUE) {
                var documentQueueSearchCriteria = new DocumentQueueSearchCriteria();
                documentQueueSearchCriteria.backgroundTaskId = task.taskId;
                documentQueueSearchCriteria.dispatchStatus = null;
                documentQueueSearchCriteria.mainType = null;
                documentQueueSearchCriteria.size = 1000000000;
                this.documentQueueService.searchDocumentQueues(documentQueueSearchCriteria)
                    .subscribe(function (result) {
                    var docs = ResponseUtil.getDataArray(result);
                    _this.backgroundTaskData = docs;
                    _this.showBackgroundTaskRunning = false;
                    _this.isDownloadable = false;
                    _this.showBackgroundTaskCompletion = true;
                    _this.documentQueueService.sendTaskStatus(TaskStatus.FINISHED);
                    if (_this.backgroundTask && _this.backgroundTask.taskStatus
                        && _this.backgroundTask.taskStatus === TaskStatus.FINISHED) {
                        _this.common.showSnackBar("Background task id: " + _this.backgroundTask.taskId + " completed.", 3000, TcErrorType.TYPE.INFO);
                    }
                    else if (_this.backgroundTask && _this.backgroundTask.taskStatus
                        && _this.backgroundTask.taskStatus === TaskStatus.FAILED) {
                        _this.common.showSnackBar("One or more transfer manifest email sending has been failed.", 3000, TcErrorType.TYPE.ERROR);
                    }
                }, function (error) {
                    _this.backgroundTaskData(new BackgroundTaskEvent(task, null));
                });
            }
        }
    };
    HeaderComponent.prototype.onClickNewNotification = function () {
        this.newNotification = false;
    };
    HeaderComponent.prototype.navigateToNotificationsPanel = function (indicator) {
        this.newNotificationData = [];
        this.onClickNewNotification();
        if (indicator === 'message') {
            this.userJourneyManager.goForKey('BEGIN_MESSAGE_QUEUE');
        }
        else {
            this.userJourneyManager.goForKey('BEGIN_NOTIFICATION_QUEUE');
        }
    };
    HeaderComponent.prototype.onBackgroundTaskClick = function () {
        this.showBackgroundTaskCompletion = !this.showBackgroundTaskCompletion;
        this.showBackgroundTaskMenu = false;
        this.showBackgroundTaskRunning = !this.showBackgroundTaskRunning;
    };
    HeaderComponent.prototype.onBackgroundDataDownloadButtonClick = function () {
        var e_1, _a;
        this.hasUnreadDocs = false;
        var docIds = [];
        try {
            for (var _b = tslib_1.__values(this.backgroundTaskData), _c = _b.next(); !_c.done; _c = _b.next()) {
                var doc = _c.value;
                docIds.push(doc.documentId);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        this.documentService.downloadDocuments(docIds);
        this.showBackgroundTaskMenu = false;
    };
    HeaderComponent.prototype.onOpenInManifestsButtonClick = function () {
        var _this = this;
        this.hasUnreadDocs = false;
        if (this.backgroundTask) {
            var criteria = new DocumentSearchCriteria(true, false);
            criteria.backgroundTaskId = this.backgroundTask.taskId;
            criteria.dispatchStatus = 'SUCCESS,FAILED,NOT_DISPATCHED,PENDING';
            criteria.mainType = MainType.ANY;
            criteria.startDate = moment().startOf('year').format('YYYY-MM-DD');
            criteria.endDate = moment().add(1, 'year').endOf('year').format('YYYY-MM-DD');
            criteria.sortDirection = SortDirection.ASC;
            this.localStorage.store(TCO.AppData.MANIFESTS_SEARCH_CRITERIA, criteria);
        }
        // this.showBackgroundTaskMenu = false;
        if (this.userJourneyManager.getCurrentPage() === 'MANIFESTS_PAGE') {
            this.userJourneyManager.goForKey('BEGIN_DUMMY');
            setTimeout(function () { return _this.userJourneyManager.goForKey('BEGIN_MANIFESTS'); }, 0);
        }
        else {
            this.userJourneyManager.goForKey('BEGIN_MANIFESTS');
        }
    };
    HeaderComponent.prototype.onBackgroundDataClearButtonClick = function () {
        this.hasUnreadDocs = false;
        this.showBackgroundTaskMenu = false;
        this.showBackgroundTaskRunning = false;
        this.showBackgroundTaskCompletion = false;
        this.backgroundTask = null;
        this.backgroundTaskData = null;
    };
    HeaderComponent.prototype.onBackgroundTaskCancelButtonClick = function () {
        this.hasUnreadDocs = false;
        if (this.backgroundTask) {
            this.backgroundTaskService.cancelStatusChecking(this.backgroundTask);
            this.showBackgroundTaskMenu = false;
            this.showBackgroundTaskRunning = false;
            this.showBackgroundTaskCompletion = false;
            this.backgroundTask = null;
            this.backgroundTaskData = null;
        }
    };
    HeaderComponent.prototype.ngOnDestroy = function () {
        if (this.notificationSubscription) {
            this.notificationSubscription.unsubscribe();
        }
        if (this.notificationReadSubscription) {
            this.notificationReadSubscription.unsubscribe();
        }
        if (this.manualBackgroundTaskSubscription) {
            this.manualBackgroundTaskSubscription.unsubscribe();
        }
        if (this.docQueueBackgroundTaskSubscription) {
            this.manualBackgroundTaskSubscription.unsubscribe();
        }
    };
    HeaderComponent.SUB_HEADER_SPLITTER = '|@SUB|';
    return HeaderComponent;
}());
export { HeaderComponent };
