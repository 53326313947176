import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { GenQueueUtil } from '../../../business/generic-reservation-management/gen-reservation-search-results/gen-queue-util';
import { Pax } from '../../../models/common/pax';
import { CostCalculationUtilsService } from '../../business-utils/cost-calculation/cost-calculation-utils.service';
import { DataKey, DataStoreService } from '../framework/data-store.service';
import { ResponseUtil } from '../response/response-util.service';

@Injectable({
    providedIn: 'root'
})
export class GenQueueGridDataProcessorService {
    
    rowHeight = 45;
    EMPTY_STRING = '--';
    resourceTypes = [];

    private resourceTypesSubscription: Subscription = new Subscription();

    constructor(
        private dataStore: DataStoreService,
        private costCalculationUtil: CostCalculationUtilsService
    ) {
        this.getAllResourceTypes();
    }

    public getAllResourceTypes() {
        this.resourceTypesSubscription = this.dataStore.get(DataKey.resourceTypes).subscribe(response => {
            if (response != null) {
                this.resourceTypesSubscription.unsubscribe();
                this.resourceTypes = ResponseUtil.getArray(response);
            }
        });
    }

    getFromConsumer = (handlerObj: any, consumer: any) => {
        if (consumer && handlerObj.handlerParam) {
            const item = _.get(consumer, handlerObj.handlerParam);
            if (item && handlerObj.type && handlerObj.type === 'function') {
                return item.bind(consumer);
            }  else {
                if (!item) {
                    if (consumer.constructor && consumer.constructor.name) {
                        console.error(handlerObj.handlerParam + ' not found in ' + consumer.constructor.name);
                    } else {
                        console.error(handlerObj.handlerParam + ' not found in ' + consumer);
                    }
                }
                return item;
            }
        }
    };

    showAssignFocusView = function(handlerObj: any, consumer: any) {
        if (consumer && handlerObj['handlerParam']) {
            const item = _.get(consumer, handlerObj['handlerParam']);
            if (handlerObj['type'] && handlerObj['type'] === 'function') {
                return item.bind(consumer);
            } else {
                return item;
            }
        }
    };

    getDateAndTour = function(handlerObj: any, consumer: any) {
        if (consumer && handlerObj['handlerParam']) {
            const item = _.get(consumer, handlerObj['handlerParam']);
            if (handlerObj['type'] && handlerObj['type'] === 'function') {
                return item.bind(consumer);
            } else {
                return item;
            }
        }
    };

    tourCellRowSpan = function(params) {
        if (params.data && params.data['tourCode']) {
            return params.node.allChildrenCount;
        } else {
            return 1;
        }
    };

    showCellTour = function(params) {
        return params && params.value !== undefined && GenQueueUtil.isTourItineraryRow(params);
    };

    havingMultiChildren = function(params) {
        return params.node.allChildrenCount > 1;
    };

    showCellTourItem = function(params) {
        return params && params.value !== undefined && GenQueueUtil.isTourItemRow(params);
    };

    categoryCellRowSpan = function(params) {
        if (params.data && params.data['categoryCode']) {
            return params.node.allChildrenCount;
        } else {
            return 1;
        }
    };

    getHeight = function(params) {
        return {'height': (params.node.allChildrenCount * this.height) + 'px !important'};
    };

    valueSetter = function(params) {
        _.set(params.data, params.colDef.cellEditorParams.rowObject, params.newValue); // calling lodash deep property value set
        return true;
    };

    numericSetter = function(params) {
        if (params.newValue > 0 || params.newValue === '') {
            _.set(params.data, params.colDef.field, params.newValue); // calling lodash deep property value set
            return true;
        }
        return false;
    };

    currencyCellRenderer = function(params: any) {
        params.data.currency = params.data.currency ? params.data.currency : 'AED';
        if (params.data && params.data.currency && params.value) {
            const inrFormat = new Intl.NumberFormat('en-Us', {
                style: 'currency',
                currency: params.data.currency.code ?
                    params.data.currency.code :
                    params.data.currency,
                minimumFractionDigits: 2
            });
            // return inrFormat.format(params.value).replace(/[a-z]{3}/i, '').trim();
            return inrFormat.format(params.value);
        } else {
            return this.EMPTY_STRING;
        }
    };

    normalCellValueRenderer = function(params) {
        if (params.value) {
            return params.value;
        } else {
            return this.EMPTY_STRING;
        }
    };

    tourRowSpan = function(params) {
        if (GenQueueUtil.isTourItineraryRow(params)) {
            return params.node.allChildrenCount;
        } else {
            return 0;
        }
    };

    tourItemRowSpan = function(params) {
        if (GenQueueUtil.isTourItemRow(params)) {
            return params.node.allChildrenCount;
        } else {
            return 0;
        }
    };

    getHeightFixBorder = function(params) {
        return {
            'height': (params.node.allChildrenCount * this.rowHeight) + 'px !important',
            // 'justify-content': 'flex-end',
            'border-width': (params.node.allChildrenCount) === 0 ? '0' : '1px'
        };
    };

    getHeightFixBorderFlexEnd = function(params) {
        return {
            'height': (params.node.allChildrenCount * this.rowHeight) + 'px !important',
            'justify-content': 'flex-end',
            'border-width': (params.node.allChildrenCount) === 0 ? '0' : '1px'
        };
    };

    getTotalCountInCategoryTypeText = function(params: any) {
        let pax: Pax;
        if (GenQueueUtil.isTourItemRow(params)) {
            pax = this.getCountByPax(params.data);
        }
        if (pax) {
            const text = pax.total + ' (' + pax.adults + ' ' + pax.children + ' ' + pax.infants + ')';
            return text;
        } else {
            return '';
        }
    };

    getTotalCountInAssignment = function(params: any) {
        let total = 0;
        if (GenQueueUtil.isAssignmentRow(params)) {
            total = params.data.adults + params.data.children + params.data.infants;
        }
        return total;
    };

    getTotalCountInAssignmentDetailed = function(params: any) {
        let text = '';
        if (GenQueueUtil.isAssignmentRow(params)) {
            const pax = this.getCountByPax(params.data);
            text = pax.total + ' (' + pax.adults + ' ' + pax.children + ' ' + pax.infants + ')';
        }
        return text;
    };

    getAllocatedCount = function(params: any) {
        let assignedCount = 0;
        let adults = 0;
        let teens = 0;
        let children = 0;
        let infants = 0;
        if (GenQueueUtil.isTourItemRow(params)) {
            if (params.data.assignments) {
                for (let i = 0; i < params.data.assignments.length; i++) {
                    const assignment = params.data.assignments[i];
                    if (assignment && assignment.supplierId) {
                        const countByPax = this.getCountByPax(assignment);
                        assignedCount += countByPax.total;
                        adults += countByPax.adults;
                        teens += countByPax.teens;
                        children += countByPax.children;
                        infants += countByPax.infants;
                    }

                }
            }
        }
        const text = assignedCount + ' (' + adults + ' ' + children + ' ' + infants + ')';
        return text;
    };

    displayObjName = function(option) {
        if (option && option.name) {
            return option.name;
        } else {
            return '';
        }
    };

    displayResourceObjName = function(option) {
        if (option && option.firstName) {
            return option.firstName + ' ' + option.lastName;
        } else {
            return '';
        }
    };

    displaySupplierObjName = (option) => {
        if (option && option.firstName) {
            return option.firstName + ' ' + option.lastName;
        } else {
            return '';
        }
    };

    getTotalCost = function(params: any) {
        if (GenQueueUtil.isAssignmentRow(params)) {
            return this.costCalculationUtil.getGenSupplierTotalCost(params.data);
        } else {
            return 0;
        }
    };

    noClientSideSortComparator = function(valueA, valueB, nodeA, nodeB, isInverted) {
        return 0;
    };

    private getCountByPax(pax: any): Pax {
        const paxObj = new Pax();
        if (!isNaN(pax.adults)) {
            paxObj.adults += Number(pax.adults);
        }
        if (!isNaN(pax.teens)) {
            paxObj.teens += Number(pax.teens);
        }
        if (!isNaN(pax.children)) {
            paxObj.children += Number(pax.children);
        }
        if (!isNaN(pax.infants)) {
            paxObj.infants += Number(pax.infants);
        }
        paxObj.total = paxObj.adults + paxObj.teens + paxObj.children + paxObj.infants;
        return paxObj;
    }

}
