import {KeyControlAndAbstractSearchCriteria} from './key-control-and-abstract-search-criteria';

export class JobSearchCriteria extends KeyControlAndAbstractSearchCriteria
{
    jobName = '';
    startTime = '';
    finishedTime = '';
    jobHistoryStatus = 'ANY';
    lockStatus = 'ANY'; // lock status
}
