/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../setup/locations-grid-setup-search-criteria/locations-grid-setup-search-criteria.component.ngfactory";
import * as i2 from "../../services/helpers/chip-handler.service";
import * as i3 from "@tc/chip-input/chip-input.service";
import * as i4 from "@tc/chip-input/shared/parser.service";
import * as i5 from "@tc-core/util/system/date-formatter.service";
import * as i6 from "../../services/util/framework/data-store.service";
import * as i7 from "@tc-core/util/framework/config-loader.service";
import * as i8 from "@tc-core/util/helpers/common-helper.service";
import * as i9 from "../../services/backend-consumers/master-data-handler-service/data-handler.service";
import * as i10 from "../setup/locations-grid-setup-search-criteria/locations-grid-setup-search-criteria.component";
import * as i11 from "@angular/router";
import * as i12 from "@tc-core/util/framework/user-journey-manager.service";
import * as i13 from "../../services/util/framework/action-handler-service.service";
import * as i14 from "@tc-core/util/framework/event-manager.service";
import * as i15 from "../../services/util/framework/dmc-query-params.service";
import * as i16 from "@tc-core/util/ui/spinner.service";
import * as i17 from "../../services/util/core-services/root.service";
import * as i18 from "../../widgets/framework/ag-grid-custom-components/components/grouping-setup-grid/grouping-setup-grid.component.ngfactory";
import * as i19 from "../../widgets/framework/ag-grid-custom-components/components/grouping-setup-grid/grouping-setup-grid.component";
import * as i20 from "../../services/util/pre-processors/grid-column-definition-processor.service";
import * as i21 from "@ngx-translate/core";
import * as i22 from "./location-selection-focus-view.component";
import * as i23 from "../../services/backend-consumers/setups/locations.service";
import * as i24 from "@tc-core/util/framework/focus-view-manager.service";
import * as i25 from "../../services/util/common/dmc-common";
import * as i26 from "@tc/focus-view/focus-view.service";
var styles_LocationSelectionFocusViewComponent = [];
var RenderType_LocationSelectionFocusViewComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_LocationSelectionFocusViewComponent, data: {} });
export { RenderType_LocationSelectionFocusViewComponent as RenderType_LocationSelectionFocusViewComponent };
export function View_LocationSelectionFocusViewComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { pageSize: 0 }), (_l()(), i0.ɵeld(1, 0, null, null, 4, "tc-locations-grid-setup-search-criteria", [], null, null, null, i1.View_LocationsGridSetupSearchCriteriaComponent_0, i1.RenderType_LocationsGridSetupSearchCriteriaComponent)), i0.ɵprd(6144, null, "ChipHandlerService", null, [i2.ChipHandlerService]), i0.ɵprd(4608, null, i3.ChipInputService, i3.ChipInputService, [i4.Parser, i5.DateFormatter]), i0.ɵprd(512, null, i2.ChipHandlerService, i2.ChipHandlerService, [i0.Injector, i6.DataStoreService, i7.ConfigLoader, i8.CommonHelper, i5.DateFormatter, i9.DataHandlerService]), i0.ɵdid(5, 245760, null, 0, i10.LocationsGridSetupSearchCriteriaComponent, [i11.ActivatedRoute, i7.ConfigLoader, i12.UserJourneyManager, i6.DataStoreService, i13.ActionHandlerService, i14.EventManager, i2.ChipHandlerService, i15.DMCQueryParamsService, i8.CommonHelper, i16.SpinnerService, i17.RootService], null, null), (_l()(), i0.ɵeld(6, 0, null, null, 1, "tc-grouping-setup-grid", [], null, null, null, i18.View_GroupingSetupGridComponent_0, i18.RenderType_GroupingSetupGridComponent)), i0.ɵdid(7, 638976, null, 0, i19.GroupingSetupGridComponent, [i20.GridColumnDefinitionProcessorService, i0.ChangeDetectorRef, i21.TranslateService], { rowDataRight: [0, "rowDataRight"], identityFunction: [1, "identityFunction"], setupGridComp: [2, "setupGridComp"], coldefConfigLeft: [3, "coldefConfigLeft"], coldefConfigRight: [4, "coldefConfigRight"], rowDataLeft: [5, "rowDataLeft"], autoSize: [6, "autoSize"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 5, 0); var currVal_0 = _co.rightSideData; var currVal_1 = _co.identityFunction; var currVal_2 = _co; var currVal_3 = _co.colDefConfigLeft; var currVal_4 = _co.colDefConfigRight; var currVal_5 = _co.leftSideData; var currVal_6 = true; _ck(_v, 7, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
export function View_LocationSelectionFocusViewComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-location-selection-focus-view", [], null, null, null, View_LocationSelectionFocusViewComponent_0, RenderType_LocationSelectionFocusViewComponent)), i0.ɵdid(1, 114688, null, 0, i22.LocationSelectionFocusViewComponent, [i23.LocationsService, i6.DataStoreService, i7.ConfigLoader, i12.UserJourneyManager, i24.FocusViewManager, i8.CommonHelper, i16.SpinnerService, i25.DMCCommon, i26.FocusViewService, i14.EventManager], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LocationSelectionFocusViewComponentNgFactory = i0.ɵccf("tc-location-selection-focus-view", i22.LocationSelectionFocusViewComponent, View_LocationSelectionFocusViewComponent_Host_0, { rightSideData: "rightSideData", locationGroup: "locationGroup" }, {}, []);
export { LocationSelectionFocusViewComponentNgFactory as LocationSelectionFocusViewComponentNgFactory };
