import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TcErrorType } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-error';
import { TC } from '@tc-core/util';
import { ConfigLoader } from '@tc-core/util/framework';
import { IGetRowsParams } from 'ag-grid-community';
import { Observable, of, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SetupGridComp } from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid-comp';
import { SetupGridDataSource } from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid-data-source';
import { SetupGridComponent } from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid.component';
import { TCO } from '../../../constants';
import { ProfileTypeSearchCriteria } from '../../../models/criteria/profile-type-search-criteria';
import { ResourceTypeSearchCriteria } from '../../../models/criteria/resource-type-search-criteria';
import { SortDirection } from '../../../models/helper/sort-direction';
import { DataHandlerService } from '../../../services/backend-consumers/master-data-handler-service/data-handler.service';
import { ResourceTypeService } from '../../../services/backend-consumers/setups/resource-type.service';
import { DMCCommon } from '../../../services/util/common/dmc-common';
import { DataKey, DataStoreService } from '../../../services/util/framework/data-store.service';
import EMPTY_CHAR = TC.FORMAT.EMPTY_CHAR;

@Component({
  selector: 'tc-profile-type-setup',
  templateUrl: './profile-type-setup.component.html'
})
export class ProfileTypeSetupComponent extends SetupGridComp implements OnInit, OnDestroy, SetupGridDataSource  {

  @ViewChild('setupGrid')
  private setupGrid: SetupGridComponent;
  public colDefConfig = [];

  private profileTypeSearchCriteria: ProfileTypeSearchCriteria = new ProfileTypeSearchCriteria();

  allProfileTypeCodes = [];

  // required options for grid editors
  // nothing

  private searchCriteriaObserver: Subscription = new Subscription();

  constructor(
      private configLoader: ConfigLoader,
      private dataStoreService: DataStoreService,
      private dataHandlerService: DataHandlerService,
      private resourceTypeService: ResourceTypeService,
      private common: DMCCommon,
  ) {
    super();
  }

  ngOnInit() {
    this.colDefConfig = this.configLoader.configurations.get(TCO.CONF.CONF_PROFILE_TYPE_SETUP);
    this.subscribeSearchCriteria();
    this.fillRequiredGridOptionData();
    this.fillDataKeyForEditorData();
    this.getProfileTypeCodes();
  }

  /**
   * subscribe search criteria changes in search bar
   * then force grid to refresh data
   * grid refresh will be call getRaws method which is using this subscribed criteria for backend call
   */
  subscribeSearchCriteria() {
    this.searchCriteriaObserver = this.dataStoreService.get(DataKey.profileTypeSearchCriteria)
                                      .subscribe(value => {
                                        this.profileTypeSearchCriteria = value;
                                        this.setupGrid.runForceSearch();
                                      });
  }

  private fillRequiredGridOptionData() {
    this.addResourceTypes();
  }

  private fillDataKeyForEditorData() {
    this.addProfileTypes();
  }

  getProfileTypeCodes() {
    this.resourceTypeService.getProfileTypeCodes().subscribe(
        response => {
          this.allProfileTypeCodes = response.data;
        }
    );
  }

  private addResourceTypes() {
    const resourceTypeSearchCriteria = new ResourceTypeSearchCriteria();
    resourceTypeSearchCriteria.size = 1000000;
    this.resourceTypeService.getResourceTypes(resourceTypeSearchCriteria).subscribe(
        result => {
          this.dataStoreService.set(DataKey.resourceTypes, result.data, true);
        });
  }

  private addProfileTypes() {
    const profileTypeSearchCriteria = new ProfileTypeSearchCriteria();
    profileTypeSearchCriteria.size = 1000000;
    this.resourceTypeService.getProfileTypes(profileTypeSearchCriteria).subscribe(
        result => {
          this.dataStoreService.set(DataKey.profileTypes, result.data, true);
        });
  }

  public isInvalidRow(params): boolean {
    const data = params.data;
    if (data) {
      let invalid = true;
      if (
          data.code &&
          data.name &&
          data.resourceType && data.resourceType.code
      ) {
        invalid = false;
      }
      return invalid;
    } else {
      return false;
    }
  }

  public createNewRow(): any {
    return {unsaved: true};
  }

  public isUnsavedRow(row: any): boolean {
    return !!row.unsaved;
  }

  public deleteRow(row: any) {
    if (row && row.code) {
      return this.resourceTypeService.deleteProfileType(row.code);
    } else {
      return of('success');
    }
  }

  public saveRow(row: any, event: any) {
    if (row.unsaved) {
      if (this.allProfileTypeCodes.includes(row.code)) {
        this.common.showSnackBar(
            'Profile Type Code Already Exist. Please Select a Different Code',
            3000,
            TcErrorType.TYPE.ERROR
        );
      } else {
        return this.resourceTypeService.saveProfileType(row)
                   .pipe(tap(data => row.unsaved = undefined));
      }
    } else {
      return this.resourceTypeService.saveProfileType(row)
                 .pipe(tap(data => row.unsaved = undefined));
    }
  }

  public getRows(params: IGetRowsParams): Observable<any> {
    console.log('fetch requesting for ' + params.startRow + ' to ' + params.endRow);
    console.log(params.sortModel);

    const pageSize = params.endRow - params.startRow;
    if (!this.profileTypeSearchCriteria) {
      this.profileTypeSearchCriteria = new ProfileTypeSearchCriteria();
    }
    this.profileTypeSearchCriteria.start = params.startRow;
    this.profileTypeSearchCriteria.size = pageSize;
    if (params.sortModel && params.sortModel[0]) {
      this.profileTypeSearchCriteria.sortBy = params.sortModel[0].colId;
      if (params.sortModel[0].sort === SetupGridComp.GRID_SORT_ASCENDING) {
        this.profileTypeSearchCriteria.sortDirection = SortDirection.ASC;
      } else {
        this.profileTypeSearchCriteria.sortDirection = SortDirection.DESC;
      }
    } else {
      this.profileTypeSearchCriteria.sortBy = 'createdOn';
      this.profileTypeSearchCriteria.sortDirection = SortDirection.ASC;
    }
    return this.resourceTypeService.getProfileTypes(this.profileTypeSearchCriteria)
               .pipe(
                   tap(data =>
                       this.dataStoreService.set(DataKey.profileTypeSearchResultsForCriteria, data)
                   )
               );
  }

  public ngOnDestroy(): void {
    if (this.searchCriteriaObserver) {
      this.searchCriteriaObserver.unsubscribe();
    }
  }

  /*
   grid config methods which are called by grid setup (ag grid)
   and set by grid config
   */

  getResourceTypeText = (params) => {
    let groupText = EMPTY_CHAR;
    if (params && params.data && params.data.resourceType) {
      if (params.data.resourceType.code) {
        groupText = params.data.resourceType.code;
      } else {
        groupText = EMPTY_CHAR;
      }
      groupText += ' | ';
      if (params.data.resourceType.name) {
        groupText += params.data.resourceType.name;
      } else {
        groupText += EMPTY_CHAR;
      }
    }
    return groupText;
  };


  getResourceTypes = (params, rowData, text) => {
    return this.dataStoreService.get(DataKey.resourceTypes)
               .map(
                   aEvents =>
                       aEvents.filter(aEvent => {
                             if (text) {
                               if (aEvent.name) {
                                 return aEvent.name.toLowerCase().indexOf(text.toLocaleString()) > -1;
                               }
                             } else {
                               return true;
                             }
                           }
                       )
               );
  };

}
