export class BookingItemPatch {
    serviceStartDateTime: Date;
    serviceEndDateTime: Date;
    nights: number;
    distance: number;
    transferMode: string;
    route: string;
    primaryServiceTypeCode: string;
    primaryServiceTypeName: string;
    pvtShared: string;

    flightDeptTime: Date;
    departureFlightNo: string;
    flightDeptTerminal: string;

    flightArrTime: Date;
    arrivalFlightNo: string;
    flightArrTerminal: string;

    pickupPointName: string;
    pickupPointCode: string;
    dropOffPointName: string;
    dropOffPointCode: string;

}
