import * as tslib_1 from "tslib";
import { DoCheck, EventEmitter, KeyValueDiffer, KeyValueDiffers, OnInit } from '@angular/core';
import { CurrencyFormat } from '@tc-core/model/it/codegen/ui/framework/currency-format';
import { Icon } from '@tc-core/model/it/codegen/ui/framework/icon';
import { TC } from '@tc-core/util';
import { ConfigLoader } from '@tc-core/util/framework';
import { TCO } from '../../constants';
import { TimelineEvent } from '../../widgets/framework/cg-timeline/timeline-event';
import { ServiceItemChanges } from '../../models/helper/service-item-changes';
import { SearchResultSummary } from '../../models/reservation/search-result-summary';
import { ResourceAvailability } from '../../models/resource/resource-availability';
import { DriverSupplierCostSummary } from "../../models/summary/driver-supplier-cost-summary";
var ResourceAllocationCardGenComponent = /** @class */ (function () {
    function ResourceAllocationCardGenComponent(configLoader, differs) {
        this.configLoader = configLoader;
        this.differs = differs;
        this.isRow1col4String = false;
        this.isBulkAssignment = false;
        this.resourceCalendarClick = new EventEmitter();
        this.eventClick = new EventEmitter();
        this.targetClick = new EventEmitter();
        this.isUpdated = false;
        this.isCancelled = false;
        this.assignments = [];
        this.timelineEvents = [];
        this.leftBufferHours = -1;
        this.rightBufferHours = -1;
        this.isTargetBlock = false;
        this.targetPointTime = new Date();
        this.targetBlockTimeStart = null;
        this.targetBlockTimeEnd = null;
        this.row1col1_customsStyles = [];
        this.row1col2_customsStyles = [];
        this.row1col3_customsStyles = [];
        this.row1col4_customsStyles = [];
        this.row2col1_customsStyles = [];
        this.row2col2_customsStyles = [];
        this.row2col3_customsStyles = [];
        this.row2col4_customsStyles = [];
        this.row3col1_customsStyles = [];
        //timepickers
        this.theme = {
            dial: {
                dialBackgroundColor: '#3f51b5'
            },
            container: {
                buttonColor: '#3f51b5'
            },
            clockFace: {
                clockHandColor: '#3f51b5'
            }
        };
    }
    ResourceAllocationCardGenComponent.prototype.ngOnInit = function () {
        this.mapToObject();
        this.createTimelineEvents();
        this.initTimeline();
        this.createTargetPointOrBlock();
        this.overrideStyles();
        this.currencyFormat = this.getCurrencyFormat();
        this.summaryCardDiffer = this.differs.find(this.summaryContent).create();
    };
    ResourceAllocationCardGenComponent.prototype.mapToObject = function () {
        var resourceSummaryContent = this.summaryContent;
        var supplierData = new DriverSupplierCostSummary();
        // this.summaryContent.resourceName = resourceSummaryContent.resourceName;
        supplierData.adultFare = resourceSummaryContent.resourceSupplier.resourceCostSummaries[0].adultFare;
        supplierData.childFare = resourceSummaryContent.resourceSupplier.resourceCostSummaries[0].childFare;
        supplierData.infantFare = resourceSummaryContent.resourceSupplier.resourceCostSummaries[0].infantFare;
        supplierData.unitFare = resourceSummaryContent.resourceSupplier.resourceCostSummaries[0].unitFare;
        supplierData.resourceType = resourceSummaryContent.resourceSupplier.resourceCostSummaries[0].resourceType;
        supplierData.city = resourceSummaryContent.resourceSupplier.city;
        supplierData.code = resourceSummaryContent.resourceSupplier.code;
        supplierData.country = resourceSummaryContent.resourceSupplier.country;
        supplierData.name = resourceSummaryContent.resourceSupplier.name;
        if (supplierData.unitFare) {
            supplierData.isUnitFare = true;
            supplierData.totalCost = supplierData.unitFare;
        }
        else {
            supplierData.isUnitFare = false;
            supplierData.isUnitFare = true;
            var costs = {};
            var serviceItems = this.bookingData.serviceItems;
            var adult_1 = 0;
            var child_1 = 0;
            var infant_1 = 0;
            serviceItems.forEach(function (item) {
                // if(item.checked){
                adult_1 = adult_1 + item.adult;
                child_1 = child_1 + item.child;
                infant_1 = infant_1 + item.infant;
                // }
            });
            costs.adultFare = (supplierData.adultFare * adult_1);
            costs.childFare = (supplierData.childFare * child_1);
            costs.infantFare = (supplierData.infantFare * infant_1);
            costs.totalCost = costs.adultFare + costs.childFare +
                costs.infantFare;
            supplierData.totalCost = costs.totalCost;
        }
        this.summaryContent.driverSupplier = supplierData;
        this.summaryContent.capacity = 0;
        this.summaryContent.resourceCost = supplierData.totalCost;
    };
    ResourceAllocationCardGenComponent.prototype.ngDoCheck = function () {
        this.mapToObject();
        var changes = this.summaryCardDiffer.diff(this.summaryContent);
        if (changes) {
            this.createTimelineEvents();
        }
    };
    ResourceAllocationCardGenComponent.prototype.initTimeline = function () {
        var timelineConfig = this.configLoader.configurations.get(TCO.CONF.CONF_TIMELINE_UNIT_CONFIG)['resource-allocation-timeline'];
        this.leftBufferHours = timelineConfig.leftBufferHours;
        this.rightBufferHours = timelineConfig.rightBufferHours;
    };
    ResourceAllocationCardGenComponent.prototype.overrideStyles = function () {
        this.row1col1_customsStyles = ['tc-strip-text--primary'];
        this.row1col2_customsStyles = ['tc-strip-text--primary'];
        this.row1col3_customsStyles = [];
        this.row1col4_customsStyles = [
            'tc-strip-text--right',
            'tc-strip-text--primary',
            'tc-strip__price',
            'tc-strip__price--x-large'
        ];
        this.row2col1_customsStyles = [];
        this.row2col2_customsStyles = ['tc-strip-text--primary'];
        this.row2col3_customsStyles = [];
        this.row2col4_customsStyles = ['tc-strip-text__label', 'tc-strip-text--right'];
    };
    ResourceAllocationCardGenComponent.prototype.getCurrencyFormat = function () {
        var currencyFormat;
        var currencySymbolPattern = this.configLoader.configurations.get(TC.CONF.CONF_SYSTEM).defaults.default_currency_symbol_pattern;
        currencyFormat = new CurrencyFormat(TCO.AppData.DEF_CURRENCY_TYPE, currencySymbolPattern);
        return currencyFormat;
    };
    ResourceAllocationCardGenComponent.prototype.createTimelineEvents = function () {
        var e_1, _a;
        this.assignments = this.summaryContent.assignments ? this.summaryContent.assignments : [];
        this.timelineEvents = [];
        try {
            for (var _b = tslib_1.__values(this.assignments), _c = _b.next(); !_c.done; _c = _b.next()) {
                var assignment = _c.value;
                this.defaultJobStartTime = assignment.startTime;
                this.defaultJobEndTime = assignment.endTime;
                var timelineEvent = new TimelineEvent();
                timelineEvent.startTime = assignment.startTimeObj;
                timelineEvent.endTime = assignment.endTimeObj;
                timelineEvent.waitingEndTime = assignment.waitingEndTimeObj;
                timelineEvent.assignment = assignment;
                this.timelineEvents.push(timelineEvent);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
    };
    ResourceAllocationCardGenComponent.prototype.createTargetPointOrBlock = function () {
        if (this.isBulkAssignment) {
            this.targetBlockTimeStart = this.itemGroup.startTimeObj;
            this.targetBlockTimeEnd = this.itemGroup.endTimeObj;
        }
        else {
            this.targetPointTime = new Date(this.bookingData.serviceItems[0].serviceDate);
        }
    };
    ResourceAllocationCardGenComponent.prototype.onResourceCalendarClick = function (resource) {
        this.resourceCalendarClick.emit(resource);
    };
    ResourceAllocationCardGenComponent.prototype.onTargetClick = function (target) {
        this.targetClick.emit(this.summaryContent);
    };
    ResourceAllocationCardGenComponent.prototype.onEventClick = function (event) {
        this.eventClick.emit(event.assignment);
    };
    ResourceAllocationCardGenComponent.prototype.setTimes = function ($event, isStartTime) {
        var _this = this;
        var time = $event;
        this.jobStartTime = this.defaultJobStartTime;
        this.jobEndTime = this.defaultJobEndTime;
        var timeArr = [];
        if (time != null && time != "") {
            if (isStartTime) {
                timeArr = time.split(':');
                this.jobStartTime = time;
            }
            else {
                timeArr = time.split(':');
                this.jobEndTime = time;
            }
            if (timeArr.length > 1 && this.timelineEvents.length > 0) {
                this.timelineEvents.forEach(function (timeline, index) {
                    if (isStartTime) {
                        timeline.assignment.startTime = _this.jobStartTime;
                        timeline.assignment.startTimeObj.setHours(+timeArr[0]);
                        timeline.assignment.startTimeObj.setMinutes(+timeArr[1]);
                        timeline.startTime.setHours(+timeArr[0]);
                        timeline.startTime.setMinutes(+timeArr[1]);
                        _this.summaryContent.assignments[index].startTime = _this.jobStartTime;
                        _this.summaryContent.assignments[index].startTimeObj.setHours(+timeArr[0]);
                        _this.summaryContent.assignments[index].startTimeObj.setMinutes(+timeArr[1]);
                    }
                    else {
                        timeline.assignment.endTime = _this.jobEndTime;
                        timeline.assignment.endTimeObj.setHours(+timeArr[0]);
                        timeline.assignment.endTimeObj.setMinutes(+timeArr[1]);
                        timeline.endTime.setHours(+timeArr[0]);
                        timeline.endTime.setMinutes(+timeArr[1]);
                        _this.summaryContent.assignments[index].endTime = _this.jobEndTime;
                        _this.summaryContent.assignments[index].endTimeObj.setHours(+timeArr[0]);
                        _this.summaryContent.assignments[index].endTimeObj.setMinutes(+timeArr[1]);
                    }
                });
            }
        }
    };
    ResourceAllocationCardGenComponent.prototype.onInputChange = function ($event, isStartTime) {
        var _this = this;
        var time = $event.target.value;
        this.jobStartTime = this.defaultJobStartTime;
        this.jobEndTime = this.defaultJobEndTime;
        var timeArr = [];
        if (time != null && time != "") {
            if (/^([2][0-3]|[01]?[0-9])([.:][0-5][0-9])?$/.test(time)) {
                if (isStartTime) {
                    timeArr = time.split(':');
                    this.jobStartTime = time;
                }
                else {
                    timeArr = time.split(':');
                    this.jobEndTime = time;
                }
                if (timeArr.length > 1 && this.timelineEvents.length > 0) {
                    this.timelineEvents.forEach(function (timeline, index) {
                        if (isStartTime) {
                            timeline.assignment.startTime = _this.jobStartTime;
                            timeline.assignment.startTimeObj.setHours(+timeArr[0]);
                            timeline.assignment.startTimeObj.setMinutes(+timeArr[1]);
                            timeline.startTime.setHours(+timeArr[0]);
                            timeline.startTime.setMinutes(+timeArr[1]);
                            _this.summaryContent.assignments[index].startTime = _this.jobStartTime;
                            _this.summaryContent.assignments[index].startTimeObj.setHours(+timeArr[0]);
                            _this.summaryContent.assignments[index].startTimeObj.setMinutes(+timeArr[1]);
                        }
                        else {
                            timeline.assignment.endTime = _this.jobEndTime;
                            timeline.assignment.endTimeObj.setHours(+timeArr[0]);
                            timeline.assignment.endTimeObj.setMinutes(+timeArr[1]);
                            timeline.endTime.setHours(+timeArr[0]);
                            timeline.endTime.setMinutes(+timeArr[1]);
                            _this.summaryContent.assignments[index].endTime = _this.jobStartTime;
                            _this.summaryContent.assignments[index].endTimeObj.setHours(+timeArr[0]);
                            _this.summaryContent.assignments[index].endTimeObj.setMinutes(+timeArr[1]);
                        }
                    });
                }
            }
            else if (isStartTime) {
                this.jobStartTime = this.defaultJobStartTime;
                $event.target.value = this.jobStartTime;
            }
            else {
                this.jobEndTime = this.defaultJobEndTime;
                $event.target.value = this.jobEndTime;
            }
        }
    };
    return ResourceAllocationCardGenComponent;
}());
export { ResourceAllocationCardGenComponent };
