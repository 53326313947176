import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material';
import {FullCalendarComponent} from '@fullcalendar/angular';
import {Calendar} from '@fullcalendar/core';
import {EventApi} from '@fullcalendar/core/api/EventApi';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import {DayGridView} from '@fullcalendar/daygrid/DayGridView';
import {TcApiError} from '@tc-core/model/it/codegen/tbx/ext/errors/tc-api-error';
import {TcErrorType} from '@tc-core/model/it/codegen/tbx/ext/errors/tc-error';
import {TcHttpError} from '@tc-core/model/it/codegen/tbx/ext/errors/tc-http-error';
import {DialogModel} from '@tc-core/model/it/codegen/ui/framework/dialog-model';
import {LEVEL, ModalData} from '@tc-core/model/it/codegen/ui/framework/modal-data';
import {TC} from '@tc-core/util';
import {ConfigLoader, EventManager, FocusViewManager} from '@tc-core/util/framework';
import {CommonHelper} from '@tc-core/util/helpers';
import {SpinnerService} from '@tc-core/util/ui';
import {DialogService} from '@tc/dialog/dialog.service';
import {FocusViewService} from '@tc/focus-view/focus-view.service';
import {Subscription} from 'rxjs';
import {TCO} from '../../../constants';
import {LeaveSetupFocusViewComponent} from '../../../business/leave-setup-focus-view/leave-setup-focus-view.component';
import {AssignmentSummary} from '../../../models/assignment-summary';
import {LeaveSearchCriteria} from '../../../models/criteria/leave-search-criteria';
import { AssignmentStatus } from '../../../models/reservation/assignment-status';
import {ResourceLeaveSummary} from '../../../models/resource-leave-summary';
import {Leave} from '../../../models/resource/leave';
import {DMCCommon} from '../../../services/util/common/dmc-common';
import {TrsReservationManagementService} from '../../../services/backend-consumers/reservation-management-services/trs-reservation-management.service';
import {ResourceAvailabilityService} from '../../../services/backend-consumers/supplier-service/resource-availability.service';
import {RootService} from '../../../services/util/core-services/root.service';
import {DataKey, DataStoreService} from '../../../services/util/framework/data-store.service';
import {DMCQueryParamsService} from '../../../services/util/framework/dmc-query-params.service';
import * as moment from 'moment';
import {ResponseUtil} from '../../../services/util/response/response-util.service';
import timeGridPlugin from '@fullcalendar/timegrid';
import {ReservationV2ManagementService} from '../../../services/backend-consumers/reservation-v2/reservation-v2-management.service';

@Component({
    selector: 'tc-resource-availability-calendar-focus-view',
    templateUrl: './res-availability-calendar-focus-view.component.html'
})
export class ResAvailabilityCalendarFocusViewComponent implements OnInit {
    calendarPlugins = [dayGridPlugin, timeGridPlugin, interactionPlugin];

    @Input() resourceSummary: any;
    @Input() navConfig: any[] = [];

    assignments = [];
    events = [];
    leaveEvents = [];
    calendarEvents = [];
    viewOptions = [];
    resourceLeaveSummary: ResourceLeaveSummary = new ResourceLeaveSummary();
    leaveSetupSearchCriteria: LeaveSearchCriteria = new LeaveSearchCriteria();
    leaveTypeConfig: any[] = [];
    duration = 4;

    private focusViewCloseSubject: Subscription = new Subscription();
    private deletedEventObsv: Subscription = new Subscription();
    private assignmentSearchObsv: Subscription = new Subscription();
    private leaveSearchObsv: Subscription = new Subscription();


    constructor(
        private resourceAvailabilityService: ResourceAvailabilityService,
        private trsReservationManagementService: TrsReservationManagementService,
        private spinnerService: SpinnerService,
        private dataStore: DataStoreService,
        private em: EventManager,
        private configLoader: ConfigLoader,
        private focusViewService: FocusViewService,
        private queryParamService: DMCQueryParamsService,
        private rootService: RootService,
        private focusViewManager: FocusViewManager,
        private dialogService: DialogService,
        private commonHelper: CommonHelper,
        private common: DMCCommon,
        private reservationV2ManagementService: ReservationV2ManagementService
    ) {
    }

    ngOnInit() {
        this.focusViewManager.canProceed.next(true);
        this.focusViewManager.canProceedState.next(true);
        this.getCalendarViewOptions();
        if (this.navConfig && this.navConfig.length > 0) {
            this.leaveTypeConfig = this.navConfig.filter(value => value.isLeave);
        }
        this.dataStore.set(DataKey.resourceLeaves, null, true);

        this.getAssignments();

        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'CLOSE_FOCUS_VIEW').subscribe(e => {
            this.dataStore.set(DataKey.availabilityCalendarFocusViewClose, null);
            this.dataStore.set(DataKey.availabilityCalendarFocusViewClose, true);
            this.focusViewService.close(true);
        });
    }

    getCalendarViewOptions() {
        this.viewOptions = this.configLoader.configurations.get(TCO.CONF.CONF_PAGE_SECTION_CONFIG)['calendar-view-options'];
    }

    // open leave setup focus view when select on date or date range
    handleDateClick(arg) {
        this.leaveSetupSearchCriteria.resourceType = this.resourceSummary.resourceType;
        this.leaveSetupSearchCriteria.resourceId = this.resourceSummary.resourceId;
        this.leaveSetupSearchCriteria.activeStatus = true;
        let startDate = moment(arg.start).format('YYYY-MM-DD');
        let endDate = moment(arg.end-1).format('YYYY-MM-DD');
        let leavesForSelectedDate: boolean = false;
        let tasksForSelectedDate: boolean = false;
        for (const value of this.events) {
            if ((value.date > startDate || value.date === startDate) && (value.date < endDate || value.date === endDate)) {
                if (value.type === 'TASK') {
                    tasksForSelectedDate = true;
                } else {
                    leavesForSelectedDate = true;
                }
                break;
            }
        }
        if (tasksForSelectedDate) {
            let confirmDataObject = new DialogModel(
                true,
                LEVEL.WARNING,
                'Add Leaves',
                'There are already assigned tasks. Do you wish to proceed?',
                true,
                3000
            );
            this.dialogService.confirm(confirmDataObject).subscribe(
                (res) => {
                    if (res === true) {
                        this.showLeaveSetup(startDate, endDate, true);
                    }
                });
            // this.common.showSnackBar('There are already assigned tasks', 3000, TcErrorType.TYPE.WARN);
        } else if (!leavesForSelectedDate){
            this.showLeaveSetup(startDate, endDate, false);
        }
    }
    showLeaveSetup(startDate: any, endDate: any, hasTask: boolean = false ){
        setTimeout(() => {
            let fabActions = [];
            // getting fab actions for leave setup focus view
            let navItemsArray = this.configLoader.configurations.get(TCO.CONF.CONF_NAVIGATION);
            this.configLoader.configurations.get(TCO.CONF.CONF_FOCUS_VIEW).LEAVE_SETUP_FOCUS_VIEW.fabAction
                .forEach(val => {
                    for (let key in navItemsArray) {
                        if (val === navItemsArray[key]['id']) {
                            fabActions.push(navItemsArray[key]);
                        }
                    }
                });
            let dataObject = new ModalData(
                LEVEL.SUCCESS,
                'Leave Setup',
                null,
                null,
                '',
                LeaveSetupFocusViewComponent,
                {
                    'leaveSearchCriteria': this.leaveSetupSearchCriteria,
                    'isInsideForm': true,
                    'hasTaskForDates': hasTask,
                    'selectedStartDate': startDate,
                    'selectedEndDate': endDate,
                    'leaveTypes': this.leaveTypeConfig
                },
                {'label': 'close'},
                '',
                '',
                fabActions,
                '', 'From: ' + startDate + ' To: ' + endDate
            );

            this.focusViewCloseSubject = this.dataStore.get(DataKey.leaveSetupFocusViewClose).subscribe(c => {
                if (c) {
                    this.getLeaves();
                }
            });
            this.focusViewService.confirm(dataObject).subscribe(res => {
                if (res) {
                    console.log(res);
                }
            });
        }, 0);
    }


    // handle click event
    eventClick(calEvent) {
        // check the target id
        if (calEvent.jsEvent && calEvent.jsEvent.target.id === 'Delete') {
            this.removeEvent(calEvent.event);
        }
    }

    removeEvent(event: any) {
        let confirmDataObject = new DialogModel(
            true,
            LEVEL.WARNING,
            'Delete Leave',
            'This Leave will be deleted. Do you wish to proceed?',
            true,
            3000
        );
        this.dialogService.confirm(confirmDataObject).subscribe(
            (res) => {
                if (res === true) {
                    let criteria: LeaveSearchCriteria;
                    criteria = event.extendedProps.leaveId;
                    this.getDeleteObserver(criteria);

                }
            });
    }

    // subscribe event delete
    private getDeleteObserver(criteria: LeaveSearchCriteria) {
        this.resourceAvailabilityService.deleteLeave(criteria).subscribe((data) => {
            if (ResponseUtil.isSuccess(data)) {
                this.getLeaves();
            } else {
                this.common.showSnackBar('Failed to delete.', 3000, TcErrorType.TYPE.ERROR);
            }
        });

    }

    // create leaves to display in calendar
    getLeaves() {
        this.dataStore.set(DataKey.resourceLeaves, null, true);
        // create search criteria
        let criteria = new LeaveSearchCriteria();
        criteria.resourceId = this.resourceSummary.resourceId;
        criteria.activeStatus = true;
        this.resourceAvailabilityService.searchLeaveSummaries(criteria, DataKey.resourceLeaves);
        this.spinnerService.show();
        this.leaveSearchObsv = this.dataStore.get(DataKey.resourceLeaves).subscribe(value => {
            if(value)
            {
                let leaveData: Leave[];
                this.resourceLeaveSummary = ResponseUtil.getFirstData(value);
                leaveData = this.resourceLeaveSummary ? this.resourceLeaveSummary.leaves : [];
                this.spinnerService.hide();
                this.events = [];
                let leaveConfig: any = {};
                if (leaveData && leaveData.length > 0) {
                    let events = [];
                    // process search results
                    leaveData.forEach(leave => {
                        let event: any = {};
                        // create leave id in FE
                        event.leaveId = {
                            'leaveDate': leave.leaveDate,
                            'resourceId': leave.resourceId,
                            'groupId': leave.groupId
                        };
                        //filter relevant configs
                        leaveConfig = this.leaveTypeConfig.filter(val => val.name === leave.leaveType)[0];
                        event.groupId = leave.groupId;
                        event.resourceId = leave.resourceId;
                        event.start = leaveConfig ? leave.leaveDate + 'T' + leaveConfig.startTime : leave.leaveDate;
                        event.end = leaveConfig ? leave.leaveDate + 'T' + leaveConfig.endTime : leave.leaveDate;
                        event.leaveType = leaveConfig ? leaveConfig.name : leave.leaveType;
                        event.type = leaveConfig ? leaveConfig.code : leave.leaveType;
                        event.innerHtml = leaveConfig ? leaveConfig.innerHtml : {};
                        event.title = leave.note;
                        event.date = leave.leaveDate;
                        event.allDay = leaveConfig.allDay;

                        events.push(event);
                    });
                    this.leaveEvents = events;
                    this.events = this.leaveEvents;
                }
                if (this.assignments && this.assignments.length > 0) {
                    this.assignments.forEach(val => this.events.push(val));
                }
                this.calendarEvents = this.events;
            }
        });
        // this.calendarComponent.fullCalendar('renderEvents', this.leaveEvents, true);
    }

    // create tasks for display in the calendar
    getAssignments() {
        let resourceId = this.resourceSummary.resourceId;
        let resourceIdList = [];
        resourceIdList.push(this.resourceSummary.resourceId);
        this.dataStore.set(DataKey.resourceAssignments, null, true);
        this.reservationV2ManagementService.searchAssignments(resourceId, DataKey.resourceAssignments);
        this.spinnerService.show();
        this.assignmentSearchObsv = this.dataStore.get(DataKey.resourceAssignments).subscribe(value => {
            let resourceTasks: AssignmentSummary[] = ResponseUtil.getDataArray<AssignmentSummary>(value);
            this.spinnerService.hide();
            if (resourceTasks && resourceTasks.length > 0) {
                let events: AssignmentSummary[] = [];
                resourceTasks.forEach(task => {
                    let event: any = {};
                    event.eventId = task.assignmentId;
                    let taskTitle = task.startLocationGroup ? task.startLocationGroup : task.tourName;
                    taskTitle = taskTitle ? taskTitle : "" ;
                    event.title = task.startTime + ' ' + taskTitle;
                    let taskConfig = this.navConfig.filter(val => !val.isLeave)[0];
                    // event.color = taskConfig.color;
                    event.textColor = taskConfig.textColor;
                    event.className = taskConfig.class;
                    event.type = taskConfig.code;
                    // check task status - assignment approved or not
                    if (task.status === AssignmentStatus.PENDING) {
                        event.innerHtml = taskConfig.neutralInnerHtml;
                    } else if(task.status === AssignmentStatus.DMC_CONFIRM || task.status === AssignmentStatus.COMPLETED || task.status === AssignmentStatus.STARTED || task.status === AssignmentStatus.SUPPLIER_CONFIRMED){
                        event.innerHtml = taskConfig.confirmedInnerHtml;
                    } else {
                        event.innerHtml = taskConfig.rejectedInnerHtml;
                    }
                    if(task.assignDate)
                    {
                        task.assignDate = task.assignDate.split('T')[0]
                        event.date = task.assignDate;
                        event.start = task.assignDate + 'T' + task.startTime;
                        let end = task.endTime ? task.endTime : parseFloat(task.startTime) + this.duration ;
                        event.end = task.assignDate + 'T' + end.toString();
                    }
                    events.push(event);
                });
                this.assignments = events;
            }

            this.getLeaves();
        });

    }

    // filter calendar events
    filterSelectedEvents(event: any[]) {
        this.calendarEvents = this.events.filter(value => event.includes(value.type));
    }

    ngOnDestroy(): void {
        if (this.assignmentSearchObsv) {
            this.assignmentSearchObsv.unsubscribe();
        }
        if (this.leaveSearchObsv) {
            this.leaveSearchObsv.unsubscribe();
        }
    }

}
