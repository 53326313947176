<form [formGroup]="personalInfoForm">
    <div class="container-fluid direct-client-personal-info-edit-card tc-card-wrapper">
        <div class="row">
            <div class="col-md-12 col-lg-12">
                <div class="tc-flex-box">
                    <div class="tc-flex-item tc-item-circle">
                        <div class="icon-box">
                            <i class="material-icons">account_circle</i>
                        </div>
                    </div>
                    <div class="tc-flex-item tc-right-cage">
                        <div class="row">
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                <mat-form-field>
                                    <mat-select
                                            placeholder="Type"
                                            name="resourceType"
                                            formControlName="resourceType"
                                            (valueChange)="onResourceTypeChange($event)"
                                            required>
                                        <mat-option
                                                *ngFor="let g of resourceTypeOptions"
                                                [value]="g.code">
                                            {{g.name}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error>{{getErrorMessage('resourceType')}}</mat-error>
                                </mat-form-field>

                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                <div class="tc-select-edit dcpiec-select-title">
                                    <mat-form-field>
                                        <mat-select
                                            #titleSelect
                                            id="DIR_PER_INFO_EDIT_TITLE"
                                            formControlName="title"
                                            [placeholder]="'Title'"
                                            required>
                                            <!-- title map includes it's name and type. So titleType.code is name of title which is used as code as well-->
                                            <mat-option
                                                    id="DIR_PER_INFO_EDIT_TITLE_{{titleType.key}}"
                                                    *ngFor="let titleType of titles"
                                                    [value]="titleType.code">
                                                {{titleType.name}}
                                            </mat-option>
                                        </mat-select>
                                        <mat-error>{{getErrorMessage('title')}}</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                <div class="tc-input-edit dcpiec-input-fname">
                                    <mat-form-field>
                                        <input
                                                matInput
                                                id="DIR_PER_INFO_EDIT_FNAME"
                                                #fNameInput
                                                formControlName="fName"
                                                [placeholder]="'First Name'"
                                                required>
                                        <mat-error>{{getErrorMessage('fName')}}</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-3 remove-bootstrap-padding-mobile">
                                <div class="tc-input-edit dcpiec-input-lname">
                                    <mat-form-field>
                                        <input
                                                matInput
                                                id="DIR_PER_INFO_EDIT_LNAME"
                                                #lNameInput
                                                formControlName="lName"
                                                [placeholder]="'Last Name'"
                                                required>
                                        <mat-error>{{getErrorMessage('lName')}}</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>

                        </div>

                        <div class="row">
                            <div class="col-xs-12 col-sm-12 col-md-3 remove-bootstrap-padding-mobile">
                                <div class="tc-input-edit dcpiec-input-lname">
                                    <mat-form-field>
                                        <mat-select
                                                placeholder="Gender" name="gender" formControlName="gender"
                                                required>
                                            <mat-option *ngFor="let g of genderOptions | objKeysPipe" [value]="g.value">
                                                {{g.value}}
                                            </mat-option>
                                        </mat-select>
                                        <mat-error>{{getErrorMessage('gender')}}</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                <div class="tc-input-edit dcpiec-input-dob">
                                    <mat-form-field>
                                        <input
                                                [max]="maxDate"
                                                #dateInput
                                                id="DIR_PER_INFO_EDIT_DOB"
                                                matInput
                                                [matDatepicker]="picker"
                                                [placeholder]="'Date of Birth'"
                                                name="dateOfBirth"
                                                formControlName="dateOfBirth">
                                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                        <mat-datepicker #picker></mat-datepicker>
                                        <mat-error>{{getErrorMessage('dateOfBirth')}}</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                <div class="tc-input-edit dcpiec-input-lname">
                                    <mat-form-field>
                                        <mat-select
                                                placeholder="Profile Type"
                                                name="supplierType"
                                                formControlName="supplierType"
                                        >
                                            <mat-option
                                                    *ngFor="let g of supplierTypeOptions"
                                                    [value]="g.code">
                                                {{g.name}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">

                                <mat-form-field>
                                    <input
                                            matInput
                                            id="DIR_PER_INFO_SUPPLIER_NAME"
                                            #supplierInput
                                            formControlName="supplier"
                                            [placeholder]="'Supplier'"
                                            [matAutocomplete]="autoS"
                                            (focus)="onSupplierFocus($event)"
                                            required
                                    >
                                    <button
                                            mat-button
                                            matSuffix
                                            mat-icon-button
                                            aria-label="Clear"
                                            [disabled]="false"
                                            (click)="clearSupplier()">
                                        <mat-icon>close</mat-icon>
                                    </button>
                                    <mat-error>{{getErrorMessage('supplier')}}</mat-error>
                                    <mat-hint *ngIf="missingCostForSupplier">Supplier cost is not available</mat-hint>
                                </mat-form-field>
                                <mat-autocomplete #autoS="matAutocomplete" [displayWith]="displaySupplierFn">
                                    <mat-option
                                            *ngFor="let supplier of filteredSuppliers"
                                            [value]="supplier"
                                            (onSelectionChange)="onSelectSupplier(supplier)">
                                        <span>{{supplier?.name ? supplier.name : ''}}</span> |
                                        <small>{{supplier?.code ? supplier.code : ''}}</small>
                                    </mat-option>
                                </mat-autocomplete>
                            </div>

                        </div>
                        <div class="row">
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                                <div class="tc-input-edit dcpiec-input-businessType">
                                    <mat-form-field>
                                        <mat-select #localeInput
                                                    id="DIR_PER_INFO_EDIT_BUSINESS_TYPE"
                                                    formControlName="businessType"
                                                    [placeholder]="'Business Type'" required>
                                            <mat-option *ngFor="let option of businessTypes | keyvalue" [value]="option.key">
                                                {{option.value}}
                                            </mat-option>
                                        </mat-select>
                                        <mat-error>{{getErrorMessage('businessType')}}</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-3 remove-bootstrap-padding-mobile">
                                <div class="tc-input-edit dcpiec-input-preference">
                                    <mat-form-field>
                                        <input matInput
                                               id="DIR_PER_INFO_EDIT_PREFERENCE"
                                               #preferenceInput
                                               formControlName="preference"
                                               [placeholder]="'Preference'"
                                        >
                                        <mat-error>{{getErrorMessage('preference')}}</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>

                            <div class="col-xs-12 col-sm-12 col-md-3 remove-bootstrap-padding-mobile">
                                <div class="tc-input-edit dcpiec-input-grade">
                                    <mat-form-field>
                                        <mat-select #localeInput
                                                    id="DIR_PER_INFO_EDIT_GRADE"
                                                    [(ngModel)]="gradeNgModel"
                                                    (selectionChange)="gradeSelectionOnChange($event)"
                                                    [ngModelOptions]="{standalone: true}"
                                                    [placeholder]="'Grade'">
                                            <mat-option *ngFor="let single of grades" [value]="single?.order">
                                                {{single?.displayName}}
                                            </mat-option>
                                        </mat-select>
                                        <mat-error>{{getErrorMessage('grade')}}</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>

                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                <div class="tc-input-edit dcpiec-input-licenseNumber">
                                    <mat-form-field>
                                        <input
                                                matInput
                                                formControlName="capacity"
                                                [placeholder]="'Capacity' | translate"
                                                required
                                        >
                                        <mat-error>{{getErrorMessage('seatingCapacity')}}</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>

                        </div>
                        <div class="row">
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">

                                <mat-form-field>
                                    <input
                                            matInput
                                            id="DIR_PER_INFO_USER_NAME"
                                            #userInput
                                            formControlName="linkedUser"
                                            [placeholder]="'Linked User'"
                                            [matAutocomplete]="autoUser"
                                            (focus)="onUserFocus($event)">
                                    <button
                                            mat-button
                                            matSuffix
                                            mat-icon-button
                                            aria-label="Clear"
                                            [disabled]="false"
                                            (click)="clearUser()">
                                        <mat-icon>close</mat-icon>
                                    </button>
                                    <mat-error>{{getErrorMessage('linkedUser')}}</mat-error>
                                </mat-form-field>
                                <mat-autocomplete #autoUser="matAutocomplete" [displayWith]="displayUserFn">
                                    <mat-option
                                            *ngFor="let user of filteredUsers"
                                            [value]="user"
                                            (onSelectionChange)="onSelectUser(user)">
                                        <span>{{getUserName(user)}}</span>
                                    </mat-option>
                                </mat-autocomplete>
                            </div>
                            <div class="col-xs-12 col-sm-6  col-md-3 col-lg-3">
                                <div class="tc-content--wrap tc-input-view">
                                </div>
                                <img
                                        *ngIf="resource?.userProfile?.imageUrl"
                                        width="50px"
                                        height="50px"
                                        style="border-radius: 50%"
                                        [src]="resource?.userProfile?.imageUrl"
                                        alt="user image">
                            </div>
                        </div>
                        <div class="tc-action-chip-list-wrapper tc-chip-selector__action-chips">
                            <div
                                    class="tc-action-chip"
                                    (click)="toggleActiveStatus()"
                                    [ngClass]=" personalInfo.activeStatus ?'tc-action-chip--active':'tc-action-chip--inactive' ">
                                <span class="tc-action-chip__icon tc-action-chip__icon-man"></span>
                                <span class="tc-action-chip__icon tc-action-chip__icon-select">
                                  <i class="material-icons">done</i>
                                </span>
                                <div class="tc-action-chip__text">
                                    {{'Active' | translate}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>

