<div class="trade-client-detail-view">
    <div
            class="detail-group">
        <div class=" detail-group__heading">
            <div class="tc-item-wrapper">
                <div class="tc-item-header tc-flex-box">
                    <div class="tc-flex-item tc-item-header__primary">{{'Summary' | translate}}</div>
                </div>
            </div>
        </div>
        <div class="detail-group__content">

            <tc-service-item-focus-view-summary
                    class="bd-booking-summary-client-info-wrapper"
                    [summaryInfo]="selectedServiceItem"
                    [supplierSummary]="selectedSupplier"
                    [resourceSummary]="selectedVehicle"
                    [driverSummary]="selectedDriver"
                    [assignmentStatus]="assignmentStatus"
                    [historyText]="historyText">
            </tc-service-item-focus-view-summary>
            <tc-service-item-focus-view-flight-info
                    class="bd-booking-summary-client-info-wrapper"
                    [summaryInfo]="selectedServiceItem">
            </tc-service-item-focus-view-flight-info>
        </div>
    </div>
    <div
            class="detail-group">
        <!--<div class=" detail-group__heading">
            <div class="tc-item-wrapper">
                <div class="tc-item-header tc-flex-box">
                    <div class="tc-flex-item tc-item-header__primary">Additional Contacts</div>

                    <div class="tc-flex-item tc-item-header__actions tc-icon-block">
      <span>
          <div class="additional-contacts-block__heading">
        <i
                class="material-icons tc-icon-block__icon tc-icon-block__icon&#45;&#45;active" (click)="addAdditionalContact()"

        >add</i>
               </div>
      </span>

                    </div>
                </div>
            </div>
        </div>-->
        <div class=" detail-group__heading">
            <div class="tc-item-wrapper">
                <div class="tc-item-header tc-flex-box">
                    <div class="tc-flex-item tc-item-header__primary">{{'Notes' | translate}}</div>
                    <div class="tc-flex-item tc-item-header__actions tc-icon-block">
                        <div class="action-icon">
                            <i *ngIf="selectedServiceItem?.status !== 1"
                                    class="material-icons"
                                    [matTooltip]="addNoteToolTip | translate"
                                    [matTooltipPosition]="toolTipPosition"
                                    (click)="onAddNotesClick($event)">add
                            </i>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="">
            <tc-service-item-notes
                    [noteList]="noteList"
                    [noteContents]="noteContents"
                    [noteSummary]="noteSummary"
                    [heading]="'Notes'"
                    [masterNoteTypes]="masterNoteTypes"
                    [isConfirmed]="isConfirmed"
                    (editClick)="onNotesEditClick($event)"
                    (deleteClick)="onNotesDeleteClick($event)"
                    (selectedNote)="onNoteSelection($event)"
                    class="bdv-booking-notes-summary-wrapper">
            </tc-service-item-notes>
        </div>
    </div>
</div>
