import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SectionHeadingModule } from '@tc/section-heading';
import { CgSeasonCalendarModule } from '../../../../widgets/framework/cg-season-calendar';
// import { CgSeasonCalendarModule } from '@tc/cg-season-calendar';
import { ResultHeaderModule } from '../../../../widgets/shared/result-header/result-header.module';
import { TransportCostSetupEditCardModule } from '../../../../widgets/shared/transport-cost-setup-edit-card/transport-cost-setup-edit-card.module';
import { TransportCostSetupSummaryCardModule } from '../../../../widgets/shared/transport-cost-setup-summary-card/transport-cost-setup-summary-card.module';
import { TransportCostSetupFocusViewComponent } from './transport-cost-setup-focus-view.component';

@NgModule({
    imports: [
        CommonModule,
        ResultHeaderModule,
        TransportCostSetupSummaryCardModule,
        TransportCostSetupEditCardModule,
        CgSeasonCalendarModule,
        SectionHeadingModule
    ],
    declarations: [TransportCostSetupFocusViewComponent],
    exports: [TransportCostSetupFocusViewComponent]
})
export class TransportCostSetupFocusViewModule {}
