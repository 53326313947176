import { AssignmentNote } from './assignment-note';
import { BookingItemHistory } from './booking-item-history';
import { Passenger } from './passenger';

export class Assignment {
    /* ids */
    id: number;

    /* booking specific data in job */
    plCode: string;
    plName: string;

    /* supplier data */
    supplierId: number;
    supplierCode: string;
    supplierName: string;

    /* resource data */
    resourceId: number;
    resourceName: string;

    /* applied cost line */
    unitFare: number;
    adultFare: number;
    teenFare: number;
    childFare: number;
    infantFare: number;
    currency: string;
    seasonId: number;
    transferMode: string;
    route: string;
    vehicleModel: string;
    resourceType: string;
    profileType: string;

    /* job data */
    assignStatus: AssignStatus;
    serviceLevel: ServiceLevel;
    sharedAssign: boolean;
    jobDate: Date;
    startTime: Date;
    startLocationGroup: string;
    waitingEndTime: Date;
    endTime: Date;
    endLocationGroup: string;
    estimatedCost: number;
    jobCost: number;

    /* read friendly data */
    adultCount: number;
    teenCount: number;
    childCount: number;
    infantCount: number;

    /* logging */
    modifiedOn: Date;
    assignedDate: Date;
    addedToLedger: boolean;

    /* relationships */
    assignmentNotes: AssignmentNote[];
    assignmentHistories: BookingItemHistory[];
    bookingPassengers: Passenger[];

    rejectedSupplierIdList: number[];
    rejectedResourceIdList: number[];
}

export enum AssignStatus {
    ASSIGNED = 'ASSIGNED',
    UNASSIGNED = 'UNASSIGNED',
    PENDING = 'PENDING',
    DMC_CONFIRM = 'DMC_CONFIRM',
    SUPPLIER_CONFIRMED = 'SUPPLIER_CONFIRMED',
    SUPPLIER_REJECTED = 'SUPPLIER_REJECTED',
    REQUEST_EXPIRED = 'REQUEST_EXPIRED',
    BOOKING_UPDATE = 'BOOKING_UPDATE',
    INACTIVE_NOTIFY = 'INACTIVE_NOTIFY',
    INACTIVE = 'INACTIVE',
    STARTED = 'STARTED',
    COMPLETED = 'COMPLETED',
    NO_SHOW = 'NO_SHOW'
}

export const StatusCodes = {
    PENDING: 'P',
    ASSIGNED: 'A',
    UNASSIGNED: 'UA',
    DMC_CONFIRM: 'DC',
    SUPPLIER_CONFIRMED: 'SC',
    SUPPLIER_REJECTED: 'R',
    REQUEST_EXPIRED: 'RE',
    BOOKING_UPDATE: 'BU',
    INACTIVE_NOTIFY: 'IN',
    INACTIVE: 'I',
    STARTED: 'S',
    COMPLETED: 'C',
    NO_SHOW: 'NS'
};

export enum ServiceLevel {
    PRIMARY = 'PRIMARY',
    SECONDARY = 'SECONDARY'
}
