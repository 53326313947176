import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DMCQueryParamsService } from '../../util/framework/dmc-query-params.service';
import { RequestService } from '../../util/framework/request.service';

@Injectable({
    providedIn: 'root'
})
export class FileHandlingService {
    constructor(
        private queryParamsService: DMCQueryParamsService,
        private requestService: RequestService,
        private queryParamService: DMCQueryParamsService
    ) {}

    // uploadImage(fileToUpload: File, endPoint: string): Observable<any> {
    //     const formData: FormData = new FormData();
    //     formData.append('imageFile', fileToUpload);
    //     return this.httpClient
    //                .post(endPoint, formData, {
    //                    reportProgress: true,
    //                    responseType: 'json'
    //                });
    // }

    uploadImage(params: any, endPoint: string): Observable<any> {
        const formData: FormData = new FormData();
        const keys = this.queryParamService.getNotNullQueryParamsArray(params);
        if (keys) {
            for (const k of keys) {
                if (params.hasOwnProperty(k)) {
                    formData.append(k, params[k]);
                }
            }
        }
        return this.requestService
                   .postForUrl(endPoint, formData, {
                       reportProgress: true,
                       responseType: 'json'
                   });
    }
}



