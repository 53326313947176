import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
    MatButtonModule,
    MatCheckboxModule, MatDatepickerModule,
    MatFormFieldModule,
    MatIconModule,
    MatOptionModule,
    MatRadioModule,
    MatSelectModule
} from '@angular/material';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { BrowserModule } from '@angular/platform-browser';
import { AgGridModule } from 'ag-grid-angular';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { AutoCompleteEditorComponent } from './auto-complete-editor/auto-complete-editor.component';
import { DateEditorComponent } from './date-editor/date-editor.component';
import { DropdownEditorComponent } from './dropdown-editor/dropdown-editor.component';
import { NoteEditorComponent } from './note-editor/note-editor.component';
import { MultiSelectorEditorComponent } from './multi-selector-editor/multi-selector-editor.component';
import { RadioButtonEditorComponent } from './radio-button-editor/radio-button-editor.component';
import { TimeEditorComponent } from './time-editor/time-editor.component';
import {TranslateModule} from '@ngx-translate/core';
import { MaxLengthValidatorComponent } from './max-length-validator/max-length-validator.component';
import { AllocationSuggestionEditorComponent } from './allocation-suggestion-editor/allocation-suggestion-editor.component';
import { MaxLengthTerminatorComponent } from './max-length-terminator/max-length-terminator.component';

@NgModule({
    declarations: [
        DropdownEditorComponent,
        AutoCompleteEditorComponent,
        NoteEditorComponent,
        MultiSelectorEditorComponent,
        RadioButtonEditorComponent,
        DateEditorComponent,
        TimeEditorComponent,
        MaxLengthValidatorComponent,
        AllocationSuggestionEditorComponent,
        MaxLengthTerminatorComponent
    ],
    imports: [
        BrowserModule,
        FormsModule,
        AgGridModule,
        ReactiveFormsModule,

        MatIconModule,
        MatSelectModule,
        MatOptionModule,
        MatFormFieldModule,
        MatCardModule,
        MatInputModule,
        MatAutocompleteModule,
        MatButtonModule,
        MatCheckboxModule,
        MatRadioModule,
        MatDatepickerModule,
        NgxMaterialTimepickerModule,
        TranslateModule.forChild()
    ],
    exports: [
        DropdownEditorComponent,
        AutoCompleteEditorComponent,
        NoteEditorComponent,
        MultiSelectorEditorComponent,
        RadioButtonEditorComponent,
        DateEditorComponent,
        TimeEditorComponent,
        MaxLengthValidatorComponent,
        MaxLengthTerminatorComponent,
        AllocationSuggestionEditorComponent
    ]
})
export class AgGridCustomEditorsModule {
}
