import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { ConfigLoader } from '@tc-core/util/framework';
import { of, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SetupGridComp } from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid-comp';
import { SetupGridComponent } from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid.component';
import { TCO } from '../../../constants';
import { RoomTypeSearchCriteria } from '../../../models/criteria/room-type-search-criteria';
import { SortDirection } from '../../../models/helper/sort-direction';
import { AccommodationService } from '../../../services/backend-consumers/setups/accommodation.service';
import { DataKey, DataStoreService } from '../../../services/util/framework/data-store.service';
var RoomTypeGridSetupComponent = /** @class */ (function (_super) {
    tslib_1.__extends(RoomTypeGridSetupComponent, _super);
    function RoomTypeGridSetupComponent(configLoader, dataStoreService, accommodationService) {
        var _this = _super.call(this) || this;
        _this.configLoader = configLoader;
        _this.dataStoreService = dataStoreService;
        _this.accommodationService = accommodationService;
        _this.colDefConfig = [];
        _this.roomTypeSearchCriteria = new RoomTypeSearchCriteria();
        // required options for grid editors
        // nothing
        _this.searchCriteriaObserver = new Subscription();
        _this.isDisableGeoClick = function (params) {
            return _this.checkRowInvalid(params.data);
        };
        return _this;
    }
    RoomTypeGridSetupComponent.prototype.ngOnInit = function () {
        this.colDefConfig = this.configLoader.configurations.get(TCO.CONF.CONF_ROOM_TYPE_SETUP);
        this.subscribeSearchCriteria();
    };
    /**
     * subscribe search criteria changes in search bar
     * then force grid to refresh data
     * grid refresh will be call getRaws method which is using this subscribed criteria for backend call
     */
    RoomTypeGridSetupComponent.prototype.subscribeSearchCriteria = function () {
        var _this = this;
        this.searchCriteriaObserver = this.dataStoreService.get(DataKey.roomTypeSearchCriteria)
            .subscribe(function (value) {
            _this.roomTypeSearchCriteria = value;
            _this.setupGrid.runForceSearch();
        });
    };
    RoomTypeGridSetupComponent.prototype.checkRowInvalid = function (data) {
        var event = {
            data: data
        };
        return this.isInvalidRow(event);
    };
    RoomTypeGridSetupComponent.prototype.fillRequiredGridOptionData = function () {
        // nothing
    };
    RoomTypeGridSetupComponent.prototype.fillDataKeyForEditorData = function () {
        // nothing
    };
    RoomTypeGridSetupComponent.prototype.isInvalidRow = function (params) {
        var data = params.data;
        if (data) {
            var invalid = true;
            if (data.code &&
                data.name) {
                invalid = false;
            }
            return invalid;
        }
        else {
            return false;
        }
    };
    RoomTypeGridSetupComponent.prototype.createNewRow = function () {
        return { unsaved: true };
    };
    RoomTypeGridSetupComponent.prototype.isUnsavedRow = function (row) {
        return !!row.unsaved;
    };
    RoomTypeGridSetupComponent.prototype.deleteRow = function (row) {
        if (row && row.code) {
            return this.accommodationService.deleteRoomType(row.code);
        }
        else {
            return of('success');
        }
    };
    RoomTypeGridSetupComponent.prototype.saveRow = function (row, event) {
        if (event.data.code === event.newValue) {
            console.log('Updated');
            return this.accommodationService.updateRoomType(event.oldValue, row)
                .pipe(tap(function (data) { return row.unsaved = undefined; }));
        }
        else {
            console.log('Save');
            return this.accommodationService.saveRoomType(row)
                .pipe(tap(function (data) { return row.unsaved = undefined; }));
        }
    };
    RoomTypeGridSetupComponent.prototype.getRows = function (params) {
        var _this = this;
        this.dataStoreService.set(DataKey.roomTypeSearchResultsForCriteria, null);
        var pageSize = 1000000;
        if (!this.roomTypeSearchCriteria) {
            this.roomTypeSearchCriteria = new RoomTypeSearchCriteria();
        }
        this.roomTypeSearchCriteria.start = params.startRow;
        this.roomTypeSearchCriteria.size = pageSize;
        if (params.sortModel && params.sortModel[0]) {
            this.roomTypeSearchCriteria.sortBy = params.sortModel[0].colId;
            if (params.sortModel[0].sort === SetupGridComp.GRID_SORT_ASCENDING) {
                this.roomTypeSearchCriteria.sortDirection = SortDirection.ASC;
            }
            else {
                this.roomTypeSearchCriteria.sortDirection = SortDirection.DESC;
            }
        }
        return this.accommodationService.getRoomTypes(this.roomTypeSearchCriteria)
            .pipe(tap(function (data) {
            return _this.dataStoreService.set(DataKey.roomTypeSearchResultsForCriteria, data);
        }));
    };
    RoomTypeGridSetupComponent.prototype.ngOnDestroy = function () {
        if (this.searchCriteriaObserver) {
            this.searchCriteriaObserver.unsubscribe();
        }
    };
    return RoomTypeGridSetupComponent;
}(SetupGridComp));
export { RoomTypeGridSetupComponent };
