import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatSelectionList } from '@angular/material/list';
import { TranslateService } from '@ngx-translate/core';
import { TcErrorType } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-error';
import { ConfigLoader, EventManager, FocusViewManager, UserJourneyManager } from '@tc-core/util/framework';
import { CommonHelper } from '@tc-core/util/helpers';
import { SpinnerService } from '@tc-core/util/ui';
import { DialogService } from '@tc/dialog/dialog.service';
import { ModalService } from '@tc/modal/modal.service';
import { AgGridAngular } from 'ag-grid-angular';
import { ICellEditorParams, ICellRendererParams, RowNode, SortChangedEvent } from 'ag-grid-community';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ButtonState } from '../../widgets/framework/ag-grid-custom-components/directives/button-state';
import {
    AutoCompleteEditorComponent
} from '../../widgets/framework/ag-grid-custom-components/editors/auto-complete-editor/auto-complete-editor.component';
import {
    DropdownEditorComponent
} from '../../widgets/framework/ag-grid-custom-components/editors/dropdown-editor/dropdown-editor.component';
import {
    NoteEditorComponent
} from '../../widgets/framework/ag-grid-custom-components/editors/note-editor/note-editor.component';
import {
    CustomTextFilterComponent
} from '../../widgets/framework/ag-grid-custom-components/filters/custom-text-filter/custom-text-filter.component';
import {
    ActionsComponent
} from '../../widgets/framework/ag-grid-custom-components/renderers/actions/actions.component';
import {
    TemplateRendererComponent
} from '../../widgets/framework/ag-grid-custom-components/renderers/template-renderer/template-renderer.component';
import { TCO } from '../../constants';
import { SupplierSearchCriteria } from '../../models/criteria/supplier-search-criteria';
import { UserProfileSearchCriteria } from '../../models/criteria/user-profile-search-criteria';
import { UserApprovalData } from '../../models/notification/user-approval-data';
import { OPResponseWrapper } from '../../models/response/op-response-wrapper';
import { SupplierSummary } from '../../models/summary/supplier-summary';
import { Supplier, SupplierBusinessType } from '../../models/supplier/supplier';
import { ApprovalStatus, UserServiceProfile } from '../../models/user/user-service-profile';
import { DocumentService } from '../../services/backend-consumers/documents/document.service';
import { NotificationService } from '../../services/backend-consumers/notifications/notification.service';
import { SupplierService } from '../../services/backend-consumers/supplier-service/supplier.service';
import { TransportResourceService } from '../../services/backend-consumers/supplier-service/transport-resource.service';
import { UserProfileService } from '../../services/backend-consumers/user-service/user-profile.service';
import { DMCCommon } from '../../services/util/common/dmc-common';
import { DataKey, DataStoreService } from '../../services/util/framework/data-store.service';
import { IdentifierService } from '../../services/util/identifier/identifier.service';
import {
    GridColumnDefinitionProcessorService
} from '../../services/util/pre-processors/grid-column-definition-processor.service';
import { ResponseUtil } from '../../services/util/response/response-util.service';
import { UserManagementService } from '../../services/user-management/user-management.service';
import { SupplierAllocationSummary } from '../../models/reservation-v2/supplier-allocation-summary';
import { ServiceItem } from '../../models/reservation-v2/service-item';
import { BookingItem } from '../../models/reservation-v2/booking-item';
import { DivisionSearchCriteria } from '../../models/criteria/division-search-criteria';
import { BrandSearchCriteria } from '../../models/criteria/brand-search-criteria';
import { DistributionChannelSearchCriteria } from '../../models/criteria/distribution-channel-search-criteria';
import { FormBuilder } from '@angular/forms';
import { BrandSetupService } from '../../services/backend-consumers/setups/brand-setup-service';
import { DivisionSetupService } from '../../services/backend-consumers/setups/division-setup-service';
import {
    DistributionChannelSetupService
} from '../../services/backend-consumers/setups/distribution-channel-setup-service';
import { UserProfile } from '../../authentication/user-profile';
import { ModalData } from '@tc-core/model/it/codegen/ui/framework/modal-data';
import { LEVEL } from '@tc-core/model/it/codegen/ui/framework/dialog-model';
import { UserRolesEditorComponent } from '../user-roles-editor/user-roles-editor.component';

@Component({
    selector: 'tc-supplier-acceptance-queue',
    templateUrl: './supplier-acceptance-queue.component.html'
})
export class SupplierAcceptanceQueueComponent implements OnInit, AfterViewInit, OnDestroy {

    constructor(
        public em: EventManager,
        private configLoader: ConfigLoader,
        private spinnerService: SpinnerService,
        private modalService: ModalService,
        private dialogService: DialogService,
        private common: DMCCommon,
        private notificationService: NotificationService,
        private cd: ChangeDetectorRef,
        private userJourneyManager: UserJourneyManager,
        private focusViewManager: FocusViewManager,
        private supplierService: SupplierService,
        private dataStoreService: DataStoreService,
        private columnDefinitionProcessorService: GridColumnDefinitionProcessorService,
        private commonHelper: CommonHelper,
        private documentService: DocumentService,
        private identifierService: IdentifierService,
        private userProfileService: UserProfileService,
        private transportResourceService: TransportResourceService,
        private translateService: TranslateService,
        private userManagementService: UserManagementService,
        private fb: FormBuilder,
        private brandSetupService: BrandSetupService,
        private divisionSetupService: DivisionSetupService,
        private distributionChannelSetupService: DistributionChannelSetupService
    ) {
        this.translateService.onLangChange.subscribe(() => {
            this.gridApi.refreshHeader();
        });
    }

    tPagination: any;
    searchCriteria = new UserProfileSearchCriteria();
    fetchedNotificationsCount = 0;
    totalNotificationCount = 0;
    resultHeaderHeading = '';

    userProfile: UserProfile;

    hasABrand = false;
    hasADivision = false;
    hasADistributionChannel = false;

    paginatorLoaded: boolean;
    searchFromSortingOrFiltering = false;
    sortFilterModelProgrammaticallyChanging = false;

    gridApi;
    gridColumnApi;
    supplierAcceptanceResults: UserServiceProfile[] = [];
    rowHeight = 42;

    defaultColDef = {
        resizable: true,
        singleClickEdit: false,
        headerValueGetter: (parameters: ICellRendererParams): string => {
            const headerIdentifier = parameters.colDef.headerName;
            if (headerIdentifier) {
                return this.translateService.instant(headerIdentifier);
            }
            return '';
        }
    };

    paginationPageSize = 10;
    EMPTY_STRING = '--';

    private searchCriteriaSubscription: Subscription = new Subscription();
    private searchResultSubscription: Subscription = new Subscription();
    private moreSearchResultSubscription: Subscription = new Subscription();
    private finalSearchResultsSubscription: Subscription = new Subscription();
    private modelConfirmSubscription: Subscription = new Subscription();
    private buttonState = new BehaviorSubject<ButtonState[]>([]);

    colDefConfig: any;
    allColDefConfig: any;

    columnDefsInput = [];
    allColumnDefsInput = [];

    @ViewChild('agGrid') agGrid: AgGridAngular;
    @ViewChild('approvalStatusCell') approvalStatusCell: TemplateRef<any>;
    @ViewChild('resourceCell') resourceCell: TemplateRef<any>;
    @ViewChild('supplierCodeCell') supplierCodeCell: TemplateRef<any>;
    @ViewChild('supplierNameCell') supplierNameCell: TemplateRef<any>;
    @ViewChild('actionCell') actionCell: TemplateRef<any>;
    @ViewChild('colSelector') private colSelector: MatSelectionList;

    frameworkComponents = {
        templateRenderer: TemplateRendererComponent,
        actionRenderer: ActionsComponent,
        autoCompleteEditor: AutoCompleteEditorComponent,
        dropDownEditor: DropdownEditorComponent,
        noteEditor: NoteEditorComponent,
        customTextFilter: CustomTextFilterComponent
    };

    PENDING = ApprovalStatus.PENDING;
    APPROVED = ApprovalStatus.APPROVED;
    REJECTED = ApprovalStatus.REJECTED;

    ngOnInit() {
        this.tPagination = this.configLoader.configurations.get(TCO.CONF.CONF_PAGINATION_CONFIG).SUP_ACCEPTANCE_SEARCH;
        this.paginationPageSize = this.tPagination.defaultPageResultCount;

        this.colDefConfig = this.userManagementService.updateColDefAccordingToLoggedUserKC(this.configLoader.configurations.get(
            TCO.CONF.CONF_SUPPLIER_ACCEPTANCE_QUEUE_COL_DEF));
        this.allColDefConfig = this.userManagementService.updateColDefAccordingToLoggedUserKC(this.configLoader.configurations.get(
            TCO.CONF.CONF_SUPPLIER_ACCEPTANCE_QUEUE_ALL_COL_DEF));

        this.allColumnDefsInput = this.columnDefinitionProcessorService
                                      .createColumnDefs(JSON.parse(JSON.stringify(this.allColDefConfig)), this);

        this.subscribeSearchCriteria();
        this.subscribeSearchResults();
        this.subscribeOnMoreSearchResults();

        this.subscribeSearchResultList();
        this.keyControlAvailability();
        this.getAvailableBrands();
        this.getAvailableDivisions();
        this.getAvailableDistributionChannels();
    }

    ngAfterViewInit(): void {
        this.setColumnDefs();
    }

    private getUserDetails() {
        this.userProfile = this.dataStoreService.get(DataKey.userProfile).getValue();
    }

    private subscribeSearchCriteria() {
        this.searchCriteriaSubscription = this.dataStoreService.get(DataKey.supAcceptanceSearchCriteria)
                                              .subscribe((data) => {
                                                  if (data !== null) {
                                                      this.searchCriteria = data;
                                                  }
                                              });
    }

    private subscribeSearchResults() {
        this.searchResultSubscription = this.dataStoreService.get(DataKey.supAcceptanceSearchResultsFromService)
                                            .subscribe((data: OPResponseWrapper<UserServiceProfile>) => {
                                                this.totalNotificationCount = 0;
                                                if (data) {
                                                    this.totalNotificationCount = ResponseUtil.getTotalCount(data);
                                                    const dataArray = ResponseUtil.getDataArray<UserServiceProfile>(data);
                                                    this.dataStoreService.set(
                                                        DataKey.supAcceptanceSearchResultsForCriteria,
                                                        dataArray,
                                                        true
                                                    );
                                                }
                                            });
    }

    private subscribeOnMoreSearchResults() {
        this.moreSearchResultSubscription = this.dataStoreService.get(DataKey.supAcceptanceSearchResultsFromServiceOnMore)
                                                .subscribe((data: OPResponseWrapper<UserServiceProfile>) => {
                                                    this.totalNotificationCount = 0;
                                                    if (data) {
                                                        this.totalNotificationCount = ResponseUtil.getTotalCount(data);
                                                        let dataArray = ResponseUtil.getDataArray<UserServiceProfile>(
                                                            data);
                                                        dataArray = this.supplierAcceptanceResults.concat(dataArray);
                                                        this.dataStoreService.set(
                                                            DataKey.supAcceptanceSearchResultsForCriteria,
                                                            dataArray,
                                                            true
                                                        );
                                                    }
                                                });
    }

    private subscribeSearchResultList() {
        this.fetchedNotificationsCount = 0;
        this.finalSearchResultsSubscription = this.dataStoreService.get(DataKey.supAcceptanceSearchResultsForCriteria)
                                                  .subscribe((data: UserServiceProfile[]) => {
                                                      this.supplierAcceptanceResults = [];
                                                      if (data != null) {
                                                          this.spinnerService.hide();

                                                          // **** result counts *** //
                                                          this.supplierAcceptanceResults = data;
                                                          this.fetchedNotificationsCount = this.supplierAcceptanceResults.length;

                                                          // **** custom buttons configuring *** //
                                                          this.paginatorLoaded = true;
                                                          if (this.fetchedNotificationsCount <
                                                              this.totalNotificationCount) {
                                                              this.enableMoreButton();
                                                          } else if (this.fetchedNotificationsCount >=
                                                              this.totalNotificationCount) {
                                                              this.disableMoreButton();
                                                          }
                                                      } else {
                                                          // **** clear previous data*** //
                                                          this.supplierAcceptanceResults = [];
                                                      }

                                                      // **** set genericGroupResults to grid*** //
                                                      this.setGridData();

                                                  });
    }

    // *********************ag grid implementation************************** //

    setGridData() {
        if (this.gridApi && this.supplierAcceptanceResults) {
            this.gridApi.setRowData(this.supplierAcceptanceResults);
            // clear grid sort filter marks on search from criteria component
            if (!this.searchFromSortingOrFiltering) {
                this.sortFilterModelProgrammaticallyChanging = true;
                this.gridApi.setSortModel(null);
                this.gridApi.setFilterModel(null);
            } else {
                this.searchFromSortingOrFiltering = false;
            }
            this.paginatorLoaded = true;
            this.gridApi.sizeColumnsToFit();
        } else {
            setTimeout(() => this.setGridData(), 0);
        }
    }

    private setColumnDefs() {
        this.columnDefsInput = this.columnDefinitionProcessorService
                                   .createColumnDefs(JSON.parse(JSON.stringify(this.colDefConfig)), this);
        this.cd.detectChanges();
    }

    private autoSizeAll() {
        const allColumnIds = [];
        if (this.gridColumnApi) {
            this.gridColumnApi.getAllColumns().forEach((column) => {
                allColumnIds.push(column.colId);
            });
            this.gridColumnApi.autoSizeColumns(allColumnIds);
        }
    }

    private enableMoreButton() {
        const config = [
            {
                buttonId: 'more',
                state: 'enable'
            }
        ];
        this.buttonState.next(config);
    }

    private disableMoreButton() {
        const config = [
            {
                buttonId: 'more',
                state: 'disable'
            }
        ];
        this.buttonState.next(config);
    }

    private searchResults() {
        this.addFilterModelToCriteria();
        this.addSortModelToCriteria();

        this.dataStoreService.set(DataKey.genReservationSearchResultsFromService, null, true);
        // this.genReservationManagementService.searchReservations(this.searchCriteria);
    }

    private addFilterModelToCriteria() {
        const filterModelArray = [];
        const filterModel = this.gridApi.getFilterModel();
        Object.keys(filterModel).forEach(key => {
            filterModel[key].colId = key;
            filterModelArray.push(filterModel[key]);
        });
        this.searchCriteria.gridFiltering = JSON.stringify(filterModelArray);
    }

    private addSortModelToCriteria() {
        const sortModel = this.gridApi.getSortModel();
        this.searchCriteria.gridSorting = JSON.stringify(sortModel);
    }

    private updateProfileStatus(userEmail: string, approvalData: UserApprovalData) {
        if (approvalData.kcDivision) {
            const divisions = this.getDivisions();
            if (divisions) {
                divisions.forEach(value => {
                    if (value.name === approvalData.kcDivision) {
                        approvalData.kcDivision = value.code;
                    }
                });
            }
        }

        if (approvalData.kcBrand) {
            const brands = this.getBrands();
            if (brands) {
                brands.forEach(value => {
                    if (value.name === approvalData.kcBrand) {
                        approvalData.kcBrand = value.code;
                    }
                });
            }
        }

        if (approvalData.kcDistributionChannel) {
            const distributionChannels = this.getDistributionChannels();
            if (distributionChannels) {
                distributionChannels.forEach(value => {
                    if (value.name === approvalData.kcDistributionChannel) {
                        approvalData.kcDistributionChannel = value.code;
                    }
                });
            }
        }

        this.userProfileService.updateUserTypeStatus(userEmail, approvalData)
            .subscribe(result => {
                if (this.commonHelper.dataValidity(result)) {
                    if (ResponseUtil.isSuccess(result)) {
                        this.common.showSnackBar(
                            'Successfully updated.',
                            3000,
                            TcErrorType.TYPE.INFO
                        );
                        // search data
                        this.userProfileService.getSupplierServiceProfiles(this.searchCriteria);
                        this.userProfileService.getPendingSupplierAcceptanceData();
                    } else {
                        this.common.showSnackBar(
                            'Failed to update.',
                            3000,
                            TcErrorType.TYPE.ERROR
                        );
                    }
                }
            });
    }

    private onSupplierTextChange(params: any, rowData: UserServiceProfile, text: string): Observable<SupplierSummary> {
        const supplierSearchCriteria = new SupplierSearchCriteria();
        supplierSearchCriteria.name = text;
        supplierSearchCriteria.status = 'true';
        if (rowData && rowData.supplierId) {
            supplierSearchCriteria.supplierId = rowData.supplierId;
        }
        return this.supplierService.searchSuppliersWithoutDataKey(supplierSearchCriteria)
                   .pipe(
                       map(
                           response => {
                               return ResponseUtil.getDataArray<SupplierSummary>(response);
                           }), catchError(
                           error => {
                               return [];
                           }
                       )
                   );
    }

    onGridReady(params) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.gridApi.setGroupHeaderHeight(40);
        this.gridApi.setHeaderHeight(40);
        this.autoSizeAll();
        this.gridApi.sizeColumnsToFit();
    }

    onRowSelected($event: any) {
        const selectedRows = this.gridApi.getSelectedRows();
        console.log(selectedRows);
    }

    onSortChanged($event: SortChangedEvent): void {
        if (!this.sortFilterModelProgrammaticallyChanging) {
            this.searchResults();
            this.searchFromSortingOrFiltering = true;
        } else {
            setTimeout(() => this.sortFilterModelProgrammaticallyChanging = false, 100);
        }
    }

    onFilterChanged($event: any) {
        if (!this.sortFilterModelProgrammaticallyChanging) {
            this.searchResults();
            this.searchFromSortingOrFiltering = true;
        } else {
            setTimeout(() => this.sortFilterModelProgrammaticallyChanging = false, 100);
        }
    }

    // used in ag grid col def by method reference
    onApprove(userData: UserServiceProfile) {
        const approvalData = new UserApprovalData();
        approvalData.approvalStatus = ApprovalStatus.APPROVED;
        approvalData.supplierId = userData.supplierId ? userData.supplierId : 0;
        approvalData.serviceTypeCode = userData.serviceTypeCode;
        approvalData.kcParent = userData.kcParent;
        approvalData.kcCompany = userData.kcCompany;
        approvalData.kcDivision = userData.kcDivision;
        approvalData.kcBrand = userData.kcBrand;
        approvalData.kcDistributionChannel = userData.kcDistributionChannel;
        this.updateProfileStatus(userData.email, approvalData);
    }

    onReject(userData: UserServiceProfile) {
        const approvalData: UserApprovalData = new UserApprovalData();
        approvalData.approvalStatus = ApprovalStatus.REJECTED;
        approvalData.supplierId = userData.supplierId ? userData.supplierId : 0;
        approvalData.serviceTypeCode = userData.serviceTypeCode;
        approvalData.kcParent = userData.kcParent;
        approvalData.kcCompany = userData.kcCompany;
        approvalData.kcDivision = userData.kcDivision;
        approvalData.kcBrand = userData.kcBrand;
        approvalData.kcDistributionChannel = userData.kcDistributionChannel;
        this.updateProfileStatus(userData.email, approvalData);
    }

    onPending(userData: UserServiceProfile) {
        const approvalData: UserApprovalData = new UserApprovalData();
        approvalData.approvalStatus = ApprovalStatus.PENDING;
        approvalData.supplierId = userData.supplierId ? userData.supplierId : 0;
        approvalData.serviceTypeCode = userData.serviceTypeCode;
        approvalData.kcParent = userData.kcParent;
        approvalData.kcCompany = userData.kcCompany;
        approvalData.kcDivision = userData.kcDivision;
        approvalData.kcBrand = userData.kcBrand;
        approvalData.kcDistributionChannel = userData.kcDistributionChannel;
        this.updateProfileStatus(userData.email, approvalData);
    }

    onGridSizeChange($event) {
        if (this.gridApi) {
            this.gridApi.sizeColumnsToFit();
        }
        // this.gridApi.autoSizeAllColumns();
    }

    openSupplierProfile(data: UserServiceProfile) {
        if (data && data.supplierId) {
            this.supplierService.retrieveSupplierById(data.supplierId)
                .subscribe(value => {
                    if (this.commonHelper.dataValidity(value)) {
                        const detailedSupplier = ResponseUtil.getFirstData(value);
                        this.userJourneyManager.goForKey('GO_TO_EDIT_TRANSPORT_SUPPLIER');
                        this.dataStoreService.set(DataKey.selectedSupplierForEdit, detailedSupplier, true);
                    }
                });
        } else {
            const supplier = new Supplier();
            supplier.supplierType = data.serviceType;

            this.userJourneyManager.goForKey('GO_TO_CREATE_SUPPLIER');
            this.dataStoreService.set(DataKey.selectedSupplierForEditAsNew, supplier, true);
        }
    }

    isRejectSupplierRestricted(serviceProfile: UserServiceProfile): boolean {
        return serviceProfile.approvalStatus === ApprovalStatus.REJECTED;
    }

    isApproveSupplierRestricted(serviceProfile: UserServiceProfile): boolean {
        return serviceProfile.approvalStatus === ApprovalStatus.APPROVED || serviceProfile.kcCompany === null;
    }

    isReviewSupplierRestricted(serviceProfile: UserServiceProfile): boolean {
        return !serviceProfile.supplierName;
    }

    setupResultHeader(count: number): string {
        return this.resultHeaderHeading = count + ' result' +
            (count > 1 ? 's' : '') + ' found.';
    }

    onClickColOption(event) {
        event.stopPropagation();
    }

    onRestoreColDefClick() {
        this.columnDefsInput = this.allColumnDefsInput;
        if (this.gridColumnApi) {
            this.gridColumnApi.resetColumnState();
            const allColumnIds = [];
            this.gridColumnApi.getAllColumns().forEach((column) => {
                allColumnIds.push(column.colId);
            });
            this.gridColumnApi.hideColumns(allColumnIds, false);
            this.colSelector.selectAll();
        }
    }

    onSelectionChangeColSelector(event) {
        if (event && event.option) {
            const option = event.option.value;
            const selected = event.option.selected;
            if (this.gridColumnApi) {
                this.gridColumnApi.setColumnVisible(option, selected);
                // this.gridApi.sizeColumnsToFit();
                this.autoSizeAll();
            }
        }
    }

    gridColumnVisibleChange(fieldName: string, isHide: boolean) {
        if (this.gridColumnApi) {
            this.gridColumnApi.setColumnVisible(fieldName, !isHide);
        }
        const val = (this.columnDefsInput.find(res => res.field === fieldName)).hide = isHide;
    }

    // used in ag grid col def by method reference
    getSuppliers = (params, rowData, text): Observable<SupplierSummary> => {
        return this.onSupplierTextChange(params, rowData, text);
    };

    onSetSupplier = (params): boolean => {
        const newSupplier: Supplier = params.newValue;
        const userServiceProfile: UserServiceProfile = params.data;
        if (newSupplier) {
            userServiceProfile.supplierId = newSupplier.supplierId;
            userServiceProfile.supplierCode = newSupplier.code;
            userServiceProfile.supplierName = newSupplier.name;
        }
        return true;
    };

    editCellValue(node: any) {
        if (node && node.data != null && node.newValue !== node.oldValue) {
            this.onPending(node.data);
        }
    }

    updateUserKC(user: any) {
        this.userProfileService.updateUserProfileKC(user).subscribe(res => {
            if (res) {
                this.userProfileService.getSupplierServiceProfiles(this.searchCriteria);
            }
        });
    }

    isEditableBrandRow = function(params: any) {
        return !this.hasABrand && (params && params.data && params.data.approvalStatus && params.data.approvalStatus !==
            ApprovalStatus.APPROVED && params.data.approvalStatus !== ApprovalStatus.REJECTED);
    };

    isEditableDivisionRow = function(params: any) {
        return !this.hasADivision &&
            (params && params.data && params.data.approvalStatus && params.data.approvalStatus !==
                ApprovalStatus.APPROVED && params.data.approvalStatus !== ApprovalStatus.REJECTED);
    };

    isEditableDistributionChannelRow = function(params: any) {
        return !this.hasADistributionChannel &&
            (params && params.data && params.data.approvalStatus && params.data.approvalStatus !==
                ApprovalStatus.APPROVED && params.data.approvalStatus !== ApprovalStatus.REJECTED);
    };

    displayKcCompanyInEditor = (params: any) => {
        let channel = 'Any';
        if (params.data && params.data.kcCompany && params.data.kcCompany.name) {
            return params.data.kcCompany.name;
        } else if (params.data && params.data.kcCompany && !params.data.kcCompany.name) {
            this.dataStoreService.get(DataKey.kcCompanies).subscribe((data) => {
                data.forEach(company => {
                    if (company && company.code === params.data.kcCompany) {
                        channel = company.name;
                    }
                });
            });
            return channel;
        } else {
            return channel;
        }
    };

    displayKcDistributionChannelInEditor = (params: any) => {
        let channel = 'Any';
        if (params.data && params.data.kcDistributionChannel && params.data.kcDistributionChannel.name) {
            return params.data.kcDistributionChannel.name;
        } else if (params.data && params.data.kcDistributionChannel && !params.data.kcDistributionChannel.name) {
            this.dataStoreService.get(DataKey.kcDistributionChannelList).subscribe((data) => {
                data.forEach(disChannel => {
                    if (disChannel && disChannel.code === params.data.kcDistributionChannel) {
                        channel = disChannel.name;
                    }
                });
            });
            return channel;
        } else {
            return channel;
        }
    };

    displayKcDivisionInEditor = (params: any) => {
        let division = 'Any';
        if (params.data && params.data.kcDivision && params.data.kcDivision.name) {
            return params.data.kcDivision.name;
        } else if (params.data && params.data.kcDivision && !params.data.kcDivision.name) {
            this.dataStoreService.get(DataKey.kcDivisions).subscribe((data) => {
                data.forEach(div => {
                    if (div && div.code === params.data.kcDivision) {
                        division = div.name;
                    }
                });
            });
            return division;
        } else {
            return division;
        }
    };

    displayKcBrandInEditor = (params: any) => {
        let kcBrand = 'Any';
        if (params.data && params.data.kcBrand && params.data.kcBrand.name) {
            return params.data.kcBrand.name;
        } else if (params.data && params.data.kcBrand && !params.data.kcBrand.name) {
            this.dataStoreService.get(DataKey.kcBrandsList).subscribe((data) => {
                data.forEach(brand => {
                    if (brand && brand.code === params.data.kcBrand) {
                        kcBrand = brand.name;
                    }
                });
            });
            return kcBrand;
        } else {
            return kcBrand;
        }
    };

    onSetBrand = (params) => {
        if (params && params.newValue && params.newValue.name) {
            params.data.kcBrand = params.newValue.code;
            params.data.kcBrandName = params.newValue.name;
        } else if (params && params.newValue && !params.newValue.name) {
            params.data.kcBrand = params.newValue;
        }
    };

    onSetDivision = (params) => {
        if (params && params.newValue && params.newValue.name) {
            params.data.kcDivision = params.newValue.code;
            params.data.kcDivisionName = params.newValue.name;
        } else if (params && params.newValue && !params.newValue.name) {
            params.data.kcDivision = params.newValue;
        }
    };

    onSetDistributionChannel = (params) => {
        if (params && params.newValue && params.newValue.name) {
            params.data.kcDistributionChannel = params.newValue.code;
            params.data.kcDistributionChannelName = params.newValue.name;
        } else if (params && params.newValue && !params.newValue.name) {
            params.data.kcDistributionChannel = params.newValue;
        }
    };

    public keyControlAvailability() {
        this.userProfile = this.dataStoreService.get(DataKey.userProfile).getValue();
        this.hasABrand = this.userProfile !== null && this.userProfile !== undefined && this.userProfile.kcBrand !==
            null && this.userProfile.kcBrand !== undefined;
        this.hasADivision = this.userProfile !== null && this.userProfile !== undefined &&
            this.userProfile.kcDivision !== null && this.userProfile.kcDivision !== undefined;
        this.hasADistributionChannel = this.userProfile !== null && this.userProfile !== undefined &&
            this.userProfile.kcDistributionChannel !== null && this.userProfile.kcDistributionChannel !== undefined;
    }

    getAvailableBrands() {
        let brandSearchCriteria = new BrandSearchCriteria();
        brandSearchCriteria = this.userManagementService.updateSearchCriteriaWithUserKC(brandSearchCriteria);
    }

    getAvailableDivisions() {
        let divisionSearchCriteria = new DivisionSearchCriteria();
        divisionSearchCriteria = this.userManagementService.updateSearchCriteriaWithUserKC(divisionSearchCriteria);
    }

    getAvailableDistributionChannels() {
        let distributionChannelSearchCriteria = new DistributionChannelSearchCriteria();
        distributionChannelSearchCriteria = this.userManagementService.updateSearchCriteriaWithUserKC(
            distributionChannelSearchCriteria);
    }

    getCompany() {
        let codeNames = new Set();
        const kcBrand = this.dataStoreService.get(DataKey.kcCompanies).subscribe((data) => {
            if (data) {
                codeNames = data;
            }
        });
        return codeNames;
    }

    getBrands() {
        let codeNames = new Set();
        const kcBrand = this.dataStoreService.get(DataKey.kcBrandsList).subscribe((data) => {
            if (data) {
                codeNames = data;
            }
        });
        return codeNames;
    }

    getDivisions() {
        let codeNames = new Set();
        const kcDivision = this.dataStoreService.get(DataKey.kcDivisions).subscribe((data) => {
            if (data) {
                codeNames = data;
            }
        });
        return codeNames;

    }

    getDistributionChannels() {
        let codeNames = new Set();
        const kcDistributionChannel = this.dataStoreService.get(DataKey.kcDistributionChannelList).subscribe((data) => {
            if (data) {
                codeNames = data;
            }
        });
        return codeNames;
    }

    private confirmModal(data: any): void {
        if (this.modelConfirmSubscription) {
            this.modelConfirmSubscription.unsubscribe();
        }
        this.modelConfirmSubscription = this.modalService.confirm(data).subscribe((res) => {
        });
    }

    onClickRoles(row: any) {
        this.confirmModal(
            new ModalData(
                LEVEL.SUCCESS,
                'Select Role',
                null,
                null,
                'modal-basic--alt modal-basic--fixed-size',
                UserRolesEditorComponent,
                {}
            )
        );
    }

    ngOnDestroy(): void {
        if (this.searchCriteriaSubscription) {
            this.searchCriteriaSubscription.unsubscribe();
        }
        if (this.searchResultSubscription) {
            this.searchResultSubscription.unsubscribe();
        }
        if (this.moreSearchResultSubscription) {
            this.moreSearchResultSubscription.unsubscribe();
        }
        if (this.finalSearchResultsSubscription) {
            this.finalSearchResultsSubscription.unsubscribe();
        }
        if (this.modelConfirmSubscription) {
            this.modelConfirmSubscription.unsubscribe();
        }
    }
}

