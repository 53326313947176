import * as tslib_1 from "tslib";
import { AbstractSearchCriteria } from './abstract-search-criteria';
var LanguageSearchCriteria = /** @class */ (function (_super) {
    tslib_1.__extends(LanguageSearchCriteria, _super);
    function LanguageSearchCriteria() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.code = '';
        _this.name = '';
        return _this;
    }
    return LanguageSearchCriteria;
}(AbstractSearchCriteria));
export { LanguageSearchCriteria };
