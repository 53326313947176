/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../res-calendar-side-nav/res-calendar-side-nav.component.ngfactory";
import * as i2 from "../res-calendar-side-nav/res-calendar-side-nav.component";
import * as i3 from "../../../../../node_modules/@tc/chip-dropdown/chip-dropdown.component.ngfactory";
import * as i4 from "@tc/chip-dropdown/chip-dropdown.component";
import * as i5 from "@angular/common";
import * as i6 from "../../../../../node_modules/@fullcalendar/angular/fullcalendar-angular.ngfactory";
import * as i7 from "@fullcalendar/angular";
import * as i8 from "./availability-calendar.component";
var styles_AvailabilityCalendarComponent = [];
var RenderType_AvailabilityCalendarComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AvailabilityCalendarComponent, data: {} });
export { RenderType_AvailabilityCalendarComponent as RenderType_AvailabilityCalendarComponent };
function View_AvailabilityCalendarComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-res-calendar-side-nav", [], null, [[null, "itemsSelected"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("itemsSelected" === en)) {
        var pd_0 = (_co.filterSelectedEvents($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_ResCalendarSideNavComponent_0, i1.RenderType_ResCalendarSideNavComponent)), i0.ɵdid(1, 114688, null, 0, i2.ResCalendarSideNavComponent, [], { navItems: [0, "navItems"], isRadioButton: [1, "isRadioButton"] }, { itemsSelected: "itemsSelected" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.navConfig; var currVal_1 = _co.isRadioButton; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_AvailabilityCalendarComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "tc-icon-block__icon"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "tc-chip-dropdown", [["class", "transfer-mode-selection"]], null, [[null, "selectedOption"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectedOption" === en)) {
        var pd_0 = (_co.onSelectViewOption($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_ChipDropdownComponent_0, i3.RenderType_ChipDropdownComponent)), i0.ɵdid(2, 49152, null, 0, i4.ChipDropdownComponent, [], { dropdownOptions: [0, "dropdownOptions"] }, { selectedOption: "selectedOption" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.viewOptions; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_AvailabilityCalendarComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { fullCalendarComponent: 0 }), (_l()(), i0.ɵeld(1, 0, null, null, 9, "div", [["class", "tc-ac__container-main"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "div", [["class", "tc-ac__container-left"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AvailabilityCalendarComponent_1)), i0.ɵdid(4, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(5, 0, null, null, 5, "div", [["class", "tc-ac__container-right"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 2, "div", [["class", "tc-sc-summery-panel__action-header"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AvailabilityCalendarComponent_2)), i0.ɵdid(8, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(9, 0, null, null, 1, "full-calendar", [["class", "tc-ac-full-calendar"]], null, [[null, "eventClick"], [null, "eventRender"], [null, "select"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("eventClick" === en)) {
        var pd_0 = (_co.onEventClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("eventRender" === en)) {
        var pd_1 = (_co.eventRender($event) !== false);
        ad = (pd_1 && ad);
    } if (("select" === en)) {
        var pd_2 = (_co.onDateClick($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i6.View_FullCalendarComponent_0, i6.RenderType_FullCalendarComponent)), i0.ɵdid(10, 7258112, [[1, 4], ["calendar", 4]], 0, i7.FullCalendarComponent, [i0.ElementRef], { eventLimit: [0, "eventLimit"], eventLimitClick: [1, "eventLimitClick"], defaultView: [2, "defaultView"], selectable: [3, "selectable"], selectOverlap: [4, "selectOverlap"], events: [5, "events"], plugins: [6, "plugins"] }, { eventClick: "eventClick", select: "select", eventRender: "eventRender" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.navConfig && (_co.navConfig.length > 0)); _ck(_v, 4, 0, currVal_0); var currVal_1 = (_co.viewOptions && (_co.viewOptions.length > 0)); _ck(_v, 8, 0, currVal_1); var currVal_2 = true; var currVal_3 = "popover"; var currVal_4 = _co.defaultView; var currVal_5 = true; var currVal_6 = true; var currVal_7 = _co.calendarEvents; var currVal_8 = _co.calendarPlugins; _ck(_v, 10, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }, null); }
export function View_AvailabilityCalendarComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-availability-calendar", [], null, null, null, View_AvailabilityCalendarComponent_0, RenderType_AvailabilityCalendarComponent)), i0.ɵdid(1, 114688, null, 0, i8.AvailabilityCalendarComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AvailabilityCalendarComponentNgFactory = i0.ɵccf("tc-availability-calendar", i8.AvailabilityCalendarComponent, View_AvailabilityCalendarComponent_Host_0, { calendarEvents: "calendarEvents", navConfig: "navConfig", calendarPlugins: "calendarPlugins", isRadioButton: "isRadioButton", viewOptions: "viewOptions" }, { dateClick: "dateClick", select: "select", eventClick: "eventClick", sideNavItemClick: "sideNavItemClick" }, []);
export { AvailabilityCalendarComponentNgFactory as AvailabilityCalendarComponentNgFactory };
