import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { TcApiError } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-api-error';
import { TcErrorType } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-error';
import { TcHttpError } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-http-error';
import { TC } from '@tc-core/util';
import { ConfigLoader, EventManager, FocusViewManager, UserJourneyManager } from '@tc-core/util/framework';
import { CommonHelper } from '@tc-core/util/helpers';
import { SpinnerService } from '@tc-core/util/ui';
import { FocusViewService } from '@tc/focus-view/focus-view.service';
import { Subscription } from 'rxjs';
import { SetupGridComp } from '../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid-comp';
import { TCO } from '../../constants';
import { LocationsSearchCriteria } from '../../models/criteria/locations-search-criteria';
import { LocationsService } from '../../services/backend-consumers/setups/locations.service';
import { DMCCommon } from '../../services/util/common/dmc-common';
import { DataKey, DataStoreService } from '../../services/util/framework/data-store.service';
import { ResponseUtil } from '../../services/util/response/response-util.service';
var EMPTY_CHAR = TC.FORMAT.EMPTY_CHAR;
var LocationSelectionFocusViewComponent = /** @class */ (function (_super) {
    tslib_1.__extends(LocationSelectionFocusViewComponent, _super);
    function LocationSelectionFocusViewComponent(locationsService, dataStoreService, configLoader, journeyManager, focusViewManager, commonHelper, spinnerService, common, focusViewService, em) {
        var _this = _super.call(this) || this;
        _this.locationsService = locationsService;
        _this.dataStoreService = dataStoreService;
        _this.configLoader = configLoader;
        _this.journeyManager = journeyManager;
        _this.focusViewManager = focusViewManager;
        _this.commonHelper = commonHelper;
        _this.spinnerService = spinnerService;
        _this.common = common;
        _this.focusViewService = focusViewService;
        _this.em = em;
        _this.pageSize = 20;
        _this.searchPage = 0;
        _this.sortBy = 'id';
        _this.sortDirection = 'ASC';
        _this.leftSideData = [];
        _this.rightSideData = [];
        _this.colDefConfigLeft = [];
        _this.colDefConfigRight = [];
        _this.colDefConfig = [];
        _this.locationsSearchCriteria = new LocationsSearchCriteria();
        _this.searchCriteriaObserver = new Subscription();
        _this.saveLocationsObsv = new Subscription();
        /*
         grid config methods which are called by grid setup (ag grid)
         and set by grid config
         */
        _this.getLocationTypeText = function (params) {
            var typeText = '';
            if (params && params.data) {
                if (params.data.locationTypeName) {
                    typeText = params.data.locationTypeName;
                }
                else if (params.data.locationType) {
                    typeText = params.data.locationType;
                }
                else {
                    typeText = EMPTY_CHAR;
                }
            }
            return typeText;
        };
        _this.getLocationGroupText = function (params) {
            var groupText = EMPTY_CHAR;
            if (params && params.data && params.data.locationGroup) {
                if (params.data.locationGroup.code) {
                    groupText = params.data.locationGroup.code;
                }
                else {
                    groupText = EMPTY_CHAR;
                }
                groupText += ' | ';
                if (params.data.locationGroup.name) {
                    groupText += params.data.locationGroup.name;
                }
                else {
                    groupText += EMPTY_CHAR;
                }
            }
            return groupText;
        };
        _this.getLocationTypes = function (params, rowData, text) {
            return _this.dataStoreService.get(DataKey.locationTypes)
                .map(function (aEvents) {
                return aEvents.filter(function (aEvent) {
                    if (text) {
                        if (aEvent.name) {
                            return aEvent.name.toLowerCase().indexOf(text.toLocaleString()) > -1;
                        }
                    }
                    else {
                        return true;
                    }
                });
            });
        };
        _this.getLocationGroups = function (params, rowData, text) {
            return _this.dataStoreService.get(DataKey.locationGroups)
                .map(function (aEvents) {
                return aEvents.filter(function (aEvent) {
                    if (text) {
                        if (aEvent.name) {
                            return aEvent.name.toLowerCase().indexOf(text.toLocaleString()) > -1;
                        }
                    }
                    else {
                        return true;
                    }
                });
            });
        };
        return _this;
    }
    LocationSelectionFocusViewComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.identityFunction = function (row) {
            return row.code;
        };
        this.subscribeSearchCriteria();
        this.getLeftSideRows();
        this.journeyManager.canProceed.next(true);
        this.focusViewManager.canProceed.next(true);
        this.focusViewManager.canProceedState.next(true);
        this.colDefConfig = this.configLoader.configurations.get(TCO.CONF.CONF_LOCATIONS_SELECTION_GRID);
        this.colDefConfigLeft = this.colDefConfig;
        this.colDefConfigRight = this.colDefConfig;
        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'CLOSE_FOCUS_VIEW').subscribe(function (e) {
            _this.onSaveLocations();
        });
    };
    LocationSelectionFocusViewComponent.prototype.onSaveLocations = function () {
        var _this = this;
        this.dataStoreService.set(DataKey.saveLocationGroups, null, true);
        this.rightSideData.forEach(function (value) {
            value.locationGroup = _this.locationGroup.code;
        });
        // this.locationGroup.locations = this.rightSideData;
        this.locationsService.saveLocationGroupWithDataKey(this.locationGroup.code, this.rightSideData);
        this.dataStoreService.set(DataKey.locationSelectionFocusViewClose, null);
        this.dataStoreService.set(DataKey.locationSelectionFocusViewClose, true);
        this.focusViewService.close(true);
        this.saveLocationsObserver();
    };
    /**
     * subscribe for data save response
     */
    LocationSelectionFocusViewComponent.prototype.saveLocationsObserver = function () {
        var _this = this;
        this.saveLocationsObsv = this.dataStoreService.get(DataKey.saveLocationGroups, true).subscribe(function (value) {
            if (value) {
                // this.saveLocationsObsv.unsubscribe();
                // this.dataStoreService.set(DataKey.saveLocationGroups, null, true);
                _this.spinnerService.hide();
                if (ResponseUtil.isSuccess(value)) {
                    _this.common.showSnackBar('Successfully saved locations.', 3000, TcErrorType.TYPE.INFO);
                    _this.dataStoreService.set(DataKey.locationSelectionFocusViewClose, null);
                    _this.dataStoreService.set(DataKey.locationSelectionFocusViewClose, true);
                    _this.focusViewService.close(true);
                }
                else if (ResponseUtil.isError(value)) {
                    _this.common.showSnackBar('Failed to save the locations', 3000, TcErrorType.TYPE.ERROR);
                }
            }
            else if (value instanceof TcApiError) {
                _this.spinnerService.hide();
            }
            else if (value instanceof TcHttpError) {
                _this.spinnerService.hide();
            }
        }, function (error) {
        });
    };
    LocationSelectionFocusViewComponent.prototype.getLeftSideRows = function () {
        var _this = this;
        this.locationsSearchCriteria = this.locationsSearchCriteria ? this.locationsSearchCriteria : new LocationsSearchCriteria();
        this.locationsSearchCriteria.size = 100000;
        this.locationsService.getLocations(this.locationsSearchCriteria).subscribe(function (data) {
            if (data) {
                _this.leftSideData = ResponseUtil.getDataArray(data);
                _this.dataStoreService.set(DataKey.locationsSearchResultsForCriteria, _this.leftSideData, true);
            }
        });
    };
    LocationSelectionFocusViewComponent.prototype.saveRow = function (row) {
        throw new Error('Method not implemented.');
    };
    LocationSelectionFocusViewComponent.prototype.deleteRow = function (row) {
        throw new Error('Method not implemented.');
    };
    LocationSelectionFocusViewComponent.prototype.isInvalidRow = function (params) {
        throw new Error('Method not implemented.');
    };
    LocationSelectionFocusViewComponent.prototype.isUnsavedRow = function (row) {
        throw new Error('Method not implemented.');
    };
    LocationSelectionFocusViewComponent.prototype.createNewRow = function () {
        throw new Error('Method not implemented.');
    };
    /**
     * subscribe search criteria changes in search bar
     * then force grid to refresh data
     * grid refresh will be call getRaws method which is using this subscribed criteria for backend call
     */
    LocationSelectionFocusViewComponent.prototype.subscribeSearchCriteria = function () {
        var _this = this;
        this.searchCriteriaObserver = this.dataStoreService.get(DataKey.locationsSearchCriteria)
            .subscribe(function (value) {
            _this.locationsSearchCriteria = value;
            _this.getLeftSideRows();
        });
    };
    /*onSearch() {
     return this.dataStoreService.get(DataKey.locationsSearchResultsForCriteria)
     .subscribe(
     data => {
     this.leftSideData = this.getDataFromResponse(data);
     });
     }*/
    LocationSelectionFocusViewComponent.prototype.getDataFromResponse = function (response) {
        var data = [];
        if (response) {
            data = response['content'];
        }
        return data;
    };
    LocationSelectionFocusViewComponent.prototype.onLogRightSideData = function () {
        if (this.rightSideData) {
            console.table(this.rightSideData);
        }
    };
    return LocationSelectionFocusViewComponent;
}(SetupGridComp));
export { LocationSelectionFocusViewComponent };
