import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { AgGridModule } from 'ag-grid-angular';
import { AgGridCustomRenderersModule } from '../../../../../widgets/framework/ag-grid-custom-components/renderers/ag-grid-custom-renderers.module';
import { CheckBoxRendererEditorComponent } from '../../../../../widgets/framework/ag-grid-custom-components/renderers/check-box-selector-renderer/check-box-renderer-editor.component';
import { PassengerSelectionComponent } from './passenger-selection.component';

@NgModule({
    declarations: [PassengerSelectionComponent],
    imports: [
        CommonModule,
        TranslateModule.forChild(),
        AgGridCustomRenderersModule,
        AgGridModule.withComponents([
            CheckBoxRendererEditorComponent
        ])
    ],
    exports: [
        PassengerSelectionComponent
    ],
    entryComponents: [
        PassengerSelectionComponent
    ]
})
export class PassengerSelectionModule {}
