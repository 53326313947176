import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {TC} from '@tc-core/util';
import {ConfigLoader} from '@tc-core/util/framework';
import {IGetRowsParams, RowNode} from 'ag-grid-community';
import {Observable, of, Subscription} from 'rxjs';
import {tap} from 'rxjs/operators';
import {SetupGridComp} from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid-comp';
import {SetupGridDataSource} from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid-data-source';
import {SetupGridComponent} from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid.component';
import {TCO} from '../../../constants';
import {LocationGroupsSearchCriteria} from '../../../models/criteria/location-groups-search-criteria';
import {RoutesSearchCriteria} from '../../../models/criteria/routes-search-criteria';
import {SortDirection} from '../../../models/helper/sort-direction';
import {DataHandlerService} from '../../../services/backend-consumers/master-data-handler-service/data-handler.service';
import {LocationsService} from '../../../services/backend-consumers/setups/locations.service';
import {DataKey, DataStoreService} from '../../../services/util/framework/data-store.service';
import EMPTY_CHAR = TC.FORMAT.EMPTY_CHAR;

@Component({
    selector: 'tc-routes-grid-setup',
    templateUrl: './routes-grid-setup.component.html'
})
export class RoutesGridSetupComponent extends SetupGridComp implements OnInit, OnDestroy, SetupGridDataSource {

    @ViewChild('setupGrid')
    private setupGrid: SetupGridComponent;
    public colDefConfig = [];

    private routesSearchCriteria: RoutesSearchCriteria = new RoutesSearchCriteria();

    // required options for grid editors
    // nothing

    private searchCriteriaObserver: Subscription = new Subscription();

    constructor(
        private configLoader: ConfigLoader,
        private dataStoreService: DataStoreService,
        private dataHandlerService: DataHandlerService,
        private locationsService: LocationsService
    ) {
        super();
    }

    ngOnInit() {
        this.colDefConfig = this.configLoader.configurations.get(TCO.CONF.CONF_ROUTES_SETUP);
        this.subscribeSearchCriteria();
        this.fillRequiredGridOptionData();
        this.fillDataKeyForEditorData();
    }

    /**
     * subscribe search criteria changes in search bar
     * then force grid to refresh data
     * grid refresh will be call getRaws method which is using this subscribed criteria for backend call
     */
    subscribeSearchCriteria() {
        this.searchCriteriaObserver = this.dataStoreService.get(DataKey.routesSearchCriteria)
            .subscribe(value => {
                this.routesSearchCriteria = value;
                this.setupGrid.runForceSearch();
            });
    }

    private fillRequiredGridOptionData() {
        // nothing
    }

    private fillDataKeyForEditorData() {
        this.addLocationGroups();
    }

    private addLocationGroups() {
        const locationGroupsSearchCriteria = new LocationGroupsSearchCriteria();
        locationGroupsSearchCriteria.size = 1000000;
        this.locationsService.getLocationGroups(locationGroupsSearchCriteria).subscribe(
            result => {
                this.dataStoreService.set(DataKey.locationGroups, result['data'], true);
            });
    }

    public isInvalidRow(params): boolean {
        const data = params.data;
        if (data) {
            let invalid = true;
            if (
                data.code &&
                data.fromGroup &&
                data.toGroup
            ) {
                invalid = false;
            }
            return invalid;
        } else {
            return false;
        }
    }

    public createNewRow(): any {
        return {unsaved: true};
    }

    public isUnsavedRow(row: any): boolean {
        return !!row.unsaved;
    }

    public deleteRow(row: any) {
        if (row && row.code) {
            return this.locationsService.deleteRoute(row.code);
        } else {
            return of('success');
        }
    }

    public saveRow(row: any, event: any) {
        return this.locationsService.saveRoute(row)
            .pipe(tap(data => row.unsaved = undefined));
    }

    public getRows(params: IGetRowsParams): Observable<any> {
        console.log('fetch requesting for ' + params.startRow + ' to ' + params.endRow);
        console.log(params.sortModel);

        const pageSize = params.endRow - params.startRow;
        if (!this.routesSearchCriteria) {
            this.routesSearchCriteria = new RoutesSearchCriteria();
        }
        this.routesSearchCriteria.start = params.startRow;
        this.routesSearchCriteria.size = pageSize;
        if (params.sortModel && params.sortModel[0]) {
            this.routesSearchCriteria.sortBy = params.sortModel[0].colId;
            if (params.sortModel[0].sort === SetupGridComp.GRID_SORT_ASCENDING) {
                this.routesSearchCriteria.sortDirection = SortDirection.ASC;
            } else {
                this.routesSearchCriteria.sortDirection = SortDirection.DESC;
            }
        } else {
            this.routesSearchCriteria.sortBy = 'createdOn';
            this.routesSearchCriteria.sortDirection = SortDirection.DESC;
        }
        return this.locationsService.getRoutes(this.routesSearchCriteria)
            .pipe(
                tap(data =>
                    this.dataStoreService.set(DataKey.routesSearchResultsForCriteria, data)
                )
            );
    }

    public ngOnDestroy(): void {
        if (this.searchCriteriaObserver) {
            this.searchCriteriaObserver.unsubscribe();
        }
    }

    /*
     grid config methods which are called by grid setup (ag grid)
     and set by grid config
     */

    getFromLocationGroupText = (params) => {
        let groupText = EMPTY_CHAR;
        if (params && params.data) {
            if (params.data.fromGroupEntity) {
                if (params.data.fromGroupEntity.code) {
                    groupText = params.data.fromGroupEntity.code;
                } else {
                    groupText = EMPTY_CHAR;
                }
                groupText += ' | ';
                if (params.data.fromGroupEntity.name) {
                    groupText += params.data.fromGroupEntity.name;
                } else {
                    groupText += EMPTY_CHAR;
                }
            } else if (params.data.fromGroup) {
                groupText = params.data.fromGroup;
            }
        }
        return groupText;
    };

    getToLocationGroupText = (params) => {
        let groupText = EMPTY_CHAR;
        if (params && params.data) {
            if (params.data.toGroupEntity) {
                if (params.data.toGroupEntity.code) {
                    groupText = params.data.toGroupEntity.code;
                } else {
                    groupText = EMPTY_CHAR;
                }
                groupText += ' | ';
                if (params.data.toGroupEntity.name) {
                    groupText += params.data.toGroupEntity.name;
                } else {
                    groupText += EMPTY_CHAR;
                }
            } else if (params.data.toGroup) {
                groupText = params.data.toGroup;
            }
        }
        return groupText;
    };

    getLocationGroups = (params, rowData, text) => {
        return this.dataStoreService.get(DataKey.locationGroups)
            .map(
                aEvents =>
                    aEvents.filter(aEvent => {
                            if (text) {
                                if (aEvent.name) {
                                    if (aEvent.name.toLowerCase().indexOf(text.toLowerCase().toLocaleString()) > -1) {
                                        return aEvent.name.toLowerCase().indexOf(text.toLowerCase().toLocaleString()) > -1;
                                    } else {
                                        return aEvent.code.toLowerCase().indexOf(text.toLowerCase().toLocaleString()) > -1;
                                    }
                                }
                            } else {
                                return true;
                            }
                        }
                    )
            );
    };

    onFromGroupEntitySet = (params) => {
        if (params.data && params.newValue) {
            params.data['fromGroupEntity'] = params.newValue;
            params.data['fromGroup'] = params.newValue['code'];
        }
        return true;
    };

    onToGroupEntitySet = (params) => {
        if (params.data && params.newValue) {
            params.data['toGroupEntity'] = params.newValue;
            params.data['toGroup'] = params.newValue['code'];
        }
        return true;
    };
}
