import * as tslib_1 from "tslib";
var BookingPassenger = /** @class */ (function () {
    function BookingPassenger() {
    }
    BookingPassenger.contentEquals = function (p1, p2) {
        var e_1, _a;
        var attOk = true;
        if (!(p1.passengerAttributes && p2.passengerAttributes) && !(!p1.passengerAttributes && !p2.passengerAttributes)) {
            attOk = false;
        }
        if (attOk && p1.passengerAttributes && p1.passengerAttributes.length) {
            var _loop_1 = function (p1Att) {
                var found = p2.passengerAttributes.find(function (p2Att) { return p2Att.name === p1Att.name && p2Att.value === p1Att.value; });
                if (!found) {
                    attOk = false;
                    return "break";
                }
            };
            try {
                for (var _b = tslib_1.__values(p1.passengerAttributes), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var p1Att = _c.value;
                    var state_1 = _loop_1(p1Att);
                    if (state_1 === "break")
                        break;
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_1) throw e_1.error; }
            }
        }
        return attOk && p1.type === p2.type
            && p1.title === p2.title
            && p1.firstName === p2.firstName
            && p1.middleName === p2.middleName
            && p1.lastName === p2.lastName
            && p1.telephone === p2.telephone
            && p1.email === p2.email
            && p1.passportNo === p2.passportNo
            && p1.citizenship === p2.citizenship
            && p1.houseNo === p2.houseNo
            && p1.street === p2.street
            && p1.city === p2.city
            && p1.country === p2.country
            && p1.postalCode === p2.postalCode;
    };
    return BookingPassenger;
}());
export { BookingPassenger };
