import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SupplierAttributes } from '../../../models/common/supplier-attributes';

@Component({
    selector: 'tc-supplier-attributes',
    templateUrl: './supplier-attributes.component.html'
})
export class SupplierAttributesComponent implements OnInit {
    @Input() supplierAttributes: SupplierAttributes[] = [];

    constructor(
    ) {
    }

    ngOnInit() {
    }

}

