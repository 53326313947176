import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChipInputModule } from '@tc/chip-input';
import { NotificationQueueSearchCriteriaComponent } from './notification-queue-search-criteria.component';

@NgModule({
  declarations: [NotificationQueueSearchCriteriaComponent],
    imports: [
        CommonModule,
        ChipInputModule
    ],
    exports: [
        NotificationQueueSearchCriteriaComponent
    ],
    entryComponents: [
        NotificationQueueSearchCriteriaComponent
    ]
})
export class NotificationQueueSearchCriteriaModule { }
