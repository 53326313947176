import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DashboardSearchItem } from '../../../models/launchpad/dashboard-search-item';
import { OPResponseWrapper } from '../../../models/response/op-response-wrapper';
import { DMCQueryParamsService } from '../../util/framework/dmc-query-params.service';
import { RequestService } from '../../util/framework/request.service';
import { UrlPaths } from '../url-paths';

@Injectable({
    providedIn: 'root'
})
export class DashboardSearchService {
    constructor(
        private queryParamsService: DMCQueryParamsService,
        private requestService: RequestService,
        private http: HttpClient
    ) {}

    public searchDashboardAssignmentItems(text: string): Observable<OPResponseWrapper<DashboardSearchItem>> {
        const params = this.queryParamsService.asQueryParams({text});
        return this.requestService.get(
            UrlPaths.OP_RES_URL_KEY,
            [UrlPaths.OP_RES_VERSION, UrlPaths.DASHBOARD_SEARCH_ITEM_V2],
            params
        );
    }

    public searchDashboardSupplierResourceItems(text: string): Observable<OPResponseWrapper<DashboardSearchItem>> {
        const params = this.queryParamsService.asQueryParams({text});
        return this.requestService.get(
            UrlPaths.OP_SUP_URL_KEY,
            [UrlPaths.OP_SUP_VERSION, UrlPaths.DASHBOARD_SEARCH_ITEM_V2],
            params
        );
    }
}
