import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { User } from '@tc-core/model/it/codegen/tbx/api/authority/v2/users/user';
import { TcErrorType } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-error';
import { Criteria } from '../../../models/criteria/criteria';
import { ReservationSearchCriteria } from '../../../models/criteria/reservation-search-criteria';
import { SavedCriteriaV2 } from '../../../models/criteria/saved-criteria-v2';
import { ProductQueueSearchCriteria } from '../../../models/reservation-v2/criteria/product-queue-search-criteria';
import { DataHandlerService } from '../../../services/backend-consumers/master-data-handler-service/data-handler.service';
import { ReservationManagementService } from '../../../services/backend-consumers/reservation-management-services/reservation-management.service';
import { ReservationV2ManagementService } from '../../../services/backend-consumers/reservation-v2/reservation-v2-management.service';
import { DMCCommon } from '../../../services/util/common/dmc-common';
import { DataKey, DataStoreService } from '../../../services/util/framework/data-store.service';
import { ResponseUtil } from '../../../services/util/response/response-util.service';

@Component({
    selector: 'tc-search-criteria-save-modal',
    templateUrl: './search-criteria-save-modal.component.html'
})
export class SearchCriteriaSaveModalComponent implements OnInit {
    changesAvailable = false;
    @Input() searchCriteria: ReservationSearchCriteria;
    @Input() searchCriteriaV2: ProductQueueSearchCriteria;
    criteriaName = new FormControl('');
    private user: User;

    constructor(
        private dataStore: DataStoreService,
        private dataHandlerService: DataHandlerService,
        private common: DMCCommon,
        private reservationV2ManagementService: ReservationV2ManagementService,
        private reservationManagementService: ReservationManagementService
    ) { }

    ngOnInit() {
        this.user = this.dataStore.get(DataKey.userDetail).getValue();
    }

    onSave() {
        // For V1 Queue
        if (this.searchCriteria) {
            let saveCriteria = new Criteria();
            saveCriteria.criteria = this.searchCriteria;
            if (!this.searchCriteria.assignedStatus) {saveCriteria.criteria.assignedStatus = null;}
            saveCriteria.name = this.criteriaName.value;
            saveCriteria.userId = this.dataStore.get(DataKey.userDetail).getValue().id;
            // this.saveSearchCriteriaV1(saveCriteria);
        }
        // For V2 Queue
        else if (this.searchCriteriaV2) {
            let savedCriteria = new SavedCriteriaV2();
            savedCriteria.criteria = this.searchCriteriaV2;
            if(savedCriteria.criteria) {
                savedCriteria.criteria.startDate = new Date(this.searchCriteriaV2.startDate);
                savedCriteria.criteria.endDate = new Date(this.searchCriteriaV2.endDate);
            }
            savedCriteria.criteriaName = this.criteriaName.value;
            savedCriteria.userId = this.dataStore.get(DataKey.userDetail).getValue().id;
            this.saveSearchCriteriaV2(savedCriteria);
        }

        this.dataStore.set(DataKey.popupClose, true);
    }

    public onClose($event: MouseEvent) {
        this.dataStore.set(DataKey.popupClose, true);
    }

    // saveSearchCriteriaV1(saveCriteria: Criteria) {
    //     this.reservationManagementService.saveSearchCriteria(saveCriteria).subscribe(data => {
    //         if (data) {
    //             this.common.showSnackBar('Search Criteria Saved Successfully.', 3000, TcErrorType.TYPE.INFO);
    //             this.getUserCriteria();
    //         }
    //     }, error => {
    //         this.common.showSnackBar('Failed to save Search Criteria.', 3000, TcErrorType.TYPE.ERROR);
    //     });
    // }

    getUserCriteria() {
        this.dataStore.set(DataKey.userSavedSearchCriteriaListV2, null, true);
        this.reservationV2ManagementService.getUserSearchCriteriaList(this.user.id).subscribe(result => {
            if (result) {
                let criterias = ResponseUtil.getDataArray<SavedCriteriaV2>(result).sort();
                this.dataStore.set(DataKey.userSavedSearchCriteriaListV2, criterias, true);
            }
        });
    }

    private saveSearchCriteriaV2(criteria: SavedCriteriaV2) {
        this.reservationV2ManagementService.saveSearchCriteria(criteria).subscribe(data => {
            if (data) {
                this.common.showSnackBar('Search Criteria Saved Successfully.', 3000, TcErrorType.TYPE.INFO);
                this.getUserCriteriaV2();
            }
        }, error => {
            this.common.showSnackBar('Failed to save Search Criteria.', 3000, TcErrorType.TYPE.ERROR);
        });
    }

    private getUserCriteriaV2() {
        this.dataStore.set(DataKey.userSavedSearchCriteriaListV2, null, true);
        this.reservationV2ManagementService.getUserSearchCriteriaList(this.user.id).subscribe(result => {
            if (result) {
                let criterias = ResponseUtil.getDataArray<SavedCriteriaV2>(result).sort();
                this.dataStore.set(DataKey.userSavedSearchCriteriaListV2, criterias, true);
            }
        });
    }
}
