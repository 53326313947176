import * as tslib_1 from "tslib";
import { OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TcErrorType } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-error';
import { DialogModel } from '@tc-core/model/it/codegen/ui/framework/dialog-model';
import { LEVEL } from '@tc-core/model/it/codegen/ui/framework/modal-data';
import { TC } from '@tc-core/util';
import { ConfigLoader, EventManager, UserJourneyManager } from '@tc-core/util/framework';
import { SpinnerService } from '@tc-core/util/ui/spinner.service';
import { DialogService } from '@tc/dialog/dialog.service';
import { FocusViewService } from '@tc/focus-view/focus-view.service';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SetupGridComp } from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid-comp';
import { SetupGridComponent } from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid.component';
import { TCO } from '../../../constants';
import { LedgerEntrySearchCriteria } from '../../../models/criteria/ledger-entry-search-criteria';
import { SortDirection } from '../../../models/helper/sort-direction';
import { DataHandlerService } from '../../../services/backend-consumers/master-data-handler-service/data-handler.service';
import { AccountingRuleService } from '../../../services/backend-consumers/setups/accounting-rule.service';
import { DMCCommon } from '../../../services/util/common/dmc-common';
import { DataKey, DataStoreService } from '../../../services/util/framework/data-store.service';
var LedgerEntrySearchResultsComponent = /** @class */ (function (_super) {
    tslib_1.__extends(LedgerEntrySearchResultsComponent, _super);
    function LedgerEntrySearchResultsComponent(configLoader, dataStoreService, dataHandlerService, accountingRuleService, activatedRoute, focusViewService, dataStore, route, router, em, dialogService, common, userJourneyManager, spinnerService) {
        var _this = _super.call(this) || this;
        _this.configLoader = configLoader;
        _this.dataStoreService = dataStoreService;
        _this.dataHandlerService = dataHandlerService;
        _this.accountingRuleService = accountingRuleService;
        _this.activatedRoute = activatedRoute;
        _this.focusViewService = focusViewService;
        _this.dataStore = dataStore;
        _this.route = route;
        _this.router = router;
        _this.em = em;
        _this.dialogService = dialogService;
        _this.common = common;
        _this.userJourneyManager = userJourneyManager;
        _this.spinnerService = spinnerService;
        _this.colDefConfig = [];
        _this.ledgerEntrySearchCriteria = new LedgerEntrySearchCriteria();
        // required options for grid editors
        // nothing
        _this.gridActions = [];
        _this.headerActions = [
            {
                icon: 'autorenew',
                tooltip: 'Generate Ledgers',
                action: _this.onLedgerGenerationClick.bind(_this),
                disable: false
            }
        ];
        _this.searchCriteriaObserver = new Subscription();
        _this.focusViewDataChangeObserver = new Subscription();
        _this.focusViewConfirmObserver = new Subscription();
        _this.focusViewCloseObserver = new Subscription();
        _this.routeDataSubscription = new Subscription();
        _this.routeParamsSubscription = new Subscription();
        _this.routeEventSubscription = new Subscription();
        _this.focusCloseSubscription = new Subscription();
        _this.isFocusViewDataChanged = false;
        return _this;
    }
    LedgerEntrySearchResultsComponent.prototype.ngOnInit = function () {
        this.colDefConfig = this.configLoader.configurations.get(TCO.CONF.CONF_LEDGER_ENTRY_GRID);
        this.subscribeSearchCriteria();
        this.fillRequiredGridOptionData();
        this.addFabActionEvents();
        this.userJourneyManager.canProceed.next(true);
    };
    LedgerEntrySearchResultsComponent.prototype.addFabActionEvents = function () {
        var _this = this;
        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'GENERATE_LEDGERS').subscribe(function (e) {
            _this.onLedgerGenerationClick();
        });
    };
    /**
     * subscribe search criteria changes in search bar
     * then force grid to refresh data
     * grid refresh will be call getRaws method which is using this subscribed criteria for backend call
     */
    LedgerEntrySearchResultsComponent.prototype.subscribeSearchCriteria = function () {
        var _this = this;
        this.searchCriteriaObserver = this.dataStoreService.get(DataKey.ledgerEntrySearchCriteria)
            .subscribe(function (value) {
            _this.ledgerEntrySearchCriteria = value;
            if (_this.ledgerEntrySearchCriteria) {
                _this.setupGrid.runForceSearch();
            }
        });
    };
    LedgerEntrySearchResultsComponent.prototype.fillRequiredGridOptionData = function () {
        // nothing
    };
    LedgerEntrySearchResultsComponent.prototype.isInvalidRow = function (params) {
        return false;
    };
    LedgerEntrySearchResultsComponent.prototype.createNewRow = function () {
        return { unsaved: true };
    };
    LedgerEntrySearchResultsComponent.prototype.isUnsavedRow = function (row) {
        return !!row.unsaved;
    };
    LedgerEntrySearchResultsComponent.prototype.deleteRow = function (row) {
        return null;
    };
    LedgerEntrySearchResultsComponent.prototype.saveRow = function (row, event) {
        return null;
    };
    LedgerEntrySearchResultsComponent.prototype.getRows = function (params) {
        var _this = this;
        console.log('fetch requesting for ' + params.startRow + ' to ' + params.endRow);
        console.log(params.sortModel);
        var pageSize = params.endRow - params.startRow;
        if (!this.ledgerEntrySearchCriteria) {
            this.ledgerEntrySearchCriteria = new LedgerEntrySearchCriteria;
        }
        this.ledgerEntrySearchCriteria.start = params.startRow;
        this.ledgerEntrySearchCriteria.size = pageSize;
        if (params.sortModel && params.sortModel[0]) {
            this.ledgerEntrySearchCriteria.sortBy = params.sortModel[0].colId;
            if (params.sortModel[0].sort === SetupGridComp.GRID_SORT_ASCENDING) {
                this.ledgerEntrySearchCriteria.sortDirection = SortDirection.ASC;
            }
            else {
                this.ledgerEntrySearchCriteria.sortDirection = SortDirection.DESC;
            }
        }
        return this.accountingRuleService.searchLedgerEntries(this.ledgerEntrySearchCriteria)
            .pipe(tap(function (data) {
            return _this.dataStoreService.set(DataKey.ledgerEntrySearchResultsForCriteria, data);
        }));
    };
    LedgerEntrySearchResultsComponent.prototype.ngOnDestroy = function () {
        if (this.searchCriteriaObserver) {
            this.searchCriteriaObserver.unsubscribe();
        }
        if (this.focusViewDataChangeObserver) {
            this.focusViewDataChangeObserver.unsubscribe();
        }
        if (this.focusViewConfirmObserver) {
            this.focusViewConfirmObserver.unsubscribe();
        }
        if (this.focusViewCloseObserver) {
            this.focusViewCloseObserver.unsubscribe();
        }
        if (this.routeDataSubscription) {
            this.routeDataSubscription.unsubscribe();
        }
        if (this.routeParamsSubscription) {
            this.routeParamsSubscription.unsubscribe();
        }
        if (this.routeEventSubscription) {
            this.routeEventSubscription.unsubscribe();
        }
        if (this.focusCloseSubscription) {
            this.focusCloseSubscription.unsubscribe();
        }
        this.em.removeEvents([
            'GENERATE_LEDGERS'
        ]);
    };
    /*
     grid config methods which are called by grid setup (ag grid)
     and set by grid config
     */
    LedgerEntrySearchResultsComponent.prototype.onRowDoubleClicked = function (event) {
        console.log('Row clicked');
    };
    LedgerEntrySearchResultsComponent.prototype.onLedgerGenerationClick = function () {
        var _this = this;
        var confirmSuccess = new DialogModel(true, LEVEL.INFO, 'Generate Ledgers', "Do you want to generate ledger entries ?", true, 2000, null, 'No', 'Yes', true);
        confirmSuccess.disableClose = true;
        this.dialogService
            .confirm(confirmSuccess)
            .subscribe(function (res) {
            if (res === true) {
                _this.spinnerService.show();
                _this.accountingRuleService.generateLedgers({
                    version: 'v2'
                }).subscribe(function (result) {
                    _this.spinnerService.hide();
                    _this.common.showSnackBar('Success', 3000, TcErrorType.TYPE.INFO);
                    _this.setupGrid.runForceSearch();
                }, function (error) {
                    _this.spinnerService.hide();
                    console.log(error);
                    _this.common.showSnackBar('Failed', 3000, TcErrorType.TYPE.ERROR);
                });
            }
        });
    };
    return LedgerEntrySearchResultsComponent;
}(SetupGridComp));
export { LedgerEntrySearchResultsComponent };
