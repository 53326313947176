<div class="ag-filter-body-wrapper">
    <div class="ag-filter-body">
        <div class="ag-input-wrapper">
            <input
                #input
                (keyup)="onKeyUp($event)"
                [(ngModel)]="filter"
                class="ag-filter-filter"
                type="text"
                placeholder="Filter...">
        </div>
    </div>
</div>
