<div class="container-fluid tc-item-wrapper tcpi-card">
    <div class="tc-item-wrapper__body">
        <div class="row">
            <div class="col-md-12">
                <div class="tc-flex-box">
                    <div class="tc-flex-item tc-item-circle">
                        <div class="icon-box">
                            <i class="material-icons">account_circle</i>
                        </div>
                    </div>
                    <div class="tc-flex-item input-cage ">
                        <div class="col-xs-12 col-sm-6  col-md-3 col-lg-3">
                            <div class="tc-content--wrap tc-input-view">
                                <div
                                        id="TRD_PER_INFO_REF"
                                        class="tc-caption-txt txt-light">{{"Company" | translate}}
                                </div>
                                <div class="tc-body1--txt">{{kcCompanyName ? kcCompanyName : 'Any'}}</div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-6  col-md-3 col-lg-3">
                            <div class="tc-content--wrap tc-input-view">
                                <div
                                        id="TRD_PER_INFO_HOD_REF"
                                        class="tc-caption-txt txt-light">{{"Division" | translate}}
                                </div>
                                <div class="tc-body1--txt">{{kcDivisionName ? kcDivisionName : 'Any'}}</div>
                            </div>
                        </div>

                        <div class="col-xs-12 col-sm-6  col-md-3 col-lg-3">
                            <div class="tc-content--wrap tc-input-view">
                                <div
                                        id="TRD_PER_INFO_HOD_BRAND"
                                        class="tc-caption-txt txt-light">{{"Brand" | translate}}
                                </div>
                                <div class="tc-body1--txt">{{kcBrandName ? kcBrandName : 'Any'}}</div>
                            </div>
                        </div>

                        <div class="col-xs-12 col-sm-6  col-md-3 col-lg-3">
                            <div class="tc-content--wrap tc-input-view">
                                <div class="tc-caption-txt txt-light">{{"Distribution Channel" | translate}}
                                </div>
                                <div class="tc-body1--txt  ">{{
                                    kcDistributionChannelName ? kcDistributionChannelName : 'Any'}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
