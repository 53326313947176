export class locationGroupSetup{
    constructor () {
        // this.locations = new Map();
    }
    groupName: string;
    groupCode: string;
    locations: string[];
}

export namespace RegionOptions {
    export enum TYPE {'MEA', 'SCU', 'AWE', 'BSH'}
}
export namespace CountryOptions {
    export enum TYPE {'DZ', 'AF', 'AD', 'AI'}
}

export namespace CityOptions {
    export enum TYPE {'Bay City', 'Santiago', 'Brady', 'Balmaceda'}
}