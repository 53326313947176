import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatExpansionModule, MatIconModule, MatSliderModule } from '@angular/material';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { ChipInputModule } from '@tc/chip-input';
import { ContentStripModule } from '@tc/content-strip';
import { DirectivesModule } from '@tc/directives';
import { ExpandedContentCardModule } from '@tc/expanded-content-card';
import { ExpandedContentCardBodyModule } from '@tc/expanded-content-card-body';
import { PaginatorModule } from '@tc/paginator';
import { SectionHeadingModule } from '@tc/section-heading';
import { GridSearchCriteriaModule } from '../search-criteria/grid-search-criteria/grid-search-criteria.module';
import {
    GenericResourceDetailViewModule
} from '../../widgets/shared/generic-resource-detail-view/generic-resource-detail-view.module';
import {
    OpExpandedContentCardHeaderModule
} from '../../widgets/shared/op-expanded-content-card-header/op-expanded-content-card-header.module';
import {
    ResultContentCardGenericModule
} from '../../widgets/shared/result-content-card-generic/result-content-card-generic.module';
import {
    AllocationFeatureSearchCriteriaComponent
} from './allocation-feature-search-criteria/allocation-feature-search-criteria.component';
import { AllocationFeatureComponent } from './allocation-feature.component';

@NgModule({
    declarations: [AllocationFeatureComponent, AllocationFeatureSearchCriteriaComponent],
    exports: [AllocationFeatureComponent, AllocationFeatureSearchCriteriaComponent],
    imports: [
        CommonModule,
        TranslateModule.forChild(),
        FormsModule,
        MatExpansionModule,
        MatIconModule,
        MatInputModule,
        MatSliderModule,
        MatSelectModule,
        MatAutocompleteModule,
        ExpandedContentCardBodyModule,
        MatTooltipModule,
        ExpandedContentCardModule,
        DirectivesModule,
        ContentStripModule,
        PaginatorModule,
        GenericResourceDetailViewModule,
        OpExpandedContentCardHeaderModule,
        ResultContentCardGenericModule,
        SectionHeadingModule,
        MatProgressBarModule,
        GridSearchCriteriaModule,
        ChipInputModule
    ],
    entryComponents: [
        AllocationFeatureComponent, AllocationFeatureSearchCriteriaComponent
    ]
})
export class AllocationFeatureModule {}
