import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TcApiError } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-api-error';
import { TcHttpError } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-http-error';
import { Journey } from '@tc-core/model/it/codegen/ui/framework/journey';
import { TC } from '@tc-core/util';
import { ConfigLoader, EventManager, UserJourneyManager } from '@tc-core/util/framework';
import { CommonHelper } from '@tc-core/util/helpers';
import { SpinnerService } from '@tc-core/util/ui';
import { ChipInputComponent } from '@tc/chip-input';
import { ChipInputService } from '@tc/chip-input/chip-input.service';
import { Subscription } from 'rxjs';
import { TCO } from '../../constants';
import { LeaveSearchCriteria } from '../../models/criteria/leave-search-criteria';
import { SortDirection } from '../../models/helper/sort-direction';
import { ChipHandlerService } from '../../services/helpers/chip-handler.service';
import { RootService } from '../../services/util/core-services/root.service';
import { ActionHandlerService } from '../../services/util/framework/action-handler-service.service';
import { DataKey, DataStoreService } from '../../services/util/framework/data-store.service';
import { DMCQueryParamsService } from '../../services/util/framework/dmc-query-params.service';
import {ResourceAvailabilityService} from '../../services/backend-consumers/supplier-service/resource-availability.service';

@Component({
    selector: 'tc-availability-calendar-search-criteria',
    templateUrl: './availability-calendar-search-criteria.component.html',
    providers: [
        ChipHandlerService,
        {provide: 'ChipHandlerService', useExisting: ChipHandlerService},
        ChipInputService
    ]
})
export class AvailabilityCalendarSearchCriteriaComponent implements OnInit {

    @Input() isInsideForm: boolean = false;
    @Input() calendarSearchCriteria: LeaveSearchCriteria;
    @Input() searchCriteriaChipConfig: string;
    @Input() isSearchBarStyleNotAvailable: boolean = false;

    @Output() searchTrigger: EventEmitter<boolean> = new EventEmitter();
    @Output() searchCriteria: EventEmitter<any> = new EventEmitter();

    tPagination: any;

    journey: string = null;

    isChipsInit = false;
    availableChips: any[] = [];
    persistentChipsArr = [];
    persistentQParams: string[] = [];
    persistentQParamMap = new Map<string, string>();

    private relatedChipsObsv: Subscription = new Subscription();
    modifiedChip;

    isDataLoaded: boolean = true;
    isTriggerSearch: boolean = true;
    displayChipsArr = [];
    isFocus: boolean = false;
    isChipListDisplay = false;

    placeHolderText = '';

    private routerChangeSubscription: Subscription;
    private selectedChipsObsv: Subscription = new Subscription();
    private selectedFieldsObsv: Subscription = new Subscription();
    private chipValueUpdateSub: Subscription = new Subscription();
    private criteriaUpdateSubscription: Subscription = new Subscription();
    private deletedChipObsv: Subscription = new Subscription();
    private selectedSupplierObsv: Subscription = new Subscription();
    private selectedChips = [];

    private chipInputService;
    @ViewChild('calendar_search_chip_input') calendarSearchChipInput: ChipInputComponent;
    private chipHandlerSubject: Subscription = new Subscription();

    constructor(
        private activatedRoute: ActivatedRoute,
        private configLoader: ConfigLoader,
        private userJourneyManager: UserJourneyManager,
        private dataStoreService: DataStoreService,
        private actionHandlerService: ActionHandlerService,
        private em: EventManager,
        private resourceAvailabilityService: ResourceAvailabilityService,
        private chipHandler: ChipHandlerService,
        private queryParamService: DMCQueryParamsService,
        private commonHelper: CommonHelper,
        private spinnerService: SpinnerService,
        private rootService: RootService
    ) { }

    ngOnInit() {
        /*this.chipInputService = this.calendarSearchChipInput.criteriaInputService;
        this.dataStoreService.set(DataKey.leaveSetupSearchCriteria, null, true);

        this.placeHolderText = 'Search Resources';
        this.tPagination = this.configLoader.configurations.get(TCO.CONF.CONF_PAGINATION_CONFIG)['LEAVE_SETUP_SEARCH'];

        //clear data store
        this.dataStoreService.set(DataKey.leaveSetupSearchResults, null, true);

        this.userJourneyManager.journey.subscribe((journey: Journey) => {
            if (journey && JSON.stringify(journey) !== JSON.stringify({})) {
                this.journey = journey.id;
            }
        });

        // resetting the view
        this.routerChangeSubscription = this.userJourneyManager.routeChanges.subscribe(e => {
            if (e) {
                this.chipInputService.fullReset(); // fully reset chips
                this.chipHandler.chipsArr = []; // reset chips array loaded from config
                // check journey begins from quick book
                if (!this.isInsideForm) {
                    this.getQueryParams();
                    this.initSearchCriteriaChips();
                }

                console.log('displayChipsArr', this.displayChipsArr);
                this.resetQueryParams();
            }
        });

        // this.getQueryParams();
        if (!this.isChipsInit) {
            this.initSearchCriteriaChips(); // load everything from start
        }

        this.getSearchObserver();
        this.errorObserver();
        this.selectedChipsUpdate();
        this.handleInterRelatedChips();
        this.notifyChipDelete();
        this.shortCutObserver();*/
    }

    getQueryParams() {
        // get persisting query params
        if (this.calendarSearchCriteria) {
            this.persistentQParams = [];
            this.persistentQParams = this.queryParamService.getNotNullQueryParamsArray(this.calendarSearchCriteria);

            // remove resourceId from persistent chips when it is -1
            if (this.persistentQParams && this.persistentQParams.length > 0) {
                if (this.calendarSearchCriteria.resourceId === -1) {
                    for (let i = 0; i < this.persistentQParams.length; i++) {
                        if (this.persistentQParams[i] === 'resourceId') {
                            this.persistentQParams.splice(i, 1);
                            this.calendarSearchCriteria.resourceId = null;
                        }
                    }
                }
                /*else {
                 for (let i = 0; i < this.persistentQParams.length; i++) {
                 if (this.persistentQParams[i] === 'resourceType') {
                 this.persistentQParams.splice(i, 1);
                 this.leaveSetupSearchCriteria.resourceType = '';
                 }
                 }
                 }*/
            }

        }
    }

    initSearchCriteriaChips() {
        this.isChipsInit = true;
        this.chipHandler.chipsArr = [];
        // read chip config
        let criteriaChipConfigs = null;

        const chipConfigs = this.configLoader.configurations.get(TCO.CONF.CONF_LEAVE_SETUP_SEARCH_CRITERIA_CHIP);
        criteriaChipConfigs = this.commonHelper.recreateJsonObject(chipConfigs);

        if (criteriaChipConfigs && criteriaChipConfigs.chips) {
            criteriaChipConfigs.chips.forEach(chip => {
                chip = this.chipHandler.preProcessChips(chip, criteriaChipConfigs.permissionKeys.keys);
            });
        }
        // this.supplierSearchCriteria = new ClientGETCriteria();
        this.chipHandler.chipsSubject.subscribe(data => {
            if (data && JSON.stringify(data) !== JSON.stringify([]) && data.length === criteriaChipConfigs.chips.length) {
                this.availableChips = data; //new json object here;
                this.chipHandler.chipsSubject.next(null);

                if (this.chipHandlerSubject) {
                    this.chipHandlerSubject.unsubscribe();
                }

                // set values to display chips
                this.displayChipsArr = [];
                this.displayChipsArr = this.availableChips;

                this.dataStoreService.set(DataKey.chips, this.availableChips, true);

                this.chipInputService.init(this.calendarSearchCriteria, this.availableChips, false, true);
                this.isChipsInit = true;

                // implement chip persistance
                if (this.persistentQParams && this.persistentQParams.length > 0) {
                    // create a queryParamMap
                    for (let i = 0; i < this.persistentQParams.length; i++) {
                        let qParam = this.persistentQParams[i];
                        let qParamValue = this.calendarSearchCriteria[qParam];
                        if (qParam && qParamValue) {
                            this.persistentQParamMap.set(qParam, qParamValue);
                        }
                    }
                    this.persistentChipsArr = [];
                    // fill persistent chips
                    this.availableChips.forEach(chip => {
                        let persistChip = Object.assign({}, chip);
                        persistChip.optionParams.data = [];
                        let isPersist = false;
                        if (chip.dataParams && chip.dataParams.length > 0) {
                            chip.dataParams.forEach(dataParam => {
                                if (this.persistentQParamMap.has(dataParam.paramValue)) {
                                    isPersist = true;
                                    const dataObj = {
                                        key: dataParam.paramValue,
                                        value: this.persistentQParamMap.get(dataParam.paramValue)
                                    };
                                    persistChip.optionParams.data.push(dataObj);
                                }
                            });
                        }
                        // push persist chips to an array
                        if (isPersist) {
                            this.persistentChipsArr.push(persistChip);
                        }
                    });
                    // persist chips
                    if (this.persistentChipsArr && this.persistentChipsArr.length > 0) {

                        this.chipInputService.persistChips(this.persistentChipsArr);

                        // trigger search when chips persisting
                        this.isDataLoaded = false;

                        this.onResourceSearch(this.calendarSearchCriteria);
                    }
                }
                this.chipInputService.criteriaUpdate.subscribe((_data) => {
                    this.calendarSearchCriteria = _data;
                });
            }
        });
    }
    // search resources
    public onResourceSearch($event) {
        this.isDataLoaded = false;
        this.calendarSearchCriteria = $event;
        this.calendarSearchCriteria.sortDirection = SortDirection.ASC;

        // create pagination configurations
        if (this.tPagination && this.tPagination['initialFetchingPages'] &&
            this.tPagination['defaultPageResultCount']) {
            this.calendarSearchCriteria.size = this.tPagination['initialFetchingPages'] *
                this.tPagination['defaultPageResultCount'];
            this.calendarSearchCriteria.start = 0;
        }

        this.userJourneyManager.canProceed.next(false);

        if (JSON.stringify(this.calendarSearchCriteria) !== JSON.stringify({})) {
            this.dataStoreService.set(DataKey.leaveSetupSearchCriteria, this.calendarSearchCriteria, true);

            this.dataStoreService.set(DataKey.leaveSetupSearchResults, null, true);
            // search resources and set the results to DataKey - leaveSetupSearchResults
            this.resourceAvailabilityService.searchLeaveSummaries(this.calendarSearchCriteria, DataKey.leaveSetupSearchResults);

            this.isTriggerSearch = false;
            this.isDataLoaded = false;
            this.searchTrigger.emit(true);

        } else {
            setTimeout(() => {
                this.isDataLoaded = true;
                this.isTriggerSearch = true;
            }, 0);
        }

    }

    public onFocus($event) {
        this.isFocus = $event;
    }

    public onCancel($event) {
        console.log('cancelled');
    }

    public onChipListDisplay($event) {
        this.dataStoreService.set(DataKey.chipListDisplay, null, true);
        this.isChipListDisplay = $event;
        this.dataStoreService.set(DataKey.chipListDisplay, this.isChipListDisplay, true);

    }

    private resetQueryParams() {
        let resourceQueryParams: string[] = [];
        resourceQueryParams = this.queryParamService.getQueryParamsArray(new LeaveSearchCriteria());

        let leaveSearchCriteria: LeaveSearchCriteria = this.rootService.loadDataFromLocalStorage([],
            new LeaveSearchCriteria(), TCO.AppData.LEAVE_SETUP_SEARCH_CRITERIA
        );

        // set criteria parameters to url
        let queryParams: string[] = [];
        queryParams = this.queryParamService.getQueryParamsArray(leaveSearchCriteria);
        this.rootService.setDataToLocalStorage(TCO.AppData.LEAVE_SETUP_SEARCH_CRITERIA, this.calendarSearchCriteria,
            true, queryParams, true
        );
    }

    private getSearchObserver() {
        const x = this.dataStoreService.get(DataKey.leaveSetupSearchResults).subscribe((data) => {
            if (this.commonHelper.dataValidity(data)) {
                setTimeout(() => {
                    this.isDataLoaded = true;
                    this.isTriggerSearch = true;
                }, 0);
            } else if (this.commonHelper.isEmptyData(data)) {
                this.isDataLoaded = true;
            } else if (data instanceof TcApiError) {
                this.isDataLoaded = true;
            } else if (data instanceof TcHttpError) {
                this.isDataLoaded = true;
            }
        }, (error: any) => {
            console.log(error);
        });

    }

    private errorObserver() {
        this.dataStoreService.get(DataKey.error).subscribe((data) => {
            if (data && JSON.stringify(data) !== JSON.stringify({})) {
                setTimeout(() => {
                    this.isDataLoaded = true;
                }, 0);
                this.dataStoreService.set(DataKey.error, null);
            }
        });
    }

    private selectedChipsUpdate() {
        this.criteriaUpdateSubscription = this.chipInputService.selectedChipsSubject.subscribe(selectedChips => {
            this.selectedChips = selectedChips;

            if (selectedChips && JSON.stringify(selectedChips) !== JSON.stringify([])) {
                // toggle chips when selecting BRANCH_OF_CLIENT_ID chip
                for (const selectedChip of this.selectedChips) {
                    if (selectedChip.chip.id === 'BRANCH_OF_CLIENT_ID' && selectedChip.chip.optionParams.toggleChips &&
                        selectedChip.chip.optionParams.toggleChips.length > 0) {
                        this.deleteToggleChips(selectedChip.chip.optionParams.toggleChips);
                        this.disableChips(selectedChip.chip.optionParams.toggleChips);
                        break;
                    }
                }
            }
        });
    }

    private handleInterRelatedChips() {
        this.relatedChipsObsv = this.chipInputService.relatedChipsSubject.subscribe(
            (data) => {
                let toggleChips = [];
                if (data && data.chip && JSON.stringify(data) !== JSON.stringify({})) {
                    this.modifiedChip = data.chip;
                    // TODO: refine chip input service: inter related chips
                    if (data.chip.type === 'CALENDAR') {
                        this.chipHandler.handleInterRelatedChips(data.chip, data.date);
                    } else {
                        this.chipHandler.handleInterRelatedChips(data.chip, data.options.value[0]);
                    }
                    if (data.chip.optionParams.toggleChips && data.chip.optionParams.toggleChips.length > 0) {
                        toggleChips = data.chip.optionParams.toggleChips;
                        if (toggleChips && data.options && data.options.value[0].value) {
                            this.chipInputService.selectedChipsBasedOnStateChange(toggleChips);
                        } else {
                            this.chipInputService.deleteChipsBasedOnStateChange(toggleChips);
                        }
                    }
                }
            }
        );
    }

    private notifyChipDelete() {
        this.deletedChipObsv = this.chipInputService.deletedChipsSubject.subscribe(deletedChip => {
            if (JSON.stringify(deletedChip) !== JSON.stringify({}) && deletedChip !== null && deletedChip !== undefined) {
                if (deletedChip.id === 'CLIENT_TYPE') {
                    const deleteChips = this.selectedChips.map(chip => chip.chip);
                    this.deleteSelectedChips(deleteChips);
                    this.enableChips(this.availableChips);
                } else if (deletedChip.id === 'BRANCH_OF_CLIENT_ID') {
                    if (deletedChip.optionParams.toggleChips && deletedChip.optionParams.toggleChips.length > 0) {
                        this.enableChips(deletedChip.optionParams.toggleChips);
                    }
                }
            }
        });
    }

    private shortCutObserver() {
        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'FOCUS_SEARCH_BAR').subscribe(e => {
            this.isFocus = true;
        });
        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'CLOSE_POPUPS').subscribe(e => {
            this.isFocus = false;
        });
    }

    /**
     * Delete toggle chips if already selected
     *
     * @param toggleChips
     */
    private deleteToggleChips(toggleChips: any[]) {
        const deleteChips = [];
        toggleChips.forEach(toggleChip => {
            for (const selectedChip of this.selectedChips) {
                if (selectedChip.chip.id === toggleChip.criteriaId) {
                    deleteChips.push(selectedChip.chip);
                    break;
                }
            }
        });
        this.deleteSelectedChips(deleteChips);
    }

    private deleteSelectedChips(deleteChips: any[]) {
        deleteChips.forEach(chip => {
            this.chipInputService.deleteChip(chip, true);
        });
    }

    private enableChips(enableChips: any[]) {
        enableChips.forEach(enableChip => {
            this.chipInputService.modifyChipsStatus(enableChip, false);
        });
    }

    private disableChips(disableChips: any[]) {
        disableChips.forEach(disableChip => {
            this.chipInputService.modifyChipsStatus(disableChip, true);
        });
    }

    ngOnDestroy() {

        /*this.routerChangeSubscription.unsubscribe();
        this.userJourneyManager.routeChanges.next(false);

        if (this.selectedChipsObsv) {
            this.selectedChipsObsv.unsubscribe();
        }

        if (this.selectedFieldsObsv) {
            this.selectedFieldsObsv.unsubscribe();
        }

        if (this.chipValueUpdateSub) {
            this.chipValueUpdateSub.unsubscribe();
        }
        if (this.criteriaUpdateSubscription) {
            this.criteriaUpdateSubscription.unsubscribe();
        }
        if (this.deletedChipObsv) {
            this.deletedChipObsv.unsubscribe();
        }*/
    }

}
