/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./availability-calendar-page.component";
import * as i2 from "../../services/backend-consumers/supplier-service/resource-availability.service";
import * as i3 from "@tc-core/util/ui/spinner.service";
import * as i4 from "../../services/util/framework/data-store.service";
import * as i5 from "@tc-core/util/framework/event-manager.service";
import * as i6 from "@tc-core/util/framework/config-loader.service";
import * as i7 from "@tc/focus-view/focus-view.service";
import * as i8 from "../../services/util/framework/dmc-query-params.service";
import * as i9 from "../../services/util/core-services/root.service";
import * as i10 from "@tc-core/util/framework/focus-view-manager.service";
import * as i11 from "@tc/dialog/dialog.service";
import * as i12 from "@tc-core/util/helpers/common-helper.service";
var styles_AvailabilityCalendarPageComponent = [];
var RenderType_AvailabilityCalendarPageComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AvailabilityCalendarPageComponent, data: {} });
export { RenderType_AvailabilityCalendarPageComponent as RenderType_AvailabilityCalendarPageComponent };
export function View_AvailabilityCalendarPageComponent_0(_l) { return i0.ɵvid(0, [], null, null); }
export function View_AvailabilityCalendarPageComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-availability-calendar-page", [], null, null, null, View_AvailabilityCalendarPageComponent_0, RenderType_AvailabilityCalendarPageComponent)), i0.ɵdid(1, 114688, null, 0, i1.AvailabilityCalendarPageComponent, [i2.ResourceAvailabilityService, i3.SpinnerService, i4.DataStoreService, i5.EventManager, i6.ConfigLoader, i7.FocusViewService, i8.DMCQueryParamsService, i9.RootService, i10.FocusViewManager, i11.DialogService, i12.CommonHelper], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AvailabilityCalendarPageComponentNgFactory = i0.ɵccf("tc-availability-calendar-page", i1.AvailabilityCalendarPageComponent, View_AvailabilityCalendarPageComponent_Host_0, { resourceSummary: "resourceSummary" }, {}, []);
export { AvailabilityCalendarPageComponentNgFactory as AvailabilityCalendarPageComponentNgFactory };
