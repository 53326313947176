import {Injectable} from '@angular/core';
import {DataKey, DataStoreService} from '../util/framework/data-store.service';
import {KeyControlAndAbstractSearchCriteria} from '../../models/criteria/key-control-and-abstract-search-criteria';
import {DataHandlerService} from '../backend-consumers/master-data-handler-service/data-handler.service';
import {BrandSearchCriteria} from '../../models/criteria/brand-search-criteria';
import {BrandSetupService} from '../backend-consumers/setups/brand-setup-service';
import {DistributionChannelSearchCriteria} from '../../models/criteria/distribution-channel-search-criteria';
import {DistributionChannelSetupService} from '../backend-consumers/setups/distribution-channel-setup-service';
import {TCO} from '../../constants';
import {ConfigLoader} from '@tc-core/util/framework';

@Injectable({
    providedIn: 'root'
})
export class UserManagementService {

    ANY: any = 'ANY';
    userProfile = this.dataStore.get(DataKey.userProfile).getValue();
    anyOption = {code: 'ANY', name: 'Any'};
    allItems = 1000000;

    /**
     * <b>configCompany:</b> the company code/name value configured in config project related to the logged in user
     * <b>Note:</b> Some front-end user interfaces, panels, and certain specialized functions will be activated according to the this value
     */
    public configCompany: string;

    constructor(
        private dataStore: DataStoreService,
        private dataHandlerService: DataHandlerService,
        private dataStoreService: DataStoreService,
        private brandSetupService: BrandSetupService,
        private distributionChannelSetupService: DistributionChannelSetupService,
        private configLoader: ConfigLoader
    ) {
    }

    /**
     * @description In order to configure front-end user interfaces, panels, and certain specialized functions linked to the particular
     * client needs, the configCompany value is updated using the loadConfiguredCompany method.
     * <br>
     * configCompany: the company code/name value configured in config project related to the logged in user
     * <br>
     * configCompany values are located on TCO-Config > config > DMC(Or Relevant folder) > common > system.config.json
     */
    loadConfiguredCompany() {
        this.configCompany = this.configLoader.configurations.get(TCO.CONF.CONF_SYSTEM).configCompany;

    }

    /**
     * @description notAvailableFor method is used to check the availability of the privileges with the given companies list
     * (input as a comma separated string).
     * @param companies The companies list which you need to restrict. Input as comma separated string. (Ex: "DMC,FC,DS")
     * @return boolean value (true/false) will return according to the logged in user configs
     */
    notAvailableFor(companies: string) {
        let isAvailable = true;
        const companyList = companies.replace(/\s/g, '').split(',');

        if (companyList.length > 0) {
            for (const comp of companyList) {
                if (comp === this.configCompany) {
                    isAvailable = false;
                    break;
                }
            }
        }
        return isAvailable;
    }

    /**
     * @description onlyAvailableFor method is used to check the availability of the privileges with the given companies list
     * (input as a comma separated string).
     * @param companies The companies list which you need to given permissions. Input as comma separated string. (Ex: "DMC,FC,DS")
     * @return boolean value (true/false) will return according to the logged in user configs
     */
    onlyAvailableFor(companies: string) {
        let isAvailable = false;
        const companyList = companies.replace(/\s/g, '').split(',');

        if (companyList.length > 0) {
            for (const comp of companyList) {
                if (comp === this.configCompany) {
                    isAvailable = true;
                    break;
                }
            }
        }
        return isAvailable;
    }

    /**
     * This method is used to update the column editable privileges according to the logged user key controls.
     *
     * @param defaultColDef default column definitions
     */
    updateColDefAccordingToLoggedUserKC = (defaultColDef: any) => {
        this.userProfile = this.dataStore.get(DataKey.userProfile).getValue();

        for (let column of defaultColDef) {
            switch (column.field) {
                case 'kcCompany':
                    if (this.userProfile.kcCompany != null) {
                        column = column.editable = false;
                    }
                    break;
                case 'kcDivision':
                    if (this.userProfile.kcDivision != null) {
                        column = column.editable = false;
                    }
                    break;
                case 'kcBrand':
                    if (this.userProfile.kcBrand != null) {
                        column = column.editable = false;
                    }
                    break;
                case 'kcDistributionChannel':
                    if (this.userProfile.kcDistributionChannel != null) {
                        column = column.editable = false;
                    }
                    break;
            }
        }
        return defaultColDef;
    };

    /**
     * This method is used to update the given search criteria chip configs according to the logged user key controls.
     *
     * @param chipsConfig The chips configs related to the search chips
     */
    updateSearchChipsAccordingToUserKC = (chipsConfig: any) => {
        this.userProfile = this.dataStore.get(DataKey.userProfile).getValue();

        if (this.notAvailableFor('FC') && chipsConfig && chipsConfig.chips) {
            for (const chip of chipsConfig.chips) {
                switch (chip.criteriaId) {
                    case 'KC_COMPANY':
                        this.chipOptionParamsUpdate(chip, true, true, true, false, null, null);
                        break;
                    case 'KC_DIVISION':
                        this.chipOptionParamsUpdate(chip, true, true, true, false, null, null);
                        break;
                    case 'KC_BRAND':
                        this.chipOptionParamsUpdate(chip, true, true, true, false, null, null);

                        if (this.userProfile.kcBrand != null) {
                        }
                        break;
                    case 'KC_DIST_CHANNEL':
                        this.chipOptionParamsUpdate(chip, true, true, true, false, null, null);
                        if (this.userProfile.kcDistributionChannel != null) {
                        }
                        break;
                }
            }
        }
        return chipsConfig;
    };

    chipOptionParamsUpdate = (
        chip: any,
        isMandatory: boolean,
        isMandatoryDisplay: boolean,
        isInitialPresent: boolean,
        disabled: boolean,
        options: any,
        data: any) => {

        if (chip && chip.optionParams) {
            chip.optionParams.isMandatory = isMandatory;
            chip.optionParams.isMandatoryDisplay = isMandatoryDisplay;
            chip.optionParams.isInitialPresent = isInitialPresent;
            chip.optionParams.disabled = disabled;

            if (options) {
                chip.optionParams.options = options;
            }
            if (data) {
                chip.optionParams.data = data;
            }
        }
    };

    chipKCDataParamsUpdate(chip: any, data: any) {
        if (chip && chip.optionParams) {
            chip.optionParams.data = data;
        }
        return chip;
    }

    /**
     * The updateSearchCriteriaWithUserKC method will update search criteria with logged in user key control values.
     *
     * @param criteria The search criteria
     */
    updateSearchCriteriaWithUserKC = (criteria: any) => {
        this.userProfile = this.dataStore.get(DataKey.userProfile).getValue();

        if (this.notAvailableFor('FC')) {
            if (this.userProfile.kcCompany) {
                criteria.kcCompany = this.userProfile.kcCompany;
            } else if (!criteria.kcCompany) {
                criteria.kcCompany = this.ANY;
            }
            if (this.userProfile.kcDivision) {
                criteria.kcDivision = this.userProfile.kcDivision;
            } else if (!criteria.kcDivision) {
                criteria.kcDivision = this.ANY;
            }
            if (this.userProfile.kcBrand) {
                criteria.kcBrand = this.userProfile.kcBrand;
            } else if (!criteria.kcBrand) {
                criteria.kcBrand = this.ANY;
            }
            if (this.userProfile.kcDistributionChannel) {
                criteria.kcDistributionChannel = this.userProfile.kcDistributionChannel;
            } else if (!criteria.kcDistributionChannel) {
                criteria.kcDistributionChannel = this.ANY;
            }
        }
        return criteria;
    };

    /**
     * updateAllocationSearchChipsWithKC used for the update search chips in allocation panels
     *
     * @param chipsConfig the chipsConfig
     * @param bookingItemCompany the code of company in the booking item.
     * @param bookingItemDivision the code of division in the booking item.
     * @param bookingItemBrand the code of brand in the booking item.
     * @param bookingItemDistributionChannel the code of distribution channel in the booking item.
     */
    updateAllocationSearchChipsWithKC = (
        chipsConfig: any,
        bookingItemCompany: string,
        bookingItemDivision: string,
        bookingItemBrand: string,
        bookingItemDistributionChannel: string) => {

        let company = [];
        let brand = [];
        let division = [];
        let disChannel = [];

        let COMMON_DATA;

        this.userProfile = this.dataStore.get(DataKey.userProfile).getValue();

        company = this.setOptions(
            this.userProfile.kcCompany, company, DataKey.kcCompanies, bookingItemCompany
        );
        division = this.setOptions(
            this.userProfile.kcDivision, division, DataKey.kcDivisions, bookingItemDivision
        );
        brand = this.setOptions(
            this.userProfile.kcBrand, brand, DataKey.kcBrandsList, bookingItemBrand
        );
        disChannel = this.setOptions(
            this.userProfile.kcDistributionChannel, disChannel, DataKey.kcDistributionChannelList, bookingItemDistributionChannel
        );

        if (chipsConfig && chipsConfig.chips) {
            for (const chip of chipsConfig.chips) {
                switch (chip.criteriaId) {
                    case 'KC_COMPANY':
                        COMMON_DATA = this.updateCommonDataWithKC(bookingItemCompany);
                        this.chipOptionParamsUpdate(
                            chip,
                            true,
                            true,
                            true,
                            false,
                            this.addDataToOptions(company, COMMON_DATA),
                            company
                        );
                        break;
                    case 'KC_DIVISION':
                        COMMON_DATA = this.updateCommonDataWithKC(bookingItemDivision);
                        this.chipOptionParamsUpdate(
                            chip,
                            true,
                            true,
                            true,
                            false,
                            this.addDataToOptions(division, COMMON_DATA),
                            division
                        );
                        break;
                    case 'KC_BRAND':
                        COMMON_DATA = this.updateCommonDataWithKC(bookingItemBrand);
                        this.chipOptionParamsUpdate(
                            chip,
                            true,
                            true,
                            true,
                            false,
                            this.addDataToOptions(brand, COMMON_DATA),
                            brand
                        );
                        break;
                    case 'KC_DIST_CHANNEL':
                        COMMON_DATA = this.updateCommonDataWithKC(bookingItemDistributionChannel);
                        this.chipOptionParamsUpdate(
                            chip,
                            true,
                            true,
                            true,
                            false,
                            this.addDataToOptions(disChannel, COMMON_DATA),
                            disChannel
                        );
                        break;
                }
            }
        }
        return chipsConfig;
    };

    /**
     * updateCommonDataWithKC method is used to update (by concatenating) common data code with booking item kc value
     *
     * @param code the code of relevant key control.
     * @return Common data array that including the concatenated key control code.
     */
    updateCommonDataWithKC(code) {
        const value = 'NOT_SPECIFY_' + (code ? code : '');
        return {name: 'Not Specified', code: value};
    }

    /**
     * This method used for adding new data to existing options list.
     *
     * @param options  the list of existing options
     * @param data  the new data that need to add to the options list
     * @return optionsTemp This method will return a list of options.
     */
    addDataToOptions(options: any, data: any) {
        let optionsTemp: any[];
        optionsTemp = options.filter((item, index, self) => self.indexOf(item) === index);
        optionsTemp.push(data);
        return optionsTemp;
    }

    /**
     * This method is used to filter options by given code
     *
     * @param userProfileKCKey the Data key which is stored the user profile data.
     * @param options  the expected options list for the relevant dropdown.
     * @param optionsDataKey  the Data key which is stored the relevant key controls data related to the logged in user.
     * @param optionsFilterKey  the key that the data need to filter
     * @return options list will return by this method
     */
    setOptions(userProfileKCKey: any, options: any, optionsDataKey: any, optionsFilterKey: string) {
        if (userProfileKCKey) {
            options = this.dataStore.get(optionsDataKey).getValue();
        } else {
            options = this.dataStore.get(optionsDataKey).getValue().filter(data => data.code === optionsFilterKey);
        }
        return options;
    }


    /**
     * getKcCompaniesAndDivisions method retrieve the company and division data relevant to the system logged user key controls.
     * As well, this method will update the related data keys which are the kcCompanies and kcDivisions.
     */
    public getKcCompaniesAndDivisions() {
        const criteria = new KeyControlAndAbstractSearchCriteria();
        criteria.kcCompany = this.userProfile.kcCompany;
        let divisionList = [];
        if (this.userProfile.kcDivision) {
            criteria.kcDivision = this.userProfile.kcDivision;
        } else {
            divisionList.push(this.anyOption);
        }
        this.dataHandlerService.getCompaniesAndDivisions(criteria).subscribe(
            result => {
                const data = result.data[0];

                if (data) {
                    this.dataStoreService.set(DataKey.kcCompanies, [data], true);

                    if (data.divisions) {
                        if (this.userProfile.kcDivision) {
                            divisionList = data.divisions.filter(div => div.code === this.userProfile.kcDivision);
                        } else {
                            divisionList.push(...data.divisions);
                        }
                    }
                    this.dataStoreService.set(DataKey.kcDivisions, divisionList, true);
                }
            });
    }

    /**
     * getKcBrands method retrieve the brand data relevant to the system logged user key controls.
     * As well, this method will update the related data key which is the kcBrandsList.
     */
    public getKcBrands() {
        const criteria = new BrandSearchCriteria();
        const brandList = [];
        criteria.size = this.allItems;
        if (this.userProfile.kcBrand) {
            criteria.code = this.userProfile.kcBrand;
        } else {
            brandList.push(this.anyOption);
        }
        this.brandSetupService.getRows(criteria).subscribe(
            result => {
                if (result) {
                    brandList.push(...result.data);
                    this.dataStoreService.set(DataKey.kcBrandsList, brandList, true);
                }
            });
    }

    /**
     * getKcDistChannels method retrieve the distribution channel data relevant to the system logged user key controls.
     * As well, this method will update the related data key which is the kcDistributionChannelList.
     */
    public getKcDistChannels() {
        const criteria = new DistributionChannelSearchCriteria();
        criteria.size = this.allItems;
        const distChannelList = [];
        if (this.userProfile.kcDistributionChannel) {
            criteria.code = this.userProfile.kcDistributionChannel;
        } else {
            distChannelList.push(this.anyOption);
        }
        this.distributionChannelSetupService.getRows(criteria).subscribe(
            result => {
                if (result) {
                    distChannelList.push(...result.data);
                    this.dataStoreService.set(DataKey.kcDistributionChannelList, distChannelList, true);
                }
            }
        );
    }
}
