import { MainType } from './main-type';
import { ProductLevel } from './product-level';
import { ServiceSharing } from './service-sharing';

export class ProductItem {
    productLevel: ProductLevel;
    mainTypes: MainType[];
    subTypes: string[];
    contractRef: string;
    plCode: string;
    plName: string;
    supplierName: string;
    startDate: string;
    endDate: string;
    pvtShared: ServiceSharing;
    languages: string[];
    bookingIdList: number[];

    // front end attributes
    selected: boolean;
}

export enum QueueType {
    PRODUCT = 'PRODUCT',
    SERVICE = 'SERVICE',
    BOOKING = 'BOOKING',
}
