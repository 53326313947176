<!--<mat-card>-->
    <!--<div>-->
        <!--<div *ngFor="let option of options">-->
            <!--<mat-checkbox-->
                    <!--class="tc-ag-grid__checkbox"-->
                    <!--[class.tc-ag-grid__checkbox&#45;&#45;error]="isError"-->
                    <!--[ngModel]="isSelected(option)"-->
                    <!--[disabled]="disabled"-->
                    <!--(change)="onChange($event,option)">-->
                <!--{{getName(option)}}-->
            <!--</mat-checkbox>-->
        <!--</div>-->
    <!--</div>-->
<!--</mat-card>-->


<mat-form-field>
    <mat-label>Choose a date</mat-label>
    <input matInput [matDatepicker]="picker" [(ngModel)]="selectedDate">
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
</mat-form-field>
