import * as tslib_1 from "tslib";
import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { MatSelectionList } from '@angular/material/list';
import { TranslateService } from '@ngx-translate/core';
import { ConfigLoader } from '@tc-core/util/framework/config-loader.service';
import { AgGridAngular } from 'ag-grid-angular';
import { Observable } from 'rxjs';
import { of } from 'rxjs/index';
import { tap } from 'rxjs/operators';
import { SetupGridComp } from '../../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid-comp';
import { GridCommonUtil } from '../../../../widgets/framework/ag-grid-custom-components/utils/grid-common-util';
import { TCO } from '../../../../constants';
import { SortDirection } from '../../../../models/helper/sort-direction';
import { BookingItemSearchCriteria } from '../../../../models/reservation-v2/criteria/booking-item-search-criteria';
import { ReservationV2ManagementService } from '../../../../services/backend-consumers/reservation-v2/reservation-v2-management.service';
import { DataKey, DataStoreService } from '../../../../services/util/framework/data-store.service';
import { OperationV2DataProcessorService } from '../../../../services/util/pre-processors/operation-v2-data-processor.service';
import { ResponseUtil } from '../../../../services/util/response/response-util.service';
import { OperationQueuePanelConfig } from '../operation-queue-panel-config';
var BookingQueueComponent = /** @class */ (function () {
    function BookingQueueComponent(reservationV2ManagementService, translateService, operationV2DataProcessorService, configLoader, dataStoreService) {
        var _this = this;
        this.reservationV2ManagementService = reservationV2ManagementService;
        this.translateService = translateService;
        this.operationV2DataProcessorService = operationV2DataProcessorService;
        this.configLoader = configLoader;
        this.dataStoreService = dataStoreService;
        this.operationPanelConfig = new OperationQueuePanelConfig();
        this.hideOverlay = false;
        this.showRowLoading = false;
        this.columnDefsInput = [];
        this.primaryColumnDefsInput = [];
        this.secondaryColumnDefsInput = [];
        this.allColumnDefsInput = [];
        this.selectedRowsCount = 0;
        this.unsavedRows = [];
        this.selectedProducts = [];
        this.frameworkComponents = {};
        this.cellEditingStart = null;
        this.autoSize = false;
        this.invalidRowClass = 'tc-ag-grid-row--error';
        this.colResizeDefault = {};
        this.domLayout = 'autoHeight';
        this.overlayNoRowsTemplate = "<div class=\"tc-ag-grid-empty\">\n            <i class=\"material-icons tc-ag-grid-empty__icon\">assignment_error</i>\n            <span class=\"tc-ag-grid-empty__text\">No Records Available</span>\n        </div>";
        this.overlayLoadingTemplate = "<div class=\"tc-ag-grid-empty\">\n            <img src=\"assets/img/loading-tab.gif\" class=\"tc-mb-2\" alt=\"Loading\">\n            <span class=\"tc-ag-grid-empty__text\">Loading...</span>\n        </div>";
        this.gridActions = [];
        this.bookingItemSearchResults = [];
        this.isHiddenPrimaryResourceButton = false;
        this.isHiddenSecondaryResourceButton = false;
        this.isHiddenSupplierConfirmActionButton = false;
        this.isHiddenSupplierRejectActionButton = false;
        this.rowDoubleClicked = new EventEmitter();
        this.rowClicked = new EventEmitter();
        this.cellClicked = new EventEmitter();
        this.getRawClass = function (params) {
            if (params.node.rowIndex % 2 === 0) {
                return 'tc-ag-grid-row--even';
            }
        };
        this.afterCellValueChange = function (event) {
            _this.cellClicked.emit(event);
            if (event.oldValue !== event.newValue) {
                console.log(event);
                _this.saveRowData(event);
            }
        };
        this.translateService.onLangChange.subscribe(function () {
            _this.gridApi.refreshHeader();
        });
    }
    Object.defineProperty(BookingQueueComponent.prototype, "primaryColumnDefs", {
        set: function (colDef) {
            this.primaryColumnDefsInput = colDef;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BookingQueueComponent.prototype, "secondaryColumnDefs", {
        set: function (colDef) {
            this.secondaryColumnDefsInput = colDef;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BookingQueueComponent.prototype, "allColumnDefs", {
        set: function (colDef) {
            this.allColumnDefsInput = colDef;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BookingQueueComponent.prototype, "columnDefs", {
        set: function (colDef) {
            var _this = this;
            this.columnDefsInput = colDef;
            if (this.gridApi) {
                setTimeout(function () {
                    _this.gridApi.sizeColumnsToFit();
                    _this.gridApi.setColumnDefs(_this.columnDefsInput);
                }, 0);
            }
        },
        enumerable: true,
        configurable: true
    });
    BookingQueueComponent.prototype.ngOnInit = function () {
        var _this = this;
        var actionButtonConfig = this.configLoader.configurations.get(TCO.CONF.CONF_TBL_DEF_BOOKING_QUEUE_ACT_BUTTON_CONFIG);
        this.isHiddenPrimaryResourceButton = actionButtonConfig.primaryResourceButton.hide;
        this.isHiddenSecondaryResourceButton = actionButtonConfig.secondaryResourceButton.hide;
        this.isHiddenSupplierConfirmActionButton = actionButtonConfig.supplierConfirmActionButton.hide;
        this.isHiddenSupplierRejectActionButton = actionButtonConfig.supplierRejectActionButton.hide;
        this.operationPanelConfig.currentTab = this.tabIndex;
        this.colResizeDefault = 'shift';
        this.gridOptions = {
            cacheBlockSize: 50,
            maxBlocksInCache: 1,
            enableSorting: true,
            rowModelType: 'infinite',
            rowSelection: 'multiple',
            pagination: true,
            paginationPageSize: 50
        };
        this.defaultColDef = {
            filter: true,
            sortable: true,
            resizable: true,
            headerValueGetter: function (parameters) {
                var headerIdentifier = parameters.colDef.headerName;
                if (headerIdentifier) {
                    return _this.translateService.instant(headerIdentifier);
                }
                return '';
            }
        };
        if (this.gridOptions.defaultColDef) {
            this.gridOptions.defaultColDef.resizable = true;
            this.gridOptions.defaultColDef.sortable = true;
        }
        this.autoSizeAll(false);
    };
    BookingQueueComponent.prototype.onClickColOption = function (event) {
        event.stopPropagation();
    };
    BookingQueueComponent.prototype.onSelectionChangeColSelector = function (event) {
        if (event && event.option) {
            var option = event.option.value;
            var selected = event.option.selected;
            if (this.gridColumnApi) {
                this.gridColumnApi.setColumnVisible(option, selected);
                // this.gridApi.sizeColumnsToFit();
                this.autoSizeAll(false);
            }
        }
    };
    BookingQueueComponent.prototype.autoSizeAll = function (skipHeader) {
        var allColumnIds = [];
        if (this.gridColumnApi) {
            this.gridColumnApi.getAllColumns().forEach(function (column) {
                allColumnIds.push(column.colId);
            });
            // this.gridApi.sizeColumnsToFit();
            this.gridColumnApi.autoSizeColumns(allColumnIds, skipHeader);
        }
    };
    BookingQueueComponent.prototype.onGridSizeChange = function ($event) {
        if (this.autoSize) {
            this.autoSizeAll(false);
        }
        else {
            this.gridApi.sizeColumnsToFit();
        }
    };
    BookingQueueComponent.prototype.getRowNodeId = function (params) {
        return "" + params.id;
    };
    BookingQueueComponent.prototype.onRestoreColDefClick = function () {
        this.columnDefsInput = this.allColumnDefsInput;
        if (this.gridColumnApi) {
            this.gridColumnApi.resetColumnState();
            var allColumnIds_1 = [];
            this.gridColumnApi.getAllColumns().forEach(function (column) {
                allColumnIds_1.push(column.colId);
            });
            this.gridColumnApi.hideColumns(allColumnIds_1, false);
            this.colSelector.selectAll();
        }
    };
    BookingQueueComponent.prototype.doSomeChange = function () {
        this.operationPanelConfig.preventSwitching = !this.operationPanelConfig.preventSwitching;
        if (this.operationPanelConfig.preventSwitching) {
            this.operationPanelConfig.currentTab = this.tabIndex;
        }
    };
    BookingQueueComponent.prototype.onSelectionChange = function ($event) {
        this.selectedRowsCount = $event.api.getSelectedRows().length;
        this.operationV2DataProcessorService.autoAllocationButtonEnable = this.selectedRowsCount > 0;
        this.operationV2DataProcessorService.confirmButtonEnable = this.selectedRowsCount > 0;
        if (!this.isHiddenSupplierConfirmActionButton) {
            this.operationV2DataProcessorService.supplierConfirmButtonEnable = this.selectedRowsCount > 0;
        }
        if (!this.isHiddenSupplierRejectActionButton) {
            this.operationV2DataProcessorService.supplierRejectButtonEnable = this.selectedRowsCount > 0;
        }
    };
    BookingQueueComponent.prototype.onGridReady = function ($event) {
        var _this = this;
        this.gridApi = $event.api;
        this.gridColumnApi = $event.columnApi;
        this.autoSizeAll(false);
        var gridDataSource = {
            getRows: function (params) {
                _this.gridApi.showLoadingOverlay();
                var sortBy = params.sortModel && params.sortModel[0] && params.sortModel[0].colId ?
                    params.sortModel[0].colId : null;
                var sortDirection = params.sortModel && params.sortModel[0] && params.sortModel[0].sort ?
                    params.sortModel[0].sort : null;
                _this.getDataRows(params.startRow, params.endRow, sortBy, sortDirection).subscribe(function (result) {
                    if (result) {
                        _this.gridApi.hideOverlay();
                        var data = ResponseUtil.getDataArray(result);
                        var totalRows = ResponseUtil.getTotalCount(result);
                        params.successCallback(data, totalRows);
                        if (totalRows === 0) {
                            _this.gridApi.showNoRowsOverlay();
                        }
                        _this.addUnSavedRowsAgainAfterReload();
                        _this.autoSizeAll(false);
                    }
                }, function (error) {
                    _this.gridApi.hideOverlay();
                    params.successCallback([], 0);
                });
            }
        };
        this.gridApi.setDatasource(gridDataSource);
    };
    BookingQueueComponent.prototype.loadData = function () {
        var _this = this;
        if (this.gridApi) {
            this.gridApi.refreshInfiniteCache();
        }
        else {
            setTimeout(function () { return _this.loadData(); }, 100);
        }
        this.autoSizeAll(false);
    };
    BookingQueueComponent.prototype.showLoading = function () {
        var _this = this;
        if (!this.hideOverlay) {
            this.showRowLoading = true;
            if (this.gridApi) {
                this.gridApi.showLoadingOverlay();
            }
            else {
                setTimeout(function () { return _this.showLoading(); }, 0);
            }
        }
    };
    BookingQueueComponent.prototype.hideLoading = function () {
        var _this = this;
        this.hideOverlay = true;
        this.showRowLoading = false;
        if (this.gridApi) {
            this.gridApi.hideOverlay();
        }
        else {
            setTimeout(function () { return _this.hideLoading(); }, 0);
        }
    };
    BookingQueueComponent.prototype.getDataRows = function (startRow, endRow, sortBy, sortDirection) {
        var _this = this;
        var bookingIds = [];
        this.selectedProducts.forEach(function (productItem) {
            bookingIds.push.apply(bookingIds, tslib_1.__spread(productItem.bookingIdList));
        });
        if (bookingIds.length) {
            var pageSize = endRow - startRow;
            var bookingItemSearchCriteria_1 = new BookingItemSearchCriteria();
            this.dataStoreService.get(DataKey.productQueueSearchCriteria)
                .subscribe(function (data) {
                if (data && data.itemNumber && data.itemNumber !== '-1' && data.itemNumber.toLowerCase() !== 'any') {
                    bookingItemSearchCriteria_1.itemNumber = data.itemNumber;
                }
            });
            bookingItemSearchCriteria_1.bookingIdList = bookingIds;
            bookingItemSearchCriteria_1.start = startRow;
            bookingItemSearchCriteria_1.size = pageSize;
            if (sortBy) {
                bookingItemSearchCriteria_1.sortBy = sortBy;
                if (sortDirection === SetupGridComp.GRID_SORT_ASCENDING) {
                    bookingItemSearchCriteria_1.sortDirection = SortDirection.ASC;
                }
                else {
                    bookingItemSearchCriteria_1.sortDirection = SortDirection.DESC;
                }
            }
            else {
                bookingItemSearchCriteria_1.sortDirection = null;
            }
            return this.reservationV2ManagementService.searchBookingItems(bookingItemSearchCriteria_1)
                .pipe(tap(function (data) {
                if (data) {
                    _this.dataStoreService.set(DataKey.queueBookingSearchResults, data.data);
                }
            }));
        }
        else {
            // todo
            return new Observable();
        }
    };
    BookingQueueComponent.prototype.setupGridHeight = function (searchSize) {
        if (this.gridApi) {
            if (searchSize > 0) {
                this.gridApi.setDomLayout('autoHeight');
                // @ts-ignore
                // document.querySelector('#bookingGrid').style.height = '';
            }
            else {
                this.gridApi.setDomLayout('normal');
                // @ts-ignore
                // document.querySelector('#bookingGrid').style.height = '';
            }
        }
    };
    BookingQueueComponent.prototype.setDataToGrid = function (data) {
        var _this = this;
        if (this.gridApi) {
            this.hideLoading();
            this.gridApi.setRowData(data);
            this.setupGridHeight(data.length);
            this.autoSizeAll(false);
        }
        else {
            setTimeout(function () { return _this.setDataToGrid(data); }, 0);
        }
    };
    BookingQueueComponent.prototype.refreshGridView = function () {
        var _this = this;
        if (this.gridApi) {
            setTimeout(function () {
                _this.gridApi.refreshCells({ force: true });
                _this.gridApi.setRowData(_this.bookingItemSearchResults);
            }, 0);
        }
    };
    BookingQueueComponent.prototype.replaceItem = function (oldBookingItem, replacingItems) {
        if (replacingItems.length > 0) {
            var newBookingItemSearchResults_1 = [];
            this.bookingItemSearchResults.forEach(function (item) {
                if (item.id === oldBookingItem.id) {
                    replacingItems.forEach(function (newItem) { return newBookingItemSearchResults_1.push(newItem); });
                }
                else {
                    newBookingItemSearchResults_1.push(item);
                }
            });
            this.bookingItemSearchResults = newBookingItemSearchResults_1;
            this.setDataToGrid(this.bookingItemSearchResults);
            this.refreshGridView();
        }
    };
    BookingQueueComponent.prototype.replaceWithAutoAllocatedBookingItems = function (oldBookingIdList, replacingItems) {
        var _this = this;
        if (replacingItems.length > 0) {
            var newBookingItemSearchResults_2 = [];
            var index_1 = 0;
            this.bookingItemSearchResults.forEach(function (oldItem) {
                var found = false;
                oldBookingIdList.forEach(function (newItemId) {
                    if (oldItem.id === newItemId) {
                        found = true;
                        index_1 = _this.bookingItemSearchResults.indexOf(oldItem);
                    }
                });
                if (!found) {
                    newBookingItemSearchResults_2.push(oldItem);
                }
            });
            newBookingItemSearchResults_2.splice.apply(newBookingItemSearchResults_2, tslib_1.__spread([index_1, 0], replacingItems));
            this.bookingItemSearchResults = newBookingItemSearchResults_2;
            this.setDataToGrid(this.bookingItemSearchResults);
            this.refreshGridView();
            this.selectRows(replacingItems);
        }
    };
    BookingQueueComponent.prototype.selectColumnGroup = function (type) {
        if (type === 'primary') {
            this.columnDefsInput = this.primaryColumnDefsInput;
        }
        else if (type === 'secondary') {
            this.columnDefsInput = this.secondaryColumnDefsInput;
        }
        this.autoSizeAll(false);
    };
    BookingQueueComponent.prototype.gridColumnVisibleChange = function (fieldName, isHide) {
        if (this.gridColumnApi) {
            this.gridColumnApi.setColumnVisible(fieldName, !isHide);
        }
        var val = (this.columnDefsInput.find(function (res) { return res.field === fieldName; })).hide = isHide;
    };
    BookingQueueComponent.prototype.onRowDoubleClicked = function ($event) {
        this.rowDoubleClicked.emit($event);
    };
    BookingQueueComponent.prototype.cellEditingStopped = function (event) {
        console.log('cell editing stopped');
        var nextCell = GridCommonUtil.getNextCell(event, true);
        this.gridApi.setFocusedCell(nextCell.rowIndex, nextCell.colKey);
        // problem if tab pressed before closi`ng the editor. tabToNext infinitely
        // this.gridApi.tabToNextCell();
    };
    BookingQueueComponent.prototype.onFirstDataRendered = function (params) {
        if (this.autoSize) {
            this.autoSizeAll(false);
        }
        else {
            this.gridApi.sizeColumnsToFit();
        }
    };
    BookingQueueComponent.prototype.saveRowData = function (event) {
        var _this = this;
        this.saveRow(event.data, event).subscribe(function (success) {
            console.log(success);
            _this.unsavedRows = _this.getUnsavedUnselectedRows();
            _this.gridApi.refreshInfiniteCache();
        }, function (error) {
            console.log(error);
        });
    };
    BookingQueueComponent.prototype.saveRow = function (row, event) {
        var saveData = row ? row : event;
        return of();
        // this.reservationV2ManagementService.saveLocation(saveData)
        //            .pipe(tap(data => row.unsaved = undefined));
    };
    BookingQueueComponent.prototype.addUnSavedRowsAgainAfterReload = function () {
        if (this.unsavedRows && this.unsavedRows.length > 0) {
            this.addNewRows(this.unsavedRows);
            this.unsavedRows = [];
        }
    };
    BookingQueueComponent.prototype.getUnsavedUnselectedRows = function () {
        var _this = this;
        var unSelectedRows = [];
        this.gridApi.forEachNode(function (node) {
            console.log(node);
            if (!node.isSelected() && _this.isUnsavedRow(node)) {
                // unSelectedRows.push(node.data);
            }
        });
        return unSelectedRows;
    };
    BookingQueueComponent.prototype.isUnsavedRow = function (row) {
        return !!row.unsaved;
    };
    BookingQueueComponent.prototype.addNewRow = function (newRow, selectRow, index) {
        var _this = this;
        if (index === void 0) { index = 0; }
        if (this.gridApi) {
            this.gridApi.updateRowData({ add: [newRow], addIndex: index });
            if (selectRow) {
                this.selectRow(newRow);
            }
        }
        else {
            setTimeout(function () { return _this.addNewRow(newRow, selectRow, index); }, 0);
        }
    };
    BookingQueueComponent.prototype.addNewRows = function (newRows, index) {
        var _this = this;
        if (index === void 0) { index = 0; }
        newRows.forEach(function (newRow) {
            _this.addNewRow(newRow, false, index);
        });
    };
    BookingQueueComponent.prototype.selectRows = function (bookingItems) {
        this.gridApi.forEachNode(function (node) {
            node.setSelected(bookingItems.includes(node.data));
        });
    };
    BookingQueueComponent.prototype.selectRow = function (row) {
        this.gridApi.forEachNode(function (node) {
            if (!node.isSelected()) {
                node.setSelected(node.data === row);
            }
            else {
                node.setSelected(true);
            }
        });
    };
    BookingQueueComponent.prototype.isInvalidRow = function (params) {
        var data = params.data;
        if (data) {
            var invalid = true;
            if (data.code && data.name && data.locationType) {
                invalid = false;
            }
            return invalid;
        }
        else {
            return false;
        }
    };
    BookingQueueComponent.prototype.onRowClicked = function ($event) {
        this.rowClicked.emit($event);
    };
    BookingQueueComponent.prototype.reloadGridData = function () {
        // todo
    };
    BookingQueueComponent.prototype.ngOnDestroy = function () {
        console.log('destroyed booking view');
        this.operationV2DataProcessorService.autoAllocationButtonEnable = false;
        this.operationV2DataProcessorService.confirmButtonEnable = false;
        this.operationV2DataProcessorService.supplierConfirmButtonEnable = false;
        this.operationV2DataProcessorService.supplierRejectButtonEnable = false;
    };
    return BookingQueueComponent;
}());
export { BookingQueueComponent };
