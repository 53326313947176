import * as tslib_1 from "tslib";
import { KeyControlAndAbstractSearchCriteria } from '../../criteria/key-control-and-abstract-search-criteria';
var ProductQueueSearchCriteria = /** @class */ (function (_super) {
    tslib_1.__extends(ProductQueueSearchCriteria, _super);
    function ProductQueueSearchCriteria() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.subType = '';
        _this.pvtShared = '';
        _this.serviceType = '';
        _this.route = '';
        _this.transferMode = '';
        _this.contractReference = '';
        _this.plCode = '';
        _this.plName = '';
        _this.defaultSupplierName = '';
        _this.allocatedSupplierCode = '';
        _this.allocatedSupplierName = '';
        _this.defaultSupplierCode = '';
        _this.language = '';
        _this.startDate = new Date().toISOString().split('T')[0];
        _this.endDate = new Date(+new Date() + 172800000).toISOString().split('T')[0];
        _this.bookingStartDate = '';
        _this.bookingEndDate = '';
        _this.assignmentId = 0;
        _this.itemNumber = '';
        _this.bookingSourceId = '';
        _this.pickupType = '';
        _this.dropOffType = '';
        _this.supplierConfNumber = '';
        // keyControls
        _this.kcParent = '';
        _this.kcCompany = '';
        _this.kcDivision = '';
        _this.kcBrand = '';
        _this.kcDistributionChannel = '';
        _this.kcExtra1 = '';
        _this.kcExtra2 = '';
        return _this;
    }
    return ProductQueueSearchCriteria;
}(KeyControlAndAbstractSearchCriteria));
export { ProductQueueSearchCriteria };
