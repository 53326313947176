<div class="tc-modal-content">
    <div class="container-fluid">
      <button mat-icon-button
              class="tc-icon-block__icon tc-icon-block__icon--active  pull-right" (click)="onAddSeasonClick($event)"><mat-icon>add</mat-icon>
      </button>
    </div>

    <!--<div class="row" *ngIf="!allRangeGroups || (allRangeGroups && allRangeGroups.length===0)">-->
      <!--No seasons added. <a class="tc-sc-setup-panel__add-btn" (click)="onAddSeasonClick($event)">-->
      <!--<i class="tc-icon-block__icon tc-icon-block__icon&#45;&#45;active"><mat-icon>add</mat-icon></i> <span>Add new</span></a>-->
    <!--</div>-->

  <div class="tc-no-results" *ngIf="!allRangeGroups || (allRangeGroups && allRangeGroups.length===0)">
    <div class="tc-no-results__image">
      <img id="no-results-found" src="../../../../../assets/img/season_unavailable.png">
    </div>
    <div class="tc-no-results__content">
      <div class="tc-no-results__primary">{{"Oh Snap ! There's nothing here." | translate}}</div>
      <div class="tc-no-results__secondary">{{"No season available" | translate}}</div>
    </div>
  </div>

    <div class="tc-sc-setup-panel row">
      <div class="tc-flex-box tc-sc-setup-panel__card container-fluid" *ngFor="let rangeGroup of allRangeGroups">

        <div class="col-xs-12 col-sm-7 tc-flex-box">
          <mat-form-field class="">
            <input
              matInput
              [(ngModel)]="rangeGroup.tempName"
              placeholder="Season Name">
          </mat-form-field>
        </div>
        <div class=" col-xs-12 col-sm-3 tc-flex-box  tc-flex--center-vertical">
          <div class="tc-sc-color {{rangeGroup.tempColorId}}"></div>

          <button mat-icon-button
                  class="tc-icon-block__icon tc-icon-block__icon--active margin-remove"
                  [matMenuTriggerFor]="menu"
                  (click)="onClickPalette()"><mat-icon>palette</mat-icon>
          </button>



          <mat-menu #menu="matMenu">
            <button
              mat-menu-item
              *ngFor="let colorClass of cgCalDateRangeColorCssClasses"
              [disabled]="isDisableColor(colorClass)"
              class="tc-sc-color-palette__item"
              [matTooltip]="colorClass.name"
              [class.tc-sc-color-palette__item--disable]="isDisableColor(colorClass)"
              [ngClass]="colorClass.class"
              (click)="onColorChanged(rangeGroup,colorClass)">
              <mat-icon *ngIf="rangeGroup.tempColorId===colorClass.class">done</mat-icon>
            </button>
          </mat-menu>

        </div>
        <div class="col-xs-12 col-sm-2 text-right remove-bootstrap-right-padding tc-icon-block">
          <button mat-icon-button
            class="tc-icon-block__icon tc-icon-block__icon--active margin-remove"
            [ngClass]="rangeGroup.nonRemovable?'tc-icon-block__icon--inactive':'tc-icon-block__icon--active'"
            (click)="onRemoveSeason(rangeGroup)"><mat-icon>delete</mat-icon>
          </button>
        </div>
      </div>
    </div>


  </div>



<div class="tc-modal-footer">
  <button class="button-left  tc-mr-2 tc-button-secondary mat-button" (click)="onClose($event)">
    Close
  </button>
  <button class="button-right tc-button-primary mat-button" (click)="onSave($event)" [disabled]="!isEnableSaveButton()">
    Save
  </button>
</div>
