<div class="" *ngIf="resultsList" id="vehicle-assign-scroll-container">

  <ng-container
          *ngFor="let summary of resultsList | tcAdvPager:pager.currPage:pager.itemsPerPage | tcAdvSorter:sortingHandler:sorter; let i = index;">

    <div class="">
      <div [ngClass]="hasSelectedThis(summary)?'tc-expanded-content-card--selected':''"
           tcExpansionPanel
           tabindex="-1"
           [exclude]="['event', 'tc-strip-timeline__target tc-strip-timeline__target--single ng-star-inserted', 'tc-strip-timeline__target tc-strip-timeline__target--group']"
           [enabled]="true"
           (clickedOutside)="onOutsideClick(selectedResource)"
           (collapse)="expandedPanelIndex = -1">

        <!-- Content card Strip -->
        <div *tcExpansionPanelSummary id="summarySumExp_{{i}}">

          <tc-content-strip>
            <tc-resource-allocation-timeline
                    [summaryContent]="summary"
                    [itemList]="itemList"
                    (eventClick)="onEventClick($event, summary)"
                    (targetClick)="onTargetClick($event)">
            </tc-resource-allocation-timeline>

          </tc-content-strip>
        </div>

        <div *tcExpansionPanelDetail id="summaryDetailExp_{{i}}">
          <tc-content-strip>
            <tc-resource-allocation-timeline
                    [summaryContent]="summary"
                    [itemList]="itemList"
                    (resourceCalendarClick)="onResourceCalendarClick($event)"
                    (eventClick)="onEventClick($event, summary)"
                    (targetClick)="onTargetClick($event)">
            </tc-resource-allocation-timeline>

          </tc-content-strip>
        </div>
      </div>
    </div>
  </ng-container>
</div>
