import { AssignStatus } from '../reservation-v2/assignment';

export class DashboardSearchItem {
    type: DashboardSearchItemType;
    supplier: string;
    supplierCode: string;
    resource: string;
    resourceCategory: ResourceCategory;
    resourceType: string;
    assignmentId: number;
    assignmentStatus: AssignStatus;
    resourceId: number;
    supplierId: number;
}

export enum DashboardSearchItemType {
    ASSIGNMENT = 'ASSIGNMENT',
    SUPPLIER = 'SUPPLIER',
    RESOURCE = 'RESOURCE',
}

export enum ResourceCategory {
    TRS = 'TRS',
    GEN = 'GEN',
}
