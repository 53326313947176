import { Component, Input, OnInit } from '@angular/core';
import { GridToolTipComponent } from '../../../widgets/framework/ag-grid-custom-components/renderers/tooltip/grid-tool-tip.component';
import { BookingNoteGroup } from '../notes-editor.component';
import {TranslateService} from '@ngx-translate/core';
import {ICellRendererParams} from 'ag-grid-community';

@Component({
    selector: 'tc-booking-note-group',
    templateUrl: './booking-note-group.component.html'
})
export class BookingNoteGroupComponent implements OnInit {

    constructor(
        private translateService: TranslateService
    ) {
        this.translateService.onLangChange.subscribe(() => {
            this.gridApi.refreshHeader();
        });
    }

    @Input() noteGroup: BookingNoteGroup;
    @Input() columnDefs: any[] = [];

    gridApi;

    public gridOptions: any = {
        // resizable: true
    };

    public frameworkComponents: any = {
        tooltipComponent: GridToolTipComponent
    };

    defaultColDef = {
        resizable: true,
        headerValueGetter: (parameters: ICellRendererParams): string => {
            const headerIdentifier = parameters.colDef.headerName;
            if (headerIdentifier) {
                return this.translateService.instant(headerIdentifier);
            }
            return '';
        }
    };

    ngOnInit() {
    }


    public onGridReadyBookingNotes(params: any) {
        this.gridApi = params.api;
        this.gridApi.sizeColumnsToFit();
    }
}
