<div class="container-fluid" *ngIf="!isHeader ">
    <div class="row tc-flex-box">
        <div class="col-xs-12 col-sm-12 col-md-4 tc-flex-item">
            <div class="tc-flex-box">
                <div class="tc-flex-item">
                    <tc-resource
                            *ngIf="summaryCard?.icon && summaryCard?.icon?.value"
                            id="{{summaryCard?.icon?.key}}"
                            [key]="summaryCard?.icon?.value?.key"
                            [value]="summaryCard?.icon?.value?.value"
                            [style]="summaryCard?.icon?.value?.style"
                            [iconType]="summaryCard?.icon?.value?.iconType">
                    </tc-resource>
                </div>
                <div
                        *ngIf="bookingItem" class="tc-flex-item"
                        [ngClass]="isHeader? 'additional-contact--header' : ''">
                    {{bookingItem.bookingReferenceIdWithoutReturnFlag == null ? bookingItem.bookingReferenceId : bookingItem.bookingReferenceIdWithoutReturnFlag}}
                </div>
            </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3  tc-flex-item"
             *ngIf="bookingItem && bookingItem.genBookingItem && bookingItem.mainType !== 'HTL' && bookingItem.genBookingItem.productName">
            <div class="">{{bookingItem.genBookingItem ? bookingItem.genBookingItem.productName : ''}}
            </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3  tc-flex-item"
             *ngIf="bookingItem && bookingItem.genBookingItem && bookingItem.mainType === 'HTL'">
            <div class="">{{bookingItem.genBookingItem ? 'Hotel' : ''}}
            </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 tc-flex-item"
             *ngIf="bookingItem && bookingItem.trsBookingItem">
            <div class="">{{bookingItem.trsBookingItem ? 'Vehicle' : ''}}
            </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 tc-flex-item">
            <div class="tc-flex tc-flex--center-vertical"
                 *ngIf="bookingItem && bookingItem.passengerTempIds">
                <mat-icon class="icon icon--inactive tc-mr-1">people</mat-icon>
                <span>{{' ' + bookingItem.passengerTempIds.length}}</span>
            </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 tc-flex-item">
            <div class="tc-flex tc-flex--center-vertical"
                 *ngIf="bookingItem ">
                <mat-icon class="icon icon--inactive tc-mr-1">schedule</mat-icon>
                <span>{{bookingItem.startTime ? (' ' + this.datePipe.transform(bookingItem.startTime, DATE_FORMAT, getTimeZone(bookingItem.startTime))) : '--'}}</span>
            </div>
        </div>
        <!--        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 tc-gwacs-card-col-3">-->
        <!--        </div>-->


    </div>
</div>


<div class="ecshc-card" *ngIf="showIcons && isHeader">
    <div class="ecshc-card__wrapper">
        <div class="ecshc-card__content ecshc-content-wrapper">
            <h5 class="ecshc-content-wrapper__heading" *ngIf="bookingItem">
                {{bookingItem.bookingReferenceIdWithoutReturnFlag == null ? bookingItem.bookingReferenceId : bookingItem.bookingReferenceIdWithoutReturnFlag}}
            </h5>
        </div>
        <div class="tc-icon-block ecshc-card__actions" *ngIf="showIcons && isHeader">
            <button class="tc-icon-block__icon sort-icon tc-icon-block__icon--active"
                    mat-icon-button
                    matTooltip="{{'View Passengers' | translate}}"
                    (click)="onPassengersClick($event)">
                <i class="material-icons">people</i>
            </button>
        </div>
    </div>
</div>
