/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid.component.ngfactory";
import * as i2 from "../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid.component";
import * as i3 from "../../../services/util/pre-processors/grid-column-definition-processor.service";
import * as i4 from "@ngx-translate/core";
import * as i5 from "@tc/dialog/dialog.service";
import * as i6 from "../../../services/util/framework/data-store.service";
import * as i7 from "../../../services/util/common/dmc-common";
import * as i8 from "./room-occupancy-setup.component";
import * as i9 from "@tc-core/util/framework/config-loader.service";
import * as i10 from "../../../services/backend-consumers/supplier-service/supplier.service";
import * as i11 from "@tc/modal/modal.service";
import * as i12 from "@tc-core/util/ui/spinner.service";
var styles_RoomOccupancySetupComponent = [];
var RenderType_RoomOccupancySetupComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_RoomOccupancySetupComponent, data: {} });
export { RenderType_RoomOccupancySetupComponent as RenderType_RoomOccupancySetupComponent };
export function View_RoomOccupancySetupComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { setupGrid: 0 }), (_l()(), i0.ɵeld(1, 0, null, null, 1, "tc-setup-grid", [], null, null, null, i1.View_SetupGridComponent_0, i1.RenderType_SetupGridComponent)), i0.ɵdid(2, 638976, [[1, 4], ["setupGrid", 4]], 0, i2.SetupGridComponent, [i0.ChangeDetectorRef, i3.GridColumnDefinitionProcessorService, i4.TranslateService, i5.DialogService, i6.DataStoreService, i7.DMCCommon], { readOnly: [0, "readOnly"], headerActions: [1, "headerActions"], cellEditingStart: [2, "cellEditingStart"], dataSource: [3, "dataSource"], setupGridComp: [4, "setupGridComp"], colDefConfig: [5, "colDefConfig"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = false; var currVal_1 = _co.headerActions; var currVal_2 = _co.cellEditingStart; var currVal_3 = _co; var currVal_4 = _co; var currVal_5 = _co.colDefConfig; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
export function View_RoomOccupancySetupComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-room-occupancy-setup", [], null, null, null, View_RoomOccupancySetupComponent_0, RenderType_RoomOccupancySetupComponent)), i0.ɵdid(1, 114688, null, 0, i8.RoomOccupancySetupComponent, [i9.ConfigLoader, i6.DataStoreService, i10.SupplierService, i11.ModalService, i12.SpinnerService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RoomOccupancySetupComponentNgFactory = i0.ɵccf("tc-room-occupancy-setup", i8.RoomOccupancySetupComponent, View_RoomOccupancySetupComponent_Host_0, {}, {}, []);
export { RoomOccupancySetupComponentNgFactory as RoomOccupancySetupComponentNgFactory };
