<div class="tc-ag-grid-action__container" *ngIf="actions?.length>0">
    <div *ngFor="let action of actions" class="tc-flex">
        <button
                mat-icon-button
                matTooltip="{{action.tooltip | translate}}"
                class="tc-m-0 tc-icon-block__icon tc-icon-block__icon--active"
                [disabled]="isActionDisabled(action)"
                (click)="onClick(action)">
            <mat-icon>{{action.icon}}</mat-icon>
        </button>
    </div>
</div>

<!--menu column view-->
<div class="tc-ag-grid-action__container" #actionCell *ngIf="kebabMenus?.length>0">
    <div *ngFor="let kebabMenu of kebabMenus">
        <button
                mat-icon-button
                [matMenuTriggerFor]="menu"
                aria-label="Go to option"
                [disabled]="isKebabMenuDisabled(kebabMenu)">
            <mat-icon>{{kebabMenu.kebabIcon}}</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
            <button
                    mat-menu-item matTooltip="{{action.tooltip | translate}}" *ngFor="let action of kebabMenu.kebabActions"
                    (click)="onClick(action)">
                <mat-icon>{{action.icon}}</mat-icon>
                <span>{{action.actionName | translate}}</span>
            </button>
        </mat-menu>
    </div>
</div>
