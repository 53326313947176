import { DatePipe } from '@angular/common';
import { EventEmitter, Injectable } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { TCO } from '../../../constants';
import { BookingItemGridParam } from '../../../models/reservation-v2/booking-item';
import { MainType } from '../../../models/reservation-v2/main-type';
import { PRODUCT_LEVELS_SHOW_AS } from '../../../models/reservation-v2/product-level';
import { ServiceItemGridParam } from '../../../models/reservation-v2/service-item';
import { ServiceSharing } from '../../../models/reservation-v2/service-sharing';
import { DataKey, DataStoreService } from '../framework/data-store.service';

@Injectable({
    providedIn: 'root'
})
export class OperationV2DataProcessorService {

    confirmButtonEnable = false;
    supplierConfirmButtonEnable = false;
    supplierRejectButtonEnable = false;
    autoAllocationButtonEnable = false;

    EMPTY_STRING = '--';
    NOT_AVAILABLE_STRING = 'N/A';

    datePipe = new DatePipe('en-US');
    simpleDateFormat = 'dd-MM-yyyy';
    simpleTimeFormat = 'h:mm a';

    // helper data
    resourceTypes: any[];
    languages: any[];

    invokeShowSearchCriteriaFunction = new EventEmitter();
    subsVar: Subscription;

    constructor(
        private dataStoreService: DataStoreService
    ) {
    }

    // common technical functions
    getFromConsumer = (handlerObj: any, consumer: any) => {
        if (consumer && handlerObj.handlerParam) {
            const item = _.get(consumer, handlerObj.handlerParam);
            if (item && handlerObj.type && handlerObj.type === 'function') {
                return item.bind(consumer);
            } else {
                if (!item) {
                    if (consumer.constructor && consumer.constructor.name) {
                        console.error(handlerObj.handlerParam + ' not found in ' + consumer.constructor.name);
                    } else {
                        console.error(handlerObj.handlerParam + ' not found in ' + consumer);
                    }
                }
                return item;
            }
        }
    };

    normalCellValueRenderer = (params) => {
        if (params.value) {
            return params.value;
        } else {
            return this.EMPTY_STRING;
        }
    };

    // common for service queue and booking queue
    displayObjName = (option) => {
        if (option && option.name) {
            return option.name;
        } else {
            return '';
        }
    };

    displayObjCodeName = function(option) {
        let codeName = '';
        if (option) {
            if (option.code) {
                codeName = option.code;
            }
            codeName += ' | ';
            if (option.name) {
                codeName += option.name;
            }
            return codeName;
        } else {
            return '';
        }
    };

    getServiceBookingKcCompany = (params: BookingItemGridParam | ServiceItemGridParam) => {
        if (params.data && params.data.kcCompany) {
            return params.data.kcCompany;
        } else {
            return this.EMPTY_STRING;
        }
    };

    getServiceBookingKcDivision = (params: BookingItemGridParam) => {
        if (params.data && params.data.kcDivision) {
            const divisions = this.dataStoreService.get(DataKey.kcDivisions);
            let divName = params.data.kcDivision;
            divisions._value.forEach(division => {
                if (division && division.code && division.code === params.data.kcDivision) {
                    divName = division.name;
                }
            });
            return divName;
        } else {
            return this.EMPTY_STRING;
        }
    };
    getServiceBookingKcBrand = (params: BookingItemGridParam) => {
        if (params.data && params.data.kcBrand) {
            const brands = this.dataStoreService.get(DataKey.kcBrandsList);
            let brandName = params.data.kcBrand;
            brands._value.forEach(brand => {
                if (brand && brand.code && brand.code === params.data.kcBrand) {
                    brandName = brand.name;
                }
            });
            return brandName;
        } else {
            return this.EMPTY_STRING;
        }
    };
    getServiceBookingKcDistributionChannel = (params: BookingItemGridParam) => {
        if (params.data && params.data.kcDistributionChannel) {
            const disChannels = this.dataStoreService.get(DataKey.kcDistributionChannelList);
            let disChannelName = params.data.kcDistributionChannel;
            disChannels._value.forEach(disChannel => {
                if (disChannel && disChannel.code && disChannel.code === params.data.kcDistributionChannel) {
                    disChannelName = disChannel.name;
                }
            });
            return disChannelName;
        } else {
            return this.EMPTY_STRING;
        }
    };


    getServiceBookingServiceDate = (params: BookingItemGridParam | ServiceItemGridParam) => {
        if (params.data && params.data.serviceDate) {
            return this.datePipe.transform(params.data.serviceDate, this.simpleDateFormat, TCO.AppData.GMT_TIME_ZONE);
        } else {
            return this.EMPTY_STRING;
        }
    }; 
    
    getBookingDate = (params: BookingItemGridParam | ServiceItemGridParam) => {
        if (params.data && params.data.bookingDate ) {
            return this.datePipe.transform(params.data.bookingDate, this.simpleDateFormat, TCO.AppData.GMT_TIME_ZONE);
        } else {
            return this.EMPTY_STRING;
        }
    };

    getServiceBookingSharingType = (params: BookingItemGridParam | ServiceItemGridParam) => {
        if (params.data && params.data.pvtShared) {
            if (ServiceSharing.SHARED === params.data.pvtShared) {
                return 'Shared';
            } else if (ServiceSharing.PVT === params.data.pvtShared) {
                return 'Pvt';
            }
        } else {
            return this.NOT_AVAILABLE_STRING;
        }
    };

    getServiceBookingQueueItemType = (params: BookingItemGridParam | ServiceItemGridParam) => {
        if (params.data && params.data) {
            if (params.data.trsBookingItem) {
                return 'Vehicle';
            } else if (params.data.genBookingItem) {
                const product = params.data.genBookingItem.productName;
                if (product) {
                    return product;

                } else {
                    if (params.data.mainType) {
                        if (params.data.mainType === MainType.HTL) {
                            return 'Hotel';
                        } else if (params.data.mainType === MainType.FLT) {
                            return 'Flight';
                        } else if (params.data.mainType === MainType.CAR) {
                            return 'Car';
                        } else if (params.data.mainType === MainType.EXC) {
                            return 'Excursion';
                        }
                    }
                    return this.EMPTY_STRING;
                }
            }
        } else {
            return this.EMPTY_STRING;
        }
    };

    getServiceBookingQueueHotelCity = (params: BookingItemGridParam | ServiceItemGridParam) => {
        if (params.data && params.data.genBookingItem && params.data.genBookingItem.city) {
            return params.data.genBookingItem.city;
        } else {
            return this.EMPTY_STRING;
        }
    };

    getServiceStartTime = (params: BookingItemGridParam | ServiceItemGridParam) => {
        if (params.data && params.data.startTime) {
            return moment(new Date(params.data.startTime)).format('HH:mm');
        } else if (params.data && params.data.primaryAssignment && params.data.primaryAssignment.startTime) {
            return moment(new Date(params.data.primaryAssignment.startTime)).format('HH:mm');
        } else if (params.data && params.data.secondaryAssignment && params.data.secondaryAssignment.startTime) {
            return moment(new Date(params.data.secondaryAssignment.startTime)).format('HH:mm');
        } else if (params.data && !params.data.startTime && params.data.trsBookingItem) {
            params.data.startTime = new Date(params.data.trsBookingItem.departureDateTime);
            return moment(params.data.startTime).format('HH:mm');
        } else if (params.data && params.data.serviceDate) {
            return moment(params.data.serviceDate).format('HH:mm');
        }
    };

    getServiceEndTime = (params: BookingItemGridParam | ServiceItemGridParam) => {
        if (params.data && params.data.endTime) {
            return moment(new Date(params.data.endTime)).format('HH:mm');
        } else if (params.data && params.data.primaryAssignment && params.data.primaryAssignment.endTime) {
            return moment(new Date(params.data.primaryAssignment.endTime)).format('HH:mm');
        } else if (params.data && params.data.secondaryAssignment && params.data.secondaryAssignment.endTime) {
            return moment(new Date(params.data.secondaryAssignment.endTime)).format('HH:mm');
        } else if (params.data && !params.data.endTime && params.data.trsBookingItem) {
            params.data.endTime = new Date(params.data.trsBookingItem.arrivalDateTime);
            return moment(params.data.endTime).format('HH:mm');
        } else if (params.data && params.data.serviceDate) {
            return moment(params.data.serviceDate).format('HH:mm');
        }
    };

    getServiceBookingQueuePrimaryJobId = (params: BookingItemGridParam | ServiceItemGridParam) => {
        if (params.data && params.data.primaryAssignment) {
            if (params.data.primaryAssignment.assignmentId) {
                return params.data.primaryAssignment.assignmentId;
            } else {
                return 'TEMP-' + params.data.primaryAssignment.temporaryAssignmentId;
            }
        } else {
            return this.EMPTY_STRING;
        }
    };

    getServiceBookingQueuePrimaryServiceType = (params: BookingItemGridParam | ServiceItemGridParam) => {
        if (params.data && params.data.primaryAssignment && params.data.primaryAssignment.resourceType) {
            return params.data.primaryAssignment.resourceType;
        } else {
            return this.EMPTY_STRING;
        }
    };

    getServiceBookingQueuePrimarySupplier = (params: BookingItemGridParam | ServiceItemGridParam) => {
        if (params.data && params.data.primaryAssignment && params.data.primaryAssignment.supplierName) {
            return params.data.primaryAssignment.supplierName;
        } else {
            return this.EMPTY_STRING;
        }
    };

    getServiceBookingQueueSupplierConfirmationNo = (params: BookingItemGridParam | ServiceItemGridParam) => {
        if (params.data && params.data.confirmationNo) {
            return params.data.confirmationNo;
        } else {
            return this.EMPTY_STRING;
        }
    };

    getServiceBookingQueuePrimaryResource = (params: BookingItemGridParam | ServiceItemGridParam) => {
        if (params.data && params.data.primaryAssignment && params.data.primaryAssignment.resourceName) {
            return params.data.primaryAssignment.resourceName;
        } else {
            return this.EMPTY_STRING;
        }
    };

    getServiceBookingQueuePrimaryJobPax = (params: BookingItemGridParam | ServiceItemGridParam) => {
        if (params.data && params.data.primaryAssignment) {
            return (params.data.primaryAssignment.adultCount + params.data.primaryAssignment.teenCount +
                    params.data.primaryAssignment.childCount + params.data.primaryAssignment.infantCount) + ' - (' +
                (params.data.primaryAssignment.adultCount + params.data.primaryAssignment.teenCount) + ' ' +
                params.data.primaryAssignment.childCount + ' ' + params.data.primaryAssignment.infantCount + ')';
        } else {
            return this.EMPTY_STRING;
        }
    };

    getServiceBookingQueueSecondaryJobId = (params: BookingItemGridParam | ServiceItemGridParam) => {
        if (params.data && params.data.secondaryAssignment) {
            if (params.data.secondaryAssignment.assignmentId) {
                return params.data.secondaryAssignment.assignmentId;
            } else {
                return 'TEMP-' + params.data.secondaryAssignment.temporaryAssignmentId;
            }
        } else {
            return this.EMPTY_STRING;
        }
    };

    getServiceBookingQueueSecondaryServiceType = (params: BookingItemGridParam | ServiceItemGridParam) => {
        if (params.data && params.data.secondaryAssignment && params.data.secondaryAssignment.resourceType) {
            return params.data.secondaryAssignment.resourceType;
        } else {
            return this.EMPTY_STRING;
        }
    };

    getServiceBookingQueueSecondarySupplier = (params: BookingItemGridParam | ServiceItemGridParam) => {
        if (params.data && params.data.secondaryAssignment && params.data.secondaryAssignment.supplierName) {
            return params.data.secondaryAssignment.supplierName;
        } else {
            return this.EMPTY_STRING;
        }
    };

    getServiceBookingQueueSecondaryResource = (params: BookingItemGridParam | ServiceItemGridParam) => {
        if (params.data && params.data.secondaryAssignment && params.data.secondaryAssignment.resourceName) {
            return params.data.secondaryAssignment.resourceName;
        } else {
            return this.EMPTY_STRING;
        }
    };

    getServiceBookingQueueSecondaryJobPax = (params: BookingItemGridParam | ServiceItemGridParam) => {
        if (params.data && params.data.secondaryAssignment) {
            return (params.data.secondaryAssignment.adultCount + params.data.secondaryAssignment.teenCount +
                    params.data.secondaryAssignment.childCount + params.data.secondaryAssignment.infantCount) + ' - (' +
                (params.data.secondaryAssignment.adultCount + params.data.secondaryAssignment.teenCount) + ' ' +
                params.data.secondaryAssignment.childCount + ' ' + params.data.secondaryAssignment.infantCount + ')';
        } else {
            return this.EMPTY_STRING;
        }
    };

    getServiceBookingQueueTransferMode = (params: BookingItemGridParam | ServiceItemGridParam) => {
        if (params.data && params.data.trsBookingItem && params.data.trsBookingItem.transferModeName) {
            return params.data.trsBookingItem.transferModeName;
        } else if (params.data && params.data.trsBookingItem && params.data.trsBookingItem.transferMode) {
            return params.data.trsBookingItem.transferMode;
        } else {
            return this.EMPTY_STRING;
        }
    };

    getServiceBookingQueueRoute = (params: BookingItemGridParam | ServiceItemGridParam) => {
        if (params.data && params.data.trsBookingItem && params.data.trsBookingItem.route) {
            return params.data.trsBookingItem.route;
        } else {
            return this.EMPTY_STRING;
        }
    };

    getServiceBookingQueuePickupDate = (params: BookingItemGridParam | ServiceItemGridParam) => {
        if (params.data && params.data.trsBookingItem && params.data.trsBookingItem.departureDateTime) {
            return this.datePipe.transform(
                params.data.trsBookingItem.departureDateTime,
                this.simpleDateFormat,
                TCO.AppData.GMT_TIME_ZONE
            );
        } else {
            return this.EMPTY_STRING;
        }
    };

    getServiceBookingQueuePickupTime = (params: BookingItemGridParam | ServiceItemGridParam) => {
        if (params.data && params.data.trsBookingItem && params.data.trsBookingItem.departureDateTime) {
            return this.datePipe.transform(
                params.data.trsBookingItem.departureDateTime,
                this.simpleTimeFormat,
                TCO.AppData.GMT_TIME_ZONE
            );
        } else {
            return this.EMPTY_STRING;
        }
    };

    getServiceBookingQueuePickupLocation = (params: BookingItemGridParam | ServiceItemGridParam) => {
        if (params.data && params.data.trsBookingItem && params.data.trsBookingItem.pickupPointName) {
            return params.data.trsBookingItem.pickupPointName;
        } else {
            return this.EMPTY_STRING;
        }
    };

    getServiceBookingQueuePickupType = (params: BookingItemGridParam | ServiceItemGridParam) => {
        if (params.data && params.data.trsBookingItem && params.data.trsBookingItem.pickupType) {
            return params.data.trsBookingItem.pickupType;
        } else {
            return this.EMPTY_STRING;
        }
    };

    getServiceBookingQueueDropOffDate = (params: BookingItemGridParam | ServiceItemGridParam) => {
        if (params.data && params.data.trsBookingItem && params.data.trsBookingItem.arrivalDateTime) {
            return this.datePipe.transform(
                params.data.trsBookingItem.arrivalDateTime,
                this.simpleDateFormat,
                TCO.AppData.GMT_TIME_ZONE
            );
        } else {
            return this.EMPTY_STRING;
        }
    };

    getServiceBookingQueueDropOffTime = (params: BookingItemGridParam | ServiceItemGridParam) => {
        if (params.data && params.data.trsBookingItem && params.data.trsBookingItem.arrivalDateTime) {
            return this.datePipe.transform(
                params.data.trsBookingItem.arrivalDateTime,
                this.simpleTimeFormat,
                TCO.AppData.GMT_TIME_ZONE
            );
        } else {
            return this.EMPTY_STRING;
        }
    };

    getServiceBookingQueueDropOffLocation = (params: BookingItemGridParam | ServiceItemGridParam) => {
        if (params.data && params.data.trsBookingItem && params.data.trsBookingItem.dropOffPointName) {
            return params.data.trsBookingItem.dropOffPointName;
        } else {
            return this.EMPTY_STRING;
        }
    };

    getServiceBookingQueueDropOffType = (params: BookingItemGridParam | ServiceItemGridParam) => {
        if (params.data && params.data.trsBookingItem && params.data.trsBookingItem.dropOffType) {
            return params.data.trsBookingItem.dropOffType;
        } else {
            return this.EMPTY_STRING;
        }
    };

    // todo consider arrival departure
    getServiceBookingQueueDeptFltDate = (params: BookingItemGridParam | ServiceItemGridParam) => {
        if (params.data && params.data.trsBookingItem && params.data.trsBookingItem.flightDeptTime) {
            return this.datePipe.transform(
                params.data.trsBookingItem.flightDeptTime,
                this.simpleDateFormat,
                TCO.AppData.GMT_TIME_ZONE
            );
        } else {
            return this.EMPTY_STRING;
        }
    };

    getServiceBookingQueueDeptFltTime = (params: BookingItemGridParam | ServiceItemGridParam) => {
        if (params.data && params.data.trsBookingItem && params.data.trsBookingItem.flightDeptTime) {
            return this.datePipe.transform(
                params.data.trsBookingItem.flightDeptTime,
                this.simpleTimeFormat,
                TCO.AppData.GMT_TIME_ZONE
            );
        }
    };

    getServiceBookingQueueDeptFltNo = (params: BookingItemGridParam | ServiceItemGridParam) => {
        if (params.data && params.data.trsBookingItem && params.data.trsBookingItem.departureFlightNo) {
            return params.data.trsBookingItem.departureFlightNo;
        } else {
            return this.EMPTY_STRING;
        }
    };

    getServiceBookingQueueDeptFltAirportCode = (params: BookingItemGridParam | ServiceItemGridParam) => {
        if (params.data && params.data.trsBookingItem && params.data.trsBookingItem.departureAirPort) {
            return params.data.trsBookingItem.departureAirPort;
        } else {
            return this.EMPTY_STRING;
        }
    };

    getServiceBookingQueueDeptFltAirlineCode = (params: BookingItemGridParam | ServiceItemGridParam) => {
        if (params.data && params.data.trsBookingItem && params.data.trsBookingItem.departureAirlineCode) {
            return params.data.trsBookingItem.departureAirlineCode;
        } else {
            return this.EMPTY_STRING;
        }
    };

    getServiceBookingQueueDeptFltTerminal = (params: BookingItemGridParam | ServiceItemGridParam) => {
        if (params.data && params.data.trsBookingItem && params.data.trsBookingItem.flightDeptTerminal) {
            return params.data.trsBookingItem.flightDeptTerminal;
        } else {
            return this.EMPTY_STRING;
        }
    };

    getServiceBookingQueueArrFltDate = (params: BookingItemGridParam | ServiceItemGridParam) => {
        if (params.data && params.data.trsBookingItem && params.data.trsBookingItem.flightArrTime) {
            return this.datePipe.transform(
                params.data.trsBookingItem.flightArrTime,
                this.simpleDateFormat,
                TCO.AppData.GMT_TIME_ZONE
            );
        } else {
            return this.EMPTY_STRING;
        }
    };

    getServiceBookingQueueArrFltTime = (params: BookingItemGridParam | ServiceItemGridParam) => {
        if (params.data && params.data.trsBookingItem && params.data.trsBookingItem.flightArrTime) {
            return this.datePipe.transform(
                params.data.trsBookingItem.flightArrTime,
                this.simpleTimeFormat,
                TCO.AppData.GMT_TIME_ZONE
            );
        }
    };

    getServiceBookingQueueArrFltNo = (params: BookingItemGridParam | ServiceItemGridParam) => {
        if (params.data && params.data.trsBookingItem && params.data.trsBookingItem.arrivalFlightNo) {
            return params.data.trsBookingItem.arrivalFlightNo;
        } else {
            return this.EMPTY_STRING;
        }
    };

    getServiceBookingQueueArrFltAirportCode = (params: BookingItemGridParam | ServiceItemGridParam) => {
        if (params.data && params.data.trsBookingItem && params.data.trsBookingItem.arrivalAirPort) {
            return params.data.trsBookingItem.arrivalAirPort;
        } else {
            return this.EMPTY_STRING;
        }
    };

    getServiceBookingQueueArrFltAirlineCode = (params: BookingItemGridParam | ServiceItemGridParam) => {
        if (params.data && params.data.trsBookingItem && params.data.trsBookingItem.arrivalAirlineCode) {
            return params.data.trsBookingItem.arrivalAirlineCode;
        } else {
            return this.EMPTY_STRING;
        }
    };

    getServiceBookingQueueArrFltTerminal = (params: BookingItemGridParam | ServiceItemGridParam) => {
        if (params.data && params.data.trsBookingItem &&
            params.data.trsBookingItem.flightArrTerminal) {
            return params.data.trsBookingItem.flightArrTerminal;
        } else {
            return this.EMPTY_STRING;
        }
    };

    getServiceBookingQueueTotalCost = (params: BookingItemGridParam | ServiceItemGridParam) => {
        let cost = 0;
        if (params.data && params.data.itemCostPortion) {
            cost = params.data.itemCostPortion.totalCost;
        }
        if (cost) {
            return cost.toFixed(2) + ' ' + params.data.itemCostPortion.currency;
        } else {
            return this.EMPTY_STRING;
        }
    };

    // for booking queue
    getBookingQueueClientGroup = (params: BookingItemGridParam) => {
        if (params.data && params.data.bookingClient && params.data.bookingClient.clientGroupName) {
            return params.data.bookingClient.clientGroupName;
        } else {
            return this.EMPTY_STRING;
        }
    };

    getBookingQueueClientGrade = (params: BookingItemGridParam) => {
        if (params.data && params.data.bookingClient && params.data.bookingClient.grade) {
            return params.data.bookingClient.grade;
        } else {
            return this.EMPTY_STRING;
        }
    };

    getBookingQueueCompany = (params: BookingItemGridParam) => {
        if (params.data && params.data.kcCompany) {
            return params.data.kcCompany;
        } else {
            return this.EMPTY_STRING;
        }
    };
    getBookingQueueDivision = (params: BookingItemGridParam) => {
        if (params.data && params.data.kcDivision) {
            const divisions = this.dataStoreService.get(DataKey.kcDivisions);
            let divName = params.data.kcDivision;
            divisions._value.forEach(division => {
                if (division && division.code && division.code === params.data.kcDivision) {
                    divName = division.name;
                }
            });
            return divName;
        } else {
            return this.EMPTY_STRING;
        }
    };
    getBookingQueueBrand = (params: BookingItemGridParam) => {
        if (params.data && params.data.kcBrand) {
            const brands = this.dataStoreService.get(DataKey.kcBrandsList);
            let brandName = params.data.kcBrand;
            brands._value.forEach(brand => {
                if (brand && brand.code && brand.code === params.data.kcBrand) {
                    brandName = brand.name;
                }
            });
            return brandName;
        } else {
            return this.EMPTY_STRING;
        }
    };
    getBookingQueueDistributionChannel = (params: BookingItemGridParam) => {
        if (params.data && params.data.kcDistributionChannel) {
            const disChannels = this.dataStoreService.get(DataKey.kcDistributionChannelList);
            let disChannelName = params.data.kcDistributionChannel;
            disChannels._value.forEach(disChannel => {
                if (disChannel && disChannel.code && disChannel.code === params.data.kcDistributionChannel) {
                    disChannelName = disChannel.name;
                }
            });
            return disChannelName;
        } else {
            return this.EMPTY_STRING;
        }
    };

    // for service queue
    getServiceQueueClientGroup = (params: ServiceItemGridParam) => {
        if (params.data && params.data.bookingClients && params.data.bookingClients.length) {
            const groupNames = [];
            for (const bookingClient of params.data.bookingClients) {
                if (bookingClient.clientGroupName && !groupNames.includes(bookingClient.clientGroupName)) {
                    groupNames.push(bookingClient.clientGroupName);
                }
            }
            return groupNames.join(', ');
        } else {
            return this.EMPTY_STRING;
        }
    };

    getServiceQueueClientGrade = (params: ServiceItemGridParam) => {
        if (params.data && params.data.bookingClients && params.data.bookingClients.length) {
            const grades = [];
            for (const bookingClient of params.data.bookingClients) {
                if (bookingClient.grade && !grades.includes(bookingClient.grade)) {
                    grades.push(bookingClient.grade);
                }
            }
            return grades.join(', ');
        } else {
            return this.EMPTY_STRING;
        }
    };
    //
    /*
     product queue specific functions
     */
    getProductQueueProductLevel = (params) => {
        if (params.data && params.data.productLevel) {
            const prodLevel = PRODUCT_LEVELS_SHOW_AS[params.data.productLevel];
            return prodLevel ? prodLevel : this.EMPTY_STRING;
        } else {
            return this.EMPTY_STRING;
        }
    };

    getProductQueueMainTypes = (params) => {
        if (params.data && params.data.mainTypes) {
            return params.data.mainTypes.join();
        } else {
            return this.EMPTY_STRING;
        }
    };

    getProductQueueSubTypes = (params) => {
        if (params.data && params.data.subTypes && params.data.subTypes.length) {

            // todo: remove this code fragment when data is cleared.
            const indexSharedTour = params.data.subTypes.indexOf('Shared');
            if (indexSharedTour !== -1) {
                params.data.subTypes[indexSharedTour] = 'Shared Tour';
            }

            const indexPvtTour = params.data.subTypes.indexOf('Private');
            if (indexPvtTour !== -1) {
                params.data.subTypes[indexPvtTour] = 'Private Tour';
            }
            return params.data.subTypes.join();
        } else {
            return this.NOT_AVAILABLE_STRING;
        }
    };

    getProductQueueSharingType = (params) => {
        if (params.data && params.data.subTypes && params.data.pvtShared) {
            if ('SHARED' === params.data.pvtShared) {
                return 'Shared';
            } else if ('PVT' === params.data.pvtShared) {
                return 'Pvt';
            }
        } else {
            return this.NOT_AVAILABLE_STRING;
        }
    };

    getProductQueueSupplierName = (params) => {
        if (params.data) {
            if (params.data.supplierName) {
                return params.data.supplierName;
            } else if (params.data.supplierCode) {
                return params.data.supplierCode;
            } else {
                return this.EMPTY_STRING;
            }
        } else {
            return this.EMPTY_STRING;
        }
    };

    getProductQueueStartDate = (params) => {
        if (params.data && params.data.startDate) {
            return this.datePipe.transform(params.data.startDate, this.simpleDateFormat, TCO.AppData.GMT_TIME_ZONE);
        } else {
            return this.NOT_AVAILABLE_STRING;
        }
    };

    getProductQueueEndDate = (params) => {
        if (params.data && params.data.endDate) {
            return this.datePipe.transform(params.data.endDate, this.simpleDateFormat, TCO.AppData.GMT_TIME_ZONE);
        } else {
            return this.NOT_AVAILABLE_STRING;
        }
    };

    getProductQueueLanguage = (params) => {
        if (params.data && params.data.languages && params.data.languages.length) {
            return params.data.languages.join();
        } else {
            return this.NOT_AVAILABLE_STRING;
        }
    };

    // booking queue specific functions
    getBookingQueueBookingId = (params: BookingItemGridParam) => {
        let id = '';
        if (params.data) {
            if (params.data.bookingSource) {
                id = params.data.bookingSource + '-';
            }
            if (params.data.bookingSourceId) {
                id += params.data.bookingSourceId;
            }
            return id;
        } else {
            return this.EMPTY_STRING;
        }
    };

    // booking queue specific functions
    getBookingQueueTbxBookingRefId = (params: BookingItemGridParam) => {
        let id = '';
        if (params.data) {
            if (params.data.bookingSourceId) {
                id += params.data.bookingSourceId;
            }
            if (params.data.productCategory) {
                id += '_' + params.data.productCategory;
            }
            if (params.data.bookingSourceItemNo) {
                id += '_' + params.data.bookingSourceItemNo;
            }
            return id;
        } else {
            return this.EMPTY_STRING;
        }
    };

    getBookingQueueTotalPax = (params: BookingItemGridParam) => {
        if (params.data) {
            return (params.data.adultCount + params.data.teenCount + params.data.childrenCount +
                    params.data.infantCount) + ' - (' +
                (params.data.adultCount + params.data.teenCount) + ' ' + params.data.childrenCount + ' ' +
                params.data.infantCount + ')';
        } else {
            return this.EMPTY_STRING;
        }
    };

    // service queue specific functions
    getServiceQueueServiceId = (params: ServiceItemGridParam) => {
        return this.EMPTY_STRING;
    };

    getServiceQueueItemName = (params: ServiceItemGridParam) => {
        return this.EMPTY_STRING;
    };

    getServiceQueueTotalPax = (params: ServiceItemGridParam) => {
        if (params.data) {
            return (params.data.adultCount + params.data.teenCount + params.data.childrenCount +
                    params.data.infantCount) + ' - (' +
                (params.data.adultCount + params.data.teenCount) + ' ' + params.data.childrenCount + ' ' +
                params.data.infantCount + ')';
        } else {
            return this.EMPTY_STRING;
        }
    };

    // showSavedCriteriaClickObserver(): Observable<any> {
    //     return this.subject.asObservable();
    // }

    // vehicle
    getServiceBookingQueueVehicleCount = (params: BookingItemGridParam | ServiceItemGridParam) => {
        if (params.data && params.data.trsBookingItem && params.data.trsBookingItem.vehicleCount) {
            return params.data.trsBookingItem.vehicleCount;
        } else {
            return this.EMPTY_STRING;
        }
    };

    onShowSavedCriteriaClick() {
        this.invokeShowSearchCriteriaFunction.emit();
    }

}
