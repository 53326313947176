<div class="product-promotion-search-results">

  <!--Results header-->
  <tc-results-header
          *ngIf="contentCardDataList && contentCardDataList.length > 0 && !isSearchInProgress"
          [heading]="resultsHeaderData?.heading"
          [actions]="resultsHeaderData?.actions"
          [columns]="resultsHeaderData?.columns"
          [toggleAction]="resultsHeaderData?.toggleAction"
          [sorters]="sorterService.sorters"
          [checkBox]="getMultiSelectCheckBox()"
          (selectSorter)="onSort($event)"
          (headerSelect)="onHeaderActionSelect($event)"
          (actionClick)="onHeaderActionClick($event)">
  </tc-results-header>

  <!--Search results-->
  <div class="tc-border-list" *ngIf="contentCardDataList && contentCardDataList.length > 0 && !isSearchInProgress"
       [id]="'RESULT_CONTAINER'" @list>
    <div class="tc-border-list__repeat-wrapper" @items
         *ngFor="let cardData of contentCardDataList | tcAdvSorter:sorterService.method:sorterService.sorter | tcAdvPager:pager.currPage:pager.itemsPerPage; let i = index">
      <tc-card-strip [id]="'RESULT_INDEX_'+i" class="tc-card-strip__temp-border"
                     [cardData]="cardData"
                     [type]="cardType"
                     [checked]="isSelected(cardData)"
                     (select)="onSelectCard($event)">
      </tc-card-strip>
    </div>
  </div>

  <!--Search result pre loader-->
  <!--<tc-pre-result-loader
          [resultsCount]="defaultPageResultCount"
          [resultsHeaders]="resultsHeaderData?.columns">
  </tc-pre-result-loader>-->

  <!--Search results paginator-->
  <tc-paginator
          *ngIf="contentCardDataList.length > defaultPageResultCount"
          [length]="contentCardDataList.length"
          [index]="pager.currPage"
          [size]="pager.itemsPerPage"
          (page)="onPage($event)"
          (more)="onMore()">
  </tc-paginator>

</div>

<!--Empty search results view-->
<tc-empty-result
        *ngIf="contentCardDataList && contentCardDataList.length === 0
&& contentCardDataList.length === 0 && !this.isSearchInProgress"
        [noResultsText]="noResultsText">
</tc-empty-result>