import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ChipInputModule } from '@tc/chip-input';
import {
    DocumentQueueSearchCriteriaComponent
} from './document-queue-search-criteria/document-queue-search-criteria.component';
import { GridSearchCriteriaComponent } from './grid-search-criteria.component';
import { LedgerEntrySearchCriteriaComponent } from './ledger-entry-search-criteria/ledger-entry-search-criteria.component';
import { ManifestsSearchCriteriaComponent } from './manifest-search-criteria/manifests-search-criteria.component';
import { ReportsSearchCriteriaComponent } from './reports-search-criteria/reports-search-criteria.component';

@NgModule({
    declarations: [
        GridSearchCriteriaComponent,
        ManifestsSearchCriteriaComponent,
        ReportsSearchCriteriaComponent,
        DocumentQueueSearchCriteriaComponent,
        LedgerEntrySearchCriteriaComponent
    ],
    imports: [
        CommonModule,
        ChipInputModule
    ],
    exports: [
        GridSearchCriteriaComponent,
        ManifestsSearchCriteriaComponent,
        ReportsSearchCriteriaComponent,
        DocumentQueueSearchCriteriaComponent,
        LedgerEntrySearchCriteriaComponent
    ],
    entryComponents: [
        GridSearchCriteriaComponent,
        ManifestsSearchCriteriaComponent,
        ReportsSearchCriteriaComponent,
        DocumentQueueSearchCriteriaComponent,
        LedgerEntrySearchCriteriaComponent
    ]
})
export class GridSearchCriteriaModule {}
