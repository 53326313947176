<div class="tc-side-nav navbar navbar-transparent custom-sidebar-styles menu-animate">
    <div class="">
        <!-- logo and top section -->
        <div class="logo" style="cursor: pointer">
            <div class="logo-normal">
                <a (click)="onLogoClick()" class="logo-dmc"></a>
            </div>
        </div>

        <!-- sidebar content section -->
        <div class="sidebar-wrapper" style="flex-direction: column;display: flex;">
            <!-- client section -->
            <div
                    *ngIf="client?.name"
                    [id]="'CLI_ID_' + client?.id"
                    class="user tc-cursor-pointer"
                    (click)="onClientSelect(client)">

                <div class="photo">
                    <i class="material-icons" *ngIf="client?.type === ClientType.DIRECT"> person </i>
                    <i class="material-icons" *ngIf="client?.type === ClientType.TRADE"> people </i>
                    <i class="material-icons" *ngIf="client?.type === ClientType.AGENT"> people </i>
                </div>

                <div class="info">
                    <a class="">
            <span
                    id="NAV_CLI_NME"
                    class="ellipsis-text tc-body1--txt">{{client?.name !== '' ? client?.name : 'Not Available'}}</span>
                    </a>
                </div>
            </div>

            <!-- cart section -->
            <div
                    *ngIf="cart && cartItemCount>0"
                    class="user tc-cursor-pointer"
                    (click)="onCartSelect(cart)">

                <div class="cart-number-display">
                    <div class="display-numbers">
                        <span>{{cartItemCount}}</span>
                    </div>
                </div>

                <div class="photo">
                    <i class="material-icons"> shopping_cart </i>
                </div>
                <div class="info">
                    <a class="">
                        <span id="CART" class="ellipsis-text tc-body1--txt"> {{'Shopping Cart' | translate}} </span>
                    </a>
                </div>
            </div>

            <!-- navigation items section -->
            <div class="nav-container">
                <div class="nav-refactoring">
                    <ul class="nav">
                        <li
                            #navitem
                            *ngFor="let navItem of navItems; let i = index;"
                            [tcSectionRestricted]="navItem.restricted"
                            routerLinkActive="active"
                            [id]="'NAV_ITEM_' + navItem?.id"
                            (click)="navItem.subItems && navItem.subItems.length > 0 ? toggleSubMenu($event, navItem) : onNavigationItemSelect($event, navItem, i)"
                            [ngClass]="{'active-tab-item': selectedTab == i, 'expanded': navItem['expand']}">
                            <a class="list-items tc-pr-2" [ngClass]="{'sid-menu--select': i === navItemIndex}">
                                <i class="material-icons">{{navItem?.icon}}</i>
                                <p id="{{navItem?.id}}">{{navItem.title | translate}}</p>
                                <div class="badge tc-status-badge--warning tc-ml-2"
                                        *ngIf="isCountAvailable(navItem.id)">
                                    <span>{{getCount(navItem.id)}}</span>
                                </div>
                                <!--                <p *ngIf="isCountAvailable(navItem.id)">{{getCount(navItem.id)}}</p>-->
                                <span class="arrow-folder" *ngIf="navItem.subItems && navItem.subItems.length > 0">
<!--                  <i class="material-icons arrow-down">arrow_drop_down</i>-->
                                 <i class="fa fa-chevron-down" aria-hidden="true"></i>
                </span>
                            </a>

                            <div class="collapse" [ngClass]="{'animated slideInDown in': navItem['expand']}">
                                <ul
                                        class="nav animated slideInDown"
                                        *ngIf="navItem.subItems && navItem.subItems.length > 0">
                                    <li
                                        #navSubItem
                                        *ngFor="let item of navItem?.subItems; let j = index;"
                                        [tcSectionRestricted]="item.restricted"
                                        routerLinkActive="active"
                                        [id]="'NAV_ITEM_' + item?.id"
                                        [ngClass]="{'active-tab-item': selectedSubTab === (j+'-'+i)}"
                                        (click)="onNavigationSubItemSelect($event, item, j+'-'+i)">
                                        <a
                                                class="list-items"
                                                [ngClass]="{'sid-menu--select': j === navSubItemIndex}">
                                            <i id="NAV_SUBMENU_ICN" class="material-icons">{{item?.icon}}</i>

                                            <p
                                                    id="{{item?.id}}"
                                                    i18n="side nav - nav options@@sideNavOptions">{{item.title | translate}}</p>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <span class="material-icons dmc-version">{{version}}</span>
        </div>
    </div>
</div>
