import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AssignmentFocusViewComponent} from './assignment-focus-view.component';
import {MatTabsModule} from '@angular/material';
import {SupplierAssignPanelModule} from '../reservation-management/supplier-assign-panel/supplier-assign-panel.module';
import {VehicleAssignPanelModule} from '../reservation-management/vehicle-assign-panel/vehicle-assign-panel.module';
import {DriverAssignPanelModule} from '../reservation-management/driver-assign-panel/driver-assign-panel.module';
import {CostCalculationUtilsService} from '../../services/business-utils/cost-calculation/cost-calculation-utils.service';
import {ResourceAssignPanelModule} from "../reservation-management/resource-assign-panel/resource-assign-panel.module";

@NgModule({
    declarations: [AssignmentFocusViewComponent],
    exports: [AssignmentFocusViewComponent],
    entryComponents: [AssignmentFocusViewComponent],
    providers:[CostCalculationUtilsService],
    imports: [
        CommonModule,
        MatTabsModule,
        SupplierAssignPanelModule,
        VehicleAssignPanelModule,
        DriverAssignPanelModule,
        ResourceAssignPanelModule
    ]
})
export class AssignmentFocusViewModule {
}
