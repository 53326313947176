import { AfterViewInit, Component, ViewChild, ViewContainerRef } from '@angular/core';
import { MatSelect } from '@angular/material';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import * as _ from 'lodash';

@Component({
    selector: 'tc-dropdown-editor',
    templateUrl: './dropdown-editor.component.html'
})
export class DropdownEditorComponent implements ICellEditorAngularComp, AfterViewInit {

    @ViewChild('selectBox', {read: ViewContainerRef}) public selectBoxContainerRef;
    @ViewChild('selectBox', {read: MatSelect}) public selectBox: MatSelect;

    // input parameters
    params: any;
    optionsFn: any;
    options: any[];
    plainOptionArray: false;
    selectMultiple: false;
    viewKey = 'name';
    valueKey = 'code';
    rawValue: any;
    returnObject = false;
    rowObject: string;
    rowData: any;
    displayItemFn: any;
    popup = true;

    // selected
    selectedItem: any;
    columnWidth: string;

    constructor() {
    }

    ngAfterViewInit(): void {
        window.setTimeout(() => {
            this.selectBoxContainerRef.element.nativeElement.focus();
            this.selectBox.open();
        });
    }

    agInit(params): void {
        this.params = params;
        this.columnWidth = params.column.actualWidth + 'px';
        this.optionsFn = params['optionsFn'];
        this.options = params['options'];
        this.plainOptionArray = params['plainOptionArray'] ? params['plainOptionArray'] : this.plainOptionArray;
        this.selectMultiple = params['selectMultiple'];
        this.viewKey = params['viewKey'] ? params['viewKey'] : this.viewKey;
        this.valueKey = params['valueKey'] ? params['valueKey'] : this.valueKey;
        this.returnObject = params['returnObject'] ? params['returnObject'] : this.returnObject;
        this.rowData = params['data'];
        this.rowObject = params['rowObject'];
        this.rawValue = params['value'];
        this.popup = params['isPopup'];
        if (this.rowObject) {
            this.selectedItem = _.get(params.data, this.rowObject);
        } else {
            this.selectedItem = params['value'];
        }
        if (this.selectedItem && this.selectMultiple && !Array.isArray(this.selectedItem)) {
            this.selectedItem = [this.selectedItem];
        }
        this.displayItemFn = params['displayItemFn'];

        // initial display function
        if (!this.displayItemFn) {
            this.displayItemFn = (option) => {
                if (this.plainOptionArray) {
                    return option;
                } else {
                    return option[this.viewKey];
                }
            };
        }

        if (this.optionsFn) {
            this.options = this.optionsFn(params, this.rowData);
        }

    }

    getValue(): any {
        if (this.selectedItem) {
            if (this.returnObject || this.plainOptionArray) {
                return this.selectedItem;
            } else if (this.valueKey) {
                return this.selectedItem[this.valueKey] ? this.selectedItem[this.valueKey] : null;
            }
        } else {
            return this.rawValue;
        }
    }

    isPopup(): boolean {
        return true;
    }

    onValueChange($event: any) {
        if (!this.selectMultiple) {
            this.params.api.stopEditing();
        }
    }
}
