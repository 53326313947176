import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { TC } from '@tc-core/util/constants';
import { UserJourneyManager } from '@tc-core/util/framework/user-journey-manager.service';
import { NavItem } from '@tc-core/model/it/codegen/ui/framework/nav-item';
import { EventManager } from '@tc-core/util/framework/event-manager.service';
import { FocusHandlerService } from '@tc-core/util/framework/focus-handler.service';

@Component({
  selector: 'tc-fab-wrapper',
  templateUrl: './fab-wrapper.component.html'
})
export class FabWrapperComponent implements OnInit, OnDestroy {
    public actions: NavItem[];
    public canProceedState: boolean;
    public page = 'EXAMPLE1';
    public canProceed = false;
    public isActive = false;
    public selectedSubActionIdx = -1;

    constructor(private userJourneyManager: UserJourneyManager,
                public em: EventManager,
                private element: ElementRef,
                private focusHandeler: FocusHandlerService) {}

    ngOnInit() {
        // this.action = this.configLoader.configurations.get(TC.CONF.CONF_FAB)[this.page];
        this.userJourneyManager.fabItems.subscribe(items => {
            if (items) {
                this.actions = items;
            }
        });

        this.userJourneyManager.canProceedState.subscribe(canProceedState => {
            this.canProceedState = canProceedState;
        });

        this.userJourneyManager.canProceed.subscribe(canProceed => {
            if (!canProceed && this.actions && this.actions.length > 0) {
                this.actions[0].disable = true;
            } else if (canProceed && this.actions && this.actions.length > 0) {
                this.actions[0].disable = false;
            }
        });

        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'FOCUS_TO_FAB').subscribe(e => {  // todo change config
            this.focusOnFab();
        });
    }

    focusOnFab() {
        this.isActive = !this.isActive;
        if (this.isActive) {
            this.focusHandeler.isFabActive = true;
        } else {
            this.focusHandeler.isFabActive = false;
        }
    }

    onTrigger(navItem: NavItem) {
        this.userJourneyManager.go(navItem);
    }

    ngOnDestroy() {
        this.em.removeEvents([
            'FOCUS_TO_FAB'
        ]);
    }

    onFABToggle(event) {
        this.isActive = event;
    }

    onFabClose() {
        this.isActive = false;
    }
}
