import {Component, Input, OnInit} from '@angular/core';
import { DataKey, DataStoreService } from '../../../services/util/framework/data-store.service';

@Component({
    selector: 'tc-add-documents',
    templateUrl: './add-documents.component.html'
})
export class AddDocumentsComponent implements OnInit {
    @Input() selectedDocs: any[];
    @Input() docTypeOptions: any[];
    constructor(
        private dataStore: DataStoreService
    ) {
    }

    ngOnInit() {
    }

    closeModal(event: any[]) {
        this.dataStore.set(DataKey.documentTypes, event, true);
        this.dataStore.set(DataKey.modalClose, true);
    }

}
