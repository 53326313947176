import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DirectivesModule } from '@tc/directives';
import { KeyControlsModule } from '@tc/key-controls';
import { SectionHeadingModule } from '@tc/section-heading';
import { AgGridModule } from 'ag-grid-angular';
import { CountryStateCityService } from '../../../services/business-utils/country-state-city/country-state-city.service';
import { AdditionalContactsModule } from '../../../widgets/shared/additional-contacts/additional-contacts.module';
import { ContactDetailsModule } from '../../../widgets/shared/contact-details/contact-details.module';
import { DocumentViewCardModule } from '../../../widgets/shared/document-view-card/document-view-card.module';
import { PaymentInformationModule } from '../../../widgets/shared/payment-information/payment-information.module';
import { RoomsModule } from '../../../widgets/shared/rooms/rooms.module';
import { SupplierAttributesModule } from '../../../widgets/shared/supplier-attributes/supplier-attributes.module';
import { SupplierInformationModule } from '../../../widgets/shared/supplier-information/supplier-information.module';
import { TaxSchemesModule } from '../../../widgets/shared/tax-schemes/tax-schemes.module';
import { KeyControlsEditViewModule } from '../common/key-controls-edit-view/key-controls-edit-view.module';
import { OccupancySetupComponent } from './occupancy-setup/occupancy-setup.component';
import { RoomGrpViewCardModule } from './res-collection/room-grp-view-card/room-grp-view-card.module';
import { SupplierCostSetupModule } from './supplier-cost-setup/supplier-cost-setup.module';
import { SupplierComponent } from './supplier.component';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
    imports: [
        CommonModule,
        SectionHeadingModule,
        DirectivesModule,
        SupplierInformationModule,
        ContactDetailsModule,
        PaymentInformationModule,
        AdditionalContactsModule,
        RoomsModule,
        SupplierCostSetupModule,
        SupplierAttributesModule,
        TaxSchemesModule,
        DocumentViewCardModule,
        RoomGrpViewCardModule,
        AgGridModule,
        TranslateModule.forChild(),
        KeyControlsEditViewModule,
        KeyControlsEditViewModule
    ],
    providers: [CountryStateCityService],
    declarations: [SupplierComponent, OccupancySetupComponent],
    exports: [SupplierComponent, OccupancySetupComponent],
    entryComponents: [SupplierComponent, OccupancySetupComponent]
})
export class SupplierModule {}
