import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaginatorModule } from '@tc/paginator';
import { ResultHeaderModule } from '../../../../widgets/shared/result-header/result-header.module';
import { AdvSupplierAllocationSearchResultsModule } from './adv-supplier-allocation-search-results/adv-supplier-allocation-search-results.module';
import { SupplierAllocationPanelComponent } from './supplier-allocation-panel.component';
import { SupplierAllocationSearchCriteriaModule } from './supplier-allocation-search-criteria/supplier-allocation-search-criteria.module';

@NgModule({
    declarations: [SupplierAllocationPanelComponent],
    exports: [
        SupplierAllocationPanelComponent
    ],
    imports: [
        CommonModule,
        PaginatorModule,
        ResultHeaderModule,
        SupplierAllocationSearchCriteriaModule,
        AdvSupplierAllocationSearchResultsModule
    ]
})
export class SupplierAllocationPanelModule { }
