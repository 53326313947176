<div class="ecshc-card">

  <div class="ecshc-card__wrapper" (click)="onHeaderClick($event)">
    <div class="tc-flex-item">
      <tc-resource
        *ngIf="icon?.value"
        id="{{icon?.key}}"
        [key]="icon?.value?.key"
        [value]="icon?.value?.value"
        [style]="icon?.value?.style"
        [iconType]="icon?.value?.iconType">
      </tc-resource>
    </div>

    <div class="ecshc-card__content ecshc-content-wrapper">
      <h5 class="ecshc-content-wrapper__heading">
        <ng-container>{{headerText | translate}}</ng-container>
      </h5>
      <small class="ecshc-content-wrapper__secondary-heading">{{secondaryHeaderText}}</small>
    </div>
  </div>
</div>
<mat-progress-bar
  class="tc-progress--bar ecshc-progress-bar" *ngIf="showProgress === true" mode="indeterminate"
  value="30"></mat-progress-bar>
