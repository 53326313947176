import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule, MatTooltipModule } from '@angular/material';
import { MatButtonModule } from '@angular/material/button';
import { PaginatorModule } from '@tc/paginator';
import { ResultHeaderModule } from '../../../../widgets/shared/result-header/result-header.module';
import { AdvResourceAllocationSearchResultsModule } from './adv-resource-allocation-search-results/adv-resource-allocation-search-results.module';
import { ResourceAllocationPanelComponent } from './resource-allocation-panel.component';
import { ResourceAllocationSearchCriteriaModule } from './resource-allocation-search-criteria/resource-allocation-search-criteria.module';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
    declarations: [ResourceAllocationPanelComponent],
    exports: [
        ResourceAllocationPanelComponent,
    ],
    imports: [
        CommonModule,
        ResourceAllocationSearchCriteriaModule,
        ResultHeaderModule,
        PaginatorModule,
        AdvResourceAllocationSearchResultsModule,
        MatIconModule,
        MatTooltipModule,
        MatButtonModule,
        TranslateModule
    ]
})
export class ResourceAllocationPanelModule { }
