import {NgModule} from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import {DataFormatterUtilService} from '@tc-core/util/system/data-formatter-util.service';
import {ContentStripModule} from '@tc/content-strip';
import {DirectivesModule} from '@tc/directives';
import {ExpandedContentCardModule} from '@tc/expanded-content-card';
import {PaginatorModule} from '@tc/paginator';
import {PipesModule} from '@tc/pipes';
import {OpExpandedContentCardHeaderModule} from '../op-expanded-content-card-header/op-expanded-content-card-header.module';
import {ResultContentCardGenericModule} from '../result-content-card-generic/result-content-card-generic.module';
import {AdvRoomSearchResultsComponent} from './adv-room-search-results.component';

@NgModule({
    imports: [
        CommonModule,
        PipesModule,
        DirectivesModule,
        ResultContentCardGenericModule,
        ContentStripModule,
        ExpandedContentCardModule,
        OpExpandedContentCardHeaderModule,
        PaginatorModule
    ],
    providers: [
        DataFormatterUtilService,
        DatePipe
    ],
    declarations: [AdvRoomSearchResultsComponent],
    exports: [AdvRoomSearchResultsComponent]
})
export class AdvRoomSearchResultsModule {
}
