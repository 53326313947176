/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid.component.ngfactory";
import * as i2 from "../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid.component";
import * as i3 from "../../../services/util/pre-processors/grid-column-definition-processor.service";
import * as i4 from "@ngx-translate/core";
import * as i5 from "@tc/dialog/dialog.service";
import * as i6 from "../../../services/util/framework/data-store.service";
import * as i7 from "../../../services/util/common/dmc-common";
import * as i8 from "./ledger-entry-search-results.component";
import * as i9 from "@tc-core/util/framework/config-loader.service";
import * as i10 from "../../../services/backend-consumers/master-data-handler-service/data-handler.service";
import * as i11 from "../../../services/backend-consumers/setups/accounting-rule.service";
import * as i12 from "@angular/router";
import * as i13 from "@tc/focus-view/focus-view.service";
import * as i14 from "@tc-core/util/framework/event-manager.service";
import * as i15 from "@tc-core/util/framework/user-journey-manager.service";
import * as i16 from "@tc-core/util/ui/spinner.service";
var styles_LedgerEntrySearchResultsComponent = [];
var RenderType_LedgerEntrySearchResultsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_LedgerEntrySearchResultsComponent, data: {} });
export { RenderType_LedgerEntrySearchResultsComponent as RenderType_LedgerEntrySearchResultsComponent };
export function View_LedgerEntrySearchResultsComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { setupGrid: 0 }), i0.ɵqud(402653184, 2, { statusCell: 0 }), i0.ɵqud(402653184, 3, { nameFormatCell: 0 }), i0.ɵqud(402653184, 4, { formatCell: 0 }), i0.ɵqud(402653184, 5, { generatedByCell: 0 }), (_l()(), i0.ɵeld(5, 0, null, null, 1, "tc-setup-grid", [], null, [[null, "rowDoubleClicked"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("rowDoubleClicked" === en)) {
        var pd_0 = (_co.onRowDoubleClicked($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_SetupGridComponent_0, i1.RenderType_SetupGridComponent)), i0.ɵdid(6, 638976, [[1, 4], ["docSetupGrid", 4]], 0, i2.SetupGridComponent, [i0.ChangeDetectorRef, i3.GridColumnDefinitionProcessorService, i4.TranslateService, i5.DialogService, i6.DataStoreService, i7.DMCCommon], { readOnly: [0, "readOnly"], actions: [1, "actions"], headerActions: [2, "headerActions"], noSelection: [3, "noSelection"], dataSource: [4, "dataSource"], setupGridComp: [5, "setupGridComp"], colDefConfig: [6, "colDefConfig"] }, { rowDoubleClicked: "rowDoubleClicked" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = true; var currVal_1 = _co.gridActions; var currVal_2 = _co.headerActions; var currVal_3 = true; var currVal_4 = _co; var currVal_5 = _co; var currVal_6 = _co.colDefConfig; _ck(_v, 6, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
export function View_LedgerEntrySearchResultsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-ledger-entry-search-results", [], null, null, null, View_LedgerEntrySearchResultsComponent_0, RenderType_LedgerEntrySearchResultsComponent)), i0.ɵdid(1, 245760, null, 0, i8.LedgerEntrySearchResultsComponent, [i9.ConfigLoader, i6.DataStoreService, i10.DataHandlerService, i11.AccountingRuleService, i12.ActivatedRoute, i13.FocusViewService, i6.DataStoreService, i12.ActivatedRoute, i12.Router, i14.EventManager, i5.DialogService, i7.DMCCommon, i15.UserJourneyManager, i16.SpinnerService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LedgerEntrySearchResultsComponentNgFactory = i0.ɵccf("tc-ledger-entry-search-results", i8.LedgerEntrySearchResultsComponent, View_LedgerEntrySearchResultsComponent_Host_0, {}, {}, []);
export { LedgerEntrySearchResultsComponentNgFactory as LedgerEntrySearchResultsComponentNgFactory };
