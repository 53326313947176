import { Component, Input, OnInit } from '@angular/core';
import { ServicePassenger } from '../../../models/reservation/service-passenger';
import { DataKey, DataStoreService } from '../../../services/util/framework/data-store.service';

@Component({
    selector: 'tc-auto-assigned-summary-wrapper',
    templateUrl: './auto-assigned-summary-wrapper.component.html'
})
export class AutoAssignedSummaryWrapperComponent implements OnInit {

    @Input() assignedResources: any;

    constructor(
        private dataStore: DataStoreService
    ) { }

    ngOnInit() {

    }

    public onClosePopup($event: any) {
        this.dataStore.set(DataKey.popupClose, true);
    }

}
