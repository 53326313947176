import * as tslib_1 from "tslib";
import { DMCQueryParamsService } from '../../util/framework/dmc-query-params.service';
import { RequestService } from '../../util/framework/request.service';
import * as i0 from "@angular/core";
import * as i1 from "../../util/framework/dmc-query-params.service";
import * as i2 from "../../util/framework/request.service";
var FileHandlingService = /** @class */ (function () {
    function FileHandlingService(queryParamsService, requestService, queryParamService) {
        this.queryParamsService = queryParamsService;
        this.requestService = requestService;
        this.queryParamService = queryParamService;
    }
    // uploadImage(fileToUpload: File, endPoint: string): Observable<any> {
    //     const formData: FormData = new FormData();
    //     formData.append('imageFile', fileToUpload);
    //     return this.httpClient
    //                .post(endPoint, formData, {
    //                    reportProgress: true,
    //                    responseType: 'json'
    //                });
    // }
    FileHandlingService.prototype.uploadImage = function (params, endPoint) {
        var e_1, _a;
        var formData = new FormData();
        var keys = this.queryParamService.getNotNullQueryParamsArray(params);
        if (keys) {
            try {
                for (var keys_1 = tslib_1.__values(keys), keys_1_1 = keys_1.next(); !keys_1_1.done; keys_1_1 = keys_1.next()) {
                    var k = keys_1_1.value;
                    if (params.hasOwnProperty(k)) {
                        formData.append(k, params[k]);
                    }
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (keys_1_1 && !keys_1_1.done && (_a = keys_1.return)) _a.call(keys_1);
                }
                finally { if (e_1) throw e_1.error; }
            }
        }
        return this.requestService
            .postForUrl(endPoint, formData, {
            reportProgress: true,
            responseType: 'json'
        });
    };
    FileHandlingService.ngInjectableDef = i0.defineInjectable({ factory: function FileHandlingService_Factory() { return new FileHandlingService(i0.inject(i1.DMCQueryParamsService), i0.inject(i2.RequestService), i0.inject(i1.DMCQueryParamsService)); }, token: FileHandlingService, providedIn: "root" });
    return FileHandlingService;
}());
export { FileHandlingService };
