export class AssignmentSummary {
    serviceItemIds : number[];
    assignmentId : number;
    categoryCode: string;
    supplierId: number;
    supplierName: string;
    resourceId: number;
    resourceName: string;
    tempAssignmentId : any;
    passengerCount : number;
    status : string;
    assignDate: string;
    resourceType: string;
    description: string;
    startTime: string;
    waitingEndTime: string;
    endTime: string;
    startLocationGroup: string;
    endLocationGroup: string;
    currency: string;
    allocatedCount: number;
    remainingCount: number;
    jobCost: number;
    unitPrice: number;
    estimatedPrice: number;
    unitIndex: number;
    widthNoOfUnits: number;
    widthMargin: number;
    selected: boolean;
    recommended: boolean;
    updated: boolean;
    tourCode: string;
    tourName: string;
    tobeSelected: boolean;
    parentAssignment: AssignmentSummary;
    childrenAssignments: AssignmentSummary[];
    startTimeObj: Date;
    endTimeObj: Date;
    waitingEndTimeObj: Date;
}
