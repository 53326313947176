import * as tslib_1 from "tslib";
var GenQueueUtil = /** @class */ (function () {
    function GenQueueUtil() {
    }
    GenQueueUtil.isTourItineraryRow = function (params) {
        return params && params.data && params.data.gridObjType && params.data.gridObjType === 'GenTourItinerary';
    };
    GenQueueUtil.isTourItemRow = function (params) {
        return params && params.data && this.isTourItemRow_(params.data);
    };
    GenQueueUtil.isTourItemRow_ = function (tourItem) {
        return tourItem.gridObjType && tourItem.gridObjType === 'GenTourItem';
    };
    GenQueueUtil.isAssignmentRow = function (params) {
        return params && params.data && params.data.gridObjType && params.data.gridObjType === 'ServiceAssignment';
    };
    GenQueueUtil.isInvalidAssignment = function (params) {
        var e_1, _a;
        var isAssignment = GenQueueUtil.isAssignmentRow(params);
        if (isAssignment) {
            var availableAdults = 0;
            var availableChildren = 0;
            var availableInfants = 0;
            var addedAdults = 0;
            var addedChildren = 0;
            var addedInfants = 0;
            var item = params.node.parent.data;
            if (item && GenQueueUtil.isTourItemRow(item)) {
                availableAdults = item.adults;
                availableChildren = item.children;
                availableInfants = item.infants;
                try {
                    for (var _b = tslib_1.__values(item.assignments), _c = _b.next(); !_c.done; _c = _b.next()) {
                        var assignment = _c.value;
                        if (!isNaN(assignment.adults)) {
                            addedAdults += assignment.adults;
                        }
                        if (!isNaN(assignment.children)) {
                            addedChildren += assignment.children;
                        }
                        if (!isNaN(assignment.infants)) {
                            addedInfants += assignment.infants;
                        }
                    }
                }
                catch (e_1_1) { e_1 = { error: e_1_1 }; }
                finally {
                    try {
                        if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                    }
                    finally { if (e_1) throw e_1.error; }
                }
            }
            return availableAdults < addedAdults || availableChildren < addedChildren || availableInfants < addedChildren;
        }
        else {
            return false;
        }
    };
    return GenQueueUtil;
}());
export { GenQueueUtil };
