import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange, TooltipPosition } from '@angular/material';
import { CurrencyFormat } from '@tc-core/model/it/codegen/ui/framework/currency-format';
import { SummaryCardData } from '@tc-core/model/it/codegen/ui/framework/summary-card-data';
import { TC } from '@tc-core/util/constants';
import { ConfigLoader } from '@tc-core/util/framework/config-loader.service';
import { TCO } from '../../../constants';
import { Season } from '../../../models/supplier/season';
import { TrsResourceCostSetup, TrsResourceCost } from '../../../models/supplier/trs-resource-cost-setup';

@Component({
    selector: 'tc-transport-cost-setup-edit-card',
    templateUrl: './transport-cost-setup-edit-card.component.html'
})
export class TransportCostSetupEditCardComponent implements OnInit, OnChanges {

    @Input() isEditable: boolean = false;
    @Input() isNew: boolean = false;
    @Input() enableCodeInput: boolean = false;
    @Input() seasonSetup: Season[];
    @Input() selectedResourceCostContent: SummaryCardData;
    @Input() daysOfWeek: any[];
    @Input() transferModes: any[] = [];
    @Input() vehicleModels: any[] = [];
    @Input() routes: any[] = [];
    @Input() selectedResourceCost: TrsResourceCostSetup;
    @Input() resourceCosts: TrsResourceCostSetup[] = [];
    @Input() isSelectable: boolean = true;

    @Output() selectedItem: any = new EventEmitter();
    @Output() deSelectedItem: any = new EventEmitter();
    @Output() validity: any = new EventEmitter();
    @Output() editClick: EventEmitter<any> = new EventEmitter();
    @Output() revertClick: EventEmitter<any> = new EventEmitter();
    @Output() saveClick: EventEmitter<any> = new EventEmitter();
    @Output() deleteClick: EventEmitter<any> = new EventEmitter();
    @Output() changeFormValidity: EventEmitter<boolean> = new EventEmitter();
    @Output() checkUniqueValues: EventEmitter<any> = new EventEmitter();

    costSetupDetailsForm: FormGroup;
    selectedDays: string = '';
    showMargin: boolean = false;
    public toolTipPosition: TooltipPosition = 'below';
    showSave = true;
    showEdit = false;
    isCostMissing = false;
    showRevert = false;
    CURRENCY_TYPE = TCO.AppData.DEF_CURRENCY_TYPE;
    currencyRegex = '^[0-9]*\.[0-9]{0,2}$';
    isInvalidCost: boolean = false;
    unEditedCostData: TrsResourceCostSetup;
    currencyFormat: CurrencyFormat;
    defaultCost = 0.00;
    vehicleModelList: any[] = [];

    constructor(private fb: FormBuilder, private configLoader: ConfigLoader) { }

    ngOnInit() {
        this.unEditedCostData = JSON.parse(JSON.stringify(this.selectedResourceCost));
        this.createForm();
        this.validateCost();
        this.getVehicleModels();
        this.costSetupDetailsForm.valueChanges.subscribe(
            (data) => {
                if (JSON.stringify(data) !== JSON.stringify({})) {
                    this.selectedResourceCost.transferMode = data.transferMode;
                    this.selectedResourceCost.vehicleModel = data.vehicleModel;
                    this.selectedResourceCost.route = data.route;
                    this.selectedResourceCost.tempSeasonId = data.season;
                    this.selectedResourceCost.dayKeys = this.selectedDays;
                    this.selectedResourceCost.unitFare = parseFloat(data.unitFare);
                    this.selectedResourceCost.adultFare = parseFloat(data.adultFare);
                    this.selectedResourceCost.childFare = parseFloat(data.childFare);
                    this.selectedResourceCost.teenFare = parseFloat(data.teenFare);
                    this.selectedResourceCost.infantFare = parseFloat(data.infantFare);
                    if (this.changeFormValidity) {
                        this.changeFormValidity.emit(this.costSetupDetailsForm.valid);
                        this.onFormChange();
                    }
                }
            }
        );
        this.currencyFormat = this.getCurrencyFormat();

        this.validateForm();
    }

    public validateForm() {
        Object.keys(this.costSetupDetailsForm.controls).forEach(field => {
            const control = this.costSetupDetailsForm.get(field);
            control.markAsTouched({onlySelf: true});
        });
    }

    private getCurrencyFormat(): CurrencyFormat {
        let currencyFormat;
        let currencySymbolPattern = this.configLoader.configurations.get(TC.CONF.CONF_SYSTEM).defaults.default_currency_symbol_pattern;
        currencyFormat = new CurrencyFormat(TCO.AppData.DEF_CURRENCY_TYPE, currencySymbolPattern);
        return currencyFormat;
    }

    getAllDays() {
        let allDays = '';
        this.daysOfWeek.forEach(data => {allDays = allDays.concat(data.key);});
        return allDays;
    }

    getVehicleModels() {
        this.vehicleModelList = this.vehicleModels.filter(
            value => value.transferMode === null || value.transferMode.code === this.selectedResourceCost.transferMode);
        /*if (this.vehicleModelList.filter(
            value => this.selectedResourceCost.vehicleModel && value.code !== this.selectedResourceCost.vehicleModel).length > 0) {
            this.selectedResourceCost.vehicleModel = null;
        }*/
        var found = false;
        for(var i = 0; i < this.vehicleModelList.length; i++) {
           if(this.selectedResourceCost.vehicleModel && this.vehicleModelList[i].code === this.selectedResourceCost.vehicleModel){
               found=true;
               break;
           }
        }
        if(!found){
            this.selectedResourceCost.vehicleModel = null;
        }
        this.validateUniqueValues();
    }

    validateUniqueValues() {
        if (this.selectedResourceCost.transferMode && this.selectedResourceCost.vehicleModel && this.selectedResourceCost.tempSeasonId &&
            this.selectedResourceCost.route && this.selectedResourceCost.dayKeys) {
            for (let i = 0; i < this.resourceCosts.length; i++) {
                if (this.resourceCosts[i].tempCostId !== this.selectedResourceCost.tempCostId &&
                    this.resourceCosts[i].transferMode === this.selectedResourceCost.transferMode &&
                    this.resourceCosts[i].vehicleModel === this.selectedResourceCost.vehicleModel &&
                    this.resourceCosts[i].route === this.selectedResourceCost.route &&
                    this.resourceCosts[i].tempSeasonId === this.selectedResourceCost.tempSeasonId &&
                    this.anythingInCommon(this.resourceCosts[i].dayKeys, this.selectedResourceCost.dayKeys)) {
                    this.isInvalidCost = true;
                    this.changeFormValidity.emit(!this.costSetupDetailsForm.valid);
                    break;
                } else {
                    this.isInvalidCost = false;
                    this.changeFormValidity.emit(this.costSetupDetailsForm.valid);
                }
            }
        } else {
            this.isInvalidCost = true;
            this.changeFormValidity.emit(!this.costSetupDetailsForm.valid);
        }
        this.onFormChange();
    }

    anythingInCommon(a, b) {
        if (b.length < a.length) {
            return this.anythingInCommon(b, a);
        }

        for (var i = 0, len = a.length; i < len; i++) {
            if (b.indexOf(a[i]) != -1) {
                return true;
            }
        }

        return false;
    }

    validateCost() {
        if (this.selectedResourceCost.unitFare) {
            this.costSetupDetailsForm.get('adultFare').disable();
            this.costSetupDetailsForm.get('childFare').disable();
            this.costSetupDetailsForm.get('teenFare').disable();
            this.costSetupDetailsForm.get('infantFare').disable();
            this.selectedResourceCost.adultFare = null;
            this.selectedResourceCost.childFare = null;
            this.selectedResourceCost.teenFare = null;
            this.selectedResourceCost.infantFare = null;
            this.isCostMissing = false;
        } else {
            this.costSetupDetailsForm.get('adultFare').enable();
            this.costSetupDetailsForm.get('childFare').enable();
            this.costSetupDetailsForm.get('teenFare').enable();
            this.costSetupDetailsForm.get('infantFare').enable();
        }
        if (this.selectedResourceCost.adultFare || this.selectedResourceCost.childFare ||
            this.selectedResourceCost.infantFare) {
            this.selectedResourceCost.unitFare = null;
            this.costSetupDetailsForm.get('unitFare').disable();
            this.isCostMissing = false;
        } else {
            this.costSetupDetailsForm.get('unitFare').enable();
        }
        if(!this.selectedResourceCost.adultFare && !this.selectedResourceCost.unitFare){
            this.isCostMissing = true;
        }
        this.onChangeFormCost();

    }

    onFormChange() {
        if (!this.isInvalidCost) {
            if (!this.costSetupDetailsForm.invalid && this.selectedResourceCost.dayKeys) {
                this.showSave = true;
                this.showEdit = false;
            } else {
                this.showSave = false;
                this.showEdit = false;
            }
        }
        else {
            this.showSave = false;
            this.showEdit = false;
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        for (let propName in changes) {
            if (propName === 'selectedResourceCost') {
                if (this.selectedResourceCost && this.costSetupDetailsForm) {
                    this.costSetupDetailsForm.patchValue({
                        transferMode: this.selectedResourceCost.transferMode,
                        vehicleModel: this.selectedResourceCost.vehicleModel,
                        route: this.selectedResourceCost.route,
                        season: this.selectedResourceCost.tempSeasonId,
                        unitFare: this.selectedResourceCost.unitFare.toFixed(2),
                        adultFare: this.selectedResourceCost.adultFare.toFixed(2),
                        childFare: this.selectedResourceCost.childFare.toFixed(2),
                        infantFare: this.selectedResourceCost.infantFare.toFixed(2),
                        teenFare: this.selectedResourceCost.teenFare.toFixed(2),
                        days: this.selectedResourceCost.dayKeys
                    });
                    this.changeFormValidity.emit(this.costSetupDetailsForm.valid);
                }
            }
        }
    }

    onChangeFormCost() {
        if (this.selectedResourceCost && this.costSetupDetailsForm) {
            this.costSetupDetailsForm.patchValue({
                unitFare: this.selectedResourceCost.unitFare ?
                    this.selectedResourceCost.unitFare.toFixed(2) :
                    this.defaultCost.toFixed(2),
                adultFare: this.selectedResourceCost.adultFare ?
                    this.selectedResourceCost.adultFare.toFixed(2) :
                    this.defaultCost.toFixed(2),
                childFare: this.selectedResourceCost.childFare ?
                    this.selectedResourceCost.childFare.toFixed(2) :
                    this.defaultCost.toFixed(2),
                infantFare: this.selectedResourceCost.infantFare ?
                    this.selectedResourceCost.infantFare.toFixed(2) :
                    this.defaultCost.toFixed(2),
                teenFare: this.selectedResourceCost.teenFare ?
                    this.selectedResourceCost.teenFare.toFixed(2) :
                    this.defaultCost.toFixed(2),
                days: this.selectedResourceCost.dayKeys
            });
            this.changeFormValidity.emit(this.costSetupDetailsForm.valid);
        }
    }

    private createForm() {
        this.costSetupDetailsForm = this.fb.group({
            transferMode: [
                this.selectedResourceCost.transferMode ? this.selectedResourceCost.transferMode : '',
                Validators.required
            ],
            vehicleModel: [
                this.selectedResourceCost.vehicleModel ? this.selectedResourceCost.vehicleModel : '',
                Validators.required
            ],
            route: [this.selectedResourceCost.route ? this.selectedResourceCost.route : '', Validators.required],
            season: [
                this.selectedResourceCost.tempSeasonId ? this.selectedResourceCost.tempSeasonId : '',
                Validators.required
            ],
            unitFare: [
                this.selectedResourceCost.unitFare ?
                    this.selectedResourceCost.unitFare.toFixed(2) :
                    this.defaultCost.toFixed(2),
                [Validators.pattern(this.currencyRegex), Validators.maxLength(12)]
            ],
            adultFare: [
                this.selectedResourceCost.adultFare ?
                    this.selectedResourceCost.adultFare.toFixed(2) :
                    this.defaultCost.toFixed(2),
                [Validators.pattern(this.currencyRegex), Validators.maxLength(12)]
            ],
            childFare: [
                this.selectedResourceCost.childFare ?
                    this.selectedResourceCost.childFare.toFixed(2) :
                    this.defaultCost.toFixed(2),
                [Validators.pattern(this.currencyRegex), Validators.maxLength(12)]
            ],
            infantFare: [
                this.selectedResourceCost.infantFare ?
                    this.selectedResourceCost.infantFare.toFixed(2) :
                    this.defaultCost.toFixed(2),
                [Validators.pattern(this.currencyRegex), Validators.maxLength(12)]
            ],
            teenFare: [
                this.selectedResourceCost.teenFare ?
                    this.selectedResourceCost.teenFare.toFixed(2) :
                    this.defaultCost.toFixed(2)
            ],
            days: [this.selectedResourceCost ? this.selectedResourceCost.dayKeys : '']
        });
        this.changeFormValidity.emit(this.costSetupDetailsForm.valid);
        this.selectedDays = this.selectedResourceCost.dayKeys ? this.selectedResourceCost.dayKeys : this.getAllDays();
    }

    toggleValue(day: string) {
        if (!this.selectedDays.includes(day)) {
            this.selectedDays = this.selectedDays.concat(day);
        } else {
            this.selectedDays = this.selectedDays.replace(day, '');
        }
        this.selectedResourceCost.dayKeys = this.selectedDays;
        this.validateUniqueValues();
        this.onFormChange();
    }

    onSaveClick(event) {
        if (this.selectedResourceCost.adultFare || this.selectedResourceCost.childFare ||
            this.selectedResourceCost.infantFare) {
            this.selectedResourceCost.unitFare = null;
        }
        else {
            this.selectedResourceCost.adultFare = null;
            this.selectedResourceCost.childFare = null;
            this.selectedResourceCost.teenFare = null;
            this.selectedResourceCost.infantFare = null;
        }
        this.saveClick.emit(this.selectedResourceCost);
    }

    onDeleteClick(event) {
        this.deleteClick.emit(this.selectedResourceCost.tempCostId);
        this.changeFormValidity.emit(this.costSetupDetailsForm.valid);
    }

    onEditClick(event) {
        this.editClick.emit(this.selectedResourceCost.tempSeasonId);
    }

    public getErrorMessageOfControl(group: FormGroup, field: string): string {
        if (field === 'transferMode') {
            return group.get('transferMode').hasError('required') ?
                field + ' required' : '';
        } else if (field === 'route') {
            return group.get('route').hasError('required') ?
                field + ' required' : '';
        } else if (field === 'vehicleModel') {
            return group.get('vehicleModel').hasError('required') ?
                field + ' required' : '';
        } else if (field === 'season') {
            return group.get('season').hasError('required') ?
                field + ' required' : '';
        } else if (field === 'Unit Cost') {
            return group.get('unitFare').hasError('pattern') ?
                field + ' invalid' : '';
        } else if (field === 'Cost Per Adult') {
            return group.get('adultFare').hasError('pattern') ?
                field + ' invalid' : '';
        } else if (field === 'Cost Per Child') {
            return group.get('childFare').hasError('pattern') ?
                field + ' invalid' : '';
        } else if (field === 'Cost Per Infant') {
            return group.get('infantFare').hasError('pattern') ?
                field + ' invalid' : '';
        }

    }

    onRevertClick() {
        if (this.selectedResourceCost && this.costSetupDetailsForm) {
            this.costSetupDetailsForm.patchValue({
                transferMode: this.unEditedCostData.transferMode,
                vehicleModel: this.unEditedCostData.vehicleModel,
                route: this.unEditedCostData.route,
                season: this.unEditedCostData.tempSeasonId
            });
            this.selectedDays = this.unEditedCostData.dayKeys;
        }
        if (this.selectedResourceCost.adultFare) {
            this.selectedResourceCost.unitFare = null;
        }
        else {
            this.selectedResourceCost.adultFare = null;
            this.selectedResourceCost.childFare = null;
            this.selectedResourceCost.teenFare = null;
            this.selectedResourceCost.infantFare = null;
        }
        this.selectedResourceCost = this.unEditedCostData;
        this.ngOnInit();
        this.revertClick.emit(this.selectedResourceCost);
    }
}
