import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatPaginatorModule, MatSortModule, MatTableModule } from '@angular/material';
import { SecurityModule } from '@tc/security';
import { TranslateModule } from '@ngx-translate/core';
import { SupplierAttributesCardComponent } from './supplier-attributes-card.component';

@NgModule({
    imports: [
        TranslateModule.forChild(),
        CommonModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule
    ],
    exports: [
        SupplierAttributesCardComponent
    ],
    declarations: [
        SupplierAttributesCardComponent
    ],
    providers: []
})
export class SupplierAttributesCardModule {
}
