import { Component, Input, OnInit } from '@angular/core';
import { ActionsComponent } from '../../widgets/framework/ag-grid-custom-components/renderers/actions/actions.component';
import { GridToolTipComponent } from '../../widgets/framework/ag-grid-custom-components/renderers/tooltip/grid-tool-tip.component';
import { ServiceItem } from '../../models/reservation/service-item';
import { ServiceNote } from '../../models/reservation/service-note';
import { DataKey, DataStoreService } from '../../services/util/framework/data-store.service';
import {TranslateService} from '@ngx-translate/core';
import {ICellRendererParams} from 'ag-grid-community';

export class BookingNoteGroup {
    bookingReferenceId: string;
    serviceItem: ServiceItem | any;
    notes: ServiceNote[] = [];
}

@Component({
    selector: 'tc-notes-editor',
    templateUrl: './notes-editor.component.html'
})
export class NotesEditorComponent implements OnInit {
    constructor(
        private dataStore: DataStoreService,
        private translateService: TranslateService
    ) {
        this.translateService.onLangChange.subscribe(() => {
            this.gridApi.refreshHeader();
        });
    }

    @Input() bookingNoteGroups: BookingNoteGroup[] = [];
    @Input() assignmentNotes: any[] = [];

    gridApi;
    columnDefs = [
        {
            headerName: 'Note',
            field: 'text',
            sortable: false,
            resizable: true,
            editable: this.isEditable(),
            tooltipField: 'text',
            tooltipComponent: 'tooltipComponent',
            tooltipComponentParams: {
                tooltipFunc: (data) => {
                    return data.text;
                }
            }
        },
        {
            headerName: 'Actions',
            field: 'actions',
            width: 40,
            cellRendererFramework: ActionsComponent,
            headerClass: 'actions-header',
            cellRendererParams: {
                actions: [
                    {
                        icon: 'delete',
                        action: this.onDeleteNote.bind(this)
                    }
                ]
            },
            resizable: true,
            pinned: 'right',
            cellClass: ['tc-ag-grid-cell--read-only', 'actions-column']
        }
    ];

    bookingNotesColumnDefs = [
        {
            headerName: 'Note',
            field: 'note',
            sortable: true,
            editable: false,
            resizable: true,
            tooltipField: 'note',
            tooltipComponent: 'tooltipComponent',
            tooltipComponentParams: {
                tooltipFunc: (data) => {
                    return data.note ? data.note.replaceAll('• ', '<br>• ') : '';
                },
                width: '300px'
            }
        },
        {
            headerName: 'Type',
            field: 'type',
            minWidth: 60,
            sortable: true,
            editable: false,
            resizable: true
        }
    ];
    public frameworkComponents: any = {
        tooltipComponent: GridToolTipComponent
    };

    defaultColDef = {
        resizable: true,
        headerValueGetter: (parameters: ICellRendererParams): string => {
            const headerIdentifier = parameters.colDef.headerName;
            if (headerIdentifier) {
                return this.translateService.instant(headerIdentifier);
            }
            return '';
        }
    };

    ngOnInit() {
    }

    public onSave() {
        this.dataStore.set(DataKey.popupClose, true);
    }

    public onClose($event: MouseEvent) {
        this.dataStore.set(DataKey.popupClose, false);
    }

    public isSaveButtonEnable() {
        return true;
    }

    public onGridReadyAssignmentNotes(params: any) {
        this.gridApi = params.api;
        this.gridApi.sizeColumnsToFit();
    }

    public onDeleteNote(params: any) {
        if (params && params.data && this.assignmentNotes.indexOf(params.data) !== -1) {
            this.assignmentNotes.splice(this.assignmentNotes.indexOf(params.data), 1);
            // if (!this.assignmentNotes.length) {
            //     this.assignmentNotes.push(this.getEmptyNote());
            // }
            this.gridApi.setRowData(this.assignmentNotes);
            this.gridApi.setDomLayout('autoHeight');
        }
    }

    private getEmptyNote() {
        return {};
    }

    public onAddNote() {
        this.assignmentNotes.splice(
            0,
            0,
            this.getEmptyNote()
        );
        this.gridApi.setRowData(this.assignmentNotes);
    }

    public isEditable() {
        return true;
    }
}
