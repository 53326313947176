import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material';
import { TableDataSource, TableService } from '../../../services/util/ui/table.service';
import { DataGridColumn } from '../../../models/ui/data-grid-column';

@Component({
    selector: 'tc-data-grid',
    templateUrl: './data-grid.component.html',
    styleUrls: ['./data-grid.component.scss']
})
export class DataGridComponent implements OnInit, OnChanges {

    @Input() displayedColumns: any[];
    @Input() data: any[];
    @Input() clickedRow: number;
    @Input() unsavedRows: number;
    @Input() showEditIcon = false;
    @Input() showSaveIcon = false;
    @Input() showDeleteIcon = false;
    @Input() showRevertIcon = false;
    @Input() readOnly = false;
    @Input() columnsArray: any[];
    @Input() columnNamesArray: any[];
    @Input() dataSource: TableDataSource<any>;
    @Input() savedDataSource: TableDataSource<any>;
    @Output() addRow: EventEmitter<any> = new EventEmitter();
    @Output() deleteRow: EventEmitter<any> = new EventEmitter();
    @Output() deleteRowID: EventEmitter<any> = new EventEmitter();
    @Output() editClick: EventEmitter<any> = new EventEmitter();
    @Output() revertClick: EventEmitter<any> = new EventEmitter();
    @Output() saveClick: EventEmitter<any> = new EventEmitter();


    @ViewChild(MatPaginator) paginator: MatPaginator;

    defaultPageResultCount = 5;

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges) {
        // for (let propName in changes) {
        //     if (propName === 'dataSource') {
        //         console.log(this.dataSource.totalData);
        //         this.clickedRow = this.dataSource.totalData.length - 1;
        //     }
        // }
    }

    onAddRow(event) {
        this.addRow.emit(event);
    }

    onSaveClick(event) {
        this.clickedRow = null;
        this.saveClick.emit(event);
    }

    onDeleteClick(event) {
        this.clickedRow = null;
        this.dataSource.totalData.splice(event, 1);
        console.log(this.dataSource.totalData);
        this.deleteRow.emit(this.dataSource.totalData);
    }

    onEditClick(event) {
        this.clickedRow = event;
        this.editClick.emit(event);
    }

    onClick(event) {
        this.deleteRowID.emit(event);
    }

    onRevertClick(event) {
        this.clickedRow = null;
        // this.dataSource.totalData.splice(event, 1, this.savedDataSource.totalData[event] );
        this.revertClick.emit(event);
    }

    checkUnsaved(rowId) {
        return (Math.pow(2, rowId) & this.unsavedRows) === Math.pow(2, rowId);
    }

    checkNewlyAdded(rowId) {
        return this.savedDataSource ? rowId > this.savedDataSource.totalData.length - 1 : false;
    }

    onInputChanges() {
        this.savedDataSource = this.dataSource;
    }
}
