import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgGridModule } from 'ag-grid-angular';
import { AutoAssignedSummaryComponent } from './auto-assigned-summary.component';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
    imports: [
        CommonModule,
        AgGridModule,
        TranslateModule.forChild()
    ],
    exports: [
        AutoAssignedSummaryComponent
    ],
    declarations: [AutoAssignedSummaryComponent]
})
export class AutoAssignedSummaryModule {}
