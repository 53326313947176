<!--<div class="tc-item-wrapper">-->
    <!--<div class="tc-item-header tc-flex-box">-->
        <!--<div class="tc-flex-item tc-item-header__primary">Contact Details</div>-->
        <!--<div class="tc-flex-item tc-item-header__actions tc-icon-block">-->
        <!--</div>-->
    <!--</div>-->
<!--</div>-->
<form [formGroup]="contactDetailsForm">
    <div class="container-fluid direct-client-personal-info-edit-card tc-card-wrapper">
        <div class="row">
            <div class="col-md-12 col-lg-12">
                <div class="tc-flex-box">
                    <div class="tc-flex-item tc-item-circle">
                        <div class="icon-box">
                            <i class="material-icons">account_box</i>
                        </div>
                    </div>
                    <div class="tc-flex-item tc-right-cage">
                        <div class="row">
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                <div class="tc-select-edit dcpiec-select-title">
                                    <mat-form-field>
                                        <mat-select
                                                name="category"
                                                formControlName="category"
                                                [placeholder]="'Category'"
                                                required>
                                            <mat-option
                                                    *ngFor="let cat of categories"
                                                    [value]="cat.code">
                                                {{cat.name}}
                                            </mat-option>
                                        </mat-select>
                                        <mat-error
                                                class="mat-error"
                                                role="alert">{{getErrorMessage('category')}}</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                <div class="tc-select-edit dcpiec-select-title">
                                    <mat-form-field>
                                        <mat-select
                                                formControlName="title"
                                                [placeholder]="'Title'"
                                                required
                                        >
                                            <mat-option
                                                    *ngFor="let t of titles"
                                                    [value]="t.name">
                                                {{t.name}}
                                            </mat-option>
                                        </mat-select>
                                        <mat-error
                                                class="mat-error"
                                                role="alert">{{getErrorMessage('title')}}</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                <div class="tc-input-edit dcpiec-input-fname">
                                    <mat-form-field>
                                        <input
                                                matInput
                                                formControlName="firstName"
                                                [placeholder]="'First Name'"
                                                required
                                        >
                                        <mat-error
                                                class="mat-error"
                                                role="alert">{{getErrorMessage('firstName')}}</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                <div class="tc-input-edit dcpiec-input-lname">
                                    <mat-form-field>
                                        <input
                                                matInput
                                                formControlName="lastName"
                                                [placeholder]="'Last Name'"
                                                required
                                        >
                                        <mat-error>{{getErrorMessage('lastName')}}</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                <div class="tc-input-edit dcpiec-input-lname">
                                    <mat-form-field>
                                        <input
                                                matInput
                                                formControlName="email"
                                                [placeholder]="'Email'"
                                                required>
                                        <mat-error>{{getErrorMessage('email')}}
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">

                                <div class="tc-input-edit dcpiec-input-lname">
                                    <mat-form-field>
                                        <input
                                                matInput
                                                formControlName="alternateEmail"
                                                [placeholder]="'Alternate Email'">
                                        <mat-error>{{getErrorMessage('alternateEmail')}}
                                        </mat-error>
                                    </mat-form-field>
                                </div>

                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                <div class="tc-select-edit dcpiec-select-title">
                                    <mat-form-field>
                                        <input
                                                matInput
                                                formControlName="telephoneNumber"
                                                [placeholder]="'Telephone Number'"
                                                maxlength="15"
                                                required>
                                        <mat-error>{{getErrorMessage('telephoneNumber')}}
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                <div class="tc-select-edit dcpiec-select-title">
                                    <mat-form-field>
                                        <input
                                                matInput
                                                formControlName="alternateTelephoneNumber"
                                                [placeholder]="'Alternate Telephone Number'"
                                                maxlength="15">
                                        <mat-error>{{getErrorMessage('alternateTelephoneNumber')}}
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                <div class="tc-input-edit dcpiec-input-country">
                                    <mat-form-field>
                                        <input
                                                matInput
                                                #countryInput
                                                formControlName="country"
                                                [placeholder]="'Country'"
                                                (focus)="onCountryFocus($event)"
                                                required
                                                [matAutocomplete]="countryAutoComplete">
                                        <mat-error
                                                *ngIf="contactDetailsForm.controls['country'].hasError('required')
                          ||contactDetailsForm.controls['country'].hasError('invalidString')">
                                            {{getErrorMessage('country')}}
                                        </mat-error>
                                    </mat-form-field>
                                    <mat-autocomplete
                                        #countryAutoComplete="matAutocomplete"
                                        [displayWith]="displayCountryFn">
                                        <mat-option
                                                *ngFor="let country of countryList"
                                                (onSelectionChange)="onSelectCountry(country)"
                                                [value]="country">
                                            {{ country?.name }}
                                        </mat-option>
                                    </mat-autocomplete>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                <div class="tc-input-edit dcpiec-input-country">

                                    <mat-form-field>
                                        <input
                                                matInput
                                                #stateInput
                                                formControlName="state"
                                                [matAutocomplete]="stateAutoComplete"
                                                (focus)="onStateFocus($event)"
                                                [placeholder]="'State'">
                                    </mat-form-field>
                                    <mat-autocomplete
                                        #stateAutoComplete="matAutocomplete"
                                        [displayWith]="displayStateFn">
                                        <mat-option
                                                *ngFor="let state of stateList"
                                                [value]="state"
                                                (onSelectionChange)="onSelectState(state)">
                                            {{ state?.name }}
                                        </mat-option>
                                    </mat-autocomplete>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                <div class="tc-input-edit dcpiec-input-country">
                                    <mat-form-field>
                                        <input
                                                matInput
                                                #cityInput
                                                formControlName="city"
                                                required
                                                (focus)="onCityFocus($event)"
                                                [matAutocomplete]="cityAutoComplete"
                                                [placeholder]="'City'">
                                        <mat-error
                                                *ngIf="contactDetailsForm.controls['city'].hasError('required')
                          ||contactDetailsForm.controls['city'].hasError('invalidString')">
                                            {{getErrorMessage('city')}}
                                        </mat-error>
                                    </mat-form-field>
                                    <mat-autocomplete
                                        #cityAutoComplete="matAutocomplete"
                                        [displayWith]="displayCityFn">
                                        <mat-option
                                                *ngFor="let city of cityList"
                                                [value]="city"
                                                (onSelectionChange)="onSelectCity(city)">
                                            {{ city?.name }}
                                        </mat-option>
                                    </mat-autocomplete>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                <div class="tc-input-edit dcpiec-input-postCode">
                                    <mat-form-field>
                                        <input
                                                matInput
                                                id="DIR_PER_INFO_EDIT_POSTCODE"
                                                #postCodeInput
                                                formControlName="postCode"
                                                [placeholder]="'Post Code'">
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div class="row">
<!--                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">-->
<!--                                <div class="tc-select-edit dcpiec-select-title">-->
<!--                                    <mat-form-field>-->
<!--                                        <input-->
<!--                                                matInput-->
<!--                                                formControlName="mobileNumber"-->
<!--                                                [placeholder]="'Mobile Number'"-->
<!--                                                maxlength="15">-->
<!--                                        <mat-error>{{getErrorMessage('mobileNumber')}}-->
<!--                                        </mat-error>-->
<!--                                    </mat-form-field>-->
<!--                                </div>-->
<!--                            </div>-->
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                <div class="tc-input-edit dcpiec-input-licenseNumber">
                                    <mat-form-field>
                                        <input
                                                matInput
                                                formControlName="faxNumber"
                                                [placeholder]="'Fax Number'"
                                                maxlength="15">
                                        <mat-error>{{getErrorMessage('faxNumber')}}
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                <div class="tc-input-edit dcpiec-input-streetsddr">
                                    <mat-form-field>
                                        <input
                                                matInput
                                                id="DIR_PER_INFO_EDIT_STREETADDRESS"
                                                #streetAddressInput
                                                formControlName="streetAddress"
                                                [placeholder]="'Street Address'">
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
