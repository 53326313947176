<div class="ecshc-card">
  <div class="ecshc-card__wrapper">
    <div class = "ecshc-card__icon">
      <tc-resource
              *ngIf="icon?.value"
              [key]="icon?.value?.key"
              [value]="icon?.value?.value"
              [style]="icon?.value?.style"
              [iconType]="icon?.value?.iconType">
      </tc-resource>
    </div>

    <div class = "ecshc-card__content ecshc-content-wrapper">
      <h5 class = "ecshc-content-wrapper__heading" id =  "bm-note-type-header">{{headerText}}</h5>
      <small class="ecshc-content-wrapper__secondary-heading" id =  "bm-note-type-sec-text">{{secondaryHeaderText}}</small>
    </div>

    <div class = "tc-icon-block ecshc-card__actions">
      <span class = "tc-icon-block__icon tc-icon-block__icon--active" *ngIf="enableItemEdit === true">
        <i
                class="material-icons mobile"
                [matTooltip]="editToolTip"
                (click)="editItemClick($event,dataEditKey)">
          edit
        </i>
      </span>
      <span class = "tc-icon-block__icon tc-icon-block__icon--active" *ngIf="enableItemDelete === true">
        <i
                class="material-icons mobile"
                [matTooltip]="deleteToolTip"
                (click)="removeItemClick($event,dataRemoveKey)">
          delete
        </i>
      </span >

      <tc-kebab-menu
              *ngIf="showKebab"
              [items]="kebabMenu">
      </tc-kebab-menu>
    </div>
  </div>
</div>

<mat-progress-bar
        *ngIf="showProgress === true" mode="indeterminate"
        class="tc-progress--bar ecshc-progress-bar"
        value="30">
</mat-progress-bar>
