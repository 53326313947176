import { AfterViewInit, Component, TemplateRef, ViewChild } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ICellEditorParams } from 'ag-grid-community';
import { NgxMaterialTimepickerComponent } from 'ngx-material-timepicker';

@Component({
    selector: 'tc-time-editor',
    templateUrl: './time-editor.component.html'
})
export class TimeEditorComponent implements ICellEditorAngularComp, AfterViewInit {

    params: any;
    isError = false;
    disabled: boolean = true;
    row: any;
    column: string;
    options: any[] = [];
    rowData: any;
    rawValue: any;
    selectedTime: any;

    @ViewChild('picker') picker: NgxMaterialTimepickerComponent;

    constructor() { }

    public agInit(params: ICellEditorParams): void {
        this.params = params;
        this.options = params['options'];
        this.disabled = !params['colDef']['editable'];
        this.rowData = params['data'];
        this.rawValue = params['value'];
    }

    public ngAfterViewInit(): void {
    }

    public focusIn(): void {
        this.picker.open();
    }

    public focusOut(): void {
    }

    public getFrameworkComponentInstance(): any {
    }

    public getValue(): any {
        if (this.selectedTime) {
            return this.selectedTime;
        } else {
            return null;
        }
    }

    public isCancelAfterEnd(): boolean {
        return false;
    }

    public isCancelBeforeStart(): boolean {
        return false;
    }

    public isPopup(): boolean {
        return false;
    }
}
