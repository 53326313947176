import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PipesModule } from '@tc/pipes';
import { ResultContentCardModule } from '../result-content-card/result-content-card.module';
import { ResultContentCardResourceAssignComponent } from './result-content-card-resource-assign.component';
import { ResourceModule } from '@tc/resource';

@NgModule({
    imports: [
        CommonModule,
        ResourceModule,
        ResultContentCardModule,
        PipesModule
    ],
    exports: [
        ResultContentCardResourceAssignComponent
    ],
    declarations: [ResultContentCardResourceAssignComponent]
})
export class ResultContentCardResourceAssignModule {}
