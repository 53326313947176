import { ResourceType } from './assignment';
import { AssignmentHistory } from './assignment-history';
import { AssignmentNote } from './assignment-note';
import { AssignmentSaveOperation } from './assignment-save-operation';
import { AssignmentStatus } from './assignment-status';

export class ServiceAssignment {
    serviceItemId: number;
    assignmentId: number;
    tempAssignmentId: any;
    tourCode: string;
    categoryCode: string;
    categoryName: string;
    typeCode: string;
    jobDate: string;
    jobPeriod: string;
    jobNo: number;
    supplierId: number;
    supplierCode: string;
    supplierName: string;
    resourceId: number;
    resourceName: string;
    adults: number;
    teens: number;
    children: number;
    infants: number;
    unitPrice: number;
    adultPrice: number;
    teenPrice: number;
    childPrice: number;
    infantPrice: number;
    estimatedCost: number;
    jobCost: number;
    currency: string;
    startLocationGroup: string;
    endLocationGroup: string;
    startTime: string;
    endTime: string;
    waitingEndTime: string;
    sharedAssign: boolean;
    assignStatus: string;
    serviceItemIdList: any[];
    updated: boolean;
    assignmentSaveOperation: string;
    // for set supplier cost in generic queue
    supplierItem: string;
    supplierItemName: string;

    parentAssignment: ServiceAssignment;
    childrenAssignments: ServiceAssignment[];

    assignmentNotes: AssignmentNote[];

    assignmentHistory: AssignmentHistory[];
    rejectedSupplierIdList: number[];
    rejectedResourceIdList: number[];

    modifiedOn: Date;
    exportedOn: Date;

    /**
     * identifier for data grid rendering process
     */
    gridObjType: string;

    // /**
    //  * objects should be attached by searching with id.
    //  * added these here for helping front end implementations
    //  */
    // supplier:SupplierSummaryDepricated;
    // resource:ResourceSummary;
    isInvalid: boolean;

    constructor() {
        this.gridObjType = 'ServiceAssignment';
    }

    isAssigned() {
        return this.supplierId > 0;
    }

    static isAssigned(assignment: ServiceAssignment) {
        if(assignment) {
            return assignment && assignment.supplierId > 0;
        }
        return false;
    }

    static isTrsAssign(assignment: ServiceAssignment) {
        if(assignment) {
            return assignment.categoryCode != null &&
                (assignment.categoryCode == ResourceType.vehicle || assignment.categoryCode == ResourceType.supplier ||
                    assignment.categoryCode == ResourceType.driver);
        }
        return false;
    }

    static isRestrictedEdit(assignment: ServiceAssignment){
        if (assignment && assignment.assignStatus && (assignment.assignStatus !== AssignmentStatus.PENDING && assignment.assignStatus !==
            AssignmentStatus.SUPPLIER_REJECTED && assignment.assignStatus !== AssignmentStatus.BOOKING_UPDATE && assignment.assignStatus !== AssignmentStatus.REQUEST_EXPIRED)) {
            return true;
        }
        return false;
    }
}
