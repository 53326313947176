
<div class="trade-client-detail-view">
  <div
          class="detail-group">
    <div class=" detail-group__heading  tc-results-header">
      <div class="tc-item-wrapper">
        <div class="tc-item-header tc-flex-box">
          <div class="tc-flex-item tc-item-header__primary">{{'Summary' | translate}}</div>
        </div>
      </div>
    </div>
    <div class="detail-group__content">
      <div class="container-fluid tc-item-wrapper tcpi-card">
        <div class="tc-item-wrapper__body">
          <div class="row">
            <div class="col-md-12 rs-full-width">
              <div class="tc-flex-box">
                <div class="tc-flex-item icon-cage input-icon">
                  <div class="card-icon--small icn-color-primary"><i class="material-icons">book</i>
                  </div>
                </div>
                <div class="tc-flex-item input-cage ">
                  <div class="col-xs-12 col-sm-12  col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                    <div class="tc-content--wrap tc-input-view">
                      <div
                              class="tc-caption-txt txt-light">{{"Apply From" | translate}}
                      </div>
                      <div class="tc-body1--txt">{{leaveSummary?.startDate ? leaveSummary?.startDate : EMPTY_CHAR}}</div>
                    </div>
                  </div>
                  <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                    <div class="tc-content--wrap tc-input-view">
                      <div
                              class="tc-caption-txt txt-light">{{"Apply To" | translate}}
                      </div>
                      <div class="tc-body1--txt">{{leaveSummary?.endDate ? leaveSummary?.endDate : EMPTY_CHAR}}</div>
                    </div>
                  </div>
                  <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                    <div class="tc-content--wrap tc-input-view">
                      <div class="tc-caption-txt txt-light">{{"Leave Type" | translate}}
                      </div>
                      <div class="tc-body1--txt ">{{leaveSummary?.leaveType ? leaveSummary?.leaveType : EMPTY_CHAR}}
                      </div>
                    </div>
                  </div>
                  <div class="col-xs-12 col-sm-6  col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                    <div class="tc-content--wrap tc-input-view">
                      <div
                              class="tc-caption-txt txt-light">{{"Note" | translate}}
                      </div>
                      <div class="tc-body1--txt">{{leaveSummary?.note ? leaveSummary?.note : EMPTY_CHAR}}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 rs-full-width">
              <div class="tc-flex-box">
                <div class="tc-flex-item icon-cage ">
                  <div class="card-icon--small icn-color-primary"></div>
                </div>
                <div class="tc-flex-item input-cage ">
                  <div class="col-xs-12 col-sm-6  col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                  </div>
                  <div class="col-xs-12 col-sm-12  col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                  </div>
                  <div class="col-xs-12 col-sm-12  col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                  </div>
                  <div class="col-xs-12 col-sm-12  col-md-3 col-lg-3 remove-bootstrap-padding-mobile last-column">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
