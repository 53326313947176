import * as tslib_1 from "tslib";
import { KeyControlAndAbstractSearchCriteria } from '../../criteria/key-control-and-abstract-search-criteria';
var SupplierAllocationSearchCriteria = /** @class */ (function (_super) {
    tslib_1.__extends(SupplierAllocationSearchCriteria, _super);
    function SupplierAllocationSearchCriteria() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.supplierCode = '';
        _this.supplierName = '';
        _this.resourceName = '';
        _this.serviceType = '';
        _this.vehicleModel = '';
        _this.route = '';
        _this.transferMode = '';
        _this.profileType = '';
        _this.serviceDateStr = '';
        _this.activeStatus = '';
        _this.boardBasis = '';
        _this.occupancy = '';
        return _this;
    }
    return SupplierAllocationSearchCriteria;
}(KeyControlAndAbstractSearchCriteria));
export { SupplierAllocationSearchCriteria };
