import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {GenericSearchCriteria} from '../../models/criteria/generic-search-criteria';
import {GenericTypePathname} from '../../models/helper/generic-type-pathname';
import {Subscription} from 'rxjs';
import {ChipInputComponent} from '@tc/chip-input';
import {ActivatedRoute} from '@angular/router';
import {ConfigLoader} from '@tc-core/util/framework/config-loader.service';
import {UserJourneyManager} from '@tc-core/util/framework/user-journey-manager.service';
import {DataKey, DataStoreService} from '../../services/util/framework/data-store.service';
import {ActionHandlerService} from '../../services/util/framework/action-handler-service.service';
import {GenericResourceService} from '../../services/backend-consumers/supplier-service/generic-resource.service';
import {EventManager} from '@tc-core/util/framework/event-manager.service';
import {ChipHandlerService} from '../../services/helpers/chip-handler.service';
import {DMCQueryParamsService} from '../../services/util/framework/dmc-query-params.service';
import {CommonHelper} from '@tc-core/util/helpers/common-helper.service';
import {SpinnerService} from '@tc-core/util/ui/spinner.service';
import {RootService} from '../../services/util/core-services/root.service';
import {TCO} from '../../constants';
import {Journey} from '@tc-core/model/it/codegen/ui/framework/journey';
import {TC} from '@tc-core/util/constants';
import {SortDirection} from '../../models/helper/sort-direction';
import {TcApiError} from '@tc-core/model/it/codegen/tbx/ext/errors/tc-api-error';
import {TcHttpError} from '@tc-core/model/it/codegen/tbx/ext/errors/tc-http-error';
import {GenericResourceSearchType} from '../generic-search-criteria/generic-search-criteria.component';
import {RoomSearchCriteria} from '../../models/criteria/room-search-criteria';
import {ChipInputService} from '@tc/chip-input/chip-input.service';
import {VehicleSearchCriteria} from '../../models/criteria/vehicle-search-criteria';
import {TransportResourceService} from '../../services/backend-consumers/supplier-service/transport-resource.service';
import {TrsReservationManagementService} from '../../services/backend-consumers/reservation-management-services/trs-reservation-management.service';
import {ResourceType} from '../../models/reservation/assignment';
import {VehicleSearchType} from '../vehicle-search-criteria/vehicle-search-criteria.component';

export enum RoomResourceSearchType {
    Normal,
    ResourceAssign,
    GenResourceAssign
}

@Component({
    selector: 'tc-room-search-criteria',
    templateUrl: './room-search-criteria.component.html',
    providers: [
        ChipHandlerService,
        {provide: 'ChipHandlerService', useExisting: ChipHandlerService},
        ChipInputService
    ]
})
export class RoomSearchCriteriaComponent implements OnInit, OnDestroy {
    @Input() isInsideForm: boolean = false;
    @Input() vehicleSearchCriteria: VehicleSearchCriteria;
    @Input() searchCriteriaChipConfig: string;
    @Input() isSearchBarStyleNotAvailable: boolean = false;
    @Input() searchType: VehicleSearchType = VehicleSearchType.Normal;

    @Input() placeHolderText = 'Search Vehicles';

    @Output() searchTrigger: EventEmitter<boolean> = new EventEmitter();
    @Output() searchCriteria: EventEmitter<any> = new EventEmitter();

    tPagination: any;

    journey: string = null;

    isChipsInit = false;
    availableChips: any[] = [];
    persistentChipsArr = [];
    persistentQParams: string[] = [];
    persistentQParamMap = new Map<string, string>();

    private relatedChipsObsv: Subscription = new Subscription();
    modifiedChip;

    isDataLoaded: boolean = true;
    isTriggerSearch: boolean = true;
    displayChipsArr = [];
    isFocus: boolean = false;
    isChipListDisplay = false;


    private routerChangeSubscription: Subscription;
    private selectedChipsObsv: Subscription = new Subscription();
    private selectedFieldsObsv: Subscription = new Subscription();
    private chipValueUpdateSub: Subscription = new Subscription();
    private criteriaUpdateSubscription: Subscription = new Subscription();
    private deletedChipObsv: Subscription = new Subscription();
    private selectedSupplierObsv: Subscription = new Subscription();
    private selectedChips = [];

    private chipInputService;
    @ViewChild('vehicle_search_chip_input') vehicleSearchChipInput: ChipInputComponent;
    private chipHandlerSubject: Subscription = new Subscription();

    constructor(
        private activatedRoute: ActivatedRoute,
        private configLoader: ConfigLoader,
        private userJourneyManager: UserJourneyManager,
        private dataStoreService: DataStoreService,
        private actionHandlerService: ActionHandlerService,
        private transportResourceService: TransportResourceService,
        private trsReservationManagementService: TrsReservationManagementService,
        private em: EventManager,
        private chipHandler: ChipHandlerService,
        private queryParamService: DMCQueryParamsService,
        private commonHelper: CommonHelper,
        private spinnerService: SpinnerService,
        private rootService: RootService
    ) { }

    ngOnInit() {

        this.chipInputService = this.vehicleSearchChipInput.criteriaInputService;

        if (this.searchType === VehicleSearchType.ResourceAssign) {
            this.tPagination = this.configLoader.configurations.get(TCO.CONF.CONF_PAGINATION_CONFIG)['RESOURCE_ALLOCATION_SEARCH'];
            this.selectedSupplierObserver();
            this.selectedSupplierFromDriverObserver();
        } else if (this.searchType === VehicleSearchType.Normal) {
            this.tPagination = this.configLoader.configurations.get(TCO.CONF.CONF_PAGINATION_CONFIG)['VEHICLE_SEARCH'];
        }

        //clear data store
        this.dataStoreService.set(DataKey.vehicleSearchResultsService, null, true);
        this.dataStoreService.set(DataKey.resourceAssignVehicleSearchResults, null, true);

        this.handleQuickActions();

        this.userJourneyManager.journey.subscribe((journey: Journey) => {
            if (journey && JSON.stringify(journey) !== JSON.stringify({})) {
                this.journey = journey.id;
            }
        });

        // resetting the view
        this.routerChangeSubscription = this.userJourneyManager.routeChanges.subscribe(e => {
            if (e) {
                this.chipInputService.fullReset(); // fully reset chips
                this.chipHandler.chipsArr = []; // reset chips array loaded from config
                // check journey begins from quick book
                if (!this.isInsideForm) {
                    this.getQueryParams();
                    this.initSearchCriteriaChips();
                }

                console.log('displayChipsArr', this.displayChipsArr);
                this.resetQueryParams();
            }
        });

        if (!this.isInsideForm) {
            this.getQueryParams();
            if (!this.isChipsInit) {
                this.initSearchCriteriaChips(); // load everything from start
            }

            if (this.searchType === VehicleSearchType.Normal) {
                this.vehicleSearchCriteria = new VehicleSearchCriteria();
                this.vehicleSearchCriteria.activeStatus = true;
                this.vehicleSearchCriteria.resourceType = ResourceType.vehicle;
            } else if (this.searchType === VehicleSearchType.ResourceAssign) {
                this.vehicleSearchCriteria.resourceId = this.vehicleSearchCriteria.resourceId === -1 ?
                    null :
                    this.vehicleSearchCriteria.resourceId;
            }
        }

        this.getVehicleSearchObserver();
        this.errorObserver();
        this.selectedChipsUpdate();
        this.handleInterRelatedChips();
        this.notifyChipDelete();
        this.shortCutObserver();
    }

    selectedSupplierObserver(){
        this.selectedSupplierObsv = this.dataStoreService.get(DataKey.assignedSupplier).subscribe(value => {
            if(value){
                this.setCriteria(value);
            }
        })
    }
    selectedSupplierFromDriverObserver(){
        this.selectedSupplierObsv = this.dataStoreService.get(DataKey.assignedSupplierFromDriver).subscribe(value => {
            if(value){
                this.setCriteria(value);
            }
        })
    }
    setCriteria(value: any){
        let selected = value;
        if(selected.code !== this.vehicleSearchCriteria.supplierCode){
            this.vehicleSearchCriteria.supplierCode = selected.code;
            this.vehicleSearchCriteria.serviceDate = selected.serviceDate ? selected.serviceDate : this.vehicleSearchCriteria.serviceDate ;
            this.vehicleSearchCriteria.route = selected.route;
            this.vehicleSearchCriteria.transferMode = selected.transferMode;
            this.vehicleSearchCriteria.activeStatus = true;
            this.vehicleSearchCriteria.resourceType = ResourceType.vehicle;
            this.getQueryParams();
            this.initSearchCriteriaChips();
        }
    }

    getQueryParams() {
        let queryParams: string[] = [];
        queryParams = this.queryParamService.getQueryParamsArray(new GenericSearchCriteria());

        if (this.searchType !== VehicleSearchType.ResourceAssign) {
            this.vehicleSearchCriteria = this.rootService.loadDataFromLocalStorage(queryParams, new VehicleSearchCriteria(),
                TCO.AppData.VEHICLE_SEARCH_CRITERIA
            );
        }

        this.vehicleSearchCriteria.resourceType = ResourceType.vehicle;
        this.vehicleSearchCriteria.activeStatus = true;


        // get persisting query params
        if (this.vehicleSearchCriteria) {
            this.persistentQParams = [];
            this.persistentQParams = this.queryParamService.getNotNullQueryParamsArray(this.vehicleSearchCriteria);

            // remove resourceId from persistent chips when it is -1
            if (this.persistentQParams && this.persistentQParams.length > 0) {
                if (this.vehicleSearchCriteria.resourceId === -1) {
                    for (let i = 0; i < this.persistentQParams.length; i++) {
                        if (this.persistentQParams[i] === 'resourceId') {
                            this.persistentQParams.splice(i, 1);
                            this.vehicleSearchCriteria.resourceId = null;
                        }
                    }
                }
                /*else {
                    for (let i = 0; i < this.persistentQParams.length; i++) {
                        if (this.persistentQParams[i] === 'resourceType') {
                            this.persistentQParams.splice(i, 1);
                            this.vehicleSearchCriteria.resourceType = '';
                        }
                    }
                }*/
            }

        }
    }

    private shortCutObserver() {
        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'FOCUS_SEARCH_BAR').subscribe(e => {
            this.isFocus = true;
        });
        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'CLOSE_POPUPS').subscribe(e => {
            this.isFocus = false;
        });
    }

    public onFocus($event) {
        this.isFocus = $event;
    }

    initSearchCriteriaChips() {
        this.isChipsInit = true;
        this.chipHandler.chipsArr = [];
        // read chip config
        let criteriaChipConfigs = null;
        if (!this.isInsideForm) {
            const chipConfigs = this.configLoader.configurations.get(TCO.CONF.CONF_VEHICLE_SEARCH_CRITERIA_CHIP);
            criteriaChipConfigs = this.commonHelper.recreateJsonObject(chipConfigs);
        } else {
            const chipConfigs = this.configLoader.configurations.get(this.searchCriteriaChipConfig);
            criteriaChipConfigs = this.commonHelper.recreateJsonObject(chipConfigs);
        }
        if (criteriaChipConfigs && criteriaChipConfigs.chips) {
            criteriaChipConfigs.chips.forEach(chip => {
                chip = this.chipHandler.preProcessChips(chip, criteriaChipConfigs.permissionKeys.keys);
            });
        }

        this.chipHandler.chipsSubject.subscribe(data => {
            if (data && JSON.stringify(data) !== JSON.stringify([]) && data.length === criteriaChipConfigs.chips.length) {
                this.availableChips = data; //new json object here;
                this.chipHandler.chipsSubject.next(null);

                if (this.chipHandlerSubject) {
                    this.chipHandlerSubject.unsubscribe();
                }

                // set values to display chips
                this.displayChipsArr = [];
                this.displayChipsArr = this.availableChips;

                this.dataStoreService.set(DataKey.chips, this.availableChips, true);


                this.availableChips.forEach((chip) => {
                    if (chip['criteriaId'] === 'RESOURCE_TYPE') {
                        chip['optionParams']['isInitialPresent'] = true;
                        let choice = chip['optionParams']['options']
                            .filter(option => option.code === this.vehicleSearchCriteria.resourceType);
                        chip['optionParams']['data'] = choice;
                        chip['optionParams']['isMandatory'] = true;
                        chip['optionParams']['disabled'] = true;
                    }
                    if (this.searchType === VehicleSearchType.ResourceAssign && this.vehicleSearchCriteria) {
                        /*if (chip['criteriaId'] === 'SERVICE_DATE' && this.vehicleSearchCriteria.serviceDate) {

                            chip['optionParams']['isInitialPresent'] = true;
                            chip['optionParams']['data'] = [moment(this.vehicleSearchCriteria.serviceDate).toDate()];
                        }*/
                        if (chip['criteriaId'] === 'TRANSFER_MODE') {

                            chip['optionParams']['isInitialPresent'] = true;
                            let choice = chip['optionParams']['options']
                                .filter(option => option.code === this.vehicleSearchCriteria.transferMode);
                            chip['optionParams']['data'] = choice;
                            chip['optionParams']['isMandatory'] = true;
                            if(this.vehicleSearchCriteria.isPrivate){
                                chip['optionParams']['disabled'] = true;
                            }
                        }
                        if (chip['criteriaId'] === 'ROUTE') {

                            chip['optionParams']['isInitialPresent'] = true;
                            let choice = chip['optionParams']['options']
                                .filter(option => option.code === this.vehicleSearchCriteria.route);
                            chip['optionParams']['data'] = choice;
                            chip['optionParams']['isMandatory'] = true;
                        }
                        if (chip['criteriaId'] === 'SUPPLIER' && this.vehicleSearchCriteria.supplierId) {

                            chip['optionParams']['isInitialPresent'] = true;
                            let choice = chip['optionParams']['options']
                                .filter(option => option.supplierId === this.vehicleSearchCriteria.supplierId);
                            chip['optionParams']['data'] = choice;
                        }
                    }
                });


                this.chipInputService.init(this.vehicleSearchCriteria, this.availableChips, false, true);
                this.isChipsInit = true;

                // implement chip persistance
                if (this.persistentQParams && this.persistentQParams.length > 0) {
                    // create a queryParamMap
                    for (let i = 0; i < this.persistentQParams.length; i++) {
                        let qParam = this.persistentQParams[i];
                        let qParamValue = this.vehicleSearchCriteria[qParam];
                        if (qParam && qParamValue) {
                            this.persistentQParamMap.set(qParam, qParamValue);
                        }
                    }
                    this.persistentChipsArr = [];
                    // fill persistent chips
                    this.availableChips.forEach(chip => {
                        let persistChip = Object.assign({}, chip);
                        persistChip.optionParams.data = [];
                        let isPersist = false;
                        if (chip.dataParams && chip.dataParams.length > 0) {
                            chip.dataParams.forEach(dataParam => {
                                if (this.persistentQParamMap.has(dataParam.paramValue)) {
                                    isPersist = true;
                                    const dataObj = {
                                        key: dataParam.paramValue,
                                        value: this.persistentQParamMap.get(dataParam.paramValue)
                                    };
                                    persistChip.optionParams.data.push(dataObj);
                                }
                            });
                        }
                        // push persist chips to an array
                        if (isPersist) {
                            this.persistentChipsArr.push(persistChip);
                        }
                    });
                    // persist chips
                    if (this.persistentChipsArr && this.persistentChipsArr.length > 0) {
                        /*const uniqueChipList = this.persistentChipsArr.filter((e, i) => {
                            return this.persistentChipsArr.findIndex((x) => {
                                return x.criteriaId === e.criteriaId;
                            }) === i;
                        });*/

                        this.chipInputService.persistChips(this.persistentChipsArr);

                        // trigger search when chips persisting
                        this.isDataLoaded = false;
                        // this.isTriggerSearch = false;
                        this.onVehicleSearch(this.vehicleSearchCriteria);
                    }
                }
                this.chipInputService.criteriaUpdate.subscribe((_data) => {
                    this.vehicleSearchCriteria = _data;
                });
            }
        });
    }

    public onVehicleSearch($event) {
        this.isDataLoaded = false;
        this.vehicleSearchCriteria = $event;
        this.vehicleSearchCriteria.sortDirection = SortDirection.ASC;

        if (this.tPagination && this.tPagination['initialFetchingPages'] &&
            this.tPagination['defaultPageResultCount']) {
            this.vehicleSearchCriteria.size = this.tPagination['initialFetchingPages'] *
                this.tPagination['defaultPageResultCount'];
            this.vehicleSearchCriteria.start = 0;
        }

        this.userJourneyManager.canProceed.next(false);

        if (JSON.stringify(this.vehicleSearchCriteria) !== JSON.stringify({})) {
            this.dataStoreService.set(DataKey.vehicleSearchCriteria, this.vehicleSearchCriteria, true);

            if (this.searchType === VehicleSearchType.ResourceAssign) {
                this.dataStoreService.set(DataKey.resourceAssignVehicleSearchResults, null, true);
                this.trsReservationManagementService.searchRecommendedVehiclesWithDataKey(this.vehicleSearchCriteria);
            } else {
                this.dataStoreService.set(DataKey.vehicleSearchResults, null, true);
                this.transportResourceService.searchVehicleSummaries(this.vehicleSearchCriteria);

                // set criteria parameters to url
                let queryParams: string[] = [];
                queryParams = this.queryParamService.getQueryParamsArray(this.vehicleSearchCriteria);
                this.rootService.setDataToLocalStorage(TCO.AppData.VEHICLE_SEARCH_CRITERIA, this.vehicleSearchCriteria, true,
                    queryParams, true
                );
            }

            // this.spinnerService.show();

            this.isTriggerSearch = false;
            this.isDataLoaded = false;
            this.searchTrigger.emit(true);

        } else {
            setTimeout(() => {
                this.isDataLoaded = true;
                this.isTriggerSearch = true;
            }, 0);
        }

    }

    getUpdatedFieldsDataObject(chip, criteria: GenericSearchCriteria) {
        const dataObjArr = [];

        if (chip && chip.dataParams && chip.dataParams.length > 0) {
            chip.dataParams.forEach(dataParam => {
                const dataObj = {
                    key: '',
                    value: ''
                };
                dataObj.key = dataParam.paramValue;
                dataObj.value = criteria[dataParam.paramValue];

                dataObjArr.push(dataObj);
            });
        }

        return dataObjArr;
    }

    private getVehicleSearchObserver() {
        if (this.searchType === VehicleSearchType.ResourceAssign) {
            const x = this.dataStoreService.get(DataKey.resourceAssignVehicleSearchResults).subscribe((data) => {
                if (this.commonHelper.dataValidity(data)) {
                    setTimeout(() => {
                        this.isDataLoaded = true;
                        this.isTriggerSearch = true;
                    }, 0);
                } else if (this.commonHelper.isEmptyData(data)) {
                    this.isDataLoaded = true;
                } else if (data instanceof TcApiError) {
                    this.isDataLoaded = true;
                } else if (data instanceof TcHttpError) {
                    this.isDataLoaded = true;
                }
            }, (error: any) => {
                console.log(error);
            });
        } else {
            const x = this.dataStoreService.get(DataKey.vehicleSearchResultsService).subscribe((data) => {
                if (this.commonHelper.dataValidity(data)) {
                    setTimeout(() => {
                        this.isDataLoaded = true;
                        this.isTriggerSearch = false;
                    }, 0);
                } else if (this.commonHelper.isEmptyData(data)) {
                    this.isDataLoaded = true;
                } else if (data instanceof TcApiError) {
                    this.isDataLoaded = true;
                } else if (data instanceof TcHttpError) {
                    this.isDataLoaded = true;
                }
            }, (error: any) => {
                console.log(error);
            });
        }
    }

    private errorObserver() {
        this.dataStoreService.get(DataKey.error).subscribe((data) => {
            if (data && JSON.stringify(data) !== JSON.stringify({})) {
                setTimeout(() => {
                    this.isDataLoaded = true;
                }, 0);
                this.dataStoreService.set(DataKey.error, null);
            }
        });
    }

    private resetQueryParams() {

        let vehicleQueryParams: string[] = [];
        vehicleQueryParams = this.queryParamService.getQueryParamsArray(new VehicleSearchCriteria());

        let vehicleSearchCriteria: VehicleSearchCriteria = this.rootService.loadDataFromLocalStorage([],
            new VehicleSearchCriteria(), TCO.AppData.VEHICLE_SEARCH_CRITERIA
        );

        // set criteria parameters to url
        let queryParams: string[] = [];
        queryParams = this.queryParamService.getQueryParamsArray(vehicleSearchCriteria);

        this.rootService.setDataToLocalStorage(TCO.AppData.VEHICLE_SEARCH_CRITERIA, this.vehicleSearchCriteria,
            true, queryParams, true
        );
    }

    public onCancel($event) {
        console.log('cancelled');
    }

    public onChipListDisplay($event) {
        this.dataStoreService.set(DataKey.chipListDisplay, null, true);
        this.isChipListDisplay = $event;
        this.dataStoreService.set(DataKey.chipListDisplay, this.isChipListDisplay, true);

    }

    handleQuickActions() {
        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'TRIGGER_SEARCH_CRITERIA').subscribe((e) => {
            this.triggerSearch(e.data);
        });
    }

    triggerSearch(data: string) {
        this.vehicleSearchCriteria = new VehicleSearchCriteria();
        // todo criteria

        this.actionHandlerService.fillCriteriaByActionData(data, this.vehicleSearchCriteria);
        let requiredChips = this.chipHandler.getChipsFromCriteria(
            this.vehicleSearchCriteria,
            TCO.CONF.CONF_VEHICLE_SEARCH_CRITERIA_CHIP,
            true
        );
        // requiredChips = this.chipHandler.validateAndFilterChips(requiredChips, ['bookingRefId|>|0']);
        this.chipInputService.hardReset();
        this.chipInputService.persistChips(requiredChips, true);
        this.onVehicleSearch(this.vehicleSearchCriteria);
    }

    observeChipValueUpdate() {
    }

    private handleInterRelatedChips() {
        this.relatedChipsObsv = this.chipInputService.relatedChipsSubject.subscribe(
            (data) => {
                let toggleChips = [];
                if (data && data.chip && JSON.stringify(data) !== JSON.stringify({})) {
                    this.modifiedChip = data.chip;
                    // TODO: refine chip input service: inter related chips
                    if (data.chip.type === 'CALENDAR') {
                        this.chipHandler.handleInterRelatedChips(data.chip, data.date);
                    } else {
                        this.chipHandler.handleInterRelatedChips(data.chip, data.options.value[0]);
                    }
                    if (data.chip.optionParams.toggleChips && data.chip.optionParams.toggleChips.length > 0) {
                        toggleChips = data.chip.optionParams.toggleChips;
                        if (toggleChips && data.options && data.options.value[0].value) {
                            this.chipInputService.selectedChipsBasedOnStateChange(toggleChips);
                        } else {
                            this.chipInputService.deleteChipsBasedOnStateChange(toggleChips);
                        }
                    }
                }
            }
        );
    }

    resetResourceAssignChips() {
        this.availableChips.forEach((chip) => {
            if (chip['criteriaId'] === 'SERVICE_DATE') {

                chip['optionParams']['isInitialPresent'] = false;
                chip['optionParams']['data'] = [];
            }
            if (chip['criteriaId'] === 'TRANSFER_MODE') {

                chip['optionParams']['isInitialPresent'] = false;
                chip['optionParams']['data'] = [];
            }
            if (chip['criteriaId'] === 'ROUTE') {

                chip['optionParams']['isInitialPresent'] = false;
                chip['optionParams']['data'] = [];
            }
        });
    }

    ngOnDestroy() {

        this.routerChangeSubscription.unsubscribe();
        this.userJourneyManager.routeChanges.next(false);

        if (this.selectedChipsObsv) {
            this.selectedChipsObsv.unsubscribe();
        }

        if (this.selectedFieldsObsv) {
            this.selectedFieldsObsv.unsubscribe();
        }

        if (this.chipValueUpdateSub) {
            this.chipValueUpdateSub.unsubscribe();
        }
        if (this.criteriaUpdateSubscription) {
            this.criteriaUpdateSubscription.unsubscribe();
        }
        if (this.deletedChipObsv) {
            this.deletedChipObsv.unsubscribe();
        }
        if (this.searchType === VehicleSearchType.ResourceAssign) {
            this.resetResourceAssignChips();
            this.resetQueryParams();
        }
        this.em.removeEvents([
            'TRIGGER_SEARCH_CRITERIA'
        ]);
    }

    private selectedChipsUpdate() {
        this.criteriaUpdateSubscription = this.chipInputService.selectedChipsSubject.subscribe(selectedChips => {
            this.selectedChips = selectedChips;

            if (selectedChips && JSON.stringify(selectedChips) !== JSON.stringify([])) {
                // toggle chips when selecting BRANCH_OF_CLIENT_ID chip
                for (const selectedChip of this.selectedChips) {
                    if (selectedChip.chip.id === 'BRANCH_OF_CLIENT_ID' && selectedChip.chip.optionParams.toggleChips &&
                        selectedChip.chip.optionParams.toggleChips.length > 0) {
                        this.deleteToggleChips(selectedChip.chip.optionParams.toggleChips);
                        this.disableChips(selectedChip.chip.optionParams.toggleChips);
                        break;
                    }
                }
            }
        });
    }

    private notifyChipDelete() {
        this.deletedChipObsv = this.chipInputService.deletedChipsSubject.subscribe(deletedChip => {
            if (JSON.stringify(deletedChip) !== JSON.stringify({}) && deletedChip !== null && deletedChip !== undefined) {
                if (deletedChip.id === 'CLIENT_TYPE') {
                    const deleteChips = this.selectedChips.map(chip => chip.chip);
                    this.deleteSelectedChips(deleteChips);
                    this.enableChips(this.availableChips);
                } else if (deletedChip.id === 'BRANCH_OF_CLIENT_ID') {
                    if (deletedChip.optionParams.toggleChips && deletedChip.optionParams.toggleChips.length > 0) {
                        this.enableChips(deletedChip.optionParams.toggleChips);
                    }
                }
            }
        });
    }

    /**
     * Delete toggle chips if already selected
     *
     * @param toggleChips
     */
    private deleteToggleChips(toggleChips: any[]) {
        const deleteChips = [];
        toggleChips.forEach(toggleChip => {
            for (const selectedChip of this.selectedChips) {
                if (selectedChip.chip.id === toggleChip.criteriaId) {
                    deleteChips.push(selectedChip.chip);
                    break;
                }
            }
        });
        this.deleteSelectedChips(deleteChips);
    }

    private deleteSelectedChips(deleteChips: any[]) {
        deleteChips.forEach(chip => {
            this.chipInputService.deleteChip(chip, true);
        });
    }

    private enableChips(enableChips: any[]) {
        enableChips.forEach(enableChip => {
            this.chipInputService.modifyChipsStatus(enableChip, false);
        });
    }

    private disableChips(disableChips: any[]) {
        disableChips.forEach(disableChip => {
            this.chipInputService.modifyChipsStatus(disableChip, true);
        });
    }
}

