import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Howl } from 'howler';
import { NotificationToastModel } from '../notification-toast-model';

@Component({
  selector: 'tc-notification-toast',
  templateUrl: './notification-toast.component.html',
  styleUrls: ['./notification-toast.component.scss']
})
export class NotificationToastItemComponent implements OnInit {

  @Input() toastModel: NotificationToastModel;
  @Output() closeClick = new EventEmitter();
  @Output() toastClick = new EventEmitter();

  onClose($event: any) {
    $event.preventDefault();
    this.closeClick.next(this.toastModel);
  }

  public onClick($event: any) {
    if ($event.target && $event.target.id !== 'item_close_btn') {
      $event.preventDefault();
      this.toastClick.next(this.toastModel);
    }
  }

  public ngOnInit(): void {
    const sound = new Howl({
      src: [this.toastModel.sound]
    });
    sound.play();
  }
}
