import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Facility } from '../../../models/resource/vehicle';

@Component({
    selector: 'tc-vehicle-amenities',
    templateUrl: './vehicle-amenities.component.html'
})
export class VehicleAmenitiesComponent implements OnInit {

    @Input() facilities: Facility [];
    @Input() defaultFacilityDetails: any[];

    @Output() changeFormValidity: EventEmitter<any> = new EventEmitter();

    facilityList = [];
    constructor() {
    }

    ngOnInit() {
        this.facilities.forEach(value => {
            this.facilityList.push(value.facilityCode);
        });
    }

    toggleValue(facility: any) {
        if (!this.facilityList.includes(facility.code)) {
            let fac = new Facility(facility.code);
            this.facilities.push(fac);
            this.facilityList.push(facility.code);
        } else {
            let removingFacility = this.facilities.find(fa => fa.facilityCode === facility.code);
            this.facilities.splice(this.facilities.indexOf(removingFacility), 1);
            this.facilityList = this.facilityList.filter(fac => fac !== facility.code);
        }
        this.changeFormValidity.emit();
    }

}

