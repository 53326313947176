import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { MatIconModule, MatMenuModule } from '@angular/material';
import { DataFormatterUtilService } from '@tc-core/util/system/data-formatter-util.service';
import { ContentStripModule } from '@tc/content-strip';
import { DirectivesModule } from '@tc/directives';
import { ExpandedContentCardModule } from '@tc/expanded-content-card';
import { PaginatorModule } from '@tc/paginator';
import { PipesModule } from '@tc/pipes';
import { OpExpandedContentCardHeaderModule } from '../op-expanded-content-card-header/op-expanded-content-card-header.module';
import { ResultContentCardGenericModule } from '../result-content-card-generic/result-content-card-generic.module';
import { VehicleDetailViewModule } from '../vehicle-detail-view/vehicle-detail-view.module';
import { AdvVehicleSearchResultsComponent } from './adv-vehicle-search-results.component';

@NgModule({
  imports: [
    CommonModule,
    PipesModule,
    DirectivesModule,
    ResultContentCardGenericModule,
    ContentStripModule,
    ExpandedContentCardModule,
    OpExpandedContentCardHeaderModule,
    PaginatorModule,
    VehicleDetailViewModule
  ],
  providers: [
    DataFormatterUtilService,
    DatePipe
  ],
  declarations: [AdvVehicleSearchResultsComponent],
  exports:[AdvVehicleSearchResultsComponent]
})
export class AdvVehicleSearchResultsModule { }
