<div class="container-fluid tc-item-wrapper tcpi-card">
    <div class="tc-item-wrapper__header tc-item-header-highlighted" *ngIf="showTitle">
        <div class="tc-item-header-highlighted__icon">
            <i class="material-icons material-icons">credit_card</i>
        </div>
        <div class="tc-item-header-highlighted__primary">{{"Payment Information" | translate}}</div>
    </div>
    <div class="tc-item-wrapper__body" *ngIf="!trsResourceCosts || trsResourceCosts.length===0">
        <div class="row">
            <div class="col-md-12">
                <div class="tc-flex-box">
                    <div class="tc-flex-item icon-cage input-icon">
                        <div class="card-icon--small icn-color-primary">
                        </div>
                    </div>
                    <div class="tc-flex-item input-cage">
                        <div class="container-fluid">
                            <span class="txt-light">{{'No Cost Setup Data' | translate}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="tc-item-wrapper__body" [hidden]="!trsResourceCosts || trsResourceCosts.length===0">
        <div class="row">
            <div class="col-md-12">
                <div class="tc-flex-box">
                    <!--<div class="tc-flex-item icon-cage input-icon">-->
                        <!--<div class="card-icon&#45;&#45;small icn-color-primary">-->
                        <!--</div>-->
                    <!--</div>-->
                    <div class="tc-flex-item input-cage">

                        <div class="mat-elevation-z0 tc-mat-table tc-mat-table--border">
                            <table mat-table [dataSource]="dataSource" matSort class="rs-full-width">

                                <!-- Season Name Column -->
                                <ng-container matColumnDef="seasonName">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'Season Name' | translate}}</th>
                                    <td mat-cell *matCellDef="let element"> {{element.seasonName}} </td>
                                </ng-container>

                                <!-- Route Column -->
                                <ng-container matColumnDef="route">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'Route' | translate}}</th>
                                    <td mat-cell *matCellDef="let element"> {{element.route}} </td>
                                </ng-container>

                                <!-- Transfer Mode Column -->
                                <ng-container matColumnDef="transferMode">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'Transfer Mode' | translate}}</th>
                                    <td mat-cell *matCellDef="let element"> {{getTrsModeName(element.transferMode)}} </td>
                                </ng-container>

                                <!-- Vehicle Model Column -->
                                <ng-container matColumnDef="vehicleModel">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'Vehicle Model' | translate}}</th>
                                    <td mat-cell *matCellDef="let element"> {{getVehicleModelName(element.vehicleModel)}} </td>
                                </ng-container>

                                <!-- Days Column -->
                                <ng-container matColumnDef="days">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'Days' | translate}}</th>
                                    <td mat-cell *matCellDef="let element" class="tc-mat-table-cell ">
                                        <div class="tc-day-selector__container">
                                            <label class="tc-day-selector">
                                                <input type="checkbox" [checked]="element.days && element.days.includes(1)" disabled>
                                                <span class="indicator indicator--alt">S</span>
                                            </label>
                                            <label class="tc-day-selector">
                                                <input type="checkbox" [checked]="element.days && element.days.includes(2)" disabled>
                                                <span class="indicator">M</span>
                                            </label>
                                            <label class="tc-day-selector">
                                                <input type="checkbox" [checked]="element.days && element.days.includes(3)" disabled>
                                                <span class="indicator">T</span>
                                            </label>
                                            <label class="tc-day-selector">
                                                <input type="checkbox" [checked]="element.days && element.days.includes(4)" disabled>
                                                <span class="indicator">W</span>
                                            </label>
                                            <label class="tc-day-selector">
                                                <input type="checkbox" [checked]="element.days && element.days.includes(5)" disabled>
                                                <span class="indicator">T</span>
                                            </label>
                                            <label class="tc-day-selector">
                                                <input type="checkbox" [checked]="element.days && element.days.includes(6)" disabled>
                                                <span class="indicator">F</span>
                                            </label>
                                            <label class="tc-day-selector">
                                                <input type="checkbox" [checked]="element.days && element.days.includes(7)" disabled>
                                                <span class="indicator">S</span>
                                            </label>
                                        </div>
                                    </td>
                                </ng-container>

                                <!-- Transfer Mode Column -->
                                <ng-container matColumnDef="costingType">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'Costing Type' | translate}}</th>
                                    <td mat-cell *matCellDef="let element"> {{getCostingTypeName(element.costingType)}} </td>
                                </ng-container>
                                <!-- Unit Fare Column -->
                                <ng-container matColumnDef="unitFare">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'Unit Fare' | translate}}</th>
                                    <td mat-cell *matCellDef="let element" class="">
                                        {{element.unitFare ? (element.unitFare | tcCurrencyPipe:currencyFormat?.currencyCode:element.currency:currencyFormat?.pattern) : EMPTY_CHAR}}
                                    </td>
                                </ng-container>


                                <!-- Adult Fare Column -->
                                <ng-container matColumnDef="adultFare">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'Adult Fare' | translate}}</th>
                                    <td mat-cell *matCellDef="let element" class="">
                                        {{element.adultFare ? (element.adultFare | tcCurrencyPipe:currencyFormat?.currencyCode:element.currency:currencyFormat?.pattern) : EMPTY_CHAR}}
                                    </td>
                                </ng-container>

                                <!-- Child Fare Column -->
                                <ng-container matColumnDef="childFare">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'Child Fare' | translate}}</th>
                                    <td mat-cell *matCellDef="let element" class="">
                                        {{element.childFare ? (element.childFare | tcCurrencyPipe:currencyFormat?.currencyCode:element.currency:currencyFormat?.pattern) : EMPTY_CHAR}}
                                    </td>
                                </ng-container>

                                <!-- Infant Fare Column -->
                                <ng-container matColumnDef="infantFare">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'Infant Fare' | translate}}</th>
                                    <td mat-cell *matCellDef="let element" class="">
                                        {{element.infantFare ? (element.infantFare | tcCurrencyPipe:currencyFormat?.currencyCode:element.currency:currencyFormat?.pattern) : EMPTY_CHAR}}
                                    </td>
                                </ng-container>

                                <!-- Teen Fare Column -->
                                <ng-container matColumnDef="teenFare">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'Teen Fare' | translate}}</th>
                                    <td mat-cell *matCellDef="let element" class="">
                                        {{element.teenFare ? (element.teenFare | tcCurrencyPipe:currencyFormat?.currencyCode:element.currency:currencyFormat?.pattern) : EMPTY_CHAR}}
                                    </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                            </table>

                            <mat-paginator pageSize="5" showFirstLastButtons></mat-paginator>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    </div>

</div>
