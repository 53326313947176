import { Component, Input, OnInit } from '@angular/core';
import { SummaryCardData } from '@tc-core/model/it/codegen/ui/framework/summary-card-data';
import {DataKey, DataStoreService} from '../../../services/util/framework/data-store.service';

@Component({
    selector: 'tc-result-content-card',
    templateUrl: './result-content-card.component.html'
})
export class ResultContentCardComponent implements OnInit {

    @Input() summaryContent: SummaryCardData;
    @Input() row2col2MatIcon: string;

    isUpdated: boolean = false;
    isCancelled: boolean = false;


    //to override in child components
    row1col1_customsStyles = [];
    row1col2_customsStyles = [];
    row1col3_customsStyles = [];
    row1col4_customsStyles = [];

    row2col1_customsStyles = [];
    row2col2_customsStyles = [];
    row2col3_customsStyles = [];
    row2col4_customsStyles = [];
    

    ngOnInit() {
    }

}
