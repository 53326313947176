<tc-header
        [inputThemeCode]="inputThemeCode"
        [headings]="headings"
        [logoLocation]="'assets/img/' + logo"
        [isSidebarOpen]="sideBarDisplay"
        [languages]="languages"
        [activeLanguage]="activeLanguage"
        [actions]="actions"
        [user]="user"
        [isUserLoaded]="true"
        (logout)="onLogout()"
        (themeSelection)="onThemeSelection($event)"
        (toggleSidebar)="onSidebarToggle($event)"
        (actionClick)="onActionClick($event)"
        (changeLanguage)="onLanguageChagne($event)">
</tc-header>
