import * as tslib_1 from "tslib";
import { AbstractSearchCriteria } from "./abstract-search-criteria";
var CountrySearchCriteria = /** @class */ (function (_super) {
    tslib_1.__extends(CountrySearchCriteria, _super);
    function CountrySearchCriteria() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return CountrySearchCriteria;
}(AbstractSearchCriteria));
export { CountrySearchCriteria };
