export enum AssignmentStatus {
    ANY = 'ANY',
    ASSIGNED= 'ASSIGNED',
    UNASSIGNED= 'UNASSIGNED',
    PENDING = 'PENDING',
    DMC_CONFIRM =  'DMC_CONFIRM',
    SUPPLIER_CONFIRMED =  'SUPPLIER_CONFIRMED',
    SUPPLIER_REJECTED =  'SUPPLIER_REJECTED',
    REQUEST_EXPIRED =  'REQUEST_EXPIRED',
    BOOKING_UPDATE =  'BOOKING_UPDATE',
    INACTIVE_NOTIFY =  'INACTIVE_NOTIFY',
    INACTIVE =  'INACTIVE',
    STARTED = 'STARTED',
    COMPLETED =  'COMPLETED',
    NO_SHOW = 'NO_SHOW'

}

export const StatusCodes = {
    PENDING: 'P',
    DMC_CONFIRM: 'DC',
    SUPPLIER_CONFIRMED: 'SC',
    SUPPLIER_REJECTED: 'R',
    REQUEST_EXPIRED: 'RE',
    BOOKING_UPDATE: 'BU',
    INACTIVE_NOTIFY: 'IN',
    INACTIVE: 'I',
    STARTED: 'S',
    COMPLETED: 'C',
    NO_SHOW: 'NS'
};
