import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ServicePassenger } from '../../../models/reservation/service-passenger';

@Component({
    selector: 'tc-lead-passenger-selection',
    templateUrl: './lead-passenger-selection.component.html'
})
export class LeadPassengerSelectionComponent implements OnInit {

    @Input() leadPassenger: ServicePassenger;
    @Input() passengers: ServicePassenger[];
    @Output() closePopUp: EventEmitter<any> = new EventEmitter();
    @Output() save: EventEmitter<any> = new EventEmitter();
    @Input() isCompleted: boolean;

    selectedPassenger: ServicePassenger;

    constructor() { }

    ngOnInit() {
        if (this.leadPassenger) {
            this.selectedPassenger = this.leadPassenger;
        }
        this.passengers.sort(((a, b) => (a.type > b.type) ? 1 : -1));
    }

    public onClose($event: MouseEvent) {
        this.closePopUp.emit($event);
    }

    public onSave($event: MouseEvent) {
        console.log(this.isCompleted)
        this.save.emit(this.selectedPassenger);
    }

    private getPassengerType(type: any): string {
        switch (type) {
            case 'A':
                return 'Adult';
            case 'C':
                return 'Child';
            case 'I':
                return 'Infant';
            default:
                return '';
        }
    }
}
