import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { LEVEL, ModalData } from '@tc-core/model/it/codegen/ui/framework/modal-data';
import { ConfigLoader } from '@tc-core/util/framework/config-loader.service';
import { FocusViewService } from '@tc/focus-view/focus-view.service';
import { ModalService } from '@tc/modal/modal.service';
import { Subscription } from 'rxjs';
import { TCO } from '../../../../constants';
import { GenResourceCost, GenResourceCostSetup } from '../../../../models/supplier/gen-resource-cost-setup';
import { RoomResourceCost, RoomResourceCostSetup } from '../../../../models/supplier/room-resource-cost';
import { Supplier } from '../../../../models/supplier/supplier';
import { TrsResourceCost, TrsResourceCostSetup } from '../../../../models/supplier/trs-resource-cost-setup';
import { DataHandlerService } from '../../../../services/backend-consumers/master-data-handler-service/data-handler.service';
import { MasterDataRestService } from '../../../../services/backend-consumers/master-data-handler-service/master-data-rest.service';
import { DataKey, DataStoreService } from '../../../../services/util/framework/data-store.service';
import { GenericCostSetupFocusViewComponent } from '../generic-cost-setup-focus-view/generic-cost-setup-focus-view.component';
import { RoomCostSetupFocusViewComponent } from '../room-cost-setup-focus-view/room-cost-setup-focus-view.component';
import { SeasonSetupFocusViewComponent } from '../season-setup-focus-view/season-setup-focus-view.component';
import { TransportCostSetupQueueFocusViewComponent } from '../transport-cost-setup-queue-focus-view/transport-cost-setup-queue-focus-view.component';
var SupplierCostSetupComponent = /** @class */ (function () {
    function SupplierCostSetupComponent(modalService, dataStore, focusViewService, dataHandlerService, configLoader, masterDataRestService) {
        this.modalService = modalService;
        this.dataStore = dataStore;
        this.focusViewService = focusViewService;
        this.dataHandlerService = dataHandlerService;
        this.configLoader = configLoader;
        this.masterDataRestService = masterDataRestService;
        this.traResourceCosts = [];
        this.genResourceCosts = [];
        this.roomResourceCosts = [];
        this.resourceCostData = [];
        this.showAddButton = true;
        this.selectedResourceTypes = [];
        this.resourceTypes = [];
        this.selectedProfileTypes = [];
        this.routes = [];
        this.transferModes = [];
        this.daysOfWeek = [];
        this.currencyList = [];
        this.costingTypeList = [];
        this.focusviewCloseSubject = new Subscription();
        this.vehicleModels = [];
        this.defaultProfileType = {
            code: 'ANY',
            name: 'Any'
        };
        this.trsCostChangeSub = new Subscription();
        this.genCostChangeSub = new Subscription();
        this.roomCostChangeSub = new Subscription();
    }
    SupplierCostSetupComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.dataStore.set(DataKey.resourceCostData, null, true);
        this.dataStore.set(DataKey.trsResourceCosts, null, true);
        this.dataStore.set(DataKey.genResourceCosts, null, true);
        this.dataStore.set(DataKey.roomResourceCosts, null, true);
        // this.resourceCosts = this.resourceCostData;
        this.getUpdatedResourceCostData();
        this.dataHandlerService.retrieveDaysOfWeek().subscribe(function (days) {
            if (days !== null) {
                _this.daysOfWeek = days.data;
                _this.getCurrencyList();
            }
        });
    };
    SupplierCostSetupComponent.prototype.getCurrencyList = function () {
        var _this = this;
        this.dataStore.get(DataKey.currencyList).subscribe(function (value) {
            _this.currencyList = value;
            _this.getCostingTypeList();
        });
    };
    SupplierCostSetupComponent.prototype.getCostingTypeList = function () {
        var _this = this;
        this.masterDataRestService.getCostingTypes().subscribe(function (result) {
            if (result && result.data) {
                _this.costingTypeList = result.data;
                _this.getResourceCost();
            }
        });
    };
    SupplierCostSetupComponent.prototype.getResourceCost = function () {
        if (this.resourceCostData.length > 0) {
            this.addDefaultProfileType(this.selectedProfileTypes);
            // setting temp ids to seasons
            this.setTempIdsToSeasons();
            var tempCostId = 0;
            for (var i = 0; i < this.resourceCostData.length; i++) {
                if (this.resourceCostData[i].trsResourceCosts.length > 0) {
                    for (var j = 0; j < this.resourceCostData[i].trsResourceCosts.length; j++) {
                        var days = this.getDays(this.resourceCostData[i].trsResourceCosts[j].days);
                        var daysArr = this.getDaysArr(this.resourceCostData[i].trsResourceCosts[j].days);
                        tempCostId++;
                        this.resourceCostData[i].trsResourceCosts[j].tempCostId = tempCostId;
                        var resourceCost = new TrsResourceCostSetup();
                        resourceCost.tempCostId = tempCostId;
                        resourceCost.transferMode = this.filterObject(this.resourceCostData[i].trsResourceCosts[j].transferMode, this.transferModes);
                        resourceCost.vehicleModel = this.filterObject(this.resourceCostData[i].trsResourceCosts[j].vehicleModel, this.vehicleModels);
                        resourceCost.route = this.filterObject(this.resourceCostData[i].trsResourceCosts[j].route, this.routes);
                        resourceCost.currency = this.resourceCostData[i].trsResourceCosts[j].currency;
                        resourceCost.costingType = this.filterObject(this.resourceCostData[i].trsResourceCosts[j].costingType, this.costingTypeList);
                        resourceCost.seasonName = this.resourceCostData[i].name;
                        resourceCost.tempSeasonId = this.resourceCostData[i].tempSeasonId;
                        resourceCost.unitFare = this.resourceCostData[i].trsResourceCosts[j].unitFare;
                        resourceCost.adultFare = this.resourceCostData[i].trsResourceCosts[j].adultFare;
                        resourceCost.teenFare = this.resourceCostData[i].trsResourceCosts[j].teenFare;
                        resourceCost.childFare = this.resourceCostData[i].trsResourceCosts[j].childFare;
                        resourceCost.infantFare = this.resourceCostData[i].trsResourceCosts[j].infantFare;
                        resourceCost.dayValues = days;
                        resourceCost.dayArray = daysArr;
                        resourceCost.dayKeys = this.resourceCostData[i].trsResourceCosts[j].days;
                        resourceCost.isEdit = false;
                        this.traResourceCosts.push(resourceCost);
                        // this.resourceCosts.splice(0,0,resourceCost);
                    }
                }
                if (this.resourceCostData[i].genResourceCosts.length > 0) {
                    for (var j = 0; j < this.resourceCostData[i].genResourceCosts.length; j++) {
                        var days = this.getDays(this.resourceCostData[i].genResourceCosts[j].days);
                        var daysArr = this.getDaysArr(this.resourceCostData[i].genResourceCosts[j].days);
                        tempCostId++;
                        this.resourceCostData[i].genResourceCosts[j].tempCostId = tempCostId;
                        var resourceCost = new GenResourceCostSetup();
                        resourceCost.tempCostId = tempCostId;
                        // resourceCost.resourceType = this.selectedResourceTypes.filter(value => value && value.code === this.resourceCostData[i].genResourceCosts[j].resourceType)[0];
                        resourceCost.resourceType = this.filterObject(this.resourceCostData[i].genResourceCosts[j].resourceType, this.selectedResourceTypes.length > 0 ? this.selectedResourceTypes : this.resourceTypes);
                        // resourceCost.profileType = this.selectedProfileTypes.filter(value => value && value.code === this.resourceCostData[i].genResourceCosts[j].profileType)[0];
                        resourceCost.profileType = this.filterObject(this.resourceCostData[i].genResourceCosts[j].profileType, this.selectedProfileTypes);
                        resourceCost.seasonName = this.resourceCostData[i].name;
                        resourceCost.tempSeasonId = this.resourceCostData[i].tempSeasonId;
                        resourceCost.currency = this.resourceCostData[i].genResourceCosts[j].currency;
                        resourceCost.costingType = this.filterObject(this.resourceCostData[i].genResourceCosts[j].costingType, this.costingTypeList);
                        resourceCost.unitFare = this.resourceCostData[i].genResourceCosts[j].unitFare;
                        resourceCost.adultFare = this.resourceCostData[i].genResourceCosts[j].adultFare;
                        resourceCost.teenFare = this.resourceCostData[i].genResourceCosts[j].teenFare;
                        resourceCost.childFare = this.resourceCostData[i].genResourceCosts[j].childFare;
                        resourceCost.infantFare = this.resourceCostData[i].genResourceCosts[j].infantFare;
                        resourceCost.dayValues = days;
                        resourceCost.dayArray = daysArr;
                        resourceCost.dayKeys = this.resourceCostData[i].genResourceCosts[j].days;
                        resourceCost.isEdit = false;
                        this.genResourceCosts.push(resourceCost);
                        // this.resourceCosts.splice(0,0,resourceCost);
                    }
                }
                if (this.resourceCostData[i].roomResourceCosts.length > 0) {
                    for (var j = 0; j < this.resourceCostData[i].roomResourceCosts.length; j++) {
                        var days = this.getDays(this.resourceCostData[i].roomResourceCosts[j].days);
                        var daysArr = this.getDaysArr(this.resourceCostData[i].roomResourceCosts[j].days);
                        tempCostId++;
                        this.resourceCostData[i].roomResourceCosts[j].tempCostId = tempCostId;
                        var resourceCost = new RoomResourceCostSetup();
                        resourceCost.tempCostId = tempCostId;
                        resourceCost.seasonName = this.resourceCostData[i].name;
                        resourceCost.tempSeasonId = this.resourceCostData[i].tempSeasonId;
                        resourceCost.currency = this.resourceCostData[i].roomResourceCosts[j].currency;
                        resourceCost.single = this.resourceCostData[i].roomResourceCosts[j].single;
                        resourceCost.twin = this.resourceCostData[i].roomResourceCosts[j].twin;
                        resourceCost.extraBed = this.resourceCostData[i].roomResourceCosts[j].extraBed;
                        resourceCost.nights = this.resourceCostData[i].roomResourceCosts[j].nights;
                        resourceCost.roomType = this.resourceCostData[i].roomResourceCosts[j].roomType;
                        resourceCost.boardBasis = this.resourceCostData[i].roomResourceCosts[j].boardBasis;
                        resourceCost.unitFare = this.resourceCostData[i].roomResourceCosts[j].unitFare;
                        resourceCost.adultFare = this.resourceCostData[i].roomResourceCosts[j].adultFare;
                        resourceCost.teenFare = this.resourceCostData[i].roomResourceCosts[j].teenFare;
                        resourceCost.childFare = this.resourceCostData[i].roomResourceCosts[j].childFare;
                        resourceCost.infantFare = this.resourceCostData[i].roomResourceCosts[j].infantFare;
                        resourceCost.costingType = this.resourceCostData[i].roomResourceCosts[j].costingType;
                        resourceCost.dayValues = days;
                        resourceCost.dayArray = daysArr;
                        resourceCost.dayKeys = this.resourceCostData[i].roomResourceCosts[j].days;
                        resourceCost.isEdit = false;
                        this.roomResourceCosts.push(resourceCost);
                        // this.resourceCosts.splice(0,0,resourceCost);
                    }
                }
            }
        }
    };
    // this.dataStore.get(DataKey.resourceTypes);
    SupplierCostSetupComponent.prototype.filterObject = function (code, objArray) {
        if (objArray && objArray.length > 0) {
            for (var i = 0; i < objArray.length; i++) {
                if (objArray[i]['code'] === code) {
                    return objArray[i];
                }
            }
            return null;
        }
        else {
            return code;
        }
    };
    SupplierCostSetupComponent.prototype.setTempIdsToSeasons = function () {
        var initId = 1;
        for (var i = 0; i < this.resourceCostData.length; i++) {
            var season = this.resourceCostData[i];
            season.tempSeasonId = initId++;
        }
    };
    SupplierCostSetupComponent.prototype.getDays = function (data) {
        var days = '';
        var _loop_1 = function (i) {
            var dayArray = this_1.daysOfWeek.find(function (day) { return day.key.toString() === data.charAt(i); });
            if (dayArray && dayArray.value) {
                days = days.concat(',' + dayArray);
            }
        };
        var this_1 = this;
        for (var i = 0; i < data.length; i++) {
            _loop_1(i);
        }
        if (days.startsWith(',')) {
            days = days.replace(',', '');
        }
        return days;
    };
    SupplierCostSetupComponent.prototype.getDaysArr = function (data) {
        var daysArr = [];
        var _loop_2 = function (i) {
            var dayArray = this_2.daysOfWeek.find(function (day) { return day.key.toString() === data.charAt(i); }).value;
            daysArr.push({ key: data.charAt(i), day: dayArray });
        };
        var this_2 = this;
        for (var i = 0; i < data.length; i++) {
            _loop_2(i);
        }
        return daysArr;
    };
    SupplierCostSetupComponent.prototype.getUpdatedResourceCostData = function () {
        var _this = this;
        this.trsCostChangeSub = this.dataStore.get(DataKey.trsResourceCosts).subscribe(function (val) {
            var e_1, _a;
            if (val !== null) {
                _this.traResourceCosts = val;
                var hasInvalidCost = false;
                var _loop_3 = function (data) {
                    if (hasInvalidCost) {
                        return "break";
                    }
                    var resourceCosts = [];
                    resourceCosts = _this.traResourceCosts.filter(function (cost) { return cost.tempSeasonId == data.tempSeasonId; });
                    if (resourceCosts !== null) {
                        data.trsResourceCosts = [];
                        for (var i = 0; i < resourceCosts.length; i++) {
                            if (resourceCosts[i].isInvalid) {
                                hasInvalidCost = true;
                                _this.dataStore.set(DataKey.supplierTrsCostSetupValid, false, true);
                                break;
                            }
                            var trsResourceCost = new TrsResourceCost();
                            trsResourceCost.days = resourceCosts[i].dayKeys;
                            trsResourceCost.infantFare = resourceCosts[i].infantFare;
                            trsResourceCost.childFare = resourceCosts[i].childFare;
                            trsResourceCost.teenFare = resourceCosts[i].teenFare;
                            trsResourceCost.unitFare = resourceCosts[i].unitFare;
                            trsResourceCost.adultFare = resourceCosts[i].adultFare;
                            trsResourceCost.route = resourceCosts[i].route && resourceCosts[i].route.code ?
                                resourceCosts[i].route.code :
                                resourceCosts[i].route;
                            trsResourceCost.transferMode = resourceCosts[i].transferMode && resourceCosts[i].transferMode.code ?
                                resourceCosts[i].transferMode.code :
                                resourceCosts[i].transferMode;
                            trsResourceCost.vehicleModel = resourceCosts[i].vehicleModel && resourceCosts[i].vehicleModel.code ?
                                resourceCosts[i].vehicleModel.code :
                                resourceCosts[i].vehicleModel;
                            trsResourceCost.currency = resourceCosts[i].currency && resourceCosts[i].currency.code ?
                                resourceCosts[i].currency.code :
                                resourceCosts[i].currency;
                            trsResourceCost.costingType = resourceCosts[i].costingType && resourceCosts[i].costingType.code ?
                                resourceCosts[i].costingType.code :
                                resourceCosts[i].costingType;
                            data.trsResourceCosts.push(trsResourceCost);
                        }
                    }
                };
                try {
                    for (var _b = tslib_1.__values(_this.resourceCostData), _c = _b.next(); !_c.done; _c = _b.next()) {
                        var data = _c.value;
                        var state_1 = _loop_3(data);
                        if (state_1 === "break")
                            break;
                    }
                }
                catch (e_1_1) { e_1 = { error: e_1_1 }; }
                finally {
                    try {
                        if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                    }
                    finally { if (e_1) throw e_1.error; }
                }
            }
        });
        this.genCostChangeSub = this.dataStore.get(DataKey.genResourceCosts).subscribe(function (val) {
            var e_2, _a;
            if (val !== null) {
                _this.genResourceCosts = val;
                // this.dataStore.set(DataKey.supplierGenCostSetupValid, true, true);
                _this.selectedProfileTypes = _this.selectedProfileTypes ?
                    _this.selectedProfileTypes :
                    _this.getSelectedProfileTypes();
                var hasInvalidCost = false;
                var _loop_4 = function (data) {
                    if (hasInvalidCost) {
                        return "break";
                    }
                    var resourceCosts = [];
                    resourceCosts = _this.genResourceCosts.filter(function (cost) { return cost.tempSeasonId == data.tempSeasonId; });
                    if (resourceCosts !== null) {
                        data.genResourceCosts = [];
                        var _loop_5 = function (i) {
                            if (resourceCosts[i].isInvalid) {
                                hasInvalidCost = true;
                                _this.dataStore.set(DataKey.supplierGenCostSetupValid, false, true);
                                return "break";
                            }
                            var genResourceCost = new GenResourceCost();
                            genResourceCost.days = resourceCosts[i].dayKeys;
                            genResourceCost.infantFare = resourceCosts[i].infantFare;
                            genResourceCost.childFare = resourceCosts[i].childFare;
                            genResourceCost.teenFare = resourceCosts[i].teenFare;
                            genResourceCost.unitFare = resourceCosts[i].unitFare;
                            genResourceCost.adultFare = resourceCosts[i].adultFare;
                            genResourceCost.currency = resourceCosts[i].currency && resourceCosts[i].currency.code ?
                                resourceCosts[i].currency.code :
                                resourceCosts[i].currency;
                            genResourceCost.costingType = resourceCosts[i].costingType && resourceCosts[i].costingType.code ?
                                resourceCosts[i].costingType.code :
                                resourceCosts[i].costingType;
                            if (resourceCosts[i].profileType.code) {
                                genResourceCost.profileType = resourceCosts[i].profileType.code;
                            }
                            else {
                                var profileType = _this.selectedProfileTypes.filter(function (value) { return value.code === resourceCosts[i].profileType; });
                                genResourceCost.profileType = profileType && profileType.length > 0 ? profileType[0].code : 'ANY';
                            }
                            genResourceCost.resourceType = resourceCosts[i].resourceType.code ?
                                resourceCosts[i].resourceType.code :
                                _this.selectedResourceTypes.filter(function (value) { return value.name ===
                                    resourceCosts[i].resourceType; })[0].code;
                            data.genResourceCosts.push(genResourceCost);
                        };
                        for (var i = 0; i < resourceCosts.length; i++) {
                            var state_3 = _loop_5(i);
                            if (state_3 === "break")
                                break;
                        }
                    }
                };
                try {
                    for (var _b = tslib_1.__values(_this.resourceCostData), _c = _b.next(); !_c.done; _c = _b.next()) {
                        var data = _c.value;
                        var state_2 = _loop_4(data);
                        if (state_2 === "break")
                            break;
                    }
                }
                catch (e_2_1) { e_2 = { error: e_2_1 }; }
                finally {
                    try {
                        if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                    }
                    finally { if (e_2) throw e_2.error; }
                }
            }
        });
        this.roomCostChangeSub = this.dataStore.get(DataKey.roomResourceCosts).subscribe(function (val) {
            var e_3, _a;
            if (val !== null) {
                _this.roomResourceCosts = val;
                var hasInvalidCost = false;
                var _loop_6 = function (data) {
                    if (hasInvalidCost) {
                        return "break";
                    }
                    var resourceCosts = [];
                    resourceCosts = _this.roomResourceCosts.filter(function (cost) { return cost.tempSeasonId === data.tempSeasonId; });
                    if (resourceCosts !== null) {
                        data.roomResourceCosts = [];
                        for (var i = 0; i < resourceCosts.length; i++) {
                            if (resourceCosts[i].isInvalid) {
                                hasInvalidCost = true;
                                _this.dataStore.set(DataKey.supplierRoomCostSetupValid, false, true);
                                break;
                            }
                            var roomResourceCost = new RoomResourceCost();
                            roomResourceCost.days = resourceCosts[i].dayKeys;
                            roomResourceCost.infantFare = resourceCosts[i].infantFare;
                            roomResourceCost.childFare = resourceCosts[i].childFare;
                            roomResourceCost.teenFare = resourceCosts[i].teenFare;
                            roomResourceCost.unitFare = resourceCosts[i].unitFare;
                            roomResourceCost.adultFare = resourceCosts[i].adultFare;
                            roomResourceCost.single = resourceCosts[i].single;
                            roomResourceCost.twin = resourceCosts[i].twin;
                            roomResourceCost.extraBed = resourceCosts[i].extraBed;
                            roomResourceCost.nights = resourceCosts[i].nights;
                            roomResourceCost.boardBasis = resourceCosts[i].boardBasis;
                            roomResourceCost.roomType = resourceCosts[i].roomType;
                            roomResourceCost.currency = resourceCosts[i].currency && resourceCosts[i].currency.code ?
                                resourceCosts[i].currency.code :
                                resourceCosts[i].currency;
                            roomResourceCost.costingType = resourceCosts[i].costingType && resourceCosts[i].costingType.code ?
                                resourceCosts[i].costingType.code :
                                resourceCosts[i].costingType;
                            data.roomResourceCosts.push(roomResourceCost);
                        }
                    }
                };
                try {
                    for (var _b = tslib_1.__values(_this.resourceCostData), _c = _b.next(); !_c.done; _c = _b.next()) {
                        var data = _c.value;
                        var state_4 = _loop_6(data);
                        if (state_4 === "break")
                            break;
                    }
                }
                catch (e_3_1) { e_3 = { error: e_3_1 }; }
                finally {
                    try {
                        if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                    }
                    finally { if (e_3) throw e_3.error; }
                }
            }
        });
    };
    SupplierCostSetupComponent.prototype.getSelectedProfileTypes = function () {
        var profileTypes = [];
        var type = {
            code: 'ANY',
            name: 'Any'
        };
        profileTypes.push(type);
        return profileTypes;
    };
    SupplierCostSetupComponent.prototype.onTransportCostSetupClick = function () {
        var _this = this;
        this.dataStore.get(DataKey.vehicleModels).subscribe(function (data) {
            if (data !== null) {
                _this.vehicleModels = data;
            }
            _this.showTransportCostFocusView();
        });
    };
    SupplierCostSetupComponent.prototype.showTransportCostFocusView = function () {
        var _this = this;
        setTimeout(function () {
            var fabActions = [];
            var navItemsArray = _this.configLoader.configurations.get(TCO.CONF.CONF_NAVIGATION);
            _this.configLoader.configurations.get(TCO.CONF.CONF_FOCUS_VIEW).TRA_COST_SETUP_FOCUS_VIEW.fabAction
                .forEach(function (val) {
                for (var key in navItemsArray) {
                    if (val === navItemsArray[key]['id']) {
                        fabActions.push(navItemsArray[key]);
                    }
                }
            });
            var dataObject = new ModalData(LEVEL.SUCCESS, 'Supplier Transport Cost Setup', null, null, 'grid-focus-view', TransportCostSetupQueueFocusViewComponent, {
                'resourceCosts': _this.traResourceCosts,
                'supplierCostSetup': _this.resourceCostData,
                'vehicleModels': _this.vehicleModels,
                'currencyList': _this.currencyList,
                'costingTypeList': _this.costingTypeList,
                'daysOfWeek': _this.daysOfWeek,
                'transferModes': _this.transferModes,
                'routes': _this.routes,
                'resourceTypes': _this.resourceTypes,
                'selectedResourceTypes': _this.selectedResourceTypes
            }, { 'label': 'close' }, '', '', fabActions, '', (_this.supplier && _this.supplier.code && _this.supplier.name) ?
                (_this.supplier.code + ' | ' + _this.supplier.name) :
                '');
            _this.focusviewCloseSubject = _this.dataStore.get(DataKey.supplierCostFocusViewClose).subscribe(function (c) {
                if (c) {
                }
            });
            _this.focusViewService.confirm(dataObject).subscribe(function (res) {
                if (res) {
                    console.log(res);
                }
            });
        }, 0);
    };
    SupplierCostSetupComponent.prototype.onGenericCostSetupClick = function () {
        var _this = this;
        var genericResourceTypes = this.selectedResourceTypes.filter(function (value) { return value && value.resourceCategory !==
            'transport'; });
        // let genCostingTypes = this.costingTypeList.filter(value => value.category !== 'DISTANCE');
        setTimeout(function () {
            var fabActions = [];
            var navItemsArray = _this.configLoader.configurations.get(TCO.CONF.CONF_NAVIGATION);
            _this.configLoader.configurations.get(TCO.CONF.CONF_FOCUS_VIEW).GEN_COST_SETUP_FOCUS_VIEW.fabAction
                .forEach(function (val) {
                for (var key in navItemsArray) {
                    if (val === navItemsArray[key]['id']) {
                        fabActions.push(navItemsArray[key]);
                    }
                }
            });
            var dataObject = new ModalData(LEVEL.SUCCESS, 'Supplier Generic Cost Setup', null, null, 'grid-focus-view', GenericCostSetupFocusViewComponent, {
                'resourceCosts': _this.genResourceCosts,
                'supplierCostSetup': _this.resourceCostData,
                'selectedResourceTypes': genericResourceTypes,
                'selectedProfileTypes': _this.selectedProfileTypes,
                'currencyList': _this.currencyList,
                'costingTypeList': _this.costingTypeList,
                'daysOfWeek': _this.daysOfWeek,
                'defaultProfileType': _this.defaultProfileType
            }, { 'label': 'close' }, '', '', fabActions, '', (_this.supplier && _this.supplier.code && _this.supplier.name) ?
                (_this.supplier.code + ' | ' + _this.supplier.name) :
                '');
            _this.focusviewCloseSubject = _this.dataStore.get(DataKey.supplierCostFocusViewClose).subscribe(function (c) {
                if (c) {
                }
            });
            _this.focusViewService.confirm(dataObject).subscribe(function (res) {
                if (res) {
                    console.log(res);
                }
            });
        }, 0);
    };
    SupplierCostSetupComponent.prototype.onRoomCostSetupClick = function () {
        var _this = this;
        var roomTypes = this.supplier.rooms;
        setTimeout(function () {
            var fabActions = [];
            var navItemsArray = _this.configLoader.configurations.get(TCO.CONF.CONF_NAVIGATION);
            _this.configLoader.configurations.get(TCO.CONF.CONF_FOCUS_VIEW).ROOM_COST_SETUP_FOCUS_VIEW.fabAction
                .forEach(function (val) {
                for (var key in navItemsArray) {
                    if (val === navItemsArray[key].id) {
                        fabActions.push(navItemsArray[key]);
                    }
                }
            });
            var dataObject = new ModalData(LEVEL.SUCCESS, 'Supplier Room Cost Setup', null, null, 'grid-focus-view', RoomCostSetupFocusViewComponent, {
                resourceCosts: _this.roomResourceCosts,
                supplierCostSetup: _this.resourceCostData,
                currencyList: _this.currencyList,
                costingTypeList: _this.costingTypeList,
                daysOfWeek: _this.daysOfWeek,
                rooms: roomTypes
            }, { label: 'close' }, '', '', fabActions, '', (_this.supplier && _this.supplier.code && _this.supplier.name) ?
                (_this.supplier.code + ' | ' + _this.supplier.name) :
                '');
            _this.focusviewCloseSubject = _this.dataStore.get(DataKey.supplierCostFocusViewClose).subscribe(function (c) {
                if (c) {
                }
            });
            _this.focusViewService.confirm(dataObject).subscribe(function (res) {
                if (res) {
                    console.log(res);
                }
            });
        }, 0);
    };
    SupplierCostSetupComponent.prototype.closeModal = function () {
        if (this.modalService.close()) {
            this.modalService.close().subscribe(function (res) {
            });
        }
    };
    SupplierCostSetupComponent.prototype.confirmModal = function (data) {
        this.modalService.confirm(data).subscribe(function (res) {
        });
    };
    SupplierCostSetupComponent.prototype.onSeasonSetupClick = function () {
        var _this = this;
        setTimeout(function () {
            var fabActions = [];
            var navItemsArray = _this.configLoader.configurations.get(TCO.CONF.CONF_NAVIGATION);
            _this.configLoader.configurations.get(TCO.CONF.CONF_FOCUS_VIEW).SEASON_SETUP_FOCUS_VIEW.fabAction
                .forEach(function (val) {
                for (var key in navItemsArray) {
                    if (val === navItemsArray[key]['id']) {
                        fabActions.push(navItemsArray[key]);
                    }
                }
            });
            var dataObject = new ModalData(LEVEL.SUCCESS, 'Season Setup', null, null, 'season-setup-focus-view', SeasonSetupFocusViewComponent, { resourceCostData: _this.resourceCostData }, { 'label': 'close' }, '', '', fabActions, '', (_this.supplier && _this.supplier.code && _this.supplier.name) ?
                (_this.supplier.code + ' | ' + _this.supplier.name) :
                '');
            _this.focusviewCloseSubject = _this.dataStore.get(DataKey.supplierCostFocusViewClose).subscribe(function (c) {
                if (c) {
                }
            });
            _this.focusViewService.confirm(dataObject).subscribe(function (res) {
                if (res) {
                    console.log(res);
                }
            });
        }, 0);
    };
    SupplierCostSetupComponent.prototype.addDefaultProfileType = function (typeList) {
        var _this = this;
        return !typeList.find(function (value) { return value.code === _this.defaultProfileType.code; }) ? typeList.push(this.defaultProfileType) : typeList;
    };
    SupplierCostSetupComponent.prototype.ngOnDestroy = function () {
        if (this.trsCostChangeSub != null) {
            this.trsCostChangeSub.unsubscribe();
        }
        if (this.genCostChangeSub != null) {
            this.genCostChangeSub.unsubscribe();
        }
        if (this.roomCostChangeSub != null) {
            this.roomCostChangeSub.unsubscribe();
        }
    };
    return SupplierCostSetupComponent;
}());
export { SupplierCostSetupComponent };
