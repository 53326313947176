import { Injectable } from '@angular/core';
import { TcApiError } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-api-error';
import { TcErrorType } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-error';
import { TcHttpError } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-http-error';
import { TC } from '@tc-core/util';
import { throwError } from 'rxjs';
import { ErrorProcessorService } from './error-processor-service.service';

@Injectable()
export class ErrorHandlerService {

    constructor(
        private errorProcessor: ErrorProcessorService
    ) {}

    public handleError(body: any, endpointId: string) {
        if (body && body.status && body.status.code === TC.ErrorCodes.ERROR) {
            if (body.error) {
                this.errorProcessor.processBackendDmcError(body.error);
            }
        } else if (body && body.status && body.status.code === TC.ErrorCodes.WARNING) {
            if (body.error) {
                this.errorProcessor.processBackendDmcError(body.error);
            }
        }
    }

    public handleHttpError(error: any, endpointId: string) {
        if (error && error.error && error.error.error) {
            this.errorProcessor.processBackendDmcError(error.error.error);
        }  else {
            this.errorProcessor.processBackendError(error.status);
        }
        // throw throwError(error);
        return throwError(error);
    }
}
