import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { ConfigLoader } from '@tc-core/util/framework';
import { Observable } from 'rxjs';
import { DocumentQueueSearchCriteria } from '../../../models/criteria/document-queue-search-criteria';
import { DocumentQueueGroup } from '../../../models/criteria/DocumentQueueGroup';
import { OPResponseWrapper } from '../../../models/response/op-response-wrapper';
import { DateTimeProcessor } from '../../business-utils/time-zone/date-time-processor';
import { DMCQueryParamsService } from '../../util/framework/dmc-query-params.service';
import { RequestService } from '../../util/framework/request.service';
import { UrlPaths } from '../url-paths';
import {Subject} from "rxjs/Subject";
import {TaskStatus} from "../../../models/background/task-status";

@Injectable({
    providedIn: 'root'
})
export class DocumentQueueService {
    datePipe = new DatePipe('en-US');
    simpleDateFormat = 'dd-MM-yyyy';
    simpleTimeFormat = 'h:mm a';

    EMPTY_STRING = '--';

    pipe = new DatePipe('en-US');

    private subject = new Subject<any>();
    public manualDocsToUi: Observable<string> = this.subject.asObservable();

    constructor(
        private configLoader: ConfigLoader,
        private requestService: RequestService,
        private queryParamsService: DMCQueryParamsService
    ) {
    }

    searchDocumentQueues(criteria: DocumentQueueSearchCriteria): Observable<OPResponseWrapper<any>> {
        DateTimeProcessor.processDocumentQueueSearchCriteriaDateTimes(criteria);
        const reqPrams = this.queryParamsService.asQueryParams(criteria);
        return this.requestService.get(
            UrlPaths.OP_DOCUMENT_URL_KEY,
            [UrlPaths.OP_DOCUMENT_VERSION, UrlPaths.RULE_BASED_DOCUMENTS],
            reqPrams
        );
    }

    patchDispatchEntry(documentQueueGroupList: DocumentQueueGroup[]): Observable<OPResponseWrapper<any>> {
        return this.requestService.post(
            UrlPaths.OP_DOCUMENT_URL_KEY,
            [UrlPaths.OP_DOCUMENT_VERSION, UrlPaths.RULE_BASED_DOCUMENTS, UrlPaths.DOCUMENT_QUEUE_ENTRIES],
            documentQueueGroupList
        );
    }

    sendTaskStatus(taskStatus: TaskStatus) {
        this.subject.next({ TaskStatus: taskStatus });
    }

    clearTaskStatus() {
        this.subject.next();
    }

    getTaskStatus(): Observable<any> {
        return this.subject.asObservable();
    }
}

