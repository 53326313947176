<form [formGroup]="noteInfo">
    <div mat-dialog-content>
        <div style="width: 500px;">

            <mat-form-field>
                <input
                        matInput
                        [placeholder]="'Note'"
                        formControlName="note"
                        #note
                >
            </mat-form-field>

        </div>

        <div class="rs-footer-section">
            <button
                    class="tc-button-secondary mat-button rs-footer-section__button"
                    (click)="closePopUp()"
                    [disabled]="false">
                {{'Cancel' | translate}}
            </button>
            <button
                    class="tc-button-primary mat-button rs-footer-section__button"
                    (click)="setNotes()"
                    [disabled]="!note.value">
                {{'Add' | translate}}
            </button>
        </div>
    </div>
</form>
