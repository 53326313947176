import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TcApiError } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-api-error';
import { TcHttpError } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-http-error';
import { LEVEL, ModalData } from '@tc-core/model/it/codegen/ui/framework/modal-data';
import { Pager } from '@tc-core/model/it/codegen/ui/framework/pager';
import { Sorter } from '@tc-core/model/it/codegen/ui/framework/sorter';
import { SummaryCardData } from '@tc-core/model/it/codegen/ui/framework/summary-card-data';
import { SorterService } from '@tc-core/service/sorters/sorter.service';
import { TC } from '@tc-core/util/constants';
import { EventManager } from '@tc-core/util/framework';
import { ConfigLoader } from '@tc-core/util/framework/config-loader.service';
import { UserJourneyManager } from '@tc-core/util/framework/user-journey-manager.service';
import { CommonHelper } from '@tc-core/util/helpers/common-helper.service';
import { AutoScrollService } from '@tc-core/util/ui/auto-scroll.service';
import { SpinnerService } from '@tc-core/util/ui/spinner.service';
import { ModalService } from '@tc/modal/modal.service';
import { Observable, Subject, Subscription } from 'rxjs';
import { BackgroundTaskEvent } from '../../../models/background/background-task-event';
import { DocumentQueueGroup } from '../../../models/criteria/DocumentQueueGroup';
import { BookingItemExportComponent } from '../../booking-item-export/booking-item-export.component';
import { TCO } from '../../../constants';
import { Booking } from '../../../models/reservation-v2/booking';
import { BookingImportSummary } from '../../../models/reservation-v2/booking-import-summary';
import { BookingSummary } from '../../../models/reservation-v2/booking-summary';
import { BookingSearchCriteria } from '../../../models/reservation-v2/criteria/booking-search-criteria';
import { OPResponseWrapper } from '../../../models/response/op-response-wrapper';
import { Supplier } from '../../../models/supplier/supplier';
import { DocumentService } from '../../../services/backend-consumers/documents/document.service';
import { DataHandlerService } from '../../../services/backend-consumers/master-data-handler-service/data-handler.service';
import { BookingManagementService } from '../../../services/backend-consumers/reservation-v2/booking-management.service';
import { ReservationV2ManagementService } from '../../../services/backend-consumers/reservation-v2/reservation-v2-management.service';
import { RootService } from '../../../services/util/core-services/root.service';
import { DataKey, DataStoreService } from '../../../services/util/framework/data-store.service';
import { DMCQueryParamsService } from '../../../services/util/framework/dmc-query-params.service';
import { ResponseUtil } from '../../../services/util/response/response-util.service';
import { ContentSummaryCardService } from '../../../services/util/ui/content-summary-card.service';
import { BookingImportSummaryComponent } from '../booking-import-summary/booking-import-summary.component';
import { BookingUtilService } from '../booking-util.service';
import { TcErrorType } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-error';
import { DMCCommon } from '../../../services/util/common/dmc-common';

@Component({
    selector: 'tc-booking-search-results',
    templateUrl: './booking-search-results.component.html'
})
export class BookingSearchResultsComponent implements OnInit, OnDestroy {

    tBookingSearchLineConfig: any;

    heading = '';

    public pager: Pager;
    public defaultPageResultCount = 10;
    selectionIndex = '';
    tPagination: any;
    fetchedReservationsCount = 0;
    dataFromMoreClick = false;
    paginatorHasMore = true;

    resultCount = 0;

    searchCriteria = new BookingSearchCriteria();

    sorters: Sorter[];

    searchResults: BookingSummary[];
    contentCardDataList: SummaryCardData[] = [];
    selectedSearchSummary: SummaryCardData = null;
    detailedBooking: Booking;
    supplier: Supplier;

    navConfig: any[] = [];
    public showEditBtn = true;
    public showEditAsNew = true;
    pageOptionsAvailability: any;

    private searchCriteriaSub: Subscription = new Subscription();
    private searchResultsCollectedSub: Subscription = new Subscription();
    private searchResultsFromServiceSub: Subscription = new Subscription();
    private bookingItemSheetImportSubscribe: Subscription = new Subscription();
    private popupCloseModel: Subscription = new Subscription();
    private supplierByIdSub: Subscription = new Subscription();
    private failedItemSheetExportSubscribe: Subscription = new Subscription();

    tTemplateTypes: any[] = [];

    constructor(
        private configLoader: ConfigLoader,
        private dataStore: DataStoreService,
        public sorterService: SorterService,
        private summaryCardService: ContentSummaryCardService,
        private spinnerService: SpinnerService,
        private userJourneyManager: UserJourneyManager,
        private dataHandlerService: DataHandlerService,
        private commonHelper: CommonHelper,
        private activatedRoute: ActivatedRoute,
        private rootService: RootService,
        private queryParamService: DMCQueryParamsService,
        private autoScrollService: AutoScrollService,
        private bookingManagementService: BookingManagementService,
        private bookingUtil: BookingUtilService,
        private reservationV2ManagementService: ReservationV2ManagementService,
        private em: EventManager,
        private modalService: ModalService,
        private documentService: DocumentService,
        private common: DMCCommon
    ) {
    }

    ngOnInit() {
        this.tBookingSearchLineConfig = this.configLoader.configurations.get(TCO.CONF.CONF_BOOKING_SEARCH_RESULTS_CONFIG);
        this.tPagination = this.configLoader.configurations.get(TCO.CONF.CONF_PAGINATION_CONFIG).BOOKING_SEARCH;
        this.tTemplateTypes = this.configLoader.configurations.get(TCO.CONF.CONF_BOOKING_IMPORT_CONFIG).template_types;

        this.setupPaginator();
        this.getSearchCriteria();
        this.subscribeSearchResults();
        this.getSearchResults();
        this.createSorters();

        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'EXPORT').subscribe(e => {
            this.importBookingItemSheet();
        });
        this.userJourneyManager.canProceed.next(true);
        this.pageOptionsAvailability = this.configLoader.configurations.get(TCO.CONF.CONF_PAGE_OPTION_AVAILABILITY).BOOKING_MANAGEMENT;
        this.setupPageOptions();
        //
        // this.supplier = null;
        // this.supplierByIdSub = this.dataStore.get(DataKey.supplier).subscribe(res => {
        //     if (this.commonHelper.dataValidity(res) && res.data && res.data.length > 0) {
        //         if (this.supplierByIdSub) {
        //             this.supplierByIdSub.unsubscribe();
        //         }
        //         this.spinnerService.hide();
        //         this.supplier = ResponseUtil.getFirstData(res);
        //         console.log(this.supplier);
        //     } else if (res instanceof TcApiError || res instanceof TcHttpError) {
        //         this.spinnerService.hide();
        //     }
        // });
    }

    private setupPaginator() {
        this.defaultPageResultCount = this.tPagination.defaultPageResultCount;
        this.pager = new Pager(0, this.defaultPageResultCount);
    }

    public onPage(pageNumber: number) {
        this.pager.currPage = pageNumber;
        this.selectionIndex = (this.pager.currPage * this.pager.itemsPerPage).toString();
    }

    public onSelection(event) {
        this.selectionIndex = event;
    }

    public onMore() {
        this.dataFromMoreClick = true;
        if (this.tPagination && this.tPagination.defaultPageResultCount && this.tPagination.fetchingPages) {
            this.searchCriteria.size = this.tPagination.fetchingPages * this.tPagination.defaultPageResultCount;
            this.searchCriteria.start = this.fetchedReservationsCount;
            this.retrieveAndCollectSearchResults(this.searchCriteria);
        }
    }

    private setPageZero() {
        if (this.pager) {
            if (!this.dataFromMoreClick) {
                this.pager.currPage = 0;
            } else {
                this.dataFromMoreClick = false;
            }
        } else {
            this.pager = new Pager(0, this.defaultPageResultCount);
        }
    }

    subscribeSearchResults() {
        this.searchResultsFromServiceSub = this.dataStore.get(DataKey.bookingSummarySearchResultsFromService)
                                               .subscribe((result: OPResponseWrapper<BookingSummary>) => {
                                                   this.resultCount = 0;
                                                   if (result) {
                                                       this.resultCount = ResponseUtil.getTotalCount(
                                                           result);
                                                       const dataList = ResponseUtil.getDataArray(result);
                                                       this.dataStore.set(
                                                           DataKey.bookingSummarySearchResults,
                                                           dataList,
                                                           true
                                                       );
                                                   }
                                               });
    }

    retrieveAndCollectSearchResults(searchCriteria: BookingSearchCriteria) {
        this.bookingManagementService.searchBookingSummariesOnMore(JSON.parse(JSON.stringify(searchCriteria)))
            .subscribe(result => {
                this.resultCount = 0;
                if (result) {
                    this.resultCount = ResponseUtil.getTotalCount(
                        result);
                    const dataList = this.searchResults.concat(
                        ResponseUtil.getDataArray(
                            result));
                    this.dataStore.set(
                        DataKey.bookingSummarySearchResults,
                        dataList,
                        true
                    );
                }
            });
    }

    getSearchCriteria() {
        this.searchCriteriaSub = this.dataStore.get(DataKey.bookingSummarySearchCriteria)
                                     .subscribe((data) => {
                                         if (data !== null) {
                                             this.searchCriteria = data;
                                             this.searchResults = [];
                                         }
                                     });
    }

    createSorters() {
        this.sorterService.sorters = [];
        this.sorters = [];
        const sorterMap = new Map<string, Sorter>();
        this.tBookingSearchLineConfig.config.placeHolderConfig.forEach(config => {
            if (config && config.sort) {
                const sorter = this.sorterService.createSorter(config);
                const id = config._id;
                sorterMap.set(id, sorter);
                this.sorters.push(sorter);
            }
        });
    }

    public onSort(event) {
        this.sorterService.sorter = event;
    }

    getSearchResults() {
        this.fetchedReservationsCount = 0;
        this.searchResultsCollectedSub = this.dataStore.get(DataKey.bookingSummarySearchResults)
                                             .subscribe(result => {
                                                 this.selectionIndex = '';
                                                 this.searchResults = [];
                                                 if (result != null) {
                                                     this.spinnerService.hide();
                                                 }
                                                 if (result) {
                                                     this.searchResults = result;
                                                     this.fetchedReservationsCount = this.searchResults.length;
                                                     if (this.fetchedReservationsCount > 0) {
                                                     }
                                                     this.getBookingCardList();
                                                 } else {
                                                     this.contentCardDataList = [];
                                                     this.heading = '';
                                                 }
                                                 this.userJourneyManager.canProceed.next(true);
                                             });
    }

    getBookingCardList() {
        const bookingSummariesData: BookingSummary[] = this.searchResults;
        // generate object list as required here
        const items = [];
        for (const bookingSummary of bookingSummariesData) {
            const item = {
                items: undefined,
                passengers: undefined
            };
            for (const k in bookingSummary) {
                if (bookingSummary.hasOwnProperty(k)) {
                    item[k] = bookingSummary[k];
                }
            }
            item.items = 'No Of Items: ' + bookingSummary.noOfItems;
            item.passengers = 'No of Passengers: ' + bookingSummary.noOfPassengers;
            items.push(item);
        }

        this.contentCardDataList = this.getSearchContentCards(items);
    }

    getSearchContentCards(dataArray: any[]) {
        const contentCardList = this.summaryCardService.getContentCardsByConfig(
            this.tBookingSearchLineConfig,
            dataArray
        );
        this.spinnerService.hide();
        this.heading = this.resultCount + ' result' + (this.resultCount > 1 ? 's' : '') + ' found.';

        this.paginatorHasMore = this.resultCount !== contentCardList.length;

        console.log(this.heading);
        this.setPageZero();
        return contentCardList;
    }

    public onSelect(event: SummaryCardData) {
        this.detailedBooking = null;
        this.selectedSearchSummary = event;
        const id = event.data.value.id;
        if (id !== null) {
            this.bookingManagementService.getById(id)
                .subscribe(
                    res => {
                        if (this.commonHelper.dataValidity(res)) {
                            this.detailedBooking = ResponseUtil.getFirstData(res);
                            const selectedBooking = {
                                bookingId: this.detailedBooking.bookingId
                            };
                            const parameters = this.queryParamService.getQueryParamsArray(selectedBooking);
                            this.rootService.setDataToLocalStorage(
                                TCO.AppData.BOOKING,
                                selectedBooking,
                                true,
                                parameters
                            );
                        }
                    }
                );
        }
    }

    public onEdit(event: Booking) {
        this.userJourneyManager.goForKey('GO_TO_EDIT_BOOKING');
        event.isEditTriggered = true;
        this.dataStore.set(DataKey.selectedBookingForEdit, event, true);
    }

    public onCardScroll(event: number) {
        setTimeout(() => {
            this.autoScrollService.scrollToID(
                'clientDetailExp_' + event, 'main-scroll-container');
        }, 0);
    }

    public onEditAsNew(event: Booking) {
        this.clearIdsInBooking(event);
        this.userJourneyManager.goForKey('GO_TO_CREATE_BOOKING');
        this.dataStore.set(DataKey.selectedBookingForEditAsNew, event, true);
    }

    /**
     * IMPORTANT : clear all the existing ids that needs to be saved as new entities
     *
     */
    clearIdsInBooking(booking: Booking) {
        // clear ids in booking
        booking.bookingId = null;
        booking.bookingIdCombined = null;
        booking.id = 0;

        let itemNumber = 1;
        if (booking.bookingItems) {
            for (const bookingItem of booking.bookingItems) {
                bookingItem.id = 0;
                let type: 'gen' | 'trs' | 'accom' = 'trs';
                if (bookingItem.genBookingItem) {
                    type = 'gen';
                    bookingItem.genBookingItem.id = 0;
                    if (bookingItem.genBookingItem && bookingItem.genBookingItem.hotelRoomType) {
                        type = 'accom';
                    }
                } else if (bookingItem.trsBookingItem) {
                    type = 'trs';
                    bookingItem.trsBookingItem.id = 0;
                }
                this.bookingUtil.setBookingReferenceId(booking, bookingItem, type, itemNumber++);

                if (bookingItem.bookingPassengers) {
                    bookingItem.bookingPassengers.forEach(p => {
                        p.id = 0;
                        p.bookingId = 0;
                    });
                }
                if (bookingItem.bookingClient) {
                    bookingItem.bookingClient.id = 0;
                }
                if (bookingItem.bookingNotes) {
                    bookingItem.bookingNotes.forEach(n => n.bookingItemId = 0);
                }
            }
        }
    }

    public ngOnDestroy(): void {
        if (this.searchCriteriaSub) {
            this.searchCriteriaSub.unsubscribe();
        }
        if (this.searchResultsCollectedSub) {
            this.searchResultsCollectedSub.unsubscribe();
        }
        if (this.searchResultsFromServiceSub) {
            this.searchResultsFromServiceSub.unsubscribe();
        }
        if (this.bookingItemSheetImportSubscribe) {
            this.bookingItemSheetImportSubscribe.unsubscribe();
        }
        if (this.popupCloseModel) {
            this.popupCloseModel.unsubscribe();
        }
        this.em.removeEvent('EXPORT');
    }

    private importBookingItemSheet() {
        this.dataStore.set(DataKey.popupClose, null);
        this.dataStore.set(DataKey.bookingItemFileToUpload, null);

        let defaultType = null;
        if (this.tTemplateTypes) {
            for (const tTemplateType of this.tTemplateTypes) {
                if (tTemplateType.default) {
                    defaultType = tTemplateType;
                }
            }
        }

        const templateTypes = this.tTemplateTypes;

        const dataObject = new ModalData(
            LEVEL.SUCCESS,
            'Upload Booking Items',
            true,
            true,
            'modal-basic--alt modal-basic--fixed-size',
            BookingItemExportComponent,
            {
                templateTypes,
                templateType: defaultType
            }
        );
        dataObject.disableClose = true;
        this.confirmModal(dataObject);

        this.popupCloseModel = this.dataStore.get(DataKey.popupClose).subscribe((data) => {
            if (data != null) {
                this.closeModal();
            }
        });
        this.bookingItemSheetImportSubscribe = this.dataStore.get(DataKey.bookingItemFileToUpload).subscribe((data) => {
            if (data != null) {
                this.uploadBookingItemSheet(data);
                this.bookingItemSheetImportSubscribe.unsubscribe();
            }
        });
    }

    private confirmModal(data: any): void {
        this.modalService.confirm(data).subscribe((res) => {
        });
    }

    closeModal() {
        if (this.modalService.close()) {
            this.modalService.close().subscribe(
                (res) => {
                }
            );
        }
    }

    private uploadBookingItemSheet(data: { data, type: { code, name }, date: Date }) {
        this.dataStore.set(DataKey.popupClose, null);
        if (data && data.type && data.type.code) {
            let serviceDateTimeStamp = '';
            if (data.date) {
                serviceDateTimeStamp = data.date.toISOString();
            }
            let colDefConfig = this.configLoader.configurations.get(TCO.CONF.CONF_BOOKING_IMPORT_SUMMARY_GRID);
            this.reservationV2ManagementService.uploadBookingItemSheet(data.data, data.type.code, serviceDateTimeStamp)
                .subscribe(
                    res => {
                        const bookingImportSummary : BookingImportSummary = ResponseUtil.getFirstData(res);

                        if(bookingImportSummary && bookingImportSummary.importedBookingItems && bookingImportSummary.importedBookingItems.length > 0 ){
                            console.log(res);
                            const dataObject = new ModalData(
                                LEVEL.SUCCESS,
                                'Upload Booking Items',
                                false,
                                false,
                                'split-passengers-modal',
                                BookingImportSummaryComponent,
                                {
                                    importSummary: bookingImportSummary,
                                    colDefConfig: colDefConfig
                                },
                                {'label': 'close'}
                            );
                            this.confirmModal(dataObject);

                            this.popupCloseModel = this.dataStore.get(DataKey.popupClose).subscribe((data) => {
                                if (data != null) {
                                    this.closeModal();
                                }
                            });

                        }
                        this.spinnerService.hide();
                    },

                    err => {
                        console.log(err);
                        this.spinnerService.hide();
                        this.common.showSnackBar(
                            'Failed to import bookings.',
                            3000,
                            TcErrorType.TYPE.ERROR
                        );
                    }
                );
        }
    }

    private setupPageOptions() {
        if (this.pageOptionsAvailability) {
            this.showEditBtn = this.pageOptionsAvailability.editable;
            this.showEditAsNew = this.pageOptionsAvailability.edit_as_new;
        }
    }
}
