import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ICellEditorParams, ICellRendererParams} from 'ag-grid-community';
import {AutoCompleteEditorComponent} from '../../../../widgets/framework/ag-grid-custom-components/editors/auto-complete-editor/auto-complete-editor.component';
import {DropdownEditorComponent} from '../../../../widgets/framework/ag-grid-custom-components/editors/dropdown-editor/dropdown-editor.component';
import {ActionsComponent} from '../../../../widgets/framework/ag-grid-custom-components/renderers/actions/actions.component';
import {Booking} from '../../../../models/reservation-v2/booking';
import {BookingPassenger} from '../../../../models/reservation-v2/booking-passenger';
import {NumericEditorComponent} from '../../../../widgets/framework/ag-grid-custom-components/renderers/numeric-editor/numeric-editor.component';

@Component({
    selector: 'tc-booking-passengers',
    templateUrl: './booking-passengers.component.html'
})
export class BookingPassengersComponent implements OnInit {

    @Input() booking: Booking;
    @Input() readonly = false;
    @Input() titles: any[] = [];
    @Input() passengerTypes: any[] = [];

    @Output() deletePassenger: EventEmitter<BookingPassenger> = new EventEmitter();
    @Output() changeValidity: EventEmitter<boolean> = new EventEmitter();

    overlayNoRowsTemplate = `<span class="ag-overlay-no-rows-center">No Passengers</span>`;

    gridApi;
    columnDefs;
    defaultColDef = {
        headerValueGetter: (parameters: ICellRendererParams): string => {
            const headerIdentifier = parameters.colDef.headerName;
            if (headerIdentifier) {
                return this.translateService.instant(headerIdentifier);
            }
            return '';
        }
    };

    frameworkComponents = {
        dropDownEditor: DropdownEditorComponent,
        autoCompleteEditor: AutoCompleteEditorComponent,
        numericEditor: NumericEditorComponent,
    };

    PASSENGER_TYPES = [
        {name: 'Adult', code: 'A'},
        {name: 'Child', code: 'C'},
        {name: 'Teen', code: 'T'},
        {name: 'Infant', code: 'I'}
    ];

    constructor(
        private translateService: TranslateService
    ) {
        this.translateService.onLangChange.subscribe(() => {
            this.gridApi.refreshHeader();
        });
    }

    ngOnInit() {
        this.setColDefs();
        this.onValueChange(null);
    }

    setColDefs() {
        this.columnDefs = [
            {
                headerName: 'Title',
                field: 'title',
                maxWidth: 100,
                cellEditorFramework: this.frameworkComponents.autoCompleteEditor,
                editable: !this.readonly,
                cellEditorParams: {
                    rowObject: 'title',
                    returnObject: true,
                    viewKey: 'name',
                    valueKey: 'code',
                    filterItemFn: this.getTitles.bind(this)
                },
                valueSetter: this.onSetTitle
            },
            {
                headerName: 'First Name',
                field: 'firstName',
                flex: 1,
                editable: !this.readonly

            },
            {
                headerName: 'Middle Name',
                field: 'middleName',
                hide: true,
                flex: 1,
                editable: !this.readonly
            },
            {
                headerName: 'Last Name',
                flex: 1,
                editable: !this.readonly,
                field: 'lastName'
            },
            {
                headerName: 'Type',
                field: 'typeName',
                flex: 1,
                editable: !this.readonly,
                cellEditorFramework: this.frameworkComponents.dropDownEditor,
                cellEditorParams: {
                    rowObject: 'typeName',
                    returnObject: true,
                    options: this.PASSENGER_TYPES
                },
                valueSetter: this.onSetType
            },
            {
                headerName: 'Email',
                field: 'email',
                flex: 1,
                editable: !this.readonly
            },
            {
                headerName: 'Contact No.',
                field: 'telephone',
                flex: 1,
                cellEditor: 'numericEditor',
                editable: !this.readonly
            },
            {
                headerName: ' ',
                field: 'actions',
                width: 65,
                cellRendererFramework: ActionsComponent,
                headerClass: 'actions-header',
                cellRendererParams: {
                    actions: [
                        {
                            icon: 'delete',
                            action: this.onDeleteClick.bind(this),
                            tooltip: 'Delete'
                        }
                    ]
                },
                filter: false,
                resizable: false,
                pinned: 'right',
                cellClass: ['actions-column'],
                suppressSizeToFit: true
            }
        ];
    }

    public onGridReady(params: any) {
        this.gridApi = params.api;
        this.gridApi.sizeColumnsToFit();
    }

    public onValueChange(event: any) {
        let allValid = true;
        for (const bookingPassenger of this.booking.bookingPassengers) {
            if (!(bookingPassenger.firstName && bookingPassenger.lastName && bookingPassenger.title && bookingPassenger.type)) {
                allValid = false;
                break;
            }
        }
        this.changeValidity.emit(allValid);
    }

    public getTitles(params: ICellEditorParams, row: BookingPassenger, text: string) {
        if (text) {
            return this.titles.filter(t => t.name.toLowerCase().includes(text.toLowerCase())).slice(0, 10);
        } else {
            return this.titles;
        }
    }

    public onDeleteClick(params: any) {
        if (!this.readonly) {
            this.deletePassenger.emit(params.data);
        }
    }

    public onSetTitle(params) {
        const title = params.newValue;
        if (title && title.code) {
            const passenger: BookingPassenger = params.data;
            passenger.title = title.code;
        }
    }

    public onSetType(params) {
        const type = params.newValue;
        if (type) {
            const passenger: BookingPassenger = params.data;
            passenger.typeName = type.name;
            passenger.type = type.code;
        }
    }

    public updateGrid() {
        if (this.gridApi) {
            this.gridApi.setRowData(this.booking.bookingPassengers);
        } else {
            setTimeout(() => this.updateGrid(), 0);
        }
    }
}
