/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./max-length-terminator.component";
var styles_MaxLengthTerminatorComponent = [];
var RenderType_MaxLengthTerminatorComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_MaxLengthTerminatorComponent, data: {} });
export { RenderType_MaxLengthTerminatorComponent as RenderType_MaxLengthTerminatorComponent };
export function View_MaxLengthTerminatorComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { input: 0 }), (_l()(), i0.ɵeld(1, 16777216, [[1, 3], ["input", 1]], null, 0, "input", [["style", "width: 100%; background: #fcf3f3; border-width: 0px"], ["type", "text"]], [[8, "value", 0], [8, "maxLength", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.value; var currVal_1 = _co.inputMaxLength; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
export function View_MaxLengthTerminatorComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-max-length-terminator", [], null, null, null, View_MaxLengthTerminatorComponent_0, RenderType_MaxLengthTerminatorComponent)), i0.ɵdid(1, 4308992, null, 0, i1.MaxLengthTerminatorComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MaxLengthTerminatorComponentNgFactory = i0.ɵccf("tc-max-length-terminator", i1.MaxLengthTerminatorComponent, View_MaxLengthTerminatorComponent_Host_0, {}, {}, []);
export { MaxLengthTerminatorComponentNgFactory as MaxLengthTerminatorComponentNgFactory };
