import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { TC } from '@tc-core/util/constants';
import { EventManager } from '@tc-core/util/framework/event-manager.service';
import { FocusViewManager } from '@tc-core/util/framework/focus-view-manager.service';
import { UserJourneyManager } from '@tc-core/util/framework/user-journey-manager.service';
import { CalendarRefreshEventType } from '../../../../widgets/framework/cg-calendar/calendar-refresh-event-type';
import { CgCalendarRefreshData } from '../../../../widgets/framework/cg-calendar/cg-calendar-refresh-data';
import { DateRange } from '../../../../widgets/framework/cg-calendar/date-range';
import { RangeGroup } from '../../../../widgets/framework/cg-calendar/range-group';
import { FocusViewService } from '@tc/focus-view/focus-view.service';
import { Season } from '../../../../models/supplier/season';
import { SeasonDateRange } from '../../../../models/supplier/season-date-range';
import { DataKey, DataStoreService } from '../../../../services/util/framework/data-store.service';

@Component({
    selector: 'tc-season-setup-focus-view',
    templateUrl: './season-setup-focus-view.component.html'
})
export class SeasonSetupFocusViewComponent implements OnInit, AfterViewInit {

    @Input() resourceCostData: Season[] = [];
    fromYear = 0;
    toYear = 0;
    rangeGroupsForSeasonCalendar: RangeGroup[] = [];

    constructor(
        private em: EventManager,
        private journeyManager: UserJourneyManager,
        private dataStore: DataStoreService,
        private focusViewManager: FocusViewManager,
        private focusViewService: FocusViewService
    ) { }

    ngOnInit() {
        this.mapToSeasonCalendarRanges();
        this.focusViewManager.canProceed.next(false);
        this.focusViewManager.canProceedState.next(true);
        this.checkToContinue();
        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'CLOSE_FOCUS_VIEW')
            .subscribe(e => {

                this.dataStore.set(DataKey.supplierCostFocusViewClose, null);
                this.dataStore.set(DataKey.supplierCostFocusViewClose, true);

                this.focusViewService.close(true);

                this.mapFromSeasonCalendarRanges();

                this.journeyManager.goForKey('GO_TO_ADD_TRANSFER_SUPPLIER');
            });
    }

    public ngAfterViewInit(): void {
        setTimeout(() => {
            let scrollingElement: Element = document.querySelector('.tc-overlay');
            scrollingElement.scrollTo(0, 0);
        }, 0);
    }

    public onChangeSeasons($event: CgCalendarRefreshData) {
        if ($event.type !== CalendarRefreshEventType.RangeGroupSelect) {
            this.checkToContinue();
        }
    }

    private checkToContinue(): boolean {
        let canContinue = true;
        for (let i = 0; i < this.rangeGroupsForSeasonCalendar.length; i++) {
            let rangeGroup = this.rangeGroupsForSeasonCalendar[i];
            if (rangeGroup.nonRemovable && rangeGroup.dateRanges.length === 0) {
                canContinue = false;
            }
        }
        if (canContinue) {
            this.focusViewManager.canProceed.next(true);
        } else {
            this.focusViewManager.canProceed.next(false);
        }
        return canContinue;
    }

    private mapToSeasonCalendarRanges() {
        // this.rangeGroupsForSeasonCalendar = [];
        for (let i = 0; i < this.resourceCostData.length; i++) {
            let season: Season = this.resourceCostData[i];
            let rangeGroup = new RangeGroup();
            rangeGroup.id = season.tempSeasonId;
            rangeGroup.name = season.name;
            rangeGroup.colorId = season.colorId;

            // make the season un deletable
            if (season.trsResourceCosts && season.trsResourceCosts.length > 0) {
                rangeGroup.nonRemovable = true;
            }

            let dateRanges: DateRange[] = [];
            for (let j = 0; j < season.seasonDateRanges.length; j++) {

                let seasonDateRange: SeasonDateRange = season.seasonDateRanges[j];
                let dateRange = new DateRange();
                dateRange.id = seasonDateRange.dateRangeId;
                dateRange.startDate = new Date(seasonDateRange.startDate);
                dateRange.endDate = new Date(seasonDateRange.endDate);
                dateRanges.push(dateRange);
            }
            rangeGroup.dateRanges = dateRanges;
            this.rangeGroupsForSeasonCalendar.push(rangeGroup);
        }
    }

    private mapFromSeasonCalendarRanges() {
        console.log(this.rangeGroupsForSeasonCalendar);
        this.setTempIdsToNewSeasons();
        for (let i = 0; i < this.rangeGroupsForSeasonCalendar.length; i++) {
            let rangeGroup: RangeGroup = this.rangeGroupsForSeasonCalendar[i];

            let season: Season = null;
            if (rangeGroup.id) {
                season = this.resourceCostData.find(sesonRange => {
                    return sesonRange.tempSeasonId === rangeGroup.id;
                });
            }
            if (!season) {
                season = new Season();
                this.resourceCostData.push(season);
            }
            season.tempSeasonId = rangeGroup.id;
            season.name = rangeGroup.name;
            season.colorId = rangeGroup.colorId;
            season.seasonDateRanges = [];
            for (let j = 0; j < rangeGroup.dateRanges.length; j++) {
                let dateRange = rangeGroup.dateRanges[j];
                let seasonDateRange = new SeasonDateRange();
                seasonDateRange.startDate = dateRange.startDate;
                seasonDateRange.dateRangeId = dateRange.id;
                seasonDateRange.endDate = dateRange.endDate;
                season.seasonDateRanges.push(seasonDateRange);
            }
        }

        this.checkForDeletedSeasons();
        this.checkForEmptySeasonsAndRemove();
        console.log(this.resourceCostData);
    }

    setTempIdsToNewSeasons() {
        let maxId = 0;
        for (let i = 0; i < this.rangeGroupsForSeasonCalendar.length; i++) {
            let rangeGroup: RangeGroup = this.rangeGroupsForSeasonCalendar[i];
            if (rangeGroup.id > maxId) {
                maxId = rangeGroup.id;
            }
        }
        maxId++;
        for (let i = 0; i < this.rangeGroupsForSeasonCalendar.length; i++) {
            let rangeGroup: RangeGroup = this.rangeGroupsForSeasonCalendar[i];
            if (!rangeGroup.id) {
                rangeGroup.id = maxId++;
            }
        }
    }

    checkForDeletedSeasons() {
        for (let i = 0; i < this.resourceCostData.length; i++) {
            let season = this.resourceCostData[i];
            let rangeGroup = this.rangeGroupsForSeasonCalendar.find(group => {
                return group.id === season.tempSeasonId;
            });
            if (!rangeGroup) {
                this.resourceCostData.splice(this.resourceCostData.indexOf(season), 1);
                i--;
            }
        }
    }

    private checkForEmptySeasonsAndRemove() {
        for (let i = 0; i < this.resourceCostData.length; i++) {
            let season = this.resourceCostData[i];
            if (season.seasonDateRanges.length === 0) {
                this.resourceCostData.splice(this.resourceCostData.indexOf(season), 1);
                i--;
            }
        }
    }
}
