import {AfterViewInit, Component, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {AgEditorComponent} from 'ag-grid-angular';
import {IAfterGuiAttachedParams, ICellEditorParams} from 'ag-grid-community';

@Component({
    selector: 'tc-max-length-validator',
    templateUrl: './max-length-validator.component.html'
})
export class MaxLengthValidatorComponent implements OnInit, AgEditorComponent, AfterViewInit {

    public params: any;
    public value: any;
    public fontColor: any = 'black';
    public inputValue: any;
    public inputMaxLength: number;

    @ViewChild('input', {read: ViewContainerRef}) public input: ViewContainerRef;

    constructor() {
    }

    ngOnInit() {
    }

    afterGuiAttached(params?: IAfterGuiAttachedParams): void {
    }

    agInit(params: ICellEditorParams): void {
        this.params = params;
        this.inputMaxLength = params.colDef['maxInputCharactersLimit'];

        this.value = this.params.value;
    }

    getInputValues() {
        this.inputValue = this.input.element.nativeElement.value;
        if (this.inputMaxLength) {
            if (this.inputValue && this.inputValue.length > this.inputMaxLength - 1) {
                this.fontColor = 'red';
            } else {
                this.fontColor = 'black';
            }
        } else {
            console.error('maxInputCharactersLimit not found!');
        }
    }

    focusIn(): void {
    }

    focusOut(): void {
    }

    getFrameworkComponentInstance(): any {
    }

    getValue(): any {
        if (this.inputMaxLength) {
            if (this.inputValue && this.inputValue.length > this.inputMaxLength - 1) {
                this.value = this.params.value;
            } else {
                this.value = this.inputValue;
            }
        } else {
            console.error('maxInputCharactersLimit not found!');
        }
        return this.value;
    }

    isCancelAfterEnd(): boolean {
        return false;
    }

    isCancelBeforeStart(): boolean {
        return false;
    }

    isPopup(): boolean {
        return false;
    }

    ngAfterViewInit(): void {
        setTimeout(() => this.input.element.nativeElement.focus());
    }

}
