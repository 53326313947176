<!--<input type="file" name="photo" ng2FileSelect [uploader]="uploader" />-->
<div class="container split-passengers-panel">
    <div class="container-fluid tc-card-wrapper">
        <div class="row">
            <div class="col-md-12 col-lg-12">
                    <div class="form-group">
                        <div class="row">
                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 remove-bootstrap-padding-mobile">
                                <!--  <label for="file">{{'Choose File' | translate}}</label>-->
                                <input
                                        [placeholder]="'File to upload'"
                                        type="file"
                                        id="file"
                                        (change)="handleFileInput($event?.target?.files)">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 remove-bootstrap-padding-mobile">
                                <mat-form-field appearance="fill">
                                    <mat-select [(ngModel)]="templateType" [placeholder]="'Template Type'| translate">
                                        <mat-option
                                                *ngFor="let option of templateTypes"
                                                [value]="option">{{option.name}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 remove-bootstrap-padding-mobile">
                                <mat-form-field appearance="fill">
                                    <input
                                            matInput
                                            [matDatepicker]="picker"
                                            [(ngModel)]="serviceDate"
                                            [placeholder]="'Service Date'">
                                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                    <mat-datepicker #picker></mat-datepicker>
                                </mat-form-field>
                            </div>
                        </div>

                    </div>
            </div>
        </div>
    </div>
</div>

<div class="tc-modal-footer">
    <button
            class="tc-button-secondary mat-button rs-footer-section__button"
            (click)="closePopUp()"
            [disabled]="false">
        {{'Cancel' | translate}}
    </button>
    <button
            class="tc-button-primary mat-button rs-footer-section__button"
            (click)="uploadFile()"
            [disabled]="!(fileToUpload && templateType && serviceDate)">
        {{'Upload' | translate}}
    </button>
</div>

