/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "../../../../../../../node_modules/ag-grid-angular/dist/agGridAngular.ngfactory";
import * as i3 from "ag-grid-angular/dist/angularFrameworkOverrides";
import * as i4 from "ag-grid-angular/dist/angularFrameworkComponentWrapper";
import * as i5 from "ag-grid-angular/dist/agGridAngular";
import * as i6 from "@angular/common";
import * as i7 from "./passenger-selection-detail-view.component";
import * as i8 from "../../../../../services/util/framework/data-store.service";
import * as i9 from "@angular/material/dialog";
var styles_PassengerSelectionDetailViewComponent = [];
var RenderType_PassengerSelectionDetailViewComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PassengerSelectionDetailViewComponent, data: {} });
export { RenderType_PassengerSelectionDetailViewComponent as RenderType_PassengerSelectionDetailViewComponent };
function View_PassengerSelectionDetailViewComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["style", "color: red"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.disableCloseMsg; _ck(_v, 1, 0, currVal_0); }); }
export function View_PassengerSelectionDetailViewComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "div", [["class", "detail-group__body-service-item container-fluid"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "div", [["class", "detail-group__heading container-fluid"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 3, "div", [["class", "item-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 2, "div", [["class", "item-header__content"]], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(6, 0, null, null, 5, "div", [["class", "tc-px-3"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 16777216, null, null, 4, "ag-grid-angular", [["class", "ag-theme-material tc-ag-grid tc-ag-grid--full-width tc-ag-grid--popup-content"]], null, [[null, "gridReady"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("gridReady" === en)) {
        var pd_0 = (_co.onGridReady($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_AgGridAngular_0, i2.RenderType_AgGridAngular)), i0.ɵprd(512, null, i3.AngularFrameworkOverrides, i3.AngularFrameworkOverrides, [i0.NgZone]), i0.ɵprd(512, null, i4.AngularFrameworkComponentWrapper, i4.AngularFrameworkComponentWrapper, []), i0.ɵdid(10, 4898816, null, 1, i5.AgGridAngular, [i0.ElementRef, i0.ViewContainerRef, i3.AngularFrameworkOverrides, i4.AngularFrameworkComponentWrapper, i0.ComponentFactoryResolver], { rowData: [0, "rowData"], columnDefs: [1, "columnDefs"], frameworkComponents: [2, "frameworkComponents"], defaultColDef: [3, "defaultColDef"], overlayNoRowsTemplate: [4, "overlayNoRowsTemplate"], domLayout: [5, "domLayout"] }, { gridReady: "gridReady" }), i0.ɵqud(603979776, 1, { columns: 1 }), (_l()(), i0.ɵeld(12, 0, null, null, 5, "div", [["class", "container assign-panel-footer-section"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PassengerSelectionDetailViewComponent_1)), i0.ɵdid(14, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(15, 0, null, null, 2, "button", [["class", "assign-panel-footer-section__button tc-button-secondary mat-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClose($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(16, null, [" ", " "])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_1 = ((_co.bookingItem == null) ? null : _co.bookingItem.bookingPassengers); var currVal_2 = _co.columnDefs; var currVal_3 = _co.frameworkComponents; var currVal_4 = _co.defaultColDef; var currVal_5 = _co.overlayNoRowsTemplate; var currVal_6 = "autoHeight"; _ck(_v, 10, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_7 = _co.disableClose; _ck(_v, 14, 0, currVal_7); }, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 4, 0, i0.ɵnov(_v, 5).transform("Item Passengers")); _ck(_v, 4, 0, currVal_0); var currVal_8 = i0.ɵunv(_v, 16, 0, i0.ɵnov(_v, 17).transform("Close")); _ck(_v, 16, 0, currVal_8); }); }
export function View_PassengerSelectionDetailViewComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-passenger-selection-detail-view", [], null, null, null, View_PassengerSelectionDetailViewComponent_0, RenderType_PassengerSelectionDetailViewComponent)), i0.ɵdid(1, 114688, null, 0, i7.PassengerSelectionDetailViewComponent, [i1.TranslateService, i8.DataStoreService, i9.MatDialog], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PassengerSelectionDetailViewComponentNgFactory = i0.ɵccf("tc-passenger-selection-detail-view", i7.PassengerSelectionDetailViewComponent, View_PassengerSelectionDetailViewComponent_Host_0, { booking: "booking", bookingItem: "bookingItem", readonly: "readonly", onChangeFn: "onChangeFn", output: "output" }, {}, []);
export { PassengerSelectionDetailViewComponentNgFactory as PassengerSelectionDetailViewComponentNgFactory };
