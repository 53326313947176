import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatSelectionList } from '@angular/material/list';
// import { TBXResponseWrapper } from '@tc-core/model/it/codegen/tbx/api/tbx-response-wrapper';
import { TcErrorType } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-error';
import { DialogModel } from '@tc-core/model/it/codegen/ui/framework/dialog-model';
import { LEVEL, ModalData } from '@tc-core/model/it/codegen/ui/framework/modal-data';
import { TC } from '@tc-core/util';
import { ConfigLoader, EventManager, FocusViewManager, UserJourneyManager } from '@tc-core/util/framework';
import { CommonHelper } from '@tc-core/util/helpers';
import { SpinnerService } from '@tc-core/util/ui';
import { DialogService } from '@tc/dialog/dialog.service';
import { FocusViewService } from '@tc/focus-view/focus-view.service';
import { ModalService } from '@tc/modal/modal.service';
import { SortChangedEvent } from 'ag-grid-community';
import * as _ from 'lodash';
import * as moment from 'moment';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import 'rxjs-compat/add/operator/filter';
import { catchError, map } from 'rxjs/operators';
import { ButtonState } from '../../../widgets/framework/ag-grid-custom-components/directives/button-state';
import { AutoCompleteEditorComponent } from '../../../widgets/framework/ag-grid-custom-components/editors/auto-complete-editor/auto-complete-editor.component';
import { DropdownEditorComponent } from '../../../widgets/framework/ag-grid-custom-components/editors/dropdown-editor/dropdown-editor.component';
import { NoteEditorComponent } from '../../../widgets/framework/ag-grid-custom-components/editors/note-editor/note-editor.component';
import { CustomTextFilterComponent } from '../../../widgets/framework/ag-grid-custom-components/filters/custom-text-filter/custom-text-filter.component';
import { ActionsComponent } from '../../../widgets/framework/ag-grid-custom-components/renderers/actions/actions.component';
import { TemplateRendererComponent } from '../../../widgets/framework/ag-grid-custom-components/renderers/template-renderer/template-renderer.component';
import { TimeEditorRendererComponent } from '../../../widgets/framework/ag-grid-custom-components/renderers/time-editor-renderer/time-editor-renderer.component';
import { GridCommonUtil } from '../../../widgets/framework/ag-grid-custom-components/utils/grid-common-util';
import { AssignmentFocusViewComponent } from '../../assignment-focus-view/assignment-focus-view.component';
import { TCO } from '../../../constants';
import { DocumentCreationModalComponent } from '../../document-creation-modal/document-creation-modal.component';
import { AssignmentSummary } from '../../../models/assignment-summary';
import {
    DocumentCreationCriteria,
    DocumentFormat,
    DocumentType
} from '../../../models/criteria/document-creation-criteria';
import { DocumentDataCriteria } from '../../../models/criteria/document-data-criteria';
import { GenericSearchCriteria } from '../../../models/criteria/generic-search-criteria';
import { ReservationSearchCriteria } from '../../../models/criteria/reservation-search-criteria';
import { SupplierSearchCriteria } from '../../../models/criteria/supplier-search-criteria';
import { DocumentCreationModel } from '../../../models/helper/document-creation-model';
import { AssignmentStatus, StatusCodes } from '../../../models/reservation/assignment-status';
import { GenTourItem } from '../../../models/reservation/generic/gen-tour-item';
import { GenTourItinerary } from '../../../models/reservation/generic/gen-tour-itinerary';
import { ServiceAssignment } from '../../../models/reservation/service-assignment';
import { ServiceItem } from '../../../models/reservation/service-item';
import { OPResponseWrapper } from '../../../models/response/op-response-wrapper';
import { GenResourceAllocationSummary } from '../../../models/summary/gen-resource-allocation-summary';
import { GenSupplierAllocationSummary } from '../../../models/summary/gen-supplier-allocation-summary';
import { SupplierSummaryDepricated } from '../../../models/summary/supplier-summary-depricated';
import { GenResourceCost } from '../../../models/supplier/gen-resource-cost-setup';
import { BookingNoteGroup, NotesEditorComponent } from '../../notes-editor/notes-editor.component';
import { SearchCriteriaSaveModalComponent } from '../../reservation-management/search-criteria-save-modal/search-criteria-save-modal.component';
import { SelectServiceItemsComponent } from '../../select-service-items/select-service-items.component';
import { DocumentService } from '../../../services/backend-consumers/documents/document.service';
import { GenReservationManagementService } from '../../../services/backend-consumers/reservation-management-services/gen-reservation-management.service';
import { ReservationManagementService } from '../../../services/backend-consumers/reservation-management-services/reservation-management.service';
import { GenericResourceService } from '../../../services/backend-consumers/supplier-service/generic-resource.service';
import { SupplierService } from '../../../services/backend-consumers/supplier-service/supplier.service';
import { CostCalculationUtilsService } from '../../../services/business-utils/cost-calculation/cost-calculation-utils.service';
import { ServiceAssignmentUtilService } from '../../../services/business-utils/service-item-utils/service-assignment-util.service';
import { DMCCommon } from '../../../services/util/common/dmc-common';
import { DataKey, DataStoreService } from '../../../services/util/framework/data-store.service';
import { IdentifierService } from '../../../services/util/identifier/identifier.service';
import { GridColumnDefinitionProcessorService } from '../../../services/util/pre-processors/grid-column-definition-processor.service';
import { ResponseUtil } from '../../../services/util/response/response-util.service';
import { SupplierPriceBreakdownComponent } from '../../supplier-price-breakdown/supplier-price-breakdown.component';
import { GenQueueUtil } from './gen-queue-util';

@Component({
    selector: 'tc-gen-reservation-search-results',
    templateUrl: './gen-reservation-search-results.component.html'
})
export class GenReservationSearchResultsComponent implements OnInit, AfterViewInit, OnDestroy {

    constructor(
        public em: EventManager,
        private configLoader: ConfigLoader,
        private dataStore: DataStoreService,
        private spinnerService: SpinnerService,
        private modalService: ModalService,
        private dialogService: DialogService,
        private common: DMCCommon,
        private genReservationManagementService: GenReservationManagementService,
        private reservationManagementService: ReservationManagementService,
        private cd: ChangeDetectorRef,
        private costCalculationUtil: CostCalculationUtilsService,
        private userJourneyManager: UserJourneyManager,
        private focusViewManager: FocusViewManager,
        private supplierService: SupplierService,
        private genericResourceService: GenericResourceService,
        private dataStoreService: DataStoreService,
        private columnDefinitionProcessorService: GridColumnDefinitionProcessorService,
        private commonHelper: CommonHelper,
        private documentService: DocumentService,
        private identifierService: IdentifierService,
        private focusViewService: FocusViewService
    ) { }

    tPagination: any;
    resourceCategoryIcons: any;
    searchCriteria = new ReservationSearchCriteria();
    fetchedReservationsCount = 0;
    totalReservationsCount = 0;
    resultHeaderHeading = '';
    isExportEnable = true;

    searchFromSortingOrFiltering = false;
    sortFilterModelProgrammaticallyChanging = false;

    assignmentChangedTours: Array<GenTourItinerary> = [];

    changesAvailableForSave = false;

    resourceTypes = [];
    selectedSupplierMap: Map<number, GenResourceCost[]> = new Map();

    private buttonState = new BehaviorSubject<ButtonState[]>([]);
    buttonStateObs = this.buttonState.asObservable();

    private searchCriteriaSubscription: Subscription = new Subscription();
    private searchResultSubscription: Subscription = new Subscription();
    private moreSearchResultSubscription: Subscription = new Subscription();
    private finalSearchResultsSubscription: Subscription = new Subscription();
    private saveGenericSubscription: Subscription = new Subscription();
    private resourceTypesSubscription: Subscription = new Subscription();
    private modalCloseSubscription: Subscription = new Subscription();
    private subscribeOnSelectedServiceItemList: Subscription = new Subscription();
    private focusviewCloseSubject: Subscription = new Subscription();

    // focus view assignments
    private assignedSupplierSubscription: Subscription = new Subscription();
    private assignedResourceSubscription: Subscription = new Subscription();

    paramsOfFocusView: any;
    isFocusViewAssignment = false;
    isFocusViewAssignmentCategory: string;

    // *********************ag grid data*****************

    EMPTY_STRING = '--';
    overlayNoRowsTemplate = `<span class="ag-overlay-no-rows-center">No Booking items</span>`;

    @ViewChild('dateCell') dateCell: TemplateRef<any>;
    @ViewChild('tourCell') tourCell: TemplateRef<any>;
    @ViewChild('itemCategoryCell') itemCategoryCell: TemplateRef<any>;
    @ViewChild('itemTypeCell') itemTypeCell: TemplateRef<any>;
    @ViewChild('timeCell') timeCell: TemplateRef<any>;
    @ViewChild('totalPaxCell') totalPaxCell: TemplateRef<any>;
    @ViewChild('allocatedCell') allocatedCell: TemplateRef<any>;
    @ViewChild('supplierCell') supplierCell: TemplateRef<any>;
    @ViewChild('supplierItemCell') supplierItemCell: TemplateRef<any>;
    @ViewChild('resourceCell') resourceCell: TemplateRef<any>;
    @ViewChild('notesCell') notesCell: TemplateRef<any>;
    @ViewChild('groupNotesCell') groupNotesCell: TemplateRef<any>;
    @ViewChild('assignStatusCell') assignStatusCell: TemplateRef<any>;
    @ViewChild('itemNameCell') itemNameCell: TemplateRef<any>;
    @ViewChild('startTimeCell') startTimeCell: TemplateRef<any>;
    @ViewChild('endTimeCell') endTimeCell: TemplateRef<any>;
    @ViewChild('menuCell') menuCell: TemplateRef<any>;

    @ViewChild('colSelector') private colSelector: MatSelectionList;

    statusCodes = StatusCodes;

    gridApi;
    gridColumnApi;
    genericGroupResults: GenTourItinerary[];
    components;
    rowHeight = 42;
    columnDefs = [];
    colResizeDefault = '';
    PENDING = AssignmentStatus.PENDING;
    DMC_CONFIRM = AssignmentStatus.DMC_CONFIRM;
    REQUEST_EXPIRED = AssignmentStatus.REQUEST_EXPIRED;
    SUPPLIER_CONFIRMED = AssignmentStatus.SUPPLIER_CONFIRMED;
    SUPPLIER_REJECTED = AssignmentStatus.SUPPLIER_REJECTED;
    BOOKING_UPDATE = AssignmentStatus.BOOKING_UPDATE;
    INACTIVE = AssignmentStatus.INACTIVE;
    INACTIVE_NOTIFY = AssignmentStatus.INACTIVE_NOTIFY;
    NO_SHOW = AssignmentStatus.NO_SHOW;
    COMPLETED = AssignmentStatus.COMPLETED;

    defaultColDef = {
        resizable: true,
        singleClickEdit: false
    };

    rowClassRules = {
        'tc-ag-grid-row--error'(params) { return params.data.isInvalid; }
    };

    gridOptions = {
        getNodeChildDetails(parent) {
            if (parent === null) {
                return null;
            }
            if (parent.itineraryItems) { // itineraryItems of tour
                return {
                    group: true,
                    children: parent.itineraryItems,
                    expanded: true
                };
            } else if (parent.assignments) { // assignments of itineraryItem
                return {
                    group: true,
                    children: parent.assignments,
                    expanded: true
                };
            } else {
                return null;
            }
        },
        rowSelection: 'multiple',
        pagination: true,
        resizable: true,
        floatingFilter: false
    };

    paginationPageSize = 10;

    getRowHeight = function(params) {
        if (params && params.data) {
            if (params.data.itineraryItems) {
                return 0;
            } else if (params.data.assignments) {
                return 0;
            } else {
                return this.rowHeight;
            }
        }
    }.bind(this);

    getRowStyle = function(params) {
        if (params.data.itineraryItems) {
            return {border: 'none'};
        } else if (params.data.assignments) {
            return {border: 'none'};
        } else {
            return '';
        }
    }.bind(this);

    frameworkComponents = {
        templateRenderer: TemplateRendererComponent,
        actionRenderer: ActionsComponent,
        autoCompleteEditor: AutoCompleteEditorComponent,
        dropDownEditor: DropdownEditorComponent,
        noteEditor: NoteEditorComponent,
        customTextFilter: CustomTextFilterComponent,
        timeEditorRenderer: TimeEditorRendererComponent,
    };

    colDefConfig: any;

    paginatorLoaded: boolean;
    saveButtonEnabled = false;
    changedAssignments: ServiceAssignment[] = [];

    ngOnInit() {
        this.tPagination = this.configLoader.configurations.get(TCO.CONF.CONF_PAGINATION_CONFIG).GEN_RESERVATION_SEARCH;
        this.paginationPageSize = this.tPagination.defaultPageResultCount;

        this.resourceCategoryIcons = this.configLoader.configurations.get(TCO.CONF.CONF_GENERIC_QUEUE_ICONS).RESOURCE_CATEGORY_ICONS;
        this.colDefConfig = this.configLoader.configurations.get(TCO.CONF.CONF_GENERIC_QUEUE_COL_DEF);
        this.subscribeSearchCriteria();
        this.subscribeSearchResults();
        this.subscribeOnMoreSearchResults();
        this.subscribeToAllocationSelection();
        this.subscribeSearchResultList();

        this.addQuickActionEvents();
        this.getAllResourceTypes();
    }

    // get all resource types for supplier item
    public getAllResourceTypes() {
        this.resourceTypesSubscription = this.dataStore.get(DataKey.resourceTypes).subscribe(response => {
            if (response != null) {
                this.resourceTypesSubscription.unsubscribe();
                this.resourceTypes = ResponseUtil.getArray(response);
            }
        });
    }

    addQuickActionEvents() {
        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'GENERATE_DOCUMENTS').subscribe(e => {
            this.onGenerateDocumentsClick();
        });
        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'EXPORT').subscribe(e => {
            this.export('', false, true);
        });
        this.em.addEvent(TCO.CONF.CONF_EVENT_MANAGER, 'SAVE_CRITERIA').subscribe(e => {
            this.onSaveSearchCriteriaClick();
        });
        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'SEND_MANIFESTS').subscribe(e => {
            this.sendManifests();
        });
        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'TOUR_LIST').subscribe(e => {
            this.getTourList('', false, true);
        });
    }

    subscribeSearchCriteria() {
        this.searchCriteriaSubscription = this.dataStore.get(DataKey.genReservationSearchCriteria).subscribe((data) => {
            if (data !== null) {
                this.searchCriteria = data;
            }
        });
    }

    subscribeSearchResults() {
        this.searchResultSubscription = this.dataStore.get(DataKey.genReservationSearchResultsFromService)
            // this.searchResultSubscription = this.genReservationManagementService.searchReservationsFromMock()
                                            .subscribe((data: OPResponseWrapper<GenTourItinerary>) => {
                                                this.totalReservationsCount = 0;
                                                if (data) {
                                                    this.totalReservationsCount = ResponseUtil.getTotalCount(data);
                                                    const dataArray = ResponseUtil.getDataArray<GenTourItinerary>(data);
                                                    this.dataStore.set(
                                                        DataKey.genReservationSearchResults,
                                                        dataArray,
                                                        true
                                                    );
                                                }
                                            });
    }

    subscribeToAllocationSelection() {
        this.assignedSupplierSubscription = this.dataStore.get(DataKey.assignedGenResourceSupp).subscribe((data: OPResponseWrapper<boolean>) => {
                if (data) {
                    this.dataStore.get(DataKey.assignedSupplier).subscribe((dataSupp: any) => {
                        if (dataSupp) {
                            this.paramsOfFocusView.newValue = dataSupp;
                            this.isFocusViewAssignment = true;
                            this.isFocusViewAssignmentCategory = dataSupp.resourceCostSummaries[0].resourceType;
                            this.onSetSupplier(this.paramsOfFocusView);
                        }});
                }
            });
        this.assignedResourceSubscription = this.dataStore.get(DataKey.assignedResource).subscribe((data: any) => {
            if (data) {
                this.paramsOfFocusView.newValue = data;
                this.isFocusViewAssignment = true;
                this.isFocusViewAssignmentCategory = data.driverSupplier.resourceType;
                this.onSetResource(this.paramsOfFocusView);
            }
        });
    }

    subscribeOnMoreSearchResults() {
        this.moreSearchResultSubscription = this.dataStore.get(DataKey.genReservationSearchResultsFromServiceOnMore)
                                                .subscribe((data: OPResponseWrapper<GenTourItinerary>) => {
                                                    this.totalReservationsCount = 0;
                                                    if (data) {
                                                        this.totalReservationsCount = ResponseUtil.getTotalCount(data);
                                                        let dataArray = ResponseUtil.getDataArray<GenTourItinerary>(data);
                                                        dataArray = this.genericGroupResults.concat(dataArray);
                                                        this.dataStore.set(
                                                            DataKey.genReservationSearchResults,
                                                            dataArray,
                                                            true
                                                        );
                                                    }
                                                });
    }

    subscribeSearchResultList() {
        this.fetchedReservationsCount = 0;
        this.finalSearchResultsSubscription = this.dataStore.get(DataKey.genReservationSearchResults)
                                                  .subscribe((data: GenTourItinerary[]) => {
                                                      this.genericGroupResults = [];
                                                      if (data != null) {
                                                          this.spinnerService.hide();

                                                          // **** result counts *** //
                                                          this.genericGroupResults = data;
                                                          this.fetchedReservationsCount = this.genericGroupResults.length;

                                                          // **** custom buttons configuring *** //
                                                          this.paginatorLoaded = true;
                                                          this.disableAllPaginatorButtons();
                                                          if (this.fetchedReservationsCount <
                                                              this.totalReservationsCount) {
                                                              this.enableMoreButton();
                                                          } else if (this.fetchedReservationsCount >=
                                                              this.totalReservationsCount) {
                                                              this.disableMoreButton();
                                                          }

                                                          // **** export button configuring *** //
                                                          if (this.fetchedReservationsCount > 0) {
                                                              this.enableExportButton();
                                                              this.userJourneyManager.enableQuickActions([
                                                                  'TRIGGER_EXPORT_FILE'
                                                              ]);
                                                          }

                                                          // **** setup result header *** //
                                                          this.setupResultHeader(this.totalReservationsCount);

                                                      } else {
                                                          // **** clear previous data*** //
                                                          this.genericGroupResults = [];
                                                          this.resultHeaderHeading = '';
                                                          this.disableAllPaginatorButtons();
                                                      }

                                                      // **** set genericGroupResults to grid*** //
                                                      this.setGridData();

                                                  });
    }

    setupResultHeader(count: number) {
        this.resultHeaderHeading = count + ' result' +
            (count > 1 ? 's' : '') + ' found.';
    }

    enableExportButton() {
        this.userJourneyManager.canProceed.next(true);
        this.focusViewManager.canProceed.next(true);
        this.isExportEnable = true;
    }

    export(docName: string, persist: boolean, download: boolean) {
        this.spinnerService.show();
        const documentCreationCriteria = new DocumentCreationCriteria();
        documentCreationCriteria.documentFormat = DocumentFormat.excel;
        documentCreationCriteria.documentType = DocumentType.genExportReport;
        documentCreationCriteria.documentName = docName;
        documentCreationCriteria.download = download;
        documentCreationCriteria.persist = persist;

        const documentDataCriteria = new DocumentDataCriteria();
        this.createDocSearchCriteria(documentDataCriteria);
        documentDataCriteria.serviceType = 'GEN';
        this.documentService.createAndDownloadDocument(documentCreationCriteria, documentDataCriteria);
    }

    onSaveSearchCriteriaClick() {
        this.dataStore.set(DataKey.popupClose, null);
        const dataObject = new ModalData(
            LEVEL.SUCCESS,
            'Save Criteria',
            null,
            null,
            'modal-basic--alt modal-basic--fixed-size',
            SearchCriteriaSaveModalComponent,
            {searchCriteria: this.searchCriteria}
        );

        const secondaryHeader = '';

        dataObject.secondaryHeader = secondaryHeader;
        this.confirmModal(dataObject);

        this.dataStore.get(DataKey.popupClose).subscribe((data) => {
            if (data != null) {
                this.closeModal();
            }
        });
    }

    private confirmModal(data: any): void {
        this.modalService.confirm(data).subscribe((res) => {
        });
    }

    closeModal() {
        if (this.modalService.close()) {
            this.modalService.close().subscribe(
                (res) => {
                }
            );
        }
    }

    // *********************ag grid implementation************************** //

    setGridData() {
        if (this.gridApi) {
            this.preProcessDataForGrid(this.genericGroupResults);

            this.gridApi.setRowData(this.genericGroupResults);

            // clear grid sort filter marks on search from criteria component
            if (!this.searchFromSortingOrFiltering) {
                this.sortFilterModelProgrammaticallyChanging = true;
                this.gridApi.setSortModel(null);
                this.gridApi.setFilterModel(null);
            } else {
                this.searchFromSortingOrFiltering = false;
            }

            this.paginatorLoaded = true;
        } else {
            setTimeout(() => this.setGridData(), 0);
        }
    }

    preProcessDataForGrid(genTourItineraries: GenTourItinerary[]) {
        for (const genTourItinerary of genTourItineraries) {
            for (const itineraryItem of genTourItinerary.itineraryItems) {
                if (!itineraryItem.assignments || itineraryItem.assignments.length === 0) {
                    itineraryItem.assignments = [this.getEmptyAssignment()];
                }
            }
        }
    }

    onGridReady(params) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.gridApi.setGroupHeaderHeight(20);
        this.gridApi.setGroupHeaderHeight(20);
        this.gridApi.setHeaderHeight(35);
        // this.gridApi.setRowHeight(40);
        // this.gridApi.groupSuppressBlankHeader(true);
        // this.gridApi.sizeColumnsToFit();
        // window.addEventListener('resize', function () {
        //   setTimeout(function () {
        //     // params.api.sizeColumnsToFit();
        //   });
        // });
        this.autoSizeAll();
        // this.gridApi.sizeColumnsToFit();

    }

    public ngAfterViewInit(): void {
        this.setColumnDefs();
    }

    private setColumnDefs() {
        const colDefConfig = JSON.parse(JSON.stringify(this.colDefConfig));
        this.columnDefs = this.columnDefinitionProcessorService.createColumnDefs(colDefConfig, this);
        this.cd.detectChanges();
    }

    autoSizeAll() {
        const allColumnIds = [];
        if (this.gridColumnApi) {
            this.gridColumnApi.getAllColumns().forEach(function(column) {
                allColumnIds.push(column.colId);
            });
            this.gridColumnApi.autoSizeColumns(allColumnIds);
        }
    }

    public onFilterChanged($event: any) {
        if (!this.sortFilterModelProgrammaticallyChanging) {
            this.searchGenericGroupResults();
            this.searchFromSortingOrFiltering = true;
        } else {
            setTimeout(() => this.sortFilterModelProgrammaticallyChanging = false, 100);
        }
    }

    onSortChanged($event: SortChangedEvent): void {
        if (!this.sortFilterModelProgrammaticallyChanging) {
            this.searchGenericGroupResults();
            this.searchFromSortingOrFiltering = true;
        } else {
            setTimeout(() => this.sortFilterModelProgrammaticallyChanging = false, 100);
        }
    }

    onClickColOption(event) {
        event.stopPropagation();
    }

    onSelectionChange(event) {
        if (event && event.option) {
            const option = event.option.value;
            const selected = event.option.selected;
            if (this.gridColumnApi) {
                this.gridColumnApi.setColumnVisible(option, selected);
                // this.gridApi.sizeColumnsToFit();
                this.autoSizeAll();
            }
        }
    }

    onRestoreClick() {
        if (this.gridColumnApi) {
            this.gridColumnApi.resetColumnState();
            this.colSelector.selectAll();
        }
    }

    addFilterModelToCriteria() {
        const filterModelArray = [];
        const filterModel = this.gridApi.getFilterModel();
        Object.keys(filterModel).forEach(key => {
            filterModel[key].colId = key;
            filterModelArray.push(filterModel[key]);
        });
        this.searchCriteria.gridFiltering = JSON.stringify(filterModelArray);
    }

    addSortModelToCriteria() {
        const sortModel = this.gridApi.getSortModel();
        if (sortModel && sortModel.length > 0) {
            this.searchCriteria.gridSorting = JSON.stringify(sortModel);
        } else {
            this.searchCriteria.gridSorting = '[{\'colId\':\'serviceDate\',\'sort\':\'asc\'},{\'colId\':\'tourItemName\',\'sort\':\'asc\'},{\'colId\':\'assignmentId\',\'sort\':\'asc\'}]';
        }
    }

    public onColumnGroupOpen($event: any) {
        console.log($event);
    }

    public getRelatedIcon(row) {
        if (row && row.categoryCode) {
            if (this.resourceCategoryIcons) {
                if (this.resourceCategoryIcons[row.categoryCode]) {
                    return this.resourceCategoryIcons[row.categoryCode];
                } else {
                    return this.resourceCategoryIcons.default;
                }
            }
        }
    }

    private getEmptyAssignment() {
        return new ServiceAssignment();
    }

    private getTotalCost(params: any) {
        if (GenQueueUtil.isAssignmentRow(params)) {
            return this.costCalculationUtil.getGenSupplierTotalCost(params.data);
        } else {
            return 0;
        }
    }

    public onClickGroupNote() {
        console.log('group clicked');
        console.log();
    }

    public onClickMore() {
        if (this.tPagination && this.tPagination.defaultPageResultCount && this.tPagination.fetchingPages) {
            this.searchCriteria.size = this.tPagination.fetchingPages * this.tPagination.defaultPageResultCount;
            this.searchCriteria.start = this.fetchedReservationsCount;
            this.searchOnMore(this.searchCriteria);
        }
    }

    searchOnMore(searchCriteria: ReservationSearchCriteria) {
        this.dataStore.set(DataKey.genReservationSearchResultsFromServiceOnMore, null, true);
        this.genReservationManagementService.searchReservationsOnMore(this.searchCriteria);
    }

    // used in ag-grid value setter
    onSetSupplierItem = function(params) {
        const assignment: ServiceAssignment = params.data;
        const supplierItem: any = params.newValue;
        assignment.assignStatus = AssignmentStatus.PENDING;
        if (supplierItem) {
            if (!supplierItem.nullSupplierItemForClearing) {
                assignment.supplierItem = supplierItem.code;
                assignment.supplierItemName = supplierItem.name;

                this.setSupplierCostForSelectedType(params);
            } else {
                assignment.supplierItem = '';
                assignment.supplierItemName = '';

            }
        }
        this.addParentTourOfAssignmentToChangedSet(params);

        return true;
    };

    private setSupplierCostForSelectedType(params: any) {
        const assignment: ServiceAssignment = params.data;
        if (this.selectedSupplierMap) {
            const resourceCosts = this.selectedSupplierMap.get(params.data.supplierId);
            if (resourceCosts) {
                const selectedCost = resourceCosts.find(value => value.resourceType === params.newValue.code);
                if (selectedCost) {
                    assignment.unitPrice = selectedCost.unitFare;
                    assignment.adultPrice = selectedCost.adultFare;
                    assignment.teenPrice = selectedCost.teenFare;
                    assignment.childPrice = selectedCost.childFare;
                    assignment.infantPrice = selectedCost.infantFare;
                    assignment.currency = selectedCost.currency;
                }
            }
            this.clearResource(assignment);
            this.updateJobCost(assignment);

        }
    }

    // used in ag grid col def by method reference
    onSetSupplier = function(params) {
        const assignment: ServiceAssignment = params.data;
        const supplier: SupplierSummaryDepricated = params.newValue;

        this.setSupplierToAssignment(assignment, supplier);
        this.mapServiceItemIdList(params, assignment);

        // this.setGridData();
        this.addParentTourOfAssignmentToChangedSet(params);

        return true;
    };

    private mapServiceItemIdList(params: any, assignment: ServiceAssignment) {
        if (params.node.parent && params.node.parent.data.serviceItems) {
            const serviceItems: ServiceItem[] = params.node.parent.data.serviceItems;
            // check for previous assignments
            let previousAssignments: ServiceAssignment[] = params.node.parent.data.assignments;
            let alreadyAddedItems = [];
            previousAssignments = previousAssignments.filter(value => value.tempAssignmentId !=
                params.data.tempAssignmentId);
            // get already added service items to disable from the new assignment popup
            if (previousAssignments && previousAssignments.length > 0) {
                alreadyAddedItems = this.getAlreadyAddedItems(previousAssignments);
            }
            assignment.assignStatus = AssignmentStatus.PENDING;
            assignment.serviceItemIdList = assignment.serviceItemIdList ? assignment.serviceItemIdList : [];
            for (let i = 0; i < serviceItems.length; i++) {
                if (!alreadyAddedItems.includes(serviceItems[i].serviceItemId) &&
                    !assignment.serviceItemIdList.includes(serviceItems[i].serviceItemId)) {
                    // set the pax count for each assignment
                    if (i == 0) {
                        assignment.adults = serviceItems[i].adult;
                        assignment.children = serviceItems[i].child;
                        assignment.infants = serviceItems[i].infant;
                    } else {
                        assignment.adults += serviceItems[i].adult;
                        assignment.children += serviceItems[i].child;
                        assignment.infants += serviceItems[i].infant;
                    }
                    assignment.serviceItemIdList.push(serviceItems[i].serviceItemId);
                }
            }
            this.refreshDataGrid();
        }
    }

    // used in ag-grid value-setter
    onSetTotalCost = function(params) {
        params.data.jobCost = params.newValue;
        this.addParentTourOfAssignmentToChangedSet(params);
        this.enableSaveButtonValidateFields();
    };

    // used in ag-grid value-setter
    onSetStartTime = function(params) {
        if (params.newValue && new Date(params.newValue) instanceof Date) {
            const date = params.node.parent.parent.data.serviceDate;
            // params.data.startTime = new Date(date + 'T' + params.newValue);
            params.data.startTime = moment(new Date(date + 'T' + params.newValue)).format('HH:mm');
            params.data.assignStatus = AssignmentStatus.PENDING;
            this.addParentTourOfAssignmentToChangedSet(params);
            this.enableSaveButtonValidateFields();
        }
    };

    // used in ag-grid value-setter
    onSetEndTime = function(params) {
        if (params.newValue && new Date(params.newValue) instanceof Date) {
            const date = params.node.parent.parent.data.serviceDate;
            params.data.endTime = moment(new Date(date + 'T' + params.newValue)).format('HH:mm');
            params.data.assignStatus = AssignmentStatus.PENDING;
            this.addParentTourOfAssignmentToChangedSet(params);
            this.enableSaveButtonValidateFields();
        }
    };

    addParentTourOfAssignmentToChangedSet(params) {
        const tour: GenTourItinerary = params.node.parent.parent.data;
        this.changesAvailableForSave = true;
        if (tour) {
            let assignments: Array<AssignmentSummary> = [];
            if (params.newValue) {
                assignments = params.newValue.assignments;
            }
            if (this.isFocusViewAssignment && tour.itineraryItems.length > 0 && assignments && assignments.length > 0) {
                assignments.forEach(assignment => {
                    tour.itineraryItems.forEach(
                        itinItem => {
                            if (itinItem.assignments.length > 0 && itinItem.categoryCode === this.isFocusViewAssignmentCategory) {
                                itinItem.assignments.forEach(assignmentIti => {
                                   if (assignmentIti.assignStatus === AssignmentStatus.PENDING) {
                                       assignmentIti.startTime = assignment.startTime;
                                       assignmentIti.endTime = assignment.endTime;
                                   }
                                });
                            }
                        }
                    );
                    this.isFocusViewAssignment = false;
                });
            }
            this.addToDataChangedArray(tour);
            this.enableSaveButtonValidateFields();
        }
    }

    addToDataChangedArray(tour: GenTourItinerary) {
        if (this.assignmentChangedTours) {
            const genTourItinerary = this.assignmentChangedTours.find(t => GenTourItinerary.issEqual(t, tour));
            if (genTourItinerary) {
                const index = this.assignmentChangedTours.indexOf(genTourItinerary);
                if (index > -1) {
                    this.assignmentChangedTours.splice(index, 1);
                }
            }
            this.assignmentChangedTours.push(tour);
        }
    }

    updateJobCost(assignment: ServiceAssignment) {
        assignment.jobCost = this.costCalculationUtil.getGenSupplierTotalCost(assignment);
    }

    setSupplierToAssignment(assignment: ServiceAssignment, supplier: GenSupplierAllocationSummary) {
        if (supplier) {
            if (!supplier.nullSupplierForClearing) {
                // assignment.supplier = supplier;
                assignment.tempAssignmentId = this.identifierService.getNextManuallyResetId();
                assignment.supplierName = supplier.name;
                assignment.supplierCode = supplier.code;
                assignment.supplierId = supplier.supplierId;
                assignment.assignStatus = assignment.assignStatus ? assignment.assignStatus : AssignmentStatus.PENDING;
                // if supplier has resource costs
                if (supplier.resourceCostSummaries && supplier.resourceCostSummaries.length > 0) {
                    this.selectedSupplierMap.set(supplier.supplierId, supplier.resourceCostSummaries);
                    assignment.unitPrice = 0;
                    assignment.adultPrice = 0;
                    assignment.teenPrice = 0;
                    assignment.childPrice = 0;
                    assignment.infantPrice = 0;
                    assignment.currency = '';
                    let supplierCost: GenResourceCost;
                    // if resource cost matches with supplier item set the supplier cost
                    if (assignment.supplierItem &&
                        supplier.resourceCostSummaries.find(value => value.resourceType === assignment.supplierItem)) {
                        supplierCost = supplier.resourceCostSummaries.find(value => value.resourceType ===
                            assignment.supplierItem);
                    } else if (assignment.categoryCode &&
                        supplier.resourceCostSummaries.find(value => value.resourceType === assignment.categoryCode)) {
                        supplierCost = supplier.resourceCostSummaries.find(value => value.resourceType ===
                            assignment.categoryCode);
                        this.clearSupplierItem(assignment);
                    } else if (supplier.resourceCostSummaries.length === 1) {
                        supplierCost = supplier.resourceCostSummaries[0];
                        this.clearSupplierItem(assignment);
                    } else {
                        this.clearSupplierItem(assignment);
                    }
                    if (supplierCost) {
                        assignment.unitPrice = supplierCost.unitFare;
                        assignment.adultPrice = supplierCost.adultFare;
                        assignment.teenPrice = supplierCost.teenFare;
                        assignment.childPrice = supplierCost.childFare;
                        assignment.infantPrice = supplierCost.infantFare;
                        assignment.currency = supplierCost.currency;
                    }
                } else {
                    assignment.unitPrice = 0;
                    assignment.adultPrice = 0;
                    assignment.teenPrice = 0;
                    assignment.childPrice = 0;
                    assignment.infantPrice = 0;
                    assignment.currency = '';
                }
                this.clearResource(assignment);
                this.updateJobCost(assignment);
            } else {
                assignment.supplierName = '';
                assignment.supplierCode = '';
                assignment.supplierId = 0;
                assignment.unitPrice = 0;
                assignment.adultPrice = 0;
                assignment.teenPrice = 0;
                assignment.childPrice = 0;
                assignment.infantPrice = 0;
                assignment.currency = '';
                assignment.serviceItemIdList = [];
                this.clearResource(assignment);
                this.updateJobCost(assignment);
            }
        }
    }

    clearSupplierItem(assignment: ServiceAssignment) {
        assignment.supplierItemName = '';
        assignment.supplierItem = '';
    }

    clearResource(assignment: ServiceAssignment) {
        assignment.resourceName = '';
        assignment.resourceId = 0;
    }

    // used in ag grid col def by method reference
    onSetResource = function(params) {
        const assignment: ServiceAssignment = params.data;
        const resource: GenResourceAllocationSummary = params.newValue;

        this.setResourceToAssignment(assignment, resource);
        this.mapServiceItemIdList(params, assignment);
        this.addParentTourOfAssignmentToChangedSet(params);
        return true;
    };

    setResourceToAssignment(assignment: ServiceAssignment, resource: GenResourceAllocationSummary) {
        if (resource) {
            assignment.assignStatus = AssignmentStatus.PENDING;
            if (!resource.nullResourceForClearing) {
                if (!assignment.supplierId && resource && resource.supplier) {
                    this.setSupplierToAssignment(assignment, resource.supplier);
                }
                if (!assignment.supplierId && resource && resource.resourceSupplier) {
                    this.setSupplierToAssignment(assignment, resource.resourceSupplier);
                }
                // assignment.resource = resource;
                assignment.resourceName = this.isFocusViewAssignment ? resource.resourceName : resource.name;
                assignment.resourceId = resource.resourceId;
                assignment.supplierItem = this.isFocusViewAssignment ? this.isFocusViewAssignmentCategory : resource.resourceType;
                const supplierItem = this.resourceTypes.find(value => value.code === resource.resourceType);
                assignment.supplierItemName = supplierItem ? supplierItem.name : '';

            } else {
                assignment.assignStatus = AssignmentStatus.PENDING;
                this.clearResource(assignment);
            }
        }
    }

    // used in ag grid col def by method reference
    setMaxRemainingAdults = function(params) {
        if (params.newValue >= 0 || params.newValue === '') {
            const value = +params.newValue;
            const tourItem: GenTourItem = params.node.parent.data;
            if (tourItem) {
                _.set(params.data, params.colDef.field, 0);
                const adults = tourItem.adults;
                const allocatedAdults = this.getAllocatedAdults(tourItem);
                const remaining = adults - allocatedAdults;
                if (value <= remaining) {
                    _.set(params.data, params.colDef.field, value);
                } else if (remaining > 0) {
                    _.set(params.data, params.colDef.field, remaining);
                } else {
                    _.set(params.data, params.colDef.field, 0);
                }
                // this.setGridData();
                this.updateJobCost(params.data);
                this.addParentTourOfAssignmentToChangedSet(params);
            }
        }
        return true;
    };

    // used in ag grid col def by method reference
    setMaxRemainingTeens = function(params) {
        if (params.newValue >= 0 || params.newValue === '') {
            const value = +params.newValue;
            const tourItem: GenTourItem = params.node.parent.data;
            if (tourItem) {
                _.set(params.data, params.colDef.field, 0);
                const teens = tourItem.teens;
                const allocatedTeens = this.getAllocatedTeens(tourItem);
                const remaining = teens - allocatedTeens;
                if (value <= remaining) {
                    _.set(params.data, params.colDef.field, value);
                } else if (remaining > 0) {
                    _.set(params.data, params.colDef.field, remaining);
                } else {
                    _.set(params.data, params.colDef.field, 0);
                }
                // this.setGridData();
                this.updateJobCost(params.data);
                this.addParentTourOfAssignmentToChangedSet(params);
            }
        }
        return false;
    };

    // used in ag grid col def by method reference
    setMaxRemainingChildren = function(params) {
        if (params.newValue >= 0 || params.newValue === '') {
            const value = +params.newValue;
            const tourItem: GenTourItem = params.node.parent.data;
            if (tourItem) {
                _.set(params.data, params.colDef.field, 0);
                const children = tourItem.children;
                const allocatedChildren = this.getAllocatedChildren(tourItem);
                const remaining = children - allocatedChildren;
                if (value <= remaining) {
                    _.set(params.data, params.colDef.field, value);
                } else if (remaining > 0) {
                    _.set(params.data, params.colDef.field, remaining);
                } else {
                    _.set(params.data, params.colDef.field, 0);
                }
                // this.setGridData();
                this.updateJobCost(params.data);
                this.addParentTourOfAssignmentToChangedSet(params);
            }
        }
        return true;
    };

    // used in ag grid col def by method reference
    setMaxRemainingInfants = function(params) {
        if (params.newValue >= 0 || params.newValue === '') {
            const value = +params.newValue;
            const tourItem: GenTourItem = params.node.parent.data;
            if (tourItem) {
                _.set(params.data, params.colDef.field, 0);
                const infants = tourItem.infants;
                const allocatedInfants = this.getAllocatedInfants(tourItem);
                const remaining = infants - allocatedInfants;
                if (value <= remaining) {
                    _.set(params.data, params.colDef.field, value);
                } else if (remaining > 0) {
                    _.set(params.data, params.colDef.field, remaining);
                } else {
                    _.set(params.data, params.colDef.field, 0);
                }
                // this.setGridData();
                this.updateJobCost(params.data);
                this.addParentTourOfAssignmentToChangedSet(params);
            }
        }
        return false;
    };

    // used in ag grid col def by method reference
    getAllocatedAdults = function(tourItem: GenTourItem) {
        let allocatedAdults = 0;
        if (GenQueueUtil.isTourItemRow_(tourItem)) {
            if (tourItem.assignments) {
                for (let i = 0; i < tourItem.assignments.length; i++) {
                    const assignment: ServiceAssignment = tourItem.assignments[i];
                    if (!isNaN(assignment.adults)) {
                        allocatedAdults += Number(assignment.adults);
                    }
                }
            }
        }
        return allocatedAdults;
    };

    // used in ag grid col def by method reference
    getAllocatedTeens = function(tourItem: GenTourItem) {
        let allocatedTeens = 0;
        if (GenQueueUtil.isTourItemRow_(tourItem)) {
            if (tourItem.assignments) {
                for (let i = 0; i < tourItem.assignments.length; i++) {
                    const assignment: ServiceAssignment = tourItem.assignments[i];
                    if (!isNaN(assignment.teens)) {
                        allocatedTeens += Number(assignment.teens);
                    }
                }
            }
        }
        return allocatedTeens;
    };

    // used in ag grid col def by method reference
    getAllocatedChildren = function(tourItem: GenTourItem) {
        let allocatedChildren = 0;
        if (GenQueueUtil.isTourItemRow_(tourItem)) {
            if (tourItem.assignments) {
                for (let i = 0; i < tourItem.assignments.length; i++) {
                    const assignment: ServiceAssignment = tourItem.assignments[i];
                    if (!isNaN(assignment.children)) {
                        allocatedChildren += Number(assignment.children);
                    }
                }
            }
        }
        return allocatedChildren;
    };

    // used in ag grid col def by method reference
    getAllocatedInfants = function(tourItem: GenTourItem) {
        let allocatedInfants = 0;
        if (GenQueueUtil.isTourItemRow_(tourItem)) {
            if (tourItem.assignments) {
                for (let i = 0; i < tourItem.assignments.length; i++) {
                    const assignment: ServiceAssignment = tourItem.assignments[i];
                    if (!isNaN(assignment.infants)) {
                        allocatedInfants += Number(assignment.infants);
                    }
                }
            }
        }
        return allocatedInfants;
    };

    isDeleteAssignmentRestricted = function(params) {
        const assignment: ServiceAssignment = params.data;
        return ServiceAssignment.isRestrictedEdit(assignment);
    };

    isAddAssignmentRestricted = function(params) {
        if (params.node.parent.data) {
            const serviceItems: ServiceItem[] = params.node.parent.data.serviceItems ?
                params.node.parent.data.serviceItems :
                [];
            const previousAssignments = params.node.parent.data.assignments ? params.node.parent.data.assignments : [];
            if (previousAssignments && previousAssignments.length > 0) {
                const alreadySelectedItems = previousAssignments && previousAssignments.length > 0 ?
                    this.getAlreadyAddedItems(previousAssignments) :
                    [];

                if (alreadySelectedItems && alreadySelectedItems.length > 0) {
                    return (serviceItems.length === alreadySelectedItems.length || serviceItems.length <
                        alreadySelectedItems.length);
                }
            }
            return serviceItems.length < 2;
        }
        return !params.data.supplierId;
    };

    // not using. allow to see always at least in readonly mode
    isPassengerAllocationRestricted = function(params) {
        const assignment: ServiceAssignment = params.data;
        return ServiceAssignment.isRestrictedEdit(assignment);
    };

    refreshDataGrid() {
        if (this.gridApi) {
            // this.gridApi.setRowData(this.genericGroupResults);
            this.gridApi.refreshCells({force: true});
        }
    }

    clearDataGrid() {
        this.genericGroupResults = [];
        this.setupResultHeader(0);
        if (this.gridApi) {
            this.gridApi.setRowData(this.genericGroupResults);
        }
    }

    public onCellValueChanged(params: any) {
        if (params &&

            this.isEditableColumn(params) &&

            (((isNaN(params.newValue) || isNaN(params.oldValue)) && params.newValue !== params.oldValue) ||
                (!isNaN(params.newValue) && !isNaN(params.oldValue) && Number(params.newValue) !==
                    Number(params.oldValue)) ||
                params.colDef.field === 'assignmentNotes'
            )
        ) {
            this.addParentTourOfAssignmentToChangedSet(params);
        }
        this.cellEditingStopped(params);
    }

    cellEditingStopped(event) {
        const nextCell = GridCommonUtil.getNextCell(event, true);
        this.gridApi.setFocusedCell(nextCell.rowIndex, nextCell.colKey);
    }

    isEditableColumn(params): boolean {
        let editable = false;
        const editableFields = ['supplierName', 'resourceName', 'assignmentNotes', 'adults', 'children', 'infants', 'assignmentNotes'];
        if (params && params.colDef && params.colDef.field &&
            editableFields.includes(params.colDef.field)) {
            editable = true;
        }
        return editable;
    }

    // *********************allocation************************** //

    isRowSelectable = function(params) {
        return GenQueueUtil.isAssignmentRow(params);
    };

    isEditableRow = function(params: any) {
        return !(params && params.data && ServiceAssignment.isRestrictedEdit(params.data));
    };

    isNotesEditable = function(dataRow: any) {
        return !(dataRow && ServiceAssignment.isRestrictedEdit(dataRow));
    };

    isNoteActionsRestricted = function(dataRow: any) {
        return (dataRow && ServiceAssignment.isRestrictedEdit(dataRow));
    };


    /***
     * intialize allocation search focus view operations
     * @param params
     */
    onAssignment = function(params: any) {
        const selectedIndex_ = 0;
        this.paramsOfFocusView = params;
        const selectedServiceItems: Array<ServiceItem> = [];
        let groupData: GenTourItem;
        let passengerCount = 0;
        const assignment: ServiceAssignment = params.data;
        if (!(assignment && assignment.serviceItemIdList && assignment.serviceItemIdList.length > 0)) {
            this.mapServiceItemIdList(params, assignment);
        }
        if (params && params.node && params.node.parent && params.node.parent.data && params.node.parent.data.serviceItems && params.node.parent.data.serviceItems.length > 0) {
            const serviceItems: Array<ServiceItem> = params.node.parent.data.serviceItems;
            const serviceItemIds: Array<number> = params.data.serviceItemIdList;
            serviceItems.forEach(item => {
                serviceItemIds.forEach(id => {
                    if (item.serviceItemId == id) {
                        selectedServiceItems.push(item);
                        passengerCount = passengerCount + item.servicePassengers.length;
                    }
                });
                }
            );
            groupData = params.node.parent.data;
            if (ServiceAssignment.isRestrictedEdit(assignment)) {
                const invalidAttemptWarning = new DialogModel(
                    true,
                    LEVEL.WARNING,
                    'Invalid Attempt',
                    'Cannot change assignment',
                    true,
                    2000
                );
                invalidAttemptWarning.disableClose = true;
                this.confirmDialog(invalidAttemptWarning);
            } else {
                const serviceTimeRange = groupData.jobPeriod;
                const categoryCode = groupData.categoryCode;
                let bookingReferenceIdStr = '';
                selectedServiceItems.forEach((item, index) => {
                    if (index === 0) {
                        bookingReferenceIdStr += item.bookingReferenceId;
                    } else {
                        bookingReferenceIdStr += ',' + item.bookingReferenceId;
                    }
                });
                const secondaryHeader = bookingReferenceIdStr + ' | Pax: ' + passengerCount + ' | ' +
                    serviceTimeRange + ' | ' + categoryCode;
                const input = {
                    isGenResourceAssignment: true,
                    selectedIndex: 0,
                    genTourItem: groupData
                };
                this.openResourceAssignmentView(input, secondaryHeader);
            }
        }
    };

    openResourceAssignmentView(input: any, secondaryHeader: string) {
        setTimeout(() => {
            const fabActions = [];
            const navItemsArray = this.configLoader.configurations.get(TCO.CONF.CONF_NAVIGATION);
            this.configLoader.configurations.get(TCO.CONF.CONF_FOCUS_VIEW).ALLOCATION_FOCUS_VIEW.fabAction
                .forEach(val => {
                    for (const key in navItemsArray) {
                        if (val === navItemsArray[key].id) {
                            fabActions.push(navItemsArray[key]);
                        }
                    }
                });
            const dataObject = new ModalData(
                LEVEL.SUCCESS,
                'Assign Supplier/Resource',
                null,
                null,
                '',
                AssignmentFocusViewComponent,
                input, {label: 'close'}, '', '', fabActions, '', secondaryHeader
            );
            this.focusviewCloseSubject = this.dataStore.get(DataKey.supplierCostFocusViewClose).subscribe(data => {

                if (data != null) {
                    this.focusviewCloseSubject.unsubscribe();
                    this.closeFocusView();
                }
            });

            this.focusViewService.confirm(dataObject).subscribe(res => {
                if (res) {
                    const e = document.getElementsByClassName('header-content__secondary-heading');
                }
            });
        }, 0);
    }

    closeFocusView() {
        if (this.focusViewService.close()) {
            this.focusViewService.close().subscribe(
                (res) => {
                }
            );
        }
    }

    // used in ag grid col def by method reference
    isOnAssignmentRestricted = function(params: any) {
        const assignment: ServiceAssignment = params.data;
        return ServiceAssignment.isRestrictedEdit(assignment);
    };

    // show dialog
    private confirmDialog(data: any): void {
        this.dialogService.confirm(data).subscribe((res) => {
        });
    }

    // used in ag grid col def by method reference
    onDeleteAssignment = function(params: any) {
        console.log('delete' + params);
        if (params && params.data && params.node && params.node.parent && params.node.parent.data &&
            params.node.parent.data.assignments && params.node.parent.data.assignments.indexOf(params.data) !=
            -1) {

            params.node.parent.data.assignments.splice(
                params.node.parent.data.assignments.indexOf(params.data),
                1
            );
            if (!params.node.parent.data.assignments.length) {
                params.node.parent.data.assignments.push(this.getEmptyAssignment());
            }
            this.gridApi.setRowData(this.genericGroupResults);
        }
        this.addParentTourOfAssignmentToChangedSet(params);
    };

    // used in ag grid col def by method reference
    onAddAssignment = function(params: any) {
        console.log('add' + params);
        if (params && params.node && params.node.parent && params.node.parent.data &&
            params.node.parent.data.assignments) {
            // params.node.parent.data['assignments'].push(this.getEmptyAssignment());

            const serviceItems: ServiceItem[] = params.node.parent.data ? params.node.parent.data.serviceItems : [];
            const allOtherAssignmentsInItem: ServiceAssignment[] = params.node.parent.data.assignments ?
                params.node.parent.data.assignments :
                [];
            const alreadySelectedItems: number[] = allOtherAssignmentsInItem && allOtherAssignmentsInItem.length >
            0 ?
                this.getAlreadyAddedItems(allOtherAssignmentsInItem) :
                [];

            const remainingItems: ServiceItem[] = serviceItems.filter(item => {
                    return !alreadySelectedItems.find(i => i === item.serviceItemId);
                }
            );
            const newAssignment = this.getEmptyAssignment();
            newAssignment.tempAssignmentId = 'Temp_' + allOtherAssignmentsInItem.length;
            newAssignment.serviceItemIdList = remainingItems.map(i => i.serviceItemId);
            params.node.parent.data.assignments.splice(
                params.node.parent.data.assignments.indexOf(params.data) + 1,
                0,
                newAssignment
            );
            this.gridApi.setRowData(this.genericGroupResults);
        }
    };

    onSelectServiceItems = function(params: any) {
        console.log('popup' + params);
        this.openItemSelectionModal(params);
    };

    public openItemSelectionModal(params) {
        const assignment: ServiceAssignment = params.data;
        const readOnly = ServiceAssignment.isRestrictedEdit(assignment);
        this.dataStore.set(DataKey.selectedServiceItemIdList, null, true);
        this.dataStore.set(DataKey.popupClose, null);
        const selectedItems = params.data && params.data.serviceItemIdList ? params.data.serviceItemIdList : [];
        let serviceItems: ServiceItem[];
        serviceItems = params.node.parent.data ? params.node.parent.data.serviceItems : [];
        if (serviceItems && serviceItems.length > 0) {
            this.setSelectedItems(serviceItems, selectedItems);
        }
        let previousAssignments = params.node.parent.data.assignments;
        previousAssignments = previousAssignments.filter(value => value.tempAssignmentId === undefined ||
            value.tempAssignmentId !== params.data.tempAssignmentId);
        let alreadySelectedItems = previousAssignments && previousAssignments.length > 0 ?
            this.getAlreadyAddedItems(previousAssignments) :
            [];
        alreadySelectedItems = alreadySelectedItems.filter(i => !selectedItems.includes(i));
        this.dataStore.set(DataKey.modalClose, null);
        const dataObject = new ModalData(LEVEL.SUCCESS, 'Select Service Items',
            null,
            null,
            'modal-basic--alt modal-basic--fixed-size',
            SelectServiceItemsComponent,
            {
                alreadySelectedItems,
                serviceItems,
                selectedItems,
                serviceAssignment: params,
                readOnly
            }
        );

        this.confirmModal(dataObject);

        this.dataStore.get(DataKey.popupClose).subscribe((data) => {
            if (data != null) {
                this.closeModal();
            }
        });
        this.subscribeForSelectedItems(params);
    }

    public onNotesClick(params: any) {
        const serviceAssignment: ServiceAssignment = params.data;
        this.dataStore.set(DataKey.popupClose, null);
        const selectedItemIds = params.data && params.data.serviceItemIdList ? params.data.serviceItemIdList : [];
        const allServiceItemsInItem: ServiceItem[] = params.node.parent.data ? params.node.parent.data.serviceItems : [];
        const selectedServiceItemsInAssignment: ServiceItem[] = allServiceItemsInItem.filter(sItem => selectedItemIds.includes(
            sItem.serviceItemId));
        const bookingNoteGroupsList: BookingNoteGroup[] = [];
        for (const sItem of selectedServiceItemsInAssignment) {
            bookingNoteGroupsList.push(
                {
                    bookingReferenceId: sItem.bookingReferenceId,
                    serviceItem: sItem,
                    notes: sItem.serviceNotes
                }
            );
        }
        serviceAssignment.assignmentNotes = serviceAssignment.assignmentNotes ? serviceAssignment.assignmentNotes : [];
        const clonedNotesArray = JSON.parse(JSON.stringify(serviceAssignment.assignmentNotes));
        const dataObject = new ModalData(LEVEL.SUCCESS, 'Notes',
            null,
            null,
            'modal-basic--alt modal-basic--fixed-size',
            NotesEditorComponent,
            {
                assignmentNotes : clonedNotesArray,
                bookingNoteGroups: bookingNoteGroupsList
            }
        );
        this.confirmModal(dataObject);
        this.dataStore.get(DataKey.popupClose).subscribe((data) => {
            if (data != null) {
                this.closeModal();
                if (data) {
                    serviceAssignment.assignmentNotes = clonedNotesArray;
                    params.colDef.field = 'assignmentNotes';
                    this.onCellValueChanged(params);
                }
            }
        });
    }

    // get all resourceTypes from datakey
    public getResourceTypes(text: string): Observable<any> {
        const clearItem = {
            name: '-- CLEAR --',
            nullSupplierForClearing: true
        };
        let array = [];
        if (text) {
            array = this.resourceTypes.filter(type => {
                if (type.code && type.code.toLowerCase().includes(text.toLowerCase())) {
                    return true;
                } else if (type.name) {
                    return type.name.toLowerCase().includes(text.toLowerCase());
                }
            });
        } else {
            array = Array.from(this.resourceTypes);
        }
        array.unshift(clearItem);
        return of(array);
    }

    // get resourceType/SupplierItem relevant to search
    getSupplierItems = function(params, rowData, text) {
        // if supplier exists - return only supplierItems
        if (rowData && rowData.supplierId) {
            return this.searchSupplierItem(params, rowData, text);
        } else {
            return this.getResourceTypes(text);
        }
    };

    public searchSupplierItem(params: any, rowData: ServiceAssignment, text: string): Observable<any> {

        const clearItem = {
            name: '-- CLEAR --',
            nullSupplierItemForClearing: true
        };
        return this.supplierService.retrieveSupplierItemsById(String(rowData.supplierId), text)
                   .map(
                       response => {

                           if (response != null) {
                               const items = ResponseUtil.getDataArray(response);
                               items.unshift(clearItem);
                               return items;
                           } else {
                               return [clearItem];
                           }

                       }
                       , catchError(
                           error => {
                               return [clearItem];
                           }
                       )
                   );

    }

    processStartTime = function(params) {
        const assignment: ServiceAssignment = params.data;

        if (assignment.startTime) {
            return assignment.startTime.toString().split(' ')[4];
        }
        return assignment.startTime;
    };

    processEndTime = function(params) {
        const assignment: ServiceAssignment = params.data;

        if (assignment.endTime) {
            return assignment.endTime.toString().split(' ')[4];
        }
        return assignment.endTime;
    };

    processSupplierItem = function(params) {
        return this.getSupplierItemName(params);
    };

    public getSupplierItemName(params: any) {
        const assignment: ServiceAssignment = params.data;
        const supplierItemCode: any = assignment ? assignment.supplierItem : null;
        if (supplierItemCode) {
            const supplierItem = this.resourceTypes.find(value => value.code === supplierItemCode);
            assignment.supplierItemName = supplierItem ? supplierItem.name : '';
        }
        return assignment;
    }

    // used in ag grid col def by method reference
    getSuppliers = function(params, rowData, text) {
        return this.onSupplierTextChange(params, rowData, text);
    };

    public onSupplierTextChange(params: any, rowData: ServiceAssignment, text: string): Observable<any> {
        const supplierSearchCriteria = new SupplierSearchCriteria();
        supplierSearchCriteria.name = text;
        supplierSearchCriteria.status = 'true';
        supplierSearchCriteria.rejectedSupplierIdList = rowData.rejectedSupplierIdList;

        const itemGroup: GenTourItem = params.node.parent.data;
        if (itemGroup) {

            // supplierSearchCriteria.profileType = itemGroup.typeCode;
            supplierSearchCriteria.serviceDate = params.node.parent.parent.data ?
                params.node.parent.parent.data.serviceDate :
                '';

        }
        const category = params.node.data.categoryCode;
        supplierSearchCriteria.resourceTypes = [];

        if (category) {
            supplierSearchCriteria.resourceTypes.push(itemGroup.supplierItemCode);
        }

        supplierSearchCriteria.start = 0;
        supplierSearchCriteria.size = 10;

        return this.supplierService.searchGenSuppliersForAssignment(supplierSearchCriteria)
                   .pipe(
                       map(
                           response => {

                               const suppliers = ResponseUtil.getDataArray(response);

                               const clearSupplier = new GenSupplierAllocationSummary();
                               clearSupplier.name = '-- CLEAR --';
                               clearSupplier.nullSupplierForClearing = true;
                               if (!suppliers.find(value => value.name === clearSupplier.name)) {
                                   suppliers.unshift(clearSupplier);
                               }

                               return suppliers;
                           }
                       ), catchError(
                           error => {
                               return [];
                           }
                       )
                   );
    }

    // used in ag grid col def by method reference
    getResources = function(params, rowData, text) {
        return this.onResourceTextChange(params, rowData, text);
    };

    public onResourceTextChange(params: any, rowData: ServiceAssignment, text: string): Observable<any> {

        const genericSearchCriteria = new GenericSearchCriteria();
        genericSearchCriteria.activeStatus = 'true';
        genericSearchCriteria.name = text;
        genericSearchCriteria.rejectedResourceIdList = rowData.rejectedResourceIdList;

        const itemGroup: GenTourItem = params.node.parent.data;
        if (itemGroup) {
            genericSearchCriteria.resourceType = itemGroup.categoryCode;
        }
        if (params && params.node && params.node.parent && params.node.parent.parent &&
            params.node.parent.parent.data && params.node.parent.parent.data.serviceDate) {
            genericSearchCriteria.serviceDate = params.node.parent.parent.data.serviceDate;
        }

        if (rowData && rowData.supplierItem) {
            genericSearchCriteria.resourceType = rowData.supplierItem;
        }

        genericSearchCriteria.start = 0;
        genericSearchCriteria.size = 10;

        if (rowData && rowData.supplierId) {
            genericSearchCriteria.supplierId = rowData.supplierId;
        }
        if (!(rowData && rowData.serviceItemIdList && rowData.serviceItemIdList.length > 0)) {
            this.mapServiceItemIdList(params, rowData);
        }
        if (rowData && rowData.serviceItemIdList && rowData.serviceItemIdList.length > 0) {
            genericSearchCriteria.serviceItems = rowData.serviceItemIdList.map((elem) => {
                return elem;
            }).join(',');
        }
        genericSearchCriteria.serviceType = 'NON_TRS';

        return this.genericResourceService.searchRecommendedResourcesForAllocation(genericSearchCriteria)
                   .pipe(
                       map(
                           response => {
                               const resources: any[] = [];
                               const resourcesUnProcessed = ResponseUtil.getDataArray(response);
                               resources.push(...this.mapRecommenderResourcesToGenResourceAllocationSummary(
                                   resourcesUnProcessed));
                               const clearResource = new GenResourceAllocationSummary();
                               clearResource.name = '-- CLEAR --';
                               clearResource.nullResourceForClearing = true;
                               if (!resources.find(value => value.name === clearResource.name)) {
                                   resources.unshift(clearResource);
                               }
                               return resources;
                           }), catchError(
                           error => {
                               return [];
                           }
                       )
                   );
    }

    mapRecommenderResourcesToGenResourceAllocationSummary(resourcesUnProcessed: any): GenResourceAllocationSummary[] {
        const list = [];
        if (resourcesUnProcessed && resourcesUnProcessed.length > 0) {
            for (const recSummary of resourcesUnProcessed) {
                const mapped = new GenResourceAllocationSummary();
                mapped.name = recSummary.resourceName;
                mapped.resourceName = recSummary.resourceName;
                mapped.resourceId = recSummary.resourceId;
                mapped.supplier = recSummary.resourceSupplier;

                if (recSummary.resourceSupplier && recSummary.resourceSupplier.resourceCostSummaries && recSummary.resourceSupplier.resourceCostSummaries && recSummary.resourceSupplier.resourceCostSummaries[0]){
                    mapped.resourceType = recSummary.resourceSupplier.resourceCostSummaries[0].resourceType;
                }
                list.push(mapped);
            }
        }
        return list;
    }

    public onClickSave() {
        console.log(this.assignmentChangedTours);
        if (this.saveGenericSubscription) {
            this.saveGenericSubscription.unsubscribe();
        }
        this.spinnerService.show();
        this.saveGenericSubscription = this.genReservationManagementService.saveGenReservations(this.assignmentChangedTours)
                                           .subscribe(
                                               response => {
                                                   this.spinnerService.hide();
                                                   if (this.commonHelper.dataValidity(response)) {
                                                       if (ResponseUtil.isSuccess(response)) {
                                                           this.common.showSnackBar(
                                                               'Successfully saved changes',
                                                               3000,
                                                               TcErrorType.TYPE.INFO
                                                           );
                                                           this.assignmentChangedTours = [];
                                                           this.changesAvailableForSave = false;
                                                           this.searchGenericGroupResults();
                                                       } else if (ResponseUtil.isError(response)) {
                                                           this.common.showSnackBar(
                                                               'Failed saving changes',
                                                               3000,
                                                               TcErrorType.TYPE.INFO
                                                           );
                                                       }

                                                       if (this.saveGenericSubscription) {
                                                           this.saveGenericSubscription.unsubscribe();
                                                       }

                                                   }

                                               },
                                               error => {
                                                   console.log(error);
                                                   this.spinnerService.hide();

                                                   this.common.showSnackBar(
                                                       'Failed to save changes',
                                                       3000,
                                                       TcErrorType.TYPE.ERROR
                                                   );
                                               }
                                           );
    }

    searchGenericGroupResults() {
        this.addFilterModelToCriteria();
        this.addSortModelToCriteria();

        this.dataStoreService.set(DataKey.genReservationSearchResultsFromService, null, true);
        this.genReservationManagementService.searchReservations(this.searchCriteria);
    }

    public onClickConfirm() {
        const assignments = this.gridApi.getSelectedRows();
        const assignmentIds = [];

        for (const data of assignments) {
            if (data.status !== AssignmentStatus.DMC_CONFIRM || data.status !== AssignmentStatus.SUPPLIER_CONFIRMED &&
                data.status !==
                AssignmentStatus.SUPPLIER_REJECTED) {
                assignmentIds.push(data.assignmentId);
            }
        }

        if (assignmentIds.length > 0) {
            const confirmSuccess = new DialogModel(
                true,
                LEVEL.WARNING,
                'Confirm Assignments',
                `
                        Confirming this action will trigger the following.<br><br>

                        1. Sending manifests to the allocated Suppliers/ Resources.<br>
                        2. Creating accounting ledger entries.<br><br>

                        Once confirmed this action cannot be undone.<br>
                        Do you wish to proceed?`,
                true,
                2000,
                null,
                'No',
                'Yes',
                true
            );
            confirmSuccess.disableClose = true;
            this.dialogService
                .confirm(confirmSuccess)
                .subscribe((res) => {
                    if (res === true) {
                        this.spinnerService.show();
                        this.reservationManagementService.confirmAssignments(assignmentIds).subscribe(
                            result => {
                                this.spinnerService.hide();

                                // refresh service items
                                this.searchGenericGroupResults();
                                // let confirmSuccess = new DialogModel(
                                //     true,
                                //     LEVEL.SUCCESS,
                                //     'Success',
                                //     `Successfully confirmed the assigned resources`,
                                //     true,
                                //     2000
                                // );
                                // this.dialogService
                                //     .confirm(confirmSuccess)
                                //     .subscribe();
                                this.common.showSnackBar(
                                    'Successfully confirmed the assigned resources',
                                    3000,
                                    TcErrorType.TYPE.INFO
                                );

                            },
                            error => {
                                console.log(error);
                                this.spinnerService.hide();

                                // let confirmError = new DialogModel(
                                //     true,
                                //     LEVEL.ERROR,
                                //     'Failed',
                                //     'Failed to confirm the assigned resources',
                                //     true,
                                //     2000
                                // );
                                // this.dialogService
                                //     .confirm(confirmError)
                                //     .subscribe();
                                this.common.showSnackBar(
                                    'Failed to confirm the assigned resources',
                                    3000,
                                    TcErrorType.TYPE.ERROR
                                );
                            }
                        );

                    }
                });

        }
    }

    public onSupplierRejectClick() {
        console.log('sup reject');
        const assignments = this.gridApi.getSelectedRows();
        const assignmentIds = [];

        for (const data of assignments) {
            if ((data.assignStatus === AssignmentStatus.SUPPLIER_CONFIRMED || data.assignStatus === AssignmentStatus.DMC_CONFIRM) &&
                !assignmentIds.includes(data.assignmentId)) {
                assignmentIds.push(data.assignmentId);
            }
        }

        if (assignmentIds.length > 0) {
            const confirmSuccess = new DialogModel(
                true,
                LEVEL.WARNING,
                'Supplier Reject Assignments',
                `
                        Rejecting this action will trigger the following.<br><br>

                        1. Removing Suppliers/ Resources from the assignment.<br>
                        2. Sending manifests to the rejected Suppliers/ Resources.<br>
                        3. Removing accounting ledger entries.<br><br>

                        Once confirmed this action cannot be undone.<br>
                        Do you wish to proceed?`,
                true,
                2000,
                null,
                'No',
                'Yes',
                true
            );
            confirmSuccess.disableClose = true;
            this.spinnerService.show();
            this.dialogService
                .confirm(confirmSuccess)
                .subscribe((res) => {
                    if (res === true) {
                        this.reservationManagementService.onSupplierRejectAssignments(assignmentIds).subscribe(
                            result => {
                                this.spinnerService.hide();

                                // refresh service items
                                this.searchGenericGroupResults();
                                this.common.showSnackBar(
                                    'Successfully set as supplier rejected the assigned resources',
                                    3000,
                                    TcErrorType.TYPE.INFO
                                );
                            },
                            error => {
                                this.spinnerService.hide();
                                this.common.showSnackBar(
                                    'Failed to set as supplier rejected the assigned resources',
                                    3000,
                                    TcErrorType.TYPE.ERROR
                                );
                            }
                        );

                    }
                });

        }
    }

    enableSaveButtonValidateFields() {
        this.saveButtonEnabled = true;
        this.changedAssignments = [];
        for (const assignmentChangedTour of this.assignmentChangedTours) {
            for (const itineraryItem of assignmentChangedTour.itineraryItems) {
                for (const assignment of itineraryItem.assignments) {
                    this.changedAssignments.push(assignment);
                    if (!this.isEmptyAssignment(assignment) &&
                        this.isIncompleteAssignment(assignment)) {
                        this.saveButtonEnabled = false;
                    }
                }
            }
        }

        if (this.saveButtonEnabled) {
            this.enableSaveButton();
        } else {
            this.disableSaveButton();
        }
    }

    isEmptyAssignment(assignment: ServiceAssignment) {
        if (assignment) {
            if (
                assignment.assignmentId > 0
                ||
                assignment.supplierId > 0
                ||
                assignment.resourceId > 0
                ||
                !!assignment.supplierItem
                ||
                !!assignment.startTime
                ||
                !!assignment.endTime
                ||
                (assignment.assignmentNotes && assignment.assignmentNotes.length > 0)
            ) {
                return false;
            }
        }
        return true;
    }

    isIncompleteAssignment(assignment: ServiceAssignment) {
        if (assignment) {
            if (
                assignment.supplierId &&
                assignment.supplierItem &&
                assignment.startTime &&
                assignment.endTime &&
                assignment.startTime !== '--' &&
                assignment.endTime !== '--'
            ) {
                return false;
            }
        }
        return true;
    }

    disableAllPaginatorButtons() {
        const config = [
            {
                buttonId: 'more',
                state: 'disable'
            },
            {
                buttonId: 'save',
                state: 'disable'
            },
            {
                buttonId: 'confirm',
                state: 'disable'
            },
            {
                buttonId: 'supplierReject',
                state: 'disable'
            }
        ];
        this.buttonState.next(config);
    }

    enableMoreButton() {
        const config = [
            {
                buttonId: 'more',
                state: 'enable'
            }
        ];
        this.buttonState.next(config);
    }

    disableMoreButton() {
        const config = [
            {
                buttonId: 'more',
                state: 'disable'
            }
        ];
        this.buttonState.next(config);
    }

    enableSaveButton() {
        const config = [
            {
                buttonId: 'save',
                state: 'enable'
            }
        ];
        this.buttonState.next(config);
    }

    disableSaveButton() {
        const config = [
            {
                buttonId: 'save',
                state: 'disable'
            }
        ];
        this.buttonState.next(config);
    }

    enableConfirmButton() {
        const config = [
            {
                buttonId: 'confirm',
                state: 'enable'
            }
        ];
        this.buttonState.next(config);
    }

    disableConfirmButton() {
        const config = [
            {
                buttonId: 'confirm',
                state: 'disable'
            }
        ];
        this.buttonState.next(config);
    }

    enableRejectButton() {
        const config = [
            {
                buttonId: 'supplierReject',
                state: 'enable'
            }
        ];
        this.buttonState.next(config);
    }

    disableRejectButton() {
        const config = [
            {
                buttonId: 'supplierReject',
                state: 'disable'
            }
        ];
        this.buttonState.next(config);
    }

    public ngOnDestroy(): void {
        if (this.searchCriteriaSubscription) {
            this.searchCriteriaSubscription.unsubscribe();
        }
        if (this.searchResultSubscription) {
            this.searchResultSubscription.unsubscribe();
        }
        if (this.moreSearchResultSubscription) {
            this.moreSearchResultSubscription.unsubscribe();
        }
        if (this.finalSearchResultsSubscription) {
            this.finalSearchResultsSubscription.unsubscribe();
        }
        if (this.subscribeOnSelectedServiceItemList) {
            this.subscribeOnSelectedServiceItemList.unsubscribe();
        }
        if (this.assignedSupplierSubscription) {
            this.assignedSupplierSubscription.unsubscribe();
        }
        if (this.assignedResourceSubscription) {
            this.assignedResourceSubscription.unsubscribe();
        }
        this.em.removeEvents([
            'EXPORT', 'SAVE_CRITERIA', 'SEND_MANIFESTS', 'TOUR_LIST'
        ]);
    }

    public onRowSelected($event: any) {
        const selectedRows = this.gridApi.getSelectedRows();
        console.log(selectedRows);
        if (!this.changesAvailableForSave && selectedRows && selectedRows.length > 0 &&
            ServiceAssignmentUtilService.isAssignedAll(selectedRows)) {
            if (!ServiceAssignmentUtilService.isRestrictedAny(selectedRows)) {
                this.enableConfirmButton();
            } else if (ServiceAssignmentUtilService.isSupplierConfirmedOrDmcConfirmedAll(selectedRows)) {
                this.enableRejectButton();
            } else {
                this.disableConfirmButton();
                this.disableRejectButton();
            }
        } else {
            this.disableRejectButton();
            this.disableConfirmButton();
        }

    }

    private sendManifests() {
        // this.spinnerService.show();
        const confirmSendManifests = new DialogModel(
            true,
            LEVEL.WARNING,
            'Send Manifests',
            ` Do you want to send manifests for all confirmed assignments
                       on service date ` + this.searchCriteria.date + ` ?`,
            true,
            2000,
            null,
            'Cancel',
            'Send',
            true
        );
        confirmSendManifests.disableClose = true;
        this.dialogService
            .confirm(confirmSendManifests)
            .subscribe((res) => {
                if (res === true) {
                    const documentCreationCriteria = new DocumentCreationCriteria();
                    documentCreationCriteria.documentFormat = DocumentFormat.pdf;
                    documentCreationCriteria.documentType = DocumentType.supplierManifest;
                    documentCreationCriteria.download = true;

                    const documentDataCriteria = new DocumentDataCriteria();
                    documentDataCriteria.searchStartDate = this.searchCriteria.startDate;
                    documentDataCriteria.searchEndDate = this.searchCriteria.endDate;
                    this.documentService.createAndDownloadDocument(documentCreationCriteria, documentDataCriteria);
                }
            });

    }

    private getTourList(docName: string, persist: boolean, download: boolean) {
        const documentCreationCriteria = new DocumentCreationCriteria();
        documentCreationCriteria.documentFormat = DocumentFormat.excel;
        documentCreationCriteria.documentType = DocumentType.tourList;
        documentCreationCriteria.documentName = docName;
        documentCreationCriteria.download = download;
        documentCreationCriteria.persist = persist;

        const documentDataCriteria = new DocumentDataCriteria();
        documentDataCriteria.searchStartDate = this.searchCriteria.startDate;
        documentDataCriteria.searchEndDate = this.searchCriteria.endDate;
        documentDataCriteria.serviceType = 'TRS';
        documentDataCriteria.productType = 'TOU';
        this.createDocSearchCriteria(documentDataCriteria);
        this.documentService.createAndDownloadDocument(documentCreationCriteria, documentDataCriteria);
    }

    createDocSearchCriteria(documentCreationCriteria: DocumentDataCriteria) {
        if (!this.searchCriteria.assignedStatus) {this.searchCriteria.assignedStatus = null; }
        documentCreationCriteria.searchStartDate = this.searchCriteria.startDate;
        documentCreationCriteria.searchEndDate = this.searchCriteria.endDate;
        if (this.searchCriteria.tourName) {
            documentCreationCriteria.tourName = this.searchCriteria.tourName;
        }
        if (this.searchCriteria.bookingDate) {
            documentCreationCriteria.bookingDate = this.searchCriteria.bookingDate;
        }
        if (this.searchCriteria.daysFrom) {
            documentCreationCriteria.daysFrom = this.searchCriteria.daysFrom;
        }
        if (this.searchCriteria.resourceType) {
            documentCreationCriteria.categoryCode = this.searchCriteria.resourceType;
        }
        if (this.searchCriteria.assignedStatus) {
            documentCreationCriteria.assignStatus = this.searchCriteria.assignedStatus;
        }
        if (this.searchCriteria.supplierId) {
            documentCreationCriteria.servicingSupplierId = this.searchCriteria.supplierId;
        }
        // documentCreationCriteria.size = this.searchCriteria.size;
        // documentCreationCriteria.start = this.searchCriteria.start;
    }

    private onGenerateDocumentsClick() {
        this.dataStore.set(DataKey.popupClose, null);
        const documentTypes = [
            {
                name: 'Gen Export Report',
                code: DocumentType.genExportReport,
                icon: 'unarchive'
            },
            {
                name: 'Tour List',
                code: DocumentType.tourList,
                icon: 'flight_takeoff'
            }
        ];

        const documentCreationModel = new DocumentCreationModel();
        documentCreationModel.autoName = true;
        documentCreationModel.download = true;

        const dataObject = new ModalData(
            LEVEL.SUCCESS,
            'Generate Documents',
            null,
            null,
            'modal-basic--alt modal-basic--fixed-size',
            DocumentCreationModalComponent,
            {
                documentTypes,
                documentCreationModel
            }
        );
        dataObject.disableClose = false;
        this.confirmModal(dataObject);
        const modalSubscription = this.dataStore.get(DataKey.popupClose).subscribe((data) => {
            if (data != null) {
                modalSubscription.unsubscribe();
                this.closeModal();
                if (data) {
                    modalSubscription.unsubscribe();
                    this.closeModal();
                    if (documentCreationModel.documentType) {
                        switch (documentCreationModel.documentType.code) {
                            case DocumentType.genExportReport:
                                this.export(
                                    documentCreationModel.documentName,
                                    !!documentCreationModel.persist,
                                    !!documentCreationModel.download
                                );
                                break;
                            case DocumentType.tourList:
                                this.getTourList(
                                    documentCreationModel.documentName,
                                    !!documentCreationModel.persist,
                                    !!documentCreationModel.download
                                );
                                break;
                        }
                    }
                }
            }
        });
    }

    private subscribeForSelectedItems(params: any) {
        this.subscribeOnSelectedServiceItemList = this.dataStore.get(DataKey.selectedServiceItemIdList).subscribe(
            (data) => {
                if (data != null) {
                    this.subscribeOnSelectedServiceItemList.unsubscribe();
                    // params.data = data;
                    this.addParentTourOfAssignmentToChangedSet(params);
                    this.enableSaveButtonValidateFields();
                    this.refreshDataGrid();
                }
            });
    }

    private setSelectedItems(serviceItems: ServiceItem[], selectedItems: any) {
        serviceItems.forEach(value => {
            value.checked = selectedItems.includes(value.serviceItemId);
        });
    }

    private getAlreadyAddedItems(previousAssignments: ServiceAssignment[]) {
        const alreadySelectedItems: any[] = [];
        if (previousAssignments && previousAssignments.length > 0) {
            for (const previousAssignment of previousAssignments) {
                if (previousAssignment.serviceItemIdList) {
                    alreadySelectedItems.push(...previousAssignment.serviceItemIdList);
                }
            }
        }
        return alreadySelectedItems;
    }

    public onShowCostBreakDown(params: any) {
        const serviceAssignment: ServiceAssignment = params.data;
        this.dataStore.set(DataKey.popupClose, null);
        const dataObject = new ModalData(LEVEL.SUCCESS, 'Cost Line',
            null,
            null,
            'modal-basic--alt modal-basic--fixed-size',
            SupplierPriceBreakdownComponent,
            {
                serviceAssignment
            }
        );
        this.confirmModal(dataObject);
        this.dataStore.get(DataKey.popupClose).subscribe((data) => {
            if (data != null) {
                this.closeModal();
            }
        });
    }

    public showAssignmentIncomplete(row: ServiceAssignment) {
        if (!this.saveButtonEnabled && row) {
            if (!this.changedAssignments.includes(row)) {
                return false;
            }
            if (this.isEmptyAssignment(row)) {
                return false;
            }
            return this.isIncompleteAssignment(row);
        }
        return false;
    }

    public getIncompleteToolTip(row: ServiceAssignment) {
        let text = '';
        if (row) {
            if (!row.supplierId || row.supplierId < 0) {
                text += 'Supplier is empty';
            }
            if (!row.supplierItem || row.supplierItem === '') {
                if (text) {
                    text += ' & ';
                }
                text += 'Supplier Item is empty';
            }
            if (!row.startTime || row.startTime === 'Invalid Time') {
                if (text) {
                    text += ' & ';
                }
                text += 'Start Time is empty';
            }
            if (!row.endTime || row.endTime === 'Invalid Time') {
                if (text) {
                    text += ' & ';
                }
                text += 'End Time is empty';
            }
        }
        return text;
    }
    public showPrivateIcon(row: GenTourItem) {
        return !!row.bookingRefIfPrivate;

    }



}
