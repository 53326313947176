import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SecurityModule } from '@tc/security';
import { TranslateModule } from '@ngx-translate/core';
import {DocumentCardComponent} from './document-card.component';
import {MatCardModule} from '@angular/material';
import {MatDividerModule} from "@angular/material/divider";

@NgModule({
    imports: [
        TranslateModule.forChild(),
        CommonModule,
        SecurityModule,
        MatCardModule,
        MatDividerModule
    ],
    exports: [
        DocumentCardComponent
    ],
    declarations: [
        DocumentCardComponent
    ],
    providers: [],
})
export class DocumentCardModule {
}