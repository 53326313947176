import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ServiceInformationComponent } from './service-information.component';
import { TranslateModule } from '@ngx-translate/core';
import {
    MatAutocompleteModule, MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule, MatNativeDateModule,
    MatOptionModule,
    MatSelectModule,
    MatSlideToggleModule
} from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from '@tc/pipes';

@NgModule({
  imports: [
      CommonModule,
      TranslateModule,
      MatInputModule,
      ReactiveFormsModule,
      MatSlideToggleModule,
      MatSelectModule,
      FormsModule,
      MatAutocompleteModule,
      MatFormFieldModule,
      PipesModule,
      MatOptionModule,
      MatDatepickerModule,
      MatNativeDateModule
  ],
  declarations: [ServiceInformationComponent],
    exports:[ServiceInformationComponent]
})
export class ServiceInformationModule { }
