import * as tslib_1 from "tslib";
import { DoCheck, EventEmitter, KeyValueDiffer, KeyValueDiffers, OnInit } from '@angular/core';
import { CurrencyFormat } from '@tc-core/model/it/codegen/ui/framework/currency-format';
import { Icon } from '@tc-core/model/it/codegen/ui/framework/icon';
import { TC } from '@tc-core/util';
import { ConfigLoader } from '@tc-core/util/framework';
import { TCO } from '../../constants';
import { TimelineEvent } from '../../widgets/framework/cg-timeline/timeline-event';
import { ServiceItemChanges } from '../../models/helper/service-item-changes';
import { SearchResultSummary } from '../../models/reservation/search-result-summary';
import { ResourceAvailability } from '../../models/resource/resource-availability';
var ResourceAllocationCardComponent = /** @class */ (function () {
    function ResourceAllocationCardComponent(configLoader, differs) {
        this.configLoader = configLoader;
        this.differs = differs;
        this.isRow1col4String = false;
        this.isBulkAssignment = false;
        this.resourceCalendarClick = new EventEmitter();
        this.eventClick = new EventEmitter();
        this.targetClick = new EventEmitter();
        this.isUpdated = false;
        this.isCancelled = false;
        this.assignments = [];
        this.timelineEvents = [];
        this.leftBufferHours = -1;
        this.rightBufferHours = -1;
        this.isTargetBlock = false;
        this.targetPointTime = new Date();
        this.targetBlockTimeStart = null;
        this.targetBlockTimeEnd = null;
        this.row1col1_customsStyles = [];
        this.row1col2_customsStyles = [];
        this.row1col3_customsStyles = [];
        this.row1col4_customsStyles = [];
        this.row2col1_customsStyles = [];
        this.row2col2_customsStyles = [];
        this.row2col3_customsStyles = [];
        this.row2col4_customsStyles = [];
        this.row3col1_customsStyles = [];
    }
    ResourceAllocationCardComponent.prototype.ngOnInit = function () {
        this.createTimelineEvents();
        this.initTimeline();
        this.createTargetPointOrBlock();
        this.overrideStyles();
        this.currencyFormat = this.getCurrencyFormat();
        this.summaryCardDiffer = this.differs.find(this.summaryContent).create();
    };
    ResourceAllocationCardComponent.prototype.ngDoCheck = function () {
        var changes = this.summaryCardDiffer.diff(this.summaryContent);
        if (changes) {
            console.log('summary card data changed');
            this.createTimelineEvents();
        }
    };
    ResourceAllocationCardComponent.prototype.initTimeline = function () {
        var timelineConfig = this.configLoader.configurations.get(TCO.CONF.CONF_TIMELINE_UNIT_CONFIG)['resource-allocation-timeline'];
        this.leftBufferHours = timelineConfig.leftBufferHours;
        this.rightBufferHours = timelineConfig.rightBufferHours;
    };
    ResourceAllocationCardComponent.prototype.overrideStyles = function () {
        this.row1col1_customsStyles = ['tc-strip-text--primary'];
        this.row1col2_customsStyles = ['tc-strip-text--primary'];
        this.row1col3_customsStyles = [];
        this.row1col4_customsStyles = [
            'tc-strip-text--right',
            'tc-strip-text--primary',
            'tc-strip__price',
            'tc-strip__price--x-large'
        ];
        this.row2col1_customsStyles = [];
        this.row2col2_customsStyles = ['tc-strip-text--primary'];
        this.row2col3_customsStyles = [];
        this.row2col4_customsStyles = ['tc-strip-text__label', 'tc-strip-text--right'];
    };
    ResourceAllocationCardComponent.prototype.getCurrencyFormat = function () {
        var currencyFormat;
        var currencySymbolPattern = this.configLoader.configurations.get(TC.CONF.CONF_SYSTEM).defaults.default_currency_symbol_pattern;
        currencyFormat = new CurrencyFormat(TCO.AppData.DEF_CURRENCY_TYPE, currencySymbolPattern);
        return currencyFormat;
    };
    ResourceAllocationCardComponent.prototype.createTimelineEvents = function () {
        var e_1, _a;
        this.assignments = this.summaryContent.assignments ? this.summaryContent.assignments : [];
        this.timelineEvents = [];
        try {
            for (var _b = tslib_1.__values(this.assignments), _c = _b.next(); !_c.done; _c = _b.next()) {
                var assignment = _c.value;
                var timelineEvent = new TimelineEvent();
                timelineEvent.startTime = assignment.startTimeObj;
                timelineEvent.endTime = assignment.endTimeObj;
                timelineEvent.waitingEndTime = assignment.waitingEndTimeObj;
                timelineEvent.assignment = assignment;
                this.timelineEvents.push(timelineEvent);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
    };
    ResourceAllocationCardComponent.prototype.createTargetPointOrBlock = function () {
        if (this.isBulkAssignment) {
            this.targetBlockTimeStart = this.itemGroup.startTimeObj;
            this.targetBlockTimeEnd = this.itemGroup.endTimeObj;
        }
        else {
            this.targetPointTime = new Date(this.bookingData.trsServiceItem.departureDateTime);
        }
    };
    ResourceAllocationCardComponent.prototype.onResourceCalendarClick = function (resource) {
        this.resourceCalendarClick.emit(resource);
    };
    ResourceAllocationCardComponent.prototype.onTargetClick = function (target) {
        this.targetClick.emit(this.summaryContent);
    };
    ResourceAllocationCardComponent.prototype.onEventClick = function (event) {
        this.eventClick.emit(event.assignment);
    };
    return ResourceAllocationCardComponent;
}());
export { ResourceAllocationCardComponent };
