import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChipInputModule } from '@tc/chip-input';
import { MappedRtypesGridSetupSearchCriteriaComponent } from './mapped-rtypes-grid-setup-search-criteria.component';

@NgModule({
  declarations: [MappedRtypesGridSetupSearchCriteriaComponent],
  exports: [
    MappedRtypesGridSetupSearchCriteriaComponent
  ],
  imports: [
    CommonModule,
    ChipInputModule
  ]
})
export class MappedRtypesGridSetupSearchCriteriaModule { }
