import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {UserProfileSearchCriteria} from '../../models/criteria/user-profile-search-criteria';
import {Subscription} from 'rxjs';
import {ChipInputComponent} from '@tc/chip-input';
import {ConfigLoader} from '@tc-core/util/framework/config-loader.service';
import {UserJourneyManager} from '@tc-core/util/framework/user-journey-manager.service';
import {DataKey, DataStoreService} from '../../services/util/framework/data-store.service';
import {ActionHandlerService} from '../../services/util/framework/action-handler-service.service';
import {EventManager} from '@tc-core/util/framework/event-manager.service';
import {ChipHandlerService} from '../../services/helpers/chip-handler.service';
import {DMCQueryParamsService} from '../../services/util/framework/dmc-query-params.service';
import {CommonHelper} from '@tc-core/util/helpers/common-helper.service';
import {SpinnerService} from '@tc-core/util/ui';
import {RootService} from '../../services/util/core-services/root.service';
import {TCO} from '../../constants';
import {TC} from '@tc-core/util';
import {TcApiError} from '@tc-core/model/it/codegen/tbx/ext/errors/tc-api-error';
import {TcHttpError} from '@tc-core/model/it/codegen/tbx/ext/errors/tc-http-error';
import {ChipInputService} from '@tc/chip-input/chip-input.service';
import {UserManagementService} from '../../services/user-management/user-management.service';
import {DateFormatter} from '@tc-core/util/system';
import {UserProfileService} from '../../services/backend-consumers/user-service/user-profile.service';
import {ApprovalStatus} from '../../models/user/user-service-profile';
import {ResourceType} from '../../models/reservation/assignment';

@Component({
  selector: 'tc-operator-acceptance-search-criteria',
  templateUrl: './operator-acceptance-search-criteria.component.html',
  styleUrls: ['./operator-acceptance-search-criteria.component.scss'],
  providers: [
    ChipHandlerService,
    {provide: 'ChipHandlerService', useExisting: ChipHandlerService},
    ChipInputService
  ]
})
export class OperatorAcceptanceSearchCriteriaComponent implements OnInit, OnDestroy {

  @Input() isInsideForm: boolean = false;
  @Input() searchCriteria: UserProfileSearchCriteria;
  @Input() searchCriteriaChipConfig: string;
  @Input() isSearchBarStyleNotAvailable = false;

  @Output() searchTrigger: EventEmitter<boolean> = new EventEmitter();

  private chipInputService;
  @ViewChild('operator_acceptance_search_chip_input') searchChipInput: ChipInputComponent;
  private chipHandlerSubject: Subscription = new Subscription();

  placeHolderText = 'Operator Acceptance Search';

  isChipsInit = false;
  availableChips: any[] = [];
  persistentChipsArr = [];
  persistentQParams: string[] = [];
  persistentQParamMap = new Map<string, string>();
  private selectedChips = [];
  modifiedChip: any;
  isDataLoaded = true;
  isTriggerSearch = true;
  displayChipsArr = [];
  isFocus = false;
  isChipListDisplay = false;

  tPagination: any;

  private relatedChipsSub: Subscription = new Subscription();
  private routerChangeSub: Subscription = new Subscription();
  private selectedChipsSub: Subscription = new Subscription();
  private selectedFieldsSub: Subscription = new Subscription();
  private chipValueUpdateSub: Subscription = new Subscription();
  private criteriaUpdateSub: Subscription = new Subscription();
  private deletedChipSub: Subscription = new Subscription();
  private searchResultSub: Subscription = new Subscription();
  private errorSub: Subscription = new Subscription();
  private relatedChipsProcessObsv: Subscription = new Subscription();
  private shortCutFocusSearchSub: Subscription = new Subscription();
  private shortCutFocusCloseSub: Subscription = new Subscription();
  private chipsSubjectSub: Subscription = new Subscription();
  private searchCriteriaUpdateSub: Subscription = new Subscription();
  private triggerSearchSub: Subscription = new Subscription();

  constructor(
      private configLoader: ConfigLoader,
      private dataStoreService: DataStoreService,
      private actionHandlerService: ActionHandlerService,
      private userJourneyManager: UserJourneyManager,
      private em: EventManager,
      private chipHandler: ChipHandlerService,
      private queryParamService: DMCQueryParamsService,
      private commonHelper: CommonHelper,
      private spinnerService: SpinnerService,
      private userProfileService: UserProfileService,
      private rootService: RootService,
      private dateFormatter: DateFormatter,
      private userManagementService: UserManagementService
  ) {
  }

  getQueryParams() {
    let queryParams: string[] = [];
    queryParams = this.queryParamService.getQueryParamsArray(new UserProfileSearchCriteria());

    this.searchCriteria = this.rootService.loadDataFromLocalStorage(queryParams, new UserProfileSearchCriteria(),
        TCO.AppData.OPERATOR_ACCEPTANCE_SEARCH_CRITERIA
    );
    if (!this.searchCriteria) {
      this.searchCriteria = new UserProfileSearchCriteria();
    }
    this.searchCriteria.approvalStatus = this.searchCriteria.approvalStatus ? this.searchCriteria.approvalStatus : ApprovalStatus.PENDING;
    this.searchCriteria = this.userManagementService.updateSearchCriteriaWithUserKC(this.searchCriteria);

    if (this.searchCriteria) {
      this.persistentQParams = [];
      this.persistentQParams = this.queryParamService.getNotNullQueryParamsArray(this.searchCriteria);

      // clear loaded search criteria here
    }
  }

  private shortCutObserver() {
   this.shortCutFocusSearchSub = this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'FOCUS_SEARCH_BAR').subscribe(e => {
      this.isFocus = true;
    });
   this.shortCutFocusCloseSub = this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'CLOSE_POPUPS').subscribe(e => {
      this.isFocus = false;
    });
  }

  public onFocus(event) {
    this.isFocus = event;
  }

  initSearchCriteriaChips() {
    this.isChipsInit = true;
    this.chipHandler.chipsArr = [];
    // read chip config
    let criteriaChipConfigs = null;
    if (!this.isInsideForm) {
      const chipConfigs = this.userManagementService.updateSearchChipsAccordingToUserKC(this.configLoader.configurations.get(
          TCO.CONF.CONF_OPERATOR_ACCEPTANCE_QUEUE_CHIP));
      criteriaChipConfigs = this.commonHelper.recreateJsonObject(chipConfigs);
    } else {
      const chipConfigs = this.userManagementService.updateSearchChipsAccordingToUserKC(this.configLoader.configurations.get(
          this.searchCriteriaChipConfig));
      criteriaChipConfigs = this.commonHelper.recreateJsonObject(chipConfigs);
    }
    if (criteriaChipConfigs && criteriaChipConfigs.chips) {
      criteriaChipConfigs.chips.forEach(chip => {
        this.chipHandler.preProcessChips(chip, criteriaChipConfigs.permissionKeys.keys);
      });
    }
   this.chipsSubjectSub = this.chipHandler.chipsSubject.subscribe(data => {
      if (data && JSON.stringify(data) !== JSON.stringify([]) && data.length ===
          criteriaChipConfigs.chips.length) {
        this.availableChips = data;
        this.chipHandler.chipsSubject.next(null);

        if (this.chipHandlerSubject) {
          this.chipHandlerSubject.unsubscribe();
        }

        // set values to display chips
        this.displayChipsArr = [];
        this.displayChipsArr = this.availableChips;

        this.dataStoreService.set(DataKey.chips, this.availableChips, true);

        this.chipInputService.init(this.searchCriteria, this.availableChips, false, true);
        this.isChipsInit = true;

        // implement chip persistence
        if (this.persistentQParams && this.persistentQParams.length > 0) {
          // create a queryParamMap
          for (const qParam of this.persistentQParams) {
            const qParamValue = this.searchCriteria[qParam];
            if (qParam && qParamValue) {
              this.persistentQParamMap.set(qParam, qParamValue);
            }
          }
          this.persistentChipsArr = [];
          // fill persistent chips
          this.availableChips.forEach(chip => {
            const persistChip = Object.assign({}, chip);
            persistChip.optionParams.data = [];
            let isPersist = false;
            if (chip.dataParams && chip.dataParams.length > 0) {
              chip.dataParams.forEach(dataParam => {
                if (this.persistentQParamMap.has(dataParam.paramValue)) {
                  isPersist = true;
                  const dataObj = {
                    key: dataParam.paramValue,
                    value: this.persistentQParamMap.get(dataParam.paramValue)
                  };
                  persistChip.optionParams.data.push(dataObj);
                }
              });
            }
            // push persist chips to an array
            if (isPersist) {
              this.persistentChipsArr.push(persistChip);
            }
          });
          // persist chips
          if (this.persistentChipsArr) {
            this.chipInputService.persistChips(this.persistentChipsArr);
            // trigger search when chips persisting
            this.isDataLoaded = false;
            // this.isTriggerSearch = false;
            this.searchCriteria.approvalStatus = ApprovalStatus.PENDING;
            this.onSearch(this.searchCriteria);
          }
        }
       this.criteriaUpdateSub = this.chipInputService.criteriaUpdate.subscribe((res) => {
          this.searchCriteria = res;
        });
      }
    });
  }

  ngOnInit() {
    this.chipInputService = this.searchChipInput.criteriaInputService;
    this.tPagination = this.configLoader.configurations.get(TCO.CONF.CONF_PAGINATION_CONFIG).OPERATOR_ACCEPTANCE_SEARCH;
    this.dataStoreService.set(DataKey.opeAcceptanceSearchCriteria, null, true);
    this.handleQuickActions();
    this.routerChangeSub = this.userJourneyManager.routeChanges
        .subscribe(e => {
          if (e) {
            this.chipInputService.fullReset();
            this.chipHandler.chipsArr = [];
            if (!this.isInsideForm) {
              this.getQueryParams();
              this.initSearchCriteriaChips();
            }

            console.log('displayChipsArr', this.displayChipsArr);
            this.resetQueryParams();
          }
        });
    if (!this.isInsideForm) {
      this.getQueryParams();
      if (!this.isChipsInit) {
        this.initSearchCriteriaChips();
      }

    }
    this.setSearchResultsObserver();
    this.errorObserver();
    this.selectedChipsUpdate();
    this.handleInterRelatedChips();
    this.notifyChipDelete();
    this.shortCutObserver();
  }

  onSearch(event) {
    this.isDataLoaded = false;
    this.searchCriteria = event;

    if (this.tPagination && this.tPagination.initialFetchingPages &&
        this.tPagination.defaultPageResultCount) {
      this.searchCriteria.size = this.tPagination.initialFetchingPages *
          this.tPagination.defaultPageResultCount;
      this.searchCriteria.start = 0;
    }

    this.userJourneyManager.canProceed.next(false);

    if (JSON.stringify(this.searchCriteria) !== JSON.stringify({})) {
      this.dataStoreService.set(DataKey.opeAcceptanceSearchCriteria, this.searchCriteria, true);

      this.dataStoreService.set(DataKey.opeAcceptanceSearchResultsFromService, null, true);
      // this.userProfileService.getOperatorServiceProfiles(JSON.parse(JSON.stringify(this.searchCriteria)));

      let queryParams: string[] = [];
      queryParams = this.queryParamService.getQueryParamsArray(this.searchCriteria);
      this.rootService.setDataToLocalStorage(TCO.AppData.OPERATOR_ACCEPTANCE_SEARCH_CRITERIA, this.searchCriteria, true,
          queryParams, true
      );

      // remove default serviceType
      this.searchCriteria.restrictedServiceTypes = [];
      this.searchCriteria.restrictedServiceTypes.push(ResourceType.default);

      // add allowed serviceTypes
      this.searchCriteria.allowedServiceTypes = [];
      this.searchCriteria.allowedServiceTypes.push(ResourceType.operator);

      // search data
      this.userProfileService.getOperatorServiceProfiles(this.searchCriteria);

      this.isTriggerSearch = false;
      this.isDataLoaded = false;
      this.searchTrigger.emit(true);
    } else {
      setTimeout(() => {
        this.isDataLoaded = true;
        this.isTriggerSearch = true;
      }, 0);
    }
  }

  getUpdatedFieldsDataObject(chip, criteria: UserProfileSearchCriteria) {
    const dataObjArr = [];
    if (chip && chip.dataParams && chip.dataParams.length > 0) {
      chip.dataParams.forEach(dataParam => {
        const dataObj = {
          key: '',
          value: ''
        };
        dataObj.key = dataParam.paramValue;
        dataObj.value = criteria[dataParam.paramValue];

        dataObjArr.push(dataObj);
      });
    }
    return dataObjArr;
  }

  setSearchResultsObserver() {
    this.searchResultSub = this.dataStoreService.get(DataKey.opeAcceptanceSearchResultsFromService)
        .subscribe((data) => {
          if (this.commonHelper.dataValidity(data)) {
            this.isDataLoaded = true;
            this.isTriggerSearch = false;
          } else if (this.commonHelper.isEmptyData(data)) {
            this.isDataLoaded = true;
          } else if (data instanceof TcApiError) {
            this.isDataLoaded = true;
          } else if (data instanceof TcHttpError) {
            this.isDataLoaded = true;
          }
        }, (error: any) => {
          console.log(error);
        });
  }

  private errorObserver() {
    this.errorSub = this.dataStoreService.get(DataKey.error).subscribe((data) => {
      if (data && JSON.stringify(data) !== JSON.stringify({})) {
        setTimeout(() => {
          this.isDataLoaded = true;
        }, 0);
        this.dataStoreService.set(DataKey.error, null);
      }
    });
  }

  private resetQueryParams() {
    const criteria: UserProfileSearchCriteria = this.rootService.loadDataFromLocalStorage([],
        new UserProfileSearchCriteria(), TCO.AppData.OPERATOR_ACCEPTANCE_SEARCH_CRITERIA
    );
    let queryParams: string[] = [];
    queryParams = this.queryParamService.getQueryParamsArray(criteria);
    this.rootService.setDataToLocalStorage(TCO.AppData.OPERATOR_ACCEPTANCE_SEARCH_CRITERIA, this.searchCriteria,
        true, queryParams, true
    );
  }

  public onCancel(event) {
    console.log('cancelled');
  }

  public onChipListDisplay(event) {
    this.dataStoreService.set(DataKey.chipListDisplay, null, true);
    this.isChipListDisplay = event;
    this.dataStoreService.set(DataKey.chipListDisplay, this.isChipListDisplay, true);
  }

  handleQuickActions() {
   this.triggerSearchSub = this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'TRIGGER_SEARCH_CRITERIA').subscribe((e) => {
      this.triggerSearch(e.data);
    });
  }

  triggerSearch(data: string) {
    this.searchCriteria = new UserProfileSearchCriteria();
    this.actionHandlerService.fillCriteriaByActionData(data, this.searchCriteria);

    this.chipInputService.hardReset();

    const requiredChips = this.chipHandler.getChipsFromCriteria(
        this.searchCriteria,
        this.availableChips,
        false
    );

    this.chipInputService.hardReset();
    this.chipInputService.persistChips(requiredChips, true);
    this.onSearch(this.searchCriteria);
  }

  private handleInterRelatedChips() {
    this.relatedChipsSub = this.chipInputService.relatedChipsSubject.subscribe(
        (data) => {
          let toggleChips = [];
          if (data && data.chip && JSON.stringify(data) !== JSON.stringify({})) {
            this.modifiedChip = data.chip;
            // TODO: refine chip input service: inter related chips
            if (data.chip.type === 'CALENDAR') {
              if (data.chip.id === 'START_DATE') {
                const startDate = data.date;
                const nights = 2;
                const currentEndDate = this.checkDependentForCalculateRelatedDate(data.chip);
                if (currentEndDate) {
                  this.setMinDateForRelatedDateChips(data.chip, data.date);

                  if (this.dateFormatter.isAfter(startDate, currentEndDate)) {
                    this.chipHandler.handleInterRelatedChips(
                        data.chip,
                        this.dateFormatter.addDaysToDate(startDate, nights)
                    );
                  } else {
                    this.chipHandler.handleInterRelatedChips(
                        data.chip,
                        currentEndDate
                    );
                  }
                }
              }
            } else {
              this.chipHandler.handleInterRelatedChips(data.chip, data.options.value[0]);
            }

            if (data.chip.optionParams.toggleChips && data.chip.optionParams.toggleChips.length > 0) {
              toggleChips = data.chip.optionParams.toggleChips;
              if (toggleChips && data.options && data.options.value[0].value) {
                this.chipInputService.selectedChipsBasedOnStateChange(toggleChips);
              } else {
                this.chipInputService.deleteChipsBasedOnStateChange(toggleChips);
              }
            }
          }
        }
    );

    this.relatedChipsProcessObsv = this.chipHandler.relatedChipsProcessSubject.subscribe((result) => {
      if (result && JSON.stringify(result) !== JSON.stringify([]) &&
          this.modifiedChip.optionParams.relatedChips &&
          this.modifiedChip.optionParams.relatedChips.length > 0 &&
          result.length === this.modifiedChip.optionParams.relatedChips.length) {

        // if chip date is passed date it will be reset for today
        result.forEach(chip => {
              if (chip.dataParam === 'DATE' && chip.criteriaId === 'START_DATE') {
                chip.options = this.dateFormatter.dateFromString(chip.options) > this.dateFormatter.getToday()
                    ? chip.options
                    : this.dateFormatter.dateAsString(this.dateFormatter.getToday());
              }
              if (chip.dataParam === 'DATE' && chip.criteriaId === 'END_DATE') {
                /** Following code lines are commented to give the access to set past dates for End Date chip. uncomment below lines when you need to allow the restrictions * */
                //     chip.options = this.dateFormatter.dateFromString(chip.options) > this.dateFormatter.getToday()
                //         ? chip.options
                //         : this.dateFormatter.dateAsString(this.dateFormatter.getToday());
              }
            }
        );

        // pass related chips to chip input service
        this.chipInputService.handleInterRelatedChips(result);
      }
    });
  }

  private selectedChipsUpdate() {
    this.criteriaUpdateSub = this.chipInputService.selectedChipsSubject.subscribe(selectedChips => {
      this.selectedChips = selectedChips;

      if (selectedChips && JSON.stringify(selectedChips) !== JSON.stringify([])) {
        // toggle chips when selecting BRANCH_OF_CLIENT_ID chip
        for (const selectedChip of this.selectedChips) {
          if (selectedChip.chip.id === 'BRANCH_OF_CLIENT_ID' && selectedChip.chip.optionParams.toggleChips &&
              selectedChip.chip.optionParams.toggleChips.length > 0) {
            this.deleteToggleChips(selectedChip.chip.optionParams.toggleChips);
            this.disableChips(selectedChip.chip.optionParams.toggleChips);
            break;
          }
        }
      }
    });
  }

  private notifyChipDelete() {
    this.deletedChipSub = this.chipInputService.deletedChipsSubject.subscribe(deletedChip => {
      if (JSON.stringify(deletedChip) !== JSON.stringify({}) && deletedChip !== null && deletedChip !==
          undefined) {
        if (deletedChip.id === 'CLIENT_TYPE') {
          const deleteChips = this.selectedChips.map(chip => chip.chip);
          this.deleteSelectedChips(deleteChips);
          this.enableChips(this.availableChips);
        } else if (deletedChip.id === 'BRANCH_OF_CLIENT_ID') {
          if (deletedChip.optionParams.toggleChips && deletedChip.optionParams.toggleChips.length > 0) {
            this.enableChips(deletedChip.optionParams.toggleChips);
          }
        }
      }
    });
  }

  /**
   * Delete toggle chips if already selected
   *
   * @param toggleChips
   */
  private deleteToggleChips(toggleChips: any[]) {
    const deleteChips = [];
    toggleChips.forEach(toggleChip => {
      for (const selectedChip of this.selectedChips) {
        if (selectedChip.chip.id === toggleChip.criteriaId) {
          deleteChips.push(selectedChip.chip);
          break;
        }
      }
    });
    this.deleteSelectedChips(deleteChips);
  }

  private deleteSelectedChips(deleteChips: any[]) {
    deleteChips.forEach(chip => {
      this.chipInputService.deleteChip(chip, true);
    });
  }

  private enableChips(enableChips: any[]) {
    enableChips.forEach(enableChip => {
      this.chipInputService.modifyChipsStatus(enableChip, false);
    });
  }

  private disableChips(disableChips: any[]) {
    disableChips.forEach(disableChip => {
      this.chipInputService.modifyChipsStatus(disableChip, true);
    });
  }

  private checkDependentForCalculateRelatedDate(chip: any) {
    let dateParam;
    if (chip.optionParams && chip.optionParams.relatedChips) {
      const dependent = chip.optionParams.behaviouralData.find(
          behData => behData.key === 'DATE_RELATED_DEPENDENT');
      if (dependent) {
        dateParam = this.getValueOfDependentParameter(dependent.value);
      }
    }
    return dateParam;
  }

  private getValueOfDependentParameter(dependent: any): any {
    if (this.chipInputService && this.chipInputService.criteria &&
        this.chipInputService.criteria[dependent.paramValue]) {
      return this.chipInputService.criteria[dependent.paramValue];
    }
    return '';
  }

  private setMinDateForRelatedDateChips(chip: any, date: string) {
    if (date && chip && chip.optionParams && chip.optionParams.relatedChips) {
      const minDate = this.dateFormatter.dateFromString(date);
      chip.optionParams.relatedChips.forEach(relateChip => {
        if (relateChip.criteriaType === 'CALENDAR') {
          relateChip.data = [{key: 'minDate', value: minDate}];
        }
      });
    }
  }

  public ngOnDestroy(): void {
    if (this.relatedChipsSub) {
      this.relatedChipsSub.unsubscribe();
    }
    if (this.routerChangeSub) {
      this.routerChangeSub.unsubscribe();
    }
    if (this.selectedChipsSub) {
      this.selectedChipsSub.unsubscribe();
    }
    if (this.selectedFieldsSub) {
      this.selectedFieldsSub.unsubscribe();
    }
    if (this.chipValueUpdateSub) {
      this.chipValueUpdateSub.unsubscribe();
    }
    if (this.criteriaUpdateSub) {
      this.criteriaUpdateSub.unsubscribe();
    }
    if (this.deletedChipSub) {
      this.deletedChipSub.unsubscribe();
    }
    if (this.searchResultSub) {
      this.searchResultSub.unsubscribe();
    }
    if (this.errorSub) {
      this.errorSub.unsubscribe();
    }
    if (this.relatedChipsProcessObsv) {
      this.relatedChipsProcessObsv.unsubscribe();
    }
    if (this.shortCutFocusSearchSub) {
      this.shortCutFocusSearchSub.unsubscribe();
    }
    if (this.shortCutFocusCloseSub) {
      this.shortCutFocusCloseSub.unsubscribe();
    }
    if (this.chipsSubjectSub) {
      this.chipsSubjectSub.unsubscribe();
    }
    if (this.searchCriteriaUpdateSub) {
      this.searchCriteriaUpdateSub.unsubscribe();
    }
    if (this.triggerSearchSub) {
      this.triggerSearchSub.unsubscribe();
    }
  }
}
