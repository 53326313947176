export class TrsSupplierCostSummary {
    supplierId: number;
    code: string;
    name: string;
    country: string;
    city: string;
    currency: string;
    transferMode: string;
    route: string;
    vehicleModel: string;
    days: string;
    seasonId: number;
    seasonName: string;
    totalCost: number;
    estimatedTotalCost: number;
    unitFare: number;
    adultFare: number;
    childFare: number;
    infantFare: number;
    teenFare: number;
    totalUnitFare: number;
    totalAdultFare: number;
    totalChildFare: number;
    totalInfantFare: number;
    totalTeenFare: number;
    capacity: number;
    isUnitFare: boolean;
}