import {
    AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChild,
    ViewChildren
} from '@angular/core';
import { Pager } from '@tc-core/model/it/codegen/ui/framework/pager';
import { Sorter } from '@tc-core/model/it/codegen/ui/framework/sorter';
import { SummaryCardData } from '@tc-core/model/it/codegen/ui/framework/summary-card-data';
import { Resource } from '../../../models/resource/resource';
import { ExpansionPanelDirective } from '@tc/directives/expansion-panel';
import { Season } from '../../../models/supplier/season';

@Component({
    selector: 'tc-adv-generic-search-results',
    templateUrl: './adv-generic-search-results.component.html'
})
export class AdvGenericSearchResultsComponent implements OnInit, AfterViewInit {

    @Input() sorter: Sorter;
    @Input() sortingHandler: any;
    @Input() sorters: Sorter[] = [];

    @Input() pager: Pager;

    @Input() isSingleResultExpand: boolean = true;

    @Input() genericResourceResultsList: SummaryCardData[];
    @Input() selectedGenericResourceSummary: SummaryCardData;
    @Input() detailedResource: Resource;
    @Input() resourceTypes: any[];
    @Input() trsModes: any[] = [];
    @Input() selectedResourceCost: Season[] = [];

    @Output() resourceSelect: EventEmitter<SummaryCardData> = new EventEmitter();
    @Output() edit: EventEmitter<Resource> = new EventEmitter();
    @Output() editAsNew: EventEmitter<Resource> = new EventEmitter();
    @Output() calendarClick: EventEmitter<SummaryCardData> = new EventEmitter();
    @Output() cardScroll: EventEmitter<number> = new EventEmitter<number>();

    public expandedPanelIndex: number = -1;

    private expandedPanel = null;
    private expansionPanel = null;

    public selectionIndex = '1';

    @ViewChildren(ExpansionPanelDirective) _expansionPanels: QueryList<ExpansionPanelDirective>;
    @ViewChild('resourceExp') clientExp: ElementRef;

    constructor() { }

    ngOnInit() {
    }

    ngAfterViewInit() {
        if (this.isSingleResultExpand && this.genericResourceResultsList && this.genericResourceResultsList.length === 1) {
            if (this._expansionPanels && this._expansionPanels !== undefined) {
                this.selectionIndex = '1';
                this.openExpansionPanel();
            }
        }
    }

    public openExpansionPanel() {
        this.onGenericResourceSelection(this.genericResourceResultsList[+this.selectionIndex - 1], +this.selectionIndex - 1);

        // close expanded panel
        if (this.expandedPanelIndex !== -1) {
            this.expandedPanel.expansionState.next(true);
        }

        // open focused panel
        this.expansionPanel = this._expansionPanels.find((expansionElement, i) => {
            return (i + 1) === +this.selectionIndex - ((this.pager.currPage) * this.pager.itemsPerPage);
        });

        // same page
        if (this.expansionPanel !== null && this.expansionPanel !== undefined) {
            this.expansionPanel.expansionState.next(false);
            // another page
        } else {
            let page = Math.ceil(+this.selectionIndex / ((this.pager.currPage + 1) * this.pager.itemsPerPage));
            this.pager.currPage = page;
        }
    }

    public onHeaderClick($event: any) {
        if($event.srcElement.className !== 'material-icons'){
            this.selectedGenericResourceSummary = null;
        }
    }

    public onActionClick(genericResource: SummaryCardData, $event: any) {

    }

    public onEditClick($event) {
        this.edit.emit(this.detailedResource);
    }

    public onGenericResourceSelection(genericResourceSummary: SummaryCardData, index?: number) {
        this.selectedGenericResourceSummary = genericResourceSummary;
        if (genericResourceSummary) {
            this.resourceSelect.emit(genericResourceSummary);
            this.cardScroll.emit(index);
        }
    }

    public getHeader() {
        if (this.detailedResource && this.detailedResource.genericResource) {
            return this.detailedResource.genericResource.title +
                ' ' + this.detailedResource.genericResource.firstName +
                ' ' + this.detailedResource.genericResource.lastName;
        } else if (this.selectedGenericResourceSummary) {
            if (this.selectedGenericResourceSummary.data.value['name']) {
                return this.selectedGenericResourceSummary.data.value['name'];
            }else{
                return '-';
            }

        }
    }

    public onEditAsNewClick($event: any) {
        this.editAsNew.emit(this.detailedResource);
    }

    public onCalendarClick($event: any) {
        this.calendarClick.emit(this.selectedGenericResourceSummary);
    }
}
