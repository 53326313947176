import { Component, Input, OnInit } from '@angular/core';
import { TC } from '@tc-core/util/constants';
import { Resource } from '../../../models/resource/resource';

@Component({
    selector: 'tc-henna-artist-specific-details-card',
    templateUrl: './henna-artist-specific-details-card.component.html'
})
export class HennaArtistSpecificDetailsCardComponent implements OnInit {

    @Input() resource: Resource;

    EMPTY_CHAR = TC.FORMAT.EMPTY_CHAR;

    constructor() { }

    ngOnInit() {
    }

}
