import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {DataKey, DataStoreService} from '../../../services/util/framework/data-store.service';
import {Journey} from '@tc-core/model/it/codegen/ui/framework/journey';
import {TCO} from '../../../constants';
import {TC} from '@tc-core/util';
import {TcApiError} from '@tc-core/model/it/codegen/tbx/ext/errors/tc-api-error';
import {TcHttpError} from '@tc-core/model/it/codegen/tbx/ext/errors/tc-http-error';
import {ActivatedRoute} from '@angular/router';
import {ConfigLoader, EventManager, UserJourneyManager} from '@tc-core/util/framework';
import {ActionHandlerService} from '../../../services/util/framework/action-handler-service.service';
import {ChipHandlerService} from '../../../services/helpers/chip-handler.service';
import {DMCQueryParamsService} from '../../../services/util/framework/dmc-query-params.service';
import {CommonHelper} from '@tc-core/util/helpers';
import {SpinnerService} from '@tc-core/util/ui';
import {RootService} from '../../../services/util/core-services/root.service';
import {ChipInputComponent} from '@tc/chip-input';
import {Subscription} from 'rxjs';
import {ChipInputService} from '@tc/chip-input/chip-input.service';
import {BoardBasisSetupSearchCriteria} from '../../../models/criteria/board-basis-setup-search-criteria';

@Component({
    selector: 'tc-board-basis-setup-search-criteria',
    templateUrl: './board-basis-setup-search-criteria.component.html',
    providers: [
        ChipHandlerService,
        {provide: 'ChipHandlerService', useExisting: ChipHandlerService},
        ChipInputService
    ]
})
export class BoardBasisSetupSearchCriteriaComponent implements OnInit {

    @Input() isInsideForm = false;
    @Input() boardBasisSetupSearchCriteria: BoardBasisSetupSearchCriteria = new BoardBasisSetupSearchCriteria();
    @Input() searchCriteriaChipConfig: string;
    @Input() isSearchBarStyleNotAvailable = false;

    @Input() placeHolderText = 'Search Board Basis Setup';

    @Output() searchTrigger: EventEmitter<boolean> = new EventEmitter();
    @Output() searchCriteria: EventEmitter<any> = new EventEmitter();

    tPagination: any;

    journey: string = null;

    isChipsInit = false;
    availableChips: any[] = [];
    persistentChipsArr = [];
    persistentQParams: string[] = [];
    persistentQParamMap = new Map<string, string>();
    modifiedChip;
    isDataLoaded = true;
    isTriggerSearch = true;
    displayChipsArr = [];
    isFocus = false;
    isChipListDisplay = false;

    @ViewChild('board_basis_setup_search_chip_input') boardBasisSetupSearchChipInput: ChipInputComponent;

    private relatedChipsObsv: Subscription = new Subscription();
    private routerChangeSubscription: Subscription;
    private selectedChipsObsv: Subscription = new Subscription();
    private selectedFieldsObsv: Subscription = new Subscription();
    private chipValueUpdateSub: Subscription = new Subscription();
    private criteriaUpdateSubscription: Subscription = new Subscription();
    private deletedChipObsv: Subscription = new Subscription();
    private searchResultObserver: Subscription = new Subscription();
    private errorsObserver: Subscription = new Subscription();
    private userJourneyObserver: Subscription = new Subscription();
    private focusSearchBarObserver: Subscription = new Subscription();
    private closePopUpObserver: Subscription = new Subscription();
    private triggerSearchCriteriaObserver: Subscription = new Subscription();

    private selectedChips = [];
    private chipInputService;
    private chipHandlerSubject: Subscription = new Subscription();

    constructor(
        private activatedRoute: ActivatedRoute,
        private configLoader: ConfigLoader,
        private userJourneyManager: UserJourneyManager,
        private dataStoreService: DataStoreService,
        private actionHandlerService: ActionHandlerService,
        private em: EventManager,
        private chipHandler: ChipHandlerService,
        private queryParamService: DMCQueryParamsService,
        private commonHelper: CommonHelper,
        private spinnerService: SpinnerService,
        private rootService: RootService,
    ) {
    }


    ngOnInit() {
        this.chipInputService = this.boardBasisSetupSearchChipInput.criteriaInputService;

        // clear data store
        this.dataStoreService.set(DataKey.costingTypeSearchCriteria, null, true);

        this.handleQuickActions();

        this.userJourneyObserver = this.userJourneyManager.journey
            .subscribe((journey: Journey) => {
                if (journey && JSON.stringify(journey) !== JSON.stringify({})) {
                    this.journey = journey.id;
                }
            });

        // resetting the view
        this.routerChangeSubscription = this.userJourneyManager.routeChanges
            .subscribe(e => {
                if (e) {
                    this.chipInputService.fullReset(); // fully reset chips
                    this.chipHandler.chipsArr = []; // reset chips array loaded from config
                    // check journey begins from quick book
                    if (!this.isInsideForm) {
                        this.getQueryParams();
                        this.initSearchCriteriaChips();
                    }

                    console.log('displayChipsArr', this.displayChipsArr);
                    this.resetQueryParams();
                }
            });

        if (!this.isInsideForm) {
            this.getQueryParams();
            if (!this.isChipsInit) {
                this.initSearchCriteriaChips(); // load everything from start
            }

            this.boardBasisSetupSearchCriteria = new BoardBasisSetupSearchCriteria();

        }

        this.getSearchObserver();
        this.errorObserver();
        this.selectedChipsUpdate();
        this.handleInterRelatedChips();
        this.notifyChipDelete();
        this.shortCutObserver();
    }

    getQueryParams() {
        let queryParams: string[] = [];
        queryParams = this.queryParamService.getQueryParamsArray(new BoardBasisSetupSearchCriteria());

        this.boardBasisSetupSearchCriteria = this.rootService.loadDataFromLocalStorage(queryParams, new BoardBasisSetupSearchCriteria(),
            TCO.AppData.BOARD_BASIS_SETUP_SEARCH_CRITERIA
        );

        // get persisting query params
        if (this.boardBasisSetupSearchCriteria) {
            this.persistentQParams = [];
            this.persistentQParams = this.queryParamService.getNotNullQueryParamsArray(this.boardBasisSetupSearchCriteria);
        }
    }

    private shortCutObserver() {
        this.focusSearchBarObserver = this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'FOCUS_SEARCH_BAR')
            .subscribe(e => {
                this.isFocus = true;
            });
        this.closePopUpObserver = this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'CLOSE_POPUPS')
            .subscribe(e => {
                this.isFocus = true;
            });
    }

    public onFocus(event: boolean) {
        this.isFocus = event;
    }

    initSearchCriteriaChips() {
        this.isChipsInit = true;
        this.chipHandler.chipsArr = [];
        // read chip config
        let criteriaChipConfigs = null;
        if (!this.isInsideForm) {
            const chipConfigs = this.configLoader.configurations.get(TCO.CONF.CONF_BOARD_BASIS_SETUP_SEARCH_CRITERIA_CHIP);
            criteriaChipConfigs = this.commonHelper.recreateJsonObject(chipConfigs);
        } else {
            const chipConfigs = this.configLoader.configurations.get(this.searchCriteriaChipConfig);
            criteriaChipConfigs = this.commonHelper.recreateJsonObject(chipConfigs);
        }
        if (criteriaChipConfigs && criteriaChipConfigs.chips) {
            criteriaChipConfigs.chips.forEach(chip => {
                chip = this.chipHandler.preProcessChips(chip, criteriaChipConfigs.permissionKeys.keys);
            });
        }

        this.chipHandler.chipsSubject.subscribe(data => {
            if (data && JSON.stringify(data) !== JSON.stringify([]) && data.length ===
                criteriaChipConfigs.chips.length) {
                this.availableChips = data; // new json object here;
                this.chipHandler.chipsSubject.next(null);

                if (this.chipHandlerSubject) {
                    this.chipHandlerSubject.unsubscribe();
                }

                // set values to display chips
                this.displayChipsArr = [];
                this.displayChipsArr = this.availableChips;

                this.dataStoreService.set(DataKey.chips, this.availableChips, true);

                this.chipInputService.init(this.boardBasisSetupSearchCriteria, this.availableChips, false, true);
                this.isChipsInit = true;

                // implement chip persistence
                if (this.persistentQParams && this.persistentQParams.length > 0) {
                    // create a queryParamMap
                    for (const qParam of this.persistentQParams) {
                        const qParamValue = this.boardBasisSetupSearchCriteria[qParam];
                        if (qParam && qParamValue) {
                            this.persistentQParamMap.set(qParam, qParamValue);
                        }
                    }
                    this.persistentChipsArr = [];
                    // fill persistent chips
                    this.availableChips.forEach(chip => {
                        const persistChip = Object.assign({}, chip);
                        persistChip.optionParams.data = [];
                        let isPersist = false;
                        if (chip.dataParams && chip.dataParams.length > 0) {
                            chip.dataParams.forEach(dataParam => {
                                if (this.persistentQParamMap.has(dataParam.paramValue)) {
                                    isPersist = true;
                                    const dataObj = {
                                        key: dataParam.paramValue,
                                        value: this.persistentQParamMap.get(dataParam.paramValue)
                                    };
                                    persistChip.optionParams.data.push(dataObj);
                                }
                            });
                        }
                        // push persist chips to an array
                        if (isPersist) {
                            this.persistentChipsArr.push(persistChip);
                        }
                    });
                    // persist chips
                    if (this.persistentChipsArr && this.persistentChipsArr.length > 0) {
                        const uniqueChipList = this.persistentChipsArr.filter((e, i) => {
                            return this.persistentChipsArr.findIndex((x) => {
                                return x.criteriaId === e.criteriaId;
                            }) === i;
                        });

                        this.chipInputService.persistChips(uniqueChipList);
                    }
                }
                // trigger search when chips persisting
                this.isDataLoaded = false;
                // this.isTriggerSearch = false;
                this.onCostingTypeSearch(this.boardBasisSetupSearchCriteria);

                this.chipInputService.criteriaUpdate.subscribe((updatedCriteriaData) => {
                    this.boardBasisSetupSearchCriteria = updatedCriteriaData;
                });
            }
        });
    }

    public onCostingTypeSearch(event) {
        this.isDataLoaded = false;
        this.boardBasisSetupSearchCriteria = event;

        this.userJourneyManager.canProceed.next(false);

        if (JSON.stringify(this.boardBasisSetupSearchCriteria) !== JSON.stringify({})) {
            this.dataStoreService.set(DataKey.boardBasisSetupSearchCriteria, this.boardBasisSetupSearchCriteria, true);

            // set criteria parameters to url
            let queryParams: string[] = [];
            queryParams = this.queryParamService.getQueryParamsArray(this.boardBasisSetupSearchCriteria);

            this.rootService.setDataToLocalStorage(TCO.AppData.BOARD_BASIS_SETUP_SEARCH_CRITERIA, this.boardBasisSetupSearchCriteria, true,
                queryParams, true
            );

            this.isTriggerSearch = false;
            this.isDataLoaded = false;
            this.searchTrigger.emit(true);

        } else {
            setTimeout(() => {
                this.isDataLoaded = true;
                this.isTriggerSearch = true;
            }, 0);
        }
    }

    getUpdatedFieldsDataObject(chip, criteria: BoardBasisSetupSearchCriteria) {
        const dataObjArr = [];

        if (chip && chip.dataParams && chip.dataParams.length > 0) {
            chip.dataParams.forEach(dataParam => {
                const dataObj = {
                    key: '',
                    value: ''
                };
                dataObj.key = dataParam.paramValue;
                dataObj.value = criteria[dataParam.paramValue];

                dataObjArr.push(dataObj);
            });
        }

        return dataObjArr;
    }

    private getSearchObserver() {
        this.searchResultObserver = this.dataStoreService.get(DataKey.boardBasisSetupSearchCriteria)
            .subscribe((data) => {
                if (this.commonHelper.dataValidity(data)) {
                    this.isDataLoaded = true;
                    this.isTriggerSearch = false;
                } else if (this.commonHelper.isEmptyData(data)) {
                    this.isDataLoaded = true;
                } else if (data instanceof TcApiError) {
                    this.isDataLoaded = true;
                } else if (data instanceof TcHttpError) {
                    this.isDataLoaded = true;
                }
            }, (error: any) => {
                console.log(error);
            });

    }

    private errorObserver() {
        this.errorsObserver = this.dataStoreService.get(DataKey.error)
            .subscribe((data) => {
                if (data && JSON.stringify(data) !== JSON.stringify({})) {
                    setTimeout(() => {
                        this.isDataLoaded = true;
                    }, 0);
                    this.dataStoreService.set(DataKey.error, null);
                }
            });
    }

    private resetQueryParams() {
        let boardBasisGroupsQueryParams: string[] = [];
        boardBasisGroupsQueryParams = this.queryParamService.getQueryParamsArray(new BoardBasisSetupSearchCriteria());

        const locGroupSearchCriteria: BoardBasisSetupSearchCriteria = this.rootService.loadDataFromLocalStorage([],
            new BoardBasisSetupSearchCriteria(), TCO.AppData.BOARD_BASIS_SETUP_SEARCH_CRITERIA
        );

        // set criteria parameters to url
        let queryParams: string[] = [];
        queryParams = this.queryParamService.getQueryParamsArray(locGroupSearchCriteria);

        this.rootService.setDataToLocalStorage(TCO.AppData.BOARD_BASIS_SETUP_SEARCH_CRITERIA, this.boardBasisSetupSearchCriteria,
            true, queryParams, true
        );
    }

    public onCancel($event) {
        console.log('cancelled');
    }

    public onChipListDisplay(event) {
        this.dataStoreService.set(DataKey.chipListDisplay, null, true);
        this.isChipListDisplay = event;
        this.dataStoreService.set(DataKey.chipListDisplay, this.isChipListDisplay, true);
    }

    handleQuickActions() {
        this.triggerSearchCriteriaObserver = this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'TRIGGER_SEARCH_CRITERIA')
            .subscribe((e) => {
                this.triggerSearch(e.data);
            });
    }

    triggerSearch(data: string) {
        this.boardBasisSetupSearchCriteria = new BoardBasisSetupSearchCriteria();
        // todo criteria

        this.actionHandlerService.fillCriteriaByActionData(data, this.boardBasisSetupSearchCriteria);
        const requiredChips = this.chipHandler.getChipsFromCriteria(
            this.boardBasisSetupSearchCriteria,
            TCO.CONF.CONF_BOARD_BASIS_SETUP_SEARCH_CRITERIA_CHIP,
            true
        );
        this.chipInputService.hardReset();
        this.chipInputService.persistChips(requiredChips, true);
        this.onCostingTypeSearch(this.boardBasisSetupSearchCriteria);
    }

    private handleInterRelatedChips() {
        this.relatedChipsObsv = this.chipInputService.relatedChipsSubject
            .subscribe(
                (data) => {
                    let toggleChips = [];
                    if (data && data.chip && JSON.stringify(data) !== JSON.stringify({})) {
                        this.modifiedChip = data.chip;
                        // TODO: refine chip input service: inter related chips
                        if (data.chip.type === 'CALENDAR') {
                            this.chipHandler.handleInterRelatedChips(data.chip, data.date);
                        } else {
                            this.chipHandler.handleInterRelatedChips(
                                data.chip,
                                data.options.value[0]
                            );
                        }
                        if (data.chip.optionParams.toggleChips &&
                            data.chip.optionParams.toggleChips.length > 0) {
                            toggleChips = data.chip.optionParams.toggleChips;
                            if (toggleChips && data.options && data.options.value[0].value) {
                                this.chipInputService.selectedChipsBasedOnStateChange(
                                    toggleChips);
                            } else {
                                this.chipInputService.deleteChipsBasedOnStateChange(toggleChips);
                            }
                        }
                    }
                }
            );
    }

    private selectedChipsUpdate() {
        this.criteriaUpdateSubscription = this.chipInputService.selectedChipsSubject
            .subscribe(selectedChips => {
                this.selectedChips = selectedChips;

                if (selectedChips && JSON.stringify(selectedChips) !==
                    JSON.stringify([])) {
                    // toggle chips when selecting BRANCH_OF_CLIENT_ID chip
                    for (const selectedChip of this.selectedChips) {
                        if (selectedChip.chip.id === 'BRANCH_OF_CLIENT_ID' &&
                            selectedChip.chip.optionParams.toggleChips &&
                            selectedChip.chip.optionParams.toggleChips.length > 0) {
                            this.deleteToggleChips(selectedChip.chip.optionParams.toggleChips);
                            this.disableChips(selectedChip.chip.optionParams.toggleChips);
                            break;
                        }
                    }
                }
            });
    }

    private notifyChipDelete() {
        this.deletedChipObsv = this.chipInputService.deletedChipsSubject
            .subscribe(deletedChip => {
                if (JSON.stringify(deletedChip) !== JSON.stringify({}) && deletedChip !== null &&
                    deletedChip !==
                    undefined) {
                    if (deletedChip.id === 'CLIENT_TYPE') {
                        const deleteChips = this.selectedChips.map(chip => chip.chip);
                        this.deleteSelectedChips(deleteChips);
                        this.enableChips(this.availableChips);
                    } else if (deletedChip.id === 'BRANCH_OF_CLIENT_ID') {
                        if (deletedChip.optionParams.toggleChips &&
                            deletedChip.optionParams.toggleChips.length > 0) {
                            this.enableChips(deletedChip.optionParams.toggleChips);
                        }
                    }
                }
            });
    }

    private deleteToggleChips(toggleChips: any[]) {
        const deleteChips = [];
        toggleChips.forEach(toggleChip => {
            for (const selectedChip of this.selectedChips) {
                if (selectedChip.chip.id === toggleChip.criteriaId) {
                    deleteChips.push(selectedChip.chip);
                    break;
                }
            }
        });
        this.deleteSelectedChips(deleteChips);
    }

    private deleteSelectedChips(deleteChips: any[]) {
        deleteChips.forEach(chip => {
            this.chipInputService.deleteChip(chip, true);
        });
    }

    private enableChips(enableChips: any[]) {
        enableChips.forEach(enableChip => {
            this.chipInputService.modifyChipsStatus(enableChip, false);
        });
    }

    private disableChips(disableChips: any[]) {
        disableChips.forEach(disableChip => {
            this.chipInputService.modifyChipsStatus(disableChip, true);
        });
    }

    ngOnDestroy() {
        // Remove this criteria's query params
        // this.queryParamService.removeParamsRelatedToObject(new CostingTypeSearchCriteria());

        this.routerChangeSubscription.unsubscribe();
        this.userJourneyManager.routeChanges.next(false);

        if (this.selectedChipsObsv) {
            this.selectedChipsObsv.unsubscribe();
        }
        if (this.selectedFieldsObsv) {
            this.selectedFieldsObsv.unsubscribe();
        }
        if (this.chipValueUpdateSub) {
            this.chipValueUpdateSub.unsubscribe();
        }
        if (this.criteriaUpdateSubscription) {
            this.criteriaUpdateSubscription.unsubscribe();
        }
        if (this.deletedChipObsv) {
            this.deletedChipObsv.unsubscribe();
        }
        if (this.searchResultObserver) {
            this.searchResultObserver.unsubscribe();
        }
        if (this.errorsObserver) {
            this.errorsObserver.unsubscribe();
        }
        if (this.userJourneyObserver) {
            this.userJourneyObserver.unsubscribe();
        }
        if (this.focusSearchBarObserver) {
            this.focusSearchBarObserver.unsubscribe();
        }
        if (this.closePopUpObserver) {
            this.closePopUpObserver.unsubscribe();
        }
        if (this.triggerSearchCriteriaObserver) {
            this.triggerSearchCriteriaObserver.unsubscribe();
        }

        this.em.removeEvents([
            'TRIGGER_SEARCH_CRITERIA'
        ]);

    }

}
