import { AssignmentSummary } from '../../../models/assignment-summary';

export class TimelineEvent {
    startTime: Date;
    waitingEndTime: Date;
    endTime: Date;
    assignment: any;

    // filled by timeline
    unitIndex: number;
    unitCount: number;
    waitingUnitCount: number;
    margin: number;
}
