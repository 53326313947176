import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { VehicleSearchCriteria } from '../../../models/criteria/vehicle-search-criteria';
import { Resource } from '../../../models/resource/resource';
import { OPResponseWrapper } from '../../../models/response/op-response-wrapper';
import { VehicleSummary } from '../../../models/summary/vehicle-summary';
import { DateTimeProcessor } from '../../business-utils/time-zone/date-time-processor';
import { DataKey } from '../../util/framework/data-store.service';
import { DMCQueryParamsService } from '../../util/framework/dmc-query-params.service';
import { RequestService } from '../../util/framework/request.service';
import { UrlPaths } from '../url-paths';

@Injectable()
export class TransportResourceService {

    constructor(
        private queryParamsService: DMCQueryParamsService,
        private requestService: RequestService
    ) {}

    searchVehiclesSummaryNoDataKey(vehicleSearchCriteria: VehicleSearchCriteria): Observable<OPResponseWrapper<VehicleSummary>> {
        DateTimeProcessor.processVehicleSearchCriteriaDateTimes(vehicleSearchCriteria);
        const params = this.queryParamsService.asQueryParams(vehicleSearchCriteria);
        return this.requestService.get(
            UrlPaths.OP_SUP_URL_KEY,
            [UrlPaths.OP_SUP_VERSION, UrlPaths.VEHICLE_SUMMARIES],
            params
        );
    }

    searchVehicleSummaries(vehicleSearchCriteria: VehicleSearchCriteria): Observable<OPResponseWrapper<VehicleSummary>> {
        DateTimeProcessor.processVehicleSearchCriteriaDateTimes(vehicleSearchCriteria);
        const params = this.queryParamsService.asQueryParams(vehicleSearchCriteria);
        return this.requestService.getWithDataKey(
            DataKey.vehicleSearchResultsService,
            UrlPaths.OP_SUP_URL_KEY,
            [UrlPaths.OP_SUP_VERSION, UrlPaths.VEHICLE_SUMMARIES],
            params
        );
    }

    searchVehiclesForPaging(vehicleSearchCriteria: VehicleSearchCriteria): Observable<OPResponseWrapper<VehicleSummary>> {
        DateTimeProcessor.processVehicleSearchCriteriaDateTimes(vehicleSearchCriteria);
        const params = this.queryParamsService.asQueryParams(vehicleSearchCriteria);
        return this.requestService.getWithDataKey(
            DataKey.vehicleSearchPagingTempResult,
            UrlPaths.OP_SUP_URL_KEY,
            [UrlPaths.OP_SUP_VERSION, UrlPaths.VEHICLE_SUMMARIES],
            params
        );
    }

    searchVehiclesForAssignmentOnMore(vehicleSearchCriteria: VehicleSearchCriteria): Observable<OPResponseWrapper<VehicleSummary>> {
        DateTimeProcessor.processVehicleSearchCriteriaDateTimes(vehicleSearchCriteria);
        const params = this.queryParamsService.asQueryParams(vehicleSearchCriteria);
        return this.requestService.getWithDataKey(
            DataKey.vehicleAssignAllFetchedResults,
            UrlPaths.OP_SUP_URL_KEY,
            [UrlPaths.OP_SUP_VERSION, UrlPaths.VEHICLE_ALLOC_SUMMARIES],
            params
        );
    }

    public retrieveResourceById(id: number): Observable<OPResponseWrapper<Resource>> {
        return this.requestService.get(
            UrlPaths.OP_SUP_URL_KEY,
            [UrlPaths.OP_SUP_VERSION, UrlPaths.RESOURCES, id.toString()]
        );
    }
}
