<br>
<div class="container-fluid">
    <div class="container-fluid results-header">
        <div class="results-header__row results-header__row--actions item-header">
            <mat-form-field appearance="outline">
                <mat-label>{{searchLabel ? searchLabel : EMPTY_STRING}}</mat-label>
                <input #searchInput matInput placeholder="{{searchPlaceholder}}" (keyup)="onSearch(searchInput.value)">
                <mat-icon matPrefix>{{searchIcon}}</mat-icon>
            </mat-form-field>
        </div>
    </div>
</div>
<div class="container-fluid">
    <div class="container-fluid results-header">
        <div class="results-header__row results-header__row--actions item-header">
            <div class="item-header__content">{{leftHeading ? leftHeading : EMPTY_STRING}}</div>
            <span class="item-header__content"
                  style="float: right">{{rightHeading ? rightHeading : EMPTY_STRING}}</span>
        </div>
    </div>
</div>
<ag-grid-angular
        class="ag-theme-material tc-ag-grid tc-ag-grid--full-width tc-ag-grid--popup-content"
        [rowData]="filteredRowData"
        [gridOptions]="gridOptions"
        [defaultColDef]="defaultColDef"
        [columnDefs]="columnDefs"
        [domLayout]="domLayout"
        [rowSelection]="rowSelection"
        [frameworkComponents]="frameworkComponents"
        (gridReady)="onGridReady($event)"
        (firstDataRendered)="onFirstDataRendered($event)"
        (rowSelected)="onRowSelected($event)">
</ag-grid-angular>

<div class="container assign-panel-footer-section">
    <button
            class="assign-panel-footer-section__button tc-button-secondary mat-button"
            [disabled]="!ButtonOneEnable"
            (click)="onClickBtnOne($event)">
        {{buttonOneLabel | translate}}
    </button>
    <button
            class="assign-panel-footer-section__button tc-button-primary mat-button"
            (click)="onClickBtnTwo($event)"
            [disabled]="!ButtonTwoEnable">
        {{buttonTwoLabel | translate}}
    </button>
</div>
