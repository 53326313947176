<div class="detail-group__body-service-item container-fluid">
    <ag-grid-angular
            style="width: 100%; height: 100%;"
            class="ag-theme-material tc-ag-grid tc-ag-grid--popup-content"
            [rowData]="rawData"
            [columnDefs]="columnDefs"
            [defaultColDef]="defaultColDef"
            (gridReady)="onGridReady($event)">
    </ag-grid-angular>
</div>

<div class="container assign-panel-footer-section">
    <button class="assign-panel-footer-section__button tc-button-secondary mat-button" (click)="onClose($event)">
        {{'Close' | translate}}
    </button>
</div>
