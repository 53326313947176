import { Injectable } from '@angular/core';
import { TBXResponseWrapper } from '@tc-core/model/it/codegen/tbx/api/tbx-response-wrapper';
import { TC } from '@tc-core/util';
import { OPResponseWrapper } from '../../../models/response/op-response-wrapper';

@Injectable()
export class ResponseUtil {

    constructor() {}

    public static getDataArray<T>(responseWrapper: TBXResponseWrapper<T>): Array<T> {
        if (responseWrapper && responseWrapper.data) {
            return responseWrapper.data;
        } else {
            return new Array<T>();
        }
    }

    public static getArray<T>(responseWrapper: any): Array<T> {
        if (responseWrapper) {
            return responseWrapper;
        } else {
            return new Array<T>();
        }
    }

    public static isSuccess(responseStatus: any): boolean {
        if (responseStatus && responseStatus.code != undefined) {
            return responseStatus.code === TC.ErrorCodes.SUCCESS;
        } else if (responseStatus && responseStatus.status && responseStatus.status.code != undefined) {
            return responseStatus.status.code === TC.ErrorCodes.SUCCESS;
        }
    }

    public static isError(responseStatus: any): boolean {
        if (responseStatus && responseStatus.code != undefined) {
            return responseStatus.code === TC.ErrorCodes.ERROR;
        } else if (responseStatus && responseStatus.status && responseStatus.status.code != undefined) {
            return responseStatus.status.code === TC.ErrorCodes.ERROR;
        }
    }

    public static getFirstData<T>(responseWrapper: TBXResponseWrapper<T>): T {
        if (responseWrapper && responseWrapper.data && responseWrapper.data.length > 0) {
            return responseWrapper.data[0];
        } else {
            return null;
        }
    }

    public static getTotalCount(responseWrapper: TBXResponseWrapper<any>): number {
        let response: OPResponseWrapper<any>;
        try {
            response = <OPResponseWrapper<any>>responseWrapper;
        } catch (e) {}
        if (response) {
            if (response.pageData && response.pageData.total > 0) {
                return response.pageData.total;
            } else if (response.data) {
                return response.data.length;
            }else{
                return 0;
            }
        } else if (responseWrapper && responseWrapper.data) {
            return responseWrapper.data.length;
        } else {
            return 0;
        }
    }

    public static getMetaData(responseWrapper: TBXResponseWrapper<any>): Map<string, string> {
        let response: OPResponseWrapper<any> = <OPResponseWrapper<any>>responseWrapper;
        if (response && response.metaData) {
            return response.metaData;
        } else {
            return new Map();
        }
    }

}
