import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DialogModel } from '@tc-core/model/it/codegen/ui/framework/dialog-model';
import { LEVEL, ModalData } from '@tc-core/model/it/codegen/ui/framework/modal-data';
// import { CalendarMenuItem } from '@tc/cg-calendar/calendar-header-controller/calendar-menu-item';
// import { CalendarRefreshEventType } from '@tc/cg-calendar/calendar-refresh-event-type';
// import { CgCalendarCssClassConstants } from '@tc/cg-calendar/cg-calendar-css-class-constants';
// import { CgCalendarRefreshData } from '@tc/cg-calendar/cg-calendar-refresh-data';
// import { DateRange } from '@tc/cg-calendar/date-range';
// import { RangeGroup } from '@tc/cg-calendar/range-group';
// import { SeasonCalendarService } from '@tc/cg-season-calendar/season-calendar.service';
// import { SeasonRangeEditCardComponent } from '@tc/cg-season-calendar/season-range-edit-card/season-range-edit-card.component';
// import { SeasonSetupSummaryComponent } from '@tc/cg-season-calendar/season-setup-summary/season-setup-summary.component';
// import { SeasonsEditCardComponent } from '@tc/cg-season-calendar/seasons-edit-card/seasons-edit-card.component';
import { DialogService } from '@tc/dialog/dialog.service';
import { ModalService } from '@tc/modal/modal.service';

import * as moment from 'moment';
import { BehaviorSubject, Observable } from 'rxjs';
import { CalendarMenuItem } from '../cg-calendar/calendar-header-controller/calendar-menu-item';
import { CalendarRefreshEventType } from '../cg-calendar/calendar-refresh-event-type';
import { CgCalendarCssClassConstants } from '../cg-calendar/cg-calendar-css-class-constants';
import { CgCalendarRefreshData } from '../cg-calendar/cg-calendar-refresh-data';
import { DateRange } from '../cg-calendar/date-range';
import { RangeGroup } from '../cg-calendar/range-group';
import { SeasonCalendarService } from './season-calendar.service';
import { SeasonRangeEditCardComponent } from './season-range-edit-card/season-range-edit-card.component';
import { SeasonSetupSummaryComponent } from './season-setup-summary/season-setup-summary.component';
import { SeasonsEditCardComponent } from './seasons-edit-card/seasons-edit-card.component';

@Component({
  selector: 'tc-cg-season-calendar',
  templateUrl: './cg-season-calendar.component.html'
})
export class CgSeasonCalendarComponent implements OnInit {

  @Input() fromYear: number = 0;
  @Input() toYear: number = 0;
  @Input() rangeGroups: RangeGroup[] = [];
  @Input() viewOnlyMode: boolean = false;
  @Input() mergeNearDateRanges: boolean = true;
  @Input() newRangeGroupNamePrefix: string = 'Season ';
  @Input() showCalendar: boolean = true;

  @Output() onChangeSeasons: EventEmitter<CgCalendarRefreshData> = new EventEmitter();

  refreshCalendar: BehaviorSubject<CgCalendarRefreshData> = new BehaviorSubject(null);
  calendarRefreshEvent: Observable<CgCalendarRefreshData> = this.refreshCalendar.asObservable();
  calendarMenuItems: CalendarMenuItem[] = [];
  seasonSetupSummaryMenuId = 'seasonSetupMenuId';
  rangeInitTempDate: Date = null;

  constructor(
    private modalService: ModalService,
    private seasonCalendarService: SeasonCalendarService,
    private dialogService: DialogService
  ) {
  }

  ngOnInit() {
    this.addInitialSeasonColors();
    this.initMenuItems();
    this.subscribeSeasonChange();
    this.seasonCalendarService.sortAllDateRanges(this.rangeGroups);
  }

  initMenuItems() {
    let calendarMenuItem = new CalendarMenuItem();
    calendarMenuItem.id = this.seasonSetupSummaryMenuId;
    calendarMenuItem.title = 'Season Setup Summary';
    calendarMenuItem.matIcon = 'assignment';

    this.calendarMenuItems.push(calendarMenuItem);
  }

  subscribeSeasonChange(){
    this.seasonCalendarService.onChangeSeasonsEvent.subscribe(
      value => {
        if (value) {
          this.onChangeSeasons.emit(value);
        }
      }
    )
  }

  addInitialSeasonColors() {
    for (let i = 0; i < this.rangeGroups.length; i++) {
      let group = this.rangeGroups[i];
      if (!group.colorId) {
        group.colorId = this.getFreeColor();
      }
    }
  }

  private getFreeColor(): string {
    let colorId = '';
    for (let colorIdKey in CgCalendarCssClassConstants.ColorId) {
      let match = false;
      this.rangeGroups.forEach(group => {
        if (group.colorId === CgCalendarCssClassConstants.ColorId[colorIdKey].class) {
          match = true;
        }
      });
      if (!match) {
        colorId = CgCalendarCssClassConstants.ColorId[colorIdKey].class;
        return colorId;
      }
    }
    return colorId;
  }

  onSelectDate($event: Date) {
    this.processDateSelect($event);
  }

  refreshCalendarData(eventType: CalendarRefreshEventType, data: any) {
    setTimeout(() => {
      this.refreshCalendar.next({type: eventType, data: data});
    }, 0);

  };

  onSelectSeason($event: RangeGroup) {
    this.rangeInitTempDate = null;
    this.seasonCalendarService.selectRangeGroup(this.rangeGroups, $event);
    this.refreshCalendarData(CalendarRefreshEventType.RangeGroupSelect,null);
  }

  onRemoveDateRange($event: DateRange) {
    if ($event) {
      let confirmSuccess = new DialogModel(
        true,
        LEVEL.WARNING,
        'Remove Date Range',
        `Do you want to delete the date range (${moment($event.startDate).format('YYYY MMM DD')} - 
        ${moment($event.endDate).format('YYYY MMM DD')}) ?`,
        true,
        2000,
        null,
        'No',
        'Yes'
      );
      this.dialogService
          .confirm(confirmSuccess)
          .subscribe((res) => {
            if (res === true) {
              this.seasonCalendarService.removeDateRange(this.rangeGroups, $event);
              this.refreshCalendarData(CalendarRefreshEventType.DateRangeRemove, null);
            }
          });
    }
  }


  onRemoveSeason($event: RangeGroup) {
    if ($event && !$event.nonRemovable) {
      let confirmSuccess = new DialogModel(
        true,
        LEVEL.WARNING,
        'Remove Season',
        `Do you want to delete the season '${$event.name}' ?`,
        true,
        2000,
        null,
        'No',
        'Yes'
      );
      this.dialogService
          .confirm(confirmSuccess)
          .subscribe((res) => {
            if (res === true) {
              this.seasonCalendarService.removeRangeGroup(this.rangeGroups, $event);
              this.refreshCalendarData(CalendarRefreshEventType.RangeGroupRemove,null);
            }
          });
    }
  }

  private processDateSelect(date: Date) {
    let processingDate = new Date(date);
    let selectedAnother = false;
    // select rangeGroup if the date is within an unselected rangeGroup
    if (!this.rangeInitTempDate) {
      selectedAnother = this.selectIfWithinAGroup(processingDate);
      if (selectedAnother) {
        this.refreshCalendarData(CalendarRefreshEventType.RangeGroupSelect,null);
      }
    }
    if (!selectedAnother && !this.viewOnlyMode) {
      let selectedRangeGroup = this.rangeGroups.find(group => group.selected);
      if (selectedRangeGroup) {
        if (this.rangeInitTempDate) {

          //create and add new date range
          this.seasonCalendarService.createAndAddNewDateRange(
            this.rangeGroups,
            this.rangeInitTempDate,
            processingDate,
            this.mergeNearDateRanges
          );

          this.refreshCalendarData(CalendarRefreshEventType.DateRangeAdded, null);
          this.rangeInitTempDate = null;

        } else {
          if (!this.hasADateRange(processingDate, selectedRangeGroup)) {
            this.rangeInitTempDate = processingDate;
          }
        }
      }
    }
  }

  private hasADateRange(processingDate: Date, selectedRangeGroup: RangeGroup) {
    let foundRange = selectedRangeGroup.dateRanges.find(range => {
      return SeasonCalendarService.withinRange(processingDate, range);
    });
    return !!foundRange;
  }

  private selectIfWithinAGroup(processingDate: Date): boolean {
    let selected = this.seasonCalendarService.selectIfWithinAGroup(this.rangeGroups, processingDate);
    return selected;
  }

  public onSetSeasons($event: any) {
    const dataObject = new ModalData(
      LEVEL.SUCCESS,
      'Set Seasons',
      null,
      null,
      'modal-basic--alt modal-basic--fixed-size',
      SeasonsEditCardComponent,
      {rangeGroups: this.rangeGroups, newRangeGroupNamePrefix: this.newRangeGroupNamePrefix}
    );
    this.modalService
        .confirm(dataObject).subscribe(res => {
      if (res) {
        this.rangeGroups = res['rangeGroups'];
        this.refreshCalendarData(CalendarRefreshEventType.RangeGroupsAddedOrAttributesChanged,null);
      }
    });

  }

  public onSetSeasonRange($event: any) {
    let selectedSeason = this.rangeGroups.find(group => group.selected);
    if (selectedSeason) {
      const dataObject = new ModalData(
        LEVEL.SUCCESS,
        'Set Date Range',
        null,
        null,
        'modal-basic--alt modal-basic--fixed-size',
        SeasonRangeEditCardComponent,
        {rangeGroup: selectedSeason, rangeGroups: this.rangeGroups, mergeNearDateRanges: this.mergeNearDateRanges}
      );
      this.modalService
          .confirm(dataObject).subscribe(res => {
        if (res) {
          this.refreshCalendarData(CalendarRefreshEventType.DateRangeAdded,null);
        }
      });
    }
  }

  public onMenuItemClick($event: CalendarMenuItem) {
    if ($event.id === this.seasonSetupSummaryMenuId) {
      const dataObject = new ModalData(
        LEVEL.SUCCESS,
        'Season Setup Summary',
        null,
        {'label': 'CLOSE'},
        'modal-basic--fixed-size modal-basic--show-footer',
        SeasonSetupSummaryComponent,
        {rangeGroups: this.rangeGroups}
      );
      this.modalService
          .confirm(dataObject).subscribe(res => {
        if (res) {
        }
      });
    }
  }

  private copyRangeGroupsArray(newRangeGroups: RangeGroup[]) {
    this.rangeGroups.length = 0; //clear original array
    newRangeGroups.forEach(
      group => {
        this.rangeGroups.push(group);
      }
    );
  }

  public onSelectDateRange($event: DateRange) {
    this.refreshCalendarData(CalendarRefreshEventType.DateRangeSelect, $event);
  }
}
