<div class="tc-h-100 tc-mq-content__wrapper">
  <nav class="tc-mq-content__header">
    <span class="tc-mq-content__header-title">{{selectedChat?.groupMsgThreadId > 0 ? (selectedChat?.groupThreadName) :
        (selectedChat?.resourceName ? (selectedChat?.resourceName) : (selectedChat?.supplierName))}}
        {{(selectedChat?.resourceTypeName) && (  selectedChat.resourceTypeName != 'Default Type')? '( ' + selectedChat.resourceTypeName + ' )' : '' }}

    </span>
<!--      <div class="tc-mq-content__header-actions">-->
<!--          <button mat-icon-button color=""  [matTooltip]="'New Tab' | translate"-->
<!--                  [matTooltipPosition]="toolTipPosition">-->
<!--              <mat-icon>open_in_new</mat-icon>-->
<!--          </button>-->

<!--          <button mat-icon-button color=""  [matTooltip]="'More' | translate"-->
<!--                  [matTooltipPosition]="toolTipPosition">-->
<!--              <mat-icon>more_vert</mat-icon>-->
<!--          </button>-->
<!--      </div>-->
  </nav>
<div id="scrollable_body" class="tc-mq-content__body" #chatPanel (scroll)="scrollEvent($event)">
    <div class="tc-py-2 tc-px-3 tc-mq-content__container">

    <ng-template [ngIf]="messagesLoaded && messages.length>0" >
        <ng-template ngFor let-msg [ngForOf]="messages" let-i="index">
            <div class="tc-mq-message-row tc-mq-message-row--primary" *ngIf="msg.sendingDmc" [id]="'msg-'+msg.notificationId" >
                <div class="tc-mq-message-content">
                    <div class="tc-mq-message-text" *ngIf="msg.indicator=='message' || msg.indicator == 'Message'" [innerHTML]="msg?.description">
                    </div>
                    <div class="tc-mq-message-text" *ngIf="msg.indicator=='job'" style="font-weight: bold;" [innerHTML]="msg?.description">
                    </div>
                    <div class="tc-mq-message-time">{{msg.displayTimeStamp}}</div>
                </div>
            </div>

            <div class="tc-mq-message-row tc-mq-message-row--secondary" *ngIf="msg.receivingDmc" [id]="'msg-'+msg.notificationId">
                <div class="tc-mq-message-content">
                    <img mat-list-avatar [src]="selectedChat?.icon | async" [alt]="selectedChat.chatHeadDisplayNameShort">
                    <div class="tc-mq-message-text" *ngIf="msg.indicator=='message'" [innerHTML]="msg?.description">
                    </div>
                    <div class="tc-mq-message-text" *ngIf="msg.indicator=='job'" style="font-weight: bold;" [innerHTML]="msg?.description">
                    </div>
                    <div class="tc-mq-message-time">{{msg?.displayTimeStamp}}</div>
                </div>
            </div>
        </ng-template>
    </ng-template>
    </div>
</div>
    <form [formGroup]="sendMessageForm">
      <nav class="tc-mq-content__footer tc-mq-content__container">
        <div class="tc-w-100">
            <mat-form-field class="mat-form-field--no-spacing tc-mt-0" [floatLabel]="'never'" >
                <!--          <button mat-button  matPrefix mat-icon-button aria-label="Attach File"-->
                <!--                                 [matTooltip]="'Attach File' | translate"-->
                <!--                                 [matTooltipPosition]="toolTipPosition">-->
                <!--          <mat-icon>attach_file</mat-icon>-->
                <!--      </button>-->
                <!--              <input type="text" placeholder="Type a message" matInput #message-->
                <!--                     formControlName="message" autocomplete="off">-->
                <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="1" #message placeholder="{{'Type a message' | translate}}" formControlName="message" autocomplete="off" (keyup.control.enter)="onKeydownCtrlEnter($event)" (keydown.enter)="onKeyDown($event)" (keyup.enter)="onKeyUp($event)"></textarea>
                <!--              (keypress)="handleKeyEvent($event)"-->
                <button mat-button class="primary" matSuffix mat-icon-button aria-label="Send" (click)="sendMessage()"
                        [matTooltip]="'Send' | translate"
                        [matTooltipPosition]="toolTipPosition">
                    <mat-icon>send</mat-icon>
                </button>
            </mat-form-field>
        </div>
      </nav>
    </form>
</div>
