import { AfterViewInit, OnInit } from '@angular/core';
import { TC } from '@tc-core/util/constants';
import { EventManager } from '@tc-core/util/framework/event-manager.service';
import { FocusViewManager } from '@tc-core/util/framework/focus-view-manager.service';
import { UserJourneyManager } from '@tc-core/util/framework/user-journey-manager.service';
import { CalendarRefreshEventType } from '../../../../widgets/framework/cg-calendar/calendar-refresh-event-type';
import { DateRange } from '../../../../widgets/framework/cg-calendar/date-range';
import { RangeGroup } from '../../../../widgets/framework/cg-calendar/range-group';
import { FocusViewService } from '@tc/focus-view/focus-view.service';
import { Season } from '../../../../models/supplier/season';
import { SeasonDateRange } from '../../../../models/supplier/season-date-range';
import { DataKey, DataStoreService } from '../../../../services/util/framework/data-store.service';
var SeasonSetupFocusViewComponent = /** @class */ (function () {
    function SeasonSetupFocusViewComponent(em, journeyManager, dataStore, focusViewManager, focusViewService) {
        this.em = em;
        this.journeyManager = journeyManager;
        this.dataStore = dataStore;
        this.focusViewManager = focusViewManager;
        this.focusViewService = focusViewService;
        this.resourceCostData = [];
        this.fromYear = 0;
        this.toYear = 0;
        this.rangeGroupsForSeasonCalendar = [];
    }
    SeasonSetupFocusViewComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.mapToSeasonCalendarRanges();
        this.focusViewManager.canProceed.next(false);
        this.focusViewManager.canProceedState.next(true);
        this.checkToContinue();
        this.em.addEvent(TC.CONF.CONF_EVENT_MANAGER, 'CLOSE_FOCUS_VIEW')
            .subscribe(function (e) {
            _this.dataStore.set(DataKey.supplierCostFocusViewClose, null);
            _this.dataStore.set(DataKey.supplierCostFocusViewClose, true);
            _this.focusViewService.close(true);
            _this.mapFromSeasonCalendarRanges();
            _this.journeyManager.goForKey('GO_TO_ADD_TRANSFER_SUPPLIER');
        });
    };
    SeasonSetupFocusViewComponent.prototype.ngAfterViewInit = function () {
        setTimeout(function () {
            var scrollingElement = document.querySelector('.tc-overlay');
            scrollingElement.scrollTo(0, 0);
        }, 0);
    };
    SeasonSetupFocusViewComponent.prototype.onChangeSeasons = function ($event) {
        if ($event.type !== CalendarRefreshEventType.RangeGroupSelect) {
            this.checkToContinue();
        }
    };
    SeasonSetupFocusViewComponent.prototype.checkToContinue = function () {
        var canContinue = true;
        for (var i = 0; i < this.rangeGroupsForSeasonCalendar.length; i++) {
            var rangeGroup = this.rangeGroupsForSeasonCalendar[i];
            if (rangeGroup.nonRemovable && rangeGroup.dateRanges.length === 0) {
                canContinue = false;
            }
        }
        if (canContinue) {
            this.focusViewManager.canProceed.next(true);
        }
        else {
            this.focusViewManager.canProceed.next(false);
        }
        return canContinue;
    };
    SeasonSetupFocusViewComponent.prototype.mapToSeasonCalendarRanges = function () {
        // this.rangeGroupsForSeasonCalendar = [];
        for (var i = 0; i < this.resourceCostData.length; i++) {
            var season = this.resourceCostData[i];
            var rangeGroup = new RangeGroup();
            rangeGroup.id = season.tempSeasonId;
            rangeGroup.name = season.name;
            rangeGroup.colorId = season.colorId;
            // make the season un deletable
            if (season.trsResourceCosts && season.trsResourceCosts.length > 0) {
                rangeGroup.nonRemovable = true;
            }
            var dateRanges = [];
            for (var j = 0; j < season.seasonDateRanges.length; j++) {
                var seasonDateRange = season.seasonDateRanges[j];
                var dateRange = new DateRange();
                dateRange.id = seasonDateRange.dateRangeId;
                dateRange.startDate = new Date(seasonDateRange.startDate);
                dateRange.endDate = new Date(seasonDateRange.endDate);
                dateRanges.push(dateRange);
            }
            rangeGroup.dateRanges = dateRanges;
            this.rangeGroupsForSeasonCalendar.push(rangeGroup);
        }
    };
    SeasonSetupFocusViewComponent.prototype.mapFromSeasonCalendarRanges = function () {
        console.log(this.rangeGroupsForSeasonCalendar);
        this.setTempIdsToNewSeasons();
        var _loop_1 = function (i) {
            var rangeGroup = this_1.rangeGroupsForSeasonCalendar[i];
            var season = null;
            if (rangeGroup.id) {
                season = this_1.resourceCostData.find(function (sesonRange) {
                    return sesonRange.tempSeasonId === rangeGroup.id;
                });
            }
            if (!season) {
                season = new Season();
                this_1.resourceCostData.push(season);
            }
            season.tempSeasonId = rangeGroup.id;
            season.name = rangeGroup.name;
            season.colorId = rangeGroup.colorId;
            season.seasonDateRanges = [];
            for (var j = 0; j < rangeGroup.dateRanges.length; j++) {
                var dateRange = rangeGroup.dateRanges[j];
                var seasonDateRange = new SeasonDateRange();
                seasonDateRange.startDate = dateRange.startDate;
                seasonDateRange.dateRangeId = dateRange.id;
                seasonDateRange.endDate = dateRange.endDate;
                season.seasonDateRanges.push(seasonDateRange);
            }
        };
        var this_1 = this;
        for (var i = 0; i < this.rangeGroupsForSeasonCalendar.length; i++) {
            _loop_1(i);
        }
        this.checkForDeletedSeasons();
        this.checkForEmptySeasonsAndRemove();
        console.log(this.resourceCostData);
    };
    SeasonSetupFocusViewComponent.prototype.setTempIdsToNewSeasons = function () {
        var maxId = 0;
        for (var i = 0; i < this.rangeGroupsForSeasonCalendar.length; i++) {
            var rangeGroup = this.rangeGroupsForSeasonCalendar[i];
            if (rangeGroup.id > maxId) {
                maxId = rangeGroup.id;
            }
        }
        maxId++;
        for (var i = 0; i < this.rangeGroupsForSeasonCalendar.length; i++) {
            var rangeGroup = this.rangeGroupsForSeasonCalendar[i];
            if (!rangeGroup.id) {
                rangeGroup.id = maxId++;
            }
        }
    };
    SeasonSetupFocusViewComponent.prototype.checkForDeletedSeasons = function () {
        var _loop_2 = function (i) {
            var season = this_2.resourceCostData[i];
            var rangeGroup = this_2.rangeGroupsForSeasonCalendar.find(function (group) {
                return group.id === season.tempSeasonId;
            });
            if (!rangeGroup) {
                this_2.resourceCostData.splice(this_2.resourceCostData.indexOf(season), 1);
                i--;
            }
            out_i_1 = i;
        };
        var this_2 = this, out_i_1;
        for (var i = 0; i < this.resourceCostData.length; i++) {
            _loop_2(i);
            i = out_i_1;
        }
    };
    SeasonSetupFocusViewComponent.prototype.checkForEmptySeasonsAndRemove = function () {
        for (var i = 0; i < this.resourceCostData.length; i++) {
            var season = this.resourceCostData[i];
            if (season.seasonDateRanges.length === 0) {
                this.resourceCostData.splice(this.resourceCostData.indexOf(season), 1);
                i--;
            }
        }
    };
    return SeasonSetupFocusViewComponent;
}());
export { SeasonSetupFocusViewComponent };
