import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ScaffS7v0Component } from './scaff-s7v0/scaff-s7v0.component';
import { CommonModule } from '@angular/common';
import {S05Component} from './s05/s05.component';
import {DirectivesModule} from '@tc/directives';

@NgModule({
  imports: [
    FormsModule,
    RouterModule,
    CommonModule,
    DirectivesModule
  ],
  declarations: [
      ScaffS7v0Component,
      S05Component
  ],
  exports: [
      ScaffS7v0Component,
      S05Component
  ]
})

export class ScaffoldingModule {
}
