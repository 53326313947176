import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { LeaveSetupDetailViewComponent } from './leave-setup-detail-view.component';

@NgModule({
    declarations: [LeaveSetupDetailViewComponent],
    imports: [
        CommonModule,
        TranslateModule.forChild()
    ],
    exports: [
        LeaveSetupDetailViewComponent
    ],
})
export class LeaveSetupDetailViewModule {}
