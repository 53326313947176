import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'tc-adv-room-search-results',
    templateUrl: './adv-room-search-results.component.html'
})
export class AdvRoomSearchResultsComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
    }

}
