/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../header/header.component.ngfactory";
import * as i2 from "../header/header.component";
import * as i3 from "../../../services/util/framework/data-store.service";
import * as i4 from "@tc-core/util/framework/user-journey-manager.service";
import * as i5 from "../notification-toast/notification-toast.service";
import * as i6 from "../../../services/backend-consumers/notifications/notification.service";
import * as i7 from "../../../services/util/change-detector/background-task.service";
import * as i8 from "../../../services/backend-consumers/documents/document.service";
import * as i9 from "../../../services/util/system/dmc-local-storage.service";
import * as i10 from "../../../services/util/common/dmc-common";
import * as i11 from "../../../services/backend-consumers/documents/document-queue.service";
import * as i12 from "./app-header.component";
import * as i13 from "@tc-core/util/framework/config-loader.service";
import * as i14 from "../../../services/util/core-services/root.service";
import * as i15 from "@tc-core/util/framework/event-manager.service";
import * as i16 from "@angular/router";
import * as i17 from "@ngx-translate/core";
import * as i18 from "../../../services/util/system/dmc-base.service";
import * as i19 from "../../../services/util/language/language.service";
var styles_AppHeaderComponent = [];
var RenderType_AppHeaderComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AppHeaderComponent, data: {} });
export { RenderType_AppHeaderComponent as RenderType_AppHeaderComponent };
export function View_AppHeaderComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-header", [], null, [[null, "logout"], [null, "themeSelection"], [null, "toggleSidebar"], [null, "actionClick"], [null, "changeLanguage"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("logout" === en)) {
        var pd_0 = (_co.onLogout() !== false);
        ad = (pd_0 && ad);
    } if (("themeSelection" === en)) {
        var pd_1 = (_co.onThemeSelection($event) !== false);
        ad = (pd_1 && ad);
    } if (("toggleSidebar" === en)) {
        var pd_2 = (_co.onSidebarToggle($event) !== false);
        ad = (pd_2 && ad);
    } if (("actionClick" === en)) {
        var pd_3 = (_co.onActionClick($event) !== false);
        ad = (pd_3 && ad);
    } if (("changeLanguage" === en)) {
        var pd_4 = (_co.onLanguageChagne($event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, i1.View_HeaderComponent_0, i1.RenderType_HeaderComponent)), i0.ɵdid(1, 245760, null, 0, i2.HeaderComponent, [i3.DataStoreService, i4.UserJourneyManager, i5.NotificationToastService, i6.NotificationService, i7.BackgroundTaskService, i8.DocumentService, i9.DMCLocalStorageService, i10.DMCCommon, i11.DocumentQueueService], { logoLocation: [0, "logoLocation"], isSidebarOpen: [1, "isSidebarOpen"], inputThemeCode: [2, "inputThemeCode"], headings: [3, "headings"], activeLanguage: [4, "activeLanguage"], user: [5, "user"], languages: [6, "languages"], actions: [7, "actions"], isUserLoaded: [8, "isUserLoaded"] }, { themeSelection: "themeSelection", toggleSidebar: "toggleSidebar", changeLanguage: "changeLanguage", actionClick: "actionClick", logout: "logout" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = ("assets/img/" + _co.logo); var currVal_1 = _co.sideBarDisplay; var currVal_2 = _co.inputThemeCode; var currVal_3 = _co.headings; var currVal_4 = _co.activeLanguage; var currVal_5 = _co.user; var currVal_6 = _co.languages; var currVal_7 = _co.actions; var currVal_8 = true; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }, null); }
export function View_AppHeaderComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-app-header", [], null, null, null, View_AppHeaderComponent_0, RenderType_AppHeaderComponent)), i0.ɵdid(1, 114688, null, 0, i12.AppHeaderComponent, [i13.ConfigLoader, i14.RootService, i15.EventManager, i16.Router, i16.ActivatedRoute, i4.UserJourneyManager, i17.TranslateService, i3.DataStoreService, i18.DMCBaseService, i19.LanguageService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppHeaderComponentNgFactory = i0.ɵccf("tc-app-header", i12.AppHeaderComponent, View_AppHeaderComponent_Host_0, {}, {}, []);
export { AppHeaderComponentNgFactory as AppHeaderComponentNgFactory };
