/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../node_modules/@angular/material/datepicker/typings/index.ngfactory";
import * as i2 from "@angular/material/datepicker";
import * as i3 from "@angular/material/core";
import * as i4 from "../month-header/month-header.directive";
import * as i5 from "../month-directive/month-date.directive";
import * as i6 from "@angular/common";
import * as i7 from "./single-year.component";
var styles_SingleYearComponent = [];
var RenderType_SingleYearComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SingleYearComponent, data: {} });
export { RenderType_SingleYearComponent as RenderType_SingleYearComponent };
function View_SingleYearComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 3, "mat-calendar", [["cgCalMonthHeader", ""], ["cgMonthDateDirective", ""], ["class", "mat-calendar"]], null, [[null, "selectedChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectedChange" === en)) {
        var pd_0 = (_co.onSelectDate($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_MatCalendar_0, i1.RenderType_MatCalendar)), i0.ɵdid(1, 10141696, [[1, 4], ["matCalendar", 4]], 0, i2.MatCalendar, [i2.MatDatepickerIntl, [2, i3.DateAdapter], [2, i3.MAT_DATE_FORMATS], i0.ChangeDetectorRef], { startAt: [0, "startAt"], selected: [1, "selected"] }, { selectedChange: "selectedChange" }), i0.ɵdid(2, 4276224, null, 0, i4.MonthHeaderDirective, [i0.ViewContainerRef], { startAt: [0, "startAt"] }, null), i0.ɵdid(3, 4210688, null, 0, i5.MonthDateDirective, [i0.ViewContainerRef], null, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getMonthStartingDate(_v.parent.parent.context.$implicit, _v.parent.context.$implicit); var currVal_1 = _co.selectedDate; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _co.getMonthStartingDate(_v.parent.parent.context.$implicit, _v.parent.context.$implicit); _ck(_v, 2, 0, currVal_2); }, null); }
function View_SingleYearComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "col-xs-3 tc-sc-month-view"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SingleYearComponent_3)), i0.ɵdid(2, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showCalendar; _ck(_v, 2, 0, currVal_0); }, null); }
function View_SingleYearComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "row tc-sc-month-view__container"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SingleYearComponent_2)), i0.ɵdid(2, 278528, null, 0, i6.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.colsIndexArray; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_SingleYearComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(671088640, 1, { matCalendar: 0 }), (_l()(), i0.ɵeld(1, 0, null, null, 6, "div", [["class", "cg-cal-single-year-body"]], null, [[null, "scroll"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("scroll" === en)) {
        var pd_0 = (_co.checkScroll($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "div", [["class", "tc-sc-year-view__header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "span", [["class", "tc-sc-year-view__header-txt"]], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, ["", ""])), (_l()(), i0.ɵeld(5, 0, null, null, 2, "div", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SingleYearComponent_1)), i0.ɵdid(7, 278528, null, 0, i6.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.rowsIndexArray; _ck(_v, 7, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.year; _ck(_v, 4, 0, currVal_0); }); }
export function View_SingleYearComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 1, "tc-single-year", [], null, null, null, View_SingleYearComponent_0, RenderType_SingleYearComponent)), i0.ɵdid(1, 4440064, null, 0, i7.SingleYearComponent, [i0.ViewContainerRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SingleYearComponentNgFactory = i0.ɵccf("tc-single-year", i7.SingleYearComponent, View_SingleYearComponent_Host_0, { year: "year", rangeGroups: "rangeGroups", calendarRefreshEvent: "calendarRefreshEvent" }, { selectDate: "selectDate" }, []);
export { SingleYearComponentNgFactory as SingleYearComponentNgFactory };
