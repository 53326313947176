import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { TC } from '@tc-core/util';
import * as _ from 'lodash';
import { TCO } from '../../../constants';

@Injectable({
    providedIn: 'root'
})
export class SetupCommonDataProcessorService
{

    EMPTY_STRING = TC.FORMAT.EMPTY_CHAR;
    pipe = new DatePipe('en-US');

    normalCellValueRenderer = (params) => {
        if (params.value) {
            return params.value;
        } else {
            return this.EMPTY_STRING;
        }
    };

    decimal2Renderer = (params) => {
        if (params.value) {
            return params.value.toFixed(2);
        } else {
            return this.EMPTY_STRING;
        }
    };

    decimal3Renderer = (params) => {
        if (params.value) {
            return params.value.toFixed(3);
        } else {
            return this.EMPTY_STRING;
        }
    };

    dateValueRenderer = (params) => {
        if (params.value) {
            try {
                const date = new Date(Date.parse(params.value));
                return this.pipe.transform(date, 'y-MMM-d', TCO.AppData.GMT_TIME_ZONE);
            } catch (e) {
                return this.EMPTY_STRING;
            }
        } else {
            return this.EMPTY_STRING;
        }
    };

    dateValueConvertor = (params) => {
        if (params) {
            try {
                const date = new Date(Date.parse(params));
                return this.pipe.transform(date, 'y-MMM-d', TCO.AppData.GMT_TIME_ZONE);
            } catch (e) {
                return this.EMPTY_STRING;
            }
        } else {
            return this.EMPTY_STRING;
        }
    };

    gmtDateTimeValueRenderer = (params) => {
        if (params.value) {
            try {
                const date = new Date(Date.parse(params.value));
                return this.pipe.transform(date, 'y-MMM-d h:mm a', TCO.AppData.GMT_TIME_ZONE);
            } catch (e) {
                return this.EMPTY_STRING;
            }
        } else {
            return this.EMPTY_STRING;
        }
    };

    dateTimeValueRenderer = (params) => {
        if (params.value) {
            try {
                const date = new Date(Date.parse(params.value));
                return this.pipe.transform(date, 'y-MMM-d h:mm a');
            } catch (e) {
                return this.EMPTY_STRING;
            }
        } else {
            return this.EMPTY_STRING;
        }
    };

    dateTimeValueRendererAsISO = (params) => {
        if (params.value) {
            try {
                let gmtDateTime = params.value;
                if (gmtDateTime.slice(gmtDateTime.length - 1).toLowerCase() !== 'z') {
                    gmtDateTime += 'Z';
                }
                const localDateTime = new Date(gmtDateTime);
                return this.pipe.transform(localDateTime, 'y-MMM-d h:mm a');
            } catch (e) {
                return this.EMPTY_STRING;
            }
        } else {
            return this.EMPTY_STRING;
        }
    };

    getFromConsumer = (handlerObj: any, consumer: any) => {
        if (consumer && handlerObj.handlerParam) {
            const item = _.get(consumer, handlerObj.handlerParam);
            if (item && handlerObj.type && handlerObj.type === 'function') {
                return item.bind(consumer);
            } else {
                if (!item) {
                    if (consumer.constructor && consumer.constructor.name) {
                        console.error(handlerObj.handlerParam + ' not found in ' + consumer.constructor.name);
                    } else {
                        console.error(handlerObj.handlerParam + ' not found in ' + consumer);
                    }
                }
                return item;
            }
        }
    };

    mapValueToName = function(handlerObj: any, consumer: any) {
        if (consumer && handlerObj['handlerParam']) {
            return function(params) {
                if (params && params.value) {
                    let name = this['nameOptions'][params.value];
                    if (name) {
                        return name;
                    } else if (this['nameOptions']['_default_']) {
                        return this['nameOptions']['_default_'];
                    } else {
                        return TC.FORMAT.EMPTY_CHAR;
                    }
                } else if (this['nameOptions']['_default_']) {
                    return this['nameOptions']['_default_'];
                } else {
                    return TC.FORMAT.EMPTY_CHAR;
                }
            }.bind({nameOptions: handlerObj['handlerParam']});
        }
    };

    displayObjName = function(option) {
        if (option && option.name) {
            return option.name;
        } else {
            return '';
        }
    };
    // resourceAcceptance queue
    displayResourceObjName = function(option) {
        if (option && option.firstName) {
            return option.firstName + ' ' + option.lastName;
        } else if (option && option.vehicleModel) {
            return option.vehicleName + ' | ' + option.vehicleModel;
        } else {
            return '';
        }
    };
    displayObjCodeName = function(option) {
        let codeName = '';
        if (option) {
            if (option.code) {
                codeName = option.code;
            }
            codeName += ' | ';
            if (option.name) {
                codeName += option.name;
            }
            return codeName;
        } else {
            return '';
        }
    };

    displaySupplierName = (option) => {
        if (option && option.name) {
            return option.name;
        } else if (option && option.resourceSummary && option.resourceSummary.name) {
            return option.resourceSummary.name;
        } else if (option && option.code) {
            return option.code;
        } else {
            return '';
        }
    };
    displayObjCode = function(option) {
        let code = '';
        if (option) {
            if (option.code) {
                code = option.code;
            }
            return code;
        } else {
            return '';
        }
    };

    numericSetter = function(params) {
        if (params.newValue > 0 || params.newValue === '') {
            _.set(params.data, params.colDef.field, params.newValue); // calling lodash deep property value set
            return true;
        }
        return false;
    };

    doubleValueSetter = function(params) {
        if (params.newValue > 0 || params.newValue === '') {
            if (typeof params.newValue === 'string' || params.newValue instanceof String) {
                params.newValue = parseFloat(params.newValue);
            }
            _.set(params.data, params.colDef.field, params.newValue); // calling lodash deep property value set
            return true;
        }
        return false;
    };

    currencyCellRenderer = function(params: any) {
        params.data.currency = params.data.currency ? params.data.currency : 'AED';
        if (params.data && params.data.currency && params.value) {
            const inrFormat = new Intl.NumberFormat('en-Us', {
                style: 'currency',
                currency: params.data.currency.code ?
                    params.data.currency.code :
                    params.data.currency,
                minimumFractionDigits: 2
            });
            // return inrFormat.format(params.value).replace(/[a-z]{3}/i, '').trim();
            return inrFormat.format(params.value);
        } else {
            return this.EMPTY_STRING;
        }
    };
    currencyCellRendererWithoutCurrencyCode = function(params: any) {
        params.data.currency = params.data.currency ? params.data.currency : 'AED';
        if (params.data && params.data.currency && params.value) {
            const inrFormat = new Intl.NumberFormat('en-Us', {
                style: 'currency',
                currency: params.data.currency.code ?
                    params.data.currency.code :
                    params.data.currency,
                minimumFractionDigits: 2
            });
            return inrFormat.format(params.value).replace(/[a-z]{3}/i, '').trim();
            // return inrFormat.format(params.value);
        } else {
            return this.EMPTY_STRING;
        }
    };
}
