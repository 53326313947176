import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ConfigLoader } from '@tc-core/util/framework';
import { IGetRowsParams } from 'ag-grid-community';
import { Observable, of, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SetupGridComp } from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid-comp';
import { SetupGridDataSource } from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid-data-source';
import { SetupGridComponent } from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid.component';
import { ActionRendererAction } from '../../../widgets/framework/ag-grid-custom-components/renderers/actions/actions.component';
import { TCO } from '../../../constants';
import { RoomTypeSearchCriteria } from '../../../models/criteria/room-type-search-criteria';
import { SortDirection } from '../../../models/helper/sort-direction';
import { DataHandlerService } from '../../../services/backend-consumers/master-data-handler-service/data-handler.service';
import { MasterDataRestService } from '../../../services/backend-consumers/master-data-handler-service/master-data-rest.service';
import { AccommodationService } from '../../../services/backend-consumers/setups/accommodation.service';
import { LocationsService } from '../../../services/backend-consumers/setups/locations.service';
import { DataKey, DataStoreService } from '../../../services/util/framework/data-store.service';

@Component({
    selector: 'tc-room-type-grid-setup',
    templateUrl: './room-type-grid-setup.component.html'
})
export class RoomTypeGridSetupComponent extends SetupGridComp implements OnInit, OnDestroy, SetupGridDataSource {

    @ViewChild('setupGrid')
    private setupGrid: SetupGridComponent;
    public colDefConfig = [];

    private roomTypeSearchCriteria: RoomTypeSearchCriteria = new RoomTypeSearchCriteria();

    // required options for grid editors
    // nothing

    private searchCriteriaObserver: Subscription = new Subscription();

    constructor(
        private configLoader: ConfigLoader,
        private dataStoreService: DataStoreService,
        private accommodationService: AccommodationService,
    ) {
        super();
    }

    ngOnInit() {
        this.colDefConfig = this.configLoader.configurations.get(TCO.CONF.CONF_ROOM_TYPE_SETUP);
        this.subscribeSearchCriteria();
    }

    /**
     * subscribe search criteria changes in search bar
     * then force grid to refresh data
     * grid refresh will be call getRaws method which is using this subscribed criteria for backend call
     */
    subscribeSearchCriteria() {
        this.searchCriteriaObserver = this.dataStoreService.get(DataKey.roomTypeSearchCriteria)
                                          .subscribe(value => {
                                              this.roomTypeSearchCriteria = value;
                                              this.setupGrid.runForceSearch();
                                          });
    }

    isDisableGeoClick = (params) => {
        return this.checkRowInvalid(params.data);
    };

    checkRowInvalid(data: any): boolean {
        let event = {
            data: data
        };
        return this.isInvalidRow(event);
    }

    private fillRequiredGridOptionData() {
        // nothing
    }

    private fillDataKeyForEditorData() {
        // nothing
    }

    public isInvalidRow(params): boolean {
        const data = params.data;
        if (data) {
            let invalid = true;
            if (
                data.code &&
                data.name
            ) {
                invalid = false;
            }
            return invalid;
        } else {
            return false;
        }
    }

    public createNewRow(): any {
        return {unsaved: true};
    }

    public isUnsavedRow(row: any): boolean {
        return !!row.unsaved;
    }

    public deleteRow(row: any) {
        if (row && row.code) {
            return this.accommodationService.deleteRoomType(row.code);
        } else {
            return of('success');
        }
    }

    public saveRow(row: any, event: any) {
        if (event.data.code === event.newValue) {
            console.log('Updated');
            return this.accommodationService.updateRoomType(event.oldValue, row)
                .pipe(tap(data => row.unsaved = undefined));
        } else {
            console.log('Save');
            return this.accommodationService.saveRoomType(row)
                .pipe(tap(data => row.unsaved = undefined));
        }
    }

    public getRows(params: IGetRowsParams): Observable<any> {
        this.dataStoreService.set(DataKey.roomTypeSearchResultsForCriteria, null)
        const pageSize = 1000000;
        if (!this.roomTypeSearchCriteria) {
            this.roomTypeSearchCriteria = new RoomTypeSearchCriteria();
        }
        this.roomTypeSearchCriteria.start = params.startRow;
        this.roomTypeSearchCriteria.size = pageSize;
        if (params.sortModel && params.sortModel[0]) {
            this.roomTypeSearchCriteria.sortBy = params.sortModel[0].colId;
            if (params.sortModel[0].sort === SetupGridComp.GRID_SORT_ASCENDING) {
                this.roomTypeSearchCriteria.sortDirection = SortDirection.ASC;
            } else {
                this.roomTypeSearchCriteria.sortDirection = SortDirection.DESC;
            }
        }
        return this.accommodationService.getRoomTypes(this.roomTypeSearchCriteria)
            .pipe(
                tap(data =>
                    this.dataStoreService.set(DataKey.roomTypeSearchResultsForCriteria, data)
                )
            );
    }

    public ngOnDestroy(): void {
        if (this.searchCriteriaObserver) {
            this.searchCriteriaObserver.unsubscribe();
        }
    }

}
