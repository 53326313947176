import * as tslib_1 from "tslib";
import { BehaviorSubject } from 'rxjs';
import { DataSource } from '@angular/cdk/collections';
var TableService = /** @class */ (function () {
    function TableService() {
    }
    TableService.prototype.getTableDataSource = function (rows, paginator) {
        return new TableDataSource(new TableData(rows), paginator);
    };
    return TableService;
}());
export { TableService };
var TableData = /** @class */ (function () {
    function TableData(data) {
        this.dataChange = new BehaviorSubject([]);
        this.dataChange.next(data);
    }
    Object.defineProperty(TableData.prototype, "data", {
        get: function () { return this.dataChange.value; },
        enumerable: true,
        configurable: true
    });
    return TableData;
}());
export { TableData };
var TableDataSource = /** @class */ (function (_super) {
    tslib_1.__extends(TableDataSource, _super);
    function TableDataSource(_tableData, _paginator) {
        var _this = _super.call(this) || this;
        _this._tableData = _tableData;
        _this._paginator = _paginator;
        _this.renderedData = [];
        _this.totalData = [];
        _this.totalData = _this._tableData.data;
        return _this;
    }
    TableDataSource.prototype.connect = function () {
        return this._tableData.dataChange;
        //   const displayDataChanges = [
        //       this._tableData.dataChange,
        //       this._paginator.page
        //   ];
        // return Observable.merge(...displayDataChanges).map(() => {
        //
        //     // Grab the page's slice of the filtered sorted data.
        //     const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
        //     this.renderedData = this._tableData.data.slice(startIndex, this._paginator.pageSize);
        //     return this.renderedData;
        // });
    };
    TableDataSource.prototype.disconnect = function () { };
    return TableDataSource;
}(DataSource));
export { TableDataSource };
