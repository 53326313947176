import { ServiceAssignment } from '../reservation/service-assignment';
import { ReservationStatus } from '../reservation/service-item';
import { ResourceAvailability } from '../resource/resource-availability';
import { DriverSupplierCostSummary } from '../summary/driver-supplier-cost-summary';
import { TrsSupplierCostSummary } from '../summary/supplier-cost-summary';

export class ServiceItemChanges {
    serviceItemId: number;
    firstParentId: number;
    checked: boolean;
    serviceAssignments: Map<string, ServiceAssignment>;
    commonSupplier: TrsSupplierCostSummary;
    vehicleSupplier: TrsSupplierCostSummary;
    driverSupplier: DriverSupplierCostSummary;
    vehicle: ResourceAvailability;
    driver: ResourceAvailability;
    vehicleCost: number;
    driverCost: number;
    changed: boolean;
    isPrivate: boolean;
    reservationStatus: ReservationStatus;
}
