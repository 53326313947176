<tc-chip-input #reservation_search_chip_input
        [isDataLoaded]="isDataLoaded"
        [isTriggerSearch]="isTriggerSearch"
        [isChipListOptionAvail]="true"
        [chisForDisplay]="displayChipsArr"
        (searchEmitter)="onReservationSearch($event)"
        [isFocus]="isFocus"
        [isSearchBarStyleNotAvailable]="isSearchBarStyleNotAvailable"
        [placeHolderText]="placeHolderText"
        [inputAuthConfigComponent]="null"
        (onfocus)="onFocus($event)"
        (cancelEmitter)="onCancel($event)"
        (chipListEmitter)="onChipListDisplay($event)"></tc-chip-input>
<div class="clearfix"></div>
