import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { isEmpty } from 'rxjs/operators';
import { ServiceNote } from '../../../models/reservation/service-note';

@Component({
    selector: 'tc-reservation-service-group-note',
    templateUrl: './reservation-service-group-note.component.html'
})
export class ReservationServiceGroupNoteComponent implements OnInit {

    public isSaved = false;
    public isEdited = false;
    public isUndo = false;
    public backupServiceNote : ServiceNote ;

    @Input() noteText: string;
    @Input() serviceNote : ServiceNote;
    @Input() disableGroupNoteEdit: boolean = false;

    @Output() saveNote: EventEmitter<ServiceNote> = new EventEmitter();
    @Output() deleteNote: EventEmitter<ServiceNote> = new EventEmitter();
    @Output() isEditClicked: EventEmitter<boolean> = new EventEmitter();

    constructor() { }

    ngOnInit() {
        if(this.serviceNote.note){
            this.isSaved = true;
        } else {
            this.isSaved = false;
        }
    }

    public onNoteSave(event: any) {
        this.saveNote.emit(this.serviceNote);
        this.isSaved = true;
    }

    public onUndo(event: any) {
        this.isEditClicked.emit(false);
        this.serviceNote = this.backupServiceNote;
        this.isSaved = true;
        this.isUndo = true;
    }

    public onEdit(event: any) {
        this.isEditClicked.emit(true);
        this.backupServiceNote = JSON.parse(JSON.stringify(this.serviceNote));
        this.isSaved = false;
        this.isEdited = true;
    }

    public onDelete(event: any) {
        this.deleteNote.emit(this.serviceNote);
    }

    public showSaveButton(note: string): boolean{
        if (!this.isSaved && note){
            return true;
        } else {
            return  false
        }
    }

    public showEditButton(): boolean{
        if (this.isSaved && !this.disableGroupNoteEdit){
            return true;
        } else {
            return  false
        }
    }

    public showDeleteButton(): boolean{
        if (!this.disableGroupNoteEdit){
            return true;
        } else {
            return  false
        }
    }
}
