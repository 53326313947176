import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TcErrorType } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-error';
import { Pager } from '@tc-core/model/it/codegen/ui/framework/pager';
import { ConfigLoader } from '@tc-core/util/framework/config-loader.service';
import { AutoScrollService } from '@tc-core/util/ui/auto-scroll.service';
import { TCO } from '../../../constants';
import { ServiceItem } from '../../../models/reservation/service-item';
import { ServiceItemChanges } from '../../../models/helper/service-item-changes';
import { Sorter } from '@tc-core/model/it/codegen/ui/framework/sorter';
import { SorterService } from '@tc-core/service/sorters/sorter.service';
import { SummaryCardData } from '@tc-core/model/it/codegen/ui/framework/summary-card-data';
import { VehicleSearchCriteria } from '../../../models/criteria/vehicle-search-criteria';
import { VehicleSearchType } from '../../vehicle-search-criteria/vehicle-search-criteria.component';
import { DMCCommon } from '../../../services/util/common/dmc-common';
import {ResourceType} from '../../../models/reservation/assignment';
import {SearchResultSummary} from '../../../models/reservation/search-result-summary';
import {AssignmentSummary} from '../../../models/assignment-summary';
import * as moment from 'moment';

@Component({
    selector: 'tc-vehicle-assign-panel',
    templateUrl: './vehicle-assign-panel.component.html'
})
export class VehicleAssignPanelComponent implements OnInit {

    @Input() isDataLoaded: boolean = true;
    @Input() isTriggerSearch: boolean = true;
    @Input() displayChipsArr = null;
    @Input() isFocus = false;
    @Input() isSearchBarStyleNotAvailable = true;
    @Input() placeHolderText = 'Search Transfer Supplier';
    @Input() selectedVehicle: ServiceItemChanges;
    @Input() itemGroup: SearchResultSummary;

    @Input() tAssignedVehicles: any = null;
    @Input() contentCardDataList: Array<any> = [];
    @Input() selectedSummaryCardData: any = null;
    @Input() bookingData: ServiceItem;
    @Input() bookingDataArray: ServiceItem[];
    @Input() selectedSupplier: any;
    @Input() isBulkAssignment: boolean = false;
    @Input() heading = '';
    @Input() hasMore = true;
    @Input() fetchedVehicleCount = 0;
    @Input() dataFromMoreClick = false;
    @Input() pager: Pager;
    @Input() vehicleSearchCriteria = new VehicleSearchCriteria;

    @Output() onPaginationMoreClick: EventEmitter<any> = new EventEmitter();
    @Output() vehicleSelect: EventEmitter<any> = new EventEmitter();
    @Output() cardScroll: EventEmitter<number> = new EventEmitter<number>();
    @Output() resourceCalendarClick: EventEmitter<any> = new EventEmitter<number>();

    @Output() eventClick: EventEmitter<any> = new EventEmitter();
    @Output() targetClick: EventEmitter<any> = new EventEmitter();

    sorters_: Sorter[];
    _searchType = VehicleSearchType.ResourceAssign;

    public defaultPageResultCount = 4;
    selectionIndex = '';
    tPagination: any;

    constructor(
        public sorterService: SorterService,
        private common: DMCCommon,
        private autoScrollService: AutoScrollService,
        private configLoader: ConfigLoader
    ) {
    }

    ngOnInit() {
        this.tPagination = this.configLoader.configurations.get(TCO.CONF.CONF_PAGINATION_CONFIG)['RESOURCE_ALLOCATION_SEARCH'];
        // this.createSorters();
        this.createVehicleSearchCriteria();
        this.setupPaginator();
    }

    createSorters() {
        if (this.tAssignedVehicles) {
            this.sorterService.sorters = [];
            this.sorters_ = [];
            let sorterMap = new Map<string, Sorter>();
            this.tAssignedVehicles.config.placeHolderConfig.forEach(config => {
                if (config && config.sort) {
                    let sorter = this.sorterService.createSorter(config);
                    let id = config['_id'];
                    sorterMap.set(id, sorter);
                    this.sorters_.push(sorter);
                }
            });
        }
    }

    public onSort(event) {
        this.sorterService.sorter = event;
    }

    public onVehicleSelect(summaryCardData: SummaryCardData) {
        this.selectedSummaryCardData = summaryCardData;
        // this.validateVehicleSelection();
        if (summaryCardData) {
            this.vehicleSelect.emit(this.selectedSummaryCardData);
        }
    }

    createVehicleSearchCriteria() {
        if (this.isBulkAssignment) {
            this.vehicleSearchCriteria.transferMode = this.bookingDataArray[0]['rawData'].trsServiceItem.transferMode;
            this.vehicleSearchCriteria.serviceDate = this.bookingDataArray[0]['rawData'].serviceDate;
            this.vehicleSearchCriteria.route = this.bookingDataArray[0]['rawData'].trsServiceItem.route;
            this.vehicleSearchCriteria.serviceItems = this.bookingDataArray.map(function (elem) {
                return elem.serviceItemId;
            }).join(",");
            this.vehicleSearchCriteria.isPrivate = this.bookingDataArray[0]['rawData'].privateBooking;
            this.vehicleSearchCriteria.serviceStartTime = moment(this.bookingDataArray[0]['rawData'].trsServiceItem.departureDateTime).format('HH:mm');
        }
         else {
            let previousAssignmentDetail = this.bookingData.serviceAssignments.find(value => value.categoryCode === ResourceType.vehicle);
            this.vehicleSearchCriteria.rejectedResourceIdList = previousAssignmentDetail ? previousAssignmentDetail.rejectedResourceIdList : [];

            this.vehicleSearchCriteria.transferMode = this.bookingData.trsServiceItem.transferMode;
            this.vehicleSearchCriteria.serviceDate = this.bookingData.serviceDate;
            this.vehicleSearchCriteria.route = this.bookingData.trsServiceItem.route;
            this.vehicleSearchCriteria.isPrivate = this.bookingData.privateBooking;
            this.vehicleSearchCriteria.serviceItems = this.bookingData.serviceItemId.toString();
            this.vehicleSearchCriteria.serviceStartTime = moment(this.bookingData.trsServiceItem.departureDateTime).format('HH:mm');
        }
        this.vehicleSearchCriteria.activeStatus = true;
        this.vehicleSearchCriteria.resourceType = ResourceType.vehicle;
    }

    private validateVehicleSelection() {
        if (!this.isBulkAssignment) {
            if (this.bookingData.servicePassengers.length > this.selectedSummaryCardData.data.value.remainingCapacity) {
                this.common.showSnackBar('Exceeding the vehicle capacity', 3000, TcErrorType.TYPE.WARN);
            }
        } else if (this.bookingDataArray) {

        }
    }

    public onCardScroll($event: number) {
        setTimeout(() => {
            this.autoScrollService.scrollToID(
                'clientDetailExp_' + $event, 'vehicle-assign-scroll-container');
        }, 0);
    }

    private setupPaginator() {
        this.defaultPageResultCount = this.tPagination['defaultPageResultCount'];
        this.pager = new Pager(0, this.defaultPageResultCount);
    }

    public onPage(pageNumber: number) {
        this.pager.currPage = pageNumber;
        this.selectionIndex = (this.pager.currPage * this.pager.itemsPerPage).toString();
    }

    public onMore() {
        this.dataFromMoreClick = true;
        if (this.tPagination && this.tPagination['defaultPageResultCount'] && this.tPagination['fetchingPages']) {
            this.vehicleSearchCriteria.size = this.tPagination['fetchingPages'] *
                this.tPagination['defaultPageResultCount'];
            this.onPaginationMoreClick.emit(this.vehicleSearchCriteria);
            // this.retrieveAndCollectSearchResults(this.supplierSearchCriteria);
        }
    }

    onResourceCalendarClick(resourceId: number) {
        this.resourceCalendarClick.emit(resourceId);
    }

    onTargetClick(target: any){
        this.targetClick.emit(target);
    }

    onEventClick(event: any){
        this.eventClick.emit(event);
    }


}
