import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule, MatSelectModule } from '@angular/material';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CurrencyFieldModule } from '@tc/currency-field';
import { CurrencyInputModule } from '@tc/currency-input';
import { SectionHeadingModule } from '@tc/section-heading';
import { AgGridModule } from 'ag-grid-angular';
import { AgGridCustomEditorsModule } from '../../../../widgets/framework/ag-grid-custom-components/editors/ag-grid-custom-editors.module';
import { AutoCompleteEditorComponent } from '../../../../widgets/framework/ag-grid-custom-components/editors/auto-complete-editor/auto-complete-editor.component';
import { DropdownEditorComponent } from '../../../../widgets/framework/ag-grid-custom-components/editors/dropdown-editor/dropdown-editor.component';
import { ActionsComponent } from '../../../../widgets/framework/ag-grid-custom-components/renderers/actions/actions.component';
import { AgGridCustomRenderersModule } from '../../../../widgets/framework/ag-grid-custom-components/renderers/ag-grid-custom-renderers.module';
import { CheckBoxSelectorComponent } from '../../../../widgets/framework/ag-grid-custom-components/renderers/check-box-selector/check-box-selector.component';
import { TemplateRendererComponent } from '../../../../widgets/framework/ag-grid-custom-components/renderers/template-renderer/template-renderer.component';
import { ActionColumnComponent } from '../../../../widgets/framework/ag-grid/action-column.component';
import { AutoCompleteComponent } from '../../../../widgets/framework/ag-grid/auto-complete.component';
import { CurrencyInputEditorComponent } from '../../../../widgets/framework/ag-grid/currency-input-editor.component';
import { MultiSelectComponent } from '../../../../widgets/framework/ag-grid/multi-select.component';
import { NumericEditorComponent } from '../../../../widgets/framework/ag-grid/numeric-editor.component';
import { CgSeasonCalendarModule } from '../../../../widgets/framework/cg-season-calendar';
import { ResultHeaderModule } from '../../../../widgets/shared/result-header/result-header.module';
import { RoomCostSetupFocusViewComponent } from './room-cost-setup-focus-view.component';

@NgModule({
    declarations: [
        RoomCostSetupFocusViewComponent,
    ],
    imports: [
        CommonModule,
        ResultHeaderModule,
        CgSeasonCalendarModule,
        FormsModule,
        ReactiveFormsModule,
        MatSelectModule,
        CurrencyInputModule,
        CurrencyFieldModule,
        MatIconModule,
        SectionHeadingModule,
        AgGridCustomEditorsModule,
        AgGridCustomRenderersModule,
        AgGridModule.withComponents([
            CurrencyInputEditorComponent,
            AutoCompleteComponent,
            NumericEditorComponent,
            ActionColumnComponent,
            MultiSelectComponent,
            ActionsComponent,
            CheckBoxSelectorComponent,
            TemplateRendererComponent,
            DropdownEditorComponent,
            AutoCompleteEditorComponent
        ]),
        MatButtonModule,
        MatTooltipModule
    ],
    exports: [RoomCostSetupFocusViewComponent],
    entryComponents: [RoomCostSetupFocusViewComponent]
})
export class RoomCostSetupFocusViewModule {}
