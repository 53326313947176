<div class="tc-ac__container-main">
  <div class="tc-ac__container-left">

    <tc-res-calendar-side-nav
            *ngIf="navConfig && navConfig.length > 0"
            [navItems]="navConfig"
            [isRadioButton]="isRadioButton"
            (itemsSelected)="filterSelectedEvents($event)"
    >
    </tc-res-calendar-side-nav>
  </div>
  <div class="tc-ac__container-right">
    <div class="tc-sc-summery-panel__action-header">
      <div *ngIf="viewOptions && viewOptions.length>0" class="tc-icon-block__icon">
        <tc-chip-dropdown
                class="transfer-mode-selection"
                [dropdownOptions]="viewOptions"
                (selectedOption)="onSelectViewOption($event)">
        </tc-chip-dropdown>

      </div>
    </div>
    <full-calendar
            #calendar
            [defaultView]="defaultView"
            [plugins]="calendarPlugins"
            [eventLimit]="true"
            class = "tc-ac-full-calendar"
            [eventLimitClick]="'popover'"
            [selectOverlap]="true"
            (eventClick)="onEventClick($event)"
            (eventRender)="eventRender($event)"
            (select)="onDateClick($event)"
            [events]="calendarEvents"
            [selectable]="true"
    >


    </full-calendar>                                       
  </div>
</div>