import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DialogModel } from '@tc-core/model/it/codegen/ui/framework/dialog-model';
import { LEVEL } from '@tc-core/model/it/codegen/ui/framework/modal-data';
import { DialogService } from '@tc/dialog/dialog.service';
import { GridApi, ICellRendererParams } from 'ag-grid-community';
import { RowNode } from 'ag-grid-community/dist/lib/entities/rowNode';
import { Subscription } from 'rxjs';
import { ProductItem } from '../../../../models/reservation-v2/product-item';
import { OPResponseWrapper } from '../../../../models/response/op-response-wrapper';
import { DataKey, DataStoreService } from '../../../../services/util/framework/data-store.service';
import { ResponseUtil } from '../../../../services/util/response/response-util.service';
import { OperationQueuePanelConfig } from '../operation-queue-panel-config';

@Component({
    selector: 'tc-product-queue',
    templateUrl: './product-queue.component.html',
    styleUrls: ['./product-queue.component.scss']
})


export class ProductQueueComponent implements OnInit, OnDestroy {

    showAllChips = false;

    constructor(
        private dataStore: DataStoreService,
        private dialogService: DialogService,
        private translateService: TranslateService
    ) {
        this.translateService.onLangChange.subscribe(() => {
            this.gridApi.refreshHeader();
        });
    }

    @Input() operationPanelConfig = new OperationQueuePanelConfig();

    /* grid data */
    productItemsSearchResults: ProductItem[] = [];
    public productItemsShowing: ProductItem[] = [];

    public numberOfProductItems = 0;

    /* grid config */
    // public overlayNoRowsTemplate: any = `<span class="ag-overlay-no-rows-center">No Products</span>`;
    public overlayNoRowsTemplate: any = `<div class="tc-ag-grid-empty tc-mt-5">
            <i class="material-icons tc-ag-grid-empty__icon">assignment_error</i>
            <span class="tc-ag-grid-empty__text">No Products Available</span>
        </div>`;
    public overlayLoadingTemplate: any = `<div class="tc-ag-grid-empty">
            <img src="assets/img/loading-tab.gif" class="tc-mb-2" alt="Loading">
            <span class="tc-ag-grid-empty__text">Loading...</span>
        </div>`;
    @Input() public columnDefs: any = [];
    public defaultColDef: any = {
        resizable: true,
        headerValueGetter: (parameters: ICellRendererParams): string => {
            const headerIdentifier = parameters.colDef.headerName;
            if (headerIdentifier) {
                return this.translateService.instant(headerIdentifier);
            }
            return '';
        }
    };
    public colResizeDefault: any = {};
    public domLayout = 'normal';

    public gridApi: GridApi;
    public gridColumnApi;

    // expansion panel
    public expandPanelOpen = false;

    // selection handling
    selectedNodes: RowNode[];
    public productsSelectedFromGrid = [];
    public productsSelectedFromGridAndChips = [];

    // for clear button
    hideClearButton = true;

    @Output() selectionChanged: EventEmitter<ProductItem[]> = new EventEmitter();
    @Output() allProducts: EventEmitter<ProductItem[]> = new EventEmitter();

    private productSearchResultsSubscription: Subscription = new Subscription();

    public getRawClass = (params) => {
        if (params.node.rowIndex % 2 === 0) {
            return 'tc-ag-grid-row--even';
        }
    };

    ngOnInit() {
        this.subscribeProductSearchResults();
        this.colResizeDefault = 'shift';
        this.operationPanelConfig.productViewOpen = this.expandPanelOpen;
    }

    getNoRowsOverlay = () => {
        const text = this.translateService.instant('No Products Available');
        return `<div class="tc-ag-grid-empty tc-mt-5">
        <i class="material-icons tc-ag-grid-empty__icon">assignment_error</i>
            <span class="tc-ag-grid-empty__text">` + text + `</span>
        </div>`;
    };

    subscribeProductSearchResults() {
        this.productSearchResultsSubscription = this.dataStore.get(DataKey.productQueueSearchResultsFromService, true)
                                                    .subscribe((data: OPResponseWrapper<ProductItem>) => {
                                                        if (data) {
                                                            this.numberOfProductItems = 0;
                                                            this.productItemsSearchResults.length = 0;
                                                            this.productItemsShowing.length = 0;
                                                            this.productsSelectedFromGrid.length = 0;
                                                            this.productsSelectedFromGridAndChips.length = 0;

                                                            this.numberOfProductItems = ResponseUtil.getTotalCount(data);
                                                            this.productItemsSearchResults = ResponseUtil.getDataArray<ProductItem>(
                                                                data);
                                                            this.allProducts.emit(this.productItemsSearchResults);
                                                            this.productItemsShowing.push(...this.productItemsSearchResults);
                                                            this.hideClearButton = true;
                                                            this.setProductData();
                                                        }
                                                    });
    }

    private setupGridHeight(searchSize: number) {
        if (this.gridApi) {
            if (searchSize > 0) {
                this.gridApi.setDomLayout('autoHeight');
                // @ts-ignore
                document.querySelector('#productGrid').style.height = '';
            } else {
                this.gridApi.setDomLayout('normal');
                // @ts-ignore
                document.querySelector('#productGrid').style.height = 'calc(50vh - 160px)';
            }
        }
    }

    public selectAllRows() {
        if (this.gridApi) {
            this.gridApi.forEachLeafNode((node) => {
                node.setSelected(true);
            });
            console.log('select all');
        }
    }

    public showLoadingOverlay() {
        if (this.gridApi) {
            this.gridApi.setRowData([]);
            this.gridApi.showLoadingOverlay();
            this.setupGridHeight(0);
        }
    }

    public setProductData() {
        if (this.gridApi) {
            this.gridApi.setRowData(this.productItemsShowing);
            if (this.productItemsShowing) {
                this.gridApi.selectAll();
                // this.onSelectionChange(this.productItemsShowing[0]);
            }
            this.gridApi.onFilterChanged();
            this.setupGridHeight(this.productItemsSearchResults.length);
        } else {
            setTimeout(() => this.setProductData, 0);
        }
    }

    public onGridReady(params: any) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        // this.gridApi.sizeColumnsToFit();
        this.setProductData();
    }

    autoSizeAll() {
        const allColumnIds = [];
        if (this.gridColumnApi) {
            this.gridColumnApi.getAllColumns().forEach((column) => {
                allColumnIds.push(column.colId);
            });
            this.gridColumnApi.autoSizeColumns(allColumnIds);
        }
    }

    public isExternalFilterPresent = () => {
        // if (this.productsSelectedFromGridAndChips) {
        //     return this.productsSelectedFromGridAndChips.length > 0;
        // } else {
        //     return false;
        // }
        // requirement came to not filter selected items
        return false;
    };

    public doesExternalFilterPass = (node) => {
        return node.data.selected;
    };

    public onChipClick(item: any) {
        if (!this.operationPanelConfig.preventSwitching) {
            item.selected = !item.selected;
            this.onChipSelectionChange(item);
        } else {
            const invalidAttemptWarning = new DialogModel(
                true,
                LEVEL.WARNING,
                'Invalid Attempt',
                'Please save or revert changes before selection change',
                true,
                2000
            );
            const subscription = this.dialogService.confirm(invalidAttemptWarning).subscribe((res) => {
                subscription.unsubscribe();
            });
        }
    }

    public onChipSelectionChange(item: any) {
        if (!this.operationPanelConfig.preventSwitching) {
            const index = this.productsSelectedFromGridAndChips.indexOf(item);
            if (item.selected) {
                if (index === -1) {
                    this.productsSelectedFromGridAndChips.push(item);
                }
            } else {
                if (index > -1) {
                    this.productsSelectedFromGridAndChips.splice(index, 1);
                }
            }
            this.gridApi.onFilterChanged();
            this.hideClearButton = this.productsSelectedFromGridAndChips.length === 0;
            this.selectionChanged.emit(this.productsSelectedFromGridAndChips);
        } else {
            const invalidAttemptWarning = new DialogModel(
                true,
                LEVEL.WARNING,
                'Invalid Attempt',
                'Please save or revert changes before selection change',
                true,
                2000
            );
            const subscription = this.dialogService.confirm(invalidAttemptWarning).subscribe((res) => {
                subscription.unsubscribe();
            });
        }
    }

    public onClearChipSelection() {
        this.productsSelectedFromGridAndChips.forEach(product => product.selected = false);
        this.productsSelectedFromGridAndChips.length = 0;
    }

    public isRawSelectable = (params: any) => {
        if (this.operationPanelConfig) {
            return !this.operationPanelConfig.preventSwitching;
        } else {
            return true;
        }
    };
    public onSelectionChange($event: any) {
        const selectedRows = this.gridApi.getSelectedRows();
        if (!this.operationPanelConfig.preventSwitching) {
            this.selectedNodes = this.gridApi.getSelectedNodes();
            for (const selectedRow of selectedRows) {
                if (this.productsSelectedFromGrid.indexOf(selectedRow) === -1) {
                    selectedRow.selected = true;
                }
            }
            this.productsSelectedFromGrid.length = 0;
            this.productsSelectedFromGrid.push(...selectedRows);

            // clear other raws
            let chipSelectionChanged = false;
            this.productItemsSearchResults.forEach(item => {
                if (this.productsSelectedFromGrid.indexOf(item) === -1) {
                    item.selected = false;
                    const indexInSelectedChips = this.productsSelectedFromGridAndChips.indexOf(item);
                    if (indexInSelectedChips > -1) {
                        this.productsSelectedFromGridAndChips.splice(indexInSelectedChips, 1);
                        chipSelectionChanged = true;
                    }
                }
            });
            this.hideClearButton = this.productsSelectedFromGridAndChips.length === 0;
            // this.gridApi.onFilterChanged();
            if (chipSelectionChanged) {
                this.selectionChanged.emit(this.productsSelectedFromGridAndChips);
            } else {
                this.productsSelectedFromGridAndChips = selectedRows;
                this.selectionChanged.emit(selectedRows);
            }
        } else {
            this.selectedNodes.forEach(rowNode => rowNode.setSelected(true, false, true));
        }
    }

    public onClickCollapseBtn() {
        if (this.productsSelectedFromGrid.length > 0 || !this.expandPanelOpen) {
            this.expandPanelOpen = !this.expandPanelOpen;
            this.operationPanelConfig.productViewOpen = this.expandPanelOpen;
        } else {
            const invalidAttemptWarning = new DialogModel(
                true,
                LEVEL.WARNING,
                'Invalid Attempt',
                'Please select one or more product item',
                true,
                2000
            );
            const subscription = this.dialogService.confirm(invalidAttemptWarning).subscribe((res) => {
                subscription.unsubscribe();
            });
        }
    }

    public onGridSizeChange() {
        // this.gridApi.sizeColumnsToFit();
    }

    public updateSearchChipsAfterSplit(oldBookingIds: number[], newBookingIds: number[]) {
        let selectedIndex = 0;

        this.productItemsSearchResults.forEach((result, index) => {
            oldBookingIds.forEach(oldId => {
                if (result.bookingIdList.includes(oldId)) {
                    result.bookingIdList.splice(result.bookingIdList.indexOf(oldId), 1);
                    selectedIndex = index;
                }
            });
        });

        newBookingIds.forEach(id => {
            this.productItemsSearchResults[selectedIndex].bookingIdList.push(id);
        });
    }

    onDocumentClick() {

    }

    onAutoAllocationClicked() {

    }

    ngOnDestroy(): void {
        if (this.productSearchResultsSubscription) {
            this.productSearchResultsSubscription.unsubscribe();
        }
    }
}
