import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfigLoader } from '@tc-core/util/framework';
import { UserProfile } from '../../../authentication/user-profile';
import { TCO } from '../../../constants';
import { CompanySearchCriteria } from '../../../models/criteria/company-search-criteria';
import { SortDirection } from '../../../models/helper/sort-direction';
import {
    MasterDataRestService
} from '../../../services/backend-consumers/master-data-handler-service/master-data-rest.service';
import { CompanySetupService } from '../../../services/backend-consumers/setups/company-setup-service';
import { UserManagementService } from '../../../services/user-management/user-management.service';
import { DMCCommon } from '../../../services/util/common/dmc-common';
import { DataKey, DataStoreService } from '../../../services/util/framework/data-store.service';
import { Subscription } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { IGetRowsParams } from 'ag-grid-community';
import { tap } from 'rxjs/operators';
import {
    SetupGridComp
} from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid-comp';
import {
    SetupGridDataSource
} from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid-data-source';
import {
    SetupGridComponent
} from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid.component';

@Component({
    selector: 'tc-company-setup',
    templateUrl: './company-setup.component.html'
})
export class CompanySetupComponent extends SetupGridComp implements OnInit, SetupGridDataSource {
    @ViewChild('setupGrid')
    private setupGrid: SetupGridComponent;
    public colDefConfig = [];
    private userProfile: UserProfile;
    private hasACompany = false;

    // search criteria obj. used to
    private companySearchCriteria: CompanySearchCriteria;
    private searchCriteriaObserver: Subscription = new Subscription();

    constructor(
        private configLoader: ConfigLoader,
        private dataStoreService: DataStoreService,
        private masterDataRestService: MasterDataRestService,
        private common: DMCCommon,
        private dataStore: DataStoreService,
        private companySetupService: CompanySetupService,
        private userManagementService: UserManagementService
    ) {
        super();
    }

    ngOnInit() {
        this.companyAvailability();
        this.colDefConfig = this.configLoader.configurations.get(TCO.CONF.CONF_COMPANY_SETUP);
        this.subscribeSearchCriteria();
    }

    /**
     * subscribe search criteria changes in search bar
     * then force grid to refresh data
     * grid refresh will be call getRaws method which is using this subscribed criteria for backend call
     */
    subscribeSearchCriteria() {
        this.searchCriteriaObserver = this.dataStoreService.get(DataKey.companySetupSearchCriteria).subscribe(value => {
            this.companySearchCriteria = value;
            this.setupGrid.runForceSearch();
        });
    }

    createNewRow(): any {
        return {unsaved: true};
    }

    deleteRow(row: any): Observable<any> {
        return undefined;
    }

    public getRows(params: IGetRowsParams): Observable<any> {

        let pageSize = 14;
        if (params && params.endRow && params.startRow) {
            pageSize = params.endRow - params.startRow;
        }

        this.companySearchCriteria = this.userManagementService.updateSearchCriteriaWithUserKC(this.companySearchCriteria);

        if (params && params.startRow) {
            this.companySearchCriteria.start = params.startRow;
        }

        if (pageSize) {
            this.companySearchCriteria.size = pageSize;
        }
        if (params.sortModel && params.sortModel[0]) {
            this.companySearchCriteria.sortBy = params.sortModel[0].colId;
            if (params.sortModel[0].sort === SetupGridComp.GRID_SORT_ASCENDING) {
                this.companySearchCriteria.sortDirection = SortDirection.ASC;
            } else {
                this.companySearchCriteria.sortDirection = SortDirection.DESC;
            }
        }

        const userProfile = this.dataStore.get(DataKey.userProfile).getValue();
        this.companySearchCriteria.code = userProfile.kcCompany;
        this.companySearchCriteria.name = userProfile.kcCompanyName;
        return this.companySetupService.getRows(this.companySearchCriteria)
                   .pipe(
                       tap(
                           data => {
                               this.dataStoreService.set(DataKey.companySearchResultsForCriteria, data);
                           }
                       )
                   );
    }

    public companyAvailability() {
        this.hasACompany = this.userProfile !== null && this.userProfile !== undefined &&
            this.userProfile.kcCompany !== null && this.userProfile.kcCompany !== undefined;
    }

    isEditableRow = function(params: any) {
        return this.isInvalidRow();
    };

    isInvalidRow(params): boolean {
        if (params && params.data) {
            const data = params.data;
            if (data) {
                let invalid = true;
                if (
                    data.code &&
                    data.name
                ) {
                    invalid = false;
                }
                return invalid;
            } else {
                return false;
            }
        }
        return false;
    }

    isUnsavedRow(row: any): boolean {
        return false;
    }

    saveRow(row: any, event: any): Observable<any> {
        return undefined;
    }
}
