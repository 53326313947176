/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../adv-booking-search-results/adv-booking-search-results.component.ngfactory";
import * as i2 from "../adv-booking-search-results/adv-booking-search-results.component";
import * as i3 from "../../../../../node_modules/@tc/paginator/paginator.component.ngfactory";
import * as i4 from "@tc/paginator/paginator.component";
import * as i5 from "../../../widgets/shared/result-header/result-header.component.ngfactory";
import * as i6 from "../../../widgets/shared/result-header/result-header.component";
import * as i7 from "@angular/common";
import * as i8 from "./booking-search-results.component";
import * as i9 from "@tc-core/util/framework/config-loader.service";
import * as i10 from "../../../services/util/framework/data-store.service";
import * as i11 from "@tc-core/service/sorters/sorter.service";
import * as i12 from "../../../services/util/ui/content-summary-card.service";
import * as i13 from "@tc-core/util/ui/spinner.service";
import * as i14 from "@tc-core/util/framework/user-journey-manager.service";
import * as i15 from "../../../services/backend-consumers/master-data-handler-service/data-handler.service";
import * as i16 from "@tc-core/util/helpers/common-helper.service";
import * as i17 from "@angular/router";
import * as i18 from "../../../services/util/core-services/root.service";
import * as i19 from "../../../services/util/framework/dmc-query-params.service";
import * as i20 from "@tc-core/util/ui/auto-scroll.service";
import * as i21 from "../../../services/backend-consumers/reservation-v2/booking-management.service";
import * as i22 from "../booking-util.service";
import * as i23 from "../../../services/backend-consumers/reservation-v2/reservation-v2-management.service";
import * as i24 from "@tc-core/util/framework/event-manager.service";
import * as i25 from "@tc/modal/modal.service";
import * as i26 from "../../../services/backend-consumers/documents/document.service";
import * as i27 from "../../../services/util/common/dmc-common";
var styles_BookingSearchResultsComponent = [];
var RenderType_BookingSearchResultsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_BookingSearchResultsComponent, data: {} });
export { RenderType_BookingSearchResultsComponent as RenderType_BookingSearchResultsComponent };
function View_BookingSearchResultsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-adv-booking-search-results", [], null, [[null, "select"], [null, "edit"], [null, "editAsNew"], [null, "cardScroll"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("select" === en)) {
        var pd_0 = (_co.onSelect($event) !== false);
        ad = (pd_0 && ad);
    } if (("edit" === en)) {
        var pd_1 = (_co.onEdit($event) !== false);
        ad = (pd_1 && ad);
    } if (("editAsNew" === en)) {
        var pd_2 = (_co.onEditAsNew($event) !== false);
        ad = (pd_2 && ad);
    } if (("cardScroll" === en)) {
        var pd_3 = (_co.onCardScroll($event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, i1.View_AdvBookingSearchResultsComponent_0, i1.RenderType_AdvBookingSearchResultsComponent)), i0.ɵdid(1, 4308992, null, 0, i2.AdvBookingSearchResultsComponent, [], { sorter: [0, "sorter"], sortingHandler: [1, "sortingHandler"], sorters: [2, "sorters"], pager: [3, "pager"], summaryList: [4, "summaryList"], selectedSummary: [5, "selectedSummary"], detailedObject: [6, "detailedObject"], showEditBtn: [7, "showEditBtn"], showEditAsNew: [8, "showEditAsNew"] }, { select: "select", edit: "edit", editAsNew: "editAsNew", cardScroll: "cardScroll" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.sorterService.sorter; var currVal_1 = _co.sorterService.method; var currVal_2 = _co.sorters; var currVal_3 = _co.pager; var currVal_4 = _co.contentCardDataList; var currVal_5 = _co.selectedSearchSummary; var currVal_6 = _co.detailedBooking; var currVal_7 = _co.showEditBtn; var currVal_8 = _co.showEditAsNew; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }, null); }
function View_BookingSearchResultsComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-paginator", [], null, [[null, "page"], [null, "more"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("page" === en)) {
        var pd_0 = (_co.onPage($event) !== false);
        ad = (pd_0 && ad);
    } if (("more" === en)) {
        var pd_1 = (_co.onMore() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i3.View_PaginatorComponent_0, i3.RenderType_PaginatorComponent)), i0.ɵdid(1, 638976, null, 0, i4.PaginatorComponent, [], { length: [0, "length"], index: [1, "index"], size: [2, "size"], hasMore: [3, "hasMore"] }, { page: "page", more: "more" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.contentCardDataList.length; var currVal_1 = _co.pager.currPage; var currVal_2 = _co.pager.itemsPerPage; var currVal_3 = _co.paginatorHasMore; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_BookingSearchResultsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-result-header", [], null, [[null, "selectSorter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectSorter" === en)) {
        var pd_0 = (_co.onSort($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_ResultHeaderComponent_0, i5.RenderType_ResultHeaderComponent)), i0.ɵdid(1, 114688, null, 0, i6.ResultHeaderComponent, [], { heading: [0, "heading"], sorters: [1, "sorters"] }, { selectSorter: "selectSorter" }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_BookingSearchResultsComponent_1)), i0.ɵdid(3, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_BookingSearchResultsComponent_2)), i0.ɵdid(5, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.heading; var currVal_1 = _co.sorters; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = (_co.searchResults && (_co.searchResults.length > 0)); _ck(_v, 3, 0, currVal_2); var currVal_3 = (_co.contentCardDataList.length > _co.defaultPageResultCount); _ck(_v, 5, 0, currVal_3); }, null); }
export function View_BookingSearchResultsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tc-booking-search-results", [], null, null, null, View_BookingSearchResultsComponent_0, RenderType_BookingSearchResultsComponent)), i0.ɵdid(1, 245760, null, 0, i8.BookingSearchResultsComponent, [i9.ConfigLoader, i10.DataStoreService, i11.SorterService, i12.ContentSummaryCardService, i13.SpinnerService, i14.UserJourneyManager, i15.DataHandlerService, i16.CommonHelper, i17.ActivatedRoute, i18.RootService, i19.DMCQueryParamsService, i20.AutoScrollService, i21.BookingManagementService, i22.BookingUtilService, i23.ReservationV2ManagementService, i24.EventManager, i25.ModalService, i26.DocumentService, i27.DMCCommon], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BookingSearchResultsComponentNgFactory = i0.ɵccf("tc-booking-search-results", i8.BookingSearchResultsComponent, View_BookingSearchResultsComponent_Host_0, {}, {}, []);
export { BookingSearchResultsComponentNgFactory as BookingSearchResultsComponentNgFactory };
