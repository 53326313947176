import * as tslib_1 from "tslib";
import { ElementRef, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ConfigLoader } from '@tc-core/util/framework/config-loader.service';
import { EventManager } from '@tc-core/util/framework/event-manager.service';
import { UserJourneyManager } from '@tc-core/util/framework/user-journey-manager.service';
import { PageSummaryService } from '@tc-core/util/ui/page-summary.service';
import { SideNavBarService } from '@tc-core/util/ui/side-nav-bar.service';
import * as moment from 'moment';
import { TCO } from '../../constants';
import { SupplierSearchCriteria } from '../../models/criteria/supplier-search-criteria';
import { VehicleSearchCriteria } from '../../models/criteria/vehicle-search-criteria';
import { SortDirection } from '../../models/helper/sort-direction';
import { DashboardSearchItem, DashboardSearchItemType, ResourceCategory } from '../../models/launchpad/dashboard-search-item';
import { DashboardSearchNavItem } from '../../models/launchpad/dashboard-search-nav-item';
import { ProductQueueSearchCriteria } from '../../models/reservation-v2/criteria/product-queue-search-criteria';
import { DashboardSearchService } from '../../services/backend-consumers/launchpad/dashboard-search.service';
import { ResponseUtil } from '../../services/util/response/response-util.service';
import { DMCLocalStorageService } from '../../services/util/system/dmc-local-storage.service';
import { UserManagementService } from "../../services/user-management/user-management.service";
var DashboardSearchComponent = /** @class */ (function () {
    function DashboardSearchComponent(sideNavBarService, pageSummaryService, em, element, userJourneyManager, dashboardSearchService, localStorageService, configLoader, userManagementService) {
        var _this = this;
        this.sideNavBarService = sideNavBarService;
        this.pageSummaryService = pageSummaryService;
        this.em = em;
        this.element = element;
        this.userJourneyManager = userJourneyManager;
        this.dashboardSearchService = dashboardSearchService;
        this.localStorageService = localStorageService;
        this.configLoader = configLoader;
        this.userManagementService = userManagementService;
        this.navItems = [];
        this.filteredAssignmentItems = [];
        this.filteredSupplierItems = [];
        this.filteredResourceItems = [];
        this.filteredNavItems = [];
        this.TYPE_ASSIGNMENT = DashboardSearchItemType.ASSIGNMENT;
        this.TYPE_SUPPLIER = DashboardSearchItemType.SUPPLIER;
        this.TYPE_RESOURCE = DashboardSearchItemType.RESOURCE;
        this.displayItemFn = function (option) {
            var text = '';
            if (option) {
                if (option instanceof DashboardSearchItem) {
                    if (option.type === _this.TYPE_ASSIGNMENT) {
                        if (option.assignmentId) {
                            text += 'Job Id: ' + option.assignmentId;
                        }
                    }
                    if (option.type === _this.TYPE_SUPPLIER) {
                        if (option.supplier) {
                            text += 'Supplier: ' + option.supplier;
                        }
                    }
                    if (option.type === _this.TYPE_RESOURCE) {
                        if (option.resource) {
                            text += 'Resource: ' + option.resource;
                        }
                    }
                    return text;
                }
                else if (option instanceof DashboardSearchNavItem) {
                    text += option.name;
                }
            }
            return text;
        };
    }
    DashboardSearchComponent.prototype.ngOnInit = function () {
        var _this = this;
        var navItems = this.configLoader.configurations.get(TCO.CONF.CONF_NAVIGATION);
        this.navItems = this.generateDashboardNavItems(navItems);
        this.searchControl = new FormControl();
        this.searchControl.valueChanges.subscribe(function (text) {
            if (JSON.stringify(text) !== JSON.stringify({})) {
                _this.filteredAssignmentItems = [];
                _this.filteredSupplierItems = [];
                _this.filteredResourceItems = [];
                _this.filteredNavItems = [];
                if (text && !(text instanceof Object)) {
                    _this.dashboardSearchService.searchDashboardAssignmentItems(text).subscribe(function (result) {
                        _this.filteredAssignmentItems = ResponseUtil.getDataArray(result);
                    });
                    _this.dashboardSearchService.searchDashboardSupplierResourceItems(text).subscribe(function (result) {
                        var e_1, _a;
                        var supAndResources = ResponseUtil.getDataArray(result);
                        var suppliers = [];
                        var resources = [];
                        try {
                            for (var supAndResources_1 = tslib_1.__values(supAndResources), supAndResources_1_1 = supAndResources_1.next(); !supAndResources_1_1.done; supAndResources_1_1 = supAndResources_1.next()) {
                                var supOrResource = supAndResources_1_1.value;
                                if (supOrResource.type === DashboardSearchItemType.SUPPLIER) {
                                    suppliers.push(supOrResource);
                                }
                                else if (supOrResource.type === DashboardSearchItemType.RESOURCE) {
                                    resources.push(supOrResource);
                                }
                            }
                        }
                        catch (e_1_1) { e_1 = { error: e_1_1 }; }
                        finally {
                            try {
                                if (supAndResources_1_1 && !supAndResources_1_1.done && (_a = supAndResources_1.return)) _a.call(supAndResources_1);
                            }
                            finally { if (e_1) throw e_1.error; }
                        }
                        _this.filteredSupplierItems = suppliers;
                        _this.filteredResourceItems = resources;
                    });
                    _this.filteredNavItems = _this.navItems.filter(function (nav) {
                        var e_2, _a;
                        var hasTerm = nav.name && nav.name.toLowerCase().startsWith(text.toLowerCase());
                        if (!hasTerm && nav.searchTags && nav.searchTags.length) {
                            try {
                                for (var _b = tslib_1.__values(nav.searchTags), _c = _b.next(); !_c.done; _c = _b.next()) {
                                    var searchTag = _c.value;
                                    hasTerm = searchTag.toLowerCase().startsWith(text.toLowerCase());
                                    if (hasTerm) {
                                        break;
                                    }
                                }
                            }
                            catch (e_2_1) { e_2 = { error: e_2_1 }; }
                            finally {
                                try {
                                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                                }
                                finally { if (e_2) throw e_2.error; }
                            }
                        }
                        return hasTerm;
                    });
                }
            }
        });
    };
    DashboardSearchComponent.prototype.generateDashboardNavItems = function (navItems) {
        var e_3, _a;
        var list = [];
        try {
            for (var _b = tslib_1.__values(Object.keys(navItems)), _c = _b.next(); !_c.done; _c = _b.next()) {
                var key = _c.value;
                var navItem = navItems[key];
                if (navItem && navItem.dashboardSearch) {
                    var dashboardSearchNavItem = new DashboardSearchNavItem();
                    dashboardSearchNavItem.icon = navItem.icon;
                    dashboardSearchNavItem.name = navItem.title;
                    dashboardSearchNavItem.actionRef = key;
                    if (navItem.searchTags) {
                        dashboardSearchNavItem.searchTags = navItem.searchTags;
                    }
                    list.push(dashboardSearchNavItem);
                }
            }
        }
        catch (e_3_1) { e_3 = { error: e_3_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_3) throw e_3.error; }
        }
        return list;
    };
    DashboardSearchComponent.prototype.onSelectItem = function (event) {
        var selected = event.source.value;
        console.log(selected);
        if (selected.type === this.TYPE_ASSIGNMENT) {
            this.openServiceQueue(selected);
        }
        if (selected.type === this.TYPE_SUPPLIER) {
            this.openSuppliers(selected);
        }
        if (selected.type === this.TYPE_RESOURCE) {
            if (selected.resourceCategory === ResourceCategory.TRS) {
                this.openTrsResources(selected);
            }
            else {
                this.openGenResources(selected);
            }
        }
    };
    DashboardSearchComponent.prototype.getAssignmentPanelMaxHeight = function () {
        if (this.filteredSupplierItems.length && this.filteredResourceItems.length) {
            return '200px';
        }
        else if (this.filteredSupplierItems.length || this.filteredResourceItems.length) {
            return '400px';
        }
        else {
            return '600px';
        }
    };
    DashboardSearchComponent.prototype.getSupplierPanelMaxHeight = function () {
        if (this.filteredAssignmentItems.length && this.filteredResourceItems.length) {
            return '200px';
        }
        else if (this.filteredAssignmentItems.length || this.filteredResourceItems.length) {
            return '400px';
        }
        else {
            return '600px';
        }
    };
    DashboardSearchComponent.prototype.getResourcePanelMaxHeight = function () {
        if (this.filteredAssignmentItems.length && this.filteredSupplierItems.length) {
            return '200px';
        }
        else if (this.filteredAssignmentItems.length || this.filteredSupplierItems.length) {
            return '400px';
        }
        else {
            return '600px';
        }
    };
    DashboardSearchComponent.prototype.getGenericIcon = function (resourceType) {
        var icon = 'person';
        if (resourceType === 'guide') {
            icon = 'face';
        }
        else if (resourceType === 'GUID') {
            icon = 'face';
        }
        else if (resourceType === 'rep') {
            icon = 'person';
        }
        else if (resourceType === 'meal') {
            icon = 'fastfood';
        }
        else if (resourceType === 'bellydancer') {
            icon = 'person';
        }
        else if (resourceType === 'hennaartist') {
            icon = 'filter_vintage';
        }
        else if (resourceType === 'driver') {
            icon = 'airline_seat_recline_normal';
        }
        else if (resourceType === 'vehicle') {
            icon = 'directions_car';
        }
        else if (resourceType === 'ticket') {
            icon = 'loyalty';
        }
        return icon;
    };
    DashboardSearchComponent.prototype.onSelectNavItem = function (event) {
        var selected = event.source.value;
        console.log(selected);
        this.userJourneyManager.goForKey(selected.actionRef);
    };
    // navigate
    DashboardSearchComponent.prototype.openServiceQueue = function (item) {
        if (item.assignmentId && item.assignmentId > 0) {
            var criteria = new ProductQueueSearchCriteria();
            criteria.assignmentId = item.assignmentId;
            criteria.startDate = moment().startOf('year').format('YYYY-MM-DD');
            criteria.endDate = moment().add(1, 'year').endOf('year').format('YYYY-MM-DD');
            criteria.sortDirection = SortDirection.ASC;
            this.localStorageService.store(TCO.AppData.PRODUCT_QUEUE_SEARCH_CRITERIA_COMMON, criteria);
        }
        this.userJourneyManager.goForKey('BEGIN_RESOURCE_ALLOCATION_AS_COMMON_PANEL');
    };
    DashboardSearchComponent.prototype.openSuppliers = function (item) {
        if (item.supplierCode) {
            var criteria = new SupplierSearchCriteria();
            criteria.code = item.supplierCode;
            this.localStorageService.store(TCO.AppData.SUPPLIER_SEARCH_CRITERIA, criteria);
        }
        this.userJourneyManager.goForKey('BEGIN_SUPPLIER_MANAGEMENT');
    };
    DashboardSearchComponent.prototype.openTrsResources = function (item) {
        if (item.resourceId) {
            var criteria = new VehicleSearchCriteria();
            criteria.resourceType = 'vehicle';
            criteria.activeStatus = true;
            criteria.resourceId = item.resourceId;
            this.localStorageService.store(TCO.AppData.VEHICLE_SEARCH_CRITERIA, criteria);
        }
        this.userJourneyManager.goForKey('BEGIN_RESOURCE_MANAGEMENT_AS_TRANSPORT');
    };
    DashboardSearchComponent.prototype.openGenResources = function (item) {
        if (item.resourceId) {
            var criteria = new VehicleSearchCriteria();
            criteria.activeStatus = true;
            criteria.resourceId = item.resourceId;
            this.localStorageService.store(TCO.AppData.GENERIC_SEARCH_CRITERIA, criteria);
        }
        this.userJourneyManager.goForKey('BEGIN_RESOURCE_MANAGEMENT_AS_GENERIC');
    };
    return DashboardSearchComponent;
}());
export { DashboardSearchComponent };
