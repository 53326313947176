import { ResourceSearchCriteria } from './resource-search-criteria';
import {KeyControlAndAbstractSearchCriteria} from './key-control-and-abstract-search-criteria';

export class GenericSearchCriteria extends ResourceSearchCriteria {
    genericResId: number;
    name: string;
    firstName: string;
    gender: string;
    grade: string;
    lastName: string;
    licenseNum: string;
    profileType:string;
    passportNum: string;
    route: string = '';
    transferMode: string = '';
    workingHours: string;
    languages: string[];
    routes: string[] = [];
    transferModes: string[] = [];
    yearsOfExperience: number;
    serviceType:string;

    // for allocations
    serviceItems: string;
    serviceStartTime: string;
    isPrivate: boolean;

    // to filter by rejected resourceId list
    rejectedResourceIdList: number[];
}
