import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatPaginatorModule, MatSortModule, MatTableModule } from '@angular/material';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from '@tc/pipes';
import { CostSetupDataCardComponent } from './cost-setup-data-card.component';

@NgModule({
    declarations: [CostSetupDataCardComponent],
    imports: [
        TranslateModule.forChild(),
        CommonModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        PipesModule
    ],
    exports: [
        CostSetupDataCardComponent
    ]
})
export class CostSetupDataCardModule {}
