import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ReservationStatus } from '../../../models/reservation/service-item';
import { ResultContentCardComponent } from '../result-content-card/result-content-card.component';
var ResultContentCardReservationsComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ResultContentCardReservationsComponent, _super);
    function ResultContentCardReservationsComponent() {
        return _super.call(this) || this;
    }
    ResultContentCardReservationsComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
        this.overrideStyles();
        // reservation status
        if (this.summaryContent.data.value['reservationStatus']) {
            if (this.summaryContent.data.value['reservationStatus'] === ReservationStatus.CANCELLED) {
                this.isCancelled = true;
            }
            else if (this.summaryContent.data.value['reservationStatus'] === ReservationStatus.UPDATED) {
                this.isUpdated = true;
            }
        }
    };
    ResultContentCardReservationsComponent.prototype.overrideStyles = function () {
        this.row1col1_customsStyles = ["tc-strip-text--primary"];
        this.row1col2_customsStyles = ["tc-strip-text--primary"];
        this.row1col3_customsStyles = ["tc-strip-text--primary"];
        this.row1col4_customsStyles = ["tc-strip-text--primary", "tc-strip-text--right"];
        this.row2col1_customsStyles = [];
        this.row2col2_customsStyles = [];
        this.row2col3_customsStyles = ["tc-strip-text--primary"];
        this.row2col4_customsStyles = ["tc-strip-text--right"];
    };
    return ResultContentCardReservationsComponent;
}(ResultContentCardComponent));
export { ResultContentCardReservationsComponent };
