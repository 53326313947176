/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./passenger-selection.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "../../../../../../../node_modules/ag-grid-angular/dist/agGridAngular.ngfactory";
import * as i4 from "ag-grid-angular/dist/angularFrameworkOverrides";
import * as i5 from "ag-grid-angular/dist/angularFrameworkComponentWrapper";
import * as i6 from "ag-grid-angular/dist/agGridAngular";
import * as i7 from "@angular/common";
import * as i8 from "./passenger-selection.component";
import * as i9 from "../../../../../services/util/framework/data-store.service";
var styles_PassengerSelectionComponent = [i0.styles];
var RenderType_PassengerSelectionComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PassengerSelectionComponent, data: {} });
export { RenderType_PassengerSelectionComponent as RenderType_PassengerSelectionComponent };
function View_PassengerSelectionComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["style", "color: red"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.disableCloseMsg; _ck(_v, 1, 0, currVal_0); }); }
export function View_PassengerSelectionComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "detail-group__body-service-item container-fluid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "detail-group__heading container-fluid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "item-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "item-header__content"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(6, 0, null, null, 5, "div", [["class", "tc-px-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 16777216, null, null, 4, "ag-grid-angular", [["class", "ag-theme-material tc-ag-grid tc-ag-grid--full-width tc-ag-grid--popup-content"]], null, [[null, "gridReady"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("gridReady" === en)) {
        var pd_0 = (_co.onGridReady($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_AgGridAngular_0, i3.RenderType_AgGridAngular)), i1.ɵprd(512, null, i4.AngularFrameworkOverrides, i4.AngularFrameworkOverrides, [i1.NgZone]), i1.ɵprd(512, null, i5.AngularFrameworkComponentWrapper, i5.AngularFrameworkComponentWrapper, []), i1.ɵdid(10, 4898816, null, 1, i6.AgGridAngular, [i1.ElementRef, i1.ViewContainerRef, i4.AngularFrameworkOverrides, i5.AngularFrameworkComponentWrapper, i1.ComponentFactoryResolver], { rowData: [0, "rowData"], columnDefs: [1, "columnDefs"], frameworkComponents: [2, "frameworkComponents"], defaultColDef: [3, "defaultColDef"], overlayNoRowsTemplate: [4, "overlayNoRowsTemplate"], domLayout: [5, "domLayout"] }, { gridReady: "gridReady" }), i1.ɵqud(603979776, 1, { columns: 1 }), (_l()(), i1.ɵeld(12, 0, null, null, 5, "div", [["class", "container assign-panel-footer-section"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PassengerSelectionComponent_1)), i1.ɵdid(14, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(15, 0, null, null, 2, "button", [["class", "assign-panel-footer-section__button tc-button-secondary mat-button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClose($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(16, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_1 = ((_co.booking == null) ? null : _co.booking.bookingPassengers); var currVal_2 = _co.columnDefs; var currVal_3 = _co.frameworkComponents; var currVal_4 = _co.defaultColDef; var currVal_5 = _co.overlayNoRowsTemplate; var currVal_6 = "autoHeight"; _ck(_v, 10, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_7 = _co.disableClose; _ck(_v, 14, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("Item Passengers")); _ck(_v, 4, 0, currVal_0); var currVal_8 = (_co.disableClose && ((_co.booking == null) ? null : ((_co.booking.bookingPassengers == null) ? null : _co.booking.bookingPassengers.length))); _ck(_v, 15, 0, currVal_8); var currVal_9 = i1.ɵunv(_v, 16, 0, i1.ɵnov(_v, 17).transform("Close")); _ck(_v, 16, 0, currVal_9); }); }
export function View_PassengerSelectionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "tc-passenger-selection", [], null, null, null, View_PassengerSelectionComponent_0, RenderType_PassengerSelectionComponent)), i1.ɵdid(1, 114688, null, 0, i8.PassengerSelectionComponent, [i2.TranslateService, i9.DataStoreService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PassengerSelectionComponentNgFactory = i1.ɵccf("tc-passenger-selection", i8.PassengerSelectionComponent, View_PassengerSelectionComponent_Host_0, { booking: "booking", bookingItem: "bookingItem", readonly: "readonly", onChangeFn: "onChangeFn", output: "output" }, {}, []);
export { PassengerSelectionComponentNgFactory as PassengerSelectionComponentNgFactory };
