import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Icon } from '@tc-core/model/it/codegen/ui/framework/icon';

@Component({
    selector: 'tc-resource-expanded-content-header',
    templateUrl: './resource-expanded-content-header.component.html'
})
export class ResourceExpandedContentHeaderComponent implements OnInit {

    @Input() headerText: string;
    @Input() secondaryHeaderText: string;
    @Input() icon: Icon = null;
    @Input() showProgress: boolean;

    @Output() headerClick: EventEmitter<any> = new EventEmitter();

    constructor() { }

    ngOnInit() {
    }

    onHeaderClick(event) {
        this.headerClick.emit(event);
    }
}
