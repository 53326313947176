import { GenResourceCost } from './gen-resource-cost-setup';
import { RoomResourceCost } from './room-resource-cost';
import { SeasonDateRange } from './season-date-range';
import { TrsResourceCost } from './trs-resource-cost-setup';

export class Season {
    seasonId: number;
    supplierId: number;
    tempSeasonId: number;
    name: string;
    colorId: string;
    seasonDateRanges: SeasonDateRange[];
    trsResourceCosts: TrsResourceCost[];
    genResourceCosts: GenResourceCost[];
    roomResourceCosts: RoomResourceCost[];
}
