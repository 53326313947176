import { DOCUMENT } from '@angular/common';
import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Inject,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    SimpleChanges,
    ViewChild,
    ViewChildren
} from '@angular/core';
import { CurrencyFormat } from '@tc-core/model/it/codegen/ui/framework/currency-format';
import { Pager } from '@tc-core/model/it/codegen/ui/framework/pager';
import { Sorter } from '@tc-core/model/it/codegen/ui/framework/sorter';
import { SummaryCardData } from '@tc-core/model/it/codegen/ui/framework/summary-card-data';
import { FocusHandlerService } from '@tc-core/util/framework/focus-handler.service';
import { PagerService } from '@tc-core/util/ui/pager.service';
import { ExpansionPanelDirective } from '@tc/directives/expansion-panel';
import { Supplier } from '../../../models/supplier/supplier';

@Component({
    selector: 'tc-adv-transfer-supplier-search-results',
    templateUrl: './adv-transfer-supplier-search-results.component.html'
})
export class AdvTransferSupplierSearchResultsComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {

    @Input() pager: Pager;

    @Input() clientResultsList: Array<SummaryCardData>;
    @Input() detailSupplier: Supplier;
    @Input() resourceTypes: any[];
    @Input() subHeaderText: string;
    @Input() footerDataMap: Map<number, object>;
    @Input() showEditBtn: boolean;
    @Input() showEditAsNew: boolean = true;
    @Input() heading: string;
    @Input() isSingleResultExpand: boolean = true;
    @Input() currencyFormat: CurrencyFormat;
    @Input() trsModes = [];
    @Input() vehicleModels = [];
    @Input() costingTypes = [];
    @Input() profileTypes = [];

    @Output() supplierSelect: EventEmitter<any> = new EventEmitter();
    @Output() edit: EventEmitter<Supplier> = new EventEmitter();
    @Output() editAsNew: EventEmitter<Supplier> = new EventEmitter();
    @Output() newTabClick: EventEmitter<any> = new EventEmitter<any>();
    @Output() del: EventEmitter<any> = new EventEmitter();
    @Output() focus: EventEmitter<number> = new EventEmitter();
    @Output() sort: EventEmitter<Sorter> = new EventEmitter();
    @Output() singleClientSelect: EventEmitter<SummaryCardData> = new EventEmitter();
    @Output() selection: EventEmitter<string> = new EventEmitter();
    @Output() cardScroll: EventEmitter<number> = new EventEmitter<number>();

    @Output() actionClick: EventEmitter<any> = new EventEmitter();
    @Output() groupActionClick: EventEmitter<any> = new EventEmitter();

    @Output() displayBookingDetails: EventEmitter<any> = new EventEmitter();
    private FOCUS_ID: string = 'result';

    public defaultPageResultCount = 10;
    public selectedClient: SummaryCardData = null;
    public expandedPanelIndex: number = -1;

    public selectionIndex = '1';
    // public pager: Pager;
    navItems: any;

    private expandedPanel = null;
    private expansionPanel = null;
    // private pagerSubscription: Subscription = new Subscription();

    @ViewChildren(ExpansionPanelDirective) _expansionPanels: QueryList<ExpansionPanelDirective>;
    @ViewChild('clientExp') clientExp: ElementRef;

    constructor(
        private pagerService: PagerService,
        private focusHandler: FocusHandlerService,
        @Inject(DOCUMENT) private document: any
    ) { }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges) {

    }

    ngAfterViewInit() {
        if (this.isSingleResultExpand && this.clientResultsList && this.clientResultsList.length === 1) {
            if (this._expansionPanels && this._expansionPanels !== undefined) {
                this.selectionIndex = '1';
                this.openExpansionPanel();
            }
        }
    }

    public openExpansionPanel() {
        this.onClientSelection(this.clientResultsList[+this.selectionIndex - 1], +this.selectionIndex - 1);

        // close expanded panel
        if (this.expandedPanelIndex !== -1) {
            this.expandedPanel.expansionState.next(true);
        }

        // open focused panel
        this.expansionPanel = this._expansionPanels.find((expansionElement, i) => {
            return (i + 1) === +this.selectionIndex - ((this.pager.currPage) * this.pager.itemsPerPage);
        });

        // same page
        if (this.expansionPanel !== null && this.expansionPanel !== undefined) {
            this.expansionPanel.expansionState.next(false);
            // another page
        } else {
            let page = Math.ceil(+this.selectionIndex / ((this.pager.currPage + 1) * this.pager.itemsPerPage));
            this.pager.currPage = page;
        }
    }

    public onClientSelection(summaryCardData: SummaryCardData, index?: number) {
        this.selectedClient = summaryCardData;

        if (summaryCardData) {
            this.supplierSelect.emit(this.selectedClient);
            this.cardScroll.emit(index);
        }
    }

    public onExpand() {
        // this.supplierSelect.emit(this.selectedClient);
    }

    public onHeaderClick() {
        this.selectedClient = null;
    }

    public onOutsideClick() {
        this.selectedClient = null;
    }

    public onEditClick(event) {
        this.edit.emit(event);
    }

    public onEditAsNewClick(event: any) {
        this.editAsNew.emit(this.detailSupplier);
    }

    public onNewTabClick(event) {
        this.newTabClick.emit(event);
    }

    public onDeleteClick(event) {
        this.del.emit(event);
    }

    onGroupActionClick(event) {
        this.groupActionClick.emit(event);
    }

    onActionClick(summaryCardData, event) {
        this.selectedClient = summaryCardData;

        if (summaryCardData) {
            this.actionClick.emit(event);
            this.supplierSelect.emit(this.selectedClient);
        }
    }

    showDetails(bookingId) {
        this.displayBookingDetails.emit(bookingId);
    }

    public onPanelExpand(index: number) {
        this.selectionIndex = (index + 1 + ((this.pager.currPage) * this.pager.itemsPerPage)).toString();
        this.expansionPanel = this._expansionPanels.find((expansionElement, i) => {
            return (i + 1) === +this.selectionIndex - (this.pager.currPage * this.pager.itemsPerPage);
        });
        this.expandedPanelIndex = +this.selectionIndex - 1;
        this.expandedPanel = this.expansionPanel;
        this.focus.emit(Number.parseInt(this.selectionIndex));

        // set animation class
        this.expansionPanel.elementRef.nativeElement.classList.add('animate');

    }

    ngOnDestroy() {
        // this.pagerSubscription.unsubscribe();
    }
}



