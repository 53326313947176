<div class="add-document-wrapper">
  <table>
    <tbody>
    <tr *ngFor="let txt of docTypeOptions">
        <mat-checkbox class="example-margin" [checked]="selected.includes(txt['code'])" (change)="addType($event, txt)">{{txt['name']}}</mat-checkbox>
    </tr>
    </tbody>
  </table>
</div>
<div class="tc-modal__footer">
  <!--<button class="button-left tc-button-secondary mat-button" mat-button=""
          (click)="closePopUp($event)"><span class="mat-button-wrapper">Cancel</span>
    <div class="mat-button-ripple mat-ripple" matripple=""></div>
    <div class="mat-button-focus-overlay"></div>
  </button>-->

  <button class="button-right tc-button-primary tc-flex-item mat-button" (click)="addDocuments()">
    {{'Add' | translate}}
  </button>
</div>
