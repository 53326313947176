import { GenTourItem } from '../../../models/reservation/generic/gen-tour-item';
import { GenTourItinerary } from '../../../models/reservation/generic/gen-tour-itinerary';
import { ServiceAssignment } from '../../../models/reservation/service-assignment';

export class GenQueueUtil {

    static isTourItineraryRow(params: any) {
        return params && params.data && params.data.gridObjType && params.data.gridObjType === 'GenTourItinerary';
    }

    static isTourItemRow(params: any) {
        return params && params.data && this.isTourItemRow_(params.data);
    }

    static isTourItemRow_(tourItem: any) {
        return tourItem.gridObjType && tourItem.gridObjType === 'GenTourItem';
    }

    static isAssignmentRow(params: any) {
        return params && params.data && params.data.gridObjType && params.data.gridObjType === 'ServiceAssignment';
    }

    static isInvalidAssignment(params: any) {
        let isAssignment = GenQueueUtil.isAssignmentRow(params);
        if (isAssignment) {
            let availableAdults = 0;
            let availableChildren = 0;
            let availableInfants = 0;

            let addedAdults = 0;
            let addedChildren = 0;
            let addedInfants = 0;
            let item: GenTourItem = params.node.parent.data;
            if (item && GenQueueUtil.isTourItemRow(item)) {
                availableAdults = item.adults;
                availableChildren = item.children;
                availableInfants = item.infants;
                for (let assignment of item.assignments) {
                    if (!isNaN(assignment.adults)) {
                        addedAdults += assignment.adults;
                    }
                    if (!isNaN(assignment.children)) {
                        addedChildren += assignment.children;
                    }
                    if (!isNaN(assignment.infants)) {
                        addedInfants += assignment.infants;
                    }
                }
            }
            return availableAdults < addedAdults || availableChildren < addedChildren || availableInfants < addedChildren;
        } else {
            return false;
        }
    }
}
