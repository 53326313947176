import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Cart} from '@tc-core/model/it/codegen/tbx/api/cart/cart';
import {Client} from '@tc-core/model/it/codegen/tbx/api/client/client';
import {ClientSummary} from '@tc-core/model/it/codegen/tbx/api/client/client-summary';
import {IProduct} from '@tc-core/model/it/codegen/tbx/api/product/iproduct';
import {LEVEL} from '@tc-core/model/it/codegen/ui/framework/dialog-model';
import {ModalData} from '@tc-core/model/it/codegen/ui/framework/modal-data';
import {NavItem} from '@tc-core/model/it/codegen/ui/framework/nav-item';
import {ClientGETCriteria} from '@tc-core/model/it/codegen/ui/search/criteria/client-GET-criteria';
import {UrlParamNames} from '@tc-core/model/it/codegen/ui/url-param-names';
// import { ServiceHandler } from '@tc-core/service/service-handlers/service-handler.service';
import {TC} from '@tc-core/util/constants';
import {DataKey, DataStore} from '@tc-core/util/framework/data-store.service';
import {EventManager} from '@tc-core/util/framework/event-manager.service';
import {FocusHandlerService} from '@tc-core/util/framework/focus-handler.service';
import {UserJourneyManager} from '@tc-core/util/framework/user-journey-manager.service';
import {FocusViewService} from '@tc/focus-view/focus-view.service';
import {Subscription} from 'rxjs';
// import { ClientInfoViewComponent } from '../client-info-view/client-info-view.component';
import {TcApiError} from '@tc-core/model/it/codegen/tbx/ext/errors/tc-api-error';
import {TcHttpError} from '@tc-core/model/it/codegen/tbx/ext/errors/tc-http-error';
import {CommonHelper} from '@tc-core/util/helpers/common-helper.service';
import {RootService} from '../../../services/util/core-services/root.service';

@Component({
    selector: 'tc-side-nav',
    templateUrl: './side-nav.component.html'
})
export class SideNavComponent implements OnInit, OnDestroy {

    public navItems: NavItem[] = [];

    sidebarSubscription: Subscription;
    getClientSubscription: Subscription;
    cartInfoSubscription: Subscription;
    showSidebar: boolean;
    userName: string;
    cartItemCount: number;
    shoppingCart: Cart;
    client: ClientSummary;

    showSideNav = false;
    sideNavVisible = true;

    selectionIndex = -1;
    environmentName: string = '';
    releaseTitle: string = '';
    innerWidth; // to check display size before side-nav toggle

    constructor(public rootService: RootService,
                private em: EventManager,
                private dataStore: DataStore,
                private userJourneyManager: UserJourneyManager,
                private activatedRoute: ActivatedRoute,
                // private serviceHandler: ServiceHandler,
                private focusHandeler: FocusHandlerService,
                private focusViewService: FocusViewService,
                private commonHelper: CommonHelper) {
        this.showSidebar = true;
    }

    ngOnInit() {
        // $.getScript('assets/js/init/initMenu.js');
        // $.getScript('assets/js/sidebar-moving-tab.js');

        this.dataStore.set(DataKey.cart, null, true);

        // load client if there is no client available
        if (!this.client) {
            let cliId = this.activatedRoute.snapshot.queryParams['cliId'];
            if (cliId && cliId !== '-1') {
                const criteria: ClientGETCriteria = new ClientGETCriteria();
                criteria.cliId = cliId;
                // this.serviceHandler.getClientSearchServiceHandler().getClient(criteria);
            }
        }

        // Get cart details
        let cartId = this.activatedRoute.snapshot.queryParams[UrlParamNames.CART_ID];
        if (cartId && cartId !== '-1') {
            // this.serviceHandler.getCartService().getCartById(cartId);
        }

        this.userJourneyManager.navItems.subscribe(navItems => {
            this.navItems = navItems;
        });

        this.em.addEvent('EVENT_MANAGER_CONFIG', 'SYS_TOGGLE_SIDE_NAV').subscribe(() => {
            console.log("before - " + this.showSideNav);
            this.showSideNav = !this.showSideNav;
            console.log("after - " + this.showSideNav);
            this.focusHandeler.isNavActive = this.showSideNav;
        });

        // Get client details
        this.getClientSubscription = this.dataStore.get(DataKey.client).subscribe(data => {
            if (this.commonHelper.dataValidity(data)) {
                let client: Client = data;
                this.userName = client.summary.name;
                this.client = client.summary;
            } else if (data instanceof TcApiError) {

            } else if (data instanceof TcHttpError) {

            }
        }, (error: any) => {
        });

        // listen to cart change
        this.cartInfoSubscription = this.dataStore.get(DataKey.readCart).subscribe(data => {
            if (this.commonHelper.dataValidity(data)) {
                this.onCartLoad(data);
            } else if (data instanceof TcApiError) {

            } else if (data instanceof TcHttpError) {

            }
        }, (error: any) => {
        });

        // this.serviceHandler
        //     .getAdminServiceHandlerService()
        //     .getEnvironmentConfigurations()
        //     .subscribe(resp => {
        //         if (this.commonHelper.dataValidity(resp)) {
        //             if (resp.length > 0) {
        //                 this.environmentName = resp[0].name;
        //                 this.releaseTitle = resp[1].name;
        //             }
        //         } else if (resp instanceof TcApiError) {
        //
        //         } else if (resp instanceof TcHttpError) {
        //
        //         }
        //     });

        this.innerWidth = window.innerWidth;
    }

    ngOnDestroy() {
        if (this.sidebarSubscription) {
            this.sidebarSubscription.unsubscribe();
        }
        if (this.getClientSubscription) {
            this.getClientSubscription.unsubscribe();
        }
        if (this.cartInfoSubscription) {
            this.cartInfoSubscription.unsubscribe();
        }

        // remove actions
        this.em.removeEvents([
            'SYS_TOGGLE_SIDE_NAV'
        ]);
    }

    public onNavSelect(navItem: NavItem) {
        this.userJourneyManager.go(navItem);
    }

    /*public onClientSelect(client: ClientSummary) {
        let qParams: Map<string, string> = new Map();
        qParams.set('cliId', client.id.toString());

        let dataObject = new ModalData(
            LEVEL.SUCCESS,
            '',
            null,
            null,
            '',
            ClientInfoViewComponent,
            {},
            {
                'label': 'close'
            },
            '',
            '',
            '',
            '',
            '',
            'client-info-secondary-header');
        this.focusViewService
            .confirm(dataObject).subscribe(res => {
            if (res) {

            }
        });
        //this.userJourneyManager.goForKey('GO_TO_CLIENT_INFO', qParams);
    }*/

    public onCartSelect(event: any) {
        this.userJourneyManager.goForKey('NEXT_SHOPPING_BASKET_PAGE');
    }

    private onCartLoad(data) {
        if (data && JSON.stringify(data) !== JSON.stringify({})) {
            // this.cartInfoSubscription.unsubscribe();
            if (data.status.code !== -1) {
                this.shoppingCart = data.data[0];
                const products: IProduct[] = this.shoppingCart.products;
                if (products) {
                    let productList = products.filter(item => item.summary.productStatus !== TC.PRODUCT_STATUS.CANCELLED);
                    this.cartItemCount = productList.length;
                }
            } else {
                // todo
            }
        } else {
            this.shoppingCart = null;
            this.cartItemCount = 0;
        }
    }

    @HostListener('document:click', ['$event'])
    private onDocumentClick(event) {
        const sidebar = document.getElementsByClassName('sidebar')[0];
        const navToggle = document.getElementsByClassName('navbar-toggle')[0];

        if (this.innerWidth < 1500 && !navToggle.contains(event.target)) {
            if (this.showSideNav) {
                this.em.trigger('SYS_TOGGLE_SIDE_NAV');
            }
        }
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.innerWidth = window.innerWidth;
        console.log(this.innerWidth);
    }
}
