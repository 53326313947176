<div class="">
    <form [formGroup]="timeRangeForm">
        <div class="row tc-py-5">
            <div class="col-xs-12 col-sm-6">
                <div class="tc-caption-txt txt-light">Start Time</div>
                <ngx-timepicker-field [format]="24" formControlName="startTime"></ngx-timepicker-field>
            </div>
            <div class="col-xs-12 col-sm-6">
                <div class="tc-caption-txt txt-light">End Time</div>
                <ngx-timepicker-field [format]="24" formControlName="endTime"></ngx-timepicker-field>
            </div>
        </div>
    </form>
    <div class="tc-modal-footer tc-px-0 tc-pb-0">
        <button class="button-left tc-button-secondary mat-button tc-mr-2" (click)="onClose()">
            {{'Close' | translate}}
        </button>
        <button
                class="button-right tc-button-primary mat-button" (click)="onOkClick()"
                [disabled]="isOkDisabled()">
            {{'Ok' | translate}}
        </button>
    </div>
</div>
