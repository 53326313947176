import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { TcErrorType } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-error';
import { LEVEL } from '@tc-core/model/it/codegen/ui/framework/dialog-model';
import { ModalData } from '@tc-core/model/it/codegen/ui/framework/modal-data';
import { TC } from '@tc-core/util';
import { ConfigLoader } from '@tc-core/util/framework';
import { ModalService } from '@tc/modal/modal.service';
import { of, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SetupGridComp } from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid-comp';
import { SetupGridComponent } from '../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid.component';
import { TCO } from '../../../constants';
import { FileUploaderComponent } from '../../../widgets/framework/file-uploader/file-uploader.component';
import { ProductDetailsSearchCriteria } from '../../../models/criteria/product-details-search-criteria';
import { SortDirection } from '../../../models/helper/sort-direction';
import { MasterDataRestService } from '../../../services/backend-consumers/master-data-handler-service/master-data-rest.service';
import { ProductDetailsService } from '../../../services/backend-consumers/setups/product-details-service';
import { DMCCommon } from '../../../services/util/common/dmc-common';
import { DataKey, DataStoreService } from '../../../services/util/framework/data-store.service';
var EMPTY_CHAR = TC.FORMAT.EMPTY_CHAR;
var ProductDetailsGridSetupComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ProductDetailsGridSetupComponent, _super);
    function ProductDetailsGridSetupComponent(configLoader, productDetailsService, dataStoreService, modalService, common, masterDataRestService) {
        var _this = _super.call(this) || this;
        _this.configLoader = configLoader;
        _this.productDetailsService = productDetailsService;
        _this.dataStoreService = dataStoreService;
        _this.modalService = modalService;
        _this.common = common;
        _this.masterDataRestService = masterDataRestService;
        _this.selectedResourceTypes = [];
        _this.colDefConfig = [];
        _this.gridActions = [];
        // search criteria obj. used to
        _this.productDetailsSearchCriteria = new ProductDetailsSearchCriteria();
        _this.searchCriteriaObserver = new Subscription();
        _this.serviceTypeList = [];
        _this.imageUrlImportSubscribe = new Subscription();
        _this.genImageUploadSubscribe = new Subscription();
        _this.onImageUploadClick = function (params) {
            _this.openImageUploadDialog(params);
        };
        _this.isDisableImageUploadClick = function (params) {
            return _this.checkRowInvalid(params.data);
        };
        _this.getServiceTypeText = function (params) {
            var groupText = EMPTY_CHAR;
            if (params && params.data && params.data.serviceType) {
                if (params.data.serviceType.code) {
                    groupText = params.data.serviceType.code;
                }
                else if (params.data.serviceType && params.data.serviceType !== '-') {
                    var type = _this.serviceTypeList.find(function (value) { return value.code === params.data.serviceType; });
                    if (type) {
                        groupText = type.name;
                    }
                    else {
                        groupText = EMPTY_CHAR;
                    }
                }
                else {
                    groupText = EMPTY_CHAR;
                }
            }
            return groupText;
        };
        _this.onServiceTypeSet = function (params) {
            if (params.newValue && params.data) {
                params.data.serviceType = params.newValue.code;
            }
        };
        _this.getServiceTypes = function (params, rowData, text) {
            return _this.dataStoreService.get(DataKey.resourceTypes)
                .map(function (aEvents) {
                return aEvents.filter(function (aEvent) {
                    if (text) {
                        if (aEvent.name) {
                            return aEvent.name.toLowerCase().indexOf(text.toLocaleString()) > -1;
                        }
                    }
                    else {
                        return true;
                    }
                });
            });
        };
        return _this;
    }
    ProductDetailsGridSetupComponent.prototype.ngOnInit = function () {
        this.colDefConfig = this.configLoader.configurations.get(TCO.CONF.CONF_PRODUCT_DETAILS_SETUP);
        this.addGridActions();
        console.log(this.setupGrid);
        this.subscribeSearchCriteria();
        this.getResourceTypeList();
    };
    ProductDetailsGridSetupComponent.prototype.addGridActions = function () {
        this.gridActions.push({
            icon: 'file_upload',
            action: this.onImageUploadClick.bind(this),
            disable: this.isDisableImageUploadClick.bind(this),
            tooltip: 'Add Image'
        });
    };
    /**
     * subscribe search criteria changes in search bar
     * then force grid to refresh data
     * grid refresh will be call getRaws method which is using this subscribed criteria for backend call
     */
    ProductDetailsGridSetupComponent.prototype.subscribeSearchCriteria = function () {
        var _this = this;
        this.searchCriteriaObserver = this.dataStoreService.get(DataKey.productDetailSearchCriteria)
            .subscribe(function (value) {
            _this.productDetailsSearchCriteria = value;
            _this.setupGrid.runForceSearch();
        });
    };
    ProductDetailsGridSetupComponent.prototype.openImageUploadDialog = function (params) {
        var _this = this;
        this.dataStoreService.set(DataKey.popupClose, null);
        this.dataStoreService.set(DataKey.fileToUpload, null);
        var dataObject = new ModalData(LEVEL.SUCCESS, 'Upload Image', true, true, 'split-passengers-modal', FileUploaderComponent, {});
        dataObject.disableClose = true;
        this.confirmModal(dataObject);
        // tslint:disable-next-line:no-shadowed-variable
        var x = this.dataStoreService.get(DataKey.popupClose).subscribe(function (data) {
            if (data != null) {
                _this.closeModal();
            }
        });
        // tslint:disable-next-line:no-shadowed-variable
        this.imageUrlImportSubscribe = this.dataStoreService.get(DataKey.fileToUpload).subscribe(function (data) {
            if (data != null) {
                _this.uploadFile(data, params);
                _this.imageUrlImportSubscribe.unsubscribe();
            }
        });
    };
    ProductDetailsGridSetupComponent.prototype.uploadFile = function (fileToUpload, params) {
        var _this = this;
        this.genImageUploadSubscribe = this.masterDataRestService.uploadImage(fileToUpload).subscribe(function (res) {
            console.log(res);
            if (res && res.data && res.data[0]) {
                params.data.imageUrl = res.data[0].contentUrl;
                _this.saveRow(params.data, null).subscribe(function (data) {
                    console.log(data);
                    _this.setupGrid.runForceSearch();
                });
                _this.common.showSnackBar('Successfully uploaded', 3000, TcErrorType.TYPE.INFO);
            }
        }, function (error) {
            if (error.error.status.description.substring(0, 4) === 'File') {
                _this.common.showSnackBar('Fail to upload: ' + error.error.status.description, 6000, TcErrorType.TYPE.ERROR);
            }
            else {
                _this.common.showSnackBar('Fail to upload', 3000, TcErrorType.TYPE.ERROR);
            }
        });
    };
    ProductDetailsGridSetupComponent.prototype.confirmModal = function (data) {
        this.modalService.confirm(data).subscribe(function (res) {
        });
    };
    ProductDetailsGridSetupComponent.prototype.closeModal = function () {
        if (this.modalService.close()) {
            this.modalService.close().subscribe(function (res) {
            });
        }
    };
    ProductDetailsGridSetupComponent.prototype.isInvalidRow = function (params) {
        var data = params.data;
        if (data) {
            var invalid = true;
            if (data.productCode &&
                data.serviceType &&
                data.imageUrl) {
                invalid = false;
            }
            return invalid;
        }
        else {
            return false;
        }
    };
    ProductDetailsGridSetupComponent.prototype.checkRowInvalid = function (data) {
        var event = {
            data: data
        };
        return this.isInvalidRow(event);
    };
    ProductDetailsGridSetupComponent.prototype.isUnsavedRow = function (row) {
        return !row.productCode && !row.serviceType;
    };
    ProductDetailsGridSetupComponent.prototype.deleteRow = function (row) {
        if (row && row.productCode) {
            var productDetailsSearchCriteria = new ProductDetailsSearchCriteria();
            productDetailsSearchCriteria.productCode = row.productCode;
            productDetailsSearchCriteria.serviceType = row.serviceType;
            return this.productDetailsService.deleteProductDetails(productDetailsSearchCriteria);
        }
        else {
            return of('success');
        }
    };
    ProductDetailsGridSetupComponent.prototype.saveRow = function (row, event) {
        return this.productDetailsService.saveProductDetails(row);
    };
    ProductDetailsGridSetupComponent.prototype.getRows = function (params) {
        var _this = this;
        console.log('fetch requesting for ' + params.startRow + ' to ' + params.endRow);
        console.log(params.sortModel);
        var pageSize = params.endRow - params.startRow;
        if (!this.productDetailsSearchCriteria) {
            this.productDetailsSearchCriteria = new ProductDetailsSearchCriteria();
        }
        this.productDetailsSearchCriteria.start = params.startRow;
        this.productDetailsSearchCriteria.size = pageSize;
        if (params.sortModel && params.sortModel[0]) {
            this.productDetailsSearchCriteria.sortBy = params.sortModel[0].colId;
            if (params.sortModel[0].sort === SetupGridComp.GRID_SORT_ASCENDING) {
                this.productDetailsSearchCriteria.sortDirection = SortDirection.ASC;
            }
            else {
                this.productDetailsSearchCriteria.sortDirection = SortDirection.DESC;
            }
        }
        else {
            // this.productDetailsSearchCriteria.sortBy = 'productCode';
            this.productDetailsSearchCriteria.sortDirection = SortDirection.ASC;
        }
        return this.productDetailsService.getProductDetails(this.productDetailsSearchCriteria)
            .pipe(tap(function (data) {
            return _this.dataStoreService.set(DataKey.productDetailsSearchResultsForCriteria, data);
        }));
    };
    ProductDetailsGridSetupComponent.prototype.createNewRow = function () {
        return {};
    };
    ProductDetailsGridSetupComponent.prototype.getResourceTypeList = function () {
        var _this = this;
        this.dataStoreService.get(DataKey.resourceTypes).subscribe(function (types) {
            if (types) {
                _this.serviceTypeList = types;
            }
        });
    };
    ProductDetailsGridSetupComponent.prototype.ngOnDestroy = function () {
        if (this.searchCriteriaObserver) {
            this.searchCriteriaObserver.unsubscribe();
        }
    };
    return ProductDetailsGridSetupComponent;
}(SetupGridComp));
export { ProductDetailsGridSetupComponent };
