import { Component } from '@angular/core';
import { TCO } from '../../../../constants';
import { ProductQueueSearchCriteria } from '../../../../models/reservation-v2/criteria/product-queue-search-criteria';
import { DispatchStatus } from '../../../../models/reservation-v2/dispatch-status';
import { MainType } from '../../../../models/reservation-v2/main-type';
import { ProductLevel } from '../../../../models/reservation-v2/product-level';
import { TrsType } from '../../../../models/reservation-v2/TrsType';
import { AssignmentStatus } from '../../../../models/reservation/assignment-status';
import { BookingItemStatusType } from '../../../../models/reservation/booking-item-status.enum';
import { DataKey } from '../../../../services/util/framework/data-store.service';

@Component({
    selector: 'tc-product-queue-search-criteria-trs',
    templateUrl: './product-queue-search-criteria-trs.component.html'
})
export class ProductQueueSearchCriteriaTrsComponent
{

    inputSearchCriteria = new ProductQueueSearchCriteria();
    searchCriteriaUpdatingDataKey = DataKey.productQueueSearchCriteria;
    searchResultsDataKey = DataKey.productQueueSearchResultsFromService;
    searchCriteriaLoadingDataKey = TCO.AppData.PRODUCT_QUEUE_SEARCH_CRITERIA_TRS;
    chipConfigKey = TCO.CONF.CONF_PRODUCT_QUEUE_SEARCH_CRITERIA_CHIP_TRS;
    paginationConfigKey = 'PRODUCT_QUEUE_SEARCH';
    placeHolderText = 'Search Transfer Products';

    createNewSearchCriteriaFn = () => {
        const criteria = new ProductQueueSearchCriteria();
        criteria.productLevel = ProductLevel.ANY;
        criteria.mainType = MainType.TRS;
        criteria.trsType = TrsType.ANY;
        criteria.transferMode = 'ANY';
        return criteria;
    };

    clearLoadedSearchCriteriaFn = (searchCriteria: ProductQueueSearchCriteria) => {
        searchCriteria.productLevel = ProductLevel.ANY;
        searchCriteria.mainType = MainType.TRS;
        searchCriteria.trsType = TrsType.ANY;
        searchCriteria.transferMode = 'ANY';
        searchCriteria.bookingSourceId = 'Any';
        searchCriteria.contractReference = 'Any';
        searchCriteria.dropOffType = 'ANY';
        searchCriteria.itemNumber = 'Any';
        searchCriteria.bookingItemStatus = BookingItemStatusType.ANY;
        searchCriteria.dispatchStatusType = DispatchStatus.ANY;
        searchCriteria.pickupType = 'ANY';
        searchCriteria.plCode = 'Any';
        searchCriteria.plName = 'Any';
        searchCriteria.allocatedSupplierCode = 'ANY';
        searchCriteria.assignStatus = AssignmentStatus.ANY;
        searchCriteria.allocatedSupplierName = 'Any';
    };
}
