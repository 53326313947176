import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TaxScheme } from '../../../models/supplier/tax-scheme';
import { ActivatedRoute } from '@angular/router';
import { FocusViewManager } from '@tc-core/util/framework/focus-view-manager.service';
import { ProfileManagementService } from '../../../services/backend-consumers/supplier-service/profile-management.service';

@Component({
    selector: 'tc-tax-scheme-edit-card',
    templateUrl: './tax-scheme-edit-card.component.html'
})
export class TaxSchemeEditCardComponent implements OnInit, OnChanges {

    taxScemeInfo: FormGroup;
    @Input() taxItems = [];
    @Input() taxSchemesMD: any[];
    resourceType: string = '';
    inputDisable = false;
    resourceValue = '';
    taxSchemeValue = '';
    specialValue = false;
    @Input() forAResourceProfile = false;

    activeFlag: boolean = false;
    exemptFlag: boolean = false;

    @Input() taxSchemeData: TaxScheme;
    @Output() changeFormValidity: EventEmitter<boolean> = new EventEmitter();

    constructor(
        private fb: FormBuilder,
        private profileManagementService: ProfileManagementService,
        private route: ActivatedRoute,
        private focusViewManager: FocusViewManager
    ) {
    }

    ngOnInit() {
        this.focusViewManager.canProceedState.next(true);
        this.createForm();
        this.resourceType = this.route.snapshot.queryParamMap.get('page').split('_')[1];
        /*if(this.dataStore.get(DataKey.supplierInfor).value) {
            this.taxItems = this.dataStore.get(DataKey.supplierInfor).value.supplierItems;
        }*/

        this.taxScemeInfo.valueChanges.subscribe(
            (data) => {
                if (JSON.stringify(data) !== JSON.stringify({})) {
                    this.taxSchemeData.resourceType = data.item;
                    this.taxSchemeData.taxSchemeCode = data.taxScheme;
                    // this.taxSchemeData.special = data.special ? data.special : false;
                    this.taxSchemeData.rate = data.specialRate;
                    // this.taxSchemeData.exempt = data.exempt;
                }

                /*if (this.changeFormValidity ) {
                    if (this.taxSchemeData["taxSchemeId"]) {
                        this.dataStore.updateArray(DataKey.selectedTaxSchemes, this.taxSchemeData, "taxSchemeId", true);
                    }else{
                        this.dataStore.updateArray(DataKey.selectedTaxSchemes, this.taxSchemeData, "tempId", true);
                    }
                }*/
            }
        );
        this.validateForm();
    }

    public validateForm() {
        Object.keys(this.taxScemeInfo.controls).forEach(field => {
            const control = this.taxScemeInfo.get(field);
            control.markAsTouched({onlySelf: true});
        });
    }

    ngOnChanges(changes: SimpleChanges) {

        for (let propName in changes) {
            if (propName === 'taxSchemeData') {
                if (this.taxSchemeData && this.taxScemeInfo) {
                    this.taxScemeInfo.patchValue({
                        item: this.taxSchemeData.resourceType ? this.taxSchemeData.resourceType : '',
                        taxScheme: this.taxSchemeData.taxSchemeCode,
                        special: this.taxSchemeData.special,
                        specialRate: this.taxSchemeData.rate,
                        exempt: this.taxSchemeData.exempt
                    });
                    // this.touchForm();
                    this.changeFormValidity.emit(this.taxScemeInfo.valid);
                }
            }
        }
    }

    /* setTaxSchemes(){
         if (this.taxScemeInfo.value.item != "") {
             this.dataStore.set(DataKey.taxSchemes, this.newTaxScheme, true);
         }
         this.dataStore.set(DataKey.modalClose, true);
         // console.log(this.newTaxScheme);
     }*/

    createForm() {
        this.taxScemeInfo = this.fb.group({
            item: [this.taxSchemeData ? this.taxSchemeData.resourceType : ''],
            taxScheme: [this.taxSchemeData ? this.taxSchemeData.taxSchemeCode : '', [Validators.required]],
            special: [this.taxSchemeData ? this.taxSchemeData.special : false],
            specialRate: [this.taxSchemeData ? this.taxSchemeData.rate : ''],
            exempt: [this.taxSchemeData ? this.taxSchemeData.exempt : false]
        });
    }

    getErrorMessage(field: string): string {
        switch (field) {
            case 'item' :
                return this.taxScemeInfo.get('item').hasError('required') ? 'Resource Required' : '';
            case 'taxScheme' :
                return this.taxScemeInfo.get('taxScheme').hasError('required') ? 'Tax Scheme Required' : '';
            default :
                return '';
        }
    }

    public toggleActiveValue() {
        if (!this.inputDisable) {
            this.taxSchemeData.special = !this.taxSchemeData.special;
        }
        this.changeFormValidity.emit(this.taxScemeInfo.valid);
    }

    public toggleExemptValue() {
        this.taxSchemeData.exempt = !this.taxSchemeData.exempt;
        this.exemptFlag = this.taxSchemeData.exempt;
        this.inputDisable = this.exemptFlag;
        if (this.exemptFlag) {
            this.taxScemeInfo.reset();
            this.taxSchemeData.special = false ;
        }
        this.changeFormValidity.emit(this.taxScemeInfo.valid);
        //
        // if (this.taxSchemeData.exempt) {
        //     this.taxScemeInfo.reset() ;
        //     this.inputDisable = !this.inputDisable;
        // }
    }
}
