import { Component, OnInit } from '@angular/core';
import { ConfigLoader } from '@tc-core/util/framework';
import { TCO } from '../../../../constants';
import { DocumentSearchCriteria } from '../../../../models/criteria/document-search-criteria';

@Component({
    selector: 'tc-reports-search-results',
    templateUrl: './reports-search-results.component.html'
})
export class ReportsSearchResultsComponent implements OnInit {

    colDefConfig = [];

    createNewSearchCriteriaFn = () => {
        return new DocumentSearchCriteria(false, true);
    }

    constructor(
        private configLoader: ConfigLoader
    ) { }

    ngOnInit() {
        this.colDefConfig = this.loadGridConfig();
    }

    private loadGridConfig() {
        return this.configLoader.configurations.get(TCO.CONF.CONF_REPORTS_GRID);
    }

}
