import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatExpansionModule } from '@angular/material';
import { TranslateModule } from '@ngx-translate/core';
import { ExpandedContentCardModule } from '@tc/expanded-content-card';
import { ExpandedContentCardBodyModule } from '@tc/expanded-content-card-body/expanded-content-card-body.module';
import { ExpandedContentCardFooterModule } from '@tc/expanded-content-card-footer';
import { AdditionalContactsCardModule } from '../additional-contacts-card/additional-contacts-card.module';
import { ContactDetailsCardModule } from '../contact-details-card/contact-details-card.module';
import { CostSetupDataCardModule } from '../cost-setup-data-card/cost-setup-data-card.module';
import { DocumentCardModule } from '../document-card/document-card.module';
import { GenCostSetupDataCardModule } from '../gen-cost-setup-data-card/gen-cost-setup-data-card.module';
import { KeyControlDetailCardModule } from '../key-control-detail-card/key-control-detail-card.module';
import { PaymentInfoCardModule } from '../payment-info-card/payment-info-card.module';
import { RoomCostSetupDataCardModule } from '../room-cost-setup-data-card/room-cost-setup-data-card.module';
import { RoomsModule } from '../rooms/rooms.module';
import { SupplierAttributesCardModule } from '../supplier-attributes-card/supplier-attributes-card.module';
import { SupplierPersonalInfoCardModule } from '../supplier-personal-info-card/supplier-personal-info-card.module';
import { TaxSchemesCardModule } from '../tax-schemes-card/tax-schemes-card.module';
import { SupplierDetailViewComponent } from './supplier-detail-view.component';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule.forChild(),
        MatExpansionModule,
        ExpandedContentCardModule,
        ExpandedContentCardFooterModule,
        ExpandedContentCardBodyModule,
        SupplierPersonalInfoCardModule,
        ContactDetailsCardModule,
        PaymentInfoCardModule,
        TaxSchemesCardModule,
        DocumentCardModule,
        AdditionalContactsCardModule,
        SupplierAttributesCardModule,
        PaymentInfoCardModule,
        AdditionalContactsCardModule,
        SupplierAttributesCardModule,
        CostSetupDataCardModule,
        GenCostSetupDataCardModule,
        RoomsModule,
        RoomCostSetupDataCardModule,
        KeyControlDetailCardModule
    ],
    exports: [
        SupplierDetailViewComponent
    ],
    declarations: [
        SupplierDetailViewComponent
    ],
    providers: []
})
export class SupplierDetailViewModule {}
