import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { DataFormatterUtilService } from '@tc-core/util/system/data-formatter-util.service';
import { ContentStripModule } from '@tc/content-strip';
import { DirectivesModule } from '@tc/directives';
import { ExpandedContentCardModule } from '@tc/expanded-content-card';
import { PaginatorModule } from '@tc/paginator';
import { PipesModule } from '@tc/pipes';
import { OpExpandedContentCardHeaderModule } from '../../../widgets/shared/op-expanded-content-card-header/op-expanded-content-card-header.module';
import { ResultContentCardGenericModule } from '../../../widgets/shared/result-content-card-generic/result-content-card-generic.module';
import { BookingDetailViewModule } from '../booking-detail-view/booking-detail-view.module';
import { AdvBookingSearchResultsComponent } from './adv-booking-search-results.component';

@NgModule({
    declarations: [AdvBookingSearchResultsComponent],
    imports: [
        CommonModule,
        PipesModule,
        DirectivesModule,
        ResultContentCardGenericModule,
        ContentStripModule,
        ExpandedContentCardModule,
        OpExpandedContentCardHeaderModule,
        PaginatorModule,
        BookingDetailViewModule
    ],
    providers: [
        DataFormatterUtilService,
        DatePipe
    ],
    exports: [
        AdvBookingSearchResultsComponent
    ]
})
export class AdvBookingSearchResultsModule {}
