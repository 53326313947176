import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { TcErrorType } from '@tc-core/model/it/codegen/tbx/ext/errors/tc-error';
import { DialogModel, LEVEL } from '@tc-core/model/it/codegen/ui/framework/dialog-model';
import { ConfigLoader } from '@tc-core/util/framework';
import { SpinnerService } from '@tc-core/util/ui/spinner.service';
import { DialogService } from '@tc/dialog/dialog.service';
import { of, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { TCO } from '../../../../constants';
import { ConfigurationSearchCriteria } from '../../../../models/criteria/configuration-search-criteria';
import { SortDirection } from '../../../../models/helper/sort-direction';
import { DataHandlerService } from '../../../../services/backend-consumers/master-data-handler-service/data-handler.service';
import { MasterDataRestService } from '../../../../services/backend-consumers/master-data-handler-service/master-data-rest.service';
import { BackendConfigService } from '../../../../services/backend-consumers/service-config/backend-config.service';
import { DMCCommon } from '../../../../services/util/common/dmc-common';
import { DataKey, DataStoreService } from '../../../../services/util/framework/data-store.service';
import { SetupGridComp } from '../../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid-comp';
import { SetupGridComponent } from '../../../../widgets/framework/ag-grid-custom-components/components/setup-grid/setup-grid.component';
var ConfigSetupGridComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ConfigSetupGridComponent, _super);
    function ConfigSetupGridComponent(configLoader, dataStoreService, dataHandlerService, masterDataRestService, backendConfigService, spinnerService, common, dialogService) {
        var _this = _super.call(this) || this;
        _this.configLoader = configLoader;
        _this.dataStoreService = dataStoreService;
        _this.dataHandlerService = dataHandlerService;
        _this.masterDataRestService = masterDataRestService;
        _this.backendConfigService = backendConfigService;
        _this.spinnerService = spinnerService;
        _this.common = common;
        _this.dialogService = dialogService;
        _this.colDefConfig = [];
        _this.searchCriteria = new ConfigurationSearchCriteria();
        _this.rowClassRules = {
            'tc-ag-grid-row--even': function (params) { return params && params.data && !!params.data.unsaved; }
        };
        _this.searchCriteriaObserver = new Subscription();
        /*
         grid config methods which are called by grid setup (ag grid)
         and set by grid config
         */
        _this.isEditableItem = function (params) {
            return _this.isEditableRow(params.data);
        };
        return _this;
    }
    ConfigSetupGridComponent.prototype.ngOnInit = function () {
        this.colDefConfig = this.configLoader.configurations.get(TCO.CONF.CONF_CONFIG_SETUP);
        this.subscribeSearchCriteria();
        this.fillRequiredGridOptionData();
        this.fillDataKeyForEditorData();
    };
    /**
     * subscribe search criteria changes in search bar
     * then force grid to refresh data
     * grid refresh will be call getRaws method which is using this subscribed criteria for backend call
     */
    ConfigSetupGridComponent.prototype.subscribeSearchCriteria = function () {
        var _this = this;
        this.searchCriteriaObserver = this.dataStoreService.get(DataKey.serviceConfiguration)
            .subscribe(function (value) {
            _this.searchCriteria = value;
            _this.setupGrid.runForceSearch();
        });
    };
    ConfigSetupGridComponent.prototype.fillRequiredGridOptionData = function () {
        // nothing
    };
    ConfigSetupGridComponent.prototype.fillDataKeyForEditorData = function () {
    };
    ConfigSetupGridComponent.prototype.isInvalidRow = function (params) {
        var data = params.data;
        if (data) {
            var invalid = true;
            if (data.code && data.value && data.description) {
                invalid = false;
            }
            return invalid;
        }
        else {
            return false;
        }
    };
    ConfigSetupGridComponent.prototype.createNewRow = function () {
        return { unsaved: true };
    };
    ConfigSetupGridComponent.prototype.isUnsavedRow = function (row) {
        return !!row.unsaved;
    };
    ConfigSetupGridComponent.prototype.deleteRow = function (row) {
        if (row && row.code && this.isEditableRow(row)) {
            return this.masterDataRestService.deleteConfiguration(row.code);
        }
        else {
            return of('success');
        }
    };
    ConfigSetupGridComponent.prototype.saveConfiguration = function () {
        var _this = this;
        if (this.savableRow) {
            this.masterDataRestService.saveConfiguration(this.savableRow)
                .subscribe(function (data) {
                _this.savableRow.unsaved = undefined;
                _this.common.showSnackBar('Successfully updated the configuration.', 3000, TcErrorType.TYPE.INFO);
                _this.spinnerService.hide();
            }, function (error) {
                _this.common.showSnackBar('Configuration update is failed.', 3000, TcErrorType.TYPE.ERROR);
                _this.spinnerService.hide();
            }, function () {
                _this.spinnerService.hide();
            });
        }
    };
    ConfigSetupGridComponent.prototype.saveRow = function (row, event) {
        var _this = this;
        this.savableRow = row;
        var confirmSaveConfig = new DialogModel(true, LEVEL.WARNING, 'Save Configuration Value', 'Your last changed cell value will be saved. Do you want to continue?', true, 2000, null, 'Cancel', 'Save', true);
        this.dialogService
            .confirm(confirmSaveConfig)
            .subscribe(function (res) {
            if (res === true) {
                _this.saveConfiguration();
            }
        });
        return undefined;
    };
    ConfigSetupGridComponent.prototype.getRows = function (params) {
        var _this = this;
        console.log('fetch requesting for ' + params.startRow + ' to ' + params.endRow);
        console.log(params.sortModel);
        var pageSize = params.endRow - params.startRow;
        if (!this.searchCriteria) {
            this.searchCriteria = new ConfigurationSearchCriteria();
        }
        this.searchCriteria.start = params.startRow;
        this.searchCriteria.size = pageSize;
        if (params.sortModel && params.sortModel[0]) {
            this.searchCriteria.sortBy = params.sortModel[0].colId;
            if (params.sortModel[0].sort === SetupGridComp.GRID_SORT_ASCENDING) {
                this.searchCriteria.sortDirection = SortDirection.ASC;
            }
            else {
                this.searchCriteria.sortDirection = SortDirection.DESC;
            }
        }
        else {
            this.searchCriteria.sortBy = 'code';
            this.searchCriteria.sortDirection = SortDirection.DESC;
        }
        return this.masterDataRestService.getConfigurations(this.searchCriteria)
            .pipe(tap(function (data) {
            _this.dataStoreService.set(DataKey.serviceConfigurationSearchResultsForCriteria, data);
            _this.backendConfigService.updateConfigs(data);
        }));
    };
    ConfigSetupGridComponent.prototype.isEditableRow = function (row) {
        if (row) {
            if (row.unsaved) {
                return true;
            }
            else {
                return row.code !== 'TEST_MODE';
            }
        }
        else {
            return false;
        }
    };
    ConfigSetupGridComponent.prototype.ngOnDestroy = function () {
        if (this.searchCriteriaObserver) {
            this.searchCriteriaObserver.unsubscribe();
        }
    };
    return ConfigSetupGridComponent;
}(SetupGridComp));
export { ConfigSetupGridComponent };
