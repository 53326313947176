<div class="container-fluid tc-gwacs-card">
    <div class="row tc-gwacs-card__row">
        <div class="tc-flex-item icon-cage input-icon">
            <div class="card-icon--small icn-color-primary">
                <i class="material-icons material-icons">notes</i>
            </div>
        </div>
        <div class="tc-flex-item input-cage rs-full-width">
            <div class="row">
            <div class="col-xs-12 col-sm-12  col-md-10 ">

                <div class="mat-input-container">
                    <mat-form-field>
                    <textarea matInput
                              [(ngModel)]="serviceNote.note"
                              [placeholder]="'Note'"
                              [disabled]="isSaved || disableGroupNoteEdit"
                    ></textarea>
                    </mat-form-field>
                </div>
            </div>
            <div class="col-xs-2 ">

                <div class="tc-action-column">
                    <div class="tc-icon-block">
                <span class="tc-icon-block__icon tc-icon-block__icon--active"
                      *ngIf='showSaveButton(serviceNote.note)'>
                  <i class="material-icons"
                     [matTooltip]="'Save' | translate"
                     [matTooltipPosition]="'below'"
                     (click)="onNoteSave($event)">save</i>
                </span>
                        <span class="tc-icon-block__icon tc-icon-block__icon--active" *ngIf="isSaved || isEdited">
                  <i
                          class="material-icons" *ngIf="showEditButton()"
                          [matTooltip]="'Edit' | translate"
                          [matTooltipPosition]="'below'"
                          (click)="onEdit($event)">edit</i>
                    <i
                            class="material-icons" *ngIf="isEdited && !isSaved"
                            [matTooltip]="'Undo' | translate"
                            [matTooltipPosition]="'below'"
                            (click)="onUndo($event)">undo</i>

                </span>
                        <span class="tc-icon-block__icon tc-icon-block__icon--active">
                  <i
                          class="material-icons" *ngIf="showDeleteButton()"
                          [matTooltip]="'Delete' | translate"
                          [matTooltipPosition]="'below'"
                          (click)="onDelete($event)">delete</i>
                </span>
                    </div>
                </div>
            </div>

            </div>
        </div>
    </div>
</div>
