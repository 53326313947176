<!--<div class="tc-item-wrapper">
    <div class="tc-item-header tc-flex-box">
        <div class="tc-flex-item tc-item-header__primary">Tax Schemes</div>
        <div class="tc-flex-item tc-item-header__actions tc-icon-block">
        </div>
    </div>
</div>-->
<form [formGroup]="taxScemeInfo">
    <div class="container-fluid direct-client-personal-info-edit-card tc-card-wrapper">
        <div class="row">
            <div class="col-md-12 col-lg-12">
                <div class="tc-flex-box">
                    <div class="tc-flex-item tc-item-circle">
                        <div class="icon-box">
                            <i class="material-icons">account_circle</i>
                        </div>
                    </div>
                    <div class="tc-flex-item tc-right-cage">
                        <div class="row">
                            <div *ngIf="!forAResourceProfile" class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                <div class="tc-select-edit dcpiec-select-title">
                                    <mat-form-field>
                                        <mat-select
                                                [disabled]="inputDisable"
                                                formControlName="item"
                                                [placeholder]="'Resource'">

                                            <mat-option *ngFor="let option of taxItems"
                                                        [value]="option.code">
                                                {{option.name}}
                                            </mat-option>
                                        </mat-select>

                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                <div class="tc-select-edit dcpiec-select-title">
                                    <mat-form-field>
                                        <mat-select
                                                    [disabled]="inputDisable"
                                                    formControlName="taxScheme"
                                                    [placeholder]="'Tax Scheme'"
                                                    >
                                            <mat-option *ngFor="let option of taxSchemesMD"
                                                        [value]="option.name">
                                                {{option.name}}
                                            </mat-option>
                                        </mat-select>

                                    </mat-form-field>
                                </div>
                            </div>



                            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 remove-bootstrap-padding-mobile">
                                <div class="tc-input-edit dcpiec-input-lname">
                                    <mat-form-field>
                                        <input
                                                [readonly]="inputDisable"
                                                matInput
                                                formControlName="specialRate"
                                                [placeholder]="'Special Rate'">


                                    </mat-form-field>
                                </div>
                            </div>


                        </div>

                        <div class="tc-action-chip-list-wrapper tc-chip-selector__action-chips">
                            <div class="tc-action-chip"
                                 (click)="toggleActiveValue()"
                                 [ngClass]=" this.taxSchemeData?.special ? 'tc-action-chip--active':'tc-action-chip--inactive' ">
                                <span class="tc-action-chip__icon tc-action-chip__icon-man"></span>
                                <span class="tc-action-chip__icon tc-action-chip__icon-select">
                                  <i class="material-icons">done</i>
                                </span>
                                <div class="tc-action-chip__text">
                                    {{'Special' | translate}}
                                </div>
                            </div>
                            <div class="tc-action-chip"
                                 (click)="toggleExemptValue()"
                                 [ngClass]=" this.taxSchemeData?.exempt ? 'tc-action-chip--active':'tc-action-chip--inactive' ">
                                <span class="tc-action-chip__icon tc-action-chip__icon-man"></span>
                                <span class="tc-action-chip__icon tc-action-chip__icon-select">
                                  <i class="material-icons">done</i>
                                </span>
                                <div class="tc-action-chip__text">
                                    {{'Exempt' | translate}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
<!--shouldn't display if vehicle resource -->
<!--<div *ngIf=""></div>-->
<!--<div *ngIf="resourceType!='vehicle'" class="payment__actions">
    <button class="button-left tc-button-secondary mat-button" mat-button=""
            (click)="closePopUp($event)"><span class="mat-button-wrapper">Cancel</span>
        <div class="mat-button-ripple mat-ripple" matripple=""></div>
        <div class="mat-button-focus-overlay"></div>
    </button>

    <button class="button-right tc-button-primary mat-button" (click)="setTaxSchemes()">
        Add
    </button>
</div>-->
