<div class="container-fluid tc-card-wrapper">
    <div class="row">
        <div class="tc-flex-box">
            <div class="col-md-12 rs-full-width">
                <div class="tc-flex-box">
                    <div class="tc-flex-item icon-cage ">
                        <div class="card-icon--small icn-color-primary">
                            <i class="material-icons">book</i>
                        </div>
                    </div>
                    <div class="tc-flex-item input-cage ">
                        <div class="col-md-3 bsci-col-1">
                            <div class="bsci-col-1__content bsci-col-1-inner-wrapper">
                                <div class="bsci-col-1-inner-wrapper__client-info">
                                    <h5 class="bsci-col-1-inner-wrapper__client">
                                        {{summaryInfo?.rawData?.trsServiceItem?.transferMode}}</h5>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-3 bsci-col-1">
                            <div class="bsci-col-2__travel-info">
                                <div
                                        class="tc-item-header__primary">{{supplierSummary ? supplierSummary?.name :
                                    'Supplier Not Assigned'}}
                                </div>
                                <div *ngIf="summaryInfo?.rawData?.assignment"
                                     class="card-chips item-right cshc-row-1__client-type-chip">
                                    <div [ngClass]="[summaryInfo?.rawData?.assignment.status==1 ?'chip active-color--state_background':'',summaryInfo?.rawData?.assignment.status==2 ?'display-chip display-chip--supplierConfirm':'', summaryInfo?.rawData?.assignment.status==3 ?'display-chip display-chip--supplierRejected':'']">
              <span
                      class="tc-strip-circle__text"
              >{{assignmentStatus | capitalizeEachWord:true}}</span>
                                    </div>
                                </div>
                                <!--<div class="card-chips bsci-col-2__chip">
                                    <div>
                                        <tc-resource
                                                *ngIf="bookingDepartureDetails?.icon?.value"
                                                id="{{bookingDepartureDetails?.icon?.key}}"
                                                [key]="bookingDepartureDetails?.icon?.value?.key"
                                                [value]="bookingDepartureDetails?.icon?.value?.value"
                                                [style]="bookingDepartureDetails?.icon?.value?.style"
                                                [iconType]="bookingDepartureDetails?.icon?.value?.iconType">
                                        </tc-resource>
                                    </div>
                                </div>-->
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-3 bsci-col-3">
                            <div class="bsci-col-2__travel-info">
                                <div
                                        class="tc-item-header__primary">{{resourceSummary ?
                                    resourceSummary?.vehicleName
                                    : 'Vehicle Not Assigned'}}
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-3 bsci-col-3">
                            <div class="tc-content--wrap tc-input-view">
                                <div class="rs-r-align tc-strip__price tc-strip__price--x-large bsci-col-2__travel-info">
                                    {{summaryInfo.rawData?.assignment?.cost | currency:CURRENCY_TYPE: true}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12 rs-full-width">
            <div class="tc-flex-box">
                <div class="tc-flex-item icon-cage ">
                    <div class="card-icon--small icn-color-primary"></div>
                </div>
                <div class="tc-flex-item input-cage ">
                    <div class="col-xs-12 col-sm-6 col-md-3">
                        <div class="tc-content--wrap tc-input-view">
                            <div
                                    id="TRD_PER_INFO_COU"
                                    class="tc-caption-txt txt-light">{{"Passenger Count" | translate}}
                            </div>
                            <div class="tc-body1--txt">{{summaryInfo.passengerCount}}
                            </div>
                        </div>
                        <div class="tc-content--wrap tc-input-view">
                            <div
                                    id="TRD_PER_INFO_REF"
                                    class="tc-caption-txt txt-light">{{"Lead Passenger" | translate}}
                            </div>
                            <div class="tc-body1--txt">{{summaryInfo.leadPassenger}}
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-6 col-md-3">
                        <div class="tc-content--wrap tc-input-view">
                            <div
                                    class="tc-caption-txt txt-light">{{"Pick up" | translate}}
                            </div>
                            <div class="tc-body1--txt">{{summaryInfo.pickups}}
                            </div>
                        </div>
                        <div class="tc-content--wrap tc-input-view">
                            <div
                                    class="tc-caption-txt txt-light">{{"Drop off" | translate}}
                            </div>
                            <div class="tc-body1--txt">{{summaryInfo.dropOffs}}
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-6 col-md-3">
                        <div class="tc-content--wrap tc-input-view">
                            <div
                                    id="TRD_PER_INFO_HOD_REF"
                                    class="tc-caption-txt txt-light">{{"Driver" | translate}}
                            </div>
                            <div class="tc-body1--txt">{{driverSummary ? driverSummary.firstName + " " + driverSummary.lastName : EMPTY_CHAR }}
                            </div>
                        </div>
                    </div>
                    <div *ngIf="summaryInfo.rawData?.assignment?.cost" class="col-xs-12 col-sm-6 col-md-3 bsci-col-3">
                        <div class="rs-r-align rs-txt-secondary">
                            <div class="tc-price-field">
                                <span class="tc-price-label">{{'Estimated' | translate}} :  </span>
                                <span class="tc-price-value">{{summaryInfo.rawData?.assignment?.estimatedCost| currency:CURRENCY_TYPE: true}}</span>
                            </div>
                        </div>
                        <!--<div class="rs-r-align rs-txt-secondary">
                            <div class="tc-price-field">
                                <span class="tc-price-label">Per Person :  </span>
                                <span class="tc-price-value">{{summaryInfo.rawData?.assignment?.unitCost| currency:CURRENCY_TYPE: true}}</span>
                            </div>
                        </div>-->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 rs-full-width">
            <div class="tc-flex-box">
                <div class="tc-flex-item icon-cage ">
                    <div class="card-icon--small icn-color-primary"></div>
                </div>
                <div *ngIf="historyText" class="tc-flex-item input-cage ">
                    <div class="col-xs-12 col-sm-6 col-md-6">
                        <div class="tc-content--wrap tc-input-view">
                            <div
                                    id="TRD_PER_INFO_HISTORY"
                                    class="tc-caption-txt txt-light">{{"History" | translate}}
                            </div>
                            <div class="tc-body1--txt" [innerHTML]="historyText"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
