<div class="add-note-cage">

  <div class="modal-inner-wrapper">
    <div class="tc-modal__header">
      <div class="model-inner-header">
        <h2 mat-dialog-title>{{data.title | translate}}</h2>
      </div>
      <div class="other-content"></div>
    </div>
  </div>

  <form [formGroup]="noteFormGroup">
    <mat-dialog-content>
      <tc-chip-selector
        #_chip_selector
        formControlName="noteType"
        [label]=""
        [keyOfCode]="['code']"
        [keyOfValue]="['code']"
        [align]="''"
        [limit]="6"
        [options]="data.noteTypes"
        [isEditable]="!data.isNoteEditMode"
        [chipMethodCodes]="data.methodCodes"
        [chipResourcePath]="data.resourcePath"
        [(ngModel)]="data.selectedNoteTypes"
        [multiselect]="multiSelect()"
        (select)="onModelChange($event)"
        (ngModelChange)="onModelChange($event)">
      </tc-chip-selector>
      <mat-form-field>
        <textarea
          matInput
          placeholder="Note"
          formControlName="noteText"
          [(ngModel)]="data.note"
          maxlength="400">
        </textarea>
      </mat-form-field>
    </mat-dialog-content>
  </form>

  <mat-dialog-actions align="end">
    <button
      mat-button
      (click)="onNoClick()"
      class="tc-btn tc-btn--ternary">
      Cancel
    </button>
    <button
      mat-button
      cdkFocusInitial
      [mat-dialog-close]="this.data"
      [ngClass]="this.noteFormGroup.invalid? 'tc-btn--disabled':''"
      [disabled]="this.noteFormGroup.invalid"
      class="tc-btn tc-btn--primary">
      {{'Save' | translate}}
    </button>
  </mat-dialog-actions>
</div>
